// eslint-disable-next-line no-unused-vars
import React, { useState } from "react"
import PropTypes from "prop-types"
import AppBar from "@mui/material/AppBar"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import ReactJson from "react-json-view"
import NarrativeBlockSnapshots from "components/NarrativeAdmin/Data/NarrativeBlockSnapshots"
import NarrativeModelTriggersSnaphot from "components/NarrativeAdmin/Data/NarrativeModelTriggersSnaphot"
import Helpers from "tools/Helpers"
import NarrativePreviewDataView from "./NarrativePreviewDataView"

const NarrativePreviewControl = ({
  activeElements,
  isLoadingPreview,
  renderedNarrative,
  renderTimeInMilliseconds,
  showElementsInGroups = false
}) => {
  const [activeTab, setActiveTab] = useState(0)
  const [isFilterErrorState, setIsFilterErrorState] = useState(false)

  const toggle = (event, newValue) => {
    if (activeTab !== newValue) {
      setActiveTab(newValue)
    }
  }

  const handleViewFailedBlocks = e => {
    e.preventDefault()
    setActiveTab(2)
    setIsFilterErrorState(true)
  }

  const extractSnapshotsFromNarrative = () => {
    const snapshots = []
    const badBlocks = []
    if (narrativeBlockSnapshots) {
      narrativeBlockSnapshots.forEach(paragraph => {
        if (paragraph.selectedSentenceObjects?.length > 0) {
          let hasErrors =
            paragraph.hasScriptErrors ||
            paragraph.hasTemplateErrors ||
            paragraph.failedValidation ||
            false
          paragraph.selectedSentenceObjects.forEach(sentence => {
            const sentenceHasErrors =
              sentence.hasScriptErrors ||
              sentence.hasTemplateErrors ||
              sentence.failedValidation ||
              false
            const s = {
              narrative_Id: sentence.narrative_Id,
              seriesPosition: sentence.seriesPosition,
              sentence_Id: sentence.id,
              status_Name: Helpers.convertSentenceStatusIdToStatusName(
                sentence.status_Id
              ),
              position: sentence.position,
              paragraph_Id: sentence.paragraph_Id,
              triggerType: sentence.triggerType,
              template: sentence.template,
              renderedString: sentence.renderedString,
              hasScriptErrors: sentence.hasScriptErrors,
              scriptErrorMessage: sentence.scriptErrorMessage,
              hasTemplateErrors: sentence.hasTemplateErrors,
              templateErrorMessage: sentence.templateErrorMessage,
              hasErrors: sentenceHasErrors,
              failedValidation: false,
              validationMessage: "",
              feedEntry_VersionNumber: 1
            }
            snapshots.push(s)
            if (sentenceHasErrors) {
              hasErrors = true
            }
          })
          const p = {
            narrative_Id: paragraph.narrative_Id || snapshots[0].narrative_Id,
            seriesPosition: paragraph.seriesPosition,
            sentence_Id: null,
            paragraph_Id: paragraph.id,
            triggerType: paragraph.triggerType,
            template: paragraph.template,
            renderedString: paragraph.renderedString,
            hasScriptErrors: paragraph.hasScriptErrors,
            scriptErrorMessage: paragraph.scriptErrorMessage,
            hasTemplateErrors: paragraph.hasTemplateErrors,
            templateErrorMessage: paragraph.templateErrorMessage,
            hasErrors,
            failedValidation: paragraph.failedValidation,
            validationMessage: paragraph.validationMessage,
            feedEntry_VersionNumber: 1
          }
          snapshots.push(p)
          if (hasErrors) {
            badBlocks.push(p)
          }
        }
        if (paragraph.failedSentenceObjects?.length > 0) {
          paragraph.failedSentenceObjects.forEach(sentence => {
            const sentenceHasErrors =
              sentence.hasScriptErrors ||
              sentence.hasTemplateErrors ||
              sentence.failedValidation ||
              false
            const s = {
              narrative_Id: sentence.narrative_Id,
              seriesPosition: sentence.seriesPosition,
              sentence_Id: sentence.id,
              paragraph_Id: sentence.paragraph_Id,
              triggerType: sentence.triggerType,
              template: sentence.template,
              renderedString: sentence.renderedString,
              hasScriptErrors: sentence.hasScriptErrors,
              scriptErrorMessage: sentence.scriptErrorMessage,
              hasTemplateErrors: sentence.hasTemplateErrors,
              templateErrorMessage: sentence.templateErrorMessage,
              hasErrors: sentenceHasErrors,
              failedValidation: false,
              validationMessage: "",
              feedEntry_VersionNumber: 1
            }
            snapshots.push(s)
          })
        }
      })
    }
    return { snapshots, badBlocks }
  }

  const { narrativeBlockSnapshots, triggers, evaluatedTriggers } =
    renderedNarrative

  const { snapshots, badBlocks } = extractSnapshotsFromNarrative()

  return (
    <div>
      <AppBar position="static">
        <Tabs
          value={activeTab}
          onChange={toggle}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="primary"
          textColor="#FFF"
        >
          <Tab value={0} label="Rendered View" />
          <Tab value={1} label="Tree View" />
          <Tab value={2} label="Blocks" />
          <Tab value={3} label="Triggers" />
        </Tabs>
      </AppBar>
      {activeTab === 0 && (
        <NarrativePreviewDataView
          activeElements={activeElements}
          badBlocks={badBlocks}
          handleViewFailedBlocks={handleViewFailedBlocks}
          isLoadingPreview={isLoadingPreview}
          renderedNarrative={renderedNarrative}
          renderTimeInMilliseconds={renderTimeInMilliseconds}
          showElementsInGroups={showElementsInGroups}
        />
      )}
      {activeTab === 1 && (
        <ReactJson
          theme="monokai"
          enableClipboard={true}
          displayObjectSize={true}
          displayDataTypes={false}
          src={renderedNarrative}
          collapsed={false}
          collapseStringsAfterLength={450}
          shouldCollapse={({ name }) =>
            name === "Triggers" ||
            name === "SelectedSentenceObjects" ||
            name === "NarrativeBlockSnapshots" ||
            name === "Narrative" ||
            name === "AuditInfo" ||
            name === "Organization" ||
            name === "Feed"
          }
          style={{ overflowWrap: "anywhere" }}
        />
      )}
      {activeTab === 2 && (
        <NarrativeBlockSnapshots
          snapshots={snapshots}
          filterErrors={isFilterErrorState}
          isLoadingPreview={isLoadingPreview}
        />
      )}
      {activeTab === 3 && (
        <NarrativeModelTriggersSnaphot
          triggers={evaluatedTriggers || triggers}
          pageSize={50}
        />
      )}
    </div>
  )
}

NarrativePreviewControl.propTypes = {
  activeElements: PropTypes.arrayOf(PropTypes.object),
  renderedNarrative: PropTypes.object,
  isLoadingPreview: PropTypes.bool,
  renderTimeInMilliseconds: PropTypes.number
}

export default NarrativePreviewControl
