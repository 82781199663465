import React, { Component } from "react"
import ReactTable from "react-table-6"

import classnames from "classnames"
import matchSorter from "match-sorter"
import FormattedDate from "components/FormattedDate"
import Tooltip from "@mui/material/Tooltip"
import withStyles from "@mui/styles/withStyles"
import GridItem from "components/Grid/GridItem"
import ContractDeliverable from "components/Organizations/ContractDeliverable"
import ContractMilestone from "components/Organizations/ContractMilestone"
import ContractDetails from "components/Organizations/ContractDetails"
import Milestone from "assets/icons/Milestone"
import Helpers from "tools/Helpers.js"
import { Cookies } from "tools/storage"
import { observer, inject } from "mobx-react"

import PropertiesManager from "components/Organizations/PropertiesManager"
import OrganizationEditor from "components/Organizations/OrganizationEditor"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import Dialog from "components/Dialog"

import CircularProgress from "@mui/material/CircularProgress"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import AppBar from "@mui/material/AppBar"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Grid from "@mui/material/Grid"
import Checkbox from "@mui/material/Checkbox"
import Modal from "@mui/material/Modal"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import LinearProgress from "@mui/material/LinearProgress"
import DialogTitle from "@mui/material/DialogTitle"
import DialogActions from "@mui/material/DialogActions"
import FormHelperText from "@mui/material/FormHelperText"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"

import { TabPanel } from "components/TabPanel/TabPanel"
import Button from "components/CustomButtons/Button"
import DeleteIcon from "@mui/icons-material/Delete"
import { Add, Assignment } from "@mui/icons-material"
import PropTypes from "prop-types"
import { globalSettings } from "variables/general"
import UploadDeliverables from "components/CustomUpload/UploadDeliverables"
import { get, put } from "tools/request"
import ContractDeliverablesTable from "./ContractDeliverablesTable"

const styles = theme => ({
  datasouresClass: {
    textAlign: "center !important",
    backgroundColor: "#fff",
    fontWeight: "bold !important",
    borderBottom: "2px solid #888",
    padding: "0 !important",
    margin: "0 !important"
  },
  emptyClass: {
    backgroundColor: "#fff",
    padding: "0 !important",
    margin: "0 !important"
  },
  sentencePreview: {
    fontWeight: "normal",
    "& .xml": {
      fontWeight: "bold",
      fontSize: "13.5px",
      fontStyle: "normal"
    },
    "& pre": {
      padding: "5px 15px!important"
    }
  },
  archiveMessage: {
    textDecoration: "line-through",
    paddingLeft: "50px"
  },
  requiredMsg: {
    color: "#c00",
    fontWeight: "bold",
    backgroundColor: "#f7f7f7"
  },
  progressingMsg: {
    color: "#999",
    textAlign: "center",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    margin: "5px 15px 0 5px"
  },
  finishedMsg: {
    color: "#069",
    textAlign: "center",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    margin: "5px 15px 0 5px"
  },
  failedListMsg: {
    color: "#C00",
    textAlign: "center",
    cursor: "pointer",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    margin: "5px 15px 0 5px"
  },
  failedMsg: {
    color: "#C00",
    textAlign: "center",
    cursor: "pointer",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    margin: "12px 15px 0 5px"
  },
  progressBar: {
    width: "40%",
    background: "#eee",
    display: "flex",
    top: "55px",
    right: "20px",
    position: "fixed",
    zIndex: "99999",
    border: "2px solid #036",
    borderRadius: "15px",
    padding: "4px"
  },
  progressIcon: {
    color: "#000",
    border: "2px solid",
    borderRadius: "20px",
    height: "30px",
    _marginTop: "5px"
  },
  successModal: {
    position: "absolute",
    color: "#069",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    transform: "translate(-45%, -45%)",
    "& h3": {
      fontWeight: "bold"
    },
    "& ul": {
      padding: "5px 0 0 5px",
      fontWeight: "bold"
    },
    [theme.breakpoints.up(701)]: {
      width: 400,
      top: "45%",
      left: "45%"
    },
    [theme.breakpoints.down(700)]: {
      width: "70vw",
      top: "50vh",
      left: "50vw"
    }
  },
  errorModal: {
    position: "absolute",
    color: "#c00",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    transform: "translate(-45%, -45%)",
    "& h3": {
      fontWeight: "bold"
    },
    "& ul": {
      padding: "5px 0 0 5px",
      fontWeight: "bold",
      textDecoration: "underline"
    },
    [theme.breakpoints.up(701)]: {
      width: 400,
      top: "45%",
      left: "45%"
    },
    [theme.breakpoints.down(700)]: {
      width: "70vw",
      top: "50vh",
      left: "50vw"
    }
  },
  myButton: {
    color: "#ffffff",
    boxShadow:
      "0 2px 2px 0 rgb(48 55 63 / 14%), 0 3px 1px -2px rgb(48 55 63 / 20%), 0 1px 5px 0 rgb(48 55 63 / 12%)",
    backgroundColor: "#4d9ab5",
    borderRadius: "3px",
    border: "none"
  },
  initTextField: {
    backgroundColor: "#ffd"
  },
  formContentContainer: {
    border: "1px solid #AAA",
    borderRadius: "20px",
    _minWidth: "1200px",
    overflowX: "auto",
    "& h2": {
      borderBottom: "1px solid #aaa"
    }
  },
  formExtrasContainer: {
    border: "1px solid #AAA",
    borderRadius: "20px",
    overflowX: "auto",
    "& h2": {
      borderBottom: "1px solid #aaa"
    }
  },

  myFlex: {
    [theme.breakpoints.down(700)]: {
      display: "flex",
      flexDirection: "column"
    },
    [theme.breakpoints.up(701)]: {
      display: "flex",
      flexDirection: "row"
    }
  },
  myFlexFontSize: {
    "& h2": {
      [theme.breakpoints.down(700)]: {
        fontSize: "1.25em"
      }
    }
  },
  myFlexContractColumnsNested: {
    [theme.breakpoints.down(960)]: {
      display: "flex",
      flexDirection: "column"
    },
    [theme.breakpoints.up(961)]: {
      display: "flex",
      flexDirection: "row"
    }
  },
  myFlexContractColumns: {
    [theme.breakpoints.up(701)]: {
      maxWidth: "33.5%",
      flexBasis: "33.5%"
    }
  },
  myFlexContractColumns25: {
    [theme.breakpoints.up(701)]: {
      borderLeft: "1px solid #888",
      borderRight: "1px solid #888",
      marginBottom: "15px",
      maxWidth: "25%",
      flexBasis: "25%"
    }
  },
  myFlexInputWidth: {
    [theme.breakpoints.down(700)]: {
      minWidth: "150px"
    }
  },
  tabScrollRoot: {
    [theme.breakpoints.down(700)]: {
      "& .MuiTabs-scrollButtonsDesktop": {
        display: "flex !important"
      },
      "& .MuiTab-wrapper": {
        fontSize: "12px"
      }
    }
  },
  formScroll: {
    overflowX: "auto"
  },
  formNoScroll: {
    overflowX: "hidden"
  },
  noWrap: {
    whiteSpace: "nowrap"
  },
  loading: {
    padding: "5px 0"
  },
  deliverablesTab: {
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.3)",
    backgroundColor: "#f0f0f0",
    marginBottom: "-5px",
    position: "relative"
  },
  deliverablesButton: {
    display: "flex",
    marginLeft: "9px"
  },
  deliverablesCard: {
    borderRadius: "20px"
  }
})

const stringFilterProps = {
  filterable: true,
  filterAll: true,
  filterMethod: ({ id, value }, rows) =>
    matchSorter(rows, value, {
      keys: [id],
      threshold: matchSorter.rankings.CONTAINS
    })
}

const useStore = component =>
  inject(({ store }) => ({
    datasources: store.narrativeStore.datasources,
    getDatasources: store.narrativeStore.getDatasources,
    bookmakers: store.narrativeStore.bookmakers,
    getBookmakers: store.narrativeStore.getBookmakers,
    account: store.accountStore.account,
    getSubjectType: store.organizationStore.getSubjectType,
    subjectTypes: store.organizationStore.subjectTypes,
    getPublishType: store.organizationStore.getPublishType,
    publishTypes: store.organizationStore.publishTypes,
    getCatalystType: store.organizationStore.getCatalystType,
    catalystTypes: store.organizationStore.catalystTypes,
    verticals: store.narrativeStore.verticals,
    getVerticals: store.narrativeStore.getVerticals,
    retrieveLeagues: store.sportsStore.retrieveLeagues,
    retrieveLeagueDetails: store.sportsStore.retrieveLeagueDetails,
    leagues: store.sportsStore.leagues,
    leagueDetailsById: store.sportsStore.leagueDetailsById
  }))(observer(component))

class OrganizationEditorForTabs extends Component {
  constructor(props) {
    super(props)
    const cookies = new Cookies()
    const showArchivedDeliverables = cookies.get("showArchivedDeliverables")
      ? cookies.get("showArchivedDeliverables")
      : false
    const showArchivedMilestones = cookies.get("showArchivedMilestones")
      ? cookies.get("showArchivedMilestones")
      : false

    this.state = {
      isAddingOrganization: props.isAddingOrganization,
      organization: props.organization,
      organizations: props.organizations,
      notChildOrgs: props.notChildOrgs,
      requiredOrgFields: { name: false, webSiteUrl: false, feedType: false },
      preview: "",
      isAccountsLoading: false,
      showLineNumbers: true,
      organizationAccounts: [],
      accountsForTable: [],
      contract: {},
      allAccounts: props.allAccounts,
      userMultiSelect: {},
      selectAllUsers: false,
      numMultiSelect: 0,
      currentView: 1,
      selectedUser: 1,
      bulkAlertMessage: {},
      bulkAlertMessageActive: false,
      bulkAlertErrorMessageActive: false,
      formErrors: {},
      cantCreateContract: false,
      nestedValue: 0,
      showArchivedDeliverables: showArchivedDeliverables,
      showArchivedMilestones: showArchivedMilestones
    }

    this.addThisUser = this.addThisUser.bind(this)
    this.removeUser = this.removeUser.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.addNewContract = this.addNewContract.bind(this)
    this.createContract = this.createContract.bind(this)
  }

  componentDidMount() {
    this.doInitialLoad()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      isAddingOrganization: nextProps.isAddingOrganization,
      organization: nextProps.organization
    })
  }

  async doInitialLoad() {
    this.loadAccountsForOrg()
    await this.loadContractForOrg()
    this.props.getDatasources()
    this.props.getBookmakers()
    this.props.getSubjectType()
    this.props.getCatalystType()
    this.props.getPublishType()
    this.props.getVerticals()
    this.props.retrieveLeagues()

    await this.loadContractDeliverablesForContract()
    if (this.state.contractDeliverables?.loadedContractDeliverableInfo) {
      this.loadArticleTypes()
      this.loadLeagueDetailsForDeliverableLeagues()
    }
    this.loadContractMilestonesForContract()
  }

  loadLeagueDetailsForDeliverableLeagues = async () => {
    const leagueIds =
      this.state.contractDeliverables?.deliverablesForContract?.reduce(
        (ids, deliverable) => {
          if (deliverable.leagueId && !ids.includes(deliverable.leagueId)) {
            ids.push(deliverable.leagueId)
          }
          return ids
        },
        []
      )
    await Promise.all(leagueIds.map(this.props.retrieveLeagueDetails))
  }

  loadAccountsForOrg() {
    if (this.state.loadingAccountsForOrg) {
      return
    }
    this.setState({ loadingAccountsForOrg: true })
    get(
      `${globalSettings.apiBaseUrl}/api/account/retrieveaccountsbyorg?orgid=${this.state.organization.id}`
    )
      .then(Response => Response.json())
      .then(response => {
        if (response.content === null) {
          this.setState({
            organizationAccounts: [],
            loadingAccountsForOrg: false
          })
        } else {
          let accountsForTable = response.content.filter(
            itm => itm.status === "Active"
          )
          let accountsNotInOrg = this.state.allAccounts.filter(function (a) {
            if (a && a.status && a.status === "Active") {
              let included = accountsForTable.find(
                b => b.id === Number.parseInt(a.id)
              )
              if (included) {
                return false
              } else {
                return true
              }
            } else {
              return false
            }
          })
          this.setState({
            organizationAccounts: response.content,
            accountsForTable,
            accountsNotInOrg,
            loadingAccountsForOrg: false
          })
        }
      })
      .catch(err => {
        console.error(
          "Error on GET to /api/account/retrieveaccountsbyorg?orgid={this.state.organization.id}",
          err
        )
      })
  }

  loadContractForOrg(newContractReload) {
    if (!newContractReload && this.state.loadingContractForOrg) {
      return
    }
    this.setState({ loadingContractForOrg: true })
    return get(
      `${globalSettings.apiBaseUrl}/api/organization/retrieve/contract?organizationId=${this.state.organization.id}`
    )
      .then(Response => Response.json())
      .then(response => {
        if (response.content[0]) {
          const newContract = {
            ...response.content[0],
            loadedContractInfo: true,
            contractContentExists: true,
            loadingContractForOrg: false,
            allContracts: response.content,
            currentContract: response.content[0]
          }
          this.setState({
            contract: newContract
          })
        } else {
          const newContract = {
            loadedContractInfo: true,
            contractContentExists: false,
            loadingContractForOrg: false,
            allContracts: [],
            currentContract: {}
          }
          this.setState({ contract: newContract })
        }
      })
      .catch(err => {
        console.error(
          "Error on GET to /api/organization/retrieve/contract?organizationId={this.state.organization.id}",
          err
        )
      })
  }

  loadContractDeliverablesForContract(id) {
    if (
      this.state.contractDeliverables &&
      this.state.contractDeliverables.contractDeliverablesForOrgLoading
    ) {
      return
    }
    const newContractDeliverables = {
      ...this.state.contractDeliverables,
      contractDeliverablesForOrgLoading: true
    }
    this.setState({ contractDeliverables: newContractDeliverables })
    let cId = id || (this.state.contract && this.state.contract.id) || 0
    return get(
      `${globalSettings.apiBaseUrl}/api/organization/retrieve/contractdeliverables?contractId=${cId}`
    )
      .then(Response => Response.json())
      .then(response => {
        if (response.content[0]) {
          const newContent = this.convertIdToOrgName(response.content)

          const newContractDeliverables = {
            deliverablesForContract: response.content,
            loadedContractDeliverableInfo: true,
            contractDeliverableContentExists: true,
            allContractDeliverablesAsObject: newContent,
            contractDeliverablesForOrgLoading: false
          }
          this.setState({
            contractDeliverables: newContractDeliverables
          })
        } else {
          const newContractDeliverables = {
            deliverablesForContract: [],
            loadedContractDeliverableInfo: true,
            contractDeliverableContentExists: false,
            allContractDeliverablesAsObject: {}
          }
          this.setState({
            contractDeliverables: newContractDeliverables
          })
        }
      })
      .catch(err => {
        console.error(
          "Error on GET to /api/organization/retrieve/contractdeliverables?contractId={cId}",
          err
        )
      })
  }

  loadContractMilestonesForContract(id) {
    if (
      this.state.contractMilestones &&
      this.state.contractMilestones.contractMilestonesForOrgLoading
    ) {
      return
    }
    const newContractMilestones = {
      ...this.state.contractMilestones,
      contractMilestonesForOrgLoading: true
    }
    this.setState({ contractMilestones: newContractMilestones })
    let cId = id || (this.state.contract && this.state.contract.id) || 0
    get(
      `${globalSettings.apiBaseUrl}/api/organization/retrieve/contractmilestones?contractId=${cId}`
    )
      .then(Response => Response.json())
      .then(response => {
        if (response.content && response.content[0]) {
          const newContent = this.convertIdToObjName(response.content)

          const newContractMilestones = {
            loadedContractMilestoneInfo: true,
            contractMilestoneContentExists: true,
            allContractMilestonesAsObject: newContent,
            contractMilestonesForOrgLoading: false
          }
          this.setState({
            contractMilestones: newContractMilestones
          })
        } else {
          const newContractMilestones = {
            loadedContractMilestoneInfo: true,
            contractMilestoneContentExists: false,
            allContractMilestonesAsObject: {}
          }
          this.setState({
            contractMilestones: newContractMilestones
          })
        }
      })
      .catch(err => {
        console.error(
          "Error on GET to /api/organization/retrieve/contractmilestones?contractId={cId}",
          err
        )
      })
  }
  convertIdToOrgName_ORIG(arr) {
    const orgs = this.state.organizations
    arr.forEach(itm => {
      orgs.forEach(itm2 => {
        if (itm2.id === itm.organizationId) {
          itm.organizationName = itm2.name
        }
      })
    })
    return arr
  }

  convertIdToOrgName(arr) {
    const orgs = this.state.organizations

    const temp = {}
    arr.forEach((itm, i) => {
      orgs.forEach(itm2 => {
        if (itm2.id === itm.organizationId) {
          arr[i].organizationName = itm2.name
          temp[`${itm.name}:contract${itm.contractId}:deliverable${itm.id}`] =
            arr[i]
        }
      })
    })
    return temp
  }

  convertIdToObjName(arr) {
    const temp = {}
    arr.forEach((itm, i) => {
      temp[`contract${itm.contractId}:milestone${itm.id}`] = arr[i]
    })
    return temp
  }
  loadArticleTypes() {
    if (
      this.state.contractDeliverables &&
      this.state.contractDeliverables.articleTypeLoading
    ) {
      return
    }
    const newContractDeliverables = {
      ...this.state.contractDeliverables,
      articleTypeLoading: true
    }
    this.setState({ contractDeliverables: newContractDeliverables })

    get(`${globalSettings.apiBaseUrl}/api/narrative/articletypes`)
      .then(Response => Response.json())
      .then(response => {
        if (response.content[0]) {
          const articleTypes = response.content
          let tmpDFC = []
          if (
            this.state.contractDeliverables &&
            this.state.contractDeliverables.deliverablesForContract &&
            this.state.contractDeliverables.deliverablesForContract.length > 0
          ) {
            tmpDFC = this.state.contractDeliverables.deliverablesForContract
            tmpDFC.forEach(itm => {
              articleTypes.forEach(itm2 => {
                if (itm2.id === itm.articleTypeId) {
                  itm.articleTypeName = itm2.name
                  itm.isArticleTypeArchived = itm2.isArchived
                }
              })
            })
          }
          const newContractDeliverables = {
            ...this.state.contractDeliverables,
            deliverablesForContract: tmpDFC,
            articleTypes: articleTypes
          }
          this.setState({
            contractDeliverables: newContractDeliverables
          })
        }
      })
      .catch(err => {
        console.error("Error on GET to /api/narrative/articletypes", err)
      })
  }

  saveContractForOrg(myContract) {
    const tempContract = myContract ? myContract : this.state.contract
    const contractId = tempContract.id
    const url = contractId
      ? `${globalSettings.apiBaseUrl}/api/organization/update/contract?id=${this.state.organization.id}`
      : `${globalSettings.apiBaseUrl}/api/organization/create/contract`
    const newContract = {
      ...tempContract,
      isActive:
        tempContract.isActive === undefined ? true : tempContract.isActive,
      id: tempContract.id || 0,
      createdByAccountId: this.props.account.id
    }
    const toDelete = [
      "allContracts",
      "accounts",
      "addDeliverable",
      "loadedContractInfo",
      "loadingContractForOrg",
      "deliverableToEdit",
      "contractContentExists",
      "auditInfo",
      "editingContract",
      "legalNameOnContract",
      "responseMessage",
      "requestFailed",
      "newContractMessage",
      "newContractId",
      "newContractEntryCreated",
      "addingContract",
      "initializedFields",
      "bookmakerFromDB",
      "contractBookmakers",
      "contractDatasources",
      "billingContactAddress",
      "billingContactEmail",
      "billingContactFullName",
      "billingContactPhone",
      "billingDetails",
      "disableSaveButton"
    ]
    toDelete.forEach(itm => {
      if (newContract[itm] !== undefined) {
        delete newContract[itm]
      }
    })
    const newCont = {
      ...tempContract,
      updatingContract: true
    }
    this.setState({ contract: newCont })
    put(url, null, {
      ...newContract,
      organizationId: this.state.organization.id
    })
      .then(Response => Response && Response.ok === true && Response.json())
      .then(response => {
        let newContractId =
          response.responseMessage &&
          response.responseMessage.match(/Contract\s#(\d*):/)
        if (
          newContractId &&
          typeof newContractId === "object" &&
          newContractId[1]
        ) {
          newContractId = newContractId[1]
        }

        if (response.responseCode === 1000) {
          const tmp = response.content

          const tmpAll = this.state.contract.allContracts || []
          if (tmpAll.length > 0) {
            //If we have no existing contracts when we save this one, we skip this
            for (let i = 0, len = tmpAll.length; i < len; i += 1) {
              if (tmp.id === tmpAll[i].id) {
                tmpAll[i] = tmp
              }
            }
          } else {
            //For when this is the first contract for an account
            tmpAll[0] = tmp
          }
          const newContract = {
            ...this.state.contract,
            newContractEntryCreated: true,
            newContractId: newContractId,
            newContractMessage: response.responseMessage,
            responseMessage: response.responseMessage,
            requestFailed: false,
            allContracts: tmpAll,
            editingContract: false,
            addingContract: false,
            contractContentExists: true,
            id: contractId ? contractId : newContractId,
            updatingContract: false,
            disableSaveButton: false
          }
          this.setState({ contract: newContract })
        } else {
          const newContract = {
            ...this.state.contract,
            newContractEntryCreated: true,
            newContractId: newContractId,
            newContractMessage: response.responseMessage,
            responseMessage: response.responseMessage,
            requestFailed: true,
            editingContract: false,
            addingContract: false,
            contractContentExists: true,
            id: contractId ? contractId : newContractId,
            updatingContract: false,
            disableSaveButton: false
          }
          this.setState({ contract: newContract })
        }
      })
      .catch(err => {
        console.error(`Error on PUT to ${url}`, err)
      })
  }

  handleSelectAllUsers() {
    this.setState({
      selectAllUsers: !this.state.selectAllUsers
    })
  }

  handleMultiUserSave() {
    if (this.state.selectAllUsers === true) {
      this.state.accountsNotInOrg.forEach(itm => {
        this.addThisUser(null, itm.id)
      })
    } else {
      const obj = this.state.userMultiSelect
      Object.keys(obj).forEach(key => {
        if (obj[key] === true) {
          this.addThisUser(null, key)
        }
      })
    }
  }
  handleMultiUserClose() {
    this.setState({
      userMultiSelect: {},
      numMultiSelect: 0
    })
  }
  addThisUser(evt, acctId) {
    let modifiedByAccount = this.props.account.id
    let currentOrg = this.state.organization.id
    let selectedAccount = evt ? evt.currentTarget.value : acctId

    if (!selectedAccount) {
      return
    }
    this.setAlertMessage(selectedAccount, "saving", "Bulk")

    put(
      `${globalSettings.apiBaseUrl}/api/organization/add-user/org/${currentOrg}/account/${selectedAccount}/modifiedby/${modifiedByAccount}`
    )
      .then(Response => {
        if (!Response.ok) {
          this.setAlertMessage(
            selectedAccount,
            "failed",
            "bulk",
            Response.status
          )
          throw new Error(`Network response not ok: ${Response.status} error`)
        } else {
          return Response && Response.ok === true && Response.json()
        }
      })
      .then(() => {
        let selectedUser = this.state.allAccounts.find(
          a => a.id === Number.parseInt(selectedAccount)
        )
        this.setAlertMessage(
          selectedAccount,
          "saved",
          "Bulk",
          null,
          `User ID #${selectedAccount} - "${selectedUser.firstName} ${selectedUser.lastName}" - was added`
        )
        this.loadAccountsForOrg()
      })
      .catch(err => {
        console.error(
          "Error on PUT to /api/organization/add-user/org/{currentOrg}/account/{selectedAccount}/modifiedby/{modifiedByAccount}",
          err
        )
      })
  }

  removeUser(accountId) {
    let selectedAccount = accountId
    if (selectedAccount == null) {
      selectedAccount = this.state.selectedUser
    }
    let currentOrg = this.state.organization.id
    this.setAlertMessage(selectedAccount, "saving", "Bulk")
    put(
      `${globalSettings.apiBaseUrl}/api/organization/remove-user/org/${currentOrg}/account/${selectedAccount}`
    )
      .then(Response => {
        if (!Response.ok) {
          this.setAlertMessage(
            selectedAccount,
            "failed",
            "bulk",
            Response.status
          )
          throw new Error(`Network response not ok: ${Response.status} error`)
        } else {
          return Response && Response.ok === true && Response.json()
        }
      })
      .then(() => {
        let selectedUser = this.state.allAccounts.find(
          a => a.id === Number.parseInt(selectedAccount)
        )
        this.setAlertMessage(
          selectedAccount,
          "saved",
          "Bulk",
          null,
          `User ID #${selectedAccount} - "${selectedUser.firstName} ${selectedUser.lastName}" - has been removed`
        )
        this.loadAccountsForOrg()
      })
      .catch(err => {
        console.error(
          "Error on PUT to /api/organization/remove-user/org/{currentOrg}/account/{selectedAccount}",
          err
        )
      })
  }

  processBulkMessage(msg) {
    let saving = {}
    let saved = {}
    let failed = {}

    let savedLength = 0
    let savingLength = 0
    let failedLength = 0

    let tmpBulkState = this.state.bulkAlertMessage

    let failedArray = []
    Object.keys(msg).forEach(function (key) {
      if (!isNaN(key)) {
        tmpBulkState[key] = tmpBulkState[key] ? tmpBulkState[key] : {}
        tmpBulkState[key][msg[key]] = msg.type ? msg.type : true
        if (msg.alertMessage) {
          tmpBulkState[key].alertMessage = msg.alertMessage
        }
      }
    })
    Object.keys(tmpBulkState).forEach(function (key) {
      if (!isNaN(key)) {
        if (tmpBulkState[key].saving) {
          saving[key] = true
          savingLength += 1
        }
        if (tmpBulkState[key].saved) {
          saved[key] = true
          savedLength += 1
        }
        if (tmpBulkState[key].failed) {
          failed[key] = tmpBulkState[key].failed
          failedLength += 1
          failedArray.push(
            JSON.parse(
              `{"id" :"${key}", "error":"${tmpBulkState[key].failed}"}`
            )
          )
        }
      }
    })

    tmpBulkState.failed = failedArray
    tmpBulkState.progress = parseInt((savedLength / savingLength) * 100)
    tmpBulkState.totals = {
      saved: savedLength,
      saving: savingLength,
      failed: failedLength,
      finished: savingLength - (savedLength + failedLength) === 0
    }
    this.setState({
      bulkAlertMessage: tmpBulkState,
      bulkAlertMessageActive: true
    })
  }

  setAlertMessage = (id, action, type, code, str) => {
    str = str || ""
    const newBulkEventSchedule = {
      [id]: action,
      alertMessage: str
    }
    if (type) {
      newBulkEventSchedule.type = type
    }
    if (code) {
      newBulkEventSchedule.type = code
    }
    this.processBulkMessage(newBulkEventSchedule)
  }

  handleCheckboxChange = event => {
    let num = 0
    const newMS = this.state.userMultiSelect
    newMS[event.target.value] = event.target.checked
    Object.values(newMS).forEach(itm => {
      if (itm === true) {
        num += 1
      }
    })
    this.setState({
      userMultiSelect: newMS,
      numMultiSelect: num
    })
  }

  handleTabChange = (event, newValue) => {
    if (!newValue) {
      this.props.backToOrgList()
      return
    }
    if (newValue === 2 && this.checkForChildOrgs(this.state.organization.id)) {
      const newOrg = {
        ...this.state.organization,
        propertyIsLoading: true
      }
      this.setState({
        organization: newOrg,
        currentView: newValue
      })
    } else {
      this.setState({
        currentView: newValue
      })
    }
  }
  handleChange(orgId) {
    if (this.props.onChange) {
      this.props.onChange(orgId)
    }
  }

  handleClose() {
    const newContract = {
      ...this.state.contract.allContracts[0],
      loadedContractInfo: true,
      contractContentExists: true,
      loadingContractForOrg: false,
      allContracts: this.state.contract.allContracts
    }
    this.setState({
      contract: newContract
    })
  }

  rememberChanges = pageSize => {
    this.setState({ pageSize })
    if (this.props.location) {
      let cookies = new Cookies()
      cookies.set("feedPageSize", pageSize, {
        path: this.props.location.pathname,
        expires: Helpers.CookieExpiration.OneMonth
      })
    }
  }

  handleOrgSave = () => {
    if (this.state.organization && this.state.organization.id > -2) {
      //We have an organization with id of -1, and the compare value used to be 0
      this.updateOrganization()
    } else {
      if (this.state.currentView === 1) {
        this.createOrganization()
      }
    }
  }
  handleOrgClose() {
    if (this.state.organization && this.state.organization.id > -2) {
      this.setState({ organization: this.props.organization })
    } else {
      this.props.backToOrgList()
    }
  }

  handleSave = () => {
    if (this.state.organization.id > -2) {
      //We have an organization with id of -1, and the compare value used to be 0
      this.updateOrganization()
      this.handleClose()
    } else {
      if (this.state.currentView === 1) {
        this.createOrganization()
      }
    }
  }

  handleCreateOrUpdateMilestone = newEntry => {
    const newMilestonesObject = {
      ...this.state.contractMilestones.allContractMilestonesAsObject
    }

    const existingMilestoneKey = Object.keys(newMilestonesObject).find(
      key => newEntry.id === newMilestonesObject[key].id
    )
    if (existingMilestoneKey) {
      newMilestonesObject[existingMilestoneKey] = {
        ...newMilestonesObject[existingMilestoneKey],
        ...newEntry
      }
    } else {
      const newKey = `${newEntry.name}:contract${newEntry.contractId}:milestone${newEntry.id}`
      newMilestonesObject[newKey] = newEntry
    }

    const newContractMilestones = {
      ...this.state.contractMilestones,
      loadedContractMilestoneInfo: true,
      contractMilestoneContentExists: true,
      allContractMilestonesAsObject: newMilestonesObject,
      contractMilestonesForOrgLoading: false
    }
    this.setState({
      contractMilestones: newContractMilestones
    })
  }

  handleNestedTabChange = (event, newValue) => {
    this.setState({ nestedValue: newValue })
  }

  updatePropertyLoadingState = val => {
    const { organization } = this.state
    const newOrg = {
      ...organization,
      propertyIsLoading: val
    }
    if (organization.propertyIsLoading !== val) {
      this.setState({ organization: newOrg })
    }
  }

  parseOrgStatus(isActive) {
    return isActive ? "Active" : "Inactive"
  }

  createOrganization = () => {
    const organization = this.state.organization
    const required = this.state.requiredOrgFields
    let fieldsNotFilled = false
    Object.keys(required).forEach(key => {
      required[key] = false
      if (!organization[key]) {
        fieldsNotFilled = true
        required[key] = true
      }
    })
    if (fieldsNotFilled) {
      this.setState({ requiredOrgFields: required })
      return
    }
    const newOrganization = {
      Name: this.state.organization.name,
      WebSiteUrl: this.state.organization.webSiteUrl,
      FeedType:
        this.state.organization.feedType ||
        "api_occidental.Models.Core.Feeds.FeedRSS",
      AuthorName: this.state.organization.authorName || "Data Skrive",
      AuthorUri: this.state.organization.authorURI || "https://dataskrive.com",
      FeedLimit: this.state.organization.feedLimit,
      FeedRights:
        this.state.organization.feedRights ||
        `© ${new Date().getFullYear()} Data Skrive. All rights reserved.`,
      CreatedByAccountId: this.props.account.id,
      ParentId: this.state.organization.parentId
    }
    const newOrg = {
      ...organization,
      updatingOrg: true
    }
    this.setState({ organization: newOrg })
    put(`${globalSettings.apiBaseUrl}/api/organization/create`, null, {
      ...newOrganization
    })
      .then(Response => Response && Response.ok === true && Response.json())
      .then(response => {
        let newOrgId =
          response.responseMessage &&
          response.responseMessage.match(/^Organization\s(\d*):/)
        if (newOrgId && typeof newOrgId === "object" && newOrgId[1]) {
          newOrgId = Number(newOrgId[1])
        }
        if (newOrgId != null && newOrgId !== 0) {
          const newOrg = {
            ...organization,
            updatingOrg: true
          }
          this.setState({
            orgUpdated: true,
            orgUpdatedMessage: response.responseMessage,
            justAddedOrganization: true,
            updatingOrg: false,
            organization: newOrg
          })
          this.handleChange(newOrgId)
        } else {
          const newOrg = {
            ...organization,
            updatingOrg: true
          }
          this.setState({
            orgUpdated: true,
            orgUpdatedMessage: response.responseMessage,
            updatingOrg: false,
            organization: newOrg
          })
          this.props.backToOrgList()
        }
      })
      .catch(err => {
        console.error(`Error on PUT to /api/organization/create`, err)
      })
  }

  updateOrganization = () => {
    const { organization } = this.state
    const {
      id,
      name,
      webSiteUrl,
      feedType,
      authorName,
      authorURI,
      feedLimit,
      feedRights,
      isActive,
      parentId
    } = organization

    const updatedOrganization = {
      Id: id,
      Name: name,
      WebSiteUrl: webSiteUrl,
      FeedType: feedType,
      AuthorName: authorName,
      AuthorUri: authorURI,
      FeedLimit: Number(feedLimit),
      FeedRights: feedRights,
      IsActive: isActive,
      ParentId: parentId,
      ModifiedByAccountId: this.props.account.id
    }
    const newOrg = {
      ...organization,
      updatingOrg: true
    }
    this.setState({ organization: newOrg })
    put(`${globalSettings.apiBaseUrl}/api/organization/update`, null, {
      ...updatedOrganization
    })
      .then(Response => Response && Response.ok === true && Response.json())
      .then(response => {
        let newOrgId =
          response.responseMessage &&
          response.responseMessage.match(/^Organization\s(\d*):/)
        if (newOrgId && typeof newOrgId === "object" && newOrgId[1]) {
          newOrgId = Number(newOrgId[1])
        }
        if (newOrgId != null && newOrgId !== 0) {
          const newOrganization = {
            ...organization,
            updatingOrg: false,
            id: newOrgId
          }
          this.setState({
            orgUpdated: true,
            orgUpdatedMessage: response.responseMessage,
            organization: newOrganization
          })
          this.handleChange(newOrgId)
        } else {
          const newOrganization = {
            ...organization,
            updatingOrg: false
          }
          this.setState({
            isAddingOrganization: false,
            organization: newOrganization,
            preview: response.responseMessage
          })
        }
      })
      .catch(err => {
        console.error(`Error on PUT to /api/organization/update`, err)
      })
  }
  addNewContract = () => {
    const newContract = {
      ...this.state.contract,
      currentContract: {
        addingContract: true,
        isActive: true,
        contractUrl: "",
        isArchived: false,
        inContract: false,
        licenseTermStart: "",
        licenseTermEnd: "",
        proservTermStart: "",
        autoRenewal: false,
        proservTermEnd: "",
        proservMonthlyTotal: "",
        licenseMonthlyTotal: "",
        includesDynamicImages: false,
        includesDynamicGraphics: false,
        includesSeoEnhancements: false,
        includesDeepLinking: false,
        addDeliverable: false,
        id: "",
        organizationId: this.state.organization.id,
        isLicense: false,
        isProservices: false,
        isAdRevShare: false,
        isRevShare: false,
        advertisingRevenueSplitPercentage: null,
        costPerActionRevenueSplitPercentage: null,
        isCostPerActionRevShare: false,
        socialMediaRevenueSplitPercentage: null,
        isSocialMediaRevShare: false,
        includesRankings: false,
        includesThirdPartyImage: false,
        accountContactFullName: this.state.contract.accountContactFullName,
        accountContactPhone: this.state.contract.accountContactPhone,
        accountContactAddress: this.state.contract.accountContactAddress,
        accountContactEmail: this.state.contract.accountContactEmail,
        contractName: this.state.organization.name,
        initializedFields: {
          contractName: true,
          msaUrl: true,
          msaDate: true,
          accountContactFullName: true,
          accountContactEmail: true,
          accountContactPhone: true,
          accountContactAddress: true,
          sportsDatasource1Id: true,
          bettingDatasource1Id: true
        }
      }
    }
    this.setState({ contract: newContract })
  }

  createDeliverables = () => {
    const newContract = {
      ...this.state.contract,
      addDeliverable: true
    }
    const newContractDeliverables = {
      ...this.state.contractDeliverables,
      contractId: this.state.contract.id,
      organizationId: this.state.organization.id
    }
    this.setState({
      contract: newContract,
      contractDeliverables: newContractDeliverables
    })
  }

  createMilestones = () => {
    const newContract = {
      ...this.state.contract,
      addMilestone: true
    }
    const newContractMilestones = {
      ...this.state.contractMilestones,
      contractId: this.state.contract.id,
      organizationId: this.state.organization.id,
      inContract: true
    }
    this.setState({
      contract: newContract,
      contractMilestones: newContractMilestones
    })
  }

  createContract = () => {
    const newContract = {
      ...this.state.contract,
      addingContract: true,
      isActive: true,
      licenseTermStart: "",
      licenseTermEnd: "",
      proservTermStart: "",
      proservTermEnd: "",
      licenseMonthlyTotal: "",
      id: "",
      organizationId: this.state.organization.id
    }
    this.setState({ contract: newContract })
  }
  deleteThisDeliverable = () => {}

  editThisDeliverable = row => {
    let index = Number(row.currentTarget.value)

    const newContract = {
      ...this.state.contract,
      deliverableToEdit: index,
      addDeliverable: true
    }
    this.setState({ contract: newContract })
  }

  addChildDeliverable = event => {
    let index = Number(event.currentTarget.value)

    const newContract = {
      ...this.state.contract,
      deliverableToEdit: index,
      childDeliverable: true,
      childOrganization: index
    }
    this.setState({ contract: newContract })
  }

  editThisMilestone = row => {
    let index = Number(row.currentTarget.value)

    let ifChildOrgId = Number(row.currentTarget.value)

    const newContract = {
      ...this.state.contract,
      milestoneToEdit: index,
      addMilestone: true,
      ifChildOrgId
    }
    this.setState({ contract: newContract })
  }

  checkForChildOrgs = id => {
    let children = []
    this.state.organizations.forEach(itm => {
      if (itm.parentId === id) {
        children.push(itm)
      }
    })
    return children
  }

  getLeagueNameDataFromIds = (leagueId, leagueRelatedIds) => {
    const {
      conferenceIds = [],
      divisionIds = [],
      teamIds = []
    } = leagueRelatedIds

    const league = this.props.leagueDetailsById[leagueId]

    return {
      leagueName: league?.name,
      conferenceNames: league?.conferences
        ?.filter(conference => !!conferenceIds?.includes(conference.id))
        .map(conference => conference.name),
      divisionNames: league?.divisions
        ?.filter(division => !!divisionIds?.includes(division.id))
        .map(division => division.name),
      teamNames: league?.teams
        ?.filter(team => !!teamIds?.includes(team.id))
        .map(team => team.name)
    }
  }

  getVerticalName = id => {
    let nm = ""
    this.props.verticals?.toJS().forEach(itm => {
      if (itm.id === id) {
        nm = itm.verticalName
      }
    })
    return nm
  }

  onShowArchivedBoxChecked = event => {
    let isChecked = event.target.checked
    const name = event.target.id
    this.setState({
      [name]: isChecked
    })
    const cookies = new Cookies()
    cookies.set(name, isChecked)
  }

  getSubjectTypeName = id => {
    let val = ""
    this.props.subjectTypes?.toJS().forEach(itm => {
      if (itm.id === id) {
        val = itm?.name
      }
    })
    return val
  }

  getCatalystTypeName = id => {
    let val = ""
    this.props.catalystTypes?.toJS().forEach(itm => {
      if (itm.id === id) {
        val = itm?.name
      }
    })
    return val
  }

  getPublishTypeName = id => {
    let val = ""
    this.props.publishTypes?.toJS().forEach(itm => {
      if (itm.id === id) {
        val = itm.name
      }
    })
    return val
  }

  filterAllContractDeliverablesObj = () => {
    const { contractDeliverables = {}, contract = {} } = this.state
    const { allContractDeliverablesAsObject = {} } = contractDeliverables

    const filteredData = Object.keys(allContractDeliverablesAsObject)
      .filter(
        key => allContractDeliverablesAsObject[key].contractId === contract?.id
      )
      .map(key => {
        const deliverable = allContractDeliverablesAsObject[key]
        const {
          subjectTypeId,
          catalystTypeId,
          publishTypeId,
          verticalId,
          leagueId,
          conferenceIds,
          divisionIds,
          teamIds
        } = deliverable

        const updatedDeliverable = {
          ...deliverable,
          subjectTypeName: this.getSubjectTypeName(subjectTypeId),
          catalystTypeName: this.getCatalystTypeName(catalystTypeId),
          publishTypeName: this.getPublishTypeName(publishTypeId),
          verticalName: this.getVerticalName(verticalId),
          ...this.getLeagueNameDataFromIds(leagueId, {
            conferenceIds,
            divisionIds,
            teamIds
          })
        }
        return updatedDeliverable
      })
    return filteredData
  }

  filterAllContractMilestonesObj = () => {
    const { contractMilestones, contract } = this.state
    let temp = []

    let myObj =
      (contractMilestones &&
        contractMilestones.allContractMilestonesAsObject) ||
      {}
    Object.keys(myObj).forEach(key => {
      if (contract?.id === myObj[key]?.contractId) {
        temp.push(myObj[key])
      }
    })
    return temp
  }

  render() {
    const { classes, datasources, bookmakers } = this.props
    const {
      accountsForTable,
      accountsNotInOrg,
      organization,
      organizations,
      currentView,
      contract,
      contractDeliverables,
      bulkAlertMessage,
      bulkAlertMessageActive,
      isAddingOrganization,
      numMultiSelect,
      selectAllUsers,
      userMultiSelect,
      nestedValue
    } = this.state

    const sportsData =
      datasources && datasources.filter(ds => ds.get("hasSports"))
    const bettingData =
      datasources && datasources.filter(ds => ds.get("hasBetting"))
    const imageData =
      datasources && datasources.filter(ds => ds.get("hasImages"))
    const bookmakerData = bookmakers && bookmakers

    const { allContracts, currentContract } = contract

    const { isAccountsLoading, formErrors } = this.state
    const { name, updatingOrg, propertyIsLoading } = this.state.organization

    const hasChildren = this.checkForChildOrgs(organization.id)

    const allContractDeliverables = this.filterAllContractDeliverablesObj()
    const allContractMilestones = this.filterAllContractMilestonesObj()

    const archivedMilestones = !!allContractMilestones.filter(
      itm => itm.isArchived
    ).length

    const activeParentOrganizations = organizations.filter(
      itm => itm.isActive === true && itm.parentId === null
    )
    activeParentOrganizations.sort(function (a, b) {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    })

    const ASSOCIATED_USER_ACCOUNTS_TAB = hasChildren.length ? 3 : 2
    const ADD_USER_TAB = hasChildren.length ? 4 : 3

    let bookmakerDataToJS = (bookmakerData && bookmakerData.toJS()) || []
    let imageDataToJS = (imageData && imageData.toJS()) || []
    let bettingDataToJS = (bettingData && bettingData.toJS()) || []
    let sportsDataToJS = (sportsData && sportsData.toJS()) || []

    bookmakerDataToJS.sort(function (a, b) {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    })
    imageDataToJS.sort(function (a, b) {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    })
    bettingDataToJS.sort(function (a, b) {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    })
    sportsDataToJS.sort(function (a, b) {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    })

    hasChildren.forEach(itm => {
      itm.bookmakers = []
      bookmakerDataToJS &&
        bookmakerDataToJS.forEach(obj => {
          if (obj.id === itm.bookmaker1Id) {
            itm.bookmakers.push(obj.name)
          }
          if (obj.id === itm.bookmaker2Id) {
            itm.bookmakers.push(obj.name)
          }
          if (obj.id === itm.bookmaker3Id) {
            itm.bookmakers.push(obj.name)
          }
          if (obj.id === itm.bookmaker4Id) {
            itm.bookmakers.push(obj.name)
          }
        })
      if (!itm.bookmakers[0]) {
        itm.bookmakers = null
      }
      imageDataToJS &&
        imageDataToJS.forEach(obj => {
          if (obj.id === itm.imageSource1Id) {
            itm.imageSource = obj.name
          }
        })
      bettingDataToJS &&
        bettingDataToJS.forEach(obj => {
          if (obj.id === itm.bettingDatasource1Id) {
            itm.bettingDatasource = obj.name
          }
        })
      sportsDataToJS &&
        sportsDataToJS.forEach(obj => {
          if (obj.id === itm.sportsDatasource1Id) {
            itm.sportsDatasource = obj.name
          }
        })
    })

    const hasWindowObject = typeof window !== "undefined"
    const screenWidth = hasWindowObject ? window.innerWidth : null
    const screenHeight = hasWindowObject ? window.innerHeight : null

    const showPagination =
      screenWidth && screenHeight && (screenHeight > 959 || screenWidth > 959)

    return (
      <React.Fragment>
        {currentView === ADD_USER_TAB &&
          (numMultiSelect > 0 || selectAllUsers) && (
            <DialogActions
              style={{
                marginBottom: "15px",
                marginRight: "70px",
                marginTop: "-44px",
                right: "120px",
                position: "fixed",
                zIndex: 999
              }}
            >
              <Button
                onClick={() => this.handleMultiUserClose()}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={() => this.handleMultiUserSave()}
                color="primary"
              >
                {numMultiSelect > 1 ? "Add Selected Users" : "Add User"}
              </Button>
            </DialogActions>
          )}
        {!this.props.isAddingOrganization && (
          <div className={classes.tabScrollRoot}>
            <AppBar position="static" color="default" enableColorOnDark>
              <Tabs
                value={currentView}
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                onChange={this.handleTabChange}
                aria-label="Organization Tabs"
                style={{
                  _minWidth: "1050px"
                }}
              >
                <Tab label="Back to Organization List" />
                <Tab label="Organization Editor" />
                {hasChildren.length > 0 && (
                  <Tab label="Associated Properties" />
                )}
                <Tab label="Associated User Accounts" />
                <Tab label="Add User(s)" />
              </Tabs>
            </AppBar>
          </div>
        )}

        <TabPanel value={currentView} index={1}>
          <OrganizationEditor
            isNowLoading={this.props.isNowLoading}
            isAddingOrganization={isAddingOrganization}
            organization={this.state.organization}
            organizations={this.state.organizations}
            onChange={orgId => this.handleChange(orgId)}
            bookmakerData={bookmakerDataToJS}
            datasourcesByOrg={this.props.datasourcesByOrg}
            bookmakersByOrg={this.props.bookmakersByOrg}
            imageData={imageDataToJS}
            bettingData={bettingDataToJS}
            sportsData={sportsDataToJS}
            backToOrgList={create => {
              this.props.backToOrgList(create)
            }}
            onClose={() => {
              this.setState({
                isEditOrganizationDisplayed: false,
                isAddingProperty: false
              })
            }}
            setUpdatedOrganization={updatedOrg =>
              this.setState({ organization: updatedOrg })
            }
            userId={this.props.account.id}
          />

          {updatingOrg && (
            <Grid spacing={1} container style={{ padding: "5px 10px" }}>
              <Grid xs item>
                <div style={{ width: "100%", height: "20px" }}>
                  <LinearProgress />
                </div>
              </Grid>
            </Grid>
          )}
          <div>
            {!contract.contractContentExists &&
              !contract.addingContract &&
              !isAddingOrganization &&
              contract.loadedContractInfo && (
                <Grid
                  style={{
                    display: "flex",
                    textAlign: "center",
                    marginTop: "15px"
                  }}
                >
                  <GridItem xs={12} sm={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        if (
                          !this.state.organization.msaDate ||
                          !this.state.organization.msaUrl
                        ) {
                          this.setState({ cantCreateContract: true })
                        } else {
                          this.createContract("new")
                        }
                      }}
                      startIcon={<Add />}
                      disabled={
                        (!this.state.organization.msaDate ||
                          !this.state.organization.msaUrl) &&
                        this.state.cantCreateContract
                      }
                    >
                      Create Contract
                    </Button>
                    {(!this.state.organization.msaDate ||
                      !this.state.organization.msaUrl) &&
                      this.state.cantCreateContract && (
                        <FormHelperText
                          className={classes.requiredMsg}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#fff"
                          }}
                        >
                          MSA Date and MSA URL are required in the DB (saved)
                          prior to adding a contract
                        </FormHelperText>
                      )}
                  </GridItem>
                </Grid>
              )}
            {(contract.contractContentExists || contract.addingContract) &&
              contract.loadedContractInfo && (
                <>
                  <Grid
                    style={{
                      display: "flex",
                      textAlign: "center",
                      marginTop: "15px"
                    }}
                  >
                    <GridItem xs={12} sm={4}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.addNewContract()}
                        startIcon={<Add />}
                      >
                        Create New Contract
                      </Button>
                    </GridItem>

                    {allContracts && allContracts.length > 1 && (
                      <GridItem xs={12} sm={4}>
                        <FormControl
                          margin="normal"
                          style={{
                            minWidth: "200px",
                            marginTop: "1px",
                            marginLeft: "10px"
                          }}
                        >
                          <InputLabel>
                            {name
                              ? `${name} Contracts`
                              : "Contracts for this Account"}
                          </InputLabel>
                          <Select
                            fullWidth={false}
                            type="select"
                            name="allContracts"
                            id="allContracts"
                            value={allContracts.indexOf(currentContract)}
                            onChange={e => {
                              const newContract = {
                                ...allContracts[e.target.value],
                                loadedContractInfo: true,
                                contractContentExists: true,
                                allContracts: contract.allContracts,
                                currentContract: allContracts[e.target.value],
                                id: allContracts[e.target.value].id
                              }
                              this.setState({
                                contract: newContract
                              })
                              this.loadContractDeliverablesForContract(
                                allContracts[e.target.value].id
                              )
                              this.loadContractMilestonesForContract(
                                allContracts[e.target.value].id
                              )
                            }}
                          >
                            {allContracts &&
                              allContracts.map((allContracts, i) => (
                                <MenuItem key={i} value={i}>
                                  {allContracts.contractName} -{" "}
                                  {allContracts.licenseTermStart} to{" "}
                                  {allContracts.licenseTermEnd}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                    )}
                  </Grid>
                  {currentContract && (
                    <ContractDetails
                      contract={currentContract}
                      formErrors={formErrors}
                      saveContractForOrg={myContract => {
                        this.saveContractForOrg(myContract)
                      }}
                      organization={this.state.organization}
                    />
                  )}
                </>
              )}
          </div>
          <div>
            <Card className={classes.deliverablesCard}>
              <Tabs
                value={nestedValue}
                onChange={this.handleNestedTabChange}
                textColor="primary"
                className={classes.deliverablesTab}
              >
                <Tab label="Deliverables" />
                <Tab label="Milestones" />
              </Tabs>
              <TabPanel value={nestedValue} index={0}>
                {contract.allContracts && contract.allContracts.length > 0 && (
                  <Grid
                    style={{
                      display: "flex",
                      marginTop: "15px"
                    }}
                  >
                    <GridItem xs={12} sm={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.createDeliverables()}
                        startIcon={<Add />}
                        className={classes.deliverablesButton}
                      >
                        Add Deliverable
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      <UploadDeliverables
                        uploadOfDeliverablesSucceeded={() => {
                          this.loadContractDeliverablesForContract(
                            this.state.contract.id
                          )
                        }}
                        contractId={this.state.contract.id}
                        organizationName={this.state.organization.name}
                        createdByAccountId={this.props.account.id}
                        classes={classes}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      {" "}
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                      <Checkbox
                        id="showArchivedDeliverables"
                        checked={this.state.showArchivedDeliverables}
                        onChange={this.onShowArchivedBoxChecked}
                      />{" "}
                      Include Archived?
                    </GridItem>
                  </Grid>
                )}
                <ContractDeliverablesTable
                  contractDeliverablesData={allContractDeliverables}
                  catalystTypeData={this.props.catalystTypes}
                  catalystTypes={this.props.catalystTypes?.toJS()}
                  publishTypes={this.props.publishTypes?.toJS()}
                  subjectTypes={this.props.subjectTypes?.toJS()}
                  leagues={this.props.leagues}
                  getLeagueNameDataFromIds={this.getLeagueNameDataFromIds}
                  getSubjectTypeName={this.getSubjectTypeName}
                  getCatalystTypeName={this.getCatalystTypeName}
                  catalystTypeId={this.props.catalystTypeId}
                  organizationId={this.props.organizationId}
                  contractId={this.props.contractId}
                  hasChildren={this.checkForChildOrgs(organization.id)}
                  showArchivedDeliverables={this.state.showArchivedDeliverables}
                  verticals={this.props.verticals}
                />
              </TabPanel>

              <TabPanel value={nestedValue} index={0}>
                {this.state.contractDeliverables &&
                  this.state.contractDeliverables
                    .contractDeliverablesForOrgLoading && (
                    <div
                      className={classes.formExtrasContainer}
                      style={{ padding: "30px" }}
                    >
                      <div className={classes.loading}>
                        <LinearProgress />
                      </div>
                    </div>
                  )}
              </TabPanel>
              <TabPanel value={nestedValue} index={1}>
                <Grid
                  style={{
                    display: "flex",
                    textAlign: "center",
                    marginTop: "15px"
                  }}
                >
                  <GridItem xs={12} sm={2}>
                    <DialogTitle
                      style={{ paddingBottom: "0px", textAlign: "center" }}
                      className={classes.myFlexFontSize}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.createMilestones()}
                        startIcon={<Add />}
                        disabled={!contract?.id}
                        className={classes.deliverablesButton}
                      >
                        Add Milestones
                      </Button>
                    </DialogTitle>
                  </GridItem>
                  <GridItem xs={12} sm={8}>
                    {" "}
                  </GridItem>
                  <GridItem xs={12} sm={2}>
                    <Checkbox
                      id="showArchivedMilestones"
                      checked={this.state.showArchivedMilestones}
                      onChange={this.onShowArchivedBoxChecked}
                    />{" "}
                    Include Archived?
                  </GridItem>
                </Grid>
                {allContractMilestones && allContractMilestones.length > 0 && (
                  <div
                    className={classes.formExtrasContainer}
                    style={{ marginTop: "10px" }}
                  >
                    <ReactTable
                      minRows={0}
                      data={
                        this.state.showArchivedMilestones
                          ? allContractMilestones
                          : allContractMilestones.filter(itm => !itm.isArchived)
                      }
                      defaultSorted={[{ id: "id", desc: true }]}
                      noDataText={
                        isAccountsLoading ? (
                          <CircularProgress />
                        ) : (
                          "No accounts found."
                        )
                      }
                      columns={[
                        {
                          __Header: "Id",
                          accessor: "id",
                          sortable: false,
                          filterable: false,
                          width: 50,
                          Cell: () => <span />
                        },
                        {
                          Header: "Name of Milestone",
                          accessor: "name",
                          width: 250,
                          ...stringFilterProps
                        },
                        {
                          Header: "Milestone Date",
                          accessor: "milestoneDate",
                          width: 170,
                          ...stringFilterProps
                        },
                        {
                          Header: "In Contract?",
                          accessor: "inContract",
                          width: 120,
                          className: classnames("no-wrap", "center"),
                          Cell: ({ row }) => (
                            <>
                              <div>
                                {row._original.inContract ? "YES" : "NO"}
                              </div>
                            </>
                          ),
                          sortable: true,
                          ...stringFilterProps
                        },
                        {
                          Header: "Milestone Description",
                          accessor: "description",
                          ...stringFilterProps
                        },
                        {
                          width: 100,
                          sortable: false,
                          filterable: false,
                          className: classnames("no-wrap", "center"),
                          Cell: ({ row }) => (
                            <Button
                              variant="outlined"
                              color="primary"
                              value={row.id}
                              onClick={row => {
                                this.editThisMilestone(row)
                              }}
                            >
                              Edit
                            </Button>
                          )
                        }
                      ]}
                      defaultPageSize={25}
                      showPaginationTop={false}
                      showPaginationBottom={
                        showPagination &&
                        allContractMilestones &&
                        allContractMilestones.length > 24
                      }
                      className="-striped -highlight -scrollEntries"
                      style={{
                        maxHeight: `${(window.innerHeight * 0.75).toFixed(0)}px`
                      }}
                      getTrProps={(state, row) => {
                        if (row) {
                          let returnObject = { style: {} }
                          if (row.original.isArchived) {
                            returnObject.style.textDecoration = "line-through"
                          }
                          return returnObject
                        } else {
                          return {}
                        }
                      }}
                    />
                  </div>
                )}
                <div>
                  {archivedMilestones && this.state.showArchivedMilestones && (
                    <div xs={3} sm={3} md={3}>
                      <div className={classes.archiveMessage}>
                        'line-through = Archive Status'
                      </div>
                    </div>
                  )}
                </div>
              </TabPanel>
            </Card>
          </div>
        </TabPanel>

        <TabPanel value={currentView} index={2}>
          {(propertyIsLoading || false) && (
            <div className={classes.loading}>
              <LinearProgress />
            </div>
          )}
          {hasChildren.length > 0 && (
            <PropertiesManager
              isNowLoading={this.updatePropertyLoadingState}
              datasourcesByOrg={this.props.datasourcesByOrg}
              bookmakersByOrg={this.props.bookmakersByOrg}
              bookmakerData={bookmakerDataToJS}
              imageData={imageDataToJS}
              bettingData={bettingDataToJS}
              sportsData={sportsDataToJS}
              currentParent={organization.id}
            />
          )}
        </TabPanel>

        {organization.id && !isAddingOrganization && (
          <>
            <TabPanel value={currentView} index={ASSOCIATED_USER_ACCOUNTS_TAB}>
              <DialogTitle
                style={{ paddingBottom: "0px" }}
                className={classes.myFlexFontSize}
              >
                Associated User Accounts {name && ` (${name})`}
              </DialogTitle>
              <ReactTable
                loading={isAccountsLoading}
                minRows={0}
                data={accountsForTable}
                defaultSorted={[{ id: "id", desc: true }]}
                noDataText={
                  isAccountsLoading ? (
                    <CircularProgress />
                  ) : (
                    "No accounts found."
                  )
                }
                columns={[
                  {
                    Header: "Id",
                    accessor: "id",
                    width: 40,
                    ...stringFilterProps
                  },
                  {
                    Header: "User Name",
                    accessor: "username",
                    ...stringFilterProps
                  },
                  {
                    Header: "First Name",
                    accessor: "firstName",
                    ...stringFilterProps
                  },
                  {
                    Header: "Last Name",
                    accessor: "lastName",
                    ...stringFilterProps
                  },
                  {
                    Header: "Email",
                    accessor: "email",
                    ...stringFilterProps
                  },
                  {
                    Header: "Org",
                    accessor: "organization.name"
                  },
                  {
                    Header: "Account Status",
                    accessor: "status"
                  },
                  {
                    Header: "Updated",
                    accessor: "modifiedDateTime",
                    width: 100,
                    className: classnames("no-wrap", "center"),
                    Cell: ({ value }) => <FormattedDate>{value}</FormattedDate>
                  },
                  {
                    Cell: ({ row }) => (
                      <div className="actions-left">
                        <Tooltip title="Remove User" placement="top">
                          <Button
                            justIcon
                            round
                            simple
                            color="primary"
                            className="primary"
                            onClick={() => this.removeUser(row.id)}
                          >
                            <DeleteIcon />
                          </Button>
                        </Tooltip>
                      </div>
                    ),
                    sortable: false,
                    filterable: false,
                    width: 40
                  }
                ]}
                pageSize={this.pageSize}
                defaultPageSize={this.pageSize}
                showPaginationTop={showPagination && true}
                showPaginationBottom={false}
                onPageChange={page => this.rememberChanges(this.pageSize, page)}
                onPageSizeChange={(pageSize, page) => {
                  this.rememberChanges(pageSize, page)
                }}
                className="-striped -highlight -scrollEntries"
                style={{
                  maxHeight: !showPagination
                    ? "auto"
                    : `${(window.innerHeight * 0.68).toFixed(0)}px`
                }}
              />
            </TabPanel>
            <TabPanel value={currentView} index={ADD_USER_TAB}>
              <Grid>
                <DialogTitle
                  style={{ paddingBottom: "0px" }}
                  className={classes.myFlexFontSize}
                >
                  Add a User(s) to{name && ` (${name})`}
                </DialogTitle>
              </Grid>
              <ReactTable
                minRows={0}
                data={accountsNotInOrg}
                defaultSorted={[{ id: "id", desc: true }]}
                columns={[
                  {
                    Header: () => (
                      <React.Fragment>
                        <Tooltip title="Select All" placement="top">
                          <Checkbox
                            type="checkbox"
                            className="checkbox"
                            checked={selectAllUsers}
                            onChange={e => {
                              this.handleSelectAllUsers(e)
                            }}
                          />
                        </Tooltip>
                      </React.Fragment>
                    ),
                    Cell: ({ row }) => (
                      <div className="actions-left">
                        <Tooltip title="Select User" placement="top">
                          <Checkbox
                            type="checkbox"
                            className="checkbox"
                            checked={userMultiSelect[row.id] || selectAllUsers}
                            value={row.id}
                            onChange={e => {
                              this.handleCheckboxChange(e)
                              row.isChecked = !row.isChecked
                            }}
                          />
                        </Tooltip>
                      </div>
                    ),
                    ...stringFilterProps,
                    sortable: false,
                    filterable: false,
                    width: 100
                  },
                  {
                    Header: "Id",
                    accessor: "id",
                    width: 50,
                    ...stringFilterProps
                  },
                  {
                    Header: "User Name",
                    accessor: "username",
                    ...stringFilterProps
                  },
                  {
                    Header: "First Name",
                    accessor: "firstName",
                    ...stringFilterProps
                  },
                  {
                    Header: "Last Name",
                    accessor: "lastName",
                    ...stringFilterProps
                  },
                  {
                    Header: "Email",
                    accessor: "email",
                    ...stringFilterProps
                  },
                  {
                    Header: "Updated",
                    accessor: "modifiedDateTime",

                    ...stringFilterProps,
                    Cell: ({ value }) => <FormattedDate>{value}</FormattedDate>
                  },
                  {
                    Header: "Add",
                    accessor: "modifiedDateTime",
                    ...stringFilterProps,
                    Cell: ({ row }) => (
                      <Button
                        variant="outlined"
                        color="primary"
                        value={row.id}
                        onClick={e => this.addThisUser(e)}
                      >
                        Add
                      </Button>
                    )
                  }
                ]}
                defaultPageSize={this.pageSize}
                showPaginationTop={false}
                showPaginationBottom={showPagination && true}
                onPageChange={page => this.rememberChanges(this.pageSize, page)}
                onPageSizeChange={(pageSize, page) => {
                  this.rememberChanges(pageSize, page)
                }}
                className="-striped -highlight -scrollEntries"
                style={{
                  maxHeight: !showPagination
                    ? "auto"
                    : `${(window.innerHeight * 0.68).toFixed(0)}px`
                }}
              />
            </TabPanel>
          </>
        )}

        {bulkAlertMessageActive && (
          <div id="myProgressParagraph" className={classes.progressBar}>
            {bulkAlertMessage.progress === 100 && (
              <div className={classes.finishedMsg}>Actions completed!</div>
            )}
            {bulkAlertMessage.progress !== 100 &&
              bulkAlertMessage.totals.finished && (
                <div
                  className={classes.failedListMsg}
                  onClick={() => {
                    this.setState({
                      bulkAlertErrorMessageActive: true
                    })
                  }}
                >
                  Completed with errors
                </div>
              )}
            {bulkAlertMessage.progress !== 100 &&
              !bulkAlertMessage.totals.finished && (
                <div className={classes.progressingMsg}>
                  Actions in progress...
                  <br />
                  {bulkAlertMessage.totals.saved} of{" "}
                  {bulkAlertMessage.totals.saving}
                  {bulkAlertMessage.totals.saving && <br />}
                </div>
              )}
            <Box display="flex" alignItems="center" width="100%">
              <Box width="100%" mr={1}>
                <LinearProgress
                  variant="determinate"
                  value={bulkAlertMessage.progress || 0}
                />
              </Box>
              <Box minWidth={35}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ fontWeight: "bold" }}
                >
                  {bulkAlertMessage.progress}%
                </Typography>
              </Box>
            </Box>
            {bulkAlertMessage.totals.failed > 0 &&
              !bulkAlertMessage.totals.finished && (
                <div className={classes.failedMsg}>
                  {bulkAlertMessage.totals.failed} failed action
                  {bulkAlertMessage.totals.failed > 1 ? "s" : ""}
                </div>
              )}
          </div>
        )}
        {bulkAlertMessage.progress !== 100 &&
          bulkAlertMessage.totals &&
          bulkAlertMessage.totals.finished && (
            <Modal
              open={bulkAlertMessage.totals.finished}
              onClose={() => {
                this.setState({
                  bulkAlertMessage: {},
                  bulkAlertMessageActive: false
                })
              }}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div className={classes.errorModal}>
                <h3 id="server-modal-title">Here are the failed requests</h3>
                <div id="server-modal-description">
                  <ul>
                    {bulkAlertMessage.failed &&
                      bulkAlertMessage.failed.map((n, index) => (
                        <li key={index}>
                          ID #{n.id} --- {n.error} error
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </Modal>
          )}
        {bulkAlertMessage.progress === 100 && (
          <Modal
            open={bulkAlertMessage.progress}
            onClose={() => {
              this.setState({
                bulkAlertMessage: {},
                userMultiSelect: {},
                bulkAlertMessageActive: false,
                selectAllUsers: false
              })
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className={classes.successModal}>
              <h2 id="server-modal-title">Success!</h2>
              <div id="server-modal-description">
                <p>
                  {bulkAlertMessage.totals.saving === 1
                    ? "Your action was "
                    : bulkAlertMessage.totals.saving === 2
                    ? "Both actions were "
                    : `All ${bulkAlertMessage.totals.saving} actions were `}
                  executed correctly
                </p>
                <ul>
                  {bulkAlertMessage &&
                    Object.keys(bulkAlertMessage)
                      ?.filter(key => !isNaN(key))
                      .map(key => (
                        <li key={key}>{bulkAlertMessage[key].alertMessage}</li>
                      ))}
                </ul>
              </div>
            </div>
          </Modal>
        )}

        {this.state.orgUpdated && (
          <Modal
            open={this.state.orgUpdated}
            onClose={() => {
              if (this.state.justAddedOrganization) {
                this.setState({
                  orgUpdated: false,
                  orgUpdatedMessage: "",
                  justAddedOrganization: false
                })
                this.props.backToOrgList()
              } else {
                this.setState({
                  orgUpdated: false,
                  orgUpdatedMessage: ""
                })
              }
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className={classes.successModal}>
              <h2 id="server-modal-title">Success!</h2>
              <div id="server-modal-description">
                {this.state.orgUpdatedMessage}
              </div>
            </div>
          </Modal>
        )}

        {contract.newContractEntryCreated && !contract.requestFailed && (
          <Modal
            open={true}
            onClose={() => {
              this.loadContractForOrg(true)

              const newContract = {
                ...contract,
                newContractEntryCreated: false,
                requestFailed: false,
                disableSaveButton: false
              }
              this.setState({ contract: newContract })
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className={classes.successModal}>
              <h2 id="server-modal-title">Success!</h2>
              <div id="server-modal-description">
                <p>{contract.newContractMessage}</p>
              </div>
            </div>
          </Modal>
        )}
        {contract.newContractEntryCreated && contract.requestFailed && (
          <Modal
            open={true}
            onClose={() => {
              const newContract = {
                ...contract,
                newContractEntryCreated: false,
                requestFailed: false,
                disableSaveButton: false
              }
              this.setState({ contract: newContract })
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className={classes.errorModal}>
              <h2 id="server-modal-title">Failed!</h2>
              <div id="server-modal-description">
                <p>{contract.newContractMessage}</p>
                <p>Error Message in the Response is:</p>
                <ul>
                  <li>{contract.responseMessage}</li>
                </ul>
              </div>
            </div>
          </Modal>
        )}
        {contract.addDeliverable && (
          <Dialog
            isOpen={contract.addDeliverable}
            title={
              contract.deliverableToEdit
                ? "Edit Deliverable"
                : "Create A New Deliverable"
            }
            icon={<Assignment />}
            onClose={() => {
              let newContract = {
                ...contract,
                addDeliverable: false,
                deliverableToEdit: null
              }
              this.setState({ contract: newContract })
            }}
            style={{ margin: "0 100px" }}
          >
            <Card style={{ marginTop: "0" }}>
              <CardBody>
                <ContractDeliverable
                  createMode={!contract.deliverableToEdit}
                  deliverables={contract}
                  hasChildren={hasChildren}
                  editDeliverable={allContractDeliverables}
                  deliverableToEdit={contract.deliverableToEdit}
                  articleTypes={contractDeliverables.articleTypes}
                  catalystTypes={this.props.catalystTypes}
                  subjectTypes={this.props.subjectTypes}
                  publishTypes={this.props.publishTypes}
                  organizations={organizations}
                  contracts={allContracts}
                  contractId={contract.id}
                  org={organization}
                  verticals={this.props.verticals}
                  getVerticalName={this.getVerticalName}
                  createdByAccountId={this.props.account.id}
                  handleAction={() => {
                    const newContract = {
                      ...contract,
                      addDeliverable: false,
                      deliverableToEdit: null
                    }
                    this.setState({
                      contract: newContract
                    })
                  }}
                  handleCopyDeliverable={() =>
                    this.loadContractDeliverablesForContract()
                  }
                  handleNewDeliverable={newEntry => {
                    let temp =
                      contractDeliverables.allContractDeliverablesAsObject || {}

                    let edit = false

                    let newKey = ""
                    if (Object.keys(temp).length) {
                      Object.keys(temp).forEach(key1 => {
                        //Make sure we edit existing items, not add new ones whne we edit
                        if (newEntry.id === temp[key1].id) {
                          edit = true
                          newKey = key1
                          Object.keys(newEntry).forEach(key => {
                            temp[key1][key] = newEntry[key]
                          })
                        }
                      })
                    }
                    if (!edit) {
                      newKey = `${newEntry.name}:contract${newEntry.contractId}:deliverable${newEntry.id}`
                      temp[newKey] = newEntry
                    }
                    let arr = []
                    if (Object.keys(temp).length) {
                      Object.keys(temp).forEach(key => {
                        arr.push(temp[key])
                      })
                    }
                    const newContractDeliverables = {
                      ...contractDeliverables,
                      loadedContractDeliverableInfo: true,
                      contractDeliverableContentExists: true,
                      deliverablesForContract: temp,
                      allContractDeliverables: arr,
                      contractDeliverablesForOrgLoading: false
                    }
                    this.setState({
                      contractDeliverables: newContractDeliverables
                    })
                  }}
                />
              </CardBody>
            </Card>
          </Dialog>
        )}

        {contract.addMilestone && (
          <Dialog
            isOpen={contract.addMilestone}
            title={
              contract.milestoneToEdit
                ? "Edit Milestones"
                : "Create A New Milestones"
            }
            __icon={<Assignment />}
            icon={<Milestone height={24} width={24} fillColor={"#fff"} />}
            onClose={() => {
              let newContract = {
                ...contract,
                addMilestone: false,
                milestoneToEdit: null
              }
              this.setState({ contract: newContract })
            }}
          >
            <Card>
              <CardBody>
                <ContractMilestone
                  contractId={contract?.id || null}
                  hasChildren={hasChildren}
                  editMilestone={allContractMilestones.find(
                    m => m.id === contract.milestoneToEdit
                  )}
                  createdByAccountId={this.props.account.id}
                  handleCloseForm={() => {
                    let newContract = {
                      ...contract,
                      addMilestone: false,
                      milestoneToEdit: null
                    }
                    this.setState({
                      contract: newContract
                    })
                  }}
                  handleCreateMilestone={this.handleCreateOrUpdateMilestone}
                />
              </CardBody>
            </Card>
          </Dialog>
        )}
      </React.Fragment>
    )
  }
}

OrganizationEditorForTabs.propTypes = {
  isOpen: PropTypes.bool,
  isAddingOrganization: PropTypes.bool,
  isNowLoading: PropTypes.bool,
  onChange: PropTypes.func,
  backToOrgList: PropTypes.func,
  onClose: PropTypes.func,
  classes: PropTypes.object,
  organization: PropTypes.object,
  datasourcesByOrg: PropTypes.object,
  bookmakersByOrg: PropTypes.object,
  organizations: PropTypes.array,
  notChildOrgs: PropTypes.array,
  allAccounts: PropTypes.array,
  datasources: PropTypes.object,
  bookmakers: PropTypes.object,
  catalystTypes: PropTypes.array
}

export default withStyles(styles)(useStore(OrganizationEditorForTabs))
