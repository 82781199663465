import React, { Component } from "react"
import Button from "components/CustomButtons/Button"
import InputLabel from "@mui/material/InputLabel"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardIcon from "components/Card/CardIcon.jsx"
import Power from "@mui/icons-material/Power"
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle"
import withStyles from "@mui/styles/withStyles"
import { globalSettings } from "variables/general"
import Check from "@mui/icons-material/Check"
import { Select, MenuItem, Checkbox } from "@mui/material"
import { HubConnectionBuilder } from "@microsoft/signalr"
import Helpers from "tools/Helpers.js"
import TimeAgo from "react-timeago"
import { observer, inject } from "mobx-react"
import TextField from "@mui/material/TextField"
import { get } from "tools/request"
import AccountDAO from "daos/accountDAO"

const useStore = component =>
  inject(({ store }) => ({
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization,
    account: store.accountStore.account
  }))(observer(component))

class WebSocketTool extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentConnectionId: "",
      currentConnectionStartDateTime: null,
      toConnectionId: "",
      toUserId: "",
      message: "",
      webSocketConnection: {},
      messagesReceived: "",
      isSendButtonEnabled: false,
      connectedUsers: [],
      connectedClients: [],
      isInPortalNotificationMessage: false
    }
  }

  componentDidMount() {
    this.props.setShowChangeCurrentOrganization(false)
    // This is Microsoft's SignalR connection pattern
    // First HTTP request is to: https://localhost:5001/api/ws/portalhub/negotiate?negotiateVersion=1
    this.establishWebSocketConnection()
  }

  displayReceivedMessage = (sendingUser, receivingUser, message) => {
    this.setState({
      messagesReceived: `${
        this.state.messagesReceived
      }From: ${sendingUser} To: ${receivingUser} says: ${message} - ${Helpers.prettyDateTimeinPacificTimeZone(
        new Date()
      )}\n`
    })
  }

  establishWebSocketConnection = async () => {
    // https://docs.microsoft.com/en-us/javascript/api/@aspnet/signalr/?view=signalr-js-latest
    let accessToken = async () => {
      await AccountDAO.wsTokenCheck()
      return localStorage.getItem("accessToken")
    }
    let newConnection = new HubConnectionBuilder()
      .withUrl(`${globalSettings.apiBaseUrl}/api/ws/portalhub`, {
        accessTokenFactory: async () => await accessToken()
      })
      .withAutomaticReconnect()
      .build()
    newConnection.serverTimeoutInMilliseconds = 30000
    newConnection.on("receiveMessageByEveryClient", (message, fromUserId) => {
      this.displayReceivedMessage(fromUserId, `Everyone`, message)
    })
    newConnection.on("receiveMessageByClient", (message, fromUserId) => {
      this.displayReceivedMessage(fromUserId, "you", message)
    })
    newConnection.on("receiveMessageByUser", (message, fromUserId) => {
      this.displayReceivedMessage(fromUserId, "you", message)
    })
    newConnection.onclose(data => {})
    newConnection.onreconnecting(data => {})
    newConnection.onreconnected(data => {
      this.setState({
        currentConnectionId: data,
        currentConnectionStartDateTime: new Date()
      })
    })
    await newConnection.start()
    this.setState({
      webSocketConnection: newConnection,
      currentConnectionId: newConnection.connectionId,
      currentConnectionStartDateTime: new Date()
    })
  }

  onSendMessageToEveryClientButtonClick = () => {
    // Not sure if there is a connection state enum
    if (this.state.webSocketConnection.state !== "Connected") {
      this.state.webSocketConnection.start()
    }
    this.state.webSocketConnection
      .invoke(
        "SendMessageToEveryClient",
        this.state.message,
        this.props.account.username
      )
      .then(this.setState({ message: "" }))
      .catch(err => {
        console.error(`Error on send to every click button click`, err)
      })
  }

  onSendMessageToUserIdButtonClick = () => {
    if (this.state.isInPortalNotificationMessage) {
      this.state.webSocketConnection
        .invoke(
          "SendInPortalToastNotificationToUser",
          this.state.toUserId,
          this.state.message
        )
        .then(this.setState({ message: "" }))
        .catch(err => {
          console.error(
            `Error invoking SendInPortalToastNotificationToUser`,
            err
          )
        })
    } else {
      this.state.webSocketConnection
        .invoke(
          "SendMessageToUser",
          this.state.toUserId,
          this.state.message,
          this.props.account.username
        )
        .then(this.setState({ message: "" }))
        .catch(err => {
          console.error(`Error invoking SendMessageToUser`, err)
        })
    }
  }

  onSendMessageToClientButtonClick = () => {
    this.state.webSocketConnection
      .invoke(
        "SendMessageToClient",
        this.state.toConnectionId,
        this.state.message,
        this.props.account.username
      )
      .then(this.setState({ message: "" }))
      .catch(err => console.error(`Error invoking SendMessageToClient`, err))
  }

  onToConnectionIdChange = event => {
    this.setState({
      toConnectionId: event.target.value
    })
  }

  onUserChange = event => {
    this.setState({
      toUserId: event.target.value
    })
  }

  onMessageChange = event => {
    this.setState({
      message: event.target.value
    })
  }

  onInPortalNotificationChange = () => {
    this.setState({
      isInPortalNotificationMessage: !this.state.isInPortalNotificationMessage
    })
  }

  onConnectedUsersFocus = () => {
    this.loadConnectedUsers()
  }
  onConnectedClientsFocus = () => {
    this.loadConnectedClients()
  }

  loadConnectedUsers() {
    get(`${globalSettings.apiBaseUrl}/api/ws/portalhub/listallconnectedusers`)
      .then(Response => Response.json())
      .then(jsonData => {
        this.setState({
          connectedUsers: jsonData.content || []
        })
      })
  }

  loadConnectedClients() {
    get(`${globalSettings.apiBaseUrl}/api/ws/portalhub/listallconnectedclients`)
      .then(Response => Response.json())
      .then(jsonData => {
        this.setState({
          connectedClients: jsonData.content || []
        })
      })
  }

  render() {
    const { classes } = this.props
    const connectedUsersMenuItems = this.state.connectedUsers.map(item => (
      <MenuItem
        key={item}
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected
        }}
        value={item}
      >
        {item}
      </MenuItem>
    ))
    const connectedClientsMenuItems = this.state.connectedClients.map(item => (
      <MenuItem
        key={item}
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected
        }}
        value={item}
      >
        {item}
      </MenuItem>
    ))
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Power />
              </CardIcon>
            </CardHeader>
            <CardBody>
              <div className="content">
                <GridContainer>
                  <GridItem>
                    Connection state:{" "}
                    <em>{this.state.webSocketConnection.state}</em>. User:{" "}
                    <strong>{this.props.account.username}</strong>
                    <br />
                    Connection id: <em>{this.state.currentConnectionId}</em> (
                    <TimeAgo
                      date={this.state.currentConnectionStartDateTime}
                      title={Helpers.prettyDateTimeinPacificTimeZone(
                        this.state.currentConnectionStartDateTime
                      )}
                    />
                    )
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem md={6}>
                    <GridContainer>
                      <GridItem md={6}>
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Connected Clients
                        </InputLabel>
                        <Select
                          fullWidth
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.toConnectionId}
                          onChange={this.onToConnectionIdChange}
                          onFocus={this.onConnectedClientsFocus}
                          inputProps={{
                            name: "simpleSelect",
                            id: "simple-select"
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                            Choose client
                          </MenuItem>
                          {connectedClientsMenuItems}
                        </Select>
                      </GridItem>
                      <GridItem md={6}>
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Connected users
                        </InputLabel>
                        <Select
                          fullWidth
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.toUserId}
                          onChange={this.onUserChange}
                          onFocus={this.onConnectedUsersFocus}
                          inputProps={{
                            name: "simpleSelect",
                            id: "simple-select"
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                            Choose user
                          </MenuItem>
                          {connectedUsersMenuItems}
                        </Select>
                      </GridItem>
                    </GridContainer>
                    <p />
                    <GridContainer>
                      <GridItem md={12}>
                        <TextField
                          label="Message"
                          id="message"
                          fullWidth={true}
                          onChange={this.onMessageChange}
                          value={this.state.message}
                          multiline={true}
                          rows={3}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem md={6}>
                    <h6>Message(s) Received</h6>
                    <GridItem md={12}>
                      <TextField
                        label="Message(s) received"
                        id="messagesReceived"
                        fullWidth={true}
                        value={this.state.messagesReceived}
                        multiline={true}
                        rows={10}
                      />
                    </GridItem>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem md={12}>
                    <Checkbox
                      checked={this.state.isInPortalNotificationMessage}
                      onChange={this.onInPortalNotificationChange}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                    />{" "}
                    In Portal Notification?
                  </GridItem>
                  <GridContainer>
                    <GridItem md={2}>
                      <Button
                        color="primary"
                        onClick={this.onSendMessageToClientButtonClick}
                        disabled={
                          this.state.toConnectionId.length > 0 &&
                          this.state.message.length > 0
                            ? false
                            : true
                        }
                      >
                        Send to client
                      </Button>
                    </GridItem>
                    <GridItem md={2}>
                      <Button
                        color="primary"
                        onClick={this.onSendMessageToUserIdButtonClick}
                        disabled={
                          this.state.toUserId.length > 0 &&
                          this.state.message.length > 0
                            ? false
                            : true
                        }
                      >
                        Send to user
                      </Button>
                    </GridItem>
                    <GridItem md={2}>
                      <Button
                        color="primary"
                        onClick={this.onSendMessageToEveryClientButtonClick}
                        disabled={
                          this.state.message.length === 0 ? true : false
                        }
                      >
                        Send to all
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridContainer>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

export default withStyles(regularFormsStyle)(useStore(WebSocketTool))
