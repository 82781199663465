export const styles = () => ({
  codeEditor: {
    display: "block",
    width: "100%",
    border: "1px solid #ced4da"
  },
  codeEditorArea: {
    display: "flex",
    minHeight: "250px"
  },
  toolbar: {
    display: "flex",
    alignItems: "flex-start"
  }
})
