import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Map } from "immutable"
import { useObserver } from "mobx-react"
import { startCase } from "lodash"
import { Typography } from "@mui/material"
import { CheckBoxTwoTone, CheckBoxOutlineBlank } from "@mui/icons-material"

import { useStore } from "contexts/rootContext"
import Loading from "components/Loading"

const propTypes = {
  groupId: PropTypes.number.isRequired
}

function UserStoreData() {
  const store = useStore()
  return useObserver(() => ({
    permissions: store.permissionGroupStore.permissions,
    permissionGroups: store.permissionGroupStore.permissionGroups,
    getPermissionGroup: store.permissionGroupStore.getPermissionGroup
  }))
}

const PermissionsList = ({ groupId }) => {
  const { permissions, permissionGroups, getPermissionGroup } = UserStoreData()

  // on first render
  useEffect(() => {
    getPermissionGroup(groupId)
  }, [])

  const allPermissions = permissions
  const permissionGroup = permissionGroups.find(
    g => g.get("id") === groupId,
    Map()
  )
  const permissionGroupPermissions = permissionGroup.get("permissions")
  const loading = permissionGroupPermissions == null

  const validateChecked = permissionId => {
    const idx = permissionGroupPermissions.findIndex(
      p => p.get("id") === permissionId
    )
    return idx !== -1 ? true : false
  }

  return (
    <div className="checklist">
      <Loading active={loading} />
      {allPermissions.map(o => {
        const checked = loading ? false : validateChecked(o.get("id"))
        return (
          <div key={o.get("id")} className="checklist__item">
            {checked && <CheckBoxTwoTone color="primary" />}
            {!checked && <CheckBoxOutlineBlank color="disabled" />}
            <Typography variant="body2" color="secondary">
              {startCase(o.get("name"))}
            </Typography>
          </div>
        )
      })}
    </div>
  )
}

PermissionsList.propTypes = propTypes
export default PermissionsList
