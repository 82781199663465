import React, { Component } from "react"
import Button from "components/CustomButtons/Button"
import ReactJson from "react-json-view"
import ReactTable from "react-table-6"
import { Tab, Tabs, Box, Typography } from "@mui/material"
import Danger from "components/Typography/Danger.jsx"
import Accordion from "components/Accordion/Accordion"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardIcon from "components/Card/CardIcon.jsx"
import StorageIcon from "@mui/icons-material/Storage"
import Visibility from "@mui/icons-material/Visibility"
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle"
import withStyles from "@mui/styles/withStyles"
import { Link } from "react-router-dom"
import matchSorter from "match-sorter"
import AuditInfo from "components/AuditInfo"
import PivotTableUI from "react-pivottable/PivotTableUI"
import "react-pivottable/pivottable.css"
import TableRenderers from "react-pivottable/TableRenderers"
import { Cookies } from "tools/storage"
import Tooltip from "@mui/material/Tooltip"
import {
  Add,
  Edit,
  Share,
  FileCopy,
  Close,
  Save,
  PlayArrow,
  Loop
} from "@mui/icons-material"
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Dialog,
  CircularProgress,
  TextField,
  DialogActions
} from "@mui/material"
import { TabPanel } from "components/TabPanel/TabPanel"
import Grid from "@mui/material/Grid"
import { globalSettings } from "variables/general.jsx"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { observer, inject } from "mobx-react"
import { get, put } from "tools/request"

const useStore = component =>
  inject(({ store }) => ({
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization
  }))(observer(component))

const ENTER_KEY = 13
const LS_SELECTEDQUERYTOTALCOUNT_KEY = "querybuilder-selectedquerytotalcount"
const LS_QUERYPAGECOUNT_KEY = "querybuilder-querypagecount"

class QueryBuilder extends Component {
  timer = null

  constructor(props) {
    super(props)
    const currentQueryId =
      props.selectedQueryId || (props.match && props.match.params.queryId)
    const currentView = currentQueryId ? 1 : 0
    let cookies = new Cookies()
    let selectedQueryTotalCount =
      Number(cookies.get(LS_SELECTEDQUERYTOTALCOUNT_KEY)) || 500
    let queryPageCount = Number(cookies.get(LS_QUERYPAGECOUNT_KEY)) || 500

    this.state = {
      currentQueryId,
      currentView,
      currentResultsView: 0,
      rawSQL: `select table_name, table_type from information_schema.tables
where table_schema = 'public'
order by table_name`,
      token: "",
      connectionId: 1,
      name: "New Query",
      rawQueries: [],
      queryResults: {},
      reactTableResults: [],
      dynamicColumns: [],
      isDebug: false,
      queryResultCode: 0,
      queryResultMessage: "",
      queryIsRunning: false,
      allQueriesLoading: false,
      isQuerySaved: false,
      isRawSqlDirty: false,
      isCreateMode: false,
      columnNames: [],
      tableFilter: [],
      isShareDialogDisplayed: false,
      queryPageCount: parseInt(queryPageCount),
      queryTotalCount: 0,
      previewElapsedTimeMilliseconds: 0,
      executedQuery: false,
      selectedQueryTotalCount: parseInt(selectedQueryTotalCount)
    }
    this.onKeyDown = this.onKeyDown.bind(this)
    this.createQuery = this.createQuery.bind(this)
  }

  componentDidMount() {
    if (!this.props.isEmbedded) {
      this.props.setShowChangeCurrentOrganization(false)
    }
    this.loadAllQueries(this.state.queryPageCount)
    this.queryRDMS()
    let propQueryId =
      this.props.selectedQueryId ||
      (this.props.match && this.props.match.params.queryId)
    if (propQueryId) {
      this.loadSingleQuery(propQueryId)
    }
    if (this.props.isEmbedded) {
      this.setState({
        currentView: propQueryId ? 1 : 0,
        tableFilter: this.state.currentQueryId
          ? [
              {
                id: "id",
                value: String(this.state.currentQueryId)
              }
            ]
          : []
      })
    }
  }

  componentDidUpdate() {
    let propQueryId =
      this.props.selectedQueryId ||
      (this.props.match && this.props.match.params.queryId)
    if (
      !this.state.isCreateMode &&
      propQueryId &&
      this.state.currentQueryId !== propQueryId
    ) {
      this.loadSingleQuery(propQueryId)
      this.setState({
        currentQueryId: propQueryId,
        currentView: propQueryId ? 1 : 0,
        tableFilter: [
          {
            id: "id",
            value: String(propQueryId)
          }
        ]
      })
    }
  }

  queryRDMS = () => {
    this.setState({
      queryIsRunning: true,
      reactTableResults: [],
      executedQuery: false
    })
    this.mapResultsColumns({})
    let myDate = new Date()
    put(`${globalSettings.apiBaseUrl}/api/querybuilder`, null, {
      RawSQL: this.state.rawSQL,
      connectionId: this.state.connectionId
    })
      .then(response => response.json())
      .then(data => {
        let newtime = new Date().getTime() - myDate.getTime()
        this.setState({
          queryResultCode: data.responseCode,
          queryResultMessage: data.responseMessage,
          queryResults: data,
          previewElapsedTimeMilliseconds: newtime,
          averageElapsedTimeMilliseconds: newtime
        })
        if (data.content.queryResults != null) {
          this.setState({
            reactTableResults: data.content.queryResults.records,
            executedQuery: true,
            columnNames: data.content.queryResults.columns,
            columnCount: data.content.queryResults.columns.length,
            averageRowCount: data.content.queryResults.records.length
            //isRawSqlDirty: columnNames.length === 0  //Removed so when you execute an edited query you can immediately save it
          })
          this.mapResultsColumns(data.content.queryResults.columns)
        } else {
          this.setState({ reactTableResults: [] })
          this.mapResultsColumns({})
        }
        this.setState({ queryIsRunning: false })
      })
  }

  saveQuery = () => {
    put(`${globalSettings.apiBaseUrl}/api/querybuilder/savequery`, null, {
      id: this.state.id,
      token: this.state.token,
      name: this.state.name,
      rawSQL: this.state.rawSQL,
      connectionId: this.state.connectionId,
      comments: this.state.comments,
      columnNames:
        this.state.columnNames && JSON.stringify(this.state.columnNames),
      columnCount: this.state.columnCount,
      averageRowCount: this.state.averageRowCount,
      averageElapsedTimeMilliseconds: this.state.averageElapsedTimeMilliseconds
    })
      .then(response => response.json())
      .then(data => {
        this.loadAllQueries()
        if (this.state.isCreateMode) {
          this.setState({ currentView: 0 })
        }
        this.setState({
          isQuerySaved: true,
          isRawSqlDirty: false,
          isCreateMode: false,
          id: data.content.id,
          token: data.content.token,
          currentQueryId: data.content.id,
          tableFilter: [
            {
              id: "id",
              value: String(data.content.id)
            }
          ]
        })
        this.props.history.push(`/portal/tools/querybuilder/${data.content.id}`)
      })
  }

  loadSingleQuery = queryId => {
    if (!queryId) {
      return null
    }
    get(
      `${globalSettings.apiBaseUrl}/api/querybuilder/retrievequery/${queryId}`
    )
      .then(response => response.json())
      .then(data => {
        this.setState({
          id: data.content.id,
          token: data.content.data,
          name: data.content.name,
          rawSQL: data.content.rawSQL,
          connectionId: data.content.connectionId,
          comments: data.content.comments,
          columnNames:
            (data.content.columnNames &&
              JSON.parse(data.content.columnNames)) ||
            [],
          columnCount: data.content.columnCount,
          averageRowCount: data.content.averageRowCount,
          averageElapsedTimeMilliseconds:
            data.content.averageElapsedTimeMilliseconds,
          isRawSqlDirty: false,
          isCreateMode: false
        })
        this.queryRDMS()
      })
  }

  loadAllQueries = () => {
    this.getQueryCount()
    this.setState({ allQueriesLoading: true })
    get(
      `${globalSettings.apiBaseUrl}/api/querybuilder/retrieveallqueries?page=1&pagecount=${this.state.selectedQueryTotalCount}`
    )
      .then(response => response.json())
      .then(data => {
        this.setState({
          rawQueries: data.content,
          allQueriesLoading: false
        })
      })
  }

  mapResultsColumns = queryResultsColumns => {
    const columns = Object.keys(queryResultsColumns).map(key => ({
      Header: queryResultsColumns[key],
      accessor: queryResultsColumns[key]
    }))
    this.setState({ dynamicColumns: columns })
  }

  executeSQLQuery = () => {
    this.queryRDMS()
  }

  onTextAreaChange = e => {
    this.setState({ rawSQL: e.target.value, isRawSqlDirty: true })
  }

  onKeyDown(e) {
    if (e.ctrlKey && e.keyCode === ENTER_KEY) {
      this.queryRDMS()
    }
  }

  selectQuery(queryId) {
    this.setState({
      isCreateMode: false,
      id: "",
      name: "",
      token: "",
      rawSQL: ""
    })
    this.props.onChange && this.props.onChange(queryId)
  }

  selectIndex(itemIndex) {
    this.props.handleIndexChange && this.props.handleIndexChange(itemIndex)
  }

  createQuery() {
    window.history.pushState(null, null, "/portal/tools/querybuilder/0")
    this.setState({
      currentQueryId: 0,
      id: 0,
      name: "",
      rawSQL: "",
      comments: "",
      token: "",
      isRawSqlDirty: false,
      currentView: 1,
      currentResultsView: 0,
      connectionId: 1,
      queryResults: [],
      reactTableResults: [],
      isCreateMode: true,
      tableFilter: []
    })
  }

  onShareButtonClicked(token) {
    let url = this.buildQueryBuilderShareUrl(token)
    this.setState({ urlToShare: url })
    this.displayShareDialog(url)
  }

  displayShareDialog() {
    this.setState({ isShareDialogDisplayed: true })
  }

  buildQueryBuilderShareUrl(token) {
    return `${globalSettings.apiBaseUrl}/api/querybuilder/results/${token}`
  }

  hideShareDialog = () => {
    this.setState({ isShareDialogDisplayed: false })
  }

  showQueryBuilderTab = () => {
    this.setState({ currentView: 1 })
  }

  onChangeQueryTotalCount = event => {
    let cookies = new Cookies()
    cookies.set(LS_SELECTEDQUERYTOTALCOUNT_KEY, event.target.value)
    this.setState({ selectedQueryTotalCount: event.target.value }, () =>
      this.loadAllQueries(event.target.value)
    )
  }

  onChangeQueryPageCount = pageSize => {
    let cookies = new Cookies()
    cookies.set(LS_QUERYPAGECOUNT_KEY, pageSize)
    this.setState({ queryPageCount: pageSize })
  }

  getQueryCount() {
    get(`${globalSettings.apiBaseUrl}/api/querybuilder/querycount/`)
      .then(Response => Response.text())
      .then(text => {
        this.setState({
          queryTotalCount: text
        })
      })
  }

  renderTotalCountOptions() {
    let steppingPoints = [
      25, 50, 75, 100, 150, 200, 250, 350, 400, 500, 750, 1000, 1250, 1500,
      2000, 2500, 3000, 3500, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 15000,
      20000, 25000, 30000, 35000, 40000, 45000, 50000
    ]
    let queryPageCountChoices = []
    let minSelectUsageSize = 1000 //This was 25 in the past
    steppingPoints.map(i => {
      if (i < this.state.queryTotalCount) {
        queryPageCountChoices.push(i)
      }
    })
    queryPageCountChoices.push(Number(this.state.queryTotalCount))
    if (
      parseInt(this.state.selectedQueryTotalCount) >
      parseInt(this.state.queryTotalCount)
    ) {
      this.setState({
        selectedQueryTotalCount: this.state.queryTotalCount
      })
    }
    if (this.state.queryTotalCount < minSelectUsageSize) {
      return this.state.queryTotalCount
    } else {
      return (
        <select
          onChange={this.onChangeQueryTotalCount}
          style={{ width: "100" }}
          value={this.state.selectedQueryTotalCount}
        >
          {queryPageCountChoices.map(o => (
            <option key={o} value={o}>
              {o}
            </option>
          ))}
        </select>
      )
    }
  }

  render() {
    const { classes } = this.props
    const { currentView, currentResultsView } = this.state

    const handleChange = (event, newValue) => {
      this.setState({
        currentView: newValue
      })
    }
    const handleViewChange = (event, newValue) => {
      this.setState({
        currentResultsView: newValue
      })
    }
    const stringFilterProps = {
      filterable: true,
      filterAll: true,
      filterMethod: ({ id, value }, rows) =>
        matchSorter(rows, value, {
          keys: [id],
          threshold: matchSorter.rankings.CONTAINS
        })
    }

    const hasWindowObject = typeof window !== "undefined"
    const screenWidth = hasWindowObject ? window.innerWidth : null
    const screenHeight = hasWindowObject ? window.innerHeight : null
    const showPagination =
      screenWidth && screenHeight && (screenHeight > 959 || screenWidth > 959)

    const myMaxHeight = showPagination
      ? `${(window.innerHeight * 0.67).toFixed(0)}px`
      : "auto"

    return (
      <Card className={this.props.isEmbedded ? classes.embedded : ""}>
        {!this.props.isEmbedded && (
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <StorageIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Query the Data Warehouse</h4>
          </CardHeader>
        )}
        <CardBody>
          {this.state.isShareDialogDisplayed && (
            <Dialog
              onClose={this.hideShareDialog}
              aria-labelledby="customized-dialog-title"
              open={this.state.isShareDialogDisplayed}
              maxWidth="sm"
              fullWidth={true}
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={this.hideShareDialog}
              >
                Sharing
              </DialogTitle>
              <DialogContent>
                {this.isSharingImage && (
                  <React.Fragment>
                    <Typography gutterBottom>
                      Choose image dimensions (original aspect-ratio is always
                      maintained), quality, and/or image format.
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item>Format</Grid>
                      <Grid item>testing</Grid>
                    </Grid>
                  </React.Fragment>
                )}
                <Typography>Copy and share the URL below:</Typography>

                <TextField
                  value={this.state.urlToShare}
                  style={{ width: "90%" }}
                  onChange={e => {
                    this.setState({
                      urlToShare: e.target.value
                    })
                  }}
                  disabled={true}
                />
                <CopyToClipboard
                  text={this.state.urlToShare}
                  onCopy={() =>
                    this.setState({
                      copySuccessText: "URL Copied."
                    })
                  }
                >
                  <Tooltip title={this.state.copySuccessText} placement="top">
                    <Button
                      justIcon
                      round
                      simple
                      color="primary"
                      className="info"
                    >
                      <FileCopy />
                    </Button>
                  </Tooltip>
                </CopyToClipboard>
                {this.isSharingImage && (
                  <img src={this.state.urlToShare} alt="" />
                )}
              </DialogContent>
              <DialogActions>
                <img
                  src={`${globalSettings.apiBaseUrl}/api/qrcode?text=${this.state.urlToShare}`}
                  height={100}
                  alt=""
                />
                <Button
                  onClick={this.hideShareDialog}
                  color="primary"
                  startIcon={<Close />}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          )}
          <Dialog
            open={this.state.isQuerySaved}
            onClose={() => this.setState({ isQuerySaved: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Query Saved</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Your raw sql query has been saved.
              </DialogContentText>
            </DialogContent>
          </Dialog>
          <Tabs
            value={currentView}
            onChange={handleChange}
            aria-label="QueryBuilder Tabs"
          >
            <Tab label="Built Queries" />
            <Tab label="Query Results" />
          </Tabs>
          <div>
            <TabPanel value={currentView} index={0}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end"
                }}
              >
                <Button
                  size="sm"
                  color="primary"
                  onClick={this.createQuery}
                  startIcon={<Add />}
                >
                  New Query
                </Button>
              </div>
              <ReactTable
                minRows={0}
                loading={this.state.allQueriesLoading}
                filtered={this.state.tableFilter}
                onFilteredChange={filtered =>
                  this.setState({ tableFilter: filtered })
                }
                noDataText={
                  this.state.allQueriesLoading ? (
                    <CircularProgress />
                  ) : (
                    "No raw sql queries found."
                  )
                }
                getTrProps={(state, rowInfo) => ({
                  style: {
                    background:
                      rowInfo &&
                      rowInfo.row &&
                      rowInfo.row.id === this.state.currentQueryId
                        ? "yellow"
                        : null
                  }
                })}
                data={this.state.rawQueries}
                columns={[
                  {
                    Header: "Actions",
                    Cell: row => (
                      <div className="actions-left">
                        <Tooltip
                          title="Preview using this query"
                          placement="top"
                        >
                          <Button
                            justIcon
                            round
                            simple
                            color="primary"
                            className="info"
                            onClick={() => this.selectQuery(row.original.id)}
                          >
                            <Visibility />
                          </Button>
                        </Tooltip>
                      </div>
                    ),
                    sortable: false,
                    filterable: false,
                    width: 70,
                    show: !!this.props.isEmbedded
                  },
                  {
                    Header: "Id",
                    accessor: "id",
                    show: !!this.props.isEmbedded,
                    Cell: cell => <div>{cell.value}</div>,
                    width: 60
                  },
                  {
                    Header: "Id",
                    accessor: "id",
                    show: !this.props.isEmbedded,
                    Cell: cell => <div>{cell.value}</div>,
                    width: 60
                  },
                  {
                    Header: "Name",
                    accessor: "name",
                    show: true,
                    width: 400,
                    ...stringFilterProps
                  },
                  {
                    Header: "Actions",
                    Cell: cell => (
                      <div className="actions-right">
                        <Link
                          to={`/portal/tools/querybuilder/${cell.original.id}`}
                          title="Edit"
                          onClick={() => {
                            this.selectQuery(cell.value)
                            this.showQueryBuilderTab()
                          }}
                        >
                          <Button
                            justIcon
                            round
                            simple
                            color="primary"
                            className="info"
                          >
                            <Edit />
                          </Button>
                        </Link>
                        <Button
                          title="Share"
                          justIcon
                          round
                          simple
                          color="primary"
                          onClick={() => {
                            this.onShareButtonClicked(cell.original.token)
                          }}
                        >
                          <Share />
                        </Button>
                      </div>
                    ),
                    sortable: false,
                    filterable: false,
                    width: 100
                  },
                  {
                    Header: "Raw SQL",
                    accessor: "rawSQL",
                    show: !this.props.isEmbedded,
                    sortable: false,
                    ...stringFilterProps,
                    Cell: cell => (
                      <div>
                        {cell.value && cell.value.length > 100
                          ? `${cell.value.substring(0, 100)}...`
                          : cell.value}
                      </div>
                    )
                  },
                  {
                    Header: "Audit Info",
                    id: "auditInfo",
                    Cell: ({ original: { auditInfoResponse } }) => (
                      <AuditInfo {...auditInfoResponse} />
                    ),
                    width: 250,
                    filterable: false
                  }
                ]}
                filterable={true}
                defaultPageSize={this.state.queryPageCount}
                onPageSizeChange={pageSize => {
                  this.onChangeQueryPageCount(pageSize)
                }}
                showPaginationTop={showPagination}
                showPaginationBottom={false}
                className="-striped -highlight -scrollEntries"
                style={{
                  maxHeight: myMaxHeight
                }}
                defaultSorted={[
                  {
                    id: "id",
                    desc: true
                  }
                ]}
                defaultFilterMethod={(filter, row) => {
                  const id = filter.pivotId || filter.id
                  return row[id] !== undefined
                    ? String(row[id])
                        .toLowerCase()
                        .startsWith(filter.value.toLowerCase())
                    : true
                }}
              >
                {(state, makeTable) => {
                  const { filtered, pageRows, pageSize, sortedData, page } =
                    state

                  let isFiltered = false
                  isFiltered = filtered.length > 0
                  let recordsCountFrom = 0
                  let recordsCountTo = 0
                  let totalRecords = 0
                  totalRecords = sortedData.length
                  recordsCountFrom = page * pageSize + 1
                  recordsCountTo = recordsCountFrom + pageRows.length - 1

                  return (
                    <div className="main-grid">
                      <div className="above-table text-right">
                        <div className="col-sm-12">
                          <span className="records-info">
                            {totalRecords > 0 && isFiltered && (
                              <span>
                                {recordsCountFrom}-{recordsCountTo} of{" "}
                                {totalRecords} filtered out of{" "}
                                {this.renderTotalCountOptions()} total queries
                              </span>
                            )}
                            {totalRecords > 0 && !isFiltered && (
                              <span>
                                {recordsCountFrom}-{recordsCountTo} of{" "}
                                {this.renderTotalCountOptions()} total queries
                              </span>
                            )}
                            {totalRecords === 0 &&
                              (this.state.isLoading
                                ? "Loading queries, please wait..."
                                : "No queries.")}
                            <Tooltip
                              title="Reload"
                              placement="top"
                              arrow={true}
                            >
                              <Button
                                justIcon
                                round
                                simple
                                color="info"
                                className="info"
                                id="reload"
                                onClick={() => {
                                  this.loadAllQueries(this.state.queryPageCount)
                                }}
                              >
                                {this.state.isLoading ? (
                                  <CircularProgress thickness={1} size={30} />
                                ) : (
                                  <Loop />
                                )}
                              </Button>
                            </Tooltip>
                          </span>
                        </div>
                      </div>

                      {makeTable()}
                    </div>
                  )
                }}
              </ReactTable>
            </TabPanel>
            <TabPanel value={currentView} index={1}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end"
                }}
              >
                <Button
                  size="sm"
                  color="primary"
                  onClick={this.createQuery}
                  startIcon={<Add />}
                >
                  New Query
                </Button>
              </div>
              <Accordion
                active={0}
                collapses={[
                  {
                    title: "Query Details",
                    content: (
                      <React.Fragment>
                        <Box
                          style={{
                            padding: "0px 20px"
                          }}
                        >
                          <TextField
                            id="name"
                            fullWidth={true}
                            placeholder={"Enter name for your query"}
                            value={this.state.name || ""}
                            onChange={e =>
                              this.setState({
                                name: e.target.value
                              })
                            }
                            helperText="Give your query a descriptive name"
                          />
                          <div>
                            <FormControl margin="normal" fullWidth>
                              <InputLabel>Connection</InputLabel>
                              <Select
                                type="select"
                                name="connectionId"
                                id="connectionId"
                                label="Connection"
                                value={this.state.connectionId || 1}
                                onChange={e =>
                                  this.setState({
                                    connectionId: e.target.value
                                  })
                                }
                              >
                                <MenuItem value={1}>DS_Main</MenuItem>
                                <MenuItem value={2}>Ricos</MenuItem>
                                <MenuItem value={3}>Databricks</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                          <div className={classes.resizeTextarea}>
                            <TextField
                              id="rawSQL"
                              fullWidth={true}
                              multiline={true}
                              placeholder="Enter some raw SQL here."
                              value={this.state.rawSQL}
                              onChange={this.onTextAreaChange}
                              onKeyDown={this.onKeyDown}
                              helperText="Only read queries (limited to 10,000 records), no modification queries allowed. (Ctrl-Enter to execute or start typing...)"
                            />
                          </div>
                          <div className={classes.flexButtonList}>
                            <Button
                              color="primary"
                              size="sm"
                              onClick={() => {
                                this.executeSQLQuery()
                              }}
                              startIcon={<PlayArrow />}
                            >
                              Execute the Query
                            </Button>
                            &nbsp;
                            <Button
                              color="primary"
                              size="sm"
                              onClick={this.saveQuery}
                              disabled={!this.state.isRawSqlDirty}
                              startIcon={<Save />}
                            >
                              Save the Query
                            </Button>
                            &nbsp;
                            <Button
                              title="Share"
                              color="primary"
                              size="sm"
                              onClick={() =>
                                this.onShareButtonClicked(this.state.token)
                              }
                              disabled={this.state.token === ""}
                              startIcon={<Share />}
                            >
                              Share Results
                            </Button>
                          </div>

                          {this.state.queryResultCode > 1000 && (
                            <Danger>
                              Error with SQL: {this.state.queryResultMessage}
                            </Danger>
                          )}
                        </Box>
                      </React.Fragment>
                    )
                  }
                ]}
              />
              <Accordion
                active={0}
                collapses={[
                  {
                    title: `Query Results ${
                      this.state.executedQuery
                        ? `(Elapsed time: ${(
                            this.state.previewElapsedTimeMilliseconds / 1000
                          ).toFixed(2)} seconds)`
                        : ""
                    }`,
                    content: (
                      <React.Fragment>
                        <Tabs
                          value={currentResultsView}
                          onChange={handleViewChange}
                          aria-label="Results Views"
                        >
                          <Tab label="Standard Table" />
                          <Tab label="Pivot Table" />
                        </Tabs>
                        <div>
                          <TabPanel value={currentResultsView} index={0}>
                            {this.state.isDebug === true && (
                              <ReactJson
                                enableClipboard={false}
                                displayObjectSize={false}
                                displayDataTypes={false}
                                src={this.state.queryResults}
                                collapsed={false}
                                collapseStringsAfterLength={450}
                                shouldCollapse={({ name }) =>
                                  name === "software" || name === "language"
                                }
                              />
                            )}
                            <ReactTable
                              loading={this.state.queryIsRunning}
                              noDataText={
                                this.state.queryIsRunning ? (
                                  <CircularProgress />
                                ) : (
                                  "No result(s) found."
                                )
                              }
                              data={this.state.reactTableResults}
                              columns={[
                                {
                                  Header: "Index",
                                  accessor: "index",
                                  show: !!this.props.isEmbedded,
                                  Cell: row => (
                                    <button
                                      onClick={() =>
                                        this.selectIndex(row.index)
                                      }
                                    >
                                      {row.index}
                                    </button>
                                  ),
                                  width: 60
                                },
                                ...this.state.dynamicColumns
                              ]}
                              minRows={0}
                              filterable={true}
                              defaultPageSize={100}
                              showPaginationTop={showPagination}
                              showPaginationBottom={showPagination}
                              className="-striped -highlight"
                              defaultFilterMethod={(filter, row) => {
                                const id = filter.pivotId || filter.id
                                return row[id] !== undefined
                                  ? String(row[id])
                                      .toLowerCase()
                                      .startsWith(filter.value.toLowerCase())
                                  : true
                              }}
                            >
                              {(state, makeTable) => {
                                let recordsInfoText = ""

                                const {
                                  filtered,
                                  pageRows,
                                  pageSize,
                                  sortedData,
                                  page
                                } = state

                                if (sortedData && sortedData.length > 0) {
                                  let isFiltered = filtered.length > 0

                                  let totalRecords = sortedData.length

                                  let recordsCountFrom = page * pageSize + 1

                                  let recordsCountTo =
                                    recordsCountFrom + pageRows.length - 1

                                  if (isFiltered) {
                                    recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`
                                  } else {
                                    recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`
                                  }
                                } else {
                                  recordsInfoText = this.state.queryIsRunning
                                    ? "Running Query, please wait"
                                    : "No records"
                                }
                                return (
                                  <div className="main-grid">
                                    <div className="above-table text-right">
                                      <div className="col-sm-12">
                                        <span className="records-info">
                                          {recordsInfoText}
                                        </span>
                                      </div>
                                    </div>
                                    {makeTable()}
                                  </div>
                                )
                              }}
                            </ReactTable>
                          </TabPanel>
                          <TabPanel
                            value={currentResultsView}
                            index={1}
                            style={{
                              overflow: "auto"
                            }}
                          >
                            <PivotTableUI
                              data={this.state.reactTableResults}
                              onChange={s => {
                                this.setState(s)
                              }}
                              renderers={Object.assign({}, TableRenderers)}
                              {...this.state}
                            />
                          </TabPanel>
                        </div>
                      </React.Fragment>
                    )
                  }
                ]}
              />
              <Accordion
                collapses={[
                  {
                    title: "Comments",
                    content: (
                      <TextField
                        id="comments"
                        fullWidth={true}
                        multiline={true}
                        rows={10}
                        placeholder="4/16/2020 - Added column X to results"
                        value={this.state.comments}
                        onChange={e =>
                          this.setState({
                            comments: e.target.value
                          })
                        }
                        helperText="Take notes regarding your query"
                      />
                    )
                  }
                ]}
              />
            </TabPanel>
          </div>
        </CardBody>
      </Card>
    )
  }
}

export default withStyles(regularFormsStyle)(useStore(QueryBuilder))
