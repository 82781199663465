import React, { useState } from "react"
import PropTypes from "prop-types"
import LinearProgress from "@mui/material/LinearProgress"
import Button from "@mui/material/Button"
import { useFormikContext } from "formik"

import "assets/css/Components/Wizard.css"

const propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.node.isRequired
    })
  ).isRequired,
  previousButtonText: PropTypes.string,
  nextButtonText: PropTypes.string,
  completeButtonText: PropTypes.string,
  saveAndViewSubmitted: PropTypes.bool
}

// This wizard is only used in one place, the create output form, and is wrapped by Formik
// Probably should be re-imagined or can refactor components/Wizard/Wizard.jsx
// to also work for the create output form
const Wizard = ({
  steps,
  previousButtonText = "Back",
  nextButtonText = "Next",
  completeButtonText = "Save",
  saveAndViewSubmitted = false
}) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0)

  const { validateForm } = useFormikContext()

  const hasNextStep = activeStepIndex < steps.length - 1
  const hasPreviousStep = activeStepIndex > 0
  const activeStep = steps[activeStepIndex]

  const handleStep = stepIdx => {
    setActiveStepIndex(stepIdx)
  }

  const handleNext = () => {
    validateForm().then((errors = {}) => {
      const isValid = !Object.keys(errors).length
      if (isValid) {
        const newStepIdx = activeStepIndex + 1
        handleStep(newStepIdx)
      }
    })
  }

  const handleBack = () => {
    const newStepIdx = activeStepIndex - 1
    handleStep(newStepIdx)
  }

  const renderFooter = () => (
    <div className="wizard__footer">
      {!saveAndViewSubmitted && (
        <Button
          variant="contained"
          disabled={!hasPreviousStep}
          onClick={handleBack}
        >
          {previousButtonText}
        </Button>
      )}
      {!saveAndViewSubmitted && hasNextStep && (
        <>
          &nbsp;
          <Button variant="contained" color="primary" onClick={handleNext}>
            {nextButtonText}
          </Button>
        </>
      )}
      {!hasNextStep && !saveAndViewSubmitted && (
        <>
          &nbsp;
          <Button variant="contained" color="primary" type="submit">
            {completeButtonText}
          </Button>
        </>
      )}
    </div>
  )

  const Component = activeStep.component

  return (
    <div className="wizard">
      <Component renderFooter={renderFooter} />
      {saveAndViewSubmitted && <LinearProgress />}
    </div>
  )
}

Wizard.propTypes = propTypes

export default Wizard
