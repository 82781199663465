// eslint-disable-next-line no-unused-vars
import React from "react"
import PropTypes from "prop-types"
import withStyles from "@mui/styles/withStyles"
import Heading from "components/Heading/Heading"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import iconsStyle from "assets/jss/material-dashboard-pro-react/views/iconsStyle"
import Box from "@mui/material/Box"

function Icons({ ...props }) {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Heading
          textAlign="center"
          title="Material Design Icons"
          category={
            <span>
              Handcrafted by our friends from{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://design.google.com/icons/"
              >
                Google
              </a>
            </span>
          }
        />
        <Card plain>
          <CardBody plain>
            <Box sx={{ display: { sm: "none", md: "block" } }}>
              <iframe
                className={props.classes.iframe}
                src="https://material.io/icons/"
                title="Icons iframe"
              >
                <p>Your browser does not support iframes.</p>
              </iframe>
            </Box>
            <Box sx={{ display: { sm: "block", md: "none" } }}>
              <GridItem xs={12} sm={12} md={6}>
                <h5>
                  The icons are visible on Desktop mode inside an iframe. Since
                  the iframe is not working on Mobile and Tablets please visit
                  the icons on their original page on Google. Check the
                  <a
                    href="https://design.google.com/icons/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Material Icons
                  </a>
                </h5>
              </GridItem>
            </Box>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

Icons.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(iconsStyle)(Icons)
