import React, { useState, useCallback } from "react"
import { fromJS } from "immutable"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import Table from "components/Table/AdvancedTable"
import {
  AutocompleteInput,
  AutocompleteMultiInput,
  BasicCell,
  LinkParagraph,
  SelectRowCell
} from "components/Table/components/CustomCells"
import {
  DefaultColumnFilter,
  SelectRowsHeaderActions
} from "components/Table/components/CustomFilters"

import PropTypes from "prop-types"
import {
  Grid,
  FormControlLabel,
  Checkbox,
  TextField,
  Popper
} from "@mui/material"
import { Autocomplete } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"

import { Form, Input, Body, Select, Button } from "components/Form"

import Icon from "@mui/material/Icon"
import Battery20 from "@mui/icons-material/Battery20"
import BatteryUnknown from "@mui/icons-material/BatteryUnknown"
import BlockOutlined from "@mui/icons-material/BlockOutlined"
import BookmarkBorderOutlined from "@mui/icons-material/BookmarkBorderOutlined"
import PlannedIcon from "@mui/icons-material/EmojiObjectsOutlined"
//import Published from "@mui/icons-material/FormatTextdirectionLToR"
import CustomDSDialog from "components/CustomDialogs/CustomDSDialog"
import Warning from "@mui/icons-material/Warning"

import {
  ContentElementFilter,
  SelectColumnFilter,
  SelectColumnAltFilter,
  SelectColumnAltArrayFilter,
  StatusTypeFilter,
  OwnerFilter
} from "components/Table/components/CustomFilters"
import {
  ContentElementInput,
  EditableCell,
  RenderTypeInput,
  StatusTypeInput,
  LibraryBlockPositionsFilterCell,
  OwnerInput,
  SwitchInput,
  SaturationChipInput
} from "components/Table/components/CustomCells"
import SaturationChip from "components/ContentSaturation/SaturationChip"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBox"

import { Cookies } from "tools/storage"

import { removeJustSavedRowFromFilter } from "tools/tableUtil"
import { Field } from "formik"
import ContentSaturationParagraphModal from "components/ContentSaturation/ParagraphModal"
import NarrativeDAO from "daos/narrativeDAO"
import useTableChangedRows from "hooks/table/useTableChangedRows"

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    updateParagraphs: store.narrativeStore.updateParagraphs,
    narrativeParagraphTagsSummary:
      store.narrativeStore.narrativeParagraphTagsSummary,
    narrativeParagraphTags: store.narrativeStore.narrativeParagraphTags
  }))
}

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiInput-underline:before": {
      borderBottom: "none"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none"
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none"
    }
  },
  inputRoot: {
    fontWeight: 400,
    fontSize: "13px",
    height: "32px",
    paddingLeft: "5px"
  },
  acOption: {
    fontWeight: 400,
    fontSize: "13px"
  },
  acOption2: {
    fontWeight: 400,
    fontSize: "12px"
  },
  statusIcon: {
    fontSize: "1em",
    width: "25px",
    height: "25px",
    color: "#144E68",
    marginRight: "20px",
    marginBottom: "15px"
  },
  infoHeader: {
    textAlign: "left",
    color: "#inherit",
    marginBottom: "10px"
  },
  addScrollIfNeeded: {
    maxHeight: "300px",
    overflowX: "hidden",
    overflowY: "auto",
    border: "1px solid #FFC107",
    padding: "10px 0 0 0",
    "& li": {
      textAlign: "left"
    }
  },
  ulStyle: {
    textAlign: "left",
    paddingLeft: "0px",
    "& span": {
      fontWeight: "bold"
    }
  },
  liStyle: { textAlign: "left", marginLeft: "20px" }
}))

const CustomPopper = function (props) {
  return <Popper {...props} style={{ width: "auto" }} placement="bottom-end" />
}

export default function ParagraphTable(props) {
  const {
    narrative,
    paragraphs,
    title,
    hiddenColumns,
    defaults,
    isLibrary,
    outlineBlockId,
    primaryTags,
    secondaryTags,
    formFactorTags,
    thirdPartySpecificTags
  } = props
  const {
    outlineBlocks,
    triggers: allAvailableTriggers,
    buildFromOutline
  } = narrative

  const {
    updateParagraphs,
    narrativeParagraphTags,
    narrativeParagraphTagsSummary
  } = useStoreData()

  const [data, setData] = useState(paragraphs)
  const libraryParagraphs = narrative.libraryParagraphs || []
  const hasLibraryData = libraryParagraphs.length > 0
  const [originalData, setOriginalData] = useState(paragraphs)
  const classes = useStyles()

  const [warningDialogOpen, setWarningDialogOpen] = useState(false)
  const [warningContent, setWarningContent] = useState([])

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.
  const skipResetRef = React.useRef(false)

  const { changedRowIndexes, addChangedRow, removeChangedRow } =
    useTableChangedRows()

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setData(paragraphs)
    setOriginalData(paragraphs)
  }, [paragraphs])

  const editValues = {
    name: undefined,
    sentencetype: undefined,
    rendertype: undefined,
    blockattributes: undefined,
    template: undefined,
    position: undefined,
    triggertype: undefined,
    triggerweight: undefined,
    comments: undefined,
    status_id: undefined,
    requireddata: undefined,
    isarchived: undefined,
    narrativeOutlineBlockId: undefined
  }

  const [bulkEditValues] = useState(editValues)

  const setAlertMessage = (id, action, type, code) => {
    let str = ""

    const newBulkEventSchedule = {
      [id]: action,
      alertMessage: str
    }
    if (type) {
      newBulkEventSchedule.type = type
    }
    if (code) {
      newBulkEventSchedule.type = code
    }
    props.bulkAlertMessage && props.bulkAlertMessage(newBulkEventSchedule)
    props.isAlertActive && props.isAlertActive()
  }

  const labelStatusIcon = status => {
    switch (status) {
      case 1:
        return <Battery20 />
      case 2:
        return <BatteryUnknown />
      //DO NOT remove this icon will be used on upcoming ticket
      // case 3:
      //   return <Published />
      case 4:
        return <PlannedIcon />
      case 5:
        return <BookmarkBorderOutlined />
      case 6:
        return <BlockOutlined />
      default:
        return null
    }
  }

  const validateParagraph = row => {
    const paragraphObj = row.original
    let header = `Index:${row.index}`
    header += row.original.id ? `, id:${row.original.id}` : ""
    let message = []

    if (
      !paragraphObj.primaryTagId &&
      isLibrary &&
      paragraphObj.status_Id === 3
    ) {
      message.push('"Primary Tag" is required prior to publishing this row.')
    }

    if (
      paragraphObj.secondaryTagIds &&
      !paragraphObj.secondaryTagIds.length > 0 &&
      isLibrary &&
      paragraphObj.status_Id === 3
    ) {
      message.push('"Secondary Tag" is required prior to publishing this row.')
    }

    if (
      !paragraphObj.formFactorTagId &&
      isLibrary &&
      paragraphObj.status_Id === 3
    ) {
      message.push(
        '"Form Factor Tag" is required prior to publishing this row.'
      )
    }
    return message.length ? { header: header, message } : null
  }

  const saveParagraph = (row, isBulk = false, preValidated = false) => {
    if (!preValidated) {
      const error = validateParagraph(row)
      if (error) {
        alert(error)
        setData([...data])
        return
      }
    }
    const paragraphObj = row.original

    if (!paragraphObj.id) {
      paragraphObj.id = 0
    }
    let isNew = paragraphObj.id === 0
    if (!paragraphObj.paragraphType) {
      paragraphObj.paragraphType = ""
    }

    setAlertMessage(
      paragraphObj.id ? paragraphObj.id : Number(999999990000 + row.index),
      "saving",
      isBulk ? "bulk" : "notBulk"
    )

    const saveAndCopySentences =
      isNew && paragraphObj.clonedFrom_Id && paragraphObj.clonedFrom_Id > 0

    NarrativeDAO.postParagraph(paragraphObj, { saveAndCopySentences })
      .then(paragraphId => {
        skipResetRef.current = true
        setAlertMessage(
          paragraphObj.id ? paragraphObj.id : Number(999999990000 + row.index),
          "saved",
          isBulk ? "bulk" : "notBulk",
          paragraphId
        )
        paragraphObj.id = paragraphId
        row.original = paragraphObj
        updateParagraphs(paragraphObj)
        props.updateNarrativeParagraphs &&
          props.updateNarrativeParagraphs(paragraphObj)
        row.isSelected = false
        row.toggleSelected(false)
        data[row.index] = row.original
        if (originalData[row.index]) {
          originalData[row.index] = row.original
        } else {
          originalData.push(row.original)
        }
        setOriginalData([...originalData])
        setData([...data])
        removeChangedRow(row.index)
      })
      .catch(err => {
        setAlertMessage(
          paragraphObj.id ? paragraphObj.id : Number(999999990000 + row.index),
          "failed",
          isBulk ? "bulk" : "notBulk",
          Response.status
        )
        cancelChanges(row)
        row.toggleSelected(false)
        removeChangedRow(Number(row.id))
        console.error(
          `Error on ${
            isBulk ? "bulk " : ""
          }fetch to /api/paragraph (saveAndCopySentences = ${
            saveAndCopySentences ? "true" : "false"
          })`,
          err
        )
      })
  }

  const updateSelected = useCallback(
    (bulkEditValues, selectedFlatRows, toggleBulkEditor) => {
      const rowsCopy = [...selectedFlatRows]
      const keys = Object.keys(bulkEditValues)

      rowsCopy.forEach(row => {
        let rowChanged = false
        keys.forEach(key => {
          if (bulkEditValues[key] !== undefined) {
            row.original[key] = bulkEditValues[key]
            rowChanged = true
          }
        })
        if (rowChanged) {
          saveParagraph(row)
        }
      })
      toggleBulkEditor()
    }
  )

  const cancelChanges = row => {
    // Handle new rows and edited rows differently
    const dataCopy = [...data]
    if (row.original.id !== null && row.original.id !== 0) {
      // Reset the edited row to the orginal state
      dataCopy[row.index] = originalData[row.index]
    } else {
      // Remove the new row from the collection
      const dataCopy = [...data]
      dataCopy.splice(row.index, 1)
    }
    setData(dataCopy)
    removeChangedRow(row.index)
  }

  const duplicateElements = ({ selectedFlatRows }) => {
    const newData = [...data]
    selectedFlatRows.forEach(row => {
      const newRow = Object.assign({}, row.original)
      newRow.id = null
      newRow.status_Id = 1
      newData.unshift(newRow)
    })
    setData([...newData])
  }

  const deepCopyElements = ({ selectedFlatRows }) => {
    const newData = [...data]
    selectedFlatRows.forEach(row => {
      const newRow = Object.assign({}, row.original)
      newRow.id = null
      newRow.status_Id = 1
      newRow.clonedFrom_Id = row.original.id
      newData.unshift(newRow)
    })
    setData([...newData])
  }

  const processMessageList = list => (
    <div>
      <div className={classes.infoHeader}>
        The following errors must be resolved in order to save
      </div>
      <div>
        {list.map((itm, j) => (
          <ul key={j} className={classes.ulStyle}>
            <span>{itm.header}</span>
            {itm.message.map((itm, i) => (
              <li key={i} className={classes.liStyle}>
                {itm}
              </li>
            ))}
          </ul>
        ))}
      </div>
    </div>
  )

  const bulkSaveElements = ({ selectedFlatRows }) => {
    const warningMessage = []
    selectedFlatRows.forEach(row => {
      const error = validateParagraph(row)
      if (error) {
        warningMessage.push(error)
      }
    })
    if (warningMessage.length) {
      setWarningDialogOpen(true)
      setWarningContent(warningMessage)
      return false
    } else {
      selectedFlatRows.forEach(row => {
        if (row.getIsSelected()) {
          saveParagraph(row, false, true)
          return true
        }
      })
    }
  }

  const bulkAssignToOutlineBlock = ({ selectedFlatRows }) => {
    if (outlineBlockId) {
      selectedFlatRows.forEach(row => {
        if (row && row.original) {
          row.original.narrativeOutlineBlockId = outlineBlockId
          saveParagraph(row)
        }
      })
    }
  }

  const getIdsFromTriggerType = triggerType => {
    const activeTriggerTypes = (triggerType && triggerType.split(",")) || []
    const allRequiredTriggers = []

    if (allAvailableTriggers) {
      activeTriggerTypes.forEach(trigger => {
        const requiredTrigger = allAvailableTriggers.find(
          t => t.type === trigger.replace("!").trim()
        )
        requiredTrigger && allRequiredTriggers.push(requiredTrigger)
      })
    }

    const newTriggerIds =
      allRequiredTriggers && allRequiredTriggers.map(t => t.id).join()

    return newTriggerIds || []
  }

  const bulkAssignTriggerIds = ({ selectedFlatRows }) => {
    selectedFlatRows.forEach(row => {
      const { triggerType, isFromLibrary, triggerIds } = row.original
      if (triggerType && !isFromLibrary) {
        const calculatedTriggerIds = getIdsFromTriggerType(triggerType)
        if (triggerIds !== calculatedTriggerIds) {
          row.original.triggerIds = calculatedTriggerIds
          saveParagraph(row)
        }
      }
    })
  }

  const bulkArchiveElements = ({ selectedFlatRows }) => {
    selectedFlatRows.forEach(row => {
      if (row && row.original && typeof row.original.isArchived === "boolean") {
        row.original.isArchived = true
        saveParagraph(row)
      }
    })
  }

  const addElements = numberToAdd => {
    let newData = [...data]
    let newElement = {
      ...defaults,
      id: null,
      narrative_Id: (data[0] && data[0].narrative_Id) || narrative.id,
      status_Id: 3
    }

    for (let i = 0; i < numberToAdd; i++) {
      newData.push(newElement)
    }
    setData([...newData])
  }

  const loadBulkEditComponent = React.useCallback(
    ({ selectedFlatRows, toggleBulkEditor }) => (
      <Form
        id="bulk-update"
        name="bulk-update"
        className="compact"
        initialValues={bulkEditValues}
        onSubmit={values =>
          updateSelected(values, selectedFlatRows, toggleBulkEditor)
        }
      >
        <Body>
          <div>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <h5>Bulk Editing</h5>
                <div style={{ padding: "12px", border: "1px solid black" }}>
                  <ul style={{ paddingInlineStart: "20px" }}>
                    {selectedFlatRows.map(d => (
                      <li key={d.original.id}>
                        {d.original.id}-{d.original.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </Grid>
              <Grid item xs={9}>
                <h5>Bulk Edit Values</h5>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    {!buildFromOutline && (
                      <Input
                        fullWidth
                        type="number"
                        name="position"
                        id="position"
                        label="Position"
                        margin="dense"
                        min={0}
                      />
                    )}
                    <Input
                      fullWidth
                      type="text"
                      name="triggerType"
                      id="triggtriggerTypeer"
                      label="Trigger"
                      margin="dense"
                    />
                    <Input
                      fullWidth
                      type="number"
                      name="triggerWeight"
                      id="triggerWeight"
                      label="Weight"
                      margin="dense"
                    />
                    <Select
                      id="renderType"
                      name="renderType"
                      label="Render Type"
                      margin="none"
                      fullWidth
                      options={fromJS([
                        { name: "", value: undefined },
                        { value: "none", name: "None" },
                        { value: "p", name: "p" },
                        { value: "div", name: "div" },
                        { value: "h1", name: "h1" },
                        { value: "h2", name: "h2" },
                        { value: "h3", name: "h3" },
                        { value: "h4", name: "h4" },
                        { value: "h5", name: "h5" },
                        { value: "ol", name: "ol" },
                        { value: "ul", name: "ul" },
                        { value: "li", name: "li" },
                        { value: "aside", name: "aside" },
                        { value: "figure", name: "figure" },
                        { value: "footer", name: "footer" },
                        { value: "header", name: "header" },
                        { value: "section", name: "section" },
                        { value: "time", name: "time" },
                        { value: "apxh:p", name: "apxh:p" },
                        { value: "apxh:div", name: "apxh:div" }
                      ])}
                      optionValueKey="value"
                      optionLabelKey="name"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Select
                      id="contentBlock"
                      name="contentBlock"
                      label="Element"
                      margin="none"
                      fullWidth
                      options={fromJS([
                        { name: "", value: undefined },
                        { value: "entryid", name: "EntryId" },
                        { value: "title", name: "Title" },
                        { value: "seotitle", name: "SEOTitle" },
                        { value: "seoslug", name: "SEOSlug" },
                        { value: "link", name: "Link" },
                        { value: "summary", name: "Summary" },
                        { value: "expiredon", name: "Expired On" },
                        {
                          value: "metadescription",
                          name: "Meta Description"
                        },
                        { value: "rights", name: "Rights" },
                        { value: "library", name: "Library" },
                        { value: "featuredimage", name: "Featured Image" },
                        {
                          value: "featuredimagecaption",
                          name: "Featured Image Caption"
                        },
                        { value: "introparagraph", name: "Intro Paragraph" },
                        { value: "content", name: "Content" },
                        { value: "bottomheading", name: "Bottom Heading" },
                        { value: "bottomparagraph", name: "Bottom Paragraph" },
                        { value: "schemaorg", name: "SchemaOrg" },
                        {
                          value: "clickdescription",
                          name: "Click Description"
                        },
                        { value: "byline", name: "AP ByLine" },
                        { value: "categories", name: "Categories (JSON)" },
                        { value: "dscategories", name: "DS Categories (JSON)" },
                        {
                          value: "outputexperimentlog",
                          name: "Output Experiment Log (JSON)"
                        },
                        { value: "tags", name: "Tags (JSON)" },
                        {
                          value: "apcontentmanagementcharacteristicsmediatype",
                          name: "AP Characteristic Media Type"
                        },
                        { value: "apdateline", name: "AP DateLine" },
                        {
                          value: "extendedheadline",
                          name: "AP Extended Headline"
                        },
                        { value: "headline", name: "AP Headline" },
                        { value: "managementid", name: "AP Management Id" },
                        {
                          value: "managementtype",
                          name: "AP Management Type"
                        },
                        {
                          value:
                            "apnewsmanagementpublishingspecialinstructions",
                          name: "AP Publishing Special Instructions"
                        },
                        {
                          value: "apnewsmanagementpublishingstatus",
                          name: "AP Publishing Status"
                        },
                        {
                          value: "apnewsmanagementmanagementsequencenumber",
                          name: "AP Sequence Number"
                        },
                        { value: "slugline", name: "AP SlugLine" }
                      ])}
                      optionValueKey="value"
                      optionLabelKey="name"
                    />
                    <Input
                      fullWidth
                      name="contentsection"
                      id="contentSection"
                      label="Section"
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Select
                      id="statusType"
                      name="status_Id"
                      label="Status"
                      margin="dense"
                      fullWidth
                      options={fromJS([
                        { name: "No Change", value: undefined },
                        { name: "Draft", value: 1 },
                        { name: "Review", value: 2 },
                        { name: "Published", value: 3 },
                        { name: "Planned", value: 4 },
                        { name: "Sample", value: 5 }
                      ])}
                      optionValueKey="value"
                      optionLabelKey="name"
                    />
                    {!isLibrary && (
                      <Select
                        id="libraryParagraph_id"
                        name="libraryParagraph_id"
                        label="Library Paragraph"
                        margin="dense"
                        fullWidth
                        options={fromJS(
                          narrative.libraryParagraphs &&
                            narrative.libraryParagraphs.map(option => ({
                              ...option,
                              combinedName: `${option.id} - ${option.name}`
                            }))
                        )}
                        optionValueKey="id"
                        optionLabelKey="combinedName"
                      />
                    )}
                    {outlineBlocks && (
                      <Select
                        id="narrativeOutlineBlockId"
                        name="narrativeOutlineBlockId"
                        label="Outline Block"
                        margin="dense"
                        fullWidth
                        options={fromJS(
                          narrative.outlineBlocks &&
                            narrative.outlineBlocks.map(option => ({
                              ...option,
                              combinedName: `${option.id} - ${option.contentSection}`
                            }))
                        )}
                        optionValueKey="id"
                        optionLabelKey="combinedName"
                      />
                    )}
                    <Field name="isArchived">
                      {({ field }) => (
                        <FormControlLabel
                          control={<Checkbox {...field} />}
                          label="Archived"
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div style={{ padding: "12px", textAlign: "end" }}>
                  <Button type="button" onClick={toggleBulkEditor}>
                    Cancel
                  </Button>
                  <Button type="submit">Update</Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </Body>
      </Form>
    ),
    [
      buildFromOutline,
      bulkEditValues,
      isLibrary,
      narrative.libraryParagraphs,
      narrative.outlineBlocks,
      outlineBlocks,
      updateSelected
    ]
  )

  // Load Column Width settings
  let cookies = new Cookies()
  let columnWidths = cookies.get("columnWidths") || {}

  const loadSelectedRow = row => {
    if (!row.original.id) {
      return
    }
    data[row.index].isLoadingRow = true
    setData([...data])
    const getParagraph = NarrativeDAO.getParagraph(row.original.id)
    getParagraph.then(response => {
      if (response) {
        data[row.index] = response
        row.original.isLoadingRow = false
        setData([...data])
      }
    })
  }

  const paragraphColumns = React.useMemo(() => {
    const FilterByInput = ({ getValue, row, column: { id }, table }) => {
      const { index } = row
      const [value, setValue] = useState("")
      const initialValue = getValue()

      const classes = useStyles()
      const { root, acOption2 } = useStyles()
      const onChange = (e, option) => {
        let optionValue = []
        option.forEach(itm => {
          optionValue.push({ tagId: itm.tagId, tagTypeId: itm.tagTypeId })
        })
        table.options.meta?.handleDataChange(index, id, optionValue)
        setValue(optionValue)
      }

      const narrativeParagraphTagsSummarySorted =
        narrativeParagraphTagsSummary || []
      const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
      const checkedIcon = (
        <CheckBoxIcon fontSize="small" className={classes.checkRegion} />
      )
      React.useEffect(() => {
        setValue(initialValue)
      }, [initialValue])
      return (
        <>
          <Autocomplete
            multiple={true}
            classes={{
              root,
              option: acOption2,
              noOptions: acOption2
            }}
            size="small"
            fullWidth={false}
            limitTags={4}
            name="libraryParagraphTagsSelected"
            id="libraryParagraphTagsSelected"
            value={narrativeParagraphTagsSummarySorted.filter(rgn => {
              let match = false
              value &&
                value.forEach(itm => {
                  if (
                    itm.tagId === rgn.tagId &&
                    itm.tagTypeId === rgn.tagTypeId
                  ) {
                    match = true
                  }
                })
              return match
            })}
            options={narrativeParagraphTagsSummarySorted}
            onChange={onChange}
            getOptionLabel={option => `${option.tagTypeId} - ${option.tagName}`}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={selected}
                />
                {option.tagTypeId} - {option.tagName}
              </li>
            )}
            isOptionEqualToValue={(option, value) =>
              option && value ? option.tagId === value.tagId : false
            }
            renderInput={params => <TextField {...params} />}
            disabled={!row.getIsSelected()}
          />
        </>
      )
    }

    const LibraryParagraphInput = ({
      getValue,
      row,
      column: { id },
      table
    }) => {
      const { index } = row
      // We need to keep and update the state of the cell normally
      const { root, inputRoot, acOption } = useStyles()
      const [value, setValue] = useState("")
      const initialValue = getValue()

      const onChange = (e, option) => {
        setValue(option)
        table.options.meta?.handleDataChange(
          index,
          id,
          option ? option.id : null
        )
      }
      const filterByValue =
        data[index] && data[index].filterBy ? data[index].filterBy : []

      // If the initialValue is changed external, sync it up with our state
      React.useEffect(() => {
        setValue(
          libraryParagraphs.find(option => {
            if (initialValue === option.id) {
              return option
            }
          })
        )
      }, [initialValue])

      const narrativeParagraphTagsSorted = narrativeParagraphTags || []
      let filteredLibraryParagraphsById = []
      narrativeParagraphTagsSorted.forEach(itm => {
        filterByValue.forEach((itm2, index) => {
          if (itm.tagId === itm2.tagId && itm.tagTypeId === itm2.tagTypeId) {
            if (
              !filteredLibraryParagraphsById.includes({
                id: itm.paragraphId,
                index
              })
            ) {
              filteredLibraryParagraphsById.push({
                id: itm.paragraphId,
                index
              })
            }
          }
        })
      })

      const filteredLibraryParagraphs = filteredLibraryParagraphsById.length
        ? libraryParagraphs.filter(itm => {
            let found = []
            filteredLibraryParagraphsById.forEach(itm2 => {
              if (itm.id === itm2.id) {
                if (!found.includes(itm2.index)) {
                  found.push(itm2.index)
                }
              }
            })
            return found.length === filterByValue.length
          })
        : libraryParagraphs || []
      return (
        <>
          <Autocomplete
            classes={{
              root,
              inputRoot,
              option: acOption,
              noOptions: acOption
            }}
            id={id}
            value={value}
            options={filteredLibraryParagraphs}
            onChange={onChange}
            PopperComponent={CustomPopper}
            getOptionLabel={option =>
              option
                ? option.name !== null
                  ? `${option.id} - ${option.name}`
                  : ""
                : ""
            }
            renderOption={(props, option) => (
              <li {...props}>
                <Icon className={classes.statusIcon}>
                  {labelStatusIcon(option.status_Id)}
                </Icon>
                {option.id} - {option.name}
                <SaturationChip
                  saturation={option.saturation}
                  utilization={option.utilization}
                />
              </li>
            )}
            isOptionEqualToValue={(option, value) =>
              option && value ? option.id === value.id : false
            }
            renderInput={params => <TextField {...params} />}
            disabled={!row.getIsSelected()}
          />
        </>
      )
    }

    const OutlineBlockInput = ({ getValue, row, column: { id }, table }) => {
      const { index } = row
      const { root, inputRoot, acOption, muiD } = useStyles()
      const value = getValue()

      const onChange = (e, option) => {
        table.options.meta?.handleDataChange(
          index,
          id,
          option ? option.id : null
        )
      }

      // If the initialValue is changed external, sync it up with our state
      const selected = outlineBlocks.find(option => value === option.id)

      // Render a multi-select box
      return (
        <Autocomplete
          aria-label="ParagraphTable OutlineBlock ContentSection"
          classes={{
            root,
            inputRoot,
            option: acOption,
            noOptions: acOption,
            muiD
          }}
          id={id}
          value={value}
          options={outlineBlocks}
          onChange={onChange}
          PopperComponent={CustomPopper}
          inputValue={
            (selected && `${selected.id} - ${selected.contentSection}`) || ""
          }
          getOptionLabel={option =>
            option ? `${option.id} - ${option.contentSection}` : ""
          }
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id === value.id : false
          }
          renderInput={params => <TextField {...params} />}
          disabled={!row.getIsSelected()}
        />
      )
    }
    return [
      {
        header: "Block Info",
        columns: isLibrary
          ? [
              {
                header: "Id",
                accessorKey: "id",
                cell: context =>
                  context.row.original.id
                    ? LinkParagraph(context)
                    : BasicCell(context)
              },
              {
                header: "Narrative",
                accessorKey: "narrative_Id",
                cell: BasicCell
              },
              {
                header: "Element",
                accessorKey: "contentBlock",
                meta: {
                  filterUi: ContentElementFilter
                },
                filterFn: "equals",
                aggregate: "uniqueCount",
                Aggregated: ({ value }) => `${value} Unique Names`,
                cell: ContentElementInput
              },
              {
                header: "Primary Tag (Structure)",
                accessorKey: "primaryTagId",
                cell: table => AutocompleteInput(table, primaryTags),
                meta: {
                  filterUi: SelectColumnAltFilter,
                  acOptions: primaryTags
                },
                size: 180
              },
              {
                header: "Secondary Tag (Intent)",
                accessorKey: "secondaryTagIds",
                cell: table => AutocompleteMultiInput(table, secondaryTags),
                meta: {
                  filterUi: SelectColumnAltArrayFilter,
                  acOptions: secondaryTags
                },
                size: 200
              },
              {
                header: "Form Factor Tag (Style)",
                accessorKey: "formFactorTagId",
                cell: table => AutocompleteInput(table, formFactorTags),
                meta: {
                  filterUi: SelectColumnAltFilter,
                  acOptions: formFactorTags
                },
                size: 180
              },
              {
                header: "Third Party Tag (Org/Data Source)",
                accessorKey: "thirdPartyTagIds",
                cell: table =>
                  AutocompleteMultiInput(table, thirdPartySpecificTags),
                meta: {
                  filterUi: SelectColumnAltArrayFilter,
                  acOptions: thirdPartySpecificTags
                },
                size: 180
              },
              {
                header: "Status",
                accessorKey: "status_Id",
                cell: StatusTypeInput,
                filterFn: "equals",
                meta: {
                  filterUi: StatusTypeFilter
                }
              },
              {
                header: "Owner",
                accessorKey: "ownerId",
                cell: OwnerInput,
                meta: {
                  filterUi: OwnerFilter
                },
                filterFn: "equals"
              },
              {
                header: "Name",
                accessorKey: "name",
                cell: EditableCell,
                meta: {
                  filterUi: DefaultColumnFilter
                },
                filterFn: "includesString"
              }
            ]
          : [
              {
                header: "Id",
                accessorKey: "id",
                cell: context =>
                  context.row.original.id
                    ? LinkParagraph(context)
                    : BasicCell(context)
              },
              {
                header: "Narrative",
                accessorKey: "narrative_Id",
                cell: BasicCell
              },
              {
                header: "Element",
                accessorKey: "contentBlock",
                meta: {
                  filterUi: ContentElementFilter
                },
                filterFn: "equals",
                aggregate: "uniqueCount",
                Aggregated: ({ value }) => `${value} Unique Names`,
                cell: ContentElementInput
              },
              {
                header: "Status",
                accessorKey: "status_Id",
                cell: StatusTypeInput,
                filterFn: "equals",
                meta: {
                  filterUi: StatusTypeFilter
                }
              },
              {
                header: "Owner",
                accessorKey: "ownerId",
                cell: OwnerInput,
                meta: {
                  filterUi: OwnerFilter
                },
                filterFn: "equals"
              },
              {
                header: "Name",
                accessorKey: "name",
                cell: EditableCell,
                meta: {
                  filterUi: DefaultColumnFilter
                },
                filterFn: "includesString"
              }
            ]
      },
      {
        header: "Render Info",
        columns: [
          {
            header: "Outline Block",
            accessorKey: "narrativeOutlineBlockId",
            meta: {
              filterUi: SelectColumnFilter
            },
            filterFn: "equals",
            cell: OutlineBlockInput
          },
          {
            header: "Position",
            accessorKey: "position",
            meta: {
              filterUi: SelectColumnFilter
            },
            filterFn: "equals",
            cell: buildFromOutline ? BasicCell : EditableCell
          },
          {
            header: "Section",
            accessorKey: "contentSection",
            cell: EditableCell,
            meta: {
              filterUi: SelectColumnFilter
            },
            filterFn: "includesString"
          },
          {
            header: "Render As",
            accessorKey: "renderType",
            meta: {
              filterUi: SelectColumnFilter
            },
            cell: RenderTypeInput
          },
          {
            header: "Comments",
            accessorKey: "comments",
            cell: EditableCell,
            meta: {
              filterUi: DefaultColumnFilter
            },
            filterFn: "includesString"
          }
        ]
      },
      {
        header: "Library Info",
        columns:
          isLibrary || !narrative.libraryNarrative_Ids
            ? [
                {
                  header: () => (
                    <div>
                      <span
                        style={{
                          fontWeight: "bold"
                        }}
                      >
                        Library Paragraph Id
                      </span>
                      <ContentSaturationParagraphModal />
                    </div>
                  ),
                  id: "libraryParagraph_Id",
                  accessorKey: "libraryParagraph_Id",
                  cell: hasLibraryData ? LibraryParagraphInput : EditableCell
                },
                {
                  header: "Library Block Position Filter",
                  accessorKey: "libraryBlockPositionsFilter",
                  cell: LibraryBlockPositionsFilterCell
                },
                {
                  header: () => (
                    <div>
                      <span
                        style={{
                          fontWeight: "bold"
                        }}
                      >
                        Paragraph Variation Score
                      </span>
                      <ContentSaturationParagraphModal />
                    </div>
                  ),
                  id: "paragraphVariationScore",
                  accessorKey: "paragraphVariationScore",
                  cell: SaturationChipInput,
                  show: false
                }
              ]
            : [
                {
                  header: "Filter By",
                  accessorKey: "filterBy",
                  cell: FilterByInput,
                  enableColumnFilter: false
                },
                {
                  header: () => (
                    <div>
                      <span
                        style={{
                          fontWeight: "bold"
                        }}
                      >
                        Library Paragraph Id
                      </span>
                      <ContentSaturationParagraphModal />
                    </div>
                  ),
                  id: "libraryParagraph_Id",
                  accessorKey: "libraryParagraph_Id",
                  cell: hasLibraryData ? LibraryParagraphInput : EditableCell,
                  meta: {
                    filterUi: DefaultColumnFilter
                  }
                },
                {
                  header: "Library Block Position Filter",
                  accessorKey: "libraryBlockPositionsFilter",
                  cell: LibraryBlockPositionsFilterCell,
                  meta: {
                    filterUi: DefaultColumnFilter
                  }
                },
                {
                  header: () => (
                    <div>
                      <span
                        style={{
                          fontWeight: "bold"
                        }}
                      >
                        Paragraph Variation Score
                      </span>
                      <ContentSaturationParagraphModal />
                    </div>
                  ),
                  id: "paragraphVariationScore",
                  accessorKey: "paragraphVariationScore",
                  cell: SaturationChipInput,
                  show: false
                }
              ]
      },
      {
        header: "Trigger Info",
        columns: [
          {
            header: "Triggers",
            accessorKey: "triggerType",
            cell: EditableCell,
            meta: {
              filterUi: DefaultColumnFilter
            },
            filterFn: "includesString"
          },
          {
            header: "Trigger Ids",
            accessorKey: "triggerIds",
            cell: EditableCell,
            filterFn: "startsWith",
            meta: {
              filterUi: DefaultColumnFilter
            }
          },
          {
            header: "Weight",
            accessorKey: "triggerWeight",
            cell: EditableCell,
            meta: {
              filterUi: SelectColumnFilter
            }
          }
        ]
      },
      {
        header: "Details",
        columns: [
          {
            header: "Attributes",
            accessorKey: "blockAttributes",
            cell: EditableCell,
            meta: {
              filterUi: DefaultColumnFilter
            },
            filterFn: "includesString"
          },
          {
            header: "Archived",
            accessorKey: "isArchived",
            cell: SwitchInput
          }
        ]
      }
    ]
  }, [
    buildFromOutline,
    columnWidths,
    hasLibraryData,
    libraryParagraphs,
    outlineBlocks
  ])

  const columns = [
    {
      id: "select",
      size: 100,
      header: ({ column, table }) => (
        <React.Fragment>
          <div style={{ paddingBottom: "5px" }}>
            <span>Select</span>
          </div>
          <div>
            <SelectRowsHeaderActions
              column={column}
              table={table}
              loadSelectedRow={row => {
                loadSelectedRow(row)
              }}
            />
          </div>
        </React.Fragment>
      ),
      cell: SelectRowCell,
      meta: {
        removeJustSavedRowFromFilter,
        saveItem: saveParagraph,
        cancelChanges,
        loadSelectedRow
      }
    },
    ...paragraphColumns
  ]

  const autonameParagraph = paragraph => {
    // Check to see if they gave us a Library Paragraph Id
    if (
      paragraph.libraryParagraph_Id &&
      paragraph.libraryParagraph_Id !== "" &&
      paragraph.name === ""
    ) {
      // Find the library Paragraph in the list of library paragraphs we may have been passed
      const libraryParagraph =
        libraryParagraphs &&
        libraryParagraphs.find(lp => lp.id === paragraph.libraryParagraph_Id)
      // Set the name of the block if we were able to find a matching Library Paragraph
      let libraryName =
        (libraryParagraph &&
          `${libraryParagraph.id} - ${libraryParagraph.name}`) ||
        paragraph.libraryParagraph_Id
      libraryName = paragraph.libraryBlockPositionsFilter
        ? `${libraryName} ${paragraph.libraryBlockPositionsFilter}`
        : libraryName
      // Assign the name to the paragraph based on the results
      paragraph.name = libraryName
        ? `Lib. Para. ${libraryName}`
        : paragraph.name
    }
    // Return the Paragraph whether changed or not
    return paragraph
  }

  // When our cell renderer calls updateLocalCollection, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateLocalCollection = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    skipResetRef.current = true
    setData((old = []) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          if (row[columnId] !== value) {
            addChangedRow(rowIndex)
          }
          const newRow = {
            ...row,
            [columnId]: value
          }
          return autonameParagraph(newRow)
        }
        return row
      })
    )
  }

  // After data changes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => {
    skipResetRef.current = false
  }, [data])

  return (
    <div>
      {title && <h3>{title}</h3>}
      <Table
        tableType="Paragraph"
        columns={columns}
        hideColumns={hiddenColumns}
        data={data}
        enableColumnFilters
        primaryTagOptions={props.primaryTags}
        handleDataChange={updateLocalCollection}
        loadBulkEditComponent={loadBulkEditComponent}
        addElements={addElements}
        duplicateElements={duplicateElements}
        deepCopyElements={deepCopyElements}
        bulkSaveElements={bulkSaveElements}
        bulkArchiveElements={bulkArchiveElements}
        bulkAssignToOutlineBlock={outlineBlockId && bulkAssignToOutlineBlock}
        bulkAssignTriggerIds={bulkAssignTriggerIds}
        updateSelected={updateSelected}
        loadSelectedRow={loadSelectedRow}
        ownerListForOrg={props.ownerListForOrg}
        skipReset={skipResetRef.current}
        changedRows={changedRowIndexes}
      />
      <CustomDSDialog
        open={warningDialogOpen}
        content={processMessageList(warningContent)}
        icon={<Warning style={{ fontSize: "4rem" }} />}
        backgroundColor={"#FFC107"}
        onClose={() => {
          setWarningDialogOpen(false)
          setWarningContent([])
        }}
      />
    </div>
  )
}

ParagraphTable.propTypes = {
  narrative: PropTypes.object,
  bulkAlertMessage: PropTypes.func,
  isAlertActive: PropTypes.func,
  loading: PropTypes.bool,
  setActiveView: PropTypes.func,
  ownerListForOrg: PropTypes.array,
  primaryTags: PropTypes.array,
  secondaryTags: PropTypes.array,
  formFactorTags: PropTypes.array,
  thirdPartySpecificTags: PropTypes.array,
  exportSentence: PropTypes.func,
  exportParagraph: PropTypes.func,
  exportTrigger: PropTypes.func,
  activeView: PropTypes.string
}
