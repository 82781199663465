import React from "react"
import PropTypes from "prop-types"
import {
  IconButton,
  Dialog,
  DialogTitle,
  CircularProgress
} from "@mui/material"
import { withStyles } from "@mui/styles"
import { Check, PriorityHigh } from "@mui/icons-material"

const styles = () => ({
  testPassed: {
    color: "#008000"
  },
  testFailed: {
    color: "#ff0000"
  }
})

function getResultsFromTrigger(evaluatedTrigger) {
  const {
    executedStartDateTime,
    executedEndDateTime,
    elapsedTimeMilliseconds,
    hasErrors,
    contextError,
    formula,
    renderString,
    isTrue,
    name,
    type
  } = evaluatedTrigger

  let triggerResultData = {
    name,
    type,
    formula,
    renderString,
    executedStartDateTime,
    executedEndDateTime,
    elapsedTimeMilliseconds,
    isTrue,
    hasErrors,
    contextError
  }
  return triggerResultData
}
function TestResultsDialog(props) {
  const { evaluatedTrigger, open, handleClose } = props
  let triggerResultData = getResultsFromTrigger(evaluatedTrigger)

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {!props.isSentence ? "Trigger" : "Sentence"} Test Results
      </DialogTitle>
      <pre>
        <code>{JSON.stringify(triggerResultData, undefined, 2)}</code>
      </pre>
    </Dialog>
  )
}
TestResultsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  evaluatedTrigger: PropTypes.string.isRequired
}

function TestResults(props) {
  const { row, classes } = props
  const { original: triggerResults } = row
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  if (triggerResults.isTesting) {
    return (
      <IconButton size="small" style={{ padding: "0" }}>
        <CircularProgress size={20} />
      </IconButton>
    )
  }

  if (!triggerResults || !triggerResults.testComplete) {
    return null
  }

  return (
    <>
      <IconButton
        size="small"
        style={{ padding: "0" }}
        onClick={handleClickOpen}
        title={
          triggerResults.hasErrors
            ? triggerResults.contextError || triggerResults.renderString
            : "No errors detected"
        }
      >
        {triggerResults.hasErrors ? (
          <PriorityHigh className={classes.testFailed} />
        ) : (
          <Check className={classes.testPassed} />
        )}
      </IconButton>
      <TestResultsDialog
        evaluatedTrigger={triggerResults}
        open={open}
        handleClose={handleClose}
        isSentence={props.isSentence}
      />
    </>
  )
}
TestResults.propTypes = {
  row: PropTypes.object.isRequired,
  isSentence: PropTypes.bool
}
export default withStyles(styles)(TestResults)
