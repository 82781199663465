// eslint-disable-next-line no-unused-vars
import React, { Fragment } from "react"
import ReactTable from "react-table-6"
import { useObserver } from "mobx-react"
import { Add } from "@mui/icons-material"
import classnames from "classnames"
import matchSorter from "match-sorter"

import { useStore } from "contexts/rootContext"
import Button from "components/CustomButtons/Button"
import FormattedDate from "components/FormattedDate"
import PermissionsList from "./PermissionGroupList/PermissionsList"
import "assets/css/Components/Table.css"

function UserStoreData() {
  const store = useStore()
  return useObserver(() => ({
    permissionGroups: store.permissionGroupStore.permissionGroups,
    loading: store.permissionGroupStore.loading,
    isModalOpen: store.permissionGroupStore.isModalOpen,
    openModal: store.permissionGroupStore.openModal,
    isPermissionGroupSelected:
      store.permissionGroupStore.isPermissionGroupSelected,
    getPermissionGroups: store.permissionGroupStore.getPermissionGroups
  }))
}

const PermissionGroupList = () => {
  const { permissionGroups, loading, openModal } = UserStoreData()

  const onTDClick = (state, rowInfo) => ({
    className: "selectable",
    title: "Edit Permission Group",
    onClick: () => {
      const {
        original: { id }
      } = rowInfo
      openModal(id)
    }
  })

  const hasWindowObject = typeof window !== "undefined"
  const screenWidth = hasWindowObject ? window.innerWidth : null
  const screenHeight = hasWindowObject ? window.innerHeight : null

  const showPagination =
    screenWidth && screenHeight && (screenHeight > 959 || screenWidth > 959)

  return (
    <Fragment>
      <Button
        variant="contained"
        color="primary"
        onClick={() => openModal()}
        startIcon={<Add />}
      >
        Add Permission Group
      </Button>
      <ReactTable
        loading={loading}
        // freezeWhenExpanded prevents the table from re-rendering and resetting isExpanded for expanded rows
        freezeWhenExpanded
        data={permissionGroups.toJS()}
        defaultSorted={[{ id: "modifiedDateTime", desc: true }]}
        minRows={0}
        SubComponent={({ original: { id } }) => (
          <PermissionsList groupId={id} />
        )}
        columns={[
          {
            Header: "",
            className: classnames("no-wrap", "center"),
            width: 25,
            expander: true
          },
          {
            Header: "Permission Group Name",
            accessor: "name",
            getProps: onTDClick,
            filterable: true,
            filterAll: true,
            filterMethod: ({ id, value }, rows) =>
              matchSorter(rows, value, {
                keys: [id],
                threshold: matchSorter.rankings.CONTAINS
              })
          },
          {
            Header: "User Count",
            accessor: "userCount",
            className: classnames("no-wrap", "center"),
            width: 110,
            getProps: onTDClick
          },
          {
            Header: "Updated",
            accessor: "modifiedDateTime",
            getProps: onTDClick,
            width: 100,
            className: classnames("no-wrap", "center"),
            Cell: ({ value }) => <FormattedDate>{value}</FormattedDate>
          }
        ]}
        defaultPageSize={25}
        showPaginationTop={showPagination}
        showPaginationBottom={showPagination}
        className="-striped -highlight -scrollEntries"
        style={{
          maxHeight: showPagination
            ? `${(window.innerHeight * 0.71).toFixed(0)}px`
            : "auto"
        }}
      />
    </Fragment>
  )
}

export default PermissionGroupList
