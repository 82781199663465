// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react"
import { useObserver } from "mobx-react"
import { useHistory } from "react-router-dom"
import { useStore } from "contexts/rootContext"
import { Form } from "components/Form"
import Wizard from "components/Wizard"
import {
  narrative as validationSchemaOutput,
  narrativeLibrary as validationSchemaLibrary,
  narrativeNoLeague as validationSchemaOutputNoLeague,
  narrativeLibraryNoLeague as validationSchemaLibraryNoLeague
} from "schemas/forms"
import NarrativeBuilderStep1 from "./NarrativeBuilder/NarrativeBuilderStep1"
import NarrativeBuilderStep2 from "./NarrativeBuilder/NarrativeBuilderStep2"
import { ENTERTAINMENT_VERTICAL_ID } from "constants"

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    narrative: store.narrativeStore.narrative,
    narratives: store.narrativeStore.narratives,
    postNarrative: store.narrativeStore.postNarrative,
    getTriggers: store.narrativeStore.getTriggers,
    getModelTypes: store.narrativeStore.getModelTypes,
    getNarratives: store.narrativeStore.getNarratives,
    getOrganizations: store.organizationStore.getOrganizations,
    getArticleTypes: store.narrativeStore.getArticleTypes,
    getContentTypes: store.narrativeStore.getContentTypes,
    getDatasources: store.narrativeStore.getDatasources,
    getFeeds: store.organizationStore.getFeeds,
    getQueries: store.queryBuilderStore.getQueries,
    getVerticals: store.narrativeStore.getVerticals,
    verticals: store.narrativeStore.verticals
  }))
}

const NarrativeBuilder = () => {
  const history = useHistory()
  const {
    narrative,
    postNarrative,
    getModelTypes,
    getNarratives,
    getOrganizations,
    getTriggers,
    getArticleTypes,
    getDatasources,
    getContentTypes,
    getFeeds,
    getQueries
  } = useStoreData()

  // on first render
  useEffect(() => {
    getFeeds(narrative?.organization?.id)
    getModelTypes()
    getNarratives()
    getOrganizations()
    getTriggers()
    getArticleTypes()
    getDatasources()
    getContentTypes()
    getQueries()
  }, [])

  const [saveAndViewSubmit, setSaveAndViewSubmit] = useState(false)

  const handleSubmit = async values => {
    setSaveAndViewSubmit(true)
    try {
      const valuesToSend = {
        ...values,
        feedFields: values.feedFieldsArray?.join(","),
        libraryNarrative_Ids: values.libraryNarrativeIdsArray?.join(","),
        leagueId: values.leagueId === "-1" ? -1 : values.leagueId
      }
      const upsertedNarrative = await postNarrative(valuesToSend)
      history.push(`/portal/narrative/${upsertedNarrative.get("id")}`)
    } catch (err) {
      console.error(`Error posting narrative ${values?.id}`, err)
    }
  }

  let steps = [{ component: NarrativeBuilderStep1 }]

  // When not a 'Library' narrative, both steps are necessary
  if (!narrative?.isLibrary) {
    steps.push({ component: NarrativeBuilderStep2 })
  }

  return (
    <Form
      id="narrative-form"
      name="narrative-form"
      initialValues={narrative}
      onSubmit={handleSubmit}
      validationSchema={
        narrative?.isLibrary
          ? narrative?.verticalId === ENTERTAINMENT_VERTICAL_ID
            ? validationSchemaLibraryNoLeague
            : validationSchemaLibrary
          : narrative?.verticalId === ENTERTAINMENT_VERTICAL_ID
          ? validationSchemaOutputNoLeague
          : validationSchemaOutput
      }
    >
      <Wizard
        completeButtonText="Save and View"
        saveAndViewSubmitted={saveAndViewSubmit}
        steps={steps}
      />
    </Form>
  )
}

export default NarrativeBuilder
