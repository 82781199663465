const triggerIdStrings = ["trigger", "insights", "dependencies"]

// entities are strings of item type and ID: `{itemType}-{id}`
const useEntityHelper = ({ entities = [], type = false }) => {
  const getFilteredEntitiesByRegex = regex =>
    entities.filter(entity => entity.match(regex))

  const getFilteredEntitiesByStrings = strings =>
    entities.filter(entity => strings.some(str => entity.includes(str)))

  const eligibleEntities =
    type === "OutlineMover"
      ? getFilteredEntitiesByRegex(/outlineBlockId/)
      : getFilteredEntitiesByRegex(/triggerid|sentenceid|paragraphid/)

  const eligibleEntitiesCount = eligibleEntities.length

  const hasEntities = () => eligibleEntitiesCount > 0

  const haveSomeEntityByRegex = regex => {
    const result = getFilteredEntitiesByRegex(regex)
    return !!result.length
  }

  const onlyTriggersInEntities = () => {
    if (!entities?.length) {
      return false
    }
    const entityTriggers = getFilteredEntitiesByStrings(triggerIdStrings)
    return entities.length === entityTriggers?.length
  }

  const noTriggersInEntities = () => {
    if (!entities?.length) {
      return true
    }
    const entityTriggers = getFilteredEntitiesByStrings(triggerIdStrings)
    return !entityTriggers?.length
  }

  const onlySentencesInEntities = () => {
    if (!entities?.length) {
      return false
    }
    const sentences = getFilteredEntitiesByRegex(/sentenceid/)
    return entities.length === sentences?.length
  }

  return {
    entities,
    getFilteredEntitiesByRegex,
    getFilteredEntitiesByStrings,
    eligibleEntities,
    eligibleEntitiesCount,
    hasEntities,
    haveSomeEntityByRegex,
    onlyTriggersInEntities,
    onlySentencesInEntities,
    noTriggersInEntities
  }
}

export default useEntityHelper
