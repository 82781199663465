import React from "react" //useMemo // useEffect // useState,
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { TreeView } from "@mui/x-tree-view/TreeView"
import { TreeItem } from "@mui/x-tree-view/TreeItem"
import { useHistory } from "react-router-dom"
import { PropTypes } from "prop-types"

function CTAPreviewDependencyBlocks(props) {
  const history = useHistory()

  return (
    <>
      <TreeView
        aria-label="CTAv2 PreviewDependency Navigation"
        defaultCollapseIcon={<ChevronRightIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        sx={{
          flexGrow: 1,
          overflowY: "auto"
        }}
      >
        <TreeItem
          nodeId="CTAPreviewDependency"
          label="CTA Preview Dependency"
          onClick={() => {
            history.push(
              `/portal/manage-ctas/${props.whichLinkType}?previewdependency=true`
            )
            props.goToPreviewDependencyBlocks()
          }}
        >
          <TreeItem
            nodeId={`CTAPreviewDependency-empty-placeholder}`}
            label=""
          />
        </TreeItem>
      </TreeView>
    </>
  )
}
CTAPreviewDependencyBlocks.propTypes = {
  goToPreviewDependencyBlocks: PropTypes.func
}

export default CTAPreviewDependencyBlocks
