function LibraryToolsConfirmDialogContent(props) {
  const {
    eligibleEntitiesCount,
    withAssociatedTriggers,
    targetNarrative,
    sourceNarrative,
    allSourceInfo,
    allTargetInfo
  } = props
  const styles = {
    textAlign: "left"
  }
  return (
    <div style={styles}>
      <u>
        The following{" "}
        {eligibleEntitiesCount - 1
          ? `${eligibleEntitiesCount} blocks`
          : `block`}
        {withAssociatedTriggers ? " (with triggers) " : " "}
        will be copied from:
      </u>
      <br />
      Narrative #{sourceNarrative?.id} - {sourceNarrative?.name}
      {eligibleEntitiesCount > 0 && (
        <ol>
          {allSourceInfo?.map(info => (
            <li
              key={info.paragraphId}
              style={{
                fontWeight: "bold"
              }}
            >
              {info.paragraphName
                ? `(${info.paragraphId}) Paragraph ${info.paragraphName}`
                : `(${info.paragraphId})`}
              {info.sentenceIds?.length && (
                <ul>
                  {info.sentenceIds?.map(sids => (
                    <li>{`Sentence (${sids.id}) ${sids.name}`}</li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ol>
      )}
      {targetNarrative?.name && (
        <div>
          <u>The blocks will be copied to:</u>
          <br />
          Narrative #{targetNarrative?.id} - {targetNarrative?.name}
        </div>
      )}
      {allTargetInfo?.length > 0 && (
        <ol>
          {allTargetInfo.map(info => (
            <li
              key={info.paragraphId}
              style={{
                fontWeight: "bold"
              }}
            >
              {info.paragraphName
                ? `To (${info.paragraphId}) Paragraph ${info.paragraphName}`
                : `To (${info.paragraphId}) Paragraph (Has no name)`}
            </li>
          ))}
        </ol>
      )}
    </div>
  )
}
export default LibraryToolsConfirmDialogContent
