// eslint-disable-next-line no-unused-vars
import React, { Component } from "react"
import PropTypes from "prop-types"
import { Field } from "formik"
import { FormControl, FormHelperText } from "@mui/material"

import AutocompleteComponent from "components/Autocomplete/Autocomplete"

const defaultProps = {
  displayEmpty: true,
  autoWidth: false,
  fullWidth: false,
  hideLabel: false,
  margin: "normal",
  multiple: false,
  readOnly: false,
  required: false,
  type: "text"
}

const propTypes = {
  displayEmpty: PropTypes.bool,
  autoWidth: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helpText: PropTypes.string,
  hideLabel: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  margin: PropTypes.oneOf(["none", "dense", "normal"]),
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool
}

class SearchInput extends Component {
  render() {
    const {
      fullWidth,
      helpText,
      id,
      label,
      margin,
      name,
      options,
      readOnly,
      required,
      nameField,
      valueField
    } = this.props

    return (
      <Field name={name}>
        {({
          form: { setFieldValue, setFieldTouched, submitCount },
          meta: { value, touched, error }
        }) => {
          const hasErrors = (submitCount || touched) && error && true
          return (
            <FormControl
              className="form__control"
              hiddenLabel={label && true}
              required={required}
              fullWidth={fullWidth}
              error={hasErrors}
              disabled={readOnly}
              margin={margin}
            >
              <AutocompleteComponent
                id={`${id}__input`}
                name={name}
                className="form__input"
                onChange={(event, newValue) => {
                  setFieldValue(name, newValue)
                }}
                onBlur={() => setFieldTouched(name, true)}
                options={options}
                nameField={nameField}
                valueField={valueField}
                label={label}
                fullWidth={fullWidth}
                value={value}
              />
              {helpText && (
                <FormHelperText
                  className="form__helper-text"
                  error={hasErrors}
                  disabled={readOnly}
                  required={required}
                >
                  {helpText}
                </FormHelperText>
              )}
            </FormControl>
          )
        }}
      </Field>
    )
  }
}

SearchInput.defaultProps = defaultProps
SearchInput.propTypes = propTypes
export default SearchInput
