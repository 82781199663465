import React, { Component } from "react"
import { observer, inject } from "mobx-react"
import { CopyToClipboard } from "react-copy-to-clipboard"

// MUI Components
import Alert from "@mui/material/Alert"
import Checkbox from "@mui/material/Checkbox"
import CircularProgress from "@mui/material/CircularProgress"
import Input from "@mui/material/Input"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import Slider from "@mui/material/Slider"
import TextField from "@mui/material/TextField"
import Tooltip from "@mui/material/Tooltip"
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import Switch from "@mui/material/Switch"
import Chip from "@mui/material/Chip"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"

// MUI Icons
import Check from "@mui/icons-material/Check"
import CloudDownload from "@mui/icons-material/CloudDownload"
import FileCopy from "@mui/icons-material/FileCopy"
import Fullscreen from "@mui/icons-material/Fullscreen"
import HelpOutline from "@mui/icons-material/HelpOutline"
import Save from "@mui/icons-material/Save"
import ImageIcon from "@mui/icons-material/Photo"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import withStyles from "@mui/styles/withStyles"

// Custom Components
import Button from "components/CustomButtons/Button"
import CustomInput from "components/CustomInput/CustomInput.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import { globalSettings } from "variables/general.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardIcon from "components/Card/CardIcon.jsx"
import Accordion from "components/Accordion/Accordion"
import Helpers from "tools/Helpers.js"
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle"
import "assets/css/resizable-box.css"
import GraphicsToolColorPicker from "./GraphicsToolColorPicker.js"
import { Cookies } from "tools/storage"
import Enums from "tools/Enums.js"
import ImageSearchTool from "views/ImageSearchTool/ImageSearchTool.jsx"

// Custom Data
import {
  loadTierLevels,
  loadDefaultTextValues,
  loadSportsbooks,
  loadWatermarkUrlChoices,
  loadBaseTemplates,
  loadBackgroundImageResizeChoices,
  opacityMarks
} from "./graphicsToolData"
import { get } from "tools/request.js"

const checkedIcon = <CheckBoxIcon fontSize="small" />
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />

const useStore = component =>
  inject(({ store }) => ({
    sortedAccountOrganizations: store.accountStore.sortedAccountOrganizations,
    sortedProperties: store.accountStore.sortedProperties,
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization,
    inputValues: store.graphicsToolStore.inputValues,
    setInputValues: store.graphicsToolStore.setInputValues,
    organizationId: store.organizationStore.organizationId,
    retrieveLeagues: store.sportsStore.retrieveLeagues,
    sortedLeagues: store.sportsStore.sortedLeaguesByName
  }))(observer(component))

class GraphicsTool extends Component {
  constructor(props) {
    super(props)
    let graphicsRenderStartTime = new Date()
    this.state = {
      templateId: GraphicsTool.defaultTemplateId,
      baseTemplates: loadBaseTemplates(),
      textFieldCount: 20,
      textValues:
        this.props.inputValues?.graphicsToolTextValues ||
        loadDefaultTextValues(),
      image1Url:
        this.props.inputValues?.graphicsToolImage1Url ||
        "https://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/2544.png",
      image2Url:
        this.props.inputValues?.graphicsToolImage2Url ||
        "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Los_Angeles_Lakers_logo.svg/1280px-Los_Angeles_Lakers_logo.svg.png",
      color1Hex: this.props.inputValues?.graphicsToolColor1Hex || "552583",
      color2Hex: this.props.inputValues?.graphicsToolColor2Hex || "fdb927",
      color3Hex: this.props.inputValues?.graphicsToolColor3Hex || "27788c",
      color4Hex: this.props.inputValues?.graphicsToolColor4Hex || "aa00cc",
      isUseColor1Hex:
        this.props.inputValues?.graphicsToolisUseColor1Hex || false,
      isUseColor2Hex:
        this.props.inputValues?.graphicsToolisUseColor2Hex || false,
      isUseColor3Hex:
        this.props.inputValues?.graphicsToolisUseColor3Hex || false,
      isUseColor4Hex:
        this.props.inputValues?.graphicsToolisUseColor4Hex || false,
      isRenderReferenceImage: false,
      watermarkUrlShortcutId:
        this.props.inputValues?.graphicsToolWatermarkUrlShortcutId || 3, // Default to using 'Organization's Logo' (3)
      watermarkUrlShortcutOption: {},
      watermarkUrl:
        this.props.inputValues?.graphicsToolWatermarkUrl ||
        "useorganizationlogo", // NOte: This prop is the determining factor in url construction.
      isUseImage1Url:
        this.props.inputValues?.graphicsToolisUseImage1Url || true,
      isUseImage2Url:
        this.props.inputValues?.graphicsToolisUseImage1Url || true,
      teamA: this.props.inputValues?.teamA || null,
      teamB: this.props.inputValues?.teamB || null,
      copySuccessText: "Copy script",
      graphicWidth: 1000,
      graphicHeight: 0,
      imageDownloadMinWidth: 200,
      imageDownloadMaxWidth: 2000,
      graphicDownloadMinHeight: 150,
      graphicDownloadMaxHeight: 1500,
      imageDownloadFormat: "jpeg",
      imageDownloadQuality: 80,
      isGraphicLoading: false,
      graphicUrl: "",
      altText: this.props.inputValues?.graphicAltText || "",
      filename: this.props.inputValues?.graphicFilename || "",
      isToDisplayTeamABetPercentage: true,
      isToDisplayTeamAStakePercentage: true,
      teamABetPercentage: 35,
      teamAStakePercentage: 64,
      isToDisplayTeamA: this.props.inputValues?.isToDisplayTeamA || false,
      isToDisplayTeamB: this.props.inputValues?.isToDisplayTeamB || false,
      teams: [],
      isLoadingTeams: false,
      geographicPlaces: [],
      isLoadingGeographicPlaces: false,
      geographicPlaceA: this.props.inputValues?.geographicPlaceA || null,
      geographicPlaceB: this.props.inputValues?.geographicPlaceB || null,
      isToDisplayGeographicPlaceA:
        this.props.inputValues?.isToDisplayGeographicPlaceA || false,
      isToDisplayGeographicPlaceB:
        this.props.inputValues?.isToDisplayGeographicPlaceB || false,
      players: [],
      isLoadingPlayers: false,
      playerA: this.props.inputValues?.playerA || null,
      playerB: this.props.inputValues?.playerB || null,
      isToDisplayPlayerA: this.props.inputValues?.isToDisplayPlayerA || false,
      isToDisplayPlayerB: this.props.inputValues?.isToDisplayPlayerB || false,
      organizationId: this.props.inputValues?.graphicsToolOrganizationId || -1,
      propertyId: this.props.inputValues?.graphicsToolPropertyId,
      isToDisplayProperties: false,
      selectedOrganizationId: this.props.inputValues?.selectedOrganizationId,
      graphicsRenderElaspedTimeMilliseconds:
        new Date().getTime() - graphicsRenderStartTime.getTime(),
      graphicNaturalWidth: 0,
      graphicNaturalHeight: 0,
      graphicDisplayWidth: 0,
      graphicDisplayHeight: 0,
      isScribanFriendly: false,
      sportsbookId: -1,
      isToDisplaySportsbooks: false,
      isToDisplayWatermark: false,
      tierLevel: 1, // Default to Tier 1
      isArchived: false,
      scriptType: 1,
      QRCode1Value: "",
      competitorGroupsOverride: null,
      competitorGroups: [],
      isLoadingCompetitorGroups: false,
      isToDisplayCompetitorGroupsOverride: false,
      imagePartFailures: "",
      backgroundImageOpacity: 100,
      backgroundImageUrl: "",
      backgroundImageResizeMode: 1, // 'Crop'
      isBackgroundImageGrayscale: false,
      isLoadingBackgroundImages: false,
      backgroundImages: [], // Available asset file images for selected organization
      leagueId: 0,
      leaguesUsedByAssets: [],
      isLoadingLeaguesUsedByAssets: false,
      backgroundFiles: [] // User choosen image files
    }
  }

  componentDidMount() {
    if (!this.props.isEmbedded) {
      this.props.setShowChangeCurrentOrganization(false)
    }
    this.loadTeams()
    this.loadGeographicPlaces()
    this.loadPlayers()
    this.loadCompetitorGroups()
    this.loadBackgroundImages()
    this.props.retrieveLeagues()
    this.loadLeaguesUsedByAssets()

    let cookies = new Cookies()
    let templateId =
      this.props.inputValues?.templateId ||
      cookies.get("graphicsToolTemplateId") ||
      GraphicsTool.defaultTemplateId
    this.changeTemplate(templateId)
  }

  static defaultTemplateId = 119
  static organizationIdsTier2 = [
    55, 81, 82, 83, 85, 86, 87, 88, 89, 90, 139, 236, 237, 71, 99, 100, 101,
    102, 170, 17, 94, 63, 137, 138, 203, 204, 368, 369, 370, 171, 207, 434, 205,
    206, 601, 863, 962, 632, 1461, 1460, 1492
  ]

  static organizationIdsTier3 = [1094, 269, 75, 74, 1061, 1160] // Betsperts.com, Betsperts, PlayPicks, TheLines, FoxSports, Foxsports.com

  onClearTextValuesButtonClick = () => {
    let newTextValues = [
      { key: 1, value: "" },
      { key: 2, value: "" },
      { key: 3, value: "" },
      { key: 4, value: "" },
      { key: 5, value: "" },
      { key: 6, value: "" },
      { key: 7, value: "" },
      { key: 8, value: "" },
      { key: 9, value: "" },
      { key: 10, value: "" },
      { key: 11, value: "" },
      { key: 12, value: "" },
      { key: 13, value: "" },
      { key: 14, value: "" },
      { key: 15, value: "" },
      { key: 16, value: "" },
      { key: 17, value: "" },
      { key: 18, value: "" },
      { key: 19, value: "" },
      { key: 20, value: "" }
    ]
    this.setState(
      {
        textValues: newTextValues
      },
      () => this.loadImage()
    )

    this.props.setInputValues({
      ...this.props.inputValues,
      graphicsToolTextValues: newTextValues
    })
  }

  onColorPickerChange = (colorKey, value) => {
    this.setState(
      {
        [`color${colorKey}Hex`]: value.replace("#", "")
      },
      () => this.loadImage()
    )
  }

  loadImage = () => {
    this.setState({ imagePartFailures: "", isGraphicLoading: true })
    get(this.buildGraphicsUrl()).then(response => {
      this.setState({
        imagePartFailures: response.headers.get("x-imagepart-failures")
      })
      response.blob().then(blob => {
        const objectURL = URL.createObjectURL(blob)
        this.setState({ imageObjectUrl: objectURL, isGraphicLoading: false })
      })
    })
  }

  onIsToUseColorChange = colorKey => {
    this.setState(
      {
        [`isUseColor${colorKey}Hex`]: !this.state[`isUseColor${colorKey}Hex`]
      },
      () => this.loadImage()
    )
  }

  onLoadDefaultTextValuesClick = () => {
    this.setState({ textValues: loadDefaultTextValues() }, () =>
      this.loadImage()
    )
    this.props.setInputValues({
      ...this.props.inputValues,
      graphicsToolTextValues: loadDefaultTextValues()
    })
  }

  loadTeams() {
    this.setState({ isLoadingTeams: true })
    get(`${globalSettings.apiBaseUrl}/api/graphics/teams`)
      .then(Response => Response.json())
      .then(Teams => {
        this.setState({
          isLoadingTeams: false,
          teams: Teams.content.teams || []
        })
      })
  }

  loadGeographicPlaces() {
    this.setState({ isLoadingGeographicPlaces: true })
    get(`${globalSettings.apiBaseUrl}/api/graphics/geographicplaces`)
      .then(Response => Response.json())
      .then(GeographicPlaces => {
        this.setState({
          isLoadingGeographicPlaces: false,
          geographicPlaces: GeographicPlaces.content.places || []
        })
      })
  }

  loadPlayers() {
    this.setState({ isLoadingPlayers: true })
    get(`${globalSettings.apiBaseUrl}/api/graphics/players`)
      .then(Response => Response.json())
      .then(players => {
        this.setState({
          isLoadingPlayers: false,
          players: players.content.players || []
        })
      })
  }

  loadCompetitorGroups() {
    this.setState({ isLoadingCompetitorGroups: true })
    get(`${globalSettings.apiBaseUrl}/api/graphics/competitorgroups`)
      .then(Response => Response.json())
      .then(response => {
        this.setState({
          isLoadingCompetitorGroups: false,
          competitorGroups: response.content || []
        })
      })
  }

  loadBackgroundImages() {
    if (this.state.selectedOrganizationId > 0) {
      this.setState({ isLoadingBackgroundImages: true })
      get(
        `${globalSettings.apiBaseUrl}/api/asset/assetsforgraphicstool?organizationid=${this.state.selectedOrganizationId}&leagueid=${this.state.leagueId}`
      )
        .then(Response => Response.json())
        .then(response => {
          this.setState({
            isLoadingBackgroundImages: false,
            backgroundImages: response.content.assets || []
          })
        })
    }
  }

  loadLeaguesUsedByAssets() {
    this.setState({ isLoadingLeaguesUsedByAssets: true })
    get(
      `${globalSettings.apiBaseUrl}/api/asset/leaguesusedbyassets?organizationid=${this.state.selectedOrganizationId}`
    )
      .then(Response => Response.json())
      .then(response => {
        this.setState({
          isLoadingLeaguesUsedByAssets: false,
          leaguesUsedByAssets: response.content || []
        })
      })
  }

  onValidateButtonClick = () => {
    this.validateAgainstLanguageToolOrg()
  }

  handleTextFieldChange = event => {
    let value = event.target.value
    const key = Number(event.target.id.replace("text", ""))
    const newTextValues = this.state.textValues.map(el =>
      el.key === key ? { ...el, value } : el
    )
    this.setState(
      {
        textValues: newTextValues
      },
      () => this.loadImage()
    )
    this.props.setInputValues({
      ...this.props.inputValues,
      graphicsToolTextValues: newTextValues
    })
  }

  handleTextChange = event => {
    let value = event.target.value
    const key = event.target.id
    if (key === "filename") {
      value = this.removeSpacesFromText(value)
      this.setState(
        {
          [key]: value
        },
        () => {
          this.buildScriptToCopy()
          this.loadImage()
        }
      )
    } else {
      this.setState(
        {
          [key]: value
        },
        () => this.loadImage()
      )
    }
  }

  handleSwitchChange = event => {
    const key = event.target.id
    this.setState(
      {
        isGraphicLoading: true,
        [key]: !this.state[key]
      },
      () => this.loadImage()
    )
  }

  handleSelectChange = event => {
    const value = event.target.value
    const key = event.target.name
    this.setState(
      {
        [key]: value
      },
      () => this.loadImage()
    )
  }

  handleMUIComponentChange = (id, newValue) => {
    const value = newValue
    const key = id
    this.setState(
      {
        [key]: value
      },
      () => this.loadImage()
    )
  }

  handleSliderInputChange = event => {
    const value = Number(event.target.value)
    const key = event.target.id
    this.setState(
      {
        [key]: value
      },
      () => this.loadImage()
    )
  }

  onQRCode1ValueChange = event => {
    this.setState(
      {
        QRCode1Value: event.target.value
      },
      () => {
        this.buildScriptToCopy()
        this.loadImage()
      }
    )
  }

  onTemplateChangeEvent = event => {
    this.changeTemplate(event.target.value)
    let cookies = new Cookies()
    cookies.set("graphicsToolTemplateId", event.target.value)
  }

  changeTemplate = templateId => {
    this.setState({
      isGraphicLoading: true,
      templateId
    })
    this.props.setInputValues({
      ...this.props.inputValues,
      templateId
    })
    let isToDisplayTeamA

    let isToDisplayTeamB

    let isToDisplayTeamABetPercentage = false

    let isToDisplayTeamAStakePercentage = false

    let isToDisplayGeographicPlaceA

    let isToDisplayGeographicPlaceB

    let isToDisplayPlayerA

    let isToDisplayPlayerB

    let isToDisplayColors = false

    let isToDisplayExternalImages = false

    let isToDisplaySportsbooks = false

    let isToDisplayWatermark = false

    let isToDisplayCompetitorGroupsOverride = false

    let graphicsIdsToDisplayTeamA = [
      75, 78, 79, 80, 81, 82, 83, 84, 85, 90, 91, 92, 93, 94, 95, 96, 97, 98,
      99, 100, 101, 102, 103, 104, 105, 106, 107, 113, 115, 116, 117, 119, 120,
      121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
      140
    ]
    let graphicsIdsToDisplayTeamB = [
      75, 78, 79, 81, 82, 83, 85, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
      101, 102, 103, 104, 105, 106, 107, 113, 115, 116, 117, 119, 120, 121, 124,
      126, 127, 128, 129, 133, 134, 140
    ]
    let graphicsIdsToDisplayPlayerA = [
      75, 78, 79, 80, 81, 82, 83, 84, 85, 90, 91, 92, 93, 94, 95, 96, 97, 98,
      99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113,
      114, 115, 116, 117, 119, 120, 122, 123, 124, 125, 126, 127, 128, 129, 130,
      131, 132, 133, 134, 135, 140
    ]
    let graphicsIdsToDisplayPlayerB = [
      75, 78, 79, 80, 81, 82, 83, 84, 85, 90, 91, 92, 93, 94, 95, 96, 97, 98,
      99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 113, 114,
      115, 116, 117, 119, 120, 124, 126, 127, 128, 129, 133, 134, 140
    ]
    let graphicsIdsToDisplayGeographicPlaceA = [108, 109, 110, 111, 112, 114]

    let graphicsIdsToDisplayGeographicPlaceB = [108, 109, 110, 111, 112, 114]

    let graphicsIdsToDisplayColorPicker = [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 75,
      107, 133, 134, 137, 138, 139
    ]
    let graphicsIdsToDisplaySportsbooks = [118]
    if (graphicsIdsToDisplaySportsbooks.includes(templateId)) {
      isToDisplaySportsbooks = true
    }
    if (graphicsIdsToDisplayColorPicker.includes(templateId)) {
      isToDisplayColors = true
    }
    let graphicsIdsToDisplayExternalImages = [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
      40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57,
      58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75,
      77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 90, 91
    ]

    let graphicsIdsToDisplayWatermark = [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
      40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57,
      58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75,
      77, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129,
      130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141
    ]

    if (graphicsIdsToDisplayExternalImages.includes(templateId)) {
      isToDisplayExternalImages = true
    }
    if (graphicsIdsToDisplayTeamA.includes(templateId)) {
      isToDisplayTeamA = true
      isToDisplayCompetitorGroupsOverride = true
    }
    if (graphicsIdsToDisplayTeamB.includes(templateId)) {
      isToDisplayTeamB = true
    }
    if (graphicsIdsToDisplayPlayerA.includes(templateId)) {
      isToDisplayPlayerA = true
      isToDisplayCompetitorGroupsOverride = true
    }
    if (graphicsIdsToDisplayPlayerB.includes(templateId)) {
      isToDisplayPlayerB = true
    }
    if (templateId === 75) {
      // Best Odds Money Line graphic
      isToDisplayTeamABetPercentage = true
      isToDisplayTeamAStakePercentage = true
    }
    if (graphicsIdsToDisplayGeographicPlaceA.includes(templateId)) {
      isToDisplayGeographicPlaceA = true
    }

    if (graphicsIdsToDisplayGeographicPlaceB.includes(templateId)) {
      isToDisplayGeographicPlaceB = true
    }

    if (graphicsIdsToDisplayWatermark.includes(templateId)) {
      isToDisplayWatermark = true
    }

    this.setState(
      {
        isToDisplayTeamA,
        isToDisplayTeamB,
        isToDisplayTeamABetPercentage,
        isToDisplayTeamAStakePercentage,
        isToDisplayGeographicPlaceA,
        isToDisplayGeographicPlaceB,
        isToDisplayPlayerA,
        isToDisplayPlayerB,
        isToDisplayColors,
        isToDisplayExternalImages,
        isToDisplaySportsbooks,
        isToDisplayWatermark,
        isToDisplayCompetitorGroupsOverride
      },
      () => this.loadImage()
    )

    this.props.setInputValues({
      ...this.props.inputValues,
      isToDisplayTeamA,
      isToDisplayTeamB,
      isToDisplayTeamABetPercentage,
      isToDisplayTeamAStakePercentage,
      isToDisplayGeographicPlaceA,
      isToDisplayGeographicPlaceB,
      isToDisplayPlayerA,
      isToDisplayPlayerB,
      isToDisplayColors,
      isToDisplayExternalImages,
      isToDisplaySportsbooks,
      isToDisplayWatermark,
      isToDisplayCompetitorGroupsOverride
    })
    this.setState({
      textFieldCount:
        this.state.baseTemplates.find(bt => bt.key === templateId)
          .textFieldCount ?? 20
    })
  }

  onOrganizationChange = event => {
    this.setState(
      {
        isToDisplayProperties: false,
        organizationId: event.target.value,
        selectedOrganizationId: event.target.value,
        backgroundFiles: []
      },
      () => {
        if (GraphicsTool.organizationIdsTier2.includes(event.target.value)) {
          this.setState({ tierLevel: 2 })
        } else if (
          GraphicsTool.organizationIdsTier3.includes(event.target.value)
        ) {
          this.setState({ tierLevel: 3 })
        } else {
          this.setState({ tierLevel: 1 })
        }
        this.props.sortedProperties.forEach(property => {
          if (this.state.organizationId === property.parentId) {
            this.setState({ isToDisplayProperties: true })
            return
          }
        })
        this.loadImage()
        this.loadBackgroundImages()
        this.loadLeaguesUsedByAssets()
      }
    )
  }

  onPropertyChange = event => {
    if (GraphicsTool.organizationIdsTier2.includes(event.target.value)) {
      this.setState({ tierLevel: 2 })
    } else if (GraphicsTool.organizationIdsTier3.includes(event.target.value)) {
      this.setState({ tierLevel: 3 })
    } else {
      this.setState({ tierLevel: 1 })
    }
    this.setState(
      {
        propertyId: event.target.value,
        selectedOrganizationId: event.target.value,
        backgroundFiles: []
      },
      () => {
        this.loadImage()
        this.loadBackgroundImages()
        this.loadLeaguesUsedByAssets()
      }
    )
  }

  onGraphicLoaded({ target: graphic }) {
    if (graphic != null) {
      this.setState({
        graphicNaturalWidth: graphic.naturalWidth,
        graphicDisplayWidth: graphic.width,
        graphicNaturalHeight: graphic.naturalHeight,
        graphicDisplayHeight: graphic.height
      })
    }
    this.setState({
      isGraphicLoading: false,
      graphicsRenderElaspedTimeMilliseconds:
        new Date().getTime() - this.graphicsRenderStartTime.getTime()
    })
  }

  buildGraphicsUrl() {
    this.graphicsRenderStartTime = new Date()
    let url = `${globalSettings.apiBaseUrl}/api/graphics?graphicstype=${
      this.state.baseTemplates.find(bt => bt.key === this.state.templateId).type
    }`
    let imageSubType = this.state.baseTemplates.find(
      bt => bt.key === this.state.templateId
    ).subType
    if (imageSubType) {
      url += `&graphicssubtype=${imageSubType}`
    }
    if (this.state.graphicWidth > 0) {
      url += `&w=${this.state.graphicWidth}`
    }
    if (this.state.graphicHeight > 0) {
      url += `&h=${this.state.graphicHeight}`
    }
    let isUsingABackgroundImage = false
    if (this.state.backgroundFiles.length > 0) {
      isUsingABackgroundImage = true
    }
    if (
      this.state.backgroundFiles.find(f => f.id === -2) && // -2 magic number for a custom background image url
      this.state.backgroundImageUrl?.length > 0
    ) {
      url += `&backgroundimageurl=${encodeURIComponent(
        this.state.backgroundImageUrl
      )}`
      isUsingABackgroundImage = true
    }

    let fileIds = this.state.backgroundFiles
      .filter(f => f.id > 0)
      .map(f => `${f.id}`)
      .join(",")
    url += `&backgroundFileIds=${fileIds}`
    if (isUsingABackgroundImage) {
      if (this.state.backgroundImageOpacity > -1) {
        url += `&backgroundimageopacity=${this.state.backgroundImageOpacity}`
      }
      if (this.state.backgroundImageResizeMode !== -1) {
        url += `&backgroundimageresizemode=${
          loadBackgroundImageResizeChoices().find(
            rz => rz.key === this.state.backgroundImageResizeMode
          )?.resizeMode
        }`
      }
      url += `&isgrayscale=${this.state.isBackgroundImageGrayscale}`
    }

    if (this.state.isRenderReferenceImage) {
      url += `&isdebug=${this.state.isRenderReferenceImage}`
    }
    // loop and on the textArray and for the url
    let textValuesCounter = 0
    this.state.textValues &&
      this.state.textValues.forEach(item => {
        textValuesCounter++
        if (
          item.value !== "" &&
          textValuesCounter <= this.state.textFieldCount
        ) {
          url += `&text${item.key}=${encodeURIComponent(item.value)}`
        }
      })
    if (this.state.isToDisplayExternalImages && this.state.isUseImage1Url) {
      url += `&image1url=${encodeURIComponent(this.state.image1Url)}`
    }
    if (this.state.isToDisplayExternalImages && this.state.isUseImage2Url) {
      url += `&image2url=${encodeURIComponent(this.state.image2Url)}`
    }
    if (this.state.watermarkUrl) {
      if (this.state.watermarkUrl === "useorganizationlogo") {
        if (this.state.organizationId !== -1) {
          url += `&watermarkurl=${encodeURIComponent(this.state.watermarkUrl)}`
        }
      } else {
        url += `&watermarkurl=${encodeURIComponent(this.state.watermarkUrl)}`
      }
    }
    if (
      this.state.isToDisplayColors &&
      this.state.isUseColor1Hex &&
      this.state.color1Hex
    ) {
      url += `&color1hex=${this.state.color1Hex}`
    }
    if (
      this.state.isToDisplayColors &&
      this.state.isUseColor2Hex &&
      this.state.color2Hex
    ) {
      url += `&color2hex=${this.state.color2Hex}`
    }
    if (
      this.state.isToDisplayColors &&
      this.state.isUseColor3Hex &&
      this.state.color3Hex
    ) {
      url += `&color3hex=${this.state.color3Hex}`
    }
    if (
      this.state.isToDisplayColors &&
      this.state.isUseColor4Hex &&
      this.state.color4Hex
    ) {
      url += `&color4hex=${this.state.color4Hex}`
    }
    if (this.state.altText !== "") {
      url += `&alttext=${encodeURIComponent(this.state.altText)}`
    }
    if (this.state.isToDisplayTeamA && this.state.teamA) {
      url += `&teamid=${this.state.teamA.teamId}`
    }
    if (this.state.isToDisplayTeamB && this.state.teamB) {
      url += `&teambid=${this.state.teamB.teamId}`
    }
    if (
      this.state.isToDisplayTeamABetPercentage &&
      this.state.teamABetPercentage !== 0
    ) {
      url += `&teamabetpercentage=${this.state.teamABetPercentage}`
    }
    if (
      this.state.isToDisplayTeamAStakePercentage &&
      this.state.teamAStakePercentage !== 0
    ) {
      url += `&teamastakepercentage=${this.state.teamAStakePercentage}`
    }
    if (this.state.isToDisplayGeographicPlaceA && this.state.geographicPlaceA) {
      url += `&geographicplaceaid=${this.state.geographicPlaceA.geoNameId}`
    }
    if (this.state.isToDisplayGeographicPlaceB && this.state.geographicPlaceB) {
      url += `&geographicplacebid=${this.state.geographicPlaceB.geoNameId}`
    }
    if (this.state.isToDisplayPlayerA && this.state.playerA) {
      url += `&playeraid=${this.state.playerA.personId}`
    }
    if (this.state.isToDisplayPlayerB && this.state.playerB) {
      url += `&playerbid=${this.state.playerB.personId}`
    }
    if (this.state.organizationId !== -1) {
      url += `&organizationid=${this.state.selectedOrganizationId}`
    }
    if (this.state.filename !== "") {
      url += `&filename=${encodeURIComponent(this.state.filename)}`
    }
    if (this.state.isToDisplaySportsbooks && this.state.sportsbookId !== -1) {
      url += `&sportsbookid=${this.state.sportsbookId}`
    }
    if (this.state.QRCode1Value && this.state.QRCode1Value !== "") {
      url += `&qrcode1value=${encodeURIComponent(this.state.QRCode1Value)}`
    }
    if (
      this.state.isToDisplayCompetitorGroupsOverride &&
      this.state.competitorGroupsOverride
    ) {
      url += `&competitorgroupid=${this.state.competitorGroupsOverride?.competitorGroupId}`
    }
    // cleanup url
    url = url.replace("?&", "?")
    return url
  }

  buildImgTag() {
    const url = this.buildScribanFriendlyGraphicsUrl()
    return `<img src="${url}" alt="${this.state.altText}" />`
  }

  buildImgandAnchorTag() {
    const url = this.buildScribanFriendlyGraphicsUrl()
    return `<a href="${this.state?.QRCode1Value}"><img src="${url}" alt="${this.state.altText}" /></a>`
  }

  buildFeaturedImageJSON() {
    const url = this.buildScribanFriendlyGraphicsUrl()
    return `{
"Uri": "${url}"
}`
  }

  buildScribanFriendlyGraphicsUrl() {
    let url = `${globalSettings.apiBaseUrl}/api/graphics?graphicstype=${
      this.state.baseTemplates.find(bt => bt.key === this.state.templateId).type
    }`
    let imageSubType = this.state.baseTemplates.find(
      bt => bt.key === this.state.templateId
    ).subType
    if (imageSubType) {
      url += `&graphicssubtype=${imageSubType}`
    }
    if (this.state.graphicWidth > 0) {
      url += `&w=${this.state.graphicWidth}`
    }
    if (this.state.graphicHeight > 0) {
      url += `&h=${this.state.graphicHeight}`
    }
    if (this.state.isRenderReferenceImage) {
      url += `&isdebug=${this.state.isRenderReferenceImage}`
    }
    // loop and on the textArray and for the url
    this.state.textValues &&
      this.state.textValues.forEach(item => {
        if (item.value !== "") {
          if (item.value.indexOf("{{") > -1) {
            const encodedValue = this.encodeScribanString(item.value)
            url += `&text${item.key}=${encodedValue}`
          } else {
            url += `&text${item.key}={{ model.text${item.key} }}`
          }
        }
      })
    if (
      this.state.isToDisplayExternalImages &&
      this.state.isUseImage1Url &&
      this.state.image1Url
    ) {
      if (this.state.image1Url.indexOf("{{") > -1) {
        url += `&image1url=${this.state.image1Url}`
      } else {
        url += `&image1url={{ model.image1Url }}`
      }
    }
    if (
      this.state.isToDisplayExternalImages &&
      this.state.isUseImage2Url &&
      this.state.image2Url
    ) {
      if (this.state.image2Url.indexOf("{{") > -1) {
        url += `&image2Url=${this.state.image2Url}`
      } else {
        url += `&image2Url={{ model.image2Url }}`
      }
    }
    if (this.state.watermarkUrl !== "") {
      if (this.state.watermarkUrl.indexOf("{{") > -1) {
        url += `&watermarkUrl=${this.state.watermarkUrl}`
      } else {
        url += `&watermarkUrl={{ model.watermarkUrl }}`
      }
    }
    if (
      this.state.isToDisplayColors &&
      this.state.isUseColor1Hex &&
      this.state.color1Hex
    ) {
      if (this.state.color1Hex.indexOf("{{") > -1) {
        url += `&color1hex=${this.state.color1Hex}`
      } else {
        url += `&color1hex={{ model.color1hex }}`
      }
    }
    if (
      this.state.isToDisplayColors &&
      this.state.isUseColor2Hex &&
      this.state.color2Hex
    ) {
      if (this.state.color2Hex.indexOf("{{") > -1) {
        url += `&color2Hex=${this.state.color2Hex}`
      } else {
        url += `&color2Hex={{ model.color2Hex }}`
      }
    }
    if (
      this.state.isToDisplayColors &&
      this.state.isUseColor3Hex &&
      this.state.color3Hex
    ) {
      if (this.state.color3Hex.indexOf("{{") > -1) {
        url += `&color3Hex=${this.state.color3Hex}`
      } else {
        url += `&color3Hex={{ model.color3Hex }}`
      }
    }
    if (
      this.state.isToDisplayColors &&
      this.state.isUseColor4Hex &&
      this.state.color4Hex
    ) {
      if (this.state.color4Hex.indexOf("{{") > -1) {
        url += `&color4Hex=${this.state.color4Hex}`
      } else {
        url += `&color4Hex={{ model.color4Hex }}`
      }
    }
    if (this.state.altText !== "") {
      if (this.state.altText.indexOf("{{") > -1) {
        const formattedAltText = this.encodeScribanString(this.state.altText)
        url += `&alttext=${formattedAltText}`
      } else {
        url += `&alttext={{ model.altext }}`
      }
    }
    if (this.state.teamA != null) {
      url += `&teamid={{ team_a.id }}`
    }
    if (this.state.teamB != null) {
      url += `&teambid={{ team_b.id }}`
    }
    if (
      this.state.isToDisplayTeamABetPercentage &&
      this.state.teamABetPercentage !== 0
    ) {
      url += `&teamabetpercentage={{ model.teamabetpercentage }}`
    }
    if (
      this.state.isToDisplayTeamAStakePercentage &&
      this.state.teamAStakePercentage !== 0
    ) {
      url += `&teamastakepercentage={{ model.teamastakepercentage }}`
    }
    if (this.state.geographicPlaceA) {
      url += `&geographicplaceaid={{ model.geographicplaceaid }}`
    }
    if (this.state.geographicPlaceB) {
      url += `&geographicplacebid={{ model.geographicplacebid }}`
    }
    if (this.state.playerA) {
      url += `&playeraid={{ model.playeraid }}`
    }
    if (this.state.playerB) {
      url += `&playerbid={{ model.playerbid }}`
    }
    if (this.state.organizationId !== -1) {
      url += `&organizationid={{ narrative.organization.id }}`
    }
    if (this.state.filename !== "") {
      if (this.state.filename.indexOf("{{") > -1) {
        url += `&filename=${this.state.filename}`
      } else {
        url += `&filename={{ model.filename }}`
      }
    }
    if (this.state.QRCode1Value !== "") {
      if (this.state.QRCode1Value.indexOf("{{") > -1) {
        url += `&qrcode1value=${this.state.QRCode1Value}`
      } else {
        url += `&qrcode1value={{ model.CTA1Value }}`
      }
    }
    if (
      this.state.isToDisplayCompetitorGroupsOverride &&
      this.state.competitorGroupsOverride
    ) {
      url += `&competitorgroupid={{ model.competitorGroupId }}`
    }
    // cleanup url
    url = url.replace("?&", "?")
    return url
  }

  buildScriptToCopy() {
    let scriptToCopy = ""
    if (this.state.filename === "") {
      scriptToCopy = ""
    } else {
      switch (this.state.scriptType) {
        case 1:
          scriptToCopy = this.buildGraphicsUrl()
          break
        case 2:
          scriptToCopy = this.buildFeaturedImageJSON()
          break
        case 3:
          scriptToCopy = this.buildImgTag()
          break
        case 4:
          scriptToCopy = this.buildImgandAnchorTag()
          break
        default:
          scriptToCopy = "Please select a script type."
          break
      }
    }
    return scriptToCopy
  }

  onBackgroundImageOpacityChange(opacity) {
    this.setState({ backgroundImageOpacity: opacity })
    this.setState(
      {
        graphicImageUrl: this.buildGraphicsUrl()
      },
      () => this.loadImage()
    )
  }

  onGraphicWidthChange(width) {
    this.setState({ graphicWidth: width })
    this.setState({ graphicHeight: "" })
    this.setState(
      {
        graphicImageUrl: this.buildGraphicsUrl()
      },
      () => this.loadImage()
    )
  }

  onGraphicHeightChange(height) {
    this.setState({ graphicHeight: height })
    this.setState({ graphicWidth: "" })
    this.setState(
      {
        graphicImageUrl: this.buildGraphicsUrl()
      },
      () => this.loadImage()
    )
  }

  encodeScribanString(string) {
    const encodedStr1 = encodeURIComponent(string) // Replace all spaces with '%20' to conform to URL encoding standards
    const encodedStr2 = encodedStr1.replace(/%7B%7B%20/gi, "{{ ") // Replace beginning Scriban encoded space with unencoded space
    const formattedStr = encodedStr2.replace(/%20%7D%7D/gi, " }}") // Same for the end of the Scriban code
    return formattedStr
  }

  removeSpacesFromText(string) {
    const unspacedString1 = string.replace(/\s/gi, "-")
    const unspacedString2 = unspacedString1.replace(/{{-/gi, "{{ ")
    const unspacedString3 = unspacedString2.replace(/-}}/gi, " }}")
    const lowerCasedString = unspacedString3.toLowerCase()
    return lowerCasedString
  }

  render() {
    const { classes } = this.props
    const baseTemplateMenuItems = this.state.baseTemplates
      .filter(bt => bt.tierLevel !== Enums.TierLevels.Level3)
      .sort(Helpers.sortByName)
      .map(item => {
        if (!this.state.isArchived && item.isArchived) {
          return null
        } else {
          return (
            <MenuItem
              key={item.key}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value={item.key}
              style={{
                textDecoration: item.isArchived ? "line-through" : "initial"
              }}
              tierLevel={item.tierLevel}
            >
              {item.name}
            </MenuItem>
          )
        }
      })

    const tierLevel3TemplateMenuItems = this.state.baseTemplates
      .filter(
        bt =>
          bt.tierLevel === Enums.TierLevels.Level3 &&
          bt.organizationIds?.includes(this.state.organizationId)
      )
      .sort(Helpers.sortByName)
      .map(item => {
        if (!this.state.isArchived && item.isArchived) {
          return null
        } else {
          return (
            <MenuItem
              key={item.key}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value={item.key}
              style={{
                textDecoration: item.isArchived ? "line-through" : "initial"
              }}
              tierLevel={item.tierLevel}
            >
              {item.name}
            </MenuItem>
          )
        }
      })

    const watermarkShortcutMenuItems = loadWatermarkUrlChoices()
    const backgroundImageResizeMenuItems = loadBackgroundImageResizeChoices()
    const sortedBackgroundImages = this.state.backgroundImages?.sort(
      Helpers.sortByCreatedOnDateDesc
    )

    const sortedCompetitorGroups = [
      { friendlyName: "No Competitor Group", competitorGroupId: -1 },
      { friendlyName: "Invalid competitor group", competitorGroupId: -2 }
    ].concat(this.state.competitorGroups?.sort(Helpers.sortByFriendlyName))

    const sortedTeams = [
      { groupingId: -1, teamDisplayName: "Invalid team", teamId: -1 }
    ].concat(this.state.teams)

    const sortedPlayers = [
      { groupingId: -1, playerDisplayName: "Invalid player", personId: -1 }
    ].concat(this.state.players)

    const sortedGeographicPlaces = [
      {
        contentType: -1,
        geographicDisplayName: "Invalid geographic place",
        geoNameId: -1
      }
    ].concat(this.state.geographicPlaces)

    const organizationMenuItems = this.props.sortedAccountOrganizations.map(
      item => (
        <MenuItem
          key={item.id}
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected
          }}
          value={item.id}
        >
          {item.name}
        </MenuItem>
      )
    )
    const propertiesMenuItems = this.props.sortedProperties.map(item => {
      if (this.state.organizationId === item.parentId) {
        return (
          <MenuItem
            key={item.id}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected
            }}
            value={item.id}
          >
            {item.name}
          </MenuItem>
        )
      } else {
        return null
      }
    })

    const sportsbooksMenuItems = loadSportsbooks().map(item => (
      <MenuItem
        key={item.key}
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected
        }}
        value={item.key}
      >
        {item.name}
      </MenuItem>
    ))
    let column1MediumUnits = this.props.isEmbedded ? 12 : 5
    let column2MediumUnits = this.props.isEmbedded ? 12 : 7

    const teamFilterOptions = createFilterOptions({
      stringify: ({ teamDisplayName, groupingId }) =>
        `${teamDisplayName} ${Helpers.renderFriendlyTeamGroupName(groupingId)}`
    })

    const playerFilterOptions = createFilterOptions({
      stringify: ({ playerDisplayName, groupingId }) =>
        `${playerDisplayName} ${Helpers.renderFriendlyTeamGroupName(
          groupingId
        )}`
    })

    const geographicPlaceFilterOptions = createFilterOptions({
      stringify: ({ geographicDisplayName, groupingId }) =>
        `${geographicDisplayName} ${Helpers.renderFriendlyGeographicPlaceGroupName(
          groupingId
        )}`
    })

    const leagueFilterOptions = createFilterOptions({
      stringify: ({ combinedName }) => `${combinedName}`
    })

    const filteredLeagues = this.props.sortedLeagues.filter(
      q => q.name !== "" && q.isActive === true
    )
    const leaguesUsedByAssets = filteredLeagues.filter(({ id }) =>
      this.state.leaguesUsedByAssets.includes(id)
    )

    const leaguesFullName = leaguesUsedByAssets.map(option => {
      let acronym = option.acronym ? `(${option.acronym}) ` : ""
      return {
        combinedName: `${acronym}${option.name}`,
        ...option
      }
    })
    const leagueOptions = [
      {
        id: 0,
        combinedName: "Not Applicable"
      }
    ].concat(leaguesFullName)

    const textFields = () => {
      const textFields = []
      for (let i = 1; i <= this.state.textFieldCount; i++) {
        textFields.push(
          <GridItem
            lg={
              this.state.textFieldCount >= 3
                ? 4
                : this.state.textFieldCount === 2 // when only 2 text fields, then side-by-side
                ? 6
                : 12
            }
            md={this.state.textFieldCount >= 3 ? 6 : 12}
            sm={12}
          >
            <TextField
              fullWidth={true}
              label={`Text #${i}`}
              id={`text${i}`}
              helperText=" "
              inputProps={{
                multiline: false,
                rows: 1,
                onChange: this.handleTextFieldChange,
                value: this.state.textValues.find(item => item.key === i).value,
                "aria-label": `text${i}`
              }}
            />
          </GridItem>
        )
      }
      return textFields
    }

    return (
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <ImageIcon />
          </CardIcon>
          <h4 className={classes.cardIconTitle} />
        </CardHeader>
        <CardBody>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={column1MediumUnits}>
                <GridContainer>
                  <GridItem
                    md={12}
                    lg={this.state.isToDisplayProperties ? 5 : 10}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Organization
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={this.state.organizationId}
                      onChange={this.onOrganizationChange}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select"
                      }}
                      fullWidth
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        Choose organization
                      </MenuItem>
                      <MenuItem
                        key={-1}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={-1}
                      >
                        Not applicable
                      </MenuItem>
                      {organizationMenuItems}
                    </Select>
                  </GridItem>
                  {this.state.isToDisplayProperties && (
                    <GridItem md={12} lg={5}>
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                      >
                        Property
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        inputProps={{
                          name: "simpleSelect",
                          id: "simple-select"
                        }}
                        fullWidth
                        value={this.state.propertyId}
                        onChange={this.onPropertyChange}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem
                          }}
                        >
                          Choose property
                        </MenuItem>
                        <MenuItem
                          key={this.state.organizationId}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={this.state.organizationId}
                        >
                          {
                            this.props.sortedAccountOrganizations.find(
                              org => org.id === this.state.organizationId
                            ).name
                          }{" "}
                          (Parent)
                        </MenuItem>
                        {propertiesMenuItems}
                      </Select>
                    </GridItem>
                  )}
                  <GridItem lg={2} style={{ padding: "0px", margin: "0px" }}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Tier Level{" "}
                    </InputLabel>
                    Tier {this.state.tierLevel}
                    <Tooltip
                      title={
                        loadTierLevels()[this.state.tierLevel - 1].description
                      }
                      placement="top"
                      style={{ padding: "0px", margin: "0px" }}
                    >
                      <Button
                        justIcon
                        round
                        simple
                        color="primary"
                        className="info"
                      >
                        <HelpOutline />
                      </Button>
                    </Tooltip>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem md={12} style={{ position: "relative" }}>
                    {this.state.isGraphicLoading && (
                      <CircularProgress
                        style={{
                          position: "absolute",
                          top: "40%",
                          left: "40%"
                        }}
                        size="5rem"
                      />
                    )}
                    {this.state.imageObjectUrl && (
                      <img
                        width="100%"
                        height="auto"
                        src={`${this.state.imageObjectUrl}`}
                        alt="The rendered graphic"
                        style={{ paddingTop: "10px" }}
                        onLoad={this.onGraphicLoaded.bind(this)}
                      />
                    )}
                  </GridItem>
                  <GridItem md={12} style={{ padding: "0px" }}>
                    <GridContainer>
                      <GridItem lg={12}>
                        <span>
                          <b>Render time:</b>{" "}
                          {this.state.graphicsRenderElaspedTimeMilliseconds}
                          ms <b>Dimensions:</b>
                          {this.state.graphicNaturalWidth} x{" "}
                          {this.state.graphicNaturalHeight} (natural),{" "}
                          {this.state.graphicDisplayWidth} x{" "}
                          {this.state.graphicDisplayHeight} (displayed)
                        </span>
                        <span>
                          <br />
                          <b>Image Part Failures:</b>{" "}
                          {this.state.imagePartFailures && (
                            <span style={{ color: "red" }}>
                              {this.state.imagePartFailures}
                            </span>
                          )}
                        </span>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem lg={3} style={{ padding: "0px" }}>
                        <Checkbox
                          id="isRenderReferenceImage"
                          checked={this.state.isRenderReferenceImage}
                          onChange={this.handleSwitchChange}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                        />{" "}
                        Example?
                      </GridItem>
                      <GridItem lg={3}>
                        <a
                          href={this.buildGraphicsUrl()}
                          target="_new"
                          title="View Image Fullscreen"
                        >
                          <Button round simple color="primary" className="info">
                            <Fullscreen /> View
                          </Button>
                        </a>
                      </GridItem>
                      <GridItem lg={3}>
                        <a
                          href={`${this.buildGraphicsUrl()}&isdownload=true`}
                          target="_new"
                          title="Download Image"
                        >
                          <Button round simple color="primary" className="info">
                            <CloudDownload /> Download
                          </Button>
                        </a>
                      </GridItem>
                      <GridItem lg={3}>
                        <a
                          href={`${this.buildGraphicsUrl()}&isSaveAsAsset=true&organizationid=${
                            this.props.organizationId
                          }`}
                          target="_new"
                          title="Save Image To File Manager"
                        >
                          <Button round simple color="primary" className="info">
                            <Save /> Save
                          </Button>
                        </a>
                      </GridItem>
                    </GridContainer>
                    <GridContainer spacing={0}>
                      <GridItem xs={12} sm={12} md={12}>
                        <InputLabel id="scriptType-label">
                          Script Type
                        </InputLabel>
                        <Select
                          style={{ width: 250, marginBottom: 10 }}
                          autoWidth
                          id="scriptType-element"
                          value={this.state.scriptType}
                          onChange={event =>
                            this.setState({
                              scriptType: event.target.value
                            })
                          }
                        >
                          {[
                            { label: "Graphics Image (URL)", value: 1 },
                            { label: "Featured Image Object (JSON)", value: 2 },
                            { label: "Inline Image (HTML)", value: 3 },
                            { label: "Inline Image w/Anchor (HTML)", value: 4 }
                          ].map(option => (
                            <MenuItem value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {this.buildScriptToCopy() === "" ? (
                          <Alert severity="warning">
                            'Filename' is required for script to appear.
                          </Alert>
                        ) : (
                          <TextField
                            style={{
                              width: "100%"
                            }}
                            value={this.buildScriptToCopy()}
                            inputProps={{ readOnly: true }}
                            multiline
                            minRows={6}
                            className={classes.disabledScriptOutput}
                          />
                        )}
                      </GridItem>
                    </GridContainer>
                    <GridContainer spacing={0}>
                      <GridItem xs={3} sm={3} md={3}>
                        <CopyToClipboard
                          disabled={this.buildScriptToCopy() === ""}
                          text={this.buildScriptToCopy()}
                          onCopy={() => {
                            this.setState({
                              copySuccessText: "Script copied"
                            })
                            setTimeout(
                              () =>
                                this.setState({
                                  copySuccessText: "Copy script"
                                }),
                              5000
                            )
                          }}
                        >
                          <Tooltip
                            title={this.state.copySuccessText}
                            placement="top"
                          >
                            <Button
                              round
                              simple
                              color="primary"
                              className="info"
                            >
                              <FileCopy /> Copy
                            </Button>
                          </Tooltip>
                        </CopyToClipboard>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={12} md={column2MediumUnits}>
                <GridContainer>
                  <GridItem md={10} lg={10}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Tier 1 and 2 templates
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={this.state.templateId}
                      onChange={this.onTemplateChangeEvent}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select"
                      }}
                      fullWidth
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        Choose template
                      </MenuItem>
                      {baseTemplateMenuItems}
                    </Select>
                  </GridItem>
                  <GridItem lg={2} md={2} style={{ padding: "0px" }}>
                    <Switch
                      id="isArchived"
                      checked={this.state.isArchived}
                      onChange={this.handleSwitchChange}
                      color="primary"
                      size="small"
                    />{" "}
                    Archived?
                  </GridItem>
                </GridContainer>
                <br />
                {this.state.tierLevel === Enums.TierLevels.Level3 && (
                  <GridContainer>
                    <GridItem md={10} lg={10}>
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                      >
                        Tier 3 templates
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={this.state.templateId}
                        onChange={this.onTemplateChangeEvent}
                        inputProps={{
                          name: "simpleSelect",
                          id: "simple-select",
                          style: { textDecoration: "line-through" }
                        }}
                        fullWidth
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem
                          }}
                        >
                          Choose template
                        </MenuItem>
                        {tierLevel3TemplateMenuItems}
                      </Select>
                    </GridItem>
                  </GridContainer>
                )}
                <Accordion
                  collapses={[
                    {
                      title: "Filename* & Alt Text",
                      content: (
                        <>
                          <GridContainer>
                            <GridItem md={12} sm={12}>
                              <TextField
                                label="Rendered filename*"
                                id="filename"
                                helperText="Provide words about the subject matter of the image."
                                inputProps={{
                                  onChange: this.handleTextChange,
                                  value: this.state.filename,
                                  variant: "outlined"
                                }}
                                fullWidth
                              />
                            </GridItem>
                            <GridItem md={12} sm={12}>
                              <TextField
                                label="Alt Text"
                                id="altText"
                                helperText=" "
                                inputProps={{
                                  onChange: this.handleTextChange,
                                  value: this.state.altText
                                }}
                                fullWidth
                              />
                            </GridItem>
                          </GridContainer>
                        </>
                      )
                    },
                    {
                      title: "Logos (Team, Player, Company brands)",
                      content: (
                        <GridContainer>
                          {!this.state.isToDisplayTeamA &&
                            !this.state.isToDisplayTeamB &&
                            !this.state.isToDisplayPlayerA &&
                            !this.state.isToDisplayPlayerB &&
                            !this.state.isToDisplaySportsbooks && (
                              <GridItem>Not applicable.</GridItem>
                            )}
                          {this.state.isToDisplayTeamA && (
                            <GridItem md={6}>
                              <Autocomplete
                                MenuProps={{ className: classes.selectMenu }}
                                classes={{
                                  select: classes.select
                                }}
                                value={this.state.teamA}
                                onChange={(event, newValue, reason) => {
                                  if (reason === "clear") {
                                    this.setState(
                                      {
                                        teamA: null
                                      },
                                      () => this.loadImage()
                                    )
                                  } else {
                                    this.handleMUIComponentChange(
                                      "teamA",
                                      newValue
                                    )
                                  }
                                }}
                                inputProps={{
                                  name: "teamA",
                                  id: "teamA-select"
                                }}
                                options={sortedTeams}
                                loading
                                getOptionLabel={option =>
                                  option.teamDisplayName || ""
                                }
                                isOptionEqualToValue={(option, value) =>
                                  option === value
                                }
                                filterOptions={teamFilterOptions}
                                renderGroup={params => (
                                  <>
                                    {params.group != null}{" "}
                                    {
                                      <div>
                                        {Helpers.renderFriendlyTeamGroupName(
                                          params.group // parameter name for this component.
                                        )}
                                      </div>
                                    }
                                    {params.children}
                                  </>
                                )}
                                groupBy={option => option.groupingId}
                                renderInput={params => (
                                  <TextField {...params} label="Team 'A'" />
                                )}
                              />
                            </GridItem>
                          )}
                          {this.state.isToDisplayTeamB && (
                            <GridItem md={6}>
                              <Autocomplete
                                MenuProps={{ className: classes.selectMenu }}
                                classes={{
                                  select: classes.select
                                }}
                                value={this.state.teamB}
                                onChange={(event, newValue, reason) => {
                                  if (reason === "clear") {
                                    this.setState(
                                      {
                                        teamB: null
                                      },
                                      () => this.loadImage()
                                    )
                                  } else {
                                    this.handleMUIComponentChange(
                                      "teamB",
                                      newValue
                                    )
                                  }
                                }}
                                inputProps={{
                                  name: "teamB",
                                  id: "teamB-select"
                                }}
                                options={sortedTeams}
                                loading
                                getOptionLabel={option =>
                                  option.teamDisplayName || ""
                                }
                                isOptionEqualToValue={(option, value) =>
                                  option === value
                                }
                                filterOptions={teamFilterOptions}
                                renderGroup={params => (
                                  <>
                                    <div>
                                      {Helpers.renderFriendlyTeamGroupName(
                                        params.group // parameter name for this component.
                                      )}
                                    </div>
                                    {params.children}
                                  </>
                                )}
                                groupBy={option => option.groupingId}
                                renderInput={params => (
                                  <TextField {...params} label="Team 'B'" />
                                )}
                              />
                            </GridItem>
                          )}
                          <GridItem md={12}>&nbsp;</GridItem>
                          {this.state.isToDisplayPlayerA && (
                            <GridItem md={6}>
                              <Autocomplete
                                MenuProps={{ className: classes.selectMenu }}
                                classes={{
                                  select: classes.select
                                }}
                                value={this.state.playerA}
                                onChange={(event, newValue, reason) => {
                                  if (reason === "clear") {
                                    this.setState(
                                      {
                                        playerA: null
                                      },
                                      () => this.loadImage()
                                    )
                                  } else {
                                    this.handleMUIComponentChange(
                                      "playerA",
                                      newValue
                                    )
                                  }
                                }}
                                inputProps={{
                                  name: "playerA",
                                  id: "playerA-select"
                                }}
                                options={sortedPlayers}
                                loading
                                getOptionLabel={option =>
                                  option.playerDisplayName || ""
                                }
                                isOptionEqualToValue={(option, value) =>
                                  option === value
                                }
                                filterOptions={playerFilterOptions}
                                renderGroup={params => (
                                  <>
                                    <div>
                                      {Helpers.renderFriendlyTeamGroupName(
                                        params.group // parameter name for this component.
                                      )}
                                    </div>
                                    {params.children}
                                  </>
                                )}
                                groupBy={option => option.groupingId}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label="Player 'A' (overrides Team 'A')"
                                  />
                                )}
                              />
                            </GridItem>
                          )}
                          {this.state.isToDisplayPlayerB && (
                            <GridItem md={6}>
                              <Autocomplete
                                MenuProps={{ className: classes.selectMenu }}
                                classes={{
                                  select: classes.select
                                }}
                                value={this.state.playerB}
                                onChange={(event, newValue, reason) => {
                                  if (reason === "clear") {
                                    this.setState(
                                      {
                                        playerB: null
                                      },
                                      () => this.loadImage()
                                    )
                                  } else {
                                    this.handleMUIComponentChange(
                                      "playerB",
                                      newValue
                                    )
                                  }
                                }}
                                inputProps={{
                                  name: "playerB",
                                  id: "playerB-select"
                                }}
                                options={sortedPlayers}
                                loading
                                getOptionLabel={option =>
                                  option.playerDisplayName || ""
                                }
                                isOptionEqualToValue={(option, value) =>
                                  option === value
                                }
                                filterOptions={playerFilterOptions}
                                renderGroup={params => (
                                  <>
                                    <div>
                                      {Helpers.renderFriendlyTeamGroupName(
                                        params.group // parameter name for this component.
                                      )}
                                    </div>
                                    {params.children}
                                  </>
                                )}
                                groupBy={option => option.groupingId}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label="Player 'B' (overrides Team 'B')"
                                  />
                                )}
                              />
                            </GridItem>
                          )}
                          <GridItem md={12}>&nbsp;</GridItem>
                          {this.state.isToDisplayGeographicPlaceA && (
                            <GridItem md={6}>
                              <Autocomplete
                                MenuProps={{ className: classes.selectMenu }}
                                classes={{
                                  select: classes.select
                                }}
                                value={this.state.geographicPlaceA}
                                onChange={(event, newValue, reason) => {
                                  if (reason === "clear") {
                                    this.setState(
                                      {
                                        geographicPlaceA: null
                                      },
                                      () => this.loadImage()
                                    )
                                  } else {
                                    this.handleMUIComponentChange(
                                      "geographicPlaceA",
                                      newValue
                                    )
                                  }
                                }}
                                inputProps={{
                                  name: "geographicPlaceA",
                                  id: "geographicPlaceA-select"
                                }}
                                options={sortedGeographicPlaces}
                                loading
                                getOptionLabel={option =>
                                  option.geographicDisplayName || ""
                                }
                                isOptionEqualToValue={(option, value) =>
                                  option === value
                                }
                                filterOptions={geographicPlaceFilterOptions}
                                renderGroup={params => (
                                  <>
                                    <div>
                                      {Helpers.renderFriendlyGeographicPlaceGroupName(
                                        params.group // parameter name for this component.
                                      )}
                                    </div>
                                    {params.children}
                                  </>
                                )}
                                groupBy={option => option.contentType}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label="Geographic Place A"
                                  />
                                )}
                              />
                            </GridItem>
                          )}
                          {this.state.isToDisplayGeographicPlaceB && (
                            <GridItem md={6}>
                              <Autocomplete
                                MenuProps={{ className: classes.selectMenu }}
                                classes={{
                                  select: classes.select
                                }}
                                value={this.state.geographicPlaceB}
                                onChange={(event, newValue, reason) => {
                                  if (reason === "clear") {
                                    this.setState(
                                      {
                                        geographicPlaceB: null
                                      },
                                      () => this.loadImage()
                                    )
                                  } else {
                                    this.handleMUIComponentChange(
                                      "geographicPlaceB",
                                      newValue
                                    )
                                  }
                                }}
                                inputProps={{
                                  name: "geographicPlaceB",
                                  id: "geographicPlaceB-select"
                                }}
                                options={sortedGeographicPlaces}
                                loading
                                getOptionLabel={option =>
                                  option.geographicDisplayName || ""
                                }
                                isOptionEqualToValue={(option, value) =>
                                  option === value
                                }
                                filterOptions={geographicPlaceFilterOptions}
                                renderGroup={params => (
                                  <>
                                    <div>
                                      {Helpers.renderFriendlyGeographicPlaceGroupName(
                                        params.group // parameter name for this component.
                                      )}
                                    </div>
                                    {params.children}
                                  </>
                                )}
                                groupBy={option => option.contentType}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label="Geographic Place B"
                                  />
                                )}
                              />
                            </GridItem>
                          )}
                          <GridItem md={12}>&nbsp;</GridItem>
                          {this.state.isToDisplayCompetitorGroupsOverride && (
                            <GridItem md={6}>
                              <Autocomplete
                                MenuProps={{ className: classes.selectMenu }}
                                classes={{
                                  select: classes.select
                                }}
                                value={this.state.competitorGroupsOverride}
                                onChange={(event, newValue, reason) => {
                                  if (reason === "clear") {
                                    this.setState(
                                      {
                                        competitorGroupsOverride: null
                                      },
                                      () => this.loadImage()
                                    )
                                  } else {
                                    this.handleMUIComponentChange(
                                      "competitorGroupsOverride",
                                      newValue
                                    )
                                  }
                                }}
                                inputProps={{
                                  name: "competitorGroupsOverride",
                                  id: "competitorGroupsOverride-select"
                                }}
                                options={sortedCompetitorGroups}
                                loading
                                getOptionLabel={option =>
                                  option.friendlyName || ""
                                }
                                isOptionEqualToValue={(option, value) =>
                                  option === value
                                }
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label="Competitor 'A' Group Override"
                                  />
                                )}
                              />
                            </GridItem>
                          )}
                          {this.state.isToDisplaySportsbooks && (
                            <GridItem md={3}>
                              <InputLabel
                                htmlFor="sportsbooks-select"
                                className={classes.selectLabel}
                              >
                                Sportsbooks
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu
                                }}
                                classes={{
                                  select: classes.select
                                }}
                                value={this.state.sportsbookId}
                                onChange={this.handleSelectChange}
                                inputProps={{
                                  name: "sportsbookId",
                                  id: "sportsbooks-select"
                                }}
                              >
                                <MenuItem
                                  disabled
                                  classes={{
                                    root: classes.selectMenuItem
                                  }}
                                >
                                  Choose sportsbook
                                </MenuItem>
                                {sportsbooksMenuItems}
                              </Select>
                            </GridItem>
                          )}
                          <GridItem md={12}>&nbsp;</GridItem>
                        </GridContainer>
                      )
                    },
                    {
                      title: `Text fields`,
                      content: (
                        <>
                          <GridContainer>
                            <GridItem md={12}>
                              <Button
                                onClick={() =>
                                  this.onClearTextValuesButtonClick()
                                }
                                size="sm"
                              >
                                Clear values
                              </Button>
                              <Button
                                onClick={() =>
                                  this.onLoadDefaultTextValuesClick()
                                }
                                size="sm"
                              >
                                Load defaults
                              </Button>
                            </GridItem>
                          </GridContainer>
                          <GridContainer style={{ paddingTop: "20px" }}>
                            {textFields()}
                          </GridContainer>
                        </>
                      )
                    },
                    {
                      title: `CTA links`,
                      content: (
                        <>
                          <GridContainer>
                            <GridItem md={12}>
                              <TextField
                                label="CTA Value"
                                id="CTAValue"
                                helperText=" "
                                inputProps={{
                                  multiline: false,
                                  rows: 1,
                                  onChange: this.onQRCode1ValueChange,
                                  value: this.state.QRCode1Value
                                }}
                                fullWidth
                              />
                            </GridItem>
                          </GridContainer>
                        </>
                      )
                    },
                    {
                      title: "Colors",
                      content: (
                        <>
                          {!this.state.isToDisplayColors && (
                            <GridItem>Not applicable.</GridItem>
                          )}
                          {this.state.isToDisplayColors && (
                            <>
                              <GridContainer>
                                <GridItem md={3}>
                                  <GraphicsToolColorPicker
                                    title="Color #1"
                                    colorKey={1}
                                    hexColor={this.state.color1Hex}
                                    isSelected={this.state.isUseColor1Hex}
                                    onColorPickerChange={
                                      this.onColorPickerChange
                                    }
                                    onIsToUseColorChange={
                                      this.onIsToUseColorChange
                                    }
                                  />
                                </GridItem>
                                <GridItem md={3}>
                                  <GraphicsToolColorPicker
                                    title="Color #2"
                                    colorKey={2}
                                    hexColor={this.state.color2Hex}
                                    isSelected={this.state.isUseColor2Hex}
                                    onColorPickerChange={
                                      this.onColorPickerChange
                                    }
                                    onIsToUseColorChange={
                                      this.onIsToUseColorChange
                                    }
                                  />
                                </GridItem>
                                <GridItem md={3}>
                                  <GraphicsToolColorPicker
                                    title="Color #3"
                                    colorKey={3}
                                    hexColor={this.state.color3Hex}
                                    isSelected={this.state.isUseColor3Hex}
                                    onColorPickerChange={
                                      this.onColorPickerChange
                                    }
                                    onIsToUseColorChange={
                                      this.onIsToUseColorChange
                                    }
                                  />
                                </GridItem>
                                <GridItem md={3}>
                                  <GraphicsToolColorPicker
                                    title="Color #4"
                                    colorKey={4}
                                    hexColor={this.state.color4Hex}
                                    isSelected={this.state.isUseColor4Hex}
                                    onColorPickerChange={
                                      this.onColorPickerChange
                                    }
                                    onIsToUseColorChange={
                                      this.onIsToUseColorChange
                                    }
                                  />
                                </GridItem>
                              </GridContainer>
                            </>
                          )}
                        </>
                      )
                    },
                    {
                      title: "Custom Background Image",
                      content: (
                        <>
                          <GridContainer>
                            <GridItem md={12}>
                              <Autocomplete
                                MenuProps={{ className: classes.selectMenu }}
                                classes={{
                                  select: classes.select
                                }}
                                value={leagueOptions.find(
                                  item => item.id === this.state.leageueId
                                )}
                                onChange={(event, newValue, reason) => {
                                  let leagueId = newValue?.id
                                  if (reason === "clear") {
                                    leagueId = null
                                  } else {
                                    this.handleMUIComponentChange(
                                      "leagueId",
                                      newValue
                                    )
                                  }
                                  this.setState(
                                    {
                                      leagueId,
                                      backgroundImageUrl: ""
                                    },
                                    () => this.loadBackgroundImages()
                                  )
                                }}
                                inputProps={{
                                  name: "sportsLeague",
                                  id: "sportsLeague"
                                }}
                                options={leagueOptions}
                                loading
                                getOptionLabel={option =>
                                  `${option.combinedName}` || ""
                                }
                                getOptionSelected={(option, value) =>
                                  option === value
                                }
                                filterOptions={leagueFilterOptions}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label="Sports League"
                                  />
                                )}
                                disabled={
                                  this.state.backgroundImages?.length === 0
                                }
                              />
                            </GridItem>
                            <GridItem md={12} style={{ paddingTop: "15px" }}>
                              <Autocomplete
                                multiple={true}
                                id="background-images-from-filemanager"
                                filterOptions={(options, params) => {
                                  // inject the Select All option at the beginning
                                  const filter = createFilterOptions()
                                  const filtered = filter(options, params)
                                  return [
                                    { id: -1, fileName: "Select All" },
                                    ...filtered
                                  ]
                                }}
                                options={sortedBackgroundImages}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                value={this.state.backgroundFiles}
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) =>
                                    // Don't display the 'Select All...' option as a tag chip.
                                    option.id > 0 ? (
                                      <Chip
                                        label={
                                          <Typography
                                            style={{ whiteSpace: "normal" }}
                                          >
                                            {option.id >= 0 && (
                                              <>
                                                <img
                                                  loading="lazy"
                                                  width={75}
                                                  src={`${globalSettings.cdnBaseUrl}/api/asset/${option.assetToken}.jpg`}
                                                  alt=""
                                                  style={{
                                                    verticalAlign: "middle"
                                                  }} // Aligns image vertically with the text
                                                />{" "}
                                                <br />
                                              </>
                                            )}
                                            {option.fileName}
                                            {option.id > 0
                                              ? ` - (File Id: ${option.id})`
                                              : ``}
                                          </Typography>
                                        }
                                        {...getTagProps({ index })}
                                        style={{
                                          height: "100%",
                                          textAlign: "center",
                                          padding: "5px"
                                        }}
                                      />
                                    ) : (
                                      <></>
                                    )
                                  )
                                }
                                disableCloseOnSelect={true}
                                getOptionLabel={option => `${option.id}` || ""}
                                renderOption={(props, option, { selected }) => (
                                  <>
                                    <Box
                                      component="li"
                                      sx={{
                                        "& > img": { mr: 2, flexShrink: 0 }
                                      }}
                                      {...props}
                                    >
                                      <>
                                        <li {...props}>
                                          <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            checked={
                                              option.id === -1 // -1 is the magic number for 'Select All' option
                                                ? !!(
                                                    this.state.backgroundFiles
                                                      .length ===
                                                    sortedBackgroundImages.length
                                                  )
                                                : selected
                                            }
                                          />
                                        </li>
                                        {option.id >= 0 && (
                                          <>
                                            <img
                                              loading="lazy"
                                              width={75}
                                              src={`${globalSettings.cdnBaseUrl}/api/asset/${option.assetToken}.jpg`}
                                              alt=""
                                            />
                                            &nbsp;
                                          </>
                                        )}
                                      </>
                                      {option.fileName}{" "}
                                      {option.id > 0
                                        ? `- (File Id: ${option.id})`
                                        : ``}
                                    </Box>
                                    {
                                      // -1 is the magic number for 'Select All' option
                                      option.id === -1 && <Divider />
                                    }
                                  </>
                                )}
                                renderInput={params => (
                                  <TextField {...params} label="Images" />
                                )}
                                onChange={(event, newValue, reason) => {
                                  // 'Select All...' option has a magic number of -1 and is being toggled
                                  if (newValue.find(v => v.id === -1)) {
                                    this.setState(
                                      {
                                        backgroundFiles:
                                          this.state.backgroundFiles.length ===
                                          sortedBackgroundImages.length
                                            ? []
                                            : sortedBackgroundImages
                                      },
                                      () => this.loadImage()
                                    )
                                  } else if (reason === "clear") {
                                    this.setState(
                                      {
                                        sortedBackgroundImages: [],
                                        backgroundFiles: []
                                      },
                                      () => this.loadImage()
                                    )
                                  } else {
                                    this.setState(
                                      {
                                        backgroundFiles: newValue
                                      },
                                      () => this.loadImage()
                                    )
                                  }
                                }}
                                disabled={
                                  this.state.backgroundImages?.length === 0
                                }
                              />
                            </GridItem>
                            {this.state.backgroundFiles.find(
                              f => f.id === -2 // -2 is a magic number for a custom background image url
                            ) && (
                              <GridItem md={12}>
                                <CustomInput
                                  labelText="Background Image Url"
                                  id="backgroundImageUrl"
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    onChange: this.handleTextChange,
                                    value: this.state.backgroundImageUrl
                                  }}
                                />
                              </GridItem>
                            )}
                            {this.state.backgroundFiles.length > 0 && (
                              <GridContainer>
                                <div>&nbsp;</div>
                                <GridItem sm={12} md={12}>
                                  Opacity
                                </GridItem>
                                <GridItem sm={12} md={6}>
                                  <Slider
                                    value={this.state.backgroundImageOpacity}
                                    onChange={(e, value) =>
                                      this.onBackgroundImageOpacityChange(value)
                                    }
                                    aria-labelledby="input-slider"
                                    max={100}
                                    min={0}
                                    step={10}
                                    valueLabelDisplay="on"
                                    marks={opacityMarks}
                                  />
                                </GridItem>
                                <GridItem lg={4}>
                                  <Switch
                                    id="isBackgroundImageGrayscale"
                                    checked={
                                      this.state.isBackgroundImageGrayscale
                                    }
                                    onChange={this.handleSwitchChange}
                                    color="primary"
                                    size="small"
                                  />{" "}
                                  Grayscale?
                                </GridItem>

                                <GridItem md={4} style={{ marginTop: "10px" }}>
                                  <Autocomplete
                                    id="background-image-resizemodes-autocomplete"
                                    options={backgroundImageResizeMenuItems}
                                    value={backgroundImageResizeMenuItems.find(
                                      item =>
                                        item.key ===
                                        this.state.backgroundImageResizeMode
                                    )}
                                    onChange={(event, newValue, reason) => {
                                      if (reason === "clear") {
                                        this.setState(
                                          {
                                            backgroundImageResizeMode: -1
                                          },
                                          () => this.loadImage()
                                        )
                                      } else {
                                        this.handleMUIComponentChange(
                                          "backgroundImageResizeModeShortcutOption",
                                          newValue
                                        )
                                        this.setState({
                                          backgroundImageResizeMode:
                                            newValue.key
                                        })
                                      }
                                    }}
                                    getOptionLabel={option => option.name}
                                    renderInput={params => (
                                      <TextField
                                        {...params}
                                        label="Resize Mode"
                                      />
                                    )}
                                  />
                                </GridItem>
                              </GridContainer>
                            )}
                            <div>&nbsp;</div>
                          </GridContainer>
                        </>
                      )
                    },
                    {
                      title: "Watermark logo",
                      content: (
                        <>
                          {!this.state.isToDisplayWatermark && (
                            <GridItem>Not applicable.</GridItem>
                          )}
                          {this.state.isToDisplayWatermark && (
                            <GridContainer>
                              <GridItem md={12}>
                                <Autocomplete
                                  id="watermark-logo-autocomplete"
                                  options={watermarkShortcutMenuItems}
                                  value={watermarkShortcutMenuItems.find(
                                    item =>
                                      item.key ===
                                      this.state.watermarkUrlShortcutId
                                  )}
                                  onChange={(event, newValue, reason) => {
                                    if (reason === "clear") {
                                      this.setState(
                                        {
                                          watermarkUrlShortcutId: -1, // Magic number for 'No watermark'
                                          watermarkUrl: ""
                                        },
                                        () => this.loadImage()
                                      )
                                    } else {
                                      this.handleMUIComponentChange(
                                        "watermarkUrlShortcutOption",
                                        newValue
                                      )
                                      this.setState({
                                        watermarkUrl: newValue.url || null,
                                        watermarkUrlShortcutId: newValue.key
                                      })
                                    }
                                  }}
                                  getOptionLabel={option => option.name}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      label="Watermark Logo Shortcut"
                                      helperText=" "
                                    />
                                  )}
                                />
                              </GridItem>
                              {this.state.watermarkUrlShortcutId === 2 && (
                                <GridItem md={12}>
                                  <TextField
                                    label="Watermark Logo Url"
                                    id="watermarkUrl"
                                    fullWidth={true}
                                    inputProps={{
                                      onChange: this.handleTextChange,
                                      value: this.state.watermarkUrl
                                    }}
                                  />
                                </GridItem>
                              )}
                            </GridContainer>
                          )}
                          <div>&nbsp;</div>
                        </>
                      )
                    },
                    {
                      title: "Dimensions",
                      content: (
                        <>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              Width
                            </GridItem>
                            <GridItem xs={10} sm={8} md={8}>
                              <Slider
                                value={this.state.graphicWidth}
                                onChange={(e, value) =>
                                  this.onGraphicWidthChange(value)
                                }
                                aria-labelledby="input-slider"
                                max={this.state.imageDownloadMaxWidth}
                                min={this.state.imageDownloadMinWidth}
                                step={10}
                                valueLabelDisplay="auto"
                              />
                            </GridItem>
                            <GridItem xs={2} sm={4} md={4}>
                              <TextField
                                className={classes.input}
                                fullWidth
                                value={this.state.graphicWidth}
                                margin="dense"
                                onChange={e =>
                                  this.onGraphicWidthChange(e.target.value)
                                }
                                inputProps={{
                                  step: 1,
                                  min: this.state.imageDownloadMinWidth,
                                  max: this.state.imageDownloadMaxWidth,
                                  type: "number",
                                  "aria-labelledby": "input-slider"
                                }}
                              />
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              Height
                            </GridItem>
                            <GridItem xs={10} sm={8} md={8}>
                              <Slider
                                value={this.state.graphicHeight}
                                onChange={(e, value) =>
                                  this.onGraphicHeightChange(value)
                                }
                                aria-labelledby="input-slider"
                                max={this.state.graphicDownloadMaxHeight}
                                min={this.state.graphicDownloadMinHeight}
                                step={10}
                                valueLabelDisplay="auto"
                              />
                            </GridItem>
                            <GridItem xs={2} sm={4} md={4}>
                              <TextField
                                className={classes.input}
                                value={this.state.graphicHeight}
                                fullWidth
                                margin="dense"
                                onChange={e =>
                                  this.onGraphicHeightChange(e.target.value)
                                }
                                // onBlur={handleBlur}
                                inputProps={{
                                  step: 1,
                                  min: this.state.graphicDownloadMinHeight,
                                  max: this.state.graphicDownloadMaxHeight,
                                  type: "number",
                                  "aria-labelledby": "input-slider",
                                  variant: "outlined"
                                }}
                              />
                            </GridItem>
                          </GridContainer>
                        </>
                      )
                    },
                    {
                      title: "External images",
                      content: (
                        <>
                          {!this.state.isToDisplayExternalImages && (
                            <GridItem>Not applicable.</GridItem>
                          )}
                          {this.state.isToDisplayExternalImages && (
                            <>
                              <GridContainer>
                                <GridItem md={1}>
                                  <Checkbox
                                    id="isUseImage1Url"
                                    checked={this.state.isUseImage1Url}
                                    onChange={this.handleSwitchChange}
                                    classes={{
                                      checked: classes.checked,
                                      root: classes.checkRoot
                                    }}
                                    checkedIcon={
                                      <Check className={classes.checkedIcon} />
                                    }
                                    icon={
                                      <Check
                                        className={classes.uncheckedIcon}
                                      />
                                    }
                                  />
                                </GridItem>
                                <GridItem md={11}>
                                  <TextField
                                    label="Image #1 Url"
                                    id="image1Url"
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      onChange: this.handleTextChange,
                                      onBlur: this.onTextAreaBlur,
                                      value: this.state.image1Url
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>
                              <GridContainer>
                                <GridItem md={1}>
                                  <Checkbox
                                    id="isUseImage2Url"
                                    checked={this.state.isUseImage2Url}
                                    onChange={this.handleSwitchChange}
                                    classes={{
                                      checked: classes.checked,
                                      root: classes.checkRoot
                                    }}
                                    checkedIcon={
                                      <Check className={classes.checkedIcon} />
                                    }
                                    icon={
                                      <Check
                                        className={classes.uncheckedIcon}
                                      />
                                    }
                                  />
                                </GridItem>
                                <GridItem md={11}>
                                  <TextField
                                    label="Image #2 Url"
                                    id="image2Url"
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      onChange: this.handleTextChange,
                                      value: this.state.image2Url
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>
                            </>
                          )}
                        </>
                      )
                    },
                    {
                      title: "Bet & Stake Percentages",
                      content: (
                        <>
                          {!this.state.isToDisplayTeamABetPercentage &&
                            !this.state.isToDisplayTeamAStakePercentage && (
                              <GridItem>Not applicable.</GridItem>
                            )}
                          {this.state.isToDisplayTeamABetPercentage && (
                            <GridItem md={12} sm={12}>
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                  Team 'A' Bet %
                                </GridItem>
                                <GridItem xs={8} sm={8} md={8}>
                                  <Slider
                                    id="teamABetPercentage"
                                    value={this.state.teamABetPercentage}
                                    onChange={(e, value) =>
                                      this.handleMUIComponentChange(
                                        "teamABetPercentage",
                                        value
                                      )
                                    }
                                    aria-labelledby="teamABetPercentage"
                                    max={100}
                                    min={0}
                                    step={1}
                                    valueLabelDisplay="auto"
                                  />
                                </GridItem>
                                <GridItem xs={4} sm={4} md={4}>
                                  <Input
                                    id="teamABetPercentage"
                                    style={{ width: 35 }}
                                    className={classes.input}
                                    fullWidth
                                    value={this.state.teamABetPercentage.toString()}
                                    margin="dense"
                                    onChange={this.handleSliderInputChange}
                                    inputProps={{
                                      step: 1,
                                      min: 1,
                                      max: 100,
                                      type: "number",
                                      "aria-labelledby": "input-slider"
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>
                            </GridItem>
                          )}
                          {this.state.isToDisplayTeamAStakePercentage && (
                            <GridItem md={12} sm={12}>
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                  Team 'A' Stake %
                                </GridItem>
                                <GridItem xs={8} sm={8} md={8}>
                                  <Slider
                                    id="teamAStakePercentage"
                                    value={this.state.teamAStakePercentage}
                                    onChange={(e, value) =>
                                      this.handleMUIComponentChange(
                                        "teamAStakePercentage",
                                        value
                                      )
                                    }
                                    aria-labelledby="teamAStakePercentage"
                                    max={100}
                                    min={0}
                                    step={1}
                                    valueLabelDisplay="auto"
                                  />
                                </GridItem>
                                <GridItem xs={4} sm={4} md={4}>
                                  <Input
                                    id="teamAStakePercentage"
                                    className={classes.input}
                                    style={{ width: 35 }}
                                    fullWidth
                                    value={this.state.teamAStakePercentage.toString()}
                                    margin="dense"
                                    onChange={this.handleSliderInputChange}
                                    inputProps={{
                                      step: 1,
                                      min: 1,
                                      max: 100,
                                      type: "number",
                                      "aria-labelledby": "input-slider"
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>
                            </GridItem>
                          )}
                        </>
                      )
                    },
                    {
                      title: "Image Search Tool",
                      content: (
                        <>
                          <ImageSearchTool />
                        </>
                      )
                    }
                  ]}
                />
              </GridItem>
            </GridContainer>
          </form>
        </CardBody>
      </Card>
    )
  }
}

export default withStyles(regularFormsStyle)(useStore(GraphicsTool))
