// eslint-disable-next-line no-unused-vars
import React from "react"
// Custom Components
import TriggerIdSync from "./TriggerIdSync"
// Stock Components
import CardHeader from "components/Card/CardHeader.jsx"
import Thermostat from "assets/icons/Thermostat"
import CardIcon from "components/Card/CardIcon.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import TriggerMetadataSync from "./TriggerMetadataSync"

function HealthCheck(props) {
  return (
    <>
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <Thermostat />
          </CardIcon>
        </CardHeader>
        <CardBody>
          <TriggerIdSync {...props} />
          <TriggerMetadataSync {...props} />
        </CardBody>
      </Card>
    </>
  )
}

export default HealthCheck
