import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import ReactTable from "react-table-6"
import { Link } from "react-router-dom"
import withStyles from "@mui/styles/withStyles"
import { groupCollectionBy } from "tools/CollectionHelper"
import { globalSettings } from "variables/general"
import TimeAgo from "react-timeago"
import Helpers from "tools/Helpers.js"
import Enums from "tools/Enums.js"
import Button from "components/CustomButtons/Button"
import UploadRenderSchedule from "components/CustomUpload/UploadRenderSchedule"
import NarrativeRenderScheduler from "components/NarrativeAdmin/NarrativeRenderScheduler"
import AdHocRenderScheduler from "components/NarrativeAdmin/AdHocRenderScheduler"

import moment from "moment"
import { Cookies } from "tools/storage"
import queryString from "query-string"
import { search_remove_all } from "tools/querystringHelper"

import CircularProgress from "@mui/material/CircularProgress"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import Modal from "@mui/material/Modal"
import LinearProgress from "@mui/material/LinearProgress"

import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
//import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon"
import CardHeader from "components/Card/CardHeader"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogActions from "@mui/material/DialogActions"
import Card from "@mui/material/Card"

import Edit from "@mui/icons-material/Edit"
import Schedule from "@mui/icons-material/Schedule"
import RssFeed from "@mui/icons-material/RssFeed"
import LibraryBooks from "@mui/icons-material/LibraryBooks"
import Delete from "@mui/icons-material/Delete"
import PauseCircleFilled from "@mui/icons-material/PauseCircleFilled"
import PlayCircleOutline from "@mui/icons-material/PlayCircleOutline"
import Loop from "@mui/icons-material/Loop"
import Clear from "@mui/icons-material/Clear"
import History from "@mui/icons-material/History"
import ArrowBack from "@mui/icons-material/ArrowBack"
import Visibility from "@mui/icons-material/Visibility"
import RepeatOneIcon from "@mui/icons-material/RepeatOne"

import "react-cron-builder/dist/bundle.css"
import ChangeLog from "../../components/NarrativeAdmin/Data/ChangeLog"

import NarrativeRenderScheduleDAO from "daos/narrativeRenderScheduleDAO"
import OrganizationDAO from "daos/organizationDAO"
import TableControlsNoToolbar from "components/NarrativeAdmin/Data/TableControlsNoToolbar"
import { observer, inject } from "mobx-react"
import InfoDialog from "components/CustomDialogs/InfoDialog"
import { List, Typography, ListItemText } from "@mui/material"
import ListItem from "@mui/material/ListItem"

import WebhookIcon from "assets/icons/WebhookIcon"
import { get, put } from "tools/request"

const styles = theme => ({
  root: {
    display: "block",
    position: "relative",
    height: "calc(200vh - 133px)",
    "& .highlighted": {
      backgroundColor: "#ffeaa7",
      color: "#e17055",
      boxShadow: "-3px 0 0 #ffeaa7, 3px 0 0 #ffeaa7" // Creates an illusion of a wider span, while not affecting the actual width.
    }
  },
  toolbar: {
    display: "flex",
    minHeight: "48px",
    padding: "0px 15px"
  },
  toggleHeader: {
    margin: "0 15px",
    padding: "10px 0!important",
    display: "flex"
  },
  toggleHeaderTitle: {
    flexGrow: 1
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    padding: "0px",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  content: {
    flexGrow: 2,
    background: "#fff"
  },
  appBar: {
    border: "1px solid #ddd",
    zIndex: "5",
    padding: theme.spacing(2)
  },
  explorerDrawer: {
    backgroundColor: "#efefef",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    "& > div": {
      flex: 1
    }
  },
  search: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  searchIcon: {
    padding: "6px",
    backgroundColor: "#fff"
  },
  inputRoot: {
    color: "inherit"
  },
  selectInput: {
    margin: "5px",
    paddingLeft: "10px",
    flexGrow: 2,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%"
    }
  },
  inputInput: {
    margin: "5px",
    paddingLeft: "10px",
    flexGrow: 2,
    transition: theme.transitions.create("width"),
    width: "100%",
    backgroundColor: "#efefef",
    [theme.breakpoints.up("md")]: {
      width: "100%"
    }
  },
  iconButton: {
    padding: "6px"
  },
  cardIconTitle: {
    color: "#000000"
  },
  cardIconTitleLeft: {
    color: "#000000",
    display: "inline-block",
    textAlign: "left",
    width: "50%"
  },
  renderButtons: {
    color: "#000000",
    display: "block",
    textAlign: "left",
    padding: "0px",
    margin: "5px 0 0 15px"
  },
  scheduleOutputModal: {
    width: "90vw",
    height: "90vh",
    margin: "5vh auto",
    overflowY: "auto"
  },
  scheduleAdHocDisabled: {
    color: "rgba(0, 0, 0, 0.9) !important"
  },
  scheduleAdHocOutputModal: {
    width: "60vw",
    height: "30%",
    minHeight: "350px",
    margin: "13vh auto"
  },
  alertMessageModal: {
    width: "40vw",
    height: "200px",
    margin: "34vh auto"
  },
  tabs: {
    borderBottom: "none!important",
    backgroundColor: "#fff",
    "& > .nav-item .nav-link": {
      cursor: "pointer"
    },
    "& > .nav-item .nav-link:hover": {
      backgroundColor: "rgba(0,0,0, 0.2)"
    },
    "& > .nav-item .nav-link.active": {
      backgroundColor: "#efefef",
      borderColor: "#dee2e6 #dee2e6 transparent"
    },
    "& .nav-tabs > .nav-tabs .nav-link.active": {
      backgroundColor: "#fff",
      borderColor: "#dee2e6 #dee2e6 #fff"
    }
  },
  gridOffset: {
    margin: "0 0 0 5px"
  },
  gridOffset2: {
    margin: "0 0 0 15px"
  },
  cronBuilder: {
    width: "34vw",
    margin: "21vh auto",
    "& .cron-builder": {
      backgroundColor: "#eee"
    },
    "& .cron-builder__legend": {
      fontSize: "14px"
    },
    "& .cron-builder__tab": {
      margin: "4px"
    },
    "& .Select-control": {
      minWidth: "110px"
    },
    "& .cron-builder__label": {
      color: "#999"
    }
  },
  resultsModal: {
    position: "absolute",
    width: "90vw",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "45%",
    left: "45%",
    transform: "translate(-45%, -45%)",
    textAlign: "center"
  },
  requiredMsg: {
    color: "#c00",
    fontWeight: "bold",
    backgroundColor: "#f7f7f7"
  },
  renderJobInfo: {
    display: "flex",
    alignItems: "center"
  }
})

const columns = [
  {
    Header: "Narrative Id",
    accessor: "narrativeId",
    sortable: false,
    filterable: false
  },
  {
    Header: "feedId",
    accessor: "feedId"
  },
  {
    Header: "contentId",
    accessor: "contentId"
  },
  {
    Header: "contentType",
    accessor: "contentType"
  },
  {
    Header: "duplicateHandling",
    accessor: "duplicateHandling"
  },
  {
    Header: "useRecurrenceSettings",
    accessor: "useRecurrenceSettings"
  },
  {
    Header: "cronTimeZoneIANA",
    accessor: "cronTimeZoneIANA"
  },
  {
    Header: "publishDelayInSeconds",
    accessor: "publishDelayInSeconds"
  }
]

const defaultProps = {
  scheduledJobs: []
}

const useStore = component =>
  inject(({ store }) => ({
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization,
    organizationId: store.organizationStore.organizationId,
    isPermissionsLoaded: store.uiStore.isPermissionsLoaded,
    accountPermissions: store.accountStore.accountPermissions,
    organizationProperties: store.organizationStore.organizationProperties
  }))(observer(component))

class NarrativeRenderSchedules extends Component {
  constructor(props) {
    super(props)
    this.props = props
    const { isLoading, narrativeId } = this.props

    const queryParams = queryString.parse(this.props.location.search)
    let narrativeRenderSchedulesIsPausedFilter
    let cookies = new Cookies()
    const goingFromNarrativeManagerToRenderSchedules = cookies.get(
      "goingFromNarrativeManagerToRenderSchedules"
    )
    const narrativeRenderSchedulesFeedEntriesPageSize = cookies.get(
      "narrativeRenderSchedulesFeedEntriesPageSize"
    )
      ? Number(cookies.get("narrativeRenderSchedulesFeedEntriesPageSize"))
      : 25
    if (!goingFromNarrativeManagerToRenderSchedules) {
      narrativeRenderSchedulesIsPausedFilter = cookies.get(
        "narrativeRenderSchedulesIsPausedFilter"
      )
        ? cookies.get("narrativeRenderSchedulesIsPausedFilter")
        : "false"
    } else {
      narrativeRenderSchedulesIsPausedFilter = "all"
      cookies.set("goingFromNarrativeManagerToRenderSchedules", 0)
    }
    //Check for ID to filter on
    let scheduleId = parseInt(queryParams.id, 10) || null
    // If there's an id filter on it otherwise filter on not paused
    let tableFilter = scheduleId
      ? [
          {
            id: "id",
            value: scheduleId
          }
        ]
      : [
          {
            id: "isPaused",
            value: narrativeRenderSchedulesIsPausedFilter
          }
        ]
    this.state = {
      narrativeId: narrativeId,
      narrativeRenderScheduleId: -1,
      organizationId: this.props.organizationId,
      editSchedule: false,
      isLoadingJobs: false,
      isLoading: isLoading,
      isLoadingRowId: [],
      selectedSchedule: {},
      tableFilter,
      narrativeRenderScheduleIdToArchive: null,
      isArchiveConfirmationDisplayed: false,
      isPausedConfirmationDisplayed: false,
      doDisplayArchivedJobs: false,
      isDisplayFutureOccurrences: false,
      cronSyntaxMouseX: 0,
      cronSyntaxMouseY: 0,
      cronSyntax: "",
      cronTimeZoneIANA: "",
      startDateTime: new Date(),
      endDateTime: null,
      useRecurrenceSettings: false,
      maxFutureOccurrenceCount: 10,
      futureOccurrences: "",
      futureOccurrencesMessage: "",
      isRenderSchedulerOpen: false,
      isRenderScheduleConfirmationOpen: false,
      isChangeHistoryModalOpen: false,
      narrative: {},
      contentTypeId: null,
      contentType: null,
      contentId: null,
      feeds: [],
      outputFeedId: 0,
      selectedMatchIds: "",
      jobLogs: [],
      jobOutputLogs: [],
      selectedScheduleId: -1,
      expandedScheduleRows: {},
      selectedJobId: -1,
      expandedJobRows: {},
      publishDelayInSeconds: 0,
      pageSize: narrativeRenderSchedulesFeedEntriesPageSize,
      narrativeRenderSchedulesIsPausedFilter:
        narrativeRenderSchedulesIsPausedFilter,
      isCronBuilderOpen: false,
      isKillJobConfirmationDisplayed: false,
      scheduleChangeLogs: [],
      showResultsModal: false,
      resultTableData: {},
      displayProperties: {},
      orderByScheduleId: false,
      ignoreKillSwitch: false,
      contractDeliverableId: null,
      contractDeliverables: [],
      isProductionFeed: false
    }
  }

  toggleGenFeedModal() {}

  hideRenderScheduleConfirmation() {}

  updateNarrativeRenderSchedule() {
    const {
      narrativeRenderScheduleId,
      startDateTime,
      endDateTime,
      duplicateHandling,
      isDebug,
      qaReports,
      useRecurrenceSettings,
      cronSyntax,
      publishDelayInSeconds,
      contentId,
      queryResultsIndexRange,
      isQaAllTriggers,
      outputFeedId,
      contractDeliverableId
    } = this.state
    put(
      `${globalSettings.apiBaseUrl}/api/narrativerenderschedule/update`,
      null,
      {
        id: narrativeRenderScheduleId,
        isDebug: isDebug === true || isQaAllTriggers,
        runQAReports: qaReports === false,
        debugAllTriggers: isQaAllTriggers,
        startDateTime,
        endDateTime,
        duplicateHandling,
        useRecurrenceSettings,
        cronSyntax,
        contentId,
        queryResultsIndexRange,
        publishDelayInSeconds,
        contractDeliverableId,
        feedId: outputFeedId
      }
    )
      .then(Response => Response && Response.ok === true && Response.json())
      .then(results => {
        if (results) {
          this.setState({
            isRenderSchedulerOpen: false
          })
          this.props.loadScheduleCallback(
            narrativeRenderScheduleId,
            this.state.organizationId
          )
        } else {
          this.setState({
            alertMessage: `Something went wrong updating the narrative render schedule. Check the details and try again.`,
            isAlertMessageOpen: true
          })
        }
      })
  }

  loadFeeds(organizationId, outputFeedId) {
    get(
      `${globalSettings.apiBaseUrl}/api/feed/retrieveall?organizationid=${organizationId}`
    )
      .then(Response => Response.json())
      .then(feeds => {
        // never show 'archived' feeds
        let filteredFeeds = []
        filteredFeeds = feeds.content.types.filter(f => f.isArchived === false)
        let isProductionFeed = false
        filteredFeeds.forEach(itm => {
          if (
            itm.id === outputFeedId &&
            itm.feedStatus === Enums.FeedStatus.ACTIVE
          ) {
            isProductionFeed = true
          }
        })
        // When a narrative is in 'Validate' status only display
        // feeds that are also in 'Validate' status
        if (
          this.state.narrative.narrativeStatusId ===
          Enums.NarrativeStatus.VALIDATE
        ) {
          filteredFeeds = filteredFeeds.filter(
            f => f.feedStatus === Enums.FeedStatus.VALIDATE
          )
        }
        this.setState({
          feeds: filteredFeeds,
          isProductionFeed
        })
      })
  }

  loadScheduleChangeLogs(narrativeRenderScheduleId) {
    get(
      `${globalSettings.apiBaseUrL}/api/narrativerenderschedule/retrieveschedulechangelog?narrativeRenderScheduleId=${narrativeRenderScheduleId}`
    )
      .then(Response => Response.json())
      .then(response => {
        this.setState({
          scheduleChangeLogs: response
        })
      })
  }

  getContractDeliverables(narrative) {
    const fetchData = async id => {
      const response = await OrganizationDAO.getContractDeliverablesForSchedule(
        narrative?.organization?.id,
        narrative.leagueId
      )
      const { content } = response
      //filter list to only those matching
      const filteredDeliverables = content.filter(
        cd => cd.narrativeId === narrative.id
      )
      this.setState({
        contractDeliverables:
          filteredDeliverables.length > 0 ? filteredDeliverables : content
      })
    }
    fetchData()
  }

  getItemValueFromPath(item, key) {
    if (!key || key === "") {
      return null
    }
    let value = ""
    if (typeof key !== "object") {
      //Make string values an array of length 1, keep arrays as arrays to iterate through
      key = [key]
    }
    key.forEach((ke, i) => {
      if (i !== 0) {
        value += " : "
      }
      if (ke && ke.indexOf(".") !== -1) {
        // eslint-disable-line no-negated-condition
        // handle nested keys
        value += ke.split(".").reduce(function (itemObj, nestedKey) {
          return itemObj[nestedKey]
        }, item)
      } else {
        value += item[ke]
      }
    })
    return value ? [].concat(value) : null
  }

  intFilterProps(propName, properties) {
    return {
      filterMethod: (filter, row) => {
        let currentValue = row._original[propName] || 0
        if (filter.value === "all") {
          return true
        }
        if (filter.value === ">0") {
          return currentValue > 0
        }
        // NOTE: string versus integer comparison,
        // don't use === operator
        // eslint-disable-next-line eqeqeq
        return row._original[propName] == filter.value
      },
      Filter: ({ filter, onChange }) => {
        if (properties.size === 0) {
          return null
        }

        const distinctOptions = Object.keys(
          groupCollectionBy(properties, p => p[propName])
        ).sort((a, b) => {
          let comparison = 0
          if (Number(a) > Number(b)) {
            comparison = 1
          } else if (Number(a) < Number(b)) {
            comparison = -1
          }
          return comparison
        })
        // build up the <select> element <option> element JSX style
        let options = []
        distinctOptions.forEach(opt => {
          options.push(
            <option value={opt} key={opt}>
              {opt}
            </option>
          )
        })
        return (
          <select
            onChange={event => {
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">All</option>
            <option value=">0">Not 0</option>
            {options}
          </select>
        )
      }
    }
  }

  stringFilterProps(propName, properties) {
    return {
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true
        }
        // NOTE: string versus integer comparison,
        // don't use === operator
        // eslint-disable-next-line eqeqeq
        return row[propName] == filter.value
      },
      Filter: ({ filter, onChange }) => {
        if (!properties || properties.size === 0) {
          return null
        }

        const distinctOptions = Object.keys(
          groupCollectionBy(properties, p =>
            this.getItemValueFromPath(p, propName)
          )
        ).sort((a, b) => {
          // Use toUpperCase() to ignore character casing
          const orgA = a.toUpperCase()
          const orgB = b.toUpperCase()
          let comparison = 0
          if (orgA > orgB) {
            comparison = 1
          } else if (orgA < orgB) {
            comparison = -1
          }
          return comparison
        })
        // build up the <select> element <option> element JSX style
        let options = []
        distinctOptions.forEach(opt => {
          options.push(
            <option value={opt} key={opt}>
              {opt}
            </option>
          )
        })
        return (
          <select
            onChange={event => {
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">All</option>
            {options}
          </select>
        )
      }
    }
  }

  idedStringFilterProps(propName, properties) {
    return {
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true
        }
        // NOTE: string versus integer comparison,
        // don't use === operator
        // eslint-disable-next-line eqeqeq
        return row[propName[1]] == filter.value
      },
      Filter: ({ filter, onChange }) => {
        if (!properties || properties.size === 0) {
          return null
        }

        const distinctOptions = Object.keys(
          groupCollectionBy(properties, p =>
            this.getItemValueFromPath(p, propName)
          )
        ).sort((a, b) => {
          // Use toUpperCase() to ignore character casing
          const orgA = a.toUpperCase()
          const orgB = b.toUpperCase()
          let comparison = 0
          if (orgA > orgB) {
            comparison = 1
          } else if (orgA < orgB) {
            comparison = -1
          }
          return comparison
        })
        // build up the <select> element <option> element JSX style
        let options = []
        let tmpOptions = []
        distinctOptions.forEach(opt => {
          let loc = opt.indexOf(" : ")
          let tmp
          tmp = opt.substring(loc, opt.length).replace(" : ", "")
          tmpOptions.push({ val: tmp, text: opt })
        })
        tmpOptions.forEach((opt, i) => {
          options.push(
            <option value={opt.val} key={i}>
              {opt.text}
            </option>
          )
        })
        return (
          <select
            onChange={event => {
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">All</option>
            {options}
          </select>
        )
      }
    }
  }
  dateFilterProps(propName) {
    return {
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true
        }
        let now = new Date()
        let futureTime = new Date()
        let pastTime = new Date()
        let columnDateTime = new Date(this.getItemValueFromPath(row, propName))
        if (filter.value === "future") {
          return columnDateTime >= now
        }
        if (filter.value === "past") {
          return columnDateTime < now
        }
        if (filter.value === "next7Days") {
          return (
            columnDateTime > now &&
            columnDateTime < futureTime.setHours(futureTime.getHours() + 7 * 24)
          )
        }
        if (filter.value === "next24hours") {
          return (
            columnDateTime > now &&
            columnDateTime < futureTime.setHours(futureTime.getHours() + 48)
          )
        }
        if (filter.value === "next1hour") {
          return (
            columnDateTime > now &&
            columnDateTime < futureTime.setHours(futureTime.getHours() + 2)
          )
        }
        if (filter.value === "within7Days") {
          return (
            columnDateTime > pastTime.setHours(pastTime.getHours() - 7 * 24) &&
            columnDateTime < futureTime.setHours(futureTime.getHours() + 7 * 24)
          )
        }
        if (filter.value === "within24hours") {
          return (
            columnDateTime > pastTime.setHours(pastTime.getHours() - 24) &&
            columnDateTime < futureTime.setHours(futureTime.getHours() + 48)
          )
        }
        if (filter.value === "within1hour") {
          return (
            columnDateTime > pastTime.setHours(pastTime.getHours() - 1) &&
            columnDateTime < futureTime.setHours(futureTime.getHours() + 2)
          )
        }
        if (filter.value === "past7Days") {
          return (
            columnDateTime > pastTime.setHours(pastTime.getHours() - 7 * 24) &&
            columnDateTime < now
          )
        }
        if (filter.value === "past24hours") {
          return (
            columnDateTime > pastTime.setHours(pastTime.getHours() - 24) &&
            columnDateTime < now
          )
        }
        if (filter.value === "past1hour") {
          return (
            columnDateTime > pastTime.setHours(pastTime.getHours() - 1) &&
            columnDateTime < now
          )
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value="future">Future</option>
          <option value="past">Past</option>
          <option value="next7Days">Next 7 Days</option>
          <option value="next24hours">Next 24 Hours</option>
          <option value="next1hour">Next 1 Hour</option>
          <option value="within7Days">Within 7 Days</option>
          <option value="within24hours">Within 24 Hours</option>
          <option value="within1hour">Within 1 Hour</option>
          <option value="past7Days">Past 7 Days</option>
          <option value="past24hours">Past 24 Hours</option>
          <option value="past1hour">Past 1 Hour</option>
        </select>
      ),
      Cell: cell =>
        cell.value &&
        cell.value !== "" && (
          <div>
            <TimeAgo
              date={cell.value}
              title={Helpers.prettyDateTimeinPacificTimeZone(cell.value)}
            />
          </div>
        )
    }
  }

  boolFilterProps(propName) {
    return {
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true
        }
        if (filter.value === "true") {
          return row[propName] === true
        }
        if (filter.value === "false") {
          return row[propName] === false
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value="false">No</option>
          <option value="true">Yes</option>
        </select>
      )
    }
  }

  truthyIntFilterProps(propName) {
    return {
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true
        }
        if (filter.value === "true") {
          return parseInt(row[propName], 10) > 0
        }
        if (filter.value === "false") {
          return !row[propName]
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value="false">No</option>
          <option value="true">Yes</option>
        </select>
      )
    }
  }

  retrieveJobLogs(narrativeRenderScheduleId) {
    this.setState({ isLoadingJobs: true, jobLogs: [] })
    get(
      `${globalSettings.apiBaseUrl}/api/narrativerenderschedule/retrievejoblog?narrativeRenderScheduleId=${narrativeRenderScheduleId}`
    )
      .then(Response => Response && Response.ok === true && Response.json())
      .then(result => {
        if (result) {
          this.setState({ jobLogs: result })
        } else {
          return "problem loading data"
        }
        this.setState({ isLoadingJobs: false })
      })
  }

  retrieveJobOutputLog(narrativeRenderScheduleLogId) {
    this.setState({ jobOutputLogs: [] })
    get(
      `${globalSettings.apiBaseUrl}/api/narrativerenderschedule/retrievejoboutputlog?narrativeRenderScheduleLogId=${narrativeRenderScheduleLogId}`
    )
      .then(Response => Response && Response.ok === true && Response.json())
      .then(result => {
        if (result) {
          this.setState({ jobOutputLogs: result })
        } else {
          return "problem loading data"
        }
      })
  }

  retrieveJobLogCount(narrativeRenderScheduleId) {
    this.setState({ jobLogCount: 0 })
    get(
      `${globalSettings.apiBaseUrl}/api/narrativerenderschedule/retrievejoblogcount?narrativeRenderScheduleId=${narrativeRenderScheduleId}`
    )
      .then(Response => Response.text())
      .then(text => {
        this.setState({
          jobLogCount: text
        })
      })
  }

  calculateFutureOccurrences() {
    const {
      startDateTime,
      endDateTime,
      useRecurrenceSettings,
      maxFutureOccurrenceCount,
      cronSyntax
    } = this.state
    let cronSyntaxValue = cronSyntax
    let endDateTimeValue =
      endDateTime != null ? endDateTime.toISOString() : null
    /*if (!useRecurrenceSettings) {
      cronSyntaxValue = "* * * * *"
      endDateTimeValue = ""
    }*/
    get(
      `${
        globalSettings.apiBaseUrl
      }/api/narrativerenderschedule/futureoccurrences?cronsyntax=${cronSyntaxValue}&startdatetime=${
        this.state.startDateTime ? startDateTime.toISOString() : ""
      }&userecurrencesettings=${useRecurrenceSettings}&enddatetime=${endDateTimeValue}&maxFutureOccurrenceCount=${maxFutureOccurrenceCount}`
    )
      .then(Response => Response && Response.ok === true && Response.json())
      .then(result => {
        if (result && result.responseCode === 1000) {
          this.setState({
            futureOccurrences: result.content,
            futureOccurrencesMessage: ""
          })
        } else {
          this.setState({
            futureOccurrences: null,
            futureOccurrencesMessage: `There was a problem described as ${result.responseMessage}.`
          })
        }
      })
  }

  generateBody(itm) {
    const body = {
      narrativeId: Number(itm.narrativeId),
      contentId: Number(itm.contentId),
      contentType: "query",
      contentTypeId: 10,
      isDebug: false,
      runQAReports: false,
      debugAllTriggers: false,
      feedId: Number(itm.feedId),
      duplicateHandling: itm.duplicateHandling,
      isPaused: false,
      useRecurrenceSettings: false,
      cronSyntax: "",
      cronTimeZoneIANA: itm.cronTimeZoneIANA,
      publishDelayInSeconds: itm.publishdelayinseconds,
      contractDeliverableId: itm.contractDeliverableId,
      webhookId: itm.webhookId,
      hideFromFeed: itm.hideFromFeed,
      narrativeBlock_Ids: "",
      futureOccurences: ""
    }
    return body
  }

  askIfYouWantToRender(cell) {
    if (
      typeof cell.original !== "object" ||
      typeof cell.original.deliveryFeed !== "object" ||
      typeof cell.original.narrative !== "object"
    ) {
      alert("broken, exiting")
      return
    }
    let item = {}
    let displayProperties = {}
    item.contentId = cell.original.content_Id
    item.publishdelayinseconds = cell.original.publishDelayInSeconds
    item.feedId = cell.original.deliveryFeed.id
    item.duplicateHandling = cell.original.duplicateHandling
    item.narrativeId = cell.original.narrative.id
    item.cronTimeZoneIANA = cell.original.cronTimeZoneIANA
    item.contractDeliverableId = cell.original.contractDeliverableId
    item.webhookId = cell.original.webhookId
    displayProperties.narrativeName = cell.original.narrative.name
    displayProperties.feedName = cell.original.deliveryFeed.name
    const body = this.generateBody(item)
    this.setState({
      resultTableData: body,
      displayProperties: displayProperties,
      showAskToRenderModal: true,
      changedRows: []
    })
  }

  clearResultsModal() {
    this.setState({
      resultTableData: {},
      displayProperties: {},
      showAskToRenderModal: false,
      showResultsModal: false
    })
  }

  runAdHocRender() {
    let body = this.state.resultTableData
    let resultArray = []
    let feedData = []
    const putScheduleFeedEntries =
      NarrativeRenderScheduleDAO.putScheduleFeedEntries(body)
    putScheduleFeedEntries.then(results => {
      if (results) {
        resultArray.push(results)
        body.nrsId = results
        feedData.push(body)
      } else {
        resultArray.push("failed")
      }
      if (resultArray.length === 1) {
        this.setState({
          resultTableData: body,
          showAskToRenderModal: false,
          showResultsModal: true
        })
      }
    })
  }

  reloadNewSchedules() {
    this.setState({
      resultTableData: {},
      displayProperties: {},
      showAskToRenderModal: false,
      showResultsModal: false,
      orderByScheduleId: true
    })
    this.props.loadScheduleCallback(
      null,
      this.props.organizationId,
      this.state.doDisplayArchivedJobs
    )
  }

  updateLocalCollection(rowIndex, columnId, value) {
    //skipResetRef.current = true // We also turn on the flag to not reset the page
    let setData = (old = []) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          if (row[columnId] !== value) {
            if (!this.state.changedRows.includes(rowIndex)) {
              this.state.changedRows.push(rowIndex)
              this.setState({
                changedRows: this.state.changedRows.push(rowIndex)
              })
            }
          }
          const newRow = {
            ...row,
            [columnId]: value
          }
          return newRow
        }
        return row
      })
    this.setState({
      resultTableData: setData
    })
  }

  displayArchiveConfirmation(narrativeRenderScheduleId) {
    this.setState({
      narrativeRenderScheduleIdToArchive: narrativeRenderScheduleId,
      isArchiveConfirmationDisplayed: true
    })
  }

  displayKillJobConfirmation(narrativeRenderJobId) {
    this.setState({
      narrativeRenderJobIdToKill: narrativeRenderJobId,
      isKillJobConfirmationDisplayed: true
    })
  }

  pauseJob(narrativeRenderScheduleId) {
    this.setState({ isLoadingRowId: [narrativeRenderScheduleId] })
    put(
      `${globalSettings.apiBaseUrl}/api/narrativerenderschedule/${narrativeRenderScheduleId}/pause`
    )
      .then(Response => Response.json())
      .then(() => {
        this.props.loadScheduleCallback(
          narrativeRenderScheduleId,
          this.state.organizationId
        )
      })
  }

  unPauseJob(narrativeRenderScheduleId) {
    if (
      this.props.isPermissionsLoaded &&
      this.props.accountPermissions.CanUnpauseRenderSchedule === "Yes"
    ) {
      this.setState({ isLoadingRowId: [narrativeRenderScheduleId] })
      put(
        `${globalSettings.apiBaseUrl}/api/narrativerenderschedule/${narrativeRenderScheduleId}/unpause`
      )
        .then(Response => Response.json())
        .then(() => {
          this.props.loadScheduleCallback(
            narrativeRenderScheduleId,
            this.state.organizationId
          )
        })
    } else {
      alert(
        "Your account does not have permission to 'Unpause' a Schedule.  Please contact a Support Specialist."
      )
    }
  }

  killConfirmed() {
    let jobId = this.state.narrativeRenderJobIdToKill
    put(
      `${globalSettings.apiBaseUrl}/api/narrativerenderschedule/killjob?jobid=${jobId}`
    )
      .then(Response => Response.json())
      .then(() => {
        this.setState({ isKillJobConfirmationDisplayed: false })
        alert(`Job # ${jobId} has been flagged to be killed.`)
        this.props.loadScheduleCallback(
          this.state.selectedScheduleId,
          this.state.organizationId
        )
      })
  }

  archiveConfirmed() {
    this.archiveNarrativeRenderSchedule(
      this.state.narrativeRenderScheduleIdToArchive
    )
    this.setState({ isArchiveConfirmationDisplayed: false })
  }

  archiveNarrativeRenderSchedule(narrativeRenderScheduleId) {
    this.setState({ isLoadingRowId: [narrativeRenderScheduleId] })
    put(
      `${globalSettings.apiBaseUrl}/api/narrativerenderschedule/${narrativeRenderScheduleId}/archive`
    )
      .then(Response => Response.json())
      .then(() => {
        this.props.loadScheduleCallback(
          narrativeRenderScheduleId,
          this.state.organizationId
        )
      })
  }

  rememberPageSizeChanges = pageSize => {
    this.setState({ pageSize })
    let cookies = new Cookies()
    cookies.set("narrativeRenderSchedulesFeedEntriesPageSize", pageSize, {
      path: "/",
      expires: Helpers.CookieExpiration.OneMonth
    })
  }

  getOrganizationName = id => {
    let val = ""
    this.props.organizationProperties?.forEach(itm => {
      if (itm.id === id) {
        val = itm.name
      }
    })
    return val
  }

  handleContentIdChangeEvent(event) {
    if (event.target.value < 0) {
      return
    }
    this.setState({ contentId: event.target.value })
  }

  handleQueryResultsIndexRangeChangeEvent(event) {
    if (event.target.value < 0) {
      return
    }
    this.setState({ queryResultsIndexRange: event.target.value })
  }

  renderJobDefinitions = [
    "Job ID: ID of the render job",

    "Actions: when a job is currently running, Action will display the option to kill the job",

    "Started: When the render job was started",

    "Ended: When the render job ended",

    "Duration: The amount of time it took to complete the render job",

    "Possible: The total possible number of feed entries to attempt to render based on the base query. The sum of all other columns (except for Published) should equal the number in Possible column",

    "Created: The number of brand new feed entries createdUpdated: The number of existing feed entries that were updated",

    "Skipped: The number of feed entries that were skipped because they were already existing",

    "Published: The total number of feed entries that were created or updated",

    "Flagged: The number of feed entries sent to editorial review",

    "Killswitched: The number of feed entries that were killswitched"
  ]

  render() {
    const { classes, isModal, fromNarrativeDashboard } = this.props
    let scheduledJobs = this.props.scheduledJobs || []

    const narrativeSpecificSchedule = isModal || !!this.props.narrativeId
    let isPreviousNarrativeSchedule = false
    if (this.props.fromNarrativeDashboard) {
      scheduledJobs.forEach(itm => {
        if (
          itm.narrative &&
          itm.narrative.id !== Number(this.props.narrativeId)
        ) {
          isPreviousNarrativeSchedule = true
        }
      })

      scheduledJobs = scheduledJobs.filter(
        itm =>
          itm.narrative && itm.narrative.id === Number(this.props.narrativeId)
      )
    }
    if (narrativeSpecificSchedule && !this.state.doDisplayArchivedJobs) {
      scheduledJobs = scheduledJobs.filter(itm => itm.isArchived === false)
    } else if (!narrativeSpecificSchedule) {
      //For the "/portal/render-schedule" page, shows for entire ORG.
      //If we click "Include Archive?" checkbox, we have all, don't need to reload if user toggles that checkbox
      // eslint-disable-next-line no-var
      var hasArchives = scheduledJobs.filter(itm => itm.isArchived)
      if (hasArchives.length > 0 && !this.state.doDisplayArchivedJobs) {
        scheduledJobs = scheduledJobs.filter(
          itm => itm.narrative && itm.narrative === false
        )
      }
    }

    const myHeight = isModal ? "85vh" : "auto"
    if (!scheduledJobs) {
      return "Assembling..."
    }

    let contractDeliverableId
    let webhookId
    let hideFromFeed
    scheduledJobs.forEach(itm => {
      if (itm.id === this.state.narrativeRenderScheduleId) {
        contractDeliverableId = itm.contractDeliverableId
        webhookId = itm.webhookId
        hideFromFeed = itm.hideFromFeed
      }
    })

    const hasWindowObject = typeof window !== "undefined"
    const screenWidth = hasWindowObject ? window.innerWidth : null
    const screenHeight = hasWindowObject ? window.innerHeight : null
    const showPagination =
      screenWidth && screenHeight && (screenHeight > 959 || screenWidth > 959)

    return (
      <Card>
        <Dialog
          onClose={() =>
            this.setState({ isArchiveConfirmationDisplayed: false })
          }
          aria-labelledby="customized-dialog-title"
          open={this.state.isArchiveConfirmationDisplayed}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-title">
            {"Archive the following job?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`(Job Id #${this.state.narrativeRenderScheduleIdToArchive})`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                this.setState({ isArchiveConfirmationDisplayed: false })
              }
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => this.archiveConfirmed()}
              color="primary"
              autoFocus
            >
              Yes, archive it
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          onClose={() =>
            this.setState({ isKillJobConfirmationDisplayed: false })
          }
          aria-labelledby="customized-dialog-title"
          open={this.state.isKillJobConfirmationDisplayed}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-title">
            {"Kill the following job?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`(Job Id #${this.state.narrativeRenderJobIdToKill})`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                this.setState({ isKillJobConfirmationDisplayed: false })
              }
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => this.killConfirmed()}
              color="primary"
              autoFocus
            >
              Yes, kill it
            </Button>
          </DialogActions>
        </Dialog>

        <CardHeader color="primary" icon style={{ display: "flex" }}>
          <CardIcon color="primary">
            <Schedule />
          </CardIcon>
          <h4 className={classes.cardIconTitleLeft}>
            Narrative Renders{" "}
            {this.state.narrativeId != null && (
              <span>for {this.state.narrativeId}</span>
            )}
          </h4>
        </CardHeader>
        <div className={classes.renderButtons}>
          {!this.props.isLoading && (
            <UploadRenderSchedule
              reloadSchedules={() => {
                this.reloadNewSchedules()
              }}
              organizationId={this.props.organizationId}
            />
          )}
        </div>
        {fromNarrativeDashboard && (
          <>
            <h4 style={{ marginLeft: "20px" }}>
              <Link
                title={`Back to Narrative Dashboard for Narrative ${this.props.narrativeId}`}
                onClick={e => {
                  this.props.history.goBack()
                  e.preventDefault()
                }}
              >
                <strong>
                  <ArrowBack />
                  Back to Narrative Dashboard
                </strong>
              </Link>{" "}
            </h4>
          </>
        )}
        <CardBody
          style={{
            overflow: "auto",
            paddingTop: "0px",
            height: `${myHeight}`
          }}
        >
          {this.props.isLoading && <LinearProgress />}
          {!this.props.isLoading && scheduledJobs.length > 0 && (
            <ReactTable
              minRows={0}
              loading={this.props.isLoading}
              data={scheduledJobs}
              noDataText={
                this.props.isLoading ? (
                  <CircularProgress />
                ) : (
                  <h2 style={{ fontWeight: "bold" }}>No jobs found.</h2>
                )
              }
              filtered={this.state.tableFilter}
              onFilteredChange={filtered => {
                const searchParams = queryString.parse(
                  this.props.location.search
                )
                if (searchParams.id) {
                  let newSearch = search_remove_all("id")
                  this.props.history.push({ search: newSearch })
                  filtered = filtered && filtered.find(item => item.id !== "id")
                }

                const isPausedFilterValue =
                  filtered && filtered.find(item => item.id === "isPaused")

                this.setState({
                  tableFilter: filtered,
                  narrativeRenderSchedulesIsPausedFilter:
                    isPausedFilterValue && isPausedFilterValue.value
                })

                if (isPausedFilterValue) {
                  let cookies = new Cookies()
                  cookies.set(
                    "narrativeRenderSchedulesIsPausedFilter",
                    isPausedFilterValue.value,
                    {
                      path: "/",
                      expires: Helpers.CookieExpiration.OneMonth
                    }
                  )
                }
              }}
              columns={[
                {
                  Header: "Organization",
                  accessor: "narrative.organization.name",
                  ...this.stringFilterProps(
                    "narrative.organization.name",
                    scheduledJobs
                  ),
                  width: 175
                },
                {
                  Header: "Job Id",
                  accessor: "id",
                  sortable: true,
                  filterable: true,
                  width: 110
                },
                {
                  Header: "Actions",
                  Cell: cell => (
                    <div className="actions-right">
                      {!cell.original.isArchived &&
                      cell.original.useRecurrenceSettings ? (
                        <Button
                          justIcon
                          round
                          simple
                          color="primary"
                          className="info"
                          title="Edit"
                          onClick={() => {
                            this.setState({
                              narrativeRenderScheduleId: cell.original.id,
                              narrative: cell.original.narrative,
                              contentTypeId: cell.original.contentType_Id,
                              contentType: cell.original.contentType,
                              contentId: cell.original.content_Id,
                              outputFeedId: cell.original.deliveryFeed.id,
                              publishDelayInSeconds:
                                cell.original.publishDelayInSeconds,
                              duplicateHandling:
                                cell.original.duplicateHandling,
                              queryResultsIndexRange:
                                cell.original.queryResultsIndexRange,
                              startDateTime: new Date(
                                cell.original.startDateTime
                              ),
                              useRecurrenceSettings:
                                cell.original.useRecurrenceSettings,
                              useProductionRenderBots:
                                cell.original.useProductionRenderBots,
                              cronSyntax: cell.original.cronSyntax,
                              endDatetime: new Date(cell.original.endDateTime),
                              isRenderSchedulerOpen: true,
                              isDebug:
                                cell.original.isDebug &&
                                !cell.original.debugAllTriggers,
                              isQaAllTriggers: cell.original.debugAllTriggers,
                              contractDeliverableId:
                                cell.original.contractDeliverableId
                            })
                          }}
                        >
                          <Edit />
                        </Button>
                      ) : (
                        <Button
                          justIcon
                          round
                          simple
                          color="primary"
                          className="info"
                          title="View"
                          onClick={() => {
                            this.setState({
                              narrativeRenderScheduleId: cell.original.id,
                              narrative: cell.original.narrative,
                              contentTypeId: cell.original.contentType_Id,
                              contentType: cell.original.contentType,
                              contentId: cell.original.content_Id,
                              outputFeedId: cell.original.deliveryFeed.id,
                              publishDelayInSeconds:
                                cell.original.publishDelayInSeconds,
                              duplicateHandling:
                                cell.original.duplicateHandling,
                              queryResultsIndexRange:
                                cell.original.queryResultsIndexRange,
                              startDateTime: new Date(
                                cell.original.startDateTime
                              ),
                              useRecurrenceSettings:
                                cell.original.useRecurrenceSettings,
                              useProductionRenderBots:
                                cell.original.useProductionRenderBots,
                              cronSyntax: cell.original.cronSyntax,
                              endDatetime: new Date(cell.original.endDateTime),
                              isRenderSchedulerOpen: true,
                              isDebug:
                                cell.original.isDebug &&
                                !cell.original.debugAllTriggers,
                              isQaAllTriggers: cell.original.debugAllTriggers,
                              ignoreKillSwitch: cell.original.ignoreKillSwitch
                            })
                          }}
                        >
                          <Visibility />
                        </Button>
                      )}
                      {!cell.original.isArchived &&
                        !cell.original.isPaused &&
                        this.props.rowIdBeingLoaded !== cell.original.id && (
                          <Button
                            justIcon
                            round
                            simple
                            title="Pause"
                            color="primary"
                            className="info"
                            id="pausejob"
                            onClick={() => this.pauseJob(cell.original.id)}
                          >
                            <PauseCircleFilled />
                          </Button>
                        )}
                      {!cell.original.isArchived &&
                        cell.original.isPaused &&
                        this.props.rowIdBeingLoaded !== cell.original.id && (
                          <Button
                            justIcon
                            round
                            simple
                            title="Unpause"
                            color="primary"
                            className="info"
                            id="unpausejob"
                            onClick={() => this.unPauseJob(cell.original.id)}
                          >
                            <PlayCircleOutline />
                          </Button>
                        )}

                      {!cell.original.isArchived &&
                        this.props.rowIdBeingLoaded !== cell.original.id && (
                          <Button
                            justIcon
                            round
                            simple
                            title="Run ad-hoc"
                            color="primary"
                            className="info"
                            id="archivejob"
                            onClick={() => {
                              this.askIfYouWantToRender(cell)
                              this.loadFeeds(
                                cell.original.narrative.organization.id,
                                cell.original.deliveryFeed.id
                              )
                            }}
                          >
                            <RepeatOneIcon />
                          </Button>
                        )}
                      {!cell.original.isArchived &&
                        this.props.rowIdBeingLoaded !== cell.original.id && (
                          <Button
                            justIcon
                            round
                            simple
                            title="Archive"
                            color="primary"
                            className="info"
                            id="archivejob"
                            onClick={() =>
                              this.displayArchiveConfirmation(cell.original.id)
                            }
                          >
                            <Delete />
                          </Button>
                        )}

                      <Button
                        justIcon
                        round
                        simple
                        title="Change History"
                        color="primary"
                        className="info"
                        id="pausejob"
                        onClick={() => {
                          this.loadScheduleChangeLogs(cell.original.id)
                          this.setState({
                            narrativeRenderScheduleId: cell.original.id,
                            isChangeHistoryModalOpen: true
                          })
                        }}
                      >
                        <History />
                      </Button>
                      {this.props.rowIdBeingLoaded === cell.original.id && (
                        <CircularProgress size={20} />
                      )}
                    </div>
                  ),
                  sortable: false,
                  filterable: false,
                  width: 225
                },
                {
                  Header: "Last Occurrence",
                  accessor: "mostRecentJobStartDateTime",
                  ...this.dateFilterProps(
                    "mostRecentJobStartDateTime",
                    scheduledJobs
                  ),
                  width: 175
                },
                {
                  Header: "Next Occurrence",
                  accessor: "nextOccurrence",
                  ...this.dateFilterProps("nextOccurrence", scheduledJobs),
                  width: 200
                },
                {
                  Header: "In Process?",
                  accessor: "isInProcess",
                  Cell: cell =>
                    cell.value ? (
                      <span>
                        Yes <CircularProgress size={20} />
                      </span>
                    ) : (
                      <span>No</span>
                    ),
                  ...this.boolFilterProps("isInProcess", scheduledJobs),
                  width: 130
                },
                {
                  Header: "Is Paused?",
                  accessor: "isPaused",
                  Cell: cell =>
                    cell.value ? <span>Yes </span> : <span>No</span>,
                  ...this.boolFilterProps("isPaused", scheduledJobs),
                  width: 130
                },
                {
                  Header: "Narrative",
                  accessor: "narrative.name",
                  ...this.idedStringFilterProps(
                    ["narrative.id", "narrative.name"],
                    scheduledJobs
                  ),
                  Cell: cell => (
                    <Link
                      to={{
                        pathname: `/portal/narrative/${cell.original.narrative.id}`
                      }}
                      title={`Edit narrative (${cell.original.narrative.id})`}
                    >
                      <Button color="primary" justIcon round simple>
                        <LibraryBooks />
                      </Button>
                      {cell.original.narrative.name}
                    </Link>
                  ),
                  width: 400
                },
                {
                  Header: "Feed",
                  accessor: "deliveryFeed.name",
                  ...this.stringFilterProps("deliveryFeed.name", scheduledJobs),
                  Cell: cell => (
                    <Link
                      to={{
                        pathname: `/portal/feed-entries/${cell.original.deliveryFeed.id}`
                      }}
                      title={`View feed (${cell.original.deliveryFeed.id}) entries`}
                    >
                      <Button color="primary" justIcon round simple>
                        <RssFeed />
                      </Button>
                      {cell.original.deliveryFeed.name}
                    </Link>
                  ),
                  width: 300
                },
                {
                  Header: "Webhook ",
                  accessor: "webhook.description",
                  width: 225,
                  Cell: cell =>
                    cell.original.webhook ? (
                      <Link
                        to={{
                          pathname: `/portal/webhookmanager/${cell.original.webhook.id}`
                        }}
                        title={`View webhook (${cell.original.webhook.description})`}
                      >
                        <Button color="primary" justIcon round simple>
                          <WebhookIcon color="#4d9ab5" />
                        </Button>
                        {cell.original.webhook.description}
                      </Link>
                    ) : (
                      "NA"
                    )
                },
                {
                  Header: "Contract?",
                  accessor: "contractDeliverableId",
                  Cell: cell =>
                    parseInt(cell.original.contractDeliverableId, 10) > 0 ? (
                      <span>Yes</span>
                    ) : (
                      <span>No</span>
                    ),
                  ...this.truthyIntFilterProps("contractDeliverableId"),
                  width: 100
                },
                {
                  Header: "Publish Delay",
                  accessor: "publishDelayInSeconds",
                  Cell: cell =>
                    Helpers.loadPublishDelayOptions().find(
                      x => x.key === cell.value
                    ).value,
                  filterable: false,
                  width: 150
                },
                {
                  Header: "Start",
                  accessor: "startDateTime",
                  ...this.dateFilterProps("startDateTime"),
                  width: 150
                },
                {
                  Header: "Repeat?",
                  accessor: "useRecurrenceSettings",
                  Cell: cell =>
                    cell.value ? <span>Yes</span> : <span>No</span>,
                  filterAll: false,
                  ...this.boolFilterProps(
                    "useRecurrenceSettings",
                    scheduledJobs
                  ),
                  width: 100
                },
                {
                  Header: "cron syntax",
                  accessor: "cronSyntax",
                  ...this.stringFilterProps("cronSyntax", scheduledJobs),
                  Cell: cell => (
                    <span
                      onMouseEnter={e => {
                        this.setState(
                          {
                            cronSyntax: cell.value,
                            startDateTime: new Date(
                              cell.original.startDateTime
                            ),
                            endDateTime: new Date(cell.original.endDateTime),
                            useRecurrenceSettings:
                              cell.original.useRecurrenceSettings,
                            cronSyntaxMouseX: e.clientX - 270,
                            cronSyntaxMouseY: e.clientY - 10,
                            isDisplayFutureOccurrences: true
                          },
                          this.calculateFutureOccurrences()
                        )
                      }}
                      onMouseLeave={() =>
                        this.setState({ isDisplayFutureOccurrences: false })
                      }
                    >
                      {cell.value}
                    </span>
                  ),
                  width: 125
                },
                {
                  Header: "End",
                  accessor: "endDateTime",
                  filterAll: false,
                  ...this.dateFilterProps("endDateTime"),
                  width: 175
                },
                {
                  Header: "Content",
                  accessor: "contentType",
                  ...this.stringFilterProps("contentType", scheduledJobs),
                  width: 120
                },
                {
                  Header: "Id",
                  accessor: "content_Id",
                  ...this.stringFilterProps("content_Id", scheduledJobs),
                  width: 100
                },
                {
                  Header: "Query Results Index Range",
                  accessor: "queryResultsIndexRange",
                  ...this.stringFilterProps(
                    "queryResultsIndexRange",
                    scheduledJobs
                  ),
                  width: 200
                },
                {
                  Header: "Dupes",
                  accessor: "duplicateHandlingType",
                  ...this.stringFilterProps(
                    "duplicateHandlingType",
                    scheduledJobs
                  )
                },
                {
                  Header: "Is Debug?",
                  accessor: "isDebug",
                  Cell: cell =>
                    cell.value ? <span>Yes</span> : <span>No</span>,
                  ...this.boolFilterProps("isDebug"),
                  width: 120
                },
                {
                  Header: "Hide from feed?",
                  accessor: "hideFromFeed",
                  Cell: cell =>
                    cell.value ? <span>Yes</span> : <span>No</span>,
                  ...this.boolFilterProps("hideFromFeed"),
                  width: 120
                },
                {
                  Header: "Is Archived?",
                  accessor: "isArchived",
                  sortable: true,
                  filterable: true,
                  Cell: cell =>
                    cell.value ? <span>Yes</span> : <span>No</span>,
                  ...this.boolFilterProps("isArchived"),
                  width: 120,
                  show: this.state.doDisplayArchivedJobs
                },
                {
                  Header: "Audit Info",
                  accessor: "auditInfo.modifiedOn",
                  Cell: cell => Helpers.renderAuditInfoCell(cell),
                  width: 250
                }
              ]}
              filterable={true}
              pageSize={
                showPagination ? this.state.pageSize : scheduledJobs.length
              }
              onPageSizeChange={pageSize => {
                this.rememberPageSizeChanges(pageSize)
              }}
              showPaginationTop={false}
              showPaginationBottom={showPagination}
              className="-striped -highlight -scrollEntries"
              defaultSorted={
                this.state.orderByScheduleId
                  ? [
                      {
                        id: "id",
                        desc: true
                      }
                    ]
                  : [
                      {
                        id: "mostRecentJobStartDateTime",
                        desc: true
                      }
                    ]
              }
              style={{
                background: "rgba(0,0,0,0.01)",
                padding: "0 10px",
                maxWidth: "100%"
              }}
              expanded={this.state.expandedScheduleRows}
              getTdProps={(state, row, col) => ({
                onClick: (event, handleOriginal) => {
                  if (col.expander) {
                    this.retrieveJobLogCount(row.original.id)
                    this.retrieveJobLogs(row.original.id)
                    if (this.state.selectedScheduleId === row.original.id) {
                      this.setState({ selectedScheduleId: -1 })
                      this.setState({
                        expandedScheduleRows: { [row.viewIndex]: false }
                      })
                    } else {
                      this.setState({ selectedScheduleId: row.original.id })
                      this.setState({
                        expandedScheduleRows: { [row.viewIndex]: true }
                      })
                    }
                  }
                  handleOriginal()
                }
              })}
              getTrProps={(state, rowInfo) => {
                if (rowInfo) {
                  let returnObject = {
                    onClick: () => {},
                    style: {}
                  }
                  if (rowInfo.original.isArchived) {
                    returnObject.style.textDecoration = "line-through"
                  }
                  if (rowInfo.original.isInProcess) {
                    returnObject.style.background = "#ffd900"
                  }
                  if (rowInfo.original.isPaused) {
                    returnObject.style.background = "#ccc"
                  }
                  if (
                    rowInfo.original.isPaused &&
                    rowInfo.original.deliveryFeed.feedStatus === 3
                  ) {
                    // Warn on active jobs
                    returnObject.style.background = "rgb(255 0 0 / 29%)"
                    returnObject.style.color = "#000000"
                  }
                  if (new Date(rowInfo.original.startDateTime) > new Date()) {
                    returnObject.style.fontStyle = "italic"
                  }
                  return returnObject
                } else {
                  return {}
                }
              }}
              SubComponent={row => {
                if (this.state.selectedScheduleId !== row.original.id) {
                  return <div>Load this job's history to view details.</div>
                }
                const historyResults = this.state.jobLogs
                if (this.state.isLoadingJobs) {
                  return (
                    <div style={{ padding: "10px" }}>
                      <CircularProgress size={30} />
                    </div>
                  )
                }
                const historyPageSize =
                  historyResults.length > 10 ? 10 : historyResults.length
                return (
                  <div
                    style={{
                      border: "1px solid #efefef",
                      marginBottom: "20px",
                      padding: "20px",
                      background: "#ffffff",
                      maxWidth: "2000px"
                    }}
                  >
                    <h5 className={classes.renderJobInfo}>
                      Job #{row.original.id} has run {this.state.jobLogCount}{" "}
                      time(s)
                      <InfoDialog
                        style={{
                          overflow: "hidden",
                          touchAction: "pan-y"
                        }}
                        content={
                          <div>
                            <Typography variant="h6">
                              Render Job Descriptions
                            </Typography>
                            <List>
                              {this.renderJobDefinitions.map(
                                (definition, index) => (
                                  <ListItem key={index} disablePadding>
                                    <ListItemText>
                                      <Typography variant="body">
                                        {definition}
                                      </Typography>
                                    </ListItemText>
                                  </ListItem>
                                )
                              )}
                            </List>
                          </div>
                        }
                      />
                    </h5>
                    {historyResults && historyResults.length > 0 && (
                      <div
                        style={{
                          border: "1px solid #efefef",
                          padding: " 20px 0 0 0"
                        }}
                      >
                        <ReactTable
                          minRows={0}
                          data={historyResults}
                          noDataText={
                            this.state.isLoadingJobs ? (
                              <CircularProgress />
                            ) : (
                              "No job history found."
                            )
                          }
                          columns={[
                            {
                              Header: "Job Id",
                              accessor: "id",
                              sortable: true,
                              filterable: false,
                              width: 80
                            },
                            {
                              Header: "Actions",
                              sortable: false,
                              filterable: false,
                              width: 100,
                              Cell: cell => (
                                <div>
                                  {cell.original.isInProcess && (
                                    <div>
                                      <CircularProgress size={20} />

                                      <Button
                                        justIcon
                                        round
                                        simple
                                        title="Kill Job"
                                        color="primary"
                                        className="info"
                                        id="killjob"
                                        onClick={() => {
                                          if (cell.original.isToBeKilled) {
                                            alert(
                                              `Job #${cell.original.id} already flagged to be killed.`
                                            )
                                          } else {
                                            this.displayKillJobConfirmation(
                                              cell.original.id
                                            )
                                          }
                                        }}
                                      >
                                        <Clear />
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              )
                            },
                            {
                              Header: "Started",
                              accessor: "startedExecutionDateTime",
                              ...this.dateFilterProps(
                                "startedExecutionDateTime"
                              ),
                              width: 120
                            },
                            {
                              Header: "Ended",
                              accessor: "finishedExecutionDateTime",
                              filterAll: false,
                              ...this.dateFilterProps(
                                "finishedExecutionDateTime"
                              ),
                              Cell: cell =>
                                cell.value &&
                                cell.value !== "" && (
                                  <div>
                                    {cell.original.isToBeKilled && (
                                      <div style={{ color: "red" }}>
                                        Job flagged to be killed by{" "}
                                        {(cell.original.isToBeKilledByAccount &&
                                          cell.original.isToBeKilledByAccount
                                            .username) ||
                                          "service"}
                                        &nbsp;
                                        <TimeAgo
                                          date={cell.original.killedDateTime}
                                          title={Helpers.prettyDateTimeinPacificTimeZone(
                                            cell.original.isToBeKilledDateTime
                                          )}
                                        />
                                      </div>
                                    )}
                                    {cell.original.killedDateTime != null && (
                                      <div style={{ color: "red" }}>
                                        Killed&nbsp;
                                        <TimeAgo
                                          date={cell.original.killedDateTime}
                                          title={Helpers.prettyDateTimeinPacificTimeZone(
                                            cell.original.killedDateTime
                                          )}
                                        />
                                      </div>
                                    )}
                                    {!cell.original.isToBeKilled && (
                                      <TimeAgo
                                        date={cell.value}
                                        title={Helpers.prettyDateTimeinPacificTimeZone(
                                          cell.value
                                        )}
                                      />
                                    )}
                                  </div>
                                ),
                              width: 120
                            },
                            {
                              Header: "Duration",
                              Cell: cell => {
                                let start = moment(
                                  cell.original.startedExecutionDateTime
                                )
                                let end = moment(
                                  cell.original.finishedExecutionDateTime
                                )
                                if (end) {
                                  return (
                                    <div>
                                      {moment
                                        .duration(end.diff(start))
                                        .humanize()}
                                    </div>
                                  )
                                } else {
                                  return <div>In Progress...</div>
                                }
                              },
                              width: 100,
                              filterable: false,
                              sortable: false
                            },
                            {
                              Header: "Possible",
                              accessor: "possibleCount",
                              sortable: true,
                              Cell: cell => (
                                <div>
                                  {cell.original.possibleCount > 0 ? (
                                    <span
                                      style={{
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {cell.original.possibleCount}
                                    </span>
                                  ) : (
                                    cell.original.possibleCount
                                  )}
                                </div>
                              ),
                              ...this.intFilterProps(
                                "possibleCount",
                                historyResults
                              ),
                              width: 100
                            },
                            {
                              Header: "Created",
                              accessor: "createdCount",
                              sortable: true,
                              width: 125,
                              Cell: cell => (
                                <div>
                                  {cell.original.createdCount > 0 ? (
                                    <span
                                      style={{
                                        color: "green",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {cell.original.createdCount}
                                    </span>
                                  ) : (
                                    cell.original.createdCount
                                  )}
                                </div>
                              ),
                              ...this.intFilterProps(
                                "createdCount",
                                historyResults
                              )
                            },
                            {
                              Header: "Updated",
                              accessor: "updatedCount",
                              sortable: true,
                              width: 125,
                              Cell: cell => (
                                <div>
                                  {cell.original.updatedCount > 0 ? (
                                    <span
                                      style={{
                                        color: "green",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {cell.original.updatedCount}
                                    </span>
                                  ) : (
                                    cell.original.updatedCount
                                  )}
                                </div>
                              ),
                              ...this.intFilterProps(
                                "updatedCount",
                                historyResults
                              )
                            },
                            {
                              Header: "Skipped",
                              accessor: "skippedCount",
                              sortable: true,
                              width: 125,
                              Cell: cell => (
                                <div>
                                  {cell.original.skippedCount > 0 ? (
                                    <span
                                      style={{
                                        color: "orange",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {cell.original.skippedCount}
                                    </span>
                                  ) : (
                                    cell.original.skippedCount
                                  )}
                                </div>
                              ),
                              ...this.intFilterProps(
                                "skippedCount",
                                historyResults
                              )
                            },
                            {
                              Header: "Published",
                              accessor: "publishedCount",
                              sortable: true,
                              width: 125,
                              Cell: cell => (
                                <div>
                                  {cell.original.publishedCount > 0 ? (
                                    <span
                                      style={{
                                        color: "green",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {cell.original.publishedCount}
                                    </span>
                                  ) : (
                                    cell.original.publishedCount
                                  )}
                                </div>
                              ),
                              ...this.intFilterProps(
                                "publishedCount",
                                historyResults
                              )
                            },
                            {
                              Header: "Flagged",
                              accessor: "flaggedCount",
                              sortable: true,
                              width: 125,
                              Cell: cell => (
                                <div>
                                  {cell.original.flaggedCount > 0 ? (
                                    <span
                                      style={{
                                        color: "red",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {cell.original.flaggedCount}
                                    </span>
                                  ) : (
                                    cell.original.flaggedCount
                                  )}
                                </div>
                              ),
                              ...this.intFilterProps(
                                "flaggedCount",
                                historyResults
                              )
                            },
                            {
                              Header: "Killswitched",
                              accessor: "killswitchedCount",
                              sortable: true,
                              width: 120,
                              Cell: cell => (
                                <div>
                                  {cell.original.killswitchedCount > 0 ? (
                                    <span
                                      style={{
                                        color: "red",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {cell.original.killswitchedCount}
                                    </span>
                                  ) : (
                                    cell.original.killswitchedCount
                                  )}
                                </div>
                              ),
                              ...this.intFilterProps(
                                "killswitchedCount",
                                historyResults
                              )
                            }
                          ]}
                          expanded={this.state.expandedJobRows}
                          getTdProps={(state, row, col) => ({
                            onClick: (event, handleOriginal) => {
                              // This is the only way to identify
                              // the specific row's primary id when the "expander" icon
                              // is clicked
                              if (col.expander) {
                                this.retrieveJobOutputLog(row.original.id)
                                if (
                                  this.state.selectedJobId === row.original.id
                                ) {
                                  // when the expander is "collapsed", unselected it
                                  this.setState({ selectedJobId: -1 })
                                  this.setState({
                                    expandedJobRows: {
                                      [row.viewIndex]: false
                                    }
                                  })
                                } else {
                                  // when the expander is "expanded", means that it is selected
                                  this.setState({
                                    selectedJobId: row.original.id
                                  })
                                  this.setState({
                                    expandedJobRows: { [row.viewIndex]: true }
                                  })
                                }
                              }
                              handleOriginal()
                            }
                          })}
                          filterable={true}
                          defaultPageSize={historyPageSize}
                          showPaginationBottom={
                            historyResults.length > historyPageSize
                          }
                          className="-striped -highlight"
                          style={{
                            border: "1px solid rgba(0,0,0,0.05)",
                            background: "rgba(0,0,0,0.01)",
                            padding: "5px"
                          }}
                          defaultSorted={[
                            {
                              id: "startedExecutionDateTime",
                              desc: true
                            }
                          ]}
                          SubComponent={() => {
                            let results = this.state.jobOutputLogs
                            const resultsPageSize =
                              results.length > 10 ? 10 : results.length
                            let createdCount = 0
                            if (results && results.length > 0) {
                              let created = results.filter(
                                r => r.feedEntryId > 0
                              )
                              createdCount = created.length
                            }
                            return (
                              <div
                                style={{
                                  border: "1px solid #efefef",
                                  marginBottom: "20px",
                                  padding: "20px",
                                  background: "#ffffff"
                                }}
                              >
                                <h5>
                                  Job resulted in {results.length} rendered,{" "}
                                  {createdCount} created.
                                </h5>
                                {results && results.length > 0 && (
                                  <div
                                    style={{
                                      border: "1px solid #efefef",
                                      padding: " 20px 0 0 0"
                                    }}
                                  >
                                    <ReactTable
                                      minRows={0}
                                      data={results}
                                      noDataText={
                                        this.state.isLoading ? (
                                          <CircularProgress />
                                        ) : (
                                          "No results found."
                                        )
                                      }
                                      columns={[
                                        {
                                          Header: "Log Id",
                                          accessor: "id",
                                          sortable: false,
                                          filterable: false,
                                          width: 100
                                        },
                                        {
                                          Header: "Result",
                                          accessor: "logMessage",
                                          Cell: cell => <div>{cell.value}</div>,
                                          sortable: false,
                                          filterable: false,
                                          width: 300
                                        },
                                        {
                                          Header: "Feed Entry Id",
                                          accessor: "feedEntryId",
                                          Cell: cell => (
                                            <Link
                                              to={{
                                                pathname: `/portal/feed-entries/entry/${cell.value}`
                                              }}
                                              title="View feed entry details"
                                            >
                                              {cell.value}
                                            </Link>
                                          ),
                                          sortable: false,
                                          filterable: false,
                                          width: 150
                                        },
                                        {
                                          Header: "Content",
                                          accessor: "contentType",
                                          ...this.stringFilterProps(
                                            "contentType",
                                            results
                                          ),
                                          width: 200
                                        },
                                        {
                                          Header: "Id",
                                          accessor: "content_Id",
                                          ...this.stringFilterProps(
                                            "content_Id",
                                            results
                                          ),
                                          width: 150
                                        },
                                        {
                                          Header: "Processed",
                                          accessor:
                                            "auditInfoResponse.createdOn",
                                          Cell: cell => (
                                            <div>
                                              <TimeAgo
                                                date={cell.value}
                                                title={Helpers.prettyDateTimeinPacificTimeZone(
                                                  cell.value
                                                )}
                                              />
                                            </div>
                                          ),
                                          width: 250
                                        }
                                      ]}
                                      style={{
                                        border: "1px solid rgba(0,0,0,0.05)",
                                        background: "rgba(0,0,0,0.01)",
                                        padding: "5px"
                                      }}
                                      filterable={true}
                                      defaultPageSize={resultsPageSize}
                                      showPaginationTop={
                                        !isModal &&
                                        results.length > resultsPageSize
                                      }
                                      showPaginationBottom={isModal || false}
                                      className="-striped -highlight"
                                      defaultSorted={[
                                        {
                                          id: "auditInfoResponse.createdOn",
                                          desc: true
                                        }
                                      ]}
                                    >
                                      {(state, makeTable) => {
                                        let recordsInfoText = ""
                                        const {
                                          filtered,
                                          pageRows,
                                          pageSize,
                                          sortedData,
                                          page
                                        } = state
                                        if (
                                          sortedData &&
                                          sortedData.length > 0
                                        ) {
                                          let isFiltered = filtered.length > 0
                                          let totalRecords = sortedData.length
                                          let recordsCountFrom =
                                            page * pageSize + 1
                                          let recordsCountTo =
                                            recordsCountFrom +
                                            pageRows.length -
                                            1
                                          if (isFiltered) {
                                            recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered results`
                                          } else {
                                            recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords} results`
                                          }
                                        } else {
                                          recordsInfoText = this.state.isLoading
                                            ? "Loading render log, please wait..."
                                            : "Nothing has been rendered from this schedule"
                                        }
                                        return (
                                          <div className="main-grid">
                                            <div className="above-table">
                                              <div className="col-sm-12">
                                                <span className="records-info">
                                                  {recordsInfoText}
                                                </span>
                                              </div>
                                            </div>
                                            {makeTable()}
                                          </div>
                                        )
                                      }}
                                    </ReactTable>
                                  </div>
                                )}
                              </div>
                            )
                          }}
                        >
                          {(state, makeTable) => {
                            let recordsInfoText = ""
                            const {
                              filtered,
                              pageRows,
                              pageSize,
                              sortedData,
                              page
                            } = state

                            if (sortedData && sortedData.length > 0) {
                              let isFiltered = filtered.length > 0
                              let totalRecords = sortedData.length
                              let recordsCountFrom = page * pageSize + 1
                              let recordsCountTo =
                                recordsCountFrom + pageRows.length - 1
                              if (isFiltered) {
                                recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered job runs`
                              } else {
                                recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords} job runs`
                              }
                            } else {
                              recordsInfoText = this.state.isLoading
                                ? "Loading completed runs, please wait..."
                                : "This Job Has Not Run"
                            }
                            return (
                              <div className="main-grid">
                                <div className="above-table">
                                  <div className="col-sm-12">
                                    <span className="records-info">
                                      {recordsInfoText}
                                    </span>
                                  </div>
                                </div>
                                {makeTable()}
                              </div>
                            )
                          }}
                        </ReactTable>
                      </div>
                    )}
                  </div>
                )
              }}
            >
              {(state, makeTable) => {
                let recordsInfoText = ""

                const { filtered, pageRows, pageSize, sortedData, page } = state

                if (sortedData && sortedData.length > 0) {
                  let isFiltered = filtered.length > 0
                  let totalRecords = sortedData.length
                  let recordsCountFrom = page * pageSize + 1
                  let recordsCountTo = recordsCountFrom + pageRows.length - 1
                  if (isFiltered) {
                    recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered jobs`
                  } else {
                    recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords} jobs`
                  }
                } else {
                  recordsInfoText = this.props.isLoading
                    ? "Loading schedules, please wait..."
                    : "No jobs found"
                }
                return (
                  <div className="main-grid">
                    <div className="above-table text-right">
                      <div className="col-sm-12">
                        <span className="records-info">{recordsInfoText}</span>
                        <Button
                          justIcon
                          round
                          simple
                          color="primary"
                          className="info"
                          id="reload"
                          title="Reload"
                          onClick={() => {
                            this.props.loadScheduleCallback(
                              null,
                              this.props.organizationId,
                              this.state.doDisplayArchivedJobs
                            )
                          }}
                        >
                          {this.props.isLoading ? (
                            <CircularProgress thickness={1} size={30} />
                          ) : (
                            <Loop />
                          )}
                        </Button>{" "}
                        <br />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                this.state.doDisplayArchivedJobs || false
                              }
                              onChange={event => {
                                if (isModal || !!this.props.narrativeId) {
                                  // If we are showing the modal schedule (modal in NarrativeEditor once you select a narrative) OR
                                  // a narrative-specific schedule (clicking the narrative's schedule icon on the "Manage Narratives" page)
                                  // we don't need to reload the archived values,
                                  //they have been already beenloaded in a narrative-specific render schedule upload
                                  this.setState({
                                    doDisplayArchivedJobs: event.target.checked
                                  })
                                } else {
                                  //We only need to reload in the Organization-wide render schedule page under the Feed Manager (/portal/render-schedule)
                                  if (hasArchives.length > 0) {
                                    //AND if we have not already loaded ALL of them including the archived ones
                                    //(in which case we only need to filter)
                                    this.setState({
                                      doDisplayArchivedJobs:
                                        event.target.checked
                                    })
                                  } else {
                                    //If we have never loaded the archived entries, please do
                                    this.setState(
                                      {
                                        doDisplayArchivedJobs:
                                          event.target.checked
                                      },
                                      this.props.loadScheduleCallback(
                                        null,
                                        this.props.organizationId ||
                                          this.state.organizationId,
                                        !this.state.doDisplayArchivedJobs
                                      )
                                    )
                                  }
                                }
                              }}
                            />
                          }
                          label="Include Archived?"
                        />
                      </div>
                    </div>
                    {makeTable()}
                  </div>
                )
              }}
            </ReactTable>
          )}
          {!this.props.isLoading &&
            scheduledJobs.length === 0 &&
            !isPreviousNarrativeSchedule && (
              <div
                style={{
                  textAlign: "center",
                  padding: "20px",
                  margin: "30px",
                  border: "1px solid black",
                  borderRadius: "5px"
                }}
              >
                <h1>No Jobs found</h1>
              </div>
            )}
          {this.state.isDisplayFutureOccurrences && (
            <Card
              style={{
                position: "absolute",
                zIndex: "1000",
                left: this.state.cronSyntaxMouseX,
                top: this.state.cronSyntaxMouseY,
                width: "500px",
                height: "300px"
              }}
            >
              <CardBody>
                <GridContainer>
                  <GridItem md={12}>
                    <h5>
                      Future Occurrence(s) :{" "}
                      <span style={{ fontSize: "10px", color: "grey" }}>
                        (Up to {this.state.maxFutureOccurrenceCount} displayed.)
                      </span>{" "}
                    </h5>
                    <div style={{ height: "280px", overflow: "auto" }}>
                      {this.state.futureOccurrences &&
                        this.state.futureOccurrences.length < 1 &&
                        "None, based on the criteria you defined."}
                      <ol>
                        {this.state.futureOccurrences &&
                          this.state.futureOccurrences.map(val => (
                            <li
                              style={{
                                textDecoration:
                                  val < new Date() ? "line-through" : "none"
                              }}
                            >
                              {Helpers.prettyDateTimeinPacificTimeZone(val)} (
                              <TimeAgo
                                date={val}
                                title={Helpers.prettyDateTimeinPacificTimeZone(
                                  val
                                )}
                              />
                              )
                            </li>
                          ))}
                      </ol>
                      {this.state.futureOccurrencesMessage &&
                        this.state.futureOccurrencesMessage}
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          )}
          {/* Change history modal begin */}
          <Modal
            open={this.state.isChangeHistoryModalOpen}
            onClose={() => this.setState({ isChangeHistoryModalOpen: false })}
          >
            <Card className={classes.scheduleOutputModal}>
              <CardHeader>
                <h4 className={classes.cardIconTitle}>
                  Change History for Schedule #{" "}
                  {this.state.narrativeRenderScheduleId}
                </h4>
              </CardHeader>
              <CardBody>
                <ChangeLog
                  handleRollBack={false}
                  changeLogs={this.state.scheduleChangeLogs}
                />
              </CardBody>
            </Card>
          </Modal>
          {/* Change history modal end */}
          {/* Editor begin */}
          <Modal
            open={this.state.isRenderSchedulerOpen}
            onClose={this.toggleGenFeedModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <NarrativeRenderScheduler
              open={this.state.isRenderSchedulerOpen}
              isMyRenderSchedulerOpen={this.state.isRenderSchedulerOpen}
              setAlertMessageOpen={itm => {
                this.setState({
                  isAlertMessageOpen: itm.isOpen,
                  alertMessage: itm.msg
                })
              }}
              contentId={this.state.contentId}
              contentType={this.state.contentType}
              feeds={this.state.feeds}
              narrative={this.state.narrative}
              hideShareDialog={this.hideShareDialog}
              narrativeLoaded={true}
              classes={classes}
              outputFeedId={this.state.outputFeedId}
              contractDeliverableId={contractDeliverableId}
              duplicateHandling={this.state.duplicateHandling}
              publishDelayInSeconds={this.state.publishDelayInSeconds}
              webhookId={webhookId}
              hideFromFeed={hideFromFeed}
              cronSyntax={this.state.cronSyntax}
              endDateTime={this.state.endDateTime}
              startDateTime={this.state.startDateTime}
              useRecurrenceSettings={this.state.useRecurrenceSettings}
              useProductionRenderBots={this.state.useProductionRenderBots}
              setRenderSchedulerOpen={val => {
                this.setState({
                  isRenderSchedulerOpen: val
                })
              }}
              reloadNarrativeRenderSchedules={() => {
                this.props.loadScheduleCallback(
                  null,
                  this.props.organizationId,
                  this.state.doDisplayArchivedJobs
                )
              }}
              narrativeRenderScheduleId={this.state.narrativeRenderScheduleId}
              isOnRenderSchedulesPage={true}
            />
          </Modal>
          <Modal
            open={this.state.showResultsModal}
            onClose={() => {
              this.setState({
                resultTableData: {},
                displayProperties: {},
                showAskToRenderModal: false,
                showResultsModal: false,
                orderByScheduleId: true
              })
              this.props.loadScheduleCallback(
                null,
                this.props.organizationId,
                this.state.doDisplayArchivedJobs
              )
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className={classes.resultsModal}>
              <h2>You succesfully saved the following entries:</h2>
              <div>
                <TableControlsNoToolbar
                  columns={[
                    {
                      Header: "Schedule ID",
                      accessor: "nrsId"
                    }
                  ].concat(columns)}
                  hideColumns={[]}
                  data={[this.state.resultTableData]}
                  readOnly={true}
                />
              </div>
            </div>
          </Modal>
          <Modal
            open={this.state.showAskToRenderModal}
            onClose={() => {
              this.setState({
                resultTableData: {},
                showAskToRenderModal: false,
                showResultsModal: false
              })
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <AdHocRenderScheduler
              feeds={this.state.feeds}
              resultTableData={this.state.resultTableData}
              narrative={this.state.narrative}
              organizationId={this.props.organizationId}
              displayProperties={this.state.displayProperties}
              classes={classes}
              clearResultsModal={() => {
                this.setState({
                  resultTableData: {},
                  showAskToRenderModal: false,
                  showResultsModal: false
                })
              }}
            />
          </Modal>
          {/* Editor end */}
          <div xs={3} sm={3} md={3}>
            <div style={{ textDecoration: "line-through" }}>
              line-through = Archived
            </div>
            <div style={{ fontStyle: "italic" }}>
              italic = future start date/time
            </div>
            <div style={{ backgroundColor: "#ffd900" }}>
              yellow = `In Process` status
            </div>
            <div style={{ backgroundColor: "#ccc" }}>
              gray = `Paused` status
            </div>
            <div
              style={{
                backgroundColor: "rgb(255 0 0 / 29%)",
                color: "#000000"
              }}
            >
              red - Indicates Paused Jobs for <strong>Active</strong> Feeds
            </div>
          </div>
        </CardBody>
      </Card>
    )
  }
}

NarrativeRenderSchedules.defaultProps = defaultProps

export default withStyles(styles)(
  withRouter(useStore(NarrativeRenderSchedules))
)
