import { Cookies } from "tools/storage"
let cookies = new Cookies()

let narrativeId = ""

export const checkEntryModeOperations = (editEntryMode, columnId, type) => {
  const pathAndColumn = getNarrativeViewKey(columnId)
  if (type === "match") {
    let match = false
    editEntryMode.forEach(itm => {
      itm = itm.split("|")[0]
      if (itm === pathAndColumn) {
        match = true
      }
    })
    return match
  } else if (type === "add") {
    const tmp = []
    editEntryMode.forEach(itm => {
      let itmX = itm.split("|")[0]
      if (itmX !== pathAndColumn) {
        tmp.push(itm)
      }
    })
    tmp.push(pathAndColumn)
    cookies.set("editEntryMode", tmp)
  } else if (type === "remove") {
    const tmp = []
    editEntryMode.forEach(itm => {
      let itmX = itm.split("|")[0]
      if (itmX !== pathAndColumn) {
        tmp.push(itm)
      }
    })
    cookies.set("editEntryMode", tmp)
  } else if (type === "matchTable") {
    let match = false
    editEntryMode.forEach(itm => {
      itm = itm.split("|")[0]
      if (itm.indexOf(pathAndColumn) !== -1) {
        match = true
      }
    })
    return match
  } else if (type === "getOldFilter") {
    let match = ""
    editEntryMode.forEach(itm => {
      itm = itm.split("|")
      let filter = itm[1] ? itm[1] : []
      itm = itm[0]
      if (itm.indexOf(pathAndColumn) !== -1) {
        match = `(${filter})`
      }
    })
    return match
  } else if (type === "getOldFilterVal") {
    let match = ""
    editEntryMode.forEach(itm => {
      itm = itm.split("|")
      let filter = itm[1] ? itm[1] : []
      itm = itm[0]
      if (itm.indexOf(pathAndColumn) !== -1) {
        match = filter
      }
    })
    return match
  }
}

let timeFiltersSet = {}

export const getFilterValues = (id, filterValue) => {
  // 14 July 21 - compares the input's stored value (filterValue) for specific filter (id)
  // to the current value stored in LocalStorage for that filter (same id on LocalStorage)
  let gridViewFilters = cookies.get("narrativeEditorGridViewFilters") //Put filter values stored in LocalStorage into an object
  let triggerFilter = false
  let match
  let currentTableType
  let narrativeAndView
  const myView = getCurrentView()
  let nIdx = window.location.pathname.match(/\/portal\/narrative\/(\d{1,6})\//)
  narrativeId = nIdx && nIdx[1] ? nIdx[1] : "_"
  narrativeAndView = `${narrativeId}:${myView}`
  id = id.replace(/^_{1,}/, "") //Put "_" in front of "id" value to not run into anything
  currentTableType = gridViewFilters["currentTableType"]

  if (
    gridViewFilters &&
    gridViewFilters[currentTableType] &&
    gridViewFilters[currentTableType][narrativeAndView] &&
    gridViewFilters[currentTableType][narrativeAndView][id]
  ) {
    //If this filter has a saved filter value - set earlier when the input "onChange" fires - proceed
    match = true
    if (!timeFiltersSet[id]) {
      triggerFilter = true
    } else if (timeFiltersSet[id].value !== filterValue) {
      triggerFilter = true
    }
    if (
      !filterValue &&
      !!gridViewFilters[currentTableType][narrativeAndView][id]
    ) {
      filterValue = gridViewFilters[currentTableType][narrativeAndView][id]
    } else {
      gridViewFilters[currentTableType][narrativeAndView][id] = filterValue
        ? filterValue
        : ""
    }
    if (!gridViewFilters[currentTableType][narrativeAndView][id]) {
      delete gridViewFilters[currentTableType][narrativeAndView][id]
    }
  } else {
    if (filterValue) {
      match = true
      gridViewFilters[currentTableType][narrativeAndView][id] = filterValue
        ? filterValue
        : ""
    }
  }

  cookies.set("narrativeEditorGridViewFilters", gridViewFilters) //14 July 2021
  if (match) {
    timeFiltersSet[id] = { time: new Date().getTime(), value: filterValue }
  }
  return { newVal: filterValue, useNewVal: triggerFilter }
}

export const setLocalStorageVals = (id, val) => {
  const myView = getCurrentView()
  let nIdx = window.location.pathname.match(/\/portal\/narrative\/(\d{1,6})\//)
  narrativeId = nIdx && nIdx[1] ? nIdx[1] : "_"
  let gridViewFilters = cookies.get("narrativeEditorGridViewFilters")
  let currentTableType
  let narrativeAndView = `${narrativeId}:${myView}`
  currentTableType = gridViewFilters["currentTableType"]
  if (currentTableType) {
    gridViewFilters[currentTableType] = gridViewFilters[currentTableType] || {}
    gridViewFilters[currentTableType][narrativeAndView] =
      gridViewFilters[currentTableType][narrativeAndView] || {}
    gridViewFilters[currentTableType][narrativeAndView][id] = val
    cookies.set("narrativeEditorGridViewFilters", gridViewFilters)
  }
}

const clearFilters = id => {
  const myView = getCurrentView()
  let gridViewFilters = cookies.get("narrativeEditorGridViewFilters")
  let currentTableType
  let narrativeAndView
  let nIdx = window.location.pathname.match(/\/portal\/narrative\/(\d{1,6})\//)
  narrativeId = nIdx && nIdx[1] ? nIdx[1] : "_"
  narrativeAndView = `${narrativeId}:${myView}`
  currentTableType = gridViewFilters["currentTableType"]
  id = id.replace(/^_{1,}/, "") //Put "__" in front of "id" value to not run into anything
  if (
    gridViewFilters[currentTableType] &&
    gridViewFilters[currentTableType][narrativeAndView] &&
    gridViewFilters[currentTableType][narrativeAndView][id]
  ) {
    delete gridViewFilters[currentTableType][narrativeAndView][id]
  }
  cookies.set("narrativeEditorGridViewFilters", gridViewFilters)
}

export const handleClearFiltersClick = e => {
  const mySRs = cookies.get("selectedGridViewRows") || {}
  const editEntryMode = cookies.get("editEntryMode") || []
  let myId = e.target.value
  myId = myId.replace(/^_{1,}/, "")
  const myView = getCurrentView()
  if (myView) {
    mySRs[`${narrativeId}:${myView}`] = []
    cookies.set("selectedGridViewRows", mySRs)
  } else {
    mySRs[`${narrativeId}:`] = []
    cookies.set("selectedGridViewRows", mySRs)
  }
  clearFilters(e.target.value) //Button for removing filter values you no longer want
  checkEntryModeOperations(editEntryMode, getNarrativeViewKey(myId), "remove")
}

const getFilterValue = id => {
  let gridViewFilters = cookies.get("narrativeEditorGridViewFilters")
  let currentTableType
  let narrativeAndView
  const myView = getCurrentView()
  let nIdx = window.location.pathname.match(/\/portal\/narrative\/(\d{1,6})\//)
  narrativeId = nIdx && nIdx[1] ? nIdx[1] : "_"
  narrativeAndView = `${narrativeId}:${myView}`
  currentTableType = gridViewFilters["currentTableType"]
  id = id.replace(/^_{1,}/, "") //Put "__" in front of "id" value to not run into anything
  if (
    gridViewFilters[currentTableType] &&
    gridViewFilters[currentTableType][narrativeAndView] &&
    gridViewFilters[currentTableType][narrativeAndView][id]
  ) {
    return gridViewFilters[currentTableType][narrativeAndView][id]
  }
}

export const handleEditEntriesClick = e => {
  const editEntryMode = cookies.get("editEntryMode") || []
  const mySRs = cookies.get("selectedGridViewRows") || {}
  let myId = e.target.value
  myId = myId.replace(/^_{1,}/, "")
  let nIdx = window.location.pathname.match(/\/portal\/narrative\/(\d{1,6})\//)
  narrativeId = nIdx && nIdx[1] ? nIdx[1] : "_"
  const myView = getCurrentView()

  if (
    checkEntryModeOperations(editEntryMode, getNarrativeViewKey(myId), "match")
  ) {
    clearFilters(e.target.value)
    if (myView) {
      mySRs[`${narrativeId}:${myView}`] = []
      cookies.set("selectedGridViewRows", mySRs)
    } else {
      mySRs[`${narrativeId}:`] = []
      cookies.set("selectedGridViewRows", mySRs)
    }
    checkEntryModeOperations(editEntryMode, getNarrativeViewKey(myId), "remove")
  } else {
    let origFilter = getFilterValue(e.target.value)
    checkEntryModeOperations(
      editEntryMode,
      `${narrativeId}:${myView}:${myId}|${origFilter}`,
      "add"
    )
  }
}

// Takes react-table 8 rows
export const getMinAndMaxInRows = (rows, columnId) => {
  const startingValue = rows.length ? rows[0].getValue(columnId) : 0
  let min = startingValue
  let max = startingValue
  rows.forEach(row => {
    min = Math.min(row.getValue(columnId), min)
    max = Math.max(row.getValue(columnId), max)
  })
  return [min, max]
}

export const StoreAndCheckForInLocalStorage = (
  filterValue,
  setFilter,
  preFilteredRows,
  id
) => {
  const editEntryMode = cookies.get("editEntryMode") || []
  let nIdx = window.location.pathname.match(/\/portal\/narrative\/(\d{1,6})\//)
  narrativeId = nIdx && nIdx[1] ? nIdx[1] : "_"
  const myView = getCurrentView()

  if (
    checkEntryModeOperations(
      editEntryMode,
      `${narrativeId}:${myView}:${id}`,
      "match"
    )
  ) {
    filterValue = ""
  } else {
    let valx = getFilterValues(id, filterValue, "filterLoad")
    // EGG - As we go through this specific filter(id), we check to see if we have a
    // value in LocalStorage for this. The return value contains a newValue AND if it should be used
    let val = valx.newVal //Maybe new filter value, depending on...
    if (valx.useNewVal) {
      //If it was determined we need to update it
      setFilter(val)
      filterValue = val
    }
    filterValue = filterValue ? filterValue : ""
  }
  return filterValue
}

const arraysMatchFirstInSecond = (arr1, arr2) => {
  let matchIndex = {}
  arr1.forEach(itm => {
    arr2.forEach(itm2 => {
      if (itm === itm2) {
        matchIndex[itm] = true
      }
    })
  })
  if (Object.keys(matchIndex).length === arr1.length) {
    return true
  } else {
    return false
  }
}

export const clearPersistentFilterAfterSave = selectedFlatRows_Filtered => {
  let mySRs = cookies.get("selectedGridViewRows") || {}
  let editEntryMode = cookies.get("editEntryMode") || []
  let rowIds = []
  let matchFilter = false
  selectedFlatRows_Filtered.forEach(itm => {
    if (itm.original && itm.original.id) {
      rowIds.push(itm.original.id)
    }
  })
  Object.keys(mySRs).forEach(function (key) {
    let match = arraysMatchFirstInSecond(rowIds, mySRs[key])
    if (match) {
      matchFilter = key
    }
  })
  if (matchFilter) {
    let thisFilter = mySRs[matchFilter].filter(itm => !rowIds.includes(itm))
    mySRs[matchFilter] = thisFilter
    cookies.set("selectedGridViewRows", mySRs)
    let tmpEntryMode = []
    if (thisFilter.length === 0) {
      editEntryMode.forEach(itm => {
        if (itm.indexOf(matchFilter) === -1) {
          tmpEntryMode.push(itm)
        }
      })
      cookies.set("editEntryMode", tmpEntryMode)
    }
  }
}

export function removeJustSavedRowFromFilter(row) {
  let gridViewRows = cookies.get("selectedGridViewRows")
  const myView = getCurrentView()
  let narrativeAndView = `${narrativeId}:${myView}`
  let thisFilter
  thisFilter = gridViewRows[narrativeAndView]
  if (thisFilter) {
    let index = thisFilter.findIndex(itm => itm === row.original.id)
    if (index > -1) {
      thisFilter.splice(index, 1)
      gridViewRows[narrativeAndView] = thisFilter
      cookies.set("selectedGridViewRows", gridViewRows)
    }
  }
}

export const areWeFiltering = () => {
  //See if we are filtering for this view
  let gridViewFilters = cookies.get("narrativeEditorGridViewFilters") || {}
  let match = false
  let currentTableType
  const myView = getCurrentView()
  let narrativeAndView = `${narrativeId}:${myView}`
  currentTableType = gridViewFilters["currentTableType"]
  let checkObj = gridViewFilters[currentTableType][narrativeAndView]
  if (checkObj) {
    Object.keys(checkObj).forEach(function (key) {
      if (checkObj[key]) {
        match = true
      }
    })
  }
  return match
}

export const getNarrativeIdFromPath = () => {
  let nIdx = window.location.pathname.match(/\/portal\/narrative\/(\d{1,6})\//)
  narrativeId = nIdx?.[1] || "_"
  return narrativeId
}

export const getCurrentView = () => {
  //Get the view information
  const qsParams = new URLSearchParams(window.location.search)
  let myView = ""
  for (const [key, value] of qsParams.entries()) {
    if (key.match(/\??view/)) {
      myView = value
    }
  }
  return myView
}

export const getNarrativeViewKey = id => {
  const narrativeId = getNarrativeIdFromPath()
  if (id.indexOf(narrativeId) === 0) {
    return id
  } else {
    const myView = getCurrentView()
    return `${narrativeId}:${myView}:${id}`
  }
}

export const weAreAddingNewContent = data => {
  //Checks to see if id=null, which means just created sentence/trigger/paragraph
  let match = 0
  data &&
    data.forEach(function (itm) {
      if (!itm.id) {
        match = match + 1
      }
    })
  return match
}

// Define a custom filter filter function!
export function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.getValue(id)
    return rowValue >= filterValue
  })
}

// This is an autoRemove method on the filter export function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== "number"
