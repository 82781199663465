import React from "react"
import withStyles from "@mui/styles/withStyles"
import Dialog from "@mui/material/Dialog"
import MuiDialogTitle from "@mui/material/DialogTitle"
import MuiDialogContent from "@mui/material/DialogContent"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import Typography from "@mui/material/Typography"
import Info from "@mui/icons-material/Info"
import Check from "@mui/icons-material/CheckCircleOutline"
import Warning from "@mui/icons-material/Warning"
import Report from "@mui/icons-material/Report"
import Dot from "@mui/icons-material/FiberManualRecord"
import { ListItem } from "@mui/material"
import { makeStyles } from "@mui/styles"

const styles = theme => ({
  root: {
    margin: 2,
    padding: theme.spacing(2),
    textAlign: "left",
    color: "#000"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
})

const useStyles = makeStyles({
  paragraphTitle: {
    fontSize: "1.25em",
    fontWeight: "bold",
    marginTop: ".5em"
  },
  paragraph: {
    marginLeft: "9px",
    fontWeight: 400,
    lineHeight: 1.75,
    marginBottom: "9px"
  },
  score: {
    fontWeight: 500
    //lineHeight: 0.75
  },
  dot: {
    fontSize: "10px",
    marginRight: "3px"
  },
  searchButton: {
    padding: 0
  }
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent)

export default function ContentSaturationModal() {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const classes = useStyles()

  return (
    <div>
      <IconButton
        className={classes.searchButton}
        onClick={handleClickOpen}
        size="large"
      >
        <Info style={{ color: "#4d9ab5", fontSize: "1.2rem" }} />
      </IconButton>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Content Variation Scoring:
          <Typography
            style={{
              marginLeft: "10px",
              textAlign: "justify",
              marginRight: "14px"
            }}
          >
            Below are descriptions for the content variation score for
            sentences. Also included is information on how to interpret content
            variation scores when slotting and reviewing at a Library level.
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Typography className={classes.paragraphTitle} gutterBottom>
            Sentence Variation Score Metrics:
          </Typography>
          <Typography className={classes.paragraph}>
            <strong> Sentence Utilization Score:</strong> Total # of times a
            sentence group has been slotted. <u>Represented by:</u> The Sentence
            Utilization score is represented by the number in the Content
            Variation Score.
          </Typography>
          <Typography className={classes.paragraph}>
            <strong>Sentence Saturation %:</strong> Percentage of narratives
            that have this sentence slotted vs. the number of narratives
            connected to the library as a whole. <br />
            <u>Represented by:</u> This Saturation % is represented by the color
            and symbol within the Content Variation Score.
          </Typography>
          <Typography className={classes.paragraphTitle} gutterBottom>
            Interpreting Content Variation Score:
          </Typography>
          <Typography className={classes.paragraph}>
            For Utilization Score, below are the recommendations for the
            following ranges:
            <ListItem className={classes.score}>
              <Dot className={classes.dot} /> 0 – 3.0 - Okay to slot
            </ListItem>
            <ListItem className={classes.score}>
              <Dot className={classes.dot} />
              4.0 – 6.0 - Caution, deeper look needed
            </ListItem>
            <ListItem className={classes.score}>
              <Dot className={classes.dot} />
              +6.0 - Do not slot
            </ListItem>
            For Saturation %, below are the recommendations for the following
            ranges:
            <ListItem className={classes.score}>
              <Check style={{ color: "#66bb6a" }} /> (0–30% saturation) - Okay
              to slot
            </ListItem>
            <ListItem className={classes.score}>
              <Warning style={{ color: "#ffc107" }} />
              (31–60% saturation) - Caution, deeper look needed
            </ListItem>
            <ListItem className={classes.score}>
              <Report style={{ color: "#a31545" }} />
              (Equals 61%+ saturation) - Do not slot
            </ListItem>
            <strong>Note:</strong> These ranges are meant to be guides. Both
            metrics should be reviewed in conjunction with each other when
            making content creation / slotting decisions.
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  )
}
