// eslint-disable-next-line no-unused-vars
import React from "react"
import PropTypes from "prop-types"
import { Button as MuiButton } from "@mui/material"

const defaultProps = {
  fullWidth: false,
  type: "button"
}

const propTypes = {
  color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  startIcon: PropTypes.node,
  type: PropTypes.oneOf(["button", "reset", "submit"])
}

function Button({ ...props }) {
  const {
    children,
    color,
    disabled,
    fullWidth,
    onClick,
    size,
    type,
    variant,
    startIcon
  } = props
  return (
    <MuiButton
      className="button"
      color={color}
      disabled={disabled}
      fullWidth={fullWidth}
      onClick={onClick}
      size={size}
      type={type}
      variant={variant}
      startIcon={startIcon}
    >
      {children}
    </MuiButton>
  )
}

Button.defaultProps = defaultProps
Button.propTypes = propTypes

export default Button
