import React, { useCallback, useEffect, useState } from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import { PropTypes } from "prop-types"
import CTAStatusTypes from "./CTAStatusTypes"
import makeStyles from "@mui/styles/makeStyles"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import CardContent from "@mui/material/CardContent"
import Button from "@mui/material/Button"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import DialogActions from "@mui/material/DialogActions"
import Divider from "@mui/material/Divider"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormHelperText from "@mui/material/FormHelperText"
import Grid from "@mui/material/Grid"
import InputAdornment from "@mui/material/InputAdornment"
import LinearProgress from "@mui/material/LinearProgress"
import Modal from "@mui/material/Modal"
import Paper from "@mui/material/Paper"
import TextField from "@mui/material/TextField"
import RadioGroup from "@mui/material/RadioGroup"
import Radio from "@mui/material/Radio"
import ToolBar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete"
import InfoIcon from "@mui/icons-material/Info"
import IconButton from "@mui/material/IconButton"
import ReportIcon from "@mui/icons-material/Report"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { globalSettings } from "variables/general"
import GridItem from "components/Grid/GridItem"
import CodeEditor from "components/CodeEditor"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBox"

import dayjs from "dayjs"
//import DayjsUtils from "@date-io/dayjs"
import utc from "dayjs/plugin/utc"

import NarrativePreviewDAO from "daos/narrativePreviewDAO"
import WordCounter from "components/WordCounter/WordCounter"
import Accordion from "@mui/material/Accordion"
import { AccordionDetails, AccordionSummary, FormLabel } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import CTAPayoutExceptionMulti from "./CTAPayoutExceptionMulti"
import { urlValidation } from "tools/ValidationHelpers"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import "dayjs/locale/en"

dayjs.extend(utc)

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    isDependencyLoaded: store.ctaPreviewDependencyStore.isDependencyLoaded,
    getCTAPreviewDependency:
      store.ctaPreviewDependencyStore.getCTAPreviewDependency,
    updateCTAPreviewDependency:
      store.ctaPreviewDependencyStore.updateCTAPreviewDependency,
    ctaPreviewDependency: store.ctaPreviewDependencyStore.ctaPreviewDependency
  }))
}

const useStyles = makeStyles({
  CTAGrid: {
    "& div.MuiAutocomplete-tag": {
      backgroundColor: "#DDEAEF",
      height: "24px"
    }
  },
  MuiPaper: {
    borderRadius: "0px"
  },
  CTAform: {
    display: "block",
    flexDirection: "row"
  },
  ctaToolbar: {
    fontSize: "1rem",
    color: "#FFF",
    backgroundColor: "#4d9ab5"
  },
  requiredMsg: {
    color: "#c00",
    textAlign: "center",
    backgroundColor: "#f7f7f7"
  },
  collapseContent: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingBottom: "15px",
    borderRadius: "0px"
  },
  monetizationCard: {
    backgroundColor: "#DDEAEF",
    display: "inline-flex",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    borderRadius: "0px"
  },
  monetizationHeader: {
    backgroundColor: "#DDEAEF",
    padding: "5px",
    borderRadius: "0px",
    marginTop: "5px",
    paddingLeft: "15px"
  },
  autocompleteCTA: {
    size: "medium",
    backgroundColor: "#FFF"
  },
  conversionType: {
    "& .MuiTypography-root": {
      fontWeight: "bold",
      color: "#888"
    }
  },
  ctaTop: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
    display: "flex",
    marginRight: "10px",
    borderRadius: "0px"
  },
  infoCard: {
    display: "inline-flex",
    flexDirection: "row",
    paddingLeft: "15px",
    paddingBottom: "9px",
    borderRadius: "0px"
  },
  detailsCard: {
    paddingLeft: "15px",
    paddingTop: "5px",
    borderRadius: "0px"
  },
  CTATitle: {
    paddingLeft: "15px",
    paddingTop: "5px"
  },
  CTADivide: {
    marginLeft: "9px",
    marginRight: "9px",
    height: "2px",
    backgroundColor: "#DDEAEF"
  },
  CTAmodalIcon: {
    fontSize: "1.25rem",
    color: "#144e68",
    marginLeft: "2px",
    padding: "0"
  },
  accordionSummary: {
    pointerEvents: "none"
  },
  accordionIcon: {
    pointerEvents: "auto"
  },
  CTAButton: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "15px",
    paddingBottom: "15px"
  },
  buttonStyle: {
    fontSize: "12px",
    padding: "14px 23px",
    minWidth: "110px",
    marginRight: "4px"
  },
  inputCTAField: {
    backgroundColor: "#FFF"
  },
  "&.MuiSelect-select.MuiSelect-select": {
    border: "2px red solid !important"
  },
  ctaRegion: {
    display: "flex",
    flexWrap: "nowrap",
    "&.MuiChip-root.MuiAutocomplete-tag.MuiChip-deletable": {
      backgroundColor: "#DDEAEF !important"
    }
  },
  checkRegion: {
    color: "#144e68",
    "&$checked": {
      color: "#144e68"
    }
  },
  payoutAccordion: {
    display: "inline-flex",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    marginBottom: "15px"
  },
  addIconButton: {
    color: "#144e68"
  },
  buttonExceptions: {
    minWidth: "65px",
    minHeight: "20px",
    fontSize: "12px",
    padding: "10px 20px",
    marginRight: "4px"
  },
  payoutDetails: {
    flexDirection: "column"
  },
  successModal: {
    position: "absolute",
    color: "#069",
    backgroundColor: "#eee",
    borderRadius: "4px",
    width: "300px",
    height: "300px",
    top: "45%",
    left: "55%",
    transform: "translate(-45%, -45%)",
    "& h3": {
      fontWeight: "bold"
    },
    "& ul": {
      padding: "5px 0 0 5px",
      fontWeight: "bold"
    }
  },
  failedModal: {
    position: "absolute",
    color: "#a31545",
    backgroundColor: "#eee",
    borderRadius: "4px",
    width: "300px",
    height: "200px",
    transform: "translate(100%, 100%)",
    "& h3": {
      fontWeight: "bold"
    },
    "& ul": {
      padding: "5px 0 0 5px",
      fontWeight: "bold"
    }
  }
})
function CTAEditor(props) {
  const classes = useStyles()
  const { isDependencyLoaded, getCTAPreviewDependency, ctaPreviewDependency } =
    useStoreData()
  const { ctaInfo } = props
  const { formValues, formOptions } = ctaInfo
  const [myFormValues, setMyFormValues] = useState(props.ctaInfo.formValues)
  const [ctaFormErrors, setCtaFormErrors] = useState({})
  const [responseMessage, setResponseMessage] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showFailedModal, setShowFailedModal] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [resetEditor, setResetEditor] = useState(false)
  const [scribanValues, setScribanValues] = useState(formValues.template)
  const [createdNewCTA, setCreatedNewCTA] = useState(false)
  const [newParamsNewCTA, setNewParamsNewCTA] = useState({})
  const [templatePreview, setTemplatePreview] = useState("")
  const [toggleOpen, setToggleOpen] = useState(false)
  const [dialogValue, setDialogValue] = useState("title")
  const [allowSaveCampaign, setAllowSaveCampaign] = useState(true)
  const [exceptionsBeingEdited, setExceptionsBeingEdited] = useState([])
  const [resetPayoutExceptions, setResetPayoutExceptions] = useState(true)
  const [haveEditedPayoutExceptions, setHaveEditedPayoutExceptions] =
    useState(false)

  const [addingPayoutExceptions, setAddingPayoutExceptions] = useState(1)
  const [appliedExceptions, setAppliedExceptions] = useState(false)
  const filter = createFilterOptions()

  const _USA_REGION = 237

  const {
    callToActionAffiliateId,
    affiliateLink,
    assetLink,
    promoText,
    callToActionCampaignId,
    companyId,
    callToActionName, //ctaName,
    callToActionTypeId, //ctaTypeId,
    startDate,
    endDate,
    ctaId,
    formFactor,
    leagueId,
    organizationId,
    callToActionPayOff, //payout
    prioritizationOverride,
    callToActionText, //ctaText,
    region,
    groupRegionByPayout,
    owner,
    status,
    isArchived,
    disableSaveButton,
    isConversionPercentPayOut
  } = myFormValues || {}

  const [regionByPayout, setRegionByPayout] = useState(
    formValues.groupRegionByPayout
  )
  const [payoutException, setPayoutException] = useState(
    formValues.payoutException
  )
  const [resetPayouts, setResetPayouts] = useState(false)
  const [exceptionPayouts, setExceptionPayouts] = useState(
    formValues.justPayoutsGrouped
  )

  const ctaFormValidation = () => {
    const newFormValues = {
      ...myFormValues
    }
    let formErrors = {}
    let validationError = false

    if (typeof newFormValues.isArchived !== "boolean") {
      formErrors.isArchived = true
      validationError = true
    }

    if (!newFormValues.callToActionCampaignId) {
      formErrors.callToActionCampaignId = true
      validationError = true
    }
    if (!newFormValues.organizationId) {
      formErrors.organizationId = true
      validationError = true
    }
    if (!newFormValues.leagueId) {
      formErrors.leagueId = true
      validationError = true
    }
    if (newFormValues.region.length === 0) {
      formErrors.region = true
      validationError = true
    }
    if (
      !newFormValues.callToActionPayOff &&
      newFormValues.callToActionPayOff !== 0
    ) {
      formErrors.callToActionPayOff = true
      validationError = true
    }
    if (!newFormValues.companyId) {
      formErrors.companyId = true
      validationError = true
    }
    if (!newFormValues.callToActionAffiliateId) {
      formErrors.callToActionAffiliateId = true
      validationError = true
    }
    if (!newFormValues.startDate) {
      formErrors.startDate = true
      validationError = true
    }
    if (!newFormValues.callToActionName) {
      formErrors.callToActionName = true
      validationError = true
    }
    if (!newFormValues.formFactor) {
      formErrors.formFactor = true
      validationError = true
    }
    if (!newFormValues.callToActionTypeId) {
      formErrors.callToActionTypeId = true
      validationError = true
    }
    if (!newFormValues.affiliateLink) {
      formErrors.affiliateLink = true
      validationError = true
      formErrors.affiliateLinkMsg = "Affiliate Link is required."
    }
    if (!newFormValues.callToActionText) {
      formErrors.callToActionText = true
      validationError = true
    }
    if (!scribanValues) {
      formErrors.template = true
      validationError = true
    }
    formErrors.validationHasError = validationError
    setCtaFormErrors(formErrors)
    return validationError
  }

  const noChangeInRegions = (arr1, arr2, exceptions1, exceptions2) => {
    if (!arr2 || !arr1) {
      return false
    } else if (arr1.length !== arr2.length) {
      return false
    } else if (arr1.length === 0 && arr2.length === 0) {
      return true
    }

    let arrayMatch = false
    let newArray = []
    arr1.forEach(itm => {
      arr2.forEach(itm2 => {
        if (arr1.includes(itm2) && !newArray.includes(itm2)) {
          newArray.push(itm2)
        }
      })
    })
    if (arr1.length === newArray.length) {
      arrayMatch = true
    }
    let match = true
    if (arrayMatch) {
      if (exceptions1.length === exceptions2.length) {
        exceptions1.forEach((excp1, i) => {
          if (excp1 !== exceptions2[i]) {
            match = false
          }
        })
      } else {
        return false
      }
    } else {
      return false
    }
    return match
  }

  const createCampaignName = ctaCampaignName => {
    //Adding New Campaign Name and Id to DB
    const campaignNameUrl = `${globalSettings.maynardBaseUrl}/api/CallToActionCampaign`

    fetch(campaignNameUrl, {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "text/plain",
        "Content-Type": "application/json",
        "X-API-Key": `${globalSettings.maynardApiKey}`
      },
      body: JSON.stringify({
        name: ctaCampaignName,
        description: ""
      })
    })
      .then(res => res.json())
      .then(data => updateCampaign(data))
      .catch(err => {
        console.error(`Error on POST to /api/CallToActionCampaign`, err)
      })
  }

  const handleCloseCampaign = () => {
    setToggleOpen(false)
  }

  const handleCampaignSubmit = event => {
    event.preventDefault()
    if ((!event.detail || event.detail === 1) && allowSaveCampaign) {
      setAllowSaveCampaign(false)
      createCampaignName(dialogValue.title)
      handleCloseCampaign()
    }
  }

  const handleInputValidation = inputId => {
    const errorObj = { ...ctaFormErrors }
    if (
      Array.isArray(myFormValues[inputId]) &&
      myFormValues[inputId].length === 0
    ) {
      errorObj[inputId] = true
      errorObj.validationHasError = true
    } else if (inputId === "assetLink") {
      const urlV = urlValidation(myFormValues.assetLink)
      if (!urlV && myFormValues.assetLink) {
        errorObj.assetLinkMsg = "Asset Link must be a valid URL."
        errorObj[inputId] = true
        errorObj.validationHasError = true
      } else {
        errorObj[inputId] = false
        errorObj.validationHasError = false
      }
    } else if (
      !Array.isArray(myFormValues[inputId]) &&
      !myFormValues[inputId]
    ) {
      if (
        (inputId === "callToActionPayOff" && myFormValues[inputId]) ||
        myFormValues[inputId] === 0
      ) {
        errorObj[inputId] = false
        errorObj.validationHasError = false
      } else {
        errorObj[inputId] = true
        errorObj.validationHasError = true
      }
      if (
        inputId === "affiliateLink" &&
        !urlValidation(myFormValues.affiliateLink)
      ) {
        errorObj.affiliateLinkMsg = "Affiliate Link is required."
      }
    } else {
      errorObj[inputId] = false
      errorObj.validationHasError = false
    }
    setCtaFormErrors(errorObj)
  }

  const handleSaveForm = () => {
    let validationError = ctaFormValidation()
    if (validationError) {
      return
    }
    let notUpdated = false
    exceptionsBeingEdited.forEach(itm => {
      if (itm) {
        notUpdated = true
      }
    })
    if (notUpdated) {
      alert("Please Apply your Payout Exceptions before saving.")
      return
    }

    const ctaId = myFormValues.ctaId
    const orgId = myFormValues.organizationId
    const url = ctaId
      ? `${globalSettings.maynardBaseUrl}/api/ManageCallToAction/${ctaId}`
      : `${globalSettings.maynardBaseUrl}/api/ManageCallToAction`

    let callToActionAffiliateOrganizationId
    formOptions.organizationContractAffiliate.forEach(itm2 => {
      if (
        itm2.affiliateId === callToActionAffiliateId &&
        myFormValues.organizationId === itm2.organizationId
      ) {
        callToActionAffiliateOrganizationId = itm2.affiliateOrganizationId
      }
    })
    let payoutExceptionsToBeSaved = false
    let regions = []
    let setRegions = []
    let missingPayoutExceptionAmount = false
    const isAllUSA = region.filter(itm => itm === _USA_REGION).length
      ? true
      : false
    regionByPayout.forEach((grp, index) => {
      grp.forEach(itm => {
        if (itm.id) {
          if (!exceptionPayouts[index] && index) {
            //This is for when you added Exception Regions BUT forgot to add a payout value.  The default can be 0
            missingPayoutExceptionAmount = true
          }
          if (
            //If we have selected the entire USA, we do not have to show individual states as having a "default payout",
            //just save the entire USA value for that "default payout"
            (itm.id && isAllUSA) ||
            itm.id === _USA_REGION ||
            (!isAllUSA && itm.id && region.includes(itm.id)) ||
            (index && !region.includes(itm.id))
          ) {
            setRegions.push(itm.id)
            regions.push({
              regionId: itm.id,
              callToActionPayOff: index
                ? exceptionPayouts[index]
                : callToActionPayOff,
              payOutException: !!index,
              isConversionPercentPayOut: isConversionPercentPayOut || false
            })
            if (!!index && !payoutExceptionsToBeSaved) {
              payoutExceptionsToBeSaved = true
            }
          }
        }
      })
    })
    //Ones you mayhave added without payout exceptions, don't need them IF you have also selected USA
    !setRegions.includes(_USA_REGION) &&
      myFormValues.region.forEach(itm => {
        if (!setRegions.includes(itm)) {
          regions.push({
            regionId: itm,
            callToActionPayOff: myFormValues.callToActionPayOff,
            payOutException: false,
            isConversionPercentPayOut: isConversionPercentPayOut || false
          })
        }
      })

    //If allUSA selected (default),  make sure states aren't "no payout excpetion" entries
    if (isAllUSA) {
      regions = regions.filter(
        //itm => itm.payoutPercentage !== callToActionPayOff
        itm => itm.payOutException || itm.regionId === _USA_REGION
      )
    }
    if (!payoutExceptionsToBeSaved && payoutException) {
      alert(
        'You must have at least 1 Payout Exceptions if you have selected the "Payout Exception" checkbox'
      )
      return
    }

    if (missingPayoutExceptionAmount) {
      alert("Each Payout Exceptions must have an Amount")
      return
    }

    const template = ctaId
      ? scribanValues
        ? scribanValues
        : myFormValues.template
      : scribanValues
      ? scribanValues
      : ""

    myFormValues.tagIds = !!myFormValues.formFactor
      ? [myFormValues.formFactor]
      : []
    const verificationStatusId = ctaId ? ctaInfo.verificationStatusId : 1
    const newFormValues = {
      ...myFormValues,
      template,
      callToActionAffiliateOrganizationId,
      prioritizationOverride: Number(myFormValues.prioritizationOverride),
      callToActionPayOff: Number(myFormValues.callToActionPayOff),
      regions,
      verificationStatusId
    }

    const toDelete = [
      "callToActionAffiliateId",
      "organizationId",
      "editingContract",
      "disableSaveButton",
      "campaignName",
      "leagueName",
      "organizationName",
      "formFactor",
      "region",
      "regionsAllParams",
      "editingCTAs",
      "justPayoutsGrouped",
      "groupRegionByPayout"
    ]
    toDelete.forEach(itm => {
      if (newFormValues[itm] !== undefined) {
        delete newFormValues[itm]
      }
    })
    setIsSaving(true)
    fetch(url, {
      credentials: "include",
      method: ctaId ? "put" : "post",
      headers: {
        Accept: "text/plain",
        "Content-Type": "application/json",
        "X-API-Key": `${globalSettings.maynardApiKey}`
      },
      body: JSON.stringify({
        ...newFormValues
      })
    })
      .then(Response => Response && Response.ok === true && Response.json())
      .then(response => {
        setIsSaving(false)
        setHaveEditedPayoutExceptions(false)
        if (ctaId) {
          if (ctaId === response) {
            setResponseMessage(`You succcessfully updated CTA #${ctaId}`)
            setShowSuccessModal(true)
            if (
              props.ctaInfo.organizationId === orgId &&
              props.ctaInfo.leagueId === myFormValues.leagueId &&
              props.ctaInfo.isArchived === myFormValues.isArchived &&
              noChangeInRegions(
                formValues.region,
                myFormValues.region,
                formValues.justPayoutsGrouped,
                exceptionPayouts
              )
            ) {
              props.updateCTAs(newFormValues, orgId, response)
            } else {
              props.reload(newFormValues, orgId)
            }
          } else {
            if (typeof response === "number") {
              setResponseMessage(`You Created CTA #${response}`)
              setShowSuccessModal(true)
              if (
                props.ctaInfo.organizationId === orgId &&
                props.ctaInfo.leagueId === myFormValues.leagueId &&
                noChangeInRegions(
                  formValues.region,
                  myFormValues.region,
                  formValues.justPayoutsGrouped,
                  exceptionPayouts
                )
              ) {
                props.updateCTAs(newFormValues, orgId, response)
              } else {
                props.reload(newFormValues, orgId)
              }
            } else {
              setResponseMessage(`Action failed, response of: ${response}`)
              setShowFailedModal(true)
            }
          }
        } else {
          if (typeof response === "object") {
            const newestFormValues = {
              ...myFormValues,
              region: regions,
              ctaId: response.id,
              editingCTAs: false,
              callToActionAffiliateOrganizationId:
                newFormValues.callToActionAffiliateOrganizationId
            }
            setCreatedNewCTA(true)
            setNewParamsNewCTA({ obj: newestFormValues, orgId })
            setResponseMessage(`You Created CTA #${response.id}`)
            setShowSuccessModal(true)
            setMyFormValues(newestFormValues)
          } else {
            setResponseMessage(`Action failed, response of: ${response}`)
            setShowFailedModal(true)
          }
        }
      })
      .catch(err => {
        console.error(`Error calling ${url}`, err)
      })
  }

  const handleCtaChangeNumber = name => event => {
    let myNumber = event.target.value
    if (myNumber === "") {
      myNumber = null
    } else {
      let tmp = myNumber.split(/\./)
      if (tmp[1] && tmp[1].length > 2) {
        return
      }
    }
    const newFormValues = {
      ...myFormValues,
      disableSaveButton: false,
      [name]: myNumber
        ? isConversionPercentPayOut && Number(myNumber) > 100
          ? 100
          : Number(myNumber)
        : null,
      editingCTAs: true
    }
    setMyFormValues(newFormValues)
  }

  const handleCtaChange = name => event => {
    const newFormValues = {
      ...myFormValues,
      disableSaveButton: false,
      [name]: event.target.value,
      editingCTAs: true
    }
    setMyFormValues(newFormValues)
  }

  const handleDateChange = (date, name) => {
    const newFormValues = {
      ...myFormValues,
      disableSaveButton: false,
      editingCTAs: true
    }
    if (!date) {
      newFormValues[name] = null
      setMyFormValues(newFormValues)
    } else if (!Object.is(date.$d.valueOf(), NaN)) {
      newFormValues[name] = date.$d.toISOString()
      setMyFormValues(newFormValues)
    }
  }

  const unselectUSA_ModifyRegionsByPayout = val => {
    let tmp = null
    for (let i = regionByPayout[0].length - 1; i > -1; i -= 1) {
      tmp = !tmp ? regionByPayout[0][i] : tmp
      regionByPayout[0].splice(i, 1)
    }
    if (val && tmp) {
      tmp.id = val[0]
      tmp.payoutException = false
      tmp.indexId = 0
      regionByPayout[0].push(tmp)
      setRegionByPayout(regionByPayout)
    }
  }

  const defaultStatesExist = nvx => {
    let found = false
    regionByPayout.forEach((grp, index) => {
      grp.forEach((itm, index2) => {
        if (nvx.includes(itm.id)) {
          if (itm.payoutException === false) {
            found = true
          }
          nvx.splice(nvx.indexOf(itm.id), 1)
        }
      })
    })
    if (nvx.length) {
      found = nvx
    }
    return found
  }

  const addRegionsToPayoutGroups = nv => {
    let selectedRegions = []
    nv &&
      nv.forEach((itm, i) => {
        selectedRegions.push({
          regionId: itm.id,
          payoutPercentage: callToActionPayOff,
          payoutException: false,
          indexId: 0,
          index: i
        })
      })

    regionByPayout[0] = selectedRegions
    if (!regionByPayout[1]) {
      regionByPayout[1] = []
      regionByPayout[1].push({
        regionId: 0,
        payoutPercentage: 0,
        display: true,
        isNew: true,
        payoutException: true
      })
    }
    setRegionByPayout(regionByPayout)
  }

  const payoutExceptionsExist = () => {
    let foundExceptions = false
    regionByPayout.forEach((grp, index) => {
      grp.forEach(itm => {
        if (
          ((itm.regionId && itm.regionId !== _USA_REGION) ||
            (itm.id && itm.id !== _USA_REGION)) &&
          itm.payoutException === true
        ) {
          foundExceptions = true
        }
      })
    })
    return foundExceptions
  }

  const checkIfWeNeedToRemoveRegionFromExceptions = vals => {
    let found = false
    vals.forEach(itm => {
      regionByPayout.forEach((grp, index) => {
        for (let i = grp.length - 1; i > -1; i -= 1) {
          if (itm === grp[i].id) {
            regionByPayout[index].splice(i, 1)
            found = true
          }
        }
      })
    })
    if (found) {
      setRegionByPayout(regionByPayout)
    }
  }

  const thisIsAPayoutException = vals => {
    let found = false
    vals.forEach(val => {
      regionByPayout.forEach((grp, index) => {
        if (index) {
          for (let i = grp.length - 1; i > -1; i -= 1) {
            if (val === grp[i].id) {
              found = true
            }
          }
        }
      })
    })
    return found
  }

  const handleAutoCompleteMultiChange = (event, newValue, reason, name) => {
    let newVals = []
    let nv = []
    newValue.forEach(itm => newVals.push(itm.id))
    newValue.forEach(itm => nv.push(itm.id))
    let oldVsNew = myFormValues[name].filter(x => !newVals.includes(x))
    if (oldVsNew[0] && oldVsNew[0] === _USA_REGION && oldVsNew.length) {
      let tmp = defaultStatesExist(newVals)
      if (tmp && tmp.length) {
        unselectUSA_ModifyRegionsByPayout(tmp)
      } else if (!tmp && payoutExceptionsExist()) {
        alert(
          "Any region with a payout exception must be removed within the payout exception field prior to being removed from the Region field"
        )
        return
      }
    } else if (
      newVals.includes(_USA_REGION) &&
      newVals.length > 1 &&
      region[0] &&
      region[0] === _USA_REGION
    ) {
      alert(
        "You have USA selected as the Region, you cannot select individual States.  Remove the USA to select individual States"
      )
      return
    } else if (newVals.includes(_USA_REGION) && newVals.length > 1) {
      alert(
        "You have selected and individual State(s), you can only select USA if you have no States selected"
      )
      return
    } else if (oldVsNew[0] && thisIsAPayoutException(oldVsNew)) {
      alert(
        "Any region with a payout exception must be removed within the payout exception field prior to being removed from the Region field"
      )
      return
      // } else if (newVals.includes(_USA_REGION)) {
      //   return
    } else if (
      oldVsNew.includes(!_USA_REGION) &&
      !newVals.includes(_USA_REGION) &&
      payoutExceptionsExist()
    ) {
      alert(
        "Any region with a payout exception must be removed within the payout exception field prior to being removed from the Region field"
      )
      return
    }
    let allArePayoutExceptions = true
    newVals.forEach(val => {
      allArePayoutExceptions =
        allArePayoutExceptions && thisIsAPayoutException([val])
    })
    if (allArePayoutExceptions && newVals.length) {
      alert("You must have at least 1 non-payout exception Region selected")
      return
    }
    if (formValues.region.length === 0) {
      addRegionsToPayoutGroups(newValue)
    }
    if (oldVsNew.length) {
      checkIfWeNeedToRemoveRegionFromExceptions(oldVsNew)
    }
    const newFormValues = {
      ...myFormValues,
      disableSaveButton: false,
      [name]: nv,
      editingCTAs: true
    }
    setMyFormValues(newFormValues)
  }

  const handleAutocompleteChangeOrg = (event, newValue, reason, name) => {
    const newFormValues = {
      ...myFormValues,
      callToActionAffiliateId: null,
      [name]: newValue?.id || null
    }
    setMyFormValues(newFormValues)
  }

  const handleAutocompleteChangeAffiliate = (event, newValue, reason, name) => {
    const newFormValues = {
      ...myFormValues,
      [name]: newValue?.id || null
    }
    setMyFormValues(newFormValues)
  }

  const handleAutocompleteChange = (event, newValue, reason, name) => {
    const newFormValues = {
      ...myFormValues,
      disableSaveButton: false,
      [name]: newValue?.id || null,
      editingCTAs: true
    }
    setMyFormValues(newFormValues)
  }

  const updateCampaign = newValue => {
    const newFormValues = {
      ...myFormValues,
      disableSaveButton: false,
      callToActionCampaignId: newValue.id,
      campaignName: newValue.name,
      editingCTAs: true
    }
    props.setCallToActionCampaign(
      [...formOptions.campaigns, newValue],
      newFormValues
    )
    setMyFormValues(newFormValues)
  }

  const createGroupPayoutExceptions = useCallback(() => {
    setAddingPayoutExceptions(addingPayoutExceptions - 1)
    let tmp = typeof regionByPayout === "object" ? regionByPayout : []
    tmp.push([
      {
        index: addingPayoutExceptions,
        regionId: 0,
        payoutPercentage: 0,
        display: true,
        isNew: true
      }
    ])
    setRegionByPayout(tmp)
  }, [addingPayoutExceptions, regionByPayout])

  const removeGroupPayoutExceptions = index => {
    setAddingPayoutExceptions(addingPayoutExceptions - 1)
    let tmp = typeof regionByPayout === "object" ? regionByPayout : []
    tmp.splice(index, 1)
    setRegionByPayout(tmp)
    let tmp2 = exceptionPayouts
    tmp2.splice(index, 1)
    setExceptionPayouts(tmp2)
    if (!areThereAnyPayoutExceptions() && payoutException) {
      setPayoutException(false)
    }
  }

  const removePayoutExceptions = () => {
    if (regionByPayout.length > 1) {
      for (let i = regionByPayout.length - 1; i > 0; i -= 1) {
        regionByPayout[i] = []
      }
      setRegionByPayout(regionByPayout)
    }
  }

  const updateSelectedRegionsOnBlur = (vals, myIndex) => {
    let tmpArray = []
    let newArray = []
    let found = false
    vals.forEach(val => {
      let foundVal = false
      regionByPayout?.forEach((itm2, index) => {
        for (let i = itm2.length - 1; i > -1; i -= 1) {
          if (val.id === itm2[i].id) {
            found = true
            foundVal = true
            itm2[i].payoutException = val.payoutException
            tmpArray.push(itm2[i])
            regionByPayout[index].splice(i, 1)
          }
        }
      })
      if (!foundVal) {
        newArray.push(val)
      }
    })
    regionByPayout[myIndex] = [] //10 April
    tmpArray.forEach(itm => {
      regionByPayout[myIndex].push(itm)
    })
    if (!found && vals.length < regionByPayout[myIndex].length) {
      //Not found because we are removing values
      for (let i = regionByPayout[myIndex].length; i > -1; i -= 1) {
        let found = false
        vals.forEach(val => {
          if (val.id === regionByPayout[myIndex][i].id) {
            found = true
          }
        })
        if (!found) {
          regionByPayout[myIndex].splice(i, 1)
        }
      }
    }

    newArray.forEach(itm => {
      regionByPayout[myIndex].push(itm)
    })

    setRegionByPayout(regionByPayout)
    setAppliedExceptions(true)
  }

  const updateSelectedRegions = (
    vals,
    oldVals,
    payoutPercentage,
    multiIndex
  ) => {
    //A few rules apply to the Region Payout Exception Groups
    //The first Region Payout Exception Group contains the "default" regions, and can never be empty -
    //ie you can't take all out an put in other Exception Groups
    //Each other region can add a value and remove values
    //You can add values from any exception Group
    //if you remove a value, it will automatically go back into the the "default" First Payuout Exception Group
    let changedOrg = oldVals.filter(function (val) {
      return vals.indexOf(val) === -1
    })
    if (!changedOrg.length) {
      changedOrg = vals.filter(function (val) {
        return oldVals.indexOf(val) === -1
      })
    }
    let tryingToRemoveLastElementOfDefaultGroup = false
    if (oldVals.length > vals.length && changedOrg.length) {
      //remove
      let cutIndex = null

      let toMove = null
      regionByPayout &&
        regionByPayout[multiIndex].forEach((itm, index2) => {
          if (itm.id === changedOrg[0]) {
            itm.payoutPercentage = formValues.callToActionPayOff
            cutIndex = index2
          }
        })
      if (cutIndex !== null) {
        toMove = regionByPayout[multiIndex][cutIndex]
        toMove.payoutPercentage = callToActionPayOff
        toMove.payoutException = false
        regionByPayout[multiIndex].splice(cutIndex, 1)
        if (!allUSA.length) {
          //Only move it if USA not selected
          regionByPayout[0].push(toMove)
        }
      }
    } else if (oldVals.length < vals.length && changedOrg.length) {
      //Add
      let cutIndex = null
      let savedToMove = false
      let payoutGroupIndex = null
      let toMove = null
      let newVal = null
      regionByPayout?.forEach((itm2, index) => {
        itm2.forEach((itm, index2) => {
          if (itm.id === changedOrg[0]) {
            itm.payoutPercentage = payoutPercentage
            cutIndex = index2
            payoutGroupIndex = index
          }
        })
      })
      if (cutIndex !== null && !savedToMove && payoutGroupIndex != null) {
        if (payoutGroupIndex || regionByPayout[payoutGroupIndex].length > 1) {
          //You can't remove the last element from the default Group
          toMove = regionByPayout[payoutGroupIndex][cutIndex]
          regionByPayout[payoutGroupIndex].splice(cutIndex, 1)
          savedToMove = true
        } else {
          tryingToRemoveLastElementOfDefaultGroup = true
        }
      } else {
        formValues.regionsAllParams?.forEach(itm => {
          if (itm.id === changedOrg[0]) {
            newVal = itm
          }
        })
      }

      if (
        cutIndex !== null &&
        toMove &&
        payoutGroupIndex != null &&
        multiIndex != null
      ) {
        regionByPayout[multiIndex].push(toMove)
      } else if (newVal) {
        regionByPayout.push(newVal)
      }
    }
    if (!tryingToRemoveLastElementOfDefaultGroup) {
      setRegionByPayout(regionByPayout)
      setResetPayouts(true)
      return true
    } else {
      return false
    }
  }

  const [open, setOpen] = useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = (
    <CheckBoxIcon fontSize="small" className={classes.checkRegion} />
  )

  /* The relationship that ties Affiliate/Contract/Organization means we need logic to reconcile some values
  In the CTAsManager, we put together the organizationContractAffiliate array that links the 3
  The callToActionAffiliate is pointed to by the value "callToActionAffiliateId" in the callToActionAffiliateOrganization table
  and it also has an "organizationId" value
  Iterating through all the entries in the organizationContractAffiliate array, IF:
  1: The "organizationId" value in that array matches the organizationId for this CTA 
  2: AND the "id" value in the "callToActionAffiliate" table matches the "affiliateId" value in the "organizationContractAffiliate" array
  3: Then this affiliate is available for the selected Organization
  4: And IF you hit this code BEFORE callToActionAffiliateId has been set - this means when you first load the CTAEditor and have not 
  edited the Affiliate - if in that case affiliateOrganizationId === callToActionAffiliateOrganizationId, then this entry of the 
  "organizationContractAffiliate" array contains the correct affiliateId, set callToActionAffiliateId = itm.affiliateId
  */
  const filteredAffiliates = formOptions.affiliates.filter(itm => {
    let found = false
    formOptions.organizationContractAffiliate &&
      formOptions.organizationContractAffiliate.forEach(itm2 => {
        if (
          organizationId === itm2.organizationId &&
          itm.id === itm2.affiliateId
        ) {
          found = true
        }
      })
    return found
  })

  const handleRadioChange = name => e => {
    if (
      (e.target.value === "Percent" && myFormValues[name] === false) ||
      (e.target.value === "Fee" && myFormValues[name] === true)
    ) {
      const newFormValues = {
        ...myFormValues,
        disableSaveButton: false,
        callToActionPayOff: 0,
        [name]:
          e.target.value === "Fee"
            ? false
            : e.target.value === "Percent"
            ? true
            : undefined,
        editingCTAs: true
      }
      setMyFormValues(newFormValues)
    }
  }

  const handleCheckboxChange = e => {
    const newFormValues = {
      ...myFormValues,
      disableSaveButton: false,
      [e.target.id]: e.target.checked,
      editingCTAs: true
    }
    setMyFormValues(newFormValues)
  }

  const areThereAnyPayoutExceptions = () => {
    let numberOfPayoutExceptions = 0
    regionByPayout.forEach((outer, index) => {
      index &&
        outer.forEach(itm => {
          if (itm.payoutPercentage && itm.payoutException) {
            numberOfPayoutExceptions += 1
          }
        })
    })
    return numberOfPayoutExceptions === 0 ? false : true
  }

  const checkIfWeNeedToDisablePayoutExceptions = () => {
    //We must have at least one region that is NOT a Payout Exception
    if (region.includes(_USA_REGION)) {
      //If we have the USA Region selected, the we don't neex to worry about that scenario
      return false
    }
    let numberOfPayoutExceptions = 0
    regionByPayout.forEach((outer, index) => {
      index &&
        outer.forEach(itm => {
          if (itm.payoutPercentage && itm.payoutException) {
            numberOfPayoutExceptions += 1
          }
        })
    })
    // console.log(
    //   `we have "${numberOfPayoutExceptions}" payout exceptions and "${region.length}" regions`
    // )
    if (region.length > numberOfPayoutExceptions + 1) {
      return false
    } else {
      return true
    }
  }

  const template = ctaId
    ? scribanValues
      ? scribanValues
      : myFormValues.template
    : scribanValues
    ? scribanValues
    : ""

  useEffect(() => {
    setMyFormValues(props.ctaInfo.formValues)
    setCtaFormErrors({})
    if (props.ctaInfo.formValues.ctaId !== ctaId) {
      setResetEditor(true)
      setScribanValues(formValues.template)
      setRegionByPayout(props.ctaInfo.formValues.groupRegionByPayout)
      setPayoutException(props.ctaInfo.formValues.payoutException)
    } else if (
      props.ctaInfo.formValues.leagueId !== leagueId ||
      props.ctaInfo.formValues.organizationId !== organizationId
    ) {
      setResetEditor(true)
      setScribanValues(formValues.template)
    } else {
      setResetEditor(false)
    }
  }, [props.ctaInfo.formValues, resetEditor])

  useEffect(() => {
    let newAcctInfo = props.accountInfo
    setAccountInfo(newAcctInfo)
  }, [props.accountInfo])

  useEffect(() => {
    if (!isDependencyLoaded) {
      getCTAPreviewDependency()
    }
  }, [])

  useEffect(() => {
    if (resetPayouts) {
      setResetPayouts(false)
    }
  }, [resetPayouts])

  useEffect(() => {
    setPayoutException(formValues.payoutException)
  }, [formValues.payoutException])

  useEffect(() => {
    setExceptionPayouts(formValues.justPayoutsGrouped)
  }, [formValues.justPayoutsGrouped])

  useEffect(() => {
    //console.log("change in appliedExceptions")
    if (appliedExceptions) {
      setAppliedExceptions(false)
    }
  }, [appliedExceptions])

  const handlePreview = () => {
    const templateString = `${ctaPreviewDependency.dependency} ${template}`

    const previewRequest = {
      templateString,
      useCache: false,
      excludeDependencies: true
    }
    const getTemplatePreview =
      NarrativePreviewDAO.evaluateSentence(previewRequest)
    getTemplatePreview.then(evaluatedTemplate => {
      setTemplatePreview(evaluatedTemplate)
    })
  }

  const [leagues] = useState(props.leagues)
  const [orgs] = useState(props.orgs)
  const [accountInfo, setAccountInfo] = useState(props.accountInfo)
  const noOwner = {
    name: "NONE",
    firstName: "NONE",
    lastName: "",
    id: null
  }
  const sortedAccounts = [noOwner].concat(
    accountInfo.sort((a, b) => {
      // Use toUpperCase() to ignore character casing
      const nameA = `${a.firstName} ${a.lastName}`.toUpperCase()
      const nameB = `${b.firstName} ${b.lastName}`.toUpperCase()
      let comparison = 0
      if (nameA > nameB) {
        comparison = 1
      } else if (nameA < nameB) {
        comparison = -1
      }
      return comparison
    })
  )

  const conversionType = isConversionPercentPayOut ? "Percent" : "Fee"
  const isEditingPayout =
    regionByPayout &&
    regionByPayout.length &&
    regionByPayout[regionByPayout.length - 1] &&
    regionByPayout[regionByPayout.length - 1][0] &&
    !regionByPayout[regionByPayout.length - 1][0].id
      ? true
      : !checkIfWeNeedToDisablePayoutExceptions()
      ? false
      : true

  let allUSA = []
  if (myFormValues.region.includes(_USA_REGION)) {
    //USA
    formOptions.regions.forEach(itm => {
      if (itm.id !== _USA_REGION) {
        allUSA.push(itm.id)
      }
    })
  }

  return (
    <div>
      {!resetEditor && (
        <div>
          <AppBar position="sticky">
            <ToolBar className={classes.ctaToolbar}>
              {!!ctaId
                ? `CTA: ${ctaInfo.organizationName} > ${ctaInfo.leagueName} > ${ctaId}`
                : `You are creating a new CTA`}
            </ToolBar>
          </AppBar>
          <Grid className={classes.CTAGrid}>
            <Paper>
              <GridItem xs={12}>
                <Card elevation={0}>
                  <CardHeader className={classes.CTATitle}>CTAId</CardHeader>
                  <CardContent className={classes.collapseContent}>
                    <GridItem xs={4} lg={3}>
                      <Autocomplete
                        className={classes.ctaTop}
                        size="small"
                        fullWidth
                        options={sortedAccounts}
                        required={true}
                        getOptionLabel={option =>
                          `${option.firstName} ${option.lastName}`
                        }
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="OWNER"
                            variant="outlined"
                            key={params.id}
                            value={params.name}
                          />
                        )}
                        value={
                          accountInfo.find(acct => acct.id === owner) ?? noOwner
                        }
                        onChange={(event, newValue, reason) => {
                          handleAutocompleteChange(
                            event,
                            newValue,
                            reason,
                            "owner"
                          )
                        }}
                      />
                    </GridItem>
                    <GridItem xs={3} variant="outlined">
                      <CTAStatusTypes
                        value={status || ""}
                        onChange={e => {
                          const newFormValues = {
                            ...myFormValues,
                            status: e,
                            disableSaveButton: false,
                            editingContract: true
                          }
                          setMyFormValues(newFormValues)
                        }}
                      />
                    </GridItem>
                  </CardContent>
                </Card>
              </GridItem>
              <Grid>
                <Card elevation={0}>
                  <Typography className={classes.CTATitle}>CTA Info</Typography>
                  <FormControl
                    margin="normal"
                    fullWidth
                    className={classes.infoCard}
                  >
                    {/* the field below will be fetch from tree by user */}
                    <GridItem xs={3}>
                      {!!ctaId && (
                        <TextField
                          label="CTA ID"
                          id="ctaId"
                          variant="outlined"
                          value={ctaId}
                          fullWidth={true}
                        />
                      )}
                      {!ctaId && (
                        <TextField
                          label="NEW CTA"
                          id="ctaId"
                          variant="outlined"
                          fullWidth={true}
                          disabled={true}
                        />
                      )}
                    </GridItem>
                    <GridItem xs>
                      <Autocomplete
                        fullWidth
                        options={formOptions.orgs.sort(function (a, b) {
                          return a.name && a.name.localeCompare(b.name)
                        })}
                        required={true}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="ORGANIZATION"
                            variant="outlined"
                            key={params.id}
                            value={params.name}
                            required={true}
                            error={ctaFormErrors.organizationId}
                            helperText={
                              ctaFormErrors.organizationId
                                ? "Organization is Required"
                                : ""
                            }
                          />
                        )}
                        value={orgs.find(org => org.id === organizationId)}
                        onChange={(event, newValue, reason) => {
                          handleAutocompleteChangeOrg(
                            event,
                            newValue,
                            reason,
                            "organizationId"
                          )
                        }}
                        onBlur={() => handleInputValidation("organizationId")}
                        disabled={false}
                      />
                    </GridItem>
                    <GridItem xs>
                      <Autocomplete
                        id="leagueId"
                        options={formOptions.leagues}
                        required={true}
                        getOptionLabel={option => option.acronym}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="LEAGUE"
                            required={true}
                            error={ctaFormErrors.leagueId}
                            helperText={
                              ctaFormErrors.leagueId ? "League is Required" : ""
                            }
                            key={params.id}
                            value={params.name}
                          />
                        )}
                        value={leagues.find(league => league.id === leagueId)}
                        onChange={(event, newValue, reason) => {
                          handleAutocompleteChange(
                            event,
                            newValue,
                            reason,
                            "leagueId"
                          )
                        }}
                        onBlur={() => handleInputValidation("leagueId")}
                      />
                    </GridItem>
                    <GridItem xs>
                      <Autocomplete
                        multiple={true}
                        className={classes.ctaRegion}
                        limitTags={3}
                        disableCloseOnSelect
                        options={formOptions.regions}
                        variant="outlined"
                        value={formOptions.regions.filter(rgn => {
                          let match = false
                          region.forEach(itm => {
                            if (allUSA.length) {
                              if (itm === rgn.id && itm === _USA_REGION) {
                                match = true
                              }
                            } else {
                              if (itm === rgn.id) {
                                match = true
                              }
                            }
                          })
                          return match
                        })}
                        getOptionLabel={option => option.geoNameAdmin1Code}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              checked={selected}
                            />
                            {option.geoNameAdmin1Code}
                          </li>
                        )}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="REGION"
                            required={true}
                            error={ctaFormErrors.region}
                            helperText={
                              ctaFormErrors.region ? "Region is Required" : ""
                            }
                          />
                        )}
                        onChange={(event, newValue, reason) => {
                          handleAutoCompleteMultiChange(
                            event,
                            newValue,
                            reason,
                            "region"
                          )
                        }}
                        onBlur={() => {
                          handleInputValidation("region")
                        }}
                      />
                    </GridItem>
                    <GridItem xs>
                      <Autocomplete
                        className={classes.inputCTAField}
                        id="campaigns"
                        fullWidth={true}
                        value={formOptions.campaigns.find(
                          campaign => campaign.id === callToActionCampaignId
                        )}
                        onChange={(event, newValue, reason) => {
                          if (typeof newValue === "string" && newValue !== "") {
                            // timeout to avoid instant validation of the dialog's form.
                            setTimeout(() => {
                              setToggleOpen(true)
                              setDialogValue({
                                title: newValue
                              })
                            })
                          } else if (newValue && newValue.inputValue) {
                            setToggleOpen(true)
                            setDialogValue({
                              title: newValue.inputValue
                            })
                          } else {
                            handleAutocompleteChange(
                              event,
                              newValue,
                              reason,
                              "callToActionCampaignId"
                            )
                          }
                        }}
                        filterOptions={(options, params) => {
                          // eslint-disable-next-line no-undef
                          const filtered = filter(options, params)
                          const { inputValue } = params
                          // Suggest the creation of a new value
                          const isExisting = options.some(
                            option => inputValue === option.name
                          )
                          if (inputValue !== "" && !isExisting) {
                            filtered.push({
                              inputValue: params.inputValue,
                              title: `Press ENTER to Create "${params.inputValue}"`
                            })
                          }

                          return filtered
                        }}
                        options={formOptions.campaigns}
                        getOptionLabel={option => {
                          // e.g value selected with enter, right from the input
                          if (typeof option === "string") {
                            return option
                          }
                          if (option.title) {
                            return option.title
                          }
                          if (option.id && option.name) {
                            return `${option.id} - ${option.name}`
                          }
                          return option.name
                        }}
                        freeSolo={true}
                        handleHomeEndKeys
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="CTA CAMPAIGN NAME"
                            required={true}
                            error={ctaFormErrors.callToActionCampaignId}
                            helperText={
                              ctaFormErrors.callToActionCampaignId
                                ? "Campaign Name is Required"
                                : ""
                            }
                          />
                        )}
                        onBlur={() =>
                          handleInputValidation("callToActionCampaignId")
                        }
                      />
                      <Dialog
                        open={toggleOpen}
                        onClose={handleCloseCampaign}
                        aria-labelledby="form-dialog-title"
                      >
                        <form onSubmit={handleCampaignSubmit}>
                          <DialogTitle id="form-dialog-title">
                            Enter New Campaign Name
                          </DialogTitle>
                          <DialogContent>
                            <TextField
                              autoFocus
                              variant="outlined"
                              margin="dense"
                              id="name"
                              value={dialogValue.title}
                              onChange={event =>
                                setDialogValue({
                                  ...dialogValue,
                                  title: event.target.value
                                })
                              }
                              label="Campaign Name"
                              type="text"
                            />
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={handleCloseCampaign}
                              color="secondary"
                            >
                              Cancel
                            </Button>
                            <Button
                              type="submit"
                              color="primary"
                              enabled={allowSaveCampaign}
                            >
                              {/* put to db */}
                              Add
                            </Button>
                          </DialogActions>
                        </form>
                      </Dialog>
                    </GridItem>
                  </FormControl>
                </Card>
              </Grid>
              <Grid>
                <Card elevation={0}>
                  <CardContent
                    className={classes.monetizationCard}
                    style={{ padding: "0" }}
                  >
                    <GridItem xs={6}>
                      <Typography className={classes.monetizationHeader}>
                        Monetization Details
                        <IconButton
                          onClick={handleClickOpen}
                          className={classes.CTAmodalIcon}
                          size="large"
                        >
                          <InfoIcon variant="outlined" color="primary">
                            Open alert dialog
                          </InfoIcon>
                        </IconButton>
                        <Dialog open={open} onClose={handleClose}>
                          <DialogTitle id="alert-dialog-title">
                            {"Company vs. Affiliate"}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              A <strong>Company</strong> denotes the “parent”
                              group that the offer is about (ie GameSys owns
                              Bally and is the company for all Bally CTAs).
                            </DialogContentText>
                            <DialogContentText>
                              An <strong>Affiliate</strong> owns the CTA link
                              and the offer based upon contractual agreements
                              with those Companies.
                            </DialogContentText>
                          </DialogContent>
                        </Dialog>
                      </Typography>
                    </GridItem>
                  </CardContent>
                  <CardContent className={classes.monetizationCard}>
                    <GridItem xs>
                      <Autocomplete
                        className={classes.autocompleteCTA}
                        options={formOptions.companies || []}
                        variant="outlined"
                        getOptionLabel={option => option.name}
                        value={formOptions.companies.find(
                          company => company.id === companyId
                        )}
                        error={
                          !formOptions.companies &&
                          formOptions.companies.length === 0
                        }
                        onChange={(event, newValue, reason) => {
                          handleAutocompleteChange(
                            event,
                            newValue,
                            reason,
                            "companyId"
                          )
                        }}
                        onInputChange={(event, newValue, reason) => {
                          if (reason === "clear" || reason === "") {
                            handleAutocompleteChange(null)
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            required={true}
                            error={ctaFormErrors.companyId}
                            helperText={
                              ctaFormErrors.companyId
                                ? "Company is Required"
                                : ""
                            }
                            label="COMPANY"
                            placeholder=""
                          />
                        )}
                        onBlur={() => handleInputValidation("companyId")}
                      />
                    </GridItem>
                    <GridItem xs>
                      <Autocomplete
                        className={classes.autocompleteCTA}
                        style={{ backgroundColor: "white" }}
                        id="callToActionAffiliateId"
                        options={filteredAffiliates}
                        variant="outlined"
                        getOptionLabel={option => option.name}
                        value={
                          formOptions.affiliates.find(
                            affiliate =>
                              Number(affiliate.id) ===
                              Number(callToActionAffiliateId)
                          ) || null
                        }
                        onChange={(event, newValue, reason) => {
                          handleAutocompleteChangeAffiliate(
                            event,
                            newValue,
                            reason,
                            "callToActionAffiliateId"
                          )
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            required={true}
                            error={ctaFormErrors.callToActionAffiliateId}
                            helperText={
                              ctaFormErrors.callToActionAffiliateId
                                ? "Affiliate is Required"
                                : ""
                            }
                            label="AFFILIATE"
                          />
                        )}
                        onBlur={() =>
                          handleInputValidation("callToActionAffiliateId")
                        }
                      />
                    </GridItem>
                    <GridItem xs={2}>
                      <FormLabel>Conversion Type</FormLabel>
                      <RadioGroup
                        className={classes.conversionType}
                        row
                        aria-labelledby="demo-form-control-label-placement"
                        name="controlled-radio-buttons-group"
                        value={conversionType}
                        onChange={handleRadioChange(
                          "isConversionPercentPayOut"
                        )}
                      >
                        <FormControlLabel
                          value="Fee"
                          control={<Radio />}
                          label="$"
                          labelPlacement="start"
                        />
                        <FormControlLabel
                          value="Percent"
                          control={<Radio />}
                          label="%"
                          labelPlacement="start"
                        />
                      </RadioGroup>
                    </GridItem>
                    <GridItem xs>
                      <TextField
                        className={classes.autocompleteCTA}
                        type="number"
                        value={
                          callToActionPayOff === 0
                            ? callToActionPayOff.toString()
                            : callToActionPayOff
                        }
                        variant="outlined"
                        fullWidth={true}
                        label="CONVERSION PAYOUT"
                        decimalscale={2}
                        required={true}
                        error={ctaFormErrors.callToActionPayOff}
                        helperText={
                          ctaFormErrors.callToActionPayOff
                            ? "Payout Amount is Required"
                            : ""
                        }
                        onChange={handleCtaChangeNumber("callToActionPayOff")}
                        onBlur={() => {
                          handleInputValidation("callToActionPayOff")
                          exceptionPayouts[0] = callToActionPayOff
                          setExceptionPayouts(exceptionPayouts)
                        }}
                        InputLabelProps={{ style: { pointerEvents: "auto" } }}
                        InputProps={
                          !isConversionPercentPayOut
                            ? {
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    tabIndex={-1}
                                  >
                                    $
                                  </InputAdornment>
                                )
                              }
                            : {
                                endAdornment: (
                                  <InputAdornment
                                    position="start"
                                    tabIndex={-1}
                                  >
                                    %
                                  </InputAdornment>
                                )
                              }
                        }
                      />
                    </GridItem>
                    <GridItem xs={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="payoutException"
                            checked={payoutException}
                            color="primary"
                            onChange={e => {
                              if (!callToActionPayOff) {
                                alert(
                                  'CTA Payout can not be "0" if adding exceptions.'
                                )
                                e.preventDefault()
                              } else if (
                                //regionByPayout.length < 2 &&
                                region.length < 2 &&
                                !region.includes(_USA_REGION) &&
                                e.target.checked
                              ) {
                                alert(
                                  "You must selected Regions before you can add Payout Exceptions"
                                )
                                e.preventDefault()
                              } else if (
                                regionByPayout.length < 2 &&
                                e.target.checked
                              ) {
                                createGroupPayoutExceptions()
                                e.preventDefault()
                              } else {
                                if (
                                  payoutException &&
                                  payoutExceptionsExist()
                                ) {
                                  if (
                                    window.confirm(
                                      `Do you want to remove all payout exceptions?`
                                    )
                                  ) {
                                    setPayoutException(e.target.checked)
                                    removePayoutExceptions()
                                  }
                                } else {
                                  setPayoutException(e.target.checked)
                                }
                              }
                            }}
                          />
                        }
                        label="Payout Exception"
                      />
                    </GridItem>
                    <GridItem xs={2}>
                      <TextField
                        className={classes.inputCTAField}
                        type="number"
                        id="prioritizationOverride"
                        variant="outlined"
                        fullWidth={true}
                        value={
                          prioritizationOverride === 0
                            ? prioritizationOverride.toString()
                            : prioritizationOverride
                        }
                        onChange={handleCtaChangeNumber(
                          "prioritizationOverride"
                        )}
                        label="PRIORITIZATION"
                        InputLabelProps={{
                          shrink:
                            prioritizationOverride === 0
                              ? prioritizationOverride.toString()
                              : prioritizationOverride
                        }}
                      />
                    </GridItem>
                  </CardContent>
                </Card>
                {payoutException && (
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary
                      className={classes.accordionSummary}
                      expandIcon={
                        <ExpandMoreIcon className={classes.accordionIcon} />
                      }
                      aria-controls="accordion-contentheader"
                      id="accordion-header"
                    >
                      <Typography>
                        Payout Exception By Region
                        {haveEditedPayoutExceptions && (
                          <span
                            style={{
                              color: "#4d9ab5",
                              display: "inline-block",
                              padding: "0 0 0 15px",
                              fontWeight: "bold"
                            }}
                          >
                            After Applying Update, Save CTA to Save Changes.
                          </span>
                        )}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.payoutDetails}>
                      {!resetPayouts &&
                        regionByPayout &&
                        regionByPayout.map(
                          (itm, i) =>
                            i > 0 && (
                              <CTAPayoutExceptionMulti
                                key={i}
                                allRegions={formOptions.regions || []}
                                chosenRegions={
                                  allUSA.length
                                    ? allUSA
                                    : myFormValues.region || []
                                }
                                isConversionPercentPayOut={
                                  isConversionPercentPayOut
                                }
                                myDetails={itm}
                                ctaId={ctaId}
                                addFormField={() => {
                                  if (!isEditingPayout) {
                                    createGroupPayoutExceptions()
                                  }
                                }}
                                removeFormField={index => {
                                  removeGroupPayoutExceptions(index)
                                }}
                                myIndex={i}
                                isNew={itm[0] && itm[0].isNew}
                                existingPayouts={groupRegionByPayout}
                                exceptionPayouts={exceptionPayouts || []}
                                regionByPayout={regionByPayout}
                                existingPayoutsInDB={
                                  formValues.regionsAllParams
                                }
                                exceptionsBeingEdited={isEditingPayout}
                                nonExceptionPayout={callToActionPayOff}
                                defaultPayout={
                                  i === 0
                                    ? callToActionPayOff === 0
                                      ? callToActionPayOff.toString()
                                      : callToActionPayOff
                                    : exceptionPayouts[i]
                                }
                                updateSelectedRegions={(
                                  vals,
                                  oldVals,
                                  payout,
                                  multiIndex
                                ) => {
                                  updateSelectedRegions(
                                    vals,
                                    oldVals,
                                    payout,
                                    multiIndex
                                  )
                                }}
                                updateSelectedRegionsOnBlur={(
                                  curDetails,
                                  index
                                ) => {
                                  updateSelectedRegionsOnBlur(curDetails, index)
                                }}
                                updatePayouts={(value, index) => {
                                  let tmp = exceptionPayouts
                                  if (tmp.length > index) {
                                    tmp[index] = value
                                  } else {
                                    for (
                                      let i = tmp.length;
                                      i < index + 1;
                                      i += 1
                                    ) {
                                      if (index === i) {
                                        tmp.push(value)
                                      } else {
                                        tmp.push()
                                      }
                                    }
                                  }
                                  setExceptionPayouts(tmp)
                                }}
                                payoutExceptionsExist={() =>
                                  payoutExceptionsExist()
                                }
                                editingException={(index, mode) => {
                                  if (!haveEditedPayoutExceptions) {
                                    setHaveEditedPayoutExceptions(true)
                                  }
                                  if (mode) {
                                    let tmp =
                                      exceptionsBeingEdited.indexOf(index)
                                    if (tmp === -1) {
                                      exceptionsBeingEdited.push(index)
                                      setExceptionsBeingEdited(
                                        exceptionsBeingEdited
                                      )
                                    }
                                  } else {
                                    let tmp =
                                      exceptionsBeingEdited.indexOf(index)
                                    if (tmp !== -1) {
                                      exceptionsBeingEdited.splice(tmp, 1)
                                      setExceptionsBeingEdited(
                                        exceptionsBeingEdited
                                      )
                                    }
                                  }
                                  if (resetPayoutExceptions) {
                                    setResetPayoutExceptions(false)
                                  }
                                }}
                                resetPayoutExceptions={resetPayoutExceptions}
                              />
                            )
                        )}
                    </AccordionDetails>
                  </Accordion>
                )}
              </Grid>
              <Grid>
                <Card elevation={0}>
                  <Typography className={classes.CTATitle}>
                    CTA Timing
                  </Typography>
                  <CardContent className={classes.infoCard}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="en"
                    >
                      <GridItem xs={12} sm={6}>
                        <DatePicker
                          autoOk
                          id="startDate"
                          variant="inline"
                          inputVariant="outlined"
                          label="Start Date"
                          format="MM/DD/YYYY"
                          value={dayjs(startDate)}
                          slotProps={{
                            position: "end",
                            textField: {
                              clearable: true
                            }
                          }}
                          onBlur={() => handleInputValidation("startDate")}
                          error={ctaFormErrors.startDate}
                          helperText={
                            ctaFormErrors.startDate
                              ? "Start Date is Required"
                              : ""
                          }
                          onChange={date => handleDateChange(date, "startDate")}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <DatePicker
                          id="endDate"
                          inputVariant="outlined"
                          label="End Date"
                          format="MM/DD/YYYY"
                          value={dayjs(endDate) || null}
                          slotProps={{
                            position: "end",
                            textField: {
                              error: false,
                              clearable: true
                            }
                          }}
                          onChange={date => handleDateChange(date, "endDate")}
                        />
                      </GridItem>
                    </LocalizationProvider>
                  </CardContent>
                </Card>
                <Divider className={classes.CTADivide} />
                <Card elevation={0} className={classes.detailsCard}>
                  <Typography>CTA Details</Typography>
                  <CardContent className={classes.infoCard}>
                    <Grid item xs md={4}>
                      <TextField
                        label="CTA NAME"
                        value={callToActionName}
                        onChange={handleCtaChange("callToActionName")}
                        variant="outlined"
                        required={true}
                        error={ctaFormErrors.callToActionName}
                        helperText={
                          ctaFormErrors.callToActionName
                            ? "CTA Name is Required"
                            : ""
                        }
                        style={{ minWidth: "350px" }}
                        onBlur={() => handleInputValidation("callToActionName")}
                        InputLabelProps={{
                          shrink: callToActionName
                        }}
                      />
                    </Grid>
                    <GridItem xs md={4}>
                      <Autocomplete
                        options={formOptions.formFactorTags}
                        getOptionLabel={option => option.name}
                        value={
                          formOptions.formFactorTags.find(
                            tags => tags.id === formFactor
                          ) || null
                        }
                        onChange={(event, newValue, reason) => {
                          handleAutocompleteChange(
                            event,
                            newValue,
                            reason,
                            "formFactor"
                          )
                        }}
                        onBlur={() => handleInputValidation("formFactor")}
                        renderInput={params => (
                          <TextField
                            {...params}
                            id="formfactor"
                            label="FORM FACTOR"
                            required={true}
                            error={ctaFormErrors.formFactor}
                            helperText={
                              ctaFormErrors.formFactor
                                ? "Form Factor is Required"
                                : ""
                            }
                            variant="outlined"
                            style={{ minWidth: "250px" }}
                          />
                        )}
                      />
                    </GridItem>
                    <GridItem xs md={4}>
                      <Autocomplete
                        options={formOptions.ctaTypes}
                        getOptionLabel={option => option.name}
                        value={
                          formOptions.ctaTypes.find(
                            ctaTypes => ctaTypes.id === callToActionTypeId
                          ) || null
                        }
                        onChange={(event, newValue, reason) => {
                          handleAutocompleteChange(
                            event,
                            newValue,
                            reason,
                            "callToActionTypeId"
                          )
                        }}
                        onBlur={() =>
                          handleInputValidation("callToActionTypeId")
                        }
                        renderInput={params => (
                          <TextField
                            {...params}
                            id="ctatypes"
                            label="CTA TYPES"
                            required={true}
                            error={ctaFormErrors.callToActionTypeId}
                            helperText={
                              ctaFormErrors.callToActionTypeId
                                ? "CTA Type is Required"
                                : ""
                            }
                            variant="outlined"
                            style={{ minWidth: "250px" }}
                          />
                        )}
                      />
                    </GridItem>
                    <GridItem xs>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="isArchived"
                            checked={isArchived || ""}
                            color="primary"
                            onChange={handleCheckboxChange}
                          />
                        }
                        label="Archive"
                      />
                    </GridItem>
                  </CardContent>
                </Card>
                <Card>
                  <CardContent className={classes.infoCard}>
                    <GridItem xs={12}>
                      <TextField
                        required={true}
                        error={ctaFormErrors.affiliateLink}
                        helperText={
                          ctaFormErrors.affiliateLink &&
                          ctaFormErrors.affiliateLinkMsg
                        }
                        label="AFFILIATE LINK"
                        variant="outlined"
                        value={affiliateLink}
                        onChange={handleCtaChange("affiliateLink")}
                        style={{ minWidth: "28rem" }}
                        onBlur={() => handleInputValidation("affiliateLink")}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <TextField
                        required={true}
                        error={ctaFormErrors.callToActionText}
                        helperText={
                          ctaFormErrors.callToActionText
                            ? "CTA Text is Required"
                            : ""
                        }
                        label="CTA TEXT"
                        variant="outlined"
                        value={callToActionText}
                        onChange={handleCtaChange("callToActionText")}
                        style={{ minWidth: "28rem" }}
                        fullWidth={true}
                        onBlur={() => handleInputValidation("callToActionText")}
                      />
                    </GridItem>
                  </CardContent>
                </Card>
                <Card>
                  <CardContent className={classes.infoCard}>
                    <GridItem xs={12}>
                      <TextField
                        required={false}
                        error={ctaFormErrors.assetLink}
                        helperText={
                          ctaFormErrors.assetLink && ctaFormErrors.assetLinkMsg
                        }
                        label="ASSET LINK"
                        variant="outlined"
                        value={assetLink}
                        onChange={handleCtaChange("assetLink")}
                        style={{ minWidth: "28rem" }}
                        onBlur={() => handleInputValidation("assetLink")}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <TextField
                        required={false}
                        label="PROMO TEXT"
                        variant="outlined"
                        value={promoText}
                        onChange={handleCtaChange("promoText")}
                        style={{ minWidth: "28rem" }}
                        fullWidth={true}
                      />
                    </GridItem>
                  </CardContent>
                </Card>
              </Grid>
              <Grid>
                <Box>
                  {!ctaFormErrors.template && (
                    <CodeEditor
                      withTabs
                      template={template}
                      handleChange={editor => {
                        if (editor && editor.getValue()) {
                          const editorContent = editor.getValue()
                          setScribanValues(editorContent)
                          if (editorContent) {
                            const errorObj = {
                              ...ctaFormErrors,
                              template: false
                            }
                            setCtaFormErrors(errorObj)
                          }
                        } else {
                          const errorObj = {
                            ...ctaFormErrors,
                            template: true
                          }
                          setCtaFormErrors(errorObj)
                          if (scribanValues) {
                            setScribanValues(formValues.template)
                          }
                        }
                      }}
                    />
                  )}
                </Box>
                {ctaFormErrors.template && (
                  <div
                    style={{
                      border: "1px solid red"
                    }}
                  >
                    <CodeEditor
                      withTabs
                      template={template}
                      handleChange={editor => {
                        if (editor && editor.getValue()) {
                          const editorContent = editor.getValue()
                          setScribanValues(editorContent)
                          if (editorContent) {
                            const errorObj = {
                              ...ctaFormErrors,
                              template: false,
                              validationHasError: false
                            }
                            setCtaFormErrors(errorObj)
                          }
                        } else {
                          const errorObj = {
                            ...ctaFormErrors,
                            template: true
                          }
                          setCtaFormErrors(errorObj)
                          if (scribanValues) {
                            setScribanValues("")
                          }
                        }
                      }}
                    />
                    <FormHelperText className={classes.requiredMsg}>
                      The Code Editor must have content
                    </FormHelperText>
                  </div>
                )}
              </Grid>
              {isSaving && <LinearProgress />}
              <Grid className={classes.CTAButton}>
                <>
                  <Button
                    className={classes.buttonStyle}
                    variant="contained"
                    size="medium"
                    color="secondary"
                    onClick={() => handlePreview()}
                  >
                    Preview
                  </Button>
                  <Button
                    className={classes.buttonStyle}
                    variant="contained"
                    size="medium"
                    color="secondary"
                    onClick={() => {
                      props.cancelFormEditsAndReload({ ctaid: ctaId })
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classes.buttonStyle}
                    variant="contained"
                    size="medium"
                    color="primary"
                    type="submit"
                    onClick={() => {
                      handleSaveForm()
                    }}
                    disabled={
                      disableSaveButton ||
                      isSaving ||
                      ctaFormErrors.validationHasError
                    }
                  >
                    Save
                  </Button>
                </>
              </Grid>
              <React.Fragment>
                <div style={{ marginTop: "10px" }} />
                <WordCounter title="Metrics" inputString={templatePreview} />
                <Card>
                  <Grid container spacing={1} item xs={12}>
                    <Grid item>
                      <div
                        style={{
                          marginTop: "10px",
                          fontSize: "14px",
                          position: "relative"
                        }}
                        dangerouslySetInnerHTML={{
                          __html:
                            templatePreview &&
                            templatePreview.replace(/apxh:/g, "")
                        }}
                      />
                    </Grid>
                  </Grid>
                </Card>
              </React.Fragment>
            </Paper>
          </Grid>
          {showSuccessModal && (
            <Modal
              elevation={6}
              open={showSuccessModal}
              onClose={() => {
                setShowSuccessModal(false)
                if (createdNewCTA) {
                  setCreatedNewCTA(false)
                  props.reload(newParamsNewCTA.obj, newParamsNewCTA.orgId)
                }
              }}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div className={classes.successModal}>
                <Typography
                  style={{
                    textAlign: "center",
                    fontSize: "16px",
                    fontWeight: "bold",
                    paddingTop: "30px"
                  }}
                >
                  <CheckCircleIcon
                    style={{ color: "#66bb6a", fontSize: "40px" }}
                  />
                  {responseMessage}
                </Typography>
              </div>
            </Modal>
          )}
          {showFailedModal && (
            <Modal
              elevation={6}
              open={showFailedModal}
              onClose={() => {
                setShowFailedModal(false)
              }}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div className={classes.successModal}>
                <Typography
                  style={{
                    textAlign: "center",
                    fontSize: "16px",
                    fontWeight: "bold",
                    paddingTop: "30px"
                  }}
                >
                  <ReportIcon style={{ color: "#a31545", fontSize: "40px" }} />
                  {responseMessage}
                </Typography>
              </div>
            </Modal>
          )}
        </div>
      )}
    </div>
  )
}
CTAEditor.propTypes = {
  form: PropTypes.object,
  formErrors: PropTypes.object,
  organization: PropTypes.object,
  saveCtaForm: PropTypes.func,
  cancelFormEditsAndReload: PropTypes.func,
  whichOne: PropTypes.string,
  hasChildren: PropTypes.array
}

export default CTAEditor
