import { useContext, useMemo, useState } from "react"
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField
} from "@mui/material"
import { Autocomplete } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {
  Battery20,
  BatteryUnknown,
  BlockOutlined,
  BookmarkBorderOutlined,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  EmojiObjectsOutlined as PlannedIcon
} from "@mui/icons-material"
import { useObserver } from "mobx-react"

import { useStore } from "contexts/rootContext"
import Accordion from "components/Accordion/Accordion"
import Button from "components/CustomButtons/Button"
import ContentBlock from "../Types/ContentBlock"
import ContentSaturationParagraphModal from "components/ContentSaturation/ParagraphModal"
import ParagraphType from "../Types/ParagraphType"
import RenderType from "../Types/RenderType"
import SaturationChip from "components/ContentSaturation/SaturationChip"
import StatusType from "../Types/StatusType"
import TriggerTypeManager from "../Data/TriggerTypeManager"

import {
  defaultFormState,
  PUBLISHED_STATUS_ID
} from "hooks/narratives/useParagraphForm"
import { NarrativeContext } from "contexts/narrative-context"

const useStyles = makeStyles(() => ({
  acOption: {
    '&[aria-selected="true"]': {
      backgroundColor: "#bdbdbd"
    }
  },
  selected: {
    "&.MuiListItem-root.Mui-selected": {
      backgroundColor: "#bdbdbd"
    }
  },
  saturationContainer: {
    display: "flex",
    justifyContent: "flex-end",
    position: "relative",
    marginBottom: "-16px"
  },
  saturationComponent: {
    position: "relative",
    marginTop: "-10px",
    display: "inline-block"
  },
  statusIcon: {
    fontSize: "1em",
    width: "28px",
    height: "25px",
    color: "#144E68",
    marginRight: "10px",
    marginBottom: "-8px"
  },
  paragraphTargetGrid: {
    display: "flex",
    alignItems: "center"
  }
}))

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    narrativeParagraphTagsSummary:
      store.narrativeStore.narrativeParagraphTagsSummary,
    narrativeParagraphTags: store.narrativeStore.narrativeParagraphTags
  }))
}

const ParagraphEditorAdvanced = ({
  allowPositionChange = false,
  renderTypeExists = false,
  showLibraryParagraphFields = false,
  initialParagraph,
  currentNarrativeId,
  primaryTags = [],
  secondaryTags = [],
  formFactorTags = [],
  thirdPartySpecificTags = [],
  handleCancelForm,
  hitExitParagraph,
  createMode = false,
  form
}) => {
  const { narrative } = useContext(NarrativeContext)
  const { isLibrary = false, outlineBlocks = [] } = narrative
  const { narrativeParagraphTags = [], narrativeParagraphTagsSummary = [] } =
    useStoreData()

  const paragraph_Id = initialParagraph?.id

  const currentNarrativeMatchesParagraph =
    currentNarrativeId === initialParagraph?.narrative_Id

  const [includeAllStatuses, setIncludeAllStatuses] = useState(false)
  const [libraryParagraphTagsSelected, setLibraryParagraphTagsSelected] =
    useState([])

  const {
    formState,
    formErrors,
    setFormValueBase,
    setFormValue,
    setCheckboxFormValue,
    handleSubmit,
    isLoading
  } = form

  const classes = useStyles()

  const {
    contentBlock,
    libraryParagraph_Id,
    isArchived,
    isRequiredBlock,
    narrativeOutlineBlockId,
    position,
    name,
    comments,
    libraryBlockPositionsFilter,
    formFactorTagId,
    primaryTagId,
    renderType,
    secondaryTagIds,
    status_Id,
    thirdPartyTagIds,
    triggerType,
    triggerWeight
  } = formState

  const handleChangeLibraryParagraph = e => {
    const startingName = `Lib. Para. ${e.target?.value}`
    if (!name) {
      setFormValueBase("name", startingName)
    }
    setFormValue("libraryParagraph_Id")(e)
  }

  const handleChangeLibraryBlockPositionsFilter = e => {
    const myTotal = e.target?.value
    if (myTotal) {
      //Check for any character not a number or "," OR two straight ",," and reject it
      if (
        myTotal.replace(/\d|,/g, "") ||
        myTotal.match(/,,/) ||
        myTotal.match(/^,/)
      ) {
        return
      }
    }
    setFormValueBase("libraryBlockPositionsFilter", myTotal)
  }

  const handleSubmitForm = async () => {
    try {
      await handleSubmit()
    } catch (err) {
      alert(err.message)
    }
  }

  const handleChangeTriggerType = ({ key = "" }) => {
    setFormValueBase("triggerType", key)
  }

  const labelStatusIcon = status => {
    switch (status) {
      case 1:
        return <Battery20 />
      case 2:
        return <BatteryUnknown />
      case 4:
        return <PlannedIcon />
      case 5:
        return <BookmarkBorderOutlined />
      case 6:
        return <BlockOutlined />
      default:
        return null
    }
  }

  const filteredLibraryParagraphs = useMemo(() => {
    let filteredLibraryParagraphsById = []
    narrativeParagraphTags?.forEach(itm => {
      libraryParagraphTagsSelected?.forEach((itm2, index) => {
        if (itm.tagId === itm2.tagId && itm.tagTypeId === itm2.tagTypeId) {
          if (
            !filteredLibraryParagraphsById.includes({
              id: itm.paragraphId,
              index
            })
          ) {
            filteredLibraryParagraphsById.push({ id: itm.paragraphId, index })
          }
        }
      })
    })

    const tmpFilteredLibraryParagraphs = filteredLibraryParagraphsById.length
      ? narrative?.libraryParagraphs?.filter(itm => {
          let found = []
          filteredLibraryParagraphsById.forEach(itm2 => {
            if (itm.id === itm2.id) {
              if (!found.includes(itm2.index)) {
                found.push(itm2.index)
              }
            }
          })
          return found.length === libraryParagraphTagsSelected.length
        })
      : narrative?.libraryParagraphs || []

    //If includeAllStatuses is Checked, show all filtered Paragraphs.  if not, only show ones with Status_Id = 3,
    //unless the selected libraryParagraph has another status
    return tmpFilteredLibraryParagraphs.filter(itm => {
      if (narrative?.isLibrary) {
        return true
      } else if (!includeAllStatuses) {
        if (libraryParagraph_Id === itm.id) {
          return true
        } else {
          return itm.status_Id === PUBLISHED_STATUS_ID
        }
      } else {
        return true
      }
    })
  }, [libraryParagraphTagsSelected])

  return (
    <Box style={{ padding: "0 20px" }}>
      <Grid container spacing={2}>
        {outlineBlocks && outlineBlocks.length > 0 && (
          <Grid item xs={12} sm={4}>
            <FormControl margin="normal" fullWidth>
              <InputLabel>Narrative Outline Block</InputLabel>
              <Select
                fullWidth={true}
                type="select"
                name="narrativeOutlineBlockId"
                id="narrativeOutlineBlockId"
                aria-label="ParagraphEditor Narrative Outline Block"
                label="Narrative Outline Block"
                value={narrativeOutlineBlockId}
                onChange={setFormValue("narrativeOutlineBlockId")}
              >
                <MenuItem
                  key="none"
                  value={defaultFormState.narrativeOutlineBlockId}
                  classes={{
                    selected: classes.selected
                  }}
                >
                  None
                </MenuItem>
                {outlineBlocks.map(n => (
                  <MenuItem
                    key={n.id}
                    value={n.id}
                    classes={{ selected: classes.selected }}
                  >
                    {n.id} - {n.contentSection}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {allowPositionChange ? (
          <Grid item xs={12} sm={2}>
            <FormControl margin="normal" fullWidth>
              <TextField
                type="number"
                name="positioninput"
                aria-label="ParagraphEditor Position Input"
                label="Position"
                value={position}
                onChange={setFormValue("position")}
              />
            </FormControl>
          </Grid>
        ) : null}
        <Grid item xs={12} sm={outlineBlocks?.length > 0 ? 6 : 9}>
          <FormControl margin="normal" fullWidth>
            <TextField
              label="Name"
              type="text"
              aria-label="ParagraphEditor Name Input"
              name="nameInput"
              value={name}
              onChange={setFormValue("name")}
            />
          </FormControl>
        </Grid>
        <Grid item sm={2}>
          {createMode ? (
            <StatusType
              aria-label="ParagraphEditor Status Type"
              value={status_Id}
              disabled={!currentNarrativeMatchesParagraph}
              onChange={val => {
                setFormValueBase("status_Id", val)
              }}
              isLibrary={narrative.isLibrary}
              paragraphType={primaryTagId}
              classes={{ selected: classes.selected }}
            />
          ) : (
            <FormControlLabel
              control={
                <Checkbox
                  aria-label="ParagraphEditor Archive Checkbox"
                  checked={isArchived}
                  onChange={setCheckboxFormValue("isArchived")}
                />
              }
              label="Archive"
            />
          )}
        </Grid>
      </Grid>
      <Grid className={classes.paragraphTargetGrid} container spacing={2}>
        <Grid item sm={3}>
          <ContentBlock
            aria-label="ParagraphEditor Contentblock Input"
            value={contentBlock}
            onChange={val => setFormValueBase("contentBlock", val)}
            required={contentBlock === "library" ? false : true}
            classes={{ selected: classes.selected }}
          />
        </Grid>
        {renderTypeExists && (
          <Grid item sm={2}>
            <RenderType
              aria-label="ParagraphEditor Render Type"
              classes={{ selected: classes.selected }}
              margin="normal"
              fullWidth
              value={renderType}
              onChange={val => setFormValueBase("renderType", val)}
            />
          </Grid>
        )}
        {showLibraryParagraphFields && (
          <Grid item sm={renderTypeExists ? 4 : 6}>
            <Autocomplete
              style={{ marginTop: "16px", marginBottom: "8px" }}
              multiple={true}
              fullWidth={true}
              limitTags={4}
              aria-label="ParagraphEditor Library Paragraph Tag"
              name="libraryParagraphTagsSelected"
              id="libraryParagraphTagsSelected"
              value={narrativeParagraphTagsSummary.filter(rgn => {
                let match = false
                libraryParagraphTagsSelected &&
                  libraryParagraphTagsSelected?.forEach(itm => {
                    if (
                      itm.tagId === rgn.tagId &&
                      itm.tagTypeId === rgn.tagTypeId
                    ) {
                      match = true
                    }
                  })
                return match
              })}
              options={narrativeParagraphTagsSummary}
              onChange={(event, newValue, reason) => {
                setLibraryParagraphTagsSelected(newValue)
              }}
              getOptionLabel={option =>
                `${option.tagTypeId} - ${option.tagName}`
              }
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={
                      <CheckBoxIcon
                        fontSize="small"
                        className={classes.checkRegion}
                      />
                    }
                    checked={selected}
                  />
                  {option.tagTypeId} - {option.tagName}
                </li>
              )}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.tagId === value.tagId : false
              }
              renderInput={params => (
                <TextField {...params} label="FILTER BY" />
              )}
            />
          </Grid>
        )}
        <Grid item sm={4}>
          <div className={classes.saturationContainer}>
            <div className={classes.saturationComponent}>
              <ContentSaturationParagraphModal />
            </div>
          </div>
          <FormControl margin="normal" fullWidth>
            <InputLabel>Library Paragraph</InputLabel>
            <Select
              fullWidth={true}
              type="select"
              aria-label="ParagraphEditor Library Paragraph Selector"
              label="Library Paragraph"
              name="libraryParagraph_Id"
              id="libraryParagraph_Id"
              value={libraryParagraph_Id}
              onChange={handleChangeLibraryParagraph}
            >
              <MenuItem
                key="none"
                value={defaultFormState.libraryParagraph_Id}
                classes={{ selected: classes.selected }}
              >
                None
              </MenuItem>
              {filteredLibraryParagraphs?.map(lp => (
                <MenuItem
                  key={lp.id}
                  value={lp.id}
                  classes={{ selected: classes.selected }}
                >
                  {showLibraryParagraphFields && (
                    <Icon className={classes.statusIcon}>
                      {labelStatusIcon(lp.status_Id)}
                    </Icon>
                  )}
                  {lp.id} - {lp.name}
                  <SaturationChip
                    saturation={lp.saturation}
                    utilization={lp.utilization}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {!isLibrary && (
          <Grid
            item
            sm={2}
            style={{
              padding: "25px 15px 0 15px",
              minWidth: "105px"
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  id="includeAllStatuses"
                  aria-label="ParagraphEditor Include All Statuses Library Paragraph Selector"
                  checked={includeAllStatuses}
                  onChange={e => setIncludeAllStatuses(e.target.checked)}
                />
              }
              label="Include All Statuses"
            />
          </Grid>
        )}
        <Grid item sm={2}>
          <FormControl margin="normal" fullWidth>
            <TextField
              aria-label="ParagraphEditor Block Positions Filter"
              label="Block Positions Filter"
              type="text"
              name="libraryBlockPositionsFilter"
              value={libraryBlockPositionsFilter}
              onChange={handleChangeLibraryBlockPositionsFilter}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={2}>
        <Grid item xs sm>
          <FormControl margin="normal" fullWidth>
            <FormControlLabel
              style={{
                width: "100%",
                marginTop: "0",
                marginBottom: "0",
                paddingTop: "10px"
              }}
              control={
                <Checkbox
                  aria-label="ParagraphEditor Required Checkbox"
                  checked={isRequiredBlock}
                  onChange={setCheckboxFormValue("isRequiredBlock")}
                  disabled={narrative.buildFromOutline}
                />
              }
              label="Required"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={8}>
          <TriggerTypeManager
            aria-label="ParagraphEditor Triggertype Manager"
            value={triggerType}
            onChange={handleChangeTriggerType}
            classes={{ selected: classes.selected }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl margin="normal" fullWidth>
            <TextField
              aria-label="ParagraphEditor Trigger Weight"
              label="Trigger Weight"
              type="number"
              name="triggerweight"
              value={triggerWeight}
              onChange={setFormValue("triggerWeight")}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="flex-end">
        {isLibrary && (
          <>
            <Grid item xs={12} sm={3}>
              <ParagraphType
                aria-label="ParagraphEditor Paragraph Type"
                value={primaryTagId}
                onChange={val => setFormValueBase("primaryTagId", val)}
                required={true}
                isLibrary={isLibrary}
                primaryTags={primaryTags}
                classes={{ option: classes.acOption }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Autocomplete
                fullWidth
                multiple
                id="secondaryTagIds"
                classes={{ option: classes.acOption }}
                options={secondaryTags}
                getOptionLabel={option => option.name}
                aria-label="ParagraphEditor Secondary Tags Selector"
                value={secondaryTags?.filter(({ id: tagId }) =>
                  secondaryTagIds?.some(id => id === tagId)
                )}
                onChange={(e, value) => {
                  setFormValueBase(
                    "secondaryTagIds",
                    value.map(tag => tag.id)
                  )
                }}
                style={{ margin: "1rem 0" }}
                renderInput={params => (
                  <TextField
                    {...params}
                    error={formErrors.secondaryTagIds}
                    helperText={
                      formErrors.secondaryTagIds &&
                      `"Secondary Tag" is required prior to publishing!`
                    }
                    required={true}
                    aria-label="ParagraphEditor Secondary Tags (Intent)"
                    label="Secondary Tags (Intent)"
                  />
                )}
                ChipProps={{
                  size: "small"
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Autocomplete
                fullWidth
                id="formFactorTagId"
                classes={{ option: classes.acOption }}
                options={formFactorTags}
                getOptionLabel={option => option.name}
                aria-label="ParagraphEditor Form Factor Selector"
                value={formFactorTags.find(tag => tag.id === formFactorTagId)}
                onChange={(e, value) => {
                  let valx = value && value.id ? value.id : ""
                  setFormValueBase("formFactorTagId", valx)
                }}
                style={{ margin: "1rem 0" }}
                renderInput={params => (
                  <TextField
                    {...params}
                    required={true}
                    label="Form Factor Tag (Style)"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Autocomplete
                fullWidth
                multiple
                id="thirdPartyTagIds"
                aria-label="ParagraphEditor Third Party Tag Selector"
                classes={{ option: classes.acOption }}
                options={thirdPartySpecificTags}
                getOptionLabel={option => option.name}
                value={thirdPartySpecificTags?.filter(({ id: tagId }) =>
                  thirdPartyTagIds?.some(id => id === tagId)
                )}
                onChange={(e, value) => {
                  setFormValueBase(
                    "thirdPartyTagIds",
                    value.map(tag => tag.id)
                  )
                }}
                style={{ margin: "1rem 0" }}
                renderInput={params => (
                  <TextField
                    {...params}
                    aria-label="ParagraphEditor Third Party Tags (Org/Data Source)"
                    label="Third Party Tags (Org/Data Source)"
                    error={formErrors.thirdPartyTagIds}
                    helperText={
                      formErrors.thirdPartyTagIds &&
                      `"Third Party Tag" is required prior to publishing!`
                    }
                  />
                )}
                ChipProps={{
                  size: "small"
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Accordion
            collapses={[
              {
                title: "Comments",
                content: (
                  <TextField
                    id="comments"
                    aria-label="ParagraphEditor Comments Box"
                    fullWidth={true}
                    multiline={true}
                    minRows={3}
                    value={comments}
                    onChange={setFormValue("comments")}
                  />
                )
              }
            ]}
          />
        </Grid>
      </Grid>
      {isLoading && <LinearProgress />}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "8px 0"
        }}
      >
        <Button
          variant="contained"
          size="sm"
          color="info"
          aria-label="ParagraphEditor Cancel Editing"
          onClick={() => {
            handleCancelForm()
            if (hitExitParagraph) {
              //In Search Editor, close on cancel
              hitExitParagraph()
            }
          }}
        >
          Cancel
        </Button>
        {!currentNarrativeId || currentNarrativeMatchesParagraph ? (
          <Button
            variant="contained"
            size="sm"
            color="primary"
            aria-label="ParagraphEditor Save Pagraph"
            onClick={handleSubmitForm}
          >
            Save
          </Button>
        ) : (
          <Button
            title="Opens new window"
            aria-label="ParagraphEditor View Pagraph In Library"
            onClick={() => {
              window.open(
                `/portal/narrative/${initialParagraph.narrative_Id}/edit?view=paragraphid-${paragraph_Id}`,
                "_blank"
              )
            }}
            color="primary"
          >
            View In Library
          </Button>
        )}
      </div>
    </Box>
  )
}

export default ParagraphEditorAdvanced
