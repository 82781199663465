import React, { useEffect, useState } from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import SplitterLayout from "react-splitter-layout"
import { makeStyles } from "@mui/styles"
import Cta_v2DAO from "daos/cta_v2DAO"
import Grid from "@mui/material/Grid"
import GridItem from "components/Grid/GridItem"
import { AppBar } from "@mui/material"
import Button from "components/CustomButtons/Button"
import { Add } from "@mui/icons-material"
import OfferLinkBlocks from "components/CTAManagerV2/OfferLinkBlocks"
import CTAPreviewDependencyBlocks from "components/CTAManagerV2/CTAPreviewDependencyBlocks"
import CTAOfferLinkForm from "components/CTAEditorV2/CTAOfferLinkForm"
import CTAPreviewDependencyEditorV2 from "components/CTAEditorV2/CTAPreviewDependencyEditorV2"
import queryString from "query-string"
import CTAVariantForm from "components/CTAEditorV2/CTAVariantForm"
import CTALinksUpload from "components/CustomUpload/CTALinksUpload"

const useStyles = makeStyles(theme => ({
  root: {
    display: "block",
    position: "relative",
    height: "calc(200vh - 133px)",
    "& .highlighted": {
      backgroundColor: "#ffeaa7",
      color: "#e17055",
      boxShadow: "-3px 0 0 #ffeaa7, 3px 0 0 #ffeaa7" // Creates an illusion of a wider span, while not affecting the actual width.
    },
    marginBottom: "75px"
  },
  appBar: {
    border: "1px solid #ddd",
    zIndex: "5",
    padding: theme.spacing(2)
  },
  appBarGrid: {
    display: "flex"
  },
  explorerContainer: {
    padding: "40px 0 0 0",
    background: "#fff",
    "& > div.circular": {
      flex: 1,
      textAlign: "center"
    }
  },
  renderButtons: {
    color: "#000000",
    display: "inline-block",
    textAlign: "left",
    padding: "0px",
    margin: "5px 0 0 15px"
  },
  explorerDrawer: {
    background: "#fff",
    border: "1px solid rgb(212, 212, 212)",
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
    "& > div": {
      flex: 1
    }
  },
  editorDrawer: {
    background: "#fff",
    border: "1px solid rgb(212, 212, 212)",
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
    "& > div": {
      flex: 1
    }
  }
}))

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    getCTAManagerConfig: store.appConfigurationStore.getCTAManagerConfig,
    shouldDisplayCTAManagerV2:
      store.appConfigurationStore.shouldDisplayCTAManagerV2,
    isDependencyLoaded: store.ctaPreviewDependencyStore.isDependencyLoadedV2,
    getPreviewDependencyByIntent:
      store.ctaPreviewDependencyStore.getPreviewDependencyByIntent,
    getVariantDisplayStyle: store.ctav2Store.getVariantDisplayStyle,
    getVariantVerticals: store.ctav2Store.getVariantVerticals,
    getLinkInfo: store.ctav2Store.getLinkInfo,
    linkInfo: store.ctav2Store.linkInfo
  }))
}

export default function CTAManagerOfferLinks() {
  const classes = useStyles()
  const {
    getCTAManagerConfig,
    shouldDisplayCTAManagerV2,
    getVariantDisplayStyle,
    getVariantVerticals,
    getLinkInfo,
    linkInfo
  } = useStoreData()
  const [showOfferLinkForm, setShowOfferLinkForm] = useState(false)
  const [linkInformation, setLinkInformation] = useState({})
  const [resetLinkForm, setResetLinkForm] = useState(false)
  const [addLink, setAddLink] = useState(false)
  const [addRightClickLinkInfo, setAddRightClickLinkInfo] = useState({})
  const [theLinkId, setTheLinkId] = useState(0)
  const [editVariant, setEditVariant] = useState(null)
  const [showVariant, setShowVariant] = useState(false)
  const [transitionToNewVariant, setTransitionToNewVariant] = useState(false)
  const [cycleAddVariantForm, setCycleAddVariantForm] = useState(false)
  const [cycleAddLinkForm, setCycleAddLinkForm] = useState(false)
  const [rightClickLinkInfo, setRightClickLinkInfo] = useState({})
  const [showPreviewDependency, setShowPreviewDependency] = useState(false)
  const [isNewVariant, setIsNewVariant] = useState(false)
  const [openAddVariantForm, setOpenAddVariantForm] = useState(false)
  const [showLinksUploads, setShowLinksUploads] = useState(true)

  const getPreviewDependencyByIntentId = () => {
    setShowPreviewDependency(true)
  }

  const fetchVariantById = async variantId => {
    try {
      const data = await Cta_v2DAO.getVariantById(variantId)
      setEditVariant(data)
      setShowVariant(true)
      setShowOfferLinkForm(false)
      setOpenAddVariantForm(false)
      setShowPreviewDependency(false)
    } catch (error) {
      console.error("Error fetching variant data:", error)
    }
  }
  const handleNewVariantCreated = newVariantId => {
    setEditVariant(newVariantId)
  }

  const queryParams = queryString.parse(window.location.search.toLowerCase())
  const linkQS = Number(queryParams["linkid"])
  const variantQS = Number(queryParams["variantid"])
  const previewDependencyQS = queryParams["previewdependency"]

  useEffect(() => {
    const fetchConfig = async () => {
      await getCTAManagerConfig()
    }
    fetchConfig()
    getVariantDisplayStyle(1) //IntentId =1 for Offers
    getVariantVerticals()
  }, [])

  useEffect(() => {
    if (cycleAddVariantForm && openAddVariantForm) {
      setOpenAddVariantForm(false)
    } else if (cycleAddVariantForm && !openAddVariantForm) {
      setCycleAddVariantForm(false)
      setOpenAddVariantForm(true)
    }
  }, [cycleAddVariantForm, openAddVariantForm])

  useEffect(() => {
    if (cycleAddVariantForm && showVariant) {
      setShowVariant(false)
    } else if (cycleAddVariantForm && !showVariant) {
      setCycleAddVariantForm(false)
      setShowVariant(true)
    }
  }, [cycleAddVariantForm, showVariant])

  useEffect(() => {
    if (cycleAddLinkForm && showOfferLinkForm) {
      setShowOfferLinkForm(false)
    } else if (cycleAddLinkForm && !showOfferLinkForm) {
      setCycleAddLinkForm(false)
      setShowOfferLinkForm(true)
    }
  }, [cycleAddLinkForm, showOfferLinkForm])

  useEffect(() => {
    if (previewDependencyQS) {
      setShowPreviewDependency(true)
      setShowOfferLinkForm(false)
    } else if (linkQS) {
      if (variantQS) {
        if (editVariant?.id !== variantQS) {
          setLinkInformation({
            id: linkQS,
            navigate: true,
            variantid: variantQS
          })
          fetchVariantById(variantQS)
          getLinkInfo(linkQS)
        }
      } else {
        setLinkInformation({ id: linkQS, navigate: true })
        setShowOfferLinkForm(true)
        setShowPreviewDependency(false)
        setTheLinkId(linkQS)
      }
    }
  }, [shouldDisplayCTAManagerV2])

  useEffect(() => {
    if (resetLinkForm === true && showOfferLinkForm === false) {
      setResetLinkForm(false)
      setShowOfferLinkForm(true)
    }
  }, [resetLinkForm, showOfferLinkForm])

  useEffect(() => {
    if (addLink) {
      if (showOfferLinkForm) {
        setShowOfferLinkForm(false)
      } else {
        setShowOfferLinkForm(true)
        if (Object.keys(addRightClickLinkInfo).length > 0) {
          setLinkInformation(addRightClickLinkInfo)
        } else {
          setLinkInformation({ new: true })
        }
        setAddLink(false)
        setAddRightClickLinkInfo({})
      }
    }
  }, [addLink, showOfferLinkForm])

  useEffect(() => {
    if (transitionToNewVariant && !openAddVariantForm) {
      setOpenAddVariantForm(true)
      setTransitionToNewVariant(false)
    }
  }, [transitionToNewVariant])

  useEffect(() => {
    if (showLinksUploads === false) {
      setShowLinksUploads(true)
    }
  }, [showLinksUploads])

  if (!shouldDisplayCTAManagerV2) {
    return "Under Construction"
  }

  return (
    <div
      className={classes.root}
      style={{ backgroundColor: "#efefef" }}
      aria-label="CTA Manager Offer Links View"
    >
      <AppBar
        className={classes.appBar}
        position="sticky"
        color="default"
        aria-label="CtaManager AppBar"
      >
        <Grid className={classes.appBarGrid}>
          <GridItem xs={6}>
            <Button
              color="primary"
              style={{ width: "150px" }}
              startIcon={<Add />}
              aria-label="CtaManager Btn AddLink"
              onClick={() => {
                setAddLink(true)
                setShowPreviewDependency(false)
                setShowVariant(false)
                setOpenAddVariantForm(false)
              }}
            >
              Add Link
            </Button>
          </GridItem>
          <GridItem xs={6}>
            {showLinksUploads && (
              <CTALinksUpload
                resetButton={() => {
                  setShowLinksUploads(false)
                }}
                linkType={"Offer"}
              />
            )}
          </GridItem>
        </Grid>
      </AppBar>

      <SplitterLayout
        horizontal
        percentage
        primaryInitialSize={25}
        secondaryInitialSize={75}
      >
        <div className={classes.explorerDrawer}>
          <div className={classes.explorerContainer}>
            <CTAPreviewDependencyBlocks
              goToPreviewDependencyBlocks={() => {
                setShowOfferLinkForm(false)
                getPreviewDependencyByIntentId()
                setShowVariant(false)
              }}
              whichLinkType={"Offer"}
            />
            <OfferLinkBlocks
              aria-label="CTAv2 Offer Links Navigation Block"
              fetchVariantById={fetchVariantById}
              setOpenAddVariantForm={val => {
                if (openAddVariantForm === true && val === true) {
                  setOpenAddVariantForm(false)
                  setTransitionToNewVariant(true)
                  setIsNewVariant(true)
                } else {
                  setOpenAddVariantForm(val)
                  setIsNewVariant(true)
                }
              }}
              setShowOfferLinkForm={val => {
                setShowOfferLinkForm(val)
              }}
              setShowVariant={val => {
                setShowVariant(val)
                setShowPreviewDependency(false)
              }}
              goToEditor={variant => {
                setLinkInformation(variant)
                setShowOfferLinkForm(true)
                setShowPreviewDependency(false)
                setOpenAddVariantForm(false)
              }}
              getLinkInfoUrl={item => {
                if (item?.id) {
                  getLinkInfo(item.id)
                }
              }}
              goToOfferLinkForm={itm => {
                if (itm) {
                  if (itm.isNewRightClickLink) {
                    setAddLink(true)
                    setShowPreviewDependency(false)
                    setShowVariant(false)
                    setAddRightClickLinkInfo(itm)
                  } else {
                    setLinkInformation(itm)
                    setShowOfferLinkForm(true)
                    setShowPreviewDependency(false)
                    setShowVariant(false)
                  }
                } else {
                  setShowOfferLinkForm(false)
                  setShowPreviewDependency(false)
                  setLinkInformation({})
                }
                setOpenAddVariantForm(false)
              }}
              addRightClickLinkId={val => {
                if (val) {
                  setRightClickLinkInfo({ id: val, intentId: 1 })
                  getLinkInfo(val)
                }
              }}
              cycleAddVariantForm={type => {
                setCycleAddVariantForm(true)
              }}
              autoLoadId={theLinkId}
              linkInformation={linkInformation}
            />
          </div>
        </div>
        <div className={classes.editorDrawer}>
          {showOfferLinkForm && (
            <CTAOfferLinkForm
              aria-label="CTAv2 Offer Link Form"
              hasNoContent={false}
              closePage={() => {
                setShowOfferLinkForm(false)
              }}
              source={"offerLinkForm"}
              linkInformation={linkInformation}
              cancelFormEditsAndReload={info => {
                if (info?.linkId) {
                  setShowOfferLinkForm(false)
                  setResetLinkForm(true)
                } else {
                  setShowOfferLinkForm(false)
                  setResetLinkForm(true)
                }
              }}
              openOfferLinkBlocks={() => {}}
            />
          )}
          {showPreviewDependency && (
            <CTAPreviewDependencyEditorV2
              aria-label="CTAv2 Preview Dependency Editor"
              intentId={1}
            />
          )}
          {openAddVariantForm && (
            <CTAVariantForm
              aria-label="CTAv2 Offer Link Form, new Variant"
              linkInfo={linkInfo}
              rightClickLinkInfo={rightClickLinkInfo}
              intentId={1}
              newVariant={isNewVariant}
              onVariantCreated={handleNewVariantCreated}
              fetchVariantById={fetchVariantById}
              closePage={() => {
                setOpenAddVariantForm(false)
              }}
            />
          )}
          {showVariant && (
            <CTAVariantForm
              aria-label="CTAv2 Offer Link Form, existing Variant"
              linkInfo={linkInfo}
              intentId={1}
              newVariant={isNewVariant}
              variantInfo={editVariant}
              onClose={() => setEditVariant(null)}
              fetchVariantById={fetchVariantById}
              closePage={() => {
                setShowVariant(false)
              }}
            />
          )}
        </div>
      </SplitterLayout>
    </div>
  )
}
