import React from "react"
import withStyles from "@mui/styles/withStyles"
import PropTypes from "prop-types"

import Modal from "@mui/material/Modal"
import Check from "@mui/icons-material/CheckCircleOutline"
import Report from "@mui/icons-material/Report"

import Button from "components/CustomButtons/Button.jsx"
import UploadButton from "components/CustomButtons/UploadButton.jsx"
import * as XLSX from "xlsx"
import OrganizationDAO from "daos/organizationDAO"
import TableSimpleResults from "components/NarrativeAdmin/Data/TableSimpleResults"
import {
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@mui/material"
import { urlValidation } from "tools/ValidationHelpers"
import InfoDialog from "components/CustomDialogs/InfoDialog"
import Link from "@mui/material/Link"
import Grid from "@mui/material/Grid"

const styles = theme => ({
  resultsModal: {
    position: "absolute",
    _width: "90vw",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "45%",
    left: "45%",
    transform: "translate(-45%, -45%)",
    textAlign: "center",
    maxWidth: "90vw",
    "& span.stk": {
      display: "inline-block",
      backgroundColor: "#eee",
      textDecoration: "line-through"
    },
    "& #initial-modal-description": {
      textAlign: "left"
    }
  },
  infoModal: {
    position: "absolute",
    width: "25vw",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "45%",
    left: "55%",
    transform: "translate(-45%, -45%)",
    textAlign: "left"
  },
  requiredMsg: {
    color: "#c00",
    fontWeight: "bold",
    backgroundColor: "#f7f7f7"
  },
  deliverablesTopofTable: {
    display: "flex"
  },
  bulkUploadList: {
    "&.MuiListItem-dense": {
      paddingTop: "1px",
      paddingBottom: "0px"
    },
    "&.MuiListItem-gutters": {
      paddingLeft: "8px"
    },
    marginLeft: "25px",
    display: "list-item",
    listStyle: "disc"
  }
})

function UploadDeliverables(props) {
  const { classes } = props
  const [resetButton, setResetButton] = React.useState(false)
  const [showResultsModal, setShowResultsModal] = React.useState(false)
  const [resultTableData, setResultTableData] = React.useState([])
  const descriptorString = "Bulk Upload Deliverables"

  const [resultString, setResultString] = React.useState("")
  const [resultMessagingString, setResultMessagingString] = React.useState("")

  const [resultObject, setResultObject] = React.useState([])

  const [malformedTSV, setMalformedTSV] = React.useState(false)
  const [notCompliantTSV, setNotCompliantTSV] = React.useState(false)
  const [postingDeliverables, setPostingDeliverables] = React.useState(false)
  const [hideActionButtons, setHideActionButtons] = React.useState(false)
  const [allInError, setAllInError] = React.useState(false)

  const columns = [
    {
      Header: "Client URL Structure",
      accessor: "clientUrlStructure",
      width: 150
    },
    {
      Header: "Name of Deliverable",
      accessor: "nameOfDeliverable",
      width: 250
    },
    {
      Header: "Vertical",
      accessor: "vertical",
      width: 100
    },
    {
      Header: "What?",
      accessor: "what",
      width: 150
    },
    {
      Header: "Who?",
      accessor: "who",
      width: 150
    },
    {
      Header: "When?",
      accessor: "when",
      width: 150
    },
    {
      Header: "Property",
      accessor: "property",
      width: 175
    },
    {
      Header: "League",
      accessor: "league",
      width: 150
    },
    {
      Header: "League Conference",
      accessor: "leagueConference",
      width: 250
    },
    {
      Header: "League Division",
      accessor: "leagueDivision",
      width: 100
    },
    {
      Header: "League Team",
      accessor: "leagueTeam",
      width: 350
    }
  ]

  const handleFileUpload = e => {
    //In here we read a Tab Seperated Values (TSV) file and when it loads convert it to CSV
    const file = e.target.files[0] //Why not CSV to begin with? Because we have commas (,) in the data and that blows up a CSV
    const reader = new FileReader() //So the code below will detect the commas and escape those values before adding the commas back in to create the CSV
    reader.onload = evt => {
      // Parse data
      const bstr = evt.target.result
      const wb = XLSX.read(bstr, { type: "binary" })
      // Get first worksheet
      const wsname = wb.SheetNames[0]
      let ws = wb.Sheets[wsname]
      Object.keys(ws).forEach(key => {
        Object.keys(ws[key]).forEach(inkey => {
          if (inkey === "v") {
            if (typeof ws[key][inkey] === "string") {
              let tmp = ws[key][inkey]
              tmp = tmp.replace(/,/g, "__COMMASPOT__")
              ws[key][inkey] = tmp
            }
          }
        })
      })
      // Convert array of arrays, will account for any commas in the data that need to be escaped before conversion to CSV
      const dataResult = XLSX.utils.sheet_to_csv(ws, { header: 1 })
      processTSVdata(dataResult)
    }
    reader.readAsBinaryString(file)
  }
  const validateTSV = myList => {
    const mandatory = [
      "Name of Deliverable",
      "Vertical",
      "What",
      "Who",
      "When",
      "League"
    ]
    const cantHaveBoth = ["League Conference", "League Division"]
    const issues = []
    myList.forEach((itm, index) => {
      const mand = [...mandatory]
      for (const key in itm) {
        const loc = mandatory.indexOf(key)
        if (loc > -1) {
          if (!!itm[key].trim()) {
            mand.splice(loc, 1)
          } else if (mandatory.includes(key)) {
            let found = false
            issues.forEach(itm => {
              if (itm.index === index) {
                itm.position += `, ${key}`
                found = true
              }
            })
            if (!found) {
              issues.push({ index: index, position: `'${key}' is missing.` })
            }
          }
        } else if (mandatory.includes(key)) {
          let found = false
          issues.forEach(itm => {
            if (itm.index === index) {
              itm.position += `, ${key}`
              found = true
            }
          })
          if (!found) {
            issues.push({ index: index, position: `'${key}' is missing.` })
          }
        } else if (key === "Client URL Structure") {
          // validate the url only it exists
          if (!!itm[key] && !urlValidation(itm[key])) {
            issues.push({
              index,
              position: `'Client URL Structure' is invalid.`
            })
          }
        }
      }
      let hasBoth = true
      cantHaveBoth.forEach(nm => {
        if (!itm[nm].trim()) {
          hasBoth = false
        }
      })
      if (hasBoth) {
        if (issues.length) {
          let found = false
          issues.forEach(itm => {
            if (itm.index === index) {
              itm.position += ", has both Conference and Division"
              found = true
            }
          })
          if (!found) {
            issues.push({
              index: index,
              position: "Has both Conference and Division"
            })
          }
        } else {
          issues.push({
            index: index,
            position: "Has both Conference and Division"
          })
        }
      }
    })
    if (issues.length) {
      if (issues.length === myList.length) {
        setResultString("Step 1 - File Validation Review")
        // setResultObject(
        //   ["All the entries are in error, cannot save anything"].concat(missing)
        // )
        setResultObject(issues)
        setAllInError(true)
        setResultMessagingString("All entries failed file validation.")
      } else {
        setResultString("Step 1 - File Validation Review")
        setResultObject(issues)
        setResultMessagingString(
          `${myList.length - issues.length} deliverables passed validation. ${
            issues.length
          }  deliverables failed validation.`
        )
      }
      setShowResultsModal(true)
      setNotCompliantTSV(true)
    } else {
      // All the entries are valid
      setResultString("Step 1 - File Validation Review")
      setResultMessagingString("File is valid.")
      setShowResultsModal(true)
      setNotCompliantTSV(false)
    }
    return issues
  }

  const callAPI = (numberToAdd, list, columns) => {
    const issues = validateTSV(list)
    const newList = []
    list.forEach((itm, i) => {
      const obj = {}
      //obj.index = i + 1
      columns.forEach(itm2 => {
        if (itm[itm2.name] || itm[itm2.name] === "") {
          obj[itm2.selector] = itm[itm2.name]
        }
      })
      issues.forEach(errs => {
        if (errs.index === i) {
          obj.hasError = true
        }
      })
      obj.createdByAccountId = props.createdByAccountId
      obj.modifiedByAccountId = props.createdByAccountId
      obj.property = obj.property || props.organizationName
      obj.contractId = props.contractId
      newList.push(obj)
    })
    setResultTableData(newList)
  }

  const uploadDeliverableContent = () => {
    setResultString("API processing...")
    setResultMessagingString("")
    setPostingDeliverables(true)
    setHideActionButtons(true)
    const resultsToBeSent = resultTableData.filter(itm => !itm.hasError)
    const results =
      OrganizationDAO.postContractDeliverableBulkUpload(resultsToBeSent)
    results.then(response => {
      const { responseMessage, content } = response
      setResultString("Step 2 - Deliverable Creation Status:")
      setResultMessagingString(responseMessage)
      const failedEntries = []
      content.forEach(itm => {
        failedEntries.push(itm.rowNumber)
      })
      let validRows = 0
      resultsToBeSent.forEach((data, index) => {
        if (failedEntries.includes(index + 1)) {
          data.hasError = true
        } else {
          validRows += 1
        }
        data.fromAPI = true
      })
      setResultTableData(resultsToBeSent)
      setNotCompliantTSV(false)
      setPostingDeliverables(false)
      if (validRows > 0) {
        props.uploadOfDeliverablesSucceeded()
      }
    })
  }

  const processTSVdata = dataString => {
    const dataStringLines = dataString.split(/\r\n|\n/) //split by CR/LF
    const initHeaders = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    ) //Take the first "line" and split out the header columns as our name keys
    const headers = []
    initHeaders.forEach(itm => {
      headers.push(itm.trim())
    })
    const list = []
    for (let i = 1; i < dataStringLines.length; i++) {
      dataStringLines[i] = dataStringLines[i].replace(/""/g, '"') //To remove double quotes "" to single quote "
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      )
      if (headers && row.length === headers.length) {
        const obj = {}
        for (let j = 0; j < headers.length; j++) {
          //This uses the single header values as the "name" and the specific row values as the "value"
          let columnValue = row[j] //For the properties of  "obj" which will correspond  to the name-value pairs for this row
          if (columnValue.length > 0) {
            if (columnValue[0] === '"') {
              columnValue = columnValue.substring(1, columnValue.length - 1)
            }
            if (columnValue[columnValue.length - 1] === '"') {
              columnValue = columnValue.substring(columnValue.length - 2, 1)
            }
          }
          if (headers[j]) {
            obj[headers[j]] = columnValue.replace(/__COMMASPOT__/g, ",").trim()
          }
        }

        // do not add any blank rows
        if (Object.values(obj).filter(x => x).length > 0) {
          list.push(obj)
        }
      }
    }
    // prepare columns list from headers
    const columnsList = headers.map(c => ({
      name: c,
      selector: convertToAccessor(c)
    }))
    if (list.length) {
      callAPI(list.length, list, columnsList)
    }
  }

  const convertToAccessor = val => {
    let found = val
    columns.forEach(itm => {
      if (itm.Header.replace("?", "") === val) {
        found = itm.accessor
      }
    })
    return found
  }

  const clearResultsModal = action => {
    if (postingDeliverables) {
      return
    }
    setResultMessagingString("")
    setShowResultsModal(false)
    setResultTableData([])
    setResetButton(true)
    setMalformedTSV(false)
    setPostingDeliverables(false)
    setHideActionButtons(false)
    setAllInError(false)
  }

  const buttonParams = {
    color: "primary",
    style: {
      color: "#c00",
      backgroundColor: "#2ff",
      borderRadius: "5px",
      padding: "10px"
    }
  }

  React.useEffect(() => {
    if (resetButton) {
      setResetButton(false)
    }
  }, [resetButton])

  return (
    <>
      {!resetButton && (
        <div
          style={{
            marginBottom: "0"
          }}
        >
          <Grid className={classes.deliverablesTopofTable}>
            <UploadButton
              doFileUpload={e => {
                handleFileUpload(e)
              }}
              buttonParams={buttonParams}
              acceptedSuffix={".tsv"}
              descriptorString={descriptorString}
            />
            <InfoDialog
              aria-label="contractdeliverables-bulk upload info dialog"
              content={
                <>
                  <Typography variant="h6" gutterBottom>
                    Deliverable Bulk Upload Information
                  </Typography>
                  <Typography align="left" gutterBottom>
                    <b>
                      <u>Example Bulk Upload Deliverable Template:</u> An
                      example Bulk Upload Deliverable
                    </b>{" "}
                    <Link
                      a
                      href="https://docs.google.com/spreadsheets/d/116yySsasALqozFA_IIIRTMx8l0KmSZgBcZPzO_1eVfk/edit#gid=0"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <b>
                        <u>can be found here {"  "}</u>
                      </b>
                    </Link>
                    These will need to be made into a TSV file.
                  </Typography>{" "}
                  <Typography align="left">
                    <u>
                      <b>Bulk Upload Mandatory Fields:</b>
                    </u>{" "}
                    A bulk upload entry will only validate if all mandatory
                    fields are included. These fields are:
                  </Typography>
                  <List dense={true}>
                    {[
                      "Name of Deliverable",
                      "What",
                      "Who",
                      "When",
                      "League"
                    ].map((itm, index) => (
                      <ListItem key={index} className={classes.bulkUploadList}>
                        <ListItemText>{itm}</ListItemText>
                      </ListItem>
                    ))}
                  </List>
                  <Typography align="left">
                    <u>
                      <b>Client URL Structure:</b>
                    </u>{" "}
                    Leave the Client URL Structure blank, unless the client is
                    using Published URL Verification.
                  </Typography>
                </>
              }
            />
          </Grid>
          <>
            <Modal
              open={showResultsModal}
              onClose={clearResultsModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div className={classes.resultsModal}>
                {!notCompliantTSV && !postingDeliverables && (
                  <div id="initial-modal-description">
                    <h2>{resultString}</h2>
                    <h3 style={{ margin: "0 0 10px 0" }}>
                      {resultMessagingString}
                    </h3>
                  </div>
                )}
                {notCompliantTSV && !postingDeliverables && (
                  <div id="initial-modal-description">
                    <h2>{resultString}</h2>
                    <h3 style={{ margin: "0 0 10px 0" }}>
                      {resultMessagingString}
                    </h3>
                    <ul
                      style={{
                        overflow: "auto auto",
                        maxHeight: "15vh",
                        maxWidth: "50vw"
                      }}
                    >
                      {resultObject &&
                        resultObject.map((itm, i) => (
                          <li key={i}>
                            Row #{itm.index + 1} --- Column: {itm.position}
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
                {postingDeliverables && <h2>{resultString}</h2>}
                {!postingDeliverables && (
                  <>
                    <TableSimpleResults
                      columns={[
                        {
                          Header: "Index",
                          accessor: "index",
                          width: 75,
                          Cell: ({ cell }) => (
                            <>
                              {cell?.row?.original?.fromAPI ? (
                                <div>
                                  {cell?.row?.original.hasError ? (
                                    <>
                                      <Report
                                        style={{
                                          color: "#a31545",
                                          width: "28px",
                                          height: "28px"
                                        }}
                                      />
                                      {cell?.row?.index + 1}
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <Check
                                        style={{
                                          color: "#66bb6a",
                                          width: "28px",
                                          height: "28px"
                                        }}
                                      />
                                      {cell?.row?.index + 1}
                                    </>
                                  )}
                                </div>
                              ) : (
                                <>
                                  {cell?.row?.original.hasError ? (
                                    <>
                                      <Report
                                        style={{
                                          color: "#a31545",
                                          width: "28px",
                                          height: "28px"
                                        }}
                                      />
                                      {cell?.row?.index + 1}
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <Check
                                        style={{
                                          color: "#66bb6a",
                                          width: "28px",
                                          height: "28px"
                                        }}
                                      />
                                      {cell?.row?.index + 1}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )
                        }
                      ].concat(columns)}
                      hideColumns={[]}
                      //hideColumns={["clientUrlStructure"]}
                      data={resultTableData}
                      readOnly={true}
                    />
                  </>
                )}
                {postingDeliverables && <CircularProgress size={20} />}
                {!hideActionButtons && (
                  <div style={{ textAlign: "right" }}>
                    <Button
                      onClick={() => {
                        clearResultsModal("cancel")
                      }}
                      className={props.classes.deliverablesButton}
                      variant="contained"
                      style={{ margin: "0 10px", display: "inline-block" }}
                    >
                      Cancel
                    </Button>
                    {!malformedTSV && !allInError && (
                      <Button
                        onClick={() => {
                          uploadDeliverableContent()
                        }}
                        className={props.classes.deliverablesButton}
                        variant="contained"
                        color="primary"
                        style={{ display: "inline-block" }}
                      >
                        Add Deliverables
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </Modal>
          </>
        </div>
      )}
    </>
  )
}

UploadDeliverables.propTypes = {
  reloadSchedules: PropTypes.func,
  uploadOfDeliverablesSucceeded: PropTypes.func,
  createdByAccountId: PropTypes.number,
  contractId: PropTypes.number,
  organizationName: PropTypes.string,
  classes: PropTypes.object
}
export default withStyles(styles)(UploadDeliverables)
