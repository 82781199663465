// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import ReactTable from "react-table-6"
import Assignment from "@mui/icons-material/Assignment"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon"
import CardHeader from "components/Card/CardHeader"
import matchSorter from "match-sorter"
import { globalSettings } from "variables/general"
import Helpers from "tools/Helpers.js"
import CircularProgress from "@mui/material/CircularProgress"
import Slider from "@mui/material/Slider"

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    playersList: store.graphicsToolStore.playersList,
    retrievePlayers: store.graphicsToolStore.retrievePlayers
    // isLoading: store.graphicsToolStore.isLoading
  }))
}

// React Functional Component Declaration
const ManagePlayers = () => {
  const { playersList, retrievePlayers, isLoading } = useStoreData()
  // Variable/State Declaration
  const [imageIssues, setImageIssues, imagesWidth] = useState()

  // Internal Function Declarations
  const stringFilterProps = {
    filterable: true,
    filterAll: true,
    filterMethod: ({ id, value }, rows) =>
      matchSorter(rows, value, {
        keys: [id],
        threshold: matchSorter.rankings.CONTAINS
      })
  }

  useEffect(() => {
    retrievePlayers()
  }, [])

  // Component Render
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <ReactTable
              minRows={0}
              data={playersList}
              loading={isLoading}
              noDataText={
                isLoading ? <CircularProgress /> : "No players found."
              }
              columns={[
                {
                  Header: "Person Id",
                  width: 80,
                  accessor: "personId",
                  Cell: cell => <div>{cell.original.personId}</div>,
                  ...stringFilterProps
                },
                {
                  Header: "Player Name",
                  width: 250,
                  accessor: "playerDisplayName",
                  Cell: cell => <div>{cell.original.playerDisplayName}</div>,
                  ...stringFilterProps
                },
                {
                  Header: "Group",
                  width: 300,
                  accessor: "groupingId",
                  Cell: cell => (
                    <div>
                      {Helpers.renderFriendlyTeamGroupName(
                        cell.original.groupingId
                      )}
                    </div>
                  ),
                  filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                      return true
                    }
                    return row._original.groupingId === parseInt(filter.value)
                  },
                  Filter: ({ filter, onChange }) => {
                    if (playersList === null) {
                      return null
                    }
                    const distinctGroupNames = []
                    const map = new Map()
                    for (const player of playersList) {
                      if (!map.has(player.groupingId)) {
                        map.set(player.groupingId, true)
                        distinctGroupNames.push({
                          key: player.groupingId,
                          id: player.groupingId,
                          name: Helpers.renderFriendlyTeamGroupName(
                            player.groupingId
                          )
                        })
                      }
                    }
                    // inline sort by org name
                    distinctGroupNames.sort((a, b) => {
                      const orgA = a.name.toUpperCase()
                      const orgB = b.name.toUpperCase()
                      let comparison = 0
                      if (orgA > orgB) {
                        comparison = 1
                      } else if (orgA < orgB) {
                        comparison = -1
                      }
                      return comparison
                    })
                    // build up the <select> element <option> element JSX style
                    let options = []
                    distinctGroupNames.forEach(groupName => {
                      options.push(
                        <option value={groupName.id} key={groupName.id}>
                          {groupName.name}
                        </option>
                      )
                    })
                    return (
                      <select
                        onChange={event => {
                          onChange(event.target.value)
                        }}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "all"}
                      >
                        <option value="all">Show All</option>
                        {options}
                      </select>
                    )
                  }
                },
                {
                  Header: s => (
                    <div>
                      Primary image
                      <br />
                      <small>(dark background)</small>
                    </div>
                  ),
                  Cell: cell => (
                    <div>
                      <img
                        src={`${globalSettings.apiBaseUrl}/api/sports/loadplayerimage?personid=${cell.original.personId}&h=50`}
                        alt="Player primary logo"
                        style={{ backgroundColor: "#000000" }}
                        onError={() => {
                          setImageIssues(
                            `${imageIssues}, Primary logo(dark background) for player: ${cell.original.playerDisplayName} (${cell.original.personId}) did not load.`
                          )
                        }}
                      />
                    </div>
                  ),
                  width: 150,
                  sortable: false,
                  filterable: false
                },
                {
                  Header: s => (
                    <div>
                      Primary image
                      <br />
                      <small>(light background)</small>
                    </div>
                  ),
                  Cell: cell => (
                    <div>
                      <img
                        src={`${globalSettings.apiBaseUrl}/api/sports/loadplayerimage?personid=${cell.original.personId}&h=50&isSafeForWhitebackground=true`}
                        alt="Player primary logo"
                        style={{ backgroundColor: "#ffffff" }}
                      />
                    </div>
                  ),
                  width: 150,
                  sortable: false,
                  filterable: false
                }
              ]}
              filterable={true}
              defaultPageSize={25}
              showPaginationTop={false}
              showPaginationBottom={true}
              className="-striped -highlight -scrollEntries"
              style={{
                height: `${(window.innerHeight * 0.72).toFixed(0)}px`
              }}
              defaultSorted={[
                {
                  id: "scheduledStartDateTime",
                  desc: false
                }
              ]}
            >
              {(state, makeTable) => {
                let recordsInfoText = ""

                const { filtered, pageRows, pageSize, sortedData, page } = state

                if (sortedData && sortedData.length > 0) {
                  let isFiltered = filtered.length > 0

                  let totalRecords = sortedData.length

                  let recordsCountFrom = page * pageSize + 1

                  let recordsCountTo = recordsCountFrom + pageRows.length - 1

                  if (isFiltered) {
                    recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`
                  } else {
                    recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`
                  }
                } else {
                  recordsInfoText = isLoading
                    ? "Loading players data, please wait"
                    : "No files"
                }
                return (
                  <div className="main-grid">
                    <div className="above-table text-right">
                      <div className="col-sm-12">
                        <span className="records-info">{recordsInfoText}</span>
                      </div>
                    </div>

                    {makeTable()}
                  </div>
                )
              }}
            </ReactTable>
            <div>
              {" "}
              <GridItem xs={12} sm={4} md={4}>
                Image Width ({imagesWidth}px):
                <Slider
                  value={imagesWidth}
                  onChange={(e, value) => this.onImagesWidthChange(value)}
                  aria-labelledby="input-slider"
                  max={600}
                  min={50}
                  step={10}
                  valueLabelDisplay="auto"
                />
              </GridItem>
            </div>

            <div style={{ background: "#ffd900" }}>
              Image issues: {imageIssues}
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

// PropType Declaration
ManagePlayers.propTypes = {}

// Default Export
export default ManagePlayers
