import React, { useEffect, useState } from "react"
import HighlightSpan from "../Data/HighlightSpan"

function BlockTriggerDetails(props) {
  const {
    triggerType,
    triggerIds,
    allAvailableTriggers,
    titleText,
    searchString,
    searchEditorMode
  } = props

  const [triggers, setTriggers] = useState([])
  const [inverseTriggers, setInverseTriggers] = useState([])
  const [newsTriggers, setNewsTriggers] = useState([])

  useEffect(() => {
    const activeTriggerTypes = (triggerType && triggerType.split(",")) || []
    const localTriggers = [] //Triggers a block must see to render
    const localInverseTriggers = [] //Triggers a block should not see to render
    const localNewsTriggers = [] //Triggers a topic to be based on that must see to render

    // If there are triggers to choose from and triggers to Id we sort and store them
    allAvailableTriggers &&
      activeTriggerTypes &&
      activeTriggerTypes.forEach(trigger => {
        if (trigger.trim().charAt(0) === "!") {
          const inverseTrigger = allAvailableTriggers.find(
            t => t.type === trigger.trim().substr(1)
          ) || {
            id: "",
            type: trigger.trim()
          }
          inverseTrigger && localInverseTriggers.push(inverseTrigger)
        } else if (trigger.includes("news_")) {
          const newsTrigger = allAvailableTriggers.find(
            t => t.type === trigger.trim().replace("news_", "")
          ) || {
            id: "",
            type: trigger.trim()
          }
          newsTrigger && localNewsTriggers.push(newsTrigger)
        } else {
          const triggerObject = allAvailableTriggers.find(
            t => t.type === trigger.trim()
          ) || {
            id: "",
            type: trigger.trim()
          }
          localTriggers.push(triggerObject)
        }
      })
    setTriggers(localTriggers)
    setInverseTriggers(localInverseTriggers)
    setNewsTriggers(localNewsTriggers)

    // Now, combine the lists of full trigger objects and join a new array of their ids
    const allRelatedTriggers = [
      ...localTriggers,
      ...localInverseTriggers,
      ...localNewsTriggers
    ]
    let newTriggerIds =
      allRelatedTriggers && allRelatedTriggers.map(t => t.id).join()
    if (newTriggerIds.endsWith(",")) {
      newTriggerIds = newTriggerIds.substring(0, newTriggerIds.length - 1)
    }

    //If the user passes an setTriggerIds function we run fire it on change because we dont save it here
    if (
      newTriggerIds !== triggerIds &&
      triggerType !== "" &&
      props.setTriggerIds
    ) {
      props.setTriggerIds(newTriggerIds)
    }
  }, [allAvailableTriggers, props, triggerIds, triggerType])

  return (
    <div style={{ flexGrow: 1 }} className="highlighter">
      <HighlightSpan highlight={searchString}>
        <h5>{titleText}</h5>
      </HighlightSpan>
      {triggers.length > 0 && (
        <div>
          Triggered by{" "}
          {triggers.map((trigger, index) => (
            <React.Fragment key={trigger.type}>
              <strong
                style={{
                  display: "inline-block",
                  whiteSpace: "pre-wrap"
                }}
              >
                <u
                  onClick={e => {
                    if (!searchEditorMode) {
                      setActiveTrigger(trigger)
                    } else {
                      e.preventDefault()
                    }
                  }}
                >
                  <HighlightSpan highlight={searchString}>
                    {`${trigger.id}-${trigger.type}`}
                  </HighlightSpan>
                </u>
                {triggers.length === 1 || index === triggers.length - 1
                  ? ""
                  : ", "}
              </strong>
            </React.Fragment>
          ))}
        </div>
      )}
      {inverseTriggers.length > 0 && (
        <div>
          Inverse Triggered by{" "}
          {inverseTriggers.map((trigger, index) => (
            <React.Fragment key={trigger.type}>
              <strong
                style={{
                  display: "inline-block",
                  whiteSpace: "pre-wrap"
                }}
              >
                <u
                  onClick={e => {
                    if (!searchEditorMode) {
                      setActiveTrigger(trigger)
                    } else {
                      e.preventDefault()
                    }
                  }}
                >
                  {`${trigger.id}-${trigger.type}`}
                </u>
                {inverseTriggers.length === 1 ||
                index === inverseTriggers.length - 1
                  ? ""
                  : ", "}
              </strong>
            </React.Fragment>
          ))}
        </div>
      )}
      {newsTriggers.length > 0 && (
        <div>
          News Triggered by{" "}
          {newsTriggers.map((trigger, index) => (
            <React.Fragment key={trigger.type}>
              <strong
                style={{
                  display: "inline-block",
                  whiteSpace: "pre-wrap"
                }}
              >
                <u
                  onClick={e => {
                    if (!searchEditorMode) {
                      setActiveNewsTrigger(trigger)
                    } else {
                      e.preventDefault()
                    }
                  }}
                >
                  <HighlightSpan highlight={searchString}>
                    {`${trigger.id}-${trigger.type}`}
                  </HighlightSpan>
                </u>
                {newsTriggers.length === 1 || index === newsTriggers.length - 1
                  ? ""
                  : ", "}
              </strong>
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  )

  function setActiveNewsTrigger(trigger) {
    if (trigger.id) {
      props.setActiveView(`newstriggerid-${trigger.id}`)
    } else {
      props.setActiveView(`searchtriggers-${trigger.type.replace("news_", "")}`)
    }
  }

  function setActiveTrigger(trigger) {
    if (trigger.id) {
      props.setActiveView(`triggerid-${trigger.id}`)
    } else {
      props.setActiveView(`searchtriggers-${trigger.type}`)
    }
  }
}

export default BlockTriggerDetails
