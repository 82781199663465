// pduey: a couple of public and private functions to add or remove a query string parameter + value pair.
//
// https://gist.githubusercontent.com/pduey/2764606/raw/e8b9d6099f1e4161f7dd9f81d71c2c7a1fecbd5b/querystring.js
//
// I like this implementation because it transforms the query string into a coherent hash with unique keys
// and an Array for the values, so it handles repeated query string parameter names, which are meant to be
// interpreted as Arrays, yo. The "private" functions could, of course, be handy for other uses.
//
// The function names include "search" because they are derived from window.location.search.
// Ideally, I'd attach these to the window object, as in, window.location.prototype.
//

// pduey: add a variable/value pair to the current query string and return updated href
export function search_add(name, value) {
  let new_search_hash = search_to_hash()
  if (!(decodeURIComponent(name) in new_search_hash)) {
    new_search_hash[decodeURIComponent(name)] = []
  }
  new_search_hash[decodeURIComponent(name)].push(decodeURIComponent(value))
  return hash_to_search(new_search_hash)
}
export function search_remove_all(name) {
  if (
    window.location.search === undefined ||
    window.location.search.length < 1
  ) {
    return ""
  }
  let params = new URLSearchParams(window.location.search.slice(1))
  // Delete the parameter.
  params.forEach((value, key) => {
    if (key === name) {
      params.delete(key)
    }
  })
  return params.toString()
}
export function search_remove(name, value) {
  let new_search_hash = search_to_hash()
  if (new_search_hash[name].indexOf(value) >= 0) {
    new_search_hash[name].splice(new_search_hash[name].indexOf(value), 1)
    if (new_search_hash[name].length === 0) {
      delete new_search_hash[name]
    }
  }
  return hash_to_search(new_search_hash)
}
function search_to_hash() {
  let h = {}
  if (
    window.location.search === undefined ||
    window.location.search.length < 1
  ) {
    return h
  }
  let q = window.location.search.slice(1).split("&")
  for (let i = 0; i < q.length; i++) {
    let key_val = q[i].split("=")
    // replace '+' (alt space) char explicitly since decode does not
    let hkey = decodeURIComponent(key_val[0]).replace(/\+/g, " ")
    let hval = decodeURIComponent(key_val[1]).replace(/\+/g, " ")
    if (h[hkey] === undefined) {
      h[hkey] = []
    }
    h[hkey].push(hval)
  }
  return h
}
function hash_to_search(h) {
  let search = "?"
  for (let k in h) {
    for (let i = 0; i < h[k].length; i++) {
      search += search === "?" ? "" : "&"
      search += `${encodeURIComponent(k)}=${encodeURIComponent(h[k][i])}`
    }
  }
  return search
}
