import React, { Component } from "react"
import { observer, inject } from "mobx-react"
import ReactTable from "react-table-6"
import { Link } from "react-router-dom"
import withStyles from "@mui/styles/withStyles"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon"
import CardHeader from "components/Card/CardHeader"
import { cardTitle } from "assets/jss/material-dashboard-pro-react"
import FeedControl from "components/NarrativeAdmin/Feed"
import matchSorter from "match-sorter"
import Button from "components/CustomButtons/Button"
import Tooltip from "@mui/material/Tooltip"
import { globalSettings } from "variables/general"
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import CircularProgress from "@mui/material/CircularProgress"
import RssFeedIcon from "@mui/icons-material/RssFeed"
import AuditInfo from "components/AuditInfo"
import Edit from "@mui/icons-material/Edit"
import Loop from "@mui/icons-material/Loop"
import { groupCollectionBy } from "tools/CollectionHelper"
import { IconButton, FormControlLabel, Checkbox } from "@mui/material"
import HelpOutline from "@mui/icons-material/HelpOutline"
import Helpers from "tools/Helpers.js"
import { Cookies } from "tools/storage"
import TimeAgo from "react-timeago"
import { Smartphone, Computer, Add } from "@mui/icons-material"
import { get } from "tools/request"

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  archiveQuestion: {
    textAlign: "right",
    paddingRight: "50px",
    "& > label": {
      marginBottom: "0"
    }
  }
}

const useStore = component =>
  inject(({ store }) => ({
    organizationId: store.organizationStore.organizationId,
    propertyId: store.organizationStore.propertyId,
    organizationProperties: store.organizationStore.organizationProperties,
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization,
    organization: store.organizationStore.organization,
    setOrganization: store.organizationStore.setOrganization
  }))(observer(component))

class Feeds extends Component {
  constructor(props) {
    super(props)
    this.props = props
    let cookies = new Cookies()
    const pageSize = props.embedded
      ? 25
      : cookies.get("feedsPageSize")
      ? Number(cookies.get("feedsPageSize"))
      : 25
    this.state = {
      isLoadingFeeds: false,
      isLoadingOrganization: false,
      feeds: [],
      notArchivedFeeds: [],
      organizationId: this.props.organizationId,
      propertyId: this.props.propertyId,
      addFeed: false,
      editFeed: false,
      fevFAQs: false,
      pageSize,
      selectedFeedId: -1,
      expandedFeedRows: {},
      doDisplayArchivedJobs: false,
      isLoadingFeedEndPointWebLogs: false,
      feedEndPointWebLogs: [],
      feedEndPointWebLogsTotalCount: 0
    }
  }

  componentDidMount() {
    this.props.setShowChangeCurrentOrganization(true)
    if (this.props.organizationId) {
      this.loadFeeds(this.props.organizationId)
      this.loadOrganizations(this.props.organizationId)
    }
  }

  componentDidUpdate() {
    if (
      (this.state.organizationId !== this.props.organizationId &&
        this.state.organization &&
        this.state.organization.parentId !== this.props.organizationId &&
        this.state.organization.parentId !== this.state.organizationId) ||
      (this.state.organizationId !== this.props.organizationId &&
        this.state.feeds.length === 0)
    ) {
      this.setState({
        feedEndPointWebLogs: [],
        expandedFeedRows: {},
        selectedFeedId: -1,
        organizationId: this.props.organizationId
      })
      if (this.props.organizationId) {
        this.loadOrganizations(this.props.organizationId)
        this.loadFeeds(this.props.organizationId)
      }
    }
  }

  getParentId_IfOrgIdIsProperty(organizationId) {
    let properties = this.props.organizationProperties
    let foundParent = 0
    properties &&
      properties.forEach(prp => {
        if (prp && prp.id === organizationId && prp.parentId) {
          foundParent = prp.parentId
        }
      })
    return foundParent ? foundParent : organizationId
  }

  loadFeeds(organizationId) {
    if (this.state.isLoadingFeeds) {
      return
    }
    organizationId = this.getParentId_IfOrgIdIsProperty(organizationId)
    this.setState({ isLoadingFeeds: true, feeds: [] })
    get(
      `${globalSettings.apiBaseUrl}/api/feed/retrieveall?organizationid=${organizationId}`
    )
      .then(Response => Response.json())
      .then(feeds => {
        feeds.content.types.forEach(item => {
          item.feedClassNameAbbrev = this.parseFeedType(item.feedClassName)
          item.apiFeedLink = `${globalSettings.apiBaseUrl}/api/feed/${item.id}?api_token=${item.organization.apiToken}`
          item.feedStatusName = Helpers.convertFeedStatusIdToStatusName(
            item.feedStatus
          )
        })
        let notArchivedFeeds = this.doArchiveCheck(feeds.content.types)
        this.setState({
          feeds: feeds.content.types,
          notArchivedFeeds: notArchivedFeeds,
          organizationId: organizationId,
          isLoadingFeeds: false
        })
      })
  }

  loadFeedEndPointWebLogs(feedId) {
    this.setState({
      isLoadingFeedEndPointWebLogs: true,
      feedEndPointWebLogs: []
    })
    get(
      `${globalSettings.apiBaseUrl}/api/webapilog/feedendpointweblogs?feedid=${feedId}`
    )
      .then(Response => Response && Response.ok === true && Response.json())
      .then(result => {
        if (result) {
          this.setState({
            isLoadingFeedEndPointWebLogs: false,
            feedEndPointWebLogs: result.content
          })
        } else {
          return "problem loading data"
        }
      })
  }

  loadFeedEndPointWebLogsTotalCount(feedId) {
    this.setState({ feedEndPointWebLogsTotalCount: 0 })
    get(
      `${globalSettings.apiBaseUrl}/api/webapilog/totalweblogscount?feedid=${feedId}`
    )
      .then(Response => Response && Response.ok === true && Response.json())
      .then(result => {
        if (result) {
          this.setState({ feedEndPointWebLogsTotalCount: result.content })
        } else {
          return "problem loading data"
        }
      })
  }

  loadOrganizations(organizationId) {
    this.setState({ isLoadingOrganization: true })
    get(`${globalSettings.apiBaseUrl}/api/organization/`)
      .then(Response => Response.json())
      .then(organizations => {
        let myOrg
        let allProperties = []
        organizations.content.forEach(item => {
          if (item.id === organizationId) {
            myOrg = item
          } else if (item.parentId) {
            allProperties.push(item)
          }
        })
        this.setState({
          organization: myOrg,
          allProperties: allProperties,
          isLoadingOrganization: false
        })
        this.props.setOrganization(myOrg)
      })
  }

  loadOrganizationAndProperties(organizationId) {
    this.setState({ isLoadingOrganization: true })
    get(
      `${globalSettings.apiBaseUrl}/api/organization/retrieve/OrgAndProperties/${organizationId}`
    )
      .then(Response => Response.json())
      .then(organizations => {
        let mainOrganization
        let properties = []
        organizations.content.forEach(item => {
          item.id === organizationId
            ? (mainOrganization = item)
            : properties.push(item)
        })
        this.setState({
          organization: mainOrganization,
          properties: properties,
          isLoadingOrganization: false
        })
        this.props.setOrganization(mainOrganization)
      })
  }

  doArchiveCheck(myfeeds) {
    let tmp = []
    myfeeds.forEach(itm => {
      if (!itm.isArchived) {
        tmp.push(itm)
      }
    })
    return tmp
  }

  parseFeedType(feedClassName) {
    switch (feedClassName) {
      case "api_occidental.Models.Core.Feeds.FeedRSS":
        return "RSS"
      case "api_occidental.Models.Core.Feeds.FeedAtom":
        return "Atom"
      case "api_occidental.Models.Core.Feeds.FeedAPAtom":
        return "AP Atom"
      default:
        return ""
    }
  }

  getItemValueFromPath(item, key) {
    if (!key || key === "") {
      return null
    }
    let value = void 0
    if (key && key.indexOf(".") !== -1) {
      // eslint-disable-line no-negated-condition
      // handle nested keys
      value = key.split(".").reduce(function (itemObj, nestedKey) {
        return itemObj[nestedKey]
      }, item)
    } else {
      value = item[key]
    }
    // concat because `value` can be a string or an array
    return value ? [].concat(value) : null
  }

  handleCancel() {
    this.setState({ fevFAQs: false })
  }

  rememberChanges = pageSize => {
    this.setState({ pageSize })
    let cookies = new Cookies()
    cookies.set("feedsPageSize", pageSize, {
      path: "/",
      expires: Helpers.CookieExpiration.OneYear
    })
  }

  stringFilterProps() {
    return {
      filterable: true,
      filterAll: true,
      filterMethod: ({ id, value }, rows) =>
        matchSorter(rows, value, {
          keys: [id],
          threshold: matchSorter.rankings.CONTAINS
        })
    }
  }

  stringDistinctFilterProps(propName, properties) {
    return {
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true
        }
        // NOTE: string versus integer comparison,
        // don't use === operator
        // eslint-disable-next-line eqeqeq
        return row[propName] == filter.value
      },
      Filter: ({ filter, onChange }) => {
        if (!properties || properties.size === 0) {
          return null
        }

        const distinctOptions = Object.keys(
          groupCollectionBy(properties, p =>
            this.getItemValueFromPath(p, propName)
          )
        ).sort((a, b) => {
          // Use toUpperCase() to ignore character casing
          const orgA = a.toUpperCase()
          const orgB = b.toUpperCase()
          let comparison = 0
          if (orgA > orgB) {
            comparison = 1
          } else if (orgA < orgB) {
            comparison = -1
          }
          return comparison
        })
        // build up the <select> element <option> element JSX style
        let options = []
        distinctOptions.forEach(opt => {
          options.push(
            <option value={opt} key={opt}>
              {opt}
            </option>
          )
        })
        return (
          <select
            onChange={event => {
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">All</option>
            {options}
          </select>
        )
      }
    }
  }

  filterByProperty(selectedFeeds, currentProp) {
    let filteredProperties = []
    currentProp = currentProp === "All Properties" ? "" : currentProp
    if (currentProp > 0) {
      filteredProperties = selectedFeeds.filter(itm => {
        let oid = itm.organization.id
        return oid === currentProp
      })
      return filteredProperties
    } else {
      return selectedFeeds
    }
  }

  render() {
    const { classes } = this.props
    const { pageSize } = this.state
    if (!this.state.feeds) {
      return "Assembling..."
    }

    let cookies = new Cookies()
    const currentProperty = cookies.get("currentProperty") || ""

    const hasWindowObject = typeof window !== "undefined"
    const screenWidth = hasWindowObject ? window.innerWidth : null
    const screenHeight = hasWindowObject ? window.innerHeight : null
    const showPagination =
      screenWidth && screenHeight && (screenHeight > 959 || screenWidth > 959)
    const myMaxHeight = showPagination
      ? `${(window.innerHeight * 0.68).toFixed(0)}px`
      : "auto"

    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.addFeed}
          style={{
            width: "80vw",
            maxWidth: "80vw"
          }}
        >
          <ModalHeader
            toggle={() => this.setState({ addFeed: !this.state.addFeed })}
          >
            Create Feed
          </ModalHeader>
          <ModalBody>
            <FeedControl
              handleCancel={() =>
                this.setState({ addFeed: !this.state.addFeed })
              }
              handleSubmit={() =>
                this.setState({ addFeed: !this.state.addFeed }, () =>
                  this.loadFeeds(this.props.organizationId)
                )
              }
              allProperties={this.state.allProperties}
              creatingFeed={true}
            />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.editFeed}
          style={{
            width: "80vw",
            maxWidth: "80vw"
          }}
        >
          <ModalHeader
            toggle={() => this.setState({ editFeed: !this.state.editFeed })}
          >
            Edit Feed
          </ModalHeader>
          <ModalBody>
            <FeedControl
              feed={this.state.feed}
              editfeed={this.state.editFeed}
              handleCancel={() =>
                this.setState({ editFeed: !this.state.editFeed })
              }
              handleSubmit={() =>
                this.setState({ editFeed: !this.state.editFeed }, () =>
                  this.loadFeeds(this.props.organizationId)
                )
              }
              allProperties={this.state.allProperties}
              creatingFeed={false}
            />
          </ModalBody>
        </Modal>
        {this.state.fevFAQs && (
          <Modal
            isOpen={this.state.fevFAQs}
            style={{
              width: "80vw",
              maxWidth: "80vw"
            }}
          >
            <ModalHeader
              toggle={() => this.setState({ fevFAQs: !this.state.fevFAQs })}
            >
              Feed Entry Validation Help
            </ModalHeader>
            <ModalBody>
              <div style={{ padding: "10px 30px" }}>
                <p>
                  Feed Entry Validation(FEV) validates that the different pieces
                  of a feed entry meet a general requirement. The different
                  pieces of the feed entry in the FEV are defined into "FEV
                  Types" below. The FEV validation types are defined on a per
                  Feed basis. FEV does NOT edit/update the feed entry in any
                  way. FEV does send a Notification Event when the FEV type does
                  not meed the expected requirements. To receive notification
                  events for FEV types, adjust your settings here:{" "}
                  <a href="https://portal.dataskrive-stage.com/portal/accountnotifications">
                    Account Notifciatoins
                  </a>
                  .
                </p>
                <p>
                  FEV Notification Events are settings on a per Organization
                  basis. So not only do FEV types need to be defined for each
                  Feed in an Organization, Notification Events for each
                  Organization per Account need to be adjusted in the Dataskrive
                  Portal.
                </p>
                <p>
                  Severity on FEV Types is the severity of the validation type.
                  The Severity setting is available on all FEV Types. There are
                  3 options; Info, Warning &amp; Error. These correspond the
                  Notification Event settings for the Organization. You can
                  adjust settings to recieve Notification Events speficically
                  for each of the Severity Types; Info, Warning &amp; Error.
                </p>
                <br />
                <p>
                  Feed Entry Validation Types:
                  <br />
                  1. NarrrativeBlockSnapshotsErrors: These represent Narrative
                  Block Snapshot Errors that occurred during the render process.
                  If any of these errors occurred, this FEV Type will be
                  triggered with a Notificaiton Event with the details of the
                  error. Parameter 1 and Parameter 2 are not used on this FEV
                  Type.
                  <br />
                  <br />
                  2. FeedEntryWordCountByPercentage: This validation feature
                  checks to see if the word count of the current feed entry
                  falls within a certain percentage of the average word count of
                  all of the feed entries on that feed. If Parameter 1 is left
                  blank, validation defaults to 25. Meaning the feed entry word
                  count of this feed entry is within 25% of the average word
                  count for all feed entries on this feed. Parameter 2 is not
                  used on this FEV type.
                  <br />
                  <br />
                  3. TriggerErrors: These represent Trigger Errors that occurred
                  during the render process. If any of these errors occurred,
                  this FEV Type will be triggered with a Notificaiton Event with
                  the details of the error. Parameter 1 and Parameter 2 are not
                  used on this FEV Type.
                  <br />
                  <br />
                  4-*: Currently these all represent different
                  fields/columns/data points, in a Feed Entry. On all of these
                  FEV Types Parameter 1 is the minimum charater length for this
                  field in a Feed Entry. If Parameter 1 is left blank, when this
                  FEV Type is enabled, it validates that the field is not Null
                  or a blank string. Parameter 2 is the maximum charater length
                  for this field in a Feed Entry. If Parameter 2 is left blank,
                  when this FEV Type is enabled, it does nothing.
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end"
                }}
              >
                <Button
                  style={{ margin: "10px 10px 10px 10px" }}
                  size="sm"
                  variant="contained"
                  onClick={() => this.handleCancel()}
                >
                  Close
                </Button>
              </div>
            </ModalBody>
          </Modal>
        )}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="primary" icon style={{ display: "flex" }}>
                <GridContainer>
                  <GridItem xs={12}>
                    <CardIcon color="primary">
                      <RssFeedIcon />
                    </CardIcon>
                    <div style={{ display: "flex" }}>
                      <h4 className={classes.cardIconTitle}>
                        <strong>{this.props.organization?.name}</strong> Feeds
                      </h4>
                      <IconButton
                        aria-label="StayPrimaryLandscape"
                        onClick={() =>
                          this.setState({
                            fevFAQs: true
                          })
                        }
                        size="large"
                      >
                        <HelpOutline />
                      </IconButton>
                      <span
                        style={{
                          color: "#3C4858",
                          marginTop: "19px",
                          marginLeft: "-15px"
                        }}
                      >
                        &lt;- FEV Help
                      </span>
                    </div>
                  </GridItem>
                  <GridItem xs={6}>
                    <Button
                      aria-label="ManageFeeds AddFeedBtn CreateFeed"
                      size="sm"
                      color="primary"
                      startIcon={<Add />}
                      onClick={() => this.setState({ addFeed: true })}
                    >
                      Add Feed
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <ReactTable
                  loading={
                    this.state.isLoadingFeeds ||
                    this.state.isLoadingOrganization
                  }
                  data={
                    this.state.doDisplayArchivedJobs
                      ? this.filterByProperty(this.state.feeds, currentProperty)
                      : this.filterByProperty(
                          this.state.notArchivedFeeds,
                          currentProperty
                        )
                  }
                  noDataText={
                    this.state.isLoadingFeeds ||
                    this.state.isLoadingOrganization ? (
                      <CircularProgress />
                    ) : (
                      "No feed(s) found."
                    )
                  }
                  columns={[
                    {
                      Header: "Id",
                      accessor: "id",
                      Cell: cell => (
                        <Link
                          to={{
                            pathname: `/portal/feed-entries/${cell.value}`,
                            state: {
                              feedTitle: `${cell.original.feedTitle}`
                            }
                          }}
                        >
                          {cell.value}
                        </Link>
                      ),
                      width: 70,
                      ...this.stringFilterProps
                    },
                    {
                      Header: "Actions",
                      accessor: "apiFeedLink",
                      Cell: cell => (
                        <div style={{ display: "flex" }}>
                          <Tooltip title="Edit" placement="top">
                            <Button
                              color="primary"
                              justIcon
                              round
                              simple
                              onClick={() =>
                                this.setState({
                                  editFeed: true,
                                  feed: cell.original
                                })
                              }
                            >
                              <Edit />
                            </Button>
                          </Tooltip>
                          <a href={cell.value} target="_new">
                            <Tooltip
                              title={`View "${cell.original.feedClassNameAbbrev}" feed`}
                              placement="top"
                            >
                              <Button
                                justIcon
                                round
                                simple
                                color="primary"
                                className="info"
                              >
                                <i className="fas fa-rss" />
                              </Button>
                            </Tooltip>
                          </a>
                        </div>
                      ),
                      sortable: false,
                      filterable: false,
                      width: 125
                    },
                    {
                      Header: "Status",
                      accessor: "feedStatusName",
                      Cell: cell => <div>{cell.original.feedStatusName}</div>,
                      width: 125,
                      ...this.stringDistinctFilterProps(
                        "feedStatusName",
                        this.state.notArchivedFeeds
                      )
                    },
                    {
                      Header: "Name",
                      accessor: "name",
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, {
                          keys: ["name"],
                          threshold: matchSorter.rankings.CONTAINS
                        }),
                      filterAll: true
                    },
                    {
                      Header: "Default Type",
                      accessor: "feedClassNameAbbrev",
                      ...this.stringDistinctFilterProps(
                        "feedClassNameAbbrev",
                        this.state.notArchivedFeeds
                      ),
                      width: 120,
                      Cell: cell => (
                        <div>{cell.original.feedClassNameAbbrev}</div>
                      )
                    },
                    {
                      Header: "Audit Info",
                      id: "auditInfo",
                      accessor: "auditInfo.modifiedOn",
                      Cell: ({ original: { auditInfo } }) => (
                        <AuditInfo {...auditInfo} />
                      ),
                      width: 250,
                      filterable: false
                    }
                  ]}
                  filterable={true}
                  minRows={0}
                  pageSize={this.state.isLoadingFeeds ? 5 : pageSize}
                  onPageSizeChange={(pageSize, page) => {
                    this.rememberChanges(pageSize, page)
                  }}
                  showPaginationTop={false}
                  showPaginationBottom={showPagination}
                  className="-striped -highlight -scrollEntries"
                  style={{
                    maxHeight: myMaxHeight
                  }}
                  defaultSorted={[
                    {
                      id: "feedTitle",
                      desc: false
                    }
                  ]}
                  getTrProps={(state, rowInfo) => {
                    if (rowInfo) {
                      let returnObject = { style: {} }
                      returnObject.style =
                        Helpers.buildCustomStyleForFeedStatus(
                          rowInfo.original.feedStatus
                        )
                      if (rowInfo.original.isArchived) {
                        returnObject.style.textDecoration = "line-through"
                      }
                      return returnObject
                    } else {
                      return {}
                    }
                  }}
                  expanded={this.state.expandedFeedRows}
                  getTdProps={(state, row, col) => ({
                    onClick: (event, handleOriginal) => {
                      // This is the only way to identify
                      // the specific row's primary id when the "expander" icon
                      // is clicked
                      if (col.expander) {
                        this.loadFeedEndPointWebLogs(row.original.id)
                        this.loadFeedEndPointWebLogsTotalCount(row.original.id)
                        if (this.state.selectedFeedId === row.original.id) {
                          // when the expander is "collapsed", unselected it
                          this.setState({ selectedFeedId: -1 })
                          this.setState({
                            expandedFeedRows: { [row.viewIndex]: false }
                          })
                        } else {
                          // when the expander is "expanded", means that it is selected
                          this.setState({ selectedFeedId: row.original.id })
                          this.setState({
                            expandedFeedRows: { [row.viewIndex]: true }
                          })
                        }
                      }
                      handleOriginal()
                    }
                  })}
                  SubComponent={() => (
                    <div style={{ marginLeft: "20px" }}>
                      {!this.state.isLoadingFeedEndPointWebLogs && (
                        <h4 style={{ float: "left" }}>
                          Feed End Point Request History{" "}
                          <span style={{ fontSize: "0.7em" }}>
                            (
                            {this.state.feedEndPointWebLogsTotalCount.toLocaleString()}{" "}
                            total, limited to most recent 1,000)
                          </span>
                        </h4>
                      )}
                      <ReactTable
                        data={this.state.feedEndPointWebLogs}
                        defaultSorted={[
                          {
                            id: "requestDateTime",
                            desc: true
                          }
                        ]}
                        loading={this.state.isLoadingFeedEndPointWebLogs}
                        noDataText={
                          this.state.isLoadingFeedEndPointWebLogs ? (
                            <CircularProgress />
                          ) : (
                            "No feed endpoint request history found."
                          )
                        }
                        columns={[
                          {
                            Header: "Id",
                            accessor: "id",
                            sortable: true,
                            filterable: false,
                            width: 80,
                            filterMethod: (filter, rows) =>
                              matchSorter(rows, filter.value, {
                                keys: ["id"],
                                threshold: matchSorter.rankings.CONTAINS
                              }),
                            filterAll: true
                          },
                          {
                            Header: "Request Date/Time",
                            accessor: "requestDateTime",
                            sortable: true,
                            filterable: false,
                            width: 105,
                            Cell: cell => (
                              <TimeAgo
                                date={cell.value}
                                title={Helpers.prettyDateTimeinPacificTimeZone(
                                  cell.value
                                )}
                              />
                            )
                          },
                          {
                            Header: "HTTP Method",
                            accessor: "httpMethod",
                            sortable: true,
                            filterable: true,
                            width: 85,
                            ...this.stringDistinctFilterProps(
                              "httpMethod",
                              this.state.feedEndPointWebLogs
                            )
                          },
                          {
                            Header: "Url",
                            accessor: "url",
                            sortable: true,
                            filterable: true,
                            width: 300,
                            filterMethod: (filter, rows) =>
                              matchSorter(rows, filter.value, {
                                keys: ["url"],
                                threshold: matchSorter.rankings.CONTAINS
                              }),
                            filterAll: true
                          },
                          {
                            Header: "Remote IP",
                            accessor: "remoteIP",
                            sortable: true,
                            filterable: true,
                            width: 110,
                            filterMethod: (filter, rows) =>
                              matchSorter(rows, filter.value, {
                                keys: ["remoteIP"],
                                threshold: matchSorter.rankings.CONTAINS
                              }),
                            filterAll: true,
                            Cell: cell => (
                              <a
                                href={`https://whatismyipaddress.com/ip/${cell.value}`}
                                target="_new"
                              >
                                <Tooltip
                                  title={`View IP Address Geolocation`}
                                  placement="top"
                                >
                                  <span>{cell.value}</span>
                                </Tooltip>
                              </a>
                            )
                          },
                          {
                            Header: "User Agent String",
                            accessor: "userAgentString",
                            sortable: true,
                            filterable: true,
                            width: 250,
                            filterMethod: (filter, rows) =>
                              matchSorter(rows, filter.value, {
                                keys: ["userAgentString"],
                                threshold: matchSorter.rankings.CONTAINS
                              }),
                            filterAll: true,
                            Cell: cell => (
                              <span>
                                {cell.value}&nbsp;
                                {!cell.value && "N/A"}
                                {cell.value &&
                                  (cell.value.indexOf("iPhone") !== -1 ||
                                  cell.value.indexOf("Android") !== -1 ? (
                                    <Smartphone />
                                  ) : (
                                    <Computer />
                                  ))}
                              </span>
                            )
                          },
                          {
                            Header: "Request Header",
                            accessor: "httpRequestHeader",
                            sortable: true,
                            filterable: true,
                            filterMethod: (filter, rows) =>
                              matchSorter(rows, filter.value, {
                                keys: ["httpRequestHeader"],
                                threshold: matchSorter.rankings.CONTAINS
                              }),
                            filterAll: true,
                            Cell: cell => {
                              if (
                                cell.value !== null &&
                                cell.value.length > 0
                              ) {
                                return (
                                  <Tooltip title={cell.value}>
                                    <span>
                                      {cell.value && cell.value.length > 100
                                        ? `${cell.value.substring(0, 100)}...`
                                        : cell.value}
                                    </span>
                                  </Tooltip>
                                )
                              } else {
                                return <span />
                              }
                            }
                          },
                          {
                            Header: "Request Body",
                            accessor: "httpRequestBody",
                            sortable: true,
                            filterable: true,
                            width: 100,
                            filterMethod: (filter, rows) =>
                              matchSorter(rows, filter.value, {
                                keys: ["httpRequestBody"],
                                threshold: matchSorter.rankings.CONTAINS
                              }),
                            filterAll: true,
                            Cell: cell => {
                              if (
                                cell.value !== null &&
                                cell.value.length > 0
                              ) {
                                return (
                                  <Tooltip title={cell.value}>
                                    <span>
                                      {cell.value && cell.value.length > 100
                                        ? `${cell.value.substring(0, 100)}...`
                                        : cell.value}
                                    </span>
                                  </Tooltip>
                                )
                              } else {
                                return <span />
                              }
                            }
                          },
                          {
                            Header: "Referrer Url",
                            accessor: "httpReferrerUrl",
                            sortable: true,
                            filterable: true,
                            filterMethod: (filter, rows) =>
                              matchSorter(rows, filter.value, {
                                keys: ["httpReferrerUrl"],
                                threshold: matchSorter.rankings.CONTAINS
                              }),
                            filterAll: true
                          }
                        ]}
                      >
                        {(state, makeTable) => {
                          let recordsInfoText = ""
                          const {
                            filtered,
                            pageRows,
                            pageSize,
                            sortedData,
                            page
                          } = state

                          if (sortedData && sortedData.length > 0) {
                            let isFiltered = filtered.length > 0

                            let totalRecords = sortedData.length

                            let recordsCountFrom = page * pageSize + 1

                            let recordsCountTo =
                              recordsCountFrom + pageRows.length - 1

                            if (isFiltered) {
                              recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords.toLocaleString()} filtered requests`
                            } else {
                              recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords.toLocaleString()} requests`
                            }
                          } else {
                            recordsInfoText = this.state
                              .isLoadingFeedEndPointWebLogs
                              ? "Loading history, please wait..."
                              : "No history"
                          }
                          return (
                            <div className="main-grid">
                              <div className="above-table text-right">
                                <div className="col-sm-12">
                                  <span className="records-info">
                                    {recordsInfoText}
                                    <Tooltip title="Reload">
                                      <Button
                                        justIcon
                                        round
                                        simple
                                        color="primary"
                                        className="info"
                                        id="reload"
                                        onClick={() => {
                                          this.loadFeedEndPointWebLogs(
                                            this.state.selectedFeedId
                                          )
                                          this.loadFeedEndPointWebLogsTotalCount(
                                            this.state.selectedFeedId
                                          )
                                        }}
                                      >
                                        {this.state
                                          .isLoadingFeedEndPointWebLogs ? (
                                          <CircularProgress
                                            thickness={1}
                                            size={30}
                                          />
                                        ) : (
                                          <Loop />
                                        )}
                                      </Button>
                                    </Tooltip>
                                  </span>
                                </div>
                              </div>
                              {makeTable()}
                            </div>
                          )
                        }}
                      </ReactTable>
                    </div>
                  )}
                >
                  {(state, makeTable) => {
                    let recordsInfoText = ""
                    const { filtered, pageRows, pageSize, sortedData, page } =
                      state

                    if (sortedData && sortedData.length > 0) {
                      let isFiltered = filtered.length > 0

                      let totalRecords = sortedData.length

                      let recordsCountFrom = page * pageSize + 1

                      let recordsCountTo =
                        recordsCountFrom + pageRows.length - 1

                      if (isFiltered) {
                        recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered feeds`
                      } else {
                        recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} feeds`
                      }
                    } else {
                      recordsInfoText =
                        this.state.isLoadingFeeds ||
                        this.state.isLoadingOrganization
                          ? "Loading feeds, please wait..."
                          : "No files"
                    }
                    return (
                      <div className="main-grid">
                        <div className="above-table text-right">
                          <div className="col-sm-12">
                            <span className={classes.archiveQuestion}>
                              <FormControlLabel
                                labelPlacement="start"
                                control={
                                  <Checkbox
                                    fontSize="small"
                                    checked={
                                      this.state.doDisplayArchivedJobs || false
                                    }
                                    onChange={event => {
                                      this.setState({
                                        doDisplayArchivedJobs:
                                          event.target.checked
                                      })
                                    }}
                                  />
                                }
                                label="Include Archived?"
                              />
                            </span>
                            <span className="records-info">
                              {recordsInfoText}
                              <Tooltip title="Reload">
                                <Button
                                  justIcon
                                  round
                                  simple
                                  color="primary"
                                  className="info"
                                  id="reload"
                                  onClick={() => {
                                    this.loadFeeds(this.props.organizationId)
                                  }}
                                >
                                  {this.state.isLoadingFeeds ? (
                                    <CircularProgress thickness={1} size={30} />
                                  ) : (
                                    <Loop />
                                  )}
                                </Button>
                              </Tooltip>
                            </span>
                          </div>
                        </div>

                        {makeTable()}
                      </div>
                    )
                  }}
                </ReactTable>
                <div xs={3} sm={3} md={3}>
                  <div style={{ background: "#ffd900" }}>
                    yellow = `Validate` status
                  </div>
                  <div style={{ background: "#f97f00" }}>
                    orange = `Validate - Internal Only` status
                  </div>
                  {this.state.doDisplayArchivedJobs && (
                    <div style={{ textDecoration: "line-through" }}>
                      line-through = 'Archived' status
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(useStore(Feeds))
