import { globalSettings } from "variables/general.jsx"
import { get, put } from "../tools/request"

// Always name the method with a verb followed by a noun.  Then you ask, what verb and what noun?
export default class AssetDAO {
  static getAssetsByOrganization = async (orgAndPropId, assetLimit) => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/asset/assets?organizationId=${orgAndPropId}&assetlimit=${assetLimit}`
    )
    return await res.json()
  }

  static getAssetCount = async orgAndPropId => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/asset/assetcount/${orgAndPropId}`
    )
    return await res.json()
  }

  static deleteAsset = async assetId => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/asset/${assetId}/delete`
    )
    return await res.json()
  }

  static associateAssetAndLeague = async (assetId, leagueId) => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/asset/associateAssetAndLeague?assetId=${assetId}&leagueId=${leagueId}`
    )
    return await res.json()
  }

  static dissociateAssetAndLeague = async (assetId, leagueId) => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/asset/dissociateAssetAndLeague?assetId=${assetId}&leagueId=${leagueId}`
    )
    return await res.json()
  }

  static dissociateAllLeaguesFromAsset = async assetId => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/asset/dissociateAllLeaguesFromAsset?assetId=${assetId}`
    )
    return await res.json()
  }

  static updateIsAvailableForGraphicsTool = async body => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/asset/updateisavailableforgraphicstool`,
      null,
      body
    )
    return await res.json()
  }
}
