// eslint-disable-next-line no-unused-vars
import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import withStyles from "@mui/styles/withStyles"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Menu from "@mui/icons-material/Menu"
import MoreVert from "@mui/icons-material/MoreVert"
import ViewList from "@mui/icons-material/ViewList"
import HeaderLinks from "./HeaderLinks"
import Button from "components/CustomButtons/Button"
import headerStyle from "assets/jss/material-dashboard-pro-react/components/headerStyle"
import Box from "@mui/material/Box"

function Header({ ...props }) {
  function makeBrand() {
    let name
    props.routes.map(prop => {
      if (prop.collapse) {
        prop.views.map(prop => {
          if (prop.path === props.location.pathname) {
            name = prop.name
          }
          return null
        })
      }
      return null
    })
    // More specific url matching
    if (props.location.pathname.indexOf("/portal/feed-entries/") !== -1) {
      name = "Feed Editor"
    }
    if (props.location.pathname.indexOf("/portal/feed-entries/entry/") !== -1) {
      name = "Feed Entry Editor"
    }
    if (props.location.pathname.indexOf("/portal/narrative/") !== -1) {
      if (props.location.pathname.indexOf("/edit") !== -1) {
        name = "Advanced Editor"
      } else if (props.location.pathname.indexOf("/output") !== -1) {
        name = "Narrative Output"
      } else {
        name = "Dashboard"
      }
    }
    if (props.location.pathname.indexOf("/portal/qa-dashboard") !== -1) {
      name = "Render Dashboard"
    }
    if (props.location.pathname.indexOf("/portal/dashboard") !== -1) {
      name = "Performance Metrics"
    }
    if (props.location.pathname.indexOf("/portal/manage-ctas/offer") !== -1) {
      name = "CTA Manager - Offer"
    }
    if (
      props.location.pathname.indexOf("/portal/manage-ctas/disclaimer") !== -1
    ) {
      name = "CTA Manager - Disclaimer"
    }
    if (
      props.location.pathname.indexOf("/portal/tools/graphicstoolv2") !== -1
    ) {
      name = "Graphics Tools"
    }
    if (props.location.pathname.indexOf("/portal/real-time-libraries") !== -1) {
      name = "Real Time Libraries"
    }
    if (props.location.pathname.indexOf("/portal/real-time-library") !== -1) {
      name = "Real Time Library"
    }
    if (!name) {
      name = "Data Skrive Admin"
    }

    return name
  }

  function showOrgMenuStatus() {
    let showOrgMenu = false
    props.routes &&
      props.routes.forEach(itm => {
        //We have added a property "showOrgMenu" in dashboard.js array "dashRoutes", which passes it as "dashboardRoutes" to dashboard.jsx
        //It contains all the route-views that need to show the organization select in the header
        if (itm.views) {
          itm.views.forEach(itm2 => {
            if (itm2.showOrgMenu) {
              if (itm2.showOrgMenuRegexp) {
                if (props.location.pathname.match(itm2.showOrgMenuRegexp)) {
                  showOrgMenu = true
                }
              } else if (props.location.pathname === itm2.path) {
                showOrgMenu = true
              }
            }
          })
        }
      })
    return showOrgMenu
  }

  const { classes, color, rtlActive } = props
  const appBarClasses = cx({
    [` ${classes[color]}`]: color
  })
  const sidebarMinimize = `${classes.sidebarMinimize} ${cx({
    [classes.sidebarMinimizeRTL]: rtlActive
  })}`
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <div className={sidebarMinimize}>
            {props.miniActive ? (
              <Button
                justIcon
                round
                color="white"
                onClick={props.sidebarMinimize}
              >
                <ViewList className={classes.sidebarMiniIcon} />
              </Button>
            ) : (
              <Button
                justIcon
                round
                color="white"
                onClick={props.sidebarMinimize}
              >
                <MoreVert className={classes.sidebarMiniIcon} />
              </Button>
            )}
          </div>
        </Box>
        <div className={classes.flex}>
          <Button href="#" className={classes.title} color="transparent">
            {makeBrand()}
          </Button>
        </div>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <HeaderLinks
            rtlActive={rtlActive}
            organizations={[{ id: 5, name: "The name" }]}
            showOrgMenu={showOrgMenuStatus()}
          />
        </Box>
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool
}

export default withStyles(headerStyle)(Header)
