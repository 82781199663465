import { action, observable, decorate, computed } from "mobx"
import dsChatDAO from "../daos/dsChatDAO"
import DSPromptStore from "./DSPromptStore"
import GPT4Tokenizer from "gpt4-tokenizer"
import { getColorFromWords } from "tools/ColorHelper"

// Function to wrap file content based on file type
function wrapFileContent(content, filename) {
  const fileType = getFileType(filename)

  if (fileType === "json") {
    return `\`\`\`json\n${content}\n\`\`\``
  } else if (fileType === "csv") {
    return `\`\`\`csv\n${content}\n\`\`\``
  } else {
    return content
  }
}

// Function to determine file type based on filename
function getFileType(filename) {
  const lowercasedFilename = filename.toLowerCase()

  if (lowercasedFilename.endsWith(".json")) {
    return "json"
  } else if (lowercasedFilename.endsWith(".csv")) {
    return "csv"
  } else {
    return "plaintext" // or handle other types as needed
  }
}
export default class DSChatStore {
  rootStore
  loading = false
  editing = false
  hasError = false
  messages = []
  systemPrompt = null
  systemAvatarColor = null
  systemBackgroundColor = null
  uploadedFile = null
  uploadedFileContent = ""
  fileTokenCount = 0
  sessionTokenCount = 0
  trimmedContent = ""
  chunkSize = 12000
  currentChunkIndex = 0
  systemMessageTokenCount = 0
  tokenizer
  promptStore
  initialized = false
  currentConversationId = null
  conversations = []
  image = ""
  imageRevisedPrompt = ""

  constructor(rootStore) {
    this.rootStore = rootStore
  }

  initializeChat() {
    this.tokenizer = new GPT4Tokenizer({ type: "gpt4" })
    this.loadPrompts()
    this.loadLastConversation()
    this.initialized = true
  }

  updateConversations = () => {
    this.conversations = this.getConversationsFromLocalStorage()
  }

  loadLastConversation() {
    const conversationId = localStorage.getItem("currentConversationId")
    if (conversationId) {
      this.loadConversationFromLocalStorage(conversationId)
    } else {
      const conversations = this.getConversationsFromLocalStorage()
      if (conversations.length > 0) {
        const lastConversation = conversations[conversations.length - 1]

        this.loadConversationFromLocalStorage(lastConversation.id)
      } else {
        // No conversations were found, so start a new one
        this.createNewConversation()
      }
    }
  }

  /**
   * Basic Local Storage Save/Load
   */
  loadConversationFromLocalStorage(conversationId) {
    let conversations = this.getConversationsFromLocalStorage()
    let chatSessionData
    if (conversationId) {
      this.setConversationId(conversationId)
      chatSessionData = conversations.find(c => c.id === conversationId)
    } else {
      chatSessionData = conversations[conversations.length - 1] // Load the last conversation
      this.setConversationId(chatSessionData.id)
    }
    if (chatSessionData) {
      this.setMessages(chatSessionData.messages || [])
      this.setSystemPrompt(chatSessionData.systemPrompt)
      this.setSessionTokenCount(chatSessionData.sessionTokenCount)
    } else {
      this.dehydrate()
    }
  }

  // Helper method to get all conversations from local storage
  getConversationsFromLocalStorage() {
    const storedConversations = localStorage.getItem("conversations")
    return storedConversations ? JSON.parse(storedConversations) : []
  }

  // Add new method to delete a conversation from local storage
  deleteConversation(conversationId) {
    let conversations = this.getConversationsFromLocalStorage()
    conversations = conversations.filter(c => c.id !== conversationId)
    localStorage.setItem("conversations", JSON.stringify(conversations))
    if (this.currentConversationId === conversationId) {
      this.dehydrate()
    }
    this.updateConversations() // add this line to update the observable
  }

  exportConversation = conversationId => {
    const conversations = this.getConversationsFromLocalStorage()
    const conversationToExport = conversations.find(
      c => c.id === conversationId
    )
    if (conversationToExport) {
      conversationToExport.id = new Date().toISOString()
      const content = JSON.stringify(conversationToExport, null, 2) // Pretty print JSON
      const blob = new Blob([content], { type: "application/json" })
      const url = URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.href = url
      link.download = `conversation-${conversationId}.json`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    } else {
      alert(`Conversation with ID ${conversationId} not found.`)
    }
  }
  importConversation = event => {
    const file = event.target.files[0]
    if (file && file.type === "application/json") {
      const reader = new FileReader()
      reader.onload = e => {
        try {
          const importedConversation = JSON.parse(e.target.result)
          importedConversation.id = new Date().toISOString()
          this.saveConversationToLocalStorage(importedConversation)
          this.loadConversationFromLocalStorage(importedConversation.id)
        } catch (error) {
          alert("Error parsing imported conversation:", error)
        }
      }
      reader.readAsText(file)
    } else {
      alert("The selected file is not a JSON file.")
    }
  }
  duplicateConversation = conversationId => {
    const conversations = this.getConversationsFromLocalStorage()
    const conversationToDuplicate = conversations.find(
      c => c.id === conversationId
    )

    if (conversationToDuplicate) {
      // Create a deep copy of the conversation to avoid mutating original one
      const newConversation = JSON.parse(
        JSON.stringify(conversationToDuplicate)
      )
      // Assign a new unique ID to the duplicated conversation
      newConversation.id = new Date().toISOString()
      // Save the duplicated conversation to local storage
      this.saveConversationToLocalStorage(newConversation)
      this.loadConversationFromLocalStorage(newConversation.id)
    } else {
      alert(`Conversation with ID ${conversationId} not found.`)
    }
  }
  // Method to get a list of conversation summaries
  getConversationSummaries() {
    const sortedConversations = this.conversations.sort(
      (a, b) => new Date(b.id) - new Date(a.id) // Assumes id is a date-conformant string
    )

    return sortedConversations.map(convo => {
      // Find the first user message to use in the summary
      const firstUserMessage = convo.messages.find(msg => msg.role === "user")
      const systemPromptName = convo.systemPrompt
        ? convo.systemPrompt.name
        : "Unknown Prompt"
      const summary = firstUserMessage
        ? `${systemPromptName}: ${firstUserMessage.content.slice(0, 50)}...` // Slice first 50 characters for the summary
        : "No user messages"

      return {
        id: convo.id,
        summary,
        timestamp: new Date(convo.id).toLocaleString() // Assuming convo.id is an ISO string
      }
    })
  }

  get conversationSummaries() {
    return this.getConversationSummaries()
  }

  /** Load required Prompts and Set the default*/
  loadPrompts = () => {
    // Load prompts from the store
    this.promptStore = new DSPromptStore(this.rootStore) // Initialize the prompt store
    this.promptStore.loadPrompts() // Load prompts from the prompt store
  }

  /**
Reactions
*/
  processConversation = async () => {
    try {
      this.setLoading(true)
      this.setHasError(false)
      // Get the system prompt for the advanced settings
      const chatCompletionOptions = {
        model: this.systemPrompt?.model?.trim(),
        temperature: this.systemPrompt?.temperature,
        maxTokens: this.systemPrompt?.maxTokens,
        nucleusSamplingFactor: this.systemPrompt?.nucleusSamplingFactor,
        imageResolution: this.systemPrompt?.imageResolution,
        imageQuality: this.systemPrompt?.imageQuality,
        imageStyle: this.systemPrompt?.imageStyle,
        messages: this.messages
      }
      const getChatCompletion = dsChatDAO.askChatGPT_V2(chatCompletionOptions)
      getChatCompletion
        .then(chatMessages => {
          const response = chatMessages
          const responseMessages = response.messages
          this.updateMessagesAndCalculateTokenCount(responseMessages)
        })
        .catch(error => {
          // Handle network errors, timeouts, etc.
          console.error("DSChatbot Error: ", error)
          this.setHasError(true)
        })
        .finally(() => {
          this.setLoading(false)
        })
    } catch (err) {
      console.error("DSChatbot Error: ", err)
    }
  }

  clearConversation = () => {
    this.dehydrate()
  }

  // Method that creates a new conversation and sets it to the current conversation.
  createNewConversation() {
    // Create a new conversation structure
    const newConversation = {
      id: new Date().toISOString(), // Assign a new unique ID
      messages: [],
      systemPrompt: null,
      sessionTokenCount: 0
      // Initialize other relevant properties as needed...
    }
    // Save and load the new conversation.
    this.saveConversationToLocalStorage(newConversation)
    this.loadConversationFromLocalStorage(newConversation.id)
  }

  // Method to save the current or provided conversation to local storage.
  saveConversationToLocalStorage(conversation = null) {
    let conversations = this.getConversationsFromLocalStorage()
    // Conversation parameter allows saving a new conversation directly.
    const chatSessionData = conversation || {
      id: this.currentConversationId || new Date().toISOString(),
      messages: this.messages,
      systemPrompt: this.systemPrompt,
      sessionTokenCount: this.sessionTokenCount
      // Other relevant properties to save...
    }
    if (!conversation) {
      // If updating the current conversation.
      const index = conversations.findIndex(c => c.id === chatSessionData.id)
      if (index !== -1) {
        conversations[index] = chatSessionData
      } else {
        conversations.push(chatSessionData)
      }
    } else {
      // If saving a new conversation.
      conversations.push(chatSessionData)
      this.setConversationId(chatSessionData.id)
    }
    localStorage.setItem("conversations", JSON.stringify(conversations))
    this.updateConversations() // add this line to update the observable
  }

  processTextToImage = async text => {
    try {
      this.setLoading(true)
      this.setHasError(false)
      // Get the system prompt for the advanced settings

      const getImageCompletion = dsChatDAO.askChatGPTForImage(text)
      getImageCompletion
        .then(response => {
          this.setImage(response.data[0].image)
          this.setImageRevisedPrompt(response.data[0].revisedPrompt)
        })
        .catch(error => {
          // Handle network errors, timeouts, etc.
          console.error("DSChatbot Error: ", error)
          this.setHasError(true)
        })
        .finally(() => {
          this.setLoading(false)
        })
    } catch (err) {
      console.error("DSChatbot Error: ", err)
    }
  }

  setSystemPrompt = prompt => {
    if (prompt && prompt.id > 0) {
      // First set the variable so everyone knows what rules the app will work under
      this.systemPrompt = prompt
      this.systemAvatarColor = getColorFromWords(prompt?.name)
      this.systemBackgroundColor = `${this.systemAvatarColor}15`

      // Update the system prompt token count
      const fullTokenCount = this.tokenizer?.estimateTokenCount(prompt?.content)
      this.setSystemMessageTokenCount(fullTokenCount)

      // Now add the message to the message list
      const message = { role: "system", content: prompt.content }
      // Remove the old system prompt
      const oldConversation = this.messages?.filter(
        messageItem => messageItem.role !== "system"
      )
      // Add the new system prompt
      const newConversation = [message, ...oldConversation]
      this.updateMessagesAndCalculateTokenCount(newConversation)
    }
  }

  containsImageRequest = prompt => {
    const lowerCasePrompt = prompt.toLowerCase()
    const containsPhrase =
      lowerCasePrompt.startsWith("image: ") ||
      lowerCasePrompt.startsWith("draw ") ||
      lowerCasePrompt.startsWith("draw me") ||
      lowerCasePrompt.startsWith("create an image") ||
      lowerCasePrompt.startsWith("make an image") ||
      lowerCasePrompt.startsWith("generate an image") || //This prompt is most often echoed by dall-e
      lowerCasePrompt.startsWith("create a photo") ||
      lowerCasePrompt.startsWith("make a photo") ||
      lowerCasePrompt.startsWith("generate a photo") ||
      lowerCasePrompt.startsWith("create a picture") ||
      lowerCasePrompt.startsWith("make a picture") ||
      lowerCasePrompt.startsWith("generate a picture")

    return containsPhrase
  }

  //Method shared by Add and Edit Chat Message
  generateMessage = (content, messageType, systemPrompt) => {
    const isImageModel =
      ["dall-e-3", "dall-e-2"].includes(systemPrompt?.model?.trim()) || false

    return {
      role: "user",
      content,
      messageType: isImageModel ? 1 : messageType,
      image: {
        imageUrl: "",
        imageResolution: systemPrompt?.imageResolution,
        imageQuality: systemPrompt?.imageQuality,
        imageStyle: systemPrompt?.imageStyle
      }
    }
  }

  addMessageAndProcess = userPrompt => {
    const completePrompt = this.trimmedContent
      ? `${userPrompt}\n\nTrimmed File Contents:\n${wrapFileContent(
          this.trimmedContent,
          this.uploadedFile?.name
        )}`
      : userPrompt

    const newMessage = this.generateMessage(
      completePrompt,
      this.containsImageRequest(completePrompt) ? 1 : 0,
      this.systemPrompt
    )

    const newConversation = [...this.messages, newMessage]
    this.updateMessagesAndCalculateTokenCount(newConversation)
    this.processConversation() // Process the conversation after adding the message
  }

  editMessageAndProcess = (updatedMessage, messageIndex) => {
    if (messageIndex >= 0 && messageIndex < this.messages.length) {
      const newMessage = this.generateMessage(
        updatedMessage,
        this.containsImageRequest(updatedMessage) ? 1 : 0,
        this.systemPrompt
      )

      const priorMessages = this.messages.slice(0, messageIndex)
      const newConversation = [...priorMessages, newMessage]

      this.updateMessagesAndCalculateTokenCount(newConversation)
      this.processConversation()
    }
  }

  handleUploadedFileContent = fileContent => {
    this.setCurrentChunkIndex(0)
    this.setUploadedFileContent(fileContent)
    this.updateTokenCountFromContent(fileContent)
    if (fileContent) {
      const chunkedText = this.tokenizer.chunkText(fileContent, this.chunkSize)
      const firstChunkText = chunkedText?.[0]?.text
      this.setTrimmedContent(firstChunkText)
    } else {
      this.setTrimmedContent("")
    }
    this.calculateTokenCount()
  }

  // Method to calculate token count from the content
  updateTokenCountFromContent = content => {
    const tokenCount = this.tokenizer.estimateTokenCount(content)
    this.setFileTokenCount(tokenCount)
    if (tokenCount > this.chunkSize) {
      alert(
        `This file exceeds the context token limit with ${tokenCount} tokens. In order to make the most of your request it will be split into chunk sizes of ${this.chunkSize} and submitted with your request.`
      )
    }
  }

  setHasError = hasError => {
    this.hasError = hasError
  }

  setUploadedFile(file) {
    this.uploadedFile = file
  }

  setUploadedFileContent(fileContent) {
    this.uploadedFileContent = fileContent
  }

  setImage(url) {
    this.image = url
  }

  setImageRevisedPrompt(revisedPrompt) {
    this.imageRevisedPrompt = revisedPrompt
  }

  setFileTokenCount(count) {
    this.fileTokenCount = count
  }

  setSessionTokenCount(count) {
    this.sessionTokenCount = count
  }

  setTrimmedContent(content) {
    this.trimmedContent = content
  }

  setChunkSize(count) {
    this.chunkSize = count
  }

  setCurrentChunkIndex(index) {
    this.currentChunkIndex = index

    // If there is a file in memory, update the current chunk in memory
    if (this.uploadedFileContent) {
      const chunkedText = this.tokenizer.chunkText(
        this.uploadedFileContent,
        this.chunkSize
      )
      const selectedChunkText = chunkedText[index]?.text
      this.setTrimmedContent(selectedChunkText)
    }
  }

  setSystemMessageTokenCount(count) {
    this.systemMessageTokenCount = count
  }

  // Function to update messages and trigger token count calculation
  updateMessagesAndCalculateTokenCount(messages) {
    this.setMessages(messages)
    this.calculateTokenCount()
    this.saveConversationToLocalStorage()
  }

  // Function to calculate the token count
  calculateTokenCount() {
    let totalTokenCount = 0

    // Calculate all the tokens in the existing messages
    this.messages?.forEach(message => {
      if (message.content) {
        const messageTokenCount = this.tokenizer?.estimateTokenCount(
          message.content
        )
        totalTokenCount += messageTokenCount
        message.tokenCount = messageTokenCount
      } else {
        message.tokenCount = 0
      }
    })
    // If a file is uploaded, include the chunk that will be attached to any user prompt
    if (this.trimmedContent) {
      const uploadedFileTokens = this.tokenizer?.estimateTokenCount(
        this.trimmedContent
      )
      totalTokenCount += uploadedFileTokens
    }
    this.setSessionTokenCount(totalTokenCount)
  }

  /**
Internal Actions
*/
  dehydrate() {
    this.setLoading(false)
    this.setEditing(false)
    this.setMessages([])
    this.setSystemPrompt(null)
    this.setUploadedFile(null)
    this.setUploadedFileContent("")
    this.setFileTokenCount(0)
    this.setSessionTokenCount(0)
    this.setCurrentChunkIndex(0)
    this.setTrimmedContent("")
    this.setHasError(false)
  }

  setLoading(value) {
    this.loading = value
  }
  setEditing(value) {
    this.editing = value
  }
  setMessages(value) {
    this.messages = value
  }
  setConversationId(value) {
    this.currentConversationId = value
    localStorage.setItem("currentConversationId", value)
  }
}

decorate(DSChatStore, {
  conversationSummaries: computed,
  conversations: observable,
  currentConversationId: observable,
  // observables
  loading: observable,
  editing: observable,
  hasError: observable,
  messages: observable,
  systemPrompt: observable,
  systemAvatarColor: observable,
  systemBackgroundColor: observable,
  uploadedFile: observable,
  uploadedFileContent: observable,
  fileTokenCount: observable,
  trimmedContent: observable,
  chunkSize: observable,
  currentChunkIndex: observable,
  systemMessageTokenCount: observable,
  sessionTokenCount: observable,
  initialized: observable,
  // initial actions
  initializeChat: action.bound,
  // states
  setLoading: action.bound,
  setEditing: action.bound,
  setHasError: action.bound,
  // File actions
  setUploadedFile: action.bound,
  setUploadedFileContent: action.bound,
  setFileTokenCount: action.bound,
  setTrimmedContent: action.bound,
  setChunkSize: action.bound,
  setCurrentChunkIndex: action.bound,
  // Chat actions
  setMessages: action.bound,
  setSystemMessageTokenCount: action.bound,
  setSessionTokenCount: action.bound,
  processConversation: action.bound,
  clearConversation: action.bound,
  addMessage: action.bound,
  addMessageAndProcess: action.bound,
  editMessageAndProcess: action.bound,
  setSystemPrompt: action.bound,
  // Conversation Actions
  createNewConversation: action.bound,
  setConversationId: action.bound,
  loadConversationFromLocalStorage: action.bound,
  deleteConversation: action.bound,
  exportConversation: action.bound,
  importConversation: action.bound,
  duplicateConversation: action.bound,
  updateConversations: action.bound,
  // Image actions
  processTextToImage: action.bound,
  setImage: action.bound,
  setImageRevisedPrompt: action.bound
})
