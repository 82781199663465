const useTriggerTypeHelper = ({ narrativeId, triggers }) => {
  // Remove imported library triggers
  const filteredTriggers = triggers.filter(trigger => !trigger.isFromLibrary)

  //Filter the triggers into their proper types
  // dependencies which run first
  const dependencyTriggers = filteredTriggers?.filter(
    trigger =>
      (trigger.narrativeId === narrativeId ||
        trigger.narrative_Id === narrativeId) &&
      trigger.isDependency !== null &&
      (trigger.isDependency === true || trigger.isDependency === "true")
  )
  // new triggers, which control theme
  const newsTriggers = filteredTriggers?.filter(
    trigger =>
      (trigger.narrativeId === narrativeId ||
        trigger.narrative_Id === narrativeId) &&
      trigger.isNews !== null &&
      (trigger.isNews === true || trigger.isNews === "true")
  )

  // insights that trigger content
  const insightTriggers = filteredTriggers?.filter(
    trigger =>
      (trigger.narrativeId === narrativeId ||
        trigger.narrative_Id === narrativeId) &&
      (trigger.isNews === null ||
        trigger.isNews === false ||
        trigger.isNews === "false") &&
      (trigger.isDependency === null ||
        trigger.isDependency === false ||
        trigger.isDependency === "false")
  )

  return { dependencyTriggers, filteredTriggers, insightTriggers, newsTriggers }
}

export default useTriggerTypeHelper
