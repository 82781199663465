import makeStyles from "@mui/styles/makeStyles"
import Chip from "@mui/material/Chip"
import Check from "@mui/icons-material/CheckCircleOutline"
import Warning from "@mui/icons-material/Warning"
import Report from "@mui/icons-material/Report"

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(1),
    fontSize: "1em",
    variant: "outlined"
  },
  ok: {
    borderColor: "#66bb6a",
    height: "24px"
  },
  warn: {
    borderColor: "#ffc107",
    height: "24px"
  },
  stop: {
    borderColor: "#a31545",
    height: "24px"
  }
}))

export default function SaturationChip({ saturation, utilization }) {
  const classes = useStyles()
  let iconSaturation = null
  let saturationClass = null

  if (utilization == null) {
    return null
  }
  if (saturation <= 30) {
    // ok is less than 30%
    saturationClass = classes.ok
    iconSaturation = (
      <Check style={{ color: "#66bb6a", width: "28px", height: "28px" }} />
    )
  } else if (saturation > 30 && saturation <= 60) {
    saturationClass = classes.warn
    iconSaturation = (
      <Warning style={{ color: "#ffc107", width: "28px", height: "28px" }} />
    )
  } else if (saturation > 60) {
    saturationClass = classes.stop
    iconSaturation = (
      <Report style={{ color: "#a31545", width: "28px", height: "28px" }} />
    )
  } else {
    return null
  }

  return (
    <Chip
      variant="outlined"
      className={saturationClass}
      deleteIcon={iconSaturation}
      onDelete={true}
      label={parseFloat(utilization).toFixed(2)}
    />
  )
}
