// Functions needed in CTAOfferLinkForm
const useDisclaimerFormHelper = ({
  setResetPayouts,
  setAddingPayoutExceptions,
  addingPayoutExceptions,
  formValues,
  setRegionByPayout,
  regionByPayout,
  setExceptionPayouts,
  exceptionPayouts,
  setPayoutException,
  payoutException,
  callToActionPayOff,
  region,
  regionsInfo,
  regionsList,
  myFormValues,
  setOrigFormValues,
  companiesInfo,
  organizations,
  classes,
  dayjs,
  timeZoneIANA,
  _USA_REGION,
  allUSA,
  startTimeValuep,
  endTimeValuep
}) => {
  const areThereAnyPayoutExceptions = () => {
    let numberOfPayoutExceptions = 0
    regionByPayout?.forEach((outer, index) => {
      index &&
        outer.forEach(itm => {
          if (itm.payoutPercentage || itm.payoutException) {
            numberOfPayoutExceptions += 1
          }
        })
    })
    return numberOfPayoutExceptions === 0 ? false : true
  }

  const checkIfWeNeedToDisablePayoutExceptions = () => {
    //We must have at least one region that is NOT a Payout Exception
    if (region?.includes(_USA_REGION)) {
      //If we have the USA Region selected, the we don't neex to worry about that scenario
      return false
    }
    let numberOfPayoutExceptions = 0
    regionByPayout?.forEach((outer, index) => {
      index &&
        outer.forEach(itm => {
          if (itm.payoutPercentage && itm.payoutException) {
            numberOfPayoutExceptions += 1
          }
        })
    })
    if (region?.length > numberOfPayoutExceptions + 1) {
      return false
    } else {
      return true
    }
  }

  const removeGroupPayoutExceptions = index => {
    setAddingPayoutExceptions(addingPayoutExceptions - 1)
    let tmp = typeof regionByPayout === "object" ? regionByPayout : []
    tmp.splice(index, 1)
    setRegionByPayout(tmp)
    let tmp2 = exceptionPayouts
    tmp2.splice(index, 1)
    setExceptionPayouts(tmp2)
    if (!areThereAnyPayoutExceptions() && payoutException) {
      setPayoutException(false)
    }
  }

  const removePayoutExceptions = () => {
    if (regionByPayout.length > 1) {
      for (let i = regionByPayout.length - 1; i > 0; i -= 1) {
        regionByPayout[i] = []
      }
      setRegionByPayout(regionByPayout)
    }
  }

  const updateSelectedRegions = (
    vals,
    oldVals,
    payoutPercentage,
    multiIndex
  ) => {
    //A few rules apply to the Region Payout Exception Groups
    //The first Region Payout Exception Group contains the "default" regions, and can never be empty -
    //ie you can't take all out an put in other Exception Groups
    //Each other region can add a value and remove values
    //You can add values from any exception Group
    //if you remove a value, it will automatically go back into the the "default" First Payuout Exception Group
    let changedOrg = oldVals.filter(function (val) {
      return vals.indexOf(val) === -1
    })
    if (!changedOrg.length) {
      changedOrg = vals.filter(function (val) {
        return oldVals.indexOf(val) === -1
      })
    }
    let tryingToRemoveLastElementOfDefaultGroup = false
    if (oldVals.length > vals.length && changedOrg.length) {
      //remove
      let cutIndex = null

      let toMove = null
      regionByPayout &&
        regionByPayout[multiIndex].forEach((itm, index2) => {
          if (itm.id === changedOrg[0]) {
            itm.payoutPercentage = formValues.callToActionPayOff
            cutIndex = index2
          }
        })
      if (cutIndex !== null) {
        toMove = regionByPayout[multiIndex][cutIndex]
        toMove.payoutPercentage = callToActionPayOff
        toMove.payoutException = false
        regionByPayout[multiIndex].splice(cutIndex, 1)
        if (!allUSA.length) {
          //Only move it if USA not selected
          regionByPayout[0].push(toMove)
        }
      }
    } else if (oldVals.length < vals.length && changedOrg.length) {
      //Add
      let cutIndex = null
      let savedToMove = false
      let payoutGroupIndex = null
      let toMove = null
      let newVal = null
      regionByPayout?.forEach((itm2, index) => {
        itm2.forEach((itm, index2) => {
          if (itm.id === changedOrg[0]) {
            itm.payoutPercentage = payoutPercentage
            cutIndex = index2
            payoutGroupIndex = index
          }
        })
      })
      if (cutIndex !== null && !savedToMove && payoutGroupIndex != null) {
        if (payoutGroupIndex || regionByPayout[payoutGroupIndex].length > 1) {
          //You can't remove the last element from the default Group
          toMove = regionByPayout[payoutGroupIndex][cutIndex]
          regionByPayout[payoutGroupIndex].splice(cutIndex, 1)
          savedToMove = true
        } else {
          tryingToRemoveLastElementOfDefaultGroup = true
        }
      } else {
        myFormValues.regionsAllParams?.forEach(itm => {
          if (itm.id === changedOrg[0]) {
            newVal = itm
          }
        })
      }

      if (
        cutIndex !== null &&
        toMove &&
        payoutGroupIndex != null &&
        multiIndex != null
      ) {
        regionByPayout[multiIndex].push(toMove)
      } else if (newVal) {
        regionByPayout.push(newVal)
      }
    }
    if (!tryingToRemoveLastElementOfDefaultGroup) {
      setRegionByPayout(regionByPayout)
      setResetPayouts(true)
      return true
    } else {
      return false
    }
  }

  const constructOrgPropList = itm => {
    const correctOrg = []
    organizations.forEach(org => {
      if (org.id === itm.organizationId) {
        correctOrg.push({
          //need to save new object - vs filter - to not overwrite the existing organizations array
          id: org.id,
          name: org.name,
          properties: org.properties
        })
      }
    })
    const myProperties = (
      (correctOrg[0] && correctOrg[0].properties) ||
      []
    ).filter(props => itm.propertyIds.includes(props.id))
    correctOrg[0].properties = myProperties
    return correctOrg
  }

  const endDateValidation = (endDate, itm) => {
    if (endDate !== null && !itm.startDate) {
      return "Start Date required with End Date"
    }
    if (!endDate || !itm.startDate) {
      return null
    }
    const dateStart = dayjs(itm.startDate)
    const dateEnd = dayjs(endDate)
    const dateDiff = dateEnd.diff(dateStart)
    return dateDiff > 0 ? null : `End Date must be after Start Date`
  }

  const validationObject = {
    hasOwnProperty: [
      { variable: "linkUrl", name: "Link URL" },
      { variable: "affiliateId", name: "Affiliate" },
      { variable: "organizationId", name: "Organization" },
      { variable: "companyId", name: "Company" },
      { variable: "shortNameOffer", name: "Short Name Offer" },
      { variable: "linkTypeId", name: "Offer Link" },
      { variable: "callToActionPayOff", name: "Conversion Payout" },
      { variable: "region", name: "Region", array: true },
      {
        variable: "endDate",
        name: "endDate",
        validation: endDateValidation
      }
    ]
  }

  const failedValidationList = list => (
    <div>
      <div className={classes.infoValidationHeader}>
        You have the following validation errors:
      </div>
      <div className={classes.addScrollIfNeeded}>
        <ol>
          {list?.map((itm, i) => (
            <li key={i}>{itm}</li>
          ))}
        </ol>
      </div>
    </div>
  )

  const checkForChangedNavElements = (newFormValues, oldFormValues) => {
    let newCompany = newFormValues?.companyId
      ? oldFormValues?.companies[0] &&
        oldFormValues.companies[0].id === newFormValues.companyId
        ? false
        : true
      : true
    let newOrganization = newFormValues?.organizationId
      ? oldFormValues?.organizations[0] &&
        oldFormValues.organizations[0].id === newFormValues.organizationId
        ? false
        : true
      : true
    let newLinkName = newFormValues.linkName !== oldFormValues.linkName
    return newLinkName || newCompany || newOrganization
  }

  const formValueValidation = itm => {
    let result = []
    validationObject.hasOwnProperty?.forEach(field => {
      if (itm.hasOwnProperty(field.variable)) {
        if (typeof field.validation === "function") {
          const errorResult = field.validation(itm[field.variable], itm)
          if (errorResult) {
            result.push(errorResult)
          }
        } else if (field.array && !itm[field.variable].length) {
          result.push(`Please add a value for "${field.name}"`)
        } else if (!itm[field.variable]) {
          result.push(`Please add a value for "${field.name}"`)
        }
      } else {
        result.push(`"${field.name}" doesn't exist in the form`)
      }
    })
    const lastRegionByPayoutGrouping =
      itm.groupRegionByPayout?.length &&
      itm.groupRegionByPayout[itm.groupRegionByPayout.length - 1]
    if (
      payoutException &&
      (!lastRegionByPayoutGrouping[0] || !lastRegionByPayoutGrouping[0].id)
    ) {
      result.push(
        "You did not apply the new Exception you were adding, please do or delete before saving the offer link"
      )
    }
    if (result.length) {
      return failedValidationList(result)
    } else {
      return false
    }
  }

  const getExceptionsInfoForPut = (
    itm,
    rgn,
    rgn2,
    i,
    regionArray,
    toBeSavedRegion,
    saveToBeSavedList
  ) => {
    let regionName = regionsInfo?.filter(x => x.id === rgn2.id) || []
    regionName = (regionName[0] && regionName[0].name) || ""
    const info = {
      country:
        rgn2.id === _USA_REGION
          ? {
              id: _USA_REGION,
              name: "United States"
            }
          : null,
      region:
        rgn2.id === _USA_REGION
          ? null
          : {
              id: rgn2.id,
              name: regionName
            },
      payoutValue: i
        ? rgn2.payoutPercentage || rgn2.payoutException
        : itm.callToActionPayOff,
      payoutTypeId: itm.isConversionPercentPayOut ? 1 : 2,
      payoutTypeName: itm.isConversionPercentPayOut ? "Percent" : "Dollar",
      payoutTypeDescription: itm.isConversionPercentPayOut
        ? "Percent"
        : "Dollar",
      isException: !!i
    }

    regionArray.push(info)
    if (saveToBeSavedList) {
      toBeSavedRegion.push(rgn2.id)
    }
  }

  const constructMonetizationRegions = itm => {
    const regionArray = []
    const toBeSavedRegion = []
    if (itm.region.length === 1 && itm.region[0] === _USA_REGION) {
      itm.groupRegionByPayout?.forEach((rgn, i) => {
        if (i !== 0) {
          rgn.forEach(rgn2 => {
            if (rgn2.id) {
              getExceptionsInfoForPut(
                itm,
                rgn,
                rgn2,
                i,
                regionArray,
                toBeSavedRegion,
                true
              )
            }
          })
        } else {
          getExceptionsInfoForPut(
            itm,
            rgn,
            { id: _USA_REGION },
            i,
            regionArray,
            toBeSavedRegion,
            true
          )
        }
      })

      return regionArray
    } else {
      itm.groupRegionByPayout?.forEach((rgn, i) => {
        rgn.forEach(rgn2 => {
          if (rgn2.id) {
            getExceptionsInfoForPut(
              itm,
              rgn,
              rgn2,
              i,
              regionArray,
              toBeSavedRegion,
              true
            )
          }
        })
      })
      if (
        itm.region.length === toBeSavedRegion.length &&
        !itm.region.includes(_USA_REGION)
      ) {
        return regionArray
      } else if (itm.region.includes(_USA_REGION)) {
        return regionArray
      } else {
        itm.region.forEach(rgn => {
          const rgn2 = { id: rgn }
          if (!toBeSavedRegion.includes(rgn)) {
            getExceptionsInfoForPut(
              itm,
              rgn,
              rgn2,
              0,
              regionArray,
              toBeSavedRegion,
              false
            )
          }
        })
        return regionArray
      }
    }
  }

  const linkSaveObject = (itm, intentType) => {
    const saveContent = {
      linkName: null,
      intentId:
        intentType === "offer" ? 1 : intentType === "disclaimer" ? 2 : 0,
      statusId: itm.status,
      isArchived: itm.isArchived,
      shortNameOffer: itm.shortNameOffer,
      url: itm.linkUrl,
      affiliateId: itm.affiliateId,
      companies: companiesInfo.toJS().filter(itmx => itmx.id === itm.companyId),
      organizations: constructOrgPropList(itm),
      monetizationRegions: constructMonetizationRegions(itm),
      startDateTime: itm.startDateTimeInTimeZone
        ? itm.startDateTimeInTimeZone
        : null,
      endDateTime: itm.endDateTimeInTimeZone ? itm.endDateTimeInTimeZone : null,
      timeZoneIANA: timeZoneIANA,
      linkDetailText: itm.promoText,
      linkTypeId: itm.linkTypeId,
      assetLink: itm.assetLink,
      ownerAccountId: itm.ownerAccountId
    }
    if (itm.id) {
      saveContent.id = itm.id
    }
    return saveContent
  }

  const createLinkObject = (itm, create, update) => {
    let regionJustId = []
    let isConversionPercentPayOut
    if (itm.isNewRightClickLink) {
      if (itm.companyKey) {
        itm.companies = companiesInfo
          .toJS()
          .filter(itmx => itmx.name === itm.companyKey)
      }
      if (itm.orgKey) {
        const allOrgs = [...organizations]
        itm.organizations = allOrgs.filter(org => org.name === itm.orgKey)
        itm.organizationId = itm.organizations[0].id
      }
    }
    itm.region = itm.monetizationRegions
    itm.region &&
      itm.region.forEach(itmm => {
        regionJustId.push(
          itmm.country ? itmm.country.id : itmm.region ? itmm.region.id : 0
        )
        isConversionPercentPayOut = itmm.payoutTypeId === 1 ? true : false
      })
    let regionsAllParams = []
    itm.region &&
      itm.region.forEach((itmm, i) => {
        regionsAllParams.push({
          id: itmm.country ? itmm.country.id : itmm.region ? itmm.region.id : 0,
          payoutPercentage: itmm.payoutValue,
          payoutException: itmm.isException,
          indexId: itmm.id,
          index: i
        })
      })
    let isUSA = regionsAllParams.filter(itm => itm.id === 237)
    const regionPayoutGroup = groupRegionByPayoutFunc(regionsAllParams)
    const payoutExceptionsExist =
      regionPayoutGroup[1] &&
      regionPayoutGroup[1][regionPayoutGroup[1].length - 1] &&
      regionPayoutGroup[1][regionPayoutGroup[1].length - 1].payoutException ===
        true &&
      regionPayoutGroup[1][regionPayoutGroup[1].length - 1].id
        ? true
        : false
    if (regionsAllParams.length === 1 && isUSA.length) {
      let tmp = []
      regionsList.forEach(rgn => {
        rgn.payoutPercentage = regionsAllParams[0].payoutPercentage
        tmp.push(rgn)
      })
      regionsAllParams = tmp
    } else if (isUSA.length) {
      let tmp = []
      regionsList.forEach(rgn => {
        let rgnMatch = regionsAllParams.filter(itm => itm.id === rgn.id)
        if (rgnMatch.length) {
          rgn.indexId = rgnMatch[0].indexId
          rgn.payoutPercentage = rgnMatch[0].payoutPercentage
          tmp.push(rgn)
        } else {
          rgn.payoutPercentage = isUSA[0].payoutPercentage
          tmp.push(rgn)
        }
      })
      regionsAllParams = tmp
    }
    const justPayoutsGrouped = []
    regionPayoutGroup.forEach(itm => {
      if (itm[0] && typeof itm[0].payoutPercentage === "number") {
        justPayoutsGrouped.push(itm[0].payoutPercentage)
      }
    })

    const startDateTime = itm.startDateTime
      ? dayjs(itm.startDateTime).local()
      : null
    const endDateTime = itm.endDateTime ? dayjs(itm.endDateTime).local() : null
    const propertyIds = []
    const firstOrgProperties =
      (itm.organizations &&
        itm.organizations[0] &&
        itm.organizations[0].properties) ||
      []
    if (firstOrgProperties.length) {
      firstOrgProperties.forEach(itm => propertyIds.push(itm.id))
    }

    const formValues = update
      ? {
          ...itm,
          region: regionJustId || [],
          status: itm.statusId
        }
      : !create
      ? {
          ctaId: itm.id,
          id: itm.id,
          linkUrl: itm.url,
          organizationName: itm.organizationName,
          organizations: itm.organizations,
          organizationId:
            (itm.organizations[0] && itm.organizations[0].id) || 0,
          propertyIds: propertyIds,
          leagueId: itm.leagueId,
          leagueName: itm.leagueName,
          formFactor: itm.tagId,
          shortNameOffer: itm.shortNameOffer,
          region: regionJustId || [],
          regionsAllParams: regionsAllParams,
          campaignName: itm.callToActionCampaignName || "",
          companyId: (itm.companies[0] && itm.companies[0].id) || 0,
          affiliateId: itm.affiliateId || "",
          callToActionPayOff:
            justPayoutsGrouped && justPayoutsGrouped[0]
              ? justPayoutsGrouped[0]
              : isUSA.length && isUSA[0].payoutPercentage
              ? isUSA[0].payoutPercentage
              : "",
          prioritizationOverride:
            itm.prioritizationOverride || itm.prioritizationOverride === 0
              ? itm.prioritizationOverride
              : "",
          callToActionName: itm.name || "",
          callToActionTypeId: itm.callToActionTypeId,
          affiliateLink: itm.affiliateLink || "",
          assetLink: itm.assetLink || "",
          promoText: itm.linkDetailText || "",
          linkTypeId: itm.linkTypeId || "",
          callToActionText: itm.callToActionText || "",
          ownerAccountId: itm.ownerAccountId,
          status: itm.statusId,
          startDate: startDateTime,
          endDate: endDateTime,
          timeZoneIANA: itm.timeZoneIANA,
          isArchived: itm.isArchived || false,
          template: itm.template,
          isConversionPercentPayOut,
          payoutException: payoutExceptionsExist,
          groupRegionByPayout: regionPayoutGroup,
          justPayoutsGrouped: justPayoutsGrouped
        }
      : {
          ctaId: null,
          id: null,
          linkUrl: "",
          organizationId: itm.organizationId,
          propertyIds: [],
          organizationName: itm.organizationName || "",
          organizations: itm.organizations,
          leagueId: itm.leagueId,
          leagueName: itm.leagueName || "",
          formFactor: "",
          shortNameOffer: "",
          region: [],
          regionsAllParams: [],
          campaignName: "",
          companyId:
            (itm.companies && itm.companies[0] && itm.companies[0].id) || 0,
          affiliateId: "",
          callToActionPayOff: 0,
          prioritizationOverride: "",
          callToActionName: "",
          callToActionTypeId: "",
          affiliateLink: "",
          assetLink: "",
          promoText: "",
          linkTypeId: "",
          callToActionText: "",
          ownerAccountId: null,
          status: 3,
          startDate: dayjs().local(),
          endDate: null,
          isArchived: false,
          isConversionPercentPayOut: false,
          template: "",
          payoutException: false,
          groupRegionByPayout: regionPayoutGroup,
          justPayoutsGrouped: justPayoutsGrouped //,
        }
    setPayoutException(formValues.payoutException)
    setRegionByPayout(formValues.groupRegionByPayout)
    setExceptionPayouts(justPayoutsGrouped)
    setOrigFormValues(formValues)
    return formValues
  }

  const groupRegionByPayoutFunc = myRegions => {
    let grouped = []
    if (myRegions.length === 1 && myRegions[0].id === 237) {
      //USA,, pass in all 51 regions
      let grp = []
      regionsList.forEach(itm => {
        itm.payoutPercentage = myRegions[0].payoutPercentage
        grp.push(itm)
      })
      grouped.push(grp)
    } else {
      myRegions.forEach(itm => {
        if (!grouped.length) {
          grouped.push([itm])
        } else {
          let groupExists = false
          grouped.forEach(grp => {
            if (
              !groupExists &&
              grp[0].payoutPercentage === itm.payoutPercentage
            ) {
              grp.push(itm)
              groupExists = true
            }
          })
          if (!groupExists) {
            grouped.push([itm])
          }
        }
      })
    }
    if (grouped.length < 2) {
      grouped.push([
        {
          index: grouped.length,
          regionId: 0,
          id: 0,
          payoutPercentage: 0,
          display: true,
          isNew: true,
          payoutException: true
        }
      ])
    } else {
      grouped.sort(function (a, b) {
        //Need to make sure the Group with NO exceptions is FIRST
        let left =
          a[0] && a[0].payoutException === false
            ? 1
            : a[0] && a[0].payoutException === true
            ? -1
            : 0
        let right =
          b[0] && b[0].payoutException === false
            ? 1
            : b[0] && b[0].payoutException === true
            ? -1
            : 0
        return right - left
      })
    }
    return grouped
  }

  const unselectUSA_ModifyRegionsByPayout = val => {
    let tmp = null
    for (let i = regionByPayout[0].length - 1; i > -1; i -= 1) {
      tmp = !tmp ? regionByPayout[0][i] : tmp
      regionByPayout[0].splice(i, 1)
    }
    if (val && tmp) {
      tmp.id = val[0]
      tmp.payoutException = false
      tmp.indexId = 0
      regionByPayout[0].push(tmp)
      setRegionByPayout(regionByPayout)
    }
  }

  const defaultStatesExist = nvx => {
    let found = false
    regionByPayout.forEach((grp, index) => {
      grp.forEach((itm, index2) => {
        if (nvx.includes(itm.id)) {
          if (itm.payoutException === false) {
            found = true
          }
          nvx.splice(nvx.indexOf(itm.id), 1)
        }
      })
    })
    if (nvx.length) {
      found = nvx
    }
    return found
  }

  const addRegionsToPayoutGroups = nv => {
    let selectedRegions = []
    nv &&
      nv.forEach((itm, i) => {
        selectedRegions.push({
          regionId: itm.id,
          id: itm.id,
          payoutPercentage: callToActionPayOff,
          payoutException: false,
          indexId: 0,
          index: i
        })
      })

    regionByPayout[0] = selectedRegions
    if (!regionByPayout[1]) {
      regionByPayout[1] = []
      regionByPayout[1].push({
        regionId: 0,
        id: 0,
        payoutPercentage: 0,
        display: true,
        isNew: true,
        payoutException: true
      })
    }
    setRegionByPayout(regionByPayout)
  }

  const payoutExceptionsExist = () => {
    let foundExceptions = false
    regionByPayout?.forEach((grp, index) => {
      grp.forEach(itm => {
        if (
          ((itm.regionId && itm.regionId !== _USA_REGION) ||
            (itm.id && itm.id !== _USA_REGION)) &&
          itm.payoutException === true
        ) {
          foundExceptions = true
        }
      })
    })
    return foundExceptions
  }

  const checkIfWeNeedToRemoveRegionFromExceptions = vals => {
    let found = false
    vals.forEach(itm => {
      regionByPayout?.forEach((grp, index) => {
        for (let i = grp.length - 1; i > -1; i -= 1) {
          if (itm === grp[i].id) {
            regionByPayout[index].splice(i, 1)
            found = true
          }
        }
      })
    })
    if (found) {
      setRegionByPayout(regionByPayout)
    }
  }

  const thisIsAPayoutException = vals => {
    let found = false
    vals.forEach(val => {
      regionByPayout?.forEach((grp, index) => {
        if (index) {
          for (let i = grp.length - 1; i > -1; i -= 1) {
            if (val === grp[i].id) {
              found = true
            }
          }
        }
      })
    })
    return found
  }
  return {
    checkIfWeNeedToDisablePayoutExceptions,
    removeGroupPayoutExceptions,
    removePayoutExceptions,
    updateSelectedRegions,
    createLinkObject,
    unselectUSA_ModifyRegionsByPayout,
    defaultStatesExist,
    addRegionsToPayoutGroups,
    payoutExceptionsExist,
    checkIfWeNeedToRemoveRegionFromExceptions,
    thisIsAPayoutException,
    formValueValidation,
    checkForChangedNavElements,
    linkSaveObject
  }
}
export default useDisclaimerFormHelper
