import React, { useEffect, useState } from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import Button from "components/CustomButtons/Button.jsx"
import {
  //Box,
  Grid,
  Dialog,
  DialogTitle
  //LinearProgress
  //Button
} from "@mui/material"

import CardBody from "components/Card/CardBody.jsx"
import CodeEditor from "components/CodeEditor"
import { Cookies } from "tools/storage"
import RealTimeDAO from "daos/realTimeDAO"
import WordCounter from "components/WordCounter/WordCounter"

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    setSuccessMessage: store.uiStore.setSuccessMessage,
    setIsSuccess: store.uiStore.setIsSuccess,
    setDialogFailMessage: store.uiStore.setDialogFailMessage,
    setDialogFailOpen: store.uiStore.setDialogFailOpen
  }))
}

export default function ScratchPadForRealTime({ modelId }) {
  const cookies = new Cookies()
  const spStorage =
    (!!cookies.get("aRealTimeScratchPad") &&
      cookies.get("aRealTimeScratchPad").savedScratchPads) ||
    []
  const {
    setSuccessMessage,
    setIsSuccess,
    setDialogFailMessage,
    setDialogFailOpen
  } = useStoreData()
  const [template, setTemplate] = useState("")
  const [templatePreview, setTemplatePreview] = useState("")
  const [starters, setStarters] = useState([])
  const [savedStarter, setSavedStarter] = useState(false)
  const [spChanged, setSpChanged] = useState(false)
  const [showTemplatePreview, setShowTemplatePreview] = useState(false)

  const parseAndBuildPreview = async () => {
    try {
      if (!template) {
        throw new Error("template values are missing.")
      }
      const templateString = template
      const previewRequest = { template: templateString }
      const modelTypeId = modelId

      const response = await RealTimeDAO.putPreviewModelTypeId(
        previewRequest,
        modelTypeId
      )
      const evaluatedTemplate = await response.text()
      if (response.ok) {
        setTemplatePreview(evaluatedTemplate)
        setShowTemplatePreview(true)
      } else {
        console.error("Failed to fetch template preview:", evaluatedTemplate)

        setTemplatePreview(evaluatedTemplate)
        setShowTemplatePreview(true)
      }
    } catch (error) {
      console.error("Error while fetching template preview:", error)
      setDialogFailMessage(`${error.message}`)
      setDialogFailOpen(true)
    }
  }

  const verifySaveStarterScratchPad = () => {
    if (spStorage.length > 0) {
      const temp = []
      spStorage.forEach(x => {
        if (x.model === modelId) {
          x.starter = template
        }
        temp.push(x)
      })
      cookies.set("aRealTimeScratchPad", { savedScratchPads: temp })
      setSuccessMessage("You succesfully saved the model to Local Storage")
      setIsSuccess(true)
      setSpChanged(false)
    }
  }

  useEffect(() => {
    if (spStorage.length > 0) {
      spStorage.forEach(x => {
        if (x.model === modelId) {
          setTemplate(x.starter || "")
        }
      })
    } else if (starters.length === 0) {
      setStarters([
        { name: "Model 1 Starter", model: 1, starter: "" },
        { name: "Model 2 Starter", model: 2, starter: "" }
      ])
    }
  }, [])

  useEffect(() => {
    if (starters?.length > 0 && spStorage?.length === 0) {
      const temp = []
      starters.forEach(x => {
        if (x.model === modelId) {
          setTemplate(x.starter || "")
        }
        temp.push(x)
      })
      cookies.set("aRealTimeScratchPad", { savedScratchPads: temp })
    }
  }, [starters])

  useEffect(() => {
    const spStr =
      (!!cookies.get("aRealTimeScratchPad") &&
        cookies.get("aRealTimeScratchPad").savedScratchPads) ||
      []
    if (spStr.length > 0) {
      spStr.forEach(x => {
        if (x.model === modelId) {
          setTemplate(x.starter || "")
        }
      })
    }
  }, [modelId])

  const displayScratchPad = modelId && Number(modelId) > 0 ? true : false

  return (
    <>
      {displayScratchPad && (
        <CardBody>
          <Grid>
            <CodeEditor
              withTabs
              template={template}
              handleFocus={editor => {
                if (editor.getValue() === "Enter Starter Here") {
                  setTemplate("")
                  editor.setValue("")
                }
              }}
              isNotLibrary={false}
              handleChange={editor => {
                if (editor && editor.getValue() !== "template") {
                  const editorContent = editor.getValue()
                  setSpChanged(true)
                  setTemplate(editorContent)
                } else if (editor.getValue() === "Enter Starter Here") {
                  setTemplate("")
                }
              }}
            />
            {showTemplatePreview && (
              <Grid
                container
                spacing={1}
                item
                xs={12}
                style={{
                  display: "inline-block",
                  paddingTop: "16px",
                  paddingLeft: "24px"
                }}
              >
                <WordCounter
                  aria-label="RealTime DataBar wordCounterTemplate"
                  title="Metrics"
                  inputString={template}
                />
                <Grid
                  item
                  style={{
                    marginTop: "10px",
                    fontSize: "16px"
                  }}
                >
                  <div
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                      position: "relative"
                    }}
                    dangerouslySetInnerHTML={{
                      __html:
                        templatePreview && templatePreview.replace(/apxh:/g, "")
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              color="secondary"
              onClick={() => parseAndBuildPreview()}
              //disabled={noTestContent}
            >
              Test
            </Button>
            <Button
              color="primary"
              onClick={() => verifySaveStarterScratchPad()}
              disabled={!spChanged}
            >
              Save
            </Button>
          </Grid>
        </CardBody>
      )}
      <Dialog
        open={savedStarter}
        onClose={() => {
          setSavedStarter(false)
        }}
        aria-labelledby="confirm-dialog"
      >
        <DialogTitle id="confirm-dialog">
          Your Real Time Starter has been saved
        </DialogTitle>
      </Dialog>
    </>
  )
}
