import React, { Component } from "react"
import { inject, observer } from "mobx-react"
import { TextField, Select, MenuItem, FormControl } from "@mui/material"
import { Link } from "react-router-dom"
import { Checkbox } from "@mui/material"
import CustomInput from "components/CustomInput/CustomInput.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import { globalSettings } from "variables/general.jsx"
import CardIcon from "components/Card/CardIcon.jsx"
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile"
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle"
import withStyles from "@mui/styles/withStyles"
import TimeAgo from "react-timeago"
import Helpers from "tools/Helpers.js"
import Button from "components/CustomButtons/Button.jsx"
import CardFooter from "components/Card/CardFooter.jsx"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import Enums from "tools/Enums.js"
import ArrowBack from "@mui/icons-material/ArrowBack"
import Slider from "@mui/material/Slider"
import { get, put } from "tools/request"

const ENTER_KEY = 13

const useStore = component =>
  inject(({ store }) => ({
    retrieveLeagues: store.sportsStore.retrieveLeagues,
    sortedLeagues: store.sportsStore.sortedLeaguesByName
  }))(observer(component))

class FileEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      output: {},
      asset: [],
      originalPlainText: "Loading...",
      sourcetype_id: "",
      gender_id: "",
      isDataDirty: false,
      isSaved: false,
      isAvailableForGraphicsTool: false,
      imageNaturalWidth: 0,
      imageNaturalHeight: 0,
      imageDisplayWidth: 0,
      imageDisplayHeight: 0
    }
    this.onPlainTextAreaKeyDown = this.onPlainTextAreaKeyDown.bind(this)
    this.retrieveAsset(props.match.params.assetId)
  }

  componentDidMount() {
    this.props.retrieveLeagues()
  }

  retrieveAsset = assetId => {
    get(
      `${globalSettings.apiBaseUrl}/api/asset/id/${assetId}?includecontent=true`
    )
      .then(response => response.json())
      .then(data => {
        this.setState({
          asset: data.content,
          originalPlainText: data.content.textContent,
          gender_id: data.content.gender_Id,
          isAvailableForGraphicsTool: data.content.isAvailableForGraphicsTool,
          imageDisplayWidth: data.content.width > 300 ? 300 : data.content.width
        })
        if (data.content.sourceType_Id > 0) {
          this.setState({
            sourcetype_id: data.content.sourceType_Id
          })
        }
      })
  }

  onSourceTypeChange = e => {
    this.setState({ sourcetype_id: e.target.value, isDataDirty: true })
  }

  onGenderIdChange = e => {
    this.setState({ gender_id: e.target.value, isDataDirty: true })
  }

  onIsAvailableForGraphicsToolChange = e => {
    this.setState({
      isAvailableForGraphicsTool: !this.state.isAvailableForGraphicsTool,
      isDataDirty: true
    })
  }

  onImageLoad({ target: image }) {
    if (image != null) {
      this.setState({
        imageNaturalWidth: image.naturalWidth,
        imageDisplayWidth: image.width,
        imageNaturalHeight: image.naturalHeight,
        imageDisplayHeight: image.height
      })
    }
  }

  onImageChange() {
    console.log("onImageChange")
  }

  updateAsset = () => {
    let sourceTypeId = this.state.sourcetype_id
    let gender_id = this.state.gender_id

    if (sourceTypeId === "") {
      sourceTypeId = 0
    }

    put(`${globalSettings.apiBaseUrl}/api/asset/update`, null, {
      Id: this.state.asset.id,
      PlainText: this.state.asset.textContent,
      SourceType_Id: sourceTypeId,
      Gender_Id: gender_id,
      IsAvailableForGraphicsTool: this.state.isAvailableForGraphicsTool
    })
      .then(response => response.json())
      .then(() => {
        this.setState({
          isDataDirty: false,
          isSaved: true,
          originalPlainText: this.state.asset.textContent
        })
      })
  }

  onSaveButtonClick = () => {
    this.updateAsset()
  }

  onPlainTextAreaChange = e => {
    let isDirty = false
    if (this.state.originalPlainText !== e.target.value) {
      isDirty = true
    }
    this.setState({
      asset: {
        ...this.state.asset,
        textContent: e.target.value
      },
      isDataDirty: isDirty
    })
  }

  onPlainTextAreaKeyDown(e) {
    if (e.ctrlKey && e.keyCode === ENTER_KEY) {
      //    do something when the enter key is pressed
    }
  }

  onImageDisplayWidthChange(value) {
    this.setState({ imageDisplayWidth: value })
  }

  render() {
    const { classes } = this.props
    const { asset } = this.state
    let isTextFile = false
    if (asset && asset.mimeType && asset.mimeType.indexOf("text") !== -1) {
      isTextFile = true
    }

    return (
      <GridContainer style={{ height: "100%" }}>
        <Dialog
          open={this.state.isSaved}
          onClose={() => this.setState({ isSaved: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Changes Saved</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Your changes have been saved.
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <InsertDriveFileIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>File Editor</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem md={12}>
                  <Link to={`/portal/filemanager`} title="Back to file manager">
                    <h6>
                      <ArrowBack />
                      Back to file manager
                    </h6>
                  </Link>
                </GridItem>
                <GridItem md={2}>
                  <h6>File ID</h6>
                  {asset.id}
                </GridItem>
                <GridItem md={2}>
                  <h6>File Token</h6>
                  <div>{asset.assetToken}</div>
                </GridItem>
                <GridItem md={2}>
                  <h6>File Size</h6>
                  <div>{asset.fileSizeInBytes?.toLocaleString()} bytes</div>
                </GridItem>
                <GridItem md={3}>
                  <h6>MIME Type</h6>
                  <div>{asset.mimeType}</div>
                </GridItem>
                <GridItem md={12}>
                  <h6>Filename</h6>
                  {asset.fileName}
                </GridItem>
                {this.state.asset.sportsLeague && (
                  <GridContainer>
                    <GridItem md={6}>
                      <h6>Sports League</h6>
                      {this.state.asset.sportsLeague?.name}
                    </GridItem>
                  </GridContainer>
                )}
                {asset.mimeType?.indexOf("image/") !== -1 && (
                  <>
                    <GridItem md={4}>
                      <h6>Image (width={this.state.imageDisplayWidth})</h6>
                      <Slider
                        value={this.state.imageDisplayWidth}
                        onChange={(e, value) =>
                          this.onImageDisplayWidthChange(value)
                        }
                        aria-labelledby="input-slider"
                        max={asset.width}
                        min={50}
                        step={10}
                        valueLabelDisplay="auto"
                      />
                      <h6>Image Dimensions</h6>
                      <div>
                        {asset.width} x {asset.height} (original)
                      </div>
                      <div>
                        {this.state.imageNaturalWidth} x{" "}
                        {this.state.imageNaturalHeight} (natural),{" "}
                        {this.state.imageDisplayWidth} x{" "}
                        {this.state.imageDisplayHeight} (displayed)
                      </div>
                      <img
                        src={`${globalSettings.cdnBaseUrl}/api/asset/${asset.assetToken}.${asset.mimeTypeExtension}?w=${asset.width}`}
                        alt=""
                        onLoad={this.onImageLoad.bind(this)}
                        onChange={this.onImageChange.bind(this)}
                        style={{ width: this.state.imageDisplayWidth }}
                      />
                    </GridItem>
                    <GridItem md={3}>
                      <h6>Graphics Tool?</h6>
                      <Checkbox
                        id="isavailableforgraphicstool"
                        fullWidth
                        checked={this.state.isAvailableForGraphicsTool}
                        onChange={this.onIsAvailableForGraphicsToolChange}
                      />
                    </GridItem>
                  </>
                )}
                {asset.mimeType?.indexOf("image/") === -1 && (
                  <>
                    <GridItem>
                      <h6>Source Type Id</h6>
                      <TextField
                        type="text"
                        id="sourcetype_id"
                        fullWidth
                        value={this.state.sourcetype_id}
                        onChange={this.onSourceTypeChange}
                      />
                    </GridItem>
                    <GridItem md={2}>
                      <h6>Gender</h6>
                      <FormControl>
                        <Select
                          type="text"
                          id="gender_id"
                          label={"Gender ID"}
                          fullWidth
                          value={this.state.gender_id || 0}
                          onChange={this.onGenderIdChange}
                        >
                          <MenuItem key="Default" value="0">
                            N/A
                          </MenuItem>
                          <MenuItem key="Womens" value={Enums.Gender.FEMALE}>
                            Womens
                          </MenuItem>
                          <MenuItem key="Mens" value={Enums.Gender.MALE}>
                            Mens
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                  </>
                )}
                <GridItem>
                  <h6>Audit Info</h6>
                  {asset.auditInfo != null && (
                    <React.Fragment>
                      created{" "}
                      <TimeAgo
                        date={asset.auditInfo.createdOn}
                        title={Helpers.prettyDateTimeinPacificTimeZone(
                          asset.auditInfo.createdOn
                        )}
                      />{" "}
                      by {asset.auditInfo.creator.username} <br />
                      modified{" "}
                      <TimeAgo
                        date={asset.auditInfo.modifiedOn}
                        title={Helpers.prettyDateTimeinPacificTimeZone(
                          asset.auditInfo.modifiedOn
                        )}
                      />{" "}
                      by {asset.auditInfo.modifier.username}
                    </React.Fragment>
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer />
              {isTextFile && (
                <form>
                  <CustomInput
                    id="plainText"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      multiline: true,
                      rows: 25,
                      placeholder: "Loading...",
                      value: asset.textContent,
                      onChange: this.onPlainTextAreaChange,
                      onKeyDown: this.onPlainTextAreaKeyDown,
                      disabled: !isTextFile
                    }}
                    helpText="Edit the text file."
                  />
                </form>
              )}
            </CardBody>
            <CardFooter>
              <Button
                onClick={() => this.onSaveButtonClick()}
                disabled={!this.state.isDataDirty}
              >
                Save
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

export default withStyles(regularFormsStyle)(useStore(FileEditor))
