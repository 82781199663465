import React, { Component } from "react"
import PropTypes from "prop-types"

class WordCounter extends Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      paragraphCount: 0,
      sentenceCount: 0,
      wordCount: 0,
      characterCount: 0,
      allCharacterCount: 0,
      renderTimeInMilliseconds: 0
    }
  }

  componentDidMount() {
    if (this.props.inputString) {
      this.countString(this.props.inputString)
    }
  }
  componentDidUpdate(nextProps) {
    if (
      this.props.inputString &&
      nextProps.inputString !== this.props.inputString
    ) {
      this.countString(this.props.inputString)
    }
  }

  countString(inputString) {
    const trimmedString = inputString.trim()
    const paragraphCount =
      (trimmedString.match(/\n|<apxh:p>.*?<\/apxh:p>|<p>.*?<\/p>/gi) || [])
        .length + 1
    const noMarkupString = trimmedString.replace(/<\/?[a-z][^>]*>/gi, "")
    const sentenceCount = (noMarkupString.match(/[.?!…]+./g) || []).length + 1
    const wordCount = (
      noMarkupString.replace(/['":,.?¿\-!¡]+/g, "").match(/\S+/g) || []
    ).length
    const characterCount = noMarkupString.replace(/\s/g, "").length
    const allCharacterCount = noMarkupString.length

    this.setState({
      paragraphCount: paragraphCount,
      sentenceCount: sentenceCount,
      wordCount: wordCount,
      characterCount: characterCount,
      allCharacterCount: allCharacterCount
    })
  }

  render() {
    const { className, title } = this.props
    if (!this.props.inputString) {
      return null
    }

    return (
      <div className={className}>
        <span style={{ color: "#000", fontWeight: "bold" }}>
          {title}
          &nbsp;
        </span>
        &nbsp; (
        <span>
          <b>Paragraphs:</b> {this.state.paragraphCount}&nbsp;
        </span>
        &nbsp;
        <span>
          <b>Sentences:</b> {this.state.sentenceCount}&nbsp;
        </span>
        &nbsp;
        <span>
          <b>Words:</b> {this.state.wordCount}&nbsp;
        </span>
        &nbsp;
        <span>
          <b>Characters:</b> {this.state.characterCount}&nbsp;
        </span>
        &nbsp;
        <span>
          <b>All Characters:</b> {this.state.allCharacterCount}&nbsp;
        </span>
        {this.props.renderTimeInMilliseconds > 0 && (
          <span>
            <b>Elapsed time:</b> {this.props.renderTimeInMilliseconds} ms
          </span>
        )}
        )
      </div>
    )
  }
}
WordCounter.propTypes = {
  inputString: PropTypes.string,
  className: PropTypes.string,
  renderTimeInMilliseconds: PropTypes.number
}
export default WordCounter
