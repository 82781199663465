import { ListItem, List } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles(theme => ({
  sublist: {
    paddingTop: 0,
    paddingBottom: 0
  },
  subListItem: {
    display: "list-item",
    paddingTop: 0,
    paddingBottom: theme.spacing(0.5)
  },
  oldValue: {
    fontWeight: "bold",
    color: theme.palette.grey[700]
  },
  newValue: {
    fontWeight: "bold"
  },
  changedField: {
    fontWeight: "bold",
    color: "#036"
  }
}))

const ItemTriggerListItem = ({ trigger, index, changeList }) => {
  const classes = useStyles()

  const isModifiedTrigger = changeList.filter(
    itm => Number(itm.itemId) === Number(trigger.id)
  )

  return isModifiedTrigger.length ? (
    <ListItem className={classes.subListItem}>
      <List className={classes.sublist}>
        <span className={classes.newValue}>{`${index + 1}: `}</span>
        {`MODIFIED: We are copying Trigger `}
        <span className={classes.oldValue}>{isModifiedTrigger[0].itemId}</span>
        {isModifiedTrigger[0].changes.map((change, i) => (
          <ListItem className={classes.subListItem} key={i}>
            <span className={classes.changedField}>"{change.field}"</span>
            {` changed from`}
            <span className={classes.oldValue}>{` ${change.oldValue} `}</span>
            to
            <span className={classes.newValue}>{` ${change.newValue}`}</span>
          </ListItem>
        ))}
      </List>
    </ListItem>
  ) : (
    <ListItem className={classes.subListItem}>
      <List className={classes.sublist}>
        <span className={classes.newValue}>{`${index + 1}: `}</span>
        {`UNMODIFIED: We are copying Trigger `}
        <span className={classes.oldValue}>{` ${trigger.id} `}</span>
        {" - "}
        <span className={classes.newValue}>{` ${trigger.type}`}</span>
      </List>
    </ListItem>
  )
}

export default ItemTriggerListItem
