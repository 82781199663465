import { globalSettings } from "variables/general.jsx"
import { get } from "../tools/request"

export default class querybuilderDAO {
  static getQueries = async () => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/querybuilder/retrievequerynames`
    )
    return await res.json()
  }
}
