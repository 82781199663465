import React, { Component } from "react"
import Button from "components/CustomButtons/Button"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem.jsx"
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle"
import withStyles from "@mui/styles/withStyles"
import { globalSettings, awsTextTranslateLanguages } from "variables/general"
import { Select, MenuItem, Checkbox } from "@mui/material"
import AudioPlayer from "../FeedAdmin/AudioPlayer"
import Helpers from "tools/Helpers.js"
import Tooltip from "@mui/material/Tooltip"
import Check from "@mui/icons-material/Check"
import { observer, inject } from "mobx-react"
import { put } from "tools/request"

const WAIT_INTERVAL = 500

const useStore = component =>
  inject(({ store }) => ({
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization
  }))(observer(component))

class TranslateTextNow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      text1: this.props.text,
      sourceLanguageCode: "en",
      text2: "",
      targetLanguageCode: "es-MX",
      lastTranslatedText: "",
      isTextTranslastionHappending: false,
      availableLanguages: awsTextTranslateLanguages,
      isDisplayOriginalTextEnabled: true
    }
  }

  componentDidMount() {
    this.props.setShowChangeCurrentOrganization(false)
    this.translateTextNow(
      this.state.text1,
      this.state.sourceLanguageCode,
      this.state.targetLanguageCode
    )
  }

  translateTextNow = (text, sourceLanguageCode, targetLanguageCode) => {
    this.setState({
      isTextTranslastionHappending: true,
      text2: "translating..."
    })
    put(`${globalSettings.apiBaseUrl}/api/translatetext`, null, {
      inputText: text,
      sourceLanguageCode,
      targetLanguageCode,
      isHTMLText: true // assume all this text is HTML
    })
      .then(response => response.json())
      .then(response => {
        this.setState({
          isTextTranslastionHappending: false,
          lastTranslatedText: response.content.outputText,
          text2: response.content.outputText
        })
      })
  }

  onSwapButtonClick = () => {
    let oldText1 = this.state.text1
    let oldSourceLanguageCode = this.state.sourceLanguageCode
    let oldText2 = this.state.text2
    let oldTargetLanguageCode = this.state.targetLanguageCode
    this.setState(
      {
        text1: oldText2,
        sourceLanguageCode: oldTargetLanguageCode,
        targetLanguageCode: oldSourceLanguageCode,
        text2: oldText1
      },
      () =>
        this.translateTextNow(
          this.state.text1,
          this.state.sourceLanguageCode,
          this.state.targetLanguageCode
        )
    )
  }

  onText1AreaChange = event => {
    this.setState({ text1: event.target.value })
    clearTimeout(this.timer)
    this.timer = setTimeout(
      () =>
        this.translateTextNow(
          this.state.text1,
          this.state.sourceLanguageCode,
          this.state.targetLanguageCode
        ),
      WAIT_INTERVAL
    )
  }

  onText2AreaChange = event => {
    this.setState({ text2: event.target.value })
  }

  onDisplayOriginalTextCheckBoxChange = () => {
    this.setState({
      isDisplayOriginalTextEnabled: !this.state.isDisplayOriginalTextEnabled
    })
  }

  render() {
    const { classes } = this.props
    const { isDisplayOriginalTextEnabled } = this.state
    const menuItems = this.state.availableLanguages.map(item => (
      <MenuItem
        key={item.languageCode}
        value={item.languageCode}
        classes={{
          root: classes.selectMenuItem
        }}
      >
        {item.name}
      </MenuItem>
    ))

    return (
      <GridItem xs={12} sm={12} md={12}>
        <GridContainer>
          {this.state.isDisplayOriginalTextEnabled && (
            <GridItem md={6}>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    this.state.text1 && this.state.text1.replace(/apxh:/g, "")
                }}
                style={{ paddingTop: "48px" }}
              />
            </GridItem>
          )}
          <GridItem md={isDisplayOriginalTextEnabled ? 6 : 12}>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={this.state.targetLanguageCode}
              onChange={event => {
                this.setState(
                  {
                    targetLanguageCode: event.target.value
                  },
                  () =>
                    this.translateTextNow(
                      this.state.text1,
                      this.state.sourceLanguageCode,
                      this.state.targetLanguageCode
                    )
                )
              }}
              inputProps={{
                name: "simpleSelect",
                id: "simple-select"
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem
                }}
              >
                Choose a language
              </MenuItem>
              {menuItems}
            </Select>
            <Tooltip title="Play Audio" placement="top">
              <Button
                justIcon
                round
                simple
                color="primary"
                className="info"
                style={{ padding: "2px", height: "0px" }}
              >
                <AudioPlayer
                  rawText={Helpers.stripHtml(this.state.text2)}
                  voiceName={"lupe"}
                  engineName={"neural"}
                  languageCode={"es-MX"}
                />
              </Button>
            </Tooltip>
            <Checkbox
              checked={this.state.isDisplayOriginalTextEnabled}
              onChange={this.onDisplayOriginalTextCheckBoxChange}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
            />{" "}
            Display Original Text?
            <div
              dangerouslySetInnerHTML={{
                __html:
                  this.state.text2 && this.state.text2.replace(/apxh:/g, "")
              }}
            />
          </GridItem>
        </GridContainer>
      </GridItem>
    )
  }
}

export default withStyles(regularFormsStyle)(useStore(TranslateTextNow))
