/* eslint-disable arrow-body-style */
import React, { useMemo, useState } from "react"
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CardContent,
  CircularProgress,
  Box,
  Typography
} from "@mui/material"
import { ExpandMore as ExpandMoreIcon, Report } from "@mui/icons-material"
import { makeStyles } from "@mui/styles"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"

import CustomInput from "components/CustomInput/CustomInput"
import ElementPreviewEntries from "./ElementPreviewEntries"
import { onPageSections } from "../Data/contentSections"

const useStyles = makeStyles(theme => ({
  previewHeader: {
    display: "flex"
  },
  previewCard: {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
    margin: 0
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    padding: "0px",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  error: {
    fontWeight: "bold",
    fontSize: "15px",
    marginBottom: "10px",
    color: "#ff8a80",
    display: "inline-block"
  },
  lessMarginUL: {
    marginBottom: "0px !important"
  }
}))

const NarrativePreviewDataView = ({
  activeElements = [],
  badBlocks,
  handleViewFailedBlocks,
  isLoadingPreview,
  renderedNarrative,
  renderTimeInMilliseconds,
  showElementsInGroups = false
}) => {
  const [isRawContentVisibile, setIsRawContentVisibile] = useState(false)
  const [isTechnicalBlocksOpen, setIsTechnicalBlocksOpen] = useState(true)
  const [isOnPageBlocksOpen, setIsOnPageBlocksOpen] = useState(true)

  const classes = useStyles()

  const { content, evaluatedTriggers, validationMessage } = renderedNarrative

  const toggleRawContent = () => {
    setIsRawContentVisibile(prevValue => !prevValue)
  }

  const parseValidationMessageToList = () => {
    let validationMessageAsArray = validationMessage.split(/\r?\n/g)
    const createdNoContent = []
    const createdNoContentParagraph = []
    const contentFailedValidation = []
    const otherIssues = []
    validationMessageAsArray.forEach(itm => {
      if (itm.match(/Outline Block \d{1,7}/i)) {
        createdNoContent.push(itm)
      } else if (itm.match(/^Paragraph Position \d{1,4}/i)) {
        createdNoContentParagraph.push(itm)
      } else if (itm.match(/^CONTENT Failed HTML/i)) {
        contentFailedValidation.push(itm)
      } else if (itm) {
        otherIssues.push(itm)
      }
    })
    return (
      <>
        {otherIssues.length &&
        (createdNoContent.length || createdNoContentParagraph.length) ? (
          <ul>
            {validationMessageAsArray.map(
              (vm, index) => !!vm && <li key={index}>{vm}</li>
            )}
          </ul>
        ) : otherIssues.length ? (
          <ul>
            {otherIssues.map((vm, index) => !!vm && <li key={index}>{vm}</li>)}
          </ul>
        ) : createdNoContent.length ? (
          <>
            <span className={classes.error}>
              Required Outline Blocks that Created No Content:
            </span>
            <ul>
              {createdNoContent.map(
                (vm, index) =>
                  !!vm && (
                    <li key={index}>
                      {vm.split(">>>")[0]}
                      {vm.split(">>>").length > 1 ? (
                        <ul>
                          <li>{vm.split(">>>")[1]}</li>
                        </ul>
                      ) : null}
                    </li>
                  )
              )}
            </ul>
          </>
        ) : createdNoContentParagraph.length ? (
          <>
            <span className={classes.error}>
              Required Paragraphs that Created No Content:
            </span>
            <ul>
              {createdNoContentParagraph.map(
                (vm, index) => !!vm && <li key={index}>{vm}</li>
              )}
            </ul>
          </>
        ) : (
          <></>
        )}
        {contentFailedValidation.length ? (
          <>
            <span className={classes.error}>
              Content that failed validation:
            </span>
            <ul>
              {contentFailedValidation.map(
                (vm, index) => !!vm && <li key={index}>{vm}</li>
              )}
            </ul>
          </>
        ) : null}
      </>
    )
  }

  const killswitches = useMemo(
    () =>
      evaluatedTriggers?.filter(
        //breakpoint
        ev => ev.key.includes("_killswitch") && ev.isTrue
      ),
    [evaluatedTriggers]
  )

  return (
    <CardContent style={{ minHeight: "300px" }}>
      {isLoadingPreview && (
        <CircularProgress
          style={{
            position: "relative",
            margin: "30px 43%"
          }}
          size={80}
        />
      )}
      {!isLoadingPreview && content === null && (
        <div>Narrative and selected data model did not render.</div>
      )}
      {killswitches?.length > 0 && (
        <Box className={classes.error}>
          <Report style={{ color: "#a31545" }} />
          Killswitch Activated
          <ul className={classes.lessMarginUL}>
            {killswitches.map(ks => (
              <li key={ks.id}>
                {ks.id} {ks.key}
              </li>
            ))}
          </ul>
        </Box>
      )}

      {badBlocks.length > 0 && (
        <>
          <Box className={classes.error}>
            <Link
              style={{
                color: "#ff8a80",
                textDecoration: "underline"
              }}
              onClick={handleViewFailedBlocks}
              title="View failed blocks"
            >
              <span
                style={{
                  fontWeight: "bold"
                }}
              >
                {badBlocks.length}
              </span>{" "}
              broken block(s) found
              {!validationMessage && " but passed validation"} - Click to View
            </Link>
          </Box>
          {validationMessage && <Box>{parseValidationMessageToList()}</Box>}
        </>
      )}
      {validationMessage && badBlocks.length === 0 && (
        <Box>{parseValidationMessageToList()}</Box>
      )}
      {/* $TODO If needed make the groups content by dynamic */}
      {showElementsInGroups ? (
        <>
          <Accordion
            expanded={isTechnicalBlocksOpen}
            onChange={() => setIsTechnicalBlocksOpen(prev => !prev)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle2">Technical Blocks</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ElementPreviewEntries
                activeElements={activeElements.filter(
                  element => !onPageSections.includes(element.id)
                )}
                renderedNarrative={renderedNarrative}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={isOnPageBlocksOpen}
            onChange={() => setIsOnPageBlocksOpen(prev => !prev)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle2">
                On-Page Content Blocks
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ElementPreviewEntries
                activeElements={activeElements.filter(element =>
                  onPageSections.includes(element.id)
                )}
                renderedNarrative={renderedNarrative}
                renderTimeInMilliseconds={renderTimeInMilliseconds}
              />
            </AccordionDetails>
          </Accordion>
        </>
      ) : (
        <ElementPreviewEntries
          activeElements={activeElements}
          renderedNarrative={renderedNarrative}
          renderTimeInMilliseconds={renderTimeInMilliseconds}
        />
      )}
      <Accordion expanded={isRawContentVisibile} onChange={toggleRawContent}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle2">Content (raw)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CustomInput
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              multiline: true,
              rows: 10,
              value: content
            }}
          />
        </AccordionDetails>
      </Accordion>
    </CardContent>
  )
}

NarrativePreviewDataView.propTypes = {
  activeElements: PropTypes.arrayOf(PropTypes.object),
  badBlocks: PropTypes.array,
  handleViewFailedBlocks: PropTypes.func,
  isLoadingPreview: PropTypes.bool,
  renderedNarrative: PropTypes.object,
  renderTimeInMilliseconds: PropTypes.number
}

export default NarrativePreviewDataView
