import React, { Component } from "react"
import Narratives from "views/NarrativeAdmin/Narratives"
import { observer, inject } from "mobx-react"
import Enums from "tools/Enums.js"

const useStore = component =>
  inject(({ store }) => ({
    organizationId: store.organizationStore.organizationId,
    setOrganizationId: store.organizationStore.setOrganizationId,
    setOrganization: store.organizationStore.setOrganization,
    organizations: store.organizationStore.organizations,
    organization: store.organizationStore.organization
  }))(observer(component))

class Libraries extends Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  render() {
    if (!this.props.organization || !this.props.organization.name) {
      return "Assembling..."
    }
    let isAccountAssociatedToLibraries = false
    if (
      this.props.organizations
        .toJS()
        .filter(o => o.id === Enums.Organizations.Libraries)
    ) {
      isAccountAssociatedToLibraries = true
    }
    return (
      <>
        {isAccountAssociatedToLibraries && (
          <Narratives isLibrariesOnly={true} />
        )}
        {!isAccountAssociatedToLibraries && (
          <h1
            style={{
              textAlign: "center",
              padding: "40px 0"
            }}
          >
            User does not have permissions to the "Libraries" Organization
          </h1>
        )}
      </>
    )
  }
}
export default useStore(Libraries)
