import React from "react"
import withStyles from "@mui/styles/withStyles"
import Dialog from "@mui/material/Dialog"
import MuiDialogTitle from "@mui/material/DialogTitle"
import MuiDialogContent from "@mui/material/DialogContent"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import Typography from "@mui/material/Typography"
import Info from "@mui/icons-material/Info"
import { makeStyles } from "@mui/styles"

const styles = theme => ({
  root: {
    margin: 2,
    padding: theme.spacing(2),
    textAlign: "left",
    color: "#000"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
})

const useStyles = makeStyles({
  paragraph: {
    marginLeft: "9px",
    fontWeight: 400,
    lineHeight: 1.75,
    marginBottom: "9px"
  },
  infoButton: {
    padding: 0
  }
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent)

export default function FeedEntryHideModal() {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const classes = useStyles()

  return (
    <div style={{ paddingTop: "35px", paddingLeft: "5px" }}>
      <IconButton
        className={classes.infoButton}
        onClick={handleClickOpen}
        size="large"
      >
        <Info style={{ color: "#4d9ab5", fontSize: "1.2rem" }} />
      </IconButton>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          To calculate minutes:
        </DialogTitle>
        <DialogContent dividers>
          <Typography className={classes.paragraph} gutterBottom>
            One hour has 60 minutes and one day has 24 hours. One day is
            calculated as 60 x 24 = 1440 minutes in a day.
          </Typography>{" "}
        </DialogContent>
      </Dialog>
    </div>
  )
}
