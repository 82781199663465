import React, { useCallback, useRef } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import makeStyles from "@mui/styles/makeStyles"
import { Box, IconButton } from "@mui/material"
import { TreeItem } from "@mui/x-tree-view"
import { Launch } from "@mui/icons-material"
import { Menu, Item, Separator, useContextMenu } from "react-contexify"
import SaturationChip from "components/ContentSaturation/SaturationChip"
import Helpers from "tools/Helpers"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"

export const useTreeItemStyles = makeStyles(theme => ({
  root: {
    display: "inline-block",
    color: theme.palette.text.secondary,
    "&:focus > $content": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: "var(--tree-view-color)"
    },
    "&[aria-selected='true'] > $content": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: "var(--tree-view-color)"
    }
  },
  content: {
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular
    }
  },
  group: {
    marginLeft: theme.spacing(1),
    "& $content": {
      paddingLeft: theme.spacing(1)
    }
  },
  expanded: {},
  label: {
    fontWeight: "inherit",
    color: "inherit"
  },
  labelRoot: {
    display: "flex",
    verticalAlign: "top",
    padding: theme.spacing(0.5, 0),
    position: "relative"
  },
  labelIcon: {
    fontSize: "22px",
    marginLeft: "-7px",
    width: "15px"
  },
  labelDetail: {
    fontWeight: "inherit",
    flexGrow: 1,
    paddingLeft: theme.spacing(0.75)
  },
  labelText: {
    fontWeight: 400,
    color: "#000000",
    flexGrow: 1,
    fontSize: "14px",
    whiteSpace: "nowrap",
    padding: "4px"
  },
  labelText900: {
    fontWeight: 900,
    color: "#000000",
    flexGrow: 1,
    fontSize: "14px",
    whiteSpace: "nowrap",
    padding: "4px"
  },
  labelInfo: {
    fontSize: "13px"
  },
  inverseTrigger: {
    fontWeight: "bold",
    fontSize: "1.2rem"
  },
  colorInherit: {
    color: "inherit"
  },
  iconButton: {
    position: "absolute",
    right: "10px",
    display: "none",
    padding: 0,
    margin: 0,
    "& > span > svg": {
      width: ".6em",
      height: ".6em"
    }
  },
  iconButton2: {
    position: "absolute",
    right: "30px",
    display: "none",
    padding: 0,
    margin: 0,
    "& > span > a > svg": {
      width: ".55em",
      height: ".55em",
      color: "rgba(0, 0, 0, 0.54)"
    }
  },
  labelPosition: {
    color: "#3f51b5",
    fontWeight: "bold",
    flexShrink: 0,
    marginLeft: theme.spacing(0.5)
  },
  labelWeight: {
    fontWeight: "bold",
    fontSize: ".55rem",
    flexShrink: 0
  },
  flex: {
    display: "flex"
  },
  flexmiddle: {
    display: "flex",
    alignItems: "center",
    "&:hover $iconButton, &:hover $iconButton2": {
      display: "inline-block"
    }
  },
  menu: {
    zIndex: 1000,
    borderRadius: "9px"
  }
}))

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    setDialogWarningMessage: store.uiStore.setDialogWarningMessage,
    setDialogWarningOpen: store.uiStore.setDialogWarningOpen,
    dialogWarningSuccess: store.uiStore.dialogWarningSuccess,
    setDialogWarningSuccess: store.uiStore.setDialogWarningSuccess,
    postArchiveParagraphAndSentences:
      store.narrativeStore.postArchiveParagraphAndSentences,
    postArchiveSentencesUnderParagraph:
      store.narrativeStore.postArchiveSentencesUnderParagraph
  }))
}

const StyledTreeItem = props => {
  const {
    setDialogWarningMessage,
    setDialogWarningOpen,
    dialogWarningSuccess,
    setDialogWarningSuccess,
    postArchiveParagraphAndSentences,
    postArchiveSentencesUnderParagraph
  } = useStoreData()

  const classes = useTreeItemStyles()
  const {
    sourceId,
    narrativeId,
    paragraphId,
    launchLink,
    addView,
    addLabel,
    itemView,
    placeholderId,
    position,
    saturation,
    utilization,
    positionCount,
    labelText,
    labelTitle,
    labelIcon: LabelIcon,
    triggerWeight,
    labelInfo,
    color,
    bgColor,
    contentBlock,
    renderType,
    isSentenceFromLibrary,
    postCopyParagraph,
    postArchiveParagraph,
    postCopySentence,
    postArchiveSentence,
    postCopyTrigger,
    postMoveSentence,
    selectedSourceId,
    selectedSourceType,
    setSourceType,
    setSourceId,
    setActionTaken,
    setLastActionType,
    setEditViewEvent,
    setActiveViewEvent,
    doesParagraphIncludeSentences,
    refreshNarrative,
    ...other
  } = props

  const generatedMenuId = useRef(
    `${itemView}-${Math.floor(Math.random() * 100000)}`
  )
  const _setEditViewEvent = setEditViewEvent || function () {} //When we copy new sentences to the outline, this function does not exist and throws an error
  const _setActiveViewEvent = setActiveViewEvent || function () {} //When we copy new sentences to the outline, this function does not exist and throws an error

  const PasteSentenceToParagraph = postCopySentence &&
    itemView &&
    itemView.includes("paragraph") &&
    selectedSourceId &&
    selectedSourceType === "sentence" && (
      <Item
        disabled={!sourceId}
        props={{ sourceId }}
        onClick={() => {
          let multiple = setActionTaken("Paste")
          if (multiple && multiple.length > 1) {
            multiple.forEach(sentenceId => {
              postCopySentence(sentenceId, sourceId, narrativeId)
            })
            setTimeout(refreshNarrative(), 1000 * multiple.length)
          } else {
            postCopySentence(selectedSourceId, sourceId, narrativeId)
            setTimeout(refreshNarrative(), 1000)
          }
        }}
      >
        Paste
      </Item>
    )
  const TreeContextMenu = useCallback(
    () => (
      <Menu id={generatedMenuId.current} className={classes.menu}>
        {itemView && (
          <Item
            onClick={event => {
              _setActiveViewEvent(event, itemView)
            }}
          >
            Open
          </Item>
        )}
        {itemView &&
          (postCopySentence ||
            postMoveSentence ||
            postCopyTrigger ||
            postCopyParagraph) && (
            <Item
              onClick={event => {
                _setEditViewEvent(event, itemView)
              }}
            >
              Edit
            </Item>
          )}
        {launchLink && (
          <Link to={launchLink} target="_blank">
            <Item>
              <IconButton
                className={classes.iconButton2}
                aria-label={"Open in new window"}
                size="large"
              >
                <Launch />
              </IconButton>
              Open in new window
            </Item>
          </Link>
        )}
        <Separator />
        {addLabel && (
          <Item
            onClick={event => {
              _setActiveViewEvent(event, addView)
            }}
          >
            {addLabel}
          </Item>
        )}
        {addLabel &&
          (postCopySentence || postMoveSentence || postCopyTrigger) && (
            <Separator />
          )}
        {postCopySentence &&
          itemView &&
          itemView.includes("sentence") &&
          !isSentenceFromLibrary && (
            <Item
              disabled={!sourceId}
              props={{ sourceId }}
              onClick={() => {
                setActionTaken("Copy")
                setSourceId(sourceId)
                setSourceType("sentence")
              }}
            >
              Copy
            </Item>
          )}
        {postMoveSentence &&
          itemView &&
          itemView.includes("sentence") &&
          !isSentenceFromLibrary && (
            <Item
              disabled={!sourceId}
              props={{ sourceId }}
              onClick={() => {
                setActionTaken("Cut")
                setSourceId(sourceId)
                setSourceType("sentence-cut")
              }}
            >
              Cut
            </Item>
          )}
        {postCopyParagraph && itemView && itemView.includes("paragraph") && (
          <Item
            disabled={!sourceId}
            props={{ sourceId }}
            onClick={() => {
              setActionTaken("Duplicate")
              postCopyParagraph(sourceId, narrativeId)
              setTimeout(refreshNarrative(), 1000)
            }}
          >
            Duplicate
          </Item>
        )}
        {postArchiveSentence &&
          itemView &&
          itemView.includes("sentence") &&
          !isSentenceFromLibrary && (
            <Item
              disabled={!sourceId}
              props={{ sourceId }}
              onClick={() => {
                setArchiveType("sentence")
                setDialogWarningOpen(true)
                setDialogWarningMessage(
                  `Are you sure you want to archive Sentence ${sourceId}?`
                )
              }}
            >
              Archive
            </Item>
          )}
        {postArchiveParagraph && itemView && itemView.includes("paragraph") && (
          <Item
            disabled={!sourceId}
            props={{ sourceId }}
            onClick={() => {
              setArchiveType("paragraphAndSentences")
              setDialogWarningOpen(true)
              setDialogWarningMessage(
                `Are you sure you want to archive Paragraph ${sourceId} and associated sentences?`
              )
            }}
          >
            Archive Paragraph + Sentences
          </Item>
        )}
        {postArchiveParagraph &&
          itemView &&
          itemView.includes("paragraph") &&
          doesParagraphIncludeSentences && (
            <Item
              disabled={!sourceId}
              props={{ sourceId }}
              onClick={() => {
                setArchiveType("sentencesUnderParagraph")
                setDialogWarningOpen(true)
                setDialogWarningMessage(
                  `Are you sure you want to archive all Sentences associated with Paragraph ${sourceId}?`
                )
              }}
            >
              Archive Sentences
            </Item>
          )}
        {postCopySentence &&
          itemView &&
          itemView.includes("sentence") &&
          !isSentenceFromLibrary && (
            <Item
              disabled={!sourceId}
              props={{ sourceId }}
              onClick={() => {
                setActionTaken("Duplicate")
                postCopySentence(sourceId, paragraphId, narrativeId)
                setTimeout(refreshNarrative(), 1000)
              }}
            >
              Duplicate
            </Item>
          )}
        {postCopyTrigger && itemView && itemView.includes("trigger") && (
          <Item
            disabled={!sourceId}
            props={{ sourceId }}
            onClick={() => {
              setActionTaken("Duplicate")
              postCopyTrigger(sourceId, narrativeId)
              setTimeout(refreshNarrative(), 1000)
            }}
          >
            Duplicate
          </Item>
        )}
        {postMoveSentence &&
          itemView &&
          itemView.includes("paragraph") &&
          selectedSourceId &&
          selectedSourceType === "sentence-cut" && (
            <Item
              disabled={!sourceId}
              props={{ sourceId }}
              onClick={() => {
                postMoveSentence(selectedSourceId, sourceId, narrativeId)
                setTimeout(refreshNarrative(), 1000)
              }}
            >
              Paste
            </Item>
          )}
        {PasteSentenceToParagraph}
      </Menu>
    ),
    [itemView, selectedSourceId]
  )
  const { show } = useContextMenu({
    id: generatedMenuId.current
  })
  function handleContextMenu(event) {
    event.stopPropagation()
    show({
      event
    })
  }

  const [archiveType, setArchiveType] = React.useState("")

  React.useEffect(() => {
    if (dialogWarningSuccess) {
      if (archiveType === "sentence") {
        setActionTaken("Archive")
        const archiveSentence = postArchiveSentence(
          sourceId,
          paragraphId,
          narrativeId
        )
        archiveSentence.then(() => {
          //setOpenWarningArchiveDialog(false)
          setTimeout(refreshNarrative(), 1000)
        })
      } else if (archiveType === "paragraphAndSentences") {
        setActionTaken("Archive")
        const archiveParagraph = postArchiveParagraphAndSentences(
          sourceId,
          narrativeId
        )
        archiveParagraph.then(() => {
          //setOpenWarningArchiveDialog(false)
          setTimeout(refreshNarrative(), 1000)
        })
      } else if (archiveType === "sentencesUnderParagraph") {
        setActionTaken("Archive")
        const archiveParagraph = postArchiveSentencesUnderParagraph(
          sourceId,
          narrativeId
        )
        archiveParagraph.then(() => {
          //setOpenWarningArchiveDialog(false)
          setTimeout(refreshNarrative(), 1000)
        })
      }
      setDialogWarningSuccess(false)
      setDialogWarningOpen(false)
    }
  }, [dialogWarningSuccess])

  return (
    <>
      <div id={generatedMenuId.current} onContextMenu={handleContextMenu}>
        <TreeItem
          title={labelTitle}
          label={
            <div className={classes.flexmiddle}>
              {LabelIcon && (
                <LabelIcon
                  style={{ color: color || "inherit" }}
                  className={classes.labelIcon}
                />
              )}
              {position && (
                <div className={classes.labelPosition}>{position}</div>
              )}
              {!!triggerWeight && triggerWeight !== "0" && (
                <div className={classes.labelWeight}>/{triggerWeight}w</div>
              )}
              <div className={classes.flex}>
                <Box
                  className={classes.labelDetail}
                  onClick={event => {
                    _setActiveViewEvent(event, itemView, placeholderId)
                  }}
                >
                  {(renderType && renderType.startsWith("h")) ||
                  (contentBlock && contentBlock === "title") ? (
                    <>
                      {Helpers.italicizeInverseTriggers(
                        labelText,
                        classes.labelText900,
                        "",
                        classes.inverseTrigger
                      )}
                    </>
                  ) : (
                    <>
                      {Helpers.italicizeInverseTriggers(
                        labelText,
                        classes.labelText,
                        "",
                        classes.inverseTrigger
                      )}
                    </>
                  )}
                  {Helpers.italicizeInverseTriggers(
                    labelInfo,
                    classes.labelInfo,
                    classes.colorInherit,
                    classes.inverseTrigger
                  )}
                </Box>
                <SaturationChip
                  saturation={saturation}
                  utilization={utilization}
                />
              </div>
            </div>
          }
          style={{
            "--tree-view-color": color,
            "--tree-view-bg-color": bgColor
          }}
          classes={{
            root: classes.root,
            content: classes.content,
            expanded: classes.expanded,
            group: classes.group,
            label: classes.label
          }}
          {...other}
        />
      </div>
      {labelText !== "Unassigned Blocks" && <TreeContextMenu />}
    </>
  )
}

export const MemoStyledTreeItem = React.memo(StyledTreeItem)

StyledTreeItem.propTypes = {
  sourceId: PropTypes.number,
  narrativeId: PropTypes.number,
  paragraphId: PropTypes.number,
  launchLink: PropTypes.string,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  doesParagraphIncludeSentences: PropTypes.bool,
  isCTA: PropTypes.bool,
  // eslint-disable-next-line react/no-typos
  labelInfo: PropTypes.string,
  labelText: PropTypes.string,
  labelTitle: PropTypes.string,
  setActiveViewEvent: PropTypes.func,
  setEditViewEvent: PropTypes.func,
  setEditView: PropTypes.func,
  itemView: PropTypes.string,
  placeholderId: PropTypes.string,
  addView: PropTypes.string,
  postCopySentence: PropTypes.func,
  postArchiveSentence: PropTypes.func,
  postMoveSentence: PropTypes.func,
  postCopyParagraph: PropTypes.func,
  postArchiveParagraph: PropTypes.func,
  postCopyTrigger: PropTypes.func,
  selectedSourceId: PropTypes.number,
  setSourceId: PropTypes.func,
  setActionTaken: PropTypes.func,
  selectedSourceType: PropTypes.string,
  setSourceType: PropTypes.func,
  refreshNarrative: PropTypes.func
}
