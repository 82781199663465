import { useEffect, useState } from "react"

const useExplorerSelectionGroup = ({
  groupIds = [],
  selectedIds = new Set(),
  handleSelectAll
}) => {
  const [allSelected, setSelectAll] = useState(false)

  useEffect(() => {
    if (groupIds.length) {
      if (!allSelected && allGroupIdsAreChecked()) {
        setSelectAll(true)
      } else if (allSelected && !allGroupIdsAreChecked()) {
        setSelectAll(false)
      }
    }
  }, [selectedIds])

  const allGroupIdsAreChecked = () => {
    let allChecked = !!groupIds.length
    for (const id of groupIds) {
      if (!selectedIds.has(id)) {
        allChecked = false
        break
      }
    }
    return allChecked
  }

  const toggleSelectAll = () => {
    setSelectAll(prev => {
      const newState = !prev
      handleSelectAll(groupIds, newState)
      return newState
    })
  }

  return {
    allGroupIdsAreChecked,
    allSelected,
    toggleSelectAll
  }
}

export default useExplorerSelectionGroup
