import React, { useEffect, useState } from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import RealTimeDAO from "daos/realTimeDAO"
//import { makeStyles } from "@mui/styles"
import {
  Autocomplete,
  Card,
  CardContent,
  CardActions,
  Dialog,
  //DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from "@mui/material"

import Button from "components/CustomButtons/Button"
import RealTimeLibraryStatus from "./RealTimeLibraryStatus"
import { useHistory } from "react-router-dom"

// const useStyles = makeStyles({
//   libraryFormBtn: {
//     display: "flex",
//     justifyContent: "flex-end"
//   },
//   editCardTitle: {
//     fontSize: "1rem",
//     fontWeight: "bold",
//     paddingLeft: "12px",
//     paddingTop: "8px"
//   }
// })

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    getRealTimeLibraryById: store.realTimeStore.getRealTimeLibraryById,
    getLeagueModelTypes: store.realTimeStore.getLeagueModelTypes,
    leagueModelTypes: store.realTimeStore.leagueModelTypes,
    realTimeLibraries: store.realTimeStore.realTimeLibraries,
    putLibraryById: store.realTimeStore.putLibraryById,
    getRealTimeLeague: store.realTimeStore.getRealTimeLeague,
    insightLeague: store.realTimeStore.insightLeague,
    setDialogSuccessMessage: store.uiStore.setDialogSuccessMessage,
    setDialogSuccessOpen: store.uiStore.setDialogSuccessOpen,
    setDialogFailMessage: store.uiStore.setDialogFailMessage,
    setDialogFailOpen: store.uiStore.setDialogFailOpen
  }))
}

const RealTimeLibraryForm = ({
  mode,
  onClose,
  onSave,
  onModeChange,
  libraryData
}) => {
  const inCreateMode = mode === "create"
  const inEditMode = mode === "edit"
  const history = useHistory()
  //const classes = useStyles()
  const {
    getLeagueModelTypes,
    getRealTimeLibraryById,
    getRealTimeLeague,
    insightLeague,
    setDialogSuccessMessage,
    setDialogSuccessOpen,
    setDialogFailMessage,
    setDialogFailOpen
  } = useStoreData()
  const defaultVerticalOption = { id: 1, name: "Sports" }
  // eslint-disable-next-line no-unused-vars
  const insightTypeIds = []
  libraryData?.insightTypes?.forEach(x => {
    insightTypeIds.push(x.id)
  })
  const initialFormValues = {
    id: libraryData?.id || 0,
    name: libraryData?.name || "",
    status: libraryData?.status || { id: null, name: "" },
    verticalId: defaultVerticalOption,
    insightTypeIds: insightTypeIds || [],
    leagueId: libraryData?.league?.id || null

    //modelType: { id: null, name: "" }
  }
  //const [inViewMode, setInViewMode] = useState(true)
  const [formValues, setFormValues] = useState(initialFormValues)
  const [formErrors, setFormErrors] = useState({})

  useEffect(() => {
    getRealTimeLeague()
    getLeagueModelTypes(libraryData?.league?.id)
  }, [])

  useEffect(() => {
    if (libraryData) {
      const insightTypeIds = []
      libraryData?.insightTypes?.forEach(x => {
        insightTypeIds.push(x.id)
      })
      setFormValues({
        id: libraryData.id,
        name: libraryData.name || "No Name from data",
        status: libraryData.status || { id: null, name: "" },
        verticalId: libraryData.vertical || defaultVerticalOption,
        leagueId: libraryData.league || null,
        insightTypeIds: insightTypeIds || []
      })
    }
  }, [libraryData])

  const handleCancel = () => {
    if (inCreateMode) {
      setFormValues({
        name: "",
        status: "",
        verticalId: null,
        leagueId: null
      })
    }
    if (onClose) {
      onClose()
    } else {
      setFormValues(initialFormValues)
      if (onModeChange) {
        onModeChange("view")
      }
    }
  }

  const handleFieldChanges = (field, value) => {
    setFormValues(prevValues => ({
      ...prevValues,
      [field]: value
    }))
  }

  const handleSave = async () => {
    if (validateForm()) {
      const { name, status, leagueId, insightTypeIds, id, vertical } =
        formValues
      // const libraryData = {
      //   name,
      //   statusId: status?.id || null,
      //   verticalId: 1,
      //   leagueId: leagueId?.id || null
      // }
      const myLibraryData = {
        id: id || 0,
        name,
        statusId: status?.id || 3,
        verticalId: vertical?.id || 1,
        leagueId: leagueId?.id,
        insightTypeIds: insightTypeIds || [],
        isArchived: false
      }
      try {
        let savedLibrary
        let successMessage = ""

        if (inCreateMode) {
          // Use postRealTimeLibrary for creation
          savedLibrary = await RealTimeDAO.postRealTimeLibrary(myLibraryData)
          if (savedLibrary && savedLibrary.id) {
            successMessage = `RealTime LibraryId: ${savedLibrary.id} created`
            history.push(`/portal/real-time-library/edit?id=${savedLibrary.id}`)
          } else {
            setDialogFailMessage(`Failed to save library`)
            setDialogFailOpen(true)
          }
        } else if (inEditMode && myLibraryData.id) {
          // Use putRealTimeLibraryById for updating
          savedLibrary = await RealTimeDAO.putRealTimeLibraryById(
            myLibraryData,
            myLibraryData.id
          )
          if (savedLibrary?.ok) {
            successMessage = `RealTime LibraryId: ${myLibraryData.id} updated`
            getRealTimeLibraryById(myLibraryData.id)
          } else {
            setDialogFailMessage(`Failed to update library`)
            setDialogFailOpen(true)
          }
        } else {
          throw new Error("Invalid mode or missing library ID for update")
        }

        // Handle success
        if (successMessage) {
          setDialogSuccessMessage(successMessage)
          setDialogSuccessOpen(true)
        }
        // Call onSave if it exists
        // if (onSave) {
        //   onSave(savedLibrary.id)
        // }
        // Close form or change mode after successful save
        if (onClose) {
          onClose()
        } else if (onModeChange) {
          onModeChange("view")
        }
        //update the form values with the saved data
        setFormValues(prevValues => ({ ...prevValues, ...savedLibrary }))
      } catch (error) {
        console.error("Error saving library:", error)
        // Show an error message to the user
        setDialogFailMessage(`Failed to save library: ${error.message}`)
        setDialogFailOpen(true)
      }
    }
  }

  const validateForm = () => {
    const newErrors = {}
    if (!formValues.name) {
      newErrors.name = "Name is required"
    }
    if (!formValues.verticalId) {
      newErrors.verticalId = "Vertical is required"
    }
    if (!formValues.leagueId || !formValues.leagueId.id) {
      newErrors.leagueId = "League is required"
    }
    // if (!formValues.statusId || !formValues.statusId.id) {
    //   newErrors.statusId = "Status is required"
    // }
    setFormErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const renderViewField = (label, value) => (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={4}>
        <Typography variant="body1">{label}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2">
          {typeof value === "object" && value !== null ? value.name : value}
        </Typography>
      </Grid>
    </Grid>
  )

  const formContent = (
    <>
      <CardContent aria-label="RealTime RealTimeForm FormCard">
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            {inCreateMode || inEditMode ? (
              <TextField
                aria-label="RealTime RealTimeForm NameField"
                sx={{ width: "100%" }}
                name="name"
                id="realTimeName"
                label="NAME"
                value={formValues.name}
                error={!!formErrors.name}
                helperText={formErrors.name}
                required
                disabled={!inEditMode && !inCreateMode}
                onChange={e => handleFieldChanges("name", e.target.value)}
              />
            ) : (
              renderViewField("NAME: ", formValues.name)
            )}
          </Grid>
          <Grid item xs={12}>
            {inCreateMode || inEditMode ? (
              <Autocomplete
                aria-label="RealTime RealTimeForm VerticalField"
                readOnly={!inCreateMode && !inEditMode}
                id="verticalId"
                options={[defaultVerticalOption]}
                value={formValues.verticalId}
                getOptionLabel={option => option.name || ""}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    label="VERTICAL"
                    required
                    error={!!formErrors.verticalId}
                    helperText={formErrors.verticalId}
                    disabled={!inEditMode && !inCreateMode}
                  />
                )}
                onChange={(e, newValue) =>
                  handleFieldChanges("verticalId", newValue)
                }
              />
            ) : (
              renderViewField("VERTICAL: ", defaultVerticalOption.name)
            )}
          </Grid>
          <Grid item xs={12}>
            {inCreateMode || inEditMode ? (
              <Autocomplete
                readOnly={!inCreateMode && !inEditMode}
                aria-label="RealTime RealTimeForm LeagueField"
                id="leagueId"
                options={insightLeague}
                value={formValues.leagueId}
                getOptionLabel={option => option.name || ""}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    label="ASSOCIATED LEAGUE"
                    required={true}
                    error={!!formErrors.leagueId}
                    helperText={formErrors.leagueId}
                    disabled={!inEditMode && !inCreateMode}
                  />
                )}
                onChange={(event, newValue) => {
                  handleFieldChanges("leagueId", newValue)
                }}
              />
            ) : (
              renderViewField("ASSOCIATED LEAGUE: ", formValues.leagueId)
            )}
          </Grid>
          <Grid item xs={12}>
            {inCreateMode || inEditMode ? (
              <RealTimeLibraryStatus
                value={formValues.status}
                onChange={newValue => {
                  setFormValues(prev => ({
                    ...prev,
                    status: newValue
                      ? { id: newValue.id, name: newValue.name }
                      : null
                    //status: newValue ? newValue.name : null
                  }))
                }}
              />
            ) : (
              renderViewField("STATUS: ", formValues.status)
            )}
          </Grid>
        </Grid>
      </CardContent>
    </>
  )

  return (
    <>
      {inCreateMode ? (
        <Dialog open={true} onClose={handleCancel} fullWidth maxWidth="md">
          <DialogTitle>Real Time Library - Create</DialogTitle>
          <DialogContent>{formContent}</DialogContent>
          <CardActions style={{ justifyContent: "flex-end" }}>
            <Button color="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button color="primary" onClick={handleSave}>
              Save
            </Button>
          </CardActions>
        </Dialog>
      ) : (
        <Card style={{ maxWidth: "100%" }} elevation={0}>
          <Typography variant="h6" component="div" style={{ padding: "16px" }}>
            Real Time Library Info - {inEditMode ? "Edit" : "View"}
          </Typography>
          {formContent}
          {inEditMode && (
            <CardActions style={{ justifyContent: "flex-end" }}>
              <Button color="secondary" onClick={handleCancel} size="sm">
                Cancel
              </Button>
              <Button color="primary" onClick={handleSave} size="sm">
                Save
              </Button>
            </CardActions>
          )}
        </Card>
      )}
    </>
  )
}
export default RealTimeLibraryForm
