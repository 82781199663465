module.exports = {
  groupCollectionBy: function (collection, funcProp) {
    return collection.reduce(function (acc, val) {
      // eslint-disable-next-line
      (acc[funcProp(val)] = acc[funcProp(val)] || []).push(val)
      return acc
    }, {})
  },
  getUnique: function (arr, comp) {
    //store the comparison  values in array
    const unique = arr
      .map(e => e[comp])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & return unique objects
      .filter(e => arr[e])
      .map(e => arr[e])

    return unique
  },
  notOneOf: function (a, b) {
    return a.filter(value => b.indexOf(value) === -1)
  }
}
