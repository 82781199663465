import React from "react"
import PropTypes from "prop-types"
import Button from "components/CustomButtons/Button.jsx"

function UploadButton(props) {
  //This Custom button allows you to upload content from your computer
  //It has 4 props - 3 passing in info, 1 passing out info via a function call to the parent

  // You can pass in 3 parameter via props from the parent containing this button:
  //props.descriptorString - The Text for the button
  //props.acceptedSuffixes - For files to load, in our case we are using ".tsv" files to get.
  //Can upload multiple types, separate by commas, ".tsv, .js"
  //props.buttonParams - If you want to use the Dataskrive Custom Button, pass in the "color", "primary" is our standard blue we us
  //props.buttonParams:{color: "primary"}  --- other colors are "secondary", "warning", "info", "gray", "rose", check our CSS for all values
  //If you want a standard HTML button, DO NOT add a color attribute and instead you can pass in the "style" attribute to be added to the button
  //props.buttonParams:{style: {      color: "#c00",      backgroundColor: "#fff", borderRadius: "5px", padding: "10px"    }}

  //You can pass out the selected file to the parent:
  //props.doFileUpload - for when you click and select a file, you can pass that file using the function "doFileUpload" to the parent for processing it

  const hiddenFileInput = React.useRef(null)
  const descriptorString = props.descriptorString
  const handleCustomClick = () => {
    props.otherClickEvent && props.otherClickEvent(props.buttonPurpose)
    hiddenFileInput.current.click()
  }

  return (
    <>
      {props.buttonParams && props.buttonParams.color && (
        <Button
          color={props.buttonParams.color}
          onClick={handleCustomClick}
          style={props.customStyle ? props.customStyle : {}}
        >
          {descriptorString}
        </Button>
      )}
      {(!props.buttonParams || !props.buttonParams.color) && (
        <button onClick={handleCustomClick}>{descriptorString}</button>
      )}
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={e => {
          props.doFileUpload(e)
        }}
        accept={props.acceptedSuffix}
        style={{ display: "none" }}
      />
    </>
  )
}

UploadButton.propTypes = {
  doFileUpload: PropTypes.func,
  otherClickEvent: PropTypes.func,
  buttonParams: PropTypes.object,
  acceptedSuffix: PropTypes.string,
  descriptorString: PropTypes.string,
  customStyle: PropTypes.object,
  buttonPurpose: PropTypes.string
}
export default UploadButton
