import React, { useState } from "react"
import Info from "@mui/icons-material/Info"
import IconButton from "@mui/material/IconButton"
import { makeStyles } from "@mui/styles"
import CustomDSDialog from "components/CustomDialogs/CustomDSDialog"

const useStyles = makeStyles({
  displayInline: {
    display: "inline-block"
  },
  modalHeader: {
    backgroundColor: "#DDEAEF"
  },
  infoIcon: {
    color: "#4d9ab5",
    fontSize: "1.2rem"
  }
})

const InfoDialog = props => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const content = props?.content ?? ""
  const displayInline = props?.displayInline ?? false
  const titleContent = props?.titleContent ?? ""

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className={displayInline ? classes.displayInline : ""}>
      <IconButton
        className={classes.searchButton}
        onClick={handleClickOpen}
        size="large"
      >
        <Info className={classes.infoIcon} />
      </IconButton>
      <CustomDSDialog
        titleContent={titleContent}
        open={open}
        content={content}
        icon={<Info style={{ fontSize: "4rem" }} />}
        onClose={handleClose}
      />
    </div>
  )
}

export default InfoDialog
