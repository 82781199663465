const Enums = {
  DaysofTheWeek: {
    SUNDAY: 0,
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6
  },
  OwnerActions: {
    CREATE: 4,
    REMOVEOWNER: 3,
    COPYEDIT: 2,
    WRITE: 1
  },
  BlockStatus: {
    ACTIVE: 3,
    INACTIVE: 1,
    REVIEW: 2
  },
  Gender: {
    DEFAULT: 0,
    FEMALE: 1,
    MALE: 2
  },
  NarrativeStatus: {
    INACTIVE: 1,
    VALIDATE: 2,
    ACTIVE: 3
  },
  FeedStatus: {
    INACTIVE: 1,
    VALIDATE: 2,
    ACTIVE: 3,
    VALIDATEINTERNAL: 4
  },
  WebhookStatus: {
    INACTIVE: 2,
    VALIDATE: 3,
    ACTIVE: 1,
    VALIDATEINTERNAL: 4
  },
  DeliveryType: {
    BATCH: 1,
    INDIVIDUAL: 2
  },
  SentenceStatus: {
    DRAFT: 1,
    REVIEW: 2,
    PUBLISHED: 3,
    PLANNED: 4,
    SAMPLE: 5
  },
  BlockDisplayStyle: {
    COMPACT: 0,
    STANDARD: 1,
    DETAILED: 2
  },
  ContentType: {
    NONE: 0,
    FANTASYFOOTBALLPLAYER: 8,
    QUERY: 10,
    SNAPSHOT: 11,
    MATCHPREVIEW: 12,
    MATCHRECAP: 13,
    COMPETITION: 14,
    SPORT: 15,
    LEAGUE: 16,
    CONFERENCE: 17,
    DIVISION: 18,
    SUBDIVSION: 19,
    TEAM: 20,
    OTHER: 21,
    LEVEL: 22,
    REGION: 23,
    PERSON: 25,
    CITY: 27,
    SCHOOL: 28,
    YEAR: 32,
    GENDER: 39,
    COUNTRY: 55,
    MEDIA: 56,
    AUDIO: 57,
    FILEASSET: 58,
    RANKING: 59,
    POST: 100,
    BUSINESSVERTICAL: 101,
    MODELDATASNAPSHOT: 102
  },
  TeamGroups: {
    Hidden: -1,
    NotApplicable: 0,
    NFL: 1, // National Football League  (United States & Canada)
    NBA: 2, // National Basketball Assocation  (United States & Canada)
    NHL: 3, // National Hockey League  (United States & Canada)
    LaLiga: 4, // Spain
    EPL: 5, // Britain
    UEFAChampionsLeague: 6, // Europe
    SerieA: 7, // Italy
    Bundesliga: 8, // Germany
    Eredivisie: 9, // Netherlands
    LaLigaMX: 10, // Mexico
    Ligue1: 11, // France
    MLS: 12, // Major League Soccer (United States & Canada)
    BrasileiroSerieA: 13, // Brazil
    PrimeiraLiga: 14, // Portugal
    SuperLig: 15, // Turkey,
    SuperLiga: 16, // Argentina
    MLB: 17, // Major League Baseball (United States & Canada)
    MensFIFAWorldCup: 18, // Men's FIFA World Cup
    WNBA: 19, // Women's National Basketball Assocation (United States)
    NCAAF: 20, // National Collegiate Atheletic Association Football (men's)
    PGA: 21, // Professional Golf Association
    NCAAB: 22, // NCAA (National Collegiate Athletic Assocation) Men's Basketball (probably division 1)
    NCAABW: 23, // NCAA (National Collegiate Athletic Assocation) Women's Basketball (probably division 1)
    ITF: 24, ///<summary>International Tennis Federation (men's and women's)</summary>
    ATP: 25, // Association of Tennis Professionals (men only)
    WTA: 26, // Women's Tennis Association (women only)
    LIVTour: 27, // Saudi Arabian Managed Professional Golf Tour LIV (Roman numerals for 54)
    ProfessionalGolfers: 28, // Place holder group for any professional golfers
    ProfessionalTennisPlayers: 29, // Place holder group for any professional tennis players
    LPGA: 30, // Ladies' Professional Golf Association
    NASCAR: 31, // The National Association for Stock Car Auto Racing
    NWSL: 32, // National Womens Soccer League (USA)
    WomensFIFAWorldCup: 33, // Women's FIFA World Cup
    NCAAHockey: 34 // NCAA Hockey
  },
  NarrativeModelType: {
    CollegeBaseballMatch: 1,
    QueryBuilder: 44
  },
  Organizations: {
    DataSkrive0: 0,
    DataSkrive1: 1,
    HeroSports: 2,
    AP: 3,
    Catena: 55,
    Libraries: 57,
    TheLines: 74,
    PlayPicks: 75,
    Betsperts: 269,
    FoxSports: 1061,
    FoxSportsCom: 1160,
    BetSpertsCom: 1094,
    OutputRefreshActivities: 1426
  },
  TierLevels: {
    Level1: 1,
    Level2: 2,
    Level3: 3
  },
  ChatBotImageResolution: {
    Square: 1, // 1024x1024
    Portrait: 2, // 1024x1792
    Landscape: 3 // 1792x1024
  },
  ChatBotImageQuality: {
    Standard: 1,
    HD: 2
  },
  ChatBotImageStyle: {
    Vivid: 1,
    Natural: 2
  }
}

export default Object.freeze(Enums)
