import React, {
  useEffect,
  useState //useMemo
} from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import { TabPanel } from "components/TabPanel/TabPanel"
import AppBar from "@mui/material/AppBar"
import ScratchPadForRealTime from "components/RealTime/EditorRealTimeLibrary/ScratchPadForRealTime"
import Helpers from "tools/Helpers.js"
import { makeStyles } from "@mui/styles"
import { Grid, Autocomplete, TextField } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"

const useStyles = makeStyles({
  ownerGrid: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingBottom: "20px"
  },
  formRow: {
    display: "flex",
    padding: "24px 0"
  },
  tabs: {
    background: "#ddeaef"
  }
})

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    getLeagueModelTypes: store.realTimeStore.getLeagueModelTypes,
    leagueModelTypes: store.realTimeStore.leagueModelTypes
  }))
}

export default function RealTimeDataBar({ sentenceModelType, leagueId }) {
  const classes = useStyles()
  const { getLeagueModelTypes, leagueModelTypes } = useStoreData()

  const [modelList, setModelList] = useState([])
  const [flatModelData, setFlatModelData] = useState([])

  const [modelSelected, setModelSelected] = useState(sentenceModelType?.id || 0)

  const [currentView, setCurrentView] = useState(0)

  const getSnapshots = () => {
    if (!leagueModelTypes || leagueModelTypes.length === 0) {
      getLeagueModelTypes(leagueId)
    }
  }
  const flatDataColumns = [
    {
      headerName: "ID",
      field: "id",
      width: 100,
      renderCell: cell => (
        <div style={{ textAlign: "left", width: "100%" }}>{cell.row["id"]}</div>
      )
    },
    {
      headerName: "NAME",
      field: "name",
      width: 450,
      renderCell: cell => (
        <div style={{ textAlign: "left", width: "100%", fontWeight: "bold" }}>
          {cell.row["name"]}
        </div>
      )
    },
    {
      headerName: "VALUE",
      field: "value",
      width: 450,
      renderCell: cell => (
        <div style={{ textAlign: "left", width: "100%", fontWeight: "bold" }}>
          {cell.row["value"] === null
            ? "null"
            : cell.row["value"] === false
            ? "false"
            : cell.row["value"] === true
            ? "true"
            : cell.row["value"] === undefined
            ? "undefined"
            : cell.row["value"]}
        </div>
      )
    }
  ]

  useEffect(() => {
    if (leagueModelTypes && leagueModelTypes.length > 0) {
      const temp = []
      leagueModelTypes.forEach(x => {
        temp.push({ id: x?.id, name: x?.name })
      })
      setModelList(temp)
    }
  }, [leagueModelTypes])

  const handleTabChange = (event, newValue) => {
    getSnapshots()
    if (newValue !== currentView) {
      setCurrentView(newValue)
    }
  }

  const modelSelectedValue = modelSelected
    ? modelSelected
    : sentenceModelType?.id > 0
    ? sentenceModelType.id
    : 0

  useEffect(() => {
    const modelSel = modelSelected || modelSelectedValue
    if (modelSel > 0) {
      if (leagueModelTypes?.length > 0) {
        leagueModelTypes.forEach(x => {
          if (x.id === modelSel) {
            const preview = JSON.parse(x.preview)
            const previewParsed =
              Helpers.turnRichObjectIntoFlatNameValuePairArray(preview)
            setFlatModelData(previewParsed)
          }
        })
      }
    }
  }, [modelSelected, modelSelectedValue])

  return (
    <>
      <AppBar
        position="static"
        color="default"
        enableColorOnDark
        aria-label="Real Time Outputs Tabs App Bar"
        style={{ margin: "10px 0 15px 0" }}
      >
        <Tabs
          value={currentView}
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleTabChange}
          aria-label="eal Time Outputs Tabs "
        >
          <Tab label="Real Time Model Information" />
          <Tab label="Scratch Pad" />
        </Tabs>
      </AppBar>
      <TabPanel
        value={currentView}
        index={0}
        aria-label="Real Time Outputs Tabs Output Preference Container"
      >
        <Grid
          container
          style={{
            alignItems: "center",
            padding: "10px 0"
          }}
        >
          {modelList.length > 0 && (
            <Autocomplete
              sx={{ minWidth: "150px" }}
              aria-label="RealTime Data Bar Select Model"
              id="modelSelected"
              options={modelList || []}
              value={modelList?.find(x => x.id === modelSelectedValue) || null}
              getOptionLabel={option => option.name || ""}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              onChange={(event, newValue, reason) => {
                if (reason === "clear") {
                  setModelSelected(0)
                } else {
                  setModelSelected(newValue.id)
                }
              }}
              renderInput={params => <TextField {...params} label="Model" />}
            />
          )}
        </Grid>
        {flatModelData.length > 0 && (
          <DataGrid
            aria-label="CTAV2 RealTimeOutputs Datagrid"
            className={classes.libraryGrid}
            columns={flatDataColumns}
            pagination
            disableColumnFilter={true}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
              columns: {
                columnVisibilityModel: {
                  id: false
                }
              }
            }}
            pageSizeOptions={[10, 25, 50]}
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#ddeaef",
                height: "80px"
              }
            }}
            rows={flatModelData}
          />
        )}
      </TabPanel>
      <TabPanel
        value={currentView}
        index={1}
        aria-label="Real Time Outputs Tabs Output Container"
      >
        <>
          <Grid
            container
            style={{
              alignItems: "center",
              padding: "10px 0 0 0"
            }}
          >
            {modelList.length > 0 && (
              <Autocomplete
                sx={{ minWidth: "150px" }}
                aria-label="RealTime Data Bar Select Model"
                id="modelSelected"
                options={modelList || []}
                value={
                  modelList?.find(x => x.id === modelSelectedValue) || null
                }
                getOptionLabel={option => option.name || ""}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                onChange={(event, newValue, reason) => {
                  if (reason === "clear") {
                    setModelSelected(0)
                  } else {
                    setModelSelected(newValue.id)
                  }
                }}
                renderInput={params => <TextField {...params} label="Model" />}
              />
            )}
          </Grid>
          <ScratchPadForRealTime modelId={modelSelectedValue} />
        </>
      </TabPanel>
    </>
  )
}
