import React, { useState } from "react"
import { useObserver } from "mobx-react"

import { useStore } from "contexts/rootContext"
import PropTypes from "prop-types"
import makeStyles from "@mui/styles/makeStyles"
import { Link } from "react-router-dom"
import { TreeView, TreeItem } from "@mui/x-tree-view"
import { Typography, Box, SvgIcon, IconButton } from "@mui/material"
import {
  PanTool,
  Launch,
  ShortText,
  ArrowDropDown,
  ArrowRight,
  FlashOn,
  Code,
  SyncAlt,
  AddAlert,
  ShowChart,
  Battery20,
  BatteryUnknown,
  LibraryBooksOutlined,
  BookmarkBorderOutlined,
  AddAlertTwoTone,
  Attachment,
  PanToolTwoTone,
  AssessmentTwoTone,
  AssessmentOutlined,
  SettingsEthernet
} from "@mui/icons-material"
import Helpers from "tools/Helpers"
import { Menu, Item, Separator, useContextMenu } from "react-contexify"
import "react-contexify/dist/ReactContexify.css"
import PlannedIcon from "@mui/icons-material/EmojiObjectsOutlined"

const useTreeItemStyles = makeStyles(theme => ({
  root: {
    display: "inline-block",
    color: theme.palette.text.secondary,
    "&:focus > $content": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: "var(--tree-view-color)"
    },
    "&[aria-selected='true'] > $content": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: "var(--tree-view-color)"
    }
  },
  content: {
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular
    }
  },
  group: {
    marginLeft: theme.spacing(1),
    "& $content": {
      paddingLeft: theme.spacing(1)
    }
  },
  expanded: {},
  label: {
    fontWeight: "inherit",
    color: "inherit"
  },
  labelRoot: {
    display: "flex",
    verticalAlign: "top",
    padding: theme.spacing(0.5, 0),
    position: "relative"
  },
  labelIcon: {
    fontSize: "22px",
    marginLeft: "-7px",
    width: "15px"
  },
  labelDetail: {
    fontWeight: "inherit",
    flexGrow: 1,
    paddingLeft: theme.spacing(0.75)
  },
  labelText: {
    fontWeight: 400,
    color: "#000000",
    flexGrow: 1,
    fontSize: "14px",
    whiteSpace: "nowrap",
    padding: "4px"
  },
  labelInfo: {
    fontSize: "13px"
  },
  iconButton: {
    position: "absolute",
    right: "10px",
    display: "none",
    padding: 0,
    margin: 0,
    "& > span > svg": {
      width: ".6em",
      height: ".6em"
    }
  },
  iconButton2: {
    position: "absolute",
    right: "30px",
    display: "none",
    padding: 0,
    margin: 0,
    "& > span > a > svg": {
      width: ".55em",
      height: ".55em",
      color: "rgba(0, 0, 0, 0.54)"
    }
  },
  labelPosition: {
    color: "#3f51b5",
    fontWeight: "bold",
    flexShrink: 0,
    marginLeft: theme.spacing(0.5)
  },
  labelWeight: {
    fontWeight: "bold",
    fontSize: ".55rem",
    flexShrink: 0
  },
  flex: {
    display: "flex"
  },
  flexmiddle: {
    display: "flex",
    alignItems: "center",
    "&:hover $iconButton, &:hover $iconButton2": {
      display: "inline-block"
    }
  }
}))

const Pilcrow = () => (
  <SvgIcon
    style={{ marginLeft: "-7px", marginTop: "-5px", width: "15px" }}
    fontSize="small"
    focusable="false"
    viewBox="0 0 24 24"
    aria-hidden="true"
    role="presentation"
  >
    <path d="M28,0h-4h-4h-4h-4C7.582,0,4,3.582,4,8s3.582,8,8,8v16h4V4h4v28h4V4h4V0z" />
  </SvgIcon>
)

function StyledTreeItem(props) {
  const classes = useTreeItemStyles()
  const {
    sourceId,
    narrativeId,
    paragraphId,
    launchLink,
    addView,
    addLabel,
    itemView,
    position,
    labelText,
    labelTitle,
    labelIcon: LabelIcon,
    triggerWeight,
    labelInfo,
    color,
    bgColor,
    postCopyParagraph,
    postCopySentence,
    postCopyTrigger,
    postMoveSentence,
    selectedSourceId,
    selectedSourceType,
    setSourceType,
    setSourceId,
    setActionTaken,
    refreshNarrative,
    ...other
  } = props

  const setEditViewEvent = props.setEditViewEvent || function () {} //When we copy new sentences to the outline, this function does not exist and throws an error
  const setActiveViewEvent = props.setActiveViewEvent || function () {} //When we copy new sentences to the outline, this function does not exist and throws an error

  const TreeContextMenu = () => (
    <Menu id={itemView || ""}>
      {itemView && (
        <Item
          onClick={event => {
            setActiveViewEvent(event, itemView)
          }}
        >
          Open
        </Item>
      )}
      {itemView &&
        (postCopySentence ||
          postMoveSentence ||
          postCopyTrigger ||
          postCopyParagraph) && (
          <Item
            onClick={event => {
              setEditViewEvent(event, itemView)
            }}
          >
            Edit
          </Item>
        )}
      {launchLink && (
        <Link to={launchLink} target="_blank">
          <Item>
            <IconButton
              className={classes.iconButton2}
              aria-label={"Open in new window"}
              size="large"
            >
              <Launch />
            </IconButton>
            Open in new window
          </Item>
        </Link>
      )}
      <Separator />
      {addLabel && (
        <Item
          onClick={event => {
            setActiveViewEvent(event, addView)
          }}
        >
          {addLabel}
        </Item>
      )}
      {addLabel &&
        (postCopySentence || postMoveSentence || postCopyTrigger) && (
          <Separator />
        )}
      {postCopySentence && itemView && itemView.startsWith("sentence") && (
        <Item
          disabled={!sourceId}
          props={{ sourceId }}
          onClick={() => {
            setActionTaken("Copy")
            setSourceId(sourceId)
            setSourceType("sentence")
          }}
        >
          Copy
        </Item>
      )}
      {postMoveSentence && itemView && itemView.startsWith("sentence") && (
        <Item
          disabled={!sourceId}
          props={{ sourceId }}
          onClick={() => {
            setActionTaken("Cut")
            setSourceId(sourceId)
            setSourceType("sentence-cut")
          }}
        >
          Cut
        </Item>
      )}
      {postCopyParagraph && itemView && itemView.startsWith("paragraph") && (
        <Item
          disabled={!sourceId}
          props={{ sourceId }}
          onClick={() => {
            setActionTaken("Duplicate")
            postCopyParagraph(sourceId, narrativeId)
            setTimeout(refreshNarrative(), 1000)
          }}
        >
          Duplicate
        </Item>
      )}
      {postCopySentence && itemView && itemView.startsWith("sentence") && (
        <Item
          disabled={!sourceId}
          props={{ sourceId }}
          onClick={() => {
            setActionTaken("Duplicate")
            postCopySentence(sourceId, paragraphId, narrativeId)
            setTimeout(refreshNarrative(), 1000)
          }}
        >
          Duplicate
        </Item>
      )}
      {postCopyTrigger && itemView && itemView.includes("trigger") && (
        <Item
          disabled={!sourceId}
          props={{ sourceId }}
          onClick={() => {
            setActionTaken("Duplicate")
            postCopyTrigger(sourceId, narrativeId)
            setTimeout(refreshNarrative(), 1000)
          }}
        >
          Duplicate
        </Item>
      )}
      {postMoveSentence &&
        itemView &&
        itemView.startsWith("paragraph") &&
        selectedSourceId &&
        selectedSourceType === "sentence-cut" && (
          <Item
            disabled={!sourceId}
            props={{ sourceId }}
            onClick={() => {
              postMoveSentence(selectedSourceId, sourceId, narrativeId)
              setTimeout(refreshNarrative(), 1000)
            }}
          >
            Paste
          </Item>
        )}
      {postCopySentence &&
        itemView &&
        itemView.startsWith("paragraph") &&
        selectedSourceId &&
        selectedSourceType === "sentence" && (
          <Item
            disabled={!sourceId}
            props={{ sourceId }}
            onClick={() => {
              let multiple = setActionTaken("Paste")
              if (multiple && multiple.length > 1) {
                multiple.forEach(sentenceId => {
                  postCopySentence(sentenceId, sourceId, narrativeId)
                })
                setTimeout(refreshNarrative(), 1000 * multiple.length)
              } else {
                postCopySentence(selectedSourceId, sourceId, narrativeId)
                setTimeout(refreshNarrative(), 1000)
              }
            }}
          >
            Paste
          </Item>
        )}
    </Menu>
  )

  const { show } = useContextMenu({
    id: itemView
  })
  function handleContextMenu(event) {
    event.stopPropagation()
    show({
      event
    })
  }

  return (
    <React.Fragment>
      <div id={itemView} onContextMenu={handleContextMenu}>
        <TreeItem
          title={labelTitle}
          label={
            <div className={classes.flexmiddle}>
              {LabelIcon && (
                <LabelIcon color="inherit" className={classes.labelIcon} />
              )}
              {
                <Typography variant="body1" className={classes.labelPosition}>
                  {position}
                </Typography>
              }
              {!!triggerWeight && triggerWeight !== "0" && (
                <Typography variant="body1" className={classes.labelWeight}>
                  /{triggerWeight}w
                </Typography>
              )}
              <div className={classes.flex}>
                <Box
                  className={classes.labelDetail}
                  onClick={event => {
                    setActiveViewEvent(event, itemView)
                  }}
                >
                  <Typography variant="body2" className={classes.labelText}>
                    {labelText}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.labelInfo}
                    color="inherit"
                  >
                    {labelInfo}
                  </Typography>
                </Box>
              </div>
            </div>
          }
          style={{
            "--tree-view-color": color,
            "--tree-view-bg-color": bgColor
          }}
          classes={{
            root: classes.root,
            content: classes.content,
            expanded: classes.expanded,
            group: classes.group,
            label: classes.label
          }}
          {...other}
        />
      </div>
      <TreeContextMenu />
    </React.Fragment>
  )
}

StyledTreeItem.propTypes = {
  sourceId: PropTypes.number,
  narrativeId: PropTypes.number,
  paragraphId: PropTypes.number,
  launchLink: PropTypes.string,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string,
  labelTitle: PropTypes.string,
  setActiveViewEvent: PropTypes.func,
  setEditViewEvent: PropTypes.func,
  setEditView: PropTypes.func,
  itemView: PropTypes.string,
  addView: PropTypes.string,
  postCopySentence: PropTypes.func,
  postMoveSentence: PropTypes.func,
  postCopyParagraph: PropTypes.func,
  postCopyTrigger: PropTypes.func,
  selectedSourceId: PropTypes.number,
  setSourceId: PropTypes.func,
  setActionTaken: PropTypes.func,
  selectedSourceType: PropTypes.string,
  setSourceType: PropTypes.func,
  refreshNarrative: PropTypes.func
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch",
    maxWidth: "100%"
  },
  drawerPrimaryCard: {
    flexGrow: 1,
    overflow: "auto",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch"
  },
  drawerContent: {
    flexGrow: 0
  },
  drawerPrimaryContent: {
    flexGrow: 1,
    padding: "10px",
    paddingRight: 0
  },
  list: {
    padding: 0
  },
  listItem: {
    margin: 0,
    padding: 0
  },
  listItemIcon: {
    minWidth: "auto"
  },
  checkbox: {
    marginLeft: "17px",
    padding: 0
  },
  cardHeader: {
    padding: "5px 10px"
  },
  cardBody: {
    padding: "5px 10px"
  }
}))

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    postCopySentence: store.narrativeStore.postCopySentence,
    postMoveSentence: store.narrativeStore.postMoveSentence,
    postCopyParagraph: store.narrativeStore.postCopyParagraph,
    postCopyTrigger: store.narrativeStore.postCopyTrigger
  }))
}

export default function SearchExplorer(props) {
  const classes = useStyles()
  const [selectedSourceId, setSourceId] = useState(0)
  const [selectedSourceType, setSourceType] = useState("")
  const [lastActionType, setLastActionType] = useState("")

  const {
    postCopySentence,
    postMoveSentence,
    postCopyParagraph,
    postCopyTrigger
  } = useStoreData()

  const {
    narrative,
    searchedTriggers,
    searchedBlocks,
    setActiveView,
    refreshNarrative,
    activeView,
    setEditView
  } = props

  const { id: narrativeId } = narrative

  const handleTreeChange = (event, newItem) => {
    let selected = activeView.split(",")
    // Check if there is a bulk select even
    // If there's a blockselection event and it's not a top level abstraction
    if (!["dependencies", "newstriggers", "insights"].includes(newItem)) {
      // Otherwise add the item directly
      if (event.ctrlKey) {
        if (selected.includes(newItem)) {
          selected = selected.filter(value => value !== newItem)
        } else {
          selected.push(newItem)
        }
        let newActiveView = selected.join(",")
        setActiveView(newActiveView)
      } else {
        setActiveView(newItem)
      }
    } else {
      setActiveView(newItem)
    }
  }

  const setEditAction = (event, newItem) => {
    let selected = activeView.split(",")
    // Check if there is a bulk select even
    // If there's a blockselection event and it's not a top level abstraction
    if (!["dependencies", "newstriggers", "insights"].includes(newItem)) {
      if (!event.ctrlKey) {
        if (selected.includes(newItem)) {
          setEditView(newItem)
        } else {
          setEditView(newItem, true)
        }
      } else {
        setEditView(newItem)
      }
    }
  }

  const setActionTaken = currentAction => {
    if (currentAction === "Paste" && lastActionType === "Copy") {
      setLastActionType(currentAction)
      let selectedViews = activeView.split(",")
      if (selectedViews && selectedViews.length > 0) {
        let nums = []
        selectedViews.forEach(item => {
          let temp = item.split("-")
          if (temp[1]) {
            nums.push(Number(temp[1]))
          }
        })
        return nums
      } else {
        return null
      }
    }
    setLastActionType(currentAction)
    return null
  }
  let dependencies =
    searchedTriggers &&
    searchedTriggers.filter(
      t =>
        t.isDependency !== null &&
        (t.isDependency === true || t.isDependency === "true")
    )
  let newsTriggers =
    searchedTriggers &&
    searchedTriggers.filter(
      t => t.isNews !== null && (t.isNews === true || t.isNews === "true")
    )
  let insightTriggers =
    searchedTriggers &&
    searchedTriggers.filter(
      t =>
        (t.isNews === null || t.isNews === false || t.isNews === "false") &&
        (t.isDependency === null ||
          t.isDependency === false ||
          t.isDependency === "false")
    )

  const labelStatusIcon = (status, icon, typeOfContent) => {
    // If below:  We want sample sentences -status=5 - to get new Icon BUT sample paragraphs NOT
    if (typeOfContent && typeOfContent === "paragraph" && status === 5) {
      status = 0
    }
    switch (status) {
      case 1:
        return Battery20
      case 2:
        return BatteryUnknown
      case 4:
        return PlannedIcon
      case 5:
        return BookmarkBorderOutlined
      default:
        return icon
    }
  }

  let searchedBlockItems =
    searchedBlocks &&
    searchedBlocks.map(paragraph => (
      <StyledTreeItem
        sourceId={paragraph.id}
        paragraphId={paragraph.id}
        narrativeId={paragraph.narrative_Id}
        selectedSourceId={selectedSourceId}
        setSourceId={setSourceId}
        setActionTaken={setActionTaken}
        selectedSourceType={selectedSourceType}
        setSourceType={setSourceType}
        setLastActionType={setLastActionType}
        refreshNarrative={refreshNarrative}
        key={`paragraphid-${paragraph.id}`}
        itemView={`paragraphid-${paragraph.id}`}
        nodeId={`paragraphid-${paragraph.id}`}
        labelText={`(${paragraph.id}) ${paragraph.name || ""}`}
        labelInfo={
          paragraph.triggerType
            ? Helpers.titleCaseSlug(paragraph.triggerType)
            : ""
        }
        launchLink={`/portal/narrative/${narrativeId}/edit?view=paragraphid-${paragraph.id}`}
        labelIcon={labelStatusIcon(paragraph.status_Id, Pilcrow, "paragraph")}
        labelTitle={`${paragraph.triggerType || ""} ${
          paragraph.narrativeId !== narrativeId
            ? `Inherited From ${narrativeId}`
            : ""
        }`}
        position={paragraph.position}
        setActiveViewEvent={handleTreeChange}
        setEditViewEvent={setEditAction}
        addLabel={`Add Sentence`}
        addView={`createsentence-${paragraph.id}`}
        postCopyParagraph={postCopyParagraph}
        postCopySentence={postCopySentence}
        postMoveSentence={postMoveSentence}
      >
        {paragraph &&
          paragraph.sentences
            .filter(sentence => sentence.importedBySentence_Id === null)
            .map(sentence => (
              <StyledTreeItem
                sourceId={sentence.id}
                paragraphId={sentence.paragraph_Id}
                narrativeId={sentence.narrative_Id}
                selectedSourceId={selectedSourceId}
                setSourceId={setSourceId}
                setActionTaken={setActionTaken}
                selectedSourceType={selectedSourceType}
                setSourceType={setSourceType}
                setLastActionType={setLastActionType}
                refreshNarrative={refreshNarrative}
                key={`sentenceid-${sentence.id}`}
                nodeId={`sentenceid-${sentence.id}`}
                itemView={`sentenceid-${sentence.id}`}
                launchLink={`/portal/narrative/${narrativeId}/edit?view=sentenceid-${sentence.id}`}
                labelText={`(${sentence.id}) ${
                  sentence.name ||
                  (sentence.triggerType
                    ? Helpers.titleCaseSlug(sentence.triggerType)
                    : "n/a")
                }`}
                labelIcon={
                  sentence.isLibraryPlaceholder
                    ? Attachment
                    : sentence.isFromLibrary
                    ? LibraryBooksOutlined
                    : labelStatusIcon(sentence.status_Id, ShortText)
                }
                labelTitle={`${sentence.triggerType || ""} ${
                  sentence.narrativeId !== narrativeId
                    ? `Imported From Library ${narrativeId}`
                    : ""
                }`}
                position={sentence.position}
                triggerWeight={sentence.triggerWeight}
                setActiveViewEvent={handleTreeChange}
                setEditViewEvent={setEditAction}
                postCopySentence={postCopySentence}
                postMoveSentence={postMoveSentence}
              >
                {sentence.isLibraryPlaceholder &&
                  paragraph.sentences
                    .filter(
                      libSentence =>
                        libSentence.importedBySentence_Id === sentence.id
                    )
                    .map(sentence => (
                      <StyledTreeItem
                        sourceId={sentence.id}
                        paragraphId={sentence.paragraph_Id}
                        narrativeId={sentence.narrative_Id}
                        selectedSourceId={selectedSourceId}
                        setSourceId={setSourceId}
                        setActionTaken={setActionTaken}
                        selectedSourceType={selectedSourceType}
                        setSourceType={setSourceType}
                        setLastActionType={setLastActionType}
                        refreshNarrative={refreshNarrative}
                        key={`sentenceid-${sentence.id}`}
                        nodeId={`sentenceid-${sentence.id}`}
                        itemView={`sentenceid-${sentence.id}`}
                        launchLink={`/portal/narrative/${narrativeId}/edit?view=sentenceid-${sentence.id}`}
                        labelText={`(${sentence.id}) ${
                          sentence.name ||
                          (sentence.triggerType
                            ? Helpers.titleCaseSlug(sentence.triggerType)
                            : "")
                        }`}
                        labelIcon={labelStatusIcon(
                          sentence.status_Id,
                          ShortText
                        )}
                        labelTitle={`${sentence.triggerType || ""} ${
                          sentence.narrativeId !== narrativeId
                            ? `Imported From Library ${narrativeId}`
                            : ""
                        }`}
                        position={sentence.position}
                        triggerWeight={sentence.triggerWeight}
                        setActiveViewEvent={handleTreeChange}
                        setEditViewEvent={setEditAction}
                      />
                    ))}
              </StyledTreeItem>
            ))}
      </StyledTreeItem>
    ))

  let dependencyTreeItems =
    dependencies &&
    dependencies.map(trigger => (
      <StyledTreeItem
        sourceId={trigger.id}
        narrativeId={trigger.narrative_Id}
        selectedSourceId={selectedSourceId}
        setSourceId={setSourceId}
        setActionTaken={setActionTaken}
        selectedSourceType={selectedSourceType}
        setSourceType={setSourceType}
        setLastActionType={setLastActionType}
        refreshNarrative={refreshNarrative}
        key={`dep-triggerid-${trigger.id}`}
        itemView={`triggerid-${trigger.id}`}
        nodeId={`triggerid-${trigger.id}`}
        launchLink={`/portal/narrative/${trigger.narrative_Id}?view=triggerid-${trigger.id}`}
        labelText={`(${trigger.id}) ${trigger.name}`}
        labelInfo={trigger.type}
        labelIcon={
          trigger.type && trigger.type.startsWith("narrative_killswitch")
            ? trigger.narrative_Id !== narrativeId
              ? PanToolTwoTone
              : PanTool
            : trigger.type && trigger.type.startsWith("narrative_reviewswitch")
            ? trigger.narrative_Id !== narrativeId
              ? AssessmentTwoTone
              : AssessmentOutlined
            : trigger.narrative_Id !== narrativeId
            ? SettingsEthernet
            : Code
        }
        labelTitle={`${trigger.name || ""} ${
          trigger.narrative_Id !== narrativeId
            ? `Imported From Library ${narrativeId}`
            : ""
        }`}
        position={trigger.rank}
        setActiveViewEvent={handleTreeChange}
        setEditViewEvent={setEditAction}
        postCopyTrigger={postCopyTrigger}
      />
    ))

  let newsTriggerTreeItems =
    newsTriggers &&
    newsTriggers.map(trigger => (
      <StyledTreeItem
        sourceId={trigger.id}
        narrativeId={trigger.narrative_Id}
        selectedSourceId={selectedSourceId}
        setSourceId={setSourceId}
        setActionTaken={setActionTaken}
        selectedSourceType={selectedSourceType}
        setSourceType={setSourceType}
        setLastActionType={setLastActionType}
        refreshNarrative={refreshNarrative}
        key={`newstriggerid-${trigger.id}`}
        itemView={`newstriggerid-${trigger.id}`}
        nodeId={`newstriggerid-${trigger.id}`}
        launchLink={`/portal/narrative/${trigger.narrative_Id}/edit?view=newstriggerid-${trigger.id}`}
        labelText={`(${trigger.id}) ${trigger.name}`}
        labelInfo={trigger.type}
        labelIcon={
          trigger.narrative_Id !== narrativeId ? AddAlertTwoTone : AddAlert
        }
        labelTitle={`${trigger.name || ""} ${
          trigger.narrative_Id !== narrativeId
            ? `Imported From Library ${narrativeId}`
            : ""
        }`}
        position={trigger.rank}
        setActiveViewEvent={handleTreeChange}
        setEditViewEvent={setEditAction}
        postCopyTrigger={postCopyTrigger}
      />
    ))

  let triggerTreeItems =
    insightTriggers &&
    insightTriggers.map(trigger => (
      <StyledTreeItem
        sourceId={trigger.id}
        narrativeId={trigger.narrative_Id}
        selectedSourceId={selectedSourceId}
        setSourceId={setSourceId}
        setActionTaken={setActionTaken}
        selectedSourceType={selectedSourceType}
        setSourceType={setSourceType}
        setLastActionType={setLastActionType}
        refreshNarrative={refreshNarrative}
        key={`triggerid-${trigger.id}`}
        itemView={`triggerid-${trigger.id}`}
        nodeId={`triggerid-${trigger.id}`}
        launchLink={`/portal/narrative/${trigger.narrative_Id}/edit?view=triggerid-${trigger.id}`}
        labelText={`(${trigger.id}) ${trigger.name}`}
        labelInfo={trigger.type}
        labelIcon={
          trigger.narrative_Id !== narrativeId ? SettingsEthernet : Code
        }
        labelTitle={`${trigger.name || ""} ${
          trigger.narrative_Id !== narrativeId
            ? `Imported From Library ${narrativeId}`
            : ""
        }`}
        position={trigger.rank}
        setActiveViewEvent={handleTreeChange}
        setEditViewEvent={setEditAction}
        postCopyTrigger={postCopyTrigger}
      />
    ))

  let defaultExpanded = ["4"]

  return (
    <Box className={classes.root}>
      <Box className={classes.drawerPrimaryCard}>
        {props.searchedBlocks.length > 0 && (
          <Box className={classes.drawerPrimaryContent}>
            <TreeView
              defaultExpanded={defaultExpanded}
              defaultCollapseIcon={<ArrowDropDown />}
              defaultExpandIcon={<ArrowRight />}
              defaultEndIcon={<div style={{ width: 12 }} />}
              selected={activeView.split(",")}
              multiSelect={true}
            >
              <StyledTreeItem
                nodeId={"0"}
                itemView={`searchedBlocks`}
                labelText="Searched Blocks"
                labelIcon={SyncAlt}
                addLabel={`Search Results`}
                addView={`seesearcjresults`}
              >
                {searchedBlockItems}
              </StyledTreeItem>
            </TreeView>
          </Box>
        )}

        {props.searchedTriggers.length > 0 && (
          <Box className={classes.drawerPrimaryContent}>
            <TreeView
              defaultExpanded={defaultExpanded}
              defaultCollapseIcon={<ArrowDropDown />}
              defaultExpandIcon={<ArrowRight />}
              defaultEndIcon={<div style={{ width: 12 }} />}
              selected={activeView.split(",")}
              multiSelect={true}
            >
              {dependencies.length > 0 && (
                <StyledTreeItem
                  nodeId={"0"}
                  itemView={`dependencies`}
                  labelText="Searched Dependency Triggers"
                  labelIcon={SyncAlt}
                  addLabel={`Add Dependency`}
                  addView={`createdependency`}
                >
                  {dependencyTreeItems}
                </StyledTreeItem>
              )}
              {newsTriggers.length > 0 && (
                <StyledTreeItem
                  nodeId={"1"}
                  itemView={`newstriggers`}
                  labelText="Searched News Triggers"
                  labelIcon={FlashOn}
                  addLabel={`Add News Trigger`}
                  addView={`createnewstrigger`}
                >
                  {newsTriggerTreeItems}
                </StyledTreeItem>
              )}
              {insightTriggers.length > 0 && (
                <StyledTreeItem
                  nodeId={"2"}
                  itemView={`insighttriggers`}
                  labelText="Searched Insight Triggers"
                  labelIcon={ShowChart}
                  addLabel={`Add Insight Trigger`}
                  addView={`createinsight`}
                >
                  {triggerTreeItems}
                </StyledTreeItem>
              )}
            </TreeView>
          </Box>
        )}
      </Box>
    </Box>
  )
}

SearchExplorer.propTypes = {
  narrative: PropTypes.object,
  searchedTriggers: PropTypes.array,
  searchedBlocks: PropTypes.array,
  loading: PropTypes.bool,
  setActiveView: PropTypes.func,
  setEditEvent: PropTypes.func,
  exportSentence: PropTypes.func,
  exportParagraph: PropTypes.func,
  exportTrigger: PropTypes.func,
  activeView: PropTypes.string,
  searchString: PropTypes.string
}
