import { useMemo } from "react"
import {
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table"

const columnHelper = createColumnHelper()

const useTableSetupHelper = ({
  tableData = [],
  tableColumns = [],
  initialTableState = {},
  filtered = false,
  paginated = false,
  sorted = false,
  expanded = false,
  enableRowSelection = false,
  onRowSelectionChange = undefined
}) => {
  const columns = useMemo(
    () =>
      tableColumns.map(
        ({
          id,
          accessor,
          header,
          sortable = false,
          cell,
          filterable = false,
          filterFn,
          ...rest
        }) =>
          columnHelper.accessor(accessor, {
            id,
            header,
            enableSorting: sortable,
            enableColumnFilter: filterable,
            cell: props => (cell ? cell(props) : props.getValue()),
            filterFn: filterFn ? filterFn : "equals",
            ...rest
          })
      ),
    [tableColumns]
  )

  const tableOptions = {
    data: tableData,
    columns,
    enableRowSelection,
    initialTableState: initialTableState || undefined,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true
  }

  if (onRowSelectionChange) {
    tableOptions.onRowSelectionChange = onRowSelectionChange
  }

  if (paginated) {
    tableOptions.getPaginationRowModel = getPaginationRowModel()
  }

  if (sorted) {
    tableOptions.getSortedRowModel = getSortedRowModel()
    tableOptions.sortDescFirst = false
  }

  if (filtered) {
    tableOptions.enableFilters = true
    tableOptions.enableColumnFilters = true
    tableOptions.getFilteredRowModel = getFilteredRowModel()
  }

  if (expanded) {
    tableOptions.getExpandedRowModel = getExpandedRowModel()
    tableOptions.getRowCanExpand = () => true
  }

  const table = useReactTable(tableOptions)
  return table
}

export default useTableSetupHelper
