import React from "react"
import PropTypes from "prop-types"
import { observer, inject } from "mobx-react"

import cx from "classnames"
import withStyles from "@mui/styles/withStyles"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import footerStyle from "assets/jss/material-dashboard-pro-react/components/footerStyle"

const useStore = component =>
  inject(({ store }) => ({
    isAccountDetailsLoaded: store.uiStore.isAccountDetailsLoaded,
    isSiteInfoLoaded: store.uiStore.isSiteInfoLoaded,
    account: store.accountStore.account,
    siteInfo: store.appConfigurationStore.siteInfo
  }))(observer(component))

class Footer extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { classes, fluid, white, rtlActive } = this.props
    let container = cx({
      [classes.container]: !fluid,
      [classes.containerFluid]: fluid,
      [classes.whiteColor]: white
    })
    let block = cx({
      [classes.block]: true,
      [classes.whiteColor]: white
    })

    return (
      <footer className={classes.footer}>
        <div className={container}>
          <div className={classes.left}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                <a href="https://dataskrive.com" className={block}>
                  {rtlActive ? "الصفحة الرئيسية" : "Data Skrive"}
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a href="https://dataskrive.com/about" className={block}>
                  {rtlActive ? "شركة" : "About Us"}
                </a>
              </ListItem>
            </List>
          </div>
          <div className={classes.right}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                Web v. {window._env_.REACT_APP_WEB_VERSION}
              </ListItem>
              {this.props.account && this.props.account.username && (
                <ListItem className={classes.inlineBlock}>
                  API v.
                  {this.props.isSiteInfoLoaded &&
                    this.props.siteInfo.assemblyVersion}{" "}
                </ListItem>
              )}
              <ListItem className={classes.inlineBlock}>
                &nbsp; &copy; {1900 + new Date().getYear()} Data Skrive&nbsp;
              </ListItem>
            </List>
          </div>
        </div>
      </footer>
    )
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool
}

export default withStyles(footerStyle)(useStore(Footer))
