// eslint-disable-next-line no-unused-vars
import React, { useState } from "react"

// 3rd Party Utils
import { useStore } from "contexts/rootContext"
import { useObserver } from "mobx-react"

// MUI Components
import { CircularProgress, IconButton } from "@mui/material"
import { FlashOn, Info } from "@mui/icons-material"
import CardHeader from "components/Card/CardHeader.jsx"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import { Modal, ModalHeader, ModalBody } from "reactstrap"

// Custom Components
import CardIcon from "components/Card/CardIcon.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import Button from "components/CustomButtons/Button.jsx"
import NarrativeModelTriggersSnaphot from "../Data/NarrativeModelTriggersSnaphot"

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    loadingTriggers: store.narrativeStore.loading,
    triggersPreview: store.narrativeStore.triggersPreview
  }))
}

function TriggerMetadataSync(props) {
  const {
    narrativeId,
    setActiveView,
    classes,
    evaluateTriggers,
    updateTriggers
  } = props
  const { loadingTriggers, triggersPreview } = useStoreData()
  const [shouldUpdateTriggers, setShouldUpdateTriggers] = useState(false)
  const [evaluateTriggersInfo, setEvaluateTriggersInfo] = useState(false)

  const dynamicTriggers = triggersPreview?.toJS()
  const handleEvaluateTriggers = () => {
    setShouldUpdateTriggers(false)
    evaluateTriggers()
  }

  const handleUpdateTriggers = () => {
    setShouldUpdateTriggers(true)
    updateTriggers()
  }

  return (
    <>
      {evaluateTriggersInfo && (
        <Modal
          isOpen={evaluateTriggersInfo}
          style={{
            width: "80vw",
            maxWidth: "80vw"
          }}
        >
          <ModalHeader
            toggle={() => setEvaluateTriggersInfo(!evaluateTriggersInfo)}
          >
            Evaluate Triggers Help
          </ModalHeader>
          <ModalBody>
            <div style={{ padding: "10px 30px" }}>
              <p>
                <span style={{ fontWeight: "bold" }}>Evaluate Triggers</span>{" "}
                functionality will evaluate all narrative triggers against an
                existing data model and shows the tokens and data that it
                generates. .
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}>Update Triggers</span>{" "}
                functionality actively evaluates and saves all narrative trigger
                data against an existing data model and shows the tokens and
                data that it generates. This “update” functionality is most
                valuable when the data model has changed or been updated.
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end"
              }}
            >
              <Button
                style={{ margin: "10px 10px 10px 10px" }}
                size="sm"
                variant="contained"
                onClick={() => setEvaluateTriggersInfo(false)}
              >
                Close
              </Button>
            </div>
          </ModalBody>
        </Modal>
      )}
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <FlashOn />
          </CardIcon>
          <GridContainer>
            <GridItem xs={6}>
              <div style={{ display: "flex" }}>
                <h4
                  className={classes}
                  style={{ color: "#333", marginTop: "15px" }}
                >
                  Evaluate Triggers
                </h4>
                <IconButton
                  aria-label="StayPrimaryLandscape"
                  onClick={() => setEvaluateTriggersInfo(true)}
                  size="large"
                >
                  <Info style={{ color: "#4d9ab5" }} />
                </IconButton>
              </div>
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          <Button
            size="sm"
            variant="contained"
            color="primary"
            onClick={handleEvaluateTriggers}
          >
            Evaluate Triggers
          </Button>
          <Button
            size="sm"
            variant="contained"
            color="primary"
            title="Update the triggers with metadata from this model."
            onClick={handleUpdateTriggers}
          >
            Update Triggers
          </Button>
          {loadingTriggers && <CircularProgress />}
          {dynamicTriggers?.length > 0 && (
            <NarrativeModelTriggersSnaphot
              setActiveView={setActiveView}
              narrativeId={narrativeId}
              embedded={true}
              triggers={dynamicTriggers}
              showUpdatedColumn={shouldUpdateTriggers}
              pageSize={50}
            />
          )}
        </CardBody>
      </Card>
    </>
  )
}

export default TriggerMetadataSync
