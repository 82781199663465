import React, { Component } from "react"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import { globalSettings } from "variables/general"
import { observer, inject } from "mobx-react"

const useStore = component =>
  inject(({ store }) => ({
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization
  }))(observer(component))

class ApplicationServicesList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      textToValidate: "N/A",
      grammarValidationResults: {}
    }
  }

  componentDidMount() {
    this.props.setShowChangeCurrentOrganization(false)
  }

  render() {
    return (
      <GridContainer>
        <GridItem md={12}>
          Our ASP.NET Core Application Services <br />
          <iframe
            title="ASP App Services"
            src={`${globalSettings.apiBaseUrl}/api/ourservices`}
            style={{ height: "800px", width: "100%" }}
          />
        </GridItem>
      </GridContainer>
    )
  }
}

export default useStore(ApplicationServicesList)
