import Dashboard from "views/Dashboard/Dashboard"
import QADashboard from "views/Dashboard/QADashboard"
import RenderDashboard from "views/Dashboard/RenderDashboard"
import Buttons from "views/Components/Buttons"
import GridSystem from "views/Components/GridSystem"
import Panels from "views/Components/Panels"
import SweetAlert from "views/Components/SweetAlert"
import Notifications from "views/Components/Notifications"
import Icons from "views/Components/Icons"
import Typography from "views/Components/Typography"
import RegularForms from "views/Forms/RegularForms"
import ValidationForms from "views/Forms/ValidationForms"
import Wizard from "views/Forms/Wizard"
import RegularTables from "views/Tables/RegularTables"
import ExtendedTables from "views/Tables/ExtendedTables"
import ReactTables from "views/Tables/ReactTables"
import AccountProfile from "views/Pages/AccountProfile"
import AccountPermissions from "views/Pages/AccountPermissions"
import AccountNotifications from "views/Pages/AccountNotifications"
import TimelinePage from "views/Pages/Timeline"
import CTAGeoPrototype from "views/Pages/CTAGeoPrototype"
import RTLSupport from "views/Pages/RTLSupport"
import FAQs from "views/FAQ/FAQs"
import SiteInfo from "views/SiteInfo/SiteInfo"
import TranslateTextTool from "views/TranslateTextTool/TranslateTextTool"
import GraphicsTool from "views/GraphicsTool/GraphicsTool"
import QueryBuilder from "../views/QueryBuilder/QueryBuilder"
import Narratives from "views/NarrativeAdmin/Narratives"
import Libraries from "views/NarrativeAdmin/Libraries"
import CTAsManager from "views/NarrativeAdmin/CTAsManager"
import LibraryCopyTools from "views/NarrativeAdmin/LibraryCopyTools"
import NarrativeCloneTool from "views/NarrativeAdmin/NarrativeCloneTool"
import NarrativeBlockMover from "views/NarrativeAdmin/NarrativeBlockMover"
import NarrativeOutlineMover from "views/NarrativeAdmin/NarrativeOutlineMover"
import NarrativeEditor from "views/NarrativeAdmin/NarrativeEditor"
import NarrativeDashboard from "views/NarrativeAdmin/NarrativeDashboard"
import BlockSearch from "views/NarrativeAdmin/BlockSearch"
import ParagraphEditor from "views/NarrativeAdmin/ParagraphEditor"
import InReview from "views/NarrativeAdmin/InReview"
import Widgets from "views/Widgets/Widgets"
import Calendar from "views/Calendar/Calendar"
import Feeds from "views/FeedAdmin/Feeds"
import FeedEntries from "views/FeedAdmin/FeedEntries"
import FeedEntryEditor from "views/FeedAdmin/FeedEntryEditor"
import FeedScheduler from "views/FeedAdmin/FeedScheduler"
import NarrativeSchedule from "views/NarrativeAdmin/NarrativeSchedule"
import NarrativeOutput from "views/NarrativeAdmin/NarrativeOutput"
import pagesRoutes from "./pages.js"
import Organizations from "views/Admin/Organizations"
import Properties from "views/Admin/Properties"
import Affiliates from "views/Admin/Affiliates"
import Accounts from "views/Admin/Accounts"
import AssetManager from "views/AssetManager/AssetManager"
import ManageCompetitions from "views/SportsManagers/ManageCompetitions"
import ManageTeams from "views/SportsManagers/ManageTeams"
import ManagePlayers from "views/SportsManagers/ManagePlayers"
import ManageGeographicPlaces from "views/SportsManagers/ManageGeographicPlaces"
import WebhookManager from "views/WebhookManager/WebhookManager"
import CacheManager from "views/CacheManager/CacheManager"
import WikiPage from "views/WikiPageManager/WikiPage"
import FileEditor from "../views/FileEditor/FileEditor"
import PermissionGroups from "views/Admin/PermissionGroups"
import MatchOddsTool from "../views/MatchOddsTool/MatchOddsTool"
import ImageSearchTool from "../views/ImageSearchTool/ImageSearchTool"
import ApplicationServicesList from "../views/ApplicationServices/ApplicationServicesList"
import ReportBuilder from "../views/ReportBuilder/ReportBuilder"
import ReportViewer from "../views/ReportBuilder/ReportViewer"
import QAReports from "../views/ReportBuilder/QAReports"
import WebSocketTool from "views/WebSocketTool/WebSocketTool"
import RenderToolWrapper from "views/RenderTool/RenderToolWrapper"
import Dialogs from "views/Components/Dialogs"
import CTAManagerOfferLinks from "views/CTAManagerV2/CTAManagerOfferLinks"
import CTAManagerDisclaimerLinks from "views/CTAManagerV2/CTAManagerDisclaimerLinks"
import GraphicsToolV2 from "views/GraphicsToolV2.jsx/GraphicsToolV2"
import RealTimeOutputs from "views/RealTimeManager/RealTimeOutputs"
import RealTimeManager from "views/RealTimeManager/RealTimeManager"
import EditorRealTimeOutput from "views/RealTimeManager/EditorRealTimeOutput"
import EditorRealTimeLibrary from "components/RealTime/EditorRealTimeLibrary/EditorRealTimeLibrary"

import { Cookies } from "tools/storage" //12 July - For NarrativeBlockMover
import {
  DateRange,
  AccountTreeOutlined,
  RssFeedOutlined,
  ListAltOutlined,
  RoomService,
  LibraryBooks,
  InsertDriveFileOutlined,
  BuildOutlined,
  InfoOutlined,
  ToysOutlined,
  HelpOutlined,
  SearchOutlined,
  MenuBook,
  HelpOutline,
  PermDataSetting,
  AccessTimeFilled
} from "@mui/icons-material"

let cookies = new Cookies()
cookies.set("BlockMoverSources", { id: [], name: [] })
cookies.set("BlockMoverTargets", { id: [], name: [] })

let pages = [
  {
    path: "/portal/cta-geo-prototype",
    name: "CTA Geo Prototype",
    mini: "CT",
    component: CTAGeoPrototype
  },
  {
    path: "/portal/timeline-page",
    name: "Timeline Page",
    mini: "TP",
    component: TimelinePage
  },
  {
    path: "/portal/accountpermissions",
    name: "Account Permissions",
    mini: "AP",
    component: AccountPermissions
  },
  {
    path: "/portal/accountnotifications",
    name: "Account Notifications",
    mini: "AN",
    component: AccountNotifications,
    showOrgMenu: true
  },
  {
    path: "/portal/accountprofile",
    name: "Account Profile",
    mini: "UP",
    component: AccountProfile
  },
  {
    path: "/portal/rtl/rtl-support-page",
    name: "RTL Support",
    mini: "RS",
    component: RTLSupport
  },
  {
    path: "/portal/moretools/websockettool", // Corrected path for WebSocketTool
    name: "Web Socket Tool",
    component: WebSocketTool
  },
  {
    path: "/portal/moretools/translatetexttool", // Corrected path for TranslateTextTool
    name: "Translate Text Tool",
    component: TranslateTextTool
  }
].concat(pagesRoutes)

let dashRoutes = [
  {
    collapse: true,
    path: "/qa",
    name: "Data Hub",
    state: "openQA",
    icon: PermDataSetting,
    views: [
      {
        path: "/portal/qa-dashboard",
        name: "QA Dashboard",
        icon: "nc-icon nc-single-copy-04",
        component: QADashboard
      },
      {
        path: "/portal/render-dashboard",
        name: "Render Dashboard",
        icon: "nc-icon nc-single-copy-04",
        component: RenderDashboard
      },
      {
        collapse: true,
        name: "Reports",
        icon: "nc-icon nc-single-copy-04",
        state: "openReports",
        views: [
          {
            hideInNav: true,
            path: "/portal/qa-reports/:narrativeId",
            component: QAReports
          },
          {
            path: "/portal/qa-reports/",
            name: "QA Reports",
            icon: "nc-icon nc-single-copy-04",
            component: QAReports
          },
          {
            hideInNav: true,
            path: "/portal/report-builder/:reportId",
            component: ReportBuilder
          },
          {
            path: "/portal/report-builder/",
            name: "Report Builder",
            icon: "nc-icon nc-single-copy-04",
            component: ReportBuilder
          },
          {
            hideInNav: true,
            path: "/portal/report-viewer/:reportId",
            component: ReportViewer
          },
          {
            path: "/portal/report-viewer/",
            name: "Report Viewer",
            icon: "nc-icon nc-single-copy-04",
            component: ReportViewer
          }
        ]
      },
      {
        collapse: true,
        name: "Manage",
        icon: "nc-icon nc-single-copy-04",
        state: "openManage",
        views: [
          {
            path: "/portal/competitionmanager",
            name: "Manage Competitions",
            icon: "nc-icon nc-single-copy-04",
            component: ManageCompetitions
          },
          {
            path: "/portal/teammanager",
            name: "Manage Teams",
            icon: "nc-icon nc-single-copy-04",
            component: ManageTeams
          },
          {
            path: "/portal/playermanager",
            name: "Manage Players",
            icon: "nc-icon nc-single-copy-04",
            component: ManagePlayers
          },
          {
            path: "/portal/geographicplacemanager",
            name: "Manage Geographic Places",
            icon: "nc-icon nc-single-copy-04",
            component: ManageGeographicPlaces
          }
        ]
      },
      {
        hideInNav: false,
        path: "/portal/dashboard",
        name: "Performance Metrics",
        component: Dashboard
      }
    ]
  },
  {
    collapse: true,
    path: "/portal/librarymanager",
    name: "Library Manager",
    mini: "L",
    state: "openLibraryManager",
    icon: LibraryBooks,
    views: [
      {
        path: "/portal/libraries",
        name: "Manage Libraries",
        component: Libraries,
        showOrgMenu: false
      },
      {
        path: "/portal/ctas",
        name: "Manage CTAs (Legacy)",
        icon: "nc-icon nc-single-copy-03",
        component: CTAsManager,
        showOrgMenu: false
      },
      {
        collapse: true,
        path: "/portal/manage-ctas",
        name: "CTA Manager",
        icon: "nc-icon nc-single-copy-03",
        state: "openCTAs",
        views: [
          {
            path: "/portal/manage-ctas/offer", // Add a path for "Offer Link"
            name: "Offer",
            icon: "nc-icon nc-single-copy-04",
            component: CTAManagerOfferLinks,
            showOrgMenu: false
          },
          {
            path: "/portal/manage-ctas/disclaimer", // Add a path for "Disclaimer Link"
            name: "Disclaimer",
            icon: "nc-icon nc-single-copy-04",
            component: CTAManagerDisclaimerLinks,
            showOrgMenu: false
          }
        ]
      },
      {
        path: "/portal/LibraryCopyTools",
        name: "Library Copy Tools",
        component: LibraryCopyTools,
        showOrgMenu: false
      }
    ]
  },
  {
    collapse: true,
    path: "/narrativemanager",
    name: "Output Manager",
    mini: "N",
    state: "openNarrativeManager",
    icon: ListAltOutlined,
    views: [
      {
        path: "/portal/narratives",
        name: "Manage Outputs",
        icon: "nc-icon nc-single-copy-04",
        component: Narratives,
        showOrgMenu: true
      },
      {
        path: "/portal/narrative-outline-mover",
        name: "Import/Export Output Outlines",
        icon: "nc-icon nc-single-copy-03",
        component: NarrativeOutlineMover,
        showOrgMenu: true
      },
      {
        path: "/portal/narrative-block-mover",
        name: "Import/Export Output Blocks",
        icon: "nc-icon nc-single-copy-03",
        component: NarrativeBlockMover,
        showOrgMenu: true
      },
      {
        path: "/portal/narrative-clone-tool",
        name: "Import/Export Narratives",
        icon: "nc-icon nc-single-copy-03",
        component: NarrativeCloneTool,
        showOrgMenu: true
      },
      {
        path: "/portal/review-blocks",
        name: "Review Blocks",
        icon: "nc-icon nc-single-copy-04",
        component: InReview,
        showOrgMenu: true
      },
      {
        hideInNav: true,
        path: "/portal/narrative/:id/schedule",
        name: "Narrative Schedule",
        icon: "nc-icon nc-ruler-pencil",
        component: NarrativeSchedule,
        showOrgMenu: true,
        showOrgMenuRegexp: /\/portal\/narrative\/\d{1,}\/schedule$/
      },
      {
        hideInNav: true,
        path: "/portal/narrative/:id/output",
        name: "Narrative Output",
        icon: "nc-icon nc-ruler-pencil",
        component: NarrativeOutput,
        showOrgMenu: true,
        showOrgMenuRegexp: /\/portal\/narrative\/\d{1,}\/output$/
      },
      {
        hideInNav: true,
        path: "/portal/narrative/:id/edit",
        name: "Edit Narrative",
        icon: "nc-icon nc-ruler-pencil",
        component: NarrativeEditor,
        showOrgMenu: true,
        showOrgMenuRegexp: /\/portal\/narrative\/\d{1,}\/edit$/
      },
      {
        hideInNav: true,
        path: "/portal/narrative/:id",
        name: "Narrative Dashboard",
        icon: "nc-icon nc-ruler-pencil",
        component: NarrativeDashboard,
        showOrgMenu: true,
        showOrgMenuRegexp: /\/portal\/narrative\/\d{1,}$/
      },
      {
        hideInNav: true,
        path: "/portal/paragraph/:id",
        name: "Edit Paragraph",
        icon: "nc-icon nc-ruler-pencil",
        component: ParagraphEditor,
        showOrgMenu: true,
        showOrgMenuRegexp: /\/portal\/paragraph\/\d{1,}$/
      }
    ]
  },
  {
    hideInNav: false,
    path: "/portal/search",
    name: "Search",
    mini: "DB",
    icon: SearchOutlined,
    component: BlockSearch
  },
  {
    collapse: true,
    path: "/feedmanager",
    name: "Delivery Manager",
    mini: "F",
    state: "openFeedManager",
    icon: RssFeedOutlined,
    views: [
      {
        path: "/portal/feed-manager",
        name: "Manage Feeds",
        icon: "nc-icon nc-single-copy-04",
        component: Feeds,
        showOrgMenu: true
      },
      {
        path: "/portal/render-schedule",
        name: "Render",
        icon: "nc-icon nc-calendar",
        component: FeedScheduler,
        showOrgMenu: true
      },
      {
        path: "/portal/webhookmanager",
        name: "Manage In/Out Webhooks",
        icon: "nc-icon nc-single-copy-04",
        component: WebhookManager,
        showOrgMenu: true
      },
      {
        hideInNav: true,
        path: "/portal/feed-entries/entry/:entryId",
        name: "Edit Entry",
        icon: "nc-icon nc-ruler-pencil",
        component: FeedEntryEditor,
        showOrgMenu: true,
        showOrgMenuRegexp: /\/portal\/feed-entries\/entry\/\d{1,}$/
      },
      {
        hideInNav: true,
        path: "/portal/feed-entries/:feedId",
        name: "Feed Entries",
        icon: "nc-icon nc-single-copy-03",
        component: FeedEntries,
        showOrgMenu: true,
        showOrgMenuRegexp: /\/portal\/feed-entries\/\d{1,}$/
      }
    ]
  },
  {
    collapse: true,
    path: "portal/realtime",
    name: "Real Time",
    state: "openRealTime",
    icon: AccessTimeFilled,
    hideInNav: false,
    views: [
      {
        path: "/portal/real-time-libraries",
        name: "Manage Real Time Libraries",
        component: RealTimeManager,
        showOrgMenu: false
      },
      {
        path: "/portal/real-time-outputs",
        name: "Manage Real Time Outputs",
        component: RealTimeOutputs,
        showOrgMenu: true
      },
      {
        path: "/portal/real-time-library/edit",
        name: "A Real Time Library",
        component: EditorRealTimeLibrary,
        showOrgMenu: false,
        hideInNav: true
      },
      {
        path: "/portal/real-time-output/:id",
        name: "A Real Time Output",
        component: EditorRealTimeOutput,
        showOrgMenu: true,
        showOrgMenuRegexp: /\/portal\/real-time-output\/\d{1,}$/,
        hideInNav: true
      }
    ]
  },
  {
    collapse: true,
    path: "/accountmanager",
    name: "Account Manager",
    mini: "A",
    state: "openAccountManager",
    icon: AccountTreeOutlined,
    views: [
      {
        path: "/portal/organizations",
        name: "Manage Organizations",
        icon: "nc-icon nc-single-copy-04",
        component: Organizations
      },
      {
        path: "/portal/properties",
        name: "Manage Properties",
        icon: "nc-icon nc-single-copy-04",
        component: Properties
      },
      {
        path: "/portal/affiliates",
        name: "Manage Affiliates",
        icon: "nc-icon nc-single-copy-04",
        component: Affiliates
      },
      {
        path: "/portal/accounts",
        name: "Manage User Accounts",
        icon: "nc-icon nc-single-copy-04",
        component: Accounts
      },
      {
        path: "/portal/permissiongroups",
        name: "Manage Permission Groups",
        icon: "nc-icon nc-single-copy-04",
        component: PermissionGroups
      }
    ]
  },
  {
    path: "https://dataskrivejira.atlassian.net/wiki/spaces/PKB/overview",
    name: "Knowledge Base",
    icon: HelpOutline,
    external: true
  },
  {
    collapse: true,
    organizationId: 44,
    path: "/wiki",
    name: "Wiki",
    state: "openWiki",
    icon: MenuBook,
    views: [
      {
        hideInNav: true,
        path: "/portal/wiki/:wikiPageId",
        component: WikiPage
      },
      {
        path: "/portal/wiki/1",
        name: "Page #1",
        component: WikiPage
      },
      {
        path: "/portal/wiki/2",
        name: "Page #2",
        component: WikiPage
      },
      {
        path: "/portal/wiki/3",
        name: "Page #3",
        component: WikiPage
      }
    ]
  },
  {
    collapse: true,
    organizationId: 44,
    path: "/filemanager",
    name: "File Manager",
    state: "openFileManager",
    icon: InsertDriveFileOutlined,
    views: [
      {
        path: "/portal/filemanager",
        name: "Manage Files",
        icon: "nc-icon nc-single-copy-04",
        component: AssetManager,
        organizationId: 34,
        showOrgMenu: true
      },
      {
        hideInNav: true,
        path: "/portal/fileeditor/:assetId",
        component: FileEditor,
        organizationId: 34,
        showOrgMenu: true
      }
    ]
  },
  {
    collapse: true,
    path: "/tools",
    name: "Tools",
    state: "openTools",
    icon: BuildOutlined,
    exact: true,
    views: [
      {
        path: "/portal/tools/cachemanager",
        name: "Cache Manager",
        mini: "CM",
        component: CacheManager
      },
      {
        path: "/portal/tools/graphicstool",
        name: "Graphics Tool",
        mini: "GT",
        component: GraphicsTool
      },
      {
        path: "/portal/tools/graphicstoolv2",
        name: "Graphics Toolv2",
        mini: "GT",
        component: GraphicsToolV2
      },
      {
        path: "/portal/tools/imagesearchtool",
        name: "Image Search Tool",
        mini: "IST",
        component: ImageSearchTool
      },
      {
        hideInNav: true,
        path: "/portal/tools/querybuilder/:queryId",
        component: QueryBuilder
      },
      {
        path: "/portal/tools/querybuilder",
        name: "Query Builder",
        mini: "QB",
        component: QueryBuilder
      },
      {
        path: "/portal/tools/rendertool",
        name: "Render Tool",
        mini: "RT",
        component: RenderToolWrapper
      },
      {
        path: "/portal/tools/matchoddstool",
        name: "Match Odds Tool",
        mini: "MOT",
        component: MatchOddsTool
      },
      {
        collapse: true,
        path: "/moretools",
        name: "More Tools",
        state: "openMoreTools",
        views: [
          {
            path: "/portal/moretools/websockettool", // Corrected path for WebSocketTool
            name: "Web Socket Tool",
            component: WebSocketTool
          },
          {
            path: "/portal/moretools/translatetexttool", // Corrected path for TranslateTextTool
            name: "Translate Text Tool",
            component: TranslateTextTool
          }
        ]
      }
    ]
  },
  {
    path: "/portal/faqs",
    name: "FAQs",
    icon: HelpOutlined,
    component: FAQs
  },
  {
    path: "/portal/applicationservices",
    name: "Application Services",
    icon: RoomService,
    component: ApplicationServicesList
  },
  {
    path: "/portal/siteinfo",
    name: "SiteInfo",
    icon: InfoOutlined,
    component: SiteInfo
  },
  {
    collapse: true,
    hideInNav: false,
    path: "-page",
    name: "Sandbox Pages",
    state: "openPages",
    icon: ToysOutlined,
    views: pages
  },
  /* The following are examples of what can be down with this react template by creative-tim */
  {
    collapse: true,
    hideInNav: false,
    path: "/portal/components",
    name: "Sandbox",
    state: "openMaps",
    icon: ToysOutlined,
    views: [
      {
        path: "/portal/components/buttons",
        name: "Buttons",
        mini: "B",
        component: Buttons
      },
      {
        path: "/portal/components/grid-system",
        name: "Grid System",
        mini: "GS",
        component: GridSystem
      },
      {
        path: "/portal/components/panels",
        name: "Panels",
        mini: "P",
        component: Panels
      },
      {
        path: "/portal/components/sweet-alert",
        name: "Sweet Alert",
        mini: "SA",
        component: SweetAlert
      },
      {
        path: "/portal/components/dialogs",
        name: "Dialogs",
        mini: "D",
        component: Dialogs
      },
      {
        path: "/portal/components/notifications",
        name: "Notifications",
        mini: "N",
        component: Notifications
      },
      {
        path: "/portal/components/icons",
        name: "Icons",
        mini: "I",
        component: Icons
      },
      {
        path: "/portal/components/typography",
        name: "Typography",
        mini: "T",
        component: Typography
      },
      {
        path: "/portal/forms/regular-forms",
        name: "Regular Forms",
        mini: "RF",
        component: RegularForms
      },
      {
        path: "/portal/forms/validation-forms",
        name: "Validation Forms",
        mini: "VF",
        component: ValidationForms
      },
      {
        path: "/portal/forms/wizard",
        name: "Wizard",
        mini: "W",
        component: Wizard
      },
      {
        path: "/portal/tables/regular-tables",
        name: "Regular Tables",
        mini: "RT",
        component: RegularTables
      },
      {
        path: "/portal/tables/extended-tables",
        name: "Extended Tables",
        mini: "ET",
        component: ExtendedTables
      },
      {
        path: "/portal/tables/react-tables",
        name: "React Tables",
        mini: "RT",
        component: ReactTables
      },
      {
        path: "/portal/widgets",
        name: "Widgets",
        mini: "W",
        component: Widgets
      },
      {
        path: "/portal/calendar",
        name: "Calendar",
        mini: "C",
        icon: DateRange,
        component: Calendar
      }
    ]
  },
  {
    redirect: true,
    path: "/portal/libraryNarratives",
    pathTo: "/portal/libraries",
    name: "Libraries"
  },
  {
    redirect: true,
    path: "/",
    pathTo: "/portal/dashboard",
    name: "Dashboard"
  }
]

// TODO: Enforce permissions on who can see these Dashboard Routes
//dashRoutes = dashRoutes.filter( r => r.name == "File Manager")
export default dashRoutes
