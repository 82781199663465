import { useState } from "react"
import { Box, Button } from "@mui/material"
import { makeStyles } from "@mui/styles"
import Highlighter from "react-highlight-words"

import SelectTriggersToAssociateTable from "../../Table/SelectTriggersToAssociateTable"
import SavedTriggersToBlocksAssociationTable from "../../Table/SavedTriggersToBlocksAssociationTable"

import CustomDSDialog from "components/CustomDialogs/CustomDSDialog"
import Warning from "@mui/icons-material/Warning"
import NarrativeDAO from "daos/narrativeDAO"
import LinearProgress from "@mui/material/LinearProgress"
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline"

export const triggerEditableFields = [
  {
    value: "name",
    label: "Name"
  },
  {
    value: "type",
    label: "Key"
  },
  {
    value: "formula",
    label: "Formula"
  }
]

const columns = [
  {
    header: "Narrative ID",
    accessor: "targetNarrativeId",
    sortable: true,
    filterable: false
  },
  {
    header: "Trigger Key",
    accessor: "triggerKey",
    sortable: true,
    filterable: true,
    filterFn: "includesStringSensitive",
    cell: ({ column, getValue }) => (
      <Highlighter
        searchWords={[column.getFilterValue()]}
        textToHighlight={getValue()}
      />
    )
  },
  {
    header: "Trigger ID",
    accessor: "newId",
    sortable: true,
    filterable: false
  }
]

const finalColumns = [
  {
    header: "Narrative ID",
    accessor: "targetNarrativeId",
    sortable: true,
    filterable: false
  },
  {
    header: "Paragraph ID",
    accessor: "paragraphId",
    sortable: true,
    filterable: false
  },
  {
    header: "Sentence ID",
    accessor: "sentenceId",
    sortable: true,
    filterable: false
  },
  {
    header: "Trigger Key",
    accessor: "triggerKey",
    sortable: true,
    filterable: true,
    filterFn: "includesStringSensitive",
    cell: ({ column, getValue }) => (
      <Highlighter
        searchWords={[column.getFilterValue()]}
        textToHighlight={getValue()}
      />
    )
  },
  {
    header: "Trigger ID(s)",
    accessor: "triggerIds",
    sortable: true,
    filterable: false
  }
]

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  buttons: {
    display: "flex",
    justifyContent: "right"
  },
  infoHeader: {
    textAlign: "left",
    color: "#inherit"
  },
  addScrollIfNeeded: {
    maxHeight: "300px",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "10px 0 0 0",
    "& li": {
      textAlign: "left"
    }
  }
}))

const TriggerIDsToContentBlocksWizard = ({
  data = [],
  targetNarrative,
  triggerChanges,
  onCancel
}) => {
  const [showAssociateTable, setShowAssociateTable] = useState(true)
  const [resultOfTriggerUpdates, setResultOfTriggerUpdates] = useState(false)
  const [triggerChangesOngoing, setTriggerChangesOngoing] = useState(false)

  const [lastItemsList, setLastItemsList] = useState([])
  const [resultOfTriggerUpdatesList, setResultOfTriggerUpdatesList] = useState(
    []
  )
  const [saveTriggerDialogOpen, setSaveTriggerDialogOpen] = useState(false)

  const classes = useStyles()

  data.forEach(newTriggers => {
    targetNarrative?.triggers?.forEach(itm => {
      if (itm.id === newTriggers.newId) {
        newTriggers.triggerKey = itm.type
        newTriggers.id = newTriggers.newId
      }
    })
  })

  const tableData =
    data?.filter(itm => itm.type === "Copy Trigger" && !!itm.triggerKey) || []

  const copiedToBlocks = data?.filter(
    itm => itm.type === "Copy Paragraph" || itm.type === "Copy Sentence"
  )

  const assembleUpdateData = () => {
    const triggerIdsWithChanges = []
    triggerChanges.forEach(itm => {
      if (lastItemsList.includes(itm.itemId)) {
        const obj = {
          id: itm.itemId,
          triggerChangesToMake: itm.changes.filter(itm => itm.field === "type")
        }
        triggerIdsWithChanges.push(obj)
      }
    })
    const blocksContainingNewTriggers = []
    copiedToBlocks.forEach(itm => {
      const obj = {
        id: itm.newId,
        sourceId: Number(itm.sourceId),
        targetNarrative: itm.targetNarrative,
        action: itm.type
      }
      blocksContainingNewTriggers.push(obj)
    })
    return { blocksContainingNewTriggers, triggerIdsWithChanges }
  }

  const onSeeSummary = () => {
    const body = assembleUpdateData()
    if (body) {
      setTriggerChangesOngoing(true)
      NarrativeDAO.updateContentBlockWithNewTrigger(body).then(
        triggerChangesMade => {
          if (triggerChangesMade.length > 0) {
            setResultOfTriggerUpdatesList(triggerChangesMade)
            setSaveTriggerDialogOpen(true)
          } else {
            setResultOfTriggerUpdatesList([{ error: true }])
          }
          setTriggerChangesOngoing(false)
          setShowAssociateTable(false)
          setResultOfTriggerUpdates(true)
        }
      )
    }
  }

  const nothingChangeMessage = () => (
    <div className={classes.infoHeader}>
      <h2>Nothing was updated</h2>
    </div>
  )

  return (
    <>
      {resultOfTriggerUpdates ? (
        <>
          {resultOfTriggerUpdatesList.length > 0 &&
          resultOfTriggerUpdatesList[0].error ? (
            <CustomDSDialog
              open={resultOfTriggerUpdatesList.length > 0}
              content={nothingChangeMessage()}
              icon={<Warning style={{ fontSize: "4rem", color: "#ffc107" }} />}
              backgroundColor={"FFC107"}
              onClose={() => {
                setResultOfTriggerUpdates(false)
                onCancel()
              }}
            />
          ) : (
            <CustomDSDialog
              open={saveTriggerDialogOpen}
              icon={<CheckCircleOutline style={{ fontSize: "4rem" }} />}
              backgroundColor={"#66BB6A"}
              content={
                <>
                  <SavedTriggersToBlocksAssociationTable
                    lastItemsList={lastItemsList}
                    onItemsChange={val => {
                      setLastItemsList(val)
                    }}
                    columns={finalColumns}
                    tableData={resultOfTriggerUpdatesList}
                    targetNarrative={targetNarrative}
                    onCancel={() => {
                      onCancel()
                    }}
                  />
                </>
              }
            />
          )}
        </>
      ) : (
        <>
          <Box className={classes.root}>
            {showAssociateTable && !resultOfTriggerUpdates && (
              <>
                <SelectTriggersToAssociateTable
                  lastItemsList={lastItemsList}
                  onItemsChange={val => {
                    setLastItemsList(val)
                  }}
                  columns={columns}
                  tableData={tableData}
                  narrativeId={targetNarrative?.id}
                />
                <Box mt={1} className={classes.buttons}>
                  <Box>
                    <Button
                      style={{ marginRight: 8 }}
                      variant="contained"
                      color="secondary"
                      onClick={onCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={onSeeSummary}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
                {triggerChangesOngoing && (
                  <div
                    style={{
                      height: "10px",
                      width: "100%",
                      margin: "3px 10px"
                    }}
                  >
                    <LinearProgress />
                  </div>
                )}
              </>
            )}
          </Box>
        </>
      )}
    </>
  )
}

export default TriggerIDsToContentBlocksWizard
