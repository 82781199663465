import { useState, useEffect } from "react"

// MUI Components
import Grid from "@mui/material/Grid"

// Custom Components
import { Body } from "components/Form"
import Accordion from "components/Accordion/Accordion"
import ReactDiffViewer from "react-diff-viewer"

// Data/Utils
import foxsportsRelevancyDAO from "daos/foxsportsRelevancyDAO"
import PropTypes from "prop-types"
import { withStyles } from "@mui/styles"
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle"
import { TextField } from "@mui/material"

const FoxSportsRelevancyEngine = props => {
  const [analyzedContent, setAnalyzedContent] = useState(null)

  const analyzeContent = async content => {
    try {
      const data = await foxsportsRelevancyDAO.analyzeContent(content)
      setAnalyzedContent(data)
    } catch (err) {
      console.error(`Error analyzing content via FoxSports Relevancy API`, err)
    }
  }
  // analyze content via FoxSports Relevancy API
  useEffect(() => {
    analyzeContent(props?.content)
  }, [props?.content])

  return (
    <Body>
      <Grid container spacing={3}>
        <Grid item md={12}>
          {analyzedContent !== null && (
            <Grid item md={12} style={{ padding: "10px" }}>
              <Accordion
                active={null}
                collapses={[
                  {
                    title: "Differences",
                    content: (
                      <Grid item md={12}>
                        <ReactDiffViewer
                          oldValue={props?.content}
                          newValue={analyzedContent}
                          splitView={false}
                          showDiffOnly={true}
                        />
                      </Grid>
                    )
                  },
                  {
                    title: "Rendered",
                    content: (
                      <Grid item md={12}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: analyzedContent
                          }}
                          style={{ paddingTop: 0 }}
                        />
                      </Grid>
                    )
                  },
                  {
                    title: "Raw",
                    content: (
                      <TextField
                        fullWidth={true}
                        multiline={true}
                        rows={10}
                        value={analyzedContent}
                      />
                    )
                  }
                ]}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Body>
  )
}

FoxSportsRelevancyEngine.propTypes = {
  content: PropTypes.string
}
export default withStyles(regularFormsStyle)(FoxSportsRelevancyEngine)
