import React, { Component } from "react"
import Paragraph from "components/NarrativeAdmin/Paragraph/Paragraph"
import ParagraphTable from "components/NarrativeAdmin/Data/ParagraphTable"
import Sentence from "components/NarrativeAdmin/Sentence/Sentence"
import Trigger from "components/NarrativeAdmin/Trigger/Trigger"
import Accordion from "components/Accordion/Accordion"

import "react-splitter-layout/lib/index.css"
import withStyles from "@mui/styles/withStyles"
import { globalSettings } from "variables/general.jsx"
import { Cookies } from "tools/storage"
import queryString from "query-string"
import { search_add, search_remove_all } from "tools/querystringHelper"
import { NarrativeContext } from "contexts/narrative-context"
import PropTypes from "prop-types"
import { observer, inject } from "mobx-react"

import "react-cron-builder/dist/bundle.css"
import {
  IconButton,
  AppBar,
  Toolbar,
  Tooltip,
  Modal,
  LinearProgress,
  Box
} from "@mui/material"
import Typography from "@mui/material/Typography"
import { Clear } from "@mui/icons-material"
import Helpers from "tools/Helpers.js"
import TriggerTable from "components/NarrativeAdmin/Data/TriggerTable"
import { get, put } from "tools/request"

const styles = theme => ({
  root: {
    display: "block",
    position: "relative",
    height: "calc(200vh - 133px)",
    "& .highlighted": {
      backgroundColor: "#ffeaa7",
      color: "#e17055",
      boxShadow: "-3px 0 0 #ffeaa7, 3px 0 0 #ffeaa7" // Creates an illusion of a wider span, while not affecting the actual width.
    }
  },
  content: {
    flexGrow: 2,
    background: "#fff"
  },
  iconButton: {
    padding: "6px"
  },
  alertMessageModal: {
    width: "40vw",
    height: "200px",
    margin: "34vh auto"
  },
  progressingMsg: {
    color: "#999",
    textAlign: "center",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    margin: "5px 15px 0 5px"
  },
  finishedMsg: {
    color: "#069",
    textAlign: "center",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    margin: "5px 15px 0 5px"
  },
  failedListMsg: {
    color: "#C00",
    textAlign: "center",
    cursor: "pointer",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    margin: "5px 15px 0 5px"
  },
  failedMsg: {
    color: "#C00",
    textAlign: "center",
    cursor: "pointer",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    margin: "12px 15px 0 5px"
  },
  progressBar: {
    width: "40%",
    background: "#eee",
    display: "flex",
    top: "55px",
    right: "20px",
    position: "fixed",
    zIndex: "99999",
    border: "2px solid #036",
    borderRadius: "15px",
    padding: "4px"
  },
  progressIcon: {
    color: "#000",
    border: "2px solid",
    borderRadius: "20px",
    height: "30px",
    _marginTop: "5px"
  },
  errorModal: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "45%",
    left: "45%",
    transform: "translate(-45%, -45%)"
  }
})

const useStore = component =>
  inject(({ store }) => ({
    organizationId: store.organizationStore.organizationId,
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization,
    setModelTypes: store.narrativeStore.setModelTypes
  }))(observer(component))

class SearchEditor extends Component {
  constructor(props) {
    super(props)
    this.props = props
    let apiOrigin = `${globalSettings.apiBaseUrl}/ap/NBA/`

    this.previewStartDateTime = new Date()
    this.endDateTimeInputRef = React.createRef()

    const queryParams = {
      view:
        this.props && this.props.searchParams && this.props.searchParams.view
    }

    this.state = {
      apiOrigin,
      activeSubTab: "querybuilderDataTab",
      narrative: {
        // eslint-disable-next-line react/prop-types
        id: +props.searchParams.narrativeId
      },
      isRecap: false,
      narrativeLoaded: false,
      paragraphs: [],
      modelType: "",
      modelTypeId: null,
      model: {},
      modelName: null,
      modelUrl: "",
      editNarrative: false,
      narrativePreviewContent: {},
      modelSnapshotTriggers: [],
      loadingGeneral: false,
      modelTypes: [],
      modelSnapshotId: parseInt(queryParams.snapshotid, 10) || null,
      selectedMatchId: parseInt(queryParams.matchid, 10) || null,
      selectedAssetId: parseInt(queryParams.assetid, 10) || null,
      selectedQueryId: parseInt(queryParams.queryid, 10) || null,
      selectedPlayerId: parseInt(queryParams.playerId, 10) || null,
      itemIndex: parseInt(queryParams.itemindex, 10) || null,
      selectedMatchIds: [],
      activeView: queryParams.view || "content",
      multiActiveView: "",
      sentenceSaved: false,
      sentenceTrigger: false,
      organizationId: this.props.organizationId,
      contentBlockElements: null,
      searchString: "",
      replaceString: "",
      searchInName: true,
      searchInTrigger: true,
      searchInTemplate: true,
      searchBlockIds: "",
      toggleElements: true,
      triggerTypes: [],
      playerId: 34543,
      feeds: [],
      archivedItems: [],
      archivedItemsLoaded: false,
      isRenderSchedulerOpen: false,
      fileDataLoaded: false,
      startDateTime: new Date(),
      endDateTime: null,
      duplicateHandling: 0,
      cronSyntax: "0 */1 * * *", // we don't allow a repeat every minute in this cron syntax.  This is use to indicate a "Run Once" schedule
      cronTimeZoneIANA: "America/Los_Angeles",
      isCronBuilderOpen: false,
      isPaused: false,
      doRenderCronSyntaxCheatSheetImage: false,
      isAlertMessageOpen: false,
      isRenderScheduleConfirmationOpen: false,
      alertMessage: "",
      futureOccurrencesMessage: null,
      useRecurrenceSettings: false,
      maxFutureOccurrenceCount: 100,
      publishDelayInSeconds: 0,
      previewElapsedTimeMilliseconds: 0,
      isLoadingPreview: false,
      doShowStartDateTime: false,
      ownerList: [],
      fetchingAccountsByOrgList: false,
      bulkAlertMessage: {},
      bulkAlertMessageActive: false,
      bulkAlertErrorMessageActive: false,
      toggleViewFromExplorer: false,
      toggleViewLast: "",
      isSearching: false,
      searchedBlocksList: [],
      searchedTriggerList: [],
      selectedSentence: null
    }

    this.fetchNarrativeModelTypes = this.fetchNarrativeModelTypes.bind(this)

    this.buildParagraphPreview = this.buildParagraphPreview.bind(this)
    this.groupCollectionBy = this.groupCollectionBy.bind(this)
    this.getContentId = this.getContentId.bind(this)
    this.getContentType = this.getContentType.bind(this)
    this.getActiveView = this.getActiveView.bind(this)
    this.changePage = this.changePage.bind(this)

    this.loadAccountsInOrg = this.loadAccountsInOrg.bind(this)

    this.togglePreviewDrawer = this.togglePreviewDrawer.bind(this)
  }

  componentDidMount() {
    this.props.setShowChangeCurrentOrganization(true)
    let match = this.props && this.props.searchParams
    let cookies = new Cookies()
    if (match) {
      if (match.orgId && !this.state.ownerList.length) {
        this.loadAccountsInOrg(match.orgId)
      }
      this.fetchNarrativeModelTypes()
      this.setState({ initialNarrativeFetch: true })
      this.fetchNarrativeInfo(match.narrativeId)
      cookies.set("lastSearchResultNarrative", match.narrativeId)
    }
  }

  componentDidUpdate() {
    let match = this.props && this.props.searchParams
    let cookies = new Cookies()
    let lastNarrId = cookies.get("lastSearchResultNarrative")

    let currNarrId =
      this.props &&
      this.props.searchParams &&
      this.props.searchParams.narrativeId
    if (match && match.orgId && !this.state.ownerList.length) {
      this.loadAccountsInOrg(match.orgId)
    }
    //If only the narrarive # changes, we need to fetch the narrative info
    if (!!currNarrId && currNarrId !== lastNarrId) {
      this.fetchNarrativeInfo(currNarrId)
      const viewKey = this.props.searchParams.view
      if (viewKey && !this.state.activeView) {
        const activeViewElements = this.getActiveView(
          viewKey,
          this.state.narrative
        )
        this.setState({
          activeView: viewKey,
          contentBlockElements: activeViewElements.contentBlockElements || []
        })
      }
    }
  }

  togglePreviewDrawer = () => {
    const { isPreviewDrawerOpen } = this.state
    this.setState({
      isPreviewDrawerOpen: !isPreviewDrawerOpen
    })
    let cookies = new Cookies()
    cookies.set("isPreviewDrawerOpen", !isPreviewDrawerOpen, {
      path: "/",
      expires: Helpers.CookieExpiration.OneMonth
    })
  }

  clearFilters() {
    if (this.state && this.state.activeView) {
      const viewKey = "content"
      this.setState(
        {
          activeView: viewKey,
          isSearching: false,
          searchString: "",
          replaceString: "",
          searchBlockIds: "",
          searchedBlocksList: [],
          searchedTriggerList: []
        },
        () => this.changePage(viewKey)
      )
    }
  }

  handleNarrativeEdit(narrative) {
    if (this.state) {
      const existingNarrative = this.state.narrative
      const newNarrative = { ...narrative }
      newNarrative.paragraphs = existingNarrative.paragraphs
      newNarrative.outlineBlocks = existingNarrative.outlineBlocks
      newNarrative.triggers = existingNarrative.triggers
      newNarrative.libraryParagraphs = existingNarrative.libraryParagraphs
      this.setState({
        narrative: newNarrative,
        editNarrative: false,
        model: narrative.dataSourceSample || this.state.model
      })
    }
  }

  groupCollectionBy(collection, funcProp) {
    return collection.reduce(function (acc, val) {
      // eslint-disable-next-line
      ;(acc[funcProp(val)] = acc[funcProp(val)] || []).push(val)
      return acc
    }, {})
  }

  reloadData(viewKey) {
    const { narrative } = this.state
    this.fetchNarrativeInfo(narrative.id)
    if (viewKey) {
      this.setState({ activeView: viewKey }, () =>
        this.changePage(viewKey, "reload")
      )
    }
  }

  loadAccountsInOrg(orgId) {
    const { narrative } = this.state
    orgId =
      orgId ||
      (narrative && narrative.organization && narrative.organization.id)
    if (orgId && !this.state.fetchingAccountsByOrgList) {
      this.setState({ fetchingAccountsByOrgList: true })
      get(
        `${globalSettings.apiBaseUrl}/api/account/retrieveaccountsbyorg?orgid=${orgId}`
      )
        .then(Response => Response.json())
        .then(accts => {
          if (accts && accts.content && accts.content.length) {
            let myList = []
            accts.content.forEach(element => {
              if (
                !!element.firstName &&
                !!element.lastName &&
                !!element.username &&
                element.status === "Active"
              ) {
                myList.push({
                  name: `${element.firstName} ${element.lastName}`,
                  username: element.username,
                  id: element.id
                })
              }
            })
            let cookies = new Cookies()
            cookies.set("AccountsForOrg", myList)
            this.setState({
              ownerList: myList,
              fetchingAccountsByOrgList: false
            })
          }
        })
    }
  }
  fetchNarrativeInfo(narrativeId) {
    this.setState({ loading: true })
    get(`${globalSettings.apiBaseUrl}/api/narrative/${narrativeId}`)
      .then(Response => Response && Response.ok === true && Response.json())
      .then(narrative => {
        if (!narrative) {
          return
        }
        if (!narrative.content) {
          this.setState({ loading: false })
          this.setState({ narrativeLoaded: false })
          return
        }

        narrative = narrative.content
        if (this.props.organizationId !== narrative.organization.id) {
          //Passing in the Property as the Org ID causes issues here 24 Feb 2022
        }

        let selectedQueryId = narrative.queryId || this.state.selectedQueryId
        let activeSubTab = this.state.activeSubTab

        if (!this.state.narrativeLoaded) {
          if (narrative.dataSourceSample !== "") {
            activeSubTab = "pastedDataTab"
          }
          if (selectedQueryId && selectedQueryId > 0) {
            activeSubTab = "querybuilderDataTab"
          } else if (narrative.leagueId !== "") {
            activeSubTab = "matchDataTab"
          }
        }

        const activeViewElements = this.getActiveView(
          this.state.activeView,
          narrative
        )
        if (this.state.initialNarrativeFetch) {
          //Wait until the narrative is loaded to call this, was called in onMount before
          this.fetchNarrativeModelTriggerTypes()
        }

        this.setState({
          initialNarrativeFetch: false,
          modelType: narrative.modelType,
          modelTypeId: narrative.ModelTypeId,
          narrative: narrative,
          model: narrative.dataSourceSample,
          loading: false,
          narrativeLoaded: true,
          organizationId: narrative.organization.id,
          selectedQueryId,
          modelName:
            selectedQueryId && `Query Builder Query #${selectedQueryId}`,
          activeSubTab,
          contentBlockElements: activeViewElements.contentBlockElements
        })
      })
  }

  updateParagraphs(paragraph) {
    if (!this.state.narrative || !this.state.narrative.paragraphs) {
      return
    }
    const narrative = this.state.narrative
    const paragraphs = narrative.paragraphs
    const paragraphIdx = paragraphs.findIndex(a => a.id === paragraph.id)
    if (!paragraph.sentences) {
      paragraph.sentences = []
    } else {
      // Don't add unsaved sentences
      paragraph.sentences = paragraph.sentences.filter(s => s.id)
    }
    // update paragraphs in state
    if (paragraphIdx !== -1) {
      narrative.paragraphs[paragraphIdx] = paragraph
    } else {
      narrative.paragraphs = [...paragraphs, paragraph]
    }
    this.setState(narrative)
  }

  fetchNarrativeModelTypes() {
    this.setState({ loadingNarrativeModels: true })
    get(`${globalSettings.apiBaseUrl}/api/narrative/modeltypes`)
      .then(Response => Response && Response.ok === true && Response.json())
      .then(response => {
        const modelTypes = response.content
        this.setState({
          loadingNarrativeModels: false,
          modelTypes
        })
        this.props.setModelTypes(modelTypes)
      })
  }

  changePage(activeView, myaction) {
    if (activeView) {
      let tmp = activeView.split("-")
      if (tmp && tmp[1]) {
        activeView = tmp[1]
      }
      this.props.subSearch(`${activeView}`, this.state.narrative, myaction)
      return
    }
    const searchParams = queryString.parse(this.props.location.search)
    let newSearch = ""
    const oldView = searchParams.view

    const sentenceOrTrigger = !!activeView.match(/sentenceid|triggerid/)
    if (sentenceOrTrigger) {
      if (oldView && !!oldView.match(/sentenceid|triggerid/)) {
        if (this.state.triggerSaved || this.state.sentenceSaved) {
          newSearch = `?view=${encodeURIComponent(this.state.multiActiveView)}`
          if (!!searchParams.itemindex || searchParams.itemindex === "0") {
            newSearch = `${newSearch}&itemindex=${searchParams.itemindex}`
          }
          this.setState({
            sentenceSaved: false,
            triggerSaved: false
          })
        } else {
          if (searchParams && searchParams.itemindex) {
            newSearch = `?view=${encodeURIComponent(activeView)}&itemindex=${
              searchParams.itemindex
            }`
          } else {
            newSearch = `?view=${encodeURIComponent(activeView)}`
          }
        }
        this.props.history.push({ search: newSearch })
      } else if (oldView && !oldView.match(/sentenceid|triggerid/)) {
        this.props.history.push({
          search: `?view=${activeView}`
        })
      } else {
        newSearch = search_remove_all("view")
        newSearch = search_add("view", activeView)
        this.props.history.push({
          search: newSearch
        })
      }
    } else {
      if (oldView) {
        newSearch = search_remove_all("view")
        this.props.history.push({ search: newSearch })
      }
      if (activeView !== "content" && activeView !== "element-content") {
        newSearch = search_add("view", activeView)
        this.props.history.push({
          search: newSearch
        })
      }
    }

    const activeViewElements = this.getActiveView(
      activeView,
      this.state.narrative
    )

    this.setState({
      contentBlockElements: activeViewElements.contentBlockElements || []
    })
  }

  getContentType() {
    const {
      modelSnapshotId,
      selectedMatchId,
      selectedAssetId,
      selectedQueryId,
      selectedPlayerId,
      selectedMatchIds,
      isRecap
    } = this.state
    if (selectedPlayerId && selectedPlayerId !== 0) {
      return "fantasyfootballplayer"
    }
    if (modelSnapshotId && modelSnapshotId !== 0) {
      return "snapshot"
    }
    if (
      (selectedMatchId && selectedMatchId !== 0) ||
      (selectedMatchIds && selectedMatchIds.length > 0)
    ) {
      return isRecap ? "match_recap" : "match_preview"
    }
    if (selectedAssetId && selectedAssetId !== 0) {
      return "asset"
    }
    if (selectedQueryId && selectedQueryId !== 0) {
      return "query"
    }
    return ""
  }

  getContentId() {
    const {
      modelSnapshotId,
      selectedMatchId,
      selectedAssetId,
      selectedQueryId,
      selectedPlayerId
    } = this.state
    return (
      selectedPlayerId ||
      modelSnapshotId ||
      selectedMatchId ||
      selectedAssetId ||
      selectedQueryId
    )
  }

  buildParagraphPreview(paragraph, debug = false) {
    this.setState({
      isLoadingPreview: true
    })
    const contentId = this.getContentId()
    const contentType = this.getContentType()
    const { model, modelTypeId, modelType, narrative } = this.state

    const queryParams = queryString.parse(this.props.location.search)

    const body = {
      paragraph,
      contentId,
      contentType: contentId ? contentType : "",
      dataModelText: contentId ? "" : model,
      modelType,
      modelTypeId,
      narrativeId: narrative.id,
      debug,
      itemIndex: parseInt(queryParams.itemindex, 10)
    }
    put(
      `${globalSettings.apiBaseUrl}/api/narrativepreview/paragraph-preview`,
      null,
      body
    )
      .then(Response => Response && Response.ok === true && Response.json())
      .then(feed => {
        let narrativePreviewContent = feed.content
        if (narrativePreviewContent) {
          this.setState({
            narrativePreviewContent,
            isLoadingPreview: false
          })
        }
      })
  }

  handleCategoryChange(categories) {
    this.setState(prevState => ({
      checkedCategories: categories,
      triggerTypes: prevState.triggerTypesBk.filter(tt =>
        categories.includes(tt.category)
      )
    }))
  }

  getTriggerTypeCategories(triggerTypes) {
    let triggerTypeList = []
    if (triggerTypes && triggerTypes.length > 0) {
      triggerTypes.forEach(tt => {
        if (tt.category && tt.category !== "") {
          triggerTypeList.push(tt.category)
        }
      })
    }
    return Object.keys(this.groupCollectionBy(triggerTypeList.sort(), t => t))
  }

  fetchNarrativeModelTriggerTypes() {
    this.setState({ triggersLoaded: true })
    const { modelType, narrative } = this.state
    const modelTypeShortName =
      modelType && modelType.includes(".")
        ? modelType.split(".").pop()
        : modelType
    let modelCategory = modelTypeShortName || ""

    if (modelType === "QueryBuilder") {
      modelCategory = `QueryBuilder${narrative.queryId}`
    }

    get(`${globalSettings.apiBaseUrl}/api/trigger`)
      .then(Response => Response.json())
      .then(triggerTypes => {
        const categories = this.getTriggerTypeCategories(triggerTypes)
        let checkedCategories = categories
        if (modelCategory) {
          checkedCategories = categories.filter(category =>
            category.includes(modelCategory)
          )
        }

        this.setState({
          triggerTypesBk: triggerTypes,
          triggerTypes: triggerTypes.filter(tt =>
            checkedCategories.includes(tt.category)
          ),
          triggerCategories: categories,
          checkedCategories
        })
      })
  }

  processBulkMessage(msg) {
    let saving = {}

    let saved = {}

    let failed = {}

    let savedLength = 0

    let savingLength = 0

    let failedLength = 0

    let tmpBulkState = this.state.bulkAlertMessage

    let failedArray = []
    Object.keys(msg).forEach(function (key) {
      if (!isNaN(key)) {
        tmpBulkState[key] = tmpBulkState[key] ? tmpBulkState[key] : {}
        tmpBulkState[key][msg[key]] = msg.type ? msg.type : true
      }
    })
    Object.keys(tmpBulkState).forEach(function (key) {
      if (!isNaN(key)) {
        if (tmpBulkState[key].saving) {
          saving[key] = true
          savingLength += 1
        }
        if (tmpBulkState[key].saved) {
          saved[key] = true
          savedLength += 1
        }
        if (tmpBulkState[key].failed) {
          failed[key] = tmpBulkState[key].failed
          failedLength += 1
          failedArray.push(
            JSON.parse(
              `{"id" :"${key}", "error":"${tmpBulkState[key].failed}"}`
            )
          )
        }
      }
    })
    tmpBulkState.failed = failedArray
    tmpBulkState.progress = parseInt((savedLength / savingLength) * 100)

    tmpBulkState.totals = {
      saved: savedLength,
      saving: savingLength,
      failed: failedLength,
      finished: savingLength - (savedLength + failedLength) === 0
    }
    this.setState({
      bulkAlertMessage: tmpBulkState,
      bulkAlertMessageActive: true
    })
  }

  filterParagraphs(paragraphs, triggerType) {
    let filteredParagraphs = []
    let contentBlocks = []
    if (paragraphs && paragraphs.length > 0) {
      paragraphs.forEach(paragraph => {
        let p = paragraph
        if (p.triggerType === triggerType) {
          filteredParagraphs.push(p)
        } else {
          let filteredSentences = p.sentences.filter(
            s => s.triggerType === triggerType
          )
          if (filteredSentences && filteredSentences.length > 0) {
            p.sentences = filteredSentences
            filteredParagraphs.push(p)
          }
        }
      })
    }
    contentBlocks = this.groupCollectionBy(
      filteredParagraphs,
      p => p.contentBlock && p.contentBlock.toLowerCase()
    )
    return contentBlocks
  }

  createBlockElements(contentBlocks, toolbarTitle) {
    const { classes } = this.props
    const { narrative } = this.state
    const { libraryParagraphs, paragraphs, outlineBlocks } = narrative
    if (Object.keys(contentBlocks).length < 1) {
      return "No content found"
    }
    const accordionItems =
      contentBlocks &&
      Object.keys(contentBlocks).map(key =>
        // key: the name of the object key
        // index: the ordinal position of the key within the object
        ({
          key: key,
          title: `   <${contentBlocks[key][0].contentBlock} />`,
          content: contentBlocks[key].map(paragraph => (
            <Paragraph
              key={`${paragraph.id}`}
              editParagraph={false}
              paragraph={paragraph}
              handleReload={viewKey => this.reloadData(viewKey)}
              paragraphs={paragraphs}
              updateNarrativeParagraphs={paragraph =>
                this.updateParagraphs(paragraph)
              }
              outlineBlocks={outlineBlocks}
              libraryParagraphs={libraryParagraphs}
              ownerListForOrg={this.state.ownerList}
              toggleViewFromExplorer={this.state.toggleViewFromExplorer}
              searchParams={this.props.searchParams}
              hitExitParagraph={() => {
                this.props.cancelComponent()
              }}
            />
          ))
        })
      )

    return (
      <div>
        {toolbarTitle && toolbarTitle !== "" && (
          <AppBar position="sticky" color="default" enableColorOnDark>
            <Toolbar className={classes.toolbar}>
              <Typography className={classes.title} variant="subtitle2" noWrap>
                {toolbarTitle}
              </Typography>
            </Toolbar>
          </AppBar>
        )}
        <Accordion collapses={accordionItems} />
      </div>
    )
  }

  getBlockSummary(blocks) {
    const blockCount = Object.keys(blocks).length
    let paragraphCount = 0
    let sentenceCount = 0
    Object.keys(blocks).forEach(blockGroup => {
      blocks[blockGroup].forEach(p => {
        paragraphCount += 1
        if (p.sentences && p.sentences.length > 0) {
          sentenceCount += p.sentences.length
        }
      })
    })
    return { blockCount, paragraphCount, sentenceCount }
  }

  getActiveView(activeView, narrative) {
    let activeViews
    if (typeof activeView === "string") {
      activeViews = activeView.split(",")
    } else {
      activeViews = activeView
    }

    let multiActiveView = ""
    activeViews.forEach(view => {
      if (view.match(/sentenceid|triggerid/)) {
        multiActiveView += (multiActiveView ? "," : "") + view
      }
    })
    this.setState({ multiActiveView: multiActiveView })

    const { classes } = this.props
    const { searchInTrigger, searchInName, searchInTemplate, searchBlockIds } =
      this.state
    const blockIds = searchBlockIds ? searchBlockIds.split(",") : []

    const { paragraphs, triggers, libraryParagraphs, outlineBlocks } = narrative
    const paragraphsCopy = paragraphs && JSON.parse(JSON.stringify(paragraphs))
    const libraryBlocks =
      libraryParagraphs &&
      this.groupCollectionBy(
        libraryParagraphs,
        p => p.contentBlock && p.contentBlock.toLowerCase()
      )
    const contentBlocks =
      paragraphsCopy &&
      this.groupCollectionBy(
        paragraphsCopy,
        p => p.contentBlock && p.contentBlock.toLowerCase()
      )
    let contentBlockElements = []
    for (let i = 0; i < activeViews.length; i++) {
      let view = ""
      try {
        view = decodeURIComponent(activeViews[i])
      } catch (error) {
        view = activeViews[i]
      }
      const viewKeyArray = view.includes("-") ? view.split("-") : [view]
      const itemType = viewKeyArray[0]
      const itemId = viewKeyArray.length > 1 ? viewKeyArray[1] : view
      const blockId = viewKeyArray.length > 2 ? viewKeyArray[2] : itemId
      const outlineBlock =
        (blockId &&
          outlineBlocks &&
          outlineBlocks.find(ob => ob.id === blockId)) ||
        {}

      const newModelTrigger = {
        narrative_Id: narrative.id,
        narrativeModelType: "",
        key: "",
        name: "",
        description: `${narrative.name} News Trigger`,
        rank: 0,
        formula: "",
        option: "",
        isNews: false,
        isActive: true,
        status_Id: 3 // published
      }

      let toolbarTitle = ""
      const paragraphBlocks = []
      const libraryParagraphBlocks = []
      const sentenceBlocks = []
      const librarySentenceBlocks = []
      const newInsightTrigger = {
        ...newModelTrigger,
        isNews: false,
        isDependency: false,
        description: `${narrative.name} Insight Trigger`
      }
      const dependencies =
        (triggers &&
          triggers.filter(
            t =>
              t.isDependency !== null &&
              (t.isDependency === true || t.isDependency === "false")
          )) ||
        []
      const insightTriggers =
        (
          triggers &&
          triggers.filter(
            t =>
              (t.isNews === null ||
                t.isNews === false ||
                t.isNews === "false") &&
              (t.isDependency === null ||
                t.isDependency === false ||
                t.isDependency === "false")
          )
        ).sort((a, b) => b.id - a.id) || []
      const newDependencyTrigger = {
        ...newModelTrigger,
        isNews: false,
        isDependency: true,
        description: `${narrative.name} Dependency`
      }
      const newsTriggers =
        (
          triggers &&
          triggers.filter(
            t => t.isNews !== null && (t.isNews === true || t.isNews === "true")
          )
        ).sort((a, b) => a.rank - b.rank || b.id - a.id) || []
      const filteredTriggers =
        triggers &&
        triggers.filter(
          t =>
            (searchInTemplate && t.formula?.includes(itemId)) ||
            (searchInName && t.name?.includes(itemId)) ||
            (searchInTrigger && t.type?.includes(itemId))
        )

      this.props.hasLoaded()
      switch (itemType) {
        case "paragraphid":
          toolbarTitle = `Paragraph ${itemId}`
          contentBlocks &&
            Object.keys(contentBlocks).forEach((key, index) => {
              // Loop through the content blocks and load the paragraph we've fitered on
              contentBlocks[key].forEach((paragraph, key) => {
                if (paragraph.id === Number.parseInt(itemId)) {
                  toolbarTitle = `${paragraph.contentBlock} Element`
                  paragraphBlocks.push(
                    <div key={`pId-${paragraph.id}`}>
                      <AppBar
                        position="sticky"
                        color="default"
                        enableColorOnDark
                      >
                        <Toolbar className={classes.toolbar}>
                          <Typography
                            className={classes.title}
                            variant="subtitle2"
                            noWrap
                          >
                            "{narrative.organization.name}"{" - "}
                            Narrative{" "}
                            <a
                              href={`/portal/narrative/${narrative.id}/edit`}
                              style={{
                                textDecoration: "underline"
                              }}
                              onClick={e => {
                                e.preventDefault()
                              }}
                              title={`Go to Narrative "${narrative.name}" (${narrative.id})`}
                            >
                              {`${narrative.id}`}
                            </a>
                            {" > "}
                            {`${paragraph.contentBlock} Element > `}
                            <a
                              href={`/portal/narrative/${narrative.id}/edit?view=paragraphid-${paragraph.id}`}
                              style={{
                                textDecoration: "underline"
                              }}
                              onClick={e => {
                                e.preventDefault()
                              }}
                              title={`Go to Paragraph ${paragraph.id}`}
                            >
                              {`Paragraph ${paragraph.id}`}
                            </a>
                          </Typography>
                        </Toolbar>
                      </AppBar>
                      <Paragraph
                        triggers={triggers}
                        key={`${paragraph.id}-${key}-${index}-${paragraph.sentences.length}`}
                        updateNarrativeParagraphs={paragraph =>
                          this.updateParagraphs(paragraph)
                        }
                        showSentences={true}
                        paragraph={paragraph}
                        paragraphs={paragraphs}
                        outlineBlocks={outlineBlocks}
                        libraryParagraphs={libraryParagraphs}
                        handleReload={viewKey => this.reloadData(viewKey)}
                        ownerListForOrg={this.state.ownerList}
                        toggleViewFromExplorer={
                          this.state.toggleViewFromExplorer
                        }
                        isPreviewDrawerOpen={this.state.isPreviewDrawerOpen}
                        togglePreviewDrawer={this.togglePreviewDrawer}
                        searchEditorMode={true}
                        savedParagraph={(pId, content) => {
                          this.setState({
                            paragraphSaved: true,
                            paragraphId: pId
                          })
                          this.props.savedEntry("paragraph", pId, content)
                        }}
                        searchParams={this.props.searchParams}
                        hitExitParagraph={() => {
                          this.props.cancelComponent()
                        }}
                      />
                    </div>
                  )
                }
              })
            })
          contentBlockElements.push(paragraphBlocks)
          break
        case "library_paragraphid":
          toolbarTitle = `Library Paragraph ${itemId}`
          libraryBlocks &&
            Object.keys(libraryBlocks).forEach((key, index) => {
              // Loop through the content blocks and load the paragraph we've fitered on
              libraryBlocks[key].forEach((paragraph, key) => {
                if (paragraph.id === Number.parseInt(itemId)) {
                  toolbarTitle = `${paragraph.contentBlock} Element`
                  libraryParagraphBlocks.push(
                    <div key={`lpId-${paragraph.id}`}>
                      <AppBar
                        position="sticky"
                        color="default"
                        enableColorOnDark
                      >
                        <Toolbar className={classes.toolbar}>
                          <Typography
                            className={classes.title}
                            variant="subtitle2"
                            noWrap
                          >
                            {toolbarTitle}
                          </Typography>
                        </Toolbar>
                      </AppBar>
                      <Paragraph
                        triggers={triggers}
                        key={`${paragraph.id}-${key}-${index}-${paragraph.sentences.length}`}
                        showSentences={true}
                        paragraph={paragraph}
                        handleReload={viewKey => this.reloadData(viewKey)}
                        ownerListForOrg={this.state.ownerList}
                        toggleViewFromExplorer={
                          this.state.toggleViewFromExplorer
                        }
                        searchParams={this.props.searchParams}
                        hitExitParagraph={() => {
                          this.props.cancelComponent()
                        }}
                      />
                    </div>
                  )
                }
              })
            })
          contentBlockElements.push(libraryParagraphBlocks)
          break
        case "sentenceid":
          toolbarTitle = `Sentence ${itemId}`
          // Loop through the contentblocks and find the sentence
          // 5 Jan 2021 - 2 issues here:
          // #1 - The "key" parameter we used for the "sentenceid", "library_paragraphid", and "paragraphid" cases
          // were not unique enough and caused a problem becauese of #2
          // #2 - Turns out for "placeholder sentences", you often have sentence ids that are duplicated in different paragraphs,
          // which then means the loop below will select multiple sentences to put into "sentenceBlocks".
          // Before today, the loop through "contentBlocks" was a MAP which meant we returned each entry which when you had multiple matches
          // resulted in weird behavior - it would elimate one of the 2 previous matches but add the 2 new matches at the bottom
          // which meant your list would get longer AND you had to navigate down to get what you clicked.
          // Doing a FOREACH and then pushing the matching sentences into "sentenceBlocks" was a better solution
          contentBlocks &&
            Object.keys(contentBlocks).forEach(key => {
              contentBlocks[key].forEach(paragraph => {
                for (let index in paragraph.sentences) {
                  const sentence = paragraph.sentences[index]
                  if (sentence.id === Number.parseInt(itemId)) {
                    //Set this sentence to the selected sentence
                    this.setState({
                      selectedSentence: sentence
                    })
                    sentenceBlocks.push(
                      <div key={`sId-${paragraph.id}-${itemId}`}>
                        <AppBar
                          position="sticky"
                          color="default"
                          enableColorOnDark
                        >
                          <Toolbar className={classes.toolbar}>
                            <Typography
                              className={classes.title}
                              variant="subtitle2"
                              noWrap
                            >
                              "{narrative.organization.name}"{" - "}
                              Narrative{" "}
                              <a
                                href={`/portal/narrative/${narrative.id}/edit`}
                                style={{
                                  textDecoration: "underline"
                                }}
                                onClick={e => {
                                  e.preventDefault()
                                }}
                                title={`Go to Narrative "${narrative.name}" (${narrative.id})`}
                              >
                                {`${narrative.id}`}
                              </a>
                              {" > "}
                              {`${paragraph.contentBlock} Element > `}
                              <a
                                href={`/portal/narrative/${narrative.id}/edit?view=paragraphid-${paragraph.id}`}
                                style={{
                                  textDecoration: "underline"
                                }}
                                onClick={e => {
                                  e.preventDefault()
                                }}
                                title={`Go to Paragraph ${paragraph.id}`}
                              >
                                {`Paragraph ${paragraph.id}`}
                              </a>
                              {" > "}
                              <a
                                href={`/portal/narrative/${narrative.id}/edit?view=sentenceid-${itemId}`}
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "underline"
                                }}
                                onClick={e => {
                                  e.preventDefault()
                                }}
                                title={`Go to Sentence ${itemId}`}
                              >
                                {`Sentence ${itemId}`}
                              </a>
                            </Typography>
                          </Toolbar>
                        </AppBar>
                        <Sentence
                          triggers={triggers}
                          key={`sentenceid-${itemId}`}
                          createMode={false}
                          edit={true}
                          contentBlock={paragraph.contentBlock}
                          toggleEdit={() => {
                            const viewKey = `sentenceid-${itemId}`
                            this.setState(
                              {
                                activeView: multiActiveView
                              },
                              () => {
                                this.fetchNarrativeInfo(narrative.id)
                                this.changePage(viewKey, "sentence")
                              }
                            )
                          }}
                          savedSentence={(sId, content) => {
                            this.setState({
                              sentenceSaved: true,
                              sentenceId: sId
                            })
                            this.props.savedEntry("sentence", sId, content)
                          }}
                          sentence={sentence}
                          libraryParagraphs={libraryParagraphs}
                          ownerListForOrg={this.state.ownerList}
                          toggleViewFromExplorer={
                            this.state.toggleViewFromExplorer
                          }
                          searchParams={this.props.searchParams}
                          searchEditorMode={true}
                          hitExitSentence={() => {
                            this.props.cancelComponent()
                          }}
                        />
                      </div>
                    )
                  }
                }
              })
            })
          contentBlockElements.push(sentenceBlocks)
          break
        case "library_sentenceid":
          toolbarTitle = `Sentence ${itemId}`
          // Loop through the contentblocks and find the sentence
          libraryBlocks &&
            Object.keys(libraryBlocks).forEach(key => {
              libraryBlocks[key].forEach(paragraph => {
                for (let index in paragraph.sentences) {
                  const sentence = paragraph.sentences[index]
                  if (sentence.id === Number.parseInt(itemId)) {
                    toolbarTitle = `${paragraph.contentBlock} Element > Paragraph ${paragraph.id} > Sentence ${itemId}`
                    librarySentenceBlocks.push(
                      <div key={`lsId:${paragraph.id}-${itemId}`}>
                        <AppBar
                          position="sticky"
                          color="default"
                          enableColorOnDark
                        >
                          <Toolbar className={classes.toolbar}>
                            <Typography
                              className={classes.title}
                              variant="subtitle2"
                              noWrap
                            >
                              {toolbarTitle}
                            </Typography>
                          </Toolbar>
                        </AppBar>
                        <Sentence
                          triggers={triggers}
                          key={`sentenceid-${itemId}`}
                          createMode={false}
                          edit={true}
                          toggleEdit={() => {
                            const viewKey = `library_sentenceid-${itemId}`
                            this.setState({ activeView: viewKey }, () => {
                              this.fetchNarrativeInfo(narrative.id)
                              this.changePage(viewKey, "sentence")
                            })
                          }}
                          savedSentence={(sId, content) => {
                            this.setState({
                              sentenceSaved: true,
                              sentenceId: sId
                            })
                            this.props.savedEntry("sentence", sId, content)
                          }}
                          sentence={sentence}
                          toggleViewFromExplorer={
                            this.state.toggleViewFromExplorer
                          }
                          libraryParagraphs={libraryParagraphs}
                          ownerListForOrg={this.state.ownerList} // To update list of available user accounts
                          searchEditorMode={true}
                          hitExitSentence={() => {
                            this.props.cancelComponent()
                          }}
                        />
                      </div>
                    )
                  }
                }
              })
            })
          contentBlockElements.push(librarySentenceBlocks)
          break
        case "insighttriggers":
          toolbarTitle = "Insight Triggers"

          // Load the contentblock we're filtering on and output all its paragraphs
          contentBlockElements = (
            <TriggerTable
              title={toolbarTitle}
              narrative={narrative}
              triggers={insightTriggers}
              bulkAlertMessage={val => {
                this.processBulkMessage(val)
              }}
              isAlertActive={() => {
                this.setState({ bulkAlertMessageActive: true })
              }}
            />
          )
          break
        case "dependencies":
          toolbarTitle = "Dependencies"
          // Load the contentblock we're filtering on and output all its paragraphs
          contentBlockElements = (
            <TriggerTable
              title={toolbarTitle}
              narrative={narrative}
              triggers={dependencies}
              bulkAlertMessage={val => {
                this.processBulkMessage(val)
              }}
              isAlertActive={() => {
                this.setState({ bulkAlertMessageActive: true })
              }}
            />
          )
          break
        case "createinsight":
          toolbarTitle = `New Trigger`

          contentBlockElements = (
            <Trigger
              edit={true}
              trigger={newInsightTrigger}
              searchEditorMode={true}
              handleCancel={() => {
                const viewKey = "insighttriggers"
                this.setState({ activeView: multiActiveView }, () => {
                  this.fetchNarrativeInfo(narrative.id)
                  this.changePage(viewKey, "trigger")
                })
              }}
              handleReload={viewKey => this.reloadData(viewKey)}
              toggleViewFromExplorer={this.state.toggleViewFromExplorer}
              searchParams={this.props.searchParams}
              savedTrigger={(tId, content) => {
                this.setState({
                  triggerSaved: true,
                  triggerId: tId
                })
                this.props.savedEntry("trigger", tId, content)
              }}
              hitExitTrigger={() => {
                this.props.cancelComponent()
              }}
            />
          )
          break
        case "createdependency":
          toolbarTitle = `New Dependency`

          contentBlockElements = (
            <Trigger
              edit={true}
              trigger={newDependencyTrigger}
              searchEditorMode={true}
              handleCancel={() => {
                const viewKey = "dependencies"
                this.setState({ activeView: viewKey }, () => {
                  this.fetchNarrativeInfo(narrative.id)
                  this.changePage(viewKey, "trigger")
                })
              }}
              handleReload={viewKey => this.reloadData(viewKey)}
              toggleViewFromExplorer={this.state.toggleViewFromExplorer}
              searchParams={this.props.searchParams}
              savedTrigger={(tId, content) => {
                this.setState({
                  triggerSaved: true,
                  triggerId: tId
                })
                this.props.savedEntry("trigger", tId, content)
              }}
              hitExitTrigger={() => {
                this.props.cancelComponent()
              }}
            />
          )
          break
        case "triggerid":
        case "newstriggerid": {
          const trigger =
            triggers && triggers.find(t => t.id === Number.parseInt(itemId))

          if (trigger) {
            let viewKey = "insighttriggers"
            if (trigger.isDependency) {
              toolbarTitle = `Dependency ${itemId}`
              viewKey = "dependencytriggers"
            } else if (trigger.isNews) {
              toolbarTitle = `News Trigger ${itemId}`
              viewKey = "newstriggers"
            } else {
              toolbarTitle = `Trigger ${itemId}`
            }

            const triggerElement = trigger && (
              <div>
                <AppBar position="sticky" color="default" enableColorOnDark>
                  <Toolbar className={classes.toolbar}>
                    <Typography
                      className={classes.title}
                      variant="subtitle2"
                      noWrap
                    >
                      "{narrative.organization.name}"{" - "}
                      Narrative{" "}
                      <a
                        href={`/portal/narrative/${narrative.id}/edit`}
                        style={{
                          textDecoration: "underline"
                        }}
                        onClick={e => {
                          e.preventDefault()
                        }}
                        title={`Go to Narrative "${narrative.name}" (${narrative.id})`}
                      >
                        {`${narrative.id}`}
                      </a>
                      {" > "}
                      {`${viewKey} > `}
                      <a
                        href={`/portal/narrative/${narrative.id}/edit?view=triggerid-${itemId}`}
                        style={{
                          textDecoration: "underline"
                        }}
                        onClick={e => {
                          e.preventDefault()
                        }}
                        title={`Go to Trigger ${itemId}`}
                      >
                        {`Trigger ${itemId}`}
                      </a>
                    </Typography>
                  </Toolbar>
                </AppBar>
                <Trigger
                  edit={true}
                  handleCancel={() => {
                    this.setState({ activeView: multiActiveView }, () => {
                      this.fetchNarrativeInfo(narrative.id)
                      this.changePage(viewKey, "trigger")
                    })
                  }}
                  searchEditorMode={true}
                  ownerList={this.state.ownerList}
                  trigger={trigger}
                  handleReload={() =>
                    this.reloadData(this.state.multiActiveView)
                  }
                  toggleViewFromExplorer={this.state.toggleViewFromExplorer}
                  searchParams={this.props.searchParams}
                  savedTrigger={(tId, content) => {
                    this.setState({
                      triggerSaved: true,
                      triggerId: tId
                    })
                    this.props.savedEntry("trigger", tId, content)
                  }}
                  hitExitTrigger={() => {
                    this.props.cancelComponent()
                  }}
                />
              </div>
            )

            contentBlockElements.push(
              <React.Fragment key={`${trigger.id}-${trigger.type}`}>
                {triggerElement}
              </React.Fragment>
            )
          } else {
            contentBlockElements.push(
              <React.Fragment key={`trigger-${itemId}`}>
                Trigger {itemId} was not found in this narrative
              </React.Fragment>
            )
          }

          break
        }
        case "createnewstrigger":
          newModelTrigger.isNews = true
          toolbarTitle = `New News Trigger`
          contentBlockElements = (
            <Trigger
              trigger={newModelTrigger}
              edit={true}
              searchEditorMode={true}
              handleCancel={() => {
                const viewKey = "newstriggers"
                this.setState({ activeView: viewKey }, () => {
                  this.fetchNarrativeInfo(narrative.id)
                  this.changePage(viewKey, "trigger")
                })
              }}
              searchParams={this.props.searchParams}
              savedTrigger={(tId, content) => {
                this.setState({
                  triggerSaved: true,
                  triggerId: tId
                })
                this.props.savedEntry("trigger", tId, content)
              }}
              handleReload={viewKey => this.reloadData(viewKey)}
              toggleViewFromExplorer={this.state.toggleViewFromExplorer}
              hitExitTrigger={() => {
                this.props.cancelComponent()
              }}
            />
          )
          break
        case "newstriggers":
          toolbarTitle = `News Triggers`
          // Load the contentblock we're filtering on and output all its paragraphs

          contentBlockElements = (
            <TriggerTable
              title={toolbarTitle}
              narrative={narrative}
              triggers={newsTriggers}
              bulkAlertMessage={val => {
                this.processBulkMessage(val)
              }}
              isAlertActive={() => {
                this.setState({ bulkAlertMessageActive: true })
              }}
            />
          )
          break
        case "search":
          toolbarTitle = `Search ${itemId}`
          if (contentBlocks) {
            Object.keys(contentBlocks).forEach((key, index) =>
              contentBlocks[key].forEach((paragraph, key) => {
                let inName

                let inTrigger = false
                let retParagraph = paragraph
                if (
                  blockIds.length > 0 &&
                  !blockIds.some(b => b.trim() === paragraph.id.toString())
                ) {
                  return
                }
                let retSentences = []
                if (
                  searchInName &&
                  paragraph.name &&
                  paragraph.name.includes(itemId)
                ) {
                  inName = true
                }
                if (
                  searchInTrigger &&
                  paragraph.triggerType &&
                  paragraph.triggerType.includes(itemId)
                ) {
                  inTrigger = true
                }
                for (let sentenceIndex in paragraph.sentences) {
                  const sentence = paragraph.sentences[sentenceIndex]
                  if (
                    searchInTemplate &&
                    sentence.template &&
                    sentence.template.includes(itemId)
                  ) {
                    retSentences.push(sentence)
                  }
                  if (
                    searchInName &&
                    sentence.name &&
                    sentence.name.includes(itemId)
                  ) {
                    inName = true
                  }
                  if (
                    searchInTrigger &&
                    sentence.triggerType &&
                    sentence.triggerType.includes(itemId)
                  ) {
                    inTrigger = true
                  }
                }
                if (retSentences.length > 0 || inName || inTrigger) {
                  retParagraph.sentences = retSentences
                  contentBlockElements.push(
                    <Paragraph
                      triggers={triggers}
                      key={`${paragraph.id}-${key}-${index}-${paragraph.sentences.length}`}
                      updateNarrativeParagraphs={paragraph =>
                        this.updateParagraphs(paragraph)
                      }
                      libraryParagraphs={libraryParagraphs}
                      paragraphs={paragraphs}
                      paragraph={retParagraph}
                      outlineBlocks={outlineBlocks}
                      handleReload={viewKey => this.reloadData(viewKey)}
                      searchString={this.state.searchString}
                      ownerListForOrg={this.state.ownerList}
                      toggleViewFromExplorer={this.state.toggleViewFromExplorer}
                      searchParams={this.props.searchParams}
                      hitExitParagraph={() => {
                        this.props.cancelComponent()
                      }}
                    />
                  )
                  let sl = this.state.searchedBlocksList

                  let exists = false
                  sl.forEach(itm => {
                    if (itm.id === retParagraph.id) {
                      exists = true
                    }
                  })
                  if (!exists) {
                    sl.push(retParagraph)
                    this.setState({ searchedBlocksList: sl })
                  }
                }
              })
            )
          }
          break
        case "searchtriggers":
          toolbarTitle = `Search Triggers for ${itemId}`
          contentBlockElements = filteredTriggers.map((trigger, key) => (
            <Trigger
              key={`${trigger.id}-${key}`}
              trigger={trigger}
              searchEditorMode={true}
              handleEdit={() => {
                const viewKey = `${trigger.isNews ? "news" : ""}triggerid-${
                  trigger.id
                }`
                this.setState({ activeView: viewKey }, () => {
                  this.changePage(viewKey, "trigger")
                })
              }}
              handleCancel={() => {
                const viewKey = `searchtriggers-${itemId}`
                this.setState({ activeView: viewKey }, () => {
                  this.changePage(viewKey)
                })
              }}
              handleReload={viewKey => this.reloadData(viewKey)}
              model={this.state.model}
              toggleViewFromExplorer={this.state.toggleViewFromExplorer}
              searchParams={this.props.searchParams}
              savedTrigger={(tId, content) => {
                this.setState({
                  triggerSaved: true,
                  triggerId: tId
                })
                this.props.savedEntry("trigger", tId, content)
              }}
              hitExitTrigger={() => {
                this.props.cancelComponent()
              }}
            />
          ))
          this.setState({ searchedTriggerList: filteredTriggers })
          break
        case "outlineBlockId": {
          toolbarTitle = `Outline Block ${itemId}`
          // Load the contentblock we're filtering on and output all its paragraphs
          contentBlockElements = (
            <ParagraphTable
              title={toolbarTitle}
              narrative={narrative}
              paragraphs={
                paragraphsCopy &&
                paragraphs.filter(p => p.narrativeOutlineBlockId === itemId)
              }
              outlineBlocks={outlineBlocks}
              outlineBlockId={itemId}
              defaults={{
                ...outlineBlock,
                narrativeOutlineBlockId: itemId,
                name: outlineBlock.contentSection
              }}
              updateNarrativeParagraphs={paragraph =>
                this.updateParagraphs(paragraph)
              }
              bulkAlertMessage={val => {
                this.processBulkMessage(val)
              }}
              isAlertActive={() => {
                this.setState({ bulkAlertMessageActive: true })
              }}
              linkId
            />
          )
          break
        }
        default:
          break
      }
    }
    return { contentBlockElements }
  }

  render() {
    const {
      isRecap,
      loading,
      model,
      modelSnapshotTriggers,
      modelType,
      modelTypeId,
      modelUrl,
      narrative,
      searchString,
      triggerTypesBk,
      triggerTypes,
      triggerCategories,
      checkedCategories,
      contentBlockElements,
      itemIndex
    } = this.state
    const { classes } = this.props
    const contentId = this.getContentId()
    const contentType = this.getContentType()
    // Build the urls to our token list and model previews

    if (!this.state.narrative.id) {
      return "Assembling..."
    }

    let narrativeTitleStyling = {}
    narrativeTitleStyling = Helpers.buildCustomStyleForNarrativeStatus(
      this.state.narrative.narrativeStatusId
    )
    if (this.state.narrative.isArchived) {
      narrativeTitleStyling.textDecoration = "line-through"
    }

    return (
      <div className={classes.root} style={{ backgroundColor: "#efefef" }}>
        <main>
          <div style={{ border: "1px solid #d4d4d4" }}>
            {(this.state.loading || this.state.loadingGeneral) && (
              <LinearProgress />
            )}
            <div className={classes.content} key="content">
              <NarrativeContext.Provider
                value={{
                  contentId,
                  contentType,
                  itemIndex,
                  isRecap,
                  loading,
                  model,
                  modelType,
                  modelTypeId,
                  modelUrl,
                  narrative,
                  searchString,
                  triggers: modelSnapshotTriggers,
                  triggerTypesBk,
                  triggerTypes,
                  triggerCategories,
                  checkedCategories,
                  buildParagraphPreview: (paragraph, debug) => {
                    this.buildParagraphPreview(paragraph, debug)
                  },
                  setLoadingGeneral: isLoading => {
                    this.setState({ loadingGeneral: isLoading })
                  },
                  setActiveView: viewKey => {
                    this.setState({ activeView: viewKey }, () =>
                      this.changePage(viewKey, "provider")
                    )
                  },
                  setActiveTriggerCategories: categories => {
                    this.handleCategoryChange(categories)
                  }
                }}
              >
                {contentBlockElements}
              </NarrativeContext.Provider>
              {this.state.isSearching &&
                this.state.searchedBlocksList.length === 0 &&
                this.state.searchedTriggerList.length === 0 && (
                  <div
                    style={{
                      margin: "20px",
                      padding: "0 0 0 40px",
                      display: "flex"
                    }}
                  >
                    <h4>
                      No search results found for "{this.state.searchString}"
                    </h4>
                    <Tooltip
                      id="tooltip-top-clear"
                      title="Clear"
                      placement="top"
                    >
                      <IconButton
                        className={classes.iconButton}
                        aria-label="Clear"
                        onClick={() => this.clearFilters()}
                        size="large"
                      >
                        <Clear />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
            </div>
          </div>
        </main>

        {this.state.bulkAlertMessageActive && (
          <div id="myProgressMarrEditor" className={classes.progressBar}>
            {this.state.bulkAlertMessage.progress === 100 && (
              <div className={classes.finishedMsg}>Actions completed!</div>
            )}
            {this.state.bulkAlertMessage.progress !== 100 &&
              this.state.bulkAlertMessage.totals.finished && (
                <span
                  className={classes.failedListMsg}
                  onClick={() => {
                    this.setState({ bulkAlertErrorMessageActive: true })
                  }}
                >
                  Completed with errors
                </span>
              )}
            {this.state.bulkAlertMessage.progress !== 100 &&
              !this.state.bulkAlertMessage.totals.finished && (
                <div className={classes.progressingMsg}>
                  Actions in progress...
                  <br />
                  {this.state.bulkAlertMessage.totals.saved} of{" "}
                  {this.state.bulkAlertMessage.totals.saving}
                </div>
              )}
            <Box display="flex" alignItems="center" width="100%">
              <Box width="100%" mr={1}>
                <LinearProgress
                  variant="determinate"
                  value={this.state.bulkAlertMessage.progress || 0}
                />
              </Box>
              <Box minWidth={35}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ fontWeight: "bold" }}
                >
                  {this.state.bulkAlertMessage.progress}%
                </Typography>
              </Box>
            </Box>
            {this.state.bulkAlertMessage.totals.failed > 0 &&
              !this.state.bulkAlertMessage.totals.finished && (
                <div className={classes.failedMsg}>
                  {this.state.bulkAlertMessage.totals.failed} failed action
                  {this.state.bulkAlertMessage.totals.failed > 1 ? "s" : ""}
                </div>
              )}
          </div>
        )}
        {this.state.bulkAlertMessage.progress !== 100 &&
          this.state.bulkAlertMessage.totals &&
          this.state.bulkAlertMessage.totals.finished && (
            <Modal
              open={this.state.bulkAlertMessage.totals.finished}
              onClose={() => {
                this.setState({
                  bulkAlertMessage: {},
                  bulkAlertMessageActive: false
                })
              }}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div className={classes.errorModal}>
                <h3 id="server-modal-title">Here are the failed requests</h3>
                <div id="server-modal-description">
                  <ul>
                    {this.state.bulkAlertMessage.failed &&
                      this.state.bulkAlertMessage.failed.map((n, index) => (
                        <li key={index}>
                          ID #{n.id} --- {n.error} error
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </Modal>
          )}
        {this.state.bulkAlertMessage.progress === 100 && (
          <Modal
            open={true}
            onClose={() => {
              this.setState({
                bulkAlertMessage: {},
                bulkAlertMessageActive: false
              })
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className={classes.errorModal}>
              <h2 id="server-modal-title">Success!</h2>
              <div id="server-modal-description">
                All {this.state.bulkAlertMessage.totals.saving} actions were
                executed correctly
              </div>
            </div>
          </Modal>
        )}
      </div>
    )
  }
}

SearchEditor.propTypes = {
  subSearch: PropTypes.func,
  savedEntry: PropTypes.func,
  cancelComponent: PropTypes.func,
  hasLoaded: PropTypes.func
}
export default withStyles(styles)(useStore(SearchEditor))
