// ##############################
// // // RegularForms view styles
// #############################

import {
  cardTitle,
  successColor,
  dangerColor
} from "assets/jss/material-dashboard-pro-react"
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch"

const regularFormsStyle = {
  ...customCheckboxRadioSwitch,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  staticFormGroup: {
    marginLeft: "0",
    marginRight: "0",
    paddingBottom: "10px",
    margin: "8px 0 0 0",
    position: "relative",
    "&:before,&:after": {
      display: "table",
      content: '" "'
    },
    "&:after": {
      clear: "both"
    }
  },
  staticFormControl: {
    marginBottom: "0",
    paddingTop: "8px",
    paddingBottom: "8px",
    minHeight: "34px"
  },
  inputAdornment: {
    marginRight: "8px",
    position: "relative"
  },
  inputAdornmentIconSuccess: {
    color: `${successColor}!important`
  },
  inputAdornmentIconError: {
    color: `${dangerColor}!important`
  },
  embedded: {
    padding: 0,
    boxShadow: "none",
    margin: 0
  },
  resizeTextarea: {
    "& textarea": {
      resize: "vertical!important"
    }
  },
  flexDirectionCacheManager: {
    "@media (max-width: 767px)": {
      flexDirection: "column"
    }
  },
  tabScrollRoot: {
    "@media (max-width: 700px)": {
      "& .MuiTabs-scrollButtonsDesktop": {
        display: "flex !important"
      },
      "& .MuiTab-wrapper": {
        fontSize: "12px"
      }
    }
  },
  acountNotificationsStandard: {
    "@media (max-width: 600px)": {
      "& div.standard": {
        display: "none"
      },
      "& div.smartphone table thead th": {
        fontSize: "1em !important",
        fontWeight: "bold !important"
      },
      "& div.smartphone table tbody td.MuiTableCell-body": {
        border: "1px solid #eee !important",
        padding: "10px 2px !important"
      }
    },
    "@media (min-width: 600px)": {
      "& div.smartphone": {
        display: "none"
      }
    }
  },
  rwsGridPadding: {
    "@media (max-width: 600px)": {
      padding: "0px !important"
    }
  },
  disabledScriptOutput: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.87)"
    }
  },
  flexButtonList: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "flex-start"
  }
}

export default regularFormsStyle
