import React from "react"
import PropTypes from "prop-types"
import withStyles from "@mui/styles/withStyles"
import {
  Grid,
  TextField,
  DialogActions,
  Modal,
  Checkbox,
  FormControlLabel
} from "@mui/material"
import Button from "components/CustomButtons/Button"
import GridItem from "components/Grid/GridItem"
import orgDAO from "daos/organizationDAO"

const styles = theme => ({
  successModal: {
    position: "absolute",
    color: "#069",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    transform: "translate(-45%, -45%)",
    "& h3": {
      fontWeight: "bold"
    },
    "& ul": {
      padding: "5px 0 0 5px",
      fontWeight: "bold"
    },
    [theme.breakpoints.up(700)]: {
      width: 400,
      top: "45%",
      left: "45%"
    },
    [theme.breakpoints.down(700)]: {
      width: "70vw",
      top: "50vh",
      left: "50vw"
    }
  },
  descriptionWidth: {
    [theme.breakpoints.down(700)]: {
      maxWidth: "inherit"
    },
    [theme.breakpoints.up(700)]: {
      maxWidth: "60% !important"
    }
  },
  errorModal: {
    position: "absolute",
    color: "#c00",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    transform: "translate(-45%, -45%)",
    "& h3": {
      fontWeight: "bold"
    },
    "& ul": {
      padding: "5px 0 0 5px",
      fontWeight: "bold",
      textDecoration: "underline"
    },
    [theme.breakpoints.up(700)]: {
      width: 400,
      top: "45%",
      left: "45%"
    },
    [theme.breakpoints.down(700)]: {
      width: "70vw",
      top: "50vh",
      left: "50vw"
    }
  }
})

function ContractMilestone(props) {
  const {
    createdByAccountId,
    contractId,
    editMilestone,
    classes,
    handleCloseForm,
    handleCreateMilestone
  } = props

  const [saveFailed, setSaveFailed] = React.useState(false)
  const [saveSuccess, setSaveSuccess] = React.useState(false)
  const [isSaving, setIsSaving] = React.useState(false)
  const [saveMessage, setSaveMessage] = React.useState("")

  const [myFormValues, setMyFormValues] = React.useState(
    editMilestone || { inContract: true }
  )

  const {
    milestoneDate,
    description,
    organizationId,
    isArchived,
    name,
    inContract
  } = myFormValues

  const handleSave = () => {
    //Save the milestones to the back end
    setIsSaving(true)
    const myPayload = {
      milestoneDate,
      contractId,
      description,
      organizationId,
      isArchived,
      name,
      createdByAccountId,
      inContract
    }
    let myaction = "create"
    if (editMilestone) {
      myPayload.id = editMilestone.id
      myaction = "update"
    }

    orgDAO
      .postMilestone(myPayload, myaction)
      .then(({ content = {}, responseMessage = "" }) => {
        const { id = null } = content
        setSaveMessage(responseMessage)

        if (id !== null) {
          const newMilestone = {
            id,
            name,
            contractId,
            description,
            isArchived,
            milestoneDate,
            inContract
          }
          handleCreateMilestone(newMilestone)
          setSaveSuccess(true)
        } else {
          setSaveFailed(true)
        }
      })
      .catch(err => {
        setSaveFailed(true)
        console.error("Error on PUT to contractmilestones API", err)
      })
      .finally(() => {
        setIsSaving(false)
      })
  }

  const handleMilestoneChange = name => e => {
    const newMilestones = {
      ...myFormValues,
      [name]: e.target.value
    }
    setMyFormValues(newMilestones)
  }

  const handleCheckboxChange = name => e => {
    const newMilestones = {
      ...myFormValues,
      [name]: e.target.checked
    }
    setMyFormValues(newMilestones)
  }

  return (
    <div style={{ _minWidth: "800px" }}>
      <React.Fragment>
        <Grid container>
          <GridItem xs={12} sm={4}>
            <TextField
              type="text"
              id="name"
              label="Name of new Milestone"
              fullWidth
              value={name}
              onChange={handleMilestoneChange("name")}
              margin="normal"
            />
          </GridItem>
          <GridItem xs={12} sm={3}>
            <TextField
              onChange={handleMilestoneChange("milestoneDate")}
              id="milestoneDate"
              label="Milestone Date"
              type="date"
              format={"DD/MM/YYYY"}
              value={milestoneDate}
              defaultValue={milestoneDate}
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
              style={{
                minWidth: "185px"
              }}
              inputProps={{
                min: "2019-01-01"
              }}
            />
          </GridItem>
          <GridItem xs={12} md={2}>
            <FormControlLabel
              style={{ padding: "15px" }}
              control={
                <Checkbox
                  checked={inContract}
                  onChange={handleCheckboxChange("inContract")}
                />
              }
              label="In Contract?"
            />
          </GridItem>
          <GridItem xs={12} md={2}>
            <FormControlLabel
              style={{ padding: "15px" }}
              control={
                <Checkbox
                  checked={isArchived}
                  onChange={handleCheckboxChange("isArchived")}
                />
              }
              label="Is Archived?"
            />
          </GridItem>
        </Grid>
        <Grid container>
          <GridItem xs={12} className={classes.descriptionWidth}>
            <TextField
              type="text"
              fullWidth
              multiline
              minRows={5}
              maxRows={10}
              label="Description"
              id="description"
              value={description}
              onChange={handleMilestoneChange("description")}
              margin="normal"
            />
          </GridItem>
        </Grid>
        <Grid container>
          <GridItem xs={12} sm={3}>
            <DialogActions style={{ marginTop: "14px" }}>
              <Button onClick={handleCloseForm} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <DialogActions style={{ marginTop: "14px" }}>
              <Button
                onClick={handleSave}
                color="primary"
                disabled={isSaving || !contractId}
              >
                Save
              </Button>
            </DialogActions>
          </GridItem>
        </Grid>
      </React.Fragment>
      <div>
        <Modal
          open={saveSuccess}
          onClose={() => {
            setSaveSuccess(false)
            handleCloseForm()
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.successModal}>
            <h2 id="server-modal-title">Success!</h2>
            <div id="server-modal-description">
              <p>{saveMessage}</p>
            </div>
          </div>
        </Modal>
        <Modal
          open={saveFailed}
          onClose={() => {
            setSaveFailed(false)
            handleCloseForm()
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.errorModal}>
            <h2 id="server-modal-title">Failed!</h2>
            <div id="server-modal-description">
              <p>{saveMessage}</p>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  )
}

ContractMilestone.propTypes = {
  contractId: PropTypes.number,
  editMilestone: PropTypes.object,
  hasChildren: PropTypes.array,
  handleCloseForm: PropTypes.func,
  handleCreateMilestone: PropTypes.func,
  createdByAccountId: PropTypes.number,
  classes: PropTypes.object
}
export default withStyles(styles)(ContractMilestone)
