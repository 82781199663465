import React from "react"
import PropTypes from "prop-types"
import { Box } from "@mui/material"

import ElementPreviewEntry from "./ElementPreviewEntry"
import {
  sectionsToIncludeWordCount,
  sectionsToRenderAsHtml,
  sectionsWithSpecialDisplayIntructions
} from "../Data/contentSections"

const ElementPreviewEntries = ({
  activeElements,
  renderedNarrative,
  renderTimeInMilliseconds
}) => (
  <Box width="100%">
    {activeElements.map(({ renderedProperty, name, id }, index) => (
      <Box key={id}>
        {index !== 0 && <hr />}
        <ElementPreviewEntry
          title={name}
          data={renderedNarrative[renderedProperty]}
          renderAsHtml={sectionsToRenderAsHtml.includes(id)}
          includeWordCounter={sectionsToIncludeWordCount.includes(id)}
          specialInstructions={sectionsWithSpecialDisplayIntructions}
          renderedProperty={renderedProperty}
          renderTimeInMilliseconds={
            renderedProperty === "content"
              ? renderTimeInMilliseconds
              : undefined
          }
        />
      </Box>
    ))}
  </Box>
)

ElementPreviewEntries.propTypes = {
  activeElements: PropTypes.arrayOf(PropTypes.object),
  renderedNarrative: PropTypes.object,
  renderTimeInMilliseconds: PropTypes.number
}

export default ElementPreviewEntries
