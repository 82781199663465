import React from "react"
import ReactTable from "react-table-6"
import CircularProgress from "@mui/material/CircularProgress"
import TimeAgo from "react-timeago"
import PropTypes from "prop-types"
import { Tooltip, FormControlLabel, Button, Snackbar } from "@mui/material"
import { RotateLeft } from "@mui/icons-material"
import Helpers from "tools/Helpers"
import { groupCollectionBy } from "tools/CollectionHelper"
import DiffViewer from "components/DiffViewer/DiffViewer"

export default function ChangeLog(props) {
  const {
    changeLogs,
    isChangeLogsLoading,
    totalChangeLogRecords,
    handleRollBack
  } = props

  const [changeLogsData, setData] = React.useState(changeLogs)
  const [alertMessage, setAlertMessage] = React.useState("")
  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false)
  const [pageSize, setPageSize] = React.useState(5)
  const [changeLogTableExpandRows, setChangeLogTableExpandRows] =
    React.useState([])

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setData(changeLogs)
  }, [changeLogs])

  // We need to keep the table from resetting the pageIndex when we
  // Update changeLogsData. So we can keep track of that flag with a ref.
  const skipResetRef = React.useRef(false)

  // After changeLogsData changes, we turn the flag back off
  // so that if changeLogsData actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => {
    skipResetRef.current = false
  }, [changeLogsData])

  function handleSnackBarClose() {
    setIsSnackBarOpen(!isSnackBarOpen)
    setAlertMessage("")
  }

  // Handle rollback process for internal and external handlers
  function rollbackChange(row) {
    const { columnName, oldValue } = row._original
    handleRollBack({ [columnName]: oldValue })
    setIsSnackBarOpen(true)
    setAlertMessage(
      `The ${columnName} was rolled back to a previous version but not saved.`
    )
  }

  // This stuff should be moved into a helper file
  // This is used by all tables and is pasted everywhere
  function getItemValueFromPath(item, key) {
    if (!key || key === "") {
      return null
    }
    let value = void 0
    if (key && key.indexOf(".") !== -1) {
      // eslint-disable-line no-negated-condition
      // handle nested keys
      value = key.split(".").reduce(function (itemObj, nestedKey) {
        return itemObj[nestedKey]
      }, item)
    } else {
      value = item[key]
    }
    // concat because `value` can be a string or an array
    return value ? [].concat(value) : null
  }

  function stringFilterProps(propName, properties) {
    return {
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true
        }
        // NOTE: string versus integer comparison,
        // don't use === operator
        // eslint-disable-next-line eqeqeq
        return row[propName] == filter.value
      },
      Filter: ({ filter, onChange }) => {
        if (!properties || properties.size === 0) {
          return
        }

        const distinctOptions = Object.keys(
          groupCollectionBy(properties, p => getItemValueFromPath(p, propName))
        ).sort((a, b) => {
          // Use toUpperCase() to ignore character casing
          const orgA = a.toUpperCase()
          const orgB = b.toUpperCase()
          let comparison = 0
          if (orgA > orgB) {
            comparison = 1
          } else if (orgA < orgB) {
            comparison = -1
          }
          return comparison
        })
        // build up the <select> element <option> element JSX style
        let options = []
        distinctOptions.forEach(opt => {
          options.push(
            <option value={opt} key={opt}>
              {opt}
            </option>
          )
        })
        return (
          <select
            onChange={event => {
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">All</option>
            {options}
          </select>
        )
      }
    }
  }

  function dateFilterProps(propName) {
    return {
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true
        }
        let now = new Date()
        let futureTime = new Date()
        let pastTime = new Date()
        let columnDateTime = new Date(getItemValueFromPath(row, propName))
        if (filter.value === "future") {
          return columnDateTime >= now
        }
        if (filter.value === "past") {
          return columnDateTime < now
        }
        if (filter.value === "next7Days") {
          return (
            columnDateTime > now &&
            columnDateTime < futureTime.setHours(futureTime.getHours() + 7 * 24)
          )
        }
        if (filter.value === "next24hours") {
          return (
            columnDateTime > now &&
            columnDateTime < futureTime.setHours(futureTime.getHours() + 48)
          )
        }
        if (filter.value === "next1hour") {
          return (
            columnDateTime > now &&
            columnDateTime < futureTime.setHours(futureTime.getHours() + 2)
          )
        }
        if (filter.value === "within7Days") {
          return (
            columnDateTime > pastTime.setHours(pastTime.getHours() - 7 * 24) &&
            columnDateTime < futureTime.setHours(futureTime.getHours() + 7 * 24)
          )
        }
        if (filter.value === "within24hours") {
          return (
            columnDateTime > pastTime.setHours(pastTime.getHours() - 24) &&
            columnDateTime < futureTime.setHours(futureTime.getHours() + 48)
          )
        }
        if (filter.value === "within1hour") {
          return (
            columnDateTime > pastTime.setHours(pastTime.getHours() - 1) &&
            columnDateTime < futureTime.setHours(futureTime.getHours() + 2)
          )
        }
        if (filter.value === "past7Days") {
          return (
            columnDateTime > pastTime.setHours(pastTime.getHours() - 7 * 24) &&
            columnDateTime < now
          )
        }
        if (filter.value === "past24hours") {
          return (
            columnDateTime > pastTime.setHours(pastTime.getHours() - 24) &&
            columnDateTime < now
          )
        }
        if (filter.value === "past1hour") {
          return (
            columnDateTime > pastTime.setHours(pastTime.getHours() - 1) &&
            columnDateTime < now
          )
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value="future">Future</option>
          <option value="past">Past</option>
          <option value="next7Days">Next 7 Days</option>
          <option value="next24hours">Next 24 Hours</option>
          <option value="next1hour">Next 1 Hour</option>
          <option value="within7Days">Within 7 Days</option>
          <option value="within24hours">Within 24 Hours</option>
          <option value="within1hour">Within 1 Hour</option>
          <option value="past7Days">Past 7 Days</option>
          <option value="past24hours">Past 24 Hours</option>
          <option value="past1hour">Past 1 Hour</option>
        </select>
      ),
      Cell: cell =>
        cell.value &&
        cell.value !== "" && (
          <div>
            <TimeAgo
              date={cell.value}
              title={Helpers.prettyDateTimeinPacificTimeZone(cell.value)}
            />
          </div>
        )
    }
  }

  function convertOperationLetterToName(operationLetter) {
    let operationName = "N/A"
    switch (operationLetter) {
      case "U":
        operationName = "Updated"
        break
      case "C":
        operationName = "Created"
        break
      case "D":
        operationName = "Deleted"
        break
    }
    return operationName
  }

  function rememberPageSizeChanges(pageSize) {
    setPageSize(pageSize)
  }

  return (
    <div style={{ padding: "10px" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={isSnackBarOpen}
        onClose={handleSnackBarClose}
        key="ChangeLogSnack"
        message={alertMessage}
      />
      <ReactTable
        data={changeLogsData}
        expanded={changeLogTableExpandRows}
        onExpandedChange={expanded => {
          setChangeLogTableExpandRows(expanded)
        }}
        defaultSorted={[
          {
            id: "operationDateTime",
            desc: true
          }
        ]}
        noDataText={
          isChangeLogsLoading ? <CircularProgress /> : "No change log(s) found."
        }
        filterable
        columns={[
          {
            Header: "Actions",
            width: 80,
            sortable: false,
            Cell: ({ row, isExpanded }) =>
              // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
              // to build the toggle for expanding a row
              isExpanded && handleRollBack ? (
                <FormControlLabel
                  title="Rollback to this version"
                  control={
                    <Button
                      color="info"
                      className="info"
                      onClick={() => {
                        rollbackChange(row)
                      }}
                    >
                      <RotateLeft />
                    </Button>
                  }
                  label="Roll back to this version"
                />
              ) : (
                ""
              )
          },
          {
            Header: "Field",
            accessor: "columnName",
            ...stringFilterProps("columnName", changeLogsData),
            Cell: cell => <strong>{cell.value}</strong>
          },
          {
            Header: "Operation Type",
            accessor: "operation",
            ...stringFilterProps("operation", changeLogsData),
            Cell: cell => <div>{convertOperationLetterToName(cell.value)}</div>
          },
          {
            Header: "Last Modified",
            accessor: "operationDateTime",
            ...dateFilterProps("operationDateTime", changeLogsData),
            Cell: cell => (
              <span>
                <TimeAgo
                  date={cell.value}
                  title={Helpers.prettyDateTimeinPacificTimeZone(cell.value)}
                />{" "}
                by {cell.original.auditInfo.creator.username}
              </span>
            )
          },
          {
            Header: "Updated By",
            accessor: "auditInfo.creator.username",
            ...stringFilterProps("auditInfo.creator.username", changeLogsData),
            Cell: cell => (
              <Tooltip
                title={`(Account Id=${cell.original.auditInfo.creator.id})`}
                placement="top"
              >
                <span>{cell.value}</span>
              </Tooltip>
            )
          }
        ]}
        SubComponent={row => <DiffViewer row={row} />}
        defaultPageSize={25}
        pageSize={isChangeLogsLoading ? 5 : pageSize}
        onPageSizeChange={pageSize => {
          rememberPageSizeChanges(pageSize)
        }}
        showPaginationTop
        showPaginationBottom={true}
        className="-striped -highlight"
      >
        {(state, makeTable) => {
          const { filtered, pageRows, pageSize, sortedData, page } = state
          let isFiltered = false
          let recordsCountFrom = 0
          let recordsCountTo = 0
          let totalRecords = 0
          isFiltered = filtered.length > 0
          totalRecords = sortedData.length
          recordsCountFrom = page * pageSize + 1
          recordsCountTo = recordsCountFrom + pageRows.length - 1
          return (
            <div className="main-grid">
              <div className="above-table text-right">
                <div className="col-sm-12">
                  <span className="records-info">
                    {totalRecords > 0 && isFiltered && (
                      <span>
                        {recordsCountFrom}-{recordsCountTo} of {totalRecords}{" "}
                        filtered out of {totalChangeLogRecords} total changes
                      </span>
                    )}
                    {totalRecords > 0 && !isFiltered && (
                      <span>
                        {recordsCountFrom}-{recordsCountTo} of{" "}
                        {totalChangeLogRecords} total changes
                      </span>
                    )}
                    {totalRecords === 0 &&
                      (isChangeLogsLoading
                        ? "Loading feed entry changes, please wait..."
                        : "No changes found.")}
                  </span>
                </div>
              </div>
              {makeTable()}
            </div>
          )
        }}
      </ReactTable>
    </div>
  )
}

ChangeLog.propTypes = {
  narrative: PropTypes.object,
  loading: PropTypes.bool,
  setActiveView: PropTypes.func,
  exportSentence: PropTypes.func,
  exportParagraph: PropTypes.func,
  exportTrigger: PropTypes.func,
  activeView: PropTypes.string
}
