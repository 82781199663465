import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import clsx from "clsx"
import PropTypes from "prop-types"
import ReactJson from "react-json-view"
import queryString from "query-string"

// MUI Components
import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import LinearProgress from "@mui/material/LinearProgress"
import Snackbar from "@mui/material/Snackbar"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import TextField from "@mui/material/TextField"
import Tooltip from "@mui/material/Tooltip"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { styled } from "@mui/styles"
import MUIAccordion from "@mui/material/Accordion"

import withStyles from "@mui/styles/withStyles"

// Custom Components
import GridItem from "components/Grid/GridItem.jsx"
import { TabPanel } from "components/TabPanel/TabPanel"
import Button from "components/CustomButtons/Button.jsx"
import Accordion from "components/Accordion/Accordion"
import StatusType from "../Types/StatusType"
import OwnerType from "../Types/OwnerType"
import CodeEditor from "components/CodeEditor"

// Custom Data
import { Cookies } from "tools/storage"
import { globalSettings } from "variables/general.jsx"
import { NarrativeContext } from "contexts/narrative-context"
import ChangeLog from "../Data/ChangeLog"
import CodeBlock from "../Data/CodeBlock"
import HighlightSpan from "../Data/HighlightSpan"
import { AccordionSummary } from "@mui/material"
import GridContainer from "components/Grid/GridContainer"
import { get, put } from "tools/request"

const TRIGGER_REGEX = "[a-z0-9_]"

const styles = theme => ({
  triggerHeader: {
    margin: "0 15px",
    padding: "10px 0!important",
    display: "flex"
  },
  codeEditorArea: {
    width: "100%",
    display: "flex",
    minHeight: "250px"
  },
  codeSummary: {
    display: "flex",
    minHeight: "auto"
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    padding: "0px",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    }),
    height: "100%",
    alignSelf: "center"
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  codePreview: {
    margin: "0 10px",
    padding: "20px!important",
    background: "#000",
    color: "#fff",
    cursor: "pointer",
    fontFamily: "monospace",
    width: "100%",
    fontSize: "12px",
    "& .highlighted": {
      backgroundColor: "#ffeaa7",
      color: "#e17055",
      boxShadow: "-3px 0 0 #ffeaa7, 3px 0 0 #ffeaa7" // Creates an illusion of a wider span, while not affecting the actual width.
    }
  },
  acOption: {
    '&[aria-selected="true"]': {
      backgroundColor: "#bdbdbd"
    }
  },
  selected: {
    "&.MuiListItem-root.Mui-selected": {
      backgroundColor: "#bdbdbd"
    }
  },
  gridStyleTriggers: {
    display: "flex"
  }
})

const FormHeader = styled(Box)({
  alignItems: "center",
  fontWeight: "bold",
  display: "flex",
  backgroundColor: "#efefef",
  padding: "0 15px",
  minHeight: "48px"
})

class Trigger extends Component {
  constructor(props) {
    super(props)
    this.state = {
      trigger: props.trigger ? { ...props.trigger } : { id: props.triggerId },
      currentView: 0,
      preview: "",
      allTokens: [],
      loading: false,
      showLineNumbers: true,
      triggerDetails: true,
      changeLogs: [],
      totalChangeLogRecords: 0,
      isChangeLogsLoading: false,
      isChangeLogsLoaded: false,
      isTriggerLoading: false,
      isTriggerLoaded: false,
      isSnackbarOpen: false,
      alertMessage: `Warning: Trigger ${props.triggerId} has unsaved changes.`,
      excludeDependencies: false,
      formIsDirty: false
    }
    this.buildPreview = this.buildPreview.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleFieldChange = this.handleFieldChange.bind(this)
    this.loadTriggerDetails = this.loadTriggerDetails.bind(this)
    this.loadHistory = this.loadHistory.bind(this)
    this.fetchChangeLog = this.fetchChangeLog.bind(this)
    this.fetchDetails = this.fetchDetails.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCodeEditorChange = this.handleCodeEditorChange.bind(this)
    this.handleRollBack = this.handleRollBack.bind(this)
  }

  componentDidMount() {
    if (!this.props.trigger && this.props.triggerId) {
      this.loadTriggerDetails()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    //Workaround to allow us to pass info from 2 children of NarrativeEditor to each other - NarrativeExplorer and here
    //Reason this code is needed is the NarrativeExplorer (child) can send info to the NarrativeEditor (parent) via a prop function
    //But when I change that state of a prop in the NarrativeEditor (parent) to tell this component (child) what has changed,
    //this component senses the change in prop values and updates, BUT it does not receive the current prop values
    //So as a workaround, I save that info in the NarrativeEditor in LocalStorage and then read it here and clear it out
    let cookies = new Cookies()
    let val = cookies.get("setEditFromExplorer")
    if (val && typeof val.toggleViewFromExplorer === "boolean") {
      if (val.toggleViewFromExplorer !== this.state.triggerDetails) {
        this.setState({ triggerDetails: val.toggleViewFromExplorer })
      }
    }
    cookies.set("setEditFromExplorer", {})

    if (prevProps.triggerId !== this.props.triggerId) {
      this.loadTriggerDetails()
    }

    if (prevState.trigger !== this.state.trigger) {
      this.setState({ isSnackbarOpen: true })
    }
  }

  static contextType = NarrativeContext // Placed here to full react/sort-comp rule - NarrativeContext is technically a custom method

  // Derive from props instead of setting state
  get currentNarrativeId() {
    return (
      +this.props.match.params.id ||
      (this.props.searchParams && Number(this.props.searchParams.narrativeId))
    )
  }

  get canSaveTriggerToBackend() {
    return this.currentNarrativeId === this.state.trigger?.narrative_Id
  }

  handleRollBack(newValue) {
    const { trigger } = this.state
    this.setState({
      ...this.state,
      ...newValue,
      trigger: {
        ...trigger,
        ...newValue
      }
    })
  }

  loadTriggerDetails() {
    if (
      !this.state.isTriggerLoading &&
      !this.state.isTriggerLoaded &&
      this.props.triggerId > 0
    ) {
      this.fetchDetails()
    }
  }

  loadHistory() {
    if (!this.state.isHistoryVisible) {
      this.setState({ isHistoryVisible: true })
      if (!this.state.isChangeLogsLoaded) {
        this.fetchChangeLog()
      }
    } else {
      this.setState({ isHistoryVisible: false })
    }
  }

  fetchDetails() {
    const { triggerId } = this.props
    this.setState({ isTriggerLoading: true })
    get(`${globalSettings.apiBaseUrl}/api/trigger/${triggerId}`)
      .then(Response => Response && Response.ok === true && Response.json())
      .then(content => {
        if (content) {
          this.setState({
            trigger: content,
            isTriggerLoading: false,
            isTriggerLoaded: true
          })
        }
      })
  }

  fetchChangeLog() {
    const { triggerId } = this.props
    this.setState({ isChangeLogsLoading: true })
    get(`${globalSettings.apiBaseUrl}/api/trigger/${triggerId}/history`)
      .then(Response => Response && Response.ok === true && Response.json())
      .then(content => {
        if (!content) {
          return
        }
        this.setState({
          changeLogs: content,
          totalChangeLogRecords: content.length,
          isChangeLogsLoading: false,
          isChangeLogsLoaded: true
        })
      })
  }

  async putUpdatedTrigger(updatedTrigger) {
    if (updatedTrigger) {
      const response = await put(
        `${globalSettings.apiBaseUrl}/api/trigger`,
        null,
        {
          ...updatedTrigger
        }
      )

      if (response?.ok === true) {
        return await response.json()
      }
    }
    return null
  }

  handleChange() {
    const { isNews } = this.state.trigger
    const triggerView = isNews ? "newstriggers" : "insighttriggers"
    if (this.props?.handleReload) {
      this.props.handleReload(triggerView)
    }
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      currentView: newValue
    })
  }

  handleCancel() {
    if (this.props && this.props.handleCancel) {
      this.props.handleCancel()
    }
    if (this.props.hitExitTrigger) {
      //In Search Editor, close on cancel
      this.props.hitExitTrigger()
    }
  }

  handleSubmit = async () => {
    const { modelType, narrative } = this.context
    const { trigger } = this.state
    const {
      description,
      formula,
      id,
      isActive,
      isArchived,
      isNews,
      isDependency,
      createReplica,
      name,
      narrative_Id,
      option,
      rank,
      status_Id,
      type,
      comments,
      categories,
      tokens,
      createdBy_Account_Id,
      createdDateTime,
      modifiedBy_Account_Id,
      owner_Account_Id
    } = trigger

    let modelTypeShortName =
      modelType && modelType.includes(".")
        ? modelType.split(".").pop()
        : modelType

    if (modelType === "QueryBuilder") {
      modelTypeShortName = `QueryBuilder${narrative.queryId}`
    }

    this.setState({ loading: true })

    const updatedTrigger = {
      formula,
      id,
      key: type,
      name,
      description,
      narrative_Id,
      isNews,
      isDependency,
      createReplica,
      isActive,
      isArchived,
      narrativeModelType: modelTypeShortName || "",
      option,
      comments,
      rank: rank && parseInt(rank),
      status_Id,
      categories,
      tokensJson: tokens && JSON.stringify(tokens),
      createdBy_Account_Id: createdBy_Account_Id || 1,
      modifiedBy_Account_Id: modifiedBy_Account_Id || 1,
      modifiedDateTime: new Date().toLocaleDateString("en-US"),
      createdDateTime:
        createdDateTime || new Date().toLocaleDateString("en-US"),
      owner_Account_Id
    }

    const responseData = await this.putUpdatedTrigger(updatedTrigger)

    if (responseData) {
      const { responseMessage } = responseData
      const { triggerId } = this.props
      const trigger = {
        ...updatedTrigger,
        id: triggerId
      }
      if (triggerId !== null && triggerId !== 0) {
        this.props.savedTrigger && this.props.savedTrigger(triggerId, formula)
        this.handleChange()
        this.setState({
          loading: false,
          isSnackbarOpen: false
        })
      } else {
        this.setState({
          loading: false,
          isSnackbarOpen: false,
          trigger,
          formIsDirty: false,
          preview: responseMessage
        })
      }
    }
  }

  buildPreview() {
    const { trigger, excludeDependencies } = this.state
    const { formula: templateString, isActive } = trigger
    const { model, modelType, modelTypeId, contentId, contentType } =
      this.context

    if (!templateString) {
      return
    }
    const queryParams = queryString.parse(this.props.location.search)

    this.setState({ loading: true })

    put(
      `${globalSettings.apiBaseUrl}/api/narrativepreview/evaluate-trigger`,
      null,
      {
        templateString,
        modelType,
        modelTypeId,
        narrativeId: this.currentNarrativeId,
        contentId: contentId,
        contentType: contentType,
        dataModelText: !contentId ? model : "",
        itemIndex: parseInt(queryParams.itemindex, 10),
        useCache: false,
        excludeDependencies,
        triggerId: trigger.id,
        skipTriggers: true
      }
    )
      .then(Response => Response && Response.ok === true && Response.json())
      .then(evaluatedTrigger => {
        let newTrigger = {
          ...trigger,
          tokens: evaluatedTrigger.tokens || trigger.tokens
        }
        this.setState({
          preview: `${!isActive ? "DEPENDENCY IS INACTIVE" : ""} ${
            evaluatedTrigger.renderString || evaluatedTrigger.contextError
          }`,
          trigger: newTrigger,
          allTokens: evaluatedTrigger.allTokens,
          loading: false
        })
      })
  }

  renderCodeBlock() {
    const { classes, edit, handleEdit } = this.props
    if (edit) {
      return null
    }
    const gotHandleEditFunc = !!handleEdit

    const { formula = "" } = this.state.trigger

    return (
      <div
        title={gotHandleEditFunc ? "Click to Edit" : undefined}
        className={classes.codePreview}
        onClick={gotHandleEditFunc ? this.props.handleEdit : undefined}
      >
        <CodeBlock highlight={this.props.searchString}>{formula}</CodeBlock>
      </div>
    )
  }

  renderCodeEditor() {
    const { edit, classes } = this.props
    if (!edit) {
      return null
    }
    const { trigger } = this.state

    return (
      <React.Fragment>
        <Box
          className={clsx(
            { [classes.codeEditorArea]: edit },
            {
              [classes.codeSummary]: !edit
            }
          )}
        >
          <CodeEditor
            withTabs
            template={trigger.formula}
            handleChange={this.handleCodeEditorChange}
          />
        </Box>
        {this.state.loading && <LinearProgress />}
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          <FormControlLabel
            style={{ flexDirection: "column" }}
            control={
              <Checkbox
                title="Test this trigger without processing narrative dependencies first"
                checked={this.state.excludeDependencies}
                onChange={event => {
                  this.setState({
                    excludeDependencies: event.target.checked
                  })
                }}
              />
            }
            label="Skip Dependencies"
          />
          <Button onClick={() => this.buildPreview()}>Test</Button>
          <Button onClick={() => this.handleCancel()}>Cancel</Button>{" "}
          {this.canSaveTriggerToBackend ? (
            <Tooltip
              disableFocusListener
              disableTouchListener
              placement="bottom-start"
              title={
                trigger.name === "" || trigger.type === ""
                  ? "Please fill in all required fields"
                  : "Save"
              }
            >
              <span style={{ marginBottom: 10 }}>
                <Button
                  style={{ height: "100%" }}
                  color="primary"
                  onClick={() => this.handleSubmit()}
                  disabled={trigger?.name === "" || trigger.type === ""}
                >
                  Save
                </Button>
              </span>
            </Tooltip>
          ) : (
            <Button
              title="Opens new window"
              onClick={() => {
                window.open(
                  `/portal/narrative/${trigger?.narrative_Id}/edit?view=triggerid-${trigger?.id}`,
                  "_blank"
                )
              }}
              color="primary"
            >
              View In Library
            </Button>
          )}
        </Box>
        {this.state.loading &&
          this.props.searchParams &&
          this.props.searchParams.narrativeId && <LinearProgress />}
      </React.Fragment>
    )
  }

  handleCodeEditorChange(editor) {
    const { trigger } = this.state
    if (editor) {
      const newTrigger = {
        ...trigger,
        formula: editor.getValue()
      }
      this.setState({
        isSnackbarOpen: true,
        trigger: newTrigger
      })
    }
  }

  handleFieldChange(name) {
    return event => {
      if (name === "type") {
        if (event.target.value.match(TRIGGER_REGEX)) {
          this.setState({ errorText: "" })
        } else {
          this.setState({ errorText: "Invalid format: ###-###-####" })
        }
        event.target.value = event.target.value.replace(/\s/g, "_")
        event.target.value = event.target.value.toLowerCase()
      }
      const newTrigger = {
        ...this.state.trigger,
        [name]: event.target.value
      }
      this.setState({ trigger: newTrigger, formIsDirty: true })
    }
  }

  render() {
    const { classes } = this.props
    const { narrative } = this.context
    const { isLibrary } = narrative
    const {
      trigger,
      preview,
      allTokens,
      triggerDetails,
      isSnackbarOpen,
      alertMessage,
      currentView
    } = this.state

    const {
      type = "",
      name = "",
      description = "",
      rank,
      option,
      status_Id,
      categories = "",
      owner_Account_Id
    } = trigger

    const isDependency =
      typeof trigger.isDependency === "boolean" ? trigger.isDependency : false

    const createReplica =
      typeof trigger.createReplica === "boolean" ? trigger.createReplica : false

    const isNews = typeof trigger.isNews === "boolean" ? trigger.isNews : false

    const isActive =
      typeof trigger.isActive === "boolean" ? trigger.isActive : false

    const isArchived =
      typeof trigger.isArchived === "boolean" ? trigger.isArchived : false

    const { edit } = this.props

    return (
      <React.Fragment>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={isSnackbarOpen}
          key="ChangeLogSnack"
          message={alertMessage}
        />
        {this.state.isTriggerLoading && <LinearProgress />}
        <MUIAccordion
          defaultExpanded={
            this.props.defaultExpanded !== undefined
              ? this.props.defaultExpanded
              : true
          }
          aria-label="adveditorsentence-accordionfortriggers-opensaccordiontrigger"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.accordionIcon} />}
            aria-controls="accordion-contentheader"
            id="accordion-header"
          >
            <h5>Triggers</h5>
          </AccordionSummary>
          <FormHeader>
            <div style={{ flexGrow: 1 }} className="highlighter">
              <h4>
                <HighlightSpan highlight={this.props.searchString}>
                  {trigger?.id && <strong>{rank}</strong>}
                  {" - "}
                  {type && <strong>{type}</strong>}
                </HighlightSpan>
              </h4>
              <HighlightSpan highlight={this.props.searchString}>
                {trigger?.id && ` Id: ${trigger?.id}`}
                {name && name !== "" && ` - ${name}`}{" "}
                {option && option !== "" && `- ${option} `}
              </HighlightSpan>
            </div>
            <div
              style={{
                justifyContent: "flex-end",
                display: "flex"
              }}
            >
              <div style={{ width: "200px", marginRight: "10px" }}>
                <OwnerType
                  id="owner_Account_Id"
                  label="owner_Account_Id"
                  value={owner_Account_Id}
                  ownerList={this.props.ownerList}
                  onChange={value => {
                    const newTrigger = {
                      ...this.state.trigger,
                      owner_Account_Id: value
                    }
                    this.setState({ trigger: newTrigger })
                  }}
                  classes={{ selected: classes.selected }}
                />
              </div>
              <div style={{ width: "200px" }}>
                <StatusType
                  id="status_Id"
                  label="status_Id"
                  value={status_Id}
                  disabled={this.currentNarrativeId !== trigger?.narrative_Id}
                  onChange={value => {
                    const newTrigger = {
                      ...this.state.trigger,
                      status_Id: value
                    }
                    this.setState({ trigger: newTrigger })
                  }}
                  classes={{ selected: classes.selected }}
                />
              </div>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: triggerDetails
                })}
                onClick={() =>
                  this.setState({
                    triggerDetails: !triggerDetails
                  })
                }
                aria-expanded={triggerDetails}
                aria-label="show more"
                size="large"
              >
                <ExpandMoreIcon />
              </IconButton>
            </div>
          </FormHeader>
          {triggerDetails && (
            <>
              <GridContainer className={classes.gridStyleTriggers}>
                <GridItem xs={2} md={1}>
                  <TextField
                    aria-label="Advanced Editor Trigger RankField Textfield"
                    //sx={{ width: "50%" }}
                    id="rank"
                    label="Rank"
                    InputLabelProps={{ shrink: true }}
                    value={rank && parseInt(rank)}
                    onChange={this.handleFieldChange("rank")}
                    margin="normal"
                    disabled={!edit}
                  />
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <TextField
                    id="triggerKey"
                    aria-label="Advanced Editor Trigger TriggerKeyField Textfield"
                    label="Trigger Type Key"
                    value={type}
                    onChange={this.handleFieldChange("type")}
                    inputProps={{
                      pattern: TRIGGER_REGEX
                    }}
                    onInvalid={() => alert("This is so wrong!!")}
                    helperText={
                      this.state.formIsDirty && !type
                        ? "Trigger Type Key is required!"
                        : "All lowercase, no spaces"
                    }
                    margin="normal"
                    fullWidth
                    required
                    disabled={!edit}
                    error={this.state.formIsDirty && !type}
                  />
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <TextField
                    id="triggerName"
                    aria-label="Advanced Editor Trigger TriggerNameField Textfield"
                    label="Trigger Type Name"
                    value={name}
                    onChange={this.handleFieldChange("name")}
                    margin="normal"
                    fullWidth
                    required
                    disabled={!edit}
                    helperText={
                      this.state.formIsDirty &&
                      !name &&
                      "Trigger Type Name is required!"
                    }
                    error={this.state.formIsDirty && !name}
                  />
                </GridItem>
                {(isDependency === true || isDependency === "true") && (
                  <GridItem xs={12} sm={3}>
                    <StatusType
                      id="status_Id"
                      aria-label="Triggers StatusType StatusComponent"
                      label="status_Id"
                      value={status_Id}
                      disabled={!edit}
                      onChange={value => {
                        const newTrigger = {
                          ...this.state.trigger,
                          status_Id: value
                        }
                        this.setState({
                          trigger: newTrigger
                        })
                      }}
                      classes={{ selected: classes.selected }}
                    />
                  </GridItem>
                )}
                <GridItem xs={12} sm={1} md={1}>
                  <FormControlLabel
                    style={{ paddingTop: "16px" }}
                    control={
                      <Checkbox
                        checked={isArchived}
                        onChange={event => {
                          const newTrigger = {
                            ...this.state.trigger,
                            isArchived: event.target.checked
                          }
                          this.setState({
                            trigger: newTrigger
                          })
                        }}
                      />
                    }
                    aria-label="Advanced Editor Trigger IsArchived Checkbox"
                    label="Archived"
                  />
                </GridItem>
              </GridContainer>
              <Grid className={classes.gridStyleTriggers}>
                <GridItem xs={8} sm>
                  <TextField
                    aria-label="Advanced Editor Trigger Description Textbox"
                    id="description"
                    label="Description"
                    value={description}
                    multiline
                    onChange={this.handleFieldChange("description")}
                    margin="normal"
                    fullWidth
                    disabled={!edit}
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignContent: "center"
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!edit}
                        checked={isNews}
                        onChange={event => {
                          //weird logic is because isnews and isdependency checkboxes should be mutually exclusive
                          const newTrigger = {
                            ...this.state.trigger,
                            isNews: event.target.checked,
                            isDependency:
                              isDependency && event.target.checked
                                ? false
                                : isDependency
                          }
                          this.setState({
                            trigger: newTrigger
                          })
                        }}
                      />
                    }
                    aria-label="Advanced Editor Trigger IsNews Checkbox"
                    label="News Trigger"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!edit}
                        checked={isDependency}
                        onChange={event => {
                          //weird logic is because isnews and isdependency checkboxes should be mutually exclusive
                          const newTrigger = {
                            ...this.state.trigger,
                            isDependency: event.target.checked,
                            isNews:
                              isNews && event.target.checked ? false : isNews
                          }
                          this.setState({
                            trigger: newTrigger
                          })
                        }}
                      />
                    }
                    aria-label="Advanced Editor Trigger IsDependency Checkbox"
                    label="Dependency"
                  />
                  {isLibrary &&
                    (isDependency === true || isDependency === "true") && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={!edit}
                            checked={createReplica}
                            onChange={event => {
                              //weird logic is because isnews and isdependency checkboxes should be mutually exclusive
                              const newTrigger = {
                                ...this.state.trigger,
                                createReplica: event.target.checked,
                                isNews:
                                  isNews && event.target.checked
                                    ? false
                                    : isNews
                              }
                              this.setState({
                                trigger: newTrigger
                              })
                            }}
                          />
                        }
                        aria-label="Advanced Editor Trigger IsReplica Checkbox"
                        label="Replicate and Disassociate?"
                      />
                    )}
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!edit}
                        checked={isActive}
                        onChange={event => {
                          const newTrigger = {
                            ...this.state.trigger,
                            isActive: event.target.checked
                          }
                          this.setState({
                            trigger: newTrigger
                          })
                        }}
                      />
                    }
                    aria-label="Advanced Editor Trigger IsActive Checkbox"
                    label="Is Active"
                  />
                </GridItem>
              </Grid>
              <Grid className={classes.gridStyleTriggers}>
                <GridItem>
                  <TextField
                    label="Categories"
                    type="text"
                    margin="normal"
                    name="categoriesInput"
                    value={categories || ""}
                    onChange={this.handleFieldChange("categories")}
                  />
                </GridItem>
                <GridItem>
                  <TextField
                    fullWidth
                    label="Required Data"
                    type="text"
                    margin="normal"
                    name="requiredDataInput"
                    value={
                      (trigger?.requiredData &&
                        JSON.stringify(trigger?.requiredData)) ||
                      ""
                    }
                    onChange={this.handleFieldChange("requiredData")}
                  />
                </GridItem>
              </Grid>
              <Grid>
                <GridItem sm={12}>
                  <Accordion
                    collapses={[
                      {
                        title: "Comments",
                        content: (
                          <TextField
                            id="comments"
                            fullWidth={true}
                            disabled={!edit}
                            type="text"
                            multiline={true}
                            minRows={3}
                            value={trigger?.comments || ""}
                            onChange={e => {
                              const newTrigger = {
                                ...this.state.trigger,
                                comments: e.target.value
                              }
                              this.setState({
                                trigger: newTrigger
                              })
                            }}
                          />
                        )
                      }
                    ]}
                  />
                </GridItem>
              </Grid>
            </>
          )}
          <Box style={{ padding: "15px" }}>
            {edit ? this.renderCodeEditor() : this.renderCodeBlock()}
          </Box>
          {preview && preview !== "" && (
            <Box style={{ margin: "15px" }}>
              <strong>Result: </strong>
              {preview}
            </Box>
          )}
          {!this.props.searchEditorMode && (
            <div>
              <Tabs
                value={currentView}
                onChange={this.handleTabChange}
                aria-label="Advanced Editor Trigger Tokens Tabs"
              >
                <Tab label="Tokens Generated" />
                <Tab label="Required Tokens" />
                <Tab label="All Tokens" />
              </Tabs>
              <div style={{ margin: "10px" }}>
                <TabPanel value={currentView} index={0}>
                  <Box>
                    <ReactJson
                      enableClipboard={true}
                      displayObjectSize={true}
                      displayDataTypes={false}
                      src={trigger?.tokens || []}
                      collapsed={true}
                      shouldCollapse={({ name }) => name === "value"}
                    />
                  </Box>
                </TabPanel>
                <TabPanel value={currentView} index={1}>
                  <Box>
                    <ReactJson
                      enableClipboard={true}
                      displayObjectSize={true}
                      displayDataTypes={false}
                      src={trigger?.requiredData || []}
                      collapsed={true}
                      shouldCollapse={({ name }) => name === "value"}
                    />
                  </Box>
                </TabPanel>
                <TabPanel value={currentView} index={2}>
                  <Box>
                    <ReactJson
                      enableClipboard={true}
                      displayObjectSize={true}
                      displayDataTypes={false}
                      src={allTokens}
                      collapsed={true}
                      shouldCollapse={({ name }) => name === "value"}
                    />
                  </Box>
                </TabPanel>
              </div>
              <div
                style={{
                  borderBottom: "1px solid rgba(0, 0, 0, 0.14)"
                }}
              >
                <div
                  className={classes.triggerHeader}
                  title="View Change Log"
                  onClick={this.loadHistory}
                  style={{ flexGrow: 1, cursor: "pointer" }}
                >
                  <div>
                    <h4
                      style={{
                        fontSize: "15px",
                        marginTop: 0,
                        marginBottom: 0,
                        fontWeight: "bolder"
                      }}
                    >
                      Change Log
                    </h4>
                  </div>
                  <IconButton
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: this.state.isHistoryVisible
                    })}
                    aria-expanded={this.state.isHistoryVisible}
                    aria-label="Advanced Editor Changelog Show More"
                    size="large"
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </div>
                <div>
                  {this.state.isHistoryVisible && (
                    <ChangeLog
                      handleRollBack={this.handleRollBack}
                      changeLogs={this.state.changeLogs}
                      totalChangeLogRecords={this.state.totalChangeLogRecords}
                      isChangeLogsLoading={this.state.isChangeLogsLoading}
                      isChangeLogsLoaded={this.state.isChangeLogsLoaded}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </MUIAccordion>
      </React.Fragment>
    )
  }
}

Trigger.propTypes = {
  classes: PropTypes.object,
  triggerId: PropTypes.number,
  savedTrigger: PropTypes.func,
  hitExitTrigger: PropTypes.func,
  edit: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleEdit: PropTypes.func,
  handleReload: PropTypes.func,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  searchParams: PropTypes.object,
  searchString: PropTypes.string,
  searchEditorMode: PropTypes.bool
}

export default withStyles(styles)(withRouter(Trigger))
