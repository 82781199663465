/*eslint-disable*/
import React from "react"
import cx from "classnames"
import PropTypes from "prop-types"
import { Switch, Route, Redirect } from "react-router-dom"
import { withRouter } from "react-router"
import PerfectScrollbar from "perfect-scrollbar"
import withStyles from "@mui/styles/withStyles"

import Header from "components/Header/Header"
import Footer from "components/Footer/Footer"
import Sidebar from "components/Sidebar/Sidebar"
import rtlRoutes, { sidebarLinks } from "routes/rtl"
import rtlStyle from "assets/jss/material-dashboard-pro-react/layouts/rtlStyle"
import image from "assets/img/sidebar-2.jpg"
import logo from "assets/img/logo-white.svg"
import "perfect-scrollbar/css/perfect-scrollbar.css"

class RTL extends React.Component {
	state = {
		mobileOpen: false,
		miniActive: false,
	}
	handleDrawerToggle = () => {
		this.setState({ mobileOpen: !this.state.mobileOpen })
	}
	componentDidMount() {
		if (navigator.platform.indexOf("Win") > -1) {
			const ps = new PerfectScrollbar(this.refs.mainPanel)
		}
	}
	componentDidUpdate(e) {
		if (e.history.location.pathname !== e.location.pathname) {
			this.refs.mainPanel.scrollTop = 0
			if (this.state.mobileOpen) {
				this.setState({ mobileOpen: false })
			}
		}
	}
	sidebarMinimize() {
		this.setState({ miniActive: !this.state.miniActive })
	}
	render() {
		const { classes, ...rest } = this.props
		const mainPanel =
			classes.mainPanel +
			" " +
			cx({
				[classes.mainPanelSidebarMini]: this.state.miniActive,
			})
		return (
			<div className={classes.wrapper}>
				<Sidebar
					routes={sidebarLinks}
					logoText={"توقيت الإبداعية"}
					logo={logo}
					image={image}
					handleDrawerToggle={this.handleDrawerToggle}
					open={this.state.mobileOpen}
					color="blue"
					bgColor="black"
					miniActive={this.state.miniActive}
					rtlActive
					{...rest}
				/>
				<div className={mainPanel} ref="mainPanel">
					<Header
						rtlActive
						sidebarMinimize={this.sidebarMinimize.bind(this)}
						miniActive={this.state.miniActive}
						routes={rtlRoutes}
						handleDrawerToggle={this.handleDrawerToggle}
						{...rest}
					/>
					<div className={classes.content}>
						<div className={classes.container}>
							<Switch>
								{rtlRoutes.map((prop, key) => {
									if (prop.redirect)
										return (
											<Redirect
												from={prop.path}
												to={prop.pathTo}
												key={key}
											/>
										)
									if (prop.collapse)
										return prop.views.map((prop, key) => {
											return (
												<Route
													path={prop.path}
													component={prop.component}
													key={key}
												/>
											)
										})
									return (
										<Route
											path={prop.path}
											component={prop.component}
											key={key}
										/>
									)
								})}
							</Switch>
						</div>
					</div>
					<Footer fluid rtlActive />
				</div>
			</div>
		)
	}
}

RTL.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(rtlStyle)(withRouter(RTL))
