import { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"

// MUI Components
import Checkbox from "@mui/material/Checkbox"
import DialogActions from "@mui/material/DialogActions"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import Grid from "@mui/material/Grid"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Modal from "@mui/material/Modal"
import Select from "@mui/material/Select"
import TextField from "@mui/material/TextField"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import FormHelperText from "@mui/material/FormHelperText"

// MUI Misc.
import Autocomplete from "@mui/material/Autocomplete"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import withStyles from "@mui/styles/withStyles"

// Custom Components
import Button from "components/CustomButtons/Button"
import GridItem from "components/Grid/GridItem"

// Custom Utils
import { globalSettings } from "variables/general"
import Helpers from "../../tools/Helpers"
import { urlValidation } from "tools/ValidationHelpers"
import { put } from "tools/request"
import { ENTERTAINMENT_VERTICAL_ID } from "constants"

const styles = theme => ({
  successModal: {
    position: "absolute",
    color: "#069",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    transform: "translate(-45%, -45%)",
    "& h3": {
      fontWeight: "bold"
    },
    "& ul": {
      padding: "5px 0 0 5px",
      fontWeight: "bold"
    },
    [theme.breakpoints.up(701)]: {
      width: 400,
      top: "45%",
      left: "45%"
    },
    [theme.breakpoints.down(700)]: {
      width: "70vw",
      top: "50vh",
      left: "50vw"
    }
  },
  myFlex: {
    alignItems: "flex-end"
  },
  deliveryInstructionsWidth: {
    [theme.breakpoints.down(700)]: {
      maxWidth: "inherit"
    },
    [theme.breakpoints.up(700)]: {
      maxWidth: "60% !important"
    }
  },
  errorModal: {
    position: "absolute",
    color: "#c00",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    transform: "translate(-45%, -45%)",
    "& h3": {
      fontWeight: "bold"
    },
    "& ul": {
      padding: "5px 0 0 5px",
      fontWeight: "bold",
      textDecoration: "underline"
    },
    [theme.breakpoints.up(701)]: {
      width: 400,
      top: "45%",
      left: "45%"
    },
    [theme.breakpoints.down(700)]: {
      width: "70vw",
      top: "50vh",
      left: "50vw"
    }
  },
  propertyCaveat: {
    position: "relative",
    top: "-110px",
    right: "400px",
    whiteSpace: "nowrap",
    width: "200px"
  },
  chip: {
    fontSize: 12
  },
  infoIcon: {
    display: "block",
    marginLeft: "auto",
    marginBottom: 5
  },
  leagueGrid: {
    marginTop: "-15px"
  },
  leagueGridDivConf: {
    marginTop: "12px"
  },
  caveatText: {
    textAlign: "center",
    marginBottom: "2rem"
  },
  nameDeliverable: {
    marginTop: "0px"
  },
  renderAttributesTitle: {
    display: "flex",
    justifyContent: "space-around"
  },
  renderAttributeHeader: {
    fontWeight: "500"
  },
  deliverableTopRow: {
    marginBottom: "25px"
  },
  propertyFields: {
    marginTop: "19px"
  },
  input: {
    textDecoration: "line-through"
  },
  requiredMsg: {
    color: "#c00",
    fontWeight: "bold",
    backgroundColor: "#f7f7f7"
  },
  clientURLFields: {
    marginTop: "16px"
  }
})

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    retrieveLeagues: store.sportsStore.retrieveLeagues,
    retrieveLeagueDetails: store.sportsStore.retrieveLeagueDetails,
    leagueDetailsById: store.sportsStore.leagueDetailsById,
    leagues: store.sportsStore.leagues
  }))
}

function ContractDeliverable({
  deliverables,
  deliverableToEdit,
  editDeliverable,
  hasChildren,
  classes,
  contracts,
  organizations,
  createMode,
  org,
  contractId,
  articleTypes = [],
  createdByAccountId,
  handleAction,
  handleNewDeliverable,
  handleCopyDeliverable,
  subjectTypes,
  publishTypes,
  catalystTypes,
  verticals,
  getVerticalName
}) {
  const orgId = org.id || 0
  const parentOrg = org.name || ""

  const { leagues, retrieveLeagues, retrieveLeagueDetails, leagueDetailsById } =
    useStoreData()

  const leagueList = [{ name: "Not Applicable", id: null }, ...leagues]

  if (hasChildren?.[0]?.name?.indexOf("*No Property") === -1) {
    hasChildren.unshift({
      id: orgId,
      name: `*No Property${parentOrg ? ` (${parentOrg})` : ""}`
    })
  }

  const getInitialFormValues = () => {
    const reservoir =
      editDeliverable.find(itm => itm.id === deliverableToEdit) || deliverables

    return {
      ...reservoir,
      clientUrlStructure: reservoir.clientUrlStructure,
      conferenceIds: reservoir.conferenceIds || [],
      divisionIds: reservoir.divisionIds || [],
      teamIds: reservoir.teamIds || [],
      articleTypeId: reservoir.articleTypeId || null
    }
  }

  const [saveFailed, setSaveFailed] = useState(false)
  const [saveSuccess, setSaveSuccess] = useState(false)
  const [showPropertyCaveat, setShowPropertyCaveat] = useState(false)
  const [copyToOtherContract, setCopyToOtherContract] = useState(false)
  const [isCopyingDeliverable, setIsCopyingDeliverable] = useState(false)
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [isProLeague, setIsProLeague] = useState(true)
  const [initializedVertical, setInitializedVertical] = useState(false)
  const [isEntertainmentVertical, setEntertainmentVertical] = useState(false)

  const myReservoir = useMemo(getInitialFormValues, [
    deliverables,
    deliverableToEdit,
    editDeliverable,
    organizations
  ])
  const [myFormValues, setMyFormValues] = useState(getInitialFormValues())

  useEffect(() => {
    if (!leagues?.length) {
      retrieveLeagues()
    }
  }, [])

  const {
    clientUrlStructure,
    name,
    organizationId,
    copyToContractId,
    leagueId,
    conferenceIds,
    divisionIds,
    teamIds,
    isArchived,
    subjectTypeId,
    publishTypeId,
    catalystTypeId,
    verticalId
  } = myFormValues

  const leagueData = leagueDetailsById[leagueId]

  const otherContracts = contracts.filter(itm => itm.id !== contractId)

  const conferenceList = useMemo(
    () =>
      leagueData?.conferences
        ?.slice()
        .sort((a, b) => (a.name > b.name ? 1 : -1)) || [],
    [leagueData]
  )

  const divisionList = useMemo(
    () =>
      leagueData?.divisions
        ?.slice()
        .sort((a, b) => (a.name > b.name ? 1 : -1)) || [],
    [leagueData]
  )

  const teamList = useMemo(
    () =>
      leagueData?.teams
        ?.filter(team => {
          if (conferenceIds?.length > 0) {
            return conferenceIds.some(id => id === team.conference_Id)
          } else if (divisionIds?.length > 0) {
            return divisionIds.some(id => id === team.division_Id)
          } else {
            return team
          }
        })
        .sort((a, b) => (a.displayName > b.displayName ? 1 : -1)) || [],
    [leagueData, conferenceIds, divisionIds]
  )

  const getLeagueName = id => {
    let val = ""
    leagueList.forEach(itm => {
      if (itm.id === id) {
        val = itm.name
      }
    })
    return val
  }

  const getNames = (list, ids) => {
    if (ids === null) {
      return
    }
    let arr = []
    list.forEach(itm =>
      ids.forEach(id => {
        if (id === itm.id) {
          arr.push(itm.name)
        }
      })
    )
    return arr
  }

  const getTeamNames = (list, ids) => {
    if (ids === null) {
      return
    }
    let arr = []
    list.forEach(itm =>
      ids.forEach(id => {
        if (id === itm.id) {
          arr.push(itm.displayName)
        }
      })
    )
    return arr
  }

  const getArticleTypeName = id => {
    let val = ""
    articleTypes.forEach(itm => {
      if (itm.id === id) {
        val = itm.name
      }
    })
    return val
  }

  const getOrganizationName = id => {
    let val = ""
    organizations.forEach(itm => {
      if (itm.id === id) {
        val = itm.name
      }
    })
    return val
  }

  const getSubjectTypeName = id => {
    let val = ""
    subjectTypes.forEach(itm => {
      if (itm.id === id) {
        val = itm?.name
      }
    })
    return val
  }

  const getCatalystTypeName = id => {
    let val = ""
    catalystTypes.forEach(itm => {
      if (itm.id === id) {
        val = itm?.name
      }
    })
    return val
  }

  const getPublishTypeName = id => {
    let val = ""
    publishTypes.forEach(itm => {
      if (itm.id === id) {
        val = itm.name
      }
    })
    return val
  }

  const handleSave = () => {
    //Save the deliverable to the back end
    setSaveButtonDisabled(true)
    const contractId = deliverables.id

    const {
      articleTypeId,
      verticalId,
      clientUrlStructure,
      copyToContractId,
      leagueId,
      conferenceIds,
      divisionIds,
      teamIds,
      name,
      organizationId,
      isArchived,
      subjectTypeId,
      publishTypeId,
      catalystTypeId,
      catalystTypeName
    } = myFormValues

    const myPayload = {
      articleTypeId,
      verticalId,
      clientUrlStructure,
      doClientVerification: !!clientUrlStructure,
      leagueId,
      conferenceIds,
      divisionIds,
      teamIds,
      contractId,
      name,
      organizationId,
      isArchived,
      createdByAccountId: createdByAccountId,
      subjectTypeId,
      publishTypeId,
      catalystTypeId,
      catalystTypeName
    }

    let myaction = "create"
    if (deliverableToEdit && deliverableToEdit > -1 && !copyToContractId) {
      myPayload.id = deliverableToEdit
      myaction = "update"
    }
    //Look to see if we are copying a Delivarable to another Contract
    if (copyToContractId) {
      myPayload.contractId = copyToContractId
      myaction = "create"
    } else {
      myPayload.contractId = myReservoir.contractId
        ? myReservoir.contractId
        : contractId
    }

    put(
      `${globalSettings.apiBaseUrl}/api/organization/${myaction}/contractdeliverables?contractId=${contractId}`,
      null,
      {
        ...myPayload
      }
    )
      .then(Response => Response && Response.ok === true && Response.json())
      .then(deliverables => {
        if (
          deliverables &&
          deliverables.content &&
          deliverables.responseMessage
        ) {
          let id = deliverables.responseMessage.match(/Deliverable\s#(\d*)/)
          if (id && typeof id === "object" && id[1]) {
            id = Number(id[1])
            const leagueName = getLeagueName(leagueId)
            const articleTypeName = getArticleTypeName(articleTypeId)
            const verticalName = getVerticalName(verticalId)
            const organizationName = getOrganizationName(organizationId)
            const conferenceNames = getNames(
              conferenceList,
              deliverables.content.conferenceIds
            )
            const divisionNames = getNames(
              divisionList,
              deliverables.content.divisionIds
            )
            const teamNames = getTeamNames(
              teamList,
              deliverables.content.teamIds
            )
            const subjectTypeName = getSubjectTypeName(subjectTypeId)
            const catalystTypeName = getCatalystTypeName(catalystTypeId)
            const publishTypeName = getPublishTypeName(publishTypeId)
            const cId = deliverables.content.contractId
            const newDeliverable = {
              id,
              name,
              clientUrlStructure,
              contractId: cId,
              articleTypeId,
              articleTypeName,
              verticalId,
              verticalName,
              leagueId,
              leagueName,
              isArchived,
              organizationId,
              organizationName,
              conferenceIds,
              divisionIds,
              teamIds,
              conferenceNames,
              divisionNames,
              teamNames,
              subjectTypeId,
              subjectTypeName,
              publishTypeId,
              publishTypeName,
              catalystTypeId,
              catalystTypeName
            }
            if (!copyToContractId || copyToContractId === contractId) {
              handleNewDeliverable(newDeliverable)
            } else {
              setIsCopyingDeliverable(true)
            }
          }
          setSaveSuccess(true)
          setSuccessMessage(deliverables.responseMessage)
        } else {
          setSaveFailed(true)
          setSuccessMessage(deliverables.responseMessage)
        }
      })
  }

  const handleClose = () => {
    handleAction("closing")
  }

  const handleDeliverableChange = name => e => {
    const newDeliverables = {
      ...myFormValues,
      [name]: e.target.value
    }

    setMyFormValues(newDeliverables)
  }

  const handleChangePropertyOrg = e => {
    const newDeliverables = {
      ...myFormValues,
      organizationId: e.target.value
    }
    setMyFormValues(newDeliverables)
  }

  const handleAutocompleteChange = (name, value, reason) => {
    if (name === "verticalId") {
      setEntertainmentVertical(value?.id === ENTERTAINMENT_VERTICAL_ID)
    }
    const newDeliverables = {
      ...myFormValues,
      [name]: reason === "clear" ? null : value?.id || null
    }

    setMyFormValues(newDeliverables)
  }

  const handleChangeLeague = value => {
    const newDeliverables = {
      ...myFormValues,
      leagueId: value?.id
    }

    // If new league is selected, we reset the other league data before fetching new data
    if (name === "leagueId") {
      newDeliverables.conferenceIds = []
      newDeliverables.divisionIds = []
      newDeliverables.teamIds = []
    }

    setMyFormValues(newDeliverables)
  }

  const handleMultipleAutocompleteChange = (name, value) => {
    const arrayOfIds = value.map(obj => obj.id)
    const newDeliverables = {
      ...myFormValues,
      [name]: arrayOfIds
    }

    // Reset teams if division/conference is change to prevent saving hidden team data.
    if (["conferenceIds", "divisionIds"].includes(name)) {
      newDeliverables.teamIds = []
    }

    setMyFormValues(newDeliverables)
  }

  const handleCheckboxChange = name => e => {
    const newDeliverables = {
      ...myFormValues,
      [name]: e.target.checked
    }
    setMyFormValues(newDeliverables)
  }

  // Get new league info if a new league is selected.
  useEffect(() => {
    if (leagueId) {
      retrieveLeagueDetails(leagueId)
    }
  }, [leagueId])

  useEffect(() => {
    setIsProLeague(leagueData?.levelId === 1)
  }, [leagueData])

  const myVerticals = verticals?.toJS() || []
  if (
    createMode &&
    myVerticals.length === 1 &&
    !verticalId &&
    !initializedVertical
  ) {
    const newDeliverables = {
      ...myFormValues,
      verticalId: myVerticals[0].id
    }
    setMyFormValues(newDeliverables)
    setInitializedVertical(true)
  }

  const getAttributeTypes = (items, isArchivedValue, currentTypeId, sortFunc) =>
    items
      ?.toJS()
      .filter(item => item.isArchived === false || currentTypeId === item.id)
      .sort(sortFunc)

  // const archivedOptionType = option =>
  //   option.isArchived ? (
  //     <span style={{ textDecoration: "line-through" }}>{option.name}</span>
  //   ) : (
  //     <span>{option.name}</span>
  //     )
  const archivedOptionType = (props, option) => (
    <li {...props}>
      {option.isArchived ? (
        <span style={{ textDecoration: "line-through" }}>{option.name}</span>
      ) : (
        <span>{option.name}</span>
      )}
    </li>
  )

  return (
    <div style={{ _minWidth: "1200px" }}>
      <Typography classes={{ root: classes.caveatText }}>
        {showPropertyCaveat &&
          `* "No Property" selected means that deliver is for ${
            org?.name ? org.name : "the organization"
          }.`}
      </Typography>
      <>
        <Grid className={classes.renderAttributesTitle}>
          <Typography className={classes.renderAttributeHeader}>
            Render Attributes
          </Typography>
        </Grid>
        <Grid
          container
          alignItems="flex-start"
          spacing={1}
          style={{ marginTop: "1rem" }}
        >
          <Grid item xs={12} sm={3} className={classes.deliverableTopRow}>
            <TextField
              className={classes.nameDeliverable}
              multiline
              type="text"
              id="name"
              label="Name of Deliverable"
              fullWidth
              value={name}
              onChange={handleDeliverableChange("name")}
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Autocomplete
              id="verticalId"
              options={[{ verticalName: "", id: null }].concat(
                verticals?.toJS().sort(function (a, b) {
                  return (
                    a.verticalName &&
                    a.verticalName.localeCompare(b.verticalName)
                  )
                })
              )}
              value={[{ verticalName: "", id: null }]
                .concat(verticals?.toJS())
                .find(vertical => vertical.id === verticalId)}
              getOptionLabel={option => option.verticalName}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={params => (
                <TextField {...params} label="Vertical" variant="outlined" />
              )}
              onChange={(event, value, reason) =>
                handleAutocompleteChange("verticalId", value, reason)
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Tooltip
              disableFocusListener
              disableTouchListener
              title="What causes this deliverable to render (ex: match or tournament)?"
              placement="top"
            >
              <Autocomplete
                id="catalystTypeId"
                options={getAttributeTypes(
                  catalystTypes,
                  false,
                  catalystTypeId,
                  Helpers.sortByName
                )}
                value={
                  catalystTypes
                    ?.toJS()
                    .find(catalystType => catalystType.id === catalystTypeId) ||
                  null
                }
                onChange={(event, value) =>
                  handleAutocompleteChange("catalystTypeId", value)
                }
                getOptionLabel={option => option?.name || ""}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        textDecoration: catalystTypes
                          ?.toJS()
                          .find(ct => ct.id === catalystTypeId)?.isArchived
                          ? "line-through"
                          : "none"
                      }
                    }}
                    label="What?"
                    variant="outlined"
                  />
                )}
                renderOption={archivedOptionType}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Tooltip
              disableFocusListener
              disableTouchListener
              title="Who is the deliverable about (ex: a person)?"
              placement="top"
            >
              <Autocomplete
                id="subjectTypeId"
                options={getAttributeTypes(
                  subjectTypes,
                  false,
                  subjectTypeId,
                  Helpers.sortByName
                )}
                value={
                  subjectTypes
                    ?.toJS()
                    .find(subjectType => subjectType.id === subjectTypeId) ||
                  null
                }
                onChange={(event, value) =>
                  handleAutocompleteChange("subjectTypeId", value)
                }
                getOptionLabel={option => option?.name || ""}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        textDecoration: subjectTypes
                          ?.toJS()
                          .find(st => st.id === subjectTypeId)?.isArchived
                          ? "line-through"
                          : "none"
                      }
                    }}
                    label="Who?"
                    variant="outlined"
                  />
                )}
                renderOption={archivedOptionType}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Tooltip
              disableFocusListener
              disableTouchListener
              title="When does the deliverable occur (ex: before)?"
              placement="top"
            >
              <Autocomplete
                id="publishTypeId"
                options={getAttributeTypes(
                  publishTypes,
                  false,
                  publishTypeId,
                  Helpers.sortByName
                )}
                value={publishTypes
                  ?.toJS()
                  .find(
                    publishType => publishType.id === publishTypeId || null
                  )}
                onChange={(event, value) =>
                  handleAutocompleteChange("publishTypeId", value)
                }
                getOptionLabel={option => option?.name || ""}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        textDecoration: publishTypes
                          ?.toJS()
                          .find(st => st.id === publishTypeId)?.isArchived
                          ? "line-through"
                          : "none"
                      }
                    }}
                    label="When?"
                    variant="outlined"
                  />
                )}
                renderOption={archivedOptionType}
                style={
                  publishTypes?.find(pt => pt.id === publishTypeId)?.isArchived
                    ? { textDecoration: "line-through" }
                    : { textDecoration: "none" }
                }
              />
            </Tooltip>
          </Grid>
          {!isEntertainmentVertical && (
            <>
              <Grid item xs={12} sm={2} classes={{ root: classes.leagueGrid }}>
                <Tooltip
                  title={
                    <span style={{ whiteSpace: "pre-line" }}>
                      A user can only select either League Division or League
                      Conference due to differing definitions by sport.
                      <br />
                      <br />
                      Also, when selecting conference or division and teams,
                      conference or division should be selected first otherwise
                      the team selections will be cleared.
                    </span>
                  }
                  placement="right-end"
                >
                  <InfoOutlinedIcon
                    color="primary"
                    fontSize="small"
                    classes={{ root: classes.infoIcon }}
                  />
                </Tooltip>
                <Autocomplete
                  id="leagueId"
                  options={leagueList}
                  onChange={(event, value) => {
                    handleChangeLeague(value)
                  }}
                  value={leagueList.find(league => league.id === leagueId)}
                  getOptionLabel={option => option?.name || ""}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="League"
                      multiline
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              {isProLeague ? (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    classes={{ root: classes.leagueGridDivConf }}
                  >
                    <Autocomplete
                      multiple
                      id="conferenceIds"
                      options={conferenceList}
                      value={
                        conferenceList?.filter(i =>
                          conferenceIds?.some(j => j === i.id)
                        ) || []
                      }
                      onChange={(event, value) =>
                        handleMultipleAutocompleteChange("conferenceIds", value)
                      }
                      getOptionLabel={option => option.name || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value?.id
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="League Conference"
                          variant="outlined"
                        />
                      )}
                      limitTags={3}
                      ChipProps={{
                        classes: { root: classes.chip },
                        size: "small"
                      }}
                      disabled={!leagueId || divisionIds?.length > 0}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    classes={{ root: classes.leagueGridDivConf }}
                  >
                    <Autocomplete
                      multiple
                      id="divisionIds"
                      options={divisionList}
                      value={
                        divisionList?.filter(i =>
                          divisionIds?.some(j => j === i.id)
                        ) || []
                      }
                      onChange={(event, value) =>
                        handleMultipleAutocompleteChange("divisionIds", value)
                      }
                      getOptionLabel={option => option.name || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value?.id
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="League Division"
                          variant="outlined"
                        />
                      )}
                      limitTags={3}
                      ChipProps={{
                        classes: { root: classes.chip },
                        size: "small"
                      }}
                      disabled={!leagueId || conferenceIds?.length > 0}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    classes={{ root: classes.leagueGridDivConf }}
                  >
                    <Autocomplete
                      multiple
                      id="divisionIds"
                      options={divisionList}
                      value={
                        divisionList?.filter(i =>
                          divisionIds?.some(j => j === i.id)
                        ) || []
                      }
                      onChange={(event, value) =>
                        handleMultipleAutocompleteChange("divisionIds", value)
                      }
                      getOptionLabel={option => option.name || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="League Division"
                          variant="outlined"
                        />
                      )}
                      limitTags={3}
                      ChipProps={{
                        classes: { root: classes.chip },
                        size: "small"
                      }}
                      disabled={!leagueId || conferenceIds?.length > 0}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    classes={{ root: classes.leagueGridDivConf }}
                  >
                    <Autocomplete
                      multiple
                      id="conferenceIds"
                      options={conferenceList}
                      value={
                        conferenceList?.filter(i =>
                          conferenceIds?.some(j => j === i.id)
                        ) || []
                      }
                      onChange={(event, value) =>
                        handleMultipleAutocompleteChange("conferenceIds", value)
                      }
                      getOptionLabel={option => option.name || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="League Conference"
                          variant="outlined"
                        />
                      )}
                      limitTags={3}
                      ChipProps={{
                        classes: { root: classes.chip },
                        size: "small"
                      }}
                      disabled={!leagueId || divisionIds?.length > 0}
                    />
                  </Grid>
                </>
              )}
              <Grid
                item
                xs={12}
                sm={2}
                classes={{ root: classes.leagueGridDivConf }}
              >
                <Autocomplete
                  multiple
                  id="teamIds"
                  options={teamList}
                  value={
                    teamList?.filter(i => teamIds?.some(j => j === i.id)) || []
                  }
                  onChange={(event, value) =>
                    handleMultipleAutocompleteChange("teamIds", value)
                  }
                  getOptionLabel={option => option.displayName || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="League Team"
                      variant="outlined"
                    />
                  )}
                  limitTags={3}
                  ChipProps={{
                    classes: { root: classes.chip },
                    size: "small"
                  }}
                  disabled={!leagueId}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid container spacing={1} className={classes.myFlex}>
          {hasChildren.length > 0 && (
            <Grid item xs={12} sm={3} className={classes.propertyFields}>
              <FormControl margin="normal" variant="outlined" fullWidth>
                <InputLabel id="property-select-label">Property</InputLabel>
                <Select
                  type="select"
                  name="organizationId"
                  id="organizationId"
                  label="Property"
                  value={organizationId || myReservoir.organizationId || ""}
                  onChange={handleChangePropertyOrg}
                  onFocus={() => {
                    setShowPropertyCaveat(true)
                  }}
                  onBlur={() => {
                    setShowPropertyCaveat(false)
                  }}
                >
                  {hasChildren &&
                    hasChildren.map(childOrgs => (
                      <MenuItem key={childOrgs.id} value={childOrgs.id}>
                        {childOrgs.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            md={3}
            fullWidth
            className={classes.clientURLFields}
          >
            <Tooltip
              title={
                <span style={{ whiteSpace: "pre-line" }}>
                  Only insert Client URL if using Publish Verification
                </span>
              }
              placement="right-end"
            >
              <InfoOutlinedIcon
                color="primary"
                fontSize="small"
                classes={{ root: classes.infoIcon }}
              />
            </Tooltip>
            <TextField
              type="text"
              id="clientUrlStructure"
              label="Client URL Structure"
              fullWidth
              value={clientUrlStructure}
              onChange={handleDeliverableChange("clientUrlStructure")}
              margin="normal"
              variant="outlined"
            />
            {clientUrlStructure &&
              clientUrlStructure.length > 0 &&
              !urlValidation(clientUrlStructure) && (
                <FormHelperText className={classes.requiredMsg}>
                  Invalid Client Structure URL
                </FormHelperText>
              )}
          </Grid>
          <Grid item xs={12} md={2} className={classes.propertyFields}>
            <FormControlLabel
              style={{ paddingLeft: "30px", paddingTop: "40px" }}
              control={
                <Checkbox
                  checked={isArchived}
                  onChange={handleCheckboxChange("isArchived")}
                />
              }
              label="Is Archived?"
            />
          </Grid>
        </Grid>
        <Grid
          style={{ display: "flex" }}
          className={classes.deliveryInstructionsWidth}
        >
          {contracts.length > 1 && (
            <Grid item sm={2}>
              <Tooltip title="Copy to other Contract?" placement="top">
                <FormControlLabel
                  labelPlacement="start"
                  control={
                    <Checkbox
                      id="copyToOtherContract"
                      checked={copyToOtherContract || false}
                      onChange={() => {
                        if (copyToOtherContract === true) {
                          const newDeliverables = {
                            ...myFormValues,
                            copyToContractId: null
                          }
                          setMyFormValues(newDeliverables)
                        }
                        setCopyToOtherContract(!copyToOtherContract)
                      }}
                    />
                  }
                  label="Copy to other Contract?"
                  style={{
                    color: "#212529",
                    marginTop: "20px"
                  }}
                />
              </Tooltip>
              {copyToOtherContract && (
                <FormControl
                  margin="normal"
                  style={{
                    minWidth: "200px",
                    margin: "12px"
                  }}
                >
                  <InputLabel>Select other Contract</InputLabel>
                  <Select
                    fullWidth={false}
                    type="select"
                    name="copyToContractId"
                    id="copyToContractId"
                    value={
                      copyToContractId || myReservoir.copyToContractId || ""
                    }
                    onChange={handleDeliverableChange("copyToContractId")}
                  >
                    <MenuItem key="0" value="0">
                      Select a Contract
                    </MenuItem>
                    {otherContracts &&
                      otherContracts.map(contract => (
                        <MenuItem key={contract.id} value={contract.id}>
                          #{contract.id} -{contract.contractName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
          )}
        </Grid>
        <Grid style={{ display: "flex", justifyContent: "flex-end" }}>
          <GridItem xs={12} sm={6}>
            <DialogActions style={{ marginTop: "14px" }}>
              <Button onClick={() => handleClose()} color="primary">
                Cancel
              </Button>
              <Button
                onClick={() => handleSave()}
                color="primary"
                disabled={saveButtonDisabled}
              >
                Save
              </Button>
            </DialogActions>
          </GridItem>
        </Grid>
      </>

      <div>
        {saveSuccess && (
          <Modal
            open={true}
            onClose={() => {
              setSaveSuccess(false)
              handleAction("closing")
              if (isCopyingDeliverable) {
                handleCopyDeliverable()
              }
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className={classes.successModal}>
              <h2 id="server-modal-title">Success!</h2>
              <div id="server-modal-description">
                <p>{successMessage}</p>
              </div>
            </div>
          </Modal>
        )}
        {saveFailed && (
          <Modal
            open={true}
            onClose={() => {
              setSaveFailed(false)
              handleAction("closing")
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className={classes.errorModal}>
              <h2 id="server-modal-title">Failed!</h2>
              <div id="server-modal-description">
                <p>{successMessage}</p>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </div>
  )
}

ContractDeliverable.propTypes = {
  // Where the user to be redirected on clicking the avatar
  deliverables: PropTypes.object,
  editDeliverable: PropTypes.object,
  hasChildren: PropTypes.array,
  deliverableToEdit: PropTypes.number,
  handleAction: PropTypes.func,
  handleCopyDeliverable: PropTypes.func,
  handleNewDeliverable: PropTypes.func,
  leagueList: PropTypes.array,
  articleTypes: PropTypes.array,
  organizations: PropTypes.array,
  contracts: PropTypes.array,
  contractId: PropTypes.number,
  org: PropTypes.object,
  createdByAccountId: PropTypes.number,
  classes: PropTypes.object,
  subjectTypes: PropTypes.array,
  publishTypes: PropTypes.array,
  catalystTypes: PropTypes.array,
  verticals: PropTypes.array,
  getVerticalName: PropTypes.func
}

export default withStyles(styles)(ContractDeliverable)
