import { List, fromJS } from "immutable"
import { action, observable, decorate } from "mobx"
import AccountStatusDao from "../daos/accountStatusDAO"

export default class AccountStatusStore {
  rootStore
  accountStatuses

  constructor(rootStore) {
    this.rootStore = rootStore
    this.dehydrate()
  }

  /**
   * Computeds
   */

  /**
   * Methods
   */
  getAccountStatuses = async () => {
    try {
      this.setLoading(true)

      const accountStatuses = fromJS(
        await AccountStatusDao.getAccountStatuses()
      )

      this.setAccountStatuses(accountStatuses.get("content", List()))
    } catch (err) {
      console.error("Error", err)
    } finally {
      this.setLoading(false)
    }
  }

  /**
   * Reactions
   */

  /**
   * Internal Actions
   */
  dehydrate() {
    this.setLoading(false)
    this.setAccountStatuses(List())
  }

  setAccountStatuses(value) {
    this.accountStatuses = value.sortBy(o => o.get("status", ""))
  }

  setLoading(value) {
    this.loading = value
  }
}

/**
 * object decorators
 */
decorate(AccountStatusStore, {
  // computeds

  // observables
  loading: observable,
  accountStatuses: observable,

  // actions
  setLoading: action.bound,
  setAccountStatuses: action.bound
})
