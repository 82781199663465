import React, { useMemo, useState } from "react"
import NarrativePreviewControl from "./NarrativePreviewer/NarrativePreviewControl"
import NarrativePreviewDAO from "daos/narrativePreviewDAO"
import Button from "components/CustomButtons/Button"
import GridItem from "components/Grid/GridItem"
import GridContainer from "components/Grid/GridContainer"
import Card from "components/Card/Card"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import makeStyles from "@mui/styles/makeStyles"
import ModelPreviewLink from "./NarrativePreviewer/ModelPreviewLink"
import Helpers from "tools/Helpers"
import { contentSections } from "./Data/contentSections"

const useStyles = makeStyles({
  previewCard: {
    marginTop: "5px",
    paddingTop: "16px"
  },
  previewButton: {
    margin: "16px 0"
  }
})

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    narrative: store.narrativeStore.narrative
  }))
}

const OUTLINE_PREVIEW_SECTIONS = [
  "expiredon",
  "deliveryid",
  "entryid",
  "categories",
  "seotitle",
  "metadescription",
  "seoslug",
  "title",
  "link",
  "summary",
  "introparagraph",
  "content",
  "bottomheading",
  "bottomparagraph"
]

function NarrativeOutlinePreview() {
  const { narrative } = useStoreData()
  const classes = useStyles()
  const [narrativePreviewContent, setNarrativePreviewContent] = useState(null)
  const [isLoadingPreview, setIsLoadingPreview] = useState(false)
  const [previewElapsedTimeMilliseconds, setPreviewElaspedTimeMilliseconds] =
    useState(0)

  const [debugAllTriggers, setDebugAllTriggers] = useState(false)
  const [ignoreKillswitch, setIgnoreKillswitch] = useState(false)

  //Build the urls to our token list and model previews
  const modelPreviewUrl = Helpers.buildModelPreviewUrl({
    narrativeId: narrative.id,
    contentId: narrative.queryId,
    contentType: "query"
  })

  const feedEntryElements = useMemo(
    () =>
      !narrativePreviewContent
        ? contentSections.slice(0, 4)
        : contentSections.filter(section => {
            const showInOutline = OUTLINE_PREVIEW_SECTIONS.includes(section.id)
            const feedValue = narrativePreviewContent[section.renderedProperty]
            return showInOutline && !!feedValue && feedValue !== "null"
          }),
    [narrativePreviewContent]
  )

  const buildFeedPreview = debug => {
    const previewStartDateTime = new Date()
    setIsLoadingPreview(true)
    const previewRequest = {
      narrativeId: narrative.id,
      contentId: narrative.queryId,
      contentType: "query",
      dataModelText: "",
      modelType: narrative.modelType,
      modelTypeId: narrative.narrativeModelType_Id,
      debug: debug === true,
      debugAllTriggers: true,
      ignoreKillswitch: true,
      itemIndex: 0,
      duplicateHandling: 1,
      modelName: null // $TODO Do we want this to ever be not null?
    }
    const getNarrativePreview =
      NarrativePreviewDAO.postPreviewFeedEntry(previewRequest)
    getNarrativePreview
      .then(feedEntry => {
        let narrativePreviewContent = feedEntry.content
        if (narrativePreviewContent) {
          setNarrativePreviewContent({
            ...narrativePreviewContent
          })
        }
        setIsLoadingPreview(false)
        const elapsedTime =
          new Date().getTime() - previewStartDateTime.getTime()
        setPreviewElaspedTimeMilliseconds(elapsedTime)
      })
      .catch(err => {
        console.error(`Error on PUT to /api/narrativepreview/feed-entry`, err)
        setIsLoadingPreview(false)
      })
  }

  return (
    <>
      <Card className={classes.previewCard}>
        <GridContainer>
          <GridItem md={12}>
            <ModelPreviewLink
              contentId={narrative.queryId}
              contentType="query"
              modelName={narrative.queryId}
              modelPreviewUrl={modelPreviewUrl}
            />
          </GridItem>
          <GridItem xs={3}>
            <Button
              className={classes.previewButton}
              size="sm"
              disabled={isLoadingPreview}
              color="primary"
              onClick={() => buildFeedPreview()}
              title="Preview this Narrative based on the selected data model"
            >
              Preview
            </Button>
          </GridItem>
          <GridItem xs={3}>
            <Button
              className={classes.previewButton}
              size="sm"
              disabled={isLoadingPreview}
              color="primary"
              onClick={() => buildFeedPreview(true)}
              title="Load every sentence"
            >
              QA Preview
            </Button>
          </GridItem>
        </GridContainer>
        <GridContainer style={{ marginLeft: "10px" }}>
          <GridItem>
            <FormControlLabel
              control={
                <Switch
                  checked={debugAllTriggers}
                  onChange={event => {
                    setDebugAllTriggers(event.target.checked)
                  }}
                  color="primary"
                  size="small"
                />
              }
              label="All Triggers?"
            />
          </GridItem>
          <GridItem>
            <FormControlLabel
              control={
                <Switch
                  checked={ignoreKillswitch}
                  onChange={event => {
                    setIgnoreKillswitch(event.target.checked)
                  }}
                  color="primary"
                  size="small"
                />
              }
              label="Ignore Kill Switches?"
            />
          </GridItem>
        </GridContainer>
        <NarrativePreviewControl
          activeElements={feedEntryElements}
          renderedNarrative={{ ...narrativePreviewContent }}
          renderTimeInMilliseconds={previewElapsedTimeMilliseconds}
          isLoadingPreview={isLoadingPreview}
        />
      </Card>
    </>
  )
}

export default NarrativeOutlinePreview
