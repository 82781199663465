import PropTypes from "prop-types"
import {
  Box,
  FormControlLabel,
  IconButton,
  LinearProgress,
  Radio,
  RadioGroup,
  Tooltip,
  Typography
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { Clear, FindReplace, Search as SearchIcon } from "@mui/icons-material"

import GreyTextInput from "components/styled/GreyTextInput"
import { lightTeal } from "variables/colors"

const useStyles = makeStyles(theme => ({
  root: {
    background: "#fff",
    border: "2px solid rgba(0, 0, 0, 0.2)"
  },
  header: {
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
    backgroundColor: lightTeal
  },
  controlRow: {
    display: "flex",
    gap: theme.spacing(1),
    padding: 5,
    "&:not(:last-child)": {
      borderBottom: "2px solid rgba(0, 0, 0, 0.2)"
    }
  },
  radioGroup: {
    margin: 0,
    color: "#999999",
    flexWrap: "nowrap",
    "& > *": {
      margin: 0
    }
  },
  iconButton: {
    padding: theme.spacing(1)
  }
}))

// Similar to SearchBlock.jsx but plenty of differences
// Eventually we may just want to have one
const FindAndReplaceControls = ({
  headerText = "",
  searchFields,
  onClear,
  onSearch,
  onReplace,
  replaceString,
  submittedSearchString,
  searchString,
  selectOnlyOneField,
  selectedFields,
  setReplaceString,
  setSearchString,
  isSearching
}) => {
  const disableSearchButton =
    !searchString || submittedSearchString === searchString

  const disableReplaceButton = !!submittedSearchString

  const onChangeSearch = e => {
    setSearchString(e.target.value)
  }

  const onClickSearch = () => {
    onSearch(searchString)
  }

  const classes = useStyles()
  const selectedField = selectedFields[0]

  const onClickReplace = () => {
    if (searchString) {
      onReplace(selectedField, searchString, replaceString)
    }
  }

  return (
    <Box className={classes.root}>
      {headerText && (
        <Box className={`${classes.controlRow} ${classes.header}`}>
          <Typography variant="subtitle2">{headerText}</Typography>
        </Box>
      )}
      <Box className={classes.controlRow}>
        <RadioGroup
          row
          value={selectedField}
          className={classes.radioGroup}
          onChange={e => selectOnlyOneField(e.target.value)}
        >
          {searchFields.map(({ value, label }, index) => (
            <FormControlLabel
              key={index}
              control={<Radio value={value} />}
              label={label}
            />
          ))}
        </RadioGroup>
        <GreyTextInput
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          value={searchString}
          onChange={onChangeSearch}
        />
        <Tooltip id="tooltip-top" title="Search" placement="top">
          <Box component="span">
            <IconButton
              className={classes.iconButton}
              aria-label="Search"
              onClick={onClickSearch}
              disabled={disableSearchButton}
              size="large"
            >
              <SearchIcon />
            </IconButton>
          </Box>
        </Tooltip>
        <Tooltip
          id="tooltip-top-clear"
          title="Clear Fields And Filters"
          placement="top"
        >
          <IconButton
            className={classes.iconButton}
            aria-label="Clear Fields And Filters"
            onClick={onClear}
            size="large"
          >
            <Clear />
          </IconButton>
        </Tooltip>
      </Box>
      <Box className={classes.controlRow}>
        <GreyTextInput
          placeholder="Replace With…"
          inputProps={{ "aria-label": "replace" }}
          value={replaceString}
          onChange={e => {
            if (searchString) {
              setReplaceString(e.target.value)
            }
          }}
        />
        <Tooltip
          id="tooltip-top"
          title="Replace In Selected Items"
          placement="top"
        >
          <Box>
            <IconButton
              className={classes.iconButton}
              aria-label="Replace In Selected Items"
              onClick={onClickReplace}
              disabled={disableReplaceButton}
              size="large"
            >
              <FindReplace />
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
      <>{isSearching && <LinearProgress />}</>
    </Box>
  )
}

FindAndReplaceControls.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onReplace: PropTypes.func.isRequired,
  isSearching: PropTypes.bool,
  submittedSearchString: PropTypes.string
}

export default FindAndReplaceControls
