import { List, fromJS } from "immutable"
import { action, observable, decorate } from "mobx"
import OrganizationDao from "../daos/organizationDAO"

export default class OrganizationStore {
  rootStore
  loading
  organizations
  narratives
  feeds
  organizationId = ""
  organizationProperties = []
  organization
  propertyId
  orgAndPropId
  contractDeliverables
  subjectTypes
  publishTypes
  catalystTypes
  webhookByOrg

  constructor(rootStore) {
    this.rootStore = rootStore
    this.dehydrate()
  }

  /**
   * Reactions
   */
  getOrganizations = async () => {
    try {
      this.setLoading(true)

      const organizations = fromJS(await OrganizationDao.getOrganizations())

      this.setOrganizations(organizations.get("content", List()))
      return organizations.toJS()?.content
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoading(false)
    }
  }

  getNarratives = async orgId => {
    try {
      this.setLoading(true)

      const narratives = fromJS(await OrganizationDao.getNarratives(orgId))

      this.setNarratives(narratives.get("content", List()))
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoading(false)
    }
  }

  getFeeds = async orgId => {
    try {
      this.setLoading(true)
      const feeds = fromJS(await OrganizationDao.getFeeds(orgId))

      this.setFeeds(feeds.get("content", List()))
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoading(false)
    }
  }

  getContractDeliverablesForSchedule = async (organizationId, leagueId) => {
    try {
      this.setLoading(true)
      const contractDeliverables = fromJS(
        await OrganizationDao.getContractDeliverablesForSchedule(
          organizationId,
          leagueId
        )
      )

      this.setContractDeliverablesForSchedule(
        contractDeliverables.get("content", List())
      )
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoading(false)
    }
  }

  postContractDeliverable = async contractDeliverable => {
    try {
      this.setLoading(true)
      const newContractDeliverable = fromJS(
        await OrganizationDao.postContractDeliverable(contractDeliverable)
      )
      if (newContractDeliverable) {
        this.getContractDeliverablesForSchedule(
          contractDeliverable.organizationId,
          contractDeliverable.leagueId
        )
      }
    } catch (err) {
      console.error("Error: ", err)
      throw err
    } finally {
      this.setLoading(false)
    }
  }

  getSubjectType = async () => {
    try {
      this.setLoading(true)
      const subjectTypes = fromJS(await OrganizationDao.getSubjectType())

      this.setSubjectType(subjectTypes.get("content", List()))
    } catch (e) {
      console.log("Error", e)
    } finally {
      this.setLoading(false)
    }
  }

  getPublishType = async () => {
    try {
      this.setLoading(true)
      const publishTypes = fromJS(await OrganizationDao.getPublishType())

      this.setPublishType(publishTypes.get("content", List()))
    } catch (e) {
      console.log("Error", e)
    } finally {
      this.setLoading(false)
    }
  }

  getCatalystType = async () => {
    try {
      this.setLoading(true)
      const catalystTypes = fromJS(await OrganizationDao.getCatalystType())

      this.setCatalystType(catalystTypes.get("content", List()))
    } catch (e) {
      console.log("Error", e)
    } finally {
      this.setLoading(false)
    }
  }

  getOutboundWebhookByOrg = async organizationId => {
    try {
      this.setLoading(true)
      const webhookByOrg = fromJS(
        await OrganizationDao.getOutboundWebhookByOrg(organizationId)
      )

      this.setWebhookByOrg(webhookByOrg.get("content", List()))
    } catch (e) {
      console.log("Error in WebhookByOrg", e)
    } finally {
      this.setLoading(false)
    }
  }
  /**
   * Internal Actions
   */
  dehydrate() {
    this.setLoading(false)
    this.setOrganizations(List())
    this.setNarratives(List())
  }

  setOrganizations(value) {
    this.organizations = value.sortBy(o => o.get("name", ""))
  }

  setOrganizationId(value) {
    this.organizationId = value
  }

  setOrganizationProperties(value) {
    this.organizationProperties = value
  }

  setNarratives(value) {
    this.narratives = value.sortBy(o => o.get("name", ""))
  }

  setFeeds(value) {
    this.feeds = value.sortBy(o => o.get("name", ""))
  }

  setLoading(value) {
    this.loading = value
  }

  setOrganization(value) {
    this.organization = value
  }

  setPropertyId(value) {
    this.propertyId = value
  }

  setOrgAndPropId(value) {
    this.orgAndPropId = value
  }

  setContractDeliverablesForSchedule(value) {
    this.contractDeliverables = value
  }

  setSubjectType(value) {
    this.subjectTypes = value
  }

  setPublishType(value) {
    this.publishTypes = value
  }

  setCatalystType(value) {
    this.catalystTypes = value
  }

  setWebhookByOrg(value) {
    this.webhookByOrg = value
  }
}

decorate(OrganizationStore, {
  // observables
  loading: observable,
  organizations: observable,
  narratives: observable,
  feeds: observable,
  organizationId: observable,
  organizationProperties: observable,
  organization: observable,
  propertyId: observable,
  contractDeliverables: observable,
  subjectTypes: observable,
  publishTypes: observable,
  catalystTypes: observable,
  webhookByOrg: observable,
  orgAndPropId: observable,

  // actions
  setLoading: action.bound,
  setOrganizations: action.bound,
  setNarratives: action.bound,
  setFeeds: action.bound,
  setOrganizationId: action.bound,
  setOrganizationProperties: action.bound,
  setOrganization: action.bound,
  setPropertyId: action.bound,
  setContractDeliverablesForSchedule: action.bound,
  setSubjectType: action.bound,
  setPublishType: action.bound,
  setCatalystType: action.bound,
  setWebhookByOrg: action.bound,
  setOrgAndPropId: action.bound,
  getContractDeliverablesForSchedule: action.bound
})
