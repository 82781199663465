import React from "react"
import PropTypes from "prop-types"

function ReplicaDependency(props) {
  return (
    <svg
      id="replica_dependency"
      xmlns="http://www.w3.org/2000/svg"
      height={props.height || "20px"}
      width={props.width || "20px"}
      viewBox="0 0 24 24"
      style={{ marginLeft: "-7px" }}
    >
      <path
        d="M7.8,15.6L0,7.8,7.8,0l1.85,1.85L3.67,7.83l5.95,5.95-1.82,1.82ZM18.2,15.6l-1.85-1.85,5.98-5.98-5.95-5.95,1.82-1.82,7.8,7.8-7.8,7.8Z"
        fill={props.fillColor || "#666"}
        strokeWidth="0"
      />
      <path
        d="M8.87,14.95c-.13,0-.17-.04-.17-.17V1.37c0-.11.02-.19.15-.19,1.66-.04,2.54-.06,4.16-.06,3.36,0,5.33,1.45,5.33,4.14,0,1.83-1.18,3.13-2.71,3.7.69.97,3.21,4.72,3.82,5.77.06.13.08.23-.04.23h-2.62c-.17,0-.23-.04-.29-.1-.61-.95-2.08-3.36-3-4.83-.15-.25-.25-.36-.48-.36h-1.81v5.12c0,.13-.06.17-.17.17h-2.16ZM13.03,7.5c1.7,0,2.79-.76,2.79-2.1s-1.11-2.08-2.9-2.08c-.71,0-1.39.02-1.72.04v4.14h1.83Z"
        strokeWidth="0"
      />
    </svg>
  )
}
ReplicaDependency.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fillColor: PropTypes.string
}
export default ReplicaDependency
