import React from "react"
import cx from "classnames"
import PropTypes from "prop-types"
import { NavLink } from "react-router-dom"
import withStyles from "@mui/styles/withStyles"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Drawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Menu from "@mui/icons-material/Menu"

import Button from "components/CustomButtons/Button"
import pagesRoutes from "routes/pages"
import pagesHeaderStyle from "assets/jss/material-dashboard-pro-react/components/pagesHeaderStyle"
import Box from "@mui/material/Box"

class PagesHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }
  handleDrawerToggle = () => {
    this.setState({ open: !this.state.open })
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.setState({ open: false })
    }
  }
  render() {
    const { classes, color } = this.props
    const appBarClasses = cx({
      [` ${classes[color]}`]: color
    })
    let list = (
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          {/*
          <NavLink to={"/dashboard"} className={classes.navLink}>
            <ListItemIcon className={classes.listItemIcon}>
              <Dashboard />
            </ListItemIcon>
            <ListItemText
              primary={"Dashboard"}
              disableTypography={true}
              className={classes.listItemText}
            />
          </NavLink>
        */}
        </ListItem>
        {pagesRoutes.map((prop, key) => {
          if (prop.redirect || prop.hiddenInNav) {
            return null
          }
          const navLink =
            classes.navLink +
            cx({
              [` ${classes.navLinkActive}`]: this.activeRoute(prop.path)
            })
          return (
            <ListItem key={key} className={classes.listItem}>
              <NavLink to={prop.path} className={navLink}>
                <ListItemIcon className={classes.listItemIcon}>
                  <prop.icon />
                </ListItemIcon>
                <ListItemText
                  primary={prop.short}
                  disableTypography={true}
                  className={classes.listItemText}
                />
              </NavLink>
            </ListItem>
          )
        })}
      </List>
    )
    return (
      <AppBar position="static" className={classes.appBar + appBarClasses}>
        <Toolbar className={classes.container}>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <div className={classes.flex}>
              <Button
                href="/portal"
                className={classes.title}
                color="transparent"
              >
                Data Skrive Portal
              </Button>
            </div>
          </Box>
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            <div className={classes.flex}>
              <Button
                href="/portal"
                className={classes.title}
                color="transparent"
              >
                Data Skrive Portal
              </Button>
            </div>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "block" } }}>{list}</Box>
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            <Button
              className={classes.sidebarButton}
              color="transparent"
              justIcon
              aria-label="open drawer"
              onClick={this.handleDrawerToggle}
            >
              <Menu />
            </Button>
          </Box>
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={this.state.open}
              classes={{
                paper: classes.drawerPaper
              }}
              onClose={this.handleDrawerToggle}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
            >
              {list}
            </Drawer>
          </Box>
        </Toolbar>
      </AppBar>
    )
  }
}

PagesHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"])
}

export default withStyles(pagesHeaderStyle)(PagesHeader)
