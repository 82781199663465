import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import withStyles from "@mui/styles/withStyles"
import Assignment from "@mui/icons-material/Assignment"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon"
import CardHeader from "components/Card/CardHeader"
import { cardTitle } from "assets/jss/material-dashboard-pro-react"
import Modal from "@mui/material/Modal"
import Button from "components/CustomButtons/Button"
import Tooltip from "@mui/material/Tooltip"
import Edit from "@mui/icons-material/Edit"
import ReactTable from "react-table-6"
import LinearProgress from "@mui/material/LinearProgress"
import AppBar from "@mui/material/AppBar"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import Checkbox from "@mui/material/Checkbox"
import Switch from "@mui/material/Switch"
import Grid from "@mui/material/Grid"
import DialogTitle from "@mui/material/DialogTitle"
import TextField from "@mui/material/TextField"
import FormHelperText from "@mui/material/FormHelperText"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import InputLabel from "@mui/material/InputLabel"
import AffiliateContract from "components/Affiliate/AffiliateContract"
import AffiliatesShortCodeTable from "components/Affiliate/AffiliatesShortCodeTable.jsx"
import AffiliateDAO from "daos/affiliateDAO"
import Add from "@mui/icons-material/Add"
import Dialog from "components/Dialog"
import AffiliatesShortCodeForm from "components/Affiliate/AffiliatesShortCodeForm"

import queryString from "query-string"
import { useHistory } from "react-router-dom"

//import { TabPanel } from "components/TabPanel/TabPanel"
import Helpers from "tools/Helpers.js"

import { globalSettings } from "variables/general.jsx"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
// import {
//   get
//   //put
// } from "tools/request"
import { useStore } from "contexts/rootContext"
import { useObserver } from "mobx-react"

dayjs.extend(utc)

const styles = theme => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  loading: {
    position: "absolute",
    width: "97%",
    top: "0",
    right: "0",
    minHeight: "10px",
    borderRadius: "4px",
    overflow: "hidden",
    zIndex: "-1",
    "& > div": {
      height: "10px"
    }
  },
  requiredMsg: {
    color: "#c00",
    fontWeight: "bold",
    backgroundColor: "#f7f7f7"
  },
  contractSelect: {
    minWidth: 180
  },
  tabScrollRoot: {
    [theme.breakpoints.down(600)]: {
      "& .MuiTabs-scrollButtonsDesktop": {
        display: "flex !important"
      },
      "& .MuiTab-wrapper": {
        fontSize: "12px"
      }
    }
  },
  noShortCodeEntries: { textAlign: "center", padding: "20px" },
  successModal: {
    position: "absolute",
    color: "#069",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    transform: "translate(-45%, -45%)",
    "& h3": {
      fontWeight: "bold"
    },
    "& ul": {
      padding: "5px 0 0 5px",
      fontWeight: "bold"
    },
    [theme.breakpoints.up(701)]: {
      width: 400,
      top: "45%",
      left: "45%"
    },
    [theme.breakpoints.down(700)]: {
      width: "70vw",
      top: "50vh",
      left: "50vw"
    }
  },
  errorModal: {
    position: "absolute",
    color: "#c00",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    transform: "translate(-45%, -45%)",
    "& h3": {
      fontWeight: "bold"
    },
    "& ul": {
      padding: "5px 0 0 5px",
      fontWeight: "bold",
      textDecoration: "underline"
    },
    [theme.breakpoints.up(701)]: {
      width: 400,
      top: "45%",
      left: "45%"
    },
    [theme.breakpoints.down(700)]: {
      width: "70vw",
      top: "50vh",
      left: "50vw"
    }
  }
})

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    callToActionAffiliate: store.affiliateStore.affiliate,
    getAffiliate: store.affiliateStore.getAffiliate,
    callToActionAffiliateOrganization:
      store.affiliateStore.affiliateOrganizations,
    getAffiliateOrganizations: store.affiliateStore.getAffiliateOrganizations,
    callToActionAffiliateContract: store.affiliateStore.affiliateContract,
    getAffiliateContract: store.affiliateStore.getAffiliateContract,
    companiesInfo: store.ctav2Store.companiesInfo,
    getCompaniesInfo: store.ctav2Store.getCompaniesInfo,
    organizations: store.ctav2Store.organizationsInfo,
    getOrganizations: store.ctav2Store.getOrganizationsInfo,
    accounts: store.accountStore.accounts,
    getAccounts: store.accountStore.getAccounts,
    shortCodeByAffiliate: store.ctav2Store.shortCodeByAffiliate,
    getShortCodeByAffiliate: store.ctav2Store.getShortCodeByAffiliate,
    getCTAManagerConfig: store.appConfigurationStore.getCTAManagerConfig,
    shouldDisplayCTAManagerV2:
      store.appConfigurationStore.shouldDisplayCTAManagerV2
  }))
}

function Affiliates(props) {
  const history = useHistory()
  const { classes } = props
  const formErrors = props.formErrors || {}

  const [isLoading, setIsLoading] = React.useState(false)
  const [myContract, setMyContract] = useState({})

  const [allLoading, setAllLoading] = useState(false)
  const [affiliateInfo, setAffiliateInfo] = useState([])
  const [organizationContractAffiliate, setOrganizationContractAffiliate] =
    useState([])

  const [shortCodeContent, setShortCodeContent] = useState([])
  const [showArchivedShortCodes, setShowArchivedShortCodes] = useState(false)
  const [emptyMessage, setEmptyMessage] = useState("Loading .....")
  const [currentView, setCurrentView] = useState(0)
  const [currentTabView, setCurrentTabView] = useState(1)
  const [isAddingContract, setIsAddingContract] = useState(false)
  const [chosenAffiliate, setChosenAffiliate] = useState({})
  const [saveFailed, setSaveFailed] = useState(false)
  const [loadFailed, setLoadFailed] = useState(false)
  const [saveSuccess, setSaveSuccess] = useState(false)
  const [showingShortCodesList, setShowingShortCodesList] = useState(false)
  const [showNewShortCodeForm, setShowNewShortCodeForm] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [justCreatedContract, setJustCreatedContract] = useState(0)
  const {
    companiesInfo,
    getCompaniesInfo,
    organizations,
    getOrganizations,
    callToActionAffiliate,
    getAffiliate,
    callToActionAffiliateOrganization,
    getAffiliateOrganizations,
    callToActionAffiliateContract,
    getAffiliateContract,
    accounts,
    getAccounts,
    getCTAManagerConfig,
    shouldDisplayCTAManagerV2,
    shortCodeByAffiliate,
    getShortCodeByAffiliate
  } = useStoreData()

  const handleCellChange = name => event => {
    const newAffiliate = {
      ...chosenAffiliate,
      [name]: event.target.value,
      editingAffiliate: true
    }
    setChosenAffiliate(newAffiliate)
  }

  const handleCheckboxChange = name => () => {
    const newAffiliate = {
      ...chosenAffiliate,
      [name]: !chosenAffiliate[name],
      editingAffiliate: true
    }
    setChosenAffiliate(newAffiliate)
  }

  const resetAffiliate = () => {
    let resetAff = {}
    affiliateInfo.forEach(itm => {
      if (itm.affiliateId === chosenAffiliate.affiliateId) {
        resetAff.isActive = itm.isActive
        resetAff.affiliateName = itm.affiliateName
        resetAff.affiliateId = itm.affiliateId
      }
    })
    const newAffiliate = {
      ...resetAff,
      editingAffiliate: false
    }
    setChosenAffiliate(newAffiliate)
    setIsAddingContract(false)
  }

  const saveAffiliate = () => {
    const newAffiliate = {
      name: chosenAffiliate.affiliateName,
      description: chosenAffiliate.affiliateName,
      isActive: chosenAffiliate.isActive
    }
    const oldUrl = chosenAffiliate.affiliateId
      ? `${globalSettings.maynardBaseUrl}/api/CallToActionAffiliate/${chosenAffiliate.affiliateId}`
      : `${globalSettings.maynardBaseUrl}/api/CallToActionAffiliate`

    if (shouldDisplayCTAManagerV2) {
      //put(url, null, newAffiliate)
      if (!chosenAffiliate.affiliateId) {
        AffiliateDAO.saveNewAffiliate(newAffiliate)
          .then(response => {
            if (typeof response === "object") {
              updateAffiliateList(response)
              setSaveSuccess(true)
              setSuccessMessage(
                `Affiiate "${response.name}" with ID="${response.id}" has been created`
              )
              addNewAffiliate(response)
              if (response.id) {
                history.push(`/portal/affiliates?affiliateId=${response.id}`)
              }
            } else {
              setSaveFailed(true)
              setSuccessMessage(`Save did not succeeed`)
            }
          })
          .catch(err => {
            console.error("Error on fetch to /api/affiliate/id create new", err)
            setSaveFailed(true)
            setSuccessMessage(err)
          })
      } else {
        AffiliateDAO.updateAffiliate(newAffiliate, chosenAffiliate.affiliateId)
          .then(response => {
            if (chosenAffiliate.affiliateId && typeof response === "number") {
              updateAffiliateList({
                ...newAffiliate,
                id: chosenAffiliate.affiliateId
              })
              setSaveSuccess(true)
              setSuccessMessage(
                `Affiiate "${newAffiliate.name}" with ID="${response}" has been updated`
              )
            } else {
              setSaveFailed(true)
              setSuccessMessage(`Save did not succeeed`)
            }
          })
          .catch(err => {
            console.error("Error on fetch to /api/affiliate update", err)
            setSaveFailed(true)
            setSuccessMessage(err)
          })
      }
    } else {
      fetch(oldUrl, {
        credentials: "include",
        method: chosenAffiliate.affiliateId ? "put" : "post",
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
          "X-API-Key": `${globalSettings.maynardApiKey}`
        },
        body: JSON.stringify({
          ...newAffiliate
        })
      })
        .then(Response => Response && Response.ok === true && Response.json())
        .then(response => {
          if (typeof response === "object") {
            updateAffiliateList(response)
            setSaveSuccess(true)
            setSuccessMessage(
              `Affiiate "${response.name}" with ID="${response.id}" has been created`
            )
            addNewAffiliate(response)
            if (response.id) {
              history.push(`/portal/affiliates?affiliateId=${response.id}`)
            }
          } else if (
            chosenAffiliate.affiliateId &&
            typeof response === "number"
          ) {
            updateAffiliateList({
              ...newAffiliate,
              id: chosenAffiliate.affiliateId
            })
            setSaveSuccess(true)
            setSuccessMessage(
              `Affiiate "${newAffiliate.name}" with ID="${response}" has been updated`
            )
          } else {
            setSaveFailed(true)
            setSuccessMessage(`Save did not succeeed`)
          }
        })
        .catch(err => {
          console.error("Error on fetch to /api/CallToActionAffiliate", err)
          setSaveFailed(true)
          setSuccessMessage(err)
        })
    }
  }

  const onEditAffiliatesButtonClicked = cell => {
    setCurrentView(2)
    setShowingShortCodesList(false)
    if (cell.row?.affiliateId) {
      getShortCodeByAffiliate(cell.row.affiliateId)
    }
    setCurrentTabView(1)
    setChosenAffiliate(cell.original)
    setIsAddingContract(false)
    setMyContract({})
    setShortCodeContent([])
  }
  const onAddAffiliateButtonClicked = cell => {
    setCurrentView(1)
    setCurrentTabView(1)
    setChosenAffiliate({ isActive: true })
    setIsAddingContract(false)
  }

  const handleTabChange = (event, newValue) => {
    setCurrentTabView(newValue)
    setShowingShortCodesList(false)
    if (newValue === 1) {
      setCurrentView(2)
      if (Object.keys(myContract).length > 0) {
        setMyContract({})
      }

      getShortCodeByAffiliate(chosenAffiliate.affiliateId)
    } else if (newValue === 0) {
      setCurrentView(0)
      if (Object.keys(myContract).length > 0) {
        setMyContract({})
      }
      history.push(`/portal/affiliates`)
    } else if (newValue === 2) {
      setCurrentView(3)
      setShowingShortCodesList(true)
      if (myContract?.callToActionaffiliateId) {
        //getShortCodeByAffiliate(myContract.callToActionaffiliateId)
      }
    }
    setJustCreatedContract(0)
  }

  const getUserName = id => {
    let nm = ""
    //accountInfo.forEach(itm => {
    accounts.forEach(itm => {
      if (itm.id === id) {
        nm = itm.username
      }
    })
    return nm
  }

  const updateContracts = itm => {
    let contractList = [...callToActionAffiliateContract]
    let found = false
    contractList.forEach(ctr => {
      if (ctr.id === itm.id) {
        found = true
        Object.entries(ctr).forEach(([key, value]) => {
          if (itm[key] !== undefined) {
            ctr[key] = itm[key]
          }
        })
      }
    })
    if (!found) {
      contractList.push(itm)
    }
    //setCallToActionAffiliateContract(contractList)
    setIsAddingContract(false)
  }

  const updateAssociatedOrganization = itm => {
    let affiliateOrgList = callToActionAffiliateOrganization
    let found = false
    affiliateOrgList.forEach(aol => {
      if (aol.id === itm.id) {
        found = true
        Object.entries(aol).forEach(([key, value]) => {
          if (itm[key] !== undefined) {
            aol[key] = itm[key]
          }
        })
      }
    })
    if (!found) {
      affiliateOrgList.push(itm)
    }
    //setCallToActionAffiliateOrganization(affiliateOrgList)
  }

  const updateAffiliateList = itm => {
    let affiliateList = callToActionAffiliate
    let found = false
    affiliateList.forEach(al => {
      if (al.id === itm.id) {
        found = true
        Object.entries(al).forEach(([key, value]) => {
          if (itm[key] !== undefined) {
            al[key] = itm[key]
          }
        })
      }
    })
    if (!found) {
      affiliateList.push(itm)
    }
    let info = []
    affiliateList.forEach(itm => {
      info.push({
        affiliateId: itm.id,
        affiliateName: itm.name,
        status: itm.isActive === false ? "Inactive" : "Active",
        isActive: itm.isActive,
        auditInfo: {
          modifiedOn: itm.modifiedOn,
          createdOn: itm.createdOn,
          modifier: {
            username: getUserName(itm.modifier)
          },
          creator: {
            username: getUserName(itm.creator)
          }
        }
      })
    })
    setAffiliateInfo(info)
    //setCallToActionAffiliate(affiliateList)
    const newAffiliate = {
      ...chosenAffiliate,
      editingAffiliate: false
    }
    setChosenAffiliate(newAffiliate)
  }

  const addNewAffiliate = itm => {
    let info = affiliateInfo
    info.push({
      affiliateId: itm.id,
      affiliateName: itm.name,
      status: itm.isActive === false ? "Inactive" : "Active",
      isActive: itm.isActive,
      auditInfo: {
        modifiedOn: itm.modifiedOn,
        createdOn: itm.createdOn,
        modifier: {
          username: getUserName(itm.modifier)
        },
        creator: {
          username: getUserName(itm.creator)
        }
      }
    })
    setAffiliateInfo(info)
    setChosenAffiliate({
      ...itm,
      affiliateId: itm.id,
      affiliateName: itm.name,
      status: itm.isActive === false ? "Inactive" : "Active",
      editingAffiliate: false
    })
  }

  useEffect(() => {
    if (
      callToActionAffiliateOrganization?.length &&
      callToActionAffiliate?.length &&
      callToActionAffiliateContract
    ) {
      let organizationContractAffiliate = []
      callToActionAffiliateOrganization.forEach(itm => {
        let orgId = itm.organizationId
        let contractId = itm.callToActionAffiliateContractId
        callToActionAffiliateContract.forEach(itm2 => {
          if (itm2.id === contractId) {
            organizationContractAffiliate.push({
              organizationId: orgId,
              contractId: contractId,
              affiliateId: itm2.callToActionaffiliateId,
              affiliateOrganizationId: itm.id
            })
          }
        })
      })
      setOrganizationContractAffiliate(organizationContractAffiliate)

      setIsLoading(false)
      let info = []
      callToActionAffiliate.forEach(itm => {
        info.push({
          affiliateId: itm.id,
          affiliateName: itm.name,
          status: itm.isActive === false ? "Inactive" : "Active",
          isActive: itm.isActive,
          auditInfo: {
            modifiedOn: itm.modifiedOn,
            createdOn: itm.createdOn,
            modifier: {
              username: getUserName(itm.modifier)
            },
            creator: {
              username: getUserName(itm.creator)
            }
          }
        })
      })
      setAffiliateInfo(info)
      const queryParams = queryString.parse(
        window.location.search.toLowerCase()
      )
      if (queryParams["affiliateid"]) {
        queryToAffiliateInfo(info, Number(queryParams["affiliateid"]))
      }
    }
  }, [
    callToActionAffiliate,
    callToActionAffiliateOrganization,
    callToActionAffiliateContract
  ])

  useEffect(() => {
    const fetchConfig = async () => {
      await getCTAManagerConfig()
      if (shouldDisplayCTAManagerV2 !== null && !allLoading) {
        const queryParams = queryString.parse(
          window.location.search.toLowerCase()
        )
        if (
          Number(queryParams["affiliateid"]) &&
          Number(queryParams["affiliateid"]) > 0
        ) {
          getShortCodeByAffiliate(Number(queryParams["affiliateid"]))
        }
        setAllLoading(true)
        getOrganizations()
        getAccounts()
        getCompaniesInfo()
        getAffiliate()
        getAffiliateOrganizations()
        getAffiliateContract()
      }
    }
    fetchConfig()
  }, [shouldDisplayCTAManagerV2])

  useEffect(() => {
    if (shortCodeByAffiliate.length) {
      const sca = []
      //let count = 1
      //const loadedSourceIds = []
      shortCodeByAffiliate.forEach(x => {
        if (!x.baseLink) {
          x.baseLink =
            "https://affiliates.routy.app/route/[shortcode]?affId=3147"
        }
        sca.push(x)
      })
      setShortCodeContent(sca)
      setShowingShortCodesList(true)
      //setHaveCodeEditorForAffiliate(chosenAffiliate.affiliateId)
      if (sca.length > 0) {
        setEmptyMessage("Loading......")
      } else {
        setEmptyMessage("There are no Entries")
      }
      //console.log(sca)
    } else {
      setEmptyMessage(
        `There are no Short Code Entries for this Affiliate (${chosenAffiliate?.affiliateName})`
      )
      setShowingShortCodesList(true)
      //setHaveCodeEditorForAffiliate(chosenAffiliate.affiliateId)
    }
  }, [shortCodeByAffiliate])

  const queryToAffiliateInfo = (info, id) => {
    if (!id || typeof id !== "number") {
      return
    }
    // let matchInfo = null
    info.forEach(itm => {
      if (id === itm.affiliateId) {
        let cell = {}
        cell.original = itm
        onEditAffiliatesButtonClicked(cell)
      }
    })
  }

  const handleShortCodeArchiveChekcboxChange = event => {
    setShowArchivedShortCodes(!showArchivedShortCodes)
  }

  const affiliateNameHasError = false

  const { currentContract } = myContract

  const selectedAffiliateContracts =
    (callToActionAffiliateContract &&
      callToActionAffiliateContract.filter(
        c => c.callToActionaffiliateId === chosenAffiliate.affiliateId
      )) ||
    []

  const associatedOrganizationsForThisAffiliate =
    callToActionAffiliateOrganization?.filter(
      org => org.callToActionAffiliateContractId === myContract.id
    )

  if (
    selectedAffiliateContracts.length === 1 &&
    Object.keys(myContract).length < 2 &&
    !isAddingContract
  ) {
    const newContract = {
      ...selectedAffiliateContracts[0],
      allContracts: selectedAffiliateContracts,
      currentContract: 0
    }
    setMyContract(newContract)
  } else if (
    selectedAffiliateContracts.length > 1 &&
    Object.keys(myContract).length < 2 &&
    !isAddingContract &&
    !myContract.loadedContractInfo
  ) {
    let contractNumber = false
    let latestDate
    selectedAffiliateContracts.forEach((itm, index) => {
      if (contractNumber === false) {
        contractNumber = index
        latestDate = itm.startDate
      } else {
        let d1 = new Date(latestDate)
        let d2 = new Date(itm.startDate)
        if (d2 > d1) {
          latestDate = itm.startDate
          contractNumber = index
        }
      }
    })

    const newContract = {
      ...selectedAffiliateContracts[contractNumber],
      allContracts: selectedAffiliateContracts,
      currentContract: contractNumber
    }
    setMyContract(newContract)
  } else if (
    selectedAffiliateContracts.length > 1 &&
    !isAddingContract &&
    justCreatedContract
  ) {
    let contractNumber = null
    selectedAffiliateContracts.forEach((itm, index) => {
      if (itm.id === justCreatedContract) {
        contractNumber = index
      }
    })
    if (contractNumber && myContract.currentContract !== contractNumber) {
      const newContract = {
        ...selectedAffiliateContracts[contractNumber],
        allContracts: selectedAffiliateContracts,
        currentContract: contractNumber
      }
      setMyContract(newContract)
    }
  }

  React.useEffect(() => {
    if (typeof props.getDatasources === "function") {
      props.getDatasources()
    }
    if (typeof props.getBookmakers === "function") {
      props.getBookmakers()
    }
  }, [])

  const createShortCode = () => {
    setShowNewShortCodeForm(true)
  }

  return (
    <>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon={true}>
              {isLoading && (
                <div className={classes.loading}>
                  <LinearProgress />
                </div>
              )}
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Affiliates</h4>
            </CardHeader>
            <CardBody>
              {currentView === 0 && (
                <div>
                  <Button
                    size="sm"
                    variant="contained"
                    color="primary"
                    onClick={() => onAddAffiliateButtonClicked()}
                  >
                    Add Affiliate
                  </Button>
                </div>
              )}
              {currentView === 0 && affiliateInfo.length > 0 && (
                <ReactTable
                  data={affiliateInfo}
                  columns={[
                    {
                      Header: "Affiliate ID",
                      accessor: "affiliateId",
                      width: 200
                    },
                    {
                      Header: "Actions",
                      headerClassName: classes.centerColumns,
                      Cell: cell => (
                        <div style={{ display: "flex" }}>
                          <Tooltip title="Edit" placement="top">
                            <Button
                              color="primary"
                              size="sm"
                              justIcon={true}
                              round={true}
                              simple={true}
                              onClick={() => {
                                onEditAffiliatesButtonClicked(cell)
                                let id = cell?.original?.affiliateId
                                if (id) {
                                  history.push(
                                    `/portal/affiliates?affiliateId=${id}`
                                  )
                                }
                              }}
                            >
                              <Edit />
                            </Button>
                          </Tooltip>
                        </div>
                      ),
                      sortable: false,
                      filterable: false,
                      width: 100
                    },
                    {
                      Header: "Affiliate Name",
                      accessor: "affiliateName",
                      width: 200
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                      width: 200
                    },
                    {
                      Header: "Audit Info",
                      accessor: "auditInfo.modifiedOn",
                      headerClassName: classes.centerColumns,
                      Cell: cell => Helpers.renderAuditInfoCell(cell),
                      width: 250,
                      filterMethod: (filter, row) => {
                        if (filter.value === "all") {
                          return true
                        }
                        let futureTime = new Date()
                        let pastTime = new Date()
                        let modifiedOn = new Date(
                          row._original.auditInfo.modifiedOn
                        )
                        if (filter.value === "within30days") {
                          return (
                            modifiedOn >
                              pastTime.setHours(
                                pastTime.getHours() - 24 * 30
                              ) &&
                            modifiedOn <
                              futureTime.setHours(
                                futureTime.getHours() + 24 * 30
                              )
                          )
                        }
                        if (filter.value === "within7days") {
                          return (
                            modifiedOn >
                              pastTime.setHours(pastTime.getHours() - 24 * 7) &&
                            modifiedOn <
                              futureTime.setHours(
                                futureTime.getHours() + 24 * 7
                              )
                          )
                        }
                        if (filter.value === "within24hours") {
                          return (
                            modifiedOn >
                              pastTime.setHours(pastTime.getHours() - 24) &&
                            modifiedOn <
                              futureTime.setHours(futureTime.getHours() + 24)
                          )
                        }
                        if (filter.value === "within1hour") {
                          return (
                            modifiedOn >
                              pastTime.setHours(pastTime.getHours() - 1) &&
                            modifiedOn <
                              futureTime.setHours(futureTime.getHours() + 1)
                          )
                        }
                      },
                      Filter: ({ filter, onChange }) => (
                        <select
                          onChange={event => onChange(event.target.value)}
                          style={{ width: "100%" }}
                          value={filter ? filter.value : "all"}
                        >
                          <option value="all">Show All</option>
                          <option value="within30days">Within 30 days</option>
                          <option value="within7days">Within 7 days</option>
                          <option value="within24hours">Within 24 Hours</option>
                          <option value="within1hour">Within 1 Hour</option>
                        </select>
                      )
                    }
                  ]}
                  minRows={0}
                  noDataText={"This sentence has no related sentences."}
                  className="-striped -hightlight -compact"
                  showPaginationTop
                  defaultPageSize={25}
                  showPageSizeOptions={true}
                  pageSizeOptions={[5, 10, 20, 25, 50, 500]}
                  showPaginationBottom={false}
                  defaultSorted={[
                    {
                      id: "affiliateId",
                      desc: false
                    }
                  ]}
                />
              )}
              {currentView === 1 && (
                <>
                  <div className={`${classes.tabScrollRoot}`}>
                    <AppBar position="static" color="default">
                      <Tabs
                        value={currentTabView}
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        onChange={handleTabChange}
                        aria-label="Property Tabs"
                        style={{
                          minWidth: "1050px"
                        }}
                      >
                        <Tab label="Back to Affiliate List" />
                        <Tab
                          label="New Affiliate Editor"
                          aria-selected={true}
                        />
                      </Tabs>
                    </AppBar>
                  </div>
                  <div
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "20px",
                      paddingBottom: "20px"
                    }}
                  >
                    <Grid
                      style={{ display: "flex" }}
                      className={classes.myFlex}
                    >
                      <GridItem xs={12} sm={10}>
                        <DialogTitle
                          style={{ paddingBottom: "0px" }}
                          className={classes.myFlexFontSize}
                        >
                          New Affiliate Editor
                        </DialogTitle>
                      </GridItem>
                    </Grid>
                    <Grid
                      style={{ display: "flex" }}
                      className={classes.myFlex}
                    >
                      <GridItem xs={12} sm={4}>
                        <TextField
                          type="text"
                          id="name"
                          label="Affillite Name"
                          fullWidth
                          value={chosenAffiliate.affiliateName}
                          onChange={handleCellChange("affiliateName")}
                          margin="normal"
                          required={true}
                          variant="outlined"
                        />
                        {affiliateNameHasError && (
                          <FormHelperText className={classes.requiredMsg}>
                            Affiliate Name is required!
                          </FormHelperText>
                        )}
                      </GridItem>
                      <GridItem xs={12} sm={8}>
                        <Tooltip title="Active" placement="top">
                          <FormControlLabel
                            labelPlacement="start"
                            control={
                              <Checkbox
                                id="isActive"
                                checked={!!chosenAffiliate.isActive}
                                onChange={handleCheckboxChange("isActive")}
                              />
                            }
                            label="Active"
                          />
                        </Tooltip>
                      </GridItem>
                    </Grid>
                    <Grid
                      container
                      justifyContent="center"
                      style={{ display: "flex" }}
                      className={classes.myFlex}
                    >
                      <GridItem xs={12} sm={1} style={{ textAlign: "center" }}>
                        {" "}
                      </GridItem>
                      <GridItem xs={12} sm={10} style={{ textAlign: "center" }}>
                        <Button
                          onClick={() => {
                            resetAffiliate(affiliateInfo)
                          }}
                          color="primary"
                          style={{ padding: "12px 60px", margin: "0 10px 0 0" }}
                          disabled={!chosenAffiliate.editingAffiliate}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() => {
                            saveAffiliate()
                          }}
                          color="primary"
                          style={{ padding: "12px 60px" }}
                          disabled={!chosenAffiliate.editingAffiliate}
                        >
                          Save
                        </Button>
                      </GridItem>
                      <GridItem xs={12} sm={1} style={{ textAlign: "center" }}>
                        {" "}
                      </GridItem>
                    </Grid>
                  </div>
                </>
              )}
              {(currentView === 2 || currentView === 3) && (
                <>
                  <div className={`${classes.tabScrollRoot}`}>
                    <AppBar position="static" color="default">
                      <Tabs
                        value={currentTabView}
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        onChange={handleTabChange}
                        aria-label="Property Tabs"
                        style={{
                          minWidth: "1050px"
                        }}
                      >
                        <Tab label="Back to Affiliate List" />
                        <Tab label="Affiliate Editor" aria-selected={true} />
                        {shortCodeContent.length > 0 && (
                          <Tab label="Short Code Editor" aria-selected={true} />
                        )}
                      </Tabs>
                    </AppBar>
                  </div>
                  {currentView === 2 && (
                    <div
                      style={{
                        border: "1px solid #ccc",
                        borderRadius: "20px",
                        paddingBottom: "5px"
                      }}
                    >
                      <Grid
                        style={{ display: "flex" }}
                        className={classes.myFlex}
                      >
                        <GridItem xs={12} sm={10}>
                          <DialogTitle
                            style={{ paddingBottom: "0px" }}
                            className={classes.myFlexFontSize}
                          >
                            Affiliate Editor
                          </DialogTitle>
                        </GridItem>
                      </Grid>
                      <Grid
                        style={{ display: "flex" }}
                        className={classes.myFlex}
                      >
                        <GridItem xs={12} sm={4}>
                          <TextField
                            type="text"
                            id="name"
                            label="Affillite Name"
                            fullWidth
                            value={chosenAffiliate.affiliateName}
                            onChange={handleCellChange("affiliateName")}
                            margin="normal"
                            required={true}
                            variant="outlined"
                          />
                          {affiliateNameHasError && (
                            <FormHelperText className={classes.requiredMsg}>
                              Affiliate Name is required!
                            </FormHelperText>
                          )}
                        </GridItem>
                        <GridItem xs={12} sm={8}>
                          <Tooltip title="Active" placement="top">
                            <FormControlLabel
                              labelPlacement="start"
                              control={
                                <Checkbox
                                  id="isActive"
                                  checked={!!chosenAffiliate.isActive}
                                  onChange={handleCheckboxChange("isActive")}
                                />
                              }
                              label="Active"
                            />
                          </Tooltip>
                        </GridItem>
                      </Grid>
                      <Grid
                        container
                        justifyContent="center"
                        style={{ display: "flex" }}
                        className={classes.myFlex}
                      >
                        <GridItem
                          xs={12}
                          sm={1}
                          style={{ textAlign: "center" }}
                        >
                          {" "}
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={10}
                          style={{ textAlign: "center" }}
                        >
                          <Button
                            onClick={() => {
                              resetAffiliate(affiliateInfo)
                            }}
                            color="primary"
                            style={{
                              padding: "12px 60px",
                              margin: "0 10px 0 0"
                            }}
                            disabled={!chosenAffiliate.editingAffiliate}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              saveAffiliate()
                            }}
                            color="primary"
                            style={{ padding: "12px 60px" }}
                            disabled={!chosenAffiliate.editingAffiliate}
                          >
                            Save
                          </Button>
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={1}
                          style={{ textAlign: "center" }}
                        >
                          {" "}
                        </GridItem>
                      </Grid>
                    </div>
                  )}
                  {currentView === 3 && (
                    <>
                      {showingShortCodesList && shortCodeContent.length > 0 && (
                        <>
                          <Grid
                            style={{ display: "flex" }}
                            className={classes.myFlex}
                          >
                            <GridItem
                              xs={12}
                              sm={8}
                              style={{ textAlign: "left", padding: "10px" }}
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  createShortCode()
                                }}
                                startIcon={<Add />}
                                className={classes.deliverablesButton}
                              >
                                Add Short Code
                              </Button>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={8}
                              style={{ textAlign: "center", padding: "10px" }}
                            >
                              <Tooltip title="Active" placement="top">
                                <FormControlLabel
                                  className={classes.styledSwitch}
                                  control={
                                    <Switch
                                      id="showArchivedShortCodes"
                                      checked={showArchivedShortCodes}
                                      onChange={
                                        handleShortCodeArchiveChekcboxChange
                                      }
                                      color="primary"
                                      size="small"
                                    />
                                  }
                                  label="Include Archived?"
                                />
                              </Tooltip>
                            </GridItem>
                          </Grid>
                          <AffiliatesShortCodeTable
                            affiliateShortCodeData={shortCodeContent || []}
                            showArchivedShortCodes={showArchivedShortCodes}
                            companies={companiesInfo}
                            groupType={[
                              { id: 0, name: "None" },
                              { id: 1, name: "Team" },
                              { id: 2, name: "Region" },
                              { id: 3, name: "Person" }
                            ]}
                            dateFilterProps={() => {}}
                            reloadShortCodes={() => {
                              getShortCodeByAffiliate(
                                chosenAffiliate.affiliateId
                              )
                            }}
                          />
                        </>
                      )}
                      {showingShortCodesList &&
                        shortCodeContent.length === 0 && (
                          <>
                            <h2 className={classes.noShortCodeEntries}>
                              {emptyMessage}
                            </h2>
                          </>
                        )}
                    </>
                  )}
                </>
              )}
              <Grid container style={{ marginTop: "1rem" }}>
                {(currentView === 2 || currentView === 1) &&
                  !!chosenAffiliate.affiliateId && (
                    <>
                      <Grid
                        container
                        justifyContent="center"
                        style={{ marginTop: "1rem" }}
                      >
                        <GridItem xs={12} sm style={{ textAlign: "left" }}>
                          <Button
                            onClick={() => {
                              setIsAddingContract(true)
                              setMyContract({ isActive: true })
                            }}
                            color="primary"
                            disabled={false}
                          >
                            Create New Contract
                          </Button>
                        </GridItem>
                      </Grid>
                    </>
                  )}

                {(currentView === 2 || currentView === 1) &&
                  selectedAffiliateContracts &&
                  selectedAffiliateContracts.length > 1 && (
                    <GridItem
                      xs={12}
                      sm={12}
                      style={{
                        minWidth: "100%",
                        textAlign: "center"
                      }}
                    >
                      <FormControl
                        margin="normal"
                        style={{
                          width: "400px",
                          marginTop: "1px",
                          marginLeft: "10px",
                          textAlign: "center"
                        }}
                      >
                        <InputLabel>Contracts for this Affiliate</InputLabel>
                        <Select
                          fullWidth={false}
                          style={{
                            width: "400px"
                          }}
                          type="select"
                          name="allContracts"
                          id="allContracts"
                          value={currentContract}
                          onChange={e => {
                            setIsAddingContract(false)
                            setJustCreatedContract(0)
                            const newContract = {
                              ...selectedAffiliateContracts[e.target.value],
                              loadedContractInfo: true,
                              contractContentExists: true,
                              allContracts: selectedAffiliateContracts,
                              currentContract: e.target.value,
                              id: selectedAffiliateContracts[e.target.value].id,
                              togglingBetweenContracts:
                                !myContract.togglingBetweenContracts
                            }
                            setMyContract(newContract)
                          }}
                        >
                          {selectedAffiliateContracts &&
                            selectedAffiliateContracts.map(
                              (allContracts, i) => (
                                <MenuItem key={i} value={i}>
                                  {allContracts.legalContractName
                                    ? allContracts.legalContractName
                                    : `Contract #${i + 1}`}{" "}
                                  - {allContracts.startDate} to{" "}
                                  {allContracts.endDate}
                                </MenuItem>
                              )
                            )}
                        </Select>
                      </FormControl>
                    </GridItem>
                  )}

                {isAddingContract &&
                  (currentView === 1 || currentView === 2) &&
                  !!chosenAffiliate.affiliateId &&
                  !!chosenAffiliate.affiliateName && (
                    <AffiliateContract
                      contract={myContract}
                      chosenAffiliate={chosenAffiliate}
                      //organizations={orgs}
                      organizations={organizations}
                      formErrors={formErrors}
                      addingContract={true}
                      cancelledContract={() => {
                        setIsAddingContract(false)
                      }}
                      resultOfSave={(itSucceeded, response, id) => {
                        if (itSucceeded) {
                          setJustCreatedContract(id)
                          setSaveSuccess(true)
                          setSuccessMessage(response)
                        } else {
                          setSaveFailed(true)
                          setSuccessMessage(response)
                        }
                      }}
                      updateContractList={contract => {
                        updateContracts(contract)
                      }}
                      updateAssociatedOrganization={orgInfo => {
                        updateAssociatedOrganization(orgInfo)
                      }}
                      organizationContractAffiliate={
                        organizationContractAffiliate
                      }
                      callToActionAffiliateContract={
                        callToActionAffiliateContract
                      }
                    />
                  )}
                {typeof currentContract === "number" &&
                  selectedAffiliateContracts.length > 0 &&
                  !isAddingContract &&
                  (currentView === 1 || currentView === 2) &&
                  !!chosenAffiliate.affiliateId &&
                  !!chosenAffiliate.affiliateName && (
                    <AffiliateContract
                      contract={myContract}
                      chosenAffiliate={chosenAffiliate}
                      //organizations={orgs}
                      organizations={organizations}
                      formErrors={formErrors}
                      associatedOrganizations={
                        associatedOrganizationsForThisAffiliate
                      }
                      addingContract={false}
                      resultOfSave={(itSucceeded, response, id) => {
                        if (itSucceeded) {
                          setJustCreatedContract(id)
                          setSaveSuccess(true)
                          setSuccessMessage(response)
                        } else {
                          setSaveFailed(true)
                          setSuccessMessage(response)
                        }
                      }}
                      updateContractList={contract => {
                        updateContracts(contract)
                      }}
                      updateAssociatedOrganization={orgInfo => {
                        updateAssociatedOrganization(orgInfo)
                      }}
                      organizationContractAffiliate={
                        organizationContractAffiliate
                      }
                      callToActionAffiliateContract={
                        callToActionAffiliateContract
                      }
                    />
                  )}
              </Grid>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {showNewShortCodeForm && (
        <Dialog
          aria-label="Short Code Form"
          isOpen={showNewShortCodeForm}
          title={"Create A New Short Code"}
          onClose={() => {
            setShowNewShortCodeForm(false)
          }}
          maxWidth="sm"
        >
          <AffiliatesShortCodeForm
            style={{ marginTop: "0", width: "600px !important" }}
            companies={companiesInfo}
            groupType={[
              { id: 0, name: "None" },
              { id: 1, name: "Team" },
              { id: 2, name: "Region" },
              { id: 3, name: "Person" }
            ]}
            affiliateId={Number(chosenAffiliate.affiliateId)}
            closeShortCodeForm={() => {
              setShowNewShortCodeForm(false)
            }}
            reloadShortCodes={() => {
              getShortCodeByAffiliate(chosenAffiliate.affiliateId)
            }}
          />
        </Dialog>
      )}
      <div>
        {saveSuccess && (
          <Modal
            open={true}
            onClose={() => {
              setSaveSuccess(false)
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className={classes.successModal}>
              <h2 id="server-modal-title">Success!</h2>
              <div id="server-modal-description">
                <p>{successMessage}</p>
              </div>
            </div>
          </Modal>
        )}
        {saveFailed && (
          <Modal
            open={true}
            onClose={() => {
              setSaveFailed(false)
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className={classes.errorModal}>
              <h2 id="server-modal-title">Failed!</h2>
              <div id="server-modal-description">
                <p>{successMessage}</p>
              </div>
            </div>
          </Modal>
        )}
        {loadFailed && (
          <Modal
            open={true}
            onClose={() => {
              setLoadFailed(false)
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className={classes.errorModal}>
              <h2 id="server-modal-title">Failed!</h2>
              <div id="server-modal-description">
                <p>{successMessage}</p>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </>
  )
}

Affiliates.propTypes = {
  isNowLoading: PropTypes.func,
  classes: PropTypes.object
}

export default withStyles(styles)(Affiliates)
