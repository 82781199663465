import { NarrativeOutlineParagraph } from "./NarrativeOutlineParagraph"
import React, { useContext, useState } from "react"
import makeStyles from "@mui/styles/makeStyles"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import PropTypes from "prop-types"

import CardActions from "@mui/material/CardActions"
import IconButton from "@mui/material/IconButton"
import Collapse from "@mui/material/Collapse"
import Modal from "@mui/material/Modal"
import LinearProgress from "@mui/material/LinearProgress"
import { Dialog, DialogTitle, Paper, DialogContent } from "@mui/material"
import ParagraphIcon from "@mui/icons-material/FormatTextdirectionLToR"

import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardHeader from "components/Card/CardHeader"
import Enums from "tools/Enums.js"
import NarrativeOutlineBlockView from "components/NarrativeAdmin/NarrativeOutlineBlockView"
import NarrativeOutlineBlockEdit from "components/NarrativeAdmin/NarrativeOutlineBlockEdit"
import ParagraphEditor from "components/NarrativeAdmin/Paragraph/ParagraphEditor"
import narrativeDashboardStyles from "assets/jss/material-dashboard-pro-react/views/narrativeDashboardStyles"
import { primaryNavy } from "assets/jss/material-dashboard-pro-react"
import { Item, Menu, useContextMenu } from "react-contexify"
import clsx from "clsx"

import Edit from "@mui/icons-material/Edit"

import { NarrativeMenuActionsContext } from "contexts/narrativeMenuActionsContext"
import { ActionType, ItemType } from "constants"
import { sectionsToRenderAsHtml } from "./Data/contentSections"

const standardBorder = "1px solid #efefef"
const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: "2px",
    backgroundColor: "#f2f2f2",
    marginTop: 0,
    marginBottom: "20px",
    position: "relative",
    boxShadow: "none",
    border: standardBorder,
    "&:hover": {
      position: "relative",
      boxShadow:
        "0 16px 28px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    }
  },
  cardCompact: {
    borderRadius: "2px",
    marginTop: 0,
    marginBottom: 0,
    boxShadow: "none",
    border: "none",
    "&:hover": {
      position: "relative",
      boxShadow:
        "0 16px 28px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    }
  },
  modalHeader: {
    backgroundColor: "#DDEAEF"
  },
  cardHeader: {
    zIndex: "inherit!important",
    display: "flex",
    fontSize: "16px",
    backgroundColor: "#dddddd",
    borderBottom: standardBorder
  },
  cardHeaderBold: {
    zIndex: "inherit!important",
    display: "flex",
    backgroundColor: "#dddddd",
    borderBottom: standardBorder,
    fontWeight: 900,
    fontSize: "20px"
  },
  tools: {
    justifyContent: "flex-end",
    display: "inline-flex",
    marginLeft: "auto",
    "& > button": {
      borderRadius: 0,
      padding: "2px",
      marginLeft: "10px"
    },
    "& > button.selected": {
      color: primaryNavy,
      borderBottom: `1px solid ${primaryNavy}`
    },
    "& svg.MuiSvgIcon-root": {
      fontSize: "22px"
    }
  },
  cardbodyCompact: {
    ...narrativeDashboardStyles.cardBody,
    padding: "10px"
  },
  cardFooter: {
    borderTop: "none",
    display: "block",
    backgroundColor: "#efefef"
  },
  preview: {
    backgroundColor: "#efefef",
    padding: "30px",
    paddingTop: "10px",
    borderRadius: "2px",
    "& > label": {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#666666",
      margin: "10px 0"
    },
    "& > div": {
      backgroundColor: "#ffffff",
      padding: "15px"
    }
  },
  previewCompact: {
    backgroundColor: "#ffffff",
    padding: "15px",
    borderRadius: "2px",
    "& > label": {
      display: "none"
    },
    "& > div": {
      backgroundColor: "#ffffff"
    }
  },
  errorModal: {
    position: "absolute",
    color: "#c00",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "45%",
    left: "45%",
    transform: "translate(-45%, -45%)",
    "& h3": {
      fontWeight: "bold"
    },
    "& ul": {
      padding: "5px 0 0 5px",
      fontWeight: "bold",
      textDecoration: "underline"
    }
  },
  successModal: {
    position: "absolute",
    color: "#069",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "45%",
    left: "45%",
    transform: "translate(-45%, -45%)",
    "& h3": {
      fontWeight: "bold"
    },
    "& ul": {
      padding: "5px 0 0 5px",
      fontWeight: "bold",
      textDecoration: "underline"
    }
  }
}))

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    reloadNarrativeBrief: store.narrativeStore.reloadNarrativeBrief,
    updateOutlineBlocks: store.narrativeStore.updateOutlineBlocks,
    setNarrativeOutlineBlocks: store.narrativeStore.setNarrativeOutlineBlocks,
    setLoadingNarrative: store.narrativeStore.setLoadingNarrative,
    postDuplicateNarrativeOutlineBlock:
      store.narrativeStore.postDuplicateNarrativeOutlineBlock
  }))
}

const NarrativeOutlineBlock = props => {
  const classes = useStyles()
  const {
    reloadNarrativeBrief,
    updateOutlineBlocks,
    setNarrativeOutlineBlocks,
    setLoadingNarrative,
    postDuplicateNarrativeOutlineBlock
  } = useStoreData()

  const {
    ableToPasteParagraph,
    handlePasteParagraph,
    setTargetItem,
    setLastActionType
  } = useContext(NarrativeMenuActionsContext)

  const {
    fontSize,
    blockViewStyle,
    canContainMarkup,
    block,
    paragraphs,
    narrative
  } = props

  const [openParagraphEditor, setOpenParagraphEditor] = useState(false)
  const [bulkAlertMessage, setBulkAlertMessage] = useState({})
  const [bulkAlertMessageActive, setBulkAlertMessageActive] = useState(false)
  const [failed, setFailed] = useState(false)
  const [succeeded, setSucceeded] = useState(false)

  const setEditView = newMode => {
    let newBlock = {
      ...props.block,
      inEditMode: newMode
    }
    //For some reason simply updating the parent collection doesn't update this component
    //We must update the local state as well as the parent collection
    setNarrativeOutlineBlocks(updateOutlineBlocks(newBlock))
  }

  const baseLocalBlock = {
    ...block,
    name: `${block.contentSection}`
  }

  const handleChange = newBlock => {
    setNarrativeOutlineBlocks(updateOutlineBlocks(newBlock))
  }

  const closeBulkModal = () => {
    setBulkAlertMessage({})
    setBulkAlertMessageActive(false)
    setSucceeded(false)
    setFailed(false)
  }

  const handleMenuAction = async actionType => {
    if (actionType === ActionType.Paste) {
      await handlePasteParagraph()
      reloadNarrativeBrief(narrative.id)
    } else if (actionType === ActionType.Duplicate) {
      setLoadingNarrative(true)
      await postDuplicateNarrativeOutlineBlock(block.id, narrative.id)
      reloadNarrativeBrief(narrative.id)
    }
    setLastActionType(actionType)
  }

  const menuId = `block-${block.id}`

  const canDuplicateBlock =
    sectionsToRenderAsHtml.includes(block.contentBlock) &&
    (narrative.buildFromOutline ?? false)

  const paragraphActionAvailable =
    ableToPasteParagraph() && narrative.buildFromOutline

  const { show } = useContextMenu({
    id: menuId
  })
  function handleContextMenu(event) {
    setTargetItem({ id: block.id, type: ItemType.Block })
    event.stopPropagation()
    show({
      event
    })
  }

  return (
    <div
      aria-label={`Narrative Outline ${block.contentBlock} ${block.contentSection}`}
    >
      <Menu id={menuId}>
        <Item onClick={() => setOpenParagraphEditor(true)}>
          Add New Paragraph
        </Item>
        {paragraphActionAvailable && (
          <Item onClick={() => handleMenuAction(ActionType.Paste)}>
            Paste Paragraph
          </Item>
        )}
        {canDuplicateBlock && (
          <Item onClick={() => handleMenuAction(ActionType.Duplicate)}>
            Duplicate Block
          </Item>
        )}
      </Menu>
      <div
        id={menuId}
        onContextMenu={handleContextMenu}
        disableMe={!paragraphActionAvailable}
      >
        <Card
          className={
            blockViewStyle !== Enums.BlockDisplayStyle.COMPACT
              ? classes.card
              : classes.cardCompact
          }
        >
          {(blockViewStyle === Enums.BlockDisplayStyle.STANDARD ||
            blockViewStyle === Enums.BlockDisplayStyle.DETAILED) && (
            <CardHeader
              className={
                (block.renderType && block.renderType.startsWith("h")) ||
                (block.contentBlock && block.contentBlock === "title")
                  ? classes.cardHeaderBold
                  : classes.cardHeader
              }
            >
              {!narrative.buildFromOutline && (
                <strong style={{ marginRight: "10px" }}>
                  {"<"}
                  {block.position}
                  {">"}
                </strong>
              )}
              {block.renderType && (
                <strong style={{ marginRight: "10px" }}>
                  {"<"}
                  {block.renderType}
                  {">"}
                </strong>
              )}
              <strong>{block.contentSection}</strong>
              {(!block.id || block.id === 0) && (
                <strong> - Unsaved Block</strong>
              )}
              <div className={classes.tools}>
                <div>
                  <IconButton title="Add Paragraph">
                    <ParagraphIcon
                      onClick={() => setOpenParagraphEditor(true)}
                      size="large"
                    />
                  </IconButton>
                  <Dialog open={openParagraphEditor} maxWidth="lg">
                    <DialogTitle className={classes.modalHeader}>
                      {"Create New Paragraph"}
                    </DialogTitle>
                    <Paper>
                      <DialogContent>
                        <ParagraphEditor
                          paragraph={{
                            ...block,
                            id: 0,
                            ownerId: 0,
                            status_Id: 3,
                            narrativeOutlineBlockId: block.id,
                            name: "",
                            addHTMLComment: false,
                            blockAttributes: "",
                            categories: "",
                            clonedFrom: null,
                            clonedFrom_Id: null,
                            clones: null,
                            comments: "",
                            libraryBlockPositionsFilter: "",
                            libraryParagraph_Id: null,
                            maxLength: null,
                            minLength: null,
                            paragraphTags: null,
                            primaryTagId: 0,
                            triggerIds: "",
                            triggerOption: null,
                            triggerType: null,
                            triggerValue: null,
                            triggerWeight: null,
                            triggers: null
                          }}
                          create
                          handleClose={() => setOpenParagraphEditor(false)}
                        />
                      </DialogContent>
                    </Paper>
                  </Dialog>
                </div>
                <IconButton
                  color="primary"
                  title="Toggle Editor"
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: block.inEditMode
                  })}
                  onClick={() => setEditView(!block.inEditMode)}
                  aria-expanded={block.inEditMode}
                  aria-label="Toggle Editor"
                  size="large"
                >
                  <Edit />
                </IconButton>
              </div>
            </CardHeader>
          )}
          <Collapse in={block.inEditMode} timeout="auto" unmountOnExit>
            <CardBody
              className={
                blockViewStyle !== Enums.BlockDisplayStyle.COMPACT
                  ? classes.cardBody
                  : classes.cardbodyCompact
              }
            >
              <NarrativeOutlineBlockEdit
                block={block}
                blockViewStyle={blockViewStyle}
                canContainMarkup={canContainMarkup}
                fontSize={fontSize}
                removeBlock={props.removeBlock}
                handleClose={() => setEditView(!block.inEditMode)}
                handleSave={() => setEditView(false)}
                handleChange={handleChange}
                hasChildren={paragraphs && paragraphs.length > 0}
              />
            </CardBody>
          </Collapse>
          {!block.inEditMode && (
            <>
              <div
                className={
                  blockViewStyle !== Enums.BlockDisplayStyle.COMPACT
                    ? classes.preview
                    : classes.previewCompact
                }
              >
                <label>Sample Text / Sentence Starter</label>
                <div>
                  <NarrativeOutlineBlockView
                    block={baseLocalBlock}
                    blockViewStyle={blockViewStyle}
                    fontSize={fontSize}
                  />
                </div>
              </div>
              {blockViewStyle !== Enums.BlockDisplayStyle.COMPACT &&
                paragraphs?.length > 0 && (
                  <CardActions className={classes.cardFooter}>
                    <div>
                      {paragraphs?.map(p => (
                        <NarrativeOutlineParagraph
                          key={p.id}
                          paragraph={p}
                          narrative={narrative}
                          block={block}
                          classes={classes}
                        />
                      ))}
                    </div>
                  </CardActions>
                )}
            </>
          )}
        </Card>
      </div>
      {bulkAlertMessageActive &&
        typeof bulkAlertMessage.progress === "number" &&
        !succeeded && <LinearProgress />}

      {bulkAlertMessage.progress !== 100 && failed && (
        <Modal
          open={failed}
          onClose={() => {
            closeBulkModal()
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.errorModal}>
            <h3 id="server-modal-title">Here are the failed requests</h3>
            <div id="server-modal-description">
              <ul>
                {bulkAlertMessage.failed &&
                  bulkAlertMessage.failed.map((n, index) => (
                    <li key={index}>
                      ID #{n.id} --- {n.error} error
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </Modal>
      )}
      {bulkAlertMessage.progress === 100 && (
        <Modal
          open={true}
          onClose={() => {
            closeBulkModal()
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.successModal}>
            <h2 id="server-modal-title">Success!</h2>
            <div id="server-modal-description">
              All {bulkAlertMessage.totals.saving} actions were executed
              correctly
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}

NarrativeOutlineBlock.propTypes = {
  narrative: PropTypes.object.isRequired,
  removeBlock: PropTypes.func.isRequired,
  block: PropTypes.object,
  blockViewStyle: PropTypes.number,
  paragraphs: PropTypes.array,
  canContainMarkup: PropTypes.bool
}

function propsAreEqual(prevProps, nextProps) {
  const isSame =
    prevProps?.block === nextProps?.block &&
    prevProps?.paragraphs === nextProps?.paragraphs
  return isSame
}
export default React.memo(NarrativeOutlineBlock, propsAreEqual)
