import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import {
  Checkbox,
  Collapse,
  Divider,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Slide
} from "@mui/material"

import { ChevronLeft, ExpandLess, ExpandMore } from "@mui/icons-material"

const drawerWidth = 240

const useStyles = makeStyles(() => ({
  root: {
    position: "relative"
  },
  fab: {
    position: "absolute",
    top: "-30px",
    left: "-20px"
  },
  help: {
    position: "absolute",
    top: "-30px",
    left: "10px"
  },
  drawer: {
    zIndex: 1035,
    top: "50px",
    left: "80px",
    position: "fixed",
    width: drawerWidth,
    flexShrink: 0,
    backgroundColor: "#ffffff",
    boxShadow:
      "0 8px 18px -6px rgba(0, 0, 0, 0.56), 0 4px 15px 0px rgba(0, 0, 0, 0.12), 0 4px 8px -5px rgba(0, 0, 0, 0.2)",
    "& > ul > div.MuiListItem-root": {
      padding: "0 16px"
    }
  },
  elementMenu: {
    maxHeight: "80vh",
    overflow: "auto",
    marginBottom: "5px"
  },
  drawerSubHeader: {
    position: "relative",
    "& > .MuiSvgIcon-root": {
      position: "absolute",
      right: "10px",
      top: "7px",
      padding: 0
    }
  }
}))

const NarrativeOutlineElementMenu = props => {
  const classes = useStyles()

  const {
    activeElements,
    inactiveElements,
    toggleElement,
    visibleNarrativeOutlineElements,
    openAvailable,
    setOpenAvailable,
    handleDrawerClose,
    handleDrawerOpen,
    openElementMenu
  } = props

  return (
    <>
      <Link title={"Add or Toggle Elements"} onClick={handleDrawerOpen}>
        Add/Toggle Section +
      </Link>
      <Slide direction="right" in={openElementMenu} mountOnEnter unmountOnExit>
        <div className={classes.drawer}>
          <div className={classes.drawerMenu}>
            <ListSubheader
              title={"Hide Outline Elements"}
              className={classes.drawerSubHeader}
              disableSticky
              component="div"
              id="activeElements"
              onClick={handleDrawerClose}
            >
              Active Elements
              <ChevronLeft />
            </ListSubheader>
            <Collapse in={!openAvailable} timeout="auto" unmountOnExit>
              <div className={classes.elementMenu}>
                <List>
                  {activeElements.map(element => (
                    <ListItem
                      button
                      key={element.id}
                      onClick={() => toggleElement(element.id)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={
                            visibleNarrativeOutlineElements.indexOf(
                              element.id
                            ) !== -1
                          }
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText primary={element.name} />
                    </ListItem>
                  ))}
                </List>
              </div>
            </Collapse>
            <Divider />
            <ListSubheader
              className={classes.drawerSubHeader}
              disableSticky
              component="div"
              id="availableItems"
              onClick={() => setOpenAvailable(!openAvailable)}
            >
              Unused Elements
              {openAvailable ? <ExpandLess /> : <ExpandMore />}
            </ListSubheader>
            <Collapse in={openAvailable} timeout="auto" unmountOnExit>
              <div className={classes.elementMenu}>
                <List>
                  {inactiveElements.map(element => (
                    <ListItem
                      button
                      key={element.id}
                      onClick={() => toggleElement(element.id)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={
                            visibleNarrativeOutlineElements.indexOf(
                              element.id
                            ) !== -1
                          }
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText primary={element.name} />
                    </ListItem>
                  ))}
                </List>
              </div>
            </Collapse>
          </div>
        </div>
      </Slide>
    </>
  )
}

export default NarrativeOutlineElementMenu
