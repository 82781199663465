import { useContext, useEffect, useState } from "react"
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Toolbar,
  Tooltip
} from "@mui/material"
import {
  Edit,
  GridOff,
  GridOn,
  ListAlt,
  NoteAdd,
  PlaylistAddCheck,
  QueuePlayNext as Preview
} from "@mui/icons-material"
import makeStyles from "@mui/styles/makeStyles"

import StatusType from "../Types/StatusType"
import BlockTriggerDetails from "../BlockTriggerDetails/BlockTriggerDetails"

import { Cookies } from "tools/storage"
import { NarrativeContext } from "contexts/narrative-context"

const cookies = new Cookies()

const useStyles = makeStyles(() => ({
  selected: {
    "&.MuiListItem-root.Mui-selected": {
      backgroundColor: "#bdbdbd"
    }
  }
}))

const ParagraphEditorToolbar = ({
  currentNarrativeId,
  form,
  paragraph,
  searchEditorMode = false,
  togglePreviewDrawer,
  toggleTable,
  tableView,
  toggleParagraph,
  openParagraph,
  ownerListForOrg
}) => {
  const [isPreviewDrawerOpen, setIsPreviewDrawerOpen] = useState(false)

  useEffect(() => {
    const drawerVal = cookies.get("isPreviewerDrawerOpen")
    if (drawerVal && typeof drawerVal === "boolean") {
      if (drawerVal !== isPreviewDrawerOpen) {
        setIsPreviewDrawerOpen(drawerVal)
      }
    }
  }, [])

  const {
    buildParagraphPreview,
    contentId,
    contentType,
    searchString,
    setActiveView,
    narrative
  } = useContext(NarrativeContext)

  const { triggers } = narrative
  const {
    setFormValueBase,
    formState: { ownerId, primaryTagId, status_Id, triggerIds, triggerType }
  } = form
  const { id: paragraphId, narrative_Id } = paragraph
  const classes = useStyles()

  return (
    <Toolbar
      style={{
        fontWeight: "bold",
        display: "flex",
        backgroundColor: "#efefef",
        padding: "0 12px",
        minHeight: "48px"
      }}
    >
      <BlockTriggerDetails
        triggerType={triggerType}
        triggerIds={triggerIds}
        allAvailableTriggers={triggers}
        titleText={
          <span style={{ color: "#3f51b5", fontWeight: "bold" }}>&nbsp;</span>
        }
        searchString={searchString}
        searchEditorMode={searchEditorMode}
        setActiveView={setActiveView}
        setTriggerIds={newTriggerIds => {
          if (currentNarrativeId === narrative_Id) {
            setFormValueBase("triggerIds", newTriggerIds)
          }
        }}
      />
      <div
        style={{
          width: "200px",
          justifyContent: "flex-end",
          display: "flex",
          marginRight: "10px"
        }}
      >
        <FormControl margin="normal" fullWidth>
          <InputLabel>Owner</InputLabel>
          <Select
            value={ownerId || ""}
            type="select"
            name="ownerId"
            id="ownerId"
            onChange={e => {
              setFormValueBase("ownerId", e.target.value)
              e.preventDefault()
            }}
            label="Owner"
          >
            <MenuItem
              key="none"
              value="0"
              classes={{ selected: classes.selected }}
            >
              None
            </MenuItem>
            {ownerListForOrg?.map(option => (
              <MenuItem
                key={option.id}
                value={option.id}
                classes={{ selected: classes.selected }}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div
        style={{
          width: "200px",
          justifyContent: "flex-end",
          display: "flex"
        }}
      >
        <StatusType
          value={status_Id}
          disabled={currentNarrativeId !== paragraph.narrative_Id}
          onChange={val => {
            setFormValueBase("status_Id", val)
            openParagraph()
          }}
          isLibrary={narrative.isLibrary}
          paragraphType={primaryTagId}
          classes={{ selected: classes.selected }}
        />
      </div>
      <div
        style={{
          justifyContent: "flex-end",
          display: "flex"
        }}
      >
        <Tooltip name="tooltip-top" title="Edit Paragraph" placement="top">
          <IconButton
            style={{ padding: "6px" }}
            aria-label="Edit"
            onClick={toggleParagraph}
            size="large"
          >
            <Edit />
          </IconButton>
        </Tooltip>
        {!searchEditorMode && (
          <>
            <Tooltip name="tooltip-top" title="Table View" placement="top">
              <IconButton
                style={{ padding: "6px" }}
                aria-label="Table View"
                onClick={toggleTable}
                size="large"
              >
                {tableView ? <GridOff /> : <GridOn />}
              </IconButton>
            </Tooltip>
            <Tooltip
              name="tooltip-top-start"
              title="Add Sentence"
              placement="top"
            >
              <IconButton
                style={{ padding: "6px" }}
                aria-label="Add Sentence"
                onClick={() => setActiveView(`createsentence-${paragraphId}`)}
                size="large"
              >
                <NoteAdd />
              </IconButton>
            </Tooltip>
            <Tooltip
              name="tooltip-top"
              title="Preview Paragraph"
              placement="top"
            >
              <div>
                <IconButton
                  style={{ padding: "6px" }}
                  aria-label="Preview"
                  onClick={() => buildParagraphPreview(paragraph, false)}
                  disabled={!contentId || contentType === ""}
                  size="large"
                >
                  <Preview />
                </IconButton>
              </div>
            </Tooltip>
            <Tooltip name="tooltip-top" title="QA Paragraph" placement="top">
              <div>
                <IconButton
                  style={{ padding: "6px" }}
                  aria-label="QA Paragraph"
                  onClick={() => {
                    if (!isPreviewDrawerOpen) {
                      togglePreviewDrawer()
                    }
                    buildParagraphPreview(paragraph, true)
                  }}
                  disabled={!contentId || contentType === ""}
                  size="large"
                >
                  <ListAlt />
                </IconButton>
              </div>
            </Tooltip>
            <Tooltip name="tooltip-top" title="QA All Triggers" placement="top">
              <div>
                <IconButton
                  style={{ padding: "6px" }}
                  aria-label="QA All Triggers"
                  onClick={() => {
                    if (!isPreviewDrawerOpen) {
                      togglePreviewDrawer()
                    }
                    buildParagraphPreview(paragraph, true, true)
                  }}
                  disabled={!contentId || contentType === ""}
                  size="large"
                >
                  <PlaylistAddCheck />
                </IconButton>
              </div>
            </Tooltip>
          </>
        )}
      </div>
    </Toolbar>
  )
}

export default ParagraphEditorToolbar
