import { Map, List, fromJS } from "immutable"
import { action, observable, decorate, reaction, computed } from "mobx"
import PermissionGroupDAO from "../daos/permissionGroupDAO"
import PermissionDAO from "../daos/permissionDAO"

export default class PermissionGroupStore {
  rootStore
  loading
  loadingPermissionGroup
  permissionGroupId
  permissionGroups
  permissionGroup
  upsertedPermissionGroup
  permissions
  isModalOpen

  constructor(rootStore) {
    this.rootStore = rootStore
    this.dehydrate()
  }

  /**
   * Computeds
   */
  get isPermissionGroupSelected() {
    return !!this.permissionGroupId
  }

  get showUpsertConfirmation() {
    return !!this.upsertedPermissionGroup.get("id")
  }

  /**
   * Methods
   */
  getPermissionGroups = async () => {
    try {
      this.setLoading(true)

      const permissionGroups = fromJS(
        await PermissionGroupDAO.getPermissionGroups()
      )

      this.setPermissionGroups(permissionGroups.get("content", List()))
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoading(false)
    }
  }

  getPermissionGroup = async id => {
    try {
      this.setLoadingPermissionGroup(true)

      const permissionGroup = fromJS(
        await PermissionGroupDAO.getPermissionGroup(id)
      )

      this.updatePermissionGroups(permissionGroup.get("content", Map()))
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoadingPermissionGroup(false)
    }
  }

  getPermissions = async () => {
    try {
      this.setLoading(true)

      const permissions = fromJS(await PermissionDAO.getPermissions())

      this.setPermissions(permissions.get("content", List()))
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoading(false)
    }
  }

  postPermissionGroup = async group => {
    try {
      this.setLoading(true)

      group.permissions = group.permissions.map(p => p.id)

      const newGroup = fromJS(
        await PermissionGroupDAO.postPermissionGroup(group)
      )

      this.setUpsertedPermissionGroup(newGroup.get("content", Map()))

      this.updatePermissionGroups(newGroup.get("content", Map()))
    } catch (err) {
      console.error("Error: ", err)
      throw err
    } finally {
      this.setLoading(false)
    }
  }

  putPermissionGroup = async group => {
    try {
      this.setLoading(true)

      group.permissions = group.permissions.map(p => p.id)

      let updatedGroup = fromJS(
        await PermissionGroupDAO.putPermissionGroup(
          this.permissionGroupId,
          group
        )
      )

      this.setUpsertedPermissionGroup(updatedGroup.get("content", Map()))

      this.updatePermissionGroups(updatedGroup.get("content", Map()))
    } catch (err) {
      console.error("Error: ", err)
      throw err
    } finally {
      this.setLoading(false)
    }
  }

  closeModal = () => {
    this.dehydratePermissionGroup()
  }

  openModal = (permissionGroupId = null) => {
    this.setPermissionGroupId(permissionGroupId)
    this.setIsModalOpen(true)
  }

  /**
   * Reactions
   */
  permissionGroupReaction = reaction(
    () => this.permissionGroupId,
    async id => {
      try {
        if (!id) {
          return
        }

        this.setLoading(true)
        this.setLoadingPermissionGroup(true)

        const permissionGroup = fromJS(
          await PermissionGroupDAO.getPermissionGroup(id)
        )

        if (!permissionGroup) {
          return
        }

        this.setPermissionGroup(permissionGroup.get("content", Map()))
      } catch (err) {
        console.error("Error: ", err)
      } finally {
        this.setLoading(false)
        this.setLoadingPermissionGroup(false)
      }
    }
  )

  /**
   * Private methods
   */
  updatePermissionGroups(group) {
    const groupIdx = this.permissionGroups.findIndex(
      a => a.get("id") === group.get("id")
    )

    // update the permission group in state
    if (groupIdx !== -1) {
      this.setPermissionGroups(this.permissionGroups.set(groupIdx, group))
    } else {
      this.setPermissionGroups(this.permissionGroups.push(group))
    }
  }

  /**
   * Actions
   */
  dehydrate() {
    this.dehydratePermissionGroup()
    this.setLoading(false)
    this.setLoadingPermissionGroup(false)
    this.setPermissionGroups(List())
    this.setPermissions(List())
    this.setIsModalOpen(false)
  }

  dehydratePermissionGroup() {
    this.setPermissionGroup(Map())
    this.setUpsertedPermissionGroup(Map())
    this.setPermissionGroupId(null)
    this.setIsModalOpen(false)
  }

  setPermissionGroupId(value) {
    if (!value) {
      this.setPermissionGroup(Map())
    }
    this.permissionGroupId = value
  }

  setPermissionGroup(value) {
    this.permissionGroup = value
  }

  setUpsertedPermissionGroup(value) {
    this.upsertedPermissionGroup = value
  }

  setPermissionGroups(value) {
    this.permissionGroups = value
  }

  setPermissions(value) {
    this.permissions = value
  }

  setIsModalOpen(value) {
    this.isModalOpen = value
  }

  setLoading(value) {
    this.loading = value
  }

  setLoadingPermissionGroup(value) {
    this.loadingPermissionGroup = value
  }
}

/**
 * object decorators
 */
decorate(PermissionGroupStore, {
  // Computeds
  isPermissionGroupSelected: computed,
  showUpsertConfirmation: computed,

  // observables
  loading: observable,
  loadingPermissionGroup: observable,
  permissionGroupId: observable,
  permissionGroups: observable,
  permissionGroup: observable,
  permissions: observable,
  isModalOpen: observable,
  upsertedPermissionGroup: observable,

  // actions
  setPermissionGroupId: action.bound,
  setPermissionGroup: action.bound,
  setPermissionGroups: action.bound,
  setPermissions: action.bound,
  setIsModalOpen: action.bound,
  setLoading: action.bound,
  setLoadingPermissionGroup: action.bound,
  setUpsertedPermissionGroup: action.bound
})
