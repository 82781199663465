import { globalSettings } from "variables/general.jsx"
import { get } from "../tools/request"

export default class SportsDAO {
  static getLeagues = async () => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/sports/league-list/`
    )
    return await res.json()
  }

  static getSeasons = async leagueId => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/sports/season-years?leagueId=${leagueId}`
    )
    return await res.json()
  }

  static getMatches = async seasonYearId => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/sports/schedule?seasonYearId=${seasonYearId}`
    )
    return await res.json()
  }

  static getLeague = async leagueId => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/sports/league?leagueid=${leagueId}`
    )
    return await res.json()
  }
}
