import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

import makeStyles from "@mui/styles/makeStyles"
import Grid from "@mui/material/Grid"
import LinearProgress from "@mui/material/LinearProgress"
import TextField from "@mui/material/TextField"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import Autocomplete from "@mui/material/Autocomplete"
import Button from "@mui/material/Button"
import Snackbar from "@mui/material/Snackbar"

import { globalSettings } from "variables/general"
import { cardTitle } from "assets/jss/material-dashboard-pro-react"
import { observer, inject } from "mobx-react"
import { get } from "tools/request"

const useStyles = makeStyles({
  copyLibary: {
    alignItems: "center"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  cardIconDescription: {
    ...cardTitle,
    fontSize: "12px",
    marginTop: "5px",
    marginBottom: "0px"
  },
  strikethrough: {
    textDecoration: "line-through"
  },
  libraryCopyButton: {
    fontSize: "12px",
    padding: "7px 14px",
    minWidth: "110px",
    marginRight: "4px"
  },
  checkBoxTitle: {
    //paddingTop: "20px",
    //paddingBottom: "9px"
  }
})
const customStyles = {
  strikethrough: {
    textDecoration: "line-through"
  }
}

const useStore = component =>
  inject(({ store }) => ({
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization,
    organizationId: store.organizationStore.organizationId
  }))(observer(component))

//class NarrativeCloneTool extends Component {
function LibraryNarrativeClone(props) {
  const history = useHistory()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [filteredNarratives, setFilteredNarratives] = useState([])
  const [narratives, setNarratives] = useState([])
  const [copyNarrativeId, setCopyNarrativeId] = useState()
  const [organizationId, setOrganizationId] = useState(props.organizationId)
  const [loadingCopy, setLoadingCopy] = useState(false)
  const [selectedCopyNarrative, setSelectedCopyNarrative] = useState()
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [copyButtonText, setCopyButtonText] = useState("Copy")
  const [copyButtonDisabled, setCopyButtonDisabled] = useState(false)

  // eslint-disable-next-line no-unused-vars
  const [showChangeCurrentOrganization, setShowChangeCurrentOrganization] =
    useState()
  const [showArchived, setShowArchived] = useState(false)

  useEffect(() => {
    setShowChangeCurrentOrganization(true)
    if (!loading && narratives.length === 0 && props.organizationId) {
      loadNarratives(organizationId)
    }
  })

  useEffect(() => {
    if (
      !loading &&
      !narratives.length &&
      organizationId &&
      (organizationId !== props.organizationId || narratives.length === 0)
    ) {
      loadNarratives(organizationId)
    }
  })

  const loadNarratives = organizationId => {
    if (loading) {
      return
    }

    setLoading(true)
    get(
      `${
        globalSettings.apiBaseUrl
      }/api/narrative/retrieveallbyorganization?organizationid=${(organizationId = 57)}`
    )
      .then(Response => Response.json())
      .then(narratives => {
        const sortedNarratives = narratives.content.sort((a, b) => {
          const itemA = a.id
          const itemB = b.id
          let comparison = 0
          if (itemA > itemB) {
            comparison = 1
          } else if (itemA < itemB) {
            comparison = -1
          }
          return comparison
        })

        setNarratives(sortedNarratives)
        setOrganizationId(organizationId)
        setLoading(false)

        const filteredNarratives = sortedNarratives.filter(
          narrative => !narrative.isArchived
        )
        // this.setState({filteredNarratives})
        setFilteredNarratives(filteredNarratives)
      })
  }

  //TODO refactor to use store
  const copyNarrative = () => {
    setLoadingCopy(true)
    get(
      `${globalSettings.apiBaseUrl}/api/narrative/copyasync/${copyNarrativeId}`
    )
      .then(Response => Response.json())
      .then(narrative => {
        setCopyButtonDisabled(true)
        setCopyButtonText(`Copying Library #${copyNarrativeId}`)
        setLoadingCopy(false)
        setSnackbarMessage(
          `Copying Library ID ${copyNarrativeId}. You will receive a notification when this has completed`
        )
        setShowSnackbar(true)
        if (narrative && narrative.content && narrative.content.id) {
          history.push(`/portal/narrative/${narrative.content.id}`)
        }
      })
      .catch(() => {
        setLoadingCopy(false)
        alert("Error when Copying Narrative")
      })
  }

  if (!narratives || !narratives.length) {
    return "Assembling..."
  }
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={3000}
        open={showSnackbar}
        onClose={(event, reason) => {
          setShowSnackbar(false)
        }}
        key="BackgroundCopySnack"
        message={snackbarMessage}
      />
      {loadingCopy && <LinearProgress />}
      <Grid container spacing={2} className={classes.copyLibary}>
        <Grid item xs={4}>
          <Autocomplete
            name="narrativeToCopy"
            id="narrativeToCopy"
            options={!showArchived ? filteredNarratives : narratives}
            getOptionLabel={option => `${option.id} - ${option.name}`}
            clearText="None"
            value={selectedCopyNarrative}
            onChange={(event, newValue) => {
              setCopyButtonDisabled(false)
              setCopyButtonText("Copy")
              if (newValue !== null) {
                setCopyNarrativeId(newValue.id)
                setSelectedCopyNarrative(selectedCopyNarrative)
              } else {
                setSelectedCopyNarrative("")
              }
            }}
            renderOption={(props, n) => (
              <div
                {...props}
                style={{ ...(n.isArchived && customStyles.strikethrough) }}
                key={n.id}
                value={n.id}
              >
                {n.id} - {n.name}
              </div>
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={params => (
              <TextField
                {...params}
                label="LIBRARY COPY"
                margin="normal"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={showArchived || false}
                onChange={() => {
                  setShowArchived(!showArchived)
                }}
              />
            }
            label="Show Archived Libraries?"
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            className={classes.libraryCopyButton}
            variant="contained"
            disabled={copyButtonDisabled}
            color="primary"
            type="submit"
            onClick={copyNarrative}
          >
            {copyButtonText}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default useStore(LibraryNarrativeClone)
