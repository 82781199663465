import React, { useEffect, useState } from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import LinearProgress from "@mui/material/LinearProgress"
import GridItem from "components/Grid/GridItem"
import {
  Paper,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
  Card,
  Alert
} from "@mui/material"
import Autocomplete from "@mui/material/Autocomplete"
import {
  DesktopDateTimePicker,
  LocalizationProvider
} from "@mui/x-date-pickers"
import { makeStyles } from "@mui/styles"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import CTAStatusTypes from "components/CTAEditor/CTAStatusTypes"
import CodeEditor from "components/CodeEditor"
import TimezoneSelect from "react-timezone-select"
import WordCounter from "components/WordCounter/WordCounter"
import queryString from "query-string"
import { useHistory } from "react-router-dom"

import Cta_v2DAO from "daos/cta_v2DAO"
import NarrativePreviewDAO from "daos/narrativePreviewDAO"
import dayjs from "dayjs" //Dayjs
import utc from "dayjs/plugin/utc"
import "dayjs/locale/en"
dayjs.extend(utc)

const useStyles = makeStyles({
  CTAGrid: {
    "& div.MuiAutocomplete-tag": {
      backgroundColor: "#DDEAEF",
      height: "24px"
    }
  },
  CTAGridOpacity: {
    "& div.MuiAutocomplete-tag": {
      backgroundColor: "#DDEAEF",
      height: "24px"
    },
    backgroundColor: "#aaa",
    opacity: ".3"
  },
  ctaContainer: {
    borderRadius: "0px",
    display: "flex",
    padding: "25px 0"
  },
  CTAform: {
    display: "block",
    flexDirection: "row"
  },
  ctaToolbar: {
    fontSize: "1rem",
    color: "#FFF",
    backgroundColor: "#4d9ab5"
  },
  requiredMsg: {
    color: "#c00",
    textAlign: "center",
    backgroundColor: "#f7f7f7"
  },
  monetizationCard: {
    backgroundColor: "#DDEAEF",
    display: "flex",
    padding: "25px 0",
    width: "100%",
    borderRadius: "0px",
    marginBottom: "9px"
  },
  monetizationHeader: {
    backgroundColor: "#DDEAEF",
    padding: "9px 0",
    borderRadius: "0px",
    marginTop: "5px",
    paddingLeft: "1px"
  },
  autocompleteCTA: {
    size: "medium",
    backgroundColor: "#FFF",
    "& div.MuiAutocomplete-tag": {
      backgroundColor: "#DDEAEF",
      height: "24px"
    }
  },
  ctaTop: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
    display: "flex",
    marginRight: "10px",
    borderRadius: "0px"
  },
  infoCard: {
    display: "inline-flex",
    flexDirection: "row",
    paddingLeft: "15px",
    paddingBottom: "9px",
    borderRadius: "0px"
  },
  detailsCard: {
    paddingLeft: "15px",
    paddingTop: "5px",
    borderRadius: "0px"
  },
  CTATitle: {
    paddingLeft: "15px",
    paddingTop: "5px"
  },
  CTADivide: {
    marginLeft: "9px",
    marginRight: "9px",
    height: "2px",
    backgroundColor: "#DDEAEF"
  },
  CTAmodalIcon: {
    fontSize: "1.25rem",
    color: "#144e68",
    marginLeft: "2px",
    padding: "0"
  },
  CTAButton: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "15px",
    paddingBottom: "15px"
  },
  buttonStyle: {
    fontSize: "12px",
    padding: "14px 23px",
    minWidth: "110px",
    marginRight: "4px"
  },
  inputCTAField: {
    backgroundColor: "#FFF"
  },
  "&.MuiSelect-select.MuiSelect-select": {
    border: "2px red solid !important"
  },
  addIconButton: {
    color: "#144e68"
  },
  buttonExceptions: {
    minWidth: "65px",
    minHeight: "20px",
    fontSize: "12px",
    padding: "10px 20px",
    marginRight: "4px"
  },
  timeZoneField: {
    minHeight: "!important 53px"
  }
})

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    accounts: store.accountStore.accounts,
    getAccounts: store.accountStore.getAccounts,
    setIsError: store.uiStore.setIsError,
    setErrorMessage: store.uiStore.setErrorMessage,
    setDialogWarningNoBtnMessage: store.uiStore.setDialogWarningNoBtnMessage,
    setDialogWarningNoBtnOpen: store.uiStore.setDialogWarningNoBtnOpen,
    setDialogInfoMessage: store.uiStore.setDialogInfoMessage,
    setDialogInfoOpen: store.uiStore.setDialogInfoOpen,
    setDialogFailMessage: store.uiStore.setDialogFailMessage,
    setDialogFailOpen: store.uiStore.setDialogFailOpen,
    setDialogSuccessMessage: store.uiStore.setDialogSuccessMessage,
    setDialogSuccessOpen: store.uiStore.setDialogSuccessOpen,
    getVariantLeagues: store.ctav2Store.getVariantLeagues,
    getLinkDisplayInfo: store.ctav2Store.getLinkDisplayInfo,
    variantLeagues: store.ctav2Store.variantLeagues,
    getVariantsByLinkId: store.ctav2Store.getVariantsByLinkId,
    displayStyle: store.ctav2Store.displayStyle,
    getVariantVerticals: store.ctav2Store.getVariantVerticals,
    verticals: store.ctav2Store.verticals,
    getVariantEvents: store.ctav2Store.getVariantEvents,
    events: store.ctav2Store.events,
    randomShortCode: store.ctav2Store.randomShortCode,
    getRandomShortCode: store.ctav2Store.getRandomShortCode,
    setRandomShortCode: store.ctav2Store.setRandomShortCode
  }))
}

export default function CTAVariantForm(props) {
  const {
    linkInfo,
    rightClickLinkInfo,
    variantInfo,
    onVariantCreated,
    intentId,
    fetchVariantById,
    closePage
  } = props
  const history = useHistory()
  const _MAX_PRIORITY = 1000
  const _MIN_PRIORITY = 1
  const _PROSE_STYLE_ID = 40

  const classes = useStyles()
  const {
    getAccounts,
    accounts,
    getVariantLeagues,
    variantLeagues,
    getVariantsByLinkId,
    displayStyle,
    getVariantVerticals,
    getVariantEvents,
    getLinkDisplayInfo,
    verticals,
    events,
    setDialogWarningNoBtnMessage,
    setDialogWarningNoBtnOpen,
    setDialogFailMessage,
    setDialogFailOpen,
    setDialogSuccessMessage,
    setDialogSuccessOpen,
    getRandomShortCode,
    setRandomShortCode,
    randomShortCode
  } = useStoreData()
  const [formValues, setFormValues] = useState({
    id: variantInfo?.id || 0,
    ownerAccountId: 0,
    status: 3,
    ctaName: "",
    priority: "",
    leagueId: [],
    displaystylesId: linkInfo.intentId === 2 ? _PROSE_STYLE_ID : "",
    verticalsId: 1,
    selectedVerticalId: 1,
    eventsId: null,
    isArchived: false,
    startDate: dayjs().local(),
    scribanValues: variantInfo?.template || "[[link_url]]",
    endDate:
      !variantInfo && linkInfo.endDateTime
        ? dayjs(linkInfo.endDateTime).local()
        : "",
    timeZoneIana:
      variantInfo && variantInfo.timeZoneIana
        ? variantInfo.timeZoneIana
        : dayjs.tz.guess()
  })
  const [startDate, setStartDate] = useState(dayjs().local())
  const [endDate, setEndDate] = useState(
    !variantInfo && linkInfo.endDateTime
      ? dayjs(linkInfo.endDateTime).local()
      : null
  )
  const [timeZoneIana, setTimeZoneIana] = useState(formValues.timeZoneIana)
  const [errors, setErrors] = useState({})
  const [scribanValues, setScribanValues] = useState(formValues.scribanValues)
  const [isSaving, setIsSaving] = useState(false)
  const [templatePreview, setTemplatePreview] = useState("")
  const [showTemplatePreview, setShowTemplatePreview] = useState(false)
  const [orgHasAnalytics, setOrgHasAnalytics] = useState(false)
  const [variantIsLoading, setVariantIsLoading] = useState(false)
  const [selectedVerticalId, setVerticalId] = useState(1)
  // eslint-disable-next-line no-unused-vars
  const [endDateError, setEndDateError] = useState("")

  const queryParams = queryString.parse(window.location.search.toLowerCase())

  useEffect(() => {
    getAccounts()
    getVariantLeagues()
    getVariantVerticals()
    getVariantEvents()
    if (!variantInfo || !variantInfo?.id) {
      setVariantIsLoading(true)
    }
  }, [])

  // useEffect(() => {
  //   if (linkInfo) {
  //     if (linkInfo.intentId) {
  //       if (!variantInfo?.template) {
  //         setScribanValues(
  //           "[[link_url]] dsClick(this.dataset.dsidElement, this.text)"
  //         )
  //         //setScribanValues(linkInfo.url)
  //       } else {
  //         setScribanValues(variantInfo?.template)
  //       }
  //       setOrgHasAnalytics(linkInfo.orgHasAnalytics)
  //     }
  //   }
  // }, [linkInfo])
  useEffect(() => {
    if (linkInfo && linkInfo.intentId) {
      setOrgHasAnalytics(linkInfo.orgHasAnalytics)

      let newScribanValues

      if (!variantInfo?.template) {
        newScribanValues = "[[link_url]]"
        if (linkInfo.orgHasAnalytics) {
          newScribanValues +=
            ' onClick="dsClick(this.dataset.dsidElement, this.text)"'
        }
      } else {
        newScribanValues = variantInfo.template
        if (
          linkInfo.orgHasAnalytics &&
          !newScribanValues.includes(
            'onClick="dsClick(this.dataset.dsidElement, this.text)"'
          )
        ) {
          newScribanValues = newScribanValues.replace(
            /\[\[link_url\]\]/g,
            '[[link_url]] onClick="dsClick(this.dataset.dsidElement, this.text)"'
          )
        }
      }

      setScribanValues(newScribanValues)
      setFormValues(prev => ({ ...prev, scribanValues: newScribanValues }))
    }
  }, [linkInfo, variantInfo])

  useEffect(() => {
    if (variantInfo && variantInfo.id) {
      const startDateTime = variantInfo.startDateTime
        ? dayjs(variantInfo.startDateTime).local()
        : null
      setStartDate(startDateTime)
      const endDateTime = variantInfo.endDateTime
        ? dayjs(variantInfo.endDateTime).local()
        : null
      setEndDate(endDateTime)
      const leagueIds = variantInfo.leagues?.map(league => league.id) || []
      setFormValues({
        id: variantInfo.id,
        ownerAccountId: variantInfo.ownerAccountId,
        status: variantInfo.statusId,
        ctaName: variantInfo.name,
        priority: variantInfo.priority,
        leagueId: leagueIds,
        displaystylesId: variantInfo.displayStyleId,
        verticalsId: variantInfo.verticalId,
        eventsId: variantInfo.eventId,
        isArchived: variantInfo.isArchived,
        startDate: startDateTime,
        scribanValues: variantInfo.template,
        endDate: endDateTime,
        timeZoneIana: variantInfo.timeZoneIana
      })
      setOrgHasAnalytics(variantInfo.orgHasAnalytics)
      setShowTemplatePreview(false)
      setScribanValues(variantInfo.template)
      setVerticalId(variantInfo.verticalId)
      setVariantIsLoading(false)
    }
  }, [variantInfo, variantLeagues, onVariantCreated])

  const handleCancel = () => {
    if (variantInfo) {
      const startDateTime = variantInfo.startDateTime
        ? dayjs(variantInfo.startDateTime).local()
        : null
      const endDateTime = variantInfo.endDateTime
        ? dayjs(variantInfo.endDateTime).local()
        : null
      setFormValues({
        id: variantInfo.id,
        ownerAccountId: variantInfo.ownerAccountId,
        status: variantInfo.statusId,
        ctaName: variantInfo.name,
        priority: variantInfo.priority,
        leagueId: variantInfo.leagues?.map(league => league.id) || [],
        displaystylesId: variantInfo.displayStyleId,
        verticalsId: variantInfo.verticalId,
        eventsId: variantInfo.eventId,
        isArchived: variantInfo.isArchived,
        startDate: startDateTime,
        endDate: endDateTime,
        scribanValues: variantInfo.template,
        timeZoneIana: variantInfo.timeZoneIana
      })
      setScribanValues(variantInfo.template)
      setStartDate(startDateTime)
      setEndDate(endDateTime)
      setTimeZoneIana(timeZoneIana)
      setVerticalId(variantInfo.verticalId)
      setErrors({})
    } else {
      const currentTime = dayjs().startOf("day")
      setFormValues({
        ownerAccountId: 0,
        status: 3,
        ctaName: "",
        priority: "",
        leagueId: [],
        displaystylesId: null,
        verticalsId: 1,
        eventsId: null,
        startDate: currentTime,
        endDate: linkInfo.endDateTime
          ? dayjs(linkInfo.endDateTime).startOf("day")
          : "",
        isArchived: false
      })
      setScribanValues("[[link_url]]")
      setStartDate(currentTime)
      setEndDate(
        linkInfo.endDateTime ? dayjs(linkInfo.endDateTime).startOf("day") : null
      )
      setErrors({})
    }
  }

  const handleAutocompleteChange = (event, newValue, reason, fieldName) => {
    let value
    if (reason === "clear") {
      value = null
    } else {
      value = newValue ? newValue.id : null
    }

    setFormValues({ ...formValues, [fieldName]: value })
  }

  const validateForm = () => {
    let errors = {}
    if (!formValues.ctaName) {
      errors.ctaName = "CTA Name required"
    } else if (formValues.ctaName.length > maxLength) {
      errors.ctaName = `CTA Name cannot exceed ${maxLength} characters`
    }
    if (!formValues.priority) {
      errors.priority = "Priority is required"
    } else if (formValues.priority < 1 || formValues.priority > 1000) {
      errors.priority = "Number must be between 1 - 1000"
    }
    if (!formValues.verticalsId || formValues.verticalsId.length === 0) {
      errors.verticalsId = "Vertical required"
    }
    if (selectedVerticalId === 1) {
      if (!formValues.leagueId || formValues.leagueId.length === 0) {
        errors.leagueId = "League required"
      }
    }
    if (
      !formValues.displaystylesId ||
      formValues.displaystylesId.length === 0
    ) {
      errors.displaystylesId = "Display style required"
    }
    const isSingleMatch = scribanValues.match(/\[\[link_url\]\]/g) || []
    if (isSingleMatch.length < 1) {
      errors.template =
        " The Code Editor must have at least one occurence of [[link_url]]"
    }
    if (orgHasAnalytics) {
      const isNotProperGAonClickDoubleQuote = scribanValues.match(
        /onClick="dsClick\(this.dataset.dsidElement,this.text\)"/gi
      )
      const isNotProperGAonClickSingleQuote = scribanValues.match(
        /onClick='dsClick\(this.dataset.dsidElement,this.text\)'/gi
      )
      if (
        !isNotProperGAonClickDoubleQuote &&
        !isNotProperGAonClickSingleQuote
      ) {
        errors.template = errors.template
          ? `${errors.template} and an OnClick Event needs to be included within Variant Code Editor`
          : "An OnClick Event needs to be included within Variant Code Editor"
      }
    }
    if (startDate && endDate) {
      const dateStart = dayjs(startDate)
      const dateEnd = dayjs(endDate)
      const dateDiff = dateEnd.diff(dateStart)
      if (dateDiff < 0) {
        errors.endDate = `End Date must be after Start Date`
      }
    }
    if (linkInfo.endDateTime) {
      //const dateStart = dayjs(itm.startDate)
      const linkDateEnd = dayjs(linkInfo.endDateTime)
      const dateDiff = linkDateEnd.diff(endDate)
      if (dateDiff < 0) {
        errors.endDate = errors.endDate
          ? `${errors.endDate} and The Variant's End Date cannot be after the Link's End Date`
          : `The Variant's End Date cannot be after the Link's End Date`
      }
    }
    return errors
  }

  const addNewVariantQueryString = variantId => {
    const linkId =
      rightClickLinkInfo?.id || linkInfo?.id || queryParams["linkid"]
    let intentName
    if (rightClickLinkInfo.intentId) {
      intentName =
        rightClickLinkInfo.intentId === 1
          ? "offer"
          : rightClickLinkInfo.intentId === 2
          ? "disclaimer"
          : ""
    } else {
      intentName =
        linkInfo.intentId === 1
          ? "offer"
          : linkInfo.intentId === 2
          ? "disclaimer"
          : ""
    }
    if (linkId && variantId) {
      history.push(
        `/portal/manage-ctas/${intentName}?linkid=${linkId}&variantid=${variantId}`
      )
      getLinkDisplayInfo(linkInfo.intentId)
    }
  }

  const createVariant = async () => {
    if (!linkInfo?.id && !rightClickLinkInfo?.id) {
      setDialogWarningNoBtnMessage(
        "You must select a Link to associated where the new Variant goes"
      )
      setDialogWarningNoBtnOpen(true)
      return null
    }
    setIsSaving(true)
    try {
      let startDateTimeInTimeZone = null
      if (startDate) {
        startDateTimeInTimeZone = dayjs(startDate).toISOString()
      }

      let endDateTimeInTimeZone = null
      if (endDate) {
        endDateTimeInTimeZone = dayjs(endDate).toISOString()
      }

      let variantCreateRequest = {
        linkId: rightClickLinkInfo.id || linkInfo.id,
        ownerAccountId: formValues.ownerAccountId,
        statusId: formValues.status,
        verticalId: formValues.verticalsId,
        eventId: formValues.eventsId,
        displayStyleId: formValues.displaystylesId,
        name: formValues.ctaName,
        leagues: formValues.leagueId
          ? formValues.leagueId.map(id => ({ id }))
          : [],
        template: scribanValues,
        isArchived: false,
        priority: formValues.priority,
        startDateTime: startDateTimeInTimeZone,
        endDateTime: endDateTimeInTimeZone,
        timeZoneIana: timeZoneIana
      }
      if (formValues.id) {
        variantCreateRequest.id = formValues.id
      }
      const data = await Cta_v2DAO.postVariant(variantCreateRequest)
      if (data) {
        onVariantCreated(data.id)
        getVariantsByLinkId(rightClickLinkInfo.id || linkInfo.id, true)
        return data
      } else {
        return null
      }
    } catch (err) {
      console.error("Error on POST to CTA Variant", err)
    } finally {
      setIsSaving(false)
    }
  }
  const editVariant = async linkId => {
    setIsSaving(true)
    try {
      let startDateTimeInTimeZone = null
      if (startDate) {
        startDateTimeInTimeZone = dayjs(startDate)
          .set({
            hour: startDate.hour(),
            minute: startDate.minute(),
            second: startDate.second()
          })
          .toISOString()
      }

      let endDateTimeInTimeZone = null
      if (endDate) {
        endDateTimeInTimeZone = dayjs(endDate)
          .set({
            hour: endDate.hour(),
            minute: endDate.minute(),
            second: endDate.second()
          })
          .toISOString()
      }

      let variantEdit = {
        id: variantInfo.id,
        linkId: linkId,
        ownerAccountId: formValues.ownerAccountId,
        statusId: formValues.status,
        verticalId: formValues.verticalsId,
        eventId: formValues.eventsId,
        displayStyleId: formValues.displaystylesId,
        name: formValues.ctaName,
        leagues: formValues.leagueId
          ? formValues.leagueId.map(id => ({ id }))
          : [],
        template: scribanValues,
        isArchived: formValues.isArchived,
        priority: formValues.priority,
        startDateTime: startDateTimeInTimeZone,
        endDateTime: endDateTimeInTimeZone,
        timeZoneIana: timeZoneIana
      }
      if (variantInfo && variantInfo.id) {
        variantEdit.id = variantInfo.id
      }
      const data = await Cta_v2DAO.postVariant(variantEdit) //update
      if (data) {
        return data
      } else {
        return null
      }
    } catch (err) {
      console.error("Error on PUT to CTA Variant", err)
      if (err.message) {
        let str = []
        Object.keys(err).forEach(k => {
          if (typeof err[k] === "object") {
            let myStr = []
            err[k].forEach(itm => {
              myStr.push(itm)
            })
            str.push({ type: k, value: myStr.join("\n") })
          } else {
            str.push({ type: k, value: err[k] })
          }
        })
        const msg = str.length
          ? str
          : [
              { type: "message", value: err?.message },
              { type: "stack", value: err?.stack }
            ]
        setDialogFailMessage(msg)
        setDialogFailOpen(true)
      }
      setIsSaving(false)
    } finally {
      setIsSaving(false)
    }
  }

  const handleStatusChange = newValue => {
    setFormValues(prevState => ({
      ...prevState,
      status: newValue
    }))
  }
  const handleCheckboxChange = event => {
    setFormValues({ ...formValues, [event.target.id]: event.target.checked })
  }

  const onTimeZoneChange = tz => {
    setTimeZoneIana(tz.value)
  }

  const handleEditorChange = editor => {
    if (editor && editor.getValue()) {
      const editorContent = editor.getValue()
      const isSingleMatch = editorContent.match(/\[\[link_url\]\]/g) || []

      if (orgHasAnalytics) {
        if (
          !editorContent.includes(
            'onClick="dsClick(this.dataset.dsidElement, this.text)"'
          )
        ) {
          const updatedContent = editorContent.replace(
            /\[\[link_url\]\]/g,
            '[[link_url]] onClick="dsClick(this.dataset.dsidElement, this.text)"'
          )
          setScribanValues(updatedContent)
        } else {
          setScribanValues(editorContent)
        }
      } else {
        setScribanValues(editorContent)
      }

      if (isSingleMatch.length === 1) {
        setScribanValues(editorContent)
        setErrors(prevErrors => ({
          ...prevErrors,
          template: false,
          validationHasError: false
        }))
      } else {
        setScribanValues(editorContent)
        setErrors(prevErrors => ({
          ...prevErrors,
          template:
            "The Code Editor must have at least one occurrence of [[link_url]]"
        }))
      }

      if (!editorContent) {
        setErrors(prevErrors => ({
          ...prevErrors,
          template: "Template is required"
        }))
      } else {
        setErrors(prevErrors => {
          const { template, ...rest } = prevErrors
          return rest
        })
      }
    } else {
      setScribanValues("")
      setErrors(prevErrors => ({
        ...prevErrors,
        template: "Template is required"
      }))
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const validationErrors = validateForm()
    setErrors(validationErrors)
    if (Object.keys(validationErrors).length === 0) {
      setIsSaving(true)
      try {
        let success
        if (variantInfo) {
          success = await editVariant(variantInfo.linkId)
        } else {
          success = await createVariant()
        }
        setIsSaving(false)
        if (success) {
          const str = variantInfo
            ? "CTA Variant Update succeeded"
            : `CTA Variant Creation succeeded. New ID is:${success.id}`
          setDialogSuccessMessage(str)
          setDialogSuccessOpen(true)
          if (!variantInfo) {
            setFormValues({ ...formValues, id: success.id })
            addNewVariantQueryString(success.id)
            closePage(true)
            fetchVariantById(success.id)
          } else {
            if (variantInfo?.name !== success?.name) {
              getVariantsByLinkId(success.linkId || linkInfo.id, true)
            }
            closePage(true)
            fetchVariantById(variantInfo.id)
          }
        }
      } catch (error) {
        setDialogFailMessage(error)
        setDialogFailOpen(true)
        setIsSaving(false)
        console.error("Error while saving variant:", error)
        // Handle error
        setIsSaving(false)
      }
    }
  }

  const clearStartDateFields = () => {
    setStartDate(dayjs(startDate).startOf("day"))
  }
  const clearEndDateFields = () => {
    setEndDate(null)
  }

  const handlePreview = async () => {
    try {
      const linkId =
        rightClickLinkInfo?.id || linkInfo?.id || queryParams["linkid"]
      if (linkInfo) {
        if (
          linkInfo.affiliateId === 1 &&
          linkInfo.url.includes("[[affiliate_code]]")
        ) {
          getRandomShortCode(linkId)
        } else {
          setRandomShortCode([])
        }
      }

      let fetchedTemplateString = ""
      const dependencies = await Cta_v2DAO.getPreviewDependencyByIntent(
        intentId
      )
      fetchedTemplateString =
        dependencies.length > 0 ? dependencies[0].dependency : ""

      let previewUrl = randomShortCode[0]
        ? randomShortCode[0].affiliateUrl
        : linkInfo.url
      let templateString = `${fetchedTemplateString} ${scribanValues}`
      templateString = templateString.replaceAll("[[link_url]]", previewUrl)

      const previewRequest = {
        templateString,
        useCache: false,
        excludeDependencies: true
      }

      const evaluatedTemplate = await NarrativePreviewDAO.evaluateSentence(
        previewRequest
      )
      setShowTemplatePreview(true)
      setTemplatePreview(evaluatedTemplate)
    } catch (error) {
      console.error("Error while fetching template preview:", error)
    }
  }

  const noOwner = {
    name: "NONE",
    firstName: "NONE",
    lastName: "",
    id: null
  }
  const sortedAccounts = [noOwner].concat(
    accounts
      ?.toJS()
      .filter(itm => itm.status === "Active")
      .sort((a, b) => {
        // Use toUpperCase() to ignore character casing
        const nameA = `${a.firstName} ${a.lastName}`.toUpperCase()
        const nameB = `${b.firstName} ${b.lastName}`.toUpperCase()
        let comparison = 0
        if (nameA > nameB) {
          comparison = 1
        } else if (nameA < nameB) {
          comparison = -1
        }
        return comparison
      })
  )
  const maxLength = 50

  const getEndDateError = value => {
    // if (value !== null && startDate === null) {
    //   return "Start Date required with End Date"
    // }
    if (value === null) {
      return ""
    }
    return ""
  }
  const endDateErrorMessage = getEndDateError(endDate)

  const handleBlurStart = e => {
    console.log(e)
  }
  const handleBlurEnd = e => {
    console.log(e)
  }

  return (
    <div
      aria-label="CTA Variant Form"
      className={
        variantIsLoading && !props.newVariant ? classes.CTAGridOpacity : null
      }
    >
      {variantIsLoading && !props.newVariant && <LinearProgress />}
      <Card elevation={1}>
        <FormControl aria-label="CTAV2 VariantForm CreateEdit">
          <Grid>
            <Paper>
              <Grid
                className={classes.ctaContainer}
                justifyContent="flex-end"
                alignItems="center"
              >
                <GridItem xs={4}>
                  <Autocomplete
                    aria-label="CTAVariantForm OwnerField UpdateOwnerField"
                    className={classes.ctaTop}
                    size="small"
                    fullWidth
                    style={{ width: "135px" }}
                    options={sortedAccounts || {}}
                    required={true}
                    getOptionLabel={option =>
                      `${option.firstName} ${option.lastName}`
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="OWNER"
                        key={params.id}
                        value={params.name}
                      />
                    )}
                    value={
                      sortedAccounts?.find(
                        acct => acct.id === formValues.ownerAccountId
                      ) ?? null
                    }
                    onChange={(event, newValue, reason) => {
                      handleAutocompleteChange(
                        event,
                        newValue,
                        reason,
                        "ownerAccountId"
                      )
                    }}
                  />
                </GridItem>
                <GridItem xs={3}>
                  <CTAStatusTypes
                    value={formValues.status || ""}
                    onChange={handleStatusChange}
                  />
                </GridItem>
              </Grid>
              <Grid>
                <GridItem>
                  <Typography style={{ fontWeight: "bold" }}>
                    CTA Variant Info
                  </Typography>
                </GridItem>
              </Grid>
              <Grid className={classes.ctaContainer}>
                <GridItem>
                  <TextField
                    aria-label="CTAv2 VariantForm variantIdField"
                    label={"VARIANT ID"}
                    id="variantInfo"
                    value={formValues?.id || "NEW VARIANT"}
                    disabled={formValues?.id}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </GridItem>
                <GridItem>
                  <TextField
                    aria-label="CTAv2 VariantForm nameField"
                    required
                    label="VARIANT NAME"
                    fullWidth
                    sx={{ width: "250px" }}
                    id="variantName"
                    value={formValues.ctaName}
                    error={!!errors.ctaName}
                    helperText={
                      errors.ctaName ? errors.ctaName : "50 characters limit"
                    }
                    onChange={e => {
                      const newCtaName = e.target.value.slice(0, maxLength)
                      setFormValues({ ...formValues, ctaName: newCtaName })
                    }}
                    InputProps={{ maxLength }}
                  />
                </GridItem>
                <GridItem>
                  <TextField
                    aria-label="CTAv2 VariantForm Priority"
                    id="priority"
                    label="PRIORITY"
                    type="text"
                    value={formValues.priority}
                    required
                    error={!!errors.priority}
                    helperText={errors.priority}
                    onChange={event => {
                      setFormValues({
                        ...formValues,
                        priority: !event.target.value
                          ? ""
                          : event.target.value.match(/[^0-9]/)
                          ? formValues.priority
                          : event.target.value < _MIN_PRIORITY
                          ? _MIN_PRIORITY
                          : event.target.value > _MAX_PRIORITY - 1
                          ? _MAX_PRIORITY
                          : event.target.value
                      })
                    }}
                    onClear={() =>
                      setFormValues({ ...formValues, priority: "" })
                    }
                  />
                </GridItem>
                <GridItem>
                  <FormControlLabel
                    control={
                      <Checkbox
                        aria-label="CTAv2 VariantForm archiveCheckbox"
                        id="isArchived"
                        checked={formValues.isArchived}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label="ARCHIVE"
                    labelPlacement="end"
                  />
                </GridItem>
              </Grid>
              <Grid className={classes.monetizationHeader}>
                <GridItem>
                  <Typography style={{ fontWeight: "bold" }}>
                    CTA Tags
                  </Typography>
                </GridItem>
              </Grid>
              <Grid className={classes.monetizationCard}>
                <GridItem>
                  <Autocomplete
                    aria-label="CTAv2 VariantForm verticalsAutocomplete"
                    className={classes.autocompleteCTA}
                    sx={{ width: 200 }}
                    fullWidth
                    required={true}
                    options={verticals || []}
                    value={
                      verticals?.find(
                        vertical => vertical.id === formValues.verticalsId
                      ) || null
                    }
                    getOptionLabel={option => option.name}
                    size="large"
                    id="verticalId"
                    onChange={(event, newValue) => {
                      setFormValues({
                        ...formValues,
                        verticalsId: newValue ? newValue.id : null
                      })
                      setVerticalId(newValue ? newValue.id : null)
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        label="Vertical"
                        error={!!errors.verticalsId}
                        helperText={errors.verticalsId}
                      />
                    )}
                  />
                </GridItem>
                {selectedVerticalId === 1 && (
                  <GridItem>
                    <Autocomplete
                      aria-label="CTAv2 VariantForm leagueAutocomplete"
                      className={classes.autocompleteCTA}
                      fullWidth
                      sx={{ minWidth: 200 }}
                      required={true}
                      multiple
                      limitTags={2}
                      id="leagueId"
                      options={variantLeagues || []}
                      value={
                        variantInfo
                          ? formValues.leagueId.map(id =>
                              variantLeagues?.find(league => league?.id === id)
                            )
                          : variantLeagues?.filter(
                              league =>
                                formValues.leagueId &&
                                formValues.leagueId.includes(league.id)
                            )
                      }
                      isOptionEqualToValue={(option, value) =>
                        option?.id === value?.id
                      }
                      getOptionLabel={option => (option ? option.name : "")}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="LEAGUE"
                          required={true}
                          error={!!errors.leagueId}
                          helperText={errors.leagueId ? errors.leagueId : ""}
                        />
                      )}
                      onChange={(event, newValue) => {
                        const selectedIds = newValue.map(option => option?.id)
                        setFormValues({ ...formValues, leagueId: selectedIds })
                      }}
                    />
                  </GridItem>
                )}
                <GridItem>
                  <Autocomplete
                    aria-label="CTAv2 VariantForm displayAutocomplete"
                    className={classes.autocompleteCTA}
                    sx={{ width: 200 }}
                    fullWidth
                    required={true}
                    options={
                      displayStyle?.filter(
                        option => option.intentId === intentId
                      ) || []
                    }
                    value={
                      displayStyle?.find(
                        display => display.id === formValues.displaystylesId
                      ) || null
                    }
                    getOptionLabel={option => option.name}
                    size="large"
                    id="displaystylesId"
                    onChange={(event, newValue) => {
                      setFormValues({
                        ...formValues,
                        displaystylesId: newValue ? newValue.id : null
                      })
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        label="DISPLAY STYLE"
                        error={!!errors.displaystylesId}
                        helperText={errors.displaystylesId}
                      />
                    )}
                  />
                </GridItem>
                <GridItem>
                  <Autocomplete
                    aria-label="CTAv2 VariantForm eventAutocomplete"
                    className={classes.autocompleteCTA}
                    id="events"
                    sx={{ width: 200 }}
                    fullWidth
                    options={events || "No options"}
                    value={
                      events && events.length > 0
                        ? events?.find(
                            event => event.id === formValues.eventsId
                          ) || null
                        : null
                    }
                    noOptionsText="No options"
                    getOptionLabel={option => option.name}
                    renderInput={params => (
                      <TextField {...params} label="EVENTS" />
                    )}
                    onChange={(event, newValue) => {
                      setFormValues({
                        ...formValues,
                        eventsId: newValue ? newValue.id : null
                      })
                    }}
                  />
                </GridItem>
              </Grid>
              <Grid>
                <GridItem>
                  <Typography style={{ fontWeight: "bold" }}>
                    Variant Timeframe
                  </Typography>
                </GridItem>
              </Grid>
              <Grid className={classes.ctaContainer}>
                <GridItem>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDateTimePicker
                      aria-label="CTAv2 VariantForm startDateTimePicker"
                      label="START DATE TIME"
                      value={startDate}
                      onSelect={newStartDate => {
                        setStartDate(newStartDate)
                      }}
                      onChange={newStartDate => {
                        // Set the end date
                        setStartDate(newStartDate)
                      }}
                      //onChange={setStartDate}
                      closeOnSelect={true}
                      slotProps={{
                        field: {
                          clearable: true,
                          onClear: clearStartDateFields
                        },
                        textField: {
                          onBlur: handleBlurStart
                        }
                      }}
                    />
                  </LocalizationProvider>
                </GridItem>
                <GridItem>
                  <TimezoneSelect
                    value={timeZoneIana}
                    onChange={onTimeZoneChange}
                    labelStyle="abbrev"
                    label="TimeZone"
                  />
                </GridItem>
                <GridItem>
                  {!errors.endDate && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDateTimePicker
                        aria-label="CTAv2 VariantForm endDateTimePicker"
                        label="END DATE TIME"
                        value={endDate}
                        closeOnSelect={true}
                        onSelect={newEndDate => {
                          setEndDate(newEndDate)
                        }}
                        onChange={newEndDate => {
                          if (linkInfo.endDateTime) {
                            //const dateStart = dayjs(itm.startDate)
                            const linkDateEnd = dayjs(linkInfo.endDateTime)
                            const dateDiff = linkDateEnd.diff(newEndDate)
                            if (dateDiff < 0) {
                              const errorObj = {
                                ...errors,
                                endDate: `The Variant's End Date cannot be after the Link's End Date`
                              }
                              setErrors(errorObj)
                            } else {
                              // Set the end date
                              const errorObj = {
                                ...errors,
                                endDate: null
                              }
                              setErrors(errorObj)
                            }
                            setEndDate(newEndDate)
                          } else {
                            // Set the end date
                            setEndDate(newEndDate)
                            const errorObj = {
                              ...errors,
                              endDate: null
                            }
                            setErrors(errorObj)
                          }
                        }}
                        slotProps={{
                          field: {
                            clearable: true,
                            onClear: clearEndDateFields
                          },
                          textField: {
                            helperText: endDateErrorMessage,
                            error: Boolean(endDateErrorMessage),
                            onBlur: handleBlurEnd
                          }
                        }}
                      />
                    </LocalizationProvider>
                  )}
                  {errors.endDate && (
                    <div
                      style={{
                        border: "1px solid red",
                        padding: "10px 0 0 0"
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDateTimePicker
                          aria-label="CTAv2 VariantForm endDateTimePicker"
                          label="END DATE TIME"
                          value={endDate}
                          closeOnSelect={true}
                          onSelect={newEndDate => {
                            setEndDate(newEndDate)
                          }}
                          onChange={newEndDate => {
                            if (linkInfo.endDateTime) {
                              //const dateStart = dayjs(itm.startDate)
                              const linkDateEnd = dayjs(linkInfo.endDateTime)
                              const dateDiff = linkDateEnd.diff(newEndDate)
                              if (dateDiff < 0) {
                                const errorObj = {
                                  ...errors,
                                  endDate: `The Variant's End Date cannot be after the Link's End Date`
                                }
                                setErrors(errorObj)
                              } else {
                                // Set the end date
                                const errorObj = {
                                  ...errors,
                                  endDate: null
                                }
                                setErrors(errorObj)
                              }
                              setEndDate(newEndDate)
                            } else {
                              // Set the end date
                              setEndDate(newEndDate)
                              const errorObj = {
                                ...errors,
                                endDate: null
                              }
                              setErrors(errorObj)
                            }
                          }}
                          slotProps={{
                            field: {
                              clearable: true,
                              onClear: clearEndDateFields
                            },
                            textField: {
                              helperText: endDateErrorMessage,
                              error: Boolean(endDateErrorMessage),
                              onBlur: handleBlurEnd
                            }
                          }}
                        />
                      </LocalizationProvider>
                      <div>
                        <FormHelperText className={classes.requiredMsg}>
                          {errors.endDate}
                        </FormHelperText>
                      </div>
                    </div>
                  )}
                </GridItem>
              </Grid>
              <Grid>
                <CodeEditor
                  withTabs
                  template={scribanValues}
                  handleChange={handleEditorChange}
                />
                {errors.template && (
                  <Alert variant="filled" severity="error">
                    {errors.template}
                  </Alert>
                )}
              </Grid>
              {isSaving && <LinearProgress />}
              <Grid className={classes.CTAButton}>
                <>
                  <Button
                    aria-label="CTAv2 VariantForm previewBtn"
                    className={classes.buttonStyle}
                    variant="contained"
                    size="medium"
                    color="secondary"
                    onClick={() => handlePreview()}
                  >
                    Preview
                  </Button>
                  <Button
                    aria-label="CTAv2 VariantForm cancelBtn"
                    className={classes.buttonStyle}
                    variant="contained"
                    size="medium"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    aria-label="CTAv2 VariantForm saveBtn"
                    className={classes.buttonStyle}
                    variant="contained"
                    size="medium"
                    color="primary"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isSaving}
                  >
                    Save
                  </Button>
                </>
              </Grid>
            </Paper>
          </Grid>
        </FormControl>
        {showTemplatePreview && (
          <Grid
            container
            spacing={1}
            item
            xs={12}
            style={{
              display: "inline-block",
              paddingTop: "16px",
              paddingLeft: "24px"
            }}
          >
            <WordCounter
              aria-label="CTAv2 VariantForm wordCounterPreviewTemplate"
              title="Metrics"
              inputString={templatePreview}
            />
            <Grid
              item
              style={{
                marginTop: "10px",
                fontSize: "16px"
              }}
            >
              <div
                style={{
                  marginTop: "10px",
                  fontSize: "14px",
                  position: "relative"
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    templatePreview && templatePreview.replace(/apxh:/g, "")
                }}
              />
            </Grid>
          </Grid>
        )}
      </Card>
    </div>
  )
}
