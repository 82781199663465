import { action, observable, decorate, runInAction } from "mobx"
import dsPromptDAO from "daos/dsPromptDAO"

export default class DSPromptStore {
  rootStore
  loading
  systemPrompts = []

  constructor(rootStore) {
    this.rootStore = rootStore
    // DS-6314
    //this.loadPrompts()
  }

  loadPrompts = async () => {
    this.loading = true
    try {
      const prompts = await dsPromptDAO.getPrompts()
      const parsedPrompts = JSON.parse(prompts) // assuming the response is JSON
      runInAction(() => {
        this.prompts = parsedPrompts
        this.systemPrompts = parsedPrompts
          .filter(prompt => prompt.role === "system")
          .sort((a, b) => a.name.localeCompare(b.name))
        this.loading = false
      })
    } catch (e) {
      runInAction(() => {
        console.error("Failed to load prompts:", e)
        this.loading = false
      })
    }
  }

  // Get user prompts that rely on a specific system prompt
  getUserPromptsBySystemPrompt = systemPromptId =>
    this.prompts?.filter(
      prompt =>
        prompt.role === "user" && prompt.systemPromptId === systemPromptId
    )

  // Add a new prompt to the store
  addPrompt = prompt => {
    this.prompts.push(prompt)
  }

  // Remove a prompt by ID
  removePromptById = promptId => {
    const index = this.prompts.findIndex(prompt => prompt.id === promptId)
    if (index !== -1) {
      this.prompts.splice(index, 1)
    }
  }
}

decorate(DSPromptStore, {
  systemPrompts: observable,
  loadPrompts: action.bound,
  getUserPromptsBySystemPrompt: action.bound,
  addPrompt: action.bound,
  removePromptById: action.bound
})
