import React from "react"
import PropTypes from "prop-types"
import makeStyles from "@mui/styles/makeStyles"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  ctaSelect: {
    maxHeight: "35px"
  }
}))
function CTAStatusTypes({ value, onChange }) {
  const classes = useStyles()

  return (
    <>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel>STATUS</InputLabel>
        <Select
          className={classes.ctaSelect}
          fullWidth={false}
          name="CTAStatusTypeInput"
          id="CTAStatusType"
          size="small"
          value={value || ""}
          onChange={e => {
            onChange(e.target.value)
          }}
          label="STATUS"
          inputProps={{
            name: "status",
            id: "status"
          }}
        >
          <MenuItem aria-label="None" value="" />
          <MenuItem value={1}>Draft</MenuItem>
          <MenuItem value={2}>Review</MenuItem>
          <MenuItem value={3}>Published</MenuItem>
          <MenuItem value={4}>Planned</MenuItem>
          <MenuItem value={5}>Sample</MenuItem>
        </Select>
      </FormControl>
    </>
  )
}

CTAStatusTypes.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
  disabled: PropTypes.bool
}

export default CTAStatusTypes
