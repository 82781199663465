import React, { useEffect, useState, useMemo } from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
//import RealTimeDAO from "daos/realTimeDAO"

import Helpers from "tools/Helpers"
//import RealTimeFormTemplate from "./RealTimeDialogFormTemplate"
import ChangeLog from "components/NarrativeAdmin/Data/ChangeLog"
import GridItem from "components/Grid/GridItem"

import { makeStyles } from "@mui/styles"
import {
  Grid,
  Autocomplete,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
  IconButton,
  Card,
  CardHeader,
  Alert,
  Snackbar,
  Button
} from "@mui/material"

import ExpandMore from "@mui/icons-material/ExpandMore"
import { Close } from "@mui/icons-material"
import RealTimeInsightStatus from "../RealTimeInsightStatus"
import FormTemplateNonDialog from "./FormTemplateNonDialog"
import { useHistory } from "react-router-dom"
import { useLocation } from "react-router-dom"

const useStyles = makeStyles({
  ownerGrid: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingTop: "4px"
  },
  formRow: {
    display: "flex",
    padding: "24px 0"
  }
})

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    getInsightGroup: store.realTimeStore.getInsightGroup,
    insightTypes: store.realTimeStore.insightTypes,
    accounts: store.accountStore.accounts,
    getAccounts: store.accountStore.getAccounts,
    organizations: store.organizationStore.organizations,
    getOrganizations: store.organizationStore.getOrganizations,
    setDialogSuccessMessage: store.uiStore.setDialogSuccessMessage,
    setDialogSuccessOpen: store.uiStore.setDialogSuccessOpen,
    setDialogFailMessage: store.uiStore.setDialogFailMessage,
    setDialogFailOpen: store.uiStore.setDialogFailOpen
  }))
}

export default function InsightGroupForm({
  open,
  onClose,
  onSave,
  onReset,
  libraryId,
  groupData,
  readOnly
}) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const groupId = queryParams.get("groupId")
  const {
    getAccounts,
    accounts,
    setDialogFailMessage,
    setDialogFailOpen,
    setDialogSuccessMessage,
    setDialogSuccessOpen,
    getOrganizations,
    organizations,
    getInsightGroup,
    insightTypes
  } = useStoreData()
  // eslint-disable-next-line no-unused-vars
  const [initialFormValues, setInitialFormValues] = useState({
    owner: { id: null, name: "" },
    id: null,
    statusId: libraryId?.status || { id: null, name: "" },
    //groupId: libraryId?.id || { id: null, name: "" },
    name: "",
    descrition: "",
    labels: [],
    insightTypes: null,
    isArchived: false
  })
  const [groupFormValues, setGroupFormValues] = useState(initialFormValues)
  // eslint-disable-next-line no-unused-vars
  const [inEdit, setInEdit] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [linkIsLoading, setLinkIsLoading] = useState(false)

  useEffect(() => {
    const tempAccount = accounts?.toJS()
    if (!tempAccount || tempAccount?.length === 0) {
      getAccounts()
    }
    const tempOrgs = organizations?.toJS()
    if (!tempOrgs || tempOrgs?.length === 0) {
      getOrganizations()
    }
  }, [])

  useEffect(() => {
    if (linkIsLoading) {
      setLinkIsLoading(false)
    }
  }, [linkIsLoading])

  useEffect(() => {
    if (groupData) {
      const selectedGroup = groupData
      const initialFormValues = {
        owner: {
          id: selectedGroup.owner?.id || null,
          name: selectedGroup.owner?.name || ""
        },
        insightTypeId: selectedGroup.insightType?.id,
        statusId: selectedGroup.status?.id || "",
        groupId: selectedGroup.id || 0,
        name: selectedGroup.name || "",
        description: selectedGroup.description || "",
        libraryId: selectedGroup.libraryId || "",
        insightType: selectedGroup.insightType || null,
        labels: selectedGroup.organizations || [],
        isArchived: false
      }
      setGroupFormValues(initialFormValues)
      setInitialFormValues(initialFormValues)
      setInEdit(true)
      setLinkIsLoading(true)
    }
  }, [groupData])

  const handleCancel = () => {
    if (initialFormValues) {
      setGroupFormValues(initialFormValues)
    } else {
      setGroupFormValues({
        ownerId: 1,
        statusId: 3,
        groupId: null,
        name: "",
        labels: [],
        isArchived: false
      })
    }
    setLinkIsLoading(true)
  }
  const handleSubmit = async () => {
    const formVals = {
      // id: 0,
      groupId: groupFormValues.groupId,
      name: groupFormValues.name,
      ownerAccountId: groupFormValues.owner.id,
      description: groupFormValues.description || "",
      libraryId: libraryId,
      organizationIds: groupFormValues.labels?.map(org => org.id) || [],
      insightTypeId: groupFormValues.insightTypeId,
      statusId: groupFormValues.statusId || 3,
      isArchived: groupFormValues.isArchived || false
    }
    const response = await onSave(formVals)
    if (groupFormValues.groupId) {
      if (response?.ok) {
        setDialogSuccessMessage(
          `You have successfully Updated Group "${groupFormValues.groupId} - ${groupFormValues.name}"`
        )
        setDialogSuccessOpen(true)
        getInsightGroup(libraryId)
        setInitialFormValues(groupFormValues)
      } else {
        setDialogFailMessage(
          `You have FAILED in Updating Group "${groupFormValues.groupId} - ${groupFormValues.name}"`
        )
        setDialogFailOpen(true)
      }
    } else {
      if (response?.id) {
        setDialogSuccessMessage(
          `You have successfully Created Group "${response?.id} - ${groupFormValues.name}"`
        )
        setDialogSuccessOpen(true)
        getInsightGroup(libraryId)
        setGroupFormValues({
          ...groupFormValues,
          id: response.id,
          groupId: response.id
        })
        setInitialFormValues({
          ...groupFormValues,
          id: response.id,
          groupId: response.id
        })
        history.push(
          `/portal/real-time-library/edit?id=${libraryId}&groupId=${response.id}`
        )
      } else {
        setDialogFailMessage(
          `You have FAILED in Creating Group "${groupFormValues.name}"`
        )
        setDialogFailOpen(true)
      }
    }
  }

  const sortedOrganizations = useMemo(
    () => organizations && organizations?.toJS().sort(Helpers.sortByName),
    [organizations]
  )
  const handleCheckboxChange = event => {
    setGroupFormValues({
      ...groupFormValues,
      isArchived: event.target.checked
    })
  }

  // const handleClose = () => {
  //   onClose()
  // }
  const handleConfirmClose = () => {
    //handlesave function
    setOpenAlert(false)
    onClose()
    history.push(`/portal/real-time-library/edit?id=${libraryId}`)
  }

  const closeAlert = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setOpenAlert(false)
  }

  const handleViewinLibrary = () => {
    const url = `/portal/real-time-library/edit?id=${libraryId}&groupId=${groupId}`
    window.open(url, "_blank")
  }

  const handleClose = () => {
    if (!readOnly) {
      setOpenAlert(true)
    } else {
      onClose()
    }
  }
  const noOwner = {
    name: "NONE",
    firstName: "NONE",
    lastName: "",
    id: null
  }
  const sortedAccounts = [noOwner].concat(
    accounts
      ?.toJS()
      .filter(itm => itm.status === "Active")
      .sort((a, b) => {
        // Use toUpperCase() to ignore character casing
        const nameA = `${a.firstName} ${a.lastName}`.toUpperCase()
        const nameB = `${b.firstName} ${b.lastName}`.toUpperCase()
        let comparison = 0
        if (nameA > nameB) {
          comparison = 1
        } else if (nameA < nameB) {
          comparison = -1
        }
        return comparison
      })
  )

  return (
    <Card>
      <CardHeader
        action={
          <IconButton onClick={handleClose} style={{ right: "8px" }}>
            <Close />
          </IconButton>
        }
      />
      {!linkIsLoading && (
        <FormTemplateNonDialog
          open={open}
          onReset={handleCancel}
          onSave={handleSubmit}
          onClose={onClose}
          title={"Insight Group"}
          readOnly={readOnly}
          onViewInLibrary={handleViewinLibrary}
        >
          {/* <IconButton onClick={handleClose} style={{ right: "8px" }}>
          <Close />
        </IconButton> */}
          <Grid className={classes.ownerGrid}>
            {
              <GridItem xs={2}>
                <Autocomplete
                  size="small"
                  name="ownerId"
                  id="ownerId"
                  options={sortedAccounts}
                  getOptionLabel={option =>
                    option.id === null
                      ? "None"
                      : `${option.firstName} ${option.lastName}`
                  }
                  value={
                    groupFormValues.owner
                      ? sortedAccounts.find(
                          acc => acc.id === groupFormValues.owner.id
                        ) || null
                      : null
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value?.id
                  }
                  onChange={(event, newValue) => {
                    setGroupFormValues(prev => ({
                      ...prev,
                      owner: newValue
                        ? {
                            id: newValue.id,
                            name: `${newValue.firstName} ${newValue.lastName}`
                          }
                        : { id: null, name: "" }
                    }))
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="OWNER"
                      key={params.id}
                      value={params.name}
                    />
                  )}
                  disabled={readOnly}
                />
              </GridItem>
            }
            <GridItem xs={3}>
              <RealTimeInsightStatus
                value={groupFormValues.statusId}
                onChange={newValue => {
                  setGroupFormValues(prev => ({
                    ...prev,
                    statusId: newValue ? newValue.id : null,
                    statusName: newValue ? newValue.name : null
                  }))
                }}
                readOnly={readOnly}
              />
            </GridItem>
          </Grid>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2 }}
            className={classes.formRow}
          >
            <GridItem xs={2}>
              {groupFormValues.groupId > 0 && (
                <TextField
                  id="groupId"
                  label="GROUP ID"
                  fullWidth={true}
                  value={groupFormValues.groupId}
                  InputLabelProps={{ shrink: true }}
                  disabled={readOnly}
                />
              )}
              {!groupFormValues.groupId && (
                <TextField
                  label="NEW GROUP"
                  id="sentenceId"
                  variant="outlined"
                  fullWidth={true}
                  disabled={true}
                />
              )}
            </GridItem>
            <GridItem xs={4}>
              <TextField
                required
                id="name"
                name="name"
                label="GROUP NAME"
                fullWidth={true}
                value={groupFormValues.name}
                onChange={e =>
                  setGroupFormValues({
                    ...groupFormValues,
                    name: e.target.value
                  })
                }
                disabled={readOnly}
              />
            </GridItem>
            <GridItem xs={3}>
              <Autocomplete
                aria-label="RealTimeLibrary SentenceForm InsightTypeField"
                id="insightTypeId"
                options={insightTypes || []}
                value={
                  insightTypes.find(
                    type => type.id === groupFormValues.insightTypeId
                  ) || null
                }
                getOptionLabel={option => option.name || ""}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                renderInput={params => (
                  <TextField {...params} label="INSIGHT TYPE" />
                )}
                onChange={(event, newValue) => {
                  setGroupFormValues(prev => ({
                    ...prev,
                    insightTypeId: newValue ? newValue.id : "",
                    insightTypeName: newValue
                      ? newValue.name
                      : "no insight name"
                  }))
                }}
                disabled={true}
              />
            </GridItem>
          </Grid>
          <Grid
            container
            columnSpacing={{ xs: 1, sm: 2 }}
            className={classes.formRow}
          >
            <GridItem xs={6}>
              <Autocomplete
                aria-label="RealTime InsightGroupForm LabelsField"
                id="labelsId"
                multiple
                limitTags={2}
                options={sortedOrganizations}
                value={groupFormValues.labels}
                getOptionLabel={option => option.name || ""}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                renderInput={params => <TextField {...params} label="LABELS" />}
                onChange={(event, selectedOptions) => {
                  setGroupFormValues({
                    ...groupFormValues,
                    labels: selectedOptions
                  })
                }}
                disabled={readOnly}
              />
            </GridItem>
            <GridItem xs={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    aria-label="RealTimeLibrary GroupForm archiveCheckbox"
                    id="isArchived"
                    checked={groupFormValues.isArchived}
                    onChange={handleCheckboxChange}
                    disabled={readOnly}
                  />
                }
                label="ARCHIVE"
                labelPlacement="end"
              />
            </GridItem>
          </Grid>
          <Grid>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                ChangeLog
              </AccordionSummary>
              <AccordionDetails>
                <ChangeLog />
              </AccordionDetails>
            </Accordion>
          </Grid>
          {openAlert && (
            <Snackbar
              style={{ top: "150px" }}
              open={openAlert}
              //autoHideDuration={6000}
              onClose={closeAlert}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert
                variant="filled"
                severity="error"
                action={
                  <Button
                    aria-label="close"
                    color="inherit"
                    variant="outlined"
                    size="small"
                    onClick={handleConfirmClose}
                  >
                    Close
                  </Button>
                }
              >
                Save any changes before closing form.
              </Alert>
            </Snackbar>
          )}
        </FormTemplateNonDialog>
      )}
    </Card>
  )
}
