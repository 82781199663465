import { List, fromJS } from "immutable"
import { action, observable, decorate } from "mobx"
import DatasourceDAO from "../daos/datasourceDAO"

export default class DatasourceStore {
  rootStore
  loading
  organizationDatasources

  constructor(rootStore) {
    this.rootStore = rootStore
    this.dehydrate()
  }

  /**
   * Computeds
   */
  get isNarrativeSelected() {
    return !!this.narrativeId
  }

  /**
   * Methods
   */

  getOrganizationDatasources = async () => {
    try {
      this.setLoading(true)

      const orgDatasources = fromJS(
        await DatasourceDAO.getOrganizationDatasources()
      )

      this.setOrganizationDatasources(orgDatasources.get("content", List()))
    } catch (err) {
      console.error("Error: ", err)
      this.setErrorCode(err.cause)
    } finally {
      this.setLoading(false)
    }
  }

  createOrUpdateOrganizationDatasources = async (datasource, type) => {
    try {
      this.setLoading(true)

      let dataForDatasource = fromJS(datasource)
      const returnedDatasourceInfo = fromJS(
        await DatasourceDAO.createOrUpdateOrganizationDatasources(
          datasource,
          type
        )
      )
      if (!dataForDatasource.id) {
        dataForDatasource.id = returnedDatasourceInfo.match()
      }
      return dataForDatasource
    } catch (err) {
      console.error("Error: ", err)
      this.setErrorCode(err.cause)
    } finally {
      this.setLoading(false)
    }
  }

  /**
   * Reactions
   */

  /**
   * Internal Actions
   */
  dehydrate() {
    this.dehydrateNarrative()
    this.setLoading(false)
    this.setErrorCode(null)
  }

  dehydrateNarrative() {
    this.setIsDirty(false)
  }

  setIsDirty(value) {
    this.isDirty = value
  }
  setErrorCode(value) {
    this.errorCode = value
  }
  setLoading(value) {
    this.loading = value
  }

  setDatasources(value) {
    this.datasources = value
  }

  setOrganizationDatasources(value) {
    this.organizationDatasources = value
  }
}

/**
 * object decorators
 */
decorate(DatasourceStore, {
  // computeds

  // observables
  loading: observable,
  organizationDatasources: observable,
  isError: observable,

  // actions
  setLoading: action.bound,
  setOrganizationDatasources: action.bound
})
