import React from "react"
import PropTypes from "prop-types"

// MUI Components
import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"

// MUI Hooks
import { useFilterPanelStyles } from "../styles/FilteredTable.styles"

import EnhancedMultiSelect from "./EnhancedMultiSelect"

const FilterPanel = ({
  open = false,
  table,
  filters = [],
  activeFilters,
  handleFilterChange,
  handleFilterClear,
  handleSelectAll,
  handleDeleteAll
}) => {
  const { box, grid, input, notchedOutline, chip, buttonText, textfield } =
    useFilterPanelStyles()
  return (
    <>
      {open ? (
        <Box className={box}>
          <Grid
            classes={{ root: grid }}
            container
            spacing={3}
            alignItems="center"
          >
            {filters.map(
              (
                {
                  filterName,
                  columnId,
                  options,
                  multiple,
                  multiSelect,
                  ignoreLeadingNumbersSort
                },
                index
              ) => {
                const [value] = activeFilters.filter(
                  filter => filter.filterName === filterName
                )
                const sortedOptions = ignoreLeadingNumbersSort
                  ? options.sort((a, b) => {
                      const aVal = a.value.replace(/^\d{1,}\s-\s/, "")
                      const bVal = b.value.replace(/^\d{1,}\s-\s/, "")
                      return aVal > bVal ? 1 : -1
                    })
                  : options.sort((a, b) => (a.value > b.value ? 1 : -1))
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    key={`filter-${filterName}-${index}`}
                  >
                    {multiSelect ? (
                      <EnhancedMultiSelect
                        multiple={multiple}
                        sortedOptions={sortedOptions}
                        filterName={filterName}
                        columnId={columnId}
                        value={value}
                        options={options}
                        handleFilterChange={handleFilterChange}
                        handleSelectAll={handleSelectAll}
                        handleDeleteAll={handleDeleteAll}
                      />
                    ) : (
                      <Autocomplete
                        value={value ? value.value : multiple ? [] : {}}
                        onChange={(event, value, reason) => {
                          handleFilterChange(
                            filterName,
                            columnId,
                            value,
                            reason
                          )
                        }}
                        multiple={multiple}
                        options={sortedOptions}
                        id={`${filterName}-filter`}
                        getOptionLabel={option =>
                          option?.value?.toString() || ""
                        }
                        isOptionEqualToValue={option => {
                          let selected
                          if (Array.isArray(value?.value)) {
                            const match = value.value.find(
                              obj => obj.value === option?.value
                            )
                            selected = match?.value === option?.value
                          } else {
                            selected = option?.value === value?.value.value
                          }
                          return selected
                        }}
                        ChipProps={{
                          classes: { root: chip },
                          size: "small"
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            classes={{ root: textfield }}
                            label={`${filterName}`}
                            InputProps={{
                              ...params.InputProps,
                              classes: { root: input },
                              notched: false
                            }}
                            InputLabelProps={{
                              ...params.InputLabelProps,
                              classes: { root: notchedOutline }
                            }}
                          />
                        )}
                      />
                    )}
                  </Grid>
                )
              }
            )}
            {activeFilters.length !== 0 && (
              <Grid item xs>
                <Button
                  classes={{ label: buttonText }}
                  onClick={() => {
                    table.resetColumnFilters(true)
                    handleFilterClear()
                  }}
                >
                  Clear Filters
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      ) : (
        <></>
      )}
    </>
  )
}

FilterPanel.propTypes = {
  filters: PropTypes.array,
  open: PropTypes.bool,
  activeFilters: PropTypes.array,
  handleFilterChange: PropTypes.func,
  handleFilterClear: PropTypes.func,
  handleSelectAll: PropTypes.func,
  handleDeleteAll: PropTypes.func
}

export default FilterPanel
