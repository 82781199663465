import React from "react"
import PropTypes from "prop-types"
import { Field } from "formik"
import { TextField } from "@mui/material"

const defaultProps = {
  fullWidth: false,
  hideLabel: false,
  margin: "normal",
  multiline: false,
  readOnly: false,
  required: false,
  type: "text"
}

const propTypes = {
  fullWidth: PropTypes.bool,
  helpText: PropTypes.string,
  hideLabel: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  margin: PropTypes.oneOf(["none", "dense", "normal"]),
  multiline: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.oneOf([
    "text",
    "email",
    "password",
    "tel",
    "url",
    "number",
    "hidden",
    "checkbox"
  ])
}

const Input = props => {
  const {
    helpText,
    hideLabel,
    id,
    readOnly,
    required,
    label,
    name,
    placeholder,
    type,
    fullWidth,
    min,
    margin
  } = props

  return (
    <Field name={name}>
      {({ field, meta: { value, touched, error } }) => {
        const hasErrors = touched && error && true
        const textLabel = hideLabel ? "" : label
        return (
          <TextField
            {...field}
            value={value || ""}
            className="form__input"
            id={id}
            type={type}
            label={hasErrors ? error : textLabel}
            required={required}
            readOnly={readOnly}
            error={hasErrors}
            placeholder={placeholder}
            fullWidth={fullWidth}
            helperText={helpText}
            min={min}
            margin={margin}
          />
        )
      }}
    </Field>
  )
}

Input.defaultProps = defaultProps
Input.propTypes = propTypes
export default Input
