import { ListItem, List } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles(theme => ({
  sublist: {
    paddingTop: 0,
    paddingBottom: 0
  },
  subListItem: {
    display: "list-item",
    paddingTop: 0,
    paddingBottom: theme.spacing(0.5)
  },
  oldValue: {
    fontWeight: "bold",
    color: theme.palette.grey[700]
  },
  newValue: {
    fontWeight: "bold"
  }
}))

const ItemEditListItem = ({ changeEntry }) => {
  const classes = useStyles()

  return (
    <ListItem className={classes.subListItem}>
      {`ID: ${changeEntry.itemId}`}
      <List className={classes.sublist}>
        {changeEntry.changes.map((change, i) => (
          <ListItem className={classes.subListItem} key={i}>
            {`${change.field} changed from`}
            <span className={classes.oldValue}>{` "${change.oldValue}" `}</span>
            to
            <span className={classes.newValue}>{` "${change.newValue}" `}</span>
          </ListItem>
        ))}
      </List>
    </ListItem>
  )
}

export default ItemEditListItem
