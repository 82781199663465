import { InputBase } from "@mui/material"
import { styled } from "@mui/styles"

import { veryLightGrey } from "variables/colors"

const GreyTextInput = styled(InputBase)(({ theme }) => ({
  paddingLeft: "10px",
  flexGrow: 2,
  transition: theme.transitions.create("width"),
  width: "100%",
  backgroundColor: veryLightGrey
}))

export default GreyTextInput
