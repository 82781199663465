import {
  Box,
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { flexRender } from "@tanstack/react-table"

import FindAndReplaceControls from "components/Table/components/FindAndReplaceControls"
import useFindAndReplaceControls from "hooks/search/useFindAndReplaceControls"
import useTableSetupHelper from "hooks/table/useTableSetupHelper"

const useStyles = makeStyles(theme => ({
  selectHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  tableArea: {
    background: "white",
    overflow: "auto"
  }
}))

const SelectableFindReplaceTable = ({
  headerText = "",
  columns,
  modifiableFields,
  tableData,
  setTableData,
  onChangeData
}) => {
  const controlState = useFindAndReplaceControls({
    initialSelectedFields: [modifiableFields[0].value]
  })

  const classes = useStyles()

  const tableColumns = [
    {
      id: "select",
      header: ({ table }) => (
        <Box className={classes.selectHeader}>
          <Box>Select</Box>
          <Checkbox
            checked={table.getIsAllPageRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        </Box>
      ),
      cell: ({ row }) => (
        <Box textAlign="center">
          <Checkbox
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        </Box>
      )
    },
    ...columns
  ]

  const table = useTableSetupHelper({
    tableData,
    tableColumns,
    paginated: true,
    sorted: true,
    filtered: true,
    enableRowSelection: true
  })

  const selectedItemIds = table
    .getSelectedRowModel()
    .rows.map(row => row.original?.id)

  const handleReplaceInItems = (selectedField, searchString, replaceString) => {
    const changedRows = []
    setTableData(
      tableData.map(row => {
        if (selectedItemIds.includes(row.id)) {
          const newRow = {
            ...row,
            [selectedField]: row[selectedField].replaceAll(
              searchString,
              replaceString
            )
          }
          changedRows.push(newRow)
          return newRow
        } else {
          return row
        }
      })
    )

    onChangeData(changedRows)
    if (changedRows.length) {
      table.resetColumnFilters()
    }
  }

  const handleSearchItems = searchString => {
    const filterColumnChoice = controlState.selectedFields?.[0]
    table.setColumnFilters([{ id: filterColumnChoice, value: searchString }])
  }

  const handleClearFilters = () => {
    controlState.clearInputs()
    table.resetColumnFilters()
  }

  return (
    <>
      <FindAndReplaceControls
        searchFields={modifiableFields}
        onClear={handleClearFilters}
        onReplace={handleReplaceInItems}
        onSearch={handleSearchItems}
        headerText={headerText}
        {...controlState}
      />
      <Box className={classes.tableArea} mt={1}>
        <Grid container>
          <Grid item sm={12} md={7}>
            <Box mt={1}>
              <Typography variant="subtitle2">{`${selectedItemIds.length} items selected for modification`}</Typography>
            </Box>
          </Grid>
        </Grid>
        <TableContainer>
          <Table size="medium">
            <TableHead>
              {table.getHeaderGroups().map(headerGroup => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <TableCell
                      style={{
                        width: header.column.columnDef.width ?? "initial"
                      }}
                      key={header.id}
                      sortDirection={header.column.getIsSorted()}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <>
                        {header.isPlaceholder ? null : header.column.getCanSort() ? (
                          <TableSortLabel
                            active={
                              table.getState().sorting[0]?.id === header?.id
                            }
                            direction={header.column.getIsSorted() || "asc"}
                            onClick={header.column.getToggleSortingHandler()}
                            data-testid={"sort"}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </TableSortLabel>
                        ) : (
                          flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )
                        )}
                      </>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {table.getRowModel().rows.map(row => (
                <TableRow data-testid="row" key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <TableCell key={cell.id} data-testid="row-cell">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          count={Object.keys(table.getRowModel().rowsById).length}
          rowsPerPage={table.getState().pagination.pageSize}
          page={table.getState().pagination.pageIndex}
          SelectProps={{
            SelectDisplayProps: {
              "data-testid": "pagination-button"
            },
            inputProps: { "data-testid": "pagination-input" }
          }}
          onPageChange={(e, page) => {
            table.setPageIndex(page)
          }}
          onRowsPerPageChange={e => {
            table.setPageSize(e.target.value)
          }}
        />
      </Box>
    </>
  )
}

export default SelectableFindReplaceTable
