import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  grayCardHeader,
  brownCardHeader,
  roseCardHeader
} from "assets/jss/material-dashboard-pro-react"
const cardIconStyle = {
  cardIcon: {
    "&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$grayCardHeader,&brownCardHeader,&$roseCardHeader":
      {
        borderRadius: "3px",
        backgroundColor: "#999",
        padding: "15px",
        marginTop: "-20px",
        marginRight: "15px",
        float: "left"
      }
  },
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  grayCardHeader,
  brownCardHeader,
  roseCardHeader
}

export default cardIconStyle
