import React from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import withStyles from "@mui/styles/withStyles"
import { SearchOutlined, Filter } from "@mui/icons-material"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon"
import CardHeader from "components/Card/CardHeader"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import SearchResultsComponent from "components/NarrativeAdmin/SearchResults"
import { groupCollectionBy } from "tools/CollectionHelper"
import { Cookies } from "tools/storage"
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle"
import narrativeDashboardStyles from "assets/jss/material-dashboard-pro-react/views/narrativeDashboardStyles"
import {
  Box,
  Tab,
  Tabs,
  CircularProgress,
  Input,
  InputLabel,
  Select,
  MenuItem,
  FormControl
} from "@mui/material"
import Enums from "tools/Enums.js"
import Helpers from "../../tools/Helpers"

const styles = {
  ...customSelectStyle,
  ...narrativeDashboardStyles,
  cardBody: {
    minHeight: "250px",
    fontSize: "18px"
  },
  nakedInput: {
    textDecoration: "underline!important",
    fontSize: "18px!important",
    "& > svg": {
      visibility: "hidden"
    },
    "&:before,&:after": {
      display: "none!important"
    }
  },
  nested: {
    border: "1px solid #999999"
  },
  nestedGrey: {
    color: "#666666",
    padding: "0 30px 30px",
    backgroundColor: "#efefef"
  }
}
function convertBlockStatusIdToStatusName(statusId) {
  let statusName = "N/A"
  switch (statusId) {
    case 1:
      statusName = "Draft"
      break
    case 2:
      statusName = "Review"
      break
    case 3:
      statusName = "Published"
      break
    case 4:
      statusName = "Planned"
      break
    case 5:
      statusName = "Sample"
      break
  }
  return statusName
}
function convertNarrativeStatusIdToStatusName(statusId) {
  let statusName = "N/A"
  switch (statusId) {
    case 1:
      statusName = "Inactive"
      break
    case 2:
      statusName = "Validate"
      break
    case 3:
      statusName = "Active"
      break
  }
  return statusName
}

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    loadingSearchResults: store.narrativeStore.loadingSearchResults,
    loadedSearchResults: store.narrativeStore.loadedSearchResults,
    searchResults: store.narrativeStore.searchResults,
    searchString: store.narrativeStore.searchString,
    setSearchString: store.narrativeStore.setSearchString
  }))
}

const BlockSearch = props => {
  const {
    setSearchString,
    searchResults,
    loadingSearchResults,
    loadedSearchResults,
    searchString
  } = useStoreData()

  const { classes } = props

  const viewStyleTabsStyles = {
    root: classes.tinyTabRootButton,
    selected: classes.tinyTabSelected,
    wrapper: classes.tinyTabWrapper
  }

  const resetLastModified = (type, id, content) => {
    let tmp = filteredResults
    let found = false
    tmp.forEach(result => {
      if (result[`${type}_id`] == id) {
        result.modifieddatetime = new Date()
        if (content) {
          result.content = content
        }
        found = true
      }
    })
    if (found) {
      setFilteredResults(tmp)
    }
  }

  const [activeSearch, setActiveSearch] = React.useState(null)
  const [searchResultsCount, setSearchResultsCount] = React.useState([])
  const [filteredResultsCount, setFilteredResultsCount] = React.useState([])

  // Sort values
  const [sortProperty, setSortProperty] = React.useState("recent")
  const [isSorting, setIsSorting] = React.useState(false)

  // Filter values
  const [narratives, setNarratives] = React.useState([])
  const [organizations, setOrganizations] = React.useState([])
  const [blockTypes, setBlockTypes] = React.useState([])
  const [narrativeStatuses, setNarrativeStatuses] = React.useState([])
  const [blockStatuses, setBlockStatuses] = React.useState([])
  const [triggers, setTriggers] = React.useState([])

  const [filteredResults, setFilteredResults] = React.useState([])
  const [filter, setFilter] = React.useState({})

  // on first render
  React.useEffect(() => {
    if (searchString !== activeSearch) {
      setActiveSearch(searchString)
    }
  }, [searchString])

  React.useEffect(() => {
    if (searchResults && searchResults.length > 0) {
      setSearchResultsCount(searchResults.length)
      setFilteredResults(searchResults)
    } else {
      setSearchResultsCount(0)
      setSearchResultsCount(0)
      setFilteredResults([])
    }
  }, [searchResults])

  React.useEffect(() => {
    if (filteredResults && filteredResults.length > 0) {
      setFilteredResultsCount(filteredResults.length)
      // Only do this when the underlying data changes
      if (!isSorting) {
        const tmpNarratives = groupCollectionBy(
          filteredResults,
          r => r.narrative
        )
        setNarratives(Object.keys(tmpNarratives))

        const tmpOrganizations = groupCollectionBy(
          filteredResults,
          r => r.organization_name
        )
        setOrganizations(Object.keys(tmpOrganizations))

        const tmpBlockTypes = groupCollectionBy(
          filteredResults,
          r => r.blocktype
        )
        setBlockTypes(Object.keys(tmpBlockTypes))

        const tmpNarrativeStatuses = groupCollectionBy(
          filteredResults,
          r => r.narrativestatus_id
        )
        setNarrativeStatuses(Object.keys(tmpNarrativeStatuses))

        const tmpBlockStatuses = groupCollectionBy(
          filteredResults,
          r => r.status_id
        )
        setBlockStatuses(Object.keys(tmpBlockStatuses))

        const tmpTriggers = groupCollectionBy(
          filteredResults,
          r => r.triggertype
        )
        setTriggers(Object.keys(tmpTriggers))
      } else {
        setIsSorting(false)
      }
    } else {
      setFilteredResultsCount(0)
      setNarratives([])
      setOrganizations([])
      setBlockTypes([])
      setNarrativeStatuses([])
      setBlockStatuses([])
      setTriggers([])
    }
  }, [filteredResults])

  React.useEffect(() => {
    if (filteredResults && filteredResults.length > 0) {
      setIsSorting(true)
      if (sortProperty === "recent") {
        let tmpSorted = filteredResults
          .slice()
          .sort((a, b) => (b.modifieddatetime > a.modifieddatetime ? 1 : -1))
        setFilteredResults(tmpSorted)
      } else if (sortProperty === "oldest") {
        let tmpSorted2 = filteredResults
          .slice()
          .sort((a, b) => (a.modifieddatetime > b.modifieddatetime ? 1 : -1))
        setFilteredResults(tmpSorted2)
      }
    }
  }, [sortProperty])

  React.useEffect(() => {
    let newSearchResults = searchResults || []
    let filterObj = Object.keys(filter)
      .filter(k => filter[k] !== null && filter[k] !== "")
      .reduce((a, k) => ({ ...a, [k]: filter[k] }), {})
    let filterKeys = Object.keys(filterObj) || []
    if (searchResultsCount > 0 && filterKeys.length > 0) {
      filterKeys.forEach(key => {
        newSearchResults = newSearchResults.filter(
          r => r[key] === filterObj[key]
        )
      })
    }
    setFilteredResults(newSearchResults)
    setFilteredResultsCount(newSearchResults.length)
  }, [filter])

  // Set page view style
  //Compact, Standard or Detailed
  let cookies = new Cookies()
  let cookieViewStyle =
    cookies.get("activeSearchViewStyle") || Enums.BlockDisplayStyle.STANDARD
  const [activeSearchViewStyle, setActiveSearchViewStyle] = React.useState(
    Number(cookieViewStyle)
  )
  const changeViewStyle = (event, newValue) => {
    if (activeSearchViewStyle !== newValue) {
      setActiveSearchViewStyle(newValue)
      cookies.set("activeSearchViewStyle", newValue, {
        path: "/",
        expires: Helpers.CookieExpiration.OneMonth
      })
    }
  }

  return (
    <Box>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="primary" icon>
              <GridContainer>
                <GridItem xs={12} sm={12} md={7}>
                  <CardIcon color="primary">
                    <SearchOutlined />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Block Search</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <Tabs
                    value={activeSearchViewStyle}
                    onChange={changeViewStyle}
                    variant="scrollable"
                    scrollButtons="auto"
                    indicatorColor="primary"
                    textColor="primary"
                    classes={{
                      root: classes.tinyTabsRoot,
                      flexContainer: classes.flexEnd
                    }}
                  >
                    <span className={classes.viewLabel}>View Style: </span>
                    <Tab
                      classes={viewStyleTabsStyles}
                      value={Enums.BlockDisplayStyle.STANDARD}
                      label="Standard"
                    />
                    <Tab
                      classes={viewStyleTabsStyles}
                      value={Enums.BlockDisplayStyle.DETAILED}
                      label="Detailed"
                    />
                  </Tabs>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody className={classes.cardBody}>
              {/* Start Search Input Section */}
              <GridContainer spacing={6}>
                <GridItem xs={4} sm={8} md={7}>
                  <InputLabel>Search</InputLabel>
                  <Input
                    fullWidth
                    type="text"
                    name="search"
                    id="search"
                    label="Search"
                    value={activeSearch || ""}
                    onChange={e => setActiveSearch(e.target.value)}
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        setSearchString(e.target.value)
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={4} sm={8} md={5}>
                  <InputLabel>Sort</InputLabel>
                  <Select
                    fullWidth
                    type="select"
                    name="sort"
                    id="sort"
                    value={sortProperty}
                    onChange={e => setSortProperty(e.target.value)}
                  >
                    <MenuItem key="recent" value="recent">
                      Recently Updated
                    </MenuItem>
                    <MenuItem key="oldest" value="oldest">
                      Oldest First
                    </MenuItem>
                  </Select>
                </GridItem>
              </GridContainer>
              <GridContainer spacing={6}>
                <GridItem xs={12}>
                  {/* Start Search Results Area */}
                  {loadingSearchResults && (
                    <CircularProgress
                      style={{ position: "absolute", left: "49%" }}
                    />
                  )}
                  {loadedSearchResults &&
                    filteredResults &&
                    filteredResults.length > 0 && (
                      <div
                        style={{
                          fontSize: "14px",
                          marginBottom: "10px"
                        }}
                      >
                        <span>
                          Listing {filteredResultsCount} of {searchResultsCount}{" "}
                          results for{" "}
                        </span>
                        <strong>
                          <u>{searchString}</u>
                        </strong>
                      </div>
                    )}
                  {filteredResults && filteredResults.length > 0 && (
                    <SearchResultsComponent
                      searchResults={filteredResults}
                      blockViewStyle={activeSearchViewStyle}
                      savedEntry={(type, id, content) => {
                        resetLastModified(type, id, content)
                      }}
                    />
                  )}
                  {loadedSearchResults &&
                    filteredResults &&
                    filteredResults.length === 0 && (
                      <div style={{ fontSize: "14px" }}>
                        <span>No results found for </span>
                        <strong>
                          <em>{searchString}</em>
                        </strong>
                      </div>
                    )}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="gray">
                <Filter />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Filter Results</h4>
            </CardHeader>
            <CardBody className={classes.cardBody}>
              {/* Start Filters Section */}
              <CardBody className={classes.nestedGrey}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl
                      margin="normal"
                      className={classes.formControl}
                      fullWidth
                    >
                      <InputLabel>Organization</InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        type="select"
                        name="organization"
                        id="organization"
                        value={filter.organization_name || ""}
                        onChange={e =>
                          setFilter({
                            ...filter,
                            organization_name: e.target.value
                          })
                        }
                      >
                        <MenuItem key="all" value="">
                          All
                        </MenuItem>
                        {organizations &&
                          organizations.map(key => (
                            <MenuItem key={key} value={key}>
                              {key}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl
                      margin="normal"
                      className={classes.formControl}
                      fullWidth
                    >
                      <InputLabel>Source Library</InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        type="select"
                        name="narrativeName"
                        id="narrativeName"
                        value={filter.narrative || ""}
                        onChange={e =>
                          setFilter({
                            ...filter,
                            narrative: e.target.value
                          })
                        }
                      >
                        <MenuItem key="all" value="">
                          All
                        </MenuItem>
                        {narratives &&
                          narratives.map(key => (
                            <MenuItem key={key} value={key}>
                              {key}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl
                      margin="normal"
                      className={classes.formControl}
                      fullWidth
                    >
                      <InputLabel>Library Status</InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        type="select"
                        name="narrativeStatus"
                        id="narrativeStatus"
                        value={filter.narrativestatus_id || ""}
                        onChange={e =>
                          setFilter({
                            ...filter,
                            narrativestatus_id: e.target.value
                          })
                        }
                      >
                        <MenuItem key="all" value="">
                          All
                        </MenuItem>
                        {narrativeStatuses &&
                          narrativeStatuses.map(key => (
                            <MenuItem key={key} value={key}>
                              {convertNarrativeStatusIdToStatusName(
                                Number(key)
                              )}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl
                      margin="normal"
                      className={classes.formControl}
                      fullWidth
                    >
                      <InputLabel>Block Type</InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        type="select"
                        name="type"
                        id="type"
                        value={filter.blocktype || ""}
                        onChange={e =>
                          setFilter({
                            ...filter,
                            blocktype: e.target.value
                          })
                        }
                      >
                        <MenuItem key="all" value="">
                          All
                        </MenuItem>
                        {blockTypes &&
                          blockTypes.map(key => (
                            <MenuItem key={key} value={key}>
                              {key}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl
                      margin="normal"
                      className={classes.formControl}
                      fullWidth
                    >
                      <InputLabel>Block Status</InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        type="select"
                        name="blockStatus"
                        id="blockStatus"
                        value={filter.status_id || ""}
                        onChange={e =>
                          setFilter({
                            ...filter,
                            status_id: e.target.value
                          })
                        }
                      >
                        <MenuItem key="all" value="">
                          All
                        </MenuItem>
                        {blockStatuses &&
                          blockStatuses.map(key => (
                            <MenuItem key={key} value={key}>
                              {convertBlockStatusIdToStatusName(Number(key))}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl
                      margin="normal"
                      className={classes.formControl}
                      fullWidth
                    >
                      <InputLabel>Trigger</InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        type="select"
                        name="triggers"
                        id="triggers"
                        value={filter.triggertype || ""}
                        onChange={e =>
                          setFilter({
                            ...filter,
                            triggertype: e.target.value
                          })
                        }
                      >
                        <MenuItem key="all" value="">
                          All
                        </MenuItem>
                        {triggers &&
                          triggers.map(key => (
                            <MenuItem key={key} value={key}>
                              {key}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Box>
  )
}

export default withStyles(styles)(BlockSearch)
