import React from "react"
import PropTypes from "prop-types"
import withStyles from "@mui/styles/withStyles"
import Assignment from "@mui/icons-material/Assignment"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon"
import CardHeader from "components/Card/CardHeader"
import { cardTitle } from "assets/jss/material-dashboard-pro-react"
import OrganizationManager from "components/Organizations/OrganizationManager"
import LinearProgress from "@mui/material/LinearProgress"
import { observer, inject } from "mobx-react"

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  headerLastChild: {
    textAlign: "left"
  },
  loading: {
    position: "absolute",
    width: "97%",
    top: "0",
    right: "0",
    minHeight: "10px",
    borderRadius: "4px",
    overflow: "hidden",
    zIndex: "-1",
    "& > div": {
      height: "10px"
    }
  }
}

const useStore = component =>
  inject(({ store }) => ({
    datasources: store.narrativeStore.datasources,
    getDatasources: store.narrativeStore.getDatasources,
    bookmakers: store.narrativeStore.bookmakers,
    getBookmakers: store.narrativeStore.getBookmakers
  }))(observer(component))

function Organizations(props) {
  const { classes } = props
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    if (typeof props.getDatasources === "function") {
      props.getDatasources()
    }
    if (typeof props.getBookmakers === "function") {
      props.getBookmakers()
    }
  }, [])

  const { datasources, bookmakers } = props

  const sportsData =
    datasources && datasources.filter(ds => ds.get("hasSports"))
  const bettingData =
    datasources && datasources.filter(ds => ds.get("hasBetting"))
  const imageData = datasources && datasources.filter(ds => ds.get("hasImages"))
  const bookmakerData = bookmakers && bookmakers

  const bookmakerDataToJS = (bookmakerData && bookmakerData.toJS()) || []
  const imageDataToJS = (imageData && imageData.toJS()) || []
  const bettingDataToJS = (bettingData && bettingData.toJS()) || []
  const sportsDataToJS = (sportsData && sportsData.toJS()) || []

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            {isLoading && (
              <div className={classes.loading}>
                <LinearProgress />
              </div>
            )}
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Organizations</h4>
          </CardHeader>
          <CardBody>
            <OrganizationManager
              isNowLoading={val => {
                setIsLoading(val)
              }}
              bookmakerData={bookmakerDataToJS}
              imageData={imageDataToJS}
              bettingData={bettingDataToJS}
              sportsData={sportsDataToJS}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

Organizations.propTypes = {
  properties: PropTypes.array,
  isNowLoading: PropTypes.func,
  classes: PropTypes.object,
  datasources: PropTypes.object,
  bookmakers: PropTypes.object
}

export default withStyles(styles)(useStore(Organizations))
