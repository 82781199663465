import { cardTitle } from "assets/jss/material-dashboard-pro-react"
import {
  primaryColor,
  grayColor
} from "assets/jss/material-dashboard-pro-react"
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle"
const narrativeDashboardStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    "& small": {
      fontSize: "80%",
      fontWeight: "400"
    }
  },
  cardIconTitle2: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    fontSize: "16px",
    "& small": {
      fontSize: "80%",
      fontWeight: "400"
    }
  },
  label: {
    marginTop: "7px",
    marginBottom: 0,
    fontSize: "18px"
  },
  viewLabel: {
    fontWeight: 600,
    alignSelf: "center",
    paddingBottom: "3px",
    color: "#999999"
  },
  cardCategory: {
    marginTop: "10px",
    color: "#999999 !important",
    textAlign: "center"
  },
  description: {
    color: "#999999"
  },
  updateProfileButton: {
    float: "right"
  },
  fullHeight: {
    height: "100%"
  },
  offsetHeight: {
    minHeight: "calc(100% - 82px)"
  },
  ...buttonsStyle,
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end"
  },
  tinyTabsRoot: {
    verticalAlign: "middle",
    minHeight: "unset !important",
    "& $tinyTabRootButton": {
      fontSize: "12px"
    }
  },
  tinyTabRootButton: {
    minHeight: "unset !important",
    minWidth: "unset !important",
    width: "unset !important",
    height: "unset !important",
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    padding: "0 5px",
    borderRadius: "3px",
    lineHeight: "24px",
    marginLeft: "3px",
    color: `${grayColor} !important`,
    textDecoration: "underline",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: `${primaryColor} !important`,
      textDecoration: "none"
    }
  },
  headerButton: {
    backgroundColor: "transparent !important",
    minHeight: "unset !important",
    minWidth: "unset !important",
    width: "unset !important",
    height: "unset !important",
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    boxShadow: "none !important",
    padding: "10px 15px",
    borderRadius: "0",
    lineHeight: "24px",
    marginRight: "5px",
    fontWeight: 500,
    color: `${primaryColor} !important`,
    borderBottom: "2px solid",
    borderColor: "transparent !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: `${primaryColor} !important`,
      borderColor: `${primaryColor} !important`
    },
    "&:last-child": {
      marginRight: "0px"
    }
  },
  headerButtonSelected: {
    backgroundColor: "transparent !important",
    minHeight: "unset !important",
    minWidth: "unset !important",
    width: "unset !important",
    height: "unset !important",
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    boxShadow: "none !important",
    padding: "10px 15px",
    borderRadius: "0",
    lineHeight: "24px",
    borderBottom: "2px solid",
    borderColor: primaryColor,
    marginRight: "2px",
    color: `${primaryColor} !important`,
    fontWeight: 500,
    "&:hover,&:selected": {
      backgroundColor: "transparent !important",
      color: `${grayColor} !important`
    },
    "&:last-child": {
      marginRight: "0px"
    }
  },
  tinyTabLabelContainer: {
    padding: "0px"
  },
  tinyTabLabel: {
    fontWeight: "500",
    fontSize: "12px"
  },
  tinyTabSelected: {
    backgroundColor: "transparent !important",
    color: `${primaryColor} !important`,
    textDecoration: "none"
  },
  tinyTabWrapper: {
    display: "inline-block",
    minHeight: "unset !important",
    minWidth: "unset !important",
    width: "unset !important",
    height: "unset !important",
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    "& > svg,& > .fab,& > .fas,& > .far,& > .fal,& > .material-icons": {
      verticalAlign: "middle",
      margin: "-1px 5px 0 0"
    }
  }
}
export default narrativeDashboardStyles
