import React, { Component } from "react"
import Button from "components/CustomButtons/Button"
import InputLabel from "@mui/material/InputLabel"
import GridItem from "components/Grid/GridItem.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardIcon from "components/Card/CardIcon.jsx"
import SyncAlt from "@mui/icons-material/SyncAlt"
import TextFieldsIcon from "@mui/icons-material/TextFields"
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle"
import withStyles from "@mui/styles/withStyles"
import { globalSettings, awsTextTranslateLanguages } from "variables/general"
import { Select, MenuItem, Checkbox } from "@mui/material"
import Check from "@mui/icons-material/Check"
import { observer, inject } from "mobx-react"
import TextField from "@mui/material/TextField"
import { put } from "tools/request"

const useStore = component =>
  inject(({ store }) => ({
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization
  }))(observer(component))

const WAIT_INTERVAL = 500

class TranslateTextTool extends Component {
  constructor(props) {
    super(props)
    this.state = {
      text1: this.props.text,
      sourceLanguageCode: "en",
      text2: "",
      targetLanguageCode: "es",
      lastTranslatedText: "",
      isTextTranslastionHappending: false,
      availableLanguages: awsTextTranslateLanguages,
      isHTMLText: false
    }
  }

  componentDidMount() {
    this.props.setShowChangeCurrentOrganization(false)
  }

  translateTextNow = (text, sourceLanguageCode, targetLanguageCode) => {
    this.setState({
      isTextTranslastionHappending: true,
      text2: "translating..."
    })
    put(`${globalSettings.apiBaseUrl}/api/translatetext`, null, {
      inputText: text,
      sourceLanguageCode,
      targetLanguageCode,
      isHTMLText: this.state.isHTMLText
    })
      .then(response => response.json())
      .then(response => {
        this.setState({
          isTextTranslastionHappending: false,
          lastTranslatedText: response.content.outputText,
          text2: response.content.outputText
        })
      })
  }

  onSwapButtonClick = () => {
    let oldText1 = this.state.text1
    let oldSourceLanguageCode = this.state.sourceLanguageCode
    let oldText2 = this.state.text2
    let oldTargetLanguageCode = this.state.targetLanguageCode
    this.setState(
      {
        text1: oldText2,
        sourceLanguageCode: oldTargetLanguageCode,
        targetLanguageCode: oldSourceLanguageCode,
        text2: oldText1
      },
      () =>
        this.translateTextNow(
          this.state.text1,
          this.state.sourceLanguageCode,
          this.state.targetLanguageCode
        )
    )
  }

  onText1AreaChange = event => {
    this.setState({ text1: event.target.value })
    clearTimeout(this.timer)
    this.timer = setTimeout(
      () =>
        this.translateTextNow(
          this.state.text1,
          this.state.sourceLanguageCode,
          this.state.targetLanguageCode
        ),
      WAIT_INTERVAL
    )
  }

  onText2AreaChange = event => {
    this.setState({ text2: event.target.value })
  }

  onIsHTMLTextCheckBoxChanged = () => {
    this.setState({ isHTMLText: !this.state.isHTMLText }, () =>
      this.translateTextNow(
        this.state.text1,
        this.state.sourceLanguageCode,
        this.state.targetLanguageCode
      )
    )
  }

  render() {
    const { classes } = this.props
    const menuItems = this.state.availableLanguages.map(item => (
      <MenuItem
        key={item.languageCode}
        value={item.languageCode}
        classes={{
          root: classes.selectMenuItem
        }}
      >
        {item.name}
      </MenuItem>
    ))

    return (
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <TextFieldsIcon />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <div className="content">
              <form>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={this.state.sourceLanguageCode}
                  onChange={event => {
                    this.setState(
                      {
                        sourceLanguageCode: event.target.value
                      },
                      () =>
                        this.translateTextNow(
                          this.state.text1,
                          this.state.sourceLanguageCode,
                          this.state.targetLanguageCode
                        )
                    )
                  }}
                  inputProps={{
                    name: "simpleSelect",
                    id: "simple-select"
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}
                  >
                    Choose a language
                  </MenuItem>
                  {menuItems}
                </Select>
                <Checkbox
                  checked={this.state.isHTMLText}
                  onChange={this.onIsHTMLTextCheckBoxChanged}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                  }}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                />{" "}
                HTML Text?
                <InputLabel style={{ color: "#AAAAAA" }} />
                <TextField
                  label="Enter text"
                  id="about-me"
                  fullWidth={true}
                  multiline={true}
                  rows={5}
                  onChange={this.onText1AreaChange}
                  value={this.state.text1}
                  helperText="Type text in the language selected"
                />
                <div className="update ml-auto mr-auto">
                  <Button
                    color="primary"
                    round
                    justIcon
                    simple
                    onClick={this.onSwapButtonClick}
                  >
                    <SyncAlt />
                  </Button>
                </div>
                <div>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={this.state.targetLanguageCode}
                    onChange={event => {
                      this.setState(
                        {
                          targetLanguageCode: event.target.value
                        },
                        () =>
                          this.translateTextNow(
                            this.state.text1,
                            this.state.sourceLanguageCode,
                            this.state.targetLanguageCode
                          )
                      )
                    }}
                    inputProps={{
                      name: "simpleSelect",
                      id: "simple-select"
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem
                      }}
                    >
                      Choose a language
                    </MenuItem>
                    {menuItems}
                  </Select>
                  <InputLabel style={{ color: "#AAAAAA" }} />
                  <TextField
                    label="Translated text"
                    id="about-me"
                    fullWidth={true}
                    multiline={true}
                    rows={5}
                    onChange={this.onText2AreaChange}
                    value={this.state.text2}
                    disabled={true}
                    helperText="Translated text"
                    enabled={false}
                  />
                </div>
              </form>
            </div>
          </CardBody>
        </Card>
      </GridItem>
    )
  }
}

export default withStyles(regularFormsStyle)(useStore(TranslateTextTool))
