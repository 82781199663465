import React, { useEffect, useState } from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import GridItem from "components/Grid/GridItem"
import CodeEditor from "components/CodeEditor"
import FormTemplateNonDialog from "./FormTemplateNonDialog"
import RealTimeInsightStatus from "../RealTimeInsightStatus"
import { makeStyles } from "@mui/styles"
import Helpers from "tools/Helpers.js"
import {
  Grid,
  Autocomplete,
  TextField,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
  Card,
  IconButton,
  CardHeader,
  Alert,
  Snackbar,
  Button
} from "@mui/material"
import ExpandMore from "@mui/icons-material/ExpandMore"
import ChangeLog from "components/NarrativeAdmin/Data/ChangeLog"
import { Close } from "@mui/icons-material"
import RealTimeDAO from "daos/realTimeDAO"
import { useHistory } from "react-router-dom"
import { useLocation } from "react-router-dom"
import WordCounter from "components/WordCounter/WordCounter"

const useStyles = makeStyles({
  ownerGrid: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingBottom: "20px"
  },
  formRow: {
    display: "flex",
    padding: "24px 0"
  }
})

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    getInsightSentence: store.realTimeStore.getInsightSentence,
    accounts: store.accountStore.accounts,
    getAccounts: store.accountStore.getAccounts,
    getReferencePeriod: store.realTimeStore.getReferencePeriod,
    setDialogSuccessMessage: store.uiStore.setDialogSuccessMessage,
    setDialogSuccessOpen: store.uiStore.setDialogSuccessOpen,
    setDialogFailMessage: store.uiStore.setDialogFailMessage,
    setDialogFailOpen: store.uiStore.setDialogFailOpen,
    getModelTypeById: store.realTimeStore.getModelTypeById,
    insightTypeToModelType: store.realTimeStore.insightTypeToModelType
  }))
}

export default function RealTimeSentenceForm({
  open,
  setOpen,
  onSave,
  onClose,
  libraryId,
  onReset,
  sentenceData,
  readOnly,
  leagueId
}) {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()
  const queryParams = new URLSearchParams(location.search)
  const libId = queryParams.get("libraryId")
  const sentenceId = queryParams.get("sentenceId")
  const {
    getInsightSentence,
    setDialogSuccessMessage,
    setDialogSuccessOpen,
    setDialogFailMessage,
    setDialogFailOpen,
    getAccounts,
    accounts,
    getModelTypeById,
    insightTypeToModelType
    //modelTypeById
    //getModelCriteriaBySentence
    //modelCriteriaBySentence
  } = useStoreData()
  //const [open, setOpen] = useState(true)
  const [openAlert, setOpenAlert] = useState(false)
  const [initialFormValues, setInitialFormValues] = useState({
    ownerId: 1,
    statusId: 3,
    id: null,
    insightTypeId: null,
    insightGroupId: null,
    name: "",
    isArchived: false,
    referencePeriodId: null,
    template: "",
    modelTypeId: null
  })
  const [sentenceFormValues, setSentenceFormValues] =
    useState(initialFormValues)
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [formIsLoading, setFormIsLoading] = useState(false)
  const [modelCriteriaOptions, setModelCriteriaOptions] = useState([])
  const [selectedProperties, setSelectedProperties] = useState([])
  const [propertyMap, setPropertyMap] = useState(new Map())
  const [modelLoading, setIsModelLoading] = useState(false)
  const [formErrors, setFormErrors] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [modelCriteria, setModelCriteria] = useState([])
  const [modelOptions, setModelOptions] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [modelCriteriaUnsavedChanges, setModelCriteriaUnsavedChanges] =
    useState(false)
  const [templatePreview, setTemplatePreview] = useState("")
  const [showTemplatePreview, setShowTemplatePreview] = useState(false)

  useEffect(() => {
    const tempAccount = accounts?.toJS()
    if (!tempAccount || tempAccount?.length === 0) {
      getAccounts()
    }
  }, [libraryId])

  useEffect(() => {
    if (sentenceData) {
      const sentence = sentenceData
      //console.log("sentenceData:", sentenceData)
      let modelTypeId = sentence.modelType?.id

      if (!sentence.id && sentence.insightType?.id) {
        modelTypeId = Number(
          insightTypeToModelType.get(sentence.insightType.id)
        )
      }
      const initialFormValues = {
        owner: {
          id: sentence.owner?.id || null,
          name: sentence.owner?.name || ""
        },
        statusId: sentence.status?.id || "",
        id: sentence.id,
        groupId: sentence.insightGroup?.id || "",
        insightGroup: sentence.insightGroup?.name || "",
        name: sentence.name || "",
        description: sentence.description || "",
        libraryId: sentence.library || "",
        insightTypeId: sentence.insightType?.id || null,
        insightType: sentence.insightType?.name || "",
        isArchived: sentence.isArchived || false,
        template: sentence.template || "",
        // modelTypeId: sentence.modelType?.id || null
        modelTypeId: modelTypeId
      }
      setSentenceFormValues(initialFormValues)
      setInitialFormValues(initialFormValues)

      //setFormIsLoading(true)
      if (!sentenceData.id) {
        setModelCriteriaOptions([])
        setPropertyMap(new Map())
        setSelectedProperties([])
        setModelOptions({})
      }
    }
  }, [sentenceData, getModelTypeById])

  useEffect(() => {
    if (sentenceData && sentenceData.id) {
      RealTimeDAO.getModelCriteriaBySentence(sentenceData.id)
        .then(response => {
          if (response && typeof response === "object") {
            if (Object.keys(response).length) {
              // Define excluded keys
              const criteriaMap = new Map(
                Object.entries(response.modelCriteria)
              )
              const selectedProps = Array.from(
                criteriaMap,
                ([name, value]) => ({
                  name: name,
                  label: name,
                  id: value[0]?.id
                })
              )

              setSelectedProperties(selectedProps)

              const options = Object.fromEntries(criteriaMap)

              setModelOptions(options)
              setFormErrors({})
            } else {
              //Sentence has no model Options
              setModelOptions({})
              setSelectedProperties([])
              setFormErrors({})
            }
          } else {
            console.warn("Unexpected response format for model criteria")
          }
        })
        .catch(error => {
          console.error("Error fetching model criteria:", error)
        })
    } else {
      console.warn("No valid sentenceData or sentenceData.id")
    }
  }, [sentenceData])

  useEffect(() => {
    const fetchModelTypeDetails = async () => {
      const modelTypeId = sentenceFormValues.modelTypeId
      if (modelTypeId) {
        setIsModelLoading(true)
        try {
          // console.log(
          //   "Calling getModelTypeById with modelTypeId:",
          //   sentenceFormValues.modelTypeId
          // )
          const modelType = await RealTimeDAO.getModelTypeById(modelTypeId)

          if (modelType && modelType.modelCriteriaProperties) {
            //properties paths
            const names = modelType.modelCriteriaProperties.map(prop => ({
              name: prop.name,
              label: prop.name,
              id: prop.id
            }))
            setModelCriteriaOptions(names)
            //propertyMap with properties and values
            const map = new Map()
            modelType.modelCriteriaProperties.forEach(property => {
              map.set(
                property.name,
                property.values.map(obj => {
                  let payload = {}
                  payload.value = obj.id
                  payload.name = obj.name
                  payload.id = property.id
                  return payload
                })
              )
            })
            setPropertyMap(map)
            // Reset modelCriteria and modelOptions when modelTypeId changes
            if (!sentenceFormValues.id) {
              setModelCriteria([])
              setModelOptions({})
            }
          }
        } catch (error) {
          console.error("Error fetching model criteria:", error)
          // Handle error
        } finally {
          setIsModelLoading(false)
        }
      }
    }

    fetchModelTypeDetails()
  }, [sentenceFormValues.modelTypeId, sentenceFormValues.id])

  const handleModelTypeChange = async (event, newValue) => {
    setSelectedProperties(newValue)
    setFormErrors(prevErrors => ({
      ...prevErrors,
      selectedProperties: undefined,
      ...Object.fromEntries(newValue.map(prop => [prop.path, undefined]))
    }))
    setModelCriteriaUnsavedChanges(true)
  }

  const handleClose = () => {
    if (!readOnly) {
      setOpenAlert(true)
    } else {
      onClose()
    }
  }

  const closeAlert = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setOpenAlert(false)
  }
  const handleConfirmClose = () => {
    setOpenAlert(false)
    //onClose()
    history.push(`/portal/real-time-library/edit?id=${libraryId}`)
  }

  const handleCancel = () => {
    if (initialFormValues) {
      setSentenceFormValues(initialFormValues)
    } else {
      setSentenceFormValues({
        ownerId: 1,
        statusId: 3,
        groupId: null,
        name: "",
        labels: [],
        isArchived: false,
        template: ""
      })
    }
    //setFormIsLoading(true)
  }

  const validateForm = () => {
    let errors = {}
    if (!sentenceFormValues.name) {
      errors.name = "Name is required"
    }
    if (selectedProperties.length === 0) {
      errors.selectedProperties = "Model Criteria is required"
    } else {
      selectedProperties.forEach(modelCriteriaProperties => {
        // Check if specific model criteria requires validation
        if (
          !modelOptions[modelCriteriaProperties.name] ||
          modelOptions[modelCriteriaProperties.name].length === 0
        ) {
          errors[
            modelCriteriaProperties.name
          ] = `${modelCriteriaProperties.label} is required`
        }
      })
    }

    if (!sentenceFormValues.template) {
      errors.template = "Template is required"
    }
    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const handleSave = async () => {
    if (!validateForm()) {
      return
    }

    try {
      let sentenceId = sentenceFormValues.id
      let successMessage = ""
      let sentenceChanged = false
      let criteriaChanged = false

      // Check for changes in sentence form
      if (unsavedChanges) {
        const sentenceData = {
          name: sentenceFormValues.name,
          description:
            sentenceFormValues.description || sentenceFormValues.name,
          ownerAccountId: sentenceFormValues.owner.id || 1,
          statusId: sentenceFormValues.statusId || 3,
          id: sentenceFormValues.id || 0,
          libraryId: libraryId,
          isArchived: sentenceFormValues.isArchived || false,
          insightTypeId: sentenceFormValues.insightTypeId,
          insightGroupId: sentenceFormValues.groupId,
          template: sentenceFormValues.template
        }

        if (sentenceId && unsavedChanges) {
          // Update existing sentence
          const response = await RealTimeDAO.updateInsightSentence(
            sentenceData,
            sentenceId
          )
          if (response.ok) {
            successMessage = `Insight Sentence ID: ${sentenceId} updated successfully`
            setInitialFormValues(sentenceFormValues)
          } else {
            throw new Error("update sentence")
          }
        } else {
          // Create new sentence
          const response = await RealTimeDAO.postInsightSentence(sentenceData)
          if (response && response.id) {
            sentenceId = response.id
            successMessage = `Insight Sentence ID: ${sentenceId} created successfully`
            history.push(
              `/portal/real-time-library/edit?id=${libraryId}&sentenceId=${sentenceId}`
            )
            setInitialFormValues({ ...sentenceFormValues, id: sentenceId })
            setSentenceFormValues(prev => ({ ...prev, id: sentenceId }))
            sentenceChanged = true
          } else {
            throw new Error("create sentence")
          }
        }
      }
      // Always save model criteria if sentenceId exists
      if (sentenceId) {
        const criteria = new Map()
        const modelCriteriaData = {
          sentenceId: sentenceId,
          selectedProperties: selectedProperties.map(prop => prop.name)
        }
        selectedProperties.forEach(property => {
          const selectedOptions = modelOptions[property.name] || []

          modelCriteriaData[property.name] = Array.isArray(selectedOptions)
            ? selectedOptions.map(option => option.id)
            : []

          criteria.set(property.name, {
            id: property.id,
            value: Array.isArray(selectedOptions)
              ? selectedOptions.map(option => option.value)
              : []
          })
          criteriaChanged = true
        })
        //must include all options
        //may want to move this functionality to the server side
        for (const key of propertyMap.keys()) {
          if (!criteria.has(key)) {
            criteria.set(key, { id: propertyMap.get(key)[0]?.id, value: null })
          }
        }
        modelCriteriaData["criteria"] = Object.fromEntries(criteria)

        if (criteriaChanged || selectedProperties.length > 0) {
          const modelCriteriaResponse = await RealTimeDAO.putModelCriteria(
            modelCriteriaData,
            sentenceId
          )
          if (!modelCriteriaResponse.ok) {
            throw new Error("save model criteria")
          }
          successMessage += "\nModel criteria updated successfully"
        }
      }
      // Handle success
      if (sentenceChanged || criteriaChanged) {
        setDialogSuccessMessage(successMessage)
        setDialogSuccessOpen(true)
        getInsightSentence(libraryId)
        if (onSave) {
          onSave({ ...sentenceFormValues, id: sentenceId })
        }
        setUnsavedChanges(false)
      }
    } catch (error) {
      console.error("Error saving:", error)
      setDialogFailMessage(`Failed to: ${error.message}`)
      setDialogFailOpen(true)
    }
  }

  const handlePreview = async () => {
    try {
      if (!sentenceFormValues || !sentenceFormValues.template) {
        throw new Error("Template or other required values are missing.")
      }
      // Fetch the template from sentenceFormValues
      const templateString = sentenceFormValues.template

      if (!templateString) {
        setFormErrors(prevErrors => ({
          ...prevErrors,
          template: "Template is required"
        }))
        return
      }

      const previewRequestBody = {
        template: templateString
      }

      const modelTypeId = sentenceFormValues.modelTypeId
      const response = await RealTimeDAO.putPreviewModelTypeId(
        previewRequestBody,
        modelTypeId
      )

      if (response.ok) {
        const evaluatedTemplate = await response.text()

        setTemplatePreview(evaluatedTemplate)
        setShowTemplatePreview(true)
      } else {
        let errorMessage = await response.text()
        console.error("Failed to fetch template preview:", errorMessage)
        const evaluatedTemplate = errorMessage

        setTemplatePreview(evaluatedTemplate)
        setShowTemplatePreview(true)
      }
    } catch (error) {
      console.error("Error while fetching template preview:", error)
      setDialogFailMessage(`${error.message}`)
      setDialogFailOpen(true)
    }
  }

  const handleViewInLibrary = () => {
    const url = `/portal/real-time-library/edit?id=${libraryId}&sentenceId=${sentenceId}`
    window.open(url, "_blank")
  }

  const handleChange = e => {
    const { name, value } = e.target
    setSentenceFormValues(prevValues => ({
      ...prevValues,
      [name]: value
    }))
    setUnsavedChanges(true)
  }

  const handleCheckboxChange = event => {
    setSentenceFormValues({
      ...sentenceFormValues,
      isArchived: event.target.checked
    })
    setUnsavedChanges(true)
  }

  const noOwner = {
    name: "NONE",
    firstName: "NONE",
    lastName: "",
    id: null
  }
  const sortedAccounts = [noOwner].concat(
    accounts
      ?.toJS()
      .filter(itm => itm.status === "Active")
      .sort((a, b) => {
        // Use toUpperCase() to ignore character casing
        const nameA = `${a.firstName} ${a.lastName}`.toUpperCase()
        const nameB = `${b.firstName} ${b.lastName}`.toUpperCase()
        let comparison = 0
        if (nameA > nameB) {
          comparison = 1
        } else if (nameA < nameB) {
          comparison = -1
        }
        return comparison
      })
  )

  return (
    <Card aria-label="RealTimeLibrary SentenceForm Card">
      <CardHeader
        action={
          <IconButton onClick={handleClose} style={{ right: "8px" }}>
            <Close />
          </IconButton>
        }
      />
      <FormTemplateNonDialog
        aria-label="RealTimeLibrary SentenceForm FormTemplate"
        open={open}
        onClose={handleClose}
        onReset={handleCancel}
        onSave={handleSave}
        title={""}
        showPreview={true}
        onPreview={handlePreview}
        hasPreview={true}
        onViewInLibrary={handleViewInLibrary}
        readOnly={readOnly}
        id={sentenceId}
        libraryId={libId}
      >
        <Accordion
          defaultExpanded
          aria-label="RealTimeLibrary SentenceForm Accordion"
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            style={{ backgroundColor: "#ddeaef", marginBottom: "20px" }}
          >
            <Typography variant="h6" component="div">
              Insight Sentence
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid className={classes.ownerGrid}>
              <GridItem xs={2}>
                <Autocomplete
                  aria-label="RealTimeLibrary SentenceForm OwnerField"
                  size="small"
                  name="ownerId"
                  id="ownerId"
                  options={sortedAccounts}
                  getOptionLabel={option =>
                    option.id === null
                      ? "None"
                      : `${option.firstName} ${option.lastName}`
                  }
                  value={
                    sentenceFormValues.owner
                      ? sortedAccounts.find(
                          acc => acc.id === sentenceFormValues.owner.id
                        ) || null
                      : null
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value?.id
                  }
                  onChange={(event, newValue) => {
                    setSentenceFormValues(prev => ({
                      ...prev,
                      owner: newValue
                        ? {
                            id: newValue.id,
                            name: `${newValue.firstName} ${newValue.lastName}`
                          }
                        : { id: null, name: "" }
                    }))
                    setUnsavedChanges(true)
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="OWNER"
                      key={params.id}
                      value={params.name}
                    />
                  )}
                  disabled={readOnly}
                />
              </GridItem>
              <GridItem xs={2}>
                <RealTimeInsightStatus
                  sx={{ size: "small" }}
                  value={sentenceFormValues.statusId}
                  onChange={newValue => {
                    setSentenceFormValues(prev => ({
                      ...prev,
                      statusId: newValue ? newValue.id : null,
                      statusName: newValue ? newValue.name : null
                    }))
                    setUnsavedChanges(true)
                  }}
                  readOnly={readOnly}
                />
              </GridItem>
            </Grid>
            <Grid container columnSpacing={{ xs: 2, sm: 2 }}>
              <GridItem xs={2}>
                {sentenceFormValues.id && (
                  <TextField
                    aria-label="RealTimeLibrary SentenceForm IdField"
                    id="sentenceId"
                    label=" INSIGHT SENTENCE ID"
                    fullWidth={true}
                    value={sentenceFormValues.id}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    disabled={readOnly}
                  />
                )}
                {!sentenceFormValues.id && (
                  <TextField
                    label="NEW SENTENCE"
                    id="sentenceId"
                    variant="outlined"
                    fullWidth={true}
                    disabled={true}
                  />
                )}
              </GridItem>
              <GridItem xs={4}>
                <TextField
                  required
                  id="name"
                  name="name"
                  label="INSIGHT NAME"
                  fullWidth={true}
                  value={sentenceFormValues.name}
                  onChange={handleChange}
                  error={!!formErrors.name}
                  helperText={formErrors.name}
                  disabled={readOnly}
                />
              </GridItem>
              <GridItem xs={2}>
                <TextField
                  aria-label="RealTimeLibrary SentenceForm GroupField"
                  id="insightGroup"
                  label="INSIGHT GROUP"
                  fullWidth={true}
                  value={sentenceFormValues.insightGroup}
                  InputLabelProps={{ shrink: true }}
                  disabled={readOnly}
                />
              </GridItem>
              <GridItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      aria-label="RealTimeLibrary SentenceForm archiveCheckbox"
                      id="isArchived"
                      checked={sentenceFormValues.isArchived}
                      onChange={handleCheckboxChange}
                      disabled={readOnly}
                    />
                  }
                  label="ARCHIVE"
                  labelPlacement="end"
                />
              </GridItem>
            </Grid>
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 2 }}
              className={classes.formRow}
            >
              <GridItem xs={3}>
                <TextField
                  aria-label="RealTimeLibrary SentenceForm InsightTypeField"
                  id="insightTypeId"
                  label="INSIGHT TYPE"
                  fullWidth
                  value={sentenceFormValues.insightType}
                  disabled={true}
                />
              </GridItem>
              <GridItem xs={3}>
                <Autocomplete
                  aria-label="RealTimeLibrary SentenceForm ModelTypeField"
                  id="modelCriteria"
                  multiple
                  limitTags={2}
                  options={modelCriteriaOptions.sort(Helpers.sortByName)}
                  value={selectedProperties.sort(Helpers.sortByName)}
                  getOptionLabel={option => option.label || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  onChange={handleModelTypeChange}
                  loading={modelLoading}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={
                        selected
                          ? { backgroundColor: "#DDEAEF" }
                          : { backgroundColor: "#fff" }
                      }
                    >
                      {option.name}
                    </li>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="MODEL CRITERIA"
                      required
                      error={!!formErrors.selectedProperties}
                      helperText={formErrors.selectedProperties}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {modelLoading ? "Loading..." : null}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                  disabled={readOnly}
                />
              </GridItem>
            </Grid>
            <Grid container columnSpacing={{ xs: 2, sm: 2 }}>
              {selectedProperties.map(modelCriteriaProperties => (
                <GridItem
                  key={modelCriteriaProperties.name}
                  xs={3}
                  style={{ margin: "7px 0" }}
                >
                  <Autocomplete
                    aria-label="RealTimeLibrary SentenceForm ModelTypeOptions"
                    id="modelCriteriaOptions"
                    required
                    multiple
                    limitTags={2}
                    // key={modelCriteriaProperties.name}
                    options={(
                      propertyMap.get(modelCriteriaProperties.name) || []
                    ).sort(Helpers.sortById)}
                    getOptionLabel={option =>
                      option.value && option.name
                        ? `${option.value} - ${option.name}`
                        : option.value || option.name || "No label"
                    }
                    value={(
                      modelOptions[modelCriteriaProperties.name] || []
                    ).sort(Helpers.sortById)}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    onChange={(event, newValue) => {
                      setModelOptions(prevOptions => ({
                        ...prevOptions,
                        [modelCriteriaProperties.name]: newValue
                      }))
                      setModelCriteriaUnsavedChanges(true)
                      setFormErrors(prevErrors => ({
                        ...prevErrors,
                        [modelCriteriaProperties.name]: undefined
                      }))
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li
                        {...props}
                        style={
                          selected
                            ? { backgroundColor: "#DDEAEF" }
                            : { backgroundColor: "#fff" }
                        }
                      >
                        {option.name}
                      </li>
                    )}
                    renderInput={params => (
                      <TextField
                        required
                        {...params}
                        label={modelCriteriaProperties.name}
                        error={!!formErrors[modelCriteriaProperties.name]}
                        helperText={formErrors[modelCriteriaProperties.name]}
                      />
                    )}
                    disabled={readOnly}
                  />
                </GridItem>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
        {!formIsLoading && (
          <Grid>
            <CodeEditor
              withTabs
              template={sentenceFormValues.template}
              handleChange={editor => {
                if (editor && editor.getValue()) {
                  const editorContent = editor.getValue()
                  setSentenceFormValues(prevValues => ({
                    ...prevValues,
                    template: editorContent
                  }))
                  //validate
                  if (!editorContent) {
                    setFormErrors(prevErrors => ({
                      ...prevErrors,
                      template: "Template is required"
                    }))
                  } else {
                    setFormErrors(prevErrors => {
                      const { template, ...rest } = prevErrors
                      return rest
                    })
                  }
                  setUnsavedChanges(true)
                }
              }}
              isNotLibrary={readOnly}
            />
            {formErrors.template && (
              <Alert variant="filled" severity="error">
                {formErrors.template}
              </Alert>
            )}
            {showTemplatePreview && (
              <Grid
                container
                spacing={1}
                item
                xs={12}
                style={{
                  display: "inline-block",
                  paddingTop: "16px",
                  paddingLeft: "24px"
                }}
              >
                <WordCounter
                  aria-label="RealTime SentenceForm wordCounterPreviewTemplate"
                  title="Metrics"
                  inputString={templatePreview}
                />
                <Grid
                  item
                  style={{
                    marginTop: "10px",
                    fontSize: "16px"
                  }}
                >
                  <div
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                      position: "relative"
                    }}
                    dangerouslySetInnerHTML={{
                      __html:
                        templatePreview && templatePreview.replace(/apxh:/g, "")
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </FormTemplateNonDialog>
      <Grid>
        <Accordion aria-label="RealTimeLibrary SentenceForm AccordionChangelog">
          <AccordionSummary expandIcon={<ExpandMore />}>
            ChangeLog
          </AccordionSummary>
          <AccordionDetails>
            <ChangeLog aria-label="RealTimeLibrary SentenceForm ChangeLog" />
          </AccordionDetails>
        </Accordion>
      </Grid>
      {openAlert && (
        <Snackbar
          style={{ top: "150px" }}
          open={openAlert}
          onClose={closeAlert}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            variant="filled"
            severity="error"
            action={
              <Button
                aria-label="close"
                color="inherit"
                variant="outlined"
                size="small"
                onClick={handleConfirmClose}
              >
                Close Alert
              </Button>
            }
          >
            Must save changes before closing the form.
          </Alert>
        </Snackbar>
      )}
    </Card>
  )
}
