// eslint-disable-next-line no-unused-vars
import React, { Component } from "react"
import { CircularProgress } from "@mui/material"
import { globalSettings } from "variables/general"
import { put } from "tools/request"

class SpellingAndGrammar extends Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      spellingAndGrammarCheckResults: {},
      matches: [],
      isLoading: false,
      hasFoundLanguageIssues: false,
      hasCheckedLanguage: false
    }
  }

  componentDidMount() {
    this.checkSpellingAndGrammar(this.props.text)
  }

  checkSpellingAndGrammar(text) {
    this.setState({ isLoading: true, feedEntries: [] })
    const body = {
      language: "en-US",
      text: text
    }
    put(`${globalSettings.apiBaseUrl}/api/languagetool/check`, null, body)
      .then(Response => Response.json())
      .then(response => {
        let spellingAndGrammarCheckResults = response.content || {}
        let matches =
          (spellingAndGrammarCheckResults &&
            spellingAndGrammarCheckResults.checkResponse &&
            spellingAndGrammarCheckResults.checkResponse.matches) ||
          []
        this.setState({
          spellingAndGrammarCheckResults,
          matches,
          isLoading: false,
          hasFoundLanguageIssues: matches.length > 0 ? true : false,
          hasCheckedLanguage: true
        })
      })
      .catch(err => {
        console.error("Error on PUT to /api/languagetool/check", err)
      })
  }

  addHighlight(text, offsetPosition, length) {
    return (
      <em>
        {text.substring(0, offsetPosition)}
        <span style={{ backgroundColor: "yellow" }}>
          {text.substring(offsetPosition, offsetPosition + length)}
        </span>
        {text.substring(offsetPosition + length)}
      </em>
    )
  }

  render() {
    return (
      <div>
        {this.state.isLoading && <CircularProgress />}
        {this.state.hasCheckedLanguage &&
          this.state.matches.map((m, index) => (
            <div>
              {index + 1}. <b>{m.message.replace(".", "")}</b>
              {m.context && (
                <span>
                  {" "}
                  in the context:{" "}
                  <em>
                    {this.addHighlight(
                      m.context.text,
                      m.context.offset,
                      m.context.length
                    )}
                  </em>
                </span>
              )}
              {m.replacements && (
                <div style={{ fontSize: "0.8em", marginLeft: "20px" }}>
                  <b> - Possible replacements:</b>{" "}
                  {m.replacements.map(r => (
                    <span>{r.value}, </span>
                  ))}
                </div>
              )}
            </div>
          ))}
        {this.state.hasCheckedLanguage &&
          !this.state.hasFoundLanguageIssues && (
            <div>No spelling mistakes or grammar issues.</div>
          )}
      </div>
    )
  }
}

export default SpellingAndGrammar
