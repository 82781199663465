import React from "react"
import cx from "classnames"
import PropTypes from "prop-types"
import { Switch, Route, Redirect } from "react-router-dom"
import { withRouter } from "react-router"
import PerfectScrollbar from "perfect-scrollbar"
import { observer, inject } from "mobx-react"
import { toJS } from "mobx"

import "perfect-scrollbar/css/perfect-scrollbar.css"
import withStyles from "@mui/styles/withStyles"
import Header from "components/Header/Header"
import Footer from "components/Footer/Footer"
import Sidebar from "components/Sidebar/SidebarNestedMenus"
import dashboardRoutes from "routes/dashboard"
import appStyle from "assets/jss/material-dashboard-pro-react/layouts/dashboardStyle"
import image from "assets/img/sidebar-2.jpg"
import logo from "assets/img/dataskrive-logo-70x70-white.png"
import { Cookies } from "tools/storage"
import { globalSettings } from "variables/general"
import Helpers from "../tools/Helpers"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from "@mui/material"
import GridItem from "components/Grid/GridItem"
import Grid from "@mui/material/Grid"
import Alert from "@mui/material/Alert"
import Snackbar from "@mui/material/Snackbar"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import { Announcement } from "@mui/icons-material"
import { fromJS } from "immutable"
import CustomDSDialog from "components/CustomDialogs/CustomDSDialog"

import InfoOutlined from "@mui/icons-material/InfoOutlined"
import Report from "@mui/icons-material/Report"
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline"
import { Warning } from "@mui/icons-material"
import { get } from "tools/request"

const useStore = component =>
  inject(({ store }) => ({
    setAuthenticated: store.sessionStore.setAuthenticated,
    setAccount: store.accountStore.setAccount,
    getPermissions: store.accountStore.getPermissions,
    accountPermissions: store.accountStore.accountPermissions,
    accountOrganizations: store.accountStore.accountOrganizations,
    accountProperties: store.accountStore.accountProperties,
    announcementMessage: store.appConfigurationStore.announcementMessage,
    setIsAccountDetailsLoaded: store.uiStore.setIsAccountDetailsLoaded,
    setSiteInfo: store.appConfigurationStore.setSiteInfo,
    setIsSiteInfoLoaded: store.uiStore.setIsSiteInfoLoaded,
    setOrganization: store.organizationStore.setOrganization,
    setOrganizations: store.organizationStore.setOrganizations,
    setOrganizationProperties:
      store.organizationStore.setOrganizationProperties,
    organizationId: store.organizationStore.organizationId,
    setOrganizationId: store.organizationStore.setOrganizationId,
    setPropertyId: store.organizationStore.setPropertyId,
    setOrgAndPropId: store.organizationStore.setOrgAndPropId,
    setAccountPermissions: store.accountStore.setAccountPermissions,
    setIsPermissionsLoaded: store.uiStore.setIsPermissionsLoaded,
    isError: store.uiStore.isError,
    errorMessage: store.uiStore.errorMessage,
    setIsError: store.uiStore.setIsError,
    isSuccess: store.uiStore.isSuccess,
    successMessage: store.uiStore.successMessage,
    setIsSuccess: store.uiStore.setIsSuccess,
    dialogWarningMessage: store.uiStore.dialogWarningMessage,
    dialogWarningOpen: store.uiStore.dialogWarningOpen,
    setDialogWarningOpen: store.uiStore.setDialogWarningOpen,
    setDialogWarningSuccess: store.uiStore.setDialogWarningSuccess,
    dialogInfoMessage: store.uiStore.dialogInfoMessage,
    dialogInfoOpen: store.uiStore.dialogInfoOpen,
    setDialogInfoOpen: store.uiStore.setDialogInfoOpen,
    dialogSuccessOpen: store.uiStore.dialogSuccessOpen,
    dialogSuccessMessage: store.uiStore.dialogSuccessMessage,
    setDialogSuccessOpen: store.uiStore.setDialogSuccessOpen,
    dialogFailOpen: store.uiStore.dialogFailOpen,
    dialogFailMessage: store.uiStore.dialogFailMessage,
    setDialogFailOpen: store.uiStore.setDialogFailOpen,
    dialogWarningNoBtnOpen: store.uiStore.dialogWarningNoBtnOpen,
    setDialogWarningNoBtnOpen: store.uiStore.setDialogWarningNoBtnOpen,
    dialogWarningNoBtnMessage: store.uiStore.dialogWarningNoBtnMessage,
    setDialogWarningNoBtnMessage: store.uiStore.setDialogWarningNoBtnMessage
  }))(observer(component))

const switchRoutes = () => (
  <Switch>
    {dashboardRoutes.map((prop, key) => {
      if (prop.redirect) {
        return <Redirect from={prop.path} to={prop.pathTo} key={key} />
      }
      if (prop.collapse) {
        return prop.views.map((prp, key) => {
          if (!prp.collapse) {
            return <Route path={prp.path} component={prp.component} key={key} />
          } else {
            return prp.views.map((prpx, keyx) => (
              <Route path={prpx.path} component={prpx.component} key={keyx} />
            ))
          }
        })
      }
      return <Route path={prop.path} component={prop.component} key={key} />
    })}
  </Switch>
)

let ps

class Dashboard extends React.Component {
  constructor(props) {
    super(props)
    let cookies = new Cookies()
    const miniActive = cookies.get("miniActive") === true || false
    const mobileOpen = cookies.get("mobileOpen") === true || false
    this.state = {
      mobileOpen,
      miniActive,
      isLoggedIn: false,
      modalOpen: false
    }
    this.resizeFunction = this.resizeFunction.bind(this)
  }
  async componentDidMount() {
    await this.props.getPermissions()
    this.loadPermissions()
    this.loadOrganizations()
    this.loadAccountDetails()
    this.loadSiteInfo()
    this.props.setAuthenticated(true)

    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false
      })
      document.body.style.overflow = "hidden"
    }
    window.addEventListener("resize", this.resizeFunction)
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      console.log("Route Change", {
        name: "Dashboard",
        id: 4252
      })
      this.refs.mainPanel.scrollTop = 0
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false })
      }
      const isDashboard = true
      Helpers.updateBrowserTabTitle(isDashboard)
    }

    if (
      this.props.announcementMessage !== e.announcementMessage &&
      this.props.announcementMessage !== null
    ) {
      const cookies = new Cookies()
      const date = new Date().getTime()
      const lsAppAnnouncementObj = cookies.get("appAnnouncement")
      if (
        date > lsAppAnnouncementObj.readExpiration ||
        lsAppAnnouncementObj.readExpiration === undefined
      ) {
        this.setState({ modalOpen: true })
      }
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy()
    }
    window.removeEventListener("resize", this.resizeFunction)
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen })
    let cookies = new Cookies()
    cookies.set("mobileOpen", !this.state.mobileOpen, {
      path: "/",
      expires: Helpers.CookieExpiration.OneMonth
    })
  }

  getRoute() {
    return this.props.location.pathname !== "/maps/full-screen-maps"
  }

  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive })
    let cookies = new Cookies()
    cookies.set("miniActive", !this.state.miniActive, {
      path: "/",
      expires: Helpers.CookieExpiration.OneMonth
    })
  }

  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false })
    }
  }

  loadPermissions() {
    this.props.setIsPermissionsLoaded(true)
  }

  loadOrganizations() {
    let accountOrganizations = toJS(this.props.accountOrganizations)
    this.setState({
      isPermissionsLoaded: true
    })
    let properties = []
    accountOrganizations.forEach(itm => {
      itm.properties &&
        itm.properties.forEach(itm2 => {
          properties.push(itm2)
        })
    })
    this.props.setOrganizations(fromJS(accountOrganizations))
    this.props.setOrganizationProperties(properties)

    // check if the user already selected an organization from the drop-down menu
    //set org to what is currently stored in the currentorg cookie
    let cookies = new Cookies()
    let cookieCurrentOrg = cookies.get("currentorg")
    let cookieCurrentProp = cookies.get("currentProperty")
    if (cookieCurrentProp && cookieCurrentProp === "All Properties") {
      cookieCurrentProp = cookies.get("currentorg")
    }
    if (cookieCurrentOrg) {
      // Only set the organization id to the cookie value,
      // if this current user has "access" to that organization id
      let foundOrganization = accountOrganizations.filter(
        o => o.id === cookieCurrentOrg
      )
      if (foundOrganization.length > 0) {
        this.props.setOrganization(foundOrganization[0])
        this.props.setOrganizationId(cookieCurrentOrg)
      } else {
        this.props.setOrganization(accountOrganizations[0])
        this.props.setOrganizationId(accountOrganizations[0].id)
      }
      if (cookieCurrentProp) {
        this.props.setPropertyId(cookieCurrentProp)
        this.props.setOrgAndPropId(cookieCurrentProp)
      } else {
        this.props.setOrgAndPropId(this.props.organizationId)
      }
    } else {
      // Set the organization to the first organization in this account's list of
      // available organizations
      this.props.setOrganization(accountOrganizations[0])
      this.props.setOrganizationId(accountOrganizations[0].id)
      this.props.setOrgAndPropId(accountOrganizations[0].id)
    }
  }

  loadAccountDetails() {
    get(`${globalSettings.apiBaseUrl}/api/account/retrieveaccountdetails`)
      .then(Response => Response.json())
      .then(data => {
        if (data.responseCode === 1000) {
          this.props.setAccount(data.content)
          this.props.setIsAccountDetailsLoaded(true)
          this.setState({ isLoggedIn: true })
        } else {
          this.props.setAccount(null)
          this.props.setIsAccountDetailsLoaded(false)
          this.setState({ isLoggedIn: false })
        }
      })
      .catch(err => {
        console.error(
          "Error on GET to /api/account/retrieveaccountdetails",
          err
        )
      })
  }

  loadSiteInfo() {
    get(`${globalSettings.apiBaseUrl}/api/siteinfo`)
      .then(Response => Response.json())
      .then(data => {
        this.props.setSiteInfo(data)
        this.props.setIsSiteInfoLoaded(true)
      })
      .catch(err => {
        console.error("Error on GET to /api/account/siteinfo", err)
      })
  }

  handleModalClose = () => {
    let cookies = new Cookies()
    this.setState({ modalOpen: !this.state.modalOpen })
    cookies.set("appAnnouncement", {
      announcementRead: Date.now(),
      readExpiration: Date.now() + 3 * 24 * 60 * 60 * 1000
    })
  }

  handleSnackbarClose = () => {
    this.props.setIsError(false)
  }

  handleSnackbarSuccessClose = () => {
    this.props.setIsSuccess(false)
  }

  parseFailMessage = list => (
    <div>
      <span>The following errors occurred</span>
      <ol>
        {list?.map((itm, i) => (
          <li key={i}>
            <Grid style={{ display: "flex" }}>
              <GridItem xs={6}>{itm.type}</GridItem>
              <GridItem xs={6}>{itm.value}</GridItem>
            </Grid>
          </li>
        ))}
      </ol>
    </div>
  )

  determineFailMessageType = data => {
    if (typeof data === "object" && data.length) {
      return this.parseFailMessage(data)
    } else {
      return data
    }
  }

  render() {
    const { classes, ...rest } = this.props
    const mainPanel = `${classes.mainPanel} ${cx({
      [classes.mainPanelSidebarMini]: this.state.miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1
    })}`

    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={dashboardRoutes}
          logoText={"Data Skrive"}
          logo={logo}
          image={image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color="white"
          bgColor="teal"
          miniActive={this.state.miniActive}
          {...rest}
        />
        <div className={mainPanel} ref="mainPanel">
          <Header
            sidebarMinimize={this.sidebarMinimize.bind(this)}
            miniActive={this.state.miniActive}
            routes={dashboardRoutes}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {this.getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>
                {switchRoutes(this.props)}
              </div>
            </div>
          ) : (
            <div className={classes.map}>{switchRoutes(this.props)}</div>
          )}
          {this.getRoute() ? <Footer fluid /> : null}
        </div>
        <Dialog
          open={this.state.modalOpen}
          onClose={this.handleModalClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          fullWidth
          maxWidth="xs"
        >
          <div>
            <DialogTitle id="alert-dialog-title">
              <Announcement style={{ marginRight: ".5rem" }} />
              Announcement
            </DialogTitle>
            <DialogContent>
              <DialogContentText variant="subtitle2">
                {this.props.announcementMessage}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleModalClose} color="primary">
                Dismiss
              </Button>
            </DialogActions>
          </div>
        </Dialog>
        <Snackbar
          style={{
            top: "40vh",
            left: "40vw",
            position: "fixed",
            display: "block"
          }}
          open={this.props.isSuccess}
          //autoHideDuration={12000}
          onClose={this.handleSnackbarSuccessClose}
        >
          <Alert
            variant="filled"
            onClose={this.handleSnackbarSuccessClose}
            severity="success"
            action={
              <IconButton
                size="small"
                aria-label="close snackbar"
                color="inherit"
                onClick={this.handleSnackbarSuccessClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          >
            {this.props.successMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.props.isError}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
        >
          <Alert
            variant="filled"
            onClose={this.handleSnackbarClose}
            severity="error"
            action={
              <IconButton
                size="small"
                aria-label="close snackbar"
                color="inherit"
                onClick={this.handleSnackbarClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          >
            {this.props.errorMessage}
          </Alert>
        </Snackbar>
        <CustomDSDialog
          open={this.props.dialogWarningOpen || false}
          content={this.props.dialogWarningMessage || ""}
          icon={<Warning style={{ fontSize: "4rem" }} />}
          backgroundColor={"#FFC107"}
          onClose={() => {
            this.props.setDialogWarningOpen(false)
          }}
          buttonProps={[
            {
              label: "No",
              onClick: () => {
                this.props.setDialogWarningOpen(false)
                this.props.setDialogWarningSuccess(false)
              }
            },
            {
              label: "Yes",
              color: "primary",
              onClick: () => {
                this.props.setDialogWarningSuccess(true)
              }
            }
          ]}
        />
        <CustomDSDialog
          open={this.props.dialogInfoOpen || false}
          content={this.props.dialogInfoMessage || ""}
          icon={<InfoOutlined style={{ fontSize: "4rem" }} />}
          onClose={() => {
            this.props.setDialogInfoOpen(false)
          }}
        />
        <CustomDSDialog
          //use this Warning dialog for no buttons
          open={this.props.dialogWarningNoBtnOpen}
          content={this.props.dialogWarningNoBtnMessage || ""}
          icon={<Warning style={{ fontSize: "4rem" }} />}
          backgroundColor={"#FFC107"}
          onClose={() => {
            this.props.setDialogWarningNoBtnOpen(false)
          }}
        />
        <CustomDSDialog
          open={this.props.dialogSuccessOpen}
          content={this.props.dialogSuccessMessage || ""}
          icon={<CheckCircleOutline style={{ fontSize: "4rem" }} />}
          backgroundColor={"#66BB6A"}
          onClose={() => {
            this.props.setDialogSuccessOpen(false)
          }}
        />
        <CustomDSDialog
          open={this.props.dialogFailOpen}
          content={this.determineFailMessageType(this.props.dialogFailMessage)}
          icon={<Report style={{ fontSize: "4rem" }} />}
          backgroundColor={"#A31515"}
          onClose={() => {
            this.props.setDialogFailOpen(false)
          }}
        />
      </div>
    )
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(appStyle)(withRouter(useStore(Dashboard)))
