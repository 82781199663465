import React, { useRef, useEffect } from "react"
import { useObserver } from "mobx-react"
import { Link } from "react-router-dom"
import { useStore } from "contexts/rootContext"
import makeStyles from "@mui/styles/makeStyles"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardHeader from "components/Card/CardHeader"
import TimeAgo from "react-timeago"
import narrativeDashboardStyles from "assets/jss/material-dashboard-pro-react/views/narrativeDashboardStyles"
import { Cookies } from "tools/storage"

import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Enums from "tools/Enums.js"

import Helpers from "../../tools/Helpers"
import CardFooter from "components/Card/CardFooter"

import Highlighter from "react-highlight-words"

import { IconButton, Tooltip, Dialog } from "@mui/material"
import SearchEditor from "components/NarrativeAdmin/Data/SearchEditor"

import { Edit } from "@mui/icons-material"
import PropTypes from "prop-types"

const useStyles = makeStyles(() => ({
  ...narrativeDashboardStyles,
  card: {
    marginTop: 0,
    borderRadius: "3px",
    "&:hover": {
      boxShadow:
        "0 16px 29px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    },
    position: "relative",
    "& .highlighted": {
      backgroundColor: "#ffeaa7",
      color: "#e17055",
      boxShadow: "-3px 0 0 #ffeaa7, 3px 0 0 #ffeaa7" // Creates an illusion of a wider span, while not affecting the actual width.
    }
  },
  cardCompact: {
    marginTop: 0,
    marginBottom: 0,
    boxShadow: "none",
    "&:hover": {
      position: "relative",
      zIndex: 192,
      boxShadow:
        "0 16px 28px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    }
  },
  inactiveCard: {
    backgroundColor: "#f7f7f7",
    marginTop: 0,
    borderRadius: "3px",
    "&:hover": {
      boxShadow:
        "0 16px 29px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    },
    position: "relative",
    "& .highlighted": {
      backgroundColor: "#ffeaa7",
      color: "#e17055",
      boxShadow: "-3px 0 0 #ffeaa7, 3px 0 0 #ffeaa7" // Creates an illusion of a wider span, while not affecting the actual width.
    }
  },
  renderScheduleModalTop: {
    height: "70vh"
  },
  renderScheduleModal: {
    width: "60vw",
    margin: "0 auto"
  },
  searchResultEdit: {
    border: "2px solid #4d9ab5",
    borderRadius: "10px 0 0 0"
  },
  inactiveCardCompact: {
    backgroundColor: "#f7f7f7",
    marginTop: 0,
    marginBottom: 0,
    boxShadow: "none",
    "&:hover": {
      position: "relative",
      zIndex: 192,
      boxShadow:
        "0 16px 28px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    }
  },
  cardHeader: {
    display: "flex",
    fontSize: "16px",
    borderBottom: "1px solid #efefef"
  },
  cardbodyCompact: {
    ...narrativeDashboardStyles.cardBody,
    padding: "10px"
  },
  cardFooter: {
    borderTop: "none",
    display: "flex"
  }
}))

function convertBlockStatusIdToStatusName(statusId) {
  let statusName = "N/A"
  switch (statusId) {
    case 1:
      statusName = "Draft"
      break
    case 2:
      statusName = "Review"
      break
    case 3:
      statusName = "Published"
      break
    case 4:
      statusName = "Planned"
      break
    case 5:
      statusName = "Sample"
      break
  }
  return statusName
}

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    searchString: store.narrativeStore.searchString
  }))
}

const NarrativeSearchBlock = props => {
  const { searchString } = useStoreData()
  const classes = useStyles()
  const { searchResult, blockViewStyle } = props
  const [editReturnedSearch, setEditReturnedSearch] = React.useState(false)
  const [editSearch, setEditSearch] = React.useState(false)
  const [searchEditorHadLoaded, setSearchEditorHadLoaded] =
    React.useState(false)

  const [returnedSearchParameters, setReturnedSearchParameters] =
    React.useState({})

  const divRef = useRef()

  useEffect(() => {
    divRef && divRef.current && divRef.current.focus()
  }, [editReturnedSearch, returnedSearchParameters])

  const getModifiedDateTime = block => {
    let lastModTime = new Date(block.modifieddatetime)
    let nowTime = new Date()
    let lastModString = ""
    let diff = Math.floor((nowTime.getTime() - lastModTime.getTime()) / 60000)
    if (diff < 1) {
      lastModString = "Last modified less than 1 minute ago"
    } else if (diff > 1440) {
      //lastModString = `Last modified on ${block.modifieddatetime}`
      lastModString = `Last modified ${Math.floor(diff / 1440)} day${
        diff / 1440 > 1 ? "s" : ""
      } ${Math.floor((diff / 60) % 24)} hr${
        (diff / 60) % 24 > 1 ? "s" : ""
      } ago`
    } else if (diff > 119) {
      lastModString = `Last modified ${Math.floor(diff / 60)} hr${
        diff / 60 > 1 ? "s" : ""
      } ${diff % 60} minute${diff % 60 > 1 ? "s" : ""} ago`
    } else if (diff > 59) {
      lastModString = `Last modified ${Math.floor(diff / 60)} hr ${
        diff % 60
      } minute${diff % 60 > 1 ? "s" : ""} ago`
    } else if (diff < 60) {
      lastModString = `Last modified ${diff} minute${diff > 1 ? "s" : ""} ago`
    }
    return `${lastModString}`
    //setLastTimeModified(lastModString)
  }

  const editSearchResult = (block, type, typeId, narrativeId, view, narr) => {
    setReturnedSearchParameters({
      type: type,
      typeId: typeId,
      narrativeId: narrativeId,
      view: view,
      orgName: block.organization_name || narr.organization.name,
      narrativeName: block.narrative || narr.name,
      orgId: block.organization_id
    })
    setEditReturnedSearch(true)
    setEditSearch(true)
  }

  const cookies = new Cookies()
  const temp = cookies.get("editSearchSentenceInPara")
  if (temp && !editReturnedSearch) {
    cookies.set("editSearchSentenceInPara", 0)
    setEditReturnedSearch(true)
    setEditSearch(true)
  }

  const BlockHeader = block => {
    switch (block.blocktype) {
      case "FeedEntry":
        return (
          <GridContainer spacing={2}>
            <GridItem xs={12}>
              <Link
                title={`Open Feed Entry ${block.feedentry_id}`}
                to={`/portal/feed-entries/entry/${block.feedentry_id}`}
              >
                {block.blocktype && (
                  <strong style={{ marginRight: "10px" }}>Feed Entry</strong>
                )}
                {block.name && (
                  <Highlighter
                    highlightClassName="highlighted"
                    searchWords={[searchString]}
                    autoEscape={true}
                    textToHighlight={block.name}
                  />
                )}
              </Link>
            </GridItem>
          </GridContainer>
        )
      case "Narrative":
        return (
          <GridContainer spacing={2}>
            <GridItem xs={12}>
              <Link
                title={`Open Narrative ${block.narrative_id}`}
                to={`/portal/narrative/${block.narrative_id}`}
              >
                {block.blocktype && (
                  <strong style={{ marginRight: "10px" }}>
                    {block.blocktype}
                  </strong>
                )}
                {block.name && (
                  <Highlighter
                    highlightClassName="highlighted"
                    searchWords={[searchString]}
                    autoEscape={true}
                    textToHighlight={block.name}
                  />
                )}
              </Link>
            </GridItem>
          </GridContainer>
        )
      case "Trigger":
        return (
          <GridContainer spacing={2}>
            <GridItem xs={7}>
              <Link
                title={`Open Trigger ${block.trigger_id}`}
                to={`/portal/narrative/${block.narrative_id}/edit?view=triggerid-${block.trigger_id}`}
              >
                {block.blocktype && (
                  <strong style={{ marginRight: "10px" }}>
                    {block.blocktype}
                  </strong>
                )}
                {block.name && (
                  <Highlighter
                    highlightClassName="highlighted"
                    searchWords={[searchString]}
                    autoEscape={true}
                    textToHighlight={block.name}
                  />
                )}
              </Link>
            </GridItem>
            <GridItem xs={4}>
              <Link
                title={`Open Narrative ${block.narrative_id}`}
                to={`/portal/narrative/${block.narrative_id}`}
              >
                <Highlighter
                  highlightClassName="highlighted"
                  searchWords={[searchString]}
                  autoEscape={true}
                  textToHighlight={block.narrative}
                />
              </Link>
            </GridItem>
            <GridItem xs={1} style={{ padding: "0" }}>
              <Tooltip
                id="tooltip-top"
                title={`Edit Trigger ${block.trigger_id}`}
                placement="top"
                arrow
              >
                <IconButton
                  aria-label="Edit"
                  onClick={() => {
                    setEditSearch(true)
                    setSearchEditorHadLoaded(false)
                    editSearchResult(
                      block,
                      "trigger",
                      `${block.trigger_id}`,
                      `${block.narrative_id}`,
                      `triggerid-${block.trigger_id}`
                    )
                  }}
                  disabled={false}
                  style={{ padding: "0" }}
                  size="large"
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            </GridItem>
          </GridContainer>
        )
      case "Paragraph":
        return (
          <GridContainer spacing={2}>
            <GridItem xs={7}>
              <Link
                title={`Open Paragraph ${block.paragraph_id}`}
                to={`/portal/narrative/${block.narrative_id}/edit?view=paragraphid-${block.paragraph_id}`}
              >
                {block.blocktype && (
                  <strong style={{ marginRight: "10px" }}>
                    {block.blocktype}
                  </strong>
                )}
                {block.name && (
                  <Highlighter
                    highlightClassName="highlighted"
                    searchWords={[searchString]}
                    autoEscape={true}
                    textToHighlight={block.name}
                  />
                )}
              </Link>
            </GridItem>
            <GridItem xs={4}>
              <Link
                title={`Open Narrative ${block.narrative_id}`}
                to={`/portal/narrative/${block.narrative_id}`}
              >
                <Highlighter
                  highlightClassName="highlighted"
                  searchWords={[searchString]}
                  autoEscape={true}
                  textToHighlight={block.narrative}
                />
              </Link>
            </GridItem>
            <GridItem xs={1} style={{ padding: "0" }}>
              <Tooltip
                id="tooltip-top"
                title={`Edit Paragraph ${block.paragraph_id}`}
                placement="top"
                arrow
              >
                <IconButton
                  aria-label="Edit"
                  onClick={() => {
                    setEditSearch(true)
                    setSearchEditorHadLoaded(false)
                    editSearchResult(
                      block,
                      "paragraph",
                      `${block.paragraph_id}`,
                      `${block.narrative_id}`,
                      `paragraphid-${block.paragraph_id}`
                    )
                  }}
                  disabled={false}
                  style={{ padding: "0" }}
                  size="large"
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            </GridItem>
          </GridContainer>
        )
      case "Sentence":
        return (
          <GridContainer spacing={2}>
            <GridItem xs={7}>
              <Link
                title={`Open Sentence ${block.sentence_id}`}
                to={`/portal/narrative/${block.narrative_id}/edit?view=sentenceid-${block.sentence_id}`}
              >
                {block.blocktype && (
                  <strong style={{ marginRight: "10px" }}>
                    {block.blocktype}
                  </strong>
                )}
                {block.name && (
                  <Highlighter
                    highlightClassName="highlighted"
                    searchWords={[searchString]}
                    autoEscape={true}
                    textToHighlight={block.name}
                  />
                )}
              </Link>
            </GridItem>
            <GridItem xs={4}>
              <Link
                title={`Open Narrative ${block.narrative_id}`}
                to={`/portal/narrative/${block.narrative_id}`}
              >
                <Highlighter
                  highlightClassName="highlighted"
                  searchWords={[searchString]}
                  autoEscape={true}
                  textToHighlight={block.narrative}
                />
              </Link>
            </GridItem>
            <GridItem xs={1} style={{ padding: "0" }}>
              <Tooltip
                id="tooltip-top"
                title={`Edit Sentence ${block.sentence_id}`}
                placement="top"
                arrow
              >
                <IconButton
                  aria-label="Edit"
                  onClick={() => {
                    setEditSearch(true)
                    setSearchEditorHadLoaded(false)
                    editSearchResult(
                      block,
                      "sentence",
                      `${block.sentence_id}`,
                      `${block.narrative_id}`,
                      `sentenceid-${block.sentence_id}`
                    )
                  }}
                  disabled={false}
                  style={{ padding: "0" }}
                  size="large"
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            </GridItem>
          </GridContainer>
        )
    }
  }

  const titleTag =
    returnedSearchParameters.type === "paragraph"
      ? `Organization = "${returnedSearchParameters.orgName}", Narrative = "${returnedSearchParameters.narrativeName}(${returnedSearchParameters.narrativeId})", Paragraph = ${returnedSearchParameters.typeId}`
      : returnedSearchParameters.type === "sentence"
      ? `Organization = "${returnedSearchParameters.orgName}", Narrative = "${returnedSearchParameters.narrativeName}(${returnedSearchParameters.narrativeId})", Sentence = ${returnedSearchParameters.typeId}`
      : returnedSearchParameters.type === "trigger"
      ? `Organization = "${returnedSearchParameters.orgName}", Narrative = "${returnedSearchParameters.narrativeName}(${returnedSearchParameters.narrativeId})", Trigger = ${returnedSearchParameters.typeId}`
      : "Nothing"

  return (
    <div className={"highlighter"}>
      <Card
        title={
          (searchResult.status_id !== Enums.BlockStatus.ACTIVE ||
            searchResult.narrativestatus_id !== Enums.NarrativeStatus.ACTIVE) &&
          "Not Active"
        }
        className={
          searchResult.status_id !== Enums.BlockStatus.ACTIVE ||
          searchResult.narrativestatus_id !== Enums.NarrativeStatus.ACTIVE
            ? blockViewStyle !== Enums.BlockDisplayStyle.COMPACT
              ? classes.inactiveCard
              : classes.inactiveCompact
            : blockViewStyle !== Enums.BlockDisplayStyle.COMPACT
            ? classes.card
            : classes.cardCompact
        }
      >
        {(blockViewStyle === Enums.BlockDisplayStyle.STANDARD ||
          blockViewStyle === Enums.BlockDisplayStyle.DETAILED) && (
          <CardHeader className={classes.cardHeader}>
            <div style={{ display: "block", width: "100%" }}>
              {BlockHeader(searchResult)}
            </div>
          </CardHeader>
        )}
        {searchResult.content && (
          <div>
            <CardBody
              className={
                blockViewStyle !== Enums.BlockDisplayStyle.COMPACT
                  ? classes.cardBody
                  : classes.cardbodyCompact
              }
            >
              <Highlighter
                highlightClassName="highlighted"
                searchWords={[searchString]}
                autoEscape={true}
                textToHighlight={searchResult.content}
              />
            </CardBody>
          </div>
        )}
        <GridContainer spacing={2}>
          <GridItem xs={12} sm={6} />
          <GridItem
            xs={12}
            sm={6}
            style={{ textAlign: "right", padding: "8px 20px 12px 8px" }}
          >
            {getModifiedDateTime(searchResult)}
          </GridItem>
        </GridContainer>
        {blockViewStyle === Enums.BlockDisplayStyle.DETAILED && (
          <CardFooter className={classes.cardFooter}>
            <div style={{ display: "block", width: "100%" }}>
              <GridContainer spacing={2}>
                {searchResult.blocktype === "FeedEntry" && (
                  <GridItem xs={6}>
                    <Link
                      title={`Open Narrative ${searchResult.narrative_id}`}
                      to={`/portal/narrative/${searchResult.narrative_id}`}
                    >
                      <Highlighter
                        highlightClassName="highlighted"
                        searchWords={[searchString]}
                        autoEscape={true}
                        textToHighlight={searchResult.narrative}
                      />
                    </Link>
                  </GridItem>
                )}
                {searchResult.blocktype !== "FeedEntry" && (
                  <React.Fragment>
                    <GridItem xs={3}>
                      {searchResult.paragraph_id && (
                        <React.Fragment>
                          <strong>Paragrah Id:</strong>{" "}
                          <Link
                            title={`Open Paragraph ${searchResult.paragraph_id}`}
                            to={`/portal/narrative/${searchResult.narrative_id}/edit?view=paragraphid-${searchResult.paragraph_id}`}
                          >
                            {searchResult.paragraph_id}
                          </Link>
                        </React.Fragment>
                      )}
                      {searchResult.trigger_id && (
                        <React.Fragment>
                          <strong>Trigger Id:</strong>{" "}
                          <Link
                            title={`Open Trigger ${searchResult.trigger_id}`}
                            to={`/portal/narrative/${searchResult.narrative_id}/edit?view=triggerid-${searchResult.trigger_id}`}
                          >
                            {searchResult.trigger_id}
                          </Link>
                        </React.Fragment>
                      )}
                    </GridItem>
                    <GridItem xs={3}>
                      {searchResult.sentence_id && (
                        <React.Fragment>
                          <strong>Sentence Id:</strong>{" "}
                          <Link
                            title={`Open Sentence ${searchResult.sentence_id}`}
                            to={`/portal/narrative/${searchResult.narrative_id}/edit?view=sentenceid-${searchResult.sentence_id}`}
                          >
                            {searchResult.sentence_id}
                          </Link>
                        </React.Fragment>
                      )}
                    </GridItem>
                  </React.Fragment>
                )}
                <GridItem xs={3}>
                  <strong>Content Status:</strong>{" "}
                  {convertBlockStatusIdToStatusName(searchResult.status_id)}
                </GridItem>
                <GridItem xs={3}>
                  <strong>Created:</strong>{" "}
                  <TimeAgo
                    date={searchResult.createddatetime}
                    title={Helpers.prettyDateTimeinPacificTimeZone(
                      searchResult.createddatetime
                    )}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer spacing={2}>
                <GridItem xs={9}>
                  {searchResult.triggertype && (
                    <React.Fragment>
                      <strong>Triggers:</strong>{" "}
                      <Highlighter
                        highlightClassName="highlighted"
                        searchWords={[searchString]}
                        autoEscape={true}
                        textToHighlight={searchResult.triggertype}
                      />
                    </React.Fragment>
                  )}
                </GridItem>
                <GridItem xs={3}>
                  <strong>Modified:</strong>{" "}
                  <TimeAgo
                    date={searchResult.modifieddatetime}
                    title={Helpers.prettyDateTimeinPacificTimeZone(
                      searchResult.modifieddatetime
                    )}
                  />
                </GridItem>
              </GridContainer>
              {searchResult.categories && (
                <GridContainer spacing={2}>
                  <GridItem xs={12}>
                    <strong>Categories:</strong>{" "}
                    <Highlighter
                      highlightClassName="highlighted"
                      searchWords={[searchString]}
                      autoEscape={true}
                      textToHighlight={searchResult.categories}
                    />
                  </GridItem>
                </GridContainer>
              )}
            </div>
          </CardFooter>
        )}
      </Card>
      {editReturnedSearch &&
        editSearch &&
        returnedSearchParameters.type === "paragraph" && (
          <Dialog
            onClose={() => {
              setEditReturnedSearch(false)
              setSearchEditorHadLoaded(false)
            }}
            aria-labelledby="customized-dialog-title"
            open={editReturnedSearch}
            maxWidth="md"
            fullWidth={true}
          >
            {!searchEditorHadLoaded && (
              <GridContainer spacing={1}>
                <GridItem
                  xs={11}
                  style={{
                    marginTop: "15px",
                    textAlign: "center",
                    fontSize: "1rem",
                    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                    fontWeight: "500",
                    lineHeight: "1.57",
                    letterSpacing: "0.00714em"
                  }}
                >
                  Loading... {titleTag}
                </GridItem>
              </GridContainer>
            )}
            <SearchEditor
              searchParams={returnedSearchParameters}
              hasLoaded={() => setSearchEditorHadLoaded(true)}
              subSearch={(viewKey, narrative, myaction) => {
                setEditSearch(false)
                setEditReturnedSearch(false)
                let cookies = new Cookies()
                cookies.set("editSearchSentenceInPara", true)
                if (!myaction || myaction !== "reload") {
                  editSearchResult(
                    {},
                    "sentence",
                    `${viewKey}`,
                    `${narrative.id}`,
                    `sentenceid-${viewKey}`,
                    narrative
                  )
                }
              }}
              savedEntry={(type, id, content) => {
                props.savedEntry(type, id, content)
                setEditSearch(false)
                setEditReturnedSearch(false)
              }}
              cancelComponent={() => {
                setEditSearch(false)
                setEditReturnedSearch(false)
              }}
            />
          </Dialog>
        )}
      {editReturnedSearch &&
        editSearch &&
        returnedSearchParameters.type !== "paragraph" && (
          <Dialog
            onClose={() => {
              setEditReturnedSearch(false)
              setSearchEditorHadLoaded(false)
            }}
            aria-labelledby="customized-dialog-title"
            open={editReturnedSearch}
            maxWidth="md"
            fullWidth={true}
          >
            {!searchEditorHadLoaded && (
              <GridContainer spacing={1}>
                <GridItem
                  xs={11}
                  style={{
                    marginTop: "15px",
                    textAlign: "center",
                    fontSize: "1rem",
                    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                    fontWeight: "500",
                    lineHeight: "1.57",
                    letterSpacing: "0.00714em"
                  }}
                >
                  Loading... {titleTag}
                </GridItem>
              </GridContainer>
            )}
            <SearchEditor
              searchParams={returnedSearchParameters}
              hasLoaded={() => setSearchEditorHadLoaded(true)}
              subSearch={(viewKey, narrative, myaction) => {
                setEditSearch(false)
                setEditReturnedSearch(false)
                let cookies = new Cookies()
                cookies.set("editSearchSentenceInPara", true)
                if (!myaction || myaction !== "reload") {
                  editSearchResult(
                    {},
                    "sentence",
                    `${viewKey}`,
                    `${narrative.id}`,
                    `sentenceid-${viewKey}`,
                    narrative
                  )
                }
              }}
              savedEntry={(type, id, content) => {
                props.savedEntry(type, id, content)
                setEditSearch(false)
                setEditReturnedSearch(false)
              }}
              cancelComponent={() => {
                setEditSearch(false)
                setEditReturnedSearch(false)
              }}
            />
          </Dialog>
        )}
    </div>
  )
}
NarrativeSearchBlock.propTypes = {
  savedEntry: PropTypes.func
}
export default NarrativeSearchBlock
