import React, { useState } from "react"
import PropTypes from "prop-types"

import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"

const ParagraphType = ({
  value,
  onChange,
  isLibrary,
  primaryTags,
  required,
  classes
}) => {
  const paragraphTypeOptions = primaryTags || [
    ({ name: "Intro", value: "Intro" },
    { name: "Team", value: "Team" },
    { name: "Player", value: "Player" },
    { name: "Match", value: "Match" },
    { name: "League", value: "League" },
    { name: "Non-Content/Meta Content", value: "Non Content" },
    { name: "Misc", value: "Misc" })
  ]
  const [valueObj, setValue] = useState(
    paragraphTypeOptions.find(option => option.value === value) || {
      name: "",
      value: ""
    }
  )
  return (
    <Autocomplete
      fullWidth
      id="paragraphType"
      options={paragraphTypeOptions}
      getOptionLabel={option => option.name}
      classes={{ option: classes?.option }}
      value={valueObj || ""}
      onChange={(e, newValue) => {
        onChange(newValue?.value)
        setValue(
          newValue === null
            ? {
                name: "",
                value: ""
              }
            : newValue
        )
      }}
      style={{ margin: "1rem 0" }}
      renderInput={params => (
        <TextField
          {...params}
          label="Primary Tag (Structure)"
          required={required}
        />
      )}
      disabled={!isLibrary}
    />
  )
}

ParagraphType.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  isLibrary: PropTypes.bool,
  primaryTags: PropTypes.array
}

export default ParagraphType
