// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react"
import { Route, Switch } from "react-router-dom"
import indexRoutes from "./routes"
import { Cookies } from "tools/storage"

import { useStore } from "contexts/rootContext"
import { useObserver } from "mobx-react"

let cookies = new Cookies()
cookies.delete("waitingForAccessToken")

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    announcement: store.appConfigurationStore.announcement,
    announcementMessage: store.appConfigurationStore.announcementMessage,
    getAnnouncementMessage: store.appConfigurationStore.getAnnouncementMessage,
    accountPermissions: store.accountStore.accountPermissions
  }))
}

export function App() {
  const { announcement, getAnnouncementMessage } = useStoreData()

  useEffect(() => {
    window.process = {
      ...window.process
    }
  }, [])

  useEffect(() => {
    if (announcement === true) {
      getAnnouncementMessage()
    } else if (announcement === false) {
      cookies.delete("appAnnouncement")
    }
  }, [announcement])

  const setEnvBanner = () => {
    let colorCode = ""

    switch (window._env_.REACT_APP_ENV) {
      case "dev":
        colorCode = "#f56754"
        break
      case "lrb":
        colorCode = "#f56754"
        break
      case "qa":
        colorCode = "#F4D786"
        break
      case "stage":
        colorCode = "#B1C4A8"
        break
      default:
        return
    }
    return (
      <div
        style={{
          backgroundColor: colorCode,
          position: "absolute",
          width: "100vw",
          zIndex: 1050
        }}
      >
        <h1
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "1rem",
            textShadow: "white 1px 1px",
            margin: 0
          }}
        >
          {window._env_.REACT_APP_ENV.toUpperCase()}
        </h1>
      </div>
    )
  }

  return (
    <>
      {window._env_.REACT_APP_ENV !== "prod" && setEnvBanner()}
      <Switch>
        <Route path="/healthz">
          <h3>AppHealthy!</h3>
        </Route>
        {indexRoutes.map((prop, key) => (
          <Route path={prop.path} component={prop.component} key={key} />
        ))}
      </Switch>
    </>
  )
}
