import adminDAO from "daos/adminDAO"
import { decorate, observable, action } from "mobx"
import AccountStore from "./AccountStore"
import { List, fromJS } from "immutable"
import { Cookies } from "tools/storage"
import Helpers from "tools/Helpers"

export default class SessionStore {
  authenticated = false

  constructor(rootStore) {
    this.rootStore = rootStore
    this.dehydrate()
  }

  /**
   * Actions
   */
  getAuthenticated = async body => {
    const accountStore = new AccountStore(this.rootStore)
    try {
      accountStore.setLoading(true)
      const response = await adminDAO.login(body)
      if (response.status === 200) {
        const data = await response.json()

        this.setAuthenticated(true)
        localStorage.setItem("accessToken", data.content.accessToken)
        localStorage.setItem("refreshToken", data.content.refreshToken)

        const permissions = fromJS(data.permissions || {})
        const organizations = fromJS(data.organizations || [])
        let properties = organizations
          .flatMap(org => org.get("properties") || List())
          .toJS()

        accountStore.setAccountPermissions(permissions)
        accountStore.setAccountOrganizations(organizations)
        accountStore.setAccountProperties(properties)

        // If you need to set a cookie with username or email
        const cookies = new Cookies()
        // get usernameOrEmail somehow (e.g., from state/context)
        const usernameOrEmail = "username@example.com" // Replace with actual data source
        cookies.set("usernameoremail", usernameOrEmail, {
          path: "/",
          expires: Helpers.CookieExpiration.OneWeek
        })

        return data // Or any other value you'd like to return indicating success
      } else {
        throw new Error(`Login failed with status ${response.status}`)
      }
    } catch (error) {
      this.setAuthenticated(false)
      accountStore.dehydrateAccount()
      // Re-throw the error or handle it as needed
      throw error
    } finally {
      accountStore.setLoading(false) // Ensure loading is stopped in any case
    }
  }

  getRefreshToken = async body => {
    const accountStore = new AccountStore(this.rootStore)
    try {
      accountStore.setLoading(true)
      const response = await adminDAO.refresh(
        localStorage.getItem("refreshToken")
      )
      if (response.status === 200) {
        const data = await response.json()

        this.setAuthenticated(true)
        localStorage.setItem("accessToken", data.content.accessToken)
        localStorage.setItem("refreshToken", data.content.refreshToken)

        const permissions = fromJS(data.permissions || {})
        const organizations = fromJS(data.organizations || [])
        let properties = organizations
          .flatMap(org => org.get("properties") || List())
          .toJS()

        accountStore.setAccountPermissions(permissions)
        accountStore.setAccountOrganizations(organizations)
        accountStore.setAccountProperties(properties)

        // If you need to set a cookie with username or email
        const cookies = new Cookies()
        // get usernameOrEmail somehow (e.g., from state/context)
        const usernameOrEmail = "username@example.com" // Replace with actual data source
        cookies.set("usernameoremail", usernameOrEmail, {
          path: "/",
          expires: Helpers.CookieExpiration.OneWeek
        })

        return data // Or any other value you'd like to return indicating success
      } else {
        throw new Error(`Login failed with status ${response.status}`)
      }
    } catch (error) {
      this.setAuthenticated(false)
      accountStore.dehydrateAccount()
      // Re-throw the error or handle it as needed
      throw error
    } finally {
      accountStore.setLoading(false) // Ensure loading is stopped in any case
    }
  }

  setAuthenticated = value => {
    this.authenticated = value
  }

  /**
   * Internal Actions
   */
  dehydrate() {
    this.setAuthenticated(false)
  }
}

decorate(SessionStore, {
  // observables
  authenticated: observable,

  // actions
  getAuthenticated: action.bound,
  setAuthenticated: action.bound,
  getRefreshToken: action.bound
})
