// Functions needed in OfferLinkBlocks amd DisclaimerLinkBLocks for their Context Menus
const useContextMenuHelper = ({
  setContextVariantMenuAnchor,
  setVariantMenuInfo,
  setContextLinkMenuAnchor,
  setContextAddLinkMenuAnchor,
  setLinkMenuInfo,
  setAddLinkMenuInfo,
  setIsExecutingActions,
  fetchVariantById,
  setDialogWarningMessage,
  setSuccessMessage,
  setIsSuccess,
  Cta_v2DAO,
  setActionType,
  setDialogWarningOpen,
  setDelayedAction,
  actionType,
  linkMenuInfo,
  addLinkMenuInfo,
  getLinkDisplayInfo,
  variantMenuInfo,
  queryParams,
  history,
  props,
  _INTENT_ID,
  _LINK_PAGE_NAME
}) => {
  const handleLinkMenuOpen = (event, item) => {
    event.preventDefault()
    event.stopPropagation()
    setContextLinkMenuAnchor(event.currentTarget)
    setLinkMenuInfo(item)
  }

  const handleAddLinkMenuOpen = (event, item) => {
    event.preventDefault()
    event.stopPropagation()
    setContextAddLinkMenuAnchor(event.currentTarget)
    setAddLinkMenuInfo(item)
  }

  const handleVariantMenuOpen = (event, item) => {
    event.preventDefault()
    event.stopPropagation()
    setContextVariantMenuAnchor(event.currentTarget)
    setVariantMenuInfo(item)
  }

  const handleContextMenuLinkClose = () => {
    setContextLinkMenuAnchor(null)
    setLinkMenuInfo(null)
  }

  const handleContextMenuAddLinkClose = () => {
    setContextAddLinkMenuAnchor(null)
    setAddLinkMenuInfo(null)
  }

  const handleContextMenuVariantClose = () => {
    setContextVariantMenuAnchor(null)
    setVariantMenuInfo(null)
  }

  const fireLinkAction = action => {
    const body = {
      actionType: action,
      id: linkMenuInfo?.id,
      variantToPasteId:
        action === "Copy-Paste-Variant" || action === "Cut-Paste-Variant"
          ? variantMenuInfo.id
          : 0
    }
    setIsExecutingActions(true)
    const getActionResults = Cta_v2DAO.postLinkAction(body)
    getActionResults.then(results => {
      showLinkSuccessModal(action, results)
      setIsExecutingActions(false)
    })
  }

  const handleLinkRightClick = action => {
    switch (action) {
      case "New Window":
        setActionType("New Window")
        setDialogWarningOpen(true)
        setDialogWarningMessage(
          `Are you sure you want to open a new window for Link "${
            linkMenuInfo?.id
          } - ${linkMenuInfo.shortNameOffer || linkMenuInfo.linkName}"?`
        )
        break

      case "Archive":
        setDialogWarningOpen(true)
        setDialogWarningMessage(
          `Do you want to archive this Offer Link and all Variants Underneath?`
        )
        setDelayedAction("Archive-Link")
        break

      case "Duplicate":
        setActionType("Duplicate")
        fireLinkAction("Duplicate")
        break

      case "Paste":
        if (actionType === "Copy:Variant:Pending") {
          setActionType("Paste")
          fireLinkAction("Copy-Paste-Variant")
        } else if (actionType === "Cut:Variant:Pending") {
          setActionType("Cut-Paste")
          fireLinkAction("Cut-Paste-Variant")
        }
        break

      default:
    }
    setContextLinkMenuAnchor(null)
  }

  const showVariantSuccessModal = (action, result) => {
    switch (action) {
      case "Archive":
        setSuccessMessage(`You have successfully Archived Variant #${result}`)
        setIsSuccess(true)
        getLinkDisplayInfo(_INTENT_ID)
        break

      case "Duplicate":
        setSuccessMessage(
          `You have successfully Duplicated Variant # ${variantMenuInfo?.id}, new ID = ${result}`
        )
        setIsSuccess(true)
        getLinkDisplayInfo(_INTENT_ID)
        break

      default:
    }
  }

  const fireVariantAction = action => {
    const body = { actionType: action, id: variantMenuInfo?.id }
    setIsExecutingActions(true)
    const getActionResults = Cta_v2DAO.postVariantAction(body)
    getActionResults.then(results => {
      showVariantSuccessModal(action, results)
      setIsExecutingActions(false)
    })
  }

  const handleVariantRightClick = action => {
    switch (action) {
      case "New Window":
        setActionType("New Window")
        setDialogWarningOpen(true)
        setDialogWarningMessage(
          `Are you sure you want to open a new window for Variant "${variantMenuInfo?.id} - ${variantMenuInfo.name}"?`
        )
        break

      case "Copy":
        setActionType("Copy:Variant:Pending")
        break

      case "Cut":
        setActionType("Cut:Variant:Pending")
        break

      case "Archive":
        setDialogWarningOpen(true)
        setDialogWarningMessage(`Do you want to archive this Variant?`)
        setDelayedAction("Archive-Variant")
        break

      case "Duplicate":
        setActionType("Duplicate")
        fireVariantAction("Duplicate")
        break

      default:
    }
    setContextVariantMenuAnchor(null)
  }

  const handleAddLinkClick = () => {
    const newLink = {
      ...addLinkMenuInfo,
      isNewRightClickLink: true
    }
    props.goToOfferLinkForm(newLink)
    setContextAddLinkMenuAnchor(null)
  }

  const handleAddVariantClick = () => {
    if (_INTENT_ID === 1) {
      props.setShowOfferLinkForm(false)
    } else if (_INTENT_ID === 2) {
      props.setShowDisclaimerLinkForm(false)
    }
    if (props.openAddVariantForm) {
      props.cycleAddVariantForm()
      props.addRightClickLinkId(linkMenuInfo?.id)
      props.setShowVariant(false)
    } else {
      props.setShowVariant(false)
      props.addRightClickLinkId(linkMenuInfo?.id)
      props.setOpenAddVariantForm(true)
    }
    setContextLinkMenuAnchor(null)
  }
  const showLinkSuccessModal = (action, result) => {
    switch (action) {
      case "Archive":
        setSuccessMessage(
          `You have successfully Archived Link #${result} and all its variants underneath`
        )
        setIsSuccess(true)
        getLinkDisplayInfo(_INTENT_ID)
        break

      case "Duplicate":
        setSuccessMessage(
          `You have successfully Duplicated Link # ${linkMenuInfo?.id}, new ID = ${result}`
        )
        setIsSuccess(true)
        getLinkDisplayInfo(_INTENT_ID)
        break

      case "Copy-Paste-Variant":
        setSuccessMessage(
          `You successfully Pasted Variant #${variantMenuInfo?.id} to Link #${linkMenuInfo?.id}, new variant ID=${result}`
        )
        setIsSuccess(true)
        getLinkDisplayInfo(_INTENT_ID)
        break

      case "Cut-Paste-Variant":
        setSuccessMessage(
          `You successfully Cut Variant #${variantMenuInfo?.id} to Link #${linkMenuInfo?.id}, it now has a new variant ID=${result}`
        )
        setIsSuccess(true)
        getLinkDisplayInfo(_INTENT_ID)
        break
      default:
    }
  }
  const handleVariantClick = async (variantId, linkId) => {
    linkId = linkId || queryParams["linkid"]
    if (linkId) {
      if (variantId) {
        history.push(
          `/portal/manage-ctas/${_LINK_PAGE_NAME}?linkid=${linkId}&variantid=${variantId}`
        )
        props.cycleAddVariantForm()
      }
    }
    await fetchVariantById(variantId)
  }

  return {
    handleVariantMenuOpen,
    handleContextMenuLinkClose,
    handleContextMenuAddLinkClose,
    handleContextMenuVariantClose,
    handleVariantRightClick,
    handleLinkRightClick,
    handleLinkMenuOpen,
    handleAddLinkMenuOpen,
    handleVariantClick,
    handleAddVariantClick,
    handleAddLinkClick,
    fireLinkAction,
    fireVariantAction
  }
}
export default useContextMenuHelper
