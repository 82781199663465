//import { processEntity } from "tools/LibraryCopyTools"
import useEntityHelper from "tools/LibraryCopyTools/EntityHelper"
import useSourceTargetHelper from "tools/LibraryCopyTools/SourceTargetHelper"
import LibraryToolsConfirmDialogContent from "components/ConfirmDialog/LibraryToolsConfirmDialogContent"
import NarrativeOutlineMoverConfirmDialogContent from "components/ConfirmDialog/NarrativeOutlineMoverConfirmDialogContent"

import CustomDSDialog from "components/CustomDialogs/CustomDSDialog"
import Warning from "@mui/icons-material/Warning"
import Info from "@mui/icons-material/Info"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
  infoHeader: {
    textAlign: "left",
    color: "#inherit"
  },
  addScrollIfNeeded: {
    maxHeight: "300px",
    overflowX: "hidden",
    overflowY: "auto",
    border: "1px solid #FFC107",
    padding: "10px 0 0 0",
    "& li": {
      textAlign: "left"
    }
  }
})

const InterstitialConfirmDialog = ({
  sourceEntities = [],
  sourceNarrative,
  targetNarrative,
  targetEntities = [],
  //selectedSources,
  withAssociatedTriggers,
  type,
  open,
  setOpen,
  onConfirm,
  onCancel,
  missingTriggerIdsList
}) => {
  const { eligibleEntities, eligibleEntitiesCount } = useEntityHelper({
    entities: sourceEntities,
    type
  })

  const classes = useStyles()

  const { allSourceInfo, allTargetInfo } = useSourceTargetHelper({
    eligibleEntities,
    eligibleEntitiesCount,
    sourceNarrative,
    targetNarrative,
    targetEntities,
    entities: sourceEntities,
    type
  })

  let title =
    eligibleEntitiesCount - 1
      ? `Do You Want To Copy These ${eligibleEntitiesCount} Block(s)`
      : `Do You Want To Copy This Block`
  title += withAssociatedTriggers ? ` (with Triggers and Update)?` : "?"

  const handleCloseWarningDialog = () => {
    onCancel(false)
  }

  const processIssueList = list => (
    <div>
      <div className={classes.infoHeader}>
        The following entities DO NOT have TriggerIds set, you must run "Health
        Check" to property set them up for copying
      </div>
      <div className={classes.addScrollIfNeeded}>
        <ol>
          {list?.map((itm, i) => (
            <li key={i}>
              {itm.parentId
                ? `Paragraph:"${itm.parentId}", Sentence:"${itm.blockId}"`
                : `${itm.type}:"${itm.blockId}"`}
            </li>
          ))}
        </ol>
      </div>
    </div>
  )

  return (
    <>
      {missingTriggerIdsList?.length > 0 && withAssociatedTriggers ? (
        <CustomDSDialog
          open={missingTriggerIdsList.length > 0}
          content={processIssueList(missingTriggerIdsList)}
          icon={<Warning style={{ fontSize: "4rem" }} />}
          backgroundColor={"#FFC107"}
          onClose={handleCloseWarningDialog}
        />
      ) : (
        <CustomDSDialog
          className={classes.infoHeader}
          titleContent={title}
          content={
            !type || type !== "OutlineMover" ? (
              <LibraryToolsConfirmDialogContent
                eligibleEntitiesCount={eligibleEntitiesCount}
                withAssociatedTriggers={withAssociatedTriggers}
                sourceNarrative={sourceNarrative}
                targetNarrative={targetNarrative}
                targetEntities={targetEntities}
                allSourceInfo={allSourceInfo}
                allTargetInfo={allTargetInfo}
              />
            ) : (
              <NarrativeOutlineMoverConfirmDialogContent
                eligibleEntitiesCount={eligibleEntitiesCount}
                withAssociatedTriggers={withAssociatedTriggers}
                sourceNarrative={sourceNarrative}
                targetNarrative={targetNarrative}
                targetEntities={targetEntities}
                allSourceInfo={allSourceInfo}
                allTargetInfo={allTargetInfo}
              />
            )
          }
          open={open}
          icon={<Info style={{ fontSize: "4rem" }} />}
          buttonProps={[
            {
              label: "No",
              color: "secondary",
              onClick: () => {
                setOpen(false)
              }
            },
            {
              label: "Yes",
              color: "primary",
              onClick: () => {
                setOpen(false)
                onConfirm()
              }
            }
          ]}
        />
      )}
    </>
  )
}
export default InterstitialConfirmDialog
