import React from "react"
import PropTypes from "prop-types"
import withStyles from "@mui/styles/withStyles"
import Notifications from "@mui/icons-material/Notifications"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardHeader from "components/Card/CardHeader"
import CardIcon from "components/Card/CardIcon"
import DialogTitle from "@mui/material/DialogTitle"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import Check from "@mui/icons-material/Check"
import Table from "components/Table/Table"
import Timeline from "components/Timeline/Timeline"
import Email from "@mui/icons-material/Email"
import Extension from "@mui/icons-material/Extension"
import TimeAgo from "react-timeago"
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle"
import Helpers from "tools/Helpers.js"
import { Link } from "react-router-dom"
import { globalSettings } from "variables/general"
import { observer, inject } from "mobx-react"
import { get, put } from "tools/request"

const useStore = component =>
  inject(({ store }) => ({
    organizationId: store.organizationStore.organizationId,
    organization: store.organizationStore.organization,
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization,
    account: store.accountStore.account
  }))(observer(component))

class AccountNotifications extends React.Component {
  constructor(props) {
    super(props)

    // we use this to make the card to appear after the page has been rendered
    this.state = {
      loading: true,
      organizationId: this.props.organizationId,
      isEmailCheckBoxes: [],
      isSlackCheckBoxes: [],
      isSlackAtMentionMeCheckBoxes: [],
      isInPortalCheckBoxes: [],
      cardAnimaton: "cardHidden",
      isFailedDialogOpen: false,
      accountNotificationsUpdateFailureReason:
        "Unable to change your account details.",
      orgSpecificNotificationHistoryItems: [],
      orgAgnosticNotificationHistoryItems: [],
      slackUsername: "",
      notificationActivities: []
    }
  }

  componentDidMount() {
    this.props.setShowChangeCurrentOrganization(true)
    this.loadData()
  }

  componentDidUpdate() {
    if (
      this.props.organizationId !== null &&
      this.props.account.id !== null &&
      this.state.organizationId !== this.props.organizationId
    ) {
      this.setState({ organizationId: this.props.organizationId })
      this.loadData()
    }
  }

  loadData() {
    if (this.props.organizationId !== null && this.props.account.id !== null) {
      // The following method will load the org. agnostic settings as well.
      this.loadAccountNotificationSettings(
        this.props.organizationId,
        this.props.account.id
      )
      this.loadAccountNotificationHistory(
        this.props.organizationId,
        this.props.account.id
      )
      this.loadAccountNotificationHistory(-1, this.props.account.id)
      this.loadNotificationActivities()
    }
  }

  loadAccountNotificationSettings(organizationId = -1, accountId) {
    if (accountId == null) {
      return
    }
    get(
      `${globalSettings.apiBaseUrl}/api/notification/accountnotificationsettings?organizationId=${organizationId}&accountId=${accountId}`
    )
      .then(Response => Response.json())
      .then(body => {
        let isEmailCheckBoxesArray = body.content.isEmailNotificationActivityIds
        let isInPortalCheckBoxesArray =
          body.content.isInPortalNotificationActivityIds
        let isSlackCheckBoxesArray = body.content.isSlackNotificationActivityIds
        let isSlackAtMentionMeCheckBoxesArray =
          body.content.isSlackAtMentionMeNotificationActivityIds
        // Now load the organization agnostic settings
        get(
          `${globalSettings.apiBaseUrl}/api/notification/accountnotificationsettings?organizationId=-1&accountId=${accountId}`
        )
          .then(Response => Response.json())
          .then(body => {
            this.setState({
              isEmailCheckBoxes: isEmailCheckBoxesArray
                .concat(body.content.isEmailNotificationActivityIds)
                .filter((value, index, self) => self.indexOf(value) === index),
              isInPortalCheckBoxes: isInPortalCheckBoxesArray
                .concat(body.content.isInPortalNotificationActivityIds)
                .filter((value, index, self) => self.indexOf(value) === index),
              isSlackCheckBoxes: isSlackCheckBoxesArray
                .concat(body.content.isSlackNotificationActivityIds)
                .filter((value, index, self) => self.indexOf(value) === index),
              isSlackAtMentionMeCheckBoxes: isSlackAtMentionMeCheckBoxesArray
                .concat(body.content.isSlackAtMentionMeNotificationActivityIds)
                .filter((value, index, self) => self.indexOf(value) === index)
            })
          })
      })
  }

  loadAccountNotificationHistory(organizationId = -1, accountId) {
    if (organizationId === null || accountId === null) {
      return
    }
    get(
      `${globalSettings.apiBaseUrl}/api/notification/notificationhistory?organizationId=${organizationId}&accountId=${accountId}`
    )
      .then(Response => Response.json())
      .then(body => {
        let notifications = body.content.notifications
        let detailedNotifications = []
        notifications.map(function (item) {
          switch (item.notificationType) {
            case 1: // Email
              detailedNotifications.push({
                title: `Email Notification`,
                titleColor: "success",
                body: (
                  <div>
                    <span>Subject: {item.subject}</span>
                    <div dangerouslySetInnerHTML={{ __html: item.bodyHTML }} />
                  </div>
                ),
                footerTitle: (
                  <TimeAgo
                    date={item.sentDateTime}
                    title={Helpers.prettyDateTimeinPacificTimeZone(
                      item.sentDateTime
                    )}
                  />
                ),
                badgeIcon: Email,
                inverted: true,
                badgeColor: "success"
              })
              break
            case 2: // Slack
              detailedNotifications.push({
                title: "Slack Notification",
                titleColor: "danger",
                body: item.message,
                footerTitle: (
                  <TimeAgo
                    date={item.sentDateTime}
                    title={Helpers.prettyDateTimeinPacificTimeZone(
                      item.sentDateTime
                    )}
                  />
                ),
                badgeIcon: Extension,
                inverted: true,
                badgeColor: "danger"
              })
              break
          }
        })
        if (organizationId === -1) {
          this.setState({
            orgAgnosticNotificationHistoryItems: detailedNotifications
          })
        } else {
          this.setState({
            orgSpecificNotificationHistoryItems: detailedNotifications
          })
        }
        return detailedNotifications
      })
  }

  updateAccountNotificationActivityIsEmailSetting(
    accountId,
    organizationId,
    notificationActivityId,
    isEmail
  ) {
    let accountNofificationActivityIsEmailSetting = {}

    accountNofificationActivityIsEmailSetting.AccountId = accountId
    accountNofificationActivityIsEmailSetting.OrganizationId = organizationId
    accountNofificationActivityIsEmailSetting.NotificationActivityId =
      notificationActivityId
    accountNofificationActivityIsEmailSetting.IsEmail = isEmail
    put(
      `${globalSettings.apiBaseUrl}/api/notification/accountnotificationactivityisemailsetting`,
      null,
      accountNofificationActivityIsEmailSetting
    )
      .then(response => response.json())
      .then(data => {
        if (data.responseCode !== 1000) {
          this.setState({ isFailedDialogOpen: true })
        }
      })
      .catch(() => {
        this.setState({
          AccountNotificationsUpdateFailureReason:
            "Unable to change account notification settings."
        })
        this.setState({ isFailedDialogOpen: true })
      })
  }

  updateAccountNotificationActivityIsInPortalSetting(
    accountId,
    organizationId,
    notificationActivityId,
    isInPortal
  ) {
    let accountNotificationActivityIsInPortalSetting = {}

    accountNotificationActivityIsInPortalSetting.AccountId = accountId
    accountNotificationActivityIsInPortalSetting.OrganizationId = organizationId
    accountNotificationActivityIsInPortalSetting.NotificationActivityId =
      notificationActivityId
    accountNotificationActivityIsInPortalSetting.isInPortal = isInPortal
    put(
      `${globalSettings.apiBaseUrl}/api/notification/accountnotificationactivityisinportalsetting`,
      null,
      accountNotificationActivityIsInPortalSetting
    )
      .then(response => response.json())
      .then(data => {
        if (data.responseCode !== 1000) {
          this.setState({ isFailedDialogOpen: true })
        }
      })
      .catch(() => {
        this.setState({
          AccountNotificationsUpdateFailureReason:
            "Unable to change account notification settings."
        })
        this.setState({ isFailedDialogOpen: true })
      })
  }

  updateAccountNotificationActivityIsSlackSetting(
    accountId,
    organizationId,
    notificationActivityId,
    isSlack
  ) {
    let accountNotificationActivityIsSlackSetting = {}

    accountNotificationActivityIsSlackSetting.AccountId = accountId
    accountNotificationActivityIsSlackSetting.OrganizationId = organizationId
    accountNotificationActivityIsSlackSetting.NotificationActivityId =
      notificationActivityId
    accountNotificationActivityIsSlackSetting.isSlack = isSlack
    put(
      `${globalSettings.apiBaseUrl}/api/notification/accountnotificationactivityisslacksetting`,
      null,
      accountNotificationActivityIsSlackSetting
    )
      .then(response => response.json())
      .then(data => {
        if (data.responseCode !== 1000) {
          this.setState({ isFailedDialogOpen: true })
        }
      })
      .catch(() => {
        this.setState({
          AccountNotificationsUpdateFailureReason:
            "Unable to change account notification settings."
        })
        this.setState({ isFailedDialogOpen: true })
      })
  }

  updateAccountNotificationActivityIsSlackAtMentionMeSetting(
    accountId,
    organizationId,
    notificationActivityId,
    isSlackAtMentionMe
  ) {
    let accountNotificationActivityIsSlackAtMentionMeSetting = {}

    accountNotificationActivityIsSlackAtMentionMeSetting.AccountId = accountId
    accountNotificationActivityIsSlackAtMentionMeSetting.OrganizationId =
      organizationId
    accountNotificationActivityIsSlackAtMentionMeSetting.NotificationActivityId =
      notificationActivityId
    accountNotificationActivityIsSlackAtMentionMeSetting.isSlackAtMentionMe =
      isSlackAtMentionMe
    put(
      `${globalSettings.apiBaseUrl}/api/notification/accountnotificationactivityisslackatmentionmesetting`,
      null,
      accountNotificationActivityIsSlackAtMentionMeSetting
    )
      .then(response => response.json())
      .then(data => {
        if (data.responseCode !== 1000) {
          this.setState({ isFailedDialogOpen: true })
        }
      })
      .catch(() => {
        this.setState({
          AccountNotificationsUpdateFailureReason:
            "Unable to change account notification settings."
        })
        this.setState({ isFailedDialogOpen: true })
      })
  }

  handleDialogClose = () => {
    this.setState({ isFailedDialogOpen: false })
    this.setState({ isSuccessDialogOpen: false })
  }

  onIsEmailToggle = value => {
    const { isEmailCheckBoxes } = this.state
    const currentIndex = isEmailCheckBoxes.indexOf(value)
    const newChecked = [...isEmailCheckBoxes]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    this.setState({
      isEmailCheckBoxes: newChecked
    })
    let isEmail = false
    if (newChecked.includes(value)) {
      isEmail = true
    }
    // Determine if this is a organization agnositc setting
    let organizationId = this.props.organizationId
    if (
      this.state.notificationActivities.filter(o => o.id === value)[0]
        .isOrganizationAgnostic
    ) {
      organizationId = -1 // Agnostic Organization Id
    }
    let notificationActivityId = value
    this.updateAccountNotificationActivityIsEmailSetting(
      this.props.account.id,
      organizationId,
      notificationActivityId,
      isEmail
    )
  }

  onIsSlackToggle = value => {
    const { isSlackCheckBoxes } = this.state
    const currentIndex = isSlackCheckBoxes.indexOf(value)
    const newChecked = [...isSlackCheckBoxes]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    this.setState({
      isSlackCheckBoxes: newChecked
    })
    let isSlack = false
    if (newChecked.includes(value)) {
      isSlack = true
    }
    // Determine if this is a organization agnositc setting
    let organizationId = this.props.organizationId
    if (
      this.state.notificationActivities.filter(o => o.id === value)[0]
        .isOrganizationAgnostic
    ) {
      organizationId = -1 // Agnostic Organization Id
    }
    let notificationActivityId = value
    this.updateAccountNotificationActivityIsSlackSetting(
      this.props.account.id,
      organizationId,
      notificationActivityId,
      isSlack
    )
  }

  onIsInPortalToggle = value => {
    const { isInPortalCheckBoxes } = this.state
    const currentIndex = isInPortalCheckBoxes.indexOf(value)
    const newChecked = [...isInPortalCheckBoxes]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    this.setState({
      isInPortalCheckBoxes: newChecked
    })
    let isInPortal = false
    if (newChecked.includes(value)) {
      isInPortal = true
    }
    // Determine if this is a organization agnositc setting
    let organizationId = this.props.organizationId
    if (
      this.state.notificationActivities.filter(o => o.id === value)[0]
        .isOrganizationAgnostic
    ) {
      organizationId = -1 // Agnostic Organization Id
    }
    let notificationActivityId = value
    this.updateAccountNotificationActivityIsInPortalSetting(
      this.props.account.id,
      organizationId,
      notificationActivityId,
      isInPortal
    )
  }

  onIsSlackAtMentionMeToggle = value => {
    const { isSlackAtMentionMeCheckBoxes } = this.state
    const currentIndex = isSlackAtMentionMeCheckBoxes.indexOf(value)
    const newChecked = [...isSlackAtMentionMeCheckBoxes]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    this.setState({
      isSlackAtMentionMeCheckBoxes: newChecked
    })
    let isSlackAtMentionMe = false
    if (newChecked.includes(value)) {
      isSlackAtMentionMe = true
    }
    // Determine if this is a organization agnositc setting
    let organizationId = this.props.organizationId
    if (
      this.state.notificationActivities.filter(o => o.id === value)[0]
        .isOrganizationAgnostic
    ) {
      organizationId = -1 // Agnostic Organization Id
    }
    let notificationActivityId = value
    this.updateAccountNotificationActivityIsSlackAtMentionMeSetting(
      this.props.account.id,
      organizationId,
      notificationActivityId,
      isSlackAtMentionMe
    )
  }

  loadNotificationActivities = () => {
    get(`${globalSettings.apiBaseUrl}/api/notification/activities`)
      .then(Response => Response.json())
      .then(body => {
        this.setState({
          notificationActivities: body.content,
          loading: false
        })
        return body.content
      })
  }

  buildNotificationActivityRowsRWS = (isOrganizationAgnostic, isMobile) => {
    const { classes } = this.props
    return this.state.notificationActivities
      .filter(o => o.isOrganizationAgnostic === isOrganizationAgnostic)
      .sort((a, b) => {
        // Use toUpperCase() to ignore character casing
        const A = a.description.toUpperCase()
        const B = b.description.toUpperCase()
        let comparison = 0
        if (A > B) {
          comparison = 1
        } else if (A < B) {
          comparison = -1
        }
        return comparison
      })
      .map(item => {
        let arr = [
          <span>{item.description}</span>,
          <></>,
          <Checkbox
            checked={this.state.isInPortalCheckBoxes.includes(item.id)}
            className={classes.positionAbsolute}
            tabIndex={-1}
            onClick={() => this.onIsInPortalToggle(item.id)}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }}
          />,
          <Checkbox
            checked={this.state.isSlackCheckBoxes.includes(item.id)}
            className={classes.positionAbsolute}
            tabIndex={-1}
            onClick={() => this.onIsSlackToggle(item.id)}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }}
          />,
          <FormControlLabel
            control={
              <Checkbox
                disabled={
                  !this.state.isSlackCheckBoxes.includes(item.id) ||
                  !this.props.account.slackUsername
                }
                checked={this.state.isSlackAtMentionMeCheckBoxes.includes(
                  item.id
                )}
                className={classes.positionAbsolute}
                tabIndex={-1}
                onClick={() => this.onIsSlackAtMentionMeToggle(item.id)}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot
                }}
              />
            }
            classes={{
              label: classes.label,
              disabled: classes.disabledCheckboxAndRadio
            }}
            label={
              this.props.account.slackUsername ? (
                this.props.account.slackUsername
              ) : (
                <Link
                  to="/portal/accountprofile"
                  className={`${classes.itemLink} ${classes.userCollapseLinks}`}
                >
                  Your Slack username is not defined.
                </Link>
              )
            }
          />
        ]
        if (isMobile) {
          //For Responsive Web Design, if we are in a small screen, remove the padding row for better visibility on smartphones
          arr.splice(1, 1)
        }
        return arr
      })
  }

  render() {
    if (this.props.account === null || this.props.organizationId === null) {
      return <div />
    }
    if (this.state.loading) {
      return null
    }
    const { classes } = this.props

    return (
      <div>
        <Dialog
          onClose={this.handleDialogClose}
          open={this.state.isFailedDialogOpen}
          aria-labelledby="simple-dialog-title"
        >
          <DialogTitle id="simple-dialog-title">{"Whoops!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {this.state.accountNotificationsUpdateFailureReason}
            </DialogContentText>
          </DialogContent>
          <DialogActions />
        </Dialog>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className={classes.rwsGridPadding}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Notifications />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  {this.props.organization.name} Organization Specific
                  Notifications
                </h4>
              </CardHeader>
              <CardBody>
                <div className={classes.acountNotificationsStandard}>
                  <div class="standard">
                    <Table
                      striped
                      tableHead={[
                        "Activity",
                        "",
                        "In Portal?",
                        "Slack Message?",
                        "Slack @Mention Me?"
                      ]}
                      tableData={this.buildNotificationActivityRowsRWS(false)}
                      customCellClasses={[
                        classes.center,
                        classes.right,
                        classes.right
                      ]}
                      customClassesForCells={[0, 5, 6]}
                      customHeadCellClasses={[
                        classes.center,
                        classes.right,
                        classes.right
                      ]}
                      customHeadClassesForCells={[0, 5, 6]}
                    />
                  </div>
                </div>
                <div className={classes.acountNotificationsStandard}>
                  <div class="smartphone">
                    <Table
                      striped
                      tableHead={[
                        "Activity",
                        "In Portal?",
                        "Slack Msg?",
                        "Slack @Me?"
                      ]}
                      tableData={this.buildNotificationActivityRowsRWS(
                        false,
                        true
                      )}
                      customCellClasses={[
                        classes.center,
                        classes.right,
                        classes.right
                      ]}
                      customClassesForCells={[0, 5, 6]}
                      customHeadCellClasses={[
                        classes.center,
                        classes.right,
                        classes.right
                      ]}
                      customHeadClassesForCells={[0, 5, 6]}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className={classes.rwsGridPadding}>
            <Card>
              <CardHeader color="primary" icon>
                <h4 className={classes.cardIconTitle}>
                  {this.props.organization.name} Organization Specific
                  Notification History (most recent 100)
                </h4>
              </CardHeader>
              <CardBody>
                <GridItem style={{ height: "400px", overflow: "auto" }}>
                  {this.state.orgSpecificNotificationHistoryItems.length ===
                    0 && <p>You haven't received any notifications yet.</p>}
                  <Timeline
                    simple
                    stories={this.state.orgSpecificNotificationHistoryItems}
                  />
                </GridItem>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className={classes.rwsGridPadding}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Notifications />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Organization Agnostic Notifications
                </h4>
              </CardHeader>
              <CardBody>
                <div className={classes.acountNotificationsStandard}>
                  <div class="standard">
                    <Table
                      striped
                      tableHead={[
                        "Activity",
                        "",
                        "In Portal?",
                        "Slack Message?",
                        "Slack @Mention Me?"
                      ]}
                      tableData={this.buildNotificationActivityRowsRWS(true)}
                      customCellClasses={[
                        classes.center,
                        classes.right,
                        classes.right
                      ]}
                      customClassesForCells={[0, 5, 6]}
                      customHeadCellClasses={[
                        classes.center,
                        classes.right,
                        classes.right
                      ]}
                      customHeadClassesForCells={[0, 5, 6]}
                    />
                  </div>
                </div>
                <div className={classes.acountNotificationsStandard}>
                  <div class="smartphone">
                    <Table
                      striped
                      tableHead={[
                        "Activity",
                        "In Portal?",
                        "Slack Msg?",
                        "Slack @Me?"
                      ]}
                      tableData={this.buildNotificationActivityRowsRWS(
                        true,
                        true
                      )}
                      customCellClasses={[
                        classes.center,
                        classes.right,
                        classes.right
                      ]}
                      customClassesForCells={[0, 5, 6]}
                      customHeadCellClasses={[
                        classes.center,
                        classes.right,
                        classes.right
                      ]}
                      customHeadClassesForCells={[0, 5, 6]}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className={classes.rwsGridPadding}>
            <Card>
              <CardHeader color="primary" icon>
                <h4 className={classes.cardIconTitle}>
                  Organization Agnostic Notification History (most recent 100)
                </h4>
              </CardHeader>
              <CardBody>
                <GridItem style={{ height: "400px", overflow: "auto" }}>
                  {this.state.orgAgnosticNotificationHistoryItems.length ===
                    0 && <p>You haven't received any notifications yet.</p>}
                  <Timeline
                    simple
                    stories={this.state.orgAgnosticNotificationHistoryItems}
                  />
                </GridItem>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

AccountNotifications.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(regularFormsStyle)(useStore(AccountNotifications))
