import React from "react"
import withStyles from "@mui/styles/withStyles"
import Dialog from "@mui/material/Dialog"
import MuiDialogTitle from "@mui/material/DialogTitle"
import MuiDialogContent from "@mui/material/DialogContent"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import Typography from "@mui/material/Typography"
import Info from "@mui/icons-material/Info"
import { makeStyles } from "@mui/styles"
import Button from "components/CustomButtons/Button"
import { globalSettings } from "variables/general.jsx"
import InfoIcon from "@mui/icons-material/Info"
import DialogActions from "@mui/material/DialogActions"

const styles = theme => ({
  root: {
    margin: 2,
    padding: theme.spacing(2),
    textAlign: "left",
    color: "#fff"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[800]
  }
})

const useStyles = makeStyles({
  paragraph: {
    marginLeft: "9px",
    fontWeight: 400,
    lineHeight: 1.75,
    marginBottom: "9px"
  },
  infoButton: {
    padding: 0
  },
  infoHeaderIcon: {
    textAlign: "center",
    color: "#fff",
    backgroundColor: "#4d9ab5"
    // fontSize: "50px"
  },
  dialogIcon: {
    fontSize: "4rem"
  }
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent)

export default function WebhookInfoDialog() {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const classes = useStyles()

  return (
    <div style={{ paddingTop: "5px", paddingLeft: "5px" }}>
      <IconButton
        className={classes.infoButton}
        onClick={handleClickOpen}
        size="large"
      >
        <Info style={{ color: "#4d9ab5", fontSize: "1.2rem" }} />
      </IconButton>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          className={classes.infoHeaderIcon}
        >
          <InfoIcon className={classes.dialogIcon} />
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="h6">Feed Selection Required</Typography>
          <Typography className={classes.paragraph} gutterBottom>
            In order to see entries for this Webhook in the Portal and schedule
            a render job, please also select a feed for this webhook job. If
            none is available, please add or create one in Feed Manager
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            href={`${globalSettings.portalBaseUrl}/portal/feed-manager`}
            target="_blank"
            color="primary"
          >
            Feed Manager
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
