import React, { useState } from "react"
import { fromJS } from "immutable"

import PropTypes from "prop-types"
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Popper,
  TextField
} from "@mui/material"
import { Autocomplete } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"

import NarrativePreviewDAO from "daos/narrativePreviewDAO"

import { NarrativeContext } from "contexts/narrative-context"
import { useContext } from "react"

import { Form, Input, Body, Select, Button } from "components/Form"

import Table from "components/Table/AdvancedTable"
import {
  SentenceTypeInput,
  StatusTypeInput,
  RenderTypeInput,
  OwnerInput,
  LibrarySentencePositionCell,
  SwitchInput,
  BasicCell,
  SaturationChipInput,
  SelectRowCell,
  EditableCell,
  LinkSentence
} from "components/Table/components/CustomCells"
import {
  SelectColumnFilter,
  StatusTypeFilter,
  OwnerFilter,
  NarrativeFilter,
  SelectRowsHeaderActions,
  DefaultColumnFilter
} from "components/Table/components/CustomFilters"

import { Field } from "formik"
import { Cookies } from "tools/storage"

import { removeJustSavedRowFromFilter } from "tools/tableUtil"
import ContentSaturationSentenceModal from "components/ContentSaturation/SentenceModal"
import NarrativeDAO from "daos/narrativeDAO"
import useTableChangedRows from "hooks/table/useTableChangedRows"

const UseStyles = makeStyles(() => ({
  root: {
    "& .MuiInput-underline:before": {
      borderBottom: "none"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none"
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none"
    }
  },
  inputRoot: {
    fontWeight: 400,
    fontSize: "13px",
    height: "32px",
    paddingLeft: "5px"
  },
  acOption: {
    fontWeight: 400,
    fontSize: "13px"
  }
}))

const CustomPopper = function (props) {
  return <Popper {...props} style={{ width: "auto" }} placement="bottom-end" />
}

export default function SentenceTable(props) {
  const { paragraph, narrative } = props
  const {
    triggers: allAvailableTriggers,
    paragraphs,
    libraryParagraphs
  } = narrative
  const { sentences } = paragraph
  const [originalData, setOriginalData] = useState(sentences)
  const [data, setData] = useState(sentences)
  const { model, modelType, modelTypeId, contentId, contentType, itemIndex } =
    useContext(NarrativeContext)

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    const { sentences } = paragraph
    setOriginalData(sentences)
    setData(sentences)
  }, [paragraph])

  const editValues = {
    name: undefined,
    sentencetype: undefined,
    rendertype: undefined,
    blockattributes: undefined,
    template: undefined,
    starter: undefined,
    position: undefined,
    triggertype: undefined,
    triggerweight: undefined,
    comments: undefined,
    status_id: undefined,
    requireddata: undefined,
    isarchived: undefined
  }

  const [bulkEditValues] = useState(editValues)
  const [, setSelectedRows] = useState([])

  const {
    changedRowIndexes,
    addChangedRow,
    removeChangedRow,
    addNumberNewChangedRows
  } = useTableChangedRows()

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.
  const skipResetRef = React.useRef(false)

  const setAlertMessage = (id, action, type, code) => {
    let str = ""
    const newBulkEventSchedule = {
      [id]: action,
      alertMessage: str
    }
    if (type) {
      newBulkEventSchedule.type = type
    }
    if (code) {
      newBulkEventSchedule.type = code
    }
    props.bulkAlertMessage(newBulkEventSchedule)
    props.isAlertActive && props.isAlertActive()
  }

  const testSentence = row => {
    let sentence = row.original
    let { starter: templateString } = sentence
    if (!templateString) {
      templateString = sentence.template //So we can also test existing sentences
      if (!templateString) {
        return
      }
    }
    //First update the state of this particular row
    sentence.isTesting = true
    sentence.testComplete = false
    if (data[row.index]) {
      data[row.index] = sentence
    } else {
      data.push(sentence)
    }
    setData([...data])
    const previewRequest = {
      templateString,
      modelType,
      modelTypeId,
      narrativeId: row.getValue("narrativeId") || narrative.id,
      contentId,
      contentType,
      dataModelText: !contentId ? model : "",
      itemIndex,
      useCache: false,
      excludeDependencies: false,
      sentenceId: sentence.id,
      skipSentences: true
    }
    const getSentencePreview =
      NarrativePreviewDAO.evaluateSentence(previewRequest)
    getSentencePreview
      .then(evaluatedSentence => {
        sentence.isTesting = false
        sentence.testComplete = true
        sentence.isTrue = !evaluatedSentence.match(/:\s{0,}error\s{0,}:/)
        sentence.hasErrors = !!evaluatedSentence.match(/:\s{0,}error\s{0,}:/)
        sentence.renderString = sentence.renderType
          ? `<${sentence.renderType}>${evaluatedSentence}</${sentence.renderType}>`
          : evaluatedSentence
        row.original = {
          ...sentence
        }
        data[row.index] = row.original
        let copyId = sentence.CopyId

        let len = data.length
        for (let i = 0; i < len; i++) {
          if (data[i].copyId && data[i].copyId === copyId) {
            data[i].isTesting = false
            data[i].testComplete = true
            data[i].isTrue = !evaluatedSentence.match(/:\s{0,}error\s{0,}:/)
            data[i].hasErrors = !!evaluatedSentence.match(/:\s{0,}error\s{0,}:/)
          }
        }
        setData([...data])
      })
      .catch(error => {
        sentence.isTesting = false
        sentence.testComplete = true
        sentence.isTrue = false
        sentence.hasErrors = true
        sentence.renderString = error
        row.original = {
          ...sentence
        }
        data[row.index] = row.original
        let copyId = sentence.CopyId

        let len = data.length
        for (let i = 0; i < len; i++) {
          if (data[i].copyId && data[i].copyId === copyId) {
            data[i].isTesting = false
            data[i].testComplete = true
            data[i].isTrue = false
            data[i].hasErrors = true
          }
        }
        setData([...data])
      })
  }

  const bulkTest = ({ selectedFlatRows }) => {
    //Edit later
    selectedFlatRows.forEach(row => {
      testSentence(row)
    })
  }

  const saveSentence = (row, isBulk = false) => {
    let sentenceObj = {}
    row.getAllCells().forEach(itm => {
      //Read the columns and only use the fields you can filter as appropriate
      const columnId = itm.column?.id
      if (columnId && itm.column.getCanFilter()) {
        if (row.original[columnId] !== undefined) {
          sentenceObj[columnId] = row.original[columnId]
        }
      }
    })
    sentenceObj.id = row.original.id || 0

    if (!sentenceObj.sentenceType) {
      sentenceObj.sentenceType = ""
    }
    setAlertMessage(
      sentenceObj.id ? sentenceObj.id : Number(999999990000 + row.index),
      "saving",
      isBulk ? "bulk" : "notBulk"
    )

    NarrativeDAO.postSentence(sentenceObj)
      .then(sentenceId => {
        setAlertMessage(
          sentenceObj.id ? sentenceObj.id : Number(999999990000 + row.index),
          "saved",
          isBulk ? "bulk" : "notBulk",
          sentenceId
        )
        skipResetRef.current = true
        sentenceObj.id = sentenceId
        row.original = sentenceObj
        row.toggleSelected(false)
        data[row.index] = row.original
        if (originalData[row.index]) {
          originalData[row.index] = row.original
        } else {
          originalData.push(row.original)
        }
        setOriginalData([...originalData])
        setData([...data])
        paragraph.sentences = [...data]
        props.updateNarrativeParagraphs &&
          props.updateNarrativeParagraphs(paragraph)
        removeChangedRow(row.index)
      })
      .catch(err => {
        setAlertMessage(
          sentenceObj.id ? sentenceObj.id : Number(999999990000 + row.index),
          "failed",
          isBulk ? "bulk" : "notBulk",
          Response.status
        )
        cancelChanges(row)
        row.toggleRowSelected(false)
        console.error(
          `Error on ${isBulk ? "bulk " : ""}PUT to /api/sentence`,
          err
        )
      })
  }

  const updateSelected = (
    bulkEditValues,
    selectedFlatRows,
    toggleBulkEditor
  ) => {
    const rowsCopy = [...selectedFlatRows]
    const keys = Object.keys(bulkEditValues)

    rowsCopy.forEach(row => {
      let rowChanged = false
      keys.forEach(key => {
        if (bulkEditValues[key] !== undefined) {
          row.original[key] = bulkEditValues[key]
          rowChanged = true
        }
      })
      if (rowChanged) {
        saveSentence(row)
      }
    })
    toggleBulkEditor()
  }

  const cancelChanges = row => {
    // Handle new rows and edited rows differently
    const dataCopy = [...data]
    if (row.original.id !== null && row.original.id !== 0) {
      // Reset the edited row to the orginal state
      dataCopy[row.index] = originalData[row.index]
      paragraph.sentences = dataCopy
    } else {
      // Remove the new row from the collection
      dataCopy.splice(row.index, 1)
    }
    setData(dataCopy)
    removeChangedRow(row.index)
  }

  const duplicateElements = ({ selectedFlatRows }) => {
    let newData = [...data]
    selectedFlatRows.forEach(row => {
      const newRow = Object.assign({}, row.original)
      newRow.id = null
      newRow.status_Id = 1
      newData.push(newRow)
    })
    setData([...newData])
    setSelectedRows([])
  }

  const getIdsFromTriggerType = triggerType => {
    const activeTriggerTypes = (triggerType && triggerType.split(",")) || []
    const allRequiredTriggers = []

    if (allAvailableTriggers) {
      activeTriggerTypes.forEach(trigger => {
        const requiredTrigger = allAvailableTriggers.find(
          t => t.type === trigger.replace("!").trim()
        )
        requiredTrigger && allRequiredTriggers.push(requiredTrigger)
      })
    }

    const newTriggerIds =
      allRequiredTriggers && allRequiredTriggers.map(t => t.id).join()

    return newTriggerIds || []
  }

  const bulkAssignTriggerIds = ({ selectedFlatRows }) => {
    selectedFlatRows.forEach(row => {
      const { triggerType, isFromLibrary, triggerIds } = row.original
      if (triggerType && !isFromLibrary) {
        const calculatedTriggerIds = getIdsFromTriggerType(triggerType)
        if (triggerIds !== calculatedTriggerIds) {
          row.original.triggerIds = calculatedTriggerIds
          saveSentence(row)
        }
      }
    })
  }

  const bulkSaveElements = ({ selectedFlatRows }) => {
    selectedFlatRows.forEach(row => {
      saveSentence(row)
    })
    return true
  }

  const bulkArchiveElements = ({ selectedFlatRows }) => {
    selectedFlatRows.forEach(row => {
      if (row && row.original && typeof row.original.isArchived === "boolean") {
        row.original.isArchived = true
        saveSentence(row)
      }
    })
  }

  const addNewElements = numberToAdd => {
    const newData = [...data]

    for (let i = 0; i < numberToAdd; i++) {
      newData.unshift({
        id: null,
        paragraph_Id: paragraph.id,
        narrative_Id: paragraph.narrative_Id,
        status_Id: 3
      })
    }
    setData([...newData])
    addNumberNewChangedRows(numberToAdd)
  }

  const loadBulkEditComponent = React.useCallback(
    ({ selectedFlatRows, toggleBulkEditor, updateSelected }) => (
      <Form
        id="bulk-update"
        name="bulk-update"
        className="compact"
        initialValues={bulkEditValues}
        onSubmit={values =>
          updateSelected(values, selectedFlatRows, toggleBulkEditor)
        }
      >
        <Body>
          <div>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <h5>Bulk Editing</h5>
                <div
                  style={{
                    padding: "12px",
                    border: "1px solid black"
                  }}
                >
                  <ul
                    style={{
                      paddingInlineStart: "20px"
                    }}
                  >
                    {selectedFlatRows.map(d => (
                      <li key={d.original.id}>
                        {d.original.id}-{d.original.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </Grid>
              <Grid item xs={9}>
                <h5>Bulk Edit Values</h5>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <Input
                      fullWidth
                      type="number"
                      min={0}
                      name="position"
                      id="position"
                      label="Position"
                      margin="dense"
                    />
                    <Input
                      fullWidth
                      type="text"
                      name="triggerType"
                      id="triggerType"
                      label="Trigger"
                      margin="dense"
                    />
                    <Input
                      fullWidth
                      type="number"
                      min={0}
                      name="triggerWeight"
                      id="triggerWeight"
                      label="Weight"
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Select
                      id="sentenceType"
                      name="sentenceType"
                      label="Sentence Type"
                      margin="none"
                      fullWidth
                      options={fromJS([
                        {
                          name: "",
                          value: undefined
                        },
                        {
                          name: "Intro/Title",
                          value: "Introduction"
                        },
                        {
                          name: "Supporting",
                          value: "Supporting Sentence"
                        },
                        {
                          name: "Transition",
                          value: "Transition"
                        },
                        {
                          name: "Conclusion",
                          value: "Conclusion"
                        }
                      ])}
                      optionValueKey="value"
                      optionLabelKey="name"
                    />
                    <Select
                      id="renderType"
                      name="renderType"
                      label="Render Type"
                      margin="none"
                      fullWidth
                      options={fromJS([
                        { name: "", value: undefined },
                        { value: "none", name: "None" },
                        { value: "p", name: "p" },
                        { value: "div", name: "div" },
                        { value: "h1", name: "h1" },
                        { value: "h2", name: "h2" },
                        { value: "h3", name: "h3" },
                        { value: "h4", name: "h4" },
                        { value: "h5", name: "h5" },
                        { value: "ol", name: "ol" },
                        { value: "ul", name: "ul" },
                        { value: "li", name: "li" },
                        { value: "aside", name: "aside" },
                        { value: "figure", name: "figure" },
                        { value: "footer", name: "footer" },
                        { value: "header", name: "header" },
                        { value: "section", name: "section" },
                        { value: "time", name: "time" },
                        { value: "apxh:p", name: "apxh:p" },
                        { value: "apxh:div", name: "apxh:div" }
                      ])}
                      optionValueKey="value"
                      optionLabelKey="name"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      fullWidth
                      type="text"
                      name="template"
                      id="template"
                      label="Template"
                      margin="dense"
                    />
                    <Input
                      fullWidth
                      type="text"
                      name="starter"
                      id="starter"
                      label="Template"
                      margin="dense"
                    />
                    <Select
                      id="statusType"
                      name="status_Id"
                      label="Status"
                      margin="dense"
                      fullWidth
                      options={fromJS([
                        {
                          name: "No Change",
                          value: undefined
                        },
                        {
                          name: "Draft",
                          value: 1
                        },
                        {
                          name: "Review",
                          value: 2
                        },
                        {
                          name: "Published",
                          value: 3
                        },
                        {
                          name: "Planned",
                          value: 4
                        },
                        {
                          name: "Sample",
                          value: 5
                        }
                      ])}
                      optionValueKey="value"
                      optionLabelKey="name"
                    />
                    <Field name="isArchived">
                      {({ field }) => (
                        <FormControlLabel
                          control={<Checkbox {...field} />}
                          label="Archived"
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    padding: "12px",
                    textAlign: "end"
                  }}
                >
                  <Button type="button" onClick={toggleBulkEditor}>
                    Cancel
                  </Button>
                  <Button type="submit">Update</Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </Body>
      </Form>
    ),
    [bulkEditValues]
  )

  const loadSelectedRow = row => {
    if (!row.original.id) {
      return
    }
    data[row.index].isLoadingRow = true
    setData([...data])
    const getSentence = NarrativeDAO.getSentence(row.original.id)
    getSentence.then(response => {
      if (response) {
        data[row.index] = response
        row.original.isLoadingRow = false
        setData([...data])
      }
    })
  }

  let cookies = new Cookies()
  let columnWidths = cookies.get("columnWidths") || {}
  const sentenceColumns = React.useMemo(() => {
    const ParagraphCell = ({
      getValue,
      row,
      column: { id },
      table // This is a custom function that we supplied to our table instance
    }) => {
      const { index } = row
      // We need to keep and update the state of the cell normally
      const { root, inputRoot, acOption } = UseStyles()
      const value = getValue()

      const onChange = (e, option) => {
        table.options.meta?.handleDataChange(
          index,
          id,
          option ? option.id : null
        )
      }
      const allParagraphs = paragraphs.concat(libraryParagraphs)
      const selected = allParagraphs && allParagraphs.find(p => p.id === value)
      // Render a multi-select box
      return (
        <Autocomplete
          classes={{
            root,
            inputRoot,
            option: acOption,
            noOptions: acOption
          }}
          id={id}
          value={value}
          inputValue={(selected && `${selected.id} - ${selected.name}`) || ""}
          options={allParagraphs}
          onChange={onChange}
          PopperComponent={CustomPopper}
          getOptionLabel={option =>
            (option && `${option.id} - ${option.name}`) || ""
          }
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id === value : false
          }
          renderInput={params => <TextField {...params} />}
          disabled={!row.getIsSelected()}
        />
      )
    }
    return [
      {
        header: "Sentences",
        columns: [
          {
            header: "Block Info",
            columns: [
              {
                header: "Id",
                accessorKey: "id",
                cell: context =>
                  context.row.original.id
                    ? LinkSentence(context)
                    : BasicCell(context),
                size: columnWidths["id"] || 75
              },
              {
                header: "Narrative",
                accessorKey: "narrative_Id",
                meta: {
                  filterUi: NarrativeFilter
                },
                cell: BasicCell,
                size: columnWidths["narrative_Id"] || 110
              },
              {
                header: "Paragraph",
                accessorKey: "paragraph_Id",
                cell: ParagraphCell,
                size: columnWidths["paragraph_Id"] || 110
              },
              {
                header: "Status",
                accessorKey: "status_Id",
                cell: StatusTypeInput,
                meta: {
                  filterUi: StatusTypeFilter
                },
                filterFn: "equals",
                size: columnWidths["status_Id"] || 150
              },
              {
                header: "Owner",
                accessorKey: "ownerId",
                cell: OwnerInput,
                meta: {
                  filterUi: OwnerFilter
                },
                size: columnWidths["ownerId"] || 150,
                filterFn: "equals"
              },
              {
                size: columnWidths["name"] || 300,
                header: "Name",
                cell: EditableCell,
                meta: {
                  filterUi: DefaultColumnFilter
                },
                accessorKey: "name",
                filterFn: "includesString"
              },
              {
                header: "Sentence Type",
                accessorKey: "sentenceType",
                cell: SentenceTypeInput,
                meta: {
                  filterUi: SelectColumnFilter
                },
                filterFn: "equals",
                size: columnWidths["sentenceType"] || 150
              }
            ]
          },
          {
            header: "Render Info",
            columns: [
              {
                size: columnWidths["position"] || 125,
                header: "Position",
                accessorKey: "position",
                meta: {
                  filterUi: SelectColumnFilter
                },
                filterFn: "equals",
                cell: BasicCell
              },
              {
                header: "Template",
                accessorKey: "template",
                cell: EditableCell,
                filterFn: "includesString",
                meta: {
                  filterUi: DefaultColumnFilter
                },
                size: columnWidths["template"] || 500
              },
              {
                header: "Triggers",
                accessorKey: "triggerType",
                cell: EditableCell,
                filterFn: "includesString",
                meta: {
                  filterUi: DefaultColumnFilter
                },
                size: columnWidths["triggerType"] || 300
              },
              {
                header: "Trigger Ids",
                accessorKey: "triggerIds",
                cell: EditableCell,
                meta: {
                  filterUi: DefaultColumnFilter
                },
                size: columnWidths["triggerIds"] || 200,
                filterFn: "includesString"
              },
              {
                header: "Render As",
                accessorKey: "renderType",
                size: columnWidths["renderType"] || 126,
                meta: {
                  filterUi: SelectColumnFilter
                },
                filterFn: "equals",
                cell: RenderTypeInput
              },
              {
                header: "Weight",
                accessorKey: "triggerWeight",
                meta: {
                  filterUi: SelectColumnFilter
                },
                filterFn: "equals",
                cell: EditableCell,
                size: columnWidths["triggerWeight"] || 150
              }
            ]
          },
          {
            header: "Library Info",
            columns: [
              {
                header: "Position Group",
                accessorKey: "isLibraryPlaceholder",
                cell: SwitchInput,
                size: columnWidths["isLibraryPlaceholder"] || 150
              },
              {
                header: "Library Paragraph Id",
                accessorKey: "libraryParagraph_Id",
                cell: EditableCell,
                meta: {
                  filterUi: DefaultColumnFilter
                },
                size: columnWidths["libraryParagraph_Id"] || 125
              },
              {
                header: "Library Sentence Position",
                accessorKey: "librarySentencePosition",
                size: columnWidths["LibrarySentencePosition"] || 150,
                meta: {
                  filterUi: DefaultColumnFilter
                },
                cell: LibrarySentencePositionCell
              },
              {
                id: "sentenceVariationScore",
                size: columnWidths["sentenceVariationScore"] || 125,
                header: () => (
                  <div>
                    <span
                      style={{
                        fontWeight: "bold"
                      }}
                    >
                      Sentence Variation Score
                    </span>
                    <ContentSaturationSentenceModal />
                  </div>
                ),
                accessorKey: "sentenceVariationScore",
                cell: SaturationChipInput
              }
            ]
          },
          {
            header: "Details",
            columns: [
              {
                header: "Starter",
                accessorKey: "starter",
                cell: EditableCell,
                meta: {
                  filterUi: DefaultColumnFilter
                },
                size: columnWidths["starter"] || 300,
                filterFn: "includesString"
              },
              {
                header: "Attributes",
                accessorKey: "blockAttributes",
                cell: EditableCell,
                meta: {
                  filterUi: DefaultColumnFilter
                },
                size: columnWidths["blockAttributes"] || 150,
                filterFn: "includesString"
              },
              {
                header: "Comments",
                accessorKey: "comments",
                cell: EditableCell,
                meta: {
                  filterUi: DefaultColumnFilter
                },
                size: columnWidths["comments"] || 150,
                filterFn: "includesString"
              },
              {
                header: "Archived",
                accessorKey: "isArchived",
                cell: SwitchInput,
                size: columnWidths["isArchived"] || 150
              }
            ]
          }
        ]
      }
    ]
  }, [columnWidths, paragraphs, libraryParagraphs])

  const columns = [
    {
      id: "select",
      size: 100,
      header: ({ column, table }) => (
        <React.Fragment>
          <div
            style={{
              paddingBottom: "5px"
            }}
          >
            <span>Select</span>
          </div>
          <div>
            <SelectRowsHeaderActions
              column={column}
              table={table}
              loadSelectedRow={row => {
                loadSelectedRow(row)
              }}
            />
          </div>
        </React.Fragment>
      ),
      cell: SelectRowCell,
      meta: {
        removeJustSavedRowFromFilter,
        saveItem: saveSentence,
        cancelChanges,
        loadSelectedRow
      }
    },
    ...sentenceColumns
  ]

  // When our cell renderer calls updateLocalCollection, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateLocalCollection = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    skipResetRef.current = true
    setData((old = []) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          if (row[columnId] !== value) {
            addChangedRow(rowIndex)
          }
          return {
            ...row,
            [columnId]: value
          }
        }
        return row
      })
    )
  }

  // After data changes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => {
    skipResetRef.current = false
  }, [data])

  return (
    <div
      style={{
        padding: "10px"
      }}
    >
      <Table
        tableType="Sentence"
        columns={columns}
        data={data}
        handleDataChange={updateLocalCollection}
        onRowSelect={setSelectedRows}
        loadBulkEditComponent={loadBulkEditComponent}
        addElements={addNewElements}
        duplicateElements={duplicateElements}
        bulkSaveElements={bulkSaveElements}
        bulkArchiveElements={bulkArchiveElements}
        paragraph={paragraph}
        bulkTest={bulkTest}
        descriptorString={"Upload Sentences from a TSV file?"}
        passDataUp={dta => {
          const newData = [...data]
          dta.forEach(itm => {
            newData.unshift(itm)
            addChangedRow(0, { isNewRow: true })
          })
          setData(newData)
        }}
        allowUploads={props.allowUploads}
        bulkAssignTriggerIds={bulkAssignTriggerIds}
        updateSelected={updateSelected}
        loadSelectedRow={loadSelectedRow}
        ownerListForOrg={props.ownerListForOrg}
        skipReset={skipResetRef.current}
        changedRows={changedRowIndexes}
        hideColumns={["sentenceVariationScore"]}
      />
    </div>
  )
}

SentenceTable.propTypes = {
  narrative: PropTypes.object,
  bulkAlertMessage: PropTypes.func,
  isAlertActive: PropTypes.func,
  loading: PropTypes.bool,
  setActiveView: PropTypes.func,
  ownerListForOrg: PropTypes.array,
  exportSentence: PropTypes.func,
  exportParagraph: PropTypes.func,
  exportTrigger: PropTypes.func,
  activeView: PropTypes.string,
  allowUploads: PropTypes.bool
}
