import { globalSettings } from "variables/general.jsx"
import { get, put } from "../tools/request"

export default class TriggerDAO {
  static getTriggers = async () => {
    const res = await get(`${globalSettings.apiBaseUrl}/api/trigger`)
    return await res.json()
  }

  static getTrigger = async triggerId => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/trigger/${triggerId}`
    )
    return await res.json()
  }

  static putTrigger = async body => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/trigger`,
      null,
      body
    )
    return await res.json()
  }

  static replaceInTriggers = async body => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/trigger/replace`,
      null,
      body
    )
    return await res.json()
  }

  static getTriggersBrief = async narrativeIds => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/trigger/briefbynarrativeids?narrativeIds=${narrativeIds}`
    )
    return await res.json()
  }
}
