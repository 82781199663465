import React, { Component } from "react"
import Button from "components/CustomButtons/Button"
import DialogTitle from "@mui/material/DialogTitle"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import { globalSettings } from "variables/general"
import CircularProgress from "@mui/material/CircularProgress"
import { observer, inject } from "mobx-react"
import { postUpload } from "tools/request"

const useStore = component =>
  inject(({ store }) => ({
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization,
    isPermissionsLoaded: store.uiStore.isPermissionsLoaded,
    accountPermissions: store.accountStore.accountPermissions
  }))(observer(component))

class FileUpload extends Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      organizationId: -1,
      uploadComplete: false,
      failureReason: "",
      isFailedDialogOpen: false,
      uploadMessage: "",
      isFileBeingUploaded: false,
      filesToUploadCount: 0,
      fileNames: "",
      isFilesSelected: false
    }
    this.handleFileChange = this.handleFileChange.bind(this)
  }

  componentDidUpdate() {
    if (this.state.organizationId !== this.props.organizationId) {
      this.setState({
        organizationId: this.props.organizationId,
        uploadMessage: "",
        isFilesSelected: false,
        fileNames: "",
        filesToUploadCount: 0
      })
    }
  }

  handleClick() {
    this.refs.fileInput.click()
    this.setState({ uploadComplete: false, isFileBeingUploaded: false })
  }

  handleFileChange(e) {
    e.preventDefault()
    let files = e.target.files
    if (!files) {
      return
    }

    this.setState({
      isFilesSelected: true,
      fileNames: "",
      filesToUploadCount: files.length
    })
    Object.keys(files).forEach((file, key) => {
      this.uploadSingleFile(files[key])
    })
  }

  uploadSingleFile(file) {
    if (!file) {
      return
    }
    this.setState({ isFileBeingUploaded: true })
    let form = new FormData()
    form.append("title", "The title")
    form.append("file", file)
    postUpload(
      `${globalSettings.apiBaseUrl}/api/asset?organizationid=${this.state.organizationId}`,
      null,
      form
    )
      .then(response => response.json())
      .then(data => {
        this.setState({ isFileBeingUploaded: false })
        if (data.responseCode === 1000) {
          let uploadSuccessText = `Upload successful for file: ${
            file.name
          } (${file.size.toLocaleString()} bytes).`
          this.setState({
            fileNames: `${this.state.fileNames}<br/>${uploadSuccessText}`
          })
          //Per Ben F. - Uncomment when ready to display Ingest to Users
          this.setState({
            uploadMessage: this.state.uploadMessage + uploadSuccessText
          })
          this.props.handleFileUploadComplete()
        } else {
          let failureReasonText = `Unable to upload file: ${file.name}`
          this.setState({ failureReason: failureReasonText })
          this.setState({ isFailedDialogOpen: true })
        }
      })
      .catch(error => {
        let failureReasonText = `Unable to upload file: ${file.name} for reason: ${error}`
        this.setState({ failureReason: failureReasonText })
        this.setState({ isFailedDialogOpen: true })
      })
  }

  handleDialogClose = () => {
    this.setState({ isFailedDialogOpen: false })
    this.setState({ isSuccessDialogOpen: false })
  }

  render() {
    return (
      <div id="FileUpload" className="fileinput text-center">
        <Dialog
          onClose={this.handleDialogClose}
          open={this.state.isFailedDialogOpen}
          aria-labelledby="simple-dialog-title"
        >
          <DialogTitle id="simple-dialog-title">{"Sorry"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {this.state.failureReason}
            </DialogContentText>
          </DialogContent>
          <DialogActions />
        </Dialog>
        {this.props.isPermissionsLoaded &&
          this.props.accountPermissions.CanSeeNarrativeBuilder === "Yes" && (
            <div style={{ textAlign: "left" }}>
              <form action="/" encType="multipart/form-data" method="post">
                <input
                  type="file"
                  onChange={this.handleFileChange}
                  ref="fileInput"
                  disabled={this.state.isFileUploadDisabled}
                  multiple
                  style={{ visibility: "hidden" }}
                />
              </form>
              <Button onClick={() => this.handleClick()}>
                Select file(s) to upload...
              </Button>
              {this.state.isFilesSelected && (
                <div>
                  You selected {this.state.filesToUploadCount} file(s) to
                  upload.
                </div>
              )}
              <div dangerouslySetInnerHTML={{ __html: this.state.fileNames }} />
              {this.state.uploadComplete && (
                <div>{this.state.uploadMessage}</div>
              )}
              {this.state.isFileBeingUploaded && <CircularProgress />}
            </div>
          )}
        {this.props.isPermissionsLoaded &&
          this.props.accountPermissions.CanSeeNarrativeBuilder !== "Yes" && (
            <div style={{ textAlign: "left" }}>
              <span id="select_file">Select file to upload.</span>
              <form action="/" encType="multipart/form-data" method="post">
                <input
                  type="file"
                  onChange={this.handleFileChange}
                  ref="fileInput"
                  disabled={this.state.isFileUploadDisabled}
                />
              </form>
              <Button id="file_btn" onClick={() => this.handleClick()}>
                Choose file...
              </Button>
              {this.state.isFilesSelected && (
                <div id="file_loaded">
                  You selected {this.state.filesToUploadCount} file to upload.
                </div>
              )}
              <div dangerouslySetInnerHTML={{ __html: this.state.fileNames }} />
              {this.state.uploadComplete && (
                <div>{this.state.uploadMessage}</div>
              )}
              {this.state.isFileBeingUploaded && <CircularProgress />}
            </div>
          )}
      </div>
    )
  }
}

export default useStore(FileUpload)
