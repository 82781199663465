import React from "react"
import { Box } from "@mui/material"
import { makeStyles } from "@mui/styles"
import PropTypes from "prop-types"

import WordCounter from "components/WordCounter/WordCounter"

const useStyles = makeStyles(() => ({
  previewBlock: {
    marginTop: "10px",
    fontSize: "14px",
    position: "relative"
  }
}))

const ElementPreviewEntry = ({
  data,
  title,
  includeWordCounter = false,
  renderAsHtml = false,
  renderTimeInMilliseconds,
  specialInstructions,
  renderedProperty
}) => {
  const classes = useStyles()
  const allBold =
    renderedProperty &&
    specialInstructions &&
    specialInstructions[renderedProperty] &&
    specialInstructions[renderedProperty]["allBold"]
      ? true
      : false
  const nonStatsBold =
    renderedProperty &&
    specialInstructions &&
    specialInstructions[renderedProperty] &&
    specialInstructions[renderedProperty]["nonStatsBold"]
      ? true
      : false
  return (
    <Box>
      {includeWordCounter && data ? (
        <WordCounter
          title={title}
          inputString={data}
          renderTimeInMilliseconds={renderTimeInMilliseconds}
        />
      ) : (
        <span style={{ fontWeight: "bold" }}>{title}</span>
      )}
      {renderAsHtml ? (
        <div
          className={classes.previewBlock}
          dangerouslySetInnerHTML={{
            __html: data?.replace(/apxh:/g, "")
          }}
        />
      ) : allBold || nonStatsBold ? (
        <Box className={classes.previewBlock} style={{ fontWeight: "bold" }}>
          {data}
        </Box>
      ) : (
        <Box className={classes.previewBlock}>{data}</Box>
      )}
    </Box>
  )
}

ElementPreviewEntry.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  includeWordCounter: PropTypes.bool,
  renderAsHtml: PropTypes.bool,
  specialInstructions: PropTypes.object,
  renderedProperty: PropTypes.string,
  renderTimeInMilliseconds: PropTypes.number
}

export default ElementPreviewEntry
