import React, { useState, useEffect } from "react"
import { useObserver } from "mobx-react"
import { Link } from "react-router-dom"
import { useStore } from "contexts/rootContext"
import makeStyles from "@mui/styles/makeStyles"
import { groupCollectionBy } from "tools/CollectionHelper"
import { Collapse, Button, Modal, Container } from "@mui/material"
import { Alert, AlertTitle } from "@mui/material"
import narrativeDashboardStyles from "assets/jss/material-dashboard-pro-react/views/narrativeDashboardStyles"

const useStyles = makeStyles(theme => ({
  ...narrativeDashboardStyles,
  root: {
    position: "relative"
  },
  linkButton: {
    "&:hover": {
      cursor: "default!important"
    }
  },
  list: {
    listStyle: "none"
  },
  successModal: {
    position: "absolute",
    color: "#069",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "45%",
    left: "45%",
    maxHeight: "90vh",
    overflowX: "auto",
    transform: "translate(-45%, -45%)",
    "& h3": {
      fontWeight: "bold"
    },
    "& ul": {
      padding: "5px 0 0 5px",
      fontWeight: "bold"
    }
  },
  errorModal: {
    position: "absolute",
    color: "#c00",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "45%",
    left: "45%",
    transform: "translate(-45%, -45%)",
    "& h3": {
      fontWeight: "bold"
    },
    "& ul": {
      padding: "5px 0 0 5px",
      fontWeight: "bold",
      textDecoration: "underline"
    }
  }
}))

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    setNarrative: store.narrativeStore.setNarrative,
    narrative: store.narrativeStore.narrative,
    buildFromOutline:
      store.narrativeStore.narrative &&
      store.narrativeStore.narrative.buildFromOutline,
    isDirty: store.narrativeStore.isDirty,
    setIsDirty: store.narrativeStore.setIsDirty,
    postNarrative: store.narrativeStore.postNarrative,
    postParagraph: store.narrativeStore.postParagraph,
    paragraphs: store.narrativeStore.narrative.paragraphs,
    loadingOutlineBlocks: store.narrativeStore.loadingOutlineBlocks,
    loadingNarrative: store.narrativeStore.loading,
    renderTemplate: store.narrativeStore.renderTemplate,
    feedFields:
      store.narrativeStore.narrative &&
      store.narrativeStore.narrative.feedFields,
    outlineBlocks: store.narrativeStore.narrative.outlineBlocks,
    postingNarrativeBlock: store.narrativeStore.postingNarrativeBlock,
    postNarrativeOutlineBlock: store.narrativeStore.postNarrativeOutlineBlock,
    getSnapshotDerivedOutlineBlocks:
      store.narrativeStore.getSnapshotDerivedOutlineBlocks,
    getFeedEntryDerivedOutlineBlocks:
      store.narrativeStore.getFeedEntryDerivedOutlineBlocks,
    getParagraphDerivedOutlineBlocks:
      store.narrativeStore.getParagraphDerivedOutlineBlocks,
    responseCode: store.narrativeStore.responseCode,
    responseMessage: store.narrativeStore.responseMessage,
    setResponseCode: store.narrativeStore.setResponseCode,
    setResponseMessage: store.narrativeStore.setResponseMessage,
    dialogWarningNoBtnOpen: store.uiStore.dialogWarningNoBtnOpen,
    setDialogWarningNoBtnMessage: store.uiStore.setDialogWarningNoBtnMessage,
    setDialogWarningNoBtnOpen: store.uiStore.setDialogWarningNoBtnOpen
  }))
}

const NarrativeOutlineWarnings = () => {
  const classes = useStyles()
  const {
    narrative,
    buildFromOutline,
    paragraphs,
    outlineBlocks,
    postNarrative,
    postParagraph,
    isDirty,
    postNarrativeOutlineBlock,
    postingNarrativeBlock,
    loadingNarrative,
    loadingOutlineBlocks,
    getParagraphDerivedOutlineBlocks,
    setDialogWarningNoBtnMessage,
    setDialogWarningNoBtnOpen
  } = useStoreData()

  const [blockGroups, setBlockGroups] = useState([])
  const [openSaveAllModal, setOpenSaveAllModal] = useState()
  const [assignableParagraphs, setAssignableParagraphs] = useState([])
  const [orphanedParagraphs, setOrphanedParagraphs] = useState([])
  const [unsavedOutlineBlocks, setUnsavedOutlineBlocks] = useState([])
  const [hasBlocksOutOfSequence, setHasBlocksOutOfSequence] = useState([])
  const [openAssignAllModal, setOpenAssignAllModal] = useState(false)

  const [openOrphanModal, setOpenOrphanModal] = useState(false)

  const [openReindexAllModal, setOpenReindexAllModal] = useState(false)

  // Check to see if we have unassigned or orphaned paragraphs
  useEffect(() => {
    const contentBlocks =
      (outlineBlocks &&
        groupCollectionBy(
          outlineBlocks,
          ob => ob.contentBlock && ob.contentBlock.toLowerCase()
        )) ||
      []
    setBlockGroups(contentBlocks)
    let assignableParagraphs = []
    let savedOutlineBlocks =
      (outlineBlocks && outlineBlocks.filter(ob => ob.id && ob.id !== 0)) || []
    let unSavedBlocks =
      (outlineBlocks && outlineBlocks.filter(ob => !ob.id || ob.id === 0)) || []
    setUnsavedOutlineBlocks(unSavedBlocks)
    let paragraphsWithoutOutlineBlockIds =
      (paragraphs &&
        paragraphs.filter(
          p =>
            !p.narrativeOutlineBlockId ||
            p.narrativeOutlineBlockId === 0 ||
            !outlineBlocks.find(ob => ob.id === p.narrativeOutlineBlockId)
        )) ||
      []
    // setUnassignedParagraphs(paragraphsWithoutOutlineBlockIds)
    savedOutlineBlocks.forEach(sob => {
      let paragraphsToAssign =
        paragraphsWithoutOutlineBlockIds.filter(
          p =>
            p.contentBlock === sob.contentBlock && p.position === sob.position
        ) || []
      assignableParagraphs = [...assignableParagraphs, ...paragraphsToAssign]
    })

    setAssignableParagraphs(assignableParagraphs)

    let unassignableParagraphs =
      paragraphsWithoutOutlineBlockIds.filter(
        p => !assignableParagraphs.find(ap => ap.id === p.id)
      ) || []
    setOrphanedParagraphs(unassignableParagraphs)
  }, [outlineBlocks, paragraphs, postingNarrativeBlock])

  ///Check to see if there are blocks out of sequence
  useEffect(() => {
    if (loadingOutlineBlocks) {
      return
    }
    let blocksOutOfSequence = false

    // Don't analyze this until everything is saved
    if (!unsavedOutlineBlocks.length > 0) {
      let outlineBlockElements =
        (outlineBlocks &&
          (outlineBlocks.size > 0 || outlineBlocks.length > 0) &&
          Object.keys(blockGroups)) ||
        []
      outlineBlockElements.forEach(key => {
        let outlineBlocks = blockGroups[key]
        let sortedBlocks =
          outlineBlocks.sort((a, b) => {
            let comparison = 0
            if (a.position > b.position) {
              comparison = 1
            } else if (a.position < b.position) {
              comparison = -1
            }
            return comparison
          }) || []
        sortedBlocks.forEach((ob, index) => {
          if (ob.position !== index) {
            blocksOutOfSequence = true
          }
        })
      })
    }
    setHasBlocksOutOfSequence(blocksOutOfSequence)
  }, [outlineBlocks, unsavedOutlineBlocks, loadingOutlineBlocks, blockGroups])

  const validateNarrativeChanges = () => {
    const errors = []
    if (!narrative?.name) {
      errors.push(`Please choose a Name before saving.`)
    }
    if (!narrative?.organization || narrative?.organization.id < 0) {
      errors.push(`Please choose an Organization before saving.`)
    }
    if (
      !narrative?.narrativeOutputAttribute ||
      narrative?.narrativeOutputAttribute?.length === 0
    ) {
      errors.push(`Please choose an Output Attribute before saving.`)
    }
    return errors
  }

  const saveNarrative = () => {
    let errorsFound = []
    if ((errorsFound = validateNarrativeChanges()).length === 0) {
      // If narrativeOutputAttribute chosen and not empty, save
      let myNarrativeOutputAttribute = []
      narrative.narrativeOutputAttribute.forEach(itm => {
        myNarrativeOutputAttribute.push({
          narrativeId: itm.narrativeId,
          outputAttributeId: itm.outputAttributeId
        })
      })
      postNarrative({
        ...narrative,
        narrativeOutputAttribute: myNarrativeOutputAttribute
      })
    } else {
      // If narrativeOutputAttribute not chosen, show dialog
      setDialogWarningNoBtnOpen(true)
      setDialogWarningNoBtnMessage(
        <>
          {errorsFound?.map(errs => (
            <div>{errs}</div>
          ))}
        </>
      )
    }
  }

  const saveAllUnsavedBlocks = () => {
    if (unsavedOutlineBlocks.length > 0) {
      // Loop through each of the unsaved blocks and save them
      for (let i = 0; i < unsavedOutlineBlocks.length; i++) {
        setTimeout(postNarrativeOutlineBlock, 1000, unsavedOutlineBlocks[i])
      }
    }
  }
  // Function to assign all unassigned paragraphs
  const assignAllParagraphs = () => {
    if (assignableParagraphs.length > 0) {
      let savedOutlineBlocks =
        (outlineBlocks && outlineBlocks.filter(ob => ob.id && ob.id !== 0)) ||
        []
      savedOutlineBlocks.forEach(ob => {
        let outlineBlockParagraphs = assignableParagraphs.filter(
          p => p.position === ob.position && p.contentBlock === ob.contentBlock
        )
        outlineBlockParagraphs.forEach(paragraph => {
          paragraph.narrativeOutlineBlockId = ob.id
          setTimeout(postParagraph, 1000, paragraph)
        })
      })
    }
  }

  const convertPositionToIndex = () => {
    let outlineBlockElements =
      ((outlineBlocks.size > 0 || outlineBlocks.length > 0) &&
        Object.keys(blockGroups)) ||
      []
    outlineBlockElements.forEach(key => {
      let elementBlocks = blockGroups[key]
      let sortedBlocks =
        elementBlocks.sort((a, b) => {
          let comparison = 0
          if (a.position > b.position) {
            comparison = 1
          } else if (a.position < b.position) {
            comparison = -1
          }
          return comparison
        }) || []
      sortedBlocks.forEach((ob, index) => {
        ob.position = index
        setTimeout(postNarrativeOutlineBlock, 1000, ob)
      })
    })
    setOpenReindexAllModal(false)
  }

  const WarningButton = props => {
    const { children, ...rest } = props
    return (
      <Button
        {...rest}
        disabled={
          loadingNarrative || loadingOutlineBlocks || postingNarrativeBlock
        }
        color="secondary"
        size="small"
      >
        {children}
      </Button>
    )
  }
  return (
    <div className={classes.root}>
      <Collapse in={isDirty}>
        <Alert
          action={
            <>
              <WarningButton onClick={saveNarrative}>Save</WarningButton>
            </>
          }
          severity="warning"
        >
          <AlertTitle>Unsaved Narrative Changes</AlertTitle>
          Changes have been made to this narrative, click Save to remember them.
        </Alert>
      </Collapse>
      <Collapse in={unsavedOutlineBlocks.length > 0}>
        <Alert
          action={
            <>
              <WarningButton onClick={saveAllUnsavedBlocks}>
                Save All
              </WarningButton>
              <WarningButton
                onClick={() => {
                  setOpenSaveAllModal(true)
                }}
              >
                More Info
              </WarningButton>
            </>
          }
          severity="warning"
        >
          <AlertTitle>Unsaved Outline Blocks</AlertTitle>
          {unsavedOutlineBlocks.length} Blocks have been added to this outline.
          Save All or get More Info.
        </Alert>
      </Collapse>
      <Collapse in={assignableParagraphs.length > 0}>
        <Alert
          action={
            <>
              <WarningButton onClick={assignAllParagraphs}>
                Assign All
              </WarningButton>
              <WarningButton
                onClick={() => {
                  setOpenAssignAllModal(true)
                }}
              >
                More Info
              </WarningButton>
            </>
          }
          severity="warning"
        >
          <AlertTitle>Unassigned Paragraphs</AlertTitle>
          There are {assignableParagraphs.length} paragraphs at positions in the
          current outline that are unassigned click 'More Info' for options.
        </Alert>
      </Collapse>
      <Collapse
        in={
          buildFromOutline &&
          unsavedOutlineBlocks.length === 0 &&
          orphanedParagraphs.length > 0
        }
      >
        <Alert
          action={
            <>
              <WarningButton
                onClick={() => {
                  setOpenOrphanModal(true)
                }}
              >
                More Info
              </WarningButton>
            </>
          }
          severity="warning"
        >
          <AlertTitle>Paragraphs Without Outline Blocks</AlertTitle>
          There are {orphanedParagraphs.length} paragraphs without outline
          blocks. Click 'More Info' for options.
        </Alert>
      </Collapse>
      <Collapse
        in={
          buildFromOutline &&
          assignableParagraphs.length === 0 &&
          orphanedParagraphs.length === 0 &&
          unsavedOutlineBlocks.length === 0 &&
          hasBlocksOutOfSequence
        }
      >
        <Alert
          action={
            <>
              {/* <WarningButton onClick={convertPositionToIndex}>
                Convert
              </WarningButton> */}
              <WarningButton
                onClick={() => {
                  setOpenReindexAllModal(true)
                }}
              >
                More Info
              </WarningButton>
            </>
          }
          severity="warning"
        >
          <AlertTitle>Outline Not Sequenced</AlertTitle>
          Outline Block positions are out of sequence.
        </Alert>
      </Collapse>
      <Modal
        open={openSaveAllModal}
        onClose={() => {
          setOpenSaveAllModal(false)
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.successModal}>
          <h2 id="server-modal-title">Unsaved Outline Blocks</h2>
          <div>
            <p>
              <strong>{unsavedOutlineBlocks.length}</strong> Outline blocks have
              been added to this outline.
            </p>
            <h3>Save Options</h3>
            <div style={{ marginLeft: "10px" }}>
              <h4>Save All Outline Blocks</h4>
              <p>
                This option will save all newly added outline blocks to this
                narrative and allow for paragraph assignments.
              </p>
            </div>
            <h3>Why is this necessary?</h3>
            <p>
              Paragraphs not assigned to outline blocks will not be published
              and can not be tracked when 'Build From Outline' is selected.
              Additionally, this is a required step for enabling drag and drop
              functionality.
            </p>
            {assignableParagraphs > 0 && (
              <p>
                <strong>IMPORTANT: </strong>
                There are {assignableParagraphs.length} that will then need to
                be assigned
              </p>
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end"
            }}
          >
            <Button
              style={{ margin: "10px 10px 10px 10px" }}
              size="sm"
              variant="contained"
              onClick={() => {
                saveAllUnsavedBlocks()
                setOpenSaveAllModal(false)
              }}
            >
              Save All Blocks
            </Button>
            {/* <Button
              style={{ margin: "10px 10px 10px 10px" }}
              size="sm"
              variant="contained"
              onClick={() => {
                saveAllUnsavedBlocks()
                assignAllParagraphs()
                setOpenSaveAllModal(false)
              }}
            >
              Save &amp; Assign Paragraphs
            </Button> */}
            <Button
              style={{ margin: "10px 10px 10px 10px" }}
              size="sm"
              variant="contained"
              onClick={() => {
                setOpenSaveAllModal(false)
              }}
            >
              Ignore For Now
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        open={openOrphanModal}
        onClose={() => {
          setOpenOrphanModal(false)
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.successModal}>
          <h2 id="server-modal-title">
            Paragraphs Without Outline Blocks Warning
          </h2>
          <div>
            <p>
              Paragraphs Without Outline Blocks, aka Orphaned Paragraphs, are
              paragraphs that exist at positions not represented as outline
              blocks. This warning can be resolved by creating the outline from
              the available paragraphs or from render block snaphsots.
            </p>
            <strongp>
              There are currently {orphanedParagraphs.length} paragraphs that
              you could create an outline from.
            </strongp>
            <Container>
              <ul>
                {orphanedParagraphs.map(up => (
                  <li key={up.id}>
                    <Link
                      title={`Open `}
                      to={`/portal/narrative/${narrative.id}/edit?view=paragraphid-${up.id}`}
                      target="_blank"
                    >
                      {up.id} {up.name} {up.contentBlock} {up.position}
                    </Link>
                  </li>
                ))}
              </ul>
            </Container>
            <h3>More Information</h3>
            <div style={{ marginLeft: "10px" }}>
              <h4>Build From Snapshots</h4>
              <p>
                This option will create outline blocks based off of previously
                rendered content, capturing a random example of content at this
                position and calculating the min, max and average length of
                content at this position for QA checks. This is the best choice
                if you want to have sample text provided within the Outline View
                in order to create an easy sample for a client. However, this
                option will not include all paragraphs / sentences within the
                "blocks" section of Narrative Editor.
              </p>
              <h4>Build From Paragraphs</h4>
              <p>
                This option will create outline blocks based off of available
                paragraph position information but will not provide a sample.
                This option is the best choice if you want to have all the
                paragraphs and content in the “blocks” section of Narrative
                Editor represented within the main Outline view and Outline
                Blocks.
              </p>
            </div>
            <h3>Why is this necessary?</h3>
            <p>
              Paragraphs not assigned to outline blocks will not be published
              and can not be tracked when 'Build From Outline' is selected.
              Additionally, this is a required step for enabling drag and drop
              functionality.
            </p>
            <h3>What to expect</h3>
            <p>
              Building an outline from existing blocks may create outline blocks
              you no longer need or want. Remove any unnecessary blocks from the
              outline before saving all to prevent erroneous content from being
              rendered.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end"
            }}
          >
            <Button
              style={{ margin: "10px 10px 10px 10px" }}
              size="sm"
              variant="contained"
              onClick={() => {
                getParagraphDerivedOutlineBlocks(narrative.id)
                setOpenOrphanModal(false)
              }}
            >
              Build From Paragraphs
            </Button>
            <Button
              style={{ margin: "10px 10px 10px 10px" }}
              size="sm"
              variant="contained"
              onClick={() => {
                setOpenOrphanModal(false)
              }}
            >
              Ignore For Now
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        open={openAssignAllModal}
        onClose={() => {
          setOpenAssignAllModal(false)
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.successModal}>
          <h2 id="server-modal-title">Unassigned Paragraph Warning</h2>
          <div>
            <p>
              Unassigned Paragraphs are paragraphs that exist at positions
              represented as outline blocks but are not yet assigned to them.
              This warning can be resolved by manually assigning each one or
              clicking Assign All below.
            </p>
            <strongp>
              There are currently {assignableParagraphs.length} paragraphs
            </strongp>
            <Container>
              <ul>
                {assignableParagraphs.map(up => (
                  <li key={up.id}>
                    <Link
                      title={`Open `}
                      to={`/portal/narrative/${narrative.id}/edit?view=paragraphid-${up.id}`}
                      target="_blank"
                    >
                      {up.id} {up.name} {up.contentBlock} {up.position}
                    </Link>
                  </li>
                ))}
              </ul>
            </Container>
            <h3>More Information</h3>
            <h4>Assign Manually</h4>
            <p>
              To assign a paragraph manually, go to the paragraph and select the
              outline block it belongs to.
            </p>
            <h4>Assign All</h4>
            <p>
              This option will assign all unassigned paragraphs to the outline
              blocks at their positions.
            </p>
            <h3>Why is this necessary?</h3>
            <p>
              We associate paragaphs to outline blocks for two major reasons:
              <ol>
                <li>
                  To render predictable, testable content blocks without
                  handcoding content positions
                </li>
                <li>
                  To allow authors to easily rearrange content without having to
                  edit every paragraph
                </li>
              </ol>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end"
            }}
          >
            <Button
              style={{ margin: "10px 10px 10px 10px" }}
              size="sm"
              variant="contained"
              onClick={() => {
                assignAllParagraphs()
                setOpenAssignAllModal(false)
              }}
            >
              Assign All
            </Button>
            <Button
              style={{ margin: "10px 10px 10px 10px" }}
              size="sm"
              variant="contained"
              onClick={() => {
                setOpenAssignAllModal(false)
              }}
            >
              Ignore
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        open={openReindexAllModal}
        onClose={() => {
          setOpenReindexAllModal(false)
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.successModal}>
          <h2 id="server-modal-title">Outline Block Out Of Sequence</h2>
          <div>
            <p>
              This narrative meets all requirements but has unsequenced blocks
              preventing the use of outline features such has Lock-a-Block and
              Drag and Drop reordering. It is not required to sequence your
              narrative, you can continue to sync and update blocks as you have
              been. Just remember, changes to the content structure of your
              output narrative will not be able to be made from this page until
              such time.
            </p>
            <h3>Warning</h3>
            <div style={{ marginLeft: "10px" }}>
              <h4>Resequencing This Narrative Is Irreversible</h4>
              <p>
                This option will change the way paragraphs are managed.
                Paragraph positions will be managed by the outline blocks they
                are assigned to. The positions will still be there for backward
                compatibility but will be zero based indexes instead of the 20,
                40, 60 spacing of legacy narrative's paragraph blocks.
              </p>
            </div>
            <h3>How did this happen?</h3>
            <p>
              If you aren't sure how this happened tell us what you did to get
              here.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end"
            }}
          >
            <Button
              style={{ margin: "10px 10px 10px 10px" }}
              size="sm"
              variant="contained"
              onClick={() => {
                convertPositionToIndex()
                setOpenReindexAllModal(false)
              }}
            >
              Resequence Blocks
            </Button>
            <Button
              style={{ margin: "10px 10px 10px 10px" }}
              size="sm"
              variant="contained"
              onClick={() => {
                setOpenReindexAllModal(false)
              }}
            >
              Ignore For Now
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default NarrativeOutlineWarnings
