import React from "react"
import PropTypes from "prop-types"
import withStyles from "@mui/styles/withStyles"
import {
  FormControl,
  OutlinedInput,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  InputLabel,
  TextField,
  IconButton,
  Tooltip
} from "@mui/material"
import { NarrativeContext } from "contexts/narrative-context"
import { Cancel, Edit } from "@mui/icons-material"

const TRIGGER_REGEX = "[a-z0-9_]"

const styles = () => ({
  root: {
    "&.root": {
      width: "100%"
    }
  },
  iconButton: {
    padding: 0,
    margin: 0,
    "& > span > svg": {
      width: ".6em",
      height: ".6em"
    }
  }
})

class TriggerTypeManager extends React.Component {
  static contextType = NarrativeContext
  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      triggerKey: props.value,
      triggerName: props.name,
      addNew: false,
      validTrigger: false
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.triggerKey !== nextProps.value) {
      this.setState({
        triggerKey: nextProps.value,
        triggerName: nextProps.name,
        selectedType: nextProps.value || ""
      })
    }
  }
  render() {
    const { classes } = this.props
    const { narrative } = this.context
    const { triggerKey, manualEdit } = this.state
    const handleTriggerChange = name => event => {
      let newState = {
        ...this.state,
        [name]: event.target.value
      }
      if (name === "triggerKey" && Array.isArray(event.target.value)) {
        newState = {
          ...newState,
          [name]: event.target.value && event.target.value.join(", ")
        }
      }

      this.setState({ ...newState })
      // Now mock up an event to pass to the parent form
      const newTrigger = {
        key: newState.triggerKey,
        name: newState.triggerName
      }
      this.props.onChange(newTrigger)
    }

    return (
      <div style={{ display: "flex", alignItems: "baseline" }}>
        {!manualEdit && (
          <React.Fragment>
            <FormControl margin="normal" fullWidth>
              <InputLabel>Selected Triggers</InputLabel>
              <Select
                label="Selected Triggers"
                fullWidth
                multiple
                value={(triggerKey && triggerKey.split(", ")) || []}
                onChange={handleTriggerChange("triggerKey")}
                input={
                  <OutlinedInput
                    id="Select Triggers"
                    label="Selected Triggers"
                  />
                }
                renderValue={selected => selected.join(", ")}
              >
                {narrative &&
                  narrative.triggers &&
                  narrative.triggers.length > 0 &&
                  narrative.triggers.map((trigger, i) => (
                    <MenuItem
                      key={i}
                      value={trigger.type}
                      classes={{ selected: classes.selected }}
                    >
                      <Checkbox
                        checked={
                          triggerKey && triggerKey.indexOf(trigger.type) > -1
                        }
                      />
                      <ListItemText primary={trigger.type} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Tooltip
              id="tooltip-top"
              title="Manually Edit Triggers"
              placement="top"
            >
              <IconButton
                className={classes.iconButton}
                aria-label="Manually Edit Triggers"
                onClick={() => this.setState({ manualEdit: true })}
                size="large"
              >
                <Edit />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        )}
        {manualEdit && (
          <React.Fragment>
            <TextField
              id="triggerKey"
              label="Selected Triggers"
              value={triggerKey}
              onChange={handleTriggerChange("triggerKey")}
              inputProps={{
                pattern: TRIGGER_REGEX
              }}
              onInvalid={() => alert("This is so wrong!!")}
              helperText="Comma separated list of necessary triggers. Put ! in front of triggers that shouldn't exist."
              margin="normal"
              fullWidth
            />
            <Tooltip
              id="tooltip-top"
              title="Cancel Manual Edit"
              placement="top"
            >
              <IconButton
                className={classes.iconButton}
                aria-label="Cancel Manual Edit"
                onClick={() => this.setState({ manualEdit: false })}
                size="large"
              >
                <Cancel />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        )}
      </div>
    )
  }
}

TriggerTypeManager.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string
}

export default withStyles(styles)(TriggerTypeManager)
