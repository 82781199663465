// eslint-disable-next-line no-unused-vars
import React, { Component } from "react"
import PropTypes from "prop-types"
import { Field } from "formik"
import { FormControl, TextField, FormHelperText, Chip } from "@mui/material"

import { Autocomplete } from "@mui/material"

const defaultProps = {
  displayEmpty: true,
  autoWidth: false,
  fullWidth: false,
  hideLabel: false,
  margin: "normal",
  multiple: false,
  readOnly: false,
  required: false,
  type: "text"
}

const propTypes = {
  displayEmpty: PropTypes.bool,
  autoWidth: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helpText: PropTypes.string,
  hideLabel: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  margin: PropTypes.oneOf(["none", "dense", "normal"]),
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool
}

class Tags extends Component {
  render() {
    const {
      fullWidth,
      helpText,
      id,
      label,
      margin,
      name,
      options,
      readOnly,
      required
    } = this.props

    return (
      <Field name={name}>
        {({
          form: { setFieldValue, setFieldTouched, submitCount },
          meta: { value, touched, error }
        }) => {
          const hasErrors = (submitCount || touched) && error && true

          return (
            <FormControl
              className="form__control"
              hiddenLabel={label && true}
              required={required}
              fullWidth={fullWidth}
              error={hasErrors}
              disabled={readOnly}
              margin={margin}
            >
              <Autocomplete
                id={`${id}__input`}
                className="form__input"
                onChange={(event, newValue) => {
                  setFieldValue(name, newValue)
                }}
                onBlur={() => setFieldTouched(name, true)}
                value={value}
                multiple
                options={(options && options.map(option => option.name)) || []}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value &&
                  value.map((option, index) => (
                    <Chip
                      key={option}
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    label={label}
                    required={required}
                    readOnly={readOnly}
                    error={hasErrors}
                    fullWidth={fullWidth}
                  />
                )}
              />
              {helpText && (
                <FormHelperText
                  className="form__helper-text"
                  error={hasErrors}
                  disabled={readOnly}
                  required={required}
                >
                  {helpText}
                </FormHelperText>
              )}
            </FormControl>
          )
        }}
      </Field>
    )
  }
}

Tags.defaultProps = defaultProps
Tags.propTypes = propTypes
export default Tags
