import React, { useEffect, useState } from "react"
import { useObserver } from "mobx-react"
import { Link } from "react-router-dom"
import { useStore } from "contexts/rootContext"
import makeStyles from "@mui/styles/makeStyles"
import styled from "styled-components"
import clsx from "clsx"
import { ExpandMore } from "@mui/icons-material"
import CardBody from "components/Card/CardBody"
import CardHeader from "components/Card/CardHeader"
import { Collapse, IconButton } from "@mui/material"
import { primaryNavy } from "assets/jss/material-dashboard-pro-react"
import NarrativeOutlineBlock from "components/NarrativeAdmin/NarrativeOutlineBlock"
import NarrativeOutlineBlockAddDnD from "components/NarrativeAdmin/NarrativeOutlineBlockAddDnD"

import NarrativeOutlineBlockAddClassic from "components/NarrativeAdmin/NarrativeOutlineBlockAdd"

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { sectionsToRenderAsHtml } from "./Data/contentSections"

const useStyles = makeStyles(theme => ({
  elementHeader: {
    display: "block",
    textAlign: "center",
    position: "relative",
    maxHeight: "40px",
    padding: "7px",
    zIndex: "inherit!important",
    backgroundColor: primaryNavy,
    "& > label": {
      fontWeight: "bold",
      fontSize: "18px",
      color: "#fff",
      textTransform: "capitalize",
      "& > a": {
        color: "#fff",
        margin: "0 5px"
      },
      "& > a:hover": {
        textDecoration: "underline"
      },
      "& > span": {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#fff",
        padding: "0 5px",
        borderRadius: "20px"
      }
    },
    "& > button": {
      position: "absolute",
      right: "10px",
      top: "5px",
      padding: 0
    }
  },
  expand: {
    color: "#fff",
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  draggableBox: {
    position: "relative"
  }
}))

const Handle = styled.div`
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 20px 20px 0;
  border-color: transparent #144d69 transparent transparent;
  transform: rotate(0deg);
  position: absolute;
  right: 0px;
  z-index: 193;
`

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    narrative: store.narrativeStore.narrative,
    paragraphs: store.narrativeStore.narrative.paragraphs,
    narrativeId: store.narrativeStore.narrativeId,
    renderTemplate: store.narrativeStore.renderTemplate,
    removeOutlineBlock: store.narrativeStore.removeOutlineBlock,
    postingNarrativeOutlineBlock:
      store.narrativeStore.postingNarrativeOutlineBlock,
    loadingOutlineBlocks: store.narrativeStore.loadingOutlineBlocks,
    postNarrativeOutlineBlock: store.narrativeStore.postNarrativeOutlineBlock,
    postParagraph: store.narrativeStore.postParagraph,
    updateElementOutlineBlockIndexes:
      store.narrativeStore.updateElementOutlineBlockIndexes
  }))
}

const NarrativeOutlineElement = props => {
  const classes = useStyles()

  const {
    narrativeId,
    narrative,
    removeOutlineBlock,
    paragraphs,
    updateElementOutlineBlockIndexes
  } = useStoreData()

  const {
    outlineBlocks,
    blockViewStyle,
    elementId,
    elementName,
    expandedNarrativeOutlineElements,
    allowReordering
  } = props

  const sortBlocks = blocks =>
    (blocks &&
      blocks.sort((a, b) => {
        let comparison = 0
        if (a.position > b.position) {
          comparison = 1
        } else if (a.position < b.position) {
          comparison = -1
        }
        return comparison
      })) ||
    []

  const [sortedBlocks, setSortedBlocks] = useState(sortBlocks(outlineBlocks))

  useEffect(() => {
    setSortedBlocks(sortBlocks(outlineBlocks))
  }, [outlineBlocks])

  const filterBlockParagraphs = (id, position) =>
    (paragraphs &&
      paragraphs.filter(
        p =>
          (p.narrativeOutlineBlockId &&
            p.narrativeOutlineBlockId > 0 &&
            p.narrativeOutlineBlockId === id) ||
          ((!p.narrativeOutlineBlockId || p.narrativeOutlineBlockId < 1) &&
            p.contentBlock === elementId &&
            p.position === position)
      )) ||
    []

  const isContentElement = sectionsToRenderAsHtml.includes(elementId)

  const anyBlocksInEditMode =
    narrative.outlineBlocks &&
    narrative.outlineBlocks.filter(ob => ob.inEditMode).length > 0
  const preventDrag =
    (!narrative.buildFromOutline && !allowReordering) || anyBlocksInEditMode

  const detailsOpen = expandedNarrativeOutlineElements.indexOf(elementId) > -1

  // Drag End event for Build form Outline Drag and Drop
  const onDragEnd = result => {
    if (!result.destination) {
      return
    }
    const items = [...sortedBlocks]
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    updateElementOutlineBlockIndexes(items)
  }

  // Add block for Zero Based Build From Outline and Drag and Drop
  const addBlock = (block, index) => {
    const items = [...sortedBlocks]
    items.splice(index, 0, block)
    updateElementOutlineBlockIndexes(items)
  }

  // Calculates the next postion between to blocks in Classic Mode
  const calculateNextPosition = blockIndex => {
    const prevBlock = sortedBlocks[blockIndex]
    const nextBlock = sortedBlocks[blockIndex + 1]
    if (nextBlock && nextBlock.position && prevBlock && prevBlock.position) {
      return Math.round((prevBlock.position + nextBlock.position) / 2)
    } else if (nextBlock && nextBlock.position && !prevBlock) {
      return Math.round(nextBlock.position / 2)
    } else if (!nextBlock && prevBlock && prevBlock.position > -1) {
      return prevBlock.position + 20
    } else {
      return 0
    }
  }

  // Calculates if the Classic Addblock should be shown
  const canAddBetween = blockIndex => {
    const thisBlock = sortedBlocks[blockIndex]
    const prevBlock = blockIndex > 0 ? sortedBlocks[blockIndex - 1] : null

    if (!prevBlock) {
      return thisBlock.position > 1
    } else if (prevBlock.position === 1) {
      return false
    } else if (
      thisBlock &&
      thisBlock.position &&
      prevBlock &&
      prevBlock.position
    ) {
      return thisBlock.position - prevBlock.position > 1
    } else {
      return false
    }
  }

  // This component accounts for the two different ways to add and order blocks
  // The DnD AddBlock adds at any point in a zero based list. Reordering all as it adds
  // The Classic calculates a postion between to blocks and does not change other blocks
  const AddBlockBetweenComponent = blockProps => {
    const { loadingOutlineBlocks, postingNarrativeOutlineBlock } = props
    const { index, block } = blockProps

    let disabled = loadingOutlineBlocks || postingNarrativeOutlineBlock
    // Return null if we know we shouldn't/can't add blocks yet
    if (disabled || !isContentElement || block.id === null || block.id === 0) {
      return null
    }

    // Build from Outline uses zero based index
    if (narrative.buildFromOutline && !preventDrag) {
      return (
        <NarrativeOutlineBlockAddDnD
          elementId={elementId}
          elementName={elementName}
          narrativeId={narrativeId}
          sampleBlock={block}
          position={index}
          hasSiblings={outlineBlocks && outlineBlocks.length > 0}
          handleAddBlock={addBlock}
          disabled={disabled}
        />
      )
    }
    // If not build from outline, will calculate space between positions
    if (canAddBetween(index)) {
      return (
        <NarrativeOutlineBlockAddClassic
          elementId={elementId}
          elementName={elementName}
          narrativeId={narrativeId}
          sampleBlock={block}
          position={calculateNextPosition(index - 1)}
          hasSiblings={outlineBlocks && outlineBlocks.length > 0}
          disabled={disabled}
        />
      )
    }
    // If we get this far we return null because no conditions were met
    return null
  }

  const AddBlockEnd = () => {
    const { loadingOutlineBlocks, postNarrativeOutlineBlock } = props

    let disabled =
      loadingOutlineBlocks || postNarrativeOutlineBlock || anyBlocksInEditMode
    if (disabled) {
      return null
    }

    if (narrative.buildFromOutline) {
      return (
        <NarrativeOutlineBlockAddDnD
          elementId={elementId}
          elementName={elementName}
          narrativeId={narrativeId}
          hasSiblings={outlineBlocks && outlineBlocks.length > 0}
          position={(outlineBlocks && outlineBlocks.length + 1) || 0}
          handleAddBlock={addBlock}
          disabled={disabled}
          // open={!outlineBlocks || outlineBlocks.length === 0}
        />
      )
    }
    return (
      <NarrativeOutlineBlockAddClassic
        elementId={elementId}
        elementName={elementName}
        narrativeId={narrativeId}
        hasSiblings={outlineBlocks && outlineBlocks.length > 0}
        position={
          outlineBlocks && outlineBlocks.length > 0
            ? calculateNextPosition(outlineBlocks.length - 1)
            : 20
        }
        disabled={disabled}
        // open={!outlineBlocks || outlineBlocks.length === 0}
      />
    )
  }

  return (
    <>
      <CardHeader className={classes.elementHeader}>
        <label>
          <Link
            title={`View ${elementName} Blocks in Editor`}
            to={
              narrativeId
                ? `/portal/narrative/${narrativeId}/edit?view=element-${elementId}`
                : "#"
            }
          >
            {elementName}
          </Link>
          {/* <span aria-label={blockTitle} title={blockTitle}>
          {(outlineBlocks && outlineBlocks.length) || 0}
        </span> */}
        </label>
        <IconButton
          color="primary"
          title="Toggle View"
          className={clsx(classes.expand, {
            [classes.expandOpen]: detailsOpen
          })}
          onClick={() =>
            props.toggleExpandElement && props.toggleExpandElement(elementId)
          }
          aria-expanded={detailsOpen}
          aria-label="show more"
          size="large"
        >
          <ExpandMore />
        </IconButton>
      </CardHeader>
      <Collapse in={detailsOpen} unmountOnExit>
        <CardBody>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={elementId}>
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {sortedBlocks &&
                    sortedBlocks.map((block, index) => (
                      <Draggable
                        isDragDisabled={preventDrag}
                        key={`${elementId}-${block.position}-${
                          block.tmpId || block.id
                        }`}
                        draggableId={`${elementId}-${block.position}-${
                          block.tmpId || block.id
                        }`}
                        index={index}
                      >
                        {provided => (
                          <div
                            key={`${block.tmpId || block.id}`}
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                          >
                            <AddBlockBetweenComponent
                              block={block}
                              index={index}
                            />
                            {!preventDrag && (
                              <div className={classes.draggableBox}>
                                <Handle
                                  {...provided.dragHandleProps}
                                  title="Drag Handle"
                                />
                              </div>
                            )}
                            <NarrativeOutlineBlock
                              canContainMarkup={isContentElement}
                              block={block}
                              blockViewStyle={blockViewStyle}
                              removeBlock={() => removeOutlineBlock(block)}
                              narrative={narrative}
                              paragraphs={filterBlockParagraphs(
                                block.id,
                                block.position
                              )}
                              elementId={elementId}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {(!outlineBlocks || isContentElement) && <AddBlockEnd />}
        </CardBody>
      </Collapse>
    </>
  )
}

export default NarrativeOutlineElement
