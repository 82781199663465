import React, { Component } from "react"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import Accordion from "components/Accordion/Accordion"
import CardHeader from "components/Card/CardHeader"
import { observer, inject } from "mobx-react"

const useStore = component =>
  inject(({ store }) => ({
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization
  }))(observer(component))

class FAQs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      textToValidate: "N/A",
      grammarValidationResults: {}
    }
  }

  componentDidMount() {
    this.props.setShowChangeCurrentOrganization(false)
  }

  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader>
              <h4 className="">Frequently Asked Questions</h4>
            </CardHeader>
            <CardBody>
              <Accordion
                active={0}
                collapses={[
                  {
                    title: "What does Data Skrive do?",
                    content:
                      "The Data Skrive platform translates structured data into consumer-ready content. Simply stated, we translate data into stories, at scale, that are indistinguishable from articles written by people."
                  },
                  {
                    title: "What is a narrative?",
                    content: (
                      <div>
                        "Narrative" is the term used to describe any content
                        produced by Data Skrive. This could be an article, a
                        social media post, an email, etc.
                      </div>
                    )
                  },
                  {
                    title: "What method is used to deliver narratives?",
                    content: (
                      <div>
                        We offer both Atom and RSS feeds for integration to your
                        Content Management System (CMS).
                      </div>
                    )
                  },
                  {
                    title: "Do you have a sample feed to look at?",
                    content: (
                      <div>
                        Yes. In this example Atom feed, the narrative is an
                        article intended for a web site and called the
                        &lt;entry&gt;.
                        <a href="https://api.dataskrive.com/api/feed/31065?api_token=9cc0fead29594c20bc1afa729066bd21">
                          https://api.dataskrive.com/api/feed/31065?api_token=9cc0fead29594c20bc1afa729066bd21
                        </a>
                      </div>
                    )
                  },
                  {
                    title:
                      "Can I choose between Atom and RSS by changing the HTTP 'Accept' header value?",
                    content:
                      "No, a feed's response type is determined by a Data Skrive server-side setting."
                  },
                  {
                    title:
                      "Do you offer delivery mechanisms other than RSS or Atom XML feeds?",
                    content:
                      "Yes, we have proprietary JSON payloads and even offer email, ftp, or sftp delivery. If one of these is required by your system, please contact your Data Skrive account manager."
                  },
                  {
                    title:
                      "Can I perform an HTTP PUT or POST method to the feed endpoint?",
                    content:
                      "No, the feed can simply be requested via an HTTP GET request."
                  },
                  {
                    title: "What is the 'api_token=' querystring parameter?",
                    content:
                      "The 'api_token' parameter is a required value that provides a measure of security.  Do not share your feed URLs with untrusted sources. 'api_token' values are generated in the Data Skrive Portal and are unique to each Data Skrive Client."
                  },
                  {
                    title:
                      "What tells me if the Atom feed <entry> is new or an update?",
                    content: (
                      <div>
                        There are three elements that create a unique key for an
                        entry &lt;entry&gt; element: <br />
                        <ol>
                          <li>
                            &lt;id&gt; - unique ID for this singular entry
                          </li>
                          <li>
                            &lt;published&gt; - first or initial date/time entry
                            is published
                          </li>
                          <li>
                            &lt;updated&gt; - most recent update date/time to
                            the entry
                          </li>
                        </ol>
                      </div>
                    )
                  },
                  {
                    title:
                      "What if I am getting more than one type of narrative from Data Skrive?",
                    content:
                      "Data Skrive can provide you with one or many data feeds or conversely one or many narratives in a single data feed with the &lt;narrativecategory&gt; as the indication of which article type is associated with the entry."
                  },
                  {
                    title:
                      "What if I want more or customized <category label =…>?",
                    content: (
                      <div>
                        Just ask! We are happy to work with you in providing the
                        categories needed to auto ingest content.
                      </div>
                    )
                  },
                  {
                    title: "How are images delivered?",
                    content: (
                      <div>
                        Images are delivered by way of an anonymous HTTP GET
                        request. Example:{" "}
                        <a href="https://cdn.dataskrive.com/api/asset/7b32hp/render?w=500">
                          https://cdn.dataskrive.com/api/asset/7b32hp/render?w=500
                        </a>
                      </div>
                    )
                  },
                  {
                    title: "Does Data Skrive host images?",
                    content: (
                      <div>
                        The image URLs provided start as hosted on Data Skrive
                        servers. We ask that you download/ingest the image into
                        your system. This ensures users on your website have the
                        best image rendering experience.
                      </div>
                    )
                  },
                  {
                    title: "How do I display a narrative?",
                    content: (
                      <div>
                        There are many plugins or readers available and any can
                        be used effectively. You should work with your technical
                        team/department to determine your best solution. Data
                        Skrive has successfully integrated with the Cyber SEO
                        Pro WordPress Plugin found at:{" "}
                        <a href="https://www.cyberseo.net/premium-plugins/">
                          https://www.cyberseo.net/premium-plugins/
                        </a>
                        , but by no means is this the only solution available.
                      </div>
                    )
                  },
                  {
                    title: "What all is needed after installing a plugin?",
                    content: (
                      <div>
                        After receiving the feed URL from Data Skrive (example
                        above can be used if testing) add the feed title, URL,
                        and the date/time to pull in narratives to your CMS. For
                        savvy tech users, you can use categorization, or even
                        create your own customized solution.
                      </div>
                    )
                  }
                ]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

export default useStore(FAQs)
