import React, { useMemo } from "react"
import { DataGrid } from "@mui/x-data-grid"

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

export default function OfferLinkAffiliateDataGrid({
  linkId,
  availableShortCodes
}) {
  const getRowId = row => `${row.affiliateId}-${row.sourceId}`

  const affiliateColumns = useMemo(() => [
    {
      headerName: "Team/Player/Region",
      field: "name",
      width: 200
    },
    {
      headerName: "Associated Affiliate URL",
      field: "affiliateUrl",
      width: 410
    },
    {
      headerName: "Team/Player/Region ID",
      field: "sourceId",
      width: 190
    }
  ])

  // Only render the Accordion if there are available short codes
  if (!availableShortCodes.length) {
    return null
  }

  return (
    <div>
      <Accordion
        defaultExpanded={false}
        aria-label="CTAV2 AffiliateUrlOffer Accordion"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="CTAV2 AffiliateUrlOffer AccordionSummary"
        >
          <Typography sx={{ fontWeight: 700 }}>
            Associated Affiliate URL Information
          </Typography>
        </AccordionSummary>
        <AccordionDetails aria-label="CTAV2 AffiliateUrlOffer AccordionDetails">
          <div style={{ maxWidth: "800px", margin: "0 100px" }}>
            <DataGrid
              aria-label="CTAV2 AffiliateUrlOffer Datagrid"
              columns={affiliateColumns}
              rows={availableShortCodes}
              getRowId={getRowId}
              pagination
              initialState={{
                sorting: {
                  sortModel: [{ field: "id", sort: "desc" }]
                },
                pagination: { paginationModel: { pageSize: 10 } }
              }}
              pageSizeOptions={(10, 25, 50)}
              sx={{
                "& .MuiDataGrid-columnHeader": { backgroundColor: "#ddeaef" }
              }}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
