import React, { Component } from "react"
import InputLabel from "@mui/material/InputLabel"
import GridItem from "components/Grid/GridItem.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardIcon from "components/Card/CardIcon.jsx"
import BuildIcon from "@mui/icons-material/Build"
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle"
import withStyles from "@mui/styles/withStyles"
import queryString from "query-string"
import ReactJson from "react-json-view"
import CircularProgress from "@mui/material/CircularProgress"
import { globalSettings } from "variables/general.jsx"
import Autocomplete from "components/Autocomplete/Autocomplete"
import TextField from "@mui/material/TextField"
import LinearProgress from "@mui/material/LinearProgress"
import { observer, inject } from "mobx-react"
import { get } from "tools/request"

const ENTER_KEY = 13

const useStore = component =>
  inject(({ store }) => ({
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization
  }))(observer(component))

class MatchOddsTool extends Component {
  constructor(props) {
    super(props)
    const parsedUrl = queryString.parse(props.location.search)
    this.state = {
      matchId: parsedUrl.matchid || 145709,
      bookmakerId: parsedUrl.bookmakerId || 0,
      bookmakerNames: [],
      bettingOddsResponse: `{}`,
      predicationResponse: `{}`,
      isBookmakerNamesLoaded: false,
      isMatchBettingOddsLoaded: false,
      isMatchPredictionLoaded: false
    }
    this.onMatchIdTextAreaKeyDown = this.onMatchIdTextAreaKeyDown.bind(this)
    this.onBookmakerIdTextAreaKeyDown =
      this.onBookmakerIdTextAreaKeyDown.bind(this)
  }

  componentDidMount() {
    this.props.setShowChangeCurrentOrganization(false)
    this.retrieveMatchOdds(this.state.matchId, this.state.bookmakerId)
    this.retrieveMatchPrediction(this.state.matchId)
    this.retrieveBookmakers()
  }

  retrieveBookmakers = () => {
    this.setState({ isBookmakerNamesLoaded: false })
    get(`${globalSettings.apiBaseUrl}/api/MatchOddsSummoner/bookmakers`)
      .then(response => response.json())
      .then(data => {
        let namesFromApi = data.map(bookmaker => ({
          id: bookmaker.id,
          name: bookmaker.name
        }))
        // inline sort by bookmaker name
        namesFromApi.sort((a, b) => {
          // Use toUpperCase() to ignore character casing
          const bookA = a.name.toUpperCase()
          const bookB = b.name.toUpperCase()
          let comparison = 0
          if (bookA > bookB) {
            comparison = 1
          } else if (bookA < bookB) {
            comparison = -1
          }
          return comparison
        })
        this.setState({
          bookmakerNames: [{ id: 0, name: "Choose Bookmaker Name" }].concat(
            namesFromApi
          ),
          isBookmakerNamesLoaded: true
        })
      })
  }

  retrieveMatchOdds = (matchId, bookmakerId) => {
    this.setState({ isMatchBettingOddsLoaded: false })
    get(
      `${globalSettings.apiBaseUrl}/api/MatchOddsSummoner?matchId=${matchId}&bookmakerId=${bookmakerId}`
    )
      .then(response => response.json())
      .then(data => {
        this.setState({
          bettingOddsResponse: data,
          isMatchBettingOddsLoaded: true
        })
      })
  }

  retrieveMatchPrediction = matchId => {
    this.setState({ isMatchPredictionLoaded: false })
    get(
      `${globalSettings.apiBaseUrl}/api/MatchPredictionSummoner?matchId=${matchId}`
    )
      .then(response => response.json())
      .then(data => {
        this.setState({
          predictionResponse: data,
          isMatchPredictionLoaded: true
        })
      })
  }

  onMatchIdTextAreaChange = e => {
    this.setState({ matchId: e.target.value })
  }

  onMatchIdTextAreaKeyDown(e) {
    if (e.ctrlKey && e.keyCode === ENTER_KEY) {
      this.setState({
        bettingOddsResponse: `{}`
      })

      this.retrieveMatchOdds(this.state.matchId, this.state.bookmakerId)
      this.retrieveMatchPrediction(this.state.matchId)
    }
    if (e.keyCode === ENTER_KEY) {
      e.preventDefault()
    }
  }

  onBookmakerIdTextAreaChange = e => {
    this.setState({ bookmakerId: e.target.value })
  }

  onBookmakerIdTextAreaKeyDown(e) {
    if (e.ctrlKey && e.keyCode === ENTER_KEY) {
      this.setState({
        bettingOddsResponse: `{}`
      })
      this.retrieveMatchOdds(this.state.matchId, this.state.bookmakerId)
      this.retrieveMatchPrediction(this.state.matchId)
    }
    if (e.keyCode === ENTER_KEY) {
      e.preventDefault()
    }
  }

  onBookmakerNamesChange = event => {
    this.setState({
      bookmakerId: event.target.value
    })
    this.retrieveMatchOdds(this.state.matchId, event.target.value)
  }

  render() {
    const { classes } = this.props
    const { isBookmakerNamesLoaded } = this.state

    return (
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <BuildIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Match Odds Tool</h4>
          </CardHeader>
          <CardBody>
            <div className="content">
              <form>
                <GridContainer>
                  <GridItem xs={3} sm={3} md={3}>
                    <InputLabel style={{ color: "#AAA" }}>Match Id</InputLabel>
                    <TextField
                      id="matchIdInput"
                      placeholder={"Enter a match id."}
                      value={this.state.matchId}
                      onChange={this.onMatchIdTextAreaChange}
                      onKeyDown={this.onMatchIdTextAreaKeyDown}
                      helperText="(Ctrl-Enter) will retrieve betting odds."
                    />
                  </GridItem>
                  <GridItem xs={3} sm={3} md={3}>
                    <InputLabel style={{ color: "#AAA" }}>
                      Bookmaker Id
                    </InputLabel>
                    <TextField
                      id="bookmakerIdInput"
                      placeholder="Enter a bookmaker id."
                      value={this.state.bookmakerId}
                      onChange={this.onBookmakerIdTextAreaChange}
                      onKeyDown={this.onBookmakerIdTextAreaKeyDown}
                      helperText="(Ctrl-Enter) will retrieve betting odds."
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Bookmaker Lookup
                    </InputLabel>
                    {isBookmakerNamesLoaded && (
                      <Autocomplete
                        value={this.state.bookmakerId}
                        id="bookmakerID"
                        options={this.state.bookmakerNames}
                        onChange={event => {
                          this.setState({
                            bookmakerId: event.target.value
                          })
                          this.retrieveMatchOdds(
                            this.state.matchId,
                            event.target.value
                          )
                        }}
                        getOptionLabel={option =>
                          option ? `${option.id} - ${option.name}` : ""
                        }
                        isOptionEqualToValue={(option, value) =>
                          option && value ? option.key === value.key : false
                        }
                        nameField="name"
                        valueField="id"
                        sortField="name"
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Choose Bookmaker Name"
                          />
                        )}
                        sx={{
                          "& .MuiFormControl-root": {
                            marginTop: 0
                          }
                        }}
                      />
                    )}
                    {!isBookmakerNamesLoaded && (
                      <div
                        style={{
                          position: "relative",
                          padding: "10px 0",
                          bottom: "-13px"
                        }}
                        title="Loading....."
                      >
                        <LinearProgress />
                      </div>
                    )}
                  </GridItem>
                </GridContainer>
                <InputLabel style={{ color: "#AAA" }}>
                  Match Betting Odds Response
                </InputLabel>
                {!this.state.isMatchBettingOddsLoaded && (
                  <CircularProgress
                    style={{
                      position: "relative",
                      top: "50px",
                      left: "200px"
                    }}
                    size={100}
                  />
                )}
                {this.state.isMatchBettingOddsLoaded && (
                  <ReactJson
                    enableClipboard={true}
                    displayObjectSize={false}
                    displayDataTypes={false}
                    src={this.state.bettingOddsResponse}
                    collapsed={false}
                  />
                )}

                <InputLabel style={{ color: "#AAA" }}>
                  Match Prediction Response
                </InputLabel>
                {!this.state.isMatchPredictionLoaded && (
                  <CircularProgress
                    style={{
                      position: "relative",
                      top: "50px",
                      left: "200px"
                    }}
                    size={100}
                  />
                )}
                {this.state.isMatchPredictionLoaded && (
                  <ReactJson
                    enableClipboard={true}
                    displayObjectSize={false}
                    displayDataTypes={false}
                    src={this.state.predictionResponse}
                    collapsed={false}
                  />
                )}
              </form>
            </div>
          </CardBody>
        </Card>
      </GridItem>
    )
  }
}

export default withStyles(regularFormsStyle)(useStore(MatchOddsTool))
