import React, { useEffect, useState } from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import { useLocation } from "react-router-dom"

import makeStyles from "@mui/styles/makeStyles"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import Typography from "@mui/material/Typography"
import { Grid } from "@mui/material"
import Button from "components/CustomButtons/Button"
import { Add } from "@mui/icons-material"

import RealTimeLibraryTable from "components/RealTime/RealTimeLibraryTable"
import RealTimeCreatorForm from "components/RealTime/RealTimeCreatorForm"

const useStyles = makeStyles(() => ({
  libaryBtn: {
    paddingLeft: "16px"
  },
  cardTop: {
    backgroundColor: "#144e68",
    color: "white"
  }
}))

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    getRealTimeConfig: store.appConfigurationStore.getRealTimeConfig,
    displayRealTimeLibrary: store.appConfigurationStore.displayRealTimeLibrary
  }))
}

function RealTimeManager() {
  const location = useLocation()
  const classes = useStyles()

  const { getRealTimeConfig, displayRealTimeLibrary } = useStoreData()
  const [openLibraryFormDialog, setOpenLibraryFormDialog] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [selectedId, setSelectedId] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [selectedLibrary, setSelectedLibrary] = useState(null)

  useEffect(() => {
    const fetchConfig = async () => {
      await getRealTimeConfig()
    }
    fetchConfig()
  }, [displayRealTimeLibrary])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const id = params.get("id")
    setSelectedId(id ? parseInt(id) : null)
  }, [location])

  if (!displayRealTimeLibrary) {
    return "Under Construction"
  }

  const handleOpenLibraryFormClick = () => {
    setOpenLibraryFormDialog(true)
  }
  const handleCloseLibraryFormDialog = () => {
    setOpenLibraryFormDialog(false)
  }

  const handleSaveLibrary = newLibraryId => {
    setSelectedId(newLibraryId)
    setOpenLibraryFormDialog(false)
  }

  return (
    <div>
      <Card>
        <CardHeader className={classes.cardTop}>
          <Typography variant="info" />
          <Button
            className={classes.libaryBtn}
            color="primary"
            onClick={() => handleOpenLibraryFormClick()}
            startIcon={<Add />}
          >
            Add Library
          </Button>
        </CardHeader>
        <Grid className={classes.libaryBtn} />
        <CardBody>
          <RealTimeLibraryTable />
        </CardBody>
        {openLibraryFormDialog && (
          <RealTimeCreatorForm
            mode="create"
            onClose={handleCloseLibraryFormDialog}
            onSave={handleSaveLibrary}
          />
        )}
      </Card>
    </div>
  )
}
export default RealTimeManager
