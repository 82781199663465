import React, { useState, useEffect } from "react"
import makeStyles from "@mui/styles/makeStyles"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Card from "@mui/material/Card"
import { FormControl, MenuItem, TextField } from "@mui/material"

import { globalSettings } from "variables/general.jsx"
import { get } from "tools/request"

const useStyles = makeStyles({
  table: {
    minWidth: 400,
    padding: "9px 9px"
  },
  formControl: {
    marginLeft: "9px",
    marginTop: "9px",
    display: "flex",
    alignItems: "end",
    labelPlacement: "end"
  },
  labelColor: {
    backgroundColor: "",
    paddingRight: "9px",
    display: "flex",
    alignItems: "end"
  },
  styleTableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#DDEAEF"
    }
  }
})
const status = [
  {
    value: 1,
    label: "Last 24 hours"
  },
  {
    value: 7,
    label: "Last 7 days"
  },
  {
    value: 30,
    label: "Last 30 days"
  },
  {
    value: 90,
    label: "Last 3 months"
  },
  {
    value: 180,
    label: "Last 6 months"
  }
]

export default function ClientStatsTable() {
  const [tableData, setTableData] = useState([])
  const [value, setValue] = useState(1)
  useEffect(() => {
    get(`${globalSettings.apiBaseUrl}/api/portalmetrics/clientmetrics/${value}`)
      .then(resp => resp.json())
      .then(data => {
        setTableData(data)
      })
      .catch(err => {
        console.error(
          `Error on GET to /api/portalmetrics/clientmetrics/${value}`,
          err
        )
      })
  }, [value])

  const classes = useStyles()

  return (
    <Card>
      <FormControl variant="outlined" className={classes.formControl}>
        <TextField
          className={classes.labelColor}
          value={value}
          onChange={e => setValue(e.target.value)}
          select
          variant="outlined"
          label="TimeDate"
          size="small"
        >
          {status.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
      {tableData?.metrics?.length ? (
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Client Stats</TableCell>
                <TableCell align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.metrics.map((row, index) => (
                <TableRow className={classes.styleTableRow} key={index}>
                  <TableCell component="th" scope="row">
                    {row.metricTitle}
                  </TableCell>
                  <TableCell align="right">{row.metricValue}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        "Loading..."
      )}
    </Card>
  )
}
