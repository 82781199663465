import React from "react"
import ReactTable from "react-table-6"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import {
  Tooltip,
  IconButton,
  Checkbox,
  FormControlLabel,
  Radio
} from "@mui/material"
import { Edit } from "@mui/icons-material"
import { NarrativeContext } from "contexts/narrative-context"
import CircularProgress from "@mui/material/CircularProgress"
import { groupCollectionBy } from "tools/CollectionHelper"

class NarrativeBlockSnapshots extends React.Component {
  static contextType = NarrativeContext

  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      sentencesOnly: false,
      paragraphsOnly: !props.filterErrors && true,
      errorsOnly: !!props.filterErrors,
      renderBlocks: true,
      showTemplate: false,
      showResult: true
    }
  }

  intFilterProps(propName, properties) {
    return {
      filterMethod: (filter, row) => {
        let currentValue = row._original[propName] || 0
        if (filter.value === "all") {
          return true
        }
        if (filter.value === ">0") {
          return currentValue > 0
        }
        if (filter.value === "=0") {
          return currentValue === 0
        }
        // NOTE: string versus integer comparison,
        // don't use === operator
        // eslint-disable-next-line eqeqeq
        return row._original[propName] == filter.value
      },
      Filter: ({ filter, onChange }) => {
        if (properties.size === 0) {
          return null
        }

        const distinctOptions = Object.keys(
          groupCollectionBy(properties, p => p[propName])
        ).sort((a, b) => {
          let comparison = 0
          if (Number(a) > Number(b)) {
            comparison = 1
          } else if (Number(a) < Number(b)) {
            comparison = -1
          }
          return comparison
        })
        // build up the <select> element <option> element JSX style
        let options = []
        distinctOptions.forEach(opt => {
          options.push(
            <option value={opt} key={opt}>
              {opt}
            </option>
          )
        })
        return (
          <select
            onChange={event => {
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">All</option>
            {options}
          </select>
        )
      }
    }
  }

  stringFilterProps(propName, properties) {
    return {
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true
        }
        // NOTE: string versus integer comparison,
        // don't use === operator
        // eslint-disable-next-line eqeqeq
        return row._original[propName] == filter.value
      },
      Filter: ({ filter, onChange }) => {
        if (properties.size === 0) {
          return null
        }

        const distinctOptions = Object.keys(
          groupCollectionBy(properties, p => p[propName])
        ).sort((a, b) => {
          // Use toUpperCase() to ignore character casing
          const orgA = a.toUpperCase()
          const orgB = b.toUpperCase()
          let comparison = 0
          if (orgA > orgB) {
            comparison = 1
          } else if (orgA < orgB) {
            comparison = -1
          }
          return comparison
        })
        // build up the <select> element <option> element JSX style
        let options = []
        distinctOptions.forEach(opt => {
          options.push(
            <option value={opt} key={opt}>
              {opt}
            </option>
          )
        })
        return (
          <select
            onChange={event => {
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">All</option>
            {options}
          </select>
        )
      }
    }
  }

  boolFilterProps(propName) {
    return {
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true
        }
        if (filter.value === "true") {
          return row._original[propName] === true
        }
        if (filter.value === "false") {
          return row._original[propName] === false
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value="false">No</option>
          <option value="true">Yes</option>
        </select>
      )
    }
  }

  checkForItemIndex(search) {
    search = search.replace(/^\?/, "")
    let vals = search.split("&")
    let itemIndx = ""
    vals.forEach(itm => {
      if (itm.match(/itemindex=/)) {
        itemIndx = `&${itm}`
      }
    })
    return itemIndx
  }

  render() {
    const { showResult, showTemplate } = this.state

    if (!this.props.snapshots) {
      return null
    }
    let snapshots = this.props.snapshots
    if (this.state.sentencesOnly) {
      snapshots = this.props.snapshots.filter(
        snapshot => snapshot.sentence_Id !== null
      )
    }
    if (this.state.paragraphsOnly) {
      snapshots = this.props.snapshots.filter(
        snapshot => snapshot.sentence_Id === null
      )
    }
    if (this.state.errorsOnly) {
      snapshots = this.props.snapshots.filter(
        snapshot =>
          snapshot.failedValidation ||
          snapshot.hasTemplateErrors ||
          snapshot.hasScriptErrors
      )
    }
    let pageSize = this.props.pageSize || 25

    let addItemIndex = this.checkForItemIndex(this.props.location.search)

    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start"
          }}
        >
          <FormControlLabel
            control={
              <Radio
                checked={this.state.paragraphsOnly || false}
                onChange={event => {
                  let paragraphsOnly = event.target.checked
                  if (paragraphsOnly) {
                    this.setState({
                      sentencesOnly: false,
                      paragraphsOnly,
                      errorsOnly: false
                    })
                  }
                }}
              />
            }
            label="Paragraphs Only"
          />
          <FormControlLabel
            control={
              <Radio
                checked={this.state.sentencesOnly || false}
                onChange={event => {
                  let sentencesOnly = event.target.checked
                  if (sentencesOnly) {
                    this.setState({
                      sentencesOnly,
                      paragraphsOnly: false,
                      errorsOnly: false
                    })
                  }
                }}
              />
            }
            label="Sentences Only"
          />
          <FormControlLabel
            control={
              <Radio
                checked={this.state.errorsOnly || false}
                onChange={event => {
                  let errorsOnly = event.target.checked
                  if (errorsOnly) {
                    this.setState({
                      sentencesOnly: false,
                      paragraphsOnly: false,
                      errorsOnly
                    })
                  }
                }}
              />
            }
            label="Errors Only"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showResult}
                onChange={event => {
                  this.setState({
                    showResult: event.target.checked
                  })
                }}
              />
            }
            label="Show Output?"
          />
          {showResult && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.renderBlocks || false}
                  onChange={event => {
                    this.setState({
                      renderBlocks: event.target.checked
                    })
                  }}
                />
              }
              label="Render HTML?"
            />
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={showTemplate}
                onChange={event => {
                  this.setState({
                    showTemplate: event.target.checked
                  })
                }}
              />
            }
            label="Show Template?"
          />
        </div>
        <ReactTable
          data={snapshots}
          noDataText={
            this.props.isLoading ? <CircularProgress /> : "No blocks found."
          }
          filterable
          SubComponent={data => (
            <div style={{ padding: "20px" }}>
              <h6>Template</h6>
              <div>{data.original.template}</div>
              <h6>Output</h6>
              <div>{data.original.renderedString}</div>
              <h6>Validation Error Messages</h6>
              <div>{data.original.validationMessage}</div>
              <h6>Scriban Template Error Messages</h6>
              <div>{data.original.templateErrorMessage}</div>
              <h6>Script Error Messages</h6>
              <div>{data.original.scriptErrorMessage}</div>
            </div>
          )}
          defaultSorted={[
            {
              id: "position",
              desc: false
            },
            {
              id: "triggerType",
              desc: false
            },
            {
              id: "id",
              desc: false
            }
          ]}
          columns={[
            {
              Header: "Paragraph",
              accessor: "paragraph_Id",
              Cell: ({ row }) => (
                <Link
                  to={`/portal/narrative/${row._original.narrative_Id}/edit?view=paragraphid-${row._original.paragraph_Id}${addItemIndex}`}
                >
                  <Tooltip
                    id="tooltip-top-start"
                    title="View Paragraph"
                    placement="top"
                  >
                    <IconButton
                      style={{ padding: 0, margin: 0 }}
                      aria-label="View Paragraph"
                      size="large"
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  &nbsp;{row._original.paragraph_Id}
                </Link>
              ),
              ...this.intFilterProps("paragraph_Id", snapshots)
            },
            {
              Header: "Sentence",
              accessor: "sentence_Id",
              Cell: ({ row }) => {
                if (row._original.sentence_Id != null) {
                  return (
                    <Link
                      to={`/portal/narrative/${row._original.narrative_Id}/edit?view=sentenceid-${row._original.sentence_Id}${addItemIndex}`}
                    >
                      <Tooltip
                        id="tooltip-top-start"
                        title="View Sentence"
                        placement="top"
                      >
                        <IconButton
                          style={{ padding: 0, margin: 0 }}
                          aria-label="View Sentence"
                          size="large"
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                      &nbsp;{row._original.sentence_Id}
                    </Link>
                  )
                } else {
                  return null
                }
              },
              ...this.intFilterProps("sentence_Id", snapshots)
            },
            {
              Header: "Status",
              id: "status",
              show: this.state.sentencesOnly,
              Cell: ({ row }) => row._original.status_Name,
              width: 130,
              ...this.intFilterProps("status_Name", snapshots)
            },
            {
              Header: "Output",
              id: "renderedString",
              Cell: ({ row }) => {
                if (this.state.renderBlocks) {
                  return (
                    <div
                      style={{ textAlign: "left" }}
                      dangerouslySetInnerHTML={{
                        __html:
                          row._original.renderedString &&
                          row._original.renderedString.replace(/apxh:/g, "")
                      }}
                    />
                  )
                }
                return row._original.renderedString
              },
              width: 500,
              show: showResult
            },
            {
              Header: "Template",
              id: "template",
              Cell: ({ row }) => row._original.template,
              width: 600,
              show: showTemplate
            },
            {
              Header: "Element",
              accessor: "contentBlock",
              ...this.stringFilterProps("contentBlock", snapshots),
              width: 130
            },
            {
              Header: "Position",
              accessor: "position",
              ...this.intFilterProps("position", snapshots)
            },
            {
              Header: "Trigger",
              accessor: "triggerType",
              width: 200,
              ...this.stringFilterProps("triggerType", snapshots)
            },
            {
              Header: "Version",
              accessor: "feedEntry_VersionNumber",
              ...this.intFilterProps("feedEntry_VersionNumber", snapshots)
            },
            {
              Header: "Has Errors?",
              id: "hasErrors",
              accessor: "",
              width: 150,
              Cell: ({ row }) => (
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={row._original.hasErrors}
                  readOnly
                />
              ),
              ...this.boolFilterProps("hasErrors")
            },
            {
              Header: "Validation Error?",
              id: "failedValidation",
              accessor: "",
              width: 150,
              Cell: ({ row }) => (
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={row._original.failedValidation}
                  readOnly
                />
              ),
              ...this.boolFilterProps("failedValidation")
            },
            {
              Header: "Validation Message",
              accessor: "validationMessage",
              ...this.stringFilterProps("validationMessage", snapshots),
              width: 200
            },
            {
              Header: "Template Error?",
              id: "HasTemplateErrors",
              accessor: "",
              width: 150,
              Cell: ({ row }) => (
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={row._original.hasTemplateErrors}
                  readOnly
                />
              ),
              ...this.boolFilterProps("hasTemplateErrors")
            },
            {
              Header: "Error Message",
              accessor: "templateErrorMessage",
              ...this.stringFilterProps("templateErrorMessage", snapshots),
              width: 200
            },
            {
              Header: "Script Error?",
              id: "hasScriptErrors",
              accessor: "",
              width: 150,
              Cell: ({ row }) => (
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={row._original.hasScriptErrors}
                  readOnly
                />
              ),
              ...this.boolFilterProps("hasScriptErrors")
            },
            {
              Header: "Error Message",
              accessor: "scriptErrorMessage",
              ...this.stringFilterProps("scriptErrorMessage", snapshots),
              width: 200
            }
          ]}
          defaultPageSize={pageSize}
          showPaginationTop
          showPaginationBottom={true}
          className="-striped -highlight -compact"
        />
      </div>
    )
  }
}

NarrativeBlockSnapshots.propTypes = {
  // Where the user to be redirected on clicking the avatar
  snapshots: PropTypes.arrayOf(PropTypes.object),
  pageSize: PropTypes.number,
  embedded: PropTypes.bool
}

export default withRouter(NarrativeBlockSnapshots)
