import React from "react"
import Check from "@mui/icons-material/CheckCircleOutline"
import Report from "@mui/icons-material/Report"

function ShowCheckOrStop(props) {
  return (
    <>
      {!props.clearValue && props.valueIsValid ? (
        <Check
          style={{
            color: "#66bb6a",
            width: props.size,
            height: props.size
          }}
        />
      ) : !props.clearValue && !props.valueIsValid ? (
        <Report
          style={{
            color: "#a31545",
            width: props.size,
            height: props.size
          }}
        />
      ) : null}
    </>
  )
}

export default ShowCheckOrStop
