import React from "react"
import withStyles from "@mui/styles/withStyles"
import Grid from "@mui/material/Grid"

const style = {
  grid: {
    padding: "0 15px"
  },
  customGrid: {
    paddingBottom: "16px",
    paddingRight: "15px",
    paddingLeft: "15px"
  }
}

function GridItem({ classes, customPadding, children, className, ...rest }) {
  const gridClass = customPadding ? classes.customGrid : classes.grid
  return (
    <Grid item {...rest} className={`${gridClass} ${className}`}>
      {children}
    </Grid>
  )
}

export default withStyles(style)(GridItem)
