import React from "react"
import TimeAgo from "react-timeago"
import PropTypes from "prop-types"
import { Typography } from "@mui/material"
import Helpers from "../tools/Helpers"
import "assets/css/Components/AuditInfo.css"

const defaultProps = {
  createOn: "",
  modifiedOn: "",
  creator: {},
  modifier: {},
  muted: false
}

const propTypes = {
  createdOn: PropTypes.string,
  modifiedOn: PropTypes.string,
  creator: PropTypes.object,
  modifier: PropTypes.object,
  muted: PropTypes.bool
}

const AuditInfo = props => {
  const { createdOn, modifiedOn, creator, modifier, muted } = props
  const { username: creatorUsername } = creator
  const { username: modifierUsername } = modifier
  if (!createdOn) {
    return <span />
  }

  return (
    <Typography
      className="audit-info"
      component="div"
      color={muted ? "textSecondary" : "textPrimary"}
      display="block"
      variant="body2"
      noWrap
    >
      <div className="audit-info__created">
        <span>created</span>
        <TimeAgo
          date={createdOn}
          title={Helpers.prettyDateTimeinPacificTimeZone(createdOn)}
        />
        <span>by {creatorUsername}</span>
      </div>
      {modifiedOn > createdOn && (
        <div className="audit-info__modified">
          <span>modified</span>
          <TimeAgo
            date={modifiedOn}
            title={Helpers.prettyDateTimeinPacificTimeZone(modifiedOn)}
          />
          <span>by {modifierUsername}</span>
        </div>
      )}
    </Typography>
  )
}

AuditInfo.defaultProps = defaultProps
AuditInfo.propTypes = propTypes

export default AuditInfo
