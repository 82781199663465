import { globalSettings } from "variables/general.jsx"
import { get, put, del } from "../tools/request"

export default class DatasourceDAO {
  static getOrganizationDatasources = async () => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/OrganizationDataSource/`
    )
    return await res.json()
  }
  static createOrUpdateOrganizationDatasources = async (body, type) => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/OrganizationDataSource/${type}`,
      null,
      body
    )
    return await res.json()
  }
  static deleteOrganizationDatasources = async id => {
    const res = await del(
      `${globalSettings.apiBaseUrl}/api/OrganizationDataSource/${id}`
      //`${globalSettings.apiBaseUrl}/api/OrganizationDataSource/${id}?organizationDataSourceId=${id}`
    )
    return await res.json()
  }
}
