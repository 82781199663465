// eslint-disable-next-line no-unused-vars
import React from "react"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "none"
  }
}))

const NarrativeOutlineBlockView = props => {
  const classes = useStyles()

  const { block, fontSize } = props

  return (
    <div
      title={`${block.name} Sample Result`}
      className={classes.root}
      style={{
        fontSize: `${fontSize || 100}%`
      }}
      dangerouslySetInnerHTML={{
        __html: block && block.sampleResult.replace(/apxh:/g, "")
      }}
    />
  )
}

export default NarrativeOutlineBlockView
