import Typography from "@mui/material/Typography"

function LinkOfferVariantBulkUploadInfo(bulkUploadList) {
  return (
    <>
      <Typography variant="h5" gutterBottom>
        Link Offer Variant Upload Information
      </Typography>
      <Typography align="left" gutterBottom>
        <b>An example Link Offer Variant Bulk Upload template </b>
        <a
          href="https://docs.google.com/spreadsheets/d/1dlgZL2NadW8ouwMGf7-yGxwbpBYuBmi4FWHoXT8FRyU/edit?usp=sharing"
          rel="noreferrer"
          target="_blank"
        >
          <u>
            <b>can be found here.</b>
          </u>
        </a>
        {"  "}
        These will need to be made into a TSV file.
        <br />
        <br />
      </Typography>
      <Typography align="left">
        <u>
          <b>Bulk Upload requirements:</b>
        </u>
        A bulk upload entry will only validate if all mandatory fields are
        included. These fields are:
      </Typography>
      <ul className={bulkUploadList}>
        <li>Variant Name</li>
        <li>Offer Link ID</li>
        <li>Priority </li>
        <li>
          Vertical
          <ul>
            <li>League only applicable if Sports vertical is chosen</li>
          </ul>
        </li>
        <li>League</li>
        <li>Display Style</li>
        <li>Template</li>
      </ul>
    </>
  )
}
export default LinkOfferVariantBulkUploadInfo
