import { useState, useEffect } from "react"
import PropTypes from "prop-types"
import withStyles from "@mui/styles/withStyles"
import {
  Grid,
  TextField,
  FormControl,
  DialogActions,
  LinearProgress,
  DialogTitle,
  Tooltip,
  FormHelperText,
  Modal,
  Checkbox,
  FormControlLabel
} from "@mui/material"
import Button from "components/CustomButtons/Button"
import GridItem from "components/Grid/GridItem"
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog"
import { globalSettings } from "variables/general"
import Autocomplete from "@mui/material/Autocomplete"

import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import OrganizationDAO from "daos/organizationDAO"
import { get, put } from "tools/request"
import CustomDSDialog from "components/CustomDialogs/CustomDSDialog"
import { Info } from "@mui/icons-material"
import Cta_v2DAO from "daos/cta_v2DAO"

const styles = theme => ({
  successModal: {
    position: "absolute",
    color: "#069",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    transform: "translate(-45%, -45%)",
    "& h3": {
      fontWeight: "bold"
    },
    "& ul": {
      padding: "5px 0 0 5px",
      fontWeight: "bold"
    },
    [theme.breakpoints.up(701)]: {
      width: 400,
      top: "45%",
      left: "45%"
    },
    [theme.breakpoints.down(700)]: {
      width: "70vw !important",
      top: "50vh !important",
      left: "50vw !important"
    }
  },
  regularSave: {
    top: "50%",
    left: "50%",
    width: "30%"
  },
  deActivateSave: {
    top: "50%",
    left: "50%",
    width: "60%"
  },
  result: {
    textDecoration: "underline",
    color: "#c00"
  },
  resultModal: {
    textDecoration: "underline"
  },
  myFlex: {
    [theme.breakpoints.down(700)]: {
      display: "flex",
      flexDirection: "column",
      "& .MuiGrid-grid-sm-3": {
        maxWidth: "auto"
      },
      "& .MuiGrid-grid-sm-2": {
        maxWidth: "auto"
      }
    },
    [theme.breakpoints.up(701)]: {
      display: "flex",
      flexDirection: "row"
    }
  },

  myFlexInputWidth: {
    [theme.breakpoints.down(700)]: {
      minWidth: "150px"
    }
  },

  myFlexFontSize: {
    [theme.breakpoints.down(700)]: {
      padding: "4px 0 0 0",
      "& h2": {
        fontSize: "1.25em"
      }
    }
  },
  changeLabel: {
    [theme.breakpoints.down(700)]: {
      color: "#212529",
      margin: "0"
    },
    [theme.breakpoints.up(700)]: {
      color: "#212529",
      marginTop: "20px"
    }
  },
  margin45: {
    [theme.breakpoints.down(700)]: {
      margin: "0"
    },
    [theme.breakpoints.up(700)]: {
      marginTop: "45px"
    }
  },
  selectControl: {
    [theme.breakpoints.down("700")]: {
      minWidth: "90%",
      marginLeft: "0px"
    },
    [theme.breakpoints.up("700")]: {
      minWidth: "18vw",
      marginLeft: "0px"
    },
    [theme.breakpoints.up("960")]: {
      minWidth: "20vw",
      marginLeft: "0px"
    },
    [theme.breakpoints.up("1200")]: {
      minWidth: "20vw",
      marginLeft: "0px"
    }
  },
  selectControlBookmaker1: {
    [theme.breakpoints.down("700")]: {
      minWidth: "90%",
      marginLeft: "0px"
    },
    [theme.breakpoints.up("700")]: {
      minWidth: "18vw",
      marginTop: "-15px",
      marginLeft: "0px"
    },
    [theme.breakpoints.up("960")]: {
      minWidth: "20vw",
      marginTop: "-15px",
      marginLeft: "0px"
    },
    [theme.breakpoints.up("1200")]: {
      minWidth: "20vw",
      marginTop: "-15px",
      marginLeft: "0px"
    }
  },
  requiredMsg: {
    color: "#c00",
    fontWeight: "bold",
    backgroundColor: "#f7f7f7"
  },
  progessDone: {
    border: "2px solid #BBDDEE"
  },
  errorModal: {
    position: "absolute",
    color: "#c00",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    transform: "translate(-45%, -45%)",
    "& h3": {
      fontWeight: "bold"
    },
    "& ul": {
      padding: "5px 0 0 5px",
      fontWeight: "bold",
      textDecoration: "underline"
    },
    [theme.breakpoints.up(701)]: {
      width: 400,
      top: "45%",
      left: "45%"
    },
    [theme.breakpoints.down(700)]: {
      width: "70vw !important",
      top: "50vh !important",
      left: "50vw !important"
    }
  }
})

function UserStoreData() {
  const store = useStore()
  return useObserver(() => ({
    getPermissions: store.accountStore.getPermissions
  }))
}
function OrganizationEditor(props) {
  const {
    isAddingOrganization,
    classes,
    organizations,
    organization,
    currentParent,
    datasourcesByOrg,
    bookmakersByOrg,
    backToOrgList,
    isNowLoading,
    setUpdatedOrganization
  } = props

  const { getPermissions } = UserStoreData()

  let datasourcesForCurrentOrg = {}
  //The code below looks for the datasources from our organization, then saves them as:
  //1: an Array of object fpr full display and testing (datasourcesForCurrentOrg.sportsDataNew then becomes organization.sportsDataNew)
  //2: an Array with just the id for identifying the default value to be passed into the MultiselectAutocompelete components (datasourcesForCurrentOrg.sportsDataNew_ then becomes organization.sportsDataOrig)
  datasourcesByOrg &&
    Object.keys(datasourcesByOrg).forEach(function (itm) {
      if (Number(itm) === organization.id) {
        Object.keys(datasourcesByOrg[itm]).forEach(function (itm2) {
          datasourcesByOrg[itm][itm2].forEach(itm3 => {
            switch (itm2) {
              case "bettingDataNew":
                datasourcesForCurrentOrg["bettingDataFromDB"] =
                  datasourcesForCurrentOrg["bettingDataFromDB"] || []
                datasourcesForCurrentOrg["bettingDataFromDB"].push({
                  id: itm3.id
                })
                break
              case "sportsDataNew":
                datasourcesForCurrentOrg["sportsDataFromDB"] =
                  datasourcesForCurrentOrg["sportsDataFromDB"] || []
                datasourcesForCurrentOrg["sportsDataFromDB"].push({
                  id: itm3.id
                })
                break
              case "imageDataNew":
                datasourcesForCurrentOrg["imageDataFromDB"] =
                  datasourcesForCurrentOrg["imageDataFromDB"] || []
                datasourcesForCurrentOrg["imageDataFromDB"].push({
                  id: itm3.id
                })
                break
              default:
            }
          })
        })
      }
    })
  let bookmakersForCurrentOrg = {}
  bookmakersByOrg &&
    Object.keys(bookmakersByOrg).forEach(function (itm) {
      if (Number(itm) === organization.id) {
        Object.keys(bookmakersByOrg[itm]).forEach(function (itm2) {
          bookmakersByOrg[itm][itm2].forEach(itm3 => {
            switch (itm2) {
              case "bookmakersDataNew":
                bookmakersForCurrentOrg["bookmakerFromDB"] =
                  bookmakersForCurrentOrg["bookmakerFromDB"] || []
                bookmakersForCurrentOrg["bookmakerFromDB"].push({
                  id: itm3.id
                })
                break
              default:
            }
          })
        })
      }
    })
  const newOrganization = {
    ...organization,
    sportsDataFromDB:
      (datasourcesForCurrentOrg && datasourcesForCurrentOrg.sportsDataFromDB) ||
      [],
    bettingDataFromDB:
      (datasourcesForCurrentOrg &&
        datasourcesForCurrentOrg.bettingDataFromDB) ||
      [],
    imageDataFromDB:
      (datasourcesForCurrentOrg && datasourcesForCurrentOrg.imageDataFromDB) ||
      [],
    bookmakerFromDB:
      (bookmakersForCurrentOrg && bookmakersForCurrentOrg.bookmakerFromDB) ||
      [],
    isDeliverableRequired: isAddingOrganization
      ? true
      : organization.isDeliverableRequired,
    isInternal: organization?.isInternal ?? false,
    addGaTag: organization?.addGaTag ?? false,
    gaTagId: organization?.gaTagId ?? ""
  }

  const [myOrganization, setMyOrganization] = useState(newOrganization)
  const [isSaving, setIsSaving] = useState(false)
  const [isSavingOrganization, setIsSavingOrganization] = useState(false)
  const [noChangesFound, setNoChangesFound] = useState(false)
  const [isSavingContent, setIsSavingContent] = useState(false)
  const [organizationUpdated, setOrganizationUpdated] = useState(false)
  const [msaUrlHasError, setMsaUrlHasError] = useState(false)
  const [webSiteUrlHasError, setWebSiteUrlHasError] = useState(false)
  const [isConfirmUpdateAllVariantsOpen, setIsConfirmUpdateAllVariantsOpen] =
    useState(false)
  const [shouldUpdateAllCtas, setShouldUpdateAllCtas] = useState(false)
  const [
    variantClickUpdateSuccessModalMessage,
    setVariantClickUpdateSuccessModalMessage
  ] = useState("")
  const [updateVariantOnclicksCompleted, setUpdateVariantOnclicksCompleted] =
    useState(false)

  const [organizationNameHasError, setOrganizationNameHasError] =
    useState(false)
  const [confirmInactiveOpen, setConfirmInactiveOpen] = useState(false)
  const [confirmInactiveSave, setConfirmInactiveSave] = useState(false)
  const [organizationActiveInDB] = useState(organization.isActive)
  const [parentIdHasError, setParentIdHasError] = useState(false)

  const [successMessage, setSuccessMessage] = useState("")
  const [removedContracts, setRemovedContracts] = useState([])
  const [removedProperties, setRemovedProperties] = useState([])

  const [resetOrganizationInfo, setResetOrganizationInfo] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const [gaTagHasError, setGaTagHasError] = useState(false)

  const {
    id,
    isActive,
    name,
    msaUrl,
    msaDate,
    webSiteUrl,
    turnIntoProperty,
    editingOrganization,
    editingDataSources,
    isDeliverableRequired,
    isInternal,
    addGaTag,
    gaTagId
  } = myOrganization
  const organizationDatasources = myOrganization.organizationDatasources || []
  const bookmakersDatasources = myOrganization.organizationBookmakers || []

  const handleClose = () => {
    const prevOrganiation = {
      ...organization,
      sportsDataFromDB:
        (datasourcesForCurrentOrg &&
          datasourcesForCurrentOrg.sportsDataFromDB) ||
        [],
      bettingDataFromDB:
        (datasourcesForCurrentOrg &&
          datasourcesForCurrentOrg.bettingDataFromDB) ||
        [],
      imageDataFromDB:
        (datasourcesForCurrentOrg &&
          datasourcesForCurrentOrg.imageDataFromDB) ||
        [],
      bookmakerFromDB:
        (bookmakersForCurrentOrg && bookmakersForCurrentOrg.bookmakerFromDB) ||
        []
    }
    setMyOrganization(prevOrganiation)
  }

  const handleActiveOrgCheck = checked => {
    const status = checked ? "Active" : "Inactive"
    if (status === "Inactive" && organizationActiveInDB) {
      setConfirmInactiveOpen(true)
    } else {
      const newOrganization = {
        ...myOrganization,
        isActive: checked,
        Status: status,
        editingOrganization: true
      }
      setMyOrganization(newOrganization)
      setConfirmInactiveOpen(false)
    }
  }

  const inactivateOrg = () => {
    const newOrganization = {
      ...myOrganization,
      isActive: false,
      Status: "Inactive",
      editingOrganization: true
    }
    setMyOrganization(newOrganization)
  }

  const urlValidation = val => {
    const regexp =
      /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
    const isUrl = regexp.test(String(val).toLowerCase())
    return isUrl
  }

  const checkUrlValidation = (event, name) => {
    const isUrl = urlValidation(event.target.value)
    setMsaUrlHasError(!isUrl && !!event.target.value)
    return isUrl || !event.target.value
  }

  const onDateChange = name => event => {
    const newOrganization = {
      ...myOrganization,
      [name]: event.target.value,
      editingOrganization: true
    }
    setMyOrganization(newOrganization)
  }

  const handleCellChange = name => event => {
    const newOrganization = {
      ...myOrganization,
      [name]: event.target.value,
      editingOrganization: true
    }
    setMyOrganization(newOrganization)
  }

  const handleCheckboxChange = name => event => {
    if (name === "addGaTag") {
      if (event.target.checked && myOrganization.id) {
        setIsConfirmUpdateAllVariantsOpen(true)
      } else {
        setShouldUpdateAllCtas(false)
      }
    }

    const newOrganization = {
      ...myOrganization,
      [name]: event.target.checked,
      editingOrganization: true
    }
    setMyOrganization(newOrganization)
  }

  const handleMultiAuto = (event, newValue, reason, forArea) => {
    const mod = {
      bookmakerFromDB: "bookmakerDataChange",
      imageDataFromDB: "imageDataChange",
      bettingDataFromDB: "bettingDataChange",
      sportsDataFromDB: "sportsDataChange"
    }
    const newOrganization = {
      ...myOrganization,
      disableSaveButton: false,
      [mod[forArea]]: newValue,
      editingOrganization: true
    }
    setMyOrganization(newOrganization)
  }

  const handleSingleAuto = (event, newValue, reason, forArea) => {
    const newOrganization = {
      ...myOrganization,
      disableSaveButton: false,
      [forArea]: newValue?.id,
      editingOrganization: true
    }

    if (forArea === "parentId" && newOrganization.parentId) {
      const parentOrg = props.organizations.find(o => o?.id === newValue?.id)
      newOrganization.isInternal = parentOrg.isInternal
      newOrganization.addGaTag = parentOrg.addGaTag
    }
    setMyOrganization(newOrganization)
  }

  const inactivatePropertiesForOrg = (id, respMsg, respMsg2) => {
    get(
      `${globalSettings.apiBaseUrl}/api/organization/inactivate/properties?organizationId=${id}`
    )
      .then(Response => Response.json())
      .then(response => {
        if (response.content[0] || response.responseCode === 1000) {
          setSuccessMessage(respMsg)
          setRemovedContracts(respMsg2)
          setRemovedProperties(response.content)
          setOrganizationUpdated(true)
        }
      })
      .catch(err => {
        console.error(
          "Error on GET to /api/organization/inactivate/properties?organizationId={id}",
          err
        )
      })
  }

  const inactivateContractsForOrg = (id, respMsg) => {
    get(
      `${globalSettings.apiBaseUrl}/api/organization/inactivate/contract?organizationId=${id}`
    )
      .then(Response => Response.json())
      .then(response => {
        if (response.content[0] || response.responseCode === 1000) {
          inactivatePropertiesForOrg(id, respMsg, response.content)
        }
      })
      .catch(err => {
        console.error(
          "Error on GET to /api/organization/inactivate/contract?organizationId={id}",
          err
        )
      })
  }

  const PopulateDataSources = () => {
    let theSources = []
    let bettingS = []
    let imageS = []
    let sportsS = []

    myOrganization["sportsDataChange"] &&
      myOrganization["sportsDataChange"].forEach(itm => {
        let obj = {}
        obj.datasourceTypeId = 1
        obj.datasourceId = itm.id
        obj.id = 0
        sportsS.push(obj)
      })
    !myOrganization["sportsDataChange"] &&
      sportsS.length === 0 &&
      organizationDatasources.forEach(itm => {
        if (itm.datasourceTypeId === 1) {
          let obj = itm
          sportsS.push(obj)
        }
      })

    myOrganization["bettingDataChange"] &&
      myOrganization["bettingDataChange"].forEach(itm => {
        let obj = {}
        obj.datasourceTypeId = 2
        obj.datasourceId = itm.id
        bettingS.push(obj)
      })
    !myOrganization["bettingDataChange"] &&
      bettingS.length === 0 &&
      organizationDatasources.forEach(itm => {
        if (itm.datasourceTypeId === 2) {
          let obj = itm
          bettingS.push(obj)
        }
      })

    myOrganization["imageDataChange"] &&
      myOrganization["imageDataChange"].forEach(itm => {
        let obj = {}
        obj.datasourceTypeId = 3
        obj.datasourceId = itm.id
        imageS.push(obj)
      })
    !myOrganization["imageDataChange"] &&
      imageS.length === 0 &&
      organizationDatasources.forEach(itm => {
        if (itm.datasourceTypeId === 3) {
          let obj = itm
          imageS.push(obj)
        }
      })

    const allChanges = sportsS.concat(bettingS, imageS)
    allChanges.forEach(itm => {
      let existing = false
      organizationDatasources.forEach(fromDB => {
        if (
          itm.datasourceId === fromDB.datasourceId &&
          itm.datasourceTypeId === fromDB.datasourceTypeId
        ) {
          existing = true
          itm.indexId = fromDB.id
        }
      })
      let obj = {}
      obj.datasourceId = itm.datasourceId
      obj.datasourceTypeId = itm.datasourceTypeId
      if (existing) {
        obj.id = itm.indexId
      }
      theSources.push(obj)
    })
    return theSources
  }

  const PopulateBookmakers = () => {
    let bookmakerS = []
    myOrganization["bookmakerDataChange"] &&
      myOrganization["bookmakerDataChange"].forEach(itm => {
        let obj = {}
        obj.bookmakerId = itm.id
        bookmakerS.push(obj)
      })
    !myOrganization["bookmakerDataChange"] &&
      bookmakerS.length === 0 &&
      bookmakersDatasources.forEach(itm => {
        let obj = itm
        bookmakerS.push(obj)
      })
    return bookmakerS
  }

  const setUpdateCTAFlag = performUpdate => {
    setShouldUpdateAllCtas(performUpdate)
    setIsConfirmUpdateAllVariantsOpen(false)
  }

  const handleOrganizationSave = () => {
    if (organizationActiveInDB && !myOrganization.isActive) {
      if (!confirmInactiveSave) {
        setConfirmInactiveSave(true)
        return
      } else {
        setConfirmInactiveSave(false)
      }
    }

    const typeOfFetch = myOrganization.id ? "update" : "create"
    const varName = myOrganization.id
      ? "ModifiedByAccountId"
      : "CreatedByAccountId"
    let validationError = false

    if (msaUrlHasError) {
      validationError = true
    }
    if (!myOrganization.webSiteUrl) {
      setWebSiteUrlHasError(true)
      validationError = true
    } else {
      setWebSiteUrlHasError(false)
    }
    if (!myOrganization.name) {
      setOrganizationNameHasError(true)
      validationError = true
    } else {
      setOrganizationNameHasError(false)
    }
    if (myOrganization.addGaTag && !myOrganization.gaTagId) {
      setGaTagHasError(true)
      validationError = true
    } else {
      setGaTagHasError(false)
    }
    if (turnIntoProperty) {
      if (!myOrganization.parentId && !currentParent) {
        setParentIdHasError(true)
        validationError = true
      } else {
        setParentIdHasError(false)
      }
    }

    if (validationError === true) {
      setIsSavingContent(false)
      return
    }

    if (!isSaving) {
      setIsSaving(true)
      setIsSavingOrganization(true)
    }

    if (shouldUpdateAllCtas) {
      Cta_v2DAO.putUpdateVariantOnclicksByOrg(myOrganization.id).then(
        response => {
          setVariantClickUpdateSuccessModalMessage(
            ` Successfully updated ${response.modified} CTA variants.`
          )
          setUpdateVariantOnclicksCompleted(true)
        }
      )
    } else {
      setUpdateVariantOnclicksCompleted(true)
    }

    let newMyOrganization = {
      Name: myOrganization.name,
      isDeliverableRequired: myOrganization.isDeliverableRequired,
      WebSiteUrl: myOrganization.webSiteUrl,
      isActive: myOrganization.isActive,
      ParentId: myOrganization.parentId || currentParent,
      MsaDate: myOrganization.msaDate,
      MsaUrl: myOrganization.msaUrl,
      SportsDatasource1Id: myOrganization.sportsDatasource1Id,
      BettingDatasource1Id: myOrganization.bettingDatasource1Id,
      ImageSource1Id: myOrganization.imageSource1Id,
      OrganizationDatasources: PopulateDataSources(),
      OrganizationBookmakers: PopulateBookmakers(),
      AuthorName: myOrganization.authorName || "Data Skrive",
      AuthorUri: myOrganization.authorURI || "https://dataskrive.com",
      IsInternal: myOrganization.isInternal,
      AddGaTag: myOrganization.addGaTag,
      GaTagId: myOrganization.gaTagId,
      [varName]: props.userId
    }
    if (myOrganization.id) {
      newMyOrganization.id = myOrganization.id
    }
    const toDelete = [
      "accounts",
      "editingOrganization",
      "websiteurl",
      "ncaaOrgId"
    ]
    toDelete.forEach(itm => {
      if (newMyOrganization[itm] !== undefined) {
        delete newMyOrganization[itm]
      }
    })
    put(`${globalSettings.apiBaseUrl}/api/organization/${typeOfFetch}`, null, {
      ...newMyOrganization
    })
      .then(Response => Response && Response.ok === true && Response.json())
      .then(response => {
        setIsSaving(false)
        setIsSavingContent(false)
        let newOrgId =
          response.responseMessage &&
          response.responseMessage.match(/^Organization\s(\d*):/)
        if (newOrgId && typeof newOrgId === "object" && newOrgId[1]) {
          newOrgId = Number(newOrgId[1])
        }

        if (organizationActiveInDB && !myOrganization.isActive) {
          inactivateContractsForOrg(myOrganization.id, response.responseMessage)
          getPermissions()
        } else {
          getPermissions()
          setSuccessMessage(response.responseMessage)
          setOrganizationUpdated(true)
        }
        setOpenModal(true)
        return response
      })
      .then(async response => {
        if (response.content && typeof response.content === "boolean") {
          isNowLoading(true)
          try {
            const data = await OrganizationDAO.getOrganization(
              myOrganization.id
            )
            setUpdatedOrganization(data.content)
          } catch (err) {
            console.error(
              "Error on PUT to /api/organization/{typeOfFetch}",
              err
            )
          } finally {
            isNowLoading(false)
          }
        } else if (response.content && typeof response.content === "number") {
          isNowLoading(true)
          try {
            const data = await OrganizationDAO.getOrganization(response.content)
            setUpdatedOrganization(data.content)
          } catch (err) {
            console.error(
              "Error on PUT to /api/organization/{typeOfFetch}",
              err
            )
          } finally {
            isNowLoading(false)
          }
        }
      })
      .catch(err => {
        console.error("Error on PUT to /api/organization/{typeOfFetch}", err)
      })
  }

  const inactivateSave = () => {
    handleOrganizationSave()
  }

  const initializedFields = {}

  const activeParentOrganizations = currentParent
    ? organizations.filter(
        itm => itm.isActive === true && itm.id === currentParent
      )
    : organizations.filter(
        itm =>
          itm.isActive === true &&
          itm.parentId === null &&
          itm.id !== organization.id
      )

  activeParentOrganizations.sort(function (a, b) {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  })

  const { sportsData, bettingData, imageData, bookmakerData } = props

  let bookmakerDataToJS = bookmakerData || []
  let imageDataToJS = imageData || []
  let bettingDataToJS = bettingData || []
  let sportsDataToJS = sportsData || []

  bookmakerDataToJS.sort(function (a, b) {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  })
  imageDataToJS.sort(function (a, b) {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  })
  bettingDataToJS.sort(function (a, b) {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  })
  sportsDataToJS.sort(function (a, b) {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  })

  const hasWindowObject = typeof window !== "undefined"
  const screenWidth = hasWindowObject ? window.innerWidth : null
  const screenHeight = hasWindowObject ? window.innerHeight : null
  const inColumnFlex =
    screenWidth && screenHeight && (screenHeight < 700 || screenWidth < 700)

  myOrganization.bookmakerFromDB &&
    myOrganization.bookmakerFromDB.forEach(itm => {
      if (!itm.name) {
        bookmakerDataToJS &&
          bookmakerDataToJS.forEach(obj => {
            if (obj.id === itm.id) {
              itm.name = obj.name
            }
          })
      }
    })

  myOrganization.imageDataFromDB &&
    myOrganization.imageDataFromDB.forEach(itm => {
      if (!itm.name) {
        imageDataToJS &&
          imageDataToJS.forEach(obj => {
            if (obj.id === itm.id) {
              itm.name = obj.name
            }
          })
      }
    })
  myOrganization.bettingDataFromDB &&
    myOrganization.bettingDataFromDB.forEach(itm => {
      if (!itm.name) {
        bettingDataToJS &&
          bettingDataToJS.forEach(obj => {
            if (obj.id === itm.id) {
              itm.name = obj.name
            }
          })
      }
    })
  myOrganization.sportsDataFromDB &&
    myOrganization.sportsDataFromDB.forEach(itm => {
      if (!itm.name) {
        sportsDataToJS &&
          sportsDataToJS.forEach(obj => {
            if (obj.id === itm.id) {
              itm.name = obj.name
            }
          })
      }
    })

  useEffect(() => {
    if (resetOrganizationInfo) {
      handleClose()
      setResetOrganizationInfo(false)
    }
  }, [resetOrganizationInfo])

  return (
    <>
      {!resetOrganizationInfo && (
        <>
          <div>
            <div
              style={{
                border: "1px solid #ccc",
                borderRadius: "20px",
                paddingBottom: "20px"
              }}
            >
              <Grid style={{ display: "flex" }} className={classes.myFlex}>
                <GridItem xs={12} sm={10}>
                  <DialogTitle
                    style={{ paddingBottom: "0px" }}
                    className={classes.myFlexFontSize}
                  >
                    Organization Editor
                    {!isAddingOrganization && name && ` (${name})`}
                    {false && !isAddingOrganization && id && ` - (id #${id})`}
                  </DialogTitle>
                </GridItem>
                {id && (
                  <GridItem xs={12} sm={2}>
                    <div style={{}}>
                      <FormControlLabel
                        labelPlacement="end"
                        control={
                          <Checkbox
                            checked={isActive}
                            onChange={event => {
                              handleActiveOrgCheck(event.target.checked)
                            }}
                          />
                        }
                        label="Active"
                        style={{ color: "#212529" }}
                      />
                    </div>
                  </GridItem>
                )}
              </Grid>
              <Grid style={{ display: "flex" }} className={classes.myFlex}>
                <GridItem xs={12} sm={!inColumnFlex ? 4 : 8}>
                  <TextField
                    type="text"
                    id="name"
                    label="Organization Name"
                    fullWidth
                    value={name}
                    onChange={handleCellChange("name")}
                    margin="normal"
                    required={true}
                  />
                  {organizationNameHasError && (
                    <FormHelperText className={classes.requiredMsg}>
                      Organization Name is required!
                    </FormHelperText>
                  )}
                </GridItem>
                <GridItem xs={12} sm={!inColumnFlex ? 4 : 8}>
                  <TextField
                    type="text"
                    id="webSiteUrl"
                    label="Organization Url"
                    fullWidth
                    value={webSiteUrl}
                    onChange={handleCellChange("webSiteUrl")}
                    margin="normal"
                    required={true}
                  />
                  {webSiteUrlHasError && (
                    <FormHelperText className={classes.requiredMsg}>
                      Organization Website Url is required!
                    </FormHelperText>
                  )}
                </GridItem>

                {!turnIntoProperty && (
                  <GridItem
                    xs={12}
                    sm={!inColumnFlex ? 4 : 8}
                    style={{ marginTop: "-4px" }}
                  >
                    <Tooltip
                      title="Change to Property"
                      placement="top"
                      className={classes.changeLabel}
                    >
                      <FormControlLabel
                        labelPlacement="end"
                        control={
                          <Checkbox
                            id="turnIntoProperty"
                            checked={turnIntoProperty || false}
                            onChange={handleCheckboxChange("turnIntoProperty")}
                          />
                        }
                        label="Change to Property"
                      />
                    </Tooltip>
                  </GridItem>
                )}
                {turnIntoProperty && (
                  <GridItem
                    xs={12}
                    sm={!inColumnFlex ? 4 : 8}
                    style={{ marginTop: "16px" }}
                  >
                    <FormControl fullWidth>
                      <Autocomplete
                        Value={myOrganization.parentId}
                        multiple={false}
                        onChange={(event, newValue, reason) => {
                          handleSingleAuto(event, newValue, reason, "parentId")
                        }}
                        renderInput={params => (
                          <TextField
                            label="Parent Organization"
                            key={params.id}
                            value={params.name}
                            margin="none"
                            {...params}
                          />
                        )}
                        options={activeParentOrganizations}
                        getOptionLabel={option => option.name}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        nameField="name"
                        valueField="id"
                      />
                      {parentIdHasError && (
                        <FormHelperText className={classes.requiredMsg}>
                          Parent Organization is required!
                        </FormHelperText>
                      )}
                    </FormControl>
                  </GridItem>
                )}
                <GridItem
                  xs={12}
                  sm={!inColumnFlex ? 4 : 8}
                  style={{ marginTop: "16px" }}
                >
                  <FormControlLabel
                    labelPlacement="end"
                    control={
                      <Checkbox
                        id="isDeliverableRequired"
                        checked={isDeliverableRequired}
                        onChange={handleCheckboxChange("isDeliverableRequired")}
                      />
                    }
                    label="Deliverable required at render?"
                    style={{ color: "#212529" }}
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={!inColumnFlex ? 4 : 8}
                  style={{ marginTop: "16px" }}
                >
                  <FormControlLabel
                    labelPlacement="end"
                    control={
                      <Checkbox
                        id="isInternal"
                        checked={isInternal}
                        onChange={handleCheckboxChange("isInternal")}
                        aria-label="org-internal-checkbox"
                      />
                    }
                    label="Internal Organization"
                    style={{ color: "#212529" }}
                  />
                </GridItem>
              </Grid>
              <Grid style={{ display: "flex" }} className={classes.myFlex}>
                <GridItem xs={12} sm={!inColumnFlex ? 4 : 8}>
                  <TextField
                    type="text"
                    id="msaUrl"
                    className={
                      initializedFields && initializedFields.msaUrl
                        ? classes.initTextField
                        : ""
                    }
                    label="MSA URL"
                    fullWidth
                    value={msaUrl || ""}
                    onChange={handleCellChange("msaUrl")}
                    margin="normal"
                    required={true}
                    onBlur={e => {
                      checkUrlValidation(e, "msaUrl")
                    }}
                  />
                  {msaUrlHasError && (
                    <FormHelperText className={classes.requiredMsg}>
                      A valid MSA URL is required!
                    </FormHelperText>
                  )}
                </GridItem>

                <GridItem xs={12} sm={6}>
                  <TextField
                    onChange={onDateChange("msaDate")}
                    id="msaDate"
                    className={
                      initializedFields && initializedFields.msaDate
                        ? classes.initTextField
                        : ""
                    }
                    label="MSA Date"
                    type="date"
                    format={"DD/MM/YYYY"}
                    value={msaDate}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                    style={{
                      width: "200px"
                    }}
                    inputProps={{
                      min: "2019-01-01"
                    }}
                    required={true}
                  />
                </GridItem>
              </Grid>
              <DialogTitle
                style={{
                  paddingBottom: "0px",
                  paddingLeft: "15px"
                }}
                className={classes.myFlexFontSize}
              >
                Data Sources
              </DialogTitle>
              <Grid style={{ display: "flex" }} className={classes.myFlex}>
                <GridItem xs={12}>
                  <Autocomplete
                    defaultValue={myOrganization.sportsDataFromDB}
                    multiple={true}
                    onChange={(event, newValue, reason) => {
                      handleMultiAuto(
                        event,
                        newValue,
                        reason,
                        "sportsDataFromDB"
                      )
                    }}
                    renderInput={params => (
                      <TextField
                        label="Sports Data Source"
                        key={params.id}
                        value={params.name}
                        margin="none"
                        {...params}
                      />
                    )}
                    options={sportsDataToJS.filter(itm => {
                      let found = false
                      const arr =
                        myOrganization.sportsDataChange ||
                        myOrganization.sportsDataFromDB ||
                        []
                      arr.forEach(itm2 => {
                        if (itm2.id === itm.id) {
                          found = true
                        }
                      })
                      return !found
                    })}
                    getOptionLabel={option => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    nameField="name"
                    valueField="id"
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Autocomplete
                    defaultValue={myOrganization.bettingDataFromDB}
                    multiple={true}
                    onChange={(event, newValue, reason) => {
                      handleMultiAuto(
                        event,
                        newValue,
                        reason,
                        "bettingDataFromDB"
                      )
                    }}
                    renderInput={params => (
                      <TextField
                        label="Betting Data Source"
                        key={params.id}
                        value={params.name}
                        margin="none"
                        {...params}
                      />
                    )}
                    options={bettingDataToJS.filter(itm => {
                      let found = false
                      const arr =
                        myOrganization.bettingDataChange ||
                        myOrganization.bettingDataFromDB ||
                        []
                      arr.forEach(itm2 => {
                        if (itm2.id === itm.id) {
                          found = true
                        }
                      })
                      return !found
                    })}
                    getOptionLabel={option => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    nameField="name"
                    valueField="id"
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Autocomplete
                    defaultValue={myOrganization.imageDataFromDB}
                    multiple={true}
                    onChange={(event, newValue, reason) => {
                      handleMultiAuto(
                        event,
                        newValue,
                        reason,
                        "imageDataFromDB"
                      )
                    }}
                    renderInput={params => (
                      <TextField
                        label="3rd Party Image Data Source"
                        key={params.id}
                        value={params.name}
                        margin="none"
                        {...params}
                      />
                    )}
                    options={imageDataToJS.filter(itm => {
                      let found = false
                      const arr =
                        myOrganization.imageDataChange ||
                        myOrganization.imageDataFromDB ||
                        []
                      arr.forEach(itm2 => {
                        if (itm2.id === itm.id) {
                          found = true
                        }
                      })
                      return !found
                    })}
                    getOptionLabel={option => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    nameField="name"
                    valueField="id"
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Autocomplete
                    defaultValue={myOrganization.bookmakerFromDB}
                    multiple={true}
                    onChange={(event, newValue, reason) => {
                      handleMultiAuto(
                        event,
                        newValue,
                        reason,
                        "bookmakerFromDB"
                      )
                    }}
                    renderInput={params => (
                      <TextField
                        label="Bookmaker Data Source"
                        key={params.id}
                        value={params.name}
                        margin="none"
                        {...params}
                      />
                    )}
                    options={bookmakerDataToJS.filter(itm => {
                      let found = false
                      const arr =
                        myOrganization.bookmakerDataChange ||
                        myOrganization.bookmakerFromDB ||
                        []
                      arr.forEach(itm2 => {
                        if (itm2.id === itm.id) {
                          found = true
                        }
                      })
                      return !found
                    })}
                    getOptionLabel={option => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    nameField="name"
                    valueField="id"
                  />
                </GridItem>
              </Grid>
              <DialogTitle
                style={{
                  paddingBottom: "0px",
                  paddingLeft: "15px"
                }}
                className={classes.myFlexFontSize}
              >
                Analytics Manager
              </DialogTitle>
              <Grid style={{ display: "flex" }} className={classes.myFlex}>
                <GridItem xs={2}>
                  <FormControlLabel
                    labelPlacement="end"
                    control={
                      <Checkbox
                        id="addGaTag"
                        checked={addGaTag}
                        onChange={handleCheckboxChange("addGaTag")}
                        aria-label="org-gatag-checkbox"
                      />
                    }
                    label="GA Tag"
                    style={{ color: "#212529" }}
                  />
                </GridItem>
                {addGaTag && (
                  <GridItem xs={4}>
                    <TextField
                      type="text"
                      id="gaTagId"
                      label="GA Tag Id"
                      fullWidth
                      value={gaTagId}
                      onChange={handleCellChange("gaTagId")}
                      margin="normal"
                      required={true}
                    />
                    {gaTagHasError && (
                      <FormHelperText className={classes.requiredMsg}>
                        GA Tag ID is required!
                      </FormHelperText>
                    )}
                  </GridItem>
                )}
              </Grid>
            </div>

            {(editingOrganization ||
              isAddingOrganization ||
              editingDataSources) && (
              <Grid
                style={{ display: "flex", justifyContent: "flex-end" }}
                className={classes.myFlex}
              >
                <GridItem xs={12} sm={6}>
                  <DialogActions style={{ marginTop: "14px" }}>
                    <Button onClick={() => backToOrgList()} color="primary">
                      Back
                    </Button>
                    <Button
                      onClick={() => {
                        setIsSavingContent(true)
                        handleOrganizationSave()
                      }}
                      color="primary"
                      disabled={isSavingContent}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </GridItem>
              </Grid>
            )}
          </div>

          {isSavingOrganization && (
            <Grid spacing={1} container style={{ paddingTop: "5px 10px" }}>
              <Grid xs item>
                <div style={{ width: "80%", height: "20px" }}>
                  {isSaving && <LinearProgress />}
                  {!isSaving && <div className={classes.progessDone} />}
                </div>
              </Grid>
            </Grid>
          )}
          {organizationUpdated && updateVariantOnclicksCompleted && (
            <Modal
              open={openModal}
              onClose={() => {
                if (isSavingOrganization) {
                  backToOrgList()
                }
                setIsSavingOrganization(false)
                setOpenModal(false)
              }}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              style={{ zIndex: "99999999" }}
            >
              <div
                className={`${classes.successModal} ${
                  removedContracts && removedContracts.length > 0
                    ? classes.deActivateSave
                    : classes.regularSave
                }`}
              >
                <h2 id="server-modal-title">Success!</h2>
                <div id="server-modal-description">
                  {successMessage}
                  {variantClickUpdateSuccessModalMessage}
                  {removedContracts && removedContracts.length > 0 && (
                    <ul>
                      {removedContracts.map((itm, i) => (
                        <li key={i} style={{ fontWeight: "bold" }}>
                          Contract "{itm.contractName}" from{" "}
                          {itm.licenseTermStart} to {itm.licenseTermEnd} has
                          been marked as{" "}
                          <span className={classes.result}>completed</span>
                        </li>
                      ))}
                    </ul>
                  )}
                  {removedProperties && removedProperties.length > 0 && (
                    <ul>
                      {removedProperties.map((itm, i) => (
                        <li key={i} style={{ fontWeight: "bold" }}>
                          Property "{itm.name}" with id="{itm.id}" has been
                          marked as{" "}
                          <span className={classes.result}>inactive</span>
                        </li>
                      ))}
                    </ul>
                  )}
                  {organizationActiveInDB &&
                    !myOrganization.isActive &&
                    removedProperties &&
                    removedProperties.length === 0 && (
                      <ul>
                        <li style={{ fontWeight: "bold" }}>
                          No Properties where removed
                        </li>
                      </ul>
                    )}
                </div>
              </div>
            </Modal>
          )}
          {noChangesFound && (
            <Modal
              open={noChangesFound}
              onClose={() => {
                setNoChangesFound(false)
              }}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div
                className={classes.successModal}
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "bold"
                }}
              >
                There are no changes to save
              </div>
            </Modal>
          )}
          <ConfirmDialog
            title={`Are you sure you want to inactivate "${name}"?`}
            open={confirmInactiveOpen}
            setOpen={setConfirmInactiveOpen}
            onConfirm={inactivateOrg}
          >
            After you hit "Save", this action will result in:
            <ul>
              <li>
                All Contracts associated with <strong>"{name}"</strong> becoming{" "}
                <span className={classes.resultModal}>completed</span>
              </li>
              <li>
                All Properties associated with <strong>"{name}"</strong>{" "}
                becoming <span className={classes.resultModal}>inactive</span>
              </li>
              <li>
                All Outputs associated with <strong>"{name}"</strong> or{" "}
                Properties associated with <strong>"{name}"</strong> will be{" "}
                given an end date and be considered done for reporting purposes.
              </li>
            </ul>
            <div>
              <strong>Are you sure you want to do that?</strong>
            </div>
          </ConfirmDialog>
          <ConfirmDialog
            title={`You are about to inactivate "${name}"?`}
            open={confirmInactiveSave}
            setOpen={setConfirmInactiveSave}
            onConfirm={inactivateSave}
          >
            Reminder, this action will result in:
            <ul>
              <li>
                All Contracts associated with <strong>"{name}"</strong> becoming{" "}
                <span className={classes.resultModal}>completed</span>
              </li>
              <li>
                All Properties associated with <strong>"{name}"</strong>{" "}
                becoming <span className={classes.resultModal}>inactive</span>
              </li>
              <li>
                All Outputs associated with <strong>"{name}"</strong> or{" "}
                Properties associated with <strong>"{name}"</strong> will be{" "}
                given an end date and be considered done for reporting purposes.
              </li>
            </ul>
            <div>
              <strong>Still want to "Save"?</strong>
            </div>
          </ConfirmDialog>
          <CustomDSDialog
            open={isConfirmUpdateAllVariantsOpen}
            icon={<Info style={{ fontSize: "4rem" }} />}
            onClose={() => {
              setIsConfirmUpdateAllVariantsOpen(false)
            }}
            backgroundColor={"#4d9ab5"}
            content={`Do you want to add an Onclick to existing CTA Variant anchor tags?`}
            buttonProps={[
              {
                label: "NO",
                color: "default",
                onClick: () => {
                  setUpdateCTAFlag(false)
                }
              },
              {
                label: "YES",
                color: "primary",
                onClick: () => {
                  setUpdateCTAFlag(true)
                }
              }
            ]}
          />
        </>
      )}
    </>
  )
}

OrganizationEditor.propTypes = {
  isOpen: PropTypes.bool,
  isAddingOrganization: PropTypes.bool,
  isNowLoading: PropTypes.bool,
  userId: PropTypes.number,
  onChange: PropTypes.func,
  backToOrgList: PropTypes.func,
  onClose: PropTypes.func,
  classes: PropTypes.object,
  organization: PropTypes.object,
  organizations: PropTypes.array,
  datasourcesByOrg: PropTypes.object,
  notChildOrgs: PropTypes.array,
  datasources: PropTypes.object,
  bookmakers: PropTypes.array,
  bookmakerData: PropTypes.array,
  imageData: PropTypes.array,
  bettingData: PropTypes.array,
  sportsData: PropTypes.array,
  setUpdatedOrganization: PropTypes.func
}
export default withStyles(styles)(OrganizationEditor)
