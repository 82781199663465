import { action, observable, decorate, computed } from "mobx"
import AffiliateDAO from "../daos/affiliateDAO"

export default class AffiliateStore {
  affiliatesByOrgId
  affiliate
  affiliateOrganizations
  affiliateContract
  availableShortCodes
  randomShortCode
  shortCodeByAffiliate

  constructor(rootStore) {
    this.rootStore = rootStore
    this.dehydrate()
  }
  /**
   * Computeds
   */
  get isDependencyLoaded() {
    return !!this.ctaPreviewDependency?.id
  }
  /**
   * Methods
   */

  getAffiliatesByOrgId = async organizationId => {
    try {
      const myAffiliates = await AffiliateDAO.getAffiliatesByOrgId(
        organizationId
      )
      myAffiliates.forEach(x => {
        x.requestOrganizationId = organizationId
      })
      this.setAffiliatesByOrgId(myAffiliates)
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  getAffiliate = async () => {
    try {
      const myAffiliates = await AffiliateDAO.getAffiliate()
      this.setAffiliate(myAffiliates)
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  getAffiliateOrganizations = async () => {
    try {
      const myAffiliates = await AffiliateDAO.getAffiliateOrganizations()
      this.setAffiliateOrganizations(myAffiliates)
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  getAffiliateContract = async () => {
    try {
      const myAffiliates = await AffiliateDAO.getAffiliateContract()
      this.setAffiliateContract(myAffiliates)
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  updateAffiliateContract = async body => {
    try {
      const result = await AffiliateDAO.updateAffiliateContract(body)
      //this.setAffiliateContract(result)
      return result
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  saveNewAffiliateContract = async body => {
    try {
      const result = await AffiliateDAO.saveNewAffiliateContract(body)
      //this.setAffiliateContract(result)
      return result
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  updateAffiliateOrganization = async body => {
    try {
      const result = await AffiliateDAO.updateAffiliateOrganization(body)
      //this.setAffiliateOrganization(result)
      return result
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  saveNewAffiliateOrganization = async body => {
    try {
      const result = await AffiliateDAO.saveNewAffiliateOrganization(body)
      //this.setAffiliateOrganization(result)
      return result
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  getAvailableShortCodes = async linkId => {
    try {
      const availableShortCodes = await AffiliateDAO.getAvailableShortCodes(
        linkId
      )
      this.setAvailableShortCodes(availableShortCodes)
    } catch (err) {
      console.error("Error:", err)
    }
  }

  getRandomShortCode = async linkId => {
    try {
      const randomShortCode = await AffiliateDAO.getRandomShortCode(linkId)
      this.setRandomShortCode(randomShortCode)
    } catch (err) {
      console.error("Error:", err)
    }
  }

  getShortCodeByAffiliate = async id => {
    try {
      const shortCodeByAffiliate = await AffiliateDAO.getShortCodeByAffiliate(
        id
      )
      this.setShortCodeByAffiliate(shortCodeByAffiliate)
    } catch (err) {
      console.error("Error:", err)
    }
  }

  /**
   * Reactions
   */

  /**
   * Internal Actions
   */
  dehydrate() {
    this.dehydrateNarrative()
    this.setAffiliate([])
    this.setAffiliateOrganizations([])
    this.setAffiliateContract([])
    this.setAffiliatesByOrgId([])
    this.setAvailableShortCodes([])
    this.setRandomShortCode([])
    this.setShortCodeByAffiliate([])
  }

  dehydrateNarrative() {
    this.setIsDirty(false)
  }

  setIsDirty(value) {
    this.isDirty = value
  }

  setAffiliate(value) {
    this.affiliate = value
  }

  setAffiliatesByOrgId(value) {
    this.affiliatesByOrgId = value
  }

  setAffiliateOrganizations(value) {
    this.affiliateOrganizations = value
  }

  setAffiliateContract(value) {
    this.affiliateContract = value
  }

  setAvailableShortCodes(value) {
    this.availableShortCodes = value
  }

  setRandomShortCode(value) {
    this.randomShortCode = value
  }

  setShortCodeByAffiliate(value) {
    this.shortCodeByAffiliate = value
  }
}

/**
 * object decorators
 */
decorate(AffiliateStore, {
  // computeds
  isDependencyLoaded: computed,
  // observables

  affiliate: observable,
  affiliateOrganizations: observable,
  affiliateContract: observable,
  affiliatesByOrgId: observable,
  availableShortCodes: observable,
  randomShortCode: observable,
  shortCodeByAffiliate: observable,

  // actions
  getAffiliate: action.bound,
  getAffiliatesByOrgId: action.bound,
  getAffiliateOrganizations: action.bound,
  getAffiliateContract: action.bound,
  getAvailableShortCodes: action.bound,
  getRandomShortCode: action.bound,
  getShortCodeByAffiliate: action.bound,
  updateAffiliateContract: action.bound,
  saveNewAffiliateContract: action.bound,

  setAffiliate: action.bound,
  setAffiliatesByOrgId: action.bound,
  setAffiliateOrganizations: action.bound,
  setAffiliateContract: action.bound,
  setAvailableShortCodes: action.bound,
  setRandomShortCode: action.bound,
  setShortCodeByAffiliate: action.bound
})
