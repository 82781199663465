import { action, observable, decorate, computed } from "mobx"
import Cta_v2DAO from "../daos/cta_v2DAO"
import AffiliateDAO from "daos/affiliateDAO"

export default class CTAv2Store {
  rootStore
  companiesInfo
  organizationsInfo
  regionsInfo
  intentInfo
  ctaInfo
  linkTypeInfo
  variantsByLinkId
  offerLinksInfo
  disclaimerLinksInfo
  affiliates
  variantLeagues
  displayStyle
  verticals
  events
  updateVariants
  loadingVariantGroupInfo
  ctaPreviewDependencyChangeLog
  ctaPreviewDependency
  ctaPreviewDependencyBackup
  availableShortCodes
  randomShortCode
  shortCodeByAffiliate

  constructor(rootStore) {
    this.rootStore = rootStore
    this.dehydrate()
  }
  /**
   * Computeds
   */
  get isDependencyLoaded() {
    return !!this.ctaPreviewDependency?.id
  }
  /**
   * Methods
   */
  getCompaniesInfo = async () => {
    try {
      const myCompaniesInfo = await Cta_v2DAO.getCompaniesInfo()
      this.setCompaniesInfo(myCompaniesInfo)
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  getOrganizationsInfo = async () => {
    try {
      const myOrganizationsInfo = await Cta_v2DAO.getOrganizationsInfo()
      this.setOrganizationsInfo(myOrganizationsInfo)
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  getRegionsInfo = async () => {
    try {
      const myRegionsInfo = await Cta_v2DAO.getRegionsInfo()
      this.setRegionsInfo(myRegionsInfo)
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  getLinkTypeInfo = async () => {
    try {
      const myLinkTypeInfo = await Cta_v2DAO.getLinkTypeInfo()
      this.setLinkTypeInfo(myLinkTypeInfo)
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  getIntentInfo = async () => {
    try {
      const myIntentInfo = await Cta_v2DAO.getIntentInfo()
      this.setIntentInfo(myIntentInfo)
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  getLinkInfo = async id => {
    try {
      const myLinkInfo = await Cta_v2DAO.getLinkInfo(id)
      this.setLinkInfo(myLinkInfo)
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  getLinkDisplayInfo = async intentId => {
    try {
      const myLinkInfo = await Cta_v2DAO.getLinkDisplayInfo(intentId)
      switch (intentId) {
        case 1:
          this.setOfferLinksInfo(myLinkInfo)
          break
        case 2:
          this.setDisclaimerLinksInfo(myLinkInfo)
          break
        default:
      }
    } catch (err) {
      console.error("Error: ", err)
    }
  }
  getAffiliatesByOrgId = async organizationId => {
    try {
      const myAffiliates = await Cta_v2DAO.getAffiliatesByOrgId(organizationId)
      myAffiliates.forEach(x => {
        x.requestOrganizationId = organizationId
      })
      this.setAffiliates(myAffiliates)
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  getVariantDisplayStyle = async intentId => {
    try {
      const displayStyle = await Cta_v2DAO.getVariantDisplayStyle(intentId)
      this.setVariantDisplayStyle(displayStyle)
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  getVariantEvents = async () => {
    try {
      const events = await Cta_v2DAO.getVariantEvents()
      this.setVariantEvents(events)
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  getVariantVerticals = async () => {
    try {
      const verticals = await Cta_v2DAO.getVariantVerticals()
      this.setVariantVerticals(verticals)
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  getVariantLeagues = async () => {
    try {
      const variantLeagues = await Cta_v2DAO.getVariantLeagues()
      this.setVariantLeagues(variantLeagues)
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  postVariant = async variant => {
    try {
      this.setLoadingNewVariant(true)
      const newVariantId = await Cta_v2DAO.postVariant(variant)
      this.variantLeagues.id = newVariantId
      //this.updateVariants(variant)
    } catch (err) {
      console.error("Error: ", err)
      throw err
    } finally {
      this.setLoadingNewVariant(false)
    }
  }

  getVariantsByLinkId = async (id, verbose) => {
    try {
      const variantsByLinkId = await Cta_v2DAO.getVariantsByLinkId(id, verbose)
      this.setVariantsByLinkId(variantsByLinkId)
    } catch (err) {
      console.error("Error: ", err)
    }
  }
  getVariantByid = async id => {
    try {
      const variantbyId = await Cta_v2DAO.getVariantById(id)
      this.setVariantById(variantbyId)
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  putCTAPreviewDependency = async dependencyObject => {
    try {
      const updatedPreviewDependency = await Cta_v2DAO.postCTADependency(
        dependencyObject
      )
      const newPreviewDependency = {
        ...dependencyObject,
        id: updatedPreviewDependency
      }
      this.setCTAPreviewDependency(newPreviewDependency)
      this.setCTAPreviewDependencyBackup(newPreviewDependency)
      this.getCTAPreviewDependencyChangeLog(newPreviewDependency.id)
      return updatedPreviewDependency
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  getCTAPreviewDependencyChangeLog = async previewDependencyId => {
    try {
      const response = await Cta_v2DAO.getCTAPreviewDependencyChangeLog(
        previewDependencyId
      )
      this.setCTAPreviewDependencyChangeLog(response)
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  updateCTAPreviewDependency = async dependencyObject => {
    try {
      this.setCTAPreviewDependency(dependencyObject)
      return dependencyObject
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  getPreviewDependencyByIntent = async intentId => {
    try {
      const previewDependencyResponse =
        await Cta_v2DAO.getPreviewDependencyByIntent(intentId)
      this.setCTAPreviewDependency(previewDependencyResponse)
      this.setCTAPreviewDependencyBackup(previewDependencyResponse)
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  getVariantGroupInfo = async (
    orgId,
    leagueId,
    verticalId,
    intentId,
    linkTypeId,
    companyId,
    linkId
  ) => {
    try {
      this.setLoadingVariantGroupInfo(true)
      const variantGroupInfo = await Cta_v2DAO.getVariantGroupInfo(
        orgId,
        leagueId,
        verticalId,
        intentId,
        linkTypeId,
        companyId,
        linkId
      )
      this.setVariantGroupInfo(variantGroupInfo)
    } catch (err) {
      console.error("Error:", err)
    } finally {
      this.setLoadingVariantGroupInfo(false)
    }
  }

  getAvailableShortCodes = async linkId => {
    try {
      const availableShortCodes = await AffiliateDAO.getAvailableShortCodes(
        linkId
      )
      this.setAvailableShortCodes(availableShortCodes)
    } catch (err) {
      console.error("Error:", err)
    }
  }

  getRandomShortCode = async linkId => {
    try {
      const randomShortCode = await AffiliateDAO.getRandomShortCode(linkId)
      this.setRandomShortCode(randomShortCode)
    } catch (err) {
      console.error("Error:", err)
    }
  }

  getShortCodeByAffiliate = async id => {
    try {
      const shortCodeByAffiliate = await AffiliateDAO.getShortCodeByAffiliate(
        id
      )
      this.setShortCodeByAffiliate(shortCodeByAffiliate)
    } catch (err) {
      console.error("Error:", err)
    }
  }

  /**
   * Reactions
   */

  /**
   * Internal Actions
   */
  dehydrate() {
    this.dehydrateNarrative()
    this.setRegionsInfo([])
    this.setCompaniesInfo([])
    this.setOrganizationsInfo([])
    this.setIntentInfo([])
    this.setCtaInfo({})
    this.setLinkInfo({})
    this.setOfferLinksInfo([])
    this.setDisclaimerLinksInfo([])
    this.setAffiliates([])
    this.setLinkTypeInfo([])
    this.setVariantsByLinkId([])
    this.setVariantEvents([])
    this.setVariantVerticals([])
    this.setVariantLeagues([])
    this.setVariantById([])
    this.setVariantGroupInfo([])
    this.setLoadingVariantGroupInfo(false)
    this.setAvailableShortCodes([])
    this.setRandomShortCode([])
    this.setShortCodeByAffiliate([])
  }

  dehydrateNarrative() {
    this.setIsDirty(false)
  }

  setIsDirty(value) {
    this.isDirty = value
  }

  setCompaniesInfo(value) {
    this.companiesInfo = value
  }

  setOrganizationsInfo(value) {
    this.organizationsInfo = value
  }

  setRegionsInfo(value) {
    this.regionsInfo = value
  }

  setIntentInfo(value) {
    this.intentInfo = value
  }

  setCtaInfo(value) {
    this.ctaInfo = value
  }

  setLinkInfo(value) {
    this.linkInfo = value
  }

  setOfferLinksInfo(value) {
    this.offerLinksInfo = value
  }

  setDisclaimerLinksInfo(value) {
    this.disclaimerLinksInfo = value
  }

  setAffiliates(value) {
    this.affiliates = value
  }

  setLinkTypeInfo(value) {
    this.linkTypeInfo = value
  }

  setVariantsByLinkId(value) {
    this.variantsByLinkId = value
  }

  setVariantDisplayStyle(value) {
    this.displayStyle = value
  }

  setVariantEvents(value) {
    this.events = value
  }

  setVariantVerticals(value) {
    this.verticals = value
  }

  setVariantLeagues(value) {
    this.variantLeagues = value
  }

  setLoadingNewVariant(value) {
    this.updateVariants = value
  }

  setLoadingVariantGroupInfo(value) {
    this.loadingVariantGroupInfo = value
  }

  setVariantById(value) {
    this.variantbyId = value
  }

  setCTAPreviewDependency(value) {
    this.ctaPreviewDependency = value
  }

  setCTAPreviewDependencyChangeLog(value) {
    this.ctaPreviewDependencyChangeLog = value
  }

  setCTAPreviewDependencyBackup(value) {
    this.ctaPreviewDependencyBackup = value
  }

  setVariantGroupInfo(value) {
    this.variantGroupInfo = value
  }

  setAvailableShortCodes(value) {
    this.availableShortCodes = value
  }

  setRandomShortCode(value) {
    this.randomShortCode = value
  }

  setShortCodeByAffiliate(value) {
    this.shortCodeByAffiliate = value
  }
}

/**
 * object decorators
 */
decorate(CTAv2Store, {
  // computeds
  isDependencyLoaded: computed,
  // observables
  companiesInfo: observable,
  organizationsInfo: observable,
  regionsInfo: observable,
  intentInfo: observable,
  ctaInfo: observable,
  linkInfo: observable,
  offerLinksInfo: observable,
  disclaimerLinksInfo: observable,
  affiliates: observable,
  linkTypeInfo: observable,
  variantsByLinkId: observable,
  displayStyle: observable,
  events: observable,
  verticals: observable,
  variantLeagues: observable,
  updateVariants: observable,
  loadingVariantGroupInfo: observable,
  ctaPreviewDependencyChangeLog: observable,
  ctaPreviewDependency: observable,
  ctaPreviewDependencyBackup: observable,
  variantGroupInfo: observable,
  availableShortCodes: observable,
  randomShortCode: observable,
  shortCodeByAffiliate: observable,
  // actions
  getCompaniesInfo: action.bound,
  getOrganizationsInfo: action.bound,
  getRegionsInfo: action.bound,
  getLinkTypeInfo: action.bound,
  getIntentInfo: action.bound,
  getLinkInfo: action.bound,
  getLinkDisplayInfo: action.bound,
  getAffiliatesByOrgId: action.bound,
  getVariantsByLinkId: action.bound,
  getVariantDisplayStyle: action.bound,
  getVariantEvents: action.bound,
  getVariantVerticals: action.bound,
  getVariantLeagues: action.bound,
  getVariantByid: action.bound,
  getCTAPreviewDependencyChangeLog: action.bound,
  getVariantGroupInfo: action.bound,
  getAvailableShortCodes: action.bound,
  getRandomShortCode: action.bound,
  getShortCodeByAffiliate: action.bound,

  setCompaniesInfo: action.bound,
  setOrganizationsInfo: action.bound,
  setRegionsInfo: action.bound,
  setLinkTypeInfo: action.bound,
  setIntentInfo: action.bound,
  setCtaInfo: action.bound,
  setLinkInfo: action.bound,
  setOfferLinksInfo: action.bound,
  setDisclaimerLinksInfo: action.bound,
  setAffiliates: action.bound,
  setVariantsByLinkId: action.bound,
  setVariantDisplayStyle: action.bound,
  setVariantEvents: action.bound,
  setVariantVerticals: action.bound,
  setVariantLeagues: action.bound,
  postVariant: action.bound,
  setVariantById: action.bound,
  setCTAPreviewDependencyChangeLog: action.bound,
  setCTAPreviewDependency: action.bound,
  setVariantGroupInfo: action.bound,
  setLoadingVariantGroupInfo: action.bound,
  setAvailableShortCodes: action.bound,
  setRandomShortCode: action.bound,
  setShortCodeByAffiliate: action.bound
})
