import React, { useState, useEffect } from "react"
import ShowCheckOrStop from "components/NarrativeAdmin/Data/ShowCheckOrStop"

const ValidateTriggers = props => {
  const [triggerValueValid, setTriggerValueValid] = useState(false)
  const [triggerValueClear, setTriggerValueClear] = useState(!props.triggerType)

  useEffect(() => {
    if (props.triggerType) {
      checkLoadedTriggers()
      props.setTriggerValue(props.triggerType)
    }
  }, [])

  useEffect(() => {
    if (props.triggerValue) {
      confirmTriggerExists()
    } else {
      setTriggerValueClear(true)
    }
  }, [props.triggerValue, props.triggerBlurred])

  const checkLoadedTriggers = () => {
    const triggerList = props.triggerType?.split(",") || []
    if (triggerList.length) {
      let foundTrigger = true
      triggerList.forEach(itm => {
        const cleanTrigger = itm.replace(/^\s+!|^\s+|^!|\s+$/g, "")
        if (!props.triggerKeys.includes(cleanTrigger)) {
          foundTrigger = false
        }
      })
      setTriggerValueClear(false)
      setTriggerValueValid(foundTrigger)
    }
  }
  const confirmTriggerExists = event => {
    setTriggerValueClear(!props.triggerValue)
    const values = props.triggerValue?.split(",") || []
    let foundTrigger = true
    values?.forEach(itm => {
      const cleanTrigger = itm.replace(/^\s+!|^\s+|^!|\s+$/g, "")
      if (!props.triggerKeys.includes(cleanTrigger)) {
        foundTrigger = false
      }
    })
    if (foundTrigger && !triggerValueValid) {
      setTriggerValueValid(true)
    } else if (!foundTrigger && triggerValueValid) {
      setTriggerValueValid(false)
    }
  }

  return (
    <ShowCheckOrStop
      clearValue={triggerValueClear}
      valueIsValid={triggerValueValid}
      size={props.size || "40px"}
    />
  )
}

export default ValidateTriggers
