export const loadTierLevels = () => [
  {
    key: 1,
    name: "Tier 1",
    description: "no changes to base template."
  },
  {
    key: 2,
    name: "Tier 2",
    description:
      "can update base template with background graphic, colors, watermark logo."
  },
  {
    key: 3,
    name: "Tier 3",
    description: "fully custom, not created off of base templates."
  }
]

export const opacityMarks = [
  {
    value: 0,
    label: "0%"
  },
  {
    value: 10,
    label: "10%"
  },
  {
    value: 50,
    label: "50%"
  },
  {
    value: 90,
    label: "90%"
  },
  {
    value: 100,
    label: "100%"
  }
]

export const loadDefaultTextValues = () => [
  { key: 1, value: "text 1" },
  { key: 2, value: "text 2" },
  { key: 3, value: "text 3" },
  { key: 4, value: "text 4" },
  { key: 5, value: "text 5" },
  { key: 6, value: "text 6" },
  { key: 7, value: "text 7" },
  { key: 8, value: "text 8" },
  { key: 9, value: "text 9" },
  { key: 10, value: "text 10" },
  { key: 11, value: "text 11" },
  { key: 12, value: "text 12" },
  { key: 13, value: "text 13" },
  { key: 14, value: "text 14" },
  { key: 15, value: "text 15" },
  { key: 16, value: "text 16" },
  { key: 17, value: "text 17" },
  { key: 18, value: "text 18" },
  { key: 19, value: "text 19" },
  { key: 20, value: "text 20" }
]

export const loadSportsbooks = () => [
  {
    key: -1,
    name: "Not applicable"
  },
  {
    key: 1,
    name: "BetMGM"
  },
  {
    key: 2,
    name: "DraftKings"
  },
  {
    key: 3,
    name: "FanDuel"
  },
  {
    key: 4,
    name: "Caesars"
  }
]

export const loadBackgroundImageResizeChoices = () => [
  {
    key: 1,
    resizeMode: "crop",
    name: "Crop"
  },
  {
    key: 2,
    resizeMode: "pad",
    name: "Pad"
  },
  {
    key: 6,
    resizeMode: "stretch",
    name: "Stretch"
  }
]

export const loadWatermarkUrlChoices = () => [
  {
    key: 1,
    url: "none",
    name: "No watermark"
  },
  {
    key: 2,
    url: "",
    name: "Custom watermark"
  },
  {
    key: 3,
    url: "useorganizationlogo",
    name: "Organization's logo"
  },
  {
    key: 4,
    url: "https://cdn.dataskrive.com/api/asset/PIJm7FS.png",
    name: "8Count Media (for dark back)"
  },
  {
    key: 5,
    url: "https://cdn.dataskrive.com/api/asset/TiNLgq5.png",
    name: "8Count Media (for light back)"
  },
  {
    key: 6,
    url: "https://cdn.dataskrive.com/api/asset/YxhOiq0.png",
    name: "BetDecider"
  },
  {
    key: 7,
    url: "https://cdn.dataskrive.com/api/asset/bu6qsYz.png",
    name: "BetMGM"
  },
  {
    key: 8,
    url: "https://cdn.dataskrive.com/api/asset/06Wp4Dh.png",
    name: "Betsperts"
  },
  {
    key: 9,
    url: "https://cdn.dataskrive.com/api/asset/maTowRz.png",
    name: "Betsperts Player Props"
  },
  {
    key: 10,
    url: "https://cdn.dataskrive.com/api/asset/eCCDl0y.png",
    name: "Bleacher Nation"
  },
  {
    key: 11,
    url: "https://cdn.dataskrive.com/api/asset/YmRv9kU.png",
    name: "Bleacher Nation (Square)"
  },
  {
    key: 12,
    url: "https://cdn.dataskrive.com/api/asset/WDn33VI.png",
    name: "Caesar's Sportsbook"
  },
  {
    key: 13,
    url: "https://cdn.dataskrive.com/api/asset/TpQhSGQ.png",
    name: "Clutch Bet"
  },
  {
    key: 14,
    url: "https://cdn.dataskrive.com/api/asset/CWuDPPv.png",
    name: "Clutch Bet (white)"
  },
  {
    key: 15,
    url: "https://cdn.dataskrive.com/api/asset/MQZ3aTR.png",
    name: "DraftKings"
  },
  {
    key: 16,
    url: "https://cdn.dataskrive.com/api/asset/PYWuof9.png",
    name: "FanDuel Sportsbook"
  },
  {
    key: 17,
    url: "https://cdn.dataskrive.com/api/asset/qgvOnzq.png",
    name: "FuboTV"
  },
  {
    key: 18,
    url: "https://cdn.dataskrive.com/api/asset/DgCWtQ6.png",
    name: "Gray TV"
  },
  {
    key: 19,
    url: "https://cdn.dataskrive.com/api/asset/tfGPbtn.png",
    name: "KillerOdds"
  },
  {
    key: 28,
    url: "https://cdn.dataskrive.com/api/asset/iyxEfbX.png",
    name: "LiveTennis"
  },
  {
    key: 20,
    url: "https://cdn.dataskrive.com/api/asset/KnwNZRc.png",
    name: "NJ Sportsbook"
  },
  {
    key: 21,
    url: "https://cdn.dataskrive.com/api/asset/kMpzJ56.png",
    name: "PickBoss (for dark back)"
  },
  {
    key: 22,
    url: "https://cdn.dataskrive.com/api/asset/2qvsdnX.png",
    name: "PickBoss (for light back)"
  },
  {
    key: 23,
    url: "https://cdn.dataskrive.com/api/asset/v3lBWba.png",
    name: "Playpicks"
  },
  {
    key: 24,
    url: "poweredbydataskrivelogo",
    name: "Powered By Data Skrive"
  },
  {
    key: 25,
    url: "https://cdn.dataskrive.com/api/asset/1D0aDqF.png",
    name: "RealGM (for light back)"
  },
  {
    key: 26,
    url: "https://cdn.dataskrive.com/api/asset/D0GCJhN.png",
    name: "RealGM (for dark back)"
  },
  {
    key: 27,
    url: "https://cdn.dataskrive.com/api/asset/VEIC13X.png",
    name: "RevMasters-BetUS (for light back)"
  },
  {
    key: 28,
    url: "https://cdn.dataskrive.com/api/asset/aQkk6r4.png",
    name: "RevMasters-BetUS (for dark back)"
  },
  {
    key: 29,
    url: "https://cdn.dataskrive.com/api/asset/mUETN5L.png",
    name: "SportsPub"
  },
  {
    key: 30,
    url: "https://cdn.dataskrive.com/api/asset/RpL99ga.png",
    name: "The Lines"
  },
  {
    key: 31,
    url: "https://cdn.dataskrive.com/api/asset/25gocFM.png",
    name: "Tipico"
  }
]

export const loadBaseTemplates = () => [
  {
    key: 1,
    type: "playerimage",
    subType: "malebaseballplayer",
    name: "Player (baseball - male)",
    isArchived: true,
    textFieldCount: 11
  },
  {
    key: 2,
    type: "playerimage",
    subType: "malebasketballplayer",
    name: "Player (basketball - male)",
    isArchived: true,
    textFieldCount: 11
  },
  {
    key: 3,
    type: "playerimage",
    subType: "femalebasketballplayer",
    name: "Player (basketball - female)",
    isArchived: true,
    textFieldCount: 11
  },
  {
    key: 4,
    type: "playerimage",
    subType: "femaleesportsplayer",
    name: "Player (esports player - female)",
    isArchived: true,
    textFieldCount: 11
  },
  {
    key: 5,
    type: "playerimage",
    subType: "maleesportsplayer",
    name: "Player (esports player - male)",
    isArchived: true,
    textFieldCount: 11
  },
  {
    key: 6,
    type: "playerimage",
    subType: "teamesportsplayers",
    name: "Player (esports team)",
    isArchived: true,
    textFieldCount: 11
  },
  {
    key: 7,
    type: "playerimage",
    subType: "malefootballplayer",
    name: "Player (football - male)",
    isArchived: true,
    textFieldCount: 11
  },
  {
    key: 8,
    type: "playerimage",
    subType: "femalegolfplayer",
    name: "Player (golfer - female)",
    isArchived: true,
    textFieldCount: 11
  },
  {
    key: 9,
    type: "playerimage",
    subType: "malegolfplayer",
    name: "Player (golfer - male)",
    isArchived: true,
    textFieldCount: 11
  },
  {
    key: 10,
    type: "playerimage",
    subType: "malehockeyplayer",
    name: "Player (hockey player - male)",
    isArchived: true,
    textFieldCount: 11
  },
  {
    key: 11,
    type: "playerimage",
    subType: "malelacrosseplayer",
    name: "Player (lacrosse player - male)",
    isArchived: true,
    textFieldCount: 11
  },
  {
    key: 12,
    type: "playerimage",
    subType: "malesoccerplayer",
    name: "Player (soccer player - male)",
    isArchived: true,
    textFieldCount: 11
  },
  {
    key: 13,
    type: "playerimage",
    subType: "femalesoccerplayer",
    name: "Player (soccer player - female)",
    isArchived: true,
    textFieldCount: 11
  },
  {
    key: 14,
    type: "playerimage",
    subType: "femalesoftballplayer",
    name: "Player (softball player - female)",
    isArchived: true,
    textFieldCount: 11
  },
  {
    key: 15,
    type: "playerimage",
    subType: "femalenascardriver",
    name: "Player (NASCAR driver - female)",
    isArchived: true,
    textFieldCount: 11
  },
  {
    key: 16,
    type: "playerimage",
    subType: "malenascardriver",
    name: "Player (NASCAR driver - male)",
    isArchived: true,
    textFieldCount: 11
  },
  {
    key: 17,
    type: "playerimage",
    subType: "femaletennisplayer",
    name: "Player (tennis - female)",
    isArchived: true,
    textFieldCount: 11
  },
  {
    key: 18,
    type: "playerimage",
    subType: "maletennisplayer",
    name: "Player (tennis - male)",
    isArchived: true,
    textFieldCount: 11
  },
  {
    key: 19,
    type: "playerimage",
    subType: "femalevolleyballplayer",
    name: "Player (volleyball - female)",
    isArchived: true,
    textFieldCount: 11
  },
  {
    key: 20,
    type: "basketball4stats",
    subType: null,
    name: "Basketball w/4 Stats",
    isArchived: true
  },
  {
    key: 21,
    type: "headtohead",
    subType: null,
    name: "Head to Head",
    isArchived: true
  },
  {
    key: 22,
    type: "schoolversusschool43",
    subType: null,
    name: "School vs School (4:3)",
    isArchived: true,
    textFieldCount: 2
  },
  {
    key: 23,
    type: "schoolversusschool169",
    subType: null,
    name: "School vs School (16:9)",
    isArchived: true,
    textFieldCount: 2
  },
  {
    key: 24,
    type: "records",
    subType: "football_celebration",
    name: "Records (4:3) - Football Celebration",
    isArchived: true,
    textFieldCount: 5
  },
  {
    key: 25,
    type: "records",
    subType: "football_celebration2",
    name: "Records (4:3) - Football Celebration #2",
    isArchived: true,
    textFieldCount: 5
  },
  {
    key: 26,
    type: "records",
    subType: "football_player",
    name: "Records (4:3) - Football Player",
    isArchived: true,
    textFieldCount: 5
  },
  {
    key: 27,
    type: "records",
    subType: "football_quarterback",
    name: "Records (4:3) - Football Quarterback",
    isArchived: true,
    textFieldCount: 5
  },
  {
    key: 28,
    type: "records",
    subType: "football_receiver",
    name: "Records (4:3) - Football Receiver #1",
    isArchived: true,
    textFieldCount: 5
  },
  {
    key: 29,
    type: "records",
    subType: "football_receiver2",
    name: "Records (4:3) - Football Receiver #2",
    isArchived: true,
    textFieldCount: 5
  },
  {
    key: 30,
    type: "records",
    subType: "football_runner",
    name: "Records (4:3) - Football Runner #1",
    isArchived: true,
    textFieldCount: 5
  },
  {
    key: 31,
    type: "records",
    subType: "football_runner2",
    name: "Records (4:3) - Football Runner #2",
    isArchived: true,
    textFieldCount: 5
  },
  {
    key: 32,
    type: "records",
    subType: "football_runner3",
    name: "Records (4:3) - Football Runner #3",
    isArchived: true,
    textFieldCount: 5
  },
  {
    key: 33,
    type: "records",
    subType: "mbb_block",
    name: "Records (4:3) - MBB Block",
    isArchived: true,
    textFieldCount: 5
  },
  {
    key: 34,
    type: "records",
    subType: "mbb_celebration",
    name: "Records (4:3) - MBB Celebration #1",
    isArchived: true,
    textFieldCount: 5
  },
  {
    key: 35,
    type: "records",
    subType: "mbb_celebration2",
    name: "Records (4:3) - MBB Celebration #2",
    isArchived: true,
    textFieldCount: 5
  },
  {
    key: 36,
    type: "records",
    subType: "mbb_clap",
    name: "Records (4:3) - MBB Clap",
    isArchived: true,
    textFieldCount: 5
  },
  {
    key: 37,
    type: "records",
    subType: "mbb_dribble",
    name: "Records (4:3) - MBB Dribble #1",
    isArchived: true,
    textFieldCount: 5
  },
  {
    key: 38,
    type: "records",
    subType: "mbb_dribble2",
    name: "Records (4:3) - MBB Dribble #2",
    isArchived: true,
    textFieldCount: 5
  },
  {
    key: 39,
    type: "records",
    subType: "mbb_dribble3",
    name: "Records (4:3) - MBB Dribble #3",
    isArchived: true,
    textFieldCount: 5
  },
  {
    key: 41,
    type: "records",
    subType: "mbb_dunk",
    name: "Records (4:3) - MBB Dunk",
    isArchived: true,
    textFieldCount: 5
  },
  {
    key: 42,
    type: "records",
    subType: "mbb_layup",
    name: "Records (4:3) - MBB Layup",
    isArchived: true,
    textFieldCount: 5
  },
  {
    key: 43,
    type: "records",
    subType: "mbb_pass",
    name: "Records (4:3) - MBB Pass",
    isArchived: true,
    textFieldCount: 5
  },
  {
    key: 44,
    type: "records",
    subType: "mbb_player",
    name: "Records (4:3) - MBB Player",
    isArchived: true,
    textFieldCount: 5
  },
  {
    key: 45,
    type: "records",
    subType: "mbb_rebound",
    name: "Records (4:3) - MBB Rebound",
    isArchived: true,
    textFieldCount: 5
  },
  {
    key: 46,
    type: "records",
    subType: "mbb_shot",
    name: "Records (4:3) - MBB Shot #1",
    isArchived: true,
    textFieldCount: 5
  },
  {
    key: 47,
    type: "records",
    subType: "mbb_shot2",
    name: "Records (4:3) - MBB Shot #2",
    isArchived: true,
    textFieldCount: 5
  },
  {
    key: 48,
    type: "records",
    subType: "wbb_dribble",
    name: "Records (4:3) - WBB Dribble #1",
    isArchived: true,
    textFieldCount: 5
  },
  {
    key: 49,
    type: "records",
    subType: "wbb_dribble2",
    name: "Records (4:3) - WBB Dribble #2",
    isArchived: true,
    textFieldCount: 5
  },
  {
    key: 50,
    type: "records",
    subType: "wbb_layup",
    name: "Records (4:3) - WBB Layup #1",
    isArchived: true,
    textFieldCount: 5
  },
  {
    key: 51,
    type: "records",
    subType: "wbb_layup2",
    name: "Records (4:3) - WBB Layup #2",
    isArchived: true,
    textFieldCount: 5
  },
  {
    key: 52,
    type: "records",
    subType: "wbb_pass",
    name: "Records (4:3) - WBB Pass",
    isArchived: true,
    textFieldCount: 5
  },
  {
    key: 53,
    type: "records",
    subType: "wbb_point",
    name: "Records (4:3) - WBB Point",
    isArchived: true,
    textFieldCount: 5
  },
  {
    key: 54,
    type: "records",
    subType: "wbb_triplethreat",
    name: "Records (4:3) - WBB Triple Threat",
    isArchived: true,
    textFieldCount: 5
  },
  {
    key: 55,
    type: "records",
    subType: null,
    name: "Records (4:3) - No silhouette",
    isArchived: true,
    textFieldCount: 5
  },
  {
    key: 56,
    type: "twologos169",
    subType: null,
    name: "Two Logos (16:9)",
    isArchived: true,
    textFieldCount: 2
  },
  {
    key: 57,
    type: "nfldraft2020player",
    subType: "football_celebration",
    name: "NFL Draft 2020 (16:9) - Football Celebration",
    isArchived: true,
    textFieldCount: 2
  },
  {
    key: 58,
    type: "nfldraft2020player",
    subType: "football_celebration2",
    name: "NFL Draft 2020 (16:9) - Football Celebration #2",
    isArchived: true,
    textFieldCount: 2
  },
  {
    key: 59,
    type: "nfldraft2020player",
    subType: "football_player",
    name: "NFL Draft 2020 (16:9) - Football Player",
    isArchived: true,
    textFieldCount: 2
  },
  {
    key: 60,
    type: "nfldraft2020player",
    subType: "football_quarterback",
    name: "NFL Draft 2020 (16:9) - Football Quarterback",
    isArchived: true,
    textFieldCount: 2
  },
  {
    key: 61,
    type: "nfldraft2020player",
    subType: "football_receiver",
    name: "NFL Draft 2020 (16:9) - Football Receiver #1",
    isArchived: true,
    textFieldCount: 2
  },
  {
    key: 62,
    type: "nfldraft2020player",
    subType: "football_receiver2",
    name: "NFL Draft 2020 (16:9) - Football Receiver #2",
    isArchived: true,
    textFieldCount: 2
  },
  {
    key: 63,
    type: "nfldraft2020player",
    subType: "football_runner",
    name: "NFL Draft 2020 (16:9) - Football Runner #1",
    isArchived: true,
    textFieldCount: 2
  },
  {
    key: 64,
    type: "nfldraft2020player",
    subType: "football_runner2",
    name: "NFL Draft 2020 (16:9) - Football Runner #2",
    isArchived: true,
    textFieldCount: 2
  },
  {
    key: 65,
    type: "nfldraft2020player",
    subType: "football_runner3",
    name: "NFL Draft 2020 (16:9) - Football Runner #3",
    isArchived: true,
    textFieldCount: 2
  },
  {
    key: 66,
    type: "mybookieheadtohead169",
    subType: null,
    name: "MyBookie Head to Head (grass field) (16:9)",
    isArchived: true,
    textFieldCount: 2
  },
  {
    key: 67,
    type: "mybookieplayerprops169",
    subType: null,
    name: "MyBookie Player Props (football player) (16:9)",
    isArchived: true,
    textFieldCount: 1
  },
  {
    key: 68,
    type: "thelinesheadtoheadbasketball169",
    subType: null,
    name: "TheLines Head to Head (basketball court) (16:9)",
    isArchived: true,
    tierLevel: 3,
    organizationIds: [74]
  },
  {
    key: 69,
    type: "playpicksheadtoheadbasketball169",
    subType: null,
    name: "PlayPicks Head to Head (basketball player) (16:9)",
    isArchived: true,
    tierLevel: 3,
    organizationIds: [75]
  },
  {
    key: 70,
    type: "thelinesheadtoheadbaseball169",
    subType: null,
    name: "TheLines Head to Head (baseball field) (16:9)",
    isArchived: true,
    tierLevel: 3,
    organizationIds: [74]
  },
  {
    key: 71,
    type: "thelinesheadtoheadfootball169",
    subType: null,
    name: "TheLines Head to Head (football field) (16:9)",
    isArchived: true,
    tierLevel: 3,
    organizationIds: [74]
  },
  {
    key: 72,
    type: "playpicksheadtoheadbaseball169",
    subType: null,
    name: "PlayPicks Head to Head (baseball batter) (16:9)",
    isArchived: true,
    tierLevel: 3,
    organizationIds: [75]
  },
  {
    key: 73,
    type: "playpicksheadtoheadfootball169",
    subType: null,
    name: "PlayPicks Head to Head (football chalkboard) (16:9)",
    isArchived: true,
    tierLevel: 3,
    organizationIds: [75]
  },
  {
    key: 74,
    type: "nbaheadtoheadcourt",
    subType: null,
    name: "NBA Head to Head (basketball court)",
    isArchived: true,
    textFieldCount: 2
  },
  {
    key: 75,
    type: "bestoddsmoneylineaction",
    subType: null,
    name: "BestOdds.com Money Line Action (1:1)",
    isArchived: true,
    textFieldCount: 4
  },
  {
    key: 76,
    type: "nflheadtoheadfield",
    subType: null,
    name: "SGPN NFL Head to Head (field)",
    isArchived: true,
    textFieldCount: 0
  },
  {
    key: 78,
    type: "sgpnteamvsteamcfb1to1ratio", // college football
    subType: null,
    name: "SGPN college football team vs team (1:1)",
    isArchived: true,
    textFieldCount: 0
  },
  {
    key: 79,
    type: "sgpnteamvsteamcfb169ratio", // college football
    subType: null,
    name: "SGPN college football team vs team (16:9)",
    isArchived: true,
    textFieldCount: 0
  },
  {
    key: 80,
    type: "TheLinesNBAPlayerPropsJerseyTexture",
    subType: null,
    name: "TheLines NBA Player Props (jersey texture)",
    isArchived: true,
    tierLevel: 3,
    organizationIds: [74]
  },
  {
    key: 81,
    type: "sgpnteamvsteamncaambb169ratio", // men's NCAA college basketball
    subType: null,
    name: "SGPN men's college basketball team vs team (16:9)",
    isArchived: true,
    textFieldCount: 0
  },
  {
    key: 82,
    type: "sgpnteamvsteamnhl169ratio", // NHL
    subType: null,
    name: "SGPN NHL team vs team (16:9)",
    isArchived: true,
    textFieldCount: 0
  },
  {
    key: 83,
    type: "sgpnteamvsteamnba169ratio", // NBA
    subType: null,
    name: "SGPN NBA team vs team (16:9)",
    isArchived: true,
    textFieldCount: 0
  },
  {
    key: 84,
    type: "sgpnnflteamplayer169ratio", // NFL team specific
    subType: null,
    name: "SGPN NFL team player (16:9)",
    isArchived: true
  },
  {
    key: 85,
    type: "sgpnmlbteamvsteam169ratio", // SGPN MLB team vs team
    subType: null,
    name: "SGPN MLB team vs team (16:9)",
    isArchived: true,
    textFieldCount: 0
  },
  {
    key: 86,
    type: "sgpnmlbplayerprops169ratio", // SGPN MLB player props
    subType: null,
    name: "SGPN MLB player props (16:9)",
    isArchived: true
  },
  {
    key: 90,
    type: "catenateamvsteamnhl169ratio",
    subType: null,
    name: "Catena NHL Head to Head (16:9)",
    isArchived: true,
    textFieldCount: 4
  },
  {
    key: 91,
    type: "catenateamvsteamnhldiagonal169ratio",
    subType: null,
    name: "Catena NHL Head to Head (diagonal) (16:9)",
    isArchived: true,
    textFieldCount: 4
  },
  {
    key: 92,
    type: "forthewinheadtohead169ratio", // For the Win
    subType: "epl",
    name: "For the Win (EPL) Head to Head (16:9)",
    isArchived: true
  },
  {
    key: 93,
    type: "forthewinheadtohead169ratio", // For the Win
    subType: "seriea",
    name: "For the Win (Serie A (Italy)) Head to Head (16:9)",
    isArchived: true
  },
  {
    key: 94,
    type: "forthewinheadtohead169ratio", // For the Win
    subType: "uefachampionsleague",
    name: "For the Win (UEFA Champions League) Head to Head (16:9)",
    isArchived: true
  },
  {
    key: 95,
    type: "forthewinheadtohead169ratio", // For the Win
    subType: "laligaspain",
    name: "For the Win (LaLiga (Spain)) Head to Head (16:9)",
    isArchived: true
  },
  {
    key: 96,
    type: "forthewinheadtohead169ratio", // For the Win
    subType: "serieabrazil",
    name: "For the Win (Série A (Brazil)) Head to Head (16:9)",
    isArchived: true
  },
  {
    key: 97,
    type: "forthewinheadtohead169ratio", // For the Win
    subType: "eredivisie",
    name: "For the Win (Eredivisie (Netherlands)) Head to Head (16:9)",
    isArchived: true
  },
  {
    key: 98,
    type: "forthewinheadtohead169ratio", // For the Win
    subType: "laligamx",
    name: "For the Win (La Liga MX (Mexico)) Head to Head (16:9)",
    isArchived: true
  },
  {
    key: 99,
    type: "forthewinheadtohead169ratio", // For the Win
    subType: "ligue1",
    name: "For the Win (Ligue 1 (France)) Head to Head (16:9)",
    isArchived: true
  },
  {
    key: 100,
    type: "forthewinheadtohead169ratio", // For the Win
    subType: "primeiraligaportugal",
    name: "For the Win (Primeira Liga (Portugal)) Head to Head (16:9)",
    isArchived: true
  },
  {
    key: 101,
    type: "forthewinheadtohead169ratio", // For the Win
    subType: "primeradivisionargentina",
    name: "For the Win (Primera División (Argentina)) Head to Head (16:9)",
    isArchived: true
  },
  {
    key: 102,
    type: "forthewinheadtohead169ratio", // For the Win
    subType: "superligturkey",
    name: "For the Win (Süper Lig (Turkey)) Head to Head (16:9)",
    isArchived: true
  },
  {
    key: 103,
    type: "forthewinheadtohead169ratio", // For the Win
    subType: "mls",
    name: "For the Win (MLS) Head to Head (16:9)",
    isArchived: true
  },
  {
    key: 104,
    type: "forthewinheadtohead169ratio", // For the Win
    subType: "bundesliga",
    name: "For the Win (Bundesliga (Germany)) Head to Head (16:9)",
    isArchived: true
  },
  {
    key: 105,
    type: "uefachampionsleaguefubotvnews", // FuboTV News
    subType: null,
    name: "FuboTV (News) UEFA Champions League Head to Head (16:9)",
    isArchived: true,
    textFieldCount: 2
  },
  {
    key: 106,
    type: "uefachampionsleaguefubotvforsportsillustrated", // FuboTV for SI
    subType: null,
    name: "FuboTV (SI) UEFA Champions League Head to Head (16:9)",
    isArchived: true,
    textFieldCount: 2
  },
  {
    key: 107,
    type: "wnbateamversusteam169ratio", // For the Win
    subType: "wnba",
    name: "WNBA team vs team (16:9)",
    isArchived: true,
    textFieldCount: 4
  },
  {
    key: 108,
    type: "wimbledon",
    subType: "betdecider",
    name: "Wimbledon BetDecider (16:9)",
    isArchived: true,
    textFieldCount: 16
  },
  {
    key: 109,
    type: "wimbledon",
    subType: "fubonews",
    name: "Wimbledon Fubo News (16:9)",
    isArchived: true,
    textFieldCount: 16
  },
  {
    key: 110,
    type: "wimbledon",
    subType: "njsportsbook",
    name: "Wimbledon NJ Sportsbook (16:9)",
    isArchived: true,
    textFieldCount: 16
  },
  {
    key: 111,
    type: "wimbledon",
    subType: "usatodayssports",
    name: "Wimbledon USA Today Sports (16:9)",
    isArchived: true,
    textFieldCount: 16
  },
  {
    key: 112,
    type: "wimbledon",
    subType: "sportsillustrated",
    name: "Wimbledon Sports Illustrated (16:9)",
    isArchived: true,
    textFieldCount: 16
  },
  {
    key: 113,
    type: "betspertsmlb",
    subType: null,
    name: "BetSperts MLB (16:9)",
    isArchived: true,
    tierLevel: 3,
    organizationIds: [269, 1094]
  },
  {
    key: 114,
    type: "wimbledon",
    subType: "njsportsbook1to1ratio",
    name: "Wimbledon NJ Sportsbook (1:1)",
    isArchived: true
  },
  {
    key: 115,
    type: "forthewinheadtohead169ratio", // For the Win
    subType: "uefaworldcupqualification",
    name: "For the Win (UEFA Qualifiers) Head to Head (16:9)",
    isArchived: true
  },
  {
    key: 116,
    type: "headtoheadwithslants",
    subType: null,
    name: "Standard Head to Head w/ Slants (16:9)",
    textFieldCount: 3
  },
  {
    key: 117,
    type: "standardplayerversusplayertable",
    subType: null,
    name: "Standard Player vs Player Comparison/Table (16:9)",
    textFieldCount: 15
  },
  {
    key: 118,
    type: "catenasportsbookpromo",
    subType: null,
    name: "Catena Sportsbook Promo (16:9)",
    tierLevel: 3,
    organizationIds: [55],
    textFieldCount: 3
  },
  {
    key: 119,
    type: "StandardHeadToHeadTight",
    subType: null,
    name: "Standard Head to Head Tight (16:9)",
    textFieldCount: 2
  },
  {
    key: 120,
    type: "StandardHeadToHeadWide",
    subType: null,
    name: "Standard Head to Head Wide (16:9)",
    textFieldCount: 2
  },
  {
    key: 121,
    type: "BetspertsHeadToHeadStacked",
    subType: null,
    name: "Betsperts Head to Head stacked (16:9)",
    tierLevel: 3,
    organizationIds: [269, 1094],
    textFieldCount: 4
  },
  {
    key: 122,
    type: "StandardPlayerTeamPropsCentered",
    subType: null,
    name: "Standard Player/Team Props Logo Centered (16:9)",
    textFieldCount: 2
  },
  {
    key: 123,
    type: "StandardPlayerTeamPropsRight",
    subType: null,
    name: "Standard Player/Team Props Logo Right (16:9)",
    textFieldCount: 2
  },
  {
    key: 124,
    type: "StandardHeadToHeadWithLeagueTextLine",
    subType: null,
    name: "Standard Head to Head w/ League Text Line (16:9)",
    textFieldCount: 2
  },
  {
    key: 125,
    type: "StandardPlayerTeamPropsLeft",
    subType: null,
    name: "Standard Player/Team Props Logo Left (16:9)",
    textFieldCount: 2
  },
  {
    key: 126,
    type: "standardplayerversusplayercomparison",
    subType: null,
    name: "Standard Player vs Player Comparison/Windows (16:9)",
    textFieldCount: 19
  },
  {
    key: 127,
    type: "StandardLibraryPlayervsPlayerComparisonCard",
    subType: null,
    name: "Standard Player vs Player Comparison/Cards (16:9)",
    textFieldCount: 13
  },
  {
    key: 128,
    type: "standardplayerversusplayertable",
    subType: "1to1",
    name: "Standard Player vs Player Comparison/Table (1:1)",
    textFieldCount: 15
  },
  {
    key: 129,
    type: "catenaheadtoheadmultisport",
    subType: null,
    name: "Catena Head to Head multisport (16:9)",
    tierLevel: 3,
    organizationIds: [55],
    textFieldCount: 3
  },
  {
    key: 130,
    type: "StandardPlayerTeamPerformanceStatsCentered",
    subType: null,
    name: "Standard Player/Team Performance/Stats Centered (16:9)",
    textFieldCount: 11
  },
  {
    key: 131,
    type: "StandardPlayerTeamPerformanceStatsLeft",
    subType: null,
    name: "Standard Player/Team Performance/Stats Left (16:9)",
    textFieldCount: 13
  },
  {
    key: 132,
    type: "StandardPlayerTeamPerformanceStatsRight",
    subType: null,
    name: "Standard Player/Team Performance/Stats Right (16:9)",
    textFieldCount: 11
  },
  {
    key: 133,
    type: "BannerImageHeadToHeadWithQRCode970x250",
    subType: null,
    name: "Standard Banner Head to Head w/QR code (970x250)",
    textFieldCount: 2
  },
  {
    key: 134,
    type: "BannerImageHeadToHead970x90",
    subType: null,
    name: "Standard Banner Head to Head (970x90)",
    textFieldCount: 2
  },
  {
    key: 135,
    type: "StandardPlayerTeamLeagueCentered",
    subType: null,
    name: "Standard Player to Team/League Centered",
    textFieldCount: 4
  },
  {
    key: 136,
    type: "StandardTextOnly",
    subType: null,
    name: "Standard Text Only (16:9)",
    textFieldCount: 10
  },
  {
    key: 137,
    type: "StandardCTALogoLeft",
    subType: null,
    name: "Standard CTA Logo Left (1275x300)",
    textFieldCount: 3
  },
  {
    key: 138,
    type: "StandardCTALogoRight",
    subType: null,
    name: "Standard CTA Logo Right (1275x300)",
    textFieldCount: 3
  },
  {
    key: 139,
    type: "StandardBrandTextButton970x90",
    subType: null,
    name: "Standard Banner Brand Text Button (970x90)",
    textFieldCount: 3
  },
  {
    key: 140,
    type: "FoxSportsStandardHeadToHeadWide",
    subType: null,
    name: "Fox Sports Head to Head Wide (16:9)",
    tierLevel: 3,
    organizationIds: [1061, 1160],
    textFieldCount: 0
  },
  {
    key: 141,
    type: "StandardTextOnlyTwoFields",
    subType: null,
    name: "Standard Text Only - 2 Fields (16:9)",
    textFieldCount: 2
  }
]
