import React, { Component } from "react"
import Button from "components/CustomButtons/Button"
import ReactTable from "react-table-6"
import { Tab, Tabs } from "@mui/material"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardIcon from "components/Card/CardIcon.jsx"
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle"
import withStyles from "@mui/styles/withStyles"
import { Link } from "react-router-dom"
import Clearfix from "components/Clearfix/Clearfix.jsx"
import { Visibility, Storage } from "@mui/icons-material"
import TimeAgo from "react-timeago"
import Helpers from "tools/Helpers.js"
import { TabPanel } from "components/TabPanel/TabPanel"
import { globalSettings } from "variables/general.jsx"
import { get, put } from "tools/request"

class QAReports extends Component {
  constructor(props) {
    super(props)
    const currentReportId =
      props.selectedReportId || (props.match && props.match.params.reportId)
    const currentView = currentReportId ? 1 : 0
    this.state = {
      currentReportId,
      reportName: "",
      reportDescription: "",
      reportIsActive: false,
      queryName: "",
      queryDescription: "",
      queryIsActive: false,
      allReportQueries: [],
      allReportInput: [
        { value: 0, display: "Active Narratives" },
        { value: 1, display: "Active Feeds" },
        { value: 2, display: "Custom Input" }
      ],
      currentView,
      allNarratives: [],
      narrativeReports: [],
      reportQuery: "select * from organization;",
      reportQueryId: 0,
      reportInputId: 0,
      reportIsRunning: false,
      allNarrativesLoading: false,
      isReportSaved: false,
      isReportDataDirty: false,
      pageSize: 25,
      tableFilter: [],
      reportsPageCount: 25,
      reportsTotalCount: 0,
      reportResultCode: 0,
      reportResultMessage: "",
      reportResults: {},
      reactTableResults: [],
      dynamicColumns: [],
      inputTokens: [{ tokenName: "", tokenValue: "" }],
      tableHistoryFilter: [],
      allHistoryLoading: false,
      currentReportHistoryId: 0,
      currentReportName: "",
      allReportHistory: [],
      showReportDetails: true,
      showQAReports: true,
      showReportInput: false
    }
  }

  componentDidMount() {
    if (!this.state.allNarrativesLoaded) {
      this.loadAllNarratives(this.state.narrativesPageCount)
    }
    let propReportId =
      this.props.selectedReportId ||
      (this.props.match && this.props.match.params.reportId)
    if (propReportId) {
      this.loadSingleReport(propReportId)
    }
  }

  componentDidUpdate() {
    let propReportId =
      this.props.selectedReportId ||
      (this.props.match && this.props.match.params.reportId)
    if (
      !this.state.isCreateMode &&
      propReportId &&
      this.state.currentReportId !== propReportId
    ) {
      this.setState({
        currentReportId: propReportId,
        currentView: propReportId ? 1 : 0,
        tableFilter: [
          {
            id: "id",
            value: String(propReportId)
          }
        ]
      })
    }
  }

  runReport = () => {
    this.setState({ reportIsRunning: true, reactTableResults: [] })
    this.mapResultsColumns({})
    put(`${globalSettings.apiBaseUrl}/api/reportbuilder/runreport`, null, {
      id: this.state.currentReportId,
      name: this.state.reportName,
      description: this.state.reportDescription,
      isActive: this.state.reportIsActive,
      queryName: this.state.queryName,
      queryDescription: this.state.queryDescription,
      queryIsActive: this.state.queryIsActive,
      reportQueryId: this.state.reportQueryId,
      reportQuerySQL: this.state.reportQuery,
      inputTokens: this.state.inputTokens
    })
      .then(response => response.json())
      .then(data => {
        this.setState({
          reportResultCode: data.responseCode,
          reportResultMessage: data.responseMessage
        })
        if (data.content.reportResults !== null) {
          this.setState({
            reactTableResults: data.content.reportResults.records
          })
          this.mapResultsColumns(data.content.reportResults.columns)
        } else {
          this.setState({ reactTableResults: [] })
          this.mapResultsColumns({})
        }
        this.setState({ reportIsRunning: false })
        this.loadReportHistory(this.state.currentReportId)
      })
  }

  mapResultsColumns = reportResultsColumns => {
    const columns = Object.keys(reportResultsColumns).map(key => ({
      Header: reportResultsColumns[key],
      accessor: reportResultsColumns[key]
    }))
    this.setState({ dynamicColumns: columns })
  }

  executeRunReport = () => {
    this.runReport()
  }

  executeSaveReport = () => {
    this.saveReport()
  }

  onTextAreaChange = e => {
    this.setState({ reportQuery: e.target.value, isReportDataDirty: true })
  }

  onReportQueryChange = event => {
    this.setState({
      reportQueryId: event.target.value
    })

    this.loadQueryInfo(event.target.value)
  }

  selectReport(reportId) {
    this.setState({
      id: "",
      name: ""
    })
    this.props.onChange && this.props.onChange(reportId)
  }

  onLoadReportButtonClicked = historyId => {
    if (!historyId) {
      return null
    }
    this.setState({
      currentReportHistoryId: historyId
    })
    get(
      `${globalSettings.apiBaseUrl}/api/reportbuilder/loadreporthistory/${historyId}`
    )
      .then(response => response.json())
      .then(data => {
        this.setState({
          currentReportName: data.content.reportName,
          reportQuery: data.content.reportSql
        })
        if (data.content.reportResults !== null) {
          this.setState({
            reactTableResults: data.content.reportResults.records
          })
          this.mapResultsColumns(data.content.reportResults.columns)
        } else {
          this.setState({ reactTableResults: [] })
          this.mapResultsColumns({})
        }
        this.setState({ reportIsRunning: false })
      })
  }

  loadSingleReport = reportId => {
    if (!reportId) {
      return null
    }
    get(
      `${globalSettings.apiBaseUrl}/api/reportbuilder/retrievereport/${reportId}`
    )
      .then(response => response.json())
      .then(data => {
        if (data.responseCode === 1000 && data.content) {
          this.setState({
            currentReportId: data.content.id,
            reportName: data.content.name,
            reportDescription: data.content.description,
            reportIsActive: data.content.isActive,
            reportQueryId: data.content.reportQueryId,
            queryName: data.content.reportQueryName,
            queryDescription: data.content.reportQueryDescription,
            queryIsActive: data.content.reportQueryIsActive,
            reportQuery: data.content.reportQuery
          })
          if (data.content.inputValues) {
            this.setState({
              inputTokens: data.content.inputValues
            })
          }
          if (data.content) {
            this.runReport()
          }
          this.loadReportHistory(reportId)
        }
      })
  }

  loadReportHistory = reportId => {
    if (!reportId) {
      return null
    }
    this.setState({ allHistoryLoading: true })
    get(
      `${globalSettings.apiBaseUrl}/api/reportbuilder/retrieveallreporthistory/${reportId}?pagecount=25`
    )
      .then(response => response.json())
      .then(data => {
        this.setState({
          allReportHistory: data.content,
          allHistoryLoading: false
        })
      })
  }

  loadAllNarratives = () => {
    this.setState({ allNarrativesLoading: true })
    get(
      `${
        globalSettings.apiBaseUrl
      }/api/reportbuilder/retrieveallnarratives?pagecount=${0}`
    )
      .then(response => response.json())
      .then(data => {
        this.setState({
          allNarratives: data.content,
          allNarrativesLoading: false,
          allNarrativesLoaded: true
        })
      })
  }

  loadNarrativeReportHistory(narrativeId) {
    get(
      `${globalSettings.apiBaseUrl}/api/reportbuilder/retrievereportsfornarrative/${narrativeId}`
    )
      .then(Response => Response.json())
      .then(data => {
        this.setState({
          narrativeReports: data.content
        })
      })
  }

  showReportDetails = () => {
    this.setState({ currentView: 1 })
  }

  onChangeReportsPageCount = event => {
    this.setState({ reportsPageCount: event.target.value })
    this.loadAllNarratives(event.target.value)
  }

  render() {
    const { classes } = this.props
    const { currentView } = this.state

    const handleChange = (event, newValue) => {
      this.setState({
        currentView: newValue
      })
    }

    return (
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <Storage />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>QA Reports</h4>
        </CardHeader>
        <CardBody>
          <Tabs
            value={currentView}
            onChange={handleChange}
            aria-label="QAReports Tabs"
          >
            <Tab label="Narratives" />
          </Tabs>
          <div>
            <TabPanel value={currentView} index={0}>
              <ReactTable
                className="-striped -highlight"
                data={this.state.allNarratives}
                expanded={this.state.expanded}
                onExpandedChange={(newExpanded, index, rowinfo, event) => {
                  if (newExpanded[index[0]] === false) {
                    newExpanded = {}
                  } else {
                    Object.keys(newExpanded).map(k => {
                      newExpanded[k] = parseInt(k) === index[0] ? {} : false
                    })
                    this.loadNarrativeReportHistory(event.original.id)
                  }
                  this.setState({
                    ...this.state,
                    expanded: newExpanded
                  })
                }}
                SubComponent={() => (
                  <div style={{ padding: "20px" }}>
                    <ReactTable
                      className="-striped -highlight"
                      data={this.state.narrativeReports}
                      columns={[
                        {
                          Header: "Id",
                          width: 100,
                          accessor: "id"
                        },
                        {
                          Header: "Report Name",
                          accessor: "reportName",
                          width: 250
                        },
                        {
                          Header: "Actions",
                          Cell: cell => (
                            <div className="actions-right">
                              <Link
                                to={`/portal/report-viewer/${cell.original.reportId}?historyId=${cell.original.id}`}
                                title="View"
                                target="_blank"
                              >
                                <Button
                                  justIcon
                                  round
                                  simple
                                  color="info"
                                  className="info"
                                >
                                  <Visibility />
                                </Button>
                              </Link>
                            </div>
                          ),
                          sortable: false,
                          filterable: false,
                          width: 100
                        },
                        {
                          Header: "Created",
                          accessor: "auditInfo.createdOn",
                          width: 400,
                          Cell: cell => (
                            <div>
                              <TimeAgo
                                date={cell.original.auditInfoResponse.createdOn}
                                title={Helpers.prettyDateTimeinPacificTimeZone(
                                  cell.original.auditInfoResponse.createdOn
                                )}
                              />
                            </div>
                          )
                        }
                      ]}
                      defaultPageSize={25}
                      defaultSorted={[
                        {
                          id: "id",
                          desc: true
                        }
                      ]}
                    />
                  </div>
                )}
                columns={[
                  {
                    Header: "Narrative ID",
                    accessor: "id",
                    width: 100
                  },
                  {
                    Header: "Name",
                    accessor: "name",
                    width: 300
                  },
                  {
                    Header: "Report Count",
                    accessor: "reportCount",
                    width: 200
                  },
                  {
                    Header: "Modified",
                    accessor: "auditInfo.modifiedOn",
                    width: 400,
                    Cell: cell => (
                      <div>
                        <TimeAgo
                          date={cell.original.auditInfoResponse.modifiedOn}
                          title={Helpers.prettyDateTimeinPacificTimeZone(
                            cell.original.auditInfoResponse.modifiedOn
                          )}
                        />
                      </div>
                    )
                  }
                ]}
                defaultPageSize={25}
                defaultSorted={[
                  {
                    id: "auditInfo.modifiedOn",
                    desc: false
                  }
                ]}
                pageSize={25}
              />
              <Clearfix />
            </TabPanel>
          </div>
        </CardBody>
      </Card>
    )
  }
}

export default withStyles(regularFormsStyle)(QAReports)
