import React, { useMemo, useState, useEffect } from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import WebhookDAO from "daos/webhookDAO"

import CustomTable from "components/Table/CustomTable"
import TimeAgo from "react-timeago"
import Helpers from "tools/Helpers.js"
import { CopyToClipboard } from "react-copy-to-clipboard"
//Icons
import IconButton from "@mui/material/IconButton"
import EditIcon from "@mui/icons-material/Edit"
import InfoIcon from "@mui/icons-material/InfoOutlined"
//import CloseIcon from "@mui/icons-material/Close"
import ArrowRightIcon from "@mui/icons-material/ArrowRight"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
//MUI imports
import FileCopy from "@mui/icons-material/FileCopy"
import Tooltip from "@mui/material/Tooltip"
import { makeStyles } from "@mui/styles"
import Button from "components/CustomButtons/Button.jsx"
import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"
import GridItem from "components/Grid/GridItem"
import CustomDSDialog from "components/CustomDialogs/CustomDSDialog"
import LinearProgress from "@mui/material/LinearProgress"

const useStyles = makeStyles({
  webhookText: {
    marginLeft: "9px",
    fontWeight: 400,
    lineHeight: 1.75,
    marginBottom: "9px",
    textAlign: "center"
  },
  infoButton: {
    padding: 0
  },
  infoHeaderIcon: {
    textAlign: "center",
    color: "#fff",
    backgroundColor: "#4d9ab5"
  },
  dialogIcon: {
    fontSize: "6rem",
    color: "#fff"
  },
  archiveSwitch: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "36px"
  },
  progressForLogTable: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "primary"
  }
})

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization,
    organizationId: store.organizationStore.organizationId,
    account: store.accountStore.account,
    webhookId: store.webhookStore.webhookId,
    getInboundWebhookByOrg: store.webhookStore.getInboundWebhookByOrg,
    loadInboundWebhookLogs: store.webhookStore.loadInboundWebhookLogs
  }))
}
export default function InBoundWebhookTable() {
  const classes = useStyles()
  const { setShowChangeCurrentOrganization, organizationId } = useStoreData()

  const [loading, setLoading] = useState(false)
  const [logLoading, setLogLoading] = useState(false)
  const [inboundWebhooks, setInboundWebhooks] = useState([])
  const [inboundWebhookLogs, setInboundWebhookLogs] = useState([])
  const [expanded, setExpanded] = useState({})
  const [open, setOpen] = useState(false)
  const [copySuccessText, setCopySuccessText] = useState("")
  const [isArchived, setIsArchived] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const loadInboundWebhooks = organizationId => {
    WebhookDAO.getInboundWebhookByOrg(organizationId)
      .then(data => {
        setInboundWebhooks(data.content)
      })
      .catch(error => {
        console.error("Error: inboundWebhookOrg", error)
      })
    setLoading(false)
  }

  const loadInboundWebhookLogs = async webhookId => {
    setLogLoading(true)
    try {
      const data = await WebhookDAO.loadInboundWebhookLogs(webhookId)
      setInboundWebhookLogs(data.content)
    } catch (error) {
      console.error("Error: inboundWebhookLogs", error)
    } finally {
      setLogLoading(false)
    }
  }

  const onExpandedChange = (newExpanded, rowIndex, event) => {
    const webhookId = inboundWebhooks[rowIndex].id
    if (newExpanded[rowIndex[0]] === false) {
      setExpanded({}) // Collapse all rows
    } else {
      const updatedExpanded = {}
      Object.keys(newExpanded).forEach(k => {
        updatedExpanded[k] = k === rowIndex[0] ? webhookId : false
      })
      setExpanded(updatedExpanded)
      loadInboundWebhookLogs(webhookId)
    }
  }

  const truncate = (value, len) => {
    if (value === null) {
      return value
    }
    return value.length > len ? `${value.substring(0, len - 3)}...` : value
  }

  const inboundWebhookFilterData = inboundWebhooks?.map(webhook => ({
    ...webhook,
    isArchived: webhook.isArchived ? "True" : "False",
    webhookStatusName: Helpers.convertWebhookStatusIdToStatusName(
      webhook.webhookStatusId
    ),
    deliveryTypeName: Helpers.renderFriendlyDeliveryTypeName(
      webhook.deliveryTypeId
    ),
    authentication: webhook.authenticationRequired ? "YES" : "NO"
  }))
  const inboundColumns = useMemo(() => [
    {
      id: "expander",
      cell: ({ row }) => (
        <IconButton
          onClick={() =>
            onExpandedChange(expanded, row.index, row.toggleExpanded())
          }
          style={{ cursor: "pointer" }}
          size="large"
        >
          {row.getIsExpanded() ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
        </IconButton>
      ),
      width: 25
    },
    {
      header: "ID",
      accessor: "id",
      cell: ({ cell, row }) => (
        <div
          style={
            isArchived && row.original.isArchived
              ? { textDecoration: "line-through" }
              : {}
          }
        >
          {cell.getValue()}
        </div>
      ),
      width: 25,
      sortable: true
    },
    {
      header: "Actions",
      id: "actions",
      cell: row => (
        <IconButton
          color="primary"
          onClick={() => handleClickOpen()}
          size="large"
        >
          {/* {row.getValue()} */}
          <EditIcon />
        </IconButton>
      ),
      width: 50,
      sortable: false
    },
    {
      header: "Name",
      accessor: "description",
      cell: ({ cell, row }) => (
        <div
          style={
            isArchived && row.original.isArchived
              ? { textDecoration: "line-through" }
              : {}
          }
        >
          {cell.getValue()}
        </div>
      ),
      width: 100,
      sortable: true,
      filterable: true
    },
    {
      header: "Webhook URL",
      accessor: "guidToken",
      cell: ({ cell, row }) => {
        let inboundURL = `https://api.dataskrive.com/api/webhook/${cell.getValue()}`
        return (
          <div
            style={
              isArchived && row.original.isArchived
                ? { textDecoration: "line-through" }
                : {}
            }
          >
            {inboundURL}
            <CopyToClipboard
              text={inboundURL}
              onCopy={() => setCopySuccessText("URL Copied.")}
            >
              <Tooltip title={copySuccessText} placement="top">
                <Button round simple color="primary">
                  <FileCopy />
                </Button>
              </Tooltip>
            </CopyToClipboard>
          </div>
        )
      },
      filterable: true,
      width: 300
    },
    {
      header: "HTTP Method",
      accessor: "externalHTTPMethod",
      cell: ({ getValue, row }) => (
        <div
          style={
            isArchived && row.original.isArchived
              ? { textDecoration: "line-through" }
              : {}
          }
        >
          {getValue()}
        </div>
      ),
      width: 75,
      sortable: true,
      filterable: true
    },
    {
      header: "Auth Required",
      accessor: "authenticationRequired",
      cell: ({ cell, row }) => (
        <div
          style={
            isArchived && row.original.isArchived
              ? { textDecoration: "line-through" }
              : {}
          }
        >
          {cell.getValue() ? <span>YES</span> : <span>NO</span>}
        </div>
      ),
      width: 25,
      // sortable: true,
      filterable: true
    },
    {
      header: "Audit Info",
      accessor: "auditInfo",
      cell: ({ cell, row }) => {
        const auditInfo = cell.getValue()
        if (!auditInfo) {
          return "None"
        }
        return (
          <div
            style={
              isArchived && row.original.isArchived
                ? { textDecoration: "line-through" }
                : {}
            }
          >
            <b>created </b>
            <TimeAgo
              date={auditInfo.createdOn}
              title={Helpers.prettyDateTimeinPacificTimeZone(
                auditInfo.createdOn
              )}
            />{" "}
            by {auditInfo.creator.username} <br />
            <b>modified </b>
            <TimeAgo
              date={auditInfo.modifiedOn}
              title={Helpers.prettyDateTimeinPacificTimeZone(
                auditInfo.modifiedOn
              )}
            />{" "}
            by {auditInfo.modifier.username}
          </div>
        )
      },
      width: 200,
      sortable: true,
      filterable: true
    },
    {
      header: "Webhook Status",
      accessor: "webhookStatusId",
      cell: ({ cell, row }) => (
        <div
          style={
            isArchived && row.original.isArchived
              ? { textDecoration: "line-through" }
              : {}
          }
        >
          {Helpers.convertWebhookStatusIdToStatusName(cell.getValue())}
        </div>
      ),
      width: 100,
      sortable: true,
      filterable: true
    }
  ])

  const inboundWebhookFilter = [
    {
      filterName: "ID",
      columnId: "id"
    },
    {
      filterName: "Name",
      columnId: "description"
    },
    {
      filterName: "Webhook URL",
      columnId: "guidToken"
    },
    {
      filterName: "Delivery Type",
      columnId: "deliveryTypeName"
    },
    {
      filterName: "Delivery Cadence",
      columnId: "deliveryCadence"
    },
    {
      filterName: "HTTP Method",
      columnId: "externalHTTPMethod"
    },
    {
      filterName: "Webhook Status",
      columnId: "webhookStatusName"
    },
    {
      filterName: "Authentication Required",
      columnId: "authentication"
    }
  ].map(filter => {
    const obj = {
      ...filter
    }

    if (!filter.options) {
      obj.options = inboundWebhookFilterData
        ? inboundWebhookFilterData
            .map(
              webhook => webhook[filter.columnId] && webhook[filter.columnId]
            )
            .filter(
              (value, index, self) => value && self.indexOf(value) === index
            )
            .map((obj, index) => ({ id: index, value: obj }))
        : []
    }
    return obj
  })

  const inboundLogColumns = useMemo(() => [
    {
      header: "Inbound Webhook Id",
      accessor: "id",
      width: 100
    },
    {
      header: "Request Date/Time",
      width: 150,
      accessor: "requestDateTime",
      cell: cell => (
        <TimeAgo
          date={cell.getValue()}
          title={Helpers.prettyDateTimeinPacificTimeZone(cell.getValue())}
        />
      ),
      sortable: true
    },
    {
      header: "HTTP Method",
      accessor: "httpMethod",
      width: 100,
      sortable: true
    },
    {
      header: "Remote IP",
      accessor: "remoteIPAddress",
      width: 150,
      sortable: true
    },
    {
      header: "HTTP Body",
      accessor: "httpRequestBody",
      width: 200,
      cell: cell => {
        const cellValue = cell.getValue()
        if (cellValue === "{}") {
          return "None"
        }
        return (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
            title={cell.getValue()}
          >
            {truncate(cell.getValue(), 100)}
          </span>
        )
      },
      sortable: true
    },
    {
      header: "Validation Message",
      accessor: "validationMessage",
      width: 150,
      sortable: true
    }
  ])

  useEffect(() => {
    setShowChangeCurrentOrganization(true)
    loadInboundWebhooks(organizationId)
  }, [organizationId])

  return (
    //build inbound table
    <>
      <GridItem xs={12} className={classes.archiveSwitch}>
        <FormControlLabel
          value="start"
          control={
            <Switch
              name="isArchived"
              color="primary"
              checked={isArchived}
              onChange={() => {
                setIsArchived(!isArchived)
              }}
            />
          }
          label="Include Archived"
          labelPlacement="end"
        />
      </GridItem>
      <div>
        <CustomTable
          aria-label="InboundWebhook Table ViewWebhooks"
          tableName="Inbound Webhooks"
          tableData={inboundWebhooks}
          tableColumns={inboundColumns}
          availableFilters={inboundWebhookFilter}
          initialTableState={{ pageIndex: 0, pageSize: 10 }}
          onExpandedChange={onExpandedChange}
          paginated={true}
          sorted
          filtered={false}
          setFilterPanelOpen={false}
          expanded={expanded}
          isLoading={loading}
          alternateRowColor={true}
          renderSubComponent={({ row }) => (
            //inbound Log table
            <div style={{ padding: "20px" }}>
              {logLoading ? (
                <LinearProgress className={classes.progressForLogTable} />
              ) : (
                <CustomTable
                  tableName="InboundLogTable"
                  minRows={5}
                  className="-striped -highlight"
                  tableColumns={inboundLogColumns}
                  tableData={inboundWebhookLogs || []}
                  paginated={true}
                  sorted={true}
                  showPaginationBottom={true}
                  alternateRowColor={true}
                  noDataText={"No inbound webhook log data found."}
                  defaultSorted={[
                    {
                      id: "requestDateTime",
                      desc: true
                    }
                  ]}
                />
              )}
            </div>
          )}
        />
      </div>
      <div style={{ paddingTop: "5px", paddingLeft: "5px" }}>
        <CustomDSDialog
          content={"Please contact Engineering to edit this webhook"}
          open={open}
          icon={<InfoIcon style={{ fontSize: "4rem" }} />}
          onClose={handleClose}
        />
      </div>
    </>
  )
}
