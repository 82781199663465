import React, { useRef, useEffect } from "react"
import PerfectScrollbar from "perfect-scrollbar"

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin. -Material Tim
const SidebarWrapper = ({ className, user, headerLinks, links, orgMenu }) => {
  const sidebarWrapperRef = useRef(null)

  useEffect(() => {
    let ps

    const isWindows = () => {
      // Modern browsers use navigator.userAgentData.platform
      if (navigator.userAgentData && navigator.userAgentData.platform) {
        return navigator.userAgentData.platform.includes("Win")
      }
      // Fallback for older browsers
      return navigator.userAgent.includes("Win")
    }

    if (isWindows()) {
      ps = new PerfectScrollbar(sidebarWrapperRef.current, {
        suppressScrollX: true,
        suppressScrollY: false
      })
    }

    // Cleanup function to execute when the component unmounts
    return () => {
      if (ps) {
        ps.destroy()
      }
    }
  }, []) // Empty dependency array ensures this effect only runs once after mount

  return (
    <div className={className} ref={sidebarWrapperRef}>
      {user}
      {headerLinks}
      {orgMenu}
      {links}
    </div>
  )
}

export default SidebarWrapper
