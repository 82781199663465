import React from "react"
import { useState, useEffect } from "react"
import ReactSpeedometer from "react-d3-speedometer"
import makeStyles from "@mui/styles/makeStyles"
import { globalSettings } from "variables/general.jsx"
import { Button } from "@mui/material"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import TextField from "@mui/material/TextField"
import { get } from "tools/request"

const useStyles = makeStyles({
  gaugeCard: {
    display: "flex",
    justifyContent: "center",
    marginTop: "9px",
    fontWeight: "bold"
  },
  rvGauge: {
    display: "flex",
    justifyContent: "center",
    marginTop: "9px"
  },
  bottomCard: {
    display: "flex",
    justifyContent: "space-between"
  }
})

export default function RevShareGauge() {
  const [value, setValue] = useState({})

  useEffect(() => {
    get(`${globalSettings.apiBaseUrl}/api/portalmetrics/revsharemetrics`)
      .then(resp => resp.json())
      .then(data => {
        setValue(data)
      })
      .catch(err => {
        console.error("Error on GET to /api/portalmetrics/revsharemetrics", err)
      })
  }, [])

  const classes = useStyles()
  let result = (value.revenue / value.goal) * 100
  const recentDate = new Date(value.date)
  const formattedDate = recentDate.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric"
  })

  return (
    <>
      <div className={classes.gaugeCard}>Actual vs. Goal</div>
      <div className={classes.rvGauge}>
        <ReactSpeedometer
          maxSegmentLabels={1}
          segments={4444}
          maxValue={100}
          currentValueText={"Actual: #{value}%"}
          currentValuePlaceholderStyle={"#{value}"}
          value={result}
          valueFormat={"d"}
          textColor={"#000"}
          startColor={"#FF0000"}
          endColor={"#00FF00"}
          needleColor={"#4d9ab5"}
          width={400}
        />
      </div>
      <div className={classes.bottomCard}>
        <TextField
          className={classes.revshareDate}
          label="last updated:"
          value={formattedDate}
          InputProps={{ readOnly: true }}
        />
        <Button
          className={classes.linkButton}
          disabled={true}
          variant="contained"
          color="primary"
          size="small"
          href="https://datastudio.google.com/reporting/c5f28a7e-3ffe-4a87-81b9-09a1cb6e4b3c/page/p_uk6xzw6f1c"
          target="_blank"
          endIcon={<OpenInNewIcon fontSize="inherit" />}
        >
          Data Studio Report
        </Button>
      </div>
    </>
  )
}
