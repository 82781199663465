import React, { useEffect, useState, useCallback } from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import makeStyles from "@mui/styles/makeStyles"
import { Menu, MenuItem } from "@mui/material"
import { Link } from "react-router-dom"
import { IconButton } from "@mui/material"
import { Launch } from "@mui/icons-material"
import RealTimeDAO from "daos/realTimeDAO"
import { useHistory } from "react-router-dom"

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    realTimeLibraryById: store.realTimeStore.realTimeLibraryById,
    getRealTimeLibraryById: store.realTimeStore.getRealTimeLibraryById,
    getInsightGroup: store.realTimeStore.getInsightGroup,
    insightGroup: store.realTimeStore.insightGroup,
    insightSentence: store.realTimeStore.insightSentence,
    getInsightSentence: store.realTimeStore.getInsightSentence,
    dialogWarningSuccess: store.uiStore.dialogWarningSuccess,
    setDialogWarningSuccess: store.uiStore.setDialogWarningSuccess,
    setDialogWarningOpen: store.uiStore.setDialogWarningOpen,
    setDialogWarningMessage: store.uiStore.setDialogWarningMessage,
    setDialogSuccessMessage: store.uiStore.setDialogSuccessMessage,
    setDialogSuccessOpen: store.uiStore.setDialogSuccessOpen,
    setDialogFailMessage: store.uiStore.setDialogFailMessage,
    setDialogFailOpen: store.uiStore.setDialogFailOpen,
    setSuccessMessage: store.uiStore.setSuccessMessage,
    setIsSuccess: store.uiStore.setIsSuccess
  }))
}

const useStyles = makeStyles({
  iconButton2: {
    position: "absolute",
    right: "30px",
    display: "none",
    padding: 0,
    margin: 0,
    "& > span > a > svg": {
      width: ".55em",
      height: ".55em",
      color: "rgba(0, 0, 0, 0.54)"
    }
  },
  offerMenu: {
    left: "125px"
  }
})

export default function InsightLibraryTreeContextMenu(props) {
  const classes = useStyles()
  const {
    libraryId,
    clickedNodeInfo,
    closeForms,
    contextMenuSentenceAnchor,
    setContextMenuSentenceAnchor,
    contextMenuInsightGroupAnchor,
    setContextMenuInsightTypeAnchor,
    contextMenuInsightTypeAnchor,
    setContextMenuInsightGroupAnchor
  } = props
  const {
    realTimeLibraryById,
    getRealTimeLibraryById,
    insightSentence,
    insightGroup,
    getInsightSentence,
    getInsightGroup,
    setDialogWarningOpen,
    setDialogWarningSuccess,
    setDialogWarningMessage,
    setDialogFailOpen,
    setDialogFailMessage,
    dialogWarningSuccess,
    setSuccessMessage,
    setIsSuccess
  } = useStoreData()
  const history = useHistory()
  const [actionType, setActionType] = useState("")
  const [cutCopyNode, setCutCopyNode] = useState({})
  const [delayedAction, setDelayedAction] = useState("")
  const [madeInsightGroupChanges, setMadeInsightGroupChanges] = useState(false)
  const [archivedGroupEntities, setArchivedGroupEntities] = useState([])
  const [archivedSentenceEntities, setArchivedSentenceEntities] = useState([])
  const [archivedInsightEntities, setArchivedInsightEntities] = useState([])
  const [numberOfNodesToBeArchived, setNumberOfNodesToBeArchived] = useState(0)

  const [madeInsightSentenceChanges, setMadeInsightSentenceChanges] =
    useState(false)

  const TreeContextMenuInsightType = useCallback(() => {
    const insightTypeId =
      (clickedNodeInfo && clickedNodeInfo?.id?.replace(/type-/, "")) || ""
    const insightTypeName = (clickedNodeInfo && clickedNodeInfo?.name) || ""
    return (
      <Menu
        className={classes.offerMenu}
        anchorEl={contextMenuInsightTypeAnchor}
        open={Boolean(contextMenuInsightTypeAnchor)}
        onClose={handleContextMenuInsightTypeClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <MenuItem
          onClick={() => {
            handleAddNewGroup(insightTypeId, insightTypeName)
            history.push(`/portal/real-time-library/edit?id=${libraryId}`)
          }}
        >
          Add Group
        </MenuItem>
        <Link
          to={`/portal/real-time-library/edit?id=${libraryId}&insightTypeId=${insightTypeId}`}
          target="_blank"
          style={{ color: "rgba(0,0,0,.87)" }}
        >
          <MenuItem>
            <IconButton
              className={classes.iconButton2}
              aria-label={"Open in New window"}
              size="large"
            >
              <Launch />
            </IconButton>
            Open in New window
          </MenuItem>
        </Link>
        {
          // disabling this functionality
          /* <MenuItem
          onClick={() => {
            handleInsightTypeRightClick("Archive")
          }}
        >
          Archive
        </MenuItem> */
        }
        {(actionType === "Copy:Group:Pending" ||
          actionType === "Cut:Group:Pending") && (
          <MenuItem
            onClick={() => {
              handleInsightRightClick("Paste")
            }}
          >
            Paste
          </MenuItem>
        )}
      </Menu>
    )
  }, [contextMenuInsightTypeAnchor])

  const TreeContextMenuInsightGroup = useCallback(() => {
    const groupId =
      (clickedNodeInfo && clickedNodeInfo?.id?.replace(/group-/, "")) || ""
    const groupName = (clickedNodeInfo && clickedNodeInfo?.name) || ""
    return (
      <Menu
        className={classes.offerMenu}
        anchorEl={contextMenuInsightGroupAnchor}
        open={Boolean(contextMenuInsightGroupAnchor)}
        onClose={handleContextMenuInsightGroupClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <MenuItem
          onClick={() => {
            handleAddNewSentence(groupId, groupName)
          }}
        >
          Add Sentence
        </MenuItem>
        <Link
          to={`/portal/real-time-library/edit?id=${libraryId}&groupId=${groupId}`}
          target="_blank"
          style={{ color: "rgba(0,0,0,.87)" }}
        >
          <MenuItem>
            <IconButton
              className={classes.iconButton2}
              aria-label={"Open in New window"}
              size="large"
            >
              <Launch />
            </IconButton>
            Open in New window
          </MenuItem>
        </Link>
        <MenuItem
          onClick={() => {
            setCutCopyNode(clickedNodeInfo)
            handleInsightGroupRightClick("Copy")
          }}
        >
          Copy
        </MenuItem>
        <MenuItem
          onClick={() => {
            setCutCopyNode(clickedNodeInfo)
            handleInsightGroupRightClick("Cut")
          }}
        >
          Cut
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleInsightGroupRightClick("Duplicate")
          }}
        >
          Duplicate
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleInsightGroupRightClick("Archive")
          }}
        >
          Archive
        </MenuItem>
        {(actionType === "Copy:Sentence:Pending" ||
          actionType === "Cut:Sentence:Pending") && (
          <MenuItem
            onClick={() => {
              handleInsightGroupRightClick("Paste")
            }}
          >
            Paste
          </MenuItem>
        )}
      </Menu>
    )
  }, [contextMenuInsightGroupAnchor])

  const TreeContextMenuSentence = useCallback(() => {
    const sentenceId =
      (clickedNodeInfo && clickedNodeInfo?.id?.replace(/sentence-/, "")) || ""
    return (
      <Menu
        className={classes.offerMenu}
        anchorEl={contextMenuSentenceAnchor}
        open={Boolean(contextMenuSentenceAnchor)}
        onClose={handleContextMenuSentenceClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <Link
          to={`/portal/real-time-library/edit?id=${libraryId}&sentenceId=${sentenceId}`}
          target="_blank"
          style={{ color: "rgba(0,0,0,.87)" }}
        >
          <MenuItem>
            <IconButton
              className={classes.iconButton2}
              aria-label={"Open in New window"}
              size="large"
            >
              <Launch />
            </IconButton>
            Open in New window
          </MenuItem>
        </Link>
        {
          // Temporarily removing this functionality
          /* <MenuItem
          onClick={() => {
            setCutCopyNode(clickedNodeInfo)
            handleSentenceRightClick("Copy")
          }}
        >
          Copy
        </MenuItem>
        <MenuItem
          onClick={() => {
            setCutCopyNode(clickedNodeInfo)
            handleSentenceRightClick("Cut")
          }}
        >
          Cut
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleSentenceRightClick("Duplicate:Sentence")
          }}
        >
          Duplicate
        </MenuItem> */
        }
        <MenuItem
          onClick={() => {
            handleSentenceRightClick("Archive")
          }}
        >
          Archive
        </MenuItem>
      </Menu>
    )
  }, [contextMenuSentenceAnchor])

  const saveModelCriteria = (idsList, sentenceInfo) => {
    //[oldId, newId]
    let numSaved = 0
    idsList?.forEach(x => {
      if (!x.newId) {
        alert("Sentence copy failed, can not copy Model Criteria")
        return
      }
      // need to adjust below before re-enabling these options
      // this needs to add in the other options for the property map (the nulls)
      const modelCriteria = RealTimeDAO.getModelCriteriaBySentence(x.oldId)
      setMadeInsightSentenceChanges(true)
      modelCriteria.then(resp => {
        const existingCriteria = {}
        existingCriteria.isoffense = resp.isoffense
        existingCriteria.sentenceId = x.newId
        for (const [key, value] of Object.entries(resp)) {
          if (Array.isArray(resp[key])) {
            existingCriteria[key] = []
            value?.forEach(y => {
              existingCriteria[key].push(y.id)
            })
          }
        }
        const modelCriteriaResponse = RealTimeDAO.putModelCriteria(
          existingCriteria,
          x.newId
        )
        modelCriteriaResponse.then(rsp => {
          if (!rsp.ok) {
            throw new Error("save model criteria")
          }
          numSaved += 1
          if (numSaved === idsList.length) {
            showLinkSuccessModal(
              sentenceInfo.action,
              sentenceInfo.resp,
              sentenceInfo.fromId
            )
          }
        })
      })
    })
  }

  const cutOrCopyGroup = (typeId, groupId, operation, action) => {
    const foundGroup = insightGroup.find(x => x.id === Number(groupId))
    if (foundGroup) {
      const orgIds = []
      foundGroup?.organizations?.forEach(x => {
        if (x.id) {
          orgIds.push(x.id)
        }
      })
      const groupToCopy = {
        id: 0,
        name: foundGroup.name,
        description: foundGroup.description,
        ownerAccountId: foundGroup.owner?.id ? foundGroup.owner.id : null,
        libraryId: libraryId,
        organizationIds: orgIds,
        insightTypeId: typeId,
        //insightTypeId: foundGroup.insightType.id,
        statusId: foundGroup.status.id
      }

      const saveGroup = RealTimeDAO.postInsightGroup(groupToCopy)
      setMadeInsightGroupChanges(true)
      saveGroup.then(resp => {
        if (operation === "copy") {
          showLinkSuccessModal(action, resp, groupId)
        } else if (operation === "cut") {
          const sentencesToArchive = []
          const sentencesToCopy = []
          const foundSentences = insightSentence.filter(
            x => x?.insightGroup?.id === Number(groupId)
          )
          foundSentences.forEach(fs => {
            sentencesToArchive.push(fs.id)
            sentencesToCopy.push({
              id: 0,
              name: fs.name,
              description: fs.description,
              statusId: fs.status.id,
              ownerAccountId: fs.owner?.id ? fs.owner.id : null,
              libraryId: libraryId,
              insightGroupId: resp.id,
              //insightTypeId: foundGroup.insightType.id,
              insightTypeId: typeId,
              statisticId: fs.statisticId,
              //referencePeriodId: fs.referencePeriod.id,
              template: fs.template || ""
            })
          })
          const savedResults = []
          if (sentencesToCopy.length > 0) {
            const idsList = []
            sentencesToCopy.forEach(sc => {
              const saveSentence = RealTimeDAO.postInsightSentence(sc)
              saveSentence.then(resps => {
                idsList.push({ oldId: sc.id, newId: resps?.id })
                savedResults.push(resps)
                if (savedResults.length === sentencesToCopy.length) {
                  saveModelCriteria(idsList, {
                    action,
                    resps,
                    fromId: groupId
                  })
                  sentencesToArchive.forEach(arc => {
                    RealTimeDAO.archiveInsightSentence(arc)
                  })
                  RealTimeDAO.archiveInsightGroup(groupId)
                }
              })
            })
          } else {
            RealTimeDAO.archiveInsightGroup(groupId)
            showLinkSuccessModal(action, resp, groupId)
          }
          //RealTimeDAO.archiveInsightGroup(mygId)
        }
      })
    }
  }

  const fireInsightAction = action => {
    if (action === "Copy:Group:Pending") {
      const mytId = clickedNodeInfo.id.replace(/type-/, "")
      const mygId = cutCopyNode.id.replace(/group-/, "")
      if (mytId && mygId) {
        cutOrCopyGroup(Number(mytId), Number(mygId), "copy", action)
      }
    } else if (action === "Cut:Group:Pending") {
      const mytId = clickedNodeInfo.id.replace(/type-/, "")
      const mygId = cutCopyNode.id.replace(/group-/, "")
      if (mytId && mygId) {
        cutOrCopyGroup(Number(mytId), Number(mygId), "cut", action)
      }
    }
  }

  const fireGroupAction = action => {
    let body = null
    let fromId = null
    if (action === "Duplicate:Group") {
      const myId = clickedNodeInfo.id.replace(/group-/, "")
      if (myId) {
        fromId = myId
        const foundGroup = insightGroup.find(x => x.id === Number(myId))
        if (foundGroup) {
          const orgIds = []
          foundGroup?.organizations?.forEach(x => {
            if (x.id) {
              orgIds.push(x.id)
            }
          })
          body = {
            id: 0,
            name: foundGroup.name,
            description: foundGroup.description,
            ownerAccountId: foundGroup.owner?.id ? foundGroup.owner.id : null,
            libraryId: libraryId,
            organizationIds: orgIds,
            insightTypeId: foundGroup.insightType.id,
            statusId: foundGroup.status.id
          }
        }
      }
    } else if (action === "Copy:Group:Pending") {
      const mygId = clickedNodeInfo.id.replace(/group-/, "")
      const mysId = cutCopyNode.id.replace(/sentence-/, "")
      if (mygId && mysId) {
        fromId = mysId
        const foundGroup = insightGroup.find(x => x.id === Number(mygId))
        const foundSentence = insightSentence.find(x => x.id === Number(mysId))
        if (foundSentence && foundGroup) {
          body = {
            id: 0,
            name: foundSentence.name,
            description: foundSentence.description,
            statusId: foundSentence.status.id,
            ownerAccountId: foundSentence.owner?.id
              ? foundSentence.owner.id
              : null,
            libraryId: libraryId,
            insightGroupId: mygId,
            insightTypeId: foundGroup.insightType.id,
            statisticId: foundSentence.statisticId,
            //referencePeriodId: foundSentence.referencePeriod.id,
            template: foundSentence.template || ""
          }
        }
      }
    } else if (action === "Cut:Group:Pending") {
      const mygId = clickedNodeInfo.id.replace(/group-/, "")
      const mysId = cutCopyNode.id.replace(/sentence-/, "")
      if (mygId && mysId) {
        fromId = mysId
        const foundGroup = insightGroup.find(x => x.id === Number(mygId))
        const foundSentence = insightSentence.find(x => x.id === Number(mysId))
        if (foundSentence && foundGroup) {
          body = {
            id: 0,
            name: foundSentence.name,
            description: foundSentence.description,
            statusId: foundSentence.status.id,
            ownerAccountId: foundSentence.owner?.id
              ? foundSentence.owner.id
              : null,
            libraryId: libraryId,
            insightGroupId: mygId,
            insightTypeId: foundGroup.insightType.id,
            statisticId: foundSentence.statisticId,
            //referencePeriodId: foundSentence.referencePeriod.id,
            template: foundSentence.template || ""
          }
          RealTimeDAO.archiveInsightGroup(mysId)
        }
      }
    }
    if (body) {
      const saveGroup = RealTimeDAO.postInsightGroup(body)
      setMadeInsightGroupChanges(true)
      saveGroup.then(resp => {
        showLinkSuccessModal(action, resp, fromId)
        //setIsExecutingActions(false)
      })
    }
  }

  const fireSentenceAction = action => {
    let body = null
    let fromId = null
    if (action === "Duplicate:Sentence") {
      const myId = clickedNodeInfo.id.replace(/sentence-/, "")
      if (myId) {
        fromId = myId
        const foundSentence = insightSentence.find(x => x.id === Number(myId))
        if (foundSentence) {
          body = {
            id: 0,
            name: foundSentence.name,
            description: foundSentence.description,
            statusId: foundSentence.status.id,
            ownerAccountId: foundSentence.owner?.id
              ? foundSentence.owner.id
              : null,
            libraryId: libraryId,
            insightGroupId: foundSentence.insightGroup.id,
            insightTypeId: foundSentence.insightType.id,
            statisticId: foundSentence.statisticId,
            //referencePeriodId: foundSentence.referencePeriod.id,
            template: foundSentence.template || ""
          }
        }
      }
    } else if (action === "Copy:Sentence:Pending") {
      const mygId = clickedNodeInfo.id.replace(/group-/, "")
      const mysId = cutCopyNode.id.replace(/sentence-/, "")
      if (mygId && mysId) {
        fromId = mysId
        const foundGroup = insightGroup.find(x => x.id === Number(mygId))
        const foundSentence = insightSentence.find(x => x.id === Number(mysId))
        if (foundSentence && foundGroup) {
          body = {
            id: 0,
            name: foundSentence.name,
            description: foundSentence.description,
            statusId: foundSentence.status.id,
            ownerAccountId: foundSentence.owner?.id
              ? foundSentence.owner.id
              : null,
            libraryId: libraryId,
            insightGroupId: mygId,
            insightTypeId: foundGroup.insightType.id,
            statisticId: foundSentence.statisticId,
            //referencePeriodId: foundSentence.referencePeriod.id,
            template: foundSentence.template || ""
          }
        }
      }
    } else if (action === "Cut:Sentence:Pending") {
      const mygId = clickedNodeInfo.id.replace(/group-/, "")
      const mysId = cutCopyNode.id.replace(/sentence-/, "")
      if (mygId && mysId) {
        fromId = mysId
        const foundGroup = insightGroup.find(x => x.id === Number(mygId))
        const foundSentence = insightSentence.find(x => x.id === Number(mysId))
        if (foundSentence && foundGroup) {
          body = {
            id: 0,
            name: foundSentence.name,
            description: foundSentence.description,
            statusId: foundSentence.status.id,
            ownerAccountId: foundSentence.owner?.id
              ? foundSentence.owner.id
              : null,
            libraryId: libraryId,
            insightGroupId: mygId,
            insightTypeId: foundGroup.insightType.id,
            //statisticId: foundSentence.statisticId,
            //referencePeriodId: foundSentence.referencePeriod.id,
            template: foundSentence.template || ""
          }
          RealTimeDAO.archiveInsightSentence(mysId)
        }
      }
    }
    if (body) {
      const saveSentence = RealTimeDAO.postInsightSentence(body)
      setMadeInsightSentenceChanges(true)
      saveSentence.then(resp => {
        saveModelCriteria([{ oldId: fromId, newId: resp?.id }], {
          action,
          resp,
          fromId
        })
      })
    }
  }

  const handleInsightRightClick = action => {
    switch (action) {
      case "Paste":
        if (actionType === "Copy:Group:Pending") {
          setActionType("Paste")
          fireInsightAction("Copy:Group:Pending")
        } else if (actionType === "Cut:Group:Pending") {
          setActionType("Cut-Paste")
          fireInsightAction("Cut:Group:Pending")
        }
        break

      default:
    }
    setContextMenuInsightTypeAnchor(null)
  }

  const handleInsightGroupRightClick = action => {
    switch (action) {
      case "Archive":
        setDialogWarningOpen(true)
        setDialogWarningMessage(
          `Do you want to archive Insight Group: ${clickedNodeInfo?.id.replace(
            /group-/,
            ""
          )} - ${clickedNodeInfo?.name} and Sentences underneath?`
        )
        setDelayedAction("Archive:Group")
        break

      case "Copy":
        setActionType("Copy:Group:Pending")
        break

      case "Cut":
        setActionType("Cut:Group:Pending")
        break
      case "Duplicate":
        setActionType("Duplicate:Group")
        //fireLinkAction("Duplicate:Group")
        fireGroupAction("Duplicate:Group")
        break

      case "Paste":
        if (actionType === "Copy:Sentence:Pending") {
          setActionType("Paste")
          fireSentenceAction(actionType)
        } else if (actionType === "Cut:Sentence:Pending") {
          setActionType("Cut-Paste")
          fireSentenceAction(actionType)
        } else if (actionType === "Copy:Group:Pending") {
          setActionType("Paste")
          fireGroupAction("Copy:Group:Pending")
        } else if (actionType === "Cut:Group:Pending") {
          setActionType("Cut-Paste")
          fireGroupAction("Cut:Group:Pending")
        }
        break

      default:
    }
    setContextMenuInsightGroupAnchor(null)
  }

  const handleSentenceRightClick = action => {
    switch (action) {
      case "Archive":
        setActionType("")
        setDialogWarningOpen(true)
        setDialogWarningMessage(
          `Do you want to archive Insight Sentence: ${clickedNodeInfo?.id.replace(
            /sentence-/,
            ""
          )} - ${clickedNodeInfo?.name}?`
        )
        setDelayedAction("Archive:Sentence")
        break

      case "Copy":
        setActionType("Copy:Sentence:Pending")
        break

      case "Cut":
        setActionType("Cut:Sentence:Pending")
        break

      case "Duplicate:Sentence":
        setActionType("Duplicate:Sentence")
        fireSentenceAction(action)
        break

      default:
    }
    setContextMenuSentenceAnchor(null)
  }

  const showLinkSuccessModal = (action, result, fromId) => {
    switch (action) {
      case "Archive":
        setSuccessMessage(`You have successfully Archived ___ #${result}`)
        setIsSuccess(true)
        getInsightGroup(libraryId)
        setActionType("")
        break

      case "Duplicate:Group":
        setSuccessMessage(
          `You have successfully Duplicated Group with ID:${fromId},  new ID = ${result?.id}`
        )
        setIsSuccess(true)
        getInsightGroup(libraryId)
        setActionType("")
        break

      case "Duplicate:Sentence":
        setSuccessMessage(
          `You have successfully Duplicated Sentence with ID:${fromId},  new ID = ${result?.id}`
        )
        setIsSuccess(true)
        getInsightSentence(libraryId)
        setActionType("")
        break

      case "Copy:Group:Pending":
        setSuccessMessage(
          `You successfully Copied Group with ID:${fromId}, new Group ID=${result?.id}`
        )
        setIsSuccess(true)
        getInsightGroup(libraryId)
        setActionType("")
        if (result?.id) {
          history.push(
            `/portal/real-time-library/edit?id=${libraryId}&groupId=${result.id}`
          )
        }
        break

      case "Copy:Sentence:Pending":
        setSuccessMessage(
          `You successfully Copied Sentence with ID:${fromId}, new Sentence ID=${result?.id}`
        )
        setIsSuccess(true)
        getInsightSentence(libraryId)
        setActionType("")
        if (result?.id) {
          history.push(
            `/portal/real-time-library/edit?id=${libraryId}&sentenceId=${result.id}`
          )
        }
        break

      case "Cut:Sentence:Pending":
        setSuccessMessage(
          `You successfully Cut Sentence with ID:${fromId}, new Sentence ID=${result?.id}`
        )
        setIsSuccess(true)
        getInsightSentence(libraryId)
        setActionType("")
        if (result?.id) {
          history.push(
            `/portal/real-time-library/edit?id=${libraryId}&sentenceId=${result.id}`
          )
        }
        break

      case "Cut:Group:Pending":
        setSuccessMessage(
          `You successfully Cut Group with ID:${fromId}, new Group ID=${result?.id}`
        )
        setIsSuccess(true)
        getInsightGroup(libraryId)
        getInsightSentence(libraryId)
        setActionType("")
        if (result?.id) {
          history.push(
            `/portal/real-time-library/edit?id=${libraryId}&groupId=${result.id}`
          )
        }
        break

      default:
    }
  }
  // const handleInsightTypeRightClick = action => {
  //   switch (action) {
  //     case "Archive":
  //       setActionType("")
  //       setDialogWarningOpen(true)
  //       setDialogWarningMessage(
  //         `Do you want to archive Insight Type: ${clickedNodeInfo?.id.replace(
  //           /type-/,
  //           ""
  //         )} - ${
  //           clickedNodeInfo?.name
  //         } and all Groups and Sentences underneath?`
  //       )
  //       setDelayedAction("Archive:InsightType")
  //       break

  //     case "Group":
  //       setActionType("Group")
  //       fireInsightAction("Group")
  //       break

  //     case "Paste":
  //       if (actionType === "Copy:Group:Pending") {
  //         setActionType("Paste")
  //         fireInsightAction("Copy:Group:Pending")
  //       } else if (actionType === "Cut:Group:Pending") {
  //         setActionType("Cut-Paste")
  //         fireInsightAction("Cut:Group:Pending")
  //       }
  //       break
  //     default:
  //   }
  //   setContextMenuInsightTypeAnchor(null)
  // }
  const handleAddNewGroup = (insightTypeId, insightTypeName) => {
    props.openAddNewGroup(Number(insightTypeId), insightTypeName)
    setContextMenuInsightTypeAnchor(null)
  }

  const handleAddNewSentence = (groupId, groupName) => {
    props.openAddNewSentence(Number(groupId), groupName)
    setContextMenuSentenceAnchor(null)
  }

  const handleContextMenuInsightTypeClose = () => {
    setContextMenuInsightTypeAnchor(null)
    //setAddLinkMenuInfo(null)
  }

  const handleContextMenuInsightGroupClose = () => {
    setContextMenuInsightGroupAnchor(null)
    //setAddLinkMenuInfo(null)
  }

  const handleContextMenuSentenceClose = () => {
    setContextMenuSentenceAnchor(null)
    //setAddLinkMenuInfo(null)
  }

  const insightGroupAndAllUndeneath = group => {
    const groupsToArchive = []
    const sentencesToArchive = []
    let total = 0

    const gId = Number(group.id.replace(/group-/, ""))
    groupsToArchive.push(gId)
    total += 1
    group.children.forEach(sentences => {
      const sId = Number(sentences.id.replace(/sentence-/, ""))
      sentencesToArchive.push(sId)
      total += 1
    })
    const nodeToArchiveList = {
      groupsToArchive,
      sentencesToArchive,
      total
    }
    return nodeToArchiveList
  }

  const insightTypeAndAllUndeneath = info => {
    const typesToArchive = []
    const groupsToArchive = []
    const sentencesToArchive = []
    let total = 0
    const tId = Number(info.id.replace(/type-/, ""))
    typesToArchive.push(tId)
    total += 1
    const nodeToArchiveList = {
      typesToArchive,
      groupsToArchive,
      sentencesToArchive,
      total
    }
    return nodeToArchiveList
  }

  const justSentence = sentence => {
    const sentencesToArchive = []
    let total = 0
    const sId = Number(sentence.id.replace(/sentence-/, ""))
    sentencesToArchive.push(sId)
    total += 1
    const nodeToArchiveList = {
      sentencesToArchive,
      total
    }
    return nodeToArchiveList
  }

  const removeThisInsightType = async node => {
    const insightTypeId =
      Number(clickedNodeInfo && clickedNodeInfo?.id?.replace(/type-/, "")) || 0
    if (insightTypeId && realTimeLibraryById?.insightTypes?.length > 0) {
      const insightTypeIds = []
      realTimeLibraryById.insightTypes.forEach(x => {
        if (insightTypeId !== Number(x.id)) {
          insightTypeIds.push(x.id)
        }
      })
      const newLibraryInfo = {
        id: realTimeLibraryById.id,
        name: realTimeLibraryById.name,
        statusId: realTimeLibraryById.status.id || 3,
        verticalId: realTimeLibraryById.vertical.id,
        leagueId: realTimeLibraryById.league.id,
        insightTypeIds,
        isArchived: false
      }
      const successfulInsightArchive = []

      RealTimeDAO.putRealTimeLibraryById(
        newLibraryInfo,
        newLibraryInfo.id
      ).then(resp => {
        successfulInsightArchive.push({
          type: "insight",
          name: node?.name || null,
          id: newLibraryInfo.id,
          success: resp.ok
        })
        setArchivedInsightEntities(successfulInsightArchive)
      })
    }
  }

  const archiveGroups = groupData => {
    const successfulArchiveGroup = []
    groupData.forEach(arc => {
      RealTimeDAO.archiveInsightGroup(arc).then(resp => {
        successfulArchiveGroup.push({
          type: "group",
          id: arc,
          success: resp.ok
        })
        if (successfulArchiveGroup.length === groupData.length) {
          setArchivedGroupEntities(successfulArchiveGroup)
        }
      })
    })
  }

  const archiveSentences = sentenceData => {
    const successfulArchiveSentence = []
    sentenceData.forEach(arc => {
      RealTimeDAO.archiveInsightSentence(arc).then(resp => {
        successfulArchiveSentence.push({
          type: "sentence",
          id: arc,
          success: resp.ok
        })
        if (successfulArchiveSentence.length === sentenceData.length) {
          setArchivedSentenceEntities(successfulArchiveSentence)
        }
      })
    })
  }

  const archiveAllTheseNodes = //async
    (list, insightTypeInfo) => {
      let groupsToArchive = list.groupsToArchive?.length > 0 ? true : false
      let sentencesToArchive =
        list.sentencesToArchive?.length > 0 ? true : false
      if (insightTypeInfo) {
        removeThisInsightType(insightTypeInfo)
      } else {
        if (groupsToArchive) {
          archiveGroups(list.groupsToArchive)
        }
        if (sentencesToArchive) {
          archiveSentences(list.sentencesToArchive)
        }
      }
    }

  useEffect(() => {
    if (dialogWarningSuccess) {
      if (delayedAction === "Archive:Group") {
        setActionType("Archive")
        setDelayedAction("")
        const nodesToArchive = insightGroupAndAllUndeneath(clickedNodeInfo)
        setNumberOfNodesToBeArchived(nodesToArchive.total)
        archiveAllTheseNodes(nodesToArchive)
      } else if (delayedAction === "Archive:InsightType") {
        //setActionType("Archive")
        setActionType("")
        setDelayedAction("")
        const nodesToArchive = insightTypeAndAllUndeneath(clickedNodeInfo)
        setNumberOfNodesToBeArchived(nodesToArchive.total)
        archiveAllTheseNodes(nodesToArchive, clickedNodeInfo)
      } else if (delayedAction === "Archive:Sentence") {
        setActionType("Archive")
        setDelayedAction("")
        const nodesToArchive = justSentence(clickedNodeInfo)
        setNumberOfNodesToBeArchived(nodesToArchive.total)
        archiveAllTheseNodes(nodesToArchive)
      }
      setDialogWarningSuccess(false)
      setDialogWarningOpen(false)
    } else if (!dialogWarningSuccess) {
      if (actionType === "Copy:Link:Pending") {
        setActionType("")
      }
    }
  }, [dialogWarningSuccess])

  useEffect(() => {}, [clickedNodeInfo])

  useEffect(() => {
    if (madeInsightGroupChanges) {
      getInsightGroup(libraryId)
      setMadeInsightGroupChanges(false)
    }
  }, [insightGroup])

  useEffect(() => {
    if (madeInsightSentenceChanges) {
      getInsightSentence(libraryId)
      setMadeInsightSentenceChanges(false)
    }
  }, [insightSentence])

  useEffect(() => {
    const total =
      archivedGroupEntities.length +
      archivedSentenceEntities.length +
      archivedInsightEntities.length
    if (total > 0 && numberOfNodesToBeArchived === total) {
      const failed = archivedGroupEntities
        .concat(archivedSentenceEntities)
        .concat(archivedInsightEntities)
        .filter(x => !x.success)
      if (failed.length === 0) {
        setSuccessMessage("Archive was successful")
        setIsSuccess(true)
        getInsightGroup(libraryId)
        getInsightSentence(libraryId)
        if (archivedInsightEntities.length) {
          getRealTimeLibraryById(libraryId)
        }
        closeForms()
      } else {
        setDialogFailMessage(
          `The following archive failed:${JSON.stringify(failed)}`
        )
        setDialogFailOpen(true)
        getInsightGroup(libraryId)
        getInsightSentence(libraryId)
      }
      setNumberOfNodesToBeArchived(0)
      setArchivedGroupEntities([])
      setArchivedSentenceEntities([])
      setArchivedInsightEntities([])
    }
  }, [archivedGroupEntities, archivedSentenceEntities, archivedInsightEntities])

  return (
    <>
      <TreeContextMenuInsightType />
      <TreeContextMenuInsightGroup />
      <TreeContextMenuSentence />
    </>
  )
}
