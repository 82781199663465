import React from "react"
import PropTypes from "prop-types"
import withStyles from "@mui/styles/withStyles"
import Button from "components/CustomButtons/Button"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardFooter from "components/Card/CardFooter"
import lockScreenPageStyle from "assets/jss/material-dashboard-pro-react/views/lockScreenPageStyle"
import InputAdornment from "@mui/material/InputAdornment"
import DialogTitle from "@mui/material/DialogTitle"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import Email from "@mui/icons-material/Email"
import ReCAPTCHA from "react-google-recaptcha"
import { globalSettings } from "variables/general"
import TextField from "@mui/material/TextField"
import { put } from "tools/request"

class ForgotPasswordPage extends React.Component {
  constructor(props) {
    super(props)
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      usernameOrEmail: "",
      didEmailSendSucceed: false,
      didEmailSendFail: false,
      isFailedDialogOpen: false,
      isSuccessDialogOpen: false,
      captchatoken: ""
    }
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" })
      }.bind(this),
      700
    )
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction)
    this.timeOutFunction = null
  }

  handleCaptcha = value => {
    if (value.length > 1) {
      this.setState({ captchatoken: value })
    }
  }

  onUsernameOrEmailChange = event => {
    this.setState({ usernameOrEmail: event.target.value })
  }

  onEmailPasswordResetButtonClick = () => {
    if (this.state.captchatoken.length === 0) {
      this.setState({ didEmailSendFail: true })
      this.setState({ isFailedDialogOpen: true })
      return
    }
    put(`${globalSettings.apiBaseUrl}/api/account/forgotpassword`, null, {
      usernameoremail: this.state.usernameOrEmail,
      captchatoken: this.state.captchatoken
    })
      .then(response => response.json())
      .then(data => {
        if (data.responseCode === 1010) {
          this.setState({ isSuccessDialogOpen: true })
        } else {
          console.error(`Error sending forget email page`)
          this.setState({ didEmailSendFail: true })
          this.setState({ isFailedDialogOpen: true })
        }
      })
  }

  handleDialogClose = () => {
    this.setState({ isFailedDialogOpen: false })
    this.setState({ isSuccessDialogOpen: false })
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.container}>
        <Dialog
          onClose={this.handleDialogClose}
          open={this.state.isFailedDialogOpen}
          aria-labelledby="simple-dialog-title"
        >
          <DialogTitle id="simple-dialog-title">{"Whoops!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              A problem occurred. Unable to send password reset email.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose} color="primary">
              Try Again
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          onClose={this.handleDialogClose}
          open={this.state.isSuccessDialogOpen}
          aria-labelledby="simple-dialog-title"
        >
          <DialogTitle id="simple-dialog-title">{"Success"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Email is on its way. Check your inbox.
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <form>
          <Card
            profile
            className={`${classes.customCardClass} ${
              classes[this.state.cardAnimaton]
            }`}
          >
            <CardBody profile>
              <h4 className={classes.cardTitle}>Reset Password</h4>
              <TextField
                label="Enter email or username"
                id="company-disabled"
                fullWidth={true}
                onChange={this.onUsernameOrEmailChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Email className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  )
                }}
              />
            </CardBody>
            <div className={classes.captcha}>
              <ReCAPTCHA
                sitekey={globalSettings.captchaSiteKey}
                onChange={this.handleCaptcha}
              />
            </div>
            <CardFooter className={classes.justifyContentCenter}>
              <Button
                color="primary"
                default
                onClick={this.onEmailPasswordResetButtonClick}
              >
                Email Reset Link
              </Button>
            </CardFooter>
          </Card>
        </form>
      </div>
    )
  }
}

ForgotPasswordPage.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  selectedValue: PropTypes.string
}

export default withStyles(lockScreenPageStyle)(ForgotPasswordPage)
