import makeStyles from "@mui/styles/makeStyles"
import { Box, Typography } from "@mui/material"
import { TreeItem } from "@mui/x-tree-view"
import PropTypes from "prop-types"

const useTreeItemStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.secondary,
    "&:focus > $content": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: "var(--tree-view-color)"
    }
  },
  content: {
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular
    }
  },
  group: {
    marginLeft: theme.spacing(1),
    "& $content": {
      paddingLeft: theme.spacing(1)
    }
  },
  expanded: {},
  label: {
    fontWeight: "inherit",
    color: "inherit"
  },
  labelRoot: {
    display: "flex",
    verticalAlign: "top",
    padding: theme.spacing(0.5, 0),
    position: "relative"
  },
  labelIcon: {
    fontSize: "22px"
  },
  labelDetail: {
    fontWeight: "inherit",
    flexGrow: 1,
    paddingLeft: theme.spacing(0.75)
  },
  labelText: {
    fontWeight: "bold",
    flexGrow: 1
  },
  iconButton: {
    position: "absolute",
    right: "10px",
    display: "none",
    padding: 0,
    margin: 0,
    "& > span > svg": {
      width: ".6em",
      height: ".6em"
    }
  },
  iconButton2: {
    position: "absolute",
    right: "30px",
    display: "none",
    padding: 0,
    margin: 0,
    "& > span > a > svg": {
      width: ".55em",
      height: ".55em",
      color: "rgba(0, 0, 0, 0.54)"
    }
  },
  labelPosition: {
    color: "#3f51b5",
    fontWeight: "bold",
    flexShrink: 0,
    marginLeft: theme.spacing(0.5)
  },
  labelWeight: {
    fontWeight: "bold",
    fontSize: ".55rem",
    flexShrink: 0
  },
  flex: {
    display: "flex"
  },
  flexmiddle: {
    display: "flex",
    alignItems: "center",
    "&:hover $iconButton, &:hover $iconButton2": {
      display: "inline-block"
    }
  }
}))

const StyledTreeItem = props => {
  const classes = useTreeItemStyles()
  const {
    itemId,
    position,
    labelText = "",
    labelName = "",
    labelTitle = "",
    labelIcon: LabelIcon,
    triggerWeight,
    labelInfo = "",
    color,
    bgColor,
    handleCheckboxSelect,
    nodeId,
    areCheckboxesDisabled,
    isItemSelected = () => false,
    selectable = false,
    ...other
  } = props

  return (
    <>
      <div className={classes.flexmiddle}>
        {selectable && (
          <div
            style={{
              alignSelf: "flex-start",
              display: "flex",
              paddingTop: "5px"
            }}
          >
            <input
              type="checkbox"
              className="checkbox"
              id={itemId}
              name={labelName}
              checked={isItemSelected(itemId)}
              disabled={!!areCheckboxesDisabled}
              onChange={handleCheckboxSelect}
            />
          </div>
        )}
        <TreeItem
          nodeId={nodeId}
          title={labelTitle}
          label={
            <div className={classes.flexmiddle}>
              {LabelIcon && (
                <LabelIcon color="inherit" className={classes.labelIcon} />
              )}
              {!!position && position !== "0" && (
                <Typography variant="body1" className={classes.labelPosition}>
                  {position}
                </Typography>
              )}
              {!!triggerWeight && triggerWeight !== "0" && (
                <Typography variant="body1" className={classes.labelWeight}>
                  /{triggerWeight}w
                </Typography>
              )}
              <div className={classes.flex}>
                <Box className={classes.labelDetail}>
                  <Typography variant="body2" className={classes.labelText}>
                    {labelText}
                  </Typography>
                  <Typography variant="body2" color="inherit">
                    {labelInfo}
                  </Typography>
                </Box>
              </div>
            </div>
          }
          style={{
            "--tree-view-color": color,
            "--tree-view-bg-color": bgColor
          }}
          classes={{
            root: classes.root,
            content: classes.content,
            expanded: classes.expanded,
            group: classes.group,
            label: classes.label
          }}
          {...other}
        />
      </div>
    </>
  )
}

StyledTreeItem.propTypes = {
  position: PropTypes.number,
  itemId: PropTypes.string,
  nodeId: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string,
  labelName: PropTypes.string,
  labelTitle: PropTypes.string,
  isItemSelected: PropTypes.func,
  handleCheckboxSelect: PropTypes.func,
  areCheckboxesDisabled: PropTypes.bool,
  selectable: PropTypes.bool
}

export default StyledTreeItem
