import { configure } from "mobx"

import AccountStore from "./AccountStore"
import AccountStatusStore from "./AccountStatusStore"
import AppConfigurationStore from "./AppConfigurationStore"
import OrganizationStore from "./OrganizationStore"
import SessionStore from "./SessionStore"
import PermissionGroupStore from "./PermissionGroupStore"
import NarrativeStore from "./NarrativeStore"
import DatasourceStore from "./DatasourceStore"
import SnippetStore from "./SnippetStore"
import NarrativeRenderScheduleStore from "./NarrativeRenderScheduleStore"
import QueryBuilderStore from "./QueryBuilderStore"
import GraphicsToolStore from "./GraphicsToolStore"
import UIStore from "./UIStore"
import SportsStore from "./SportsStore"
import CTAPreviewDependencyStore from "./CTAPreviewDependencyStore"
import CTAv2Store from "./CTAv2Store"
import DSChatStore from "./DSChatStore"
import DSPromptStore from "./DSPromptStore"
import WebhookStore from "./WebhookStore"
import AssetStore from "./AssetStore"
import AffiliateStore from "./AffiliateStore"
import RealTimeStore from "./RealTimeStore"

configure({
  enforceActions: "observed"
})

export default class RootStore {
  constructor() {
    this.sessionStore = new SessionStore(this)
    this.accountStore = new AccountStore(this)
    this.appConfigurationStore = new AppConfigurationStore(this)
    this.organizationStore = new OrganizationStore(this)
    this.accountStatusStore = new AccountStatusStore(this)
    this.permissionGroupStore = new PermissionGroupStore(this)
    this.narrativeStore = new NarrativeStore(this)
    this.datasourceStore = new DatasourceStore(this)
    this.snippetStore = new SnippetStore(this)
    this.narrativeRenderScheduleStore = new NarrativeRenderScheduleStore(this)
    this.queryBuilderStore = new QueryBuilderStore(this)
    this.graphicsToolStore = new GraphicsToolStore(this)
    this.uiStore = new UIStore(this)
    this.sportsStore = new SportsStore(this)
    this.ctaPreviewDependencyStore = new CTAPreviewDependencyStore(this)
    this.dsChatStore = new DSChatStore(this)
    this.dsPromptStore = new DSPromptStore(this)
    this.webhookStore = new WebhookStore(this)
    this.assetStore = new AssetStore(this)
    this.ctav2Store = new CTAv2Store(this)
    this.affiliateStore = new AffiliateStore(this)
    this.realTimeStore = new RealTimeStore(this)
  }
}
