// eslint-disable-next-line no-unused-vars
import React from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import { Body } from "components/Form"
import PropTypes from "prop-types"

import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Autocomplete from "@mui/material/Autocomplete"
import Checkbox from "@mui/material/Checkbox"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import TextField from "@mui/material/TextField"
import { contentSections as targetElements } from "components/NarrativeAdmin/Data/contentSections"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles({
  MultiSelect: {
    "& div.MuiAutocomplete-tag": {
      backgroundColor: "#DDEAEF",
      height: "24px"
    }
  }
})

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    narrative: store.narrativeStore.narrative,
    setNarrative: store.narrativeStore.setNarrative
  }))
}

const NarrativeBuilderStep2 = ({ renderFooter }) => {
  const classes = useStyles()
  const { narrative, setNarrative } = useStoreData()
  const handleCellChange = event => {
    const newNarrative = {
      ...narrative,
      [event.target.id]: event.target.value
    }
    setNarrative(newNarrative)
  }
  const handleAutoCompleteMultiChange = (event, newValue, reason, name) => {
    if (!event || typeof event === "string" || !newValue) {
      return
    }
    let newVals = []
    newValue.forEach(itm => newVals.push(itm.id))
    const newNarrative = {
      ...narrative,
      [name]: newVals
    }
    setNarrative(newNarrative)
  }
  return (
    <Body>
      {!narrative?.isLibrary && (
        <>
          <div>
            <h3>Structure</h3>
          </div>
          <GridContainer>
            <GridItem md={12} className={classes.MultiSelect}>
              <Autocomplete
                aria-label="NarrBuilderStep2 OutputFields DropDown"
                multiple
                id="feedFieldsArray"
                options={targetElements || {}}
                limitTags={11}
                disableCloseOnSelect
                getOptionLabel={option => option.name || ""}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                value={targetElements.filter(n => {
                  let isMatch = false
                  narrative.feedFieldsArray?.forEach(itm => {
                    if (itm === n.id) {
                      isMatch = true
                    }
                  })
                  return isMatch
                })}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Output Fields"
                  />
                )}
                onChange={(event, newValue, reason) => {
                  handleAutoCompleteMultiChange(
                    event,
                    newValue,
                    reason,
                    "feedFieldsArray"
                  )
                }}
              />
            </GridItem>
          </GridContainer>
          <div>&nbsp;</div>
          <GridContainer>
            <GridItem md={12}>
              <TextField
                id="rawStory"
                label="Import Raw Story"
                multiline
                minRows={15}
                variant="outlined"
                fullWidth
                value={narrative.rawStory}
                onChange={e => handleCellChange(e)}
              />
            </GridItem>
          </GridContainer>
        </>
      )}
      {renderFooter()}
    </Body>
  )
}

NarrativeBuilderStep2.propTypes = {
  renderFooter: PropTypes.func.isRequired
}

export default NarrativeBuilderStep2
