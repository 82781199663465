import { useRef, useState } from "react"

const useTableChangedRows = () => {
  const [changedRowIndexes, setChangedRowIndexes] = useState([])
  const recordedChangedIndexes = useRef([])

  const addChangedRow = (rowIndex, options = { isNewRow: false }) => {
    if (!changedRowIndexes.includes(rowIndex) || options.isNewRow) {
      setChangedRowIndexes(prevRows => {
        let newChangedRows = [...prevRows]
        if (options.isNewRow) {
          newChangedRows = newChangedRows.map(idx =>
            idx >= rowIndex ? idx + 1 : idx
          )
        }
        newChangedRows.push(rowIndex)
        return newChangedRows
      })
    }
  }

  const removeChangedRow = (rowIndex, options = { isRowDeleted: false }) => {
    setChangedRowIndexes(prevIndexes => {
      if (!changedRowIndexes.includes(rowIndex)) {
        return prevIndexes
      }
      const newChangedRows = prevIndexes.filter(idx => idx !== rowIndex)
      return options.isRowDeleted
        ? newChangedRows.map(idx => (idx > rowIndex ? idx - 1 : idx))
        : newChangedRows
    })
  }

  const addNumberNewChangedRows = numberOfRows => {
    setChangedRowIndexes(prevRows => [
      ...Array(numberOfRows).keys(),
      ...prevRows
    ])
  }

  const recordChangedIndexes = () => {
    recordedChangedIndexes.current = changedRowIndexes
  }

  const restoreLastChangedIndexes = () => {
    setChangedRowIndexes(recordedChangedIndexes.current)
  }

  return {
    changedRowIndexes,
    addChangedRow,
    removeChangedRow,
    addNumberNewChangedRows,
    recordChangedIndexes,
    restoreLastChangedIndexes
  }
}

export default useTableChangedRows
