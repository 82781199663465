import { useEffect, useState } from "react"

import { Cookies } from "tools/storage"
const cookies = new Cookies()

const defaultOptions = {
  tableName: null,
  persistFilters: false
}

const useTableFilters = ({ tableName, persistFilters } = defaultOptions) => {
  const [activeFilters, setActiveFilters] = useState(
    cookies.get(tableName) || []
  )

  const handleFilterClear = () => {
    setActiveFilters([])
  }

  const handleFilterChange = (filterName, columnId, value, reason) => {
    const eventValue = value
    const eventId = filterName
    const activeFiltersCopy = [...activeFilters]
    const filterIndex = activeFiltersCopy.findIndex(
      obj => obj.filterName === filterName
    )

    if (reason === "remove-option" || reason === "clear") {
      if (eventValue === null || eventValue?.length === 0) {
        // DELETE from filter array
        const updatedFilters = activeFiltersCopy.filter(
          obj => obj.filterName !== eventId
        )
        setActiveFilters(updatedFilters)
      } else {
        // Remove multi-option value
        activeFiltersCopy.splice(filterIndex, 1, {
          filterName: eventId,
          id: columnId,
          value: eventValue
        })
        setActiveFilters(activeFiltersCopy)
      }
    } else if (filterIndex === -1) {
      // ADD to filter array
      activeFiltersCopy.push({
        filterName: eventId,
        id: columnId,
        value: eventValue
      })
      setActiveFilters(activeFiltersCopy)
    } else {
      if (eventValue === null) {
        // DELETE from filter array
        activeFiltersCopy.splice(filterIndex, 1)
        setActiveFilters(activeFiltersCopy)
      } else {
        // UPDATE filter array
        activeFiltersCopy.splice(filterIndex, 1, {
          filterName: eventId,
          id: columnId,
          value: eventValue
        })
        setActiveFilters(activeFiltersCopy)
      }
    }
  }

  const handleFilterDelete = (filterName, columnId, value) => {
    const activeFiltersCopy = [...activeFilters]
    const filterIndex = activeFiltersCopy.findIndex(
      obj => obj.filterName === filterName
    )
    if (value) {
      const nestedArr = activeFiltersCopy[filterIndex].value
      const updatedArr = nestedArr.filter(filter => filter.value !== value)
      if (updatedArr.length === 0) {
        activeFiltersCopy.splice(filterIndex, 1)
      } else {
        activeFiltersCopy[filterIndex] = {
          ...activeFiltersCopy[filterIndex],
          filterName,
          id: columnId,
          value: updatedArr
        }
      }

      setActiveFilters(activeFiltersCopy)
    } else {
      const updatedFilters = activeFiltersCopy.filter(
        obj => obj.filterName !== filterName
      )
      setActiveFilters(updatedFilters)
    }
  }

  const handleSelectAll = (filterName, columnId, value) => {
    const activeFilters = []

    if (activeFilters[0]?.value?.length === value.length) {
      return
    }

    activeFilters.push({
      filterName,
      id: columnId,
      value: value
    })

    setActiveFilters(activeFilters)
  }

  const handleDeleteAll = filterName => {
    const activeFiltersCopy = [...activeFilters]
    const updatedFilters = activeFiltersCopy.filter(
      obj => obj.filterName !== filterName
    )
    setActiveFilters(updatedFilters)
  }

  const formatFiltersForTable = () =>
    activeFilters.map(filter => {
      if (Array.isArray(filter.value)) {
        return { id: filter.id, value: filter.value }
      } else {
        return {
          id: filter.id,
          value: filter.value.value
        }
      }
    })

  useEffect(() => {
    if (persistFilters) {
      cookies.set(tableName, activeFilters)
    }
  }, [activeFilters])

  return {
    filterCount: activeFilters.length,
    activeFilters,
    tableActiveFilters: formatFiltersForTable(),
    handleDeleteAll,
    handleSelectAll,
    handleFilterDelete,
    handleFilterChange,
    handleFilterClear
  }
}

export default useTableFilters
