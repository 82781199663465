import { globalSettings } from "variables/general"
import { get, put } from "../tools/request"

export default class Cta_v2DAO {
  static getVariantsByLinkId = async (linkId, verbose) => {
    const res = await get(
      `${
        globalSettings.monetizationv2BaseUrl
      }/api/variant?linkId=${linkId}&verbose=${verbose ? verbose : "false"}`
    )
    return await res.json()
  }

  static getLinkDisplayInfo = async intentId => {
    const res = await get(
      `${globalSettings.monetizationv2BaseUrl}/api/link/tree?intentId=${intentId}`
    )
    return await res.json()
  }

  static getCompaniesInfo = async () => {
    const res = await get(`${globalSettings.monetizationv2BaseUrl}/api/company`)
    return await res.json()
  }

  static getOrganizationsInfo = async () => {
    const res = await get(
      `${globalSettings.monetizationv2BaseUrl}/api/organization`
    )
    return await res.json()
  }

  static getRegionsInfo = async () => {
    const res = await get(
      `${globalSettings.monetizationv2BaseUrl}/api/link/linkregions`
    )
    return await res.json()
  }

  static getIntentInfo = async () => {
    const res = await get(`${globalSettings.monetizationv2BaseUrl}/api/intent`)
    return await res.json()
  }

  static getLinkTypeInfo = async () => {
    const res = await get(
      `${globalSettings.monetizationv2BaseUrl}/api/link/linktypes`
    )
    return await res.json()
  }

  static getPreviewDependencyByIntent = async intentId => {
    const res = await get(
      `${globalSettings.monetizationv2BaseUrl}/api/previewdependency?intentId=${intentId}`
    )
    return await res.json()
  }

  static getCTAPreviewDependencyChangeLog = async previewDependencyId => {
    const res = await get(
      `${globalSettings.monetizationv2BaseUrl}/api/previewdependency/${previewDependencyId}/changelog`
    )
    return await res.json()
  }

  static postCTADependency = async body => {
    const res = await put(
      `${globalSettings.monetizationv2BaseUrl}/api/previewdependency`,
      null,
      body
    )
    return await res.json()
  }

  static getAffiliatesByOrgId = async organizationId => {
    const res = await get(
      `${globalSettings.monetizationv2BaseUrl}/api/affiliate?organizationIds=${organizationId}`
    )
    return await res.json()
  }

  static getLinkInfo = async id => {
    const res = await get(
      `${globalSettings.monetizationv2BaseUrl}/api/link/${id}`
    )
    return await res.json()
  }
  static getIntents = async () => {
    const res = await get(`${globalSettings.monetizationv2BaseUrl}/api/intent`)
    return await res.json()
  }

  static getCompanies = async () => {
    const res = await get(`${globalSettings.monetizationv2BaseUrl}/api/company`)
    return await res.json()
  }

  static postLinkInfo = async body => {
    const res = await put(
      `${globalSettings.monetizationv2BaseUrl}/api/link`,
      null,
      body
    )
    return await res.json()
  }

  static postLinkAction = async body => {
    const res = await put(
      `${globalSettings.monetizationv2BaseUrl}/api/link/action`,
      null,
      body
    )
    return await res.json()
  }

  static putUpdateVariantOnclicksByOrg = async orgId => {
    const res = await put(
      `${globalSettings.monetizationv2BaseUrl}/api/variant/addclickevents/${orgId}`,
      null,
      null
    )
    return await res.json()
  }

  static postVariantAction = async body => {
    const res = await put(
      `${globalSettings.monetizationv2BaseUrl}/api/variant/action`,
      null,
      body
    )
    return await res.json()
  }

  static postVariant = async body => {
    const res = await put(
      `${globalSettings.monetizationv2BaseUrl}/api/variant`,
      null,
      body
    )
    return await res.json()
  }

  static getVariantById = async variantId => {
    const res = await get(
      `${globalSettings.monetizationv2BaseUrl}/api/variant/${variantId}`
    )
    return await res.json()
  }

  static getVariantDisplayStyle = async intentId => {
    const res = await get(
      `${globalSettings.monetizationv2BaseUrl}/api/variant/displaystyles?intentId=${intentId}`
    )
    return await res.json()
  }

  static getVariantEvents = async () => {
    const res = await get(
      `${globalSettings.monetizationv2BaseUrl}/api/variant/events`
    )
    return await res.json()
  }

  static getVariantVerticals = async () => {
    const res = await get(
      `${globalSettings.monetizationv2BaseUrl}/api/variant/verticals`
    )
    return await res.json()
  }

  static getVariantLeagues = async () => {
    const res = await get(`${globalSettings.monetizationv2BaseUrl}/api/league`)
    return await res.json()
  }

  static getVariantGroupInfo = async (
    orgId,
    leagueId,
    verticalId,
    intentId,
    linkTypeId,
    companyId,
    linkId
  ) => {
    let qLeagueId = leagueId !== null ? leagueId : ""
    let qVerticalId = verticalId !== null ? verticalId : ""
    let qs1 = linkTypeId !== undefined ? `&linkTypeId=${linkTypeId}` : ""
    let qs2 = companyId !== undefined ? `&companyId=${companyId}` : ""
    let qs3 = linkId !== undefined ? `&linkId=${linkId}` : ""
    const qs = qs1 + qs2 + qs3
    const res = await get(
      `${globalSettings.monetizationv2BaseUrl}/api/link/variantgroupinfo?orgId=${orgId}&intentId=${intentId}&leagueId=${qLeagueId}&verticalId=${qVerticalId}${qs}`
    )
    return await res.json()
  }

  static getAvailableShortCodes = async linkId => {
    const res = await get(
      `${globalSettings.monetizationv2BaseUrl}/api/affiliate/availableshortcodes?linkId=${linkId}`
    )
    return await res.json()
  }

  static getRandomShortCode = async linkId => {
    const res = await get(
      `${globalSettings.monetizationv2BaseUrl}/api/affiliate/availableshortcodes?linkId=${linkId}&israndom=true`
    )
    return await res.json()
  }
}
