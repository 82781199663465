import PricingPage from "views/Pages/PricingPage"
import LoginPage from "views/Pages/LoginPage"
import LogoutPage from "views/Pages/LogoutPage"
import LockScreenPage from "views/Pages/LockScreenPage"
import ForgotPasswordPage from "views/Pages/ForgotPasswordPage"
import ChangePasswordPage from "views/Pages/ChangePasswordPage"
import Fingerprint from "@mui/icons-material/Fingerprint"
import MonetizationOn from "@mui/icons-material/MonetizationOn"
import LockOpen from "@mui/icons-material/LockOpen"
import FeedEntryPreviewPage from "views/Pages/FeedEntryPreviewPage"
import NarrativeOutlinePreviewPage from "views/Pages/NarrativeOutlinePreviewPage"

const pagesRoutes = [
  {
    path: "/login",
    name: "Login Page",
    short: "Login",
    mini: "LP",
    icon: Fingerprint,
    component: LoginPage
  },
  {
    hiddenInNav: true,
    path: "/logout",
    name: "Logout Page",
    short: "Logout",
    mini: "LO",
    icon: Fingerprint,
    component: LogoutPage
  },
  {
    hiddenInNav: true,
    path: "/forgotpassword",
    name: "Forgot Password Page",
    short: "Forgot",
    mini: "FP",
    icon: Fingerprint,
    component: ForgotPasswordPage
  },
  {
    hiddenInNav: true,
    path: "/changepassword",
    name: "Change Password Page",
    short: "Change",
    mini: "CP",
    icon: Fingerprint,
    component: ChangePasswordPage
  },
  {
    hiddenInNav: true,
    path: "/pages/pricing-page",
    name: "Pricing Page",
    short: "Pricing",
    mini: "PP",
    icon: MonetizationOn,
    component: PricingPage
  },
  {
    hiddenInNav: true,
    path: "/pages/lock-screen-page",
    name: "Lock Screen Page",
    short: "Lock",
    mini: "LSP",
    icon: LockOpen,
    component: LockScreenPage
  },
  {
    hiddenInNav: true,
    path: "/feedentrypreview/:feedEntryToken",
    name: "Feed Entry Preview Page",
    short: "Feed Entry Preview",
    mini: "FE",
    icon: Fingerprint,
    component: FeedEntryPreviewPage
  },
  {
    hiddenInNav: true,
    path: "/narrativeoutlinepreview/:narrativeToken",
    name: "Narrative Outline Preview Page",
    short: "Narrative Preview",
    mini: "FE",
    icon: Fingerprint,
    component: NarrativeOutlinePreviewPage
  }
  // { redirect: true, path: "/", pathTo: "/pages/login-page", name: "Dashboard" }
]

export default pagesRoutes
