import React, { useState, useEffect } from "react"
import { useObserver } from "mobx-react"
import { Cookies } from "tools/storage"
import { useStore } from "contexts/rootContext"
import { styled } from "@mui/styles"
import makeStyles from "@mui/styles/makeStyles"
import {
  Card,
  CardContent,
  FormControl,
  CircularProgress,
  Checkbox,
  TextField,
  FormControlLabel,
  Button
} from "@mui/material"
import { Autocomplete } from "@mui/material"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import InterstitialConfirmDialog from "components/NarrativeAdmin/LibraryCopyTools/InterstitialConfirmDialog"
import NarrativeBlockExplorer from "components/NarrativeAdmin/NarrativeBlockExplorer"
import useEntityHelper from "tools/LibraryCopyTools/EntityHelper"
import useNarrativeOptions from "hooks/narratives/useNarrativeOptions"
import LibraryCopySuccessModal from "components/NarrativeAdmin/LibraryCopyTools/LibraryCopySuccessModal"
import { processEntity } from "tools/LibraryCopyTools"
import NarrativeAutoComplete from "components/NarrativeAdmin/LibraryCopyTools/NarrativeAutocomplete"
import Enums from "tools/Enums.js"

const useStyles = makeStyles({
  explorerGridItem: {
    position: "relative",
    minHeight: "60vh"
  }
})

const Spinner = styled(CircularProgress)({
  position: "absolute",
  left: "45%",
  top: 30
})

const styles = {
  strikethrough: {
    textDecoration: "line-through"
  }
}

function useStoreData() {
  const store = useStore()

  return useObserver(() => ({
    loading: store.narrativeStore.loading,
    loadingSourceNarrative: store.narrativeStore.loadingSourceNarrative,
    loadingTargetNarrative: store.narrativeStore.loadingTargetNarrative,
    organizations: store.organizationStore.organizations,
    getSourceNarrative: store.narrativeStore.getSourceNarrative,
    sourceNarrative: store.narrativeStore.sourceNarrative,
    getTargetNarrative: store.narrativeStore.getTargetNarrative,
    targetNarrative: store.narrativeStore.targetNarrative,
    getNarratives: store.narrativeStore.getNarratives,
    getOrganizations: store.organizationStore.getOrganizations,
    postCopySentence: store.narrativeStore.postCopySentence,
    postCopyParagraph: store.narrativeStore.postCopyParagraph,
    postCopyTrigger: store.narrativeStore.postCopyTrigger,
    copiedElements: store.narrativeStore.copiedElements,
    setCopiedElements: store.narrativeStore.setCopiedElements,
    setSourceNarrative: store.narrativeStore.setSourceNarrative,
    setTargetNarrative: store.narrativeStore.setTargetNarrative
  }))
}

const NarrativeBlockBuilder = () => {
  const classes = useStyles()
  const {
    getSourceNarrative,
    getTargetNarrative,
    sourceNarrative,
    targetNarrative,
    organizations,
    getOrganizations,
    postCopyParagraph,
    postCopySentence,
    postCopyTrigger,
    loading,
    loadingSourceNarrative,
    loadingTargetNarrative,
    copiedElements,
    setCopiedElements,
    setSourceNarrative,
    setTargetNarrative
  } = useStoreData()

  // Get default current org from cookie
  let cookies = new Cookies()
  let cookieCurrentOrg = cookies.get("currentorg")

  const [confirmOpen, setConfirmOpen] = React.useState(false)
  const [showArchived, setShowArchived] = React.useState(false)
  const [showInactiveSrc, setShowInactiveSrc] = useState(false)

  const [numEntitiesBeingCopied, setNumEntitiesBeingCopied] = React.useState(0)

  // Source Info
  const [selectedSourceNarrative, setSelectedSourceNarrative] = useState(null)
  const [selectedSourceIds, setSelectedSourceIds] = useState([])

  // Target Info
  const [selectedTargetNarrative, setSelectedTargetNarrative] = useState(null)
  const [selectedTargetIds, setSelectedTargetIds] = useState([])

  // Autocomplete States
  const [selectedSourceOrg, setSelectedSourceOrg] = useState(null)
  const [selectedTargetOrg, setSelectedTargetOrg] = useState(null)

  const selectedSourceOrgId = selectedSourceOrg?.id
  const selectedTargetOrgId = selectedTargetOrg?.id

  const sourceNarrativeId = selectedSourceNarrative?.id
  const targetNarrativeId = selectedTargetNarrative?.id

  const { hasEntities, haveSomeEntityByRegex, onlySentencesInEntities } =
    useEntityHelper({ entities: selectedSourceIds })

  const {
    fetchNarratives: fetchSourceNarratives,
    narrativeOptions: sourceNarrativeOptions,
    isLoading: sourceNarrativeOptionsLoading,
    nonArchivedNarratives: nonArchivedSourceNarratives
  } = useNarrativeOptions({ organizationId: selectedSourceOrgId })

  const {
    fetchNarratives: fetchTargetNarratives,
    narrativeOptions: targetNarrativeOptions,
    isLoading: targetNarrativeOptionsLoading,
    nonArchivedNarratives: nonArchivedTargetNarratives
  } = useNarrativeOptions({ organizationId: selectedTargetOrgId })

  // on first render
  useEffect(() => {
    getOrganizations().then(organizations => {
      const org = organizations?.find(org => org.id === cookieCurrentOrg)
      setSelectedSourceOrg(org)
      setSelectedTargetOrg(org)
    })
  }, [])

  // componentWillUnmount()
  // reset source narrative state
  useEffect(
    () => () => {
      setSourceNarrative()
      setTargetNarrative()
    },
    []
  )

  useEffect(() => {
    setSelectedSourceIds([])
    fetchSourceNarratives()
  }, [selectedSourceOrgId])

  useEffect(() => {
    setSelectedTargetIds([])
    fetchTargetNarratives()
  }, [selectedTargetOrgId])

  useEffect(() => {
    if (selectedSourceNarrative === null) {
      setSourceNarrative()
    }
  }, [selectedSourceNarrative])

  useEffect(() => {
    if (selectedTargetNarrative === null) {
      setTargetNarrative()
    }
  }, [selectedTargetNarrative])

  const reloadNarratives = () => {
    getTargetNarrative(targetNarrativeId)
    getSourceNarrative(sourceNarrativeId)
  }

  const resetBlockSelections = () => {
    setSelectedSourceIds([])
    setSelectedTargetIds([])
  }

  const resetAllSelections = () => {
    resetBlockSelections()
    reloadNarratives()
  }

  const onCloseSuccessModal = () => {
    setCopiedElements([])
    setNumEntitiesBeingCopied(0)
  }

  // We only want paragraphs for targets
  const targetsAreValid = () =>
    selectedTargetIds?.length &&
    !selectedTargetIds.some(entity => {
      const { type } = processEntity(entity)
      return type !== "paragraphid"
    })

  async function postCopyNonSentenceBlock({ type, id }) {
    if (type === "triggerid" || type === "newstriggerid") {
      return postCopyTrigger(id, targetNarrativeId)
    } else if (type === "paragraphid") {
      return postCopyParagraph(id, targetNarrativeId)
    } else if (type === "sentenceid") {
      throw new Error("Sentence copying should use copySentencesToParagraphs()")
    }
  }

  const processBlocks = async blocks => {
    try {
      if (hasEntities() && onlySentencesInEntities() && targetsAreValid()) {
        await copySentencesToParagraphs()
      } else {
        setNumEntitiesBeingCopied(blocks.length)
        const promises = blocks.map(block => {
          const blockParts = processEntity(block)
          return postCopyNonSentenceBlock(blockParts)
        })
        await Promise.all(promises)
      }
    } catch (err) {
      console.error(
        `Error copying blocks to library ${selectedTargetNarrative?.id}`,
        err
      )
    }
  }

  const copySentencesToParagraphs = async () => {
    setCopiedElements([])
    setNumEntitiesBeingCopied(
      selectedSourceIds.length * selectedTargetIds.length
    )

    const promises = []
    selectedTargetIds.forEach(target => {
      selectedSourceIds.forEach(src => {
        const { id: srcId } = processEntity(src)
        const { id: targetId } = processEntity(target)
        if (srcId && targetId) {
          promises.push(
            postCopySentence(Number(srcId), Number(targetId), targetNarrativeId)
          )
        }
      })
    })
    await Promise.all(promises)
  }

  function canWeCopyBlocks() {
    if (!hasEntities()) {
      return {
        able: false,
        msg: "Nothing selected to copy."
      }
    }

    const haveSentences = haveSomeEntityByRegex(/sentenceid/)

    // If its a sentence, be sure other blocks aren't also selected
    if (haveSentences && !onlySentencesInEntities()) {
      return {
        able: false,
        msg: "You've selected a mix of sentences and other blocks which can confuse the system. Please copy top level items like Triggers and Paragraphs in separate batches from sentences."
      }
    }

    if (haveSentences && !targetsAreValid()) {
      return {
        able: false,
        msg: "Sentences can only be copied to paragraphs. Please select a target paragraph to continue"
      }
    }

    // Be sure we didnt get here without a target narrative
    if (
      targetNarrativeId === null ||
      targetNarrativeId === "" ||
      targetNarrativeId === 0
    ) {
      return {
        able: false,
        msg: "Please select the narrative you wish to export the blocks to."
      }
    }

    return { able: true }
  }

  async function copySelectedBlocks() {
    const { able, msg } = canWeCopyBlocks()
    if (!able) {
      alert(msg)
      return
    }

    await processBlocks(selectedSourceIds)
    resetAllSelections()
  }

  const orgsNoProps = organizations.toJS().filter(itm => itm.parentId === null)
  const orgsNoPropsNoArchive = orgsNoProps.filter(itm => itm.isActive === true)

  const disableClearSelection =
    !selectedSourceIds?.length && !selectedTargetIds?.length

  const successModalOpen = !!copiedElements?.length
  const disableCopyBlocks = !canWeCopyBlocks().able

  return (
    <Card>
      <CardContent>
        <GridContainer>
          <GridItem xs={4} style={{ textAlign: "center" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showInactiveSrc || false}
                  onChange={() => {
                    setShowInactiveSrc(!showInactiveSrc)
                  }}
                />
              }
              label={`Show Inactive Source Organizations? ${
                showInactiveSrc ? "*" : ""
              }`}
            />
          </GridItem>
          <GridItem xs={4} style={{ textAlign: "center" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showArchived || false}
                  onChange={() => {
                    setShowArchived(!showArchived)
                  }}
                />
              }
              label="Show Archived Outputs?"
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={5}>
            <h4>Source Output</h4>
          </GridItem>

          <GridItem xs={2} />
          <GridItem xs={5}>
            <h4>Target Output</h4>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={2}>
            <FormControl fullWidth>
              <Autocomplete
                id="organizations"
                disabled={sourceNarrativeOptionsLoading}
                options={
                  showInactiveSrc
                    ? orgsNoProps?.filter(
                        itm => itm.id !== Enums.Organizations.Libraries
                      )
                    : orgsNoPropsNoArchive?.filter(
                        itm => itm.id !== Enums.Organizations.Libraries
                      )
                }
                value={selectedSourceOrg}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id || ""
                }
                renderInput={params => (
                  <TextField
                    label="Source Organization"
                    key={params.id}
                    margin="none"
                    variant="outlined"
                    {...params}
                  />
                )}
                getOptionLabel={option =>
                  `${option.id} - ${option.name} ${option.isActive ? "" : "*"}`
                }
                renderOption={(props, option) => (
                  <li {...props}>
                    <div
                      style={{
                        ...(option.isArchived && styles.strikethrough)
                      }}
                    >
                      {option.id} - {option.name}
                    </div>
                  </li>
                )}
                onChange={(e, value) => {
                  setSelectedSourceOrg(value)
                }}
                onInputChange={(event, value, reason) => {
                  if (reason === "clear" || reason === "reset") {
                    setSelectedSourceNarrative(null)
                  }
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={3}>
            <FormControl fullWidth>
              <NarrativeAutoComplete
                narrative={selectedSourceNarrative}
                labelName="Source"
                isLibrary={false}
                options={
                  showArchived
                    ? sourceNarrativeOptions
                    : nonArchivedSourceNarratives
                }
                onChange={(e, value) => {
                  setSelectedSourceIds([])
                  getSourceNarrative(value?.id)
                  setSelectedSourceNarrative(value)
                }}
                disabled={sourceNarrativeOptionsLoading}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={2}>
            <div style={{ textAlign: "center" }}>
              {loading && (
                <Spinner
                  style={{ position: "absolute", left: "49%", top: "unset" }}
                />
              )}
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={disableClearSelection}
                  onClick={resetAllSelections}
                >
                  Clear Selections
                </Button>
              </div>
            </div>
            <InterstitialConfirmDialog
              open={confirmOpen}
              setOpen={setConfirmOpen}
              onConfirm={copySelectedBlocks}
              sourceEntities={selectedSourceIds}
              sourceNarrative={sourceNarrative}
              targetNarrative={targetNarrative}
              targetEntities={selectedTargetIds}
              selectedSources={selectedSourceIds}
            />
          </GridItem>
          <GridItem xs={2}>
            <FormControl fullWidth>
              <Autocomplete
                id="target-organizations"
                disabled={targetNarrativeOptionsLoading}
                options={orgsNoPropsNoArchive?.filter(
                  itm => itm.id !== Enums.Organizations.Libraries
                )}
                value={selectedTargetOrg}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id || ""
                }
                renderInput={params => (
                  <TextField
                    margin="none"
                    label="Target Organization"
                    key={params.id}
                    variant="outlined"
                    {...params}
                  />
                )}
                getOptionLabel={option =>
                  `${option.id} - ${option.name} ${option.isActive ? "" : "*"}`
                }
                renderOption={(props, option) => (
                  <li {...props}>
                    <div
                      style={{
                        ...(option.isArchived && styles.strikethrough)
                      }}
                    >
                      {option.id} - {option.name}
                    </div>
                  </li>
                )}
                onChange={(e, value) => {
                  setSelectedTargetOrg(value)
                }}
                onInputChange={(event, value, reason) => {
                  if (reason === "clear" || reason === "reset") {
                    setSelectedTargetNarrative(null)
                  }
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={3}>
            <FormControl fullWidth>
              <NarrativeAutoComplete
                narrative={selectedTargetNarrative}
                labelName="Target"
                isLibrary={false}
                options={
                  showArchived
                    ? targetNarrativeOptions
                    : nonArchivedTargetNarratives
                }
                onChange={(e, value) => {
                  setSelectedTargetIds([])
                  getTargetNarrative(value?.id)
                  setSelectedTargetNarrative(value)
                }}
                disabled={targetNarrativeOptionsLoading}
              />
            </FormControl>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={5} className={classes.explorerGridItem}>
            {loadingSourceNarrative && <Spinner />}
            {sourceNarrative?.id && (
              <NarrativeBlockExplorer
                targetOrSource="sourceNarrative"
                narrative={sourceNarrative}
                setBlocks={data => {
                  setSelectedSourceIds(data)
                }}
              />
            )}
          </GridItem>
          <GridItem
            xs={2}
            style={{
              textAlign: "center"
            }}
          >
            {sourceNarrative?.id && (
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  _disabled={!targetNarrativeId}
                  disabled={disableCopyBlocks}
                  style={{ _top: "300px" }}
                  onClick={() => setConfirmOpen(true)}
                >
                  Copy Selected Blocks
                </Button>
              </div>
            )}
          </GridItem>
          <GridItem xs={5} className={classes.explorerGridItem}>
            {loadingTargetNarrative && <Spinner />}
            {targetNarrative?.id && (
              <NarrativeBlockExplorer
                targetOrSource="targetNarrative"
                narrative={targetNarrative}
                handleBlockSelection={() => null}
                setBlocks={setSelectedTargetIds}
              />
            )}
          </GridItem>
        </GridContainer>
      </CardContent>
      {copiedElements && copiedElements.length > 0 && (
        <LibraryCopySuccessModal
          open={successModalOpen}
          copiedElements={copiedElements}
          numEntitiesBeingCopied={numEntitiesBeingCopied}
          onClose={onCloseSuccessModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          onCloseAction={() => {
            onCloseSuccessModal()
          }}
        />
      )}
    </Card>
  )
}

export default NarrativeBlockBuilder
