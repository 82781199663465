import React, { useEffect, useState } from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import { useLocation } from "react-router-dom"
import Button from "components/CustomButtons/Button"
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  Divider,
  Box
} from "@mui/material"
import {
  Edit,
  ExpandMore //, Add
} from "@mui/icons-material"

import { makeStyles } from "@mui/styles"
import RealTimeOutputForm from "components/RealTime/RealTimeOutputForm"
import RealTimeOutputTabs from "components/RealTime/EditorRealTimeLibrary/RealTimeOutputTabs"
import RealTimeInsightLibraryTree from "components/RealTime/EditorRealTimeLibrary/RealTimeInsightLibraryTree"
import { Cookies } from "tools/storage"
import { useHistory } from "react-router-dom"
import RealTimeDAO from "daos/realTimeDAO"

const useStyles = makeStyles(() => ({
  cardTop: {
    flexDirection: "column",
    backgroundColor: "#144e68",
    color: "white"
  },
  libraryTreeAccordion: {
    flex: "inline-flex"
  }
}))

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    realTimeOutput: store.realTimeStore.realTimeOutput,
    getRealTimeOutput: store.realTimeStore.getRealTimeOutput,
    allRealTimeOutputs: store.realTimeStore.allRealTimeOutputs,
    realTimeLibraries: store.realTimeStore.realTimeLibraries,
    realTimeLibraryById: store.realTimeStore.realTimeLibraryById,
    getRealTimeLibraryById: store.realTimeStore.getRealTimeLibraryById,
    insightSentence: store.realTimeStore.insightSentence,
    getInsightType: store.realTimeStore.getInsightType,
    insightType: store.realTimeStore.insightType,
    getInsightTypes: store.realTimeStore.getInsightTypes,
    insightTypes: store.realTimeStore.insightTypes,
    accountOrganizations: store.accountStore.accountOrganizations,
    setDialogSuccessMessage: store.uiStore.setDialogSuccessMessage,
    setDialogSuccessOpen: store.uiStore.setDialogSuccessOpen,
    setDialogFailMessage: store.uiStore.setDialogFailMessage,
    setDialogFailOpen: store.uiStore.setDialogFailOpen
  }))
}

function EditorRealTimeOutput() {
  const {
    realTimeOutput,
    getRealTimeOutput,
    allRealTimeOutputs,
    realTimeLibraries,
    getRealTimeLibraryById,
    realTimeLibraryById,
    getInsightType,
    insightType,
    getInsightTypes,
    accountOrganizations,
    setDialogSuccessMessage,
    setDialogSuccessOpen,
    setDialogFailMessage,
    setDialogFailOpen
  } = useStoreData()
  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  let cookies = new Cookies()
  const orgId = cookies.get("currentorg")
  const propertyId = cookies.get("currentProperty")
  const sentenceId = queryParams.get("sentenceId")
  const groupId = queryParams.get("groupId")
  const insightTypeId = queryParams.get("insightTypeId")

  const classes = useStyles()
  const [mode, setMode] = useState("")
  const [infoAccordionExpanded, setInfoAccordionExpanded] = useState(false)
  const [outputAccordionExpanded, setOutputAccordionExpanded] = useState(false)
  const [formValues, setFormValues] = useState({})
  const [rto, setRto] = useState({})
  const [openSentence, setOpenSentence] = useState(false)
  const [openGroup, setOpenGroup] = useState(false)
  const [openInsightType, setOpenInsightType] = useState(false)

  const [insightMode, setInsightMode] = useState("create")
  const [insightTypeData, setInsightTypeData] = useState(null)
  const [clickedSentence, setClickedSentence] = useState(null)
  const [clickedGroup, setClickedGroup] = useState(null)
  const [activateSave, setActivateSave] = useState(true)
  const [errorValues, setErrorValues] = useState([])

  const handleEditClick = () => {
    if (mode !== "edit") {
      setMode("edit")
    }
  }

  let outputId = window.location.pathname.match(/\d{1,}/)
  outputId = outputId[0] ? Number(outputId[0]) : 0

  const handleOpen = () => {
    setOpenSentence(true)
  }

  const leagueIdIfSports = (leagueId, itm) => {
    if (itm.verticalId?.id === 1) {
      if (!leagueId?.id) {
        return `Please add a League`
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const validationObject = {
    hasOwnProperty: [
      { variable: "name", name: "Name", step: 1 },
      { variable: "verticalId", name: "Vertical", step: 1 },
      { variable: "organizationId", name: "Organization", step: 1 },
      {
        variable: "leagueId",
        name: "League",
        step: 1,
        validation: leagueIdIfSports
      },
      { variable: "libraryId", name: "Library", step: 1 }
    ]
  }

  const formValueValidation = (itm, step) => {
    let result = []
    validationObject.hasOwnProperty?.forEach(field => {
      if (itm.hasOwnProperty(field.variable)) {
        if (typeof field.validation === "function") {
          const errorResult = field.validation(itm[field.variable], itm)
          if (errorResult) {
            result.push({
              name: field.variable,
              msg: errorResult
            })
          }
        } else if (field.array && !itm[field.variable].length) {
          result.push({
            name: field.variable,
            msg: `Please add a value for "${field.name}"`
          })
        } else if (!itm[field.variable]) {
          result.push({
            name: field.variable,
            msg: `Please add a value for "${field.name}"`
          })
        }
      }
    })
    if (result.length) {
      return result
    } else {
      return false
    }
  }
  const handleSave = async editSave => {
    const currentLibrary =
      realTimeLibraries.find(x => x.id === realTimeOutput?.library?.id) || {}
    const insightTypeId = []
    if (editSave) {
      currentLibrary?.insightTypes?.forEach(x => {
        if (formValues[x.name] === true) {
          insightTypeId.push(x.id)
        }
      })
    } else {
      formValues.libraryId?.insightTypes?.forEach(x => {
        if (formValues[x.name] === true) {
          insightTypeId.push(x.id)
        }
      })
    }

    let result = formValueValidation(formValues)
    if (insightTypeId?.length === 0) {
      result = !result
        ? [
            {
              name: "Insight Tuning",
              msg: `Please add at least one Insight Type`
            }
          ]
        : result.push({
            name: "Insight Tuning",
            msg: `Please add at least one Insight Type`
          })
    }
    setErrorValues(result ? result : [])
    if (result) {
      return
    }
    const outputData = {
      name: formValues.name,
      description: formValues.description || formValues.name,
      statusId: formValues.status?.id || 3,
      id: formValues.id || 0,
      libraryId: formValues.library?.id,
      isArchived: formValues.isArchived || false,
      insightTypeIds: insightTypeId,
      leagueId: formValues.league?.id || 1,
      organizationId: formValues.organization?.id,
      verticalId: formValues.vertical?.id
    }

    try {
      let successMessage = ""

      if (outputData.id) {
        // Update existing sentence
        const response = await RealTimeDAO.putRealTimeOutput(
          outputData,
          outputData.id
        )
        if (response.ok) {
          successMessage = `Output with ID:${formValues.id} updated successfully`
        } else {
          setDialogFailMessage(`Failed to update Output`)
          setDialogFailOpen(true)
        }
      } else {
        // Create new sentence
        const response = await RealTimeDAO.postRealTimeOutput(outputData)
        if (response && response.id) {
          successMessage = `New Output with ID: ${response.id} created successfully`
          history.push(`/portal/real-time-output/${response.id}`)
        } else {
          setDialogFailMessage(`Failed to save Output`)
          setDialogFailOpen(true)
        }
      }
      // Handle success
      if (successMessage) {
        setDialogSuccessMessage(successMessage)
        setDialogSuccessOpen(true)
        getRealTimeOutput(outputId)
      }
    } catch (error) {
      console.error("Error saving sentence:", error)
      // Show an error message to the user
      setDialogFailMessage(`Failed to save sentence: ${error.message}`)
      setDialogFailOpen(true)
    }
  }

  const handleInsightTypeFormSave = savedData => {
    setInsightMode("edit")
    setInsightTypeData(savedData)
    setOpenInsightType(true)
  }

  useEffect(() => {
    if (allRealTimeOutputs?.length > 0) {
      let rt
      allRealTimeOutputs.forEach(x => {
        if (x.id === outputId) {
          rt = x
        }
      })
      if (rt) {
        setRto(rt)
      }
    }
  }, [allRealTimeOutputs])

  useEffect(() => {
    if (outputId) {
      getRealTimeOutput(outputId)
    }
  }, [])

  useEffect(() => {
    if (outputId) {
      getRealTimeOutput(outputId)
    }
  }, [outputId])

  useEffect(() => {
    const fetchInsightType = async () => {
      if (insightTypeId) {
        try {
          await getInsightType(insightTypeId)
        } catch (error) {
          console.error("Error fetching insightType: ", error)
        } finally {
          setInsightMode("edit")
          setInsightTypeData(insightType)
          setOpenGroup(false)
          setOpenSentence(false)
          setOpenInsightType(true)
        }
      }
    }
    fetchInsightType()
  }, [insightTypeId])

  useEffect(() => {
    if (rto?.library?.id) {
      getRealTimeLibraryById(rto?.library?.id)
    }
  }, [rto])

  useEffect(() => {
    if (realTimeLibraryById?.league?.id > 0) {
      getInsightType(realTimeLibraryById.league.id)
      getInsightTypes(realTimeLibraryById.league.id)
    }
  }, [realTimeLibraryById])

  useEffect(() => {
    if (
      realTimeOutput?.organization?.id &&
      accountOrganizations?.toJS().length > 0
    ) {
      const orgs = accountOrganizations?.toJS()
      if (
        realTimeOutput?.organization?.id !== orgId &&
        realTimeOutput?.organization?.id !== propertyId
      ) {
        const realID = realTimeOutput.organization.id
        let correctOrg
        let correctProperty
        orgs?.forEach(x => {
          if (x.id === realID) {
            correctOrg = realID
          }
          x.properties?.forEach(p => {
            if (p.id === realID) {
              correctOrg = x.id
              correctProperty = p.id
            }
          })
        })
        if (correctOrg) {
          if (correctProperty) {
            if (propertyId === "All Properties") {
            } else {
              cookies.set("currentProperty", correctProperty)
              cookies.set("currentorg", correctOrg, { path: "/" })
              history.push(window.location.pathname)
            }
          } else {
            cookies.set("currentorg", correctOrg, { path: "/" })
            history.push(window.location.pathname)
          }
        }
      }
    }
  }, [realTimeOutput, accountOrganizations])

  return (
    <>
      <Box aria-label="Real Time Output Page, View with Functionality">
        {realTimeOutput?.id ? (
          <>
            <Typography variant="h5" style={{ paddingLeft: 8 }}>
              {realTimeOutput
                ? `${realTimeOutput?.id} - ${realTimeOutput?.name}`
                : "No data"}
            </Typography>
            {/* <ModelPreviewLink
            //   contentId={contentId}
            //   contentType={contentType}
            //   modelName={modelName}
            //   modelPreviewUrl={modelPreviewUrl}
            >
              Data Model:{" "}
            </ModelPreviewLink> */}
            <Card>
              <CardHeader
                className={classes.cardTop}
                sx={{ borderRadius: 0 }}
                //DO NOT REMOVE will added in V2
                // action={
                //   <Button startIcon={<Add />} color="primary">
                //     Bulk Upload
                //   </Button>
                // }
              />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={3}
                    aria-label="RealTimeLibrary Left Nav, Output Editor Card and Output Explorer"
                  >
                    <Accordion
                      onChange={(e, expanded) => {
                        if (expanded) {
                          setInfoAccordionExpanded(true)
                          setOutputAccordionExpanded(false)
                        } else {
                          setInfoAccordionExpanded(false)
                        }
                      }}
                      expanded={infoAccordionExpanded}
                    >
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        Real Time Output Information
                      </AccordionSummary>
                      <Card aria-label="RealTimeLibrary Editor Card">
                        <CardContent>
                          <Button
                            aria-label="RealTimeOutput Editor EditBtn"
                            startIcon={mode === "edit" ? null : <Edit />}
                            onClick={handleEditClick}
                            color="primary"
                            size="sm"
                          >
                            {"EDIT"}
                          </Button>
                          <RealTimeOutputForm
                            aria-label="RealTimeOutput Editor InfoForm"
                            sendFormValues={val => {
                              setFormValues(val)
                            }}
                            editStep2={false}
                            inCreateMode={false}
                            mode={mode}
                            step={0}
                            realTimeOutput={realTimeOutput}
                            // onCancel={handleCancelEdit}
                            onSave={editSave => {
                              handleSave(editSave)
                            }}
                            activateSave={activateSave}
                            errorValues={errorValues}
                            cancelEdit={() => {
                              setMode("")
                            }}
                          />
                        </CardContent>
                      </Card>
                    </Accordion>
                    <Accordion
                      onChange={(e, expanded) => {
                        if (expanded) {
                          setOutputAccordionExpanded(true)
                          setInfoAccordionExpanded(false)
                        } else {
                          setOutputAccordionExpanded(false)
                        }
                      }}
                      expanded={outputAccordionExpanded}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        sx={{
                          "& .MuiAccordionSummary-content": {
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                          }
                        }}
                      >
                        <Typography>Real Time Output</Typography>
                      </AccordionSummary>
                      <Divider />
                      <AccordionDetails>
                        <RealTimeInsightLibraryTree
                          treeForLibraryNotOutput={false}
                          realTimeOutput={realTimeOutput}
                          onItemClick={handleOpen}
                          libraryId={realTimeLibraryById?.id}
                          realTimeLibrary={realTimeLibraryById}
                          openSentenceForm={val => {
                            setOpenSentence(true)
                            setOpenGroup(false)
                            setOpenInsightType(false)
                            setClickedSentence(val)
                          }}
                          openGroupForm={val => {
                            setOpenGroup(true)
                            setOpenSentence(false)
                            setOpenInsightType(false)
                            setClickedGroup(val)
                          }}
                          openInsightForm={val => {
                            setOpenGroup(false)
                            setOpenSentence(false)
                            setInsightMode("edit")
                            setOpenInsightType(true)
                            setInsightTypeData(val)
                          }}
                          closeForms={() => {
                            setOpenGroup(false)
                            setOpenSentence(false)
                            setOpenInsightType(false)
                          }}
                          sentenceId={sentenceId}
                          groupId={groupId}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    aria-label="RealTimeLibrary Editor Card, Output Tabs section"
                  >
                    <RealTimeOutputTabs
                      infoAccordionOpen={infoAccordionExpanded}
                      outputAccordionOpen={outputAccordionExpanded}
                      aria-label="RealTimeOutput Editor InfoForm Step 2"
                      editStep2={true}
                      inCreateMode={false}
                      mode={mode}
                      outputId={outputId}
                      step={1}
                      realTimeOutput={realTimeOutput || rto}
                      realTimeLibraryById={realTimeLibraryById}
                      formValues={formValues}
                      handleInsightTypeFormSave={handleInsightTypeFormSave}
                      insightMode={insightMode}
                      insightTypeData={insightTypeData}
                      openInsightType={openInsightType}
                      sendFormValues={val => {
                        setFormValues(val)
                      }}
                      onSave={editSave => {
                        handleSave(editSave)
                      }}
                      resetToInfoAccordion={() => {
                        setInfoAccordionExpanded(true)
                        setOutputAccordionExpanded(false)
                      }}
                      resetToOutputAccordion={() => {
                        setInfoAccordionExpanded(false)
                        setOutputAccordionExpanded(true)
                      }}
                      resetOpenSentence={() => {
                        setOpenSentence(false)
                      }}
                      resetOpenGroup={() => {
                        setOpenGroup(false)
                      }}
                      resetOpenInsight={() => {
                        setOpenInsightType(false)
                      }}
                      insightTypeId={insightTypeId}
                      openSentence={openSentence}
                      openGroup={openGroup}
                      clickedSentence={clickedSentence}
                      clickedGroup={clickedGroup}
                      setActivateSave={val => {
                        setActivateSave(val)
                      }}
                    />
                    <Grid
                      aria-label="RealTimeLibrary Data Bar"
                      style={{ padding: "8px 0 0 0" }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </>
        ) : (
          <Typography variant="h5" style={{ paddingLeft: 8 }}>
            No Library Selected
          </Typography>
        )}
      </Box>
    </>
  )
}
export default EditorRealTimeOutput
