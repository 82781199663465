import React, { useEffect, useMemo } from "react"
import { useHistory } from "react-router-dom"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"

import { DataGrid } from "@mui/x-data-grid/DataGrid"

import makeStyles from "@mui/styles/makeStyles"
import {
  FormControlLabel,
  Switch,
  Card,
  IconButton,
  Typography
} from "@mui/material"
import { Edit } from "@mui/icons-material"

import GridItem from "components/Grid/GridItem"

const useStyles = makeStyles(() => ({
  archiveSwitch: {
    display: "flex",
    justifyContent: "flex-end"
  },
  libraryGrid: {
    maxWidth: 1300,
    marginLeft: "100px"
  },
  libraryRTCard: {
    width: 1300,
    paddingBottom: "24px"
  },
  tableLabel: {
    marginLeft: "50px",
    fontWeight: "bold",
    fontSize: "1.15rem"
  }
}))

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    realTimeLibraries: store.realTimeStore.realTimeLibraries,
    getRealTimeLibraries: store.realTimeStore.getRealTimeLibraries
  }))
}

export default function RealTimeLibraryTable({ onEditClick }) {
  const classes = useStyles()
  const history = useHistory()
  const { realTimeLibraries, getRealTimeLibraries } = useStoreData()

  const handleEditClick = id => {
    if (id != null) {
      const queryParams = new URLSearchParams({ id: id.toString() }).toString()
      history.push(`/portal/real-time-library/edit?${queryParams}`)
    } else {
      console.error("Attempted to edit library with undefined id")
    }
  }

  useEffect(() => {
    getRealTimeLibraries()
  }, [])

  const libraryColumns = useMemo(
    () => [
      {
        headerName: "ID",
        field: "id",
        width: 100
      },
      {
        field: "actions",
        headerName: "Action",
        type: "actions",
        renderCell: params => (
          <IconButton
            color="primary"
            onClick={() => handleEditClick(params.row.id)}
          >
            <Edit />
          </IconButton>
        ),
        width: 75
      },
      {
        headerName: "Name",
        field: "name",
        width: 350
      },
      {
        headerName: "Status",
        field: "status",
        renderCell: params => <span>{params.value.name}</span>,
        width: 200,
        sortable: true
      },
      {
        headerName: "Audit Info",
        field: "modifiedByAccount.name",
        width: 300
      }
    ],
    [onEditClick]
  )

  return (
    <>
      <Card
        elevation={0}
        aria-label="RealTimeLibrary Card DataGridContainer"
        className={classes.libraryRTCard}
      >
        <Typography className={classes.tableLabel}>
          Real Time Library Table
        </Typography>
        <GridItem className={classes.archiveSwitch}>
          <FormControlLabel
            control={<Switch name="includeArchived" color="primary" />}
            label="Include Archived"
            labelPlacement="end"
          />
        </GridItem>
        <DataGrid
          aria-label="RealTimeLibrary LibraryTable DataGrid"
          className={classes.libraryGrid}
          columns={libraryColumns}
          pagination
          initialState={{
            sorting: {
              sortModel: [{ field: "id", sort: "desc" }]
            },
            pagination: { paginationModel: { pageSize: 10 } }
          }}
          pageSizeOptions={[10, 25, 50]}
          sx={{
            "& .MuiDataGrid-columnHeaders": { backgroundColor: "#ddeaef" }
          }}
          rows={realTimeLibraries}
        />
      </Card>
    </>
  )
}
