export default function getFeaturedImageMarkup(featuredImageJSON) {
  try {
    const featuredImageObject = JSON.parse(featuredImageJSON)

    const imageUrl = featuredImageObject.Value.Url
    const linkString = `<a aria-label="NarrativePreviewControl FeaturedImage Preview Link" href="${imageUrl}" target="_blank">Click here to view the featured image.</a>`

    return linkString
  } catch (err) {
    console.error("Unable to parse narrative preview featured image json.", err)
  }
}
