import React from "react"
import ItemEditSummary from "../TriggerEdit/ItemEditSummary"

const useSuccessMessage = (
  copiedElements = [],
  withAssociatedTriggers = false,
  numEntitiesBeingCopied = 0,
  triggerChanges = [],
  classes = {}
) => {
  const success = (
    <div className={classes.successModal}>
      {numEntitiesBeingCopied === copiedElements.length && (
        <h2 className={classes.successH2}>Success!</h2>
      )}
      {numEntitiesBeingCopied > copiedElements.length && (
        <h4 className={classes.successH2}>
          You have copied {copiedElements.length} of {numEntitiesBeingCopied}{" "}
          entities {withAssociatedTriggers ? "with Triggers" : ""}
        </h4>
      )}
      <div id="server-modal-description">
        <ul>
          {copiedElements
            .filter(element => element.type !== "Copy Trigger")
            .map((itm, index, elems) => {
              const key = `${itm.type}-${itm.sourceId}`
              if (itm.type === "Copy Sentence") {
                return (
                  <li
                    key={key}
                    style={{
                      fontWeight: "bold",
                      listStyleType: "decimal"
                    }}
                  >
                    We copied Sentence "{itm.sourceId}" to Narrative "
                    {itm.targetNarrativeId}"{" "}
                    {withAssociatedTriggers ? "with Triggers" : ""}, Paragraph "
                    {itm.targetParagraphId}" and the copy now has a SentenceID
                    of "{itm.newId}" ({index + 1} of {elems.length})
                  </li>
                )
              } else if (itm.type === "Copy Paragraph") {
                return (
                  <li
                    key={key}
                    style={{
                      fontWeight: "bold",
                      listStyleType: "decimal"
                    }}
                  >
                    We copied Paragraph "{itm.sourceId}" to Narrative "
                    {itm.targetNarrativeId}"{" "}
                    {withAssociatedTriggers ? "with Triggers" : ""}, and the
                    copy now has a ParagraphID of "{itm.newId}" ({index + 1} of{" "}
                    {elems.length})
                  </li>
                )
              }
              return null
            })}
        </ul>
        {!!triggerChanges?.length && (
          <h3 className={classes.successH2}>
            Summary of Triggers Copied with Updates
          </h3>
        )}
        <ul>
          {copiedElements
            .filter(element => element.type === "Copy Trigger")
            .map((element, index, elems) => (
              <>
                <li
                  key={`${element.type}-${element.sourceId}`}
                  style={{
                    fontWeight: "bold",
                    listStyleType: "decimal"
                  }}
                >
                  <span>
                    We copied Trigger "{element.sourceId}" to Narrative "
                    {element.targetNarrativeId}", and the copy now has a
                    TriggerID of "{element.newId}" ({index + 1} of{" "}
                    {elems.length})
                  </span>
                  {!!triggerChanges?.length && (
                    <ItemEditSummary
                      changeList={triggerChanges.filter(
                        change => change.itemId === element.newId
                      )}
                    />
                  )}
                </li>
              </>
            ))}
        </ul>
      </div>
    </div>
  )
  return success
}

export default useSuccessMessage
