import React, { Component } from "react"
import PlayCircleFilled from "@mui/icons-material/PlayCircleFilled"
import PauseCircleFilled from "@mui/icons-material/PauseCircleFilled"
import { globalSettings } from "variables/general"

const defaultProps = {
  rawText: "",
  voiceName: "joanna",
  engineName: "neural",
  speakingStyle: "newscaster",
  languageCode: "en-US"
}

class AudioPlayer extends Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      playing: false
    }
  }

  audio = new Audio()

  playAudio = () => {
    this.setState({ playing: true })
    this.audio.src = `${globalSettings.apiBaseUrl}/api/converttexttoaudio?rawtext=${this.props.rawText}&voicename=${this.props.voiceName}&enginename=${this.props.engineName}&speakingstyle=${this.props.speakingStyle}&languageCode=${this.props.languageCode}`
    this.audio.play()
  }

  pauseAudio = () => {
    this.setState({ playing: false })
    this.audio.pause()
  }

  render() {
    return (
      <span onClick={this.state.playing ? this.pauseAudio : this.playAudio}>
        {!this.state.playing && <PlayCircleFilled />}
        {this.state.playing && <PauseCircleFilled />}
      </span>
    )
  }
}

AudioPlayer.defaultProps = defaultProps

export default AudioPlayer
