import { standardMargin } from "./NarrativeEditor"

export const styles = theme => ({
  root: {
    display: "block",
    position: "relative",
    height: "calc(200vh - 133px)",
    "& .highlighted": {
      backgroundColor: "#ffeaa7",
      color: "#e17055",
      boxShadow: "-3px 0 0 #ffeaa7, 3px 0 0 #ffeaa7" // Creates an illusion of a wider span, while not affecting the actual width.
    }
  },
  toolbar: {
    display: "flex",
    minHeight: "48px",
    padding: "0px 15px"
  },
  toggleHeader: {
    margin: "0 15px",
    padding: "10px 0!important",
    display: "flex"
  },
  toggleHeaderTitle: {
    flexGrow: 1
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    padding: "0px",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  content: {
    flexGrow: 2,
    background: "#fff"
  },
  appBar: {
    border: "1px solid #ddd",
    zIndex: "5",
    padding: theme.spacing(2)
  },
  explorerDrawer: {
    background: "#fff",
    border: "1px solid rgb(212, 212, 212)",
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
    "& > div": {
      flex: 1
    }
  },
  renderScheduleModalTop: {
    height: "80%"
  },
  renderScheduleModal: {
    width: "60vw",
    margin: "0 auto"
  },
  search: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  searchIcon: {
    padding: "6px",
    backgroundColor: "#fff"
  },
  inputRoot: {
    color: "inherit"
  },
  selectInput: {
    margin: "5px",
    paddingLeft: "10px",
    flexGrow: 2,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%"
    }
  },
  inputInput: {
    margin: "5px",
    paddingLeft: "10px",
    flexGrow: 2,
    transition: theme.transitions.create("width"),
    width: "100%",
    backgroundColor: "#efefef",
    [theme.breakpoints.up("md")]: {
      width: "100%"
    }
  },
  iconButton: {
    padding: "6px"
  },
  cardIconTitle: {
    color: "#000000"
  },
  scheduleOutputModal: {
    width: "90vw",
    height: "90vh",
    margin: "5vh auto",
    overflowY: "auto"
  },
  alertMessageModal: {
    width: "40vw",
    height: "200px",
    margin: "34vh auto"
  },
  tabs: {
    borderBottom: "none!important",
    backgroundColor: "#fff",
    "& > .nav-item .nav-link": {
      cursor: "pointer"
    },
    "& > .nav-item .nav-link:hover": {
      backgroundColor: "rgba(0,0,0, 0.2)"
    },
    "& > .nav-item .nav-link.active": {
      backgroundColor: "#efefef",
      borderColor: "#dee2e6 #dee2e6 transparent"
    },
    "& .nav-tabs > .nav-tabs .nav-link.active": {
      backgroundColor: "#fff",
      borderColor: "#dee2e6 #dee2e6 #fff"
    },
    "& > .nav-item .nav-link.activeStarter": {
      backgroundColor: "#ddd",
      borderColor: "#dee2e6 #dee2e6 transparent"
    },
    "& .nav-tabs > .nav-tabs .nav-link.activeStarter": {
      backgroundColor: "#eee",
      borderColor: "#dee2e6 #dee2e6 #fff"
    }
  },
  cronBuilder: {
    width: "34vw",
    margin: "21vh auto",
    "& .cron-builder": {
      backgroundColor: "#eee"
    },
    "& .cron-builder__legend": {
      fontSize: "14px"
    },
    "& .cron-builder__tab": {
      margin: "4px"
    },
    "& .Select-control": {
      minWidth: "110px"
    },
    "& .cron-builder__label": {
      color: "#999"
    }
  },
  progressingMsg: {
    color: "#999",
    textAlign: "center",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    margin: standardMargin
  },
  finishedMsg: {
    color: "#069",
    textAlign: "center",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    margin: standardMargin
  },
  failedListMsg: {
    color: "#C00",
    textAlign: "center",
    cursor: "pointer",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    margin: standardMargin
  },
  failedMsg: {
    color: "#C00",
    textAlign: "center",
    cursor: "pointer",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    margin: "12px 15px 0 5px"
  },
  progressBar: {
    width: "40%",
    background: "#eee",
    display: "flex",
    top: "55px",
    right: "20px",
    position: "fixed",
    zIndex: "99999",
    border: "2px solid #036",
    borderRadius: "15px",
    padding: "4px"
  },
  progressIcon: {
    color: "#000",
    border: "2px solid",
    borderRadius: "20px",
    height: "30px",
    _marginTop: "5px"
  },
  errorModal: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "45%",
    left: "45%",
    transform: "translate(-45%, -45%)"
  },
  variationScoreHolder: {
    display: "inline-flex"
  },
  variationScoreRadio: {
    display: "inline-flex",
    alignItems: "center",
    "& .MuiRadio-root": {
      padding: 0,
      margin: "0 5px"
    }
  }
})
