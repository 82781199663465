import { useEffect, useState } from "react"
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query"

// MUI Components
import Alert from "@mui/material/Alert"
import DialogTitle from "@mui/material/DialogTitle"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import Divider from "@mui/material/Divider"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import Skeleton from "@mui/material/Skeleton"
import Snackbar from "@mui/material/Snackbar"
import Switch from "@mui/material/Switch"
import Typography from "@mui/material/Typography"

// MUI Icons & Utils
import CancelIcon from "@mui/icons-material/Cancel"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed"
import ErrorIcon from "@mui/icons-material/Error"
import InfoIcon from "@mui/icons-material/Info"
import withStyles from "@mui/styles/withStyles"

// Custom Components
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardHeader from "components/Card/CardHeader"
import CardIcon from "components/Card/CardIcon"

// Utils
import AppConfigDAO from "daos/appConfigDAO.js"

// Styles
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle"
import { useRenderToolStyles } from "./styles/RenderTool.style.jsx"

const RenderTool = ({ classes }) => {
  const {
    gridContainer,
    cardTitle,
    cardSubTitle,
    helpIcon,
    statusContainer,
    controlContainer,
    statusLabel,
    subHeading,
    botCount,
    switchContainer,
    switchLabel,
    errorMessage,
    errorIcon,
    falseIcon,
    successIcon,
    snackbar
  } = useRenderToolStyles()

  const [response, setResponse] = useState(undefined)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  const queryClient = useQueryClient()

  const { status, data } = useQuery({
    queryKey: ["renderStatus"],
    queryFn: () => AppConfigDAO.getRenderStatus(),
    refetchOnWindowFocus: false
  })

  const mutation = useMutation({
    mutationFn: event => {
      const domain =
        event.target.name === "canschedulenarratives" ? "scheduler" : "cli"
      AppConfigDAO.setRenderStatus(
        domain,
        event.target.name,
        !event.target.checked
      )
    },
    onMutate: async event => {
      await queryClient.cancelQueries({ queryKey: ["renderStatus"] })
      const previousState = queryClient.getQueryData(["renderStatus"])
      if (previousState) {
        const name =
          event.target.name === "canrendernarratives"
            ? "isRenderingNarratives"
            : "isSchedulingNarratives"
        queryClient.setQueryData(["renderStatus"], {
          ...previousState,
          content: {
            ...previousState.content,
            [name]: !event.target.checked
          }
        })
      }
      return previousState
    },
    onSuccess: () => {
      setResponse({ type: "success", message: "Update successful" })
    },
    onError: (err, variables, prevData) => {
      const label =
        variables.target.name === "canrendernarratives"
          ? "Rendering Narratives"
          : "Scheduling Narratives"
      setResponse({ type: "error", message: `${label} update failed.` })
      if (prevData) {
        queryClient.setQueryData(["renderStatus"], prevData)
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["renderStatus"],
        refetchType: "all"
      })
    }
  })

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setOpenSnackbar(false)
  }

  useEffect(() => {
    if (response !== undefined) {
      setOpenSnackbar(true)
    }
  }, [response])

  return (
    <>
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <DynamicFeedIcon />
          </CardIcon>
          <h4 className={`${classes.cardIconTitle} ${cardTitle}`}>
            Renderer & Scheduler Tool
          </h4>
          <IconButton
            aria-label="Render Tool Information"
            color="primary"
            className={helpIcon}
            onClick={() => setOpenDialog(true)}
            size="large"
          >
            <InfoIcon />
          </IconButton>
        </CardHeader>
        <CardBody>
          <Typography variant="h1" className={cardSubTitle}>
            {window._env_.REACT_APP_ENV.toUpperCase()} Renderbot & Scheduler
          </Typography>
          <Grid
            container
            className={gridContainer}
            justifyContent="center"
            spacing={2}
          >
            {status === "error" ? (
              <Grid item>
                <Typography variant="h3" className={errorMessage}>
                  <ErrorIcon className={errorIcon} />
                  An error occurred. Please contact a system administrator.
                </Typography>
              </Grid>
            ) : (
              <>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  justifyContent="flex-end"
                  className={statusContainer}
                >
                  <Typography variant="h2" className={subHeading}>
                    Status
                  </Typography>
                  <Grid container justifyContent="center" spacing={2}>
                    <Grid item>
                      {status === "loading" ? (
                        <Skeleton
                          variant="text"
                          animation="wave"
                          style={{ width: 200, height: "1.5rem" }}
                        />
                      ) : (
                        <Typography variant="h3" className={statusLabel}>
                          Renderbot Count:{" "}
                        </Typography>
                      )}
                      {status === "loading" ? (
                        <Skeleton
                          variant="text"
                          animation="wave"
                          style={{ width: 200, height: "5rem" }}
                        />
                      ) : (
                        <Typography variant="h3" className={botCount}>
                          {data?.content.renderbotCount}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item>
                      {status === "loading" ? (
                        <Skeleton
                          variant="text"
                          animation="wave"
                          style={{ width: 200, height: "1.5rem" }}
                        />
                      ) : (
                        <Typography variant="h3" className={statusLabel}>
                          UAT Renderbot Count:{" "}
                        </Typography>
                      )}
                      {status === "loading" ? (
                        <Skeleton
                          variant="text"
                          animation="wave"
                          style={{ width: 200, height: "5rem" }}
                        />
                      ) : (
                        <Typography variant="h3" className={botCount}>
                          {data?.content.uatRenderbotCount}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item xs={12} sm={5} className={controlContainer}>
                  <Typography variant="h2" className={subHeading}>
                    Control
                  </Typography>
                  <FormGroup className={switchContainer}>
                    {status === "loading" ? (
                      <Skeleton
                        variant="text"
                        animation="wave"
                        style={{ width: 220, height: 40 }}
                      />
                    ) : (
                      <>
                        {window._env_.REACT_APP_ENV === "prod" ? (
                          <Typography variant="body1" className={switchLabel}>
                            {data?.content.isRenderingNarratives ? (
                              <CheckCircleIcon className={successIcon} />
                            ) : (
                              <CancelIcon className={falseIcon} />
                            )}{" "}
                            Render Outputs
                          </Typography>
                        ) : (
                          <FormControlLabel
                            control={
                              <Switch
                                checked={data?.content.isRenderingNarratives}
                                onChange={event => mutation.mutate(event)}
                                name="canrendernarratives"
                                color="primary"
                                disabled={window._env_.REACT_APP_ENV === "prod"}
                              />
                            }
                            label={
                              <Typography
                                variant="body1"
                                className={switchLabel}
                              >
                                Render Outputs
                              </Typography>
                            }
                          />
                        )}
                      </>
                    )}
                    {status === "loading" ? (
                      <Skeleton
                        variant="text"
                        animation="wave"
                        style={{ width: 220, height: 40 }}
                      />
                    ) : (
                      <>
                        {window._env_.REACT_APP_ENV === "prod" ? (
                          <Typography variant="body1" className={switchLabel}>
                            {data?.content.isSchedulingNarratives ? (
                              <CheckCircleIcon className={successIcon} />
                            ) : (
                              <CancelIcon className={falseIcon} />
                            )}{" "}
                            Schedule Outputs
                          </Typography>
                        ) : (
                          <FormControlLabel
                            control={
                              <Switch
                                checked={data?.content.isSchedulingNarratives}
                                onChange={event => mutation.mutate(event)}
                                name="canschedulenarratives"
                                color="primary"
                                disabled={window._env_.REACT_APP_ENV === "prod"}
                              />
                            }
                            label={
                              <Typography
                                variant="body1"
                                className={switchLabel}
                              >
                                Schedule Outputs
                              </Typography>
                            }
                          />
                        )}
                      </>
                    )}
                  </FormGroup>
                </Grid>
              </>
            )}
          </Grid>
        </CardBody>
      </Card>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleClose}
        TransitionProps={{ onExited: () => setResponse(undefined) }}
      >
        <Alert
          onClose={handleClose}
          variant="filled"
          severity={response?.type}
          className={snackbar}
        >
          {response?.message}
        </Alert>
      </Snackbar>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="render-tool-dialog-title"
      >
        <DialogTitle id="render-tool-dialog-title">
          Render Tool Information
        </DialogTitle>
        <DialogContent>
          <Typography paragraph>
            Renderbot Count = Current number of Renderbots enabled for rendering
            feed entries on Active feeds.
          </Typography>
          <Typography paragraph>
            UAT Renderbot Count = Current number of Renderbots enabled for
            rendering feed entries on UAT specific feeds.
          </Typography>
          <Typography paragraph>
            Render Outputs = Toggle to allow for Renderbots to render Feed
            Entries to Feeds based on Render Instructions. (Must be ON for Feed
            Entries to be created for Ad-Hoc or Render Schedules)
          </Typography>
          <Typography paragraph>
            Schedule Outputs = Toggle to allow for system to analyze Render
            Schedules CRON timing and Ad-Hoc Render Requests to render Feed
            Entries. (Must be ON for for Render Instructions to be sent to
            Renderbot queue)
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default withStyles(regularFormsStyle)(RenderTool)
