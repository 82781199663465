import React from "react"

export default function CodeBlock({ children, highlight }) {
  if (!highlight) {
    return children
  }
  let safeHighlight = highlight.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1")
  const regexp = new RegExp(safeHighlight, "g")
  const matches = children.match(regexp)
  let parts = children.split(new RegExp(`${safeHighlight.replace()}`, "g"))

  for (let i = 0; i < parts.length; i++) {
    if (i !== parts.length - 1) {
      let match = matches[i]
      // While the next part is an empty string, merge the corresponding match with the current
      // match into a single <span/> to avoid consequent spans with nothing between them.
      while (parts[i + 1] === "") {
        match += matches[++i] || ""
      }

      parts[i] = (
        <React.Fragment key={i}>
          {parts[i]}
          <span className="highlighted">{match}</span>
        </React.Fragment>
      )
    }
  }
  return <div className="highlighter">{parts}</div>
}
