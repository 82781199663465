import React, {
  useEffect,
  useState //useMemo
} from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"

import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import { TabPanel } from "components/TabPanel/TabPanel"
import AppBar from "@mui/material/AppBar"

//import { makeStyles } from "@mui/styles"
import {
  Grid //Autocomplete, TextField
} from "@mui/material"
import RealTimeOutputForm from "components/RealTime/RealTimeOutputForm"
import RealTimeSentenceForm from "./RealTimeSentenceForm"
import InsightGroupForm from "./InsightGroupForm"
import InsightTypeForm from "./InsightTypeForm"
import { Card, CardContent } from "@mui/material"

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    insightType: store.realTimeStore.insightType,
    insightTypes: store.realTimeStore.insightTypes
  }))
}

export default function RealTimeOutputTabs({
  mode,
  onClose,
  step,
  editStep2,
  outputId,
  insightTypeId,
  outputAccordionOpen,
  infoAccordionOpen,
  formValues,
  openSentence,
  openGroup,
  realTimeLibraryById,
  clickedGroup,
  clickedSentence,
  resetToInfoAccordion,
  resetToOutputAccordion,
  resetOpenSentence,
  resetOpenGroup,
  resetOpenInsight,
  setActivateSave,
  handleInsightTypeFormSave,
  insightMode,
  insightTypeData,
  openInsightType,
  onSave,
  realTimeOutput,
  sendFormValues
}) {
  const [openSentenceReadOnly, setOpenSentenceReadOnly] = useState(false)
  const [openGroupReadOnly, setOpenGroupReadOnly] = useState(false)
  const [openInsightReadOnly, setOpenInsightReadOnly] = useState(true)
  const [currentView, setCurrentView] = useState(0)
  const [initialLoad, setInitialLoad] = useState(true)
  const [unusedInsightTypes, setUnusedInsightTypes] = useState([])
  const _OUTPUT = 1
  const _OUTPUT_PREFERENCE = 0

  const { insightType, insightTypes } = useStoreData()

  const handleCloseSentence = () => {
    setOpenSentenceReadOnly(false)
    resetOpenSentence()
  }

  const handleCloseGroup = () => {
    setOpenGroupReadOnly(false)
    resetOpenGroup()
  }

  const handleInsightTypeFormClose = () => {
    setOpenInsightReadOnly(false)
    resetOpenInsight()
  }

  const handleTabChange = (event, newValue) => {
    if (newValue !== currentView || initialLoad) {
      if (newValue === _OUTPUT_PREFERENCE) {
        resetToInfoAccordion()
      } else if (newValue === _OUTPUT) {
        resetToOutputAccordion()
      }
      setCurrentView(newValue)
      setInitialLoad(false)
    }
  }

  useEffect(() => {
    if (outputAccordionOpen === true) {
      setCurrentView(_OUTPUT)
      setInitialLoad(false)
    } else if (infoAccordionOpen === true) {
      setCurrentView(_OUTPUT_PREFERENCE)
      setInitialLoad(false)
    } else if (outputAccordionOpen === false) {
      setCurrentView(-1)
    } else if (infoAccordionOpen === false) {
      setCurrentView(-1)
    }
  }, [outputAccordionOpen, infoAccordionOpen])

  useEffect(() => {
    if (openSentence === true) {
      setOpenSentenceReadOnly(true)
      setOpenGroupReadOnly(false)
      setOpenInsightReadOnly(false)
    } else if (openGroup === true) {
      setOpenGroupReadOnly(true)
      setOpenSentenceReadOnly(false)
      setOpenInsightReadOnly(false)
    } else if (openInsightType) {
      setOpenInsightReadOnly(true)
      setOpenSentenceReadOnly(false)
      setOpenGroupReadOnly(false)
    }
  }, [openSentence, openGroup, openInsightType])

  useEffect(() => {
    if (realTimeLibraryById?.insightTypes && insightTypes?.length > 0) {
      const availableInsightTypes = []
      insightTypes.forEach(it => {
        let exists = false
        realTimeLibraryById?.insightTypes.forEach(rt => {
          if (rt?.id === it?.id) {
            exists = true
          }
        })
        if (!exists) {
          availableInsightTypes.push(it)
        }
      })
      setUnusedInsightTypes(availableInsightTypes)
    }
  }, [realTimeLibraryById, insightTypes])

  return (
    <>
      <AppBar
        position="static"
        color="default"
        enableColorOnDark
        aria-label="Real Time Outputs Tabs App Bar"
      >
        <Tabs
          value={initialLoad ? -1 : currentView}
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleTabChange}
          aria-label="eal Time Outputs Tabs "
        >
          <Tab label="OUTPUT PREFERENCES" />
          <Tab label="OUTPUT" />
        </Tabs>
      </AppBar>
      <TabPanel
        value={initialLoad ? -1 : currentView}
        index={0}
        aria-label="Real Time Outputs Tabs Output Preference Container"
      >
        <Grid
          container
          style={{
            alignItems: "center",
            padding: "10px 0"
          }}
        >
          <Card>
            <CardContent>
              <RealTimeOutputForm
                infoAccordionOpen={infoAccordionOpen}
                outputAccordionOpen={outputAccordionOpen}
                tabsFormat={true}
                aria-label="RealTimeOutput Editor InfoForm Output Preference Container"
                editStep2={true}
                inCreateMode={false}
                mode={mode}
                outputId={outputId}
                step={1}
                //realTimeOutput={rto || realTimeOutput}
                formValues={formValues}
                realTimeOutput={realTimeOutput}
                sendFormValues={val => {
                  sendFormValues(val)
                }}
                onSave={editSave => {
                  onSave(editSave)
                  //handleSave(editSave)
                }}
                //activateSave={activateSave}
                setActivateSave={val => {
                  setActivateSave(val)
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </TabPanel>
      <TabPanel
        value={initialLoad ? -1 : currentView}
        index={1}
        aria-label="Real Time Outputs Tabs Output Container"
      >
        <Grid
          container
          style={{
            alignItems: "center",
            padding: "10px 0 0 0"
          }}
        >
          <CardContent style={{ width: "100%" }}>
            {openSentenceReadOnly && (
              <RealTimeSentenceForm
                open={openSentence}
                //setOpen={setOpenSentence}
                onClose={handleCloseSentence}
                libraryId={realTimeLibraryById?.id}
                onSave={() => {}}
                saveData={{}}
                sentenceData={clickedSentence}
                leagueId={realTimeLibraryById?.league?.id}
                readOnly={true}
              />
            )}
            {openGroupReadOnly && (
              <InsightGroupForm
                onClose={handleCloseGroup}
                open={openGroup}
                onSave={() => {}}
                libraryId={realTimeLibraryById?.id}
                groupData={clickedGroup}
                readOnly={true}
              />
            )}
            {openInsightReadOnly && (
              <InsightTypeForm
                open={openInsightType}
                onClose={handleInsightTypeFormClose}
                mode={insightMode}
                showDialog={!insightTypeId}
                typeData={insightType ?? insightTypeData}
                onSave={handleInsightTypeFormSave}
                realTimeLibraryById={realTimeLibraryById}
                unusedInsightTypes={unusedInsightTypes}
                readOnly={true}
              />
            )}
          </CardContent>
        </Grid>
      </TabPanel>
    </>
  )
}
