import React from "react"
import { withRouter } from "react-router"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import { Cookies } from "tools/storage"
import { Select, MenuItem } from "@mui/material"
import Enums from "tools/Enums.js"

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    sortedAccountOrganizations: store.accountStore.sortedAccountOrganizations,
    accountOrganizations: store.accountStore.accountOrganizations,
    properties: store.accountStore.sortedProperties,
    setOrganizationId: store.organizationStore.setOrganizationId,
    setOrganizationProperties:
      store.organizationStore.setOrganizationProperties,
    organizationId: store.organizationStore.organizationId,
    setOrganization: store.organizationStore.setOrganization,
    orgAndPropId: store.organizationStore.orgAndPropId,
    setOrgAndPropId: store.organizationStore.setOrgAndPropId
  }))
}

function OrganizationsMenu(props) {
  const {
    sortedAccountOrganizations,
    properties,
    setOrganizationId,
    setOrganizationProperties,
    organizationId,
    setOrganization,
    accountOrganizations,
    setOrgAndPropId
  } = useStoreData()
  const loadOrganizationAndProperties = (organizationId, properties) => {
    let propertiesForOrg = []
    let newOrgId = null
    properties &&
      properties.forEach(itm => {
        if (itm.parentId === organizationId) {
          propertiesForOrg.push(itm)
        } else if (itm.id === organizationId && !!itm.parentId) {
          newOrgId = itm.parentId
        }
      })
    if (newOrgId && properties.length !== propertiesForOrg.length) {
      setOrganizationId(newOrgId)
      setOrgAndPropId(newOrgId)
      setOrganizationProperties(propertiesForOrg)
    } else if (properties.length !== propertiesForOrg.length) {
      setOrganizationId(organizationId)
      setOrgAndPropId(organizationId)
      setOrganizationProperties(propertiesForOrg)
    }
  }

  const onOrganizationChange = event => {
    if (organizationId !== event.target.value) {
      let cookies = new Cookies()
      if (Number(event.target.value) !== Enums.Organizations.Libraries) {
        cookies.set("lastorg", event.target.value)
      }
      cookies.set("currentProperty", "")
      setOrganizationId(event.target.value)
      setOrganization(
        accountOrganizations.filter(o => o.id === event.target.value)[0]
      )
      loadOrganizationAndProperties(event.target.value, properties)
      cookies.set("currentorg", event.target.value, { path: "/" })
      setOrgAndPropId(event.target.value)
      const pagesToReloadOnOrgChange = [
        {
          matchValue: /portal\/feed-entries(?:\/entry\/|\/)\d{3,}/,
          reloadURL: "/portal/feed-manager"
        },
        {
          matchValue: /portal\/feed-manager/,
          reloadURL: "/portal/feed-manager"
        },
        {
          matchValue: /portal\/narratives\/?/,
          reloadURL: "/portal/narratives"
        },
        {
          matchValue:
            /portal\/narrative\/(?:\d{3,}\/?$|\d{3,}\/edit\/?$|\d{3,}\/output\/?$|\d{3,}\/schedule\/?$)/,
          reloadURL: "/portal/narratives"
        },
        {
          matchValue: /portal\/real-time-outputs\/?/,
          reloadURL: "/portal/real-time-outputs"
        },
        {
          matchValue: /portal\/real-time-output\/\d{1,}/,
          reloadURL: "/portal/real-time-outputs"
        }
      ]
      pagesToReloadOnOrgChange.forEach(itm => {
        if (props.location.pathname.match(itm.matchValue)) {
          props.history.push(itm.reloadURL)
        }
      })
    }
  }

  if (props.showOrgMenu) {
    return (
      <Select
        variant="standard"
        fullWidth={false}
        type="select"
        name="OrganizationAndPropertySelect"
        id="OrganizationAndPropertySelect"
        value={props.organizationId || ""}
        onChange={e => onOrganizationChange(e)}
      >
        {sortedAccountOrganizations.map(organization => (
          <MenuItem key={organization.id} value={organization.id}>
            {organization.name}
          </MenuItem>
        ))}
      </Select>
    )
  } else {
    return <></>
  }
}

export default withRouter(OrganizationsMenu)
