import { useState } from "react"
import { Box, Button } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import Highlighter from "react-highlight-words"

import SelectableFindReplaceTable from "../../Table/SelectableFindReplaceTable"
import ItemTriggerSummary from "./ItemTriggerSummary"
import CustomDSDialog from "components/CustomDialogs/CustomDSDialog"
import Warning from "@mui/icons-material/Warning"
import InfoOutlined from "@mui/icons-material/InfoOutlined"

const Steps = {
  Edit: "edit",
  Summary: "summary"
}

export const triggerEditableFields = [
  {
    value: "name",
    label: "Name"
  },
  {
    value: "type",
    label: "Key"
  },
  {
    value: "formula",
    label: "Formula"
  }
]

const columns = [
  {
    header: "Trigger ID",
    accessor: "id",
    sortable: true,
    filterable: false
  },
  {
    header: "Trigger Name",
    accessor: "name",
    sortable: true,
    filterable: true,
    filterFn: "includesStringSensitive",
    cell: ({ column, getValue }) => (
      <Highlighter
        searchWords={[column.getFilterValue()]}
        textToHighlight={getValue()}
      />
    )
  },
  {
    header: "Trigger Key",
    accessor: "type",
    sortable: true,
    filterable: true,
    filterFn: "includesStringSensitive",
    cell: ({ column, getValue }) => (
      <Highlighter
        searchWords={[column.getFilterValue()]}
        textToHighlight={getValue()}
      />
    )
  },
  {
    header: "Trigger Formula",
    accessor: "formula",
    sortable: false,
    filterable: true,
    filterFn: "includesStringSensitive",
    cell: ({ column, getValue }) => (
      <Highlighter
        searchWords={[column.getFilterValue()]}
        textToHighlight={getValue()}
      />
    )
  }
]

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between"
  },
  infoHeader: {
    textAlign: "left",
    color: "#inherit"
  },
  addScrollIfNeeded: {
    maxHeight: "300px",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "10px 0 0 0",
    "& li": {
      textAlign: "left"
    }
  }
}))

const TriggerTableEditWizard = ({
  data = [],
  sourceEntities = [],
  sourceNarrative,
  targetNarrative,
  targetEntities = [],
  selectedSources,
  destinationLibraryId,
  withAssociatedTriggers,
  missingTriggerIdsList,
  onCancel,
  onSaveTriggers
}) => {
  const [tableData, setTableData] = useState(data)
  const [modifiedTriggersById, setModifiedTriggersById] = useState({})

  const [currentStep, setCurrentStep] = useState(Steps.Edit)
  const [resetDialogOpen, setResetDialogOpen] = useState(false)

  const classes = useStyles({ currentStep })

  const buildDataById = rows => {
    const byId = {}
    rows.forEach(row => {
      byId[row.id] = row
    })
    return byId
  }

  const handleChangeData = modifiedRows => {
    const changes = buildDataById(modifiedRows)
    setModifiedTriggersById({ ...modifiedTriggersById, ...changes })
  }

  const onSeeSummary = () => {
    setCurrentStep(Steps.Summary)
  }

  const handleBackToEdit = () => {
    setCurrentStep(Steps.Edit)
  }

  const onSave = () => {
    onSaveTriggers(buildChangesList())
  }

  const resetData = () => {
    setTableData(data)
    setModifiedTriggersById({})
  }

  const buildChangeEntry = (originalItem, changedItem) => {
    const changeEntry = { itemId: originalItem.id, changes: [] }

    triggerEditableFields.forEach(({ value: field }) => {
      if (changedItem[field] !== originalItem[field]) {
        changeEntry.changes.push({
          field: field,
          oldValue: originalItem[field],
          newValue: changedItem[field]
        })
      }
    })

    return changeEntry
  }

  const buildChangesList = () => {
    const originalDataById = buildDataById(data)
    const changeList = []
    const noChangeList = []
    Object.keys(originalDataById).forEach(itemId => {
      noChangeList.push(itemId)
    })
    Object.keys(modifiedTriggersById).forEach(itemId => {
      const changeEntry = buildChangeEntry(
        originalDataById[itemId],
        modifiedTriggersById[itemId]
      )
      if (noChangeList.includes(itemId)) {
        noChangeList.splice(noChangeList.indexOf(itemId), 1)
      }
      if (changeEntry.changes?.length) {
        changeList.push(changeEntry)
      }
    })

    return { changeList, noChangeList }
  }

  const orderTriggerChangeList = (a, b) => {
    let foundA = -1
    let foundB = -1
    Object.keys(modifiedTriggersById).forEach(itemId => {
      if (Number(a.id) === Number(itemId)) {
        foundA = 1
      } else if (Number(b.id) === Number(itemId)) {
        foundB = 1
      }
    })
    return foundB - foundA
  }

  const handleCloseWarningDialog = () => {
    onCancel(false)
  }

  const processIssueList = list => (
    <div>
      <div className={classes.infoHeader}>
        The following entities DO NOT have TriggerIds set, you must run "Health
        Check" to property set them up for copying
      </div>
      <div className={classes.addScrollIfNeeded}>
        <ol>
          {list?.map((itm, i) => (
            <li key={i}>
              {itm.parentId
                ? `Paragraph:"${itm.parentId}", Sentence:"${itm.blockId}"`
                : `${itm.type}:"${itm.blockId}"`}
            </li>
          ))}
        </ol>
      </div>
    </div>
  )

  return (
    <>
      {missingTriggerIdsList?.length > 0 ? (
        <CustomDSDialog
          open={missingTriggerIdsList.length > 0}
          content={processIssueList(missingTriggerIdsList)}
          icon={<Warning style={{ fontSize: "4rem" }} />}
          backgroundColor={"#FFC107"}
          onClose={handleCloseWarningDialog}
        />
      ) : (
        <Box className={classes.root}>
          {currentStep === Steps.Edit ? (
            <>
              <SelectableFindReplaceTable
                onChangeData={handleChangeData}
                columns={columns}
                tableData={tableData}
                setTableData={setTableData}
                modifiableFields={triggerEditableFields}
                headerText={`Updating Triggers for Destination Library: ${destinationLibraryId}`}
              />
              <Box mt={1} className={classes.buttons}>
                <Button
                  color="secondary"
                  style={{ fontSize: "0.75rem" }}
                  onClick={() => setResetDialogOpen(true)}
                >
                  Reset To Original Data
                </Button>
                <Box>
                  <Button
                    style={{ marginRight: 8 }}
                    variant="contained"
                    color="secondary"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={onSeeSummary}
                  >
                    Review And Save
                  </Button>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <CustomDSDialog
                open={currentStep === Steps.Summary}
                icon={<InfoOutlined style={{ fontSize: "4rem" }} />}
                content={
                  <>
                    <ItemTriggerSummary
                      title="Summary Of Trigger Changes"
                      changeList={buildChangesList().changeList}
                      triggerList={data.sort(orderTriggerChangeList)}
                      sourceEntities={sourceEntities}
                      sourceNarrative={sourceNarrative}
                      targetNarrative={targetNarrative}
                      targetEntities={targetEntities}
                      destinationLibraryId={destinationLibraryId}
                      selectedSources={selectedSources}
                      withAssociatedTriggers={withAssociatedTriggers}
                    />
                  </>
                }
                maxWidth="800px"
                buttonProps={[
                  {
                    label: "Back",
                    color: "secondary",
                    onClick: () => {
                      handleBackToEdit()
                    }
                  },
                  {
                    label: "Save",
                    color: "primary",
                    onClick: () => {
                      onSave()
                    }
                  }
                ]}
              />
            </>
          )}
          <CustomDSDialog
            titleContent="Reset to original data"
            icon={<InfoOutlined style={{ fontSize: "4rem" }} />}
            content={`Are you sure you want to reset the trigger data? You will lose all
            your changes.`}
            open={resetDialogOpen}
            setOpen={setResetDialogOpen}
            onConfirm={resetData}
          />
        </Box>
      )}
    </>
  )
}

export default TriggerTableEditWizard
