import React from "react"
import PropTypes from "prop-types"

const ModelPreviewLink = ({
  contentId,
  contentType = "query",
  modelName = null,
  modelPreviewUrl
}) => (
  <>
    Data model:&nbsp;
    {modelName !== null ? (
      <a
        href={modelPreviewUrl}
        title="Open Narrative data model as JSON"
        target="_blank"
        rel="noopener noreferrer"
      >
        {modelName} - (Content Type: {contentType}, Content Id: {contentId})
      </a>
    ) : (
      <span>None selected.</span>
    )}
  </>
)

ModelPreviewLink.propTypes = {
  contentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  contentType: PropTypes.string,
  modelName: PropTypes.string,
  modelPreviewUrl: PropTypes.string
}

export default ModelPreviewLink
