import React from "react"
import { useObserver } from "mobx-react"
import CheckIcon from "@mui/icons-material/Check"
import { useStore } from "contexts/rootContext"
import { Form, Input, Footer, Body, Select, Button } from "components/Form"
import { account as validationSchema } from "schemas/forms"
import { globalSettings } from "variables/general.jsx"
import { fromJS } from "immutable"
import { Cookies } from "tools/storage"
import makeStyles from "@mui/styles/makeStyles"
import { get } from "tools/request"

const useStyles = makeStyles({
  formBody: {
    flexDirection: "column"
  }
})

const useStoreData = () => {
  const store = useStore()
  return useObserver(() => ({
    account: store.accountStore.account,
    organizations: store.organizationStore.organizations,
    permissionGroups: store.permissionGroupStore.permissionGroups,
    accountStatuses: store.accountStatusStore.accountStatuses,
    postAccount: store.accountStore.postAccount,
    putAccount: store.accountStore.putAccount,
    loading: store.accountStore.loading
  }))
}

const AccountForm = ({ onCancel }) => {
  let cookies = new Cookies()
  const classes = useStyles()
  const {
    account,
    organizations,
    permissionGroups,
    accountStatuses,
    postAccount,
    putAccount,
    loading
  } = useStoreData()

  const sampleScheduler = {
    filteredAccounts: [],
    lastId: cookies.get("accountFormLastAcctId") || "" //This component needs some way of keeping state so we don't re-fetch the orgs this account has access to
  }
  const [renderSchedule, setRenderSchedule] = React.useState(sampleScheduler)

  const loadOrgsForAccount = acctId => {
    if (
      acctId &&
      Number(acctId) > 0 &&
      renderSchedule.lastId !== acctId &&
      cookies.get("accountFormLastAcctId") !== acctId
    ) {
      const newSchedule = {
        ...renderSchedule,
        lastId: acctId
      }
      cookies.set("accountFormLastAcctId", acctId)
      setRenderSchedule(newSchedule)
      get(
        `${globalSettings.apiBaseUrl}/api/account/retrievepermissionsorglist?acctId=${acctId}`
      )
        .then(Response => Response.json())
        .then(orgsForAcct => {
          let orgList = []
          let allAccounts = organizations.toJS()

          allAccounts.forEach(itm =>
            orgsForAcct.organizations.forEach(item => {
              if (itm.id === item.id) {
                orgList.push(itm)
              }
            })
          )

          let temp = fromJS(orgList)
          const newSchedule = {
            ...renderSchedule,
            filteredAccounts: temp
          }
          setRenderSchedule(newSchedule)
        })
    } else {
      //New Account
      if (
        !renderSchedule.filteredAccounts ||
        renderSchedule.filteredAccounts.length === 0
      ) {
        let orgList = []
        let allOrgs = organizations.toJS()
        let acmeOrg = allOrgs.find(i => i.id === 22) //Acme Org Id: 22
        orgList.push(acmeOrg)
        let temp = fromJS(orgList)
        const newSchedule = {
          ...renderSchedule,
          filteredAccounts: temp
        }
        setRenderSchedule(newSchedule)
      }
    }
  }

  loadOrgsForAccount(account.get("id"))

  return (
    <Form
      className={classes.formStyle}
      id="account"
      name="account"
      initialValues={account.toJS()}
      onSubmit={account.get("id") ? putAccount : postAccount}
      validationSchema={validationSchema}
    >
      <Body>
        <div className={classes.formBody}>
          <Input
            type="text"
            id="firstName"
            name="firstName"
            label="First Name"
            placeholder="First Name"
            required
          />
          <Input
            type="text"
            id="lastName"
            name="lastName"
            label="Last Name"
            placeholder="Last Name"
            required
          />
        </div>
        <div className={classes.formBody}>
          <Input
            type="text"
            id="username"
            name="username"
            label="Username"
            placeholder="Username"
            required
          />
          <Select
            id="organizationId"
            name="organizationId"
            label="Organization"
            placeholder="Organization"
            margin="none"
            options={renderSchedule.filteredAccounts}
            optionValueKey="id"
            optionLabelKey="name"
            required
          />
          <Input
            type="email"
            id="email"
            name="email"
            label="Email"
            placeholder="Email"
            required
          />
        </div>
        <div className={classes.formBody}>
          <Input
            type="text"
            id="title"
            name="title"
            label="Title"
            placeholder="Title"
          />
          <Select
            id="permissionGroups"
            name="permissionGroups"
            label="Permission Groups"
            placeholder="Permission Groups"
            margin="none"
            options={permissionGroups}
            optionValueKey="id"
            optionLabelKey="name"
            defaultValue={[]}
            multiple
            required
          />
          <Input
            type="text"
            id="slackUsername"
            name="slackUsername"
            label="Slack Username"
            placeholder="Slack Username (e.g. @me)"
          />
          <Input
            type="text"
            id="smsNumber"
            name="smsNumber"
            label="SMS Number"
            placeholder="SMS Number (e.g. 206-555-1212) "
          />
        </div>
        <div>
          <Select
            id="accountStatusId"
            name="accountStatusId"
            label="Account Status"
            placeholder="Account Status"
            options={accountStatuses}
            optionValueKey="id"
            optionLabelKey="status"
            optionLabelDescriptionKey="description"
            margin="none"
            required
          />
        </div>
        <div>
          <Input
            type="text"
            id="aboutMe"
            name="aboutMe"
            label="About Me"
            placeholder="About Me"
            fullWidth
          />
        </div>
      </Body>
      <Footer>
        {onCancel && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              onCancel()
              cookies.set("accountFormLastAcctId", "")
            }}
            disabled={loading}
          >
            Cancel
          </Button>
        )}

        <Button
          variant="contained"
          color="primary"
          type="submit"
          startIcon={<CheckIcon />}
          disabled={loading}
        >
          {account.get("id") ? "Update Profile" : "Add & Invite"}
        </Button>
      </Footer>
    </Form>
  )
}

export default AccountForm
