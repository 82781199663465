import React, { Component } from "react"
import { observer, inject } from "mobx-react"
import ReactTable from "react-table-6"
import { Link } from "react-router-dom"
import withStyles from "@mui/styles/withStyles"
import ListIcon from "@mui/icons-material/List"
import Edit from "@mui/icons-material/Edit"
import Public from "@mui/icons-material/Public"
import Loop from "@mui/icons-material/Loop"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon"
import CardHeader from "components/Card/CardHeader"
import { cardTitle } from "assets/jss/material-dashboard-pro-react"
import TimeAgo from "react-timeago"
import matchSorter from "match-sorter"
import Button from "components/CustomButtons/Button"
import Tooltip from "@mui/material/Tooltip"
import WordCounter from "components/WordCounter/WordCounter"
import { globalSettings } from "variables/general"
import OpenInBrowser from "@mui/icons-material/OpenInBrowser"
import Email from "@mui/icons-material/Email"
import { withRouter } from "react-router-dom"
import DialogTitle from "@mui/material/DialogTitle"
import Dialog from "@mui/material/Dialog"
import Datetime from "react-datetime"
import DialogActions from "@mui/material/DialogActions"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { Separator } from "react-contexify"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import { Checkbox } from "@mui/material"
import InputAdornment from "@mui/material/InputAdornment"
import Helpers from "tools/Helpers.js"
import CircularProgress from "@mui/material/CircularProgress"
import { Cookies } from "tools/storage"
import AudioPlayer from "./AudioPlayer"
import { Launch, ArrowBack } from "@mui/icons-material"
import Accordion from "components/Accordion/Accordion"
import SpellingAndGrammar from "components/SpellingAndGrammar.jsx"
import { groupCollectionBy } from "tools/CollectionHelper"
import TranslateTextNow from "../TranslateTextTool/TranslateTextNow"
import FoxSportsRelevancyEngine from "components/FoxSportsRelevancyEngine"
import Enums from "tools/Enums.js"
import TextField from "@mui/material/TextField"
import { get, put } from "tools/request"

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  outputNameColumn: {
    overflow: "hidden",
    wordWrap: "break-word",
    textOverflow: "ellipsis",
    maxWidth: "225"
  }
}

const useStore = component =>
  inject(({ store }) => ({
    organizationId: store.organizationStore.organizationId,
    setOrganizationId: store.organizationStore.setOrganizationId,
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization
  }))(observer(component))

class FeedEntries extends Component {
  constructor(props) {
    super(props)
    this.props = props
    let cookies = new Cookies()
    const pageSize = props.embedded
      ? 25
      : cookies.get("feedEntriesPageSize")
      ? Number(cookies.get("feedEntriesPageSize"))
      : 25
    const totalFeedEntriesLimit = props.embedded
      ? 100
      : cookies.get("totalFeedEntriesLimit")
      ? Number(cookies.get("totalFeedEntriesLimit"))
      : 100
    this.state = {
      apiFeedLink: "",
      isLoadingFeed: false,
      isLoadingFeedEntries: false,
      feedEntries: [],
      expandedFeedEntryRows: [],
      selectedFeeEntryId: -1,
      feedId: (props.match && props.match.params.feedId) || props.feedId,
      feedTitle: props.location && props.location.search,
      narrativeId: props.narrativeId || null,
      openEmailSendDialog: false,
      email: "",
      feedEntryIdToShare: null,
      feedEntryTokenToShare: null,
      pageSize,
      feedEntryIds: [],
      newStatus: "",
      feedEntryCountLimit: totalFeedEntriesLimit,
      feedEntryTotalCount: 0,
      tableFilter: [],
      selectAll: false,
      embedded: props.embedded,
      pageIcon: props.feedIcon || <ListIcon />,
      publishedDateTime: new Date(),
      feed: null,
      copySuccessText: ""
    }
    this.summaryRef = React.createRef()
    this.contentRef = React.createRef()
    this.fetchFeed = this.fetchFeed.bind(this)
    this.fetchFeedEntries = this.fetchFeedEntries.bind(this)
  }

  componentDidMount() {
    this.props.setShowChangeCurrentOrganization(true)
    this.fetchFeed()
    this.fetchFeedEntries(this.state.feedEntryCountLimit)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.feedId && nextProps.feedId !== this.state.feedId) {
      this.setState(
        {
          feedId: nextProps.feedId
        },
        () => this.fetchFeedEntries(this.state.feedEntryCountLimit)
      )
    }
  }

  fetchFeed() {
    this.setState({ isLoadingFeed: true, feed: null })
    get(`${globalSettings.apiBaseUrl}/api/feed/retrieve/${this.state.feedId}`)
      .then(Response => Response.json())
      .then(feed => {
        this.setState({
          feed,
          isLoadingFeed: false
        })
        this.props.setOrganizationId(feed?.organization.id)
        if (feed?.organization?.apiToken) {
          this.setState({
            apiFeedLink: `${globalSettings.apiBaseUrl}/api/feed/${feed.id}?api_token=${feed.organization.apiToken}`
          })
        }
        if (
          this.props.organizationId !== feed.organization.id &&
          this.props.organizationId !== feed.organization.parentId
        ) {
          this.props.setOrganizationId(feed.organization.id)
          let cookies = new Cookies()
          cookies.set(
            "currentorg",
            feed.organization.parentId
              ? feed.organization.parentId
              : feed.organization.id,
            {
              path: "/",
              expires: Helpers.CookieExpiration.OneMonth
            }
          )
        }
      })
  }

  fetchFeedEntries(feedEntryCountLimit) {
    const { narrativeId, feedId } = this.state
    this.getFeedEntryCount()
    this.setState({ isLoadingFeedEntries: true, feedEntries: [] })
    if (!narrativeId && feedId) {
      get(
        `${globalSettings.apiBaseUrl}/api/feed/recent/${feedId}?feedentrycountlimit=${feedEntryCountLimit}`
      )
        .then(Response => Response.json())
        .then(feedEntries => {
          this.setState({
            feedEntries: feedEntries,
            isLoadingFeedEntries: false
          })
        })
    } else {
      get(
        `${globalSettings.apiBaseUrl}/api/feed/recentnarrativeoutput/${narrativeId}?feedentrycountlimit=${feedEntryCountLimit}&feedid=${feedId}`
      )
        .then(Response => Response.json())
        .then(feedEntries => {
          this.setState({
            feedEntries: feedEntries,
            isLoadingFeedEntries: false
          })
        })
    }
  }

  fetchFeedEntryInfoForRow(row) {
    this.setState({ isFeedEntryLoading: true })
    get(`${globalSettings.apiBaseUrl}/api/feedentry/${row.original.id}`)
      .then(Response => Response && Response.ok === true && Response.json())
      .then(feedEntry => {
        if (!feedEntry) {
          return
        }
        // set table row properties immediately
        row.original.featuredImage = feedEntry.featuredImage
        row.original.content = feedEntry.content
        row.original.introParagraph = feedEntry.introParagraph
        row.original.bottomHeading = feedEntry.bottomHeading
        row.original.bottomParagraph = feedEntry.bottomParagraph
        const { feedEntries } = this.state
        feedEntries[row.index] = row.original
        this.setState({
          feedEntry: feedEntry,
          isFeedEntryLoading: false,
          feedEntries: [...feedEntries]
        })
      })
  }

  getFeedEntryCount() {
    get(
      `${globalSettings.apiBaseUrl}/api/feed/feedentrycount/${this.state.feedId}`
    )
      .then(Response => Response.text())
      .then(text => {
        this.setState({
          feedEntryTotalCount: text
        })
      })
  }

  onChangeFeedEntryCountLimit = event => {
    this.setState({ feedEntryCountLimit: event.target.value })
    this.fetchFeedEntries(event.target.value)
    // Persist the last selection in a cookie.
    let cookies = new Cookies()
    cookies.set("totalFeedEntriesLimit", event.target.value, {
      path: "/",
      expires: Helpers.CookieExpiration.OneMonth
    })
  }

  renderTotalCountOptions() {
    let steppingPoints = [
      15, 25, 50, 75, 100, 150, 200, 250, 350, 400, 500, 750, 1000, 1250, 1500,
      2000, 2500, 3000, 3500, 4000, 5000, 6000, 7000, 8000, 9000, 10000
    ]
    let feedEntryCountLimitChoices = []
    steppingPoints.forEach(i => {
      if (i < this.state.feedEntryTotalCount) {
        feedEntryCountLimitChoices.push(i)
      }
    })
    feedEntryCountLimitChoices.push(this.state.feedEntryTotalCount)
    if (
      parseInt(this.state.feedEntryCountLimit) >
      parseInt(this.state.feedEntryTotalCount)
    ) {
      this.setState({
        feedEntryCountLimit: this.state.feedEntryTotalCount
      })
    }
    if (this.state.feedEntryTotalCount < 50) {
      return this.state.feedEntryTotalCount
    } else {
      return (
        <select
          onChange={this.onChangeFeedEntryCountLimit}
          style={{ width: "100" }}
          value={this.state.feedEntryCountLimit}
        >
          {feedEntryCountLimitChoices.map(o => (
            <option key={o} value={o}>
              {o}
            </option>
          ))}
        </select>
      )
    }
  }

  onShareViaEmailButtonClick = (feedEntryId, feedEntryToken) => {
    this.setState({
      openEmailSendDialog: true,
      feedEntryIdToShare: feedEntryId,
      feedEntryTokenToShare: feedEntryToken
    })
  }

  onEmailSendButtonClick = () => {
    put(
      `${globalSettings.apiBaseUrl}/api/feedentry/entry/${this.state.feedEntryIdToShare}/email`,
      null,
      {
        emailToAddress: this.state.email
      }
    )
      .then(response => response.json())
      .then(() => {
        this.setState({ email: null, openEmailSendDialog: false })
      })
  }

  didSortedDataOrPageRowsChange = state => {
    if (!state) {
      return true
    }
    const sameSortedData = Helpers.doTheseTwoArraysOfObjectsMatch(
      state.sortedData,
      this.state.sortedData,
      "id"
    )
    const samePageRows = Helpers.doTheseTwoArraysOfObjectsMatch(
      state.pageRows,
      this.state.pageRows,
      "id"
    )
    return !(sameSortedData && samePageRows)
  }

  modifiedDateTimeFilter = (filter, feedEntry) => {
    if (filter.value === "all") {
      return true
    }
    let futureTime = new Date()
    let pastTime = new Date()
    let modifiedOn = new Date(feedEntry.auditInfo.modifiedOn)
    if (filter.value === "within7days") {
      return (
        modifiedOn > pastTime.setHours(pastTime.getHours() - 24 * 7) &&
        modifiedOn < futureTime.setHours(futureTime.getHours() + 24 * 7)
      )
    }
    if (filter.value === "within24hours") {
      return (
        modifiedOn > pastTime.setHours(pastTime.getHours() - 24) &&
        modifiedOn < futureTime.setHours(futureTime.getHours() + 24)
      )
    }
    if (filter.value === "within1hour") {
      return (
        modifiedOn > pastTime.setHours(pastTime.getHours() - 1) &&
        modifiedOn < futureTime.setHours(futureTime.getHours() + 1)
      )
    }
  }

  publishedDateTimeFilter = (filter, feedEntry) => {
    if (filter.value === "all") {
      return true
    }
    let now = new Date()
    let futureTime = new Date()
    let pastTime = new Date()
    let publishedDate = new Date(feedEntry.publishedDateTime)
    if (filter.value === "future") {
      return publishedDate >= now
    }
    if (filter.value === "past") {
      return publishedDate < now
    }
    if (filter.value === "within24hours") {
      return (
        publishedDate > pastTime.setHours(pastTime.getHours() - 24) &&
        publishedDate < futureTime.setHours(futureTime.getHours() + 24)
      )
    }
    if (filter.value === "within1hour") {
      return (
        publishedDate > pastTime.setHours(pastTime.getHours() - 1) &&
        publishedDate < futureTime.setHours(futureTime.getHours() + 1)
      )
    }
  }

  bulkUpdate = () => {
    if (this.state.newStatus === "") {
      return
    }
    const { tableFilter, feedEntries, pageRows } = this.state
    let { feedEntryIds } = this.state
    let visibleRowIds = []
    pageRows.forEach(itm => {
      visibleRowIds.push(itm.id)
    })

    if (feedEntryIds.length > 0 && tableFilter.length > 0) {
      //If you have a table filter set, then we go through what the value is and
      //filter out only the feedsthat match it
      //Right now, I am only considering the narrative.id filter
      let tmpFeedEntryIds = []
      feedEntries.forEach(fe => {
        let matches = {}
        if (feedEntryIds.includes(fe.id)) {
          //Make sure the feed.id is a selected Feed Entry AND a visible Row
          tableFilter.forEach(itm => {
            if (itm.id === "auditInfo.modifiedOn") {
              matches[itm.id] = this.modifiedDateTimeFilter(itm, fe)
            } else if (itm.id === "publishedDateTime") {
              matches[itm.id] = this.publishedDateTimeFilter(itm, fe)
            } else if (itm.id === "feedEntryStatus" && itm.value === "all") {
              matches[itm.id] = true
            } else if (
              itm.id === "versionNumber" ||
              itm.id === "feedEntryStatus"
            ) {
              //version number and feedEntryStatus must be an exact match, not a contains
              matches[itm.id] = fe[itm.id].toString() === itm.value.toString()
            } else {
              let obj = Helpers.parseNestedObjectSelector(fe, itm.id) //using this helper becuase we do have composite selectors - narrative.id - that most be iterated through
              if (
                obj &&
                obj
                  .toString()
                  .toLowerCase()
                  .indexOf(itm.value.toString().toLowerCase()) !== -1
              ) {
                //Does this item match the filter?
                matches[itm.id] = true
              }
            }
          })
          let fullMatch = true //We check to make sure all the filters that are being used have a match
          tableFilter.forEach(itm => {
            fullMatch = matches[itm.id] && fullMatch
          })
          if (fullMatch) {
            //If they all match, its a winner and will be saved
            tmpFeedEntryIds.push(fe.id)
          }
        }
      })
      feedEntryIds = tmpFeedEntryIds
    }
    //If no table filters set, just save the PageRows that are visible and checked
    else if (feedEntryIds.length > 0 && pageRows.length > 0) {
      let tmpFeedEntryIds = []
      feedEntries.forEach(fe => {
        if (feedEntryIds.includes(fe.id)) {
          tmpFeedEntryIds.push(fe.id)
        }
      })
      feedEntryIds = tmpFeedEntryIds
    }

    if (feedEntryIds.length === 0) {
      return
    }
    put(`${globalSettings.apiBaseUrl}/api/feed/bulkupdate`, null, {
      status: this.state.newStatus,
      feedEntryIds: feedEntryIds,
      publishedDate: this.state.publishedDateTime
    })
      .then(response => response.json())
      .then(() => {
        this.setState(
          {
            newStatus: "",
            feedEntryIds: [],
            tableFilter: [],
            selectAll: false
          },
          () => this.fetchFeedEntries(this.state.feedEntryCountLimit)
        )
      })
  }

  onEmailChange = event => {
    this.setState({ email: event.target.value })
  }

  handleDialogClose = () => {
    this.setState({ openEmailSendDialog: false })
  }

  rememberChanges = pageSize => {
    this.setState({ pageSize })
    let cookies = new Cookies()
    cookies.set("feedEntriesPageSize", pageSize, {
      path: "/",
      expires: Helpers.CookieExpiration.OneYear
    })
  }

  checkSelectAllStatus = pageRows => {
    const { feedEntryIds } = this.state
    let selectRows = 0
    let visibleRowIds = []
    pageRows.forEach(itm => {
      visibleRowIds.push(itm.id)
    })
    feedEntryIds.forEach(id => {
      if (visibleRowIds.includes(id)) {
        selectRows += 1
      }
    })
    if (selectRows >= pageRows.length) {
      this.setState({ selectAll: true })
    } else {
      this.setState({ selectAll: false })
    }
  }

  addOrRemoveId = entryId => {
    let entryIds = this.state.feedEntryIds
    if (entryIds.includes(entryId)) {
      entryIds = entryIds.filter(value => Number(value) !== Number(entryId))
    } else {
      entryIds.push(entryId)
    }
    this.setState({ feedEntryIds: entryIds })
  }

  addOrRemoveAllId = event => {
    const { feedEntries, pageRows } = this.state
    let entryIds = []
    let visibleRowIds = []
    pageRows.forEach(itm => {
      visibleRowIds.push(itm.id)
    })
    if (event.target.checked) {
      feedEntries.forEach(feed => {
        if (visibleRowIds.includes(feed.id)) {
          entryIds.push(feed.id)
        }
      })
      this.setState({ feedEntryIds: this.state.feedEntryIds.concat(entryIds) })
    } else {
      let tmpFeedEntryIds = []
      this.state.feedEntryIds.forEach(id => {
        if (!visibleRowIds.includes(id)) {
          tmpFeedEntryIds.push(id)
        }
      })
      this.setState({ feedEntryIds: tmpFeedEntryIds })
    }
  }

  fetchAudio(rawText) {
    // response.value for fetch streams is a Uint8Array
    window.audio = new Audio()
    window.audio.src = this.buildConvertTextToAudioGETURL(
      rawText,
      "joanna",
      "neural",
      "newscaster"
    )
    window.audio.play()
  }

  buildConvertTextToAudioGETURL(rawText, voiceName, engineName, speakingStyle) {
    let url = `${
      globalSettings.apiBaseUrl
    }/api/converttexttoaudio?rawtext=${encodeURIComponent(
      rawText
    )}&voicename=${voiceName}&enginename=${engineName}&speakingstyle=${speakingStyle}`
    return url
  }

  convertStatusIdToStatusName = statusId => {
    let statusName = "N/A"
    switch (statusId) {
      case 1:
        statusName = "Draft"
        break
      case 2:
        statusName = "Review"
        break
      case 3:
        statusName = "Published"
        break
      default:
        statusName = "N/A"
        break
    }
    return statusName
  }

  stringFilterFeedNames(allResults) {
    return {
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true
        }
        // NOTE: string versus integer comparison,
        // don't use === operator
        // eslint-disable-next-line eqeqeq
        return row._original.feed.name == filter.value
      },
      Filter: ({ filter, onChange }) => {
        if (allResults.size === 0) {
          return null
        }

        const distinctOptions = Object.keys(
          groupCollectionBy(allResults, p => p.feed.name)
        ).sort((a, b) => {
          // Use toUpperCase() to ignore character casing
          const orgA = a.toUpperCase()
          const orgB = b.toUpperCase()
          let comparison = 0
          if (orgA > orgB) {
            comparison = 1
          } else if (orgA < orgB) {
            comparison = -1
          }
          return comparison
        })
        // build up the <select> element <option> element JSX style
        let options = []
        distinctOptions.forEach(opt => {
          options.push(
            <option value={opt} key={opt}>
              {opt}
            </option>
          )
        })
        return (
          <select
            onChange={event => {
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">All</option>
            {options}
          </select>
        )
      }
    }
  }

  render() {
    const { classes, embedded, isNarrativeOutput } = this.props

    const { page, pageSize, feedEntries, pageIcon, feed, narrativeId } =
      this.state

    let feedTitle = this.props.feedTitle || ""
    if (this.props.location && this.props.location.state) {
      feedTitle = this.props.location.state.feedTitle
    }

    return (
      <Card>
        <Dialog
          onClose={this.handleDialogClose}
          open={this.state.openEmailSendDialog}
          aria-labelledby="simple-dialog-title"
        >
          <DialogTitle id="simple-dialog-title">
            Send Feed Entry via Email
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <TextField
                label="Enter email"
                id="company-disabled"
                fullWidth={true}
                onChange={this.onEmailChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Email className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  )
                }}
              />
            </DialogContentText>
            <img
              src={`${globalSettings.apiBaseUrl}/api/qrcode?text=${globalSettings.apiBaseUrl}/feedentrypreview/${this.state.feedEntryTokenToShare}`}
              width={150}
              alt=""
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onEmailSendButtonClick} color="primary">
              Send
            </Button>
          </DialogActions>
        </Dialog>
        <CardHeader color="primary" icon>
          <GridContainer>
            <GridItem xs={10}>
              <CardIcon color="primary" style={{ float: "left" }}>
                {pageIcon}
              </CardIcon>
              {!isNarrativeOutput && (
                <h4 className={classes.cardIconTitle}>
                  <Link
                    to={`/portal/feed-manager`}
                    title="Back to feed manager"
                  >
                    <strong>
                      <ArrowBack />
                      {(feed && feed.name) || feedTitle}
                    </strong>
                  </Link>{" "}
                  Feed Entries&nbsp;
                  {this.state.apiFeedLink && (
                    <span>
                      <a href={this.state.apiFeedLink} target="_new">
                        <Tooltip
                          title={`View "${this.state.apiFeedLink}" feed`}
                          placement="top"
                        >
                          <Button
                            justIcon
                            round
                            simple
                            color="primary"
                            className="info"
                          >
                            <i className="fas fa-rss" />
                          </Button>
                        </Tooltip>
                      </a>
                    </span>
                  )}
                </h4>
              )}
              {isNarrativeOutput && (
                <h4 className={classes.cardIconTitle}>
                  <Link
                    title={`Back to Narrative Dashboard`}
                    onClick={e => {
                      this.props.history.goBack()
                      e.preventDefault()
                    }}
                  >
                    <strong>
                      <ArrowBack />
                      Back to Narrative Dashboard
                    </strong>
                  </Link>{" "}
                </h4>
              )}
            </GridItem>
            <GridItem xs={2} style={{ textAlign: "right" }}>
              {embedded && (
                <Link
                  to={`/portal/feed-entries/${this.state.feedId}`}
                  title="View feed entries"
                >
                  <Button
                    justIcon
                    round
                    simple
                    color="primary"
                    className="info"
                  >
                    <Launch />
                  </Button>
                </Link>
              )}
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          <ReactTable
            data={feedEntries}
            filtered={this.state.tableFilter}
            onFilteredChange={filtered =>
              this.setState({ tableFilter: filtered })
            }
            loading={this.state.isLoadingFeedEntries}
            noDataText={
              this.state.isLoadingFeedEntries ? (
                <CircularProgress />
              ) : (
                "No feed entries found."
              )
            }
            expanded={this.state.expandedFeedEntryRows}
            getTdProps={(state, row, col) => ({
              onClick: (event, handleOriginal) => {
                // This is the only way to identify
                // the specific row's primary id when the "expander" icon
                // is clicked
                if (col.expander) {
                  if (!row.original.content) {
                    this.fetchFeedEntryInfoForRow(row)
                  }
                  if (this.state.selectedFeeEntryId === row.original.id) {
                    // when the expander is "collapsed", unselected it
                    this.setState({ selectedFeeEntryId: -1 })
                    this.setState({
                      expandedFeedEntryRows: { [row.viewIndex]: false }
                    })
                  } else {
                    // when the expander is "expanded", means that it is selected
                    this.setState({ selectedFeeEntryId: row.original.id })
                    this.setState({
                      expandedFeedEntryRows: { [row.viewIndex]: true }
                    })
                  }
                }
                handleOriginal && handleOriginal()
              }
            })}
            SubComponent={row => (
              <React.Fragment>
                <GridContainer>
                  <GridItem md={1}>
                    <h6>Version</h6>
                    <div>{row.original.versionNumber}</div>
                  </GridItem>
                  <GridItem md={1}>
                    <h6>Token</h6>
                    <div>{row.original.feedEntryToken}</div>
                  </GridItem>
                  <GridItem md={3}>
                    <h6>Entry Id</h6>
                    <div>{row.original.entryId || row.original.id}</div>
                  </GridItem>
                  <GridItem md={1}>
                    <h6>Narrative Id</h6>
                    <div>{row.original.narrative?.id}</div>
                  </GridItem>
                  <GridItem md={1}>
                    <h6>Content Type</h6>
                    <div>
                      {Helpers.convertContentTypeIdToName(
                        row.original.contentTypeId
                      )}
                    </div>
                  </GridItem>
                  <GridItem md={1}>
                    <h6>Content Id</h6>
                    <div>{row.original.contentId}</div>
                  </GridItem>
                  <GridItem md={1}>
                    <h6>Secondary Content Type</h6>
                    <div>
                      {Helpers.convertContentTypeIdToName(
                        row.original.secondaryContentTypeId
                      )}
                    </div>
                  </GridItem>
                  <GridItem md={1}>
                    <h6>Secondary Content Id</h6>
                    <div>{row.original.secondaryContentId}</div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  {row.original.featuredImage && (
                    <GridItem md={10}>
                      <h6>Featured Image</h6>
                      <div>
                        <img
                          alt="Featured"
                          src={row.original.featuredImage.url}
                          width="500"
                          height="auto"
                        />
                      </div>
                      <div>
                        <strong>Url:</strong>{" "}
                        <em>{row.original.featuredImage.url}</em>
                      </div>
                      {row.original.featuredImage.dynamicGraphicUrl && (
                        <>
                          <div>
                            <strong>Dynamic Graphic Url:</strong>{" "}
                            <em>
                              {row.original.featuredImage.dynamicGraphicUrl}
                            </em>
                          </div>
                          <div>
                            <strong>Image Part Failures:</strong>{" "}
                            <em>
                              {row.original.featuredImage.imagePartFailures}
                            </em>
                          </div>
                        </>
                      )}
                      <div>
                        <strong>Title/Headline:</strong>{" "}
                        <em>{row.original.featuredImage.title}</em>
                      </div>
                      <div>
                        <strong>Caption:</strong>{" "}
                        <em>{row.original.featuredImage.caption}</em>
                      </div>
                      <div>
                        <strong>Photographer</strong>:{" "}
                        <em>{row.original.featuredImage.photographerName}</em>
                      </div>
                      <div>
                        <strong>Copyright:</strong>{" "}
                        <em>{row.original.featuredImage.copyright}</em>
                      </div>
                      <div>
                        <strong>Dimensions:</strong>{" "}
                        <em>
                          {row.original.featuredImage.width}
                          {row.original.featuredImage.height && (
                            <span>x {row.original.featuredImage.height}</span>
                          )}
                        </em>{" "}
                      </div>
                    </GridItem>
                  )}
                  {row.original.summary && (
                    <React.Fragment>
                      <GridItem xs={10}>
                        <GridContainer>
                          <WordCounter
                            title="Summary"
                            inputString={row.original.summary}
                          />
                          <Tooltip title="Play Audio" placement="top">
                            <Button
                              justIcon
                              round
                              simple
                              color="primary"
                              className="info"
                              style={{ padding: "0px", height: "6px" }}
                            >
                              <AudioPlayer
                                rawText={Helpers.stripHtml(
                                  row.original.summary
                                )}
                              />
                            </Button>
                          </Tooltip>
                        </GridContainer>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              row.original.summary &&
                              row.original.summary.replace(/apxh:/g, "")
                          }}
                          ref={this.summaryRef}
                        />
                      </GridItem>
                      <GridItem xs={10}>
                        <Accordion
                          active={null}
                          collapses={[
                            {
                              title: "Summary (raw)",
                              content: (
                                <TextField
                                  fullWidth={true}
                                  multiline={true}
                                  rows={5}
                                  value={row.original.summary}
                                />
                              )
                            },
                            {
                              title: "Summary (spelling & grammar)",
                              content: (
                                <SpellingAndGrammar
                                  text={Helpers.stripHtml(row.original.summary)}
                                />
                              )
                            },
                            {
                              title: "Summary (translation)",
                              content: (
                                <TranslateTextNow
                                  text={Helpers.stripHtml(row.original.summary)}
                                />
                              )
                            }
                          ]}
                        />
                      </GridItem>
                    </React.Fragment>
                  )}
                  {row.original.introParagraph && (
                    <React.Fragment>
                      <GridItem xs={10}>
                        <div>
                          <WordCounter
                            title="Intro Paragraph"
                            inputString={row.original.introParagraph}
                          />
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              row.original.introParagraph &&
                              row.original.introParagraph.replace(/apxh:/g, "")
                          }}
                          ref={this.introRef}
                        />
                      </GridItem>
                      <GridItem xs={10}>
                        <Accordion
                          active={null}
                          collapses={[
                            {
                              title: "Intro Paragraph (raw)",
                              content: (
                                <TextField
                                  fullWidth={true}
                                  multiline={true}
                                  rows={5}
                                  value={row.original.introParagraph}
                                />
                              )
                            },
                            {
                              title: "Intro Paragraph (spelling & grammar)",
                              content: (
                                <SpellingAndGrammar
                                  text={Helpers.stripHtml(
                                    row.original.introParagraph
                                  )}
                                />
                              )
                            },
                            {
                              title: "Intro Paragraph (translation)",
                              content: (
                                <TranslateTextNow
                                  text={row.original.introParagraph}
                                />
                              )
                            }
                          ]}
                        />
                      </GridItem>
                    </React.Fragment>
                  )}
                </GridContainer>
                {row.original.content && (
                  <GridContainer>
                    <GridItem xs={10}>
                      <div>
                        <WordCounter
                          title="Content"
                          inputString={row.original.content}
                        />
                      </div>
                      <div
                        style={{ position: "relative" }}
                        dangerouslySetInnerHTML={{
                          __html:
                            row.original.content &&
                            row.original.content.replace(/apxh:/g, "")
                        }}
                        ref={this.contentRef}
                      />
                    </GridItem>
                    <GridItem xs={10}>
                      <Accordion
                        active={null}
                        collapses={[
                          {
                            title: "Content (raw)",
                            content: (
                              <TextField
                                fullWidth={true}
                                multiline={true}
                                rows={10}
                                value={row.original.content}
                              />
                            )
                          },
                          {
                            title: "Content (spelling & grammar)",
                            content:
                              (!this.state.isFeedEntryLoading && (
                                <SpellingAndGrammar
                                  text={Helpers.stripHtml(row.original.content)}
                                />
                              )) ||
                              ""
                          },
                          {
                            title: "Content (translation)",
                            content:
                              (!this.state.isFeedEntryLoading && (
                                <TranslateTextNow text={row.original.content} />
                              )) ||
                              ""
                          },
                          {
                            title: "Content (FoxSports Linking)",
                            content:
                              (!this.state.isFeedEntryLoading &&
                                (this.props.organizationId ===
                                  Enums.Organizations.FoxSports ||
                                  this.props.organizationId ===
                                    Enums.Organizations.FoxSportsCom) && (
                                  <FoxSportsRelevancyEngine
                                    content={row.original.content}
                                  />
                                )) ||
                              "Not applicable."
                          }
                        ]}
                      />
                    </GridItem>
                  </GridContainer>
                )}
                {row.original.bottomHeading && (
                  <React.Fragment>
                    <GridContainer>
                      <GridItem xs={10}>
                        <div>
                          <WordCounter
                            title="Bottom Heading"
                            inputString={row.original.bottomHeading}
                          />
                        </div>
                        <h1>{row.original.bottomHeading}</h1>
                      </GridItem>
                      <GridItem xs={10}>
                        <Accordion
                          active={null}
                          collapses={[
                            {
                              title: "Bottom Heading (raw)",
                              content: (
                                <TextField
                                  fullWidth={true}
                                  multiline={true}
                                  rows={5}
                                  value={row.original.bottomHeading}
                                />
                              )
                            },
                            {
                              title: "Bottom Heading (spelling & grammar)",
                              content: (
                                <SpellingAndGrammar
                                  text={Helpers.stripHtml(
                                    row.original.bottomHeading
                                  )}
                                />
                              )
                            },
                            {
                              title: "Bottom Heading (translation)",
                              content:
                                (!this.state.isFeedEntryLoading && (
                                  <TranslateTextNow
                                    text={row.original.bottomHeading}
                                  />
                                )) ||
                                ""
                            }
                          ]}
                        />
                      </GridItem>
                    </GridContainer>
                  </React.Fragment>
                )}
                {row.original.bottomParagraph && (
                  <React.Fragment>
                    <GridContainer>
                      <GridItem xs={10}>
                        <div>
                          <WordCounter
                            title="Bottom Paragraph"
                            inputString={row.original.bottomParagraph}
                          />
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              row.original.bottomParagraph &&
                              row.original.bottomParagraph.replace(/apxh:/g, "")
                          }}
                          ref={this.bottomRef}
                        />
                      </GridItem>
                      <GridItem xs={10}>
                        <Accordion
                          active={null}
                          collapses={[
                            {
                              title: "Bottom Paragraph (raw)",
                              content: (
                                <TextField
                                  fullWidth={true}
                                  multiline={true}
                                  rows={5}
                                  value={row.original.bottomParagraph}
                                />
                              )
                            },
                            {
                              title: "Bottom Paragraph (spelling & grammar)",
                              content: (
                                <SpellingAndGrammar
                                  text={Helpers.stripHtml(
                                    row.original.bottomParagraph
                                  )}
                                />
                              )
                            },
                            {
                              title: "Bottom Paragraph (translation)",
                              content:
                                (!this.state.isFeedEntryLoading && (
                                  <TranslateTextNow
                                    text={row.original.bottomParagraph}
                                  />
                                )) ||
                                ""
                            }
                          ]}
                        >
                          {" "}
                        </Accordion>
                      </GridItem>
                    </GridContainer>
                  </React.Fragment>
                )}
                <GridContainer>
                  <GridItem xs={10}>
                    <h6 style={{ paddingTop: "10px" }}>ACTIONS</h6>
                    <Link to={`/portal/feed-entries/entry/${row.original.id}`}>
                      <Tooltip title="Edit" placement="top">
                        <Button
                          justIcon
                          round
                          simple
                          color="primary"
                          className="info"
                        >
                          <Edit />
                        </Button>
                      </Tooltip>
                    </Link>
                    <Link
                      to={{
                        pathname: `/portal/narrative/${row.original.narrative?.id}?feedentryid=${row.original?.id}`,
                        state: {
                          feedTitle: `${feedTitle}`
                        }
                      }}
                      title="View Narrative Outline"
                    >
                      <Button
                        justIcon
                        round
                        simple
                        color="primary"
                        className="info"
                      >
                        <ListIcon />
                      </Button>
                    </Link>
                    <Link
                      to={`/feedentrypreview/${row.original.feedEntryToken}`}
                    >
                      <Tooltip title="Full page preview" placement="top">
                        <Button
                          justIcon
                          round
                          simple
                          color="primary"
                          className="info"
                        >
                          <OpenInBrowser />
                        </Button>
                      </Tooltip>
                    </Link>
                    <a
                      href={`https://www.google.com/search?q="${encodeURIComponent(
                        row.original.title
                      )}"`}
                      target="_new"
                    >
                      <Tooltip title="Find on web" placement="top">
                        <Button
                          justIcon
                          round
                          simple
                          color="primary"
                          className="info"
                        >
                          <Public />
                        </Button>
                      </Tooltip>
                    </a>
                    <Tooltip title="Share via Email" placement="top">
                      <Button
                        justIcon
                        round
                        simple
                        color="primary"
                        className="info"
                        onClick={() =>
                          this.onShareViaEmailButtonClick(
                            row.original.id,
                            row.original.feedEntryToken
                          )
                        }
                      >
                        <Email />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Play Audio" placement="top">
                      <Button
                        justIcon
                        round
                        simple
                        color="primary"
                        className="info"
                      >
                        <AudioPlayer
                          rawText={`The title reads: ${Helpers.stripHtml(
                            row.original.title
                          )}, the body reads as: ${Helpers.stripHtml(
                            row.original.content
                          )}`}
                        />
                      </Button>
                    </Tooltip>
                  </GridItem>
                </GridContainer>
              </React.Fragment>
            )}
            columns={[
              {
                id: "entryCheck",
                Cell: ({ row }) => (
                  <input
                    type="checkbox"
                    className="checkbox"
                    ___defaultChecked={this.state.feedEntryIds.includes(
                      row._original.id
                    )}
                    checked={this.state.feedEntryIds.includes(row._original.id)}
                    onClick={() => this.addOrRemoveId(row._original.id, true)}
                  />
                ),
                Header: state => (
                  <input
                    type="checkbox"
                    onChange={e => {
                      this.addOrRemoveAllId(e)
                      let selectAll = !this.state.selectAll
                      this.setState({ selectAll: selectAll })
                    }}
                    checked={this.state.selectAll}
                  />
                ),
                sortable: false,
                filterable: false,
                width: 40
              },
              {
                Header: "Id",
                accessor: "id",
                Cell: cell => (
                  <Link
                    to={{
                      pathname: `/portal/feed-entries/entry/${cell.value}`,
                      state: {
                        feedTitle: `${feedTitle}`
                      }
                    }}
                  >
                    {cell.value}
                  </Link>
                ),
                width: 80
              },
              {
                Header: "Actions",
                Cell: cell => (
                  <div className="actions-left">
                    <Link
                      to={`/portal/feed-entries/entry/${cell.original.id}`}
                      title="Edit"
                    >
                      <Button
                        justIcon
                        round
                        simple
                        color="primary"
                        className="info"
                      >
                        <Edit />
                      </Button>
                    </Link>
                    <Link
                      to={`/feedentrypreview/${cell.original.feedEntryToken}`}
                      title="Full page preview"
                    >
                      <Button
                        justIcon
                        round
                        simple
                        color="primary"
                        className="info"
                      >
                        <OpenInBrowser />
                      </Button>
                    </Link>
                    <a
                      href={`https://www.google.com/search?q="${encodeURIComponent(
                        cell.original.title
                      )}"`}
                      target="_new"
                      title="Find on web"
                    >
                      <Button
                        justIcon
                        round
                        simple
                        color="primary"
                        className="info"
                      >
                        <Public />
                      </Button>
                    </a>
                  </div>
                ),
                sortable: false,
                filterable: false,
                width: 185
              },
              {
                Header: "Feed",
                accessor: "feed.name",
                ...this.stringFilterFeedNames(feedEntries),
                show: narrativeId
              },
              {
                Header: "Status",
                accessor: "feedEntryStatus",
                Cell: cell => (
                  <div>
                    {this.convertStatusIdToStatusName(
                      cell.original.feedEntryStatus
                    )}
                  </div>
                ),
                width: 125,
                sortMethod: (a, b) =>
                  this.convertStatusIdToStatusName(a) >
                  this.convertStatusIdToStatusName(b)
                    ? 1
                    : -1,
                filterMethod: (filter, row) => {
                  if (filter.value === "all") {
                    return true
                  }
                  return row.feedEntryStatus === parseInt(filter.value)
                },
                Filter: ({ filter, onChange }) => {
                  if (feedEntries.size === 0) {
                    return null
                  }

                  const distinctStatuses = []
                  const map = new Map()
                  for (let entry of feedEntries) {
                    if (!map.has(entry.feedEntryStatus)) {
                      map.set(entry.feedEntryStatus, true)
                      distinctStatuses.push({
                        id: entry.feedEntryStatus,
                        name: this.convertStatusIdToStatusName(
                          entry.feedEntryStatus
                        )
                      })
                    }
                  }
                  // inline sort by status name
                  distinctStatuses.sort((a, b) => {
                    // Use toUpperCase() to ignore character casing
                    const A = a.name.toUpperCase()
                    const B = b.name.toUpperCase()
                    let comparison = 0
                    if (A > B) {
                      comparison = 1
                    } else if (A < B) {
                      comparison = -1
                    }
                    return comparison
                  })
                  // build up the <select> element <option> element JSX style
                  let options = []
                  distinctStatuses.forEach(status => {
                    if (status.id !== null) {
                      options.push(
                        <option value={status.id} key={status.id}>
                          {status.name}
                        </option>
                      )
                    }
                  })
                  return (
                    <select
                      onChange={event => {
                        onChange(event.target.value)
                      }}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="all">Show All</option>
                      {options}
                    </select>
                  )
                }
              },
              {
                Header: "Title",
                accessor: "title",
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, {
                    keys: ["title"],
                    threshold: matchSorter.rankings.CONTAINS
                  }),
                filterAll: true,
                width: 350
              },
              {
                Header: "Version",
                accessor: "versionNumber",
                width: 90
              },
              {
                Header: "Published",
                accessor: "publishedDateTime",
                Cell: row => (
                  <TimeAgo
                    date={row.original.publishedDateTime}
                    title={Helpers.prettyDateTimeinPacificTimeZone(
                      row.original.publishedDateTime
                    )}
                  />
                ),
                width: 175,
                filterMethod: (filter, row) => {
                  if (filter.value === "all") {
                    return true
                  }
                  let now = new Date()
                  let futureTime = new Date()
                  let pastTime = new Date()
                  let publishedDate = new Date(row.publishedDateTime)
                  if (filter.value === "future") {
                    return publishedDate >= now
                  }
                  if (filter.value === "past") {
                    return publishedDate < now
                  }
                  if (filter.value === "within24hours") {
                    return (
                      publishedDate >
                        pastTime.setHours(pastTime.getHours() - 24) &&
                      publishedDate <
                        futureTime.setHours(futureTime.getHours() + 24)
                    )
                  }
                  if (filter.value === "within1hour") {
                    return (
                      publishedDate >
                        pastTime.setHours(pastTime.getHours() - 1) &&
                      publishedDate <
                        futureTime.setHours(futureTime.getHours() + 1)
                    )
                  }
                },
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                  >
                    <option value="all">Show All</option>
                    <option value="future">Future</option>
                    <option value="past">Past</option>
                    <option value="within24hours">Within 24 Hours</option>
                    <option value="within1hour">Within 1 Hour</option>
                  </select>
                )
              },
              {
                Header: "Updated",
                accessor: "updatedDateTime",
                Cell: row => (
                  <TimeAgo
                    date={row.original.updatedDateTime}
                    title={Helpers.prettyDateTimeinPacificTimeZone(
                      row.original.updatedDateTime
                    )}
                  />
                ),
                width: 175,
                filterMethod: (filter, row) => {
                  if (filter.value === "all") {
                    return true
                  }
                  let now = new Date()
                  let futureTime = new Date()
                  let pastTime = new Date()
                  let updatedDate = new Date(row.updatedDateTime)
                  if (filter.value === "future") {
                    return updatedDate >= now
                  }
                  if (filter.value === "past") {
                    return updatedDate < now
                  }
                  if (filter.value === "within24hours") {
                    return (
                      updatedDate >
                        pastTime.setHours(pastTime.getHours() - 24) &&
                      updatedDate <
                        futureTime.setHours(futureTime.getHours() + 24)
                    )
                  }
                  if (filter.value === "within1hour") {
                    return (
                      updatedDate >
                        pastTime.setHours(pastTime.getHours() - 1) &&
                      updatedDate <
                        futureTime.setHours(futureTime.getHours() + 1)
                    )
                  }
                },
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                  >
                    <option value="all">Show All</option>
                    <option value="future">Future</option>
                    <option value="past">Past</option>
                    <option value="within24hours">Within 24 Hours</option>
                    <option value="within1hour">Within 1 Hour</option>
                  </select>
                )
              },
              {
                Header: "Output Id",
                accessor: "narrative.id",
                Cell: cell => (
                  <Link
                    to={{
                      pathname: `/portal/narrative/${cell.value}`,
                      state: {
                        feedTitle: `${feedTitle}`
                      }
                    }}
                  >
                    {cell.value}
                  </Link>
                ),
                width: 120,
                show: !narrativeId
              },
              {
                Header: "Output Name",
                accessor: "narrative.name",
                Cell: cell => {
                  const cellValue = cell.value
                  if (cellValue === "{}") {
                    return "None"
                  }
                  return (
                    <div
                      className={classes.outputNameColumn}
                      aria-label="feedentries-outputnamecolumn-outputname"
                    >
                      {cell.value}
                    </div>
                  )
                },
                Filter: ({ filter, onChange }) => (
                  <input
                    type="text"
                    value={filter ? filter.value : ""}
                    onChange={e => onChange(e.target.value)}
                  />
                ),
                filterMethod: (filter, row) => {
                  const cellValue = String(row["narrative.name"]).toLowerCase()
                  return cellValue.includes(filter.value.toLowerCase())
                },
                filterable: true,
                width: 250
              },
              {
                id: "hidden",
                Header: "Hidden",
                accessor: "hideFromFeed",
                width: 80,
                Cell: ({ row }) => (
                  <Checkbox
                    style={{ paddingLeft: "22px" }}
                    type="checkbox"
                    className="checkbox"
                    checked={row._original.hideFromFeed}
                    disabled
                  />
                )
              },
              {
                Header: "Audit Info",
                accessor: "auditInfo.modifiedOn",
                Cell: cell => Helpers.renderAuditInfoCell(cell),
                width: 250,
                filterMethod: (filter, row) => {
                  if (filter.value === "all") {
                    return true
                  }
                  let futureTime = new Date()
                  let pastTime = new Date()
                  let modifiedOn = new Date(row._original.auditInfo.modifiedOn)
                  if (filter.value === "within7days") {
                    return (
                      modifiedOn >
                        pastTime.setHours(pastTime.getHours() - 24 * 7) &&
                      modifiedOn <
                        futureTime.setHours(futureTime.getHours() + 24 * 7)
                    )
                  }
                  if (filter.value === "within24hours") {
                    return (
                      modifiedOn >
                        pastTime.setHours(pastTime.getHours() - 24) &&
                      modifiedOn <
                        futureTime.setHours(futureTime.getHours() + 24)
                    )
                  }
                  if (filter.value === "within1hour") {
                    return (
                      modifiedOn > pastTime.setHours(pastTime.getHours() - 1) &&
                      modifiedOn <
                        futureTime.setHours(futureTime.getHours() + 1)
                    )
                  }
                },
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                  >
                    <option value="all">Show All</option>
                    <option value="within7days">Within 7 days</option>
                    <option value="within24hours">Within 24 Hours</option>
                    <option value="within1hour">Within 1 Hour</option>
                  </select>
                )
              }
            ]}
            filterable={true}
            page={page}
            defaultPageSize={pageSize}
            minRows={0}
            showPaginationTop={!embedded}
            showPaginationBottom={true}
            className="-striped -highlight"
            onPageChange={page => this.rememberChanges(pageSize, page)}
            onPageSizeChange={(pageSize, page) => {
              this.rememberChanges(pageSize, page)
            }}
            defaultSorted={[
              {
                id: "publishedDateTime",
                desc: true
              }
            ]}
            getTrProps={(state, rowInfo) => {
              if (rowInfo) {
                let returnObject = { style: {} }
                switch (rowInfo.original.feedEntryStatus) {
                  case 1: // Draft
                    returnObject = {
                      style: {
                        background: "#ccc"
                      }
                    }
                    break
                  case 2: // Review
                    returnObject = {
                      style: {
                        background: "#ffd900"
                      }
                    }
                    break
                  default:
                    returnObject = { style: {} }
                    break
                }
                if (rowInfo.original.isArchived) {
                  returnObject.style.textDecoration = "line-through"
                }
                if (new Date(rowInfo.original.publishedDateTime) > new Date()) {
                  returnObject.style.fontStyle = "italic"
                }
                return returnObject
              } else {
                return {}
              }
            }}
          >
            {(state, makeTable) => {
              const { filtered, pageRows, pageSize, sortedData, page } = state
              let isFiltered = false
              let recordsCountFrom = 0
              let recordsCountTo = 0
              let totalRecords = 0
              isFiltered = filtered.length > 0
              totalRecords = sortedData.length
              recordsCountFrom = page * pageSize + 1
              recordsCountTo = recordsCountFrom + pageRows.length - 1
              if (this.didSortedDataOrPageRowsChange(state)) {
                //If we have had a change to the sortedData or pageRows, save these changes to this.state here
                //These will be used ot make sure that, if we use "Select All" and Apply, we will only select
                //visible rows, not seleced rows that are not in the current view
                this.setState(
                  { sortedData: sortedData, pageRows: pageRows },
                  this.checkSelectAllStatus(pageRows)
                )
              }

              return (
                <div>
                  <GridContainer container>
                    <GridItem xs={3}>
                      <FormControl fullWidth style={{ marginTop: "-10px" }}>
                        <InputLabel>Status/Flags</InputLabel>
                        <Select
                          fullWidth={false}
                          type="select"
                          name="status_Id"
                          id="status_Id"
                          value={this.state.newStatus || ""}
                          onChange={event =>
                            this.setState({ newStatus: event.target.value })
                          }
                        >
                          <MenuItem value="">Select</MenuItem>
                          <MenuItem value="publish">Publish Now</MenuItem>
                          <MenuItem value="draft">Mark as Draft</MenuItem>
                          <MenuItem value="review">Set for Review</MenuItem>
                          <Separator />
                          <MenuItem value="archive">Archive</MenuItem>
                          <MenuItem value="hide">Hide From Feed</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={3}>
                      <FormControl fullWidth>
                        <Datetime
                          value={this.state.publishedDateTime}
                          dateFormat={"M/D/YYYY"}
                          timeFormat={"h:mm A (Z zz)"}
                          inputProps={{
                            placeholder: "Choose a start date/time",
                            style: { font: "inherit", color: "black" },
                            readOnly: true
                          }}
                          onChange={e =>
                            this.setState({
                              publishedDateTime: new Date(
                                e ? new Date(e) : new Date()
                              )
                            })
                          }
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={2}>
                      <FormControl>
                        <Button
                          size="sm"
                          variant="contained"
                          onClick={() => {
                            this.bulkUpdate()
                          }}
                          color="primary"
                        >
                          Apply
                        </Button>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={4} className="text-right">
                      <FormControl>
                        <span className="records-info">
                          {totalRecords > 0 && isFiltered && (
                            <span>
                              {recordsCountFrom}-{recordsCountTo} of{" "}
                              {totalRecords} filtered out of{" "}
                              {this.renderTotalCountOptions()} total feed
                              entries
                            </span>
                          )}
                          {totalRecords > 0 && !isFiltered && (
                            <span>
                              {recordsCountFrom}-{recordsCountTo} of{" "}
                              {this.renderTotalCountOptions()} total feed
                              entries
                            </span>
                          )}
                          {totalRecords === 0 &&
                            (this.state.isLoadingFeedEntries
                              ? "Loading feed entries, please wait..."
                              : "No feed entries.")}
                          <Tooltip title="Reload" placement="top" arrow={true}>
                            <Button
                              justIcon
                              round
                              simple
                              color="primary"
                              className="info"
                              id="reload"
                              onClick={() => {
                                this.fetchFeedEntries(
                                  this.state.feedEntryCountLimit
                                )
                              }}
                            >
                              {this.state.isLoadingFeedEntries ? (
                                <CircularProgress thickness={1} size={30} />
                              ) : (
                                <Loop />
                              )}
                            </Button>
                          </Tooltip>
                        </span>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  {makeTable()}
                </div>
              )
            }}
          </ReactTable>
          <div xs={3} sm={3} md={3}>
            <div style={{ fontStyle: "italic" }}>
              italic = future publish date/time
            </div>
            <div style={{ backgroundColor: "#ffd900" }}>
              yellow = `Review` status
            </div>
            <div style={{ backgroundColor: "#ccc" }}>gray = `Draft` status</div>
            <div style={{ textDecoration: "line-through" }}>
              line-through = Archived
            </div>
          </div>
        </CardBody>
      </Card>
    )
  }
}

export default withStyles(styles)(withRouter(useStore(FeedEntries)))
