import { globalSettings } from "../variables/general"
import { get, put } from "../tools/request"

export default class AccountDAO {
  static getAccounts = async () => {
    const res = await get(`${globalSettings.apiBaseUrl}/api/account`)
    return await res.json()
  }

  static getActiveAccounts = async () => {
    const res = await get(`${globalSettings.apiBaseUrl}/api/account/active`)
    return await res.json()
  }

  static getActiveAccountsByOrganization = async orgId => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/account/retrieveaccountsbyorg?orgid=${orgId}`
    )
    return await res.json()
  }

  static getAccount = async id => {
    const res = await get(`${globalSettings.apiBaseUrl}/api/account/${id}`, {
      doSkipCache: true
    })
    return await res.json()
  }

  static putAccount = async (id, account) => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/account/${id}`,
      null,
      account
    )
    return await res.json()
  }

  static postAccount = async account => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/account`,
      null,
      account
    )
    return await res.json()
  }

  /**
   * Get permissions for the current account
   */
  static getPermissionGroups = async () => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/account/retrievePermissionGroups`
    )
    return await res.json()
  }

  static getPermissions = async () => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/account/retrievepermissions`
    )
    return await res.json()
  }

  /**
   * Retrieve login attempt history for the current account
   */
  static getLoginAttemptHistory = async () => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/account/retrievelatestloginattempts`
    )
    return await res.json()
  }

  static wsTokenCheck = async () => {
    const tokenActive = get(
      `${globalSettings.apiBaseUrl}/api/ws/portalhub/validatetoken`
    )
    return await tokenActive
  }
}
