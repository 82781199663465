module.exports = {
  userAudiInfo: function (userAccount, auditInfo, accountOwner) {
    if (auditInfo && auditInfo.modifier) {
      auditInfo.modifier.id = userAccount.id
    } else {
      auditInfo = {
        modifier: { id: userAccount.id },
        creator: { id: userAccount.id },
        owner: { id: accountOwner ? accountOwner : userAccount.id }
      }
    }
    return auditInfo
  }
}
