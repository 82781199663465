import React, { Fragment } from "react"
import ReactTable from "react-table-6"
import { useObserver } from "mobx-react"
import CheckIcon from "@mui/icons-material/Check"
import classnames from "classnames"
import matchSorter from "match-sorter"
import FormattedDate from "components/FormattedDate"
import CircularProgress from "@mui/material/CircularProgress"
import { useStore } from "contexts/rootContext"
import {
  Form,
  Input,
  Footer,
  Body,
  CheckboxGroup,
  Button
} from "components/Form"
import { permissionGroup as validationSchema } from "schemas/forms"

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    loading: store.permissionGroupStore.loading,
    loadingPermissionGroup: store.permissionGroupStore.loadingPermissionGroup,
    permissionGroup: store.permissionGroupStore.permissionGroup,
    permissions: store.permissionGroupStore.permissions,
    putPermissionGroup: store.permissionGroupStore.putPermissionGroup,
    postPermissionGroup: store.permissionGroupStore.postPermissionGroup,
    closeModal: store.permissionGroupStore.closeModal,
    isPermissionGroupSelected:
      store.permissionGroupStore.isPermissionGroupSelected
  }))
}

const PermissionGroupForm = () => {
  const {
    loading,
    loadingPermissionGroup,
    permissionGroup,
    permissions,
    postPermissionGroup,
    putPermissionGroup,
    closeModal,
    isPermissionGroupSelected
  } = useStoreData()

  const stringFilterProps = {
    filterable: true,
    filterAll: true,
    filterMethod: ({ id, value }, rows) =>
      matchSorter(rows, value, {
        keys: [id],
        threshold: matchSorter.rankings.CONTAINS
      })
  }

  if (loadingPermissionGroup) {
    return <Fragment />
  }

  return (
    <Form
      id="permissionGroup"
      name="permissionGroup"
      initialValues={permissionGroup.toJS()}
      onSubmit={
        isPermissionGroupSelected ? putPermissionGroup : postPermissionGroup
      }
      validationSchema={validationSchema}
    >
      <Body>
        <div>
          <Input
            type="text"
            id="name"
            name="name"
            label="Permission Group Name"
            placeholder="Permission Group Name"
            required
          />
          <Input
            type="text"
            id="description"
            name="description"
            label="Permission Group Description"
            placeholder="Permission Group Description"
            required
          />
        </div>
        <div>
          <CheckboxGroup
            id="permissions"
            name="permissions"
            label="Assign Group Permissions"
            options={permissions}
            optionValueKey="id"
            optionLabelKey="name"
          />
        </div>
      </Body>
      <Footer>
        {
          <Button
            variant="outlined"
            color="primary"
            onClick={closeModal}
            disabled={loading}
          >
            Cancel
          </Button>
        }

        <Button
          variant="contained"
          color="primary"
          type="submit"
          startIcon={<CheckIcon />}
          disabled={loading}
        >
          {isPermissionGroupSelected
            ? "Update Permission Group"
            : "Add Permission Group"}
        </Button>
      </Footer>
      <div>
        <h4>Accounts in Group</h4>
      </div>
      <div>
        <ReactTable
          loading={loading}
          minRows={0}
          data={permissionGroup.toJS().accounts}
          defaultSorted={[{ id: "modifiedDateTime", desc: true }]}
          noDataText={loading ? <CircularProgress /> : "No accounts found."}
          columns={[
            {
              Header: "User Name",
              accessor: "username",
              ...stringFilterProps
            },
            {
              Header: "First Name",
              accessor: "firstName",
              ...stringFilterProps
            },
            {
              Header: "Last Name",
              accessor: "lastName",
              ...stringFilterProps
            },
            {
              Header: "Email",
              accessor: "email",
              ...stringFilterProps
            },
            {
              Header: "Org",
              accessor: "organization.name"
            },
            {
              Header: "Account Status",
              accessor: "status"
            },
            {
              Header: "Updated",
              accessor: "modifiedDateTime",
              width: 100,
              className: classnames("no-wrap", "center"),
              Cell: ({ value }) => <FormattedDate>{value}</FormattedDate>
            }
          ]}
          defaultPageSize={25}
          showPaginationTop={true}
          showPaginationBottom={false}
          className="-striped -highlight"
        />
      </div>
    </Form>
  )
}

export default PermissionGroupForm
