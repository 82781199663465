import {
  //useEffect,
  useState //useMemo
} from "react"
import PropTypes from "prop-types"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"

// MUI Components
import Switch from "@mui/material/Switch"
import DialogActions from "@mui/material/DialogActions"

import FormControlLabel from "@mui/material/FormControlLabel"
import Grid from "@mui/material/Grid"
import Button from "components/CustomButtons/Button"

import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import withStyles from "@mui/styles/withStyles"

// Custom Utils
import Helpers from "../../tools/Helpers"
import { urlValidation } from "tools/ValidationHelpers"
//import { put } from "tools/request"
import dayjs from "dayjs" //Dayjs
import AffiliateDAO from "daos/affiliateDAO"

const styles = theme => ({
  shortCodeForm: {
    padding: "5px 0"
  },
  input: {
    textDecoration: "line-through"
  }
})

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    setDialogWarningNoBtnMessage: store.uiStore.setDialogWarningNoBtnMessage,
    setDialogWarningNoBtnOpen: store.uiStore.setDialogWarningNoBtnOpen,
    setDialogSuccessMessage: store.uiStore.setDialogSuccessMessage,
    setDialogSuccessOpen: store.uiStore.setDialogSuccessOpen,
    setDialogFailMessage: store.uiStore.setDialogFailMessage,
    setDialogFailOpen: store.uiStore.setDialogFailOpen,
    retrieveLeagues: store.sportsStore.retrieveLeagues,
    retrieveLeagueDetails: store.sportsStore.retrieveLeagueDetails,
    leagueDetailsById: store.sportsStore.leagueDetailsById,
    leagues: store.sportsStore.leagues,
    dialogSuccessOpen: store.uiStore.dialogSuccessOpen,
    dialogSuccessMessage: store.uiStore.dialogSuccessMessage
  }))
}

function AffiliatesShortCodeForm({
  companies,
  groupType,
  affiliateId,
  classes,
  reloadShortCodes,
  closeShortCodeForm
}) {
  const _BASE_LINK = "https://affiliates.routy.app/route/{shortcode}?affId=3147"

  const [scFormErrors, setScFormErrors] = useState({})

  const {
    setDialogWarningNoBtnMessage,
    setDialogWarningNoBtnOpen,
    setDialogSuccessMessage,
    setDialogSuccessOpen,
    setDialogFailMessage,
    setDialogFailOpen
  } = useStoreData()

  const [shortCode, setShortCode] = useState({})

  const { name, companyId, groupTypeId, companyCode, sourceId, isArchived } =
    shortCode

  const _MAX_VALUE = 99999
  const _MIN_VALUE = 1

  const handleShortCodeIntergerChange = name => event => {
    const newShortCode = {
      ...shortCode,
      [name]: !event.target.value
        ? ""
        : event.target.value.match(/[^0-9]/)
        ? shortCode[name] || ""
        : event.target.value < _MIN_VALUE
        ? shortCode[name] || ""
        : event.target.value > _MAX_VALUE
        ? shortCode[name] || ""
        : event.target.value
    }
    setShortCode(newShortCode)
  }

  const handleShortCodeChange = name => e => {
    const newShortCode = {
      ...shortCode,
      [name]: e.target.value
    }
    setShortCode(newShortCode)
  }

  const handleAutocompleteChange = (event, value, reason, name) => {
    const newShortCode = {
      ...shortCode,
      [name]: reason === "clear" ? null : value?.id || null
    }
    setShortCode(newShortCode)
  }

  const handleCheckboxChange = name => e => {
    const newShortCode = {
      ...shortCode,
      [name]: e.target.checked
    }
    setShortCode(newShortCode)
  }
  const handleSave = () => {
    const foundNotValid = shortCodeFormValueValidation(shortCode)
    if (foundNotValid) {
      setDialogWarningNoBtnMessage(validationMessage(foundNotValid))
      setDialogWarningNoBtnOpen(true)
    } else {
      const body = {
        //id: 0,
        affiliateUrl:
          shortCode.affiliateUrl ||
          "https://affiliates.routy.app/route/{shortcode}?affId=3147",
        affiliateId: affiliateId,
        affiliateCodeSourceId: 1,
        companyCode: shortCode.companyCode,
        name: shortCode.name,
        companyId: shortCode.companyId,
        createdByAccountId: 0, //shortCode.createdBy_Account_Id,
        createdDateTime: dayjs().local(),
        leagueId: shortCode.leagueId,
        isArchived: shortCode.isArchived || false,
        modifiedByAccountId: 0, //shortCode.modifiedBy_Account_Id,
        modifiedDateTime: dayjs().local(),
        sourceId: shortCode.sourceId
      }
      //const resultMessages = []
      AffiliateDAO.saveNewShortCode(body).then(response => {
        if (response) {
          setDialogSuccessMessage(
            `You saved "${body.name}" and it has an ID of "${response}"`
          )
          setDialogSuccessOpen(true)
          closeShortCodeForm()
          reloadShortCodes()
        } else {
          setDialogFailMessage("Failed")
          closeShortCodeForm()
          setDialogFailOpen(true)
        }
      })
    }
  }
  const handleClose = () => {
    closeShortCodeForm()
  }

  const validationMessage = list => (
    <div>
      <div className={classes.infoValidationHeader}>
        You have the following issues:
      </div>
      <div className={classes.addScrollIfNeeded}>
        <ol>
          {list?.map((itm, i) => (
            <li key={i}>{itm}</li>
          ))}
        </ol>
      </div>
    </div>
  )

  const companyCodeValidation = name => {
    const howLong = name?.length
    if (howLong && howLong !== 5) {
      return "The Company Code must be 5 digits"
    } else {
      return null
    }
  }
  const shortCodeValidationObject = {
    hasOwnProperty: [
      {
        variable: "name",
        name: "Name"
      },
      {
        variable: "companyId",
        name: "Company"
      },
      {
        variable: "groupTypeId",
        name: "Group Type"
      },
      {
        variable: "sourceId",
        name: "Source ID"
      },
      {
        variable: "companyCode",
        name: "Company Code",
        validation: companyCodeValidation
      }
    ]
  }

  const shortCodeFormValueValidation = itm => {
    let result = []
    shortCodeValidationObject.hasOwnProperty?.forEach(field => {
      if (itm.hasOwnProperty(field.variable)) {
        if (typeof field.validation === "function") {
          const errorResult = field.validation(itm[field.variable], itm)
          if (errorResult) {
            result.push(errorResult)
          }
        } else if (field.array && !itm[field.variable].length) {
          result.push(`Please add a value for "${field.name}"`)
        } else if (!itm[field.variable]) {
          result.push(`Please add a value for "${field.name}"`)
        }
      } else {
        result.push(`"${field.name}" doesn't exist in the form`)
      }
    })
    if (result.length) {
      return result
    } else {
      return false
    }
  }

  const handleInputValidation = inputId => {
    const errorObj = { ...scFormErrors }
    if (inputId === "assetLink") {
      const urlV = urlValidation(shortCode.assetLink)
      if (!urlV && shortCode.assetLink) {
        errorObj.assetLinkMsg = "Asset Link must be a valid URL."
        errorObj[inputId] = true
        errorObj.validationHasError = true
      } else {
        errorObj[inputId] = false
        errorObj.validationHasError = false
      }
    } else if (inputId === "linkUrl") {
      const urlV = urlValidation(shortCode.linkUrl)
      if (!urlV && shortCode.linkUrl) {
        errorObj.linkURLMsg = "Link URL must be a valid URL."
        errorObj[inputId] = true
        errorObj.validationHasError = true
      } else {
        errorObj[inputId] = false
        errorObj.validationHasError = false
      }
    }
    setScFormErrors(errorObj)
  }

  return (
    <div style={{ maxWidth: "600px" }}>
      <>
        <Grid item xs={8} md={8}>
          <FormControlLabel
            className={classes.shortCodeForm}
            control={
              <Switch
                id="showArchivedShortCodes"
                checked={isArchived}
                onChange={handleCheckboxChange("isArchived")}
                color="primary"
                size="small"
              />
            }
            label="Is Archived?"
          />
        </Grid>
        <Grid item xs={12} sm={8} className={classes.shortCodeForm}>
          <TextField
            className={classes.shortCodeForm}
            multiline
            type="text"
            id="name"
            label="Name"
            fullWidth
            value={name}
            onChange={handleShortCodeChange("name")}
            margin="normal"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={8} sm={8}>
          <Autocomplete
            className={classes.shortCodeForm}
            options={groupType || []}
            //options={groupType?.slice().sort(Helpers.sortByName) || []}
            variant="outlined"
            getOptionLabel={option => option.name}
            value={groupType?.find(grp => grp.id === groupTypeId)}
            error={!groupType && groupType?.length === 0 ? 1 : 0}
            onChange={(event, newValue, reason) => {
              handleAutocompleteChange(event, newValue, reason, "groupTypeId")
            }}
            onInputChange={(event, newValue, reason) => {
              if (reason === "clear" || reason === "") {
                handleAutocompleteChange(null)
              }
            }}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                key={params.id}
                value={params.name}
                required={true}
                error={scFormErrors.companyId}
                helperText={
                  scFormErrors.companyId ? "Group Type is Required" : ""
                }
                label="Group Type"
                placeholder=""
              />
            )}
            onBlur={() => handleInputValidation("groupTypeId")}
          />
        </Grid>
        <Grid item xs={8} sm={8}>
          <TextField
            className={classes.shortCodeForm}
            type="text"
            id="baseLink"
            label="Base Link"
            fullWidth
            value={_BASE_LINK}
            //onChange={handleShortCodeChange("baseLink")}
            margin="normal"
            variant="outlined"
            disabled={true}
          />
        </Grid>
        <Grid item xs={8} sm={8}>
          <Autocomplete
            className={classes.shortCodeForm}
            options={companies?.slice().sort(Helpers.sortByName) || []}
            variant="outlined"
            getOptionLabel={option => option.name}
            value={companies?.find(company => company.id === companyId)}
            error={!companies && companies?.length === 0 ? 1 : 0}
            onChange={(event, newValue, reason) => {
              handleAutocompleteChange(event, newValue, reason, "companyId")
            }}
            onInputChange={(event, newValue, reason) => {
              if (reason === "clear" || reason === "") {
                handleAutocompleteChange(null)
              }
            }}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                key={params.id}
                value={params.name}
                required={true}
                error={scFormErrors.companyId}
                helperText={scFormErrors.companyId ? "Company is Required" : ""}
                label="COMPANY"
                placeholder=""
              />
            )}
            onBlur={() => handleInputValidation("companyId")}
          />
        </Grid>
        <Grid item xs={8} sm={8}>
          <TextField
            className={classes.shortCodeForm}
            multiline
            type="text"
            id="companyCode"
            label="Company Code"
            fullWidth
            value={companyCode}
            onChange={handleShortCodeIntergerChange("companyCode")}
            margin="normal"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={8} sm={8}>
          <TextField
            className={classes.shortCodeForm}
            multiline
            type="text"
            id="sourceId"
            label="Source ID"
            fullWidth
            value={sourceId}
            onChange={handleShortCodeIntergerChange("sourceId")}
            margin="normal"
            variant="outlined"
          />
        </Grid>

        <Grid item xs={8} md={8}>
          <DialogActions style={{ marginTop: "14px" }}>
            <Button onClick={() => handleClose()} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleSave()} color="primary">
              Save
            </Button>
          </DialogActions>
        </Grid>
      </>
    </div>
  )
}

AffiliatesShortCodeForm.propTypes = {
  // Where the user to be redirected on clicking the avatar
  companies: PropTypes.array,
  groupType: PropTypes.array,
  affiliateId: PropTypes.number,
  closeShortCodeForm: PropTypes.func,
  reloadShortCodes: PropTypes.func,
  classes: PropTypes.object
}
export default withStyles(styles)(AffiliatesShortCodeForm)
