import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import makeStyles from "@mui/styles/makeStyles"
import PropTypes from "prop-types"

import NarrativeEditLink from "./NarrativeEditLink"

const useStyles = makeStyles(() => ({
  inactive: {
    textDecoration: "line-through"
  }
}))

const NarrativeAutoComplete = ({
  narrative,
  labelName,
  options,
  onChange,
  isLibrary,
  disabled = false
}) => {
  const classes = useStyles()

  const libraryOrOutput = isLibrary ? "Library" : "Output"

  return (
    <Autocomplete
      id={
        labelName
          ? `${labelName.toLowerCase()}NarrativeId`
          : "targetNarrativeId"
      }
      fullWidth
      disabled={disabled}
      options={options}
      value={narrative}
      getOptionLabel={option =>
        option.id && option.name ? `${option.id} - ${option.name}` : ""
      }
      isOptionEqualToValue={(option, value) => option.id === value.id || ""}
      renderInput={params => (
        <TextField
          key={params.id}
          {...params}
          id={
            labelName
              ? `${labelName.toLowerCase()}${libraryOrOutput}`
              : `target${libraryOrOutput}`
          }
          label={
            labelName
              ? `${labelName} ${libraryOrOutput}`
              : `Target ${libraryOrOutput}`
          }
          variant="outlined"
          style={{ minWidth: "250px" }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                <NarrativeEditLink narrativeId={narrative?.id} />
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props}>
          <div
            className={option.isArchived && classes.inactive}
            key={option.id}
            value={option.id}
          >
            {option.id} - {option.name}
          </div>
        </li>
      )}
      onChange={onChange}
    />
  )
}

NarrativeAutoComplete.propTypes = {
  narrative: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isLibrary: PropTypes.bool
}

export default NarrativeAutoComplete
