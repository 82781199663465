import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withStyles from "@mui/styles/withStyles"
import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import CardFooter from "components/Card/CardFooter"
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import { globalSettings } from "variables/general"
import { put } from "tools/request"

class LogoutPage extends React.Component {
  constructor(props) {
    super(props)
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      usernameOrEmail: "",
      password: "",
      isLoggedIn: false,
      isRememberMe: false
    }
  }

  componentDidMount() {
    this.onLogoutButtonClick()
  }

  onLogoutButtonClick = () => {
    put(`${globalSettings.apiBaseUrl}/api/account/logout`)
      .then(response => response.json())
      .then(data => {
        this.setState({ isLoggedIn: false })
      })
      .catch(err => {
        console.error(`Logout error`)
      })
    localStorage.removeItem("accessToken")
    localStorage.removeItem("refreshToken")
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.container}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={6} md={4}>
            <Card>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="primary"
              >
                <h4 className={classes.cardTitle}>Logged Out</h4>
              </CardHeader>
              <CardBody>
                <div align="center">
                  You have been logged out of the Data Skrive Portal.
                </div>
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <h4>
                  <Link to="/login">Log In</Link>
                </h4>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

LogoutPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(loginPageStyle)(LogoutPage)
