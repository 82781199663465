import React, { Component } from "react"
import Button from "components/CustomButtons/Button"
import ReactTable from "react-table-6"
import { CSVLink } from "react-csv"
import { Tab, Tabs } from "@mui/material"
import Datetime from "react-datetime"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardIcon from "components/Card/CardIcon.jsx"
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle"
import withStyles from "@mui/styles/withStyles"
import { Link } from "react-router-dom"
import matchSorter from "match-sorter"
import AuditInfo from "components/AuditInfo"
import queryString from "query-string"
import InfoDialog from "components/CustomDialogs/InfoDialog"
import { CopyToClipboard } from "react-copy-to-clipboard"

import Tooltip from "@mui/material/Tooltip"
import { Visibility, Publish, Storage, Loop } from "@mui/icons-material"
import {
  DialogContentText,
  DialogContent,
  DialogTitle,
  Dialog,
  CircularProgress,
  TextField
} from "@mui/material"

import TimeAgo from "react-timeago"
import Helpers from "tools/Helpers.js"
import { TabPanel } from "components/TabPanel/TabPanel"
import { globalSettings } from "variables/general.jsx"
import { get, put } from "tools/request"

class ReportViewer extends Component {
  constructor(props) {
    super(props)
    const parsedUrl = queryString.parse(props.location.search)
    const currentReportId =
      props.selectedReportId || (props.match && props.match.params.reportId)
    const currentView = currentReportId ? 1 : 0
    const historyId = parsedUrl.historyId
    this.state = {
      currentReportId,
      reportName: "",
      reportDescription: "",
      reportIsActive: false,
      queryName: "",
      queryDescription: "",
      queryIsActive: false,
      allReportQueries: [],
      allReportInput: [
        { value: 0, display: "Active Narratives" },
        { value: 1, display: "Active Feeds" },
        { value: 2, display: "Custom Input" }
      ],
      currentView,
      allReports: [],
      reportQuery: "select * from organization;",
      reportQueryId: 0,
      reportInputId: 0,
      reportIsRunning: false,
      allReportsLoading: false,
      isReportSaved: false,
      isReportDataDirty: false,
      pageSize: 25,
      tableFilter: [],
      reportsPageCount: 25,
      reportsTotalCount: 0,
      reportResultCode: 0,
      reportResultMessage: "",
      reportResults: {},
      reactTableResults: [],
      dynamicColumns: [],
      inputTokens: [{ tokenName: "", tokenValue: "" }],
      tableHistoryFilter: [],
      allHistoryLoading: false,
      currentReportHistoryId: historyId || 0,
      historyLoaded: false,
      currentReportName: "",
      allReportHistory: [],
      showReportDetails: true,
      showReportInput: false,
      reportHistoryToken: "",
      linkCopyText: "Copy URL for Google Sheets"
    }
  }

  componentDidMount() {
    if (!this.state.allReportsLoaded) {
      this.loadAllReports(this.state.reportsPageCount)
    }

    let propReportId =
      this.props.selectedReportId ||
      (this.props.match && this.props.match.params.reportId)
    if (propReportId) {
      this.loadSingleReport(propReportId)
      if (this.state.currentReportHistoryId > 0 && !this.state.historyLoaded) {
        this.loadReportHistoryById(this.state.currentReportHistoryId)
      }
    }
  }

  componentDidUpdate() {
    let propReportId =
      this.props.selectedReportId ||
      (this.props.match && this.props.match.params.reportId)
    if (
      !this.state.isCreateMode &&
      propReportId &&
      Number(this.state.currentReportId) !== Number(propReportId)
    ) {
      this.setState({
        currentReportId: propReportId,
        //currentView: propReportId ? 1 : 0,
        tableFilter: [
          {
            id: "id",
            value: String(propReportId)
          }
        ]
      })
    }
  }

  isThisNewActionRequest(id) {
    if (
      !this.state.isCreateMode &&
      id &&
      Number(this.state.currentReportId) !== Number(id)
    ) {
      this.loadSingleReport(id)
      if (this.state.currentReportHistoryId > 0 && !this.state.historyLoaded) {
        this.loadReportHistoryById(this.state.currentReportHistoryId)
      }
    }
  }

  runReport = () => {
    this.setState({ reportIsRunning: true, reactTableResults: [] })
    this.mapResultsColumns({})
    put(`${globalSettings.apiBaseUrl}/api/reportbuilder/runreport`, null, {
      id: this.state.currentReportId,
      name: this.state.reportName,
      description: this.state.reportDescription,
      isActive: this.state.reportIsActive,
      queryName: this.state.queryName,
      queryDescription: this.state.queryDescription,
      queryIsActive: this.state.queryIsActive,
      reportQueryId: this.state.reportQueryId,
      reportQuerySQL: this.state.reportQuery,
      inputTokens: this.state.inputTokens
    })
      .then(response => response.json())
      .then(data => {
        this.setState({
          reportResultCode: data.responseCode,
          reportResultMessage: data.responseMessage
        })
        if (data.content.reportResults !== null) {
          this.setState({
            reactTableResults: data.content.reportResults.records
          })
          this.mapResultsColumns(data.content.reportResults.columns)
        } else {
          this.setState({ reactTableResults: [] })
          this.mapResultsColumns({})
        }
        this.setState({ reportIsRunning: false })
        this.loadReportHistory(this.state.currentReportId)
      })
  }

  mapResultsColumns = reportResultsColumns => {
    if (reportResultsColumns.length > 0) {
      let narrative_id_found = reportResultsColumns.find(
        c => c.toLowerCase() === "narrative_id"
      )
      const columns = Object.keys(reportResultsColumns).map(key =>
        this.parseIdLinks(reportResultsColumns[key], narrative_id_found)
      )
      this.setState({ dynamicColumns: columns })
    }
  }

  parseIdLinks = (columnHeader, hasNarrId) => {
    let colHeader = columnHeader.toLowerCase()
    switch (colHeader) {
      case "narrative_id":
        return {
          Header: columnHeader,
          accessor: columnHeader,
          Cell: cell => (
            <div>
              <Link
                to={{
                  pathname: `/portal/narrative/${cell.value}/edit`
                }}
                title="View Narrative"
                target="_blank"
              >
                {cell.value}
              </Link>
            </div>
          )
        }
      case "feed_id":
        return {
          Header: columnHeader,
          accessor: columnHeader,
          Cell: cell => (
            <div>
              <Link
                to={{
                  pathname: `/portal/feed-entries/${cell.value}`
                }}
                title="View Feed"
                target="_blank"
              >
                {cell.value}
              </Link>
            </div>
          )
        }
      case "feedentry_id":
        return {
          Header: columnHeader,
          accessor: columnHeader,
          Cell: cell => (
            <div>
              <Link
                to={{
                  pathname: `/portal/feed-entries/entry/${cell.value}`
                }}
                title="View Feed Entry"
                target="_blank"
              >
                {cell.value}
              </Link>
            </div>
          )
        }
      case "paragraph_id":
        if (hasNarrId) {
          return {
            Header: columnHeader,
            accessor: columnHeader,
            Cell: cell => (
              <div>
                <Link
                  to={{
                    pathname: `/portal/narrative/${cell.original.narrative_id}/edit?view=paragraphid-${cell.value}`
                  }}
                  title="View Paragraph"
                  target="_blank"
                >
                  {cell.value}
                </Link>
              </div>
            )
          }
        } else {
          return {
            Header: columnHeader,
            accessor: columnHeader
          }
        }
      case "sentence_id":
        if (hasNarrId) {
          return {
            Header: columnHeader,
            accessor: columnHeader,
            Cell: cell => (
              <div>
                <Link
                  to={{
                    pathname: `/portal/narrative/${cell.original.narrative_id}/edit?view=sentenceid-${cell.value}`
                  }}
                  title="View Sentence"
                  target="_blank"
                >
                  {cell.value}
                </Link>
              </div>
            )
          }
        } else {
          return {
            Header: columnHeader,
            accessor: columnHeader
          }
        }
      default:
        return {
          Header: columnHeader,
          accessor: columnHeader,
          Cell: cell => cell.value?.toString()
        }
    }
  }

  onTextAreaChange = e => {
    this.setState({ reportQuery: e.target.value, isReportDataDirty: true })
  }

  onReportQueryChange = event => {
    this.setState({
      reportQueryId: event.target.value
    })

    this.loadQueryInfo(event.target.value)
  }

  selectReport(reportId) {
    this.setState({
      id: "",
      name: ""
    })
    this.props.onChange && this.props.onChange(reportId)
  }

  onLoadReportButtonClicked = historyId => {
    if (!historyId) {
      return null
    }
    this.loadReportHistoryById(historyId)
  }

  loadSingleReport = reportId => {
    if (!reportId) {
      return null
    }
    get(
      `${globalSettings.apiBaseUrl}/api/reportbuilder/retrievereport/${reportId}`
    )
      .then(response => response.json())
      .then(data => {
        if (data.responseCode === 1000 && data.content) {
          this.setState({
            currentReportId: data.content.id,
            reportName: data.content.name,
            currentReportName: data.content.name, //added ???
            reportDescription: data.content.description,
            reportIsActive: data.content.isActive,
            reportQueryId: data.content.reportQueryId,
            queryName: data.content.reportQueryName,
            queryDescription: data.content.reportQueryDescription,
            queryIsActive: data.content.reportQueryIsActive,
            reportQuery: data.content.reportQuery
          })
          if (data.content.inputValues) {
            this.setState({
              inputTokens: data.content.inputValues
            })
          }

          let currentHistoryId = this.state.currentReportHistoryId
          if (currentHistoryId === 0 && data.content.mostRecentHistoryId > 0) {
            this.setState({
              currentReportHistoryId: data.content.mostRecentHistoryId
            })
            this.loadReportHistoryById(data.content.mostRecentHistoryId)
          }
          this.loadReportHistory(reportId)
        }
      })
  }

  loadReportHistoryById = historyId => {
    this.setState({
      currentReportHistoryId: historyId,
      historyLoaded: false
    })
    get(
      `${globalSettings.apiBaseUrl}/api/reportbuilder/loadreporthistory/${historyId}`
    )
      .then(response => response.json())
      .then(data => {
        this.setState({
          currentReportName: data.content.reportName,
          reportQuery: data.content.reportSql,
          reportHistoryToken: data.content.reportHistoryToken,
          historyLoaded: true
        })
        if (data.content.reportResults !== null) {
          this.setState({
            reactTableResults: data.content.reportResults.records
          })
          this.mapResultsColumns(data.content.reportResults.columns)
        } else {
          this.setState({ reactTableResults: [] })
          this.mapResultsColumns({})
        }
        this.setState({ reportIsRunning: false })
      })
  }

  loadReportHistory = reportId => {
    if (!reportId) {
      return null
    }
    this.setState({ allHistoryLoading: true })
    get(
      `${globalSettings.apiBaseUrl}/api/reportbuilder/retrieveallreporthistory/${reportId}?pagecount=25`
    )
      .then(response => response.json())
      .then(data => {
        this.setState({
          allReportHistory: data.content,
          allHistoryLoading: false
        })
      })
  }

  loadAllReports = reportsPageCount => {
    this.getReportCount()
    this.setState({ allReportsLoading: true })
    get(
      `${globalSettings.apiBaseUrl}/api/reportbuilder/retrieveallreports?pagecount=${reportsPageCount}`
    )
      .then(response => response.json())
      .then(data => {
        this.setState({
          allReports: data.content,
          allReportsLoading: false,
          allReportsLoaded: true
        })
      })
  }

  showReportDetails = () => {
    this.setState({ currentView: 1 })
  }

  onChangeReportsPageCount = event => {
    this.setState({ reportsPageCount: event.target.value })
    this.loadAllReports(event.target.value)
  }

  getReportCount() {
    get(`${globalSettings.apiBaseUrl}/api/reportbuilder/reportcount/`)
      .then(Response => Response.text())
      .then(text => {
        this.setState({
          reportsTotalCount: text
        })
      })
  }

  renderTotalCountOptions() {
    let steppingPoints = [25, 50, 75, 100, 150, 200, 250, 350, 400, 500]
    let reportsPageCountChoices = []
    steppingPoints.map(i => {
      if (i < this.state.reportsTotalCount) {
        reportsPageCountChoices.push(i)
      }
    })
    reportsPageCountChoices.push(this.state.reportsTotalCount)
    if (
      parseInt(this.state.reportsPageCount) >
      parseInt(this.state.reportsTotalCount)
    ) {
      this.setState({
        reportsPageCount: this.state.reportsTotalCount
      })
    }
    if (this.state.reportsTotalCount < 25) {
      return this.state.reportsTotalCount
    } else {
      return (
        <select
          onChange={this.onChangeReportsPageCount}
          style={{ width: "100" }}
          value={this.state.reportsPageCount}
        >
          {reportsPageCountChoices.map(o => (
            <option key={o} value={o}>
              {o}
            </option>
          ))}
        </select>
      )
    }
  }

  render() {
    const { classes } = this.props
    const { currentView } = this.state

    const reportDate = Datetime.moment().format("M/D/YY")

    const handleChange = (event, newValue) => {
      let currentV = this.state.currentView
      if (newValue !== currentV && newValue === 0) {
        this.setState({
          currentView: newValue,
          tableFilter: []
        })
      } else {
        this.setState({
          currentView: newValue
        })
      }
    }

    const stringFilterProps = {
      filterable: true,
      filterAll: true,
      filterMethod: ({ id, value }, rows) =>
        matchSorter(rows, value, {
          keys: [id],
          threshold: matchSorter.rankings.CONTAINS
        })
    }

    return (
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <Storage />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Report Viewer</h4>
        </CardHeader>
        <CardBody>
          <Dialog
            open={this.state.isReportSaved}
            onClose={() => this.setState({ isReportSaved: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Report Saved</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Your report has been saved.
              </DialogContentText>
            </DialogContent>
          </Dialog>
          <Tabs
            value={currentView}
            onChange={handleChange}
            aria-label="ReportViewer Tabs"
          >
            <Tab label="Reports" />
            <Tab label="Report Results" />
          </Tabs>
          <div>
            <TabPanel value={currentView} index={0}>
              <ReactTable
                loading={this.state.allReportsLoading}
                data={this.state.allReports}
                noDataText={
                  this.state.allReportsLoading ? (
                    <CircularProgress />
                  ) : (
                    "No reports found."
                  )
                }
                filtered={this.state.tableFilter}
                onFilteredChange={filtered =>
                  this.setState({ tableFilter: filtered })
                }
                getTrProps={(state, rowInfo) => ({
                  style: {
                    background:
                      rowInfo &&
                      rowInfo.row &&
                      rowInfo.row.id === this.state.currentReportId
                        ? "yellow"
                        : null
                  }
                })}
                columns={[
                  {
                    Header: "Id",
                    accessor: "id",
                    Cell: cell => <div>{cell.value}</div>,
                    width: 60
                  },
                  {
                    Header: "Name",
                    accessor: "name",
                    show: true,
                    width: 400,
                    ...stringFilterProps
                  },
                  {
                    Header: "Actions",
                    Cell: cell => (
                      <div className="actions-right">
                        <Link
                          to={`/portal/report-viewer/${cell.original.id}`}
                          title="View"
                          onClick={() => {
                            this.selectReport(cell.original.id)
                            this.showReportDetails()
                            this.isThisNewActionRequest(cell.original.id)
                          }}
                        >
                          <Button
                            justIcon
                            round
                            simple
                            color="info"
                            className="info"
                          >
                            <Visibility />
                          </Button>
                        </Link>
                      </div>
                    ),
                    sortable: false,
                    filterable: false,
                    width: 100
                  },
                  {
                    Header: "Description",
                    accessor: "description",
                    sortable: false,
                    ...stringFilterProps,
                    Cell: cell => (
                      <div>
                        {cell.value && cell.value.length > 100
                          ? `${cell.value.substring(0, 100)}...`
                          : cell.value}
                      </div>
                    )
                  },
                  {
                    Header: "Audit Info",
                    id: "auditInfo",
                    Cell: ({ original: { auditInfoResponse } }) => (
                      <AuditInfo {...auditInfoResponse} />
                    ),
                    width: 250,
                    filterable: false
                  }
                ]}
                filterable={true}
                defaultPageSize={50}
                showPaginationTop={true}
                showPaginationBottom={false}
                className="-striped -highlight"
                defaultSorted={[
                  {
                    id: "auditInfo",
                    desc: false
                  }
                ]}
                defaultFilterMethod={(filter, row) => {
                  const id = filter.pivotId || filter.id
                  return row[id] !== undefined
                    ? String(row[id])
                        .toLowerCase()
                        .startsWith(filter.value.toLowerCase())
                    : true
                }}
              >
                {(state, makeTable) => {
                  let recordsInfoText = ""

                  const { filtered, pageRows, pageSize, sortedData, page } =
                    state

                  if (sortedData && sortedData.length > 0) {
                    let isFiltered = filtered.length > 0
                    let totalRecords = sortedData.length
                    let recordsCountFrom = page * pageSize + 1
                    let recordsCountTo = recordsCountFrom + pageRows.length - 1
                    if (isFiltered) {
                      recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered reports`
                    } else {
                      recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords} reports`
                    }
                  } else {
                    recordsInfoText = this.state.isPausedJobsLoading
                      ? "Loading reports, please wait..."
                      : "No reports found"
                  }
                  return (
                    <div className="main-grid">
                      <div className="above-table text-right">
                        <div className="col-sm-12">
                          <span className="records-info">
                            {recordsInfoText}
                          </span>
                          <Tooltip title="Reload" placement="top" arrow={true}>
                            <Button
                              justIcon
                              round
                              simple
                              color="primary"
                              className="info"
                              id="reload"
                              onClick={() => {
                                this.loadAllReports()
                              }}
                            >
                              {this.state.isPausedJobsLoading ? (
                                <CircularProgress thickness={1} size={30} />
                              ) : (
                                <Loop />
                              )}
                            </Button>
                          </Tooltip>{" "}
                        </div>
                      </div>
                      {makeTable()}
                    </div>
                  )
                }}
              </ReactTable>
            </TabPanel>
            <TabPanel value={currentView} index={1}>
              <h4>{`Report: ${this.state.currentReportName}`}</h4>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  Report History
                </AccordionSummary>
                <AccordionDetails>
                  <React.Fragment>
                    <ReactTable
                      loading={this.state.allHistoryLoading}
                      data={this.state.allReportHistory}
                      noDataText={
                        this.state.allHistoryLoading ? (
                          <CircularProgress />
                        ) : (
                          "No report history found."
                        )
                      }
                      filtered={this.state.tableHistoryFilter}
                      onFilteredChange={filtered =>
                        this.setState({ tableHistoryFilter: filtered })
                      }
                      getTrProps={(state, rowInfo) => ({
                        style: {
                          background:
                            rowInfo &&
                            rowInfo.row &&
                            rowInfo.row.id === this.state.currentReportHistoryId
                              ? "yellow"
                              : null
                        }
                      })}
                      columns={[
                        {
                          Header: "Actions",
                          Cell: cell => (
                            <div className="actions-right">
                              <Tooltip title="Load Report" placement="top">
                                <Button
                                  justIcon
                                  round
                                  simple
                                  color="info"
                                  className="info"
                                  id="share_file"
                                  onClick={() =>
                                    this.onLoadReportButtonClicked(
                                      cell.original.id
                                    )
                                  }
                                >
                                  <Publish />
                                </Button>
                              </Tooltip>
                            </div>
                          ),
                          sortable: false,
                          filterable: false,
                          width: 100
                        },
                        {
                          Header: "Id",
                          accessor: "id",
                          Cell: cell => <div>{cell.value}</div>,
                          width: 60
                        },
                        {
                          Header: "Report Name",
                          accessor: "reportName",
                          show: true,
                          width: 400,
                          ...stringFilterProps
                        },
                        {
                          Header: "Query Name",
                          accessor: "queryName",
                          show: true,
                          width: 400,
                          ...stringFilterProps
                        },
                        {
                          Header: "Report Schedule",
                          accessor: "reportSchedule",
                          show: true,
                          width: 400,
                          ...stringFilterProps
                        },
                        {
                          Header: "Created",
                          id: "created",
                          accessor: "auditInfo.createdOn",
                          width: 400,
                          Cell: cell => (
                            <div>
                              <TimeAgo
                                date={cell.original.auditInfoResponse.createdOn}
                                title={Helpers.prettyDateTimeinPacificTimeZone(
                                  cell.original.auditInfoResponse.createdOn
                                )}
                              />
                            </div>
                          )
                        }
                      ]}
                      filterable={true}
                      defaultPageSize={5}
                      showPaginationTop={true}
                      showPaginationBottom={false}
                      className="-striped -highlight"
                      defaultSorted={[
                        {
                          id: "created",
                          desc: false
                        }
                      ]}
                      defaultFilterMethod={(filter, row) => {
                        const id = filter.pivotId || filter.id
                        return row[id] !== undefined
                          ? String(row[id])
                              .toLowerCase()
                              .startsWith(filter.value.toLowerCase())
                          : true
                      }}
                    >
                      {(state, makeTable) => {
                        let recordsInfoText = ""

                        const {
                          filtered,
                          pageRows,
                          pageSize,
                          sortedData,
                          page
                        } = state

                        if (sortedData && sortedData.length > 0) {
                          let isFiltered = filtered.length > 0
                          let totalRecords = sortedData.length
                          let recordsCountFrom = page * pageSize + 1
                          let recordsCountTo =
                            recordsCountFrom + pageRows.length - 1
                          if (isFiltered) {
                            recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered report history`
                          } else {
                            recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords} report history`
                          }
                        } else {
                          recordsInfoText = this.state.isPausedJobsLoading
                            ? "Loading report history, please wait..."
                            : "No report history found"
                        }
                        return (
                          <div className="main-grid">
                            <div className="above-table text-right">
                              <div className="col-sm-12">
                                <span className="records-info">
                                  {recordsInfoText}
                                </span>
                                <Tooltip
                                  title="Reload"
                                  placement="top"
                                  arrow={true}
                                >
                                  <Button
                                    justIcon
                                    round
                                    simple
                                    color="primary"
                                    className="info"
                                    id="reload"
                                    onClick={() => {
                                      this.loadReportHistory(
                                        this.state.currentReportId
                                      )
                                    }}
                                  >
                                    {this.state.isPausedJobsLoading ? (
                                      <CircularProgress
                                        thickness={1}
                                        size={30}
                                      />
                                    ) : (
                                      <Loop />
                                    )}
                                  </Button>
                                </Tooltip>{" "}
                              </div>
                            </div>
                            {makeTable()}
                          </div>
                        )
                      }}
                    </ReactTable>
                  </React.Fragment>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  Report SQL
                </AccordionSummary>
                <AccordionDetails
                  className={classes.reportTableStyle}
                  style={{ display: "block" }}
                >
                  <React.Fragment>
                    <div
                      className={classes.resizeTextarea}
                      style={{ width: "100%" }}
                    >
                      <TextField
                        id="reportSql"
                        fullWidth={true}
                        multiline={true}
                        placeholder={"Enter Report SQL here."}
                        value={this.state.reportQuery}
                        InputProps={{ readOnly: true }}
                      />
                    </div>
                  </React.Fragment>
                </AccordionDetails>
              </Accordion>
              <Accordion defaultExpanded={true}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {`Report Results  (history id: ${this.state.currentReportHistoryId})`}
                </AccordionSummary>
                <AccordionDetails style={{ display: "block" }}>
                  <React.Fragment>
                    <ReactTable
                      noDataText={
                        this.state.reportIsRunning ? (
                          <CircularProgress />
                        ) : (
                          "No result(s) found."
                        )
                      }
                      data={this.state.reactTableResults}
                      columns={[...this.state.dynamicColumns]}
                      filterable={true}
                      defaultPageSize={50}
                      showPaginationTop={true}
                      showPaginationBottom={true}
                      className="-striped -highlight"
                      defaultFilterMethod={(filter, row) => {
                        const id = filter.pivotId || filter.id
                        return row[id] !== undefined
                          ? String(row[id])
                              .toLowerCase()
                              .startsWith(filter.value.toLowerCase())
                          : true
                      }}
                    >
                      {(state, makeTable) => {
                        let recordsInfoText = ""

                        const {
                          filtered,
                          pageRows,
                          pageSize,
                          sortedData,
                          page
                        } = state

                        if (sortedData && sortedData.length > 0) {
                          let isFiltered = filtered.length > 0

                          let totalRecords = sortedData.length

                          let recordsCountFrom = page * pageSize + 1

                          let recordsCountTo =
                            recordsCountFrom + pageRows.length - 1

                          if (isFiltered) {
                            recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`
                          } else {
                            recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`
                          }
                        } else {
                          recordsInfoText = this.state.reportIsRunning
                            ? "Running Query, please wait"
                            : "No records"
                        }
                        return (
                          <div
                            className="main-grid"
                            style={{ overflow: "scroll" }}
                          >
                            <div>
                              <button className="btn btn-primary">
                                <CSVLink
                                  data={this.state.reactTableResults}
                                  filename={`${this.state.reportName}_${reportDate}.csv`}
                                  style={{ color: "#fff" }}
                                >
                                  Export To CSV
                                </CSVLink>
                              </button>

                              <CopyToClipboard
                                text={`=ImportJSON("${globalSettings.apiBaseUrl}/api/reportbuilder/retrievereporthistorybytoken/${this.state.reportHistoryToken}")`}
                                onCopy={() => {
                                  this.setState({
                                    linkCopyText: "URL Copied for Google Sheets"
                                  })
                                  setTimeout(
                                    () =>
                                      this.setState({
                                        linkCopyText:
                                          "Copy URL for Google Sheets"
                                      }),
                                    5000
                                  )
                                }}
                              >
                                <Tooltip
                                  title={this.state.linkCopyText}
                                  placement="top"
                                >
                                  <span>
                                    <button
                                      className="btn btn-primary"
                                      style={{ marginLeft: "10px" }}
                                      disabled={!this.state.historyLoaded}
                                    >
                                      Export for Sheets
                                    </button>
                                  </span>
                                </Tooltip>
                              </CopyToClipboard>
                              <InfoDialog
                                displayInline={true}
                                content={
                                  <div>
                                    Export For Sheets copies a link as part of a
                                    process to review Reports within Google
                                    Sheets. Find the rest of the process{" "}
                                    <a
                                      href="https://dataskrivejira.atlassian.net/wiki/spaces/PKB/pages/313098241/Exporting+Report+Viewer+Reports+to+Google+Sheets"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      here
                                    </a>
                                    .
                                  </div>
                                }
                              />
                            </div>
                            <div className="above-table text-right">
                              <div className="col-sm-12">
                                <span className="records-info">
                                  {recordsInfoText}
                                </span>
                              </div>
                            </div>
                            {makeTable()}
                          </div>
                        )
                      }}
                    </ReactTable>
                  </React.Fragment>
                </AccordionDetails>
              </Accordion>
            </TabPanel>
          </div>
        </CardBody>
      </Card>
    )
  }
}

export default withStyles(regularFormsStyle)(ReportViewer)
