import React, { useEffect } from "react"
import { useObserver } from "mobx-react"
import { Assignment } from "@mui/icons-material"
import { Map } from "immutable"
import { lowerCase, startCase } from "lodash"

import { useStore } from "contexts/rootContext"
import Page from "components/Page"
import Dialog, { Confirmation } from "components/Dialog"
import PermissionGroupForm from "./PermissionGroups/PermissionGroupForm"
import PermissionGroupList from "./PermissionGroups/PermissionGroupList"

function UserStoreData() {
  const store = useStore()
  return useObserver(() => ({
    loading: store.permissionGroupStore.loading,
    loadingPermissionGroup: store.permissionGroupStore.loadingPermissionGroup,
    isModalOpen: store.permissionGroupStore.isModalOpen,
    isPermissionGroupSelected:
      store.permissionGroupStore.isPermissionGroupSelected,
    getPermissionGroups: store.permissionGroupStore.getPermissionGroups,
    getPermissions: store.permissionGroupStore.getPermissions,
    closeModal: store.permissionGroupStore.closeModal,
    permissionGroup: store.permissionGroupStore.permissionGroup,
    upsertedPermissionGroup: store.permissionGroupStore.upsertedPermissionGroup,
    showUpsertConfirmation: store.permissionGroupStore.showUpsertConfirmation
  }))
}

const PermissionGroups = () => {
  const {
    loading,
    loadingPermissionGroup,
    isModalOpen,
    isPermissionGroupSelected,
    getPermissionGroups,
    getPermissions,
    closeModal,
    permissionGroup,
    upsertedPermissionGroup,
    showUpsertConfirmation
  } = UserStoreData()

  // on first render
  useEffect(() => {
    getPermissionGroups()
    getPermissions()
  }, [])

  const confirmationAction = isPermissionGroupSelected ? "Updated" : "Added"
  const actionTitle = isPermissionGroupSelected
    ? "Edit Permission Group"
    : "Add Permission Group"
  const modalTitle = showUpsertConfirmation
    ? `Permission Group Successfully ${startCase(confirmationAction)}!`
    : actionTitle

  return (
    <Page title="Permission Groups" loading={loading} icon={<Assignment />}>
      <PermissionGroupList />

      {/* edit/create groups */}
      <Dialog
        isOpen={isModalOpen}
        title={modalTitle}
        icon={<Assignment />}
        loading={loading || loadingPermissionGroup}
        auditInfo={permissionGroup.get("auditInfo", Map()).toJS()}
        isConfirmation={showUpsertConfirmation}
        onClose={closeModal}
      >
        {!showUpsertConfirmation && <PermissionGroupForm />}
        {showUpsertConfirmation && (
          <Confirmation
            body1={`You successfully ${lowerCase(
              confirmationAction
            )} the permission group '${upsertedPermissionGroup.get(
              "name",
              ""
            )}'.`}
            body2="Be sure that you have successfully assigned the relevant permissions to this group before using."
          />
        )}
      </Dialog>
    </Page>
  )
}

export default PermissionGroups
