import React, { Component } from "react"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardHeader from "components/Card/CardHeader"
import TimeAgo from "react-timeago"
import { globalSettings } from "variables/general"
import Helpers from "tools/Helpers.js"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import timezone from "dayjs/plugin/timezone"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CloseIcon from "@mui/icons-material/Close"
import { observer, inject } from "mobx-react"
import { get } from "tools/request"

dayjs.extend(timezone).extend(advancedFormat)

const useStore = component =>
  inject(({ store }) => ({
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization
  }))(observer(component))

class SiteInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      siteInfo: [],
      now: new Date()
    }
  }

  componentDidMount() {
    this.props.setShowChangeCurrentOrganization(false)
    setInterval(() => {
      this.setState({
        now: new Date()
      })
    }, 1000)
    return get(`${globalSettings.apiBaseUrl}/api/siteinfo?showServerInfo=true`)
      .then(Response => Response.json())
      .then(siteInfo => {
        this.setState({
          siteInfo: siteInfo,
          loading: false
        })
      })
  }

  render() {
    if (this.state.loading) {
      return <div>loading...</div>
    }
    const siteInfo = this.state.siteInfo
    const isDBHealthy = siteInfo.isDBHealthy || false
    const isReplicaHealthy = siteInfo.isDBReplicaHealthy || false
    const isCacheHealthy = siteInfo.isRedisHealthy || false
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader>
              <h4 className="">Site Information</h4>
            </CardHeader>
            <CardBody>
              {siteInfo &&
                siteInfo.onCallAccount &&
                siteInfo.onCallAccount.firstName &&
                siteInfo.onCallAccount.lastName && (
                  <div>
                    <h6>On-call resource</h6>
                    <div>
                      {siteInfo.onCallAccount.firstName}{" "}
                      {siteInfo.onCallAccount.lastName}
                    </div>
                  </div>
                )}
              {siteInfo &&
                siteInfo.onCallAccount &&
                siteInfo.onCallAccount.email && (
                  <div>{siteInfo.onCallAccount.email}</div>
                )}
              <h6>Current Client Date/time</h6>
              <div>
                {Helpers.prettyDateTimeinUTCTimeZone(this.state.now)} <br />
                {Helpers.prettyDateTimeinPacificTimeZone(this.state.now)} <br />
                {Helpers.prettyDateTimeinDefaultTimeZone(this.state.now)}{" "}
                {dayjs().format("z")}
              </div>
              <h6>Current Server Date/time</h6>
              <div>
                {Helpers.prettyDateTimeinUTCTimeZone(
                  siteInfo.currentServerUtcDateTime
                )}{" "}
                <br />
                {Helpers.prettyDateTimeinPacificTimeZone(
                  siteInfo.currentServerPacificTimeZoneDateTime
                )}{" "}
                <br />
                {Helpers.prettyDateTimeinDefaultTimeZone(
                  siteInfo.currentServerLocalDateTime
                )}{" "}
                {dayjs().format("z")}
              </div>
              <h6>.NET Core Assembly Version</h6>
              <div>{siteInfo.netCoreVersion}</div>
              <h6>.NET Core Runtime Version</h6>
              <div>{siteInfo.runtimeVersion}</div>
              <h6>.NET Core Application Version</h6>
              <div>{siteInfo.assemblyVersion}</div>
              <h6>.NET Core Assembly Modified Date/time</h6>
              <div>
                {Helpers.prettyDateTimeinPacificTimeZone(
                  siteInfo.assemblyModifyDateTime
                )}
                &nbsp; (
                <TimeAgo
                  date={siteInfo.assemblyModifyDateTime}
                  title={Helpers.prettyDateTimeinUTCTimeZone(
                    siteInfo.assemblyModifyDateTime
                  )}
                />
                )
              </div>
              <h6>Web Application Version</h6>
              <div>{window._env_.REACT_APP_WEB_VERSION}</div>
              <h6>Environment</h6>
              <div>{siteInfo.environmentSetting}</div>

              <h6>Docker Image Tag</h6>
              <div>{siteInfo.dockerImageTag}</div>

              <h6>Max Working Set</h6>
              <div>{siteInfo.maxWorkingSet}</div>
              <h6>Memory Bytes Allocated</h6>
              <div>{siteInfo.memoryBytesAllocated}</div>
              <h6>Peak Memory Bytes Allocated</h6>
              <div>{siteInfo.peakMemoryBytesAllocated}</div>
              <h6>Virtual Memory Bytes Allocated</h6>
              <div>{siteInfo.virtualMemoryBytesAllocated}</div>
              <h6>Peak Virtual Memory Bytes Allocated</h6>
              <div>{siteInfo.peakVirtualMemoryBytesAllocated}</div>
              <h6>Private Memory Bytes Allocated</h6>
              <div>{siteInfo.privateMemoryBytesAllocated}</div>
              <h6>Paged System Memory Bytes Allocated</h6>
              <div>{siteInfo.pagedSystemMemoryBytesAllocated}</div>
              <h6>Paged Memory Bytes Allocated</h6>
              <div>{siteInfo.pagedMemoryBytesAllocated}</div>
              <h6>Peak Paged Memory Bytes Allocated</h6>
              <div>{siteInfo.peakPagedMemoryBytesAllocated}</div>

              <h6>Process Start Date/time</h6>
              <div>
                {Helpers.prettyDateTimeinPacificTimeZone(
                  siteInfo.processStartDateTime
                )}
                &nbsp; (
                <TimeAgo
                  date={siteInfo.processStartDateTime}
                  title={Helpers.prettyDateTimeinUTCTimeZone(
                    siteInfo.processStartDateTime
                  )}
                />
                )
              </div>
              <h6>Your IP Address</h6>
              <div>{siteInfo.remoteIPAddress}</div>
              <h6>Server IP Address</h6>
              <div>{siteInfo.serverIPAddress}</div>
              <h6>Web Server Name</h6>
              <div>{siteInfo.webServerName}</div>
              <h6>Is Narrative Render Scheduler Healthy?</h6>
              <div>
                {siteInfo.isNarrativeRenderSchedulerHealthy ? "Yes" : "No"}
              </div>
              <h6>DB (read-write) Max Connection Pools</h6>
              <div>{siteInfo.postgresWriteDBMaxDBConnections}</div>
              <h6>DB (read-write) Current Open Connections</h6>
              <div>{siteInfo.howManyOpenWriteConnections}</div>

              <h6>DB (read-only) Max Connection Pools</h6>
              <div>{siteInfo.postgresReadDBMaxConnections}</div>
              <h6>DB (read-only) Current Open Connections</h6>
              <div>{siteInfo.howManyOpenReadConnections}</div>
              <h6>S3 'Assets' bucket name</h6>
              <div>{siteInfo.s3AssetsBucketName}</div>
              <h6>S3 'Raw Scrapes' bucket name</h6>
              <div>{siteInfo.s3RawScrapesBucketName}</div>
              <h6>S3 'Reports' bucket name</h6>
              <div>{siteInfo.s3ReportsBucketName}</div>
              <h6>Assets File System Cache</h6>
              <div>
                S3 file load(s): {siteInfo.totalFilesLoadedFromS3} (
                {Math.round(siteInfo.totalBytesLoadedFromS3 / 1024 / 1024)} MB),
                File system load(s): {siteInfo.totalFilesLoadedFromFileSystem} (
                {Math.round(
                  siteInfo.totalBytesLoadedFromFileSystem / 1024 / 1024
                )}{" "}
                MB) Cache Hit Ratio:{" "}
                {Math.round(
                  (siteInfo.totalFilesLoadedFromFileSystem /
                    (siteInfo.totalFilesLoadedFromFileSystem +
                      siteInfo.totalFilesLoadedFromS3)) *
                    1000
                ) / 10}
                %
              </div>
              <h6>Assets File System</h6>
              <div>
                Total files: {siteInfo.totalAssetFilesCountOnFileSystem} (
                {Math.round(
                  siteInfo.totalAssetFilesBytesOnFileSystem / 1024 / 1024
                )}{" "}
                MB), Total folders:{" "}
                {siteInfo.totalAssetFilesFolderCountOnFileSystem}
              </div>
              <h6>Data Health</h6>
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <b>Write Database</b> |{" "}
                      </td>
                      <td>
                        <b>Read Database</b> |{" "}
                      </td>
                      <td>
                        <b>Data Cache</b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {isDBHealthy && (
                          <CheckCircleIcon style={{ color: "#008000" }} />
                        )}
                        {!isDBHealthy && <CloseIcon color="action" />}
                      </td>
                      <td>
                        {isReplicaHealthy && (
                          <CheckCircleIcon style={{ color: "#008000" }} />
                        )}
                        {!isReplicaHealthy && <CloseIcon color="action" />}
                      </td>
                      <td>
                        {isCacheHealthy && (
                          <CheckCircleIcon style={{ color: "#008000" }} />
                        )}
                        {!isCacheHealthy && <CloseIcon color="action" />}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

export default useStore(SiteInfo)
