import React from "react"
import PropTypes from "prop-types"
import withStyles from "@mui/styles/withStyles"
import Button from "components/CustomButtons/Button"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardFooter from "components/Card/CardFooter"
import registerPageStyle from "assets/jss/material-dashboard-pro-react/views/registerPageStyle"
import DialogTitle from "@mui/material/DialogTitle"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import { globalSettings } from "variables/general"
import Helpers from "tools/Helpers"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Select, MenuItem, CircularProgress } from "@mui/material"
import { get, put } from "tools/request"

class FeedEntryPreviewPage extends React.Component {
  constructor(props) {
    super(props)
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      originalTitle: "",
      title: "",
      originalIntroParagraph: "",
      introParagraph: "",
      originalSummary: "",
      summary: "",
      originalBody: "",
      body: "",
      originalBottomHeading: "",
      bottomHeading: "",
      originalBottomParagraph: "",
      bottomParagraph: "",
      featuredImage: null,
      originalFeaturedImageCaption: null,
      feedEntryToken: this.props.match.params.feedEntryToken,
      isFailedDialogOpen: false,
      isSuccessDialogOpen: false,
      targetLanguageCode: "en",
      isAbleToTranslate: false,
      availableLanguages: [
        { name: "English", languageCode: "en" },
        { name: "Afrikaans", languageCode: "af" },
        { name: "Albanian", languageCode: "sq" },
        { name: "Amharic", languageCode: "am" },
        { name: "Arabic", languageCode: "ar" },
        { name: "Armenian", languageCode: "hy" },
        { name: "Azerbaijani", languageCode: "az" },
        { name: "Bengali", languageCode: "bn" },
        { name: "Bosnian", languageCode: "bs" },
        { name: "Bulgarian", languageCode: "bg" },
        { name: "Catalan", languageCode: "ca" },
        { name: "Chinese (Simplified)", languageCode: "zh" },
        { name: "Chinese (Traditional)", languageCode: "zh-TW" },
        { name: "Croatian", languageCode: "hr" },
        { name: "Czech", languageCode: "cs" },
        { name: "Danish", languageCode: "da" },
        { name: "Dari", languageCode: "fa-AF" },
        { name: "Dutch", languageCode: "nl" },
        { name: "Estonian", languageCode: "et" },
        { name: "Farsi (Persian)", languageCode: "fa" },
        { name: "Filipino Tagalog", languageCode: "tl" },
        { name: "Finnish", languageCode: "fi" },
        { name: "French", languageCode: "fr" },
        { name: "French (Canada)", languageCode: "fr-CA" },
        { name: "Georgian", languageCode: "ka" },
        { name: "German", languageCode: "de" },
        { name: "Greek", languageCode: "el" },
        { name: "Gujarati", languageCode: "gu" },
        { name: "Haitian Creole", languageCode: "ht" },
        { name: "Hausa", languageCode: "ha" },
        { name: "Hebrew", languageCode: "he" },
        { name: "Hindi", languageCode: "hi" },
        { name: "Hungarian", languageCode: "hu" },
        { name: "Icelandic", languageCode: "is" },
        { name: "Indonesian", languageCode: "id" },
        { name: "Italian", languageCode: "it" },
        { name: "Japanese", languageCode: "ja" },
        { name: "Kannada", languageCode: "kn" },
        { name: "Kazakh", languageCode: "kk" },
        { name: "Korean", languageCode: "ko" },
        { name: "Latvian", languageCode: "lv" },
        { name: "Lithuanian", languageCode: "lt" },
        { name: "Macedonian", languageCode: "mk" },
        { name: "Malay", languageCode: "ms" },
        { name: "Malayalam", languageCode: "ml" },
        { name: "Maltese", languageCode: "mt" },
        { name: "Mongolian", languageCode: "mn" },
        { name: "Norwegian", languageCode: "no" },
        { name: "Persian", languageCode: "fa" },
        { name: "Pashto", languageCode: "ps" },
        { name: "Polish", languageCode: "pl" },
        { name: "Portuguese", languageCode: "pt" },
        { name: "Romanian", languageCode: "ro" },
        { name: "Russian", languageCode: "ru" },
        { name: "Serbian", languageCode: "sr" },
        { name: "Sinhala", languageCode: "si" },
        { name: "Slovak", languageCode: "sk" },
        { name: "Slovenian", languageCode: "sl" },
        { name: "Somali", languageCode: "so" },
        { name: "Spanish", languageCode: "es" },
        { name: "Spanish (Mexico)", languageCode: "es-MX" },
        { name: "Swahili", languageCode: "sw" },
        { name: "Swedish", languageCode: "sv" },
        { name: "Tagalog", languageCode: "tl" },
        { name: "Tamil", languageCode: "ta" },
        { name: "Telugu", languageCode: "te" },
        { name: "Thai", languageCode: "th" },
        { name: "Turkish", languageCode: "tr" },
        { name: "Ukrainian", languageCode: "uk" },
        { name: "Urdu", languageCode: "ur" },
        { name: "Uzbek", languageCode: "uz" },
        { name: "Vietnamese", languageCode: "vi" },
        { name: "Welsh", languageCode: "cy" }
      ]
    }
  }

  componentDidMount() {
    if (this.state.title) {
      Helpers.updateBrowserTabTitle(false, this.state.title)
    }
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" })
      }.bind(this),
      700
    )
    this.fetchFeedEntry(this.state.feedEntryToken)
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction)
    this.timeOutFunction = null
  }

  onUsernameOrEmailChange = event => {
    this.setState({ usernameOrEmail: event.target.value })
  }

  fetchFeedEntry = feedEntryToken => {
    this.setState({ isFetchingFeedEntry: true })
    get(
      `${globalSettings.apiBaseUrl}/api/feedentry/entrybytoken/${feedEntryToken}`
    )
      .then(response => response.json())
      .then(data => {
        let summary = Helpers.stripHtml(data.summary)
        this.setState({
          title: data.title,
          originalTitle: data.title,
          introParagraph: data.introParagraph,
          originalIntroParagraph: data.introParagraph,
          summary: summary,
          originalSummary: summary,
          body: data.content,
          originalBody: data.content,
          bottomHeading: data.bottomHeading,
          originalBottomHeading: data.bottomHeading,
          bottomParagraph: data.bottomParagraph,
          originalBottomParagraph: data.bottomParagraph,
          featuredImage: data.featuredImage,
          featuredImageCaption:
            data.featuredImage && data.featuredImage.caption,
          originalFeaturedImageCaption:
            data.featuredImage && data.featuredImage.caption,
          isFetchingFeedEntry: false
        })
        if (data.title) {
          Helpers.updateBrowserTabTitle(false, data.title)
        }
        document.getElementsByTagName("meta")["description"].content = summary
        document
          .querySelector("meta[property='og:description']")
          .setAttribute("content", summary)
        document
          .querySelector("meta[property='og:title']")
          .setAttribute("content", data.title)
        if (data.featuredImage) {
          document
            .querySelector("meta[property='og:image']")
            .setAttribute("content", data.featuredImage.url)
        }
      })
  }

  handleDialogClose = () => {
    this.setState({ isFailedDialogOpen: false })
    this.setState({ isSuccessDialogOpen: false })
  }

  translateFeedEntryPreview = targetLanguage => {
    if (targetLanguage === "en") {
      this.setState({
        title: this.state.originalTitle,
        introParagraph: this.state.originalIntroParagraph,
        summary: this.state.originalSummary,
        body: this.state.originalBody,
        bottomHeading: this.state.bottomHeading,
        bottomParagraph: this.state.bottomParagraph,
        featuredImageCaption: this.state.originalFeaturedImageCaption
      })
    } else {
      this.translateText(
        "title",
        this.state.originalTitle,
        "en",
        targetLanguage
      )
      this.translateText(
        "introParagraph",
        this.state.originalIntroParagraph,
        "en",
        targetLanguage
      )
      this.translateText(
        "summary",
        this.state.originalSummary,
        "en",
        targetLanguage
      )
      this.translateText("body", this.state.originalBody, "en", targetLanguage)
      this.translateText(
        "bottomHeading",
        this.state.originalBottomHeading,
        "en",
        targetLanguage
      )
      this.translateText(
        "bottomParagraph",
        this.state.originalBottomParagraph,
        "en",
        targetLanguage
      )
      this.translateText(
        "featuredImageCaption",
        this.state.originalFeaturedImageCaption,
        "en",
        targetLanguage
      )
    }
  }

  translateText = (stateName, text, sourceLanguageCode, targetLanguageCode) => {
    this.setState({
      isTextTranslastionHappending: true,
      text2: "translating..."
    })
    put(`${globalSettings.apiBaseUrl}/api/translatetext`, null, {
      inputText: text,
      sourceLanguageCode,
      targetLanguageCode
    })
      .then(response => response.json())
      .then(response => {
        this.setState({
          isTextTranslastionHappending: false,
          lastTranslatedText: response.content.outputText,
          [stateName]: response.content.outputText
        })
      })
  }

  render() {
    const { classes } = this.props
    const previewUrl = `${globalSettings.portalBaseUrl}/feedentrypreview/${this.state.feedEntryToken}`
    const qrCodeUrl = `${globalSettings.apiBaseUrl}/api/qrcode?text=${previewUrl}`
    const menuItems = this.state.availableLanguages.map(item => (
      <MenuItem
        key={item.languageCode}
        value={item.languageCode}
        classes={{
          root: classes.selectMenuItem
        }}
      >
        {item.name}
      </MenuItem>
    ))

    return (
      <div className={classes.container}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={12} md={12}>
            <Dialog
              onClose={this.handleDialogClose}
              open={this.state.isFailedDialogOpen}
              aria-labelledby="simple-dialog-title"
            >
              <DialogTitle id="simple-dialog-title">{"Whoops!"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  A problem occurred. Unable to send password reset email.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleDialogClose} color="primary">
                  Try Again
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              onClose={this.handleDialogClose}
              open={this.state.isSuccessDialogOpen}
              aria-labelledby="simple-dialog-title"
            >
              <DialogTitle id="simple-dialog-title">{"Success"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Email is on its way. Check your inbox.
                </DialogContentText>
              </DialogContent>
            </Dialog>
            <Card
              profile
              className={`${classes.customCardClass} ${
                classes[this.state.cardAnimaton]
              }`}
            >
              <CardBody profile>
                {this.state.featuredImage && (
                  <div>
                    <img
                      src={this.state.featuredImage.url}
                      style={{ width: "100%" }}
                      title={this.state.featuredImageCaption}
                      alt=""
                    />{" "}
                    <br />
                    <em>{this.state.featuredImageCaption}</em>
                  </div>
                )}
                <h1 className={classes.cardTitle}>{this.state.title}</h1>
                <div
                  style={{
                    textAlign: "left",
                    position: "relative",
                    display: "none"
                  }}
                  dangerouslySetInnerHTML={{
                    __html:
                      this.state.summary &&
                      this.state.summary.replace(/apxh:/g, "")
                  }}
                />
                <div
                  style={{ textAlign: "left", position: "relative" }}
                  dangerouslySetInnerHTML={{
                    __html:
                      this.state.introParagraph &&
                      this.state.introParagraph.replace(/apxh:/g, "")
                  }}
                />
                <div
                  style={{ textAlign: "left", position: "relative" }}
                  dangerouslySetInnerHTML={{
                    __html:
                      this.state.body && this.state.body.replace(/apxh:/g, "")
                  }}
                />
                {this.state.bottomHeading ? (
                  <h1
                    style={{ textAlign: "left", position: "relative" }}
                    dangerouslySetInnerHTML={{
                      __html:
                        this.state.bottomHeading &&
                        this.state.bottomHeading.replace(/apxh:/g, "")
                    }}
                  />
                ) : null}
                <div
                  style={{ textAlign: "left", position: "relative" }}
                  dangerouslySetInnerHTML={{
                    __html:
                      this.state.bottomParagraph &&
                      this.state.bottomParagraph.replace(/apxh:/g, "")
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter} />
            </Card>
          </GridItem>
          <GridItem>
            <Card className={classes.justifyContentCenter}>
              <CardBody>
                <CopyToClipboard
                  text={previewUrl}
                  onCopy={() => {
                    alert(`Url ${previewUrl} copied to clipboard.`)
                    this.setState({ isAbleToTranslate: true })
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    Click to copy url. <br />
                    <img src={qrCodeUrl} width={150} alt="" />
                  </div>
                </CopyToClipboard>
                {!this.state.isFetchingFeedEntry &&
                  this.state.isAbleToTranslate && (
                    <div style={{ textAlign: "left" }}>
                      Translate to&nbsp;
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={this.state.targetLanguageCode}
                        onChange={event => {
                          this.setState(
                            {
                              targetLanguageCode: event.target.value
                            },
                            () =>
                              this.translateFeedEntryPreview(
                                this.state.targetLanguageCode
                              )
                          )
                        }}
                        inputProps={{
                          name: "simpleSelect",
                          id: "simple-select"
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem
                          }}
                        >
                          Choose a language
                        </MenuItem>
                        {menuItems}
                      </Select>
                      {this.state.isTextTranslastionHappending && (
                        <CircularProgress />
                      )}
                    </div>
                  )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

FeedEntryPreviewPage.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  selectedValue: PropTypes.string
}

export default withStyles(registerPageStyle)(FeedEntryPreviewPage)
