// ##############################
// // // GridSystem view styles
// #############################

const gridSystemStyle = {
  title: {
    color: "#3C4858",
    textDecoration: "none"
  }
}

export default gridSystemStyle
