import React from "react"
import withStyles from "@mui/styles/withStyles"
import PropTypes from "prop-types"
import { CloudUpload } from "@mui/icons-material"
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Tooltip,
  Modal
} from "@mui/material"
import ArchivedSentencePreview from "../Sentence/ArchivedSentencePreview"
import GridItem from "components/Grid/GridItem"
import { globalSettings } from "variables/general"
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog"
import { get } from "tools/request"

const styles = theme => ({
  successModal: {
    position: "absolute",
    textAlign: "center",
    color: "#069",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "45%",
    left: "45%",
    transform: "translate(-45%, -45%)",
    "& span": {
      fontWeight: "bold"
    },
    "& ul": {
      padding: "5px 0 0 5px",
      fontWeight: "bold"
    }
  },
  errorModal: {
    position: "absolute",
    color: "#c00",
    width: 300,
    textAlign: "center",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "45%",
    left: "45%",
    transform: "translate(-45%, -45%)",
    "& span": {
      fontWeight: "bold"
    },
    "& ul": {
      padding: "5px 0 0 5px",
      fontWeight: "bold",
      textDecoration: "underline"
    }
  }
})

function ArchivedItems(props) {
  const [showSentence, setShowSentence] = React.useState(false)
  const [showParagraph, setShowParagraph] = React.useState(false)
  const [showTrigger, setShowTrigger] = React.useState(false)
  const [selectVals, setSelectVals] = React.useState({})
  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false)
  const [targetId, setTargetId] = React.useState(0)
  const [targetType, setTargetType] = React.useState("")
  const [showSuccessModal, setShowSuccessModal] = React.useState(false)
  const [showFailureModal, setShowFailureModal] = React.useState(false)
  const [failureMsg, setFailureMsg] = React.useState("")
  const [successMsg, setSuccessMsg] = React.useState("")
  const [paragraphName, setParagraphName] = React.useState("")

  const { classes } = props

  const handleUnarchive = (id, type) => {
    setTargetId(id)
    setTargetType(type.split("Id")[0])
    setShowConfirmDialog(true)
  }

  const unarchiveEntity = () => {
    const id = targetId
    const type = targetType
    if (id && type) {
      get(
        `${globalSettings.apiBaseUrl}/api/narrative/unarchive/${type}?${type}Id=${id}`
      )
        .then(Response => Response.text())
        .then(archivedItems => {
          const myResult = archivedItems.match(/\d{3,}/)
          setShowConfirmDialog(false)
          if (!!myResult && myResult.length < 60) {
            setShowSuccessModal(true)
            setSuccessMsg(archivedItems)
          } else if (!!myResult && myResult.length > 59) {
            setShowFailureModal(true)
            setFailureMsg("This transaction failed")
          } else {
            setShowFailureModal(true)
            setFailureMsg("This transaction failed")
          }
        })
    }
  }

  const dialogSetOpen = () => {
    setShowConfirmDialog(false)
  }

  const archivedSentences = props.archivedSentences || []
  const archivedParagraphs = props.archivedParagraphs || []
  const archivedTriggers = props.archivedTriggers || []
  const sentenceId = selectVals.sentenceId || ""
  const paragraphId = selectVals.paragraphId || ""
  const triggerId = selectVals.triggerId || ""

  let paragraphList = {}
  archivedParagraphs.forEach(itm => {
    paragraphList[itm.paragraph_id] = true
  })
  const archivedStandAloneSentences = archivedSentences.filter(
    item => item.isarchived === true
  )
  const filteredSentences = archivedSentences.filter(
    item => item.sentence_id === sentenceId
  )
  const archivedSentencesForParagraphs = archivedSentences.filter(
    item => item.paragraph_id === paragraphId
  )
  const filteredTriggers = archivedTriggers.filter(
    item => item.trigger_id === triggerId
  )

  return (
    <div>
      {!archivedSentences.length &&
        !archivedParagraphs.length &&
        !archivedTriggers.length && (
          <h5 style={{ color: "#144D69", fontWeight: "bold" }}>
            This Narrative nas no Sentences, Paragraphs, or Triggers that are
            archived.
          </h5>
        )}
      {(!!archivedSentences.length ||
        !!archivedParagraphs.length ||
        !!archivedTriggers.length) && (
        <h5 style={{ color: "#144D69", fontWeight: "bold" }}>
          Select the archived entity you want to investigate:
        </h5>
      )}

      {archivedStandAloneSentences &&
        archivedStandAloneSentences.length > 0 && (
          <Grid style={{ display: "flex" }}>
            <GridItem xs sm={6}>
              <FormControl margin="normal" fullWidth>
                <InputLabel>Archived Sentences</InputLabel>
                <Select
                  fullWidth
                  type="select"
                  name="sentenceId"
                  label="Archived Sentences"
                  id="sentenceId"
                  value={sentenceId || ""}
                  onChange={e => {
                    if (!e.target.value) {
                      return
                    }
                    const newSelectVals = {
                      ...selectVals,
                      sentenceId: e.target.value,
                      paragraphId: "",
                      triggerId: ""
                    }
                    setSelectVals(newSelectVals)
                    setShowSentence(true)
                    setShowParagraph(false)
                    setShowTrigger(false)
                  }}
                >
                  <MenuItem value="">Select</MenuItem>
                  {archivedStandAloneSentences.map((option, i) => (
                    <MenuItem key={i} value={option.sentence_id}>
                      Sentence ID - {option.sentence_id}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
          </Grid>
        )}

      {archivedParagraphs && archivedParagraphs.length > 0 && (
        <Grid style={{ display: "flex" }}>
          <GridItem xs sm={6}>
            <FormControl margin="normal" fullWidth>
              <InputLabel>Archived Paragraphs</InputLabel>
              <Select
                fullWidth={false}
                type="select"
                name="paragraphId"
                id="paragraphId"
                value={paragraphId || ""}
                onChange={e => {
                  if (!e.target.value) {
                    return
                  }
                  const newSelectVals = {
                    ...selectVals,
                    sentenceId: "",
                    paragraphId: e.target.value,
                    triggerId: ""
                  }
                  setParagraphName(
                    e.currentTarget &&
                      e.currentTarget.dataset &&
                      e.currentTarget.dataset.name
                  )
                  setSelectVals(newSelectVals)
                  setShowSentence(false)
                  setShowTrigger(false)
                  setShowParagraph(true)
                }}
              >
                <MenuItem value="">Select</MenuItem>
                {archivedParagraphs.map((option, i) => (
                  <MenuItem
                    key={i}
                    value={option.paragraph_id}
                    data-name={option.name}
                  >
                    Paragraph ID - {option.paragraph_id}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
        </Grid>
      )}

      {archivedTriggers && archivedTriggers.length > 0 && (
        <Grid style={{ display: "flex" }}>
          <GridItem xs sm={6}>
            <FormControl margin="normal" fullWidth>
              <InputLabel>Archived Triggers</InputLabel>
              <Select
                fullWidth={false}
                type="select"
                name="triggerId"
                id="triggerId"
                value={triggerId || ""}
                onChange={e => {
                  if (!e.target.value) {
                    return
                  }
                  const newSelectVals = {
                    ...selectVals,
                    sentenceId: "",
                    paragraphId: "",
                    triggerId: e.target.value
                  }
                  setSelectVals(newSelectVals)
                  setShowSentence(false)
                  setShowParagraph(false)
                  setShowTrigger(true)
                }}
              >
                <MenuItem value="">Select</MenuItem>
                {archivedTriggers.map((option, i) => (
                  <MenuItem key={i} value={option.trigger_id}>
                    Trigger ID - {option.trigger_id}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
        </Grid>
      )}

      {showSentence && (
        <div
          style={{
            border: "1px solid #aaa",
            borderRadius: "5px"
          }}
        >
          <Grid style={{ display: "flex" }}>
            <GridItem xs sm={6}>
              <h4 style={{ fontWeight: "bold" }}>Sentence: {sentenceId}</h4>
            </GridItem>
            <GridItem xs={12} sm={2}>
              <Tooltip
                id="tooltip-unarchive-sentence"
                title="Unarchive Sentence"
                placement="top"
                arrow
              >
                <IconButton
                  onClick={() => handleUnarchive(sentenceId, "sentenceId")}
                  aria-label="Unarchive Sentence"
                  size="large"
                >
                  <CloudUpload />
                </IconButton>
              </Tooltip>
            </GridItem>
          </Grid>
          {filteredSentences &&
            filteredSentences.length > 0 &&
            filteredSentences.map((sentence, index) => (
              <ArchivedSentencePreview
                key={`${sentence.sentence_id}-${index}`}
                sentence={sentence}
                searchString={"searchString"}
              />
            ))}
        </div>
      )}

      {showParagraph && (
        <div
          style={{
            border: "1px solid #aaa",
            borderRadius: "5px"
          }}
        >
          <Grid style={{ display: "flex" }}>
            <GridItem xs sm={6}>
              <h4 style={{ fontWeight: "bold" }}>
                Paragraph: {paragraphId} - {paragraphName}
              </h4>
            </GridItem>
            <GridItem xs={12} sm={2}>
              <Tooltip
                id="tooltip-unarchive-paragraph"
                title="Unarchive Paragraph"
                placement="top"
                arrow
              >
                <IconButton
                  onClick={() => handleUnarchive(paragraphId, "paragraphId")}
                  aria-label="Unarchive Paragraph"
                  size="large"
                >
                  <CloudUpload />
                </IconButton>
              </Tooltip>
            </GridItem>
          </Grid>
          {archivedSentencesForParagraphs &&
            archivedSentencesForParagraphs.length > 0 &&
            archivedSentencesForParagraphs.map((para, index) => (
              <ArchivedSentencePreview
                key={`${para.paragraph_id}-${index}`}
                sentence={para}
                searchString={"searchString"}
              />
            ))}
        </div>
      )}
      {showTrigger && (
        <div
          style={{
            border: "1px solid #aaa",
            borderRadius: "5px"
          }}
        >
          <Grid style={{ display: "flex" }}>
            <GridItem xs sm={6}>
              <h4 style={{ fontWeight: "bold" }}>Trigger: {triggerId}</h4>
            </GridItem>
            <GridItem xs={12} sm={2}>
              <Tooltip
                id="tooltip-unarchive-trigger"
                title="Unarchive Trigger"
                placement="top"
                arrow
              >
                <IconButton
                  onClick={() => handleUnarchive(triggerId, "triggerId")}
                  aria-label="Unarchive Trigger"
                  size="large"
                >
                  <CloudUpload />
                </IconButton>
              </Tooltip>
            </GridItem>
          </Grid>
          {filteredTriggers &&
            filteredTriggers.length > 0 &&
            filteredTriggers.map((trigger, index) => (
              <ArchivedSentencePreview
                key={`${trigger.trigger_id}-${index}`}
                sentence={trigger}
                searchString={"searchString"}
              />
            ))}
        </div>
      )}
      {showConfirmDialog && (
        <ConfirmDialog
          title="Unarchive this entity?"
          open={showConfirmDialog}
          setOpen={dialogSetOpen}
          onConfirm={unarchiveEntity}
        >
          <div>
            <strong>
              Confirm that you want the following to be Unarchived:
            </strong>
            <h4>
              <strong>
                {targetType} #{targetId}
              </strong>
            </h4>
          </div>
        </ConfirmDialog>
      )}
      {showFailureModal && (
        <Modal
          open={showFailureModal}
          onClose={() => {
            setShowFailureModal(false)
            setShowSentence(false)
            setShowParagraph(false)
            setShowTrigger(false)
            props.reloadArchive()
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.errorModal}>
            <span>{failureMsg}</span>
          </div>
        </Modal>
      )}
      {showSuccessModal && (
        <Modal
          open={showSuccessModal}
          onClose={() => {
            setShowSuccessModal(false)
            setShowSentence(false)
            setShowParagraph(false)
            setShowTrigger(false)
            props.reloadArchive()
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.successModal}>
            <span>{successMsg}</span>
          </div>
        </Modal>
      )}
    </div>
  )
}

ArchivedItems.propTypes = {
  archivedSentences: PropTypes.array,
  archivedParagraphs: PropTypes.array,
  archivedTriggers: PropTypes.array,
  reloadArchive: PropTypes.func,
  classes: PropTypes.object
}
export default withStyles(styles)(ArchivedItems)
