import React, { useState } from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import makeStyles from "@mui/styles/makeStyles"
import { Schedule } from "@mui/icons-material"
import CardBody from "components/Card/CardBody"
import Enums from "tools/Enums.js"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogActions from "@mui/material/DialogActions"
import Switch from "@mui/material/Switch"
import Helpers from "tools/Helpers.js"
import TimeAgo from "react-timeago"
import PropTypes from "prop-types"
import Button from "components/CustomButtons/Button.jsx"
import { globalSettings } from "variables/general.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Datetime from "react-datetime"
import CronBuilder from "react-cron-builder"
import Build from "@mui/icons-material/Build"
import Help from "@mui/icons-material/Help"
import Clear from "@mui/icons-material/Clear"
import Card from "components/Card/Card.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import {
  IconButton,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Modal,
  TextField,
  LinearProgress,
  Select,
  MenuItem,
  FormHelperText
} from "@mui/material"
import { Autocomplete } from "@mui/material"
import OrganizationDAO from "daos/organizationDAO"
import CardFooter from "components/Card/CardFooter"
import WebhookInfoDialog from "./WebhookInfoDialog"
import { get, put } from "tools/request"

const useStyles = makeStyles(() => ({
  scheduleOutputModal: {
    width: "90vw",
    height: "90vh",
    margin: "5vh auto",
    overflowY: "auto",
    padding: "0 10px"
  },
  alertMessageModal: {
    width: "40vw",
    height: "200px",
    margin: "34vh auto"
  },
  requiredMsg: {
    color: "#c00",
    //fontWeight: "bold",
    backgroundColor: "#f7f7f7"
  },
  highliteCreateMode: {
    color: "#f00",
    fontWeight: "bold",
    "& h6": {
      color: "#f00",
      fontWeight: "bold"
    }
  },
  styledSwitch: {
    paddingTop: "15px",
    paddingLeft: "6px",
    color: "rgba(0,0,0,0.87)"
  },
  cronBuilder: {
    width: "34vw",
    margin: "21vh auto",
    "& .cron-builder": {
      backgroundColor: "#eee"
    },
    "& .cron-builder__legend": {
      fontSize: "14px"
    },
    "& .cron-builder__tab": {
      margin: "4px"
    },
    "& .Select-control": {
      minWidth: "110px"
    },
    "& .cron-builder__label": {
      color: "#999"
    }
  },
  gridContainer: {
    height: "5rem",
    marginTop: "30px",
    "& .MuiGrid-item": {
      padding: 0
    },
    "& .rdt": {
      marginTop: "-7px"
    }
  },
  cardFooter: {
    justifyContent: "right"
  },
  fieldSpacing: {
    paddingTop: "16px"
  },
  helperText: {
    display: "inline-flex",
    alignItems: "flex-end"
  }
}))

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    queries: store.queryBuilderStore.queries,
    getQueries: store.queryBuilderStore.getQueries,
    feeds: store.organizationStore.feeds,
    getFeeds: store.organizationStore.getFeeds,
    organizations: store.organizationStore.organizations,
    setDialogWarningMessage: store.uiStore.setDialogWarningMessage,
    dialogWarningSuccess: store.uiStore.dialogWarningSuccess,
    setDialogWarningSuccess: store.uiStore.setDialogWarningSuccess,
    setDialogWarningOpen: store.uiStore.setDialogWarningOpen
  }))
}

const NarrativeRenderScheduler = props => {
  const classes = useStyles()
  const {
    getQueries,
    queries,
    getFeeds,
    feeds,
    organizations,
    setDialogWarningMessage,
    setDialogWarningOpen,
    dialogWarningSuccess,
    setDialogWarningSuccess
  } = useStoreData()

  //const endDateTimeInputRef = React.createRef()
  const { narrativeLoaded, narrative, modelName, selectedMatchIds } = props

  const [doShowStartDateTime, setDoShowStartDateTime] = React.useState(false)

  const [contentId, setContentId] = React.useState(props.contentId)
  const [contentType] = React.useState(props.contentType)

  const [queryIdDisplayName, setQueryDisplayName] = React.useState("")
  const [filteredFeeds, setFilteredFeeds] = React.useState([])

  const [contractDeliverables, setContractDeliverables] = React.useState([])
  const [filteredWebhooks, setFilteredWebhooks] = useState([])

  const [rsFormErrors, setRsFormErrors] = useState({})

  const sampleScheduler = {
    narrativeId: narrative.id,
    contentId,
    alertMessage: "",
    isAlertMessageOpen: false,
    futureOccurrences: "",
    futureOccurrencesMessage: null,
    contentIds: selectedMatchIds,
    contentType,
    isDebug: props.debug || false,
    feedId: null,
    contractDeliverableId: props.contractDeliverableId || null,
    feedsLoaded: false,
    outputFeedId: narrative.productionFeed_Id || props.outputFeedId,
    endDateTime: props.endDateTime || null,
    startDateTime: props.startDateTime || new Date(),
    cronSyntax: props.cronSyntax || "0 */1 * * *", // we don't allow a repeat every minute in this cron syntax.  This is use to indicate a "Run Once" schedule
    cronTimeZoneIANA: "America/Los_Angeles",
    duplicateHandling: isNaN(props.duplicateHandling)
      ? 1
      : props.duplicateHandling, // Update if its undefined or null
    isPaused: false,
    queryResultsIndexRange: "",
    publishDelayInSeconds: props.publishDelayInSeconds || 0,
    maxFutureOccurrenceCount: 100,
    doShowStartDateTime: false,
    useRecurrenceSettings: props.useRecurrenceSettings || false,
    useProductionRenderBots: props.useProductionRenderBots || false,
    doRenderCronSyntaxCheatSheetImage: false,
    isRenderSchedulerOpen: props.isMyRenderSchedulerOpen,
    narrativeRenderScheduleId: props.narrativeRenderScheduleId,
    isRenderScheduleConfirmationOpen: false,
    narrativeBlock_Ids: "",
    ignoreKillSwitch: false,
    webhookId: props.webhookId || null,
    hideFromFeed: props.hideFromFeed || false
  }

  const [renderSchedule, setRenderSchedule] = React.useState(sampleScheduler)

  React.useEffect(() => {
    if (!queries || queries.size === 0) {
      getQueries()
    }
    const narrative = props.narrative
    const tempFeeds = feeds ? feeds.toJS() : false
    const oldId =
      (tempFeeds &&
        tempFeeds.types &&
        tempFeeds.types[0] &&
        tempFeeds.types[0].organization &&
        tempFeeds.types[0].organization.id) ||
      -99
    if (
      narrative &&
      narrative.organization &&
      narrative.organization.id &&
      (!feeds || feeds.length === 0 || oldId !== narrative.organization.id)
    ) {
      setFilteredFeeds([])
      getFeeds(narrative.organization.id)
    }
    getContractDeliverables(narrative)
    getOutboundWebhookByOrg(narrative?.organization?.id)
  }, [narrative, queries])

  React.useEffect(() => {
    if (!feeds) {
      setFilteredFeeds([])
      return
    }
    let feedObjects = feeds.toJS()
    const feedId =
      (feedObjects &&
        feedObjects.types &&
        feedObjects.types[0] &&
        feedObjects.types[0].organization &&
        feedObjects.types[0].organization.id) ||
      -99
    const narrId =
      narrative && narrative.organization && narrative.organization.id
    if (feedId !== narrId) {
      //If the feed for the previous ORG is here, not the new ORG's feed, exit out
      return
    }
    // never show 'archived' feeds
    let filtered = feedObjects.types.filter(f => f.isArchived === false)
    // When a narrative is in 'Validate' status only display
    // feeds that are also in 'Validate' status
    if (props.narrative.narrativeStatusId === Enums.NarrativeStatus.VALIDATE) {
      filtered = filtered.filter(
        f =>
          f.feedStatus === Enums.FeedStatus.VALIDATE ||
          f.feedStatus === Enums.FeedStatus.VALIDATEINTERNAL
      )
    }
    setFilteredFeeds(filtered)
  }, [feeds])

  React.useEffect(() => {
    if (dialogWarningSuccess) {
      const newSchedule = {
        ...renderSchedule,
        useProductionRenderBots: true
      }
      setRenderSchedule(newSchedule)
      setDialogWarningSuccess(false)
      setDialogWarningOpen(false)
    }
  }, [dialogWarningSuccess])

  const getContractDeliverables = narrative => {
    const fetchData = async id => {
      const response = await OrganizationDAO.getContractDeliverablesForSchedule(
        narrative?.organization?.id,
        narrative.leagueId
      )
      const { content } = response
      const filteredDeliverables = content.filter(
        cd => cd.narrativeId === narrative.id
      )
      setContractDeliverables(
        filteredDeliverables.length > 0 ? filteredDeliverables : content
      )
    }
    fetchData()
  }

  const getOutboundWebhookByOrg = orgId => {
    const results = OrganizationDAO.getOutboundWebhookByOrg(orgId)
    results.then(response => {
      const { content } = response
      const filteredWebhooks = content.filter(
        wh => wh.organization.id === orgId && !wh.isArchived
      )

      const isValidateStatus =
        props.narrative.narrativeStatusId === Enums.NarrativeStatus.VALIDATE

      if (isValidateStatus) {
        const filteredWebhooksForValidation = filteredWebhooks.filter(
          webhook =>
            webhook.webhookStatusId === Enums.WebhookStatus.VALIDATE ||
            webhook.webhookStatusId === Enums.WebhookStatus.VALIDATEINTERNAL
        )
        setFilteredWebhooks(filteredWebhooksForValidation)
      } else {
        setFilteredWebhooks(filteredWebhooks)
      }
    })
  }

  const handleChange = event => {
    let newSchedule = {
      ...renderSchedule,
      [event.target.id || event.target.name]: event.target.value
    }
    if (
      //This is for when we swithd the feedID and we need to see
      //if the selected contract Deliverable no longer matches
      "outputFeedId" === event.target.id ||
      "outputFeedId" === event.target.name
    ) {
      let feedMatchedSelectedDeliverable = false
      contractDeliverables.forEach(itm => {
        if (
          itm.id === renderSchedule.contractDeliverableId &&
          Number(itm.feedId) === Number(event.target.value)
        ) {
          feedMatchedSelectedDeliverable = true
        }
      })
      if (!feedMatchedSelectedDeliverable) {
        newSchedule = { ...newSchedule, contractDeliverableId: "" }
      }
    }
    setRenderSchedule(newSchedule)
    onChangeValidation({
      name: event.target.id || event.target.name,
      value: event.target.value
    })
  }

  const handleQueryResultsIndexRangeChange = event => {
    const newSchedule = {
      ...renderSchedule,
      queryResultsIndexRange: event.target.value
    }
    setRenderSchedule(newSchedule)
  }

  const calculateFutureOccurrences = schedule => {
    const { useRecurrenceSettings, maxFutureOccurrenceCount } = schedule
    let cronSyntax = schedule.cronSyntax
    let endDateTimeValue =
      schedule.endDateTime !== null ? schedule.endDateTime.toISOString() : null
    if (!schedule.useRecurrenceSettings) {
      cronSyntax = ""
      endDateTimeValue = ""
    }
    get(
      `${
        globalSettings.apiBaseUrl
      }/api/narrativerenderschedule/futureoccurrences?cronsyntax=${cronSyntax}&startdatetime=${
        schedule.startDateTime ? schedule.startDateTime.toISOString() : ""
      }&userecurrencesettings=${useRecurrenceSettings}&enddatetime=${endDateTimeValue}&maxFutureOccurrenceCount=${maxFutureOccurrenceCount}`
    )
      .then(Response => Response && Response.ok === true && Response.json())
      .then(result => {
        if (result && result.responseCode === 1000) {
          const newSchedule = {
            ...schedule,
            futureOccurrences: result.content,
            futureOccurrencesMessage: ""
          }
          setRenderSchedule(newSchedule)
        } else {
          const newSchedule = {
            ...schedule,
            futureOccurrences: null,
            futureOccurrencesMessage: `There was a problem described as ${result.responseMessage}.`
          }
          setRenderSchedule(newSchedule)
        }
      })
      .catch(err =>
        console.error(
          "Error on GET to /api/narrativerenderschedule/futureoccurrences",
          err
        )
      )
  }

  const checkboxChange = event => {
    const newSchedule = {
      ...renderSchedule,
      [event.target.id || event.target.name]: event.target.checked
    }
    setRenderSchedule(newSchedule)
    if (
      event.target.id === "useRecurrenceSettings" ||
      event.target.id === "doShowStartDateTime"
    ) {
      calculateFutureOccurrences(newSchedule)
    }
  }

  const startDateTimeChange = event => {
    const newSchedule = {
      ...renderSchedule,
      startDateTime: event ? new Date(event) : new Date()
    }
    setRenderSchedule(newSchedule)
    calculateFutureOccurrences(newSchedule)
  }

  const cronSyntaxChange = event => {
    const newSchedule = {
      ...renderSchedule,
      cronSyntax: event.target.value
    }
    setRenderSchedule(newSchedule)
    calculateFutureOccurrences(newSchedule)
  }

  const cronSyntaxTrimChange = () => {
    if (renderSchedule.cronSyntax.trim().split(" ").length !== 5) {
      alert(
        "Must have five distinct cron syntax values (e.g. <minute> <hour> <day of month> <month> <day of week>)"
      )
      return
    }
    const newSchedule = {
      ...renderSchedule,
      isCronBuilderOpen: true,
      cronSyntax:
        renderSchedule.cronSyntax.trim().length === 0
          ? "*/30 * * * *"
          : renderSchedule.cronSyntax
    }
    setRenderSchedule(newSchedule)
  }

  const doRenderCronSyntaxCheatSheet = () => {
    const newSchedule = {
      ...renderSchedule,
      doRenderCronSyntaxCheatSheetImage:
        !renderSchedule.doRenderCronSyntaxCheatSheetImage
    }
    setRenderSchedule(newSchedule)
  }

  const endDateTimeChange = event => {
    const newSchedule = {
      ...renderSchedule,
      endDateTime: event ? new Date(event) : null
    }
    setRenderSchedule(newSchedule)
    calculateFutureOccurrences(newSchedule)
  }

  const hideRenderScheduleConfirmation = () => {
    const newSchedule = {
      ...renderSchedule,
      isRenderScheduleConfirmationOpen: false
    }
    setRenderSchedule(newSchedule)
  }
  const cronExpressionChange = event => {
    const newSchedule = {
      ...renderSchedule,
      cronSyntax: event,
      isCronBuilderOpen: false
    }
    setRenderSchedule(newSchedule)
    calculateFutureOccurrences(newSchedule)
  }

  const clearEndDateTime = () => {
    const newSchedule = {
      ...renderSchedule,
      endDateTime: null
    }
    setRenderSchedule(newSchedule)
    calculateFutureOccurrences(newSchedule)
  }

  const cancelSchedule = () => {
    const newSchedule = {
      ...renderSchedule,
      isRenderSchedulerOpen: false
    }
    props.setRenderSchedulerOpen(false)
    setRenderSchedule(newSchedule)
  }

  const [isRenderSchedulerOpen, setIsRenderSchedulerOpen] = React.useState(
    props.isMyRenderSchedulerOpen
  )
  const [isLoadingPreview, setIsLoadingPreview] = React.useState(false)

  const toggleScheduler = () => {
    setIsRenderSchedulerOpen(!isRenderSchedulerOpen)
  }

  const displayOutputFeed = () => {
    let outputFeed = filteredFeeds.find(
      feed => feed.id === renderSchedule.outputFeedId
    )
    if (outputFeed) {
      return `${outputFeed.id} - ${outputFeed.name}`
    } else {
      return "No Feed Selected"
    }
  }

  const displayWebhook = () => {
    let outputWebhook = filteredWebhooks.find(
      webhook => webhook.id === renderSchedule.webhookId
    )
    if (outputWebhook) {
      return `${outputWebhook.id} - ${outputWebhook.description}`
    } else {
      return "No Webhook Selected"
    }
  }

  const scheduleFeedEntryCreation = () => {
    const {
      startDateTime,
      endDateTime,
      duplicateHandling,
      outputFeedId,
      contractDeliverableId,
      selectedMatchIds,
      isDebug,
      qaReports,
      isQaAllTriggers,
      useRecurrenceSettings,
      useProductionRenderBots,
      cronSyntax,
      cronTimeZoneIANA,
      isPaused,
      queryResultsIndexRange,
      publishDelayInSeconds,
      narrativeBlock_Ids,
      ignoreKillSwitch,
      webhookId,
      hideFromFeed
    } = renderSchedule

    const narrativeId = props.narrative.id

    setIsLoadingPreview(true)
    let startDateTimeToPass = startDateTime
    if (!doShowStartDateTime && !props.isOnRenderSchedulesPage) {
      // Let the server calculate the start date/time
      startDateTimeToPass = null
    }
    const addOrUpdate = props.isOnRenderSchedulesPage
      ? "update"
      : "add-instructions"
    const payload = props.isOnRenderSchedulesPage
      ? {
          contentId,
          contractDeliverableId,
          cronSyntax,
          debugAllTriggers: !!isQaAllTriggers,
          duplicateHandling,
          endDateTime,
          feedId: outputFeedId,
          id: props.narrativeRenderScheduleId,
          isDebug: isDebug || !!isQaAllTriggers,
          publishDelayInSeconds,
          queryResultsIndexRange,
          runQAReports: !!qaReports,
          startDateTime: startDateTimeToPass,
          useRecurrenceSettings,
          useProductionRenderBots,
          webhookId,
          hideFromFeed
        }
      : {
          narrativeId,
          contentId,
          contentIds: selectedMatchIds,
          contentType,
          isDebug: isDebug || isQaAllTriggers,
          runQAReports: qaReports,
          debugAllTriggers: isQaAllTriggers,
          feedId: outputFeedId,
          contractDeliverableId,
          startDateTime: startDateTimeToPass,
          endDateTime,
          duplicateHandling,
          isPaused,
          useRecurrenceSettings,
          useProductionRenderBots,
          cronSyntax,
          cronTimeZoneIANA,
          queryResultsIndexRange,
          publishDelayInSeconds,
          narrativeBlock_Ids,
          ignoreKillSwitch,
          webhookId,
          hideFromFeed
        }
    put(
      `${globalSettings.apiBaseUrl}/api/narrativerenderschedule/${addOrUpdate}`,
      null,
      payload
    )
      .then(Response => Response && Response.ok === true && Response.json())
      .then(results => {
        if (results) {
          if (results === "Updated") {
            const newSchedule = {
              ...renderSchedule,
              alertMessage: `Render job id #${props.narrativeRenderScheduleId} had been Updated.`,
              isAlertMessageOpen: true,
              isRenderScheduleConfirmationOpen: false
            }
            setRenderSchedule(newSchedule)
          } else {
            const newSchedule = {
              ...renderSchedule,
              isLoadingPreview: false,
              startDateTime: null,
              endDateTime: null,
              duplicateHandling: 1, //Update
              isDebug,
              cronSyntax: "0 */1 * * *", // "default cron syntax"
              queryResultsIndexRange: "",
              outputFeedId: "",
              useRecurrenceSettings: false,
              useProductionRenderBots: false,
              doShowStartDateTime: false,
              isRenderScheduleConfirmationOpen: false,
              isRenderSchedulerOpen: false,
              alertMessage: `Render job id #${results} created.`,
              isAlertMessageOpen: true
            }
            setRenderSchedule(newSchedule)
            props.setAlertMessageOpen &&
              props.setAlertMessageOpen({
                isOpen: newSchedule.isAlertMessageOpen,
                msg: newSchedule.alertMessage
              })
          }
        } else {
          const newSchedule = {
            ...renderSchedule,
            alertMessage: `Something went wrong saving this item. Check the details and try again.`,
            outputFeedId: "",
            isAlertMessageOpen: true
          }
          setRenderSchedule(newSchedule)
          props.setAlertMessageOpen &&
            props.setAlertMessageOpen({
              isOpen: newSchedule.isAlertMessageOpen,
              msg: newSchedule.alertMessage
            })
        }
      })
      .catch(err =>
        console.error(
          "Error on PUT to /api/narrativerenderschedule/add-instructions",
          err
        )
      )
  }

  const onChangeValidation = obj => {
    const myRsFormErrors = {
      ...rsFormErrors,
      [obj.name]: obj.value ? false : true
    }
    setRsFormErrors(myRsFormErrors)
  }

  const validateRenderCreation = () => {
    const {
      startDateTime,
      endDateTime,
      outputFeedId,
      useRecurrenceSettings,
      contractDeliverableId
    } = renderSchedule
    const narrativeId = props.narrative.id
    let foundErrors = false
    let myRsFormErrors = {}
    if (isProductionFeed && isDeliverableRequired && !contractDeliverableId) {
      foundErrors = true
      myRsFormErrors = {
        ...myRsFormErrors,
        contractDeliverableId: true
      }
    }
    if (!contentId) {
      foundErrors = true
      myRsFormErrors = {
        ...myRsFormErrors,
        contentType: true
      }
    }
    let outputFeed = filteredFeeds.find(feed => feed.id === outputFeedId)
    if (!outputFeed || !narrativeId) {
      foundErrors = true
      myRsFormErrors = {
        ...myRsFormErrors,
        outputFeedId: true
      }
    }
    if (foundErrors) {
      setRsFormErrors(myRsFormErrors)
      return
    }
    if (
      startDateTime !== null &&
      startDateTime.getTime() + 60 * 1000 < new Date().getTime()
    ) {
      const newSchedule = {
        ...renderSchedule,
        startDateTime: new Date()
      }
      setRenderSchedule(newSchedule)
      return
    }
    if (
      useRecurrenceSettings &&
      endDateTime !== null &&
      endDateTime.getTime() <
        (startDateTime !== null
          ? startDateTime.getTime()
          : new Date().getTime())
    ) {
      const newSchedule = {
        ...renderSchedule,
        endDateTime: renderSchedule.startDateTime
      }
      setRenderSchedule(newSchedule)
      return
    }
    const newSchedule = {
      ...renderSchedule,
      isRenderScheduleConfirmationOpen: true
    }
    setRenderSchedule(newSchedule)
  }

  const handleBlockIds = event => {
    const newSchedule = {
      ...renderSchedule,
      narrativeBlock_Ids: event.target.value
    }
    setRenderSchedule(newSchedule)
  }

  const filteredQueries =
    queries.filter(
      q =>
        q.get("id") === narrative.queryId ||
        q.get("id") === narrative.refreshQueryId
    ) || []

  const queryOptionsMap = filteredQueries.map(option => {
    let query = option.toJS()
    let queryName = (query.name && query.name.trim()) || ""
    const firstLetter = (queryName[0] && queryName[0].toUpperCase()) || ""
    const isProdQuery = query.id === narrative.queryId
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      combinedName: `${query.id} - ${queryName} ${
        isProdQuery ? "(Prod)" : "(QA)"
      }`,
      ...query
    }
  })
  let validateInternalFeedCurrentlySelected = false
  filteredFeeds.forEach(itm => {
    if (
      itm.id === renderSchedule.outputFeedId &&
      itm.feedStatus === Enums.FeedStatus.VALIDATEINTERNAL
    ) {
      validateInternalFeedCurrentlySelected = true
    }
  })
  let isProductionFeed = false
  filteredFeeds.forEach(itm => {
    if (
      itm.id === renderSchedule.outputFeedId &&
      itm.feedStatus === Enums.FeedStatus.ACTIVE
    ) {
      isProductionFeed = true
    }
  })

  const createRenderPageTitle = () =>
    !props.isOnRenderSchedulesPage ? (
      <h3>Narrative Render</h3>
    ) : (
      <h3>
        {renderSchedule.useRecurrenceSettings
          ? `Narrative Rendering Options (Schedule #
                  ${renderSchedule.narrativeRenderScheduleId})`
          : `Narrative Render (Schedule #
                  ${renderSchedule.narrativeRenderScheduleId})`}
      </h3>
    )

  const createNarrativeInfoTitle = () =>
    !props.isOnRenderSchedulesPage ? (
      <GridContainer spacing={3}>
        <GridItem xs={6}>
          <InputLabel>Narrative:&nbsp;</InputLabel>
          <span
            style={Helpers.buildCustomStyleForNarrativeStatus(
              narrative.narrativeStatusId
            )}
          >
            {narrative.id} - {narrative.familyName || narrative.name}
          </span>
        </GridItem>
        <GridItem xs={6}>
          <InputLabel>Data model:&nbsp;</InputLabel>
          {modelName !== null ? (
            <span>
              Content Type: {contentType}, Content Id: {contentId}
            </span>
          ) : (
            <span>None selected.</span>
          )}
        </GridItem>
      </GridContainer>
    ) : (
      <GridContainer spacing={3}>
        <GridItem xs={12}>
          Narrative:&nbsp;
          <span
            style={Helpers.buildCustomStyleForNarrativeStatus(
              narrative.narrativeStatusId
            )}
          >
            {narrative.id} - {narrative.familyName || narrative.name}
          </span>
        </GridItem>
        <GridItem xs={12}>
          Data model:&nbsp;
          {
            <span>
              Content Type: {contentType}, Content Id: {contentId}
            </span>
          }
        </GridItem>
      </GridContainer>
    )

  const createSelectedMatchesInfo = () =>
    selectedMatchIds &&
    selectedMatchIds.length > 0 && (
      <div>
        <strong>{selectedMatchIds.length}</strong> Matches Selected
        <br />
        <em>({selectedMatchIds.join(", ")})</em>
      </div>
    )

  const createQueryInformation = () =>
    (contentType === "query" || contentType === "Query") && (
      <>
        {!props.isOnRenderSchedulesPage && (
          <GridContainer spacing={3}>
            <GridItem xs={6}>
              <Autocomplete
                required={true}
                variant={rsFormErrors.contentType ? "outlined" : ""}
                id="queryId"
                options={queryOptions.sort(
                  (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                )}
                renderOption={(props, option) => (
                  <li {...props}>{option.combinedName}</li>
                )}
                getOptionLabel={option => (option && option.combinedName) || ""}
                value={contentId || ""}
                onChange={event => {
                  const newQueryId =
                    event.target.innerText &&
                    event.target.innerText.split(" - ")[0]
                  setContentId(newQueryId)
                  onChangeValidation({
                    name: "contentType",
                    value: newQueryId
                  })
                }}
                isOptionEqualToValue={option =>
                  option.id === contentId && Number(contentId)
                }
                inputValue={queryIdDisplayName?.toString() || ""}
                onInputChange={(event, newInputValue) => {
                  if (event) {
                    if (!newInputValue && contentId) {
                      return
                    } else {
                      setQueryDisplayName(newInputValue)
                    }
                  }
                }}
                //onblur={onBlurValidation}
                renderInput={params => (
                  <TextField
                    {...params}
                    required={true}
                    label="Query"
                    margin="normal"
                    error={rsFormErrors.contentType}
                    helperText={
                      rsFormErrors.contentType &&
                      "Select a narrative data model prior to scheduling"
                    }
                    key={params.id}
                    value={params.name}
                    fullWidth
                  />
                )}
                disabled={
                  !props.useRecurrenceSettings && props.isOnRenderSchedulesPage
                }
              />
            </GridItem>
            <GridItem xs={6}>
              <TextField
                type="text"
                label="Query Builder Results Index Range (e.g. 0-4, 8, 20 - 30)"
                id="itemIndex2"
                value={renderSchedule.queryResultsIndexRange}
                onChange={handleQueryResultsIndexRangeChange}
                margin="normal"
                fullWidth
                disabled={
                  !props.useRecurrenceSettings && props.isOnRenderSchedulesPage
                }
              />
            </GridItem>
          </GridContainer>
        )}
        {props.isOnRenderSchedulesPage && (
          <GridContainer spacing={3}>
            <GridItem xs={3}>
              <TextField
                type="number"
                label="QUERY BUILDER QUERY #"
                id="contentId"
                value={contentId}
                onChange={e => this.handleContentIdChangeEvent(e)}
                margin="normal"
                disabled={
                  !props.useRecurrenceSettings && props.isOnRenderSchedulesPage
                }
              />
            </GridItem>
            <GridItem xs={9}>
              <TextField
                type="text"
                label="QUERY BUILDER RESULTS INDEX RANGE (e.g. 0-4, 8, 20 - 30)"
                id="itemIndex2"
                value={renderSchedule.queryResultsIndexRange}
                onChange={handleQueryResultsIndexRangeChange}
                margin="normal"
                min={0}
                fullWidth
                disabled={
                  !props.useRecurrenceSettings && props.isOnRenderSchedulesPage
                }
              />
            </GridItem>
          </GridContainer>
        )}
      </>
    )

  const createStartDateTime = () =>
    !props.isOnRenderSchedulesPage ? (
      <>
        <GridItem xs={3}>
          <Checkbox
            style={{ paddingLeft: 0 }}
            checked={doShowStartDateTime || false}
            id="doShowStartDateTime"
            onChange={() => {
              setDoShowStartDateTime(!doShowStartDateTime)
            }}
            disabled={
              !props.useRecurrenceSettings && props.isOnRenderSchedulesPage
            }
          />{" "}
          Change Start Date/Time?
        </GridItem>
        <GridItem xs={3}>
          {doShowStartDateTime && (
            <>
              <InputLabel
                className={classes.label}
                style={{ fontSize: "10px" }}
              >
                START DATE/TIME
              </InputLabel>
              <Datetime
                initialValue={renderSchedule.startDateTime}
                dateFormat={"M/D/YYYY"}
                timeFormat={"h:mm A (Z zz)"}
                onChange={startDateTimeChange}
                inputProps={{
                  placeholder: "Start date/time in the Pacific Time Zone",
                  style: {
                    font: "inherit",
                    color: "black",
                    marginTop: "-7px"
                  },
                  readOnly: true
                }}
                isValidDate={current =>
                  current.isAfter(Datetime.moment().subtract(1, "day"))
                }
              />
            </>
          )}
        </GridItem>
      </>
    ) : (
      <>
        <GridItem xs={6}>
          <InputLabel className={classes.label} style={{ fontSize: "10px" }}>
            START DATE/TIME
          </InputLabel>
          <Datetime
            initialValue={renderSchedule.startDateTime}
            dateFormat={"M/D/YYYY"}
            timeFormat={"h:mm A (Z zz)"}
            onChange={startDateTimeChange}
            inputProps={{
              placeholder: "Start date/time in the Pacific Time Zone",
              style: {
                font: "inherit",
                color: "black",
                marginTop: "-7px"
              },
              readOnly: true
            }}
            isValidDate={current =>
              current.isAfter(Datetime.moment().subtract(1, "day"))
            }
          />
        </GridItem>
      </>
    )

  const queryOptions = queryOptionsMap && queryOptionsMap.toJS()

  React.useEffect(() => {
    let queryDisplayName = contentId
    if (contentId && queryOptions && queryOptions.length > 0) {
      let queryOption = queryOptions.find(q => q.id === contentId)
      if (queryOption) {
        queryDisplayName = queryOption.combinedName
      }
    }
    setQueryDisplayName(queryDisplayName)
  }, [queryOptions])

  React.useEffect(() => {
    if (props.useRecurrenceSettings) {
      calculateFutureOccurrences(renderSchedule)
    }
  }, [])

  const orgInfo =
    organizations
      .toJS()
      .filter(
        org =>
          org.id === narrative?.organization?.id ||
          org.properties?.filter(itm => itm.id === narrative?.organization?.id)
            .length > 0
      ) || []
  const isDeliverableRequired = orgInfo[0]?.isDeliverableRequired || false

  return (
    <div>
      <IconButton
        aria-label="Narrative Render Scheduler 2"
        onClick={() => toggleScheduler()}
        disabled={!narrativeLoaded}
        size="large"
      >
        <Schedule />
      </IconButton>
      <Modal
        open={renderSchedule.isRenderSchedulerOpen}
        onClose={toggleScheduler}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Card className={classes.scheduleOutputModal}>
          <CardBody>
            {renderSchedule.isRenderSchedulerOpen &&
              renderSchedule.isRenderScheduleConfirmationOpen && (
                <Dialog
                  onClose={props.hideShareDialog}
                  aria-labelledby="customized-dialog-title"
                  open={renderSchedule.isRenderScheduleConfirmationOpen}
                  maxWidth="sm"
                  fullWidth={true}
                >
                  <DialogTitle id="alert-dialog-title">
                    Confirmation
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <p>
                        A narrative render schedule job will be created with the
                        following criteria:
                      </p>
                      <h6>Narrative</h6>
                      <p>
                        {narrative.name} ({narrative.id})
                      </p>
                      <h6>Data Model</h6>
                      <p>
                        {modelName} - (Content Type: {contentType}, Content Id:{" "}
                        {contentId})
                      </p>
                      <h6>Query Builder Results Index Range</h6>
                      <p>
                        {!renderSchedule.queryResultsIndexRange
                          ? "N/A"
                          : renderSchedule.queryResultsIndexRange}
                      </p>
                      <h6>Feed</h6>
                      <p>{displayOutputFeed()}</p>
                      <h6>Webhook</h6>
                      <p>{displayWebhook()}</p>
                      <h6>Hide From Feed</h6>
                      <p>{renderSchedule.hideFromFeed ? "Yes" : "No"}</p>
                      <div
                        class={
                          Number(renderSchedule.duplicateHandling) !== 0 &&
                          Number(renderSchedule.duplicateHandling) !== 1
                            ? classes.highliteCreateMode
                            : ""
                        }
                      >
                        <h6>Duplicate Handling</h6>
                        <p>
                          {Number(renderSchedule.duplicateHandling) === 0
                            ? "Skip"
                            : Number(renderSchedule.duplicateHandling) === 1
                            ? "Update"
                            : "Create - ***Running in Create mode ***"}
                        </p>
                      </div>
                      <h6>Start Date/Time</h6>
                      <p>
                        {(doShowStartDateTime ||
                          props.isOnRenderSchedulesPage) &&
                        renderSchedule.startDateTime.getTime() -
                          new Date().getTime() >
                          300000 ? (
                          <TimeAgo
                            date={renderSchedule.startDateTime}
                            title={Helpers.prettyDateTimeinPacificTimeZone(
                              renderSchedule.startDateTime
                            )}
                          />
                        ) : (
                          "ASAP"
                        )}
                      </p>
                      <h6>Publish Delay</h6>
                      <p>
                        {
                          Helpers.loadPublishDelayOptions().find(
                            n => n.key === renderSchedule.publishDelayInSeconds
                          ).value
                        }
                      </p>
                      <h6>Is Debug Mode?</h6>
                      <p>{!renderSchedule.isDebug ? "No" : "Yes"}</p>
                      <h6>Run QA Reports?</h6>
                      <p>{!renderSchedule.qaReports ? "No" : "Yes"}</p>
                      <h6>Run All Triggers?</h6>
                      <p>{!renderSchedule.isQaAllTriggers ? "No" : "Yes"}</p>
                      {validateInternalFeedCurrentlySelected && (
                        <>
                          <h6>Use Production Render Bots?</h6>
                          <p>
                            {!renderSchedule.useProductionRenderBots
                              ? "No"
                              : "Yes"}
                          </p>
                        </>
                      )}
                      <h6>
                        {renderSchedule.narrativeBlock_Ids &&
                          "Narrative Blocks to be QA'd:"}
                      </h6>
                      <p>{renderSchedule.narrativeBlock_Ids}</p>
                      <h6>Use recurrence settings?</h6>
                      <p>
                        {!renderSchedule.useRecurrenceSettings ? "No" : "Yes"}
                      </p>
                      <h6>
                        Future occurrence(s):{" "}
                        <span style={{ fontSize: "10px", color: "grey" }}>
                          (Up to {renderSchedule.maxFutureOccurrenceCount}{" "}
                          displayed.)
                        </span>
                      </h6>
                      <p>
                        <span
                          style={{
                            height: "175px",
                            overflow: "auto",
                            display: "block"
                          }}
                        >
                          {renderSchedule.futureOccurrences &&
                            renderSchedule.futureOccurrences.length < 1 &&
                            "None, based on the criteria you defined."}
                          <ol>
                            {renderSchedule.futureOccurrences &&
                              renderSchedule.futureOccurrences.map(
                                (val, key) => (
                                  <li
                                    key={key}
                                    style={{
                                      textDecoration:
                                        val < new Date()
                                          ? "line-through"
                                          : "none"
                                    }}
                                  >
                                    {Helpers.prettyDateTimeinPacificTimeZone(
                                      val
                                    )}{" "}
                                    (
                                    <TimeAgo
                                      date={val}
                                      title={Helpers.prettyDateTimeinPacificTimeZone(
                                        val
                                      )}
                                    />
                                    )
                                  </li>
                                )
                              )}
                          </ol>
                          {renderSchedule.futureOccurrencesMessage &&
                            renderSchedule.futureOccurrencesMessage}
                        </span>
                      </p>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={hideRenderScheduleConfirmation}
                      color="secondary"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => scheduleFeedEntryCreation()}
                      color="primary"
                      autoFocus
                      disabled={isLoadingPreview}
                    >
                      Yes, schedule it
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
            {createRenderPageTitle()}
            {createSelectedMatchesInfo()}
            {createNarrativeInfoTitle()}
            {createQueryInformation()}
            <GridContainer spacing={3} className={classes.fieldSpacing}>
              <GridItem xs={6}>
                <FormControl
                  fullWidth
                  error={rsFormErrors.outputFeedId}
                  required={true}
                >
                  <InputLabel>OUTPUT TO FEED</InputLabel>
                  {filteredFeeds && filteredFeeds.length > 0 && (
                    <Select
                      label="OUTPUT TO FEED"
                      fullWidth={false}
                      type="select"
                      name="outputFeedId"
                      id="outputFeedId"
                      defaultValue={""}
                      onChange={handleChange}
                      value={renderSchedule.outputFeedId}
                      disabled={
                        !props.useRecurrenceSettings &&
                        props.isOnRenderSchedulesPage
                      }
                    >
                      <MenuItem key="none" value="">
                        None
                      </MenuItem>
                      {filteredFeeds &&
                        filteredFeeds.length > 0 &&
                        filteredFeeds.map(feed => (
                          <MenuItem
                            //variant="outlined"

                            key={feed.id}
                            value={feed.id}
                            style={Helpers.buildCustomStyleForFeedStatus(
                              feed.feedStatus
                            )}
                          >
                            {feed.id} - {feed.name} (Status:&nbsp;
                            {Helpers.convertFeedStatusIdToStatusName(
                              feed.feedStatus
                            )}
                            )
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                  {rsFormErrors.outputFeedId && (
                    <FormHelperText className={classes.requiredMsg}>
                      Choose a feed
                    </FormHelperText>
                  )}
                </FormControl>
                {(!filteredFeeds || filteredFeeds.length === 0) && (
                  <div
                    style={{
                      position: "relative",
                      padding: "10px 0",
                      bottom: "-13px"
                    }}
                    title="Loading....."
                  >
                    <LinearProgress />
                  </div>
                )}
              </GridItem>
              <GridItem xs={3}>
                <FormControl fullWidth>
                  <InputLabel title="How should the system handle Duplicate Feed Entries?">
                    DUPLICATE HANDLING
                  </InputLabel>
                  <Select
                    fullWidth={false}
                    label="DUPLICATE HANDLING"
                    type="select"
                    name="duplicateHandling"
                    id="duplicateHandling"
                    value={
                      renderSchedule.duplicateHandling ||
                      narrative.duplicateHandling ||
                      "0"
                    }
                    onChange={handleChange}
                    disabled={
                      !props.useRecurrenceSettings &&
                      props.isOnRenderSchedulesPage
                    }
                  >
                    <MenuItem key="skip" value="0">
                      Skip
                    </MenuItem>
                    <MenuItem key="update" value="1">
                      Update
                    </MenuItem>
                    <MenuItem key="create" value="2">
                      Create
                    </MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={3}>
                <FormControl fullWidth>
                  <InputLabel>PUBLISH DELAY</InputLabel>
                  <Select
                    fullWidth={false}
                    label="PUBLISH DELAY"
                    type="select"
                    name="publishDelayInSeconds"
                    id="publishDelayInSeconds"
                    defaultValue={""}
                    onChange={handleChange}
                    value={renderSchedule.publishDelayInSeconds}
                    disabled={
                      !props.useRecurrenceSettings &&
                      props.isOnRenderSchedulesPage
                    }
                  >
                    {Helpers.loadPublishDelayOptions().map(n => (
                      <MenuItem key={n.key} value={n.key}>
                        {n.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer spacing={3} className={classes.fieldSpacing}>
              <GridItem xs={6}>
                <FormControl fullWidth>
                  <InputLabel>OUTPUT TO WEBHOOK</InputLabel>
                  <Select
                    label="OUTPUT TO WEBHOOK"
                    type="select"
                    id="webhookId"
                    name="webhookId"
                    defaultValue={""}
                    value={renderSchedule.webhookId || ""}
                    onChange={handleChange}
                    disabled={
                      !props.useRecurrenceSettings &&
                      props.isOnRenderSchedulesPage
                    }
                  >
                    <MenuItem key="none" value="">
                      None
                    </MenuItem>
                    {filteredWebhooks.map(webhook => (
                      <MenuItem
                        key={webhook.id}
                        value={webhook.id}
                        style={Helpers.buildCustomStyleForWebhookStatus(
                          webhook.webhookStatusId
                        )}
                      >
                        {webhook.id} - {webhook.description} (Status:&nbsp;
                        {Helpers.convertWebhookStatusIdToStatusName(
                          webhook.webhookStatusId
                        )}
                        )
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText
                    variant="standard"
                    className={classes.helperText}
                  >
                    webhook requires a feed selection
                    <WebhookInfoDialog />
                  </FormHelperText>
                </FormControl>
              </GridItem>
              <GridItem xs={6}>
                <FormControlLabel
                  className={classes.styledSwitch}
                  control={
                    <Switch
                      aria-label="NarrRenderSched Toggle HideFromFeed"
                      id="hideFromFeed"
                      checked={renderSchedule.hideFromFeed}
                      onChange={checkboxChange}
                      color="primary"
                      size="small"
                    />
                  }
                  label="Hide From Feed?"
                />
              </GridItem>
            </GridContainer>
            <GridContainer spacing={3} className={classes.fieldSpacing}>
              <GridItem xs={6}>
                <Autocomplete
                  aria-label="NarrRenderSched Autcomplete ContractDelDropdown"
                  required={true}
                  options={
                    contractDeliverables?.sort(
                      (a, b) => -b.name.localeCompare(a.name)
                    ) || []
                  }
                  getOptionLabel={option =>
                    `${option.id} - ${option.name} Type Id: ${option.articleTypeId} League Id: ${option.leagueId}`
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={
                    contractDeliverables.find(
                      option =>
                        option.id === renderSchedule.contractDeliverableId
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    handleChange({
                      target: {
                        id: "contractDeliverableId",
                        value: newValue ? newValue.id : ""
                      }
                    })
                  }}
                  renderOptions={(props, option) => (
                    <li {...props}>
                      {`${option.id} - ${option.name} Type Id:
                      ${option.articleTypeId} League Id:
                      ${option.leagueId}`}
                    </li>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="SELECT RELATED CONTRACT DELIVERABLE"
                      required={true}
                      margin="normal"
                      name="contractDeliverableId"
                      id="contractDeliverableId"
                      error={
                        isProductionFeed && rsFormErrors.contractDeliverableId
                      }
                      helperText={
                        rsFormErrors.contractDeliverableId &&
                        "Contract Deliverable is Required"
                      }
                      disabled={
                        !isProductionFeed ||
                        (!props.useRecurrenceSettings &&
                          props.isOnRenderSchedulesPage)
                      }
                    />
                  )}
                />
                {isDeliverableRequired &&
                  (!contractDeliverables ||
                    contractDeliverables.length === 0) && (
                    <div
                      style={{
                        position: "relative"
                      }}
                      title="Loading....."
                    >
                      <LinearProgress />
                    </div>
                  )}
              </GridItem>
              {createStartDateTime()}
            </GridContainer>
            <GridContainer spacing={3}>
              <GridItem md={6}>
                <GridContainer spacing={3} className={classes.fieldSpacing}>
                  <GridItem xs={6}>
                    <Checkbox
                      style={{ paddingLeft: 0 }}
                      checked={renderSchedule.useRecurrenceSettings || false}
                      id="useRecurrenceSettings"
                      onChange={checkboxChange}
                      disabled={
                        !props.useRecurrenceSettings &&
                        props.isOnRenderSchedulesPage
                      }
                    />{" "}
                    Use Recurrence Settings?
                  </GridItem>
                  <GridItem xs={6}>
                    {renderSchedule.useRecurrenceSettings && (
                      <React.Fragment>
                        <TextField
                          type="text"
                          label="CRON SYNTAX"
                          id="cronSyntax"
                          value={renderSchedule.cronSyntax}
                          disabled={!renderSchedule.useRecurrenceSettings}
                          onChange={cronSyntaxChange}
                        />
                        <Tooltip title="Build cron syntax" placement="top">
                          <Button
                            justIcon
                            round
                            simple
                            color="primary"
                            className="info"
                            onClick={cronSyntaxTrimChange}
                            disabled={!renderSchedule.useRecurrenceSettings}
                          >
                            <Build />
                          </Button>
                        </Tooltip>
                        <Button
                          justIcon
                          round
                          simple
                          color="primary"
                          className="info"
                          onClick={doRenderCronSyntaxCheatSheet}
                          disabled={!renderSchedule.useRecurrenceSettings}
                        >
                          <Help />
                        </Button>
                        {renderSchedule.doRenderCronSyntaxCheatSheetImage && (
                          <modal
                            style={{
                              position: "absolute",
                              zIndex: "1",
                              marginTop: "-115px",
                              marginLeft: "36px"
                            }}
                          >
                            <Card>
                              <CardBody>
                                <div>
                                  <img
                                    src="/images/cron-diagram-expression.png"
                                    style={{ width: "200px" }}
                                    alt=""
                                  />
                                </div>
                              </CardBody>
                            </Card>
                          </modal>
                        )}
                      </React.Fragment>
                    )}
                  </GridItem>
                </GridContainer>
                {renderSchedule.useRecurrenceSettings && (
                  <GridContainer style={{ padding: 0 }} alignItems="flex-end">
                    <Modal
                      open={renderSchedule.isCronBuilderOpen}
                      onClose={renderSchedule.toggleCronBuilderModal}
                    >
                      <Card className={classes.cronBuilder}>
                        <CardBody>
                          <CronBuilder
                            cronExpression={renderSchedule.cronSyntax}
                            onChange={cronExpressionChange}
                            showResult={true}
                          />
                        </CardBody>
                      </Card>
                    </Modal>
                    <GridItem xs={11} style={{ padding: 0, marginTop: "20px" }}>
                      <InputLabel
                        className={classes.label}
                        style={{ fontSize: "10px", margin: 0 }}
                      >
                        END DATE/TIME
                      </InputLabel>
                      <Datetime
                        style={{
                          marginTop: "-14px",
                          font: "inherit",
                          color: "black"
                        }}
                        initialValue={renderSchedule.endDateTime}
                        dateFormat={"M/D/YYYY"}
                        timeFormat={"h:mm A (Z zz)"}
                        onChange={endDateTimeChange}
                        inputProps={{
                          placeholder: "Leave empty to repeat forever",
                          disabled: !renderSchedule.useRecurrenceSettings,
                          readOnly: true,
                          style: {
                            font: "inherit",
                            color: renderSchedule.useRecurrenceSettings
                              ? "black"
                              : "grey"
                          }
                          //ref: endDateTimeInputRef
                        }}
                        isValidDate={current =>
                          current.isAfter(
                            Datetime.moment(renderSchedule.startDateTime)
                              .subtract(1)
                              .subtract(1, "day")
                          )
                        }
                      />
                    </GridItem>
                    <GridItem style={{ padding: 0 }} xs={1}>
                      <Tooltip title="Clear the end date/time" placement="top">
                        <Button
                          justIcon
                          round
                          simple
                          color="primary"
                          className="info"
                          onClick={clearEndDateTime}
                          disabled={!renderSchedule.useRecurrenceSettings}
                        >
                          <Clear />
                        </Button>
                      </Tooltip>
                    </GridItem>
                  </GridContainer>
                )}
              </GridItem>
              <GridItem md={6}>
                {renderSchedule.useRecurrenceSettings && (
                  <>
                    <h5>
                      Future Occurrence(s) :{" "}
                      <span style={{ fontSize: "10px", color: "grey" }}>
                        (Up to {renderSchedule.maxFutureOccurrenceCount}{" "}
                        displayed.)
                      </span>{" "}
                    </h5>
                    <div style={{ height: "300px", overflow: "auto" }}>
                      {renderSchedule.futureOccurrences &&
                        renderSchedule.futureOccurrences.length < 1 &&
                        "None, based on the criteria you defined."}
                      <ol>
                        {renderSchedule.futureOccurrences &&
                          renderSchedule.futureOccurrences.map((val, key) => (
                            <li
                              key={key}
                              style={{
                                textDecoration:
                                  val < new Date() ? "line-through" : "none"
                              }}
                            >
                              {Helpers.prettyDateTimeinPacificTimeZone(val)} (
                              <TimeAgo
                                date={val}
                                title={Helpers.prettyDateTimeinPacificTimeZone(
                                  val
                                )}
                              />
                              )
                            </li>
                          ))}
                      </ol>
                      {renderSchedule.futureOccurrencesMessage}
                    </div>
                  </>
                )}
              </GridItem>
            </GridContainer>
            {validateInternalFeedCurrentlySelected && (
              <>
                <GridContainer spacing={3}>
                  <GridItem>
                    <Tooltip
                      id="tooltip-top"
                      title={narrative.isArchived ? `QA all triggers` : ``}
                      placement="top"
                      arrow
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={renderSchedule.isQaAllTriggers || false}
                            id="isQaAllTriggers"
                            onChange={checkboxChange}
                            disabled={renderSchedule.isDebug}
                          />
                        }
                        label=" QA All Triggers?"
                      />
                    </Tooltip>
                  </GridItem>
                  <GridItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={renderSchedule.ignoreKillSwitch || false}
                          id="ignoreKillSwitch"
                          onChange={checkboxChange}
                        />
                      }
                      label="Ignore All Kill Switches?"
                    />
                  </GridItem>
                  {filteredFeeds?.find(
                    feed =>
                      feed.id === renderSchedule.outputFeedId &&
                      feed.feedStatus === 4
                  ) && (
                    <GridItem>
                      <Tooltip
                        id="tooltip-top"
                        title={
                          narrative.isArchived ? `Render all sentences` : ``
                        }
                        placement="top"
                        arrow
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={renderSchedule.isDebug || false}
                              id="isDebug"
                              onChange={checkboxChange}
                              disabled={renderSchedule.isQaAllTriggers}
                            />
                          }
                          label=" QA Render?"
                        />
                      </Tooltip>
                    </GridItem>
                  )}
                  {renderSchedule.isDebug && (
                    <GridItem>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={renderSchedule.qaReports || false}
                            id="qaReports"
                            onChange={checkboxChange}
                          />
                        }
                        label=" Run QA Reports?"
                      />
                    </GridItem>
                  )}
                  {renderSchedule.isDebug && renderSchedule.qaReports && (
                    <GridItem xs={4}>
                      <TextField
                        label="QA block ID - if multiple needed, separate by commas"
                        helperText="*if running full narrative, leave this blank"
                        checked={renderSchedule.qaReports || false}
                        id="narrativeBlock_Ids"
                        onChange={handleBlockIds}
                        fullWidth
                      />
                    </GridItem>
                  )}
                </GridContainer>
                <GridContainer spacing={3}>
                  <GridItem>
                    <Tooltip
                      id="tooltip-top"
                      title={"Use Production Render Bots"}
                      placement="top"
                      arrow
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              renderSchedule.useProductionRenderBots || false
                            }
                            id="useProductionRenderBots"
                            onChange={e => {
                              if (!renderSchedule.useProductionRenderBots) {
                                setDialogWarningMessage(
                                  "Are you sure want to use Production render bots on this render schedule?"
                                )
                                setDialogWarningOpen(true)
                              } else {
                                checkboxChange(e)
                              }
                            }}
                            disabled={renderSchedule.isDebug}
                          />
                        }
                        label=" Use Production Render Bots?"
                      />
                    </Tooltip>
                  </GridItem>
                </GridContainer>
              </>
            )}
          </CardBody>
          <CardFooter className={classes.cardFooter}>
            <Button
              color="secondary"
              onClick={cancelSchedule}
              title="Do nothing"
            >
              {!props.useRecurrenceSettings && props.isOnRenderSchedulesPage
                ? `Close`
                : `Cancel`}
            </Button>
            &nbsp;
            {((props.isOnRenderSchedulesPage && props.useRecurrenceSettings) ||
              !props.isOnRenderSchedulesPage) && (
              <Button
                color="primary"
                onClick={() => validateRenderCreation()}
                title="Schedule the creation of a feed entry for this narrative and the selected data model"
                //disabled={!renderSchedule.outputFeedId}
              >
                Add to Render
              </Button>
            )}
          </CardFooter>
        </Card>
      </Modal>
      <Modal
        open={renderSchedule.isAlertMessageOpen}
        onClose={() => {
          const newSchedule = {
            ...renderSchedule,
            alertMessage: ``,
            isAlertMessageOpen: false
          }
          setRenderSchedule(newSchedule)
          props.setRenderSchedulerOpen(false)
          props.reloadNarrativeRenderSchedules()
        }}
      >
        <Card className={classes.alertMessageModal}>
          <CardHeader>
            <h3>Alert</h3>
          </CardHeader>
          <CardBody>
            <h5>{renderSchedule.alertMessage}</h5>
          </CardBody>
        </Card>
      </Modal>
    </div>
  )
}

NarrativeRenderScheduler.propTypes = {
  narrative: PropTypes.object,
  narrativeLoaded: PropTypes.bool,
  debug: PropTypes.bool,
  modelName: PropTypes.string,
  contentId: PropTypes.number,
  contentType: PropTypes.string,
  selectedMatchIds: PropTypes.array,
  hideShareDialog: PropTypes.func,
  setRenderSchedulerOpen: PropTypes.func,
  reloadNarrativeRenderSchedules: PropTypes.func,
  setAlertMessageOpen: PropTypes.func,
  isMyRenderSchedulerOpen: PropTypes.bool,
  isOnRenderSchedulesPage: PropTypes.bool,
  narrativeRenderScheduleId: PropTypes.number,
  outputFeedId: PropTypes.number,
  contractDeliverableId: PropTypes.number,
  webhookId: PropTypes.number,
  duplicateHandling: PropTypes.number,
  publishDelayInSeconds: PropTypes.number,
  cronSyntax: PropTypes.string,
  endDateTime: PropTypes.string,
  startDateTime: PropTypes.string,
  useRecurrenceSettings: PropTypes.bool,
  useProductionRenderBots: PropTypes.bool,
  isDeliverableRequired: PropTypes.bool,
  hideFromFeed: PropTypes.bool
}
export default NarrativeRenderScheduler
