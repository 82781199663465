export function checkUserPermissions(props) {
  return prop => {
    if (
      prop.name === "Dashboard" &&
      props.accountPermissions.CanSeeDashboard !== "Yes"
    ) {
      return null
    }
    if (prop.name === "QA") {
      if (props.accountPermissions.CanSeeQADashboard !== "Yes") {
        return null
      } else {
        //Check Views
        if (props.accountPermissions.CanSeeReportBuilder !== "Yes") {
          prop.views = prop.views.filter(v => v.name !== "Report Builder")
        }
        if (props.accountPermissions.CanSeeReportViewer !== "Yes") {
          prop.views = prop.views.filter(v => v.name !== "Report Viewer")
        }
        if (props.accountPermissions.CanSeeQAReports !== "Yes") {
          prop.views = prop.views.filter(v => v.name !== "QA Reports")
        }
      }
    }

    if (prop.name === "Wiki" && props.accountPermissions.CanSeeWiki !== "Yes") {
      return null
    }
    if (
      prop.name === "Search" &&
      props.accountPermissions.CanSeeSearch !== "Yes"
    ) {
      return null
    }

    if (
      prop.name === "Narrative Manager" &&
      props.accountPermissions.CanSeeNarrativeBuilder !== "Yes"
    ) {
      return null
    }
    if (
      prop.name === "Feed Manager" &&
      props.accountPermissions.CanSeeFeedManager !== "Yes"
    ) {
      return null
    }
    if (
      prop.name === "Account Manager" &&
      props.accountPermissions.CanSeeAccountManager !== "Yes"
    ) {
      return null
    }
    if (
      prop.name === "Knowledge Base" &&
      props.accountPermissions.CanSeeSiteInfo !== "Yes"
    ) {
      return null
    }
    if (
      prop.name === "File Manager" &&
      props.accountPermissions.CanSeeFileManager !== "Yes"
    ) {
      return null
    }
    if (
      prop.name === "Sports Manager" &&
      props.accountPermissions.CanSeeSportsManager !== "Yes"
    ) {
      return null
    }
    if (prop.name === "Tools") {
      if (props.accountPermissions.CanSeeTools !== "Yes") {
        return null
      }
      //Check Views
      if (props.accountPermissions.CanSeeCacheManager !== "Yes") {
        prop.views = prop.views.filter(v => v.name !== "Cache Manager")
      }
    }

    if (
      prop.name === "SiteInfo" &&
      props.accountPermissions.CanSeeSiteInfo !== "Yes"
    ) {
      return null
    }
    if (
      prop.name === "Sandbox Pages" &&
      props.accountPermissions.CanSeeSandboxPages !== "Yes"
    ) {
      return null
    }
    if (
      prop.name === "Sandbox" &&
      props.accountPermissions.CanSeeSandbox !== "Yes"
    ) {
      return null
    }
    if (prop.name === "FAQs" && props.accountPermissions.CanSeeFAQs !== "Yes") {
      return null
    }
    if (
      prop.name === "Internal Web APIs" &&
      props.accountPermissions.CanSeeInternalWebAPI !== "Yes"
    ) {
      return null
    }
    if (
      prop.name === "External Web APIs" &&
      props.accountPermissions.CanSeeExternalWebAPI !== "Yes"
    ) {
      return null
    }
    if (
      prop.name === "Application Services" &&
      props.accountPermissions.CanSeeServicesList !== "Yes"
    ) {
      return null
    }
    if (
      prop.name === "Webhook Manager" &&
      props.accountPermissions.CanSeeWebhookManager !== "Yes"
    ) {
      return null
    }
    if (
      prop.name === "CTA Manager" &&
      props.accountPermissions.canseectav2 !== "Yes" //Lowercase from FeatureFlag
    ) {
      return null
    }
    if (
      prop.name === "Graphics Toolv2" &&
      props.accountPermissions.canseegraphicstoolv2 !== "Yes" //Lowercase from FeatureFlag
    ) {
      return null
    }
    if (
      prop.name === "Real Time" &&
      props.accountPermissions.canseerealtime !== "Yes" //Lowercase from FeatureFlag
    ) {
      return null
    }
    // More checks ...
    return true
  }
}
