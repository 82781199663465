import { action, observable, decorate, computed } from "mobx"
import sportsDAO from "../daos/sportsDAO"
import Helpers from "tools/Helpers"

export default class SportsStore {
  rootStore
  isLoading = false
  leagues = []
  leagueDetailsById = {}

  constructor(rootStore) {
    this.rootStore = rootStore
    this.dehydrate()
  }

  /**
   * Computeds
   */

  get sortedLeaguesById() {
    return this.leagues.slice().sort(function (a, b) {
      return a.id - b.id
    })
  }

  get sortedLeaguesByName() {
    return this.leagues.slice().sort(Helpers.sortByName)
  }

  /**
   * Methods
   */
  retrieveLeagueDetails = async id => {
    if (id in this.leagueDetailsById) {
      return this.leagueDetailsById[id]
    }
    try {
      this.setIsLoading(true)
      const league = await sportsDAO.getLeague(id)
      this.addLeagueDetailsToMap(id, league.content)
      return league.content
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setIsLoading(false)
    }
  }

  retrieveLeagues = async () => {
    try {
      if (!this.leagues?.length) {
        this.setIsLoading(true)
        const webResponse = await sportsDAO.getLeagues()
        const { leagues = [] } = webResponse?.content
        this.setLeagues(leagues)
      }
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setIsLoading(false)
    }
  }

  /**
   * Internal Actions
   */
  dehydrate() {
    this.setLeagues([])
    this.resetLeagueDetailsMap()
    this.setIsLoading(false)
  }

  setIsLoading(value) {
    this.isLoading = value
  }

  setLeagues(value) {
    this.leagues = value
  }

  resetLeagueDetailsMap() {
    this.leagueDetailsById = {}
  }

  addLeagueDetailsToMap(id, value) {
    this.leagueDetailsById[id] = value
  }
}

/**
 * object decorators
 */
decorate(SportsStore, {
  // computeds
  sortedLeaguesById: computed,
  sortedLeaguesByName: computed,

  // observables
  leagues: observable,
  leagueDetailsById: observable,
  seasons: observable,
  matches: observable,
  isLoading: observable,

  // actions
  setLeagues: action.bound,
  setIsLoading: action.bound,
  addLeagueDetailsToMap: action.bound,
  resetLeagueDetailsMap: action.bound
})
