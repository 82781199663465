import React from "react"
import PropTypes from "prop-types"

import withStyles from "@mui/styles/withStyles"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"

// @mui/icons-material
import ExpandMore from "@mui/icons-material/ExpandMore"

import accordionStyle from "assets/jss/material-dashboard-pro-react/components/accordionStyle"

class DSAccordion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: props.active
    }
  }
  handleChange = panel => (event, expanded) => {
    this.setState({
      active: expanded ? panel : -1
    })
  }
  render() {
    const { classes, collapses, noIcon, overrideStyle } = this.props
    return (
      <div className={classes.root}>
        {collapses.map((prop, key) => (
          <Accordion
            expanded={this.props.active === key || prop.active}
            onChange={this.handleChange(key)}
            TransitionProps={{ unmountOnExit: true }}
            style={overrideStyle && overrideStyle.accordion}
            key={key}
            classes={{
              root: classes.Accordion,
              expanded: classes.AccordionExpanded
            }}
          >
            <AccordionSummary
              expandIcon={noIcon ? <></> : <ExpandMore />}
              style={overrideStyle && overrideStyle.accordionSummary}
              classes={{
                root: classes.AccordionSummary,
                expanded: classes.AccordionSummaryExpaned,
                content: classes.AccordionSummaryContent,
                expandIcon: classes.AccordionSummaryExpandIcon
              }}
            >
              <h4 className={classes.title}>{prop.title}</h4>
            </AccordionSummary>
            <AccordionDetails
              className={classes.AccordionDetails}
              style={{ display: "block", padding: "0" }}
            >
              {prop.content}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    )
  }
}

DSAccordion.defaultProps = {
  active: -1
}

DSAccordion.propTypes = {
  classes: PropTypes.object.isRequired,
  // index of the default active collapse
  active: PropTypes.number,
  collapses: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.node
    })
  ).isRequired
}

export default withStyles(accordionStyle)(DSAccordion)
