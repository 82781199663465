import React, { useEffect, useState } from "react"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Heading from "components/Heading/Heading"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import FeedEntryDAO from "daos/feedEntryDAO"
import Button from "components/CustomButtons/Button"
import { makeStyles } from "@mui/styles"
import { globalSettings } from "variables/general.jsx"
import { Checkbox } from "@mui/material"
import { FormControlLabel } from "@mui/material"
import { LinearProgress } from "@mui/material"

const useStyles = makeStyles({
  infoHeader: {
    textAlign: "left",
    color: "#inherit"
  },
  tagInfo: {
    fontWeight: "bold",
    fontSize: "18px"
  }
})

const CTAGeoPrototype = props => {
  const [isFeedEntryLoading, setIsFeedEntryLoading] = useState(false)
  const [isGeoInfoLoading, setIsGeoInfoLoading] = useState(false)
  const [whatWasLoaded, setWhatWasLoaded] = useState(0)
  const [feedLoaded, setFeedLoaded] = useState(false)
  const [feedEntry, setFeedEntry] = useState({})
  const [showVerbose, setShowVerbose] = useState(false)

  const classes = useStyles()
  const addTagsToContent = () => {
    const feedContentArea = document.getElementById("allFeedContent")
    if (feedContentArea) {
      const h2s = feedContentArea.getElementsByTagName("h2")
      if (h2s.length && h2s[1]) {
        const newDiv = document.createElement("div")
        newDiv.style.cssText =
          "font-size:18px;border:1px solid;display:inline-block;padding:5px;font-weight:bold;background:#ddd;border-radius:5px;"
        newDiv.innerHTML = window.infoObj?.Template || ""
        h2s[1].parentNode.insertBefore(newDiv, h2s[1])
      }
    }
  }

  const makeJScall = () => {
    const script = document.createElement("script")
    const random = Math.floor(Math.random() * 10000000)
    //script.src = `${globalSettings.apiBaseUrl}/api/embed/js/locationCTA?ot=18CSA60&v=${random}` //help
    script.src = `${
      globalSettings.monetizationv2BaseUrl
    }/api/geoprototype/locationCTA${
      whatWasLoaded ? "" : "/3"
    }?ot=18CSA60&v=${random}`
    script.async = (document.head || document.body).appendChild(script)
    script.onload = () => {
      setIsGeoInfoLoading(false)
      addTagsToContent()
    }
    //setIsGeoInfoLoading(false)
  }

  const fetchFeedEntryInfo = entryId => {
    setIsFeedEntryLoading(true)
    FeedEntryDAO.getFeedEntryInfo(entryId ? entryId : 2432056).then(result => {
      if (!result) {
        setIsFeedEntryLoading(false)
        return
      }
      setWhatWasLoaded(entryId)
      setFeedEntry(result)
      setIsFeedEntryLoading(false)
      //etIsGeoInfoLoading(false)
      setFeedLoaded(true)
    })
  }
  const showFeedHTML = content => (
    <div>
      <div className={classes.infoHeader}>
        {isGeoInfoLoading && <LinearProgress />}
        <div id="dynamicContentForTag" className={classes.tagInfo}>
          Loading GEO info...
        </div>

        <div
          id="allFeedContent"
          dangerouslySetInnerHTML={{
            __html: content
          }}
          style={{ paddingTop: 0 }}
        />
      </div>
    </div>
  )

  useEffect(() => {
    if (feedLoaded) {
      if (document.getElementById("dynamicContentForTag")) {
        setIsGeoInfoLoading(true)
        makeJScall()
      } else {
      }
    }
  }, [feedLoaded])

  return (
    <>
      <div>
        <Heading title="Timeline" textAlign="center" />
        {isFeedEntryLoading && <LinearProgress />}

        <GridContainer>
          <GridItem xs={12}>
            <Card plain>
              <CardBody plain>
                {feedLoaded && (
                  <>
                    <Button
                      onClick={() => {
                        setFeedLoaded(false)
                        setShowVerbose(false)
                      }}
                    >
                      Clear
                    </Button>
                    <FormControlLabel
                      style={{ margin: "0", color: "#999999" }}
                      control={
                        <Checkbox
                          checked={showVerbose}
                          title="Name"
                          onChange={event => {
                            const obj = document.getElementById("geoIpInfo")
                            if (obj) {
                              obj.style.display = event.target.checked
                                ? "inline-flex"
                                : "none"
                              setShowVerbose(event.target.checked)
                            }
                          }}
                        />
                      }
                      label="Show GEO/IP Info"
                      title="Show GEO/IP Info"
                    />
                  </>
                )}
                {feedLoaded && showFeedHTML(feedEntry.content)}
                {!feedLoaded && (
                  <>
                    <Button onClick={() => fetchFeedEntryInfo(2432056)}>
                      Feed with GEO Info
                    </Button>
                  </>
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </>
  )
}

export default CTAGeoPrototype
