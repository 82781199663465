import { globalSettings } from "variables/general.jsx"
import { get } from "../tools/request"

export default class GraphicsDAO {
  static getGeographicPlacesforGraphicsTool = async () => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/graphics/geographicplaces/`
    )
    return await res.json()
  }

  static getTeamsForGraphicsTool = async () => {
    const res = await get(`${globalSettings.apiBaseUrl}/api/graphics/teams/`)
    return await res.json()
  }

  static getPlayersForGraphicsTool = async () => {
    const res = await get(`${globalSettings.apiBaseUrl}/api/graphics/players/`)
    return await res.json()
  }
}
