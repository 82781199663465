import React from "react"
import PropTypes from "prop-types"
import withStyles from "@mui/styles/withStyles"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Clearfix from "components/Clearfix/Clearfix"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardHeader from "components/Card/CardHeader"
import CardIcon from "components/Card/CardIcon"
import accountProfileStyles from "assets/jss/material-dashboard-pro-react/views/accountProfileStyles"
import Table from "components/Table/Table"
import Lock from "@mui/icons-material/Lock"
import Group from "@mui/icons-material/Group"
import { globalSettings } from "variables/general"
import { get } from "tools/request"

class AccountPermissions extends React.Component {
  constructor(props) {
    super(props)
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      isFailedDialogOpen: false,
      isSuccessDialogOpen: false,
      permissionGroups: [],
      permissionDetails: []
    }
    this.loadPermissionGroups()
    this.loadPermissionDetails()
  }

  loadPermissionGroups() {
    get(`${globalSettings.apiBaseUrl}/api/account/retrievepermissiongroups`)
      .then(Response => Response.json())
      .then(data => {
        // convert json array of objects, to array of arrays
        let permissionGroups = []
        data.content.map(function (item) {
          permissionGroups.push([item.name, item.description])
        })
        this.setState({
          permissionGroups: permissionGroups
        })
      })
  }

  loadPermissionDetails() {
    get(`${globalSettings.apiBaseUrl}/api/account/retrievepermissiondetails`)
      .then(Response => Response.json())
      .then(data => {
        // convert json array of objects, to array of arrays
        let permissions = []
        data.content.map(function (item) {
          permissions.push([
            item.name.split(/(?=[A-Z])/).join(" "),
            item.description
          ])
        })
        this.setState({
          permissionDetails: permissions
        })
      })
  }

  render() {
    const { classes } = this.props
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Group />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Your Groups</h4>
              </CardHeader>
              <CardBody>
                <Table
                  striped
                  tableHead={["Name", "Description"]}
                  tableData={this.state.permissionGroups}
                  customCellClasses={[
                    classes.center,
                    classes.right,
                    classes.right
                  ]}
                  customClassesForCells={[0, 5, 6]}
                  customHeadCellClasses={[
                    classes.center,
                    classes.right,
                    classes.right
                  ]}
                  customHeadClassesForCells={[0, 5, 6]}
                />
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Lock />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Your Permissions</h4>
              </CardHeader>
              <CardBody>
                <Table
                  striped
                  tableHead={["Name", "Description"]}
                  tableData={this.state.permissionDetails}
                  customCellClasses={[
                    classes.center,
                    classes.right,
                    classes.right
                  ]}
                  customClassesForCells={[0, 5, 6]}
                  customHeadCellClasses={[
                    classes.center,
                    classes.right,
                    classes.right
                  ]}
                  customHeadClassesForCells={[0, 5, 6]}
                />
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

AccountPermissions.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(accountProfileStyles)(AccountPermissions)
