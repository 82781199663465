import { List, fromJS } from "immutable"
import { action, observable, decorate } from "mobx"
import NarrativeRenderScheduleDao from "../daos/narrativeRenderScheduleDAO"

export default class NarrativeRenderScheduleStore {
  rootStore
  loading
  schedules

  constructor(rootStore) {
    this.rootStore = rootStore
    this.dehydrate()
  }

  /**
   * Reactions
   */
  getNarrativeRenderSchedules = async () => {
    try {
      this.setLoading(true)

      const schedules = fromJS(
        await NarrativeRenderScheduleDao.getNarrativeRenderSchedules()
      )
      // The end date/time can be null, so change it to be
      // date/time way into the future.
      schedules.forEach(item => {
        if (item.endDateTime == null) {
          item.endDateTime = new Date("1/1/2050") // A date for into the future
        }
      })
      this.setNarrativeRenderSchedules(schedules.get("content", List()))
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoading(false)
    }
  }

  getNarrativeRenderSchedulesByNarrativeId = async narrativeId => {
    try {
      this.setLoading(true)

      const schedules = fromJS(
        await NarrativeRenderScheduleDao.getNarrativeRenderSchedulesByNarrativeId(
          narrativeId
        )
      )

      this.setNarrativeRenderSchedules(schedules)
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoading(false)
    }
  }

  /**
   * Internal Actions
   */
  dehydrate() {
    this.setLoading(false)
    this.setNarrativeRenderSchedules(List())
  }

  setNarrativeRenderSchedules(value) {
    this.schedules = value.sortBy(o => o.get("id", ""))
  }

  setLoading(value) {
    this.loading = value
  }
}

decorate(NarrativeRenderScheduleStore, {
  // observables
  loading: observable,
  schedules: observable,

  // actions
  setLoading: action.bound,
  setNarrativeRenderSchedules: action.bound
})
