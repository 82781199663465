import Helpers from "tools/Helpers.js"
// entities are strings of item type and ID: `{itemType}-{id}`
const useSourceTargetHelper = ({
  entities = [],
  targetEntities = [],
  sourceNarrative = [],
  targetNarrative = [],
  eligibleEntities = [],
  type = false
}) => {
  const allSourceInfo = []
  const allTargetInfo = []

  const getLibraryToolsInfo = () => {
    eligibleEntities.forEach(itm => {
      let theId = itm.match(/\d{1,8}/)
      theId = theId && theId[0] ? Number(theId[0]) : null
      if (itm.match(/sentenceid/) && theId) {
        sourceNarrative?.paragraphs?.forEach(para => {
          para.sentences?.forEach(snt => {
            if (snt.id === theId) {
              let repeat = false
              allSourceInfo.forEach((info, index) => {
                if (info.paragraphId === para.id) {
                  repeat = index
                }
              })
              if (repeat !== false) {
                allSourceInfo[repeat].sentenceIds?.push({
                  id: snt.id,
                  name: snt.name || Helpers.titleCaseSlug(snt.triggerType)
                })
              } else {
                allSourceInfo.push({
                  paragraphName: para.name,
                  paragraphId: para.id,
                  sentenceIds: [
                    {
                      id: snt.id,
                      name: snt.name || Helpers.titleCaseSlug(snt.triggerType)
                    }
                  ]
                })
              }
            }
          })
        })
      } else if (itm.match(/paragraphid/)) {
        sourceNarrative?.paragraphs?.forEach(para => {
          if (para.id === theId) {
            allSourceInfo.push({
              paragraphName: para.name,
              paragraphId: para.id
            })
          }
        })
      }
    })

    if (targetEntities.length) {
      targetEntities.forEach(itm => {
        let theId = itm.match(/\d{1,8}/)
        theId = theId && theId[0] ? Number(theId[0]) : null
        if (itm.match(/paragraphid/)) {
          targetNarrative?.paragraphs?.forEach(para => {
            if (para.id === theId) {
              allTargetInfo.push({
                paragraphName: para.name,
                paragraphId: para.id
              })
            }
          })
        }
      })
    }
  }
  const getNarrativeOutlineMoverInfo = () => {
    eligibleEntities.forEach(itm => {
      let theId = itm.match(/\d{1,8}/)
      theId = theId && theId[0] ? Number(theId[0]) : null
      if (itm.match(/outlineBlockId/) && theId) {
        sourceNarrative?.outlineBlocks?.forEach(outline => {
          if (outline.id === theId) {
            allSourceInfo.push({
              outlineName: outline.contentSection,
              outlineId: outline.id,
              position: outline.position
            })
          }
        })
      }
    })

    if (targetEntities.length) {
      targetEntities.forEach(itm => {
        let theId = itm.match(/\d{1,8}/)
        theId = theId && theId[0] ? Number(theId[0]) : null
        if (itm.match(/outlineBlockId/)) {
          targetNarrative?.outlineBlocks?.forEach(outline => {
            if (outline.id === theId) {
              allTargetInfo.push({
                outlineName: outline.contentSection,
                outlineId: outline.id,
                position: outline.position
              })
            }
          })
        }
      })
    }
  }
  if (!type || type !== "OutlineMover") {
    getLibraryToolsInfo()
  } else if (type === "OutlineMover") {
    getNarrativeOutlineMoverInfo()
  }

  return {
    allSourceInfo,
    allTargetInfo
  }
}

export default useSourceTargetHelper
