import React from "react"
import {
  Box,
  //Checkbox,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { flexRender } from "@tanstack/react-table"

import useTableSetupHelper from "hooks/table/useTableSetupHelper"
import Helpers from "tools/Helpers"

const useStyles = makeStyles(theme => ({
  selectHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  tableArea: {
    background: "white",
    overflow: "auto"
  }
}))

const SavedTriggersToBlocksAssociationTable = ({
  columns,
  tableData,
  targetNarrative,
  onItemsChange,
  lastItemsList,
  onCancel
}) => {
  const classes = useStyles()

  const tableColumns = [...columns]

  tableData?.forEach(itm => {
    itm.targetNarrativeId = targetNarrative?.id || ""
    if (itm.blockType === "Sentence") {
      itm.sentenceId = itm.blockId
      itm.paragraphId = itm.parentId
    } else if (itm.blockType === "Paragraph") {
      itm.paragraphId = itm.blockId
      itm.sentenceId = "---"
    }
    itm.triggerKey = itm.newValue
  })

  const table = useTableSetupHelper({
    tableData,
    tableColumns,
    paginated: true,
    sorted: true,
    filtered: true,
    enableRowSelection: true
  })

  const selectedItemIds = table
    .getSelectedRowModel()
    .rows.map(row => row.original?.id)

  React.useEffect(() => {
    if (
      !Helpers.doTheseTwoArraysOfObjectsMatch(lastItemsList, selectedItemIds)
    ) {
      onItemsChange(selectedItemIds)
    }
  }, [lastItemsList, selectedItemIds])

  return (
    <>
      <Box className={classes.tableArea} mt={1}>
        <Grid container>
          <Grid item sm={12} md={7} style={{ padding: "0 0 0 30px" }}>
            <Box mt={1}>
              <Typography variant="subtitle2">{`${tableData.length} modifications were made`}</Typography>
            </Box>
          </Grid>
        </Grid>
        <TableContainer>
          <Table size="medium">
            <TableHead>
              {table.getHeaderGroups().map(headerGroup => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <TableCell
                      style={{
                        width: header.column.columnDef.width ?? "initial"
                      }}
                      key={header.id}
                      sortDirection={header.column.getIsSorted()}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <>
                        {header.isPlaceholder ? null : header.column.getCanSort() ? (
                          <TableSortLabel
                            active={
                              table.getState().sorting[0]?.id === header?.id
                            }
                            direction={header.column.getIsSorted() || "asc"}
                            onClick={header.column.getToggleSortingHandler()}
                            data-testid={"sort"}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </TableSortLabel>
                        ) : (
                          flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )
                        )}
                      </>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {table.getRowModel().rows.map(row => (
                <TableRow data-testid="row" key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <TableCell key={cell.id} data-testid="row-cell">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          count={Object.keys(table.getRowModel().rowsById).length}
          rowsPerPage={table.getState().pagination.pageSize}
          page={table.getState().pagination.pageIndex}
          SelectProps={{
            SelectDisplayProps: {
              "data-testid": "pagination-button"
            },
            inputProps: { "data-testid": "pagination-input" }
          }}
          onPageChange={(e, page) => {
            table.setPageIndex(page)
          }}
          onRowsPerPageChange={e => {
            table.setPageSize(e.target.value)
          }}
        />
      </Box>
      <div style={{ textAlign: "center", margin: "0% 40%", padding: "10px 0" }}>
        <Button
          style={{ marginRight: 8 }}
          variant="contained"
          color="secondary"
          onClick={onCancel}
        >
          Close
        </Button>
      </div>
    </>
  )
}

export default SavedTriggersToBlocksAssociationTable
