import { createTheme, adaptV4Theme } from "@mui/material/styles"

//creates outline variant for exisiting custom components
export const FormTheme = createTheme(
  adaptV4Theme({
    props: {
      // Name of the component
      MuiTextField: {
        // The default props to change
        variant: "outlined"
      },
      MuiFormControl: {
        variant: "outlined"
      }
    },
    palette: {
      primary: {
        main: "#4d9ab5"
      },
      secondary: {
        main: "#c4c4c4"
      }
    }
  })
)
