import { useState, useEffect } from "react"
import PropTypes from "prop-types"
import withStyles from "@mui/styles/withStyles"
import {
  Grid,
  Select,
  MenuItem,
  AppBar,
  TextField,
  FormControl,
  InputLabel,
  DialogActions,
  DialogTitle,
  Tab,
  Tabs,
  Tooltip,
  FormHelperText,
  LinearProgress,
  Modal,
  Checkbox,
  FormControlLabel
} from "@mui/material"
import Button from "components/CustomButtons/Button"
import GridItem from "components/Grid/GridItem"
import { globalSettings } from "variables/general"
import { TabPanel } from "components/TabPanel/TabPanel"
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog"
import Autocomplete from "@mui/material/Autocomplete"
import Helpers from "tools/Helpers.js"

import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import { withRouter } from "react-router"
import { put } from "tools/request"
import CustomDSDialog from "components/CustomDialogs/CustomDSDialog"
import { Info } from "@mui/icons-material"
import Cta_v2DAO from "daos/cta_v2DAO"

const styles = theme => ({
  successModal: {
    position: "absolute",
    color: "#069",
    _width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    _top: "45%",
    _left: "45%",
    transform: "translate(-45%, -45%)",
    "& h3": {
      fontWeight: "bold"
    },
    "& ul": {
      padding: "5px 0 0 5px",
      fontWeight: "bold"
    },
    [theme.breakpoints.up(701)]: {
      width: 400,
      top: "45%",
      left: "45%"
    },
    [theme.breakpoints.down(700)]: {
      width: "70vw",
      top: "50vh",
      left: "50vw"
    }
  },
  myFlex: {
    [theme.breakpoints.down(700)]: {
      display: "flex",
      flexDirection: "column"
    },
    [theme.breakpoints.up(700)]: {
      display: "flex",
      flexDirection: "row"
    }
  },

  myFlexInputWidth: {
    [theme.breakpoints.down(700)]: {
      minWidth: "150px"
    }
  },
  myFlexFontSize: {
    [theme.breakpoints.down(700)]: {
      padding: "4px 0 0 0",
      "& h2": {
        fontSize: "1.25em"
      }
    }
  },
  changeLabel: {
    [theme.breakpoints.down(700)]: {
      color: "#212529",
      margin: "0"
    },
    [theme.breakpoints.up(700)]: {
      color: "#212529",
      marginTop: "20px"
    }
  },
  margin45: {
    [theme.breakpoints.down(700)]: {
      margin: "0"
    },
    [theme.breakpoints.up(700)]: {
      marginTop: "45px"
    }
  },
  tabScrollRoot: {
    [theme.breakpoints.down(600)]: {
      "& .MuiTabs-scrollButtonsDesktop": {
        display: "flex !important"
      },
      "& .MuiTab-wrapper": {
        fontSize: "12px"
      }
    }
  },

  selectControl: {
    [theme.breakpoints.down("700")]: {
      minWidth: "90%",
      marginLeft: "0px"
    },
    [theme.breakpoints.up("700")]: {
      minWidth: "18vw",
      _marginTop: "0px",
      marginLeft: "0px"
    },
    [theme.breakpoints.up("960")]: {
      minWidth: "12vw",
      _marginTop: "0px",
      marginLeft: "0px"
    },
    [theme.breakpoints.up("1200")]: {
      minWidth: "12vw",
      _marginTop: "0px",
      marginLeft: "0px"
    }
  },
  selectControlBookmaker1: {
    [theme.breakpoints.down("700")]: {
      minWidth: "90%",
      marginLeft: "0px"
    },
    [theme.breakpoints.up("700")]: {
      minWidth: "18vw",
      marginTop: "-15px",
      marginLeft: "0px"
    },
    [theme.breakpoints.up("960")]: {
      minWidth: "12vw",
      marginTop: "-15px",
      marginLeft: "0px"
    },
    [theme.breakpoints.up("1200")]: {
      minWidth: "12vw",
      marginTop: "-15px",
      marginLeft: "0px"
    }
  },
  requiredMsg: {
    color: "#c00",
    fontWeight: "bold",
    backgroundColor: "#f7f7f7"
  },
  progessDone: {
    border: "2px solid #BBDDEE"
  },
  errorModal: {
    position: "absolute",
    color: "#c00",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    transform: "translate(-45%, -45%)",
    "& h3": {
      fontWeight: "bold"
    },
    "& ul": {
      padding: "5px 0 0 5px",
      fontWeight: "bold",
      textDecoration: "underline"
    },
    [theme.breakpoints.up(701)]: {
      width: 400,
      top: "45%",
      left: "45%"
    },
    [theme.breakpoints.down(700)]: {
      width: "70vw",
      top: "50vh",
      left: "50vw"
    }
  }
})

function UserStoreData() {
  const store = useStore()
  return useObserver(() => ({
    getPermissions: store.accountStore.getPermissions
  }))
}

function PropertyEditor(props) {
  const {
    isAddingProperty,
    classes,
    organizations,
    organization,
    currentParent,
    datasourcesByOrg,
    bookmakersByOrg
  } = props

  let datasourcesForCurrentOrg = {}
  //The code below looks for the datasources from our organization, then saves them as:
  //1: an Array of object fpr full display and testing (datasourcesForCurrentOrg.sportsDataNew then becomes organization.sportsDataNew)
  //2: an Array with just the id for identifying the default value to be passed into the MultiselectAutocompelete components (datasourcesForCurrentOrg.sportsDataNew_ then becomes organization.sportsDataOrig)
  datasourcesByOrg &&
    Object.keys(datasourcesByOrg).forEach(function (itm) {
      if (Number(itm) === organization.id) {
        Object.keys(datasourcesByOrg[itm]).forEach(function (itm2) {
          datasourcesByOrg[itm][itm2].forEach(itm3 => {
            switch (itm2) {
              case "bettingDataNew":
                datasourcesForCurrentOrg["bettingDataFromDB"] =
                  datasourcesForCurrentOrg["bettingDataFromDB"] || []
                datasourcesForCurrentOrg["bettingDataFromDB"].push({
                  id: itm3.id
                })
                break
              case "sportsDataNew":
                datasourcesForCurrentOrg["sportsDataFromDB"] =
                  datasourcesForCurrentOrg["sportsDataFromDB"] || []
                datasourcesForCurrentOrg["sportsDataFromDB"].push({
                  id: itm3.id
                })
                break
              case "imageDataNew":
                datasourcesForCurrentOrg["imageDataFromDB"] =
                  datasourcesForCurrentOrg["imageDataFromDB"] || []
                datasourcesForCurrentOrg["imageDataFromDB"].push({
                  id: itm3.id
                })
                break
              default:
            }
          })
        })
      }
    })
  let bookmakersForCurrentOrg = {}
  bookmakersByOrg &&
    Object.keys(bookmakersByOrg).forEach(function (itm) {
      if (Number(itm) === organization.id) {
        Object.keys(bookmakersByOrg[itm]).forEach(function (itm2) {
          bookmakersByOrg[itm][itm2].forEach(itm3 => {
            switch (itm2) {
              case "bookmakersDataNew":
                bookmakersForCurrentOrg["bookmakerFromDB"] =
                  bookmakersForCurrentOrg["bookmakerFromDB"] || []
                bookmakersForCurrentOrg["bookmakerFromDB"].push({
                  id: itm3.id
                })
                break
              default:
            }
          })
        })
      }
    })

  const newProperty = {
    ...organization,
    sportsDataFromDB:
      (datasourcesForCurrentOrg && datasourcesForCurrentOrg.sportsDataFromDB) ||
      [],
    bettingDataFromDB:
      (datasourcesForCurrentOrg &&
        datasourcesForCurrentOrg.bettingDataFromDB) ||
      [],
    imageDataFromDB:
      (datasourcesForCurrentOrg && datasourcesForCurrentOrg.imageDataFromDB) ||
      [],
    bookmakerFromDB:
      (bookmakersForCurrentOrg && bookmakersForCurrentOrg.bookmakerFromDB) ||
      [],
    isInternal: organization?.isInternal ?? false,
    addGaTag: organization?.addGaTag ?? false,
    gaTagId: organization?.gaTagId ?? ""
  }

  if (currentParent) {
    const parentOrg = props.organizations.find(o => o?.id === currentParent)
    if (parentOrg?.isInternal) {
      newProperty.isInternal = parentOrg.isInternal
    }
    if (parentOrg?.addGaTag) {
      newProperty.addGaTag = parentOrg.addGaTag
    }
  }

  const { getPermissions } = UserStoreData()

  const [myProperties, setMyProperties] = useState(newProperty)
  const [isSaving, setIsSaving] = useState(false)
  const [isSavingProperty, setIsSavingProperty] = useState(false)
  const [justAddedOrganization, setJustAddedOrganization] = useState(false)
  const [propertyUpdated, setPropertyUpdated] = useState(false)
  const [webSiteUrlHasError, setWebSiteUrlHasError] = useState(false)

  const [parentIdHasError, setParentIdHasError] = useState(false)
  const [propertyNameHasError, setPropertyNameHasError] = useState(false)

  const [successMessage, setSuccessMessage] = useState("")
  const [isSavingContent, setIsSavingContent] = useState(false)
  const [currentView, setCurrentView] = useState(1)
  const [isConfirmUpdateAllVariantsOpen, setIsConfirmUpdateAllVariantsOpen] =
    useState(false)
  const [shouldUpdateAllCtas, setShouldUpdateAllCtas] = useState(false)
  const [
    variantClickUpdateSuccessModalMessage,
    setVariantClickUpdateSuccessModalMessage
  ] = useState("")
  const [updateVariantOnclicksCompleted, setUpdateVariantOnclicksCompleted] =
    useState(false)
  const [confirmInactiveOpen, setConfirmInactiveOpen] = useState(false)
  const [resetPropertyInfo, setResetPropertyInfo] = useState(false)
  const [gaTagHasError, setGaTagHasError] = useState(false)

  const {
    id,
    isActive,
    name,
    webSiteUrl,
    turnIntoOrganization,
    editingProperty,
    isInternal,
    addGaTag,
    gaTagId
  } = myProperties

  const organizationDatasources = myProperties.organizationDatasources || []
  const bookmakersDatasources = myProperties.organizationBookmakers || []

  const handleActiveOrgCheck = checked => {
    const status = checked ? "Active" : "Inactive"
    if (status === "Inactive") {
      setConfirmInactiveOpen(true)
    } else {
      const newProperties = {
        ...myProperties,
        isActive: checked,
        Status: status,
        editingProperty: true
      }
      setMyProperties(newProperties)
    }
  }

  const inactivateProperty = () => {
    const newProperties = {
      ...myProperties,
      isActive: false,
      Status: "Inactive",
      editingProperty: true
    }
    setMyProperties(newProperties)
  }

  const handleClose = () => {
    const prevProperty = {
      ...organization,
      sportsDataFromDB:
        (datasourcesForCurrentOrg &&
          datasourcesForCurrentOrg.sportsDataFromDB) ||
        [],
      bettingDataFromDB:
        (datasourcesForCurrentOrg &&
          datasourcesForCurrentOrg.bettingDataFromDB) ||
        [],
      imageDataFromDB:
        (datasourcesForCurrentOrg &&
          datasourcesForCurrentOrg.imageDataFromDB) ||
        [],
      bookmakerFromDB:
        (bookmakersForCurrentOrg && bookmakersForCurrentOrg.bookmakerFromDB) ||
        []
    }
    setMyProperties(prevProperty)
  }

  const handleCellChange = name => event => {
    const newProperties = {
      ...myProperties,
      [name]: event.target.value,
      editingProperty: true
    }
    if (name === "parentId") {
      const parentOrg = props.organizations.find(
        o => o?.id === event.target.value
      )
      newProperties.isInternal = parentOrg.isInternal
      newProperties.addGaTag = parentOrg.addGaTag
    }
    setMyProperties(newProperties)
  }

  const handleCheckboxChange = name => () => {
    if (name === "addGaTag") {
      if (!myProperties[name] && id) {
        setIsConfirmUpdateAllVariantsOpen(true)
      } else {
        setShouldUpdateAllCtas(false)
      }
    }
    const newProperties = {
      ...myProperties,
      [name]: !myProperties[name],
      editingProperty: true
    }
    setMyProperties(newProperties)
  }

  const handleTabChange = (event, newValue) => {
    setCurrentView(newValue)
    if (!newValue) {
      props.backToOrgList()
      return
    }
  }

  const handleMultiAuto = (event, newValue, reason, forArea) => {
    let changesToMultiselect = !Helpers.doTheseTwoArraysOfObjectsMatch(
      newValue,
      myProperties[forArea],
      "id"
    )
    const mod = {
      bookmakerFromDB: "bookmakerDataChange",
      imageDataFromDB: "imageDataChange",
      bettingDataFromDB: "bettingDataChange",
      sportsDataFromDB: "sportsDataChange"
    }
    const newProperties = {
      ...myProperties,
      disableSaveButton: false,
      [mod[forArea]]: newValue,
      editingProperty:
        changesToMultiselect && myProperties[forArea] && newValue
          ? true
          : myProperties.editingProperty
    }
    setMyProperties(newProperties)
  }

  const PopulateDataSources = () => {
    let theSources = []
    let bettingS = []
    let imageS = []
    let sportsS = []

    myProperties["sportsDataChange"] &&
      myProperties["sportsDataChange"].forEach(itm => {
        let obj = {}
        obj.datasourceTypeId = 1
        obj.datasourceId = itm.id
        obj.id = 0
        sportsS.push(obj)
      })
    !myProperties["sportsDataChange"] &&
      sportsS.length === 0 &&
      organizationDatasources.forEach(itm => {
        if (itm.datasourceTypeId === 1) {
          let obj = itm
          sportsS.push(obj)
        }
      })

    myProperties["bettingDataChange"] &&
      myProperties["bettingDataChange"].forEach(itm => {
        let obj = {}
        obj.datasourceTypeId = 2
        obj.datasourceId = itm.id
        bettingS.push(obj)
      })
    !myProperties["bettingDataChange"] &&
      bettingS.length === 0 &&
      organizationDatasources.forEach(itm => {
        if (itm.datasourceTypeId === 2) {
          let obj = itm
          bettingS.push(obj)
        }
      })

    myProperties["imageDataChange"] &&
      myProperties["imageDataChange"].forEach(itm => {
        let obj = {}
        obj.datasourceTypeId = 3
        obj.datasourceId = itm.id
        imageS.push(obj)
      })
    !myProperties["imageDataChange"] &&
      imageS.length === 0 &&
      organizationDatasources.forEach(itm => {
        if (itm.datasourceTypeId === 3) {
          let obj = itm
          imageS.push(obj)
        }
      })

    const allChanges = sportsS.concat(bettingS, imageS)
    allChanges.forEach(itm => {
      let existing = false
      organizationDatasources.forEach(fromDB => {
        if (
          itm.datasourceId === fromDB.datasourceId &&
          itm.datasourceTypeId === fromDB.datasourceTypeId
        ) {
          existing = true
          itm.indexId = fromDB.id
        }
      })
      let obj = {}
      obj.datasourceId = itm.datasourceId
      obj.datasourceTypeId = itm.datasourceTypeId
      if (existing) {
        obj.id = itm.indexId
      }
      theSources.push(obj)
    })
    return theSources
  }

  const PopulateBookmakers = () => {
    let bookmakerS = []
    myProperties["bookmakerDataChange"] &&
      myProperties["bookmakerDataChange"].forEach(itm => {
        let obj = {}
        obj.bookmakerId = itm.id
        bookmakerS.push(obj)
      })
    !myProperties["bookmakerDataChange"] &&
      bookmakerS.length === 0 &&
      bookmakersDatasources.forEach(itm => {
        let obj = itm
        bookmakerS.push(obj)
      })
    return bookmakerS
  }

  const setUpdateCTAFlag = performUpdate => {
    setShouldUpdateAllCtas(performUpdate)
    setIsConfirmUpdateAllVariantsOpen(false)
  }

  const handlePropSave = () => {
    const typeOfFetch = id ? "update" : "create"
    const varName = id ? "ModifiedByAccountId" : "CreatedByAccountId"
    let validationError = false

    if (!myProperties.webSiteUrl) {
      setWebSiteUrlHasError(true)
      validationError = true
    } else {
      setWebSiteUrlHasError(false)
    }
    if (!myProperties.parentId && !currentParent) {
      setParentIdHasError(true)
      validationError = true
    } else {
      setParentIdHasError(false)
    }
    if (!myProperties.name) {
      setPropertyNameHasError(true)
      validationError = true
    } else {
      setPropertyNameHasError(false)
    }
    if (myProperties.addGaTag && !myProperties.gaTagId) {
      setGaTagHasError(true)
      validationError = true
    } else {
      setGaTagHasError(false)
    }
    if (validationError === true) {
      setIsSavingContent(false)
      return
    }

    if (!isSaving) {
      setIsSaving(true)
      setIsSavingProperty(true)
    }

    if (shouldUpdateAllCtas) {
      Cta_v2DAO.putUpdateVariantOnclicksByOrg(id).then(response => {
        setVariantClickUpdateSuccessModalMessage(
          ` Successfully updated ${response.modified} CTA variants.`
        )
        setUpdateVariantOnclicksCompleted(true)
      })
    } else {
      setUpdateVariantOnclicksCompleted(true)
    }

    let newMyProperties = {
      name: myProperties.name,
      webSiteUrl: myProperties.webSiteUrl,
      isActive: myProperties.isActive,
      parentId: myProperties.turnIntoOrganization
        ? null
        : myProperties.parentId || currentParent,
      sportsDatasource1Id: myProperties.sportsDatasource1Id,
      bettingDatasource1Id: myProperties.bettingDatasource1Id,
      imageSource1Id: myProperties.imageSource1Id,
      bookmaker1Id: myProperties.bookmaker1Id,
      bookmaker2Id: myProperties.bookmaker2Id,
      bookmaker3Id: myProperties.bookmaker3Id,
      bookmaker4Id: myProperties.bookmaker4Id,
      OrganizationDatasources: PopulateDataSources(),
      OrganizationBookmakers: PopulateBookmakers(),
      IsInternal: myProperties.isInternal,
      AddGaTag: myProperties.addGaTag,
      GaTagId: myProperties.gaTagId,
      [varName]: props.userId
    }
    if (id) {
      newMyProperties.id = id
    }
    const toDelete = ["accounts", "editingProperty", "websiteurl", "ncaaOrgId"]
    toDelete.forEach(itm => {
      if (newMyProperties[itm] !== undefined) {
        delete newMyProperties[itm]
      }
    })
    put(`${globalSettings.apiBaseUrl}/api/organization/${typeOfFetch}`, null, {
      ...newMyProperties
    })
      .then(Response => Response && Response.ok === true && Response.json())
      .then(response => {
        setIsSaving(false)
        setIsSavingContent(false)
        let newOrgId =
          response.responseMessage &&
          response.responseMessage.match(/^Organization\s(\d*):/)
        if (newOrgId && typeof newOrgId === "object" && newOrgId[1]) {
          newOrgId = Number(newOrgId[1])
        }
        getPermissions()
        setPropertyUpdated(true)
        setSuccessMessage(response.responseMessage)
        if (newOrgId != null && newOrgId !== 0) {
          if (!id) {
            setJustAddedOrganization(true)
          }
        }
      })
  }

  let { parentId } = myProperties
  if (currentParent && currentParent > 0) {
    parentId = currentParent
  }

  const activeParentOrganizations = currentParent
    ? organizations.filter(
        itm =>
          itm.isActive === true &&
          itm.id === currentParent &&
          itm.id !== organization.id
      )
    : organizations.filter(
        itm => itm.isActive === true && itm.parentId === null
      )

  activeParentOrganizations.sort(function (a, b) {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  })

  const { sportsData, bettingData, imageData, bookmakerData } = props

  let bookmakerDataToJS = bookmakerData || []
  let imageDataToJS = imageData || []
  let bettingDataToJS = bettingData || []
  let sportsDataToJS = sportsData || []

  bookmakerDataToJS.sort(function (a, b) {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  })
  imageDataToJS.sort(function (a, b) {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  })
  bettingDataToJS.sort(function (a, b) {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  })
  sportsDataToJS.sort(function (a, b) {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  })

  const hasWindowObject = typeof window !== "undefined"
  const screenWidth = hasWindowObject ? window.innerWidth : null
  const screenHeight = hasWindowObject ? window.innerHeight : null
  const inColumnFlex =
    screenWidth && screenHeight && (screenHeight < 700 || screenWidth < 700)

  myProperties.bookmakerFromDB &&
    myProperties.bookmakerFromDB.forEach(itm => {
      if (!itm.name) {
        bookmakerDataToJS &&
          bookmakerDataToJS.forEach(obj => {
            if (obj.id === itm.id) {
              itm.name = obj.name
            }
          })
      }
    })

  myProperties.imageDataFromDB &&
    myProperties.imageDataFromDB.forEach(itm => {
      if (!itm.name) {
        imageDataToJS &&
          imageDataToJS.forEach(obj => {
            if (obj.id === itm.id) {
              itm.name = obj.name
            }
          })
      }
    })
  myProperties.bettingDataFromDB &&
    myProperties.bettingDataFromDB.forEach(itm => {
      if (!itm.name) {
        bettingDataToJS &&
          bettingDataToJS.forEach(obj => {
            if (obj.id === itm.id) {
              itm.name = obj.name
            }
          })
      }
    })
  myProperties.sportsDataFromDB &&
    myProperties.sportsDataFromDB.forEach(itm => {
      if (!itm.name) {
        sportsDataToJS &&
          sportsDataToJS.forEach(obj => {
            if (obj.id === itm.id) {
              itm.name = obj.name
            }
          })
      }
    })

  useEffect(() => {
    if (resetPropertyInfo) {
      handleClose()
      setResetPropertyInfo(false)
    }
  }, [resetPropertyInfo])

  return (
    <>
      {!resetPropertyInfo && (
        <>
          <div className={`${classes.tabScrollRoot}`}>
            <AppBar position="static" color="default" enableColorOnDark>
              <Tabs
                value={currentView}
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                onChange={handleTabChange}
                aria-label="Property Tabs"
                style={{
                  minWidth: "1050px"
                }}
              >
                <Tab label="Back to Property List" />
                <Tab label="Property Editor" />
              </Tabs>
            </AppBar>
          </div>

          <TabPanel value={currentView} index={1}>
            <div>
              <div
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "20px",
                  paddingBottom: "20px"
                }}
              >
                <Grid style={{ display: "flex" }} className={classes.myFlex}>
                  <GridItem xs={12} sm={10}>
                    <DialogTitle
                      style={{ paddingBottom: "0px" }}
                      className={classes.myFlexFontSize}
                    >
                      Property Editor
                      {!isAddingProperty && name && ` (${name})`}
                    </DialogTitle>
                  </GridItem>
                  {id && (
                    <GridItem
                      xs={12}
                      sm={!inColumnFlex ? 3 : 6}
                      style={{ paddingLeft: "10px" }}
                    >
                      <div style={{}}>
                        <FormControlLabel
                          labelPlacement="end"
                          control={
                            <Checkbox
                              checked={isActive}
                              onChange={event => {
                                handleActiveOrgCheck(event.target.checked)
                              }}
                            />
                          }
                          label="Active"
                          style={{ color: "#212529" }}
                        />
                      </div>
                    </GridItem>
                  )}
                </Grid>
                <Grid style={{ display: "flex" }} className={classes.myFlex}>
                  <GridItem xs={12} sm={!inColumnFlex ? 4 : 8}>
                    <TextField
                      className={classes.selectControl}
                      type="text"
                      id="name"
                      label="Property Name"
                      fullWidth
                      value={name}
                      onChange={handleCellChange("name")}
                      margin="normal"
                      required={true}
                    />
                    {propertyNameHasError && (
                      <FormHelperText className={classes.requiredMsg}>
                        Property Name is required!
                      </FormHelperText>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={!inColumnFlex ? 4 : 8}>
                    <TextField
                      className={classes.selectControl}
                      type="text"
                      id="webSiteUrl"
                      label="Property Url"
                      fullWidth
                      value={webSiteUrl}
                      onChange={handleCellChange("webSiteUrl")}
                      margin="normal"
                      required={true}
                    />
                    {webSiteUrlHasError && (
                      <FormHelperText className={classes.requiredMsg}>
                        Property Website Url is required!
                      </FormHelperText>
                    )}
                  </GridItem>
                  {!turnIntoOrganization && (
                    <GridItem xs={12} sm={!inColumnFlex ? 3 : 8}>
                      <FormControl
                        margin="normal"
                        className={classes.selectControl}
                        fullWidth
                      >
                        <InputLabel>Parent Organization</InputLabel>
                        <Select
                          fullWidth={false}
                          type="select"
                          name="parentId"
                          id="parentId"
                          label="Parent Organization"
                          value={parentId || ""}
                          onChange={handleCellChange("parentId")}
                        >
                          {!currentParent && (
                            <MenuItem value="">Select</MenuItem>
                          )}
                          {activeParentOrganizations.map((option, i) => (
                            <MenuItem key={i} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {parentIdHasError && (
                          <FormHelperText className={classes.requiredMsg}>
                            Parent Organization is required!
                          </FormHelperText>
                        )}
                      </FormControl>
                    </GridItem>
                  )}
                  <GridItem xs={12} sm={!inColumnFlex ? 2 : 8}>
                    <Tooltip
                      className={`${classes.selectControl} ${classes.changeLabel}`}
                      title="Change to Organization"
                      placement="top"
                    >
                      <FormControlLabel
                        labelPlacement="end"
                        control={
                          <Checkbox
                            id="turnIntoOrganization"
                            checked={turnIntoOrganization || false}
                            onChange={handleCheckboxChange(
                              "turnIntoOrganization"
                            )}
                          />
                        }
                        label="Change to Organization"
                      />
                    </Tooltip>
                  </GridItem>
                  <GridItem xs={12} sm={!inColumnFlex ? 2 : 8}>
                    <Tooltip
                      className={`${classes.selectControl} ${classes.changeLabel}`}
                      title="Internal Organization"
                      placement="top"
                    >
                      <FormControlLabel
                        labelPlacement="end"
                        control={
                          <Checkbox
                            id="isInternal"
                            checked={isInternal}
                            onChange={handleCheckboxChange("isInternal")}
                            aria-label="org-internal-checkbox"
                          />
                        }
                        label="Internal Organization"
                      />
                    </Tooltip>
                  </GridItem>
                </Grid>
                <DialogTitle
                  style={{
                    paddingBottom: "0px",
                    paddingLeft: "15px"
                  }}
                  className={classes.myFlexFontSize}
                >
                  Data Sources
                </DialogTitle>
                <Grid style={{ display: "flex" }} className={classes.myFlex}>
                  <GridItem xs={12}>
                    <Autocomplete
                      defaultValue={myProperties.sportsDataFromDB}
                      multiple={true}
                      onChange={(event, newValue, reason) => {
                        handleMultiAuto(
                          event,
                          newValue,
                          reason,
                          "sportsDataFromDB"
                        )
                      }}
                      renderInput={params => (
                        <TextField
                          label="Sports Data Source"
                          key={params.id}
                          value={params.name}
                          margin="none"
                          {...params}
                        />
                      )}
                      options={sportsDataToJS.filter(itm => {
                        let found = false
                        const arr =
                          myProperties.sportsDataChange ||
                          myProperties.sportsDataFromDB ||
                          []
                        arr.forEach(itm2 => {
                          if (itm2.id === itm.id) {
                            found = true
                          }
                        })
                        return !found
                      })}
                      getOptionLabel={option => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      nameField="name"
                      valueField="id"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <Autocomplete
                      defaultValue={myProperties.bettingDataFromDB}
                      multiple={true}
                      onChange={(event, newValue, reason) => {
                        handleMultiAuto(
                          event,
                          newValue,
                          reason,
                          "bettingDataFromDB"
                        )
                      }}
                      renderInput={params => (
                        <TextField
                          label="Betting Data Source"
                          key={params.id}
                          value={params.name}
                          margin="none"
                          {...params}
                        />
                      )}
                      options={bettingDataToJS.filter(itm => {
                        let found = false
                        const arr =
                          myProperties.bettingDataChange ||
                          myProperties.bettingDataFromDB ||
                          []
                        arr.forEach(itm2 => {
                          if (itm2.id === itm.id) {
                            found = true
                          }
                        })
                        return !found
                      })}
                      getOptionLabel={option => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      nameField="name"
                      valueField="id"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <Autocomplete
                      defaultValue={myProperties.imageDataFromDB}
                      multiple={true}
                      onChange={(event, newValue, reason) => {
                        handleMultiAuto(
                          event,
                          newValue,
                          reason,
                          "imageDataFromDB"
                        )
                      }}
                      renderInput={params => (
                        <TextField
                          label="3rd Party Image Data Source"
                          key={params.id}
                          value={params.name}
                          margin="none"
                          {...params}
                        />
                      )}
                      options={imageDataToJS.filter(itm => {
                        let found = false
                        const arr =
                          myProperties.imageDataChange ||
                          myProperties.imageDataFromDB ||
                          []
                        arr.forEach(itm2 => {
                          if (itm2.id === itm.id) {
                            found = true
                          }
                        })
                        return !found
                      })}
                      getOptionLabel={option => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      nameField="name"
                      valueField="id"
                    />
                  </GridItem>
                  <GridItem xs={12} style={{ zIndex: "99999" }}>
                    <Autocomplete
                      defaultValue={myProperties.bookmakerFromDB}
                      multiple={true}
                      onChange={(event, newValue, reason) => {
                        handleMultiAuto(
                          event,
                          newValue,
                          reason,
                          "bookmakerFromDB"
                        )
                      }}
                      renderInput={params => (
                        <TextField
                          label="Bookmaker Data Source"
                          key={params.id}
                          value={params.name}
                          margin="none"
                          {...params}
                        />
                      )}
                      options={bookmakerDataToJS.filter(itm => {
                        let found = false
                        const arr =
                          myProperties.bookmakerDataChange ||
                          myProperties.bookmakerFromDB ||
                          []
                        arr.forEach(itm2 => {
                          if (itm2.id === itm.id) {
                            found = true
                          }
                        })
                        return !found
                      })}
                      getOptionLabel={option => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      nameField="name"
                      valueField="id"
                    />
                  </GridItem>
                </Grid>
                <DialogTitle
                  style={{
                    paddingBottom: "0px",
                    paddingLeft: "15px"
                  }}
                  className={classes.myFlexFontSize}
                >
                  Analytics Manager
                </DialogTitle>
                <Grid style={{ display: "flex" }} className={classes.myFlex}>
                  <GridItem xs={2}>
                    <FormControlLabel
                      labelPlacement="end"
                      control={
                        <Checkbox
                          id="addGaTag"
                          checked={addGaTag}
                          onChange={handleCheckboxChange("addGaTag")}
                          aria-label="org-gatag-checkbox"
                        />
                      }
                      label="GA Tag"
                      style={{ color: "#212529" }}
                    />
                  </GridItem>
                  {addGaTag && (
                    <GridItem xs={4}>
                      <TextField
                        type="text"
                        id="gaTagId"
                        label="GA Tag Id"
                        fullWidth
                        value={gaTagId}
                        onChange={handleCellChange("gaTagId")}
                        margin="normal"
                        required={true}
                      />
                      {gaTagHasError && (
                        <FormHelperText className={classes.requiredMsg}>
                          GA Tag ID is required!
                        </FormHelperText>
                      )}
                    </GridItem>
                  )}
                </Grid>
                {(editingProperty || isAddingProperty) && (
                  <Grid
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    className={classes.myFlex}
                  >
                    <GridItem xs={12} sm={6}>
                      <DialogActions style={{ marginTop: "14px" }}>
                        <Button
                          onClick={() => setResetPropertyInfo(true)}
                          color="primary"
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() => {
                            setIsSavingContent(true)
                            handlePropSave()
                          }}
                          disabled={isSavingContent}
                          color="primary"
                        >
                          Save
                        </Button>
                      </DialogActions>
                    </GridItem>
                  </Grid>
                )}
              </div>
            </div>
            {isSavingProperty && (
              <Grid spacing={1} container style={{ paddingTop: "5px 10px" }}>
                <Grid xs item>
                  <div style={{ width: "80%", height: "20px" }}>
                    {isSaving && <LinearProgress />}
                    {!isSaving && <div className={classes.progessDone} />}
                  </div>
                </Grid>
              </Grid>
            )}
          </TabPanel>
          <CustomDSDialog
            open={isConfirmUpdateAllVariantsOpen}
            icon={<Info style={{ fontSize: "4rem" }} />}
            onClose={() => {
              setIsConfirmUpdateAllVariantsOpen(false)
            }}
            backgroundColor={"#4d9ab5"}
            content={`Do you want to add an Onclick to existing CTA Variant anchor tags?`}
            buttonProps={[
              {
                label: "NO",
                color: "default",
                onClick: () => {
                  setUpdateCTAFlag(false)
                }
              },
              {
                label: "YES",
                color: "primary",
                onClick: () => {
                  setUpdateCTAFlag(true)
                }
              }
            ]}
          />
          <ConfirmDialog
            title={`Are you sure you want to inactivate "${name}"?`}
            open={confirmInactiveOpen}
            setOpen={setConfirmInactiveOpen}
            onConfirm={inactivateProperty}
          >
            After you hit "Save", this action will result in:
            <ul>
              <li>
                All Outputs associated with <strong>"{name}"</strong> will be{" "}
                given an end date and be considered done for reporting purposes.
              </li>
            </ul>
            <div>
              <strong>Are you sure you want to do that?</strong>
            </div>
          </ConfirmDialog>
          {propertyUpdated && updateVariantOnclicksCompleted && (
            <Modal
              open={propertyUpdated}
              onClose={() => {
                setIsSavingProperty(false)
                if (justAddedOrganization) {
                  props.backToOrgList(true)
                } else {
                  props.backToOrgList(true) //Changes to Properties will also necessiate a reload, not just new Properties
                }
              }}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              style={{ zIndex: "99999999" }}
            >
              <div className={classes.successModal}>
                <h2 id="server-modal-title">Success!</h2>
                <div id="server-modal-description">
                  {successMessage}
                  {variantClickUpdateSuccessModalMessage}
                </div>
              </div>
            </Modal>
          )}
        </>
      )}
    </>
  )
}

PropertyEditor.propTypes = {
  isOpen: PropTypes.bool,
  isAddingProperty: PropTypes.bool,
  userId: PropTypes.number,
  onChange: PropTypes.func,
  backToOrgList: PropTypes.func,
  onClose: PropTypes.func,
  classes: PropTypes.object,
  organization: PropTypes.object,
  organizations: PropTypes.array,
  datasourcesByOrg: PropTypes.object,
  bookmakersByOrg: PropTypes.object,
  properties: PropTypes.array,
  notChildOrgs: PropTypes.array,
  allAccounts: PropTypes.array,
  datasources: PropTypes.object,
  bookmakers: PropTypes.array,
  bookmakerData: PropTypes.array,
  imageData: PropTypes.array,
  bettingData: PropTypes.array,
  sportsData: PropTypes.array //,
}

export default withStyles(styles)(withRouter(PropertyEditor))
