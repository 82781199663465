export const DEFAULT_TIMEZONE = "US/Pacific"
export const DATE_TIME_FORMAT = {
  LONG_TIME: "YYYY-MM-DD h:MM z",
  SHORT_DATE: "YYYY-MM-DD"
}
export const ENTERTAINMENT_VERTICAL_ID = 2
/**
 * "Types"
 */

// The ints for Copy, Cut, Duplicate are accepted by the backend API
export const ActionType = {
  Copy: 1,
  Cut: 2,
  Duplicate: 3,
  Archive: 4,
  Paste: 5,
  CreatePlaceholder: 6,
  CreatePlaceholderRemoveParagraph: 7,
  ArchiveSentences: 8
}

export const ItemType = {
  Paragraph: "paragraph",
  Sentence: "sentence",
  Block: "outline-block"
}

// Hardcoded narrative data automatically added on creation of a new one
export const initialHardcodedNarrativeData = {
  modelTypeId: 44,
  narrativeStatusId: 2, // Validate
  leagueId: null,
  verticalId: 1,
  feedFieldsArray: [
    "entryid",
    "title",
    "content",
    "seotitle",
    "seoslug",
    "categories",
    "dscategories",
    "outputexperimentlog",
    "metadescription",
    "featuredimagejson",
    "clickdescription"
  ]
}

export const defaultNarrativeStarter = `
Matchstatus IDs:
In Progress: 1
Complete: 2
Scheduled: 5
Scheduled (Time TBA): 12

Matchtype IDs:
Regular Season: 2
Playoffs: 9
NIT: 14
NCAA Tournament: 15
Conference Tournament: 16
Play-In Tournament (NBA): 20
Bowl Game: 21
Conference Championship: 22
Championship: 7
`
