import { globalSettings } from "variables/general.jsx"
import { get, put, del } from "../tools/request"

export default class PermissionGroupDAO {
  static getPermissionGroups = async () => {
    const res = await get(`${globalSettings.apiBaseUrl}/api/permissionGroup`)
    return await res.json()
  }

  static getPermissionGroup = async id => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/permissionGroup/${id}`
    )
    return await res.json()
  }

  static postPermissionGroup = async group => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/permissionGroup`,
      null,
      group
    )
    return await res.json()
  }

  static putPermissionGroup = async (id, group) => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/permissionGroup/${id}`,
      null,
      group
    )
    return await res.json()
  }

  static deletePermissionGroup = async id => {
    const res = await del(
      `${globalSettings.apiBaseUrl}/api/permissionGroup/${id}`
    )
    return await res.json()
  }
}
