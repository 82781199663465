import React, { useMemo, useState, useCallback } from "react"
import PropTypes from "prop-types"
import makeStyles from "@mui/styles/makeStyles"
import { TreeView } from "@mui/x-tree-view/TreeView"
import { ArrowDropDown, ArrowRight } from "@mui/icons-material"
import Helpers from "tools/Helpers"
import "react-contexify/dist/ReactContexify.css"
import { MemoStyledTreeItemCTA as StyledTreeItemCTA } from "./StyledTreeItemCTA"
import { useHistory } from "react-router-dom"
import { globalSettings } from "variables/general.jsx"

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch",
    maxWidth: "100%"
  },
  drawerPrimaryCard: {
    flexGrow: 1,
    overflow: "auto",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch"
  },
  drawerContent: {
    flexGrow: 0
  },
  drawerPrimaryContent: {
    flexGrow: 1,
    padding: "10px",
    paddingRight: 0
  },
  list: {
    padding: 0
  },
  listItem: {
    margin: 0,
    padding: 0
  },
  listItemIcon: {
    minWidth: "auto"
  },
  checkbox: {
    marginLeft: "17px",
    padding: 0
  },
  cardHeader: {
    padding: "5px 10px"
  },
  cardBody: {
    padding: "5px 10px"
  }
}))

function CTAsExplorer(props) {
  const history = useHistory()
  const classes = useStyles()
  const [selectedSourceType, setSourceType] = useState("")
  const [lastActionType, setLastActionType] = useState("")
  const [myDefaultExpanded, setMyDefaultExpanded] = useState(["2"])
  const [cutSelected, setCutSelected] = useState(false)
  const [copySelected, setCopySelected] = useState(false)
  const [pasteAction, setPasteAction] = useState(false)
  const [doPasteOperation, setDoPasteOperation] = useState(false)

  const { setActiveView, setCtaCreation, activeView, setEditView } = props

  const [orgOfCTA, setOrgOfCTA] = useState(props.orgOfCTA)
  const [hierarchyCTAs, setHierarchyCTAs] = useState(props.hierarchyCTAs)

  const [isLoaded, setIsLoaded] = useState(false)
  const [initialPageLoad, setInitialPageLoad] = useState(true)

  const [selectedSourceId, setSourceId] = useState(0)
  const [selectedLeagueId, setLeagueId] = useState(0)
  const [selectedOrganizationId, setOrganizationId] = useState(0)
  const [targetLeagueId, setTargetLeagueId] = useState(0)
  const [targetOrganizationId, setTargetOrganizationId] = useState(0)

  React.useEffect(() => {
    if (doPasteOperation) {
      processPaste()
      setDoPasteOperation(false)
    }
  }, [doPasteOperation])

  React.useEffect(() => {
    if (lastActionType === "Archive") {
      doArchive()
    }
  }, [lastActionType])

  React.useEffect(() => {
    setOrgOfCTA(props.orgOfCTA)
  }, [props.orgOfCTA])

  React.useEffect(() => {
    setHierarchyCTAs(props.hierarchyCTAs)
    if (Object.keys(props.hierarchyCTAs).length && props.initialLoadedCTA) {
      setIsLoaded(true)
      setMyDefaultExpanded(
        props.defaultExpandedTree && props.defaultExpandedTree.length
          ? props.defaultExpandedTree
          : ["2"]
      )
    } else {
      setIsLoaded(false)
      setMyDefaultExpanded(["2"])
      props.setExplorerLoading()
    }
  }, [props.hierarchyCTAs, props.initialLoadedCTA])

  const resetArchivedCTA = ctaId => {
    //Code to remove just-archived CTA from our list of CTAs
    let newHierarchyCTAs = { ...props.hierarchyCTAs }
    let toArchiveOrg
    let toArchiveLeague
    newHierarchyCTAs &&
      Object.keys(newHierarchyCTAs).forEach(key => {
        Object.keys(newHierarchyCTAs[key]).forEach(league => {
          newHierarchyCTAs[key][league].forEach(itm => {
            if (itm.id === ctaId) {
              itm.isArchived = true
              toArchiveOrg = key
              toArchiveLeague = league
            }
          })
        })
      })
    if (toArchiveOrg && toArchiveLeague) {
      //Delete empty Leagues - it has no CTAs - and empty Orgs - all Leagues have no CTAs
      if (newHierarchyCTAs[toArchiveOrg][toArchiveLeague].length === 1) {
        delete newHierarchyCTAs[toArchiveOrg][toArchiveLeague]
        if (Object.keys(newHierarchyCTAs[toArchiveOrg]).length === 0) {
          delete newHierarchyCTAs[toArchiveOrg]
        }
      }
    }
    setHierarchyCTAs(newHierarchyCTAs)
  }

  const doArchive = () => {
    if (lastActionType === "Archive") {
      let cta
      Object.keys(hierarchyCTAs).forEach(key => {
        Object.keys(hierarchyCTAs[key]).forEach(key2 => {
          hierarchyCTAs[key][key2].forEach(itm => {
            if (Number(itm.id) === Number(selectedSourceId)) {
              cta = itm
            }
          })
        })
      })
      if (cta) {
        if (window.confirm(`Do you want to Archive CTA # ${cta.id}?`)) {
          archiveCTA(cta)
          setLastActionType("")
        } else {
          setLastActionType("")
        }
      }
    }
  }

  const processPaste = () => {
    let cta
    Object.keys(hierarchyCTAs).forEach(key => {
      Object.keys(hierarchyCTAs[key]).forEach(key2 => {
        hierarchyCTAs[key][key2].forEach(itm => {
          if (Number(itm.id) === Number(selectedSourceId)) {
            cta = itm
          }
        })
      })
    })
    let validSameContract = false
    let validSameAffiiate = false
    let sameAffiliateDifferntContractId
    props.organizationContractAffiliate &&
      props.organizationContractAffiliate.forEach(itm => {
        if (
          itm.contractId === cta.contractId &&
          targetOrganizationId === itm.organizationId
        ) {
          validSameContract = true
        } else if (
          itm.affiliateId === cta.callToActionaffiliateId &&
          targetOrganizationId === itm.organizationId
        ) {
          validSameAffiiate = true
          sameAffiliateDifferntContractId = itm.contractId
        }
      })
    if (lastActionType === "Duplicate") {
      callCopyPasteCTA_API(
        selectedSourceId,
        selectedOrganizationId,
        selectedLeagueId,
        cta.contractId,
        1
      )
    } else if (lastActionType === "Copy" || lastActionType === "Cut") {
      if (validSameContract) {
        callCopyPasteCTA_API(
          selectedSourceId,
          targetOrganizationId,
          targetLeagueId,
          cta.contractId,
          lastActionType === "Copy" ? 1 : lastActionType === "Cut" ? 2 : 0
        )
      } else if (validSameAffiiate) {
        callCopyPasteCTA_API(
          selectedSourceId,
          targetOrganizationId,
          targetLeagueId,
          sameAffiliateDifferntContractId,
          lastActionType === "Copy"
            ? 1
            : lastActionType === "Cut"
            ? 2
            : lastActionType === "Duplicate"
            ? 3
            : 0
        )
      } else {
        alert("No contract with affiliate chosen, can not paste here.")
      }
    }
  }

  const archiveCTA = cta => {
    fetch(
      `${globalSettings.maynardBaseUrl}/api/ArchiveCallToAction/${cta.id}`,
      {
        credentials: "include",
        method: "put",
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
          "X-API-Key": `${globalSettings.maynardApiKey}`
        }
      }
    )
      .then(Response => Response && Response.ok === true && Response.json())
      .then(response => {
        resetArchivedCTA(cta.id || cta.ctaId)
        alert("Archived")
      })
      .catch(err => {
        console.error("Error on PUT to /api/ArchiveCallToAction/{cta.id}", err)
      })
  }
  const callCopyPasteCTA_API = (
    id,
    orgId,
    leagueId,
    orgContractId,
    replicationEnum
  ) => {
    const body = {
      sourceCallToActionId: id,
      destinationOrganizationId: orgId,
      destinationLeagueId: leagueId,
      destinationCalltoActionOrganizationContractId: orgContractId,
      replicationEnum: replicationEnum //copy=1, cut=2, duplicate=3
    }
    fetch(`${globalSettings.maynardBaseUrl}/api/CallToActionReplication`, {
      credentials: "include",
      method: "post",
      headers: {
        Accept: "text/plain",
        "Content-Type": "application/json",
        "X-API-Key": `${globalSettings.maynardApiKey}`
      },
      body: JSON.stringify({
        ...body
      })
    })
      .then(Response => Response && Response.ok === true && Response.json())
      .then(response => {
        if (response) {
          alert(`${lastActionType} successful`)
          if (pasteAction) {
            setPasteAction(false)
          }

          props.reload({ id: selectedSourceId }, targetOrganizationId, true)
        }
        setLastActionType("")
      })
      .catch(err =>
        console.error("Error on POST to /api/CallToActionReplication", err)
      )
  }

  const handleTreeChange = useCallback(
    (event, newItem) => {
      let selected = activeView.split(",")
      // Check if there is a bulk select even
      // If there's a blockselection event and it's not a top level abstraction
      if (!!event) {
        // Otherwise add the item directly
        if (event.ctrlKey) {
          if (selected.includes(newItem)) {
            selected = selected.filter(value => value !== newItem)
          } else {
            selected.push(newItem)
          }
          let newActiveView = selected.join(",")
          setActiveView(newActiveView)
        } else {
          setActiveView(newItem)
        }
      } else {
        setActiveView(newItem)
      }
    },
    [activeView, setActiveView]
  )

  const handleCTAcreation = useCallback(
    (event, newItem, itemOrg, itemLeague) => {
      setCtaCreation(newItem, itemOrg, itemLeague)
    },
    [activeView, setActiveView]
  )

  const setEditAction = useCallback(
    (event, newItem, action) => {
      let selected = activeView.split(",")
      // Check if there is a bulk select even
      // If there's a blockselection event and it's not a top level abstraction
      if (["Preview Dependency"].includes(newItem)) {
        if (!event.ctrlKey) {
          if (selected.includes(newItem)) {
            setEditView(newItem)
          } else {
            setEditView(newItem, true)
          }
        } else {
          setEditView(newItem)
        }
      } else if (!!newItem.match("ctaInfo")) {
        if (event.ctrlKey) {
          if (selected.includes(newItem)) {
            setEditView(newItem)
          } else {
            setEditView(newItem, true)
          }
        } else {
          setEditView(newItem)
        }
      }
    },
    [activeView, setEditView]
  )

  const setActionTaken = useCallback(
    currentAction => {
      setLastActionType(currentAction)
      return null
    },
    [activeView, lastActionType]
  )
  const sortCTAs = (a, b) => {
    if (a.callToActionCampaignId < b.callToActionCampaignId) {
      //I am sorting first by callToActionCampaignId, then by the prioritizationOverride, then by name alphabetical order
      return -1
    } else if (a.callToActionCampaignId > b.callToActionCampaignId) {
      return 1
    } else {
      if (a.prioritizationOverride !== b.prioritizationOverride) {
        if (a.prioritizationOverride === 0) {
          // nulls sort after anything else
          return 1
        }
        if (b.prioritizationOverride === 0) {
          return -1
        }
        if (a.prioritizationOverride < b.prioritizationOverride) {
          return -1
        }
        if (a.prioritizationOverride > b.prioritizationOverride) {
          return 1
        }
      } else {
        return a.name && a.name.localeCompare(b.name)
      }
    }
  }

  const buildLabelText = itm => {
    const prioritizationString =
      itm.prioritizationOverride > 0 ? `/${itm.prioritizationOverride}p` : ""
    return `${itm.callToActionCampaignId}${prioritizationString} (${itm.id}) ${itm.name}`
  }

  const treeitemsCTAsHierarchy = useMemo(
    () =>
      hierarchyCTAs &&
      Object.keys(Object.fromEntries(Object.entries(hierarchyCTAs).sort())).map(
        key => (
          <StyledTreeItemCTA
            key={`element-${key}`}
            nodeId={key}
            itemView={`Organization for ${key}`}
            itemOrg={orgOfCTA[key] && orgOfCTA[key].id}
            labelText={`${key}`}
            labelIcon=""
            setActiveViewEvent={handleTreeChange}
            setCtaCreationEvent={handleCTAcreation}
            addLabel={`Add Organization`}
            addView={`org-${key}`}
            isCTA={true}
          >
            {Object.keys(
              Object.fromEntries(Object.entries(hierarchyCTAs[key]).sort())
            ).map((league, leagueIndex) => (
              <StyledTreeItemCTA
                setSourceId={setSourceId}
                setActionTaken={setActionTaken}
                selectedSourceType={selectedSourceType}
                setSourceType={setSourceType}
                key={`element-${key}-${league}`}
                nodeId={`${key}-${league}`}
                itemView={`League for-${key}-${league}`}
                itemOrg={orgOfCTA[key] && orgOfCTA[key].id}
                itemLeague={
                  orgOfCTA[key] &&
                  orgOfCTA[key][league] &&
                  orgOfCTA[key][league].id
                }
                labelText={`${league}`}
                labelIcon=""
                setActiveViewEvent={handleTreeChange}
                setCtaCreationEvent={handleCTAcreation}
                addLabel={`Add League`}
                addView={`lg-${league}`}
                isCTA={true}
                setCutSelected={val => {
                  setCutSelected(val)
                }}
                setCopySelected={val => {
                  setCopySelected(val)
                }}
                setPasteAction={val => {
                  setPasteAction(val)
                }}
                cutSelected={cutSelected}
                copySelected={copySelected}
                setTargetOrganizationId={val => {
                  setTargetOrganizationId(val)
                }}
                setTargetLeagueId={val => {
                  setTargetLeagueId(val)
                }}
                setPromptInExplorer={val => {
                  setDoPasteOperation(true)
                }}
              >
                {hierarchyCTAs[key][league]
                  .sort(function (a, b) {
                    return sortCTAs(a, b)
                  })
                  .map(
                    (itm, itmIndex) =>
                      itm.isArchived !== true && (
                        <StyledTreeItemCTA
                          setActionTaken={setActionTaken}
                          selectedSourceType={selectedSourceType}
                          setSourceType={setSourceType}
                          key={`ctaInfo-${key}-${league}-${itm.id}`}
                          itemView={`ctaInfo-${key}-${league}-${itm.id}-${itm.contractId}`}
                          nodeId={`ctaInfo-${key}-${league}-${itm.id}`}
                          leagueId={itm.leagueId}
                          organizationId={itm.organizationId}
                          labelText={`${itm.callToActionCampaignId}/${itm.prioritizationOverride}p (${itm.id}) ${itm.name}`}
                          labelInfo={
                            itm.triggerType
                              ? Helpers.titleCaseSlug(itm.triggerType)
                              : ""
                          }
                          launchLink={`?ctaId=${itm.id}`}
                          labelIcon=""
                          labelTitle={`${itm.triggerType || ""} ${""}`}
                          setActiveViewEvent={() => {
                            props.toggleCTAs(itm, hierarchyCTAs, key, league)
                            if (initialPageLoad) {
                              setInitialPageLoad(false)
                            }
                            history.push(`/portal/ctas?ctaId=${itm.id}`)
                          }}
                          setEditViewEvent={() => {
                            props.toggleCTAs(itm, hierarchyCTAs, key, league)
                          }}
                          addLabel={`Add Sentence`}
                          addView={`cta-${itm.id}`}
                          isCTA={true}
                          setSourceId={val => {
                            setSourceId(Number(val))
                          }}
                          setLeagueId={val => {
                            setLeagueId(val)
                          }}
                          setOrganizationId={val => {
                            setOrganizationId(val)
                          }}
                          setOrganizationContractId={val => {}}
                          setCutSelected={val => {
                            setCutSelected(val)
                          }}
                          setCopySelected={val => {
                            setCopySelected(val)
                          }}
                          setPasteAction={val => {
                            setPasteAction(val)
                          }}
                          cutSelected={cutSelected}
                          copySelected={copySelected}
                          setPromptInExplorer={val => {
                            setDoPasteOperation(true)
                          }}
                        />
                      )
                  )}
              </StyledTreeItemCTA>
            ))}
          </StyledTreeItemCTA>
        )
      ),
    [hierarchyCTAs, selectedSourceType, selectedSourceId]
  )

  const treeitemsCTAsHierarchyInitial = useMemo(
    () =>
      hierarchyCTAs &&
      Object.keys(Object.fromEntries(Object.entries(hierarchyCTAs).sort())).map(
        key => (
          <StyledTreeItemCTA
            key={`element-${key}`}
            nodeId={key}
            itemView={`Organization for ${key}`}
            itemOrg={orgOfCTA[key] && orgOfCTA[key].id}
            labelText={`${key}`}
            labelIcon=""
            setActiveViewEvent={handleTreeChange}
            setCtaCreationEvent={handleCTAcreation}
            addLabel={`Add Organization`}
            addView={`org-${key}`}
            isCTA={true}
          >
            {Object.keys(
              Object.fromEntries(Object.entries(hierarchyCTAs[key]).sort())
            ).map((league, leagueIndex) => (
              <StyledTreeItemCTA
                setSourceId={setSourceId}
                setActionTaken={setActionTaken}
                selectedSourceType={selectedSourceType}
                setSourceType={setSourceType}
                key={`element-${key}-${league}`}
                nodeId={`${key}-${league}`}
                itemView={`League for-${key}-${league}`}
                itemOrg={orgOfCTA[key] && orgOfCTA[key].id}
                itemLeague={
                  orgOfCTA[key] &&
                  orgOfCTA[key][league] &&
                  orgOfCTA[key][league].id
                }
                labelText={`${league}`}
                labelIcon=""
                setActiveViewEvent={handleTreeChange}
                setCtaCreationEvent={handleCTAcreation}
                addLabel={`Add League`}
                addView={`lg-${league}`}
                isCTA={true}
                setCutSelected={val => {
                  setCutSelected(val)
                }}
                setCopySelected={val => {
                  setCopySelected(val)
                }}
                setPasteAction={val => {
                  setPasteAction(val)
                }}
                cutSelected={cutSelected}
                copySelected={copySelected}
                setTargetOrganizationId={val => {
                  setTargetOrganizationId(val)
                }}
                setTargetLeagueId={val => {
                  setTargetLeagueId(val)
                }}
                setPromptInExplorer={val => {
                  setDoPasteOperation(true)
                }}
              >
                {hierarchyCTAs[key][league]
                  .sort(function (a, b) {
                    return sortCTAs(a, b)
                  })
                  .map(
                    (itm, itmIndex) =>
                      itm.isArchived !== true && (
                        <StyledTreeItemCTA
                          setActionTaken={setActionTaken}
                          selectedSourceType={selectedSourceType}
                          setSourceType={setSourceType}
                          key={`ctaInfo-${key}-${league}-${itm.id}`}
                          itemView={`ctaInfo-${key}-${league}-${itm.id}-${itm.contractId}`}
                          nodeId={`ctaInfo-${key}-${league}-${itm.id}`}
                          leagueId={itm.leagueId}
                          organizationId={itm.organizationId}
                          labelText={buildLabelText(itm)}
                          labelInfo={
                            itm.triggerType
                              ? Helpers.titleCaseSlug(itm.triggerType)
                              : ""
                          }
                          launchLink={`?ctaId=${itm.id}`}
                          labelIcon=""
                          labelTitle={`${itm.triggerType || ""} ${""}`}
                          setActiveViewEvent={() => {
                            props.toggleCTAs(itm, hierarchyCTAs, key, league)
                            if (initialPageLoad) {
                              setInitialPageLoad(false)
                            }
                            history.push(`/portal/ctas?ctaId=${itm.id}`)
                          }}
                          setEditViewEvent={setEditAction}
                          addLabel={`Add CTA`}
                          addView={`ctaView-${itm.id}`}
                          isCTA={true}
                          setSourceId={val => {
                            setSourceId(Number(val))
                          }}
                          setLeagueId={val => {
                            setLeagueId(val)
                          }}
                          setOrganizationId={val => {
                            setOrganizationId(val)
                          }}
                          setTargetOrganizationId={val => {
                            setTargetOrganizationId(val)
                          }}
                          setTargetLeagueId={val => {
                            setTargetLeagueId(val)
                          }}
                          setCutSelected={val => {
                            setCutSelected(val)
                          }}
                          setCopySelected={val => {
                            setCopySelected(val)
                          }}
                          setPasteAction={val => {
                            setPasteAction(val)
                          }}
                          cutSelected={cutSelected}
                          copySelected={copySelected}
                          setPromptInExplorer={val => {
                            setDoPasteOperation(true)
                          }}
                          style={{
                            background:
                              props.ctaInfo &&
                              props.ctaInfo.id === itm.id &&
                              initialPageLoad
                                ? "#ccc"
                                : "#fff"
                          }}
                        />
                      )
                  )}
              </StyledTreeItemCTA>
            ))}
          </StyledTreeItemCTA>
        )
      ),
    [hierarchyCTAs, selectedSourceId, selectedSourceType]
  )

  return (
    <div className={classes.root}>
      <div className={classes.drawerPrimaryCard}>
        {isLoaded && (
          <div className={classes.drawerPrimaryContent}>
            <TreeView
              defaultExpanded={myDefaultExpanded || ["2"]}
              defaultCollapseIcon={<ArrowDropDown />}
              defaultExpandIcon={<ArrowRight />}
              defaultEndIcon={<div style={{ width: 12 }} />}
              selected={activeView.split(",")}
              multiSelect={true}
            >
              <StyledTreeItemCTA
                nodeId={"1"}
                itemView={`Preview Dependency`}
                labelText={"CTA Preview Dependency"}
                labelIcon=""
                setActiveViewEvent={props.showPreviewDependency}
                setEditViewEvent={setEditAction}
                setCtaCreationEvent={handleCTAcreation}
                addLabel={`Add Preview Dependency`}
                addView={`create dependency`}
                isCTA={true}
              />

              <StyledTreeItemCTA
                nodeId={"2"}
                itemView={`CTA Blocks`}
                labelText={"CTA Blocks"}
                labelIcon=""
                setActiveViewEvent={handleTreeChange}
                setEditViewEvent={setEditAction}
                setCtaCreationEvent={handleCTAcreation}
                addLabel={`Add CTA Block`}
                addView={`create CTA`}
                isCTA={true}
                cutSelected={cutSelected}
                copySelected={copySelected}
              >
                {!initialPageLoad && treeitemsCTAsHierarchy}
                {initialPageLoad && treeitemsCTAsHierarchyInitial}
              </StyledTreeItemCTA>
            </TreeView>
          </div>
        )}
      </div>
    </div>
  )
}

CTAsExplorer.propTypes = {
  hierarchyCTAs: PropTypes.object,
  ctaInfo: PropTypes.object,
  orgOfCTA: PropTypes.object,
  loading: PropTypes.bool,
  setActiveView: PropTypes.func,
  setCtaCreation: PropTypes.func,
  setEditEvent: PropTypes.func,
  exportSentence: PropTypes.func,
  exportParagraph: PropTypes.func,
  showPreviewDependency: PropTypes.func,
  toggleCTAs: PropTypes.func,
  exportTrigger: PropTypes.func,
  setExplorerLoading: PropTypes.func,
  reload: PropTypes.func,
  activeView: PropTypes.string,
  defaultExpandedTree: PropTypes.array,
  organizationContractAffiliate: PropTypes.array
}
export default CTAsExplorer
