import { globalSettings } from "variables/general.jsx"
import { put } from "../tools/request"

export default class FoxSportsDAO {
  static analyzeContent = async content => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/foxsportsrelevancy`,
      null,
      {
        content
      }
    )
    return await res.json()
  }
}
