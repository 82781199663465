import { useState } from "react"
import { useObserver } from "mobx-react"

import { useStore } from "contexts/rootContext"
import { EMPTY_ITEM } from "contexts/narrativeMenuActionsContext"
import { ActionType, ItemType } from "constants"
import NarrativeDAO from "daos/narrativeDAO"

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    postCopySentence: store.narrativeStore.postCopySentence,
    postMoveSentence: store.narrativeStore.postMoveSentence,
    narrativeId: store.narrativeStore.narrativeId
  }))
}

export default function useNarrativeMenuActions() {
  const [selectedSourceItem, setSelectedSourceItem] = useState({
    ...EMPTY_ITEM
  })
  const [selectedSourceParentItem, setSelectedSourceParentItem] = useState({
    ...EMPTY_ITEM
  })
  const [targetItem, setTargetItem] = useState({ ...EMPTY_ITEM })
  const [lastActionType, setLastActionType] = useState(null)

  const { narrativeId, postCopySentence, postMoveSentence } = useStoreData()

  const reset = () => {
    setSelectedSourceItem({ ...EMPTY_ITEM })
    setSelectedSourceParentItem({ ...EMPTY_ITEM })
    setTargetItem({ ...EMPTY_ITEM })
    setLastActionType(null)
  }

  const wasParagraphCutSelected = paragraphId => {
    const { type, id } = selectedSourceItem
    return (
      type === ItemType.Paragraph &&
      id === paragraphId &&
      lastActionType === ActionType.Cut
    )
  }

  const wasSentenceCutSelected = sentenceId => {
    const { type, id } = selectedSourceItem
    return (
      type === ItemType.Sentence &&
      id === sentenceId &&
      lastActionType === ActionType.Cut
    )
  }

  const ableToPasteSentence = () =>
    targetItem.type === ItemType.Paragraph &&
    selectedSourceItem.type === ItemType.Sentence &&
    [ActionType.Copy, ActionType.Cut].includes(lastActionType)

  const ableToPasteParagraph = () =>
    targetItem.type === ItemType.Block &&
    selectedSourceItem.type === ItemType.Paragraph &&
    [ActionType.Copy, ActionType.Cut].includes(lastActionType)

  const handlePasteSentence = async () => {
    if (ableToPasteSentence()) {
      if (lastActionType === ActionType.Copy) {
        await postCopySentence(
          selectedSourceItem.id,
          targetItem.id,
          narrativeId
        )
      } else {
        await postMoveSentence(
          selectedSourceItem.id,
          targetItem.id,
          narrativeId
        )
      }
      reset()
    }
  }

  // Copy paragraph between outline blocks within the same narrative
  const copyParagraphToOutlineBlock = async () => {
    await NarrativeDAO.postCopyParagraph({
      SourceId: selectedSourceItem.id,
      TargetNarrativeId: narrativeId,
      TargetNarrativeOutlineBlockId: targetItem.id
    })
  }

  const cutParagraphToOutlineBlock = async () => {
    await NarrativeDAO.postCopyParagraph({
      SourceId: selectedSourceItem.id,
      TargetNarrativeOutlineBlockId: targetItem.id
    })
  }

  const handlePasteParagraph = async () => {
    if (ableToPasteParagraph()) {
      if (lastActionType === ActionType.Copy) {
        await copyParagraphToOutlineBlock()
      } else {
        await cutParagraphToOutlineBlock()
      }
      reset()
    }
  }

  return {
    selectedSourceItem,
    setSelectedSourceItem,
    selectedSourceParentItem,
    setSelectedSourceParentItem,
    targetItem,
    setTargetItem,
    lastActionType,
    setLastActionType,
    ableToPasteSentence,
    ableToPasteParagraph,
    handlePasteSentence,
    handlePasteParagraph,
    wasParagraphCutSelected,
    wasSentenceCutSelected
  }
}
