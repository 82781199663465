import React from "react"
import PropTypes from "prop-types"
import withStyles from "@mui/styles/withStyles"
import InputLabel from "@mui/material/InputLabel"
import PermIdentity from "@mui/icons-material/PermIdentity"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Button from "components/CustomButtons/Button"
import Clearfix from "components/Clearfix/Clearfix"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardHeader from "components/Card/CardHeader"
import CardIcon from "components/Card/CardIcon"
import CardAvatar from "components/Card/CardAvatar"
import accountProfileStyles from "assets/jss/material-dashboard-pro-react/views/accountProfileStyles"
import DialogTitle from "@mui/material/DialogTitle"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import TimezoneSelect from "react-timezone-select"
import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"
import TimeAgo from "react-timeago"
import Helpers from "tools/Helpers.js"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import { Smartphone, Computer } from "@mui/icons-material"
import { TextField, Tooltip } from "@mui/material"
import { globalSettings } from "variables/general"
import Gravatar from "react-gravatar"
import { observer, inject } from "mobx-react"
import { get, put } from "tools/request"

dayjs.extend(timezone)

const useStore = component =>
  inject(({ store }) => ({
    setAccount: store.accountStore.setAccount
  }))(observer(component))

class AccountProfile extends React.Component {
  constructor(props) {
    super(props)
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      username: "",
      password: "",
      isLoggedIn: false,
      isRememberMe: false,
      firstName: "",
      lastName: "",
      email: "",
      isEmailSendable: true,
      aboutMe: "",
      title: "",
      slackUsername: "",
      isSlackUsernameSendable: true,
      smsNumber: "",
      isSMSNumberSendable: true,
      organizationName: "",
      timeZoneIANA: dayjs.tz.guess(), // guess the current browser's timezone
      isFailedDialogOpen: false,
      isSuccessDialogOpen: false,
      accountProfileUpdateFailureReason:
        "Unable to change your account details.",
      isDataDirty: false,
      loginAttemptHistory: [],
      accountStatus: "N/A"
    }
    this.loadAccountDetails()
    this.loadLoginHistory()
  }

  componentDidMount() {
    // wireup the browser window so if the browser is closed or refreshed
    window.onbeforeunload = () => false
  }

  onTitleChange = event => {
    this.setState({ title: event.target.value })
  }

  onFirstNameChange = event => {
    this.setState({ firstName: event.target.value })
  }

  onLastNameChange = event => {
    this.setState({ lastName: event.target.value })
  }

  onEmailChange = event => {
    this.setState({ email: event.target.value })
  }

  onAboutMeChange = event => {
    this.setState({ aboutMe: event.target.value })
  }

  onUsernameChange = event => {
    this.setState({ username: event.target.value })
  }

  onTimeZoneChange = tz => {
    this.setState({ timeZoneIANA: tz.value }) // Just the string value of IANA timezones, this is what we store in the [account] table in the DB.
  }

  onSlackUsernameChange = event => {
    this.setState({ slackUsername: event.target.value })
  }

  onSMSNumberChange = event => {
    this.setState({ smsNumber: event.target.value })
  }

  loadAccountDetails() {
    get(`${globalSettings.apiBaseUrl}/api/account/retrieveaccountdetails`)
      .then(Response => Response.json())
      .then(body => {
        this.setState({
          accountDetails: body.content,
          isAccountDetailsLoaded: true,
          email: body.content.email,
          isEmailSendable: body.content.isEmailSendable,
          username: body.content.username,
          firstName: body.content.firstName,
          lastName: body.content.lastName,
          organizationName: body.content.organization.name,
          title: body.content.title,
          aboutMe: body.content.aboutMe,
          slackUsername: body.content.slackUsername,
          isSlackUsernameSendable: body.content.isSlackUsernameSendable,
          timeZoneIANA: body.content.timeZoneIANA || dayjs.tz.guess(), // if the Portal user hasn't set their preferred timezone, than guess for them
          smsNumber: body.content.smsNumber,
          isSMSNumberSendable: body.content.isSMSNumberSendable,
          accountStatus: body.content.accountStatus.status
        })
        this.setState({ isUnsavedChanges: false })
      })
  }

  loadLoginHistory() {
    get(`${globalSettings.apiBaseUrl}/api/account/retrievelatestloginattempts`)
      .then(Response => Response.json())
      .then(body => {
        this.setState({
          loginAttemptHistory: body.content
        })
      })
  }

  updateAccountDetails() {
    let profile = {}
    profile.id = this.state.accountDetails.id
    profile.username = this.state.username
    profile.email = this.state.email
    profile.firstName = this.state.firstName
    profile.lastName = this.state.lastName
    profile.aboutMe = this.state.aboutMe
    profile.title = this.state.title
    profile.slackUsername = this.state.slackUsername
    profile.smsNumber = this.state.smsNumber
    profile.timeZoneIANA = this.state.timeZoneIANA
    put(
      `${globalSettings.apiBaseUrl}/api/account/updateaccountdetails`,
      null,
      profile
    )
      .then(response => response.json())
      .then(data => {
        if (data.responseCode === 1000) {
          this.props.setAccount(profile)
          this.setState({ isSuccessDialogOpen: true })
          this.setState({ isDataDirty: false })
        } else {
          this.setState({ isFailedDialogOpen: true })
        }
      })
      .catch(() => {
        this.setState({
          accountProfileUpdateFailureReason: "Unable to change account details."
        })
        this.setState({ isFailedDialogOpen: true })
      })
  }

  handleDialogClose = () => {
    this.setState({ isFailedDialogOpen: false })
    this.setState({ isSuccessDialogOpen: false })
  }

  render() {
    const { classes } = this.props
    return (
      <div>
        <React.Fragment>{/* Component JSX */}</React.Fragment>
        <Dialog
          onClose={this.handleDialogClose}
          open={this.state.isFailedDialogOpen}
          aria-labelledby="simple-dialog-title"
        >
          <DialogTitle id="simple-dialog-title">{"Whoops!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {this.state.accountProfileUpdateFailureReason}
            </DialogContentText>
          </DialogContent>
          <DialogActions />
        </Dialog>
        <Dialog
          onClose={this.handleDialogClose}
          open={this.state.isSuccessDialogOpen}
          aria-labelledby="simple-dialog-title"
        >
          <DialogTitle id="simple-dialog-title">{"Success"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Your account profile has been changed.
            </DialogContentText>
            <Button
              onClick={this.handleDialogClose}
              color="primary"
              style={{ float: "right" }}
            >
              Close
            </Button>
          </DialogContent>
        </Dialog>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <PermIdentity />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Edit Profile</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem customPadding xs={12} sm={12} md={6}>
                    <TextField
                      label="Username"
                      id="username"
                      variant="standard"
                      fullWidth
                      inputProps={{
                        disabled: false,
                        value: this.state.username,
                        onChange: this.onUsernameChange
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={10} md={4}>
                    <TextField
                      label="Email address"
                      id="email-address"
                      variant="standard"
                      fullWidth
                      inputProps={{
                        disabled: false,
                        value: this.state.email,
                        onChange: this.onEmailChange
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={2} md={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={true}
                          checked={this.state.isEmailSendable}
                          className={classes.positionAbsolute}
                          tabIndex={-1}
                        />
                      }
                      label={"Is Email Valid?"}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem customPadding xs={12} sm={12} md={6}>
                    <TextField
                      label="Organization"
                      id="organization"
                      variant="standard"
                      fullWidth
                      inputProps={{
                        disabled: true,
                        value: this.state.organizationName
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      label="Title"
                      id="title"
                      variant="standard"
                      fullWidth
                      inputProps={{
                        disabled: false,
                        value: this.state.title,
                        onChange: this.onTitleChange
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem customPadding xs={12} sm={12} md={6}>
                    <TextField
                      label="First Name"
                      id="first-name"
                      variant="standard"
                      fullWidth
                      inputProps={{
                        disabled: false,
                        value: this.state.firstName,
                        onChange: this.onFirstNameChange
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      label="Last Name"
                      id="last-name"
                      variant="standard"
                      fullWidth
                      inputProps={{
                        disabled: false,
                        value: this.state.lastName,
                        onChange: this.onLastNameChange
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem customPadding xs={12} sm={10} md={4}>
                    <TextField
                      label="Slack Username"
                      id="slackUsername"
                      variant="standard"
                      fullWidth
                      inputProps={{
                        disabled: false,
                        value: this.state.slackUsername,
                        onChange: this.onSlackUsernameChange
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={2} md={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={true}
                          checked={this.state.isSlackUsernameSendable}
                          className={classes.positionAbsolute}
                          tabIndex={-1}
                        />
                      }
                      label={"Is Slack Username Valid?"}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={10} md={4}>
                    <TextField
                      label="SMS Number"
                      id="smsNumber"
                      variant="standard"
                      fullWidth
                      inputProps={{
                        disabled: false,
                        value: this.state.smsNumber,
                        onChange: this.onSMSNumberChange
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={2} md={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={true}
                          checked={this.state.isSMSNumberSendable}
                          className={classes.positionAbsolute}
                          tabIndex={-1}
                        />
                      }
                      label={"Is SMS Number Valid?"}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem customPadding xs={12} sm={12} md={6}>
                    <InputLabel style={{ color: "#AAAAAA", fontSize: "12px" }}>
                      Preferred Timezone
                    </InputLabel>
                    <TimezoneSelect
                      value={this.state.timeZoneIANA}
                      onChange={tz => this.onTimeZoneChange(tz)}
                      labelStyle="abbrev"
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem customPadding xs={12} sm={12} md={12}>
                    <InputLabel style={{ color: "#AAAAAA", marginTop: "10px" }}>
                      About me
                    </InputLabel>
                    <TextField
                      label=""
                      id="about-me"
                      variant="standard"
                      fullWidth
                      defaultValue="Default Value"
                      multiline
                      inputProps={{
                        multiline: true,
                        rows: 5,
                        onChange: this.onAboutMeChange,
                        value: this.state.aboutMe
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem customPadding xs={12} sm={12} md={6}>
                    <TextField
                      label="Account Status"
                      id="accountStatus"
                      variant="standard"
                      fullWidth
                      inputProps={{
                        disabled: false,
                        value: this.state.accountStatus
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <Button
                  color="primary"
                  className={classes.updateProfileButton}
                  onClick={() => this.updateAccountDetails()}
                >
                  Update Profile
                </Button>
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card profile>
              <CardAvatar profile>
                <Gravatar email={this.state.email} size={500} default="mp" />
              </CardAvatar>
              <CardBody profile>
                <h6 className={classes.cardCategory}>
                  {this.state.title ? `${this.state.title} - ` : ""}
                  {this.state.organizationName}
                </h6>
                <h4 className={classes.cardTitle}>
                  {this.state.firstName} {this.state.lastName}
                </h4>
                <h5 className={classes.cardTitle}>
                  {this.state.email}{" "}
                  {this.state.username ? `(${this.state.username})` : ""}
                </h5>
                <p className={classes.description}>{this.state.aboutMe}</p>
              </CardBody>
            </Card>
            <Card profile>
              <CardBody profile>
                <h4>Login History</h4>
                <h6>(most recent 50 attempts)</h6>
                <div style={{ height: "300px", overflowY: "scroll" }}>
                  {this.state.loginAttemptHistory.map(l => {
                    let expiresIn = l.isRememberMe ? (
                      <TimeAgo
                        date={l.loginTokenExpirationDateTime}
                        title={Helpers.prettyDateTimeinPacificTimeZone(
                          l.loginTokenExpirationDateTime
                        )}
                      />
                    ) : (
                      ` browser is closed`
                    )
                    let loginFailureReason = ``
                    if (l.isPasswordWrong) {
                      loginFailureReason = "wrong password"
                    }
                    if (l.isAccountLocked) {
                      loginFailureReason = "account locked"
                    }
                    if (!l.isAccountActive) {
                      loginFailureReason = "account inactive"
                    }
                    return (
                      <li
                        style={{
                          textAlign: "left",
                          marginLeft: "20px",
                          color: l.isLoginSuccessful ? "green" : "red",
                          textDecoration:
                            l.loginTokenExpirationDateTime !== null &&
                            new Date(l.loginTokenExpirationDateTime).getTime() <
                              new Date().getTime()
                              ? "line-through"
                              : "initial"
                        }}
                      >
                        {
                          <TimeAgo
                            date={l.requestDateTime}
                            title={Helpers.prettyDateTimeinPacificTimeZone(
                              l.requestDateTime
                            )}
                          />
                        }{" "}
                        -{" "}
                        {!l.isLoginSuccessful
                          ? `${loginFailureReason} failure`
                          : ``}
                        {l.isLoginSuccessful ? (
                          <React.Fragment>
                            remember me until {expiresIn}
                          </React.Fragment>
                        ) : (
                          ``
                        )}
                        &nbsp;
                        <Tooltip title={`${l.id} - ${l.remoteIP}`}>
                          {l.userAgentString.indexOf("iPhone") !== -1 ||
                          l.userAgentString.indexOf("Android") !== -1 ? (
                            <Smartphone />
                          ) : (
                            <Computer />
                          )}
                        </Tooltip>
                      </li>
                    )
                  })}
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

AccountProfile.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(accountProfileStyles)(useStore(AccountProfile))
