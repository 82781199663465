import React, { useCallback } from "react" //useState
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import makeStyles from "@mui/styles/makeStyles"
import { Box, IconButton } from "@mui/material"
import { TreeItem } from "@mui/x-tree-view"
import { Launch } from "@mui/icons-material"
import { Menu, Item, Separator, useContextMenu } from "react-contexify"

export const useTreeItemStyles = makeStyles(theme => ({
  root: {
    display: "inline-block",
    color: theme.palette.text.secondary,
    "&:focus > $content": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: "var(--tree-view-color)"
    },
    "&[aria-selected='true'] > $content": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: "var(--tree-view-color)"
    }
  },
  content: {
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular
    }
  },
  group: {
    marginLeft: theme.spacing(1),
    "& $content": {
      paddingLeft: theme.spacing(1)
    }
  },
  expanded: {},
  label: {
    fontWeight: "inherit",
    color: "inherit"
  },
  labelRoot: {
    display: "flex",
    verticalAlign: "top",
    padding: theme.spacing(0.5, 0),
    position: "relative"
  },
  labelIcon: {
    fontSize: "22px",
    marginLeft: "-7px",
    width: "15px"
  },
  labelDetail: {
    fontWeight: "inherit",
    flexGrow: 1,
    paddingLeft: theme.spacing(0.75)
  },
  labelText: {
    fontWeight: 400,
    color: "#000000",
    flexGrow: 1,
    fontSize: "14px",
    whiteSpace: "nowrap",
    padding: "4px"
  },
  labelInfo: {
    fontSize: "13px"
  },
  iconButton: {
    position: "absolute",
    right: "10px",
    display: "none",
    padding: 0,
    margin: 0,
    "& > span > svg": {
      width: ".6em",
      height: ".6em"
    }
  },
  iconButton2: {
    position: "absolute",
    right: "30px",
    display: "none",
    padding: 0,
    margin: 0,
    "& > span > a > svg": {
      width: ".55em",
      height: ".55em",
      color: "rgba(0, 0, 0, 0.54)"
    }
  },
  labelPosition: {
    color: "#3f51b5",
    fontWeight: "bold",
    flexShrink: 0,
    marginLeft: theme.spacing(0.5)
  },
  labelWeight: {
    fontWeight: "bold",
    fontSize: ".55rem",
    flexShrink: 0
  },
  flex: {
    display: "flex"
  },
  flexmiddle: {
    display: "flex",
    alignItems: "center",
    "&:hover $iconButton, &:hover $iconButton2": {
      display: "inline-block"
    }
  },
  menu: {
    zIndex: 1000,
    borderRadius: "9px"
  }
}))

const StyledTreeItemCTA = props => {
  const classes = useTreeItemStyles()
  const {
    sourceId,

    launchLink,
    addView,
    addLabel,
    itemView,
    itemOrg,
    leagueId,
    organizationId,
    itemLeague,
    position,
    positionCount,
    labelText,
    labelTitle,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    isCTA,
    bgColor,
    contentBlock,
    selectedSourceType,
    setSourceType,
    setSourceId,
    setLeagueId,
    setOrganizationId,
    setOrganizationContractId,
    setTargetLeagueId,
    setTargetOrganizationId,
    setPromptInExplorer,
    setActionTaken,
    setEditViewEvent,
    setActiveViewEvent,
    setCtaCreationEvent,
    setCutSelected,
    setCopySelected,
    setPasteAction,
    cutSelected,
    copySelected,
    ...other
  } = props

  //When we copy new sentences to the outline, this function does not exist and throws an error
  const _setActiveViewEvent = setActiveViewEvent || function () {} //When we copy new sentences to the outline, this function does not exist and throws an error
  const _setCtaCreationEvent = setCtaCreationEvent || function () {}

  const PasteInLeague = isCTA &&
    (copySelected || cutSelected) &&
    itemView &&
    typeof itemView === "string" &&
    !!itemView.match(/League for/) && (
      <Item
        onClick={event => {
          const info = itemView.split("-")
          if (info.length === 3) {
            setPasteAction(true)
            setTargetLeagueId(itemLeague)
            setTargetOrganizationId(itemOrg)
            setPromptInExplorer()
          }
        }}
      >
        Paste
      </Item>
    )

  const PasteInCTA = isCTA &&
    (copySelected || cutSelected) &&
    itemView &&
    typeof itemView === "string" &&
    !!itemView.match(/ctaInfo/) && (
      <Item
        onClick={event => {
          const info = itemView.split("-")
          if (info.length === 5) {
            setPasteAction(true)
            setCopySelected(false)
            setCutSelected(false)
            setSourceId(info[3])
            setLeagueId(leagueId)
            setOrganizationId(organizationId)
            setOrganizationContractId(info[4])
          }
        }}
      >
        Paste
      </Item>
    )

  const TreeContextMenuCTA = useCallback(
    () => (
      <Menu id={itemView || ""} className={classes.menu}>
        {isCTA &&
          itemView &&
          typeof itemView === "string" &&
          (itemView.match(/Organization/) ||
            itemView.match(/League/) ||
            itemView.match(/CTA Blocks/)) && (
            <Item
              onClick={event => {
                _setCtaCreationEvent(event, itemView, itemOrg, itemLeague)
              }}
            >
              Add CTA
            </Item>
          )}
        {isCTA &&
          itemView &&
          typeof itemView === "string" &&
          (!!itemView.match(/ctaInfo/) ||
            !!itemView.match(/Preview Dependency/)) && (
            <Item
              onClick={event => {
                _setActiveViewEvent(event, itemView)
              }}
            >
              Edit
            </Item>
          )}
        {isCTA &&
          itemView &&
          typeof itemView === "string" &&
          !!itemView.match(/ctaInfo/) && (
            <div>
              <Link to={launchLink} target="_blank">
                <Item>
                  <IconButton
                    className={classes.iconButton2}
                    aria-label={"Open in new window"}
                    size="large"
                  >
                    <Launch />
                  </IconButton>
                  Open in new window
                </Item>
              </Link>
            </div>
          )}
        {isCTA &&
          itemView &&
          typeof itemView === "string" &&
          (!!itemView.match(/ctaInfo/) ||
            (itemView.match(/League for/) &&
              (copySelected || cutSelected))) && <Separator />}
        {isCTA &&
          itemView &&
          typeof itemView === "string" &&
          !!itemView.match(/ctaInfo/) && (
            <Item
              onClick={event => {
                const info = itemView.split("-")
                if (info.length === 5) {
                  setCopySelected(true)
                  setCutSelected(false)
                  setActionTaken("Copy")
                  setSourceId(info[3])
                  setLeagueId(leagueId)
                  setOrganizationId(organizationId)
                  setOrganizationContractId(info[4])
                }
              }}
            >
              Copy
            </Item>
          )}
        {isCTA &&
          itemView &&
          typeof itemView === "string" &&
          !!itemView.match(/ctaInfo/) && (
            <Item
              onClick={event => {
                const info = itemView.split("-")
                if (info.length === 5) {
                  setCutSelected(true)
                  setCopySelected(false)
                  setActionTaken("Cut")
                  setSourceId(info[3])
                  setLeagueId(leagueId)
                  setOrganizationId(organizationId)
                  setOrganizationContractId(info[4])
                }
              }}
            >
              Cut
            </Item>
          )}

        {isCTA &&
          itemView &&
          typeof itemView === "string" &&
          !!itemView.match(/ctaInfo/) && (
            <Item
              onClick={event => {
                const info = itemView.split("-")
                if (info.length === 5) {
                  setActionTaken("Duplicate")
                  setSourceId(info[3])
                  setLeagueId(leagueId)
                  setOrganizationId(organizationId)
                  setOrganizationContractId(info[4])
                  setPromptInExplorer()
                }
              }}
            >
              Duplicate
            </Item>
          )}
        {isCTA &&
          itemView &&
          typeof itemView === "string" &&
          !!itemView.match(/ctaInfo/) && (
            <Item
              onClick={event => {
                const info = itemView.split("-")
                if (info.length === 5) {
                  setCopySelected(true)
                  setCutSelected(false)
                  setActionTaken("Archive")
                  setSourceId(info[3])
                  setLeagueId(leagueId)
                  setOrganizationId(organizationId)
                  setOrganizationContractId(info[4])
                }
              }}
            >
              Archive
            </Item>
          )}
        {PasteInLeague}
        {PasteInCTA}
      </Menu>
    ),
    [itemView, itemOrg, itemLeague]
  )
  const { show } = useContextMenu({
    id: itemView
  })
  function handleContextMenu(event) {
    event.stopPropagation()
    show({
      event
    })
  }
  return (
    <>
      <div id={itemView} onContextMenu={handleContextMenu}>
        <TreeItem
          title={labelTitle}
          label={
            <div className={classes.flexmiddle}>
              {LabelIcon && (
                <LabelIcon color="inherit" className={classes.labelIcon} />
              )}
              {position && (
                <div className={classes.labelPosition}>{position}</div>
              )}

              <div className={classes.flex}>
                <Box
                  className={classes.labelDetail}
                  onClick={event => {
                    _setActiveViewEvent(event, itemView)
                  }}
                >
                  <div className={classes.labelText}>{labelText}</div>
                  <div className={classes.labelInfo} color="inherit">
                    {labelInfo}
                  </div>
                </Box>
              </div>
            </div>
          }
          style={{
            "--tree-view-color": color,
            "--tree-view-bg-color": bgColor
          }}
          classes={{
            root: classes.root,
            content: classes.content,
            expanded: classes.expanded,
            group: classes.group,
            label: classes.label
          }}
          {...other}
        />
      </div>
      <TreeContextMenuCTA />
    </>
  )
}

export const MemoStyledTreeItemCTA = React.memo(StyledTreeItemCTA)

StyledTreeItemCTA.propTypes = {
  sourceId: PropTypes.number,
  leagueId: PropTypes.number,
  organizationId: PropTypes.number,
  launchLink: PropTypes.string,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  isCTA: PropTypes.bool,
  // eslint-disable-next-line react/no-typos
  labelInfo: PropTypes.string,
  labelText: PropTypes.string,
  labelTitle: PropTypes.string,
  setActiveViewEvent: PropTypes.func,
  setEditViewEvent: PropTypes.func,
  setEditView: PropTypes.func,
  itemView: PropTypes.string,
  itemOrg: PropTypes.number,
  itemLeague: PropTypes.number,
  addView: PropTypes.string,

  setSourceId: PropTypes.func,
  setLeagueId: PropTypes.func,
  setOrganizationId: PropTypes.func,
  setOrganizationContractId: PropTypes.func,
  setTargetLeagueId: PropTypes.func,
  setTargetOrganizationId: PropTypes.func,
  setPromptInExplorer: PropTypes.func,

  cutSelected: PropTypes.bool,
  copySelected: PropTypes.bool,
  setCutSelected: PropTypes.func,
  setCopySelected: PropTypes.func,
  setPasteAction: PropTypes.func,

  setActionTaken: PropTypes.func,
  selectedSourceType: PropTypes.string,
  setSourceType: PropTypes.func
}
