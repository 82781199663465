import React, { useState } from "react"
import CustomDSDialog from "components/CustomDialogs/CustomDSDialog"
//import CustomDSDialogButtons from "components/CustomDialogs/CustomDSDialogWithButtons"
//MUI components
//MUIv5 update
//import { makeStyles } from "@mui/styles"
import { makeStyles } from "@mui/styles"
import CardHeader from "@mui/material/CardHeader"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import IconButton from "@mui/material/IconButton"
import Button from "components/CustomButtons/Button"
//Icons
import Info from "@mui/icons-material/Info"
import InfoOutlined from "@mui/icons-material/InfoOutlined"
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline"
import Report from "@mui/icons-material/Report"
import Warning from "@mui/icons-material/Warning"

const useStyles = makeStyles({
  dialogCard: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "32px"
  }
})

export default function Dialogs() {
  const classes = useStyles()
  const [infoDialogOpen, setInfoDialogOpen] = useState(false)
  const [warningDialogOpen, setWarningDialogOpen] = useState(false)
  const [successDialogOpen, setSuccessDialogOpen] = useState(false)
  const [failDialogOpen, setFailDialogOpen] = useState(false)
  const [buttonDialogOpen, setButtonDialogOpen] = useState(false)

  //repeating code here only to show handling of open & close of dialogs
  const handleOpenInfoDialog = () => {
    setInfoDialogOpen(true)
  }

  const handleCloseInfoDialog = () => {
    setInfoDialogOpen(false)
  }

  const handleOpenWarningDialog = () => {
    setWarningDialogOpen(true)
  }

  const handleCloseWarningDialog = () => {
    setWarningDialogOpen(false)
  }

  const handleOpenSuccessDialog = () => {
    setSuccessDialogOpen(true)
  }

  const handleCloseSuccessDialog = () => {
    setSuccessDialogOpen(false)
  }

  const handleOpenFailDialog = () => {
    setFailDialogOpen(true)
  }

  const handleCloseFailDialog = () => {
    setFailDialogOpen(false)
  }
  const handleOpenButtonDialog = () => {
    setButtonDialogOpen(true)
  }

  const handleCloseButtonDialog = () => {
    setButtonDialogOpen(false)
  }

  return (
    <Card backgroundColor="primary">
      <CardHeader title="DS Dialogs" />
      <CardBody className={classes.dialogCard}>
        <GridItem>
          <IconButton
            style={{ color: "#4d9ab5" }}
            onClick={handleOpenInfoDialog}
            size="large"
          >
            <Info />
          </IconButton>
          Click here to view the info dialog
          <CustomDSDialog
            open={infoDialogOpen}
            content={"This is an example of an Info Dialog"}
            icon={<InfoOutlined style={{ fontSize: "4rem" }} />}
            onClose={handleCloseInfoDialog}
          />
        </GridItem>
        <GridItem>
          <IconButton
            style={{ color: "#FFC107" }}
            onClick={handleOpenWarningDialog}
            size="large"
          >
            <Warning />
          </IconButton>
          Click here to view warning dialog
          <CustomDSDialog
            open={warningDialogOpen}
            content="This is an example of a Warning Dialog"
            icon={<Warning style={{ fontSize: "4rem" }} />}
            backgroundColor={"#FFC107"}
            onClose={handleCloseWarningDialog}
          />
        </GridItem>
        <GridItem>
          <Button onClick={handleOpenSuccessDialog} color="primary">
            SAVE
          </Button>{" "}
          Click here to view Success dialog
          <CustomDSDialog
            open={successDialogOpen}
            content="This is an example of a Success Dialog"
            icon={<CheckCircleOutline style={{ fontSize: "4rem" }} />}
            backgroundColor={"#66BB6A"}
            onClose={handleCloseSuccessDialog}
          />
        </GridItem>
        <GridItem>
          <Button onClick={handleOpenFailDialog} color="primary">
            SAVE
          </Button>{" "}
          Click here to view Fail dialog
          <CustomDSDialog
            open={failDialogOpen}
            content="This is an example of a Fail Dialog"
            icon={<Report style={{ fontSize: "4rem" }} />}
            backgroundColor={"#A31515"}
            onClose={handleCloseFailDialog}
          />
        </GridItem>
        <GridItem>
          <Button onClick={handleOpenButtonDialog} color="primary">
            Button Dialog
          </Button>{" "}
          Click to view Dialog with Buttons
          <CustomDSDialog
            open={buttonDialogOpen}
            icon={<InfoOutlined style={{ fontSize: "4rem" }} />}
            content={"Dialog Content Here"}
            buttonProps={[
              { label: "Dialog Button", color: "default" },
              { label: "Dialog Button 2", color: "primary" }
            ]}
            onClose={handleCloseButtonDialog}
          />
        </GridItem>
      </CardBody>
    </Card>
  )
}
