import React from "react"
export const NarrativeContext = React.createContext({
  activeView: "content",
  apiOrigin: "",
  contentId: null,
  contentType: "",
  isRecap: false,
  loading: false,
  searchString: "",
  model: "",
  modelName: "",
  modelType: "",
  modelTypeId: null,
  modelUrl: "",
  narrative: {},
  triggers: [],
  triggerTypesBk: {},
  triggerTypes: [],
  triggerCategories: [],
  checkedCategories: [],
  buildParagraphPreview: () => {},
  setActiveView: () => {},
  setActiveTriggerCategories: () => {}
})
