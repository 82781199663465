import { makeStyles } from "@mui/styles"

/**
 * Global Body Style Overrides
 */
export const useGlobalStyles = makeStyles({
  "@global": {
    body: {
      backgroundColor: "#eeeeee",
      color: "#3c4858",
      margin: 0,
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 300,
      lineHeight: "1.5em"
    },
    "body, h1, h2, h3, h4, h5, h6": {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 300,
      lineHeight: "1.5em"
    },
    "blockquote footer:before, blockquote small:before": {
      content: "\\2014 \\00A0"
    },
    h1: {
      fontSize: "3em",
      lineHeight: "1.15em"
    },
    h2: {
      fontSize: "2.6em"
    },
    h3: {
      fontSize: "1.825em",
      lineHeight: "1.4em",
      margin: "20px 0 10px"
    },
    h4: {
      fontSize: "1.3em",
      lineHeight: "1.4em",
      marginTop: 10,
      marginBottom: 10
    },
    h5: {
      fontSize: "1.25em",
      lineHeight: "1.4em",
      marginBottom: 15
    },
    h6: {
      fontSize: "0.8em",
      textTransform: "uppercase",
      fontWeight: 500
    },
    "h4, h5, h6": {
      marginTop: 10,
      marginBottom: 10
    },
    p: {
      margin: "0 0 10px"
    },
    a: {
      color: "#144d69",
      textDecoration: "none"
    },
    "a:hover, a:focus": {
      color: "#144d69",
      textDecoration: "none"
    },
    'a:focus, a:active, button:active, button:focus, button:hover, button::-moz-focus-inner, input[type="reset"]::-moz-focus-inner, input[type="button"]::-moz-focus-inner, input[type="submit"]::-moz-focus-inner, select::-moz-focus-inner, input[type="file"] > input[type="button"]::-moz-focus-inner':
      {
        outline: "0 !important"
      },
    legend: {
      marginBottom: 20,
      fontSize: 21
    },
    output: {
      paddingTop: 8,
      fontSize: 14,
      lineHeight: "1.42857"
    },
    label: {
      fontSize: 14,
      lineHeight: "1.42857",
      color: "#aaaaaa",
      fontWeight: 400
    },
    footer: {
      padding: "15px 0"
    },
    "footer ul": {
      marginBottom: 0,
      padding: 0,
      listStyle: "none"
    },
    "footer ul li": {
      display: "inline-block"
    },
    "footer ul li a": {
      color: "inherit",
      padding: "15px",
      fontWeight: 500,
      fontSize: "12px",
      textTransform: "uppercase",
      borderRadius: "3px",
      textDecoration: "none",
      position: "relative",
      display: "block"
    },
    "footer ul li a:hover": {
      textDecoration: "none"
    }
  }
})
