import React, { useEffect, useState } from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"

import { makeStyles } from "@mui/styles"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Typography,
  TextField,
  Button,
  IconButton,
  Grid
} from "@mui/material"
import GridItem from "components/Grid/GridItem"
import Cancel from "@mui/icons-material/Cancel"
import { ExpandMore } from "@mui/icons-material"
import LinearProgress from "@mui/material/LinearProgress"

const useStyles = makeStyles({
  sectionGrid: {
    display: "flex"
  },
  titleHeaders: {
    padding: "8px 0px",
    fontWeight: "600"
  },
  noVariantstitleHeaders: {
    padding: "8px 0px",
    fontWeight: "600",
    color: "#C00"
  },
  showBtn: {
    paddingLeft: "11px",
    color: "primary"
  }
})

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    getCTAManagerConfig: store.appConfigurationStore.getCTAManagerConfig,
    shouldDisplayCTAManagerV2:
      store.appConfigurationStore.shouldDisplayCTAManagerV2,
    getIntentInfo: store.ctav2Store.getIntentInfo,
    intentInfo: store.ctav2Store.intentInfo,
    linkTypeInfo: store.ctav2Store.linkTypeInfo,
    getLinkTypeInfo: store.ctav2Store.getLinkTypeInfo,
    getVariantGroupInfo: store.ctav2Store.getVariantGroupInfo,
    variantGroupInfo: store.ctav2Store.variantGroupInfo,
    loadingVariantGroupInfo: store.ctav2Store.loadingVariantGroupInfo
  }))
}

export default function CTAAccordion(props) {
  const classes = useStyles()
  const _OFFER_INTENT = 1
  const _DISCLAIMER_INTENT = 2
  const { orgId, leagueId, verticalId, handleAddCTAVariant } = props
  const {
    getCTAManagerConfig,
    getIntentInfo,
    intentInfo,
    getVariantGroupInfo,
    variantGroupInfo,
    loadingVariantGroupInfo
  } = useStoreData()

  const [intentId, setIntentId] = useState("")
  const [selectedIntent, setSelectedIntent] = useState(null)
  const [selectedCompany, setSelectedCompany] = useState(
    intentId === 2 ? [] : null
  )
  const [company, setCompany] = useState([])
  const [linkType, setLinkType] = useState([])
  const [selectedLinkType, setSelectedLinkType] = useState(null)
  const [linkInfo, setLinkInfo] = useState([])
  const [selectedLink, setSelectedLink] = useState(null)
  const [selectedDisplay, setSelectedDisplay] = useState(null)
  const [selectedPriority, setSelectedPriority] = useState(0)
  const [variants, setVariants] = useState([])
  const [loadingContent, setLoadingContent] = useState([])
  const [loadingLinkSelection, setLoadingLinkSelection] = useState(false)
  const [linkSelectionLoaded, setLinkSelectionLoaded] = useState(false)
  const [loadingVariantInfo, setLoadingVariantInfo] = useState(false)
  const [variantInfoLoaded, setVariantInfoLoaded] = useState(false)
  const [displayStyles, setDisplayStyles] = useState([])
  const [priorities, setPriorities] = useState([])
  const [displayedItems, setDisplayedItems] = useState(6)
  const [displayError, setDisplayError] = useState(false)
  const [priorityError, setPriorityError] = useState(false)

  useEffect(() => {
    const fetchConfig = async () => {
      await getCTAManagerConfig()
    }
    fetchConfig()
  }, [])

  useEffect(() => {
    getIntentInfo()
  }, [selectedIntent])

  useEffect(() => {
    if (loadingVariantGroupInfo) {
      setLoadingContent(true)
    } else {
      setLoadingContent(false)
      if (loadingLinkSelection) {
        setLoadingLinkSelection(false)
        setLinkSelectionLoaded(true)
      } else if (loadingVariantInfo) {
        setLoadingVariantInfo(false)
        setVariantInfoLoaded(true)
      }
    }
  }, [loadingVariantGroupInfo])

  useEffect(() => {
    if (variantGroupInfo?.companies) {
      setCompany(variantGroupInfo?.companies)
      setLinkInfo(variantGroupInfo?.links)
      if (intentId !== 2) {
        setLinkType(variantGroupInfo?.linkTypes)
      } else {
        setLinkType([]) // Set linkType as null for intentId === 2
      }
      if (variantGroupInfo.displayStyles) {
        setDisplayStyles(variantGroupInfo.displayStyles)
      }
      if (variantGroupInfo.priorities) {
        setPriorities(variantGroupInfo.priorities)
      }
      if (variantGroupInfo.variantsInGroup) {
        setVariants(variantGroupInfo?.variantsInGroup)
      }
    }
  }, [variantGroupInfo])

  const resetAccordionContent = () => {
    setLoadingLinkSelection(false)
    setLinkSelectionLoaded(false)
    setLoadingVariantInfo(false)
    setVariantInfoLoaded(false)
    setSelectedLinkType(null)
    setSelectedCompany(null)
    setSelectedLink(null)
    setSelectedDisplay(null)
    setSelectedPriority(null)
  }

  const handleIntentChange = (event, newValue, action, option) => {
    setSelectedIntent(newValue)
    if (newValue) {
      getVariantGroupInfo(orgId, leagueId, verticalId, newValue.id)
      setIntentId(newValue.id)
      setLoadingLinkSelection(true)
      setLoadingVariantInfo(false)
      setVariantInfoLoaded(false)
      setSelectedLinkType(null)
      setSelectedCompany(null)
      setSelectedLink(null)
    } else {
      //Clearing out value, reset all
      resetAccordionContent()
    }
  }

  const handleCompanyChange = async (event, newValue) => {
    setSelectedCompany(newValue) // Update selected company
    // Check if newValue is not null
    if (newValue !== null) {
      // Fetch data using newValue.id
      getVariantGroupInfo(
        orgId,
        leagueId,
        verticalId,
        intentId,
        selectedLinkType?.id,
        newValue.id
      )
    } else {
      // Options cleared, handle accordingly (e.g., refetch data without company filter)
      getVariantGroupInfo(orgId, leagueId, verticalId, intentId)
      setSelectedLinkType(null)
    }
    setSelectedLink(null)
    setSelectedDisplay(null)
    setSelectedPriority(null)
  }

  const handleLinkTypeChange = (event, newValue) => {
    setSelectedLinkType(newValue)
    if (newValue) {
      getVariantGroupInfo(orgId, leagueId, verticalId, intentId, newValue.id)
    } else {
      getVariantGroupInfo(orgId, leagueId, verticalId, intentId)
      setSelectedLink(null)
      setSelectedDisplay(null)
      setSelectedPriority(null)
      setSelectedCompany(null)
    }
  }

  const handleShortNameChange = (event, newValue) => {
    setSelectedLink(newValue)
    if (newValue) {
      getVariantGroupInfo(
        orgId,
        leagueId,
        verticalId,
        intentId,
        selectedLinkType?.id,
        selectedCompany?.id,
        newValue.id
      )
      setLoadingVariantInfo(true)
      setVariantInfoLoaded(false)
      setSelectedDisplay(null)
      setSelectedPriority(null)
    }
  }

  const handleDisplayStyleChange = (event, newValue) => {
    setSelectedDisplay(newValue)
    setDisplayError(false)
  }
  const handlePrioritiesChange = (event, newValue) => {
    setSelectedPriority(newValue)
    setPriorityError(false)
  }

  const handleResetAccordion = () => {
    // Reset all state variables to their initial values or appropriate values
    setSelectedIntent(null)
    setSelectedCompany([])
    setLinkType([])
    setSelectedLinkType(null)
    setLinkInfo([])
    setSelectedLink(null)
    setSelectedDisplay(null)
    setSelectedPriority(0)
    setVariants([])
    setLoadingContent(false)
    setLoadingLinkSelection(false)
    setLinkSelectionLoaded(false)
    setLoadingVariantInfo(false)
    setVariantInfoLoaded(false)
    setDisplayedItems(6)
  }

  const handleAddCTA = () => {
    if (selectedDisplay && selectedPriority) {
      const templateCTA = `IncludeCTA '${selectedLink.id}|${selectedDisplay.name}|P${selectedPriority}'`
      if (handleAddCTAVariant) {
        handleAddCTAVariant(templateCTA)
        handleResetAccordion()
      }
    } else {
      if (!selectedDisplay) {
        setDisplayError(true)
      }
      if (!selectedPriority) {
        setPriorityError(true)
      }
    }
  }

  // Filter variants only if selectedDisplay is not null
  const filteredVariants = selectedDisplay
    ? variants.filter(
        variant =>
          variant.displayStyleId === selectedDisplay.id &&
          variant.priority === selectedPriority
      )
    : []

  // "no company" accomodation for Disclaimer Links -
  // API currently sends back all Links for company = none, should send back only ones with no company
  const filteredLinkInfo =
    selectedIntent?.id === _DISCLAIMER_INTENT && selectedCompany?.id === 0
      ? linkInfo.filter(link => link.companies.length === 0)
      : linkInfo

  const totalItems = filteredVariants?.length

  const handleShowMore = () => {
    setDisplayedItems(totalItems)
  }

  const handleHide = () => {
    setDisplayedItems(6)
  }

  const linkDescriptor = `${
    selectedIntent?.id === _DISCLAIMER_INTENT
      ? "DISCLAIMER "
      : selectedIntent?.id === _OFFER_INTENT
      ? "OFFER "
      : ""
  }LINK`

  return (
    <Accordion aria-label="CTAv2 SentenceAccordion Accoridion">
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography sx={{ fontWeight: 700 }}>
          CTA Variant Group Placement
        </Typography>
      </AccordionSummary>
      <AccordionDetails aria-label="CTAv2 SentenceAccordion AccoridionDetails">
        <GridItem>
          <Autocomplete
            aria-label="CTAv2 SentenceAccordion intentAutoc"
            sx={{ width: "230px" }}
            id="intentId"
            options={intentInfo}
            value={selectedIntent}
            getOptionLabel={option => option?.name}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            onChange={handleIntentChange}
            renderInput={params => (
              <TextField
                {...params}
                required
                label="CTA INTENT"
                helperText={
                  selectedIntent ? "" : "Choose an Intent for CTA Options"
                }
              />
            )}
          />
        </GridItem>
        {loadingContent && <LinearProgress style={{ margin: "10px" }} />}
        {selectedIntent && linkSelectionLoaded && (
          <>
            <Typography className={classes.titleHeaders}>
              Link Selection
            </Typography>
            <Grid className={classes.sectionGrid}>
              {selectedIntent.id !== 2 && (
                <GridItem sm={4}>
                  <Autocomplete
                    aria-label="CTAv2 SentenceAccordion linkTypeAutocomplete"
                    fullWidth={true}
                    sx={{ minWidth: "175px" }}
                    id="linkTypeId"
                    options={linkType}
                    value={selectedLinkType}
                    getOptionLabel={option => option?.name}
                    onChange={handleLinkTypeChange}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="LINK TYPE"
                        fullWidth={true}
                      />
                    )}
                    disabled={loadingVariantGroupInfo}
                  />
                </GridItem>
              )}
              <GridItem sm={4}>
                <Autocomplete
                  aria-label="CTAv2 SentenceAccordion companyAutocomplete"
                  sx={{ width: "160px" }}
                  fullWidth
                  id="companiesId"
                  options={company}
                  value={selectedCompany || []}
                  onChange={handleCompanyChange}
                  getOptionLabel={option => option?.name || []}
                  renderInput={params => (
                    <TextField
                      required={selectedIntent?.id === 1}
                      {...params}
                      label="COMPANY"
                      helperText={selectedCompany ? "" : "Company is required"}
                    />
                  )}
                  disabled={loadingVariantGroupInfo}
                />
              </GridItem>
              <GridItem sm={4}>
                <Autocomplete
                  aria-label="CTAv2 SentenceAccordion linkIdAutocomplete"
                  fullWidth
                  sx={{ width: "175px" }}
                  id="linksId"
                  options={filteredLinkInfo}
                  value={selectedLink}
                  onChange={handleShortNameChange}
                  getOptionLabel={option =>
                    option
                      ? `${option?.id} - ${
                          option?.shortNameOffer || option?.linkName
                        }`
                      : null
                  }
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id &&
                    (option?.shortNameOffer === value?.shortNameOffer ||
                      option?.linkName === value?.linkName)
                  }
                  renderInput={params => (
                    <TextField
                      required
                      {...params}
                      label={linkDescriptor}
                      helperText={
                        selectedLink || selectedCompany
                          ? ""
                          : "Must select Company first"
                      }
                    />
                  )}
                  disabled={!selectedCompany || loadingVariantGroupInfo}
                />
              </GridItem>
            </Grid>
          </>
        )}
        {selectedLink && variantInfoLoaded && (
          <>
            <Typography className={classes.titleHeaders}>
              Variant Group Selection
            </Typography>
            <Grid className={classes.sectionGrid}>
              <GridItem>
                <Autocomplete
                  aria-label="CTAv2 SentenceEditor displayAutocomplete"
                  sx={{ width: 200 }}
                  fullWidth
                  options={
                    displayStyles?.filter(
                      option => option.intentId === (intentId === 2 ? 2 : 1)
                    ) || []
                  }
                  value={selectedDisplay || null}
                  getOptionLabel={option => option?.name || ""}
                  size="large"
                  id="displaystylesId"
                  onChange={handleDisplayStyleChange}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      label="DISPLAY STYLE"
                      error={displayError}
                      helperText={
                        displayError ? "Must choose Display Style" : ""
                      }
                    />
                  )}
                />
              </GridItem>
              <GridItem>
                <Autocomplete
                  aria-label="CTAv2 SentenceEditor prioritiesAutocomplete"
                  sx={{ width: 200 }}
                  fullWidth
                  options={priorities || []}
                  value={selectedPriority || null}
                  getOptionLabel={option => option}
                  size="large"
                  id="priorityId"
                  onChange={handlePrioritiesChange}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      label="PRIORITIES"
                      error={priorityError}
                      helperText={priorityError ? "Must choose a Priority" : ""}
                    />
                  )}
                />
              </GridItem>
              <GridItem>
                <IconButton sx={{ color: "#a31515" }}>
                  <Cancel
                    onClick={handleResetAccordion}
                    aria-label="CTAv2 SentenceAccordion cancelBtn"
                  />
                </IconButton>
                <Button
                  aria-label="CTAv2 SentenceAccordion addBtn"
                  size="small"
                  variant="contained"
                  onClick={handleAddCTA}
                  disabled={
                    (!variantInfoLoaded && variants.length === 0) ||
                    displayError ||
                    priorityError ||
                    !selectedDisplay ||
                    !selectedPriority
                  }
                >
                  ADD CTA
                </Button>
              </GridItem>
            </Grid>
          </>
        )}
        {selectedLink &&
          selectedPriority &&
          selectedDisplay &&
          variantInfoLoaded &&
          filteredVariants.length === 0 && (
            <>
              <Grid>
                <GridItem>
                  <Typography className={classes.noVariantstitleHeaders}>
                    NO Variants in currently Selected GROUP
                  </Typography>
                </GridItem>
              </Grid>
            </>
          )}
        {selectedLink &&
          selectedPriority &&
          selectedDisplay &&
          variantInfoLoaded &&
          filteredVariants.length > 0 && (
            <>
              <Grid>
                <GridItem>
                  <Typography className={classes.titleHeaders}>
                    Variants currently in Group Selected:
                  </Typography>
                </GridItem>
              </Grid>
              <Grid
                style={{ display: "flex", flexWrap: "wrap" }}
                className={classes.titleHeaders}
              >
                {filteredVariants?.slice(0, displayedItems)?.map((itm, i) => (
                  <GridItem xs={6}>
                    {itm.id}-{itm.name}
                  </GridItem>
                ))}
              </Grid>
              <Grid className={classes.showBtn}>
                {totalItems > 6 && (
                  <Button
                    aria-label="CTAv2 SentenceAccordion showHideBtn"
                    size="small"
                    onClick={displayedItems === 6 ? handleShowMore : handleHide}
                  >
                    {displayedItems === 6
                      ? `Show (+${totalItems - 6})`
                      : "Hide"}
                  </Button>
                )}
              </Grid>
            </>
          )}
      </AccordionDetails>
    </Accordion>
  )
}
