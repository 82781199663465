import React, { Component } from "react"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardIcon from "components/Card/CardIcon.jsx"
import { TabPanel } from "components/TabPanel/TabPanel"
import { Tab, Tabs, AppBar } from "@mui/material"
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle"
import withStyles from "@mui/styles/withStyles"
import { globalSettings } from "variables/general"
import Button from "components/CustomButtons/Button"
import ReactJson from "react-json-view"
import LayersIcon from "@mui/icons-material/Layers"
import { observer, inject } from "mobx-react"
import { TextField } from "@mui/material"
import { del, get } from "tools/request"

const useStore = component =>
  inject(({ store }) => ({
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization
  }))(observer(component))

class CacheManager extends Component {
  constructor(props) {
    super(props)
    const currentView = 0
    const cacheType = "redis"
    this.state = {
      loading: true,
      cacheStatus: [],
      cacheKeys: [],
      cacheKeyCount: 0,
      cacheKeyPattern: "",
      cacheKeyDelete: "",
      cacheKeyBulkDelete: "",
      now: new Date(),
      currentView,
      cacheType
    }
  }

  componentDidMount() {
    this.props.setShowChangeCurrentOrganization(false)
    this.loadCache()
  }

  loadCache = () => {
    this.setState({
      loading: true
    })
    get(
      `${globalSettings.apiBaseUrl}/api/cachemanager/status?cachetype=${this.state.cacheType}`
    )
      .then(Response => Response.json())
      .then(response => {
        this.setState({
          cacheStatus: response.content,
          cacheKeyCount: response.content.cacheItemCount,
          cacheKeys: response.content.cacheItemKeys,
          loading: false
        })
      })
  }

  reloadCache = cacheType => {
    this.setState({
      loading: true
    })
    get(
      `${globalSettings.apiBaseUrl}/api/cachemanager/status?cachetype=${cacheType}`
    )
      .then(Response => Response.json())
      .then(response => {
        this.setState({
          cacheStatus: response.content,
          cacheKeyCount: response.content.cacheItemCount,
          cacheKeys: response.content.cacheItemKeys,
          loading: false
        })
      })
  }

  onNukeCacheButtonClick = () => {
    if (window.confirm("This will clear all Cache items")) {
      del(`${globalSettings.realtimeAdminBaseUrl}/api/cache`)
      del(`${globalSettings.apiBaseUrl}/api/cachemanager/clear-all-cache`)
        .then(Response => Response.json())
        .then(cache => {
          if (cache && cache.responseMessage) {
            alert(cache.responseMessage)
            this.reloadCache(this.state.cacheType)
          }
        })
    }
  }

  onCacheKeyChange = e => {
    this.setState({ cacheKey: e.target.value })
  }

  onCacheCheckButtonClick = () => {
    get(
      `${globalSettings.apiBaseUrl}/api/cachemanager/inspect?cachekey=${this.state.cacheKey}&cachetype=${this.state.cacheType}`
    )
      .then(Response => Response.json())
      .then(response => {
        this.setState({
          cacheKeys: response.content.value,
          loading: false
        })
      })
  }

  onCacheKeyDeleteChange = e => {
    this.setState({ cacheKeyDelete: e.target.value })
  }

  onCacheDeleteButtonClick = () => {
    del(
      `${globalSettings.apiBaseUrl}/api/cachemanager/delete-from-cache?cachekey=${this.state.cacheKeyDelete}`
    )
      .then(Response => Response.json())
      .then(cache => {
        if (cache && cache.responseMessage) {
          alert(cache.responseMessage)
          this.reloadCache(this.state.cacheType)
        }
      })
  }

  onCacheKeyBulkDeleteChange = e => {
    this.setState({ cacheKeyBulkDelete: e.target.value })
  }

  onCacheBulkDeleteButtonClick = () => {
    del(
      `${globalSettings.apiBaseUrl}/api/cachemanager/bulk-delete?cachepattern=${this.state.cacheKeyBulkDelete}`
    )
      .then(Response => Response.json())
      .then(cache => {
        if (cache && cache.responseMessage) {
          this.setState({
            cacheKeyBulkDelete: ""
          })
          alert(cache.responseMessage)
          this.reloadCache(this.state.cacheType)
        }
      })
  }

  onCacheKeySearchChange = e => {
    this.setState({ cacheKeyPattern: e.target.value })
  }

  onCacheSearchButtonClick = () => {
    get(
      `${globalSettings.apiBaseUrl}/api/cachemanager/search?cacheType=${this.state.cacheType}&pattern=${this.state.cacheKeyPattern}`
    )
      .then(Response => Response.json())
      .then(response => {
        this.setState({
          cacheKeys: response.content.value,
          cacheKeyCount: response.content.value.length,
          loading: false
        })
      })
  }

  onDisplayAllItemsButtonClick = () => {
    this.setState({ cacheKeyPattern: "" })
    this.reloadCache(this.state.cacheType)
  }

  handleTabChange = (event, newValue) => {
    let cacheType = newValue === 0 ? "redis" : "mem"
    this.setState({
      currentView: newValue,
      cacheType: cacheType
    })
    this.reloadCache(cacheType)
  }

  render() {
    if (this.state.loading) {
      return <div>loading...</div>
    }
    const { classes } = this.props
    const { currentView } = this.state

    return (
      <Card className={this.props.embedded ? classes.embedded : ""}>
        {!this.props.embedded && (
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <LayersIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Cache Manager</h4>
          </CardHeader>
        )}
        <div className="update ml-auto mr-auto">
          <Button color="primary" onClick={this.onNukeCacheButtonClick}>
            Nuke Cache
          </Button>
        </div>
        <CardBody>
          <div className={`${classes.tabScrollRoot}`}>
            <AppBar position="static" color="default">
              <Tabs
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                value={currentView}
                onChange={this.handleTabChange}
                aria-label="CacheManager Tabs"
              >
                <Tab label="Redis Cache (External)" />
                <Tab label="Memory Cache (Server)" />
                <Tab label="File System Cache (Server)" />
              </Tabs>
            </AppBar>
          </div>
          <div className="content">
            <form>
              <TabPanel value={currentView} index={0}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card>
                      <CardBody>
                        <h6>{this.state.cacheType} Item Cache Count</h6>
                        <div>{this.state.cacheKeyCount}</div>
                        <GridContainer
                          className={classes.flexDirectionCacheManager}
                        >
                          <GridItem xs={6} sm={3}>
                            <div>
                              <TextField
                                id="cacheKeySearchInput"
                                placeholder="Search Pattern"
                                value={this.state.cacheKeyPattern}
                                onChange={this.onCacheKeySearchChange}
                              />
                              <div className="update ml-auto mr-auto">
                                <Button
                                  color="primary"
                                  onClick={this.onCacheSearchButtonClick}
                                >
                                  Search Cache
                                </Button>
                              </div>
                              <div className="update ml-auto mr-auto">
                                <Button
                                  color="secondary"
                                  onClick={this.onDisplayAllItemsButtonClick}
                                >
                                  Display All Items
                                </Button>
                              </div>
                            </div>
                          </GridItem>
                          <GridItem xs={6} sm={3}>
                            <div>
                              <TextField
                                id="cacheKeyInput"
                                placeholder="Cache Key"
                                value={this.state.cacheKey}
                                onChange={this.onCacheKeyChange}
                              />
                              <div className="update ml-auto mr-auto">
                                <Button
                                  color="primary"
                                  onClick={this.onCacheCheckButtonClick}
                                >
                                  Check Value
                                </Button>
                              </div>
                            </div>
                          </GridItem>
                          <GridItem xs={6} sm={3}>
                            <div>
                              <TextField
                                id="cacheKeyDeleteInput"
                                placeholder={"Delete Key"}
                                value={this.state.cacheKeyDelete}
                                onChange={this.onCacheKeyDeleteChange}
                              />
                              <div className="update ml-auto mr-auto">
                                <Button
                                  color="primary"
                                  onClick={this.onCacheDeleteButtonClick}
                                >
                                  Delete Key
                                </Button>
                              </div>
                            </div>
                          </GridItem>
                          <GridItem xs={6} sm={3}>
                            <div>
                              <TextField
                                id="cacheKeyBulkDeleteInput"
                                placeholder="Delete Pattern"
                                value={this.state.cacheKeyBulkDelete}
                                onChange={this.onCacheKeyBulkDeleteChange}
                              />
                              <div className="update ml-auto mr-auto">
                                <Button
                                  color="primary"
                                  onClick={this.onCacheBulkDeleteButtonClick}
                                >
                                  Bulk Delete
                                </Button>
                              </div>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </TabPanel>
              <TabPanel value={currentView} index={1}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card>
                      <CardBody>
                        <h6>Current Web Server</h6>
                        <div>{this.state.cacheStatus.currentServerIP}</div>
                        <h6>{this.state.cacheType} Item Cache Count</h6>
                        <div>{this.state.cacheKeyCount}</div>
                        <GridContainer className={classes.flexDirection}>
                          <GridItem xs={6} sm={3}>
                            <div>
                              <TextField
                                id="cacheKeySearchInput"
                                placeholder="Search Pattern"
                                value={this.state.cacheKeyPattern}
                                onChange={this.onCacheKeySearchChange}
                              />
                              <div className="update ml-auto mr-auto">
                                <Button
                                  color="primary"
                                  onClick={this.onCacheSearchButtonClick}
                                >
                                  Search Cache
                                </Button>
                              </div>
                              <div className="update ml-auto mr-auto">
                                <Button
                                  color="secondary"
                                  onClick={this.onDisplayAllItemsButtonClick}
                                >
                                  Display All Items
                                </Button>
                              </div>
                            </div>
                          </GridItem>
                          <GridItem xs={6} sm={3}>
                            <div>
                              <TextField
                                placeholder="Cache Key"
                                value={this.state.cacheKey}
                                onChange={this.onCacheKeyChange}
                              />
                              <div className="update ml-auto mr-auto">
                                <Button
                                  color="primary"
                                  onClick={this.onCacheCheckButtonClick}
                                >
                                  Check Value
                                </Button>
                              </div>
                              <div className="update ml-auto mr-auto">
                                <Button
                                  color="secondary"
                                  onClick={this.onCacheSearchButtonClick}
                                >
                                  Reset
                                </Button>
                              </div>
                            </div>
                          </GridItem>
                          <GridItem xs={6} sm={3}>
                            <div>
                              <TextField
                                fplaceholder={"Delete Key"}
                                value={this.state.cacheKeyDelete}
                                onChange={this.onCacheKeyDeleteChange}
                              />
                              <div className="update ml-auto mr-auto">
                                <Button
                                  color="primary"
                                  onClick={this.onCacheDeleteButtonClick}
                                >
                                  Delete Key
                                </Button>
                              </div>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </TabPanel>
              <TabPanel value={currentView} index={2}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card>
                      <CardBody>
                        <h6>File System Cache</h6>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </TabPanel>
              <Card>
                <CardBody>
                  <ReactJson
                    enableClipboard={true}
                    displayObjectSize={false}
                    displayDataTypes={false}
                    src={this.state.cacheKeys}
                    collapsed={false}
                  />
                </CardBody>
              </Card>
            </form>
          </div>
        </CardBody>
      </Card>
    )
  }
}

export default withStyles(regularFormsStyle)(useStore(CacheManager))
