import { fromJS } from "immutable" //TODO: Remove this dependency
import { action, observable, decorate } from "mobx"
import graphicsDAO from "../daos/graphicsDAO"

export default class GraphicsToolStore {
  rootStore
  isLoading
  geographicPlacesList
  teamsList
  playersList
  inputValues

  constructor(rootStore) {
    this.rootStore = rootStore
    this.dehydrate()
  }

  /**
   * Computeds
   */

  /**
   * Methods
   */
  retrieveGeographicPlaces = async () => {
    try {
      this.setIsLoading(true)
      const webResponse = fromJS(
        await graphicsDAO.getGeographicPlacesforGraphicsTool()
      )
      this.setGeographicPlaces(webResponse.get("content").get("places").toJS())
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setIsLoading(false)
    }
  }

  retrieveTeams = async () => {
    try {
      this.setIsLoading(true)
      const webResponse = fromJS(await graphicsDAO.getTeamsForGraphicsTool())
      this.setTeams(webResponse.get("content").get("teams").toJS())
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setIsLoading(false)
    }
  }

  retrievePlayers = async () => {
    try {
      this.setIsLoading(true)
      const webResponse = fromJS(await graphicsDAO.getPlayersForGraphicsTool())
      this.setPlayers(webResponse.get("content").get("players").toJS())
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setIsLoading(false)
    }
  }

  /**
   * Internal Actions
   */
  dehydrate() {
    this.setGeographicPlaces([])
    this.setTeams([])
    this.setPlayers([])
    this.setIsLoading(false)
    this.setInputValues({})
  }

  setIsLoading(value) {
    this.isLoading = value
  }

  setGeographicPlaces(value) {
    this.geographicPlacesList = value
  }

  setTeams(value) {
    this.teamsList = value
  }

  setPlayers(value) {
    this.playersList = value
  }

  setInputValues(value) {
    this.inputValues = value
  }
}

/**
 * object decorators
 */
decorate(GraphicsToolStore, {
  // observables
  teamsList: observable,
  playersList: observable,
  geographicPlacesList: observable,
  isLoading: observable,
  inputValues: observable,

  // actions
  setGeographicPlaces: action.bound,
  setTeams: action.bound,
  setPlayers: action.bound,
  setIsLoading: action.bound,
  setInputValues: action.bound
})
