import React, { useState } from "react"
import PropTypes from "prop-types"
import makeStyles from "@mui/styles/makeStyles"
// MUI Components
import Grid from "@mui/material/Grid"
import InputAdornment from "@mui/material/InputAdornment"
import TextField from "@mui/material/TextField"
import Button from "components/CustomButtons/Button"
import { Autocomplete } from "@mui/material"
import GridItem from "components/Grid/GridItem"
import IconButton from "@mui/material/IconButton"
import Checkbox from "@mui/material/Checkbox"
import AddIcon from "@mui/icons-material/QueueOutlined"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBox"

import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"

dayjs.extend(utc)
const useStyles = makeStyles({
  ctaRegion: {
    display: "flex",
    flexWrap: "nowrap",
    "&.MuiChip-root.MuiAutocomplete-tag.MuiChip-deletable": {
      backgroundColor: "#DDEAEF !important"
    }
  },
  checkRegion: {
    color: "#144e68",
    "&$checked": {
      color: "#144e68"
    }
  },
  payoutAccordion: {
    display: "inline-flex",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    marginBottom: "15px"
  },
  addIconButton: {
    color: "#144e68"
  },
  buttonExceptions: {
    minWidth: "65px",
    minHeight: "20px",
    fontSize: "12px",
    padding: "10px 20px",
    marginRight: "4px"
  },
  payoutDetails: {
    flexDirection: "column"
  },
  headings: {
    display: "inline",
    fontSize: "1.5rem",
    fontWeight: 400
  },
  checkboxLabel: {
    "& .MuiFormControlLabel-label": {
      color: "#000",
      fontWeight: 400
    }
  },
  subtitle: {
    display: "inline",
    color: "#888"
  },
  requiredMsg: {
    color: "#c00",
    fontWeight: "bold",
    backgroundColor: "#f7f7f7"
  },
  formContentContainer: {
    _border: "1px solid #AAA",
    margin: "15px",
    background: "#fff",
    _borderRadius: "20px",
    _minWidth: "1200px",
    overflowX: "auto"
  },
  contractSelect: {
    minWidth: 180
  }
})

function CTAPayoutExceptionMulti(props) {
  // Variable Assignment
  const classes = useStyles()
  const {
    myDetails,
    chosenRegions,
    existingPayoutsInDB,
    defaultPayout,
    allRegions
  } = props
  const originalPayoutPercentage = defaultPayout
  const [payoutPercentage, setPayoutPercentage] = useState(
    originalPayoutPercentage || 0
  )
  const [editMode, setEditMode] = useState(false)
  const [newEntryUpdated, setNewEntryUpdated] = useState(false)
  const [ourRegions, setOurRegions] = useState(chosenRegions)
  const [currentDetails, setCurrentDetails] = useState(myDetails)

  const _USA_REGION = 237

  const handleChangeNumber = (event, name) => {
    let myNumber = event.target.value
    if (myNumber) {
      //Check for any character not a number or a single ".", and reject it
      let tmp = myNumber
      if (
        tmp.replace(/\d|\./g, "") ||
        (tmp.match(/\./g) && tmp.match(/\./g).length > 1)
      ) {
        return
      }
      tmp = myNumber.split(/\./)
      if (tmp[1] && tmp[1].length > 2) {
        return
      }
      setEditMode(true)
      myNumber = props.isConversionPercentPayOut
        ? Number(myNumber) > 100
          ? 100
          : Number(myNumber)
        : Number(myNumber)
      setPayoutPercentage(myNumber)
      props.editingException(props.myIndex, true)
      let oldVals = []
      let newDetails = []
      currentDetails.forEach(itm => {
        if (itm.id) {
          oldVals.push(itm.id)
        }
      })
      currentDetails.forEach(itm => {
        itm.payoutPercentage = myNumber
        itm.payoutException = true
        newDetails.push(itm)
      })
      setCurrentDetails(newDetails)
    } else {
      setPayoutPercentage()
    }
  }

  const checkForDuplicatePayoutExceptions = () => {
    if (props.exceptionPayouts?.length > 1) {
      let repeat = false
      props.exceptionPayouts.forEach((itm, i) => {
        if (i !== props.myIndex && payoutPercentage === itm) {
          repeat = true
        }
      })
      if (repeat) {
        alert(
          `You already have a Payout Exception with ${payoutPercentage} as the value.`
        )
        setPayoutPercentage(0)
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const saveNewException = e => {
    if (!payoutPercentage) {
      alert("You must have a Payout Exception value")
      setPayoutPercentage(0)
      e.preventDefault()
      return
    } else if (
      currentDetails[0] &&
      currentDetails[0].id &&
      (currentDetails[0].payoutPercentage || payoutPercentage)
    ) {
      if (props.nonExceptionPayout === payoutPercentage) {
        alert(
          "You can't have an exception Payout with the same value as the Default Payout Exception"
        )
        setPayoutPercentage(0)
        e.preventDefault()
        return
      }
      sendAllAutocompleteChanges()
      setEditMode(false)
      setNewEntryUpdated(true)
      props.editingException(props.myIndex, false)
    } else {
      alert("Add at least one Exception with a Payout before you can Apply")
    }
  }

  const sendAllAutocompleteChanges = () => {
    if (currentDetails.length) {
      props.updateSelectedRegionsOnBlur(currentDetails, props.myIndex)
    } else {
      props.removeFormField(props.myIndex)
    }
  }

  const handleAutoCompleteMultiChange = (event, newValue, reason, name) => {
    if (!event || typeof event === "string" || !newValue) {
      return
    }
    let newVals = []
    let oldVals = []
    let newDetails = []

    if (newValue.length === myOptions.length) {
      alert(
        "You cannot select ALL the Regions to have Payout Exceptions, must have at least 1 default"
      )
      return
    }
    currentDetails.forEach(itm => {
      if (itm.id) {
        oldVals.push(itm.id)
      }
    })
    if (!props.myIndex && oldVals.length > newValue.length) {
      return
    }
    newValue.forEach(itm => {
      let found = false
      newVals.push(itm.id)
      myDetails.forEach(itm2 => {
        if (itm.id === itm2.id) {
          newDetails.push(itm2)
          found = true
        }
      })
      if (!found) {
        let notFoundInDb_Entries = true
        existingPayoutsInDB.forEach(itm2 => {
          if (itm.id === itm2.id) {
            let tmp = {
              id: itm2.id,
              payoutPercentage: payoutPercentage,
              payoutException: true,
              indexId: itm2.indexId ? itm2.indexId : 0
            }
            newDetails.push(tmp)
            notFoundInDb_Entries = false
          }
        })
        if (notFoundInDb_Entries) {
          allRegions.forEach(itm2 => {
            if (itm.id === itm2.id) {
              let tmp = {
                id: itm2.id,
                payoutPercentage: payoutPercentage,
                payoutException: true,
                indexId: 0
              }
              newDetails.push(tmp)
            }
          })
        }
      }
    })

    setCurrentDetails(newDetails)
    setOurRegions(newVals)
    setEditMode(true)
    if (payoutPercentage) {
      props.editingException(props.myIndex, true)
    }
  }

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = (
    <CheckBoxIcon fontSize="small" className={classes.checkRegion} />
  )

  const regionIdToIdInDB = []
  existingPayoutsInDB?.forEach(itm => {
    regionIdToIdInDB.push({ regionId: itm.id, indexId: itm.indexId })
  })

  const allUSA = chosenRegions.filter(itm => itm === _USA_REGION).length
    ? true
    : false

  const myOptions = allRegions.filter(itm => {
    let found = allUSA
      ? true
      : !!chosenRegions.filter(crgn => itm.id === crgn).length
    if (!found) {
      return false
    }
    let alreadySelected = false
    props.regionByPayout?.forEach((itm3, i) => {
      if (i && i !== props.myIndex) {
        itm3.forEach(itm4 => {
          if (itm4.id === itm.id) {
            alreadySelected = true
          }
        })
      }
    })
    return found && !alreadySelected
  })

  React.useEffect(() => {
    if (ourRegions.length !== chosenRegions.length) {
      setOurRegions(chosenRegions)
    }
  }, [chosenRegions])

  React.useEffect(() => {
    setCurrentDetails(myDetails)
    setPayoutPercentage(props.defaultPayout)
  }, [myDetails])

  React.useEffect(() => {
    if (props.resetPayoutExceptions) {
      setEditMode(false)
      setCurrentDetails(myDetails)
      setPayoutPercentage(props.defaultPayout)
    }
  }, [props.resetPayoutExceptions])

  React.useEffect(() => {
    if (props.nonExceptionPayout === 0) {
      setPayoutPercentage(0)
    }
  }, [props.isConversionPercentPayOut])

  return (
    <>
      <Grid className={classes.payoutAccordion}>
        <GridItem>
          <Autocomplete
            multiple={true}
            style={{ width: "400px" }}
            className={classes.ctaRegion}
            limitTags={3}
            disableCloseOnSelect={true}
            options={myOptions}
            variant="outlined"
            value={myOptions.filter(rgn => {
              let match = false
              currentDetails &&
                currentDetails.forEach(itm => {
                  if (itm.id === rgn.id) {
                    match = true
                  }
                })
              return match
            })}
            getOptionLabel={option => option.geoNameAdmin1Code}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={selected}
                />
                {option.geoNameAdmin1Code}
              </li>
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="REGION"
                required={true}
                error={props.ctaFormErrors?.regions}
                helperText={
                  props.ctaFormErrors?.regions ? "Region is Required" : ""
                }
              />
            )}
            onChange={(event, newValue, reason) => {
              handleAutoCompleteMultiChange(event, newValue, reason, "regionId")
            }}
          />
        </GridItem>
        <GridItem>
          <TextField
            id="payoutException"
            className={classes.autocompleteCTA}
            type="number"
            name="callToActionPayOff"
            value={props.myIndex ? payoutPercentage : props.defaultPayout}
            variant="outlined"
            fullWidth={true}
            label="CONVERSION PAYOUT"
            decimalscale={2}
            required={true}
            error={props.ctaFormErrors?.callToActionPayOff}
            helperText={
              props.ctaFormErrors?.callToActionPayOff
                ? "Payout Amount is Required"
                : ""
            }
            onFocus={e => {
              if (e.target.value === "0") {
                setPayoutPercentage("")
              }
            }}
            onChange={event => {
              if (props.myIndex) {
                handleChangeNumber(event, "payoutPercentage")
              }
            }}
            onBlur={e => {
              if (props.myIndex) {
                if (checkForDuplicatePayoutExceptions()) {
                  return
                }
                props.updatePayouts(payoutPercentage, props.myIndex)
                if (e.target.value === "") {
                  setPayoutPercentage(0)
                }
              }
            }}
            // InputLabelProps={{
            //   style: { pointerEvents: "auto" }
            // }}
            InputProps={
              !props.isConversionPercentPayOut
                ? {
                    startAdornment: (
                      <InputAdornment position="start" tabIndex={-1}>
                        $
                      </InputAdornment>
                    )
                  }
                : {
                    endAdornment: (
                      <InputAdornment position="start" tabIndex={-1}>
                        %
                      </InputAdornment>
                    )
                  }
            }
          />
        </GridItem>

        <GridItem>
          {!editMode && (!props.isNew || newEntryUpdated) && (
            <IconButton
              className={classes.addIconButton}
              onClick={props.addFormField}
              disabled={props.exceptionsBeingEdited}
              size="large"
            >
              <AddIcon />
            </IconButton>
          )}
          <IconButton
            onClick={() => {
              setEditMode(false)
              props.removeFormField(props.myIndex)
              props.editingException(props.myIndex, false)
            }}
            size="large"
          >
            <DeleteIcon className={classes.addIconButton} />
          </IconButton>
        </GridItem>
        {editMode && (
          <GridItem>
            <Button
              className={classes.buttonExceptions}
              variant="contained"
              size="sm"
              color="primary"
              type="submit"
              onClick={e => {
                saveNewException(e)
              }}
              disabled={!editMode}
            >
              Apply
            </Button>
          </GridItem>
        )}
      </Grid>
    </>
  )
}

CTAPayoutExceptionMulti.propTypes = {
  allRegions: PropTypes.array,
  chosenRegions: PropTypes.array,
  updateSelectedRegions: PropTypes.func,
  updateSelectedRegionsOnBlur: PropTypes.func,
  payoutExceptionsExist: PropTypes.func,
  addFormField: PropTypes.func,
  removeFormField: PropTypes.func,
  editingException: PropTypes.func,
  reload: PropTypes.func,
  myDetails: PropTypes.array,
  myIndex: PropTypes.number,
  ctaId: PropTypes.number,
  existingPayoutsInDB: PropTypes.array,
  existingPayouts: PropTypes.array,
  exceptionPayouts: PropTypes.array,
  regionByPayout: PropTypes.array,
  isNew: PropTypes.bool,
  resetPayoutExceptions: PropTypes.bool,
  exceptionsBeingEdited: PropTypes.bool,
  isConversionPercentPayOut: PropTypes.bool
}

export default CTAPayoutExceptionMulti
