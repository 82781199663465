import { action, observable, decorate } from "mobx"
import RealTimeDAO from "daos/realTimeDAO"

export default class RealTimeStore {
  rootStore
  realTimeLibraries
  realTimeLibraryById
  realTimeOutputs
  realTimeOutput
  allRealTimeOutputs
  insightType
  insightTypes
  insightGroup
  insightSentence
  insightStatus
  libraryStatus
  referencePeriod
  leagueModelTypes
  insightTypeToModelType
  modelTypes
  insightLeague
  modelTypeById
  modelCriteriaBySentence

  constructor(rootStore) {
    this.rootStore = rootStore
    this.dehydrate()
  }
  /**
   * Computeds
   */
  //   isLibrarySelected() {
  //     return !!this.libraryId
  //   }
  /**
   * Methods
   */
  //library

  getRealTimeOutput = async id => {
    try {
      const realTimeOutput = await RealTimeDAO.getRealTimeOutput(id)
      this.setRealTimeOutput(realTimeOutput)
    } catch (err) {
      console.error("Error: No realtime outputs", {}, err)
    }
  }

  getRealTimeOutputs = async orgId => {
    try {
      const realTimeOutputs = await RealTimeDAO.getRealTimeOutputs(orgId)
      this.setRealTimeOutputs(realTimeOutputs)
    } catch (err) {
      console.error("Error: No realtime outputs", {}, err)
    }
  }

  getAllRealTimeOutputs = async () => {
    try {
      const allRealTimeOutputs = await RealTimeDAO.getAllRealTimeOutputs()
      this.setAllRealTimeOutputs(allRealTimeOutputs)
    } catch (err) {
      console.error("Error: No realtime outputs", {}, err)
    }
  }

  getRealTimeLibraries = async () => {
    try {
      const realTimeLibraries = await RealTimeDAO.getRealTimeLibraries()
      this.setRealTimeLibraries(realTimeLibraries)
    } catch (err) {
      console.error("Error: No realtime library", {}, err)
    }
  }

  getRealTimeLibraryById = async id => {
    try {
      const realTimeLibraryById = await RealTimeDAO.getRealTimeLibraryById(id)
      this.setRealTimeLibraryById(realTimeLibraryById)
    } catch (err) {
      console.error("Error: No realtime library Id", {}, err)
    }
  }

  postRealTimeLibrary = async body => {
    try {
      const newRealTimeLibrary = await RealTimeDAO.postRealTimeLibrary(body)
      this.addLibrary(newRealTimeLibrary)
    } catch (err) {
      console.error("Error: Unable to post new library", {}, err)
    }
  }

  putRealTimeLibrary = async (body, id) => {
    try {
      const updatedRealTimeLibrary = await RealTimeDAO.putRealTimeLibraryById(
        body,
        id
      )
      this.putLibraryById(id, updatedRealTimeLibrary)
    } catch (err) {
      console.error("Error: Unable to updatelibrary", {}, err)
    }
  }
  archiveLibrary = async id => {
    try {
      const archiveRealTimeLibrary = await RealTimeDAO.archiveLibrary(id)
      this.deleteLibraryById(id, archiveRealTimeLibrary)
    } catch (err) {
      console.error("Error: Unable to archive library", {}, err)
    }
  }

  //insight
  getInsights = async matchId => {
    try {
      const insights = await RealTimeDAO.getInsights(matchId)
      this.setInsights(insights)
    } catch (err) {
      console.log("Data Error: No insight", {}, err)
    }
  }
  //Insight Type
  getInsightTypes = async leagueId => {
    try {
      const insightTypes = await RealTimeDAO.getInsightTypes(leagueId)
      this.setInsightTypes(insightTypes)
    } catch (err) {
      console.log("Error: No insightTypes", {}, err)
    }
  }
  getInsightType = async id => {
    try {
      const insightTypeByID = await RealTimeDAO.getInsightType(id)
      this.setInsightType(insightTypeByID)
    } catch (err) {
      console.log("Error: No insightType", {}, err)
    }
  }

  //Group
  getInsightGroup = async libraryId => {
    try {
      const insightGroup = await RealTimeDAO.getInsightGroup(libraryId)
      this.setInsightGroup(insightGroup)
    } catch (err) {
      console.log("Error: No insightGroup", {}, err)
    }
  }
  postInsightGroup = async body => {
    try {
      const newInsightGroup = await RealTimeDAO.postInsightGroup(body, null)
      this.addInsightGroup(newInsightGroup)
    } catch (err) {
      console.error("Error: Unable to post new insightGroup", {}, err)
    }
  }
  putInsightGroup = async body => {
    try {
      const id = await RealTimeDAO.updateInsightGroup(body, body.id)
      let updatedGroup = await RealTimeDAO.getInsightGroupById(id)
      this.updateInsightGroup(updatedGroup)
    } catch (err) {
      console.error("Error: Unable to update InsightGroup", {}, err)
    }
  }
  //Sentence
  getInsightSentence = async libraryId => {
    try {
      const insightSentence = await RealTimeDAO.getInsightSentence(libraryId)
      this.setInsightSentence(insightSentence)
    } catch (err) {
      console.log("Error: No insightSentence", {}, err)
    }
  }

  postInsightSentence = async body => {
    try {
      const newInsightSentence = await RealTimeDAO.postInsightSentence(
        body,
        null
      )
      this.addInsightSentence(newInsightSentence)
    } catch (err) {
      console.log("Error: unable to save sentence", {}, err)
    }
  }

  putInsightSentence = async body => {
    try {
      const id = await RealTimeDAO.updateInsightSentence(body, body.id)
      let updatedSentence = await RealTimeDAO.getInsightSentenceById(id)
      this.updateInsightSentence(updatedSentence)
    } catch (err) {
      console.error("Error: Unable to update InsightSentence", {}, err)
    }
  }

  //Others
  getInsightStatus = async () => {
    try {
      const insightStatus = await RealTimeDAO.getInsightStatus()
      this.setInsightStatus(insightStatus)
    } catch (err) {
      console.log("Error: No insightStatus", {}, err)
    }
  }
  getLibraryStatus = async () => {
    try {
      const libraryStatus = await RealTimeDAO.getLibraryStatus()
      this.setLibraryStatus(libraryStatus)
    } catch (err) {
      console.log("Error: No LibraryStatus", {}, err)
    }
  }
  getReferencePeriod = async () => {
    try {
      const referencePeriod = await RealTimeDAO.getReferencePeriod()
      this.setReferencePeriod(referencePeriod)
    } catch (err) {
      console.log("Error: No referencePeriod", {}, err)
    }
  }
  getLeagueModelTypes = async leagueId => {
    try {
      const leagueModelTypes = await RealTimeDAO.getModelTypesByLeague(leagueId)
      let insightTypeToModelType = new Map()
      leagueModelTypes.forEach(mt => {
        mt.insightTypes.forEach(it => {
          insightTypeToModelType.set(it.id, mt.id)
        })
      })
      this.setInsightTypeToModelType(insightTypeToModelType)
      this.setLeagueModelTypes(leagueModelTypes)
    } catch (err) {
      console.log("Error: No modelTypes", {}, err)
    }
  }
  getModelTypes = async () => {
    try {
      const modelTypes = await RealTimeDAO.getModelTypes()
      this.setModelTypes(modelTypes)
    } catch (err) {
      console.log("Error: No modelTypes", {}, err)
    }
  }
  getModelTypeById = async id => {
    try {
      const modelTypeById = await RealTimeDAO.getModelTypeById(id)
      this.setModelTypeById(modelTypeById)
    } catch (err) {
      console.log("Error: No modelTypeId", {}, err)
    }
  }

  getRealTimeLeague = async () => {
    try {
      const insightLeague = await RealTimeDAO.getRealTimeLeague()
      this.setRealTimeLeague(insightLeague)
    } catch (err) {
      console.log("Error fetching league:", {}, err)
    }
  }

  getModelCriteriaBySentence = async sentenceId => {
    try {
      const modelCriteriaBySentence =
        await RealTimeDAO.getModelCriteriaBySentence(sentenceId)
      this.setModelCriteriaBySentence(modelCriteriaBySentence)
    } catch (err) {
      console.log("Error: did not fetch model criteria", {}, err)
    }
  }

  /**
   * Internal Actions
   */
  dehydrate() {
    this.setRealTimeLibraries([])
    this.setRealTimeOutputs([])
    this.setRealTimeOutput([])
    this.setAllRealTimeOutputs([])
    this.setInsightType([])
    this.setInsightTypes([])
    this.setRealTimeLibraryById()
    this.setInsightGroup([])
    this.setInsightSentence([])
    this.setInsightStatus([])
    this.setLibraryStatus([])
    this.setReferencePeriod([])
    this.setLeagueModelTypes([])
    this.setInsightTypeToModelType(new Map())
    this.setModelTypes([])
    this.setRealTimeLeague([])
    this.setModelTypeById([])
    this.setModelCriteriaBySentence([])
  }
  setAllRealTimeOutputs(value) {
    this.allRealTimeOutputs = value
  }
  setRealTimeOutputs(value) {
    this.realTimeOutputs = value
  }
  setRealTimeOutput(value) {
    this.realTimeOutput = value
  }
  setRealTimeLibraries(value) {
    this.realTimeLibraries = value
  }
  setRealTimeLibraryById(value) {
    this.realTimeLibraryById = value
  }
  setInsightTypes(value) {
    this.insightTypes = value
  }
  setInsightType(value) {
    this.insightType = value
  }
  setInsightGroup(value) {
    this.insightGroup = value
  }
  setInsightSentence(value) {
    this.insightSentence = value
  }
  setInsightStatus(value) {
    this.insightStatus = value
  }
  setLibraryStatus(value) {
    this.libraryStatus = value
  }
  setReferencePeriod(value) {
    this.referencePeriod = value
  }
  addInsightType(value) {
    this.insightType.push(value)
  }
  setLeagueModelTypes(value) {
    this.leagueModelTypes = value
  }
  setInsightTypeToModelType(value) {
    this.insightTypeToModelType = value
  }
  setModelTypes(value) {
    this.modelTypes = value
  }
  addLibrary(value) {
    this.realTimeLibraries.push(value)
  }
  putLibraryById(id, updatedLibrary) {
    const index = this.realTimeLibraries.findIndex(library => library.id === id)
    if (index !== -1) {
      this.realTimeLibraries[index] = updatedLibrary
    }
  }
  addInsightGroup(value) {
    this.insightGroup.push(value)
  }
  updateInsightGroup(value) {
    //this.removeInsightGroup(value.id)
    //this.addInsightGroup(value)
  }
  addInsightSentence(value) {
    this.insightSentence.push(value)
  }
  updateInsightSentence(value) {
    //console.log("breakpoint")
    this.removeInsightSentence(value.id)
    this.addInsightSentence(value)
  }
  setRealTimeLeague(value) {
    this.insightLeague = value
  }
  setModelTypeById(value) {
    this.modelTypeById = value
  }
  setModelCriteriaBySentence(value) {
    this.modelCriteriaBySentence = value
  }
}
/**
 * object decorators
 */
decorate(RealTimeStore, {
  //isLibrarySelected: computed,
  realTimeLibraries: observable,
  realTimeLibraryById: observable,
  realTimeOutputs: observable,
  realTimeOutput: observable,
  allRealTimeOutputs: observable,
  insightType: observable,
  insightTypes: observable,
  insightGroup: observable,
  insightSentence: observable,
  insightStatus: observable,
  libraryStatus: observable,
  referencePeriod: observable,
  leagueModelTypes: observable,
  insightTypeToModelType: observable,
  modelTypes: observable,
  insightLeague: observable,
  modelTypeById: observable,
  modelCriteriaBySentence: observable,

  //actions
  getRealTimeLibraries: action.bound,
  getRealTimeOutputs: action.bound,
  getRealTimeOutput: action.bound,
  getAllRealTimeOutputs: action.bound,
  getRealTimeLibraryById: action.bound,
  getInsightType: action.bound,
  getInsightTypes: action.bound,
  getInsightGroup: action.bound,
  getInsightSentence: action.bound,
  getInsightStatus: action.bound,
  getLibraryStatus: action.bound,
  getReferencePeriod: action.bound,
  getLeagueModelTypes: action.bound,
  getModelTypes: action.bound,
  getRealTimeLeague: action.bound,
  getModelTypeById: action.bound,
  getModelCriteriaBySentence: action.bound,

  setRealTimeLibraries: action.bound,
  setRealTimeOutputs: action.bound,
  setRealTimeOutput: action.bound,
  setAllRealTimeOutputs: action.bound,
  setRealTimeLibraryById: action.bound,
  setInsightTypes: action.bound,
  setInsightType: action.bound,
  setInsightGroup: action.bound,
  setInsightSentence: action.bound,
  setInsightStatus: action.bound,
  setLibraryStatus: action.bound,
  setReferencePeriod: action.bound,
  setLeagueModelTypes: action.bound,
  setInsightTypeToModelType: action.bound,
  setModelTypes: action.bound,
  setRealTimeLeague: action.bound,
  setModelTypeById: action.bound,
  setModelCriteriaBySentence: action.bound,

  postRealTimeLibrary: action.bound,
  postInsightGroup: action.bound,
  putInsightGroup: action.bound,
  postInsightSentence: action.bound,
  putInsightSentence: action.bound,

  updateLibraryById: action.bound,
  updateInsightGroupById: action.bound
})
