import { useState } from "react"

const useFindAndReplaceControls = ({ initialSelectedFields }) => {
  const [searchString, setSearchString] = useState("")
  const [replaceString, setReplaceString] = useState("")
  const [selectedFields, setSelectedFields] = useState(initialSelectedFields)

  const clearInputs = () => {
    setSearchString("")
    setReplaceString("")
    setSelectedFields(initialSelectedFields)
  }

  const selectOnlyOneField = field => {
    setSelectedFields([field])
  }

  const toggleField = field => {
    selectedFields.includes(field) ? deselectField(field) : selectField(field)
  }

  const selectField = field => {
    setSelectedFields([...selectedFields, field])
  }

  const deselectField = field => {
    setSelectedFields(selectedFields.filter(selected => selected !== field))
  }

  const isFieldChecked = field => selectedFields.includes(field)

  return {
    searchString,
    setSearchString,
    replaceString,
    setReplaceString,
    selectedFields,
    setSelectedFields,
    isFieldChecked,
    toggleField,
    selectOnlyOneField,
    clearInputs
  }
}

export default useFindAndReplaceControls
