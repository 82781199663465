export function Cookies() {
  this.init = function () {
    this.checkStorageAvailability() // Run this to find out if Local Storage is available - should be
    this.initialized = true
  }

  this.initialized = false
  this.lsObject = true // TRUE tells us to store/read the LocalStorage value as a JSON object, FALSE is individual name-value pairs
  this.cookieTime = 30 //30 days expiration in case we have to use cookie storage and the expiration string is not passed in
  this.storageKEY = "DataSkrive_portalSettings"

  this.set = function (name, value, params) {
    // mimics import { Cookies } from "react-cookie" so we don't have to rewrite our calls
    if (!this.initialized) {
      this.init()
    }
    if (this.lsObject) {
      this.setNameValuePairAsJSON(name, value, params)
    } else {
      this.setSingleNameValuePair(name, value, params)
    }
  }

  this.get = function (n) {
    //mimics import { Cookies } from "react-cookie" so we don't have to rewrite our calls
    if (!this.initialized) {
      this.init()
    }
    if (this.lsObject) {
      return this.getNameValuePairFromJSON(n)
    } else {
      return this.getSingleNameValuePair(n)
    }
  }

  this.delete = function (n) {
    let lsObj = this.parseLocalStorageJSON()
    delete lsObj[n]
    localStorage.setItem(this.storageKEY, JSON.stringify(lsObj))
  }

  this.getBaseDomain = function () {
    //For cookie storage, use the base domain so we don't have to worry about sub-domain issues
    let sld = document.domain
    let dp = sld.split(".")
    let len = dp.length
    if (len < 2) {
      sld = null
    } else if (!isNaN(dp[len - 1]) && !isNaN(dp[len - 2])) {
      sld = null
    } else {
      sld = `.${dp[len - 2]}.${dp[len - 1]}`
    }
    return sld
  }

  this.setCookie = function (name, value, domain, expirationTime, params) {
    //If we use cookies, we either set based on my defaults of 30 days in the path "/",
    //or use the values passed in via the "params" object
    let de = new Date()
    let expTime
    let path
    de.setTime(de.getTime() + expirationTime * 1000)
    expTime = params && params.expires ? params.expires : de.toUTCString()
    path = params && params.path ? params.path : "/"
    document.cookie = `${name}=${escape(
      value
    )}; expires=${expTime}; path=${path}${
      domain === null ? "" : `;domain=${domain}`
    }`
  }

  this.getCookie = function (name) {
    if (!name) {
      return ""
    }
    return (
      decodeURIComponent(
        document.cookie.replace(
          new RegExp(
            `(?:(?:^|.*;)\\s*${encodeURIComponent(name).replace(
              /[-.+*]/g,
              "\\$&"
            )}\\s*\\=\\s*([^;]*).*$)|^.*$`
          ),
          "$1"
        )
      ) || ""
    )
  }

  this.setSingleNameValuePair = function (name, val, params) {
    //We are setting a single localStorage/cookie value for the name-value pair
    if (this.isLocalStorageEnabled) {
      localStorage.setItem(name, val)
    } else {
      if (document.cookie) {
        this.setCookie(
          name,
          val,
          this.getBaseDomain(),
          this.cookieTime * 24 * 60 * 60,
          params
        )
      } else {
        return ""
      }
    }
  }

  this.getSingleNameValuePair = function (name) {
    // We are reading a single localStorage/cookie value for the name-value pair
    if (this.isLocalStorageEnabled) {
      return localStorage.getItem(name)
    } else {
      if (document.cookie) {
        return this.getCookie(name)
      } else {
        return ""
      }
    }
  }

  this.setNameValuePairAsJSON = function (name, value, params) {
    // We are setting the name-value pair as a localStorage property within an object named by the "storageKEY" value
    let lsVal = this.parseLocalStorageJSON()
    let found = false
    let tmp = {}
    params = params || {}
    for (let n in lsVal) {
      if (n === name) {
        found = true
        tmp.value = value
        for (let m in params) {
          tmp[m] = params[m]
        }
        lsVal[n] = tmp
      }
    }
    if (!found) {
      lsVal[name] = {}
      lsVal[name].value = value
      for (let o in params) {
        lsVal[name][o] = params[o]
      }
    }
    localStorage.setItem(this.storageKEY, JSON.stringify(lsVal))
  }

  this.getNameValuePairFromJSON = function (name) {
    // We are getting the name-value pair from a localStorage property within an object named by the "storageKEY" value,
    // unless it does not exist, in which case we look for it in cookies
    let lsVal = this.parseLocalStorageJSON()
    let found = false
    for (let n in lsVal) {
      if (n === name) {
        return lsVal[n].value
      }
    }
    if (!found) {
      // if not found in localStorage, look for in cookies
      let tryInCookies = this.getCookie(name)
      return tryInCookies
    }
  }

  this.parseLocalStorageJSON = function () {
    let lsVal = localStorage.getItem(this.storageKEY)
    if (!lsVal) {
      return {}
    } else {
      lsVal = JSON.parse(lsVal)
      return lsVal
    }
  }

  this.checkStorageAvailability = function () {
    let testKey = "test"
    try {
      localStorage.setItem(testKey, "1")
      localStorage.getItem(testKey)
      this.isLocalStorageEnabled = true
      localStorage.removeItem(testKey)
    } catch (error) {
      this.isLocalStorageEnabled = false
    }
  }
}
