import React, { useState } from "react"
import makeStyles from "@mui/styles/makeStyles"
import Card from "components/Card/Card"
import { Editor } from "@tinymce/tinymce-react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import { CardActions, InputLabel } from "@mui/material"
import Button from "components/CustomButtons/Button"

const useStyles = makeStyles(() => ({
  outlineBlockCard: {
    borderRadius: "1px",
    marginTop: 0,
    position: "relative",
    backgroundColor: "#f2f2f2",
    boxShadow: "none",
    "& div.MuiFormControl-root": {
      backgroundColor: "#fff",
      padding: "0 5px",
      border: "1px solid #fff",
      "&:hover": {
        border: "1px solid #ccc"
      }
    },
    "& div.MuiSelect-select:focus": {
      backgroundColor: "#fff"
    },
    "& div.MuiInput-underline:before, & .MuiInput-underline:after, & div.MuiInput-underline:hover":
      {
        border: "none",
        content: "none"
      }
  },
  cardFooter: {
    borderTop: "none",
    display: "block",
    textAlign: "right",
    "& > a:hover": {
      cursor: "pointer"
    }
  },
  textAreaHolder: {
    backgroundColor: "#ffffff",
    "& > textarea": {
      width: "100%"
    }
  }
}))

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    setNarrative: store.narrativeStore.setNarrative,
    narrative: store.narrativeStore.narrative,
    postingNarrative: store.narrativeStore.postingNarrative,
    postNarrative: store.narrativeStore.postNarrative,
    getNarrativeBrief: store.narrativeStore.getNarrativeBrief
  }))
}

const NarrativeOutlineBlocksFromHTML = props => {
  const classes = useStyles()
  const { narrative, setNarrative, postNarrative, postingNarrative } =
    useStoreData()
  const [isDirty, setIsDirty] = useState(false)

  // const [
  //   advancedOptionsHeaderVisible,
  //   setadvancedOptionsHeaderVisible
  // ] = useState(false)

  const handleEditorChange = event => {
    const newNarrative = {
      ...narrative,
      rawStory: event.target.getContent()
    }
    setNarrative(newNarrative)
  }

  const closeEditor = () => {
    //Clear it out so it never sends again
    const newNarrative = {
      ...narrative,
      rawStory: ""
    }
    setNarrative(newNarrative)
    //Close this window
    props.handleClose && props.handleClose()
  }

  const saveNarrative = () => {
    if (narrative.id) {
      //Post the rawstory to the server
      postNarrative(narrative)
      closeEditor()
    }
  }

  return (
    <div className={classes.root}>
      <Card className={classes.outlineBlockCard}>
        <div className={classes.textAreaHolder}>
          <InputLabel style={{ fontSize: "11px", padding: "2px" }}>
            Create From A Sample
          </InputLabel>
          <Editor
            apiKey="rpxxr36ah4ei6v91l9blwt1ceamufuog4ta1906jtt9by41t"
            initialValue={narrative.rawStory}
            id={`.${narrative.id}-sample-editor`}
            init={{
              selector: `.${narrative.id}-${
                narrative.organization && narrative.organization.id
              }`,
              height: "65vh",
              menubar: false,
              statusbar: true,
              plugins: [
                "advlist autolink code lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount"
              ],
              spellchecker_dialog: false,
              spellchecker_on_load: false,
              toolbar:
                "undo redo | formatselect | bold italic backcolor | \
                      alignleft aligncenter alignright alignjustify | \
                      bullist numlist outdent indent | removeformat | code | help",
              protect: [
                /<\/?(if|endif)>/g, // Protect <if> & </endif>
                /<xsl:[^>]+>/g, // Protect <xsl:...>
                /<\?php.*?\?>/g, // Protect php code
                /<\/?(apxh:div|apxh:p)>/g // Protect <apxh:div /> & <apxh:p />
              ],
              force_br_newlines: false,
              force_p_newlines: false,
              forced_root_block: "",
              remove_linebreaks: false,
              convert_newlines_to_brs: false,
              preformatted: true
            }}
            onChange={() => !isDirty && setIsDirty(true)}
            onBlur={handleEditorChange}
          />
        </div>
        <CardActions className={classes.cardFooter}>
          <Button onClick={closeEditor}>Cancel</Button>
          <Button
            disabled={narrative.rawStory === "" || postingNarrative}
            color={isDirty ? "primary" : "info"}
            onClick={saveNarrative}
          >
            Save To Outline
          </Button>
        </CardActions>
      </Card>
    </div>
  )
}
export default NarrativeOutlineBlocksFromHTML
