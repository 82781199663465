/* eslint-disable no-unused-vars */
import React, { useState } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material"
import {
  flexRender,
  useReactTable,
  getFilteredRowModel,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel
} from "@tanstack/react-table"
import styled from "styled-components"

import { IndeterminateCheckbox } from "./components/CustomCells"
import SearchTriggersPagination from "components/NarrativeAdmin/Trigger/SearchTriggersPagination"

import {
  areWeFiltering,
  checkEntryModeOperations,
  clearPersistentFilterAfterSave,
  getCurrentView,
  getNarrativeIdFromPath,
  weAreAddingNewContent
} from "tools/tableUtil"

import UploadTSV from "components/CustomUpload/UploadTSV"

import { Cookies } from "tools/storage"
//import { has } from "immutable"
const cookies = new Cookies()

const Styles = styled.div`
  background-color: #fff;
  .toolBar {
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    white-space: nowrap;
    .toggleItem {
      margin: 5px;
      diplay: inline-block;
    }
  }
  .editFilters {
    padding: 0 2px;
    margin: 0 15px 0 5px;
    line-height: 1;
    display: inline-block;
  }
  .editEntries {
    padding: 0 2px;
    margin: 0 0 0 5px;
    line-height: 1;
    display: inline-block;
    background: #777;
    color: #fff;
  }
  .sortableHeader {
    cursor: pointer;
    user-select: none;
  }
  .bulkEditRow {
    display: flex;
  }
  .boldFilter {
    font-weight: bold;
    color: #144d69;
    display: inline-block;
    padding: 3px;
  }
  .pagination {
    padding-left: 10px;
    display: flex;
    align-items: stretch;
    justify-content: "space-between";
  }
  .table {
    font-size: 13px;
    font-family: "Roboto", arial, sans-serif;
    width: fit-content;
    max-width: 100%;
    overflow: auto;
    display: inline-block;
    border-spacing: 0;
    border: 1px solid #ccc;
    .tr {
      display: flex;
      width: fit-content;
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      margin: 0;
      padding: 0;
      position: relative;
      border: 1px solid #ccc;

      select,
      input {
        width: 100%;
        padding: 5px;
        margin: 0;
        border: none;
        background-color: #ffffff;
      }
      input[type="checkbox"] {
        width: auto;
        padding: 6px;
        border: 0;
      }
      button svg {
        font-size: 20px;
      }
      select {
        height: 33px;
      }
      .tool-cell {
        padding: 5px;
        margin: 0;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: left;
        & .tool-buttons {
          min-width: 40px;
        }
      }
      .resizer {
        display: inline-block;
        width: 3px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        cursor: col-resize;

        touch-action: none;
        &:hover {
          background: #4285f4;
        }
        &.isResizing {
          background: #c9a872;
        }
      }
    }

    .th {
      background-color: #f8f9fa;
      > div:first-child {
        font-weight: bold;
        text-align: center;
        padding: 5px;
      }
      input[type="range"] {
        width: 75%;
      }
      input,
      select {
        border: 1px solid transparent;
        background-color: transparent;
      }
    }
  }
`

// Be sure to pass our handleDataChange and the skipReset option
function Table({
  columns,
  hideColumns = [],
  data,
  handleDataChange, // function with signature: (rowIndex, columnId, value) => void
  enableColumnFilters,
  loadBulkEditComponent,
  duplicateElements,
  deepCopyElements,
  bulkSaveElements,
  bulkArchiveElements,
  bulkAssignToOutlineBlock,
  bulkAssignTriggerIds,
  bulkTest,
  paragraph,
  narrative,
  addElements,
  primaryTagOptions = [],
  passDataUp,
  loadSelectedRow,
  descriptorString,
  updateSelected,
  skipReset,
  changedRows = [],
  tableType
}) {
  const defaultColumn = {
    minSize: 50,
    size: 150,
    filterFn: "startsWith"
  }

  const editEntryMode = cookies.get("editEntryMode") || []

  // Use the state and functions returned from useTable to build your UI
  const table = useReactTable({
    columns,
    data,
    defaultColumn,
    // We also need to pass this so the page doesn't change
    // when we edit the data.
    autoResetPageIndex: !skipReset,
    initialState: {
      pagination: {
        pageSize: Number(cookies.get("TableControlPageSize")) || 25
      },
      columnVisibility: hideColumns.reduce((result, next) => {
        result[next] = false
        return result
      }, {})
    },
    filterFns: {
      startsWith: (row, columnId, filterValue) => {
        const rowValue = row.getValue(columnId)
        return rowValue !== undefined
          ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
          : true
      }
    },
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    enableColumnResizing: true,
    enableColumnFilters,
    // enableGrouping: true,
    columnResizeMode: "onChange",
    meta: {
      // handleDataChange isn't part of the API, but
      // anything we put into meta will
      // automatically be available on table.options.meta.
      // That way we can call this function from our
      // cell renderer!
      handleDataChange,
      primaryTagOptions,
      changedRows
    }
  })

  const [bulkCommand, setBulkCommand] = useState("")
  const [isBulkOpen, setBulkOpen] = useState(false)
  const [isColumnMenuOpen, setColumnMenuOpen] = useState(false)
  const [addRows, setAddRows] = useState(0)
  const [myPageSize, setMyPageSize] = useState(
    Number(cookies.get("TableControlPageSize")) || 25
  )
  const [myPageIndex, setMyPageIndex] = useState(0)
  const [hugeSearchIndex, setHugeSearchIndex] = useState(0)
  const [hugePageSize, setHugePageSize] = useState(
    Number(cookies.get("TriggerSearchHugePageSize")) || 25
  )

  let selectedFlatRows_Filtered // This special filtered value is for when we edit "filtered" entries and want then to "stay in the filter"
  // after we change them BUT before we save them
  const toggleBulkEditor = () => {
    let clearRows = true
    if (bulkCommand === "edit") {
      setBulkOpen(!isBulkOpen)
    } else {
      if (bulkCommand === "duplicate") {
        duplicateElements({ selectedFlatRows: selectedFlatRows_Filtered })
      } else if (bulkCommand === "deepcopy") {
        deepCopyElements({ selectedFlatRows: selectedFlatRows_Filtered })
      } else if (bulkCommand === "bulksave") {
        // added to allow bulk saving of sentences/triggers/paragraphs
        const onlySelectedOnes = selectedFlatRows_Filtered.filter(itm =>
          itm.getIsSelected()
        )
        clearRows = bulkSaveElements({
          selectedFlatRows: onlySelectedOnes
        })
      } else if (bulkCommand === "assign") {
        // added to allow bulk assignment to narrative outline block
        bulkAssignToOutlineBlock({
          selectedFlatRows: selectedFlatRows_Filtered
        })
      } else if (bulkCommand === "archive") {
        // added to allow bulk saving of sentences/triggers/paragraphs
        bulkArchiveElements({ selectedFlatRows: selectedFlatRows_Filtered })
      } else if (bulkCommand === "triggers") {
        // added to allow bulk saving of sentences/triggers/paragraphs
        bulkAssignTriggerIds({
          selectedFlatRows: selectedFlatRows_Filtered
        })
      } else if (bulkCommand === "test") {
        bulkTest({
          selectedFlatRows: selectedFlatRows_Filtered
        })
      }
      if (clearRows) {
        table.resetRowSelection()
        setBulkCommand("")
      }
    }
  }
  const handleClose = () => {
    setColumnMenuOpen(false)
  }

  const getRowsForThisLeaf = (
    allRows,
    pageSize,
    pageIndex,
    hasTextFilter,
    hugeSearchIndex,
    hugePageSize
  ) => {
    if (!!hasTextFilter) {
      if (allRows.length > hugePageSize) {
        const totalLeft =
          allRows.length - hugePageSize + 1 > hugeSearchIndex * hugePageSize
            ? hugePageSize
            : allRows.length - hugeSearchIndex * hugePageSize
        const final = allRows.slice(
          hugeSearchIndex * hugePageSize,
          totalLeft + hugeSearchIndex * hugePageSize
        )
        return final
      } else {
        return allRows
      }
    } else {
      const totalLeft =
        allRows.length - pageSize + 1 > pageIndex * pageSize
          ? pageSize
          : allRows.length - pageIndex * pageSize
      const final = allRows.slice(
        pageIndex * pageSize,
        totalLeft + pageIndex * pageSize
      )
      return final
    }
  }

  const largeTriggerPagination = () => {
    const largeTriggers =
      descriptorString?.match(/trigger/i) && tmpPage.length > 100
    return largeTriggers
  }

  const filteringWithPagination = (filterColumn, filterVal) => {
    const positionFilter = filterColumn === "position" && !isNaN(filterVal)
    const anyFilter = filterVal && !!filterVal.toString().match(/\w/)
    return positionFilter || anyFilter
  }

  const filteredColumns = table
    .getAllLeafColumns()
    .filter(
      c => c.id !== "selection" && c.id !== "expander" && c.id !== "select"
    )

  let bulkOptions = (
    <React.Fragment>
      <option value={""}>Bulk Actions</option>
      <option value={"edit"}>Edit</option>
      <option value={"duplicate"}>Duplicate</option>
      {deepCopyElements && <option value={"deepcopy"}>Deep Copy</option>}
      {bulkTest && <option value={"test"}>Test</option>}
      {bulkAssignToOutlineBlock && (
        <option value={"assign"}>Assign to Outline</option>
      )}{" "}
      {bulkAssignTriggerIds && (
        <option value={"triggers"}>Assign Trigger Ids</option>
      )}
      <option value={"archive"}>Archive</option>
      <option value={"bulksave"}>Save Multiple</option>
    </React.Fragment>
  )

  const narrativeId = getNarrativeIdFromPath()
  const myView = getCurrentView()
  const narrativeAndView = `${narrativeId}:${myView}`

  //Set current tableType being edited. This will allow us to differntiate between the filters on different table types
  let gridViewFilters = cookies.get("narrativeEditorGridViewFilters") || {}
  gridViewFilters["currentTableType"] = tableType
  gridViewFilters[tableType] = gridViewFilters[tableType] || {}
  gridViewFilters[tableType][narrativeAndView] =
    gridViewFilters[tableType][narrativeAndView] || {}
  cookies.set("narrativeEditorGridViewFilters", gridViewFilters)

  //This special filtered value area that saves the selected rows by ID and accounts for when we are in this special mode and NOT in that mode
  const selectedViews = []
  let hasTextFilter = false
  let textFilterColumn = ""
  Object.keys(gridViewFilters[tableType][narrativeAndView]).forEach(key => {
    hasTextFilter =
      hasTextFilter || gridViewFilters[tableType][narrativeAndView][key]
    if (gridViewFilters[tableType][narrativeAndView][key]) {
      textFilterColumn = key
    }
  })

  const allRows = [...table.getFilteredRowModel().rows]

  let tmpPage = allRows.sort(function (itm) {
    //Sort array to show id=null - new sentence/trigger/paragraph - at the top of the table
    if (!itm.original.id) {
      return -1
    } else {
      return 0
    }
  })

  const selectedRows = table.getSelectedRowModel()
  let origTmpPageLength = tmpPage.length
  const hasFilteringWithPagination = filteringWithPagination(
    textFilterColumn,
    hasTextFilter
  )
  const hasLargeTriggerPagination = largeTriggerPagination()
  if (allRows.length < myPageIndex * myPageSize && hasFilteringWithPagination) {
    setMyPageIndex(0)
  }

  // if (hasLargeTriggerPagination) {
  //   //Workaround for outputs/libraries with a lot of triggers
  //   if (tmpPage.length > hugePageSize * hugeSearchIndex) {
  //     tmpPage = tmpPage.slice(
  //       hugePageSize * hugeSearchIndex,
  //       hugePageSize * (hugeSearchIndex + 1)
  //     )
  //   } else if (hugeSearchIndex) {
  //     setHugeSearchIndex(0)
  //   }
  // }
  if (selectedRows?.rows.length) {
    const selectedItems = []
    selectedRows?.rows.forEach((sel, j) => {
      let justBefore = -1
      let nearest = 0
      let found = false
      tmpPage.forEach((tmp, i) => {
        if (sel.index === tmp.index) {
          found = true
        } else {
          if (
            (sel.index - tmp.index > 0 && sel.index - tmp.index < nearest) ||
            nearest === 0
          ) {
            nearest = sel.index - tmp.index
            justBefore = tmp.index
          }
        }
      })
      let newSel = sel
      newSel.tmpLoc = justBefore
      newSel.selIndex = j
      newSel.found = found
      selectedItems.push(newSel)
    })
    selectedItems.forEach((sel, j) => {
      if (!sel.found) {
        const tempTmpPage = [...tmpPage]
        tempTmpPage.forEach((tmp, i) => {
          if (sel.tmpLoc === tmp.index) {
            tmpPage.splice(i, 0, sel)
            //tmpPage.splice(i + 1, 0, sel)
          }
        })
      }
    })
  } else {
    tmpPage = getRowsForThisLeaf(
      tmpPage,
      myPageSize,
      myPageIndex,
      hasTextFilter,
      hugeSearchIndex,
      hugePageSize
    )
  }

  const myGridViews = cookies.get("selectedGridViewRows") || {}
  const inPersistFilterMode = checkEntryModeOperations(
    editEntryMode,
    narrativeAndView,
    "matchTable"
  )
  if (!inPersistFilterMode) {
    tmpPage.forEach(itm => {
      hasTextFilter &&
        itm.original &&
        itm.original.id &&
        selectedViews.push(itm.original.id)
    })
    if (myView) {
      myGridViews[narrativeAndView] = selectedViews
      cookies.set("selectedGridViewRows", myGridViews)
    } else {
      myGridViews[`${narrativeId}:`] = selectedViews
      cookies.set("selectedGridViewRows", myGridViews)
    }
  } else {
    //We ARE in the special filtered value mode, so now we turn the "saved visible rows" from the last view before we switched into this mode
    const mySRs = cookies.get("selectedGridViewRows") || {}
    let mySelectedRows
    if (myView) {
      mySelectedRows = mySRs[narrativeAndView]
    } else {
      mySelectedRows = mySRs[`${narrativeId}:`]
    }
    if (mySelectedRows && mySelectedRows.length) {
      //If we have an array of "saved visible rows", add them to the tmpPage array (my substiture for page)
      const tmpArray = []
      table.getCoreRowModel().rows.forEach(itm => {
        mySelectedRows.forEach(itm2 => {
          if (
            typeof itm2 === "number" &&
            itm.original &&
            itm2 === itm.original.id
          ) {
            tmpArray.push(itm)
          }
        })
      })
      tmpPage = tmpArray
    }
  }

  if (!hasFilteringWithPagination) {
    origTmpPageLength = tmpPage.length
  }
  if (
    !inPersistFilterMode &&
    tmpPage.length > table.getSelectedRowModel().flatRows.length
  ) {
    //This accounts for when you "select" all the rows in the 1st collumn select
    selectedFlatRows_Filtered = table.getSelectedRowModel().flatRows
  } else {
    selectedFlatRows_Filtered = tmpPage
  }

  const renderSortDirectionIndicator = header => {
    const sortState = header.column.getIsSorted()
    return (
      <span>{!!sortState ? (sortState === "asc" ? " 🔼" : " 🔽") : ""}</span>
    )
  }

  return (
    <Styles>
      <div className="toolBar">
        <select
          value={bulkCommand}
          onChange={e => {
            setBulkCommand(e.target.value)
          }}
        >
          {bulkOptions}
        </select>
        <button
          onClick={toggleBulkEditor}
          disabled={
            selectedFlatRows_Filtered.length === 0 || bulkCommand === ""
          }
        >
          {isBulkOpen && bulkCommand === "edit" ? "Cancel" : "Apply"}
        </button>
        <button
          title="Column Choices"
          onClick={() => setColumnMenuOpen(!isColumnMenuOpen)}
        >
          Columns
        </button>
        {data?.length > table.getState().pagination.pageSize &&
          !hasTextFilter && (
            <div className="pagination">
              <button
                onClick={() => {
                  table.setPageIndex(0)
                  setMyPageIndex(0)
                }}
                disabled={!myPageIndex}
              >
                {"<<"}
              </button>{" "}
              <button
                onClick={() => {
                  table.previousPage()
                  setMyPageIndex(myPageIndex - 1)
                }}
                disabled={!myPageIndex}
              >
                {"<"}
              </button>{" "}
              <button
                onClick={() => {
                  table.nextPage()
                  setMyPageIndex(myPageIndex + 1)
                }}
                disabled={!(myPageIndex < table.getPageCount() - 1)}
              >
                {">"}
              </button>{" "}
              <button
                onClick={() => {
                  table.setPageIndex(table.getPageCount() - 1)
                  setMyPageIndex(table.getPageCount() - 1)
                }}
                disabled={!(myPageIndex < table.getPageCount() - 1)}
              >
                {">>"}
              </button>{" "}
              <span>
                Page{" "}
                <strong>
                  {myPageIndex + 1} of {table.getPageOptions().length}
                </strong>{" "}
              </span>
              <span>
                | Go to page:{" "}
                <input
                  type="number"
                  defaultValue={table.getState().pagination.pageIndex + 1}
                  onChange={e => {
                    const index = e.target.value
                      ? Number(e.target.value) - 1
                      : 0
                    table.setPageIndex(index)
                  }}
                  style={{ width: "100px" }}
                />
              </span>{" "}
              <select
                value={table.getState().pagination.pageSize}
                onChange={e => {
                  table.setPageSize(Number(e.target.value))
                  cookies.set("TableControlPageSize", Number(e.target.value))
                }}
              >
                {[25, 50, 100, 200, 300, 400, 500].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          )}
        <UploadTSV
          descriptorString={descriptorString}
          narrativeAndView={narrativeAndView}
          paragraph={paragraph}
          narrative={narrative}
          data={data}
          passDataUp={dta => {
            passDataUp(dta)
          }}
        />
        {((origTmpPageLength > 0 && descriptorString?.match(/trigger/i)) ||
          hasFilteringWithPagination) && (
          <>
            <SearchTriggersPagination
              searchResultsLength={origTmpPageLength}
              passBackPageSize={val => {
                setHugePageSize(val)
              }}
              passBackPageIndex={val => {
                setHugeSearchIndex(val)
              }}
              largeTriggerPagination={hasLargeTriggerPagination}
              hasFilteringWithPagination={!!hasFilteringWithPagination}
              tableType={gridViewFilters.currentTableType}
              filterColumn={textFilterColumn}
            />
          </>
        )}
      </div>
      <div>
        {isBulkOpen &&
          bulkCommand === "edit" &&
          loadBulkEditComponent({
            selectedFlatRows: selectedFlatRows_Filtered,
            toggleBulkEditor,
            updateSelected
          })}
      </div>
      <div>
        <div className="table" style={{ width: table.getCenterTotalSize() }}>
          <div>
            {table.getHeaderGroups().map(headerGroup => (
              <div key={headerGroup.id} className="tr">
                {headerGroup.headers.map(header => (
                  <div
                    {...{
                      key: header.id,
                      colSpan: header.colSpan,
                      className: "th",
                      style: {
                        width: header.getSize()
                      }
                    }}
                  >
                    <>
                      {header.isPlaceholder ? null : (
                        <div
                          className={
                            header.column.getCanSort() ? "sortableHeader" : ""
                          }
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {renderSortDirectionIndicator(header)}
                        </div>
                      )}
                      {/* Render the columns filter UI */}
                      <div>
                        {header.column.getCanFilter()
                          ? flexRender(header.column.columnDef.meta?.filterUi, {
                              column: header.column,
                              table
                            })
                          : null}
                      </div>
                      <div
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                        className={`resizer ${
                          header.column.getIsResizing() ? "isResizing" : ""
                        }`}
                      />
                    </>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div style={{ display: "grid" }}>
            {tmpPage.map((row, index) => (
              <div
                key={row.id}
                id={`row_${index}`}
                onClick={e => {
                  if (e.detail > 1) {
                    //On Double or Triple click, toggle the row ON (EDIT)
                    if (!row.getIsSelected()) {
                      row.toggleSelected()
                      row.getToggleSelectedHandler()
                      if (typeof loadSelectedRow === "function") {
                        loadSelectedRow(row)
                      }
                    }
                  }
                }}
              >
                <div className="tr">
                  {row.getVisibleCells().map(cell => (
                    <div
                      key={cell.id}
                      className="td"
                      style={{ width: cell.column.getSize() }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="toolBar">
        <span>
          Add {tableType}s:{" "}
          <input
            type="number"
            value={addRows}
            min="1"
            onChange={e => {
              const numRows = e.target.value ? Number(e.target.value) : 0
              setAddRows(numRows)
            }}
            style={{ width: "100px" }}
          />
        </span>
        <button
          onClick={() => {
            addElements(addRows)
            setAddRows(0)
          }}
          disabled={addRows === 0}
        >
          Add
        </button>
        {data?.length > table.getState().pagination.pageSize &&
          !hasTextFilter && (
            <div className="pagination">
              <button
                onClick={() => {
                  table.setPageIndex(0)
                  setMyPageIndex(0)
                }}
                disabled={!myPageIndex}
              >
                {"<<"}
              </button>{" "}
              <button
                onClick={() => {
                  table.previousPage()
                  setMyPageIndex(myPageIndex - 1)
                }}
                disabled={!myPageIndex}
              >
                {"<"}
              </button>{" "}
              <button
                onClick={() => {
                  table.nextPage()
                  setMyPageIndex(myPageIndex + 1)
                }}
                disabled={!(myPageIndex < table.getPageCount() - 1)}
              >
                {">"}
              </button>{" "}
              <button
                onClick={() => {
                  table.setPageIndex(table.getPageCount() - 1)
                  setMyPageIndex(table.getPageCount() - 1)
                }}
                disabled={!(myPageIndex < table.getPageCount() - 1)}
              >
                {">>"}
              </button>{" "}
              <span>
                Page{" "}
                <strong>
                  {myPageIndex + 1} of {table.getPageOptions().length}
                </strong>{" "}
              </span>
              <span>
                | Go to page:{" "}
                <input
                  type="number"
                  defaultValue={table.getState().pagination.pageIndex + 1}
                  onChange={e => {
                    const index = e.target.value
                      ? Number(e.target.value) - 1
                      : 0
                    table.setPageIndex(index)
                    setMyPageIndex(index)
                  }}
                  style={{ width: "100px" }}
                />
              </span>{" "}
              <select
                value={table.getState().pagination.pageSize}
                onChange={e => {
                  table.setPageSize(Number(e.target.value))
                  cookies.set("TableControlPageSize", Number(e.target.value))
                  setMyPageSize(Number(e.target.value))
                }}
              >
                {[25, 50, 100, 200, 300, 400, 500].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          )}
      </div>
      <div>
        <Dialog
          open={isColumnMenuOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Select the colums you want to see"}
          </DialogTitle>
          <DialogContent>
            <div>
              <div className="toggleItem">
                <IndeterminateCheckbox
                  checked={table.getIsAllColumnsVisible()}
                  indeterminate={
                    table.getIsSomeColumnsVisible() &&
                    !table.getIsAllColumnsVisible()
                  }
                  onChange={table.getToggleAllColumnsVisibilityHandler()}
                />{" "}
                Toggle All
              </div>
              <br />
              {filteredColumns.map(column => (
                <div className="toggleItem" key={column.id}>
                  <label>
                    <input
                      type="checkbox"
                      checked={column.getIsVisible()}
                      onChange={column.getToggleVisibilityHandler()}
                    />{" "}
                    {flexRender(column.columnDef.header)}
                  </label>
                </div>
              ))}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Styles>
  )
}

export default Table
