import React, { Component } from "react"
import withStyles from "@mui/styles/withStyles"
import GridItem from "components/Grid/GridItem"
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from "@mui/material"
import Button from "components/CustomButtons/Button"
import PropTypes from "prop-types"
import { globalSettings } from "variables/general"
import { CopyToClipboard } from "react-copy-to-clipboard"
import InputLabel from "@mui/material/InputLabel"
import { get } from "tools/request"

const styles = {
  sentencePreview: {
    fontWeight: "normal",
    "& .xml": {
      fontWeight: "bold",
      fontSize: "13.5px",
      fontStyle: "normal"
    },
    "& pre": {
      padding: "5px 15px!important"
    }
  }
}

class OrganizationWidgetShare extends Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      organization: props.organization,
      preview: "",
      isLoading: false,
      showLineNumbers: true
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  componentDidMount() {
    this.retrieveJavaScriptToEmbed(this.props.organization.id)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      organization: nextProps.organization
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.organization !== prevProps.organization) {
      if (this.props.organization != null) {
        this.retrieveJavaScriptToEmbed(this.props.organization.id)
      }
    }
  }

  handleChange() {
    if (this.props.onChange) {
      this.props.onChange(this.state.organization)
    }
  }

  handleClose() {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  retrieveJavaScriptToEmbed = organizationId => {
    // https://localhost:5001/api/embed/js/javascripttoembed/?organizationId=30
    get(
      `${globalSettings.apiBaseUrl}/api/embed/js/javascripttoembed/?organizationId=${organizationId}`
    )
      .then(Response => Response && Response.ok === true && Response.text())
      .then(text => {
        this.setState({
          javascriptToEmbed: text
        })
      })
  }

  handleFocus = event => event.target.select()

  render() {
    const { organization } = this.state
    if (organization === null) {
      return null
    }

    return (
      <React.Fragment>
        <Dialog maxWidth="lg" fullWidth open={true}>
          <DialogTitle>Widget Embed Code</DialogTitle>
          <DialogContent>
            <Grid style={{ display: "flex" }}>
              <GridItem xs={12} sm={12}>
                <InputLabel style={{ color: "#AAAAAA" }}>
                  Paste the javascript code into the &lt;head&gt; of every page
                  you wish to track and/or use Data Skrive widgets.
                </InputLabel>
                <TextField
                  label=""
                  id="javascriptCodeSnippet"
                  fullWidth={true}
                  multiline={true}
                  rows={10}
                  onChange={null}
                  value={this.state.javascriptToEmbed}
                  onFocus={this.handleFocus}
                />
              </GridItem>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose()} color="primary">
              Close
            </Button>
            <CopyToClipboard text={this.state.javascriptToEmbed}>
              <Button onClick={this.hideShareDialog} color="primary">
                Copy Code Snippet
              </Button>
            </CopyToClipboard>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

OrganizationWidgetShare.propTypes = {
  isOpen: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  classes: PropTypes.object,
  organization: PropTypes.object
}

export default withStyles(styles)(OrganizationWidgetShare)
