import { globalSettings } from "variables/general"
// This file simplifies fetches for maynard requests. It would be nice to
// rafactor the existing request.js to accept headers as an arg but for now
// The headers are different enough to require different request wrappers

const HEADERS = {
  Accept: "text/plain",
  "Content-Type": "application/json",
  "X-API-Key": `${globalSettings.dennyApiKey}`
}

const get = async (url, params) => {
  const options = {
    method: "GET"
  }

  return await request(url, options, params)
}

const put = async (url, params, data) => {
  const options = {
    method: "PUT",
    body: JSON.stringify(data)
  }

  return await request(url, options, params)
}

const post = async (url, params, data) => {
  const options = {
    method: "POST",
    body: JSON.stringify(data)
  }

  return await request(url, options, params)
}

const del = async (url, params) => {
  const options = {
    method: "DELETE"
  }

  return await request(url, options, params)
}

const request = async (url, options, params) => {
  let requestUrl = url
  if (params) {
    requestUrl += `?${toQueryString(params)}`
  }
  options.credentials = "include"
  const resp = await fetch(requestUrl, { ...options, headers: HEADERS })

  await handleErrors(resp)

  return resp
}

const handleErrors = async resp => {
  if (resp.status !== 200) {
    throw new Error("Error", { cause: resp.status })
  }
}

const toQueryString = object =>
  Object.keys(object)
    .map(k => `${k}=${object[k]}`)
    .join("&")

export { get, put, post, del }
