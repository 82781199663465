import React from "react"
import PropTypes from "prop-types"
import withStyles from "@mui/styles/withStyles"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardFooter from "components/Card/CardFooter"
import registerPageStyle from "assets/jss/material-dashboard-pro-react/views/registerPageStyle"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import { globalSettings } from "variables/general"
import Helpers from "tools/Helpers"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { CircularProgress } from "@mui/material"
import { get } from "tools/request"

class NarrativeOutlinePreviewPage extends React.Component {
  constructor(props) {
    super(props)
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      title: "",
      summary: "",
      body: "",
      featuredImageUrl: "",
      featuredImageCaption: "",
      narrativeToken: this.props.match.params.narrativeToken,
      isFailedDialogOpen: false,
      isSuccessDialogOpen: false,
      isLoading: false
    }
  }

  componentDidMount() {
    if (this.state.title) {
      Helpers.updateBrowserTabTitle(false, this.state.title)
    }
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" })
      }.bind(this),
      700
    )
    this.setState({ isLoading: true })
    this.fetchPreview(this.state.narrativeToken)
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction)
    this.timeOutFunction = null
  }

  onUsernameOrEmailChange = event => {
    this.setState({ usernameOrEmail: event.target.value })
  }

  fetchPreview = narrativeToken => {
    get(
      `${globalSettings.apiBaseUrl}/api/feedentry/previewoutline/${narrativeToken}`
    )
      .then(response => response.json())
      .then(data => {
        this.setState({
          title: data.title,
          summary: data.summary,
          body: data.content,
          featuredImageUrl: data.featuredImage,
          featuredImageCaption: data.featuredImageCaption,
          isLoading: false
        })
        if (data.title) {
          Helpers.updateBrowserTabTitle(false, data.title)
        }
        let summary = Helpers.stripHtml(data.summary)
        document.getElementsByTagName("meta")["description"].content = summary
        document
          .querySelector("meta[property='og:description']")
          .setAttribute("content", summary)
        document
          .querySelector("meta[property='og:title']")
          .setAttribute("content", data.title)
        if (data.featuredImage) {
          document
            .querySelector("meta[property='og:image']")
            .setAttribute("content", data.featuredImage)
        }
      })
      .catch(err => {
        console.error(`Error on GET to /api/feedentry/previewoutline/`, err)
        this.setState({ isLoading: false })
      })
  }

  render() {
    const { classes } = this.props
    const previewUrl = `${globalSettings.cdnBaseUrl}/narrativeoutlinepreview/${this.state.narrativeToken}`
    const qrCodeUrl = `${globalSettings.apiBaseUrl}/api/qrcode?text=${previewUrl}`

    return (
      <div className={classes.container}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card
              profile
              className={`${classes.customCardClass} ${
                classes[this.state.cardAnimaton]
              }`}
            >
              <CardBody profile>
                {this.state.isLoading && <CircularProgress />}
                {this.state.featuredImageUrl && (
                  <div>
                    <img
                      src={this.state.featuredImageUrl}
                      style={{ width: "100%" }}
                      alt=""
                    />{" "}
                    <br />
                    <em>{this.state.featuredImageCaption}</em>
                  </div>
                )}
                <h3 className={classes.cardTitle}>{this.state.title}</h3>
                <h4
                  style={{ textAlign: "left" }}
                  dangerouslySetInnerHTML={{
                    __html:
                      this.state.summary &&
                      this.state.summary.replace(/apxh:/g, "")
                  }}
                />
                <p
                  style={{ textAlign: "left" }}
                  dangerouslySetInnerHTML={{
                    __html:
                      this.state.body && this.state.body.replace(/apxh:/g, "")
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter} />
            </Card>
          </GridItem>
          <GridItem>
            <Card className={classes.justifyContentCenter}>
              <CardBody>
                <CopyToClipboard
                  text={previewUrl}
                  onCopy={() => alert(`Url ${previewUrl} copied to clipboard.`)}
                >
                  <div style={{ textAlign: "center" }}>
                    Click to copy url. <br />
                    <img src={qrCodeUrl} width={150} alt="" />
                  </div>
                </CopyToClipboard>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

NarrativeOutlinePreviewPage.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  selectedValue: PropTypes.string
}

export default withStyles(registerPageStyle)(NarrativeOutlinePreviewPage)
