import { Grid, Card, Typography, CardContent, Button } from "@mui/material"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import makeStyles from "@mui/styles/makeStyles"

import LibraryStatsTable from "components/Dashboard/LibraryStatsTable"
import ClientStatsTable from "components/Dashboard/ClientStatsTable"
import RevShareGauge from "components/Dashboard/RevShareGauge"

const useStyles = makeStyles({
  container: {
    padding: "9px 12px"
  },
  card: {
    minHeight: 400,
    padding: "9px 12px",
    marginBottom: "15px",
    width: "100%"
  },
  cardDD: {
    minHeight: 400,
    padding: "9px",
    paddingBottom: " 30px",
    marginBottom: "15px",
    width: "100%"
  },
  graphDD: {
    height: "400px",
    width: "100%",
    overflow: "auto",
    margin: "auto"
  },
  linkButton: {
    fontSize: ".75rem",
    marginLeft: "12px",
    backgroundColor: "#4d9ab5"
  }
})

export default function Dashboard() {
  const classes = useStyles()
  return (
    <Grid container className={classes.container}>
      <Button
        className={classes.linkButton}
        variant="contained"
        color="primary"
        size="small"
        href="https://dataskrivejira.atlassian.net/wiki/spaces/PROD/pages/16580671/Release+Notes"
        target="_blank"
        endIcon={<OpenInNewIcon fontSize="inherit" />}
      >
        Release Notes
      </Button>
      <Grid item xs={12}>
        <Typography variant="body1" />
      </Grid>
      {/* row 1 */}
      <Grid item xs={12} sm={6} md={6} className={classes.container}>
        <LibraryStatsTable />
      </Grid>
      <Grid item xs={12} sm={6} md={6} className={classes.container}>
        <ClientStatsTable />
      </Grid>
      {/*row 2*/}
      <Grid item xs={12} sm={6} className={classes.container}>
        <Card className={classes.card}>
          <CardContent>
            <Typography>Revenue Share Goals</Typography>
            <RevShareGauge />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
