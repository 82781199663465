// eslint-disable-next-line no-unused-vars
import React, { useCallback, useEffect } from "react"
import { useObserver } from "mobx-react"
import { PermIdentity, Lock } from "@mui/icons-material"
import { useStore } from "contexts/rootContext"
import AccountForm from "./Account/AccountForm"
import Page from "components/Page"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardIcon from "components/Card/CardIcon"
import CardHeader from "components/Card/CardHeader"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"

function useAccountData() {
  const store = useStore()
  return useObserver(() => ({
    account: store.accountStore.account,
    loading: store.accountStore.loading,
    loadingAccount: store.accountStore.loadingAccount,
    setAccountId: store.accountStore.setAccountId,
    loginAttemptHistory: store.accountStore.loginAttemptHistory,
    getLoginAttemptHistory: store.accountStore.getLoginAttemptHistory
  }))
}

const Account = () => {
  const {
    account,
    loading,
    loadingAccount,
    setAccountId,
    getLoginAttemptHistory
  } = useAccountData()

  // on first render
  useEffect(() => {
    getLoginAttemptHistory()
  }, [])

  // scroll top on mount
  const resetScroll = element => {
    element.scrollIntoView()
  }

  const accountRef = useCallback(element => {
    if (element !== null && !loading) {
      resetScroll(element)
    }
  })

  return (
    <div ref={accountRef}>
      <Page
        title="Edit Account"
        loading={loadingAccount}
        icon={<PermIdentity />}
        auditInfo={account?.auditInfo}
      >
        {!loadingAccount && <AccountForm onCancel={() => setAccountId(null)} />}
      </Page>
      <Card>
        <CardHeader color="primary" icon>
          <GridContainer>
            <GridItem xs={10}>
              <CardIcon color="primary" style={{ float: "left" }}>
                {" "}
                Login History
                <Lock />
              </CardIcon>
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody />
      </Card>
    </div>
  )
}

export default Account
