import React, { useEffect, useState } from "react" //
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"

import RealTimeFormTemplate from "./RealTimeDialogFormTemplate"

import { makeStyles } from "@mui/styles"
import {
  Autocomplete,
  TextField,
  Grid,
  Checkbox,
  FormControlLabel
} from "@mui/material"
import GridItem from "components/Grid/GridItem"
import RealTimeDAO from "daos/realTimeDAO"
//import RealTimeInsightStatus from "../RealTimeInsightStatus"

const useStyles = makeStyles({
  formRow: {
    display: "flex",
    padding: "24px 0"
  }
})

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    insightTypes: store.realTimeStore.insightTypes,
    getRealTimeLibraryById: store.realTimeStore.getRealTimeLibraryById,
    setDialogSuccessMessage: store.uiStore.setDialogSuccessMessage,
    setDialogSuccessOpen: store.uiStore.setDialogSuccessOpen,
    setDialogFailMessage: store.uiStore.setDialogFailMessage,
    setDialogFailOpen: store.uiStore.setDialogFailOpen
  }))
}

export default function InsightTypeForm({
  open,
  onClose,
  onSave,
  mode,
  showDialog,
  typeData,
  realTimeLibraryById,
  unusedInsightTypes,
  readOnly
}) {
  const classes = useStyles()
  const {
    getRealTimeLibraryById,
    setDialogSuccessMessage,
    setDialogSuccessOpen,
    setDialogFailMessage,
    setDialogFailOpen
  } = useStoreData()
  const [initialFormValues, setInitialFormValues] = useState({
    id: null,
    insightType: [],
    statusId: 3,
    isArchived: false
  })
  const [typeFormValues, setTypeFormValues] = useState(initialFormValues)
  const [formErrors, setFormErrors] = useState({ insightType: "" })

  // const [unusedInsightType, setUnusedInsightType] = useState([])

  useEffect(() => {
    let initialFormValues = {
      id: null,
      insightType: [],
      statusId: 3,
      isArchived: false
    }
    if (typeData && mode === "edit") {
      initialFormValues = {
        id: typeData?.id,
        insightType: { id: typeData?.id, name: typeData?.name },
        statusId: 3,
        isArchived: false
      }
    }
    setTypeFormValues(initialFormValues)
    setInitialFormValues(initialFormValues)
  }, [typeData, mode])

  const handleClose = () => {
    setTypeFormValues({
      id: null,
      InsightType: "",
      statusId: null,
      isArchived: false
    })
    onClose()
  }

  const handleAutocompleteChange = (event, newValue) => {
    setTypeFormValues(prev => ({
      ...prev,
      id: newValue ? newValue.id : null,
      insightType: newValue,
      isArchived: false
    }))
    setFormErrors(prev => ({
      ...prev,
      insightType: newValue ? "" : "Insight Type is required"
    }))
  }

  const handleArchiveChange = event => {
    setTypeFormValues({ ...typeFormValues, isArchived: event.target.checked })
  }

  const validateForm = () => {
    const newErrors = {}
    if (!typeFormValues.insightType?.id) {
      newErrors.insightType = "Insight Type is required"
    }
    setFormErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSave = async () => {
    if (!validateForm()) {
      return
    }
    //Code path for Archive AKA Disassociate
    if (typeFormValues.isArchived) {
      const insightTypeId = typeFormValues.insightType?.id
      if (insightTypeId && realTimeLibraryById?.insightTypes?.length > 0) {
        const insightTypeIds = []
        realTimeLibraryById.insightTypes.forEach(x => {
          if (Number(insightTypeId) !== Number(x.id)) {
            insightTypeIds.push(x.id)
          }
        })
        const newLibraryInfo = {
          id: realTimeLibraryById.id,
          name: realTimeLibraryById.name,
          statusId: realTimeLibraryById.status.id || 3,
          verticalId: realTimeLibraryById.vertical.id,
          leagueId: realTimeLibraryById.league.id,
          insightTypeIds,
          isArchived: false
        }
        const success = await RealTimeDAO.putRealTimeLibraryById(
          newLibraryInfo,
          newLibraryInfo.id
        )
        if (success?.ok) {
          setDialogSuccessMessage(
            `You have successfully Archived Insight Type ${insightTypeId} from Library ${realTimeLibraryById.id}`
          )
          setDialogSuccessOpen(true)
          getRealTimeLibraryById(newLibraryInfo.id)
          onClose()
        } else {
          setDialogFailMessage(
            `Failed: Archiving Insight Type ${insightTypeId} from Library ${realTimeLibraryById.id}`
          )
          setDialogFailOpen(true)
          onClose()
        }
      }
    } else {
      const insightTypeIds = []
      //Below is  code for the single select Autocomplete
      realTimeLibraryById?.insightTypes?.forEach(x => {
        insightTypeIds.push(x.id)
      })
      if (typeFormValues?.insightType?.id) {
        insightTypeIds.push(typeFormValues?.insightType?.id)
      }
      //BELOW is multiselect Autocomplete code, DO NOT DELETE
      // realTimeLibraryById?.insightTypes.forEach(x => {
      //   insightTypeIds.push(x.id)
      // })
      // formValue?.insightType?.forEach(x => {
      //   insightTypeIds.push(x.id)
      // })
      const newLibraryInfo = {
        id: realTimeLibraryById.id,
        name: realTimeLibraryById.name,
        statusId: realTimeLibraryById.status.id || 3,
        verticalId: realTimeLibraryById.vertical.id,
        leagueId: realTimeLibraryById.league.id,
        insightTypeIds,
        isArchived: false
      }
      const success = await RealTimeDAO.putRealTimeLibraryById(
        newLibraryInfo,
        newLibraryInfo.id
      )
      if (success?.ok) {
        setDialogSuccessMessage(
          `You have successfully updated Library ${realTimeLibraryById.id}`
        )
        setDialogSuccessOpen(true)
        getRealTimeLibraryById(newLibraryInfo.id)
        onClose()
      } else {
        setDialogFailMessage(`Fail: Updating Library ${realTimeLibraryById.id}`)
        setDialogFailOpen(true)
        onClose()
      }
    }

    //onSave(newLibraryInfo)
  }

  return (
    <>
      <RealTimeFormTemplate
        open={open}
        onClose={handleClose}
        onReset={
          mode === "create"
            ? handleClose
            : function () {
                handleClose()
              }
        }
        onSave={handleSave}
        title={mode === "create" ? "Insight Type - Create" : "Insight Type"}
        mode={mode}
        showDialog={showDialog}
        readOnly={readOnly}
      >
        {(mode === "create" || !showDialog) && (
          <Grid container className={classes.formRow}>
            <GridItem xs={3}>
              <TextField
                label="INSIGHT TYPE ID"
                id="id"
                value={
                  typeFormValues.insightType?.id
                    ? typeFormValues.insightType?.id
                    : ""
                }
                variant="outlined"
                disabled={true}
              />
            </GridItem>
            <GridItem xs={3}>
              <Autocomplete
                aria-label="RealTimeLibrary InsightTypeForm TypeField"
                id="InsightType"
                //multiple={true}
                options={unusedInsightTypes || []}
                value={typeFormValues.insightType}
                getOptionLabel={option => option.name || ""}
                onChange={handleAutocompleteChange}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    label="INSIGHT TYPE"
                    //label="AVAILABLE INSIGHT TYPES"
                    required
                    error={!!formErrors.insightType}
                    helperText={formErrors.insightType}
                  />
                )}
                disabled={readOnly || (mode === "edit" && !showDialog)}
              />
            </GridItem>
            {(mode === "edit" || !showDialog) && (
              <GridItem xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      aria-label="RealTimeLibrary InsightTypeForm archiveCheckbox"
                      id="isArchived"
                      checked={typeFormValues.isArchived}
                      onChange={handleArchiveChange}
                      disabled={readOnly}
                    />
                  }
                  label="ARCHIVE"
                  labelPlacement="end"
                />
              </GridItem>
            )}
            <GridItem xs={1}>&nbsp;</GridItem>
          </Grid>
        )}
      </RealTimeFormTemplate>
    </>
  )
}
