import React from "react"
import { Link, withRouter } from "react-router-dom"
import classNames from "classnames"
import PropTypes from "prop-types"
import { observer, inject } from "mobx-react"
import withStyles from "@mui/styles/withStyles"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import Paper from "@mui/material/Paper"
import Grow from "@mui/material/Grow"
import Popper from "@mui/material/Popper"
import Person from "@mui/icons-material/Person"
import Notifications from "@mui/icons-material/Notifications"
import Dashboard from "@mui/icons-material/Dashboard"
import Button from "components/CustomButtons/Button"
import OrganizationsMenu from "components/Organizations/OrganizationsMenu"
import headerLinksStyle from "assets/jss/material-dashboard-pro-react/components/headerLinksStyle"
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Select,
  Snackbar,
  CircularProgress,
  Box
} from "@mui/material"
import { Settings, Search, Close } from "@mui/icons-material"
import { Cookies } from "tools/storage"
import { globalSettings } from "variables/general"
import TimeAgo from "react-timeago"
import Helpers from "../../tools/Helpers"
import { marked } from "marked"
import { HubConnectionBuilder } from "@microsoft/signalr"
import CustomDSDialog from "components/CustomDialogs/CustomDSDialog"
import Info from "@mui/icons-material/Info"
import { get, put } from "tools/request"
import AccountDAO from "daos/accountDAO"

const useStore = component =>
  inject(({ store }) => ({
    setSearchString: store.narrativeStore.setSearchString,
    searchString: store.narrativeStore.searchString,
    organizations: store.accountStore.accountOrganizations,
    organizationId: store.organizationStore.organizationId,
    orgAndPropId: store.organizationStore.orgAndPropId,
    setOrganizationId: store.organizationStore.setOrganizationId,
    setAccountProperties: store.accountStore.setAccountProperties,
    setPropertyId: store.organizationStore.setPropertyId,
    properties: store.accountStore.sortedProperties,
    sortedAccountOrganizations: store.accountStore.sortedAccountOrganizations,
    organizationProperties: store.organizationStore.organizationProperties,
    setOrganizationProperties:
      store.organizationStore.setOrganizationProperties,
    setOrgAndPropId: store.organizationStore.setOrgAndPropId
  }))(observer(component))

let cookies = new Cookies()
let initProp = cookies.get("currentProperty") || ""
class HeaderLinks extends React.Component {
  constructor(props) {
    super(props)
    marked.setOptions({
      gfm: true,
      tables: true,
      breaks: false,
      pedantic: false,
      smartLists: true,
      smartypants: false
    })
    this.state = {
      open: false,
      activeSearchString: props.searchString || "",
      showQuickLinks: false,
      inPortalNotificationNotYetRead: [],
      inPortalNotificationNotYetReadCount: 0,
      inPortalToastNotifications: [],
      inPortalToastNotificationsOpen: [],
      websocketInfoDialogOpen: false,
      websocketInfoDialogMessage: "",
      markingAllInPortalNotificationAsRead: false,
      property: initProp
    }
  }

  componentDidMount() {
    this.establishWebSocketConnection()
    if (process.env.NODE_ENV !== "development") {
      //this.establishWebSocketConnection()
      // Disabling polling for notifications
      // this.retrieveInPortalNotifications()
      // this.retrieveInPortalNotificationsNotYetReadCount()
      // this.retrieveInPortalToastNotifications()
      // this.interval = setInterval(() => {
      //   this.retrieveInPortalNotifications()
      //   this.retrieveInPortalNotificationsNotYetReadCount()
      //   this.retrieveInPortalToastNotifications()
      // }, 30000) // 30 seconds auto-refresh
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  handleSearch = searchString => {
    this.props.setSearchString(searchString)
    this.props.history.push("/portal/search")
  }

  handleSearchChange = searchString => {
    let isNumber = false
    // Check if input is string
    if (typeof searchString !== "string") {
      isNumber = false
    } else {
      // Use type coercion to parse the _entirety_ of the string
      // (`parseFloat` alone does not do this).
      // Also ensure that the strings whitespaces fail
      isNumber = !isNaN(searchString) && !isNaN(parseFloat(searchString))
    }
    this.setState({
      activeSearchString: searchString,
      showQuickLinks: isNumber
    })
  }

  handleCellChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleRedirect = path => {
    this.props.history.push(path)
    this.setState({
      activeSearchString: "",
      showQuickLinks: false
    })
  }

  handleNotificationsClick = () => {
    this.setState({ open: !this.state.open })
  }

  onDashboardButtonClick = () => {
    this.props.history.push("/portal/dashboard")
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  onPropertyChange = event => {
    this.handleCellChange(event)
    let cookies = new Cookies()
    cookies.set("currentProperty", event.target.value, { path: "/" })
    this.props.setPropertyId(event.target.value)
    let orgAndPropId = event.target.value
    if (event.target.value === "All Properties") {
      orgAndPropId = cookies.get("currentorg")
    }
    this.props.setOrgAndPropId(orgAndPropId)
    const pagesToReloadOnOrgChange = [
      //if changing orgs inside feed entries, go back to feed-manager to reload
      {
        matchValue: /portal\/feed-manager/,
        reloadURL: "/portal/feed-manager"
      },
      {
        matchValue: /portal\/narratives\/?/,
        reloadURL: "/portal/narratives"
      },
      {
        matchValue: /portal\/render-schedule\/?/,
        reloadURL: "/portal/render-schedule"
      },
      {
        matchValue:
          /portal\/narrative\/(?:\d{3,}\/?$|\d{3,}\/edit\/?$|\d{3,}\/output\/?$|\d{3,}\/schedule\/?$)/,
        reloadURL: "/portal/narratives"
      },
      {
        matchValue: /portal\/real-time-outputs\/?/,
        reloadURL: "/portal/real-time-outputs"
      }
    ]
    pagesToReloadOnOrgChange.forEach(itm => {
      if (this.props.location.pathname.match(itm.matchValue)) {
        this.props.history.push(itm.reloadURL)
      }
    })
  }

  loadOrganizationAndProperties(organizationId, properties) {
    let propertiesForOrg = []
    let newOrgId = null
    properties &&
      properties.forEach(itm => {
        if (itm.parentId === organizationId) {
          propertiesForOrg.push(itm)
        } else if (itm.id === organizationId && !!itm.parentId) {
          newOrgId = itm.parentId
        }
      })
    if (newOrgId && properties.length !== propertiesForOrg.length) {
      this.props.setOrganizationId(newOrgId)
      this.props.setOrgAndPropId(newOrgId)
      this.props.setOrganizationProperties(propertiesForOrg)
    } else if (properties.length !== propertiesForOrg.length) {
      this.props.setOrganizationId(organizationId)
      this.props.setOrgAndPropId(organizationId)
      this.props.setOrganizationProperties(propertiesForOrg)
    }
  }

  retrieveInPortalNotificationsNotYetReadCount() {
    get(
      `${globalSettings.apiBaseUrl}/api/notification/retrieveinportalnotificationsnotyetreadcount`
    )
      .then(Response => Response.json())
      .then(response => {
        this.setState({
          inPortalNotificationNotYetReadCount: response.content
        })
      })
      .catch(err => {
        console.error("retrieveInPortalNotificationsNotYetReadCount Error", err)
      })
  }

  retrieveInPortalNotifications() {
    get(
      `${globalSettings.apiBaseUrl}/api/notification/retrieveinportalnotificationsnotyetread`
    )
      .then(Response => Response.json())
      .then(response => {
        this.setState({
          inPortalNotificationNotYetRead: response.content
        })
      })
      .catch(err => {
        console.error("retrieveInPortalNotifications Error", err)
      })
  }

  displayInPortalToastNotification() {
    let notifications = [{ message: "the message" }]
    this.setState({
      inPortalToastNotifications: notifications,
      inPortalToastNotificationsOpen: new Array(notifications).fill(true)
    })
  }

  establishWebSocketConnection = async () => {
    // https://docs.microsoft.com/en-us/javascript/api/@aspnet/signalr/?view=signalr-js-latest
    let accessToken = async () => {
      await AccountDAO.wsTokenCheck()
      return localStorage.getItem("accessToken")
    }
    let newConnection = new HubConnectionBuilder()
      .withUrl(`${globalSettings.apiBaseUrl}/api/ws/portalhub`, {
        accessTokenFactory: async () => await accessToken()
      })
      .withAutomaticReconnect()
      .build()
    newConnection.on("receiveInPortalToastNotificationByUser", message => {
      let notifications = [{ message: message }]
      this.setState({
        inPortalToastNotifications: notifications,
        inPortalToastNotificationsOpen: new Array(notifications).fill(true)
      })
    })
    newConnection.on("receiveInPortalInfoDialog", message => {
      this.setState({
        websocketInfoDialogOpen: true,
        websocketInfoDialogMessage: message
      })
    })
    await newConnection.start()
  }

  retrieveInPortalToastNotifications() {
    get(
      `${globalSettings.apiBaseUrl}/api/notification/retrieveinportaltoastnotifications`
    )
      .then(Response => Response.json())
      .then(response => {
        this.setState({
          inPortalToastNotifications: response.content,
          inPortalToastNotificationsOpen: new Array(
            response.content.length
          ).fill(true)
        })
      })
      .catch(err => {
        console.error("retrieveInPortalNotificationsNotYetReadCount Error", err)
      })
  }

  markInPortalNotificationAsRead(notificationQueueId) {
    put(
      `${globalSettings.apiBaseUrl}/api/notification/markinportalnotificationasread?notificationQueueId=${notificationQueueId}`,
      null,
      ""
    )
      .then(Response => Response && Response.ok === true && Response.json())
      .then(() => {
        this.retrieveInPortalNotifications()
        this.retrieveInPortalNotificationsNotYetReadCount()
      })
      .catch(err => {
        console.error(
          `Error on PUT to /api/notification/markinportalnotificationasread?notificationQueueId=${notificationQueueId}`,
          err
        )
      })
  }

  markAllInPortalNotificationAsRead() {
    this.setState({ markingAllInPortalNotificationAsRead: true })
    put(
      `${globalSettings.apiBaseUrl}/api/notification/markallinportalnotificationasread`,
      null,
      ""
    )
      .then(Response => Response && Response.ok === true && Response.json())
      .then(() => {
        this.retrieveInPortalNotifications()
        this.retrieveInPortalNotificationsNotYetReadCount()
        this.setState({ markingAllInPortalNotificationAsRead: false })
      })
      .catch(err => {
        console.error(
          "Error on PUT to /api/notification/markallinportalnotificationasread",
          err
        )
      })
  }

  render() {
    const { classes, rtlActive, showOrgMenu } = this.props
    const { open, activeSearchString, showQuickLinks } = this.state
    let properties = []

    const searchButton = `${classes.top} ${classes.searchButton} ${classNames({
      [classes.searchRTL]: rtlActive
    })}`
    const dropdownItem = classNames(
      classes.dropdownItem,
      classes.primaryHover,
      { [classes.dropdownItemRTL]: rtlActive },
      { width: "400px" }
    )
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive
    })
    const managerClasses = classNames({
      [classes.managerClasses]: true
    })

    let property = cookies.get("currentProperty") || ""
    let org = cookies.get("currentorg") || ""
    let organizationId = org || this.props.organizationId
    let isOrganizationIdParentOrg = false
    let orgMatch = false

    this.props.sortedAccountOrganizations.forEach(itm => {
      //We are pulling the properties out of the organization object, and then sorting them
      if (itm.id === organizationId) {
        isOrganizationIdParentOrg = true
        orgMatch = true
        properties = itm.properties
          .filter(a => a.isActive)
          .sort((a, b) =>
            a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
          )
      }
    })

    // START - need a way to account for a "Property" that looks like an "Organization"
    if (!isOrganizationIdParentOrg) {
      //In case we have a Property, not org, as the organizationId, need to finds its parent to property show the
      this.props.sortedAccountOrganizations.forEach(org => {
        org.properties &&
          org.properties.forEach(prp => {
            if (prp.id === organizationId) {
              orgMatch = true
              property = property
                ? property === "All Properties"
                  ? ""
                  : property
                : organizationId
              organizationId = prp.parentId
              properties = org.properties
                .filter(a => a.isActive)
                .sort((a, b) =>
                  a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
                )
            }
          })
      })
    }

    let organizationName = ""
    organizationId &&
      this.props.sortedAccountOrganizations.forEach(itm => {
        if (itm.id === organizationId) {
          organizationName = itm.name
        }
      })

    return (
      <div className={wrapper}>
        <span
          style={{
            display: "none",
            padding: "7px",
            margin: "0 10px",
            border: "3px solid",
            color: `${orgMatch} ? "red" : "inherit"`
          }}
        >
          My Selected Organization is {organizationId}{" "}
          {!orgMatch ? " --- NO ORG MATCH!" : ""}
        </span>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="portal-search-box">Search...</InputLabel>
          <Input
            className={classes.searchInput}
            id="portal-search-box"
            type="text"
            value={activeSearchString || ""}
            onChange={e => this.handleSearchChange(e.target.value)}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                this.handleSearch(e.target.value)
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  title="Search"
                  className={searchButton}
                  aria-label="Headerlinks Search Button"
                  onClick={() => this.handleSearch(activeSearchString)}
                  size="large"
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            }
          />
          {showQuickLinks && (
            <div className={classes.quickLinks}>
              <MenuList>
                <MenuItem
                  title="Go To Narrative"
                  onClick={() =>
                    this.handleRedirect(
                      `/portal/narrative/${activeSearchString}`
                    )
                  }
                >
                  View&nbsp;<strong>Narrative {activeSearchString}</strong>
                </MenuItem>
                <MenuItem
                  title="Edit Narrative"
                  onClick={() =>
                    this.handleRedirect(
                      `/portal/narrative/${activeSearchString}/edit`
                    )
                  }
                >
                  Edit&nbsp;<strong>Narrative {activeSearchString}</strong>
                </MenuItem>
                <MenuItem
                  title="Go To Feed"
                  onClick={() =>
                    this.handleRedirect(
                      `/portal/feed-entries/${activeSearchString}`
                    )
                  }
                >
                  View&nbsp;<strong>Feed {activeSearchString}</strong>
                </MenuItem>
                <MenuItem
                  title="Go To Feed Entry"
                  onClick={() =>
                    this.handleRedirect(
                      `/portal/feed-entries/entry/${activeSearchString}`
                    )
                  }
                >
                  View&nbsp;<strong>Feed Entry {activeSearchString}</strong>
                </MenuItem>
              </MenuList>
            </div>
          )}
        </FormControl>
        {showOrgMenu && (
          <div
            id="OrganizationDropdown"
            className={classes.orgMenuOn}
            style={{ margin: "auto 10px" }}
          >
            <OrganizationsMenu
              showOrgMenu={showOrgMenu}
              organizationId={organizationId}
            />
            {properties.length > 0 && (
              <Select
                variant="standard"
                fullWidth={false}
                type="select"
                name="property"
                id="property"
                value={property || "All Properties"}
                onChange={e => this.onPropertyChange(e)}
                style={{ margin: "auto 10px" }}
              >
                <MenuItem key="All Properties" value="All Properties">
                  All Properties
                </MenuItem>
                <MenuItem key={organizationId} value={organizationId}>
                  {organizationName}
                  {" (Parent)"}
                </MenuItem>
                {properties.sort(Helpers.sortByName).map(property => (
                  <MenuItem key={property.id} value={property.id}>
                    {property.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </div>
        )}
        <Button
          color="transparent"
          simple
          aria-label="Dashboard"
          justIcon
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : ""
          }}
          onClick={this.onDashboardButtonClick}
        >
          <Dashboard
            className={`${classes.headerLinksSvg} ${
              rtlActive ? `${classes.links} ${classes.linksRTL}` : classes.links
            }`}
          />
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            <span className={classes.linkText}>
              {rtlActive ? "لوحة القيادة" : "Dashboard"}
            </span>
          </Box>
        </Button>
        <div className={managerClasses}>
          <Button
            color="transparent"
            justIcon
            aria-label="Notifications"
            aria-owns={open ? "menu-list" : null}
            aria-haspopup="true"
            onClick={this.handleNotificationsClick}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : ""
            }}
          >
            <Notifications
              className={`${classes.headerLinksSvg} ${
                rtlActive
                  ? `${classes.links} ${classes.linksRTL}`
                  : classes.links
              }`}
            />
            {this.state.inPortalNotificationNotYetReadCount > 0 && (
              <span className={classes.notifications}>
                {this.state.inPortalNotificationNotYetReadCount > 99
                  ? "99+"
                  : this.state.inPortalNotificationNotYetReadCount}
              </span>
            )}
            <Box sx={{ display: { xs: "block", md: "none" } }}>
              <span
                onClick={this.handleClickNotification}
                className={classes.linkText}
              >
                {rtlActive ? "إعلام" : "Notification"}
              </span>
            </Box>
          </Button>
          <div style={{ position: "relative", left: "-400px" }}>
            <Popper
              open={Boolean(open)}
              anchorEl={open}
              transition
              disablePortal
              placement="bottom"
              className={classNames({
                [classes.popperClose]: !open,
                [classes.popperResponsive]: true,
                [classes.popperNav]: true
              })}
              style={{
                width: "400px",
                whiteSpace: "normal",
                position: "relative"
              }}
            >
              {({ TransitionProps }) => (
                <Grow
                  {...TransitionProps}
                  id="notification-menu-list"
                  style={{ transformOrigin: "0 0 0" }}
                >
                  <Paper className={classes.dropdown}>
                    <ClickAwayListener onClickAway={this.handleClose}>
                      <div
                        style={{
                          height: "1000px",
                          overflow: "auto",
                          textAlign: "right"
                        }}
                      >
                        <div style={{ margin: "10px" }}>
                          {this.state.inPortalNotificationNotYetRead.length ===
                            0 && <div>You have no notifications.</div>}
                          <Link
                            to={`/portal/accountnotifications`}
                            onClick={this.handleClose}
                          >
                            <Settings style={{ width: "0.7em" }} /> Configure
                            notifications
                          </Link>
                          {this.state.inPortalNotificationNotYetReadCount >
                            1 && (
                            <Link
                              onClick={() =>
                                this.markAllInPortalNotificationAsRead()
                              }
                            >
                              <div>
                                Mark all{" "}
                                {this.state.inPortalNotificationNotYetReadCount}{" "}
                                notifications as read
                              </div>
                            </Link>
                          )}
                        </div>
                        {this.state.markingAllInPortalNotificationAsRead && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center"
                            }}
                          >
                            <CircularProgress
                              style={{
                                position: "relative",
                                zIndex: "101"
                              }}
                            />
                          </div>
                        )}

                        {!this.state.markingAllInPortalNotificationAsRead && (
                          <MenuList role="menu">
                            {this.state.inPortalNotificationNotYetRead.map(
                              (n, index) => {
                                let menuItemStyle =
                                  index % 2
                                    ? { backgroundColor: "default" }
                                    : {}
                                menuItemStyle.whiteSpace = "normal"
                                let markdownToHTML = marked(n.message)
                                return (
                                  <MenuItem
                                    onClick={() =>
                                      this.markInPortalNotificationAsRead(n.id)
                                    }
                                    className={dropdownItem}
                                    style={menuItemStyle}
                                  >
                                    <span
                                      style={
                                        n.hasBeenRead
                                          ? { fontWeight: "normal" }
                                          : { fontWeight: "bold" }
                                      }
                                    >
                                      <em>
                                        <TimeAgo
                                          date={n.sentDateTime}
                                          title={Helpers.prettyDateTimeinPacificTimeZone(
                                            n.sentDateTime
                                          )}
                                        />
                                      </em>{" "}
                                      <br />
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: rtlActive
                                            ? markdownToHTML
                                            : markdownToHTML
                                        }}
                                      />
                                    </span>
                                  </MenuItem>
                                )
                              }
                            )}
                          </MenuList>
                        )}
                      </div>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </div>
        {this.state.inPortalToastNotifications.map((n, index) => {
          let menuItemStyle = index % 2 ? { backgroundColor: "default" } : {}
          menuItemStyle.whiteSpace = "normal"
          let markdownToHTML = marked(n.message)
          return (
            <Snackbar
              key={index}
              autoHideDuration={5000}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              open={
                this.state.inPortalToastNotificationsOpen[index] ? true : false
              }
              onClose={() => {
                let inPortalToastNotificationsOpen = [
                  ...this.state.inPortalToastNotificationsOpen
                ]
                inPortalToastNotificationsOpen[index] = false
                this.setState({
                  inPortalToastNotificationsOpen
                })
              }}
              message={
                <span
                  dangerouslySetInnerHTML={{
                    __html: rtlActive ? markdownToHTML : markdownToHTML
                  }}
                />
              }
              action={
                <React.Fragment>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() => {
                      let inPortalToastNotificationsOpen = [
                        ...this.state.inPortalToastNotificationsOpen
                      ]
                      inPortalToastNotificationsOpen[index] = false
                      this.setState({
                        inPortalToastNotificationsOpen
                      })
                    }}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            />
          )
        })}
        <div className={managerClasses}>
          <Button
            color="transparent"
            aria-label="Person"
            justIcon
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : ""
            }}
            onClick={() => {
              this.props.history.push("/portal/accountprofile")
            }}
          >
            <Person
              className={`${classes.headerLinksSvg} ${
                rtlActive
                  ? `${classes.links} ${classes.linksRTL}`
                  : classes.links
              }`}
            />
            <Box sx={{ display: { xs: "block", md: "none" } }}>
              <span className={classes.linkText}>
                {rtlActive ? "الملف الشخصي" : "Profile"}
              </span>
            </Box>
          </Button>
        </div>
        <CustomDSDialog
          titleContent="System message"
          open={this.state.websocketInfoDialogOpen}
          content={
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.websocketInfoDialogMessage
              }}
            />
          }
          icon={<Info style={{ fontSize: "4rem" }} />}
          onClose={() => {
            this.setState({
              websocketInfoDialogOpen: false
            })
          }}
        />
      </div>
    )
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool,
  showOrgMenu: PropTypes.bool
}

export default withStyles(headerLinksStyle)(withRouter(useStore(HeaderLinks)))
