import { action, observable, decorate, reaction } from "mobx"
import AppConfigDAO from "../daos/appConfigDAO"

export default class AppConfigurationStore {
  announcementMessage = null
  siteInfo = null
  shouldDisplayCTAManagerV2 = null
  displayRealTimeLibrary = null

  constructor(rootStore) {
    this.rootStore = rootStore
  }

  /**
   * Reactions
   */
  resetMessage = reaction(
    () => this.announcement,
    announcement => {
      if (!announcement) {
        this.setAnnouncementMessage(null)
      }
    }
  )

  /**
   * Methods
   */

  getAnnouncementConfig = async () => {
    try {
      const announcementConfig = await AppConfigDAO.getAppConfig(
        "portalannouncement"
      )
      this.setAnnouncementConfig(announcementConfig)
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  getAnnouncementMessage = async () => {
    try {
      const announcementMessage = await AppConfigDAO.getAppMessage(
        "portalannouncement"
      )
      this.setAnnouncementMessage(announcementMessage)
    } catch (err) {
      console.error("Error: ", err)
    }
  }
  getCTAManagerConfig = async () => {
    try {
      const ctaConfigRestrictionResponse = await AppConfigDAO.getAppConfig(
        "canseectav2"
      )
      this.setCTAManagerConfig(ctaConfigRestrictionResponse)
    } catch (err) {
      console.error("Error: ", err)
    }
  }
  getGraphicsConfig = async () => {
    try {
      const graphicstoolConfigRestrictionResponse =
        await AppConfigDAO.getAppConfig("canseegraphicstoolv2")
      this.setCTAManagerConfig(graphicstoolConfigRestrictionResponse)
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  getRealTimeConfig = async () => {
    try {
      const realtimeConfigResponse = await AppConfigDAO.getAppConfig(
        "canseerealtime"
      )
      this.setRealTimeConfig(realtimeConfigResponse)
    } catch (err) {
      console.log("Error in realtime feature flag")
    }
  }
  /**
   * Internal Actions
   */

  setAnnouncementConfig(value) {
    this.announcement = value
  }

  setAnnouncementMessage(value) {
    this.announcementMessage = value
  }

  setSiteInfo(value) {
    this.siteInfo = value
  }
  setCTAManagerConfig(value) {
    this.shouldDisplayCTAManagerV2 = value
  }
  setRealTimeConfig(value) {
    this.displayRealTimeLibrary = value
  }
}

decorate(AppConfigurationStore, {
  // observables
  announcement: observable,
  announcementMessage: observable,
  siteInfo: observable,
  shouldDisplayCTAManagerV2: observable,
  displayRealTimeLibrary: observable,

  // actions
  setAnnouncementConfig: action.bound,
  setAnnouncementMessage: action.bound,
  setSiteInfo: action.bound,
  setCTAManagerConfig: action.bound,
  setRealTimeConfig: action.bound
})
