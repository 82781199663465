import React from "react"
import styled from "styled-components"
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useRowSelect,
  useBlockLayout,
  useResizeColumns,
  useRowState
} from "react-table"
import matchSorter from "match-sorter"

const Styles = styled.div`
  background-color: #fff;
  .focused {
    background-color: #eee !important;
    text-decoration: underline;
  }
  .table {
    font-size: 13px;
    max-height: 65vh;
    font-family: "Roboto", arial, sans-serif;
    max-width: 100%;
    overflow: auto;
    display: inline-block;
    border-spacing: 0;
    border: 1px solid #ccc;
    .tr {
      display: flex;
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      margin: 0;
      padding: 0;
      position: relative;
      border: 1px solid #ccc;
      select,
      input {
        width: 100%;
        padding: 5px;
        margin: 0;
        border: none;
        background-color: #ffffff;
      }
      input[type="checkbox"] {
        width: auto;
        padding: 6px;
        border: 0;
      }
      button svg {
        font-size: 20px;
      }
      select {
        height: 33px;
      }
      .tool-cell {
        padding: 5px;
        margin: 0;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: left;
        & .tool-buttons {
          min-width: 40px;
        }
      }
      .resizer {
        display: inline-block;
        width: 3px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        touch-action: none;
        &:hover {
          background: #4285f4;
        }
        &.isResizing {
          background: #c9a872;
        }
      }
    }

    .th {
      background-color: #f8f9fa;
      > div:first-child {
        font-weight: bold;
        text-align: center;
        padding: 5px;
      }
      input[type="range"] {
        width: 75%;
      }
      input,
      select {
        border: 1px solid transparent;
        background-color: transparent;
      }
    }
  }
`

// Create an editable cell renderer
export const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id, type },
  handleDataChange, // This is a custom function that we supplied to our table instance
  readOnly
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue || "")

  const onChange = e => {
    if (readOnly) {
      return
    }
    setValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = e => {
    if (readOnly) {
      return
    }
    handleDataChange(index, id, value)
    let tgt = e.target
    tgt.className = ""
  }

  const onFocus = e => {
    if (readOnly) {
      return
    }
    let tgt = e.target
    tgt.className = "focused"
  }

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  let inputObject =
    id === "futureOccurences" ? (
      <input
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={true}
        style={{ background: "#eee" }}
      />
    ) : (
      <input
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    )
  if (type === "checkbox") {
    return (
      <div
        style={{
          padding: "5px",
          textAlign: "center"
        }}
      >
        {inputObject}
      </div>
    )
  }
  return inputObject
}

// Define a default UI for filtering
export function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter }
}) {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ""}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

export function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

// Define a custom filter filter function!
export function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}

// This is an autoRemove method on the filter export function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== "number"

// Be sure to pass our handleDataChange and the skipReset option
function TableControlsNoToolbar({
  columns,
  hideColumns,
  data,
  handleDataChange,
  resetData,
  skipReset,
  readOnly
}) {
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 50,
      width: 150,
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
      // And also ouEditableCellr default editable cell
      Cell: EditableCell
    }),
    []
  )

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      //filterTypes,
      // handleDataChange isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      handleDataChange,
      readOnly,
      resetData,
      // We also need to pass this so the page doesn't change
      // when we edit the data.
      autoResetPage: !skipReset,
      autoResetSelectedRows: !skipReset,
      autoResetResize: false,
      autoResetHiddenColumns: false,
      disableMultiSort: false,
      initialState: {
        pageSize: 1000,
        hiddenColumns: hideColumns || []
      }
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    useBlockLayout,
    useResizeColumns,
    useRowState,
    // Here we will use a plugin to add our selection column
    hooks => {
      hooks.visibleColumns.push(columns => [...columns])
    }
  )

  return (
    <Styles>
      <div>
        <div {...getTableProps()} className="table">
          <div>
            {headerGroups.map(headerGroup => (
              <div
                key={headerGroup.key}
                {...headerGroup.getHeaderGroupProps()}
                className="tr"
              >
                {headerGroup.headers.map(column => (
                  <div
                    key={column.key}
                    {...column.getHeaderProps()}
                    className="th"
                  >
                    <div>
                      {column.canGroupBy ? (
                        // If the column can be grouped, let's add a toggle
                        <span {...column.getGroupByToggleProps()}>
                          {column.isGrouped ? "🛑 " : "👊 "}
                        </span>
                      ) : null}
                      <span {...column.getSortByToggleProps()}>
                        {column.render("Header")}
                        {/* Add a sort direction indicator */}
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </div>
                    {/* Render the columns filter UI */}
                    <div>
                      {column.canFilter && false
                        ? column.render("Filter")
                        : null}
                    </div>
                    {/* Use column.getResizerProps to hook up the events correctly */}
                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${
                        column.isResizing ? "isResizing" : ""
                      }`}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} style={{ display: "grid" }}>
            {page.map(row => {
              prepareRow(row)
              return (
                <div
                  key={row.key}
                  {...row.getRowProps()}
                  style={{ display: "inline-block" }}
                >
                  <div className="tr">
                    {row.cells.map(cell => (
                      <div
                        key={cell.key}
                        {...cell.getCellProps()}
                        className="td"
                      >
                        {cell.isGrouped ? (
                          // If it's a grouped cell, add an expander and row count
                          <>
                            <span {...row.getToggleRowExpandedProps()}>
                              {row.isExpanded ? "👇" : "👉"}
                            </span>{" "}
                            {cell.render("Cell", { editable: false })} (
                            {row.subRows.length})
                          </>
                        ) : cell.isAggregated ? (
                          // If the cell is aggregated, use the Aggregated
                          // renderer for cell
                          cell.render("Aggregated")
                        ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                          // Otherwise, just render the regular cell
                          cell.render("Cell", {
                            editable:
                              cell.column.id === "futureOccurences"
                                ? false
                                : true
                          })
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </Styles>
  )
}

export default TableControlsNoToolbar
