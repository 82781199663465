import React from "react"
import { MobXProviderContext } from "mobx-react"

const useStore = () => {
  const { store } = React.useContext(MobXProviderContext)

  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error("useStore must be used within a StoreProvider.")
  }

  return store
}

export { useStore }
