import { Box, List, Typography } from "@mui/material"
import ItemEditListItem from "./ItemEditListItem"

const ItemEditSummary = ({ title = null, changeList = [] }) => (
  <Box>
    {title && <Typography variant="h5">{title}</Typography>}
    {changeList?.length ? (
      <List>
        {changeList.map((entry, i) => (
          <ItemEditListItem changeEntry={entry} key={i} />
        ))}
      </List>
    ) : (
      <Box mt={1} mb={2}>
        <Typography variant="body1">No changes</Typography>
      </Box>
    )}
  </Box>
)

export default ItemEditSummary
