import React from "react"
import PropTypes from "prop-types"
import withStyles from "@mui/styles/withStyles"
import TimeAgo from "react-timeago"
import Helpers from "../../tools/Helpers"

const defaultProps = {
  selectedImage: {},
  image: {},
  testing: 1
}

const style = {
  singleImage: {
    "&:hover": {
      transform: "scale(1.05)",
      outline: "2px solid yellow"
    }
  }
}

class SingleImageResult extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      isBeingHovered: false,
      imageDetailsPositionX: 0,
      imageDetailsPositionY: 0
    }
  }

  onImageLoad({ target: img }) {
    this.props.image.width = img.naturalWidth
    this.props.image.height = img.naturalHeight
  }

  render() {
    const { classes, image } = this.props
    return (
      <React.Fragment>
        <span>
          <img
            className={classes.singleImage}
            src={this.props.image.url}
            width={this.props.width}
            alt=""
            style={{
              margin: "5px",
              outline:
                this.props.selectedImage.externalId ===
                this.props.image.externalId
                  ? "5px solid red"
                  : ""
            }}
            onClick={() => {
              this.props.onImageClickCallBack(image)
            }}
            onMouseEnter={e => {
              let imageRect = e.target.getBoundingClientRect()
              this.setState({
                isBeingHovered: true,
                imageDetailsPositionX: imageRect.x - imageRect.width,
                imageDetailsPositionY: imageRect.offsetHeight
              })
            }}
            onMouseOut={() => {
              this.setState({ isBeingHovered: false })
            }}
            onLoad={this.onImageLoad.bind(this)}
          />
          {this.state.isBeingHovered && (
            <div
              style={{
                position: "absolute",
                zIndex: "1000",
                top: this.state.imageDetailsPositionY,
                left: this.state.imageDetailsPositionX,
                width: "500px",
                height: "220px",
                backgroundColor: "white",
                border: "1px solid black",
                fontSize: "0.7em"
              }}
            >
              External Source: {image.externalSourceName} <br />
              External Id: {image.externalId} <br />
              Title: {image.title} <br />
              Caption: {image.caption} <br />
              Preview Dimensions: {image.width} X {image.height}
              &nbsp;Max Download Dimensions: {image.maxDownloadWidth} X{" "}
              {image.maxDownloadHeight} <br />
              Photo Taken Date/Time: <TimeAgo
                date={image.photoTakenDateTime}
              />{" "}
              (
              {Helpers.prettyDateTimeinPacificTimeZone(
                image.photoTakenDateTime
              )}
              )
            </div>
          )}
        </span>
      </React.Fragment>
    )
  }
}

SingleImageResult.propTypes = {
  // Where the user to be redirected on clicking the avatar
  onChange: PropTypes.func,
  value: PropTypes.string,
  required: PropTypes.bool
}
SingleImageResult.defaultProps = defaultProps

export default withStyles(style)(SingleImageResult)
