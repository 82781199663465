import React, { useState } from "react"
import PropTypes from "prop-types"

// MUI Components
import Grid from "@mui/material/Grid"
import InputAdornment from "@mui/material/InputAdornment"
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined"
import TextField from "@mui/material/TextField"
import Button from "components/CustomButtons/Button"
import { Autocomplete } from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import GridItem from "components/Grid/GridItem"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"

dayjs.extend(utc)
const styles = theme => ({
  headings: {
    display: "inline",
    fontSize: "1.5rem",
    fontWeight: 400
  },
  checkboxLabel: {
    "& .MuiFormControlLabel-label": {
      color: "#000",
      fontWeight: 400
    }
  },
  subtitle: {
    display: "inline",
    color: "#888"
  },
  requiredMsg: {
    color: "#c00",
    fontWeight: "bold",
    backgroundColor: "#f7f7f7"
  },
  formContentContainer: {
    _border: "1px solid #AAA",
    margin: "15px",
    background: "#fff",
    _borderRadius: "20px",
    _minWidth: "1200px",
    overflowX: "auto"
  },
  contractSelect: {
    minWidth: 180
  }
})

function AssociateOrganization({
  key,
  associationId,
  organizationId,
  splitPercentage,
  organizations,
  associatedOrganizations,
  handleSave,
  handleNewAffiliateOrganization
}) {
  // Variable Assignment
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(
    organizationId || null
  )
  const [selectedSplitPercentage, setSelectedSplitPercentage] = useState(
    splitPercentage || null
  )

  const handleAutocompleteChange = value => {
    setSelectedOrganizationId(value?.id || null)
  }

  const handleChangeNumber = event => {
    let value = event.target.value

    // If there are leading zeros, remove them.
    if (/^(?:0+(?=[1-9])|0+(?=0$))/.test(value)) {
      value = value.replace(/^(?:0+(?=[1-9])|0+(?=0$))/, "")
    }

    if (value) {
      // If there is non-null value, ensure only 2 decimal places are used.
      const index = value.indexOf(".")
      value =
        index >= 0 ? value.substr(0, index) + value.substr(index, 3) : value
    }

    // Check for no value (empty string) and set to null
    if (value === "") {
      value = null
    } else if (Number(value) > 100) {
      value = 100
    }

    setSelectedSplitPercentage(value)
  }

  return (
    <>
      <Grid container style={{ marginTop: "1.5rem", _width: "70%" }}>
        <GridItem>
          <Autocomplete
            id={`${key}-organizationId`}
            fullWidth={false}
            options={organizations.sort(function (a, b) {
              return a.name && a.name.localeCompare(b.name)
            })}
            size="small"
            required={true}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextField
                {...params}
                label="ORGANIZATION"
                variant="outlined"
                key={params.id}
                value={params.name}
                required={true}
              />
            )}
            value={
              organizations.find(org => org.id === selectedOrganizationId) ||
              null
            }
            onChange={(event, value) => handleAutocompleteChange(value)}
            style={{
              width: "300px",
              display: "inline-flex",
              margin: "0 10px 0 0"
            }}
            getOptionDisabled={option =>
              associatedOrganizations.some(
                obj => obj.organizationId === option.id
              )
            }
          />

          <TextField
            type="number"
            id={`${key}-splitPercentage`}
            label="Split % to Affiliate"
            style={{
              color: "#000 !important",
              width: "200px",
              margin: "0 10px 0 0"
            }}
            value={selectedSplitPercentage}
            onChange={handleChangeNumber}
            _margin="normal"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" tabIndex={-1}>
                  %
                </InputAdornment>
              )
            }}
            inputProps={{
              min: 0,
              max: 100,
              step: 1,
              pattern: "\\d*"
            }}
          />
          <Button
            style={{
              color: "#000 !important",
              padding: "4px",
              margin: "0 10px 0 0"
            }}
            color="white"
            onClick={() => {
              setSelectedOrganizationId(organizationId || null)
              setSelectedSplitPercentage(splitPercentage || null)
            }}
          >
            Cancel
          </Button>

          <Button
            style={{
              color: "#000 !important",
              padding: "4px",
              margin: "0 10px 0 0"
            }}
            color="primary"
            onClick={() =>
              handleSave(
                selectedOrganizationId,
                associationId,
                selectedSplitPercentage
              )
            }
            disabled={!selectedOrganizationId || !selectedSplitPercentage}
          >
            Save
          </Button>

          <LibraryAddOutlinedIcon
            onClick={handleNewAffiliateOrganization}
            style={{
              color: "#000 !important",
              margin: "0 10px 0 0"
            }}
          />
        </GridItem>
      </Grid>
    </>
  )
}

AssociateOrganization.propTypes = {
  key: PropTypes.string,
  associationId: PropTypes.number,
  organizationId: PropTypes.number,
  splitPercentage: PropTypes.string || PropTypes.number,
  organizations: PropTypes.array,
  associatedOrganizations: PropTypes.array,
  handleSave: PropTypes.func,
  handleNewAffiliateOrganization: PropTypes.func
}

export default withStyles(styles)(AssociateOrganization)
