import React from "react"
import PropTypes from "prop-types"
import LinearProgress from "@mui/material/LinearProgress"
import classnames from "classnames"

import "assets/css/Components/Loading.css"

const defaultProps = {
  active: false
}

const propTypes = {
  active: PropTypes.bool
}

const Loading = ({ active }) => (
  <div className={classnames("loading", { active })}>
    {active && <LinearProgress />}
  </div>
)

Loading.defaultProps = defaultProps
Loading.propTypes = propTypes
export default Loading
