import React from "react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"

import RenderTool from "./RenderTool"

const queryClient = new QueryClient()

const RenderToolWrapper = props => (
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <RenderTool />
  </QueryClientProvider>
)

export default RenderToolWrapper
