import React from "react"
//import { Link } from "react-router-dom"
import {
  Card,
  CardContent,
  CardActions,
  Typography
  //IconButton
} from "@mui/material"
import Button from "components/CustomButtons/Button"

//import Close from "@mui/icons-material/Close"

const FormTemplateNonDialog = ({
  onSave,
  title,
  children,
  showPreview,
  onPreview,
  onViewInLibrary,
  hasPreview,
  onClose,
  readOnly,
  onReset
}) => (
  <Card elevation={0}>
    <CardContent>
      {title && (
        <Typography variant="h6" component="div">
          {title}
        </Typography>
      )}
      {/* <IconButton
        onClick={onClose}
        style={{ position: "absolute", right: "8px" }}
      >
        <Close />
      </IconButton> */}
      {children}
    </CardContent>
    {!readOnly && (
      <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
        {showPreview && (
          <Button onClick={onPreview} color="secondary">
            Preview
          </Button>
        )}
        <Button onClick={onReset} color="secondary">
          Cancel
        </Button>
        <Button onClick={onSave} color="primary">
          Save
        </Button>
      </CardActions>
    )}
    {readOnly && (
      <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
        {hasPreview === true && (
          <Button onClick={onPreview} color="secondary">
            Preview
          </Button>
        )}
        <Button onClick={onViewInLibrary} color="secondary">
          View in Library
        </Button>
      </CardActions>
    )}
  </Card>
)

export default FormTemplateNonDialog
