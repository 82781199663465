import { useState } from "react"
import {
  Accordion as MUIAccordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Link,
  Typography
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

import NarrativeDAO from "daos/narrativeDAO"

const useStyles = makeStyles(() => ({
  cloneHeading: {
    fontSize: "15px",
    marginTop: 0,
    marginBottom: 0,
    fontWeight: "bolder"
  },
  cloneDetails: {
    margin: "0px 0px",
    flexDirection: "column"
  }
}))

const ParagraphRelatedParagraphs = ({ paragraph_Id }) => {
  const [clonedInfo, setClonedInfo] = useState([])

  const loadClonedFrom = async () => {
    if (!paragraph_Id) {
      return
    }

    const content = await NarrativeDAO.getParagraph(paragraph_Id)
    if (!content) {
      return
    }

    const clonedInfo = []

    if (content.clonedFrom_Id) {
      const obj = {
        relationship: "Cloned From",
        paragraph_Id: content.clonedFrom_Id,
        narrative_Id: content.clonedFrom.narrative_Id
      }
      clonedInfo.push(obj)
    }

    if (content.clones) {
      content.clones.forEach(clone => {
        const obj = {
          relationship: "Cloned To",
          paragraph_Id: clone.id,
          narrative_Id: clone.narrative_Id
        }
        clonedInfo.push(obj)
      })
    }

    setClonedInfo(clonedInfo)
  }

  const classes = useStyles()

  return (
    <Grid item xs={12}>
      <MUIAccordion
        onChange={(event, expanded) => {
          if (expanded) {
            loadClonedFrom()
          }
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.cloneHeading}>
            Related Paragraph Info
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.cloneDetails}>
          <p>
            Cloned From:{" "}
            {clonedInfo
              ?.filter(obj => obj.relationship === "Cloned From")
              .map((clone, index, arr) => (
                <div key={`clone-${clone.narrative_Id}-${clone.paragraph_Id}`}>
                  <Link
                    style={{
                      color: "#144D69",
                      textDecoration: "underline"
                    }}
                    to={`/portal/narrative/${clone.narrative_Id}/edit?view=paragraphid-${clone.paragraph_Id}`}
                  >
                    {clone.paragraph_Id}
                  </Link>
                  {arr.length - 1 === index ? "" : ", "}
                </div>
              ))}
          </p>
          <p>
            Cloned To:{" "}
            {clonedInfo &&
              clonedInfo
                .filter(obj => obj.relationship === "Cloned To")
                .map((clone, index, arr) => (
                  <>
                    <Link
                      style={{
                        color: "#144D69",
                        textDecoration: "underline"
                      }}
                      to={`/portal/narrative/${clone.narrative_Id}/edit?view=paragraphid-${clone.paragraph_Id}`}
                    >
                      {clone.paragraph_Id}
                    </Link>
                    {arr.length - 1 === index ? "" : ", "}
                  </>
                ))}
          </p>
        </AccordionDetails>
      </MUIAccordion>
    </Grid>
  )
}

export default ParagraphRelatedParagraphs
