import { globalSettings } from "variables/general"
import { get, put } from "../tools/request"

export default class AffiliateDAO {
  static getShortCodesByAffiiateId = async affiliateId => {
    const res = await get(
      `${globalSettings.monetizationv2BaseUrl}/api/affiliate/shortCode/${affiliateId}`
    )
    return await res.json()
  }

  static saveNewAffiliate = async body => {
    const res = await put(
      `${globalSettings.monetizationv2BaseUrl}/api/affiliate`,
      null,
      body
    )
    return await res.json()
  }

  static updateAffiliate = async (body, id) => {
    const res = await put(
      `${globalSettings.monetizationv2BaseUrl}/api/affiliate/${id}`,
      null,
      body
    )
    return await res.json()
  }

  static getAffiliatesByOrgId = async organizationId => {
    const res = await get(
      `${globalSettings.monetizationv2BaseUrl}/api/affiliate?organizationIds=${organizationId}`
    )
    return await res.json()
  }

  static getAffiliate = async () => {
    const res = await get(
      `${globalSettings.monetizationv2BaseUrl}/api/affiliate/`
    )
    return await res.json()
  }

  static getAffiliateOrganizations = async () => {
    const res = await get(
      `${globalSettings.monetizationv2BaseUrl}/api/affiliate/organization`
    )
    return await res.json()
  }

  static getAffiliateContract = async () => {
    const res = await get(
      `${globalSettings.monetizationv2BaseUrl}/api/affiliate/contract`
    )
    return await res.json()
  }

  static updateAffiliateContract = async (body, id) => {
    const res = await put(
      `${globalSettings.monetizationv2BaseUrl}/api/affiliate/contract/${id}`,
      null,
      body
    )
    return await res.json()
  }

  static saveNewAffiliateContract = async body => {
    const res = await put(
      `${globalSettings.monetizationv2BaseUrl}/api/affiliate/contract/`,
      null,
      body
    )
    return await res.json()
  }

  static updateAffiliateOrganization = async (body, id) => {
    const res = await put(
      `${globalSettings.monetizationv2BaseUrl}/api/affiliate/organization/${id}`,
      null,
      body
    )
    return await res.json()
  }

  static saveNewAffiliateOrganization = async body => {
    const res = await put(
      `${globalSettings.monetizationv2BaseUrl}/api/affiliate/organization/`,
      null,
      body
    )
    return await res.json()
  }
  static getAvailableShortCodes = async linkId => {
    const res = await get(
      `${globalSettings.monetizationv2BaseUrl}/api/affiliate/availableshortcodes?linkId=${linkId}`
    )
    return await res.json()
  }

  static getRandomShortCode = async linkId => {
    const res = await get(
      `${globalSettings.monetizationv2BaseUrl}/api/affiliate/availableshortcodes?linkId=${linkId}&israndom=true`
    )
    return await res.json()
  }

  static getShortCodeByAffiliate = async id => {
    const res = await get(
      `${globalSettings.monetizationv2BaseUrl}/api/affiliate/shortcode/byaffiliate/${id}`
    )
    return await res.json()
  }

  static updateShortCodeById = async (body, id) => {
    const res = await put(
      `${globalSettings.monetizationv2BaseUrl}/api/affiliate/shortcode/${id}`,
      null,
      body
    )
    return await res.json()
  }

  static saveNewShortCode = async body => {
    const res = await put(
      `${globalSettings.monetizationv2BaseUrl}/api/affiliate/shortcode/`,
      null,
      body
    )
    return await res.json()
  }
}
