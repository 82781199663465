// eslint-disable-next-line no-unused-vars
import React, { Component } from "react"
import PropTypes from "prop-types"
import ReactTable from "react-table-6"
import withStyles from "@mui/styles/withStyles"
import Assignment from "@mui/icons-material/Assignment"
import { Visibility } from "@mui/icons-material"
import { Input } from "reactstrap"
import Grid from "@mui/material/Grid"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon"
import CardHeader from "components/Card/CardHeader"
import { cardTitle } from "assets/jss/material-dashboard-pro-react"
import TimeAgo from "react-timeago"
import matchSorter from "match-sorter"
import Button from "components/CustomButtons/Button"
import Tooltip from "@mui/material/Tooltip"
import Helpers from "tools/Helpers.js"
import { Cookies } from "tools/storage"
import { observer, inject } from "mobx-react"
import SportsDAO from "daos/sportsDAO"

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
}

const useStore = component =>
  inject(({ store }) => ({
    retrieveLeagues: store.sportsStore.retrieveLeagues,
    leagues: store.sportsStore.leagues
  }))(observer(component))

const cookies = new Cookies()

class MatchManager extends Component {
  constructor(props) {
    super(props)
    const initialLeagueId =
      props.leagueId || cookies.get("matchManagerLeagueId") || null
    this.state = {
      displayDeleteConfirmation: false,
      displayShareNotYetImplemented: false,
      leagueId: initialLeagueId,
      seasonYearId: cookies.get("matchManagerSeasonYearId") || null,
      seasons: [],
      matches: [],
      selectedMatchId: props.selectedMatchId,
      selectedIds: [],
      isMatchesLoading: false
    }
    this.selectMatch = this.selectMatch.bind(this)
    this.onChangeSeason = this.onChangeSeason.bind(this)
    this.onChangeLeague = this.onChangeLeague.bind(this)
  }

  async componentDidMount() {
    if (!this.props.leagues?.length) {
      this.props.retrieveLeagues()
    }
    if (this.state.leagueId) {
      await this.loadSeasonsAndMatches(this.state.leagueId)
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    // The narrative takes forever to load, so sometimes we will arrive at
    // this page before we have the league id from props
    if (!prevState.leagueId && this.state.leagueId) {
      await this.loadSeasonsAndMatches(this.state.leagueId)
    }
  }

  get embedded() {
    return this.props.embedded || false
  }

  onChangeSeason = e => {
    const newSeasonYearId = e.target.value

    let cookies = new Cookies()
    cookies.set("matchManagerSeasonYearId", newSeasonYearId, {
      path: "/",
      expires: Helpers.CookieExpiration.OneMonth
    })

    this.loadMatches(newSeasonYearId)
    this.setState({ seasonYearId: newSeasonYearId })
  }

  onChangeLeague = e => {
    const newLeagueId = e.target.value
    let cookies = new Cookies()
    cookies.set("matchManagerLeagueId", newLeagueId, {
      path: "/",
      expires: Helpers.CookieExpiration.OneMonth
    })

    this.loadSeasonsAndMatches(newLeagueId)
    this.setState({ leagueId: newLeagueId })
  }

  loadSeasonsAndMatches = async leagueId => {
    const seasonYearId = await this.loadSeasons(leagueId)
    await this.loadMatches(seasonYearId)
  }

  loadSeasons = async leagueId => {
    const { content } = await SportsDAO.getSeasons(leagueId)
    if (content) {
      const { seasonYears = [] } = content
      const seasonYearId = seasonYears?.[0]?.id
      this.setState({
        seasons: seasonYears,
        seasonYearId
      })
      return seasonYearId
    }
  }

  loadMatches = async seasonYearId => {
    if (!seasonYearId) {
      return
    }
    this.setState({ isMatchesLoading: true })
    const matches = await SportsDAO.getMatches(seasonYearId)
    this.setState({
      matches: matches.content.events,
      isMatchesLoading: false
    })
  }

  selectMatch(row) {
    if (row.original.id !== this.state.selected) {
      this.setState(
        {
          selectedMatchId: row.original.id
        },
        this.props.onChange(row)
      )
    }
  }

  addOrRemoveId = id => {
    let ids = this.state.selectedIds
    if (ids.includes(id)) {
      ids = ids.filter(value => value !== id)
    } else {
      ids.push(id)
    }
    this.setState({ selectedIds: ids })
    this.props.onMultiSelect(ids)
  }

  renderStatus(cell) {
    let statusName

    switch (cell.original.matchStatus_Id) {
      case 1:
        statusName = "In Progress"
        break
      case 2:
        statusName = "Completed"
        break
      case 3:
        statusName = "Postponed"
        break
      case 4:
        statusName = "Cancelled"
        break
      case 5:
        statusName = "Scheduled"
        break
      case 6:
        statusName = "Unnecessary"
        break
      case 7:
        statusName = "Forfeit"
        break
      case 8:
        statusName = "Unknown"
        break
      case 9:
        statusName = "Error"
        break
      case 10:
        statusName = "If Necessary"
        break
      default:
        statusName = "Unknown"
        break
    }
    return <div>{statusName}</div>
  }

  render() {
    const { leagueId, seasonYearId, seasons, matches, isMatchesLoading } =
      this.state

    const { leagues } = this.props

    const leagueChoices = leagues?.map(league => (
      <option key={league.id} value={league.id}>
        {league.name}
      </option>
    ))

    const seasonChoices = seasons?.map(seasonYear => (
      <option key={seasonYear.id} value={seasonYear.id}>
        {seasonYear.name}
      </option>
    ))

    const cardBody = (
      <div>
        <ReactTable
          data={matches}
          minRows={1}
          columns={[
            {
              Header: "Actions",
              Cell: row => (
                <div className="actions-left">
                  <Tooltip
                    title="Preview using this competition"
                    placement="top"
                  >
                    <Button
                      justIcon
                      round
                      simple
                      color="info"
                      className="info"
                      onClick={() => this.selectMatch(row)}
                    >
                      <Visibility />
                    </Button>
                  </Tooltip>
                </div>
              ),
              sortable: false,
              filterable: false,
              width: 70
            },
            {
              Header: "Id",
              accessor: "id",
              Cell: cell => <div>{cell.value}</div>,
              width: 80
            },
            {
              Header: "Status",
              accessor: "matchStatus_Id",
              Cell: cell => this.renderStatus(cell),
              width: 80
            },
            {
              Header: "Name",
              accessor: "name",
              Cell: cell => <div>{cell.value}</div>,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, {
                  keys: ["name"],
                  threshold: matchSorter.rankings.CONTAINS
                }),
              filterAll: true,
              width: 300
            },
            {
              Header: "Scheduled",
              accessor: "scheduledStartDateTime",
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true
                }
                let now = new Date()
                let futureTime = new Date()
                let pastTime = new Date()
                let scheduledStartDateTime = new Date(
                  row.scheduledStartDateTime
                )
                if (filter.value === "future") {
                  return scheduledStartDateTime >= now
                }
                if (filter.value === "past") {
                  return scheduledStartDateTime < now
                }
                if (filter.value === "next7Days") {
                  return (
                    scheduledStartDateTime > now &&
                    scheduledStartDateTime <
                      futureTime.setHours(futureTime.getHours() + 7 * 24)
                  )
                }
                if (filter.value === "next24hours") {
                  return (
                    scheduledStartDateTime > now &&
                    scheduledStartDateTime <
                      futureTime.setHours(futureTime.getHours() + 48)
                  )
                }
                if (filter.value === "next1hour") {
                  return (
                    scheduledStartDateTime > now &&
                    scheduledStartDateTime <
                      futureTime.setHours(futureTime.getHours() + 2)
                  )
                }
                if (filter.value === "within7Days") {
                  return (
                    scheduledStartDateTime >
                      pastTime.setHours(pastTime.getHours() - 7 * 24) &&
                    scheduledStartDateTime <
                      futureTime.setHours(futureTime.getHours() + 7 * 24)
                  )
                }
                if (filter.value === "within24hours") {
                  return (
                    scheduledStartDateTime >
                      pastTime.setHours(pastTime.getHours() - 24) &&
                    scheduledStartDateTime <
                      futureTime.setHours(futureTime.getHours() + 48)
                  )
                }
                if (filter.value === "within1hour") {
                  return (
                    scheduledStartDateTime >
                      pastTime.setHours(pastTime.getHours() - 1) &&
                    scheduledStartDateTime <
                      futureTime.setHours(futureTime.getHours() + 2)
                  )
                }
                if (filter.value === "past7Days") {
                  return (
                    scheduledStartDateTime >
                      pastTime.setHours(pastTime.getHours() - 7 * 24) &&
                    scheduledStartDateTime < now
                  )
                }
                if (filter.value === "past24hours") {
                  return (
                    scheduledStartDateTime >
                      pastTime.setHours(pastTime.getHours() - 24) &&
                    scheduledStartDateTime < now
                  )
                }
                if (filter.value === "past1hour") {
                  return (
                    scheduledStartDateTime >
                      pastTime.setHours(pastTime.getHours() - 1) &&
                    scheduledStartDateTime < now
                  )
                }
              },
              Filter: ({ filter, onChange }) => (
                <select
                  onChange={event => onChange(event.target.value)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                >
                  <option value="all">Show All</option>
                  <option value="future">Future</option>
                  <option value="past">Past</option>
                  <option value="next7Days">Next 7 Days</option>
                  <option value="next24hours">Next 24 Hours</option>
                  <option value="next1hour">Next 1 Hour</option>
                  <option value="within7Days">Within 7 Days</option>
                  <option value="within24hours">Within 24 Hours</option>
                  <option value="within1hour">Within 1 Hour</option>
                  <option value="past7Days">Past 7 Days</option>
                  <option value="past24hours">Past 24 Hours</option>
                  <option value="past1hour">Past 1 Hour</option>
                </select>
              ),
              Cell: cell => (
                <div>
                  <TimeAgo
                    date={cell.value}
                    title={Helpers.prettyDateTimeinPacificTimeZone(cell.value)}
                  />
                </div>
              ),
              width: 200
            }
          ]}
          filterable={true}
          defaultPageSize={25}
          showPaginationTop={true}
          showPaginationBottom={true}
          className="-striped -highlight"
          defaultSorted={[
            {
              id: "scheduledStartDateTime",
              desc: true
            }
          ]}
          style={{
            height: "1000px" // This will force the table body to overflow and scroll, since there is not enough room
          }}
          loading={isMatchesLoading}
        >
          {(state, makeTable) => {
            let recordsInfoText = ""
            const { filtered, pageRows, pageSize, sortedData, page } = state

            if (sortedData && sortedData.length > 0) {
              let isFiltered = filtered.length > 0
              let totalRecords = sortedData.length
              let recordsCountFrom = page * pageSize + 1
              let recordsCountTo = recordsCountFrom + pageRows.length - 1

              if (isFiltered) {
                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`
              } else {
                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`
              }
            } else {
              recordsInfoText = this.state.isLoading
                ? "Loading matches, please wait"
                : "No files"
            }
            return (
              <div className="main-grid">
                <div className="above-table text-right">
                  <div className="col-sm-12">
                    <span className="records-info">{recordsInfoText}</span>
                  </div>
                </div>

                {makeTable()}
              </div>
            )
          }}
        </ReactTable>
      </div>
    )

    const toolBar = (
      <Grid container style={{ alignItems: "center" }}>
        <Grid item xs={6} style={{ paddingRight: "8px" }}>
          <span
            style={{
              fontWeight: "bold",
              fontSize: "16px",
              display: "block",
              whiteSpace: "nowrap",
              marginRight: "10px"
            }}
          >
            Filter by League
          </span>
          <Input
            type="select"
            style={{ display: "block" }}
            value={leagueId || ""}
            onChange={this.onChangeLeague}
          >
            <option value="">All</option>
            {leagueChoices}
          </Input>
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: "8px" }}>
          <span
            style={{
              fontWeight: "bold",
              fontSize: "16px",
              display: "block",
              whiteSpace: "nowrap",
              marginRight: "10px"
            }}
          >
            Filter by Season Year
          </span>
          <Input
            type="select"
            style={{ display: "block" }}
            value={seasonYearId || ""}
            onChange={this.onChangeSeason}
          >
            <option value="">All</option>
            {seasonChoices}
          </Input>
        </Grid>
      </Grid>
    )
    return this.embedded ? (
      <div>
        {toolBar}
        {cardBody}
      </div>
    ) : (
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <Assignment />
          </CardIcon>
          {toolBar}
        </CardHeader>
        <CardBody>{cardBody}</CardBody>
      </Card>
    )
  }
}

MatchManager.propTypes = {
  onChange: PropTypes.func,
  onMultiSelect: PropTypes.func,
  selectedMatchId: PropTypes.number,
  leagueId: PropTypes.number,
  embedded: PropTypes.bool
}
export default withStyles(styles)(useStore(MatchManager))
