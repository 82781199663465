import React, { useState } from "react"
import makeStyles from "@mui/styles/makeStyles"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import narrativeDashboardStyles from "assets/jss/material-dashboard-pro-react/views/narrativeDashboardStyles"
import Card from "components/Card/Card"

const useStyles = makeStyles(() => ({
  ...narrativeDashboardStyles,
  root: {
    position: "relative"
  }
}))

const NarrativeTags = props => {
  const classes = useStyles()
  const { name, tags, onChange } = props

  const [tagBlocks, setTagBlocks] = useState([])
  const [isValidTagJSON, setIsValidTagJSON] = useState(true)

  const sampleTag = { text: "default", slug: "default", description: "default" }

  React.useEffect(() => {
    try {
      let tmpBlocks = tags && JSON.parse(tags)
      if (!tags) {
        tmpBlocks = [sampleTag]
      }
      setTagBlocks(tmpBlocks)
      let newEvent = {
        target: {
          id: name,
          value: JSON.stringify(tmpBlocks)
        }
      }
      onChange && onChange(newEvent)
      setIsValidTagJSON(true)
    } catch (e) {
      setIsValidTagJSON(false)
    }
  }, [tags])

  const handleInputChange = (index, event) => {
    const tmpBlocks = tagBlocks
    tmpBlocks[index][event.target.name] = event.target.value

    setTagBlocks(tmpBlocks)
    let newEvent = {
      target: {
        id: name,
        value: JSON.stringify(tmpBlocks)
      }
    }
    onChange && onChange(newEvent)
  }

  const handleAddFields = () => {
    const tmpBlocks = [...tagBlocks, sampleTag]
    setTagBlocks(tmpBlocks)
    let newEvent = {
      target: {
        id: name,
        value: JSON.stringify(tmpBlocks)
      }
    }
    onChange && onChange(newEvent)
  }

  const handleRemoveFields = index => {
    if (tagBlocks.length > 1) {
      const tmpBlocks = tagBlocks
      tmpBlocks.splice(index, 1)
      setTagBlocks(tmpBlocks)
      let newEvent = {
        target: {
          id: name,
          value: JSON.stringify(tmpBlocks)
        }
      }
      onChange && onChange(newEvent)
    }
  }

  const sampleJSON = `[{"text": "default", "slug": "default", "description": "default"}, {"text": "default", "slug": "default", "description": "default"}]`

  return (
    <Card className={classes.root} style={{ marginTop: "5px" }}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <h4>Tag Editor</h4>
          {tagBlocks.length === 0 && (
            <>
              <div style={{ color: "red" }}>Invalid Tag Editor JSON</div>
              <div>
                example:
                <br /> {sampleJSON}
              </div>
            </>
          )}
          {tagBlocks &&
            tagBlocks.map((tag, index) => (
              <React.Fragment key={`${index}`}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <label htmlFor="label">text</label>
                    <input
                      type="text"
                      className="form-control"
                      id="text"
                      name="text"
                      value={tag.text}
                      onChange={event => handleInputChange(index, event)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <label htmlFor="term">slug</label>
                    <input
                      type="text"
                      className="form-control"
                      id="slug"
                      name="slug"
                      value={tag.slug}
                      onChange={event => handleInputChange(index, event)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <label htmlFor="term">description</label>
                    <input
                      type="text"
                      className="form-control"
                      id="description"
                      name="description"
                      value={tag.description}
                      onChange={event => handleInputChange(index, event)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <button
                      className="btn btn-link"
                      type="button"
                      onClick={() => handleRemoveFields(index)}
                    >
                      -
                    </button>
                    <button
                      className="btn btn-link"
                      type="button"
                      onClick={() => handleAddFields(index)}
                    >
                      +
                    </button>
                  </GridItem>
                </GridContainer>
              </React.Fragment>
            ))}
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <h4>Preview</h4>
          {!isValidTagJSON && (
            <div style={{ color: "red" }}>Unable to parse JSON into tags.</div>
          )}
          {isValidTagJSON && tagBlocks.length > 0 && (
            <pre>{JSON.stringify(tagBlocks, null, 2)}</pre>
          )}
          {isValidTagJSON && tagBlocks.length === 0 && (
            <>
              <div style={{ color: "red" }}>Invalid Tag Editor JSON.</div>
              <div>{tags}</div>
            </>
          )}
        </GridItem>
      </GridContainer>
    </Card>
  )
}

export default NarrativeTags
