import { List, Map, fromJS } from "immutable"
import { action, reaction, observable, computed, decorate } from "mobx"
import SnippetDao from "../daos/snippetDAO"

export default class SnippetStore {
  rootStore
  snippetId
  snippet
  snippets
  isAddSnippetOpen
  loading
  loadingSnippet
  upsertedSnippet

  constructor(rootStore) {
    this.rootStore = rootStore
    this.dehydrate()
  }

  /**
   * Computeds
   */
  get isSnippetSelected() {
    return !!this.snippetId
  }

  get showUpsertConfirmation() {
    return !!this.upsertedSnippet.get("id")
  }

  get upsertedSnippetKey() {
    return `${this.upsertedSnippet.get("key", "")} ${this.upsertedSnippet.get(
      "description",
      ""
    )}`
  }

  /**
   * Methods
   */

  postSnippet = async snippet => {
    try {
      this.setLoading(true)

      const newSnippet = fromJS(await SnippetDao.postSnippet(snippet))

      this.updateSnippets(newSnippet.get("content", Map()))

      this.setUpsertedSnippet(newSnippet.get("content", Map()))
    } catch (err) {
      console.error("Error: ", err)
      throw err
    } finally {
      this.setLoading(false)
    }
  }
  getSnippets = async () => {
    try {
      this.setLoading(true)

      const snippets = fromJS(await SnippetDao.getSnippets())

      this.setSnippets(snippets.get("content", List()))
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoading(false)
    }
  }

  closeAddSnippet = () => {
    this.dehydrateSnippet()
  }

  openAddSnippet = (id = null) => {
    this.setSnippetId(id)
    this.setIsAddSnippetOpen(true)
  }

  /**
   * Reactions
   */
  getSnippet = reaction(
    () => this.snippetId,
    async id => {
      try {
        if (!id) {
          return
        }

        this.setLoading(true)
        this.setLoadingSnippet(true)

        const apiSnippet = fromJS(await SnippetDao.getSnippet(id))

        if (!apiSnippet) {
          return
        }

        const snippet = apiSnippet.get("content")

        this.setSnippet(snippet)
      } catch (err) {
        console.error("Error: ", err)
      } finally {
        this.setLoading(false)
        this.setLoadingSnippet(false)
      }
    }
  )

  /**
   * Internal Actions
   */
  dehydrate() {
    this.dehydrateSnippet()
    this.setSnippets(List())
    this.setLoading(false)
    this.setLoadingSnippet(false)
    this.setIsAddSnippetOpen(false)
  }

  dehydrateSnippet() {
    this.setSnippet(Map())
    this.setUpsertedSnippet(Map())
    this.setSnippetId(null)
    this.setIsAddSnippetOpen(false)
  }

  setSnippetId(value) {
    if (!value) {
      this.setSnippet(Map())
    }
    this.snippetId = value
  }

  setSnippet(value) {
    this.snippet = value
  }

  setUpsertedSnippet(value) {
    this.upsertedSnippet = value
  }

  setSnippets(value) {
    this.snippets = value
  }

  setIsAddSnippetOpen(value) {
    this.isAddSnippetOpen = value
  }

  setPermissionGroups(value) {
    this.permissionGroups = value
  }

  setLoading(value) {
    this.loading = value
  }

  setLoadingSnippet(value) {
    this.loadingSnippet = value
  }

  updateSnippets(snippet) {
    const snippetIdx = this.snippets.findIndex(
      a => a.get("id") === snippet.get("id")
    )

    // update the snippet in state
    if (snippetIdx !== -1) {
      this.setSnippets(this.snippets.set(snippetIdx, snippet))
    } else {
      this.setSnippets(this.snippets.push(snippet))
    }
  }
}

/**
 * object decorators
 */
decorate(SnippetStore, {
  //computeds
  isSnippetSelected: computed,
  showUpsertConfirmation: computed,

  // observables
  snippet: observable,
  snippets: observable,
  snippetId: observable,
  loading: observable,
  loadingSnippet: observable,
  isAddSnippetOpen: observable,
  upsertedSnippet: observable,

  // actions
  setSnippetId: action.bound,
  setSnippet: action.bound,
  setSnippets: action.bound,
  setIsAddSnippetOpen: action.bound,
  setLoading: action.bound,
  setLoadingSnippet: action.bound,
  setUpsertedSnippet: action.bound
})
