import React, { Component } from "react"
import ReactTable from "react-table-6"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import withStyles from "@mui/styles/withStyles"
import {
  Edit,
  CloudDownload,
  Visibility,
  Share,
  Delete,
  FileCopy,
  DomainDisabled,
  Loop
} from "@mui/icons-material"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import matchSorter from "match-sorter"
import Button from "components/CustomButtons/Button.jsx"
import Tooltip from "@mui/material/Tooltip"
import FileUpload from "../FileUpload/FileUpload"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogActions from "@mui/material/DialogActions"
import { TextField, Checkbox } from "@mui/material"
import Typography from "@mui/material/Typography"
import { globalSettings } from "variables/general.jsx"
import { CopyToClipboard } from "react-copy-to-clipboard"
import Slider from "@mui/material/Slider"
import Input from "@mui/material/Input"
import Grid from "@mui/material/Grid"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import Check from "@mui/icons-material/Check"
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle"
import Helpers from "tools/Helpers.js"
import CircularProgress from "@mui/material/CircularProgress"
import Enums from "tools/Enums.js"
import { observer, inject } from "mobx-react"
import Switch from "@mui/material/Switch"
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete"
import { get, put } from "tools/request"
import Box from "@mui/material/Box"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import { Cookies } from "tools/storage"

const useStore = component =>
  inject(({ store }) => ({
    orgAndPropId: store.organizationStore.orgAndPropId,
    accountPermissions: store.accountStore.accountPermissions,
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization,
    retrieveLeagues: store.sportsStore.retrieveLeagues,
    sortedLeagues: store.sportsStore.sortedLeaguesByName,
    associateAssetAndLeague: store.assetStore.associateAssetAndLeague,
    dissociateAssetAndLeague: store.assetStore.dissociateAssetAndLeague,
    dissociateAllLeaguesFromAsset:
      store.assetStore.dissociateAllLeaguesFromAsset,
    retrieveAssets: store.assetStore.retrieveAssets,
    isLoading: store.assetStore.isLoading,
    assets: store.assetStore.assets,
    assetCount: store.assetStore.assetCount,
    retrieveAssetCount: store.assetStore.retrieveAssetCount,
    updateAssetIsAvailableForGraphicsTool:
      store.assetStore.updateAssetIsAvailableForGraphicsTool,
    deleteAsset: store.assetStore.deleteAsset,
    narratives: store.organizationStore.narratives,
    getNarratives: store.organizationStore.getNarratives
  }))(observer(component))

const cookies = new Cookies()
cookies.set("currentAssentCount", 0)
class AssetManager extends Component {
  constructor(props) {
    super(props)
    this.props = props

    this.state = {
      assetToken: "",
      assetMIMEType: "",
      assetMIMETypeExtension: "",
      isCopyToClipboardButtonDisabled: true,
      isDeleteConfirmationDisplayed: false,
      isShareDialogDisplayed: false,
      isPreviewDialogDisplayed: false,
      isFeedEntryDialogDisplayed: false,
      isSaveStoryButtonDisabled: false,
      errorMessages: "",
      isFirstRowExpanded: false,
      fileName: "",
      feedEntry: "",
      feedEntryTitle: "",
      feedEntryContent: "",
      feedEntryResponse: "",
      feedEntryResponseCode: "",
      feedEntryResponseMessage: "",
      feedId: "",
      feedInfo: "",
      hasFeedEntry: false,
      liveFeedUrl: "",
      selectedAssetId: null,
      narrativeId: "",
      narrativeName: "",
      assetLimit: 50,
      assetTotalCount: 0,
      productionFeedId: "",
      showErrorMessageDialog: false,
      copySuccessText: "Copy URL",
      baseUrlToShare: "",
      urlToShare: "",
      isSharingImage: false,
      imageShareWidth: 500,
      imageShareMaxWidth: 1000,
      imageShareHeight: "",
      imageShareMaxHeight: 1000,
      imageShareQuality: "",
      imageShareFormat: "original",
      isImageToBeCropped: false,
      copyButtonText: "Copy To Clipboard",
      isShowHTML: false,
      orgAndPropId: this.props.orgAndPropId
    }
    this.handleFileUploadComplete = this.handleFileUploadComplete.bind(this)
    this.assetIdToDelete = -1
    this.assetFilenameToDelete = ""
    this.isSharingImage = false
    this.selectAsset = this.selectAsset.bind(this)
    this.contentRef = React.createRef()
  }

  componentDidMount() {
    this.props.setShowChangeCurrentOrganization(true)
    if (this.props.assets && this.props.assets.length === 0) {
      this.props.retrieveAssets(this.props.orgAndPropId, this.state.assetLimit)
    }
    this.props.retrieveLeagues()
  }

  componentDidUpdate() {
    if (this.props.assetCount > 0) {
      let assetTotalCount = cookies.get("currentAssentCount")
      if (Number(assetTotalCount) !== Number(this.props.assetCount)) {
        // console.log(
        //   `Update, assets=${this.props.assetCount}, from ${assetTotalCount}`
        // )
        cookies.set("currentAssentCount", this.props.assetCount)
        if (this.props.assetCount < 50) {
          this.setState({
            assetTotalCount: this.props.assetCount,
            assetLimit: this.props.assetCount
          })
        } else {
          this.setState({
            assetTotalCount: this.props.assetCount
          })
        }
      }
    }
    if (this.state.orgAndPropId !== this.props.orgAndPropId) {
      this.setState(
        {
          assetLimit: 50,
          orgAndPropId: this.props.orgAndPropId,
          retrieveAssetCount: true
        },
        () => {
          this.props.retrieveAssets(
            this.props.orgAndPropId,
            this.state.assetLimit
          )
          this.props.getNarratives(this.state.orgAndPropId)
          this.props.retrieveAssetCount(this.state.orgAndPropId)
        }
      )
    }
  }

  onChangeAssetLimit = event => {
    this.setState({ assetLimit: event.target.value }, () =>
      this.props.retrieveAssets(this.props.orgAndPropId, this.state.assetLimit)
    )
  }

  onSaveButtonClick = () => {
    this.updateAsset()
  }

  renderTotalCountOptions() {
    let steppingPoints = [
      25, 50, 75, 100, 150, 200, 250, 350, 400, 500, 750, 1000, 1250, 1500,
      2000, 2500, 3000, 3500, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 15000,
      20000, 25000, 30000, 35000, 40000, 45000, 50000
    ]
    let assetCountLimitChoices = []
    steppingPoints.forEach(i => {
      if (i < this.state.assetTotalCount) {
        assetCountLimitChoices.push(i)
      }
    })
    assetCountLimitChoices.push(this.state.assetTotalCount)
    if (
      parseInt(this.state.assetLimit) > parseInt(this.state.assetTotalCount)
    ) {
      this.setState({
        assetLimit: this.state.assetTotalCount
      })
    }
    if (this.state.assetTotalCount < 50) {
      return this.state.assetTotalCount
    } else {
      return (
        <select
          onChange={this.onChangeAssetLimit}
          style={{ width: "100" }}
          value={this.state.assetLimit}
        >
          {assetCountLimitChoices.map(o => (
            <option value={o}>{o}</option>
          ))}
        </select>
      )
    }
  }

  displayDeleteConfirmation(assetId, assetFilename) {
    this.assetIdToDelete = assetId
    this.assetFilenameToDelete = assetFilename
    this.setState({ isDeleteConfirmationDisplayed: true })
  }

  displayShareDialog(baseUrl, imageMaxWidth, imageMaxHeight) {
    if (this.isSharingImage) {
      let imageUrlToShare = this.buildImageShareUrl()
      this.setState(
        {
          urlToShare: imageUrlToShare,
          imageShareMaxWidth: imageMaxWidth,
          imageShareMaxHeight: imageMaxHeight
        },
        () => this.setState({ isShareDialogDisplayed: true })
      )
    } else {
      this.setState({ urlToShare: baseUrl }, () =>
        this.setState({ isShareDialogDisplayed: true })
      )
    }
  }

  buildImageShareUrl() {
    let fileExtension = this.state.assetMIMETypeExtension
    let imageUrlToShare = ""
    if (this.state.imageShareFormat !== "original") {
      fileExtension = this.state.imageShareFormat
    }
    if (fileExtension !== "") {
      imageUrlToShare = `${this.state.baseUrlToShare}.${fileExtension}?`
    } else {
      imageUrlToShare = `${this.state.baseUrlToShare}?`
    }
    if (this.state.imageShareWidth > 0) {
      imageUrlToShare += `&w=${this.state.imageShareWidth}`
    }
    if (this.state.imageShareHeight > 0) {
      imageUrlToShare += `&h=${this.state.imageShareHeight}`
    }
    if (
      (this.state.imageShareFormat === "jpg" ||
        (this.state.imageShareFormat === "original" &&
          this.state.assetMIMEType === "image/jpeg")) &&
      this.state.imageShareQuality > 0
    ) {
      imageUrlToShare += `&q=${this.state.imageShareQuality}`
    }
    // cleanup url
    imageUrlToShare = imageUrlToShare.replace("?&", "?")
    this.setState({ copySuccessText: "Copy URL" })
    return imageUrlToShare
  }

  onImageWidthChange(width) {
    // Only when cropping is enabled, can both height and width be changed at the same time
    if (!this.state.isImageToBeCropped) {
      this.setState({ imageShareHeight: "" })
    }
    this.setState({ imageShareWidth: width }, () =>
      this.setState({
        urlToShare: this.buildImageShareUrl()
      })
    )
  }

  onImageHeightChange(height) {
    // Only when cropping is enabled, can both height and width be changed at the same time
    if (!this.state.isImageToBeCropped) {
      this.setState({ imageShareWidth: "" })
    }
    this.setState({ imageShareHeight: height }, () =>
      this.setState({
        urlToShare: this.buildImageShareUrl()
      })
    )
  }

  onImageQualityChange(quality) {
    this.setState({ imageShareQuality: quality }, () =>
      this.setState({
        urlToShare: this.buildImageShareUrl()
      })
    )
  }

  onImageFormatChange(format) {
    this.setState({ imageShareFormat: format }, () =>
      this.setState({
        urlToShare: this.buildImageShareUrl()
      })
    )
  }

  onImageCropCheckBoxChanged = () => {
    this.setState({ isImageToBeCropped: !this.state.isImageToBeCropped }, () =>
      this.setState({
        urlToShare: this.buildImageShareUrl()
      })
    )
  }

  onShareButtonClicked(cell) {
    let baseUrlToShare = `${globalSettings.cdnBaseUrl}/api/asset/${cell.original.assetToken}`
    this.setState(
      {
        baseUrlToShare,
        assetMIMEType: cell.original.mimeType,
        assetMIMETypeExtension: cell.original.mimeTypeExtension
      },
      () => {
        if (cell.original.mimeType.indexOf("image/") !== -1) {
          this.isSharingImage = true
          this.displayShareDialog(
            baseUrlToShare,
            cell.original.width,
            cell.original.height
          )
        } else {
          this.isSharingImage = false
          this.displayShareDialog(baseUrlToShare)
        }
      }
    )
  }

  onPreviewClicked(cell, id, filename, assettoken, feedid) {
    this.setState({
      narrativeId: cell.original.id,
      narrativeName: cell.original.name,
      productionFeedId: feedid,
      selectedAssetId: id,
      fileName: filename,
      assetToken: assettoken,
      isCopyToClipboardButtonDisabled: true,
      isSaveStoryButtonDisabled: true,
      isPreviewDialogDisplayed: true,
      errorMessages: "",
      showErrorMessageDialog: false
    })

    this.displayPreviewDialog(id, cell.original.id, false)
  }

  getTextToCopy() {
    let textToCopy = ""
    if (this.state.isShowHTML) {
      if (this.state.feedEntryTitle !== "N/A") {
        textToCopy = `${this.state.feedEntryTitle}\r\n\r\n`
      }
      textToCopy += `${this.state.feedEntryContent}`
    } else {
      if (this.state.feedEntryTitle !== "N/A") {
        textToCopy = Helpers.stripHtml(`${this.state.feedEntryTitle}\r\n\r\n`)
      }
      //retain whitespace
      let textWithBreaks = this.state.feedEntryContent
      textWithBreaks = textWithBreaks.replace(/<br>/gi, "$&\r\n")
      textWithBreaks = textWithBreaks.replace(/<br\/>/gi, "$&\r\n")
      textWithBreaks = textWithBreaks.replace(/<\/p>/gi, "$&\r\n\r\n")
      textToCopy += Helpers.stripHtml(textWithBreaks)
    }
    return textToCopy
  }

  onCopyButtonClick() {
    const { classes } = this.props
    this.setState({
      copyButtonText: (
        <div>
          <Check className={classes.icons} /> Copied To Clipboard
        </div>
      )
    })
  }

  stripHtml(html) {
    // Create a new div element
    let temporalDivElement = document.createElement("div")
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || ""
  }

  publishFeedEntry(feedEntry) {
    let feedGenUrl = `${globalSettings.apiBaseUrl}/api/feed/publish/${feedEntry.id}`

    get(`${feedGenUrl}`)
      .then(Response => Response && Response.ok === true && Response.json())
      .then(feedEntryResponse => {
        this.setState({
          feedEntryResponse: feedEntryResponse,
          isCopyToClipboardButtonDisabled: false,
          isSaveStoryButtonDisabled: true
        })
      })
      .catch(err => {
        console.error(`Error on GET to /api/feed/publish/${feedEntry.id}`, err)
        this.setState({
          feedEntryResponseMessage: err
        })
      })
  }

  downloadFeedEntryAsOpenXMLWordDocument(feedEntryId) {
    let downloadUrl = `${globalSettings.apiBaseUrl}/api/openxml/DownloadFeedEntryAsOpenXMLWordDocument?feedentryid=${feedEntryId}&filename=${this.state.fileName}`
    window.open(downloadUrl, "_blank")
  }

  displayPreviewDialog(contentId, narrativeId) {
    // reset the story preview.
    let feedEntryContent = "Generating story content..."
    let feedEntryTitle = "Generating story title..."
    this.setState({
      feedEntryTitle: feedEntryTitle,
      feedEntryContent: feedEntryContent,
      isCopyToClipboardButtonDisabled: true,
      isSaveStoryButtonDisabled: true
    })
    let previewUrl = `${globalSettings.apiBaseUrl}/api/narrativepreview/feed-entry?create=true`
    const contentType = "asset"
    const contentTypeId = 58 // FileAsset (TODO: enum in JavaScript)

    put(`${previewUrl}`, null, {
      narrativeId,
      contentId,
      contentType,
      contentTypeId,
      debug: false
    })
      .then(Response => {
        if (Response.ok) {
          return Response.json()
        } else {
          throw new Error("Something went wrong")
        }
      })
      .then(feedEntryRes => {
        let feedEntryResponse = feedEntryRes.content
        if (feedEntryResponse) {
          this.setState({
            feedEntry: feedEntryResponse
          })
          if (feedEntryResponse.title) {
            feedEntryTitle = feedEntryResponse.title
          } else {
            feedEntryTitle = "N/A"
          }
          if (feedEntryResponse.content) {
            feedEntryContent = feedEntryResponse.content
          } else {
            feedEntryContent = "Sorry, unable to generate content."
          }
          this.setState({
            feedEntryTitle: feedEntryTitle,
            feedEntryContent: feedEntryContent
          })
          // Status (3) = "Published"
          if (feedEntryResponse.feedEntryStatus === 3) {
            this.setState({
              isCopyToClipboardButtonDisabled: false,
              isSaveStoryButtonDisabled: true
            })
            // Status (1) = "Draft"
          } else if (feedEntryResponse.feedEntryStatus === 1) {
            this.setState({
              isCopyToClipboardButtonDisabled: true,
              isSaveStoryButtonDisabled: false
            })
          } else if (feedEntryResponse.feedEntryStatus === 0) {
            const errors = feedEntryResponse.triggers.map(item => (
              <p>-- {item.value}</p>
            ))

            this.setState({
              showErrorMessageDialog: true,
              errorMessages: errors
            })
          }
        }
      })
      .catch(err => {
        console.error(`Error on PUT to ${previewUrl}`, err)
        this.setState({
          isCopyToClipboardButtonDisabled: true,
          isSaveStoryButtonDisabled: true,
          feedEntryTitle: "Sorry, unable to generate title.",
          feedEntryContent: "Sorry, unable to generate content.",
          showErrorMessageDialog: true
        })
      })
  }

  selectAsset(row) {
    if (row._original.id !== this.state.selected) {
      this.setState(
        {
          selectedMatchId: row._original.id
        },
        this.props.onChange(row)
      )
    }
  }

  handleIsAvailableForGraphicsToolToggle(e, row) {
    let changedAssets = this.props.assets
    changedAssets[row.index].isAvailableForGraphicsTool =
      !changedAssets[row.index].isAvailableForGraphicsTool

    this.setState({
      assets: changedAssets
    })

    this.props.updateAssetIsAvailableForGraphicsTool(
      row.original.id,
      row.original.isAvailableForGraphicsTool
    )
  }

  handleFileUploadComplete() {
    this.props.retrieveAssets(this.props.orgAndPropId, this.state.assetLimit)
    this.setState({ isFirstRowExpanded: true })
  }

  hideDeleteAlert() {
    this.setState({ isDeleteConfirmationDisplayed: false })
  }

  hideShareDialog = () => {
    this.setState({ isShareDialogDisplayed: false })
  }

  hidePreviewDialog = () => {
    this.setState({
      isPreviewDialogDisplayed: false,
      copyButtonText: "Copy To Clipboard"
    })
  }

  deleteConfirmed() {
    this.props.deleteAsset(this.assetIdToDelete)
    this.hideDeleteAlert()
  }

  onShowHTMLCheckBoxChange = () => {
    this.setState({
      isShowHTML: !this.state.isShowHTML,
      copyButtonText: "Copy To Clipboard"
    })
  }

  render() {
    const { classes } = this.props
    const { selected, feedEntry } = this.state
    const checkedIcon = <CheckBoxIcon fontSize="small" />
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />

    const leagueFilterOptions = createFilterOptions({
      stringify: ({ combinedName }) => `${combinedName}`
    })

    const filteredLeagues = this.props.sortedLeagues.filter(
      q => q.name !== "" && q.isActive === true
    )
    const leaguesFullName = filteredLeagues.map(option => {
      let acronym = option.acronym ? `(${option.acronym}) ` : ""
      return {
        combinedName: `${acronym}${option.name}`,
        ...option
      }
    })
    const leagueOptions = [].concat(leaguesFullName)

    // Only display narratives that are set to be displayed in File Manager
    // and set to an 'active' status
    let filteredNarratives = this.props.narratives
      ?.toJS()
      .filter(n => n.narrativeStatusId === Enums.NarrativeStatus.ACTIVE)
    // Display all narratives for a user who has the 'Can See All Narratives' permission
    if (!this.props.accountPermissions?.CanSeeAllNarratives) {
      filteredNarratives = filteredNarratives.filter(
        n => n.canAppearInFileManager
      )
    }

    return (
      <Card className={this.props.embedded ? classes.embedded : ""}>
        <CardBody className={this.props.embedded ? classes.embedded : ""}>
          <div style={{ display: "flex", justifyItems: "flex-end" }}>
            <FileUpload
              organizationId={this.state.orgAndPropId}
              handleFileUploadComplete={this.handleFileUploadComplete}
            />
          </div>
          {this.state.isDeleteConfirmationDisplayed && (
            <Dialog
              onClose={this.hideShareDialog}
              aria-labelledby="customized-dialog-title"
              open={this.state.isDeleteConfirmationDisplayed}
              maxWidth="sm"
              fullWidth={true}
            >
              <DialogTitle id="alert-dialog-title">
                {"Archive the following file?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {`(${this.assetIdToDelete}) - ${this.assetFilenameToDelete}`}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => this.deleteConfirmed()}
                  color="primary"
                  autoFocus
                >
                  Yes, archive it
                </Button>
                <Button onClick={() => this.hideDeleteAlert()} color="primary">
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {this.state.isShareDialogDisplayed && (
            <Dialog
              onClose={this.hideShareDialog}
              aria-labelledby="customized-dialog-title"
              open={this.state.isShareDialogDisplayed}
              maxWidth="sm"
              fullWidth={true}
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={this.hideShareDialog}
              >
                Sharing
              </DialogTitle>
              <DialogContent>
                {this.isSharingImage && (
                  <React.Fragment>
                    <Typography gutterBottom>
                      Choose image dimensions (original aspect-ratio is always
                      maintained), quality, and/or image format.
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item>Width</Grid>
                      <Grid item xs>
                        <Slider
                          value={this.state.imageShareWidth}
                          onChange={(e, value) =>
                            this.onImageWidthChange(value)
                          }
                          aria-labelledby="input-slider"
                          max={this.state.imageShareMaxWidth}
                          min={50}
                          step={10}
                          valueLabelDisplay="auto"
                        />
                      </Grid>
                      <Grid>
                        <Input
                          className={classes.input}
                          value={this.state.imageShareWidth}
                          margin="dense"
                          onChange={e =>
                            this.onImageWidthChange(e.target.value)
                          }
                          inputProps={{
                            step: 1,
                            min: 50,
                            max: this.state.imageShareMaxWidth,
                            type: "number",
                            "aria-labelledby": "input-slider"
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item>Crop</Grid>
                      <Grid item xs>
                        <Checkbox
                          checked={this.state.isImageToBeCropped}
                          onChange={this.onImageCropCheckBoxChanged}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item>Height</Grid>
                      <Grid item xs>
                        <Slider
                          value={this.state.imageShareHeight}
                          onChangeCommitted={(e, value) =>
                            this.onImageHeightChange(value)
                          }
                          aria-labelledby="input-slider"
                          max={this.state.imageShareMaxHeight}
                          min={50}
                          step={10}
                          valueLabelDisplay="auto"
                        />
                      </Grid>
                      <Grid item>
                        <Input
                          className={classes.input}
                          value={this.state.imageShareHeight}
                          margin="dense"
                          onChange={e =>
                            this.onImageHeightChange(e.target.value)
                          }
                          inputProps={{
                            step: 1,
                            min: 50,
                            max: this.state.imageShareMaxHeight,
                            type: "number",
                            "aria-labelledby": "input-slider"
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item>Format</Grid>
                      <Grid item>
                        <Select
                          value={this.state.imageShareFormat}
                          onChange={e =>
                            this.onImageFormatChange(e.target.value)
                          }
                        >
                          <MenuItem value="original">Original</MenuItem>
                          <MenuItem value="jpg">JPEG</MenuItem>
                          <MenuItem value="png">PNG</MenuItem>
                          <MenuItem value="gif">GIF</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                    {(this.state.imageShareFormat === "jpg" ||
                      (this.state.imageShareFormat === "original" &&
                        this.state.assetMIMEType === "image/jpeg")) && (
                      <Grid container spacing={2}>
                        <Grid item>JPEG Quality</Grid>
                        <Grid item xs>
                          <Slider
                            value={this.state.imageShareQuality}
                            onChangeCommitted={(e, value) =>
                              this.onImageQualityChange(value)
                            }
                            aria-labelledby="input-slider"
                            max={100}
                            min={1}
                            step={5}
                            valueLabelDisplay="auto"
                          />
                        </Grid>
                        <Grid item>
                          <Input
                            className={classes.input}
                            value={this.state.imageShareQuality}
                            margin="dense"
                            onChange={e =>
                              this.onImageQualityChange(e.target.value)
                            }
                            inputProps={{
                              step: 1,
                              min: 1,
                              max: 100,
                              type: "number",
                              "aria-labelledby": "input-slider"
                            }}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </React.Fragment>
                )}
                <Typography>Copy and share the URL below:</Typography>

                <TextField
                  value={this.state.urlToShare}
                  style={{ width: "90%" }}
                  onChange={e => {
                    this.setState({ urlToShare: e.target.value })
                  }}
                  disabled={true}
                />
                <CopyToClipboard
                  text={this.state.urlToShare}
                  onCopy={() =>
                    this.setState({ copySuccessText: "URL Copied." })
                  }
                >
                  <Tooltip title={this.state.copySuccessText} placement="top">
                    <Button justIcon round simple color="info" className="info">
                      <FileCopy />
                    </Button>
                  </Tooltip>
                </CopyToClipboard>
                {this.isSharingImage && (
                  <img src={this.state.urlToShare} alt="" />
                )}
              </DialogContent>
              <DialogActions>
                <img
                  src={`${globalSettings.apiBaseUrl}/api/qrcode?text=${this.state.urlToShare}`}
                  alt=""
                  height={100}
                />
                <CopyToClipboard text={this.state.urlToShare}>
                  <Button onClick={this.hideShareDialog} color="primary">
                    Copy URL and Close
                  </Button>
                </CopyToClipboard>
                <Button onClick={this.hideShareDialog} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {this.state.isPreviewDialogDisplayed && (
            <Dialog
              onClose={this.hidePreviewDialog}
              aria-labelledby="customized-dialog-title"
              open={this.state.isPreviewDialogDisplayed}
              maxWidth="md"
              fullWidth={true}
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={this.hidePreviewDialog}
              >
                Story Preview
              </DialogTitle>
              {this.state.showErrorMessageDialog && (
                <DialogContent>
                  <div style={{ color: "red" }}>
                    <span style={{ fontWeight: "bold" }}>Errors:</span>
                    <div>{this.state.errorMessages}</div>
                  </div>
                </DialogContent>
              )}
              <DialogContent>
                <h6>Narrative Name</h6>
                <p>{this.state.narrativeName}</p>
                <h6>Title</h6>
                {!this.state.isShowHTML && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.feedEntryTitle
                    }}
                  />
                )}
                {this.state.isShowHTML && (
                  <div>{this.state.feedEntryTitle}</div>
                )}
                <h6>Content</h6>
                {!this.state.isShowHTML && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.feedEntryContent
                    }}
                  />
                )}
                {this.state.isShowHTML && (
                  <div>{this.state.feedEntryContent}</div>
                )}
              </DialogContent>
              <DialogActions>
                <div style={{ textAlign: "right" }}>
                  Show HTML?{" "}
                  <Checkbox
                    checked={this.state.isShowHTML}
                    onClick={() => this.onShowHTMLCheckBoxChange()}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                  />
                </div>{" "}
                <br />
                <Button
                  disabled={this.state.isSaveStoryButtonDisabled}
                  onClick={() => this.publishFeedEntry(feedEntry)}
                  color="primary"
                >
                  Save
                </Button>
                <CopyToClipboard
                  text={this.getTextToCopy()}
                  onCopy={() => this.onCopyButtonClick()}
                >
                  <Button
                    disabled={this.state.isCopyToClipboardButtonDisabled}
                    color="primary"
                  >
                    {this.state.copyButtonText}
                  </Button>
                </CopyToClipboard>
                <Button
                  disabled={this.state.isCopyToClipboardButtonDisabled}
                  onClick={() =>
                    this.downloadFeedEntryAsOpenXMLWordDocument(feedEntry.id)
                  }
                  color="primary"
                >
                  Download as Word Doc
                </Button>
                <Button onClick={this.hidePreviewDialog} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          )}
          <ReactTable
            data={this.props.assets}
            loading={this.props.isLoading}
            noDataText={
              this.props.isLoading ? <CircularProgress /> : "No file(s) found."
            }
            SubComponent={row => (
              <div style={{ padding: "20px" }}>
                {row.original.mimeType.indexOf("text/xml") === -1 &&
                  !this.props.embedded && (
                    <table>
                      <tbody>
                        <tr>
                          <th style={{ paddingRight: "30px" }}>
                            <h6>Asset Id</h6>
                          </th>
                          <th style={{ paddingRight: "30px" }}>
                            <h6>Asset Token</h6>
                          </th>
                          <th style={{ paddingRight: "30px" }}>
                            <h6>File Size</h6>
                          </th>
                          <th style={{ paddingRight: "30px" }}>
                            <h6>MIME Type</h6>
                          </th>
                          <th style={{ paddingRight: "30px" }}>
                            <h6>Alt Text</h6>
                          </th>
                          <th style={{ paddingRight: "30px" }}>
                            <h6>Graphics Tool?</h6>
                          </th>
                        </tr>
                        <tr>
                          <th
                            style={{
                              fontWeight: "lighter",
                              paddingRight: "30px"
                            }}
                          >
                            {row.original.id}
                          </th>
                          <th
                            style={{
                              fontWeight: "lighter",
                              paddingRight: "30px"
                            }}
                          >
                            {row.original.assetToken}
                          </th>
                          <th
                            style={{
                              fontWeight: "lighter",
                              paddingRight: "30px"
                            }}
                          >
                            {row.original.fileSizeInBytes.toLocaleString()}{" "}
                            bytes.
                          </th>
                          <th
                            style={{
                              fontWeight: "lighter",
                              paddingRight: "30px"
                            }}
                          >
                            {row.original.mimeType}
                          </th>
                          <th
                            style={{
                              fontWeight: "lighter",
                              paddingRight: "30px"
                            }}
                          >
                            {row.original.altText}
                          </th>
                          <th
                            style={{
                              fontWeight: "lighter",
                              paddingRight: "30px"
                            }}
                          >
                            {row.original.isAvailableForGraphicsTool
                              ? "Yes"
                              : "No"}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  )}
                {row.original.mimeType.indexOf("image/") !== -1 && (
                  <div>
                    <h6>Image Dimensions</h6>
                    <div>
                      {row.original.width}x{row.original.height}
                    </div>
                    <h6>Preview (width=200)</h6>
                    <div>
                      <img
                        src={`${globalSettings.cdnBaseUrl}/api/asset/${row.original.assetToken}.${row.original.mimeTypeExtension}?w=200`}
                        alt=""
                      />
                    </div>
                  </div>
                )}
                {row.original.mimeType.indexOf("text/xml") !== -1 &&
                  !this.props.embedded && (
                    <ReactTable
                      data={filteredNarratives.sort(Helpers.sortByName)}
                      columns={[
                        {
                          Header: <h4>Narratives Available</h4>,
                          columns: [
                            {
                              Header: "Name",
                              accessor: "name",
                              Cell: cell => (
                                <div
                                  style={{
                                    fontStyle: !cell.original
                                      .canAppearInFileManager
                                      ? "italic"
                                      : "initial"
                                  }}
                                >
                                  {!cell.original.canAppearInFileManager && (
                                    <DomainDisabled color="disabled" />
                                  )}
                                  {cell.value}
                                </div>
                              ),
                              width: 350,
                              sortable: false,
                              filterable: false
                            },
                            {
                              Header: "Actions",
                              accessor: "productionFeed_Id",
                              sortable: false,
                              filterable: false,
                              Cell: cell => (
                                //onPreviewClicked() third parameter is whether to show the preview with HTML included
                                <div>
                                  <Button
                                    color="primary"
                                    id="storypreview_file"
                                    simple
                                    onClick={() =>
                                      this.onPreviewClicked(
                                        cell,
                                        row.original.id,
                                        row.original.fileName,
                                        row.original.assetToken,
                                        cell.value
                                      )
                                    }
                                  >
                                    <i
                                      className={`${classes.socialButtonsIcons} ${classes.marginRight} fas fa-eye`}
                                    />{" "}
                                    Preview
                                  </Button>
                                  {/*Only show "View Feed" button if a production feed exists for Narrative*/}
                                  {cell.original.productionFeed &&
                                    cell.original.productionFeed.feedUrl && (
                                      <a
                                        href={`${cell.original.productionFeed.feedUrl}`}
                                        target="_new"
                                      >
                                        <Button color="info" simple>
                                          <i
                                            className={`${classes.socialButtonsIcons} ${classes.marginRight} fas fa-rss`}
                                          />{" "}
                                          View Feed
                                        </Button>
                                      </a>
                                    )}
                                </div>
                              )
                            }
                          ]
                        }
                      ]}
                      className=""
                      minRows={0}
                      showPagination={false}
                    />
                  )}
              </div>
            )}
            columns={[
              {
                Header: "Select",
                id: "assetcheckbox",
                Cell: ({ row }) => (
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={selected === row._original.id}
                    onClick={() => this.selectAsset(row)}
                  />
                ),
                sortable: false,
                filterable: false,
                width: 80,
                show: !!this.props.embedded
              },
              {
                Header: "Id",
                accessor: "id",
                Cell: cell => <div>{cell.value}</div>,
                width: 100
              },
              {
                Header: "Actions",
                Cell: cell => (
                  <div className="actions-right">
                    <Link to={`/portal/fileeditor/${cell.original.id}`}>
                      <Tooltip title="Edit" placement="top">
                        <Button
                          justIcon
                          round
                          simple
                          color="primary"
                          className="info"
                          id="edit_file"
                        >
                          <Edit />
                        </Button>
                      </Tooltip>
                    </Link>
                    <Tooltip title="Share" placement="top">
                      <Button
                        justIcon
                        round
                        simple
                        color="primary"
                        className="info"
                        id="share_file"
                        onClick={() => this.onShareButtonClicked(cell)}
                      >
                        <Share />
                      </Button>
                    </Tooltip>
                    <a
                      href={`${globalSettings.apiBaseUrl}/api/asset/${cell.original.assetToken}/download`}
                      target="_new"
                    >
                      <Tooltip title="Download" placement="top">
                        <Button
                          justIcon
                          round
                          simple
                          color="primary"
                          className="info"
                          id="download_file"
                        >
                          <CloudDownload />
                        </Button>
                      </Tooltip>
                    </a>
                    <a
                      href={`${globalSettings.apiBaseUrl}/api/asset/${cell.original.assetToken}.${cell.original.mimeTypeExtension}`}
                      target="_new"
                    >
                      <Tooltip title="View" placement="top">
                        <Button
                          justIcon
                          round
                          simple
                          color="primary"
                          className="info"
                          id="render_file"
                        >
                          <Visibility />
                        </Button>
                      </Tooltip>
                    </a>
                    <Tooltip title="Archive" placement="top">
                      <Button
                        justIcon
                        round
                        simple
                        color="primary"
                        className="info"
                        id="archive_file"
                        onClick={() =>
                          this.displayDeleteConfirmation(
                            cell.original.id,
                            cell.original.fileName
                          )
                        }
                      >
                        <Delete />
                      </Button>
                    </Tooltip>
                  </div>
                ),
                sortable: false,
                filterable: false,
                width: 225
              },
              {
                Header: "Filename",
                accessor: "fileName",
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, {
                    keys: ["fileName"],
                    threshold: matchSorter.rankings.CONTAINS
                  }),
                filterAll: true
              },
              {
                Header: "MIME Type",
                accessor: "mimeType",
                id: "mimetype",
                width: 200,
                Cell: cell => (
                  <div>
                    {cell.value}&nbsp;
                    {cell.value.indexOf("image") !== -1 && (
                      <img
                        src={`${globalSettings.cdnBaseUrl}/api/asset/${cell.original.assetToken}/render?h=50`}
                        alt=""
                      />
                    )}
                  </div>
                ),
                sortMethod: (a, b) =>
                  a?.toLowerCase() > b?.toLowerCase() ? 1 : -1,
                filterMethod: (filter, row) => {
                  if (filter.value === "all") {
                    return true
                  }
                  return row.mimetype === filter.value
                },
                Filter: ({ filter, onChange }) => {
                  if (this.props.assets === null) {
                    return null
                  }
                  const distinctMIMETypes = []
                  const map = new Map()
                  for (const asset of this.props.assets) {
                    if (!map.has(asset.mimeType)) {
                      map.set(asset.mimeType, true)
                      distinctMIMETypes.push({
                        key: asset.mimeType,
                        id: asset.mimeType,
                        name: asset.mimeType
                      })
                    }
                  }
                  // inline sort by org name
                  distinctMIMETypes.sort((a, b) => {
                    // Use toUpperCase() to ignore character casing
                    const orgA = a.name.toUpperCase()
                    const orgB = b.name.toUpperCase()
                    let comparison = 0
                    if (orgA > orgB) {
                      comparison = 1
                    } else if (orgA < orgB) {
                      comparison = -1
                    }
                    return comparison
                  })
                  // build up the <select> element <option> element JSX style
                  let options = []
                  distinctMIMETypes.forEach(mimeType => {
                    options.push(
                      <option value={mimeType.id} key={mimeType.id}>
                        {mimeType.name}
                      </option>
                    )
                  })
                  return (
                    <select
                      onChange={event => {
                        onChange(event.target.value)
                      }}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="all">Show All</option>
                      {options}
                    </select>
                  )
                }
              },
              {
                Header: "Audit Info",
                accessor: "auditInfo.createdOn",
                Cell: cell => Helpers.renderAuditInfoCell(cell),
                width: 200,
                filterMethod: (filter, row) => {
                  if (filter.value === "all") {
                    return true
                  }
                  let futureTime = new Date()
                  let pastTime = new Date()
                  let modifiedOn = new Date(row._original.auditInfo.modifiedOn)
                  if (filter.value === "within7days") {
                    return (
                      modifiedOn >
                        pastTime.setHours(pastTime.getHours() - 24 * 7) &&
                      modifiedOn <
                        futureTime.setHours(futureTime.getHours() + 24 * 7)
                    )
                  }
                  if (filter.value === "within24hours") {
                    return (
                      modifiedOn >
                        pastTime.setHours(pastTime.getHours() - 24) &&
                      modifiedOn <
                        futureTime.setHours(futureTime.getHours() + 24)
                    )
                  }
                  if (filter.value === "within1hour") {
                    return (
                      modifiedOn > pastTime.setHours(pastTime.getHours() - 1) &&
                      modifiedOn <
                        futureTime.setHours(futureTime.getHours() + 1)
                    )
                  }
                },
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                  >
                    <option value="all">Show All</option>
                    <option value="within7days">Within 7 days</option>
                    <option value="within24hours">Within 24 Hours</option>
                    <option value="within1hour">Within 1 Hour</option>
                  </select>
                )
              },
              {
                Header: "Graphics Tool?",
                accessor: "isavailableforgraphicstool",
                id: "isavailableforgraphicstool",
                Cell: row => (
                  <div>
                    {row.original.mimeType.indexOf("image/") !== -1 && (
                      <Switch
                        id="toggleIsAvailableForGraphicsTool"
                        checked={row.original.isAvailableForGraphicsTool}
                        onChange={e =>
                          this.handleIsAvailableForGraphicsToolToggle(e, row)
                        }
                        color="primary"
                        size="small"
                      />
                    )}
                  </div>
                ),
                width: 110,
                sortable: true,
                filterable: true,
                filterMethod: (filter, row) => {
                  if (filter.value === "all") {
                    return true
                  }
                  if (filter.value === "yes") {
                    return row._original.isAvailableForGraphicsTool === true
                  }
                  if (
                    filter.value === "no" &&
                    row._original.mimeType.indexOf("image/") !== -1
                  ) {
                    return row._original.isAvailableForGraphicsTool === false
                  }
                },
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                  >
                    <option value="all">Show All</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                )
              },
              {
                Header: "League",
                accessor: "sportsLeagues",
                Cell: row => (
                  <div>
                    {row.original.mimeType.indexOf("image/") !== -1 && (
                      <Autocomplete
                        limitTags={5}
                        options={leagueOptions}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        multiple={true}
                        value={leagueOptions.filter(league1 =>
                          row.original.sportsLeagues?.some(
                            league2 => league2.id === league1.id
                          )
                        )}
                        onChange={(event, newValue, reason, detail) => {
                          let sportsLeagues = newValue
                          switch (reason) {
                            case "clear":
                              sportsLeagues = null
                              this.props.dissociateAllLeaguesFromAsset(
                                row.original.id
                              )
                              break
                            case "removeOption":
                              this.props.dissociateAssetAndLeague(
                                row.original.id,
                                detail.option.id
                              )
                              break
                            case "selectOption":
                              this.props.associateAssetAndLeague(
                                row.original.id,
                                detail.option.id
                              )
                              break
                            default:
                              break
                          }
                          let changedAssets = this.props.assets
                          changedAssets[row.index].sportsLeagues = sportsLeagues
                          this.setState({
                            assets: changedAssets
                          })
                        }}
                        inputProps={{
                          name: "sportsLeagues",
                          id: "sportsLeagues"
                        }}
                        loading
                        getOptionLabel={option =>
                          `${option.combinedName}` || ""
                        }
                        filterOptions={leagueFilterOptions}
                        renderInput={params => (
                          <TextField {...params} label="Sports Leagues" />
                        )}
                        disableCloseOnSelect={true}
                        disabled={!row.original.isAvailableForGraphicsTool}
                        renderOption={(props, option, { selected }) => (
                          <Box>
                            <li {...props} style={{ paddingLeft: "1px" }}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                checked={selected}
                              />
                              {option.combinedName}
                            </li>
                          </Box>
                        )}
                      />
                    )}
                  </div>
                ),
                width: 300,
                sortable: false,
                filterable: false
              }
            ]}
            collapseOnDataChange={false}
            defaultExpanded={{ 0: this.state.isFirstRowExpanded }}
            filterable={true}
            minRows={5}
            defaultPageSize={25}
            showPaginationTop={true}
            showPaginationBottom={true}
            className="-striped -highlight -scrollEntries"
            style={{
              maxHeight: `${(window.innerHeight * 0.69).toFixed(0)}px`
            }}
            defaultSorted={[
              {
                id: "auditInfo.createdOn",
                desc: true
              }
            ]}
          >
            {(state, makeTable) => {
              const { filtered, pageRows, pageSize, sortedData, page } = state
              let isFiltered = false
              let recordsCountFrom = 0

              let recordsCountTo = 0

              let totalRecords = 0
              isFiltered = filtered.length > 0
              totalRecords = sortedData.length
              recordsCountFrom = page * pageSize + 1
              recordsCountTo = recordsCountFrom + pageRows.length - 1
              return (
                <div className="main-grid">
                  <div className="above-table text-right">
                    <div className="col-sm-12">
                      <span className="records-info">
                        {totalRecords > 0 && isFiltered && (
                          <span>
                            {recordsCountFrom}-{recordsCountTo} of{" "}
                            {totalRecords} filtered out of{" "}
                            {this.renderTotalCountOptions()} total files
                          </span>
                        )}
                        {totalRecords > 0 && !isFiltered && (
                          <span>
                            {recordsCountFrom}-{recordsCountTo} of{" "}
                            {this.renderTotalCountOptions()} total files
                          </span>
                        )}
                        {totalRecords === 0 &&
                          (this.props.isLoading
                            ? "Loading files, please wait..."
                            : "No files.")}
                      </span>
                      <Tooltip title="Reload" placement="top" arrow={true}>
                        <Button
                          justIcon
                          round
                          simple
                          color="info"
                          className="info"
                          id="reload"
                          onClick={() => {
                            this.props.retrieveAssets(
                              this.props.orgAndPropId,
                              this.state.assetLimit
                            )
                          }}
                        >
                          {this.props.isLoading ? (
                            <CircularProgress thickness={1} size={30} />
                          ) : (
                            <Loop />
                          )}
                        </Button>
                      </Tooltip>
                    </div>
                  </div>

                  {makeTable()}
                </div>
              )
            }}
          </ReactTable>
        </CardBody>
      </Card>
    )
  }
}

export default withStyles(regularFormsStyle)(withRouter(useStore(AssetManager)))
