import { globalSettings } from "variables/general.jsx"
import { get, put } from "../tools/request"

export default class WebhookDAO {
  static getWebhooks = async () => {
    const res = await get(`${globalSettings.apiBaseUrl}/api/webhook`)
    return await res.json()
  }

  static getOutboundWebhookByOrg = async organizationId => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/webhook/listoutboundwebhooks?organizationid=${organizationId}`
    )
    return await res.json()
  }

  static getInboundWebhookByOrg = async organizationId => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/webhook/listinboundwebhooks?organizationid=${organizationId}`
    )
    return await res.json()
  }

  static loadWebhookOutboundQueueSent = async webhookId => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/webhook/listoutboundwebhookqueuesent?webhookId=${webhookId}`
    )
    return await res.json()
  }

  static loadInboundWebhookLogs = async webhookId => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/webhook/listinboundwebhooklogs?webhookid=${webhookId}`
    )
    return await res.json()
  }

  static postWebhook = async body => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/webhook/createwebhook`,
      null,
      body
    )
    return await res.json()
  }

  static putWebhook = async body => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/webhook/updatewebhook`,
      null,
      body
    )
    return await res.json()
  }
}
