module.exports = {
  getColorFromWords(words, salt = 160) {
    let hex = "#"
    const wordValue = words
      .split("")
      .map(s => s.charCodeAt(0))
      .join("")
    const stringValue = (wordValue * salt).toString().replace(/[^0-9]+/g, "")
    if (stringValue && stringValue.length >= 13) {
      let start = Math.round(stringValue.length * 0.45)
      hex += stringValue.substring(start, start + 6)
    } else if (
      stringValue &&
      stringValue.length > 6 &&
      stringValue.length < 13
    ) {
      hex += stringValue.substr(-6)
    } else {
      hex += stringValue
    }
    return hex
  },
  getRandomColor() {
    return `#${Math.random().toString(16).substr(-6)}`
  }
}
