// eslint-disable-next-line no-unused-vars
import React from "react"
import PropTypes from "prop-types"

import { FormControl, InputLabel, Select, MenuItem } from "@mui/material"
import Helpers from "tools/Helpers.js"

function NarrativeModelType({ ...props }) {
  const { modelTypes, value } = props
  if (!modelTypes) {
    return
  }
  return (
    <FormControl margin="normal" fullWidth>
      <InputLabel>Narrative Model (default)</InputLabel>
      <Select
        type="select"
        name="modelTypeId"
        id="modelTypeId"
        label="Narrative Model (default)"
        value={value || ""}
        onChange={e => {
          props.onChange(e)
        }}
      >
        <MenuItem value="">None</MenuItem>
        {modelTypes.sort(Helpers.sortByName).map(modelType => (
          <MenuItem
            data-model-type-id={modelType.id}
            key={modelType.key}
            value={modelType.id}
          >
            {modelType.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

NarrativeModelType.propTypes = {
  modelTypes: PropTypes.array,
  value: PropTypes.string,
  modelTypeId: PropTypes.number
}

export default NarrativeModelType
