import { globalSettings } from "variables/general"

export default class adminDAO {
  static login = async body => {
    const loginUser = fetch(`${globalSettings.berthaBaseUrl}/api/Login`, {
      credentials: "include",
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    })
    return await loginUser
  }

  static refresh = async body => {
    const loginUser = fetch(`${globalSettings.berthaBaseUrl}/api/Refresh`, {
      credentials: "include",
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    })
    return await loginUser
  }
}
