import { globalSettings } from "variables/general.jsx"
import { get, put } from "../tools/request"

export default class NarrativeRenderScheduleDAO {
  static getNarrativeRenderSchedules = async () => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/narrativeRenderSchedule/`
    )
    return await res.json()
  }

  static getNarrativeRenderSchedulesByNarrativeId = async narrativeId => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/narrativeRenderSchedule/retrieveallbynarrative?narrativeid=${narrativeId}`
    )
    return await res.json()
  }

  static putScheduleFeedEntries = async body => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/narrativerenderschedule/add-instructions`,
      null,
      body
    )
    return await res.json()
  }

  static getScheduleFutureOccurences = async (
    cronSyntaxValue,
    startDateTime,
    useRecurrenceSettings,
    endDateTimeValue,
    maxFutureOccurrenceCount
  ) => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/narrativerenderschedule/futureoccurrences?cronsyntax=${cronSyntaxValue}&startdatetime=${startDateTime}&userecurrencesettings=${useRecurrenceSettings}&enddatetime=${endDateTimeValue}&maxFutureOccurrenceCount=${maxFutureOccurrenceCount}`
    )
    return await res.json()
  }
}
