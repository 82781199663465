import React, { useState } from "react"
import PropTypes from "prop-types"
import { Cookies } from "tools/storage"

export default function SearchTriggersPagination(props) {
  const cookies = new Cookies()
  const { searchResultsLength } = props
  // const [myPageSize, setMyPageSize] = useState(
  //   Number(cookies.get("TriggerSearchHugePageSize")) || 25
  // )
  const myPageSize = Number(cookies.get("TriggerSearchHugePageSize")) || 25

  const [myPageIndex, setMyPageIndex] = useState(0)
  const pageCount = Math.ceil(searchResultsLength / myPageSize)

  return (
    <>
      {props.hasFilteringWithPagination && (
        <>
          <div
            style={{
              display: "inline-block",
              fontWeight: "bold",
              whiteSpace: "nowrap",
              padding: "0px 0px 0px 0px",
              lineHeight: "1.5"
            }}
          >
            <div className="">
              {props.largeTriggerPagination && !props.hasFilteringWithPagination
                ? "Showing "
                : `Searching (${props.tableType} table), showing `}
              {1 + myPageIndex * myPageSize > searchResultsLength
                ? searchResultsLength
                  ? "1"
                  : "0"
                : 1 + myPageIndex * myPageSize}{" "}
              to{" "}
              {(myPageIndex + 1) * myPageSize > searchResultsLength
                ? searchResultsLength
                : (myPageIndex + 1) * myPageSize}{" "}
              (of {searchResultsLength}) results{" "}
              <button
                onClick={() => {
                  setMyPageIndex(0)
                  props.passBackPageIndex(0)
                }}
                disabled={!myPageIndex}
              >
                {"<<"}
              </button>{" "}
              <button
                onClick={() => {
                  setMyPageIndex(myPageIndex - 1)
                  props.passBackPageIndex(myPageIndex - 1)
                }}
                disabled={!myPageIndex}
              >
                {"<"}
              </button>{" "}
              <button
                onClick={() => {
                  setMyPageIndex(myPageIndex + 1)
                  props.passBackPageIndex(myPageIndex + 1)
                }}
                disabled={!(myPageIndex < pageCount - 1)}
              >
                {">"}
              </button>{" "}
              <button
                onClick={() => {
                  setMyPageIndex(pageCount - 1)
                  props.passBackPageIndex(pageCount - 1)
                }}
                disabled={!(myPageIndex < pageCount - 1)}
              >
                {">>"}
              </button>{" "}
              <span>
                {" "}
                Page{" "}
                <strong>
                  {myPageIndex + 1 > Math.ceil(searchResultsLength / myPageSize)
                    ? Math.ceil(searchResultsLength / myPageSize)
                    : myPageIndex + 1}{" "}
                  of {Math.ceil(searchResultsLength / myPageSize)}
                </strong>{" "}
              </span>
            </div>
          </div>
        </>
      )}
    </>
  )
}

SearchTriggersPagination.propTypes = {
  searchResultsLength: PropTypes.number,
  passBackPageSize: PropTypes.func,
  passBackPageIndex: PropTypes.func,
  largeTriggerPagination: PropTypes.bool,
  hasFilteringWithPagination: PropTypes.bool,
  tableType: PropTypes.string,
  filterColumn: PropTypes.string
}
