import { globalSettings } from "variables/general.jsx"
import { get, put } from "../tools/request"

export default class NarrativePreviewDAO {
  static getSnapshotTypes = async () => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/narrativepreview/snapshot-types`
    )
    return res.json()
  }

  static getNarrativeModelSnapshots = async snapshotId => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/narrativepreview/recent-models/${snapshotId}`
    )
    return res.json()
  }

  static postFeedEntry = async body => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/narrativepreview/feed-entry`,
      { create: true },
      body
    )
    return res.json()
  }

  static postPreviewFeedEntry = async body => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/narrativepreview/feed-entry`,
      null,
      body
    )
    return res.json()
  }

  static postPreviewOutline = async body => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/narrativepreview/outline`,
      null,
      body
    )
    return res.json()
  }

  static evaluateTrigger = async body => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/narrativepreview/evaluate-trigger`,
      null,
      body
    )
    return res.json()
  }

  static evaluateSentence = async body => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/narrativepreview/template-preview`,
      null,
      body
    )
    return res.json()
  }

  static evaluateTriggers = async body => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/narrativepreview/evaluate-triggers`,
      null,
      body
    )
    return res.json()
  }

  static updateTriggers = async body => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/narrativepreview/update-triggers`,
      null,
      body
    )
    return res.json()
  }
}
