import { globalSettings } from "variables/general.jsx"
import { get, put } from "../tools/request"

export default class OrganizationDAO {
  static getOrganizations = async () => {
    const res = await get(`${globalSettings.apiBaseUrl}/api/organization/`)
    return await res.json()
  }

  static getOrganization = async organizationId => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/organization/retrieve/${organizationId}`
    )
    return await res.json()
  }

  static getFeeds = async organizationId => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/feed/retrieveall?organizationid=${organizationId}`
    )
    return await res.json()
  }

  static getNarratives = async organizationId => {
    // TODO: pre api refactor
    // const res = await get(
    //   `${globalSettings.apiBaseUrl}/api/organization/${organizationId}/narrative`
    // )
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/narrative/retrieveallbyorganization`,
      {
        organizationId
      }
    )
    return await res.json()
  }

  static getContractDeliverablesForSchedule = async (
    organizationId,
    leagueId = "",
    articleTypeId = ""
  ) => {
    //fetch the response
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/organization/retrieve/${organizationId}/contractdeliverables`,
      {
        leagueId,
        articleTypeId
      }
    )
    return await res.json()
  }

  static postContractDeliverable = async contractDeliverable => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/organization/update/contractdeliverables`,
      null,
      contractDeliverable
    )
    return await res.json()
  }

  static postMilestone = async (milestone, action) => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/organization/${action}/contractmilestones`,
      null,
      milestone
    )
    return await res.json()
  }

  static getSubjectType = async () => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/organization/contractdeliverables/subjecttypes`
    )
    return await res.json()
  }

  static getPublishType = async () => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/organization/contractdeliverables/publishtypes`
    )
    return await res.json()
  }
  static getCatalystType = async () => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/organization/contractdeliverables/catalysttypes`
    )
    return await res.json()
  }

  static postContractDeliverableBulkUpload = async bulkUpload => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/organization/contractdeliverablebulkupload`,
      null,
      bulkUpload
    )
    return await res.json()
  }

  static getOutboundWebhookByOrg = async organizationId => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/webhook/listorganizationwebhooks?organizationid=${organizationId}`
    )

    return await res.json()
  }

  static getOrganizationNarrativesAndFeeds = async organizationId => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/organization/validate/narrativesAndFeeds/${organizationId}`
    )
    return await res.json()
  }

  static getDoOrgAndFeedHaveSameOrg = async body => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/organization/validate/narrativesAndFeeds/eachrowissameorg`,
      null,
      body
    )
    return await res.json()
  }
}
