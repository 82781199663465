import React, { Component } from "react"
import ReactTable from "react-table-6"
import withStyles from "@mui/styles/withStyles"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardHeader from "components/Card/CardHeader"
import { cardTitle } from "assets/jss/material-dashboard-pro-react"
import matchSorter from "match-sorter"
import Button from "components/CustomButtons/Button"
import Tooltip from "@mui/material/Tooltip"
import { globalSettings } from "variables/general"
import CircularProgress from "@mui/material/CircularProgress"
import Loop from "@mui/icons-material/Loop"
import { groupCollectionBy } from "tools/CollectionHelper"
import { IconButton } from "@mui/material"
import Helpers from "tools/Helpers.js"
import { Cookies } from "tools/storage"
import TimeAgo from "react-timeago"
import { ThumbsUpDown } from "@mui/icons-material"
import { observer, inject } from "mobx-react"
import { get } from "tools/request"

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  outlineCard: {
    marginTop: "5px"
  }
}

const useStore = component =>
  inject(({ store }) => ({
    organizationId: store.organizationStore.organizationId,
    organization: store.organizationStore.organization,
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization,
    showChangeCurrentOrganization: store.uiStore.showChangeCurrentOrganization
  }))(observer(component))

class InReview extends Component {
  constructor(props) {
    super(props)
    this.props = props
    let cookies = new Cookies()
    const pageSize = props.embedded
      ? 25
      : cookies.get("feedsPageSize")
      ? Number(cookies.get("feedsPageSize"))
      : 25
    this.state = {
      isLoadingReviewItems: false,
      isLoadingOrganization: false,
      inReview: [],
      organizationId: this.props.organizationId,
      addFeed: false,
      editFeed: false,
      fevFAQs: false,
      pageSize,
      selectedFeedId: -1,
      expandedFeedRows: {},
      isLoadingFeedEndPointWebLogs: false,
      feedEndPointWebLogs: [],
      feedEndPointWebLogsTotalCount: 0
    }
  }

  loadOrgReviewItems(organizationId) {
    this.setState({ isLoadingReviewItems: true, inReview: [] })
    get(
      `${globalSettings.apiBaseUrl}/api/narrative/inreviewbyorganization?organizationid=${organizationId}`
    )
      .then(Response => Response.json())
      .then(inReviewResponse => {
        this.setState({
          inReview: inReviewResponse.content,
          organizationId: organizationId,
          isLoadingReviewItems: false
        })
      })
  }

  loadNarrativeReviewItems(organizationId) {
    this.setState({ isLoadingReviewItems: true, inReview: [] })
    get(
      `${globalSettings.apiBaseUrl}/api/narrative/inreviewbyorganization?organizationid=${organizationId}`
    )
      .then(Response => Response.json())
      .then(inReviewResponse => {
        this.setState({
          inReview: inReviewResponse.content,
          organizationId: organizationId,
          isLoadingReviewItems: false
        })
      })
  }

  componentDidMount() {
    if (this.props.narrativeId) {
      this.loadNarrativeReviewItems(this.props.narrativeId)
    } else {
      if (!this.props.showChangeCurrentOrganization) {
        this.props.setShowChangeCurrentOrganization(false)
      }
      this.loadOrgReviewItems(this.props.organizationId)
    }
  }

  componentDidUpdate() {
    if (!this.props.narrativeId) {
      if (this.state.organizationId !== this.props.organizationId) {
        this.setState({
          organizationId: this.props.organizationId
        })
        this.loadOrgReviewItems(this.props.organizationId)
      }
    }
  }

  getItemValueFromPath(item, key) {
    if (!key || key === "") {
      return null
    }
    let value = void 0
    if (key && key.indexOf(".") !== -1) {
      // eslint-disable-line no-negated-condition
      // handle nested keys
      value = key.split(".").reduce(function (itemObj, nestedKey) {
        return itemObj[nestedKey]
      }, item)
    } else {
      value = item[key]
    }
    // concat because `value` can be a string or an array
    return value ? [].concat(value) : null
  }

  handleCancel() {
    this.setState({ fevFAQs: false })
  }

  rememberChanges = pageSize => {
    this.setState({ pageSize })
    let cookies = new Cookies()
    cookies.set("feedsPageSize", pageSize, {
      path: "/",
      expires: Helpers.CookieExpiration.OneYear
    })
  }

  stringFilterProps() {
    return {
      filterable: true,
      filterAll: true,
      filterMethod: ({ id, value }, rows) =>
        matchSorter(rows, value, {
          keys: [id],
          threshold: matchSorter.rankings.CONTAINS
        })
    }
  }

  stringDistinctFilterProps(propName, properties) {
    return {
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true
        }
        // NOTE: string versus integer comparison,
        // don't use === operator
        // eslint-disable-next-line eqeqeq
        return row[propName] == filter.value
      },
      Filter: ({ filter, onChange }) => {
        if (!properties || properties.size === 0) {
          return null
        }

        const distinctOptions = Object.keys(
          groupCollectionBy(properties, p =>
            this.getItemValueFromPath(p, propName)
          )
        ).sort((a, b) => {
          // Use toUpperCase() to ignore character casing
          const orgA = a.toUpperCase()
          const orgB = b.toUpperCase()
          let comparison = 0
          if (orgA > orgB) {
            comparison = 1
          } else if (orgA < orgB) {
            comparison = -1
          }
          return comparison
        })
        // build up the <select> element <option> element JSX style
        let options = []
        distinctOptions.forEach(opt => {
          options.push(
            <option value={opt} key={opt}>
              {opt}
            </option>
          )
        })
        return (
          <select
            onChange={event => {
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">All</option>
            {options}
          </select>
        )
      }
    }
  }

  render() {
    const { classes } = this.props
    const { inReview, pageSize } = this.state
    if (!inReview) {
      return "Assembling..."
    }

    return (
      <React.Fragment>
        <GridContainer>
          <GridItem xs={12}>
            <Card className={classes.outlineCard}>
              <CardHeader color="primary" icon style={{ display: "flex" }}>
                <GridContainer>
                  <GridItem xs={12}>
                    <div style={{ display: "flex" }}>
                      <h4 className={classes.cardIconTitle}>
                        <strong>{this.props.organization.name}</strong> - In
                        Review or Changed in the past 72 hours
                      </h4>
                    </div>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <ReactTable
                  minRows={0}
                  loading={
                    this.state.isLoadingReviewItems ||
                    this.state.isLoadingOrganization
                  }
                  data={inReview}
                  noDataText={
                    this.state.isLoadingReviewItems ||
                    this.state.isLoadingOrganization ? (
                      <CircularProgress />
                    ) : (
                      `Nothing Recently Changed or In Review found for ${this.props.organization.name}`
                    )
                  }
                  defaultFiltered={[
                    {
                      id: "narrativestatus_id",
                      value: 3
                    }
                  ]}
                  defaultSorted={[
                    {
                      id: "modifieddatetime",
                      desc: true
                    }
                  ]}
                  columns={[
                    {
                      Cell: cell => (
                        <div style={{ display: "flex" }}>
                          {cell.original.blocktype === "Trigger" && (
                            <span>
                              <Tooltip
                                id="tooltip-top-start"
                                title="Review Trigger"
                                placement="top"
                              >
                                <IconButton
                                  style={{ padding: 0, margin: 0 }}
                                  aria-label="Review Trigger"
                                  onClick={() => {
                                    this.props.history.push(
                                      `/portal/narrative/${cell.original.narrative_id}?view=triggerid-${cell.original.trigger_id}`
                                    )
                                  }}
                                  size="large"
                                >
                                  <ThumbsUpDown />
                                </IconButton>
                              </Tooltip>
                            </span>
                          )}
                          {cell.original.blocktype === "Paragraph" && (
                            <span>
                              <Tooltip
                                id="tooltip-top-start"
                                title="Review Paragraph"
                                placement="top"
                              >
                                <IconButton
                                  style={{ padding: 0, margin: 0 }}
                                  aria-label="Review Paragraph"
                                  onClick={() => {
                                    this.props.history.push(
                                      `/portal/narrative/${cell.original.narrative_id}/edit?view=paragraphid-${cell.original.paragraph_id}`
                                    )
                                  }}
                                  size="large"
                                >
                                  <ThumbsUpDown />
                                </IconButton>
                              </Tooltip>
                            </span>
                          )}
                          {cell.original.blocktype === "Sentence" && (
                            <span>
                              <Tooltip
                                id="tooltip-top-start"
                                title="Review Sentence"
                                placement="top"
                              >
                                <IconButton
                                  style={{ padding: 0, margin: 0 }}
                                  aria-label="Review Sentence"
                                  onClick={() => {
                                    this.props.history.push(
                                      `/portal/narrative/${cell.original.narrative_id}/edit?view=sentenceid-${cell.original.sentence_id}`
                                    )
                                  }}
                                  size="large"
                                >
                                  <ThumbsUpDown />
                                </IconButton>
                              </Tooltip>
                            </span>
                          )}
                        </div>
                      ),
                      sortable: false,
                      filterable: false,
                      width: 50
                    },
                    {
                      Header: "Type",
                      accessor: "blocktype",
                      width: 100,
                      Cell: cell => cell.value,
                      ...this.stringDistinctFilterProps("blocktype", inReview)
                    },
                    {
                      Header: "Name",
                      accessor: "name",
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, {
                          keys: ["name"],
                          threshold: matchSorter.rankings.CONTAINS
                        }),
                      filterAll: true
                    },
                    {
                      Header: "Status",
                      accessor: "status_id",
                      Cell: cell => (
                        <div>
                          {Helpers.convertBlocksStatusIdToStatusName(
                            cell.original.status_id
                          )}
                        </div>
                      ),
                      width: 175,
                      sortMethod: (a, b) =>
                        Helpers.convertBlocksStatusIdToStatusName(a) >
                        Helpers.convertBlocksStatusIdToStatusName(b)
                          ? 1
                          : -1,
                      filterMethod: (filter, row) => {
                        if (filter.value === "all") {
                          return true
                        }
                        // NOTE: string versus integer comparison,
                        // don't use === operator
                        // eslint-disable-next-line eqeqeq
                        return row.status_id == filter.value
                      },
                      Filter: ({ filter, onChange }) => {
                        if (inReview.size === 0) {
                          return null
                        }

                        const distinctNarrativeStatuses = []
                        const map = new Map()
                        for (let review of inReview) {
                          if (!map.has(review.status_id)) {
                            map.set(review.status_id, true)
                            distinctNarrativeStatuses.push({
                              id: review.status_id,
                              name: Helpers.convertBlocksStatusIdToStatusName(
                                review.status_id
                              )
                            })
                          }
                        }
                        // inline sort by status name
                        distinctNarrativeStatuses.sort((a, b) => {
                          // Use toUpperCase() to ignore character casing
                          const orgA = a.name.toUpperCase()
                          const orgB = b.name.toUpperCase()
                          let comparison = 0
                          if (orgA > orgB) {
                            comparison = 1
                          } else if (orgA < orgB) {
                            comparison = -1
                          }
                          return comparison
                        })
                        // build up the <select> element <option> element JSX style
                        let options = []
                        distinctNarrativeStatuses.forEach(status => {
                          if (status.id !== null) {
                            options.push(
                              <option value={status.id} key={status.id}>
                                {status.name}
                              </option>
                            )
                          }
                        })
                        return (
                          <select
                            onChange={event => {
                              onChange(event.target.value)
                            }}
                            style={{ width: "100%" }}
                            value={filter ? filter.value : "all"}
                          >
                            <option value="all">Show All</option>
                            {options}
                          </select>
                        )
                      }
                    },
                    {
                      Header: "Narrative",
                      accessor: "narrative",
                      show: !this.props.narrativeId,
                      Cell: cell => cell.value,
                      ...this.stringDistinctFilterProps("narrative", inReview)
                    },
                    {
                      Header: "Narrative Status",
                      accessor: "narrativestatus_id",
                      show: !this.props.narrativeId,
                      Cell: cell => (
                        <div>
                          {Helpers.convertNarrativeStatusIdToStatusName(
                            cell.original.narrativestatus_id
                          )}
                        </div>
                      ),
                      width: 175,
                      sortMethod: (a, b) =>
                        Helpers.convertNarrativeStatusIdToStatusName(a) >
                        Helpers.convertNarrativeStatusIdToStatusName(b)
                          ? 1
                          : -1,
                      filterMethod: (filter, row) => {
                        if (filter.value === "all") {
                          return true
                        }
                        // NOTE: string versus integer comparison,
                        // don't use === operator
                        // eslint-disable-next-line eqeqeq
                        return row.narrativestatus_id == filter.value
                      },
                      Filter: ({ filter, onChange }) => {
                        if (inReview.size === 0) {
                          return null
                        }

                        const distinctNarrativeStatuses = []
                        const map = new Map()
                        for (let review of inReview) {
                          if (!map.has(review.narrativestatus_id)) {
                            map.set(review.narrativestatus_id, true)
                            distinctNarrativeStatuses.push({
                              id: review.narrativestatus_id,
                              name: Helpers.convertNarrativeStatusIdToStatusName(
                                review.narrativestatus_id
                              )
                            })
                          }
                        }
                        // inline sort by status name
                        distinctNarrativeStatuses.sort((a, b) => {
                          // Use toUpperCase() to ignore character casing
                          const orgA = a.name.toUpperCase()
                          const orgB = b.name.toUpperCase()
                          let comparison = 0
                          if (orgA > orgB) {
                            comparison = 1
                          } else if (orgA < orgB) {
                            comparison = -1
                          }
                          return comparison
                        })
                        // build up the <select> element <option> element JSX style
                        let options = []
                        distinctNarrativeStatuses.forEach(status => {
                          if (status.id !== null) {
                            options.push(
                              <option value={status.id} key={status.id}>
                                {status.name}
                              </option>
                            )
                          }
                        })
                        return (
                          <select
                            onChange={event => {
                              onChange(event.target.value)
                            }}
                            style={{ width: "100%" }}
                            value={filter ? filter.value : "all"}
                          >
                            <option value="all">Show All</option>
                            {options}
                          </select>
                        )
                      }
                    },
                    {
                      Header: "Created",
                      accessor: "createddatetime",
                      width: 175,
                      show: !this.props.narrativeId,
                      Cell: row => (
                        <div>
                          <TimeAgo
                            date={row.original.createddatetime}
                            title={Helpers.prettyDateTimeinPacificTimeZone(
                              row.original.createddatetime
                            )}
                          />
                        </div>
                      ),
                      filterMethod: (filter, row) => {
                        if (filter.value === "all") {
                          return true
                        }
                        let futureTime = new Date()
                        let pastTime = new Date()
                        let createdOn = new Date(row.createddatetime)
                        if (filter.value === "within7days") {
                          return (
                            createdOn >
                              pastTime.setHours(pastTime.getHours() - 24 * 7) &&
                            createdOn <
                              futureTime.setHours(
                                futureTime.getHours() + 24 * 7
                              )
                          )
                        }
                        if (filter.value === "within24hours") {
                          return (
                            createdOn >
                              pastTime.setHours(pastTime.getHours() - 24) &&
                            createdOn <
                              futureTime.setHours(futureTime.getHours() + 24)
                          )
                        }
                        if (filter.value === "within1hour") {
                          return (
                            createdOn >
                              pastTime.setHours(pastTime.getHours() - 1) &&
                            createdOn <
                              futureTime.setHours(futureTime.getHours() + 1)
                          )
                        }
                      },
                      Filter: ({ filter, onChange }) => (
                        <select
                          onChange={event => onChange(event.target.value)}
                          style={{ width: "100%" }}
                          value={filter ? filter.value : "all"}
                        >
                          <option value="all">Show All</option>
                          <option value="within7days">Within 7 days</option>
                          <option value="within24hours">Within 24 Hours</option>
                          <option value="within1hour">Within 1 Hour</option>
                        </select>
                      )
                    },
                    {
                      Header: "Modified",
                      accessor: "modifieddatetime",
                      width: 175,
                      Cell: row => (
                        <div>
                          <TimeAgo
                            date={row.original.modifieddatetime}
                            title={Helpers.prettyDateTimeinPacificTimeZone(
                              row.original.modifieddatetime
                            )}
                          />
                        </div>
                      ),
                      filterMethod: (filter, row) => {
                        if (filter.value === "all") {
                          return true
                        }
                        let futureTime = new Date()
                        let pastTime = new Date()
                        let createdOn = new Date(row.modifieddatetime)
                        if (filter.value === "within7days") {
                          return (
                            createdOn >
                              pastTime.setHours(pastTime.getHours() - 24 * 7) &&
                            createdOn <
                              futureTime.setHours(
                                futureTime.getHours() + 24 * 7
                              )
                          )
                        }
                        if (filter.value === "within24hours") {
                          return (
                            createdOn >
                              pastTime.setHours(pastTime.getHours() - 24) &&
                            createdOn <
                              futureTime.setHours(futureTime.getHours() + 24)
                          )
                        }
                        if (filter.value === "within1hour") {
                          return (
                            createdOn >
                              pastTime.setHours(pastTime.getHours() - 1) &&
                            createdOn <
                              futureTime.setHours(futureTime.getHours() + 1)
                          )
                        }
                      },
                      Filter: ({ filter, onChange }) => (
                        <select
                          onChange={event => onChange(event.target.value)}
                          style={{ width: "100%" }}
                          value={filter ? filter.value : "all"}
                        >
                          <option value="all">Show All</option>
                          <option value="within7days">Within 7 days</option>
                          <option value="within24hours">Within 24 Hours</option>
                          <option value="within1hour">Within 1 Hour</option>
                        </select>
                      )
                    }
                  ]}
                  filterable={true}
                  pageSize={this.state.isLoadingReviewItems ? 5 : pageSize}
                  onPageSizeChange={(pageSize, page) => {
                    this.rememberChanges(pageSize, page)
                  }}
                  showPaginationTop={false}
                  showPaginationBottom={true}
                  className="-striped -highlight"
                >
                  {(state, makeTable) => {
                    let recordsInfoText = ""
                    const { filtered, pageRows, pageSize, sortedData, page } =
                      state

                    if (sortedData && sortedData.length > 0) {
                      let isFiltered = filtered.length > 0

                      let totalRecords = sortedData.length

                      let recordsCountFrom = page * pageSize + 1

                      let recordsCountTo =
                        recordsCountFrom + pageRows.length - 1

                      if (isFiltered) {
                        recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered inReview`
                      } else {
                        recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} inReview`
                      }
                    } else {
                      recordsInfoText = this.state.isLoadingReviewItems
                        ? `Searching for ${this.props.organization.name} items marked for review...`
                        : `${this.props.organization.name} has nothing marked for review`
                    }
                    return (
                      <div className="main-grid">
                        <div className="above-table text-right">
                          <div className="col-sm-12">
                            <span className="records-info">
                              {recordsInfoText}
                              <Tooltip title="Reload">
                                <Button
                                  justIcon
                                  round
                                  simple
                                  color="info"
                                  className="info"
                                  id="reload"
                                  onClick={() => {
                                    this.loadReviewItems(
                                      this.props.organizationId
                                    )
                                  }}
                                >
                                  {this.state.isLoadingReviewItems ? (
                                    <CircularProgress thickness={1} size={30} />
                                  ) : (
                                    <Loop />
                                  )}
                                </Button>
                              </Tooltip>
                            </span>
                          </div>
                        </div>
                        {makeTable()}
                      </div>
                    )
                  }}
                </ReactTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(useStore(InReview))
