import React from "react"

import CustomDSDialog from "components/CustomDialogs/CustomDSDialog"
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline"
import InfoOutlined from "@mui/icons-material/InfoOutlined"
import useSuccessMessage from "components/NarrativeAdmin/LibraryCopyTools/SuccessMessage"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles(() => ({
  successModal: {
    position: "relative",
    width: "auto",
    backgroundColor: "#fff",
    padding: "5px 5px",
    maxHeight: "90vh",
    textAlign: "left",
    "& h3": {
      fontWeight: "bold"
    },
    "& ul": {
      fontWeight: "bold"
    }
  },
  successH2: {
    textAlign: "center"
  },
  modalWrap: {
    width: "80vw"
  }
}))

const LibraryCopySuccessModal = ({
  copiedElements,
  numEntitiesBeingCopied,
  triggerChanges,
  withAssociatedTriggers,
  onCloseAction,
  onSyncTriggers,
  targetNarrative,
  ...rest
}) => {
  const classes = useStyles()

  const [associateTriggerInfo, setAssociateTriggerInfo] = React.useState(false)
  const [successDialog, setSuccessDialog] = React.useState(true)
  const [targetLoaded, setTargetLoaded] = React.useState(false)

  const didWeSaveTriggers = () => {
    let foundTriggers = false
    copiedElements?.forEach(itm => {
      foundTriggers = itm.type === "Copy Trigger" ? true : foundTriggers
    })
    return foundTriggers
  }

  React.useEffect(() => {
    if (targetNarrative?.id > 0 && targetLoaded === false) {
      setTargetLoaded(true)
    } else if (!targetNarrative?.id && targetLoaded === true) {
      setTargetLoaded(false)
    }
  }, [targetNarrative])

  return (
    <>
      {successDialog && (
        <CustomDSDialog
          {...rest}
          setMaxWidth={"md"}
          content={useSuccessMessage(
            copiedElements,
            withAssociatedTriggers,
            numEntitiesBeingCopied,
            triggerChanges,
            classes
          )}
          icon={<CheckCircleOutline style={{ fontSize: "4rem" }} />}
          backgroundColor={"#66BB6A"}
          alternateWidth={true}
          onClose={() => {
            if (didWeSaveTriggers() && triggerChanges?.length > 0) {
              setAssociateTriggerInfo(true)
              setSuccessDialog(false)
            } else {
              onCloseAction()
            }
          }}
        />
      )}
      <CustomDSDialog
        open={associateTriggerInfo && targetLoaded}
        content={
          "Do you want to associate copied Content Blocks with new Triggers Keys and Trigger IDs?"
        }
        icon={<InfoOutlined style={{ fontSize: "4rem" }} />}
        buttonProps={[
          {
            label: "No",
            onClick: () => {
              setAssociateTriggerInfo(false)
              onCloseAction()
            }
          },
          {
            label: "Yes",
            color: "primary",
            onClick: () => {
              onSyncTriggers(copiedElements)
            }
          }
        ]}
      />
    </>
  )
}

export default LibraryCopySuccessModal
