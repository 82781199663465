import React, { useState } from "react"
import { useObserver } from "mobx-react"
import { Link } from "react-router-dom"
import { useStore } from "contexts/rootContext"
import makeStyles from "@mui/styles/makeStyles"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link as LinkButton
} from "@mui/material"
import narrativeDashboardStyles from "assets/jss/material-dashboard-pro-react/views/narrativeDashboardStyles"

import { SettingsOutlined } from "@mui/icons-material"

const useStyles = makeStyles(() => ({
  ...narrativeDashboardStyles,
  root: {
    position: "relative"
  },
  linkButton: {
    "&:hover": {
      cursor: "default!important"
    }
  }
}))

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    narrative: store.narrativeStore.narrative,
    paragraphs: store.narrativeStore.narrative.paragraphs,
    outlineBlocks: store.narrativeStore.outlineBlocks
  }))
}

const NarrativeOutlineHelp = props => {
  const classes = useStyles()
  const { narrative, paragraphs, outlineBlocks } = useStoreData()

  const { feedentryid, changeActiveSource, handleDrawerOpen } = props
  const [openHelp, setOpenHelp] = useState(false)

  const handleHelpOpen = () => {
    setOpenHelp(true)
  }

  const handleHelpClose = () => {
    setOpenHelp(false)
  }

  let helpTitle = "Narrative Outline Help"

  return (
    <div className={classes.root}>
      <IconButton
        title={"Outline Help"}
        className={classes.help}
        onClick={handleHelpOpen}
        size="large"
      >
        <SettingsOutlined />
      </IconButton>
      <Dialog
        open={openHelp}
        onClose={handleHelpClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{helpTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {outlineBlocks &&
              (outlineBlocks.size === 0 || outlineBlocks.length === 0) &&
              paragraphs &&
              paragraphs.length === 0 && (
                <div>
                  <h3 className={classes.cardIconTitle}>Create Your Outline</h3>
                  <h4 className={classes.cardIconTitle2}>
                    This narrative has no outline.
                  </h4>
                  <p>Start adding blocks to your outline.</p>
                </div>
              )}
            {outlineBlocks &&
              (outlineBlocks.size === 0 || outlineBlocks.length === 0) &&
              paragraphs &&
              paragraphs.length > 0 && (
                <div>
                  <h3 className={classes.cardIconTitle}>
                    No Outline Available
                  </h3>
                  <h4 className={classes.cardIconTitle2}>
                    This narrative contains paragraphs but follows no outline.
                  </h4>
                  <p>
                    If you would like to create an outline for this narrative
                    you can do so in the following ways
                    <ol>
                      <li>
                        Based off of{" "}
                        <LinkButton
                          className={classes.linkButton}
                          onClick={() => changeActiveSource("blockoutline")}
                          title="Derive From Paragraph Block"
                        >
                          Paragraphs Blocks
                        </LinkButton>
                      </li>
                      <li>
                        Based off of{" "}
                        <LinkButton
                          className={classes.linkButton}
                          onClick={() => changeActiveSource("snapshots")}
                          title="Derive Outline From Snaphots"
                        >
                          Rendered Block Snapshots
                        </LinkButton>
                      </li>
                      <li>
                        Based off of a{" "}
                        {feedentryid && (
                          <Link
                            className={classes.linkButton}
                            to={{
                              pathname: `/portal/narrative/${narrative.id}/output`,
                              state: {
                                narrative_name: `${narrative.name}`
                              }
                            }}
                            title="View Output"
                          >
                            Feed Entry
                          </Link>
                        )}
                        {!feedentryid && (
                          <LinkButton
                            className={classes.linkButton}
                            title="Derive Outline From Feed Entry"
                            onClick={() => changeActiveSource("feedentry")}
                          >
                            Load Feed Entry
                          </LinkButton>
                        )}
                      </li>
                      <li>
                        <LinkButton
                          className={classes.linkButton}
                          onClick={handleDrawerOpen}
                          title="Derive Outline From Snaphots"
                        >
                          Create By Hand
                        </LinkButton>
                      </li>
                    </ol>
                  </p>
                </div>
              )}
            {outlineBlocks &&
              !(outlineBlocks.size === 0 || outlineBlocks.length === 0) && (
                <div>
                  {outlineBlocks.length > 1 && (
                    <h3 className={classes.cardIconTitle}>
                      Viewing {outlineBlocks.length} Outline Blocks
                    </h3>
                  )}
                  {outlineBlocks.length === 1 && (
                    <h3 className={classes.cardIconTitle}>
                      Viewing {outlineBlocks.length} Outline Block
                    </h3>
                  )}
                  <h4 className={classes.cardIconTitle2}>
                    This narrative contains {outlineBlocks.length} outline
                    blocks{" "}
                    {(narrative &&
                      narrative.paragraphs &&
                      narrative.paragraphs.length) ||
                      0}{" "}
                    paragraphs.
                  </h4>
                  <p>
                    If you would like to add more outline blocks to this
                    narrative you can do so in the following ways
                    <ol>
                      <li>
                        Based off of{" "}
                        <LinkButton
                          className={classes.linkButton}
                          onClick={() => changeActiveSource("blockoutline")}
                          title="Derive From Paragraph Block"
                        >
                          Paragraphs Blocks
                        </LinkButton>
                      </li>
                      <li>
                        Based off of{" "}
                        <LinkButton
                          className={classes.linkButton}
                          onClick={() => changeActiveSource("snapshots")}
                          title="Derive Outline From Snaphots"
                        >
                          Rendered Block Snapshots
                        </LinkButton>
                      </li>
                      <li>
                        Based off of a{" "}
                        <Link
                          className={classes.linkButton}
                          to={{
                            pathname: `/portal/narrative/${narrative.id}/output`,
                            state: {
                              narrative_name: `${narrative.name}`
                            }
                          }}
                          title="View Output"
                        >
                          Feed Entry
                        </Link>
                      </li>
                      <li>
                        <LinkButton
                          className={classes.linkButton}
                          onClick={handleDrawerOpen}
                          title="Derive Outline From Snaphots"
                        >
                          Create By Hand
                        </LinkButton>
                      </li>
                    </ol>
                  </p>
                </div>
              )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleHelpClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default NarrativeOutlineHelp
