import React from "react"
import withStyles from "@mui/styles/withStyles"
import Dialog from "@mui/material/Dialog"
import MuiDialogTitle from "@mui/material/DialogTitle"
import MuiDialogContent from "@mui/material/DialogContent"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import Typography from "@mui/material/Typography"
import Info from "@mui/icons-material/Info"
import Dot from "@mui/icons-material/FiberManualRecord"
import { ListItem } from "@mui/material"
import { makeStyles } from "@mui/styles"

const styles = theme => ({
  root: {
    margin: 2,
    padding: theme.spacing(2),
    textAlign: "left",
    color: "#000"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
})

const useStyles = makeStyles({
  paragraphTitle: {
    fontSize: "1.25em",
    fontWeight: "bold",
    marginTop: ".5em"
  },
  paragraph: {
    marginLeft: "9px",
    fontWeight: 400,
    lineHeight: 1.75,
    marginBottom: "9px"
  },
  dot: {
    fontSize: "10px",
    marginRight: "10px"
  },
  searchButton: {
    padding: 0
  }
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent)

export default function NarrativeOutlineHierarchyModal() {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const classes = useStyles()

  return (
    <>
      <IconButton
        className={classes.searchButton}
        onClick={handleClickOpen}
        size="large"
      >
        <Info style={{ color: "#4d9ab5", fontSize: "1.2rem" }} />
      </IconButton>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Outline Hierarchy Definitions
          <Typography
            style={{
              marginTop: "10px",
              textAlign: "justify"
            }}
          >
            Since the Outline is meant to be a higher level view there are
            differences to hierarchy and views as compared to the experience in
            Advanced Editor.
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Typography className={classes.paragraph}>
            <strong>Paragraphs</strong> are content blocks that are associated
            to an outline block and hold sentences and Library Position Groups
            underneath.
          </Typography>
          <Typography className={classes.paragraph}>
            <strong>Sentences</strong> within the outline, are individually
            created (i.e. non-slotted) sentences that are associated under a
            paragraph.
          </Typography>
          <Typography className={classes.paragraph}>
            <strong>Library Position Groups</strong> represent a family of
            sentences slotted from a library and grouped by position
          </Typography>
          <ListItem>
            <Dot className={classes.dot} /> Sentences from the library will not
            be shown underneath Library Position Groups in outline, but can be
            found in advanced editor
          </ListItem>
        </DialogContent>
      </Dialog>
    </>
  )
}
