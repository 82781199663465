import React, { useState, useEffect } from "react"
import withStyles from "@mui/styles/withStyles"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"

import { globalSettings } from "variables/general.jsx"
import LinearProgress from "@mui/material/LinearProgress"
import SplitterLayout from "react-splitter-layout"
import { standardMargin } from "./NarrativeEditor"
import CTAsExplorer from "components/NarrativeAdmin/Data/CTAsExplorer"
import CTAEditor from "components/CTAEditor/CTAEditor"
import Typography from "@mui/material/Typography"
import CTApreviewDependencyEditor from "components/CTAEditor/CTAPreviewDependencyEditor"
import queryString from "query-string"
import Modal from "@mui/material/Modal"
import { useHistory } from "react-router-dom"
import { get } from "tools/request"

const styles = theme => ({
  root: {
    display: "block",
    position: "relative",
    height: "calc(200vh - 133px)",
    "& .highlighted": {
      backgroundColor: "#ffeaa7",
      color: "#e17055",
      boxShadow: "-3px 0 0 #ffeaa7, 3px 0 0 #ffeaa7" // Creates an illusion of a wider span, while not affecting the actual width.
    }
  },
  toolbar: {
    display: "flex",
    minHeight: "48px",
    padding: "0px 15px"
  },
  toggleHeader: {
    margin: "0 15px",
    padding: "10px 0!important",
    display: "flex"
  },
  toggleHeaderTitle: {
    flexGrow: 1
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    padding: "0px",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  content: {
    flexGrow: 2,
    background: "#fff"
  },
  appBar: {
    border: "1px solid #ddd",
    zIndex: "5",
    padding: theme.spacing(2)
  },
  explorerDrawer: {
    background: "#fff",
    border: "1px solid rgb(212, 212, 212)",
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
    "& > div": {
      flex: 1
    }
  },
  renderScheduleModalTop: {
    height: "80%"
  },
  renderScheduleModal: {
    width: "60vw",
    margin: "0 auto"
  },
  search: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  searchIcon: {
    padding: "6px",
    backgroundColor: "#fff"
  },
  inputRoot: {
    color: "inherit"
  },
  selectInput: {
    margin: "5px",
    paddingLeft: "10px",
    flexGrow: 2,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%"
    }
  },
  inputInput: {
    margin: "5px",
    paddingLeft: "10px",
    flexGrow: 2,
    transition: theme.transitions.create("width"),
    width: "100%",
    backgroundColor: "#efefef",
    [theme.breakpoints.up("md")]: {
      width: "100%"
    }
  },
  iconButton: {
    padding: "6px"
  },
  cardIconTitle: {
    color: "#000000"
  },
  scheduleOutputModal: {
    width: "60vw",
    height: "62%",
    minHeight: "700px",
    margin: "13vh auto"
  },
  alertMessageModal: {
    width: "40vw",
    height: "200px",
    margin: "34vh auto"
  },
  tabs: {
    borderBottom: "none!important",
    backgroundColor: "#fff",
    "& > .nav-item .nav-link": {
      cursor: "pointer"
    },
    "& > .nav-item .nav-link:hover": {
      backgroundColor: "rgba(0,0,0, 0.2)"
    },
    "& > .nav-item .nav-link.active": {
      backgroundColor: "#efefef",
      borderColor: "#dee2e6 #dee2e6 transparent"
    },
    "& .nav-tabs > .nav-tabs .nav-link.active": {
      backgroundColor: "#fff",
      borderColor: "#dee2e6 #dee2e6 #fff"
    },
    "& > .nav-item .nav-link.activeStarter": {
      backgroundColor: "#ddd",
      borderColor: "#dee2e6 #dee2e6 transparent"
    },
    "& .nav-tabs > .nav-tabs .nav-link.activeStarter": {
      backgroundColor: "#eee",
      borderColor: "#dee2e6 #dee2e6 #fff"
    }
  },
  cronBuilder: {
    width: "34vw",
    margin: "21vh auto",
    "& .cron-builder": {
      backgroundColor: "#eee"
    },
    "& .cron-builder__legend": {
      fontSize: "14px"
    },
    "& .cron-builder__tab": {
      margin: "4px"
    },
    "& .Select-control": {
      minWidth: "110px"
    },
    "& .cron-builder__label": {
      color: "#999"
    }
  },
  progressingMsg: {
    color: "#999",
    textAlign: "center",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    margin: standardMargin
  },
  finishedMsg: {
    color: "#069",
    textAlign: "center",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    margin: standardMargin
  },
  failedListMsg: {
    color: "#C00",
    textAlign: "center",
    cursor: "pointer",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    margin: standardMargin
  },
  failedMsg: {
    color: "#C00",
    textAlign: "center",
    cursor: "pointer",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    margin: "12px 15px 0 5px"
  },
  progressBar: {
    width: "40%",
    background: "#eee",
    display: "flex",
    top: "55px",
    right: "20px",
    position: "fixed",
    zIndex: "99999",
    border: "2px solid #036",
    borderRadius: "15px",
    padding: "4px"
  },
  progressIcon: {
    color: "#000",
    border: "2px solid",
    borderRadius: "20px",
    height: "30px",
    _marginTop: "5px"
  },
  errorModal: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #C00",
    color: "#C00",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "45%",
    left: "45%",
    transform: "translate(-45%, -45%)",
    "& > p": {}
  },

  variationScoreHolder: {
    display: "inline-flex"
  },
  variationScoreRadio: {
    display: "inline-flex",
    alignItems: "center",
    "& .MuiRadio-root": {
      padding: 0,
      margin: "0 5px"
    }
  }
})

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    sortedLeaguesById: store.sportsStore.sortedLeaguesById,
    retrieveLeagues: store.sportsStore.retrieveLeagues,
    leaguesLoading: store.sportsStore.isLoading
  }))
}

function CTAsManager(props) {
  const history = useHistory()
  const { classes } = props
  const [hierarchyCTAs, setHierarchyCTAs] = useState({})
  const [companies, setCompanies] = useState([])
  const [campaigns, setCampaigns] = useState([])
  const [affiliates, setAffiliates] = useState([])
  const [contracts, setContracts] = useState([])
  const [ctaTypes, setCtaTypes] = useState([])

  const [orgs, setOrgs] = useState([])
  const [filteredOrgs, setFilteredOrgs] = useState([])
  const [contentCTAs, setContentCTAs] = useState({})
  const [callToAction, setCallToAction] = useState([])
  const [callToActionAffiliate, setCallToActionAffiliate] = useState([])
  const [
    callToActionAffiliateOrganization,
    setCallToActionAffiliateOrganization
  ] = useState([])
  const [callToActionAffiliateContract, setCallToActionAffiliateContract] =
    useState([])
  const [loadFailed, setLoadFailed] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")

  const [callToActionTag, setCallToActionTag] = useState([])

  const [callToActionRegion, setCallToActionRegion] = useState([])
  const [callToActionCampaign, setCallToActionCampaign] = useState([])
  const [callToActionCompany, setCallToActionCompany] = useState([])
  const [callToActionType, setCallToActionType] = useState([])
  const [callToActionPreviewDependency, setCallToActionPreviewDependency] =
    useState({})
  const [organizationContractAffiliate, setOrganizationContractAffiliate] =
    useState([])

  const [loadingNarrative, setLoadingNarrative] = useState(false)
  const [createCTAs, setCreateCTAs] = useState(false)
  const [narrativeLoaded, setNarrativeLoaded] = useState(false)

  const [orgsLoading, setOrgsLoading] = useState(false)
  const [narrativeExplorerWidth, setNarrativeExplorerWidth] = useState(78)

  const [showPreviewDependency, setShowPreviewDependency] = useState(false)
  const [showCTAs, setShowCTAs] = useState(false)
  const [clearCtaForm, setClearCtaForm] = useState({})

  const [orgId, setOrgId] = useState(0)
  const [leagueId, setLeagueId] = useState(0)
  const [ctaInfo, setCtaInfo] = useState({})
  const [tags, setTags] = useState({})

  const [accountInfo, setAccountInfo] = useState([])
  const [regions, setRegions] = useState([])
  const [defaultExpandedTree, setDefaultExpandedTree] = useState([])
  const [initialLoadedCTA, setInitialLoadedCTA] = useState(false)
  const [explorerLoading, setExplorerLoading] = useState(false)

  const {
    sortedLeaguesById: leaguesFromStore,
    leaguesLoading,
    retrieveLeagues
  } = useStoreData()

  const loadToCheckForLinkToCTA = params => {
    let cta = []
    Object.keys(hierarchyCTAs).forEach(key => {
      Object.keys(hierarchyCTAs[key]).forEach(key2 => {
        hierarchyCTAs[key][key2].forEach(itm => {
          if (Number(itm.id) === Number(params["ctaid"])) {
            cta.push(itm)
          }
        })
      })
    })

    if (cta[0]) {
      if (!showCTAs) {
        setShowCTAs(true)
      }
      setCTAs(cta[0])
      setInitialLoadedCTA(true)
    } else {
      history.push(`/portal/ctas`)
      setInitialLoadedCTA(true)
    }
  }

  const loadTags = () => {
    get(`${globalSettings.apiBaseUrl}/api/tag/type/3/`)
      .then(function (response) {
        if (response.ok) {
          return response.json()
        }
      })
      .then(response => {
        if (typeof response === "undefined") {
          setLoadFailed(true)
          setSuccessMessage(
            'The call "Tags" failed, the page can not load properly'
          )
          return
        }
        let tags = [
          {
            index: 0,
            id: undefined,
            name: ""
          }
        ]
        response.content &&
          response.content.forEach((itm, index) => {
            tags.push({
              index: index + 1,
              id: itm.id,
              name: itm.name
            })
          })
        setTags(tags)
        maynardAPIs.forEach(itm => {
          loadMaynard(itm)
        })
      })
      .catch(err => {
        console.error(`Error on GET to /api/tag/type/3`, err)
      })
  }

  const loadAccounts = () => {
    get(`${globalSettings.apiBaseUrl}/api/account/`)
      .then(function (response) {
        if (response.ok) {
          return response.json()
        }
      })
      .then(response => {
        if (typeof response === "undefined") {
          setLoadFailed(true)
          setSuccessMessage(
            'The call "Accounts" failed, the page can not load properly'
          )
          return
        }
        if (response.content === null) {
        } else {
          let accountsForTable = response.content
            .filter(itm => itm.status === "Active")
            .sort((a, b) => a.username.localeCompare(b.username))
          setAccountInfo(accountsForTable)
          loadLeagues()
        }
      })
      .catch(err => {
        console.error(`Error on GET to /api/account`, err)
      })
  }

  const loadLeagues = async () => {
    try {
      await retrieveLeagues()
    } catch (err) {
      setLoadFailed(true)
      setSuccessMessage(
        'The call "Leagues" failed, the page can not load properly'
      )
      console.error(`Error on GET to /api/sports/league-list`, err)
      return
    }

    loadTags()
  }

  const loadOrganizations = () => {
    setOrgsLoading(true)
    setLoadingNarrative(true)
    get(`${globalSettings.apiBaseUrl}/api/organization/`)
      .then(function (response) {
        if (response.ok) {
          return response.json()
        }
      })
      .then(organizations => {
        if (typeof organizations === "undefined") {
          setLoadFailed(true)
          setSuccessMessage(
            'The call to "Organizations" failed, the page can not load properly'
          )
          return
        }
        organizations.content.forEach(item => {
          item.Status = item.isActive ? "Active" : "Inactive"
          item.name = item.name || "--none--"
        })
        setOrgs(organizations.content)
        loadAccounts()
      })
      .catch(err => {
        console.error(`Error on GET to /api/organization`, err)
      })
  }

  const maynardAPIs = [
    "CallToActionAffiliate",
    "CallToActionAffiliateOrganization",
    "CallToActionAffiliateContract",
    "CallToActionType",
    "CallToActionTag",
    "CallToActionRegion",
    "CallToActionCampaign",
    "CallToActionCompany",
    "CallToActionPreviewDependency/1",
    "CallToAction",
    "Region?countryId=237&childLevel=1"
  ]
  const loadMaynard = apiPath => {
    fetch(`${globalSettings.maynardBaseUrl}/api/${apiPath}`, {
      credentials: "include",
      method: "get",
      headers: {
        "X-API-Key": `${globalSettings.maynardApiKey}`,
        accept: "text/plain"
      }
    })
      .then(function (response) {
        if (response.ok) {
          return response.json()
        }
      })
      .then(resp => {
        if (typeof resp === "undefined") {
          setLoadFailed(true)
          setSuccessMessage(
            `THe Maynard call to "${apiPath}" failed, the page will not properly load`
          )
          return
        }
        switch (apiPath) {
          case "CallToActionAffiliate":
            setCallToActionAffiliate(resp)
            break
          case "CallToAction":
            setCallToAction(resp)
            break
          case "CallToActionType":
            setCallToActionType(resp)
            break
          case "CallToActionTag":
            setCallToActionTag(resp)
            break
          case "CallToActionRegion":
            setCallToActionRegion(resp)
            break
          case "CallToActionCampaign":
            setCallToActionCampaign(resp)
            break
          case "CallToActionCompany":
            setCallToActionCompany(resp)
            break
          case "CallToActionPreviewDependency/1":
            setCallToActionPreviewDependency(resp)
            break
          case "CallToActionAffiliateOrganization":
            setCallToActionAffiliateOrganization(resp)
            break
          case "CallToActionAffiliateContract":
            setCallToActionAffiliateContract(resp)
            break
          case "Region?countryId=237&childLevel=1":
            setRegions(resp)
            break
          default:
            break
        }
      })
      .catch(err => {
        console.error(`Error on GET to /api/${apiPath}`, err)
      })
  }
  if (
    !orgsLoading &&
    !leaguesLoading &&
    !loadingNarrative &&
    !narrativeLoaded
  ) {
    loadOrganizations()
  }
  const onNarrativeExplorerWidthChange = newWidth => {
    setNarrativeExplorerWidth(newWidth)
  }

  const convertApiFormatToWebFormat = (obj, orgId) => {
    let itm = {}
    itm.organizationId = orgId
    Object.keys(obj).forEach(itmKey => {
      // Items with different names in form (itm) vs PUT payload (obj)
      if (itmKey === "tagIds") {
        itm["tagId"] = Number(obj["tagIds"].join())
      } else if (itmKey === "callToActionName") {
        itm["name"] = obj[itmKey]
      } else if (itmKey === "callToActionPayOff") {
        itm["payout"] = obj[itmKey]
      } else if (itmKey === "status") {
        itm["statusId"] = obj["status"]
      } else if (itmKey === "region") {
        obj["region"].forEach(itm2 => {
          if (itm2.regionId) {
            itm2.id = itm2.regionId
          }
        })
        itm.region = obj["region"]
      } else if (itmKey === "regions") {
        obj["regions"].forEach(itm2 => {
          if (itm2.regionId) {
            itm2.id = itm2.regionId
          }
        })
        itm.region = obj["regions"]
      } else if (obj[itmKey]) {
        itm[itmKey] = obj[itmKey]
      }
    })
    return itm
  }

  const leagues = leaguesFromStore.map((league, index) => ({
    index,
    id: league.id,
    name: league.name,
    acronym: league.acronym || league.name
  }))

  const updateHierarchyCTAs = (obj, orgId, id) => {
    let found = false
    let newEntry = false
    let newLeagueEntry = false
    let newOrganizationEntry = false
    let newHierarchyCTAs = { ...hierarchyCTAs }
    hierarchyCTAs &&
      Object.keys(hierarchyCTAs).forEach(key => {
        Object.keys(hierarchyCTAs[key]).forEach(league => {
          hierarchyCTAs[key][league].forEach(itm => {
            if (
              itm.leagueId === obj.leagueId &&
              itm.organizationId === orgId &&
              itm.id === id
            ) {
              found = true
              obj.organizationId = orgId
              Object.keys(itm).forEach(itmKey => {
                // Items with different names in form (itm) vs PUT payload (obj)
                if (itmKey === "tagId" && obj["tagIds"]) {
                  itm[itmKey] = Number(obj["tagIds"].join())
                } else if (itmKey === "region" && obj["regions"]) {
                  let rgn = []
                  obj["regions"].forEach(itmx => {
                    rgn.push({
                      id: itmx.regionId,
                      callToActionPayOff: itmx.callToActionPayOff,
                      isConversionPercentPayOut: itmx.isConversionPercentPayOut,
                      payOutException: itmx.payOutException,
                      indexId: itmx.id
                    })
                  })
                  itm[itmKey] = rgn
                } else if (itmKey === "endDate" && obj["endDate"]) {
                  itm[itmKey] = obj[itmKey]
                } else if (itmKey === "startDate" && obj["startDate"]) {
                  itm[itmKey] = obj[itmKey]
                } else if (itmKey === "assetLink") {
                  itm[itmKey] = obj[itmKey]
                } else if (itmKey === "promoText") {
                  itm[itmKey] = obj[itmKey]
                } else if (obj[itmKey]) {
                  itm[itmKey] = obj[itmKey]
                } else if (itmKey === "name" && obj["callToActionName"]) {
                  itm[itmKey] = obj["callToActionName"]
                } else if (
                  itmKey === "payout" &&
                  (obj["callToActionPayOff"] || obj["callToActionPayOff"] === 0)
                ) {
                  itm[itmKey] = obj["callToActionPayOff"]
                } else if (itmKey === "statusId" && obj["status"]) {
                  itm[itmKey] = obj["status"]
                } else if (itmKey === "ownerAccountId" && obj["owner"]) {
                  itm[itmKey] = obj["owner"]
                } else if (
                  itmKey === "callToActionCompanyId" &&
                  obj["companyId"]
                ) {
                  itm[itmKey] = obj["companyId"]
                } else if (
                  itmKey === "prioritizationOverride" &&
                  (obj["prioritizationOverride"] ||
                    obj["prioritizationOverride"] === 0)
                ) {
                  itm[itmKey] = obj["prioritizationOverride"]
                }
              })
            } else if (
              itm.leagueId === obj.leagueId &&
              itm.organizationId === orgId
            ) {
              newEntry = [key, league]
            } else if (itm.organizationId === orgId) {
              newLeagueEntry = [key]
            } else {
              newOrganizationEntry = true
            }
          })
        })
      })
    if (!found) {
      let orgOfCTA = {}
      let orgOfCTAchange = false
      if (newEntry) {
        let itm = convertApiFormatToWebFormat(obj)
        newHierarchyCTAs[newEntry[0]][newEntry[1]].push(itm)
        setCtaObject(itm)
      } else if (newLeagueEntry) {
        newHierarchyCTAs[newLeagueEntry[0]] =
          newHierarchyCTAs[newLeagueEntry[0]] || {}
        const lgx = leagues.filter(itm => itm.id === obj.leagueId)
        obj.leagueName = lgx[0].acronym
        obj.organizationName = newLeagueEntry[0]
        obj.organizationId = orgId
        let itm = convertApiFormatToWebFormat(obj)
        newHierarchyCTAs[newLeagueEntry[0]][lgx[0].acronym] = [itm]
        setCtaObject(itm)
        orgOfCTAchange = true
        orgOfCTA[newLeagueEntry[0]] = orgOfCTA[newLeagueEntry[0]] || {}
        orgOfCTA[newLeagueEntry[0]].id = orgOfCTA[newLeagueEntry[0]].id || orgId
        orgOfCTA[newLeagueEntry[0]][lgx[0].acronym] =
          orgOfCTA[newLeagueEntry[0]][lgx[0].acronym] || {}
        orgOfCTA[newLeagueEntry[0]][lgx[0].acronym].id =
          orgOfCTA[newLeagueEntry[0]][lgx[0].acronym].id || obj.leagueId
      } else if (newOrganizationEntry) {
        const orgx = orgs.filter(itm => itm.id === obj.organizationId)
        newHierarchyCTAs[orgx[0].name] = newHierarchyCTAs[orgx[0].name] || {}
        const lgx = leagues.filter(itm => itm.id === obj.leagueId)
        obj.leagueName = lgx[0].acronym
        obj.organizationName = orgx[0].name
        obj.organizationId = orgId
        let itm = convertApiFormatToWebFormat(obj)
        setCtaObject(itm)
        newHierarchyCTAs[orgx[0].name][lgx[0].acronym] = [itm]
        orgOfCTAchange = true
        orgOfCTA[orgx[0].name] = orgOfCTA[orgx[0].name] || {}
        orgOfCTA[orgx[0].name].id =
          orgOfCTA[orgx[0].name].id || obj.organizationId
        orgOfCTA[orgx[0].name][lgx[0].acronym] =
          orgOfCTA[orgx[0].name][lgx[0].acronym] || {}
        orgOfCTA[orgx[0].name][lgx[0].acronym].id =
          orgOfCTA[orgx[0].name][lgx[0].acronym].id || obj.leagueId
      }
      if (orgOfCTAchange) {
        const newContentCTAs = {
          ...contentCTAs,
          orgOfCTA
        }
        setContentCTAs(newContentCTAs)
      }
    }
    setHierarchyCTAs(newHierarchyCTAs)
    return found
  }

  const setPreviewDependency = () => {
    setShowPreviewDependency(true)
    setShowCTAs(false)
    setCreateCTAs(false)
    history.push(`/portal/ctas?previewDependency=true`)
  }
  const groupRegionByPayoutFunc = myRegions => {
    let grouped = []
    if (myRegions.length === 1 && myRegions[0].id === 237) {
      //USA,, pass in all 51 regions
      let grp = []
      regions.forEach(itm => {
        itm.payoutPercentage = myRegions[0].payoutPercentage
        grp.push(itm)
      })
      grouped.push(grp)
    } else {
      myRegions.forEach(itm => {
        if (!grouped.length) {
          grouped.push([itm])
        } else {
          let groupExists = false
          grouped.forEach(grp => {
            if (
              !groupExists &&
              grp[0].payoutPercentage === itm.payoutPercentage
            ) {
              grp.push(itm)
              groupExists = true
            }
          })
          if (!groupExists) {
            grouped.push([itm])
          }
        }
      })
    }
    if (grouped.length < 2) {
      grouped.push([
        {
          index: grouped.length,
          regionId: 0,
          payoutPercentage: 0,
          display: true,
          isNew: true,
          payoutException: true
        }
      ])
    } else {
      grouped.sort(function (a, b) {
        //Need to make sure the Group with NO exceptions is FIRST
        let left =
          a[0] && a[0].payoutException === false
            ? 1
            : a[0] && a[0].payoutException === true
            ? -1
            : 0
        let right =
          b[0] && b[0].payoutException === false
            ? 1
            : b[0] && b[0].payoutException === true
            ? -1
            : 0
        return right - left
      })
    }
    return grouped
  }

  const setCampaignAndOptions = (campaignCollection, newFormValues) => {
    setCampaigns(campaignCollection)
    const newFormOptions = {
      ...ctaInfo.formOptions,
      campaigns: campaignCollection
    }
    const newItem = {
      ...ctaInfo,
      formValues: newFormValues,
      formOptions: newFormOptions
    }

    setCtaInfo(newItem)
  }

  const setCtaObject = (itm, create, update) => {
    let regionJustId = []
    let isConversionPercentPayOut
    itm.region &&
      itm.region.forEach(itm => {
        regionJustId.push(itm.id)
        isConversionPercentPayOut = itm.isConversionPercentPayOut
      })
    let regionsAllParams = []
    itm.region &&
      itm.region.forEach((itm, i) => {
        regionsAllParams.push({
          id: itm.id,
          payoutPercentage: itm.callToActionPayOff,
          payoutException: itm.payOutException,
          indexId: itm.indexId,
          index: i
        })
      })
    let isUSA = regionsAllParams.filter(itm => itm.id === 237)
    const regionPayoutGroup = groupRegionByPayoutFunc(regionsAllParams)
    const payoutExceptionsExist =
      regionPayoutGroup[1] &&
      regionPayoutGroup[1][regionPayoutGroup[1].length - 1] &&
      regionPayoutGroup[1][regionPayoutGroup[1].length - 1].payoutException ===
        true &&
      regionPayoutGroup[1][regionPayoutGroup[1].length - 1].id
        ? true
        : false
    if (regionsAllParams.length === 1 && isUSA.length) {
      let tmp = []
      regions.forEach(rgn => {
        rgn.payoutPercentage = regionsAllParams[0].payoutPercentage
        tmp.push(rgn)
      })
      regionsAllParams = tmp
    } else if (isUSA.length) {
      let tmp = []
      regions.forEach(rgn => {
        let rgnMatch = regionsAllParams.filter(itm => itm.id === rgn.id)
        if (rgnMatch.length) {
          rgn.indexId = rgnMatch[0].indexId
          rgn.payoutPercentage = rgnMatch[0].payoutPercentage
          tmp.push(rgn)
        } else {
          rgn.payoutPercentage = isUSA[0].payoutPercentage
          tmp.push(rgn)
        }
      })
      regionsAllParams = tmp
    }
    const justPayoutsGrouped = []
    regionPayoutGroup.forEach(itm => {
      if (itm[0] && typeof itm[0].payoutPercentage === "number") {
        justPayoutsGrouped.push(itm[0].payoutPercentage)
      }
    })

    const formValues = update
      ? {
          ...itm,
          region: regionJustId || [],
          status: itm.statusId
        }
      : !create
      ? {
          ctaId: itm.id,
          organizationId: itm.organizationId,
          organizationName: itm.organizationName,
          leagueId: itm.leagueId,
          leagueName: itm.leagueName,
          formFactor: itm.tagId,
          region: regionJustId || [],
          regionsAllParams: regionsAllParams,
          campaignName: itm.callToActionCampaignName || "",
          callToActionCampaignId: itm.callToActionCampaignId,
          company: itm.callToActionCompanyName || "",
          companyId: itm.callToActionCompanyId || itm.companyId,
          affiliate: itm.callToActionAffiliateOrganizationName || "",
          callToActionAffiliateId:
            organizationContractAffiliate.find(
              obj =>
                obj.affiliateOrganizationId ===
                itm.callToActionAffiliateOrganizationId
            )?.affiliateId || null,
          callToActionAffiliateOrganizationId:
            itm.callToActionAffiliateOrganizationId,
          callToActionPayOff: itm.payout || itm.payout === 0 ? itm.payout : "",
          prioritizationOverride:
            itm.prioritizationOverride || itm.prioritizationOverride === 0
              ? itm.prioritizationOverride
              : "",
          callToActionName: itm.name || "",
          callToActionTypeId: itm.callToActionTypeId,
          affiliateLink: itm.affiliateLink || "",
          assetLink: itm.assetLink || "",
          promoText: itm.promoText || "",
          callToActionText: itm.callToActionText || "",
          owner: itm.ownerAccountId,
          status: itm.statusId,
          startDate: itm.startDate || null,
          endDate: itm.endDate || null,
          isArchived: itm.isArchived || false,
          template: itm.template,
          isConversionPercentPayOut,
          payoutException: payoutExceptionsExist,
          groupRegionByPayout: regionPayoutGroup,
          justPayoutsGrouped: justPayoutsGrouped
        }
      : {
          ctaId: null,
          organizationId: itm.organizationId,
          organizationName: itm.organizationName || "",
          leagueId: itm.leagueId,
          leagueName: itm.leagueName || "",
          formFactor: "",
          region: [],
          regionsAllParams: [],
          campaignName: "",
          callToActionCampaignId: "",
          company: "",
          companyId: "",
          affiliate: "",
          callToActionAffiliateOrganizationId: "",
          callToActionAffiliateId: "",
          callToActionPayOff: 0,
          prioritizationOverride: "",
          callToActionName: "",
          callToActionTypeId: "",
          affiliateLink: "",
          assetLink: "",
          promoText: "",
          callToActionText: "",
          owner: null,
          status: 3,
          startDate: itm.startDate,
          endDate: null,
          isArchived: false,
          isConversionPercentPayOut: false,
          template: "",
          payoutException: false,
          groupRegionByPayout: regionPayoutGroup,
          justPayoutsGrouped: justPayoutsGrouped //,
        }

    let formOptions = {
      leagues,
      orgs: filteredOrgs || orgs,
      companies,
      campaigns: campaigns.sort((a, b) => b.id - a.id),
      regions: [
        {
          id: 237,
          name: "USA",
          geoNameAdmin1Code: "USA"
        }
      ].concat(
        regions.sort((a, b) =>
          a.geoNameAdmin1Code.localeCompare(b.geoNameAdmin1Code)
        )
      ),
      affiliates,
      contracts,
      ctaTypes,
      formFactorTags: tags,
      callToActionAffiliateOrganization,
      callToActionAffiliateContract,
      callToActionAffiliate,
      organizationContractAffiliate
    }

    const newItem = {
      ...itm,
      formValues,
      formOptions
    }

    setCtaInfo(newItem)
  }

  const setCTAs = (itm, obj, key, league, toggleVal) => {
    setOrgId(itm.organizationId)
    setLeagueId(itm.leagueId)
    setCtaObject(itm)
    setShowCTAs(true)
    setCreateCTAs(false)
    setShowPreviewDependency(false)
    if (itm) {
      const orgx = orgs.filter(org => org.id === itm.organizationId)
      itm.organizationName = (orgx[0] && orgx[0].name) || "None"
      const lgx = leagues.filter(lg => lg.id === itm.leagueId)
      itm.leagueName = (lgx[0] && lgx[0].acronym) || "None"
      setDefaultExpandedTree([
        "2",
        itm.organizationName,
        `${itm.organizationName}-${itm.leagueName}`
      ])
    }
  }

  useEffect(() => {
    if (!showCTAs) {
      if (clearCtaForm && clearCtaForm.ctaid) {
        loadToCheckForLinkToCTA(clearCtaForm)
        setClearCtaForm({})
      }
    }
  }, [showCTAs])

  useEffect(() => {
    if (
      callToActionAffiliateOrganization.length &&
      !contentCTAs.CallToAction &&
      !contentCTAs.CallToActionAffiliateOrganization
    ) {
      let tmpOrg = []
      callToActionAffiliateOrganization.forEach(itm => {
        tmpOrg.push(itm.organizationId)
      })
      let filtOrgs = orgs.filter(itm => tmpOrg.includes(itm.id))
      setFilteredOrgs(filtOrgs)
      const newContentCTAs = {
        ...contentCTAs,
        CallToActionAffiliateOrganization: callToActionAffiliateOrganization
      }
      setContentCTAs(newContentCTAs)
    } else if (
      callToActionAffiliateContract.length &&
      !contentCTAs.CallToAction &&
      !contentCTAs.CallToActionAffiliateContract
    ) {
      const newContentCTAs = {
        ...contentCTAs,
        CallToActionAffiliateContract: callToActionAffiliateContract
      }
      setContentCTAs(newContentCTAs)
      let cntrcts = []
      callToActionAffiliateContract.forEach(itm2 => {
        cntrcts.push({ id: itm2.id, name: itm2.name })
      })
      setContracts(cntrcts)
    } else if (
      callToAction.length &&
      !contentCTAs.CallToAction &&
      callToActionAffiliateOrganization.length &&
      callToActionAffiliateContract.length &&
      callToActionAffiliate.length &&
      callToActionCampaign.length &&
      callToActionCompany.length &&
      callToActionType.length &&
      callToActionRegion.length &&
      regions.length &&
      organizationContractAffiliate.length &&
      callToActionTag
    ) {
      let hierarchyCTA = {}
      let orgOfCTA = {}
      let oca = []
      callToActionAffiliateOrganization.forEach(itm => {
        let orgId = itm.organizationId
        let contractId = itm.callToActionAffiliateContractId
        callToActionAffiliateContract.forEach(itm2 => {
          if (itm2.id === contractId) {
            oca.push({
              organizationId: orgId,
              contractId: contractId,
              affiliateId: itm2.callToActionaffiliateId,
              affiliateOrganizationId: itm.id
            })
          }
        })
      })
      setOrganizationContractAffiliate(oca)

      callToAction.forEach((itm, index) => {
        let match = leagues.filter(lg => lg.id === itm.leagueId)
        itm.leagueName = match[0].acronym || "none"
        callToActionAffiliateOrganization.forEach(itm2 => {
          if (itm2.id === itm.callToActionAffiliateOrganizationId) {
            itm.organizationId = itm2.organizationId
            itm.contractId = itm2.callToActionAffiliateContractId
          }
        })
        callToActionAffiliateContract.forEach(itm2 => {
          if (itm2.id === itm.contractId) {
            itm.callToActionaffiliateId = itm2.callToActionaffiliateId
          }
        })
        callToActionCampaign.forEach(itm2 => {
          if (itm2.id === itm.callToActionCampaignId) {
            itm.callToActionCampaignName = itm2.name || "No Campaign Name"
          }
        })
        callToActionCompany.forEach(itm2 => {
          if (itm2.id === itm.callToActionCompanyId) {
            itm.callToActionCompanyName = itm2.name || "No Company Name"
          }
        })
        let region = []
        let regionIds = []
        callToActionRegion.forEach(itm2 => {
          if (
            itm2.callToActionId === itm.id &&
            !regionIds.includes(itm2.regionId)
          ) {
            regionIds.push(itm2.regionId)
            region.push({
              id: itm2.regionId,
              callToActionPayOff: itm2.callToActionPayOff,
              isConversionPercentPayOut: itm2.isConversionPercentPayOut,
              payOutException: itm2.payOutException,
              indexId: itm2.id
            })
          }
        })

        callToActionTag.forEach(itm2 => {
          if (itm2.callToActionId === itm.id) {
            itm.tagId = itm2.tagId
          }
        })
        let match2 = orgs.filter(org => org.id === itm.organizationId) || []
        itm.organizationName = (match2[0] && match2[0].name) || "none"
        if (!itm.isArchived) {
          hierarchyCTA[itm.organizationName] =
            hierarchyCTA[itm.organizationName] || {}
          hierarchyCTA[itm.organizationName][itm.leagueName] =
            hierarchyCTA[itm.organizationName][itm.leagueName] || []

          hierarchyCTA[itm.organizationName][itm.leagueName].push({
            url: itm.affiliateLink,
            name: itm.callToActionText,
            template: itm.template,
            startDate: itm.startDate,
            endDate: itm.endDate,
            index: index,
            CTAindex: index,
            tagId: itm.tagId || "",
            region: region || [],
            ...itm,
            payout: itm.callToActionPayOff
          })
          orgOfCTA[itm.organizationName] = orgOfCTA[itm.organizationName] || {}
          orgOfCTA[itm.organizationName].id =
            orgOfCTA[itm.organizationName].id || itm.organizationId

          orgOfCTA[itm.organizationName][itm.leagueName] =
            orgOfCTA[itm.organizationName][itm.leagueName] || {}
          orgOfCTA[itm.organizationName][itm.leagueName].id =
            orgOfCTA[itm.organizationName][itm.leagueName].id || itm.leagueId
        }
      })
      const newContentCTAs = {
        ...contentCTAs,
        CallToAction: callToAction,
        hierarchyCTA,
        orgOfCTA
      }
      setContentCTAs(newContentCTAs)
      setHierarchyCTAs(hierarchyCTA)
      setLoadingNarrative(false)
      setNarrativeLoaded(true)
    } else if (
      callToAction.length &&
      !contentCTAs.CallToAction &&
      callToActionAffiliateOrganization.length &&
      callToActionAffiliateContract.length &&
      organizationContractAffiliate.length === 0
    ) {
      let oca = []
      callToActionAffiliateOrganization.forEach(itm => {
        let orgId = itm.organizationId
        let contractId = itm.callToActionAffiliateContractId
        callToActionAffiliateContract.forEach(itm2 => {
          if (itm2.id === contractId) {
            oca.push({
              organizationId: orgId,
              contractId: contractId,
              affiliateId: itm2.callToActionaffiliateId,
              affiliateOrganizationId: itm.id
            })
          }
        })
      })
      setOrganizationContractAffiliate(oca)
    } else if (callToActionType.length && !contentCTAs.CallToActionType) {
      const newContentCTAs = {
        ...contentCTAs,
        CallToActionType: callToActionType
      }
      setContentCTAs(newContentCTAs)
      let types = []
      callToActionType.forEach(itm2 => {
        types.push({ id: itm2.id, name: itm2.name })
      })
      setCtaTypes(types)
    } else if (
      callToActionTag &&
      callToActionTag.length &&
      !contentCTAs.CallToActionTag
    ) {
      const newContentCTAs = {
        ...contentCTAs,
        CallToActionTag: callToActionTag
      }
      setContentCTAs(newContentCTAs)
    } else if (
      callToActionRegion &&
      callToActionRegion.length &&
      !contentCTAs.CallToActionRegion
    ) {
      const newContentCTAs = {
        ...contentCTAs,
        CallToActionRegion: callToActionRegion
      }
      setContentCTAs(newContentCTAs)
    } else if (callToActionCompany.length && !contentCTAs.CallToActionCompany) {
      const newContentCTAs = {
        ...contentCTAs,
        CallToActionCompany: callToActionCompany
      }
      setContentCTAs(newContentCTAs)
      let cmps = []
      callToActionCompany.forEach(itm2 => {
        cmps.push({ id: itm2.id, name: itm2.name })
      })
      setCompanies(cmps)
    } else if (
      callToActionCampaign.length &&
      !contentCTAs.CallToActionCampaign
    ) {
      const newContentCTAs = {
        ...contentCTAs,
        CallToActionCampaign: callToActionCampaign
      }
      setContentCTAs(newContentCTAs)
      let cmpgs = []
      callToActionCampaign.forEach(itm2 => {
        cmpgs.push({ id: itm2.id, name: itm2.name })
      })
      setCampaigns(cmpgs)
    } else if (
      callToActionAffiliate.length &&
      !contentCTAs.CallToActionAffiliate
    ) {
      const newContentCTAs = {
        ...contentCTAs,
        CallToActionAffiliate: callToActionAffiliate
      }
      setContentCTAs(newContentCTAs)
      let affs = []
      callToActionAffiliate.forEach(itm2 => {
        affs.push({ id: itm2.id, name: itm2.name })
      })
      setAffiliates(affs)
    } else if (
      callToActionPreviewDependency.length &&
      !contentCTAs.CallToActionPreviewDependency
    ) {
      const newContentCTAs = {
        ...contentCTAs,
        CallToActionPreviewDependency: callToActionPreviewDependency
      }
      setContentCTAs(newContentCTAs)
    } else if (Object.keys(hierarchyCTAs).length && contentCTAs.CallToAction) {
      const queryParams = queryString.parse(
        window.location.search.toLowerCase()
      )
      if (queryParams["ctaid"]) {
        loadToCheckForLinkToCTA(queryParams)
      } else if (queryParams["previewdependency"]) {
        setInitialLoadedCTA(true)
        setShowPreviewDependency(true)
      } else {
        setInitialLoadedCTA(true)
      }
    }
  }, [
    callToAction,
    callToActionType,
    callToActionTag,
    callToActionRegion,
    callToActionCampaign,
    callToActionCompany,
    callToActionAffiliate,
    callToActionAffiliateOrganization,
    callToActionAffiliateContract,
    callToActionPreviewDependency,
    regions,
    organizationContractAffiliate,
    hierarchyCTAs,
    initialLoadedCTA,
    explorerLoading
  ])

  const explorerReload = (obj, orgId, partialReload) => {
    setCallToAction([])
    setCallToActionType([])
    setCallToActionTag(null)
    setCallToActionCampaign([])
    setCallToActionRegion([])
    setCallToActionCompany([])
    setCallToActionAffiliate([])
    setCallToActionAffiliateOrganization([])
    setCallToActionPreviewDependency([])
    setRegions([])

    setContentCTAs({})
    if (obj?.id || obj.ctaId) {
      obj.id = obj.id ? obj.id : obj.ctaId
      history.push(`/portal/ctas?ctaId=${obj.id}`)
    } else {
      history.push(`/portal/ctas`)
    }
    maynardAPIs.forEach(itm => {
      loadMaynard(itm)
    })
    if (!partialReload) {
      obj.organizationId = orgId
      const orgx = orgs.filter(itm => itm.id === orgId)
      obj.organizationName = (orgx[0] && orgx[0].name) || "None"
      const lgx = leagues.filter(itm => itm.id === obj.leagueId)
      obj.leagueName = (lgx[0] && lgx[0].acronym) || "None"
      let itm = convertApiFormatToWebFormat(obj)
      setCtaObject(itm)
      setDefaultExpandedTree([
        "2",
        obj.organizationName,
        `${obj.organizationName}-${obj.leagueName}`
      ])
    }
    setHierarchyCTAs({})
  }

  //Code for changing CTA or going to PreviewDependency based on URL
  const queryParams = queryString.parse(window.location.search.toLowerCase())
  if (queryParams["ctaid"] && ctaInfo && ctaInfo.id) {
    if (Number(queryParams["ctaid"]) !== Number(ctaInfo.id)) {
      loadToCheckForLinkToCTA(queryParams)
    } else if (queryParams["previewdependency"]) {
      setShowPreviewDependency(true)
    }
  } else if (createCTAs && queryParams["ctaid"]) {
    loadToCheckForLinkToCTA(queryParams)
  }

  return (
    <div className={classes.root} style={{ backgroundColor: "#efefef" }}>
      <Typography variant="h5" className={classes.cardIconTitle}>
        CTA Manager
      </Typography>
      {loadingNarrative && <LinearProgress />}
      {narrativeLoaded && (
        <div>
          <SplitterLayout
            horizontal
            percentage
            secondaryInitialSize={narrativeExplorerWidth}
            onSecondaryPaneSizeChange={newSize => {
              onNarrativeExplorerWidthChange(newSize)
            }}
          >
            <div className={classes.explorerDrawer}>
              <CTAsExplorer
                narrative={{}}
                loading={loadingNarrative}
                setActiveView={viewKey => {}}
                setCtaCreation={(a, itemOrg, itemLeague) => {
                  history.push(`/portal/ctas`)
                  setShowCTAs(false)
                  setCreateCTAs(true)
                  setShowPreviewDependency(false)
                  setCtaObject(
                    {
                      organizationId: itemOrg,
                      leagueId: itemLeague || "",
                      startDate: new Date().toISOString()
                    },
                    true
                  )
                }}
                ctaInfo={ctaInfo}
                orgs={orgs}
                leagues={leagues}
                showTheCTAs={showCTAs}
                activeView={"activeView"}
                refreshNarrative={() => {}}
                setEditView={(viewKey, change) => {
                  if (viewKey === "Preview Dependency") {
                    setPreviewDependency()
                  }
                }}
                orgOfCTA={contentCTAs.orgOfCTA}
                hierarchyCTAs={hierarchyCTAs}
                initialLoadedCTA={initialLoadedCTA}
                showPreviewDependency={setPreviewDependency}
                toggleCTAs={(itm, obj, key, league, toggleVal) => {
                  setCTAs(itm, obj, key, league, toggleVal)
                }}
                defaultExpandedTree={defaultExpandedTree}
                organizationContractAffiliate={organizationContractAffiliate}
                reload={(obj, orgId, partialReload) => {
                  explorerReload(obj, orgId, partialReload)
                }}
                setExplorerLoading={() => {
                  setExplorerLoading(true)
                }}
              />
            </div>
            <div style={{ border: "1px solid #d4d4d4" }}>
              <div className={classes.content} key="content">
                {showPreviewDependency && (
                  <div>
                    <CTApreviewDependencyEditor
                      callToActionPreviewDependency={
                        callToActionPreviewDependency
                      }
                    />
                  </div>
                )}
                {showCTAs && !createCTAs && (
                  <div>
                    <CTAEditor
                      wer={"showCTAs"}
                      orgs={orgs}
                      leagues={leagues}
                      orgId={orgId}
                      leagueId={leagueId}
                      ctaInfo={ctaInfo}
                      setCallToActionCampaign={setCampaignAndOptions}
                      updateCTAs={(obj, orgId, id) => {
                        updateHierarchyCTAs(obj, orgId, id)
                      }}
                      reload={(obj, orgId) => {
                        explorerReload(obj, orgId)
                      }}
                      accountInfo={accountInfo}
                      cancelFormEditsAndReload={params => {
                        setClearCtaForm(params)
                        setShowCTAs(false)
                      }}
                    />
                  </div>
                )}
                {createCTAs && !showCTAs && (
                  <CTAEditor
                    wer={"createCTAs"}
                    orgs={orgs}
                    leagues={leagues}
                    orgId={orgId}
                    leagueId={leagueId}
                    ctaInfo={ctaInfo}
                    setCallToActionCampaign={setCampaignAndOptions}
                    updateCTAs={(obj, orgId, id) => {
                      updateHierarchyCTAs(obj, orgId, id)
                    }}
                    reload={(obj, orgId) => {
                      explorerReload(obj, orgId)
                    }}
                    accountInfo={accountInfo}
                  />
                )}
              </div>
            </div>
          </SplitterLayout>
        </div>
      )}
      {loadFailed && (
        <Modal
          open={true}
          onClose={() => {
            setLoadFailed(false)
            setLoadingNarrative(false)
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.errorModal}>
            <h2 id="server-modal-title">Failed!</h2>
            <div id="server-modal-description">
              <p>{successMessage}</p>
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default withStyles(styles)(CTAsManager)
