import React, { useContext, useState } from "react"
import { makeStyles } from "@mui/styles"
import { Dialog, DialogContent, DialogTitle, Paper } from "@mui/material"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import PropTypes from "prop-types"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import { useHistory } from "react-router-dom"
import { Menu, Item, useContextMenu, Separator } from "react-contexify"
import SentenceEditor from "components/NarrativeAdmin/Sentence/SentenceEditor"
import SaturationChip from "components/ContentSaturation/SaturationChip"
import { ActionType, ItemType } from "constants"
import { NarrativeMenuActionsContext } from "contexts/narrativeMenuActionsContext"
import CustomDSDialog from "components/CustomDialogs/CustomDSDialog"
import { Warning } from "@mui/icons-material"

const useStyles = makeStyles(() => ({
  sentenceDetails: {
    margin: "2px",
    backgroundColor: "#fafafa",
    fontSize: "12px",
    color: "#000",
    opacity: props => (props.cutActionTaken ? 0.6 : 1)
  },
  fakeLink: {
    color: "inherit",
    "&:hover": {
      cursor: "pointer"
    }
  },
  gridItem: {
    padding: "5px 15px",
    "& > a": {
      color: "#007bff"
    }
  },
  sentenceModel: {
    maxWidth: "1100px",
    scrollbarWidth: "none"
  }
}))

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    postSentence: store.narrativeStore.postSentence,
    postCopySentence: store.narrativeStore.postCopySentence,
    postArchiveSentence: store.narrativeStore.postArchiveSentence,
    reloadNarrativeBrief: store.narrativeStore.reloadNarrativeBrief,
    removeLibraryParagraphAssociation:
      store.narrativeStore.removeLibraryParagraphAssociation
  }))
}

function NarrativeOutlineSentencePosition(props) {
  const { narrative, sentencePosition, block: outlineBlock, paragraph } = props
  const history = useHistory()
  const {
    postCopySentence,
    postArchiveSentence,
    reloadNarrativeBrief,
    postSentence,
    removeLibraryParagraphAssociation
  } = useStoreData()
  const {
    setSelectedSourceItem,
    setSelectedSourceParentItem,
    setLastActionType,
    wasSentenceCutSelected
  } = useContext(NarrativeMenuActionsContext)

  const classes = useStyles({
    cutActionTaken: wasSentenceCutSelected(sentencePosition.id)
  })

  const [openSentenceEditor, setOpenSentenceEditor] = useState(false)
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false)

  const handleClickOpenSentenceEditor = () => {
    setOpenSentenceEditor(true)
  }

  const handleCloseSentenceEditor = () => {
    setOpenSentenceEditor(false)
  }

  const handleClickRemoveParagraphAssociation = () => {
    removeLibraryParagraphAssociation(paragraph)
  }
  const openWarnDialog = () => {
    setIsWarningDialogOpen(true)
  }

  const closeWarnDialog = () => {
    setIsWarningDialogOpen(false)
  }

  const openUrl = `/portal/narrative/${narrative.id}/edit?view=${
    narrative?.buildFromOutline ? "outlinesentenceid" : "sentenceid"
  }-${sentencePosition.id}`

  const contextMenuId = `outlineblock-${outlineBlock.id}-sentence-narrative-${sentencePosition.narrative_Id}-sentence-paragraph-${sentencePosition.paragraph_Id}-sentence-${sentencePosition.id}-position-${sentencePosition.position}`

  const { show } = useContextMenu({
    id: contextMenuId
  })

  function handleContextMenu(event) {
    event.stopPropagation()
    show({
      event
    })
  }

  const handleMenuAction = actionType => {
    if (actionType === ActionType.Archive) {
      openWarnDialog()
    } else if (actionType === ActionType.Duplicate) {
      postCopySentence(sentencePosition.id, paragraph.id, narrative.id)
      setTimeout(() => reloadNarrativeBrief(narrative.id), 1000)
    } else if (
      actionType === ActionType.Copy ||
      actionType === ActionType.Cut
    ) {
      setSelectedSourceItem({
        id: sentencePosition.id,
        type: ItemType.Sentence
      })
      setSelectedSourceParentItem({
        id: paragraph.id,
        type: ItemType.Paragraph
      })
    } else if (actionType === ActionType.CreatePlaceholder) {
      createPlaceholderSentence()
      setTimeout(() => reloadNarrativeBrief(narrative.id), 1000)
    } else if (actionType === ActionType.CreatePlaceholderRemoveParagraph) {
      createPlaceholderSentence()
      removeLibraryParagraphAssociation(paragraph)
    }
    setLastActionType(actionType)
  }

  const handleArchiveConfirm = () => {
    postArchiveSentence(sentencePosition.id).then(() => {
      reloadNarrativeBrief(narrative.id)
    })
  }

  const createPlaceholderSentence = () => {
    const placeholderSentence = {
      ...sentencePosition,
      id: 0,
      isLibraryPlaceholder: true,
      isFromLibrary: false,
      narrative_Id: narrative.id,
      libraryParagraph_Id: sentencePosition.paragraph_Id,
      paragraph_Id: paragraph.id,
      importedByParagraph_Id: null,
      triggerType: "",
      triggerIds: "",
      triggerWeight: "",
      saturation: null,
      utilization: null,
      paragraphSaturation: null,
      paragraphUtilization: null,
      clonedFrom_Id: null,
      comments: "",
      librarySentencePosition: sentencePosition.position
    }
    postSentence(placeholderSentence)
  }
  const renderContextMenu = () =>
    !sentencePosition.isFromLibrary ? (
      <Menu id={contextMenuId}>
        <Item onClick={() => history.push(openUrl)}>Open</Item>
        <Item onClick={() => handleClickOpenSentenceEditor()}>Edit</Item>
        <Item onClick={() => window.open(openUrl, "_blank")}>
          Open in new window
        </Item>
        <Separator />
        <Item
          disabled={!sentencePosition.id}
          onClick={() => handleMenuAction(ActionType.Copy)}
        >
          Copy
        </Item>
        <Item
          disabled={!sentencePosition.id}
          onClick={() => handleMenuAction(ActionType.Cut)}
        >
          Cut
        </Item>
        <Item
          disabled={!sentencePosition.id}
          onClick={() => handleMenuAction(ActionType.Duplicate)}
        >
          Duplicate
        </Item>
        <Item onClick={() => handleMenuAction(ActionType.Archive)}>
          Archive
        </Item>
      </Menu>
    ) : (
      <Menu id={contextMenuId} className={classes.menu}>
        <Item onClick={() => handleMenuAction(ActionType.CreatePlaceholder)}>
          Create Library Position Group
        </Item>
        <Item
          onClick={() =>
            handleMenuAction(ActionType.CreatePlaceholderRemoveParagraph)
          }
        >
          Create Library Position Group and Remove Paragraph Association
        </Item>
        <Item onClick={handleClickRemoveParagraphAssociation}>
          Remove Paragraph Association
        </Item>
      </Menu>
    )

  const renderLabelAndLink = () => {
    const { isLibraryPlaceholder, isFromLibrary } = sentencePosition
    const label =
      isLibraryPlaceholder || isFromLibrary
        ? "Library Position Group"
        : "Sentence"
    const hideLink = isFromLibrary && !isLibraryPlaceholder
    const title =
      isLibraryPlaceholder || isFromLibrary ? "Position Group" : "Sentence"
    return (
      <>
        {`${label} `}
        {!hideLink && (
          <span
            className={classes.fakeLink}
            title={`Open ${title} ${sentencePosition.id}`}
            onClick={e => {
              handleClickOpenSentenceEditor()
              e.preventDefault()
            }}
          >
            {sentencePosition.id}
          </span>
        )}
      </>
    )
  }

  return (
    <>
      {renderContextMenu()}
      <CustomDSDialog
        open={isWarningDialogOpen}
        icon={<Warning style={{ fontSize: "4rem" }} />}
        onClose={closeWarnDialog}
        backgroundColor={"#FFC107"}
        content={`Are you sure you want to archive Sentence ${sentencePosition.id} ?`}
        buttonProps={[
          {
            label: "NO",
            color: "default",
            onClick: closeWarnDialog
          },
          {
            label: "YES",
            color: "primary",
            onClick: handleArchiveConfirm
          }
        ]}
      />
      <div id={contextMenuId} onContextMenu={handleContextMenu}>
        <GridContainer
          className={classes.sentenceDetails}
          key={sentencePosition.id}
        >
          <GridItem className={classes.gridItem} xs={1} />
          <GridItem className={classes.gridItem} xs={3}>
            {renderLabelAndLink()}
          </GridItem>
          <GridItem className={classes.gridItem} xs={3}>
            {sentencePosition.libraryParagraph_Id ||
            paragraph.libraryParagraph_Id ? (
              <>
                Library Par.:{" "}
                {sentencePosition.libraryParagraph_Id ||
                  paragraph.libraryParagraph_Id}
              </>
            ) : (
              ""
            )}
          </GridItem>
          <GridItem className={classes.gridItem} xs={2}>
            Position: {sentencePosition.position}
          </GridItem>
          <GridItem className={classes.gridItem} xs={3}>
            <SaturationChip
              saturation={sentencePosition.saturation}
              utilization={sentencePosition.utilization}
            />
          </GridItem>
        </GridContainer>
      </div>
      <Dialog
        open={openSentenceEditor}
        maxWidth={"md"}
        onContextMenu={e => {
          //prevent right click from triggering parent context menu
          e.stopPropagation()
        }}
      >
        <DialogTitle className={classes.modalHeader}>
          Edit Sentence {sentencePosition.id}
        </DialogTitle>
        <Paper>
          <DialogContent className={classes.sentenceModel}>
            <SentenceEditor
              {...props}
              sentence={sentencePosition}
              handleClose={handleCloseSentenceEditor}
              renderNoneOption={true}
            />
          </DialogContent>
        </Paper>
      </Dialog>
    </>
  )
}
NarrativeOutlineSentencePosition.propTypes = {
  narrative: PropTypes.object,
  sentencePosition: PropTypes.object,
  block: PropTypes.object,
  paragraph: PropTypes.object,
  myIndex: PropTypes.number
}

export default NarrativeOutlineSentencePosition
