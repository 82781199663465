import React from "react"
import PropTypes from "prop-types"
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material"

class OwnerType extends React.Component {
  render() {
    return (
      <FormControl margin="normal" fullWidth>
        <InputLabel>Owner</InputLabel>
        <Select
          type="select"
          name="OwnerTypeInput"
          label="Owner"
          id="OwnerType"
          value={this.props.value || ""}
          onChange={e => {
            this.props.onChange(e.target.value)
            e.preventDefault()
          }}
        >
          <MenuItem
            value=""
            classes={{ selected: this.props.classes?.selected }}
          >
            None
          </MenuItem>
          {this.props.ownerList &&
            this.props.ownerList.map(owner => (
              <MenuItem
                key={owner.id}
                value={owner.id}
                classes={{ selected: this.props.classes?.selected }}
              >
                {owner.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
  }
}

OwnerType.propTypes = {
  // Where the user to be redirected on clicking the avatar
  onChange: PropTypes.func,
  value: PropTypes.number,
  disabled: PropTypes.bool,
  ownerList: PropTypes.array
}

export default OwnerType
