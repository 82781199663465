// eslint-disable-next-line no-unused-vars
import React, { useRef, lazy, useEffect, Suspense } from "react"
import PropTypes from "prop-types"
import { observer, inject } from "mobx-react"

import CodeMirror from "@uiw/react-codemirror"
import "./scriban/cm-scriban"
import "./scriban/cm-scriban.css"
import "codemirror/addon/mode/simple"
import "codemirror/keymap/sublime"
import "codemirror/addon/hint/show-hint"
import "codemirror/addon/hint/show-hint.css"

import withStyles from "@mui/styles/withStyles"
import { styles } from "./styles"

const Snippets = lazy(() => import("../NarrativeAdmin/Data/Snippets"))

const useStore = component =>
  inject(({ store }) => ({
    getSnippets: store.snippetStore.getSnippets,
    snippets: store.snippetStore.snippets
  }))(observer(component))

function CodeEditor(props) {
  const {
    withTabs = false,
    template,
    handleChange,
    handleFocus,
    classes,
    getSnippets,
    snippets,
    isNotLibrary = false
  } = props
  const editorEl = useRef()

  const compProps = {
    ref: editorEl,
    value: template,
    height: "auto",
    options: {
      theme: "scriban",
      lineWrapping: true,
      lineNumbers: true,
      mode: "scriban",
      readOnly: isNotLibrary,
      viewportMargin: 100,
      spellcheck: true,
      extraKeys: { "Ctrl-Space": "autocomplete" }
    },
    onBlur: () => handleChange && handleChange(editorEl.current.editor)
  }

  if (!withTabs) {
    compProps.onFocus = () => handleFocus(editorEl.current.editor)
  }

  useEffect(() => {
    if (snippets === null || snippets.size === 0) {
      getSnippets()
    }
  }, [])

  const insertStringInTemplate = str => {
    const editor = editorEl.current.editor
    const selection = editor && editor.getSelection && editor.getSelection()

    if (selection && selection.length > 0) {
      editor.replaceSelection(str)
    } else {
      const doc = editor.getDoc()
      const cursor = doc.getCursor()

      const pos = {
        line: cursor.line,
        ch: cursor.ch
      }

      doc.replaceRange(str, pos)
    }
  }

  return (
    <div className={classes.codeEditor}>
      <Suspense fallback={<></>}>
        {withTabs && (
          <div className={classes.toolbar}>
            <Snippets
              handleChange={e => {
                insertStringInTemplate(e.target.value)
                e.preventDefault()
              }}
            />
          </div>
        )}
      </Suspense>
      <div className={classes.codeEditorArea}>
        <CodeMirror {...compProps} />
      </div>
    </div>
  )
}

CodeEditor.propTypes = {
  template: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleFocus: PropTypes.func,
  classes: PropTypes.object,
  getSnippets: PropTypes.func,
  withTabs: PropTypes.bool
}

export default withStyles(styles)(useStore(CodeEditor))
