import { List, fromJS } from "immutable"
import { action, observable, decorate } from "mobx"
import WebhookDAO from "../daos/webhookDAO"

export default class WebhookStore {
  rootStore
  loading
  organizationId
  outboundWebhookByOrg
  inboundWebhookOrg
  outboundWebhookQueue
  inboundWebhookLog
  webhookId
  updateWebhooks

  constructor(rootStore) {
    this.rootStore = rootStore
    this.dehydrate()
  }

  /**
   * Reactions
   */
  getOutboundWebhookByOrg = async organizationId => {
    try {
      this.setLoading(true)
      const outboundWebhookByOrg = fromJS(
        await WebhookDAO.getOutboundWebhookByOrg(organizationId)
      )

      this.setOutboundWebhookByOrg(outboundWebhookByOrg.get("content", List()))
    } catch (e) {
      console.error("Error: outboundWebhookOrg", e)
    } finally {
      this.setLoading(false)
    }
  }
  getInboundWebhookByOrg = async organizationId => {
    try {
      this.setLoading(true)
      const inboundWebhookOrg = fromJS(
        await WebhookDAO.getInboundWebhookByOrg(organizationId)
      )

      this.setInboundWebhookByOrg(inboundWebhookOrg.get("content", List()))
    } catch (e) {
      console.error("Error: inboundWebhookOrg", e)
    } finally {
      this.setLoading(false)
    }
  }

  loadWebhookOutboundQueueSent = async webhookId => {
    try {
      this.setLoading(true)
      const outboundWebhookQueue = fromJS(
        await WebhookDAO.loadWebhookOutboundQueueSent(webhookId)
      )

      this.setLoadOutboundWebhookQueue(
        outboundWebhookQueue.get("content", List())
      )
    } catch (e) {
      console.error("Error: outboundWebhookQueue", e)
    } finally {
      this.setLoading(false)
    }
  }
  loadInboundWebhookLogs = async webhookId => {
    try {
      this.setLoading(true)
      const inboundWebhookLog = fromJS(
        await WebhookDAO.loadInboundWebhookLogs(webhookId)
      )

      this.setInboundWebhookLogs(inboundWebhookLog.get("content", List()))
    } catch (e) {
      console.error("Error: inboundWebhookLog", e)
    } finally {
      this.setLoading(false)
    }
  }

  postWebhook = async webhook => {
    try {
      this.setLoadingNewWebhook(true)
      const newWebhookId = await WebhookDAO.postWebhook(webhook)
      webhook.id = newWebhookId
      this.updateWebhooks(webhook)
    } catch (e) {
      console.error("Error: ", e)
      throw e
    } finally {
      this.setLoadingNewWebhook(false)
    }
  }

  /**
   * Internal Actions
   */
  dehydrate() {
    this.setLoading(false)
  }

  setLoading(value) {
    this.loading = value
  }
  setOutboundWebhookByOrg(value) {
    this.outboundWebhookByOrg = value
  }
  setInboundWebhookByOrg(value) {
    this.inboundWebhookOrg = value
  }
  setLoadOutboundWebhookQueue(value) {
    this.outboundWebhookQueue = value
  }
  setInboundWebhookLogs(value) {
    this.inboundWebhookLog = value
  }
  setLoadingNewWebhook(value) {
    this.updateWebhooks = value
  }
}
decorate(WebhookStore, {
  //observables
  loading: observable,
  outboundWebhookByOrg: observable,
  inboundWebhookOrg: observable,
  outboundWebhookQueue: observable,
  inboundWebhookLog: observable,
  updateWebhooks: observable,

  //actions
  setLoading: action.bound,
  setOutboundWebhookByOrg: action.bound,
  getInboundWebhookByOrg: action.bound,
  setInboundWebhookByOrg: action.bound,
  setLoadOutboundWebhookQueue: action.bound,
  setInboundWebhookLogs: action.bound,
  loadInboundWebhookLogs: action.bound
})
