import { useMemo, useState } from "react"

import NarrativeDAO from "daos/narrativeDAO"

const LIBRARY_ORG = 57

const defaultProps = {
  organizationId: LIBRARY_ORG
}

const useNarrativeOptions = ({ organizationId } = defaultProps) => {
  const [narrativeOptions, setNarrativeOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const nonArchivedNarratives = useMemo(
    () => narrativeOptions.filter(narrative => !narrative.isArchived),
    [narrativeOptions]
  )

  const fetchNarratives = async () => {
    if (!organizationId) {
      return
    }

    try {
      setIsLoading(true)
      const response = await NarrativeDAO.getNarrativesByOrganizationId(
        organizationId
      )
      const { content = [] } = response
      content.sort((a, b) => (a > b ? 1 : -1))
      setNarrativeOptions(content)
    } catch (err) {
      console.error(`Error fetching narratives for org ${organizationId}`, err)
    } finally {
      setIsLoading(false)
    }
  }

  return {
    fetchNarratives,
    isLoading,
    narrativeOptions,
    nonArchivedNarratives
  }
}

export default useNarrativeOptions
