// eslint-disable-next-line no-unused-vars
import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { Formik, Form as FormikForm } from "formik"
import { Map } from "immutable"
import { SnackbarContent } from "@mui/material"

import Footer from "./Footer"
import Body from "./Body"
import Input from "./Input"
import TextArea from "./TextArea"
import Select from "./Select"
import Button from "./Button"
import CheckboxGroup from "./CheckboxGroup"
import SearchInput from "./SearchInput"
import Tags from "./Tags"

import "assets/css/Components/Form.css"
import "assets/css/Components/Button.css"

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  validationSchema: PropTypes.object
}

const Form = props => {
  const { children, className, id, initialValues, onSubmit, validationSchema } =
    props

  const handleSubmit = async (values, actions) => {
    try {
      actions.setStatus(null)
      await onSubmit(values, actions.setStatus)
    } catch (err) {
      actions.setSubmitting(false)
      const message = err instanceof Map ? err.get("message") : err.message
      return actions.setStatus({
        type: "error",
        message
      })
    }
  }

  const buildErrorMsg = (errors = {}) => (
    <ul>
      {Object.values(errors).map(err => (
        <li>{err}</li>
      ))}
    </ul>
  )

  return (
    <Formik
      id={id}
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ errors, isSubmitting, status }) => (
        <FormikForm
          className={classnames("form", className, {
            "form--submitting": isSubmitting
          })}
        >
          {status?.message && (
            <SnackbarContent
              className={classnames("form__message", status.type)}
              message={status.message}
            />
          )}
          {!!Object.keys(errors)?.length && (
            <SnackbarContent
              className={classnames("form__message", "error-list")}
              message={buildErrorMsg(errors)}
            />
          )}
          {children}
        </FormikForm>
      )}
    </Formik>
  )
}

Form.propTypes = propTypes
export default Form
export {
  Form,
  Input,
  Body,
  Footer,
  Select,
  Button,
  CheckboxGroup,
  TextArea,
  SearchInput,
  Tags
}
