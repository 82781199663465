import React, { useEffect } from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"

import { Autocomplete, TextField } from "@mui/material"

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    getInsightStatus: store.realTimeStore.getInsightStatus,
    insightStatus: store.realTimeStore.insightStatus
  }))
}

export default function RealTimeInsightStatus({ value, onChange, readOnly }) {
  const { getInsightStatus, insightStatus } = useStoreData()

  useEffect(() => {
    if (!insightStatus || insightStatus?.length === 0) {
      getInsightStatus()
    }
  }, [insightStatus])

  const handleChange = (event, newValue) => {
    onChange(newValue)
  }

  return (
    <>
      <Autocomplete
        sx={{ minWidth: "150px" }}
        size="small"
        aria-label="RealTime InsightStatus component"
        id="insightStatusId"
        options={insightStatus || []}
        value={insightStatus?.find(status => status.id === value) || null}
        getOptionLabel={option => option.name || ""}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        onChange={handleChange}
        renderInput={params => <TextField {...params} label="STATUS" />}
        disabled={readOnly}
      />
    </>
  )
}
