import React, { useEffect } from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"

import { Autocomplete, TextField } from "@mui/material"

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    getLibraryStatus: store.realTimeStore.getLibraryStatus,
    libraryStatus: store.realTimeStore.libraryStatus
  }))
}

export default function RealTimeLibraryStatus({
  value,
  onChange,
  statusErrorMsg
}) {
  const { getLibraryStatus, libraryStatus } = useStoreData()

  useEffect(() => {
    getLibraryStatus()
  }, [getLibraryStatus])

  const handleChange = (event, newValue, reason) => {
    if (reason === "clear") {
      onChange({ id: "", name: "" })
    } else {
      onChange(newValue) // Update the selected value
    }
  }

  return (
    <>
      {libraryStatus?.length > 0 && (
        <Autocomplete
          aria-label="RealTime LibraryStatus component"
          id="libraryStatusId"
          options={libraryStatus}
          value={libraryStatus?.find(status => status.id === value.id || null)}
          getOptionLabel={option => option.name || ""}
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          onChange={handleChange}
          renderInput={params => (
            <TextField
              {...params}
              error={!!statusErrorMsg}
              helperText={statusErrorMsg}
              label="STATUS"
              size="small"
            />
          )}
        />
      )}
    </>
  )
}
