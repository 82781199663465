import React from "react"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import { inject, observer } from "mobx-react"
import {
  Grid,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Snackbar,
  Checkbox,
  FormControlLabel
} from "@mui/material"
import Autocomplete from "components/Autocomplete/Autocomplete"
import AutocompleteMUI from "@mui/material/Autocomplete"
import Button from "components/CustomButtons/Button"
import GridItem from "components/Grid/GridItem"
import NarrativeModelType from "components/NarrativeAdmin/Types/NarrativeModelType"
import { globalSettings } from "variables/general"
import { userAudiInfo } from "tools/AuditInfoHelper"
import LinearProgress from "@mui/material/LinearProgress"
import { Cookies } from "tools/storage"
import Enums from "tools/Enums.js"
import Helpers from "tools/Helpers.js"
import { get } from "tools/request"
import { ENTERTAINMENT_VERTICAL_ID } from "constants"

const styles = {
  checkboxAlign: {
    display: "flex",
    alignItems: "flex-end"
  },
  textGridAlign: {
    display: "flex"
  }
}

const useStore = component =>
  inject(({ store }) => ({
    postNarrative: store.narrativeStore.postNarrative,
    setNarrative: store.narrativeStore.setNarrative,
    organizationId: store.organizationStore.organizationId,
    organizations: store.organizationStore.organizations,
    getOrganizations: store.organizationStore.getOrganizations,
    feeds: store.organizationStore.feeds,
    getFeeds: store.organizationStore.getFeeds,
    organizationProperties: store.organizationStore.organizationProperties,
    account: store.accountStore.account,
    modelTypes: store.narrativeStore.modelTypes,
    retrieveLeagues: store.sportsStore.retrieveLeagues,
    sortedLeagues: store.sportsStore.sortedLeaguesByName
  }))(observer(component))

class Narrative extends React.Component {
  constructor(props) {
    super(props)
    let cookies = new Cookies()
    let cookieCurrentOrg =
      this.props.organizationId || cookies.get("currentorg")
    this.state = {
      currentOrgId: cookieCurrentOrg || {},
      narrative: props.narrative || {},
      originalNarrative: props.narrative || {},
      Permissions: {},
      rawStory: "",
      ownerList: [],
      isSnackBarOpen: false,
      removingProperty: false,
      accountOwner: this.props && this.props.account && this.props.account.id,
      alertMessage: "",
      queriesLoading: false,
      queryList: [],
      queryIdDisplayName: "",
      refreshQueryIdDisplayName: ""
    }
  }

  componentDidMount() {
    this.loadNarratives()
    this.loadOwners()
    this.loadQueries()
    this.props.retrieveLeagues()
    this.props.getFeeds(this.props.narrative?.organization?.id)
    this.props.getOrganizations()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.narrative &&
      (nextProps.narrative.id !== this.props.narrative.id ||
        nextProps.narrative.modelTypeId !== this.props.narrative.modelTypeId)
    ) {
      this.setState({
        narrative: nextProps.narrative
      })
    }
  }

  loadNarratives() {
    get(`${globalSettings.apiBaseUrl}/api/narrative`)
      .then(Response => Response.json())
      .then(narratives => {
        this.setState({
          narratives: narratives.content.filter(
            n => n.clonedFrom_Id === null || n.clonedFrom_Id === 0
          )
        })
      })
      .catch(err => {
        console.error("Error on GET to /api/narrative", err)
      })
  }

  loadOwners() {
    const { narrative } = this.state
    if (narrative && narrative.organization && narrative.organization.id) {
      get(
        `${globalSettings.apiBaseUrl}/api/account/retrieveaccountsbyorg?orgid=${narrative.organization.id}`
      )
        .then(Response => Response.json())
        .then(accts => {
          if (accts && accts.content && accts.content.length) {
            let myList = []
            accts.content.forEach(element => {
              if (
                !!element.firstName &&
                !!element.lastName &&
                !!element.username &&
                element.status === "Active"
              ) {
                myList.push({
                  name: `${element.firstName} ${element.lastName}`,
                  username: element.username,
                  id: element.id
                })
              }
            })
            this.setState({
              ownerList: myList
            })
          }
        })
        .catch(err => {
          console.error(
            `Error on GET to /api/account/retrieveaccountsbyorg?orgid=${narrative?.organization.id}`,
            err
          )
        })
    } else if (this.state.currentOrgId) {
      get(
        `${globalSettings.apiBaseUrl}/api/account/retrieveaccountsbyorg?orgid=${this.state.currentOrgId}`
      )
        .then(Response => Response.json())
        .then(accts => {
          if (accts && accts.content && accts.content.length) {
            let myList = []
            accts.content.forEach(element => {
              if (
                !!element.firstName &&
                !!element.lastName &&
                !!element.username &&
                element.status === "Active"
              ) {
                myList.push({
                  name: `${element.firstName} ${element.lastName}`,
                  username: element.username,
                  id: element.id
                })
              }
            })
            this.setState({
              ownerList: myList
            })
          }
        })
        .catch(err => {
          console.error(
            `Error on GET to /api/account/retrieveaccountsbyorg?orgid=${this.state.currentOrgId}`,
            err
          )
        })
    }
  }

  loadQueries() {
    this.setState({ queriesLoading: true })
    get(`${globalSettings.apiBaseUrl}/api/querybuilder/retrievequerynames`)
      .then(response => response.json())
      .then(data => {
        let queryList = data.content
        this.setState({
          queryList,
          queriesLoading: false
        })
      })
      .catch(err => {
        console.error(
          `Error on GET to /api/querybuilder/retrievequerynames`,
          err
        )
      })
  }

  handleSubmit() {
    const { narrative, rawStory } = this.state
    const { isLibrary } = narrative
    const { account } = this.props
    this.setState({
      isSnackBarOpen: true,
      alertMessage: `Changes were made to this narrative that may not be reflected in any scheduled jobs. Be sure to make changes to any scheduled jobs as well.`
    })

    if (!narrative.name) {
      this.setState({ showError: true })
      return
    } else {
      this.setState({ isSaving: true })
    }

    let publishedStatus = 3
    if (narrative.statusId && narrative.statusId !== 0) {
      publishedStatus = narrative.statusId
    }
    const tempNarrative = {}
    if (this.state.property) {
      tempNarrative.organization = {
        id: this.state.property
      }
    } else if (narrative.organization) {
      tempNarrative.organization = narrative.organization
    } else {
      tempNarrative.organization = {
        id: this.state.currentOrgId
      }
    }
    const newNarrative = {
      ...narrative,
      id: narrative.id || 0,
      statusId: publishedStatus,
      parentId: narrative.parent_Id || null,
      isLibrary: isLibrary,
      parents: null,
      accountOwner: this.state.accountOwner,
      modelTypeId: narrative.modelTypeId,
      queryId: narrative.queryId || null,
      refreshQueryId: narrative.refreshQueryId || null,
      dataSourceSample: narrative.dataSourceSample || "",
      description: narrative.description || "",
      organization: tempNarrative.organization,
      // organization: narrative.organization || { id: this.state.property || this.state.currentOrgId },
      auditInfo: userAudiInfo(
        account,
        narrative.auditInfo,
        this.state.accountOwner
      ),
      rawStory
    }
    this.props.postNarrative(newNarrative)
    this.props.handleSave && this.props.handleSave(newNarrative)
  }

  render() {
    const { createMode } = this.props
    const {
      narrative,
      feeds,
      ownerList,
      narratives,
      showError,
      isSnackBarOpen,
      alertMessage,
      removingProperty,
      queryList,
      property,
      addProperty
    } = this.state

    if (!narrative && !createMode && !feeds && !narratives) {
      return "Assembling..."
    }

    const {
      name,
      parent_Id,
      organization,
      libraryNarrative_Ids,
      modelTypeId,
      leagueId,
      narrativeStatusId,
      description,
      categoryTerm,
      categoryLabel,
      categoryScheme,
      dataSourceSample,
      queryId,
      refreshQueryId,
      isArchived,
      isLibrary
    } = narrative

    let accountOwner =
      narrative.accountOwner ||
      (createMode
        ? this.props.account.id
        : narrative &&
          narrative.auditInfo &&
          narrative.auditInfo.owner &&
          narrative.auditInfo.owner.id)

    // Get organization list
    let sortedOrganizations = this.props.organizations
      ?.toJS()
      .sort(Helpers.sortbyName)
    let organization_Id = (organization && organization.id) || -3

    // Sort the feeds
    let sortedFeeds = this.props.feeds?.toJS().types.sort(Helpers.sortbyName)

    let property_Id = this.state.property || 0

    this.props.organizationProperties.forEach(itm => {
      if (itm.id === organization_Id) {
        if (property_Id === 0 && !removingProperty) {
          property_Id = organization_Id
        }
        organization_Id = itm.parentId
      }
    })

    const selectedOrgName = (organization && organization.name) || ""

    const allProperties = this.props.organizationProperties.sort(
      Helpers.sortbyName
    )

    let filteredProperties = {}

    allProperties &&
      allProperties.forEach(item => {
        if (filteredProperties[item.parentId]) {
          filteredProperties[item.parentId].push(item)
        } else {
          filteredProperties[item.parentId] = []
          filteredProperties[item.parentId].push(item)
        }
      })

    // Sort the available narrative data models
    let sortedModelTypes =
      this.props.modelTypes && this.props.modelTypes.sort(Helpers.sortbyName)

    const filteredLeagues = this.props.sortedLeagues.filter(
      q => q.name !== "" && q.isActive === true
    )
    const leaguesFullName = filteredLeagues
      .map(option => {
        let acronym = option.acronym ? `(${option.acronym}) ` : ""
        return {
          combinedName: `${acronym}${option.name}`,
          ...option
        }
      })
      .sort((a, b) => {
        const aTern = (a.acronym || a.name).toUpperCase()
        const bTerm = (b.acronym || b.name).toUpperCase()
        if (aTern < bTerm) {
          return -1
        } else if (aTern > bTerm) {
          return 1
        }
        return 0
      })

    const leagueOptions = [
      {
        id: 0,
        combinedName: "Not Applicable"
      }
    ].concat(leaguesFullName)

    const handleCellChange = name => event => {
      const newNarrative = {
        ...this.state.narrative,
        [name]: event.target.value
      }
      if (name === "name" && newNarrative.familyName !== newNarrative.name) {
        newNarrative.familyName = newNarrative.name
        this.setState({ narrative: newNarrative })
      } else if (name === "accountOwner") {
        accountOwner = event.target.value
        this.setState({
          narrative: newNarrative,
          accountOwner: event.target.value
        })
      } else if (name === "queryId") {
        this.setState({
          narrative: newNarrative,
          isSnackBarOpen: true,
          alertMessage: `Warning: The query this narrative is based on has changed.
          Please update any scheduled jobs that may rely on this value.`
        })
      } else if (name === "refreshQueryId") {
        this.setState({
          narrative: newNarrative,
          isSnackBarOpen: true,
          alertMessage: `Warning: The qa query this narrative is based on has changed.
        Please update any scheduled jobs that may rely on this value.`
        })
      } else if (name === "libraryNarrative_Ids") {
        this.setState({
          narrative: newNarrative,
          isSnackBarOpen: true,
          alertMessage: `Any changes to the library paragraph ids will cause reloading of the narrative`
        })
      } else {
        this.setState({ narrative: newNarrative })
      }
    }

    const handleSnackBarClose = () => {
      this.setState({ isSnackBarOpen: false })
    }

    const filteredQueries = queryList.filter(q => q.name !== "")

    const queryOptions = filteredQueries.map(option => {
      const firstLetter = option.name[0].toUpperCase()
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
        ...option
      }
    })

    return (
      <React.Fragment>
        {this.state.isSaving && <LinearProgress />}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={isSnackBarOpen}
          onClose={handleSnackBarClose}
          key="ChangeLogSnack"
          message={alertMessage}
        />
        <Grid>
          <GridItem xs={12}>
            <TextField
              required
              error={showError}
              type="text"
              id="name"
              label={name ? "Name" : "Name"}
              fullWidth
              value={name || ""}
              onChange={handleCellChange("name")}
              margin="normal"
            />
          </GridItem>
          <GridItem xs={12} style={isLibrary ? { display: "none" } : {}}>
            <Autocomplete
              id="parentNarrative"
              options={
                (narratives && narratives.filter(n => !n.isArchived)) || []
              }
              label="Parent Narrative"
              value={parent_Id || ""}
              onChange={handleCellChange("parent_Id")}
              nameField="name"
              valueField="id"
              sortField="name"
              renderOption={(props, n) => (
                <li
                  {...props}
                  key={n.id}
                  value={n.id}
                  style={Helpers.buildCustomStyleForNarrativeStatus(
                    n.narrativeStatusId
                  )}
                >
                  {n.id} - {n.isArchived === true && "ARCHIVED:"}
                  {n.name} (Status:&nbsp;
                  {Helpers.convertNarrativeStatusIdToStatusName(
                    n.narrativeStatusId
                  )}
                  ) )
                </li>
              )}
            />
          </GridItem>
          <GridItem xs={12} style={isLibrary ? { display: "none" } : {}}>
            <TextField
              type="text"
              id="libraryNarrative_Ids"
              label={"Library Narrative Ids"}
              fullWidth
              value={libraryNarrative_Ids}
              onChange={handleCellChange("libraryNarrative_Ids")}
              margin="normal"
            />
          </GridItem>
        </Grid>
        <Grid
          container
          style={isLibrary ? { display: "none" } : styles.textGridAlign}
        >
          <GridItem xs={4} sm={4}>
            <FormControl margin="normal" fullWidth>
              <InputLabel>Organization</InputLabel>
              <Select
                fullWidth={false}
                type="select"
                name="organization"
                id="organization"
                label="Organization"
                value={organization_Id}
                __value={
                  (organization && organization.id) ||
                  this.props.organizationId ||
                  ""
                }
                onChange={e => {
                  const newOrganization = {
                    id: e.target.value
                  }
                  const newNarrative = {
                    ...this.state.narrative,
                    organization: newOrganization
                  }
                  this.setState({ narrative: newNarrative }, () => {
                    this.props.getFeeds(e.target.value)
                    this.loadOwners()
                  })
                }}
              >
                <MenuItem key="none" value="">
                  None
                </MenuItem>
                {sortedOrganizations &&
                  sortedOrganizations.map(organization => (
                    <MenuItem key={organization.id} value={organization.id}>
                      {organization.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </GridItem>
          {(!!property_Id || addProperty) && (
            <GridItem xs={4} sm={4}>
              <FormControl
                margin="normal"
                fullWidth
                style={{ whiteSpace: "nowrap" }}
              >
                <InputLabel>
                  Property (Assign to one of {selectedOrgName}'s properties?)
                </InputLabel>
                <Select
                  fullWidth={false}
                  type="select"
                  name="property"
                  id="property"
                  label="Property (Assign to one of {selectedOrgName}'s properties?)"
                  value={property_Id}
                  __value={property || selectedOrgName}
                  onChange={e => {
                    this.setState({
                      property: e.target.value
                    })
                  }}
                >
                  <MenuItem key={selectedOrgName} value={selectedOrgName}>
                    {selectedOrgName}
                  </MenuItem>
                  {filteredProperties[organization_Id].map(property =>
                    property.isActive ? (
                      <MenuItem key={property.id} value={property.id}>
                        {property.name}
                      </MenuItem>
                    ) : (
                      <MenuItem
                        style={styles.strikethrough}
                        key={property.id}
                        value={property.id}
                      >
                        {property.name}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </GridItem>
          )}
          {!property_Id && (
            <GridItem xs={4} sm={4} style={{ padding: "25px 0 0 0" }}>
              <FormControlLabel
                labelPlacement="end"
                control={
                  <Checkbox
                    fontSize="small"
                    checked={this.state.addProperty || false}
                    disabled={!filteredProperties[organization_Id]}
                    onChange={event => {
                      this.setState({
                        addProperty: event.target.checked,
                        removingProperty: !event.target.checked
                      })
                    }}
                  />
                }
                label="Add Property?"
              />
            </GridItem>
          )}
          {!!property_Id && (
            <GridItem xs={4} sm={4} style={{ padding: "25px 0 0 0" }}>
              <FormControlLabel
                labelPlacement="end"
                control={
                  <Checkbox
                    fontSize="small"
                    checked={this.state.removeProperty || false}
                    onChange={() => {
                      const newOrganization = {
                        id: organization_Id
                      }
                      const newNarrative = {
                        ...this.state.narrative,
                        organization: newOrganization
                      }
                      this.setState({
                        narrative: newNarrative,
                        property: null,
                        addProperty: false,
                        removingProperty: true
                      })
                    }}
                  />
                }
                label="Remove Property?"
              />
            </GridItem>
          )}
        </Grid>
        <Grid container style={{ display: "flex" }}>
          <GridItem xs={4}>
            {sortedModelTypes && (
              <NarrativeModelType
                modelTypes={sortedModelTypes}
                value={modelTypeId}
                onChange={e => {
                  const newNarrative = {
                    ...this.state.narrative,
                    modelType: "",
                    modelTypeId: e.target.value ?? null
                  }
                  this.setState({ narrative: newNarrative })
                }}
              />
            )}
          </GridItem>
          {modelTypeId === Enums.NarrativeModelType.QueryBuilder && (
            <GridItem xs={4}>
              <Autocomplete
                id="queryId"
                options={queryOptions}
                groupBy={option => option && option.firstLetter}
                value={queryId || null}
                onChange={handleCellChange("queryId")}
                nameField="name"
                valueField="id"
                sortField="firstLetter"
                label="Query"
              />
            </GridItem>
          )}
          {modelTypeId === Enums.NarrativeModelType.QueryBuilder && (
            <GridItem xs={4}>
              <Autocomplete
                id="refreshQueryId"
                options={queryOptions}
                groupBy={option => option && option.firstLetter}
                value={refreshQueryId || null}
                onChange={handleCellChange("refreshQueryId")}
                nameField="name"
                valueField="id"
                sortField="firstLetter"
                label="Refresh QA Query"
              />
            </GridItem>
          )}
        </Grid>
        <Grid sx={{ my: 2 }}>
          {narrative?.verticalId !== ENTERTAINMENT_VERTICAL_ID && (
            <GridItem xs={12}>
              <AutocompleteMUI
                id="leagueId"
                options={leagueOptions}
                value={leagueOptions.find(l => l.id === leagueId) || ""}
                onChange={(event, newValue, reason) => {
                  let leagueId = newValue?.id
                  if (reason === "clear") {
                    leagueId = null
                  }
                  const newNarrative = {
                    ...narrative,
                    leagueId
                  }
                  this.setState({ narrative: newNarrative })
                }}
                getOptionLabel={option =>
                  option ? `${option.id} - ${option.combinedName}` : ""
                }
                renderInput={params => (
                  <TextField {...params} label="Sports League" />
                )}
                label="Sports League"
              />
            </GridItem>
          )}
        </Grid>
        <Grid
          container
          style={isLibrary ? { display: "none" } : styles.textGridAlign}
        >
          <GridItem xs={12} md={6}>
            <AutocompleteMUI
              id="productionFeed_Id"
              name="productionFeed_Id"
              onChange={(event, newValue, reason) => {
                let productionFeed_Id = newValue?.id
                if (reason === "clear") {
                  productionFeed_Id = null
                }
                const newNarrative = {
                  ...narrative,
                  productionFeed_Id
                }
                this.setState({ narrative: newNarrative })
              }}
              value={
                sortedFeeds?.find(f => f.id === narrative?.productionFeed_Id) ||
                ""
              }
              options={
                sortedFeeds?.filter(
                  f =>
                    f.isArchived === false ||
                    f.id === this.state.originalNarrative?.productionFeed_Id // or this Narrative is already assigned to this now 'archived' feed
                ) || {}
              }
              getOptionLabel={option => option.name || ""}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Production Feed (default)"
                  style={
                    sortedFeeds?.find(
                      f => f.id === narrative?.productionFeed_Id
                    )?.isArchived
                      ? { textDecoration: "line-through" }
                      : { textDecoration: "none" }
                  }
                />
              )}
              renderOption={(props, option) =>
                option.isArchived ? (
                  <div {...props} style={{ textDecoration: "line-through" }}>
                    {option.name} ({option.id})
                  </div>
                ) : (
                  <div {...props}>{option.name}</div>
                )
              }
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <AutocompleteMUI
              id="uatFeed_Id"
              name="uatFeed_Id"
              onChange={(event, newValue, reason) => {
                let uatFeed_Id = newValue?.id
                if (reason === "clear") {
                  uatFeed_Id = null
                }
                const newNarrative = {
                  ...narrative,
                  uatFeed_Id
                }
                this.setState({ narrative: newNarrative })
              }}
              value={
                sortedFeeds?.find(f => f.id === narrative?.uatFeed_Id) || ""
              }
              options={
                sortedFeeds?.filter(
                  f =>
                    f.isArchived === false ||
                    f.id === this.state.originalNarrative?.uatFeed_Id // or this Narrative is already assigned to this now 'archived' feed
                ) || {}
              }
              getOptionLabel={option => option.name || ""}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Client Dev - Feed"
                  style={
                    sortedFeeds?.find(at => at.id === narrative?.uatFeed_Id)
                      ?.isArchived
                      ? { textDecoration: "line-through" }
                      : { textDecoration: "none" }
                  }
                />
              )}
              renderOption={(props, option) =>
                option.isArchived ? (
                  <div {...props} style={{ textDecoration: "line-through" }}>
                    {option.name} ({option.id})
                  </div>
                ) : (
                  <div {...props}>{option.name}</div>
                )
              }
            />
          </GridItem>
        </Grid>
        <Grid container style={{ display: "flex" }}>
          <GridItem xs={6} md={6}>
            <FormControl margin="normal" fullWidth>
              <InputLabel>Narrative Owner</InputLabel>
              <Select
                autoWidth
                type="select"
                name="accountOwner"
                id="accountOwner"
                label="Narrative Owner"
                value={accountOwner || ""}
                onChange={handleCellChange("accountOwner")}
              >
                <MenuItem key="none" value="0">
                  None
                </MenuItem>
                {ownerList &&
                  ownerList.map(owner => (
                    <MenuItem key={owner.id} value={owner.id}>
                      {owner.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={2} md={2}>
            <FormControl margin="normal" fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                fullWidth={false}
                type="select"
                name="narrativeStatus"
                id="narrativeStatus"
                label="Status"
                value={narrativeStatusId}
                onChange={handleCellChange("narrativeStatusId")}
              >
                <MenuItem key="Active" value={Enums.NarrativeStatus.ACTIVE}>
                  Active
                </MenuItem>
                <MenuItem key="Inactive" value={Enums.NarrativeStatus.INACTIVE}>
                  Inactive
                </MenuItem>
                <MenuItem key="Validate" value={Enums.NarrativeStatus.VALIDATE}>
                  Validate
                </MenuItem>
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={2} md={2} style={styles.checkboxAlign}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isArchived || false}
                  onChange={event => {
                    const newNarrative = {
                      ...this.state.narrative,
                      isArchived: event.target.checked
                    }
                    this.setState({ narrative: newNarrative })
                  }}
                />
              }
              label="Is Archived?"
            />
          </GridItem>
        </Grid>
        <Grid>
          <GridItem xs={12}>
            <TextField
              type="text"
              fullWidth
              label="Description"
              id="description"
              multiline
              value={description || ""}
              onChange={handleCellChange("description")}
              margin="normal"
            />
          </GridItem>
        </Grid>
        <Grid style={{ display: "flex" }}>
          <GridItem xs={12} md={4}>
            <TextField
              required
              type="text"
              fullWidth
              label="Category Term"
              id="categoryTerm"
              multiline
              value={categoryTerm || ""}
              onChange={handleCellChange("categoryTerm")}
              margin="normal"
            />
          </GridItem>
          <GridItem xs={12} md={4}>
            <TextField
              type="text"
              fullWidth
              label="Category Label"
              id="categoryLabel"
              multiline
              value={categoryLabel || ""}
              onChange={handleCellChange("categoryLabel")}
              margin="normal"
            />
          </GridItem>
          <GridItem xs={12} md={4}>
            <TextField
              type="text"
              fullWidth
              label="Category Scheme"
              id="categoryScheme"
              multiline
              value={categoryScheme || ""}
              onChange={handleCellChange("categoryScheme")}
              margin="normal"
            />
          </GridItem>
        </Grid>
        <Grid>
          <GridItem sm={12}>
            <TextField
              type="text"
              fullWidth
              multiline
              minRows={10}
              label="Sample Data"
              id="dataSourceSample"
              value={dataSourceSample || ""}
              onChange={handleCellChange("dataSourceSample")}
              margin="normal"
            />
          </GridItem>
        </Grid>
        {createMode && (
          <Grid style={{ display: "flex" }}>
            <GridItem xs={12} sm={12}>
              <TextField
                type="text"
                id="rawStory"
                label="Raw Story"
                fullWidth
                multiline
                minRows={30}
                value={""}
                onChange={e => {
                  this.setState({ rawStory: e.target.value })
                }}
                margin="normal"
              />
            </GridItem>
          </Grid>
        )}
        <Grid>
          <GridItem
            xs={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {!createMode && (
              <Button
                size="sm"
                variant="contained"
                color="secondary"
                onClick={() =>
                  this.props.handleCancel &&
                  this.props.handleCancel(this.state.narrative)
                }
              >
                Cancel
              </Button>
            )}
            <Button
              size="sm"
              variant="contained"
              color="primary"
              onClick={() => this.handleSubmit()}
            >
              Save
            </Button>
          </GridItem>
        </Grid>
      </React.Fragment>
    )
  }
}

Narrative.propTypes = {
  // Where the user to be redirected on clicking the avatar
  narrative: PropTypes.object
}

export default withRouter(useStore(Narrative))
