import { useState, useEffect } from "react"
import { useObserver } from "mobx-react"
import ReactJson from "react-json-view"

import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardHeader from "components/Card/CardHeader"
import Grid from "@mui/material/Grid"
import LinearProgress from "@mui/material/LinearProgress"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import ToolBar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"

import makeStyles from "@mui/styles/makeStyles"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

import CodeEditor from "components/CodeEditor"
import CTAChangeLog from "components/CTAEditor/CTAChangeLog"
import GridItem from "components/Grid/GridItem"

import NarrativePreviewDAO from "daos/narrativePreviewDAO"
import { useStore } from "contexts/rootContext"

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    isDependencyLoaded: store.ctaPreviewDependencyStore.isDependencyLoaded,
    getCTAPreviewDependency:
      store.ctaPreviewDependencyStore.getCTAPreviewDependency,
    updateCTAPreviewDependency:
      store.ctaPreviewDependencyStore.updateCTAPreviewDependency,
    putCTAPreviewDependency:
      store.ctaPreviewDependencyStore.putCTAPreviewDependency,
    ctaPreviewDependency: store.ctaPreviewDependencyStore.ctaPreviewDependency,
    ctaPreviewDependencyBackup:
      store.ctaPreviewDependencyStore.ctaPreviewDependencyV2Backup
  }))
}

const useStyles = makeStyles({
  ctaTriggerToolbar: {
    fontSize: "1rem",
    color: "#FFF",
    backgroundColor: "#4d9ab5"
  },
  CTAButton: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "15px",
    paddingBottom: "15px"
  },
  buttonStyle: {
    fontSize: "12px",
    padding: "14px 23px",
    minWidth: "110px",
    marginRight: "4px"
  },
  changeLogDetails: {
    display: "flex",
    flexDirection: "column"
  },
  alertSnack: {
    top: "250px",
    marginRight: "25px"
  }
})

function CTApreviewDependencyEditor(props) {
  const {
    isDependencyLoaded,
    getCTAPreviewDependency,
    updateCTAPreviewDependency: updateDependencyObject,
    putCTAPreviewDependency,
    ctaPreviewDependency: dependencyObject,
    ctaPreviewDependencyBackup
  } = useStoreData()
  const classes = useStyles()

  const [isSaving, setIsSaving] = useState(false)
  const [dependencyResponse, setDependencyResponse] = useState("")
  const [tabValue, setTabValue] = useState("1")

  useEffect(() => {
    !isDependencyLoaded && getCTAPreviewDependency()
  }, [])

  const testDependency = () => {
    let testDependencyResponse = {}
    const { dependency } = dependencyObject
    const previewRequest = {
      templateString: dependency,
      useCache: false,
      excludeDependencies: true,
      skipTriggers: true
    }
    const evaluateDependency =
      NarrativePreviewDAO.evaluateTrigger(previewRequest)
    evaluateDependency
      .then(evaluatedDependency => {
        testDependencyResponse.isTesting = false
        testDependencyResponse.testComplete = true
        testDependencyResponse.isTrue = evaluatedDependency.isTrue
        testDependencyResponse.hasErrors = evaluatedDependency.hasErrors
        testDependencyResponse.renderString =
          evaluatedDependency.renderString || evaluatedDependency.contextError
        testDependencyResponse.contextError = evaluatedDependency.contextError
        setDependencyResponse({
          ...evaluatedDependency,
          ...testDependencyResponse
        })
      })
      .catch(error => {
        testDependencyResponse.isTesting = false
        testDependencyResponse.testComplete = true
        testDependencyResponse.isTrue = false
        testDependencyResponse.hasErrors = true
        testDependencyResponse.renderString = error
        testDependencyResponse.contextError = error
        setDependencyResponse(testDependencyResponse)
      })
  }

  const handleSave = () => {
    setIsSaving(true)
    const updatedDependency = {
      ...dependencyObject
    }
    if (updatedDependency) {
      putCTAPreviewDependency(updatedDependency)
    }
    setTimeout(() => {
      setIsSaving(false)
    }, 1000)
  }

  const handleCancel = () => {
    updateDependencyObject(ctaPreviewDependencyBackup)
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  // const handleCloseAlert = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return
  //   }
  //   setOpen(false)
  // }

  return (
    <>
      <AppBar position="sticky">
        <ToolBar className={classes.ctaTriggerToolbar}>
          Preview Dependency
        </ToolBar>
      </AppBar>
      <Card elevation={0}>
        <CardHeader />
        {true && (
          <Grid>
            <Box>
              <CodeEditor
                withTabs
                template={dependencyObject?.dependency || ""}
                handleChange={editor => {
                  if (editor && editor.getValue()) {
                    const editorContent = editor.getValue()
                    updateDependencyObject({
                      ...dependencyObject,
                      dependency: editorContent
                    })
                  }
                }}
              />
            </Box>
          </Grid>
        )}
        {isSaving && <LinearProgress />}
        <Grid className={classes.CTAButton}>
          <>
            <Button
              className={classes.buttonStyle}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={testDependency}
            >
              Test
            </Button>
            <Button
              className={classes.buttonStyle}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className={classes.buttonStyle}
              variant="contained"
              size="medium"
              color="primary"
              type="submit"
              onClick={handleSave}
              disabled={isSaving}
            >
              Save
            </Button>
          </>
        </Grid>
        <Grid>
          <GridItem>
            {dependencyResponse.renderString &&
              dependencyResponse.renderString !== "" && (
                <Box style={{ margin: "15px" }}>
                  <strong>Result: </strong>
                  {dependencyResponse.renderString}
                </Box>
              )}
          </GridItem>
          <TabContext value={tabValue}>
            <AppBar position="static">
              <TabList onChange={handleTabChange}>
                <Tab label="Tokens Generated" value="1" />
              </TabList>
            </AppBar>
            <TabPanel value={"1"}>
              <Box>
                <ReactJson
                  enableClipboard={true}
                  displayObjectSize={true}
                  displayDataTypes={false}
                  src={(dependencyResponse && dependencyResponse.tokens) || {}}
                  collapsed={false}
                  shouldCollapse={({ name }) => name === "value"}
                />
              </Box>
            </TabPanel>
          </TabContext>
        </Grid>
        <Accordion TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Change Log</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.changeLogDetails}>
            <CTAChangeLog />
          </AccordionDetails>
        </Accordion>
      </Card>
    </>
  )
}

export default CTApreviewDependencyEditor
