import React, { Component } from "react"
import PropTypes from "prop-types"
import { List } from "immutable"
import { Field } from "formik"
import {
  FormControl,
  Select as FormSelect,
  InputLabel,
  FormHelperText,
  MenuItem
} from "@mui/material"

const defaultProps = {
  displayEmpty: true,
  autoWidth: false,
  fullWidth: false,
  hideLabel: false,
  margin: "normal",
  multiple: false,
  readOnly: false,
  required: false,
  type: "text"
}

const propTypes = {
  displayEmpty: PropTypes.bool,
  autoWidth: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helpText: PropTypes.string,
  hideLabel: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  margin: PropTypes.oneOf(["none", "dense", "normal"]),
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  optionLabelKey: PropTypes.string.isRequired,
  optionLabelDescriptionKey: PropTypes.string,
  options: PropTypes.instanceOf(List).isRequired,
  optionValueKey: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool
}

class Select extends Component {
  render() {
    const {
      displayEmpty,
      autoWidth,
      fullWidth,
      helpText,
      hideLabel,
      id,
      label,
      margin,
      multiple,
      name,
      optionLabelKey,
      optionLabelDescriptionKey,
      options,
      optionValueKey,
      placeholder,
      readOnly,
      required,
      defaultValue
    } = this.props

    return (
      <Field as="select" name={name}>
        {({
          form: { setFieldValue, setFieldTouched, submitCount },
          meta: { value, touched, error }
        }) => {
          const hasErrors = (submitCount || touched) && error && true
          const errors = hasErrors

          return (
            <FormControl
              className="form__control"
              hiddenLabel={label && true}
              required={required}
              fullWidth={fullWidth}
              error={hasErrors}
              disabled={readOnly}
              margin={margin}
            >
              {label && (
                <InputLabel
                  id={`${id}__label`}
                  className="form__label"
                  required={required}
                  disabled={readOnly}
                  error={errors}
                  htmlFor={name}
                  hidden={hideLabel}
                >
                  {label}
                </InputLabel>
              )}
              <FormSelect
                className="form__input"
                autoWidth={autoWidth}
                multiple={multiple}
                placeholder={placeholder}
                displayEmpty={displayEmpty}
                onChange={({ target: { value } }) => setFieldValue(name, value)}
                onBlur={() => setFieldTouched(name, true)}
                value={value || defaultValue || ""}
                inputProps={{ id, name }}
              >
                {options &&
                  options.size > 0 &&
                  options.map(o => (
                    <MenuItem
                      key={o.get(optionValueKey) || "none"}
                      value={
                        o.get(optionValueKey) === 0
                          ? "0"
                          : o.get(optionValueKey)
                      }
                    >
                      {o.get(optionLabelKey)}
                      {o.get(optionLabelDescriptionKey) && (
                        <span>&nbsp;- {o.get(optionLabelDescriptionKey)}</span>
                      )}
                    </MenuItem>
                  ))}
              </FormSelect>
              {helpText && (
                <FormHelperText
                  className="form__helper-text"
                  error={hasErrors}
                  disabled={readOnly}
                  required={required}
                >
                  {helpText}
                </FormHelperText>
              )}
            </FormControl>
          )
        }}
      </Field>
    )
  }
}

Select.defaultProps = defaultProps
Select.propTypes = propTypes
export default Select
