import React from "react"
import PropTypes from "prop-types"
import { Typography } from "@mui/material"
import Assignment from "@mui/icons-material/Assignment"

import Loading from "components/Loading"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon"
import CardHeader from "components/Card/CardHeader"
import AuditInfo from "components/AuditInfo"
import "assets/css/Components/Page.css"

const defaultProps = {
  loading: false,
  icon: <Assignment />,
  title: ""
}

const propTypes = {
  loading: PropTypes.bool,
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  auditInfo: PropTypes.object
}

const Page = ({ loading, title, icon, children, auditInfo }) => (
  <Card className="page">
    <CardHeader className="page__header" color="primary" icon>
      <Loading active={loading} />
      <CardIcon color="primary">{icon}</CardIcon>
      <Typography
        component="h1"
        variant="h1"
        color="secondary"
        className="page__title"
      >
        {title}
      </Typography>
      {auditInfo && <AuditInfo muted {...auditInfo} />}
    </CardHeader>
    <CardBody className="page__body">{children}</CardBody>
  </Card>
)

Page.defaultProps = defaultProps
Page.propTypes = propTypes

export default Page
