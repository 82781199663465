// eslint-disable-next-line no-unused-vars
import React, { useEffect } from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import NarrativeRenderSchedules from "./NarrativeRenderSchedules"
import { CircularProgress } from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import { Link } from "react-router-dom"
import { ArrowBack } from "@mui/icons-material"

const styles = () => ({
  emptyModal: {
    width: "60vw",
    height: "200px",
    margin: "5vh 0",
    borderRadius: "5px",
    background: "#fff",
    textAlign: "center",
    padding: "7vh",
    border: "2px solid #333"
  }
})

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    narrative: store.narrativeStore.narrative,
    schedules: store.narrativeRenderScheduleStore.schedules,
    loadingSchedules: store.narrativeRenderScheduleStore.loading,
    getNarrativeRenderSchedulesByNarrativeId:
      store.narrativeRenderScheduleStore
        .getNarrativeRenderSchedulesByNarrativeId,
    putNarrativeRenderSchedule: store.narrativeStore.putNarrativeRenderSchedule
  }))
}

const NarrativeSchedule = props => {
  const {
    schedules,
    loadingSchedules,
    getNarrativeRenderSchedulesByNarrativeId
  } = useStoreData()

  const { classes } = props
  let narrativeId = props.narrativeId || props.match.params.id
  let isModal = props.isModal
  const schedulesToJS = schedules.toJS()

  // on first render
  useEffect(() => {
    let match = props && props.match
    if (match) {
      narrativeId = match.params.id
      getNarrativeRenderSchedulesByNarrativeId(narrativeId)
    } else if (props && props.narrativeId) {
      getNarrativeRenderSchedulesByNarrativeId(props.narrativeId)
    }
  }, [])

  return (
    <div style={{ padding: "10px" }}>
      {!loadingSchedules && (!schedules || schedules.toJS().length === 0) && (
        <h5>No Jobs Scheduled</h5>
      )}
      {loadingSchedules && (
        <CircularProgress
          style={{
            position: "absolute",
            left: "45%",
            top: "60px",
            zIndex: "101"
          }}
        />
      )}
      {schedules && schedulesToJS.length > 0 && (
        <NarrativeRenderSchedules
          narrativeId={narrativeId}
          scheduledJobs={schedules.toJS()}
          loadScheduleCallback={() =>
            getNarrativeRenderSchedulesByNarrativeId(narrativeId)
          }
          isModal={isModal}
          fromNarrativeDashboard={!props.fromNarrativeEditor}
        />
      )}
      {!loadingSchedules && schedules && schedules.toJS().length === 0 && (
        <>
          <h4 style={{ marginLeft: "20px" }}>
            <Link
              title={`Back to Narrative Dashboard for Narrative ${narrativeId}`}
              onClick={e => {
                props.history.goBack()
                e.preventDefault()
              }}
            >
              <strong>
                <ArrowBack />
                Back to Narrative Dashboard
              </strong>
            </Link>{" "}
          </h4>
          <div className={classes.emptyModal}>
            <h1>Job is Empty</h1>
          </div>
        </>
      )}
    </div>
  )
}

export default withStyles(styles)(NarrativeSchedule)
