import React from "react"
import PropTypes from "prop-types"
import ReactTable from "react-table-6"
import moment, { now } from "moment"
import withStyles from "@mui/styles/withStyles"
import AccessTime from "@mui/icons-material/AccessTime"
import Language from "@mui/icons-material/Language"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Button from "components/CustomButtons/Button"
import TimeAgo from "react-timeago"
import { Cookies } from "tools/storage"
import { groupCollectionBy } from "tools/CollectionHelper"
import CardBody from "components/Card/CardBody"
import CardFooter from "components/Card/CardFooter"
import Card from "components/Card/Card"
import CardIcon from "components/Card/CardIcon"
import CardHeader from "components/Card/CardHeader"
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle"
import { Link } from "react-router-dom"
import { globalSettings } from "variables/general"
import Helpers from "tools/Helpers.js"
import {
  CircularProgress,
  FormControl,
  Tab,
  Tabs,
  Tooltip
} from "@mui/material"
import { TabPanel } from "components/TabPanel/TabPanel"
import { RssFeed, LibraryBooks, Loop } from "@mui/icons-material"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import relativeTime from "dayjs/plugin/relativeTime"
import advancedFormat from "dayjs/plugin/advancedFormat"
import { observer, inject } from "mobx-react"
import Typography from "@mui/material/Typography"
import { get } from "tools/request"

// The following was added to remove ReactTable 6 errors per this blog post
// https://spectrum.chat/react-table/general/v6-10-3-proptypes-fail-for-component-props~9ca49c7e-df58-4488-97eb-ab168570d597
/* eslint-disable react/forbid-foreign-prop-types */
// @ts-ignore
delete ReactTable.propTypes.TableComponent
// @ts-ignore
delete ReactTable.propTypes.TheadComponent
// @ts-ignore
delete ReactTable.propTypes.TbodyComponent
// @ts-ignore
delete ReactTable.propTypes.TrGroupComponent
// @ts-ignore
delete ReactTable.propTypes.TrComponent
// @ts-ignore
delete ReactTable.propTypes.ThComponent
// @ts-ignore
delete ReactTable.propTypes.TdComponent
// @ts-ignore
delete ReactTable.propTypes.TfootComponent
// @ts-ignore
delete ReactTable.propTypes.FilterComponent
// @ts-ignore
delete ReactTable.propTypes.ExpanderComponent
// @ts-ignore
delete ReactTable.propTypes.PivotValueComponent
// @ts-ignore
delete ReactTable.propTypes.AggregatedComponent
// @ts-ignore
delete ReactTable.propTypes.PivotComponent
// @ts-ignore
delete ReactTable.propTypes.PaginationComponent
// @ts-ignore
delete ReactTable.propTypes.PreviousComponent
// @ts-ignore
delete ReactTable.propTypes.NextComponent
// @ts-ignore
delete ReactTable.propTypes.LoadingComponent
// @ts-ignore
delete ReactTable.propTypes.NoDataComponent
// @ts-ignore
delete ReactTable.propTypes.ResizerComponent
// @ts-ignore
delete ReactTable.propTypes.PadRowComponent
/* eslint-enable react/forbid-foreign-prop-types */

dayjs.extend(utc).extend(relativeTime).extend(advancedFormat)

const useStore = component =>
  inject(({ store }) => ({
    accountPermissions: store.accountStore.accountPermissions,
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization
  }))(observer(component))

class RenderDashboard extends React.Component {
  constructor(props) {
    super(props)
    let cookies = new Cookies()
    const qaDashboardRecentFeedEntriesPageSize = cookies.get(
      "qaDashboardRecentFeedEntriesPageSize"
    )
      ? Number(cookies.get("qaDashboardRecentFeedEntriesPageSize"))
      : 25

    this.state = {
      isFilterPanelOpen: false,
      isLoadingQAPortalMetrics: true,
      siteInfo: [],
      now: new Date(),
      allEvents: [],
      selectedEvents: [],
      relatedFeeds: [],
      modalOpen: false,
      joblogs: [],
      pausedJobs: [],
      isPausedJobsLoading: false,
      isPausedJobsLoaded: false,
      recentRenderSchedules: [],
      isRecentRenderSchedulesLoading: false,
      isRecentRenderSchedulesLoaded: false,
      selectedJobId: -1,
      expandedRows: {},
      expandedJobRows: {},
      expandedJob: null,
      modalBody: null,
      recentFeedEntriesPageSize: qaDashboardRecentFeedEntriesPageSize,
      currentView: 0,
      tableFilter: [],
      recentRenders: [],
      brokenResult: [],
      isBrokenBlocksLoading: false
    }
    this.handleClose = this.handleClose.bind(this)
    this.selectedEvent = this.selectedEvent.bind(this)
    this.retrieveJobLogs = this.retrieveJobLogs.bind(this)
  }

  componentDidMount() {
    this.props.setShowChangeCurrentOrganization(false)
    this.retrieveRecentRenders()
    this.retrieveRecentFeedEntries()
  }

  retrieveRecentRenders() {
    this.setState({ isLoadingQAPortalMetrics: true })
    get(`${globalSettings.apiBaseUrl}/api/qa/renderresults`)
      .then(Response => Response.json())
      .then(renderResults => {
        if (renderResults) {
          this.setState({
            recentRenders: renderResults,
            isLoadingQAPortalMetrics: false
          })
        }
      })
  }

  retrieveRecentFeedEntries() {
    this.setState({ isLoadingQAPortalMetrics: true })
    get(`${globalSettings.apiBaseUrl}/api/qa/feedentries`)
      .then(Response => Response.json())
      .then(feedEntries => {
        if (feedEntries) {
          this.setState({
            recentFeedEntries: feedEntries,
            isLoadingQAPortalMetrics: false
          })
        }
      })
  }

  retrieveJobLogs(narrativeRenderScheduleId) {
    this.setState({ joblogs: [], isLogResultsLoading: true })
    get(
      `${globalSettings.apiBaseUrl}/api/narrativerenderschedule/retrievejoboutputlog?narrativeRenderScheduleLogId=${narrativeRenderScheduleId}`
    )
      .then(Response => Response && Response.ok === true && Response.json())
      .then(result => {
        if (result) {
          this.setState({ joblogs: result, isLogResultsLoading: false })
        } else {
          return "problem loading data"
        }
      })
  }

  retrieveJobLogCount(narrativeRenderScheduleId) {
    this.setState({ jobLogCount: 0 })
    get(
      `${globalSettings.apiBaseUrl}/api/narrativerenderschedule/retrievejoblogcount?narrativeRenderScheduleId=${narrativeRenderScheduleId}`
    )
      .then(Response => Response.text())
      .then(text => {
        this.setState({
          jobLogCount: text
        })
      })
  }

  retrieveRecentRenderSchedules() {
    this.setState({
      recentRenderSchedules: [],
      isRecentRenderSchedulesLoading: true
    })
    get(`${globalSettings.apiBaseUrl}/api/qa/renderjobs`)
      .then(Response => Response && Response.ok === true && Response.json())
      .then(result => {
        if (result) {
          this.setState({
            recentRenderSchedules: result,
            isRecentRenderSchedulesLoading: false,
            isRecentRenderSchedulesLoaded: true
          })
        } else {
          return "problem loading data"
        }
      })
  }

  retrievePausedJobs() {
    this.setState({ pausedJobs: [], isPausedJobsLoading: true })
    get(`${globalSettings.apiBaseUrl}/api/qa/pausedrenders`)
      .then(Response => Response && Response.ok === true && Response.json())
      .then(result => {
        if (result) {
          this.setState({
            pausedJobs: result,
            isPausedJobsLoading: false,
            isPausedJobsLoaded: true
          })
        } else {
          return "problem loading data"
        }
      })
  }

  retrieveBrokenBlocks() {
    this.setState({ brokenResult: [], isBrokenBlocksLoading: true })
    get(`${globalSettings.apiBaseUrl}/api/feedentry/brokenblocksnapshots`)
      .then(resp => resp.json())
      .then(brokenBlocks => {
        if (brokenBlocks) {
          this.setState({
            brokenResult: brokenBlocks,
            isBrokenBlocksLoading: false
          })
        } else {
          return "problem loading Broken Blocks"
        }
      })
  }

  filterEvents(events, colors, filters) {
    return (
      events &&
      events.map(event => {
        if (event && filters.indexOf(event.eventorg) > -1) {
          //Get the event color for this league
          const eventColor =
            colors && colors.find(ec => ec.eventorg === event.eventorg)
          return {
            title: event.name,
            allDay: false,
            start: moment(event.scheduledstartdatetime).toDate(),
            end: event.scheduledenddatetime
              ? moment(event.scheduledenddatetime).toDate()
              : moment(event.scheduledstartdatetime).add(2, "hours").toDate(),
            color: eventColor ? eventColor.color : "default",
            ...event
          }
        }
      })
    )
  }

  addOrRemoveFilter = filter => {
    let filters = this.state.selectedEventTypes
    const { allEvents, eventColors } = this.state
    if (filters.includes(filter)) {
      filters = filters.filter(value => value !== filter)
    } else {
      filters.push(filter)
    }
    const selectedEvents = this.filterEvents(allEvents, eventColors, filters)
    this.setState({ selectedEventTypes: filters, selectedEvents })
  }

  intFilterProps(propName, properties) {
    return {
      filterMethod: (filter, row) => {
        let currentValue = row._original[propName] || 0
        if (filter.value === "all") {
          return true
        }
        if (filter.value === ">0") {
          return currentValue > 0
        }
        if (filter.value === "=0") {
          return currentValue === 0
        }
        // NOTE: string versus integer comparison,
        // don't use === operator
        // eslint-disable-next-line eqeqeq
        return row._original[propName] == filter.value
      },
      Filter: ({ filter, onChange }) => {
        if (!properties || properties.size === 0) {
          return null
        }

        const distinctOptions = Object.keys(
          groupCollectionBy(properties, p => p[propName])
        ).sort((a, b) => {
          let comparison = 0
          if (Number(a) > Number(b)) {
            comparison = 1
          } else if (Number(a) < Number(b)) {
            comparison = -1
          }
          return comparison
        })
        // build up the <select> element <option> element JSX style
        let options = []
        distinctOptions.forEach(opt => {
          options.push(
            <option value={opt} key={opt}>
              {opt}
            </option>
          )
        })
        return (
          <select
            onChange={event => {
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">All</option>
            <option value="=0">0</option>
            <option value=">0">Not 0</option>
            {/* {options} */}
          </select>
        )
      }
    }
  }
  stringFilterProps(propName, properties) {
    return {
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true
        }
        // NOTE: string versus integer comparison,
        // don't use === operator
        return (
          // eslint-disable-next-line eqeqeq
          Helpers.parseNestedObjectSelector(row._original, propName) ==
          filter.value
        )
      },
      Filter: ({ filter, onChange }) => {
        if (!properties || properties.size === 0) {
          return null
        }
        const distinctOptions = Object.keys(
          groupCollectionBy(properties, p =>
            this.getItemValueFromPath(p, propName)
          )
        ).sort((a, b) => {
          // Use toUpperCase() to ignore character casing
          const orgA = a.toUpperCase()
          const orgB = b.toUpperCase()
          let comparison = 0
          if (orgA > orgB) {
            comparison = 1
          } else if (orgA < orgB) {
            comparison = -1
          }
          return comparison
        })
        // build up the <select> element <option> element JSX style
        let options = []
        distinctOptions.forEach(opt => {
          options.push(
            <option value={opt} key={opt}>
              {opt}
            </option>
          )
        })
        return (
          <select
            onChange={event => {
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">All</option>
            {options}
          </select>
        )
      }
    }
  }
  libraryNameFilterProps(properties) {
    return {
      filterMethod: (filter, row) => {
        const libraryName = row._original.libraryName

        if (filter.value === "all") {
          return true
        }

        if (filter.value === "none") {
          // Check for no library assigned (assuming null, undefined, or empty string)
          return (
            !row._original.library_ID ||
            row._original.library_ID === row._original.output_ID
          )
        }

        // String comparison, case-insensitive
        return (
          libraryName &&
          libraryName.toLowerCase() === filter.value.toLowerCase()
        )
      },
      Filter: ({ filter, onChange }) => {
        if (!properties || properties.length === 0) {
          return null
        }

        // Filter out library names where library_ID matches output_ID
        const filteredProperties = properties.filter(
          p => p.library_ID !== p.output_ID
        )

        let libraryNames = filteredProperties.map(item => item.libraryName)

        // Remove duplicates, sort, and add 'None' option for unassigned items
        libraryNames = [...new Set(libraryNames)].sort((a, b) =>
          a.localeCompare(b, undefined, { sensitivity: "base" })
        )
        libraryNames.unshift("None") // Insert 'None' at the start

        let options = libraryNames.map(name => (
          <option
            value={name.toLowerCase() === "none" ? "none" : name}
            key={name}
          >
            {name}
          </option>
        ))

        return (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">All</option>
            {options}
          </select>
        )
      }
    }
  }
  idedStringFilterProps(propName, properties) {
    return {
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true
        }
        // NOTE: string versus integer comparison,
        // don't use === operator
        return (
          // eslint-disable-next-line eqeqeq
          Helpers.parseNestedObjectSelector(row._original, propName[1]) ==
          filter.value
        )
      },
      Filter: ({ filter, onChange }) => {
        if (!properties || properties.size === 0) {
          return null
        }

        const distinctOptions = Object.keys(
          groupCollectionBy(properties, p =>
            this.getItemValueFromPath(p, propName)
          )
        ).sort((a, b) => {
          // Use toUpperCase() to ignore character casing
          const orgA = a.toUpperCase()
          const orgB = b.toUpperCase()
          let comparison = 0
          if (orgA > orgB) {
            comparison = 1
          } else if (orgA < orgB) {
            comparison = -1
          }
          return comparison
        })
        // build up the <select> element <option> element JSX style
        let options = []
        let tmpOptions = []
        distinctOptions.forEach(opt => {
          let loc = opt.indexOf(" : ")
          let tmp
          tmp = opt.substring(loc, opt.length).replace(" : ", "")
          tmpOptions.push({ val: tmp, text: opt })
        })
        tmpOptions.forEach(opt => {
          options.push(
            <option value={opt.val} key={opt.val}>
              {opt.text}
            </option>
          )
        })
        return (
          <select
            onChange={event => {
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">All</option>
            {options}
          </select>
        )
      }
    }
  }

  dateFilterProps(propName) {
    return {
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true
        }
        let now = new Date()
        let futureTime = new Date()
        let pastTime = new Date()
        let columnDateTime = new Date(this.getItemValueFromPath(row, propName))
        if (filter.value === "future") {
          return columnDateTime >= now
        }
        if (filter.value === "past") {
          return columnDateTime < now
        }
        if (filter.value === "next7Days") {
          return (
            columnDateTime > now &&
            columnDateTime < futureTime.setHours(futureTime.getHours() + 7 * 24)
          )
        }
        if (filter.value === "next24hours") {
          return (
            columnDateTime > now &&
            columnDateTime < futureTime.setHours(futureTime.getHours() + 48)
          )
        }
        if (filter.value === "next1hour") {
          return (
            columnDateTime > now &&
            columnDateTime < futureTime.setHours(futureTime.getHours() + 2)
          )
        }
        if (filter.value === "within7Days") {
          return (
            columnDateTime > pastTime.setHours(pastTime.getHours() - 7 * 24) &&
            columnDateTime < futureTime.setHours(futureTime.getHours() + 7 * 24)
          )
        }
        if (filter.value === "within24hours") {
          return (
            columnDateTime > pastTime.setHours(pastTime.getHours() - 24) &&
            columnDateTime < futureTime.setHours(futureTime.getHours() + 48)
          )
        }
        if (filter.value === "within1hour") {
          return (
            columnDateTime > pastTime.setHours(pastTime.getHours() - 1) &&
            columnDateTime < futureTime.setHours(futureTime.getHours() + 2)
          )
        }
        if (filter.value === "past7Days") {
          return (
            columnDateTime > pastTime.setHours(pastTime.getHours() - 7 * 24) &&
            columnDateTime < now
          )
        }
        if (filter.value === "past24hours") {
          return (
            columnDateTime > pastTime.setHours(pastTime.getHours() - 24) &&
            columnDateTime < now
          )
        }
        if (filter.value === "past1hour") {
          return (
            columnDateTime > pastTime.setHours(pastTime.getHours() - 1) &&
            columnDateTime < now
          )
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value="future">Future</option>
          <option value="past">Past</option>
          <option value="next7Days">Next 7 Days</option>
          <option value="next24hours">Next 24 Hours</option>
          <option value="next1hour">Next 1 Hour</option>
          <option value="within7Days">Within 7 Days</option>
          <option value="within24hours">Within 24 Hours</option>
          <option value="within1hour">Within 1 Hour</option>
          <option value="past7Days">Past 7 Days</option>
          <option value="past24hours">Past 24 Hours</option>
          <option value="past1hour">Past 1 Hour</option>
        </select>
      ),
      Cell: cell =>
        cell.value && (
          <div>
            <TimeAgo
              date={cell.value}
              title={Helpers.prettyDateTimeinPacificTimeZone(cell.value)}
            />
          </div>
        )
    }
  }
  hourFilterProps(propName) {
    return {
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true
        }
        let pastTime = new Date(filter.value)
        // eslint-disable-next-line newline-per-chained-call
        let futureTime = new moment(pastTime).add(60, "m").toDate()

        let columnDateTime = new Date(this.getItemValueFromPath(row, propName))
        return columnDateTime > pastTime && columnDateTime < futureTime
      },
      Cell: cell =>
        cell.value && (
          <div>{Helpers.shortDateTimeinPacificTimeZone(cell.value)}</div>
        )
    }
  }

  rememberPageSizeChanges = pageSize => {
    this.setState({ pageSize })
    let cookies = new Cookies()
    cookies.set("qaDashboardRecentFeedEntriesPageSize", pageSize, {
      path: "/",
      expires: Helpers.CookieExpiration.OneMonth
    })
  }

  getItemValueFromPath(item, key) {
    if (!key || key === "") {
      return null
    }
    let value = ""
    if (typeof key !== "object") {
      //Make string values an array of length 1, keep arrays as arrays to iterate through
      key = [key]
    }
    key.forEach((ke, i) => {
      if (i !== 0) {
        value += " : "
      }
      if (ke && ke.indexOf(".") !== -1) {
        // eslint-disable-line no-negated-condition
        // handle nested keys
        value += ke.split(".").reduce(function (itemObj, nestedKey) {
          return itemObj[nestedKey]
        }, item)
      } else {
        value += item[ke]
      }
    })
    return value ? [].concat(value) : null
  }
  boolFilterProps(propName) {
    return {
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true
        }
        if (filter.value === "true") {
          return row[propName] === true
        }
        if (filter.value === "false") {
          return row[propName] === false
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value="false">Active</option>
          <option value="true">Archived</option>
        </select>
      )
    }
  }
  yesOrNoFilterProps(propName) {
    return {
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true
        }
        if (filter.value === "true") {
          return row[propName] === true
        }
        if (filter.value === "false") {
          return row[propName] === false
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      )
    }
  }
  selectedEvent(event) {
    const historyResults =
      (this.state.recentRenders && this.state.recentRenders.length) || 0
    if (historyResults === 0) {
      return null
    }
    const historyPageSize = 1
    const eventString = (
      <CardBody style={{ width: "60vw" }}>
        <h3 style={{ textAlign: "center" }}>
          <div
            style={{
              position: "relative",
              top: "3px",
              display: "inline-block",
              backgroundColor: event.color,
              width: "25px",
              height: "25px",
              border: "1px solid #efefef",
              marginRight: "5px"
            }}
          />
          {event.eventorg} - {event.title} ({event.id})
        </h3>
        <GridContainer style={{ textAlign: "center" }}>
          <GridItem xs={3}>
            <strong>
              <u>Type</u>
            </strong>
            <br />
            {event.eventorg}&nbsp;{event.category}
          </GridItem>
          <GridItem xs={3}>
            <strong>
              <u>Start</u>
            </strong>
            <br />
            {moment(event.start).format("LL")}
            <br />
            {moment(event.start).format("h:mm A")}
            &nbsp;
            {moment()
              .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
              .zoneAbbr()}
          </GridItem>
          <GridItem xs={3}>
            <strong>
              <u>End</u>
            </strong>
            <br />
            {moment(event.end).format("LL")}
            <br />
            {moment(event.end).format("h:mm A")}
            &nbsp;
            {moment()
              .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
              .zoneAbbr()}
          </GridItem>
          <GridItem xs={3}>
            <strong>
              <u>Status</u>
            </strong>
            <br />
            {event.status}
          </GridItem>
        </GridContainer>
        <Card>
          <CardBody style={{ paddingTop: 0 }}>
            {event.type === "GenFeed" &&
              this.state.recentRenders &&
              event.status !== "Scheduled" && (
                <div
                  style={{
                    background: "#ffffff"
                  }}
                >
                  <div
                    style={{
                      padding: " 20px 0 0 0"
                    }}
                  >
                    <ReactTable
                      data={this.state.recentRenders}
                      defaultFiltered={[
                        {
                          id: "id",
                          value: event.id
                        }
                      ]}
                      noDataText={
                        this.state.isLoadingQAPortalMetrics ? (
                          <CircularProgress />
                        ) : (
                          "No job history found."
                        )
                      }
                      columns={[
                        {
                          Header: "Id",
                          accessor: "id",
                          sortable: true,
                          filterable: true,
                          width: 80
                        },
                        {
                          Header: "Possible",
                          accessor: "possiblecount",
                          sortable: true,
                          Cell: cell => (
                            <div title="Possible">
                              {cell.original.possiblecount > 0 ? (
                                <span
                                  style={{
                                    fontWeight: "bold"
                                  }}
                                >
                                  {cell.original.possiblecount}
                                </span>
                              ) : (
                                cell.original.possiblecount
                              )}
                            </div>
                          ),
                          ...this.intFilterProps(
                            "possiblecount",
                            this.state.recentRenders
                          )
                        },
                        {
                          Header: "Created",
                          accessor: "createdcount",
                          sortable: true,
                          Cell: cell => (
                            <div title="Created">
                              {cell.original.createdcount > 0 ? (
                                <span
                                  style={{
                                    color: "green",
                                    fontWeight: "bold"
                                  }}
                                >
                                  {cell.original.createdcount}
                                </span>
                              ) : (
                                cell.original.createdcount
                              )}
                            </div>
                          ),
                          ...this.intFilterProps(
                            "createdcount",
                            this.state.recentRenders
                          )
                        },
                        {
                          Header: "Updated",
                          accessor: "updatedcount",
                          sortable: true,
                          Cell: cell => (
                            <div title="Updated">
                              {cell.original.updatedcount > 0 ? (
                                <span
                                  style={{
                                    color: "green",
                                    fontWeight: "bold"
                                  }}
                                >
                                  {cell.original.updatedcount}
                                </span>
                              ) : (
                                cell.original.updatedcount
                              )}
                            </div>
                          ),
                          ...this.intFilterProps(
                            "updatedcount",
                            this.state.recentRenders
                          )
                        },
                        {
                          Header: "Skipped",
                          accessor: "skippedcount",
                          sortable: true,
                          Cell: cell => (
                            <div title="Skipped">
                              {cell.original.skippedcount > 0 ? (
                                <span
                                  style={{
                                    color: "orange",
                                    fontWeight: "bold"
                                  }}
                                >
                                  {cell.original.skippedcount}
                                </span>
                              ) : (
                                cell.original.skippedcount
                              )}
                            </div>
                          ),
                          ...this.intFilterProps(
                            "skippedcount",
                            this.state.recentRenders
                          )
                        },
                        {
                          Header: "Published",
                          accessor: "publishedcount",
                          sortable: true,
                          Cell: cell => (
                            <div title="Published">
                              {cell.original.publishedcount > 0 ? (
                                <span
                                  style={{
                                    color: "green",
                                    fontWeight: "bold"
                                  }}
                                >
                                  {cell.original.publishedcount}
                                </span>
                              ) : (
                                cell.original.publishedcount
                              )}
                            </div>
                          ),
                          ...this.intFilterProps(
                            "publishedcount",
                            this.state.recentRenders
                          )
                        },
                        {
                          Header: "Flagged",
                          accessor: "flaggedcount",
                          sortable: true,
                          Cell: cell => (
                            <div title="Flagged">
                              {cell.original.flaggedcount > 0 ? (
                                <span
                                  style={{
                                    color: "red",
                                    fontWeight: "bold"
                                  }}
                                >
                                  {cell.original.flaggedcount}
                                </span>
                              ) : (
                                cell.original.flaggedcount
                              )}
                            </div>
                          ),
                          ...this.intFilterProps(
                            "flaggedcount",
                            this.state.recentRenders
                          )
                        },
                        {
                          Header: "Killswitched",
                          accessor: "killswitchedcount",
                          sortable: true,
                          width: 120,
                          Cell: cell => (
                            <div title="Killswitched">
                              {cell.original.killswitchedcount > 0 ? (
                                <span
                                  style={{
                                    color: "red",
                                    fontWeight: "bold"
                                  }}
                                >
                                  {cell.original.killswitchedcount}
                                </span>
                              ) : (
                                cell.original.killswitchedcount
                              )}
                            </div>
                          ),
                          ...this.intFilterProps(
                            "killswitchedcount",
                            this.state.recentRenders
                          )
                        },
                        {
                          Header: "Start",
                          accessor: "startedexecutiondatetime",
                          ...this.dateFilterProps("startedexecutiondatetime"),
                          width: 120
                        },
                        {
                          Header: "End",
                          accessor: "finishedexecutiondatetime",
                          filterAll: false,
                          ...this.dateFilterProps("finishedexecutiondatetime"),
                          width: 120
                        },
                        {
                          Header: "Duration",
                          Cell: cell => {
                            let start = moment(
                              cell.original.startedexecutiondatetime
                            )
                            let end = moment(
                              cell.original.finishedexecutiondatetime || now()
                            )

                            return (
                              <strong>
                                {moment.duration(end.diff(start)).humanize()}
                              </strong>
                            )
                          },
                          width: 100,
                          filterable: false,
                          sortable: false
                        },
                        {
                          Header: "Timed Out",
                          accessor: "timedOut",
                          Cell: cell =>
                            cell.value ? <span>Yes</span> : <span>No</span>,
                          ...this.yesOrNoFilterProps(
                            "timedOut",
                            this.state.recentRenders
                          ),
                          width: 130
                        },
                        {
                          Header: "Manually Killed",
                          accessor: "killed",
                          Cell: cell =>
                            cell.value ? <span>Yes</span> : <span>No</span>,
                          ...this.yesOrNoFilterProps(
                            "killed",
                            this.state.recentRenders
                          ),
                          width: 140
                        }
                      ]}
                      filterable={true}
                      defaultPageSize={historyPageSize}
                      showPaginationTop={historyResults > historyPageSize}
                      showPaginationBottom={false}
                      className="-striped -highlight"
                      style={{
                        border: "1px solid rgba(0,0,0,0.05)",
                        background: "rgba(0,0,0,0.01)",
                        padding: "5px"
                      }}
                      defaultSorted={[
                        {
                          id: "startDateTime",
                          desc: false
                        }
                      ]}
                    >
                      {(state, makeTable) => {
                        let recordsInfoText = ""
                        const {
                          filtered,
                          pageRows,
                          pageSize,
                          sortedData,
                          page
                        } = state

                        if (sortedData && sortedData.length > 0) {
                          let isFiltered = filtered.length > 0
                          let totalRecords = sortedData.length
                          let recordsCountFrom = page * pageSize + 1
                          let recordsCountTo =
                            recordsCountFrom + pageRows.length - 1
                          if (isFiltered) {
                            recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered runs`
                          } else {
                            recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} runs`
                          }
                        } else {
                          recordsInfoText = this.state.isLoadingQAPortalMetrics
                            ? "Loading completed runs, please wait..."
                            : "This Job Has Not Run"
                        }
                        return (
                          <div className="main-grid">
                            <div className="above-table text-right">
                              <div className="col-sm-12">
                                <span className="records-info">
                                  {recordsInfoText}
                                  <Tooltip title="Reload">
                                    <Button
                                      justIcon
                                      round
                                      simple
                                      color="primary"
                                      className="info"
                                      id="reload"
                                      onClick={() => {
                                        this.retrieveRecentFeedEntries()
                                      }}
                                    >
                                      {this.state.isLoadingQAPortalMetrics ? (
                                        <CircularProgress
                                          thickness={1}
                                          size={30}
                                        />
                                      ) : (
                                        <Loop />
                                      )}
                                    </Button>
                                  </Tooltip>
                                </span>
                              </div>
                            </div>
                            {makeTable()}
                          </div>
                        )
                      }}
                    </ReactTable>
                  </div>
                </div>
              )}
            {event.type === "PublishEvent" && this.state.recentFeedEntries && (
              <div
                style={{
                  background: "#ffffff"
                }}
              >
                <div
                  style={{
                    padding: " 20px 0 0 0"
                  }}
                >
                  <ReactTable
                    data={this.state.recentFeedEntries}
                    defaultFiltered={[
                      {
                        id: "feed_id",
                        value: event.id
                      },
                      {
                        id: "modifieddatetime",
                        value: event.scheduledstartdatetime
                      }
                    ]}
                    noDataText={
                      this.state.isLoadingQAPortalMetrics ? (
                        <CircularProgress />
                      ) : (
                        "No feed entries found."
                      )
                    }
                    columns={[
                      {
                        Header: "Id",
                        accessor: "feed_id"
                      },
                      {
                        Header: "Feed",
                        accessor: "feed_id",
                        Cell: cell => (
                          <Link
                            to={{
                              pathname: `/portal/feed-entries/${cell.value}`
                            }}
                            title="View feed entries"
                          >
                            {cell.original.feed}
                          </Link>
                        ),
                        ...this.stringFilterProps(
                          "feed",
                          this.state.recentFeedEntries
                        ),
                        width: 275
                      },
                      {
                        Header: "Title",
                        accessor: "feedentry_id",
                        Cell: cell => (
                          <div>
                            <Link
                              to={{
                                pathname: `/portal/feed-entries/entry/${cell.value}`
                              }}
                              title="View feed entries"
                            >
                              {cell.original.title}
                            </Link>
                          </div>
                        ),
                        sortable: false,
                        filterable: false,
                        width: 450
                      },
                      {
                        Header: "Status",
                        accessor: "status",
                        ...this.stringFilterProps(
                          "status",
                          this.state.recentFeedEntries
                        ),
                        width: 125
                      },
                      {
                        Header: "Archived",
                        accessor: "isArchived",
                        ...this.boolFilterProps("Archived"),
                        width: 125
                      },
                      {
                        Header: "Modified",
                        accessor: "modifieddatetime",
                        ...this.hourFilterProps("modifieddatetime"),
                        width: 300
                      }
                    ]}
                    filterable={true}
                    defaultPageSize={25}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                    defaultSorted={[
                      {
                        id: "modifieddatetime",
                        desc: true
                      }
                    ]}
                    getTrProps={(state, rowInfo) => {
                      if (rowInfo) {
                        let returnObject = { style: {} }
                        switch (rowInfo.original.feedEntryStatus) {
                          case 1: // Draft
                            returnObject = {
                              style: {
                                background: "#ccc"
                              }
                            }
                            break
                          case 2: // Review
                            returnObject = {
                              style: {
                                background: "#ffd900"
                              }
                            }
                            break
                        }
                        if (rowInfo.original.isArchived) {
                          returnObject.style.textDecoration = "line-through"
                        }
                        if (
                          new Date(rowInfo.original.publishedDateTime) >
                          new Date()
                        ) {
                          returnObject.style.fontStyle = "italic"
                        }
                        return returnObject
                      } else {
                        return {}
                      }
                    }}
                  >
                    {(state, makeTable) => {
                      const { filtered, pageRows, pageSize, sortedData, page } =
                        state
                      let isFiltered = false
                      let recordsCountFrom = 0
                      let recordsCountTo = 0
                      let totalRecords = 0
                      isFiltered = filtered.length > 0
                      totalRecords = sortedData.length
                      recordsCountFrom = page * pageSize + 1
                      recordsCountTo = recordsCountFrom + pageRows.length - 1
                      let niceDate = new Date(event.actualstart)
                      // eslint-disable-next-line newline-per-chained-call
                      let endDate = new moment(niceDate).add(60, "m").toDate()
                      return (
                        <div>
                          <GridContainer container>
                            <GridItem xs={12} className="text-center">
                              <FormControl>
                                <span className="records-info">
                                  {totalRecords > 0 && isFiltered && (
                                    <span>
                                      {recordsCountFrom}-{recordsCountTo} of{" "}
                                      {totalRecords} feed entries published
                                      between {niceDate.toLocaleTimeString()}{" "}
                                      and {endDate.toLocaleTimeString()}
                                    </span>
                                  )}
                                  {totalRecords > 0 && !isFiltered && (
                                    <span>
                                      {recordsCountFrom}-{recordsCountTo} of{" "}
                                      {totalRecords} total feed entries on{" "}
                                      {Helpers.prettyDateTimeinPacificTimeZone(
                                        niceDate
                                      )}
                                    </span>
                                  )}
                                  {totalRecords === 0 &&
                                    (this.state.isLoadingQAPortalMetrics
                                      ? "Loading feed entries, please wait..."
                                      : "No feed entries.")}
                                </span>
                              </FormControl>
                            </GridItem>
                          </GridContainer>
                          {makeTable()}
                        </div>
                      )
                    }}
                  </ReactTable>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      </CardBody>
    )
    this.setState({ modalOpen: true, modalBody: eventString })
  }

  handleClose() {
    this.setState({ modalOpen: false, modalBody: "" })
  }

  eventColors(event) {
    return {
      style: { backgroundColor: event.color, cursor: "pointer" }
    }
  }

  dayJsLocal = date => dayjs(date).local().format("MM-DD-YYYY HH:mm:ss")

  render() {
    if (this.state.isLoadingQAPortalMetrics) {
      return <div>loading...</div>
    }
    const { classes } = this.props

    const {
      recentFeedEntries: feedEntries,
      currentView
      //brokenResult: brokenBlocks
    } = this.state

    const handleChange = (event, newValue) => {
      const {
        isPausedJobsLoaded,
        isPausedJobsLoading,
        isRecentRenderSchedulesLoading,
        isBrokenBlocksLoading
      } = this.state
      if (newValue === 1 && !(isPausedJobsLoaded || isPausedJobsLoading)) {
        this.retrievePausedJobs()
      }
      if (newValue === 2 && !isRecentRenderSchedulesLoading) {
        this.retrieveRecentRenderSchedules()
      }
      if (newValue === 3 && !isBrokenBlocksLoading) {
        this.retrieveBrokenBlocks()
      }
      this.setState({
        currentView: newValue
      })
    }

    //Broken Blocks Table Data

    return (
      <div>
        {this.props.accountPermissions &&
          !this.props.accountPermissions.CanSeeQADashboard && (
            <GridContainer>
              <GridItem>
                <Card>
                  <CardHeader>
                    <h3>Data Skrive Customer Success Contact information</h3>
                  </CardHeader>
                  <CardBody>
                    <div className={classes.typo}>
                      <div className={classes.note}>
                        Standard Hours of Operation
                      </div>
                      <h4>Pacific Time 8 am to 6 pm</h4>
                    </div>
                    <div className={classes.typo}>
                      <div className={classes.note}>E-mail address</div>
                      <h4>
                        <a href="mailto:cs@dataskrive.com?subject=DataSkrive Portal Support">
                          cs@dataskrive.com
                        </a>
                      </h4>
                    </div>
                    <div className={classes.typo}>
                      <div className={classes.note}>Mailing Address</div>
                      <h4>100 S. King Street, Suite 400, Seattle, WA 98104 </h4>
                    </div>
                    <div className={classes.typo}>
                      <div className={classes.note}>General Information</div>
                      <h4>
                        If you need off hours support and have not been able to
                        reach your assigned Customer Success Manager, please
                        call: <a href="tel:206-214-5206">206-214-5206</a>
                      </h4>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          )}
        {this.props.accountPermissions &&
          this.props.accountPermissions.CanSeeQADashboard && (
            <div>
              <Tabs
                value={currentView}
                onChange={handleChange}
                aria-label="QADashboard Tabs TabViewer"
              >
                <Tab label="Render Results" />
                <Tab label="Paused Renders" />
                <Tab label="Render Jobs" />
                <Tab label="Broken Blocks" />
              </Tabs>
              <TabPanel value={currentView} index={0}>
                <GridContainer>
                  <GridItem xs={12}>
                    <Card chart className={classes.cardHover}>
                      <CardHeader color="info" icon>
                        <CardIcon color="info">
                          <Language />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>
                          Recent Render Results
                        </h4>
                      </CardHeader>
                      <CardBody>
                        <ReactTable
                          aria-label="QADashboard Table RenderResults"
                          data={this.state.recentRenders}
                          defaultFiltered={[
                            {
                              id: "feedStatusId",
                              value: 3
                            }
                          ]}
                          noDataText={
                            this.state.isLoadingQAPortalMetrics ? (
                              <CircularProgress />
                            ) : (
                              "No job history found."
                            )
                          }
                          columns={[
                            {
                              Header: "Job Id",
                              accessor: "id",
                              sortable: true,
                              filterable: true,
                              width: 80
                            },
                            {
                              Header: "Schedule",
                              accessor: "narrativeRenderScheduleId",
                              sortable: true,
                              filterable: true,
                              ...this.stringFilterProps(
                                "narrativeRenderScheduleId",
                                this.state.recentRenders
                              )
                            },
                            {
                              Header: "Organization",
                              accessor: "organization",
                              sortable: true,
                              filterable: true,
                              ...this.stringFilterProps(
                                "organization",
                                this.state.recentRenders
                              ),
                              width: 150
                            },
                            {
                              sortable: true,
                              filterable: true,
                              width: 250,
                              Header: "Feed",
                              accessor: "feedName",
                              Cell: cell => (
                                <Link
                                  to={{
                                    pathname: `/portal/feed-entries/${cell.value}`
                                  }}
                                  title="View feed entries"
                                >
                                  {cell.original.feedName}
                                </Link>
                              ),
                              ...this.stringFilterProps(
                                "feedName",
                                this.state.recentRenders
                              )
                            },
                            {
                              Header: "Feed Status",
                              accessor: "feedStatusId",
                              Cell: cell => (
                                <div>
                                  {Helpers.convertFeedStatusIdToStatusName(
                                    cell.original.feedStatusId
                                  )}
                                </div>
                              ),
                              width: 125,
                              sortMethod: (a, b) =>
                                Helpers.convertFeedStatusIdToStatusName(a) >
                                Helpers.convertFeedStatusIdToStatusName(b)
                                  ? 1
                                  : -1,
                              filterMethod: (filter, row) => {
                                if (filter.value === "all") {
                                  return true
                                }
                                // NOTE: string versus integer comparison,
                                // don't use === operator
                                // eslint-disable-next-line eqeqeq
                                return row.feedStatusId == filter.value
                              },
                              Filter: ({ filter, onChange }) => {
                                if (!feedEntries || feedEntries.size === 0) {
                                  return null
                                }

                                const distinctStatuses = []
                                const map = new Map()
                                for (let entry of this.state.recentRenders) {
                                  if (!map.has(entry.feedStatusId)) {
                                    map.set(entry.feedStatusId, true)
                                    distinctStatuses.push({
                                      id: entry.feedStatusId,
                                      name: Helpers.convertFeedStatusIdToStatusName(
                                        entry.feedStatusId
                                      )
                                    })
                                  }
                                }
                                // inline sort by status name
                                distinctStatuses.sort((A, B) => {
                                  // Use toUpperCase() to ignore character casing
                                  let comparison = 0
                                  if (A > B) {
                                    comparison = 1
                                  } else if (A < B) {
                                    comparison = -1
                                  }
                                  return comparison
                                })
                                // build up the <select> element <option> element JSX style
                                let options = []
                                distinctStatuses.forEach(status => {
                                  if (status.id !== null) {
                                    options.push(
                                      <option value={status.id} key={status.id}>
                                        {status.name}
                                      </option>
                                    )
                                  }
                                })
                                return (
                                  <select
                                    onChange={event => {
                                      onChange(event.target.value)
                                    }}
                                    style={{ width: "100%" }}
                                    value={filter ? filter.value : "all"}
                                  >
                                    <option value="all">All</option>
                                    {options}
                                  </select>
                                )
                              }
                            },
                            {
                              Header: "Output",
                              accessor: "narrativeId",
                              sortable: true,
                              filterable: true,
                              width: 325,
                              ...this.idedStringFilterProps(
                                ["narrativeId", "narrativeName"],
                                this.state.recentRenders
                              ),
                              Cell: cell => (
                                <Link
                                  to={{
                                    pathname: `/portal/narrative/${cell.value}`
                                  }}
                                  title="View Output"
                                >
                                  {cell.original.narrativeName}
                                </Link>
                              )
                            },
                            {
                              sortable: true,
                              filterable: true,
                              width: 150,
                              Header: "League",
                              accessor: "league",
                              ...this.stringFilterProps(
                                "league",
                                this.state.recentRenders
                              )
                            },
                            {
                              Header: "Possible",
                              accessor: "possibleCount",
                              sortable: true,
                              Cell: cell => (
                                <div title="Possible">
                                  {cell.original.possibleCount > 0 ? (
                                    <span
                                      style={{
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {cell.original.possibleCount}
                                    </span>
                                  ) : (
                                    cell.original.possibleCount
                                  )}
                                </div>
                              ),
                              ...this.intFilterProps(
                                "possibleCount",
                                this.state.recentRenders
                              )
                            },
                            {
                              Header: "Created",
                              accessor: "createdCount",
                              sortable: true,
                              Cell: cell => (
                                <div title="Created">
                                  {cell.original.createdCount > 0 ? (
                                    <span
                                      style={{
                                        color: "green",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {cell.original.createdCount}
                                    </span>
                                  ) : (
                                    cell.original.createdCount
                                  )}
                                </div>
                              ),
                              ...this.intFilterProps(
                                "createdCount",
                                this.state.recentRenders
                              )
                            },
                            {
                              Header: "Updated",
                              accessor: "updatedCount",
                              sortable: true,
                              Cell: cell => (
                                <div title="Updated">
                                  {cell.original.updatedCount > 0 ? (
                                    <span
                                      style={{
                                        color: "green",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {cell.original.updatedCount}
                                    </span>
                                  ) : (
                                    cell.original.updatedCount
                                  )}
                                </div>
                              ),
                              ...this.intFilterProps(
                                "updatedCount",
                                this.state.recentRenders
                              )
                            },
                            {
                              Header: "Skipped",
                              accessor: "skippedCount",
                              sortable: true,
                              Cell: cell => (
                                <div title="Skipped">
                                  {cell.original.skippedCount > 0 ? (
                                    <span
                                      style={{
                                        color: "orange",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {cell.original.skippedCount}
                                    </span>
                                  ) : (
                                    cell.original.skippedCount
                                  )}
                                </div>
                              ),
                              ...this.intFilterProps(
                                "skippedCount",
                                this.state.recentRenders
                              )
                            },
                            {
                              Header: "Published",
                              accessor: "publishedCount",
                              sortable: true,
                              Cell: cell => (
                                <div title="Published">
                                  {cell.original.publishedCount > 0 ? (
                                    <span
                                      style={{
                                        color: "green",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {cell.original.publishedCount}
                                    </span>
                                  ) : (
                                    cell.original.publishedCount
                                  )}
                                </div>
                              ),
                              ...this.intFilterProps(
                                "publishedCount",
                                this.state.recentRenders
                              )
                            },
                            {
                              Header: "Flagged",
                              accessor: "flaggedCount",
                              sortable: true,
                              Cell: cell => (
                                <div title="Flagged For Review">
                                  {cell.original.flaggedCount > 0 ? (
                                    <span
                                      style={{
                                        color: "red",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {cell.original.flaggedCount}
                                    </span>
                                  ) : (
                                    cell.original.flaggedCount
                                  )}
                                </div>
                              ),
                              ...this.intFilterProps(
                                "flaggedCount",
                                this.state.recentRenders
                              )
                            },
                            {
                              Header: "Killswitched",
                              accessor: "killSwitchedCount",
                              sortable: true,
                              width: 120,
                              Cell: cell => (
                                <div title="Killswitched">
                                  {cell.original.killSwitchedCount > 0 ? (
                                    <span
                                      style={{
                                        color: "red",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {cell.original.killSwitchedCount}
                                    </span>
                                  ) : (
                                    cell.original.killSwitchedCount
                                  )}
                                </div>
                              ),
                              ...this.intFilterProps(
                                "killSwitchedCount",
                                this.state.recentRenders
                              )
                            },
                            {
                              Header: "Start",
                              accessor: "startedExecutionDateTime",
                              ...this.dateFilterProps(
                                "startedExecutionDateTime"
                              ),
                              width: 120
                            },
                            {
                              Header: "End",
                              accessor: "finishedExecutionDateTime",
                              ...this.dateFilterProps(
                                "finishedExecutionDateTime"
                              ),
                              width: 120
                            },
                            {
                              Header: "Duration",
                              Cell: cell => {
                                let start = moment(
                                  cell.original.startedExecutionDateTime
                                )
                                let end = moment(
                                  cell.original.finishedExecutionDateTime ||
                                    now()
                                )

                                return (
                                  <strong>
                                    {moment
                                      .duration(end.diff(start))
                                      .humanize()}
                                  </strong>
                                )
                              },
                              width: 100,
                              filterable: false,
                              sortable: false
                            },
                            {
                              Header: "Timed Out",
                              accessor: "timedOut",
                              Cell: cell =>
                                cell.value ? <span>Yes</span> : <span>No</span>,
                              ...this.yesOrNoFilterProps(
                                "timedOut",
                                this.state.recentRenders
                              ),
                              width: 130
                            },
                            {
                              Header: "Manually Killed",
                              accessor: "killed",
                              Cell: cell =>
                                cell.value ? <span>Yes</span> : <span>No</span>,
                              ...this.yesOrNoFilterProps(
                                "killed",
                                this.state.recentRenders
                              ),
                              width: 140
                            }
                          ]}
                          filterable={true}
                          defaultPageSize={50}
                          showPaginationTop={
                            this.state.recentRenders &&
                            this.state.recentRenders.length > 25
                          }
                          showPaginationBottom={false}
                          className="-striped -highlight -scrollEntries"
                          style={{
                            border: "1px solid rgba(0,0,0,0.05)",
                            background: "rgba(0,0,0,0.01)",
                            padding: "5px",
                            height: `${(window.innerHeight * 0.69).toFixed(
                              0
                            )}px`
                          }}
                          defaultSorted={[
                            {
                              id: "startDateTime",
                              desc: false
                            }
                          ]}
                          expanded={this.state.expandedRows}
                          onExpandedChange={expanded => {
                            // don't forget to save the 'expanded'
                            // so it can be fed back in as a prop
                            this.setState({ expanded })
                          }}
                          getTdProps={(state, row, col) => ({
                            onClick: (event, handleOriginal) => {
                              // do some stuff with the event
                              if (col.expander) {
                                this.retrieveJobLogs(row.original.id)
                                if (
                                  this.state.selectedJobId === row.original.id
                                ) {
                                  this.setState({
                                    selectedJobId: -1,
                                    expandedRows: { [row.viewIndex]: false }
                                  })
                                } else {
                                  this.setState({
                                    selectedJobId: row.original.id,
                                    expandedRows: { [row.viewIndex]: true }
                                  })
                                }
                              }
                              handleOriginal && handleOriginal()
                            }
                          })}
                          SubComponent={() => {
                            let results = this.state.joblogs
                            return (
                              <div
                                style={{
                                  background: "#ffffff"
                                }}
                              >
                                <h5>
                                  Job resulted in {results.length} possible
                                  entries.
                                </h5>
                                <div
                                  style={{
                                    padding: "20px",
                                    maxWidth: "1280px"
                                  }}
                                >
                                  <ReactTable
                                    data={results}
                                    noDataText={
                                      this.state.isLogResultsLoading ? (
                                        <CircularProgress />
                                      ) : (
                                        "No results found."
                                      )
                                    }
                                    columns={[
                                      {
                                        Header: "Log Id",
                                        accessor: "id",
                                        sortable: false,
                                        filterable: false,
                                        width: 100
                                      },
                                      {
                                        Header: "Feed Entry Id",
                                        accessor: "feedEntryId",
                                        Cell: cell => (
                                          <Link
                                            to={{
                                              pathname: `/portal/feed-entries/entry/${cell.value}`
                                            }}
                                            title="View feed entry details"
                                          >
                                            {cell.value}
                                          </Link>
                                        ),
                                        width: 150
                                      },
                                      {
                                        Header: "Status",
                                        accessor: "feedEntryStatus",
                                        Cell: cell => <div>{cell.value}</div>,
                                        ...this.stringFilterProps(
                                          "feedEntryStatus",
                                          results
                                        ),
                                        width: 250
                                      },
                                      {
                                        Header: "Message",
                                        accessor: "logMessage",
                                        Cell: cell => <div>{cell.value}</div>,
                                        ...this.stringFilterProps(
                                          "logMessage",
                                          results
                                        ),
                                        width: 250
                                      },
                                      {
                                        Header: "Content Type Id",
                                        accessor: "contentTypeId",
                                        ...this.stringFilterProps(
                                          "contentTypeId",
                                          results
                                        ),
                                        width: 100
                                      },
                                      {
                                        Header: "Content Id",
                                        accessor: "contentId",
                                        ...this.stringFilterProps(
                                          "contentId",
                                          results
                                        ),
                                        width: 100
                                      },
                                      {
                                        Header: "Ms.",
                                        accessor: "elapsedTimeMilliseconds",
                                        width: 100,
                                        Cell: cell => (
                                          <span title="Process time in MilliSeconds">
                                            {cell.value}
                                          </span>
                                        )
                                      },
                                      {
                                        Header: "Processed",
                                        accessor: "auditInfoResponse.createdOn",
                                        Cell: cell => (
                                          <div>
                                            <TimeAgo
                                              date={cell.value}
                                              title={Helpers.prettyDateTimeinPacificTimeZone(
                                                cell.value
                                              )}
                                            />
                                          </div>
                                        ),
                                        width: 300
                                      }
                                    ]}
                                    style={{
                                      border: "1px solid rgba(0,0,0,0.05)",
                                      background: "rgba(0,0,0,0.01)",
                                      padding: "5px"
                                    }}
                                    filterable={true}
                                    defaultPageSize={5}
                                    showPaginationTop={results.length > 5}
                                    showPaginationBottom={false}
                                    className="-striped -highlight"
                                  >
                                    {(state, makeTable) => {
                                      let recordsInfoText = ""
                                      const {
                                        filtered,
                                        pageRows,
                                        pageSize,
                                        sortedData,
                                        page
                                      } = state
                                      if (sortedData && sortedData.length > 0) {
                                        let isFiltered = filtered.length > 0
                                        let totalRecords = sortedData.length
                                        let recordsCountFrom =
                                          page * pageSize + 1
                                        let recordsCountTo =
                                          recordsCountFrom + pageRows.length - 1
                                        if (isFiltered) {
                                          recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered results`
                                        } else {
                                          recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} results`
                                        }
                                      } else {
                                        recordsInfoText = this.state
                                          .isLoadingQAPortalMetrics
                                          ? "Loading render log, please wait..."
                                          : "Nothing has been rendered from this schedule"
                                      }
                                      return (
                                        <div className="main-grid">
                                          <div className="above-table text-right">
                                            <div className="col-sm-12">
                                              <span className="records-info">
                                                {recordsInfoText}
                                                <Tooltip title="Reload">
                                                  <Button
                                                    justIcon
                                                    round
                                                    simple
                                                    color="primary"
                                                    className="info"
                                                    id="reload"
                                                    onClick={() => {
                                                      this.retrieveJobLogs(
                                                        this.state.selectedJobId
                                                      )
                                                    }}
                                                  >
                                                    {this.state
                                                      .isLoadingQAPortalMetrics ? (
                                                      <CircularProgress
                                                        thickness={1}
                                                        size={30}
                                                      />
                                                    ) : (
                                                      <Loop />
                                                    )}
                                                  </Button>
                                                </Tooltip>
                                              </span>
                                            </div>
                                          </div>
                                          {makeTable()}
                                        </div>
                                      )
                                    }}
                                  </ReactTable>
                                </div>
                              </div>
                            )
                          }}
                        >
                          {(state, makeTable) => {
                            let recordsInfoText = ""
                            const {
                              filtered,
                              pageRows,
                              pageSize,
                              sortedData,
                              page
                            } = state

                            if (sortedData && sortedData.length > 0) {
                              let isFiltered = filtered.length > 0
                              let totalRecords = sortedData.length
                              let recordsCountFrom = page * pageSize + 1
                              let recordsCountTo =
                                recordsCountFrom + pageRows.length - 1
                              if (isFiltered) {
                                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered runs`
                              } else {
                                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} runs`
                              }
                            } else {
                              recordsInfoText = this.state
                                .isLoadingQAPortalMetrics
                                ? "Loading completed runs, please wait..."
                                : "This Job Has Not Run"
                            }
                            return (
                              <div className="main-grid">
                                <div className="above-table text-right">
                                  <div className="col-sm-12">
                                    <span className="records-info">
                                      {recordsInfoText}
                                      <Tooltip title="Reload">
                                        <Button
                                          justIcon
                                          round
                                          simple
                                          color="primary"
                                          className="info"
                                          id="reload"
                                          onClick={() => {
                                            this.retrieveRecentFeedEntries()
                                          }}
                                        >
                                          {this.state
                                            .isLoadingQAPortalMetrics ? (
                                            <CircularProgress
                                              thickness={1}
                                              size={30}
                                            />
                                          ) : (
                                            <Loop />
                                          )}
                                        </Button>
                                      </Tooltip>
                                    </span>
                                  </div>
                                </div>
                                {makeTable()}
                              </div>
                            )
                          }}
                        </ReactTable>
                      </CardBody>
                      <CardFooter chart>
                        <div className={classes.stats}>
                          <AccessTime /> updated 1 minute ago
                        </div>
                      </CardFooter>
                    </Card>
                  </GridItem>
                </GridContainer>
              </TabPanel>
              <TabPanel value={currentView} index={1}>
                <GridContainer>
                  <GridItem xs={12}>
                    <Card chart className={classes.cardHover}>
                      <CardHeader color="info" icon>
                        <CardIcon color="info">
                          <Language />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>
                          <strong>Paused Renders</strong>
                        </h4>
                      </CardHeader>
                      <CardBody>
                        <ReactTable
                          aria-label="QADashboard Table PausedRenders"
                          loading={this.state.isPausedJobsLoading}
                          data={this.state.pausedJobs}
                          noDataText={
                            this.state.isPausedJobsLoading ? (
                              <CircularProgress />
                            ) : (
                              "No paused render schedules found."
                            )
                          }
                          filtered={this.state.tableFilter}
                          onFilteredChange={filtered => {
                            this.setState({
                              tableFilter: filtered
                            })
                          }}
                          columns={[
                            {
                              Header: "Schedule Id",
                              accessor: "id",
                              sortable: true,
                              filterable: true,
                              Cell: cell => (
                                <Link
                                  to={{
                                    pathname: `/portal/render-schedule`,
                                    search: `?id=${cell.original.id}`
                                  }}
                                  title={`Manage this schedule`}
                                >
                                  {cell.original.id}
                                </Link>
                              ),
                              width: 130
                            },
                            {
                              Header: "Organization",
                              accessor: "narrative.organization.name",
                              ...this.stringFilterProps(
                                "narrative.organization.name",
                                this.state.pausedJobs
                              ),
                              width: 150
                            },
                            {
                              Header: "League",
                              accessor: "narrative.leagueName",
                              sortable: true,
                              filterable: true,
                              width: 400,
                              ...this.idedStringFilterProps(
                                ["narrative.leagueId", "narrative.leagueName"],
                                this.state.pausedJobs
                              ),
                              Cell: cell => (
                                <div>{cell.original.narrative.leagueName}</div>
                              )
                            },
                            {
                              Header: "Feed Id",
                              accessor: "deliveryFeed.id",
                              sortable: true,
                              filterable: true,
                              Cell: cell => (
                                <Link
                                  to={{
                                    pathname: `/portal/feed-entries/${cell.original.deliveryFeed.id}`
                                  }}
                                  title={`View feed (${cell.original.deliveryFeed.id}) entries`}
                                >
                                  {cell.original.deliveryFeed.id}
                                </Link>
                              ),
                              width: 130
                            },
                            {
                              Header: "Feed",
                              accessor: "deliveryFeed.name",
                              ...this.stringFilterProps(
                                "deliveryFeed.name",
                                this.state.pausedJobs
                              ),
                              Cell: cell => (
                                <Link
                                  to={{
                                    pathname: `/portal/feed-entries/${cell.original.deliveryFeed.id}`
                                  }}
                                  title={`View feed (${cell.original.deliveryFeed.id}) entries`}
                                >
                                  <Button color="primary" justIcon round simple>
                                    <RssFeed />
                                  </Button>
                                  {cell.original.deliveryFeed.name}
                                </Link>
                              ),
                              width: 300
                            },
                            {
                              Header: "Output",
                              accessor: "narrative.name",
                              sortable: true,
                              filterable: true,
                              width: 400,
                              ...this.idedStringFilterProps(
                                ["narrative.id", "narrative.name"],
                                this.state.pausedJobs
                              ),
                              Cell: cell => (
                                <Link
                                  to={{
                                    pathname: `/portal/narrative/${cell.original.narrative.id}`
                                  }}
                                  title="View Output"
                                >
                                  {cell.original.narrative.name}
                                </Link>
                              )
                            },
                            {
                              Header: "Feed Status",
                              accessor: "deliveryFeed.feedStatus",
                              Cell: cell => (
                                <div>
                                  {Helpers.convertFeedStatusIdToStatusName(
                                    cell.original.deliveryFeed.feedStatus
                                  )}
                                </div>
                              ),
                              width: 125,
                              sortMethod: (a, b) =>
                                Helpers.convertFeedStatusIdToStatusName(a) >
                                Helpers.convertFeedStatusIdToStatusName(b)
                                  ? 1
                                  : -1,
                              filterMethod: (filter, row) => {
                                if (filter.value === "all") {
                                  return true
                                }
                                // NOTE: string versus integer comparison,
                                // don't use === operator
                                return (
                                  // eslint-disable-next-line eqeqeq
                                  row._original.deliveryFeed.feedStatus ==
                                  filter.value
                                )
                              },
                              Filter: ({ filter, onChange }) => {
                                if (!feedEntries || feedEntries.size === 0) {
                                  return null
                                }

                                const distinctStatuses = []
                                const map = new Map()
                                for (let entry of this.state.pausedJobs) {
                                  if (!map.has(entry.deliveryFeed.feedStatus)) {
                                    map.set(entry.deliveryFeed.feedStatus, true)
                                    distinctStatuses.push({
                                      id: entry.deliveryFeed.feedStatus,
                                      name: Helpers.convertFeedStatusIdToStatusName(
                                        entry.deliveryFeed.feedStatus
                                      )
                                    })
                                  }
                                }
                                // inline sort by status name
                                distinctStatuses.sort((A, B) => {
                                  // Use toUpperCase() to ignore character casing
                                  let comparison = 0
                                  if (A > B) {
                                    comparison = 1
                                  } else if (A < B) {
                                    comparison = -1
                                  }
                                  return comparison
                                })
                                // build up the <select> element <option> element JSX style
                                let options = []
                                distinctStatuses.forEach(status => {
                                  if (status.id !== null) {
                                    options.push(
                                      <option value={status.id} key={status.id}>
                                        {status.name}
                                      </option>
                                    )
                                  }
                                })
                                return (
                                  <select
                                    onChange={event => {
                                      onChange(event.target.value)
                                    }}
                                    style={{ width: "100%" }}
                                    value={filter ? filter.value : "all"}
                                  >
                                    <option value="all">All</option>
                                    {options}
                                  </select>
                                )
                              }
                            },
                            {
                              Header: "Audit Info",
                              accessor: "auditInfo.modifiedOn",
                              Cell: cell => Helpers.renderAuditInfoCell(cell),
                              width: 250,
                              filterable: false
                            }
                          ]}
                          filterable={true}
                          pageSize={
                            this.state.isPausedJobsLoading
                              ? 50
                              : this.state.pageSize
                          }
                          onPageSizeChange={pageSize => {
                            this.rememberPageSizeChanges(pageSize)
                          }}
                          showPaginationTop={false}
                          showPaginationBottom={true}
                          className="-striped -highlight"
                          defaultSorted={[
                            {
                              id: "auditInfo.modifiedOn",
                              desc: true
                            }
                          ]}
                          style={{
                            background: "rgba(0,0,0,0.01)",
                            padding: "10px",
                            maxWidth: "100%",
                            height: `${(window.innerHeight * 0.69).toFixed(
                              0
                            )}px`
                          }}
                          getTrProps={(state, rowInfo) => {
                            if (rowInfo) {
                              let returnObject = {
                                style: {}
                              }
                              if (rowInfo.original.isArchived) {
                                returnObject.style.textDecoration =
                                  "line-through"
                              }
                              if (rowInfo.original.isInProcess) {
                                returnObject.style.background = "#ffd900"
                              }
                              if (
                                rowInfo.original.deliveryFeed.feedStatus === 3
                              ) {
                                // Warn on active jobs
                                returnObject.style.background =
                                  "rgb(255 0 0 / 29%)"
                                returnObject.style.color = "#000000"
                              }
                              if (
                                new Date(rowInfo.original.startDateTime) >
                                new Date()
                              ) {
                                returnObject.style.fontStyle = "italic"
                              }
                              return returnObject
                            } else {
                              return {}
                            }
                          }}
                        >
                          {(state, makeTable) => {
                            let recordsInfoText = ""

                            const {
                              filtered,
                              pageRows,
                              pageSize,
                              sortedData,
                              page
                            } = state

                            if (sortedData && sortedData.length > 0) {
                              let isFiltered = filtered.length > 0
                              let totalRecords = sortedData.length
                              let recordsCountFrom = page * pageSize + 1
                              let recordsCountTo =
                                recordsCountFrom + pageRows.length - 1
                              if (isFiltered) {
                                recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered schedules`
                              } else {
                                recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords} schedules`
                              }
                            } else {
                              recordsInfoText = this.state.isPausedJobsLoading
                                ? "Loading paused schedules, please wait..."
                                : "No paused schedules found"
                            }
                            return (
                              <div className="main-grid">
                                <div className="above-table text-right">
                                  <div className="col-sm-12">
                                    <span className="records-info">
                                      {recordsInfoText}
                                    </span>
                                    <Tooltip
                                      title="Reload"
                                      placement="top"
                                      arrow={true}
                                    >
                                      <Button
                                        justIcon
                                        round
                                        simple
                                        color="primary"
                                        className="info"
                                        id="reload"
                                        onClick={() => {
                                          this.retrievePausedJobs()
                                        }}
                                      >
                                        {this.state.isPausedJobsLoading ? (
                                          <CircularProgress
                                            thickness={1}
                                            size={30}
                                          />
                                        ) : (
                                          <Loop />
                                        )}
                                      </Button>
                                    </Tooltip>{" "}
                                  </div>
                                </div>
                                {makeTable()}
                              </div>
                            )
                          }}
                        </ReactTable>
                        <div xs={3} sm={3} md={3}>
                          <div style={{ fontStyle: "italic" }}>
                            italic = future start date/time
                          </div>
                          <div style={{ backgroundColor: "#ffd900" }}>
                            yellow = `In Process` status
                          </div>
                          <div style={{ backgroundColor: "#ccc" }}>
                            gray = `Paused` status
                          </div>
                          <div
                            style={{
                              backgroundColor: "rgb(255 0 0 / 29%)",
                              color: "#000000"
                            }}
                          >
                            red - Indicates Paused Jobs for{" "}
                            <strong>Active</strong> Feeds
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </TabPanel>
              <TabPanel value={currentView} index={2}>
                <GridContainer>
                  <GridItem xs={12}>
                    <Card chart className={classes.cardHover}>
                      <CardHeader color="info" icon>
                        <CardIcon color="info">
                          <Language />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>
                          <strong>Recent Render Jobs</strong>
                        </h4>
                      </CardHeader>
                      <CardBody>
                        <ReactTable
                          aria-label="QADashboard Table RenderJobs"
                          loading={this.state.isRecentRenderSchedulesLoading}
                          data={this.state.recentRenderSchedules}
                          noDataText={
                            this.state.isRecentRenderSchedulesLoading ? (
                              <CircularProgress />
                            ) : (
                              "No render schedules found."
                            )
                          }
                          filtered={this.state.tableFilter}
                          onFilteredChange={filtered => {
                            this.setState({
                              tableFilter: filtered
                            })
                          }}
                          columns={[
                            {
                              Header: "Organization",
                              accessor: "narrative.organization.name",
                              ...this.stringFilterProps(
                                "narrative.organization.name",
                                this.state.recentRenderSchedules
                              ),
                              width: 150
                            },
                            {
                              Header: "Schedule Id",
                              accessor: "id",
                              sortable: true,
                              filterable: true,
                              Cell: cell => (
                                <Link
                                  to={{
                                    pathname: `/portal/render-schedule`,
                                    search: `?id=${cell.original.id}`
                                  }}
                                  title={`Manage this schedule`}
                                >
                                  {cell.original.id}
                                </Link>
                              ),
                              width: 130
                            },
                            {
                              Header: "Next Occurrence",
                              accessor: "nextOccurrence",
                              ...this.dateFilterProps(
                                "nextOccurrence",
                                this.state.recentRenderSchedules
                              ),
                              width: 180
                            },
                            {
                              Header: "Last Occurrence",
                              accessor: "mostRecentJobStartDateTime",
                              ...this.dateFilterProps(
                                "mostRecentJobStartDateTime",
                                this.state.recentRenderSchedules
                              ),
                              width: 180
                            },
                            {
                              Header: "Feed",
                              accessor: "deliveryFeed.name",
                              ...this.stringFilterProps(
                                "deliveryFeed.name",
                                this.state.recentRenderSchedules
                              ),
                              Cell: cell => (
                                <Link
                                  to={{
                                    pathname: `/portal/feed-entries/${cell.original.deliveryFeed.id}`
                                  }}
                                  title={`View feed (${cell.original.deliveryFeed.id}) entries`}
                                >
                                  <Button color="primary" justIcon round simple>
                                    <RssFeed />
                                  </Button>
                                  {cell.original.deliveryFeed.name}
                                </Link>
                              ),
                              width: 280
                            },
                            {
                              Header: "Output",
                              accessor: "narrative.name",
                              sortable: true,
                              filterable: true,
                              width: 350,
                              ...this.idedStringFilterProps(
                                ["narrative.id", "narrative.name"],
                                this.state.recentRenderSchedules
                              ),
                              Cell: cell => (
                                <Link
                                  to={{
                                    pathname: `/portal/narrative/${cell.original.narrative.id}`
                                  }}
                                  title="View Output"
                                >
                                  <Button color="primary" justIcon round simple>
                                    <LibraryBooks />
                                  </Button>
                                  {cell.original.narrative.name}
                                </Link>
                              )
                            },
                            {
                              Header: "Feed Status",
                              accessor: "deliveryFeed.feedStatus",
                              Cell: cell => (
                                <div>
                                  {Helpers.convertFeedStatusIdToStatusName(
                                    cell.original.deliveryFeed.feedStatus
                                  )}
                                </div>
                              ),
                              width: 125,
                              sortMethod: (a, b) =>
                                Helpers.convertFeedStatusIdToStatusName(a) >
                                Helpers.convertFeedStatusIdToStatusName(b)
                                  ? 1
                                  : -1,
                              filterMethod: (filter, row) => {
                                if (filter.value === "all") {
                                  return true
                                }
                                // NOTE: string versus integer comparison,
                                // don't use === operator
                                return (
                                  // eslint-disable-next-line eqeqeq
                                  row._original.deliveryFeed.feedStatus ==
                                  filter.value
                                )
                              },
                              Filter: ({ filter, onChange }) => {
                                if (!feedEntries || feedEntries.size === 0) {
                                  return null
                                }

                                const distinctStatuses = []
                                const map = new Map()
                                for (let entry of this.state
                                  .recentRenderSchedules) {
                                  if (!map.has(entry.deliveryFeed.feedStatus)) {
                                    map.set(entry.deliveryFeed.feedStatus, true)
                                    distinctStatuses.push({
                                      id: entry.deliveryFeed.feedStatus,
                                      name: Helpers.convertFeedStatusIdToStatusName(
                                        entry.deliveryFeed.feedStatus
                                      )
                                    })
                                  }
                                }
                                // inline sort by status name
                                distinctStatuses.sort((A, B) => {
                                  // Use toUpperCase() to ignore character casing
                                  let comparison = 0
                                  if (A > B) {
                                    comparison = 1
                                  } else if (A < B) {
                                    comparison = -1
                                  }
                                  return comparison
                                })
                                // build up the <select> element <option> element JSX style
                                let options = []
                                distinctStatuses.forEach(status => {
                                  if (status.id !== null) {
                                    options.push(
                                      <option value={status.id} key={status.id}>
                                        {status.name}
                                      </option>
                                    )
                                  }
                                })
                                return (
                                  <select
                                    onChange={event => {
                                      onChange(event.target.value)
                                    }}
                                    style={{ width: "100%" }}
                                    value={filter ? filter.value : "all"}
                                  >
                                    <option value="all">All</option>
                                    {options}
                                  </select>
                                )
                              }
                            },
                            {
                              Header: "In Process?",
                              accessor: "isInProcess",
                              Cell: cell =>
                                cell.value ? (
                                  <span>
                                    Yes <CircularProgress size={20} />
                                  </span>
                                ) : (
                                  <span>No</span>
                                ),
                              ...this.yesOrNoFilterProps(
                                "isInProcess",
                                this.state.recentRenderSchedules
                              ),
                              width: 130
                            },
                            {
                              Header: "Publish Delay",
                              accessor: "publishDelayInSeconds",
                              Cell: cell =>
                                Helpers.loadPublishDelayOptions().find(
                                  x => x.key === cell.value
                                ).value,
                              filterable: false,
                              width: 200
                            }
                          ]}
                          filterable={true}
                          pageSize={
                            this.state.isPausedJobsLoading
                              ? 50
                              : this.state.pageSize
                          }
                          onPageSizeChange={pageSize => {
                            this.rememberPageSizeChanges(pageSize)
                          }}
                          showPaginationTop={false}
                          showPaginationBottom={true}
                          className="-striped -highlight"
                          defaultSorted={[
                            {
                              id: "auditInfo.modifiedOn",
                              desc: true
                            }
                          ]}
                          style={{
                            background: "rgba(0,0,0,0.01)",
                            padding: "10px",
                            maxWidth: "100%",
                            height: `${(window.innerHeight * 0.69).toFixed(
                              0
                            )}px`
                          }}
                          getTrProps={(state, rowInfo) => {
                            if (rowInfo) {
                              let returnObject = {
                                style: {}
                              }
                              if (rowInfo.original.isArchived) {
                                returnObject.style.textDecoration =
                                  "line-through"
                              }
                              if (rowInfo.original.isInProcess) {
                                returnObject.style.background = "#ffd900"
                              }
                              if (rowInfo.original.isPaused) {
                                returnObject.style.background = "#ccc"
                              }
                              if (
                                rowInfo.original.isPaused &&
                                rowInfo.original.deliveryFeed.feedStatus === 3
                              ) {
                                // Warn on active jobs
                                returnObject.style.background =
                                  "rgb(255 0 0 / 29%)"
                                returnObject.style.color = "#000000"
                              }
                              if (
                                new Date(rowInfo.original.startDateTime) >
                                new Date()
                              ) {
                                returnObject.style.fontStyle = "italic"
                              }
                              return returnObject
                            } else {
                              return {}
                            }
                          }}
                          expanded={this.state.expandedJobRows}
                          onExpandedChange={expanded => {
                            // don't forget to save the 'expanded'
                            // so it can be fed back in as a prop
                            this.setState({ expandedJob: expanded })
                          }}
                          getTdProps={(state, row, col) => ({
                            onClick: (event, handleOriginal) => {
                              // do some stuff with the event
                              if (col.expander) {
                                if (
                                  this.state.selectedJobId === row.original.id
                                ) {
                                  this.setState({
                                    selectedJobId: -1,
                                    expandedJobRows: {
                                      [row.viewIndex]: false
                                    }
                                  })
                                } else {
                                  this.setState({
                                    selectedJobId: row.original.id,
                                    expandedJobRows: {
                                      [row.viewIndex]: true
                                    }
                                  })
                                }
                              }
                              handleOriginal && handleOriginal()
                            }
                          })}
                          SubComponent={row => (
                            <div
                              style={{
                                background: "#ffffff"
                              }}
                            >
                              <div
                                style={{
                                  padding: "20px",
                                  maxWidth: "1280px"
                                }}
                              >
                                <ReactTable
                                  data={this.state.recentRenders}
                                  defaultFiltered={[
                                    {
                                      id: "narrativeRenderScheduleId",
                                      value: row.original.id
                                    }
                                  ]}
                                  noDataText={
                                    this.state.isLoadingQAPortalMetrics ? (
                                      <CircularProgress />
                                    ) : (
                                      "No job history found."
                                    )
                                  }
                                  columns={[
                                    {
                                      Header: "Job Id",
                                      accessor: "id",
                                      sortable: true,
                                      filterable: true,
                                      width: 80
                                    },
                                    {
                                      Header: "Schedule",
                                      accessor: "narrativeRenderScheduleId",
                                      sortable: true,
                                      filterable: true,
                                      ...this.stringFilterProps(
                                        "narrativeRenderScheduleId",
                                        this.state.recentRenders
                                      )
                                    },
                                    {
                                      Header: "Organization",
                                      accessor: "organization",
                                      sortable: true,
                                      filterable: true,
                                      ...this.stringFilterProps(
                                        "organization",
                                        this.state.recentRenders
                                      ),
                                      width: 150
                                    },
                                    {
                                      sortable: true,
                                      filterable: true,
                                      width: 250,
                                      Header: "Feed",
                                      accessor: "feedName",
                                      Cell: cell => (
                                        <Link
                                          to={{
                                            pathname: `/portal/feed-entries/${cell.value}`
                                          }}
                                          title="View feed entries"
                                        >
                                          {cell.original.feedName}
                                        </Link>
                                      ),
                                      ...this.stringFilterProps(
                                        "feedName",
                                        this.state.recentRenders
                                      )
                                    },
                                    {
                                      Header: "Feed Status",
                                      accessor: "feedstatus_id",
                                      Cell: cell => (
                                        <div>
                                          {Helpers.convertFeedStatusIdToStatusName(
                                            cell.original.feedstatus_id
                                          )}
                                        </div>
                                      ),
                                      width: 125,
                                      sortMethod: (a, b) =>
                                        Helpers.convertFeedStatusIdToStatusName(
                                          a
                                        ) >
                                        Helpers.convertFeedStatusIdToStatusName(
                                          b
                                        )
                                          ? 1
                                          : -1,
                                      filterMethod: (filter, row) => {
                                        if (filter.value === "all") {
                                          return true
                                        }
                                        // NOTE: string versus integer comparison,
                                        // don't use === operator
                                        // eslint-disable-next-line eqeqeq
                                        return row.feedstatus_id == filter.value
                                      },
                                      Filter: ({ filter, onChange }) => {
                                        if (
                                          !feedEntries ||
                                          feedEntries.size === 0
                                        ) {
                                          return null
                                        }

                                        const distinctStatuses = []
                                        const map = new Map()
                                        for (let entry of this.state
                                          .recentRenders) {
                                          if (!map.has(entry.feedStatusId)) {
                                            map.set(entry.feedStatusId, true)
                                            distinctStatuses.push({
                                              id: entry.feedStatusId,
                                              name: Helpers.convertFeedStatusIdToStatusName(
                                                entry.feedStatusId
                                              )
                                            })
                                          }
                                        }
                                        // inline sort by status name
                                        distinctStatuses.sort((A, B) => {
                                          // Use toUpperCase() to ignore character casing
                                          let comparison = 0
                                          if (A > B) {
                                            comparison = 1
                                          } else if (A < B) {
                                            comparison = -1
                                          }
                                          return comparison
                                        })
                                        // build up the <select> element <option> element JSX style
                                        let options = []
                                        distinctStatuses.forEach(status => {
                                          if (status.id !== null) {
                                            options.push(
                                              <option
                                                value={status.id}
                                                key={status.id}
                                              >
                                                {status.name}
                                              </option>
                                            )
                                          }
                                        })
                                        return (
                                          <select
                                            onChange={event => {
                                              onChange(event.target.value)
                                            }}
                                            style={{ width: "100%" }}
                                            value={
                                              filter ? filter.value : "all"
                                            }
                                          >
                                            <option value="all">All</option>
                                            {options}
                                          </select>
                                        )
                                      }
                                    },
                                    {
                                      Header: "Output",
                                      accessor: "narrativeId",
                                      sortable: true,
                                      filterable: true,
                                      width: 325,
                                      ...this.idedStringFilterProps(
                                        ["narrativeId", "narrativeName"],
                                        this.state.recentRenders
                                      ),
                                      Cell: cell => (
                                        <Link
                                          to={{
                                            pathname: `/portal/narrative/${cell.value}`
                                          }}
                                          title="View Output"
                                        >
                                          {cell.original.narrativeName}
                                        </Link>
                                      )
                                    },
                                    {
                                      sortable: true,
                                      filterable: true,
                                      width: 150,
                                      Header: "League",
                                      accessor: "league",
                                      ...this.stringFilterProps(
                                        "league",
                                        this.state.recentRenders
                                      )
                                    },
                                    {
                                      sortable: true,
                                      filterable: true,
                                      width: 150,
                                      Header: "Narr. Content",
                                      accessor: "narrativeContentType",
                                      ...this.stringFilterProps(
                                        "narrativeContentType",
                                        this.state.recentRenders
                                      )
                                    },
                                    {
                                      sortable: true,
                                      filterable: true,
                                      width: 150,
                                      Header: "Article Type",
                                      accessor: "articleType",
                                      ...this.stringFilterProps(
                                        "articleType",
                                        this.state.recentRenders
                                      )
                                    },
                                    {
                                      Header: "Possible",
                                      accessor: "possibleCount",
                                      sortable: true,
                                      Cell: cell => (
                                        <div title="Possible">
                                          {cell.original.possibleCount > 0 ? (
                                            <span
                                              style={{
                                                fontWeight: "bold"
                                              }}
                                            >
                                              {cell.original.possibleCount}
                                            </span>
                                          ) : (
                                            cell.original.possibleCount
                                          )}
                                        </div>
                                      ),
                                      ...this.intFilterProps(
                                        "possibleCount",
                                        this.state.recentRenders
                                      )
                                    },
                                    {
                                      Header: "Created",
                                      accessor: "createdCount",
                                      sortable: true,
                                      Cell: cell => (
                                        <div title="Created">
                                          {cell.original.createdCount > 0 ? (
                                            <span
                                              style={{
                                                color: "green",
                                                fontWeight: "bold"
                                              }}
                                            >
                                              {cell.original.createdCount}
                                            </span>
                                          ) : (
                                            cell.original.createdCount
                                          )}
                                        </div>
                                      ),
                                      ...this.intFilterProps(
                                        "createdCount",
                                        this.state.recentRenders
                                      )
                                    },
                                    {
                                      Header: "Updated",
                                      accessor: "updatedCount",
                                      sortable: true,
                                      Cell: cell => (
                                        <div title="Updated">
                                          {cell.original.updatedCount > 0 ? (
                                            <span
                                              style={{
                                                color: "green",
                                                fontWeight: "bold"
                                              }}
                                            >
                                              {cell.original.updatedCount}
                                            </span>
                                          ) : (
                                            cell.original.updatedCount
                                          )}
                                        </div>
                                      ),
                                      ...this.intFilterProps(
                                        "updatedCount",
                                        this.state.recentRenders
                                      )
                                    },
                                    {
                                      Header: "Skipped",
                                      accessor: "skippedCount",
                                      sortable: true,
                                      Cell: cell => (
                                        <div title="Skipped">
                                          {cell.original.skippedCount > 0 ? (
                                            <span
                                              style={{
                                                color: "orange",
                                                fontWeight: "bold"
                                              }}
                                            >
                                              {cell.original.skippedCount}
                                            </span>
                                          ) : (
                                            cell.original.skippedCount
                                          )}
                                        </div>
                                      ),
                                      ...this.intFilterProps(
                                        "skippedCount",
                                        this.state.recentRenders
                                      )
                                    },
                                    {
                                      Header: "Published",
                                      accessor: "publishedCount",
                                      sortable: true,
                                      Cell: cell => (
                                        <div title="Published">
                                          {cell.original.publishedCount > 0 ? (
                                            <span
                                              style={{
                                                color: "green",
                                                fontWeight: "bold"
                                              }}
                                            >
                                              {cell.original.publishedCount}
                                            </span>
                                          ) : (
                                            cell.original.publishedCount
                                          )}
                                        </div>
                                      ),
                                      ...this.intFilterProps(
                                        "publishedCount",
                                        this.state.recentRenders
                                      )
                                    },
                                    {
                                      Header: "Flagged",
                                      accessor: "flaggedCount",
                                      sortable: true,
                                      Cell: cell => (
                                        <div title="Flagged For Review">
                                          {cell.original.flaggedCount > 0 ? (
                                            <span
                                              style={{
                                                color: "red",
                                                fontWeight: "bold"
                                              }}
                                            >
                                              {cell.original.flaggedCount}
                                            </span>
                                          ) : (
                                            cell.original.flaggedCount
                                          )}
                                        </div>
                                      ),
                                      ...this.intFilterProps(
                                        "flaggedCount",
                                        this.state.recentRenders
                                      )
                                    },
                                    {
                                      Header: "Killswitched",
                                      accessor: "killSwitchedCount",
                                      sortable: true,
                                      width: 120,
                                      Cell: cell => (
                                        <div title="Killswitched">
                                          {cell.original.killSwitchedCount >
                                          0 ? (
                                            <span
                                              style={{
                                                color: "red",
                                                fontWeight: "bold"
                                              }}
                                            >
                                              {cell.original.killSwitchedCount}
                                            </span>
                                          ) : (
                                            cell.original.killSwitchedCount
                                          )}
                                        </div>
                                      ),
                                      ...this.intFilterProps(
                                        "killSwitchedCount",
                                        this.state.recentRenders
                                      )
                                    },
                                    {
                                      Header: "Start",
                                      accessor: "startedExecutionDateTime",
                                      ...this.dateFilterProps(
                                        "startedExecutionDateTime"
                                      ),
                                      width: 120
                                    },
                                    {
                                      Header: "End",
                                      accessor: "finishedexecutiondatetime",
                                      ...this.dateFilterProps(
                                        "finishedexecutiondatetime"
                                      ),
                                      width: 120
                                    },
                                    {
                                      Header: "Duration",
                                      Cell: cell => {
                                        let start = moment(
                                          cell.original.startedexecutiondatetime
                                        )
                                        let end = moment(
                                          cell.original
                                            .finishedexecutiondatetime || now()
                                        )

                                        return (
                                          <strong>
                                            {moment
                                              .duration(end.diff(start))
                                              .humanize()}
                                          </strong>
                                        )
                                      },
                                      width: 100,
                                      filterable: false,
                                      sortable: false
                                    },
                                    {
                                      Header: "Timed Out",
                                      accessor: "timedOut",
                                      Cell: cell =>
                                        cell.value ? (
                                          <span>Yes</span>
                                        ) : (
                                          <span>No</span>
                                        ),
                                      ...this.yesOrNoFilterProps(
                                        "timedOut",
                                        this.state.recentRenders
                                      ),
                                      width: 130
                                    },
                                    {
                                      Header: "Manually Killed",
                                      accessor: "killed",
                                      Cell: cell =>
                                        cell.value ? (
                                          <span>Yes</span>
                                        ) : (
                                          <span>No</span>
                                        ),
                                      ...this.yesOrNoFilterProps(
                                        "killed",
                                        this.state.recentRenders
                                      ),
                                      width: 140
                                    }
                                  ]}
                                  filterable={true}
                                  defaultPageSize={50}
                                  showPaginationTop={
                                    this.state.recentRenders &&
                                    this.state.recentRenders.length > 25
                                  }
                                  showPaginationBottom={false}
                                  className="-striped -highlight -scrollEntries"
                                  style={{
                                    border: "1px solid rgba(0,0,0,0.05)",
                                    background: "rgba(0,0,0,0.01)",
                                    padding: "5px",
                                    height: `${(
                                      window.innerHeight * 0.69
                                    ).toFixed(0)}px`
                                  }}
                                  defaultSorted={[
                                    {
                                      id: "startDateTime",
                                      desc: false
                                    }
                                  ]}
                                  expanded={this.state.expandedRows}
                                  onExpandedChange={expanded => {
                                    // don't forget to save the 'expanded'
                                    // so it can be fed back in as a prop
                                    this.setState({ expanded })
                                  }}
                                  getTdProps={(state, row, col) => ({
                                    onClick: (event, handleOriginal) => {
                                      // do some stuff with the event
                                      if (col.expander) {
                                        this.retrieveJobLogs(row.original.id)
                                        if (
                                          this.state.selectedJobId ===
                                          row.original.id
                                        ) {
                                          this.setState({
                                            selectedJobId: -1,
                                            expandedRows: {
                                              [row.viewIndex]: false
                                            }
                                          })
                                        } else {
                                          this.setState({
                                            selectedJobId: row.original.id,
                                            expandedRows: {
                                              [row.viewIndex]: true
                                            }
                                          })
                                        }
                                      }
                                      handleOriginal && handleOriginal()
                                    }
                                  })}
                                  SubComponent={() => {
                                    let results = this.state.joblogs
                                    return (
                                      <div
                                        style={{
                                          background: "#ffffff"
                                        }}
                                      >
                                        <h5>
                                          Job resulted in {results.length}{" "}
                                          possible entries.
                                        </h5>
                                        <div
                                          style={{
                                            padding: "20px",
                                            maxWidth: "1280px"
                                          }}
                                        >
                                          <ReactTable
                                            data={results}
                                            noDataText={
                                              this.state.isLogResultsLoading ? (
                                                <CircularProgress />
                                              ) : (
                                                "No results found."
                                              )
                                            }
                                            columns={[
                                              {
                                                Header: "Log Id",
                                                accessor: "id",
                                                sortable: false,
                                                filterable: false,
                                                width: 100
                                              },
                                              {
                                                Header: "Feed Entry Id",
                                                accessor: "feedEntryId",
                                                Cell: cell => (
                                                  <Link
                                                    to={{
                                                      pathname: `/portal/feed-entries/entry/${cell.value}`
                                                    }}
                                                    title="View feed entry details"
                                                  >
                                                    {cell.value}
                                                  </Link>
                                                ),
                                                width: 150
                                              },
                                              {
                                                Header: "Result",
                                                accessor: "logMessage",
                                                Cell: cell => (
                                                  <div>{cell.value}</div>
                                                ),
                                                ...this.stringFilterProps(
                                                  "logMessage",
                                                  results
                                                ),
                                                width: 250
                                              },
                                              {
                                                Header: "Content Type Id",
                                                accessor: "contentTypeId",
                                                ...this.stringFilterProps(
                                                  "contentTypeId",
                                                  results
                                                ),
                                                width: 100
                                              },
                                              {
                                                Header: "Content Id",
                                                accessor: "contentId",
                                                ...this.stringFilterProps(
                                                  "contentId",
                                                  results
                                                ),
                                                width: 100
                                              },
                                              {
                                                Header: "Processed",
                                                accessor:
                                                  "auditInfoResponse.createdOn",
                                                Cell: cell => (
                                                  <div>
                                                    <TimeAgo
                                                      date={cell.value}
                                                      title={Helpers.prettyDateTimeinPacificTimeZone(
                                                        cell.value
                                                      )}
                                                    />
                                                  </div>
                                                ),
                                                width: 300
                                              }
                                            ]}
                                            style={{
                                              border:
                                                "1px solid rgba(0,0,0,0.05)",
                                              background: "rgba(0,0,0,0.01)",
                                              padding: "5px"
                                            }}
                                            filterable={true}
                                            defaultPageSize={5}
                                            showPaginationTop={
                                              results.length > 5
                                            }
                                            showPaginationBottom={false}
                                            className="-striped -highlight"
                                          >
                                            {(state, makeTable) => {
                                              let recordsInfoText = ""
                                              const {
                                                filtered,
                                                pageRows,
                                                pageSize,
                                                sortedData,
                                                page
                                              } = state
                                              if (
                                                sortedData &&
                                                sortedData.length > 0
                                              ) {
                                                let isFiltered =
                                                  filtered.length > 0
                                                let totalRecords =
                                                  sortedData.length
                                                let recordsCountFrom =
                                                  page * pageSize + 1
                                                let recordsCountTo =
                                                  recordsCountFrom +
                                                  pageRows.length -
                                                  1
                                                if (isFiltered) {
                                                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered results`
                                                } else {
                                                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} results`
                                                }
                                              } else {
                                                recordsInfoText = this.state
                                                  .isLoadingQAPortalMetrics
                                                  ? "Loading render log, please wait..."
                                                  : "Nothing has been rendered from this schedule"
                                              }
                                              return (
                                                <div className="main-grid">
                                                  <div className="above-table text-right">
                                                    <div className="col-sm-12">
                                                      <span className="records-info">
                                                        {recordsInfoText}
                                                        <Tooltip title="Reload">
                                                          <Button
                                                            justIcon
                                                            round
                                                            simple
                                                            color="primary"
                                                            className="info"
                                                            id="reload"
                                                            onClick={() => {
                                                              this.retrieveJobLogs(
                                                                this.state
                                                                  .selectedJobId
                                                              )
                                                            }}
                                                          >
                                                            {this.state
                                                              .isLoadingQAPortalMetrics ? (
                                                              <CircularProgress
                                                                thickness={1}
                                                                size={30}
                                                              />
                                                            ) : (
                                                              <Loop />
                                                            )}
                                                          </Button>
                                                        </Tooltip>
                                                      </span>
                                                    </div>
                                                  </div>
                                                  {makeTable()}
                                                </div>
                                              )
                                            }}
                                          </ReactTable>
                                        </div>
                                      </div>
                                    )
                                  }}
                                >
                                  {(state, makeTable) => {
                                    let recordsInfoText = ""
                                    const {
                                      filtered,
                                      pageRows,
                                      pageSize,
                                      sortedData,
                                      page
                                    } = state

                                    if (sortedData && sortedData.length > 0) {
                                      let isFiltered = filtered.length > 0
                                      let totalRecords = sortedData.length
                                      let recordsCountFrom = page * pageSize + 1
                                      let recordsCountTo =
                                        recordsCountFrom + pageRows.length - 1
                                      if (isFiltered) {
                                        recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered runs`
                                      } else {
                                        recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} runs`
                                      }
                                    } else {
                                      recordsInfoText = this.state
                                        .isLoadingQAPortalMetrics
                                        ? "Loading completed runs, please wait..."
                                        : "This Job Has Not Run"
                                    }
                                    return (
                                      <div className="main-grid">
                                        <div className="above-table text-right">
                                          <div className="col-sm-12">
                                            <span className="records-info">
                                              {recordsInfoText}
                                            </span>
                                          </div>
                                        </div>
                                        {makeTable()}
                                      </div>
                                    )
                                  }}
                                </ReactTable>
                              </div>
                            </div>
                          )}
                        >
                          {(state, makeTable) => {
                            let recordsInfoText = ""

                            const {
                              filtered,
                              pageRows,
                              pageSize,
                              sortedData,
                              page
                            } = state

                            if (sortedData && sortedData.length > 0) {
                              let isFiltered = filtered.length > 0
                              let totalRecords = sortedData.length
                              let recordsCountFrom = page * pageSize + 1
                              let recordsCountTo =
                                recordsCountFrom + pageRows.length - 1
                              if (isFiltered) {
                                recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered schedules`
                              } else {
                                recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords} schedules`
                              }
                            } else {
                              recordsInfoText = this.state.isPausedJobsLoading
                                ? "Loading paused schedules, please wait..."
                                : "No paused schedules found"
                            }
                            return (
                              <div className="main-grid">
                                <div className="above-table text-right">
                                  <div className="col-sm-12">
                                    <span className="records-info">
                                      {recordsInfoText}
                                    </span>
                                    <Tooltip
                                      title="Reload"
                                      placement="top"
                                      arrow={true}
                                    >
                                      <Button
                                        justIcon
                                        round
                                        simple
                                        color="primary"
                                        className="info"
                                        id="reload"
                                        onClick={() => {
                                          this.retrieveRecentRenderSchedules()
                                        }}
                                      >
                                        {this.state.isPausedJobsLoading ? (
                                          <CircularProgress
                                            thickness={1}
                                            size={30}
                                          />
                                        ) : (
                                          <Loop />
                                        )}
                                      </Button>
                                    </Tooltip>{" "}
                                  </div>
                                </div>
                                {makeTable()}
                              </div>
                            )
                          }}
                        </ReactTable>
                        <div xs={3} sm={3} md={3}>
                          <div style={{ textDecoration: "line-through" }}>
                            line-through = Archived
                          </div>
                          <div style={{ fontStyle: "italic" }}>
                            italic = future start date/time
                          </div>
                          <div style={{ backgroundColor: "#ffd900" }}>
                            yellow = `In Process` status
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </TabPanel>
              <TabPanel value={currentView} index={3}>
                <GridContainer>
                  <GridItem xs={12}>
                    <Card>
                      <CardHeader className={classes.cardHover}>
                        <Typography
                          style={{
                            fontSize: "1.3em",
                            marginLeft: "9px",
                            paddingTop: "9px"
                          }}
                        >
                          Broken Blocks
                        </Typography>
                      </CardHeader>
                      <CardBody>
                        {/* <CustomTable
                          tableName="Broken Blocks"
                          // change table Filters so that chips do not render under filter table
                          // tableFilters={}
                          tableData={brokenBlocksData}
                          tableColumns={brokenBlocksColumns}
                          // initialTableState={}
                          paginated
                          sorted
                          filtered
                        /> */}
                        <ReactTable
                          aria-label="QADashboard Table BrokenBlocks"
                          data={this.state.brokenResult}
                          loading={this.state.isBrokenBlocksLoading}
                          noDataText={
                            this.state.isBrokenBlocksLoading ? (
                              <CircularProgress />
                            ) : (
                              "No Broken Blocks found."
                            )
                          }
                          className="-striped -highlight"
                          columns={[
                            {
                              Header: "Broken Block Count",
                              accessor: "brokenBlockCount",
                              sortable: true,
                              width: 120,
                              ...this.stringFilterProps(
                                "brokenBlockCount",
                                this.state.brokenResult
                              )
                            },
                            {
                              Header: "Feed",
                              accessor: "feed",
                              sortable: true,
                              filterable: true,
                              width: 120,
                              Cell: ({ row }) => {
                                if (row._original.feed_ID != null) {
                                  return (
                                    <Link
                                      to={`feed-entries/${row._original.feed_ID}`}
                                      target="_blank"
                                    >
                                      &nbsp;{row._original.feed}
                                    </Link>
                                  )
                                } else {
                                  return null
                                }
                              },
                              ...this.stringFilterProps(
                                "feed",
                                this.state.brokenResult
                              )
                            },
                            {
                              Header: "Library",
                              accessor: "libraryName",
                              sortable: true,
                              filterable: true,
                              width: 120,
                              Cell: ({ row }) => {
                                if (
                                  row._original.library_ID != null &&
                                  row._original.library_ID !==
                                    row._original.output_ID
                                ) {
                                  return (
                                    <Link
                                      to={`narrative/${row._original.library_ID}/edit`}
                                      target="_blank"
                                    >
                                      &nbsp;{row._original.libraryName}
                                    </Link>
                                  )
                                } else {
                                  return null
                                }
                              },
                              ...this.libraryNameFilterProps(
                                this.state.brokenResult
                              )
                            },
                            {
                              Header: "Output",
                              accessor: "outputName",
                              sortable: true,
                              filterable: true,
                              width: 120,
                              Cell: ({ row }) => {
                                if (row._original.output_ID != null) {
                                  return (
                                    <Link
                                      to={`narrative/${row._original.output_ID}/edit`}
                                      target="_blank"
                                    >
                                      &nbsp;{row._original.outputName}
                                    </Link>
                                  )
                                } else {
                                  return null
                                }
                              },
                              ...this.stringFilterProps(
                                "outputName",
                                this.state.brokenResult
                              )
                            },
                            {
                              Header: "Paragraph ID",
                              accessor: "paragraph_ID",
                              sortable: true,
                              width: 130,
                              Cell: ({ row }) => {
                                if (row._original.paragraph_ID != null) {
                                  return (
                                    <a
                                      href={`${globalSettings.apiBaseUrl}/api/paragraph/link/${row._original.paragraph_ID}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      &nbsp;{row._original.paragraph_ID}
                                    </a>
                                  )
                                } else {
                                  return null
                                }
                              },
                              ...this.stringFilterProps(
                                "paragraph_ID",
                                this.state.brokenResult
                              )
                            },
                            {
                              Header: "Sentence ID",
                              accessor: "sentence_ID",
                              sortable: true,
                              width: 130,
                              Cell: ({ row }) => {
                                if (row._original.sentence_ID != null) {
                                  return (
                                    <a
                                      href={`${globalSettings.apiBaseUrl}/api/sentence/link/${row._original.sentence_ID}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      &nbsp;{row._original.sentence_ID}
                                    </a>
                                  )
                                } else {
                                  return null
                                }
                              },
                              ...this.stringFilterProps(
                                "sentence_ID",
                                this.state.brokenResult
                              )
                            },
                            {
                              Header: "Feed Entry",
                              accessor: "feedEntry_ID",
                              sortable: true,
                              filterable: true,
                              width: 120,
                              Cell: ({ row }) => {
                                if (row._original.feedEntry_ID != null) {
                                  return (
                                    <Link
                                      to={`feed-entries/entry/${row._original.feedEntry_ID}`}
                                      target="_blank"
                                    >
                                      &nbsp;{row._original.feedEntry_ID}
                                    </Link>
                                  )
                                } else {
                                  return null
                                }
                              },
                              ...this.stringFilterProps(
                                "feedEntry_ID",
                                this.state.brokenResult
                              )
                            },
                            {
                              Header: "Entry ID",
                              accessor: "entryID",
                              width: 140,
                              ...this.stringFilterProps(
                                "entryID",
                                this.state.brokenResult
                              )
                            },
                            {
                              Header: "Content Block",
                              accessor: "contentBlock",
                              sortable: true,
                              filterable: true,
                              width: 140,
                              ...this.stringFilterProps(
                                "contentBlock",
                                this.state.brokenResult
                              )
                            },
                            {
                              Header: "Script Error",
                              accessor: "scriptErrorMessage",
                              width: 150,
                              ...this.stringFilterProps(
                                "scriptErrorMessage",
                                this.state.brokenResult
                              )
                            },
                            {
                              Header: "Template Error",
                              accessor: "templateErrorMessage",
                              width: 150,
                              ...this.stringFilterProps(
                                "templateErrorMessage",
                                this.state.brokenResult
                              )
                            }
                          ]}
                          showPaginationBottom={true}
                          defaultPageSize={25}
                          style={{
                            border: "1px solid rgba(0,0,0,0.05)",
                            borderRadius: "9px",
                            background: "rgba(0,0,0,0.01)",
                            padding: "5px",
                            height: `${(window.innerHeight * 0.69).toFixed(
                              0
                            )}px`
                          }}
                        />
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </TabPanel>
            </div>
          )}
      </div>
    )
  }
}

RenderDashboard.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(dashboardStyle)(useStore(RenderDashboard))
