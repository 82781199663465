import React from "react"
import PropTypes from "prop-types"
import makeStyles from "@mui/styles/makeStyles"
import { TreeView, TreeItem } from "@mui/x-tree-view"
import { Cookies } from "tools/storage"
import { Typography, Box } from "@mui/material"
import {
  LibraryBooks,
  ArrowDropDown,
  ArrowRight,
  FeaturedPlayList
} from "@mui/icons-material"
import Helpers from "tools/Helpers"

const useTreeItemStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.secondary,
    "&:focus > $content": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: "var(--tree-view-color)"
    }
  },
  content: {
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular
    }
  },
  group: {
    marginLeft: theme.spacing(1),
    "& $content": {
      paddingLeft: theme.spacing(1)
    }
  },
  expanded: {},
  label: {
    fontWeight: "inherit",
    color: "inherit"
  },
  labelRoot: {
    display: "flex",
    verticalAlign: "top",
    padding: theme.spacing(0.5, 0),
    position: "relative"
  },
  labelIcon: {
    fontSize: "22px"
  },
  labelDetail: {
    fontWeight: "inherit",
    flexGrow: 1,
    paddingLeft: theme.spacing(0.75)
  },
  labelText: {
    fontWeight: "bold",
    flexGrow: 1
  },
  iconButton: {
    position: "absolute",
    right: "10px",
    display: "none",
    padding: 0,
    margin: 0,
    "& > span > svg": {
      width: ".6em",
      height: ".6em"
    }
  },
  iconButton2: {
    position: "absolute",
    right: "30px",
    display: "none",
    padding: 0,
    margin: 0,
    "& > span > a > svg": {
      width: ".55em",
      height: ".55em",
      color: "rgba(0, 0, 0, 0.54)"
    }
  },
  labelPosition: {
    color: "#3f51b5",
    fontWeight: "bold",
    flexShrink: 0,
    marginLeft: theme.spacing(0.5)
  },
  labelWeight: {
    fontWeight: "bold",
    fontSize: ".55rem",
    flexShrink: 0
  },
  flex: {
    display: "flex"
  },
  flexmiddle: {
    display: "flex",
    alignItems: "center",
    "&:hover $iconButton, &:hover $iconButton2": {
      display: "inline-block"
    }
  }
}))

function StyledTreeItem(props) {
  const classes = useTreeItemStyles()
  const {
    itemView,
    position,
    labelText,
    labelTitle,
    labelIcon: LabelIcon,
    triggerWeight,
    labelInfo,
    color,
    bgColor,
    blockSelectEvent,
    selectedItems,
    nodeId,
    areCheckboxesDisabled,
    ...other
  } = props

  return (
    <React.Fragment>
      <div className={classes.flexmiddle}>
        {itemView && (
          <input
            type="checkbox"
            className="checkbox"
            disabled={areCheckboxesDisabled ? "disabled" : ""}
            defaultChecked={selectedItems && selectedItems.includes(itemView)}
            onChange={() => {
              blockSelectEvent(itemView)
            }}
          />
        )}
        <TreeItem
          nodeId={nodeId}
          title={labelTitle}
          label={
            <div className={classes.flexmiddle}>
              {LabelIcon && (
                <LabelIcon color="inherit" className={classes.labelIcon} />
              )}

              <Typography variant="body1" className={classes.labelPosition}>
                {position}
              </Typography>

              {!!triggerWeight && triggerWeight !== "0" && (
                <Typography variant="body1" className={classes.labelWeight}>
                  /{triggerWeight}w
                </Typography>
              )}
              <div className={classes.flex}>
                <Box className={classes.labelDetail}>
                  <Typography variant="body2" className={classes.labelText}>
                    {labelText}
                  </Typography>
                  <Typography variant="body2" color="inherit">
                    {labelInfo}
                  </Typography>
                </Box>
              </div>
            </div>
          }
          style={{
            "--tree-view-color": color,
            "--tree-view-bg-color": bgColor
          }}
          classes={{
            root: classes.root,
            content: classes.content,
            expanded: classes.expanded,
            group: classes.group,
            label: classes.label
          }}
          {...other}
        />
      </div>
    </React.Fragment>
  )
}

StyledTreeItem.propTypes = {
  nodeId: PropTypes.string,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  launchLink: PropTypes.string,
  // eslint-disable-next-line react/no-typos
  labelInfo: PropTypes.string,
  labelText: PropTypes.string,
  labelTitle: PropTypes.string,
  blockSelectEvent: PropTypes.func,
  itemView: PropTypes.string,
  selectedItems: PropTypes.array
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch",
    maxWidth: "100%",
    backgroundColor: theme.palette.background.paper
  },
  drawerPrimaryCard: {
    flexGrow: 1,
    overflow: "auto",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch"
  },
  drawerContent: {
    flexGrow: 0
  },
  drawerPrimaryContent: {
    flexGrow: 1,
    padding: "10px"
  },
  list: {
    padding: 0
  },
  listItem: {
    margin: 0,
    padding: 0
  },
  listItemIcon: {
    minWidth: "auto"
  },
  checkbox: {
    marginLeft: "17px",
    padding: 0
  },
  cardHeader: {
    padding: "5px 10px"
  },
  cardBody: {
    padding: "5px 10px"
  }
}))

function groupCollectionBy(collection, funcProp) {
  return collection.reduce(function (acc, val) {
    // eslint-disable-next-line
    (acc[funcProp(val)] = acc[funcProp(val)] || []).push(val)
    return acc
  }, {})
}

export default function NarrativeBlockExplorer(props) {
  const {
    narrative,
    handleBlockSelection,
    handleBulkSelection,
    selectedBlocks,
    targetedBlocks,
    areCheckboxesDisabled
  } = props

  const classes = useStyles()
  const cookies = new Cookies()
  const [localSelectedBlocks, setLocalSelected] = React.useState(selectedBlocks)
  const [localTargetedBlocks, setLocalTargeted] = React.useState(targetedBlocks)

  const { id: narrativeId, triggers, paragraphs, outlineBlocks } = narrative

  let sortedBlocks =
    (outlineBlocks &&
      outlineBlocks.sort((a, b) => {
        let comparison = 0
        if (a.position > b.position) {
          comparison = 1
        } else if (a.position < b.position) {
          comparison = -1
        }
        return comparison
      })) ||
    []

  const contentBlocks =
    paragraphs &&
    groupCollectionBy(
      paragraphs,
      p => p.contentBlock && p.contentBlock.toLowerCase()
    )
  const outlineContentBlocks =
    sortedBlocks &&
    groupCollectionBy(
      sortedBlocks,
      p => p.contentBlock && p.contentBlock.toLowerCase()
    )

  // Remove imported library triggers
  const filteredTriggers = triggers.filter(t => !t.isFromLibrary)
  //Filter the triggers into their proper types
  // dependencies which run first
  let dependencies =
    filteredTriggers &&
    filteredTriggers.filter(
      t =>
        t.narrative_Id === narrativeId &&
        t.isDependency !== null &&
        (t.isDependency === true || t.isDependency === "true")
    )
  // new triggers, which control theme
  let newsTriggers =
    filteredTriggers &&
    filteredTriggers.filter(
      t =>
        t.narrative_Id === narrativeId &&
        t.isNews !== null &&
        (t.isNews === true || t.isNews === "true")
    )
  // insights that trigger content
  let insightTriggers =
    filteredTriggers &&
    filteredTriggers.filter(
      t =>
        t.narrative_Id === narrativeId &&
        (t.isNews === null || t.isNews === false || t.isNews === "false") &&
        (t.isDependency === null ||
          t.isDependency === false ||
          t.isDependency === "false")
    )

  const getIdData = ids => {
    let result = []
    Object.keys(contentBlocks).forEach(function (key) {
      ids.forEach(id => {
        id = id.split("-")[1]
        contentBlocks[key].forEach(function (itm) {
          if (itm.id === id) {
            result.push(itm.name)
          }
        })
      })
    })
    return result
  }

  // This code handles the trigger and block selection
  const handleTreeChange = newItem => {
    let selected = localSelectedBlocks

    let targeted = localTargetedBlocks

    // Check if there is a bulk select even
    if (["dependencies", "newstriggers", "insights"].includes(newItem)) {
      // see if it's one we have to auto-select the children
      if (newItem === "dependencies") {
        dependencies.forEach(trigger => {
          let id = `triggerid-${trigger.id}`
          if (selected.includes(id)) {
            selected = selected.filter(value => value !== id)
          } else {
            selected.push(id)
          }
        })
      } else if (newItem === "newstriggers") {
        newsTriggers.forEach(trigger => {
          let id = `newstriggerid-${trigger.id}`
          if (selected.includes(id)) {
            selected = selected.filter(value => value !== id)
          } else {
            selected.push(id)
          }
        })
      } else if (newItem === "insights") {
        insightTriggers.forEach(trigger => {
          let id = `triggerid-${trigger.id}`
          if (selected.includes(id)) {
            selected = selected.filter(value => value !== id)
          } else {
            selected.push(id)
          }
        })
      }
      setLocalSelected(selected)
      handleBulkSelection(selected)
    }
    // If there's a blockselection event and it's not a top level abstraction
    if (!["dependencies", "newstriggers", "insights"].includes(newItem)) {
      //9 July 2021 - changes here
      // Otherwise add the item directly
      if (handleBulkSelection) {
        if (selected.includes(newItem)) {
          selected = selected.filter(value => value !== newItem)
        } else {
          selected.push(newItem)
        }
        setLocalSelected(selected)
        handleBulkSelection(selected)
        let nameInfo = getIdData(selected)
        cookies.set("BlockMoverSources", { id: selected, name: nameInfo })
      } else if (handleBlockSelection) {
        if (targeted.includes(newItem)) {
          targeted = targeted.filter(value => value !== newItem)
        } else {
          targeted.push(newItem)
        }
        setLocalTargeted(targeted)
        let nameInfo = getIdData(targeted)
        cookies.set("BlockMoverTargets", { id: targeted, name: nameInfo })
      }
    }
  }

  let outlineTreeItems =
    outlineContentBlocks &&
    Object.keys(outlineContentBlocks).map(key => {
      key = key || "empty"
      return (
        <StyledTreeItem
          key={`element-${key}`}
          nodeId={key}
          labelText={`<${key}/>`}
          labelIcon=""
          areCheckboxesDisabled={areCheckboxesDisabled}
        >
          {outlineContentBlocks[key].map(outlineBlock => (
            <StyledTreeItem
              key={`outlineBlockId-${outlineBlock.id}`}
              itemView={`outlineBlockId-${outlineBlock.id}`}
              nodeId={`outlineBlockId-${outlineBlock.id}`}
              labelText={`(${outlineBlock.id}) ${
                outlineBlock.contentSection || ""
              }`}
              labelInfo={
                outlineBlock.triggerType
                  ? Helpers.titleCaseSlug(outlineBlock.triggerType)
                  : ""
              }
              launchLink={`/portal/narrative/${narrativeId}/edit?view=outlineBlockid-${outlineBlock.id}`}
              labelIcon={FeaturedPlayList}
              labelTitle={`${outlineBlock.triggerType || ""} ${
                outlineBlock.narrativeId !== narrativeId
                  ? `Inherited From ${narrativeId}`
                  : ""
              }`}
              position={outlineBlock.position}
              blockSelectEvent={handleTreeChange}
              selectedItems={localSelectedBlocks}
              areCheckboxesDisabled={areCheckboxesDisabled}
            />
          ))}
        </StyledTreeItem>
      )
    })

  let blockKeys = contentBlocks && Object.keys(contentBlocks)
  let defaultExpanded = ["outineNode"]
  if (blockKeys && blockKeys.length > 0) {
    defaultExpanded = [...defaultExpanded, ...Object.keys(contentBlocks)]
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.drawerPrimaryCard}>
        {outlineTreeItems && (
          <Box className={classes.drawerPrimaryContent}>
            <TreeView
              defaultExpanded={defaultExpanded}
              defaultCollapseIcon={<ArrowDropDown />}
              defaultExpandIcon={<ArrowRight />}
              defaultEndIcon={<div style={{ width: 12 }} />}
            >
              <StyledTreeItem
                nodeId={"outineNode"}
                labelText="Outline Blocks"
                labelIcon={LibraryBooks}
              >
                {outlineTreeItems}
              </StyledTreeItem>
            </TreeView>
          </Box>
        )}
        {/* </SplitterLayout> */}
      </Box>
    </Box>
  )
}

NarrativeBlockExplorer.propTypes = {
  narrative: PropTypes.object,
  loading: PropTypes.bool,
  areCheckboxesDisabled: PropTypes.bool,
  handleBlockSelection: PropTypes.func,
  handleBulkSelection: PropTypes.func,
  selectedBlocks: PropTypes.array,
  targetedBlocks: PropTypes.array
}
