import React, { Component } from "react"
import { Editor } from "@tinymce/tinymce-react"
import { globalSettings } from "variables/general.jsx"
import Helpers from "tools/Helpers.js"
import TimeAgo from "react-timeago"
import { get, put } from "tools/request"

class WikiPage extends Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      wysiwygEditorContent: "",
      updatedWysiwygEditorContent: "",
      isEditorContentDirty: false,
      wikiPageId: -1,
      isEditing: false,
      lastModifiedOn: new Date(),
      lastModifiedBy: "n/a"
    }
    this.handleDoEdit = this.handleDoEdit.bind(this)
    this.handleOnSave = this.handleOnSave.bind(this)
    this.saveWikiPage = this.saveWikiPage.bind(this)
  }

  componentDidMount() {
    this.loadWikiPage(this.state.wikiPageId)
  }

  componentDidUpdate() {
    if (this.props.match.params.wikiPageId !== this.state.wikiPageId) {
      this.setState({
        wikiPageId: this.props.match.params.wikiPageId,
        isEditing: false
      })
      this.loadWikiPage(this.props.match.params.wikiPageId)
    }
  }

  handleEditorChange = e => {
    this.setState({
      isEditorContentDirty: true,
      updatedWysiwygEditorContent: e.target.getContent()
    })
  }

  loadWikiPage(wikiPageId) {
    this.setState({ isLoading: true })
    get(`${globalSettings.apiBaseUrl}/api/wikipage/${wikiPageId}`)
      .then(Response => Response.json())
      .then(data => {
        this.setState({
          wysiwygEditorContent: data.content.markUp,
          updatedWysiwygEditorContent: data.content.markUp,
          isLoading: false,
          lastModifiedBy: data.content.lastModifiedBy,
          lastModifiedOn: data.content.lastModifiedOn
        })
      })
  }

  saveWikiPage(wikiPageId) {
    put(
      `${globalSettings.apiBaseUrl}/api/wikipage/${wikiPageId}/update`,
      null,
      {
        Id: wikiPageId,
        MarkUp: this.state.updatedWysiwygEditorContent,
        IsDeleted: false
      }
    ).then(Response => Response.json())
  }

  handleDoEdit() {
    this.setState({ isEditing: true })
  }

  handleOnSave() {
    this.saveWikiPage(this.state.wikiPageId)
    this.setState({ isEditing: false })
    return false
  }

  render() {
    return (
      <React.Fragment>
        <div style={{ textAlign: "right" }}>
          Last edited{" "}
          <TimeAgo
            date={this.state.lastModifiedOn}
            title={Helpers.prettyDateTimeinPacificTimeZone(
              this.state.lastModifiedOn
            )}
          />{" "}
          by: {this.state.lastModifiedBy}
        </div>

        <div style={{ backgroundColor: "#fff" }}>
          {!this.state.isEditing && (
            <div
              onClick={this.handleDoEdit}
              dangerouslySetInnerHTML={{
                __html: this.state.updatedWysiwygEditorContent
              }}
            />
          )}
          {this.state.isEditing && (
            <form>
              <Editor
                apiKey="rpxxr36ah4ei6v91l9blwt1ceamufuog4ta1906jtt9by41t"
                initialValue={this.state.wysiwygEditorContent}
                init={{
                  height: 500,
                  menubar: "file edit view insert format tools table tc help",
                  menu: {
                    tc: {
                      title: "Comments",
                      items: "addcomment showcomments deleteallconversations"
                    }
                  },
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount save",
                    "print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons"
                  ],
                  spellchecker_dialog: false,
                  spellchecker_on_load: false,
                  toolbar:
                    // eslint-disable-next-line no-multi-str
                    "save undo redo | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help",
                  protect: [
                    /<\/?(if|endif)>/g, // Protect <if> & </endif>
                    /<xsl:[^>]+>/g, // Protect <xsl:...>
                    /<\?php.*?\?>/g, // Protect php code
                    /<\/?(apxh:div|apxh:p)>/g // Protect <apxh:div /> & <apxh:p />
                  ],
                  force_br_newlines: false,
                  force_p_newlines: false,
                  forced_root_block: "",
                  remove_linebreaks: false,
                  convert_newlines_to_brs: false,
                  preformatted: true,
                  save_onsavecallback: this.handleOnSave
                }}
                onChange={this.handleEditorChange}
              />
            </form>
          )}
        </div>
      </React.Fragment>
    )
  }
}

export default WikiPage
