import React, { Component } from "react"
import ReactTable from "react-table-6"
import SweetAlert from "react-bootstrap-sweetalert"
import withStyles from "@mui/styles/withStyles"
import Assignment from "@mui/icons-material/Assignment"
import { Visibility, Photo, Dialpad, History } from "@mui/icons-material"
import Grid from "@mui/material/Grid"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon"
import CardHeader from "components/Card/CardHeader"
import { cardTitle } from "assets/jss/material-dashboard-pro-react"
import TimeAgo from "react-timeago"
import matchSorter from "match-sorter"
import Button from "components/CustomButtons/Button"
import Tooltip from "@mui/material/Tooltip"
import { globalSettings } from "variables/general"
import MenuItem from "@mui/material/MenuItem"
import { Select } from "@mui/material"
import Helpers from "tools/Helpers.js"
import CircularProgress from "@mui/material/CircularProgress"
import { Cookies } from "tools/storage"
import { observer, inject } from "mobx-react"
import SportsDAO from "daos/sportsDAO"

const useStore = component =>
  inject(({ store }) => ({
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization,
    leagues: store.sportsStore.leagues,
    retrieveLeagues: store.sportsStore.retrieveLeagues
  }))(observer(component))

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
}

class SportsManager extends Component {
  constructor(props) {
    super(props)
    this.props = props
    let cookies = new Cookies()
    let sportsManagerLeagueIdCookie = cookies.get("sportsManagerLeagueId")
    let sportsManagerSeasonYearIdCookie = cookies.get(
      "sportsManagerSeasonYearId"
    )
    this.state = {
      isLoading: false,
      matches: [],
      tableFilter: [],
      displayDeleteConfirmation: false,
      displayShareNotYetImplemented: false,
      seasons: [],
      leagueId: sportsManagerLeagueIdCookie || 2, // default to MLB
      seasonYearId: sportsManagerSeasonYearIdCookie || 1844, // default MLB season 2022
      seasonsLoaded: false,
      choosenLeagueName: ""
    }
    this.assetIdToDelete = -1
    this.assetFilenameToDelete = ""
  }

  componentDidMount() {
    this.props.setShowChangeCurrentOrganization(false)
    if (this.state.seasonYearId) {
      this.loadMatches()
    }
    // Set the default scheduled start date/time filter after data is loaded.
    if (!this.props.leagues?.length) {
      this.props.retrieveLeagues()
    }
    if (!this.state.seasonsLoaded) {
      this.loadSeasons()
    }
  }

  displayDeleteConfirmation(assetId, assetFilename) {
    this.assetIdToDelete = assetId
    this.assetFilenameToDelete = assetFilename
    this.setState({ displayDeleteConfirmation: true })
  }

  displayShareNotYetImplemented() {
    this.setState({ displayShareNotYetImplemented: true })
  }

  hideDeleteAlert() {
    this.setState({ displayDeleteConfirmation: false })
  }

  hideShareAlert() {
    this.setState({ displayShareNotYetImplemented: false })
  }

  deleteConfirmed() {
    this.deleteAsset(this.assetIdToDelete)
    this.hideDeleteAlert()
  }

  loadSeasons() {
    SportsDAO.getSeasons(this.state.leagueId).then(response => {
      this.setState({
        seasons: response.content.seasonYears,
        seasonsLoaded: true
      })
    })
  }

  loadMatches() {
    this.setState({ isLoading: true })
    SportsDAO.getMatches(this.state.seasonYearId).then(matches => {
      this.setState({
        isLoading: false,
        matches: matches.content.events || []
      })
    })
  }

  render() {
    const { classes, leagues } = this.props
    const { seasons, leagueId, seasonYearId } = this.state

    const leagueChoices = leagues?.map(league => (
      <MenuItem key={league.id} value={league.id}>
        {league.name} ({league.acronym})
      </MenuItem>
    ))

    let seasonChoices = seasons?.map(seasonYear => (
      <MenuItem key={seasonYear.id} value={seasonYear.id}>
        {seasonYear.name}
      </MenuItem>
    ))

    const toolBar = (
      <Grid container style={{ alignItems: "center" }}>
        <Grid item xs={6} style={{ paddingRight: "8px" }}>
          <span
            style={{
              fontWeight: "bold",
              fontSize: "16px",
              display: "block",
              whiteSpace: "nowrap",
              marginRight: "10px"
            }}
          >
            Filter by League
          </span>
          <Select
            fullWidth={false}
            style={{
              backgroundColor: "white",
              textAlign: "center",
              padding: "0 10px",
              margin: "0 10px"
            }}
            value={leagueId}
            onChange={e => {
              let cookies = new Cookies()
              cookies.set("sportsManagerLeagueId", e.target.value, {
                path: "/",
                expires: Helpers.CookieExpiration.OneMonth
              })
              this.setState({ leagueId: e.target.value }, () => {
                this.loadSeasons()
                this.setState({ matches: [] })
              })
            }}
          >
            <MenuItem
              disabled
              classes={{
                root: classes.selectMenuItem
              }}
            >
              Choose a league
            </MenuItem>
            {leagueChoices}
          </Select>
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: "8px" }}>
          <span
            style={{
              fontWeight: "bold",
              fontSize: "16px",
              display: "block",
              whiteSpace: "nowrap",
              marginRight: "10px"
            }}
          >
            Filter by Season Year
          </span>
          <Select
            fullWidth={false}
            style={{
              backgroundColor: "white",
              textAlign: "center",
              padding: "0 10px",
              margin: "0 10px"
            }}
            value={seasonYearId || ""}
            onChange={e => {
              let cookies = new Cookies()
              cookies.set("sportsManagerSeasonYearId", e.target.value, {
                path: "/",
                expires: Helpers.CookieExpiration.OneMonth
              })
              this.setState({ seasonYearId: e.target.value }, () =>
                this.loadMatches()
              )
            }}
          >
            <MenuItem
              disabled
              classes={{
                root: classes.selectMenuItem
              }}
            >
              Choose a season year
            </MenuItem>
            {seasonChoices}
          </Select>
        </Grid>
      </Grid>
    )

    //   if (!this.state.matches) return "Loading..."
    const stringFilterProps = {
      filterable: true,
      filterAll: true,
      filterMethod: ({ id, value }, rows) =>
        matchSorter(rows, value, {
          keys: [id],
          threshold: matchSorter.rankings.CONTAINS
        })
    }

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{toolBar}</h4>
            </CardHeader>
            <CardBody>
              {this.state.displayDeleteConfirmation && (
                <SweetAlert
                  warning
                  style={{ display: "block", marginTop: "-100px" }}
                  title="Are you sure?"
                  onConfirm={() => this.deleteConfirmed()}
                  onCancel={() => this.hideDeleteAlert()}
                  confirmBtnCssClass={`${this.props.classes.button} ${this.props.classes.success}`}
                  cancelBtnCssClass={`${this.props.classes.button} ${this.props.classes.danger}`}
                  confirmBtnText="Yes, delete it"
                  cancelBtnText="Cancel"
                  showCancel
                >
                  {`(${this.assetIdToDelete}) - ${this.assetFilenameToDelete}`}
                </SweetAlert>
              )}

              {this.state.displayShareNotYetImplemented && (
                <SweetAlert
                  style={{
                    display: "block",
                    marginTop: "-100px",
                    fontSize: "0.8em"
                  }}
                  title="Sorry, sharing is not yet implemented"
                  onConfirm={() => this.hideShareAlert()}
                  onCancel={() => this.hideShareAlert()}
                  confirmBtnCssClass={`${this.props.classes.button} ${this.props.classes.info}`}
                />
              )}
              <ReactTable
                minRows={0}
                data={this.state.matches}
                noDataText={
                  this.state.isLoading ? (
                    <CircularProgress />
                  ) : (
                    "No games/matches found."
                  )
                }
                // This is the technique to have multiple filters. https://codesandbox.io/s/3x51yzollq
                // filtered={this.state.tableFilter}
                columns={[
                  {
                    Header: "Id",
                    accessor: "id",
                    Cell: cell => <div>{cell.value}</div>,
                    width: 80,
                    ...stringFilterProps
                  },
                  {
                    Header: "Actions",
                    Cell: cell => {
                      let liveFeedUrl
                      let viewLiveFeed = ``
                      // MLS seasons
                      if (
                        [
                          218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228,
                          229, 230, 231, 232, 233
                        ].includes(cell.original.seasonYear_Id)
                      ) {
                        liveFeedUrl = `https://api.sportradar.us/soccer-p3/am/en/matches/${cell.original.sportRadarId}/timeline.json?api_key=bk3ze2hfs762fpnns9duakbg`
                      }
                      // MLB and MiLB seasons
                      else if (
                        [
                          210, 211, 212, 213, 214, 215, 216, 217, 236, 237, 238,
                          239, 240, 241, 243, 247, 248, 249, 235, 250, 242, 244,
                          245, 246
                        ].includes(cell.original.seasonYear_Id)
                      ) {
                        liveFeedUrl = `https://statsapi.mlb.com/api/v1.1/game/${cell.original.mlbStatsGameId}/feed/live`
                      }
                      // NASCAR seasons
                      else if (
                        [252, 253, 254].includes(cell.original.seasonYear_Id)
                      ) {
                        liveFeedUrl = `${globalSettings.apiBaseUrl}/api/nascar/raw-race?raceid=${cell.original.nascarRaceId}&v=2.0`
                      }
                      if (liveFeedUrl) {
                        viewLiveFeed = (
                          <a href={liveFeedUrl} target="_new">
                            <Tooltip
                              title="View MLB AM live feed"
                              placement="top"
                            >
                              <Button
                                justIcon
                                round
                                simple
                                color="primary"
                                className="info"
                              >
                                <Visibility />
                              </Button>
                            </Tooltip>
                          </a>
                        )
                      }
                      return (
                        <div className="actions-right">
                          {viewLiveFeed}
                          <a
                            href={`${globalSettings.apiBaseUrl}/api/dynamicimage?imagetype=baseballgamerecap&contenttypeid=1&contentid=${cell.original.id}`}
                            target="_new"
                          >
                            <Tooltip title="View Game Image" placement="top">
                              <Button
                                justIcon
                                round
                                simple
                                color="primary"
                                className="info"
                              >
                                <Photo />
                              </Button>
                            </Tooltip>
                          </a>
                          <a
                            href={`/portal/tools/matchoddstool?matchid=${cell.original.id}`}
                          >
                            <Tooltip
                              title="View Game Betting Odds"
                              placement="top"
                            >
                              <Button
                                justIcon
                                round
                                simple
                                color="primary"
                                className="info"
                              >
                                <Dialpad />
                              </Button>
                            </Tooltip>
                          </a>
                          {cell.original.hasTimingInfo === true && (
                            <a
                              href={`/portal/matchtiming?matchid=${cell.original.id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Tooltip
                                title="View Match Timings"
                                placement="top"
                              >
                                <Button
                                  justIcon
                                  round
                                  simple
                                  color="primary"
                                  className="info"
                                >
                                  <History />
                                </Button>
                              </Tooltip>
                            </a>
                          )}
                        </div>
                      )
                    },
                    sortable: false,
                    filterable: false,
                    width: 200
                  },
                  {
                    Header: "Name",
                    accessor: "name",
                    Cell: cell => <div>{cell.value}</div>,
                    ...stringFilterProps
                  },
                  {
                    Header: "Scheduled Start Date/Time",
                    accessor: "scheduledStartDateTime",
                    filterMethod: (filter, row) => {
                      if (filter.value === "all") {
                        return true
                      }
                      let futureTime = new Date()
                      let pastTime = new Date()
                      let now = new Date()
                      let scheduledStartDateTime = new Date(
                        row.scheduledStartDateTime
                      )
                      if (filter.value === "future") {
                        return scheduledStartDateTime >= now
                      }
                      if (filter.value === "past") {
                        return scheduledStartDateTime < now
                      }
                      if (filter.value === "within7Days") {
                        return (
                          scheduledStartDateTime >
                            pastTime.setHours(pastTime.getHours() - 7 * 24) &&
                          scheduledStartDateTime <
                            futureTime.setHours(futureTime.getHours() + 7 * 24)
                        )
                      }
                      if (filter.value === "within24hours") {
                        return (
                          scheduledStartDateTime >
                            pastTime.setHours(pastTime.getHours() - 24) &&
                          scheduledStartDateTime <
                            futureTime.setHours(futureTime.getHours() + 24)
                        )
                      }
                      if (filter.value === "within1hour") {
                        return (
                          scheduledStartDateTime >
                            pastTime.setHours(pastTime.getHours() - 1) &&
                          scheduledStartDateTime <
                            futureTime.setHours(futureTime.getHours() + 1)
                        )
                      }
                    },
                    Filter: ({ filter, onChange }) => (
                      <select
                        onChange={event => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "all"}
                      >
                        <option value="all">Show All</option>
                        <option value="future">Future</option>
                        <option value="past">Past</option>
                        <option value="within7Days">Within 7 Days</option>
                        <option value="within24hours">Within 24 Hours</option>
                        <option value="within1hour">Within 1 Hour</option>
                      </select>
                    ),
                    Cell: cell => (
                      <div>
                        <TimeAgo
                          date={cell.value}
                          title={Helpers.prettyDateTimeinPacificTimeZone(
                            cell.value
                          )}
                        />
                      </div>
                    ),
                    width: 200
                  }
                ]}
                filterable={true}
                defaultPageSize={25}
                showPaginationTop={true}
                showPaginationBottom={true}
                className="-striped -highlight -scrollEntries"
                style={{
                  height: `${(window.innerHeight * 0.72).toFixed(0)}px`
                }}
                defaultSorted={[
                  {
                    id: "scheduledStartDateTime",
                    desc: false
                  }
                ]}
              >
                {(state, makeTable) => {
                  let recordsInfoText = ""

                  const { filtered, pageRows, pageSize, sortedData, page } =
                    state

                  if (sortedData && sortedData.length > 0) {
                    let isFiltered = filtered.length > 0

                    let totalRecords = sortedData.length

                    let recordsCountFrom = page * pageSize + 1

                    let recordsCountTo = recordsCountFrom + pageRows.length - 1

                    if (isFiltered) {
                      recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`
                    } else {
                      recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`
                    }
                  } else {
                    recordsInfoText = this.state.isLoading
                      ? "Loading sports data, please wait"
                      : "No files"
                  }
                  return (
                    <div className="main-grid">
                      <div className="above-table text-right">
                        <div className="col-sm-12">
                          <span className="records-info">
                            {recordsInfoText}
                          </span>
                        </div>
                      </div>

                      {makeTable()}
                    </div>
                  )
                }}
              </ReactTable>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

export default withStyles(styles)(useStore(SportsManager))
