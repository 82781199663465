import React from "react"
import PropTypes from "prop-types"
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material"

import { contentSections } from "../Data/contentSections"

class ContentBlock extends React.Component {
  render() {
    return (
      <FormControl margin="normal" fullWidth>
        <InputLabel>Target Tag (element)</InputLabel>
        <Select
          type="select"
          name="contentBlockinput"
          id="contentBlock"
          label="Target Tag (element)"
          value={this.props.value || ""}
          onChange={e => this.props.onChange(e.target.value)}
          required={this.props.required}
        >
          {contentSections.map(item => (
            <MenuItem
              value={item.id}
              disabled={
                item.id === "featuredimage" ||
                item.id === "featuredimagecaption"
              }
              classes={{ selected: this.props.classes?.selected }}
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }
}

ContentBlock.propTypes = {
  // Where the user to be redirected on clicking the avatar
  onChange: PropTypes.func,
  value: PropTypes.string,
  required: PropTypes.bool
}

export default ContentBlock
