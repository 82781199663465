import { globalSettings } from "variables/general.jsx"
import { get, put } from "../tools/request"

export default class SnippetDAO {
  static getSnippets = async () => {
    const res = await get(`${globalSettings.apiBaseUrl}/api/snippet`)
    return await res.json()
  }

  static getSnippet = async id => {
    const res = await get(`${globalSettings.apiBaseUrl}/api/snippet/${id}`)
    return await res.json()
  }

  // static putSnippet = async (id, snippet) => {
  //   const res = await put(
  //     `${globalSettings.apiBaseUrl}/api/snippet/${id}`,
  //     null,
  //     snippet
  //   )
  //   return await res.json()
  // }

  static postSnippet = async snippet => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/snippet`,
      null,
      snippet
    )
    return await res.json()
  }
}
