import { useState } from "react"
import NarrativeDAO from "daos/narrativeDAO"

export const defaultFormState = {
  ownerId: 0,
  status_Id: 3,
  narrativeOutlineBlockId: null,
  position: 0,
  name: "",
  contentBlock: "",
  renderType: "",
  libraryParagraph_Id: "",
  libraryBlockPositionsFilter: "",
  triggerIds: "",
  triggerType: "",
  triggerWeight: "",
  comments: "",
  primaryTagId: null,
  secondaryTagIds: [],
  formFactorTagId: null,
  thirdPartyTagIds: [],
  isArchived: false,
  isRequiredBlock: false
}

export const PUBLISHED_STATUS_ID = 3

const useParagraphForm = ({
  isLibrary = false,
  initialParagraph,
  handleSubmitResponse
}) => {
  const initialFormState = Object.keys(defaultFormState).reduce(
    (acc, field) => {
      acc[field] = initialParagraph[field] || defaultFormState[field]
      return acc
    },
    {}
  )

  const [formState, setFormState] = useState(initialFormState)
  const [formErrors, setFormErrors] = useState({})

  const [dirtyFields, setDirtyFields] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [libraryOrPositionChange, setLibraryOrPositionChange] = useState(false)

  const setFormValueBase = (fieldName, value) => {
    setFormState({
      ...formState,
      [fieldName]: value
    })
    setDirtyField(fieldName)
  }

  const isFieldDirty = fieldName => dirtyFields.includes(fieldName)

  const setDirtyField = fieldName => {
    if (!isFieldDirty(fieldName)) {
      setDirtyFields([...dirtyFields, fieldName])
    }
  }

  const validateForm = () => {
    const {
      contentBlock,
      primaryTagId,
      secondaryTagIds,
      formFactorTagId,
      status_Id
    } = formState

    const localFormErrors = {}

    if (!contentBlock) {
      localFormErrors.contentBlock =
        "Please select the tag this content will be targetting."
    }

    if (isLibrary) {
      if (!primaryTagId && status_Id === PUBLISHED_STATUS_ID) {
        localFormErrors.primaryTagId =
          "Primary Tag is required prior to publishing!"
      }

      if (!secondaryTagIds?.length && status_Id === PUBLISHED_STATUS_ID) {
        localFormErrors.secondaryTagIds =
          "Secondary Tag(s) is required prior to publishing!"
      }

      if (!formFactorTagId && status_Id === PUBLISHED_STATUS_ID) {
        localFormErrors.formFactorTagId =
          "Form Factor Tag is required prior to publishing!"
      }
    }

    setFormErrors(localFormErrors)
    return localFormErrors
  }

  const handleSubmit = async () => {
    const errors = validateForm()
    if (!!Object.keys(errors).length) {
      const errMsg = Object.values(errors).join("\n")
      throw new Error(errMsg)
    }

    const {
      createdDateTime,
      modifiedDateTime = new Date().toLocaleDateString("en-US"),
      createdBy_Account_Id,
      modifiedBy_Account_Id
    } = initialParagraph

    const paragraphToSubmit = {
      ...initialParagraph,
      ...formState,
      createdBy_Account_Id: createdBy_Account_Id || 1,
      modifiedBy_Account_Id: modifiedBy_Account_Id || 1,
      createdDateTime: createdDateTime || modifiedDateTime,
      modifiedDateTime
    }

    if (
      paragraphToSubmit.libraryParagraph_Id !==
        initialParagraph.libraryParagraph_Id ||
      paragraphToSubmit.position !== initialParagraph.position
    ) {
      setLibraryOrPositionChange(true)
    }

    setIsLoading(true)
    const paragraphId = await NarrativeDAO.postParagraph(paragraphToSubmit)
    setIsLoading(false)
    await handleSubmitResponse(paragraphId, paragraphToSubmit)
    setLibraryOrPositionChange(false)
    setDirtyFields([])
  }

  const setFormValue = fieldName => event =>
    setFormValueBase(fieldName, event.target.value)

  const setCheckboxFormValue = fieldName => event =>
    setFormValueBase(fieldName, event.target.checked)

  return {
    formErrors,
    formState,
    handleSubmit,
    isFieldDirty,
    isLoading,
    libraryOrPositionChange,
    setFormState,
    setFormValueBase,
    setFormValue,
    setCheckboxFormValue,
    validateForm
  }
}

export default useParagraphForm
