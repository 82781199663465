import React, { useEffect, useState } from "react"
import { PropTypes } from "prop-types"
import makeStyles from "@mui/styles/makeStyles"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import CTAStatusTypes from "components/CTAEditor/CTAStatusTypes"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Button from "@mui/material/Button"

import Divider from "@mui/material/Divider"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import Grid from "@mui/material/Grid"
import LinearProgress from "@mui/material/LinearProgress"
import CircularProgress from "@mui/material/CircularProgress"
import Paper from "@mui/material/Paper"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import Autocomplete from "@mui/material/Autocomplete"
import InfoIcon from "@mui/icons-material/Info"
import IconButton from "@mui/material/IconButton"
import GridItem from "components/Grid/GridItem"
import { useHistory } from "react-router-dom"

import Helpers from "tools/Helpers.js"
import { urlValidation } from "tools/ValidationHelpers"
import Switch from "@mui/material/Switch"
import useDisclaimerFormHelper from "tools/CTAv2/DisclaimerFormHelper"
import Cta_v2DAO from "daos/cta_v2DAO"
import TimezoneSelect from "react-timezone-select"
import {
  DesktopDateTimePicker,
  LocalizationProvider
} from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs" //Dayjs
import utc from "dayjs/plugin/utc"
import "dayjs/locale/en"
dayjs.extend(utc)

const useStyles = makeStyles({
  CTAGrid: {
    "& div.MuiAutocomplete-tag": {
      backgroundColor: "#DDEAEF",
      height: "24px"
    }
  },
  CTAGridOpacity: {
    "& div.MuiAutocomplete-tag": {
      backgroundColor: "#DDEAEF",
      height: "24px"
    },
    backgroundColor: "#aaa",
    opacity: ".3"
  },
  linkTimeframe: {
    display: "inline-block",
    flexDirection: "row",
    width: "8vw"
  },
  linkTimeDate: {
    display: "inline-block",
    flexDirection: "row",
    width: "15vw"
  },
  linkTimeZone: {
    display: "inline-block",
    flexDirection: "row",
    width: "15vw",
    zIndex: "9",
    paddingTop: "7px"
  },
  MuiPaper: {
    borderRadius: "0px"
  },
  CTAform: {
    display: "block",
    flexDirection: "row"
  },
  ctaToolbar: {
    fontSize: "1rem",
    color: "#FFF",
    backgroundColor: "#4d9ab5"
  },
  requiredMsg: {
    color: "#c00",
    textAlign: "center",
    backgroundColor: "#f7f7f7"
  },
  collapseContent: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingBottom: "15px",
    borderRadius: "0px"
  },
  monetizationCard: {
    backgroundColor: "#DDEAEF",
    display: "inline-flex",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    borderRadius: "0px"
  },
  infoHeader: {
    width: "75vw",
    padding: "5px",
    borderRadius: "0px",
    marginTop: "5px",
    paddingLeft: "15px",
    fontWeight: "bold"
  },
  infoValidationHeader: {
    textAlign: "center",
    color: "#inherit"
  },
  addScrollIfNeeded: {
    maxHeight: "300px",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "10px 0 0 0",
    "& li": {
      textAlign: "left"
    }
  },
  monetizationHeader: {
    backgroundColor: "#DDEAEF",
    padding: "5px",
    borderRadius: "0px",
    marginTop: "5px",
    paddingLeft: "15px",
    fontWeight: "bold"
  },
  autocompleteCTA: {
    size: "medium",
    backgroundColor: "#FFF"
  },
  conversionType: {
    "& .MuiTypography-root": {
      fontWeight: "bold",
      color: "#888"
    }
  },
  ctaTop: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
    display: "flex",
    marginRight: "10px",
    borderRadius: "0px"
  },
  infoCard: {
    display: "inline-flex",
    flexDirection: "row",
    paddingLeft: "15px",
    paddingBottom: "9px",
    borderRadius: "0px"
  },
  detailsCard: {
    paddingLeft: "15px",
    paddingTop: "5px",
    borderRadius: "0px"
  },
  CTATitle: {
    paddingLeft: "15px",
    paddingTop: "5px",
    fontWeight: "bold"
  },
  TimeZone: {
    display: "flex",
    paddingLeft: "15px",
    paddingTop: "5px",
    fontWeight: "bold"
  },
  CTADivide: {
    height: "2px",
    backgroundColor: "#DDEAEF",
    margin: "20px 9px 20px 9px"
  },
  CTAmodalIcon: {
    fontSize: "1.25rem",
    color: "#144e68",
    marginLeft: "2px",
    padding: "0"
  },
  accordionSummary: {
    pointerEvents: "none"
  },
  accordionIcon: {
    pointerEvents: "auto"
  },
  CTAButton: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "15px",
    paddingBottom: "15px",
    marginTop: "40px"
  },
  progressContainer: {
    textAlign: "center",
    marginTop: "100px",
    width: "100%",
    height: "100%",
    background: "#fff !important"
  },
  buttonStyle: {
    fontSize: "12px",
    padding: "14px 23px",
    minWidth: "110px",
    marginRight: "4px"
  },
  inputCTAField: {
    backgroundColor: "#FFF"
  },
  "&.MuiSelect-select.MuiSelect-select": {
    border: "2px red solid !important"
  },
  ctaRegion: {
    display: "flex",
    flexWrap: "nowrap",
    "&.MuiChip-root.MuiAutocomplete-tag.MuiChip-deletable": {
      backgroundColor: "#DDEAEF !important"
    }
  },
  checkRegion: {
    color: "#144e68",
    "&$checked": {
      color: "#144e68"
    }
  },
  payoutAccordion: {
    display: "inline-flex",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    marginBottom: "15px"
  },
  addIconButton: {
    color: "#144e68"
  },
  buttonExceptions: {
    minWidth: "65px",
    minHeight: "20px",
    fontSize: "12px",
    padding: "10px 20px",
    marginRight: "4px"
  },
  payoutDetails: {
    flexDirection: "column"
  }
})

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    companiesInfo: store.ctav2Store.companiesInfo,
    getCompaniesInfo: store.ctav2Store.getCompaniesInfo,
    organizations: store.ctav2Store.organizationsInfo,
    getOrganizations: store.ctav2Store.getOrganizationsInfo,
    regionsInfo: store.ctav2Store.regionsInfo,
    getRegionsInfo: store.ctav2Store.getRegionsInfo,
    linkTypeInfo: store.ctav2Store.linkTypeInfo,
    getLinkTypeInfo: store.ctav2Store.getLinkTypeInfo,
    intentInfo: store.ctav2Store.intentInfo,
    getIntentInfo: store.ctav2Store.getIntentInfo,
    getLinkInfo: store.ctav2Store.getLinkInfo,
    linkInfo: store.ctav2Store.linkInfo,
    getAffiliatesByOrgId: store.ctav2Store.getAffiliatesByOrgId,
    affiliates: store.ctav2Store.affiliates,
    getLinkDisplayInfo: store.ctav2Store.getLinkDisplayInfo,
    disclaimerLinksInfo: store.ctav2Store.disclaimerLinksInfo,
    accounts: store.accountStore.accounts,
    getAccounts: store.accountStore.getAccounts,
    setDialogWarningNoBtnMessage: store.uiStore.setDialogWarningNoBtnMessage,
    setDialogWarningNoBtnOpen: store.uiStore.setDialogWarningNoBtnOpen,
    setDialogInfoMessage: store.uiStore.setDialogInfoMessage,
    setDialogInfoOpen: store.uiStore.setDialogInfoOpen,
    dialogInfoOpen: store.uiStore.dialogInfoOpen,
    setDialogSuccessMessage: store.uiStore.setDialogSuccessMessage,
    setDialogSuccessOpen: store.uiStore.setDialogSuccessOpen,
    dialogSuccessOpen: store.uiStore.dialogSuccessOpen,
    setDialogFailMessage: store.uiStore.setDialogFailMessage,
    setDialogFailOpen: store.uiStore.setDialogFailOpen,
    dialogFailOpen: store.uiStore.dialogFailOpen
  }))
}

function CTADisclaimerLinkForm(props) {
  const history = useHistory()
  const classes = useStyles()
  const {
    organizations,
    getOrganizations,
    getRegionsInfo,
    getCompaniesInfo,
    getLinkTypeInfo,
    getIntentInfo,
    getLinkInfo,
    getAffiliatesByOrgId,
    getLinkDisplayInfo,
    disclaimerLinksInfo,
    companiesInfo,
    intentInfo,
    linkInfo,
    getAccounts,
    accounts,
    setDialogInfoMessage,
    setDialogInfoOpen,
    setDialogWarningNoBtnOpen,
    setDialogWarningNoBtnMessage,
    setDialogSuccessMessage,
    setDialogSuccessOpen,
    setDialogFailMessage,
    setDialogFailOpen,
    dialogInfoOpen
  } = useStoreData()
  const { linkInformation, openDisclaimerLinkBlocks } = props

  const [myFormValues, setMyFormValues] = useState({
    organizationIds: [],
    propertyIds: [],
    companyIds: [],
    organizations: []
  })
  const [ctaFormErrors, setCtaFormErrors] = useState({})
  const [resetEditor, setResetEditor] = useState(false)
  const [refreshReset, setRefreshReset] = useState(true)
  const [linkIsLoading, setLinkIsLoading] = useState(false)
  const [creatingNewLink, setCreatingNewLink] = useState(0)
  const [origFormValues, setOrigFormValues] = useState({})
  const [disableCancel, setDisableCancel] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const [appliedExceptions, setAppliedExceptions] = useState(false)
  const {
    assetLink,
    companyIds,
    startDate,
    endDate,
    linkUrl,
    linkName,
    organizationIds,
    propertyIds,
    ownerAccountId,
    status,
    isArchived,
    disableSaveButton
  } = myFormValues || {}

  const linkId = myFormValues.id || myFormValues.linkId
  const timeZoneIANA = myFormValues.timeZoneIANA || dayjs.tz.guess()

  const ctaIntent =
    props.source === "disclaimerLinkForm"
      ? 2
      : props.source === "offerLinkForm"
      ? 1
      : ""

  const [orgs, setOrgs] = useState([])
  const [companies, setCompanies] = useState([])
  const [intentTypes, setIntentTypes] = useState([])

  const {
    createLinkObject,
    formValueValidation,
    checkForChangedNavElements,
    linkSaveObject,
    constructOrgPropList
  } = useDisclaimerFormHelper({
    setOrigFormValues,
    urlValidation,
    companiesInfo,
    organizations,
    classes,
    dayjs,
    timeZoneIANA
  })

  const clearStartDateFields = () => {
    const newFormValues = {
      ...myFormValues,
      startDate: dayjs(startDate).startOf("day"),
      disableSaveButton: false,
      editingCTAs: true
    }
    setMyFormValues(newFormValues)
  }
  const clearEndDateFields = () => {
    const newFormValues = {
      ...myFormValues,
      endDate: null,
      disableSaveButton: false,
      editingCTAs: true
    }
    setMyFormValues(newFormValues)
  }
  const getEndDateError = value => {
    if (value !== null && startDate === null) {
      return "Start Date required with End Date"
    }
    if (value === null) {
      return ""
    }
    return ""
  }
  const endDateErrorMessage = getEndDateError(endDate)

  const handleCheckboxChange = e => {
    const newFormValues = {
      ...myFormValues,
      disableSaveButton: false,
      [e.target.id]: e.target.checked,
      editingCTAs: true
    }
    setMyFormValues(newFormValues)
  }

  const handleAutoCompleteMultiChangeForOrgs = (
    event,
    newValue,
    reason,
    name
  ) => {
    if (!event || typeof event === "string" || !newValue) {
      return
    }
    let newVals = []
    newValue.forEach(itm => newVals.push(itm.id))
    if (newVals.includes(-1)) {
      //Select All chosen
      newVals = []
      organizations?.forEach(x => {
        newVals.push(x.id)
      })
    } else if (newVals.includes(-2)) {
      //Remove All chosen
      newVals = []
    }
    const newFormValues = {
      ...myFormValues,
      [name]: newVals
    }
    setMyFormValues(newFormValues)
  }

  const handleAutoCompleteMultiChangeForProperties = (
    event,
    newValue,
    reason,
    name
  ) => {
    if (!event || typeof event === "string" || !newValue) {
      return
    }
    let newVals = []
    newValue.forEach(itm => newVals.push(itm.id))
    if (newVals.includes(-1)) {
      //Select All chosen
      newVals = []
      properties?.forEach(x => {
        newVals.push(x.id)
      })
    } else if (newVals.includes(-2)) {
      //Remove All chosen
      newVals = []
    }
    const newFormValues = {
      ...myFormValues,
      [name]: newVals
    }
    setMyFormValues(newFormValues)
  }

  const handleAutoCompleteMultiChangeForCompanies = (
    event,
    newValue,
    reason,
    name
  ) => {
    if (!event || typeof event === "string" || !newValue) {
      return
    }
    let newVals = []
    newValue.forEach(itm => newVals.push(itm.id))
    if (newVals.includes(-1)) {
      //Select All chosen
      newVals = []
      companies?.forEach(x => {
        newVals.push(x.id)
      })
    } else if (newVals.includes(-2)) {
      //Remove All chosen
      newVals = []
    }
    const newFormValues = {
      ...myFormValues,
      [name]: newVals
    }
    setMyFormValues(newFormValues)
  }

  const handleAutocompleteChange = (event, newValue, reason, name) => {
    const newFormValues = {
      ...myFormValues,
      disableSaveButton: false,
      [name]: newValue?.id || null,
      editingCTAs: true
    }
    setMyFormValues(newFormValues)
  }

  const onTimeZoneChange = (zone, name) => {
    const newFormValues = {
      ...myFormValues,
      disableSaveButton: false,
      [name]: (zone && zone.value) || null,
      editingCTAs: true
    }
    setMyFormValues(newFormValues)
  }

  const handleDateChange = (date, name) => {
    const newFormValues = {
      ...myFormValues,
      [name]: date,
      disableSaveButton: false,
      editingCTAs: true
    }
    setMyFormValues(newFormValues)
  }

  const handleLinkChange = name => event => {
    const val =
      name !== "linkName"
        ? event.target.value
        : event.target.value.substring(0, 50)
    const newFormValues = {
      ...myFormValues,
      disableSaveButton: false,
      [name]: val,
      editingCTAs: true
    }
    setMyFormValues(newFormValues)
  }

  const handleInputValidation = inputId => {
    const errorObj = { ...ctaFormErrors }
    if (inputId === "assetLink") {
      const urlV = urlValidation(myFormValues.assetLink)
      if (!urlV && myFormValues.assetLink) {
        errorObj.assetLinkMsg = "Asset Link must be a valid URL."
        errorObj[inputId] = true
        errorObj.validationHasError = true
      } else {
        errorObj[inputId] = false
        errorObj.validationHasError = false
      }
    } else if (inputId === "linkUrl") {
      const urlV = urlValidation(myFormValues.linkUrl)
      if (!urlV && myFormValues.linkUrl) {
        errorObj.linkURLMsg = "Link URL must be a valid URL."
        errorObj[inputId] = true
        errorObj.validationHasError = true
      } else {
        errorObj[inputId] = false
        errorObj.validationHasError = false
      }
    }
    setCtaFormErrors(errorObj)
  }

  // on first render
  useEffect(() => {
    getCompaniesInfo()
    getRegionsInfo() //Need real regions list
    getOrganizations()
    getLinkTypeInfo()
    getIntentInfo()
    getAccounts()
  }, [])

  useEffect(() => {
    if (!linkInformation?.new) {
      if (linkInfo?.id) {
        if (resetEditor) {
          setResetEditor(false)
          setRefreshReset(false)
        } else if (refreshReset) {
          const myFV = createLinkObject(linkInfo, false, false)
          setMyFormValues(myFV)
          setResetEditor(true)
          setRefreshReset(false)
          setLinkIsLoading(false)
        }
      }
    }
  }, [linkInfo, resetEditor])

  useEffect(() => {
    if (companiesInfo?.length) {
      setCompanies(companiesInfo)
    }
  }, [companiesInfo])

  useEffect(() => {
    if (linkInformation?.id && linkInformation?.id !== linkId) {
      if (linkInfo?.id) {
        if (linkInfo.id === linkInformation.id) {
          if (resetEditor === true) {
            setResetEditor(false)
          }
          const myFV = createLinkObject(linkInfo, false, false)
          setMyFormValues(myFV)
          if (myFV.organizationIds.length) {
            if (myFV.propertyIds.length) {
              getAffiliatesByOrgId(myFV.propertyIds.join(","))
            } else {
              getAffiliatesByOrgId(myFV.organizationIds)
            }
          }
          setLinkIsLoading(false)
        } else if (accounts?.toJS().length) {
          if (linkInformation?.id) {
            setLinkIsLoading(true)
            getLinkInfo(linkInformation?.id)
          }
        }
      } else if (accounts?.toJS().length) {
        if (linkInformation?.id) {
          setLinkIsLoading(true)
          getLinkInfo(linkInformation?.id)
        }
      }
    } else if (linkInformation?.new === true) {
      if (resetEditor === true) {
        setResetEditor(false)
      }
      const myFV = createLinkObject({}, true, false)
      setMyFormValues(myFV)
    }
  }, [linkInformation, linkInfo, resetEditor])

  useEffect(() => {
    if (accounts?.toJS().length) {
      if (linkInformation?.id && !linkInfo?.id) {
        getLinkInfo(linkInformation.id)
        if (linkInformation.navigate) {
          openDisclaimerLinkBlocks()
        }
      } else if (linkInformation?.new === true) {
        const myFV = createLinkObject({}, true, false)
        setMyFormValues(myFV)
        setResetEditor(true)
      }
    }
  }, [accounts])

  useEffect(() => {
    if (intentInfo?.length) {
      setIntentTypes(intentInfo)
    }
  }, [intentInfo])

  useEffect(() => {
    if (appliedExceptions) {
      setAppliedExceptions(false)
    }
  }, [appliedExceptions])

  useEffect(() => {
    if (linkInfo?.id) {
      if (linkInformation?.id) {
        if (linkInfo.id === linkInformation.id) {
          const myFV = createLinkObject(linkInfo, false, false)
          setMyFormValues(myFV)
          setLinkIsLoading(false)
        }
      } else if (linkInformation?.new === true) {
        const myFV = createLinkObject({}, true, false)
        setMyFormValues(myFV)
      } else {
      }
    }
  }, [linkInfo])

  useEffect(() => {
    if (organizations?.toJS().length) {
      const orgss = organizations.toJS()
      setOrgs(orgss)
    }
  }, [organizations])

  useEffect(() => {
    if (
      creatingNewLink !== 0 &&
      disclaimerLinksInfo.length &&
      !dialogInfoOpen
    ) {
      history.push(`/portal/manage-ctas/Disclaimer?linkid=${creatingNewLink}`)
      setCreatingNewLink(0)
      getLinkDisplayInfo(2)
      //history.go(0)
    }
  }, [disclaimerLinksInfo, dialogInfoOpen])

  const linkSaveMessage = (header, list) => (
    <div>
      <div className={classes.infoValidationHeader}>{header}</div>
      <div className={classes.addScrollIfNeeded}>
        <ol>
          {list?.map((itm, i) => (
            <li key={i}>{itm}</li>
          ))}
        </ol>
      </div>
    </div>
  )

  const validLinkUrl = linkUrl && !ctaFormErrors.linkUrl //To disable/enable Preview Link

  const handleSaveForm = () => {
    const foundNotValid = formValueValidation(myFormValues)
    const navElementsHaveChanged = linkInformation?.new
      ? false
      : checkForChangedNavElements(myFormValues, linkInfo)
    if (!foundNotValid) {
      let startDateTimeInTimeZone = null
      if (startDate) {
        startDateTimeInTimeZone = dayjs(startDate).toISOString()
      }

      let endDateTimeInTimeZone = null
      if (endDate) {
        endDateTimeInTimeZone = dayjs(endDate).toISOString()
      }
      const newFormValues = {
        ...myFormValues,
        startDateTimeInTimeZone,
        endDateTimeInTimeZone
      }
      const linkObject = linkSaveObject(newFormValues, "Disclaimer")
      setIsSaving(true)
      Cta_v2DAO.postLinkInfo(linkObject)
        .then(response => {
          setIsSaving(false)
          if (response.errors) {
            let str = []
            Object.keys(response.errors).forEach(k => {
              //let myType = k
              if (typeof response.errors[k] === "object") {
                let myStr = []
                response.errors[k].forEach(itm => {
                  myStr.push(itm)
                })
                str.push({ type: k, value: myStr.join("\n") })
              } else {
                str.push({ type: k, value: response.errors[k] })
              }
            })
            setDialogFailMessage(str)
            setDialogFailOpen(true)
            setDisableCancel(false)
          } else {
            const str = linkObject.id
              ? "Link Update succeeded"
              : `Link Creation succeeded. New ID is:${response.id}`
            if (response.message && response.message.length > 0) {
              const msg = response.message //.split("||")
              const scsMessage = linkSaveMessage(str, msg)
              setDialogSuccessMessage(scsMessage)
              setDialogSuccessOpen(true)
            } else {
              setDialogSuccessMessage(str)
              setDialogSuccessOpen(true)
            }
            if (!linkObject.id && response.id) {
              const newFormValues = {
                ...myFormValues,
                id: response.id
              }
              setMyFormValues(newFormValues)
              getLinkDisplayInfo(2)
              setCreatingNewLink(response.id)
            } else if (navElementsHaveChanged) {
              getLinkDisplayInfo(2)
              getLinkInfo(linkObject.id)
            }
            setDisableCancel(false)
          }
        })
        .catch(err => {
          if (err.message) {
            let str = []
            Object.keys(err).forEach(k => {
              //let myType = k
              if (typeof err[k] === "object") {
                let myStr = []
                err[k].forEach(itm => {
                  myStr.push(itm)
                })
                str.push({ type: k, value: myStr.join("\n") })
              } else {
                str.push({ type: k, value: err[k] })
              }
            })
            const msg = str.length
              ? str
              : [
                  { type: "message", value: err?.message },
                  { type: "stack", value: err?.stack }
                ]
            setDialogFailMessage(msg)
            setDialogFailOpen(true)
          }
          setDisableCancel(false)
        })
    } else {
      setDialogWarningNoBtnOpen(true)
      setDialogWarningNoBtnMessage(foundNotValid)
      setDisableCancel(false)
    }
  }

  const infoDialog = () => (
    <>
      <div>
        <h3 id="alert-dialog-title">Company Information:</h3>
        <div>
          <div>
            A <strong>Company</strong> denotes the “parent” group that the offer
            is about (ie GameSys owns Bally and is the company for all Bally
            CTAs).
          </div>
          <p>
            When the <strong>Link URL </strong> field is edited, the update will
            cascade down to all Affiliate URLs within associated CTA Variant
            templates..
          </p>
        </div>
      </div>
    </>
  )

  const noOwner = {
    name: "NONE",
    firstName: "NONE",
    lastName: "",
    id: null
  }

  const properties = constructOrgPropList(myFormValues)
  const hasProperties = !!properties.length

  const sortedAccounts = [noOwner].concat(
    accounts
      ?.toJS()
      .filter(itm => itm.status === "Active")
      .sort((a, b) => {
        // Use toUpperCase() to ignore character casing
        const nameA = `${a.firstName} ${a.lastName}`.toUpperCase()
        const nameB = `${b.firstName} ${b.lastName}`.toUpperCase()
        let comparison = 0
        if (nameA > nameB) {
          comparison = 1
        } else if (nameA < nameB) {
          comparison = -1
        }
        return comparison
      })
  )

  return (
    <div aria-label="CTA Disclaimer Link Form">
      {(linkIsLoading || isSaving) && <LinearProgress />}
      {linkIsLoading && (
        <div className={classes.progressContainer}>
          <CircularProgress size={100} />
        </div>
      )}
      {!resetEditor && !linkIsLoading && (
        <Grid className={classes.CTAGrid}>
          <Paper>
            <GridItem xs={12}>
              <Card elevation={0}>
                <CardContent className={classes.collapseContent}>
                  <GridItem xs={6} className={classes.infoHeader}>
                    <Typography style={{ fontWeight: "bold" }}>
                      Disclaimer Link Info
                      <IconButton
                        onClickCapture={() => {
                          setDialogInfoMessage(infoDialog())
                          setDialogInfoOpen(true)
                        }}
                        className={classes.CTAmodalIcon}
                        size="large"
                      >
                        <InfoIcon variant="outlined" color="primary">
                          Open alert dialog
                        </InfoIcon>
                      </IconButton>
                    </Typography>
                  </GridItem>
                  <GridItem xs={4} style={{ width: "15vw" }}>
                    <Autocomplete
                      className={classes.ctaTop}
                      size="small"
                      fullWidth
                      options={sortedAccounts || []}
                      required={true}
                      getOptionLabel={option =>
                        `${option.firstName} ${option.lastName}`
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="OWNER"
                          variant="outlined"
                          key={params.id}
                          value={params.name}
                        />
                      )}
                      value={
                        sortedAccounts.find(
                          acct => acct.id === ownerAccountId
                        ) ?? noOwner
                      }
                      onChange={(event, newValue, reason) => {
                        handleAutocompleteChange(
                          event,
                          newValue,
                          reason,
                          "ownerAccountId"
                        )
                      }}
                    />
                  </GridItem>
                  <GridItem xs={3} variant="outlined">
                    <CTAStatusTypes
                      value={status || ""}
                      onChange={e => {
                        const newFormValues = {
                          ...myFormValues,
                          status: e,
                          disableSaveButton: false,
                          editingContract: true
                        }
                        setMyFormValues(newFormValues)
                      }}
                    />
                  </GridItem>
                </CardContent>
              </Card>
            </GridItem>
            <Grid>
              <Card elevation={0}>
                <FormControl
                  margin="normal"
                  fullWidth
                  className={classes.infoCard}
                >
                  {/* the field below will be fetch from tree by user */}
                  <GridItem xs>
                    {!!linkId && (
                      <TextField
                        label="LINK ID"
                        id="linkId"
                        variant="outlined"
                        value={linkId}
                        fullWidth={true}
                        disabled={true}
                      />
                    )}
                    {!linkId && (
                      <TextField
                        label="NEW LINK"
                        id="linkId"
                        variant="outlined"
                        fullWidth={true}
                        disabled={true}
                      />
                    )}
                  </GridItem>
                  <GridItem xs>
                    <TextField
                      label="Link URL"
                      id="linkUrl"
                      variant="outlined"
                      fullWidth={true}
                      required={true}
                      value={linkUrl}
                      onChange={handleLinkChange("linkUrl")}
                      onBlur={() => handleInputValidation("linkUrl")}
                      error={ctaFormErrors.linkUrl}
                      helperText={
                        ctaFormErrors.linkUrl && ctaFormErrors.linkURLMsg
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  </GridItem>

                  <GridItem xs>
                    <TextField
                      label="LINK NAME"
                      fullWidth={true}
                      id="linkName"
                      variant="outlined"
                      required={true}
                      value={linkName}
                      onChange={handleLinkChange("linkName")}
                      InputLabelProps={{ shrink: true }}
                    />
                  </GridItem>
                  <GridItem xs>
                    <Autocomplete
                      fullWidth
                      options={
                        intentTypes?.sort(function (a, b) {
                          return a.name && a.name.localeCompare(b.name)
                        }) || []
                      }
                      required={true}
                      getOptionLabel={option => option.name}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="CTA INTENT"
                          variant="outlined"
                          key={params.id}
                          value={params.name}
                          required={true}
                          error={ctaFormErrors.ctaIntent}
                          helperText={
                            ctaFormErrors.ctaIntent
                              ? "CTA INTENT is Required"
                              : ""
                          }
                        />
                      )}
                      value={intentTypes?.find(it => {
                        if (it.id === ctaIntent) {
                          return true
                        } else {
                          return false
                        }
                      })}
                      onChange={(event, newValue, reason) => {
                        handleAutocompleteChange(
                          event,
                          newValue,
                          reason,
                          "ctaIntent"
                        )
                      }}
                      onBlur={() => handleInputValidation("ctaIntent")}
                      disabled={!!ctaIntent}
                    />
                  </GridItem>
                  <GridItem xs={1}>&nbsp;</GridItem>
                </FormControl>
              </Card>
            </Grid>
            <Grid>
              <Card elevation={0}>
                <FormControl
                  margin="normal"
                  fullWidth
                  className={classes.infoCard}
                >
                  <GridItem xs>
                    {companies?.length > 0 && (
                      <Autocomplete
                        className={classes.autocompleteCTA}
                        options={
                          [
                            companies.length === companyIds.length
                              ? { id: -2, name: "Remove All" }
                              : {
                                  id: -1,
                                  name: "Select All"
                                }
                          ].concat(companies.sort(Helpers.sortByName)) || []
                        }
                        variant="outlined"
                        multiple={true}
                        limitTags={2}
                        getOptionLabel={option => option.name}
                        value={companies?.filter(cmps =>
                          companyIds.includes(cmps.id)
                        )}
                        error={!companies && companies?.length === 0}
                        onChange={(event, newValue, reason) => {
                          handleAutoCompleteMultiChangeForCompanies(
                            event,
                            newValue,
                            reason,
                            "companyIds"
                          )
                        }}
                        onInputChange={(event, newValue, reason) => {
                          if (reason === "clear" || reason === "") {
                            handleAutocompleteChange(null)
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            key={params.id}
                            value={params.name}
                            label="COMPANY"
                            placeholder=""
                          />
                        )}
                        onBlur={() => handleInputValidation("companyIds")}
                      />
                    )}
                  </GridItem>
                  <GridItem xs>
                    {orgs?.length > 0 && (
                      <Autocomplete
                        multiple={true}
                        limitTags={2}
                        options={
                          [
                            orgs.length === organizationIds.length
                              ? { id: -2, name: "Remove All" }
                              : {
                                  id: -1,
                                  name: "Select All"
                                }
                          ].concat(
                            orgs?.sort(function (a, b) {
                              return a.name && a.name.localeCompare(b.name)
                            })
                          ) || []
                        }
                        required={true}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="ORGANIZATION"
                            variant="outlined"
                            key={params.id}
                            value={params.name}
                            required={true}
                            error={ctaFormErrors.organizationIds}
                            helperText={
                              ctaFormErrors.organizationIds
                                ? "Organization is Required"
                                : ""
                            }
                          />
                        )}
                        value={orgs?.filter(org =>
                          organizationIds.includes(org.id)
                        )}
                        onChange={(event, newValue, reason) => {
                          handleAutoCompleteMultiChangeForOrgs(
                            event,
                            newValue,
                            reason,
                            "organizationIds"
                          )
                        }}
                        onBlur={() => handleInputValidation("organizationIds")}
                        disabled={false}
                      />
                    )}
                  </GridItem>
                  {hasProperties && (
                    <GridItem xs>
                      <Autocomplete
                        fullWidth
                        multiple={true}
                        limitTags={2}
                        options={
                          [
                            properties.length === propertyIds.length
                              ? { id: -2, name: "Remove All" }
                              : {
                                  id: -1,
                                  name: "Select All"
                                }
                          ].concat(
                            properties?.sort(function (a, b) {
                              return a.name && a.name.localeCompare(b.name)
                            })
                          ) || []
                        }
                        required={false}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="PROPERTY"
                            variant="outlined"
                            key={params.id}
                            value={params.name}
                            required={true}
                            error={ctaFormErrors.propertyIds}
                            helperText={
                              ctaFormErrors.propertyIds
                                ? "Property is Required"
                                : ""
                            }
                          />
                        )}
                        value={properties?.filter(prps =>
                          propertyIds.includes(prps.id)
                        )}
                        onChange={(event, newValue, reason) => {
                          handleAutoCompleteMultiChangeForProperties(
                            event,
                            newValue,
                            reason,
                            "propertyIds"
                          )
                        }}
                        onBlur={() => handleInputValidation("propertyIds")}
                        disabled={false}
                      />
                    </GridItem>
                  )}
                  <GridItem xs>
                    <FormControlLabel
                      style={{ margin: "15px 0" }}
                      control={
                        <Switch
                          id="isArchived"
                          checked={isArchived || ""}
                          color="primary"
                          onChange={handleCheckboxChange}
                          size="small"
                        />
                      }
                      label="Archive"
                    />
                  </GridItem>
                  <GridItem>&nbsp;</GridItem>
                  {!hasProperties && <GridItem xs>&nbsp;</GridItem>}
                </FormControl>
              </Card>
            </Grid>
            <Divider className={classes.CTADivide} />
            <Grid style={{ display: "flex" }}>
              <GridItem>
                <Typography className={classes.CTATitle}>
                  Link Timeframe
                </Typography>
                <FormControl
                  margin="normal"
                  fullWidth
                  className={classes.infoCard}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="en"
                  >
                    <GridItem>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDateTimePicker
                          aria-label="CTAv2 VariantForm startDateTimePicker"
                          label="START DATE TIME"
                          value={startDate}
                          //onChange={setStartDate}
                          onChange={date => handleDateChange(date, "startDate")}
                          closeOnSelect={true}
                          slotProps={{
                            field: {
                              clearable: true,
                              onClear: clearStartDateFields
                            }
                          }}
                        />
                      </LocalizationProvider>
                    </GridItem>
                    <GridItem className={classes.linkTimeZone}>
                      <TimezoneSelect
                        value={timeZoneIANA}
                        onChange={zone =>
                          onTimeZoneChange(zone, "timeZoneIANA")
                        }
                        labelStyle="abbrev"
                      />
                    </GridItem>
                    <GridItem>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDateTimePicker
                          aria-label="CTAv2 VariantForm endDateTimePicker"
                          label="END DATE TIME"
                          value={endDate}
                          closeOnSelect={true}
                          onChange={date => handleDateChange(date, "endDate")}
                          slotProps={{
                            field: {
                              clearable: true,
                              onClear: clearEndDateFields
                            },
                            textField: {
                              helperText: endDateErrorMessage,
                              error: Boolean(endDateErrorMessage)
                            }
                          }}
                        />
                      </LocalizationProvider>
                    </GridItem>
                  </LocalizationProvider>
                </FormControl>
              </GridItem>
            </Grid>
            <Divider className={classes.CTADivide} />
            <Grid>
              <GridItem>
                <Typography className={classes.CTATitle}>
                  Disclaimer Details
                </Typography>
                <FormControl
                  margin="normal"
                  fullWidth
                  className={classes.infoCard}
                >
                  <GridItem xs>
                    <TextField
                      label="ASSET LINK"
                      id="assetLink"
                      variant="outlined"
                      fullWidth={true}
                      value={assetLink}
                      onChange={handleLinkChange("assetLink")}
                      onBlur={() => handleInputValidation("assetLink")}
                      error={ctaFormErrors.assetLink}
                      helperText={
                        ctaFormErrors.assetLink && ctaFormErrors.assetLinkMsg
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  </GridItem>
                  <GridItem xs>&nbsp;</GridItem>
                  <GridItem xs>&nbsp;</GridItem>
                </FormControl>
              </GridItem>
            </Grid>
            <Grid className={classes.CTAButton}>
              <>
                <Button
                  className={classes.buttonStyle}
                  variant="contained"
                  size="medium"
                  color="secondary"
                  disabled={!validLinkUrl}
                >
                  <a
                    target="blank"
                    title={`View Link URL in New Tab`}
                    href={linkUrl}
                    style={{ color: "#fff" }}
                  >
                    Preview Link
                  </a>
                </Button>
                <Button
                  className={classes.buttonStyle}
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={() => {
                    props.cancelFormEditsAndReload({
                      linkId: origFormValues.id
                    })
                  }}
                  disabled={disableCancel}
                >
                  Cancel
                </Button>
                <Button
                  className={classes.buttonStyle}
                  variant="contained"
                  size="medium"
                  color="primary"
                  type="submit"
                  onClick={() => {
                    setDisableCancel(true)
                    handleSaveForm()
                  }}
                  disabled={
                    disableSaveButton ||
                    isSaving ||
                    ctaFormErrors.validationHasError
                  }
                >
                  Save
                </Button>
              </>
            </Grid>
            {isSaving && <LinearProgress />}
          </Paper>
        </Grid>
      )}
    </div>
  )
  //}
}
CTADisclaimerLinkForm.propTypes = {
  closePage: PropTypes.func,
  openDisclaimerLinkBlocks: PropTypes.func,
  linkInformation: PropTypes.object
}

export default CTADisclaimerLinkForm
