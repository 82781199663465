import React, { useEffect, useState } from "react"
import withStyles from "@mui/styles/withStyles"
import PropTypes from "prop-types"

import LinearProgress from "@mui/material/LinearProgress"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import DialogActions from "@mui/material/DialogActions"
import Enums from "tools/Enums.js"

import Button from "components/CustomButtons/Button.jsx"
import LinkAndVariantUploadButton from "components/CustomButtons/LinkAndVariantUploadButton.jsx"

import * as XLSX from "xlsx"
import { urlValidation } from "tools/ValidationHelpers"
import TableControlsNoToolbar from "components/NarrativeAdmin/Data/TableControlsNoToolbar"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import LinkOfferBulkUploadInfo from "components/CTAManagerV2/LinkOfferBulkUploadInfo"
import LinkDisclaimerBulkUploadInfo from "components/CTAManagerV2/LinkDisclaimerBulkUploadInfo"

import LinkOfferVariantBulkUploadInfo from "components/CTAManagerV2/LinkOfferVariantBulkUploadInfo"
import useUploadLinkOfferHelpers from "tools/UploadLinkOffersHelpers"

import Cta_v2DAO from "daos/cta_v2DAO"
import AffiliateDAO from "daos/affiliateDAO"
import { TryRounded } from "@mui/icons-material"
import dayjs from "dayjs" //Dayjs
import utc from "dayjs/plugin/utc"
import "dayjs/locale/en"
dayjs.extend(utc)

const styles = theme => ({
  resultsModal: {
    position: "absolute",
    width: "90vw",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "45%",
    left: "45%",
    transform: "translate(-45%, -45%)",
    _textAlign: "center",
    overflow: "auto",
    maxHeight: "95vh",
    "& h2": {
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      fontWeight: "500",
      fontSize: "1.42857rem",
      lineHeight: "1.6",
      letterSpacing: "0.0075em",
      textAlign: "center",
      //margin: "0 0 0 35vw",
      padding: "0"
    },
    "& h6": {
      textAlign: "left",
      margin: "0 0 0 35vw"
    },
    "& ul.topList": {
      textAlign: "left",
      margin: "0 0 0 35vw",
      padding: "0"
    },
    "& p": {
      textAlign: "left",
      margin: "0 0 0 35vw",
      padding: "0"
    }
  },
  impropertlyFormattedRow: {
    color: "#C00",
    fontWeight: "500"
  },
  infoValidationHeader: {
    textAlign: "center",
    color: "#inherit"
  },
  addScrollIfNeeded: {
    maxHeight: "300px",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "10px 0 0 0",
    "& li": {
      textAlign: "left"
    }
  },
  requiredMsg: {
    color: "#c00",
    fontWeight: "bold",
    backgroundColor: "#f7f7f7"
  },
  bulkUploadList: {
    "&.MuiListItem-dense": {
      paddingTop: "1px",
      paddingBottom: "0px"
    },
    "&.MuiListItem-gutters": {
      paddingLeft: "8px"
    },
    marginLeft: "2px",
    fontSize: ".9em",
    textAlign: "left"
  },
  leftJustify: {
    padding: "12px 12px 12px 2px"
  },
  errorList: {
    listStyleType: "none",
    textAlign: "left",
    margin: "0 0 0 35vw",
    padding: "0"
  }
})

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    companiesInfo: store.ctav2Store.companiesInfo,
    getCompaniesInfo: store.ctav2Store.getCompaniesInfo,
    organizations: store.ctav2Store.organizationsInfo,
    getOrganizations: store.ctav2Store.getOrganizationsInfo,
    regionsInfo: store.ctav2Store.regionsInfo,
    getRegionsInfo: store.ctav2Store.getRegionsInfo,
    getVariantLeagues: store.ctav2Store.getVariantLeagues,
    variantLeagues: store.ctav2Store.variantLeagues,
    linkTypeInfo: store.ctav2Store.linkTypeInfo,
    getLinkTypeInfo: store.ctav2Store.getLinkTypeInfo,
    getIntentInfo: store.ctav2Store.getIntentInfo,
    intentInfo: store.ctav2Store.intentInfo,
    getLinkDisplayInfo: store.ctav2Store.getLinkDisplayInfo,
    displayStyle: store.ctav2Store.displayStyle,
    verticals: store.ctav2Store.verticals,
    offerLinksInfo: store.ctav2Store.offerLinksInfo,
    disclaimerLinksInfo: store.ctav2Store.disclaimerLinksInfo,
    accounts: store.accountStore.accounts,
    getAccounts: store.accountStore.getAccounts,
    setDialogSuccessMessage: store.uiStore.setDialogSuccessMessage,
    setDialogSuccessOpen: store.uiStore.setDialogSuccessOpen,
    setDialogWarningNoBtnMessage: store.uiStore.setDialogWarningNoBtnMessage,
    setDialogWarningNoBtnOpen: store.uiStore.setDialogWarningNoBtnOpen,
    setIsError: store.uiStore.setIsError,
    setErrorMessage: store.uiStore.setErrorMessage,
    setDialogInfoMessage: store.uiStore.setDialogInfoMessage,
    setDialogInfoOpen: store.uiStore.setDialogInfoOpen
  }))
}

function CTALinksUpload(props) {
  const { classes, linkType } = props
  const [resetButton, setResetButton] = useState(false)
  const [showResultsModal, setShowResultsModal] = useState(false)
  const [showAskToLoadOfferLinks, setShowAskToLoadOfferLinks] = useState(false)
  const [loadingValidEntries, setLoadingValidEntries] = useState(false)
  const [savedResults, setSavedResults] = useState([])
  const [resultTableData, setResultTableData] = useState([])

  const [affiliatesForOrgLoaded, setAffiliatesForOrgLoaded] = useState(false)
  const [inputFileData, setInputFileData] = useState({})
  const [whatAreWeUploading, setWhatAreWeUploading] = useState("")

  const _OFFER_LINK = 1
  const _PROPERTY_NOT_FOUND = 999999

  const {
    setDialogInfoMessage,
    setDialogInfoOpen,
    setDialogSuccessMessage,
    setDialogSuccessOpen,
    setDialogWarningNoBtnMessage,
    setDialogWarningNoBtnOpen,
    setIsError,
    setErrorMessage,
    organizations,
    getOrganizations,
    getRegionsInfo,
    getCompaniesInfo,
    getLinkTypeInfo,
    getIntentInfo,
    getLinkDisplayInfo,
    getVariantLeagues,
    displayStyle,
    verticals,
    offerLinksInfo,
    disclaimerLinksInfo,
    regionsInfo,
    variantLeagues,
    companiesInfo,
    linkTypeInfo,
    getAccounts,
    accounts,
    intentInfo
  } = useStoreData()

  const {
    constructOrgPropList,
    getStatusId,
    getOwnerId,
    getPropertyId,
    isValidOrganizationName,
    formValueOfferLinkValidation,
    formValueDisclaimerLinkValidation,
    formValueVariantValidation,
    saveLinkEntry,
    saveVariantEntry,
    addUpTotalOrgAndPropCombinations,
    getEndDateFromLink,
    columnsOfferLinks,
    columnsDisclaimerLinks,
    columnsVariant,
    errorColumn
  } = useUploadLinkOfferHelpers({
    linkTypeInfo,
    regionsInfo,
    urlValidation,
    linkType,
    companiesInfo,
    organizations,
    accounts,
    setInputFileData,
    Enums,
    Cta_v2DAO,
    dayjs,
    setAffiliatesForOrgLoaded,
    intentInfo,
    offerLinksInfo,
    disclaimerLinksInfo,
    variantLeagues,
    displayStyle,
    verticals
  })

  const [notCompliantTSV, setNotCompliantTSV] = useState(false)
  const [resultString, setResultString] = useState("")
  const [resultMessagingString, setResultMessagingString] = useState("")
  const [resultObject, setResultObject] = useState([])
  const [improperlyFormattedRow, setImproperlyFormattedRow] = useState([])
  const [file, setFile] = useState(null)
  const [readerType, setReaderType] = useState("")
  const [targetFileName, setTargetFileName] = useState("")

  useEffect(() => {
    let fileReader
    let isCancel = false
    if (file) {
      fileReader = new FileReader()
      fileReader.onload = evt => {
        // Parse data
        const bstr = evt.target.result
        const wb = XLSX.read(bstr, { type: "binary" })
        // Get first worksheet
        const wsname = wb.SheetNames[0]
        let ws = wb.Sheets[wsname]
        //So the code below will detect the commas and escape those values before adding the commas back in to create the CSV
        Object.keys(ws).forEach(key => {
          Object.keys(ws[key]).forEach(inkey => {
            if (inkey === "v") {
              if (typeof ws[key][inkey] === "string") {
                let tmp = ws[key][inkey]
                tmp = tmp.replace(/,/g, "__COMMASPOT__")
                ws[key][inkey] = tmp
              }
            }
          })
        })
        // Convert array of arrays, will account for any commas in the data that need to be escaped before conversion to CSV
        const dataResult = XLSX.utils.sheet_to_csv(ws, { header: 1 })
        if (readerType === "Link" && !isCancel) {
          setWhatAreWeUploading("Link")
          if (linkType === "Offer") {
            processTSVdataOfferLink(dataResult)
          } else if (linkType === "Disclaimer") {
            processTSVdataDisclaimerLink(dataResult)
          }
        } else if (readerType === "Variant" && !isCancel) {
          setWhatAreWeUploading("Variant")
          processTSVdataVariant(dataResult)
        }
      }
      if (file) {
        fileReader.readAsArrayBuffer(file)
      }
    }
    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        setIsError(true)
        setErrorMessage("There is a problem with the file you read, aborting")
        fileReader.abort()
      }
    }
  }, [file])

  const handleFileUpload = (e, type) => {
    //In here we read a Tab Seperated Values (TSV) file and when it loads convert it to CSV
    const file = e.target.files[0] //Why not CSV to begin with? Because we have commas (,) in the data and that blows up a CSV
    let pathBrokenOut = e.target.value.split(/.+(\\.+)$/g)
    setTargetFileName(pathBrokenOut.join(""))
    setFile(file)
    setReaderType(type)
  }

  const validateTSV = (myList, affToOrg, type) => {
    const issues = []
    myList.forEach((itm, index) => {
      const foundNotValid =
        type === "Link" && linkType === "Offer"
          ? formValueOfferLinkValidation(itm, affToOrg)
          : type === "Link" && linkType === "Disclaimer"
          ? formValueDisclaimerLinkValidation(itm, affToOrg)
          : type === "Variant"
          ? formValueVariantValidation(itm, affToOrg)
          : formValueOfferLinkValidation(itm, affToOrg)
      if (foundNotValid) {
        issues.push({ row: index + 1, content: foundNotValid })
      }
    })
    if (issues.length) {
      if (issues.length === myList.length) {
        setResultString(
          type === "Link"
            ? `Step 1 - ${linkType} Link File Validation Review, "${targetFileName}"`
            : type === "Variant"
            ? `Step 1 - ${linkType} Link Variant File Validation Review, "${targetFileName}"`
            : "Step 1 - File Validation Review"
        )
        setResultObject(issues)
        setResultMessagingString("All entries failed file validation.")
      } else {
        setResultString(
          type === "Link"
            ? `Step 1 - ${linkType} Link File Validation Review, "${targetFileName}"`
            : type === "Variant"
            ? `Step 1 - ${linkType} Link Variant File Validation Review, "${targetFileName}"`
            : "Step 1 - File Validation Review"
        )
        setResultObject(issues)
        setResultMessagingString(
          `${myList.length - issues.length} deliverables passed validation. ${
            issues.length
          }  deliverables failed validation.`
        )
      }
      setShowAskToLoadOfferLinks(true)
      setNotCompliantTSV(true)
    } else {
      // All the entries are valid
      setResultString(
        type === "Link"
          ? `Step 1 - ${linkType} Link File Validation Review, "${targetFileName}"`
          : type === "Variant"
          ? `Step 1 - ${linkType} Link Variant File Validation Review, "${targetFileName}"`
          : "Step 1 - File Validation Review"
      )
      setResultMessagingString("File is valid.")
      setShowAskToLoadOfferLinks(true)
      setNotCompliantTSV(false)
    }
    return { issues, modifiedList: myList }
  }

  const callDisclaimerAPI = (numberToAdd, list, columnsMapping, affToOrg) => {
    const { issues, modifiedList } = validateTSV(list, affToOrg, "Link")
    const newList = []
    modifiedList.forEach((itm, i) => {
      const obj = {}
      columnsMapping.forEach(itm2 => {
        if (itm[itm2.name] || itm[itm2.name] === "") {
          obj[itm2.selector] = itm[itm2.name]
        }
      })
      issues.forEach(errs => {
        if (errs.row === i + 1) {
          obj.hasError = true
          obj.errorDetails = errs.content
        }
      })
      if (!obj.errorDetails) {
        obj.errorDetails = []
      }
      obj.ownerAccountId = getOwnerId(itm)
      obj.statusId = getStatusId(itm)
      obj.intentId = _OFFER_LINK
      obj.isArchived = false
      obj.timeZoneIANA = itm.timeZone ? itm.timeZone : "America/Los_Angeles"
      obj.startDateTime = itm.startDateTime
        ? itm.startDateTime
        : dayjs().toISOString()
      obj.endDateTime = itm.endDateTime
      obj.companyId = itm.companyId
      obj.url = itm.linkUrl
      obj.companies = itm.companies
      obj.organizationId = itm.organizationId
      obj.organizations = itm.organizations
      newList.push(obj)
    })
    setResultTableData(newList)
    if (issues.length === 0) {
      setShowAskToLoadOfferLinks(true)
    }
  }

  const callLinkAPI = (numberToAdd, list, columnsMapping, affToOrg) => {
    const { issues, modifiedList } = validateTSV(list, affToOrg, "Link")
    const newList = []
    modifiedList.forEach((itm, i) => {
      const obj = {}
      columnsMapping.forEach(itm2 => {
        if (itm[itm2.name] || itm[itm2.name] === "") {
          obj[itm2.selector] = itm[itm2.name]
        }
      })
      issues.forEach(errs => {
        if (errs.row === i + 1) {
          obj.hasError = true
          obj.errorDetails = errs.content
        }
      })
      if (!obj.errorDetails) {
        obj.errorDetails = []
      }
      obj.ownerAccountId = getOwnerId(itm)
      obj.statusId = getStatusId(itm)
      obj.intentId = _OFFER_LINK
      obj.isArchived = false
      obj.timeZoneIANA = itm.timeZone ? itm.timeZone : "America/Los_Angeles"
      obj.startDateTime = itm.startDateTime
        ? itm.startDateTime
        : dayjs().toISOString()
      obj.endDateTime = itm.endDateTime
      obj.companyId = itm.companyId
      obj.affiliateId = itm.affiliateId
      obj.linkTypeId = itm.linkTypeId
      obj.url = itm.linkUrl
      obj.companies = companiesInfo
        .toJS()
        .filter(itmx => itmx.id === itm.companyId)
      obj.organizationId = itm.organizationId
      obj.organizations = constructOrgPropList(itm)
      obj.monetizationRegions = itm.monetizationRegions
      const thePayoutValue =
        itm.conversionPayoutAmount.match(/\d{1,5}\.?\d{0,2}/g)
      obj.conversionPayoutAmount = thePayoutValue
        ? Number(thePayoutValue.join(""))
        : 0
      newList.push(obj)
    })
    setResultTableData(newList)
    if (issues.length === 0) {
      setShowAskToLoadOfferLinks(true)
    }
  }

  const callLinkVariantAPI = (numberToAdd, list, columnsMapping, affToOrg) => {
    const { issues, modifiedList } = validateTSV(list, affToOrg, "Variant")
    const newList = []
    modifiedList.forEach((itm, i) => {
      const obj = {}
      columnsMapping.forEach(itm2 => {
        if (itm[itm2.name] || itm[itm2.name] === "") {
          obj[itm2.selector] = itm[itm2.name]
        }
      })
      issues.forEach(errs => {
        if (errs.row === i + 1) {
          obj.hasError = true
          obj.errorDetails = errs.content
        }
      })
      if (!obj.errorDetails) {
        obj.errorDetails = []
      }
      obj.ownerAccountId = getOwnerId(itm)
      obj.statusId = getStatusId(itm)
      //obj.displayStyle = itm.displayStyle
      obj.displayStyleId = itm.displayStyleId
      obj.verticalId = itm.verticalId
      obj.isArchived = false
      obj.timeZoneIANA = itm.timeZone ? itm.timeZone : "America/Los_Angeles"
      obj.startDateTime = itm.startDateTime
        ? itm.startDateTime
        : dayjs().toISOString()
      obj.endDateTime = itm.endDateTime
        ? itm.endDateTime
        : getEndDateFromLink(itm.linkId)
      obj.linkId = Number(itm.linkId)
      obj.priority = Number(itm.priority)
      obj.template = itm.template
      obj.leagues = itm.leagues
      obj.events = itm.events
      newList.push(obj)
    })
    setResultTableData(newList)
    if (issues.length === 0) {
      setShowAskToLoadOfferLinks(true)
    }
  }
  //Link URL	Company 	Short Name Offer	Organization 	Property 	Affiliate 	Region	Conversion Type	Conversion Payout Amount	Start Date Time	End Date Time	Time Zone	Offer Link Type	Promo Text	Asset Link	Owner 	Status
  const processTSVdataVariant = dataString => {
    const dataStringLines = dataString.split(/\r\n|\n/) //split by CR/LF
    const headersOrig = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    ) //Take the first "line" and split out the header columns as our name keys
    const keyListConverter = {
      "Variant Name": "variantName",
      "Offer Link ID": "linkId",
      Priority: "priority",
      League: "league",
      "Display Style": "displayStyle",
      Vertical: "vertical",
      Template: "template",
      Events: "events",
      Owner: "owner",
      Status: "status",
      "Start Date Time": "startDateTime",
      "End Date Time": "endDateTime",
      "Time Zone": "timeZone"
    }
    const headers = []
    headersOrig.forEach(itm => {
      const itmX = itm.trim()
      if (keyListConverter[itmX]) {
        headers.push(keyListConverter[itmX].trim())
      } else {
        headers.push(itmX)
      }
    })
    const list = []
    const badRows = []
    for (let i = 1; i < dataStringLines.length; i++) {
      dataStringLines[i] = dataStringLines[i].replace(/""/g, '"') //To remove double quotes "" to single quote "
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      )
      if (headers && row.length === headers.length) {
        const obj = {}
        for (let j = 0; j < headers.length; j++) {
          //This uses the single header values as the "name" and the specific row values as the "value"
          let columnValue = row[j] //For the properties of  "obj" which will correspond  to the name-value pairs for this row
          if (columnValue.length > 0) {
            if (columnValue[0] === '"') {
              columnValue = columnValue.substring(1, columnValue.length - 1)
            }
            if (columnValue[columnValue.length - 1] === '"') {
              columnValue = columnValue.substring(columnValue.length - 2, 1)
            }
          }
          if (headers[j]) {
            obj[headers[j]] = columnValue.replace(/__COMMASPOT__/g, ",").trim()
          }
        }

        // do not add any blank rows
        if (Object.values(obj).filter(x => x).length > 0) {
          list.push(obj)
        }
      } else {
        badRows.push(
          `You do not see Row #${i} in the TSV becasue it is improperly formatted,  check the TSV to determine why (usually an unclosed quote)`
        )
      }
    }
    // prepare columns list from headers
    const columnsMapping = headers.map(c => ({
      name: c,
      selector: c
    }))
    if (badRows.length) {
      setImproperlyFormattedRow(badRows)
    }
    if (list.length) {
      callLinkVariantAPI(list.length, list, columnsMapping)
    } else if (badRows.length) {
      setShowAskToLoadOfferLinks(true)
    }
    // setDialogInfoMessage(
    //   "The Variant Upload ticket, DS-6348, will be done soon."
    // )
    // setDialogInfoOpen(true)
  }

  const processTSVdataDisclaimerLink = dataString => {
    // setDialogWarningNoBtnMessage(
    //   "Working on Disclaimer Uploads, DS-6352, stay tuned"
    // )
    // setDialogWarningNoBtnOpen(true)
    const dataStringLines = dataString.split(/\r\n|\n/) //split by CR/LF
    const headersOrig = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    ) //Take the first "line" and split out the header columns as our name keys
    const keyListConverter = {
      "Link URL": "linkUrl",
      Company: "company",
      "Link Name": "linkName",
      Organization: "organization",
      Property: "property",
      "Asset Link": "assetLink",
      "Start Date Time": "startDateTime",
      "End Date Time": "endDateTime",
      "Time Zone": "timeZone",
      Owner: "owner",
      Status: "status"
    }
    const headers = []
    headersOrig.forEach(itm => {
      const itmX = itm.trim()
      if (keyListConverter[itmX]) {
        headers.push(keyListConverter[itmX].trim())
      } else {
        headers.push(itmX)
      }
    })
    const list = []
    const badRows = []
    for (let i = 1; i < dataStringLines.length; i++) {
      dataStringLines[i] = dataStringLines[i].replace(/""/g, '"') //To remove double quotes "" to single quote "
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      )
      if (headers && row.length === headers.length) {
        const obj = {}
        for (let j = 0; j < headers.length; j++) {
          //This uses the single header values as the "name" and the specific row values as the "value"
          let columnValue = row[j] //For the properties of  "obj" which will correspond  to the name-value pairs for this row
          if (columnValue.length > 0) {
            if (columnValue[0] === '"') {
              columnValue = columnValue.substring(1, columnValue.length - 1)
            }
            if (columnValue[columnValue.length - 1] === '"') {
              columnValue = columnValue.substring(columnValue.length - 2, 1)
            }
          }
          if (headers[j]) {
            obj[headers[j]] = columnValue.replace(/__COMMASPOT__/g, ",").trim()
          }
        }

        // do not add any blank rows
        if (Object.values(obj).filter(x => x).length > 0) {
          list.push(obj)
        }
      } else {
        badRows.push(
          `You do not see Row #${i} in the TSV becasue it is improperly formatted,  check the TSV to determine why (usually an unclosed quote)`
        )
      }
    }
    // prepare columns list from headers
    const columnsMapping = headers.map(c => ({
      name: c,
      selector: c
    }))
    if (badRows.length) {
      setImproperlyFormattedRow(badRows)
    }
    if (list.length) {
      //getAffiliateInformation(list.length, list, columnsMapping)
      callDisclaimerAPI(list.length, list, columnsMapping)
    } else if (badRows.length) {
      setShowAskToLoadOfferLinks(true)
    }
  }

  const processTSVdataOfferLink = dataString => {
    const dataStringLines = dataString.split(/\r\n|\n/) //split by CR/LF
    const headersOrig = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    ) //Take the first "line" and split out the header columns as our name keys
    const keyListConverter = {
      "Link URL": "linkUrl",
      Company: "company",
      "Short Name Offer": "shortNameOffer",
      Organization: "organization",
      Property: "property",
      Affiliate: "affiliate",
      Region: "region",
      "Conversion Type": "conversionType",
      "Conversion Payout Amount": "conversionPayoutAmount",
      "Start Date Time": "startDateTime",
      "End Date Time": "endDateTime",
      "Time Zone": "timeZone",
      "Offer Link Type": "offerLinkType",
      "Promo Text": "promoText",
      "Asset Link": "assetLink",
      Owner: "owner",
      Status: "status"
    }
    const headers = []
    headersOrig.forEach(itm => {
      const itmX = itm.trim()
      if (keyListConverter[itmX]) {
        headers.push(keyListConverter[itmX].trim())
      } else {
        headers.push(itmX)
      }
    })
    const list = []
    const badRows = []
    for (let i = 1; i < dataStringLines.length; i++) {
      dataStringLines[i] = dataStringLines[i].replace(/""/g, '"') //To remove double quotes "" to single quote "
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      )
      if (headers && row.length === headers.length) {
        const obj = {}
        for (let j = 0; j < headers.length; j++) {
          //This uses the single header values as the "name" and the specific row values as the "value"
          let columnValue = row[j] //For the properties of  "obj" which will correspond  to the name-value pairs for this row
          if (columnValue.length > 0) {
            if (columnValue[0] === '"') {
              columnValue = columnValue.substring(1, columnValue.length - 1)
            }
            if (columnValue[columnValue.length - 1] === '"') {
              columnValue = columnValue.substring(columnValue.length - 2, 1)
            }
          }
          if (headers[j]) {
            obj[headers[j]] = columnValue.replace(/__COMMASPOT__/g, ",").trim()
          }
        }

        // do not add any blank rows
        if (Object.values(obj).filter(x => x).length > 0) {
          list.push(obj)
        }
      } else {
        badRows.push(
          `You do not see Row #${i} in the TSV becasue it is improperly formatted,  check the TSV to determine why (usually an unclosed quote)`
        )
      }
    }
    // prepare columns list from headers
    const columnsMapping = headers.map(c => ({
      name: c,
      selector: c
    }))
    if (badRows.length) {
      setImproperlyFormattedRow(badRows)
    }
    if (list.length) {
      getAffiliateInformation(list.length, list, columnsMapping)
    } else if (badRows.length) {
      setShowAskToLoadOfferLinks(true)
    }
  }

  const getAffiliateInformation = async (len, list, columnsMapping) => {
    const affToOrg = []
    const totalOrgAndPropCombinations = addUpTotalOrgAndPropCombinations(list)
    if (totalOrgAndPropCombinations > 0) {
      list?.forEach(itm => {
        //First, use the Organization Name to find the ID
        if (isValidOrganizationName(itm.organization)) {
          organizations?.every(org => {
            if (org.name === itm.organization) {
              itm.organizationId = org.id
              return false
            } else {
              return true
            }
          })
        }
      })
      list.forEach((itm, index) => {
        if (itm.organizationId) {
          let theIds = []
          if (itm.property) {
            //If you have Property(s), the Affiliated is through the Property, not the Org.
            //You will need to check all properties to ensure all have affiliates
            theIds = getPropertyId(itm)
            //theId = theId ? theId : itm.organizationId
          } else {
            //if no Property(s), then the Affiliate is through the Org
            theIds.push({
              id: itm.organizationId,
              name: itm.organization,
              type: "Organization"
            })
          }
          theIds.forEach(theIdObj => {
            if (theIdObj.id === _PROPERTY_NOT_FOUND) {
              affToOrg.push({
                orgId: _PROPERTY_NOT_FOUND,
                name: "NONE",
                affiliates: []
              })
              if (affToOrg.length === totalOrgAndPropCombinations) {
                setAffiliatesForOrgLoaded(true)
                setInputFileData({ list, columnsMapping, affToOrg })
              }
            } else {
              const myAffiliate = AffiliateDAO.getAffiliatesByOrgId(theIdObj.id)
              myAffiliate.then(results => {
                if (results) {
                  affToOrg.push({
                    orgId: theIdObj.id,
                    name: theIdObj.name,
                    type: theIdObj.type,
                    affiliates: results
                  })
                }
                if (affToOrg.length === totalOrgAndPropCombinations) {
                  setAffiliatesForOrgLoaded(true)
                  setInputFileData({ list, columnsMapping, affToOrg })
                  //callAPI(list.length, list, columnsMapping)
                }
              })
            }
          })
        }
      })
    } else {
      setDialogWarningNoBtnMessage(
        "There are no Organizations in this file, check to make sure it is valid"
      )
      setDialogWarningNoBtnOpen(true)
    }
  }

  const showVariantSuccessInfo = (header, list) => (
    <div>
      <div className={classes.infoValidationHeader}>{header}</div>
      <div className={classes.addScrollIfNeeded}>
        <ul>
          {list?.map((itm, i) => (
            <li key={i}>
              Offer Variant - {itm.id} - {itm.variantName} - {itm.priority} for
              Link {itm.linkId}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )

  const getDisclaimerOrgPropertyString = itm => {
    let props = []
    itm.organizations.forEach(org => {
      const ps = []
      org.properties.forEach(prp => {
        ps.push(prp.name)
      })
      props.push({
        org: org.name,
        properties: ps.length ? ps.join(", ") : null
      })
    })
    return (
      <span>
        {props?.map((itm, i) => (
          <span key={i}>
            {"("}
            {itm.org}
            {itm.properties ? ` - ${itm.properties}` : ""}
            {")"}
          </span>
        ))}
      </span>
    )
  }

  const getDisclaimerCompanyString = itm => {
    if (itm.companies.length === 0) {
      return <span>No Company</span>
    } else {
      return (
        <span>
          {"("}
          {itm.companies?.map((itm, i) => (
            <span key={i}>{i ? ` - ${itm.name}` : itm.name}</span>
          ))}
          {")"}
        </span>
      )
    }
  }

  const showDisclaimmerLinkSuccessInfo = (header, list) => (
    <div>
      <div className={classes.infoValidationHeader}>{header}</div>
      <div className={classes.addScrollIfNeeded}>
        <ul>
          {list?.map((itm, i) => (
            <li key={i}>
              {linkType} Link - {itm.id} - {getDisclaimerOrgPropertyString(itm)}{" "}
              - {getDisclaimerCompanyString(itm)} - {itm.shortNameOffer}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )

  const getOfferOrgPropertyString = itm => {
    let props = []
    itm.organizations[0].properties.forEach(prp => {
      props.push(prp.name)
    })
    const result = props.length > 0 ? `/${props.join(",")}` : ""
    return itm.organizations[0].name + result
  }

  const showOfferLinkSuccessInfo = (header, list) => (
    <div>
      <div className={classes.infoValidationHeader}>{header}</div>
      <div className={classes.addScrollIfNeeded}>
        <ul>
          {list?.map((itm, i) => (
            <li key={i}>
              {linkType} Link - {itm.id} - {getOfferOrgPropertyString(itm)} -{" "}
              {itm.companies[0].name} - {itm.shortNameOffer}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )

  const saveValidEntries = () => {
    const validEntries = resultTableData.filter(x => !x.hasError)
    let numLoaded = 0
    if (whatAreWeUploading === "Variant") {
      validEntries.forEach((row, index) => {
        //console.log(row)
        const saveObj = saveVariantEntry(row)
        //const saveObj = row
        Cta_v2DAO.postVariant(saveObj).then(response => {
          row.id = response.id
          numLoaded += 1
          if (numLoaded === validEntries.length) {
            setSavedResults(validEntries)
            //setShowResultsModal(true)
            setShowAskToLoadOfferLinks(false)
            setShowResultsModal(false)
            const str =
              numLoaded === 1
                ? `${numLoaded} ${linkType} Variant has been created`
                : `${numLoaded} ${linkType} Variants have been created`
            setDialogSuccessMessage(showVariantSuccessInfo(str, validEntries))
            setDialogSuccessOpen(true)
            setLoadingValidEntries(false)
          }
        })
      })
    } else if (whatAreWeUploading === "Link") {
      validEntries.forEach((row, index) => {
        //console.log(row)
        const saveObj = saveLinkEntry(row)
        Cta_v2DAO.postLinkInfo(saveObj).then(response => {
          row.id = response.id
          numLoaded += 1
          if (numLoaded === validEntries.length) {
            setSavedResults(validEntries)
            setShowAskToLoadOfferLinks(false)
            setShowResultsModal(false)
            const str =
              numLoaded === 1
                ? `${numLoaded} ${linkType} Link has been created`
                : `${numLoaded} ${linkType} Links have been created`
            if (linkType === "Offer") {
              setDialogSuccessMessage(
                showOfferLinkSuccessInfo(str, validEntries)
              )
            } else if (linkType === "Disclaimer") {
              setDialogSuccessMessage(
                showDisclaimmerLinkSuccessInfo(str, validEntries)
              )
            }
            setDialogSuccessOpen(true)
            setLoadingValidEntries(false)
            getLinkDisplayInfo(
              linkType === "Offer" ? 1 : linkType === "Disclaimer" ? 2 : 1
            )
          }
        })
      })
    }
  }

  const buttonParams = {
    color: "primary",
    style: {
      color: "#c00",
      backgroundColor: "#2ff",
      borderRadius: "5px",
      padding: "10px"
    }
  }

  // on first render
  useEffect(() => {
    getCompaniesInfo()
    getRegionsInfo() //Need real regions list
    getOrganizations()
    getLinkTypeInfo()
    getIntentInfo()
    getAccounts()
    getVariantLeagues()
  }, [])

  useEffect(() => {
    if (resetButton) {
      setResetButton(false)
    }
  }, [resetButton])

  useEffect(() => {
    if (
      affiliatesForOrgLoaded &&
      inputFileData?.list?.length &&
      inputFileData?.affToOrg.length &&
      addUpTotalOrgAndPropCombinations(inputFileData?.list) ===
        inputFileData?.affToOrg.length
      //inputFileData?.list?.length === inputFileData?.affToOrg.length
    ) {
      callLinkAPI(
        inputFileData.list.length,
        inputFileData.list,
        inputFileData.columnsMapping,
        inputFileData.affToOrg
      )
      setAffiliatesForOrgLoaded(false)
    }
  }, [inputFileData, affiliatesForOrgLoaded])

  return (
    <>
      {!resetButton && (
        <>
          {loadingValidEntries && (
            <Box sx={{ width: "100%", height: "10px" }}>
              <LinearProgress />
            </Box>
          )}
          <LinkAndVariantUploadButton
            doLinkFileUpload={e => {
              handleFileUpload(e, "Link")
            }}
            doVariantFileUpload={e => {
              handleFileUpload(e, "Variant")
            }}
            buttonParams={buttonParams}
            acceptedSuffix={".tsv"}
            setDialogInfoOpen={() => {
              setDialogInfoOpen(true)
            }}
            leftJustifyStyle={classes.leftJustify}
            setDialogInfoMessage={type => {
              if (type === "Link") {
                if (linkType === "Offer") {
                  setDialogInfoMessage(
                    LinkOfferBulkUploadInfo(classes.bulkUploadList)
                  )
                } else if (linkType === "Disclaimer") {
                  setDialogInfoMessage(
                    LinkDisclaimerBulkUploadInfo(classes.bulkUploadList)
                  )
                }
              } else if (type === "Variant") {
                setDialogInfoMessage(
                  LinkOfferVariantBulkUploadInfo(classes.bulkUploadList)
                )
              }
            }}
          />
          <>
            <Modal
              open={showResultsModal}
              onClose={() => {
                props.resetButton()
              }}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div className={classes.resultsModal}>
                <h2>You successfully saved the following Jobs:</h2>
                <div>
                  <TableControlsNoToolbar
                    columns={[
                      {
                        Header: `${linkType} Link ID`,
                        accessor: "id",
                        width: "80",
                        Cell: ({ row }) => (
                          <div style={{ padding: "5px" }}>
                            {row.original?.id}
                          </div>
                        )
                      }
                    ].concat(
                      whatAreWeUploading === "Variant"
                        ? columnsVariant
                        : linkType === "Offer"
                        ? columnsOfferLinks
                        : linkType === "Disclaimer"
                        ? columnsDisclaimerLinks
                        : []
                    )}
                    hideColumns={[]}
                    data={savedResults}
                    readOnly={true}
                  />
                </div>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setShowResultsModal(false)
                      getLinkDisplayInfo(1)
                      props.resetButton()
                    }}
                  >
                    Close
                  </Button>
                </DialogActions>
              </div>
            </Modal>
            <Modal
              open={showAskToLoadOfferLinks}
              onClose={() => {
                setShowAskToLoadOfferLinks(false)
                props.resetButton()
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div className={classes.resultsModal}>
                {!notCompliantTSV && TryRounded && (
                  <div id="initial-modal-description">
                    <h2>{resultString}</h2>
                    <h3 style={{ margin: "0 0 10px 0" }}>
                      {resultMessagingString}
                    </h3>
                  </div>
                )}
                {notCompliantTSV && true && (
                  <div id="initial-modal-description">
                    <h2>{resultString}</h2>
                    <h3 style={{ margin: "0 0 10px 0" }}>
                      {resultMessagingString}
                    </h3>
                    <ul
                      style={{
                        overflow: "auto auto",
                        maxHeight: "15vh",
                        maxWidth: "50vw"
                      }}
                    >
                      {resultObject &&
                        resultObject.map((itm, i) => (
                          <li key={i}>
                            Row #{itm.row}
                            <ul>
                              {itm.content?.map((err, j) => (
                                <li key={j}>{err}</li>
                              ))}
                            </ul>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
                {improperlyFormattedRow.length > 0 &&
                  improperlyFormattedRow.map((itm, i) => (
                    <li className={classes.impropertlyFormattedRow} key={i}>
                      {itm}
                    </li>
                  ))}
                {resultTableData.length > 0 && (
                  <>
                    <TableControlsNoToolbar
                      columns={errorColumn.concat(
                        whatAreWeUploading === "Variant"
                          ? columnsVariant
                          : linkType === "Offer"
                          ? columnsOfferLinks
                          : linkType === "Disclaimer"
                          ? columnsDisclaimerLinks
                          : []
                      )}
                      hideColumns={[]}
                      data={resultTableData}
                      handleDataChange={() => {}} //Not able to EDIT the entries
                      skipReset={true}
                    />
                    <DialogActions>
                      <Button
                        onClick={() => {
                          setShowAskToLoadOfferLinks(false)
                          props.resetButton()
                        }}
                      >
                        Cancel
                      </Button>
                      {resultTableData.filter(x => !x.hasError).length > 0 && (
                        <Button
                          onClick={() => {
                            setLoadingValidEntries(true)
                            setShowAskToLoadOfferLinks(false)
                            saveValidEntries()
                          }}
                        >
                          Upload
                        </Button>
                      )}
                    </DialogActions>
                  </>
                )}
              </div>
            </Modal>
          </>
        </>
      )}
    </>
  )
}

CTALinksUpload.propTypes = {
  resetButton: PropTypes.func
}
export default withStyles(styles)(CTALinksUpload)
