import React from "react"
import PropTypes from "prop-types"

// MUI Components
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Chip from "@mui/material/Chip"

import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles({
  box: {
    padding: "1rem 0"
  },
  chip: {
    marginRight: 8
  },
  buttonText: {
    fontWeight: "bold"
  }
})

const FilterChips = ({
  table,
  activeFilters,
  handleFilterClear,
  handleFilterDelete
}) => {
  const { box, chip, buttonText } = useStyles()

  return (
    <>
      {activeFilters.length > 0 ? (
        <Box className={box}>
          {activeFilters.map(filter => {
            if (Array.isArray(filter.value)) {
              return filter.value.map(obj => (
                <Chip
                  classes={{ root: chip }}
                  key={`${filter.id}-${obj.value}-chip`}
                  label={`${filter.filterName}: ${obj.value}`}
                  onDelete={() => {
                    handleFilterDelete(filter.filterName, filter.id, obj.value)
                  }}
                  variant="outlined"
                />
              ))
            } else {
              return (
                <Chip
                  classes={{ root: chip }}
                  key={`${filter.id}-${filter.value.value}-chip`}
                  label={`${filter.filterName}: ${filter.value.value}`}
                  onDelete={() => {
                    handleFilterDelete(filter.filterName)
                  }}
                  variant="outlined"
                />
              )
            }
          })}
          <Button
            classes={{ label: buttonText }}
            onClick={() => {
              table.resetColumnFilters(true)

              handleFilterClear()
            }}
          >
            Clear Filters
          </Button>
        </Box>
      ) : (
        <></>
      )}
    </>
  )
}

FilterChips.propTypes = {
  table: PropTypes.object,
  activeFilters: PropTypes.array,
  handleFilterClear: PropTypes.func,
  handleFilterDelete: PropTypes.func
}

export default FilterChips
