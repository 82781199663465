import React from "react"
import withStyles from "@mui/styles/withStyles"
import CodeBlock from "../Data/CodeBlock"
import HighlightSpan from "../Data/HighlightSpan"
import "codemirror/addon/mode/simple"
import "assets/syntax/cm-eureka"
import "assets/syntax/cm-eureka.css"
import "codemirror/keymap/sublime"
import PropTypes from "prop-types"

const styles = () => ({
  sentencePreview: {
    fontWeight: "normal",
    "& .xml": {
      fontWeight: "bold",
      fontSize: "13.5px",
      fontStyle: "normal"
    },
    "& pre": {
      padding: "5px 15px!important"
    }
  },
  sentenceHeader: {
    margin: "0 15px",
    padding: "10px 0!important",
    display: "flex",
    "& .highlighted": {
      backgroundColor: "#ffeaa7",
      color: "#e17055",
      boxShadow: "-3px 0 0 #ffeaa7, 3px 0 0 #ffeaa7" // Creates an illusion of a wider span, while not affecting the actual width.
    }
  },
  codePreview: {
    margin: "0 10px",
    padding: "20px!important",
    background: "#000",
    color: "#fff",
    cursor: "pointer",
    fontFamily: "monospace",
    fontSize: "12px",
    "& .highlighted": {
      backgroundColor: "#ffeaa7",
      color: "#e17055",
      boxShadow: "-3px 0 0 #ffeaa7, 3px 0 0 #ffeaa7" // Creates an illusion of a wider span, while not affecting the actual width.
    }
  },
  codeHolder: {
    margin: "0",
    padding: "0 10px"
  },
  codeEditorArea: {
    display: "flex",
    minHeight: "250px"
  },
  edit: {
    marginLeft: "auto",
    padding: "0px"
  }
})

function ArchivedSentencePreview(props) {
  const { sentence, classes, searchString } = props
  const {
    display,
    name,
    template,
    trigger_id,
    sentence_id,
    triggerType,
    triggerOption,
    status_Id,
    isArchived
  } = sentence

  if (!sentence) {
    return null
  }

  const sentenceName = display || name
  const titleText = `${
    !!sentenceName && sentenceName !== "" ? `${sentenceName} ` : ""
  }, Id = "${sentence_id || trigger_id}"`

  return (
    <React.Fragment>
      <div
        style={{
          background: status_Id !== 3 ? "rgba(193, 184, 184, 0.6)" : "initial",
          textDecoration: isArchived ? "line-through" : "initial",
          paddingBottom: "10px",
          borderTop: "1px solid #efefef"
        }}
      >
        <div className={classes.sentenceHeader}>
          <div style={{ flexGrow: 1 }} className="highlighter">
            <h6>
              <HighlightSpan highlight={searchString}>
                {titleText}
              </HighlightSpan>
            </h6>
            {triggerType !== undefined && (
              <React.Fragment>
                Triggered by{" "}
                {triggerType !== undefined &&
                  triggerType !== "" &&
                  triggerType.split(", ").map(keyName => (
                    <React.Fragment key={keyName}>
                      <strong>
                        <u
                          onClick={() =>
                            this.context.setActiveView(
                              `searchtriggers-${keyName}`
                            )
                          }
                        >
                          <HighlightSpan highlight={searchString}>
                            {keyName}
                          </HighlightSpan>
                        </u>
                      </strong>
                      ,
                    </React.Fragment>
                  ))}
                ,{" "}
              </React.Fragment>
            )}{" "}
            {triggerOption !== undefined &&
              triggerOption !== "" &&
              triggerOption}
          </div>
        </div>
        <div className={classes.codePreview}>
          <CodeBlock highlight={searchString}>{template || ""}</CodeBlock>
        </div>
      </div>
    </React.Fragment>
  )
}

ArchivedSentencePreview.propTypes = {
  sentence: PropTypes.object,
  searchString: PropTypes.string,
  classes: PropTypes.object
}
export default withStyles(styles)(ArchivedSentencePreview)
