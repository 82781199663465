import React, { useState } from "react"
import { fromJS } from "immutable"

import Table from "components/Table/AdvancedTable"
import {
  DefaultColumnFilter,
  NumberRangeColumnFilter,
  SelectRowsHeaderActions,
  StatusTypeFilter
} from "components/Table/components/CustomFilters"
import {
  BasicCell,
  SelectRowCell,
  StatusTypeInput,
  SwitchInput,
  LinkTriggerCell,
  EditableCell
} from "components/Table/components/CustomCells"

import PropTypes from "prop-types"
import { Grid, FormControlLabel, Checkbox } from "@mui/material"
import { Form, Input, Body, Select, Button } from "components/Form"

import { Cookies } from "tools/storage"

import { NarrativeContext } from "contexts/narrative-context"
import { useContext } from "react"

import { removeJustSavedRowFromFilter } from "tools/tableUtil"
import NarrativePreviewDAO from "daos/narrativePreviewDAO"
import TriggerDAO from "daos/triggerDAO"
import useTableChangedRows from "hooks/table/useTableChangedRows"

export default function TriggerTable(props) {
  const { triggers, narrative, title } = props
  const [originalData, setOriginalData] = useState(triggers)
  const [data, setData] = useState(triggers)
  const { model, modelType, modelTypeId, contentId, contentType, itemIndex } =
    useContext(NarrativeContext)

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.
  const skipResetRef = React.useRef(false) //All references to skipResetRef added from ParagraphTable

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setData(triggers || [])
    setOriginalData(triggers || [])
  }, [triggers])

  const editValues = {
    key: undefined,
    name: undefined,
    formula: undefined,
    rank: undefined,
    description: undefined,
    comments: undefined,
    status_id: undefined,
    categories: undefined,
    requireddata: undefined,
    isarchived: undefined
  }

  const [bulkEditValues] = useState(editValues)
  const {
    changedRowIndexes,
    addChangedRow,
    removeChangedRow,
    addNumberNewChangedRows
    // recordChangedIndexes,
    // restoreLastChangedIndexes
  } = useTableChangedRows()

  const setAlertMessage = (id, action, type, code) => {
    let str = ""
    const newBulkEventSchedule = {
      [id]: action,
      alertMessage: str
    }
    if (type) {
      newBulkEventSchedule.type = type
    }
    if (code) {
      newBulkEventSchedule.type = code
    }
    props.bulkAlertMessage(newBulkEventSchedule)
    props.isAlertActive && props.isAlertActive()
  }

  const testTrigger = row => {
    let trigger = row.original
    const { formula: templateString } = trigger
    if (!templateString) {
      return
    }
    //First update the state of this particular row
    trigger.isTesting = true
    trigger.testComplete = false
    if (data[row.index]) {
      data[row.index] = trigger
    } else {
      data.push(trigger)
    }
    setData([...data])
    const previewRequest = {
      templateString,
      modelType,
      modelTypeId,
      narrativeId: row.narrativeId || narrative.id,
      contentId,
      contentType,
      dataModelText: !contentId ? model : "",
      itemIndex,
      useCache: false,
      excludeDependencies: false,
      triggerId: trigger.id,
      skipTriggers: true
    }
    const getTriggerPreview =
      NarrativePreviewDAO.evaluateTrigger(previewRequest)
    getTriggerPreview
      .then(evaluatedTrigger => {
        trigger.isTesting = false
        trigger.testComplete = true
        trigger.isTrue = evaluatedTrigger.isTrue
        trigger.hasErrors = evaluatedTrigger.hasErrors
        trigger.renderString = evaluatedTrigger.renderString
        trigger.contextError = evaluatedTrigger.contextError
        row.original = {
          ...evaluatedTrigger,
          ...trigger
        }
        data[row.index] = row.original
        setData([...data])
      })
      .catch(error => {
        trigger.isTesting = false
        trigger.testComplete = true
        trigger.isTrue = false
        trigger.hasErrors = true
        trigger.renderString = error
        trigger.contextError = error
        row.original = {
          ...trigger
        }
        data[row.index] = row.original
        setData([...data])
      })
  }

  const bulkTest = ({ selectedFlatRows }) => {
    //Edit later
    selectedFlatRows.forEach(row => {
      testTrigger(row)
    })
  }

  const saveTrigger = (row, isBulk) => {
    const { modelType } = narrative

    let triggerObj = {}
    row.getAllCells().forEach(itm => {
      const columnId = itm.column?.id
      //Read the columns and only use the fields you can filter as appropriate
      if (columnId && itm.column.getCanFilter()) {
        if (row.original[columnId] !== undefined) {
          triggerObj[columnId] = row.original[columnId]
        }
      }
    })
    triggerObj.id = row.original.id ? row.original.id : 0

    let modelTypeShortName =
      modelType && modelType.includes(".")
        ? modelType.split(".").pop()
        : modelType

    if (modelType === "QueryBuilder") {
      modelTypeShortName = `QueryBuilder${narrative.queryId}`
    }

    triggerObj.narrativeModelType = modelTypeShortName
    triggerObj.key = triggerObj.type
    setAlertMessage(
      triggerObj.id ? triggerObj.id : Number(999999990000 + row.index),
      "saving",
      isBulk ? "bulk" : "notBulk"
    )

    TriggerDAO.putTrigger(triggerObj)
      .then(response => {
        const { content: triggerId } = response
        skipResetRef.current = true
        setAlertMessage(
          triggerObj.id ? triggerObj.id : Number(999999990000 + row.index),
          "saved",
          isBulk ? "bulk" : "notBulk",
          triggerId
        )
        triggerObj.id = triggerId
        row.original = triggerObj
        row.toggleSelected(false)
        data[row.index] = row.original
        if (originalData[row.index]) {
          originalData[row.index] = row.original
        } else {
          originalData.push(row.original)
        }
        setOriginalData([...originalData])
        setData([...data])
        removeChangedRow(row.index)
      })
      .catch(err => {
        setAlertMessage(
          triggerObj.id ? triggerObj.id : Number(999999990000 + row.index),
          "failed",
          isBulk ? "bulk" : "notBulk",
          Response.status
        )
        console.error(
          `Error on ${isBulk ? "bulk " : ""}PUT to /api/trigger`,
          err
        )
      })
  }

  const updateSelected = (
    bulkEditValues,
    selectedFlatRows,
    toggleBulkEditor
  ) => {
    const rowsCopy = [...selectedFlatRows]
    const keys = Object.keys(bulkEditValues)

    rowsCopy.forEach(row => {
      let rowChanged = false
      keys.forEach(key => {
        if (bulkEditValues[key] !== undefined) {
          row.original[key] = bulkEditValues[key]
          rowChanged = true
        }
      })
      if (rowChanged) {
        saveTrigger(row)
      }
    })
    toggleBulkEditor()
  }

  const cancelChanges = row => {
    // Handle new rows and edited rows differently
    const dataCopy = [...data]
    let removedRow = false
    if (row.original.id !== null && row.original.id !== 0) {
      // Reset the edited row to the original state
      dataCopy[row.index] = originalData[row.index]
    } else {
      // Remove the new row from the collection
      dataCopy.splice(row.index, 1)
      removedRow = true
    }
    setData(dataCopy)
    removeChangedRow(row.index, { isRowDeleted: removedRow })
  }

  const duplicateElements = ({ selectedFlatRows }) => {
    let newData = [...data]
    selectedFlatRows.forEach(row => {
      const newRow = Object.assign({}, row.original)
      newRow.id = null
      newRow.status_Id = 3
      newData.unshift(newRow)
    })
    setData([...newData])
  }

  const bulkSaveElements = ({ selectedFlatRows }) => {
    //Edit later
    selectedFlatRows.forEach(row => {
      saveTrigger(row, true)
    })
    return true
  }
  const bulkArchiveElements = ({ selectedFlatRows }) => {
    //Edit later
    selectedFlatRows.forEach(row => {
      if (row && row.original && typeof row.original.isArchived === "boolean") {
        row.original.isArchived = true
        saveTrigger(row, true)
      }
    })
  }

  const addNewElements = numberToAdd => {
    const newData = [...data]
    for (let i = 0; i < numberToAdd; i++) {
      newData.unshift({
        id: null,
        narrative_Id: narrative.id,
        status_Id: 1,
        isActive: true
      })
    }
    setData(newData)
    addNumberNewChangedRows(numberToAdd)
  }

  const loadBulkEditComponent = React.useCallback(
    ({ selectedFlatRows, toggleBulkEditor, updateSelected }) => (
      <Form
        id="bulk-update"
        name="bulk-update"
        className="compact"
        initialValues={bulkEditValues}
        onSubmit={values =>
          updateSelected(values, selectedFlatRows, toggleBulkEditor)
        }
      >
        <Body>
          <div>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <h5>Bulk Editing</h5>
                <div style={{ padding: "12px", border: "1px solid black" }}>
                  <ul style={{ paddingInlineStart: "20px" }}>
                    {selectedFlatRows.map(d => (
                      <li key={d.original.id}>
                        {d.original.id}-{d.original.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </Grid>
              <Grid item xs={9}>
                <h5>Bulk Edit Values</h5>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <Input
                      fullWidth
                      type="number"
                      name="position"
                      id="position"
                      label="Position"
                      margin="dense"
                    />
                    <Input
                      fullWidth
                      type="text"
                      name="type"
                      id="type"
                      label="Key"
                      margin="dense"
                    />
                    <Input
                      fullWidth
                      type="number"
                      name="triggerWeight"
                      id="triggerWeight"
                      label="Weight"
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      fullWidth
                      type="text"
                      name="categories"
                      id="categoriesInput"
                      label="Categories"
                      margin="dense"
                    />

                    <Input
                      fullWidth
                      type="text"
                      name="requiredData"
                      id="requiredDataInput"
                      label="Required Data"
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      fullWidth
                      type="text"
                      name="formula"
                      id="formula"
                      label="Formula"
                      margin="dense"
                    />
                    <Select
                      id="statusType"
                      name="status_Id"
                      label="Status"
                      margin="dense"
                      fullWidth
                      options={fromJS([
                        { name: "No Change", value: undefined },
                        { name: "Draft", value: 1 },
                        { name: "Review", value: 2 },
                        { name: "Published", value: 3 },
                        { name: "Planned", value: 4 },
                        { name: "Sample", value: 5 }
                      ])}
                      optionValueKey="value"
                      optionLabelKey="name"
                    />
                    <FormControlLabel
                      control={<Checkbox name="isArchived" id="isArchived" />}
                      label="Archive?"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div style={{ padding: "12px", textAlign: "end" }}>
                  <Button type="button" onClick={toggleBulkEditor}>
                    Cancel
                  </Button>
                  <Button type="submit">Update</Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </Body>
      </Form>
    ),
    []
  )
  const loadSelectedRow = row => {
    if (!row.original.id) {
      return
    }
    data[row.index].isLoadingRow = true
    setData([...data])
    const getTrigger = TriggerDAO.getTrigger(row.original.id)
    getTrigger.then(response => {
      if (response) {
        data[row.index] = response
        row.original.isLoadingRow = false
        setData([...data])
      }
    })
  }

  // Load Column Width settings
  let cookies = new Cookies()
  let columnWidths = cookies.get("columnWidths") || {}

  const triggerColumns = React.useMemo(
    () => [
      {
        header: "Triggers",
        columns: [
          {
            header: "Id",
            accessorKey: "id",
            cell: context =>
              context.row.original.id
                ? LinkTriggerCell(context)
                : BasicCell(context),
            size: columnWidths["id"] || 75
          },
          {
            header: "Narrative",
            accessorKey: "narrative_Id",
            cell: BasicCell,
            size: columnWidths["narrative_Id"] || 110
          },
          {
            header: "Dependency",
            accessorKey: "isDependency",
            cell: SwitchInput,
            size: columnWidths["isDependency"] || 150
          },
          {
            header: "Create Replica",
            accessorKey: "createReplica",
            cell: SwitchInput,
            size: columnWidths["createReplica"] || 150
          },
          {
            header: "News",
            accessorKey: "isNews",
            cell: SwitchInput,
            size: columnWidths["isNews"] || 150
          },
          {
            header: "Active",
            accessorKey: "isActive",
            cell: SwitchInput,
            size: columnWidths["isActive"] || 150
          },
          {
            header: "Status",
            accessorKey: "status_Id",
            cell: StatusTypeInput,
            meta: {
              filterUi: StatusTypeFilter
            },
            size: columnWidths["status_Id"] || 150
          },
          {
            header: "Name",
            accessorKey: "name",
            cell: EditableCell,
            meta: {
              filterUi: DefaultColumnFilter
            },
            filterFn: "includesString",
            size: columnWidths["name"] || 150
          },
          {
            header: "Key",
            accessorKey: "type",
            cell: EditableCell,
            meta: {
              filterUi: DefaultColumnFilter
            },
            filterFn: "includesString",
            size: columnWidths["type"] || 300
          },
          {
            header: "Formula",
            accessorKey: "formula",
            cell: EditableCell,
            meta: {
              filterUi: DefaultColumnFilter
            },
            filterFn: "includesString",
            size: columnWidths["formula"] || 500
          },
          {
            header: "Rank",
            accessorKey: "rank",
            cell: EditableCell,
            meta: {
              filterUi: NumberRangeColumnFilter
            },
            filterFn: "inNumberRange",
            size: columnWidths["contentBlock"] || 150
          },
          {
            header: "Archived",
            accessorKey: "isArchived",
            cell: SwitchInput,
            size: columnWidths["isArchived"] || 150
          }
        ]
      }
    ],
    []
  )

  const columns = [
    {
      id: "select",
      size: 90,
      header: ({ table, column }) => (
        <React.Fragment>
          <div style={{ paddingBottom: "10px" }}>
            <span>Select</span>
          </div>
          <div>
            <SelectRowsHeaderActions
              column={column}
              table={table}
              loadSelectedRow={row => {
                loadSelectedRow(row)
              }}
            />
          </div>
        </React.Fragment>
      ),
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      cell: SelectRowCell,
      meta: {
        removeJustSavedRowFromFilter,
        saveItem: saveTrigger,
        cancelChanges,
        loadSelectedRow
      }
    },
    ...triggerColumns
  ]

  // When our cell renderer calls updateLocalCollection, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateLocalCollection = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    skipResetRef.current = true
    setData((old = []) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          if (row[columnId] !== value) {
            addChangedRow(rowIndex)
          }
          return {
            ...row,
            [columnId]: value
          }
        }
        return row
      })
    )
  }

  // After data changes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => {
    skipResetRef.current = false
  }, [data])

  return (
    <div style={{ padding: "10px" }}>
      <h3>{title}</h3>
      <Table
        tableType="Trigger"
        columns={columns}
        data={data}
        handleDataChange={updateLocalCollection}
        loadBulkEditComponent={loadBulkEditComponent}
        duplicateElements={duplicateElements}
        bulkSaveElements={bulkSaveElements}
        bulkArchiveElements={bulkArchiveElements}
        narrative={narrative}
        bulkTest={bulkTest}
        passDataUp={dta => {
          const newData = [...data]
          dta.forEach(itm => {
            newData.unshift(itm)
            addChangedRow(0, { isNewRow: true })
          })
          setData(newData)
        }}
        descriptorString={"Upload Triggers from a TSV file?"}
        allowUploads={true}
        loadSelectedRow={loadSelectedRow}
        addElements={addNewElements}
        updateSelected={updateSelected}
        skipReset={skipResetRef.current}
        changedRows={changedRowIndexes}
        enableColumnFilters
      />
    </div>
  )
}

TriggerTable.propTypes = {
  narrative: PropTypes.object,
  bulkAlertMessage: PropTypes.func,
  isAlertActive: PropTypes.func,
  loading: PropTypes.bool,
  setActiveView: PropTypes.func,
  exportSentence: PropTypes.func,
  exportParagraph: PropTypes.func,
  exportTrigger: PropTypes.func,
  activeView: PropTypes.string
}
