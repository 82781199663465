import React from "react"
import PropTypes from "prop-types"
import ReactDiffViewer from "react-diff-viewer"

import FormControlLabel from "@mui/material/FormControlLabel"
import Grid from "@mui/material/Grid"
import Switch from "@mui/material/Switch"

import WordCounter from "components/WordCounter/WordCounter"

const DiffViewer = ({ row }) => {
  const [isWordDiff, setIsWordDiff] = React.useState(true)
  const [isDarkDiffTheme, setIsDarkDiffTheme] = React.useState(false)
  const [compareMethod, setCompareMethod] = React.useState("diffWords")
  const [compareMethodCHARS, setCompareMethodCHARS] = React.useState(false)
  const [isSplitView, setIsSplitView] = React.useState(true)

  ///// This is the Diffviewer toggle options
  function toggleDarkTheme() {
    setIsDarkDiffTheme(!isDarkDiffTheme)
  }

  function toggleWordDiff() {
    setIsWordDiff(!isWordDiff)
  }
  function toggleSplitView() {
    setIsSplitView(!isSplitView)
  }

  function toggleCompareMethod() {
    if (compareMethodCHARS) {
      setCompareMethod("diffWords")
      setCompareMethodCHARS(false)
    } else {
      setCompareMethod("diffChars")
      setCompareMethodCHARS(true)
    }
  }

  let oldValue = row.original.oldValue || ""
  let newValue = row.original.newValue || ""
  // More than 5,000 characters and comparing by word/character is too slow.
  const compareCharacterThreshold = 5000
  let maxOldValueLength =
    oldValue.length > compareCharacterThreshold
      ? compareCharacterThreshold
      : oldValue.length
  let maxNewValueLength =
    newValue.length > compareCharacterThreshold
      ? compareCharacterThreshold
      : newValue.length
  const isTooLong =
    oldValue.length > compareCharacterThreshold ||
    newValue.length > compareCharacterThreshold
      ? true
      : false
  let leftTitleMessage = "Old Value/New Value Inline View"
  if (isTooLong) {
    leftTitleMessage += ` - (This change contains more than ${compareCharacterThreshold} characters. Compare lines for better performance)`
  }
  return (
    <div>
      <Grid container>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <FormControlLabel
            control={
              <Switch
                checked={isDarkDiffTheme}
                onChange={toggleDarkTheme}
                value="checkedA"
              />
            }
            label="Use Dark Theme?"
          />
          <FormControlLabel
            control={
              <Switch
                checked={isWordDiff}
                onChange={toggleWordDiff}
                value="checkedB"
              />
            }
            label="Highlight Word Differences?"
          />
          <FormControlLabel
            control={
              <Switch
                checked={compareMethodCHARS}
                onChange={toggleCompareMethod}
                value="checkedC"
              />
            }
            label="Compare Characters?"
          />
          <FormControlLabel
            control={
              <Switch
                checked={isSplitView}
                onChange={toggleSplitView}
                value="checkedC"
              />
            }
            label="Split View"
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <WordCounter title="Old Value" inputString={oldValue} />
        </Grid>
        <Grid item xs={6}>
          <WordCounter title="New Value" inputString={newValue} />
        </Grid>
      </Grid>
      <ReactDiffViewer
        oldValue={oldValue.substring(0, maxOldValueLength)}
        newValue={newValue.substring(0, maxNewValueLength)}
        splitView={isSplitView}
        leftTitle={
          isSplitView ? <span>Old Value</span> : <span>{leftTitleMessage}</span>
        }
        rightTitle={<span>New Value</span>}
        showDiffOnly={true} // Shows only the diffed lines and folds the unchanged lines
        disableWordDiff={!isWordDiff}
        useDarkTheme={isDarkDiffTheme}
        compareMethod={compareMethod}
      />
    </div>
  )
}

DiffViewer.propTypes = {
  row: PropTypes.object
}

export default DiffViewer
