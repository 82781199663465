import { globalSettings } from "variables/general"
import { get, put } from "../tools/maynardRequest"

export default class CTAPreviewDependencyDAO {
  static getCTADependency = async () => {
    const res = await get(
      `${globalSettings.maynardBaseUrl}/api/CallToActionPreviewDependency/1`
    )
    return await res.json()
  }

  static postCTADependency = async body => {
    const res = await put(
      `${globalSettings.maynardBaseUrl}/api/CallToActionPreviewDependency/1`,
      null,
      body
    )
    return await res.json()
  }

  static getCTAPreviewDependencyChangeLog = async () => {
    const res = await get(
      `${globalSettings.maynardBaseUrl}/api/CallToActionPreviewDependencyChangeLog/1`
    )
    return await res.json()
  }

  static postCTADependencyV2 = async body => {
    const res = await put(
      `${globalSettings.monetizationv2BaseUrl}/api/previewdependency`,
      null,
      body
    )
    return await res.json()
  }

  static getCTAPreviewDependencyV2ChangeLog = async previewDependencyId => {
    const res = await get(
      `${globalSettings.monetizationv2BaseUrl}/api/previewdependency/${previewDependencyId}/changelog`
    )
    return await res.json()
  }
}
