// eslint-disable-next-line no-unused-vars
import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import withStyles from "@mui/styles/withStyles"
import Button from "components/CustomButtons/Button.jsx"
import classnames from "classnames"
import { Box, Grid, Dialog, DialogTitle, LinearProgress } from "@mui/material"
import { Widgets } from "@mui/icons-material"
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import CardHeader from "components/Card/CardHeader.jsx"
import CardIcon from "components/Card/CardIcon.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CodeEditor from "components/CodeEditor"

import PropTypes from "prop-types"
import { globalSettings } from "variables/general.jsx"
import queryString from "query-string"

import WordCounter from "components/WordCounter/WordCounter.jsx"

import { NarrativeContext } from "contexts/narrative-context"
import { put } from "tools/request"

const styles = theme => ({
  sentencePreview: {
    fontWeight: "normal",
    "& .xml": {
      fontWeight: "bold",
      fontSize: "13.5px",
      fontStyle: "normal"
    },
    "& pre": {
      padding: "5px 15px!important"
    }
  },
  sentenceHeader: {
    margin: "0 15px",
    padding: "10px 0!important",
    display: "flex"
  },
  codePreview: {
    margin: "0 10px",
    padding: "20px!important",
    background: "#000",
    color: "#fff",
    cursor: "pointer",
    fontFamily: "monospace",
    fontSize: "12px",
    "& .highlighted": {
      backgroundColor: "#ffeaa7",
      color: "#e17055",
      boxShadow: "-3px 0 0 #ffeaa7, 3px 0 0 #ffeaa7" // Creates an illusion of a wider span, while not affecting the actual width.
    }
  },
  cardIconTitle: {
    color: "#000000"
  },
  codeHolder: {
    margin: "0",
    padding: "0 10px",
    position: "relative"
  },
  codeEditorArea: {
    display: "flex",
    minHeight: "250px"
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    padding: "0px",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  tabs: {
    borderBottom: "none!important",
    backgroundColor: "#fff",
    "& > .nav-item .nav-link": {
      cursor: "pointer"
    },
    "& > .nav-item .nav-link:hover": {
      backgroundColor: "rgba(0,0,0, 0.2)"
    },
    "& > .nav-item .nav-link.active": {
      backgroundColor: "#efefef",
      borderColor: "#dee2e6 #dee2e6 transparent"
    },
    "& .nav-tabs > .nav-tabs .nav-link.active": {
      backgroundColor: "#fff",
      borderColor: "#dee2e6 #dee2e6 #fff"
    },
    "& > .nav-item .nav-link.activeStarter": {
      backgroundColor: "#ddd",
      borderColor: "#dee2e6 #dee2e6 transparent"
    },
    "& .nav-tabs > .nav-tabs .nav-link.activeStarter": {
      backgroundColor: "#eee",
      borderColor: "#dee2e6 #dee2e6 #fff"
    }
  },
  list: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    justifyContent: "space-evenly"
  },
  actionButtons: {
    padding: "12px 3px",
    margin: "5px 3px"
  },
  itemButtons: {
    padding: "12px",
    margin: "5px 3px"
  },
  myListItems: {
    fontWeight: "bold",
    marginTop: "5px"
  }
})

class ScratchPadForStarters extends Component {
  static contextType = NarrativeContext
  constructor(props) {
    super(props)
    let template =
      (props.starterContent &&
        props.starterContent[0] &&
        props.starterContent[0].starter) ||
      ""
    this.state = {
      currentNarrativeId: +props.match.params.id,
      preview: "",
      activeSubTab: "Starter1",
      buildingPreview: false,
      savingTheStarter: false,
      savedStarter: false,
      template,
      starterContent: props.starterContent,
      readOnly: !!props.template
    }
    this.parseAndBuildPreview = this.parseAndBuildPreview.bind(this)
    this.buildPreview = this.buildPreview.bind(this)
    this.saveScratchPad = this.saveScratchPad.bind(this)
    this.subToggle = this.subToggle.bind(this)
    this.setConfirmOpen = this.setConfirmOpen.bind(this)
    this.userAudiInfo = this.userAudiInfo.bind(this)
    this.saveStarterScratchPad = this.saveStarterScratchPad.bind(this)
    this.verifySaveStarterScratchPad =
      this.verifySaveStarterScratchPad.bind(this)
  }

  verifySaveStarterScratchPad() {
    this.setState({ savingTheStarter: true })
    this.saveStarterScratchPad()
  }
  userAudiInfo(userAccount, auditInfo, accountOwner) {
    if (auditInfo && auditInfo.modifier) {
      auditInfo.modifier.id = userAccount.id
      auditInfo.modifiedOn = new Date()
    } else {
      auditInfo = {
        modifier: { id: userAccount.id },
        creator: { id: userAccount.id },
        owner: { id: accountOwner ? accountOwner : userAccount.id },
        modifiedOn: new Date()
      }
    }
    return auditInfo
  }

  saveStarterScratchPad() {
    let starter = this.state.template
    let myUserAudiInfo
    let userAccountObj = {}
    userAccountObj.id = this.props.userAccount

    myUserAudiInfo = this.userAudiInfo(
      userAccountObj,
      this.props.narrative.auditInfo,
      userAccountObj
    )

    if (starter) {
      const { narrative } = this.props
      const body = {
        id: narrative.id,
        starter: starter,
        auditInfo: myUserAudiInfo
      }
      put(`${globalSettings.apiBaseUrl}/api/narrative/starter`, null, body)
        .then(Response => Response && Response.ok === true && Response.json())
        .then(() => {
          this.setState({
            savedStarter: true,
            savingTheStarter: false
          })
        })
    }
  }

  subToggle(tab, index) {
    if (this.state.activeSubTab !== tab) {
      if (typeof index === "number") {
        this.setState({
          activeSubTab: tab,
          template:
            this.state.starterContent[index] &&
            this.state.starterContent[index].starter
        })
      } else {
        this.setState({
          activeSubTab: tab
        })
      }
    }
  }

  saveScratchPad() {
    this.setState({ saveScratchPadDialog: true })
  }

  setConfirmOpen() {
    this.setState({ savedStarter: false })
  }

  parseAndBuildPreview() {
    this.setState({ preview: "" })
    let tmpTokens = this.state.template
    if (tmpTokens) {
      this.buildPreview(tmpTokens)
    }
  }

  buildPreview(parsedTokens) {
    const { model, modelType, modelTypeId, contentId, contentType } = this.props
    const queryParams = queryString.parse(this.props.location.search)
    this.setState({ buildingPreview: true })
    const body = {
      contentId,
      contentType: contentId ? contentType : "",
      dataModelText: contentId ? "" : model,
      modelType,
      modelTypeId,
      narrativeId: this.state.currentNarrativeId,
      templateString: parsedTokens, //this.state.template,
      itemIndex: parseInt(queryParams.itemindex, 10),
      useCache: true
    }
    put(
      `${globalSettings.apiBaseUrl}/api/narrativepreview/template-preview`,
      null,
      body
    )
      .then(Response => Response && Response.ok === true && Response.json())
      .then(preview => {
        this.setState({
          preview,
          buildingPreview: false
        })
      })
  }

  render() {
    const {
      classes,
      isLibrary,
      starterName,
      narrativeId,
      starters,
      starterContent
    } = this.props

    if (
      !this.state.template &&
      starterContent &&
      starterContent.length > 0 &&
      starterContent[0].starter
    ) {
      // For when the template is empty but the starters are not, set template then
      this.setState({ template: starterContent[0].starter })
    }
    const { activeSubTab } = this.state
    let preview = this.state.preview
    preview = preview.replace(/\n/g, "<br/>")
    const noTestContent = !this.state.template
    return (
      <div className={classes.codeHolder}>
        {isLibrary && (
          <div style={{ padding: "10px" }}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Widgets />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Output Starters for Library ({narrativeId})
                </h4>
              </CardHeader>
              <CardBody>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: true
                      })}
                      onClick={e => {
                        e.preventDefault()
                      }}
                      title={starterName}
                      style={{ cursor: "pointer" }}
                    >
                      Commonly used Tokens, Triggers, and Functions for a
                      Library
                    </NavLink>
                  </NavItem>
                </Nav>
                <Box>
                  <CodeEditor
                    template={starters[0]}
                    handleFocus={editor => {
                      if (editor.getValue() === "Enter Starter Here") {
                        this.setState({
                          template: ""
                        })
                        editor.setValue("")
                      }
                    }}
                    isNotLibrary={false}
                    handleChange={editor => {
                      if (
                        editor &&
                        editor.getValue() != "template" &&
                        true //!readOnly
                      ) {
                        const editorContent = editor.getValue()
                        this.setState({
                          template: editorContent
                        })
                      } else if (editor.getValue() === "Enter Starter Here") {
                        this.setState({
                          template: ""
                        })
                      }
                    }}
                  />
                </Box>
                <Box>
                  <Grid container spacing={3}>
                    <Grid
                      container
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      <Button
                        onClick={() => this.parseAndBuildPreview()}
                        disabled={noTestContent}
                      >
                        Test
                      </Button>{" "}
                    </Grid>

                    <Grid
                      container
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      <Button
                        onClick={() => this.verifySaveStarterScratchPad()}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </CardBody>
            </Card>
          </div>
        )}
        {!isLibrary && ( //For a Narrative that is displaying the contents of its Libraries
          <div style={{ padding: "10px" }}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Widgets />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Output Starters</h4>
              </CardHeader>
              <CardBody>
                <Nav tabs className={classes.tabs}>
                  {starterContent &&
                    starterContent.length > 0 &&
                    starterContent.map((itm, index) => (
                      <NavItem>
                        <NavLink
                          className={classnames({
                            activeStarter:
                              activeSubTab === `Starter${index + 1}`
                          })}
                          onClick={() => {
                            this.subToggle(`Starter${index + 1}`, index)
                          }}
                          title={itm.name}
                          style={{ cursor: "pointer" }}
                        >
                          Lib ({itm.id})
                        </NavLink>
                      </NavItem>
                    ))}
                </Nav>
                <TabContent
                  activeTab={activeSubTab}
                  style={{
                    marginLeft: "1px",
                    marginRight: "1px",
                    marginTop: "-1px"
                  }}
                >
                  {starterContent &&
                    starterContent.length > 0 &&
                    starterContent.map((itm, index) => (
                      <div>
                        <TabPane tabId={`Starter${index + 1}`}>
                          {itm.starter &&
                            activeSubTab === `Starter${index + 1}` && (
                              <CodeEditor
                                template={itm.starter}
                                handleFocus={editor => {
                                  if (
                                    editor.getValue() === "Enter Starter Here"
                                  ) {
                                    this.setState({
                                      template: ""
                                    })
                                    editor.setValue("")
                                  }
                                }}
                                isNotLibrary={true}
                              />
                            )}
                          {!itm.starter &&
                            activeSubTab === `Starter${index + 1}` && (
                              <h4>
                                This Narrative points to a library ({itm.id})
                                which has no Starter
                              </h4>
                            )}
                        </TabPane>
                        {itm.starter &&
                          activeSubTab === `Starter${index + 1}` && (
                            <Box>
                              <Grid container spacing={3}>
                                <Grid
                                  container
                                  item
                                  xs={12}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center"
                                  }}
                                >
                                  {(!starterContent ||
                                    (starterContent &&
                                      starterContent.length === 0)) && (
                                    <h4>
                                      This Output is not associated with a
                                      library, so there is no Output Starter
                                      available.
                                    </h4>
                                  )}
                                  {starterContent &&
                                    starterContent.length > 0 && (
                                      <Button
                                        onClick={() =>
                                          this.parseAndBuildPreview()
                                        }
                                        disabled={noTestContent}
                                      >
                                        Test
                                      </Button>
                                    )}
                                </Grid>
                              </Grid>
                            </Box>
                          )}
                      </div>
                    ))}
                </TabContent>
                {(!starterContent ||
                  (starterContent && starterContent.length === 0)) && (
                  <h4>
                    This Output is not associated with a library, so there is no
                    Output Starter available.{" "}
                  </h4>
                )}
              </CardBody>
            </Card>
          </div>
        )}
        {(this.state.buildingPreview || this.state.savingTheStarter) && (
          <LinearProgress />
        )}
        <Box>
          <Grid container spacing={3} item xs={12}>
            <Grid item>
              <WordCounter title="Content" inputString={preview} />
              <div
                style={{
                  marginTop: "10px",
                  fontSize: "14px"
                }}
                dangerouslySetInnerHTML={{
                  __html: preview && preview.replace(/apxh:/g, "")
                }}
                ref={this.contentRef}
              />
            </Grid>
          </Grid>
        </Box>
        <Dialog
          open={this.state.savedStarter}
          onClose={() => {
            this.setState({ savedStarter: false })
          }}
          aria-labelledby="confirm-dialog"
        >
          <DialogTitle id="confirm-dialog">
            Your Output Starter has been saved
          </DialogTitle>
        </Dialog>
      </div>
    )
  }
}

ScratchPadForStarters.propTypes = {
  classes: PropTypes.object
}

export default withStyles(styles)(withRouter(ScratchPadForStarters))
