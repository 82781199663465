import { useState } from "react"

const useCheckboxSelection = ({ initialSelected = [] }) => {
  const [selectedIds, setSelectedIds] = useState(new Set(initialSelected))

  const toggleOneSelection = itemId => {
    const newSelectedIds = new Set(selectedIds)

    if (!newSelectedIds.has(itemId)) {
      newSelectedIds.add(itemId)
    } else {
      newSelectedIds.delete(itemId)
    }

    setSelectedIds(newSelectedIds)
  }

  const setItemsCheckState = (itemIds, checked) => {
    const newSelectedIds = new Set(selectedIds)
    itemIds.forEach(itemId => {
      checked ? newSelectedIds.add(itemId) : newSelectedIds.delete(itemId)
    })
    setSelectedIds(newSelectedIds)
  }

  const handleCheckboxSelect = e => {
    const clickedItemId = e.target.id
    toggleOneSelection(clickedItemId)
  }

  const isItemSelected = itemId => selectedIds.has(itemId)

  return {
    handleCheckboxSelect,
    isItemSelected,
    selectedIds,
    setItemsCheckState
  }
}

export default useCheckboxSelection
