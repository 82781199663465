import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import RealTimeDataBar from "./RealTimeDataBar"
import Button from "components/CustomButtons/Button"
//import CardHeader from "components/Card/CardHeader"
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  //AppBar,
  Card,
  CardContent,
  CardHeader,
  //LinearProgress,
  Typography,
  Paper,
  Grid,
  Divider,
  Box
  //LinearProgress
} from "@mui/material"
import { makeStyles } from "@mui/styles"

//import { useParams } from "react-router-dom"
import ModelPreviewLink from "components/NarrativeAdmin/NarrativePreviewer/ModelPreviewLink"
import { Edit, ExpandMore, Add } from "@mui/icons-material"
import RealTimeLibraryForm from "../RealTimeCreatorForm"
import InsightTypeForm from "./InsightTypeForm"
import RealTimeInsightLibraryTree from "./RealTimeInsightLibraryTree"
import RealTimeSentenceForm from "./RealTimeSentenceForm"
import InsightGroupForm from "./InsightGroupForm"
import RealTimeDAO from "daos/realTimeDAO"
import { useHistory } from "react-router-dom"

const useStyles = makeStyles(() => ({
  cardTop: {
    flexDirection: "column",
    backgroundColor: "#144e68",
    color: "white"
  },
  libraryTreeAccordion: {
    flex: "inline-flex"
  }
}))

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    realTimeLibraryById: store.realTimeStore.realTimeLibraryById,
    getRealTimeLibraryById: store.realTimeStore.getRealTimeLibraryById,
    insightSentence: store.realTimeStore.insightSentence,
    getInsightTypes: store.realTimeStore.getInsightTypes,
    insightTypes: store.realTimeStore.insightTypes
  }))
}

export default function EditorRealTimeLibrary() {
  const location = useLocation()
  const history = useHistory()
  const queryParams = new URLSearchParams(location.search)
  const id = queryParams.get("id")
  const sentenceId = queryParams.get("sentenceId")
  const groupId = queryParams.get("groupId")
  const insightTypeId = queryParams.get("insightTypeId")

  // console.log("EditorRealTimeLibrary component is rendering")
  // console.log("URL ID:", id)
  const classes = useStyles()
  const {
    realTimeLibraryById,
    getRealTimeLibraryById,
    getInsightTypes,
    insightTypes,
    insightSentence
  } = useStoreData()

  //const [library, setLibrary] = useState(null)
  const [loading, setLoading] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [selectedForm, setSelectedForm] = useState("sentence")
  const [mode, setMode] = useState("view")
  const [openSentence, setOpenSentence] = useState(false)
  const [openGroup, setOpenGroup] = useState(false)
  const [openInsightType, setOpenInsightType] = useState(false)
  const [insightMode, setInsightMode] = useState("create")
  const [insightTypeData, setInsightTypeData] = useState(null)
  const [sentenceData, setSentenceData] = useState(null)
  const [clickedSentence, setClickedSentence] = useState(null)
  const [clickedGroup, setClickedGroup] = useState(null)
  const [sentenceModelType, setSentenceModelType] = useState({})
  const [unusedInsightTypes, setUnusedInsightTypes] = useState([])
  const [showInsightFormNotDialog, setShowInsightFormNotDialog] =
    useState(false)

  useEffect(() => {
    const fetchLibraryData = async () => {
      if (id) {
        setLoading(true)
        try {
          await getRealTimeLibraryById(id)
          console.log("Fetched library data: ", realTimeLibraryById)
        } catch (err) {
          console.log("Error fetching libraryById: ", err)
        } finally {
          setLoading(false)
        }
      }
    }
    fetchLibraryData()
  }, [id])

  useEffect(() => {
    if (realTimeLibraryById?.league?.id > 0) {
      getInsightTypes(realTimeLibraryById.league.id)
    }
  }, [realTimeLibraryById])

  useEffect(() => {
    if (realTimeLibraryById?.insightTypes && insightTypes?.length > 0) {
      const availableInsightTypes = []
      insightTypes.forEach(it => {
        let exists = false
        realTimeLibraryById?.insightTypes.forEach(rt => {
          if (rt?.id === it?.id) {
            exists = true
          }
        })
        if (!exists) {
          availableInsightTypes.push(it)
        }
      })
      setUnusedInsightTypes(availableInsightTypes)
    }
  }, [realTimeLibraryById, insightTypes])

  useEffect(() => {
    if (insightTypeId) {
      const newInsightType = insightTypes.find(
        x => x.id === Number(insightTypeId)
      )
      setInsightMode("edit")
      setInsightTypeData(newInsightType)
      setOpenGroup(false)
      setOpenSentence(false)
      setOpenInsightType(true)
      setLoading(false)
      setShowInsightFormNotDialog(true)
    }
  }, [insightTypeId])

  useEffect(() => {
    if (clickedGroup?.id) {
      let mt = null
      const gId = clickedGroup.id
      insightSentence?.forEach(sent => {
        if (sent?.insightGroup?.id === gId) {
          mt = sent.modelType
        }
      })
      if (mt) {
        setSentenceModelType(mt)
      }
    }
  }, [clickedGroup, insightSentence])

  useEffect(() => {
    if (clickedSentence?.modelType) {
      setSentenceModelType(clickedSentence?.modelType)
    }
  }, [clickedSentence])

  const handleOpen = () => {
    setOpenSentence(true)
  }

  const handleSentenceFormSave = savedSentence => {
    setSentenceData(savedSentence)
    setOpenSentence(true)
  }

  const handleCloseSentence = () => {
    setOpenSentence(false)
  }

  const handleCloseForm = (event, reason) => {
    setOpenGroup(false)
  }

  const handleEditClick = () => {
    console.log("Before setMode:", mode) // Log current mode
    setMode("edit")
  }
  const handleCancelEdit = () => {
    setMode("view")
  }

  const handleSave = () => {
    // Perform save operation
    setMode("view")
  }

  const handleSaveGroup = async formData => {
    try {
      console.log("Saving group data:", formData)
      if (formData.groupId) {
        formData.id = formData.groupId
        const success = await RealTimeDAO.updateInsightGroup(
          formData,
          formData.id
        )
        console.log("Updated Insight Group:", formData)
        setMode("view")
        return success
      } else {
        const success = await RealTimeDAO.postInsightGroup(formData)
        console.log("Created new Insight Group:", formData)
        setMode("view")
        return success
      }
    } catch (err) {
      console.error("Error saving group data:", err)
    }
  }

  const handleAddTypeClick = event => {
    event.stopPropagation()
    setInsightMode("create")
    setInsightTypeData(null)
    setOpenInsightType(true)
    setShowInsightFormNotDialog(false)
    if (insightTypeId) {
      history.push(`/portal/real-time-library/edit?id=${id}`)
    }
  }

  const handleInsightTypeFormSave = savedData => {
    setInsightMode("edit")
    setInsightTypeData(savedData)
    setOpenInsightType(true)
  }

  const handleInsightTypeFormClose = () => {
    setOpenInsightType(false)
    history.push(`/portal/real-time-library/edit?id=${id}`)
  }

  if (loading) {
    return (
      <Typography variant="h5" style={{ paddingLeft: 8 }}>
        Loading Library...
      </Typography>
    )
  }

  return (
    <Box>
      {realTimeLibraryById ? (
        <>
          <Typography variant="h5" style={{ paddingLeft: 8 }}>
            {realTimeLibraryById
              ? `${realTimeLibraryById?.id} - ${realTimeLibraryById?.name}`
              : "No data"}
          </Typography>
          <ModelPreviewLink
          //   contentId={contentId}
          //   contentType={contentType}
          //   modelName={modelName}
          //   modelPreviewUrl={modelPreviewUrl}
          >
            Data Model:{" "}
          </ModelPreviewLink>
          <Card aria-label="RealTimeLibrary Editor Card">
            <CardHeader
              className={classes.cardTop}
              sx={{ borderRadius: 0 }}
              //DO NOT REMOVE will added in V2
              // action={
              //   <Button startIcon={<Add />} color="primary">
              //     Bulk Upload
              //   </Button>
              // }
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      Real Time Library Information
                    </AccordionSummary>
                    <Card>
                      <CardContent>
                        <Button
                          aria-label="RealTimeLibrary Editor EditBtn"
                          startIcon={<Edit />}
                          onClick={handleEditClick}
                          color="primary"
                          size="sm"
                          disabled={mode === "edit"}
                        >
                          EDIT
                        </Button>
                        <RealTimeLibraryForm
                          aria-label="RealTimeLibrary Editor InfoForm"
                          mode={mode}
                          onCancel={handleCancelEdit}
                          onSave={handleSave}
                          onModeChange={setMode}
                          libraryData={realTimeLibraryById || {}}
                        />
                      </CardContent>
                    </Card>
                  </Accordion>
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      sx={{
                        "& .MuiAccordionSummary-content": {
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center"
                        }
                      }}
                    >
                      <Typography>Library</Typography>
                      <Button
                        aria-label="RealTimeLibrary Editor AddTypeBtn"
                        startIcon={<Add />}
                        color="primary"
                        size="sm"
                        onClick={handleAddTypeClick}
                        style={{ marginLeft: "30px" }}
                        disabled={unusedInsightTypes.length === 0}
                      >
                        Add Type
                      </Button>
                    </AccordionSummary>
                    <Divider />
                    <AccordionDetails>
                      <RealTimeInsightLibraryTree
                        treeForLibraryNotOutput={true}
                        realTimeOutput={{}}
                        onItemClick={handleOpen}
                        libraryId={realTimeLibraryById.id}
                        realTimeLibrary={realTimeLibraryById}
                        openSentenceForm={val => {
                          setOpenSentence(true)
                          setOpenGroup(false)
                          setOpenInsightType(false)
                          setClickedSentence(val)
                        }}
                        openGroupForm={val => {
                          setOpenGroup(true)
                          setOpenSentence(false)
                          setOpenInsightType(false)
                          setClickedGroup(val)
                        }}
                        openInsightForm={val => {
                          setOpenGroup(false)
                          setOpenSentence(false)
                          setInsightMode("edit")
                          setOpenInsightType(true)
                          setInsightTypeData(val)
                        }}
                        closeForms={() => {
                          setOpenGroup(false)
                          setOpenSentence(false)
                          setOpenInsightType(false)
                        }}
                        sentenceId={sentenceId}
                        groupId={groupId}
                        insightTypeId={insightTypeId}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={9}>
                  <Paper elevation={0}>
                    {openSentence && (
                      <RealTimeSentenceForm
                        open={openSentence}
                        setOpen={setOpenSentence}
                        onClose={handleCloseSentence}
                        libraryId={realTimeLibraryById?.id}
                        onSave={handleSentenceFormSave}
                        saveData={sentenceData}
                        sentenceData={clickedSentence}
                        leagueId={realTimeLibraryById?.league?.id}
                      />
                    )}
                    {openGroup && (
                      <InsightGroupForm
                        onClose={handleCloseForm}
                        open={openGroup}
                        onSave={handleSaveGroup}
                        libraryId={realTimeLibraryById?.id}
                        groupData={clickedGroup}
                      />
                    )}
                    {openInsightType && (
                      <InsightTypeForm
                        open={openInsightType}
                        onClose={handleInsightTypeFormClose}
                        mode={insightMode}
                        showDialog={!showInsightFormNotDialog}
                        typeData={insightTypeData}
                        onSave={handleInsightTypeFormSave}
                        realTimeLibraryById={realTimeLibraryById}
                        unusedInsightTypes={unusedInsightTypes}
                      />
                    )}
                  </Paper>
                  <Grid
                    aria-label="RealTimeLibrary Data Bar"
                    style={{ padding: "8px 0 0 0" }}
                  >
                    <RealTimeDataBar
                      sentenceModelType={sentenceModelType}
                      leagueId={realTimeLibraryById.league.id}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </>
      ) : (
        <Typography variant="h5" style={{ paddingLeft: 8 }}>
          No Library Selected
        </Typography>
      )}
    </Box>
  )
}
