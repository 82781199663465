import React, { useState } from "react"
import makeStyles from "@mui/styles/makeStyles"
import { Add } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { grayColor } from "assets/jss/material-dashboard-pro-react"

const useStyles = makeStyles(() => ({
  addBlockHeader: {
    position: "relative",
    alignItems: "center",
    textAlign: "center",
    maxHeight: "60px",
    "& > label": {
      backgroundColor: "#ffffff",
      fontWeight: "bold",
      fontSize: "18px",
      padding: "5px 10px",
      margin: "0 auto",
      zIndex: 0,
      position: "relative",
      color: "#dddddd",
      textTransform: "capitalize",
      borderRadius: "20px",
      "& > a": {
        color: grayColor,
        margin: "0 5px"
      },
      "& > a:hover": {
        textDecoration: "underline"
      },
      "& > button": {
        position: "relative",
        top: "-2px",
        padding: 0,
        color: "#4d9ab5",
        "&:hover": {
          background: "none"
        }
      },
      "& > span": {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#dddddd",
        padding: "0 5px",
        borderRadius: "20px"
      }
    },
    "& > hr": {
      position: "absolute",
      width: "100%",
      borderColor: "#4d9ab5",
      borderWidth: "1px",
      top: "-2px"
    }
  }
}))

const NarrativeOutlineBlockAddDnD = props => {
  const classes = useStyles()
  const { elementId, elementName, narrativeId, position, handleAddBlock } =
    props
  const [hideButton, setHideButton] = useState(false)
  const updateHandling = elementId === "featuredimagejson" ? 1 : 0

  const newBlock = {
    addHTMLComment: false,
    blockAttributes: null,
    clonedFromTemplateBlock_Id: null,
    comments: "",
    contentBlock: elementId,
    contentSection: "",
    id: 0,
    isArchived: false,
    isLocked: false,
    isRequiredBlock: true,
    library_Paragraph_Id: null,
    maxLength: null,
    minLength: null,
    narrative_Id: narrativeId,
    position: position,
    renderType: "",
    sampleResult: "",
    sampleTemplate: "",
    triggerType: null,
    updateHandling,
    validationScript: null,
    validationSeverity_Id: null,
    tmpId: null,
    inEditMode: true
  }

  const handleClick = () => {
    setTimeout(() => {
      handleAddBlock(newBlock, position)
    }, 500)
    setHideButton(true)
  }

  return (
    !hideButton && (
      <>
        <div className={classes.addBlockHeader}>
          <hr className={classes.elementHr} />

          <label>
            <IconButton
              title={`Add ${elementName} Outline Block`}
              onClick={handleClick}
              aria-label={`Add ${elementName} Outline Block`}
              size="large"
            >
              <Add />
            </IconButton>
          </label>
        </div>
      </>
    )
  )
}

export default NarrativeOutlineBlockAddDnD
