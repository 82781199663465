import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import makeStyles from "@mui/styles/makeStyles"
import clsx from "clsx"
import { ExpandMore, Widgets } from "@mui/icons-material"
import Button from "components/CustomButtons/Button.jsx"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon"
import CardHeader from "components/Card/CardHeader"
import {
  Box,
  Grid,
  CardActions,
  Collapse,
  FormLabel,
  IconButton,
  InputBase,
  LinearProgress,
  Checkbox,
  CircularProgress,
  Tooltip,
  Typography
} from "@mui/material"
import InfoIcon from "@mui/icons-material/Info"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import narrativeDashboardStyles from "assets/jss/material-dashboard-pro-react/views/narrativeDashboardStyles"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import FormControl from "@mui/material/FormControl"

import Helpers from "../../tools/Helpers"
import Enums from "../../tools/Enums.js"
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import CodeEditor from "components/CodeEditor"
import WordCounter from "components/WordCounter/WordCounter"
import classnames from "classnames"
import { ENTERTAINMENT_VERTICAL_ID } from "constants"

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const CheckedIcon = classes => (
  <CheckBoxIcon fontSize="small" className={classes} />
)

const useStyles = makeStyles(theme => ({
  ...narrativeDashboardStyles,
  cardBody: {
    fontSize: "16px"
  },
  cardEmbedded: {
    margin: 0,
    boxShadow: "none"
  },
  embedded: {
    marginTop: "5px"
  },
  nakedInput: {
    textDecoration: "underline!important",
    fontSize: "16px!important",
    "&:before,&:after": {
      display: "none!important"
    },
    "&:hover": {
      "& > svg": {
        visibility: "visible"
      },
      backgroundColor: "#ffffff"
    }
  },
  sectionHeader: {
    padding: "0"
  },
  nested: {
    border: "1px solid #999999"
  },
  nestedGrey: {
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: "5px",
    backgroundColor: "#efefef"
  },
  embeddedNestedGrey: {
    borderRadius: "2px",
    backgroundColor: "#efefef"
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  noWhiteSpace: {
    whiteSpace: "nowrap"
  },
  chip: {
    fontSize: 12
  },
  assocDelGrid: {
    marginBottom: "9px",
    display: "flex",
    justifyContent: "flex-end"
  },
  deliverableAutocomplete: {
    marginBottom: "9px"
  },
  deliverablesSectionHeading: {
    fontWeight: "400"
  },
  deliverableButton: {
    display: "flex",
    marginRight: "15px",
    paddingBottom: "15px"
  },
  buttonStyle: {
    fontSize: "12px",
    padding: "7px 14px",
    minWidth: "110px",
    marginRight: "4px"
  },
  tabs: {
    borderBottom: "none!important",
    backgroundColor: "#fff",
    "& > .nav-item .nav-link": {
      cursor: "pointer"
    },
    "& > .nav-item .nav-link:hover": {
      backgroundColor: "rgba(0,0,0, 0.2)"
    },
    "& > .nav-item .nav-link.active": {
      backgroundColor: "#efefef",
      borderColor: "#dee2e6 #dee2e6 transparent"
    },
    "& .nav-tabs > .nav-tabs .nav-link.active": {
      backgroundColor: "#fff",
      borderColor: "#dee2e6 #dee2e6 #fff"
    },
    "& > .nav-item .nav-link.activeStarter": {
      backgroundColor: "#ddd",
      borderColor: "#dee2e6 #dee2e6 transparent"
    },
    "& .nav-tabs > .nav-tabs .nav-link.activeStarter": {
      backgroundColor: "#eee",
      borderColor: "#dee2e6 #dee2e6 #fff"
    }
  },
  label: {
    fontSize: "16px"
  }
}))

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    feeds: store.organizationStore.feeds,
    sortedLeagues: store.sportsStore.sortedLeaguesByName,
    getLeagues: store.sportsStore.retrieveLeagues,
    leagueDetailsById: store.sportsStore.leagueDetailsById,
    retrieveLeagueDetails: store.sportsStore.retrieveLeagueDetails,
    modelTypes: store.narrativeStore.modelTypes,
    narrative: store.narrativeStore.narrative,
    isLoading: store.organizationStore.loading,
    narratives: store.narrativeStore.narratives,
    setNarrative: store.narrativeStore.setNarrative,
    organizations: store.organizationStore.organizations,
    articleTypesCollection: store.narrativeStore.articleTypes,
    setIsDirty: store.narrativeStore.setIsDirty,
    postNarrative: store.narrativeStore.postNarrative,
    getModelTypes: store.narrativeStore.getModelTypes,
    getNarratives: store.narrativeStore.getNarratives,
    getArticleTypes: store.narrativeStore.getArticleTypes,
    getContentTypes: store.narrativeStore.getContentTypes,
    getOrganizations: store.organizationStore.getOrganizations,
    getFeeds: store.organizationStore.getFeeds,
    getDatasources: store.narrativeStore.getDatasources,
    datasources: store.narrativeStore.datasources,
    narrativeStatuses: store.narrativeStore.narrativeStatuses,
    queries: store.queryBuilderStore.queries,
    getQueries: store.queryBuilderStore.getQueries,
    isDirty: store.narrativeStore.isDirty,
    contractDeliverables: store.organizationStore.contractDeliverables,
    getContractDeliverablesForSchedule:
      store.organizationStore.getContractDeliverablesForSchedule,
    setContractDeliverablesForSchedule:
      store.organizationStore.contractDeliverables,
    postContractDeliverable: store.organizationStore.postContractDeliverable,
    verticals: store.narrativeStore.verticals,
    getVerticals: store.narrativeStore.getVerticals,
    outputAttributes: store.narrativeStore.outputAttributes,
    getOutputAttributes: store.narrativeStore.getOutputAttributes,
    starters: store.narrativeStore.starters,
    getStarters: store.narrativeStore.getStarters,
    evaluateSentence: store.narrativeStore.evaluateSentence,
    starterPreview: store.narrativeStore.starterPreview,
    isLoadingNarrativeStore: store.narrativeStore.loading
  }))
}

const NarrativeDetails = props => {
  const classes = useStyles()
  const { embedded } = props
  const {
    sortedLeagues,
    getLeagues,
    leagueDetailsById,
    retrieveLeagueDetails,
    modelTypes,
    narrative,
    isLoading,
    organizations,
    feeds,
    setNarrative,
    getModelTypes,
    getArticleTypes,
    getContentTypes,
    getOrganizations,
    getFeeds,
    setIsDirty,
    getDatasources,
    datasources,
    narrativeStatuses,
    queries,
    getQueries,
    isDirty,
    contractDeliverables,
    getContractDeliverablesForSchedule,
    postContractDeliverable,
    verticals,
    getVerticals,
    outputAttributes,
    getOutputAttributes,
    starters,
    getStarters,
    evaluateSentence,
    starterPreview,
    isLoadingNarrativeStore
  } = useStoreData()

  const { organization, leagueId } = narrative

  const [keywordsOpen, setKeywordsOpen] = useState(false)
  const [detailsOpen, setDetailsOpen] = useState(true)
  const [dataOpen, setDataOpen] = useState(false)
  const [associateDeliverableOpen, setAssociateDeliverableOpen] =
    useState(false)

  const [outputStarterOpen, setOutputStarterOpen] = useState(false)
  const [buildingPreview, setBuildingPreview] = useState(false)
  const [showPreview, setShowPreview] = useState(false)
  const [isChanging, setIsChanging] = useState("")
  const [originalNarrative, setOriginalNarrative] = useState({})
  const [isLoadingLeagueData, setIsLoadingLeagueData] = useState(false)
  const [isOutputAttributesError, setIsOutputAttributesError] = useState(false)

  const sportsData = useMemo(
    () => datasources?.filter(ds => ds.get("hasSports")),
    [datasources]
  )

  const bettingData = useMemo(
    () => datasources?.filter(ds => ds.get("hasBetting")),
    [datasources]
  )

  const imageData = useMemo(
    () => datasources?.filter(ds => ds.get("hasImages")),
    [datasources]
  )

  const leagueData = leagueDetailsById[narrative?.leagueId] || {}
  const { conferences, divisions, teams } = leagueData

  const conferenceList =
    conferences?.sort((a, b) => (a.name > b.name ? 1 : -1)) || []

  const divisionList =
    divisions?.sort((a, b) => (a.name > b.name ? 1 : -1)) || []

  const teamList =
    teams
      ?.filter(team => {
        if (narrative.conferenceIds?.length > 0) {
          return narrative.conferenceIds.some(id => id === team.conference_Id)
        } else if (narrative.divisionIds?.length > 0) {
          return narrative.divisionIds.some(id => id === team.division_Id)
        } else {
          return team
        }
      })
      .sort((a, b) => (a.displayName > b.displayName ? 1 : -1)) || []

  const sortedOrganizations = useMemo(
    () => organizations && organizations?.toJS().sort(Helpers.sortByName),
    [organizations]
  )

  const sortedFeeds = useMemo(
    () => (feeds && feeds?.toJS().types.sort(Helpers.sortByName)) || [],
    [feeds]
  )

  const sortedNarrativeStatuses = useMemo(
    () => narrativeStatuses?.sort(Helpers.sortByName) || [],
    [narrativeStatuses]
  )

  let sortedDeliverables =
    contractDeliverables?.toJS()?.sort(Helpers.sortByName) || []

  const leagueOptions = [{ name: "Not Applicable", id: -1 }, ...sortedLeagues]
  const isNarrativeLoaded =
    !!narrative?.id &&
    (!!narrative?.name ||
      !!narrative?.organization?.id ||
      !!narrative?.leagueId)

  const handleCellChange = event => {
    const newNarrative = {
      ...narrative,
      [event.target.id]: event.target.value
    }
    if (event.target.id === "name") {
      if (newNarrative.familyName !== newNarrative.name) {
        newNarrative.familyName = newNarrative.name
      }
    }

    if (narrative[event.target.id] !== event.target.value) {
      setIsChanging(event.target.id)
    }
    setNarrative(newNarrative)
  }

  const contentChangeDirty = event => {
    if (isChanging === event.target.id) {
      setIsDirty(true)
      setIsChanging("")
    }
  }

  const handleClickChange = event => {
    const newNarrative = {
      ...narrative,
      [event.target.id]: event.target.checked
    }
    setIsDirty(true)
    setNarrative(newNarrative)
  }

  const handleAutoCompleteMultiChange = (event, newValue, reason, name) => {
    if (!event || typeof event === "string" || !newValue) {
      return
    }
    let newVals = []
    newValue.forEach(itm => newVals.push(itm.id))
    const newNarrative = {
      ...narrative,
      [name]: newVals
    }
    setNarrative(newNarrative)
  }

  const parseAndBuildPreview = tmpTokens => {
    if (tmpTokens) {
      setShowPreview(false)
      buildPreview(tmpTokens)
    }
  }
  const buildPreview = tmpTokens => {
    const payload = {
      contentId: narrative.queryId, //should be queryId
      contentType: "query",
      dataModelText: null,
      modelType: narrative.modelType,
      modelTypeId: narrative.modelTypeId,
      narrativeId: narrative.id, //output ID (not Library ID)
      templateString: tmpTokens,
      itemIndex: null,
      useCache: true
    }
    evaluateSentence(payload)
    setBuildingPreview(true)
  }

  const handleDeliverableChange = (event, selectedDeliverables) => {
    if (!event || typeof event === "string" || !selectedDeliverables) {
      return
    }
    // If there are selected deliverables
    if (selectedDeliverables.length > 0) {
      //Loop through all possible blocks
      sortedDeliverables.forEach(deliverable => {
        // If this contract deliverable is associated with this narrative
        if (deliverable.narrativeId === narrative.id) {
          //check to see if it's in the selected deliverables list
          const matchingDeliverable = selectedDeliverables.find(
            sd => sd.id === deliverable.id
          )
          if (!matchingDeliverable) {
            const updatedDeliverable = {
              ...deliverable,
              narrativeId: null
            }
            postContractDeliverable(updatedDeliverable)
          }
        } else {
          // This one isn't matched with the narrative
          // see if we need to update it
          const matchingDeliverable = selectedDeliverables.find(
            sd => sd.id === deliverable.id
          )
          if (matchingDeliverable) {
            const updatedDeliverable = {
              ...deliverable,
              narrativeId: narrative.id
            }
            postContractDeliverable(updatedDeliverable)
          }
        }
      })
    } else {
      // If nothing is selected, update any that say they are
      sortedDeliverables.forEach(deliverable => {
        if (deliverable.narrativeId === narrative.id) {
          const updatedDeliverable = {
            ...deliverable,
            narrativeId: null
          }
          postContractDeliverable(updatedDeliverable)
        }
      })
    }
  }

  // on first render
  useEffect(() => {
    getModelTypes()
    getLeagues()
    getOrganizations()
    getDatasources()
    getArticleTypes()
    getContentTypes()
    getQueries()
    getVerticals()
    getOutputAttributes()
  }, [])

  useEffect(() => {
    if (narrative?.organization?.id) {
      getFeeds(narrative?.organization?.id)
    }
  }, [organization])

  useEffect(() => {
    if (buildingPreview && !isLoadingNarrativeStore) {
      setBuildingPreview(false)
      setShowPreview(true)
    }
  }, [isLoadingNarrativeStore])

  useEffect(() => {
    if (isNarrativeLoaded) {
      setOriginalNarrative(narrative)
      getContractDeliverablesForSchedule(
        narrative?.organization?.id,
        narrative?.leagueId
      )
      if (narrative.libraryNarrative_Ids) {
        const libNarrIds = narrative.libraryNarrative_Ids?.split(",") || []
        if (libNarrIds[0]) {
          getStarters(libNarrIds[0])
        }
      }
    }
  }, [isNarrativeLoaded])

  // Get new league info if a new league is selected.
  useEffect(() => {
    const fetchLeague = async leagueId => {
      try {
        await retrieveLeagueDetails(leagueId)
      } catch (err) {
        console.error("getLeague Error", err)
      } finally {
        setIsLoadingLeagueData(false)
      }
    }

    if (narrative.leagueId !== null && narrative.leagueId !== -1) {
      setIsLoadingLeagueData(true)
      fetchLeague(narrative.leagueId)
    }
  }, [narrative.leagueId])

  useEffect(() => {
    if (isDirty) {
      const newNarrative = {
        ...narrative,
        teamIds: []
      }
      setNarrative(newNarrative)
    }
  }, [narrative.conferenceIds, narrative.divisionIds])

  useEffect(() => {
    if (isDirty) {
      const newNarrative = {
        ...narrative,
        conferenceIds: [],
        divisionIds: []
      }
      setNarrative(newNarrative)
    }
  }, [leagueId])

  useEffect(() => {
    getContractDeliverablesForSchedule(narrative?.organization?.id, leagueId)
  }, [leagueId])

  const checkedIcon = (
    <CheckBoxIcon fontSize="small" className={classes.checkRegion} />
  )

  const getStarterContent = () => {
    let myStarterContent = starters || []
    const libraryNarrativeIds = narrative.libraryNarrative_Ids?.split(",") || []

    if (myStarterContent.length !== libraryNarrativeIds.length) {
      //We have libraries with no starters, so they came back empty from the DB
      let missingLibraries = libraryNarrativeIds
      myStarterContent.forEach(itm => {
        let position = libraryNarrativeIds.includes(itm.id)
        if (position > -1) {
          missingLibraries.splice(position, 1)
        }
      })
      missingLibraries.forEach(itm => {
        myStarterContent.push({ id: itm, name: "", starter: "" })
      })
    }
    return myStarterContent
  }

  const starterContent = getStarterContent()

  const noTestContent = !starterContent[0]?.starter

  const preview =
    showPreview && typeof starterPreview === "string"
      ? starterPreview.replace(/\n/g, "<br/>") || ""
      : ""

  return (
    <Card className={classes.cardEmbedded}>
      {isLoading && (
        <CircularProgress
          style={{
            position: "relative",
            left: "45%",
            top: "200px",
            zIndex: "101"
          }}
        />
      )}
      <CardBody className={classes.cardBody}>
        {/* Start Details Section */}
        <CardBody
          className={embedded ? classes.embeddedNestedGrey : classes.nestedGrey}
        >
          <CardActions className={classes.sectionHeader} disableSpacing>
            <strong>Details</strong>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: detailsOpen
              })}
              onClick={() => setDetailsOpen(!detailsOpen)}
              aria-expanded={detailsOpen}
              aria-label="show more"
              size="large"
            >
              <ExpandMore />
            </IconButton>
          </CardActions>
          <Collapse in={detailsOpen} unmountOnExit>
            <GridContainer spacing={3}>
              <GridItem xs={12} sm={4}>
                <FormLabel className={classes.label}>Name*:</FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8}>
                <FormControl
                  variant="standard"
                  required={true}
                  fullWidth={true}
                >
                  <TextField
                    fullWidth
                    className={classes.inputInput}
                    id="name"
                    value={narrative.name}
                    onChange={handleCellChange}
                    onBlur={contentChangeDirty}
                    required={true}
                    error={!narrative?.name && isDirty}
                    helperText={
                      !narrative?.name && isDirty ? "Name is Required" : " "
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={4}>
                <FormLabel className={classes.label}>Organization*:</FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8}>
                {sortedOrganizations?.length > 0 &&
                  (narrative?.organization?.id || isDirty) && (
                    <Autocomplete
                      id="organization"
                      name="organization"
                      //disableClearable={true}
                      required={true}
                      onChange={(event, newValue, reason) => {
                        let newOrganization = {
                          id: newValue?.id
                        }
                        if (reason === "clear") {
                          newOrganization = null
                        }
                        const newNarrative = {
                          ...narrative,
                          organization: newOrganization
                        }
                        setIsDirty(true)
                        setNarrative(newNarrative)
                        getFeeds(newValue?.id)
                      }}
                      value={sortedOrganizations?.find(
                        at => at.id === narrative?.organization?.id
                      )}
                      options={
                        sortedOrganizations?.filter(
                          f =>
                            f.isActive === true ||
                            originalNarrative?.organization?.id === f.id //or this Narrative is already assigned
                        ) || []
                      }
                      getOptionLabel={option => option?.name || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value?.id
                      }
                      renderOption={(props, option) =>
                        option.isActive ? (
                          <span {...props}>
                            {option.name}
                            {!option?.parentId && " (Parent)"}
                          </span>
                        ) : (
                          <span
                            {...props}
                            style={{
                              textDecoration: "line-through",
                              textDecorationColor: "#144E68"
                            }}
                          >
                            {option.name} ({option.id})
                          </span>
                        )
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          //this renders updated org in field to line-through
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              textDecoration: sortedOrganizations?.find(
                                f => f.id === narrative?.organization?.id
                              )?.isActive
                                ? "none"
                                : "line-through"
                            }
                          }}
                          error={!narrative?.organization && isDirty} // Display error when no checkboxes are selected
                          helperText={
                            !narrative?.organization && isDirty
                              ? "Organization is Required"
                              : ""
                          }
                        />
                      )}
                    />
                  )}
              </GridItem>
              {narrative?.verticalId !== ENTERTAINMENT_VERTICAL_ID && (
                <>
                  <GridItem xs={12} sm={4}>
                    <FormLabel className={classes.label}>League*:</FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={8}>
                    {isNarrativeLoaded && sortedLeagues?.length > 0 && (
                      <Autocomplete
                        id="leagueId"
                        name="leagueId"
                        //disableClearable={true}
                        required={true}
                        onChange={(event, newValue, reason) => {
                          let leagueId = newValue?.id
                          if (reason === "clear") {
                            leagueId = -1
                          }
                          const newNarrative = {
                            ...narrative,
                            leagueId
                          }
                          setIsDirty(true)
                          setNarrative(newNarrative)
                        }}
                        value={leagueOptions?.find(
                          at => at.id === (narrative?.leagueId || -1)
                        )}
                        options={leagueOptions || []}
                        getOptionDisabled={option => option.isArchived === true}
                        getOptionLabel={option => (option ? option?.name : "")}
                        isOptionEqualToValue={(option, value) =>
                          option && value ? option.id === value.id : false
                        }
                        renderInput={params => <TextField {...params} />}
                      />
                    )}
                  </GridItem>
                </>
              )}
              {isNarrativeLoaded &&
                narrative.leagueId &&
                narrative.leagueId !== -1 && (
                  <>
                    <GridItem xs={12} sm={4}>
                      <FormLabel
                        className={classes.label}
                        style={{ display: "inline-flex" }}
                      >
                        Conference:{" "}
                        <Tooltip
                          placement="right-end"
                          title="A user can only select either League Division or League Conference due to differing definitions by sport."
                        >
                          <InfoIcon color="primary" fontSize="small" />
                        </Tooltip>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={8}>
                      <Autocomplete
                        multiple
                        id="conferenceIds"
                        options={conferenceList || []}
                        value={conferenceList?.filter(i =>
                          narrative.conferenceIds?.some(j => j === i.id)
                        )}
                        onChange={(event, value, reason) => {
                          setIsDirty(true)
                          handleAutoCompleteMultiChange(
                            event,
                            value,
                            reason,
                            "conferenceIds"
                          )
                        }}
                        getOptionLabel={option => option.name || ""}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value?.id
                        }
                        renderInput={params => <TextField {...params} />}
                        limitTags={3}
                        ChipProps={{
                          classes: { root: classes.chip },
                          size: "small"
                        }}
                        disabled={
                          !narrative.leagueId ||
                          narrative.divisionIds?.length > 0 ||
                          isLoadingLeagueData
                        }
                      />
                    </GridItem>{" "}
                  </>
                )}
              {isNarrativeLoaded &&
                narrative.leagueId &&
                narrative.leagueId !== -1 && (
                  <>
                    <GridItem xs={12} sm={4}>
                      <FormLabel className={classes.label}>Division:</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={8}>
                      <Autocomplete
                        multiple
                        id="divisionIds"
                        options={divisionList || []}
                        value={divisionList?.filter(i =>
                          narrative.divisionIds?.some(j => j === i.id)
                        )}
                        onChange={(event, value, reason) => {
                          setIsDirty(true)
                          handleAutoCompleteMultiChange(
                            event,
                            value,
                            reason,
                            "divisionIds"
                          )
                        }}
                        getOptionLabel={option => option.name || ""}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value?.id
                        }
                        renderInput={params => <TextField {...params} />}
                        limitTags={3}
                        ChipProps={{
                          classes: { root: classes.chip },
                          size: "small"
                        }}
                        disabled={
                          !narrative.leagueId ||
                          narrative.conferenceIds?.length > 0 ||
                          isLoadingLeagueData
                        }
                      />
                    </GridItem>
                  </>
                )}
              {isNarrativeLoaded &&
                narrative.leagueId &&
                narrative.leagueId !== -1 && (
                  <>
                    <GridItem xs={12} sm={4}>
                      <FormLabel className={classes.label}>Team:</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={8}>
                      <Autocomplete
                        multiple
                        id="teamIds"
                        options={teamList || []}
                        value={teamList?.filter(i =>
                          narrative.teamIds?.some(j => j === i.id)
                        )}
                        onChange={(event, value, reason) => {
                          setIsDirty(true)
                          handleAutoCompleteMultiChange(
                            event,
                            value,
                            reason,
                            "teamIds"
                          )
                        }}
                        getOptionLabel={option => option.displayName || ""}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value?.id
                        }
                        renderInput={params => <TextField {...params} />}
                        limitTags={3}
                        ChipProps={{
                          classes: { root: classes.chip },
                          size: "small"
                        }}
                        disabled={!narrative.leagueId || isLoadingLeagueData}
                      />
                    </GridItem>
                  </>
                )}

              <GridItem xs={12} sm={4}>
                <FormLabel className={classes.label}>Vertical:</FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8}>
                {isNarrativeLoaded && (
                  <Autocomplete
                    id="verticalId"
                    options={[{ verticalName: "", id: null }].concat(
                      verticals?.toJS().sort(function (a, b) {
                        return (
                          a.verticalName &&
                          a.verticalName.localeCompare(b.verticalName)
                        )
                      })
                    )}
                    value={[{ verticalName: "", id: null }]
                      .concat(verticals?.toJS())
                      .find(vertical => vertical.id === narrative.verticalId)}
                    getOptionLabel={option => option.verticalName}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={params => <TextField {...params} />}
                    onChange={(event, newValue, reason) => {
                      let verticalId = newValue?.id
                      if (reason === "clear") {
                        verticalId = null
                      }
                      const newNarrative = {
                        ...narrative,
                        verticalId
                      }
                      setIsDirty(true)
                      setNarrative(newNarrative)
                    }}
                  />
                )}
              </GridItem>
              <GridItem xs={12} sm={4}>
                <FormLabel className={classes.label}>
                  Output Attributes*:
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8}>
                {isNarrativeLoaded && (
                  <Autocomplete
                    multiple={true}
                    limitTags={3}
                    required={true}
                    aria-label="NarrativeOutput OutputAttributes DropDown"
                    id="outputAttributes"
                    name="outputAttributes"
                    options={
                      outputAttributes?.toJS().sort(function (a, b) {
                        return (
                          a.attribute && a.attribute.localeCompare(b.attribute)
                        )
                      }) || []
                    }
                    onChange={(event, newValue, reason) => {
                      let outputAttributes = []
                      newValue?.forEach(itm => {
                        outputAttributes.push({
                          narrativeId: narrative.id,
                          outputAttributeId: itm.id
                        })
                      })
                      if (reason === "clear") {
                        outputAttributes = []
                      }
                      const newNarrative = {
                        ...narrative,
                        narrativeOutputAttribute: outputAttributes
                      }
                      setIsDirty(true)
                      setNarrative(newNarrative)
                      setIsOutputAttributesError(outputAttributes.length === 0)
                    }}
                    value={outputAttributes?.toJS().filter(oAO => {
                      let match = false
                      narrative.narrativeOutputAttribute
                        ?.toJS()
                        .forEach(itm => {
                          if (itm.outputAttributeId === oAO.id) {
                            match = true
                          }
                        })
                      return match
                    })}
                    getOptionDisabled={option => option.isArchived === true}
                    getOptionLabel={option => option.attribute || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value?.id
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        error={isOutputAttributesError} // Display error when no checkboxes are selected
                        helperText={
                          isOutputAttributesError
                            ? "Output Attributes are required"
                            : ""
                        }
                      />
                    )}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          checked={selected}
                        />
                        {option.attribute}
                      </li>
                    )}
                  />
                )}
              </GridItem>
              <GridItem xs={12} sm={6}>
                <FormLabel className={classes.label}>
                  Build From Outline:
                </FormLabel>
              </GridItem>
              <GridItem
                xs={12}
                sm={6}
                title={
                  narrative.isLibrary
                    ? "“Build from Outline” disabled when in a Library."
                    : "Build from Outline"
                }
              >
                <Checkbox
                  id="buildFromOutline"
                  className={classes.nakedInput}
                  disabled={narrative.isLibrary}
                  checked={narrative.buildFromOutline || false}
                  onChange={handleClickChange}
                />
              </GridItem>
              <GridItem xs={12} sm={4}>
                <FormLabel className={classes.label}>
                  Library Narrative(s):
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8}>
                <InputBase
                  fullWidth
                  className={classes.nakedInput}
                  id="libraryNarrative_Ids"
                  value={narrative.libraryNarrative_Ids}
                  onChange={handleCellChange}
                  onBlur={contentChangeDirty}
                />
              </GridItem>
              <GridItem xs={12} sm={4}>
                <FormLabel className={classes.label}>
                  Production Feed:
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8}>
                {isNarrativeLoaded && sortedFeeds && sortedFeeds.length > 0 && (
                  <Autocomplete
                    id="productionFeed_Id"
                    name="productionFeed_Id"
                    onChange={(event, newValue, reason) => {
                      let productionFeed_Id = newValue?.id
                      if (reason === "clear") {
                        productionFeed_Id = null
                      }
                      const newNarrative = {
                        ...narrative,
                        productionFeed_Id
                      }
                      setIsDirty(true)
                      setNarrative(newNarrative)
                    }}
                    value={sortedFeeds?.find(
                      f => f.id === narrative?.productionFeed_Id
                    )}
                    options={
                      sortedFeeds?.filter(
                        f =>
                          f.isArchived === false ||
                          originalNarrative?.productionFeed_Id === f.id // or this Narrative is already assigned to this now 'archived' feed
                      ) || []
                    }
                    getOptionLabel={option => option.name || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value?.id
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        style={
                          sortedFeeds?.find(
                            f => f.id === narrative?.productionFeed_Id
                          )?.isArchived
                            ? { textDecoration: "line-through" }
                            : { textDecoration: "none" }
                        }
                      />
                    )}
                    renderOption={(props, option) =>
                      option.isArchived ? (
                        <span
                          {...props}
                          style={{ textDecoration: "line-through" }}
                        >
                          {option.name} ({option.id})
                        </span>
                      ) : (
                        <span {...props}>{option.name}</span>
                      )
                    }
                  />
                )}
              </GridItem>
              <GridItem xs={12} sm={4}>
                <FormLabel className={classes.label}>Status*:</FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8}>
                {isNarrativeLoaded && sortedNarrativeStatuses?.length > 0 && (
                  <Autocomplete
                    id="narrativeStatusId"
                    name="narrativeStatusId"
                    disableClearable={true}
                    onChange={(event, newValue, reason) => {
                      let newNarrativeStatusId = newValue?.id
                      if (reason === "clear") {
                        newNarrativeStatusId = null
                      }
                      const newNarrative = {
                        ...narrative,
                        narrativeStatusId: newNarrativeStatusId
                      }
                      setIsDirty(true)
                      setNarrative(newNarrative)
                    }}
                    value={sortedNarrativeStatuses?.find(
                      f => f.id === narrative?.narrativeStatusId
                    )}
                    options={sortedNarrativeStatuses || []}
                    getOptionLabel={option => option.name || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value?.id
                    }
                    renderInput={params => <TextField {...params} />}
                  />
                )}
              </GridItem>
              <GridItem xs={12} sm={12} style={{ display: "inline-flex" }} />
            </GridContainer>
          </Collapse>
        </CardBody>
        {/* Start SEO Section */}
        <CardBody
          className={embedded ? classes.embeddedNestedGrey : classes.nestedGrey}
        >
          <CardActions className={classes.sectionHeader} disableSpacing>
            <strong>SEO</strong>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: keywordsOpen
              })}
              onClick={() => setKeywordsOpen(!keywordsOpen)}
              aria-expanded={keywordsOpen}
              aria-label="show more"
              size="large"
            >
              <ExpandMore />
            </IconButton>
          </CardActions>
          <Collapse in={keywordsOpen} unmountOnExit>
            <GridContainer spacing={3}>
              <GridItem xs={12} sm={4}>
                <FormLabel className={classes.label}>Keyword 1:</FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8}>
                <InputBase
                  className={classes.nakedInput}
                  type="text"
                  label="Keyword 1"
                  id="narrativeKeyword1"
                  value={narrative.narrativeKeyword1}
                  onChange={handleCellChange}
                  onBlur={contentChangeDirty}
                />
              </GridItem>
              <GridItem xs={12} sm={4}>
                <FormLabel className={classes.label}>Keyword 2:</FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8}>
                <InputBase
                  className={classes.nakedInput}
                  type="text"
                  label="Keyword 2"
                  id="narrativeKeyword2"
                  value={narrative.narrativeKeyword2}
                  onChange={handleCellChange}
                  onBlur={contentChangeDirty}
                />
              </GridItem>
              <GridItem xs={12} sm={4}>
                <FormLabel className={classes.label}>Keyword 3:</FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8}>
                <InputBase
                  className={classes.nakedInput}
                  type="text"
                  label="Keyword3"
                  id="narrativeKeyword3"
                  value={narrative.narrativeKeyword3}
                  onChange={handleCellChange}
                  onBlur={contentChangeDirty}
                />
              </GridItem>
              <GridItem xs={12} sm={4}>
                <FormLabel className={classes.label}>Keyword 4:</FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8}>
                <InputBase
                  className={classes.nakedInput}
                  type="text"
                  label="Keyword 4"
                  id="narrativeKeyword4"
                  value={narrative.narrativeKeyword4}
                  onChange={handleCellChange}
                  onBlur={contentChangeDirty}
                />
              </GridItem>
              <GridItem xs={12} sm={4}>
                <Tooltip
                  title="Used as the name of graphics in the feed entry"
                  placement="bottom"
                >
                  <FormLabel className={classes.label}>
                    Graphic Filename:
                  </FormLabel>
                </Tooltip>
              </GridItem>
              <GridItem xs={12} sm={8}>
                <InputBase
                  className={classes.nakedInput}
                  type="text"
                  label="Graphic Filename"
                  id="recommendedGraphicName"
                  value={narrative.recommendedGraphicName}
                  onChange={handleCellChange}
                  onBlur={contentChangeDirty}
                />
              </GridItem>
              <GridItem xs={12} sm={12} style={{ display: "inline-flex" }} />
            </GridContainer>
          </Collapse>
        </CardBody>
        {/* Start Data Section */}
        <CardBody
          className={embedded ? classes.embeddedNestedGrey : classes.nestedGrey}
        >
          <CardActions className={classes.sectionHeader} disableSpacing>
            <strong>Data</strong>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: dataOpen
              })}
              onClick={() => setDataOpen(!dataOpen)}
              aria-expanded={dataOpen}
              aria-label="show more"
              size="large"
            >
              <ExpandMore />
            </IconButton>
          </CardActions>
          <Collapse in={dataOpen} unmountOnExit>
            <GridContainer spacing={3}>
              <GridItem xs={12} sm={4}>
                <FormLabel className={classes.label}>Model:</FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8}>
                {modelTypes?.toJS().length > 0 && (
                  <Autocomplete
                    id="modelTypeId"
                    name="modelTypeId"
                    onChange={(event, newValue, reason) => {
                      let modelTypeId = newValue?.id
                      if (reason === "clear") {
                        modelTypeId = null
                      }
                      const newNarrative = {
                        ...narrative,
                        modelTypeId
                      }
                      setIsDirty(true)
                      setNarrative(newNarrative)
                    }}
                    value={modelTypes
                      ?.toJS()
                      .find(mt => mt.id === narrative?.modelTypeId)}
                    options={modelTypes?.toJS().sort(Helpers.sortByName)}
                    getOptionDisabled={option => option.isArchived === true}
                    getOptionLabel={option => option.name || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value?.id
                    }
                    renderInput={params => <TextField {...params} />}
                  />
                )}
              </GridItem>

              {narrative.modelTypeId ===
                Enums.NarrativeModelType.QueryBuilder && (
                <>
                  <GridItem xs={12} sm={4}>
                    <FormLabel className={classes.label}>Query:</FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={8}>
                    <Autocomplete
                      id="queryId"
                      name="queryId"
                      onChange={(event, newValue, reason) => {
                        let queryId = newValue?.id
                        if (reason === "clear") {
                          queryId = null
                        }
                        const newNarrative = {
                          ...narrative,
                          queryId
                        }
                        setIsDirty(true)
                        setNarrative(newNarrative)
                      }}
                      value={
                        queries
                          ?.toJS()
                          .find(f => f.id === narrative?.queryId) ?? null
                      }
                      options={queries?.toJS().sort(Helpers.sortByName) || []}
                      getOptionDisabled={option => option.isArchived === true}
                      getOptionLabel={option =>
                        option ? `${option.id} - ${option.name}` : ""
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.id === value?.id
                      }
                      renderInput={params => <TextField {...params} />}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <FormLabel className={classes.label}>
                      Refresh QA Query:
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={8}>
                    <Autocomplete
                      id="refreshQueryId"
                      name="refreshQueryId"
                      onChange={(event, newValue, reason) => {
                        let refreshQueryId = newValue?.id
                        if (reason === "clear") {
                          refreshQueryId = null
                        }
                        const newNarrative = {
                          ...narrative,
                          refreshQueryId
                        }
                        setIsDirty(true)
                        setNarrative(newNarrative)
                      }}
                      value={
                        queries
                          ?.toJS()
                          .find(f => f.id === narrative?.refreshQueryId) ?? null
                      }
                      options={queries?.toJS().sort(Helpers.sortByName) || []}
                      getOptionDisabled={option => option.isArchived === true}
                      getOptionLabel={option =>
                        option ? `${option.id} - ${option.name}` : ""
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.id === value?.id
                      }
                      renderInput={params => <TextField {...params} />}
                    />
                  </GridItem>
                </>
              )}
              <GridItem xs={12} sm={4}>
                <FormLabel className={classes.label}>
                  Sports Datasource:
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8}>
                <Autocomplete
                  id="sportsDatasource1Id"
                  name="sportsDatasource1Id"
                  onChange={(event, newValue, reason) => {
                    let sportsDatasource1Id = newValue?.id
                    if (reason === "clear") {
                      sportsDatasource1Id = null
                    }
                    const newNarrative = {
                      ...narrative,
                      sportsDatasource1Id
                    }
                    setIsDirty(true)
                    setNarrative(newNarrative)
                  }}
                  value={sportsData
                    ?.toJS()
                    .find(x => x.id === narrative.sportsDatasource1Id)}
                  options={sportsData?.toJS().sort(Helpers.sortByName) || []}
                  getOptionDisabled={option => option.isArchived === true}
                  getOptionLabel={option => option.name || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value?.id
                  }
                  renderInput={params => <TextField {...params} />}
                />
              </GridItem>
              <GridItem xs={12} sm={4}>
                <FormLabel className={classes.label}>
                  Betting Datasource:
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8}>
                <Autocomplete
                  id="bettingDatasource1Id"
                  name="bettingDatasource1Id"
                  onChange={(event, newValue, reason) => {
                    let bettingDatasource1Id = newValue?.id
                    if (reason === "clear") {
                      bettingDatasource1Id = null
                    }
                    const newNarrative = {
                      ...narrative,
                      bettingDatasource1Id
                    }
                    setIsDirty(true)
                    setNarrative(newNarrative)
                  }}
                  value={bettingData
                    ?.toJS()
                    .find(x => x.id === narrative.bettingDatasource1Id)}
                  options={bettingData?.toJS().sort(Helpers.sortByName) || []}
                  getOptionDisabled={option => option.isArchived === true}
                  getOptionLabel={option => option.name || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value?.id
                  }
                  renderInput={params => <TextField {...params} />}
                />
              </GridItem>
              <GridItem xs={12} sm={4}>
                <FormLabel className={classes.label}>
                  Image Datasource:
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8}>
                <Autocomplete
                  id="imageSource1Id"
                  name="imageSource1Id"
                  onChange={(event, newValue, reason) => {
                    let imageSource1Id = newValue?.id
                    if (reason === "clear") {
                      imageSource1Id = null
                    }
                    const newNarrative = {
                      ...narrative,
                      imageSource1Id
                    }
                    setIsDirty(true)
                    setNarrative(newNarrative)
                  }}
                  value={imageData
                    ?.toJS()
                    .find(x => x.id === narrative.imageSource1Id)}
                  options={imageData?.toJS().sort(Helpers.sortByName) || []}
                  getOptionDisabled={option => option.isArchived === true}
                  getOptionLabel={option => option.name || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value?.id
                  }
                  renderInput={params => <TextField {...params} />}
                />
              </GridItem>
              <GridItem xs={12} sm={12} style={{ display: "inline-flex" }} />
            </GridContainer>
          </Collapse>
        </CardBody>
        <CardBody
          className={embedded ? classes.embeddedNestedGrey : classes.nestedGrey}
        >
          <CardActions className={classes.sectionHeader} disableSpacing>
            <Typography
              variant="body"
              className={classes.deliverablesSectionHeading}
            >
              Associate Deliverables
            </Typography>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: associateDeliverableOpen
              })}
              onClick={() =>
                setAssociateDeliverableOpen(!associateDeliverableOpen)
              }
              aria-expanded={associateDeliverableOpen}
              aria-label="show more"
              size="large"
            >
              <ExpandMore />
            </IconButton>
          </CardActions>
          <Collapse in={associateDeliverableOpen} unmountOnExit>
            <GridContainer className={classes.assocDelGrid} spacing={3}>
              <GridItem
                xs={12}
                sm={12}
                className={classes.deliverableAutocomplete}
              >
                <Autocomplete
                  id="contractDeliverables"
                  name="contractDeliverables"
                  fullWidth={true}
                  multiple={true}
                  limitTags={3}
                  disableCloseOnSelect
                  value={
                    sortedDeliverables &&
                    sortedDeliverables.filter(
                      sd => sd.narrativeId === narrative?.id
                    )
                  }
                  options={sortedDeliverables?.sort(Helpers.sortByName) || []}
                  //getOptionLabel={option => option.name}
                  getOptionLabel={option => `${option.id} - ${option.name}`}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value?.id
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={
                          <CheckedIcon classes={classes.checkRegion} />
                        }
                        checked={selected}
                      />
                      {`${option.id} - ${option.name}`}
                    </li>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Associate Deliverables"
                    />
                  )}
                  onChange={handleDeliverableChange}
                />
              </GridItem>
            </GridContainer>
          </Collapse>
        </CardBody>
        <CardBody
          className={embedded ? classes.embeddedNestedGrey : classes.nestedGrey}
        >
          <CardActions className={classes.sectionHeader} disableSpacing>
            <Typography
              variant="body"
              className={classes.deliverablesSectionHeading}
            >
              Output Starter
            </Typography>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: outputStarterOpen
              })}
              onClick={() => {
                setOutputStarterOpen(!outputStarterOpen)
                setShowPreview(false)
              }}
              aria-expanded={outputStarterOpen}
              aria-label="show more"
              size="large"
            >
              <ExpandMore />
            </IconButton>
          </CardActions>
          <Collapse in={outputStarterOpen} unmountOnExit>
            <GridContainer className={classes.assocDelGrid} spacing={3}>
              <GridItem
                xs={12}
                sm={12}
                className={classes.deliverableAutocomplete}
              >
                <div style={{ padding: "10px" }}>
                  <Card>
                    <CardHeader color="primary" icon>
                      <CardIcon color="primary">
                        <Widgets />
                      </CardIcon>
                      <h4 className={classes.cardIconTitle}>Output Starters</h4>
                    </CardHeader>
                    <CardBody>
                      <Nav tabs className={classes.tabs}>
                        {starterContent &&
                          starterContent.length > 0 &&
                          starterContent.map((itm, index) => (
                            <NavItem key={itm.id}>
                              <NavLink
                                className={classnames({
                                  activeStarter: true
                                })}
                                title={itm.name}
                                style={{ cursor: "pointer" }}
                              >
                                Lib ({itm.id})
                              </NavLink>
                            </NavItem>
                          ))}
                      </Nav>
                      <TabContent
                        activeTab={true}
                        style={{
                          marginLeft: "1px",
                          marginRight: "1px",
                          marginTop: "-1px"
                        }}
                      >
                        {starterContent &&
                          starterContent.length > 0 &&
                          starterContent.map((itm, index) => (
                            <div>
                              <TabPane tabId={`Starter${index + 1}`}>
                                {itm.starter && (
                                  <CodeEditor
                                    template={itm.starter}
                                    isNotLibrary={true}
                                    handleFocus={() => {}}
                                  />
                                )}
                                {!itm.starter && (
                                  <h4>
                                    This Narrative points to a library ({itm.id}
                                    ) which has no Starter
                                  </h4>
                                )}
                              </TabPane>
                              {itm.starter && (
                                <Box>
                                  <Grid container spacing={3}>
                                    <Grid
                                      container
                                      item
                                      xs={12}
                                      style={{
                                        display: "flex",
                                        justifyContent: "center"
                                      }}
                                    >
                                      {(!starterContent ||
                                        (starterContent &&
                                          starterContent.length === 0)) && (
                                        <h4>
                                          This Output is not associated with a
                                          library, so there is no Output Starter
                                          available.
                                        </h4>
                                      )}
                                      {starterContent &&
                                        starterContent.length > 0 && (
                                          <Button
                                            onClick={() =>
                                              parseAndBuildPreview(itm.starter)
                                            }
                                            disabled={noTestContent}
                                          >
                                            Test
                                          </Button>
                                        )}
                                    </Grid>
                                  </Grid>
                                </Box>
                              )}
                            </div>
                          ))}
                      </TabContent>
                      {(!starterContent ||
                        (starterContent && starterContent.length === 0)) && (
                        <h4>
                          This Output is not associated with a library, so there
                          is no Output Starter available.{" "}
                        </h4>
                      )}
                    </CardBody>
                  </Card>
                  {buildingPreview && isLoadingNarrativeStore && (
                    <LinearProgress />
                  )}
                  <Box>
                    <Grid container spacing={3} item xs={12}>
                      <Grid item>
                        <WordCounter title="Content" inputString={preview} />
                        <div
                          style={{
                            marginTop: "10px",
                            fontSize: "14px"
                          }}
                          dangerouslySetInnerHTML={{
                            __html: preview && preview.replace(/apxh:/g, "")
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              </GridItem>
            </GridContainer>
          </Collapse>
        </CardBody>
      </CardBody>
    </Card>
  )
}

NarrativeDetails.propTypes = { entryId: PropTypes.number }

export default React.memo(NarrativeDetails)
