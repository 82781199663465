import React, { useEffect, useState } from "react"
import makeStyles from "@mui/styles/makeStyles"
import clsx from "clsx"
import { Add, ExpandMore, Remove } from "@mui/icons-material"
import { Collapse, IconButton } from "@mui/material"
import { primaryNavy, grayColor } from "assets/jss/material-dashboard-pro-react"
import CardBody from "components/Card/CardBody"
import CardHeader from "components/Card/CardHeader"
import NarrativeOutlineBlockEdit from "components/NarrativeAdmin/NarrativeOutlineBlockEdit"

const useStyles = makeStyles(theme => ({
  addBlockHeader: {
    position: "relative",
    alignItems: "center",
    textAlign: "center",
    maxHeight: "60px",
    "& > label": {
      backgroundColor: "#ffffff",
      fontWeight: "bold",
      fontSize: "18px",
      padding: "5px 10px",
      margin: "0 auto",
      zIndex: 0,
      position: "relative",
      color: "#dddddd",
      textTransform: "capitalize",
      borderRadius: "20px",
      "& > a": {
        color: grayColor,
        margin: "0 5px"
      },
      "& > a:hover": {
        textDecoration: "underline"
      },
      "& > button": {
        position: "relative",
        top: "-2px",
        padding: 0,
        color: "#4d9ab5",
        "&:hover": {
          background: "none"
        }
      },
      "& > span": {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#dddddd",
        padding: "0 5px",
        borderRadius: "20px"
      }
    },
    "& > hr": {
      position: "absolute",
      width: "100%",
      borderColor: "#4d9ab5",
      borderWidth: "1px",
      top: "-2px"
    }
  },
  tools: {
    justifyContent: "flex-end",
    display: "inline-flex",
    marginLeft: "auto",
    "& > button": {
      borderRadius: 0,
      padding: "2px",
      marginLeft: "10px"
    },
    "& > button.selected": {
      color: primaryNavy,
      borderBottom: `1px solid ${primaryNavy}`
    },
    "& svg.MuiSvgIcon-root": {
      fontSize: "22px"
    }
  },
  expand: {
    color: primaryNavy,
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  blockActions: {
    display: "flex",
    justifyContent: "center"
  },
  paragraphBody: {
    borderRadius: "2px",
    backgroundColor: "#ffffff",
    boxShadow: "none",
    border: "1px solid #efefef"
  },
  paragraphHeader: {
    display: "flex",
    fontSize: "16px",
    backgroundColor: "#ffffff",
    borderBottom: "1px solid #efefef"
  },
  outlineBlockBody: {
    borderRadius: "2px",
    backgroundColor: "#f2f2f2",
    boxShadow: "none",
    border: "1px solid #efefef",
    marginBottom: "20px"
  },
  outlineBlockHeader: {
    display: "flex",
    fontSize: "16px",
    backgroundColor: "#dddddd",
    borderBottom: "1px solid #efefef"
  }
}))

const NarrativeOutlineBlockAdd = props => {
  const classes = useStyles()
  const { elementId, elementName, narrativeId, position, paragraphs } = props
  const updateHandling = elementId === "featuredimagejson" ? 1 : 0

  let newBlock = {
    addHTMLComment: false,
    blockAttributes: null,
    clonedFromTemplateBlock_Id: null,
    comments: "",
    contentBlock: elementId,
    contentSection: "",
    id: 0,
    isArchived: false,
    isLocked: false,
    isRequiredBlock: false,
    library_Paragraph_Id: null,
    maxLength: null,
    minLength: null,
    narrative_Id: narrativeId,
    position: position,
    renderType: "",
    sampleResult: "",
    sampleTemplate: "",
    triggerType: null,
    updateHandling,
    validationScript: null,
    validationSeverity_Id: null,
    tmpId: null
  }

  const [formOpen, setFormOpen] = useState(false)
  const [newOutlineBlock, setNewOutlineBlock] = useState(newBlock)
  const handleChange = changedBlock => {
    setNewOutlineBlock(changedBlock)
  }
  const canContainMarkup = elementId !== "content"
  const closeAll = () => {
    setFormOpen(false)
  }

  useEffect(() => {
    // Clear any local variables in this tmp object
    if (!formOpen) {
      setNewOutlineBlock(newBlock)
    }
  }, [formOpen])

  useEffect(() => {
    setNewOutlineBlock(newBlock)
  }, [position])

  return (
    <>
      <div className={classes.addBlockHeader}>
        <hr className={classes.elementHr} />
        <label>
          <IconButton
            title={`Add ${elementName} Block`}
            onClick={e => {
              setFormOpen(!formOpen)
              e.preventDefault()
            }}
            aria-label={`Add ${elementName} Block`}
            size="large"
          >
            {!formOpen && <Add />}
            {formOpen && <Remove />}
          </IconButton>
        </label>
      </div>
      <Collapse in={formOpen} unmountOnExit>
        <CardHeader className={classes.outlineBlockHeader}>
          <strong style={{ marginRight: "10px" }}>
            {"<"}
            {newOutlineBlock.position}
            {">"}
          </strong>
          {newOutlineBlock.renderType && (
            <strong style={{ marginRight: "10px" }}>
              {"<"}
              {newOutlineBlock.renderType}
              {">"}
            </strong>
          )}
          <strong>
            {newOutlineBlock.contentSection ||
              `[New ${elementName} Outline Block]`}
          </strong>
          <div className={classes.tools}>
            <IconButton
              color="primary"
              title="Toggle Edit"
              className={clsx(classes.expand, {
                [classes.expandOpen]: formOpen
              })}
              onClick={() => setFormOpen(!formOpen)}
              aria-expanded={formOpen}
              aria-label="toggle edit"
              size="large"
            >
              <ExpandMore />
            </IconButton>
          </div>
        </CardHeader>
        <CardBody className={classes.outlineBlockBody}>
          <NarrativeOutlineBlockEdit
            editMode
            canContainMarkup={canContainMarkup}
            blockLevel="outline"
            block={newOutlineBlock}
            handleClose={closeAll}
            handleSave={closeAll}
            removeBlock={closeAll}
            handleChange={handleChange}
            hasChildren={paragraphs && paragraphs.length > 0}
          />
        </CardBody>
      </Collapse>
    </>
  )
}

export default NarrativeOutlineBlockAdd
