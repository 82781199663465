import React, { useState } from "react"
import makeStyles from "@mui/styles/makeStyles"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import narrativeDashboardStyles from "assets/jss/material-dashboard-pro-react/views/narrativeDashboardStyles"
import Card from "components/Card/Card"

const useStyles = makeStyles(() => ({
  ...narrativeDashboardStyles,
  root: {
    position: "relative"
  }
}))

const NarrativeCategories = props => {
  const classes = useStyles()
  const { name, categories, onChange } = props

  const [categoryBlocks, setCategoryBlocks] = useState([])
  const [isValidTagJSON, setIsValidTagJSON] = useState(true)

  const sampleCategory = { Label: "default", Term: "default" }

  React.useEffect(() => {
    try {
      let tmpBlocks = categories && JSON.parse(categories)
      if (!categories) {
        tmpBlocks = [sampleCategory]
      }
      setCategoryBlocks(tmpBlocks)
      let newEvent = {
        target: {
          id: name,
          value: JSON.stringify(tmpBlocks)
        }
      }
      onChange && onChange(newEvent)
      setCategoryBlocks(tmpBlocks)
      setIsValidTagJSON(true)
    } catch (e) {
      setIsValidTagJSON(false)
    }
  }, [categories])

  const handleInputChange = (index, event) => {
    const tmpBlocks = categoryBlocks
    tmpBlocks[index][event.target.name] = event.target.value

    setCategoryBlocks(tmpBlocks)
    let newEvent = {
      target: {
        id: name,
        value: JSON.stringify(tmpBlocks)
      }
    }
    onChange && onChange(newEvent)
  }

  const handleAddFields = () => {
    const tmpBlocks = [...categoryBlocks, sampleCategory]
    setCategoryBlocks(tmpBlocks)
    let newEvent = {
      target: {
        id: name,
        value: JSON.stringify(tmpBlocks)
      }
    }
    onChange && onChange(newEvent)
  }

  const handleRemoveFields = index => {
    if (categoryBlocks.length > 1) {
      const tmpBlocks = categoryBlocks
      tmpBlocks.splice(index, 1)
      setCategoryBlocks(tmpBlocks)
      let newEvent = {
        target: {
          id: name,
          value: JSON.stringify(tmpBlocks)
        }
      }
      onChange && onChange(newEvent)
    }
  }

  const sampleJSON = `[{ "Label": "default", "Term": "default"},{ "Label": "default", "Term": "default"}]`

  return (
    <Card className={classes.root} style={{ marginTop: "5px" }}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <h4>Category Editor</h4>
          {categoryBlocks.length === 0 && (
            <>
              <div style={{ color: "red" }}>Invalid Category Editor JSON</div>
              <div>
                example:
                <br /> {sampleJSON}
              </div>
            </>
          )}
          {categoryBlocks &&
            categoryBlocks.map((category, index) => (
              <React.Fragment key={`${index}`}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={5}>
                    <label htmlFor="label">Label</label>
                    <input
                      type="text"
                      className="form-control"
                      id="Label"
                      name="Label"
                      value={category.Label}
                      onChange={event => handleInputChange(index, event)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={5}>
                    <label htmlFor="term">Term</label>
                    <input
                      type="text"
                      className="form-control"
                      id="Term"
                      name="Term"
                      value={category.Term}
                      onChange={event => handleInputChange(index, event)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <button
                      className="btn btn-link"
                      type="button"
                      onClick={() => handleRemoveFields(index)}
                    >
                      -
                    </button>
                    <button
                      className="btn btn-link"
                      type="button"
                      onClick={() => handleAddFields(index)}
                    >
                      +
                    </button>
                  </GridItem>
                </GridContainer>
              </React.Fragment>
            ))}
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <h4>Preview</h4>
          {!isValidTagJSON && (
            <div style={{ color: "red" }}>Unable to parse JSON into tags.</div>
          )}
          {isValidTagJSON && categoryBlocks.length > 0 && (
            <pre>{JSON.stringify(categoryBlocks, null, 2)}</pre>
          )}
          {isValidTagJSON && categoryBlocks.length === 0 && (
            <>
              <div style={{ color: "red" }}>Invalid Category Editor JSON.</div>
              <div>{categories}</div>
            </>
          )}
        </GridItem>
      </GridContainer>
    </Card>
  )
}

export default NarrativeCategories
