import React from "react"
import withStyles from "@mui/styles/withStyles"
import Checkbox from "@mui/material/Checkbox"
import Assignment from "@mui/icons-material/Assignment"
import Person from "@mui/icons-material/Person"
import Edit from "@mui/icons-material/Edit"
import Close from "@mui/icons-material/Close"
import Check from "@mui/icons-material/Check"
import Remove from "@mui/icons-material/Remove"
import Add from "@mui/icons-material/Add"
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Table from "components/Table/Table"
import Button from "components/CustomButtons/Button"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon"
import CardHeader from "components/Card/CardHeader"
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle"
import product1 from "assets/img/product1.jpg"
import product2 from "assets/img/product2.jpg"
import product3 from "assets/img/product3.jpg"

class ExtendedTables extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: []
    }
    this.handleToggle = this.handleToggle.bind(this)
  }
  handleToggle(value) {
    const { checked } = this.state
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    this.setState({
      checked: newChecked
    })
  }
  render() {
    const { classes } = this.props
    const fillButtons = [
      { color: "info", icon: Person },
      { color: "success", icon: Edit },
      { color: "danger", icon: Close }
    ].map((prop, key) => (
      <Button color={prop.color} className={classes.actionButton} key={key}>
        <prop.icon className={classes.icon} />
      </Button>
    ))
    const simpleButtons = [
      { color: "info", icon: Person },
      { color: "success", icon: Edit },
      { color: "danger", icon: Close }
    ].map((prop, key) => (
      <Button
        color={prop.color}
        simple
        className={classes.actionButton}
        key={key}
      >
        <prop.icon className={classes.icon} />
      </Button>
    ))
    const roundButtons = [
      { color: "info", icon: Person },
      { color: "success", icon: Edit },
      { color: "danger", icon: Close }
    ].map((prop, key) => (
      <Button
        round
        color={prop.color}
        className={`${classes.actionButton} ${classes.actionButtonRound}`}
        key={key}
      >
        <prop.icon className={classes.icon} />
      </Button>
    ))
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Simple Table</h4>
            </CardHeader>
            <CardBody>
              <Table
                tableHead={[
                  "#",
                  "Name",
                  "Job Position",
                  "Since",
                  "Salary",
                  "Actions"
                ]}
                tableData={[
                  [
                    "1",
                    "Andrew Mike",
                    "Develop",
                    "2013",
                    "€ 99,225",
                    fillButtons
                  ],
                  ["2", "John Doe", "Design", "2012", "€ 89,241", roundButtons],
                  [
                    "3",
                    "Alex Mike",
                    "Design",
                    "2010",
                    "€ 92,144",
                    simpleButtons
                  ],
                  [
                    "4",
                    "Mike Monday",
                    "Marketing",
                    "2013",
                    "€ 49,990",
                    roundButtons
                  ],
                  [
                    "5",
                    "Paul Dickens",
                    "Communication",
                    "2015",
                    "€ 69,201",
                    fillButtons
                  ]
                ]}
                customCellClasses={[
                  classes.center,
                  classes.right,
                  classes.right
                ]}
                customClassesForCells={[0, 4, 5]}
                customHeadCellClasses={[
                  classes.center,
                  classes.right,
                  classes.right
                ]}
                customHeadClassesForCells={[0, 4, 5]}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Striped Table</h4>
            </CardHeader>
            <CardBody>
              <Table
                striped
                tableHead={[
                  "#",
                  "",
                  "Product Name",
                  "Type",
                  "Qty",
                  "Price",
                  "Amount"
                ]}
                tableData={[
                  [
                    "1",
                    <Checkbox
                      className={classes.positionAbsolute}
                      tabIndex={-1}
                      onClick={() => this.handleToggle(1)}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />,
                    "Moleskine Agenda",
                    "Office",
                    "25",
                    "€ 49",
                    "€ 1,225"
                  ],
                  [
                    "2",
                    <Checkbox
                      className={classes.positionAbsolute}
                      tabIndex={-1}
                      onClick={() => this.handleToggle(2)}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />,
                    "Stabilo Pen",
                    "Office",
                    "30",
                    "€ 10",
                    "€ 300"
                  ],
                  [
                    "3",
                    <Checkbox
                      className={classes.positionAbsolute}
                      tabIndex={-1}
                      onClick={() => this.handleToggle(3)}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />,
                    "A4 Paper Pack",
                    "Office",
                    "50",
                    "€ 10.99",
                    "€ 109"
                  ],
                  [
                    "4",
                    <Checkbox
                      className={classes.positionAbsolute}
                      tabIndex={-1}
                      onClick={() => this.handleToggle(4)}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />,
                    "Apple iPad",
                    "Communication",
                    "10",
                    "€ 499.00",
                    "€ 4,990"
                  ],
                  [
                    "5",
                    <Checkbox
                      className={classes.positionAbsolute}
                      tabIndex={-1}
                      onClick={() => this.handleToggle(5)}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />,
                    "Apple iPhone",
                    "Communication",
                    "10",
                    "€ 599.00",
                    "€ 5,999"
                  ],
                  { total: true, colspan: "5", amount: "€12,999" }
                ]}
                customCellClasses={[
                  classes.center,
                  classes.right,
                  classes.right
                ]}
                customClassesForCells={[0, 5, 6]}
                customHeadCellClasses={[
                  classes.center,
                  classes.right,
                  classes.right
                ]}
                customHeadClassesForCells={[0, 5, 6]}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Shopping Cart Table</h4>
            </CardHeader>
            <CardBody>
              <Table
                tableHead={[
                  "",
                  "PRODUCT",
                  "COLOR",
                  "SIZE",
                  "PRICE",
                  "QTY",
                  "AMOUNT",
                  ""
                ]}
                tableData={[
                  [
                    <div className={classes.imgContainer}>
                      <img src={product1} alt="..." className={classes.img} />
                    </div>,
                    <span>
                      <a href="#jacket" className={classes.tdNameAnchor}>
                        Spring Jacket
                      </a>
                      <br />
                      <small className={classes.tdNameSmall}>
                        by Dolce&ampGabbana
                      </small>
                    </span>,
                    "Red",
                    "M",
                    <span>
                      <small className={classes.tdNumberSmall}>€</small> 549
                    </span>,
                    <span>
                      1{` `}
                      <div className={classes.buttonGroup}>
                        <Button
                          color="info"
                          size="sm"
                          round
                          className={classes.firstButton}
                        >
                          <Remove className={classes.icon} />
                        </Button>
                        <Button
                          color="info"
                          size="sm"
                          round
                          className={classes.lastButton}
                        >
                          <Add className={classes.icon} />
                        </Button>
                      </div>
                    </span>,
                    <span>
                      <small className={classes.tdNumberSmall}>€</small> 549
                    </span>,
                    <Button simple className={classes.actionButton}>
                      <Close className={classes.icon} />
                    </Button>
                  ],
                  [
                    <div className={classes.imgContainer}>
                      <img src={product2} alt="..." className={classes.img} />
                    </div>,
                    <span>
                      <a href="#jacket" className={classes.tdNameAnchor}>
                        Short Pants{" "}
                      </a>
                      <br />
                      <small className={classes.tdNameSmall}>by Pucci</small>
                    </span>,
                    "Purple",
                    "M",
                    <span>
                      <small className={classes.tdNumberSmall}>€</small> 499
                    </span>,
                    <span>
                      2{` `}
                      <div className={classes.buttonGroup}>
                        <Button
                          color="info"
                          size="sm"
                          round
                          className={classes.firstButton}
                        >
                          <Remove className={classes.icon} />
                        </Button>
                        <Button
                          color="info"
                          size="sm"
                          round
                          className={classes.lastButton}
                        >
                          <Add className={classes.icon} />
                        </Button>
                      </div>
                    </span>,
                    <span>
                      <small className={classes.tdNumberSmall}>€</small> 998
                    </span>,
                    <Button simple className={classes.actionButton}>
                      <Close className={classes.icon} />
                    </Button>
                  ],
                  [
                    <div className={classes.imgContainer}>
                      <img src={product3} alt="..." className={classes.img} />
                    </div>,
                    <span>
                      <a href="#jacket" className={classes.tdNameAnchor}>
                        Pencil Skirt
                      </a>
                      <br />
                      <small className={classes.tdNameSmall}>
                        by Valentino
                      </small>
                    </span>,
                    "White",
                    "XL",
                    <span>
                      <small className={classes.tdNumberSmall}>€</small> 799
                    </span>,
                    <span>
                      1{` `}
                      <div className={classes.buttonGroup}>
                        <Button
                          color="info"
                          size="sm"
                          round
                          className={classes.firstButton}
                        >
                          <Remove className={classes.icon} />
                        </Button>
                        <Button
                          color="info"
                          size="sm"
                          round
                          className={classes.lastButton}
                        >
                          <Add className={classes.icon} />
                        </Button>
                      </div>
                    </span>,
                    <span>
                      <small className={classes.tdNumberSmall}>€</small> 799
                    </span>,
                    <Button simple className={classes.actionButton}>
                      <Close className={classes.icon} />
                    </Button>
                  ],
                  {
                    total: true,
                    colspan: "5",
                    amount: (
                      <span>
                        <small>€</small>2,346
                      </span>
                    )
                  },
                  {
                    purchase: true,
                    colspan: "6",
                    col: {
                      colspan: 2,
                      text: (
                        <Button color="info" round>
                          Complete Purchase{" "}
                          <KeyboardArrowRight className={classes.icon} />
                        </Button>
                      )
                    }
                  }
                ]}
                tableShopping
                customHeadCellClasses={[
                  classes.center,
                  classes.description,
                  classes.description,
                  classes.right,
                  classes.right,
                  classes.right
                ]}
                customHeadClassesForCells={[0, 2, 3, 4, 5, 6]}
                customCellClasses={[
                  classes.tdName,
                  classes.customFont,
                  classes.customFont,
                  classes.tdNumber,
                  `${classes.tdNumber} ${classes.tdNumberAndButtonGroup}`,
                  classes.tdNumber
                ]}
                customClassesForCells={[1, 2, 3, 4, 5, 6]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

export default withStyles(extendedTablesStyle)(ExtendedTables)
