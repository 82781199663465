import React, { Component } from "react"
import Paragraph from "components/NarrativeAdmin/Paragraph/Paragraph"
import classnames from "classnames"
import { globalSettings } from "variables/general"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap"
import { get, put } from "tools/request"

class ParagraphEditor extends Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      activeTab: "1",
      paragraph: {
        // eslint-disable-next-line react/prop-types
        id: null
      },
      triggers: [],
      model: {},
      modelPreview: null,
      modelUrl: `${globalSettings.apiBaseUrl}/ap/NBA/data-preview?SeasonYear=2018&SeasonMonth=11&SeasonDay=14`
    }

    this.toggle = this.toggle.bind(this)
    this.changeName = this.changeName.bind(this)
    this.changeBody = this.changeBody.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }

  componentDidMount() {
    let match = this.props && this.props.match
    if (match) {
      get(`${globalSettings.apiBaseUrl}/api/paragraph/${match.params.id}`)
        .then(Response => Response.json())
        .then(paragraph => {
          this.setState({
            paragraph
          })
        })
    }
  }

  changeName(event) {
    this.setState({ narrativeName: event.target.value })
  }

  changeBody(event) {
    this.setState({ narrativeBody: event.target.value })
  }

  handleSubmit() {
    // var match = this.props && this.props.match
    // if (match) {
    //   fetch(`${globalSettings.apiBaseUrl}/api/narrative`, {
    //     credentials: "include",
    //     method: "put",
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json"
    //     },
    //     body: JSON.stringify({
    //       id: this.state.narrative.id,
    //       Name: this.state.narrative.name
    //     })
    //   })
    //     .then(Response => Response.json())
    //     .then(findresponse => {
    //       alert(findresponse.result)
    //     })
    // }
  }

  loadSampleData() {
    // var parsedModel = JSON.parse(this.state.model)
    let { modelUrl } = this.state
    this.setState({
      model: {}
    })
    get(modelUrl)
      .then(Response => Response.json())
      .then(model => {
        this.setState({
          model: JSON.parse(model).model,
          modelPreview: JSON.stringify(JSON.parse(model).model)
        })
      })
  }

  buildPreview() {
    // var parsedModel = JSON.parse(this.state.model)
    put(`${globalSettings.apiBaseUrl}/api/narrative/preview`, null, {
      paragraph: this.state.paragraph,
      triggers: this.state.triggers,
      model: this.state.model
    })
      .then(Response => Response.json())
      .then(preview => {
        this.setState({
          preview
        })
      })
  }

  render() {
    if (!this.state.paragraph.id) {
      return "Assembling..."
    }

    return (
      <div className="content">
        <Card>
          <CardHeader>
            <Form className="form">
              <Row>
                <Col lg="12">
                  <Paragraph
                    model={this.state.model}
                    triggers={this.state.triggers}
                    createMode={true}
                    edit={false}
                    paragraph={this.state.paragraph}
                    updateNarrativeParagraphs={paragraph =>
                      this.updateParagraphs(paragraph)
                    }
                  />
                </Col>
              </Row>
            </Form>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                {this.state.paragraph && (
                  <Paragraph
                    model={this.state.model}
                    triggers={this.state.triggers}
                    key={`${this.state.paragraph.id}`}
                    paragraph={this.state.paragraph}
                    updateNarrativeParagraphs={paragraph =>
                      this.updateParagraphs(paragraph)
                    }
                  />
                )}
              </Col>
              <Col lg="6">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "1"
                      })}
                      onClick={() => {
                        this.toggle("1")
                      }}
                    >
                      Test Data
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "2"
                      })}
                      onClick={() => {
                        this.toggle("2")
                      }}
                    >
                      Triggers
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <Row
                      style={{
                        height: "auto",
                        display: "flex",
                        flexFlow: "row column"
                      }}
                    >
                      <Col
                        sm="12"
                        style={{
                          flex: "1 1 100%"
                        }}
                      >
                        <Card body>
                          <CardTitle>Model</CardTitle>
                          <CardBody>
                            <Container>
                              <Row>
                                <Col>
                                  <FormGroup>
                                    <Label>Model Url</Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      defaultValue={this.state.modelUrl}
                                      onChange={e =>
                                        this.setState({
                                          modelUrl: e.target.value
                                        })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <Button
                                    color="success"
                                    onClick={() => this.loadSampleData()}
                                  >
                                    Load Sample Data
                                  </Button>
                                </Col>
                              </Row>
                              <Row>
                                <Col>{this.state.modelPreview}</Col>
                              </Row>
                            </Container>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row
                      style={{
                        height: "auto",
                        display: "flex",
                        flexFlow: "row column"
                      }}
                    >
                      <Col
                        sm="12"
                        style={{
                          flex: "1 1 100%"
                        }}
                      >
                        <Card body>
                          <CardTitle>Triggers</CardTitle>
                          <CardText>
                            <Input
                              style={{
                                height: "100vh",
                                display: "flex",
                                flexFlow: "row column"
                              }}
                              type="textarea"
                              className="form-contrrol"
                              value={this.state.triggers}
                              onChange={e =>
                                this.setState({ triggers: e.target.value })
                              }
                            />
                          </CardText>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    )
  }
}

export default ParagraphEditor
