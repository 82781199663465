import React, { useState } from "react"
import CardBody from "components/Card/CardBody"

import DialogActions from "@mui/material/DialogActions"
import Helpers from "tools/Helpers.js"
import Enums from "tools/Enums.js"
import PropTypes from "prop-types"
import Button from "components/CustomButtons/Button.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Card from "components/Card/Card.jsx"
import NarrativeRenderScheduleDAO from "daos/narrativeRenderScheduleDAO"
import OrganizationDAO from "daos/organizationDAO"
import Modal from "@mui/material/Modal"
import TableControlsNoToolbar from "components/NarrativeAdmin/Data/TableControlsNoToolbar"
import {
  FormControl,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  LinearProgress
} from "@mui/material"

const AdHocRenderScheduler = props => {
  const { narrative, displayProperties, feeds, classes } = props

  const [showResultsModal, setShowResultsModal] = useState(false)
  const [contractDeliverables, setContractDeliverables] = React.useState([])
  const [filteredWebhooks, setFilteredWebhooks] = useState([])

  const columns = [
    {
      Header: "Narrative Id",
      accessor: "narrativeId",
      sortable: false,
      filterable: false
    },
    {
      Header: "Feed Id",
      accessor: "feedId"
    },
    {
      Header: "Content Id",
      accessor: "contentId"
    },
    {
      Header: "Content Type",
      accessor: "contentType"
    },
    {
      Header: "Contract Deliverable",
      accessor: "contractDeliverableId"
    },
    {
      Header: "Webhook Id",
      accessor: "webhookId"
    },
    {
      Header: "Duplicate Handling",
      accessor: "duplicateHandling"
    },
    {
      Header: "Use Recurrence Settings",
      accessor: "useRecurrenceSettings"
    },
    {
      Header: "Cron Time Zone IANA",
      accessor: "cronTimeZoneIANA"
    },
    {
      Header: "Publish Delay In Seconds",
      accessor: "publishDelayInSeconds"
    }
  ]
  const sampleScheduler = {
    ...props.resultTableData
  }

  const [resultTableData, setResultTableData] = useState(sampleScheduler)
  const narrativeId = props.resultTableData?.narrativeId || -1

  const runAdHocRender = () => {
    let body = resultTableData
    let resultArray = []
    let feedData = []
    const putScheduleFeedEntries =
      NarrativeRenderScheduleDAO.putScheduleFeedEntries(body)
    putScheduleFeedEntries.then(results => {
      if (results) {
        resultArray.push(results)
        body.nrsId = results
        feedData.push(body)
      } else {
        resultArray.push("failed")
      }
      if (resultArray.length === 1) {
        setShowResultsModal(true)
        setResultTableData(body)
      }
    })
  }

  React.useEffect(() => {
    getContractDeliverables(narrative)
    getOutboundWebhookByOrg(narrative?.organization?.id || props.organizationId)
  }, [narrative])

  const getOutboundWebhookByOrg = orgId => {
    const results = OrganizationDAO.getOutboundWebhookByOrg(orgId)
    results.then(response => {
      const { content } = response
      const filteredWebhooks = content.filter(
        wh => wh.organization.id === orgId && !wh.isArchived
      )

      const isValidateStatus =
        props.narrative?.narrativeStatusId === Enums.NarrativeStatus.VALIDATE

      if (isValidateStatus) {
        const filteredWebhooksForValidation = filteredWebhooks.filter(
          webhook =>
            webhook.webhookStatusId === Enums.WebhookStatus.VALIDATE ||
            webhook.webhookStatusId === Enums.WebhookStatus.VALIDATEINTERNAL
        )
        setFilteredWebhooks(filteredWebhooksForValidation)
      } else {
        setFilteredWebhooks(filteredWebhooks)
      }
    })
  }
  const getContractDeliverables = narrative => {
    const fetchData = async id => {
      const response = await OrganizationDAO.getContractDeliverablesForSchedule(
        narrative?.organization?.id || props.organizationId,
        narrative.leagueId
      )
      const { content } = response
      const filteredDeliverables = content.filter(
        cd => cd.narrativeId === narrativeId
      )
      setContractDeliverables(
        filteredDeliverables.length > 0 ? filteredDeliverables : content
      )
    }
    fetchData()
  }

  const handleChange = event => {
    const newResultTableData = {
      ...resultTableData,
      [event.target.name]: event.target.value
    }
    setResultTableData(newResultTableData)
  }

  return (
    <div>
      <Card className={classes.scheduleAdHocOutputModal}>
        <CardBody>
          <h3>Would you like to run this Ad-hoc Render Schedule?</h3>
          <GridContainer spacing={3}>
            <GridItem xs={12}>
              Narrative:&nbsp;
              <span
                style={Helpers.buildCustomStyleForNarrativeStatus(
                  narrative?.narrativeStatusId
                )}
              >
                {resultTableData?.narrativeId} -{" "}
                {displayProperties?.narrativeName}
              </span>
            </GridItem>
            <GridItem xs={12}>
              Data model:&nbsp;
              {
                <span>
                  (Content Type:&nbsp;
                  {resultTableData?.contentType}, Content Id:{" "}
                  {resultTableData?.contentId})
                </span>
              }
            </GridItem>
          </GridContainer>
          {resultTableData?.contentType === "Query" && (
            <GridContainer>
              <GridItem md={3}>
                <TextField
                  type="number"
                  label="Query Builder Query #"
                  id="contentId"
                  value={resultTableData?.contentId}
                  onChange={e => this.handleContentIdChangeEvent(e)}
                  margin="normal"
                />
              </GridItem>
            </GridContainer>
          )}

          <GridContainer spacing={3} className={classes.gridOffset}>
            <GridItem xs={6}>
              <FormControl fullWidth>
                <InputLabel>Output to Feed</InputLabel>
                <Select
                  fullWidth={false}
                  label="Output to Feed"
                  type="select"
                  name="feedId"
                  id="feedId"
                  value={resultTableData?.feedId}
                  onChange={handleChange}
                >
                  <MenuItem key="none" value="">
                    None
                  </MenuItem>
                  {feeds?.map(feed => (
                    <MenuItem
                      key={feed.id}
                      value={feed.id}
                      style={Helpers.buildCustomStyleForFeedStatus(
                        feed.feedStatus
                      )}
                    >
                      {feed.id} - {feed.name} (Status:&nbsp;
                      {Helpers.convertFeedStatusIdToStatusName(feed.feedStatus)}
                      )
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {(!feeds || feeds.length === 0) && (
                <div
                  style={{
                    position: "relative"
                  }}
                  title="Loading....."
                >
                  <LinearProgress />
                </div>
              )}
            </GridItem>
            <GridItem xs={3}>
              <FormControl fullWidth>
                <InputLabel title="How should the system handle Duplicate Feed Entries?">
                  Duplicate Handling
                </InputLabel>
                <Select
                  fullWidth={false}
                  label="Duplicate Handling"
                  type="select"
                  name="duplicateHandling"
                  id="duplicateHandling"
                  value={
                    isNaN(resultTableData.duplicateHandling)
                      ? "1"
                      : Number(resultTableData.duplicateHandling)
                  }
                  onChange={handleChange}
                >
                  <MenuItem key="skip" value="0">
                    Skip
                  </MenuItem>
                  <MenuItem key="update" value="1">
                    Update
                  </MenuItem>
                  <MenuItem key="create" value="2">
                    Create
                  </MenuItem>
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>
          <GridContainer spacing={3} className={classes.gridOffset2}>
            <GridItem md={3}>
              <FormControl fullWidth disabled={true}>
                <InputLabel>Publish Delay</InputLabel>
                <Select
                  fullWidth={false}
                  type="select"
                  label="Publish Delay"
                  name="publishDelayInSeconds"
                  id="publishDelayInSeconds"
                  value={resultTableData.publishDelayInSeconds}
                  onChange={handleChange}
                  className={classes.scheduleAdHocDisabled}
                >
                  {Helpers.loadPublishDelayOptions().map(n => (
                    <MenuItem key={n.key} value={n.key}>
                      {n.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>
          <GridContainer spacing={3} className={classes.gridOffset}>
            <GridItem xs={6}>
              <FormControl fullWidth>
                <InputLabel>Output to Webhook</InputLabel>
                <Select
                  type="select"
                  label="Output to Webhook"
                  id="webhookId"
                  name="webhookId"
                  value={resultTableData.webhookId}
                  onChange={handleChange}
                  disabled={true}
                >
                  <MenuItem key="none" value="">
                    None
                  </MenuItem>
                  {filteredWebhooks.map(webhook => (
                    <MenuItem
                      key={webhook.id}
                      value={webhook.id}
                      style={Helpers.buildCustomStyleForWebhookStatus(
                        webhook.webhookStatusId
                      )}
                    >
                      {webhook.id} - {webhook.description} (Status:&nbsp;
                      {Helpers.convertWebhookStatusIdToStatusName(
                        webhook.webhookStatusId
                      )}
                      )
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>
          <GridContainer spacing={3} className={classes.gridOffset}>
            <GridItem xs={6}>
              <FormControl fullWidth={true}>
                <InputLabel title="How should the system handle Duplicate Feed Entries?">
                  Select Related Contract Deliverable
                </InputLabel>
                <Select
                  fullWidth={true}
                  label="Select Related Contract Deliverables"
                  type="select"
                  name="contractDeliverableId"
                  id="contractDeliverableId"
                  value={resultTableData.contractDeliverableId}
                  onChange={handleChange}
                  disabled={true}
                >
                  <MenuItem key="none" value="">
                    None
                  </MenuItem>
                  {contractDeliverables?.length > 0 &&
                    contractDeliverables
                      .sort((a, b) => -b.name.localeCompare(a.name))
                      .map(deliverable => (
                        <MenuItem key={deliverable.id} value={deliverable.id}>
                          {deliverable.id} - {deliverable.name}
                          {" Type Id: "}
                          {deliverable.articleTypeId}
                          {" League Id: "}
                          {deliverable.leagueId}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem
              xs={5}
              style={{
                display: "flex"
              }}
            >
              <DialogActions>
                <Button
                  color="secondary"
                  onClick={() => {
                    props.clearResultsModal()
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    runAdHocRender()
                  }}
                >
                  Create Render Schedule
                </Button>
              </DialogActions>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <Modal
        open={showResultsModal}
        onClose={() => {
          setShowResultsModal(false)
          setResultTableData({})
          props.clearResultsModal()
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.resultsModal}>
          <h2>You succesfully saved the following entries:</h2>
          <div>
            <TableControlsNoToolbar
              columns={[
                {
                  Header: "Schedule ID",
                  accessor: "nrsId"
                }
              ].concat(columns)}
              hideColumns={[]}
              data={[resultTableData]}
              readOnly={true}
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}

AdHocRenderScheduler.propTypes = {
  narrative: PropTypes.object,
  resultTableData: PropTypes.object,
  displayProperties: PropTypes.object,
  feeds: PropTypes.array,
  clearResultsModal: PropTypes.func,
  organizationId: PropTypes.number,
  classes: PropTypes.object
}
export default AdHocRenderScheduler
