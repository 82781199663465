import Dashboard from "views/Dashboard/Dashboard"
import Buttons from "views/Components/Buttons"
import GridSystem from "views/Components/GridSystem"
import Panels from "views/Components/Panels"
import SweetAlert from "views/Components/SweetAlert"
import Notifications from "views/Components/Notifications"
import Icons from "views/Components/Icons"
import Typography from "views/Components/Typography"
import RegularForms from "views/Forms/RegularForms"
import ValidationForms from "views/Forms/ValidationForms"
import Wizard from "views/Forms/Wizard"
import RegularTables from "views/Tables/RegularTables"
import ExtendedTables from "views/Tables/ExtendedTables"
import ReactTables from "views/Tables/ReactTables"
import Calendar from "views/Calendar/Calendar"
import Widgets from "views/Widgets/Widgets"
import AccountProfile from "views/Pages/AccountProfile"
import TimelinePage from "views/Pages/Timeline"
import RTLSupport from "views/Pages/RTLSupport"
import PricingPage from "views/Pages/PricingPage"
import LoginPage from "views/Pages/LoginPage"
import LockScreenPage from "views/Pages/LockScreenPage"
import DashboardIcon from "@mui/icons-material/Dashboard"
import Image from "@mui/icons-material/Image"
import Apps from "@mui/icons-material/Apps"
import GridOn from "@mui/icons-material/GridOn"
import WidgetsIcon from "@mui/icons-material/Widgets"
import DateRange from "@mui/icons-material/DateRange"
import Fingerprint from "@mui/icons-material/Fingerprint"
import MonetizationOn from "@mui/icons-material/MonetizationOn"
import LockOpen from "@mui/icons-material/LockOpen"

const rtlRoutes = [
  {
    path: "/rtl/rtl-support-page",
    name: "دعم رتل",
    mini: "ص",
    component: RTLSupport
  },
  {
    redirect: true,
    path: "/rtl",
    pathTo: "/rtl/rtl-support-page",
    name: "RTL Support"
  }
]

let sidebarLinks = [
  {
    path: "/dashboard",
    name: "لوحة القيادة ة",
    icon: DashboardIcon,
    component: Dashboard
  },
  {
    collapse: true,
    path: "-page",
    name: "صفحات",
    state: "openPages",
    icon: Image,
    views: [
      {
        path: "/portal/timeline-page",
        name: "الجدول الزمني",
        mini: "ر",
        component: TimelinePage
      },
      {
        path: "/portal/accountprofile",
        name: "ملف تعريفي للمستخدم",
        mini: "شع",
        component: AccountProfile
      },
      {
        path: "/portal/rtl/rtl-support-page",
        name: "دعم رتل",
        mini: "ص",
        component: RTLSupport
      },
      {
        path: "/login",
        name: "صفحة تسجيل الدخول",
        short: "صفحة تسجيل الدخول",
        mini: "هعذا",
        icon: Fingerprint,
        component: LoginPage
      },
      {
        path: "/portal/pages/pricing-page",
        name: "التسعير",
        short: "التسعير",
        mini: "ع",
        icon: MonetizationOn,
        component: PricingPage
      },
      {
        path: "/portal/pages/lock-screen-page",
        name: "قفل صفحة الشاشة",
        short: "قفل صفحة الشاشة",
        mini: "هذاع",
        icon: LockOpen,
        component: LockScreenPage
      }
    ]
  },
  {
    collapse: true,
    path: "/portal/components",
    name: "المكونات",
    state: "openComponents",
    icon: Apps,
    views: [
      {
        path: "/portal/components/buttons",
        name: "وصفتت",
        mini: "بت",
        component: Buttons
      },
      {
        path: "/portal/components/grid-system",
        name: "نظام الشبكةو",
        mini: "زو",
        component: GridSystem
      },
      {
        path: "/portal/components/panels",
        name: "لوحات",
        mini: "ع",
        component: Panels
      },
      {
        path: "/portal/components/sweet-alert",
        name: "التنبيه الحلو",
        mini: "ومن",
        component: SweetAlert
      },
      {
        path: "/portal/components/notifications",
        name: "إخطارات",
        mini: "ن",
        component: Notifications
      },
      {
        path: "/portal/components/icons",
        name: "الرموز",
        mini: "و",
        component: Icons
      },
      {
        path: "/portal/components/typography",
        name: "طباعة",
        mini: "ر",
        component: Typography
      }
    ]
  },
  {
    collapse: true,
    path: "/portal/forms",
    name: "إستمارات",
    state: "openForms",
    icon: "content_paste",
    views: [
      {
        path: "/portal/forms/regular-forms",
        name: "أشكال منتظمة",
        mini: "صو",
        component: RegularForms
      },
      {
        path: "/portal/forms/validation-forms",
        name: "نماذج التحقق",
        mini: "تو",
        component: ValidationForms
      },
      { path: "/forms/wizard", name: "ساحر", mini: "ث", component: Wizard }
    ]
  },
  {
    collapse: true,
    path: "/portal/tables",
    name: "الجداول",
    state: "openTables",
    icon: GridOn,
    views: [
      {
        path: "/portal/tables/regular-tables",
        name: "الجداول العادية",
        mini: "صر",
        component: RegularTables
      },
      {
        path: "/portal/tables/extended-tables",
        name: "الجداول الموسعة",
        mini: "هور",
        component: ExtendedTables
      },
      {
        path: "/portal/tables/react-tables",
        name: "رد فعل الجداول",
        mini: "غ",
        component: ReactTables
      }
    ]
  },
  {
    path: "/portal/widgets",
    name: "الحاجيات",
    icon: WidgetsIcon,
    component: Widgets
  },
  {
    path: "/portal/calendar",
    name: "التقويم",
    icon: DateRange,
    component: Calendar
  }
]

export default rtlRoutes
export { sidebarLinks }
