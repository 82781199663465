import React from "react"
import withStyles from "@mui/styles/withStyles"
import Dialog from "@mui/material/Dialog"
import { DialogActions } from "@mui/material"
import MuiDialogTitle from "@mui/material/DialogTitle"
import MuiDialogContent from "@mui/material/DialogContent"
import Button from "components/CustomButtons/Button"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import Typography from "@mui/material/Typography"
import { makeStyles } from "@mui/styles"
//MUIv5 update
//import { makeStyles } from "@mui/styles"
//import { withStyles } from "@mui/styles"

const styles = theme => ({
  root: {
    margin: 2,
    padding: theme.spacing(2),
    textAlign: "left",
    color: "#fff"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[800]
  }
})

const useStyles = makeStyles({
  content: {
    fontWeight: 400,
    lineHeight: 1.75,
    textAlign: "center"
  },
  infoButton: {
    padding: 0,
    color: "#4d9ab5"
  },
  infoHeaderIcon: {
    textAlign: "center",
    color: "#fff"
  },
  dialogIcon: {
    fontSize: "4rem"
  },
  titleContentText: {
    fontWeight: "500"
  }
})

const DialogTitle = withStyles(styles)(props => {
  const {
    children,
    classes,
    onClose,
    backgroundColor,
    icon,
    buttonProps,
    ...other
  } = props

  const dialogTitleStyle = {
    backgroundColor: backgroundColor || "#4d9ab5" // Use provided default backgroundColor or use appropriate color
  }
  return (
    <MuiDialogTitle
      className={classes.root}
      {...other}
      style={dialogTitleStyle}
    >
      {icon}
      <Typography>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(6)
  }
}))(MuiDialogContent)

export default function CustomDSDialog({
  title,
  titleContent,
  content,
  icon,
  backgroundColor,
  open,
  onClose,
  setMaxWidth,
  buttonProps
}) {
  const classes = useStyles()

  return (
    <div style={{ paddingLeft: "5px" }}>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={setMaxWidth !== undefined ? setMaxWidth : "sm"}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={onClose}
          className={classes.infoHeaderIcon}
          backgroundColor={backgroundColor}
          icon={icon}
        >
          {title}
        </DialogTitle>
        <DialogContent className={classes.content} dividers>
          <Typography variant="h5" className={classes.titleContentText}>
            {titleContent}
          </Typography>
          {content}
        </DialogContent>
        {buttonProps && (
          <DialogActions>
            {buttonProps.map((props, index) => (
              <Button key={index} {...props}>
                {props.label}
              </Button>
            ))}
          </DialogActions>
        )}
      </Dialog>
    </div>
  )
}
