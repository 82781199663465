import React from "react"
import PropTypes from "prop-types"
import { observer, inject } from "mobx-react"
import { Link, Redirect } from "react-router-dom"
import withStyles from "@mui/styles/withStyles"
import InputAdornment from "@mui/material/InputAdornment"
import Icon from "@mui/material/Icon"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import Email from "@mui/icons-material/Email"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Button from "components/CustomButtons/Button"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardHeader from "components/Card/CardHeader"
import CardFooter from "components/Card/CardFooter"
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle"
import { Cookies } from "tools/storage"
import DialogTitle from "@mui/material/DialogTitle"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import queryString from "query-string"
import TextField from "@mui/material/TextField"

const useStore = component =>
  inject(({ store }) => ({
    getAuthenticated: store.sessionStore.getAuthenticated,
    setAuthenticated: store.sessionStore.setAuthenticated,
    setAccount: store.accountStore.setAccount,
    setPermissions: store.permissionGroupStore.setPermissions,
    setOrganizations: store.organizationStore.setOrganizations,
    setOrganizationId: store.organizationStore.setOrganizationId
  }))(observer(component))

class LoginPage extends React.Component {
  constructor(props) {
    super(props)
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      usernameOrEmail: "",
      password: "",
      isLoggedIn: false,
      isRememberMe: true,
      isFailedDialogOpen: false,
      isAccountDetailsLoaded: false,
      failedDialogText: "Login failed.",
      isLoginButtonEnabled: true
    }
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" })
      }.bind(this),
      700
    )
    // Check if user is logged in
    let cookies = new Cookies()
    if (cookies.get("usernameoremail")) {
      this.setState({ usernameOrEmail: cookies.get("usernameoremail") })
    }
    if (cookies.get("currentorg")) {
      this.props.setOrganizationId(cookies.get("currentorg"))
    }
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction)
    this.timeOutFunction = null
  }

  onUsernameOrEmailChange = event => {
    this.setState({ usernameOrEmail: event.target.value })
  }

  onPasswordKeyPress = event => {
    if (event.charCode === 13) {
      this.onLoginButtonClick()
    }
  }

  onPasswordChange = event => {
    this.setState({ password: event.target.value })
  }

  onRememberMeChange = () => {
    this.setState({ isRememberMe: !this.state.isRememberMe })
  }

  onLoginButtonClick = () => {
    this.setState({ isLoginButtonEnabled: false })
    this.props
      .getAuthenticated({
        usernameoremail: this.state.usernameOrEmail,
        password: this.state.password,
        isrememberme: this.state.isRememberMe
      })
      .then(data => {
        if (data?.content?.id) {
          this.setState({
            isLoggedIn: true,
            accountDetails: data.content,
            isAccountDetailsLoaded: true,
            failedDialogText: "",
            isFailedDialogOpen: false
          })
          return
        } else {
          let failedDialogText = "Login failed."
          this.setState({
            failedDialogText,
            isFailedDialogOpen: true,
            isLoginButtonEnabled: true
          })
        }
      })
      .catch(error => {
        this.setState({
          failedDialogText: `Network issue, try again later ${error}.`,
          isFailedDialogOpen: true
        })
        this.setState({ isLoginButtonEnabled: true })
      })
  }

  onLogoutButtonClick = () => {
    this.setState({ isLoggedIn: false })
    this.props.setAuthenticated(false)
    this.props.setAccount(null)
    this.props.setPermissions(null)
    this.props.setOrganizations(null)
    this.props.setOrganizationId(null)
  }

  handleDialogClose = () => {
    this.setState({ isFailedDialogOpen: false })
  }

  render() {
    const { classes } = this.props
    let loggedInStateMarkup
    let loggedOutStateMarkup
    if (this.state.isLoggedIn) {
      // Determine is there is a prior requested url stored
      // when the user account was not logged in.
      let destinationUrl = "/portal"
      // NOTE: I actually only want to use HTTP cookies, not local storage.
      let cookies = new Cookies()

      if (cookies.getCookie("sparequestedurl") !== "") {
        destinationUrl = cookies.getCookie("sparequestedurl")
        cookies.setCookie("sparequestedurl", null, null, null, {
          path: "/",
          expires: new Date("1/1/1970")
        })
      }
      const queryParams = queryString.parse(this.props.location.search)
      if (queryParams.rdurl) {
        destinationUrl = queryParams.rdurl.replace(/https?:\/\/([^/]+)/, "")
      }

      loggedInStateMarkup = <Redirect to={destinationUrl} />
    } else {
      loggedOutStateMarkup = (
        <form>
          <Dialog
            onClose={this.handleDialogClose}
            open={this.state.isFailedDialogOpen}
            aria-labelledby="simple-dialog-title"
          >
            <DialogTitle id="simple-dialog-title">{"Oh darn"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {this.state.failedDialogText}
              </DialogContentText>
            </DialogContent>
            <DialogActions />
          </Dialog>
          <Card login className={classes[this.state.cardAnimaton]}>
            <CardHeader
              className={`${classes.cardHeader} ${classes.textCenter}`}
              color="primary"
            >
              <h4 className={classes.cardTitle}>
                Log in to Data Skrive Portal
              </h4>
            </CardHeader>
            <CardBody>
              <TextField
                label="Username or Email"
                id="email"
                fullWidth={true}
                onChange={this.onUsernameOrEmailChange}
                value={this.state.usernameOrEmail}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Email className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  )
                }}
              />
              <p />
              <TextField
                label="Password"
                id="password"
                fullWidth={true}
                onChange={this.onPasswordChange}
                onKeyPress={this.onPasswordKeyPress}
                type="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon className={classes.inputAdornmentIcon}>
                        lock_outline
                      </Icon>
                    </InputAdornment>
                  )
                }}
              />
              <h6 align="center">
                <Link to="/forgotpassword">Forgot Your Password?</Link>
              </h6>
              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      checked={this.state.isRememberMe}
                      onChange={this.onRememberMeChange}
                    />
                  }
                  classes={{
                    label: classes.label
                  }}
                  label="Keep me logged in?"
                />
              </div>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <Button
                color="primary"
                onClick={this.onLoginButtonClick}
                disabled={!this.state.isLoginButtonEnabled}
              >
                Log In
              </Button>
            </CardFooter>
          </Card>
        </form>
      )
    }
    return (
      <div className={classes.container}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={6} md={4}>
            {loggedInStateMarkup}
            {loggedOutStateMarkup}
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(loginPageStyle)(useStore(LoginPage))
