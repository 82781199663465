import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

// MUI Components
import HeadingBox from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"
import FormHelperText from "@mui/material/FormHelperText"
import Grid from "@mui/material/Grid"
import InputAdornment from "@mui/material/InputAdornment"
import InputLabel from "@mui/material/InputLabel"
import LinearProgress from "@mui/material/LinearProgress"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import TextField from "@mui/material/TextField"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"

// MUI Icons
import InfoIcon from "@mui/icons-material/Info"

import { withStyles } from "@mui/styles"

// Custom Components
import Button from "components/CustomButtons/Button"
import GridItem from "components/Grid/GridItem"

// Utils
import { formatCurrencyFloatToString } from "tools/NumberHelpers"
import { urlValidation, emailValidation } from "tools/ValidationHelpers"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
dayjs.extend(utc)

const styles = theme => ({
  headings: {
    display: "inline",
    fontSize: "1.5rem",
    fontWeight: 400
  },
  checkboxLabel: {
    "& .MuiFormControlLabel-label": {
      color: "#000",
      fontWeight: 400
    }
  },
  subtitle: {
    display: "inline",
    color: "#888"
  },
  requiredMsg: {
    color: "#c00",
    fontWeight: "bold",
    backgroundColor: "#f7f7f7"
  },
  formContentContainer: {
    border: "1px solid #AAA",
    borderRadius: "20px",
    _minWidth: "1200px",
    overflowX: "auto"
  },
  contractSelect: {
    minWidth: 180
  },
  headingUnderline: {
    borderBottom: "2px solid #333"
  }
})

function ContractDetails(props) {
  // Variable Assignment
  const { classes, contract, formErrors } = props

  // Due to how <input> element (or the MUI <TextField> component) expects the date to be formatted (YYYY-MM-DD), the ISO 8601 dates from the API need to be converted prior to the value being passed to the component. The handleSaveContract() function will format these dates back to the ISO 8601 format prior to sending the payload to the API.
  // Additionally, isAdRevShare, isCostPerActionRevShare, isSocialMediaRevShare are derived from the incoming data and calculated before initializing in state.

  // Checkbox values must be initialize to make the component controlled and allow for contract switching to be reflected in the UI properly.
  // Best-practice would initialize all values.
  const [myContract, setMyContract] = useState({
    autoRenewal: false,
    includesDynamicImages: false,
    includesDynamicGraphics: false,
    includesSeoEnhancements: false,
    includesDeepLinking: false,
    includesRankings: false,
    includesThirdPartyImage: false,
    isLicense: false,
    isProservices: false,
    isRevShare: false,
    isAdRevShare: false,
    isCostPerActionRevShare: false,
    isSocialMediaRevShare: false,
    isActive: false
  })
  const [myFormErrors, setMyFormErrors] = useState(formErrors)

  const {
    contractUrl,
    isActive,
    licenseMonthlyTotal,
    contractName,
    contractTermStart,
    contractTermEnd,
    contractTotal,
    isLicense,
    licenseTermStart,
    licenseTermEnd,
    isProservices,
    proservTermStart,
    proservTermEnd,
    proservMonthlyTotal,
    accountContactFullName,
    accountContactEmail,
    accountContactPhone,
    accountContactAddress,
    autoRenewal,
    isRevShare,
    advertisingRevenueSplitPercentage,
    isAdRevShare,
    costPerActionRevenueSplitPercentage,
    isCostPerActionRevShare,
    socialMediaRevenueSplitPercentage,
    isSocialMediaRevShare,
    revshareTermStart,
    revshareTermEnd,
    includesDynamicImages,
    includesDynamicGraphics,
    includesSeoEnhancements,
    includesDeepLinking,
    includesRankings,
    includesThirdPartyImage,
    disableSaveButton
  } = myContract

  // Handler Function Definitions
  const contractFormValidation = () => {
    const newContract = {
      ...myContract
    }
    let formErrors = {}
    let validationError = false

    if (typeof newContract.isActive !== "boolean") {
      formErrors.isActive = true
      validationError = true
    }

    if (!newContract.contractName) {
      formErrors.contractName = true
      validationError = true
    }

    if (!urlValidation(newContract.contractUrl)) {
      formErrors.contractUrl = true
      validationError = true
    }

    if (newContract.isRevShare) {
      if (
        !newContract.isAdRevShare &&
        !newContract.isCostPerActionRevShare &&
        !newContract.isSocialMediaRevShare
      ) {
        formErrors.revshareOptions = true
        validationError = true
      }

      if (
        newContract.isAdRevShare &&
        !newContract.advertisingRevenueSplitPercentage
      ) {
        if (newContract.advertisingRevenueSplitPercentage === 0) {
          formErrors.advertisingRevenueSplitPercentage = false
          validationError = false
        } else {
          formErrors.advertisingRevenueSplitPercentage = true
          validationError = true
        }
      }

      if (
        newContract.isCostPerActionRevShare &&
        !newContract.costPerActionRevenueSplitPercentage
      ) {
        if (newContract.costPerActionRevenueSplitPercentage === 0) {
          formErrors.costPerActionRevenueSplitPercentage = false
          validationError = false
        } else {
          formErrors.costPerActionRevenueSplitPercentage = true
          validationError = true
        }
      }

      if (
        newContract.isSocialMediaRevShare &&
        !newContract.socialMediaRevenueSplitPercentage
      ) {
        if (newContract.socialMediaRevenueSplitPercentage === 0) {
          formErrors.socialMediaRevenueSplitPercentage = false
          validationError = false
        } else {
          formErrors.socialMediaRevenueSplitPercentage = true
          validationError = true
        }
      }

      if (!newContract.revshareTermStart) {
        formErrors.revshareTermStart = true
        validationError = true
      }

      if (!newContract.revshareTermEnd) {
        formErrors.revshareTermEnd = true
        validationError = true
      }
    }

    if (newContract.isProservices) {
      if (!newContract.proservMonthlyTotal) {
        if (newContract.proservMonthlyTotal === 0) {
          formErrors.proservMonthlyTotal = false
          validationError = false
        } else {
          formErrors.proservMonthlyTotal = true
          validationError = true
        }
      }

      if (!newContract.proservTermStart) {
        formErrors.proservTermStart = true
        validationError = true
      }

      if (!newContract.proservTermEnd) {
        formErrors.proservTermEnd = true
        validationError = true
      }
    }

    if (newContract.isLicense) {
      if (!newContract.licenseMonthlyTotal) {
        formErrors.licenseMonthlyTotal = true
        validationError = true
      }

      if (!newContract.licenseTermStart) {
        formErrors.licenseTermStart = true
        validationError = true
      }

      if (!newContract.licenseTermEnd) {
        formErrors.licenseTermEnd = true
        validationError = true
      }
    }

    if (!newContract.accountContactFullName) {
      formErrors.accountContactFullName = true
      validationError = true
    }

    if (!emailValidation(newContract.accountContactEmail)) {
      formErrors.accountContactEmail = true
      validationError = true
    }

    formErrors.validationHasError = validationError
    setMyFormErrors(formErrors)
    return validationError
  }

  const handleSaveContract = () => {
    const newContract = {
      ...myContract,
      legalNameOnContract: myContract.contractName,
      licenseMonthlyTotal: Number(myContract.licenseMonthlyTotal),
      disableSaveButton: true,
      contractTermStart:
        myContract.contractTermStart &&
        dayjs(myContract.contractTermStart).local().format(),
      contractTermEnd:
        myContract.contractTermEnd &&
        dayjs(myContract.contractTermEnd).local().format(),
      revshareTermStart:
        myContract.revshareTermStart &&
        dayjs(myContract.revshareTermStart).local().format(),
      revshareTermEnd:
        myContract.revshareTermEnd &&
        dayjs(myContract.revshareTermEnd).local().format(),
      licenseTermStart:
        myContract.licenseTermStart &&
        dayjs(myContract.licenseTermStart).local().format(),
      licenseTermEnd:
        myContract.licenseTermEnd &&
        dayjs(myContract.licenseTermEnd).local().format(),
      proservTermStart:
        myContract.proservTermStart &&
        dayjs(myContract.proservTermStart).local().format(),
      proservTermEnd:
        myContract.proservTermEnd &&
        dayjs(myContract.proservTermEnd).local().format()
    }

    let validationError = contractFormValidation()
    if (!validationError) {
      props.saveContractForOrg(newContract)
    }
  }

  const handleContractChange = name => event => {
    const newInitializedFields = {
      ...myContract.initializedFields,
      [name]: false
    }
    const newContract = {
      ...myContract,
      disableSaveButton: false,
      [name]: event.target.value,
      initializedFields: newInitializedFields,
      editingContract: true
    }
    setMyContract(newContract)
  }

  const checkUrlValidation = (event, name) => {
    const isUrl = urlValidation(event.target.value)
    let formErrors = {
      ...myFormErrors
    }
    formErrors[name] = !isUrl
    if (!isUrl) {
      formErrors.validationHasError = !isUrl
    }
    setMyFormErrors(formErrors)
    return isUrl
  }

  const checkEmailValidation = (event, name) => {
    const isEmail = emailValidation(event.target.value)
    let formErrors = {
      ...myFormErrors
    }
    formErrors[name] = !isEmail
    if (!isEmail) {
      formErrors.validationHasError = !isEmail
    }
    setMyFormErrors(formErrors)
    return isEmail
  }

  const handleContractPhoneChange = name => event => {
    const newInitializedFields = {
      ...myContract.initializedFields,
      [name]: false
    }
    let myNumber = event.target.value
    if (myNumber) {
      //Check for any character not a number, ")", "(", "-" or ".", and reject it
      let tmp = myNumber.replace(/^\+/, "") //You can start with a +, but cannot be anywhere else
      if (tmp.replace(/\d|\.|-|\(|\)/g, "")) {
        return
      }
    }

    const newContract = {
      ...myContract,
      disableSaveButton: false,
      [name]: myNumber,
      initializedFields: newInitializedFields,
      editingContract: true
    }
    setMyContract(newContract)
  }

  const handleContractTotalChange = name => event => {
    const newInitializedFields = {
      ...myContract.initializedFields,
      [name]: false
    }
    let myTotal = event.target.value.replace(/,/g, "")
    if (myTotal) {
      //Check for any character not a number or ".", and reject it
      let tmp = myTotal
      if (tmp.replace(/\d|\./g, "")) {
        return
      }
    }
    let digits = myTotal.split(".")
    if (digits[1] && digits[1].length > 2) {
      myTotal = Number(`${digits[0]}.${digits[1].substring(0, 2)}`)
    }
    const newContract = {
      ...myContract,
      disableSaveButton: false,
      [name]: Number(myTotal),
      initializedFields: newInitializedFields,
      editingContract: true
    }
    setMyContract(newContract)
  }

  const handleIsActiveChange = name => event => {
    let myVal =
      event.target.value === "true"
        ? true
        : event.target.value === "false"
        ? false
        : event.target.value
    const newContract = {
      ...myContract,
      [name]: myVal,
      editingContract: true
    }
    setMyContract(newContract)
  }

  const onDateChange = name => event => {
    const newInitializedFields = {
      ...myContract.initializedFields,
      [name]: false
    }
    const newContract = {
      ...myContract,
      disableSaveButton: false,
      [name]: event.target.value,
      initializedFields: newInitializedFields,
      editingContract: true
    }
    setMyContract(newContract)
  }

  const handleContractCheckboxChange = event => {
    const name = event.target.id
    let newContract = {
      ...myContract,
      disableSaveButton: false,
      [name]: !myContract[name],
      editingContract: true
    }
    if (name === "isRevShare" && !event.target.checked) {
      newContract.isAdRevShare = false
      newContract.advertisingRevenueSplitPercentage = null
      newContract.isCostPerActionRevShare = false
      newContract.costPerActionRevenueSplitPercentage = null
      newContract.isSocialMediaRevShare = false
      newContract.socialMediaRevenueSplitPercentage = null
      newContract.revshareTermStart = null
      newContract.revshareTermEnd = null
    }

    if (name === "isAdRevShare" && !event.target.checked) {
      newContract.advertisingRevenueSplitPercentage = null
    }

    if (name === "isCostPerActionRevShare" && !event.target.checked) {
      newContract.costPerActionRevenueSplitPercentage = null
    }

    if (name === "isSocialMediaRevShare" && !event.target.checked) {
      newContract.socialMediaRevenueSplitPercentage = null
    }

    if (name === "isLicense" && !event.target.checked) {
      newContract.licenseMonthlyTotal = null
      newContract.licenseTermStart = null
      newContract.licenseTermEnd = null
    }

    if (name === "isProservices" && !event.target.checked) {
      newContract.proservMonthlyTotal = null
      newContract.proservTermStart = null
      newContract.proservTermEnd = null
    }

    setMyContract(newContract)
  }

  const handlePercentageChange = event => {
    const name = event.target.id
    let value = event.target.value

    // If there are leading zeros, remove them.
    if (/^(?:0+(?=[1-9])|0+(?=0$))/.test(value)) {
      value = value.replace(/^(?:0+(?=[1-9])|0+(?=0$))/, "")
    }

    if (value) {
      // If there is non-null value, ensure only 2 decimal places are used.
      const index = value.indexOf(".")
      value =
        index >= 0 ? value.substr(0, index) + value.substr(index, 3) : value
    }

    // Check for no value (empty string) and set to null
    if (value === "") {
      value = null
    } else if (Number(value) > 100) {
      value = 100
    }

    const newContract = {
      ...myContract,
      disableSaveButton: false,
      [name]: value,
      editingContract: true
    }
    setMyContract(newContract)
  }

  const formatContractObject = contractObj => {
    const formattedContract = {
      ...contractObj,
      revshareTermStart:
        contract.revshareTermStart &&
        dayjs(contract.revshareTermStart).format("YYYY-MM-DD"),
      revshareTermEnd:
        contract.revshareTermEnd &&
        dayjs(contract.revshareTermEnd).format("YYYY-MM-DD"),
      licenseTermStart:
        contract.licenseTermStart &&
        dayjs(contract.licenseTermStart).format("YYYY-MM-DD"),
      licenseTermEnd:
        contract.licenseTermEnd &&
        dayjs(contract.licenseTermEnd).format("YYYY-MM-DD"),
      proservTermStart:
        contract.proservTermStart &&
        dayjs(contract.proservTermStart).format("YYYY-MM-DD"),
      proservTermEnd:
        contract.proservTermEnd &&
        dayjs(contract.proservTermEnd).format("YYYY-MM-DD"),
      isAdRevShare:
        !!contract.advertisingRevenueSplitPercentage ||
        contract.advertisingRevenueSplitPercentage === 0,
      isCostPerActionRevShare:
        !!contract.costPerActionRevenueSplitPercentage ||
        contract.costPerActionRevenueSplitPercentage === 0,
      isSocialMediaRevShare:
        !!contract.socialMediaRevenueSplitPercentage ||
        contract.socialMediaRevenueSplitPercentage === 0
    }
    return formattedContract
  }

  // Effects
  useEffect(() => {
    const startDates = [licenseTermStart, proservTermStart, revshareTermStart]
    let filteredStartDates = startDates.filter(date => date)
    let sortedStartDates = filteredStartDates.sort((a, b) => (a < b ? -1 : 0))
    let earliestDate = sortedStartDates[0]

    const endDates = [licenseTermEnd, proservTermEnd, revshareTermEnd]
    let filteredEndDates = endDates.filter(date => date)
    let sortedEndDates = filteredEndDates.sort((a, b) => (a < b ? -1 : 0))
    let latestDate = sortedEndDates[sortedEndDates.length - 1]

    let num1 = 0
    let num2 = 0

    if (licenseMonthlyTotal) {
      if (typeof licenseMonthlyTotal === "string") {
        num1 = parseFloat(licenseMonthlyTotal.replace(/,/g, ""))
      } else {
        num1 = licenseMonthlyTotal
      }
    }

    if (proservMonthlyTotal) {
      if (typeof proservMonthlyTotal === "string") {
        num2 = parseFloat(proservMonthlyTotal.replace(/,/g, ""))
      } else {
        num2 = proservMonthlyTotal
      }
    }

    const sum = (num1 + num2).toFixed(2)

    const updatedContract = {
      ...myContract,
      contractTermStart: earliestDate,
      contractTermEnd: latestDate,
      contractTotal: Number(sum)
    }
    setMyContract(updatedContract)
  }, [
    licenseTermStart,
    proservTermStart,
    revshareTermStart,
    licenseTermEnd,
    proservTermEnd,
    revshareTermEnd,
    licenseMonthlyTotal,
    proservMonthlyTotal
  ])

  useEffect(() => {
    setMyContract(formatContractObject(contract))
  }, [contract])

  return (
    <>
      <>
        <div
          style={{
            paddingBottom: "0px",
            position: "relative",
            top: "12px",
            left: "20px"
          }}
        >
          <span
            style={{
              display: "inline-block",
              fontSize: "14px",
              background: "#fff",
              padding: "0 5px",
              fontWeight: "bold"
            }}
          >
            Contract Details
          </span>
        </div>
      </>

      <Grid container className={classes.formContentContainer}>
        <GridItem xs={12} style={{ margin: "1.5rem 0 1rem 0" }}>
          <FormControl
            variant="outlined"
            required
            error={formErrors.isActive}
            className={classes.contractSelect}
          >
            <InputLabel id="contract-status-select-label">
              Contract Status
            </InputLabel>
            <Select
              label="Contract Status"
              id="contract-status-select"
              variant="outlined"
              value={isActive}
              onChange={handleIsActiveChange("isActive")}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Completed</MenuItem>
            </Select>
            {myFormErrors.isActive && (
              <FormHelperText className={classes.requiredMsg}>
                Contract Status is required!
              </FormHelperText>
            )}
          </FormControl>
        </GridItem>

        <GridItem xs={12} sm={5} direction="column">
          <HeadingBox className={classes.headingUnderline}>
            <Typography className={classes.headings}>
              Dates &amp; Details
            </Typography>
          </HeadingBox>
          <TextField
            type="text"
            id="contractName"
            label="Legal Contract Name"
            fullWidth
            value={contractName}
            onChange={handleContractChange("contractName")}
            margin="normal"
            variant="outlined"
            required={true}
            onBlur={() => {
              if (myFormErrors.validationHasError) {
                contractFormValidation()
              }
            }}
            inputProps={{ tabIndex: "1" }}
          />
          {myFormErrors.contractName && (
            <FormHelperText className={classes.requiredMsg}>
              Contract Name is required!
            </FormHelperText>
          )}
          <TextField
            type="text"
            id="contractUrl"
            label="Contract URL"
            fullWidth
            value={contractUrl}
            onChange={handleContractChange("contractUrl")}
            margin="normal"
            variant="outlined"
            required={true}
            onBlur={e => {
              checkUrlValidation(e, "contractUrl")
              if (myFormErrors.validationHasError) {
                contractFormValidation()
              }
            }}
            inputProps={{ tabIndex: "2" }}
          />
          {myFormErrors.contractUrl && (
            <FormHelperText className={classes.requiredMsg}>
              A valid Contract URL is required!
            </FormHelperText>
          )}
          <TextField
            disabled
            type="text"
            id="contractTotal"
            label={
              <>
                Contract Monthly Total{" "}
                <Tooltip
                  title="Populated by Contract Type data on save."
                  placement="right"
                  fontSize="small"
                  color="primary"
                >
                  <InfoIcon />
                </Tooltip>
              </>
            }
            fullWidth
            value={formatCurrencyFloatToString(contractTotal)}
            margin="normal"
            variant="outlined"
            InputLabelProps={{ style: { pointerEvents: "auto" } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
          />
          <TextField
            fullWidth
            disabled
            id="contractTermStart"
            label={
              <>
                Contract Term Start{" "}
                <Tooltip
                  title="Populated by Contract Type data on save."
                  placement="right"
                  fontSize="small"
                  color="primary"
                >
                  <InfoIcon />
                </Tooltip>
              </>
            }
            type="date"
            value={
              contractTermStart
                ? dayjs(contractTermStart).format("YYYY-MM-DD")
                : ""
            }
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
              style: { pointerEvents: "auto" }
            }}
          />
          <TextField
            fullWidth
            disabled
            id="contractTermEnd"
            label={
              <>
                Contract Term End{" "}
                <Tooltip
                  title="Populated by Contract Type data on save."
                  placement="right"
                  fontSize="small"
                  color="primary"
                >
                  <InfoIcon />
                </Tooltip>
              </>
            }
            type="date"
            value={
              contractTermEnd ? dayjs(contractTermEnd).format("YYYY-MM-DD") : ""
            }
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
              style: { pointerEvents: "auto" }
            }}
          />
          <FormControlLabel
            className={classes.checkboxLabel}
            labelPlacement="end"
            control={
              <Checkbox
                id="autoRenewal"
                checked={autoRenewal}
                color="primary"
                onChange={event => {
                  handleContractCheckboxChange(event)
                }}
              />
            }
            label="Does Contract Auto Renew?"
          />
          <HeadingBox className={classes.headingUnderline}>
            <Typography className={classes.headings}>Contract Types</Typography>{" "}
            <span className={classes.subtitle}>(select*)</span>
          </HeadingBox>
          <FormGroup>
            <FormControlLabel
              className={classes.checkboxLabel}
              labelPlacement="end"
              control={
                <Checkbox
                  id="isRevShare"
                  checked={isRevShare}
                  color="primary"
                  onChange={event => {
                    handleContractCheckboxChange(event)
                  }}
                />
              }
              label={`${
                isRevShare ? "Revenue Share (select*)" : "Revenue Share"
              }`}
            />
            {isRevShare && (
              <>
                <FormGroup style={{ marginLeft: 40 }}>
                  <FormControlLabel
                    className={classes.checkboxLabel}
                    labelPlacement="end"
                    control={
                      <Checkbox
                        id="isAdRevShare"
                        checked={isAdRevShare}
                        color="primary"
                        onChange={event => {
                          handleContractCheckboxChange(event)
                        }}
                      />
                    }
                    label="Advertising Revenue Share"
                  />
                  {isAdRevShare && (
                    <>
                      <TextField
                        type="number"
                        id="advertisingRevenueSplitPercentage"
                        label="Advertising Revenue Split % for Org"
                        fullWidth
                        value={
                          advertisingRevenueSplitPercentage === 0
                            ? advertisingRevenueSplitPercentage.toString()
                            : advertisingRevenueSplitPercentage
                        }
                        onChange={handlePercentageChange}
                        margin="none"
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          )
                        }}
                        InputLabelProps={{
                          shrink:
                            advertisingRevenueSplitPercentage ||
                            advertisingRevenueSplitPercentage === 0
                        }}
                        inputProps={{
                          type: "number",
                          min: 0,
                          max: 100,
                          step: 0.01
                        }}
                        onBlur={() => {
                          if (myFormErrors.validationHasError) {
                            contractFormValidation()
                          }
                        }}
                      />
                      {myFormErrors.advertisingRevenueSplitPercentage && (
                        <FormHelperText className={classes.requiredMsg}>
                          Advertising Revenue Split percentage is required!
                        </FormHelperText>
                      )}
                    </>
                  )}
                  <FormControlLabel
                    className={classes.checkboxLabel}
                    labelPlacement="end"
                    control={
                      <Checkbox
                        id="isCostPerActionRevShare"
                        checked={isCostPerActionRevShare}
                        color="primary"
                        onChange={event => {
                          handleContractCheckboxChange(event)
                        }}
                      />
                    }
                    label="Cost per Action Revenue Share"
                  />
                  {isCostPerActionRevShare && (
                    <>
                      <TextField
                        type="number"
                        id="costPerActionRevenueSplitPercentage"
                        label="Cost per Action Revenue Split % for Org"
                        fullWidth
                        value={
                          costPerActionRevenueSplitPercentage === 0
                            ? costPerActionRevenueSplitPercentage.toString()
                            : costPerActionRevenueSplitPercentage
                        }
                        onChange={handlePercentageChange}
                        margin="none"
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          )
                        }}
                        InputLabelProps={{
                          shrink:
                            costPerActionRevenueSplitPercentage ||
                            costPerActionRevenueSplitPercentage === 0
                        }}
                        inputProps={{
                          min: 0,
                          max: 100,
                          step: 0.01
                        }}
                        onBlur={() => {
                          if (myFormErrors.validationHasError) {
                            contractFormValidation()
                          }
                        }}
                      />
                      {myFormErrors.costPerActionRevenueSplitPercentage && (
                        <FormHelperText className={classes.requiredMsg}>
                          Cost per Action Revenue Split percentage is required!
                        </FormHelperText>
                      )}
                    </>
                  )}
                  <FormControlLabel
                    className={classes.checkboxLabel}
                    labelPlacement="end"
                    control={
                      <Checkbox
                        id="isSocialMediaRevShare"
                        checked={isSocialMediaRevShare}
                        color="primary"
                        onChange={event => {
                          handleContractCheckboxChange(event)
                        }}
                      />
                    }
                    label="Social Media Revenue Share"
                  />
                  {isSocialMediaRevShare && (
                    <>
                      <TextField
                        type="number"
                        id="socialMediaRevenueSplitPercentage"
                        label="Social Media Revenue Split % for Org"
                        fullWidth
                        value={
                          socialMediaRevenueSplitPercentage === 0
                            ? socialMediaRevenueSplitPercentage.toString()
                            : socialMediaRevenueSplitPercentage
                        }
                        onChange={handlePercentageChange}
                        margin="none"
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          )
                        }}
                        InputLabelProps={{
                          shrink:
                            socialMediaRevenueSplitPercentage ||
                            socialMediaRevenueSplitPercentage === 0
                        }}
                        inputProps={{
                          min: 0,
                          max: 100,
                          step: 0.01
                        }}
                        onBlur={() => {
                          if (myFormErrors.validationHasError) {
                            contractFormValidation()
                          }
                        }}
                      />
                      {myFormErrors.socialMediaRevenueSplitPercentage && (
                        <FormHelperText className={classes.requiredMsg}>
                          Social Media Revenue Split percentage is required!
                        </FormHelperText>
                      )}
                    </>
                  )}
                  {myFormErrors.revshareOptions && (
                    <FormHelperText className={classes.requiredMsg}>
                      A Revenue Share option is required!
                    </FormHelperText>
                  )}
                </FormGroup>
                <TextField
                  required
                  onChange={onDateChange("revshareTermStart")}
                  id="revshareTermStart"
                  label="Revenue Share Term Start"
                  type="date"
                  value={revshareTermStart || ""}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  fullWidth
                  inputProps={{
                    min: "2019-01-01",
                    max: "2100-01-01"
                  }}
                  onBlur={() => {
                    if (myFormErrors.validationHasError) {
                      contractFormValidation()
                    }
                  }}
                />
                {myFormErrors.revshareTermStart && (
                  <FormHelperText className={classes.requiredMsg}>
                    Revenue Share Term Start is required!
                  </FormHelperText>
                )}
                <TextField
                  required
                  onChange={onDateChange("revshareTermEnd")}
                  id="revshareTermEnd"
                  label="Revenue Share Term End"
                  type="date"
                  value={revshareTermEnd || ""}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  fullWidth
                  inputProps={{
                    min: "2019-01-01",
                    max: "2100-01-01"
                  }}
                  onBlur={() => {
                    if (myFormErrors.validationHasError) {
                      contractFormValidation()
                    }
                  }}
                />
                {myFormErrors.revshareTermEnd && (
                  <FormHelperText className={classes.requiredMsg}>
                    Revenue Share Term End is required!
                  </FormHelperText>
                )}
              </>
            )}
            <FormControlLabel
              className={classes.checkboxLabel}
              labelPlacement="end"
              control={
                <Checkbox
                  id="isLicense"
                  checked={isLicense}
                  color="primary"
                  onChange={event => {
                    handleContractCheckboxChange(event)
                  }}
                />
              }
              label="License"
            />
            {isLicense && (
              <>
                <TextField
                  required
                  type="text"
                  id="licenseMonthlyTotal"
                  label="License Contract Total"
                  fullWidth
                  value={formatCurrencyFloatToString(licenseMonthlyTotal)}
                  onChange={handleContractTotalChange("licenseMonthlyTotal")}
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                  onBlur={() => {
                    if (myFormErrors.validationHasError) {
                      contractFormValidation()
                    }
                  }}
                />
                {myFormErrors.licenseMonthlyTotal && (
                  <FormHelperText className={classes.requiredMsg}>
                    License Contract Total is required!
                  </FormHelperText>
                )}
                <TextField
                  required
                  onChange={onDateChange("licenseTermStart")}
                  id="licenseTermStart"
                  label="License Term Start"
                  type="date"
                  value={licenseTermStart || ""}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  fullWidth
                  inputProps={{
                    min: "2019-01-01",
                    max: "2100-01-01"
                  }}
                  onBlur={() => {
                    if (myFormErrors.validationHasError) {
                      contractFormValidation()
                    }
                  }}
                />
                {myFormErrors.licenseTermStart && (
                  <FormHelperText className={classes.requiredMsg}>
                    License Term Start is required!
                  </FormHelperText>
                )}
                <TextField
                  required
                  onChange={onDateChange("licenseTermEnd")}
                  id="licenseTermEnd"
                  label="License Term End"
                  type="date"
                  value={licenseTermEnd || ""}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  fullWidth
                  inputProps={{
                    min: "2019-01-01",
                    max: "2100-01-01"
                  }}
                  onBlur={() => {
                    if (myFormErrors.validationHasError) {
                      contractFormValidation()
                    }
                  }}
                />
                {myFormErrors.licenseTermEnd && (
                  <FormHelperText className={classes.requiredMsg}>
                    License Term End is required!
                  </FormHelperText>
                )}
              </>
            )}
            <FormControlLabel
              className={classes.checkboxLabel}
              labelPlacement="end"
              control={
                <Checkbox
                  id="isProservices"
                  checked={isProservices}
                  color="primary"
                  onChange={event => {
                    handleContractCheckboxChange(event)
                  }}
                />
              }
              label="Professional Services"
            />
            {isProservices && (
              <>
                <TextField
                  required
                  type="text"
                  id="proservMonthlyTotal"
                  label="Professional Services Contract Total"
                  fullWidth
                  value={formatCurrencyFloatToString(proservMonthlyTotal)}
                  onChange={handleContractTotalChange("proservMonthlyTotal")}
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                  onBlur={() => {
                    if (myFormErrors.validationHasError) {
                      contractFormValidation()
                    }
                  }}
                />
                {myFormErrors.proservMonthlyTotal && (
                  <FormHelperText className={classes.requiredMsg}>
                    Contract Professional Services Monthly total is required!
                  </FormHelperText>
                )}
                <TextField
                  required
                  onChange={onDateChange("proservTermStart")}
                  id="proservTermStart"
                  label="Professional Services Term Start"
                  type="date"
                  value={proservTermStart || ""}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  fullWidth
                  inputProps={{
                    min: "2019-01-01",
                    max: "2100-01-01"
                  }}
                  onBlur={() => {
                    if (myFormErrors.validationHasError) {
                      contractFormValidation()
                    }
                  }}
                />
                {myFormErrors.proservTermStart && (
                  <FormHelperText className={classes.requiredMsg}>
                    Professional Services Term Start is required!
                  </FormHelperText>
                )}
                <TextField
                  required
                  onChange={onDateChange("proservTermEnd")}
                  id="proservTermEnd"
                  label="Professional Services Term End"
                  type="date"
                  value={proservTermEnd || ""}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  fullWidth
                  inputProps={{
                    min: "2019-01-01",
                    max: "2100-01-01"
                  }}
                  onBlur={() => {
                    if (myFormErrors.validationHasError) {
                      contractFormValidation()
                    }
                  }}
                />
                {myFormErrors.proservTermEnd && (
                  <FormHelperText className={classes.requiredMsg}>
                    Professional Services Term End is required!
                  </FormHelperText>
                )}
              </>
            )}
          </FormGroup>
        </GridItem>

        <GridItem sm={2} />

        <GridItem xs={12} sm={5} direction="column">
          <HeadingBox className={classes.headingUnderline}>
            <Typography className={classes.headings}>
              Account Contact
            </Typography>
          </HeadingBox>

          <TextField
            type="text"
            id="accountContactFullName"
            label="Account Contact Full Name"
            fullWidth
            value={accountContactFullName}
            onChange={handleContractChange("accountContactFullName")}
            margin="normal"
            variant="outlined"
            required={true}
            onBlur={() => {
              if (myFormErrors.validationHasError) {
                contractFormValidation()
              }
            }}
            inputProps={{ tabIndex: "3" }}
          />
          {myFormErrors.accountContactFullName && (
            <FormHelperText className={classes.requiredMsg}>
              Account Contact Full Name is required!
            </FormHelperText>
          )}
          <TextField
            type="text"
            id="accountContactEmail"
            label="Account Contact Email"
            fullWidth
            value={accountContactEmail}
            onChange={handleContractChange("accountContactEmail")}
            margin="normal"
            variant="outlined"
            required={true}
            onBlur={e => {
              checkEmailValidation(e, "accountContactEmail")
              if (myFormErrors.validationHasError) {
                contractFormValidation()
              }
            }}
            inputProps={{ tabIndex: "4" }}
          />
          {myFormErrors.accountContactEmail && (
            <FormHelperText className={classes.requiredMsg}>
              A valid Account Contact Email is required!
            </FormHelperText>
          )}
          <TextField
            type="text"
            id="accountContactPhone"
            label="Account Contact Phone"
            fullWidth
            value={accountContactPhone}
            onChange={handleContractPhoneChange("accountContactPhone")}
            margin="normal"
            variant="outlined"
          />
          <TextField
            type="text"
            id="accountContactAddress"
            label="Account Contact Address"
            fullWidth
            value={accountContactAddress}
            onChange={handleContractChange("accountContactAddress")}
            margin="normal"
            variant="outlined"
          />
          <HeadingBox className={classes.headingUnderline}>
            <Typography className={classes.headings}>Add-ons</Typography>
          </HeadingBox>
          <FormGroup>
            <FormControlLabel
              className={classes.checkboxLabel}
              labelPlacement="end"
              control={
                <Checkbox
                  id="includesDynamicImages"
                  checked={includesDynamicImages}
                  color="primary"
                  onChange={event => {
                    handleContractCheckboxChange(event)
                  }}
                />
              }
              label="Graphics"
            />
            <FormControlLabel
              className={classes.checkboxLabel}
              labelPlacement="end"
              control={
                <Checkbox
                  id="includesRankings"
                  checked={includesRankings}
                  color="primary"
                  onChange={event => {
                    handleContractCheckboxChange(event)
                  }}
                />
              }
              label="Ranking &amp; Predictions Algorithms"
            />
            <FormControlLabel
              className={classes.checkboxLabel}
              labelPlacement="end"
              control={
                <Checkbox
                  id="includesDynamicGraphics"
                  checked={includesDynamicGraphics}
                  color="primary"
                  onChange={event => {
                    handleContractCheckboxChange(event)
                  }}
                />
              }
              label="Motion Graphics"
            />
            <FormControlLabel
              className={classes.checkboxLabel}
              labelPlacement="end"
              control={
                <Checkbox
                  id="includesThirdPartyImage"
                  checked={includesThirdPartyImage}
                  color="primary"
                  onChange={event => {
                    handleContractCheckboxChange(event)
                  }}
                />
              }
              label="Third-party Image Integration"
            />
            <FormControlLabel
              className={classes.checkboxLabel}
              labelPlacement="end"
              control={
                <Checkbox
                  id="includesSeoEnhancements"
                  checked={includesSeoEnhancements}
                  color="primary"
                  onChange={event => {
                    handleContractCheckboxChange(event)
                  }}
                />
              }
              label="SEO Research &amp; Best Practices"
            />
            <FormControlLabel
              className={classes.checkboxLabel}
              labelPlacement="end"
              control={
                <Checkbox
                  id="includesDeepLinking"
                  checked={includesDeepLinking}
                  color="primary"
                  onChange={event => {
                    handleContractCheckboxChange(event)
                  }}
                />
              }
              label="Deep Linking"
            />
          </FormGroup>
        </GridItem>
      </Grid>

      <Grid container justifyContent="center" style={{ marginTop: "1rem" }}>
        {myContract.editingContract && (
          <>
            <GridItem xs={12} sm style={{ textAlign: "center" }}>
              <Button
                onClick={() => {
                  setMyContract(formatContractObject(contract))
                  setMyFormErrors(formErrors)
                }}
                color="primary"
              >
                Cancel
              </Button>
            </GridItem>
            <GridItem xs={12} sm style={{ textAlign: "center" }}>
              <Button
                onClick={() => {
                  handleSaveContract()
                }}
                color="primary"
                disabled={disableSaveButton}
              >
                Save Contract Info
              </Button>
            </GridItem>
          </>
        )}
      </Grid>
      {props.updatingContract && (
        <Grid
          spacing={1}
          container
          style={{ marginTop: "1rem", paddingTop: "5px 10px" }}
        >
          <GridItem xs>
            <LinearProgress />
          </GridItem>
        </Grid>
      )}
    </>
  )
}

ContractDetails.propTypes = {
  contract: PropTypes.object,
  formErrors: PropTypes.object,
  organization: PropTypes.object,
  saveContractForOrg: PropTypes.func,
  whichOne: PropTypes.string
}

export default withStyles(styles)(ContractDetails)
