import React, { useState, useEffect } from "react"
import { useObserver } from "mobx-react"
import { Cookies } from "tools/storage"
import { useStore } from "contexts/rootContext"
import {
  Card,
  CardContent,
  Box,
  FormControl,
  CircularProgress,
  Checkbox,
  TextField,
  FormControlLabel,
  Button
} from "@mui/material"
import { Autocomplete } from "@mui/material"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import NarrativeOutlineBlockExplorer from "components/NarrativeAdmin/Data/NarrativeOutlineBlockExplorer"
import NarrativeDAO from "daos/narrativeDAO"
import Enums from "tools/Enums.js"
import CustomDSDialog from "components/CustomDialogs/CustomDSDialog"
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline"
import { makeStyles } from "@mui/styles"
import InterstitialConfirmDialog from "components/NarrativeAdmin/LibraryCopyTools/InterstitialConfirmDialog"

const useStyles = makeStyles({
  infoHeader: {
    textAlign: "left",
    color: "inherit",
    marginTop: "15px"
  },
  addScrollIfNeeded: {
    maxHeight: "28vh",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "10px 0 0 0",
    "& li": {
      textAlign: "left"
    }
  },
  floatAlignedButton: {
    position: "fixed"
  },
  gridAlignment: {
    position: "relative",
    minHeight: "60vh"
  },
  ciruclarProgressCSS: {
    position: "absolute",
    left: "45%",
    top: "30px"
  }
})

const styles = {
  strikethrough: {
    textDecoration: "line-through"
  }
}

function useStoreData() {
  const store = useStore()

  return useObserver(() => ({
    loading: store.narrativeStore.loading,
    loadingSourceNarrative: store.narrativeStore.loadingSourceNarrative,
    loadingTargetNarrative: store.narrativeStore.loadingTargetNarrative,
    organizations: store.organizationStore.organizations,
    getSourceNarrativeBrief: store.narrativeStore.getSourceNarrativeBrief,
    sourceNarrative: store.narrativeStore.sourceNarrative,
    getTargetNarrativeBrief: store.narrativeStore.getTargetNarrativeBrief,
    targetNarrative: store.narrativeStore.targetNarrative,
    getOrganizations: store.organizationStore.getOrganizations,
    postCopyNarrativeOutlineBlock:
      store.narrativeStore.postCopyNarrativeOutlineBlock,
    postCopyParagraph: store.narrativeStore.postCopyParagraph,
    postCopyTrigger: store.narrativeStore.postCopyTrigger,
    setSourceNarrative: store.narrativeStore.setSourceNarrative,
    setTargetNarrative: store.narrativeStore.setTargetNarrative
  }))
}

const NarrativeOutlineMover = () => {
  const {
    getSourceNarrativeBrief,
    getTargetNarrativeBrief,
    sourceNarrative,
    targetNarrative,
    organizations,
    getOrganizations,
    postCopyParagraph,
    postCopyNarrativeOutlineBlock,
    postCopyTrigger,
    loading,
    loadingSourceNarrative,
    loadingTargetNarrative,
    setSourceNarrative,
    setTargetNarrative
  } = useStoreData()

  // Get default current org from cookie
  let cookies = new Cookies()
  let cookieCurrentOrg = cookies.get("currentorg")
  // Source Info
  const [selectedSourceOrgId, setSelectedSourceOrgId] =
    useState(cookieCurrentOrg)
  const [sourceNarrativeId, setSourceNarrativeId] = useState(null)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [showArchived, setShowArchived] = useState(false)
  const [showInactiveSrc, setShowInactiveSrc] = useState(false)
  const [showResultModal, setShowResultModal] = useState(false)
  const [showResultInfo, setShowResultInfo] = useState([])

  // Target Info
  const [selectedTargetOrgId, setSelectedTargetOrgId] =
    useState(cookieCurrentOrg)
  const [targetNarrativeId, setTargetNarrativeId] = useState("")

  // Blocks to copy
  const [selectedBlocks, setSelectedBlocks] = useState([])
  const [targetedBlocks, setTargetedBlocks] = useState([])

  // Block to copy to (for sentences)
  const [selectedTarget, setTargetBlock] = useState("")

  // Autocomplete Options
  const [sourceNarratives, setSourceNarratives] = useState([])
  const [targetNarratives, setTargetNarratives] = useState([])
  const [nonarchivedSourceNarratives, setNonarchivedSourceNarratives] =
    useState([])
  const [nonarchivedTargetNarratives, setNonarchivedTargetNarratives] =
    useState([])

  // Autocomplete States
  const [selectedSourceOrg, setSelectedSourceOrg] = useState(null)
  const [selectedSourceNarrative, setSelectedSourceNarrative] = useState(null)
  const [selectedTargetOrg, setSelectedTargetOrg] = useState(null)
  const [selectedTargetNarrative, setSelectedTargetNarrative] = useState(null)
  const [disableSourceMenus, setDisableSourceMenus] = useState(false)
  const [disableTargetMenus, setDisableTargetMenus] = useState(false)
  const [targetNarrativesResult, setTargetNarrativesResult] = useState([])

  const sourceEntities = cookies.get("BlockMoverSources")["id"]
  const orgsNoProps = organizations.toJS().filter(itm => itm.parentId === null)
  const orgsNoPropsNoArchive = orgsNoProps.filter(itm => itm.isActive === true)

  const classes = useStyles()

  // Handle bulk selection replaces the current collection
  const handleBulkSelection = newItems => {
    setSelectedBlocks(newItems)
    storeBlocksToBeProcessed(newItems)
  }

  const handleTargetSelection = newItem => {
    if (newItem !== selectedTarget) {
      setTargetBlock(newItem)
    } else {
      setTargetBlock(null)
    }
  }

  const processBlock = async block => {
    let srcType = block.split("-")[0]
    let srcId = block.split("-")[1]
    if (srcType === "triggerid" || srcType === "newstriggerid") {
      const result = await postCopyTrigger(srcId, targetNarrativeId)
      updateTargetNarrativesResult(srcId, result, srcType)
    } else if (srcType === "paragraphid") {
      const result = await postCopyParagraph(srcId, targetNarrativeId)
      updateTargetNarrativesResult(srcId, result, srcType)
    } else if (srcType === "outlineBlockId") {
      const result = await postCopyNarrativeOutlineBlock(
        srcId,
        targetNarrativeId
      )
      updateTargetNarrativesResult(srcId, result, srcType)
    }
  }

  const storeBlocksToBeProcessed = blocks => {
    let resultObj = []
    blocks.forEach(itm => {
      let foundOutlineInfo = {}
      let found = false
      sourceNarrative.outlineBlocks?.forEach(outB => {
        if (!found) {
          const srcId = outB?.id
          let fromId = itm.match(/\d{3,}/)
          fromId = fromId && fromId[0] ? Number(fromId[0]) : null
          if (srcId && fromId && srcId === fromId) {
            foundOutlineInfo = {
              from: itm,
              position: outB.position,
              contentSection: outB.contentSection
            }
            found = true
          }
        }
      })
      resultObj.push(foundOutlineInfo)
    })
    setTargetNarrativesResult(resultObj)
  }

  const updateTargetNarrativesResult = (srcid, result, type) => {
    targetNarrativesResult.forEach(itm => {
      if (itm.from.indexOf(srcid) !== -1) {
        itm.to = result
        itm.type = type
      }
    })
    setTargetNarrativesResult(targetNarrativesResult)
  }

  const copySelectedBlocks = async () => {
    // Do some test to be sure we can do anything
    // 1.) Have you any blocks?
    if (!selectedBlocks || selectedBlocks.length === 0) {
      alert("Nothing selected to copy.")
      return
    }
    // Make variables
    let sentences = selectedBlocks.filter(block => block.startsWith("sentence"))
    let otherBlocks = selectedBlocks.filter(
      block => !block.startsWith("sentence")
    )
    let targetType =
      selectedTarget && selectedTarget.split("-")[0].toLowerCase()

    // 2.) If there any sentences, be sure a target paragraph was selected
    if (sentences && sentences.length > 0 && targetType !== "paragraphid") {
      let fromSource = cookies.get("BlockMoverSources")
      let toTarget = cookies.get("BlockMoverTargets")
      fromSource = fromSource.id
      toTarget = toTarget.id
      toTarget.forEach(target => {
        fromSource.forEach(src => {
          let srcNum = src.split("-")
          let tgtNum = target.split("-")
          if (srcNum[1] && tgtNum[1]) {
            setTimeout(function () {}, 200)
          }
        })
      })
      cookies.set("BlockMoverSources", { id: [], name: [] })
      cookies.set("BlockMoverTargets", { id: [], name: [] })
      setTimeout(getTargetNarrativeBrief(targetNarrativeId), 750)
      setTimeout(getSourceNarrativeBrief(sourceNarrativeId), 750)
      setSelectedBlocks([])
      setTargetedBlocks([])
      setTargetBlock(null)
      return
    }
    // 2.) If its a sentence, be sure other blocks aren't also selected
    if (
      sentences &&
      sentences.length > 0 &&
      otherBlocks &&
      otherBlocks.length > 0
    ) {
      alert(
        "You've selected a mix of sentences and other blocks which can confuse the system. Please copy top level items like Triggers and Paragraphs in separate batches from sentences."
      )
      return
    }
    // 3.) Be sure we didnt get here without a target narrative
    if (
      targetNarrativeId === null ||
      targetNarrativeId === "" ||
      targetNarrativeId === 0
    ) {
      alert("Please select the narrative you wish to export the blocks to.")
      return
    }
    // Loop through each of the blocks and process them
    //storeBlocksToBeProcessed(selectedBlocks)
    for (const block of selectedBlocks) {
      //do 1 at a time so they all get a uniuque/correct position
      await processBlock(block)
    }
    cookies.set("BlockMoverSources", { id: [], name: [] })
    cookies.set("BlockMoverTargets", { id: [], name: [] })
    getTargetNarrativeBrief(targetNarrativeId)
    getSourceNarrativeBrief(sourceNarrativeId)
    setSelectedBlocks([])
    setTargetedBlocks([])
    setTargetBlock(null)
  }

  // on first render
  useEffect(() => {
    getOrganizations()
  }, [])

  // componentWillUnmount()
  // reset source narrative state
  useEffect(
    () => () => {
      setSourceNarrative()
      setTargetNarrative()
    },
    []
  )

  useEffect(() => {
    if (organizations.toJS().length > 0) {
      const org = organizations
        .toJS()
        .find(org => org.id === selectedSourceOrgId)
      setSelectedSourceOrg(org)
      setSelectedTargetOrg(org)
    }
  }, [organizations])

  // Fetch Updated Source Organization Narratives
  useEffect(() => {
    if (selectedSourceOrgId !== undefined) {
      const fetchData = async id => {
        setDisableSourceMenus(true)
        const response = await NarrativeDAO.getNarrativesByOrganizationId(id)
        const { content } = response
        content.sort((a, b) => (a > b ? 1 : -1))
        setSourceNarratives(content)
        const nonarchivedSourceNarratives = content.filter(
          narrative => !narrative.isArchived
        )
        setNonarchivedSourceNarratives(nonarchivedSourceNarratives)
        setDisableSourceMenus(false)
      }

      try {
        fetchData(selectedSourceOrgId)
      } catch (err) {
        console.error(
          `Error fetching narratives for org ${selectedSourceOrgId}`,
          err
        )
        setDisableSourceMenus(false)
      }
    } else {
      setSourceNarratives([])
      setNonarchivedSourceNarratives([])
    }
  }, [selectedSourceOrgId])

  // Fetch Updated Target Organization Narratives
  useEffect(() => {
    if (selectedTargetOrgId !== undefined) {
      const fetchData = async id => {
        setDisableTargetMenus(true)
        const response = await NarrativeDAO.getNarrativesByOrganizationId(id)
        const { content } = response
        content.sort((a, b) => (a > b ? 1 : -1))
        setTargetNarratives(content)
        const nonarchivedTargetNarratives = content.filter(
          narrative => !narrative.isArchived
        )
        setNonarchivedTargetNarratives(nonarchivedTargetNarratives)
        setDisableTargetMenus(false)
      }

      try {
        fetchData(selectedTargetOrgId)
      } catch (err) {
        setDisableTargetMenus(false)
      }
    } else {
      setTargetNarratives([])
      setNonarchivedTargetNarratives([])
    }
  }, [selectedTargetOrgId])

  useEffect(() => {
    if (selectedSourceNarrative === null) {
      setSourceNarrative()
      setSourceNarrativeId()
    }
  }, [selectedSourceNarrative])

  useEffect(() => {
    if (selectedTargetNarrative === null) {
      setTargetNarrative()
      setTargetNarrativeId()
    }
  }, [selectedTargetNarrative])

  const showResultsSummary = () => {
    let myResults = []
    targetNarrativesResult.forEach(info => {
      if (info.from.match(/outlineBlock/)) {
        targetNarrative.outlineBlocks?.forEach(tgt => {
          if (tgt?.id === Number(info.to)) {
            let fromId = info.from.match(/\d{3,}/)
            fromId = fromId && fromId[0] ? Number(fromId[0]) : ""
            myResults.push(
              `We copied Outline Block "${fromId} - ${info.contentSection}", Position ${info.position} to Output "${targetNarrative.id}", copy has an Outline Block ID of "${tgt.id}", Position ${tgt.position}`
            )
          }
        })
      }
    })
    if (myResults.length && showResultInfo.length === 0) {
      setShowResultInfo(myResults)
      setShowResultModal(true)
      setTargetNarrativesResult([])
    }
  }

  useEffect(() => {
    if (targetNarrativesResult.length > 0) {
      showResultsSummary()
    }
  }, [targetNarrative])

  const processSuccessList = list => (
    <div>
      <div className={classes.infoHeader}>
        The following entities have been copied:
      </div>
      <div className={classes.addScrollIfNeeded}>
        <ol>
          {list?.map((itm, i) => (
            <li key={i}>{itm}</li>
          ))}
        </ol>
      </div>
    </div>
  )

  return (
    <>
      <CustomDSDialog
        open={showResultModal}
        titleContent="Success!"
        content={processSuccessList(showResultInfo)}
        icon={<CheckCircleOutline style={{ fontSize: "4rem" }} />}
        backgroundColor={"#66BB6A"}
        onClose={() => {
          setShowResultModal(false)
          setShowResultInfo([])
        }}
      />

      <Card>
        <CardContent>
          <GridContainer>
            <GridItem xs={4} style={{ textAlign: "center" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showInactiveSrc || false}
                    onChange={() => {
                      setShowInactiveSrc(!showInactiveSrc)
                    }}
                  />
                }
                label={`Show Inactive Source Organizations? ${
                  showInactiveSrc ? "*" : ""
                }`}
              />
            </GridItem>
            <GridItem xs={4} style={{ textAlign: "center" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showArchived || false}
                    onChange={() => {
                      setShowArchived(!showArchived)
                    }}
                  />
                }
                label="Show Archived Outputs?"
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={5}>
              <h4>Source Output</h4>
            </GridItem>

            <GridItem xs={2} />
            <GridItem xs={5}>
              <h4>Target Output</h4>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={2}>
              <FormControl fullWidth>
                <Autocomplete
                  id="organizations"
                  disabled={disableSourceMenus}
                  options={
                    showInactiveSrc
                      ? orgsNoProps?.filter(
                          itm => itm.id !== Enums.Organizations.Libraries
                        )
                      : orgsNoPropsNoArchive?.filter(
                          itm => itm.id !== Enums.Organizations.Libraries
                        )
                  }
                  value={selectedSourceOrg}
                  getOptionLabel={option => `${option.id} - ${option.name} 
                      ${option.isArchived ? "*" : ""}`}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <div
                        style={{
                          ...(option.isArchived && styles.strikethrough)
                        }}
                      >
                        {option.id} - {option.name}
                      </div>
                    </li>
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id || ""
                  }
                  renderInput={params => (
                    <TextField
                      label="Source Organization"
                      key={params.id}
                      margin="none"
                      {...params}
                    />
                  )}
                  onChange={(e, value) => {
                    setSelectedSourceOrg(value)
                    setSelectedSourceOrgId(value?.id)
                  }}
                  onInputChange={(event, value, reason) => {
                    if (reason === "clear" || reason === "reset") {
                      setSelectedSourceNarrative(null)
                    }
                  }}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={3}>
              <FormControl fullWidth>
                <Autocomplete
                  id="sourceNarrativeId"
                  disabled={disableSourceMenus}
                  options={
                    showArchived
                      ? sourceNarratives
                      : nonarchivedSourceNarratives
                  }
                  value={selectedSourceNarrative}
                  getOptionLabel={option =>
                    `${option.id} - ${option.name} ${
                      option.isArchived ? "*" : ""
                    }`
                  }
                  renderOption={(props, option) => (
                    <li {...props}>
                      <div
                        style={{
                          ...(option.isArchived && styles.strikethrough)
                        }}
                      >
                        {option.id} - {option.name}
                      </div>
                    </li>
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id || ""
                  }
                  renderInput={params => (
                    <TextField
                      margin="none"
                      label="Source Output"
                      key={params.id}
                      {...params}
                    />
                  )}
                  onChange={(e, value) => {
                    cookies.set("BlockMoverSources", { id: [], name: [] })
                    setSelectedBlocks([])
                    setSourceNarrativeId(value?.id)
                    getSourceNarrativeBrief(value?.id)
                    setSelectedSourceNarrative(value)
                  }}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={2}>
              <div style={{ textAlign: "center" }}>
                {loading && (
                  <CircularProgress
                    style={{ position: "absolute", left: "49%" }}
                  />
                )}
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      !(
                        (selectedBlocks && selectedBlocks.length > 0) ||
                        selectedTarget
                      )
                    }
                    onClick={() => {
                      setSelectedBlocks([])
                      setTargetBlock(null)
                      getSourceNarrativeBrief(sourceNarrativeId)
                    }}
                  >
                    Clear Selections
                  </Button>
                </div>
              </div>
              <InterstitialConfirmDialog
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={copySelectedBlocks}
                sourceEntities={selectedBlocks}
                sourceNarrative={sourceNarrative}
                targetNarrative={targetNarrative}
                targetEntities={[]}
                selectedSources={selectedBlocks}
                withAssociatedTriggers={false}
                type={"OutlineMover"}
              />
            </GridItem>
            <GridItem xs={2}>
              <FormControl fullWidth>
                <Autocomplete
                  id="target-organizations"
                  disabled={disableTargetMenus}
                  options={orgsNoPropsNoArchive?.filter(
                    itm => itm.id !== Enums.Organizations.Libraries
                  )}
                  value={selectedTargetOrg}
                  getOptionLabel={option =>
                    `${option.id} - ${option.name} ${
                      option.isActive ? "" : "*"
                    }`
                  }
                  renderOption={(props, option) => (
                    <li {...props}>
                      <div>
                        {option.id} - {option.name}
                      </div>
                    </li>
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id || ""
                  }
                  renderInput={params => (
                    <TextField
                      margin="none"
                      label="Target Organization"
                      key={params.id}
                      {...params}
                    />
                  )}
                  onChange={(e, value) => {
                    setSelectedTargetOrgId(value?.id)
                    setSelectedTargetOrg(value)
                  }}
                  onInputChange={(event, value, reason) => {
                    if (reason === "clear" || reason === "reset") {
                      setSelectedTargetNarrative(null)
                    }
                  }}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={3}>
              <FormControl fullWidth>
                <Autocomplete
                  id="target-narratives"
                  disabled={disableTargetMenus}
                  options={
                    showArchived
                      ? targetNarratives
                      : nonarchivedTargetNarratives
                  }
                  value={selectedTargetNarrative}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id || ""
                  }
                  getOptionLabel={option =>
                    `${option.id} - ${option.name} ${
                      option.isActive ? "" : "*"
                    }`
                  }
                  renderOption={(props, option) => (
                    <li {...props}>
                      <div>
                        {option.id} - {option.name}
                      </div>
                    </li>
                  )}
                  renderInput={params => (
                    <TextField
                      margin="none"
                      key={params.id}
                      label="Target Output"
                      {...params}
                    />
                  )}
                  onChange={(e, value) => {
                    cookies.set("BlockMoverTargets", { id: [], name: [] })
                    setTargetedBlocks([])
                    setTargetNarrativeId(value?.id)
                    getTargetNarrativeBrief(value?.id)
                    setSelectedTargetNarrative(value)
                  }}
                />
              </FormControl>
            </GridItem>
          </GridContainer>
          <Box>
            <GridContainer>
              <GridItem xs={5} className={classes.gridAlignment}>
                {loadingSourceNarrative && (
                  <CircularProgress className={classes.ciruclarProgressCSS} />
                )}
                {sourceNarrative?.id && (
                  <NarrativeOutlineBlockExplorer
                    id="sourceNarrative"
                    selectedBlocks={selectedBlocks}
                    narrative={sourceNarrative}
                    handleBulkSelection={handleBulkSelection}
                    targetedBlocks={targetedBlocks}
                  />
                )}
              </GridItem>
              <GridItem
                xs={2}
                style={{
                  textAlign: "center"
                }}
              >
                <div style={{ textAlign: "left" }}>
                  {sourceNarrative?.id && sourceEntities && (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!targetNarrativeId}
                      className={classes.floatAlignedButton}
                      onClick={() => setConfirmOpen(true)}
                    >
                      Copy Selected Blocks
                    </Button>
                  )}
                </div>
              </GridItem>
              <GridItem xs={5} className={classes.gridAlignment}>
                {loadingTargetNarrative && (
                  <CircularProgress className={classes.ciruclarProgressCSS} />
                )}
                {targetNarrative?.id && (
                  <NarrativeOutlineBlockExplorer
                    id="targetNarrative"
                    selectedBlocks={selectedTarget || []}
                    narrative={targetNarrative}
                    handleBlockSelection={handleTargetSelection}
                    targetedBlocks={targetedBlocks}
                    areCheckboxesDisabled={true}
                  />
                )}
              </GridItem>
            </GridContainer>
          </Box>
        </CardContent>
      </Card>
    </>
  )
}

export default NarrativeOutlineMover
