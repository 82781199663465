import React, { Component } from "react"
import withStyles from "@mui/styles/withStyles"
import Assignment from "@mui/icons-material/Assignment"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon"
import CardHeader from "components/Card/CardHeader"
import { cardTitle } from "assets/jss/material-dashboard-pro-react"
import LinearProgress from "@mui/material/LinearProgress"
//import CircularProgress from "@mui/material/CircularProgress"
import TextField from "@mui/material/TextField"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import Autocomplete from "@mui/material/Autocomplete"
import { observer, inject } from "mobx-react"
import Button from "components/CustomButtons/Button"
import Helpers from "tools/Helpers.js"

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  cardIconDescription: {
    ...cardTitle,
    fontSize: "12px",
    marginTop: "5px",
    marginBottom: "0px"
  },
  strikethrough: {
    textDecoration: "line-through"
  }
}

const useStore = component =>
  inject(({ store }) => ({
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization,
    orgAndPropId: store.organizationStore.orgAndPropId,
    narratives: store.organizationStore.narratives,
    getNarratives: store.organizationStore.getNarratives,
    isCopyingNarrative: store.narrativeStore.isCopyingNarrative,
    copyNarrative: store.narrativeStore.copyNarrative,
    isLoading: store.narrativeStore.loading
  }))(observer(component))

class NarrativeCloneTool extends Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      orgAndPropId: this.props.orgAndPropId,
      selectedCopyNarrative: null,
      isShowArchived: false
    }
  }

  componentDidMount() {
    this.props.setShowChangeCurrentOrganization(true)
    this.props.getNarratives(this.props.orgAndPropId)
  }

  componentDidUpdate() {
    if (this.state.orgAndPropId !== this.props.orgAndPropId) {
      this.setState(
        {
          orgAndPropId: this.props.orgAndPropId
        },
        () => this.props.getNarratives(this.state.orgAndPropId)
      )
    }
  }

  onCopyNarrativeClick = async () => {
    try {
      let response = await this.props.copyNarrative(
        this.state.selectedCopyNarrative?.id
      )
      if (response?.toJS().content?.id) {
        this.props.history.push(
          `/portal/narrative/${response?.toJS().content?.id}`
        )
      }
    } catch (e) {
      alert(`Error when Copying Narrative: ${e.Message}`)
    }
  }

  render() {
    const { classes } = this.props
    const sortedNarratives = this.props.narratives
      ?.toJS()
      .sort(Helpers.sortByName)
    const filteredNarratives = sortedNarratives.filter(
      narrative => !narrative.isArchived
    )
    if (!this.props.narratives) {
      return "Assembling..."
    }

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Narrative Copy Tool</h4>
              <span className={classes.cardIconDescription}>
                Creates a new narrative based on an existing narrative.
              </span>
            </CardHeader>
            <CardBody>
              {this.props.isCopyingNarrative && <LinearProgress />}
              <GridContainer>
                <GridItem xs={12}>Narrative to Copy</GridItem>
                <GridItem xs={7}>
                  <Autocomplete
                    name="narrativeToCopy"
                    id="narrativeToCopy"
                    options={
                      !this.state.isShowArchived
                        ? filteredNarratives
                        : sortedNarratives
                    }
                    loading={this.props.isLoading}
                    loadingText={"Loading Narrative Options..."}
                    disableClearable={false}
                    getOptionLabel={option => `${option.id} - ${option.name}`}
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value?.id
                    }
                    value={this.state.selectedCopyNarrative}
                    onChange={(event, newValue) => {
                      this.setState({
                        selectedCopyNarrative: newValue
                      })
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <div
                          style={{
                            ...(option.isArchived && styles.strikethrough)
                          }}
                          key={option.id}
                          value={option.id}
                        >
                          {option.id} - {option.name}
                        </div>
                      </li>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Narrative to Copy"
                        margin="normal"
                      />
                    )}
                  />
                </GridItem>
                <GridItem xs={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.isShowArchived || false}
                        onChange={() => {
                          this.setState({
                            isShowArchived: !this.state.isShowArchived
                          })
                        }}
                      />
                    }
                    label="Show Archived Narratives?"
                  />
                </GridItem>
                <GridItem xs={12} style={{ textAlign: "left" }}>
                  <Button
                    type="submit"
                    onClick={this.onCopyNarrativeClick}
                    color="primary"
                  >
                    Copy
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

export default withStyles(styles)(useStore(NarrativeCloneTool))
