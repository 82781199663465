import Typography from "@mui/material/Typography"

function LinkOfferBulkUploadInfo(bulkUploadList) {
  return (
    <>
      <Typography variant="h5" gutterBottom>
        Link Offer Upload Information
      </Typography>
      <Typography align="left" gutterBottom>
        <b>An example Link Offer Bulk Upload template </b>
        <a
          href="https://docs.google.com/spreadsheets/d/1dznHI1jUxj3y5JSjP7Qz5a2XXoCs-U3vsp3tix6rFwY/edit#gid=0"
          rel="noreferrer"
          target="_blank"
        >
          {" "}
          <u>
            <b>can be found here.</b>
          </u>
        </a>
        {"  "}
        These will need to be made into a TSV file.
        <br />
        <br />
      </Typography>
      <Typography align="left">
        <u>
          <b>Bulk Upload requirements:</b>
        </u>
        {"  "}A bulk upload entry will only validate if all mandatory fields are
        included. These fields are:
      </Typography>
      <ul className={bulkUploadList}>
        <li>Link URL</li>
        <li>Company</li>
        <li>Short Name Offer</li>
        <li>Organization</li>
        <li>Affiliate</li>
        <li>Conversion Type</li>
        <li>Region</li>
        <li>Conversion Payout</li>
        <li>Offer Link Type</li>
      </ul>
    </>
  )
}
export default LinkOfferBulkUploadInfo
