import PropTypes from "prop-types"

// MUI Components
import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"
import Link from "@mui/material/Link"
import TextField from "@mui/material/TextField"

// MUI Icons
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBox"

// MUI Hooks
import { useFilterPanelStyles } from "../styles/FilteredTable.styles"

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = (
  <CheckBoxIcon style={{ color: "#144d69" }} fontSize="small" />
)

const MultiSelectListBox = (props, classes, data) => (
  <>
    <ul style={{ maxHeight: "50vh" }} {...props}>
      <Box classes={{ root: classes.multiSelectListboxActions }}>
        <Link
          variant="caption"
          onClick={() => {
            data.handleSelectAll(data.filterName, data.columnId, data.options)
          }}
        >
          Select All
        </Link>
        <span>-</span>
        <Link
          variant="caption"
          onClick={() => {
            data.handleDeleteAll(data.filterName)
          }}
        >
          Clear
        </Link>
      </Box>
      {props.children}
    </ul>
  </>
)

const EnhancedMultiSelect = ({
  multiple,
  sortedOptions,
  filterName,
  columnId,
  value,
  options,
  handleFilterChange,
  handleSelectAll,
  handleDeleteAll
}) => {
  const { input, notchedOutline, chip, multiSelectListboxActions, textfield } =
    useFilterPanelStyles()

  return (
    <Autocomplete
      value={value ? value.value : multiple ? [] : {}}
      onChange={(event, value, reason) => {
        handleFilterChange(filterName, columnId, value, reason)
      }}
      disableCloseOnSelect
      limitTags={3}
      multiple={multiple}
      options={sortedOptions}
      id={`${filterName}-filter`}
      getOptionLabel={option => option?.value?.toString() || ""}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      ChipProps={{
        classes: { root: chip },
        size: "small"
      }}
      ListboxComponent={params =>
        MultiSelectListBox(
          { ...params },
          { multiSelectListboxActions },
          {
            filterName,
            columnId,
            options,
            handleSelectAll,
            handleDeleteAll
          }
        )
      }
      renderInput={params => (
        <TextField
          {...params}
          size="small"
          variant="outlined"
          label={`${filterName}`}
          classes={{ root: textfield }}
          InputProps={{
            ...params.InputProps,
            classes: { root: input },
            notched: false
          }}
          InputLabelProps={{
            ...params.InputLabelProps,
            classes: { root: notchedOutline }
          }}
        />
      )}
      // renderOption={(props, option, { selected }) => (
      //   <>
      //     <Checkbox
      //       icon={icon}
      //       checkedIcon={checkedIcon}
      //       style={{ marginRight: 8 }}
      //       checked={selected}
      //     />
      //     {option.value}
      //   </>
      // )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.value}
        </li>
      )}
    />
  )
}

EnhancedMultiSelect.propTypes = {
  multiple: PropTypes.bool,
  sortedOptions: PropTypes.array,
  filterName: PropTypes.string,
  columnId: PropTypes.string,
  value: PropTypes.object,
  options: PropTypes.array,
  handleFilterChange: PropTypes.func,
  handleSelectAll: PropTypes.func,
  handleDeleteAll: PropTypes.func
}

export default EnhancedMultiSelect
