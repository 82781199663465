import { globalSettings } from "variables/general.jsx"
import { get, put } from "../tools/request"

export default class NarrativeDAO {
  static getNarratives = async () => {
    const res = await get(`${globalSettings.apiBaseUrl}/api/narrative`)
    return await res.json()
  }

  static getNarrativesByOrganizationId = async organizationId => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/narrative/retrieveallbyorganization?organizationid=${organizationId}`
    )
    return await res.json()
  }

  static getNarrative = async id => {
    const res = await get(`${globalSettings.apiBaseUrl}/api/narrative/${id}`)
    return await res.json()
  }

  static getNarrativeIsLibrary = async id => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/narrative/${id}/islibrary`
    )
    return await res.json()
  }

  static getNarrativeBrief = async id => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/narrative/${id}/brief`
    )
    return await res.json()
  }

  static getNarrativeBase = async id => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/narrative/${id}/base`
    )
    return await res.json()
  }

  static getNarrativeBriefLibrary = async id => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/narrative/${id}/briefLibrary`
    )
    return await res.json()
  }

  static getSnapshotDerivedOutlineBlocks = async id => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/narrative/${id}/derivedtemplate`
    )
    return await res.json()
  }
  static getFeedEntryDerivedOutlineBlocks = async feedEntryId => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/feedentry/deriverendertemplate/${feedEntryId}`
    )
    return await res.json()
  }

  static getBrokenBlockSnapShot = async entryId => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/feedentry/brokenblocksnapshots/${entryId}`
    )
    return await res.json()
  }

  static getParagraphDerivedOutlineBlocks = async id => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/narrative/${id}/paragraphoutline`
    )
    return await res.json()
  }

  static getBlockSamples = async id => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/narrative/${id}/sampleblocks`
    )
    return await res.json()
  }

  static getSearchResults = async searchString => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/narrative/search?searchstring=${searchString}`
    )
    return await res.json()
  }

  static postNarrative = async body => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/narrative`,
      null,
      body
    )
    return await res.json()
  }

  static getModelTypes = async () => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/narrative/modelTypes`
    )
    return await res.json()
  }

  static getModelTokens = async (narrativeId, contentId, contentType) => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/narrative/${narrativeId}/token-dictionary`,
      {
        contentId,
        contentType
      }
    )
    return await res.json()
  }

  static postTrigger = async (narrativeId, body) => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/narrative/${narrativeId}/trigger`,
      null,
      body
    )
    return await res.json()
  }

  static postNarrativeOutlineBlock = async body => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/narrativeoutlineblock`,
      null,
      body
    )
    return await res.json()
  }

  static postCopyNarrativeOutlineBlock = async body => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/narrativeoutlineblock/copy`,
      null,
      body
    )
    return await res.json()
  }

  static postDuplicateNarrativeOutlineBlock = async body => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/narrativeoutlineblock/duplicate`,
      null,
      body
    )
    return await res.json()
  }

  static getParagraph = async id => {
    const res = await get(`${globalSettings.apiBaseUrl}/api/paragraph/${id}`)
    return await res.json()
  }

  static getParagraphHistory = async id => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/paragraph/${id}/history`
    )
    return await res.json()
  }

  static postParagraph = async body => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/paragraph`,
      null,
      body
    )
    return await res.json()
  }

  static getSentence = async id => {
    const res = await get(`${globalSettings.apiBaseUrl}/api/sentence/${id}`)
    return await res.json()
  }

  static postSentence = async body => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/sentence`,
      null,
      body
    )
    return await res.json()
  }

  static postCopySentence = async body => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/sentence/copy`,
      null,
      body
    )
    return await res.json()
  }

  static postMoveSentence = async body => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/sentence/move`,
      null,
      body
    )
    return await res.json()
  }

  static postCopyParagraph = async body => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/paragraph/copy`,
      null,
      body
    )
    return await res.json()
  }

  static postArchiveParagraph = async paragraphId => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/paragraph/archive/${paragraphId}`,
      null
    )
    return await res.json()
  }

  static postArchiveParagraphAndSentences = async paragraphId => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/paragraph/archiveparagraphandsentences/${paragraphId}`,
      null
    )
    return await res.json()
  }

  static postArchiveSentencesUnderParagraph = async paragraphId => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/paragraph/archiveparagraphandsentences/${paragraphId}?archivejustsentences=true`,
      null
    )
    return await res.json()
  }

  static postArchiveSentence = async sentenceId => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/sentence/archive/${sentenceId}`,
      null
    )
    return await res.json()
  }

  static postCopyTrigger = async body => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/trigger/copy`,
      null,
      body
    )
    return await res.json()
  }

  static getArticleTypes = async () => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/narrative/articletypes/`
    )
    return await res.json()
  }
  static getDatasources = async () => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/narrative/datasources/`
    )
    return await res.json()
  }

  static getBookmakers = async () => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/narrative/bookmakers/`
    )
    return await res.json()
  }

  static getContentTypes = async () => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/narrative/contenttypes/`
    )
    return await res.json()
  }

  static getMissingNarrativeTriggers = async narrativeId => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/narrative/${narrativeId}/missingtriggers`
    )
    return await res.json()
  }

  static updateMissingNarrativeTriggers = async narrativeId => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/narrative/${narrativeId}/updatemissingtriggers`
    )
    return await res.json()
  }

  static updateContentBlockWithNewTrigger = async body => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/narrative/updatecontentwithnewtriggers`,
      null,
      body
    )
    return await res.json()
  }

  static getHealthCheckLogTable = async narrativeId => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/narrative/${narrativeId}/narrattivehealthlog`
    )
    return await res.json()
  }

  static getLibraryParagraphs = async (
    libraryId,
    includeVariationScores = false
  ) => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/narrative/${libraryId}/paragraphdrilldown?includeVariationScores=${includeVariationScores}`
    )
    return await res.json()
  }

  static getLibraryParagraphsBrief = async (
    libraryId,
    includeVariationScores = false
  ) => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/narrative/${libraryId}/paragraphbrief?includeVariationScores=${includeVariationScores}`
    )
    return await res.json()
  }

  static getVerticals = async () => {
    const res = await get(`${globalSettings.apiBaseUrl}/api/vertical`)
    return await res.json()
  }

  static getOutputAttributes = async () => {
    const res = await get(`${globalSettings.apiBaseUrl}/api/outputattribute`)
    return await res.json()
  }

  static getNarrativeParagraphTagsSummary = async libraryId => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/narrative/${libraryId}/narrativeparagraphtagssummary`
    )
    return await res.json()
  }

  static getNarrativeParagraphTags = async libraryId => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/narrative/${libraryId}/narrativeparagraphtags`
    )
    return await res.json()
  }

  static getStarters = async libraryNarrativeId => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/narrative/starter?narrativeId=${libraryNarrativeId}`
    )
    return await res.json()
  }

  static setStarterForNarrative = async body => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/narrative/starter`,
      null,
      body
    )
    return await res.json()
  }

  static getLibraryNarratives = async () => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/narrative?islibrary=true`
    )
    return await res.json()
  }

  static copyNarrative = async narrativeId => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/narrative/copy/${narrativeId} `
    )
    return await res.json()
  }
}
