// eslint-disable-next-line no-unused-vars
import React, { useState, useMemo, useEffect } from "react"

// 3rd Party Utils
import { useStore } from "contexts/rootContext"
import { useObserver } from "mobx-react"
import ReactTable from "react-table-6"
import { Link } from "react-router-dom"
import Helpers from "tools/Helpers.js"
import TimeAgo from "react-timeago"

// MUI Components
import {
  CircularProgress as MuiCircularProgress,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent as MuiDialogContent,
  DialogContentText,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails,
  Typography
} from "@mui/material"
import { ExpandMore, DirectionsBoat } from "@mui/icons-material"
import withStyles from "@mui/styles/withStyles"
import CardHeader from "components/Card/CardHeader.jsx"

// Custom Components
import Thermostat from "assets/icons/Thermostat"
import CardIcon from "components/Card/CardIcon.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import Button from "components/CustomButtons/Button.jsx"

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    getNarrativeMissingTriggers:
      store.narrativeStore.getNarrativeMissingTriggers,
    narrativeMissingTriggers: store.narrativeStore.narrativeMissingTriggers,
    openModal: store.narrativeStore.openModal,
    openLogModal: store.narrativeStore.openLogModal,
    setOpenModal: store.narrativeStore.setOpenModal,
    setOpenLogModal: store.narrativeStore.setOpenLogModal,
    narrativeMissingTriggersMetadata:
      store.narrativeStore.narrativeMissingTriggersMetadata,
    narrativeMissingTriggersUpdatedMetadata:
      store.narrativeStore.narrativeMissingTriggersUpdatedMetadata,
    narrativeMissingTriggersTableData:
      store.narrativeStore.narrativeMissingTriggersTableData,
    updateNarrativeMissingTriggers:
      store.narrativeStore.updateNarrativeMissingTriggers,
    healthCheckLogTableMetadata:
      store.narrativeStore.healthCheckLogTableMetadata,
    errorCode: store.narrativeStore.errorCode,
    healthCheckState: store.narrativeStore.healthCheckState,
    healthCheckLogState: store.narrativeStore.healthCheckLogState,
    setHealthCheckState: store.narrativeStore.setHealthCheckState,
    setHealthCheckLogState: store.narrativeStore.setHealthCheckLogState,
    getHealthCheckLogTable: store.narrativeStore.getHealthCheckLogTable
  }))
}

const Accordion = withStyles({
  root: {
    border: "none",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    }
  }
})(MuiAccordion)

const AccordionSummary = withStyles({
  content: {
    margin: "12px 0",
    "&.Mui-expanded": {
      margin: "12px 0"
    }
  }
})(MuiAccordionSummary)

const CircularProgress = withStyles({
  root: {
    display: "block",
    margin: "auto"
  }
})(MuiCircularProgress)

const Dialog = withStyles({
  paper: {
    overflow: "visible"
  }
})(MuiDialog)

const DialogContent = withStyles({
  root: {
    minHeight: "12rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
})(MuiDialogContent)

function TriggerIdSync(props) {
  const [openAccordion, setOpenAccordion] = useState(false)
  const [openHealthTable, setOpenHealthTable] = useState(false)
  const [healthLogEmpty, setHealthLogEmpty] = useState(false)
  const { narrativeId } = props

  const {
    getNarrativeMissingTriggers,
    getHealthCheckLogTable,
    narrativeMissingTriggersMetadata,
    healthCheckLogTableMetadata,
    narrativeMissingTriggersUpdatedMetadata,
    openModal,
    setOpenModal,
    narrativeMissingTriggersTableData,
    updateNarrativeMissingTriggers,
    errorCode,
    healthCheckState,
    setHealthCheckState,
    healthCheckLogState
  } = useStoreData()

  React.useEffect(() => {
    if (
      openHealthTable === true &&
      healthCheckLogState === "checked" &&
      healthCheckLogTableMetadata?.length === 0
    ) {
      setHealthLogEmpty(true)
    } else if (healthCheckLogState !== "checked" || openHealthTable !== true) {
      setHealthLogEmpty(false)
    }
  }, [healthCheckLogState])

  const columns = [
    {
      Header: "Paragraph",
      accessor: "paragraphId",
      Cell: ({ row }) => (
        <Link
          style={{ color: "#144D69", textDecoration: "underline" }}
          to={`/portal/narrative/${narrativeId}/edit?view=paragraphid-${row.paragraphId}`}
        >
          {row.paragraphId}
        </Link>
      ),
      width: 110
    },
    {
      Header: "Sentence",
      accessor: "sentenceId",
      Cell: ({ row }) => (
        <Link
          style={{ color: "#144D69", textDecoration: "underline" }}
          to={`/portal/narrative/${narrativeId}/edit?view=sentenceid-${row.sentenceId}`}
        >
          {row.sentenceId}
        </Link>
      ),
      width: 110
    },
    {
      Header: "Trigger Type",
      accessor: "triggerType"
    }
  ]

  const generateErrorComponent = code => {
    switch (code) {
      case 504:
        return (
          <>
            <Typography
              variant="body1"
              color="error"
              style={{ fontSize: "2rem" }}
            >
              Trigger ID sync has timed out.
            </Typography>
            <Typography
              variant="body1"
              color="error"
              style={{ fontSize: "1.5rem" }}
            >
              Please restart the sync process to confirm completion.
            </Typography>
          </>
        )
      default:
        return (
          <>
            <Typography
              variant="body1"
              color="error"
              style={{ fontSize: "2rem" }}
            >
              There was an error with the Trigger ID sync process.
            </Typography>
            <Typography
              variant="body1"
              color="error"
              style={{ fontSize: "1.5rem" }}
            >
              Please try again.
            </Typography>
          </>
        )
    }
  }

  let healthLogResults = []
  healthCheckLogTableMetadata &&
    healthCheckLogTableMetadata
      .slice()
      .reverse()
      .forEach(itm => {
        if (itm.narrative_Id !== narrativeId) {
          return
        }
        let description = itm.description || ""
        let columnContent = {}
        let healthCheckColumns = description.split(/\n/)
        healthCheckColumns.forEach((itm, index) => {
          let tmp = itm.split(":")
          columnContent[
            tmp[0] && tmp[0].trim() ? tmp[0].replace(/\s|:/g, "") : index
          ] = tmp[1] && tmp[1].trim() ? tmp[1].trim().replace(/\./g, "") : index
        })
        columnContent["syncDate"] = itm.syncDate
        columnContent["syncedBy"] = itm.syncedBy
        healthLogResults.push(columnContent)
      })

  return (
    <>
      <Card>
        <Accordion
          onChange={() => setOpenAccordion(!openAccordion)}
          expanded={openAccordion}
        >
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Thermostat />
            </CardIcon>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <h4 className={props.classes}>Trigger IDs</h4>
            </AccordionSummary>
          </CardHeader>
          <CardBody>
            <AccordionDetails>
              {healthCheckState === "loading" && !openModal && (
                <CircularProgress />
              )}
              {narrativeMissingTriggersTableData !== undefined &&
                narrativeMissingTriggersTableData.length > 0 && (
                  <ReactTable
                    columns={columns}
                    data={narrativeMissingTriggersTableData}
                    minRows={0}
                    className="-striped -highlight -compact"
                    showPaginationTop
                    showPaginationBottom={false}
                    defaultSorted={[
                      {
                        id: "paragraphId",
                        desc: false
                      }
                    ]}
                  />
                )}
              {narrativeMissingTriggersTableData !== undefined &&
                narrativeMissingTriggersTableData.length === 0 && (
                  <Typography
                    variant="body1"
                    style={{ margin: "auto", fontWeight: "bold" }}
                  >
                    All Triggers Synced
                  </Typography>
                )}
            </AccordionDetails>
          </CardBody>
        </Accordion>
        <CardBody>
          <Button
            size="sm"
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenAccordion(true)
              setHealthLogEmpty(false)
              getNarrativeMissingTriggers(narrativeId)
            }}
          >
            Sync Trigger IDs
          </Button>
        </CardBody>
        <CardBody>
          <Button
            size="sm"
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenHealthTable(true)
              getHealthCheckLogTable(narrativeId)
            }}
          >
            Display Health Check Log
          </Button>
          {healthLogResults.length > 0 && (
            <ReactTable
              data={healthLogResults}
              columns={[
                {
                  Header: () => (
                    <div style={{ textAlign: "center", lineHeight: "normal" }}>
                      Date
                    </div>
                  ),
                  accessor: "syncDate",
                  Cell: cell => (
                    <div style={{ textAlign: "center" }}>
                      Created{" "}
                      <TimeAgo
                        date={cell.value}
                        title={Helpers.prettyDateTimeinPacificTimeZone(
                          cell.value
                        )}
                      />
                    </div>
                  ),
                  width: 200
                },
                {
                  Header: () => (
                    <div style={{ textAlign: "center", lineHeight: "normal" }}>
                      Modified By
                    </div>
                  ),
                  accessor: "syncedBy",
                  Cell: cell => (
                    <div style={{ textAlign: "center" }}>{cell.value}</div>
                  ),
                  width: 150
                },
                {
                  Header: () => (
                    <div style={{ textAlign: "center", lineHeight: "normal" }}>
                      Paragraphs Synced
                    </div>
                  ),
                  accessor: "Thenumberofparagraphssynced",
                  Cell: cell => (
                    <div style={{ textAlign: "center" }}>{cell.value}</div>
                  ),
                  width: 150
                },
                {
                  Header: () => (
                    <div style={{ textAlign: "center", lineHeight: "normal" }}>
                      Paragraphs w/Sentences Synced
                    </div>
                  ),
                  accessor: "Thenumberofparagraphswithsentencestobesynced",
                  Cell: cell => (
                    <div style={{ textAlign: "center" }}>{cell.value}</div>
                  ),
                  width: 150
                },
                {
                  Header: () => (
                    <div style={{ textAlign: "center", lineHeight: "normal" }}>
                      Sentences Synced
                    </div>
                  ),
                  accessor: "Thenumbersentencessynced",
                  Cell: cell => (
                    <div style={{ textAlign: "center" }}>{cell.value}</div>
                  ),
                  width: 150
                },
                {
                  Header: () => (
                    <div style={{ textAlign: "center", lineHeight: "normal" }}>
                      Paragraphs Unsynced
                    </div>
                  ),
                  accessor: "Thenumberofparagraphsunsynced",
                  Cell: cell => (
                    <div style={{ textAlign: "center" }}>{cell.value}</div>
                  ),
                  width: 150
                },
                {
                  Header: () => (
                    <div style={{ textAlign: "center", lineHeight: "normal" }}>
                      Paragraphs w/Sentences Unsynced
                    </div>
                  ),
                  accessor: "Thenumberofparagraphswithsentencesunsynced",
                  Cell: cell => (
                    <div style={{ textAlign: "center" }}>{cell.value}</div>
                  ),
                  width: 150
                },
                {
                  Header: () => (
                    <div style={{ textAlign: "center", lineHeight: "normal" }}>
                      Sentences Unsynced
                    </div>
                  ),
                  accessor: "Thenumberofsentencesunsynced",
                  Cell: cell => (
                    <div style={{ textAlign: "center" }}>{cell.value}</div>
                  ),
                  width: 150
                }
              ]}
              filterable={false}
              defaultPageSize={5}
              showPaginationTop={true}
              showPaginationBottom={false}
              className="-striped -highlight"
              defaultSorted={[
                {
                  id: "id",
                  desc: false
                }
              ]}
              style={{
                marginTop: "15px",
                height: `${275 + 30 * healthLogResults.length}px` // This will force the table body to overflow and scroll, since there is not enough room
              }}
            />
          )}

          {healthLogEmpty && (
            <div
              style={{
                textAlign: "center"
              }}
            >
              <Typography
                variant="body1"
                style={{ margin: "auto", fontWeight: "bold" }}
              >
                No Trigger ID sync to display
              </Typography>
            </div>
          )}
        </CardBody>
      </Card>
      <Dialog
        style={{ overflow: "visible" }}
        open={openModal}
        onClose={(e, reason) => {
          if (reason === "escapeKeyDown" || reason === "backdropClick") {
            return
          }
          setOpenModal(false)
        }}
        fullWidth={true}
        maxWidth={"md"}
        aria-labelledby="Missing Triggers Dialog Box"
        aria-describedby="Dialog box showing sentences and paragraphs that are missing trigger IDs."
      >
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <Thermostat />
          </CardIcon>
        </CardHeader>
        <DialogContent>
          <DialogContentText>
            {healthCheckState === "loading" && <CircularProgress />}
            {healthCheckState === "checked" &&
              narrativeMissingTriggersMetadata.paragraphMissingTriggersCount ===
                0 &&
              narrativeMissingTriggersMetadata.paragraphSentenceMissingTriggersCount ===
                0 &&
              narrativeMissingTriggersMetadata.sentenceMissingTriggersCount ===
                0 && (
                <Typography
                  variant="h6"
                  style={{ textTransform: "none" }}
                  gutterBottom
                >
                  <DirectionsBoat style={{ margin: "1rem" }} />
                  Nothing to sync.
                </Typography>
              )}
            {healthCheckState === "checked" &&
              (narrativeMissingTriggersMetadata.paragraphMissingTriggersCount >
                0 ||
                narrativeMissingTriggersMetadata.paragraphSentenceMissingTriggersCount >
                  0 ||
                narrativeMissingTriggersMetadata.sentenceMissingTriggersCount >
                  0) && (
                <>
                  <Typography
                    variant="h6"
                    style={{ textTransform: "none" }}
                    gutterBottom
                  >
                    Would you like to sync with Trigger IDs now?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {narrativeMissingTriggersMetadata !== undefined &&
                      narrativeMissingTriggersMetadata.paragraphMissingTriggersCount}{" "}
                    paragraphs.
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {narrativeMissingTriggersMetadata !== undefined &&
                      narrativeMissingTriggersMetadata.paragraphSentenceMissingTriggersCount}{" "}
                    paragraphs that need to have sentences updated.
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {narrativeMissingTriggersMetadata !== undefined &&
                      narrativeMissingTriggersMetadata.sentenceMissingTriggersCount}{" "}
                    sentences.
                  </Typography>
                </>
              )}
            {healthCheckState === "updated" && (
              <>
                <Typography
                  variant="h6"
                  color="primary"
                  style={{ textTransform: "none" }}
                  gutterBottom
                >
                  You have successfully synced the following with Trigger IDs:
                </Typography>
                <Typography variant="body1" color="primary" gutterBottom>
                  {narrativeMissingTriggersUpdatedMetadata !== undefined &&
                    narrativeMissingTriggersUpdatedMetadata.paragraphUpdatedMissingTriggersCount}{" "}
                  paragraphs.
                </Typography>
                <Typography variant="body1" color="primary" gutterBottom>
                  {narrativeMissingTriggersUpdatedMetadata !== undefined &&
                    narrativeMissingTriggersUpdatedMetadata.paragraphUpdatedSentenceMissingTriggersCount}{" "}
                  paragraphs with sentences updated.
                </Typography>
                <Typography variant="body1" color="primary" gutterBottom>
                  {narrativeMissingTriggersUpdatedMetadata !== undefined &&
                    narrativeMissingTriggersUpdatedMetadata.sentenceUpdatedMissingTriggersCount}{" "}
                  sentences.
                </Typography>
                <Typography
                  variant="h6"
                  color="error"
                  style={{ textTransform: "none" }}
                  gutterBottom
                >
                  The following were unable to be synced with Trigger IDs:
                </Typography>
                <Typography variant="body1" color="error" gutterBottom>
                  {narrativeMissingTriggersUpdatedMetadata !== undefined &&
                    narrativeMissingTriggersUpdatedMetadata.paragraphMissingTriggersCount}{" "}
                  paragraphs.
                </Typography>
                <Typography variant="body1" color="error" gutterBottom>
                  {narrativeMissingTriggersUpdatedMetadata !== undefined &&
                    narrativeMissingTriggersUpdatedMetadata.paragraphSentenceMissingTriggersCount}{" "}
                  paragraphs that need to have sentences updated.
                </Typography>
                <Typography variant="body1" color="error" gutterBottom>
                  {narrativeMissingTriggersUpdatedMetadata !== undefined &&
                    narrativeMissingTriggersUpdatedMetadata.sentenceMissingTriggersCount}{" "}
                  sentences.
                </Typography>
              </>
            )}
            {healthCheckState === "error" && generateErrorComponent(errorCode)}
          </DialogContentText>
        </DialogContent>
        {healthCheckState === "error" && (
          <DialogActions>
            <Button
              onClick={() => {
                setOpenModal(false)
                setOpenAccordion(false)
                setHealthCheckState("start")
              }}
            >
              Ok
            </Button>
          </DialogActions>
        )}
        {healthCheckState === "checked" &&
          narrativeMissingTriggersMetadata.paragraphMissingTriggersCount ===
            0 &&
          narrativeMissingTriggersMetadata.paragraphSentenceMissingTriggersCount ===
            0 &&
          narrativeMissingTriggersMetadata.sentenceMissingTriggersCount ===
            0 && (
            <DialogActions>
              <Button
                onClick={() => {
                  setOpenModal(false)
                  setOpenAccordion(false)
                  setHealthCheckState("start")
                }}
              >
                Ok
              </Button>
            </DialogActions>
          )}
        {healthCheckState === "checked" &&
          (narrativeMissingTriggersMetadata.paragraphMissingTriggersCount > 0 ||
            narrativeMissingTriggersMetadata.paragraphSentenceMissingTriggersCount >
              0 ||
            narrativeMissingTriggersMetadata.sentenceMissingTriggersCount >
              0) && (
            <DialogActions>
              <Button
                onClick={() => updateNarrativeMissingTriggers(narrativeId)}
              >
                Yes
              </Button>
              <Button
                color="info"
                onClick={() => {
                  setOpenModal(false)
                  setOpenAccordion(false)
                  setHealthCheckState("start")
                }}
              >
                No
              </Button>
            </DialogActions>
          )}
        {healthCheckState === "updated" && (
          <DialogActions>
            <Button
              onClick={() => {
                setOpenModal(false)
                props.reloadNarrative()
              }}
            >
              Ok
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  )
}

export default TriggerIdSync
