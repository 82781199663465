import makeStyles from "@mui/styles/makeStyles"

const useFilterPanelStyles = makeStyles({
  box: {
    backgroundColor: "#DDEAEF",
    padding: 20
  },
  grid: {
    textAlign: "end"
  },
  input: {
    backgroundColor: "#FFF"
  },
  notchedOutline: {
    "&.MuiInputLabel-shrink": {
      transform: "translate(0px, -15px) scale(0.75);"
    }
  },
  chip: {
    margin: "0 3px"
  },
  buttonText: {
    fontWeight: "bold"
  },
  multiSelectListboxActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "baseline",
    "& > a": {
      cursor: "pointer",
      margin: "5px 5px 0px 5px",
      color: "#144d69"
    }
  },
  textfield: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#144d69"
    },
    "& .MuiInputLabel-outlined": {
      color: "#144d69"
    }
  },
  anchorOriginTopRightCircular: {
    minWidth: 15,
    height: 15,
    padding: 0,
    fontSize: ".6rem"
  }
})

export { useFilterPanelStyles }
