import { createContext } from "react"

export const EMPTY_ITEM = {
  id: null,
  type: null
}

export const NarrativeMenuActionsContext = createContext({
  selectedSourceItem: { ...EMPTY_ITEM },
  setSelectedSourceItem: () => null,
  selectedSourceParentItem: { ...EMPTY_ITEM },
  setSelectedSourceParentItem: () => null,
  targetItem: { ...EMPTY_ITEM },
  setTargetItem: () => null,
  lastActionType: null,
  setLastActionType: () => null,
  ableToPasteSentence: () => false,
  ableToPasteParagraph: () => false,
  handlePasteSentence: () => null,
  handlePasteParagraph: () => null,
  wasParagraphCutSelected: () => false,
  wasSentenceCutSelected: () => false
})
