export const multiSelectFilterFunction = (row, columnId, filterValue) => {
  const rowValue = row.getValue(columnId)
  let found = true

  if (filterValue.length === 0) {
    return found
  }

  const index = filterValue.findIndex(value => value.value === rowValue)

  if (index === -1) {
    found = false
  }

  return found
}

export const dateRangeFilterFunction = (row, columnId, filterValue) => {
  const rowValue = row.getValue(columnId)

  if (filterValue === "All") {
    return true
  }

  const now = new Date()
  const futureTime = new Date()
  const pastTime = new Date()
  const dateValue = new Date(rowValue)

  if (filterValue === "Future") {
    if (dateValue >= now) {
      return true
    }
  }

  if (filterValue === "Past") {
    if (dateValue < now) {
      return true
    }
  }

  if (filterValue === "Next 7 Days") {
    if (
      dateValue > now &&
      dateValue < futureTime.setHours(futureTime.getHours() + 7 * 24)
    ) {
      return true
    }
  }

  if (filterValue === "Next 24 Hours") {
    if (
      dateValue > now &&
      dateValue < futureTime.setHours(futureTime.getHours() + 48)
    ) {
      return true
    }
  }

  if (filterValue === "Next 1 Hour") {
    if (
      dateValue > now &&
      dateValue < futureTime.setHours(futureTime.getHours() + 2)
    ) {
      return true
    }
  }

  if (filterValue === "Within 7 Days") {
    if (
      dateValue > pastTime.setHours(pastTime.getHours() - 7 * 24) &&
      dateValue < futureTime.setHours(futureTime.getHours() + 7 * 24)
    ) {
      return true
    }
  }

  if (filterValue === "Within 24 Hours") {
    if (
      dateValue > pastTime.setHours(pastTime.getHours() - 24) &&
      dateValue < futureTime.setHours(futureTime.getHours() + 48)
    ) {
      return true
    }
  }

  if (filterValue === "Within 1 Hour") {
    if (
      dateValue > pastTime.setHours(pastTime.getHours() - 1) &&
      dateValue < futureTime.setHours(futureTime.getHours() + 2)
    ) {
      return true
    }
  }

  if (filterValue === "Past 7 Days") {
    if (
      dateValue > pastTime.setHours(pastTime.getHours() - 7 * 24) &&
      dateValue < now
    ) {
      return true
    }
  }

  if (filterValue === "Past 24 Hours") {
    if (
      dateValue > pastTime.setHours(pastTime.getHours() - 24) &&
      dateValue < now
    ) {
      return true
    }
  }

  if (filterValue === "Past 1 Hour") {
    if (
      dateValue > pastTime.setHours(pastTime.getHours() - 1) &&
      dateValue < now
    ) {
      return true
    }
  }

  return false
}
