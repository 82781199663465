import React from "react"
import PropTypes from "prop-types"
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material"

class StatusType extends React.Component {
  render() {
    return (
      <FormControl margin="normal" fullWidth>
        <InputLabel>Status</InputLabel>
        <Select
          type="select"
          name="StatusTypeinput"
          id="StatusType"
          label="Status"
          disabled={this.props.disabled}
          value={this.props.value || ""}
          onChange={e => {
            if (
              this.props.isLibrary &&
              !this.props.paragraphType &&
              e.target.value === 3
            ) {
              e.preventDefault()
              //You can only Publish from inside a Library
              alert("You can only publish if you have a Primary Tag")
            } else {
              this.props.onChange(e.target.value)
              e.preventDefault()
            }
          }}
          //selectedValue={this.props.value}
        >
          <MenuItem
            classes={{ selected: this.props.classes?.selected }}
            value=""
          >
            Select
          </MenuItem>
          <MenuItem
            classes={{ selected: this.props.classes?.selected }}
            value={1}
          >
            Draft
          </MenuItem>
          <MenuItem
            classes={{ selected: this.props.classes?.selected }}
            value={2}
          >
            Review
          </MenuItem>
          <MenuItem
            classes={{ selected: this.props.classes?.selected }}
            value={3}
          >
            Published
          </MenuItem>
          <MenuItem
            classes={{ selected: this.props.classes?.selected }}
            value={4}
          >
            Planned
          </MenuItem>
          <MenuItem
            classes={{ selected: this.props.classes?.selected }}
            value={5}
          >
            Sample
          </MenuItem>
        </Select>
      </FormControl>
    )
  }
}

StatusType.propTypes = {
  // Where the user to be redirected on clicking the avatar
  onChange: PropTypes.func,
  value: PropTypes.number,
  disabled: PropTypes.bool,
  isLibrary: PropTypes.bool,
  paragraphType: PropTypes.string
}

export default StatusType
