import { Link } from "react-router-dom"
import Edit from "@mui/icons-material/Edit"
import IconButton from "@mui/material/IconButton"

// Could use this in many places if desired, such as other narrative components
// or anything that wants to link to edit a narrative rendering a pencil icon

const NarrativeEditLink = ({ narrativeId }) =>
  narrativeId ? (
    <Link
      to={`/portal/narrative/${narrativeId}/edit`}
      title="View Output"
      target="_blank"
    >
      <IconButton color="primary" className="info" size="small">
        <Edit fontSize="small" />
      </IconButton>
    </Link>
  ) : null

export default NarrativeEditLink
