// eslint-disable-next-line no-unused-vars
import React from "react"
import PropTypes from "prop-types"
import {
  IconButton,
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogTitle,
  DialogContent
} from "@mui/material"
import { Close, DoneRounded } from "@mui/icons-material"
import classnames from "classnames"

import Loading from "components/Loading"
import AuditInfo from "components/AuditInfo"
import Confirmation from "./Dialog/Confirmation"
import "assets/css/Components/Dialog.css"

const defaultProps = {
  disableOuterCancel: false,
  fullWidth: true,
  isOpen: false,
  loading: false,
  isConfirmation: false,
  maxWidth: "lg"
}

const propTypes = {
  auditInfo: PropTypes.object,
  children: PropTypes.node.isRequired,
  disableOuterCancel: PropTypes.bool,
  isOnlyClosebleByClickingX: PropTypes.bool,
  fullWidth: PropTypes.bool,
  icon: PropTypes.node,
  isOpen: PropTypes.bool,
  loading: PropTypes.bool,
  maxWidth: PropTypes.oneOf(["lg", "md", "sm"]),
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string.isRequired,
  isConfirmation: PropTypes.bool
}

const Dialog = ({
  auditInfo,
  children,
  disableOuterCancel,
  isOnlyClosebleByClickingX,
  fullWidth,
  icon,
  isOpen,
  loading,
  maxWidth,
  onClose,
  onConfirm,
  isConfirmation,
  title
}) => (
  <MuiDialog
    className={classnames("dialog", { "dialog--confirm": isConfirmation })}
    maxWidth={maxWidth}
    fullWidth={fullWidth}
    //disableBackdropClick={disableOuterCancel}
    disableEscapeKeyDown={disableOuterCancel}
    open={isOpen}
    onClose={() => {
      if (!isOnlyClosebleByClickingX) {
        onClose()
      }
    }}
  >
    {onClose && (
      <IconButton
        className="dialog__close"
        aria-label="close"
        onClick={onClose}
        size="large"
      >
        <Close />
      </IconButton>
    )}

    <div className="dialog__header">
      {!isConfirmation && <Loading active={loading} />}
      {!isConfirmation && icon && <div className="dialog__icon">{icon}</div>}
      {isConfirmation && (
        <DoneRounded className="dialog__icon--done" color="primary" />
      )}
      <DialogTitle className="dialog__title">{title}</DialogTitle>
      {!isConfirmation && auditInfo && <AuditInfo muted {...auditInfo} />}
    </div>

    <DialogContent className="dialog__content">{children}</DialogContent>

    <DialogActions className="dialog__actions">
      {onConfirm && (
        <Button onClick={onConfirm} color="primary">
          Confirm
        </Button>
      )}
    </DialogActions>
  </MuiDialog>
)

Dialog.defaultProps = defaultProps
Dialog.propTypes = propTypes

export { Dialog, Confirmation }
export default Dialog
