import { object, string, number, array, mixed } from "yup"

const account = object().shape({
  firstName: string().required("First name is required"),
  lastName: string().required("Last name is required"),
  username: string().required("Username is required"),
  permissionGroups: array().required("Permission Groups are required"),
  organizationId: number().required("Organization is required"),
  email: string()
    .email("Email must be a valid email")
    .required("Email is required")
})

const permissionGroup = object().shape({
  name: string().required("Name is required"),
  description: string().required("Description is required"),
  permissions: array()
})

const narrative = object().shape({
  name: string().required("Name is required"),
  parentNarrativeId: number(),
  organizationId: number().required("Organization is required"),
  leagueId: mixed().required("League is required"),
  narrativeOutputAttribute: array()
    .min(1, "Output Attribute is required")
    .required("Output Attribute(s) is required"),
  description: string()
})

const narrativeLibrary = object().shape({
  name: string().required("Name is required"),
  parentNarrativeId: number(),
  organizationId: number().required("Organization is required"),
  leagueId: mixed().required("League is required"),
  description: string()
})

const narrativeNoLeague = object().shape({
  name: string().required("Name is required"),
  parentNarrativeId: number(),
  organizationId: number().required("Organization is required"),
  narrativeOutputAttribute: array()
    .min(1, "Output Attribute is required")
    .required("Output Attribute(s) is required"),
  description: string()
})

const narrativeLibraryNoLeague = object().shape({
  name: string().required("Name is required"),
  parentNarrativeId: number(),
  organizationId: number().required("Organization is required"),
  description: string()
})

const snippetForm = object().shape({
  name: string().required("Name is required"),
  description: string().required("Description is required"),
  key: string().required("Function Key is required"),
  snippettype: string().required("Snippet Type is required"),
  library: string().required("Function Key is required"),
  template: string().required("Snippet Template is required"),
  sequence: number()
})

export {
  account,
  permissionGroup,
  narrative,
  narrativeLibrary,
  narrativeNoLeague,
  narrativeLibraryNoLeague,
  snippetForm
}
