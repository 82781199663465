import { useEffect, useState, Fragment } from "react"
import PropTypes from "prop-types"

// MUI Components
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Skeleton from "@mui/material/Skeleton"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Typography from "@mui/material/Typography"

// MUI Icons
import { ArrowDropUp } from "@mui/icons-material"
import { useCustomTableStyles } from "./styles/CustomTable.style"

// React Table
import { flexRender } from "@tanstack/react-table"

// Custom Components
import FilterPanel from "./components/FilterPanel"
import FilterChips from "./components/FilterChips"
import FilterButton from "./components/FilterButton"

// Utils
import useTableFilters from "hooks/table/useTableFilters"
import useTableSetupHelper from "hooks/table/useTableSetupHelper"

const SkeletonWave = params => <Skeleton {...params} animation="wave" />

const CustomTable = ({
  tableName = "",
  tableData = [],
  availableFilters = [],
  tableColumns = [],
  initialTableState = {},
  minWidth = 650,
  stickyHeader = false,
  alternateRowColor = false,
  paginated = false,
  sorted = false,
  isLoading = false,
  filtered = false,
  expanded = false,
  enableRowSelection = false,
  tableSize = "medium",
  renderSubComponent,
  filterPanelOpen
}) => {
  const [filterPanelOpenState, setFilterPanelOpenState] =
    useState(filterPanelOpen)

  const {
    activeFilters,
    filterCount,
    tableActiveFilters,
    handleFilterChange,
    handleFilterDelete,
    handleFilterClear,
    handleDeleteAll,
    handleSelectAll
  } = useTableFilters({ tableName, persistFilters: true })

  const {
    tableBox,
    tableStyle,
    tableHead,
    rowStyle,
    asc,
    paginationSkeleton,
    noDataBox,
    noDataText
  } = useCustomTableStyles(minWidth)

  const table = useTableSetupHelper({
    tableData,
    tableColumns,
    initialTableState,
    filtered,
    paginated,
    sorted,
    expanded,
    enableRowSelection
  })

  useEffect(() => {
    table.setColumnFilters(tableActiveFilters)
  }, [activeFilters])

  return (
    <>
      {tableData.length === 0 ? (
        <Box classes={{ root: noDataBox }}>
          <Typography classes={{ root: noDataText }}>
            No {tableName} data found.
          </Typography>
        </Box>
      ) : (
        <Box classes={{ root: tableBox }}>
          {filtered && (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginBottom: "1rem"
              }}
            >
              <FilterButton
                filterCount={filterCount}
                onClick={() => setFilterPanelOpenState(!filterPanelOpenState)}
              />
            </div>
          )}
          {filtered && (
            <FilterPanel
              open={filterPanelOpenState}
              table={table}
              filters={availableFilters}
              activeFilters={activeFilters}
              handleFilterChange={handleFilterChange}
              handleFilterClear={handleFilterClear}
              handleSelectAll={handleSelectAll}
              handleDeleteAll={handleDeleteAll}
            />
          )}
          <Grid container alignItems="flex-end">
            <Grid item sm={12} md={7}>
              {!filtered && (
                <FilterChips
                  table={table}
                  activeFilters={activeFilters}
                  handleFilterClear={handleFilterClear}
                  handleFilterDelete={handleFilterDelete}
                />
              )}
            </Grid>
            <Grid item sm={12} md={5}>
              {paginated && (
                <TablePagination
                  classes={{ root: paginationSkeleton }}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  count={Object.keys(table.getRowModel().rowsById).length}
                  rowsPerPage={table.getState().pagination.pageSize}
                  page={table.getState().pagination.pageIndex}
                  SelectProps={{
                    SelectDisplayProps: {
                      "data-testid": "pagination-button"
                    },
                    inputProps: { "data-testid": "pagination-input" }
                  }}
                  onPageChange={(e, page) => {
                    table.setPageIndex(page)
                  }}
                  onRowsPerPageChange={e => {
                    table.setPageSize(e.target.value)
                  }}
                  component={isLoading ? SkeletonWave : "div"}
                />
              )}
            </Grid>
          </Grid>
          <TableContainer>
            <Table
              classes={{ root: tableStyle }}
              stickyHeader={stickyHeader}
              size={tableSize}
            >
              <TableHead>
                {table.getHeaderGroups().map(headerGroup => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <TableCell
                        style={{
                          width: header.column.columnDef.width ?? "initial"
                        }}
                        classes={{ head: tableHead }}
                        key={header.id}
                        sortDirection={header.column.getIsSorted()}
                        {...{
                          onClick: () => header.column.getToggleSortingHandler()
                        }}
                      >
                        {isLoading ? (
                          <SkeletonWave />
                        ) : (
                          <>
                            {header.isPlaceholder ? null : header.column.getCanSort() ? (
                              <TableSortLabel
                                classes={{
                                  iconDirectionAsc: asc
                                }}
                                active={
                                  table.getState().sorting[0]?.id === header?.id
                                }
                                direction={header.column.getIsSorted() || "asc"}
                                IconComponent={ArrowDropUp}
                                {...{
                                  onClick:
                                    header.column.getToggleSortingHandler()
                                }}
                                data-testid={"sort"}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                              </TableSortLabel>
                            ) : (
                              flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )
                            )}
                          </>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {table.getRowModel().rows.map(row => (
                  <Fragment key={row.id}>
                    <TableRow
                      data-testid="row"
                      className={`${alternateRowColor && rowStyle}`}
                    >
                      {row.getVisibleCells().map(cell => (
                        <TableCell key={cell.id} data-testid="row-cell">
                          {isLoading ? (
                            <SkeletonWave />
                          ) : (
                            flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                    {row.getIsExpanded() && (
                      <TableRow data-testid="subrow">
                        <TableCell colSpan={row.getVisibleCells().length}>
                          {renderSubComponent({ row })}
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {paginated && (
            <TablePagination
              classes={{ root: paginationSkeleton }}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              count={Object.keys(table.getRowModel().rowsById).length}
              rowsPerPage={table.getState().pagination.pageSize}
              page={table.getState().pagination.pageIndex}
              SelectProps={{
                SelectDisplayProps: {
                  "data-testid": "pagination-button"
                },
                inputProps: { "data-testid": "pagination-input" }
              }}
              onPageChange={(e, page) => {
                table.setPageIndex(page)
              }}
              onRowsPerPageChange={e => {
                table.setPageSize(e.target.value)
              }}
              component={isLoading ? SkeletonWave : "div"}
            />
          )}
        </Box>
      )}
    </>
  )
}

export const customTablePropTypes = {
  tableName: PropTypes.string.isRequired,
  tableData: PropTypes.array.isRequired,
  tableColumns: PropTypes.array.isRequired,
  availableFilters: PropTypes.array,
  initialTableState: PropTypes.object,
  stickyHeader: PropTypes.bool,
  alternateRowColor: PropTypes.bool,
  paginated: PropTypes.bool,
  sorted: PropTypes.bool,
  filtered: PropTypes.bool,
  isLoading: PropTypes.bool,
  renderSubComponent: PropTypes.func
}

CustomTable.propTypes = { ...customTablePropTypes }

export default CustomTable
