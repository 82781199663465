// 3rd Party Imports
import React, { useState, useEffect } from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import ReactTable from "react-table-6"
import Assignment from "@mui/icons-material/Assignment"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon"
import CardHeader from "components/Card/CardHeader"
import { globalSettings } from "variables/general"
import Helpers from "tools/Helpers.js"
import CircularProgress from "@mui/material/CircularProgress"
import matchSorter from "match-sorter"

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    geographicPlacesList: store.graphicsToolStore.geographicPlacesList,
    retrieveGeographicPlaces: store.graphicsToolStore.retrieveGeographicPlaces,
    isLoading: store.graphicsToolStore.isLoading
  }))
}

// React Functional Component Declaration
const ManageGeographicPlaces = () => {
  const { geographicPlacesList, retrieveGeographicPlaces, isLoading } =
    useStoreData()
  // Variable/State Declaration
  const [imageIssues, setImageIssues] = useState()

  // Internal Function Declarations
  const stringFilterProps = {
    filterable: true,
    filterAll: true,
    filterMethod: ({ id, value }, rows) =>
      matchSorter(rows, value, {
        keys: [id],
        threshold: matchSorter.rankings.CONTAINS
      })
  }

  useEffect(() => {
    retrieveGeographicPlaces()
  }, [])

  // Component Render
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <ReactTable
              minRows={0}
              data={geographicPlacesList}
              loading={isLoading}
              noDataText={
                isLoading ? <CircularProgress /> : "No geographic places found."
              }
              columns={[
                {
                  Header: "Geo Name Id",
                  width: 140,
                  accessor: "geoNameId",
                  Cell: cell => <div>{cell.original.geoNameId}</div>,
                  ...stringFilterProps
                },
                {
                  Header: "Geographic Place",
                  width: 250,
                  accessor: "geographicDisplayName",
                  Cell: cell => (
                    <div>{cell.original.geographicDisplayName}</div>
                  ),
                  ...stringFilterProps
                },
                {
                  Header: "Group",
                  width: 300,
                  accessor: "groupingId",
                  Cell: cell => (
                    <div>
                      {Helpers.renderFriendlyGeographicPlaceGroupName(
                        cell.original.contentType
                      )}
                    </div>
                  ),
                  filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                      return true
                    }
                    return row._original.contentType === parseInt(filter.value)
                  },
                  Filter: ({ filter, onChange }) => {
                    if (geographicPlacesList === null) {
                      return null
                    }
                    const distinctGroupNames = []
                    const map = new Map()
                    for (const geographicPlace of geographicPlacesList) {
                      if (!map.has(geographicPlace.contentType)) {
                        map.set(geographicPlace.contentType, true)
                        distinctGroupNames.push({
                          key: geographicPlace.contentType,
                          id: geographicPlace.contentType,
                          name: Helpers.renderFriendlyGeographicPlaceGroupName(
                            geographicPlace.contentType
                          )
                        })
                      }
                    }
                    // inline sort by org name
                    distinctGroupNames.sort((a, b) => {
                      const orgA = a.name.toUpperCase()
                      const orgB = b.name.toUpperCase()
                      let comparison = 0
                      if (orgA > orgB) {
                        comparison = 1
                      } else if (orgA < orgB) {
                        comparison = -1
                      }
                      return comparison
                    })
                    // build up the <select> element <option> element JSX style
                    let options = []
                    distinctGroupNames.forEach(groupName => {
                      options.push(
                        <option value={groupName.id} key={groupName.id}>
                          {groupName.name}
                        </option>
                      )
                    })
                    return (
                      <select
                        onChange={event => {
                          onChange(event.target.value)
                        }}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "all"}
                      >
                        <option value="all">Show All</option>
                        {options}
                      </select>
                    )
                  }
                },
                {
                  Header: s => (
                    <div>
                      Primary logo
                      <br />
                      <small>(dark background)</small>
                    </div>
                  ),
                  Cell: cell => (
                    <div>
                      <img
                        src={`${globalSettings.apiBaseUrl}/api/graphics/loadgeographicplacelogo?geonameid=${cell.original.geoNameId}&h=50`}
                        alt="Geographic Place primary logo"
                        style={{ backgroundColor: "#000000" }}
                        onError={e => {
                          setImageIssues(
                            `${imageIssues}, Primary logo (dark background) for geographic place: ${cell.original.geographicDisplayName} (${cell.original.geoNameId}) did not load.`
                          )
                        }}
                      />
                    </div>
                  ),
                  width: 150,
                  sortable: false,
                  filterable: false
                },
                {
                  Header: s => (
                    <div>
                      Primary logo
                      <br />
                      <small>(light background)</small>
                    </div>
                  ),
                  Cell: cell => (
                    <div>
                      <img
                        src={`${globalSettings.apiBaseUrl}/api/graphics/loadgeographicplacelogo?geonameid=${cell.original.geoNameId}&h=50&isSafeForWhitebackground=true`}
                        alt="Geographic Place primary logo"
                        style={{ backgroundColor: "#ffffff" }}
                      />
                    </div>
                  ),
                  width: 150,
                  sortable: false,
                  filterable: false
                }
              ]}
              filterable={true}
              defaultPageSize={25}
              showPaginationTop={false}
              showPaginationBottom={true}
              className="-striped -highlight -scrollEntries"
              style={{
                height: `${(window.innerHeight * 0.72).toFixed(0)}px`
              }}
            >
              {(state, makeTable) => {
                let recordsInfoText = ""

                const { filtered, pageRows, pageSize, sortedData, page } = state

                if (sortedData && sortedData.length > 0) {
                  let isFiltered = filtered.length > 0

                  let totalRecords = sortedData.length

                  let recordsCountFrom = page * pageSize + 1

                  let recordsCountTo = recordsCountFrom + pageRows.length - 1

                  if (isFiltered) {
                    recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`
                  } else {
                    recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`
                  }
                } else {
                  recordsInfoText = isLoading
                    ? "Loading sports data, please wait"
                    : "No files"
                }
                return (
                  <div className="main-grid">
                    <div className="above-table text-right">
                      <div className="col-sm-12">
                        <span className="records-info">{recordsInfoText}</span>
                      </div>
                    </div>

                    {makeTable()}
                  </div>
                )
              }}
            </ReactTable>
            <div style={{ background: "#ffd900" }}>
              Image issues: {imageIssues}
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

// PropType Declaration
ManageGeographicPlaces.propTypes = {}

// Default Export
export default ManageGeographicPlaces
