import { useContext, useState, useMemo } from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import {
  Collapse,
  Dialog,
  DialogTitle,
  IconButton,
  Paper,
  DialogContent
} from "@mui/material"
import { ExpandMore, SyncAlt, Warning } from "@mui/icons-material"
import { makeStyles } from "@mui/styles"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import { useHistory } from "react-router-dom"
import { primaryNavy } from "assets/jss/material-dashboard-pro-react"
import NarrativeOutlineSentencePosition from "components/NarrativeAdmin/NarrativeOutlineSentencePosition"
import SaturationChip from "components/ContentSaturation/SaturationChip"
import ParagraphEditor from "components/NarrativeAdmin/Paragraph/ParagraphEditor"
import { Menu, Item, useContextMenu, Separator } from "react-contexify"
import { ActionType, ItemType } from "constants"
import "react-contexify/dist/ReactContexify.css"
import SentenceEditor from "./Sentence/SentenceEditor"
import { NarrativeMenuActionsContext } from "contexts/narrativeMenuActionsContext"
import CustomDSDialog from "components/CustomDialogs/CustomDSDialog"

const useStyles = makeStyles(theme => ({
  paragraphDetails: {
    margin: "2px",
    backgroundColor: "#fff",
    fontSize: "12px",
    color: "#000",
    opacity: props => (props.cutActionTaken ? 0.6 : 1)
  },
  gridItem: {
    padding: "5px 15px"
  },
  fakeLink: {
    color: "inherit",
    "&:hover": {
      cursor: "pointer"
    }
  },
  gridItemFlex: {
    position: "relative",
    display: "flex",
    alignItems: "top",
    "& > button": {
      position: "absolute",
      right: "5px",
      top: "0px",
      padding: 0
    }
  },
  paragraphListHeader: {
    backgroundColor: "#dddddd",
    display: "block",
    position: "relative",
    maxHeight: "40px",
    padding: "7px",
    "& > label": {
      marginLeft: "15px",
      fontWeight: "bold",
      fontSize: "14px",
      color: primaryNavy,
      textTransform: "capitalize"
    },
    "& > button": {
      position: "absolute",
      right: "5px",
      top: "5px",
      padding: 0
    }
  },
  modalHeader: {
    "& h2 ": {
      textTransform: "uppercase"
    }
  },
  expand: {
    color: primaryNavy,
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  syncAlt: {
    color: "#144E68"
  },
  sentenceModel: {
    maxWidth: "1000px",
    scrollbarWidth: "none"
  }
}))

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    postCopyParagraph: store.narrativeStore.postCopyParagraph,
    postArchiveParagraph: store.narrativeStore.postArchiveParagraph,
    postArchiveParagraphAndSentences:
      store.narrativeStore.postArchiveParagraphAndSentences,
    reloadNarrativeBrief: store.narrativeStore.reloadNarrativeBrief,
    removeLibraryParagraphAssociation:
      store.narrativeStore.removeLibraryParagraphAssociation,
    postArchiveSentence: store.narrativeStore.postArchiveSentence,
    postArchiveSentencesUnderParagraph:
      store.narrativeStore.postArchiveSentencesUnderParagraph
  }))
}

export function NarrativeOutlineParagraph(props) {
  const {
    postCopyParagraph,
    reloadNarrativeBrief,
    removeLibraryParagraphAssociation,
    postArchiveParagraphAndSentences,
    postArchiveSentencesUnderParagraph
  } = useStoreData()
  const history = useHistory()

  const {
    ableToPasteSentence,
    handlePasteSentence,
    setLastActionType,
    setSelectedSourceItem,
    setSelectedSourceParentItem,
    setTargetItem,
    wasParagraphCutSelected
  } = useContext(NarrativeMenuActionsContext)

  const { narrative, paragraph, block } = props
  const doesParagraphIncludeSentences = !!paragraph?.sentences?.filter(
    sntc => sntc.paragraph_Id === paragraph.id
  ).length

  const classes = useStyles({
    cutActionTaken: wasParagraphCutSelected(paragraph.id)
  })

  const [showSentenceGroups, setShowSentenceGroups] = useState(false)
  const [openParagraphEditor, setOpenParagraphEditor] = useState(false)
  const [openSentenceCreator, setOpenSentenceCreator] = useState(false)
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false)
  const [contentMessage, setContentMessage] = useState(false)
  const [archiveType, setArchiveType] = useState("")

  const openUrl = `/portal/narrative/${narrative.id}/edit?view=${
    narrative?.buildFromOutline ? "outlineparagraphid" : "paragraphid"
  }-${paragraph.id}`

  const handleClickOpenEditor = () => {
    setOpenParagraphEditor(true)
  }

  const sampleSentence = useMemo(
    () => ({
      paragraph_Id: paragraph.id,
      narrative_Id: narrative.id,
      status_Id: 3,
      name: "",
      template: "",
      position: 10
    }),
    [paragraph]
  )

  const handleCloseEditor = () => {
    setOpenParagraphEditor(false)
  }
  const handleClickOpenSentenceCreator = () => {
    setOpenSentenceCreator(true)
  }
  const handleCloseSentenceCreator = () => {
    setOpenSentenceCreator(false)
  }

  const handleClickRemoveParagraphAssociation = () => {
    removeLibraryParagraphAssociation(paragraph)
  }

  const openWarnDialog = message => {
    setIsWarningDialogOpen(true)
    setContentMessage(message)
  }

  const closeWarnDialog = () => {
    setIsWarningDialogOpen(false)
  }

  const sortedSentenceGroups = useMemo(
    () =>
      (paragraph?.sentences &&
        paragraph?.sentences.slice().sort((a, b) => {
          let comparison = 0
          if (a.position > b.position) {
            comparison = 1
          } else if (a.position < b.position) {
            comparison = -1
          }
          return comparison
        })) ||
      [],
    [paragraph.sentences]
  )
  const { show } = useContextMenu({
    id: paragraph.id
  })

  function handleContextMenu(event) {
    setTargetItem({ id: paragraph.id, type: ItemType.Paragraph })
    event.stopPropagation()
    show({
      event
    })
  }

  const handleMenuAction = async actionType => {
    let contentMessage =
      actionType === ActionType.Archive
        ? `Are you sure you want to archive Paragraph ${paragraph.id} and associated Sentences?`
        : actionType === ActionType.ArchiveSentences
        ? `Are you sure you want to archive all Sentences associated with Paragraph ${paragraph.id}?`
        : null
    if (
      actionType === ActionType.Archive ||
      actionType === ActionType.ArchiveSentences
    ) {
      openWarnDialog(contentMessage)
    } else if (actionType === ActionType.Duplicate) {
      await postCopyParagraph(paragraph.id, narrative.id)
      reloadNarrativeBrief(narrative.id)
    } else if (
      actionType === ActionType.Copy ||
      actionType === ActionType.Cut
    ) {
      if (!narrative.buildFromOutline) {
        return
      }
      setSelectedSourceItem({
        id: paragraph.id,
        type: ItemType.Paragraph
      })
      setSelectedSourceParentItem({
        id: block.id,
        type: ItemType.Block
      })
    } else if (actionType === ActionType.Paste) {
      await handlePasteSentence()
      reloadNarrativeBrief(narrative.id)
    }
    setLastActionType(actionType)
    setArchiveType(actionType)
  }

  const handleConfirm = async () => {
    if (archiveType === ActionType.Archive) {
      await postArchiveParagraphAndSentences(paragraph.id)
    } else if (archiveType === ActionType.ArchiveSentences) {
      await postArchiveSentencesUnderParagraph(paragraph.id, narrative.id)
    }
    reloadNarrativeBrief(narrative.id)
    closeWarnDialog() // since async function, close the dialog after the archive is performed
  }

  return (
    <>
      <CustomDSDialog
        open={isWarningDialogOpen}
        icon={<Warning style={{ fontSize: "4rem" }} />}
        onClose={closeWarnDialog}
        backgroundColor={"#FFC107"}
        content={contentMessage}
        buttonProps={[
          {
            label: "NO",
            color: "default",
            onClick: closeWarnDialog
          },
          {
            label: "YES",
            color: "primary",
            onClick: handleConfirm
          }
        ]}
      />
      <Menu id={paragraph.id}>
        <Item onClick={() => history.push(openUrl)}>Open</Item>
        <Item onClick={handleClickOpenEditor}>Edit</Item>
        <Item onClick={handleClickOpenSentenceCreator}>Add New Sentence</Item>
        <Item onClick={() => window.open(openUrl, "_blank")}>
          Open in new window
        </Item>
        {paragraph.libraryParagraph_Id && (
          <Item onClick={handleClickRemoveParagraphAssociation}>
            Remove Paragraph Association
          </Item>
        )}
        <Separator />
        {ableToPasteSentence() && (
          <Item onClick={() => handleMenuAction(ActionType.Paste)}>Paste</Item>
        )}
        {paragraph.id && narrative.buildFromOutline && (
          <>
            <Item
              disabled={!paragraph.id || !narrative.buildFromOutline}
              onClick={() => handleMenuAction(ActionType.Copy)}
            >
              Copy
            </Item>
            <Item
              disabled={!paragraph.id || !narrative.buildFromOutline}
              onClick={() => handleMenuAction(ActionType.Cut)}
            >
              Cut
            </Item>
          </>
        )}
        <Item
          disabled={!paragraph.id}
          onClick={() => handleMenuAction(ActionType.Duplicate)}
        >
          Duplicate
        </Item>
        <Item onClick={() => handleMenuAction(ActionType.Archive)}>
          Archive Paragraph + Sentences
        </Item>
        {doesParagraphIncludeSentences && (
          <Item onClick={() => handleMenuAction(ActionType.ArchiveSentences)}>
            Archive Sentences
          </Item>
        )}
      </Menu>
      <div id={paragraph.id} onContextMenu={handleContextMenu}>
        <GridContainer className={classes.paragraphDetails} key={paragraph.id}>
          <GridItem className={classes.gridItem} xs={4}>
            Paragraph:{" "}
            <span
              className={classes.fakeLink}
              title={`Open Paragraph ${paragraph.id}`}
              onClick={e => {
                handleClickOpenEditor()
                e.preventDefault()
              }}
            >
              {paragraph.id}
            </span>
          </GridItem>
          <GridItem className={classes.gridItem} xs={5}>
            {paragraph.libraryParagraph_Id && (
              <>Library Par.: {paragraph.libraryParagraph_Id}</>
            )}
          </GridItem>
          <GridItem className={classes.gridItem} xs={2}>
            <SaturationChip
              saturation={paragraph.saturation}
              utilization={paragraph.utilization}
            />
          </GridItem>
          <GridItem className={classes.gridItemFlex} xs={1}>
            {paragraph.narrativeOutlineBlockId === block.id && (
              <span title="Connected">
                <SyncAlt className={classes.syncAlt} />
              </span>
            )}
            {sortedSentenceGroups && sortedSentenceGroups.length > 0 && (
              <IconButton
                className={showSentenceGroups ? classes.expandOpen : ""}
                onClick={() => setShowSentenceGroups(!showSentenceGroups)}
                aria-expanded={showSentenceGroups}
                aria-label="show more"
                size="large"
              >
                <ExpandMore />
              </IconButton>
            )}
          </GridItem>
        </GridContainer>
      </div>
      <Collapse in={showSentenceGroups} unmountOnExit>
        {sortedSentenceGroups?.map((s, index) => (
          <NarrativeOutlineSentencePosition
            key={s.id}
            sentencePosition={s}
            narrative={narrative}
            block={block}
            paragraph={paragraph}
            //allSentences={sortedSentenceGroups}
            myIndex={index}
          />
        ))}
      </Collapse>
      <Dialog
        open={openParagraphEditor}
        maxWidth="lg"
        onContextMenu={e => {
          //prevent right click from triggering parent context menu
          e.stopPropagation()
        }}
      >
        <DialogTitle className={classes.modalHeader}>
          {paragraph?.id ? `Paragraph ${paragraph.id}` : "Create New Paragraph"}
        </DialogTitle>
        <Paper>
          <DialogContent>
            <ParagraphEditor {...props} handleClose={handleCloseEditor} />
          </DialogContent>
        </Paper>
      </Dialog>
      <Dialog open={openSentenceCreator} maxWidth="lg">
        <DialogTitle className={classes.modalHeader}>
          Create New Sentence for Paragraph {paragraph.id}
        </DialogTitle>
        <Paper className={classes.sentenceModel}>
          <DialogContent>
            <SentenceEditor
              {...props}
              sentence={sampleSentence}
              create
              handleClose={handleCloseSentenceCreator}
            />
          </DialogContent>
        </Paper>
      </Dialog>
    </>
  )
}
