import React from "react"
import withStyles from "@mui/styles/withStyles"
import { IconButton } from "@mui/material"
import CodeBlock from "../Data/CodeBlock"
import "codemirror/addon/mode/simple"
import "assets/syntax/cm-eureka"
import "assets/syntax/cm-eureka.css"
import "codemirror/keymap/sublime"
import PropTypes from "prop-types"
import { NarrativeContext } from "contexts/narrative-context"
import { Edit } from "@mui/icons-material"
import { useContext } from "react"
import BlockTriggerDetails from "../BlockTriggerDetails/BlockTriggerDetails"

const styles = () => ({
  sentencePreview: {
    fontWeight: "normal",
    "& .xml": {
      fontWeight: "bold",
      fontSize: "13.5px",
      fontStyle: "normal"
    },
    "& pre": {
      padding: "5px 15px!important"
    }
  },
  sentenceHeader: {
    margin: "0 15px",
    padding: "10px 0!important",
    display: "flex",
    "& .highlighted": {
      backgroundColor: "#ffeaa7",
      color: "#e17055",
      boxShadow: "-3px 0 0 #ffeaa7, 3px 0 0 #ffeaa7" // Creates an illusion of a wider span, while not affecting the actual width.
    }
  },
  codePreview: {
    margin: "0 10px",
    padding: "20px!important",
    background: "#000",
    color: "#fff",
    cursor: "pointer",
    fontFamily: "monospace",
    fontSize: "12px",
    "& .highlighted": {
      backgroundColor: "#ffeaa7",
      color: "#e17055",
      boxShadow: "-3px 0 0 #ffeaa7, 3px 0 0 #ffeaa7" // Creates an illusion of a wider span, while not affecting the actual width.
    }
  },
  codeHolder: {
    margin: "0",
    padding: "0 10px"
  },
  codeEditorArea: {
    display: "flex",
    minHeight: "250px"
  },
  edit: {
    marginLeft: "auto",
    padding: "0px"
  }
})

const SentencePreview = props => {
  const narrativeContext = useContext(NarrativeContext)
  const { classes, searchString, triggers, sentence, outlineBlock } = props
  if (!sentence) {
    return null
  }

  const {
    name: sentenceName,
    template,
    position,
    triggerIds,
    triggerType,
    status_Id,
    isArchived,
    sentenceDetails
  } = sentence

  const handleEdit = () => {
    const localActiveView = outlineBlock
      ? `outlinesentenceid-${sentence.id}`
      : `sentenceid-${sentence.id}`
    narrativeContext.setActiveView(localActiveView)
  }

  const titleText = `${
    !!position && position !== 0 ? `Position ${position}, ` : ""
  }${!!sentenceName && sentenceName !== "" ? `${sentenceName} ` : ""}Id ${
    sentence.id
  }`

  return (
    <React.Fragment>
      <div
        style={{
          background: status_Id !== 3 ? "rgba(193, 184, 184, 0.6)" : "initial",
          textDecoration: isArchived ? "line-through" : "initial",
          paddingBottom: "10px",
          borderTop: "1px solid #efefef"
        }}
      >
        <div className={classes.sentenceHeader}>
          <BlockTriggerDetails
            triggerType={triggerType}
            triggerIds={triggerIds}
            allAvailableTriggers={triggers}
            titleText={titleText}
            searchString={searchString}
            setActiveView={narrativeContext.setActiveView}
          />
          <IconButton
            className={classes.edit}
            onClick={e => handleEdit(e)}
            aria-expanded={sentenceDetails}
            aria-label="show more"
            size="large"
          >
            <Edit />
          </IconButton>
        </div>
        <div className={classes.codePreview} onClick={() => handleEdit()}>
          <CodeBlock highlight={searchString}>{template || ""}</CodeBlock>
        </div>
      </div>
    </React.Fragment>
  )
}

SentencePreview.propTypes = {
  editEvent: PropTypes.func,
  classes: PropTypes.object,
  sentence: PropTypes.object
}

export default withStyles(styles)(SentencePreview)
