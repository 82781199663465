import Check from "@mui/icons-material/CheckCircleOutline"
import Report from "@mui/icons-material/Report"

const useUploadLinkOfferHelpers = ({
  linkTypeInfo,
  regionsInfo,
  urlValidation,
  companiesInfo,
  organizations,
  accounts,
  intentInfo,
  displayStyle,
  verticals,
  offerLinksInfo,
  disclaimerLinksInfo,
  variantLeagues,
  setInputFileData,
  linkType,
  Enums,
  Cta_v2DAO,
  dayjs,
  setAffiliatesForOrgLoaded
}) => {
  const _PROPERTY_NOT_FOUND = 999999
  const linkSaveObject = (itm, intentType) => {
    const saveContent = {
      linkName: null,
      intentId: 1,
      statusId: itm.status,
      isArchived: false,
      shortNameOffer: itm.shortNameOffer,
      url: itm.linkUrl,
      affiliateId: itm.affiliateId,
      companies: itm.companies,
      organizations: itm.organizations,
      monetizationRegions: itm.monetizationRegions,
      startDateTime: itm.startDateTimeInTimeZone
        ? itm.startDateTimeInTimeZone
        : null,
      endDateTime: itm.endDateTimeInTimeZone ? itm.endDateTimeInTimeZone : null,
      timeZoneIANA: itm.timeZoneIANA,
      linkDetailText: itm.promoText,
      linkTypeId: itm.linkTypeId,
      assetLink: itm.assetLink,
      ownerAccountId: itm.ownerAccountId
    }
    if (itm.id) {
      saveContent.id = itm.id
    }
    return saveContent
  }

  const constructOrgPropList = itm => {
    //Finds the Organization AND if it has Properties, sees which ones are active for this itm
    const isOrgId = isValidOrganizationName(itm.organization)
    if (isOrgId) {
      itm.organizationId = isOrgId
      const myPropertiesList = itm.property
        ? itm.property
            .toLowerCase()
            .split(",")
            .map(item => item.trim())
        : []
      const correctOrg = []
      organizations.forEach(org => {
        if (org.id === itm.organizationId) {
          correctOrg.push({
            id: org.id,
            name: org.name,
            properties: org.properties
          })
        }
      })
      const myProperties = (
        (correctOrg[0] && correctOrg[0].properties) ||
        []
      ).filter(props => myPropertiesList.includes(props.name.toLowerCase()))
      correctOrg[0].properties = myProperties
      return correctOrg
    } else {
      return []
    }
  }

  const constructOrgPropListForDisclaimers = itm => {
    //Finds the Organization AND if it has Properties, sees which ones are active for this itm
    const myOrgNames = itm.organization
      ? itm.organization.split(",").map(item => item.trim())
      : []
    const correctOrgs = []
    if (myOrgNames.length > 0) {
      myOrgNames.forEach(orgName => {
        const isOrgId = isValidOrganizationName(orgName)
        if (isOrgId) {
          itm.organizationId = isOrgId
          const myPropertiesList = itm.property
            ? itm.property
                .toLowerCase()
                .split(",")
                .map(item => item.trim())
            : []
          //const correctOrg = []
          organizations.forEach(org => {
            if (org.id === itm.organizationId) {
              correctOrgs.push({
                id: org.id,
                name: org.name,
                properties: org.properties.filter(props =>
                  myPropertiesList.includes(props.name.toLowerCase())
                )
              })
            }
          })
        }
      })
      return correctOrgs
    } else {
      return []
    }
  }

  const linkUrlValidationOrEmpty = val => {
    if (!val) {
      return null
    } else {
      const isValid = urlValidation(val)
      return isValid ? null : "Asset Link URL must be a valid URL."
    }
  }

  const linkUrlValidation = val => {
    const isValid = urlValidation(val)
    return isValid ? null : "Link URL must be a valid URL."
  }

  const affiliateValidation = (val, itm, affToOrg) => {
    let isValid = false
    affToOrg?.forEach(pairs => {
      if (pairs.type === "Organization") {
        if (pairs.orgId === itm.organizationId) {
          pairs?.affiliates?.forEach(aff => {
            const affName = aff.name.toLowerCase().replace(/\s/g, "")
            const valAff = val.toLowerCase().replace(/\s/g, "")
            if (affName === valAff) {
              isValid = aff.id
            }
          })
        }
      } else if (pairs.type === "Property") {
        const theIds = getPropertyId(itm) || []
        theIds.forEach(theIdObj => {
          if (pairs.orgId === theIdObj.id && theIdObj.type === "Property") {
            pairs?.affiliates?.forEach(aff => {
              const affName = aff.name.toLowerCase().replace(/\s/gi, "")
              const valAff = val.toLowerCase().replace(/\s/g, "")
              if (affName === valAff) {
                isValid = aff.id
              }
            })
          }
        })
      }
    })
    if (isValid) {
      itm.affiliateId = isValid
    }
    return isValid ? null : "You don't have a valid Affiliate"
  }

  const orgValidationForDisclaimers = (val, itm, affToOrg) => {
    const correctOrg = constructOrgPropListForDisclaimers(itm)
    let isValidOrgList = []
    let hasInvalidProp = false
    let hasProperties = false
    let foundProperties = []
    if (itm.organization) {
      itm.organizations = correctOrg || []
    } else {
      itm.organizations = []
    }
    const myOrgNames = val ? val.split(",").map(item => item.trim()) : []
    const properties = itm.property
      ? itm.property.split(",").map(item => item.trim())
      : []
    myOrgNames.forEach(orgName => {
      correctOrg?.forEach(org => {
        const orgName = org.name.toLowerCase().replace(/\s/g, "")
        const valOrg = orgName.toLowerCase().replace(/\s/g, "")
        if (orgName === valOrg) {
          if (!isValidOrgList.includes(org.id)) {
            isValidOrgList.push(org.id)
          }

          if (properties.length > 0) {
            hasProperties = true
            //let found = false
            properties.forEach(prp => {
              if (itm.onePropertyIsTheParentOrg && itm.organization === prp) {
                //For when an Organization is a "Property" for categorization purposes
                //found = true
                if (!foundProperties.includes(prp)) {
                  foundProperties.push(prp)
                }
              } else {
                org.properties?.forEach(prop => {
                  if (prp.toLowerCase() === prop.name.toLowerCase()) {
                    //found = true
                    if (!foundProperties.includes(prop.name)) {
                      foundProperties.push(prop.name)
                    }
                  }
                })
              }
            })
          }
        }
      })
    })
    if (foundProperties.length !== properties.length) {
      hasInvalidProp = true
    }
    let errMsg = ""
    if (isValidOrgList.length === myOrgNames.length) {
      if (hasProperties) {
        if (!hasInvalidProp) {
        } else {
          errMsg = "You have a valid Organization but an invalid Property(s)"
        }
      }
    } else {
      errMsg = "You have an invalid Organization"
    }
    return errMsg ? errMsg : null
  }

  const orgValidation = (val, itm, affToOrg) => {
    const correctOrg = constructOrgPropList(itm)
    let isValidOrg = false
    let hasInvalidProp = false
    let hasProperties = false
    correctOrg?.forEach(org => {
      const orgName = org.name.toLowerCase().replace(/\s/g, "")
      const valOrg = val.toLowerCase().replace(/\s/g, "")
      if (orgName === valOrg) {
        isValidOrg = org.id
        const properties = itm.property
          ? itm.property.split(",").map(item => item.trim())
          : []
        if (properties.length > 0) {
          hasProperties = true

          properties.forEach(prp => {
            let found = false
            if (itm.onePropertyIsTheParentOrg && itm.organization === prp) {
              //For when an Organization is a "Property" for categorization purposes
              found = true
            } else {
              org.properties?.forEach(prop => {
                if (prp.toLowerCase() === prop.name.toLowerCase()) {
                  found = true
                }
              })
            }
            if (!found) {
              hasInvalidProp = true
            }
          })
        }
      }
    })
    let errMsg = ""
    if (isValidOrg) {
      if (hasProperties) {
        if (!hasInvalidProp) {
        } else {
          errMsg = "You have a valid Organization but an invalid Property(s)"
        }
      }
    } else {
      errMsg = "You dont have a valid Organization"
    }
    return errMsg ? errMsg : null
  }

  const companyValidationDisclaimer = (val, itm) => {
    const companyList = []
    const companies = itm.company
      ? itm.company.split(",").map(item => item.trim())
      : []
    if (companies.length) {
      companies?.forEach(myCmp => {
        companiesInfo?.every(cmp => {
          const cmpName = cmp.name.toLowerCase().replace(/\s/g, "")
          const valName = myCmp.toLowerCase().replace(/\s/g, "")
          if (cmpName === valName) {
            //isValid = cmp.id
            itm.companyId = cmp.id
            companyList.push({ id: cmp.id, name: cmp.name })
            return false
          } else {
            return true
          }
        })
      })
      itm.companies = companyList
      return companies.length === companyList.length
        ? null
        : "You have an invalid Company"
    } else {
      itm.companies = []
      return null
    }
  }

  const companyValidation = (val, itm) => {
    let isValid = false
    companiesInfo?.every(cmp => {
      const cmpName = cmp.name.toLowerCase().replace(/\s/g, "")
      const valName = val.toLowerCase().replace(/\s/g, "")
      if (cmpName === valName) {
        isValid = cmp.id
        itm.companyId = cmp.id
        return false
      } else {
        return true
      }
    })
    return isValid ? null : "You don't have a valid Company"
  }

  const regionValidation = (val, itm) => {
    if (!val) {
      return "You need to enter a Region(s)"
    }
    const regions = val.toLowerCase().split(",")
    const theValue = itm.conversionPayoutAmount.match(/\d{1,5}\.?\d{0,2}/g)
    const cType =
      itm.conversionType === "$" ? 2 : itm.conversionType === "%" ? 1 : 0
    if (regions.includes("usa")) {
      if (regions.length === 1) {
        const monetization = [
          {
            id: 0,
            region: null,
            country: {
              id: 237,
              name: "United States"
            },
            payoutValue: theValue ? Number(theValue.join("")) : 0,
            payoutTypeId: cType,
            isException: false
          }
        ]
        itm.monetizationRegions = monetization
        return null
      } else {
        return "You can't have the USA and additional states"
      }
    } else {
      const monetization = []
      regions.forEach(rgns => {
        regionsInfo.every(rgn => {
          const rgn1 = rgns.toUpperCase().trim()
          if (rgn1 === rgn.geoNameAdmin1Code) {
            monetization.push({
              id: 0,
              region: {
                id: rgn.id,
                geoNameAdmin1Code: rgn.geoNameAdmin1Code,
                name: rgn.name
              },
              country: null,
              payoutValue: theValue ? Number(theValue.join("")) : 0,
              payoutTypeId: cType,
              isException: false
            })
            return false
          } else {
            return true
          }
        })
      })
      itm.monetizationRegions = monetization
      if (regions.length === 0) {
        return "You must have at least one Region"
      } else if (monetization.length !== regions.length) {
        return "You have at least one invalid Region"
      } else {
        return null
      }
    }
  }

  const shortNameOfferValidation = shortName => {
    const howLong = shortName.length
    return howLong > 50 ? "The Short Name is too long (50 character max)" : null
  }

  const linkNameDisclaimerValidation = linkName => {
    if (!linkName) {
      return "You need a Link Name"
    }
    const howLong = linkName.length
    return howLong > 50 ? "The Link Name is too long (50 character max)" : null
  }

  const offerLinkTypeValidation = (val, itm) => {
    let isValid = false
    linkTypeInfo?.every(lti => {
      const ltiName = lti.name.toLowerCase().replace(/\s/g, "")
      const valLti = val.toLowerCase().replace(/\s/g, "")
      if (ltiName === valLti) {
        isValid = lti.id
        return false
      } else {
        return true
      }
    })
    if (isValid) {
      itm.linkTypeId = isValid
    }
    return isValid ? null : "You dont have a valid Link Type Info"
  }

  const conversionPayoutAmountValidation = val => {
    const isValid = val.match(/^\$?\d{1,5}\.?\d{0,2}%?$/g)
    const theValue = val.match(/\d{1,5}\.?\d{0,2}/g)
    if (theValue && Number(theValue.join("")) === 0) {
      return '"0" is NOT a valid Conversion Payout Amount'
    } else {
      return isValid ? null : "You dont have a valid Conversion Payout Amount"
    }
  }

  const conversionTypeValidation = val => {
    const isValid = val === "$" || val === "%" ? true : false
    return isValid ? null : "You dont have a valid Conversion Type"
  }

  const getEndDateFromLink = id => {
    let foundEndDate = null
    const linkInfo =
      linkType === "Offer"
        ? offerLinksInfo
        : linkType === "Disclaimer"
        ? disclaimerLinksInfo
        : []
    linkInfo.every(lnk => {
      if (lnk.id === Number(id)) {
        foundEndDate = lnk.endDateTime
        return false
      } else {
        return true
      }
    })
    return foundEndDate
  }

  const getPropertyId = itm => {
    const correctOrg = constructOrgPropList(itm)
    const properties = itm.property
      ? itm.property.split(",").map(item => item.trim())
      : []
    if (properties.length > 0) {
      //let found = false
      const propList = []
      correctOrg?.forEach(org => {
        properties.forEach(prpName => {
          let foundProperty = false
          org.properties?.forEach(prop => {
            if (
              prop.name.trim().toLowerCase() === prpName.trim().toLowerCase()
            ) {
              //propList.push({ name: prop.name.trim(), id: prop.id })
              propList.push({
                id: prop.id,
                name: prop.name,
                type: "Property"
              })
              foundProperty = true
            }
          })
          if (!foundProperty) {
            if (org.name === prpName) {
              //If the Property is in fact the ORG itself
              propList.push({
                id: org.id,
                name: org.name,
                type: "Organization"
              })
              itm.onePropertyIsTheParentOrg = true
            } else {
              propList.push({ id: _PROPERTY_NOT_FOUND, name: "NONE" })
            }
          }
        })
      })
      if (propList.length === 0) {
        return []
      } else {
        return propList
      }
    } else {
      return []
    }
  }

  const isValidOrganizationName = orgName => {
    let found = false
    if (orgName) {
      organizations?.every(org => {
        if (org.name === orgName) {
          found = org.id
          return false
        } else {
          return true
        }
      })
    }
    return found
  }

  const addUpTotalOrgAndPropCombinations = list => {
    let total = 0
    list?.forEach(itm => {
      const isOrgId = isValidOrganizationName(itm.organization)
      if (isOrgId) {
        itm.organizationId = isOrgId
        if (itm.property?.trim() !== "") {
          const allProperties = itm.property.split(",").map(item => item.trim())
          total += allProperties.length
        } else {
          total += 1
        }
      }
    })
    return total
  }

  const getStatusId = itm => {
    let statusId = Enums.SentenceStatus.PUBLISHED //Default to PUBLISHED if none set
    Object.keys(Enums.SentenceStatus).forEach(k => {
      if (k === itm.status.toUpperCase()) {
        statusId = Enums.SentenceStatus[k]
      }
    })
    return statusId
  }

  const getOwnerId = itm => {
    let ownerId = null
    const accountsToJS = accounts?.toJS() || []
    accountsToJS.every(accts => {
      if (
        itm.owner.toLowerCase() ===
        `${accts.firstName} ${accts.lastName}`.toLowerCase()
      ) {
        ownerId = accts.id
        return false
      } else {
        return true
      }
    })
    return ownerId
  }

  const disclaimerLinkValidationObject = {
    hasOwnProperty: [
      { variable: "linkUrl", name: "Link URL", validation: linkUrlValidation },
      {
        variable: "assetLink",
        name: "Asset URL",
        validation: linkUrlValidationOrEmpty
      },
      {
        variable: "organization",
        name: "Organization",
        validation: orgValidationForDisclaimers
      },
      {
        variable: "company",
        name: "Company",
        validation: companyValidationDisclaimer
      },
      {
        variable: "linkName",
        name: "Link Name",
        validation: linkNameDisclaimerValidation
      }
    ]
  }

  const offerLinkValidationObject = {
    hasOwnProperty: [
      { variable: "linkUrl", name: "Link URL", validation: linkUrlValidation },
      {
        variable: "assetLink",
        name: "Asset URL",
        validation: linkUrlValidationOrEmpty
      },
      {
        variable: "organization",
        name: "Organization",
        validation: orgValidation
      },
      { variable: "company", name: "Company", validation: companyValidation },
      {
        variable: "shortNameOffer",
        name: "Short Name Offer",
        validation: shortNameOfferValidation
      },
      {
        variable: "offerLinkType",
        name: "Offer Link Type",
        validation: offerLinkTypeValidation
      },
      {
        variable: "conversionPayoutAmount",
        name: "Conversion Payout",
        validation: conversionPayoutAmountValidation
      },
      {
        variable: "region",
        name: "Region",
        validation: regionValidation
      },
      {
        variable: "conversionType",
        name: "Conversion Type",
        validation: conversionTypeValidation
      },
      {
        variable: "affiliate",
        name: "Affiliate",
        validation: affiliateValidation
      }
    ]
  }

  const variantNameValidation = name => {
    const howLong = name?.length
    if (!howLong) {
      return "You must enter a Variant Name"
    }
    return howLong > 50
      ? "The Variant Name is too long (50 character max)"
      : howLong > 0
      ? null
      : "You must enter a Variant Name"
  }

  const offerLinkIdValidation = id => {
    const isNum = id && !isNaN(id)
    if (!isNum) {
      return "You must enter a Numerical LinkId"
    }
    let found = false
    const linkInfo =
      linkType === "Offer"
        ? offerLinksInfo
        : linkType === "Disclaimer"
        ? disclaimerLinksInfo
        : []
    linkInfo.every(lnk => {
      if (lnk.id === Number(id)) {
        found = Number(id)
        return false
      } else {
        return true
      }
    })
    return found ? null : `You must enter an existing ${linkType} Link Id`
  }

  const displayStyleValidation = (name, itm) => {
    let isValid = false
    displayStyle?.every(dst => {
      const dstName = dst.name.toLowerCase().replace(/\s/g, "")
      const valName = name.toLowerCase().replace(/\s/g, "")
      if (dstName === valName) {
        isValid = dst.id
        itm.displayStyleId = dst.id
        return false
      } else {
        return true
      }
    })
    return isValid ? null : "You dont have a valid Display Style"
  }

  const verticalsValidation = (name, itm) => {
    let isValid = false
    verticals?.every(vrt => {
      const dstName = vrt.name.toLowerCase().replace(/\s/g, "")
      const valName = name.toLowerCase().replace(/\s/g, "")
      if (dstName === valName) {
        isValid = vrt.id
        itm.verticalId = vrt.id
        return false
      } else {
        return true
      }
    })
    return isValid ? null : "You dont have a valid Vertical"
  }
  const priorityValidation = num => {
    const isNum = num && !isNaN(num)
    if (!isNum) {
      return "You must enter a Numerical Priority"
    }
    return Number(num) > 1000 || Number(num) < 1
      ? "The Priority must be between 1 and 1000"
      : null
  }

  const leagueValidation = (leagueList, itm) => {
    const chosenVertical = verticals?.find(x => x.id === itm.verticalId)
    const leagues = (!!leagueList && leagueList?.split(",")) || []
    const myLeagues = []
    leagues.forEach(lgs => {
      variantLeagues?.every(vls => {
        const dstName = vls.name.toLowerCase().replace(/\s/g, "")
        const valName = lgs.toLowerCase().replace(/\s/g, "")
        if (dstName === valName) {
          myLeagues.push({ id: vls.id })
          return false
        } else {
          return true
        }
      })
    })
    if (chosenVertical?.name === "Entertainment") {
      if (leagues.length > 0) {
        return "You have an Entertainment as your vertical, you cannot have a League"
      }
    } else {
      itm.leagues = myLeagues
      if (leagues.length !== myLeagues.length) {
        return "You have an invalid League"
      } else {
        return null
      }
    }
  }

  const templateValidation = (name, itm) => {
    const valid = name.match(/\[\[link_url\]\]/g) || []
    return valid.length >= 1
      ? null
      : `Template not valid, needs to have [[link_url]]`
  }

  const endDateTimeValidation = (name, itm) => {
    const endDate = getEndDateFromLink(itm.linkId)
    if (!endDate || !itm.endDateTime) {
      return null
    }
    const variantEndDate = dayjs(itm.endDateTime)
    const linkDateEnd = dayjs(endDate)
    const dateDiff = linkDateEnd.diff(variantEndDate)
    if (dateDiff < 0) {
      return "Your End Date time is Invalid, it's after the Link's End Date time"
    }
  }

  const variantValidationObject = {
    hasOwnProperty: [
      {
        variable: "variantName",
        name: "Variant Name",
        validation: variantNameValidation
      },
      {
        variable: "linkId",
        name: `${linkType} Link ID`,
        validation: offerLinkIdValidation
      },
      {
        variable: "vertical",
        name: "Vertical",
        validation: verticalsValidation
      },
      {
        variable: "displayStyle",
        name: "Display Style",
        validation: displayStyleValidation
      },
      {
        variable: "priority",
        name: "Priority",
        validation: priorityValidation
      },
      {
        variable: "league",
        name: "League",
        validation: leagueValidation
      },
      {
        variable: "template",
        name: "Template",
        validation: templateValidation
      },
      {
        variable: "endDateTime",
        name: "endDateTime",
        validation: endDateTimeValidation
      }
    ]
  }
  const formValueVariantValidation = itm => {
    let result = []
    variantValidationObject.hasOwnProperty?.forEach(field => {
      if (itm.hasOwnProperty(field.variable)) {
        if (typeof field.validation === "function") {
          const errorResult = field.validation(itm[field.variable], itm)
          if (errorResult) {
            result.push(errorResult)
          }
        } else if (field.array && !itm[field.variable].length) {
          result.push(`Please add a value for "${field.name}"`)
        } else if (!itm[field.variable]) {
          result.push(`Please add a value for "${field.name}"`)
        }
      } else {
        result.push(`"${field.name}" doesn't exist in the form`)
      }
    })
    if (result.length) {
      return result
    } else {
      return false
    }
  }

  const formValueDisclaimerLinkValidation = (itm, affToOrg) => {
    let result = []
    disclaimerLinkValidationObject.hasOwnProperty?.forEach(field => {
      if (itm.hasOwnProperty(field.variable)) {
        if (typeof field.validation === "function") {
          const errorResult = field.validation(
            itm[field.variable],
            itm,
            affToOrg
          )
          if (errorResult) {
            result.push(errorResult)
          }
        } else if (field.array && !itm[field.variable].length) {
          result.push(`Please add a value for "${field.name}"`)
        } else if (!itm[field.variable]) {
          result.push(`Please add a value for "${field.name}"`)
        }
      } else {
        result.push(`"${field.name}" doesn't exist in the form`)
      }
    })
    if (result.length) {
      return result
    } else {
      return false
    }
  }

  const formValueOfferLinkValidation = (itm, affToOrg) => {
    let result = []
    offerLinkValidationObject.hasOwnProperty?.forEach(field => {
      if (itm.hasOwnProperty(field.variable)) {
        if (typeof field.validation === "function") {
          const errorResult = field.validation(
            itm[field.variable],
            itm,
            affToOrg
          )
          if (errorResult) {
            result.push(errorResult)
          }
        } else if (field.array && !itm[field.variable].length) {
          result.push(`Please add a value for "${field.name}"`)
        } else if (!itm[field.variable]) {
          result.push(`Please add a value for "${field.name}"`)
        }
      } else {
        result.push(`"${field.name}" doesn't exist in the form`)
      }
    })
    if (result.length) {
      return result
    } else {
      return false
    }
  }

  const saveLinkEntry = row => {
    if (linkType === "Offer") {
      const saveObj = {
        affiliateId: row.affiliateId,
        companies: row.companies,
        endDateTime: row.endDateTime
          ? dayjs(row.endDateTime).toISOString()
          : null,
        intentId: 1,
        isArchived: row.isArchived,
        linkTypeId: row.linkTypeId,
        linkDetailText: row.promoText,
        assetLink: row.assetLink,
        monetizationRegions: row.monetizationRegions,
        organizations: row.organizations,
        ownerAccountId: row.ownerAccountId,
        shortNameOffer: row.shortNameOffer,
        startDateTime: row.startDateTime
          ? dayjs(row.startDateTime).toISOString()
          : null,
        statusId: row.statusId,
        timeZoneIANA: row.timeZoneIANA,
        url: row.url
      }
      return saveObj
    } else if (linkType === "Disclaimer") {
      const saveObj = {
        affiliateId: 0,
        companies: row.companies,
        endDateTime: row.endDateTime
          ? dayjs(row.endDateTime).toISOString()
          : null,
        intentId: 2,
        isArchived: row.isArchived,
        linkName: row.linkName,
        linkTypeId: 0,
        monetizationRegions: [],
        organizations: row.organizations,
        ownerAccountId: row.ownerAccountId,
        shortNameOffer: "",
        startDateTime: row.startDateTime
          ? dayjs(row.startDateTime).toISOString()
          : null,
        statusId: row.statusId,
        timeZoneIANA: row.timeZoneIANA,
        url: row.url
      }
      return saveObj
    } else {
      return {}
    }
  }

  const saveVariantEntry = row => {
    const saveObj = {
      id: 0,
      displayStyleId: row.displayStyleId,
      verticalId: row.verticalId,
      name: row.variantName,
      isArchived: false,
      timeZoneIANA: row.timeZone ? row.timeZone : "America/Los_Angeles",
      linkId: Number(row.linkId),
      priority: row.priority.toString(),
      template: row.template,
      leagues: row.leagues,
      eventId: row.events ? row.events : null,
      ownerAccountId: row.ownerAccountId,
      shortNameOffer: row.shortNameOffer,
      startDateTime: row.startDateTime
        ? dayjs(row.startDateTime).toISOString()
        : null,
      endDateTime: row.endDateTime
        ? dayjs(row.endDateTime).toISOString()
        : null,
      statusId: row.statusId,
      url: row.url
    }
    return saveObj
  }
  const columnsDisclaimerLinks = [
    {
      Header: "Link URL",
      accessor: "Link URL",
      //accessor: "linkUrl",
      sortable: false,
      filterable: false,
      width: "300",
      Cell: ({ row }) => (
        <div
          style={
            row.original?.hasNarrativeError
              ? { color: "#c00", fontWeight: "bold", padding: "5px" }
              : { padding: "5px" }
          }
        >
          {row.original?.linkUrl}
        </div>
      )
    },
    {
      Header: "Company",
      accessor: "Company",
      //accessor: "company",
      width: "120",
      Cell: ({ row }) => (
        <div
          style={
            row.original?.hasFeedError
              ? { color: "#c00", fontWeight: "bold", padding: "5px" }
              : { padding: "5px" }
          }
        >
          {row.original?.company}
        </div>
      )
    },
    {
      Header: "Link Name",
      accessor: "Link Name",
      //accessor: "shortNameOffer",
      width: "150",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.linkName}
        </div>
      )
    },
    {
      Header: "Organization",
      accessor: "organization",
      width: "150",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.organization}
        </div>
      )
    },
    // {
    //   Header: "Properties",
    //   accessor: "organizations.properties",
    //   width: "150",
    //   Cell: ({ row }) =>
    //     row.original?.organizations.map(
    //       (org, i) =>
    //         org.properties?.length > 0 &&
    //         org.properties?.map((prp, j) => (
    //           <div key={j} style={{ fontWeight: "bold", padding: "0px" }}>
    //             {prp.name}
    //           </div>
    //         ))
    //     )
    //},
    {
      Header: "Properties",
      accessor: "property",
      width: "150",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.property}
        </div>
      )
    },
    {
      Header: "Start Date Time",
      accessor: "startDateTime",
      width: "150",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.startDateTime}
        </div>
      )
    },
    {
      Header: "End Date Time",
      accessor: "endDateTime",
      width: "150",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.endDateTime}
        </div>
      )
    },
    {
      Header: "Time Zone",
      accessor: "timeZone",
      width: "100",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.timeZone}
        </div>
      )
    },
    {
      Header: "Asset Link",
      accessor: "assetLink",
      width: "200",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.assetLink}
        </div>
      )
    },
    {
      Header: "Owner",
      accessor: "owner",
      width: "120",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.owner}
        </div>
      )
    },
    {
      Header: "Status",
      accessor: "status",
      width: "100",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.status}
        </div>
      )
    },
    {
      Header: "Errors?",
      accessor: "errorDetails",
      width: "200",
      Cell: ({ row }) => {
        if (row.original?.errorDetails?.length) {
          return (
            <ul>
              {row.original?.errorDetails?.map((err, i) => (
                <li key={i}>{err}</li>
              ))}
            </ul>
          )
        } else {
          return <span>&nbsp;</span>
        }
      }
    }
  ]

  const columnsOfferLinks = [
    {
      Header: "Link URL",
      accessor: "Link URL",
      //accessor: "linkUrl",
      sortable: false,
      filterable: false,
      width: "300",
      Cell: ({ row }) => (
        <div
          style={
            row.original?.hasNarrativeError
              ? { color: "#c00", fontWeight: "bold", padding: "5px" }
              : { padding: "5px" }
          }
        >
          {row.original?.linkUrl}
        </div>
      )
    },
    {
      Header: "Company",
      accessor: "Company",
      //accessor: "company",
      width: "120",
      Cell: ({ row }) => (
        <div
          style={
            row.original?.hasFeedError
              ? { color: "#c00", fontWeight: "bold", padding: "5px" }
              : { padding: "5px" }
          }
        >
          {row.original?.company}
        </div>
      )
    },
    {
      Header: "Short Name Offer",
      accessor: "Short Name Offer",
      //accessor: "shortNameOffer",
      width: "150",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.shortNameOffer}
        </div>
      )
    },
    {
      Header: "Organization",
      accessor: "organization",
      width: "150",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.organization}
        </div>
      )
    },
    // {
    //   Header: "Properties",
    //   accessor: "organizations.properties",
    //   width: "150",
    //   Cell: ({ row }) =>
    //     row.original?.organizations.map(
    //       (org, i) =>
    //         org.properties?.length > 0 &&
    //         org.properties?.map((prp, j) => (
    //           <div key={j} style={{ fontWeight: "bold", padding: "0px" }}>
    //             {prp.name}
    //           </div>
    //         ))
    //     )
    //},
    {
      Header: "Properties",
      accessor: "property",
      width: "150",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.property}
        </div>
      )
    },
    {
      Header: "Affiliate",
      accessor: "affiliate",
      width: "150",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.affiliate}
        </div>
      )
    },
    {
      Header: "Region",
      accessor: "region",
      width: "200",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.region}
        </div>
      )
    },
    {
      Header: "Conversion Payout Amount",
      accessor: "conversionPayoutAmount",
      width: "100",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.conversionPayoutAmount}
        </div>
      )
    },
    {
      Header: "Conversion Type",
      accessor: "conversionType",
      width: "100",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.conversionType}
        </div>
      )
    },

    {
      Header: "Start Date Time",
      accessor: "startDateTime",
      width: "150",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.startDateTime}
        </div>
      )
    },
    {
      Header: "End Date Time",
      accessor: "endDateTime",
      width: "150",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.endDateTime}
        </div>
      )
    },
    {
      Header: "Time Zone",
      accessor: "timeZone",
      width: "100",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.timeZone}
        </div>
      )
    },
    {
      Header: "Offer Link Type",
      accessor: "offerLinkType",
      width: "150",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.offerLinkType}
        </div>
      )
    },
    {
      Header: "Promo Text",
      accessor: "promoText",
      width: "200",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.promoText}
        </div>
      )
    },
    {
      Header: "Asset Link",
      accessor: "assetLink",
      width: "200",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.assetLink}
        </div>
      )
    },
    {
      Header: "Owner",
      accessor: "owner",
      width: "120",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.owner}
        </div>
      )
    },
    {
      Header: "Status",
      accessor: "status",
      width: "100",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.status}
        </div>
      )
    },
    {
      Header: "Errors?",
      accessor: "errorDetails",
      width: "200",
      Cell: ({ row }) => {
        if (row.original?.errorDetails?.length) {
          return (
            <ul>
              {row.original?.errorDetails?.map((err, i) => (
                <li key={i}>{err}</li>
              ))}
            </ul>
          )
        } else {
          return <span>&nbsp;</span>
        }
      }
    }
  ]

  const columnsVariant = [
    {
      Header: "Variant Name",
      accessor: "Variant Name",
      sortable: false,
      filterable: false,
      width: "300",
      Cell: ({ row }) => (
        <div
          style={
            row.original?.hasNarrativeError
              ? { color: "#c00", fontWeight: "bold", padding: "5px" }
              : { padding: "5px" }
          }
        >
          {row.original?.variantName}
        </div>
      )
    },
    {
      Header: `${linkType} Link ID`,
      accessor: `${linkType} Link ID`,
      width: "120",
      Cell: ({ row }) => (
        <div
          style={
            row.original?.hasFeedError
              ? { color: "#c00", fontWeight: "bold", padding: "5px" }
              : { padding: "5px" }
          }
        >
          {row.original?.linkId}
        </div>
      )
    },
    {
      Header: "Priority",
      accessor: "priority",
      width: "150",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.priority}
        </div>
      )
    },
    {
      Header: "Vertical",
      accessor: "vertical",
      width: "150",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.vertical}
        </div>
      )
    },
    {
      Header: "Display Style",
      accessor: "displayStyle",
      width: "150",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.displayStyle}
        </div>
      )
    },
    {
      Header: "league",
      accessor: "league",
      width: "150",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.league}
        </div>
      )
    },
    // {
    //   Header: "League",
    //   accessor: "league",
    //   width: "150",
    //   Cell: ({ row }) =>
    //     row.original?.league.map((lg, i) => (
    //       <div key={i} style={{ fontWeight: "bold", padding: "5px" }}>
    //         {lg.name}
    //       </div>
    //     ))
    // },
    {
      Header: "Template",
      accessor: "template",
      width: "150",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.template}
        </div>
      )
    },
    {
      Header: "Events",
      accessor: "events",
      width: "200",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.events}
        </div>
      )
    },
    {
      Header: "Start Date Time",
      accessor: "startDateTime",
      width: "150",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.startDateTime}
        </div>
      )
    },
    {
      Header: "End Date Time",
      accessor: "endDateTime",
      width: "150",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.endDateTime}
        </div>
      )
    },
    {
      Header: "Time Zone",
      accessor: "timeZone",
      width: "100",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.timeZone}
        </div>
      )
    },
    {
      Header: "Owner",
      accessor: "owner",
      width: "120",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.owner}
        </div>
      )
    },
    {
      Header: "Status",
      accessor: "status",
      width: "100",
      Cell: ({ row }) => (
        <div style={{ fontWeight: "bold", padding: "5px" }}>
          {row.original?.status}
        </div>
      )
    },
    {
      Header: "Errors?",
      accessor: "errorDetails",
      width: "200",
      Cell: ({ row }) => {
        if (row.original?.errorDetails?.length) {
          return (
            <ul>
              {row.original?.errorDetails?.map((err, i) => (
                <li key={i}>{err}</li>
              ))}
            </ul>
          )
        } else {
          return <span>&nbsp;</span>
        }
      }
    }
  ]
  const errorColumn = [
    {
      Header: "Index",
      accessor: "index",
      width: 75,
      Cell: ({ cell }) => (
        <>
          {cell?.row?.original?.fromAPI ? (
            <div>
              {cell?.row?.original.hasError ? (
                <>
                  {cell?.row?.index + 1}{" "}
                  <Report
                    style={{
                      color: "#a31545",
                      width: "28px",
                      height: "28px"
                    }}
                  />
                </>
              ) : (
                <>
                  {cell?.row?.index + 1}{" "}
                  <Check
                    style={{
                      color: "#66bb6a",
                      width: "28px",
                      height: "28px"
                    }}
                  />
                </>
              )}
            </div>
          ) : (
            <>
              {cell?.row?.original.hasError ? (
                <>
                  {cell?.row?.index + 1}{" "}
                  <Report
                    style={{
                      color: "#a31545",
                      width: "28px",
                      height: "28px"
                    }}
                  />
                </>
              ) : (
                <>
                  {cell?.row?.index + 1}{" "}
                  <Check
                    style={{
                      color: "#66bb6a",
                      width: "28px",
                      height: "28px"
                    }}
                  />
                </>
              )}
            </>
          )}
        </>
      )
    }
  ]

  return {
    linkSaveObject,
    constructOrgPropList,
    linkUrlValidation,
    affiliateValidation,
    companyValidation,
    regionValidation,
    shortNameOfferValidation,
    offerLinkTypeValidation,
    conversionPayoutAmountValidation,
    conversionTypeValidation,
    formValueOfferLinkValidation,
    formValueDisclaimerLinkValidation,
    formValueVariantValidation,
    saveLinkEntry,
    saveVariantEntry,
    getStatusId,
    getPropertyId,
    getEndDateFromLink,
    isValidOrganizationName,
    getOwnerId,
    addUpTotalOrgAndPropCombinations,
    columnsOfferLinks,
    columnsDisclaimerLinks,
    columnsVariant,
    errorColumn
  }
}
export default useUploadLinkOfferHelpers
