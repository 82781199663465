import React from "react"
import ReactTable from "react-table-6"
import { globalSettings } from "variables/general"
import Button from "components/CustomButtons/Button"
import Tooltip from "@mui/material/Tooltip"
import PropertyEditor from "./PropertyEditor"
import PropTypes from "prop-types"
import withStyles from "@mui/styles/withStyles"

import OrganizationWidgetShare from "./OrganizationWidgetShare"
import Edit from "@mui/icons-material/Edit"
import WidgetsIcon from "@mui/icons-material/Widgets"
import Helpers from "tools/Helpers.js"
import matchSorter from "match-sorter"
import { Cookies } from "tools/storage"
import { TabPanel } from "components/TabPanel/TabPanel"
import { observer, inject } from "mobx-react"
import { get } from "tools/request"

const styles = {
  datasouresClass: {
    textAlign: "center !important",
    backgroundColor: "#fff",
    fontWeight: "bold !important",
    borderBottom: "2px solid #888",
    padding: "0 !important",
    margin: "0 !important"
  },
  emptyClass: {
    backgroundColor: "#fff",
    padding: "0 !important",
    margin: "0 !important"
  },
  centerColumns: {
    textAlign: "center !important"
  },
  loading: {
    position: "absolute",
    width: "97%",
    top: "0",
    right: "0",
    minHeight: "10px",
    borderRadius: "4px",
    overflow: "hidden",
    zIndex: "-1",
    "& > div": {
      height: "10px"
    }
  }
}

const useStore = component =>
  inject(({ store }) => ({
    account: store.accountStore.account
  }))(observer(component))

class PropertiesManager extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
    let cookies = new Cookies()
    const pageSize = props.embedded
      ? 25
      : !cookies.get("orgPageSize")
      ? 25
      : Number(cookies.get("orgPageSize"))
    this.state = {
      isLoadingProperties: false,
      organizations: [],
      allAccounts: [],
      childOrgs: [],
      editOrganizationIndex: null,
      widgetShareOrganizationIndex: null,
      selectedOrganization: null,
      isAddingProperty: false,
      pageSize,
      newModelOrganization: {
        name: "",
        websiteurl: "",
        ncaaOrgId: 0,
        accounts: []
      },
      isWidgetShareCodeDisplayed: false,
      isEditOrganizationDisplayed: false,
      currentView: 0,
      showTabs: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.onAddPropertyButtonClick = this.onAddPropertyButtonClick.bind(this)
    this.loadProperties = this.loadProperties.bind(this)
    this.loadAllAccounts = this.loadAllAccounts.bind(this)
  }
  componentDidMount() {
    let organizations = this.state && this.props.organizations
    if (
      !organizations ||
      (organizations.length === 0 && !this.state.isLoadingProperties)
    ) {
      this.loadProperties()
    }
    let allAccounts = this.state.allAccounts
    if (
      !allAccounts ||
      (allAccounts.length === 0 && !this.state.isLoadingAccounts)
    ) {
      this.loadAllAccounts()
    }
  }
  onAddPropertyButtonClick = () => {
    this.setState({
      selectedOrganization: this.state.newModelOrganization,
      isAddingProperty: !this.state.isAddingProperty,
      isEditOrganizationDisplayed: true,
      currentView: 1,
      showTabs: true
    })
  }

  handleChange = orgId => {
    this.loadProperties(orgId)
    this.setState({
      isAddingProperty: false,
      editOrganizationIndex: null,
      widgetShareOrganizationIndex: null
    })
  }
  handleOrgChange = (event, newValue) => {
    this.setState({
      currentView: newValue,
      showTabs: !!newValue && this.state.showTabs
    })
  }

  loadProperties() {
    this.setState({ isLoadingProperties: true })
    this.props.isNowLoading(true)
    get(`${globalSettings.apiBaseUrl}/api/organization`)
      .then(Response => Response.json())
      .then(organizations => {
        if (organizations.content) {
          organizations.content.forEach(item => {
            item.Status = this.parseOrgStatus(item.isActive)
          })
          let parentOrgs = {}
          let nameOfParent = {}
          let childOrgs = organizations.content.filter(itm => {
            if (!itm.token || !!itm.parentId) {
              parentOrgs[itm.parentId] = true
            } else {
              nameOfParent[itm.id] = itm.name
            }
            return !itm.token || !!itm.parentId
          })
          let selectedOrg = null
          childOrgs.forEach(itm => {
            if (nameOfParent[itm.parentId]) {
              itm.parentOrg = nameOfParent[itm.parentId]
            }
          })

          if (selectedOrg) {
            this.setState({
              organizations: organizations.content,
              selectedOrganization: selectedOrg,
              childOrgs,
              isLoadingProperties: false
            })
          } else {
            this.setState({
              organizations: organizations.content,
              childOrgs,
              isLoadingProperties: false
            })
          }
        }
      })
  }

  loadAllAccounts() {
    this.setState({ isLoadingAccounts: true })
    get(`${globalSettings.apiBaseUrl}/api/account`)
      .then(Response => Response.json())
      .then(response => {
        let sortedAllAcounts = response.content.sort((a, b) =>
          a.id > b.id ? 1 : -1
        )
        this.setState({
          allAccounts: sortedAllAcounts
        })
      })
  }

  parseOrgStatus(isActive) {
    return isActive ? "Active" : "Inactive"
  }

  rememberChanges = pageSize => {
    this.setState({ pageSize })
    if (this.props.location) {
      let cookies = new Cookies()
      cookies.set("feedPageSize", pageSize, {
        path: this.props.location.pathname,
        expires: Helpers.CookieExpiration.OneMonth
      })
    }
  }

  onEditPropertiesButtonClicked(cell) {
    this.setState({
      selectedOrganization: cell.original,
      isEditOrganizationDisplayed: true,
      currentView: 1,
      showTabs: true
    })
  }

  onWidgetShareButtonClicked(cell) {
    this.setState({
      selectedOrganization: cell.original,
      editOrganizationIndex: cell.original.id,
      isWidgetShareCodeDisplayed: true
    })
  }

  getNameOfProvider(src, id) {
    let name = ""
    src &&
      src.forEach(itm => {
        if (itm.id === id) {
          name = itm.name
        }
      })
    return name
  }

  render() {
    const {
      pageSize,
      organizations,
      allAccounts,
      isAddingProperty,
      newModelOrganization,
      currentView
    } = this.state

    let { childOrgs } = this.state

    //Don't render until Orgs and Accounts loaded
    if (organizations === null || organizations.length === 0) {
      return null
    }
    if (allAccounts === null || allAccounts.length === 0) {
      return null
    }

    const stringFilterProps = {
      filterable: true,
      filterAll: true,
      filterMethod: ({ id, value }, rows) =>
        matchSorter(rows, value, {
          keys: [id],
          threshold: matchSorter.rankings.CONTAINS
        })
    }

    const {
      bookmakerData,
      imageData,
      bettingData,
      sportsData,
      classes,
      currentParent
    } = this.props

    if (currentParent && currentParent > 0) {
      childOrgs = childOrgs.filter(itm => itm.parentId === currentParent)
    }

    let datasourcesByOrg = {}
    organizations &&
      organizations.forEach(itm1 => {
        let id = itm1.id
        let nm = 0
        itm1.organizationDatasources.forEach(itm => {
          datasourcesByOrg[id] = datasourcesByOrg[id]
            ? datasourcesByOrg[id]
            : {}
          switch (Number(itm.datasourceTypeId)) {
            case 1: //Sports
              nm = this.getNameOfProvider(sportsData, itm.datasourceId)
              datasourcesByOrg[id]["sportsDataNew"] =
                datasourcesByOrg[id]["sportsDataNew"] || []
              datasourcesByOrg[id]["sportsDataNew"].push({
                id: itm.datasourceId,
                name: nm,
                indexId: itm.id
              })
              break

            case 2: //Betting
              nm = this.getNameOfProvider(bettingData, itm.datasourceId)
              datasourcesByOrg[id]["bettingDataNew"] =
                datasourcesByOrg[id]["bettingDataNew"] || []
              datasourcesByOrg[id]["bettingDataNew"].push({
                id: itm.datasourceId,
                name: nm,
                indexId: itm.id
              })
              break

            case 3: //Image
              nm = this.getNameOfProvider(imageData, itm.datasourceId)
              datasourcesByOrg[id]["imageDataNew"] =
                datasourcesByOrg[id]["imageDataNew"] || []
              datasourcesByOrg[id]["imageDataNew"].push({
                id: itm.datasourceId,
                name: nm,
                indexId: itm.id
              })
              break

            default:
          }
        })
      })

    let bookmakersByOrg = {}
    organizations &&
      organizations.forEach(itm1 => {
        let id = itm1.id
        let nm = 0
        itm1.organizationBookmakers &&
          itm1.organizationBookmakers.forEach(itm => {
            bookmakersByOrg[id] = bookmakersByOrg[id] ? bookmakersByOrg[id] : {}
            nm = this.getNameOfProvider(bookmakerData, itm.bookmakerId)
            bookmakersByOrg[id]["bookmakersDataNew"] =
              bookmakersByOrg[id]["bookmakersDataNew"] || []
            bookmakersByOrg[id]["bookmakersDataNew"].push({
              id: itm.bookmakerId,
              name: nm,
              indexId: itm.id
            })
          })
      })

    childOrgs.forEach(itm => {
      itm.bookmakers = []
      itm.bettingDatasource = []
      itm.imageSource = []
      itm.sportsDatasource = []
      if (bookmakersByOrg[itm.id]) {
        if (bookmakersByOrg[itm.id]["bookmakersDataNew"]) {
          bookmakerData &&
            bookmakerData.forEach(obj => {
              bookmakersByOrg[itm.id]["bookmakersDataNew"].forEach(obj2 => {
                if (obj.id === obj2.id) {
                  itm.bookmakers.push(obj2.name)
                }
              })
            })
        }
      }
      if (datasourcesByOrg[itm.id]) {
        if (datasourcesByOrg[itm.id]["imageDataNew"]) {
          imageData &&
            imageData.forEach(obj => {
              datasourcesByOrg[itm.id]["imageDataNew"].forEach(obj2 => {
                if (obj.id === obj2.id) {
                  itm.imageSource.push(obj2.name)
                }
              })
            })
        }
        if (datasourcesByOrg[itm.id]["bettingDataNew"]) {
          bettingData &&
            bettingData.forEach(obj => {
              datasourcesByOrg[itm.id]["bettingDataNew"].forEach(obj2 => {
                if (obj.id === obj2.id) {
                  itm.bettingDatasource.push(obj2.name)
                }
              })
            })
        }
        if (datasourcesByOrg[itm.id]["sportsDataNew"]) {
          sportsData &&
            sportsData.forEach(obj => {
              datasourcesByOrg[itm.id]["sportsDataNew"].forEach(obj2 => {
                if (obj.id === obj2.id) {
                  itm.sportsDatasource.push(obj2.name)
                }
              })
            })
        }
      }
    })

    if (childOrgs && childOrgs.length > 0) {
      this.props.isNowLoading(false)
    } else {
      this.props.isNowLoading(true)
    }
    const hasWindowObject = typeof window !== "undefined"
    const screenWidth = hasWindowObject ? window.innerWidth : null
    const screenHeight = hasWindowObject ? window.innerHeight : null
    const showPaginationBottom =
      screenWidth && screenHeight && (screenHeight > 959 || screenWidth > 959)

    const myHeight = showPaginationBottom
      ? `${(window.innerHeight * 0.71).toFixed(0)}px`
      : "auto"

    return (
      <div>
        <div>
          {!this.state.showTabs && (
            <Button
              size="sm"
              variant="contained"
              color="primary"
              onClick={() => this.onAddPropertyButtonClick()}
            >
              Add Property
            </Button>
          )}
        </div>
        {this.state.isWidgetShareCodeDisplayed && (
          <OrganizationWidgetShare
            organization={this.state.selectedOrganization}
            onChange={() => this.handleChange()}
            onClose={() => {
              this.setState({
                isWidgetShareCodeDisplayed: false,
                selectedOrganization: null
              })
            }}
          />
        )}
        <TabPanel value={currentView} index={0}>
          <ReactTable
            data={childOrgs}
            loading={this.state.isLoadingProperties}
            noDataText="No organizations found."
            filterable
            columns={[
              {
                Header: "",
                headerClassName: classes.emptyClass,
                columns: [
                  {
                    Header: "Id",
                    accessor: "id",
                    width: 70,
                    ...stringFilterProps
                  },
                  {
                    Header: "Actions",
                    headerClassName: classes.centerColumns,
                    Cell: cell => (
                      <div style={{ display: "flex" }}>
                        <Tooltip title="Edit" placement="top">
                          <Button
                            color="primary"
                            size="sm"
                            justIcon
                            round
                            simple
                            onClick={() => {
                              this.onEditPropertiesButtonClicked(cell)
                            }}
                          >
                            <Edit />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Widget Code" placement="top">
                          <Button
                            color="primary"
                            size="sm"
                            justIcon
                            round
                            simple
                            onClick={() => {
                              this.onWidgetShareButtonClicked(cell)
                            }}
                          >
                            <WidgetsIcon />
                          </Button>
                        </Tooltip>
                      </div>
                    ),
                    sortable: false,
                    filterable: false,
                    width: 100
                  },
                  {
                    Header: "Property Name",
                    accessor: "name",
                    headerClassName: classes.centerColumns,
                    width: 200,
                    ...stringFilterProps
                  },

                  {
                    Header: "Url",
                    accessor: "webSiteUrl",
                    headerClassName: classes.centerColumns,
                    width: 250,
                    Cell: cell => (
                      <a href={cell.original.webSiteUrl} target="_new">
                        {cell.original.webSiteUrl}
                      </a>
                    ),
                    ...stringFilterProps
                  }
                ]
              },
              {
                Header: "Data Sources",
                headerClassName: classes.datasouresClass,
                getProps: () => ({
                  style: {
                    textAlign: "center",
                    backgroundColor: "#fff"
                  }
                }),
                columns: [
                  {
                    Header: "Sports",
                    headerClassName: classes.centerColumns,
                    width: 150,
                    Cell: row => {
                      if (
                        row &&
                        row.original &&
                        row.original.sportsDatasource &&
                        row.original.sportsDatasource.length > 0
                      ) {
                        return (
                          <ol>
                            {row.original.sportsDatasource.map((itm, i) => (
                              <li key={i}>{itm}</li>
                            ))}
                          </ol>
                        )
                      } else {
                        return ""
                      }
                    },
                    getProps: () => ({
                      style: {
                        textAlign: "center"
                      }
                    }),
                    ...stringFilterProps
                  },
                  {
                    Header: "Betting",
                    headerClassName: classes.centerColumns,
                    width: 150,
                    Cell: row => {
                      if (
                        row &&
                        row.original &&
                        row.original.bettingDatasource &&
                        row.original.bettingDatasource.length > 0
                      ) {
                        return (
                          <ol>
                            {row.original.bettingDatasource.map((itm, i) => (
                              <li key={i}>{itm}</li>
                            ))}
                          </ol>
                        )
                      } else {
                        return ""
                      }
                    },
                    getProps: () => ({
                      style: {
                        textAlign: "center"
                      }
                    }),
                    ...stringFilterProps
                  },
                  {
                    Header: "Image",
                    headerClassName: classes.centerColumns,
                    width: 150,

                    Cell: row => {
                      if (
                        row &&
                        row.original &&
                        row.original.imageSource &&
                        row.original.imageSource.length > 0
                      ) {
                        return (
                          <ol>
                            {row.original.imageSource.map((itm, i) => (
                              <li key={i}>{itm}</li>
                            ))}
                          </ol>
                        )
                      } else {
                        return ""
                      }
                    },
                    getProps: () => ({
                      style: {
                        textAlign: "center"
                      }
                    }),
                    ...stringFilterProps
                  },
                  {
                    Header: "Bookmaker(s)",
                    headerClassName: classes.centerColumns,
                    width: 250,

                    Cell: row => {
                      if (
                        row &&
                        row.original &&
                        row.original.bookmakers &&
                        row.original.bookmakers.length > 0
                      ) {
                        return (
                          <ol>
                            {row.original.bookmakers.map((itm, i) => (
                              <li key={i}>{itm}</li>
                            ))}
                          </ol>
                        )
                      } else {
                        return ""
                      }
                    },
                    ...stringFilterProps
                  }
                ]
              },

              {
                Header: "",
                headerClassName: classes.emptyClass,
                columns: [
                  {
                    Header: "Organization",
                    accessor: "parentOrg",
                    width: 150,
                    getProps: () => ({
                      style: {
                        textAlign: "center"
                      }
                    }),
                    ...stringFilterProps
                  },
                  {
                    Header: "Status",
                    accessor: "Status",
                    headerClassName: classes.centerColumns,
                    width: 80,
                    getProps: () => ({
                      style: {
                        textAlign: "center"
                      }
                    }),
                    ...stringFilterProps
                  },
                  {
                    id: "isInternal",
                    Header: "Internal",
                    accessor: d => (d.isInternal ? "Yes" : "No"),
                    width: 90,
                    ...stringFilterProps
                  },
                  {
                    Header: "Audit Info",
                    accessor: "auditInfo.modifiedOn",
                    headerClassName: classes.centerColumns,
                    Cell: cell => Helpers.renderAuditInfoCell(cell),
                    width: 250,
                    filterMethod: (filter, row) => {
                      if (filter.value === "all") {
                        return true
                      }
                      let futureTime = new Date()
                      let pastTime = new Date()
                      let modifiedOn = new Date(
                        row._original.auditInfo.modifiedOn
                      )
                      if (filter.value === "within30days") {
                        return (
                          modifiedOn >
                            pastTime.setHours(pastTime.getHours() - 24 * 30) &&
                          modifiedOn <
                            futureTime.setHours(futureTime.getHours() + 24 * 30)
                        )
                      }
                      if (filter.value === "within7days") {
                        return (
                          modifiedOn >
                            pastTime.setHours(pastTime.getHours() - 24 * 7) &&
                          modifiedOn <
                            futureTime.setHours(futureTime.getHours() + 24 * 7)
                        )
                      }
                      if (filter.value === "within24hours") {
                        return (
                          modifiedOn >
                            pastTime.setHours(pastTime.getHours() - 24) &&
                          modifiedOn <
                            futureTime.setHours(futureTime.getHours() + 24)
                        )
                      }
                      if (filter.value === "within1hour") {
                        return (
                          modifiedOn >
                            pastTime.setHours(pastTime.getHours() - 1) &&
                          modifiedOn <
                            futureTime.setHours(futureTime.getHours() + 1)
                        )
                      }
                    },
                    Filter: ({ filter, onChange }) => (
                      <select
                        onChange={event => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "all"}
                      >
                        <option value="all">Show All</option>
                        <option value="within30days">Within 30 days</option>
                        <option value="within7days">Within 7 days</option>
                        <option value="within24hours">Within 24 Hours</option>
                        <option value="within1hour">Within 1 Hour</option>
                      </select>
                    )
                  }
                ]
              }
            ]}
            defaultSorted={[
              {
                id: "name",
                desc: false
              }
            ]}
            pageSize={showPaginationBottom ? pageSize : childOrgs.length}
            defaultPageSize={showPaginationBottom ? pageSize : childOrgs.length}
            onPageChange={page => this.rememberChanges(pageSize, page)}
            onPageSizeChange={(pageSize, page) => {
              this.rememberChanges(pageSize, page)
            }}
            showPaginationBottom={showPaginationBottom}
            className="-striped -highlight -scrollEntries"
            style={{
              height: `${myHeight}`
            }}
            getTrProps={(state, row) => {
              if (row) {
                let returnObject = { style: {} }
                if (row.original.id) {
                  returnObject.style.background = "#fff"
                }
                return returnObject
              } else {
                return {}
              }
            }}
          />
        </TabPanel>
        <TabPanel value={currentView} index={1}>
          {this.state.showTabs && (
            <PropertyEditor
              isAddingProperty={isAddingProperty}
              organization={this.state.selectedOrganization}
              organizations={this.state.organizations}
              properties={this.state.childOrgs}
              allAccounts={allAccounts}
              onChange={orgId => this.handleChange(orgId)}
              datasourcesByOrg={datasourcesByOrg}
              bookmakersByOrg={bookmakersByOrg}
              bookmakerData={this.props.bookmakerData}
              imageData={this.props.imageData}
              bettingData={this.props.bettingData}
              sportsData={this.props.sportsData}
              backToOrgList={create => {
                this.setState({
                  currentView: 0,
                  showTabs: false,
                  isAddingProperty: false
                })
                if (create) {
                  this.loadProperties()
                }
              }}
              onClose={tmpOrganization => {
                this.setState({
                  isEditOrganizationDisplayed: false,
                  newModelOrganization: tmpOrganization || newModelOrganization,
                  isAddingProperty: false
                })
              }}
              userId={this.props.account.id}
              currentParent={currentParent}
            />
          )}
        </TabPanel>
      </div>
    )
  }
}

PropertiesManager.propTypes = {
  // Put any props we need here. Can't think of any we need now though.
  isNowLoading: PropTypes.func,
  currentParent: PropTypes.number,
  classes: PropTypes.object,
  bookmakerData: PropTypes.array,
  imageData: PropTypes.array,
  bettingData: PropTypes.array,
  sportsData: PropTypes.array
}

export default withStyles(styles)(useStore(PropertiesManager))
