import CodeMirror from "codemirror"
;(function (mod) {
  mod(CodeMirror)
})(function (CodeMirror) {
  let keywords = [
    "apname",
    "one_of",
    "apnumber",
    "mlbformat",
    "aprun_line",
    "eraformat",
    "basketball_stats",
    "hockey_stats",
    "string",
    "null",
    "if",
    "else",
    "end",
    "for",
    "in",
    "case",
    "when",
    "tablerow",
    "unless",
    "raw",
    "regex",
    "string",
    "timespan",
    "empty",
    "func",
    "written_value",
    "numeric_ordinal_suffixes",
    "written_ordinal_suffixes",
    "home_team",
    "away_team",
    "team_a",
    "team_b",
    "possessive",
    "aan",
    "slugify",
    "deslugify",
    "parsefortimezone",
    "parsefortimezone2",
    "apdate",
    "apdate2",
    "get_Count",
    "get_IsReadOnly",
    "GetStreaks",
    "GetTeamRecordWhenStatisticCondition",
    "GetTeamRecordForTeamId",
    "GetTeamRecordForTeamIdandSeasonYearId",
    "GetMembers",
    "Contains",
    "ToString",
    "get_PersonsFullName",
    "set_PersonsFullName",
    "get_PersonsLastName",
    "set_PersonsLastName",
    "get_FilteredTeamsIds",
    "set_FilteredTeamsIds",
    "get_Count",
    "get_IsReadOnly",
    "get_Keys",
    "get_Values",
    "APName",
    "AddFilteredTeamId",
    "ShowFilteredTeamIds",
    "OneOf",
    "APNumber",
    "APNumberInt",
    "APDate",
    "APMonth",
    "APSuffix",
    "APSuffixInt",
    "MLBFormat",
    "APRunLine",
    "APGameTime",
    "ERAFormat",
    "FormatGold",
    "GameClock",
    "PluralizeDecimal",
    "IfRanked",
    "Commafy",
    "CompareTable",
    "BasketballStats",
    "BasketballStatsPRA",
    "BBallDouble",
    "BaseballStats",
    "NoAbBaseballStats",
    "NoHrBaseballStats",
    "NoRbiBaseballStats",
    "BaseballStatsNos",
    "NoAbBaseballStatsNos",
    "NoHrBaseballStatsNos",
    "SingleGamePitchingStats",
    "HockeyStats",
    "Slugify",
    "DeSlugify",
    "Possessive",
    "AAn",
    "AAn",
    "ParseForTimeZone",
    "AvailableCommands",
    "GetMatchOdds",
    "PlusMinus",
    "GetMatchPrediction"
  ]
  let operators = ["==", "!=", "<", ">", "<=", ">=", "in", "not", "or", "and"]

  keywords = new RegExp(`^\\b(${keywords.join("|")})\\b`)
  operators = new RegExp(`^\\b(${operators.join("|")})\\b`)

  CodeMirror.defineSimpleMode("scriban-tags", {
    start: [{ regex: /\{\{/, push: "scriban", token: "tag" }],
    scriban: [
      { regex: /\}\}/, pop: true, token: "tag" },

      // Double and single quotes
      { regex: /"(?:[^\\"]|\\.)*"?/, token: "def" },
      { regex: /'(?:[^\\']|\\.)*'?/, token: "string" },

      // Handlebars keywords
      { regex: keywords, token: "variable" },

      // Numeral
      { regex: /\d+/i, token: "number" },

      // Atoms like = and .
      { regex: operators, token: "atom" },

      // Catch all group used for Intellisense
      { regex: /([^\s]+)/, token: "none" }
    ],
    meta: {
      blockCommentStart: 'one_of "',
      blockCommentEnd: '"'
    }
  })

  CodeMirror.defineMode("scriban", function (config, parserConfig) {
    let scriban = CodeMirror.getMode(config, "scriban-tags")
    if (!parserConfig || !parserConfig.base) {
      return scriban
    }
    return CodeMirror.multiplexingMode(
      CodeMirror.getMode(config, parserConfig.base),
      {
        open: "{{",
        close: "}}",
        mode: scriban,
        parseDelimiters: true
      }
    )
  })

  CodeMirror.defineMIME("text/x-scriban-template", "scriban")

  let Pos = CodeMirror.Pos

  // eslint-disable-next-line
  var scribanKeywords =
    `apname one_of apnumber mlbformat aprun_line eraformat basketball_stats hockey_stats string null if else end for in case when tablerow unless raw regex string timespan empty func written_value numeric_ordinal_suffixes written_ordinal_suffixes home_team away_team team_a team_b possessive aan slugify deslugify parsefortimezone parsefortimezone2 apdate apdate2 get_Count get_IsReadOnly GetStreaks GetTeamRecordWhenStatisticCondition GetTeamRecordForTeamId GetTeamRecordForTeamIdandSeasonYearId GetMembers Contains ToString get_PersonsFullName set_PersonsFullName get_PersonsLastName set_PersonsLastName get_FilteredTeamsIds set_FilteredTeamsIds get_Count get_IsReadOnly get_Keys get_Values APName AddFilteredTeamId ShowFilteredTeamIds OneOf APNumber APNumberInt APDate APMonth APSuffix APSuffixInt MLBFormat APRunLine APGameTime ERAFormat FormatGold GameClock PluralizeDecimal IfRanked Commafy CompareTable BasketballStats BasketballStatsPRA BBallDouble BaseballStats NoAbBaseballStats NoHrBaseballStats NoRbiBaseballStats BaseballStatsNos NoAbBaseballStatsNos NoHrBaseballStatsNos SingleGamePitchingStats HockeyStats Slugify DeSlugify Possessive AAn AAn ParseForTimeZone AvailableCommands GetMatchOdds PlusMinus GetMatchPrediction`.split(
      " "
    )

  // Execute callback 'f' and pass the individual array item 'i' to the callback
  function forEach(arr, f) {
    for (let i = 0, e = arr.length; i < e; ++i) {
      f(arr[i])
    }
  }

  // Will return true or false
  // Does the given array contain the given item
  function arrayContains(arr, item) {
    if (!Array.prototype.indexOf) {
      let i = arr.length
      while (i--) {
        if (arr[i] === item) {
          return true
        }
      }
      return false
    }
    return arr.indexOf(item) !== -1
  }

  function getCompletions(token, keywords) {
    const found = []
    const start = token.string

    function maybeAdd(str) {
      if (str.lastIndexOf(start, 0) === 0 && !arrayContains(found, str)) {
        found.push(str)
      }
    }

    forEach(keywords, maybeAdd)
    return found
  }

  function scriptHint(editor, keywords, getToken) {
    // Find the token at the cursor
    const cur = editor.getCursor()
    let token = getToken(editor, cur)

    // Get the inner context of the parent mode.
    let cmState = CodeMirror.innerMode(editor.getMode(), token.state)

    // If current state is not scriban, do not show hint
    if (cmState.state.state !== "scriban") {
      return
    }

    // If it's not a 'word-style' token, ignore the token.
    if (!/^[\w$_]*$/.test(token.string)) {
      token = {
        start: cur.ch,
        end: cur.ch,
        string: "",
        state: token.state,
        type: token.string === "." ? "property" : null
      }
    } else if (token.end > cur.ch) {
      token.end = cur.ch
      token.string = token.string.slice(0, cur.ch - token.start)
    }

    return {
      list: getCompletions(token, keywords),
      from: Pos(cur.line, token.start),
      to: Pos(cur.line, token.end)
    }
  }

  function scribanHint(editor) {
    return scriptHint(editor, scribanKeywords, function (e, cur) {
      return e.getTokenAt(cur)
    })
  }

  // Modified from https://codemirror.net/5/addon/hint/javascript-hint.js
  CodeMirror.registerHelper("hint", "scriban", scribanHint)
})
