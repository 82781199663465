import React from "react"
import PropTypes from "prop-types"
//import Button from "components/CustomButtons/Button.jsx"
import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import Grow from "@mui/material/Grow"
import Paper from "@mui/material/Paper"
import Popper from "@mui/material/Popper"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import IconButton from "@mui/material/IconButton"
import InfoIcon from "@mui/icons-material/Info"
import { Cookies } from "tools/storage"
const cookies = new Cookies()
const options = ["UPLOAD LINKS", "UPLOAD VARIANTS"]

function LinkAndVariantUploadButton(props) {
  //This Custom button allows you to upload content from your computer

  //You can pass out the selected file to the parent:
  //props.doFileUpload - for when you click and select a file, you can pass that file using the function "doFileUpload" to the parent for processing it

  const hiddenFileInput = React.useRef(null)
  const handleCustomClick = (event, index) => {
    props.otherClickEvent && props.otherClickEvent(props.buttonPurpose)
    hiddenFileInput.current.click()
  }
  const currentUploadType = cookies.get("offerUploadType") || ""

  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const [selectedIndex, setSelectedIndex] = React.useState(
    currentUploadType === "Variant" ? 1 : currentUploadType === "Link" ? 0 : 0
  )

  const handleClick = e => {
    handleCustomClick(e, selectedIndex)
  }

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index)
    setOpen(false)
    //handleCustomClick(event, index)
    cookies.set(
      "offerUploadType",
      index === 0 ? "Link" : index === 1 ? "Variant" : "None"
    )
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  return (
    <>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={e => {
          console.log(`In the onChange, selected index is:${selectedIndex}`)
          if (selectedIndex === 0) {
            props.doLinkFileUpload(e)
          } else if (selectedIndex === 1) {
            props.doVariantFileUpload(e)
          }
        }}
        accept={props.acceptedSuffix}
        style={{ display: "none" }}
      />
      <React.Fragment>
        <ButtonGroup variant="contained" ref={anchorRef}>
          <Button
            onClick={handleClick}
            style={{ fontSize: "12px", padding: "10px 15px" }}
          >
            {options[selectedIndex]}
          </Button>
          <Button
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        disabled={index === 2}
                        selected={index === selectedIndex}
                        onClick={event => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </React.Fragment>
      {selectedIndex === 0 && (
        <IconButton
          aria-label="Bulk Upload Offer Links"
          color="primary"
          className={props.leftJustifyStyle}
          onClick={() => {
            props.setDialogInfoOpen(true)
            props.setDialogInfoMessage("Link")
            //props.setDialogInfoMessage(props.linkOfferBulkUploadInfo())
          }}
        >
          <InfoIcon />
        </IconButton>
      )}
      {selectedIndex === 1 && (
        <IconButton
          aria-label="Bulk Upload Offer Variants"
          color="primary"
          className={props.leftJustifyStyle}
          onClick={() => {
            props.setDialogInfoOpen(true)
            props.setDialogInfoMessage("Variant")
            //props.setDialogInfoMessage(props.linkOfferBulkUploadInfo())
          }}
        >
          <InfoIcon />
        </IconButton>
      )}
    </>
  )
}

LinkAndVariantUploadButton.propTypes = {
  doLinkFileUpload: PropTypes.func,
  doVariantFileUpload: PropTypes.func,
  setDialogInfoOpen: PropTypes.func,
  setDialogInfoMessage: PropTypes.func,
  linkOfferBulkUploadInfo: PropTypes.func
}
export default LinkAndVariantUploadButton
