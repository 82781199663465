import React from "react"
import PropTypes from "prop-types"
import { Typography } from "@mui/material"

const propTypes = {
  body1: PropTypes.string,
  body2: PropTypes.string
}

const Confirmation = ({ body1, body2 }) => (
  <div className="dialog__confirmation">
    {body1 && (
      <Typography component="p" variant="body1" color="textPrimary">
        {body1}
      </Typography>
    )}
    {body2 && (
      <Typography component="p" variant="body1" color="textPrimary">
        {body2}
      </Typography>
    )}
  </div>
)

Confirmation.propTypes = propTypes
export default Confirmation
