import { action, observable, decorate, computed } from "mobx"
import CTAPreviewDependencyDAO from "../daos/ctaPreviewDependencyDAO"
import Cta_v2DAO from "../daos/cta_v2DAO"

export default class CTAPreviewDependencyStore {
  rootStore
  ctaPreviewDependency
  ctaPreviewDependencyV2
  ctaPreviewDependencyBackup
  ctaPreviewDependencyV2Backup
  ctaPreviewDependencyChangeLog
  ctaPreviewDependencyV2ChangeLog

  constructor(rootStore) {
    this.rootStore = rootStore
    this.dehydrate()
  }
  /**
   * Computeds
   */
  get isDependencyLoaded() {
    return !!this.ctaPreviewDependency?.id
  }

  get isDependencyLoadedV2() {
    return !!this.ctaPreviewDependencyV2?.id
  }
  /**
   * Methods
   */

  getPreviewDependencyByIntent = async intentId => {
    try {
      const previewDependencyResponse =
        await Cta_v2DAO.getPreviewDependencyByIntent(intentId)
      this.setCTAPreviewDependencyV2(previewDependencyResponse)
      this.setCTAPreviewDependencyV2Backup(previewDependencyResponse)
    } catch (err) {
      console.error("Error: ", err)
    }
  }
  getCTAPreviewDependency = async () => {
    try {
      const previewDependencyResponse =
        await CTAPreviewDependencyDAO.getCTADependency()
      this.setCTAPreviewDependency(previewDependencyResponse)
      this.setCTAPreviewDependencyBackup(previewDependencyResponse)
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  getCTAPreviewDependencyChangeLog = async () => {
    try {
      const response =
        await CTAPreviewDependencyDAO.getCTAPreviewDependencyChangeLog()
      this.setCTAPreviewDependencyChangeLog(response)
    } catch (err) {
      console.error("Error: ", err)
    }
  }
  getCTAPreviewDependencyV2ChangeLog = async previewDependencyId => {
    try {
      const response =
        await CTAPreviewDependencyDAO.getCTAPreviewDependencyV2ChangeLog(
          previewDependencyId
        )
      this.setCTAPreviewDependencyV2ChangeLog(response)
    } catch (err) {
      console.error("Error: ", err)
    }
  }
  putCTAPreviewDependency = async dependencyObject => {
    try {
      const updatedPreviewDependency =
        await CTAPreviewDependencyDAO.postCTADependency(dependencyObject)
      const newPreviewDependency = {
        ...dependencyObject,
        id: updatedPreviewDependency
      }
      this.setCTAPreviewDependency(newPreviewDependency)
      this.setCTAPreviewDependencyBackup(newPreviewDependency)
      return updatedPreviewDependency
    } catch (err) {
      console.error("Error: ", err)
    }
  }
  putCTAPreviewDependencyV2 = async dependencyObject => {
    try {
      const updatedPreviewDependency =
        await CTAPreviewDependencyDAO.postCTADependencyV2(dependencyObject)
      const newPreviewDependency = {
        ...dependencyObject,
        id: updatedPreviewDependency
      }
      this.setCTAPreviewDependencyV2(newPreviewDependency)
      this.setCTAPreviewDependencyV2Backup(newPreviewDependency)
      return updatedPreviewDependency
    } catch (err) {
      console.error("Error: ", err)
    }
  }
  updateCTAPreviewDependency = async dependencyObject => {
    try {
      this.setCTAPreviewDependency(dependencyObject)
      return dependencyObject
    } catch (err) {
      console.error("Error: ", err)
    }
  }
  updateCTAPreviewDependencyV2 = async dependencyObject => {
    try {
      this.setCTAPreviewDependencyV2(dependencyObject)
      return dependencyObject
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  /**
   * Reactions
   */

  /**
   * Internal Actions
   */
  dehydrate() {
    this.dehydrateNarrative()
  }

  dehydrateNarrative() {
    this.setIsDirty(false)
  }

  setIsDirty(value) {
    this.isDirty = value
  }

  setCTAPreviewDependency(value) {
    this.ctaPreviewDependency = value
  }

  setCTAPreviewDependencyV2(value) {
    this.ctaPreviewDependencyV2 = value
  }

  setCTAPreviewDependencyBackup(value) {
    this.ctaPreviewDependencyBackup = value
  }

  setCTAPreviewDependencyV2Backup(value) {
    this.ctaPreviewDependencyV2Backup = value
  }

  setCTAPreviewDependencyChangeLog(value) {
    this.ctaPreviewDependencyChangeLog = value
  }

  setCTAPreviewDependencyV2ChangeLog(value) {
    this.ctaPreviewDependencyV2ChangeLog = value
  }
}

/**
 * object decorators
 */
decorate(CTAPreviewDependencyStore, {
  // computeds
  isDependencyLoaded: computed,
  isDependencyLoadedV2: computed,
  // observables
  ctaPreviewDependency: observable,
  ctaPreviewDependencyV2: observable,
  ctaPreviewDependencyChangeLog: observable,
  ctaPreviewDependencyV2ChangeLog: observable,
  // actions
  setCTAPreviewDependency: action.bound,
  setCTAPreviewDependencyV2: action.bound,
  setCTAPreviewDependencyChangeLog: action.bound,
  setCTAPreviewDependencyV2ChangeLog: action.bound
})
