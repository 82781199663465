import React, { useMemo, useContext, useRef, useState } from "react"
import {
  Box,
  FormControlLabel,
  TextField,
  LinearProgress,
  Popover,
  Switch,
  Grid
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import PropTypes from "prop-types"

import NarrativePreviewControl from "components/NarrativeAdmin/NarrativePreviewer/NarrativePreviewControl"
import { NarrativeContext } from "contexts/narrative-context"
import Button from "components/CustomButtons/Button"
import ModelPreviewLink from "components/NarrativeAdmin/NarrativePreviewer/ModelPreviewLink"
import ElementSelectionList from "components/NarrativeAdmin/NarrativePreviewer/ElementSelectionList"
import {
  contentSections,
  mostUtilizedContentSections
} from "components/NarrativeAdmin/Data/contentSections"

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1, 2)
  },
  textField: {
    margin: theme.spacing(0, 1, 2, 1)
  },
  buttonGroup: {
    "& > *": {
      marginLeft: theme.spacing(1)
    }
  }
}))

const NarrativePreviewer = ({
  handleBuildFeedPreview,
  handleChangeItemIndex,
  isLoadingPreview,
  itemIndex,
  modelPreviewUrl,
  narrativePreviewContent = {},
  previewElapsedTimeMilliseconds
}) => {
  const [debugAllTriggers, setDebugAllTriggers] = useState(false)
  const [ignoreKillswitch, setIgnoreKillswitch] = useState(false)
  const [showBlockFilter, setShowBlockFilter] = useState(false)
  const [activeElementIds, setActiveElementIds] = useState(
    mostUtilizedContentSections.map(section => section.id)
  )

  const {
    contentId,
    contentType,
    loading: narrativeLoading,
    modelName
  } = useContext(NarrativeContext)

  const filterAnchorElement = useRef(null)
  const classes = useStyles()

  const toggleBlockFilter = e => {
    if (filterAnchorElement.current === null) {
      filterAnchorElement.current = e.currentTarget
    }
    setShowBlockFilter(prev => !prev)
  }

  const handleToggleElementInFilter = elementId => {
    setActiveElementIds(prevIds =>
      prevIds.includes(elementId)
        ? prevIds.filter(id => id !== elementId)
        : [...prevIds, elementId]
    )
  }

  const narrativePreviewIsEmpty = !Object.keys(narrativePreviewContent)?.length

  const elementFilterOptions = useMemo(
    () =>
      narrativePreviewIsEmpty
        ? mostUtilizedContentSections
        : contentSections.filter(section => {
            const feedValue = narrativePreviewContent[section.renderedProperty]
            return !!feedValue && feedValue !== "null"
          }),
    [narrativePreviewContent]
  )

  const getActiveElements = (ids = []) =>
    elementFilterOptions.filter(section => ids.includes(section.id))

  return (
    <Box mt={1}>
      <Grid container className={classes.container} spacing={1}>
        <Grid item md={12}>
          <ModelPreviewLink
            contentId={contentId}
            contentType={contentType}
            modelName={modelName}
            modelPreviewUrl={modelPreviewUrl}
          />
        </Grid>
        <Grid item xs={12}>
          <span>
            Elapsed render time:{" "}
            {previewElapsedTimeMilliseconds
              ? `${previewElapsedTimeMilliseconds}ms`
              : ""}
          </span>
        </Grid>
        {narrativeLoading && <LinearProgress />}
        {contentType === "query" && (
          <Grid item xs={12}>
            <TextField
              style={{
                marginLeft: "10px",
                marginRight: "10px"
              }}
              className={classes.textField}
              type="number"
              label="Query Builder Results Index"
              id="itemIndex"
              value={itemIndex || ""}
              onChange={handleChangeItemIndex}
              margin="normal"
              min={0}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Box className={classes.buttonGroup}>
            <Button
              size="sm"
              disabled={isLoadingPreview}
              color="primary"
              onClick={() => {
                handleBuildFeedPreview({
                  debug: false,
                  debugAllTriggers,
                  ignoreKillswitch
                })
              }}
              title="Preview this Narrative based on the selected data model"
            >
              Preview
            </Button>
            <Button
              size="sm"
              disabled={isLoadingPreview}
              color="primary"
              onClick={() => {
                handleBuildFeedPreview({
                  debug: true,
                  debugAllTriggers,
                  ignoreKillswitch
                })
              }}
              title="Load every sentence"
            >
              QA Preview
            </Button>
            <Button
              size="sm"
              disabled={narrativePreviewIsEmpty}
              color="primary"
              onClick={toggleBlockFilter}
              title="Choose visible blocks in the previewer"
            >
              Filter Blocks
            </Button>
          </Box>
        </Grid>
        <Popover
          id="preview-block-visible-selection-popover"
          open={showBlockFilter}
          onClose={() => setShowBlockFilter(false)}
          anchorEl={filterAnchorElement.current}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
        >
          <ElementSelectionList
            activeElementIds={activeElementIds}
            elementOptions={elementFilterOptions}
            handleToggleElement={handleToggleElementInFilter}
          />
        </Popover>
        <Grid item xs={12}>
          <Box marginLeft={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={debugAllTriggers}
                  onChange={event => {
                    setDebugAllTriggers(event.target.checked)
                  }}
                  color="primary"
                  size="small"
                />
              }
              label="All Triggers?"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={ignoreKillswitch}
                  onChange={event => {
                    setIgnoreKillswitch(event.target.checked)
                  }}
                  color="primary"
                  size="small"
                />
              }
              label="Ignore Kill Switches?"
            />
          </Box>
        </Grid>
      </Grid>
      <NarrativePreviewControl
        activeElements={getActiveElements(activeElementIds)}
        renderedNarrative={narrativePreviewContent}
        isLoadingPreview={isLoadingPreview}
        showElementsInGroups
      />
    </Box>
  )
}

NarrativePreviewer.propTypes = {
  handleBuildFeedPreview: PropTypes.func,
  handleChangeItemIndex: PropTypes.func,
  isLoadingPreview: PropTypes.bool,
  itemIndex: PropTypes.number,
  modelPreviewUrl: PropTypes.string,
  narrativePreviewContent: PropTypes.object,
  previewElapsedTimeMilliseconds: PropTypes.number
}

export default NarrativePreviewer
