import CodeMirror from "codemirror"
;(function (mod) {
  mod(CodeMirror)
})(function (CodeMirror) {
  let keywords = [
    "apname",
    "one_of",
    "apnumber",
    "mlbformat",
    "aprun_line",
    "eraformat",
    "basketball_stats",
    "hockey_stats",
    "string",
    "null",
    "if",
    "else",
    "end",
    "for",
    "in",
    "case",
    "when",
    "tablerow",
    "unless",
    "raw",
    "regex",
    "string",
    "timespan",
    "empty",
    "func",
    "written_value",
    "numeric_ordinal_suffixes",
    "written_ordinal_suffixes",
    "home_team",
    "away_team",
    "team_a",
    "team_b",
    "possessive",
    "aan",
    "slugify",
    "deslugify",
    "parsefortimezone",
    "parsefortimezone2",
    "apdate",
    "apdate2",
    "get_Count",
    "get_IsReadOnly",
    "GetStreaks",
    "GetTeamRecordWhenStatisticCondition",
    "GetTeamRecordForTeamId",
    "GetTeamRecordForTeamIdandSeasonYearId",
    "GetMembers",
    "Contains",
    "ToString",
    "get_PersonsFullName",
    "set_PersonsFullName",
    "get_PersonsLastName",
    "set_PersonsLastName",
    "get_FilteredTeamsIds",
    "set_FilteredTeamsIds",
    "get_Count",
    "get_IsReadOnly",
    "get_Keys",
    "get_Values",
    "APName",
    "AddFilteredTeamId",
    "ShowFilteredTeamIds",
    "OneOf",
    "APNumber",
    "APNumberInt",
    "APDate",
    "APMonth",
    "APSuffix",
    "APSuffixInt",
    "MLBFormat",
    "APRunLine",
    "APGameTime",
    "ERAFormat",
    "FormatGold",
    "GameClock",
    "PluralizeDecimal",
    "IfRanked",
    "Commafy",
    "CompareTable",
    "BasketballStats",
    "BasketballStatsPRA",
    "BBallDouble",
    "BaseballStats",
    "NoAbBaseballStats",
    "NoHrBaseballStats",
    "NoRbiBaseballStats",
    "BaseballStatsNos",
    "NoAbBaseballStatsNos",
    "NoHrBaseballStatsNos",
    "SingleGamePitchingStats",
    "HockeyStats",
    "Slugify",
    "DeSlugify",
    "Possessive",
    "AAn",
    "AAn",
    "ParseForTimeZone",
    "AvailableCommands",
    "GetMatchOdds",
    "PlusMinus",
    "GetMatchPrediction"
  ]
  let operators = ["==", "!=", "<", ">", "<=", ">=", "in", "not", "or", "and"]

  keywords = new RegExp(`^\\b(${keywords.join("|")})\\b`)
  operators = new RegExp(`^\\b(${operators.join("|")})\\b`)

  CodeMirror.defineSimpleMode("eureka-tags", {
    start: [{ regex: /\{\{/, push: "eureka", token: "tag" }],
    eureka: [
      { regex: /\}\}/, pop: true, token: "tag" },

      // Double and single quotes
      { regex: /"(?:[^\\"]|\\.)*"?/, token: "def" },
      { regex: /'(?:[^\\']|\\.)*'?/, token: "string" },

      // Handlebars keywords
      { regex: keywords, token: "variable" },

      // Numeral
      { regex: /\d+/i, token: "number" },

      // Atoms like = and .
      { regex: operators, token: "atom" }
    ],
    meta: {
      blockCommentStart: 'one_of "',
      blockCommentEnd: '"'
    }
  })

  CodeMirror.defineMode("eureka", function (config, parserConfig) {
    let eureka = CodeMirror.getMode(config, "eureka-tags")
    if (!parserConfig || !parserConfig.base) {
      return eureka
    }
    return CodeMirror.multiplexingMode(
      CodeMirror.getMode(config, parserConfig.base),
      {
        open: "{{",
        close: "}}",
        mode: eureka,
        parseDelimiters: true
      }
    )
  })

  CodeMirror.defineMIME("text/x-eureka-template", "eureka")
})
