import React from "react"
import ReactTable from "react-table-6"
import PropTypes from "prop-types"
import TimeAgo from "react-timeago"
import NarrativeModelTriggersSnaphot from "./NarrativeModelTriggersSnaphot"
import Helpers from "tools/Helpers.js"
import matchSorter from "match-sorter"
import CircularProgress from "@mui/material/CircularProgress"
import { Visibility } from "@mui/icons-material"
import Button from "components/CustomButtons/Button"
import Tooltip from "@mui/material/Tooltip"

class NarrativeModelSnapshots extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      selected: props.selectedSnapshotId
    }
    this.toggleRow = this.toggleRow.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ selected: nextProps.selectedSnapshotId })
  }

  toggleRow(row) {
    if (row.original.id !== this.state.selected) {
      this.setState(
        {
          selected: row.original.id
        },
        this.props.onChange(row)
      )
    }
  }

  render() {
    if (!this.props.snapshots || this.props.snapshots.length < 1) {
      return <div>No snapshots found.</div>
    }

    const stringFilterProps = {
      filterable: true,
      filterAll: true,
      filterMethod: ({ id, value }, rows) =>
        matchSorter(rows, value, {
          keys: [id],
          threshold: matchSorter.rankings.CONTAINS
        })
    }

    return (
      <ReactTable
        data={this.props.snapshots}
        noDataText={
          this.state.isLoading ? <CircularProgress /> : "No snapshots found."
        }
        SubComponent={row => {
          let triggers = null
          if (row.original.triggers != null) {
            triggers = JSON.parse(row.original.triggers)
          }

          return (
            <div style={{ padding: "20px" }}>
              <h6>{row.type}</h6>
              <div>{row.name}</div>
              <h6>Recorded Triggers</h6>
              {row.original.triggers !== null && (
                <NarrativeModelTriggersSnaphot
                  triggers={triggers}
                  pageSize={10}
                />
              )}
            </div>
          )
        }}
        filterable
        columns={[
          {
            id: "checkbox",
            accessor: "",
            Header: "Actions",
            Cell: row => (
              <div className="actions-left">
                <Tooltip title="Preview using this snapshot" placement="top">
                  <Button
                    justIcon
                    round
                    simple
                    color="info"
                    className="info"
                    onClick={() => this.toggleRow(row)}
                  >
                    <Visibility />
                  </Button>
                </Tooltip>
              </div>
            ),
            filterable: false,
            sortable: false,
            width: 70
          },
          {
            Header: "Name",
            id: "name",
            accessor: "name",
            Cell: row =>
              `${
                row.original.name && row.original.name.includes("<")
                  ? row.original.name.split("<")[0]
                  : row.original.name
              }`,
            ...stringFilterProps
          },
          {
            Header: "Created Date",
            accessor: "createdDateTime",
            width: 120,
            Cell: row => (
              <div>
                <TimeAgo
                  date={row.original.createdDateTime}
                  title={Helpers.prettyDateTimeinPacificTimeZone(
                    row.original.createdDateTime
                  )}
                />
              </div>
            ),
            filterMethod: (filter, row) => {
              if (filter.value === "all") {
                return true
              }
              let futureTime = new Date()
              let pastTime = new Date()
              let createdOn = new Date(row.original.createdDateTime)
              if (filter.value === "within7days") {
                return (
                  createdOn > pastTime.setHours(pastTime.getHours() - 24 * 7) &&
                  createdOn <
                    futureTime.setHours(futureTime.getHours() + 24 * 7)
                )
              }
              if (filter.value === "within24hours") {
                return (
                  createdOn > pastTime.setHours(pastTime.getHours() - 24) &&
                  createdOn < futureTime.setHours(futureTime.getHours() + 24)
                )
              }
              if (filter.value === "within1hour") {
                return (
                  createdOn > pastTime.setHours(pastTime.getHours() - 1) &&
                  createdOn < futureTime.setHours(futureTime.getHours() + 1)
                )
              }
            },
            Filter: ({ filter, onChange }) => (
              <select
                onChange={event => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
              >
                <option value="all">Show All</option>
                <option value="within7days">Within 7 days</option>
                <option value="within24hours">Within 24 Hours</option>
                <option value="within1hour">Within 1 Hour</option>
              </select>
            )
          }
        ]}
        defaultPageSize={this.props.pageSize || 25}
        showPaginationTop
        showPaginationBottom={false}
        className="-striped -highlight"
        getTrProps={(state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              style: {
                background:
                  rowInfo.index === state.selectedSnapshotId
                    ? "#00afec"
                    : "white",
                color:
                  rowInfo.index === state.selectedSnapshotId ? "white" : "black"
              }
            }
          } else {
            return {}
          }
        }}
      />
    )
  }
}

NarrativeModelSnapshots.propTypes = {
  // Where the user to be redirected on clicking the avatar
  onChange: PropTypes.func,
  snapshots: PropTypes.arrayOf(PropTypes.object),
  selectedSnapshotId: PropTypes.number,
  pageSize: PropTypes.number
}

export default NarrativeModelSnapshots
