import React from "react"
import PropTypes from "prop-types"
import { TextField } from "@mui/material"
import { Autocomplete as AutoCompleteMUI } from "@mui/material"

const propTypes = {
  options: PropTypes.array,
  groupBy: PropTypes.func,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  renderOption: PropTypes.func,
  getOptionLabel: PropTypes.func.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func,
  isOptionEqualToValue: PropTypes.func,
  valueField: PropTypes.string,
  nameField: PropTypes.string,
  sortField: PropTypes.string
}

const Autocomplete = props => {
  const { label, nameField, valueField, sortField } = props
  const { options, value, onChange } = props

  const [displayValue, setDisplayValue] = React.useState("")
  const [combinedOptions, setCombinedOptions] = React.useState([])

  React.useEffect(() => {
    if (!options || options.length < 1) {
      return
    }
    const filteredOptions = options.filter(q => q[nameField] !== "")
    const newCombinedOptions = filteredOptions.map(option => ({
      ...option,
      combinedName: `${option[valueField]} - ${option[nameField]}`
    }))
    let sortedOptions = newCombinedOptions.sort((a, b) => {
      // Use toUpperCase() to ignore character casing
      const orgA = a[sortField] ? a[sortField].toUpperCase() : ""
      const orgB = b[sortField] ? b[sortField].toUpperCase() : ""
      let comparison = 0
      if (orgA > orgB) {
        comparison = 1
      } else if (orgA < orgB) {
        comparison = -1
      }
      return comparison
    })
    setCombinedOptions(sortedOptions)
    if (value) {
      let selectedOption =
        sortedOptions.find(option => option[valueField] === Number(value)) || {}
      setDisplayValue(selectedOption.combinedName)
    } else {
      setDisplayValue("")
    }
  }, [options])

  const handleChange = e => {
    const newId = e.target.innerText && e.target.innerText.split(" - ")[0]
    e.target.value = (newId && Number(newId)) || ""
    onChange(e)
  }

  return (
    <AutoCompleteMUI
      {...props}
      onChange={handleChange}
      options={combinedOptions || []}
      inputValue={displayValue || ""}
      renderOption={
        props.renderOption ||
        ((props, option) => <li {...props}>{option.combinedName}</li>)
      }
      getOptionLabel={
        props.getOptionLabel || (option => option && option.combinedName)
      }
      isOptionEqualToValue={option =>
        option[valueField] &&
        Number(option[valueField]) === value &&
        Number(value)
      }
      onInputChange={(event, newInputValue) => {
        if (event) {
          if (!newInputValue && value) {
            return
          } else {
            setDisplayValue(newInputValue)
          }
        }
      }}
      renderInput={params => (
        <TextField {...params} label={label} margin="normal" fullWidth />
      )}
    />
  )
}

Autocomplete.propTypes = propTypes
export default Autocomplete
