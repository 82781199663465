// eslint-disable-next-line no-unused-vars
import React from "react"
import PropTypes from "prop-types"
import { Field } from "formik"
import { TextareaAutosize, FormControl, FormHelperText } from "@mui/material"

const defaultProps = {
  fullWidth: false,
  hideLabel: false,
  margin: "normal",
  rowsMax: false,
  readOnly: false,
  required: false,
  type: "text"
}

const propTypes = {
  fullWidth: PropTypes.bool,
  helpText: PropTypes.string,
  hideLabel: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  margin: PropTypes.oneOf(["none", "dense", "normal"]),
  rowsMax: PropTypes.number,
  rowsMin: PropTypes.number,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool
}

const TextArea = props => {
  const {
    helpText,
    id,
    readOnly,
    required,
    label,
    name,
    fullWidth,
    rowsMax,
    rowsMin,
    margin
  } = props

  return (
    <FormControl fullWidth>
      <Field name={name}>
        {({ field, meta: { value, touched, error, submitCount } }) => {
          const hasErrors = (submitCount || touched) && error && true

          return (
            <FormControl
              className="form__control"
              hiddenLabel={label && true}
              required={required}
              fullWidth={fullWidth}
              error={hasErrors}
              disabled={readOnly}
              margin={margin}
            >
              <TextareaAutosize
                {...field}
                style={{ display: "block" }}
                defaultValue={value || ""}
                className="form__input"
                id={id}
                placeholder={label}
                required={required}
                readOnly={readOnly}
                minRows={rowsMin || 3}
                maxRows={rowsMax || 10}
                margin="dense"
              />
              {helpText && (
                <FormHelperText
                  className="form__helper-text"
                  error={hasErrors}
                  disabled={readOnly}
                  required={required}
                >
                  {helpText}
                </FormHelperText>
              )}
            </FormControl>
          )
        }}
      </Field>
    </FormControl>
  )
}

TextArea.defaultProps = defaultProps
TextArea.propTypes = propTypes
export default TextArea
