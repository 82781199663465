import React, { Component } from "react"
import withStyles from "@mui/styles/withStyles"
import { cardTitle } from "assets/jss/material-dashboard-pro-react"
import { globalSettings } from "variables/general"
import NarrativeRenderSchedules from "../NarrativeAdmin/NarrativeRenderSchedules"
import { Cookies } from "tools/storage"
import { observer, inject } from "mobx-react"
import { get } from "tools/request"

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
}

const useStore = component =>
  inject(({ store }) => ({
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization,
    organizationId: store.organizationStore.organizationId
  }))(observer(component))

class FeedScheduler extends Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      organizationId: this.props.organizationId,
      addFeed: false,
      editFeed: false,
      isLoading: false,
      isLoaded: false
    }
  }

  componentDidMount() {
    this.props.setShowChangeCurrentOrganization(true)
    if (this.state.organizationId) {
      this.loadSchedule(null, this.state.organizationId, false)
    }
  }

  componentDidUpdate() {
    if (this.state.organizationId !== this.props.organizationId) {
      this.setState({ organizationId: this.props.organizationId })
      this.loadSchedule(null, this.props.organizationId, false)
    }
  }

  loadSchedule = (rowIdBeingLoaded, organizationId, includeArchived) => {
    if (!organizationId) {
      return
    }
    this.setState({
      isLoading: true,
      scheduledJobs: [],
      rowIdBeingLoaded: rowIdBeingLoaded
    })
    get(
      `${globalSettings.apiBaseUrl}/api/narrativerenderschedule?organizationId=${organizationId}&includeArchived=${includeArchived}`
    )
      .then(Response => Response.json())
      .then(scheduledJobs => {
        scheduledJobs.forEach(item => {
          if (item.endDateTime === null) {
            item.endDateTime = new Date("1/1/2050") // A date for into the future
          }
        })
        this.setState({
          scheduledJobs: scheduledJobs,
          isLoading: false,
          rowIdBeingLoaded: -1,
          isLoaded: true
        })
      })
  }

  filterByProperty(scheduledJobs, currentProp) {
    let filteredJobsByProperties = []
    if (currentProp > 0) {
      filteredJobsByProperties = scheduledJobs.filter(itm => {
        let oid =
          itm.narrative &&
          itm.narrative.organization &&
          itm.narrative.organization.id
        return (
          oid === currentProp ||
          currentProp === itm.deliveryFeed?.organization?.id
        )
      })
      return filteredJobsByProperties
    } else {
      return scheduledJobs
    }
  }

  render() {
    if (!this.state.scheduledJobs) {
      return "Assembling..."
    }
    let cookies = new Cookies()
    let currentProperty = isNaN(cookies.get("currentProperty"))
      ? -3
      : cookies.get("currentProperty")

    return (
      <NarrativeRenderSchedules
        __scheduledJobs={this.state.scheduledJobs}
        scheduledJobs={this.filterByProperty(
          this.state.scheduledJobs,
          currentProperty
        )}
        loadScheduleCallback={this.loadSchedule}
        isLoading={this.state.isLoading}
        rowIdBeingLoaded={this.state.rowIdBeingLoaded}
      />
    )
  }
}

export default withStyles(styles)(useStore(FeedScheduler))
