import { fromJS } from "immutable" //TODO: Remove this dependency
import { action, observable, decorate } from "mobx"
import assetDAO from "../daos/assetDAO"

export default class AssetStore {
  rootStore
  isLoading
  assets
  assetCount

  constructor(rootStore) {
    this.rootStore = rootStore
    this.dehydrate()
  }

  /**
   * Computeds
   */

  /**
   * Methods
   */
  retrieveAssets = async (organizationId, assetLimit) => {
    try {
      this.setIsLoading(true)
      const webResponse = fromJS(
        await assetDAO.getAssetsByOrganization(organizationId, assetLimit)
      )
      this.setAssets(webResponse.get("content").get("assets").toJS())
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setIsLoading(false)
    }
  }

  retrieveAssetCount = async organizationId => {
    try {
      this.setIsLoading(true)
      const webResponse = fromJS(await assetDAO.getAssetCount(organizationId))
      this.setAssetCount(webResponse)
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setIsLoading(false)
    }
  }

  deleteAsset = async assetId => {
    try {
      const webResponse = fromJS(await assetDAO.deleteAsset(assetId))
      return webResponse
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setIsLoading(false)
    }
  }

  associateAssetAndLeague = async (assetId, leagueId) => {
    try {
      this.setIsLoading(true)
      const webResponse = fromJS(
        await assetDAO.associateAssetAndLeague(assetId, leagueId)
      )
      webResponse.get("content")
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setIsLoading(false)
    }
  }

  dissociateAssetAndLeague = async (assetId, leagueId) => {
    try {
      this.setIsLoading(true)
      const webResponse = fromJS(
        await assetDAO.dissociateAssetAndLeague(assetId, leagueId)
      )
      webResponse.get("content")
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setIsLoading(false)
    }
  }

  dissociateAllLeaguesFromAsset = async assetId => {
    try {
      this.setIsLoading(true)
      const webResponse = fromJS(
        await assetDAO.dissociateAllLeaguesFromAsset(assetId)
      )
      webResponse.get("content")
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setIsLoading(false)
    }
  }

  updateAssetIsAvailableForGraphicsTool = async (
    assetId,
    isAvailableForGraphicsTool
  ) => {
    try {
      let body = { assetId, isAvailableForGraphicsTool }
      const webResponse = fromJS(
        await assetDAO.updateIsAvailableForGraphicsTool(body)
      )
      return webResponse
    } catch (err) {
      console.error("Error: ", err)
    } finally {
    }
  }

  /**
   * Internal Actions
   */
  dehydrate() {
    this.setIsLoading(false)
    this.setAssets([])
    this.setAssetCount(0)
  }

  setIsLoading(value) {
    this.isLoading = value
  }

  setAssets(value) {
    this.assets = value
  }

  setAssetCount(value) {
    this.assetCount = value
  }
}

/**
 * object decorators
 */
decorate(AssetStore, {
  // observables
  assets: observable,
  assetCount: observable,
  isLoading: observable,

  // actions
  setAssets: action.bound,
  setAssetCount: action.bound,
  setIsLoading: action.bound
})
