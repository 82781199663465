import makeStyles from "@mui/styles/makeStyles"

const useCustomTableStyles = makeStyles({
  tableBox: {
    marginTop: "-2.5rem"
  },
  table: {
    minWidth: minWidth => minWidth || 650
  },
  tableHead: {
    color: "#000",
    fontWeight: "bold",
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: "#DDEAEF"
  },
  rowStyle: {
    "&:nth-of-type(even)": {
      backgroundColor: "rgba(0,0,0,0.05)"
    }
  },
  asc: {
    transform: "scaleY(-1)",
    opacity: 0.8
  },
  paginationSkeleton: {
    "&.MuiSkeleton-root": {
      marginLeft: "auto"
    }
  },
  noDataBox: {
    display: "flex",
    justifyContent: "center",
    padding: "2rem"
  },
  noDataText: {
    fontSize: "2rem"
  }
})

export { useCustomTableStyles }
