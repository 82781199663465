// eslint-disable-next-line no-unused-vars
import React from "react"
import ReactTable from "react-table-6"
import { useObserver } from "mobx-react"
import { Add, PermIdentity } from "@mui/icons-material"
import classnames from "classnames"
import matchSorter from "match-sorter"

import { useStore } from "contexts/rootContext"
import Page from "components/Page"
import Button from "components/CustomButtons/Button"
import FormattedDate from "components/FormattedDate"
import "assets/css/Components/Table.css"
import { Cookies } from "tools/storage"

function UserStoreData() {
  const store = useStore()
  return useObserver(() => ({
    accounts: store.accountStore.accounts,
    loading: store.accountStore.loading,
    openAddAccountModal: store.accountStore.openAddAccountModal,
    setAccountId: store.accountStore.setAccountId
  }))
}

const AccountList = () => {
  const { accounts, loading, openAddAccountModal, setAccountId } =
    UserStoreData()
  let cookies = new Cookies()
  let queryPageCount = Number(cookies.get("accountsListPerPageCount")) || 25

  const stringFilterProps = {
    filterable: true,
    filterAll: true,
    filterMethod: ({ id, value }, rows) =>
      matchSorter(rows, value, {
        keys: [id],
        threshold: matchSorter.rankings.CONTAINS
      })
  }

  const onChangeQueryPageCount = pageSize => {
    let cookies = new Cookies()
    cookies.set("accountsListPerPageCount", pageSize)
    queryPageCount = pageSize
  }

  const hasWindowObject = typeof window !== "undefined"
  const screenWidth = hasWindowObject ? window.innerWidth : null
  const screenHeight = hasWindowObject ? window.innerHeight : null

  const showPagination =
    screenWidth && screenHeight && (screenHeight > 959 || screenWidth > 959)

  return (
    <Page title="Accounts" loading={loading} icon={<PermIdentity />}>
      <Button
        size="sm"
        variant="contained"
        color="primary"
        onClick={() => openAddAccountModal()}
        startIcon={<Add />}
      >
        Add Account
      </Button>
      <ReactTable
        loading={loading}
        minRows={0}
        data={accounts.toJS()}
        defaultSorted={[{ id: "modifiedDateTime", desc: true }]}
        getTrProps={(state, rowInfo) => ({
          className: "selectable",
          title: "Edit Account",
          onClick: () => {
            cookies.set("accountFormLastAcctId", "") //To clear out the "Edit Account" component on the "Manage Accounts" page
            const {
              original: { id }
            } = rowInfo
            setAccountId(id)
          }
        })}
        columns={[
          {
            Header: "User Name",
            accessor: "username",
            ...stringFilterProps
          },
          {
            Header: "First Name",
            accessor: "firstName",
            ...stringFilterProps
          },
          {
            Header: "Last Name",
            accessor: "lastName",
            ...stringFilterProps
          },
          {
            Header: "Email",
            accessor: "email",
            ...stringFilterProps
          },
          {
            Header: "Org",
            accessor: "organization.name"
          },
          {
            Header: "Account Status",
            accessor: "status"
          },
          {
            Header: "Updated",
            accessor: "modifiedDateTime",
            width: 100,
            className: classnames("no-wrap", "center"),
            Cell: ({ value }) => <FormattedDate>{value}</FormattedDate>
          }
        ]}
        defaultPageSize={queryPageCount}
        onPageSizeChange={pageSize => {
          onChangeQueryPageCount(pageSize)
        }}
        showPaginationTop={showPagination}
        showPaginationBottom={false}
        className="-striped -highlight -scrollEntries"
        style={{
          maxHeight: showPagination
            ? `${(window.innerHeight * 0.71).toFixed(0)}px`
            : "auto"
        }}
      />
    </Page>
  )
}

export default AccountList
