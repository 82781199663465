import React, { useState, useEffect, useCallback } from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { Box, Menu, MenuItem } from "@mui/material"
import { TreeView } from "@mui/x-tree-view/TreeView"
import { TreeItem } from "@mui/x-tree-view/TreeItem"
import Cta_v2DAO from "daos/cta_v2DAO"
import LinearProgress from "@mui/material/LinearProgress"
import CircularProgress from "@mui/material/CircularProgress"
import { PropTypes } from "prop-types"
import queryString from "query-string"
import { useHistory } from "react-router-dom"
import makeStyles from "@mui/styles/makeStyles"

import { Link } from "react-router-dom"
import { IconButton } from "@mui/material"
import { Launch } from "@mui/icons-material"
import useContextMenuHelper from "tools/CTAv2/ContextMenuHelper"

const useTreeItemStyles = makeStyles(theme => ({
  expanded: {},
  label: {
    fontWeight: "inherit",
    color: "inherit"
  },
  labelIcon: {
    fontSize: "22px",
    marginLeft: "-7px",
    width: "15px"
  },
  labelDetail: {
    fontWeight: "inherit",
    flexGrow: 1,
    paddingLeft: theme.spacing(0.75)
  },
  iconButton: {
    position: "absolute",
    right: "10px",
    display: "none",
    padding: 0,
    margin: 0,
    "& > span > svg": {
      width: ".6em",
      height: ".6em"
    }
  },
  iconButton2: {
    position: "absolute",
    right: "30px",
    display: "none",
    padding: 0,
    margin: 0,
    "& > span > a > svg": {
      width: ".55em",
      height: ".55em",
      color: "rgba(0, 0, 0, 0.54)"
    }
  },
  labelPosition: {
    color: "#3f51b5",
    fontWeight: "bold",
    flexShrink: 0,
    marginLeft: theme.spacing(0.5)
  },
  flex: {
    display: "flex"
  },
  flexmiddle: {
    display: "flex",
    alignItems: "center",
    "&:hover $iconButton, &:hover $iconButton2": {
      display: "inline-block"
    }
  }
}))
function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    getLinkDisplayInfo: store.ctav2Store.getLinkDisplayInfo,
    disclaimerLinksInfo: store.ctav2Store.disclaimerLinksInfo,
    variantsByLinkId: store.ctav2Store.variantsByLinkId,
    getLinkInfo: store.ctav2Store.getLinkInfo,
    getVariantById: store.ctav2Store.getVariantById,
    setDialogWarningMessage: store.uiStore.setDialogWarningMessage,
    setDialogWarningOpen: store.uiStore.setDialogWarningOpen,
    dialogWarningSuccess: store.uiStore.dialogWarningSuccess,
    setDialogWarningSuccess: store.uiStore.setDialogWarningSuccess,
    setSuccessMessage: store.uiStore.setSuccessMessage,
    setIsSuccess: store.uiStore.setIsSuccess
  }))
}
function DisclaimerLinkBlocks(props) {
  const history = useHistory()
  const classes = useTreeItemStyles()
  const {
    getLinkDisplayInfo,
    disclaimerLinksInfo,
    variantsByLinkId,
    setDialogWarningMessage,
    setDialogWarningOpen,
    dialogWarningSuccess,
    setDialogWarningSuccess,
    setSuccessMessage,
    setIsSuccess
  } = useStoreData()
  const {
    autoLoadId,
    goToDisclaimerLinkForm,
    linkInformation,
    fetchVariantById
  } = props
  //const [newLink, setNewLink] = useState(false)
  const [selectedLink, setSelectedLink] = useState({})
  const [expanded, setExpanded] = useState([])
  const [contextLinkMenuAnchor, setContextLinkMenuAnchor] = useState(null)
  const [contextVariantMenuAnchor, setContextVariantMenuAnchor] = useState(null)
  const [linkMenuInfo, setLinkMenuInfo] = useState(null)
  const [variantMenuInfo, setVariantMenuInfo] = useState(null)
  const [actionType, setActionType] = useState("")
  const [delayedAction, setDelayedAction] = useState("")

  const [variantsLoaded, setVariantsLoaded] = useState(false)
  const [loadingVariants, setLoadingVariants] = useState(false)
  const [loadingDisclaimerLinks, setLoadingDisclaimerLinks] = useState(false)
  const [linkDisplayInfo, setLinkDisplayInfo] = useState([])
  const [linkDisplayObject, setLinkDisplayObject] = useState({})
  const [isExecutingActions, setIsExecutingActions] = useState(false)
  const queryParams = queryString.parse(window.location.search.toLowerCase())

  const _INTENT_ID = 2
  const _LINK_PAGE_NAME = "disclaimer"
  const {
    handleVariantMenuOpen,
    handleContextMenuLinkClose,
    handleContextMenuVariantClose,
    handleVariantRightClick,
    handleLinkRightClick,
    handleAddVariantClick,
    handleVariantClick,
    handleLinkMenuOpen,
    fireLinkAction,
    fireVariantAction
  } = useContextMenuHelper({
    setContextVariantMenuAnchor,
    setVariantMenuInfo,
    setContextLinkMenuAnchor,
    setLinkMenuInfo,
    setIsExecutingActions,
    fetchVariantById,
    setDialogWarningMessage,
    setSuccessMessage,
    setIsSuccess,
    Cta_v2DAO,
    setActionType,
    setDialogWarningOpen,
    setDelayedAction,
    actionType,
    linkMenuInfo,
    getLinkDisplayInfo,
    variantMenuInfo,
    queryParams,
    history,
    props,
    _INTENT_ID,
    _LINK_PAGE_NAME
  })

  const getVariantInfo = (linkId, ldobj) => {
    setLoadingVariants(true)
    const getLinkVariants = Cta_v2DAO.getVariantsByLinkId(linkId)
    getLinkVariants.then(results => {
      if (results) {
        if (results && results.length > 0) {
          let newObj = ldobj || linkDisplayObject
          Object.keys(newObj).forEach(ldo => {
            newObj[ldo].forEach((itm, j) => {
              if (linkId === itm.id) {
                itm.variants = results
                newObj[ldo][j].variants = results
              }
            })
          })
          setLinkDisplayObject(newObj)
          setVariantsLoaded(true)
          setLoadingVariants(false)
        } else {
          setVariantsLoaded(true)
          setLoadingVariants(false)
        }
      }
    })
  }

  const variantAlreadyFetched = id => {
    let variantsLoaded = false
    Object.keys(linkDisplayObject).forEach(ldo => {
      linkDisplayObject[ldo].forEach(itm => {
        if (itm.id === id && itm.variants) {
          variantsLoaded = true
        }
      })
    })
    return variantsLoaded
  }

  const getFullVariantName = itm => `${itm.name} - ${itm.displayStyle}`
  const sortByDisclaimerVariantName = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const aString = getFullVariantName(a)
    const bString = getFullVariantName(b)
    const stringA = aString ? aString.toUpperCase() : ""
    const stringB = bString ? bString.toUpperCase() : ""
    let comparison = 0
    if (stringA > stringB) {
      comparison = 1
    } else if (stringA < stringB) {
      comparison = -1
    }
    return comparison
  }

  const sortByObjKey = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const stringA = a ? a.toUpperCase() : ""
    const stringB = b ? b.toUpperCase() : ""
    let comparison = 0
    if (stringA > stringB) {
      comparison = 1
    } else if (stringA < stringB) {
      comparison = -1
    }
    return comparison
  }

  useEffect(() => {
    if (dialogWarningSuccess) {
      if (delayedAction === "Archive-Link") {
        setActionType("Archive")
        fireLinkAction("Archive")
        setDelayedAction("")
      } else if (delayedAction === "Archive-Variant") {
        setActionType("Archive")
        fireVariantAction("Archive")
        setDelayedAction("")
      }
      setDialogWarningSuccess(false)
      setDialogWarningOpen(false)
    } else if (!dialogWarningSuccess) {
      if (actionType === "Copy:Link:Pending") {
        setActionType("")
      }
    }
  }, [dialogWarningSuccess])

  useEffect(() => {
    if (!linkDisplayInfo.length) {
      getLinkDisplayInfo(2)
      setLoadingDisclaimerLinks(true)
    }
  }, [])

  useEffect(() => {
    if (variantsByLinkId && variantsByLinkId.length > 0) {
      console.log(variantsByLinkId)
      let newObj = linkDisplayObject
      Object.keys(newObj).forEach(ldo => {
        newObj[ldo].forEach((itm, j) => {
          if (variantsByLinkId[0].linkId === itm.id) {
            itm.variants = variantsByLinkId
            newObj[ldo][j].variants = variantsByLinkId
          }
        })
      })
      setLinkDisplayObject(newObj)
    }
  }, [variantsByLinkId])

  useEffect(() => {
    if (disclaimerLinksInfo?.length) {
      setLinkDisplayInfo(disclaimerLinksInfo)
      setLoadingDisclaimerLinks(false)
    } else if (
      typeof disclaimerLinksInfo === "object" &&
      !!disclaimerLinksInfo &&
      disclaimerLinksInfo.length === 0
    ) {
      setLoadingDisclaimerLinks(false)
    }
  }, [disclaimerLinksInfo])

  useEffect(() => {
    if (variantsLoaded) {
      setVariantsLoaded(false)
    }
  }, [variantsLoaded])

  useEffect(() => {
    if (autoLoadId) {
      console.log(`qs detected, value:${autoLoadId}`)
      const itm = { id: autoLoadId, navigate: true }
      goToDisclaimerLinkForm(itm)
      setSelectedLink(itm)
    }
  }, [autoLoadId])

  useEffect(() => {
    if (linkDisplayInfo.length) {
      const obj = {}
      linkDisplayInfo?.forEach(itm => {
        const organizationName =
          itm.organizations.length > 1
            ? "Multi"
            : itm.organizations.length === 1
            ? itm.organizations[0].name
            : "None"
        const companyName =
          itm.companies.length > 1
            ? "Multi"
            : itm.companies.length === 1
            ? itm.companies[0].name
            : "None"
        const longKey = `${organizationName} - ${companyName} - ${itm.linkName}`
        if (!obj[longKey]) {
          obj[longKey] = []
        }
        obj[longKey].push(itm)
      })
      //setLinkDisplayObject(obj)
      if (
        expanded &&
        expanded[0] === "DisclaimerLinkBlocks" &&
        queryParams["linkid"] &&
        (linkInformation?.navigate ||
          Object.keys(linkDisplayObject).length !== linkDisplayInfo.length)
      ) {
        Object.keys(obj).forEach(k => {
          obj[k].forEach(itm => {
            const linkid = queryParams["linkid"]
            if (itm.id === Number(linkid)) {
              const copyExpanded = ["DisclaimerLinkBlocks"]
              copyExpanded.push(`${k}-${itm.id}`)
              setExpanded(copyExpanded)
              getVariantInfo(itm.id, obj)
            }
          })
        })
      }
      setLinkDisplayObject(obj)
    }
  }, [linkDisplayInfo])

  useEffect(() => {
    const linkId = Number(queryParams["linkid"])
    const variantId = Number(queryParams["variantid"])
    if (linkId && linkId !== selectedLink.id) {
      if (variantId) {
        let theRest = ""
        if (selectedLink?.variants) {
          //This is true when navigating
          selectedLink?.variants?.forEach(x => {
            if (x.id === variantId) {
              theRest = `&variantid=${variantId}`
            }
          })
          history.push(
            `/portal/manage-ctas/disclaimer?linkid=${
              selectedLink?.id ? selectedLink.id : linkId
            }${theRest}`
          )
        } else {
          //This would be a reload from cut and pasted URL
          history.push(
            `/portal/manage-ctas/disclaimer?linkid=${
              selectedLink?.id ? selectedLink.id : linkId
            }&variantid=${variantId}`
          )
        }
        //console.log(selectedLink?.variants)
      } else {
        history.push(`/portal/manage-ctas/disclaimer?linkid=${selectedLink.id}`)
      }
    } else if (!linkId) {
      if (linkInformation?.id) {
        history.push(
          `/portal/manage-ctas/disclaimer?linkid=${linkInformation.id}`
        )
      }
    }
  }, [selectedLink])

  useEffect(() => {
    if (linkInformation.navigate) {
      setExpanded(["DisclaimerLinkBlocks"])
      //setNewLink(false)
    } else if (linkInformation.new) {
      history.push(`/portal/manage-ctas/disclaimer`)
      //setNewLink(true)
    } else {
      //setNewLink(false)
    }
  }, [linkInformation])

  const TreeContextMenuLink = useCallback(
    () => (
      <Menu
        className={classes.offerMenu}
        anchorEl={contextLinkMenuAnchor}
        open={Boolean(contextLinkMenuAnchor)}
        onClose={handleContextMenuLinkClose}
      >
        <MenuItem onClick={handleAddVariantClick}>Add CTA Variant</MenuItem>
        <Link
          to={`/portal/manage-ctas/disclaimer?linkid=${linkMenuInfo?.id}`}
          target="_blank"
          style={{ color: "rgba(0,0,0,.87)" }}
        >
          <MenuItem>
            <IconButton
              className={classes.iconButton2}
              aria-label={"Open in New window"}
              size="large"
            >
              <Launch />
            </IconButton>
            Open in New window
          </MenuItem>
        </Link>

        <MenuItem
          onClick={() => {
            handleLinkRightClick("Duplicate")
          }}
        >
          Duplicate
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleLinkRightClick("Archive")
          }}
        >
          Archive
        </MenuItem>
        {(actionType === "Copy:Variant:Pending" ||
          actionType === "Cut:Variant:Pending") && (
          <MenuItem
            onClick={() => {
              handleLinkRightClick("Paste")
            }}
          >
            Paste
          </MenuItem>
        )}
      </Menu>
    ),
    [contextLinkMenuAnchor]
  )

  const TreeContextMenuVariant = useCallback(
    () => (
      <Menu
        className={classes.offerMenu}
        anchorEl={contextVariantMenuAnchor}
        open={Boolean(contextVariantMenuAnchor)}
        onClose={handleContextMenuVariantClose}
      >
        <Link
          to={`/portal/manage-ctas/disclaimer?linkid=${variantMenuInfo?.linkId}&variantid=${variantMenuInfo?.id}`}
          target="_blank"
          style={{ color: "rgba(0,0,0,.87)" }}
        >
          <MenuItem>
            <IconButton
              className={classes.iconButton2}
              aria-label={"Open in New window"}
              size="large"
            >
              <Launch />
            </IconButton>
            Open in New window
          </MenuItem>
        </Link>
        <MenuItem
          onClick={() => {
            handleVariantRightClick("Copy")
          }}
        >
          Copy
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleVariantRightClick("Cut")
          }}
        >
          Cut
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleVariantRightClick("Duplicate")
          }}
        >
          Duplicate
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleVariantRightClick("Archive")
          }}
        >
          Archive
        </MenuItem>
      </Menu>
    ),
    [contextVariantMenuAnchor]
  )

  return (
    <>
      {isExecutingActions && <LinearProgress />}
      {loadingDisclaimerLinks && (
        <div className="circular">
          <CircularProgress />
        </div>
      )}
      {linkDisplayObject && Object.keys(linkDisplayObject).length > 0 && (
        <TreeView
          aria-label="CTAv2 Disclaimer Link Navigation"
          key="CTAv2DisclaimerLinkNavigation"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          sx={{
            flexGrow: 1,
            overflowY: "auto"
          }}
          expanded={expanded}
        >
          <TreeItem
            nodeId="DisclaimerLinkBlocks"
            label="Disclaimer Link Blocks"
            key="DisclaimerLinkBlocks"
            onClick={() => {
              if (expanded.length) {
                setExpanded([])
              } else {
                setExpanded(["DisclaimerLinkBlocks"])
              }
            }}
          >
            {linkDisplayObject &&
              Object.keys(
                Object.fromEntries(Object.entries(linkDisplayObject).sort())
              )
                .sort(sortByObjKey)
                .map(longKey => (
                  <>
                    {linkDisplayObject[longKey].map((itm, i) => (
                      <div
                        id={`${longKey}-${itm.id}`}
                        onContextMenu={event => handleLinkMenuOpen(event, itm)}
                      >
                        <TreeItem
                          title={`#${itm.id}-${longKey}`}
                          nodeId={`${longKey}-${itm.id}`}
                          key={`${longKey}-${itm.id}`}
                          label={
                            <div className={classes.flexmiddle}>
                              <div className={classes.flex}>
                                <Box
                                  className={classes.labelDetail}
                                  onClick={event => {
                                    goToDisclaimerLinkForm(itm)
                                    setSelectedLink(itm)
                                    if (!variantAlreadyFetched(itm.id)) {
                                      getVariantInfo(itm.id)
                                    }
                                    history.push(
                                      `/portal/manage-ctas/disclaimer?linkid=${itm.id}`
                                    )
                                    const eexpanded = ["DisclaimerLinkBlocks"]
                                    eexpanded.push(`${longKey}-${itm.id}`)
                                    setExpanded(eexpanded)
                                  }}
                                >
                                  {longKey}
                                </Box>
                              </div>
                            </div>
                          }
                          onClick={() => {
                            if (!variantAlreadyFetched(itm.id)) {
                              getVariantInfo(itm.id)
                            }
                            const eexpanded = ["DisclaimerLinkBlocks"]
                            eexpanded.push(`${longKey}-${itm.id}`)
                            setExpanded(eexpanded)
                          }}
                        >
                          {itm.variants
                            ?.sort(sortByDisclaimerVariantName)
                            .map((vrn, j) => (
                              <div
                                id={`${longKey}-link${itm.id}-${vrn.id}-${j}`}
                                onContextMenu={event =>
                                  handleVariantMenuOpen(event, vrn)
                                }
                              >
                                <TreeItem
                                  title={`#${vrn.id}-${getFullVariantName(
                                    vrn
                                  )}`}
                                  nodeId={`${longKey}-link${itm.id}-${vrn.id}-${j}`}
                                  label={`${vrn.name} - ${vrn.displayStyle}`}
                                  onClick={() => {
                                    handleVariantClick(vrn.id, itm.id)
                                  }}
                                />
                              </div>
                            ))}
                          {!itm.variants && !loadingVariants && (
                            <TreeItem
                              nodeId={`${longKey}-link${itm.id}-empty-placeholder}`}
                              label=""
                            />
                          )}
                          {!itm.variants && loadingVariants && (
                            <LinearProgress />
                          )}
                        </TreeItem>
                      </div>
                    ))}
                  </>
                ))}
          </TreeItem>
        </TreeView>
      )}
      <TreeContextMenuLink aria-label="CTAv2 Disclaimer Link Context Menu" />
      <TreeContextMenuVariant aria-label="CTAv2 Disclaimer Variant Context Menu" />
    </>
  )
}
DisclaimerLinkBlocks.propTypes = {
  goToDisclaimerEditor: PropTypes.func,
  goToDisclaimerLinkForm: PropTypes.func,
  cycleAddVariantForm: PropTypes.func,
  setOpenAddVariantForm: PropTypes.func,
  setShowVariant: PropTypes.func,
  setShowDisclaimerLinkForm: PropTypes.func,
  openAddVariantForm: PropTypes.bool
}

export default DisclaimerLinkBlocks
