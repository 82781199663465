import { action, observable, decorate, reaction } from "mobx"

export default class UIStore {
  rootStore
  isAccountDetailsLoaded
  isSiteInfoLoaded
  leaguesLoaded
  showChangeCurrentOrganization
  isPermissionsLoaded
  isError = false
  isSucess = false
  errorMessage = ""
  successMessage = ""
  dialogWarningOpen = false
  dialogWarningMessage = ""
  dialogInfoMessage = ""
  dialogWarningNoBtnOpen = false
  dialogWarningNoBtnMessage = ""
  dialogFailOpen = false
  dialogFailMessage = ""
  dialogSuccessOpen = false
  dialogSuccessMessage = ""

  constructor(rootStore) {
    this.rootStore = rootStore
  }

  /**
   * Computeds
   */

  /**
   * Methods
   */

  /**
   * Reactions
   */

  resetErrorMessage = reaction(
    () => this.isError,
    isError => {
      if (!isError) {
        setTimeout(() => this.setErrorMessage(""), 1000)
      }
    }
  )

  /**
   * Internal Actions
   */
  setIsAccountDetailsLoaded(value) {
    this.isAccountDetailsLoaded = value
  }

  setIsSiteInfoLoaded(value) {
    this.isSiteInfoLoaded = value
  }

  setIsLeaguesLoaded(value) {
    this.leaguesLoaded = value
  }

  setShowChangeCurrentOrganization(value) {
    this.showChangeCurrentOrganization = value
  }

  setIsPermissionsLoaded(value) {
    this.isPermissionsLoaded = value
  }

  setIsError(value) {
    this.isError = value
  }

  setErrorMessage(value) {
    this.errorMessage = value
  }

  setIsSuccess(value) {
    this.isSuccess = value
  }

  setSuccessMessage(value) {
    this.successMessage = value
  }

  setDialogWarningMessage(value) {
    this.dialogWarningMessage = value
  }

  setDialogWarningOpen(value) {
    this.dialogWarningOpen = value
  }
  setDialogWarningSuccess(value) {
    this.dialogWarningSuccess = value
  }

  setDialogInfoMessage(value) {
    this.dialogInfoMessage = value
  }

  setDialogInfoOpen(value) {
    this.dialogInfoOpen = value
  }

  setDialogSuccessMessage(value) {
    this.dialogSuccessMessage = value
  }

  setDialogSuccessOpen(value) {
    this.dialogSuccessOpen = value
  }

  setDialogFailMessage(value) {
    this.dialogFailMessage = value
  }

  setDialogFailOpen(value) {
    this.dialogFailOpen = value
  }

  setDialogWarningNoBtnOpen(value) {
    this.dialogWarningNoBtnOpen = value
  }

  setDialogWarningNoBtnMessage(value) {
    this.dialogWarningNoBtnMessage = value
  }
}

/**
 * object decorators
 */
decorate(UIStore, {
  //computeds

  // observables
  isAccountDetailsLoaded: observable,
  isSiteInfoLoaded: observable,
  leaguesLoaded: observable,
  showChangeCurrentOrganization: observable,
  isPermissionsLoaded: observable,
  isError: observable,
  isSuccess: observable,
  errorMessage: observable,
  successMessage: observable,
  dialogWarningMessage: observable,
  dialogWarningOpen: observable,
  dialogWarningSuccess: observable,
  dialogInfoMessage: observable,
  dialogInfoOpen: observable,
  dialogFailOpen: observable,
  dialogFailMessage: observable,
  dialogSuccessOpen: observable,
  dialogSuccessMessage: observable,
  dialogWarningNoBtnOpen: observable,
  dialogWarningNoBtnMessage: observable,

  // actions
  setIsAccountDetailsLoaded: action.bound,
  setIsSiteInfoLoaded: action.bound,
  setIsLeaguesLoaded: action.bound,
  setShowChangeCurrentOrganization: action.bound,
  setIsPermissionsLoaded: action.bound,
  setIsError: action.bound,
  setIsSuccess: action.bound,
  setErrorMessage: action.bound,
  setSuccessMessage: action.bound,
  setDialogWarningMessage: action.bound,
  setDialogWarningOpen: action.bound,
  setDialogWarningSuccess: action.bound,
  setDialogInfoMessage: action.bound,
  setDialogInfoOpen: action.bound,
  setDialogFailOpen: action.bound,
  setDialogFailMessage: action.bound,
  setDialogSuccessOpen: action.bound,
  setDialogSuccessMessage: action.bound,
  setDialogWarningNoBtnOpen: action.bound,
  setDialogWarningNoBtnMessage: action.bound
})
