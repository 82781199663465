//import { SvgIcon } from "@mui/material"
import React from "react"
import PropTypes from "prop-types"

//export default function Milestone() {
function Milestone(props) {
  return (
    <svg
      id="Layer_1"
      enableBackground="new 0 0 512.002 512.002"
      height={props.height}
      viewBox="0 0 512.002 512.002"
      width={props.width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fill={props.fillColor}
          d="m201.618 277.472c14.572 14.42 33.886 22.362 54.383 22.362 20.478 0 39.771-7.927 54.338-22.319l13.811-13.811-53.149-92.057v-45.248h121.206c13.604.121 20.434-16.073 10.966-25.606l-37.593-37.592 37.594-37.594c9.471-9.541 2.631-25.728-10.966-25.606h-136.207c-8.284 0-15 6.716-15 15v156.647l-53.149 92.058c6.092 6.093 13.766 13.766 13.766 13.766z"
        />
        <path
          fill={props.fillColor}
          d="m454.513 489.501-114.834-198.899-8.194 8.194c-20.225 20.016-47.032 31.039-75.484 31.039-28.451 0-55.259-11.022-75.484-31.037l-8.194-8.194-114.834 198.897c-5.813 9.789 1.61 22.64 12.99 22.5h371.043c11.384.139 18.802-12.715 12.991-22.5z"
        />
      </g>
    </svg>
  )
}
Milestone.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fillColor: PropTypes.string
}
export default Milestone
