import React from "react"
import PropTypes from "prop-types"
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material"

class RenderType extends React.Component {
  render() {
    return (
      <FormControl {...this.props}>
        <InputLabel>Render As</InputLabel>
        <Select
          type="select"
          name="RenderTypeinput"
          id="RenderType"
          label="Render As"
          value={this.props.value || ""}
          onChange={e => this.props.onChange(e.target.value)}
        >
          <MenuItem
            classes={{ selected: this.props.classes?.selected }}
            value=""
          >
            Select
          </MenuItem>
          {this.props.renderNoneOption && (
            <MenuItem
              classes={{ selected: this.props.classes?.selected }}
              value="none"
            >
              None
            </MenuItem>
          )}
          <MenuItem
            classes={{ selected: this.props.classes?.selected }}
            value="p"
          >
            p
          </MenuItem>
          <MenuItem
            classes={{ selected: this.props.classes?.selected }}
            value="div"
          >
            div
          </MenuItem>
          <MenuItem
            classes={{ selected: this.props.classes?.selected }}
            value="h1"
          >
            h1
          </MenuItem>
          <MenuItem
            classes={{ selected: this.props.classes?.selected }}
            value="h2"
          >
            h2
          </MenuItem>
          <MenuItem
            classes={{ selected: this.props.classes?.selected }}
            value="h3"
          >
            h3
          </MenuItem>
          <MenuItem
            classes={{ selected: this.props.classes?.selected }}
            value="h4"
          >
            h4
          </MenuItem>
          <MenuItem
            classes={{ selected: this.props.classes?.selected }}
            value="h5"
          >
            h5
          </MenuItem>
          <MenuItem
            classes={{ selected: this.props.classes?.selected }}
            value="ol"
          >
            ol
          </MenuItem>
          <MenuItem
            classes={{ selected: this.props.classes?.selected }}
            value="ul"
          >
            ul
          </MenuItem>
          <MenuItem
            classes={{ selected: this.props.classes?.selected }}
            value="li"
          >
            li
          </MenuItem>
          {/* <MenuItem classes={{ selected: this.props.classes?.selected }} value="article">article</MenuItem> */}
          <MenuItem
            classes={{ selected: this.props.classes?.selected }}
            value="aside"
          >
            aside
          </MenuItem>
          {/* <MenuItem classes={{ selected: this.props.classes?.selected }} value="details">details</MenuItem> */}
          {/* <MenuItem classes={{ selected: this.props.classes?.selected }} value="figcaption">figcaption</MenuItem> */}
          <MenuItem
            classes={{ selected: this.props.classes?.selected }}
            value="figure"
          >
            figure
          </MenuItem>
          <MenuItem
            classes={{ selected: this.props.classes?.selected }}
            value="footer"
          >
            footer
          </MenuItem>
          <MenuItem
            classes={{ selected: this.props.classes?.selected }}
            value="header"
          >
            header
          </MenuItem>
          {/*
          <MenuItem classes={{ selected: this.props.classes?.selected }} value="main">main</MenuItem>
          <MenuItem classes={{ selected: this.props.classes?.selected }} value="mark">mark</MenuItem>
          <MenuItem classes={{ selected: this.props.classes?.selected }} value="nav">nav</MenuItem>
          */}
          <MenuItem
            classes={{ selected: this.props.classes?.selected }}
            value="section"
          >
            section
          </MenuItem>
          {/* <MenuItem classes={{ selected: this.props.classes?.selected }} value="summary">summary</MenuItem> */}
          <MenuItem
            classes={{ selected: this.props.classes?.selected }}
            value="time"
          >
            time
          </MenuItem>
          <MenuItem
            classes={{ selected: this.props.classes?.selected }}
            value="apxh:p"
          >
            apxh:p
          </MenuItem>
          <MenuItem
            classes={{ selected: this.props.classes?.selected }}
            value="apxh:div"
          >
            apxh:div
          </MenuItem>
        </Select>
      </FormControl>
    )
  }
}

RenderType.propTypes = {
  // Where the user to be redirected on clicking the avatar
  onChange: PropTypes.func,
  value: PropTypes.string,
  renderNoneOption: PropTypes.bool
}

export default RenderType
