import React, { useState, useEffect } from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx"
import makeStyles from "@mui/styles/makeStyles"
import MenuItem from "@mui/material/MenuItem"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Grid from "@mui/material/Grid"

import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Button from "components/CustomButtons/Button.jsx"
import Clearfix from "components/Clearfix/Clearfix.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardIcon from "components/Card/CardIcon.jsx"
import { TabPanel } from "components/TabPanel/TabPanel"
import WebhookIcon from "assets/icons/WebhookIcon"
//MUI Icons
import Report from "@mui/icons-material/Report"
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline"
import Add from "@mui/icons-material/Add"

import OutboundWebhookTable from "components/Webhooks/OutboundWebhookTable"
import InBoundWebhookTable from "components/Webhooks/InBoundWebhookTable"
import CustomDSDialog from "components/CustomDialogs/CustomDSDialog"
import OutboundWebhookFormDialog from "components/Webhooks/OutboundWebhookFormDialog"

const useStyles = makeStyles(() => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  archiveSwitch: {
    display: "flex",
    paddingBottom: "20px"
  },
  orgGrid: {
    display: "flex"
  },
  managerForm: {
    paddingBottom: "16px"
  },
  authLabelInfo: {
    display: "flex",
    alignItems: "center"
  },
  archiveSwitchEdit: {
    display: "flex",
    justifyContent: "end"
  }
}))

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization,
    organizationId: store.organizationStore.organizationId,
    organizations: store.organizationStore.organizations,
    getOrganizations: store.organizationStore.getOrganizations,
    propertyId: store.organizationStore.propertyId,
    account: store.accountStore.account,
    isPermissionsLoaded: store.uiStore.isPermissionsLoaded,
    accountPermissions: store.accountStore.accountPermissions,
    getOutboundWebhookByOrg: store.webhookStore.getOutboundWebhookByOrg,
    webhookId: store.webhookStore.webhookId,
    postWebhook: store.webhookStore.postWebhook
  }))
}

const notificationActivites = [
  { id: 1, name: "File Uploaded via File Manager" },
  { id: 2, name: "Feed Entry created" },
  { id: 3, name: "Logged Into Portal" },
  { id: 4, name: "Account Registered" },
  { id: 5, name: "Account requested forgot my password" },
  { id: 6, name: "Account changed their password" }
]

const WebhookManager = () => {
  const classes = useStyles()
  const {
    setShowChangeCurrentOrganization,
    organizationId,
    isPermissionsLoaded,
    accountPermissions,
    getOutboundWebhookByOrg,
    getOrganizations,
    webhookId
  } = useStoreData()

  const [
    isCreateOutboundWebhookDialogOpen,
    setIsCreateOutboundWebhookDialogOpen
  ] = useState(false)
  const [tabValue, setTabValue] = useState(0)
  const [formValues, setFormValues] = useState({
    organizationId,
    filteredProperties: "",
    webhookStatusId: "",
    description: "",
    deliveryTypeId: "",
    deliveryCadence: 0,
    externalUrl: "",
    externalHTTPMethod: "",
    authenticationRequired: false,
    webhookId,
    isArchived: false
  })

  const [formErrors, setFormErrors] = useState({})
  const [editForm, setEditForm] = useState(false)
  const [successDialogOpen, setSuccessDialogOpen] = useState(false)
  const [failDialogOpen, setFailDialogOpen] = useState(false)
  const [webhookManagerState, setWebhookManagerState] = useState(false)

  useEffect(() => {
    getOutboundWebhookByOrg()
    getOrganizations()
  }, [])

  const handleCreateOutboundWebhookClick = () => {
    setIsCreateOutboundWebhookDialogOpen(true)
  }

  const hideCreateOutboundWebhookDialog = () => {
    setIsCreateOutboundWebhookDialogOpen(false)
  }

  const openEditForm = webhookData => {
    setEditForm(true)
    setFormValues({
      ...formValues,
      ...webhookData?.row?.original
    })

    setIsCreateOutboundWebhookDialogOpen(true) // Set the data of the selected webhook for editing
  }

  const closeDialogForm = () => {
    hideCreateOutboundWebhookDialog() // Close the dialog
    setEditForm(false)
  }

  useEffect(() => {
    setShowChangeCurrentOrganization(true)
  }, [organizationId])

  const triggerRerenderOutboundTable = () => {
    setWebhookManagerState(prevState => !prevState)
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  let options = []
  options.push(
    <MenuItem
      disabled
      classes={{
        root: classes.selectMenuItem
      }}
    >
      Choose activity/event
    </MenuItem>
  )
  notificationActivites.forEach(notificationActivity => {
    options.push(
      <MenuItem
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected
        }}
        value={notificationActivity.id}
      >
        {notificationActivity.name}
      </MenuItem>
    )
  })

  return (
    <div>
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <WebhookIcon />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Webhooks</h4>
          <Grid>
            <GridItem>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                textColor="primary"
                indicatorColor="primary"
              >
                <Tab label="Outbound" />
                <Tab label="Inbound" />
              </Tabs>
            </GridItem>
          </Grid>
        </CardHeader>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CardBody>
              <CustomDSDialog
                open={successDialogOpen}
                titleContent={"Success!"}
                content={"Webhook Saved"}
                icon={<CheckCircleOutline style={{ fontSize: "4rem" }} />}
                backgroundColor={"#66BB6A"}
                onClose={() => setSuccessDialogOpen(false)}
              />
              <CustomDSDialog
                open={failDialogOpen}
                content={"Save failed"}
                icon={<Report style={{ fontSize: "4rem" }} />}
                backgroundColor={"#A31515"}
                onClose={() => setFailDialogOpen(false)}
              />
              <TabPanel value={tabValue} index={0}>
                {isPermissionsLoaded &&
                  accountPermissions.CanCreateOutboundWebhook === "Yes" && (
                    <div style={{ textAlign: "left" }}>
                      <Button
                        size="sm"
                        color="primary"
                        onClick={() => handleCreateOutboundWebhookClick()}
                        startIcon={<Add />}
                      >
                        Add Webhook
                      </Button>
                    </div>
                  )}
                {isCreateOutboundWebhookDialogOpen && (
                  <OutboundWebhookFormDialog
                    organizationId={organizationId}
                    isCreateOutboundWebhookDialogOpen={
                      isCreateOutboundWebhookDialogOpen
                    }
                    closeDialogForm={closeDialogForm}
                    editForm={editForm}
                    formValues={formValues}
                    setFormValues={setFormValues}
                    formErrors={formErrors}
                    setFormErrors={setFormErrors}
                    setIsCreateOutboundWebhookDialogOpen={
                      setIsCreateOutboundWebhookDialogOpen
                    }
                    setSuccessDialogOpen={setSuccessDialogOpen}
                    setFailDialogOpen={setFailDialogOpen}
                    triggerRerenderOutboundTable={triggerRerenderOutboundTable}
                  />
                )}
                <OutboundWebhookTable
                  onEditClick={data => {
                    openEditForm(data)
                  }}
                  webhookManagerState={webhookManagerState}
                />
                <Clearfix />
                <div style={{ textDecoration: "line-through" }}>
                  line-through = Archived
                </div>
              </TabPanel>
            </CardBody>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <CardBody>
              <TabPanel value={tabValue} index={1}>
                <h5>HTTP POST method only. Any payload is accepted.</h5>
                <InBoundWebhookTable />
                <Clearfix />
                <GridItem xs={3} sm={3} md={3}>
                  <div style={{ textDecoration: "line-through" }}>
                    line-through = Archived
                  </div>
                </GridItem>
              </TabPanel>
            </CardBody>
          </GridItem>
        </GridContainer>
      </Card>
    </div>
  )
}

export default WebhookManager
