import { Box, List, Typography } from "@mui/material"
import useEntityHelper from "tools/LibraryCopyTools/EntityHelper"
import { processEntity } from "tools/LibraryCopyTools"
import ItemTriggerListItem from "./ItemTriggerListItem"

const ItemTriggerSummary = ({
  title = null,
  changeList = [],
  triggerList = [],
  targetNarrative = {},
  sourceNarrative = {},
  sourceEntities,
  targetEntities,
  selectedSources,
  withAssociatedTriggers,
  destinationLibraryId
}) => {
  const { eligibleEntities, eligibleEntitiesCount } = useEntityHelper({
    entities: sourceEntities
  })
  return (
    <Box>
      {title && <Typography variant="h5">{title}</Typography>}
      <div style={{ textAlign: "left" }}>
        The following{" "}
        {eligibleEntitiesCount - 1
          ? `${eligibleEntitiesCount} blocks`
          : `block`}
        (with triggers) will be copied from:{" "}
        <strong>{sourceNarrative && sourceNarrative.name}</strong> <br />
        To:{" "}
        <strong>
          {targetNarrative && targetNarrative.name}, Paragraph{" "}
          {targetEntities.map(entity => processEntity(entity)?.id).join(", ")}
        </strong>
        :
        {eligibleEntitiesCount > 0 && (
          <ol>
            {eligibleEntities.map(id => (
              <li
                key={id}
                style={{
                  fontWeight: "bold"
                }}
              >
                {id} -{" "}
                {
                  sourceNarrative.paragraphs.find(
                    p => p.id === parseInt(id.replace("paragraphid-", ""))
                  )?.name
                }
                {
                  sourceNarrative.triggers.find(
                    p => p.id === parseInt(id.replace("triggerid-", ""))
                  )?.name
                }
              </li>
            ))}
          </ol>
        )}
        {targetEntities && targetEntities?.length > 0 && (
          <div>
            To:
            <ul>
              {targetEntities.map(id => (
                <li key={id} style={{ fontWeight: "bold" }}>
                  {id}
                </li>
              ))}
            </ul>
          </div>
        )}
        {!(targetEntities && targetEntities.length > 0) &&
          targetNarrative &&
          targetNarrative.name && (
            <div>
              To:
              <ul>
                <li style={{ fontWeight: "bold" }}>
                  Narrative #{targetNarrative.id} - {targetNarrative.name}
                </li>
              </ul>
            </div>
          )}
      </div>
      {triggerList?.length && (
        <div
          style={{
            padding: "2px 0 2px 5px",
            border: "2px solid #ccc",
            borderRadius: "5px"
          }}
        >
          <div
            style={{
              maxHeight: "300px",
              overflowX: "hidden",
              overflowY: "auto"
            }}
            className="-scrollEntries"
          >
            Copying the following Triggers:
            <List>
              {triggerList.map((entry, index) => (
                <ItemTriggerListItem
                  key={index}
                  trigger={entry}
                  index={index}
                  changeList={changeList}
                />
              ))}
            </List>
          </div>
        </div>
      )}
    </Box>
  )
}

export default ItemTriggerSummary
