// eslint-disable-next-line no-unused-vars
import React, { useEffect } from "react"
import { useObserver } from "mobx-react"

import { useStore } from "contexts/rootContext"
import { Link } from "react-router-dom"
import { Public, ArrowBack } from "@mui/icons-material"
import FeedEntries from "../FeedAdmin/FeedEntries"
import LinearProgress from "@mui/material/LinearProgress"

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    narrative: store.narrativeStore.narrative,
    setNarrativeId: store.narrativeStore.setNarrativeId
  }))
}

const NarrativeOutput = props => {
  const { narrative, setNarrativeId } = useStoreData()

  // on first render
  useEffect(() => {
    let match = props && props.match
    if (match) {
      setNarrativeId(match.params.id)
    }
  }, [])

  return (
    <div style={{ padding: "10px" }}>
      {narrative && narrative.productionFeed_Id && (
        <FeedEntries
          narrativeId={props && props.match && props.match.params.id}
          feedId={narrative && narrative.productionFeed_Id}
          embedded
          feedTitle="Recent Output"
          feedIcon={<Public />}
          isNarrativeOutput={true}
        />
      )}
      {narrative &&
        typeof narrative.productionFeed_Id !== "undefined" &&
        !narrative.productionFeed_Id && (
          <>
            <h4 style={{ marginLeft: "20px" }}>
              <Link
                title={`Back to Narrative Dashboard`}
                onClick={e => {
                  props.history.goBack()
                  e.preventDefault()
                }}
              >
                <strong>
                  <ArrowBack />
                  Back to Narrative Dashboard
                </strong>
              </Link>
            </h4>
            <div
              style={{
                textAlign: "center",
                padding: "20px",
                margin: "30px",
                border: "1px solid black",
                borderRadius: "5px"
              }}
            >
              <h1>No Feeds for Narrative {narrative.id}</h1>
            </div>
          </>
        )}
      {!narrative ||
        (narrative && typeof narrative.productionFeed_Id === "undefined" && (
          <LinearProgress thickness={1} size={30} />
        ))}
    </div>
  )
}

export default NarrativeOutput
