import React, { useEffect, useState } from "react"
import { useObserver } from "mobx-react"
import { Link, Redirect } from "react-router-dom"
import { useStore } from "contexts/rootContext"
import NarrativeMenuActionsProvider from "./NarrativeMenuActionsProvider"
import makeStyles from "@mui/styles/makeStyles"
import { Cookies } from "tools/storage"
import { groupCollectionBy } from "tools/CollectionHelper"
import { CircularProgress, Modal } from "@mui/material"
import narrativeDashboardStyles from "assets/jss/material-dashboard-pro-react/views/narrativeDashboardStyles"

import NarrativeOutlineElement from "components/NarrativeAdmin/NarrativeOutlineElement"
import NarrativeOutlineElementMenu from "components/NarrativeAdmin/NarrativeOutlineElementMenu"

import { contentSections as targetElements } from "./Data/contentSections"

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  ...narrativeDashboardStyles,
  root: {
    minHeight: "999px",
    position: "relative"
  },
  hide: {
    display: "none"
  },
  fab: {
    position: "absolute",
    top: "-30px",
    left: "-20px"
  },
  help: {
    position: "absolute",
    top: "-30px",
    left: "10px"
  },
  drawer: {
    zIndex: 1035,
    top: "50px",
    left: "80px",
    position: "fixed",
    width: drawerWidth,
    flexShrink: 0,
    backgroundColor: "#ffffff",
    boxShadow:
      "0 8px 18px -6px rgba(0, 0, 0, 0.56), 0 4px 15px 0px rgba(0, 0, 0, 0.12), 0 4px 8px -5px rgba(0, 0, 0, 0.2)",
    "& > ul > div.MuiListItem-root": {
      padding: "0 16px"
    }
  },
  elementMenu: {
    maxHeight: "80vh",
    overflow: "auto",
    marginBottom: "5px"
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    backgroundColor: "#efefefe",
    justifyContent: "flex-start"
  },
  drawerSubHeader: {
    position: "relative",
    "& > .MuiSvgIcon-root": {
      position: "absolute",
      right: "10px",
      top: "7px",
      padding: 0
    }
  },
  linkButton: {
    "&:hover": {
      cursor: "default!important"
    }
  },
  list: {
    listStyle: "none"
  },
  mouseOver: {
    margin: "10px 0",
    "&:hover": {
      boxShadow:
        "0 8px 18px -6px rgba(0, 0, 0, 0.56), 0 4px 15px 0px rgba(0, 0, 0, 0.12), 0 4px 8px -5px rgba(0, 0, 0, 0.2)"
    }
  },
  successModal: {
    position: "absolute",
    color: "#069",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "45%",
    left: "45%",
    transform: "translate(-45%, -45%)",
    "& h3": {
      fontWeight: "bold"
    },
    "& ul": {
      padding: "5px 0 0 5px",
      fontWeight: "bold"
    }
  },
  errorModal: {
    position: "absolute",
    color: "#c00",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "45%",
    left: "45%",
    transform: "translate(-45%, -45%)",
    "& h3": {
      fontWeight: "bold"
    },
    "& ul": {
      padding: "5px 0 0 5px",
      fontWeight: "bold",
      textDecoration: "underline"
    }
  }
}))

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    narrative: store.narrativeStore.narrative,
    isDirty: store.narrativeStore.isDirty,
    setIsDirty: store.narrativeStore.setIsDirty,
    postNarrative: store.narrativeStore.postNarrative,
    postParagraph: store.narrativeStore.postParagraph,
    paragraphs: store.narrativeStore.narrative.paragraphs,
    loadingOutlineBlocks: store.narrativeStore.loadingOutlineBlocks,
    renderTemplate: store.narrativeStore.renderTemplate,
    outlineBlocks: store.narrativeStore.narrative.outlineBlocks,
    postingNarrativeBlock: store.narrativeStore.postingNarrativeBlock,
    postNarrativeOutlineBlock: store.narrativeStore.postNarrativeOutlineBlock,
    responseCode: store.narrativeStore.responseCode,
    responseMessage: store.narrativeStore.responseMessage,
    setResponseCode: store.narrativeStore.setResponseCode,
    setResponseMessage: store.narrativeStore.setResponseMessage
  }))
}

const NarrativeOutline = props => {
  const classes = useStyles()
  const {
    narrative,
    loadingOutlineBlocks,
    renderTemplate,
    outlineBlocks,
    postNarrative,
    responseCode,
    responseMessage,
    setResponseCode,
    setResponseMessage
  } = useStoreData()

  let cookies = new Cookies()
  let cookieKeyOrder =
    cookies.get(`keyorder`) || targetElements.map(element => element.id)
  const [keyOrder] = useState(cookieKeyOrder)
  const [visibleNarrativeOutlineElements, setVisibleNarrativeOutlineElements] =
    useState([])
  const [
    expandedNarrativeOutlineElements,
    setExpandedNarrativeOutlineElements
  ] = useState([])
  const [activeElements, setActiveElements] = useState([])
  const [inactiveElements, setInactiveElements] = useState([])
  const [blocks, setBlocks] = useState([])
  const [isOpenElementMenu, setOpenElementMenu] = useState(false)
  const [openAvailable, setOpenAvailable] = useState(true)
  const [containerYouAreRemovingNotEmpty, setContainerYouAreRemovingNotEmpty] =
    useState(false)
  const [showStatus, setShowStatus] = useState(false)
  const [statusMessage, setStatusMessage] = useState()
  const [statusCode, setStatusCode] = useState()

  const toggleMenu = () => {
    setOpenElementMenu(!isOpenElementMenu)
  }

  // Listen for response message/code changes
  useEffect(() => {
    responseMessage && setShowStatus(true)
    setStatusMessage(responseMessage)
    setStatusCode(responseCode)
  }, [responseMessage, responseCode])

  // Listen for changes to the render template
  useEffect(() => {
    if (
      (outlineBlocks && (outlineBlocks.size > 0 || outlineBlocks.length > 0)) ||
      narrative.feedFields
    ) {
      const contentBlocks =
        (outlineBlocks &&
          groupCollectionBy(
            outlineBlocks,
            p => p.contentBlock && p.contentBlock.toLowerCase()
          )) ||
        []
      setBlocks(contentBlocks)
      //Set Active Keys
      // This list comes from the fields collected in the narrative wizard
      let narrativeFeedFields =
        (narrative.feedFields &&
          narrative.feedFields.split(",").map(f => f && f.trim())) ||
        []
      narrativeFeedFields = narrativeFeedFields.filter(
        fieldField => fieldField && fieldField.length > 0
      )
      // This list comes from the outline blocks on the narrative or in memory unsaved
      let outlineBlockElements =
        (outlineBlocks &&
          (outlineBlocks.size > 0 || outlineBlocks.length > 0) &&
          Object.keys(contentBlocks)) ||
        []
      outlineBlockElements = outlineBlockElements.filter(
        blockKey => blockKey && blockKey.length > 0
      )
      // This is the combined list
      let activeKeys = outlineBlockElements.concat(
        narrativeFeedFields.filter(f => outlineBlockElements.indexOf(f) < 0)
      )
      //Set visible elements to the active keys
      // Sort based on saved keyorder
      let sortedVisible = activeKeys.sort(function (a, b) {
        return keyOrder.indexOf(a) - keyOrder.indexOf(b)
      })
      setVisibleNarrativeOutlineElements(sortedVisible)
      setExpandedNarrativeOutlineElements(activeKeys)
      let newActiveElements = targetElements
        .filter(key => activeKeys.includes(key.id))
        .sort(function (a, b) {
          return keyOrder.indexOf(a) - keyOrder.indexOf(b)
        })
      setOpenAvailable(newActiveElements.length === 0)
      setActiveElements(newActiveElements)
      let newInactiveElements = targetElements
        .filter(key => !activeKeys.includes(key.id))
        .sort(function (a, b) {
          return keyOrder.indexOf(a) - keyOrder.indexOf(b)
        })
      setInactiveElements(newInactiveElements)
    } else {
      let allInactiveElements = targetElements.sort(function (a, b) {
        return keyOrder.indexOf(a) - keyOrder.indexOf(b)
      })
      setInactiveElements(allInactiveElements)
      setVisibleNarrativeOutlineElements([])
      setExpandedNarrativeOutlineElements([])
      setActiveElements([])
      setBlocks([])
      setOpenAvailable(true)
    }
  }, [outlineBlocks, keyOrder, narrative])

  // Active Elements handler
  const handleSetActiveElements = elementsArray => {
    let newActiveElements = elementsArray.join()
    if (narrative.feedFields !== newActiveElements) {
      let newNarrative = { ...narrative }
      newNarrative.feedFields = newActiveElements
      postNarrative(newNarrative)
    }
    let newActiveElements2 = targetElements
      .filter(key => newActiveElements.split(",").includes(key.id))
      .sort(function (a, b) {
        return keyOrder.indexOf(a) - keyOrder.indexOf(b)
      })
    setActiveElements(newActiveElements2)
  }

  const isContainerEmpty = elementKey => {
    if (elementKey) {
      return !blocks[elementKey]
    } else {
      return true
    }
  }

  const toggleElement = elementKey => {
    let newVisible = [...visibleNarrativeOutlineElements]
    let newInvisible = [...inactiveElements]
    if (!isContainerEmpty(elementKey)) {
      setContainerYouAreRemovingNotEmpty(true)
      return
    }
    if (newVisible.indexOf(elementKey) > -1) {
      newVisible = newVisible.filter(key => key !== elementKey)
      handleSetActiveElements(newVisible)
      let newInvisibleTarget = targetElements.filter(
        key => key.id === elementKey
      )
      newInvisible.push(newInvisibleTarget[0])
      setInactiveElements(newInvisible)
    } else {
      newVisible.push(elementKey)
      handleSetActiveElements(newVisible)
      newInvisible = newInvisible.filter(key => key.id !== elementKey)
      setInactiveElements(newInvisible)
    }
    setVisibleNarrativeOutlineElements(newVisible)
  }

  const toggleExpandElement = elementKey => {
    let newVisible = [...expandedNarrativeOutlineElements]
    if (newVisible.indexOf(elementKey) > -1) {
      newVisible = newVisible.filter(key => key !== elementKey)
    } else {
      newVisible.push(elementKey)
    }
    setExpandedNarrativeOutlineElements(newVisible)
  }

  return (
    <div className={classes.root}>
      {narrative && narrative.isLibrary ? (
        <Redirect to={`/portal/narrative/${narrative.id}/edit`} />
      ) : null}
      <Modal
        open={showStatus}
        onClose={() => {
          setShowStatus(false)
          setTimeout(() => {
            setResponseMessage(undefined)
            setResponseCode(undefined)
          }, 100)
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className={
            statusCode === 1000 ? classes.successModal : classes.errorModal
          }
        >
          <h2 id="server-modal-title">
            {statusCode === 1000 ? "Saved!" : "Failed to Save!"}
          </h2>
          <div id="server-modal-description">
            {statusCode === 1000 ? (
              statusMessage
            ) : (
              <div>
                <h4>There was an error saving the narrative.</h4>
                <div>Narrative Id: {narrative.id}</div>
                <div>Error Code: {statusCode}</div>
              </div>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        open={containerYouAreRemovingNotEmpty}
        onClose={() => {
          setContainerYouAreRemovingNotEmpty(false)
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.errorModal}>
          <div>
            <h4>
              Unable to delete Outline Sections with Outline Blocks associated
            </h4>
            <div>Delete associated Outline Blocks and try again.</div>
          </div>
        </div>
      </Modal>
      <NarrativeOutlineElementMenu
        activeElements={activeElements}
        setActiveElements={setActiveElements}
        inactiveElements={inactiveElements}
        setInactiveElements={setInactiveElements}
        toggleElement={toggleElement}
        visibleNarrativeOutlineElements={visibleNarrativeOutlineElements}
        handleDrawerClose={toggleMenu}
        handleDrawerOpen={toggleMenu}
        openElementMenu={isOpenElementMenu}
        openAvailable={openAvailable}
        setOpenAvailable={setOpenAvailable}
      />
      {renderTemplate && renderTemplate.description && (
        <div style={{ textAlign: "center" }}>
          <h4 className={classes.cardIconTitle}>
            {renderTemplate.description}
          </h4>
        </div>
      )}
      {loadingOutlineBlocks && (
        <div style={{ textAlign: "center" }}>
          <h3 className={classes.cardIconTitle}>Assembling Outline</h3>
          <h4 className={classes.cardIconTitle2}>
            This process could take some time.
          </h4>
        </div>
      )}
      {!loadingOutlineBlocks && !renderTemplate && (
        <div style={{ textAlign: "center" }}>
          <h3 className={classes.cardIconTitle}>Unable To Assemble Outline</h3>
          <h4 className={classes.cardIconTitle2}>
            Unable to create outline from all blocks, select a Feed Entry from{" "}
            <Link
              to={{
                pathname: `/portal/narrative/${narrative.id}/output`,
                state: {
                  narrative_name: `${narrative.name}`
                }
              }}
              title="View Output"
            >
              this narrative's output
            </Link>
          </h4>
        </div>
      )}
      {!loadingOutlineBlocks && outlineBlocks && outlineBlocks.length < 1 && (
        <div style={{ textAlign: "center" }}>
          <h4 className={classes.cardIconTitle2}>No rendered blocks found</h4>
        </div>
      )}
      {loadingOutlineBlocks && (
        <CircularProgress
          style={{
            position: "absolute",
            left: "48%",
            top: "150px",
            zIndex: "101"
          }}
        />
      )}
      <NarrativeMenuActionsProvider>
        {visibleNarrativeOutlineElements.map(key => (
          <div key={key}>
            <NarrativeOutlineElement
              outlineBlocks={blocks[key]}
              elementId={key}
              elementName={
                targetElements.find(e => e.id === key)
                  ? targetElements.find(e => e.id === key).name
                  : key.toUpperCase()
              }
              expandedNarrativeOutlineElements={
                expandedNarrativeOutlineElements
              }
              toggleExpandElement={toggleExpandElement}
              blockViewStyle={props.blockViewStyle}
              allowReordering={narrative.buildFromOutline}
            />
          </div>
        ))}
      </NarrativeMenuActionsProvider>
    </div>
  )
}

export default NarrativeOutline
