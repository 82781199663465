import React, {
  useEffect,
  useState //, useCallback
} from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import { TreeView, TreeItem } from "@mui/x-tree-view"
import { ExpandMore, ChevronRight } from "@mui/icons-material"
import LinearProgress from "@mui/material/LinearProgress"
import queryString from "query-string"
import InsightLibraryTreeContextMenu from "./InsightLibraryTreeContextMenu"
import RealTimeDAO from "daos/realTimeDAO"
import { useHistory } from "react-router-dom"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
  RealTimeTree: {
    "& div.Mui-selected": {
      backgroundColor: "#4d9ab580 !important",
      "& span": {
        fontWeight: 500
      }
    }
  }
})

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    insightGroup: store.realTimeStore.insightGroup,
    getInsightGroup: store.realTimeStore.getInsightGroup,
    setInsightGroup: store.realTimeStore.setInsightGroup,
    insightSentence: store.realTimeStore.insightSentence,
    getInsightSentence: store.realTimeStore.getInsightSentence,
    setInsightSentence: store.realTimeStore.setInsightSentence
  }))
}

export default function RealTimeInsightLibraryTree(props) {
  const {
    getInsightGroup,
    setInsightGroup,
    getInsightSentence,
    setInsightSentence,
    insightGroup,
    insightSentence
  } = useStoreData()

  const {
    treeForLibraryNotOutput,
    realTimeOutput,
    openSentenceForm,
    openGroupForm,
    openInsightForm,
    closeForms,
    libraryId,
    sentenceId,
    groupId,
    realTimeLibrary
  } = props
  const queryParams = queryString.parse(window.location.search.toLowerCase())
  const [expandItems, setExpandItems] = useState([])
  const [treeData, setTreeData] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [selectedItem, setSelectedItem] = useState(null)
  const [contextMenuSentenceAnchor, setContextMenuSentenceAnchor] =
    useState(null)
  const [contextMenuInsightTypeAnchor, setContextMenuInsightTypeAnchor] =
    useState(null)
  const [contextMenuInsightGroupAnchor, setContextMenuInsightGroupAnchor] =
    useState(null)

  const [isBuildingTree, setIsBuildingTree] = useState(false)

  const [clickedNodeInfo, setClickedNodeInfo] = useState({})
  const history = useHistory()
  const classes = useStyles()

  const rightClickHandleContextMenu = (event, nodes) => {
    event.preventDefault()
    event.stopPropagation()
    setClickedNodeInfo(nodes)
    switch (nodes.type) {
      case "sentence":
        setContextMenuSentenceAnchor(event.currentTarget)
        break

      case "type":
        setContextMenuInsightTypeAnchor(event.currentTarget)
        break

      case "group":
        setContextMenuInsightGroupAnchor(event.currentTarget)
        break

      default:
    }
  }

  const fetchData = id => {
    setIsBuildingTree(true)
    setInsightGroup([])
    setInsightSentence([])
    getInsightGroup(id)
    getInsightSentence(id)
  }

  const expandSentenceTree = sentenceId => {
    let combos = []
    treeData.forEach(x => {
      combos = [x.id]
      x.children.forEach(y => {
        y.children.forEach(z => {
          const sId = z.id.replace(/sentence-/, "")
          if (Number(sId) === Number(sentenceId)) {
            combos.unshift(y.id)
            setExpandItems(combos)
          }
        })
      })
    })
  }

  const expandGroupTree = groupId => {
    let combos = []
    treeData.forEach(x => {
      combos = [x.id]
      x.children.forEach(y => {
        const gId = y.id.replace(/group-/, "")
        if (Number(gId) === Number(groupId)) {
          setExpandItems(combos)
        }
      })
    })
  }

  useEffect(() => {
    if (realTimeLibrary?.league?.id > 0) {
      if (treeForLibraryNotOutput) {
        const tree = buildLibraryTree(insightGroup, insightSentence)
        setTreeData(tree)
      } else {
        const igList = []
        realTimeOutput?.insightTypes?.forEach(x => {
          igList.push(x.id)
        })
        const insightGroupFiltered = insightGroup.filter(x =>
          igList.includes(x.insightType?.id)
        )
        const tree = buildLibraryTree(insightGroupFiltered, insightSentence)
        setTreeData(tree)
      }
    }
  }, [realTimeLibrary])

  useEffect(() => {
    if (sentenceId && Number(sentenceId) > 0) {
      const sentence = RealTimeDAO.getInsightSentenceById(sentenceId)
      sentence.then(resp => {
        openSentenceForm(resp)
      })
    } else if (groupId && Number(groupId) > 0) {
      const group = RealTimeDAO.getInsightGroupById(groupId)
      group.then(resp => {
        openGroupForm(resp)
      })
    }
  }, [])

  useEffect(() => {
    if (treeForLibraryNotOutput) {
      const tree = buildLibraryTree(insightGroup, insightSentence)
      setTreeData(tree)
    } else {
      const igList = []
      realTimeOutput?.insightTypes?.forEach(x => {
        igList.push(x.id)
      })
      const insightGroupFiltered = insightGroup.filter(x =>
        igList.includes(x.insightType?.id)
      )
      const tree = buildLibraryTree(insightGroupFiltered, insightSentence)
      setTreeData(tree)
    }
  }, [insightSentence])

  useEffect(() => {
    if (treeForLibraryNotOutput) {
      const tree = buildLibraryTree(insightGroup, insightSentence)
      setTreeData(tree)
    } else {
      const igList = []
      realTimeOutput?.insightTypes?.forEach(x => {
        igList.push(x.id)
      })
      const insightGroupFiltered = insightGroup.filter(x =>
        igList.includes(x.insightType?.id)
      )
      const tree = buildLibraryTree(insightGroupFiltered, insightSentence)
      setTreeData(tree)
    }
  }, [insightGroup])

  useEffect(() => {
    setIsBuildingTree(false)
    if (treeData.length > 0) {
      if (sentenceId && Number(sentenceId) > 0) {
        expandSentenceTree(sentenceId)
      } else if (groupId && Number(groupId) > 0) {
        expandGroupTree(groupId)
      }
    }
  }, [treeData])

  useEffect(() => {
    const id = queryParams["id"] || queryParams["libraryid"]
    if (id && libraryId && Number(id) === Number(libraryId)) {
      fetchData(libraryId)
    }
  }, [libraryId])

  const handleExpandedItemsChange = (event, itemIds) => {
    setExpandItems(itemIds)
  }

  const getInsightTypeFromTreeInfo = item => {
    if (item.id) {
      const myId = item.id.replace(/type-/, "")
      if (myId) {
        return Number(myId)
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const getGroupFromTreeInfo = item => {
    if (item.id) {
      const myId = item.id.replace(/group-/, "")
      if (myId) {
        const foundGroup = insightGroup?.find(x => x.id === Number(myId))
        return foundGroup
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const getSentenceFromTreeInfo = item => {
    if (item.id) {
      const myId = item.id.replace(/sentence-/, "")
      if (myId) {
        const foundSentence = insightSentence?.find(x => x.id === Number(myId))
        return foundSentence
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const handleItemFormClick = (item, event) => {
    if (item.type === "sentence") {
      event.preventDefault()
    }
    setSelectedItem(item)
    if (item.type === "sentence") {
      //console.log("ZZ-Open sentence form: ", item.name)
      const clickedSentence = getSentenceFromTreeInfo(item)
      if (clickedSentence) {
        openSentenceForm(clickedSentence)
        history.push(
          treeForLibraryNotOutput
            ? `/portal/real-time-library/edit?id=${libraryId}&sentenceId=${clickedSentence.id}`
            : `/portal/real-time-output/${realTimeOutput?.id}?libraryId=${libraryId}&sentenceId=${clickedSentence.id}`
        )
      }
    } else if (item.type === "group") {
      //console.log("Open group form: ", item.name)
      const clickedGroup = getGroupFromTreeInfo(item)
      if (clickedGroup) {
        openGroupForm(clickedGroup)
        history.push(
          treeForLibraryNotOutput
            ? `/portal/real-time-library/edit?id=${libraryId}&groupId=${clickedGroup.id}`
            : `/portal/real-time-output/${realTimeOutput?.id}?libraryId=${libraryId}&groupId=${clickedGroup.id}`
        )
      }
    } else if (item.type === "type") {
      console.log("Open type form: ", item.name)
      const clickedTypeId = getInsightTypeFromTreeInfo(item)
      if (clickedTypeId) {
        openInsightForm({ id: clickedTypeId, name: item.name })
        history.push(
          treeForLibraryNotOutput
            ? `/portal/real-time-library/edit?id=${libraryId}&insightTypeId=${clickedTypeId}`
            : `/portal/real-time-output/${realTimeOutput?.id}?libraryId=${libraryId}&insightTypeId=${clickedTypeId}`
        )
      }
    }
  }
  const getInsightTypeFromGroupId = groupId => {
    let myInsightType = {}
    treeData.forEach(x => {
      //combos = [x.id]
      x.children.forEach(y => {
        const gId = y.id.replace(/group-/, "")
        if (Number(gId) === Number(groupId)) {
          myInsightType = {
            id: Number(x.id.replace(/type-/, "")),
            name: x.name
          }
        }
      })
    })
    return myInsightType
  }

  const buildLibraryTree = (groups, sentences) => {
    const insightTypes = {}

    groups.forEach(group => {
      if (!group.insightType) {
        console.error("Group is missing insightType:", group)
        return
      }
      const typeId = group.insightType.id
      if (!insightTypes[typeId]) {
        insightTypes[typeId] = {
          id: `type-${typeId}`,
          name: group.insightType.name,
          type: "type",
          handleContextMenu: rightClickHandleContextMenu,
          children: []
        }
      }
      insightTypes[typeId].children.push({
        id: `group-${group.id}`,
        name: group.name,
        type: "group",
        handleContextMenu: rightClickHandleContextMenu,
        children: []
      })
    })
    sentences.forEach(sentence => {
      if (!sentence.insightType || !sentence.insightGroup) {
        //console.log("Sentence missing insightType or group:", sentence)
        return
      }
      const typeId = sentence.insightType.id
      const typeNode = insightTypes[typeId]
      if (typeNode) {
        const groupNode = typeNode.children.find(
          g => g.id === `group-${sentence.insightGroup.id}`
        )
        if (groupNode) {
          groupNode.children.push({
            id: `sentence-${sentence.id}`,
            name: sentence.name,
            type: "sentence",
            handleContextMenu: rightClickHandleContextMenu
          })
        } else {
        }
      }
    })
    const allInsightTypes = []
    realTimeLibrary?.insightTypes?.forEach(x => {
      if (x) {
        allInsightTypes.push(x)
      }
    })
    const existingTypes = Object.values(insightTypes)
    const notFound = []
    allInsightTypes?.forEach(x => {
      let match = false
      existingTypes.forEach(y => {
        if (x.name === y.name) {
          match = true
        }
      })
      if (!match) {
        notFound.push({
          id: `type-${x.id}`,
          name: x.name,
          type: "type",
          handleContextMenu: rightClickHandleContextMenu,
          children: []
        })
      }
    })
    if (treeForLibraryNotOutput) {
      const allTypes = existingTypes.concat(notFound)
      return allTypes
    } else {
      return existingTypes
    }
  }

  const renderTree = nodes => (
    <div
      id={nodes.contextMenuId}
      onContextMenu={event => nodes.handleContextMenu(event, nodes)}
    >
      <TreeItem
        key={nodes.id}
        nodeId={nodes.id}
        title={`${nodes.id}`}
        label={
          <span onClick={event => handleItemFormClick(nodes, event)}>
            {nodes.name}
          </span>
        }
        onClick={() => nodes.isSentence && handleItemFormClick(nodes.name)}
        expandIcon={
          nodes.type === "sentence" ? (
            <div style={{ width: 24 }} />
          ) : (
            <ChevronRight />
          )
        }
        collapseIcon={
          nodes.type === "sentence" ? (
            <div style={{ width: 24 }} />
          ) : (
            <ExpandMore />
          )
        }
      >
        {nodes.type !== "sentence" &&
        Array.isArray(nodes.children) &&
        nodes.children.length > 0
          ? nodes.children.map(node => renderTree(node))
          : null}
      </TreeItem>
    </div>
  )

  if (isBuildingTree) {
    return <LinearProgress />
  } else {
    return (
      <div className={classes.RealTimeTree}>
        <TreeView
          expanded={expandItems}
          onNodeToggle={handleExpandedItemsChange}
        >
          {treeData.map(node => renderTree(node))}
        </TreeView>
        {treeForLibraryNotOutput && (
          <InsightLibraryTreeContextMenu
            libraryId={libraryId}
            treeData={treeData}
            clickedNodeInfo={clickedNodeInfo}
            contextMenuSentenceAnchor={contextMenuSentenceAnchor}
            contextMenuInsightTypeAnchor={contextMenuInsightTypeAnchor}
            contextMenuInsightGroupAnchor={contextMenuInsightGroupAnchor}
            setContextMenuSentenceAnchor={setContextMenuSentenceAnchor}
            setContextMenuInsightTypeAnchor={setContextMenuInsightTypeAnchor}
            setContextMenuInsightGroupAnchor={setContextMenuInsightGroupAnchor}
            closeForms={() => {
              closeForms()
            }}
            openAddNewSentence={(groupId, groupName) => {
              openSentenceForm({
                insightGroup: { id: groupId, name: groupName },
                insightType: getInsightTypeFromGroupId(groupId)
              })
            }}
            openAddNewGroup={(insightTypeId, insightTypeName) => {
              openGroupForm({
                insightType: { id: insightTypeId, name: insightTypeName }
              })
            }}
          />
        )}
      </div>
    )
  }
}
