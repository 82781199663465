import React from "react"
import PropTypes from "prop-types"
import withStyles from "@mui/styles/withStyles"
import GridItem from "components/Grid/GridItem.jsx"
import { globalSettings } from "variables/general"
import GridContainer from "components/Grid/GridContainer.jsx"
import CircularProgress from "@mui/material/CircularProgress"
import Button from "components/CustomButtons/Button"
import SingleImageResult from "./SingleImageResult"
import Accordion from "components/Accordion/Accordion"
import HelpOutline from "@mui/icons-material/HelpOutline"
import FileCopy from "@mui/icons-material/FileCopy"
import Tooltip from "@mui/material/Tooltip"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { get } from "tools/request"

const defaultProps = {
  selectedImage: {},
  image: {},
  testing: 1,
  imagesWidth: 160,
  maxImagesCount: 6,
  searchTerms: "",
  searchKeywords: "",
  doSearch: false,
  doLoadGetty: false,
  doLoadImagn: false,
  doLoadAPMedia: false,
  doLoadImago: false,
  displayAPIRequest: false,
  imageItemClicked: {},
  doIncludeSIPAImages: false,
  gettyImagesNumberOfPeople: null
}

const style = {
  singleImage: {
    "&:hover": {
      transform: "scale(1.05)",
      outline: "2px solid yellow"
    }
  }
}

class ImageSearchTermResults extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      isBeingHovered: false,
      imageDetailsPositionX: 0,
      imageDetailsPositionY: 0,
      gettyCurlCommand: ``,
      imagnCurlCommand: ``,
      apMediaCurlCommand: ``,
      imagoCurlCommand: ``,
      areGettyImagesLoaded: false,
      areImagnImagesLoaded: false,
      areAPImagesLoaded: false,
      gettyImages: [],
      imagnImages: [],
      apImages: [],
      imagoImages: []
    }
  }

  componentDidMount() {
    this.searchImages()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.findImagesButtonClicked !== prevProps.findImagesButtonClicked
    ) {
      this.searchImages()
    }
  }

  searchImages() {
    if (this.props.doLoadGetty) {
      this.searchGettyImages(
        this.buildGettyAPIUrl(
          this.props.searchTerms,
          this.props.gettyImagesNumberOfPeople
        )
      )
    }
    if (this.props.doLoadImagn) {
      this.searchImagnImages(
        this.buildImagnAPIUrl(
          this.props.searchTerms,
          this.props.searchKeywords,
          this.props.doIncludeSIPAImages
        )
      )
    }
    if (this.props.doLoadAPMedia) {
      this.searchAPImages(this.buildAPMediaAPIUrl(this.props.searchTerms))
    }
    if (this.props.doLoadImago) {
      this.searchImagoImages(this.buildImagoAPIUrl(this.props.searchTerms))
    }
  }

  onImageLoad({ target: img }) {
    this.props.image.width = img.naturalWidth
    this.props.image.height = img.naturalHeight
  }

  buildGettyAPIUrl(terms, gettyImagesNumberOfPeople) {
    let url = `${
      globalSettings.apiBaseUrl
    }/api/imagesearch/search?provider=getty&terms=${encodeURIComponent(
      terms
    )}&numberofpeople=${gettyImagesNumberOfPeople}`
    this.setState({ gettyUrl: url, isGettyUrlBuilt: true })
    return url
  }

  buildImagnAPIUrl(terms, keywords, doIncludeSIPAImages) {
    let url = `${
      globalSettings.apiBaseUrl
    }/api/imagesearch/search?provider=imagn&terms=${encodeURIComponent(
      terms
    )}&doIncludeSIPAImages=${doIncludeSIPAImages}`
    if (keywords) {
      url += `&keywords=${encodeURIComponent(keywords)}`
    }
    this.setState({ imagnAPIUrl: url, isImagnUrlBuilt: true })
    return url
  }

  buildAPMediaAPIUrl(terms) {
    let url = `${
      globalSettings.apiBaseUrl
    }/api/imagesearch/search?provider=apmedia&terms=${encodeURIComponent(
      terms
    )}`
    this.setState({ apUrl: url, isAPUrlBuilt: true })
    return url
  }

  buildImagoAPIUrl(terms) {
    let url = `${
      globalSettings.apiBaseUrl
    }/api/imagesearch/search?provider=imago&terms=${encodeURIComponent(terms)}`
    this.setState({ apUrl: url, isImagoUrlBuilt: true })
    return url
  }

  searchGettyImages = gettyUrl => {
    this.setState({ areGettyImagesLoaded: false, gettyImages: [] })
    get(gettyUrl)
      .then(response => response.json())
      .then(data => {
        let imagesFound = []
        if (data.content !== null) {
          data.content.images.forEach(i => {
            imagesFound.push(i)
          })
          this.setState({
            gettyCurlCommand: data.content.providerCurlCommand
          })
        }
        this.setState({ gettyImages: imagesFound, areGettyImagesLoaded: true })
      })
  }

  searchImagnImages = imagnAPIUrl => {
    this.setState({ areImagnImagesLoaded: false, imagnImages: [] })
    get(imagnAPIUrl)
      .then(response => response.json())
      .then(data => {
        let imagesFound = []
        if (data.content !== null) {
          data.content.images.forEach(i => {
            imagesFound.push(i)
          })
          this.setState({
            imagnCurlCommand: data.content.providerCurlCommand
          })
        }
        this.setState({ imagnImages: imagesFound, areImagnImagesLoaded: true })
      })
  }

  searchAPImages = apUrl => {
    this.setState({ areAPImagesLoaded: false, apImages: [] })
    get(apUrl)
      .then(response => response.json())
      .then(data => {
        let imagesFound = []
        if (data.content !== null) {
          data.content.images.forEach(i => {
            imagesFound.push(i)
          })
          this.setState({
            apMediaCurlCommand: data.content.providerCurlCommand
          })
        }
        this.setState({ apImages: imagesFound, areAPImagesLoaded: true })
      })
  }

  searchImagoImages = imagoUrl => {
    this.setState({ areImagoImagesLoaded: false, imagoImages: [] })
    get(imagoUrl)
      .then(response => response.json())
      .then(data => {
        let imagesFound = []
        if (data.content !== null) {
          data.content.images.forEach(i => {
            imagesFound.push(i)
          })
          this.setState({
            imagoCurlCommand: data.content.providerCurlCommand
          })
        }
        this.setState({
          imagoImages: imagesFound,
          areImagoImagesLoaded: true
        })
      })
  }

  render() {
    const {
      areGettyImagesLoaded,
      areImagnImagesLoaded,
      areAPImagesLoaded,
      areImagoImagesLoaded,
      imagnCurlCommand,
      gettyCurlCommand,
      apMediaCurlCommand,
      imagoCurlCommand
    } = this.state
    const { maxImagesCount, imagesWidth, selectedImage } = this.props
    const gettyImages = this.state.gettyImages.map((item, index) => {
      if (item === null || index >= maxImagesCount) {
        return
      }
      return (
        <SingleImageResult
          key={index}
          image={item}
          width={imagesWidth}
          selectedImage={selectedImage}
          onImageClickCallBack={this.props.onImageClickCallBack}
        />
      )
    })
    const imagnImages = this.state.imagnImages.map((item, index) => {
      if (item === null || index >= maxImagesCount) {
        return
      }
      return (
        <SingleImageResult
          image={item}
          width={imagesWidth}
          selectedImage={selectedImage}
          onImageClickCallBack={this.props.onImageClickCallBack}
        />
      )
    })
    const apImages = this.state.apImages.map((item, index) => {
      if (item === null || index >= maxImagesCount) {
        return
      }
      return (
        <SingleImageResult
          image={item}
          width={imagesWidth}
          selectedImage={selectedImage}
          onImageClickCallBack={this.props.onImageClickCallBack}
        />
      )
    })
    const imagoImages = this.state.imagoImages.map((item, index) => {
      if (item === null || index >= maxImagesCount) {
        return
      }
      return (
        <SingleImageResult
          image={item}
          width={imagesWidth}
          selectedImage={selectedImage}
          onImageClickCallBack={this.props.onImageClickCallBack}
        />
      )
    })

    return (
      <React.Fragment>
        {this.props.doLoadGetty && (
          <React.Fragment>
            <GridItem md={12}>
              <h4>Getty Images: ({this.props.searchTerms})</h4>
            </GridItem>
            {this.props.displayAPIRequest && (
              <GridItem md={12}>
                <Accordion
                  active={false}
                  collapses={[
                    {
                      title: "Getty cURL Command",
                      content: (
                        <span>
                          <CopyToClipboard
                            text={gettyCurlCommand}
                            onCopy={() =>
                              alert(`cURL command copied to clipboard.`)
                            }
                          >
                            <Tooltip
                              title={"Copy cURL command."}
                              placement="top"
                            >
                              <Button
                                justIcon
                                round
                                simple
                                color="primary"
                                className="info"
                              >
                                <FileCopy />
                              </Button>
                            </Tooltip>
                          </CopyToClipboard>
                          {gettyCurlCommand}
                          <a
                            href={`https://developers.gettyimages.com/docs/`}
                            target="_new"
                          >
                            <Tooltip title="View documentation" placement="top">
                              <Button
                                justIcon
                                round
                                simple
                                color="primary"
                                className="info"
                              >
                                <HelpOutline />
                              </Button>
                            </Tooltip>
                          </a>
                        </span>
                      )
                    }
                  ]}
                />
              </GridItem>
            )}
            <GridItem md={12}>
              {!areGettyImagesLoaded && (
                <CircularProgress
                  style={{
                    position: "relative",
                    top: "25px",
                    left: "150px"
                  }}
                  size={60}
                />
              )}
              {gettyImages.length > 0
                ? gettyImages
                : areGettyImagesLoaded
                ? " none found."
                : ""}
            </GridItem>
          </React.Fragment>
        )}
        {this.props.doLoadImagn && (
          <React.Fragment>
            <GridContainer>
              <GridItem md={12}>
                <h4>
                  Imagn Images: ({this.props.searchTerms}) - (
                  {this.props.searchKeywords})
                </h4>
              </GridItem>
              {this.props.displayAPIRequest && (
                <GridItem md={12}>
                  <Accordion
                    active={false}
                    collapses={[
                      {
                        title: "Imagn cURL Command",
                        content: (
                          <span>
                            <CopyToClipboard
                              text={imagnCurlCommand}
                              onCopy={() =>
                                alert(`cURL command copied to clipboard.`)
                              }
                            >
                              <Tooltip
                                title={"Copy cURL command."}
                                placement="top"
                              >
                                <Button
                                  justIcon
                                  round
                                  simple
                                  color="primary"
                                  className="info"
                                >
                                  <FileCopy />
                                </Button>
                              </Tooltip>
                            </CopyToClipboard>
                            {imagnCurlCommand}
                            <a
                              href={`https://cdn.dataskrive.com/api/asset/7ZhhWJ0/render`}
                              target="_new"
                            >
                              <Tooltip
                                title="View documentation"
                                placement="top"
                              >
                                <Button
                                  justIcon
                                  round
                                  simple
                                  color="primary"
                                  className="info"
                                >
                                  <HelpOutline />
                                </Button>
                              </Tooltip>
                            </a>
                          </span>
                        )
                      }
                    ]}
                  />
                </GridItem>
              )}
            </GridContainer>
            <GridItem md={12}>
              {!areImagnImagesLoaded && (
                <CircularProgress
                  style={{
                    position: "relative",
                    top: "25px",
                    left: "150px"
                  }}
                  size={60}
                />
              )}
              {imagnImages.length > 0
                ? imagnImages
                : areImagnImagesLoaded
                ? " none found."
                : ""}
            </GridItem>
          </React.Fragment>
        )}
        {this.props.doLoadAPMedia && (
          <React.Fragment>
            <GridContainer>
              <GridItem md={12}>
                <h4>AP Media: ({this.props.searchTerms})</h4>
              </GridItem>
              {this.props.displayAPIRequest && (
                <GridItem md={12}>
                  <Accordion
                    active={-1}
                    collapses={[
                      {
                        title: "AP Media cURL Command",
                        content: (
                          <span>
                            <CopyToClipboard
                              text={apMediaCurlCommand}
                              onCopy={() =>
                                alert(`cURL command copied to clipboard.`)
                              }
                            >
                              <Tooltip
                                title={"Copy cURL command."}
                                placement="top"
                              >
                                <Button
                                  justIcon
                                  round
                                  simple
                                  color="primary"
                                  className="info"
                                >
                                  <FileCopy />
                                </Button>
                              </Tooltip>
                            </CopyToClipboard>
                            {apMediaCurlCommand}
                            <a
                              href={`https://developer.ap.org/ap-media-api/`}
                              target="_new"
                            >
                              <Tooltip
                                title="View AP Media API documentation"
                                placement="top"
                              >
                                <Button
                                  justIcon
                                  round
                                  simple
                                  color="primary"
                                  className="info"
                                >
                                  <HelpOutline />
                                </Button>
                              </Tooltip>
                            </a>
                          </span>
                        )
                      }
                    ]}
                  />
                </GridItem>
              )}
            </GridContainer>
            <GridItem md={12}>
              {apImages.length > 0
                ? apImages
                : areAPImagesLoaded
                ? " none found."
                : ""}
              {!areAPImagesLoaded && (
                <CircularProgress
                  style={{
                    position: "relative",
                    top: "25px",
                    left: "150px"
                  }}
                  size={60}
                />
              )}
            </GridItem>
          </React.Fragment>
        )}
        {this.props.doLoadImago && (
          <React.Fragment>
            <GridContainer>
              <GridItem md={12}>
                <h4>Imago: ({this.props.searchTerms})</h4>
              </GridItem>
              {this.props.displayAPIRequest && (
                <GridItem md={12}>
                  <Accordion
                    active={-1}
                    collapses={[
                      {
                        title: "Imago cURL Command",
                        content: (
                          <span>
                            <CopyToClipboard
                              text={imagoCurlCommand}
                              onCopy={() =>
                                alert(`cURL command copied to clipboard.`)
                              }
                            >
                              <Tooltip
                                title={"Copy cURL command."}
                                placement="top"
                              >
                                <Button
                                  justIcon
                                  round
                                  simple
                                  color="primary"
                                  className="info"
                                >
                                  <FileCopy />
                                </Button>
                              </Tooltip>
                            </CopyToClipboard>
                            {imagoCurlCommand}
                            <a
                              href={`https://cdn.dataskrive.com/api/asset/C4UKpcq/render`}
                              target="_new"
                            >
                              <Tooltip
                                title="View Imago API documentation"
                                placement="top"
                              >
                                <Button
                                  justIcon
                                  round
                                  simple
                                  color="primary"
                                  className="info"
                                >
                                  <HelpOutline />
                                </Button>
                              </Tooltip>
                            </a>
                          </span>
                        )
                      }
                    ]}
                  />
                </GridItem>
              )}
            </GridContainer>
            <GridItem md={12}>
              {imagoImages.length > 0
                ? imagoImages
                : areImagoImagesLoaded
                ? " none found."
                : ""}
              {!areImagoImagesLoaded && (
                <CircularProgress
                  style={{
                    position: "relative",
                    top: "25px",
                    left: "150px"
                  }}
                  size={60}
                />
              )}
            </GridItem>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

ImageSearchTermResults.propTypes = {
  // Where the user to be redirected on clicking the avatar
  onChange: PropTypes.func,
  value: PropTypes.string,
  required: PropTypes.bool
}
ImageSearchTermResults.defaultProps = defaultProps

export default withStyles(style)(ImageSearchTermResults)
