import adminDAO from "daos/adminDAO"

const getAuthHeader = () => ({
  Authorization: `Bearer ${localStorage.getItem("accessToken")}`
})

// Other headers remain the same
const STATIC_HEADERS = {
  accept: "application/json",
  "accept-charset": "UTF-8",
  "content-type": "application/json",
  "cache-control": "no-cache"
}

const get = async (url, params) => {
  const options = {
    method: "GET"
  }

  return await request(url, options, params)
}

const put = async (url, params, data) => {
  const options = {
    method: "PUT",
    body: JSON.stringify(data)
  }

  return await request(url, options, params)
}

const post = async (url, params, data) => {
  const options = {
    method: "POST",
    body: JSON.stringify(data)
  }

  return await request(url, options, params)
}

const postUpload = async (url, params, data) => {
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`
    },
    body: data
  }

  return fetch(url, options)
}

const del = async (url, params) => {
  const options = {
    method: "DELETE"
  }

  return await request(url, options, params)
}

const request = async (url, options, params) => {
  let requestUrl = url
  if (params) {
    requestUrl += `?${toQueryString(params)}`
  }
  options.credentials = "include"
  options.headers = { ...STATIC_HEADERS, ...getAuthHeader() } // Combine static headers with the auth header
  try {
    const resp = await fetch(requestUrl, options)
    await handleErrors(resp)
    return resp
  } catch (error) {
    // Handle general fetch errors
    await refreshAccessToken() // Refresh the token
    options.headers = { ...STATIC_HEADERS, ...getAuthHeader() } // Update the headers with the new token
    return fetch(requestUrl, options)
  }
}

// Function to refresh the access token
const refreshAccessToken = async () => {
  try {
    const response = await adminDAO.refresh({
      refreshToken: localStorage.getItem("refreshToken"),
      accessToken: localStorage.getItem("accessToken")
    })
    if (response.status === 200) {
      const data = await response.json()
      localStorage.setItem("accessToken", data.content.accessToken)
      localStorage.setItem("refreshToken", data.content.refreshToken)
    } else {
      localStorage.removeItem("accessToken")
      localStorage.removeItem("refreshToken")
      throw new Error("Could not refresh token")
    }
  } catch (error) {
    console.error("Failed to refresh the access token", error)
  }
}

const handleErrors = async resp => {
  if (resp.status === 401) {
    throw new Error("Auth Error", { cause: resp.status })
  }
}

const toQueryString = object =>
  Object.keys(object)
    .map(k => `${k}=${object[k]}`)
    .join("&")

export { get, put, post, del, postUpload }
