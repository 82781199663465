import React, { Component } from "react"
import PropTypes from "prop-types"
import { List } from "immutable"
import { Field, FieldArray } from "formik"
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  FormLabel,
  FormHelperText,
  Typography
} from "@mui/material"
import { startCase } from "lodash"
import { CheckBoxTwoTone, CheckBoxOutlineBlank } from "@mui/icons-material"

const defaultProps = {
  fullWidth: false,
  hideLabel: false,
  margin: "normal",
  readOnly: false,
  required: false
}

const propTypes = {
  fullWidth: PropTypes.bool,
  helpText: PropTypes.string,
  hideLabel: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  margin: PropTypes.oneOf(["none", "dense", "normal"]),
  name: PropTypes.string.isRequired,
  optionLabelKey: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(List).isRequired,
  optionValueKey: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  required: PropTypes.bool
}

class CheckboxGroup extends Component {
  render() {
    const {
      id,
      fullWidth,
      helpText,
      hideLabel,
      label,
      margin,
      name,
      optionLabelKey,
      options,
      optionValueKey,
      readOnly,
      required
    } = this.props

    const handleChange = (values, arrayHelpers, event, option) => {
      if (event.target.checked) {
        arrayHelpers.push(option.toJS())
      } else {
        const match = values.find(
          v => v[optionValueKey] === option.get(optionValueKey)
        )
        arrayHelpers.remove(values.indexOf(match))
      }
    }

    const checkedSelected = (values = [], option) => {
      const selected = values.findIndex(
        v => v[optionValueKey] === option.get(optionValueKey)
      )
      return selected !== -1 ? true : false
    }

    return (
      <FieldArray name={name}>
        {helpers => (
          <Field id={id} name={name}>
            {({ meta: { value, error } }) => {
              const hasErrors = error != null && error.length !== 0 && true
              const errors = hasErrors && error
              return (
                <FormControl
                  className="form__control"
                  hiddenLabel={label && true}
                  required={required}
                  fullWidth={fullWidth}
                  error={errors}
                  disabled={readOnly}
                  margin={margin}
                >
                  {label && (
                    <FormLabel
                      className="form__label"
                      required={required}
                      disabled={readOnly}
                      error={errors}
                      hidden={hideLabel}
                    >
                      {label}
                    </FormLabel>
                  )}
                  {helpText && (
                    <FormHelperText
                      className="form__helper-text"
                      error={errors}
                      disabled={readOnly}
                      required={required}
                      hidden={helpText == null}
                    >
                      {helpText}
                    </FormHelperText>
                  )}
                  <FormGroup className="form__input-checkbox">
                    {options &&
                      options.size > 0 &&
                      options.map(o => {
                        const checked = checkedSelected(value, o)
                        return (
                          <FormControlLabel
                            key={o.get(optionValueKey)}
                            className="form__input-checkbox-label"
                            checked={checked}
                            disabled={readOnly}
                            control={
                              <Checkbox
                                name={`${name}.${o.get(optionValueKey)}`}
                                disabled={readOnly}
                                checked={checked}
                                checkedIcon={
                                  <CheckBoxTwoTone color="primary" />
                                }
                                icon={<CheckBoxOutlineBlank color="disabled" />}
                                onChange={e =>
                                  handleChange(value, helpers, e, o)
                                }
                                value={o.get(optionValueKey)}
                              />
                            }
                            label={
                              <Typography variant="body2" color="secondary">
                                {startCase(o.get(optionLabelKey))}
                              </Typography>
                            }
                          />
                        )
                      })}
                  </FormGroup>
                </FormControl>
              )
            }}
          </Field>
        )}
      </FieldArray>
    )
  }
}

CheckboxGroup.defaultProps = defaultProps
CheckboxGroup.propTypes = propTypes
export default CheckboxGroup
