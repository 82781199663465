import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import { Pagination } from "@mui/material"

import NarrativeSearchBlock from "components/NarrativeAdmin/NarrativeSearchBlock"
import PropTypes from "prop-types"

const useStyles = makeStyles(() => ({
  pagingBottom: { display: "flex", justifyContent: "center" }
}))

function pagination(length, currentPage, itemsPerPage) {
  return {
    total: length,
    pageSize: itemsPerPage,
    currentPage: currentPage,
    lastPage: Math.ceil(length / itemsPerPage),
    from: (currentPage - 1) * itemsPerPage,
    to: currentPage * itemsPerPage
  }
}

const SearchResults = props => {
  const classes = useStyles()
  const { blockViewStyle, searchResults, pageSize } = props

  // Paging values

  const [page, setPage] = React.useState(1)
  const [paging, setPaging] = React.useState({})

  const handleChange = (event, value) => {
    setPage(value)
    setPaging(pagination(searchResults.length, value, pageSize || 10))
  }

  React.useEffect(() => {
    if (searchResults && searchResults.length > 0) {
      setPaging(pagination(searchResults.length, 1, pageSize || 10))
    }
  }, [searchResults])

  return (
    <React.Fragment>
      {searchResults &&
        searchResults.length > 0 &&
        searchResults.slice(paging.from, paging.to).map((result, index) => (
          <NarrativeSearchBlock
            blockViewStyle={blockViewStyle}
            key={index}
            searchResult={result}
            savedEntry={(type, id, content) => {
              props.savedEntry(type, id, content)
            }}
          />
        ))}
      <div className={classes.pagingBottom}>
        <Pagination
          count={paging.lastPage}
          page={page}
          onChange={handleChange}
        />
      </div>
    </React.Fragment>
  )
}
SearchResults.propTypes = {
  savedEntry: PropTypes.func
}
export default SearchResults
