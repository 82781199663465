import React, { useMemo, useEffect, useRef, useState } from "react"
import {
  DataGridPro,
  GridActionsCellItem,
  useGridApiRef
} from "@mui/x-data-grid-pro"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"

import Box from "@mui/material/Box"
import Alert from "@mui/material/Alert"
import Snackbar from "@mui/material/Snackbar"
import { Button } from "@mui/material"
import SaveIcon from "@mui/icons-material/Save"

import CancelIcon from "@mui/icons-material/Cancel"
import { AlertTitle } from "@mui/material"

import CheckIcon from "@mui/icons-material/CheckBox"
import Square from "@mui/icons-material/CropSquare"

import Helpers from "tools/Helpers"

import InfoDialog from "components/CustomDialogs/InfoDialog"
import AffiliateDAO from "daos/affiliateDAO"

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    setDialogSuccessMessage: store.uiStore.setDialogSuccessMessage,
    setDialogSuccessOpen: store.uiStore.setDialogSuccessOpen,
    setDialogFailMessage: store.uiStore.setDialogFailMessage,
    setDialogFailOpen: store.uiStore.setDialogFailOpen,
    dialogFailOpen: store.uiStore.dialogFailOpen,
    dialogFailMessage: store.uiStore.dialogFailMessage
  }))
}

function AffiliateShortCodeTable(props) {
  const {
    affiliateShortCodeData,
    showArchivedShortCodes,
    companies,
    groupType,
    dateFilterProps,
    reloadShortCodes
  } = props

  const {
    setDialogSuccessMessage,
    setDialogSuccessOpen,
    setDialogFailMessage,
    setDialogFailOpen
  } = useStoreData()

  const apiRef = useGridApiRef()

  const [hasUnsavedRows, setHasUnsavedRows] = useState(false)
  const unsavedChangesRef = useRef({ unsavedRows: {}, rowsBeforeChange: {} })
  //const [isSaving, setIsSaving] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [errorValue, setErrorValue] = useState("")
  const [rowSelectionModel, setRowSelectionModel] = useState([])

  const [filteredData, setFilteredData] = useState(() =>
    showArchivedShortCodes
      ? affiliateShortCodeData
      : affiliateShortCodeData?.filter(item => !item.isArchived) || []
  )

  useEffect(() => {
    setFilteredData(
      showArchivedShortCodes
        ? affiliateShortCodeData
        : affiliateShortCodeData?.filter(item => !item.isArchived) || []
    )
  }, [affiliateShortCodeData, showArchivedShortCodes])

  const handleAlertClose = () => {
    setAlertOpen(false)
  }

  const processRowUpdate = (newRow, oldRow) => {
    // Check if the newRow has newData from the oldRow
    const newData = Object.keys(newRow).some(key => newRow[key] !== oldRow[key])

    if (newData) {
      const rowId = newRow.id
      unsavedChangesRef.current.unsavedRows[rowId] = newRow
      if (!unsavedChangesRef.current.rowsBeforeChange[rowId]) {
        unsavedChangesRef.current.rowsBeforeChange[rowId] = oldRow
      }
      setHasUnsavedRows(true)
      const isSelected = rowSelectionModel.includes(newRow.id)
      return { ...newRow, isSelected }
    }
    // If there no changes, return the oldRow without updating unsavedChangesRef or hasUnsavedRows
    //persist selected rows with checkbox selected
    const isSelected = rowSelectionModel.includes(newRow.id)
    return { ...oldRow, isSelected }
  }
  const saveChanges = async () => {
    if (Object.keys(unsavedChangesRef.current.unsavedRows).length === 0) {
      return
    }
    const bulkUpdateData = Object.values(
      unsavedChangesRef.current.unsavedRows
    ).map(newRow => ({
      id: newRow.id,
      affiliateUrl:
        newRow.affiliateUrl ||
        "https://affiliates.routy.app/route/{shortcode}?affId=3147",
      affiliateId: newRow.affiliateId,
      affiliateCodeSourceId: newRow.affiliateCodeSourceId,
      companyCode: newRow.companyCode,
      name: newRow.name,
      companyId: newRow.companyId,
      createdByAccountId: newRow.createdByAccountId,
      createdDateTime: newRow.createdDateTime,
      leagueId: newRow.leagueId,
      isArchived: newRow.isArchived || false,
      modifiedByAccountId: newRow.modifiedByAccountId,
      modifiedDateTime: newRow.modifiedDateTime,
      // auditInfo: newRow.auditInfo,
      // doClientVerification: false,
      // narrativeId: null,
      sourceId: newRow.sourceId
    }))

    //try {
    //setIsSaving(true)

    // Process each update individually
    let success = true
    //let responseMessage = "Save successful"
    const resultMessages = []
    for (const updateData of bulkUpdateData) {
      const response = await AffiliateDAO.updateShortCodeById(
        updateData,
        updateData.id
      )
      if (response !== updateData.id) {
        // If any request fails, mark success as false
        success = false

        break
      } else {
        resultMessages.push(
          `You succcesfully updated id#${response} of short code "${updateData.name}"`
        )
      }
    }

    if (success) {
      setDialogSuccessMessage(resultMessages.join(", "))
      setDialogSuccessOpen(true)
      reloadShortCodes()
    } else {
      setDialogFailOpen(true)
      setDialogFailMessage("Failed to Update")
    }

    // Clear unsaved changes after successful save
    setHasUnsavedRows(false)
    unsavedChangesRef.current = { unsavedRows: {}, rowsBeforeChange: {} }
  }

  const getRowClassName = params => {
    // Check if current row's ID is in the unsaved changes
    if (unsavedChangesRef.current.unsavedRows[params.id]) {
      return "edited-row"
    }
    //condition to apply 'archived-row' class
    return params.row && params.row.isArchived ? "archived-row" : ""
  }

  const affiliateShortCodeColumns = useMemo(
    () => [
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        getActions: ({ id, row }) => [
          <GridActionsCellItem
            icon={<CancelIcon />}
            label={"Discard changes"}
            disabled={
              unsavedChangesRef.current.unsavedRows[id] === undefined ||
              JSON.stringify(unsavedChangesRef.current.unsavedRows[id]) ===
                JSON.stringify(unsavedChangesRef.current.rowsBeforeChange[id])
            }
            onClick={() => {
              apiRef.current.updateRows([
                unsavedChangesRef.current.rowsBeforeChange[id]
              ])
              delete unsavedChangesRef.current.rowsBeforeChange[id]
              delete unsavedChangesRef.current.unsavedRows[id]
              setHasUnsavedRows(
                Object.keys(unsavedChangesRef.current.unsavedRows).length > 0
              )
            }}
            style={{
              color:
                unsavedChangesRef.current.unsavedRows[id] === undefined
                  ? "#bdbdbd"
                  : "#D01111"
            }}
          />
        ]
      },
      {
        headerName: "ID",
        field: "id",
        width: 75
      },
      {
        headerName: "Company",
        field: "companyId",
        type: "singleSelect",
        valueOptions: (companies || []).map(ct => ({
          value: ct.id,
          label: ct.name
        })),
        editable: true,
        width: 125
      },
      {
        headerName: "Group Type",
        field: "affiliateCodeSourceId",
        type: "singleSelect",
        editable: true,
        valueOptions: (groupType || []).map(ct => ({
          value: ct.id,
          label: ct.name
        })),
        width: 100
      },
      {
        headerName: "Name",
        field: "name",
        editable: true,
        width: 250,
        renderCell: params => (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer"
            }}
            title={params.value} // Show full text in tooltip on hover
          >
            {params.value}
          </div>
        )
      },
      {
        headerName: (
          <div style={{ display: "flex" }}>
            Base Link
            <InfoDialog
              content={
                "This base link is the Routy URL and will contain the Company Code for the entry."
              }
            />
          </div>
        ),
        field: "baseLink",
        editable: false,
        renderCell: cell => (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer"
            }}
          >
            {cell.row?.baseLink?.replace(
              /\[shortcode\]/g,
              cell.row?.companyCode || "[shortcode]"
            )}
          </div>
        ),
        width: 400
      },
      {
        headerName: "Company Code",
        field: "companyCode",
        editable: true,
        width: 120,
        renderCell: params => (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer"
            }}
            title={params.value} // Show full text in tooltip on hover
          >
            {params.value}
          </div>
        ),
        preProcessEditCellProps: params => {
          const inputValue = params.props.value.trim()
          const hasError = inputValue.length !== 5
          const hasNonInteger = inputValue.match(/[^0-9]/)
          if (hasNonInteger) {
            setErrorValue("Company Code can only have numbers")
            setAlertOpen(true)
          } else if (hasError) {
            setErrorValue("Company Code must be 5 digits")
            setAlertOpen(true)
          } else {
            setErrorValue("")
            setAlertOpen(false)
          }

          return {
            ...params.props,
            error: hasError || hasNonInteger,
            value: hasError || hasNonInteger ? null : params.props.value
          }
        }
      },
      {
        headerName: "Source ID",
        field: "sourceId",
        editable: true,
        preProcessEditCellProps: params => {
          const inputValue = params.props.value.trim()
          const hasNonInteger = inputValue.match(/[^0-9]/)
          if (hasNonInteger) {
            setErrorValue("Source ID can only have numbers")
            setAlertOpen(true)
          } else {
            setErrorValue("")
            setAlertOpen(false)
          }

          return {
            ...params.props,
            error: hasNonInteger,
            value: hasNonInteger ? null : params.props.value
          }
        },
        width: 75
      },
      {
        headerName: "Archive",
        field: "isArchived",
        editable: true,
        type: "boolean",
        sortable: false,
        renderCell: params => (
          <div>
            {params.value ? (
              <CheckIcon style={{ color: "#4d9ab5" }} />
            ) : (
              <Square style={{ color: "#808080" }} />
            )}
          </div>
        ),
        width: 100
      },
      {
        headerName: "Audit Info",
        field: "modifiedDateTime",
        type: "dateTime",
        valueGetter: params => {
          const dateValue = params.row.modifiedDateTime
          return dateValue ? new Date(dateValue) : null
        },
        ...dateFilterProps("modifiedDateTime", filteredData),
        renderCell: cell =>
          Helpers.renderAuditInfoMuiDataGridProAffiliateCell(cell),
        width: 250
      }
    ],
    [apiRef]
  )

  return (
    <>
      <Button
        aria-label="AffiliateShortCodeTable SaveBtn SaveShortCode"
        disabled={!hasUnsavedRows}
        //loading={isSaving}
        onClick={saveChanges}
        startIcon={
          <SaveIcon
            style={{
              color: hasUnsavedRows ? "#66bb6a" : "#bdbdbd",
              fontSize: "32px"
            }}
          />
        }
        //loadingPosition="start"
        style={{
          color: hasUnsavedRows ? "#66bb6a" : "#bdbdbd",
          paddingLeft: "25px"
        }}
      >
        <span>SAVE ALL</span>
      </Button>
      <Box
        aria-label="AffiliateShortCodeTable Box GridContainer"
        sx={{
          "& .Mui-error": {
            backgroundColor: "#ffcccc",
            border: "solid 2px red",
            height: "51px"
          }
        }}
      >
        <DataGridPro
          aria-label="AffiliateShortCodeTable MuiDatagrid GridShortCodeTable"
          columns={affiliateShortCodeColumns}
          rows={filteredData}
          apiRef={apiRef}
          processRowUpdate={processRowUpdate}
          onRowSelectionModelChange={newRowSelectionModel => {
            setRowSelectionModel(newRowSelectionModel)
          }}
          rowSelectionModel={rowSelectionModel}
          pagination
          initialState={{
            sorting: {
              sortModel: [{ field: "id", sort: "desc" }]
            },
            pagination: { paginationModel: { pageSize: 25 } }
          }}
          pageSizeOptions={[25, 50, 100]}
          getRowClassName={getRowClassName}
          sx={{
            "& .MuiDataGrid-columnHeaders": { backgroundColor: "#ddeaef" },
            "& .MuiDataGrid-row": {
              "&.archived-row": {
                textDecoration: "line-through"
              },
              "&.Mui-selected": {
                backgroundColor: "#ddeaef"
              }
            },
            "& .MuiDataGrid-row.edited-row": {
              backgroundColor: "#dddddd"
            }
          }}
        />
        <Snackbar
          aria-label="AffiliateShortCodeTable Alert InvalidContentEditAlert"
          open={alertOpen}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        >
          <Alert variant="filled" severity="error">
            <AlertTitle>{errorValue}</AlertTitle>
          </Alert>
        </Snackbar>
      </Box>{" "}
    </>
  )
}
export default AffiliateShortCodeTable
