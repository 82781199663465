/* eslint-disable arrow-body-style */
import { useEffect, useState } from "react"
import { Grid, IconButton } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import clsx from "clsx"

import NarrativeDAO from "daos/narrativeDAO"
import ChangeLog from "../Data/ChangeLog"

const useStyles = makeStyles(theme => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    padding: "0px",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  }
}))

const ParagraphChangeLog = ({ paragraph_Id, handleRollback }) => {
  const [changeLogs, setChangeLogs] = useState([])
  const [isChangeLogsLoading, setIsChangeLogsLoading] = useState(false)
  const [isChangeLogsLoaded, setIsChangeLogsLoaded] = useState(false)
  const [totalChangeLogRecords, setTotalChangeLogRecords] = useState(0)
  const [isHistoryVisible, setIsHistoryVisible] = useState(false)

  const toggleChangeLog = () => {
    setIsHistoryVisible(prev => !prev)
  }

  const fetchChangeLog = async () => {
    setIsChangeLogsLoading(true)
    const content = await NarrativeDAO.getParagraphHistory(paragraph_Id)
    if (!content) {
      return
    }

    setChangeLogs(content)
    setTotalChangeLogRecords(content.length)
    setIsChangeLogsLoaded(true)
    setIsChangeLogsLoading(false)
  }

  useEffect(() => {
    fetchChangeLog()
  }, [])

  const classes = useStyles()

  return (
    <Grid item xs={12}>
      <div
        style={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.14)"
        }}
      >
        <div
          title="View Change Log"
          onClick={toggleChangeLog}
          style={{
            flexGrow: 1,
            cursor: "pointer",
            margin: "0 15px",
            padding: "10px 0!important",
            display: "flex"
          }}
        >
          <div>
            <h4
              style={{
                fontSize: "15px",
                marginTop: 0,
                marginBottom: 0,
                fontWeight: "bolder"
              }}
            >
              Change Log
            </h4>
          </div>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: isHistoryVisible
            })}
            aria-expanded={isHistoryVisible}
            aria-label="show more"
            size="large"
          >
            <ExpandMoreIcon />
          </IconButton>
        </div>
        <div>
          {isHistoryVisible && (
            <ChangeLog
              handleRollBack={handleRollback}
              changeLogs={changeLogs}
              totalChangeLogRecords={totalChangeLogRecords}
              isChangeLogsLoading={isChangeLogsLoading}
              isChangeLogsLoaded={isChangeLogsLoaded}
            />
          )}
        </div>
      </div>
    </Grid>
  )
}

export default ParagraphChangeLog
