import React, { useMemo, useState, useCallback } from "react"
import { useObserver } from "mobx-react"

import { useStore } from "contexts/rootContext"
import PropTypes from "prop-types"
import makeStyles from "@mui/styles/makeStyles"
import { TreeView } from "@mui/x-tree-view"
import { SvgIcon } from "@mui/material"
import {
  LibraryBooks,
  ShortText,
  ArrowDropDown,
  ArrowRight,
  FlashOn,
  Code,
  SyncAlt,
  AddAlert,
  ShowChart,
  LibraryBooksOutlined,
  AddAlertTwoTone,
  Attachment,
  SettingsEthernet,
  FeaturedPlayList,
  PanTool,
  PanToolTwoTone,
  AssessmentTwoTone,
  AssessmentOutlined,
  Battery20,
  BatteryUnknown,
  BookmarkBorderOutlined,
  BlockOutlined
} from "@mui/icons-material"

import Helpers from "tools/Helpers"
import "react-contexify/dist/ReactContexify.css"
import { MemoStyledTreeItem as StyledTreeItem } from "./StyledTreeItem"
import { groupCollectionBy } from "tools/CollectionHelper"
import PlannedIcon from "@mui/icons-material/EmojiObjectsOutlined"
import ReplicaKillswitch from "assets/icons/ReplicaKillswitch"
import ReplicaDependency from "assets/icons/ReplicaDependency"

const Pilcrow = () => (
  <SvgIcon
    style={{ marginLeft: "-7px", marginTop: "-5px", width: "15px" }}
    fontSize="small"
    focusable="false"
    viewBox="0 0 24 24"
    aria-hidden="true"
    role="presentation"
  >
    <path d="M28,0h-4h-4h-4h-4C7.582,0,4,3.582,4,8s3.582,8,8,8v16h4V4h4v28h4V4h4V0z" />
  </SvgIcon>
)
const ColorBlockOutlined = () => (
  <BlockOutlined style={{ color: "#A31515", fontSize: "20px" }} />
)

const labelStatusIcon = (status, icon, typeOfContent) => {
  switch (status) {
    case 1:
      return Battery20
    case 2:
      return BatteryUnknown
    case 4:
      return PlannedIcon
    case 5:
      return BookmarkBorderOutlined
    case 6:
      return ColorBlockOutlined
    default:
      return icon
  }
}

const dependencyTriggerIcon = (trigger, narrativeId) => {
  // Checking for replica triggers first
  if (trigger.createReplica) {
    if (trigger.type.startsWith("narrative_killswitch")) {
      return ReplicaKillswitch
    }
    return ReplicaDependency
  }

  // Handling regular triggers
  if (trigger.type.startsWith("narrative_killswitch")) {
    return trigger.narrative_Id !== narrativeId ? PanToolTwoTone : PanTool
  } else if (trigger.type.startsWith("narrative_reviewswitch")) {
    return trigger.narrative_Id !== narrativeId
      ? AssessmentTwoTone
      : AssessmentOutlined
  } else {
    return trigger.narrative_Id !== narrativeId ? SettingsEthernet : Code
  }
}
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch",
    maxWidth: "100%"
  },
  drawerPrimaryCard: {
    flexGrow: 1,
    overflow: "auto",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch"
  },
  drawerContent: {
    flexGrow: 0
  },
  drawerPrimaryContent: {
    flexGrow: 1,
    padding: "10px",
    paddingRight: 0
  },
  list: {
    padding: 0
  },
  listItem: {
    margin: 0,
    padding: 0
  },
  listItemIcon: {
    minWidth: "auto"
  },
  checkbox: {
    marginLeft: "17px",
    padding: 0
  },
  cardHeader: {
    padding: "5px 10px"
  },
  cardBody: {
    padding: "5px 10px"
  }
}))

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    postCopySentence: store.narrativeStore.postCopySentence,
    postMoveSentence: store.narrativeStore.postMoveSentence,
    postCopyParagraph: store.narrativeStore.postCopyParagraph,
    postCopyTrigger: store.narrativeStore.postCopyTrigger,
    postArchiveSentence: store.narrativeStore.postArchiveSentence,
    postArchiveParagraph: store.narrativeStore.postArchiveParagraph
  }))
}

function NarrativeExplorerComponent(props) {
  const classes = useStyles()
  const [selectedSourceId, setSourceId] = useState(0)
  const [selectedSourceType, setSourceType] = useState("")
  const [lastActionType, setLastActionType] = useState("")

  const {
    postCopySentence,
    postArchiveSentence,
    postMoveSentence,
    postCopyParagraph,
    postArchiveParagraph,
    postCopyTrigger
  } = useStoreData()

  const {
    narrative,
    setActiveView,
    refreshNarrative,
    activeView,
    setEditView
  } = props
  const {
    id: narrativeId,
    triggers,
    paragraphs,
    libraryParagraphs,
    outlineBlocks,
    lastModifiedDate
  } = narrative

  const [contentBlocks, setContenentBlocks] = useState([])
  const [processedParagraphs, setProcessedParagraphs] = useState([])
  const [orphanBlocks, setOrphanBlocks] = useState([])
  const [outlineContentBlocks, setOutlineContentBlocks] = useState([])
  const [dependencies, setDependencies] = useState([])
  const [newsTriggers, setNewsTriggers] = useState([])
  const [insightTriggers, setInsightTriggers] = useState([])

  const [isLoaded, setIsLoaded] = useState(false)

  const firstItem = activeView.split(",")[0]
  const itemType = firstItem.includes("-") ? firstItem.split("-")[0] : firstItem
  const itemId = firstItem.includes("-") ? firstItem.split("-")[1] : firstItem

  React.useEffect(() => {
    if (
      (paragraphs && paragraphs.length > 0) ||
      (outlineBlocks && outlineBlocks.length > 0)
    ) {
      const localParagraphs =
        paragraphs && JSON.parse(JSON.stringify(paragraphs))
      for (const paragraph of localParagraphs) {
        if (
          paragraph.libraryParagraph_Id > 0 &&
          !libraryParagraphs.filter(
            lp => lp.id === paragraph.libraryParagraph_Id
          )?.length > 0
        ) {
          paragraph.status_Id = 6 //Mark as errored to indicate not in associated library
        }
        for (const sentence of paragraph.sentences) {
          if (
            sentence.isLibraryPlaceholder &&
            !libraryParagraphs.filter(
              lp => lp.id === sentence.libraryParagraph_Id
            )?.length > 0
          ) {
            sentence.status_Id = 6 //Mark as errored to indicate not in associated library
          }
          if (
            sentence.isFromLibrary &&
            !libraryParagraphs.filter(lp => lp.id === sentence.paragraph_Id)
              ?.length > 0
          ) {
            sentence.status_Id = 6 //Mark as errored to indicate not in associated library
          }
        }
      }
      setProcessedParagraphs(localParagraphs)
      const localContent =
        localParagraphs &&
        groupCollectionBy(
          localParagraphs,
          p => p.contentBlock && p.contentBlock.toLowerCase()
        )
      setContenentBlocks(localContent)
      let paragraphsWithoutOutlineBlockIds =
        (localParagraphs &&
          localParagraphs.filter(
            p =>
              !p.narrativeOutlineBlockId ||
              p.narrativeOutlineBlockId === 0 ||
              !outlineBlocks.find(ob => ob.id === p.narrativeOutlineBlockId)
          )) ||
        []
      let localOrphans =
        paragraphsWithoutOutlineBlockIds &&
        groupCollectionBy(
          paragraphsWithoutOutlineBlockIds,
          p => p.contentBlock && p.contentBlock.toLowerCase()
        )
      setOrphanBlocks(localOrphans)
      let sortedBlocks =
        (outlineBlocks &&
          outlineBlocks.sort((a, b) => {
            let comparison = 0
            if (a.position > b.position) {
              comparison = 1
            } else if (a.position < b.position) {
              comparison = -1
            }
            return comparison
          })) ||
        []
      const localOutline =
        sortedBlocks &&
        groupCollectionBy(
          sortedBlocks,
          p => p.contentBlock && p.contentBlock.toLowerCase()
        )
      setOutlineContentBlocks(localOutline)
      setIsLoaded(true)
    } else {
      if (narrative.narrativeToken) {
        setIsLoaded(true)
      }
    }
  }, [
    narrative,
    outlineBlocks,
    paragraphs,
    libraryParagraphs,
    lastModifiedDate
  ])

  React.useEffect(() => {
    if (triggers) {
      setDependencies(() =>
        triggers.filter(
          t =>
            t.isDependency !== null &&
            (t.isDependency === true || t.isDependency === "true")
        )
      )
      setNewsTriggers(() =>
        triggers
          .filter(
            t => t.isNews !== null && (t.isNews === true || t.isNews === "true")
          )
          .sort((a, b) => a.rank - b.rank || b.id - a.id)
      )
      setInsightTriggers(() =>
        triggers
          .filter(
            t =>
              (t.isNews === null ||
                t.isNews === false ||
                t.isNews === "false") &&
              (t.isDependency === null ||
                t.isDependency === false ||
                t.isDependency === "false")
          )
          .sort((a, b) => b.id - a.id)
      )
    }
  }, [triggers])

  const handleTreeChange = useCallback(
    (event, newItem, placeholderId) => {
      let selected = activeView.split(",")
      // Check if there is a bulk select even
      // If there's a blockselection event and it's not a top level abstraction
      if (!["dependencies", "newstriggers", "insights"].includes(newItem)) {
        // Otherwise add the item directly
        if (event.ctrlKey) {
          if (selected.includes(newItem)) {
            selected = selected.filter(value => value !== newItem)
          } else {
            selected.push(newItem)
          }
          let newActiveView = selected.join(",")
          setActiveView(newActiveView)
        } else {
          setActiveView(newItem, placeholderId)
        }
      } else {
        setActiveView(newItem)
      }
    },
    [activeView, setActiveView]
  )

  const setEditAction = useCallback(
    (event, newItem) => {
      let selected = activeView.split(",")
      // Check if there is a bulk select even
      // If there's a blockselection event and it's not a top level abstraction
      if (!["dependencies", "newstriggers", "insights"].includes(newItem)) {
        if (!event.ctrlKey) {
          if (selected.includes(newItem)) {
            setEditView(newItem)
          } else {
            setEditView(newItem, true)
          }
        } else {
          setEditView(newItem)
        }
      }
    },
    [activeView, setEditView]
  )

  const setActionTaken = useCallback(
    currentAction => {
      if (currentAction === "Paste" && lastActionType === "Copy") {
        setLastActionType(currentAction)
        let selectedViews = activeView.split(",")
        if (selectedViews && selectedViews.length > 0) {
          let nums = []
          selectedViews.forEach(item => {
            let temp = item.split("-")
            if (temp[1]) {
              nums.push(Number(temp[1]))
            }
          })
          return nums
        } else {
          return null
        }
      }
      setLastActionType(currentAction)
      return null
    },
    [activeView, lastActionType]
  )

  let defaultExpanded = narrative.buildFromOutline ? ["4"] : ["5"] //If build from outline expand it

  if (
    itemType === "outlinesentenceid" ||
    itemType === "sentenceid" ||
    itemType === "placeholderoutlinesentenceid"
  ) {
    const sentenceId = Number.parseInt(itemId)
    let selectedParagraph =
      processedParagraphs &&
      processedParagraphs.find(p => p.sentences.some(s => s.id === sentenceId))
    if (selectedParagraph) {
      if (
        itemType === "outlinesentenceid" ||
        itemType === "placeholderoutlinesentenceid"
      ) {
        defaultExpanded = [
          "4",
          selectedParagraph.contentBlock,
          `outlineparagraphid-${selectedParagraph.id}`,
          `outlineblockid-${selectedParagraph.narrativeOutlineBlockId}`
        ]
      } else {
        defaultExpanded.push(`paragraphid-${selectedParagraph.id}`)
      }
    }
  }
  if (itemType === "triggerid") {
    if (
      dependencies &&
      dependencies.filter(t => t.id === Number.parseInt(itemId)).length > 0
    ) {
      defaultExpanded.push("0")
    } else {
      defaultExpanded.push("2")
    }
  }
  if (itemType === "newstriggerid") {
    defaultExpanded.push("1")
  }
  if (itemType === "outlineparagraphid") {
    let thisParagraph =
      processedParagraphs &&
      processedParagraphs.find(p => p.id === Number.parseInt(itemId))
    if (thisParagraph) {
      defaultExpanded = [
        "4",
        thisParagraph.contentBlock,
        `outlineblockid-${thisParagraph.narrativeOutlineBlockId}`
      ]
    }
  }

  const treeitems = useMemo(
    () =>
      !narrative.buildFromOutline &&
      contentBlocks &&
      Object.keys(contentBlocks).map(key => (
        <StyledTreeItem
          key={`element-${key}`}
          nodeId={key}
          itemView={`element-${key}`}
          labelText={`<${key}/>`}
          labelIcon=""
          setActiveViewEvent={handleTreeChange}
          addLabel={`Add Block`}
          addView={`createparagraph-${key}`}
          setEditViewEvent={setEditAction}
        >
          {contentBlocks[key].map(paragraph => (
            <StyledTreeItem
              sourceId={paragraph.id}
              paragraphId={paragraph.id}
              narrativeId={paragraph.narrative_Id}
              selectedSourceId={selectedSourceId}
              setSourceId={setSourceId}
              setActionTaken={setActionTaken}
              selectedSourceType={selectedSourceType}
              setSourceType={setSourceType}
              setLastActionType={setLastActionType}
              refreshNarrative={refreshNarrative}
              key={`paragraphid-${paragraph.id}`}
              itemView={`paragraphid-${paragraph.id}`}
              nodeId={`paragraphid-${paragraph.id}`}
              labelText={`(${paragraph.id}) ${paragraph.name || ""}`}
              labelInfo={
                paragraph.triggerType
                  ? Helpers.titleCaseSlug(paragraph.triggerType)
                  : ""
              }
              launchLink={`/portal/narrative/${narrativeId}/edit?view=paragraphid-${paragraph.id}`}
              labelIcon={labelStatusIcon(
                paragraph.status_Id,
                Pilcrow,
                "paragraph"
              )}
              labelTitle={buildParagraphTitle(paragraph)}
              position={paragraph.position}
              saturation={paragraph.saturation}
              utilization={paragraph.utilization}
              setActiveViewEvent={handleTreeChange}
              setEditViewEvent={setEditAction}
              addLabel={`Add Sentence`}
              addView={`createsentence-${paragraph.id}`}
              postCopyParagraph={postCopyParagraph}
              postArchiveParagraph={postArchiveParagraph}
              postCopySentence={postCopySentence}
              postArchiveSentence={postArchiveSentence}
              postMoveSentence={postMoveSentence}
              doesParagraphIncludeSentences={
                !!paragraph?.sentences?.filter(
                  sntc => sntc.paragraph_Id === paragraph.id
                ).length
              }
            >
              {paragraph &&
                paragraph.sentences
                  .filter(sentence => !sentence.importedBySentence_Id)
                  .sort(function (a, b) {
                    if (a.position < b.position) {
                      //I am sorting first by Position, then by the triggerType
                      return -1
                    } else if (a.position > b.position) {
                      return 1
                    } else {
                      if (a.triggerWeight < b.triggerWeight) {
                        return -1
                      } else if (a.triggerWeight > b.triggerWeight) {
                        return 1
                      } else {
                        return (
                          a.triggerType &&
                          a.triggerType.localeCompare(b.triggerType)
                        )
                      }
                    }
                  })
                  .map(sentence => (
                    <StyledTreeItem
                      sourceId={sentence.id}
                      paragraphId={sentence.paragraph_Id}
                      narrativeId={sentence.narrative_Id}
                      selectedSourceId={selectedSourceId}
                      setSourceId={setSourceId}
                      setActionTaken={setActionTaken}
                      selectedSourceType={selectedSourceType}
                      setSourceType={setSourceType}
                      setLastActionType={setLastActionType}
                      refreshNarrative={refreshNarrative}
                      key={`sentenceid-${sentence.id}`}
                      isSentenceFromLibrary={sentence.isFromLibrary}
                      nodeId={`sentenceid-${sentence.id}`}
                      itemView={`sentenceid-${sentence.id}`}
                      launchLink={`/portal/narrative/${narrativeId}/edit?view=sentenceid-${sentence.id}`}
                      labelText={`(${sentence.id}) ${
                        sentence.name ||
                        (sentence.triggerType
                          ? Helpers.titleCaseSlug(sentence.triggerType)
                          : "n/a")
                      }`}
                      labelIcon={
                        sentence.isLibraryPlaceholder &&
                        sentence.status_Id !== 6
                          ? Attachment
                          : sentence.isFromLibrary && sentence.status_Id !== 6
                          ? LibraryBooksOutlined
                          : labelStatusIcon(sentence.status_Id, ShortText)
                      }
                      labelTitle={buildSentenceTitle(sentence)}
                      position={sentence.position}
                      saturation={sentence.saturation}
                      utilization={sentence.utilization}
                      triggerWeight={sentence.triggerWeight}
                      setActiveViewEvent={handleTreeChange}
                      setEditViewEvent={setEditAction}
                      postCopySentence={postCopySentence}
                      postArchiveSentence={postArchiveSentence}
                      postMoveSentence={postMoveSentence}
                    >
                      {sentence.isLibraryPlaceholder &&
                        paragraph.sentences
                          .filter(
                            libSentence =>
                              libSentence.importedBySentence_Id === sentence.id
                          )
                          .sort(function (a, b) {
                            if (a.position < b.position) {
                              //I am sorting first by Position, then by the triggerType
                              return -1
                            } else if (a.position > b.position) {
                              return 1
                            } else {
                              if (a.triggerWeight < b.triggerWeight) {
                                return -1
                              } else if (a.triggerWeight > b.triggerWeight) {
                                return 1
                              } else {
                                return (
                                  a.triggerType &&
                                  a.triggerType.localeCompare(b.triggerType)
                                )
                              }
                            }
                          })
                          .map(sentence => (
                            <StyledTreeItem
                              sourceId={sentence.id}
                              paragraphId={sentence.paragraph_Id}
                              narrativeId={sentence.narrative_Id}
                              selectedSourceId={selectedSourceId}
                              setSourceId={setSourceId}
                              setActionTaken={setActionTaken}
                              selectedSourceType={selectedSourceType}
                              setSourceType={setSourceType}
                              setLastActionType={setLastActionType}
                              refreshNarrative={refreshNarrative}
                              key={`sentenceid-${sentence.id}`}
                              isSentenceFromLibrary={sentence.isFromLibrary}
                              nodeId={`sentenceid-${sentence.id}`}
                              itemView={`sentenceid-${sentence.id}`}
                              launchLink={`/portal/narrative/${narrativeId}/edit?view=sentenceid-${sentence.id}`}
                              labelText={`(${sentence.id}) ${
                                sentence.name ||
                                (sentence.triggerType
                                  ? Helpers.titleCaseSlug(sentence.triggerType)
                                  : "")
                              }`}
                              labelIcon={labelStatusIcon(
                                sentence.status_Id,
                                ShortText
                              )}
                              labelTitle={buildSentenceTitle(sentence)}
                              position={sentence.position}
                              //no saturation.sentence
                              triggerWeight={sentence.triggerWeight}
                              setActiveViewEvent={handleTreeChange}
                              setEditViewEvent={setEditAction}
                            />
                          ))}
                    </StyledTreeItem>
                  ))}
            </StyledTreeItem>
          ))}
        </StyledTreeItem>
      )),
    [contentBlocks, selectedSourceId, selectedSourceType]
  )

  let orphanTreeItems = useMemo(
    () =>
      orphanBlocks &&
      Object.keys(orphanBlocks).map(key => (
        <StyledTreeItem
          key={`element-${key}`}
          nodeId={key}
          itemView={`element-${key}`}
          labelText={`<${key}/>`}
          labelIcon=""
          setActiveViewEvent={handleTreeChange}
          addLabel={`Add Block`}
          addView={`createparagraph-${key}`}
          setEditViewEvent={setEditAction}
        >
          {orphanBlocks[key].map(paragraph => (
            <StyledTreeItem
              sourceId={paragraph.id}
              paragraphId={paragraph.id}
              narrativeId={paragraph.narrative_Id}
              selectedSourceId={selectedSourceId}
              setSourceId={setSourceId}
              setActionTaken={setActionTaken}
              selectedSourceType={selectedSourceType}
              setSourceType={setSourceType}
              setLastActionType={setLastActionType}
              refreshNarrative={refreshNarrative}
              key={`paragraphid-${paragraph.id}`}
              itemView={`paragraphid-${paragraph.id}`}
              nodeId={`paragraphid-${paragraph.id}`}
              labelText={`(${paragraph.id}) ${paragraph.name || ""}`}
              labelInfo={
                paragraph.triggerType
                  ? Helpers.titleCaseSlug(paragraph.triggerType)
                  : ""
              }
              launchLink={`/portal/narrative/${narrativeId}/edit?view=paragraphid-${paragraph.id}`}
              labelIcon={labelStatusIcon(
                paragraph.status_Id,
                Pilcrow,
                "paragraph"
              )}
              labelTitle={buildParagraphTitle(paragraph)}
              position={paragraph.position}
              saturation={paragraph.saturation}
              utilization={paragraph.utilization}
              setActiveViewEvent={handleTreeChange}
              setEditViewEvent={setEditAction}
              addLabel={`Add Sentence`}
              addView={`createsentence-${paragraph.id}`}
              postCopyParagraph={postCopyParagraph}
              postArchiveParagraph={postArchiveParagraph}
              postCopySentence={postCopySentence}
              postArchiveSentence={postArchiveSentence}
              postMoveSentence={postMoveSentence}
              doesParagraphIncludeSentences={
                !!paragraph?.sentences?.filter(
                  sntc => sntc.paragraph_Id === paragraph.id
                ).length
              }
            >
              {paragraph &&
                paragraph.sentences
                  .filter(sentence => !sentence.importedBySentence_Id)
                  .sort(function (a, b) {
                    if (a.position < b.position) {
                      //I am sorting first by Position, then by the triggerType
                      return -1
                    } else if (a.position > b.position) {
                      return 1
                    } else {
                      if (a.triggerWeight < b.triggerWeight) {
                        return -1
                      } else if (a.triggerWeight > b.triggerWeight) {
                        return 1
                      } else {
                        return (
                          a.triggerType &&
                          a.triggerType.localeCompare(b.triggerType)
                        )
                      }
                    }
                  })
                  .map(sentence => (
                    <StyledTreeItem
                      sourceId={sentence.id}
                      paragraphId={sentence.paragraph_Id}
                      narrativeId={sentence.narrative_Id}
                      selectedSourceId={selectedSourceId}
                      setSourceId={setSourceId}
                      setActionTaken={setActionTaken}
                      selectedSourceType={selectedSourceType}
                      setSourceType={setSourceType}
                      setLastActionType={setLastActionType}
                      refreshNarrative={refreshNarrative}
                      key={`sentenceid-${sentence.id}`}
                      isSentenceFromLibrary={sentence.isFromLibrary}
                      nodeId={`sentenceid-${sentence.id}`}
                      itemView={`sentenceid-${sentence.id}`}
                      launchLink={`/portal/narrative/${narrativeId}/edit?view=sentenceid-${sentence.id}`}
                      labelText={`(${sentence.id}) ${
                        sentence.name ||
                        (sentence.triggerType
                          ? Helpers.titleCaseSlug(sentence.triggerType)
                          : "n/a")
                      }`}
                      labelIcon={
                        sentence.isLibraryPlaceholder &&
                        sentence.status_Id !== 6
                          ? Attachment
                          : sentence.isFromLibrary && sentence.status_Id !== 6
                          ? LibraryBooksOutlined
                          : labelStatusIcon(sentence.status_Id, ShortText)
                      }
                      labelTitle={buildSentenceTitle(sentence)}
                      position={sentence.position}
                      saturation={sentence.saturation}
                      utilization={sentence.utilization}
                      triggerWeight={sentence.triggerWeight}
                      setActiveViewEvent={handleTreeChange}
                      setEditViewEvent={setEditAction}
                      postCopySentence={postCopySentence}
                      postArchiveSentence={postArchiveSentence}
                      postMoveSentence={postMoveSentence}
                    >
                      {sentence.isLibraryPlaceholder &&
                        paragraph.sentences
                          .filter(
                            libSentence =>
                              libSentence.importedBySentence_Id === sentence.id
                          )
                          .sort(function (a, b) {
                            if (a.position < b.position) {
                              //I am sorting first by Position, then by the triggerType
                              return -1
                            } else if (a.position > b.position) {
                              return 1
                            } else {
                              if (a.triggerWeight < b.triggerWeight) {
                                return -1
                              } else if (a.triggerWeight > b.triggerWeight) {
                                return 1
                              } else {
                                return (
                                  a.triggerType &&
                                  a.triggerType.localeCompare(b.triggerType)
                                )
                              }
                            }
                          })
                          .map(sentence => (
                            <StyledTreeItem
                              sourceId={sentence.id}
                              paragraphId={sentence.paragraph_Id}
                              narrativeId={sentence.narrative_Id}
                              selectedSourceId={selectedSourceId}
                              setSourceId={setSourceId}
                              setActionTaken={setActionTaken}
                              selectedSourceType={selectedSourceType}
                              setSourceType={setSourceType}
                              setLastActionType={setLastActionType}
                              refreshNarrative={refreshNarrative}
                              key={`sentenceid-${sentence.id}`}
                              isSentenceFromLibrary={sentence.isFromLibrary}
                              nodeId={`sentenceid-${sentence.id}`}
                              itemView={`sentenceid-${sentence.id}`}
                              launchLink={`/portal/narrative/${narrativeId}/edit?view=sentenceid-${sentence.id}`}
                              labelText={`(${sentence.id}) ${
                                sentence.name ||
                                (sentence.triggerType
                                  ? Helpers.titleCaseSlug(sentence.triggerType)
                                  : "")
                              }`}
                              labelIcon={labelStatusIcon(
                                sentence.status_Id,
                                ShortText
                              )}
                              labelTitle={buildSentenceTitle(sentence)}
                              position={sentence.position}
                              triggerWeight={sentence.triggerWeight}
                              setActiveViewEvent={handleTreeChange}
                              setEditViewEvent={setEditAction}
                            />
                          ))}
                    </StyledTreeItem>
                  ))}
            </StyledTreeItem>
          ))}
        </StyledTreeItem>
      )),
    [orphanBlocks, selectedSourceId, selectedSourceType]
  )

  let outlineTreeItems = useMemo(
    () =>
      outlineContentBlocks &&
      Object.keys(outlineContentBlocks).map(key => {
        key = key || "empty"
        return (
          <StyledTreeItem
            key={`output_element-${key}`}
            nodeId={`output_element-${key}`}
            labelText={`<${key}/>`}
            labelIcon=""
            itemView={`output_element-${key}`}
            setActiveViewEvent={handleTreeChange}
          >
            {outlineContentBlocks[key].map(outlineBlock => (
              <StyledTreeItem
                key={`outlineblockid-${outlineBlock.id}`}
                itemView={`outlineblockid-${outlineBlock.id}`}
                nodeId={`outlineblockid-${outlineBlock.id}`}
                labelText={`(${outlineBlock.id}) ${
                  outlineBlock.contentSection || ""
                }`}
                labelInfo={
                  outlineBlock.triggerType
                    ? Helpers.titleCaseSlug(outlineBlock.triggerType)
                    : ""
                }
                launchLink={`/portal/narrative/${narrativeId}/edit?view=outlineblockid-${outlineBlock.id}`}
                labelIcon={FeaturedPlayList}
                labelTitle={`Outline Block`}
                setActiveViewEvent={handleTreeChange}
                addLabel={`Add Block`}
                addView={`createparagraph-${key}-${outlineBlock.id}`}
                setEditViewEvent={setEditAction}
                contentBlock={outlineBlock.contentBlock}
                renderType={outlineBlock.renderType}
              >
                {processedParagraphs &&
                  processedParagraphs
                    .filter(p => p.narrativeOutlineBlockId === outlineBlock.id)
                    .map(paragraph => (
                      <StyledTreeItem
                        sourceId={paragraph.id}
                        paragraphId={paragraph.id}
                        narrativeId={paragraph.narrative_Id}
                        saturation={paragraph.saturation}
                        utilization={paragraph.utilization}
                        selectedSourceId={selectedSourceId}
                        setSourceId={setSourceId}
                        setActionTaken={setActionTaken}
                        selectedSourceType={selectedSourceType}
                        setSourceType={setSourceType}
                        setLastActionType={setLastActionType}
                        refreshNarrative={refreshNarrative}
                        key={`outlineparagraphid-${paragraph.id}`}
                        itemView={`outlineparagraphid-${paragraph.id}`}
                        nodeId={`outlineparagraphid-${paragraph.id}`}
                        labelText={`(${paragraph.id}) ${paragraph.name || ""}`}
                        labelInfo={
                          paragraph.triggerType
                            ? Helpers.titleCaseSlug(paragraph.triggerType)
                            : ""
                        }
                        launchLink={`/portal/narrative/${narrativeId}/edit?view=outlineparagraphid-${paragraph.id}`}
                        labelIcon={labelStatusIcon(
                          paragraph.status_Id,
                          Pilcrow,
                          "paragraph"
                        )}
                        labelTitle={buildParagraphTitle(paragraph)}
                        setActiveViewEvent={handleTreeChange}
                        setEditViewEvent={setEditAction}
                        addLabel={`Add Sentence`}
                        addView={`createsentence-${paragraph.id}`}
                        postCopyParagraph={postCopyParagraph}
                        postArchiveParagraph={postArchiveParagraph}
                        postCopySentence={postCopySentence}
                        postArchiveSentence={postArchiveSentence}
                        postMoveSentence={postMoveSentence}
                        color={
                          paragraph.status_Id === 6 ? "#A31545" : "inherit"
                        }
                        doesParagraphIncludeSentences={
                          !!paragraph?.sentences?.filter(
                            sntc => sntc.paragraph_Id === paragraph.id
                          ).length
                        }
                      >
                        {paragraph &&
                          paragraph.sentences
                            .filter(sentence => !sentence.importedBySentence_Id)
                            .map(sentence => (
                              <StyledTreeItem
                                sourceId={sentence.id}
                                paragraphId={sentence.paragraph_Id}
                                narrativeId={sentence.narrative_Id}
                                selectedSourceId={selectedSourceId}
                                setSourceId={setSourceId}
                                setActionTaken={setActionTaken}
                                selectedSourceType={selectedSourceType}
                                setSourceType={setSourceType}
                                setLastActionType={setLastActionType}
                                refreshNarrative={refreshNarrative}
                                key={`outlinesentenceid-${sentence.id}`}
                                isSentenceFromLibrary={sentence.isFromLibrary}
                                nodeId={`outlinesentenceid-${sentence.id}`}
                                itemView={`outlinesentenceid-${sentence.id}`}
                                launchLink={`/portal/narrative/${narrativeId}/edit?view=outlinesentenceid-${sentence.id}`}
                                labelText={`(${sentence.id}) ${
                                  sentence.name ||
                                  (sentence.triggerType
                                    ? Helpers.titleCaseSlug(
                                        sentence.triggerType
                                      )
                                    : "n/a")
                                }`}
                                labelIcon={
                                  sentence.isLibraryPlaceholder &&
                                  sentence.status_Id !== 6
                                    ? Attachment
                                    : sentence.isFromLibrary &&
                                      sentence.status_Id !== 6
                                    ? LibraryBooksOutlined
                                    : labelStatusIcon(
                                        sentence.status_Id,
                                        ShortText
                                      )
                                }
                                labelTitle={buildSentenceTitle(sentence)}
                                position={sentence.position}
                                saturation={sentence.saturation}
                                utilization={sentence.utilization}
                                triggerWeight={sentence.triggerWeight}
                                setActiveViewEvent={handleTreeChange}
                                setEditViewEvent={setEditAction}
                                postCopySentence={postCopySentence}
                                postArchiveSentence={postArchiveSentence}
                                postMoveSentence={postMoveSentence}
                                color={
                                  sentence.status_Id === 6
                                    ? "#A31545"
                                    : "inherit"
                                }
                              >
                                {sentence.isLibraryPlaceholder &&
                                  paragraph.sentences
                                    .filter(
                                      libSentence =>
                                        libSentence.importedBySentence_Id ===
                                        sentence.id
                                    )
                                    .map(sentence => (
                                      <StyledTreeItem
                                        sourceId={sentence.id}
                                        paragraphId={sentence.paragraph_Id}
                                        narrativeId={sentence.narrative_Id}
                                        selectedSourceId={selectedSourceId}
                                        setSourceId={setSourceId}
                                        setActionTaken={setActionTaken}
                                        selectedSourceType={selectedSourceType}
                                        setSourceType={setSourceType}
                                        setLastActionType={setLastActionType}
                                        refreshNarrative={refreshNarrative}
                                        key={`placeholderoutlinesentenceid-${sentence.id}`}
                                        isSentenceFromLibrary={
                                          sentence.isFromLibrary
                                        }
                                        nodeId={`placeholderoutlinesentenceid-${sentence.id}`}
                                        itemView={`placeholderoutlinesentenceid-${sentence.id}`}
                                        launchLink={`/portal/narrative/${narrativeId}/edit?view=placeholderoutlinesentenceid-${sentence.id}`}
                                        placeholderId={`${sentence.importedBySentence_Id}`}
                                        labelText={`(${sentence.id}) ${
                                          sentence.name ||
                                          (sentence.triggerType
                                            ? Helpers.titleCaseSlug(
                                                sentence.triggerType
                                              )
                                            : "")
                                        }`}
                                        labelIcon={labelStatusIcon(
                                          sentence.status_Id,
                                          ShortText
                                        )}
                                        labelTitle={buildSentenceTitle(
                                          sentence
                                        )}
                                        position={sentence.position}
                                        saturation={sentence.saturation}
                                        utilization={sentence.utilization}
                                        triggerWeight={sentence.triggerWeight}
                                        setActiveViewEvent={handleTreeChange}
                                        setEditViewEvent={setEditAction}
                                      />
                                    ))}
                              </StyledTreeItem>
                            ))}
                      </StyledTreeItem>
                    ))}
              </StyledTreeItem>
            ))}
          </StyledTreeItem>
        )
      }),
    [
      outlineContentBlocks,
      processedParagraphs,
      selectedSourceId,
      selectedSourceType
    ]
  )
  let dependencyTreeItems = useMemo(
    () =>
      dependencies &&
      dependencies.map(trigger => (
        <StyledTreeItem
          sourceId={trigger.id}
          narrativeId={trigger.narrative_Id}
          selectedSourceId={selectedSourceId}
          setSourceId={setSourceId}
          setActionTaken={setActionTaken}
          selectedSourceType={selectedSourceType}
          setSourceType={setSourceType}
          setLastActionType={setLastActionType}
          refreshNarrative={refreshNarrative}
          key={`dep-triggerid-${trigger.id}`}
          itemView={`triggerid-${trigger.id}`}
          nodeId={`triggerid-${trigger.id}`}
          launchLink={`/portal/narrative/${trigger.narrative_Id}?view=triggerid-${trigger.id}`}
          labelText={`(${trigger.id}) ${trigger.name}`}
          labelInfo={trigger.type}
          labelIcon={dependencyTriggerIcon(trigger, narrativeId)}
          labelTitle={`${trigger.name || ""} ${
            trigger.narrative_Id !== narrativeId
              ? `Imported From Library ${trigger.narrative_Id}`
              : ""
          }`}
          position={trigger.rank}
          setActiveViewEvent={handleTreeChange}
          setEditViewEvent={setEditAction}
          postCopyTrigger={postCopyTrigger}
        />
      )),
    [dependencies, selectedSourceId, selectedSourceType]
  )
  let newsTriggerTreeItems = useMemo(
    () =>
      newsTriggers &&
      newsTriggers
        .slice(0, 20)
        .map(trigger => (
          <StyledTreeItem
            sourceId={trigger.id}
            narrativeId={trigger.narrative_Id}
            selectedSourceId={selectedSourceId}
            setSourceId={setSourceId}
            setActionTaken={setActionTaken}
            selectedSourceType={selectedSourceType}
            setSourceType={setSourceType}
            setLastActionType={setLastActionType}
            refreshNarrative={refreshNarrative}
            key={`newstriggerid-${trigger.id}`}
            itemView={`newstriggerid-${trigger.id}`}
            nodeId={`newstriggerid-${trigger.id}`}
            launchLink={`/portal/narrative/${trigger.narrative_Id}/edit?view=newstriggerid-${trigger.id}`}
            labelText={`(${trigger.id}) ${trigger.name}`}
            labelInfo={trigger.type}
            labelIcon={
              trigger.narrative_Id !== narrativeId ? AddAlertTwoTone : AddAlert
            }
            labelTitle={`${trigger.name || ""} ${
              trigger.narrative_Id !== narrativeId
                ? `Imported From Library ${trigger.narrative_Id}`
                : ""
            }`}
            position={trigger.rank}
            setActiveViewEvent={handleTreeChange}
            setEditViewEvent={setEditAction}
            postCopyTrigger={postCopyTrigger}
          />
        )),
    [newsTriggers, selectedSourceId, selectedSourceType]
  )
  let triggerTreeItems = useMemo(
    () =>
      insightTriggers &&
      insightTriggers
        .slice(0, 20)
        .map(trigger => (
          <StyledTreeItem
            sourceId={trigger.id}
            narrativeId={trigger.narrative_Id}
            selectedSourceId={selectedSourceId}
            setSourceId={setSourceId}
            setActionTaken={setActionTaken}
            selectedSourceType={selectedSourceType}
            setSourceType={setSourceType}
            setLastActionType={setLastActionType}
            refreshNarrative={refreshNarrative}
            key={`triggerid-${trigger.id}`}
            itemView={`triggerid-${trigger.id}`}
            nodeId={`triggerid-${trigger.id}`}
            launchLink={`/portal/narrative/${trigger.narrative_Id}/edit?view=triggerid-${trigger.id}`}
            labelText={`(${trigger.id}) ${trigger.name}`}
            labelInfo={trigger.type}
            labelIcon={
              trigger.narrative_Id !== narrativeId ? SettingsEthernet : Code
            }
            labelTitle={`${trigger.name || ""} ${
              trigger.narrative_Id !== narrativeId
                ? `Imported From Library ${trigger.narrative_Id}`
                : ""
            }`}
            position={trigger.rank}
            setActiveViewEvent={handleTreeChange}
            setEditViewEvent={setEditAction}
            postCopyTrigger={postCopyTrigger}
          />
        )),
    [insightTriggers, selectedSourceId, selectedSourceType]
  )

  //We could do maybe do away with this block.
  if (activeView.match(/^outline/)) {
    const blockKeys = outlineContentBlocks && Object.keys(outlineContentBlocks)
    if (blockKeys && blockKeys.length > 0) {
      defaultExpanded = [
        ...defaultExpanded,
        ...Object.keys(outlineContentBlocks)
      ]
    }
  } else {
    const blockKeys = contentBlocks && Object.keys(contentBlocks)
    const outlineContentBlockKeys =
      outlineContentBlocks &&
      Object.keys(outlineContentBlocks).map(key => `output_element-${key}`)

    //The content block in now 5, used to be 4 (The recently added Outline block is 4 now)
    if (blockKeys && blockKeys.length > 0) {
      defaultExpanded = [
        ...defaultExpanded,
        ...outlineContentBlockKeys,
        ...Object.keys(contentBlocks)
      ]
    }
  }
  return (
    <div className={classes.root}>
      <div className={classes.drawerPrimaryCard}>
        {isLoaded && (
          <div className={classes.drawerPrimaryContent}>
            <TreeView
              defaultExpanded={defaultExpanded}
              defaultCollapseIcon={<ArrowDropDown />}
              defaultExpandIcon={<ArrowRight />}
              defaultEndIcon={<div style={{ width: 12 }} />}
              selected={activeView.split(",")}
              multiSelect={true}
            >
              <StyledTreeItem
                nodeId={"0"}
                itemView={`dependencies`}
                labelText="Dependency Triggers"
                labelIcon={SyncAlt}
                setActiveViewEvent={handleTreeChange}
                setEditViewEvent={setEditAction}
                addLabel={`Add Dependency`}
                addView={`createdependency`}
              >
                {dependencyTreeItems}
              </StyledTreeItem>
              <StyledTreeItem
                nodeId={"1"}
                itemView={`newstriggers`}
                labelText="News Triggers"
                labelIcon={FlashOn}
                setActiveViewEvent={handleTreeChange}
                setEditViewEvent={setEditAction}
                addLabel={`Add News Trigger`}
                addView={`createnewstrigger`}
              >
                {newsTriggerTreeItems}
              </StyledTreeItem>
              <StyledTreeItem
                nodeId={"2"}
                itemView={`insighttriggers`}
                labelText="Insight Triggers"
                labelIcon={ShowChart}
                setActiveViewEvent={handleTreeChange}
                setEditViewEvent={setEditAction}
                addLabel={`Add Insight Trigger`}
                addView={`createinsight`}
              >
                {triggerTreeItems}
              </StyledTreeItem>
              {outlineTreeItems && outlineTreeItems.length > 0 && (
                <StyledTreeItem
                  nodeId={"4"}
                  itemView="content"
                  labelText="Outline"
                  labelIcon={LibraryBooks}
                  setActiveViewEvent={handleTreeChange}
                >
                  {outlineTreeItems}
                </StyledTreeItem>
              )}
              {!narrative.buildFromOutline && (
                <StyledTreeItem
                  nodeId={"5"}
                  itemView={`content`}
                  labelText={
                    narrative.buildFromOutline ? "Unassigned Blocks" : "Blocks"
                  }
                  labelIcon={LibraryBooks}
                  setActiveViewEvent={handleTreeChange}
                  setEditViewEvent={setEditAction}
                  addLabel={`Add Block`}
                  addView={`createparagraph`}
                >
                  {treeitems}
                </StyledTreeItem>
              )}
              {narrative.buildFromOutline && orphanTreeItems.length > 0 && (
                <StyledTreeItem
                  nodeId={"5"}
                  itemView={`content`}
                  labelText={
                    narrative.buildFromOutline ? "Unassigned Blocks" : "Blocks"
                  }
                  labelIcon={LibraryBooks}
                  setActiveViewEvent={handleTreeChange}
                  setEditViewEvent={setEditAction}
                  addLabel={`Add Block`}
                  addView={`createparagraph`}
                >
                  {orphanTreeItems}
                </StyledTreeItem>
              )}
            </TreeView>
          </div>
        )}
      </div>
    </div>
  )

  function buildSentenceTitle(sentence) {
    let labelString = sentence.triggerType ? `${sentence.triggerType} ` : ""
    // Is it a library sentence with no errors (e.g. from an associated library)
    if (
      (sentence.isLibraryPlaceholder || sentence.isLibrary) &&
      sentence.status_Id !== 6
    ) {
      //labelString += `Imported From Library ${sentence.narrative_Id}`
      const lpIds = sentence.libraryParagraph_Id.toString().split(",")
      const fromLibrary =
        narrative.libraryParagraphs.filter(x => {
          const myId = x.id
          return lpIds.includes(myId.toString())
        }) || []
      const libraryNarrativeId =
        fromLibrary[0] && fromLibrary[0].narrative_Id
          ? fromLibrary[0].narrative_Id
          : sentence.narrative_Id
      labelString += `Imported From Library ${libraryNarrativeId}`
    }
    // Is it a Library sentence with an error (e.g. from an unassociated library)
    if (
      (sentence.isLibraryPlaceholder || sentence.isLibrary) &&
      sentence.status_Id === 6
    ) {
      labelString += `Inherited From an Unassociated Library`
    }
    return labelString
  }

  function buildParagraphTitle(paragraph) {
    let labelString = paragraph.triggerType ? `${paragraph.triggerType} ` : ""
    // Is it a library Placeholder with no errors (e.g. from an associated library)
    if (paragraph.libraryParagraph_Id > 0 && paragraph.status_Id !== 6) {
      const lpIds = paragraph.libraryParagraph_Id.toString().split(",")
      const fromLibrary =
        narrative.libraryParagraphs.filter(x => {
          const myId = x.id
          return lpIds.includes(myId.toString())
        }) || []
      const libraryNarrativeId =
        fromLibrary[0] && fromLibrary[0].narrative_Id
          ? fromLibrary[0].narrative_Id
          : paragraph.narrative_Id
      labelString += `Imported From Library ${libraryNarrativeId}`
    }
    // Is it a library Placeholder with errors (e.g. not from an associated library)
    if (paragraph.libraryParagraph_Id > 0 && paragraph.status_Id === 6) {
      labelString += `Inherited From an Unassociated Library`
    }
    return labelString
  }
}

export const NarrativeExplorer = React.memo(NarrativeExplorerComponent)

NarrativeExplorer.propTypes = {
  narrative: PropTypes.object,
  loading: PropTypes.bool,
  setActiveView: PropTypes.func,
  setEditEvent: PropTypes.func,
  exportSentence: PropTypes.func,
  exportParagraph: PropTypes.func,
  exportTrigger: PropTypes.func,
  activeView: PropTypes.string
}
