import Pages from "layouts/Pages"
import RTL from "layouts/RTL"
import Dashboard from "layouts/Dashboard"

export default [
  { path: "/rtl", name: "RTL", component: RTL },
  { path: "/pages", name: "Pages", component: Pages },
  { path: "/portal", name: "Home", component: Dashboard },
  { path: "/", name: "Default", component: Pages }
]
