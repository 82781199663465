// Functions needed in CTADisclaimerLinkForm
const useDisclaimerFormHelper = ({
  regionsList,
  setOrigFormValues,
  urlValidation,
  companiesInfo,
  organizations,
  classes,
  dayjs,
  timeZoneIANA
}) => {
  const constructOrgPropList = itm => {
    let correctOrg =
      organizations?.filter(org => itm.organizationIds?.includes(org.id)) || []
    const myProperties = []
    correctOrg.forEach(itm => {
      itm.properties?.forEach(x => myProperties.push(x))
    })
    return myProperties
  }

  const selectedPropList = itm => {
    const myProperties = []
    itm.organizations?.forEach(org => {
      org.properties?.forEach(x => {
        myProperties.push(x)
      })
    })
    return myProperties
  }

  const selectedOrgList = itm => {
    let correctOrg =
      organizations?.filter(org => itm.organizationIds?.includes(org.id)) || []

    const myOrgs = []
    correctOrg.forEach(org => {
      const myProperties = []
      org.properties?.forEach(x => {
        if (itm.propertyIds.includes(x.id)) {
          myProperties.push(x)
        }
      })
      myOrgs.push({ id: org.id, name: org.name, properties: myProperties })
    })
    return myOrgs
  }

  const endDateValidation = (endDate, itm) => {
    if (endDate !== null && itm.startDate === null) {
      return "Start Date required with End Date"
    }
    if (!endDate || !itm.startDate) {
      return null
    }
    const dateStart = dayjs(itm.startDate)
    const dateEnd = dayjs(endDate)
    const dateDiff = dateEnd.diff(dateStart)
    return dateDiff > 0 ? null : `End Date must be after Start Date`
  }

  const validationObject = {
    hasOwnProperty: [
      {
        variable: "linkUrl",
        name: "Link URL",
        msg: "Please add a valid Disclaimer URL for",
        func: urlValidation
      },
      { variable: "organizationIds", name: "Organization", array: true },
      { variable: "linkName", name: "Link Name" },
      {
        variable: "endDate",
        name: "endDate",
        validation: endDateValidation
      }
    ]
  }

  const failedValidationList = list => (
    <div>
      <div className={classes.infoValidationHeader}>
        You have the following validation errors:
      </div>
      <div className={classes.addScrollIfNeeded}>
        <ol>
          {list?.map((itm, i) => (
            <li key={i}>{itm}</li>
          ))}
        </ol>
      </div>
    </div>
  )

  const doOrgsWithPropertiesHavePropertiesSelected = itm => {
    const orgsWithProperties = []
    let missingProperties = []
    organizations.forEach(x => {
      if (x.properties.length) {
        orgsWithProperties.push(x.id)
      }
    })
    const orgs = selectedOrgList(itm)
    orgs.forEach(x => {
      if (orgsWithProperties.includes(x.id) && x.properties.length === 0) {
        missingProperties.push(
          `Organization ${x.name}, id=${x.id}, has no properties selected`
        )
      }
    })
    return missingProperties
  }

  const checkForChangedNavElements = (newFormValues, oldFormValues) => {
    let newOrgList =
      newFormValues.organizationIds.length !==
      oldFormValues.organizations.length
    let newCompaniesList =
      newFormValues.companyIds.length !== oldFormValues.companies.length
    let newLinkName = newFormValues.linkName !== oldFormValues.linkName
    if (!newCompaniesList) {
      oldFormValues.companies.forEach(cmp => {
        if (!newFormValues.companyIds.includes(cmp.id)) {
          newCompaniesList = true
        }
      })
    }
    if (!newOrgList) {
      oldFormValues.organizations.forEach(org => {
        if (!newFormValues.organizationIds.includes(org.id)) {
          newOrgList = true
        }
      })
    }
    return newLinkName || newCompaniesList || newOrgList
  }

  const formValueValidation = itm => {
    let result = []
    validationObject.hasOwnProperty?.forEach(field => {
      if (itm.hasOwnProperty(field.variable)) {
        if (typeof field.validation === "function") {
          const errorResult = field.validation(itm[field.variable], itm)
          if (errorResult) {
            result.push(errorResult)
          }
        } else if (field.array && !itm[field.variable].length) {
          result.push(`Please add a value for "${field.name}"`)
        } else if (field.func && !field.func(itm[field.variable])) {
          result.push(`${field.msg} "${field.name}"`)
        } else if (!itm[field.variable]) {
          result.push(`Please add a value for "${field.name}"`)
        }
      } else {
        result.push(`"${field.name}" doesn't exist in the form`)
      }
    })
    const temp = doOrgsWithPropertiesHavePropertiesSelected(itm)
    if (temp) {
      result = result.concat(temp)
    }
    if (result.length) {
      return failedValidationList(result)
    } else {
      return false
    }
  }

  const linkSaveObject = (itm, intentType) => {
    const saveContent = {
      intentId: 2,
      statusId: itm.status,
      linkName: itm.linkName,
      isArchived: itm.isArchived,
      url: itm.linkUrl,
      companies: companiesInfo
        .toJS()
        .filter(x => itm.companyIds.includes(x.id)),
      organizations: selectedOrgList(itm),
      startDateTime: itm.startDateTimeInTimeZone
        ? itm.startDateTimeInTimeZone
        : null,
      endDateTime: itm.endDateTimeInTimeZone ? itm.endDateTimeInTimeZone : null,
      timeZoneIANA: timeZoneIANA,
      //linkDetailText: itm.promoText,
      //linkTypeId: itm.linkTypeId,
      assetLink: itm.assetLink,
      ownerAccountId: itm.ownerAccountId
    }
    if (itm.id) {
      saveContent.id = itm.id
    }
    return saveContent
  }

  const createLinkObject = (itm, create, update) => {
    const startDateTime = itm.startDateTime
      ? dayjs(itm.startDateTime).local()
      : null
    //setStartDate(startDateTime)
    const endDateTime = itm.endDateTime ? dayjs(itm.endDateTime).local() : null
    //setEndDate(endDateTime)

    const companyIds = []
    itm.companies?.forEach(itm => companyIds.push(itm.id))
    const organizationIds = []
    itm.organizations?.forEach(itm => organizationIds.push(itm.id))

    const propertyIds = []
    const orgsProperties = selectedPropList(itm)
    orgsProperties.forEach(itm => propertyIds.push(itm.id))

    const formValues = update
      ? {
          ...itm,
          status: itm.statusId
        }
      : !create
      ? {
          ctaId: itm.id,
          id: itm.id,
          linkUrl: itm.url,
          linkName: itm.linkName,
          organizationName: itm.organizationName,
          organizations: itm.organizations,
          organizationIds: organizationIds,
          propertyIds: propertyIds,
          properties: orgsProperties,
          leagueId: itm.leagueId,
          leagueName: itm.leagueName,
          formFactor: itm.tagId,
          shortNameOffer: itm.shortNameOffer,
          companies: itm.companies,
          companyIds: companyIds,
          callToActionName: itm.name || "",
          callToActionTypeId: itm.callToActionTypeId,
          affiliateLink: itm.affiliateLink || "",
          assetLink: itm.assetLink || "",
          promoText: itm.linkDetailText || "",
          //linkTypeId: itm.linkTypeId || 1,
          callToActionText: itm.callToActionText || "",
          ownerAccountId: itm.ownerAccountId,
          status: itm.statusId,
          startDate: startDateTime,
          endDate: endDateTime,
          timeZoneIANA: itm.timeZoneIANA,
          isArchived: itm.isArchived || false
        }
      : {
          ctaId: null,
          id: null,
          linkUrl: "",
          linkName: "",
          propertyIds: [],
          properties: [],
          organizations: [],
          organizationIds: [],
          leagueId: itm.leagueId,
          leagueName: itm.leagueName || "",
          formFactor: "",
          shortNameOffer: "",
          region: [],
          regionsAllParams: [],
          companies: [],
          companyIds: [],
          assetLink: "",
          promoText: "",
          //linkTypeId: 1,
          callToActionText: "",
          ownerAccountId: null,
          status: 3,
          startDate: dayjs().local(),
          endDate: null,
          isArchived: false
        }

    setOrigFormValues(formValues)
    return formValues
  }

  return {
    createLinkObject,
    constructOrgPropList,
    formValueValidation,
    checkForChangedNavElements,
    //urlValidationForDisclaimer,
    linkSaveObject
  }
}
export default useDisclaimerFormHelper
