// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react"
import Checkbox from "@mui/material/Checkbox"
import Check from "@mui/icons-material/Check"
import PropTypes from "prop-types"
import FormControlLabel from "@mui/material/FormControlLabel"
import withStyles from "@mui/styles/withStyles"
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle"
import { HexColorPicker } from "react-colorful"
import TextField from "@mui/material/TextField"

// React Functional Component Declaration
const GraphicsToolColorPicker = ({
  colorKey,
  hexColor,
  isSelected,
  classes,
  title,
  onColorPickerChange,
  onIsToUseColorChange
}) => {
  // Variable/State Declaration
  const [isToDisplayColorPicker, setIsToDisplayColorPicker] = useState(false)

  const handleColorChange = event => {
    let value = event.target.value
    onColorPickerChange(colorKey, value)
  }

  const handleColorPickerChange = value => {
    let hexColor = value.replace("#", "").substring(0, 6)
    onColorPickerChange(colorKey, hexColor)
  }

  const handleIsToUseColorChange = event => {
    onIsToUseColorChange(colorKey)
  }

  // Component Render
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={isSelected}
            onChange={handleIsToUseColorChange}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }}
          />
        }
        classes={{ label: classes.label }}
        label={
          <TextField
            label={
              <>
                <span style={{ display: "flex" }}>
                  {title}
                  {hexColor && (
                    <>
                      &nbsp;
                      <div
                        style={{
                          backgroundColor: `#${hexColor}`,
                          height: 25,
                          width: 25,
                          border: `1px solid black`
                        }}
                      />
                    </>
                  )}
                </span>
              </>
            }
            fullWidth={true}
            onChange={handleColorChange}
            value={hexColor}
            onFocus={() => setIsToDisplayColorPicker(true)}
            onBlur={() => setIsToDisplayColorPicker(false)}
            labelProps={{ shrink: hexColor }}
          />
        }
      />
      {isToDisplayColorPicker && (
        <HexColorPicker
          id="colorPicker1"
          color={`#${hexColor}`}
          onChange={handleColorPickerChange}
          onFocus={() => setIsToDisplayColorPicker(true)}
          onBlur={() => setIsToDisplayColorPicker(false)}
        />
      )}
    </div>
  )
}

// PropType Declaration
GraphicsToolColorPicker.propTypes = {
  title: PropTypes.string,
  hexColor: PropTypes.string,
  isSelected: PropTypes.bool,
  colorKey: PropTypes.number,
  onColorPickerChange: PropTypes.func,
  onIsToUseColorChange: PropTypes.func
}

// Default Export
export default withStyles(regularFormsStyle)(GraphicsToolColorPicker)
