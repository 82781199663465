import React from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Card,
  CardContent,
  CardActions //,
  //Typography
} from "@mui/material"
import Button from "components/CustomButtons/Button"
import { Close } from "@mui/icons-material"

const RealTimeFormTemplate = ({
  open,
  onClose,
  onSave,
  title,
  children,
  showPreview,
  onPreview,
  mode,
  showDialog,
  onReset,
  readOnly
}) => {
  if (showDialog) {
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle>{title}</DialogTitle>
        <IconButton
          onClick={onClose}
          style={{ position: "absolute", right: "30px" }}
        >
          <Close />
        </IconButton>
        <DialogContent>{children}</DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "flex-end" }}>
          {showPreview && (
            <Button onClick={onPreview} color="secondary">
              Preview
            </Button>
          )}
          <Button onClick={onReset} color="secondary">
            Cancel
          </Button>
          <Button onClick={onSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  } else {
    return (
      <Card elevation={0}>
        <CardContent>
          <DialogTitle style={{ display: "inline-block" }}>{title}</DialogTitle>
          <IconButton
            onClick={onClose}
            style={{ position: "absolute", right: "30px" }}
          >
            <Close />
          </IconButton>
          {children}
        </CardContent>
        {!readOnly && (
          <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={onReset} color="secondary">
              Cancel
            </Button>
            <Button onClick={onSave} color="primary">
              Save
            </Button>
          </CardActions>
        )}
      </Card>
    )
  }
}

export default RealTimeFormTemplate
