// eslint-disable-next-line no-unused-vars
import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import withStyles from "@mui/styles/withStyles"
import Button from "components/CustomButtons/Button.jsx"
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog"
import {
  Box,
  LinearProgress,
  Grid,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  ListItem,
  List,
  ListItemAvatar,
  Toolbar,
  Avatar
} from "@mui/material"
import { Clear, Close } from "@mui/icons-material"

import CodeEditor from "components/CodeEditor"

import PropTypes from "prop-types"
import queryString from "query-string"

import WordCounter from "components/WordCounter/WordCounter.jsx"

import { NarrativeContext } from "contexts/narrative-context"
import { Cookies } from "tools/storage"

import NarrativePreviewDAO from "daos/narrativePreviewDAO"
import dsChatDAO from "daos/dsChatDAO"

const styles = theme => ({
  sentencePreview: {
    fontWeight: "normal",
    "& .xml": {
      fontWeight: "bold",
      fontSize: "13.5px",
      fontStyle: "normal"
    },
    "& pre": {
      padding: "5px 15px!important"
    }
  },
  sentenceHeader: {
    margin: "0 15px",
    padding: "10px 0!important",
    display: "flex"
  },
  codePreview: {
    margin: "0 10px",
    padding: "20px!important",
    background: "#000",
    color: "#fff",
    cursor: "pointer",
    fontFamily: "monospace",
    fontSize: "12px",
    "& .highlighted": {
      backgroundColor: "#ffeaa7",
      color: "#e17055",
      boxShadow: "-3px 0 0 #ffeaa7, 3px 0 0 #ffeaa7" // Creates an illusion of a wider span, while not affecting the actual width.
    }
  },
  codeHolder: {
    margin: "0",
    padding: "0 10px",
    position: "relative"
  },
  codeEditorArea: {
    display: "flex",
    minHeight: "250px"
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    padding: "0px",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  iconButton: {
    position: "absolute",
    right: "0",
    top: "-50px"
  },
  list: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    justifyContent: "space-evenly"
  },
  actionButtons: {
    padding: "12px 3px",
    margin: "5px 3px"
  },
  itemButtons: {
    padding: "12px",
    margin: "5px 3px"
  },
  myListItems: {
    fontWeight: "bold",
    marginTop: "5px"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "-4px -4px"
  },
  centeredCell: {
    display: "flex",
    justifyContent: "center"
  }
})
const entries = ["A", "B", "C"]
let cookies = new Cookies()

function HTMLPreviewResponseBlock(props) {
  return (
    <Box>
      <Grid container spacing={3} item xs={12}>
        <Grid item>
          <WordCounter title="Content" inputString={props.preview} />
          <div
            style={{
              marginTop: "10px",
              fontSize: "14px"
            }}
            dangerouslySetInnerHTML={{
              __html: props.preview?.replace(/apxh:/g, "")
            }}
            ref={props.contentRef}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

class ScratchPad extends Component {
  static contextType = NarrativeContext
  constructor(props) {
    super(props)
    let template =
      props.template ||
      (!!cookies.get("aScratchPad") &&
        !!cookies.get("aScratchPad").default &&
        cookies.get("aScratchPad").default) ||
      `<h3>Scratchpad & Model Explorer</h3>
<h4>Narrative Meta-Data</h4>
<pre>{{ PrettifyObject narrative }}</pre>
<h4>Narrative Render Instructions</h4>
<pre>{{ PrettifyObject instructions }}</pre>
<dl>
  <dt>Top Level Tokens</dt>
  <dd>
    <ul>
      {{for key in GetMembers(this) ~}}
        <li>{{key}}</li>
    {{end}}
    </ul>
  </dd>
  {{if model != empty }}
    <dt>Model Tokens</dt>
    <dd>
      <ul>
      {{for key in model | object.keys~}}
        <li>{{key}}</li>
      {{end}}
      </ul>
    </dd>
  {{end}}
</dl>
{{if model != empty && model._ItemArray != empty }}
  This is item <strong>{{model._ItemIndex}}</strong> of <strong>{{model._ItemArray | array.size}}</strong> items.
{{end}}`
    let spStorage =
      (!!cookies.get("aScratchPad") &&
        cookies.get("aScratchPad").savedScratchPads) ||
      []
    this.state = {
      currentNarrativeId: +props.match.params.id,
      preview: "",
      loadingPreview: false,
      loadingChatResponsePreview: false,
      showLineNumbers: true,
      saveScratchPadDialog: false,
      deleteScratchPadDialog: false,
      savedScratchPads: spStorage,
      template,
      readOnly: !!props.template,
      chatResponse: false
    }
    this.buildPreview = this.buildPreview.bind(this)
    this.saveScratchPad = this.saveScratchPad.bind(this)
    this.setConfirmOpen = this.setConfirmOpen.bind(this)
    this.saveScratchPadContents = this.saveScratchPadContents.bind(this)
    this.deleteScratchPadEntry = this.deleteScratchPadEntry.bind(this)
    this.handleScratchPadDialogClose =
      this.handleScratchPadDialogClose.bind(this)
    this.handleDeleteItemClick = this.handleDeleteItemClick.bind(this)
  }

  handleDeleteItemClick(sp, i) {
    let newArr = this.state.savedScratchPads
    let temp = cookies.get("aScratchPad") || {}
    newArr = newArr.filter((item, index) => index !== i)
    this.setState({
      deleteScratchPadDialog: false,
      savedScratchPads: newArr
    })
    temp.savedScratchPads = newArr
    cookies.set("aScratchPad", temp)
  }

  handleScratchPadDialogClose() {
    this.setState({ deleteScratchPadDialog: false })
  }

  deleteScratchPadEntry() {
    this.setState({ deleteScratchPadDialog: true })
  }

  getScratchPad(num) {
    this.setState({ template: this.state.savedScratchPads[num] })
  }

  saveScratchPad() {
    this.setState({ saveScratchPadDialog: true })
  }
  saveScratchPadContents() {
    let temp = cookies.get("aScratchPad") || {}
    let savedSp = ""
    let spStorage =
      (!!cookies.get("aScratchPad") &&
        cookies.get("aScratchPad").savedScratchPads) ||
      []
    savedSp = temp.default
    if (spStorage.length < 3) {
      spStorage.push(savedSp)
    } else {
      spStorage.push(savedSp)
      spStorage.shift()
    }
    temp.savedScratchPads = spStorage
    cookies.set("aScratchPad", temp)
    this.setState({ saveScratchPadDialog: false, savedScratchPads: spStorage })
  }

  setConfirmOpen() {
    this.setState({ saveScratchPadDialog: false })
  }

  buildPreview() {
    const { model, modelType, modelTypeId, contentId, contentType } = this.props
    const queryParams = queryString.parse(this.props.location.search)
    this.clearResultsAndSetLoading()
    const previewRequest = {
      contentId,
      contentType: contentId ? contentType : "",
      dataModelText: contentId ? "" : model,
      modelType,
      modelTypeId,
      narrativeId: this.state.currentNarrativeId,
      templateString: this.state.template,
      itemIndex: parseInt(queryParams.itemindex, 10),
      useCache: false,
      excludeDependencies: true
    }
    const getTemplatePreview =
      NarrativePreviewDAO.evaluateSentence(previewRequest)
    getTemplatePreview.then(evaluatedTemplate => {
      this.setState({
        preview: evaluatedTemplate,
        loadingPreview: false
      })
    })
  }

  askChatGPT() {
    this.clearResultsAndSetLoading()
    const getChatCompletion = dsChatDAO.askChatGPTPrompt(this.state.template)
    getChatCompletion.then(evaluatedTemplate => {
      this.setState({
        preview: evaluatedTemplate,
        loadingPreview: false,
        chatResponse: true
      })
    })
  }

  clearResultsAndSetLoading() {
    this.setState({
      loadingPreview: true,
      chatResponse: false,
      preview: "",
      chatResponsePreview: ""
    })
  }

  buildChatResponsePreview() {
    const { model, modelType, modelTypeId, contentId, contentType } = this.props
    const queryParams = queryString.parse(this.props.location.search)
    this.setState({
      loadingChatResponsePreview: true,
      chatResponsePreview: ""
    })
    const previewRequest = {
      contentId,
      contentType: contentId ? contentType : "",
      dataModelText: contentId ? "" : model,
      modelType,
      modelTypeId,
      narrativeId: this.state.currentNarrativeId,
      templateString: this.state.preview,
      itemIndex: parseInt(queryParams.itemindex, 10),
      useCache: false,
      excludeDependencies: true
    }
    const getTemplatePreview =
      NarrativePreviewDAO.evaluateSentence(previewRequest)
    getTemplatePreview.then(evaluatedTemplate => {
      this.setState({
        chatResponsePreview: evaluatedTemplate,
        loadingChatResponsePreview: false,
        chatResponse: true
      })
    })
  }

  render() {
    const { classes } = this.props
    const { template, preview, readOnly, chatResponse, chatResponsePreview } =
      this.state
    return (
      <div className={classes.codeHolder}>
        <Tooltip
          id="tooltip-top-clear"
          title="Clear Scratch Pad"
          placement="top"
        >
          <IconButton
            className={classes.iconButton}
            aria-label="Clear Scratch Pad"
            onClick={() =>
              this.setState({
                template: ""
              })
            }
            size="large"
          >
            <Clear />
          </IconButton>
        </Tooltip>
        <Box>
          <CodeEditor
            withTabs
            template={template}
            handleChange={editor => {
              if (editor?.getValue() !== template && !readOnly) {
                const editorContent = editor.getValue()
                this.setState({
                  template: editorContent
                })
                const temp = cookies.get("aScratchPad") || {}
                temp.default = editorContent
                cookies.set("aScratchPad", temp)
              }
            }}
          />
        </Box>
        {this.state.loadingPreview && <LinearProgress />}
        {!readOnly ? (
          <Box className={classes.buttonContainer}>
            <Button onClick={() => this.buildPreview()}>Test</Button>
            <Button onClick={() => this.saveScratchPad()}>Save</Button>
            {/* <Button onClick={() => this.askChatGPT()}>Ask ChatGPT</Button> */}
          </Box>
        ) : (
          <Box className={classes.buttonContainer}>
            <Button onClick={() => this.buildPreview()}>Test</Button>
            {/* <Button onClick={() => this.askChatGPT()}>Ask ChatGPT</Button> */}
          </Box>
        )}
        {!readOnly ? (
          <Box>
            <Grid container item xs={12} className={classes.centeredCell}>
              {this.state.savedScratchPads.length > 0 && (
                <Tooltip
                  title={this.state.savedScratchPads[0]}
                  aria-label={this.state.savedScratchPads[0]}
                  placement="bottom"
                >
                  <span>
                    <Button size="small" onClick={() => this.getScratchPad(0)}>
                      A
                    </Button>
                  </span>
                </Tooltip>
              )}
              {this.state.savedScratchPads.length > 1 && (
                <Tooltip
                  title={this.state.savedScratchPads[1]}
                  aria-label={this.state.savedScratchPads[1]}
                  placement="bottom"
                >
                  <span>
                    <Button size="small" onClick={() => this.getScratchPad(1)}>
                      B
                    </Button>
                  </span>
                </Tooltip>
              )}
              {this.state.savedScratchPads.length > 2 && (
                <Tooltip
                  title={this.state.savedScratchPads[2]}
                  aria-label={this.state.savedScratchPads[2]}
                  placement="bottom"
                >
                  <span>
                    <Button size="small" onClick={() => this.getScratchPad(2)}>
                      C
                    </Button>
                  </span>
                </Tooltip>
              )}
              {this.state.savedScratchPads.length > 0 && (
                <Grid container item xs={4} className={classes.centeredCell}>
                  <Button
                    size="small"
                    onClick={() => this.deleteScratchPadEntry()}
                  >
                    Delete
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        ) : null}
        {chatResponse ? (
          <div>
            <CodeEditor
              template={preview}
              handleChange={editor => {
                if (editor && editor.getValue() !== preview && !readOnly) {
                  const editorContent = editor.getValue()
                  this.setState({
                    preview: editorContent
                  })
                }
              }}
              handleFocus={editor => {}}
            />
            {this.state.loadingChatResponsePreview && <LinearProgress />}
            <Box className={classes.buttonContainer}>
              <Button onClick={() => this.buildChatResponsePreview()}>
                Test
              </Button>
            </Box>
            <HTMLPreviewResponseBlock
              contentRef={this.contentRef}
              preview={chatResponsePreview}
            />
          </div>
        ) : (
          <HTMLPreviewResponseBlock
            contentRef={this.contentRef}
            preview={preview}
          />
        )}
        <ConfirmDialog
          title="Save the current Scratchpad?"
          open={this.state.saveScratchPadDialog}
          setOpen={this.setConfirmOpen}
          onConfirm={this.saveScratchPadContents}
        >
          <strong>
            This is saved in "Local Storage" on your machine for this browser
            type only and will be available until you overwrite it OR clear the
            storage on this browser.
          </strong>
          <br />
          <br />
          <strong style={{ marginBottom: "20px" }}>
            <u>
              Remember, the last entry in ScratchPad is always saved when you
              navigate away
            </u>
          </strong>

          {this.state.savedScratchPads.length > 2 && (
            <ul style={{ marginBottom: "0px" }}>
              <li key={2} className={classes.myListItems}>
                <strong>
                  You currently have {this.state.savedScratchPads.length}{" "}
                  ScratchPads saved, the next one you save will eliminate the
                  entry in position A.
                </strong>
              </li>
              <li key={3} className={classes.myListItems}>
                <strong>
                  You may prefer to delete your least-needed entry, then save
                  this new one.
                </strong>
              </li>
            </ul>
          )}
          <ul style={{ marginBottom: "0px" }}>
            <li key={1} className={classes.myListItems}>
              <strong>
                You can hover over the buttons to see the contents of your saved
                ScratchPads
              </strong>
            </li>
          </ul>
        </ConfirmDialog>
        <Dialog
          onClose={this.handleScratchPadDialogClose}
          aria-labelledby="simple-dialog-title"
          open={this.state.deleteScratchPadDialog}
        >
          <Toolbar style={{ minHeight: "12px" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={this.handleScratchPadDialogClose}
              aria-label="close"
              style={{ padding: "12px 0 0 12px" }}
              size="large"
            >
              <Close />
            </IconButton>
          </Toolbar>
          <DialogTitle id="simple-dialog-title">
            Delete which ScratchPad?
          </DialogTitle>
          <List className={classes.list}>
            {this.state.savedScratchPads.map((sp, i) => (
              <ListItem
                button
                onClick={() => this.handleDeleteItemClick(sp, i)}
                key={i}
                style={{
                  justifyContent: "space-evenly"
                }}
              >
                <ListItemAvatar>
                  <Avatar className={classes.avatar}>{entries[i]}</Avatar>
                </ListItemAvatar>
              </ListItem>
            ))}
          </List>
        </Dialog>
      </div>
    )
  }
}

ScratchPad.propTypes = {
  classes: PropTypes.object
}

export default withStyles(styles)(withRouter(ScratchPad))
