import React, { Component, Fragment } from "react"
import { withRouter } from "react-router"
import AssetManager from "views/AssetManager/AssetManager.jsx"
import Paragraph from "components/NarrativeAdmin/Paragraph/Paragraph"
import ParagraphTable from "components/NarrativeAdmin/Data/ParagraphTable"
import Sentence from "components/NarrativeAdmin/Sentence/Sentence"
import ScratchPad from "components/NarrativeAdmin/ScratchPad/ScratchPad"
import ScratchPadForStarters from "components/NarrativeAdmin/ScratchPad/ScratchPadForStarters"
import Trigger from "components/NarrativeAdmin/Trigger/Trigger"
import NarrativeRenderScheduler from "components/NarrativeAdmin/NarrativeRenderScheduler"
import MatchManager from "components/NarrativeAdmin/Data/MatchManager.jsx"
import classnames from "classnames"
import { NarrativeExplorer } from "components/NarrativeAdmin/Data/NarrativeExplorer"
import SearchExplorer from "components/NarrativeAdmin/Data/SearchExplorer"
import Narrative from "components/NarrativeAdmin/Narrative"
import NarrativeModelSnapshots from "components/NarrativeAdmin/Data/NarrativeModelSnapshots"
import ArchivedItems from "components/NarrativeAdmin/Data/ArchivedItems"
import GraphicsTool from "views/GraphicsTool/GraphicsTool"
import ImageSearchTool from "views/ImageSearchTool/ImageSearchTool"
import QueryBuilder from "views/QueryBuilder/QueryBuilder.jsx"
import Accordion from "components/Accordion/Accordion"
import SplitterLayout from "react-splitter-layout"
import "react-splitter-layout/lib/index.css"
import withStyles from "@mui/styles/withStyles"
import { globalSettings } from "variables/general.jsx"
import { Cookies } from "tools/storage"
import { groupCollectionBy } from "tools/CollectionHelper"
import queryString from "query-string"
import { search_add, search_remove_all } from "tools/querystringHelper"
import { NarrativeContext } from "contexts/narrative-context"
import Button from "components/CustomButtons/Button.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import SearchBlock from "components/NarrativeAdmin/Data/SearchBlock"
import NarrativeSchedule from "views/NarrativeAdmin/NarrativeSchedule.jsx"
import NarrativeDAO from "daos/narrativeDAO"
import "react-cron-builder/dist/bundle.css"
import {
  IconButton,
  InputLabel,
  AppBar,
  Toolbar,
  Checkbox,
  Grid,
  FormControlLabel,
  Tooltip,
  Modal,
  TextField,
  LinearProgress,
  Box,
  RadioGroup,
  Radio
} from "@mui/material"
import CardHeader from "components/Card/CardHeader.jsx"
import CardIcon from "components/Card/CardIcon.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import Typography from "@mui/material/Typography"
import Edit from "@mui/icons-material/Edit"
import NoteAdd from "@mui/icons-material/NoteAdd"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"
import {
  Clear,
  Widgets,
  Gesture,
  Schedule,
  LayersClear,
  Phonelink,
  PhonelinkOff
} from "@mui/icons-material"
import {
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap"
import Helpers from "tools/Helpers.js"
import TriggerTable from "../../components/NarrativeAdmin/Data/TriggerTable"
import HealthCheck from "components/NarrativeAdmin/HealthCheck"
import Enums from "tools/Enums.js"
import { Link } from "react-router-dom"
import { observer, inject } from "mobx-react"
import { styles } from "./styles"
import ContentSaturationModal from "components/ContentSaturation/Modal"
import TriggerDAO from "daos/triggerDAO"
import ModelPreviewLink from "components/NarrativeAdmin/NarrativePreviewer/ModelPreviewLink"
import NarrativePreviewer from "./NarrativePreviewer/NarrativePreviewerAdvanced"
import getFeaturedImageMarkup from "tools/getFeaturedImageMarkup"
import { get, put } from "tools/request"

export const standardMargin = "5px 15px 0 5px"
const useStore = component =>
  inject(({ store }) => ({
    setNarrativeMissingTriggersMetadata:
      store.narrativeStore.setNarrativeMissingTriggersMetadata,
    setNarrativeMissingTriggersUpdatedMetadata:
      store.narrativeStore.setNarrativeMissingTriggersUpdatedMetadata,
    setNarrativeMissingTriggersTableData:
      store.narrativeStore.setNarrativeMissingTriggersTableData,
    setNarrative: store.narrativeStore.setNarrative,
    evaluateTriggersMetadata: store.narrativeStore.evaluateTriggersMetadata,
    updateTriggersMetadata: store.narrativeStore.updateTriggersMetadata,
    setOrganizationId: store.organizationStore.setOrganizationId,
    setModelTypes: store.narrativeStore.setModelTypes,
    account: store.accountStore.account,
    organization: store.organizationStore.organization,
    organizationId: store.organizationStore.organizationId,
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization,
    getLibraryParagraphsBrief: store.narrativeStore.getLibraryParagraphsBrief,
    setIsError: store.uiStore.setIsError,
    setErrorMessage: store.uiStore.setErrorMessage
  }))(observer(component))

class NarrativeEditor extends Component {
  constructor(props) {
    super(props)
    this.props = props
    let apiOrigin = `${globalSettings.apiBaseUrl}/ap/NBA/`
    let date = new Date()
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()
    this.previewStartDateTime = new Date()
    this.endDateTimeInputRef = React.createRef()

    const queryParams = queryString.parse(props.location.search)
    let cookies = new Cookies()
    let fullScreenCookie = cookies.get("isFullScreen")
    let isFullScreen = fullScreenCookie === "true" || fullScreenCookie === true
    let isDataDrawerOpenCookie = cookies.get("isDataDrawerOpen")
    let isPreviewDrawerOpenCookie = cookies.get("isPreviewDrawerOpen")
    let dataDrawerWidthCookie = cookies.get("dataDrawerWidth")
    let previewDrawerWidthCookie = cookies.get("previewDrawerWidth")
    let narrativeExplorerWidthCookie = cookies.get("narrativeExplorerWidth")
    let showVariation = cookies.get("showVariation")
    let doShowSearch = cookies.get("doShowSearch")

    let isDataDrawerOpenState =
      isDataDrawerOpenCookie === "true" || isDataDrawerOpenCookie === true
    let isPreviewDrawerState =
      isPreviewDrawerOpenCookie === "true" || isPreviewDrawerOpenCookie === true

    this.state = {
      dataDrawerWidth: Number(dataDrawerWidthCookie) || 30,
      previewDrawerWidth: Number(previewDrawerWidthCookie) || 50,
      narrativeExplorerWidth: Number(narrativeExplorerWidthCookie) || 80,
      apiOrigin,
      activeNarrativeTab: 0,
      activeTab: "scratchpadTab",
      narrative: {
        id: +props.match.params.id
      },
      isRecap: false,
      narrativeLoaded: false,
      paragraphs: [],
      addNewParagraph: false,
      modelType: "",
      modelTypeId: null,
      model: {},
      modelPreview: null,
      modelUrlSuffix: `data-preview?GameYear=${year}&GameMonth=${month}&GameDay=${day}&narrativeid=${props.match.params.id}`,
      modelName: null,
      modelUrl: "",
      editNarrative: false,
      narrativePreviewContent: {},
      narrativeBlockSnapshots: [],
      modelSnapshots: [],
      activeNarrativeTriggers: [],
      modelSnapshotTriggers: [],
      dynamicTriggers: [],
      narrativeTriggers: [],
      loadingTriggers: false,
      loadingGeneral: false,
      modelTypes: [],
      modelSnapshotNarrativeId: props.match.params.id,
      searchType: "blocks",
      searchFilter: "",
      tokenList: {},
      modelSnapshotId: parseInt(queryParams.snapshotid, 10) || null,
      selectedMatchId: parseInt(queryParams.matchid, 10) || null,
      selectedAssetId: parseInt(queryParams.assetid, 10) || null,
      selectedQueryId: parseInt(queryParams.queryid, 10) || null,
      selectedPlayerId: parseInt(queryParams.playerId, 10) || null,
      itemIndex: parseInt(queryParams.itemindex, 10) || null,
      queryResultsIndexRange: "",
      selectedMatchIds: [],
      activeView: queryParams.view || "content",
      multiActiveView: "",
      sentenceSaved: false,
      sentenceTrigger: false,
      organizationId: this.props.organizationId,
      organizations: this.props.organizations?.toJS() || [],
      narrativeChanged: false,
      showInherited: false,
      contentBlockElements: null,
      searchString: "",
      replaceString: "",
      searchInName: true,
      searchInTrigger: true,
      searchInTemplate: true,
      searchBlockIds: "",
      isDebugMode: false,
      toggleElements: true,
      triggerTypes: [],
      isFullScreen,
      playerId: 34543,
      feeds: [],
      archivedItems: [],
      outputStarterTab: [],
      archivedItemsLoaded: false,
      isRenderSchedulerOpen: false,
      fileDataLoaded: false,
      startDateTime: new Date(),
      endDateTime: null,
      duplicateHandling: 0,
      cronSyntax: "0 */1 * * *", // we don't allow a repeat every minute in this cron syntax.  This is use to indicate a "Run Once" schedule
      cronTimeZoneIANA: "America/Los_Angeles",
      isCronBuilderOpen: false,
      isPaused: false,
      doRenderCronSyntaxCheatSheetImage: false,
      isAlertMessageOpen: false,
      isRenderScheduleConfirmationOpen: false,
      alertMessage: "",
      futureOccurrencesMessage: null,
      useRecurrenceSettings: false,
      maxFutureOccurrenceCount: 100,
      isDataDrawerOpen: isDataDrawerOpenState,
      isPreviewDrawerOpen: isPreviewDrawerState,
      publishDelayInSeconds: 0,
      previewElapsedTimeMilliseconds: 0,
      isLoadingPreview: false,
      doShowStartDateTime: false,
      ownerList: [],
      fetchingAccountsByOrgList: 0,
      bulkAlertMessage: {},
      bulkAlertMessageActive: false,
      bulkAlertErrorMessageActive: false,
      toggleViewFromExplorer: false,
      toggleViewLast: "",
      isSearching: false,
      isLoadingSearch: false,
      searchedBlocksList: [],
      searchedTriggerList: [],
      selectedSentence: null,
      sentenceTransition: false,
      showVariation: showVariation || false,
      doShowSearch: doShowSearch || false
    }

    this.fetchNarrativeModelSnapshots =
      this.fetchNarrativeModelSnapshots.bind(this)
    this.fetchNarrativeModelTokenList =
      this.fetchNarrativeModelTokenList.bind(this)
    this.fetchTagTypes = this.fetchTagTypes.bind(this)
    this.fetchNarrativeModelTypes = this.fetchNarrativeModelTypes.bind(this)
    this.toggleNarrativeViews = this.toggleNarrativeViews.bind(this)
    this.toggle = this.toggle.bind(this)
    this.subToggle = this.subToggle.bind(this)
    this.toggleNarrativeEdit = this.toggleNarrativeEdit.bind(this)
    this.changeName = this.changeName.bind(this)
    this.changeBody = this.changeBody.bind(this)
    this.handlePlayerIdChange = this.handlePlayerIdChange.bind(this)
    this.handleIndexChangeEvent = this.handleIndexChangeEvent.bind(this)
    this.handleIndexChange = this.handleIndexChange.bind(this)
    this.handleQueryResultsIndexRangeChange =
      this.handleQueryResultsIndexRangeChange.bind(this)
    this.buildParagraphPreview = this.buildParagraphPreview.bind(this)
    this.onDataModelTextAreaChange = this.onDataModelTextAreaChange.bind(this)
    this.getContentId = this.getContentId.bind(this)
    this.getContentType = this.getContentType.bind(this)
    this.getActiveView = this.getActiveView.bind(this)
    this.changePage = this.changePage.bind(this)
    this.replaceStrings = this.replaceStrings.bind(this)
    this.loadFeeds = this.loadFeeds.bind(this)
    this.loadAccountsInOrg = this.loadAccountsInOrg.bind(this)
    this.toggleGenFeedModal = this.toggleScheduler.bind(this)
    this.toggleCronBuilderModal = this.toggleCronBuilderModal.bind(this)
    this.validateRenderCreation = this.validateRenderCreation.bind(this)
    this.togglePreviewDrawer = this.togglePreviewDrawer.bind(this)
    this.buildFeedPreview = this.buildFeedPreview.bind(this)
  }

  componentDidMount() {
    this.props.setShowChangeCurrentOrganization(true)
    let match = this.props?.match
    let cookies = new Cookies()
    let orgId = this.props?.organizationId || cookies.get("currentorg")
    if (
      orgId &&
      !this.state.ownerList.length &&
      !this.state.fetchingAccountsByOrgList
    ) {
      this.loadAccountsInOrg(orgId)
    }
    if (match) {
      this.fetchTagTypes()
      this.fetchNarrativeModelTypes()
      this.fetchNarrativeInfo(match.params.id)
      this.fetchNarrativeModelTriggerTypes()
      if (cookies.get("showRenderSchedulerFromNarrativesPage")) {
        if (this.state.narrative && this.state.narrative.organization) {
          this.toggleScheduler()
          if (!this.state.ownerList.length) {
            this.loadAccountsInOrg()
          }
          cookies.set("showRenderSchedulerFromNarrativesPage", null)
        }
      } else {
        if (
          this.state.narrative &&
          this.state.narrative.organization &&
          !this.state.ownerList.length
        ) {
          this.loadAccountsInOrg()
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    let cookies = new Cookies()
    let orgId = this.props?.organizationId || cookies.get("currentorg")
    if (
      orgId &&
      !this.state.ownerList.length &&
      !this.state.fetchingAccountsByOrgList
    ) {
      if (this.state.fetchingAccountsByOrgList !== orgId) {
        this.loadAccountsInOrg(orgId)
      }
    }
    if (this.state.narrative?.name) {
      Helpers.updateBrowserTabTitle(
        false,
        `${this.state.narrative.name} - Advanced Editor`
      )
    }
    //If only the narrarive # changes in the URL, we need to fetch the narrative info
    let curURL = prevProps.history.location.pathname.match(
      /\/portal\/narrative\/(\d{1,6})\/edit$/
    )

    let newURL = prevProps.location.pathname.match(
      /\/portal\/narrative\/\d{1,6}\/edit$/
    )
    if (
      !!curURL &&
      !!newURL &&
      prevProps.history.location.pathname !== prevProps.location.pathname
    ) {
      if (curURL && curURL[1]) {
        this.fetchNarrativeInfo(curURL[1])
      }
    }
    if (curURL[1] && !this.state.checkingIfIsLibrary) {
      this.setState({ checkingIfIsLibrary: true })
      const isNarrativeLibrary = NarrativeDAO.getNarrativeIsLibrary(curURL[1])
      isNarrativeLibrary.then(result => {
        Helpers.didWeNavigateToLibraryEditor(result.content)
      })
    }
    if (cookies.get("showRenderSchedulerFromNarrativesPage")) {
      if (this.state.narrative && this.state.narrative.organization) {
        this.toggleScheduler()
        if (!this.state.ownerList.length) {
          this.loadAccountsInOrg()
        }
        cookies.set("showRenderSchedulerFromNarrativesPage", null)
      }
    } else {
      if (
        this.state.narrative?.organization &&
        !this.state.ownerList.length &&
        !this.state.fetchingAccountsByOrgList
      ) {
        if (this.state.fetchingAccountsByOrgList !== orgId) {
          this.loadAccountsInOrg()
        }
      }
    }
    if (prevProps.history.location.search !== prevProps.location.search) {
      const queryParams = queryString.parse(prevProps.history.location.search)
      const viewKey = queryParams.view
      if (viewKey && !this.state.activeView) {
        const activeViewElements = this.getActiveView(
          viewKey,
          this.state.narrative
        )
        this.setState({
          activeView: viewKey,
          contentBlockElements: activeViewElements.contentBlockElements || []
        })
      } else if (!!viewKey && viewKey !== this.state.activeView) {
        const activeViewElements = this.getActiveView(
          viewKey,
          this.state.narrative
        )
        this.setState({
          activeView: viewKey,
          contentBlockElements: activeViewElements.contentBlockElements || []
        })
      }
    }
  }

  componentWillUnmount() {
    this.props.setNarrativeMissingTriggersMetadata(undefined)
    this.props.setNarrativeMissingTriggersUpdatedMetadata(undefined)
    this.props.setNarrativeMissingTriggersTableData(undefined)
  }

  toggleNarrativeViews(tab) {
    if (this.state.activeNarrativeTab !== tab) {
      this.setState({
        activeNarrativeTab: tab
      })
    }
  }
  handleNarrativeViewChange = (_event, newValue) => {
    this.setState({
      activeNarrativeTab: newValue
    })
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }
  subToggle(tab) {
    if (this.state.activeSubTab !== tab) {
      this.setState({
        activeSubTab: tab
      })
    }
  }

  toggleView() {
    const { isFullScreen } = this.state
    this.setState({
      isFullScreen: !isFullScreen
    })
    let cookies = new Cookies()
    cookies.set("isFullScreen", !isFullScreen, {
      path: "/",
      expires: Helpers.CookieExpiration.OneMonth
    })
  }

  toggleDataDrawer() {
    const { isDataDrawerOpen } = this.state
    this.setState({
      isDataDrawerOpen: !isDataDrawerOpen
    })
    let cookies = new Cookies()
    cookies.set("isDataDrawerOpen", !isDataDrawerOpen, {
      path: "/",
      expires: Helpers.CookieExpiration.OneMonth
    })
  }

  onNarrativeExplorerWidthChange(newWidth) {
    this.setState({ narrativeExplorerWidth: newWidth })
    let cookies = new Cookies()
    cookies.set("narrativeExplorerWidth", newWidth, {
      path: "/",
      expires: Helpers.CookieExpiration.OneMonth
    })
  }

  onDataDrawerWidthChange(newWidth) {
    this.setState({ dataDrawerWidth: newWidth })
    let cookies = new Cookies()
    cookies.set("dataDrawerWidth", newWidth, {
      path: "/",
      expires: Helpers.CookieExpiration.OneMonth
    })
  }

  onPreviewDrawerWidthChange(newWidth) {
    this.setState({ previewDrawerWidth: newWidth })
    let cookies = new Cookies()
    cookies.set("previewDrawerWidth", newWidth, {
      path: "/",
      expires: Helpers.CookieExpiration.OneMonth
    })
  }

  togglePreviewDrawer = () => {
    const { isPreviewDrawerOpen } = this.state
    this.setState({
      isPreviewDrawerOpen: !isPreviewDrawerOpen
    })
    let cookies = new Cookies()
    cookies.set("isPreviewDrawerOpen", !isPreviewDrawerOpen, {
      path: "/",
      expires: Helpers.CookieExpiration.OneMonth
    })
  }

  searchForString(triggers = false) {
    if (this.state && this.state.searchString) {
      let activeView = encodeURIComponent(`search-${this.state.searchString}`)
      if (triggers) {
        activeView = encodeURIComponent(
          `searchtriggers-${this.state.searchString}`
        )
      }
      this.setState(
        {
          activeView,
          isSearching: true,
          isLoadingSearch: true,
          searchedBlocksList: [],
          searchedTriggerList: []
        },
        () => this.changePage(activeView)
      )
    }
  }

  clearFilters() {
    if (this.state && this.state.activeView) {
      const viewKey = "content"
      this.setState(
        {
          activeView: viewKey,
          isSearching: false,
          isLoadingSearch: false,
          searchString: "",
          replaceString: "",
          searchBlockIds: "",
          searchedBlocksList: [],
          searchedTriggerList: []
        },
        () => this.changePage(viewKey)
      )
    }
  }

  handleNarrativeEdit(narrative) {
    if (this.state) {
      const existingNarrative = this.state.narrative
      const newNarrative = { ...narrative }
      newNarrative.paragraphs = existingNarrative.paragraphs
      newNarrative.outlineBlocks = existingNarrative.outlineBlocks
      newNarrative.triggers = existingNarrative.triggers
      newNarrative.libraryParagraphs = existingNarrative.libraryParagraphs
      this.setState(
        {
          narrative: newNarrative,
          editNarrative: false,
          model: narrative.dataSourceSample || this.state.model
        },
        () => {
          if (
            existingNarrative.libraryNarrative_Ids !==
            newNarrative.libraryNarrative_Ids
          ) {
            this.reloadLibraryParagraphs()
          }
        }
      )
      this.props.setNarrative(newNarrative) //Passes the Store the new narrative value so that  other components - those using the Store - have the latest copy
    }
  }
  toggleNarrativeEdit() {
    if (this.state) {
      this.setState({
        editNarrative: !this.state.editNarrative
      })
    }
  }
  toggleScheduler() {
    const contentId = this.getContentId()
    const contentType = this.getContentType()
    const { narrative } = this.state
    if (contentType === "query" && this.state.queryResultsIndexRange === "") {
      this.setState({
        queryResultsIndexRange: ""
      })
    }
    if (narrative.isArchived) {
      this.setState({
        alertMessage: "An archived narrative cannot be scheduled.",
        isAlertMessageOpen: true
      })
      return
    }
    // Inactive status = 1
    if (narrative.narrativeStatusId === 1) {
      this.setState({
        alertMessage:
          "A narrative with an 'inactive' status cannot be scheduled.",
        isAlertMessageOpen: true
      })
      return
    }
    if (!contentId || !contentType) {
      this.setState({
        alertMessage: "Select a narrative data model prior to scheduling.",
        isAlertMessageOpen: true
      })
      return
    }
    if (this.state.startDateTime < new Date()) {
      this.setState({
        startDateTime: new Date()
      })
    }
    if (!this.state.isRenderSchedulerOpen) {
      this.calculateFutureOccurrences()
      //this.loadFeeds()
    }
    this.setState({
      isRenderSchedulerOpen: !this.state.isRenderSchedulerOpen
    })
  }

  toggleCronBuilderModal() {
    this.setState({
      isCronBuilderOpen: !this.state.isCronBuilderOpen
    })
  }
  clearNarrativeCache() {
    const { narrative } = this.state
    if (narrative && narrative.id) {
      get(
        `${globalSettings.apiBaseUrl}/api/sandbox/memorycache/clear-narrative-cache?narrativeId=${narrative.id}`
      )
        .then(Response => Response.json())
        .then(cache => {
          if (cache && cache.responseCode === 1000) {
            alert(`Cache Cleared for Narrative ${narrative.id}`)
          }
        })
    }
  }

  getArchivedItemsForNarrative() {
    const { narrative } = this.state
    if (narrative && narrative.id) {
      get(`${globalSettings.apiBaseUrl}/api/narrative/${narrative.id}/archived`)
        .then(Response => Response.json())
        .then(archivedItems => {
          if (
            archivedItems &&
            archivedItems.content &&
            archivedItems.content[0]
          ) {
            let items = archivedItems.content
            this.setState({ archivedItems: items, archivedItemsLoaded: true })
          } else {
            this.setState({ archivedItems: [], archivedItemsLoaded: true })
          }
        })
    }
  }

  reloadData(viewKey) {
    const { narrative } = this.state
    this.fetchNarrativeInfo(narrative.id)
    if (viewKey) {
      this.setState({ activeView: viewKey }, () => this.changePage(viewKey))
    }
  }

  reloadLibraryParagraphs() {
    const { narrative } = this.state
    if (narrative.libraryNarrative_Ids !== null) {
      const ids = narrative.libraryNarrative_Ids.split(/[, ]+/)
      this.props
        .getLibraryParagraphsBrief(ids, this.state.showVariation)
        .then(response => {
          narrative.libraryParagraphs = response || []
          this.setState({ narrative })
        })
    }
  }

  replaceStrings(triggers = false) {
    if (this.state && this.state.searchString && this.state.replaceString) {
      const {
        searchString,
        replaceString,
        narrative,
        searchBlockIds,
        searchInName,
        searchInTemplate,
        searchInTrigger
      } = this.state
      this.setState({ isLoadingSearch: true })
      if (triggers) {
        TriggerDAO.replaceInTriggers({
          narrativeId: narrative.id,
          searchString,
          replaceString,
          inName: searchInName,
          inTrigger: searchInTrigger,
          inTemplate: searchInTemplate,
          blockIds:
            (searchBlockIds &&
              searchBlockIds !== "" &&
              searchBlockIds.split(",")) ||
            null
        })
          .then(Response => Response && Response.ok === true)
          .then(() => {
            this.fetchNarrativeInfo(this.state.narrative.id)
            let activeView = `searchtriggers-${this.state.replaceString}`
            this.setState({ activeView, isLoadingSearch: false }, () =>
              this.changePage(activeView)
            )
          })
      } else {
        put(`${globalSettings.apiBaseUrl}/api/sentence/replace`, null, {
          narrativeId: narrative.id,
          searchString,
          replaceString,
          inName: searchInName,
          inTrigger: searchInTrigger,
          inTemplate: searchInTemplate,
          blockIds:
            (searchBlockIds &&
              searchBlockIds !== "" &&
              searchBlockIds.split(",")) ||
            null
        })
          .then(Response => Response && Response.ok === true && Response.json())
          .then(() => {
            this.fetchNarrativeInfo(this.state.narrative.id)
            this.setState({ isLoadingSearch: false })
          })
      }
    }
  }

  loadFeeds() {
    const { narrative } = this.state
    if (narrative && narrative.organization && narrative.organization.id) {
      get(
        `${globalSettings.apiBaseUrl}/api/feed/retrieveall?organizationid=${narrative.organization.id}`
      )
        .then(Response => Response.json())
        .then(feeds => {
          // never show 'archived' feeds
          let filteredFeeds = []
          filteredFeeds = feeds.content.types.filter(
            f => f.isArchived === false
          )
          // When a narrative is in 'Validate' status only display
          // feeds that are also in 'Validate' status
          if (
            this.state.narrative.narrativeStatusId ===
            Enums.NarrativeStatus.VALIDATE
          ) {
            filteredFeeds = filteredFeeds.filter(
              f => f.feedStatus === Enums.FeedStatus.VALIDATE
            )
          }
          this.setState({
            feeds: filteredFeeds
          })
        })
    }
  }

  loadAccountsInOrg(orgId) {
    const { narrative } = this.state
    orgId =
      orgId ||
      (narrative && narrative.organization && narrative.organization.id)
    if (orgId && !this.state.fetchingAccountsByOrgList) {
      this.setState({ fetchingAccountsByOrgList: true })
      get(
        `${globalSettings.apiBaseUrl}/api/account/retrieveaccountsbyorg?orgid=${orgId}`
      )
        .then(Response => Response.json())
        .then(accts => {
          if (accts && accts.content && accts.content.length) {
            let myList = []
            accts.content.forEach(element => {
              if (
                !!element.firstName &&
                !!element.lastName &&
                !!element.username &&
                element.status === "Active"
              ) {
                myList.push({
                  name: `${element.firstName} ${element.lastName}`,
                  username: element.username,
                  id: element.id
                })
              }
            })
            let cookies = new Cookies()
            cookies.set("AccountsForOrg", myList)
            this.setState({
              ownerList: myList,
              fetchingAccountsByOrgList: orgId
            })
          } else {
            this.setState({ fetchingAccountsByOrgList: orgId })
          }
        })
    }
  }

  updateNarrativeSentence(updatedSentence) {
    // In lieu of reloading the entire narrative for sentence changes,
    // just update the paragraph and sentence in the narrative
    const sid = updatedSentence?.id || updatedSentence?.createdSentenceId
    const pid = updatedSentence?.paragraph_Id
    let myNarrative = this.state.narrative
    let found = false
    let refetch = false
    const paragraph = myNarrative.paragraphs.find(p => p.id === pid)
    if (paragraph) {
      paragraph.sentences.forEach((itm2, j) => {
        if (itm2.id === sid) {
          found = true
          let currentSentence = paragraph.sentences[j]
          if (
            currentSentence.isLibraryPlaceholder !==
              updatedSentence.isLibraryPlaceholder ||
            currentSentence.libraryParagraph_Id !==
              updatedSentence.libraryParagraph_Id ||
            currentSentence.librarySentencePosition !==
              updatedSentence.librarySentencePosition
          ) {
            refetch = true
            return
          } else {
            paragraph.sentences[j] = updatedSentence
          }
        }
      })
      if (!found) {
        const newSentenceValue = {
          ...updatedSentence,
          id: sid
        }
        paragraph.sentences.push(newSentenceValue)
        found = true
      }
    }
    if (found) {
      this.updateParagraphs(paragraph, true)
    }
    if (refetch) {
      this.fetchNarrativeInfo(myNarrative.id)
    }
  }

  fetchNarrativeInfo(narrativeId) {
    this.fetchParagraphTagsForMNarrative(narrativeId) //21 Dec 22
    this.setState({ loading: true })
    get(
      `${globalSettings.apiBaseUrl}/api/narrative/${narrativeId}?includeVariationScores=${this.state.showVariation}&doIncludeSearchableColumns=${this.state.doShowSearch}`
    )
      .then(Response => Response && Response.ok === true && Response.json())
      .then(async narrative => {
        if (!narrative) {
          this.props.history.push("/portal/narratives")
          this.props.setIsError(true)
          this.props.setErrorMessage(`Narrative ${narrativeId} does not exist.`)
        }
        if (!narrative.content) {
          this.setState({ loading: false })
          this.setState({ narrativeLoaded: false })
          return
        }

        const { content: fetchedNarrative } = narrative

        let selectedQueryId =
          fetchedNarrative.queryId || this.state.selectedQueryId
        let activeSubTab = this.state.activeSubTab

        if (!this.state.narrativeLoaded) {
          if (fetchedNarrative.dataSourceSample !== "") {
            activeSubTab = "pastedDataTab"
          }
          if (selectedQueryId && selectedQueryId > 0) {
            activeSubTab = "querybuilderDataTab"
          } else if (fetchedNarrative.leagueId !== "") {
            activeSubTab = "matchDataTab"
          }
        }
        let activeViewElements = null

        if (fetchedNarrative.libraryNarrative_Ids !== null) {
          const ids = fetchedNarrative.libraryNarrative_Ids.split(/[, ]+/)
          const libraryParagraphs = await this.props.getLibraryParagraphsBrief(
            ids,
            this.state.showVariation
          )
          fetchedNarrative.libraryParagraphs = libraryParagraphs || []
        }

        if (!this.state.loadingParagraphTags) {
          activeViewElements = this.getActiveView(
            this.state.activeView,
            fetchedNarrative
          )
        }

        this.props.setOrganizationId(fetchedNarrative.organization.id)
        this.setState({
          modelType: fetchedNarrative.modelType,
          modelTypeId: fetchedNarrative.ModelTypeId,
          narrative: fetchedNarrative,
          model: fetchedNarrative.dataSourceSample,
          loading: false,
          narrativeLoaded: true,
          organizationId: fetchedNarrative.organization.id,
          selectedQueryId,
          modelName:
            selectedQueryId && `Query Builder Query #${selectedQueryId}`,
          activeSubTab,
          contentBlockElements: activeViewElements
            ? activeViewElements.contentBlockElements
            : this.state.contentBlockElements
        })
      })
  }

  updateParagraphs(paragraph, updatingSentence) {
    if (!this.state.narrative || !this.state.narrative.paragraphs) {
      return
    }
    const narrative = this.state.narrative
    const paragraphs = narrative.paragraphs
    let paragraphTags = this.state.paragraphTags //Update paragraphTags with the change
    if (!updatingSentence) {
      let found = false
      //DO Primary tags
      paragraphTags[paragraph.id] = paragraphTags[paragraph.id] || []
      if (paragraph.primaryTagId) {
        found = false
        if (paragraphTags && paragraphTags[paragraph.id]) {
          paragraphTags[paragraph.id].forEach(itm => {
            if (itm.tagTypeId === 1) {
              itm.tagId = paragraph.primaryTagId
              found = true
            }
          })
        }
        if (!found) {
          if (paragraphTags[paragraph.id]) {
            paragraphTags[paragraph.id].push({
              paragraphId: paragraph.id,
              tagId: paragraph.primaryTagId,
              tagTypeId: 1
            })
          } else {
            paragraphTags[paragraph.id] = [
              {
                paragraphId: paragraph.id,
                tagId: paragraph.primaryTagId,
                tagTypeId: 1
              }
            ]
          }
        }
      } else {
        //If primaryTagId emtpy, remove all primaryTagId
        paragraphTags[paragraph.id] = paragraphTags[paragraph.id].filter(
          itm => itm.tagTypeId !== 1
        )
      }
      //Do Secondary Tags
      if (paragraphTags[paragraph.id]) {
        paragraphTags[paragraph.id] = paragraphTags[paragraph.id].filter(
          itm => itm.tagTypeId !== 2
        )
      }
      paragraph.secondaryTagIds &&
        paragraph.secondaryTagIds.forEach(itm => {
          paragraphTags[paragraph.id].push({
            paragraphId: paragraph.id,
            tagId: itm,
            tagTypeId: 2
          })
        })
      //Do Form Factor Tags
      if (paragraph.formFactorTagId) {
        found = false
        if (paragraphTags && paragraphTags[paragraph.id]) {
          paragraphTags[paragraph.id].forEach(itm => {
            if (itm.tagTypeId === 3) {
              itm.tagId = paragraph.formFactorTagId
              found = true
            }
          })
        }
        if (!found) {
          if (paragraphTags[paragraph.id]) {
            paragraphTags[paragraph.id].push({
              paragraphId: paragraph.id,
              tagId: paragraph.formFactorTagId,
              tagTypeId: 3
            })
          } else {
            paragraphTags[paragraph.id] = [
              {
                paragraphId: paragraph.id,
                tagId: paragraph.formFactorTagId,
                tagTypeId: 3
              }
            ]
          }
        }
      } else {
        //If formFactorTagId emtpy, remove all primaryTagId
        paragraphTags[paragraph.id] = paragraphTags[paragraph.id].filter(
          itm => itm.tagTypeId !== 3
        )
      }
      //Do Third Party specific Tags
      if (paragraphTags[paragraph.id]) {
        paragraphTags[paragraph.id] = paragraphTags[paragraph.id].filter(
          itm => itm.tagTypeId !== 4
        )
      }
      paragraph.thirdPartyTagIds &&
        paragraph.thirdPartyTagIds.forEach(itm => {
          paragraphTags[paragraph.id].push({
            paragraphId: paragraph.id,
            tagId: itm,
            tagTypeId: 4
          })
        })
    }
    const paragraphIdx = paragraphs.findIndex(a => a.id === paragraph.id)
    if (!paragraph.sentences) {
      paragraph.sentences = []
    } else {
      // Don't add unsaved sentences
      paragraph.sentences = paragraph.sentences.filter(s => s.id)
    }
    // update paragraphs in state
    if (paragraphIdx !== -1) {
      narrative.paragraphs[paragraphIdx] = paragraph
    } else {
      narrative.paragraphs = [...paragraphs, paragraph]
    }
    narrative.lastModifiedDate = Date.now()
    this.setState({
      narrative,
      paragraphTags
    })
  }

  fetchNarrativeModelTypes() {
    this.setState({ loadingNarrativeModels: true })
    get(`${globalSettings.apiBaseUrl}/api/narrative/modeltypes`)
      .then(Response => Response && Response.ok === true && Response.json())
      .then(response => {
        const modelTypes = response.content
        this.setState({
          loadingNarrativeModels: false,
          modelTypes
        })
        this.props.setModelTypes(modelTypes)
      })
  }

  fetchParagraphTagsForMNarrative(nId) {
    this.setState({ loadingParagraphTags: true })
    get(`${globalSettings.apiBaseUrl}/api/narrative/${nId}/paragraphtags`)
      .then(Response => Response.json())
      .then(response => {
        const paragraphTags = {}
        response &&
          response.forEach(resp => {
            paragraphTags[resp.id] = resp.paragraphTags
          })

        if (!this.state.loading) {
          this.setState(
            {
              paragraphTags: paragraphTags,
              loadingParagraphTags: false
            },
            () => {
              const activeViewElements = this.getActiveView(
                this.state.activeView,
                this.state.narrative
              )
              this.setState({
                contentBlockElements: activeViewElements.contentBlockElements
              })
            }
          )
        } else {
          this.setState({
            paragraphTags: paragraphTags,
            loadingParagraphTags: false
          })
        }
      })
  }

  fetchTagTypes() {
    this.setState({ loadingPrimaryTags: true })
    get(`${globalSettings.apiBaseUrl}/api/tag/`)
      .then(Response => Response.json())
      .then(response => {
        const tagTypes = [
          { name: "primaryTags", tagTypeId: 1, values: [] },
          { name: "secondaryTags", tagTypeId: 2, values: [] },
          { name: "formFactorTags", tagTypeId: 3, values: [] },
          { name: "thirdPartySpecificTags", tagTypeId: 4, values: [] }
        ]
        tagTypes.forEach(itm => {
          const temp =
            response.content &&
            response.content.filter(resp => resp.tagTypeId === itm.tagTypeId)
          temp.forEach(itm2 => {
            itm2.label = itm2.name
            itm2.value = itm2.id
          })
          itm.values = temp
        })
        this.setState({
          primaryTags: tagTypes[0].values,
          secondaryTags: tagTypes[1].values,
          formFactorTags: tagTypes[2].values,
          thirdPartySpecificTags: tagTypes[3].values,
          loadingPrimaryTags: false
        })
      })
  }

  fetchNarrativeModelSnapshots() {
    this.setState({ loadingSnapshots: true })
    get(
      `${globalSettings.apiBaseUrl}/api/narrativepreview/recent-models/${this.state.modelSnapshotNarrativeId}`
    )
      .then(Response => Response && Response.ok === true && Response.json())
      .then(snapshots => {
        if (!snapshots || snapshots.length < 1) {
          this.setState({
            loadingSnapshots: false,
            modelSnapshots: []
          })
          return
        }
        const snapshotTriggers = JSON.parse(snapshots[0].triggers)
        const modelSnapshotTriggers =
          snapshotTriggers &&
          snapshotTriggers.map(key => ({
            key: key.Key,
            value: key.Value
          }))
        const contentId = this.getContentId()
        if (!contentId) {
          this.setState({
            loadingSnapshots: false,
            modelSnapshots: snapshots,
            modelSnapshotTriggers
          })
        } else {
          this.setState({
            loadingSnapshots: false,
            modelSnapshots: snapshots
          })
        }
      })
  }

  changePage(activeView, placeholderId) {
    const searchParams = queryString.parse(this.props.location.search)
    let newSearch = ""
    const oldView = searchParams.view

    const sentenceOrTrigger = !!activeView.match(/sentenceid|triggerid/)
    if (sentenceOrTrigger) {
      if (oldView && !!oldView.match(/sentenceid|triggerid/)) {
        if (this.state.triggerSaved || this.state.sentenceSaved) {
          newSearch = `?view=${encodeURIComponent(this.state.multiActiveView)}`
          if (!!searchParams.itemindex || searchParams.itemindex === "0") {
            newSearch = `${newSearch}&itemindex=${searchParams.itemindex}`
          }
          this.setState({
            sentenceSaved: false,
            triggerSaved: false
          })
        } else {
          if (searchParams && searchParams.itemindex) {
            newSearch = `?view=${encodeURIComponent(activeView)}&itemindex=${
              searchParams.itemindex
            }`
          } else {
            newSearch = `?view=${encodeURIComponent(activeView)}`
          }
        }
        this.props.history.push({ search: newSearch })
      } else if (oldView && !oldView.match(/sentenceid|triggerid/)) {
        this.props.history.push({
          search: `?view=${activeView}`
        })
      } else {
        newSearch = search_remove_all("view")
        newSearch = search_add("view", activeView)
        this.props.history.push({
          search: newSearch
        })
      }
    } else {
      if (oldView) {
        newSearch = search_remove_all("view")
        this.props.history.push({ search: newSearch })
      }
      if (activeView !== "content" && activeView !== "element-content") {
        newSearch = search_add("view", activeView)
        this.props.history.push({
          search: newSearch
        })
      }
    }

    const activeViewElements = this.getActiveView(
      activeView,
      this.state.narrative,
      placeholderId
    )

    this.setState({
      contentBlockElements: activeViewElements.contentBlockElements || []
    })
  }

  fetchNarrativeModelTokenList() {
    const contentId = this.getContentId()
    const contentType = this.getContentType()
    const { narrative } = this.state
    if (contentId !== "") {
      this.setState({ loadingData: true })
      const params = `narrativeid=${narrative.id}&contentid=${contentId}&contenttype=${contentType}`
      get(
        `${globalSettings.apiBaseUrl}/api/narrativepreview/token-dictionary?${params}`
      )
        .then(Response => Response && Response.ok === true && Response.json())
        .then(tokenDictionary => {
          const tokenList = JSON.parse(tokenDictionary)
          this.setState({
            tokenList,
            loadingData: false
          })
        })
    }
  }

  getContentType() {
    const {
      modelSnapshotId,
      selectedMatchId,
      selectedAssetId,
      selectedQueryId,
      selectedPlayerId,
      selectedMatchIds,
      isRecap
    } = this.state
    if (selectedPlayerId && selectedPlayerId !== 0) {
      return "fantasyfootballplayer"
    }
    if (modelSnapshotId && modelSnapshotId !== 0) {
      return "snapshot"
    }
    if (
      (selectedMatchId && selectedMatchId !== 0) ||
      (selectedMatchIds && selectedMatchIds.length > 0)
    ) {
      return isRecap ? "match_recap" : "match_preview"
    }
    if (selectedAssetId && selectedAssetId !== 0) {
      return "asset"
    }
    if (selectedQueryId && selectedQueryId !== 0) {
      return "query"
    }
    return ""
  }

  getContentId() {
    const {
      modelSnapshotId,
      selectedMatchId,
      selectedAssetId,
      selectedQueryId,
      selectedPlayerId
    } = this.state
    return (
      selectedPlayerId ||
      modelSnapshotId ||
      selectedMatchId ||
      selectedAssetId ||
      selectedQueryId
    )
  }

  buildFeedPreview(
    options = { debug: false, debugAllTriggers: false, ignoreKillswitch: false }
  ) {
    const contentId = this.getContentId()
    const contentType = this.getContentType()

    const { model, modelSnapshotTriggers, modelTypeId, modelType, narrative } =
      this.state

    const queryParams = queryString.parse(this.props.location.search)
    this.previewStartDateTime = new Date()
    this.setState({
      isLoadingPreview: true
    })
    put(`${globalSettings.apiBaseUrl}/api/narrativepreview/feed-entry`, null, {
      narrativeId: narrative.id,
      contentId,
      contentType,
      dataModelText: contentId ? "" : model,
      modelType: modelType || narrative.modelType,
      modelTypeId: modelTypeId || narrative.narrativeModelType_Id,
      debug: options.debug === true,
      debugAllTriggers: options.debugAllTriggers === true,
      ignoreKillswitch: options.ignoreKillswitch === true,
      triggers: modelSnapshotTriggers,
      itemIndex: parseInt(queryParams.itemindex, 10),
      duplicateHandling: 1
    })
      .then(Response => Response && Response.ok === true && Response.json())
      .then(feedEntry => {
        let narrativePreviewContent = feedEntry.content
        if (narrativePreviewContent) {
          // Downstream processes cannot use the FeaturedImageJson
          // they want it converted to html that renders a link to the image
          if (
            narrativePreviewContent.featuredImageJSON &&
            narrativePreviewContent.featuredImageJSON !== "null"
          ) {
            // If there's a featured image we swap it with the markup
            narrativePreviewContent.featuredImageJSON = getFeaturedImageMarkup(
              narrativePreviewContent.featuredImageJSON
            )
          }
          this.setState({
            narrativePreviewContent
          })
        }
        this.setState({
          isLoadingPreview: false,
          previewElapsedTimeMilliseconds:
            new Date().getTime() - this.previewStartDateTime.getTime()
        })
      })
      .catch(err => {
        console.error(`Error on PUT to /api/narrativepreview/feed-entry`, err)
        this.setState({
          isLoadingPreview: false
        })
      })
  }

  buildParagraphPreview(
    paragraph,
    debug = false,
    debugAllTriggers = false,
    ignoreKillswitch
  ) {
    this.setState({
      isLoadingPreview: true
    })
    const contentId = this.getContentId()
    const contentType = this.getContentType()
    const { model, modelTypeId, modelType, narrative } = this.state

    const queryParams = queryString.parse(this.props.location.search)

    put(
      `${globalSettings.apiBaseUrl}/api/narrativepreview/paragraph-preview`,
      null,
      {
        paragraph,
        contentId,
        contentType: contentId ? contentType : "",
        dataModelText: contentId ? "" : model,
        modelType,
        modelTypeId,
        narrativeId: narrative.id,
        debug,
        debugAllTriggers,
        ignoreKillswitch,
        itemIndex: parseInt(queryParams.itemindex, 10)
      }
    )
      .then(Response => Response && Response.ok === true && Response.json())
      .then(feed => {
        let narrativePreviewContent = feed.content
        if (narrativePreviewContent) {
          this.setState({
            narrativePreviewContent,
            isLoadingPreview: false
          })
        }
      })
  }

  hideRenderScheduleConfirmation() {
    this.setState({
      isRenderScheduleConfirmationOpen: false
    })
  }

  validateRenderCreation() {
    const contentId = this.getContentId()
    const contentType = this.getContentType()
    const {
      startDateTime,
      endDateTime,
      narrative,
      outputFeedId,
      useRecurrenceSettings
    } = this.state
    const narrativeId = narrative.id
    if (!contentId || !contentType) {
      this.setState({
        alertMessage: "Select a narrative data model prior to scheduling.",
        isAlertMessageOpen: true
      })
      return
    }
    let outputFeed = this.state.feeds.find(feed => feed.id === outputFeedId)

    if (!outputFeed || !narrativeId) {
      this.setState({
        alertMessage: `Choose a feed.`,
        isAlertMessageOpen: true
      })
      return
    }
    if (
      startDateTime !== null &&
      startDateTime.getTime() + 60 * 1000 < new Date().getTime()
    ) {
      this.setState({
        startDateTime: new Date()
      })
      return
    }
    if (
      useRecurrenceSettings &&
      endDateTime !== null &&
      endDateTime.getTime() <
        (startDateTime !== null
          ? startDateTime.getTime()
          : new Date().getTime())
    ) {
      this.setState({
        endDateTime: this.state.startDateTime
      })
      return
    }
    this.setState({ isRenderScheduleConfirmationOpen: true })
  }

  calculateFutureOccurrences() {
    const { useRecurrenceSettings, maxFutureOccurrenceCount } = this.state
    let cronSyntax = this.state.cronSyntax
    let endDateTimeValue =
      this.state.endDateTime !== null
        ? this.state.endDateTime.toISOString()
        : null
    if (!this.state.useRecurrenceSettings) {
      cronSyntax = ""
      endDateTimeValue = ""
    }
    get(
      `${
        globalSettings.apiBaseUrl
      }/api/narrativerenderschedule/futureoccurrences?cronsyntax=${cronSyntax}&startdatetime=${
        this.state.startDateTime ? this.state.startDateTime.toISOString() : ""
      }&userecurrencesettings=${useRecurrenceSettings}&enddatetime=${endDateTimeValue}&maxFutureOccurrenceCount=${maxFutureOccurrenceCount}`
    )
      .then(Response => Response && Response.ok === true && Response.json())
      .then(result => {
        if (result && result.responseCode === 1000) {
          this.setState({
            futureOccurrences: result.content,
            futureOccurrencesMessage: ""
          })
        } else {
          this.setState({
            futureOccurrences: null,
            futureOccurrencesMessage: `There was a problem described as ${result.responseMessage}.`
          })
        }
      })
  }

  changeName(event) {
    this.setState({ narrativeName: event.target.value })
  }

  changeBody(event) {
    this.setState({ narrativeBody: event.target.value })
  }

  handleCategoryChange(categories) {
    this.setState(prevState => ({
      checkedCategories: categories,
      triggerTypes: prevState.triggerTypesBk.filter(tt =>
        categories.includes(tt.category)
      )
    }))
  }

  getTriggerTypeCategories(triggerTypes) {
    let triggerTypeList = []
    if (triggerTypes && triggerTypes.length > 0) {
      triggerTypes.forEach(tt => {
        if (tt.category && tt.category !== "") {
          triggerTypeList.push(tt.category)
        }
      })
    }
    return Object.keys(groupCollectionBy(triggerTypeList.sort(), t => t))
  }

  fetchNarrativeModelTriggerTypes() {
    this.setState({ triggersLoaded: true })
    const { modelType, narrative } = this.state
    const modelTypeShortName =
      modelType && modelType.includes(".")
        ? modelType.split(".").pop()
        : modelType
    let modelCategory = modelTypeShortName || ""

    if (modelType === "QueryBuilder") {
      modelCategory = `QueryBuilder${narrative.queryId}`
    }

    get(`${globalSettings.apiBaseUrl}/api/trigger`)
      .then(Response => Response.json())
      .then(triggerTypes => {
        const categories = this.getTriggerTypeCategories(triggerTypes)
        let checkedCategories = categories
        if (modelCategory) {
          checkedCategories = categories.filter(category =>
            category.includes(modelCategory)
          )
        }

        this.setState({
          triggerTypesBk: triggerTypes,
          triggerTypes: triggerTypes.filter(tt =>
            checkedCategories.includes(tt.category)
          ),
          triggerCategories: categories,
          checkedCategories
        })
      })
  }

  handlePlayerIdChange(event) {
    this.setState({ playerId: event.target.value })
  }

  handleQueryResultsIndexRangeChange(event) {
    this.setState({ queryResultsIndexRange: event.target.value })
  }

  handleIndexChangeEvent(event) {
    this.handleIndexChange(event.target.value)
  }

  handleIndexChange(itemIndex) {
    this.setState({ itemIndex })
    const searchParams = queryString.parse(this.props.location.search)
    let newSearch = ""
    const oldIndex = searchParams.itemindex
    if (oldIndex) {
      newSearch = search_remove_all("itemindex")
      this.props.history.push({ search: newSearch })
    }
    newSearch = search_add("itemindex", itemIndex)
    this.props.history.push({
      search: newSearch
    })
  }

  //Builds a render instructions object for trigger previews
  triggerRenderInstructions = () => {
    const contentId = this.getContentId()
    const contentType = this.getContentType()
    const { narrative, model, modelType, modelTypeId } = this.state
    if (!model && !contentId && !modelType) {
      alert("Missing Model Info")
      return
    }
    return {
      narrativeId: narrative.id,
      contentId,
      contentType,
      dataModelText: contentId ? "" : model,
      modelType: modelType || narrative.modelType,
      modelTypeId: modelTypeId || narrative.ModelTypeId,
      ignoreKillswitch: true
    }
  }

  evaluateTriggers = () => {
    const renderInstructions = this.triggerRenderInstructions()
    this.props.evaluateTriggersMetadata(renderInstructions)
  }
  updateTriggers = () => {
    const renderInstructions = this.triggerRenderInstructions()
    this.props.updateTriggersMetadata(renderInstructions)
  }

  onDataModelTextAreaChange(e) {
    this.setState({
      model: e.target.value,
      modelSnapshotId: null,
      selectedMatchId: null,
      selectedMatchIds: [],
      selectedAssetId: null,
      selectedQueryId: null,
      selectedPlayerId: null
    })
  }

  processBulkMessage(msg) {
    let saving = {}

    let saved = {}

    let failed = {}

    let savedLength = 0

    let savingLength = 0

    let failedLength = 0

    let tmpBulkState = this.state.bulkAlertMessage

    let failedArray = []
    Object.keys(msg).forEach(function (key) {
      if (!isNaN(key)) {
        tmpBulkState[key] = tmpBulkState[key] ? tmpBulkState[key] : {}
        tmpBulkState[key][msg[key]] = msg.type ? msg.type : true
      }
    })
    Object.keys(tmpBulkState).forEach(function (key) {
      if (!isNaN(key)) {
        if (tmpBulkState[key].saving) {
          saving[key] = true
          savingLength += 1
        }
        if (tmpBulkState[key].saved) {
          saved[key] = true
          savedLength += 1
        }
        if (tmpBulkState[key].failed) {
          failed[key] = tmpBulkState[key].failed
          failedLength += 1
          failedArray.push(
            JSON.parse(
              `{"id" :"${key}", "error":"${tmpBulkState[key].failed}"}`
            )
          )
        }
      }
    })
    tmpBulkState.failed = failedArray
    tmpBulkState.progress = parseInt((savedLength / savingLength) * 100)

    tmpBulkState.totals = {
      saved: savedLength,
      saving: savingLength,
      failed: failedLength,
      finished: savingLength - (savedLength + failedLength) === 0
    }
    this.setState({
      bulkAlertMessage: tmpBulkState,
      bulkAlertMessageActive: true
    })
  }

  filterParagraphs(paragraphs, triggerType, viewKey) {
    let filteredParagraphs = []
    let filteredSentences = []
    let contentBlocks = []
    if (paragraphs && paragraphs.length > 0) {
      paragraphs.forEach(paragraph => {
        let p = paragraph
        if (p.triggerType && p.triggerType !== null) {
          p.triggerType = p.triggerType.replace("news_", "")
        }
        if (p.triggerType === triggerType) {
          filteredParagraphs.push(p)
        } else {
          if (viewKey === "newstriggers") {
            if (p.sentences && p.sentences.length > 0) {
              p.sentences.forEach(sentence => {
                let s = sentence
                if (s.triggerType && s.triggerType !== null) {
                  s.triggerType = s.triggerType.replace("news_", "")
                }
                if (s.triggerType === triggerType) {
                  filteredSentences.push(s)
                }
                if (filteredSentences.length > 0) {
                  p.sentences = filteredSentences
                  filteredParagraphs.push(p)
                }
              })
            }
          } else {
            let filteredSentences = p.sentences.filter(
              s => s.triggerType === triggerType
            )
            if (filteredSentences && filteredSentences.length > 0) {
              p.sentences = filteredSentences
              filteredParagraphs.push(p)
            }
          }
        }
      })
    }
    contentBlocks = groupCollectionBy(
      filteredParagraphs,
      p => p.contentBlock && p.contentBlock.toLowerCase()
    )
    return contentBlocks
  }

  createBlockElements(contentBlocks, toolbarTitle) {
    const { classes } = this.props
    const { narrative } = this.state
    const { libraryParagraphs, paragraphs, outlineBlocks } = narrative
    if (Object.keys(contentBlocks).length < 1) {
      return "No content found"
    }
    const accordionItems =
      contentBlocks &&
      Object.keys(contentBlocks).map(key =>
        // key: the name of the object key
        // index: the ordinal position of the key within the object
        ({
          key: key,
          title: `   <${contentBlocks[key][0].contentBlock} />`,
          content: contentBlocks[key].map(paragraph => (
            <Paragraph
              key={`${paragraph.id}`}
              editParagraph={false}
              paragraph={paragraph}
              handleReload={viewKey => this.reloadData(viewKey)}
              paragraphs={paragraphs}
              updateNarrativeParagraphs={paragraph =>
                this.updateParagraphs(paragraph)
              }
              outlineBlocks={outlineBlocks}
              libraryParagraphs={libraryParagraphs}
              ownerListForOrg={this.state.ownerList}
              toggleViewFromExplorer={this.state.toggleViewFromExplorer}
              primaryTags={this.state.primaryTags}
            />
          ))
        })
      )

    return (
      <div>
        {toolbarTitle && toolbarTitle !== "" && (
          <AppBar position="sticky" color="default" enableColorOnDark>
            <Toolbar className={classes.toolbar}>
              <Typography className={classes.title} variant="subtitle2" noWrap>
                {toolbarTitle}
              </Typography>
            </Toolbar>
          </AppBar>
        )}
        <Accordion collapses={accordionItems} />
      </div>
    )
  }

  getBlockSummary(blocks) {
    const blockCount = Object.keys(blocks).length
    let paragraphCount = 0
    let sentenceCount = 0
    Object.keys(blocks).forEach(blockGroup => {
      blocks[blockGroup].forEach(p => {
        paragraphCount += 1
        if (p.sentences && p.sentences.length > 0) {
          sentenceCount += p.sentences.length
        }
      })
    })
    return { blockCount, paragraphCount, sentenceCount }
  }

  getActiveView(activeView, narrative, placeholderId) {
    let activeViews
    placeholderId = placeholderId || "0"
    if (typeof activeView === "string") {
      activeViews = activeView.split(",")
    } else {
      activeViews = activeView
    }

    let multiActiveView = ""
    activeViews.forEach(view => {
      if (view.match(/sentenceid|triggerid/)) {
        multiActiveView += (multiActiveView ? "," : "") + view
      }
    })
    this.setState({ multiActiveView: multiActiveView })

    const { classes } = this.props
    const {
      searchInTrigger,
      searchInName,
      searchInTemplate,
      searchBlockIds,
      paragraphTags
    } = this.state
    const blockIds = searchBlockIds ? searchBlockIds.split(",") : []

    const { triggers, libraryParagraphs, outlineBlocks, leagueId, verticalId } =
      narrative
    let { paragraphs } = narrative
    paragraphs.forEach(para => {
      let primaryTagId = null
      const secondaryTagIds = []
      let formFactorTagId = null
      const thirdPartyTagIds = []

      if (paragraphTags && paragraphTags[para.id]) {
        paragraphTags[para.id].forEach(tags => {
          switch (tags.tagTypeId) {
            case 1:
              primaryTagId = tags.tagId
              break
            case 2:
              secondaryTagIds.push(tags.tagId)
              break
            case 3:
              formFactorTagId = tags.tagId
              break
            case 4:
              thirdPartyTagIds.push(tags.tagId)
              break
            default:
              break
          }
        })

        para.primaryTagId = primaryTagId
        para.secondaryTagIds = secondaryTagIds
        para.formFactorTagId = formFactorTagId
        para.thirdPartyTagIds = thirdPartyTagIds
      }
    })
    const paragraphsCopy = paragraphs && JSON.parse(JSON.stringify(paragraphs))
    const libraryBlocks =
      libraryParagraphs &&
      groupCollectionBy(
        libraryParagraphs,
        p => p.contentBlock && p.contentBlock.toLowerCase()
      )
    const contentBlocks =
      paragraphsCopy &&
      groupCollectionBy(paragraphsCopy, p => p.contentBlock?.toLowerCase())
    let contentBlockElements = []
    for (const element of activeViews) {
      let view = ""
      try {
        view = decodeURIComponent(element)
      } catch (error) {
        view = element
      }
      const viewKeyArray = view.includes("-") ? view.split("-") : [view]
      const itemType = viewKeyArray[0]
      const itemId = viewKeyArray.length > 1 ? viewKeyArray[1] : view
      const blockId = viewKeyArray.length > 2 ? viewKeyArray[2] : itemId
      const outlineBlock =
        (blockId &&
          outlineBlocks &&
          outlineBlocks.find(ob => ob.id === blockId)) ||
        {}

      const newModelTrigger = {
        narrative_Id: narrative.id,
        narrativeModelType: "",
        key: "",
        name: "",
        description: `${narrative.name} News Trigger`,
        rank: 0,
        formula: "",
        option: "",
        isNews: false,
        isActive: true,
        status_Id: 3 // published
      }

      let toolbarTitle = ""

      switch (itemType) {
        case "content": {
          toolbarTitle = `All Blocks`
          // Load the contentblock we're filtering on and output all its paragraphs
          contentBlockElements = (
            <ParagraphTable
              title={toolbarTitle}
              narrative={narrative}
              paragraphs={paragraphsCopy}
              outlineBlocks={outlineBlocks}
              updateNarrativeParagraphs={paragraph =>
                this.updateParagraphs(paragraph)
              }
              bulkAlertMessage={val => {
                this.processBulkMessage(val)
              }}
              isAlertActive={() => {
                this.setState({ bulkAlertMessageActive: true })
              }}
              hiddenColumns={["paragraphVariationScore"]}
              isLibrary={narrative.isLibrary}
              linkId
              primaryTags={this.state.primaryTags}
              secondaryTags={this.state.secondaryTags}
              formFactorTags={this.state.formFactorTags}
              thirdPartySpecificTags={this.state.thirdPartySpecificTags}
            />
          )
          break
        }
        case "library_content": {
          toolbarTitle = `All Library Blocks`
          // Load the contentblock we're filtering on and output all its paragraphs
          contentBlockElements = (
            <ParagraphTable
              title={toolbarTitle}
              narrative={narrative}
              paragraphs={libraryParagraphs}
              bulkAlertMessage={val => {
                this.processBulkMessage(val)
              }}
              isAlertActive={() => {
                this.setState({ bulkAlertMessageActive: true })
              }}
              hiddenColumns={[
                "filterBy",
                "libraryParagraph_Id",
                "libraryBlockPositionsFilter",
                "paragraphVariationScore"
              ]}
              isLibrary={narrative.isLibrary}
              linkId
              primaryTags={this.state.primaryTags}
              secondaryTags={this.state.secondaryTags}
              formFactorTags={this.state.formFactorTags}
              thirdPartySpecificTags={this.state.thirdPartySpecificTags}
            />
          )
          break
        }
        case "element": {
          if (contentBlocks[itemId]) {
            toolbarTitle = `<${itemId} /> Element`
            // Load the contentblock we're filtering on and output all its paragraphs
            contentBlockElements = (
              <ParagraphTable
                title={toolbarTitle}
                narrative={narrative}
                updateNarrativeParagraphs={paragraph =>
                  this.updateParagraphs(paragraph)
                }
                paragraphs={[...(contentBlocks[itemId] || [])]}
                outlineBlocks={outlineBlocks}
                bulkAlertMessage={val => {
                  this.processBulkMessage(val)
                }}
                isAlertActive={() => {
                  this.setState({ bulkAlertMessageActive: true })
                }}
                hiddenColumns={
                  itemId !== "content"
                    ? ["paragraphType", "paragraphVariationScore"]
                    : ["paragraphVariationScore"]
                }
                isLibrary={narrative.isLibrary}
                linkId
                primaryTags={this.state.primaryTags}
                secondaryTags={this.state.secondaryTags}
                formFactorTags={this.state.formFactorTags}
                thirdPartySpecificTags={this.state.thirdPartySpecificTags}
              />
            )
          } else {
            toolbarTitle = `<${itemId} /> Element Not Found`
            // Load the contentblock we're filtering on and output all its paragraphs
            contentBlockElements = (
              <AppBar position="sticky" color="default">
                <Toolbar className={classes.toolbar}>
                  <Typography
                    className={classes.title}
                    variant="subtitle2"
                    noWrap
                  >
                    {toolbarTitle}
                  </Typography>
                </Toolbar>
              </AppBar>
            )
          }
          break
        }
        case "output_element": {
          if (contentBlocks[itemId]) {
            toolbarTitle = `<${itemId} /> Library Element`
            // Load the contentblock we're filtering on and output all its paragraphs
            contentBlockElements = (
              <ParagraphTable
                title={toolbarTitle}
                narrative={narrative}
                paragraphs={[...(contentBlocks[itemId] || [])]}
                bulkAlertMessage={val => {
                  this.processBulkMessage(val)
                }}
                isLibrary={narrative.isLibrary}
                isAlertActive={() => {
                  this.setState({ bulkAlertMessageActive: true })
                }}
                hiddenColumns={
                  itemId !== "content"
                    ? [
                        "paragraphType",
                        "filterBy",
                        "libraryParagraph_Id",
                        "libraryBlockPositionsFilter",
                        "paragraphVariationScore"
                      ]
                    : [
                        "filterBy",
                        "libraryParagraph_Id",
                        "libraryBlockPositionsFilter",
                        "paragraphVariationScore"
                      ]
                }
                linkId
                primaryTags={this.state.primaryTags}
                secondaryTags={this.state.secondaryTags}
                formFactorTags={this.state.formFactorTags}
                thirdPartySpecificTags={this.state.thirdPartySpecificTags}
              />
            )
          } else {
            toolbarTitle = `<${itemId} /> Library Element Not Found`
            // Load the contentblock we're filtering on and output all its paragraphs
            contentBlockElements = (
              <AppBar position="sticky" color="default">
                <Toolbar className={classes.toolbar}>
                  <Typography
                    className={classes.title}
                    variant="subtitle2"
                    noWrap
                  >
                    {toolbarTitle}
                  </Typography>
                </Toolbar>
              </AppBar>
            )
          }
          break
        }
        case "library_element": {
          if (contentBlocks[itemId]) {
            toolbarTitle = `<${itemId} /> Library Element`
            // Load the contentblock we're filtering on and output all its paragraphs
            contentBlockElements = (
              <ParagraphTable
                title={toolbarTitle}
                narrative={narrative}
                paragraphs={[...(libraryBlocks[itemId] || [])]}
                bulkAlertMessage={val => {
                  this.processBulkMessage(val)
                }}
                isLibrary={narrative.isLibrary}
                isAlertActive={() => {
                  this.setState({ bulkAlertMessageActive: true })
                }}
                hiddenColumns={
                  itemId !== "content"
                    ? [
                        "paragraphType",
                        "filterBy",
                        "libraryParagraph_Id",
                        "libraryBlockPositionsFilter",
                        "paragraphVariationScore"
                      ]
                    : [
                        "filterBy",
                        "libraryParagraph_Id",
                        "libraryBlockPositionsFilter",
                        "paragraphVariationScore"
                      ]
                }
                linkId
                primaryTags={this.state.primaryTags}
                secondaryTags={this.state.secondaryTags}
                formFactorTags={this.state.formFactorTags}
                thirdPartySpecificTags={this.state.thirdPartySpecificTags}
              />
            )
          } else {
            toolbarTitle = `<${itemId} /> Library Element Not Found`
            // Load the contentblock we're filtering on and output all its paragraphs
            contentBlockElements = (
              <AppBar position="sticky" color="default">
                <Toolbar className={classes.toolbar}>
                  <Typography
                    className={classes.title}
                    variant="subtitle2"
                    noWrap
                  >
                    {toolbarTitle}
                  </Typography>
                </Toolbar>
              </AppBar>
            )
          }
          break
        }
        case "createparagraph":
          if (itemId) {
            toolbarTitle = `New <${itemId} /> Block`
          } else {
            toolbarTitle = `New Element`
          }

          // Display the sentence control
          contentBlockElements.push(
            <div key="create-paragraph">
              <AppBar position="sticky" color="default">
                <Toolbar className={classes.toolbar}>
                  <Typography
                    className={classes.title}
                    variant="subtitle2"
                    noWrap
                  >
                    {toolbarTitle}
                  </Typography>
                </Toolbar>
              </AppBar>
              <Paragraph
                triggers={triggers}
                createMode={true}
                createParagraph={true}
                handleReload={viewKey => this.reloadData(viewKey)}
                libraryParagraphs={libraryParagraphs}
                paragraphs={paragraphs}
                outlineBlocks={outlineBlocks}
                updateNarrativeParagraphs={paragraph =>
                  this.updateParagraphs(paragraph)
                }
                paragraph={{
                  narrative_Id: narrative.id,
                  contentBlock: itemId,
                  position:
                    outlineBlock.position ||
                    (paragraphs && paragraphs.length + 1) ||
                    0,
                  narrativeOutlineBlockId: outlineBlock.id,
                  name: outlineBlock.contentSection
                }}
                ownerListForOrg={this.state.ownerList}
                toggleViewFromExplorer={this.state.toggleViewFromExplorer}
                primaryTags={this.state.primaryTags}
                secondaryTags={this.state.secondaryTags}
                formFactorTags={this.state.formFactorTags}
                thirdPartySpecificTags={this.state.thirdPartySpecificTags}
              />
            </div>
          )
          break
        case "paragraphid": {
          toolbarTitle = `Paragraph ${itemId}`
          const paragraphBlocks = []
          contentBlocks &&
            Object.keys(contentBlocks).forEach((key, index) => {
              // Loop through the content blocks and load the paragraph we've fitered on
              contentBlocks[key].forEach((paragraph, key) => {
                if (paragraph.id === Number.parseInt(itemId)) {
                  toolbarTitle = `${paragraph.contentBlock} Element > `
                  paragraphBlocks.push(
                    <div key={`pId-${paragraph.id}`}>
                      <AppBar position="sticky" color="default">
                        <Toolbar className={classes.toolbar}>
                          <Typography
                            className={classes.title}
                            variant="subtitle2"
                            noWrap
                          >
                            {toolbarTitle}
                            <a
                              href={`/portal/narrative/${narrative.id}/edit?view=paragraphid-${paragraph.id}`}
                              style={{
                                textDecoration: "underline"
                              }}
                              onClick={e => {
                                e.preventDefault()
                              }}
                              title={`Go to Paragraph ${paragraph.id}`}
                            >
                              {`Paragraph ${paragraph.id}`}
                            </a>
                          </Typography>
                        </Toolbar>
                      </AppBar>
                      <Paragraph
                        triggers={triggers}
                        key={`${paragraph.id}-${key}-${index}-${paragraph.sentences.length}`}
                        updateNarrativeParagraphs={paragraph =>
                          this.updateParagraphs(paragraph)
                        }
                        showSentences={true}
                        paragraph={paragraph}
                        paragraphs={paragraphs}
                        outlineBlocks={outlineBlocks}
                        libraryParagraphs={libraryParagraphs}
                        handleReload={viewKey => this.reloadData(viewKey)}
                        ownerListForOrg={this.state.ownerList}
                        toggleViewFromExplorer={
                          this.state.toggleViewFromExplorer
                        }
                        isPreviewDrawerOpen={this.state.isPreviewDrawerOpen}
                        togglePreviewDrawer={this.togglePreviewDrawer}
                        primaryTags={this.state.primaryTags}
                        secondaryTags={this.state.secondaryTags}
                        formFactorTags={this.state.formFactorTags}
                        thirdPartySpecificTags={
                          this.state.thirdPartySpecificTags
                        }
                      />
                    </div>
                  )
                }
              })
            })
          contentBlockElements.push(paragraphBlocks)
          break
        }
        case "outlineparagraphid": {
          toolbarTitle = `Paragraph ${itemId}`
          const outlineParagraphBlocks = []
          contentBlocks &&
            Object.keys(contentBlocks).forEach((key, index) => {
              // Loop through the content blocks and load the paragraph we've fitered on
              contentBlocks[key].forEach((paragraph, nestedKey) => {
                if (paragraph.id === Number.parseInt(itemId)) {
                  toolbarTitle = `${paragraph.contentBlock} Element > `
                  outlineParagraphBlocks.push(
                    <div key={`pId-${paragraph.id}`}>
                      <AppBar position="sticky" color="default">
                        <Toolbar className={classes.toolbar}>
                          <Typography
                            className={classes.title}
                            variant="subtitle2"
                            noWrap
                          >
                            {toolbarTitle}
                            <a
                              href={`/portal/narrative/${narrative.id}/edit?view=paragraphid-${paragraph.id}`}
                              style={{
                                textDecoration: "underline"
                              }}
                              onClick={e => {
                                e.preventDefault()
                              }}
                              title={`Go to Paragraph ${paragraph.id}`}
                            >
                              {`Paragraph ${paragraph.id}`}
                            </a>
                          </Typography>
                        </Toolbar>
                      </AppBar>
                      <Paragraph
                        triggers={triggers}
                        key={`${paragraph.id}-${nestedKey}-${index}-${
                          paragraph.sentences && paragraph.sentences.length
                        }`}
                        updateNarrativeParagraphs={paragraph =>
                          this.updateParagraphs(paragraph)
                        }
                        showSentences={true}
                        paragraph={paragraph}
                        paragraphs={paragraphs}
                        outlineBlocks={outlineBlocks}
                        libraryParagraphs={libraryParagraphs}
                        handleReload={viewKey => this.reloadData(viewKey)}
                        ownerListForOrg={this.state.ownerList}
                        toggleViewFromExplorer={
                          this.state.toggleViewFromExplorer
                        }
                        isPreviewDrawerOpen={this.state.isPreviewDrawerOpen}
                        togglePreviewDrawer={this.togglePreviewDrawer}
                        hidePosition
                        primaryTags={this.state.primaryTags}
                      />
                    </div>
                  )
                }
              })
            })
          contentBlockElements.push(outlineParagraphBlocks)
          break
        }
        case "library_paragraphid": {
          toolbarTitle = `Library Paragraph ${itemId}`
          const libraryParagraphBlocks = []
          libraryBlocks &&
            Object.keys(libraryBlocks).forEach((key, index) => {
              // Loop through the content blocks and load the paragraph we've fitered on
              libraryBlocks[key].forEach((paragraph, key) => {
                if (paragraph.id === Number.parseInt(itemId)) {
                  toolbarTitle = `${paragraph.contentBlock} Element`
                  libraryParagraphBlocks.push(
                    <div key={`lpId-${paragraph.id}`}>
                      <AppBar position="sticky" color="default">
                        <Toolbar className={classes.toolbar}>
                          <Typography
                            className={classes.title}
                            variant="subtitle2"
                            noWrap
                          >
                            {toolbarTitle}
                          </Typography>
                        </Toolbar>
                      </AppBar>
                      <Paragraph
                        triggers={triggers}
                        key={`${paragraph.id}-${key}-${index}-${
                          paragraph.sentences && paragraph.sentences.length
                        }`}
                        showSentences={true}
                        paragraph={paragraph}
                        handleReload={viewKey => this.reloadData(viewKey)}
                        ownerListForOrg={this.state.ownerList}
                        toggleViewFromExplorer={
                          this.state.toggleViewFromExplorer
                        }
                        primaryTags={this.state.primaryTags}
                        isLibraryParagraph={true}
                      />
                    </div>
                  )
                }
              })
            })
          contentBlockElements.push(libraryParagraphBlocks)
          break
        }
        case "createsentence":
          toolbarTitle = `Create Sentence For Paragraph ${itemId}`
          // Display the sentence control
          contentBlockElements.push(
            <div>
              <AppBar position="sticky" color="default">
                <Toolbar className={classes.toolbar}>
                  <Typography
                    className={classes.title}
                    variant="subtitle2"
                    noWrap
                  >
                    {toolbarTitle}
                  </Typography>
                </Toolbar>
              </AppBar>
              <Sentence
                triggers={triggers}
                handleReload={() => this.reloadData(`paragraphid-${itemId}`)}
                createMode={true}
                edit={true}
                toggleEdit={(a, savedValues, sid) => {
                  const viewKey = `paragraphid-${itemId}`
                  const newSavedValue = {
                    ...savedValues,
                    createdSentenceId: sid
                  }
                  this.updateNarrativeSentence(newSavedValue)
                  this.setState(
                    {
                      activeView: viewKey
                    },
                    () => {
                      this.changePage(viewKey)
                    }
                  )
                }}
                savedSentence={updatedSentence => {
                  this.updateNarrativeSentence(updatedSentence)
                }}
                libraryParagraphs={libraryParagraphs}
                isLibrary={narrative.isLibrary}
                sentence={{
                  narrative_Id: narrative.id,
                  paragraph_Id: itemId
                }}
                ownerListForOrg={this.state.ownerList}
                isToBeFilteredBy={
                  !narrative.isLibrary && narrative.libraryNarrative_Ids
                }
                toggleViewFromExplorer={this.state.toggleViewFromExplorer}
                orgId={this.state.organizationId || narrative.organization?.id}
                leagueId={leagueId}
                verticalId={verticalId}
              />
            </div>
          )
          break
        case "sentenceid":
          toolbarTitle = `Sentence ${itemId}`
          // Loop through the contentblocks and find the sentence
          // 5 Jan 2021 - 2 issues here:
          // #1 - The "key" parameter we used for the "sentenceid", "library_paragraphid", and "paragraphid" cases
          // were not unique enough and caused a problem becauese of #2
          // #2 - Turns out for "placeholder sentences", you often have sentence ids that are duplicated in different paragraphs,
          // which then means the loop below will select multiple sentences to put into "sentenceBlocks".
          // Before today, the loop through "contentBlocks" was a MAP which meant we returned each entry which when you had multiple matches
          // resulted in weird behavior - it would elimate one of the 2 previous matches but add the 2 new matches at the bottom
          // which meant your list would get longer AND you had to navigate down to get what you clicked.
          // Doing a FOREACH and then pushing the matching sentences into "sentenceBlocks" was a better solution
          const sentenceBlocks = []
          contentBlocks &&
            Object.keys(contentBlocks).forEach(key => {
              contentBlocks[key].forEach(paragraph => {
                for (let index in paragraph.sentences) {
                  const sentence = paragraph.sentences[index]
                  if (sentence.id === Number.parseInt(itemId)) {
                    //Set this sentence to the selected sentence
                    this.setState({
                      selectedSentence: sentence
                    })
                    sentenceBlocks.push(
                      <div key={`sId-${paragraph.id}-${itemId}`}>
                        <AppBar position="sticky" color="default">
                          <Toolbar className={classes.toolbar}>
                            <Typography
                              className={classes.title}
                              variant="subtitle2"
                              noWrap
                            >
                              {`${paragraph.contentBlock} Element > `}
                              <a
                                href={`/portal/narrative/${narrative.id}/edit?view=paragraphid-${paragraph.id}`}
                                style={{
                                  textDecoration: "underline"
                                }}
                                onClick={e => {
                                  e.preventDefault()
                                  let vk = `paragraphid-${paragraph.id}`
                                  this.setState({
                                    activeView: vk
                                  })
                                  this.changePage(vk)
                                }}
                                title="Go to Paragraph"
                              >
                                {`Paragraph ${paragraph.id}`}
                              </a>
                              {" > "}
                              <a
                                href={`/portal/narrative/${narrative.id}/edit?view=sentenceid-${itemId}`}
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "underline"
                                }}
                                onClick={e => {
                                  e.preventDefault()
                                }}
                                title="Go to Paragraph"
                              >
                                {`Sentence ${itemId}`}
                              </a>
                            </Typography>
                          </Toolbar>
                        </AppBar>
                        <Sentence
                          triggers={triggers}
                          key={`sentenceid-${itemId}`}
                          createMode={false}
                          edit={true}
                          contentBlock={paragraph.contentBlock}
                          toggleEdit={(a, savedValues) => {
                            this.updateNarrativeSentence(savedValues)
                            const viewKey = `sentenceid-${itemId}`
                            this.setState(
                              {
                                activeView: multiActiveView,
                                sentenceTransition: true
                              },
                              () => {
                                this.changePage(viewKey)
                              }
                            )
                          }}
                          savedSentence={updatedSentence => {
                            this.updateNarrativeSentence(updatedSentence)
                          }}
                          isLibrary={narrative.isLibrary}
                          sentence={sentence}
                          libraryParagraphs={libraryParagraphs}
                          ownerListForOrg={this.state.ownerList}
                          isToBeFilteredBy={
                            !narrative.isLibrary &&
                            narrative.libraryNarrative_Ids
                          }
                          toggleViewFromExplorer={
                            this.state.toggleViewFromExplorer
                          }
                          orgId={
                            this.state.organizationId ||
                            narrative.organization?.id
                          }
                          leagueId={leagueId}
                          verticalId={verticalId}
                        />
                      </div>
                    )
                  }
                }
              })
            })
          contentBlockElements.push(sentenceBlocks)
          this.setState({
            sentenceTransition: false
          })
          break

        case "outlinesentenceid":
          toolbarTitle = `Sentence ${itemId}`
          // Loop through the contentblocks and find the sentence
          // 5 Jan 2021 - 2 issues here:
          // #1 - The "key" parameter we used for the "sentenceid", "library_paragraphid", and "paragraphid" cases
          // were not unique enough and caused a problem becauese of #2
          // #2 - Turns out for "placeholder sentences", you often have sentence ids that are duplicated in different paragraphs,
          // which then means the loop below will select multiple sentences to put into "sentenceBlocks".
          // Before today, the loop through "contentBlocks" was a MAP which meant we returned each entry which when you had multiple matches
          // resulted in weird behavior - it would elimate one of the 2 previous matches but add the 2 new matches at the bottom
          // which meant your list would get longer AND you had to navigate down to get what you clicked.
          // Doing a FOREACH and then pushing the matching sentences into "sentenceBlocks" was a better solution
          const outlinesentenceBlocks = []
          contentBlocks &&
            Object.keys(contentBlocks).forEach(key => {
              contentBlocks[key].forEach(paragraph => {
                for (let index in paragraph.sentences) {
                  const sentence = paragraph.sentences[index]
                  if (sentence.id === Number.parseInt(itemId)) {
                    //Set this sentence to the selected sentence
                    this.setState({
                      selectedSentence: sentence
                    })
                    outlinesentenceBlocks.push(
                      <div key={`sId-${paragraph.id}-${itemId}`}>
                        <AppBar position="sticky" color="default">
                          <Toolbar className={classes.toolbar}>
                            <Typography
                              className={classes.title}
                              variant="subtitle2"
                              noWrap
                            >
                              {`${paragraph.contentBlock} Element > `}
                              <a
                                href={`/portal/narrative/${narrative.id}/edit?view=paragraphid-${paragraph.id}`}
                                style={{
                                  textDecoration: "underline"
                                }}
                                onClick={e => {
                                  e.preventDefault()
                                  let vk = `paragraphid-${paragraph.id}`
                                  this.setState({
                                    activeView: vk
                                  })
                                  this.changePage(vk)
                                }}
                                title="Go to Paragraph"
                              >
                                {`Paragraph ${paragraph.id}`}
                              </a>
                              {" > "}
                              <a
                                href={`/portal/narrative/${narrative.id}/edit?view=outlinesentenceid-${itemId}`}
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "underline"
                                }}
                                onClick={e => {
                                  e.preventDefault()
                                }}
                                title="Go to Paragraph"
                              >
                                {`Sentence ${itemId}`}
                              </a>
                            </Typography>
                          </Toolbar>
                        </AppBar>
                        <Sentence
                          triggers={triggers}
                          key={`sentenceid-${itemId}`}
                          createMode={false}
                          edit={true}
                          contentBlock={paragraph.contentBlock}
                          toggleEdit={(a, savedValues) => {
                            this.updateNarrativeSentence(savedValues)
                            const viewKey = `outlinesentenceid-${itemId}`
                            this.setState(
                              {
                                activeView: multiActiveView,
                                sentenceTransition: true
                              },
                              () => {
                                this.changePage(viewKey)
                              }
                            )
                          }}
                          savedSentence={updatedSentence => {
                            this.updateNarrativeSentence(updatedSentence)
                          }}
                          isLibrary={narrative.isLibrary}
                          sentence={sentence}
                          libraryParagraphs={libraryParagraphs}
                          ownerListForOrg={this.state.ownerList}
                          isToBeFilteredBy={
                            !narrative.isLibrary &&
                            narrative.libraryNarrative_Ids
                          }
                          toggleViewFromExplorer={
                            this.state.toggleViewFromExplorer
                          }
                          handleReload={() =>
                            this.reloadData(`outlinesentenceid-${itemId}`)
                          }
                          orgId={
                            this.state.organizationId ||
                            narrative.organization?.id
                          }
                          leagueId={leagueId}
                          verticalId={verticalId}
                        />
                      </div>
                    )
                  }
                }
              })
            })
          contentBlockElements.push(outlinesentenceBlocks)
          this.setState({
            sentenceTransition: false
          })
          break

        case "placeholderoutlinesentenceid":
          toolbarTitle = `Sentence ${itemId}`
          // Look at outlinesentence comments, this just adds a check for placeholder
          const placeholderoutlinesentenceBlocks = []
          contentBlocks &&
            Object.keys(contentBlocks).forEach(key => {
              contentBlocks[key].forEach(paragraph => {
                for (let index in paragraph.sentences) {
                  const sentence = paragraph.sentences[index]
                  if (
                    sentence.id === Number.parseInt(itemId) &&
                    sentence.importedBySentence_Id === Number(placeholderId)
                    //placeholderoutlinesentenceBlocks.length === 0
                  ) {
                    //Set this sentence to the selected sentence
                    this.setState({
                      selectedSentence: sentence
                    })
                    placeholderoutlinesentenceBlocks.push(
                      <div key={`sId-${paragraph.id}-${itemId}`}>
                        <AppBar position="sticky" color="default">
                          <Toolbar className={classes.toolbar}>
                            <Typography
                              className={classes.title}
                              variant="subtitle2"
                              noWrap
                            >
                              {`${paragraph.contentBlock} Element > `}
                              <a
                                href={`/portal/narrative/${narrative.id}/edit?view=outlineparagraphid-${paragraph.id}`}
                                style={{
                                  textDecoration: "underline"
                                }}
                                onClick={e => {
                                  e.preventDefault()
                                  let vk = `outlineparagraphid-${paragraph.id}`
                                  this.setState({
                                    activeView: vk
                                  })
                                  this.changePage(vk)
                                }}
                                title="Go to Outline Paragraph"
                              >
                                {`Paragraph ${paragraph.id}`}
                              </a>
                              {" > "}
                              <a
                                href={`/portal/narrative/${narrative.id}/edit?view=outlinesentenceid-${sentence.importedBySentence_Id}`}
                                style={{
                                  textDecoration: "underline"
                                }}
                                onClick={e => {
                                  e.preventDefault()
                                  let vk = `outlinesentenceid-${sentence.importedBySentence_Id}`
                                  this.setState({
                                    activeView: vk
                                  })
                                  this.changePage(vk)
                                }}
                                title="Go to Lib. Placeholder"
                              >
                                {`Lib. Placeholder ${sentence.importedBySentence_Id}`}
                              </a>
                              {" > "}
                              <a
                                href={`/portal/narrative/${narrative.id}/edit?view=sentenceid-${itemId}`}
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "underline"
                                }}
                                onClick={e => {
                                  e.preventDefault()
                                }}
                                title="Go to Sentence"
                              >
                                {`Sentence ${itemId}`}
                              </a>
                            </Typography>
                          </Toolbar>
                        </AppBar>
                        <Sentence
                          triggers={triggers}
                          key={`sentenceid-${itemId}`}
                          createMode={false}
                          edit={true}
                          contentBlock={paragraph.contentBlock}
                          toggleEdit={(a, savedValues) => {
                            this.updateNarrativeSentence(savedValues)
                            const viewKey = `placeholderoutlinesentenceid-${itemId}`
                            this.setState(
                              {
                                activeView: multiActiveView,
                                sentenceTransition: true
                              },
                              () => {
                                this.changePage(viewKey)
                              }
                            )
                          }}
                          savedSentence={updatedSentence => {
                            this.updateNarrativeSentence(updatedSentence)
                          }}
                          isLibrary={narrative.isLibrary}
                          sentence={sentence}
                          libraryParagraphs={libraryParagraphs}
                          ownerListForOrg={this.state.ownerList}
                          isToBeFilteredBy={
                            !narrative.isLibrary &&
                            narrative.libraryNarrative_Ids
                          }
                          toggleViewFromExplorer={
                            this.state.toggleViewFromExplorer
                          }
                          handleReload={() =>
                            this.reloadData(
                              `placeholderoutlinesentenceid-${itemId}`
                            )
                          }
                          orgId={
                            this.state.organizationId ||
                            narrative.organization?.id
                          }
                          leagueId={leagueId}
                          verticalId={verticalId}
                        />
                      </div>
                    )
                  }
                }
              })
            })
          contentBlockElements.push(placeholderoutlinesentenceBlocks)
          this.setState({
            sentenceTransition: false
          })
          break

        case "library_sentenceid": {
          toolbarTitle = `Sentence ${itemId}`
          // Loop through the contentblocks and find the sentence
          const librarySentenceBlocks = []
          libraryBlocks &&
            Object.keys(libraryBlocks).forEach(key => {
              libraryBlocks[key].forEach(paragraph => {
                for (let index in paragraph.sentences) {
                  const sentence = paragraph.sentences[index]
                  if (sentence.id === Number.parseInt(itemId)) {
                    toolbarTitle = `${paragraph.contentBlock} Element > Paragraph ${paragraph.id} > Sentence ${itemId}`
                    librarySentenceBlocks.push(
                      <div key={`lsId:${paragraph.id}-${itemId}`}>
                        <AppBar position="sticky" color="default">
                          <Toolbar className={classes.toolbar}>
                            <Typography
                              className={classes.title}
                              variant="subtitle2"
                              noWrap
                            >
                              {toolbarTitle}
                            </Typography>
                          </Toolbar>
                        </AppBar>
                        <Sentence
                          triggers={triggers}
                          key={`sentenceid-${itemId}`}
                          createMode={false}
                          edit={true}
                          toggleEdit={(a, savedValues) => {
                            this.updateNarrativeSentence(savedValues)
                            const viewKey = `library_sentenceid-${itemId}`
                            this.setState(
                              {
                                activeView: viewKey,
                                updateInfo: savedValues
                              },
                              () => {
                                this.changePage(viewKey)
                              }
                            )
                          }}
                          savedSentence={updatedSentence => {
                            this.updateNarrativeSentence(updatedSentence)
                          }}
                          isLibrary={narrative.isLibrary}
                          sentence={sentence}
                          toggleViewFromExplorer={
                            this.state.toggleViewFromExplorer
                          }
                          orgId={
                            this.state.organizationId ||
                            narrative.organization?.id
                          }
                          leagueId={leagueId}
                          verticalId={verticalId}
                        />
                      </div>
                    )
                  }
                }
              })
            })
          contentBlockElements.push(librarySentenceBlocks)
          break
        }
        case "insighttriggers": {
          toolbarTitle = "Insight Triggers"
          const insightTriggers =
            (
              triggers &&
              triggers.filter(
                t =>
                  (t.isNews === null ||
                    t.isNews === false ||
                    t.isNews === "false") &&
                  (t.isDependency === null ||
                    t.isDependency === false ||
                    t.isDependency === "false")
              )
            ).sort((a, b) => b.id - a.id) || []
          // Load the contentblock we're filtering on and output all its paragraphs
          contentBlockElements = (
            <TriggerTable
              title={toolbarTitle}
              narrative={narrative}
              triggers={insightTriggers}
              bulkAlertMessage={val => {
                this.processBulkMessage(val)
              }}
              isAlertActive={() => {
                this.setState({ bulkAlertMessageActive: true })
              }}
            />
          )
          break
        }
        case "dependencies": {
          toolbarTitle = "Dependencies"
          const dependencies =
            (triggers &&
              triggers.filter(
                t =>
                  t.isDependency !== null &&
                  (t.isDependency === true || t.isDependency === "false")
              )) ||
            []
          // Load the contentblock we're filtering on and output all its paragraphs
          contentBlockElements = (
            <TriggerTable
              title={toolbarTitle}
              narrative={narrative}
              triggers={dependencies}
              bulkAlertMessage={val => {
                this.processBulkMessage(val)
              }}
              isAlertActive={() => {
                this.setState({ bulkAlertMessageActive: true })
              }}
            />
          )
          break
        }
        case "createinsight": {
          toolbarTitle = `New Trigger`
          const newInsightTrigger = {
            ...newModelTrigger,
            isNews: false,
            isDependency: false,
            description: `${narrative.name} Insight Trigger`
          }
          contentBlockElements = (
            <Trigger
              edit={true}
              trigger={newInsightTrigger}
              handleCancel={() => {
                const viewKey = "insighttriggers"
                this.setState({ activeView: multiActiveView }, () => {
                  this.fetchNarrativeInfo(narrative.id)
                  this.changePage(viewKey)
                })
              }}
              handleReload={viewKey => this.reloadData(viewKey)}
              toggleViewFromExplorer={this.state.toggleViewFromExplorer}
            />
          )
          break
        }
        case "createdependency": {
          toolbarTitle = `New Dependency`
          const newDependencyTrigger = {
            ...newModelTrigger,
            isNews: false,
            isDependency: true,
            description: `${narrative.name} Dependency`
          }

          contentBlockElements = (
            <Trigger
              edit={true}
              trigger={newDependencyTrigger}
              handleCancel={() => {
                const viewKey = "dependencies"
                this.setState({ activeView: viewKey }, () => {
                  this.fetchNarrativeInfo(narrative.id)
                  this.changePage(viewKey)
                })
              }}
              handleReload={viewKey => this.reloadData(viewKey)}
              toggleViewFromExplorer={this.state.toggleViewFromExplorer}
            />
          )
          break
        }
        case "triggerid":
        case "newstriggerid": {
          const trigger =
            triggers && triggers.find(t => t.id === Number.parseInt(itemId))
          if (trigger) {
            let viewKey = "insighttriggers"
            if (trigger.isDependency) {
              toolbarTitle = `Dependency ${itemId}`
              viewKey = "dependencytriggers"
            } else if (trigger.isNews) {
              toolbarTitle = `News Trigger ${itemId}`
              viewKey = "newstriggers"
            } else {
              toolbarTitle = `Trigger ${itemId}`
            }

            const triggerElement = (
              <Trigger
                edit={true}
                handleCancel={() => {
                  this.setState({ activeView: multiActiveView }, () => {
                    this.fetchNarrativeInfo(narrative.id)
                    this.changePage(viewKey)
                  })
                }}
                savedTrigger={() => {
                  this.setState({ triggerSaved: true })
                }}
                ownerList={this.state.ownerList}
                triggerId={trigger.id}
                handleReload={() => this.reloadData(this.state.multiActiveView)}
                toggleViewFromExplorer={this.state.toggleViewFromExplorer}
              />
            )

            const filteredNarrativeParagraphs = this.filterParagraphs(
              paragraphsCopy,
              trigger.type,
              viewKey
            )

            const narrativeBlockElements = this.createBlockElements(
              filteredNarrativeParagraphs
            )

            const narrativeBlockSummary = this.getBlockSummary(
              filteredNarrativeParagraphs
            )

            const narrativeParagraphTable = (
              <ParagraphTable
                title={`Narrative related to ${trigger.type}`}
                narrative={narrative}
                paragraphs={[
                  {
                    narrative_Id: narrative.id,
                    position: (paragraphs && paragraphs.length + 1) || 1,
                    triggerType: trigger.type
                  }
                ]}
                updateNarrativeParagraphs={paragraph =>
                  this.updateParagraphs(paragraph)
                }
                bulkAlertMessage={val => {
                  this.processBulkMessage(val)
                }}
                isAlertActive={() => {
                  this.setState({ bulkAlertMessageActive: true })
                }}
                hiddenColumns={["paragraphType", "paragraphVariationScore"]}
                linkId
                primaryTags={this.state.primaryTags}
                secondaryTags={this.state.secondaryTags}
                formFactorTags={this.state.formFactorTags}
                thirdPartySpecificTags={this.state.thirdPartySpecificTags}
              />
            )

            const filteredLibraryParagraphs = this.filterParagraphs(
              libraryParagraphs,
              trigger.type,
              viewKey
            )

            const libraryBlockElements = this.createBlockElements(
              filteredLibraryParagraphs
            )

            const libraryBlockSummary = this.getBlockSummary(
              filteredLibraryParagraphs
            )

            const libraryParagraphTable = (
              <ParagraphTable
                title={`Narrative related to ${trigger.type}`}
                narrative={narrative}
                paragraphs={[
                  {
                    narrative_Id: narrative.id,
                    position: (paragraphs && paragraphs.length + 1) || 1,
                    triggerType: trigger.type
                  }
                ]}
                bulkAlertMessage={val => {
                  this.processBulkMessage(val)
                }}
                isLibrary
                isAlertActive={() => {
                  this.setState({ bulkAlertMessageActive: true })
                }}
                hiddenColumns={[
                  "paragraphType",
                  "filterBy",
                  "libraryParagraph_Id",
                  "libraryBlockPositionsFilter",
                  "paragraphVariationScore"
                ]}
                linkId
                primaryTags={this.state.primaryTags}
                secondaryTags={this.state.secondaryTags}
                formFactorTags={this.state.formFactorTags}
                thirdPartySpecificTags={this.state.thirdPartySpecificTags}
              />
            )

            const accordion =
              libraryParagraphs && libraryParagraphs.length > 0 ? (
                <Accordion
                  collapses={[
                    {
                      title: `Related Narrative Paragraph Table`,
                      content: (
                        <div style={{ margin: "10px" }}>
                          {narrativeParagraphTable}
                        </div>
                      )
                    },
                    {
                      title: `Related Narrative Content Blocks: ${narrativeBlockSummary.blockCount} Element(s), ${narrativeBlockSummary.paragraphCount} Paragraph(s), ${narrativeBlockSummary.sentenceCount} Sentence(s) Created`,
                      content: (
                        <div style={{ margin: "10px" }}>
                          {narrativeBlockElements}
                        </div>
                      )
                    },
                    {
                      title: `Related Library Paragraph Table`,
                      content: (
                        <div style={{ margin: "10px" }}>
                          {libraryParagraphTable}
                        </div>
                      )
                    },
                    {
                      title: `Related Library Content Blocks: ${libraryBlockSummary.blockCount} Element(s), ${libraryBlockSummary.paragraphCount} Paragraph(s), ${libraryBlockSummary.sentenceCount} Sentence(s) Created`,
                      content: (
                        <div style={{ margin: "10px" }}>
                          {libraryBlockElements}
                        </div>
                      )
                    }
                  ]}
                />
              ) : (
                <Accordion
                  collapses={[
                    {
                      title: `Related Narrative Paragraph Table`,
                      content: (
                        <div style={{ margin: "10px" }}>
                          {narrativeParagraphTable}
                        </div>
                      )
                    },
                    {
                      title: `Related Narrative Content Blocks: ${narrativeBlockSummary.blockCount} Element(s), ${narrativeBlockSummary.paragraphCount} Paragraph(s), ${narrativeBlockSummary.sentenceCount} Sentence(s) Created`,
                      content: (
                        <div style={{ margin: "10px" }}>
                          {narrativeBlockElements}
                        </div>
                      )
                    }
                  ]}
                />
              )
            contentBlockElements.push(
              <Fragment key={`${trigger.id}-${trigger.type}`}>
                {triggerElement}
                {accordion}
              </Fragment>
            )
          } else {
            contentBlockElements.push(
              <Fragment key={`trigger-${itemId}`}>
                Trigger {itemId} was not found in this narrative
              </Fragment>
            )
          }

          break
        }
        case "createnewstrigger": {
          newModelTrigger.isNews = true
          toolbarTitle = `New News Trigger`
          contentBlockElements = (
            <Trigger
              trigger={newModelTrigger}
              edit={true}
              handleCancel={() => {
                const viewKey = "newstriggers"
                this.setState({ activeView: viewKey }, () => {
                  this.fetchNarrativeInfo(narrative.id)
                  this.changePage(viewKey)
                })
              }}
              savedTrigger={() => {
                this.setState({ triggerSaved: true })
              }}
              handleReload={viewKey => this.reloadData(viewKey)}
              toggleViewFromExplorer={this.state.toggleViewFromExplorer}
            />
          )
          break
        }
        case "newstriggers": {
          toolbarTitle = `News Triggers`
          // Load the contentblock we're filtering on and output all its paragraphs
          const newsTriggers =
            (
              triggers &&
              triggers.filter(
                t =>
                  t.isNews !== null &&
                  (t.isNews === true || t.isNews === "true")
              )
            ).sort((a, b) => a.rank - b.rank || b.id - a.id) || []
          contentBlockElements = (
            <TriggerTable
              title={toolbarTitle}
              narrative={narrative}
              triggers={newsTriggers}
              bulkAlertMessage={val => {
                this.processBulkMessage(val)
              }}
              isAlertActive={() => {
                this.setState({ bulkAlertMessageActive: true })
              }}
            />
          )
          break
        }
        case "search":
          toolbarTitle = `Search ${itemId}`
          if (contentBlocks) {
            Object.keys(contentBlocks).forEach((key, index) =>
              contentBlocks[key].forEach((paragraph, nestedKey) => {
                let inName

                let inTrigger = false
                let retParagraph = paragraph
                if (
                  blockIds.length > 0 &&
                  !blockIds.some(b => b.trim() === paragraph.id.toString())
                ) {
                  return
                }
                if (
                  searchInName &&
                  paragraph.name &&
                  paragraph.name.includes(itemId)
                ) {
                  inName = true
                }
                if (
                  searchInTrigger &&
                  paragraph.triggerType &&
                  paragraph.triggerType.includes(itemId)
                ) {
                  inTrigger = true
                }

                const retSentences = []
                for (let sentenceIndex in paragraph.sentences) {
                  const sentence = paragraph.sentences[sentenceIndex]
                  if (
                    searchInTemplate &&
                    sentence.template &&
                    sentence.template.includes(itemId)
                  ) {
                    retSentences.push({ ...sentence })
                  }
                  if (
                    searchInName &&
                    sentence.name &&
                    sentence.name.includes(itemId)
                  ) {
                    inName = true
                    retSentences.push({ ...sentence, template: "" })
                  }
                  if (
                    searchInTrigger &&
                    sentence.triggerType &&
                    sentence.triggerType.includes(itemId)
                  ) {
                    inTrigger = true
                    retSentences.push({ ...sentence, template: "" })
                  }
                }
                if (retSentences.length > 0 || inName || inTrigger) {
                  retParagraph.sentences = retSentences
                  contentBlockElements.push(
                    <Paragraph
                      triggers={triggers}
                      key={`${paragraph.id}-${nestedKey}-${index}-${paragraph.sentences.length}`}
                      updateNarrativeParagraphs={paragraph =>
                        this.updateParagraphs(paragraph)
                      }
                      libraryParagraphs={libraryParagraphs}
                      paragraphs={paragraphs}
                      paragraph={retParagraph}
                      outlineBlocks={outlineBlocks}
                      handleReload={viewKey => this.reloadData(viewKey)}
                      searchString={this.state.searchString}
                      ownerListForOrg={this.state.ownerList}
                      toggleViewFromExplorer={this.state.toggleViewFromExplorer}
                      primaryTags={this.state.primaryTags}
                    />
                  )
                  let sl = this.state.searchedBlocksList

                  let exists = false
                  sl.forEach(itm => {
                    if (itm.id === retParagraph.id) {
                      exists = true
                    }
                  })
                  if (!exists) {
                    sl.push(retParagraph)
                    this.setState({
                      searchedBlocksList: sl,
                      isLoadingSearch: false
                    })
                  } else {
                    this.setState({
                      isLoadingSearch: false
                    })
                  }
                } else {
                  this.setState({
                    isLoadingSearch: false
                  })
                }
              })
            )
          }
          break
        case "searchtriggers":
          toolbarTitle = `Search Triggers for ${itemId}`
          let filteredTriggers
          const filteredTriggersPreId =
            triggers &&
            triggers.filter(
              t =>
                (searchInTemplate && t.formula?.includes(itemId)) ||
                (searchInName && t.name?.includes(itemId)) ||
                (searchInTrigger && t.type?.includes(itemId))
            )

          if (blockIds.length > 0) {
            let fliteredById = []
            blockIds.forEach((itm, i) => {
              filteredTriggersPreId.forEach((trigger, j) => {
                if (trigger.id === Number(itm)) {
                  fliteredById.push(trigger)
                }
              })
            })
            filteredTriggers = fliteredById
          } else {
            filteredTriggers = filteredTriggersPreId
          }
          contentBlockElements = filteredTriggers.map((trigger, key) => (
            <Trigger
              key={`${trigger.id}-${key}`}
              triggerId={trigger.id}
              handleEdit={() => {
                const viewKey = `${trigger.isNews ? "news" : ""}triggerid-${
                  trigger.id
                }`
                this.setState({ activeView: viewKey }, () => {
                  this.changePage(viewKey)
                })
              }}
              handleCancel={() => {
                const viewKey = `searchtriggers-${itemId}`
                this.setState({ activeView: viewKey }, () => {
                  this.changePage(viewKey)
                })
              }}
              handleReload={viewKey => this.reloadData(viewKey)}
              model={this.state.model}
              toggleViewFromExplorer={this.state.toggleViewFromExplorer}
            />
          ))
          this.setState({
            searchedTriggerList: filteredTriggers,
            isLoadingSearch: false
          })
          break
        case "outlineBlockId": {
          toolbarTitle = `Outline Block ${itemId}`
          // Load the contentblock we're filtering on and output all its paragraphs
          contentBlockElements = (
            <ParagraphTable
              title={toolbarTitle}
              narrative={narrative}
              paragraphs={paragraphs.filter(
                p => p.narrativeOutlineBlockId === Number(itemId)
              )}
              outlineBlocks={outlineBlocks}
              outlineBlockId={itemId}
              defaults={{
                ...outlineBlock,
                narrativeOutlineBlockId: itemId,
                name: outlineBlock.contentSection
              }}
              updateNarrativeParagraphs={paragraph =>
                this.updateParagraphs(paragraph)
              }
              bulkAlertMessage={val => {
                this.processBulkMessage(val)
              }}
              isAlertActive={() => {
                this.setState({ bulkAlertMessageActive: true })
              }}
              linkId
              isLibrary={narrative.isLibrary}
              hiddenColumns={["paragraphVariationS"]}
              primaryTags={this.state.primaryTags}
              secondaryTags={this.state.secondaryTags}
              formFactorTags={this.state.formFactorTags}
              thirdPartySpecificTags={this.state.thirdPartySpecificTags}
            />
          )
          break
        }
        default:
          break
      }
    }
    return { contentBlockElements }
  }

  setIsRenderSchedulerOpenFromNarrativeScheduler(val) {
    this.setState({
      isRenderSchedulerOpen: val
    })
  }

  displayOutputFeed() {
    let outputFeed = this.state.feeds.find(
      feed => feed.id === this.state.outputFeedId
    )
    if (outputFeed) {
      return `${outputFeed.id} - ${outputFeed.name}`
    } else {
      return "No Feed Selected"
    }
  }

  closeBulkModal() {
    this.setState({
      bulkAlertMessage: {},
      bulkAlertMessageActive: false
    })
  }

  setLoading(isLoading) {
    this.setState({
      loadingGeneral: isLoading
    })
  }

  render() {
    const {
      activeView,
      editNarrative,
      isRecap,
      loading,
      model,
      modelSnapshotNarrativeId,
      modelSnapshotTriggers,
      modelType,
      modelTypeId,
      modelUrl,
      narrative,
      narrativeLoaded,
      narratives,
      searchString,
      triggerTypesBk,
      triggerTypes,
      triggerCategories,
      checkedCategories,
      isFullScreen,
      contentBlockElements,
      isDataDrawerOpen,
      isPreviewDrawerOpen,
      selectedSentence,
      sentenceTransition,
      itemIndex
    } = this.state
    const { classes } = this.props
    const { id: narrativeId, leagueId, isLibrary } = narrative
    const contentId = this.getContentId()
    const contentType = this.getContentType()

    // Build the urls to our token list and model previews
    const modelPreviewUrl = Helpers.buildModelPreviewUrl({
      narrativeId,
      contentId,
      contentType
    })

    let narrativeChoices = []
    if (narratives && narratives.length > 0) {
      narratives.forEach(n => {
        narrativeChoices.push(
          <option key={n.id} value={n.id}>
            {n.name} ({n.id})
          </option>
        )
      })
    }

    if (!this.state.narrative.id) {
      return "Assembling..."
    }

    let narrativeTitleStyling = {}
    narrativeTitleStyling = Helpers.buildCustomStyleForNarrativeStatus(
      this.state.narrative.narrativeStatusId
    )
    if (this.state.narrative.isArchived) {
      narrativeTitleStyling.textDecoration = "line-through"
    }

    let archivedSentences = []

    let archivedParagraphs = []

    let archivedTriggers = []
    if (this.state.archivedItems && this.state.archivedItems.length > 0) {
      this.state.archivedItems.forEach(items => {
        if (items.blocktype === "Sentence") {
          archivedSentences.push(items)
        } else if (items.blocktype === "Paragraph") {
          archivedParagraphs.push(items)
        } else if (items.blocktype === "Trigger") {
          archivedTriggers.push(items)
        }
      })
    }

    const libraryNarrativeIds = !narrative.libraryNarrative_Ids
      ? []
      : narrative.libraryNarrative_Ids.split(",")

    let starterContent = narrative.starters || []
    let starters = []

    if (starterContent.length !== libraryNarrativeIds.length) {
      //We have libraries with no starters, so they came back empty from the DB
      let missingLibraries = libraryNarrativeIds
      starterContent.forEach(itm => {
        let position = libraryNarrativeIds.includes(itm.id)
        if (position > -1) {
          missingLibraries.splice(position, 1)
          starters.push(itm.starter)
        }
      })
      missingLibraries.forEach(itm => {
        starterContent.push({ id: itm, name: "", starter: "" })
        starters.push("")
      })
    } else {
      starterContent.forEach(itm => {
        starters.push(itm.starter)
      })
    }

    return (
      <div className={classes.root} style={{ backgroundColor: "#efefef" }}>
        <SplitterLayout
          horizontal
          percentage
          secondaryInitialSize={this.state.dataDrawerWidth}
          onSecondaryPaneSizeChange={newSize => {
            this.onDataDrawerWidthChange(newSize)
          }}
        >
          <main>
            <AppBar
              position="sticky"
              color="default"
              className={classes.appBar}
            >
              {!editNarrative && (
                <Fragment>
                  <GridContainer style={{ alignItems: "left" }}>
                    <Grid item md={12}>
                      <Tooltip
                        id="tooltip-top"
                        title={
                          this.state.narrative.isArchived
                            ? `Narrative has been archived`
                            : ``
                        }
                        placement="top"
                        arrow
                      >
                        <h5 style={narrativeTitleStyling}>
                          {!this.state.narrative.isLibrary && (
                            <Link
                              to={{
                                pathname: `/portal/narrative/${narrativeId}`,
                                state: {
                                  narrative_name: `${narrative.name}`
                                }
                              }}
                              title="Narrative Outline"
                            >
                              {this.state.narrative.id}
                              {" - "}
                              {this.state.narrative.name}
                              {!narrativeLoaded && "Loading Narrative..."}
                            </Link>
                          )}
                          {this.state.narrative.isLibrary && (
                            <span>
                              {this.state.narrative.id}
                              {" - "}
                              {this.state.narrative.name}
                              {!narrativeLoaded && "Loading Library..."}
                            </span>
                          )}
                        </h5>
                      </Tooltip>
                    </Grid>
                  </GridContainer>
                  <GridContainer>
                    <Grid item md={2} className={classes.variationScoreHolder}>
                      <InputLabel
                        style={{ marginTop: "5px", marginRight: "5px" }}
                      >
                        Content Variation Score
                      </InputLabel>
                      <ContentSaturationModal />
                    </Grid>
                    <Grid item md={10}>
                      <RadioGroup
                        className={classes.variationScoreRadio}
                        row
                        value={this.state.showVariation}
                        onChange={() => {
                          this.setState(
                            {
                              showVariation: !this.state.showVariation
                            },
                            () => {
                              this.fetchNarrativeInfo(narrative.id)
                            }
                          )
                          let cookies = new Cookies()
                          cookies.set(
                            "showVariation",
                            !this.state.showVariation
                          )
                        }}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio size="small" />}
                          label="Show"
                          labelPlacement="start"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio size="small" />}
                          label="Hide"
                          labelPlacement="start"
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item md={2}>
                      <InputLabel
                        style={{ marginTop: "5px", marginRight: "5px" }}
                      >
                        Search bar
                      </InputLabel>
                    </Grid>
                    <Grid item md={10}>
                      <RadioGroup
                        className={classes.variationScoreRadio}
                        row
                        value={this.state.doShowSearch}
                        onChange={() => {
                          this.setState(
                            {
                              doShowSearch: !this.state.doShowSearch
                            },
                            () => {
                              if (this.state.doShowSearch) {
                                this.fetchNarrativeInfo(narrative.id)
                              }
                            }
                          )
                          let cookies = new Cookies()
                          cookies.set("doShowSearch", !this.state.doShowSearch)
                        }}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio size="small" />}
                          label="Show"
                          labelPlacement="start"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio size="small" />}
                          label="Hide"
                          labelPlacement="start"
                        />
                      </RadioGroup>
                    </Grid>
                  </GridContainer>
                  <GridContainer>
                    <Grid
                      item
                      md={12}
                      style={{
                        justifyContent: "flex-end",
                        display: "flex"
                      }}
                    >
                      <Tooltip
                        id="tooltip-top"
                        title="Edit Narrative"
                        placement="top"
                        arrow
                      >
                        <div>
                          <IconButton
                            aria-label="Edit"
                            onClick={() => this.toggleNarrativeEdit()}
                            disabled={!narrativeLoaded}
                            size="large"
                          >
                            <Edit />
                          </IconButton>
                        </div>
                      </Tooltip>
                      <Tooltip
                        id="tooltip-top-start"
                        title="Add Block"
                        placement="top"
                        arrow
                      >
                        <div>
                          <IconButton
                            aria-label="Add Block"
                            onClick={() =>
                              this.setState(
                                {
                                  activeView: "createparagraph"
                                },
                                () => this.changePage("createparagraph")
                              )
                            }
                            disabled={
                              !narrativeLoaded ||
                              this.state.narrative.isArchived
                            }
                            size="large"
                          >
                            <NoteAdd />
                          </IconButton>
                        </div>
                      </Tooltip>
                      <Tooltip
                        id="tooltip-top"
                        title="Narrative Render"
                        placement="top"
                        arrow
                      >
                        <div>
                          <IconButton
                            aria-label="Narrative Render Scheduler"
                            onClick={() => this.toggleScheduler()}
                            disabled={!narrativeLoaded}
                            size="large"
                          >
                            <Schedule />
                          </IconButton>
                        </div>
                      </Tooltip>

                      <Tooltip
                        id="tooltip-top"
                        title="View Existing Render"
                        placement="top"
                        arrow
                      >
                        <div>
                          <IconButton
                            color="primary"
                            disabled={!narrativeLoaded}
                            round="true"
                            simple="true"
                            onClick={() => {
                              let cookies = new Cookies()
                              cookies.set(
                                "goingFromNarrativeManagerToRenderSchedules",
                                1
                              )
                              this.setState({
                                isShowRenderSchedulesOpen: true
                              })
                            }}
                            size="large"
                          >
                            <CalendarTodayIcon
                              style={{
                                color: !narrativeLoaded
                                  ? "inherit"
                                  : "rgba(0, 0, 0, 0.6)"
                              }}
                            />
                          </IconButton>
                        </div>
                      </Tooltip>

                      <Tooltip
                        id="tooltip-top"
                        title="Clear Narrative Cache"
                        placement="top"
                        arrow
                      >
                        <div>
                          <IconButton
                            aria-label="Clear Narrative Cache"
                            onClick={() => {
                              if (
                                window.confirm(
                                  "Do you want to clear the narrative data cache?"
                                )
                              ) {
                                this.clearNarrativeCache()
                              }
                            }}
                            disabled={!narrativeLoaded}
                            size="large"
                          >
                            <LayersClear />
                          </IconButton>
                        </div>
                      </Tooltip>
                      <Tooltip
                        id="tooltip-top"
                        title={
                          isDataDrawerOpen
                            ? "Close Data Drawer"
                            : "Open Data Drawer"
                        }
                        placement="top"
                        arrow
                      >
                        <div>
                          {isDataDrawerOpen && (
                            <IconButton
                              aria-label="Close Data Drawer"
                              onClick={() => this.toggleDataDrawer()}
                              size="large"
                            >
                              <PhonelinkOff />
                            </IconButton>
                          )}
                          {!isDataDrawerOpen && (
                            <IconButton
                              aria-label="Open Data Drawer"
                              onClick={() => this.toggleDataDrawer()}
                              size="large"
                            >
                              <Phonelink />
                            </IconButton>
                          )}
                        </div>
                      </Tooltip>
                      <Tooltip
                        id="tooltip-top"
                        title={
                          isPreviewDrawerOpen
                            ? "Close Preview Drawer"
                            : "Open Preview Drawer"
                        }
                        placement="top"
                        arrow
                      >
                        <div>
                          {isPreviewDrawerOpen && (
                            <IconButton
                              aria-label="Close Preview Drawer"
                              onClick={() => this.togglePreviewDrawer()}
                              size="large"
                            >
                              <VisibilityOff />
                            </IconButton>
                          )}
                          {!isPreviewDrawerOpen && (
                            <IconButton
                              aria-label="Open Preview Drawer"
                              onClick={() => this.togglePreviewDrawer()}
                              size="large"
                            >
                              <Visibility />
                            </IconButton>
                          )}
                        </div>
                      </Tooltip>
                    </Grid>
                  </GridContainer>
                  <GridContainer style={{ marginBottom: "10px" }}>
                    <Grid item xs={12}>
                      <ModelPreviewLink
                        contentId={contentId}
                        contentType={contentType}
                        modelName={this.state.modelName}
                        modelPreviewUrl={modelPreviewUrl}
                      />
                    </Grid>
                  </GridContainer>
                </Fragment>
              )}
              {editNarrative && (
                <Narrative
                  narrative={narrative}
                  handleCancel={this.toggleNarrativeEdit}
                  handleSave={narrative => this.handleNarrativeEdit(narrative)}
                  allProperties={this.state.allProperties}
                  creatingFeed={false}
                />
              )}
              {this.state.doShowSearch && (
                <SearchBlock
                  getSearchString={this.state.searchString}
                  loadingSearchResults={this.state.isLoadingSearch}
                  setSearchInName={val => {
                    this.setState({ searchInName: val })
                  }}
                  setSearchType={val => {
                    this.setState({ searchType: val })
                  }}
                  setSearchInTrigger={val => {
                    this.setState({
                      searchInTrigger: val
                    })
                  }}
                  setSearchInTemplate={val => {
                    this.setState({
                      searchInTemplate: val
                    })
                  }}
                  searchForString={val => {
                    this.searchForString(val === "triggers")
                  }}
                  setReplaceStrings={val =>
                    this.replaceStrings(val === "triggers")
                  }
                  setReplaceStringValue={val =>
                    this.setState({
                      replaceString: val
                    })
                  }
                  setSearchBlockIds={val => {
                    this.setState({
                      searchBlockIds: val
                    })
                  }}
                  setSearchString={val => {
                    this.setState({
                      searchString: val
                    })
                  }}
                  clearFilters={() => this.clearFilters()}
                />
              )}
              <Modal
                open={this.state.isAlertMessageOpen}
                onClose={() => {
                  this.setState({
                    isAlertMessageOpen: false,
                    isRenderSchedulerOpen: false
                  })
                }}
              >
                <Card className={classes.alertMessageModal}>
                  <CardHeader>
                    <h3>Alert</h3>
                  </CardHeader>
                  <CardBody>
                    <h5>{this.state.alertMessage}</h5>
                  </CardBody>
                </Card>
              </Modal>
              {this.state.isRenderSchedulerOpen && (
                <NarrativeRenderScheduler
                  open={this.state.isRenderSchedulerOpen}
                  isMyRenderSchedulerOpen={this.state.isRenderSchedulerOpen}
                  setAlertMessageOpen={itm => {
                    this.setState({
                      isAlertMessageOpen: itm.isOpen,
                      alertMessage: itm.msg
                    })
                  }}
                  contentId={contentId}
                  contentType={contentType}
                  feeds={this.state.feeds}
                  narrative={this.state.narrative}
                  hideShareDialog={this.hideShareDialog}
                  narrativeLoaded={true}
                  classes={classes}
                  setRenderSchedulerOpen={val => {
                    this.setState({
                      isRenderSchedulerOpen: val
                    })
                  }}
                />
              )}
              {this.state.isShowRenderSchedulesOpen && (
                <Modal
                  open={this.state.isShowRenderSchedulesOpen}
                  className={classes.renderScheduleModalTop}
                  onClose={() => {
                    this.setState({
                      isShowRenderSchedulesOpen: false
                    })
                  }}
                >
                  <div className={classes.renderScheduleModal}>
                    <NarrativeSchedule
                      isModal={true}
                      narrativeId={narrativeId}
                      fromNarrativeEditor={true}
                    />
                  </div>
                </Modal>
              )}
            </AppBar>
            <SplitterLayout
              horizontal
              percentage
              secondaryInitialSize={this.state.narrativeExplorerWidth}
              onSecondaryPaneSizeChange={newSize => {
                this.onNarrativeExplorerWidthChange(newSize)
              }}
            >
              {!isFullScreen && !this.state.isSearching && (
                <div className={classes.explorerDrawer}>
                  <NarrativeExplorer
                    narrative={narrative}
                    loading={loading}
                    setActiveView={(viewKey, placeholderId) => {
                      this.setState(
                        {
                          activeView: viewKey,
                          sentenceTransition: viewKey.match(/sentence/)
                            ? true
                            : this.state.sentenceTransition
                        },
                        () => this.changePage(viewKey, placeholderId)
                      )
                      //this.changePage(viewKey)
                    }}
                    activeView={activeView}
                    refreshNarrative={() =>
                      this.fetchNarrativeInfo(narrativeId)
                    }
                    setEditView={(viewKey, change) => {
                      if (change) {
                        this.setState(
                          {
                            activeView: viewKey,
                            sentenceTransition: viewKey.match(/sentence/)
                              ? true
                              : this.state.sentenceTransition
                          },
                          () => this.changePage(viewKey)
                        )
                        //this.changePage(viewKey)
                      }
                      if (this.state.toggleViewLast === viewKey) {
                        this.setState({
                          toggleViewFromExplorer:
                            !this.state.toggleViewFromExplorer,
                          toggleViewLast: viewKey
                        })
                        let cookies = new Cookies()
                        cookies.set("setEditFromExplorer", {
                          toggleViewFromExplorer:
                            !this.state.toggleViewFromExplorer,
                          toggleViewLast: viewKey
                        })
                      } else {
                        this.setState({
                          toggleViewFromExplorer: true,
                          toggleViewLast: viewKey
                        })
                        let cookies = new Cookies()
                        cookies.set("setEditFromExplorer", {
                          toggleViewFromExplorer: true,
                          toggleViewLast: viewKey
                        })
                      }
                    }}
                  />
                </div>
              )}
              {!isFullScreen && this.state.isSearching && (
                <div className={classes.explorerDrawer}>
                  <SearchExplorer
                    narrative={narrative}
                    searchString={this.state.searchString}
                    searchedBlocks={this.state.searchedBlocksList}
                    searchedTriggers={this.state.searchedTriggerList}
                    loading={loading}
                    setActiveView={(viewKey, placeholderId) => {
                      this.setState({ activeView: viewKey })
                      this.changePage(viewKey, placeholderId)
                    }}
                    activeView={activeView}
                    refreshNarrative={() =>
                      this.fetchNarrativeInfo(narrativeId)
                    }
                    setEditView={(viewKey, change) => {
                      if (change) {
                        this.setState({
                          activeView: viewKey
                        })
                        this.changePage(viewKey)
                      }
                      if (this.state.toggleViewLast === viewKey) {
                        this.setState({
                          toggleViewFromExplorer:
                            !this.state.toggleViewFromExplorer,
                          toggleViewLast: viewKey
                        })
                        let cookies = new Cookies()
                        cookies.set("setEditFromExplorer", {
                          toggleViewFromExplorer:
                            !this.state.toggleViewFromExplorer,
                          toggleViewLast: viewKey
                        })
                      } else {
                        this.setState({
                          toggleViewFromExplorer: true,
                          toggleViewLast: viewKey
                        })
                        let cookies = new Cookies()
                        cookies.set("setEditFromExplorer", {
                          toggleViewFromExplorer: true,
                          toggleViewLast: viewKey
                        })
                      }
                    }}
                  />
                </div>
              )}
              <div style={{ border: "1px solid #d4d4d4" }}>
                {(this.state.loading || this.state.loadingGeneral) && (
                  <LinearProgress />
                )}
                <div className={classes.content} key="content">
                  <NarrativeContext.Provider
                    value={{
                      contentId,
                      contentType,
                      itemIndex,
                      isRecap,
                      loading,
                      model,
                      modelType,
                      modelTypeId,
                      modelUrl,
                      narrative,
                      searchString,
                      triggers: modelSnapshotTriggers,
                      triggerTypesBk,
                      triggerTypes,
                      triggerCategories,
                      checkedCategories,
                      buildParagraphPreview: (
                        paragraph,
                        debug,
                        debugAllTriggers,
                        ignoreKillswitch
                      ) => {
                        this.buildParagraphPreview(
                          paragraph,
                          debug,
                          debugAllTriggers,
                          ignoreKillswitch
                        )
                      },
                      setLoadingGeneral: isLoading => {
                        this.setLoading(isLoading)
                      },
                      setActiveView: (viewKey, placeholderId) => {
                        this.setState({ activeView: viewKey }, () =>
                          this.changePage(viewKey, placeholderId)
                        )
                      },
                      setActiveTriggerCategories: categories => {
                        this.handleCategoryChange(categories)
                      }
                    }}
                  >
                    {contentBlockElements}
                  </NarrativeContext.Provider>
                  {this.state.isSearching &&
                    !this.state.loading &&
                    this.state.searchedBlocksList.length === 0 &&
                    this.state.searchedTriggerList.length === 0 && (
                      <div
                        style={{
                          margin: "20px",
                          padding: "0 0 0 40px",
                          display: "flex"
                        }}
                      >
                        <h4>
                          No search results found for "{this.state.searchString}
                          "
                        </h4>
                        <Tooltip
                          id="tooltip-top-clear"
                          title="Clear"
                          placement="top"
                        >
                          <IconButton
                            className={classes.iconButton}
                            aria-label="Clear"
                            onClick={() => this.clearFilters()}
                            size="large"
                          >
                            <Clear />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                </div>
              </div>
            </SplitterLayout>
          </main>
          {!isFullScreen && (isDataDrawerOpen || isPreviewDrawerOpen) && (
            <div>
              <SplitterLayout
                horizontal
                percentage
                secondaryInitialSize={this.state.previewDrawerWidth}
                onSecondaryPaneSizeChange={newSize => {
                  this.onPreviewDrawerWidthChange(newSize)
                }}
              >
                {isDataDrawerOpen && (
                  <div>
                    <Nav tabs className={classes.tabs}>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "dataTab"
                          })}
                          onClick={() => {
                            this.toggle("dataTab")
                            if (
                              !this.state.narratives ||
                              (this.state.narratives &&
                                this.state.narratives.length === 0)
                            ) {
                              this.fetchNarrativeModelSnapshots()
                            }
                          }}
                          title="Test Data Sources"
                        >
                          Data
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "graphicsToolTab"
                          })}
                          onClick={() => {
                            this.toggle("graphicsToolTab")
                          }}
                          title="Build Graphics"
                        >
                          Graphics Tool
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "imageSearchTab"
                          })}
                          onClick={() => {
                            this.toggle("imageSearchTab")
                          }}
                          title="Search for Images"
                        >
                          Image Search Tool
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "scratchpadTab"
                          })}
                          onClick={() => {
                            this.toggle("scratchpadTab")
                          }}
                          title="Test Content In the Scratch Pad"
                        >
                          Scratch Pad
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "archiveItemsTab"
                          })}
                          onClick={() => {
                            this.toggle("archiveItemsTab")
                            if (this.state.archivedItems.length === 0) {
                              this.getArchivedItemsForNarrative()
                            }
                          }}
                          title="List of Archived Items"
                        >
                          Archived Items
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "outputStarterTab"
                          })}
                          onClick={() => {
                            this.toggle("outputStarterTab")
                            //if (this.state.outputStarterTab.length === 0) {
                            //this.getOutputStartersForNarrative()
                            //}
                          }}
                          title="List of Output Starters"
                        >
                          Output Starter{" "}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "healthCheckTab"
                          })}
                          onClick={() => {
                            this.toggle("healthCheckTab")
                          }}
                          title="Run Health Checks Against Narrative"
                        >
                          Health Check
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent
                      activeTab={this.state.activeTab}
                      style={{
                        marginLeft: "1px",
                        marginRight: "1px",
                        marginTop: "0px",
                        display: "flex",
                        flexDirection: "column"
                      }}
                    >
                      <TabPane tabId="dataTab">
                        {(this.state.loading ||
                          this.state.loadingSnapshots ||
                          this.state.loadingData ||
                          this.state.isLoadingPreview) && <LinearProgress />}
                        {this.state.activeTab === "dataTab" && (
                          <div style={{ padding: "10px" }}>
                            <Card>
                              <CardHeader color="primary" icon>
                                <CardIcon color="primary">
                                  <Widgets />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                  Source Data
                                </h4>
                              </CardHeader>
                              <CardBody>
                                <Label>
                                  Data model:{" "}
                                  {this.state.modelName !== null ? (
                                    <span>
                                      {" "}
                                      {this.state.modelName} - (Content Type:{" "}
                                      {contentType}, Content Id: {contentId})
                                    </span>
                                  ) : (
                                    <span>None selected.</span>
                                  )}
                                </Label>
                                <br />
                                {this.state.loadingSnapshots && (
                                  <span>Loading Snapshots</span>
                                )}
                                {this.state.isLoadingPreview && (
                                  <span>Loading Preview</span>
                                )}
                                {this.state.loadingData && (
                                  <span>Loading Data</span>
                                )}
                                <Nav tabs>
                                  <NavItem>
                                    <NavLink
                                      className={classnames({
                                        active:
                                          this.state.activeSubTab ===
                                          "snapshotDataTab"
                                      })}
                                      onClick={() => {
                                        this.subToggle("snapshotDataTab")
                                        this.fetchNarrativeModelSnapshots()
                                      }}
                                    >
                                      Snapshots
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      className={classnames({
                                        active:
                                          this.state.activeSubTab ===
                                          "matchDataTab"
                                      })}
                                      onClick={() => {
                                        this.subToggle("matchDataTab")
                                      }}
                                    >
                                      Competitions
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      className={classnames({
                                        active:
                                          this.state.activeSubTab ===
                                          "fileDataTab"
                                      })}
                                      onClick={() => {
                                        if (!this.state.fileDataLoaded) {
                                          this.setState({
                                            fileDataLoaded: true
                                          })
                                        }
                                        this.subToggle("fileDataTab")
                                      }}
                                    >
                                      File
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      className={classnames({
                                        active:
                                          this.state.activeSubTab ===
                                          "querybuilderDataTab"
                                      })}
                                      onClick={() => {
                                        this.subToggle("querybuilderDataTab")
                                      }}
                                    >
                                      Query Builder
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      className={classnames({
                                        active:
                                          this.state.activeSubTab ===
                                          "pastedDataTab"
                                      })}
                                      onClick={() => {
                                        this.subToggle("pastedDataTab")
                                      }}
                                    >
                                      Pasted Data
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      className={classnames({
                                        active:
                                          this.state.activeSubTab ===
                                          "playerDataTab"
                                      })}
                                      onClick={() => {
                                        this.subToggle("playerDataTab")
                                      }}
                                    >
                                      Players
                                    </NavLink>
                                  </NavItem>
                                </Nav>
                                <TabContent
                                  activeTab={this.state.activeSubTab}
                                  style={{
                                    marginLeft: "1px",
                                    marginRight: "1px",
                                    marginTop: "-1px"
                                  }}
                                >
                                  <TabPane tabId="snapshotDataTab">
                                    <Grid
                                      container
                                      style={{
                                        alignItems: "center"
                                      }}
                                    >
                                      <Grid
                                        item
                                        style={{
                                          padding: "16px 3px",
                                          display: "flex",
                                          alignItems: "center"
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            display: "block",
                                            whiteSpace: "nowrap",
                                            marginRight: "10px"
                                          }}
                                        >
                                          Filter By Narrative
                                        </span>
                                        <Input
                                          type="select"
                                          style={{
                                            display: "block"
                                          }}
                                          value={modelSnapshotNarrativeId}
                                          onChange={e => {
                                            this.setState(
                                              {
                                                modelSnapshotNarrativeId:
                                                  e.target.value
                                              },
                                              () =>
                                                this.fetchNarrativeModelSnapshots()
                                            )
                                          }}
                                        >
                                          <option value="">All</option>
                                          {narrativeChoices}
                                        </Input>
                                      </Grid>
                                    </Grid>

                                    <NarrativeModelSnapshots
                                      type="text"
                                      className="form-control"
                                      snapshots={this.state.modelSnapshots}
                                      selectedSnapshotId={
                                        this.state.modelSnapshotId
                                      }
                                      narratives={narratives}
                                      onChange={row => {
                                        let newModelSnapshotTriggers = []
                                        if (
                                          row.original.triggers &&
                                          row.original.triggers !== "null"
                                        ) {
                                          const snapshotTriggers = JSON.parse(
                                            row.original.triggers
                                          )
                                          newModelSnapshotTriggers =
                                            snapshotTriggers.map(key => ({
                                              key: key.Key,
                                              value: key.Value
                                            }))
                                        }
                                        this.setState(
                                          {
                                            selectedMatchId: null,
                                            selectedMatchIds: [],
                                            modelSnapshotId: row.original.id,
                                            selectedAssetId: null,
                                            selectedQueryId: null,
                                            selectedPlayerId: null,
                                            modelType:
                                              row.original.narrativeModelType,
                                            modelName:
                                              row.original.name &&
                                              row.original.name.includes("<")
                                                ? row.original.name.split(
                                                    "<"
                                                  )[0]
                                                : row.original.name,
                                            modelSnapshotTriggers:
                                              newModelSnapshotTriggers
                                          },
                                          () => {
                                            this.props.history.push({
                                              search: `?snapshotid=${row.original.id}`
                                            })
                                            this.buildFeedPreview()
                                          }
                                        )
                                      }}
                                    />
                                  </TabPane>
                                  <TabPane tabId="matchDataTab">
                                    <div>
                                      <div>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={
                                                this.state.isRecap || false
                                              }
                                              onChange={event => {
                                                this.setState({
                                                  isRecap: event.target.checked
                                                })
                                              }}
                                            />
                                          }
                                          label="Competition Recap"
                                        />
                                      </div>
                                      {this.state.narrativeLoaded && (
                                        <MatchManager
                                          embedded
                                          leagueId={leagueId}
                                          onChange={row =>
                                            this.setState(
                                              {
                                                modelSnapshotId: null,
                                                selectedMatchId:
                                                  row.original.id,
                                                selectedMatchIds: [],
                                                selectedAssetId: null,
                                                selectedQueryId: null,
                                                selectedPlayerId: null,
                                                modelName:
                                                  row.original.name &&
                                                  row.original.name.includes(
                                                    "<"
                                                  )
                                                    ? row.original.name.split(
                                                        "<"
                                                      )[0]
                                                    : row.original.name
                                              },
                                              () => {
                                                this.props.history.push({
                                                  search: `?matchid=${row.original.id}`
                                                })
                                                this.buildFeedPreview()
                                              }
                                            )
                                          }
                                          onMultiSelect={selectedMatchIds =>
                                            this.setState({
                                              selectedMatchIds,
                                              selectedMatchId:
                                                selectedMatchIds[
                                                  selectedMatchIds.length - 1
                                                ],
                                              modelSnapshotId: null,
                                              selectedAssetId: null,
                                              selectedQueryId: null,
                                              selectedPlayerId: null
                                            })
                                          }
                                        />
                                      )}
                                    </div>
                                  </TabPane>
                                  <TabPane tabId="fileDataTab">
                                    {this.state.activeSubTab ===
                                      "fileDataTab" &&
                                      this.state.fileDataLoaded && (
                                        <AssetManager
                                          embedded={true}
                                          onChange={row =>
                                            this.setState(
                                              {
                                                modelSnapshotId: null,
                                                selectedMatchId: null,
                                                selectedMatchIds: [],
                                                selectedAssetId:
                                                  row.original.id,
                                                selectedQueryId: null,
                                                selectedPlayerId: null,
                                                modelName:
                                                  row.original.name &&
                                                  row.original.name.includes(
                                                    "<"
                                                  )
                                                    ? row.original.name.split(
                                                        "<"
                                                      )[0]
                                                    : row.original.name
                                              },
                                              () => {
                                                this.props.history.push({
                                                  search: `?assetid=${row.original.id}`
                                                })
                                                this.buildFeedPreview()
                                              }
                                            )
                                          }
                                        />
                                      )}
                                  </TabPane>
                                  <TabPane tabId="querybuilderDataTab">
                                    {this.state.activeSubTab ===
                                      "querybuilderDataTab" && (
                                      <QueryBuilder
                                        isEmbedded={true}
                                        selectedQueryId={
                                          this.state.selectedQueryId
                                        }
                                        handleIndexChange={itemIndex =>
                                          this.handleIndexChange(itemIndex)
                                        }
                                        onChange={queryId =>
                                          this.setState(
                                            {
                                              modelSnapshotId: null,
                                              selectedMatchId: null,
                                              selectedMatchIds: [],
                                              selectedAssetId: null,
                                              selectedQueryId: queryId,
                                              selectedPlayerId: null,
                                              modelName: `Query Builder Query #${queryId}`
                                            },
                                            () => {
                                              this.buildFeedPreview()
                                            }
                                          )
                                        }
                                      />
                                    )}
                                  </TabPane>
                                  <TabPane tabId="pastedDataTab">
                                    <InputLabel
                                      style={{
                                        color: "#AAA"
                                      }}
                                    >
                                      Data Model
                                    </InputLabel>
                                    <TextField
                                      id="data-model"
                                      fullWidth={true}
                                      multiline={true}
                                      rows={100}
                                      placeholder={
                                        "Enter some XML or JSON text here."
                                      }
                                      value={this.state.model}
                                      onBlur={this.onDataModelTextAreaChange}
                                      helperText="XML must have a 'root' element.  JSON starts and ends with a curly braces (e.g. { } ).  Ctrl-Enter will display all tokens."
                                    />
                                  </TabPane>
                                  <TabPane tabId="playerDataTab">
                                    <div>
                                      <TextField
                                        type="text"
                                        fullWidth
                                        label="Player Id"
                                        id="playerid"
                                        multiline
                                        value={this.state.playerId || ""}
                                        onChange={this.handlePlayerIdChange}
                                        margin="normal"
                                      />
                                      <Button
                                        onClick={() => {
                                          this.setState({
                                            model: {},
                                            modelName: `Fantasy Football Player: ${this.state.playerId}`,
                                            modelPreview: JSON.stringify({}),
                                            selectedPlayerId:
                                              this.state.playerId
                                          })
                                        }}
                                      >
                                        Load fantasy football model...
                                      </Button>
                                    </div>
                                  </TabPane>
                                </TabContent>
                              </CardBody>
                            </Card>
                          </div>
                        )}
                      </TabPane>
                      <TabPane tabId="scratchpadTab">
                        {this.state.loadingData && <LinearProgress />}
                        {selectedSentence &&
                        selectedSentence.starter &&
                        activeView !== "dependencies" &&
                        !activeView.match(/triggers$/i) &&
                        !activeView.match(/^triggerid/i) &&
                        !activeView.match(/^newstrigger/i) &&
                        !sentenceTransition ? (
                          <div style={{ padding: "10px" }}>
                            <Card>
                              <CardHeader color="primary" icon>
                                <CardIcon color="primary">
                                  <Gesture />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                  Sentence Starters
                                </h4>
                              </CardHeader>
                              <CardBody>
                                {this.state.activeTab === "scratchpadTab" &&
                                  !sentenceTransition && (
                                    <ScratchPad
                                      narrativeId={narrative.id}
                                      contentId={contentId}
                                      contentType={contentType}
                                      dataModelText={contentId ? "" : model}
                                      modelType={
                                        modelType || narrative.modelType
                                      }
                                      modelTypeId={
                                        modelTypeId || narrative.ModelTypeId
                                      }
                                      template={selectedSentence.starter}
                                      itemIndex={this.state.itemIndex}
                                    />
                                  )}
                              </CardBody>
                            </Card>
                          </div>
                        ) : null}
                        <div style={{ padding: "10px" }}>
                          <Card>
                            <CardHeader color="primary" icon>
                              <CardIcon color="primary">
                                <Gesture />
                              </CardIcon>
                              <h4 className={classes.cardIconTitle}>
                                Narrative Scratch Pad
                              </h4>
                            </CardHeader>
                            <CardBody>
                              {this.state.activeTab === "scratchpadTab" &&
                                !sentenceTransition && (
                                  <ScratchPad
                                    narrativeId={narrative.id}
                                    contentId={contentId}
                                    contentType={contentType}
                                    dataModelText={contentId ? "" : model}
                                    modelType={modelType || narrative.modelType}
                                    modelTypeId={
                                      modelTypeId || narrative.ModelTypeId
                                    }
                                    itemIndex={this.state.itemIndex}
                                  />
                                )}
                            </CardBody>
                          </Card>
                        </div>
                      </TabPane>
                      <TabPane tabId="outputStarterTab">
                        {(this.state.loading ||
                          this.state.loadingSnapshots ||
                          this.state.loadingData ||
                          this.state.isLoadingPreview) && <LinearProgress />}
                        {this.state.activeTab === "outputStarterTab" && (
                          <ScratchPadForStarters
                            isLibrary={isLibrary}
                            starterName={
                              starterContent &&
                              starterContent[0] &&
                              starterContent[0].name
                            }
                            starterId={
                              starterContent &&
                              starterContent[0] &&
                              starterContent[0].id
                            }
                            starterContent={starterContent}
                            starters={starters}
                            libraryNarrativeIds={libraryNarrativeIds}
                            narrativeId={narrative.id}
                            contentId={contentId}
                            contentType={contentType}
                            narrative={narrative}
                            userAccount={this.props.userAccount?.id}
                            dataModelText={contentId ? "" : model}
                            modelType={modelType || narrative.modelType}
                            modelTypeId={modelTypeId || narrative.modelTypeId}
                          />
                        )}
                      </TabPane>
                      <TabPane tabId="graphicsToolTab">
                        <div style={{ padding: "10px" }}>
                          {this.state.activeTab === "graphicsToolTab" && (
                            <GraphicsTool isEmbedded={true} />
                          )}
                        </div>
                      </TabPane>
                      <TabPane tabId="imageSearchTab">
                        <div style={{ padding: "10px" }}>
                          {this.state.activeTab === "imageSearchTab" && (
                            <ImageSearchTool isEmbedded={true} location="" />
                          )}
                        </div>
                      </TabPane>
                      <TabPane tabId="archiveItemsTab">
                        <div style={{ padding: "10px" }}>
                          {this.state.activeTab === "archiveItemsTab" &&
                            this.state.archivedItemsLoaded && (
                              <ArchivedItems
                                archivedSentences={archivedSentences}
                                archivedParagraphs={archivedParagraphs}
                                archivedTriggers={archivedTriggers}
                                reloadArchive={() => {
                                  this.setState({
                                    archivedItems: []
                                  })
                                  this.getArchivedItemsForNarrative()
                                }}
                              />
                            )}
                        </div>
                      </TabPane>
                      <TabPane tabId="healthCheckTab">
                        <div style={{ padding: "10px" }}>
                          {this.state.activeTab === "healthCheckTab" && (
                            <HealthCheck
                              classes={classes.cardIconTitle}
                              narrativeId={narrative.id}
                              reloadNarrative={() => {
                                this.fetchNarrativeInfo(narrative.id)
                              }}
                              evaluateTriggers={this.evaluateTriggers}
                              updateTriggers={this.updateTriggers}
                            />
                          )}
                        </div>
                      </TabPane>
                    </TabContent>
                  </div>
                )}
                {isPreviewDrawerOpen && (
                  <Card>
                    <CardHeader color="primary" icon>
                      <CardIcon color="primary">
                        <Visibility />
                      </CardIcon>
                      <h4 className={classes.cardIconTitle}>Previewer</h4>
                    </CardHeader>
                    <CardBody style={{ padding: 0 }}>
                      <NarrativeContext.Provider
                        value={{
                          contentId,
                          contentType,
                          loading,
                          modelName: this.state.modelName
                        }}
                      >
                        <NarrativePreviewer
                          narrativePreviewContent={
                            Object.keys(this.state.narrativePreviewContent)
                              .length > 2
                              ? {
                                  ...this.state.narrativePreviewContent,
                                  expiredOn: !this.state.narrativePreviewContent
                                    .expiredOn
                                    ? "None"
                                    : this.state.narrativePreviewContent
                                        .expiredOn,
                                  deliveryId: this.state.narrativePreviewContent
                                    .deliveryId
                                    ? `[FEED ID]-${this.state.narrativePreviewContent.deliveryId}`
                                    : "None"
                                }
                              : { ...this.state.narrativePreviewContent }
                          }
                          previewElapsedTimeMilliseconds={
                            this.state.previewElapsedTimeMilliseconds
                          }
                          isLoadingPreview={this.state.isLoadingPreview}
                          handleChangeItemIndex={this.handleIndexChangeEvent}
                          handleBuildFeedPreview={this.buildFeedPreview}
                          itemIndex={this.state.itemIndex}
                          modelPreviewUrl={modelPreviewUrl}
                        />
                      </NarrativeContext.Provider>
                    </CardBody>
                  </Card>
                )}
              </SplitterLayout>
            </div>
          )}
        </SplitterLayout>

        {this.state.bulkAlertMessageActive && (
          <div id="myProgressMarrEditor" className={classes.progressBar}>
            {this.state.bulkAlertMessage.progress === 100 && (
              <div className={classes.finishedMsg}>Actions completed!</div>
            )}
            {this.state.bulkAlertMessage.progress !== 100 &&
              this.state.bulkAlertMessage.totals.finished && (
                <a
                  className={classes.failedListMsg}
                  onClick={() => {
                    this.setState({
                      bulkAlertErrorMessageActive: true
                    })
                  }}
                >
                  Completed with errors
                </a>
              )}
            {this.state.bulkAlertMessage.progress !== 100 &&
              !this.state.bulkAlertMessage.totals.finished && (
                <div className={classes.progressingMsg}>
                  Actions in progress...
                  <br />
                  {this.state.bulkAlertMessage.totals.saved} of{" "}
                  {this.state.bulkAlertMessage.totals.saving}
                </div>
              )}
            <Box display="flex" alignItems="center" width="100%">
              <Box width="100%" mr={1}>
                <LinearProgress
                  variant="determinate"
                  value={this.state.bulkAlertMessage.progress || 0}
                />
              </Box>
              <Box minWidth={35}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ fontWeight: "bold" }}
                >
                  {this.state.bulkAlertMessage.progress}%
                </Typography>
              </Box>
            </Box>
            {this.state.bulkAlertMessage.totals.failed > 0 &&
              !this.state.bulkAlertMessage.totals.finished && (
                <div className={classes.failedMsg}>
                  {this.state.bulkAlertMessage.totals.failed} failed action
                  {this.state.bulkAlertMessage.totals.failed > 1 ? "s" : ""}
                </div>
              )}
          </div>
        )}
        {this.state.bulkAlertMessage.progress !== 100 &&
          this.state.bulkAlertMessage.totals &&
          this.state.bulkAlertMessage.totals.finished && (
            <Modal
              open={this.state.bulkAlertMessage.totals.finished}
              onClose={() => {
                this.closeBulkModal()
              }}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div className={classes.errorModal}>
                <h3 id="server-modal-title">Here are the failed requests</h3>
                <div id="server-modal-description">
                  <ul>
                    {this.state.bulkAlertMessage.failed &&
                      this.state.bulkAlertMessage.failed.map((n, index) => (
                        <li key={index}>
                          ID #{n.id} --- {n.error} error
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </Modal>
          )}
        {this.state.bulkAlertMessage.progress === 100 && (
          <Modal
            open={true}
            onClose={() => {
              this.closeBulkModal()
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className={classes.errorModal}>
              <h2 id="server-modal-title">Success!</h2>
              <div id="server-modal-description">
                All {this.state.bulkAlertMessage.totals.saving} actions were
                executed correctly
              </div>
            </div>
          </Modal>
        )}
      </div>
    )
  }
}
export default withStyles(styles)(withRouter(useStore(NarrativeEditor)))
