// eslint-disable-next-line no-unused-vars
import React from "react"
import ReactDOM from "react-dom"
import { createBrowserHistory } from "history"
import { Router } from "react-router-dom"
import { Provider } from "mobx-react"
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme
} from "@mui/material/styles"

import RootStore from "./stores/RootStore"

import "bootstrap/dist/css/bootstrap.css"
import "react-sortable-tree/style.css" // This only needs to be imported once in your app
import "assets/css/react-table.css"
import "assets/css/react-datetime.css"
import "assets/css/react-bootstrap-sweetalert.css"
import "assets/css/react-big-calendar.css"
import { globalSettings } from "variables/general"
import { App } from "./App"
import { useGlobalStyles } from "./globalstyles"
import { get } from "tools/request"

const hist = createBrowserHistory()
const rootStore = new RootStore()

function navigateToLoginPage() {
  window.location.href = `/login?rdurl=${encodeURIComponent(
    window.location.href
  )}`
}

function validateDSToken() {
  get(`${globalSettings.apiBaseUrl}/api/account/retrieveaccountdetails`)
    .then(Response => Response.json())
    .then(data => {
      if (!data.content) {
        navigateToLoginPage()
      }
    })
    .catch(err => {
      console.error("Server error", err)
      alert(
        `There appears to be a problem with ${globalSettings.apiBaseUrl}, is api-occidental web server running?`
      )
    })
}

// Never validate the dstoken on the /login page, /logout page, /register page, /forgotpassword page, or the /feedentrypreview page
if (
  window.location.pathname !== null &&
  window.location.pathname.indexOf("/login") === -1 &&
  window.location.pathname.indexOf("/logout") === -1 &&
  window.location.pathname.indexOf("/forgotpassword") === -1 &&
  window.location.pathname.indexOf("/changepassword") === -1 &&
  window.location.pathname.indexOf("/feedentrypreview") === -1 &&
  window.location.pathname.indexOf("/healthz") === -1 &&
  window.location.pathname.indexOf("/narrativeoutlinepreview") === -1
) {
  if (!localStorage.getItem("accessToken")) {
    navigateToLoginPage()
  } else {
    validateDSToken()
  }
}

/**
 * Global theme
 */
const theme = createTheme(
  adaptV4Theme({
    palette: {
      mode: "light",
      primary: {
        main: "#4d9ab5",
        contrastText: "#fff"
      },
      secondary: {
        main: "#666",
        contrastText: "#fff"
      },
      error: {
        main: "#D01111"
      },
      success: {
        main: "#008526"
      }
    },
    typography: {
      htmlFontSize: 12,
      fontSize: 12,
      fontWeightLight: 200,
      fontWeightRegular: 300,
      body1: { fontSize: ".875rem" },
      body2: { fontSize: ".8rem" },
      h1: { fontSize: "1.3em" },
      h2: { fontSize: "1.2em" },
      h3: { fontSize: "1.1em" }
    }
  })
)

function MyThemeProvider({ children }) {
  useGlobalStyles()
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  )
}

ReactDOM.render(
  <Router history={hist}>
    <MyThemeProvider theme={theme}>
      <Provider store={rootStore}>
        <App />
      </Provider>
    </MyThemeProvider>
  </Router>,
  document.getElementById("root")
)
