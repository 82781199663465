import { useEffect, useState } from "react"

import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"

import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import makeStyles from "@mui/styles/makeStyles"

import CustomTable from "components/Table/CustomTable"
import DiffViewer from "components/DiffViewer/DiffViewer"

import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import relativeTime from "dayjs/plugin/relativeTime"
import advancedFormat from "dayjs/plugin/advancedFormat"
dayjs.extend(utc).extend(relativeTime).extend(advancedFormat)

const useStoreData = () => {
  const store = useStore()
  return useObserver(() => ({
    getCTAPreviewDependencyChangeLog:
      store.ctaPreviewDependencyStore.getCTAPreviewDependencyChangeLog,
    ctaPreviewDependencyChangeLog:
      store.ctaPreviewDependencyStore.ctaPreviewDependencyChangeLog,
    accounts: store.accountStore.accounts,
    getAccounts: store.accountStore.getAccounts
  }))
}

const useStyles = makeStyles({
  tableWrapper: {
    paddingTop: "2rem"
  }
})

const CTAChangeLog = props => {
  const {
    ctaPreviewDependencyChangeLog,
    getCTAPreviewDependencyChangeLog,
    accounts,
    getAccounts
  } = useStoreData()
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)

  const columns = [
    {
      id: "expander",
      width: 20,
      header: () => null,
      cell: ({ row }) =>
        row.getCanExpand() ? (
          <IconButton
            {...{
              size: "small",
              onClick: row.getToggleExpandedHandler()
            }}
            size="large"
          >
            {row.getIsExpanded() ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        ) : (
          ""
        )
    },
    {
      header: "Operation Type",
      accessor: "operation",
      sortable: true,
      cell: props => {
        let cellValue
        switch (props.getValue()) {
          case "D":
            cellValue = "Delete"
            break
          case "U":
            cellValue = "Update"
            break
          default:
            cellValue = props.getValue()
        }

        return <span>{cellValue}</span>
      }
    },
    {
      header: "Last Modified",
      accessor: "operationDateTime",
      sortable: true,
      cell: props => (
        <span
          title={dayjs(props.getValue()).format(
            "dddd, MMM DD, YYYY, H:mm:ss A z"
          )}
        >
          {dayjs(props.getValue()).fromNow()}
        </span>
      )
    },
    {
      header: "Updated By",
      accessor: "modifiedByAccountId",
      sortable: true,
      cell: row => {
        const foundAccount = accounts
          .toJS()
          .find(account => account.id === row.getValue())
        return (
          <span>{`${foundAccount?.firstName} ${foundAccount?.lastName}`}</span>
        )
      }
    }
  ]

  const renderSubComponent = ({ row }) => <DiffViewer row={row} />

  useEffect(() => {
    if (!ctaPreviewDependencyChangeLog) {
      getCTAPreviewDependencyChangeLog(props.previewDependencyId || 1)
    }
  }, [])

  useEffect(() => {
    if (!accounts.toJS() || Object.keys(accounts.toJS()).length === 0) {
      getAccounts()
    }
  }, [])

  useEffect(() => {
    if (
      !ctaPreviewDependencyChangeLog ||
      Object.keys(accounts.toJS()).length === 0
    ) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [ctaPreviewDependencyChangeLog, accounts])

  return (
    <Box className={classes.tableWrapper}>
      <CustomTable
        tableName="CTA Preview Dependency Change Log"
        tableColumns={columns}
        tableData={ctaPreviewDependencyChangeLog}
        isLoading={isLoading}
        renderSubComponent={renderSubComponent}
        tableSize="small"
        expanded
        sorted
      />
    </Box>
  )
}

export default CTAChangeLog
