import React, {
  useCallback,
  useEffect,
  useState //, useMemo
} from "react"
import { PropTypes } from "prop-types"
import makeStyles from "@mui/styles/makeStyles"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import CTAStatusTypes from "components/CTAEditor/CTAStatusTypes"
import Card from "@mui/material/Card"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import CardContent from "@mui/material/CardContent"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import Divider from "@mui/material/Divider"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import Grid from "@mui/material/Grid"
import InputAdornment from "@mui/material/InputAdornment"
import LinearProgress from "@mui/material/LinearProgress"
import CircularProgress from "@mui/material/CircularProgress"
import Paper from "@mui/material/Paper"
import TextField from "@mui/material/TextField"
import RadioGroup from "@mui/material/RadioGroup"
import Radio from "@mui/material/Radio"
import Typography from "@mui/material/Typography"
import Autocomplete from "@mui/material/Autocomplete" //createFilterOptions
import InfoIcon from "@mui/icons-material/Info"
import IconButton from "@mui/material/IconButton"
import GridItem from "components/Grid/GridItem"
import { useHistory } from "react-router-dom"

import Helpers from "tools/Helpers.js"
import { urlValidation } from "tools/ValidationHelpers"
import Switch from "@mui/material/Switch"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import { AccordionDetails, AccordionSummary, FormLabel } from "@mui/material"
import Accordion from "@mui/material/Accordion"
import CTAPayoutExceptionMulti from "components/CTAEditor/CTAPayoutExceptionMulti"
import useOfferFormHelper from "tools/CTAv2/OfferFormHelper"
import Cta_v2DAO from "daos/cta_v2DAO"
import TimezoneSelect from "react-timezone-select"
import {
  DesktopDateTimePicker,
  LocalizationProvider
} from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs" //Dayjs
import utc from "dayjs/plugin/utc"
import "dayjs/locale/en"
import OfferLinkAffiliateDataGrid from "./OfferLinkAffiliateDataGrid"
dayjs.extend(utc)

const useStyles = makeStyles({
  CTAGrid: {
    "& div.MuiAutocomplete-tag": {
      backgroundColor: "#DDEAEF",
      height: "24px"
    }
  },
  linkTimeZone: {
    display: "inline-block",
    flexDirection: "row",
    width: "15vw",
    zIndex: "9",
    paddingTop: "7px"
  },
  CTAform: {
    display: "block",
    flexDirection: "row"
  },
  collapseContent: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingBottom: "15px",
    borderRadius: "0px"
  },
  monetizationCard: {
    backgroundColor: "#DDEAEF",
    display: "inline-flex",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    borderRadius: "0px"
  },
  monetizationArea: {
    marginTop: "20px",
    marginBottom: "20px"
  },
  infoHeader: {
    width: "75vw",
    padding: "5px",
    borderRadius: "0px",
    marginTop: "5px",
    paddingLeft: "15px",
    fontWeight: "bold"
  },
  infoValidationHeader: {
    textAlign: "center",
    color: "#inherit"
  },
  addScrollIfNeeded: {
    maxHeight: "300px",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "10px 0 0 0",
    "& li": {
      textAlign: "left"
    }
  },
  monetizationHeader: {
    backgroundColor: "#DDEAEF",
    padding: "5px",
    borderRadius: "0px",
    marginTop: "5px",
    paddingLeft: "15px",
    fontWeight: "bold"
  },
  autocompleteCTA: {
    size: "medium",
    backgroundColor: "#FFF"
  },
  conversionType: {
    "& .MuiTypography-root": {
      fontWeight: "bold",
      color: "#888"
    }
  },
  ctaTop: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
    display: "flex",
    marginRight: "10px",
    borderRadius: "0px"
  },
  infoCard: {
    display: "inline-flex",
    flexDirection: "row",
    paddingLeft: "15px",
    paddingBottom: "9px",
    borderRadius: "0px"
  },
  CTATitle: {
    paddingLeft: "15px",
    paddingTop: "5px",
    fontWeight: "bold"
  },
  CTADivide: {
    height: "2px",
    backgroundColor: "#DDEAEF",
    margin: "20px 9px 20px 9px"
  },
  CTAmodalIcon: {
    fontSize: "1.25rem",
    color: "#144e68",
    marginLeft: "2px",
    padding: "0"
  },
  accordionSummary: {
    pointerEvents: "none"
  },
  accordionIcon: {
    pointerEvents: "auto"
  },
  CTAButton: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "15px",
    paddingBottom: "15px",
    marginTop: "40px"
  },
  progressContainer: {
    textAlign: "center",
    marginTop: "100px",
    width: "100%",
    height: "100%",
    background: "#fff !important"
  },
  buttonStyle: {
    fontSize: "12px",
    padding: "14px 23px",
    minWidth: "110px",
    marginRight: "4px"
  },
  "&.MuiSelect-select.MuiSelect-select": {
    border: "2px red solid !important"
  },
  checkRegion: {
    color: "#144e68",
    "&$checked": {
      color: "#144e68"
    }
  },
  payoutDetails: {
    flexDirection: "column"
  }
})

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    companiesInfo: store.ctav2Store.companiesInfo,
    getCompaniesInfo: store.ctav2Store.getCompaniesInfo,
    organizations: store.ctav2Store.organizationsInfo,
    getOrganizations: store.ctav2Store.getOrganizationsInfo,
    regionsInfo: store.ctav2Store.regionsInfo,
    getRegionsInfo: store.ctav2Store.getRegionsInfo,
    linkTypeInfo: store.ctav2Store.linkTypeInfo,
    getLinkTypeInfo: store.ctav2Store.getLinkTypeInfo,
    intentInfo: store.ctav2Store.intentInfo,
    getIntentInfo: store.ctav2Store.getIntentInfo,
    getLinkInfo: store.ctav2Store.getLinkInfo,
    linkInfo: store.ctav2Store.linkInfo,
    getAffiliatesByOrgId: store.ctav2Store.getAffiliatesByOrgId,
    affiliates: store.ctav2Store.affiliates,
    getLinkDisplayInfo: store.ctav2Store.getLinkDisplayInfo,
    offerLinksInfo: store.ctav2Store.offerLinksInfo,
    accounts: store.accountStore.accounts,
    getAccounts: store.accountStore.getAccounts,
    setIsError: store.uiStore.setIsError,
    setErrorMessage: store.uiStore.setErrorMessage,
    setDialogWarningNoBtnMessage: store.uiStore.setDialogWarningNoBtnMessage,
    setDialogWarningNoBtnOpen: store.uiStore.setDialogWarningNoBtnOpen,
    setDialogInfoMessage: store.uiStore.setDialogInfoMessage,
    setDialogInfoOpen: store.uiStore.setDialogInfoOpen,
    dialogInfoOpen: store.uiStore.dialogInfoOpen,
    setDialogSuccessMessage: store.uiStore.setDialogSuccessMessage,
    setDialogSuccessOpen: store.uiStore.setDialogSuccessOpen,
    dialogSuccessOpen: store.uiStore.dialogSuccessOpen,
    setDialogFailMessage: store.uiStore.setDialogFailMessage,
    setDialogFailOpen: store.uiStore.setDialogFailOpen,
    dialogFailOpen: store.uiStore.dialogFailOpen,
    availableShortCodes: store.ctav2Store.availableShortCodes,
    getAvailableShortCodes: store.ctav2Store.getAvailableShortCodes,
    randomShortCode: store.ctav2Store.randomShortCode,
    getRandomShortCode: store.ctav2Store.getRandomShortCode,
    setRandomShortCode: store.ctav2Store.setRandomShortCode
  }))
}

function CTAOfferLinkForm(props) {
  const history = useHistory()
  const classes = useStyles()
  const {
    organizations,
    getOrganizations,
    getRegionsInfo,
    getCompaniesInfo,
    getLinkTypeInfo,
    getIntentInfo,
    getLinkInfo,
    getAffiliatesByOrgId,
    affiliates,
    getLinkDisplayInfo,
    offerLinksInfo,
    regionsInfo,
    companiesInfo,
    intentInfo,
    ctaInfoFS,
    linkInfo,
    linkTypeInfo,
    getAccounts,
    accounts,
    setIsError,
    setErrorMessage,
    setDialogWarningNoBtnOpen,
    setDialogWarningNoBtnMessage,
    setDialogInfoMessage,
    setDialogInfoOpen,
    dialogInfoOpen,
    setDialogSuccessMessage,
    setDialogSuccessOpen,
    setDialogFailMessage,
    setDialogFailOpen,
    getAvailableShortCodes,
    availableShortCodes,
    getRandomShortCode,
    setRandomShortCode,
    randomShortCode
  } = useStoreData()
  const { linkInformation, openOfferLinkBlocks } = props
  const { formValues } = ctaInfoFS || {}
  const [myFormValues, setMyFormValues] = useState({
    isConversionPercentPayOut: true,
    region: [],
    callToActionPayOff: ""
  })
  const [ctaFormErrors, setCtaFormErrors] = useState({})
  const [resetEditor, setResetEditor] = useState(false)
  const [refreshReset, setRefreshReset] = useState(true)
  const [linkIsLoading, setLinkIsLoading] = useState(false)
  const [creatingNewLink, setCreatingNewLink] = useState(0)
  const [origFormValues, setOrigFormValues] = useState({})
  const [disableCancel, setDisableCancel] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const [exceptionsBeingEdited, setExceptionsBeingEdited] = useState([])

  const [resetPayoutExceptions, setResetPayoutExceptions] = useState(true)
  const [haveEditedPayoutExceptions, setHaveEditedPayoutExceptions] =
    useState(false)
  const [hadProperties, setHadProperties] = useState(0)
  const [cyclePropertyAutoselect, setCyclePropertyAutoselect] = useState(false) //I need this to account for an MUI Autocomplete bug, changing Options not updated

  const [addingPayoutExceptions, setAddingPayoutExceptions] = useState(1)
  const [appliedExceptions, setAppliedExceptions] = useState(false)
  const [getOrgAffiliatesFirst, setGetOrgAffiliatesFirst] = useState("")
  const [
    selectedOrgHasAffiliateRelationship,
    setSelectedOrgHasAffiliateRelationship
  ] = useState(false)

  const {
    affiliateId,
    companyId,
    startDate,
    endDate,
    linkUrl,
    shortNameOffer,
    linkTypeId,
    promoText,
    assetLink,
    organizationId,
    propertyIds,
    callToActionPayOff, //payout
    region,
    groupRegionByPayout,
    ownerAccountId,
    status,
    isArchived,
    disableSaveButton,
    isConversionPercentPayOut
  } = myFormValues || {}

  const linkId = myFormValues.id || myFormValues.linkId
  const timeZoneIANA = myFormValues.timeZoneIANA || dayjs.tz.guess()

  const ctaIntent =
    myFormValues.ctaIntent ||
    (props.source === "disclaimerLinkForm"
      ? 2
      : props.source === "offerLinkForm"
      ? 1
      : "")

  const _USA_REGION = 237

  const [regionByPayout, setRegionByPayout] = useState(
    myFormValues?.groupRegionByPayout || []
  )
  const [payoutException, setPayoutException] = useState(
    myFormValues?.payoutException || false
  )
  const [resetPayouts, setResetPayouts] = useState(false)
  const [exceptionPayouts, setExceptionPayouts] = useState(
    myFormValues?.justPayoutsGrouped || []
  )

  const [orgs, setOrgs] = useState([])
  const [companies, setCompanies] = useState([])
  const [regionsList, setRegionsList] = useState([])
  const [intentTypes, setIntentTypes] = useState([])
  const [linkTypes, setLinkTypes] = useState([])
  const [accordionVisible, setAccordionVisible] = useState(false)

  let allUSA = []
  if (myFormValues?.region?.includes(_USA_REGION)) {
    //USA
    regionsList.forEach(itm => {
      if (itm.id !== _USA_REGION) {
        allUSA.push(itm.id)
      }
    })
  }

  const {
    checkIfWeNeedToDisablePayoutExceptions,
    removeGroupPayoutExceptions,
    removePayoutExceptions,
    updateSelectedRegions,
    createLinkObject,
    unselectUSA_ModifyRegionsByPayout,
    defaultStatesExist,
    addRegionsToPayoutGroups,
    payoutExceptionsExist,
    checkIfWeNeedToRemoveRegionFromExceptions,
    thisIsAPayoutException,
    linkSaveObject,
    formValueValidation,
    checkForChangedNavElements
  } = useOfferFormHelper({
    setResetPayouts,
    setAddingPayoutExceptions,
    addingPayoutExceptions,
    formValues,
    regionByPayout,
    setExceptionPayouts,
    exceptionPayouts,
    setPayoutException,
    payoutException,
    region,
    regionsList,
    regionsInfo,
    setRegionByPayout,
    callToActionPayOff,
    myFormValues,
    setOrigFormValues,
    companiesInfo,
    organizations,
    classes,
    dayjs,
    timeZoneIANA,
    _USA_REGION,
    allUSA
  })

  const clearStartDateFields = () => {
    const newFormValues = {
      ...myFormValues,
      startDate: dayjs(startDate).startOf("day"),
      disableSaveButton: false,
      editingCTAs: true
    }
    setMyFormValues(newFormValues)
  }
  const clearEndDateFields = () => {
    const newFormValues = {
      ...myFormValues,
      endDate: null,
      disableSaveButton: false,
      editingCTAs: true
    }
    setMyFormValues(newFormValues)
  }
  const getEndDateError = value => {
    if (value !== null && startDate === null) {
      return "Start Date required with End Date"
    }
    if (value === null) {
      return ""
    }
    return ""
  }
  const endDateErrorMessage = getEndDateError(endDate)

  const createGroupPayoutExceptions = useCallback(() => {
    setAddingPayoutExceptions(addingPayoutExceptions - 1)
    let tmp = typeof regionByPayout === "object" ? regionByPayout : []
    tmp.push([
      {
        index: addingPayoutExceptions,
        regionId: 0,
        payoutPercentage: 0,
        display: true,
        isNew: true
      }
    ])
    setRegionByPayout(tmp)
  }, [addingPayoutExceptions, regionByPayout])

  const handleAutocompleteChangeOrg = (event, newValue, reason, name) => {
    const newFormValues = {
      ...myFormValues,
      [name]: newValue?.id || null
    }
    if (newValue?.id && newValue.id !== organizationId) {
      getAffiliatesByOrgId(newValue.id)
      newFormValues["affiliateId"] = ""
      newFormValues["propertyIds"] = []
      setSelectedOrgHasAffiliateRelationship(false)
    }
    setMyFormValues(newFormValues)
  }

  const handleAutoCompleteMultiChangeProperty = (
    event,
    newValue,
    reason,
    name
  ) => {
    if (!event || typeof event === "string" || !newValue) {
      return
    }
    if (newValue.length) {
      const ids = []
      newValue.forEach(x => ids.push(x.id))

      let newVals = []
      if (ids.includes(-1)) {
        //Select All chosen
        properties?.forEach(x => {
          newVals.push(x.id)
        })
      } else if (ids.includes(-2)) {
        //Remove All chosen
        newVals = []
      } else {
        newValue.forEach(itm => newVals.push(itm.id))
      }
      if (newVals.length > 0) {
        getAffiliatesByOrgId(newVals.filter(x => x > 0).join(","))
      }
      const newFormValues = {
        ...myFormValues,
        affiliateId: newVals.length > 0 ? myFormValues.affiliateId : "",
        [name]: newVals
      }
      setMyFormValues(newFormValues)
    } else {
      const newFormValues = {
        ...myFormValues,
        affiliateId: "",
        [name]: []
      }
      setMyFormValues(newFormValues)
      setSelectedOrgHasAffiliateRelationship(false)
    }
  }

  const handleAutocompleteChange = (event, newValue, reason, name) => {
    const newFormValues = {
      ...myFormValues,
      disableSaveButton: false,
      [name]: newValue?.id || null,
      editingCTAs: true
    }
    setMyFormValues(newFormValues)
  }

  const onTimeZoneChange = (zone, name) => {
    const newFormValues = {
      ...myFormValues,
      disableSaveButton: false,
      [name]: (zone && zone.value) || null,
      editingCTAs: true
    }
    setMyFormValues(newFormValues)
  }

  const handleDateChange = (date, name) => {
    const newFormValues = {
      ...myFormValues,
      [name]: date,
      disableSaveButton: false,
      editingCTAs: true
    }
    setMyFormValues(newFormValues)
  }

  const handleLinkChange = name => event => {
    const val =
      name !== "shortNameOffer"
        ? event.target.value
        : event.target.value.substring(0, 50)
    const newFormValues = {
      ...myFormValues,
      disableSaveButton: false,
      [name]: val,
      editingCTAs: true
    }
    setMyFormValues(newFormValues)
  }

  const handleCheckboxChange = e => {
    const newFormValues = {
      ...myFormValues,
      disableSaveButton: false,
      [e.target.id]: e.target.checked,
      editingCTAs: true
    }
    setMyFormValues(newFormValues)
  }

  const handleCtaChangeNumber = name => event => {
    let myNumber = event.target.value
    if (myNumber === "") {
      myNumber = null
    } else {
      let tmp = myNumber.split(/\./)
      if (tmp[1] && tmp[1].length > 2) {
        return
      }
    }
    const newFormValues = {
      ...myFormValues,
      disableSaveButton: false,
      [name]: myNumber
        ? isConversionPercentPayOut && Number(myNumber) > 100
          ? 100
          : Number(myNumber)
        : null,
      editingCTAs: true
    }
    setMyFormValues(newFormValues)
  }

  const handleRadioChange = name => e => {
    if (
      (e.target.value === "Percent" && myFormValues[name] === false) ||
      (e.target.value === "Fee" && myFormValues[name] === true)
    ) {
      const newFormValues = {
        ...myFormValues,
        disableSaveButton: false,
        callToActionPayOff: 0,
        [name]:
          e.target.value === "Fee"
            ? false
            : e.target.value === "Percent"
            ? true
            : undefined,
        editingCTAs: true
      }
      setMyFormValues(newFormValues)
    }
  }

  const handleAutoCompleteMultiChange = (event, newValue, reason, name) => {
    let newVals = []
    let nv = []
    newValue.forEach(itm => newVals.push(itm.id))
    newValue.forEach(itm => nv.push(itm.id))
    let oldVsNew = myFormValues[name]
      ? myFormValues[name].filter(x => !newVals.includes(x))
      : []
    if (oldVsNew[0] && oldVsNew[0] === _USA_REGION && oldVsNew.length) {
      let tmp = defaultStatesExist(newVals)
      if (tmp && tmp.length) {
        unselectUSA_ModifyRegionsByPayout(tmp)
      } else if (!tmp && payoutExceptionsExist()) {
        alert(
          "Any region with a payout exception must be removed within the payout exception field prior to being removed from the Region field"
        )
        return
      }
    } else if (
      newVals.includes(_USA_REGION) &&
      newVals.length > 1 &&
      region[0] &&
      region[0] === _USA_REGION
    ) {
      alert(
        "You have USA selected as the Region, you cannot select individual States.  Remove the USA to select individual States"
      )
      return
    } else if (newVals.includes(_USA_REGION) && newVals.length > 1) {
      alert(
        "You have selected and individual State(s), you can only select USA if you have no States selected"
      )
      return
    } else if (oldVsNew[0] && thisIsAPayoutException(oldVsNew)) {
      alert(
        "Any region with a payout exception must be removed within the payout exception field prior to being removed from the Region field"
      )
      return
    } else if (
      oldVsNew.includes(!_USA_REGION) &&
      !newVals.includes(_USA_REGION) &&
      payoutExceptionsExist()
    ) {
      alert(
        "Any region with a payout exception must be removed within the payout exception field prior to being removed from the Region field"
      )
      return
    }
    let allArePayoutExceptions = true
    newVals.forEach(val => {
      allArePayoutExceptions =
        allArePayoutExceptions && thisIsAPayoutException([val])
    })
    if (allArePayoutExceptions && newVals.length) {
      alert("You must have at least 1 non-payout exception Region selected")
      return
    }
    if (origFormValues?.region?.length === 0) {
      addRegionsToPayoutGroups(newValue)
    }
    if (oldVsNew.length) {
      checkIfWeNeedToRemoveRegionFromExceptions(oldVsNew)
    }
    const newFormValues = {
      ...myFormValues,
      disableSaveButton: false,
      [name]: nv,
      editingCTAs: true
    }
    setMyFormValues(newFormValues)
  }

  const handleInputValidation = inputId => {
    const errorObj = { ...ctaFormErrors }
    if (inputId === "assetLink") {
      const urlV = urlValidation(myFormValues.assetLink)
      if (!urlV && myFormValues.assetLink) {
        errorObj.assetLinkMsg = "Asset Link must be a valid URL."
        errorObj[inputId] = true
        errorObj.validationHasError = true
      } else {
        errorObj[inputId] = false
        errorObj.validationHasError = false
      }
    } else if (inputId === "linkUrl") {
      const urlV = urlValidation(myFormValues.linkUrl)
      if (!urlV && myFormValues.linkUrl) {
        errorObj.linkURLMsg = "Link URL must be a valid URL."
        errorObj[inputId] = true
        errorObj.validationHasError = true
      } else {
        errorObj[inputId] = false
        errorObj.validationHasError = false
      }
    }
    setCtaFormErrors(errorObj)
  }

  const updateSelectedRegionsOnBlur = (vals, myIndex) => {
    let tmpArray = []
    let newArray = []
    let found = false
    vals.forEach(val => {
      let foundVal = false
      regionByPayout?.forEach((itm2, index) => {
        for (let i = itm2.length - 1; i > -1; i -= 1) {
          if (val.id === itm2[i].id) {
            found = true
            foundVal = true
            itm2[i].payoutException = val.payoutPercentage
            tmpArray.push(itm2[i])
            regionByPayout[index].splice(i, 1)
          }
        }
      })
      if (!foundVal) {
        newArray.push(val)
      }
    })
    regionByPayout[myIndex] = [] //10 April
    tmpArray.forEach(itm => {
      regionByPayout[myIndex].push(itm)
    })
    if (!found && vals.length < regionByPayout[myIndex].length) {
      //Not found because we are removing values
      for (let i = regionByPayout[myIndex].length; i > -1; i -= 1) {
        let found = false
        vals.forEach(val => {
          if (val.id === regionByPayout[myIndex][i].id) {
            found = true
          }
        })
        if (!found) {
          regionByPayout[myIndex].splice(i, 1)
        }
      }
    }

    newArray.forEach(itm => {
      regionByPayout[myIndex].push(itm)
    })

    setRegionByPayout(regionByPayout)
    setAppliedExceptions(true)
  }

  // on first render
  useEffect(() => {
    getCompaniesInfo()
    getRegionsInfo() //Need real regions list
    getOrganizations()
    getLinkTypeInfo()
    getIntentInfo()
    getAccounts()
  }, [])

  useEffect(() => {
    if (!linkInformation?.new) {
      if (linkInfo?.id) {
        if (resetEditor) {
          setResetEditor(false)
          setRefreshReset(false)
        } else if (refreshReset) {
          const myFV = createLinkObject(linkInfo, false, false)
          setMyFormValues(myFV)
          setResetEditor(true)
          setRefreshReset(false)
          setLinkIsLoading(false)
          if (linkInfo.affiliateId === 1) {
            setAccordionVisible(true)
          } else {
            setRandomShortCode([])
          }
        }
      }
    }
  }, [linkInfo, resetEditor])

  useEffect(() => {
    if (companiesInfo?.length) {
      setCompanies(companiesInfo)
    }
  }, [companiesInfo])

  useEffect(() => {
    if (getOrgAffiliatesFirst) {
      getAffiliatesByOrgId(getOrgAffiliatesFirst)
      setGetOrgAffiliatesFirst("")
    }
  }, [affiliates, getOrgAffiliatesFirst])

  useEffect(() => {
    if (linkInformation?.id && linkInformation?.id !== linkId) {
      if (linkInfo?.id) {
        if (linkInfo.id === linkInformation.id) {
          if (resetEditor === true) {
            setResetEditor(false)
          }
          const myFV = createLinkObject(linkInfo, false, false)
          setMyFormValues(myFV)
          if (myFV.affiliateId === 1) {
            setAccordionVisible(true)
          } else {
            setRandomShortCode([])
          }
          setPayoutException(myFV.payoutException)
          setExceptionPayouts(myFV.justPayoutsGrouped)
          setRegionByPayout(myFV.groupRegionByPayout)
          setSelectedOrgHasAffiliateRelationship(false)
          if (myFV.organizationId) {
            if (myFV.propertyIds.length) {
              getAffiliatesByOrgId(myFV.organizationId)
              setGetOrgAffiliatesFirst(myFV.propertyIds.join(","))
            } else {
              getAffiliatesByOrgId(myFV.organizationId)
            }
          }
          setLinkIsLoading(false)
        } else if (accounts?.toJS().length) {
          if (linkInformation?.id) {
            setLinkIsLoading(true)
            getLinkInfo(linkInformation?.id)
          }
        }
      } else if (accounts?.toJS().length) {
        if (linkInformation?.id) {
          setLinkIsLoading(true)
          getLinkInfo(linkInformation?.id)
        }
      }
    } else if (linkInformation?.new === true) {
      if (resetEditor === true) {
        setResetEditor(false)
      }
      const myFV = createLinkObject({}, true, false)
      setMyFormValues(myFV)
    }
  }, [linkInformation, linkInfo, resetEditor])

  useEffect(() => {
    if (linkId) {
      getAvailableShortCodes(linkId)
      getRandomShortCode(linkId)
    }
  }, [linkId, getAvailableShortCodes])

  useEffect(() => {
    if (availableShortCodes.length > 0) {
      setAccordionVisible(true)
    } else {
      setAccordionVisible(false)
      setRandomShortCode([])
    }
  }, [availableShortCodes])

  useEffect(() => {
    if (accounts?.toJS().length) {
      if (linkInformation?.id && !linkInfo?.id) {
        getLinkInfo(linkInformation.id)
        if (linkInformation.navigate) {
          openOfferLinkBlocks()
        }
      } else if (linkInformation?.new === true) {
        const myFV = createLinkObject({}, true, false)
        setMyFormValues(myFV)
        setResetEditor(true)
      } else if (linkInformation?.isNewRightClickLink === true) {
        const myFV = createLinkObject(linkInformation, true, false)
        setMyFormValues(myFV)
        getAffiliatesByOrgId(myFV.organizationId)
      }
    }
  }, [accounts])

  useEffect(() => {
    if (regionsInfo?.length) {
      setRegionsList(regionsInfo)
    }
  }, [regionsInfo])

  useEffect(() => {
    if (linkTypeInfo?.length) {
      setLinkTypes(linkTypeInfo)
    }
  }, [linkTypeInfo])

  useEffect(() => {
    if (intentInfo?.length) {
      setIntentTypes(intentInfo)
    }
  }, [intentInfo])

  useEffect(() => {
    if (appliedExceptions) {
      setAppliedExceptions(false)
    }
  }, [appliedExceptions])

  useEffect(() => {
    if (organizations?.toJS().length) {
      const orgss = organizations.toJS()
      setOrgs(orgss)
    }
  }, [organizations])

  useEffect(() => {
    if (
      organizationId &&
      affiliates.length === 0 &&
      organizations.length &&
      !linkIsLoading
    ) {
      setSelectedOrgHasAffiliateRelationship(false)
      setIsError(true)
      setErrorMessage(
        "There are no affiliates with this Organzation/Property combo"
      )
    } else if (organizationId && affiliates.length > 0) {
      setIsError(false)
      affiliates.forEach(aff => {
        const orgIdList = aff.requestOrganizationId?.toString().split(",")
        if (orgIdList && orgIdList.includes(organizationId.toString())) {
          setSelectedOrgHasAffiliateRelationship(true)
        }
      })
    }
  }, [affiliates, organizationId])

  useEffect(() => {
    if (creatingNewLink !== 0 && offerLinksInfo.length && !dialogInfoOpen) {
      history.push(`/portal/manage-ctas/offer?linkid=${creatingNewLink}`)
      setCreatingNewLink(0)
      getLinkDisplayInfo(1)
    }
  }, [offerLinksInfo, dialogInfoOpen, creatingNewLink])

  useEffect(() => {
    //I need this to account for an MUI Autocomplete bug, changing Options not updated
    if (cyclePropertyAutoselect) {
      setCyclePropertyAutoselect(false)
    }
  }, [cyclePropertyAutoselect])

  const validLinkUrl = linkUrl && !ctaFormErrors.linkUrl //To disable/enable Preview Link

  const linkSaveMessage = (header, list) => (
    <div>
      <div className={classes.infoValidationHeader}>{header}</div>
      <div className={classes.addScrollIfNeeded}>
        <ol>
          {list?.map((itm, i) => (
            <li key={i}>{itm}</li>
          ))}
        </ol>
      </div>
    </div>
  )

  const handleSaveForm = () => {
    const foundNotValid = formValueValidation(myFormValues)
    const navElementsHaveChanged =
      linkInformation?.new || linkInformation?.isNewRightClickLink
        ? false
        : checkForChangedNavElements(myFormValues, linkInfo)
    if (!foundNotValid) {
      let startDateTimeInTimeZone = null
      if (startDate) {
        startDateTimeInTimeZone = dayjs(startDate).toISOString()
      }

      let endDateTimeInTimeZone = null
      if (endDate) {
        endDateTimeInTimeZone = dayjs(endDate).toISOString()
      }
      const newFormValues = {
        ...myFormValues,
        startDateTimeInTimeZone,
        endDateTimeInTimeZone
      }
      const linkObject = linkSaveObject(newFormValues, "offer")
      setIsSaving(true)
      Cta_v2DAO.postLinkInfo(linkObject)
        .then(response => {
          setIsSaving(false)
          if (response.errors) {
            let str = []
            Object.keys(response.errors).forEach(k => {
              if (typeof response.errors[k] === "object") {
                let myStr = []
                response.errors[k].forEach(itm => {
                  myStr.push(itm)
                })
                str.push({ type: k, value: myStr.join("\n") })
              } else {
                str.push({ type: k, value: response.errors[k] })
              }
            })
            setDialogFailMessage(str)
            setDialogFailOpen(true)
            setDisableCancel(false)
          } else {
            let str = linkObject.id
              ? "Link Update succeeded"
              : `Link Creation succeeded. New ID is:${response.id}`
            if (response.message && response.message.length > 0) {
              const msg = response.message
              const scsMessage = linkSaveMessage(str, msg)
              setDialogSuccessMessage(scsMessage)
              setDialogSuccessOpen(true)
            } else {
              setDialogSuccessMessage(str)
              setDialogSuccessOpen(true)
            }
            if (!linkObject.id && response.id) {
              const newFormValues = {
                ...myFormValues,
                id: response.id
              }
              setMyFormValues(newFormValues)
              getLinkDisplayInfo(1)
              setCreatingNewLink(response.id)
            } else if (navElementsHaveChanged) {
              getLinkDisplayInfo(1)
              getLinkInfo(linkObject.id)
            }
            setDisableCancel(false)
            //check affiliateId and set URL accordion to visible
            if (linkId?.affiliateId === 1) {
              setAccordionVisible(true)
            } else {
              setRandomShortCode([])
            }
            getAvailableShortCodes(response.id || myFormValues.id).then(
              shortCodes => {
                if (Array.isArray(shortCodes) && shortCodes.length > 0) {
                  setAccordionVisible(true)
                } else {
                  setAccordionVisible(false)
                  setRandomShortCode([])
                }
              }
            )
            getRandomShortCode(response.id || myFormValues.id)
          }
        })
        .catch(err => {
          if (err.message) {
            let str = []
            Object.keys(err).forEach(k => {
              if (typeof err[k] === "object") {
                let myStr = []
                err[k].forEach(itm => {
                  myStr.push(itm)
                })
                str.push({ type: k, value: myStr.join("\n") })
              } else {
                str.push({ type: k, value: err[k] })
              }
            })
            const msg = str.length
              ? str
              : [
                  { type: "message", value: err?.message },
                  { type: "stack", value: err?.stack }
                ]
            setDialogFailMessage(msg)
            setDialogFailOpen(true)
          }
        })
    } else {
      setDialogWarningNoBtnOpen(true)
      setDialogWarningNoBtnMessage(foundNotValid)
      setDisableCancel(false)
    }
  }

  const infoDialog = () => (
    <>
      <div>
        <h3 id="alert-dialog-title">Company Information:</h3>
        <div>
          <p>
            A <strong>Company</strong> denotes the “parent” group that the offer
            is about (ie GameSys owns Bally and is the company for all Bally
            CTAs).
          </p>
          <p>
            An <strong>Affiliate </strong>owns the CTA link and the offer based
            upon contractual agreements with those Companies.
          </p>
          <ul>
            <li>
              If the Affiliate drop-down is blank check Manage Affiliates to
              confirm Org / Property contracts.
            </li>
          </ul>
          <p>
            When the <strong>Link URL </strong> field is edited, the update will
            cascade down to all Affiliate URLs within associated CTA Variant
            templates..
          </p>
        </div>
      </div>
    </>
  )

  const noOwner = {
    name: "NONE",
    firstName: "NONE",
    lastName: "",
    id: null
  }
  const allOrgs = [...organizations]
  let correctOrg = allOrgs.filter(itm => itm.id === organizationId)
  const properties = (correctOrg[0] && correctOrg[0].properties) || []

  if (
    properties.length > 0 &&
    selectedOrgHasAffiliateRelationship &&
    correctOrg[0] &&
    correctOrg[0].id
  ) {
    if (properties.filter(x => x.id === organizationId).length === 0) {
      properties.push({
        id: correctOrg[0].id,
        name: `${correctOrg[0].name} (Parent)`
      })
    }
  }

  const propertiesOptionsArray = !properties?.length
    ? []
    : [
        properties?.length === propertyIds?.length
          ? { id: -2, name: "Remove All" }
          : {
              id: -1,
              name: "Select All"
            }
      ].concat(
        properties
          ?.slice()
          .sort(function (a, b) {
            return a.name.match(/\(parent\)/i)
              ? -1
              : a.name.localeCompare(b.name)
          })
          .sort(Helpers.sortByName)
      ) || []

  const hasProperties = propertiesOptionsArray?.length || 0
  if (hadProperties !== hasProperties) {
    setHadProperties(hasProperties)
    setCyclePropertyAutoselect(true)
  }

  const showAffiliateList =
    hasProperties === 0 ? !!organizationId : propertyIds.length > 0

  const sortedAccounts = [noOwner].concat(
    accounts
      ?.toJS()
      .filter(itm => itm.status === "Active")
      .sort((a, b) => {
        // Use toUpperCase() to ignore character casing
        const nameA = `${a.firstName} ${a.lastName}`.toUpperCase()
        const nameB = `${b.firstName} ${b.lastName}`.toUpperCase()
        let comparison = 0
        if (nameA > nameB) {
          comparison = 1
        } else if (nameA < nameB) {
          comparison = -1
        }
        return comparison
      })
  )

  const conversionType = isConversionPercentPayOut ? "Percent" : "Fee"
  const isEditingPayout =
    regionByPayout &&
    regionByPayout.length &&
    regionByPayout[regionByPayout.length - 1] &&
    regionByPayout[regionByPayout.length - 1][0] &&
    !regionByPayout[regionByPayout.length - 1][0].id
      ? true
      : !checkIfWeNeedToDisablePayoutExceptions()
      ? false
      : true

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = (
    <CheckBoxIcon fontSize="small" className={classes.checkRegion} />
  )
  const ctaId = myFormValues.ctaId

  return (
    <div aria-label="CTA Offer Link Form">
      {(linkIsLoading || isSaving) && <LinearProgress />}
      {linkIsLoading && (
        <div className={classes.progressContainer}>
          <CircularProgress size={100} />
        </div>
      )}
      {!resetEditor && !linkIsLoading && (
        <Grid className={classes.CTAGrid}>
          <Paper>
            <GridItem xs={12}>
              <Card elevation={0}>
                <CardContent className={classes.collapseContent}>
                  <GridItem xs={6} className={classes.infoHeader}>
                    <Typography style={{ fontWeight: "bold" }}>
                      Offer Link Info
                      <IconButton
                        //onClick={handleClickOpen}
                        onClickCapture={() => {
                          setDialogInfoMessage(infoDialog())
                          setDialogInfoOpen(true)
                        }}
                        className={classes.CTAmodalIcon}
                        size="large"
                      >
                        <InfoIcon variant="outlined" color="primary">
                          Open alert dialog
                        </InfoIcon>
                      </IconButton>
                    </Typography>
                  </GridItem>
                  <GridItem xs={4} style={{ width: "15vw" }}>
                    <Autocomplete
                      className={classes.ctaTop}
                      size="small"
                      fullWidth
                      options={sortedAccounts || []}
                      required={true}
                      getOptionLabel={option =>
                        `${option.firstName} ${option.lastName}`
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="OWNER"
                          variant="outlined"
                          key={params.id}
                          value={params.name}
                        />
                      )}
                      value={
                        sortedAccounts.find(
                          acct => acct.id === ownerAccountId
                        ) ?? noOwner
                      }
                      onChange={(event, newValue, reason) => {
                        handleAutocompleteChange(
                          event,
                          newValue,
                          reason,
                          "ownerAccountId"
                        )
                      }}
                    />
                  </GridItem>
                  <GridItem xs={3} variant="outlined">
                    <CTAStatusTypes
                      value={status || ""}
                      onChange={e => {
                        const newFormValues = {
                          ...myFormValues,
                          status: e,
                          disableSaveButton: false,
                          editingContract: true
                        }
                        setMyFormValues(newFormValues)
                      }}
                    />
                  </GridItem>
                </CardContent>
              </Card>
            </GridItem>
            <Grid>
              <Card elevation={0}>
                <FormControl
                  margin="normal"
                  fullWidth
                  className={classes.infoCard}
                >
                  {/* the field below will be fetch from tree by user */}
                  <GridItem xs>
                    {!!linkId && (
                      <TextField
                        label="LINK ID"
                        id="linkId"
                        variant="outlined"
                        value={linkId}
                        fullWidth={true}
                        disabled={true}
                      />
                    )}
                    {!linkId && (
                      <TextField
                        label="NEW LINK"
                        id="linkId"
                        variant="outlined"
                        fullWidth={true}
                        disabled={true}
                      />
                    )}
                  </GridItem>
                  <GridItem xs>
                    <TextField
                      label="Link URL"
                      id="linkUrl"
                      variant="outlined"
                      fullWidth={true}
                      required={true}
                      value={linkUrl}
                      onChange={handleLinkChange("linkUrl")}
                      onBlur={() => handleInputValidation("linkUrl")}
                      error={ctaFormErrors.linkUrl}
                      helperText={
                        ctaFormErrors.linkUrl && ctaFormErrors.linkURLMsg
                      }
                      nputLabelProps={{ shrink: true }}
                    />
                  </GridItem>
                  <GridItem xs>
                    {intentTypes?.length > 0 && (
                      <Autocomplete
                        fullWidth
                        options={
                          intentTypes?.slice().sort(function (a, b) {
                            return a.name && a.name.localeCompare(b.name)
                          }) || []
                        }
                        required={true}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="CTA INTENT"
                            variant="outlined"
                            key={params.id}
                            value={params.name}
                            required={true}
                            error={ctaFormErrors.ctaIntent}
                            helperText={
                              ctaFormErrors.ctaIntent
                                ? "CTA INTENT is Required"
                                : ""
                            }
                          />
                        )}
                        value={intentTypes?.find(it => {
                          if (it.id === ctaIntent) {
                            return true
                          } else {
                            return false
                          }
                        })}
                        onChange={(event, newValue, reason) => {
                          handleAutocompleteChange(
                            event,
                            newValue,
                            reason,
                            "ctaIntent"
                          )
                        }}
                        onBlur={() => handleInputValidation("ctaIntent")}
                        disabled={!!ctaIntent}
                      />
                    )}
                  </GridItem>
                  <GridItem xs>
                    {companies?.length > 0 && (
                      <Autocomplete
                        className={classes.autocompleteCTA}
                        options={
                          companies.slice().sort(Helpers.sortByName) || []
                        }
                        variant="outlined"
                        getOptionLabel={option => option.name}
                        value={companies?.find(
                          company => company.id === companyId
                        )}
                        error={!companies && companies?.length === 0}
                        onChange={(event, newValue, reason) => {
                          handleAutocompleteChange(
                            event,
                            newValue,
                            reason,
                            "companyId"
                          )
                        }}
                        onInputChange={(event, newValue, reason) => {
                          if (reason === "clear" || reason === "") {
                            handleAutocompleteChange(null)
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            key={params.id}
                            value={params.name}
                            required={true}
                            error={ctaFormErrors.companyId}
                            helperText={
                              ctaFormErrors.companyId
                                ? "Company is Required"
                                : ""
                            }
                            label="COMPANY"
                            placeholder=""
                          />
                        )}
                        onBlur={() => handleInputValidation("companyId")}
                      />
                    )}
                  </GridItem>
                  <GridItem xs>
                    <TextField
                      label="SHORT NAME OFFER"
                      id="shortNameOffer"
                      variant="outlined"
                      required={true}
                      value={shortNameOffer}
                      onChange={handleLinkChange("shortNameOffer")}
                      InputLabelProps={{ shrink: true }}
                    />
                  </GridItem>
                  <GridItem xs={1}>&nbsp;</GridItem>
                </FormControl>
              </Card>
            </Grid>
            <Grid>
              <Card elevation={0}>
                <FormControl
                  margin="normal"
                  fullWidth
                  className={classes.infoCard}
                >
                  {/* the field below will be fetch from tree by user */}
                  <GridItem xs>
                    {orgs?.length > 0 && (
                      <Autocomplete
                        fullWidth
                        options={
                          orgs?.slice().sort(function (a, b) {
                            return a.name && a.name.localeCompare(b.name)
                          }) || []
                        }
                        required={true}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="ORGANIZATION"
                            variant="outlined"
                            key={params.id}
                            value={params.name}
                            required={true}
                            error={ctaFormErrors.organizationId}
                            helperText={
                              ctaFormErrors.organizationId
                                ? "Organization is Required"
                                : ""
                            }
                          />
                        )}
                        value={orgs?.find(org => {
                          if (org.id === organizationId) {
                            return true
                          } else {
                            return false
                          }
                        })}
                        onChange={(event, newValue, reason) => {
                          handleAutocompleteChangeOrg(
                            event,
                            newValue,
                            reason,
                            "organizationId"
                          )
                        }}
                        onBlur={() => handleInputValidation("organizationId")}
                        disabled={false}
                      />
                    )}
                  </GridItem>
                  {!cyclePropertyAutoselect &&
                    hasProperties > 0 &&
                    propertiesOptionsArray.length > 0 && (
                      <GridItem xs>
                        <Autocomplete
                          fullWidth
                          multiple={true}
                          limitTags={2}
                          options={propertiesOptionsArray}
                          required={false}
                          getOptionLabel={option => option.name}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="PROPERTY"
                              variant="outlined"
                              key={params.id}
                              value={params.name}
                              required={true}
                              error={ctaFormErrors.propertyIds}
                              helperText={
                                ctaFormErrors.propertyIds
                                  ? "Property is Required"
                                  : ""
                              }
                            />
                          )}
                          value={properties?.filter(prps =>
                            propertyIds.includes(prps.id)
                          )}
                          onChange={(event, newValue, reason) => {
                            handleAutoCompleteMultiChangeProperty(
                              event,
                              newValue,
                              reason,
                              "propertyIds"
                            )
                          }}
                          onBlur={() => handleInputValidation("propertyIds")}
                          disabled={false}
                        />
                      </GridItem>
                    )}
                  <GridItem xs>
                    <Autocomplete
                      className={classes.autocompleteCTA}
                      style={{ backgroundColor: "white" }}
                      id="affiliateId"
                      options={
                        showAffiliateList ? affiliates?.toJS() || [] : []
                      }
                      variant="outlined"
                      getOptionLabel={option => option.name}
                      value={
                        affiliates
                          ?.toJS()
                          ?.find(
                            affiliate =>
                              Number(affiliate.id) === Number(affiliateId)
                          ) || null
                      }
                      onChange={(event, newValue, reason) => {
                        handleAutocompleteChange(
                          event,
                          newValue,
                          reason,
                          "affiliateId"
                        )
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          required={true}
                          error={ctaFormErrors.affiliateId}
                          helperText={
                            ctaFormErrors.affiliateId
                              ? "Affiliate is Required"
                              : ""
                          }
                          label="AFFILIATE"
                        />
                      )}
                      onBlur={() => handleInputValidation("affiliateId")}
                    />
                  </GridItem>
                  <GridItem>
                    <FormControlLabel
                      style={{ margin: "15% 0" }}
                      control={
                        <Switch
                          id="isArchived"
                          checked={isArchived || ""}
                          color="primary"
                          onChange={handleCheckboxChange}
                          size="small"
                        />
                      }
                      label="Archive"
                    />
                  </GridItem>
                  <GridItem xs>&nbsp;</GridItem>
                  <GridItem xs={1}>&nbsp;</GridItem>
                </FormControl>
              </Card>
            </Grid>
            <Grid className={classes.monetizationArea}>
              <Card elevation={0}>
                <CardContent
                  className={classes.monetizationCard}
                  style={{ padding: "0" }}
                >
                  <GridItem xs={6}>
                    <Typography className={classes.monetizationHeader}>
                      Monetization Details
                    </Typography>
                  </GridItem>
                </CardContent>
                <CardContent className={classes.monetizationCard}>
                  <GridItem xs>
                    {regionsInfo?.length > 0 && (
                      <Autocomplete
                        multiple={true}
                        className={classes.autocompleteCTA}
                        limitTags={3}
                        disableCloseOnSelect
                        options={
                          [
                            {
                              id: 237,
                              name: "USA",
                              geoNameAdmin1Code: "USA"
                            }
                          ].concat(
                            regionsInfo?.slice().sort(Helpers.sortByName)
                          ) || []
                        }
                        variant="outlined"
                        value={[
                          {
                            id: 237,
                            name: "USA",
                            geoNameAdmin1Code: "USA"
                          }
                        ]
                          .concat(regionsInfo?.toJS())
                          .filter(rgn => {
                            let match = false
                            region?.forEach(itm => {
                              if (allUSA?.length) {
                                if (itm === rgn.id && itm === _USA_REGION) {
                                  match = true
                                }
                              } else {
                                if (itm === rgn.id) {
                                  match = true
                                }
                              }
                            })
                            return match
                          })}
                        getOptionLabel={option => option.geoNameAdmin1Code}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              checked={selected}
                            />
                            {option.geoNameAdmin1Code}
                          </li>
                        )}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="REGION"
                            required={true}
                            error={ctaFormErrors.region}
                            helperText={
                              ctaFormErrors.region ? "Region is Required" : ""
                            }
                          />
                        )}
                        onChange={(event, newValue, reason) => {
                          handleAutoCompleteMultiChange(
                            event,
                            newValue,
                            reason,
                            "region"
                          )
                        }}
                        onBlur={() => {
                          handleInputValidation("region")
                        }}
                      />
                    )}
                  </GridItem>
                  <GridItem xs={2}>
                    <FormLabel>Conversion Type</FormLabel>
                    <RadioGroup
                      className={classes.conversionType}
                      row
                      aria-labelledby="demo-form-control-label-placement"
                      name="controlled-radio-buttons-group"
                      value={conversionType}
                      onChange={handleRadioChange("isConversionPercentPayOut")}
                    >
                      <FormControlLabel
                        value="Fee"
                        control={<Radio />}
                        label="$"
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        value="Percent"
                        control={<Radio />}
                        label="%"
                        labelPlacement="start"
                      />
                    </RadioGroup>
                  </GridItem>
                  <GridItem xs>
                    <TextField
                      className={classes.autocompleteCTA}
                      type="number"
                      value={
                        callToActionPayOff === 0
                          ? callToActionPayOff.toString()
                          : callToActionPayOff
                      }
                      variant="outlined"
                      fullWidth={true}
                      label="CONVERSION PAYOUT"
                      decimalscale={2}
                      required={true}
                      error={ctaFormErrors.callToActionPayOff}
                      helperText={
                        ctaFormErrors.callToActionPayOff
                          ? "Payout Amount is Required"
                          : ""
                      }
                      onChange={handleCtaChangeNumber("callToActionPayOff")}
                      onBlur={() => {
                        handleInputValidation("callToActionPayOff")
                        exceptionPayouts[0] = callToActionPayOff
                        setExceptionPayouts(exceptionPayouts)
                      }}
                      InputLabelProps={{ style: { pointerEvents: "auto" } }}
                      InputProps={
                        !isConversionPercentPayOut
                          ? {
                              startAdornment: (
                                <InputAdornment position="start" tabIndex={-1}>
                                  $
                                </InputAdornment>
                              )
                            }
                          : {
                              endAdornment: (
                                <InputAdornment position="start" tabIndex={-1}>
                                  %
                                </InputAdornment>
                              )
                            }
                      }
                    />
                  </GridItem>
                  <GridItem xs={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="payoutException"
                          checked={payoutException}
                          color="primary"
                          onChange={e => {
                            if (!callToActionPayOff) {
                              alert(
                                'CTA Payout can not be "0" if adding exceptions.'
                              )
                              e.preventDefault()
                            } else if (
                              region?.length < 2 &&
                              !region.includes(_USA_REGION) &&
                              e.target.checked
                            ) {
                              alert(
                                "You must selected Regions before you can add Payout Exceptions"
                              )
                              e.preventDefault()
                            } else if (
                              regionByPayout?.length < 2 &&
                              e.target.checked
                            ) {
                              createGroupPayoutExceptions()
                              e.preventDefault()
                            } else {
                              if (payoutException && payoutExceptionsExist()) {
                                if (
                                  window.confirm(
                                    `Do you want to remove all payout exceptions?`
                                  )
                                ) {
                                  setPayoutException(e.target.checked)
                                  removePayoutExceptions()
                                }
                              } else {
                                setPayoutException(e.target.checked)
                              }
                            }
                          }}
                        />
                      }
                      label="Payout Exception"
                    />
                  </GridItem>
                  <GridItem xs={2}>&nbsp;</GridItem>
                </CardContent>
              </Card>
              {payoutException && (
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    className={classes.accordionSummary}
                    expandIcon={
                      <ExpandMoreIcon className={classes.accordionIcon} />
                    }
                    aria-controls="accordion-contentheader"
                    id="accordion-header"
                  >
                    <Typography>
                      Payout Exception By Region
                      {haveEditedPayoutExceptions && (
                        <span
                          style={{
                            color: "#4d9ab5",
                            display: "inline-block",
                            padding: "0 0 0 15px",
                            fontWeight: "bold"
                          }}
                        >
                          After Applying Update, Save CTA to Save Changes.
                        </span>
                      )}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.payoutDetails}>
                    {!resetPayouts &&
                      regionByPayout &&
                      regionByPayout.map(
                        (itm, i) =>
                          i > 0 && (
                            <CTAPayoutExceptionMulti
                              key={i}
                              allRegions={
                                regionsInfo?.slice().sort(Helpers.sortByName) ||
                                []
                              }
                              chosenRegions={
                                allUSA?.length
                                  ? allUSA
                                  : myFormValues.region || []
                              }
                              isConversionPercentPayOut={
                                isConversionPercentPayOut
                              }
                              myDetails={itm}
                              ctaId={ctaId}
                              addFormField={() => {
                                if (!isEditingPayout) {
                                  createGroupPayoutExceptions()
                                }
                              }}
                              removeFormField={index => {
                                removeGroupPayoutExceptions(index)
                              }}
                              myIndex={i}
                              isNew={itm[0] && itm[0].isNew}
                              existingPayouts={groupRegionByPayout}
                              exceptionPayouts={exceptionPayouts || []}
                              regionByPayout={regionByPayout}
                              existingPayoutsInDB={
                                myFormValues.regionsAllParams
                              }
                              exceptionsBeingEdited={isEditingPayout}
                              nonExceptionPayout={callToActionPayOff}
                              defaultPayout={
                                i === 0
                                  ? callToActionPayOff === 0
                                    ? callToActionPayOff.toString()
                                    : callToActionPayOff
                                  : exceptionPayouts[i]
                              }
                              updateSelectedRegions={(
                                vals,
                                oldVals,
                                payout,
                                multiIndex
                              ) => {
                                updateSelectedRegions(
                                  vals,
                                  oldVals,
                                  payout,
                                  multiIndex
                                )
                              }}
                              updateSelectedRegionsOnBlur={(
                                curDetails,
                                index
                              ) => {
                                updateSelectedRegionsOnBlur(curDetails, index)
                              }}
                              updatePayouts={(value, index) => {
                                let tmp = exceptionPayouts || []
                                if (tmp.length > index) {
                                  tmp[index] = value
                                } else {
                                  for (
                                    let i = tmp.length;
                                    i < index + 1;
                                    i += 1
                                  ) {
                                    if (index === i) {
                                      tmp.push(value)
                                    } else {
                                      tmp.push()
                                    }
                                  }
                                }
                                setExceptionPayouts(tmp)
                              }}
                              payoutExceptionsExist={() =>
                                payoutExceptionsExist()
                              }
                              editingException={(index, mode) => {
                                if (!haveEditedPayoutExceptions) {
                                  setHaveEditedPayoutExceptions(true)
                                }
                                if (mode) {
                                  let tmp = exceptionsBeingEdited.indexOf(index)
                                  if (tmp === -1) {
                                    exceptionsBeingEdited.push(index)
                                    setExceptionsBeingEdited(
                                      exceptionsBeingEdited
                                    )
                                  }
                                } else {
                                  let tmp = exceptionsBeingEdited.indexOf(index)
                                  if (tmp !== -1) {
                                    exceptionsBeingEdited.splice(tmp, 1)
                                    setExceptionsBeingEdited(
                                      exceptionsBeingEdited
                                    )
                                  }
                                }
                                if (resetPayoutExceptions) {
                                  setResetPayoutExceptions(false)
                                }
                              }}
                              resetPayoutExceptions={resetPayoutExceptions}
                            />
                          )
                      )}
                  </AccordionDetails>
                </Accordion>
              )}
            </Grid>
            <Grid style={{ display: "flex" }}>
              <GridItem>
                <Typography className={classes.CTATitle}>
                  Link Timeframe
                </Typography>
                <FormControl
                  margin="normal"
                  fullWidth
                  className={classes.infoCard}
                >
                  <GridItem>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDateTimePicker
                        aria-label="CTAv2 VariantForm startDateTimePicker"
                        label="START DATE TIME"
                        value={startDate}
                        onChange={date => handleDateChange(date, "startDate")}
                        closeOnSelect={true}
                        slotProps={{
                          field: {
                            clearable: true,
                            onClear: clearStartDateFields
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </GridItem>
                  <GridItem className={classes.linkTimeZone}>
                    <TimezoneSelect
                      value={timeZoneIANA}
                      onChange={zone => onTimeZoneChange(zone, "timeZoneIANA")}
                      labelStyle="abbrev"
                    />
                  </GridItem>
                  <GridItem>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDateTimePicker
                        aria-label="CTAv2 VariantForm endDateTimePicker"
                        label="END DATE TIME"
                        value={endDate}
                        closeOnSelect={true}
                        onChange={date => handleDateChange(date, "endDate")}
                        slotProps={{
                          field: {
                            clearable: true,
                            onClear: clearEndDateFields
                          },
                          textField: {
                            helperText: endDateErrorMessage,
                            error: Boolean(endDateErrorMessage)
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </GridItem>
                </FormControl>
              </GridItem>
            </Grid>
            <Divider className={classes.CTADivide} />
            <Grid>
              <GridItem>
                <Typography className={classes.CTATitle}>
                  Promo Details
                </Typography>
                <FormControl
                  margin="normal"
                  fullWidth
                  className={classes.infoCard}
                >
                  <GridItem xs>
                    {linkTypes?.length > 0 && (
                      <Autocomplete
                        fullWidth
                        options={
                          linkTypes?.slice().sort(function (a, b) {
                            return a?.name.localeCompare(b?.name)
                          }) || []
                        }
                        required={true}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="OFFER LINK TYPE"
                            variant="outlined"
                            key={params.id}
                            value={params.name}
                            required={true}
                            error={ctaFormErrors.linkTypeId}
                            helperText={
                              ctaFormErrors.linkTypeId
                                ? "OFFER LINK TYPE is Required"
                                : ""
                            }
                          />
                        )}
                        value={linkTypes?.find(lt => lt.id === linkTypeId)}
                        onChange={(event, newValue, reason) => {
                          handleAutocompleteChange(
                            event,
                            newValue,
                            reason,
                            "linkTypeId"
                          )
                        }}
                        onBlur={() => handleInputValidation("linkTypeId")}
                        disabled={false}
                      />
                    )}
                  </GridItem>
                  <GridItem xs>
                    <TextField
                      label="PROMO TEXT"
                      id="promoText"
                      variant="outlined"
                      value={promoText}
                      fullWidth={true}
                      onChange={handleLinkChange("promoText")}
                      nputLabelProps={{ shrink: true }}
                    />
                  </GridItem>
                  <GridItem xs>
                    <TextField
                      label="ASSET LINK"
                      id="assetLink"
                      variant="outlined"
                      fullWidth={true}
                      value={assetLink}
                      onChange={handleLinkChange("assetLink")}
                      onBlur={() => handleInputValidation("assetLink")}
                      error={ctaFormErrors.assetLink}
                      helperText={
                        ctaFormErrors.assetLink && ctaFormErrors.assetLinkMsg
                      }
                      nputLabelProps={{ shrink: true }}
                    />
                  </GridItem>
                </FormControl>
              </GridItem>
            </Grid>
            <Grid className={classes.CTAButton}>
              <>
                <Button
                  className={classes.buttonStyle}
                  variant="contained"
                  size="medium"
                  color="secondary"
                  disabled={!validLinkUrl || !linkId}
                  onClick={() => {
                    getRandomShortCode(linkId)
                  }}
                >
                  <a
                    target="blank"
                    title={`View Link URL in New Tab`}
                    href={
                      randomShortCode &&
                      randomShortCode.length > 0 &&
                      randomShortCode[0] &&
                      linkUrl?.match(/\[\[affiliate_code\]\]/)
                        ? randomShortCode[0].affiliateUrl
                        : linkUrl
                    }
                    style={{ color: "#fff" }}
                  >
                    Preview Link
                  </a>
                </Button>
                <Button
                  className={classes.buttonStyle}
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={() => {
                    props.cancelFormEditsAndReload({
                      linkId: origFormValues.id
                    })
                  }}
                  disabled={disableCancel}
                >
                  Cancel
                </Button>
                <Button
                  className={classes.buttonStyle}
                  variant="contained"
                  size="medium"
                  color="primary"
                  type="submit"
                  onClick={() => {
                    setDisableCancel(true)
                    handleSaveForm()
                  }}
                  disabled={
                    disableSaveButton ||
                    isSaving ||
                    ctaFormErrors.validationHasError
                  }
                >
                  Save
                </Button>
              </>
            </Grid>
            {isSaving && <LinearProgress />}
            {accordionVisible && availableShortCodes.length > 0 && (
              <OfferLinkAffiliateDataGrid
                aria-label="CTAV2 OfferLinkForm AffiliateAccordion"
                linkId={linkId}
                availableShortCodes={availableShortCodes}
              />
            )}
          </Paper>
        </Grid>
      )}
    </div>
  )
}
CTAOfferLinkForm.propTypes = {
  closePage: PropTypes.func,
  openOfferLinkBlocks: PropTypes.func,
  linkInformation: PropTypes.object
}

export default CTAOfferLinkForm
