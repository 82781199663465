// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import ReactTable from "react-table-6"
import Assignment from "@mui/icons-material/Assignment"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon"
import CardHeader from "components/Card/CardHeader"
import matchSorter from "match-sorter"
import { globalSettings } from "variables/general"
import Helpers from "tools/Helpers.js"
import CircularProgress from "@mui/material/CircularProgress"

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    teamsList: store.graphicsToolStore.teamsList,
    retrieveTeams: store.graphicsToolStore.retrieveTeams
    // isLoading: store.graphicsToolStore.isLoading
  }))
}

// React Functional Component Declaration
const ManageTeams = () => {
  const { teamsList, retrieveTeams, isLoading } = useStoreData()
  // Variable/State Declaration
  const [imageIssues, setImageIssues] = useState()

  // Internal Function Declarations
  const stringFilterProps = {
    filterable: true,
    filterAll: true,
    filterMethod: ({ id, value }, rows) =>
      matchSorter(rows, value, {
        keys: [id],
        threshold: matchSorter.rankings.CONTAINS
      })
  }

  useEffect(() => {
    retrieveTeams()
  }, [])

  // Component Render
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <ReactTable
              minRows={0}
              data={teamsList}
              loading={isLoading}
              noDataText={isLoading ? <CircularProgress /> : "No teams found."}
              columns={[
                {
                  Header: "Id",
                  width: 80,
                  accessor: "teamId",
                  Cell: cell => <div>{cell.original.teamId}</div>,
                  ...stringFilterProps
                },
                {
                  Header: "Team Name",
                  width: 250,
                  accessor: "teamDisplayName",
                  Cell: cell => <div>{cell.original.teamDisplayName}</div>,
                  ...stringFilterProps
                },
                {
                  Header: "Group",
                  width: 300,
                  accessor: "groupingId",
                  Cell: cell => (
                    <div>
                      {Helpers.renderFriendlyTeamGroupName(
                        cell.original.groupingId
                      )}
                    </div>
                  ),
                  filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                      return true
                    }
                    return row._original.groupingId === parseInt(filter.value)
                  },
                  Filter: ({ filter, onChange }) => {
                    if (teamsList === null) {
                      return null
                    }
                    const distinctGroupNames = []
                    const map = new Map()
                    for (const team of teamsList) {
                      if (!map.has(team.groupingId)) {
                        map.set(team.groupingId, true)
                        distinctGroupNames.push({
                          key: team.groupingId,
                          id: team.groupingId,
                          name: Helpers.renderFriendlyTeamGroupName(
                            team.groupingId
                          )
                        })
                      }
                    }
                    // inline sort by org name
                    distinctGroupNames.sort((a, b) => {
                      const orgA = a.name.toUpperCase()
                      const orgB = b.name.toUpperCase()
                      let comparison = 0
                      if (orgA > orgB) {
                        comparison = 1
                      } else if (orgA < orgB) {
                        comparison = -1
                      }
                      return comparison
                    })
                    // build up the <select> element <option> element JSX style
                    let options = []
                    distinctGroupNames.forEach(groupName => {
                      options.push(
                        <option value={groupName.id} key={groupName.id}>
                          {groupName.name}
                        </option>
                      )
                    })
                    return (
                      <select
                        onChange={event => {
                          onChange(event.target.value)
                        }}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "all"}
                      >
                        <option value="all">Show All</option>
                        {options}
                      </select>
                    )
                  }
                },
                {
                  Header: s => (
                    <div>
                      Primary logo
                      <br />
                      <small>(dark background)</small>
                    </div>
                  ),
                  Cell: cell => (
                    <div>
                      <img
                        src={`${globalSettings.apiBaseUrl}/api/sports/loadteamlogo?teamid=${cell.original.teamId}&h=50`}
                        alt="Team primary logo"
                        style={{ backgroundColor: "#000000" }}
                        onError={e => {
                          setImageIssues(
                            `${imageIssues}, Primary logo(dark background) for team: ${cell.original.teamDisplayName} (${cell.original.teamId}) did not load.`
                          )
                        }}
                      />
                    </div>
                  ),
                  width: 150,
                  sortable: false,
                  filterable: false
                },
                {
                  Header: s => (
                    <div>
                      Primary logo
                      <br />
                      <small>(light background)</small>
                    </div>
                  ),
                  Cell: cell => (
                    <div>
                      <img
                        src={`${globalSettings.apiBaseUrl}/api/sports/loadteamlogo?teamid=${cell.original.teamId}&h=50&isSafeForWhitebackground=true`}
                        alt="Team primary logo"
                        style={{ backgroundColor: "#ffffff" }}
                      />
                    </div>
                  ),
                  width: 150,
                  sortable: false,
                  filterable: false
                },
                {
                  Header: s => <div>Primary color</div>,
                  Cell: cell => (
                    <div>
                      {cell.original.primaryColorHexCode}{" "}
                      {cell.original.primaryColorHexCode && (
                        <div
                          style={{
                            backgroundColor: `#${cell.original.primaryColorHexCode.replace(
                              "#",
                              ""
                            )}`,
                            border: "1px solid black",
                            height: 25,
                            width: 25
                          }}
                          title={`Hex Code #${cell.original.primaryColorHexCode?.replace(
                            "#",
                            ""
                          )}`}
                        />
                      )}
                      {!cell.original.primaryColorHexCode && (
                        <div
                          style={{
                            backgroundImage: `url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVQYV2NkIAKckTrzn5GQOpAik2cmjHgVwhSBDMOpEFkRToXoirAqxKYIQyEuRSgK8SmCKySkCKyQGEUghQD+Nia8BIDCEQAAAABJRU5ErkJggg==")`,
                            border: "1px solid black",
                            height: 25,
                            width: 25
                          }}
                          title={`Hex Code N/A`}
                        />
                      )}
                    </div>
                  ),
                  width: 150,
                  sortable: false,
                  filterable: false
                },
                {
                  Header: s => <div>Secondary color</div>,
                  Cell: cell => (
                    <div>
                      {cell.original.secondaryColorHexCode}{" "}
                      {cell.original.secondaryColorHexCode && (
                        <div
                          style={{
                            backgroundColor: `#${cell.original.secondaryColorHexCode.replace(
                              "#",
                              ""
                            )}`,
                            border: "1px solid black",
                            height: 25,
                            width: 25
                          }}
                          title={`Hex Code #${cell.original.secondaryColorHexCode?.replace(
                            "#",
                            ""
                          )}`}
                        />
                      )}
                      {!cell.original.secondaryColorHexCode && (
                        <div
                          style={{
                            backgroundImage: `url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVQYV2NkIAKckTrzn5GQOpAik2cmjHgVwhSBDMOpEFkRToXoirAqxKYIQyEuRSgK8SmCKySkCKyQGEUghQD+Nia8BIDCEQAAAABJRU5ErkJggg==")`,
                            border: "1px solid black",
                            height: 25,
                            width: 25
                          }}
                          title={`Hex Code N/A`}
                        />
                      )}
                    </div>
                  ),
                  width: 150,
                  sortable: false,
                  filterable: false
                }
              ]}
              filterable={true}
              defaultPageSize={25}
              showPaginationTop={false}
              showPaginationBottom={true}
              className="-striped -highlight -scrollEntries"
              style={{
                height: `${(window.innerHeight * 0.72).toFixed(0)}px`
              }}
              defaultSorted={[
                {
                  id: "scheduledStartDateTime",
                  desc: false
                }
              ]}
            >
              {(state, makeTable) => {
                let recordsInfoText = ""

                const { filtered, pageRows, pageSize, sortedData, page } = state

                if (sortedData && sortedData.length > 0) {
                  let isFiltered = filtered.length > 0

                  let totalRecords = sortedData.length

                  let recordsCountFrom = page * pageSize + 1

                  let recordsCountTo = recordsCountFrom + pageRows.length - 1

                  if (isFiltered) {
                    recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`
                  } else {
                    recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`
                  }
                } else {
                  recordsInfoText = isLoading
                    ? "Loading teams data, please wait"
                    : "No files"
                }
                return (
                  <div className="main-grid">
                    <div className="above-table text-right">
                      <div className="col-sm-12">
                        <span className="records-info">{recordsInfoText}</span>
                      </div>
                    </div>

                    {makeTable()}
                  </div>
                )
              }}
            </ReactTable>
            <div style={{ background: "#ffd900" }}>
              Image issues: {imageIssues}
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

// PropType Declaration
ManageTeams.propTypes = {}

// Default Export
export default ManageTeams
