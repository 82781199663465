import React, { useEffect } from "react"
import { useObserver } from "mobx-react"
import { useHistory } from "react-router-dom"

import { useStore } from "contexts/rootContext"

import withStyles from "@mui/styles/withStyles"
import { RssFeed, Share, Tune, Visibility } from "@mui/icons-material"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
//import CardIcon from "components/Card/CardIcon"
import CardHeader from "components/Card/CardHeader"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import NarrativeDetails from "components/NarrativeAdmin/NarrativeDetails"
import NarrativeOutline from "components/NarrativeAdmin/NarrativeOutline"
import NarrativeOutlineHelp from "components/NarrativeAdmin/NarrativeOutlineHelp"
import NarrativeOutlineWarnings from "components/NarrativeAdmin/NarrativeOutlineWarnings"
import NarrativeOutlineHierarchyModal from "components/NarrativeAdmin/NarrativeOutlineHierarchyModal"
import NarrativeDAO from "daos/narrativeDAO"

import { Cookies } from "tools/storage"

import queryString from "query-string"

import InReview from "views/NarrativeAdmin/InReview"
import narrativeDashboardStyles from "assets/jss/material-dashboard-pro-react/views/narrativeDashboardStyles"
import {
  Box,
  IconButton,
  Tab,
  Tabs,
  CircularProgress,
  ButtonGroup
} from "@mui/material"

import Button from "components/CustomButtons/Button"
import { Link } from "react-router-dom"

import Enums from "tools/Enums.js"

import Helpers from "../../tools/Helpers"
import NarrativeOutlineBlocksFromHTML from "../../components/NarrativeAdmin/NarrativeOutlineBlocksFromHTML"
import NarrativeOutlinePreview from "components/NarrativeAdmin/NarrativeOutlinePreview"

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    setIsDirty: store.narrativeStore.setIsDirty,
    loadingNarrative: store.narrativeStore.loadingNarrative,
    narrative: store.narrativeStore.narrative,
    getTriggersBrief: store.narrativeStore.getTriggersBrief,
    outlineBlocks: store.narrativeStore.narrative.outlineBlocks,
    getSnapshotDerivedOutlineBlocks:
      store.narrativeStore.getSnapshotDerivedOutlineBlocks,
    getFeedEntryDerivedOutlineBlocks:
      store.narrativeStore.getFeedEntryDerivedOutlineBlocks,
    getParagraphDerivedOutlineBlocks:
      store.narrativeStore.getParagraphDerivedOutlineBlocks,
    loadingSchedules: store.narrativeRenderScheduleStore.loading,
    schedules: store.narrativeRenderScheduleStore.schedules,
    setNarrativeId: store.narrativeStore.setNarrativeId,
    getNarrativeRenderSchedulesByNarrativeId:
      store.narrativeRenderScheduleStore
        .getNarrativeRenderSchedulesByNarrativeId,
    isError: store.uiStore.isError
  }))
}

const NarrativeDashboard = props => {
  const history = useHistory()
  const {
    getFeedEntryDerivedOutlineBlocks,
    getNarrativeRenderSchedulesByNarrativeId,
    getParagraphDerivedOutlineBlocks,
    getSnapshotDerivedOutlineBlocks,
    narrative,
    getTriggersBrief,
    schedules,
    setNarrativeId,
    loadingNarrative,
    isError
  } = useStoreData()

  const { classes } = props

  const viewStyleTabsStyles = {
    root: classes.tinyTabRootButton,
    selected: classes.tinyTabSelected,
    wrapper: classes.tinyTabWrapper
  }

  let queryParams = queryString.parse(props.location.search)

  let feedentryid = (queryParams && queryParams.feedentryid) || null

  let narrativeId = null
  let tmpId = (props.match && props.match.params && props.match.params.id) || ""

  if (tmpId.indexOf("?")) {
    narrativeId = tmpId.split("?")[0]
    if (!feedentryid) {
      queryParams = queryString.parse(tmpId.split("?")[1])
      feedentryid = (queryParams && queryParams.feedentryid) || null
    }
  }

  const [activeRightBar, setActiveRightBar] = React.useState(0)

  const [activeSource, setActiveSource] = React.useState("") // blockoutline, snapshots, feedentry

  const [allowBlockImports, setAllowBlockImports] = React.useState(false)

  // on first render
  useEffect(() => {
    if (narrativeId && (!narrative || narrative.id !== narrativeId)) {
      setNarrativeId(null)
      setNarrativeId(narrativeId)
    }
  }, [narrativeId])

  useEffect(() => {
    let paragraphs = narrative.paragraphs || []
    setAllowBlockImports(!narrative.buildFromOutline && paragraphs.length > 0)
    if (narrative.name) {
      Helpers.updateBrowserTabTitle(false, narrative.name)
      Helpers.didWeNavigateToLibraryEditor(narrative)
    }
  }, [narrative.paragraphs, narrative.buildFromOutline])

  useEffect(() => {
    if (narrative?.id && !isNaN(narrative.id)) {
      //Make sure we only accepts numeric values
      let myIds = [narrative.id]
      if (narrative.libraryNarrative_Ids) {
        const libNarrIds = narrative.libraryNarrative_Ids?.split(",") || []
        const libNarrIdsFiltered =
          libNarrIds.length > 0 ? libNarrIds.filter(x => !isNaN(x)) : libNarrIds //Make sure we only accepts numeric values
        myIds = myIds.concat(libNarrIdsFiltered)
        getTriggersBrief(myIds.join("|"))
      } else {
        getTriggersBrief(narrative.id)
      }
    }
  }, [narrative.id, narrative.libraryNarrative_Ids])

  useEffect(() => {
    if (isError) {
      history.push("/portal/narratives")
    }
  }, [isError])
  useEffect(() => {
    let curURL = history.location.pathname.match(
      /\/portal\/narrative\/(\d{1,6})\/?$/
    )
    if (curURL && curURL[1]) {
      const isNarrativeLibrary = NarrativeDAO.getNarrativeIsLibrary(curURL[1])
      isNarrativeLibrary.then(result => {
        Helpers.didWeNavigateToLibraryEditor(result.content)
      })
    }
  }, [])

  const changeActiveSource = newValue => {
    if (activeSource !== newValue) {
      setActiveSource(newValue)
      if (newValue === "blockoutline") {
        getParagraphDerivedOutlineBlocks(narrativeId)
      } else if (newValue === "snapshots") {
        getSnapshotDerivedOutlineBlocks(narrativeId)
      } else if (newValue === "feedentry" && feedentryid) {
        getFeedEntryDerivedOutlineBlocks(feedentryid)
      }
    }
  }

  const changeRightBar = newValue => {
    if (activeRightBar !== newValue) {
      setActiveRightBar(newValue)
      if (newValue === 3 && schedules && schedules.toJS().length === 0) {
        getNarrativeRenderSchedulesByNarrativeId(narrativeId)
      }
    }
  }
  // const handleTabChange = (_event, newValue) => {
  //   setOutlineCurrentTab(newValue)
  // }
  //Compact, Standard or Detailed

  let cookies = new Cookies()
  let cookieViewStyle =
    cookies.get("activeViewStyle") || Enums.BlockDisplayStyle.STANDARD
  const [activeViewStyle, setActiveViewStyle] = React.useState(
    Number(cookieViewStyle)
  )

  const changeViewStyle = (event, newValue) => {
    if (activeViewStyle !== newValue) {
      setActiveViewStyle(newValue)
      cookies.set("activeViewStyle", newValue, {
        path: "/",
        expires: Helpers.CookieExpiration.OneMonth
      })
    }
  }
  return (
    <Box>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          {loadingNarrative && (
            <CircularProgress
              size={40}
              style={{
                position: "fixed",
                left: "45%",
                top: "45vh",
                zIndex: "101"
              }}
            />
          )}
          <GridContainer aria-label="Outline Nav Menu">
            <GridItem xs={12} sm={12} md={7}>
              <Box
                style={{
                  flex: "1 1 auto",
                  display: "inline-block",
                  position: "relative",
                  whiteSpace: "nowrap"
                }}
              >
                <Link
                  to={{
                    pathname: `/portal/narrative/${narrativeId}`,
                    state: {
                      narrative_name: `${narrative.name}`
                    }
                  }}
                  title="Outline"
                >
                  <Button
                    color="primary"
                    className={classes.headerButtonSelected}
                  >
                    Outline
                  </Button>
                </Link>
                <Link
                  to={{
                    pathname: `/portal/narrative/${narrativeId}/edit`,
                    state: {
                      narrative_name: `${narrative.name}`
                    }
                  }}
                  title="Advanced Editor"
                >
                  <Button color="primary" className={classes.headerButton}>
                    Advanced Editor
                  </Button>
                </Link>
                <Link
                  to={{
                    pathname: `/portal/narrative/${narrativeId}/schedule`,
                    state: {
                      narrative_name: `${narrative.name}`
                    }
                  }}
                  title="View Jobs"
                >
                  <Button color="primary" className={classes.headerButton}>
                    Renders
                  </Button>
                </Link>
                <Link
                  to={{
                    pathname: `/portal/narrative/${narrativeId}/output`,
                    state: {
                      narrative_name: `${narrative.name}`
                    }
                  }}
                  title="View Rendered Content"
                >
                  <Button color="primary" className={classes.headerButton}>
                    Rendered Content
                  </Button>
                </Link>
              </Box>
            </GridItem>
            <GridItem xs={12} sm={12} md={5}>
              <Box
                title="Create or update your outline based on these sources"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  position: "relative",
                  whiteSpace: "nowrap"
                }}
              >
                <span className={classes.viewLabel}>Import From: </span>
                {/* 
                  Commenting the outline sources not HTML based for now
                  These only import from the narrative they are in, we need to 
                  have them select from other narratives to build from
                */}
                {allowBlockImports ? (
                  <React.Fragment>
                    <Button
                      title="Derive Outline From Paragraphs"
                      className={
                        activeSource === "blockoutline"
                          ? classes.headerButtonSelected
                          : classes.headerButton
                      }
                      onClick={() => changeActiveSource("blockoutline")}
                    >
                      Paragraphs
                    </Button>
                    {feedentryid && (
                      <Button
                        title="Derive Outline From Feed Entry"
                        className={
                          activeSource === "feedentry"
                            ? classes.headerButtonSelected
                            : classes.headerButton
                        }
                        onClick={() => changeActiveSource("feedentry")}
                      >
                        Feed Entry
                      </Button>
                    )}
                  </React.Fragment>
                ) : (
                  <Button
                    title="Derive Outline From Pasted Html"
                    className={
                      activeSource === "html"
                        ? classes.headerButtonSelected
                        : classes.headerButton
                    }
                    onClick={() => changeActiveSource("html")}
                  >
                    HTML
                  </Button>
                )}
              </Box>
            </GridItem>
          </GridContainer>
          <Card style={{ marginTop: "5px" }}>
            <CardHeader color="primary" icon>
              <GridContainer>
                <GridItem xs={12} sm={12} md={7}>
                  {/* <CardIcon color="primary">
                    <MenuBookTwoTone />
                  </CardIcon> */}
                  <h4 className={classes.cardIconTitle}>
                    {narrative.name}
                    <NarrativeOutlineHierarchyModal />
                  </h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <Tabs
                    value={activeViewStyle}
                    onChange={changeViewStyle}
                    variant="scrollable"
                    scrollButtons="auto"
                    indicatorColor="none"
                    textColor="primary"
                    classes={{
                      root: classes.tinyTabsRoot,
                      flexContainer: classes.flexEnd
                    }}
                  >
                    <span className={classes.viewLabel}>View: </span>
                    <Tab
                      classes={viewStyleTabsStyles}
                      value={Enums.BlockDisplayStyle.COMPACT}
                      label="Preview"
                    />
                    <span>|</span>
                    <Tab
                      classes={viewStyleTabsStyles}
                      value={Enums.BlockDisplayStyle.STANDARD}
                      label="Standard"
                    />
                    <span>|</span>
                    <Tab
                      classes={viewStyleTabsStyles}
                      value={Enums.BlockDisplayStyle.DETAILED}
                      label="Detailed"
                    />

                    <IconButton
                      size="small"
                      aria-label="Share Preview"
                      color="inherit"
                    >
                      <Link
                        to={`/narrativeoutlinepreview/${narrative.narrativeToken}`}
                        target="_blank"
                        title="Preview Link"
                      >
                        <Share fontSize="small" />
                      </Link>
                    </IconButton>

                    <NarrativeOutlineHelp
                      feedentryid={feedentryid}
                      changeActiveSource={changeActiveSource}
                    />
                  </Tabs>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              {activeSource === "html" && (
                <NarrativeOutlineBlocksFromHTML
                  handleClose={() => changeActiveSource("")}
                />
              )}
              {activeSource !== "html" && (
                <NarrativeOutline blockViewStyle={activeViewStyle} />
              )}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card className={classes.tabCard} style={{ marginTop: "58px" }}>
            <NarrativeOutlineWarnings />
            <ButtonGroup
              disableElevation={true}
              size="small"
              style={{ marginTop: "-5px", justifyContent: "center" }}
            >
              <Button
                color={activeRightBar === 0 ? "primary" : "info"}
                className={classes.outlineButton}
                onClick={() => changeRightBar(0)}
                startIcon={<Tune />}
              >
                OutPut Information
              </Button>
              <Button
                color={activeRightBar === 2 ? "primary" : "info"}
                onClick={() => changeRightBar(2)}
                startIcon={<RssFeed />}
              >
                In Review
              </Button>
              <Button
                color={activeRightBar === 3 ? "primary" : "info"}
                onClick={() => changeRightBar(3)}
                startIcon={<Visibility />}
              >
                Previewer
              </Button>
            </ButtonGroup>
            {activeRightBar === 0 && <NarrativeDetails />}
            {activeRightBar === 2 && (
              <InReview embedded narrativeId={narrativeId} />
            )}
            {activeRightBar === 3 && <NarrativeOutlinePreview />}
          </Card>
        </GridItem>
      </GridContainer>
    </Box>
  )
}

export default withStyles(narrativeDashboardStyles)(NarrativeDashboard)
