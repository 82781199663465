import React, { useState, useRef, useMemo, useEffect } from "react"
import OrganizationDAO from "daos/organizationDAO"

import {
  DataGridPro,
  GridActionsCellItem,
  useGridApiRef
} from "@mui/x-data-grid-pro"

import Box from "@mui/material/Box"
import Alert from "@mui/material/Alert"
import Snackbar from "@mui/material/Snackbar"
import SaveIcon from "@mui/icons-material/Save"
import CancelIcon from "@mui/icons-material/Cancel"
import { AlertTitle } from "@mui/material"
import { Button } from "@mui/material"
import CheckIcon from "@mui/icons-material/CheckBox"
import Square from "@mui/icons-material/CropSquare"
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline"
import Report from "@mui/icons-material/Report"

import Helpers from "tools/Helpers"
import TimeAgo from "react-timeago"
import { urlValidation } from "tools/ValidationHelpers"
import InfoDialog from "components/CustomDialogs/InfoDialog"
//import EditAutocompleteforTables from "components/Autocomplete/AutocompleteForTables"
import CustomDSDialog from "components/CustomDialogs/CustomDSDialog"

const dateFilterProps = (propName, data) => ({
  filterMethod: (filter, row) => {
    if (filter.value === "all") {
      return true
    }

    const now = new Date()
    const futureTime = new Date()
    const pastTime = new Date()
    const columnDateTime = new Date(row.data[propName])
    if (filter.value === "future") {
      return columnDateTime >= now
    }
    if (filter.value === "past") {
      return columnDateTime < now
    }
    if (filter.value === "next7Days") {
      return (
        columnDateTime > now &&
        columnDateTime < futureTime.setHours(futureTime.getHours() + 7 * 24)
      )
    }
    if (filter.value === "next24hours") {
      return (
        columnDateTime > now &&
        columnDateTime < futureTime.setHours(futureTime.getHours() + 48)
      )
    }
    if (filter.value === "next1hour") {
      return (
        columnDateTime > now &&
        columnDateTime < futureTime.setHours(futureTime.getHours() + 2)
      )
    }
    if (filter.value === "within7Days") {
      return (
        columnDateTime > pastTime.setHours(pastTime.getHours() - 7 * 24) &&
        columnDateTime < futureTime.setHours(futureTime.getHours() + 7 * 24)
      )
    }
    if (filter.value === "within24hours") {
      return (
        columnDateTime > pastTime.setHours(pastTime.getHours() - 24) &&
        columnDateTime < futureTime.setHours(futureTime.getHours() + 48)
      )
    }
    if (filter.value === "within1hour") {
      return (
        columnDateTime > pastTime.setHours(pastTime.getHours() - 1) &&
        columnDateTime < futureTime.setHours(futureTime.getHours() + 2)
      )
    }
    if (filter.value === "past7Days") {
      return (
        columnDateTime > pastTime.setHours(pastTime.getHours() - 7 * 24) &&
        columnDateTime < now
      )
    }
    if (filter.value === "past24hours") {
      return (
        columnDateTime > pastTime.setHours(pastTime.getHours() - 24) &&
        columnDateTime < now
      )
    }
    if (filter.value === "past1hour") {
      return (
        columnDateTime > pastTime.setHours(pastTime.getHours() - 1) &&
        columnDateTime < now
      )
    }
  },
  renderCell: params =>
    params.value && (
      <div>
        <TimeAgo
          date={params.value}
          title={Helpers.prettyDateTimeinPacificTimeZone(params.value)}
        />
      </div>
    )
})

export default function ContractDeliverablesTable({
  contractDeliverablesData,
  catalystTypes,
  subjectTypes,
  publishTypes,
  organizationId,
  leagues,
  hasChildren,
  showArchivedDeliverables,
  verticals
}) {
  // const filteredData = showArchivedDeliverables
  //   ? contractDeliverablesData
  //   : contractDeliverablesData.filter(item => !item.isArchived)
  const apiRef = useGridApiRef()

  const [hasUnsavedRows, setHasUnsavedRows] = useState(false)
  const unsavedChangesRef = useRef({ unsavedRows: {}, rowsBeforeChange: {} })
  const [isSaving, setIsSaving] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false)
  const [openFailDialog, setOpenFailDialog] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [rowSelectionModel, setRowSelectionModel] = useState([])

  useEffect(() => {}, [openSuccessDialog])

  const [filteredData, setFilteredData] = useState(() =>
    showArchivedDeliverables
      ? contractDeliverablesData
      : contractDeliverablesData.filter(item => !item.isArchived)
  )

  useEffect(() => {
    setFilteredData(
      showArchivedDeliverables
        ? contractDeliverablesData
        : contractDeliverablesData.filter(item => !item.isArchived)
    )
  }, [showArchivedDeliverables, contractDeliverablesData])

  const handleCloseSuccessDialog = () => {
    setOpenSuccessDialog(false)
  }

  const handleCloseFailDialog = () => {
    setOpenFailDialog(false)
  }

  const handleAlertClose = () => {
    setAlertOpen(false)
  }

  const processRowUpdate = (newRow, oldRow) => {
    // Check if the newRow has newData from the oldRow
    const newData = Object.keys(newRow).some(key => newRow[key] !== oldRow[key])

    if (newData) {
      const rowId = newRow.id
      unsavedChangesRef.current.unsavedRows[rowId] = newRow
      if (!unsavedChangesRef.current.rowsBeforeChange[rowId]) {
        unsavedChangesRef.current.rowsBeforeChange[rowId] = oldRow
      }
      setHasUnsavedRows(true)
      const isSelected = rowSelectionModel.includes(newRow.id)
      return { ...newRow, isSelected }
    }
    // If there no changes, return the oldRow without updating unsavedChangesRef or hasUnsavedRows
    //persist selected rows with checkbox selected
    const isSelected = rowSelectionModel.includes(newRow.id)
    return { ...oldRow, isSelected }
  }

  const bulkSuccessMessage = messages => (
    <div>
      <div>Results:</div>
      <ol
        style={{
          textAlign: "left"
        }}
      >
        {Array.isArray(messages) ? (
          messages.map((message, i) => (
            <li key={`${i}`} style={{ textAlign: "left", marginBottom: "5px" }}>
              {message}
            </li>
          ))
        ) : (
          <>
            {messages.split("|").map((line, i) => (
              <li key={i}>
                {/* {i > 0 && ","} */}
                {line}
              </li>
            ))}
          </>
        )}
      </ol>
    </div>
  )
  const saveChanges = async () => {
    if (Object.keys(unsavedChangesRef.current.unsavedRows).length === 0) {
      return
    }

    const bulkUpdateData = Object.values(
      unsavedChangesRef.current.unsavedRows
    ).map(newRow => ({
      id: newRow.id,
      organizationId: newRow.organizationId,
      contractId: newRow.contractId,
      name: newRow.name,
      articleTypeId: newRow.articleTypeId,
      catalystTypeId: newRow.catalystTypeId,
      publishTypeId: newRow.publishTypeId,
      subjectTypeId: newRow.subjectTypeId,
      leagueId: newRow.leagueId,
      isArchived: newRow.isArchived || false,
      verticalId: newRow.verticalId,
      clientUrlStructure: newRow.clientUrlStructure,
      auditInfo: newRow.auditInfo,
      doClientVerification: false,
      narrativeId: null,
      conferenceIds: newRow.conferenceIds,
      divisionIds: newRow.divisionIds,
      teamIds: newRow.teamIds
    }))

    try {
      setIsSaving(true)

      // Process each update individually
      let success = true
      //let responseMessage = "Save successful"
      const resultMessages = []

      for (const updateData of bulkUpdateData) {
        const response = await OrganizationDAO.postContractDeliverable(
          updateData
        )
        if (response.responseCode !== 1000) {
          // If any request fails, mark success as false
          success = false

          break
        } else {
          resultMessages.push(response.responseMessage)
        }
      }

      if (success) {
        setOpenSuccessDialog(true)
        setSuccessMessage(resultMessages.join("|"))
        setTimeout(() => setOpenSuccessDialog(false), 5000)
      } else {
        setOpenFailDialog(true)
        setTimeout(() => setOpenFailDialog(false), 5000)
      }

      // Clear unsaved changes after successful save
      setHasUnsavedRows(false)
      unsavedChangesRef.current = { unsavedRows: {}, rowsBeforeChange: {} }
    } catch (error) {
      setIsSaving(false)
    } finally {
      setIsSaving(false)
    }
  }

  const getRowClassName = params => {
    // Check if current row's ID is in the unsaved changes
    if (unsavedChangesRef.current.unsavedRows[params.id]) {
      return "edited-row"
    }
    //condition to apply 'archived-row' class
    return params.row && params.row.isArchived ? "archived-row" : ""
  }

  const deliverableColumns = useMemo(
    () => [
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        getActions: ({ id, row }) => [
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Discard changes"
            disabled={
              unsavedChangesRef.current.unsavedRows[id] === undefined ||
              JSON.stringify(unsavedChangesRef.current.unsavedRows[id]) ===
                JSON.stringify(unsavedChangesRef.current.rowsBeforeChange[id])
            }
            onClick={() => {
              apiRef.current.updateRows([
                unsavedChangesRef.current.rowsBeforeChange[id]
              ])
              delete unsavedChangesRef.current.rowsBeforeChange[id]
              delete unsavedChangesRef.current.unsavedRows[id]
              setHasUnsavedRows(
                Object.keys(unsavedChangesRef.current.unsavedRows).length > 0
              )
            }}
            style={{
              color:
                unsavedChangesRef.current.unsavedRows[id] === undefined
                  ? "#bdbdbd"
                  : "#D01111"
            }}
          />
        ]
      },
      {
        headerName: "ID",
        field: "id",
        width: 75
      },
      {
        headerName: "Name of Deliverable",
        field: "name",
        editable: true,
        width: 250,
        renderCell: params => (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer"
            }}
            title={params.value} // Show full text in tooltip on hover
          >
            {params.value}
          </div>
        )
      },
      {
        headerName: "Vertical",
        field: "verticalId",
        type: "singleSelect",
        editable: true,
        valueOptions: [{ id: null, verticalName: "None" }]
          .concat(verticals?.toJS() ?? [])
          .map(ct => ({
            value: ct.id,
            label: ct.verticalName
          })),
        valueFormatter: params => {
          let vert = verticals?.toJS()?.find(e => e.id === params.value)
          return vert?.verticalName ?? "None"
        },
        width: 150
      },
      {
        headerName: "What?",
        field: "catalystTypeId",
        type: "singleSelect",
        valueOptions: (catalystTypes || []).map(ct => ({
          value: ct.id,
          label: ct.name
        })),
        sortComparator: (v1, v2) => {
          const name1 = catalystTypes.find(ct => ct.id === v1)?.name || ""
          const name2 = catalystTypes.find(ct => ct.id === v2)?.name || ""
          return name1.localeCompare(name2)
        },
        editable: true,
        width: 150
      },
      {
        headerName: "Who?",
        field: "subjectTypeId",
        editable: true,
        type: "singleSelect",
        valueOptions: (subjectTypes || []).map(st => ({
          value: st.id,
          label: st.name
        })),
        sortComparator: (v1, v2) => {
          const name1 = subjectTypes.find(ct => ct.id === v1)?.name || ""
          const name2 = subjectTypes.find(ct => ct.id === v2)?.name || ""
          return name1.localeCompare(name2)
        },
        width: 150
      },
      {
        headerName: "When?",
        field: "publishTypeId",
        editable: true,
        type: "singleSelect",
        valueOptions: (publishTypes || []).map(pt => ({
          value: pt.id,
          label: pt.name
        })),
        sortComparator: (v1, v2) => {
          const name1 = publishTypes.find(ct => ct.id === v1)?.name || ""
          const name2 = publishTypes.find(ct => ct.id === v2)?.name || ""
          return name1.localeCompare(name2)
        },
        width: 150
      },
      {
        headerName: "League",
        field: "leagueName",
        width: 170
      },
      // {
      // DO NOT REMOVE, TRYING TO FIND WAY FOR THIS TO WORK WITH CONF, TEAM, ETC
      //   headerName: "League",
      //   field: "leagueName",
      //   editable: true,
      //   width: 170,
      //   valueGetter: params => params.row.leagueName || "",
      //   valueSetter: params => {
      //     const newLeague = leagues.find(
      //       league => league.id === params.value.id
      //     )
      //     return {
      //       ...params.row,
      //       league: { id: newLeague?.id, name: newLeague?.name }
      //     }
      //   },
      //   renderEditCell: params => (
      //     <EditAutocompleteforTables
      //       {...params}
      //       options={leagues?.map(league => ({
      //         value: league.id,
      //         label: league.name
      //       }))}
      //     />
      //   )
      // },
      {
        headerName: "Conferences",
        field: "conferenceNames",
        width: 150
      },
      {
        headerName: "Divisions",
        field: "divisionNames",
        width: 120
      },
      {
        headerName: "Teams",
        field: "teamNames",
        disabled: true,
        renderCell: params => (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer"
            }}
            title={Array.isArray(params.value) ? params.value.join(", ") : ""} // Show full text in tooltip on hover
          >
            {Array.isArray(params.value) ? params.value.join(", ") : ""}
          </div>
        ),
        width: 150
      },
      ...(hasChildren && hasChildren.length > 0
        ? [
            {
              field: "organizationName",
              headerName: "Property",
              valueGetter: params => (params.value ? params.value : "NA"),
              width: 200
            }
          ]
        : []),
      {
        headerName: (
          <div style={{ display: "flex" }}>
            Client URL Structure
            <InfoDialog
              content={
                "If a Client URL Structure is present, then a nightly workflow will check this URL and the SEO slug together to cerify published status 200 for every feed entry rendered."
              }
            />
          </div>
        ),
        field: "clientUrlStructure",
        editable: true,
        preProcessEditCellProps: params => {
          const inputValue = params.props.value.trim()
          const hasError = inputValue.length > 0 && !urlValidation(inputValue)

          if (hasError) {
            setAlertOpen(true)
          }

          return {
            ...params.props,
            error: hasError,
            value: hasError ? null : params.props.value
          }
        },
        width: 225
      },
      {
        headerName: "Archive",
        field: "isArchived",
        editable: true,
        type: "boolean",
        sortable: false,
        renderCell: params => (
          <div>
            {params.value ? (
              <CheckIcon style={{ color: "#4d9ab5" }} />
            ) : (
              <Square style={{ color: "#808080" }} />
            )}
          </div>
        ),
        width: 100
      },
      {
        headerName: "Audit Info",
        field: "auditInfo.modifiedOn",
        type: "dateTime",
        valueGetter: params => {
          const dateValue = params.row.auditInfo?.modifiedOn
          return dateValue ? new Date(dateValue) : null
        },
        ...dateFilterProps("auditInfo.modifiedOn", contractDeliverablesData),
        renderCell: cell => Helpers.renderAuditInfoMuiDataGridProCell(cell),
        width: 200
      }
    ],
    [apiRef, catalystTypes, subjectTypes, publishTypes, verticals]
  )
  return (
    <div>
      <CustomDSDialog
        aria-label="ContractDeliverGrid SuccessDialog SuccessMessage"
        titleContent={"Success"}
        content={bulkSuccessMessage(successMessage)}
        icon={<CheckCircleOutline style={{ fontSize: "4rem" }} />}
        backgroundColor={"#66bb6a"}
        open={openSuccessDialog}
        onClose={handleCloseSuccessDialog}
      />
      <CustomDSDialog
        aria-label="ContractDeliverGrid FailDialog FailMessage"
        open={openFailDialog}
        content={"Failed to Update"}
        icon={<Report style={{ fontSize: "4rem" }} />}
        backgroundColor={"#A31515"}
        onClose={handleCloseFailDialog}
      />
      <Button
        aria-label="ContractDeliverGrid SaveBtn SaveDelivarble"
        disabled={!hasUnsavedRows}
        loading={isSaving}
        onClick={saveChanges}
        startIcon={
          <SaveIcon
            style={{
              color: hasUnsavedRows ? "#66bb6a" : "#bdbdbd",
              fontSize: "32px"
            }}
          />
        }
        loadingPosition="start"
        style={{
          color: hasUnsavedRows ? "#66bb6a" : "#bdbdbd",
          paddingLeft: "25px"
        }}
      >
        <span>SAVE ALL</span>
      </Button>
      <Box
        aria-label="ContractDeliverGrid Box GridContainer"
        sx={{
          "& .Mui-error": {
            backgroundColor: "#ffcccc",
            border: "solid 2px red",
            height: "51px"
          }
        }}
      >
        <DataGridPro
          aria-label="ContractDeliverGrid MuiDatagrid GridContractDeliver"
          columns={deliverableColumns}
          rows={filteredData}
          apiRef={apiRef}
          processRowUpdate={processRowUpdate}
          onRowSelectionModelChange={newRowSelectionModel => {
            setRowSelectionModel(newRowSelectionModel)
          }}
          rowSelectionModel={rowSelectionModel}
          pagination
          initialState={{
            sorting: {
              sortModel: [{ field: "id", sort: "desc" }]
            },
            pagination: { paginationModel: { pageSize: 25 } }
          }}
          pageSizeOptions={[25, 50, 100]}
          getRowClassName={getRowClassName}
          sx={{
            "& .MuiDataGrid-columnHeader": { backgroundColor: "#ddeaef" },
            "& .MuiDataGrid-row": {
              "&.archived-row": {
                textDecoration: "line-through"
              },
              "&.Mui-selected": {
                backgroundColor: "#ddeaef"
              }
            },
            "& .MuiDataGrid-row.edited-row": {
              backgroundColor: "#dddddd"
            }
          }}
        />
        <Snackbar
          aria-label="ContractDeliverGrid Alert InvalidURLAlert"
          open={alertOpen}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ horizontal: "center", vertical: "center" }}
        >
          <Alert variant="filled" severity="error">
            <AlertTitle>Error: Invalid URL Format</AlertTitle>
            EX: https://url.com
          </Alert>
        </Snackbar>
      </Box>
    </div>
  )
}
