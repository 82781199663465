import { List, Map, fromJS } from "immutable"
import { action, reaction, observable, computed, decorate } from "mobx"
import AccountDao from "../daos/accountDAO"
import Enums from "tools/Enums.js"
import Helpers from "tools/Helpers.js"

export default class AccountStore {
  rootStore
  accountId
  account
  accounts
  activeAccounts = []
  permissionGroups
  isAddAccountModalOpen
  loading
  loadingAccount
  upsertedAccount
  loginAttemptHistory
  accountPermissions
  accountOrganizations = []
  accountProperties = []

  constructor(rootStore) {
    this.rootStore = rootStore
    this.dehydrate()
  }

  /**
   * Computeds
   */
  get isAccountSelected() {
    return !!this.accountId
  }

  get showUpsertConfirmation() {
    return !!this.upsertedAccount.get("id")
  }

  get upsertedAccountFullName() {
    return `${this.upsertedAccount.get(
      "firstName",
      ""
    )} ${this.upsertedAccount.get("lastName", "")}`
  }

  get sortedAccountOrganizations() {
    return this.accountOrganizations
      .filter(
        accOrg => accOrg.isActive && accOrg.id !== Enums.Organizations.Libraries
      )
      .sort(Helpers.sortByName)
  }

  get sortedProperties() {
    return this.accountProperties
      .filter(a => a.isActive)
      .sort(Helpers.sortByName)
  }

  /**
   * Methods
   */
  putAccount = async account => {
    try {
      this.setLoading(true)

      let updatedAccount = fromJS(
        await AccountDao.putAccount(this.accountId, account)
      )

      this.updateAccounts(updatedAccount.get("content", Map()))

      this.dehydrateAccount()
    } catch (err) {
      console.error("Error", err)
      throw err
    } finally {
      this.setLoading(false)
    }
  }

  postAccount = async account => {
    try {
      this.setLoading(true)

      const newAccount = fromJS(await AccountDao.postAccount(account))

      this.updateAccounts(newAccount.get("content", Map()))

      this.setUpsertedAccount(newAccount.get("content", Map()))
    } catch (err) {
      console.error("Error", err)
      throw err
    } finally {
      this.setLoading(false)
    }
  }

  getPermissionGroups = async () => {
    try {
      this.setLoading(true)

      const permissions = fromJS(await AccountDao.getPermissionGroups())

      this.setPermissionGroups(permissions.get("content", List()))
    } catch (err) {
      console.error("Error", err)
      throw err
    } finally {
      this.setLoading(false)
    }
  }

  getPermissions = async () => {
    try {
      this.setLoading(true)

      const permissions = await AccountDao.getPermissions()

      const properties = []
      permissions.organizations.forEach(itm => {
        itm.properties &&
          itm.properties.forEach(itm2 => {
            properties.push(itm2)
          })
      })

      this.setAccountPermissions(permissions.permissions)
      this.setAccountOrganizations(permissions.organizations)
      this.setAccountProperties(properties)
    } catch (err) {
      console.error("Error", err)
      throw err
    } finally {
      this.setLoading(false)
    }
  }

  getLoginAttemptHistory = async () => {
    try {
      this.setLoading(true)
      const loginAttemptHistory = fromJS(
        await AccountDao.getLoginAttemptHistory()
      )
      this.setLoginAttemptHistory(loginAttemptHistory.get("content", List()))
    } catch (err) {
      console.error("Error", err)
      throw err
    } finally {
      this.setLoading(false)
    }
  }

  getAccounts = async () => {
    try {
      this.setLoading(true)

      const accounts = fromJS(await AccountDao.getAccounts())

      this.setAccounts(accounts.get("content", List()))
    } catch (err) {
      console.error("Error", err)
    } finally {
      this.setLoading(false)
    }
  }

  getActiveAccounts = async () => {
    try {
      this.setLoading(true)

      const activeAccounts = await AccountDao.getActiveAccounts()
      this.setActiveAccounts(activeAccounts.content)
    } catch (err) {
      console.error("Error", err)
    } finally {
      this.setLoading(false)
    }
  }

  getActiveAccountsByOrganization = async orgId => {
    try {
      this.setLoading(true)
      const activeAccounts = await AccountDao.getActiveAccountsByOrganization(
        orgId
      )
      this.setAccounts(activeAccounts.content)
    } catch (err) {
      console.error("Error", err)
    } finally {
      this.setLoading(false)
    }
  }

  closeAddAccountModal = () => {
    this.dehydrateAccount()
  }

  openAddAccountModal = (id = null) => {
    this.setAccountId(id)
    this.setIsAddAccountModalOpen(true)
  }

  /**
   * Reactions
   */
  getAccount = reaction(
    () => this.accountId,
    async id => {
      try {
        if (!id) {
          return
        }

        this.setLoading(true)
        this.setLoadingAccount(true)

        const apiAccount = fromJS(await AccountDao.getAccount(id))

        if (!apiAccount) {
          return
        }

        const account = apiAccount.get("content").merge(
          Map({
            organizationId: apiAccount.getIn(
              ["content", "organization", "id"],
              ""
            ),
            permissionGroups: apiAccount
              .getIn(["content", "permissionGroups"], List())
              .map(pg => pg.get("id")),
            accountStatusId: apiAccount.getIn(
              ["content", "accountStatus", "id"],
              ""
            )
          })
        )
        this.setAccount(account)
      } catch (err) {
        console.error("Error", err)
      } finally {
        this.setLoading(false)
        this.setLoadingAccount(false)
      }
    }
  )

  /**
   * Internal Actions
   */
  dehydrate() {
    this.dehydrateAccount()
    this.setPermissionGroups(List())
    this.setAccounts(List())
    this.setActiveAccounts([])
    this.setLoading(false)
    this.setLoadingAccount(false)
    this.setIsAddAccountModalOpen(false)
  }

  dehydrateAccount() {
    this.setAccount(Map())
    this.setUpsertedAccount(Map())
    this.setAccountId(null)
    this.setIsAddAccountModalOpen(false)
  }

  setAccountId(value) {
    if (!value) {
      this.setAccount(Map())
    }
    this.accountId = value
  }

  setAccount(value) {
    this.account = value
  }

  setUpsertedAccount(value) {
    this.upsertedAccount = value
  }

  setAccounts(value) {
    this.accounts = value
  }

  setActiveAccounts(value) {
    this.activeAccounts = value
  }

  setLoginAttemptHistory(value) {
    this.loginAttemptHistory = value
  }

  setIsAddAccountModalOpen(value) {
    this.isAddAccountModalOpen = value
  }

  setPermissionGroups(value) {
    this.permissionGroups = value
  }

  setLoading(value) {
    this.loading = value
  }

  setLoadingAccount(value) {
    this.loadingAccount = value
  }

  setAccountPermissions(value) {
    this.accountPermissions = value
  }

  setAccountOrganizations(value) {
    this.accountOrganizations = value
  }

  setAccountProperties(value) {
    this.accountProperties = value
  }

  updateAccounts(account) {
    const accountIdx = this.accounts.findIndex(
      a => a.get("id") === account.get("id")
    )

    // update the account in state
    if (accountIdx !== -1) {
      this.setAccounts(this.accounts.set(accountIdx, account))
    } else {
      this.setAccounts(this.accounts.push(account))
    }
  }
}

/**
 * object decorators
 */
decorate(AccountStore, {
  //computeds
  isAccountSelected: computed,
  showUpsertConfirmation: computed,
  sortedAccountOrganizations: computed,

  // observables
  account: observable,
  accounts: observable,
  activeAccounts: observable,
  accountId: observable,
  permissionGroups: observable,
  loading: observable,
  loadingAccount: observable,
  isAddAccountModalOpen: observable,
  upsertedAccount: observable,
  accountPermissions: observable,
  accountOrganizations: observable,
  accountProperties: observable,

  // actions
  setAccountId: action.bound,
  setAccount: action.bound,
  setActiveAccounts: action.bound,
  setAccounts: action.bound,
  setIsAddAccountModalOpen: action.bound,
  setPermissionGroups: action.bound,
  setLoading: action.bound,
  setLoadingAccount: action.bound,
  setUpsertedAccount: action.bound,
  setAccountPermissions: action.bound,
  setAccountOrganizations: action.bound,
  setAccountProperties: action.bound
})
