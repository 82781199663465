import Typography from "@mui/material/Typography"

import Check from "@mui/icons-material/CheckCircleOutline"
import Report from "@mui/icons-material/Report"

export const renderBulkUploadInfo = bulkUploadList => (
  <>
    <Typography variant="h5" gutterBottom>
      Bulk Upload Information
    </Typography>
    <Typography align="left" gutterBottom>
      <b>An example Renderer Bulk Upload template</b>{" "}
      <a
        href="https://docs.google.com/spreadsheets/d/1pNu7OuyU-P4ewavo_78dFzJs0D0GAZktg8w3uDOOlTQ/edit#gid=0"
        rel="noreferrer"
        target="_blank"
      >
        <u>
          <b>can be found here.</b>
        </u>
      </a>
      {"   "}
      These will need to be made into a TSV file.
      <br />
      <br />
    </Typography>{" "}
    <Typography align="left">
      <u>
        <b>Bulk Upload requirements:</b>
      </u>
      {"  "}A bulk upload entry will only validate if all mandatory fields are
      included. These fields are:
    </Typography>
    <ul className={bulkUploadList}>
      <li>
        All columns are mandatory with duplicate handling expecting to be a 2.
      </li>
      <li>Org/Property of Output and Feeds match current Org</li>
    </ul>
  </>
)

export const renderOutputRefreshInfo = bulkUploadList => (
  <>
    <Typography variant="h5" gutterBottom>
      Output Refresh Bulk Upload Information
    </Typography>
    <Typography align="left" gutterBottom>
      <b>An example Output Refresh Bulk Upload template</b>{" "}
      <a
        href="https://docs.google.com/spreadsheets/d/1R3OPcXOByQkUZiND5BK8UOv89WIgFY61stot6SDrthY/edit#gid=0"
        rel="noreferrer"
        target="_blank"
      >
        <u>
          <b>can be found here.</b>
        </u>
      </a>
      {"   "}
      These will need to be made into a TSV file.
      <br />
      <br />
    </Typography>{" "}
    <Typography align="left">
      <u>
        <b>Bulk Upload requirements:</b>
      </u>{" "}
      A bulk upload entry will only validate if all mandatory fields are
      included. These fields are:
    </Typography>
    <ul className={bulkUploadList}>
      <li>
        All columns are mandatory with duplicate handling expecting to be a 2.
      </li>
      <li>Only a Feed in Property ID #1426 is allowed</li>
      <li>Only Validate - Internal Only status feeds</li>
      <li>Will always run as a one time/immediate job</li>
      <li>No contract deliverable needed</li>
    </ul>
  </>
)

export const renderSampleRefreshInfo = bulkUploadList => (
  <>
    <Typography variant="h5" gutterBottom>
      Sample Refresh Bulk Upload Information
    </Typography>
    <Typography align="left" gutterBottom>
      <b>An example Sample Refresh Bulk Upload template</b>{" "}
      <a
        href="https://docs.google.com/spreadsheets/d/1VRq8hYkjl2STCt3vCEA_dvMpYOqHYhRiIRxNm1ei-c0/edit#gid=1544831476"
        rel="noreferrer"
        target="_blank"
      >
        <u>
          <b>can be found here.</b>
        </u>
      </a>
      {"   "}
      These will need to be made into a TSV file.
      <br />
      <br />
    </Typography>{" "}
    <Typography align="left">
      <u>
        <b>Bulk Upload requirements:</b>
      </u>{" "}
      A bulk upload entry will only validate if all mandatory fields are
      included. These fields are:
    </Typography>
    <ul className={bulkUploadList}>
      <li>
        Only same Org/Property Output and Feeds (e.g. each row must be the same
        Org or Property)
      </li>
      <li>Only Validate status Feeds (expects to go to a client Dev feed)</li>
      <li>Will always run as a one time/immediate job</li>
      <li>No contract deliverable needed</li>
    </ul>
  </>
)

export const columns = [
  {
    Header: "Output Id",
    accessor: "narrativeId",
    sortable: false,
    filterable: false,
    width: "80",
    Cell: ({ row }) => (
      <div
        style={
          row.original?.hasNarrativeError
            ? { color: "#c00", fontWeight: "bold", padding: "5px" }
            : { padding: "5px" }
        }
      >
        {row.original?.narrativeId}
      </div>
    )
  },
  {
    Header: "feedId",
    accessor: "feedId",
    width: "80",
    Cell: ({ row }) => (
      <div
        style={
          row.original?.hasFeedError
            ? { color: "#c00", fontWeight: "bold", padding: "5px" }
            : { padding: "5px" }
        }
      >
        {row.original?.feedId}
      </div>
    )
  },
  {
    Header: "contentId",
    accessor: "contentId",
    width: "80",
    Cell: ({ row }) => (
      <div style={{ fontWeight: "bold", padding: "5px" }}>
        {row.original?.contentId}
      </div>
    )
  },
  {
    Header: "contentType",
    accessor: "contentType",
    width: "80",
    Cell: ({ row }) => (
      <div style={{ fontWeight: "bold", padding: "5px" }}>
        {row.original?.contentId}
      </div>
    )
  },
  {
    Header: "Duplicate Handling",
    accessor: "duplicateHandling",
    width: "80",
    Cell: ({ row }) => (
      <div style={{ fontWeight: "bold", padding: "5px" }}>
        {row.original?.duplicateHandling}
      </div>
    )
  },
  {
    Header: "Use Recurrence Settings",
    accessor: "useRecurrenceSettings",
    width: "80",
    Cell: ({ row }) => (
      <div style={{ fontWeight: "bold", padding: "5px" }}>
        {row.original?.useRecurrenceSettings ? "true" : "false"}
      </div>
    )
  },
  {
    Header: "Cron Syntax",
    accessor: "cronSyntax",
    width: "100",
    Cell: ({ row }) => (
      <div style={{ fontWeight: "bold", padding: "5px" }}>
        {row.original?.cronSyntax}
      </div>
    )
  },
  {
    Header: "cronTimeZoneIANA",
    accessor: "cronTimeZoneIANA",
    width: "150",
    Cell: ({ row }) => (
      <div style={{ fontWeight: "bold", padding: "5px" }}>
        {row.original?.cronTimeZoneIANA}
      </div>
    )
  },
  {
    Header: "Publish Delay (Seconds)",
    accessor: "publishDelayInSeconds",
    width: "80",
    Cell: ({ row }) => (
      <div style={{ fontWeight: "bold", padding: "5px" }}>
        {row.original?.publishDelayInSeconds}
      </div>
    )
  },
  {
    Header: "Start Date Time",
    accessor: "startdatetime",
    width: "200",
    Cell: ({ row }) => (
      <div style={{ fontWeight: "bold", padding: "5px" }}>
        {row.original?.startdatetime}
      </div>
    )
  },
  {
    Header: "Deliverable Id",
    accessor: "deliverableId",
    width: "200",
    Cell: ({ row }) => (
      <div style={{ fontWeight: "bold", padding: "5px" }}>
        {row.original?.deliverableId}
      </div>
    )
  },
  {
    Header: "Next Occurence",
    accessor: "futureOccurences",
    width: "200",
    Cell: ({ row }) => (
      <div style={{ fontWeight: "bold", padding: "5px" }}>
        {row.original?.futureOccurences}
      </div>
    )
  },
  {
    Header: "Errors?",
    accessor: "hasError",
    width: "200",
    Cell: ({ row }) => {
      const errors = []
      if (row.original?.hasError) {
        if (row.original?.hasFeedError) {
          errors.push("Feed Error")
        }
        if (row.original?.hasNarrativeError) {
          errors.push("Output Error")
        }
        if (row.original?.hasWrongFeedStatus) {
          errors.push("Wrong Feed Status")
        }
        if (row.original?.hasOrgFeedMismatch) {
          errors.push("Org/Feed mismatch Error")
        }
        if (row.original?.hasWrongDuplicateHandling) {
          errors.push("Duplicate Handling value Error")
        }
        if (row.original?.hasNoDeliverableId) {
          errors.push("Deliverable ID Error")
        }
        if (row.original?.hasArchivedDeliverableId) {
          errors.push("Archived Deliverable ID Error")
        }
        if (!errors.length) {
          errors.push("Yes")
        }
      }
      return (
        <ul>
          {errors.map((err, i) => (
            <li key={i}>{err}</li>
          ))}
        </ul>
      )
    }
  }
]

export const errorColumn = [
  {
    Header: "Index",
    accessor: "index",
    width: 75,
    Cell: ({ cell }) => (
      <>
        {cell?.row?.original?.fromAPI ? (
          <div>
            {cell?.row?.original.hasError ? (
              <>
                {cell?.row?.index + 1}{" "}
                <Report
                  style={{
                    color: "#a31545",
                    width: "28px",
                    height: "28px"
                  }}
                />
              </>
            ) : (
              <>
                {cell?.row?.index + 1}{" "}
                <Check
                  style={{
                    color: "#66bb6a",
                    width: "28px",
                    height: "28px"
                  }}
                />
              </>
            )}
          </div>
        ) : (
          <>
            {cell?.row?.original.hasError ? (
              <>
                {cell?.row?.index + 1}{" "}
                <Report
                  style={{
                    color: "#a31545",
                    width: "28px",
                    height: "28px"
                  }}
                />
              </>
            ) : (
              <>
                {cell?.row?.index + 1}{" "}
                <Check
                  style={{
                    color: "#66bb6a",
                    width: "28px",
                    height: "28px"
                  }}
                />
              </>
            )}
          </>
        )}
      </>
    )
  }
]
