// eslint-disable-next-line no-unused-vars
import React, { Fragment, useEffect } from "react"
import { useObserver } from "mobx-react"
import { PersonAddOutlined } from "@mui/icons-material"

import { useStore } from "contexts/rootContext"
import Dialog, { Confirmation } from "components/Dialog"
import Account from "./Account"
import AccountForm from "./Account/AccountForm"
import AccountList from "./Accounts/AccountList"

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    loading: store.accountStore.loading,
    isAddAccountModalOpen: store.accountStore.isAddAccountModalOpen,
    setIsAddAccountModalOpen: store.accountStore.setIsAddAccountModalOpen,
    setAccountId: store.accountStore.setAccountId,
    isAccountSelected: store.accountStore.isAccountSelected,
    getAccounts: store.accountStore.getAccounts,
    showUpsertConfirmation: store.accountStore.showUpsertConfirmation,
    closeAddAccountModal: store.accountStore.closeAddAccountModal,
    upsertedAccountFullName: store.accountStore.upsertedAccountFullName,
    getOrganizations: store.organizationStore.getOrganizations,
    getAccountStatuses: store.accountStatusStore.getAccountStatuses,
    getPermissionGroups: store.permissionGroupStore.getPermissionGroups
  }))
}

const Accounts = () => {
  const {
    loading,
    isAddAccountModalOpen,
    setIsAddAccountModalOpen,
    setAccountId,
    isAccountSelected,
    getAccounts,
    showUpsertConfirmation,
    closeAddAccountModal,
    upsertedAccountFullName,
    getOrganizations,
    getAccountStatuses,
    getPermissionGroups
  } = useStoreData()

  // on first render
  useEffect(() => {
    getAccounts()
    getOrganizations()
    getAccountStatuses()
    getPermissionGroups()
  }, [])

  return (
    <Fragment>
      {/* Edit accounts */}
      {isAccountSelected && <Account onCancel={() => setAccountId(null)} />}

      {/* Create accounts */}
      {!isAccountSelected && (
        <Fragment>
          <AccountList />
          <Dialog
            isOpen={isAddAccountModalOpen}
            loading={loading}
            title={
              showUpsertConfirmation
                ? "Account Successfully Added!"
                : "Add Account"
            }
            icon={<PersonAddOutlined />}
            isConfirmation={showUpsertConfirmation}
            onClose={closeAddAccountModal}
          >
            {!showUpsertConfirmation && (
              <AccountForm onCancel={() => setIsAddAccountModalOpen(false)} />
            )}
            {showUpsertConfirmation && (
              <Confirmation
                body1={`You successfully created an account for ${upsertedAccountFullName}`}
                body2="They will be getting an email notification shortly."
              />
            )}
          </Dialog>
        </Fragment>
      )}
    </Fragment>
  )
}

export default Accounts
