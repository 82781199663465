import React from "react"
import PropTypes from "prop-types"

// MUI
import Button from "@mui/material/Button"
import Badge from "@mui/material/Badge"
import FilterListIcon from "@mui/icons-material/FilterList"

// MUI Hooks
import { useFilterPanelStyles } from "../styles/FilteredTable.styles"

const FilterButton = ({ onClick, filterCount }) => {
  const { anchorOriginTopRightCircular } = useFilterPanelStyles()

  return (
    <Button
      onClick={onClick}
      variant="contained"
      style={{ backgroundColor: "#ddeaef", color: "#000" }}
      size="small"
      startIcon={
        <Badge
          style={{ display: "flex" }}
          classes={{
            anchorOriginTopRightCircular: anchorOriginTopRightCircular
          }}
          overlap="circular"
          badgeContent={filterCount}
          color="primary"
          disabled
        >
          <FilterListIcon />
        </Badge>
      }
    >
      Filter
    </Button>
  )
}

FilterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  filterCount: PropTypes.number
}

export default FilterButton
