import makeStyles from "@mui/styles/makeStyles"

const useRenderToolStyles = makeStyles(theme => ({
  gridContainer: {
    marginBottom: "2rem"
  },
  cardTitle: {
    display: "inline-block"
  },
  cardSubTitle: {
    textAlign: "center",
    fontSize: "1.5rem",
    marginBottom: "1rem"
  },
  helpIcon: {
    float: "right"
  },
  statusContainer: {
    textAlign: "center"
  },
  controlContainer: {
    textAlign: "center"
  },
  subHeading: {
    fontSize: "2rem",
    marginBottom: "1rem"
  },
  statusLabel: {
    fontSize: "1.5rem"
  },
  botCount: {
    fontSize: "5rem",
    color: theme.palette.primary.main
  },
  switchContainer: {
    alignItems: "center",
    marginTop: "1rem"
  },
  switchLabel: {
    color: theme.palette.common.black,
    fontSize: "1rem"
  },
  errorMessage: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.error.main,
    fontSize: "2.5rem"
  },
  errorIcon: {
    color: theme.palette.error.main,
    fontSize: "2.5rem",
    marginRight: "1rem"
  },
  falseIcon: {
    color: theme.palette.error.main
  },
  successIcon: {
    color: theme.palette.success.main
  },
  snackbar: {
    fontSize: "1rem"
  }
}))

export { useRenderToolStyles }
