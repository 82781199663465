import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Icon, Tab, Tabs, TextField } from "@mui/material"
import { cardTitle } from "assets/jss/material-dashboard-pro-react"
import Typography from "@mui/material/Typography"
import ReactJson from "react-json-view"
import Button from "components/CustomButtons/Button"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardHeader from "components/Card/CardHeader"
import ReactDiffViewer from "react-diff-viewer"
import { CSVLink } from "react-csv"
import NarrativeBlockSnapshots from "components/NarrativeAdmin/Data/NarrativeBlockSnapshots"

import Helpers from "tools/Helpers.js"
import CircularProgress from "@mui/material/CircularProgress"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import CardIcon from "components/Card/CardIcon"
import Assignment from "@mui/icons-material/Assignment"
import ArrowBack from "@mui/icons-material/ArrowBack"
import SpellCheckIcon from "@mui/icons-material/Spellcheck"
import { Editor } from "@tinymce/tinymce-react"

import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"
import { Visibility } from "@mui/icons-material"
import FeedEntryPreviewPage from "views/Pages/FeedEntryPreviewPage"

import { Cookies } from "tools/storage"
import ChangeLog from "../../components/NarrativeAdmin/Data/ChangeLog"
import Accordion from "components/Accordion/Accordion"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"

import { useStore } from "contexts/rootContext"
import { useObserver } from "mobx-react"
import { makeStyles } from "@mui/styles"
import FeedEntryDAO from "daos/feedEntryDAO"

const useStyles = makeStyles({
  jsonTreeHolder: {
    "& .variable-editor": {
      width: "85%"
    },
    "& .variable-value": {
      width: "85%"
    }
  },
  paddedGridItem: {
    textAlign: "right",
    margin: "15px 25px"
  },
  cardIconTitle: {
    ...cardTitle
  }
})

const TabContainer = props => (
  <Typography component="div" style={{ padding: 8 * 3 }}>
    {props.children}
  </Typography>
)

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    getNarrativeBase: store.narrativeStore.getNarrativeBase,
    narrative: store.narrativeStore.narrative,
    organizationId: store.organizationStore.organizationId,
    setOrganizationId: store.organizationStore.setOrganizationId,
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization
  }))
}

const FeedEntryEditor = props => {
  let cookies = new Cookies()
  const {
    getNarrativeBase,
    narrative,
    organizationId,
    setOrganizationId,
    setShowChangeCurrentOrganization
  } = useStoreData()

  const [masterState, setMasterState] = useState({})
  const [feedEntry, setFeedEntry] = useState({})
  const [feedEntryDataModel, setFeedEntryDataModel] = useState({})
  const [activeTab, setActiveTab] = useState(
    Number(cookies.get("lastFeedEntryEditorActiveTab")) || 1
  )
  const [feed, setFeed] = useState({})
  const [isFeedEntryLoading, setIsFeedEntryLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isChangeLogsLoaded, setIsChangeLogsLoaded] = useState(false)

  const [isChangeLogsLoading, setIsChangeLogsLoading] = useState(false)
  const [isRenderedBlocksLoading, setIsRenderedBlocksLoading] = useState(false)
  const [isRenderedBlocksLoaded, setIsRenderedBlocksLoaded] = useState(false)
  const [feedEntryTriggers, setFeedEntryTriggers] = useState(false)

  const [feedEntryBlockSnapShots, setFeedEntryBlockSnapShots] = useState([])
  const [feedEntryLogs, setFeedEntryLogs] = useState([])
  const [htmlValidationErrors, setHtmlValidationErrors] = useState([])
  const [prettifyJSON, setPrettifyJSON] = useState(false)

  useEffect(() => {
    setShowChangeCurrentOrganization(true)

    let match = props && props.match
    if (match) {
      fetchFeedEntryInfo(match.params.entryId)
    } else {
      setMasterState({})
    }
  }, [])

  const fetchFeedEntryInfo = entryId => {
    setIsFeedEntryLoading(true)
    FeedEntryDAO.getFeedEntryInfo(entryId).then(feedEntry => {
      if (!feedEntry) {
        setIsFeedEntryLoading(false)
        return
      }
      setFeedEntry(feedEntry)
      setIsFeedEntryLoading(false)
      if (activeTab === 3) {
        fetchBlockSnapShot(entryId, feedEntry.versionNumber)
      }
      if (activeTab === 6) {
        fetchDataModelSnapShot(entryId, feedEntry.versionNumber)
      }
      fetchFeed(feedEntry.feed_Id)
      getNarrativeBase(feedEntry.narrative_Id)
    })
  }

  const fetchFeed = feedId => {
    setIsLoading(true)
    FeedEntryDAO.getFeed(feedId).then(feed => {
      if (organizationId !== feed.organization.id) {
        setOrganizationId(feed.organization.id)
        let cookies = new Cookies()
        cookies.set("currentorg", feed.organization.id, {
          path: "/",
          expires: Helpers.CookieExpiration.OneMonth
        })
      }
      setFeed(feed)
      setIsLoading(false)
    })
  }

  const fetchFeedEntryLogs = entryId => {
    setIsChangeLogsLoading(true)
    FeedEntryDAO.getFeedEntryLogs(entryId).then(content => {
      if (!content) {
        return
      }
      setIsChangeLogsLoaded(true)
      setFeedEntryLogs(content.content)
      setIsChangeLogsLoading(false)
    })
  }

  const fetchBlockSnapShot = (entryId, versionNumber) => {
    setIsRenderedBlocksLoading(true)
    FeedEntryDAO.getFeedBlockSnapShot(entryId, versionNumber).then(
      feedEntryBlkSnapShots => {
        if (!feedEntryBlkSnapShots) {
          return
        }
        for (let shots of feedEntryBlkSnapShots) {
          if (
            shots.hasTemplateErrors === true ||
            shots.hasScriptErrors === true ||
            shots.failedValidation === true
          ) {
            shots.hasErrors = true
          } else {
            shots.hasErrors = false
          }
        }
        setFeedEntryBlockSnapShots(feedEntryBlkSnapShots)
        setIsRenderedBlocksLoading(false)
        setIsRenderedBlocksLoaded(true)
      }
    )
  }

  const fetchDataModelSnapShot = (entryId, versionNumber) => {
    const newMasterState = {
      ...masterState,
      isDataModelLoading: true,
      activeTab: 6
    }
    setMasterState(newMasterState)
    setActiveTab(6)
    FeedEntryDAO.getFeedDataModelSnapShot(entryId, versionNumber).then(data => {
      let modelResponse = data.content
      const newMasterState = {
        ...masterState,
        activeTab: 6,
        feedEntryDataModel: JSON.parse(modelResponse.model),
        feedEntryTriggers: modelResponse.triggers,
        isDataModelLoading: false
      }
      setFeedEntryDataModel(JSON.parse(modelResponse.model))
      setFeedEntryTriggers(modelResponse.triggers)
      setMasterState(newMasterState)
    })
  }

  const validateHtml = () => {
    FeedEntryDAO.validateHtml(feedEntry.content).then(response => {
      setHtmlValidationErrors(response.content)
    })
  }

  const onFullyUpdate = updateObject => {
    let { updated_src, name, new_value, existing_value } = updateObject
    let newChangelog = masterState.changeLog || []
    let change = {
      changeNumber: newChangelog.length + 1,
      name,
      new_value,
      existing_value
    }
    newChangelog.push(change)
    const newMasterState = {
      ...masterState,
      isFeedEntryDirty: true,
      feedEntry: updated_src,
      changeLog: newChangelog
    }
    setMasterState(newMasterState)
  }

  const saveFeedEntry = () => {
    // Save every property except model and renderedparagaphs in the [feedentry object]
    const { ...feedEntryToSave } = masterState.feedEntry || feedEntry
    FeedEntryDAO.postFeedEntry(feedEntryToSave).then(() => {
      fetchFeedEntryLogs(feedEntry.id)
      props.history.push(`/portal/feed-entries/entry/${feedEntry.id}`)
      const newMasterState = {
        ...masterState,
        isFeedEntryDirty: false,
        isSaved: true
      }
      setMasterState(newMasterState)
    })
  }

  const handleEditorChange = e => {
    const newMasterState = {
      ...masterState,
      wysiwygEditorContent: e.target.getContent()
    }
    setMasterState(newMasterState)
  }

  const data = feedEntryBlockSnapShots
  const classes = useStyles()

  if (isLoading && feed && feed === 666 && feedEntryDataModel) {
    return
  }
  if (!feedEntry?.id) {
    return "Assembling..."
  }
  return (
    <>
      <Dialog
        open={masterState.isSaved}
        onClose={() => {
          const newMasterState = {
            ...masterState,
            isSaved: false
          }
          setMasterState(newMasterState)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Feed Entry Changes Saved
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your changes have been saved.
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <Assignment />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>
            {feedEntry.id} - {feedEntry.title}
          </h4>
          <h5 className={classes.cardIconTitle}>
            <Link
              to={`/portal/narrative/${feedEntry.narrative_Id}/edit`}
              title="Edit Narrative"
              target="_blank"
            >
              {feedEntry.narrative_Id} - {narrative.name}
            </Link>
          </h5>
          <Link
            to={`/portal/feed-entries/${feedEntry.feed_Id}`}
            title="Back to feed entry list"
          >
            <h6>
              <ArrowBack />
              Back to feed entries
            </h6>
          </Link>
        </CardHeader>
        <CardBody>
          <div className={classes.jsonTreeHolder}>
            <Tabs
              classes={{
                root: "border-b-1",
                flexContainer: "justify-end"
              }}
              value={activeTab}
              onChange={(event, value) => {
                setActiveTab(value)
                // Always reload "change logs" data
                if (value === 2) {
                  fetchFeedEntryLogs(feedEntry.id)
                }
                let cookies = new Cookies()
                cookies.set("lastFeedEntryEditorActiveTab", value, {
                  path: "/",
                  expires: Helpers.CookieExpiration.OneMonth
                })
              }}
              variant="scrollable"
            >
              <Tab
                classes={{ root: "min-w-64" }}
                icon={<Icon>code</Icon>}
                label="Feed Entry"
              />
              <Tab
                classes={{ root: "min-w-64" }}
                icon={<Icon>report_problem_outlined</Icon>}
                label="Unsaved Changes"
              />
              <Tab
                classes={{ root: "min-w-64" }}
                icon={<Icon>change_history</Icon>}
                label="Change Log"
                onClick={() => fetchFeedEntryLogs(feedEntry.id)}
              />
              <Tab
                classes={{ root: "min-w-64" }}
                icon={<Icon>widgets</Icon>}
                label="Rendered Blocks"
                onClick={() => {
                  !isRenderedBlocksLoaded &&
                    fetchBlockSnapShot(feedEntry.id, feedEntry.versionNumber)
                }}
              />
              <Tab
                classes={{ root: "min-w-64" }}
                icon={<SpellCheckIcon />}
                label="WYSIWYG Editor"
                onClick={() => {
                  validateHtml()
                }}
              />
              <Tab
                classes={{ root: "min-w-64" }}
                icon={<Visibility />}
                label="Preview"
              />
              <Tab
                classes={{ root: "min-w-64" }}
                icon={<Icon>album</Icon>}
                label="Model &amp; Triggers"
                onClick={() => {
                  !masterState.isDataModelLoading &&
                    fetchDataModelSnapShot(
                      feedEntry.id,
                      feedEntry.versionNumber
                    )
                }}
              />
            </Tabs>
            {activeTab === 0 && (
              <TabContainer>
                <ReactJson
                  theme="monokai"
                  enableClipboard={true}
                  displayObjectSize={true}
                  displayDataTypes={false}
                  src={feedEntry}
                  collapsed={false}
                  collapseStringsAfterLength={450}
                  shouldCollapse={({ name }) =>
                    name === "feed" ||
                    name === "categories" ||
                    name === "triggers" ||
                    name === "creator" ||
                    name === "modifier" ||
                    name === "auditInfo" ||
                    name === "apNewsManagement" ||
                    name === "author" ||
                    name === "selectedSentenceObjects" ||
                    name === "model" ||
                    name === "renderedParagraphs" ||
                    name === "characteristics"
                  }
                  onEdit={edit => onFullyUpdate(edit)}
                />
                <div className={classes.paddedGridItem}>
                  <Button
                    onClick={() => saveFeedEntry()}
                    disabled={!masterState.isFeedEntryDirty}
                  >
                    Save Changes
                  </Button>
                </div>
              </TabContainer>
            )}
            {activeTab === 1 && (
              <TabContainer>
                <ReactJson
                  enableClipboard={true}
                  displayObjectSize={false}
                  displayDataTypes={false}
                  src={masterState.changeLog}
                  collapsed={false}
                />
                <div className={classes.paddedGridItem}>
                  <Button
                    onClick={() => saveFeedEntry()}
                    disabled={!masterState.isFeedEntryDirty}
                  >
                    Save Changes
                  </Button>
                </div>
              </TabContainer>
            )}
            {activeTab === 2 && (
              <TabContainer>
                <Card>
                  <CardBody>
                    <ChangeLog
                      handleRollBack={false}
                      changeLogs={feedEntryLogs}
                      totalChangeLogRecords={feedEntryLogs.length}
                      isChangeLogsLoading={isChangeLogsLoading}
                      isChangeLogsLoaded={isChangeLogsLoaded}
                    />
                  </CardBody>
                </Card>
              </TabContainer>
            )}
            {activeTab === 3 && (
              <TabContainer>
                <div style={{ marginLeft: "25px" }}>
                  <button className="btn btn-primary">
                    <CSVLink data={data} style={{ color: "#fff" }}>
                      Export CSV
                    </CSVLink>
                  </button>
                </div>
                <Card>
                  <CardBody>
                    <NarrativeBlockSnapshots
                      embedded={true}
                      feedEntry={feedEntry}
                      isLoading={isFeedEntryLoading || isRenderedBlocksLoading}
                      snapshots={feedEntryBlockSnapShots}
                    />
                  </CardBody>
                </Card>
              </TabContainer>
            )}
            {activeTab === 4 && (
              <TabContainer>
                <Card>
                  <CardBody>
                    <Editor
                      apiKey="rpxxr36ah4ei6v91l9blwt1ceamufuog4ta1906jtt9by41t"
                      initialValue={feedEntry.content}
                      init={{
                        height: 500,
                        menubar: true,
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount"
                        ],
                        spellchecker_dialog: false,
                        spellchecker_on_load: false,
                        toolbar: [
                          "undo redo | formatselect | bold italic backcolor | ",
                          "alignleft aligncenter alignright alignjustify | ",
                          "bullist numlist outdent indent | removeformat | help"
                        ].join(""),
                        protect: [
                          /<\/?(if|endif)>/g, // Protect <if> & </endif>
                          /<xsl:[^>]+>/g, // Protect <xsl:...>
                          /<\?php.*?\?>/g, // Protect php code
                          /<\/?(apxh:div|apxh:p)>/g // Protect <apxh:div /> & <apxh:p />
                        ],
                        force_br_newlines: false,
                        force_p_newlines: false,
                        forced_root_block: "",
                        remove_linebreaks: false,
                        convert_newlines_to_brs: false,
                        preformatted: true
                      }}
                      onChange={handleEditorChange}
                    />
                    <Accordion
                      active={null}
                      collapses={[
                        {
                          title: `HTML Validation Errors (${htmlValidationErrors.length})`,
                          content: (
                            <div>
                              {htmlValidationErrors.map(error => (
                                <div>
                                  Line #{error.errorLineNumber} -{" "}
                                  {error.errorReason}
                                </div>
                              ))}
                            </div>
                          )
                        },
                        {
                          title: "Content (before WYSIWYG Editor)",
                          content: (
                            <div>
                              {" "}
                              <TextField
                                fullWidth={true}
                                multiline={true}
                                //rows={15}
                                value={feedEntry.content}
                                placeholder={`Raw 'Content' before WYSIWYG Editor)`}
                              />
                            </div>
                          )
                        },
                        {
                          title: "Content (after WYSIWYG Editor)",
                          content: (
                            <div>
                              {" "}
                              <TextField
                                fullWidth={true}
                                multiline={true}
                                //rows={15}
                                value={masterState.wysiwygEditorContent}
                                placeholder={`Raw 'Content' after WYSIWYG Editor.`}
                              />
                            </div>
                          )
                        },
                        {
                          title: "Diff Viewer",
                          content: (
                            <ReactDiffViewer
                              oldValue={feedEntry.content}
                              newValue={masterState.wysiwygEditorContent}
                              splitView={true}
                            />
                          )
                        }
                      ]}
                    />
                  </CardBody>
                </Card>
              </TabContainer>
            )}
            {activeTab === 5 && feedEntry.feedEntryToken && (
              <div>
                <FeedEntryPreviewPage
                  match={{
                    params: {
                      feedEntryToken: feedEntry.feedEntryToken
                    }
                  }}
                />
              </div>
            )}
            {activeTab === 6 && (
              <div>
                <GridContainer>
                  <GridItem xs={6}>
                    <form>
                      <h5>
                        <u>Model (JSON)</u>
                      </h5>
                      {masterState.isDataModelLoading && <CircularProgress />}
                      {!masterState.isDataModelLoading && (
                        <TextField
                          id="narrative-data-model"
                          fullWidth={true}
                          multiline={true}
                          rows={30}
                          value={JSON.stringify(feedEntryDataModel, null, 2)}
                        />
                      )}
                    </form>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={prettifyJSON}
                          onChangeCapture={event => {
                            setPrettifyJSON(event.target.checked)
                          }}
                          value="doPrettifyDataModelJSON"
                          classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            icon: classes.switchIcon,
                            iconChecked: classes.switchIconChecked,
                            bar: classes.switchBar
                          }}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label="Prettify JSON?"
                    />
                    {prettifyJSON && (
                      <ReactJson
                        enableClipboard={true}
                        displayObjectSize={false}
                        displayDataTypes={false}
                        src={feedEntryDataModel}
                        collapsed={false}
                      />
                    )}
                  </GridItem>
                  <GridItem xs={6}>
                    <form>
                      <h5>
                        <u>Triggers (JSON)</u>
                      </h5>
                      {masterState.isDataModelLoading && <CircularProgress />}
                      {!masterState.isDataModelLoading && (
                        <TextField
                          id="data-model-triggers"
                          fullWidth={true}
                          multiline={true}
                          rows={30}
                          value={JSON.stringify(feedEntryTriggers, null, 2)}
                        />
                      )}
                    </form>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={prettifyJSON}
                          onChangeCapture={event => {
                            setPrettifyJSON(event.target.checked)
                          }}
                          value="doPrettifyDataModelJSON"
                          classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            icon: classes.switchIcon,
                            iconChecked: classes.switchIconChecked,
                            bar: classes.switchBar
                          }}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label="Prettify JSON?"
                    />
                    {prettifyJSON && (
                      <ReactJson
                        enableClipboard={true}
                        displayObjectSize={false}
                        displayDataTypes={false}
                        src={feedEntryTriggers}
                        collapsed={false}
                      />
                    )}
                  </GridItem>
                </GridContainer>
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </>
  )
}
export default FeedEntryEditor
