import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import { NavLink, useLocation } from "react-router-dom"
import cx from "classnames"
import withStyles from "@mui/styles/withStyles"
import Drawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Collapse from "@mui/material/Collapse"
import Icon from "@mui/material/Icon"
import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle"
import { observer, inject } from "mobx-react"
import OrganizationsMenu from "../Organizations/OrganizationsMenu"
import Gravatar from "react-gravatar"
import { globalSettings } from "variables/general.jsx"
import Box from "@mui/material/Box"
import SidebarWrapper from "./SidebarWrapper"
import { checkUserPermissions } from "./checkUserPermissions"

const useStore = component =>
  inject(({ store }) => ({
    organizationId: store.organizationStore.organizationId,
    isAccountDetailsLoaded: store.uiStore.isAccountDetailsLoaded,
    isPermissionsLoaded: store.uiStore.isPermissionsLoaded,
    account: store.accountStore.account,
    accountPermissions: store.accountStore.accountPermissions
  }))(observer(component))

const SidebarNestedMenus = props => {
  // State management using useState hook
  const [states, setStates] = useState({
    openAvatar: false,
    miniActive: true
  })
  const location = useLocation() // Right way to use the useLocation hook, at the top

  const mainPanelRef = useRef(null)
  // get values from props and set up variables
  // Destructured props for easier access
  const {
    classes,
    logo,
    image,
    logoText,
    routes,
    bgColor,
    rtlActive,
    miniActive,
    isAccountDetailsLoaded,
    isPermissionsLoaded,
    account
  } = props

  if (!isAccountDetailsLoaded || !isPermissionsLoaded) {
    // Perhaps include a fallback UI here, such as a loader
    return <div>Loading...</div>
  }

  // Helper function to generate class names
  const getClassNames = (baseClass, conditionClasses) =>
    `${baseClass} ${cx(
      Object.entries(conditionClasses).reduce(
        (accumulator, [key, condition]) => {
          if (condition) {
            accumulator[key] = true
          }
          return accumulator
        },
        {}
      )
    )}`

  const isActiveMini = miniActive && states.miniActive

  // Generate class names with conditions
  const itemText = getClassNames(classes.itemText, {
    [classes.itemTextMini]: isActiveMini,
    [classes.itemTextMiniRTL]: rtlActive && isActiveMini,
    [classes.itemTextRTL]: rtlActive
  })

  const collapseItemText = getClassNames(classes.collapseItemText, {
    [classes.collapseItemTextMini]: isActiveMini,
    [classes.collapseItemTextMiniRTL]: rtlActive && isActiveMini,
    [classes.collapseItemTextRTL]: rtlActive
  })

  const userWrapperClass = getClassNames(classes.user, {
    [classes.whiteAfter]: bgColor === "white"
  })

  const caret = getClassNames(classes.caret, {
    [classes.caretRTL]: rtlActive
  })

  const collapseItemMini = getClassNames(classes.collapseItemMini, {
    [classes.collapseItemMiniRTL]: rtlActive
  })

  const photo = getClassNames(classes.photo, {
    [classes.photoRTL]: rtlActive
  })

  // Initialize default account values
  const accountName =
    account?.firstName || account?.username || account?.email || "anonymous"
  const accountEmail = account?.email || null

  // Toggles collapse states
  const openCollapse = collapse => {
    setStates(prevState => ({ ...prevState, [collapse]: !prevState[collapse] }))
  }
  const hasPermission = checkUserPermissions(props)

  //Below is the original activeRoute using includes instead of exact match
  //leaving in for now
  //const activeRoute = routeName => location.pathname.includes(routeName)
  const activeRoute = routeName => location.pathname === routeName

  const renderMenuItem = (routes, key, location) =>
    routes.map((prop, index) => {
      if (prop.redirect || prop.hideInNav) {
        return null
      }

      if (!hasPermission(prop)) {
        return null
      }

      let iconElement = null
      if (prop.icon) {
        if (typeof prop.icon === "string") {
          iconElement = <Icon>{prop.icon}</Icon>
        } else if (React.isValidElement(React.createElement(prop.icon))) {
          iconElement = <prop.icon />
        }
      }

      const linkClassName = `${classes.itemLink} ${
        activeRoute(prop.path) ? classes.white : ""
      }`

      if (prop.collapse) {
        return (
          <ListItem key={`${key}_${index}`} className={classes.item}>
            <NavLink
              to={"#"}
              className={`${classes.itemLink} ${
                activeRoute(prop.path) ? classes.white : ""
              }`}
              onClick={() => openCollapse(prop.state)}
            >
              <ListItemIcon
                className={`${classes.itemIcon} ${
                  rtlActive ? classes.itemIconRTL : ""
                }`}
              >
                {iconElement}
              </ListItemIcon>
              <ListItemText
                primary={prop.name}
                secondary={
                  <b
                    className={`${classes.caret} ${
                      states[prop.state] ? classes.caretActive : ""
                    }`}
                  />
                }
                disableTypography={true}
                className={`${classes.itemText} ${
                  rtlActive ? classes.itemTextRTL : ""
                }`}
              />
            </NavLink>
            <Collapse in={states[prop.state]} unmountOnExit>
              <List className={`${classes.list} ${classes.collapseList}`}>
                {renderMenuItem(
                  prop.views,
                  `${prop.name}-parent-key`,
                  location
                )}
              </List>
            </Collapse>
          </ListItem>
        )
      } else {
        // Check if the prop represents an external link
        const isExternal = prop.external || false // Add an external flag to your route definitions

        const linkComponent = isExternal ? (
          <a
            href={prop.path}
            target="_blank"
            className={linkClassName}
            rel="noreferrer"
          >
            <ListItemIcon
              className={`${classes.itemIcon} ${
                rtlActive ? classes.itemIconRTL : ""
              }`}
            >
              {iconElement}
            </ListItemIcon>
            <ListItemText
              primary={prop.name}
              disableTypography={true}
              className={`${classes.itemText} ${
                rtlActive ? classes.itemTextRTL : ""
              }`}
            />
          </a>
        ) : (
          <NavLink
            to={prop.path}
            className={`${classes.itemLink} ${
              activeRoute(prop.path) ? classes.white : ""
            }`}
          >
            <ListItemIcon
              className={`${classes.itemIcon} ${
                rtlActive ? classes.itemIconRTL : ""
              }`}
            >
              {iconElement}
            </ListItemIcon>
            <ListItemText
              primary={prop.name}
              disableTypography={true}
              className={`${classes.itemText} ${
                rtlActive ? classes.itemTextRTL : ""
              }`}
            />
          </NavLink>
        )

        return (
          <ListItem key={`${key}_${index}`} className={classes.item}>
            {linkComponent}
          </ListItem>
        )
      }
    })
  const user = (
    <div className={userWrapperClass}>
      <div className={photo}>
        <Gravatar
          email={accountEmail}
          className={classes.avatarImg}
          default="mp"
          size={35}
        />
      </div>
      <List className={classes.list}>
        <ListItem className={`${classes.item} ${classes.userItem}`}>
          <NavLink
            to={"#"}
            className={`${classes.itemLink} ${classes.userCollapseButton}`}
            onClick={() => openCollapse("openAvatar")}
          >
            <ListItemText
              primary={rtlActive ? "تانيا أندرو" : `Welcome, ${accountName}`}
              secondary={
                <b
                  className={`${caret} ${classes.userCaret} ${
                    states.openAvatar ? classes.caretActive : ""
                  }`}
                />
              }
              disableTypography={true}
              className={`${itemText} ${classes.userItemText}`}
            />
          </NavLink>
          <Collapse in={states.openAvatar} unmountOnExit>
            <List className={`${classes.list} ${classes.collapseList}`}>
              <ListItem className={classes.collapseItem}>
                <NavLink
                  to="/portal/accountprofile"
                  className={`${classes.itemLink} ${classes.userCollapseLinks}`}
                >
                  <span className={collapseItemMini}>
                    {rtlActive ? "مع" : "PR"}
                  </span>
                  <ListItemText
                    primary={rtlActive ? "ملفي" : "Profile"}
                    disableTypography={true}
                    className={collapseItemText}
                  />
                </NavLink>
              </ListItem>
              <ListItem className={classes.collapseItem}>
                <NavLink
                  to="/portal/accountpermissions"
                  className={`${classes.itemLink} ${classes.userCollapseLinks}`}
                >
                  <span className={collapseItemMini}>
                    {rtlActive ? "و" : "P"}
                  </span>
                  <ListItemText
                    primary={rtlActive ? "إعدادات" : "Permissions"}
                    disableTypography={true}
                    className={collapseItemText}
                  />
                </NavLink>
              </ListItem>
              {props.isPermissionsLoaded &&
                props.accountPermissions.CanSeeAccountNotifications ===
                  "Yes" && (
                  <ListItem className={classes.collapseItem}>
                    <NavLink
                      to="/portal/accountnotifications"
                      className={`${classes.itemLink} ${classes.userCollapseLinks}`}
                    >
                      <span className={collapseItemMini}>
                        {rtlActive ? "و" : "N"}
                      </span>
                      <ListItemText
                        primary={rtlActive ? "إعدادات" : "Notifications"}
                        disableTypography={true}
                        className={collapseItemText}
                      />
                    </NavLink>
                  </ListItem>
                )}
              <ListItem className={classes.collapseItem}>
                <NavLink
                  to="/logout"
                  className={`${classes.itemLink} ${classes.userCollapseLinks}`}
                >
                  <span className={collapseItemMini}>
                    {rtlActive ? "و" : "L"}
                  </span>
                  <ListItemText
                    primary={rtlActive ? "إعدادات" : "Log Out"}
                    disableTypography={true}
                    className={collapseItemText}
                  />
                </NavLink>
              </ListItem>
            </List>
          </Collapse>
        </ListItem>
      </List>
    </div>
  )
  const orgMenu = (
    <OrganizationsMenu
      showOrgMenu={props.organizationId !== ""}
      organizationId={props.organizationId}
      classes={{
        input: {
          padding: 0,
          margin: "10px 15px 0",
          backgroundColor: "white",
          width: "89%"
        }
      }}
    />
  )
  const links = (
    <List className={classes.list} aria-label="Left Nav Menu">
      {renderMenuItem(routes, "menu", location)}
    </List>
  )
  const logoNormal = `${classes.logoNormal} ${cx({
    [classes.logoNormalSidebarMini]: props.miniActive && states.miniActive,
    [classes.logoNormalSidebarMiniRTL]:
      rtlActive && props.miniActive && states.miniActive,
    [classes.logoNormalRTL]: rtlActive
  })}`
  const logoMini = `${classes.logoMini} ${cx({
    [classes.logoMiniRTL]: rtlActive
  })}`
  const logoClasses = `${classes.logo} ${cx({
    [classes.whiteAfter]: bgColor === "dstealAccent"
  })}`
  const brand = (
    <div className={logoClasses}>
      <a
        href={`${globalSettings.portalBaseUrl}/portal/dashboard/`}
        className={logoMini}
      >
        <img src={logo} alt="logo" className={classes.img} />
      </a>
      <a
        href={`${globalSettings.portalBaseUrl}/portal/dashboard/`}
        className={logoNormal}
      >
        {logoText}
      </a>
    </div>
  )
  const drawerPaper = `${classes.drawerPaper} ${cx({
    [classes.drawerPaperMini]: props.miniActive && states.miniActive,
    [classes.drawerPaperRTL]: rtlActive
  })}`
  const sidebarWrapper = `${classes.sidebarWrapper} ${cx({
    [classes.drawerPaperMini]: props.miniActive && states.miniActive,
    [classes.sidebarWrapperWithPerfectScrollbar]:
      navigator.platform.indexOf("Win") > -1
  })}`

  return (
    <div ref={mainPanelRef}>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Drawer
          variant="temporary"
          anchor={rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: `${drawerPaper} ${classes[`${bgColor}Background`]}`
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <SidebarWrapper
            className={sidebarWrapper}
            user={user}
            links={links}
            orgMenu={orgMenu}
          />
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: `url(${image})` }}
            />
          ) : null}
        </Drawer>
      </Box>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <Drawer
          onMouseOver={() => setStates({ ...states, miniActive: false })}
          onMouseOut={() => setStates({ ...states, miniActive: true })}
          anchor={rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: `${drawerPaper} ${classes[`${bgColor}Background`]}`
          }}
        >
          {brand}
          <SidebarWrapper
            className={sidebarWrapper}
            user={user}
            links={links}
          />
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: `url(${image})` }}
            />
          ) : null}
        </Drawer>
      </Box>
    </div>
  )
}

SidebarNestedMenus.defaultProps = {
  bgColor: "blue"
}

SidebarNestedMenus.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue", "dstealAccent", "teal"]),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose",
    "dstealAccent"
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  accountPermissions: PropTypes.object
}

export default useStore(withStyles(sidebarStyle)(SidebarNestedMenus))
