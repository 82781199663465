import React, { useEffect, useState, useCallback } from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { Box } from "@mui/material"
import { TreeView } from "@mui/x-tree-view/TreeView"
import { TreeItem } from "@mui/x-tree-view/TreeItem"
import Cta_v2DAO from "daos/cta_v2DAO"
import LinearProgress from "@mui/material/LinearProgress"
import CircularProgress from "@mui/material/CircularProgress"
import { PropTypes } from "prop-types"
import queryString from "query-string"
import { useHistory } from "react-router-dom"
import makeStyles from "@mui/styles/makeStyles"
import { Menu, MenuItem } from "@mui/material"
import { Link } from "react-router-dom"
import { IconButton } from "@mui/material"
import { Launch } from "@mui/icons-material"
import useContextMenuHelper from "tools/CTAv2/ContextMenuHelper"

const useTreeItemStyles = makeStyles(theme => ({
  expanded: {},
  label: {
    fontWeight: "inherit",
    color: "inherit"
  },
  labelIcon: {
    fontSize: "22px",
    marginLeft: "-7px",
    width: "15px"
  },
  labelDetail: {
    fontWeight: "inherit",
    flexGrow: 1,
    paddingLeft: theme.spacing(0.75)
  },
  iconButton: {
    position: "absolute",
    right: "10px",
    display: "none",
    padding: 0,
    margin: 0,
    "& > span > svg": {
      width: ".6em",
      height: ".6em"
    }
  },
  iconButton2: {
    position: "absolute",
    right: "30px",
    display: "none",
    padding: 0,
    margin: 0,
    "& > span > a > svg": {
      width: ".55em",
      height: ".55em",
      color: "rgba(0, 0, 0, 0.54)"
    }
  },
  labelPosition: {
    color: "#3f51b5",
    fontWeight: "bold",
    flexShrink: 0,
    marginLeft: theme.spacing(0.5)
  },
  flex: {
    display: "flex"
  },
  flexmiddle: {
    display: "flex",
    alignItems: "center",
    "&:hover $iconButton, &:hover $iconButton2": {
      display: "inline-block"
    }
  },
  offerMenu: {
    left: "125px"
  }
}))

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    getLinkDisplayInfo: store.ctav2Store.getLinkDisplayInfo,
    offerLinksInfo: store.ctav2Store.offerLinksInfo,
    getLinkInfo: store.ctav2Store.getLinkInfo,
    getVariantById: store.ctav2Store.getVariantById,
    variantsByLinkId: store.ctav2Store.variantsByLinkId,
    setDialogWarningMessage: store.uiStore.setDialogWarningMessage,
    setDialogWarningOpen: store.uiStore.setDialogWarningOpen,
    dialogWarningSuccess: store.uiStore.dialogWarningSuccess,
    setDialogWarningSuccess: store.uiStore.setDialogWarningSuccess,
    setSuccessMessage: store.uiStore.setSuccessMessage,
    setIsSuccess: store.uiStore.setIsSuccess
  }))
}

function OfferLinkBlocks(props) {
  const {
    getLinkDisplayInfo,
    offerLinksInfo,
    variantsByLinkId,
    setDialogWarningMessage,
    setDialogWarningOpen,
    dialogWarningSuccess,
    setDialogWarningSuccess,
    setSuccessMessage,
    setIsSuccess
  } = useStoreData()
  const history = useHistory()
  const classes = useTreeItemStyles()
  const { autoLoadId, goToOfferLinkForm, linkInformation } = props
  const [variantsLoaded, setVariantsLoaded] = useState(false)
  const [actionType, setActionType] = useState("")
  const [delayedAction, setDelayedAction] = useState("")

  const [loadingVariants, setLoadingVariants] = useState(false)
  const [loadingOfferLinks, setLoadingOfferLinks] = useState(false)
  const [isExecutingActions, setIsExecutingActions] = useState(false)

  const [linkDisplayInfo, setLinkDisplayInfo] = useState([])
  const [linkDisplayObject, setLinkDisplayObject] = useState({})
  const [selectedLink, setSelectedLink] = useState({})
  const [expanded, setExpanded] = useState([])

  const { fetchVariantById } = props
  const [contextAddLinkMenuAnchor, setContextAddLinkMenuAnchor] = useState(null)
  const [contextLinkMenuAnchor, setContextLinkMenuAnchor] = useState(null)
  const [contextVariantMenuAnchor, setContextVariantMenuAnchor] = useState(null)

  const [addLinkMenuInfo, setAddLinkMenuInfo] = useState(null)
  const [linkMenuInfo, setLinkMenuInfo] = useState(null)
  const [variantMenuInfo, setVariantMenuInfo] = useState(null)

  const queryParams = queryString.parse(window.location.search.toLowerCase())

  const _INTENT_ID = 1
  const _LINK_PAGE_NAME = "offer"
  const {
    handleVariantMenuOpen,
    handleContextMenuLinkClose,
    handleContextMenuAddLinkClose,
    handleContextMenuVariantClose,
    handleVariantRightClick,
    handleLinkRightClick,
    handleAddVariantClick,
    handleAddLinkClick,
    handleVariantClick,
    handleLinkMenuOpen,
    handleAddLinkMenuOpen,
    fireLinkAction,
    fireVariantAction
  } = useContextMenuHelper({
    setContextVariantMenuAnchor,
    setVariantMenuInfo,
    setContextLinkMenuAnchor,
    setContextAddLinkMenuAnchor,
    setLinkMenuInfo,
    setAddLinkMenuInfo,
    setIsExecutingActions,
    fetchVariantById,
    setDialogWarningMessage,
    setSuccessMessage,
    setIsSuccess,
    Cta_v2DAO,
    setActionType,
    setDialogWarningOpen,
    setDelayedAction,
    actionType,
    linkMenuInfo,
    addLinkMenuInfo,
    getLinkDisplayInfo,
    variantMenuInfo,
    queryParams,
    history,
    props,
    _INTENT_ID,
    _LINK_PAGE_NAME
  })

  const getVariantInfo = (linkId, ldobj) => {
    setLoadingVariants(true)
    const getLinkVariants = Cta_v2DAO.getVariantsByLinkId(linkId)
    getLinkVariants.then(results => {
      if (results) {
        //console.log(results)
        if (results && results.length > 0) {
          let newObj = ldobj || linkDisplayObject
          Object.keys(newObj).forEach(ldo => {
            Object.keys(newObj[ldo]).forEach(ldc => {
              newObj[ldo][ldc].forEach((itm, j) => {
                if (linkId === itm.id) {
                  itm.variants = results
                  newObj[ldo][ldc][j].variants = results
                }
              })
            })
          })
          setLinkDisplayObject(newObj)
          setVariantsLoaded(true)
          setLoadingVariants(false)
        } else {
          setVariantsLoaded(true)
          setLoadingVariants(false)
        }
      }
    })
  }

  const variantAlreadyFetched = id => {
    let variantsLoaded = false
    Object.keys(linkDisplayObject).forEach(ldo => {
      Object.keys(linkDisplayObject[ldo]).forEach(ldc => {
        linkDisplayObject[ldo][ldc].forEach(itm => {
          if (itm.id === id && itm.variants) {
            variantsLoaded = true
          }
        })
      })
    })
    return variantsLoaded
  }

  const getFullVariantName = itm => {
    const leagueName =
      itm.leagues.length > 1
        ? "Multi"
        : itm.leagues.length === 1
        ? itm.leagues[0].name
        : "None"
    const middleWord = itm.verticalId === 1 ? leagueName : "Entertainment"
    return `${itm.name} - ${middleWord} - ${itm.displayStyle}`
  }

  const sortByFullVariantName = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const aString = getFullVariantName(a)
    const bString = getFullVariantName(b)
    const stringA = aString ? aString.toUpperCase() : ""
    const stringB = bString ? bString.toUpperCase() : ""
    let comparison = 0
    if (stringA > stringB) {
      comparison = 1
    } else if (stringA < stringB) {
      comparison = -1
    }
    return comparison
  }
  const getFullLinkName = itm =>
    `${itm.organizations[0] && itm.organizations[0].name}-${
      itm.companies[0] && itm.companies[0].name
    }-${itm.shortNameOffer}`

  const sortByFullLinkName = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const aString = getFullLinkName(a)
    const bString = getFullLinkName(b)
    const stringA = aString ? aString.toUpperCase() : ""
    const stringB = bString ? bString.toUpperCase() : ""
    let comparison = 0
    if (stringA > stringB) {
      comparison = 1
    } else if (stringA < stringB) {
      comparison = -1
    }
    return comparison
  }

  const sortByObjKey = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const stringA = a ? a.toUpperCase() : ""
    const stringB = b ? b.toUpperCase() : ""
    let comparison = 0
    if (stringA > stringB) {
      comparison = 1
    } else if (stringA < stringB) {
      comparison = -1
    }
    return comparison
  }

  useEffect(() => {
    if (dialogWarningSuccess) {
      if (delayedAction === "Archive-Link") {
        setActionType("Archive")
        fireLinkAction("Archive")
        setDelayedAction("")
      } else if (delayedAction === "Archive-Variant") {
        setActionType("Archive")
        fireVariantAction("Archive")
        setDelayedAction("")
      }
      setDialogWarningSuccess(false)
      setDialogWarningOpen(false)
    } else if (!dialogWarningSuccess) {
      if (actionType === "Copy:Link:Pending") {
        setActionType("")
      }
    }
  }, [dialogWarningSuccess])

  useEffect(() => {
    if (!linkDisplayInfo.length) {
      getLinkDisplayInfo(1)
      setLoadingOfferLinks(true)
    }
  }, [])

  useEffect(() => {
    if (variantsByLinkId && variantsByLinkId.length > 0) {
      let newObj = linkDisplayObject
      Object.keys(newObj).forEach(ldo => {
        Object.keys(newObj[ldo]).forEach(ldc => {
          newObj[ldo][ldc].forEach((itm, j) => {
            if (variantsByLinkId[0].linkId === itm.id) {
              itm.variants = variantsByLinkId
              newObj[ldo][ldc][j].variants = variantsByLinkId
            }
          })
        })
      })
      setLinkDisplayObject(newObj)
    }
  }, [variantsByLinkId])

  useEffect(() => {
    if (offerLinksInfo?.length) {
      setLinkDisplayInfo(offerLinksInfo)
      setLoadingOfferLinks(false)
    } else if (
      typeof offerLinksInfo === "object" &&
      !!offerLinksInfo &&
      offerLinksInfo.length === 0
    ) {
      setLoadingOfferLinks(false)
    }
  }, [offerLinksInfo])

  useEffect(() => {
    if (variantsLoaded) {
      setVariantsLoaded(false)
    }
  }, [variantsLoaded])

  useEffect(() => {
    if (autoLoadId) {
      const itm = { id: autoLoadId, navigate: true }
      goToOfferLinkForm(itm)
      setSelectedLink(itm)
    }
  }, [autoLoadId])

  useEffect(() => {
    if (linkDisplayInfo.length) {
      const obj = {}
      linkDisplayInfo?.forEach(itm => {
        const organizationName =
          itm.organizations.length > 1
            ? "Multi"
            : itm.organizations.length === 1
            ? itm.organizations[0].name
            : "None"
        const companyName =
          itm.companies.length > 1
            ? "Multi"
            : itm.companies.length === 1
            ? itm.companies[0].name
            : "None"
        if (!obj[organizationName]) {
          obj[organizationName] = {}
        }
        if (!obj[organizationName][companyName]) {
          obj[organizationName][companyName] = []
        }
        obj[organizationName][companyName].push(itm)
      })
      if (
        expanded &&
        expanded[0] === "OfferLinkBlocks" &&
        queryParams["linkid"] &&
        (linkInformation?.navigate ||
          Object.keys(linkDisplayObject).length !== linkDisplayInfo.length)
      ) {
        Object.keys(obj).forEach(k => {
          Object.keys(obj[k]).forEach(l => {
            obj[k][l].forEach(itm => {
              const linkid = queryParams["linkid"]
              //const variantid = queryParams["variantid"]
              if (itm.id === Number(linkid)) {
                const copyExpanded = ["OfferLinkBlocks"]
                copyExpanded.push(`${k}`)
                copyExpanded.push(`${k}-${l}`)
                copyExpanded.push(`${k}-${l}-${itm.id}`)
                setExpanded(copyExpanded)

                getVariantInfo(itm.id, obj)
              }
            })
          })
        })
      }
      setLinkDisplayObject(obj)
    }
  }, [linkDisplayInfo])

  useEffect(() => {
    const linkId = Number(queryParams["linkid"])
    const variantId = Number(queryParams["variantid"])
    if (linkId && linkId !== selectedLink.id) {
      if (variantId) {
        let theRest = ""
        if (selectedLink?.variants) {
          //This is true when navigating
          selectedLink?.variants?.forEach(x => {
            if (x.id === variantId) {
              theRest = `&variantid=${variantId}`
            }
          })
          history.push(
            `/portal/manage-ctas/offer?linkid=${
              selectedLink?.id ? selectedLink.id : linkId
            }${theRest}`
          )
        } else {
          //This would be a reload from cut and pasted URL
          history.push(
            `/portal/manage-ctas/offer?linkid=${
              selectedLink?.id ? selectedLink.id : linkId
            }&variantid=${variantId}`
          )
        }
      } else {
        history.push(`/portal/manage-ctas/offer?linkid=${selectedLink.id}`)
      }
    } else if (!linkId) {
      if (linkInformation?.id) {
        history.push(`/portal/manage-ctas/offer?linkid=${linkInformation.id}`)
      }
    }
  }, [selectedLink])

  useEffect(() => {
    if (linkInformation.navigate) {
      setExpanded(["OfferLinkBlocks"])
    } else if (linkInformation?.new || linkInformation?.isNewRightClickLink) {
      history.push(`/portal/manage-ctas/offer`)
    }
  }, [linkInformation])

  const loadedLinkId = Number(queryParams["linkid"])
  const loadedVariantId = Number(queryParams["variantid"])

  const TreeContextMenuOrgCompany = useCallback(
    () => (
      <Menu
        className={classes.offerMenu}
        anchorEl={contextAddLinkMenuAnchor}
        open={Boolean(contextAddLinkMenuAnchor)}
        onClose={handleContextMenuAddLinkClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <MenuItem onClick={handleAddLinkClick}>Add Link</MenuItem>
      </Menu>
    ),
    [contextAddLinkMenuAnchor]
  )

  const TreeContextMenuLink = useCallback(
    () => (
      <Menu
        className={classes.offerMenu}
        anchorEl={contextLinkMenuAnchor}
        open={Boolean(contextLinkMenuAnchor)}
        onClose={handleContextMenuLinkClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <MenuItem onClick={handleAddVariantClick}>Add CTA Variant</MenuItem>
        <Link
          to={`/portal/manage-ctas/offer?linkid=${linkMenuInfo?.id}`}
          target="_blank"
          style={{ color: "rgba(0,0,0,.87)" }}
        >
          <MenuItem>
            <IconButton
              className={classes.iconButton2}
              aria-label={"Open in New window"}
              size="large"
            >
              <Launch />
            </IconButton>
            Open in New window
          </MenuItem>
        </Link>

        <MenuItem
          onClick={() => {
            handleLinkRightClick("Duplicate")
          }}
        >
          Duplicate
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleLinkRightClick("Archive")
          }}
        >
          Archive
        </MenuItem>
        {(actionType === "Copy:Variant:Pending" ||
          actionType === "Cut:Variant:Pending") && (
          <MenuItem
            onClick={() => {
              handleLinkRightClick("Paste")
            }}
          >
            Paste
          </MenuItem>
        )}
      </Menu>
    ),
    [contextLinkMenuAnchor]
  )
  const TreeContextMenuVariant = useCallback(
    () => (
      <Menu
        className={classes.offerMenu}
        anchorEl={contextVariantMenuAnchor}
        open={Boolean(contextVariantMenuAnchor)}
        onClose={handleContextMenuVariantClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <Link
          to={`/portal/manage-ctas/offer?linkid=${variantMenuInfo?.linkId}&variantid=${variantMenuInfo?.id}`}
          target="_blank"
          style={{ color: "rgba(0,0,0,.87)" }}
        >
          <MenuItem>
            <IconButton
              className={classes.iconButton2}
              aria-label={"Open in New window"}
              size="large"
            >
              <Launch />
            </IconButton>
            Open in New window
          </MenuItem>
        </Link>
        <MenuItem
          onClick={() => {
            handleVariantRightClick("Copy")
          }}
        >
          Copy
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleVariantRightClick("Cut")
          }}
        >
          Cut
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleVariantRightClick("Duplicate")
          }}
        >
          Duplicate
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleVariantRightClick("Archive")
          }}
        >
          Archive
        </MenuItem>
      </Menu>
    ),
    [contextVariantMenuAnchor]
  )

  return (
    <>
      {isExecutingActions && <LinearProgress />}
      {loadingOfferLinks && (
        <div className="circular">
          <CircularProgress />
        </div>
      )}
      {linkDisplayObject && Object.keys(linkDisplayObject).length > 0 && (
        <TreeView
          aria-label="CTAv2 OfferLink Navigation"
          key="CTAv2OfferLinkNavigation"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          sx={{
            flexGrow: 1,
            overflowY: "auto"
          }}
          expanded={expanded}
        >
          <TreeItem
            nodeId="OfferLinkBlocks"
            label="Offer Link Blocks"
            key="OfferLinkBlocks"
            onClick={() => {
              const eexpanded = [...expanded]
              if (eexpanded.includes(`OfferLinkBlocks`)) {
                setExpanded([])
              } else {
                setExpanded(["OfferLinkBlocks"])
              }
            }}
          >
            {linkDisplayObject &&
              Object.keys(Object.fromEntries(Object.entries(linkDisplayObject)))
                .sort(sortByObjKey)
                .map(orgKey => (
                  <div
                    key={`${orgKey}`}
                    id={`${orgKey}`}
                    onContextMenu={event =>
                      handleAddLinkMenuOpen(event, { orgKey })
                    }
                  >
                    <TreeItem
                      key={`element-${orgKey}`}
                      nodeId={orgKey}
                      label={`${orgKey}`}
                      title={`${orgKey}`}
                      onClick={() => {
                        const eexpanded = [...expanded]
                        if (eexpanded.includes(`${orgKey}`)) {
                          const eexpanded2 = eexpanded.filter(
                            x => x !== `${orgKey}`
                          )
                          setExpanded(eexpanded2)
                        } else {
                          eexpanded.push(`${orgKey}`)
                          setExpanded(eexpanded)
                        }
                      }}
                    >
                      {Object.keys(
                        Object.fromEntries(
                          Object.entries(linkDisplayObject[orgKey]).sort()
                        )
                      ).map((companyKey, companyIndex) => (
                        <div
                          key={`${orgKey}-${companyKey}`}
                          id={`${orgKey}-${companyKey}`}
                          onContextMenu={event =>
                            handleAddLinkMenuOpen(event, { orgKey, companyKey })
                          }
                        >
                          <TreeItem
                            key={`element-${orgKey}-${companyKey}`}
                            nodeId={`${orgKey}-${companyKey}`}
                            label={`${companyKey}`}
                            title={`${companyKey}`}
                            onClick={() => {
                              const eexpanded = [...expanded]
                              if (
                                eexpanded.includes(`${orgKey}-${companyKey}`)
                              ) {
                                const eexpanded2 = eexpanded.filter(
                                  x => x !== `${orgKey}-${companyKey}`
                                )
                                setExpanded(eexpanded2)
                              } else {
                                eexpanded.push(`${orgKey}-${companyKey}`)
                                setExpanded(eexpanded)
                              }
                            }}
                          >
                            <>
                              {linkDisplayObject[orgKey][companyKey]
                                .sort(sortByFullLinkName)
                                .map((itm, i) => (
                                  <div
                                    key={`${orgKey}-${companyKey}`}
                                    id={`${orgKey}-${companyKey}`}
                                    onContextMenu={event =>
                                      handleLinkMenuOpen(event, itm)
                                    }
                                  >
                                    <TreeItem
                                      title={`${orgKey}-${companyKey}-${itm.id}`}
                                      nodeId={`${orgKey}-${companyKey}-${itm.id}`}
                                      key={`${orgKey}-${companyKey}-${itm.id}`}
                                      id={`${orgKey}-${companyKey}-${itm.id}`}
                                      label={
                                        <div
                                          className={classes.flexmiddle}
                                          style={
                                            !loadedVariantId &&
                                            loadedLinkId &&
                                            loadedLinkId === itm.id
                                              ? { backgroundColor: "#DDEAEF" }
                                              : { backgroundColor: "#fff" }
                                          }
                                        >
                                          <div className={classes.flex}>
                                            <Box
                                              className={classes.labelDetail}
                                            >
                                              {`${orgKey}-${companyKey}-${itm.shortNameOffer}`}
                                            </Box>
                                          </div>
                                        </div>
                                      }
                                      onClick={event => {
                                        goToOfferLinkForm(itm)
                                        setSelectedLink(itm)
                                        if (!variantAlreadyFetched(itm.id)) {
                                          getVariantInfo(itm.id)
                                        }
                                        history.push(
                                          `/portal/manage-ctas/offer?linkid=${itm.id}`
                                        )
                                        const eexpanded = [...expanded]
                                        if (
                                          eexpanded.includes(
                                            `${orgKey}-${companyKey}-${itm.id}`
                                          )
                                        ) {
                                          const eexpanded2 = eexpanded.filter(
                                            x =>
                                              x !==
                                              `${orgKey}-${companyKey}-${itm.id}`
                                          )
                                          setExpanded(eexpanded2)
                                        } else {
                                          eexpanded.push(
                                            `${orgKey}-${companyKey}-${itm.id}`
                                          )
                                          setExpanded(eexpanded)
                                        }
                                      }}
                                    >
                                      {itm.variants
                                        ?.slice()
                                        .sort(sortByFullVariantName)
                                        .map((vrn, j) => (
                                          <div
                                            key={`${orgKey}-${companyKey}-link${itm.id}-${vrn.id}-${j}`}
                                            id={`${orgKey}-${companyKey}-link${itm.id}-${vrn.id}-${j}`}
                                            onContextMenu={event =>
                                              handleVariantMenuOpen(event, vrn)
                                            }
                                            style={
                                              loadedVariantId &&
                                              loadedVariantId === vrn.id
                                                ? { backgroundColor: "#DDEAEF" }
                                                : { backgroundColor: "#fff" }
                                            }
                                          >
                                            <TreeItem
                                              title={`${getFullVariantName(
                                                vrn
                                              )}-#${vrn.id}`}
                                              nodeId={`${orgKey}-${companyKey}-link${itm.id}-${vrn.id}-${j}`}
                                              key={`${orgKey}-${companyKey}-link${itm.id}-${vrn.id}-${j}`}
                                              label={getFullVariantName(vrn)}
                                              onClick={() => {
                                                handleVariantClick(
                                                  vrn.id,
                                                  itm.id
                                                )
                                                props.setShowOfferLinkForm(
                                                  false
                                                )
                                              }}
                                            />
                                          </div>
                                        ))}
                                      {!itm.variants && !loadingVariants && (
                                        <TreeItem
                                          nodeId={`${orgKey}-${companyKey}-link${itm.id}-empty-placeholder}`}
                                          key={`${orgKey}-${companyKey}-link${itm.id}-empty-placeholder}`}
                                          label=""
                                        />
                                      )}
                                      {!itm.variants && loadingVariants && (
                                        <LinearProgress />
                                      )}
                                    </TreeItem>
                                  </div>
                                ))}
                            </>
                          </TreeItem>
                        </div>
                      ))}
                    </TreeItem>
                  </div>
                ))}
          </TreeItem>
        </TreeView>
      )}
      <TreeContextMenuOrgCompany aria-label="CTAv2 Offer Add Link Context Menu" />
      <TreeContextMenuLink aria-label="CTAv2 Offer Link Context Menu" />
      <TreeContextMenuVariant aria-label="CTAv2 Offer Variant Context Menu" />
    </>
  )
}
OfferLinkBlocks.propTypes = {
  goToEditor: PropTypes.func,
  goToOfferLinkForm: PropTypes.func,
  autoLoadId: PropTypes.number,
  linkInformation: PropTypes.object,
  setOpenAddVariantForm: PropTypes.func,
  setShowOfferLinkForm: PropTypes.func,
  cycleAddVariantForm: PropTypes.func,
  setShowVariant: PropTypes.func,
  openAddVariantForm: PropTypes.bool
}

export default OfferLinkBlocks
