import React from "react"
import ReactTable from "react-table-6"
import PropTypes from "prop-types"
import ReactJson from "react-json-view"
import { Tab, Tabs, Box } from "@mui/material"
import { Link } from "react-router-dom"
import Accordion from "components/Accordion/Accordion"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardIcon from "components/Card/CardIcon.jsx"
import StorageIcon from "@mui/icons-material/Storage"
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle"
import withStyles from "@mui/styles/withStyles"
import { TabPanel } from "components/TabPanel/TabPanel"

class NarrativeModelTriggersSnaphot extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
    const currentView = 0
    let defaultTrigger = {
      id: 0,
      name: "None Found",
      key: "",
      isDependency: false,
      rank: 0,
      members: [],
      hasErrors: false,
      contextError: "",
      renderString: ""
    }
    this.state = {
      triggers: props.triggers,
      selectedTrigger: defaultTrigger,
      currentView
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.triggers !== nextProps.triggers) {
      this.setState({ triggers: nextProps.triggers })
    }
  }

  selectTrigger(trigger) {
    this.setState({
      selectedTrigger: trigger
    })
    this.showTriggerDetailsTab()
  }

  showTriggerDetailsTab = () => {
    this.setState({ currentView: 1 })
  }

  render() {
    if (!this.state.triggers) {
      return null
    }
    const { triggers, currentView } = this.state
    const { classes, showUpdatedColumn } = this.props
    const selectedTrigger = this.state.selectedTrigger
    const handleChange = (event, newValue) => {
      this.setState({
        currentView: newValue
      })
    }
    let pageSize = this.props.pageSize || 25
    if (triggers && triggers.length + 1 < pageSize) {
      pageSize = triggers.length + 1
    }

    return (
      <Card className={this.props.embedded ? classes.embedded : ""}>
        {!this.props.embedded && (
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <StorageIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Dynamic Triggers</h4>
          </CardHeader>
        )}
        <CardBody>
          <Tabs
            value={currentView}
            onChange={handleChange}
            aria-label="Trigger Tabs"
          >
            <Tab label="Evaluated Triggers" />
            <Tab label="Trigger Details" />
          </Tabs>
          <div>
            <TabPanel value={currentView} index={0}>
              <ReactTable
                noDataText={"No Triggers Were Evaluated."}
                getTrProps={(state, rowInfo) => ({
                  onClick: () => {
                    this.selectTrigger(rowInfo.original)
                  }
                })}
                data={triggers}
                columns={[
                  {
                    Header: "Key",
                    accessor: "key",
                    Cell: cell => <div>{cell.value}</div>
                  },
                  {
                    Header: "Rank",
                    accessor: "rank",
                    width: 50
                  },
                  {
                    Header: "Dep",
                    accessor: "isDependency",
                    Cell: ({ row }) => (
                      <input
                        type="checkbox"
                        className="checkbox"
                        style={{ marginLeft: "6px", marginTop: "3px" }}
                        checked={row._original.isDependency}
                      />
                    ),
                    width: 50
                  },
                  {
                    Header: "T/F",
                    accessor: "isTrue",
                    Cell: ({ row }) => (
                      <input
                        type="checkbox"
                        className="checkbox"
                        style={{ marginLeft: "6px", marginTop: "3px" }}
                        checked={row._original.isTrue}
                      />
                    ),
                    width: 40
                  },
                  {
                    Header: "Ms",
                    accessor: "elapsedTimeMilliseconds",
                    width: 80
                  },
                  {
                    Header: "Errors",
                    accessor: "hasErrors",
                    Cell: ({ row }) => (
                      <input
                        type="checkbox"
                        className="checkbox"
                        style={{ marginLeft: "6px", marginTop: "3px" }}
                        checked={row._original.hasErrors}
                      />
                    ),
                    width: 80
                  },
                  {
                    Header: "Updated",
                    accessor: "updatedMetadata",
                    show: showUpdatedColumn,
                    Cell: ({ row }) => (
                      <input
                        type="checkbox"
                        className="checkbox"
                        style={{ marginLeft: "6px", marginTop: "3px" }}
                        checked={row._original.updatedMetadata}
                      />
                    ),
                    width: 80
                  }
                ]}
                filterable={true}
                defaultPageSize={this.props.pageSize}
                showPaginationTop={true}
                showPaginationBottom={false}
                className="-striped -highlight"
              />
            </TabPanel>
            <TabPanel value={currentView} index={1}>
              {selectedTrigger ? (
                <div>
                  <h4>
                    {selectedTrigger.name} -{" "}
                    {selectedTrigger.isDependency ? "Dependency" : "Trigger"}
                  </h4>
                </div>
              ) : (
                <div>
                  <h6>No Trigger Found</h6>
                </div>
              )}
              <Accordion
                active={0}
                collapses={[
                  {
                    title: "Details",
                    content: (
                      <Box style={{ margin: "0 18px 18px" }}>
                        <h4>
                          <Link
                            to={`/portal/narrative/${this.props.narrativeId}/edit?view=triggerid-${selectedTrigger.id}`}
                            onClick={() =>
                              this.props.setActiveView(
                                `triggerid-${selectedTrigger.id}`
                              )
                            }
                            title="View Trigger"
                          >
                            View Trigger
                          </Link>
                        </h4>
                        <GridContainer>
                          <GridItem xs={2}>
                            <h6>Id</h6>
                            <div>{selectedTrigger.id}</div>
                          </GridItem>
                          <GridItem xs={4}>
                            <h6>Rank</h6>
                            <div>{selectedTrigger.rank}</div>
                          </GridItem>
                          <GridItem xs={6}>
                            <h6>Execution Time</h6>
                            <div>
                              {selectedTrigger.elapsedTimeMilliseconds} ms.
                            </div>
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={6}>
                            <h6>Key</h6>
                            <div>{selectedTrigger.key}</div>
                          </GridItem>
                          <GridItem xs={6}>
                            <h6>Name</h6>
                            <div>{selectedTrigger.name}</div>
                          </GridItem>
                        </GridContainer>
                      </Box>
                    )
                  },
                  {
                    title: "Tokens",
                    content: (
                      <Box style={{ margin: "0 18px 18px" }}>
                        <ReactJson
                          enableClipboard={true}
                          displayObjectSize={false}
                          displayDataTypes={false}
                          src={selectedTrigger.tokens}
                          collapsed={false}
                        />
                      </Box>
                    )
                  },
                  {
                    title: "Scriban",
                    content: (
                      <Box style={{ margin: "0 18px 18px" }}>
                        <div>{selectedTrigger.formula}</div>
                      </Box>
                    )
                  },
                  {
                    title: "Output",
                    content: (
                      <Box style={{ margin: "0 18px 18px" }}>
                        {selectedTrigger.renderString}
                      </Box>
                    )
                  },
                  {
                    title: "Errors",
                    content: (
                      <Box style={{ margin: "0 18px 18px" }}>
                        <GridContainer>
                          <GridItem xs={12} sm={12}>
                            <h6>Context Errors</h6>
                            <div>{selectedTrigger.contextError}</div>
                          </GridItem>
                        </GridContainer>
                      </Box>
                    )
                  }
                ]}
              />
            </TabPanel>
          </div>
        </CardBody>
      </Card>
    )
  }
}

NarrativeModelTriggersSnaphot.propTypes = {
  // Where the user to be redirected on clicking the avatar
  onChange: PropTypes.func,
  triggers: PropTypes.arrayOf(PropTypes.object),
  selectedSnapshotId: PropTypes.number,
  pageSize: PropTypes.number
}

export default withStyles(regularFormsStyle)(NarrativeModelTriggersSnaphot)
