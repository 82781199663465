import React from "react"
import PropTypes from "prop-types"

function ReplicaKillswitch(props) {
  return (
    <svg
      id="replica_killswitch"
      xmlns="http://www.w3.org/2000/svg"
      height={props.height || "20px"}
      width={props.width || "20px"}
      viewBox="0 0 24 24"
      style={{ marginLeft: "-7px" }}
    >
      <path
        d="M10.73,26.67c-.61,0-1.17-.14-1.7-.41s-.96-.66-1.3-1.17L0,13.79l.73-.7c.38-.38.84-.61,1.36-.67.53-.06,1,.06,1.42.36l3.52,2.45V3.64c0-.34.12-.63.35-.86s.52-.35.86-.35.63.12.86.35.35.52.35.86v9.7h2.42V1.21c0-.34.12-.63.35-.86s.52-.35.86-.35.63.12.86.35c.23.23.35.52.35.86v12.12h2.42V2.42c0-.34.12-.63.35-.86s.52-.35.86-.35.63.12.86.35.35.52.35.86v10.91h2.42V4.85c0-.34.12-.63.35-.86s.52-.35.86-.35.63.12.86.35.35.52.35.86v16.97c0,1.33-.47,2.47-1.42,3.42-.95.95-2.09,1.42-3.42,1.42h-8.42Z"
        fill={props.fillColor || "#666"}
        strokeWidth="0"
      />
      <path
        d="M10.76,27.24c-.12,0-.16-.04-.16-.16v-12.4c0-.1.02-.17.14-.17,1.53-.04,2.35-.06,3.84-.06,3.1,0,4.93,1.34,4.93,3.82,0,1.69-1.09,2.89-2.5,3.42.64.89,2.97,4.36,3.53,5.33.06.12.08.21-.04.21h-2.43c-.16,0-.21-.04-.27-.1-.56-.87-1.92-3.1-2.77-4.46-.14-.23-.23-.33-.45-.33h-1.67v4.73c0,.12-.06.16-.16.16h-2ZM14.6,20.36c1.57,0,2.58-.7,2.58-1.94s-1.03-1.92-2.68-1.92c-.66,0-1.28.02-1.59.04v3.82h1.69Z"
        fill="#fff"
        strokeWidth="0"
      />
    </svg>
  )
}
ReplicaKillswitch.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fillColor: PropTypes.string
}
export default ReplicaKillswitch
