import React, { Component } from "react"
import Button from "components/CustomButtons/Button"
import ReactTable from "react-table-6"
import { CSVLink } from "react-csv"
import { Tab, Tabs, Box } from "@mui/material"
import Danger from "components/Typography/Danger.jsx"
import Datetime from "react-datetime"
import Accordion from "components/Accordion/Accordion"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardIcon from "components/Card/CardIcon.jsx"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle"
import withStyles from "@mui/styles/withStyles"
import { Link } from "react-router-dom"
import matchSorter from "match-sorter"
import AuditInfo from "components/AuditInfo"
import CronBuilder from "react-cron-builder"
import Autocomplete from "@mui/material/Autocomplete"

import Tooltip from "@mui/material/Tooltip"
import {
  Add,
  Edit,
  Publish,
  Share,
  Save,
  PlayArrow,
  Check,
  Storage,
  Build,
  Help,
  Loop
} from "@mui/icons-material"
import {
  FormControl,
  Modal,
  InputLabel,
  Select,
  MenuItem,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Dialog,
  CircularProgress,
  TextField,
  Checkbox
} from "@mui/material"

import TimeAgo from "react-timeago"
import Helpers from "tools/Helpers.js"
import { TabPanel } from "components/TabPanel/TabPanel"
import { globalSettings } from "variables/general.jsx"
import { Cookies } from "tools/storage"
import { get, put } from "tools/request"

class ReportBuilder extends Component {
  constructor(props) {
    super(props)
    const cookies = new Cookies()
    const showArchived = cookies.get("reportBuilderShowArchived")
      ? cookies.get("reportBuilderShowArchived")
      : false
    const currentReportId =
      props.selectedReportId || (props.match && props.match.params.reportId)
    const currentView = currentReportId ? 1 : 0
    this.state = {
      currentReportId,
      reportName: "",
      reportDescription: "",
      reportIsActive: false,
      reportIsArchived: false,
      includeArchived: showArchived,
      queryName: "",
      queryDescription: "",
      queryIsActive: false,
      allReportQueries: [],
      reportDependencies: [],
      timeTypes: [],
      currentView,
      reportTypes: [],
      selectedReportTypeId: 0,
      reportDependencyId: 0,
      reportTypeName: "",
      allReports: [],
      reportQuery: "select * from organization;",
      reportQueryId: 0,
      reportQueryBuilderId: 0,
      reportInputId: 0,
      reportIsRunning: false,
      allReportsLoading: false,
      isReportSaved: false,
      isReportDataDirty: false,
      pageSize: 25,
      tableFilter: [],
      reportsPageCount: 50,
      reportsTotalCount: 0,
      reportResultCode: 0,
      reportResultMessage: "",
      reportResults: {},
      reactTableResults: [],
      dynamicColumns: [],
      inputTokens: [{ tokenName: "", tokenValue: "" }],
      tableHistoryFilter: [],
      allHistoryLoading: false,
      currentReportHistoryId: 0,
      currentReportName: "",
      allReportHistory: [],
      showReportDetails: true,
      showReportResults: true,
      showReportInput: false,
      useSchedule: false,
      reportScheduleId: 0,
      reportEndDateTime: null,
      maxFutureOccurrenceCount: 10,
      cronSyntax: "0 */1 * * *", // we don't allow a repeat every minute in this cron syntax.  This is use to indicate a "Run Once" schedule
      cronTimeZoneIANA: "America/Los_Angeles",
      sendSlack: false,
      slackChannel: "",
      showTimeBack: false,
      timeBack: 0,
      timeType: "",
      selectedReportType: { value: 0, display: "Create New Report Type" },
      selectedReportDependency: { value: 0, display: "None" },
      selectedReportQueryLookUp: { value: 0, display: "Create New Query" },
      isCronBuilderOpen: false
    }
    this.createReport = this.createReport.bind(this)
    this.setInputTokens = this.setInputTokens.bind(this)
    this.loadSingleReport = this.loadSingleReport.bind(this)
  }

  componentDidMount() {
    this.loadReportQueries()
    this.loadReportTypes()
    this.loadReportDependencies()
    this.loadTimeTypes()

    this.loadAllReports(this.state.reportsPageCount)

    let propReportId =
      this.props.selectedReportId ||
      (this.props.match && this.props.match.params.reportId)
    if (propReportId) {
      this.loadSingleReport(propReportId)
    }
  }

  componentDidUpdate() {
    let propReportId =
      this.props.selectedReportId ||
      (this.props.match && this.props.match.params.reportId)
    if (
      !this.state.isCreateMode &&
      propReportId &&
      this.state.currentReportId !== propReportId
    ) {
      this.setState({
        currentReportId: propReportId,
        currentView: propReportId ? 1 : 0,
        tableFilter: [
          {
            id: "id",
            value: String(propReportId)
          }
        ]
      })
    }
  }

  isThisNewActionRequest(id) {
    if (
      !this.state.isCreateMode &&
      id &&
      Number(this.state.currentReportId) !== Number(id)
    ) {
      this.loadSingleReport(id)
    }
  }

  runReport = () => {
    this.setState({ reportIsRunning: true, reactTableResults: [] })
    this.mapResultsColumns({})
    put(`${globalSettings.apiBaseUrl}/api/reportbuilder/runreport`, null, {
      id: this.state.currentReportId,
      name: this.state.reportName,
      description: this.state.reportDescription,
      isActive: this.state.reportIsActive,
      isArchived: this.state.reportIsArchived,
      reportDependencyId: this.state.reportDependencyId,
      timeBack: this.state.timeBack,
      timeType: this.state.timeType,
      queryName: this.state.queryName,
      queryDescription: this.state.queryDescription,
      queryIsActive: this.state.queryIsActive,
      reportQueryId: this.state.reportQueryId,
      reportQuerySQL: this.state.reportQuery,
      inputTokens: this.state.inputTokens
    })
      .then(response => response.json())
      .then(data => {
        this.setState({
          reportResultCode: data.responseCode,
          reportResultMessage: data.responseMessage
        })
        if (data.content.reportResults !== null) {
          this.setState({
            reactTableResults: data.content.reportResults.records
          })
          this.mapResultsColumns(data.content.reportResults.columns)
        } else {
          this.setState({ reactTableResults: [] })
          this.mapResultsColumns({})
        }
        this.setState({ reportIsRunning: false })
        this.loadReportHistory(this.state.currentReportId)
      })
  }

  saveReport = () => {
    put(`${globalSettings.apiBaseUrl}/api/reportbuilder/savereport`, null, {
      id: this.state.currentReportId,
      name: this.state.reportName,
      description: this.state.reportDescription,
      isActive: this.state.reportIsActive,
      isArchived: this.state.reportIsArchived,
      reportTypeId: this.state.selectedReportTypeId,
      reportTypeName: this.state.reportTypeName,
      reportScheduleId: this.state.reportScheduleId,
      scheduleEndDate: this.state.reportEndDateTime,
      cronSyntax: this.state.cronSyntax,
      useSchedule: this.state.useSchedule,
      reportDependencyId: this.state.reportDependencyId,
      timeBack: this.state.timeBack,
      timeType: this.state.timeType,
      reportInputId: this.state.reportInputId,
      inputTokens: this.state.inputTokens,
      queryName: this.state.queryName,
      queryDescription: this.state.queryDescription,
      queryIsActive: this.state.queryIsActive,
      reportQueryId: this.state.reportQueryId,
      reportQuerySQL: this.state.reportQuery,
      ReportQueryBuilderId: this.state.reportQueryBuilderId,
      sendSlack: this.state.sendSlack,
      slackChannel: this.state.slackChannel
    })
      .then(response => response.json())
      .then(data => {
        this.loadAllReports(this.state.reportsPageCount)
        this.loadReportQueries()
        if (this.state.isCreateMode) {
          this.setState({ currentView: 0 })
        }
        this.setState({
          isReportSaved: true,
          isReportDataDirty: false,
          isCreateMode: false,
          currentReportId: data.content.id,
          tableFilter: [
            {
              id: "id",
              value: String(data.content.id)
            }
          ]
        })
        this.props.history.push(`/portal/report-builder/${data.content.id}`)
      })
  }

  mapResultsColumns = reportResultsColumns => {
    const columns = Object.keys(reportResultsColumns).map(key => ({
      Header: reportResultsColumns[key],
      accessor: reportResultsColumns[key],
      Cell: cell => cell.value?.toString()
    }))
    this.setState({ dynamicColumns: columns })
  }

  executeRunReport = () => {
    this.runReport()
  }

  executeSaveReport = () => {
    if (this.state.reportName === "") {
      alert("Please Provide a Report Name")
    }
    if (this.state.reportQuery === "" && this.state.reportQueryId === 0) {
      alert("Please select a Query")
    }
    if (this.state.reportTypeName === "") {
      alert("Please Provide a Report Type")
    } else {
      this.saveReport()
    }
  }

  onTextAreaChange = e => {
    this.setState({ reportQuery: e.target.value, isReportDataDirty: true })
  }

  onReportQueryChange = event => {
    this.setState({
      reportQueryId: event.target.value
    })

    this.loadQueryInfo(event.target.value)
  }

  onActiveBoxChecked = event => {
    let isChecked = event.target.checked
    this.setState({
      reportIsActive: isChecked,
      isReportDataDirty: true
    })
  }

  onArchivedBoxChecked = event => {
    let isChecked = event.target.checked
    this.setState({
      reportIsArchived: isChecked,
      isReportDataDirty: true
    })
  }

  onIncludeArchivedBoxChecked = event => {
    let isChecked = event.target.checked
    this.setState({
      includeArchived: isChecked,
      isReportDataDirty: true
    })
    const cookies = new Cookies()
    cookies.set("reportBuilderShowArchived", isChecked)
  }

  onSlackBoxChecked = event => {
    let isChecked = event.target.checked
    this.setState({
      sendSlack: isChecked,
      isReportDataDirty: true
    })
  }

  onQueryActiveBoxChecked = event => {
    let isChecked = event.target.checked
    this.setState({
      queryIsActive: isChecked,
      isReportDataDirty: true
    })
  }

  setInputTokens(values) {
    this.setState({
      inputTokens: values
    })
  }

  handleInputChange = (index, event) => {
    const values = this.state.inputTokens
    if (event.target.name === "tokenName") {
      values[index].tokenName = event.target.value
    } else {
      values[index].tokenValue = event.target.value
    }

    this.setInputTokens(values)
  }

  handleAddFields = () => {
    const values = this.state.inputTokens
    values.push({ tokenName: "", tokenValue: "" })
    this.setInputTokens(values)
  }

  handleRemoveFields = index => {
    const values = this.state.inputTokens
    if (values.length > 1) {
      values.splice(index, 1)
      this.setInputTokens(values)
    }
  }

  createReport() {
    window.history.pushState(null, null, "/portal/report-builder/0")
    this.setState({
      currentReportId: 0,
      reportName: "",
      reportDescription: "",
      reportIsActive: false,
      reportIsArchived: false,
      reportTypeName: "",
      selectedReportTypeId: 0,
      reportDependencyId: 0,
      inputTokens: [{ tokenName: "", tokenValue: "" }],
      reportQueryId: 0,
      reportQueryBuilderId: 0,
      queryName: "",
      queryDescription: "",
      queryIsActive: false,
      reportQuery: "",
      currentView: 1,
      currentResultsView: 0,
      reportResults: {},
      reactTableResults: [],
      allReportHistory: [],
      isCreateMode: true,
      tableFilter: [],
      tableHistoryFilter: [],
      cronSyntax: "0 */1 * * *",
      useSchedule: false,
      reportEndDateTime: null,
      useSlack: false,
      slackChannel: "",
      showTimeBack: false,
      timeBack: "",
      selectedReportType: { value: 0, display: "Create New Report Type" },
      selectedReportDependency: { value: 0, display: "None" },
      selectedReportQueryLookUp: { value: 0, display: "Create New Query" }
    })
  }

  selectReport(reportId) {
    this.setState({
      id: "",
      name: ""
    })
    this.props.onChange && this.props.onChange(reportId)
  }

  loadQueryInfo = queryId => {
    this.setState({
      isReportDataDirty: true
    })
    if (queryId === 0) {
      this.setState({
        reportQueryId: queryId,
        reportQueryBuilderId: 0,
        queryName: "",
        queryDescription: "",
        queryIsActive: false,
        reportQuery: "",
        reactTableResults: []
      })
    } else {
      get(
        `${globalSettings.apiBaseUrl}/api/reportbuilder/retrievereportquery/${queryId}`
      )
        .then(response => response.json())
        .then(data => {
          this.setState({
            reportQueryId: data.content.id,
            queryName: data.content.name,
            queryDescription: data.content.description,
            queryIsActive: data.content.isActive,
            reportQuery: data.content.reportQuerySQL,
            reportQueryBuilderId: data.content.reportQueryBuilderId
          })

          if (data.content) {
            this.runReport()
          }
        })
    }
  }

  loadSingleReport = reportId => {
    if (!reportId) {
      return null
    }
    get(
      `${globalSettings.apiBaseUrl}/api/reportbuilder/retrievereport/${reportId}`
    )
      .then(response => response.json())
      .then(data => {
        if (data.responseCode === 1000 && data.content) {
          this.setState({
            currentReportId: data.content.id,
            reportName: data.content.name,
            reportDescription: data.content.description,
            reportIsActive: data.content.isActive,
            reportIsArchived: data.content.isArchived,
            selectedReportTypeId: data.content.reportTypeId,
            reportTypeName: data.content.reportTypeName,
            reportScheduleId: data.content.reportScheduleId,
            reportDependencyId: data.content.reportDependencyId,
            selectedReportType: {
              value: data.content.reportTypeId,
              display:
                data.content.reportTypeId > 0
                  ? data.content.reportTypeName
                  : "Create New Report Type"
            },
            selectedReportDependency: {
              value: data.content.reportDependencyId,
              display:
                data.content.reportDependencyId > 0
                  ? data.content.reportDependencyName
                  : "None"
            },
            selectedReportQueryLookUp: {
              value: data.content.reportQueryId,
              display:
                data.content.reportQueryId > 0
                  ? data.content.reportQueryName
                  : "Create New Query"
            },
            timeBack: data.content.timeBack,
            timeType: data.content.timeType,
            reportInputId: data.content.reportInputId,
            reportQueryId: data.content.reportQueryId,
            reportQueryBuilderId: data.content.reportQueryBuilderId,
            queryName: data.content.reportQueryName,
            queryDescription: data.content.reportQueryDescription,
            queryIsActive: data.content.reportQueryIsActive,
            reportQuery: data.content.reportQuery,
            sendSlack: data.content.sendSlack,
            slackChannel: data.content.slackChannel
          })
          if (data.content.reportScheduleId > 0) {
            this.setState({
              useSchedule: !data.content.isPaused,
              reportEndDateTime: new Date(data.content.scheduleEndDate),
              cronSyntax: data.content.cronSyntax
            })
            this.calculateReportSchedule()
          }

          let dependency = data.content.reportDependencyName
          let dependencyClause =
            "WHERE narrative_id in (DEPENDENCY_PLACEHOLDER)"
          let dependencyHelp = ""
          let showTimeBack = false

          if (dependency) {
            if (dependency.includes("Active_Narrative")) {
              dependencyHelp = "Narrative IDs for all ACTIVE narratives"
            } else if (dependency.includes("Active_Feed")) {
              dependencyHelp = "Narrative IDs for all ACTIVE feeds"
            } else if (dependency.includes("Recent_Narrative")) {
              showTimeBack = true
              dependencyHelp =
                "Narrative IDs for narratives with changes in last X time"
            } else if (dependency.includes("Recent_Feed")) {
              showTimeBack = true
              dependencyHelp =
                "Narrative IDs for feedentries modified in last X time"
            }

            this.setState({
              dependencyClause: dependencyClause,
              dependencyHelp: dependencyHelp,
              showTimeBack: showTimeBack
            })
          }

          if (data.content.inputValues) {
            this.setState({
              inputTokens: JSON.parse(data.content.inputValues)
            })
          }
          if (data.content) {
            this.runReport()
          }
          this.loadReportHistory(reportId)
        }
      })
  }

  loadReportQueries = () => {
    get(
      `${globalSettings.apiBaseUrl}/api/reportbuilder/retrieveallreportqueries`
    )
      .then(response => response.json())
      .then(data => {
        let namesFromApi = data.content.map(query => ({
          value: query.id,
          display: query.name
        }))
        // inline sort by bookmaker name
        namesFromApi.sort((a, b) => {
          // Use toUpperCase() to ignore character casing
          const bookA = a.display.toUpperCase()
          const bookB = b.display.toUpperCase()
          let comparison = 0
          if (bookA > bookB) {
            comparison = 1
          } else if (bookA < bookB) {
            comparison = -1
          }
          return comparison
        })
        this.setState({
          allReportQueries: [{ value: 0, display: "Create New Query" }].concat(
            namesFromApi
          )
        })
      })
  }

  loadReportTypes = () => {
    get(`${globalSettings.apiBaseUrl}/api/reportbuilder/retrievereporttypes`)
      .then(response => response.json())
      .then(data => {
        let namesFromApi = data.content.map(type => ({
          value: type.id,
          display: type.name
        }))
        // inline sort by bookmaker name
        namesFromApi.sort((a, b) => {
          // Use toUpperCase() to ignore character casing
          const bookA = a.display.toUpperCase()
          const bookB = b.display.toUpperCase()
          let comparison = 0
          if (bookA > bookB) {
            comparison = 1
          } else if (bookA < bookB) {
            comparison = -1
          }
          return comparison
        })
        this.setState({
          reportTypes: [{ value: 0, display: "Create New Report Type" }].concat(
            namesFromApi
          )
        })
      })
  }

  calculateReportSchedule() {
    const { useSchedule, maxFutureOccurrenceCount } = this.state
    let cronSyntax = this.state.cronSyntax
    let startDateTimeValue = new Date().toISOString()
    let endDateTimeValue = ""
    if (!this.state.useSchedule) {
      cronSyntax = ""
      endDateTimeValue = ""
    }
    get(
      `${globalSettings.apiBaseUrl}/api/reportbuilder/reportschedule?cronsyntax=${cronSyntax}
      &startdatetime=${startDateTimeValue}&usereportschedule=${useSchedule}
      &enddatetime=${endDateTimeValue}&maxFutureOccurrenceCount=${maxFutureOccurrenceCount}`
    )
      .then(Response => Response && Response.ok === true && Response.json())
      .then(result => {
        if (result && result.responseCode === 1000) {
          this.setState({
            futureOccurrences: result.content,
            futureOccurrencesMessage: ""
          })
        } else {
          this.setState({
            futureOccurrences: null,
            futureOccurrencesMessage: `There was a problem described as ${result.responseMessage}.`
          })
        }
      })
  }

  loadTimeTypes = () => {
    this.setState({
      timeTypes: [
        {
          value: "hour",
          display: "Hours"
        },
        {
          value: "minute",
          display: "Minutes"
        },
        {
          value: "day",
          display: "Days"
        }
      ]
    })
  }

  loadReportDependencies = () => {
    get(`${globalSettings.apiBaseUrl}/api/reportbuilder/loadreportdependencies`)
      .then(response => response.json())
      .then(data => {
        let namesFromApi = Object.entries(data.content).map(([key, value]) => ({
          value: key,
          display: value
        }))
        this.setState({
          reportDependencies: namesFromApi
        })
      })
  }

  onLoadReportButtonClicked = historyId => {
    if (!historyId) {
      return null
    }
    this.setState({
      currentReportHistoryId: historyId
    })
    get(
      `${globalSettings.apiBaseUrl}/api/reportbuilder/loadreporthistory/${historyId}`
    )
      .then(response => response.json())
      .then(data => {
        this.setState({
          currentReportName: data.content.reportName
        })
        if (data.content.reportResults !== null) {
          this.setState({
            reactTableResults: data.content.reportResults.records
          })
          this.mapResultsColumns(data.content.reportResults.columns)
        } else {
          this.setState({ reactTableResults: [] })
          this.mapResultsColumns({})
        }
        this.setState({ reportIsRunning: false })
      })
  }

  onShareButtonClicked = () => {
    alert("Share Button Clicked")
  }

  loadReportHistory = reportId => {
    if (!reportId) {
      return null
    }
    this.setState({ allHistoryLoading: true })
    get(
      `${globalSettings.apiBaseUrl}/api/reportbuilder/retrieveallreporthistory/${reportId}?pagecount=50`
    )
      .then(response => response.json())
      .then(data => {
        this.setState({
          allReportHistory: data.content,
          allHistoryLoading: false
        })
      })
  }

  loadAllReports = reportsPageCount => {
    this.getReportCount()
    this.setState({ allReportsLoading: true })
    get(
      `${globalSettings.apiBaseUrl}/api/reportbuilder/retrieveallreports?pagecount=${reportsPageCount}&includearchived=true`
    )
      .then(response => response.json())
      .then(data => {
        this.setState({ allReports: data.content, allReportsLoading: false })
      })
  }

  showReportDetails = () => {
    this.setState({ currentView: 1 })
  }

  onChangeReportsPageCount = event => {
    this.setState({ reportsPageCount: event.target.value })
    this.loadAllReports(event.target.value)
  }

  getReportCount() {
    get(`${globalSettings.apiBaseUrl}/api/reportbuilder/reportcount/`)
      .then(Response => Response.text())
      .then(text => {
        this.setState({
          reportsTotalCount: text
        })
      })
  }

  renderTotalCountOptions() {
    let steppingPoints = [25, 50, 75, 100, 150, 200, 250, 350, 400, 500]
    let reportsPageCountChoices = []
    steppingPoints.map(i => {
      if (i < this.state.reportsTotalCount) {
        reportsPageCountChoices.push(i)
      }
    })
    reportsPageCountChoices.push(this.state.reportsTotalCount)
    if (
      parseInt(this.state.reportsPageCount) >
      parseInt(this.state.reportsTotalCount)
    ) {
      this.setState({
        reportsPageCount: this.state.reportsTotalCount
      })
    }
    if (this.state.reportsTotalCount < 25) {
      return this.state.reportsTotalCount
    } else {
      return (
        <select
          onChange={this.onChangeReportsPageCount}
          style={{ width: "100" }}
          value={this.state.reportsPageCount}
        >
          {reportsPageCountChoices.map(o => (
            <option key={o} value={o}>
              {o}
            </option>
          ))}
        </select>
      )
    }
  }

  render() {
    const { classes } = this.props
    const { currentView, inputTokens } = this.state

    const reportDate = Datetime.moment().format("M/D/YY")

    const timeTypeItems = this.state.timeTypes.map(item => (
      <MenuItem
        key={item.value}
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected
        }}
        value={item.value}
      >
        {item.display}
      </MenuItem>
    ))

    const handleChange = (event, newValue) => {
      let currentV = this.state.currentView
      if (newValue !== currentV && newValue === 0) {
        this.setState({
          currentView: newValue,
          tableFilter: []
        })
      } else {
        this.setState({
          currentView: newValue
        })
      }
    }

    const stringFilterProps = {
      filterable: true,
      filterAll: true,
      filterMethod: ({ id, value }, rows) =>
        matchSorter(rows, value, {
          keys: [id],
          threshold: matchSorter.rankings.CONTAINS
        })
    }

    return (
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <Storage />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Report Builder</h4>
        </CardHeader>
        <CardBody>
          <Dialog
            open={this.state.isReportSaved}
            onClose={() => this.setState({ isReportSaved: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Report Saved</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Your report has been saved.
              </DialogContentText>
            </DialogContent>
          </Dialog>
          <Tabs
            value={currentView}
            onChange={handleChange}
            aria-label="ReportBuilder Tabs"
          >
            <Tab label="Reports" />
            <Tab label="Report Results" />
          </Tabs>
          <div>
            <TabPanel value={currentView} index={0}>
              <div style={{ display: "flex", paddingTop: "8px" }}>
                <Button
                  aria-label="reportBuilder addReportBtn addReport"
                  size="sm"
                  color="primary"
                  onClick={this.createReport}
                  startIcon={<Add />}
                >
                  Add Report
                </Button>
              </div>
              <ReactTable
                loading={this.state.allReportsLoading}
                data={
                  this.state.includeArchived
                    ? this.state.allReports
                    : this.state.allReports.filter(itm => !itm.isArchived)
                }
                noDataText={
                  this.state.allReportsLoading ? (
                    <CircularProgress />
                  ) : (
                    "No reports found."
                  )
                }
                filtered={this.state.tableFilter}
                onFilteredChange={filtered =>
                  this.setState({ tableFilter: filtered })
                }
                getTrProps={(state, rowInfo) => ({
                  style: {
                    background:
                      rowInfo &&
                      rowInfo.row &&
                      rowInfo.row.id === this.state.currentReportId
                        ? "yellow"
                        : null,
                    textDecoration:
                      rowInfo && rowInfo.original && rowInfo.original.isArchived
                        ? "line-through"
                        : "none"
                  }
                })}
                columns={[
                  {
                    Header: "Id",
                    accessor: "id",
                    Cell: cell => <div>{cell.value}</div>,
                    width: 60
                  },
                  {
                    Header: "Type",
                    accessor: "reportType",
                    Cell: cell => <div>{cell.value}</div>,
                    width: 200
                  },
                  {
                    Header: "Name",
                    accessor: "name",
                    show: true,
                    width: 200,
                    ...stringFilterProps
                  },
                  {
                    Header: "Actions",
                    Cell: cell => (
                      <div className="actions-right">
                        <Link
                          to={`/portal/report-builder/${cell.original.id}`}
                          title="Edit"
                          onClick={() => {
                            this.selectReport(cell.original.id)
                            this.showReportDetails()
                            this.isThisNewActionRequest(cell.original.id)
                          }}
                        >
                          <Button
                            justIcon
                            round
                            simple
                            color="info"
                            className="info"
                          >
                            <Edit />
                          </Button>
                        </Link>
                        <Button
                          title="Share"
                          justIcon
                          round
                          simple
                          color="info"
                          onClick={() => {
                            this.onShareButtonClicked(cell.original.token)
                          }}
                        >
                          <Share />
                        </Button>
                      </div>
                    ),
                    sortable: false,
                    filterable: false,
                    width: 100
                  },
                  {
                    Header: "Description",
                    accessor: "description",
                    sortable: false,
                    ...stringFilterProps,
                    Cell: cell => (
                      <div>
                        {cell.value && cell.value.length > 100
                          ? `${cell.value.substring(0, 100)}...`
                          : cell.value}
                      </div>
                    )
                  },
                  {
                    Header: "Audit Info",
                    id: "auditInfo",
                    Cell: ({ original: { auditInfoResponse } }) => (
                      <AuditInfo {...auditInfoResponse} />
                    ),
                    width: 250,
                    filterable: false
                  }
                ]}
                filterable={true}
                defaultPageSize={50}
                showPaginationTop={true}
                showPaginationBottom={false}
                className="-striped -highlight"
                defaultSorted={[
                  {
                    id: "auditInfo",
                    desc: false
                  }
                ]}
                defaultFilterMethod={(filter, row) => {
                  const id = filter.pivotId || filter.id
                  return row[id] !== undefined
                    ? String(row[id])
                        .toLowerCase()
                        .startsWith(filter.value.toLowerCase())
                    : true
                }}
              >
                {(state, makeTable) => {
                  let recordsInfoText = ""

                  const { filtered, pageRows, pageSize, sortedData, page } =
                    state

                  if (sortedData && sortedData.length > 0) {
                    let isFiltered = filtered.length > 0
                    let totalRecords = sortedData.length
                    let recordsCountFrom = page * pageSize + 1
                    let recordsCountTo = recordsCountFrom + pageRows.length - 1
                    if (isFiltered) {
                      recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered reports`
                    } else {
                      recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords} reports`
                    }
                  } else {
                    recordsInfoText = this.state.isPausedJobsLoading
                      ? "Loading reports, please wait..."
                      : "No reports found"
                  }
                  return (
                    <div className="main-grid">
                      <div className="above-table text-right">
                        <div className="col-sm-12">
                          <span
                            style={{
                              display: "inline-block",
                              marginRight: "20px",
                              fontSize: "1em"
                            }}
                          >
                            <Checkbox
                              checked={this.state.includeArchived}
                              onChange={this.onIncludeArchivedBoxChecked}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                            />{" "}
                            Include Archived?
                          </span>
                          <span className="records-info">
                            {recordsInfoText}
                          </span>
                          <Tooltip title="Reload" placement="top" arrow={true}>
                            <Button
                              justIcon
                              round
                              simple
                              color="primary"
                              className="info"
                              id="reload"
                              onClick={() => {
                                this.loadAllReports()
                              }}
                            >
                              {this.state.isPausedJobsLoading ? (
                                <CircularProgress thickness={1} size={30} />
                              ) : (
                                <Loop />
                              )}
                            </Button>
                          </Tooltip>{" "}
                        </div>
                      </div>
                      {makeTable()}
                    </div>
                  )
                }}
              </ReactTable>
            </TabPanel>
            <TabPanel value={currentView} index={1}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  size="sm"
                  color="primary"
                  onClick={this.createReport}
                  startIcon={<Add />}
                >
                  New Report
                </Button>
              </div>
              <Accordion
                active={this.state.showReportDetails ? 0 : null}
                collapses={[
                  {
                    title: "Report Details",
                    content: (
                      <React.Fragment>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <Card>
                              <CardBody>
                                <GridContainer>
                                  <GridItem xs={12} sm={4} md={4}>
                                    <div>
                                      <TextField
                                        id="name"
                                        fullWidth={true}
                                        multiline={true}
                                        placeholder="Enter name for your report"
                                        defaultValue={
                                          this.state.reportName || ""
                                        }
                                        onBlur={e =>
                                          this.setState({
                                            reportName: e.target.value,
                                            isReportDataDirty: true
                                          })
                                        }
                                        helperText="Give your report a name"
                                      />
                                    </div>
                                  </GridItem>
                                  <GridItem xs={12} sm={5} md={5}>
                                    <div>
                                      <TextField
                                        id="description"
                                        fullWidth={true}
                                        multiline={true}
                                        placeholder="Enter a description for your report"
                                        defaultValue={
                                          this.state.reportDescription || ""
                                        }
                                        onBlur={e =>
                                          this.setState({
                                            reportDescription: e.target.value,
                                            isReportDataDirty: true
                                          })
                                        }
                                        helperText="Give your report a description"
                                      />
                                    </div>
                                  </GridItem>
                                  <GridItem xs={12} sm={1} md={1}>
                                    <div>
                                      <Checkbox
                                        checked={this.state.reportIsActive}
                                        onChange={this.onActiveBoxChecked}
                                        classes={{
                                          checked: classes.checked,
                                          root: classes.checkRoot
                                        }}
                                        checkedIcon={
                                          <Check
                                            className={classes.checkedIcon}
                                          />
                                        }
                                        icon={
                                          <Check
                                            className={classes.uncheckedIcon}
                                          />
                                        }
                                      />{" "}
                                      Is Active?
                                    </div>
                                  </GridItem>
                                  <GridItem xs={12} sm={2} md={2}>
                                    <div>
                                      <Checkbox
                                        checked={this.state.reportIsArchived}
                                        onChange={this.onArchivedBoxChecked}
                                        classes={{
                                          checked: classes.checked,
                                          root: classes.checkRoot
                                        }}
                                        checkedIcon={
                                          <Check
                                            className={classes.checkedIcon}
                                          />
                                        }
                                        icon={
                                          <Check
                                            className={classes.uncheckedIcon}
                                          />
                                        }
                                      />{" "}
                                      Is Archived?
                                    </div>
                                  </GridItem>
                                  <GridItem xs={3} sm={3} md={3}>
                                    <div>
                                      <FormControl margin="normal" fullWidth>
                                        <Autocomplete
                                          id="reportType-select"
                                          name="reportTypeSelect"
                                          options={this.state.reportTypes}
                                          getOptionLabel={option =>
                                            option.display
                                          }
                                          value={this.state.selectedReportType}
                                          onChange={(event, newValue) => {
                                            if (newValue !== null) {
                                              this.setState({
                                                reportTypeName:
                                                  newValue && newValue.display,
                                                isReportDataDirty: true,
                                                selectedReportType: newValue,
                                                selectedReportTypeId:
                                                  newValue && newValue.value
                                              })
                                            }
                                          }}
                                          renderInput={params => (
                                            <TextField
                                              {...params}
                                              label="Report Type"
                                              margin="normal"
                                            />
                                          )}
                                        />
                                      </FormControl>
                                      {this.state.selectedReportTypeId ===
                                        0 && (
                                        <TextField
                                          id="reporttypetext"
                                          fullWidth={true}
                                          placeholder="Report Type Name"
                                          defaultValue={
                                            this.state.reportTypeName || ""
                                          }
                                          onChange={e =>
                                            this.setState({
                                              reportTypeName: e.target.value,
                                              isReportDataDirty: true
                                            })
                                          }
                                        />
                                      )}
                                    </div>
                                  </GridItem>
                                  <GridItem xs={3} sm={3} md={3}>
                                    <div>
                                      <FormControl margin="normal" fullWidth>
                                        <Autocomplete
                                          id="reportDependency-select"
                                          name="reportDependencySelect"
                                          options={
                                            this.state.reportDependencies
                                          }
                                          getOptionLabel={option =>
                                            option.display
                                          }
                                          value={
                                            this.state.selectedReportDependency
                                          }
                                          onChange={(event, newValue) => {
                                            if (newValue !== null) {
                                              let dependency = newValue

                                              let dependencyClause =
                                                "WHERE narrative_id in (DEPENDENCY_PLACEHOLDER)"
                                              let dependencyHelp = ""

                                              if (dependency) {
                                                if (
                                                  dependency.display.includes(
                                                    "Active_Narrative"
                                                  )
                                                ) {
                                                  dependencyHelp =
                                                    "Narrative IDs for all ACTIVE narratives"
                                                } else if (
                                                  dependency.display.includes(
                                                    "Active_Feed"
                                                  )
                                                ) {
                                                  dependencyHelp =
                                                    "Narrative IDs for all ACTIVE feeds"
                                                } else if (
                                                  dependency.display.includes(
                                                    "Recent_Narrative"
                                                  )
                                                ) {
                                                  dependencyHelp =
                                                    "Narrative IDs for narratives modified in last X time"
                                                } else if (
                                                  dependency.display.includes(
                                                    "Recent_Feed"
                                                  )
                                                ) {
                                                  dependencyHelp =
                                                    "Narrative IDs for feedentries modified in last X time"
                                                }

                                                if (
                                                  dependency.value === 3 ||
                                                  dependency.value === 4
                                                ) {
                                                  this.setState({
                                                    showTimeBack: true
                                                  })
                                                } else {
                                                  this.setState({
                                                    showTimeBack: false
                                                  })
                                                }
                                                this.setState({
                                                  reportDependencyId:
                                                    dependency.value,
                                                  selectedReportDependency:
                                                    dependency,
                                                  dependencyClause:
                                                    dependencyClause,
                                                  dependencyHelp: dependencyHelp
                                                })
                                              }
                                            }
                                          }}
                                          renderInput={params => (
                                            <TextField
                                              {...params}
                                              label="Report Dependency"
                                              margin="normal"
                                            />
                                          )}
                                        />
                                      </FormControl>
                                      {this.state.reportDependencyId !== 0 && (
                                        <span
                                          className={
                                            classes.cardIconDescription
                                          }
                                        >
                                          {this.state.dependencyHelp} <br />
                                          Please Include this Where Clause:{" "}
                                          <br />{" "}
                                          <b>{this.state.dependencyClause}</b>
                                        </span>
                                      )}
                                    </div>
                                  </GridItem>
                                  <GridItem>
                                    <div>
                                      {this.state.showTimeBack && (
                                        <div>
                                          <TextField
                                            id="timeBack"
                                            fullWidth={false}
                                            multiline={true}
                                            placeholder="Time Back"
                                            value={this.state.timeBack || ""}
                                            onChange={e =>
                                              this.setState({
                                                timeBack: e.target.value,
                                                isReportDataDirty: true
                                              })
                                            }
                                          />
                                          <FormControl
                                            margin="normal"
                                            fullWidth
                                          >
                                            <InputLabel>Time Type</InputLabel>
                                            <Select
                                              MenuProps={{
                                                className: classes.selectMenu
                                              }}
                                              classes={{
                                                select: classes.select
                                              }}
                                              value={this.state.timeType}
                                              onChange={e => {
                                                this.setState({
                                                  timeType: e.target.value
                                                })
                                              }}
                                              inputProps={{
                                                name: "timeTypeSelect",
                                                id: "timeType-select"
                                              }}
                                            >
                                              {timeTypeItems}
                                            </Select>
                                          </FormControl>
                                        </div>
                                      )}
                                    </div>
                                  </GridItem>
                                  <GridItem xs={3} sm={3} md={3}>
                                    <div>
                                      <Checkbox
                                        checked={this.state.sendSlack}
                                        onChange={this.onSlackBoxChecked}
                                        classes={{
                                          checked: classes.checked,
                                          root: classes.checkRoot
                                        }}
                                        checkedIcon={
                                          <Check
                                            className={classes.checkedIcon}
                                          />
                                        }
                                        icon={
                                          <Check
                                            className={classes.uncheckedIcon}
                                          />
                                        }
                                      />{" "}
                                      Send Slack?
                                    </div>
                                    {this.state.sendSlack && (
                                      <TextField
                                        id="slackChannel"
                                        fullWidth={true}
                                        placeholder="Slack Channel"
                                        value={this.state.slackChannel || ""}
                                        onChange={e =>
                                          this.setState({
                                            slackChannel: e.target.value,
                                            isReportDataDirty: true
                                          })
                                        }
                                      />
                                    )}
                                  </GridItem>
                                </GridContainer>
                              </CardBody>
                            </Card>
                            <Card>
                              <CardBody>
                                <GridContainer
                                  style={{
                                    marginTop: "10px",
                                    display: "relative"
                                  }}
                                >
                                  <GridItem sm={6}>
                                    <GridContainer>
                                      <GridItem md={12}>
                                        <Checkbox
                                          checked={
                                            this.state.useSchedule || false
                                          }
                                          onChange={e => {
                                            this.setState(
                                              {
                                                useSchedule: e.target.checked,
                                                isReportDataDirty: true
                                              },
                                              () =>
                                                this.calculateReportSchedule()
                                            )
                                          }}
                                        />{" "}
                                        Schedule Report?
                                      </GridItem>
                                      <GridItem md={12}>
                                        <TextField
                                          type="text"
                                          label="Cron Syntax"
                                          id="cronSyntax"
                                          value={this.state.cronSyntax}
                                          disabled={!this.state.useSchedule}
                                          onChange={e => {
                                            this.setState(
                                              {
                                                cronSyntax: e.target.value,
                                                isReportDataDirty: true
                                              },
                                              () =>
                                                this.calculateReportSchedule()
                                            )
                                          }}
                                        />
                                        <Tooltip
                                          title="Build cron syntax"
                                          placement="top"
                                        >
                                          <Button
                                            justIcon
                                            round
                                            simple
                                            color="info"
                                            className="info"
                                            onClick={() => {
                                              if (
                                                this.state.cronSyntax.trim()
                                                  .length === 0
                                              ) {
                                                this.setState({
                                                  cronSyntax: "*/30 * * * *"
                                                })
                                              }
                                              if (
                                                this.state.cronSyntax
                                                  .trim()
                                                  .split(" ").length !== 5
                                              ) {
                                                alert(
                                                  "Must have five distinct cron syntax values (e.g. <minute> <hour> <day of month> <month> <day of week>)"
                                                )
                                                return
                                              }
                                              this.setState({
                                                isCronBuilderOpen: true
                                              })
                                            }}
                                            disabled={!this.state.useSchedule}
                                          >
                                            <Build />
                                          </Button>
                                        </Tooltip>
                                        <Button
                                          justIcon
                                          round
                                          simple
                                          color="info"
                                          className="info"
                                          onClick={() =>
                                            this.setState({
                                              doRenderCronSyntaxCheatSheetImage:
                                                !this.state
                                                  .doRenderCronSyntaxCheatSheetImage
                                            })
                                          }
                                          disabled={!this.state.useSchedule}
                                        >
                                          <Help />
                                        </Button>
                                        {this.state
                                          .doRenderCronSyntaxCheatSheetImage && (
                                          <modal
                                            style={{
                                              position: "absolute",
                                              zIndex: "1",
                                              marginTop: "-115px",
                                              marginLeft: "36px"
                                            }}
                                          >
                                            <Card>
                                              <CardBody>
                                                <div>
                                                  <img
                                                    src="/images/cron-diagram-expression.png"
                                                    style={{ width: "200px" }}
                                                    alt=""
                                                  />
                                                </div>
                                              </CardBody>
                                            </Card>
                                          </modal>
                                        )}
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem md={6}>
                                        <Modal
                                          open={this.state.isCronBuilderOpen}
                                          onClose={this.toggleCronBuilderModal}
                                        >
                                          <Card className={classes.cronBuilder}>
                                            <CardBody>
                                              <CronBuilder
                                                cronExpression={
                                                  this.state.cronSyntax
                                                }
                                                onChange={e => {
                                                  this.setState(
                                                    {
                                                      cronSyntax: e,
                                                      isCronBuilderOpen: false
                                                    },
                                                    () =>
                                                      this.calculateReportSchedule()
                                                  )
                                                }}
                                                showResult={true}
                                              />
                                            </CardBody>
                                          </Card>
                                        </Modal>
                                      </GridItem>
                                    </GridContainer>
                                  </GridItem>
                                  <GridItem sm={6}>
                                    <h5>
                                      Future Occurrence(s) :{" "}
                                      <span
                                        style={{
                                          fontSize: "10px",
                                          color: "grey"
                                        }}
                                      >
                                        (Up to{" "}
                                        {this.state.maxFutureOccurrenceCount}{" "}
                                        displayed.)
                                      </span>{" "}
                                    </h5>
                                    <div style={{ overflow: "auto" }}>
                                      {this.state.futureOccurrences &&
                                        this.state.futureOccurrences.length <
                                          1 &&
                                        "None, based on the criteria you defined."}
                                      <ol>
                                        {this.state.futureOccurrences &&
                                          this.state.futureOccurrences.map(
                                            val => (
                                              <li
                                                style={{
                                                  textDecoration:
                                                    val < new Date()
                                                      ? "line-through"
                                                      : "none"
                                                }}
                                              >
                                                {Helpers.prettyDateTimeinPacificTimeZone(
                                                  val
                                                )}{" "}
                                                (
                                                <TimeAgo
                                                  date={val}
                                                  title={Helpers.prettyDateTimeinPacificTimeZone(
                                                    val
                                                  )}
                                                />
                                                )
                                              </li>
                                            )
                                          )}
                                      </ol>
                                      {this.state.futureOccurrencesMessage &&
                                        this.state.futureOccurrencesMessage}
                                    </div>
                                  </GridItem>
                                </GridContainer>
                              </CardBody>
                            </Card>
                            <Card>
                              <CardBody>
                                <GridContainer>
                                  <GridItem xs={12} sm={12} md={12}>
                                    <div className="form-row">
                                      {inputTokens.map((inputField, index) => (
                                        <React.Fragment
                                          key={`${inputField}~${index}`}
                                        >
                                          <div className="form-group col-sm-6">
                                            <label htmlFor="tokenName">
                                              Token Name
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="tokenName"
                                              name="tokenName"
                                              value={inputField.tokenName}
                                              onChange={event =>
                                                this.handleInputChange(
                                                  index,
                                                  event
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="form-group col-sm-4">
                                            <label htmlFor="tokenValue">
                                              Token Value
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="tokenValue"
                                              name="tokenValue"
                                              value={inputField.tokenValue}
                                              onChange={event =>
                                                this.handleInputChange(
                                                  index,
                                                  event
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="form-group col-sm-2">
                                            <button
                                              className="btn btn-link"
                                              type="button"
                                              onClick={() =>
                                                this.handleRemoveFields(index)
                                              }
                                            >
                                              -
                                            </button>
                                            <button
                                              className="btn btn-link"
                                              type="button"
                                              onClick={() =>
                                                this.handleAddFields(index)
                                              }
                                            >
                                              +
                                            </button>
                                          </div>
                                        </React.Fragment>
                                      ))}
                                    </div>
                                    <pre>
                                      {JSON.stringify(
                                        this.state.inputTokens,
                                        null,
                                        2
                                      )}
                                    </pre>
                                  </GridItem>
                                </GridContainer>
                              </CardBody>
                            </Card>
                            <Card>
                              <CardBody>
                                <GridContainer>
                                  <GridItem xs={12} sm={12} md={12}>
                                    <Autocomplete
                                      id="reportQuery-select"
                                      name="reportQuerySelect"
                                      options={this.state.allReportQueries}
                                      getOptionLabel={option => option.display}
                                      value={
                                        this.state.selectedReportQueryLookUp
                                      }
                                      onChange={(event, newValue) => {
                                        if (newValue !== null) {
                                          this.setState({
                                            selectedReportQueryLookUp: newValue,
                                            reportQueryId: newValue.value
                                          })
                                        }
                                      }}
                                      renderInput={params => (
                                        <TextField
                                          {...params}
                                          label="Report Query Lookup"
                                          margin="normal"
                                        />
                                      )}
                                    />
                                  </GridItem>
                                  <GridItem
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    sx={{ mb: 2 }}
                                  >
                                    <GridContainer>
                                      <GridItem xs={12} sm={5} md={5}>
                                        <div>
                                          <TextField
                                            id="queryName"
                                            fullWidth={true}
                                            multiline={true}
                                            placeholder="Enter name for your sql query"
                                            defaultValue={
                                              this.state.queryName || ""
                                            }
                                            onBlur={e =>
                                              this.setState({
                                                queryName: e.target.value,
                                                isReportDataDirty: true
                                              })
                                            }
                                            helperText="Give your query a name"
                                          />
                                        </div>
                                      </GridItem>
                                      <GridItem xs={12} sm={5} md={5}>
                                        <div>
                                          <TextField
                                            id="queryDescription"
                                            fullWidth={true}
                                            multiline={true}
                                            placeholder="Enter a description for your sql query"
                                            defaultValue={
                                              this.state.queryDescription || ""
                                            }
                                            onBlur={e =>
                                              this.setState({
                                                queryDescription:
                                                  e.target.value,
                                                isReportDataDirty: true
                                              })
                                            }
                                            helperText="Give your query a description"
                                          />
                                        </div>
                                      </GridItem>
                                      <GridItem xs={12} sm={2} md={2}>
                                        <div>
                                          <Checkbox
                                            checked={this.state.queryIsActive}
                                            onChange={
                                              this.onQueryActiveBoxChecked
                                            }
                                            classes={{
                                              checked: classes.checked,
                                              root: classes.checkRoot
                                            }}
                                            checkedIcon={
                                              <Check
                                                className={classes.checkedIcon}
                                              />
                                            }
                                            icon={
                                              <Check
                                                className={
                                                  classes.uncheckedIcon
                                                }
                                              />
                                            }
                                          />{" "}
                                          Is Active?
                                        </div>
                                      </GridItem>
                                    </GridContainer>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={12}>
                                    <div className={classes.resizeTextarea}>
                                      <TextField
                                        id="reportQuery"
                                        fullWidth={true}
                                        multiline={true}
                                        placeholder="Enter Report SQL here."
                                        value={this.state.reportQuery}
                                        onChange={this.onTextAreaChange}
                                        sx={{ mb: 2 }}
                                      />
                                    </div>
                                    <div className="update ml-auto mr-auto">
                                      <Button
                                        color="primary"
                                        round
                                        onClick={this.executeRunReport}
                                        startIcon={<PlayArrow />}
                                      >
                                        Run Report
                                      </Button>
                                      &nbsp;
                                      <Button
                                        color="primary"
                                        round
                                        onClick={this.executeSaveReport}
                                        disabled={!this.state.isReportDataDirty}
                                        startIcon={<Save />}
                                      >
                                        Save Report
                                      </Button>
                                    </div>
                                  </GridItem>
                                </GridContainer>
                              </CardBody>
                            </Card>
                          </GridItem>
                        </GridContainer>

                        <Box style={{ padding: "0px 20px" }}>
                          {this.state.reportResultCode > 1000 && (
                            <Danger>
                              Error with SQL: {this.state.reportResultMessage}
                            </Danger>
                          )}
                        </Box>
                      </React.Fragment>
                    )
                  }
                ]}
              />
              <Accordion
                active={this.state.showReportResults ? 0 : null}
                collapses={[
                  {
                    title: `Report Results: ${this.state.currentReportName} (${this.state.currentReportHistoryId})`,
                    content: (
                      <React.Fragment>
                        <div style={{ marginLeft: "25px" }}>
                          <button className="btn btn-primary">
                            <CSVLink
                              data={this.state.reactTableResults}
                              filename={`${this.state.reportName}_${reportDate}.csv`}
                              style={{ color: "#fff" }}
                            >
                              Export To CSV
                            </CSVLink>
                          </button>
                        </div>
                        <ReactTable
                          noDataText={
                            this.state.reportIsRunning ? (
                              <CircularProgress />
                            ) : (
                              "No result(s) found."
                            )
                          }
                          data={this.state.reactTableResults}
                          columns={[...this.state.dynamicColumns]}
                          filterable={true}
                          defaultPageSize={50}
                          showPaginationTop={true}
                          showPaginationBottom={true}
                          className="-striped -highlight"
                          defaultFilterMethod={(filter, row) => {
                            const id = filter.pivotId || filter.id
                            return row[id] !== undefined
                              ? String(row[id])
                                  .toLowerCase()
                                  .startsWith(filter.value.toLowerCase())
                              : true
                          }}
                        >
                          {(state, makeTable) => {
                            let recordsInfoText = ""

                            const {
                              filtered,
                              pageRows,
                              pageSize,
                              sortedData,
                              page
                            } = state

                            if (sortedData && sortedData.length > 0) {
                              let isFiltered = filtered.length > 0

                              let totalRecords = sortedData.length

                              let recordsCountFrom = page * pageSize + 1

                              let recordsCountTo =
                                recordsCountFrom + pageRows.length - 1

                              if (isFiltered) {
                                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`
                              } else {
                                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`
                              }
                            } else {
                              recordsInfoText = this.state.reportIsRunning
                                ? "Running Query, please wait"
                                : "No records"
                            }
                            return (
                              <div className="main-grid">
                                <div className="above-table text-right">
                                  <div className="col-sm-12">
                                    <span className="records-info">
                                      {recordsInfoText}
                                    </span>
                                  </div>
                                </div>
                                {makeTable()}
                              </div>
                            )
                          }}
                        </ReactTable>
                      </React.Fragment>
                    )
                  }
                ]}
              />
              <Accordion
                collapses={[
                  {
                    title: "Report History",
                    content: (
                      <React.Fragment>
                        <ReactTable
                          filtered={this.state.tableHistoryFilter}
                          onFilteredChange={filtered =>
                            this.setState({ tableHistoryFilter: filtered })
                          }
                          noDataText={
                            this.state.allHistoryLoading ? (
                              <CircularProgress />
                            ) : (
                              "No report history found."
                            )
                          }
                          getTrProps={(state, rowInfo) => ({
                            style: {
                              background:
                                rowInfo &&
                                rowInfo.row &&
                                rowInfo.row.id ===
                                  this.state.currentReportHistoryId
                                  ? "yellow"
                                  : null
                            }
                          })}
                          data={this.state.allReportHistory}
                          columns={[
                            {
                              Header: "Actions",
                              Cell: cell => (
                                <div className="actions-right">
                                  <Tooltip title="Load Report" placement="top">
                                    <Button
                                      justIcon
                                      round
                                      simple
                                      color="info"
                                      className="info"
                                      id="share_file"
                                      onClick={() =>
                                        this.onLoadReportButtonClicked(
                                          cell.original.id
                                        )
                                      }
                                    >
                                      <Publish />
                                    </Button>
                                  </Tooltip>
                                  <Button
                                    title="Share"
                                    justIcon
                                    round
                                    simple
                                    color="info"
                                    onClick={() => {
                                      this.onShareButtonClicked(
                                        cell.original.id
                                      )
                                    }}
                                  >
                                    <Share />
                                  </Button>
                                </div>
                              ),
                              sortable: false,
                              filterable: false,
                              width: 100
                            },
                            {
                              Header: "Id",
                              accessor: "id",
                              Cell: cell => <div>{cell.value}</div>,
                              width: 60
                            },
                            {
                              Header: "Report Name",
                              accessor: "reportName",
                              show: true,
                              width: 400,
                              ...stringFilterProps
                            },
                            {
                              Header: "Query Name",
                              accessor: "queryName",
                              show: true,
                              width: 400,
                              ...stringFilterProps
                            },
                            {
                              Header: "Report Schedule",
                              accessor: "reportSchedule",
                              show: true,
                              width: 400,
                              ...stringFilterProps
                            },
                            {
                              Header: "Audit Info",
                              id: "auditInfo",
                              Cell: ({ original: { auditInfoResponse } }) => (
                                <AuditInfo {...auditInfoResponse} />
                              ),
                              width: 250,
                              filterable: false
                            }
                          ]}
                          filterable={true}
                          defaultPageSize={5}
                          showPaginationTop={true}
                          showPaginationBottom={false}
                          className="-striped -highlight"
                          defaultSorted={[
                            {
                              id: "id",
                              desc: true
                            }
                          ]}
                          defaultFilterMethod={(filter, row) => {
                            const id = filter.pivotId || filter.id
                            return row[id] !== undefined
                              ? String(row[id])
                                  .toLowerCase()
                                  .startsWith(filter.value.toLowerCase())
                              : true
                          }}
                        >
                          {(state, makeTable) => {
                            const {
                              filtered,
                              pageRows,
                              pageSize,
                              sortedData,
                              page
                            } = state

                            let isFiltered = false
                            isFiltered = filtered.length > 0
                            let recordsCountFrom = 0
                            let recordsCountTo = 0
                            let totalRecords = 0
                            totalRecords = sortedData.length
                            recordsCountFrom = page * pageSize + 1
                            recordsCountTo =
                              recordsCountFrom + pageRows.length - 1

                            return (
                              <div className="main-grid">
                                <div className="above-table text-right">
                                  <div className="col-sm-12">
                                    <span className="records-info">
                                      {totalRecords > 0 && isFiltered && (
                                        <span>
                                          {recordsCountFrom}-{recordsCountTo} of{" "}
                                          {totalRecords} filtered out of{" "}
                                          {this.renderTotalCountOptions()} total
                                          reports
                                        </span>
                                      )}
                                      {totalRecords > 0 && !isFiltered && (
                                        <span>
                                          {recordsCountFrom}-{recordsCountTo} of{" "}
                                          {this.renderTotalCountOptions()} total
                                          reports
                                        </span>
                                      )}
                                      {totalRecords === 0 &&
                                        (this.state.isLoading
                                          ? "Loading report history, please wait..."
                                          : "No reports history.")}
                                    </span>
                                  </div>
                                </div>

                                {makeTable()}
                              </div>
                            )
                          }}
                        </ReactTable>
                      </React.Fragment>
                    )
                  }
                ]}
              />
            </TabPanel>
          </div>
        </CardBody>
      </Card>
    )
  }
}

export default withStyles(regularFormsStyle)(ReportBuilder)
