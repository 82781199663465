import React from "react"
import {
  Box,
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { flexRender } from "@tanstack/react-table"

import useTableSetupHelper from "hooks/table/useTableSetupHelper"
import Helpers from "tools/Helpers"

const useStyles = makeStyles(theme => ({
  selectHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  tableArea: {
    background: "white",
    overflow: "auto"
  },
  header: {
    background: "#eee",
    fontWeight: "bold",
    padding: "3px 0",
    display: "inline-block"
  }
}))

const SelectTriggersToAssociateTable = ({
  columns,
  tableData,
  onItemsChange,
  lastItemsList,
  narrativeId
}) => {
  const classes = useStyles()

  const tableColumns = [
    {
      id: "select",
      header: ({ table }) => (
        <Box className={classes.selectHeader}>
          <Box>Select</Box>
          <Checkbox
            checked={true}
            indeterminate={false}
            onChange={() => {}}
            disabled={true}
          />
        </Box>
      ),
      cell: ({ row }) => (
        <Box textAlign="center">
          <Checkbox checked={true} onChange={() => {}} disabled={true} />
        </Box>
      )
    },
    ...columns
  ]

  const table = useTableSetupHelper({
    tableData,
    tableColumns,
    paginated: true,
    sorted: true,
    filtered: true,
    enableRowSelection: true
  })

  // const selectedItemIds = table
  //   .getSelectedRowModel()
  //   .rows.map(row => row.original?.id)

  let selectedItemIds = []
  tableData.forEach(itm => {
    selectedItemIds.push(itm.id)
  })

  React.useEffect(() => {
    if (
      !Helpers.doTheseTwoArraysOfObjectsMatch(lastItemsList, selectedItemIds)
    ) {
      onItemsChange(selectedItemIds)
    }
  }, [lastItemsList, selectedItemIds])

  return (
    <>
      <span className={classes.header}>
        ASSOCIATE CONTENT BLOCKS WITH NEW TRIGGER KEYS AND TRIGGER IDS FOR
        DESTINATION LIBRARY: {narrativeId}
      </span>
      <Box className={classes.tableArea} mt={1}>
        <Grid container>
          <Grid item sm={12} md={7}>
            <Box mt={1}>
              <Typography variant="subtitle2">{`${selectedItemIds.length} items selected for modification`}</Typography>
            </Box>
          </Grid>
        </Grid>
        <TableContainer>
          <Table size="medium">
            <TableHead>
              {table.getHeaderGroups().map(headerGroup => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <TableCell
                      style={{
                        width: header.column.columnDef.width ?? "initial"
                      }}
                      key={header.id}
                      sortDirection={header.column.getIsSorted()}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <>
                        {header.isPlaceholder ? null : header.column.getCanSort() ? (
                          <TableSortLabel
                            active={
                              table.getState().sorting[0]?.id === header?.id
                            }
                            direction={header.column.getIsSorted() || "asc"}
                            onClick={header.column.getToggleSortingHandler()}
                            data-testid={"sort"}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </TableSortLabel>
                        ) : (
                          flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )
                        )}
                      </>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {table.getRowModel().rows.map(row => (
                <TableRow data-testid="row" key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <TableCell key={cell.id} data-testid="row-cell">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          count={Object.keys(table.getRowModel().rowsById).length}
          rowsPerPage={table.getState().pagination.pageSize}
          page={table.getState().pagination.pageIndex}
          SelectProps={{
            SelectDisplayProps: {
              "data-testid": "pagination-button"
            },
            inputProps: { "data-testid": "pagination-input" }
          }}
          onPageChange={(e, page) => {
            table.setPageIndex(page)
          }}
          onRowsPerPageChange={e => {
            table.setPageSize(e.target.value)
          }}
        />
      </Box>
    </>
  )
}

export default SelectTriggersToAssociateTable
