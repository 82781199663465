import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import makeStyles from "@mui/styles/makeStyles"
import { TreeView } from "@mui/x-tree-view/TreeView"
import { Box, SvgIcon } from "@mui/material"
import {
  PanTool,
  AssignmentLate,
  Functions,
  LibraryBooks,
  ShortText,
  ArrowDropDown,
  ArrowRight,
  FlashOn,
  Code,
  SyncAlt,
  AddAlert,
  ShowChart
} from "@mui/icons-material"
import Helpers from "tools/Helpers"
import useExplorerSelectionGroup from "hooks/explorer/useExplorerSelectionGroup"
import StyledTreeItem from "./StyledTreeItem"
import { groupCollectionBy } from "tools/CollectionHelper"
import useCheckboxSelection from "hooks/useCheckboxSelection"
import useTriggerTypeHelper from "hooks/triggers/useTriggerTypeHelper"
import Button from "components/CustomButtons/Button.jsx"

const Pilcrow = () => (
  <SvgIcon
    style={{ marginLeft: "-7px", marginTop: "-5px", width: "15px" }}
    fontSize="small"
    focusable="false"
    viewBox="0 0 24 24"
    aria-hidden="true"
    role="presentation"
  >
    <path d="M28,0h-4h-4h-4h-4C7.582,0,4,3.582,4,8s3.582,8,8,8v16h4V4h4v28h4V4h4V0z" />
  </SvgIcon>
)

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch",
    maxWidth: "100%",
    backgroundColor: theme.palette.background.paper
  },
  drawerPrimaryCard: {
    flexGrow: 1,
    overflow: "auto",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch"
  },
  drawerPrimaryContent: {
    flexGrow: 1,
    padding: "10px"
  },
  labelBold: {
    fontWeight: "bold"
  },
  paginationArea: {
    padding: "0",
    margin: "0",
    textAlign: "left",
    "& span": {
      textAlign: "left",
      display: "inline-block",
      margin: "0 10px",
      fontWeight: "bold"
    }
  },
  paginationButton: {
    padding: "0 10px",
    margin: "0",
    display: "inline-block",
    fontSize: "10px"
  }
}))

export default function NarrativeBlockExplorer(props) {
  const {
    narrative,
    setBlocks,
    targetOrSource = "sourceNarrative",
    disableIndividualTriggers
  } = props

  const [hugeInsightTriggersOffset, setHugeInsightTriggersOffset] = useState(0)
  const MAX_SIZE_OF_INSIGHT_TRIGGER_PANE = 500

  const classes = useStyles()

  const { id: narrativeId, triggers, paragraphs } = narrative

  const contentBlocks = useMemo(() => {
    const narrativeParagraphs = [...paragraphs].filter(
      p => p.narrativeId === narrativeId || p.narrative_Id === narrativeId
    )
    return narrativeParagraphs
      ? groupCollectionBy(
          narrativeParagraphs,
          p => p.contentBlock && p.contentBlock.toLowerCase()
        )
      : []
  }, [paragraphs])

  const { dependencyTriggers, insightTriggers, newsTriggers } =
    useTriggerTypeHelper({ narrativeId, triggers })

  const contentBlockParagraphIds =
    contentBlocks?.content?.map(paragraph => `paragraphid-${paragraph.id}`) ||
    []

  const {
    handleCheckboxSelect,
    isItemSelected,
    selectedIds,
    setItemsCheckState
  } = useCheckboxSelection({})

  const {
    allSelected: allDependenciesSelected,
    toggleSelectAll: toggleAllDependencies
  } = useExplorerSelectionGroup({
    groupIds: dependencyTriggers.map(item => `triggerid-${item.id}`),
    selectedIds,
    handleSelectAll: setItemsCheckState
  })

  const {
    allSelected: allNewsTriggersSelected,
    toggleSelectAll: toggleAllNewsTriggers
  } = useExplorerSelectionGroup({
    groupIds: newsTriggers.map(item => `newstriggerid-${item.id}`),
    selectedIds,
    handleSelectAll: setItemsCheckState
  })

  const {
    allSelected: allInsightTriggersSelected,
    toggleSelectAll: toggleAllInsightTriggers
  } = useExplorerSelectionGroup({
    groupIds: insightTriggers.map(item => `triggerid-${item.id}`),
    selectedIds,
    handleSelectAll: setItemsCheckState
  })

  const {
    allSelected: allContentBlockParagraphsSelected,
    toggleSelectAll: toggleAllContentBlockParagraphs
  } = useExplorerSelectionGroup({
    groupIds: contentBlockParagraphIds,
    selectedIds,
    handleSelectAll: setItemsCheckState
  })

  useEffect(() => {
    setBlocks(Array.from(selectedIds))
  }, [selectedIds])

  const TriggerTreeItem = ({ trigger, icon, type }) => {
    const prefix = type === "news" ? "newstriggerid" : "triggerid"

    return (
      <StyledTreeItem
        itemId={`${prefix}-${trigger.id}`}
        nodeId={`${prefix}-${trigger.id}`}
        labelText={`(${trigger.id}) - ${trigger.name}`}
        labelName={trigger.name}
        labelInfo={trigger.type}
        labelIcon={icon}
        labelTitle={`${trigger.name || ""} ${
          trigger.narrativeId !== narrativeId ||
          trigger.narrative_Id !== narrativeId
            ? `Inherited From ${narrativeId}`
            : ""
        }`}
        position={trigger.rank}
        handleCheckboxSelect={handleCheckboxSelect}
        isItemSelected={isItemSelected}
        selectable={targetOrSource === "sourceNarrative"}
        areCheckboxesDisabled={disableIndividualTriggers}
      />
    )
  }

  const blockKeys = contentBlocks && Object.keys(contentBlocks)
  let defaultExpanded = ["contentNode"]
  if (blockKeys?.length) {
    defaultExpanded = [...defaultExpanded, ...blockKeys]
  }

  const handleNextNavigationClick = () => {
    if (
      hugeInsightTriggersOffset <
      insightTriggers.length / hugeInsightTriggersOffset
    ) {
      setHugeInsightTriggersOffset(hugeInsightTriggersOffset + 1)
    }
  }

  const handlePreviousNavigationClick = () => {
    if (hugeInsightTriggersOffset > 0) {
      setHugeInsightTriggersOffset(hugeInsightTriggersOffset - 1)
    }
  }

  const treeItems = blockKeys?.map(key => {
    key = key || "empty"
    return (
      <StyledTreeItem
        key={`element-${key}`}
        nodeId={key}
        labelText={`<${key}/>`}
        labelIcon=""
      >
        {targetOrSource === "sourceNarrative" && key === "content" && (
          <div className={classes.labelBold}>
            <input
              type="checkbox"
              className="checkbox"
              checked={allContentBlockParagraphsSelected}
              onChange={toggleAllContentBlockParagraphs}
            />{" "}
            Select all Paragraphs
          </div>
        )}

        {contentBlocks[key].map(paragraph => (
          <StyledTreeItem
            key={`paragraphid-${paragraph.id}`}
            itemId={`paragraphid-${paragraph.id}`}
            nodeId={`paragraphid-${paragraph.id}`}
            labelText={`(${paragraph.id}) ${paragraph.name || ""}`}
            labelName={paragraph.name}
            labelInfo={
              paragraph.triggerType
                ? Helpers.titleCaseSlug(paragraph.triggerType)
                : ""
            }
            labelIcon={Pilcrow}
            labelTitle={`${paragraph.triggerType || ""} ${
              paragraph.narrativeId !== narrativeId ||
              paragraph.narrative_Id !== narrativeId
                ? `Inherited From ${narrativeId}`
                : ""
            }`}
            position={paragraph.position}
            handleCheckboxSelect={handleCheckboxSelect}
            isItemSelected={isItemSelected}
            selectable
          >
            {paragraph?.sentences
              ?.filter(
                s =>
                  (s.narrativeId === narrativeId ||
                    s.narrativeId === narrativeId) &&
                  !s.isFromLibrary
              )
              .map(sentence => (
                <StyledTreeItem
                  key={`sentenceid-${sentence.id}`}
                  nodeId={`sentenceid-${sentence.id}`}
                  itemId={`sentenceid-${sentence.id}`}
                  labelText={`(${sentence.id}) ${
                    sentence.name ||
                    (sentence.triggerType
                      ? Helpers.titleCaseSlug(sentence.triggerType)
                      : "")
                  }`}
                  labelIcon={ShortText || ""}
                  labelName={sentence.name}
                  labelTitle={`${sentence.triggerType || ""} ${
                    sentence.narrativeId !== narrativeId ||
                    sentence.narrative_Id !== narrativeId
                      ? `Inherited From ${narrativeId}`
                      : ""
                  }`}
                  position={sentence.position}
                  triggerWeight={sentence.triggerWeight}
                  handleCheckboxSelect={handleCheckboxSelect}
                  isItemSelected={isItemSelected}
                  selectable={targetOrSource === "sourceNarrative"}
                />
              ))}
          </StyledTreeItem>
        ))}
      </StyledTreeItem>
    )
  })

  const isHugeNumberOfInsightTriggers =
    insightTriggers.length > MAX_SIZE_OF_INSIGHT_TRIGGER_PANE ? true : false
  const reducedInsightTriggerList = isHugeNumberOfInsightTriggers
    ? insightTriggers.slice(
        MAX_SIZE_OF_INSIGHT_TRIGGER_PANE * hugeInsightTriggersOffset,
        MAX_SIZE_OF_INSIGHT_TRIGGER_PANE * (hugeInsightTriggersOffset + 1)
      )
    : []

  const xOfY = `${hugeInsightTriggersOffset + 1} of ${Math.ceil(
    insightTriggers.length / MAX_SIZE_OF_INSIGHT_TRIGGER_PANE
  )}`

  return (
    <Box className={classes.root}>
      <Box className={classes.drawerPrimaryCard}>
        {treeItems && (
          <Box className={classes.drawerPrimaryContent}>
            <TreeView
              defaultExpanded={defaultExpanded}
              defaultCollapseIcon={<ArrowDropDown />}
              defaultExpandIcon={<ArrowRight />}
              defaultEndIcon={<div style={{ width: 12 }} />}
            >
              <StyledTreeItem
                itemId={dependencyTriggers?.length > 0 ? "dependencies" : null}
                nodeId={"dependenciesNode"}
                labelText="Dependencies"
                labelIcon={SyncAlt}
                labelName="Dependencies"
                handleCheckboxSelect={toggleAllDependencies}
                isItemSelected={() => allDependenciesSelected}
                selectable={targetOrSource === "sourceNarrative"}
                areCheckboxesDisabled={disableIndividualTriggers}
              >
                {dependencyTriggers?.map(trigger => (
                  <TriggerTreeItem
                    key={`dep-triggerid-${trigger.id}`}
                    trigger={trigger}
                    icon={
                      trigger.type?.startsWith("narrative_killswitch")
                        ? PanTool
                        : trigger.type?.startsWith("narrative_reviewswitch")
                        ? AssignmentLate
                        : Functions
                    }
                    type="dependency"
                  />
                ))}
              </StyledTreeItem>
              <StyledTreeItem
                itemId={newsTriggers?.length > 0 ? "newstriggers" : null}
                nodeId={"newsNode"}
                labelText="News Triggers"
                labelName="News Triggers"
                labelIcon={FlashOn}
                handleCheckboxSelect={toggleAllNewsTriggers}
                isItemSelected={() => allNewsTriggersSelected}
                selectable={targetOrSource === "sourceNarrative"}
                areCheckboxesDisabled={disableIndividualTriggers}
              >
                {newsTriggers?.map(trigger => (
                  <TriggerTreeItem
                    key={`newstriggerid-${trigger.id}`}
                    trigger={trigger}
                    icon={AddAlert}
                    type="news"
                  />
                ))}
              </StyledTreeItem>
              <StyledTreeItem
                itemId={insightTriggers?.length > 0 ? "insights" : null}
                nodeId={"insightNode"}
                labelText="Insight Triggers"
                labelName="Insight Triggers"
                labelIcon={ShowChart}
                handleCheckboxSelect={toggleAllInsightTriggers}
                isItemSelected={() => allInsightTriggersSelected}
                selectable={targetOrSource === "sourceNarrative"}
                areCheckboxesDisabled={disableIndividualTriggers}
              >
                {!isHugeNumberOfInsightTriggers ? (
                  insightTriggers?.map(trigger => (
                    <TriggerTreeItem
                      key={`triggerid-${trigger.id}`}
                      trigger={trigger}
                      icon={Code}
                      type="insight"
                    />
                  ))
                ) : (
                  <>
                    <div className={classes.paginationArea}>
                      {hugeInsightTriggersOffset ? (
                        <Button
                          onClick={handlePreviousNavigationClick}
                          className={classes.paginationButton}
                        >
                          Prev
                        </Button>
                      ) : null}
                      <span>{xOfY}</span>
                      {hugeInsightTriggersOffset + 1 <
                      Math.ceil(
                        insightTriggers.length /
                          MAX_SIZE_OF_INSIGHT_TRIGGER_PANE
                      ) ? (
                        <Button
                          onClick={handleNextNavigationClick}
                          className={classes.paginationButton}
                        >
                          Next
                        </Button>
                      ) : null}
                    </div>
                    {reducedInsightTriggerList.map(trigger => (
                      <TriggerTreeItem
                        key={`triggerid-${trigger.id}`}
                        trigger={trigger}
                        icon={Code}
                        type="insight"
                      />
                    ))}
                    <div className={classes.paginationArea}>
                      {hugeInsightTriggersOffset ? (
                        <Button
                          onClick={handlePreviousNavigationClick}
                          className={classes.paginationButton}
                        >
                          Prev
                        </Button>
                      ) : null}
                      <span>{xOfY}</span>
                      {hugeInsightTriggersOffset + 1 <
                      Math.ceil(
                        insightTriggers.length /
                          MAX_SIZE_OF_INSIGHT_TRIGGER_PANE
                      ) ? (
                        <Button
                          onClick={handleNextNavigationClick}
                          className={classes.paginationButton}
                        >
                          Next
                        </Button>
                      ) : null}
                    </div>
                  </>
                )}
              </StyledTreeItem>
              <StyledTreeItem
                nodeId={"contentNode"}
                labelText="Content Blocks"
                labelIcon={LibraryBooks}
              >
                {treeItems}
              </StyledTreeItem>
            </TreeView>
          </Box>
        )}
      </Box>
    </Box>
  )
}

NarrativeBlockExplorer.propTypes = {
  narrative: PropTypes.object.isRequired,
  setBlocks: PropTypes.func.isRequired,
  targetOrSource: PropTypes.string,
  disableIndividualTriggers: PropTypes.bool
}
