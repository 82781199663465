import React from "react"
import withStyles from "@mui/styles/withStyles"
import PropTypes from "prop-types"

import Modal from "@mui/material/Modal"
import Check from "@mui/icons-material/CheckCircleOutline"
import Report from "@mui/icons-material/Report"

import Button from "components/CustomButtons/Button.jsx"
import UploadButton from "components/CustomButtons/UploadButton.jsx"
import * as XLSX from "xlsx"

import TableSimpleResults from "components/NarrativeAdmin/Data/TableSimpleResults"
import IconButton from "@mui/material/IconButton"
import InfoIcon from "@mui/icons-material/Info"
import { CircularProgress } from "@mui/material"
import Enums from "tools/Enums"
import CustomDSDialog from "components/CustomDialogs/CustomDSDialog"
import InfoOutlined from "@mui/icons-material/InfoOutlined"

const styles = theme => ({
  resultsModal: {
    position: "absolute",
    _width: "90vw",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "45%",
    left: "45%",
    transform: "translate(-45%, -45%)",
    textAlign: "center",
    maxWidth: "90vw",
    "& span.stk": {
      display: "inline-block",
      backgroundColor: "#eee",
      textDecoration: "line-through"
    },
    "& #initial-modal-description": {
      textAlign: "left"
    }
  },
  dialogCard: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "32px",
    textAlign: "left"
  },
  infoModal: {
    position: "absolute",
    width: "25vw",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "45%",
    left: "55%",
    transform: "translate(-45%, -45%)",
    textAlign: "left"
  },
  requiredMsg: {
    color: "#c00",
    fontWeight: "bold",
    backgroundColor: "#f7f7f7"
  },
  infoHeader: {
    textAlign: "left",
    color: "#inherit"
  },
  addScrollIfNeeded: {
    maxHeight: "300px",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "10px 0 0 0",
    "& li": {
      textAlign: "left"
    }
  },
  deliverablesButton: {
    display: "flex"
  }
})

function UploadTSV(props) {
  const { descriptorString, narrativeAndView, paragraph, narrative, classes } =
    props
  const [resetButton, setResetButton] = React.useState(false)
  const [showResultsModal, setShowResultsModal] = React.useState(false)
  const [resultTableData, setResultTableData] = React.useState([])
  const [triggerIssues, setTriggerIssues] = React.useState([])
  const [hiddenColumns, setHiddenColumns] = React.useState([])

  const [resultString, setResultString] = React.useState("")
  const [resultMessagingString, setResultMessagingString] = React.useState("")

  const [resultObject, setResultObject] = React.useState([])

  const [malformedTSV, setMalformedTSV] = React.useState(false)
  const [notCompliantTSV, setNotCompliantTSV] = React.useState(false)
  const [openSentenceDialog, setOpenSentenceDialog] = React.useState(false)
  const [openTriggerDialog, setOpenTriggerDialog] = React.useState(false)
  const [loadingSentenceOrTrigger, setLoadingSentenceOrTrigger] =
    React.useState(false)
  const [hideActionButtons, setHideActionButtons] = React.useState(false)
  const [allInError, setAllInError] = React.useState(false)
  const [headersInvalid, setHeadersInvalid] = React.useState(false)
  const tsvType = descriptorString
    ? !!descriptorString.match(/sentence/i)
      ? "sentence"
      : !!descriptorString.match(/trigger/i)
      ? "trigger"
      : ""
    : ""

  const columns = {
    trigger: [
      {
        Header: "Trigger/Sentence",
        accessor: "Trigger/Sentence",
        width: 300
      },
      {
        Header: "Trigger Scriban",
        accessor: "formula",
        width: 300
      },
      {
        Header: "Trigger Type Name",
        accessor: "name",
        width: 300
      },
      {
        Header: "Trigger Type",
        accessor: "type",
        width: 400
      }
    ],

    sentence: [
      {
        Header: "Trigger/Sentence",
        accessor: "Trigger/Sentence",
        width: 150
      },
      {
        Header: "Position",
        accessor: "position",
        width: 150
      },
      {
        Header: "Render",
        accessor: "renderType",
        width: 150
      },
      {
        Header: "Weight",
        accessor: "triggerWeight",
        width: 150
      },
      {
        Header: "# of Sentences",
        accessor: "numberOfSentences",
        width: 150
      },
      {
        Header: "Trigger Scriban",
        accessor: "formula",
        width: 250
      },
      {
        Header: "Trigger Type Name",
        accessor: "name",
        width: 150
      },
      {
        Header: "Sentence Start",
        accessor: "starter",
        width: 400
      },
      {
        Header: "Trigger Type",
        accessor: "triggerType",
        width: 400
      }
    ]
  }

  const mandatoryColumns = {
    trigger: ["formula", "type", "name"],
    sentence: [
      "position", //"renderType",
      "starter"
    ]
  }

  const handleFileUpload = e => {
    //In here we read a Tab Seperated Values (TSV) file and when it loads convert it to CSV
    const file = e.target.files[0] //Why not CSV to begin with? Because we have commas (,) in the data and that blows up a CSV
    const reader = new FileReader() //So the code below will detect the commas and escape those values before adding the commas back in to create the CSV
    reader.onload = evt => {
      // Parse data
      const bstr = evt.target.result
      const wb = XLSX.read(bstr, { type: "binary" })
      // Get first worksheet
      const wsname = wb.SheetNames[0]
      let ws = wb.Sheets[wsname]
      Object.keys(ws).forEach(key => {
        Object.keys(ws[key]).forEach(inkey => {
          if (inkey === "v") {
            if (typeof ws[key][inkey] === "string") {
              let tmp = ws[key][inkey]
              tmp = tmp.replace(/,/g, "__COMMASPOT__")
              ws[key][inkey] = tmp
            }
          }
        })
      })
      // Convert array of arrays, will account for any commas in the data that need to be escaped before conversion to CSV
      const dataResult = XLSX.utils.sheet_to_csv(ws, { header: 1 })
      processTSVdata(dataResult)
    }
    reader.readAsBinaryString(file)
  }
  const checkMandatoryColumns = (mandatory, key) => {
    let loc = mandatory.indexOf(key)
    if (loc === -1) {
      let found = -1
      columns[tsvType]?.forEach(itm => {
        if (itm.Header === key && itm.accessor) {
          found = mandatory.indexOf(itm.accessor)
        }
      })
      return found
    } else {
      return loc
    }
  }
  const checkIfThisSentenceIsValid = myList => {
    const mandatory = mandatoryColumns[tsvType] || []
    const issues = []
    myList.forEach((itm, index) => {
      const mand = [...mandatory]
      for (const key in itm) {
        const loc = checkMandatoryColumns(mandatory, key)
        if (loc > -1) {
          if (!!itm[key].trim()) {
            mand.splice(loc, 1)
          } else {
            let found = false
            issues.forEach(itm => {
              if (itm.index === index) {
                itm.position += `, ${key}`
                found = true
              }
            })
            if (!found) {
              issues.push({ index: index, position: `'${key}' is missing.` })
            }
          }
        } else if (mandatory.includes(key)) {
          let found = false
          issues.forEach(itm => {
            if (itm.index === index) {
              itm.position += `, ${key}`
              found = true
            }
          })
          if (!found) {
            issues.push({ index: index, position: `'${key}' is missing.` })
          }
        }
      }
    })
    if (issues.length) {
      if (issues.length === myList.length) {
        setResultString("File Validation Review")
        setResultObject(issues)
        setAllInError(true)
        setResultMessagingString("All entries failed file validation.")
      } else {
        setResultString("File Validation Review")
        setResultObject(issues)
        setResultMessagingString(
          `${myList.length - issues.length} ${tsvType}s passed validation. ${
            issues.length
          }  ${tsvType}s failed validation.`
        )
      }
      setShowResultsModal(true)
      setNotCompliantTSV(true)
    } else {
      // All the entries are valid
      setResultString("File Validation Review")
      setResultMessagingString("File is valid.")
      setResultMessagingString(
        `${myList.length} ${tsvType}s passed validation. `
      )
      setShowResultsModal(true)
      setNotCompliantTSV(false)
    }
    return issues
  }

  const checkForMandatoryFields = (trg, key) => {
    let found = false
    columns[tsvType]?.forEach(itm => {
      if (itm.Header && itm.accessor === key) {
        found = !!trg[itm.Header]
      }
    })
    return found
  }

  const checkIfThisTriggerIsValid = list => {
    let requiredFields = mandatoryColumns[tsvType] || []
    let allFieldsExist = true
    //let duplicateKeys = []
    let fieldsMissing = []
    let duplicateKeyValues = []
    let existingDataDuplicateKeys = []
    let existingDataDuplicateKeysValues = []
    let totalIssues = 0

    list.forEach((thisTrigger, i) => {
      let missingFieldsForThisItem = {}
      requiredFields.forEach(itm => {
        const fieldExists = checkForMandatoryFields(thisTrigger, itm)
        allFieldsExist = allFieldsExist && fieldExists
        if (!fieldExists) {
          missingFieldsForThisItem["key"] = itm
          missingFieldsForThisItem["index"] = i
          totalIssues += 1
        }
      })
      fieldsMissing.push(missingFieldsForThisItem)
    })
    list.forEach((thisTrigger, j) => {
      narrative?.triggers?.forEach(itm => {
        if (itm["type"] === thisTrigger["Trigger Type"]) {
          existingDataDuplicateKeysValues.push({ key: itm["type"], index: j })
          existingDataDuplicateKeys.push(itm["type"])
          totalIssues += 1
        }
      })
    })
    const allTriggersInTable = props.data || []
    list.forEach((thisTrigger, j) => {
      allTriggersInTable.forEach(itm => {
        if (
          itm["type"] === thisTrigger["Trigger Type"] &&
          !existingDataDuplicateKeys.includes(itm["type"])
        ) {
          duplicateKeyValues.push({ key: itm["type"], index: j })
          totalIssues += 1
        }
      })
    })
    const issuesArray = []
    if (existingDataDuplicateKeysValues.length > 0) {
      issuesArray.push({
        existingDataDuplicateKey: existingDataDuplicateKeysValues
      })
    }
    if (!allFieldsExist) {
      issuesArray.push({
        missing: fieldsMissing
      })
    }
    if (duplicateKeyValues.length > 0) {
      issuesArray.push({
        duplicateKey: duplicateKeyValues
      })
    }
    if (issuesArray.length === 0) {
      setResultString("File Validation Review")
      setResultMessagingString("File is valid.")
      setResultMessagingString(`${list.length} ${tsvType}s passed validation. `)
      setShowResultsModal(true)
      setNotCompliantTSV(false)
    } else {
      setResultString("File Validation Review")
      setResultObject(issuesArray)
      setShowResultsModal(true)
      setResultMessagingString(
        `${
          list.length - totalIssues
        } ${tsvType}s passed validation. ${totalIssues}  ${tsvType}s failed validation.`
      )
      if (list.length === totalIssues) {
        setAllInError(true)
      }
    }
    return issuesArray
  }

  const callAPI = list => {
    const issues =
      tsvType === "sentence"
        ? checkIfThisSentenceIsValid(list)
        : tsvType === "trigger"
        ? checkIfThisTriggerIsValid(list)
        : []
    const newList = []
    const trigIssues = []
    list.forEach((itm, i) => {
      const obj = {}
      columns[tsvType]?.forEach(itm2 => {
        if (itm[itm2.Header] || itm[itm2.Header] === "") {
          obj[itm2.accessor] = itm[itm2.Header]
        }
      })
      issues.forEach(errs => {
        if (tsvType === "sentence") {
          if (errs.index === i) {
            obj.hasError = true
          }
        } else if (tsvType === "trigger") {
          Object.keys(errs).forEach(keey => {
            errs[keey].forEach(errItm => {
              if (errItm.index === i) {
                obj.hasError = true
                trigIssues.push({ type: keey, detail: errItm.key, index: i })
              }
            })
          })
        }
      })
      if (tsvType === "sentence") {
        obj.id = null
        obj.isActive = true
        obj.paragraph_Id = paragraph?.id
        obj.narrative_Id = paragraph?.narrative_Id
        obj.status_Id = Enums.SentenceStatus.DRAFT
        obj.template = obj.starter
        newList.push(obj)
      } else if (tsvType === "trigger") {
        obj.id = null
        obj.isActive = true
        obj.narrative_Id = narrative?.id
        obj.status_Id = 3
        newList.push(obj)
      }
    })
    setResultTableData(newList)
    setTriggerIssues(trigIssues)
  }

  const passDataUp = theData => {
    props.passDataUp(theData.filter(itm => !itm.hasError))
  }

  const validateHeaders = headers => {
    const missingColumns = []
    const missingMandatory = []
    columns[tsvType]?.forEach(clm => {
      let match = false
      headers?.forEach(hdrs => {
        if (clm.Header === hdrs) {
          match = true
        }
      })
      if (!match) {
        if (mandatoryColumns[tsvType].includes(clm.accessor)) {
          missingMandatory.push(clm.Header)
          missingColumns.push(`${clm.Header}*`)
        } else {
          missingColumns.push(clm.Header)
        }
      }
    })
    return { missingColumns, missingMandatory }
  }

  const processIssueList = (list, mandatoryMissing) => (
    <div>
      <div className={classes.infoHeader}>
        {!mandatoryMissing
          ? "The following Header columns are missing"
          : "The following Header columns are missing, including Mandatory fields. Invalid TSV."}
      </div>
      <div className={classes.addScrollIfNeeded}>
        <ol>
          {list?.map((itm, i) => (
            <li key={i}>{itm}</li>
          ))}
        </ol>
      </div>
      {mandatoryMissing ? "* Mandatory Fields" : ""}
    </div>
  )
  const processTSVdata = dataString => {
    const dataStringLines = dataString.split(/\r\n|\n/) //split by CR/LF
    const initHeaders = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    ) //Take the first "line" and split out the header columns as our name keys
    const headers = []
    initHeaders.forEach(itm => {
      headers.push(itm.trim())
    })
    const { missingColumns, missingMandatory } = validateHeaders(headers)
    if (missingColumns.length) {
      // //alert("Headers invalid")
      if (missingMandatory.length) {
        setResultMessagingString(processIssueList(missingColumns, true))
        setHeadersInvalid(true)
        setShowResultsModal(true)
        return
      } else {
        setHiddenColumns(missingColumns)
      }
    }
    const list = []
    for (let i = 1; i < dataStringLines.length; i++) {
      dataStringLines[i] = dataStringLines[i].replace(/""/g, '"') //To remove double quotes "" to single quote "
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      )
      if (headers && row.length === headers.length) {
        const obj = {}
        for (let j = 0; j < headers.length; j++) {
          //This uses the single header values as the "name" and the specific row values as the "value"
          let columnValue = row[j] //For the properties of  "obj" which will correspond  to the name-value pairs for this row
          if (columnValue.length > 0) {
            if (columnValue[0] === '"') {
              columnValue = columnValue.substring(1, columnValue.length - 1)
            }
            if (columnValue[columnValue.length - 1] === '"') {
              columnValue = columnValue.substring(columnValue.length - 2, 1)
            }
          }
          if (headers[j]) {
            obj[headers[j]] = columnValue.replace(/__COMMASPOT__/g, ",").trim()
          }
        }

        // do not add any blank rows
        if (Object.values(obj).filter(x => x).length > 0) {
          list.push(obj)
        }
      }
    }
    if (list.length) {
      callAPI(list)
    }
  }

  const setAppropriateInfoDialog = () => {
    if (tsvType === "sentence") {
      setOpenSentenceDialog(true)
    } else if (tsvType === "trigger") {
      setOpenTriggerDialog(true)
    }
  }

  const clearResultsModal = action => {
    if (loadingSentenceOrTrigger) {
      return
    }
    setResultMessagingString("")
    setShowResultsModal(false)
    setResultTableData([])
    setResetButton(true)
    setMalformedTSV(false)
    setLoadingSentenceOrTrigger(false)
    setHideActionButtons(false)
    setAllInError(false)
    setHeadersInvalid(false)
  }

  React.useEffect(() => {
    if (resetButton) {
      setResetButton(false)
    }
  }, [resetButton])
  if (
    !descriptorString ||
    narrativeAndView?.match(/dependencies/i) ||
    narrativeAndView?.match(/newstriggers/i)
  ) {
    return null
  }
  return (
    <>
      {!resetButton && (
        <div
          style={{
            display: "inline-block",
            fontWeight: "bold",
            whiteSpace: "nowrap",
            padding: "0px 5px 0px 15px"
          }}
        >
          <UploadButton
            doFileUpload={e => {
              handleFileUpload(e)
            }}
            //buttonParams={buttonParams}
            acceptedSuffix={".tsv"}
            descriptorString={descriptorString}
            customStyle={{
              padding: "5px",
              margin: "0 5px",
              display: "inline-block"
            }}
          />
          <IconButton
            style={{
              padding: "0px",
              margin: "0 0 0 5px",
              color: "#4d9ab5"
            }}
            aria-label="Information"
            color="secondary"
            //className={helpIcon}
            onClick={() => setAppropriateInfoDialog(true)}
            size="large"
          >
            <InfoIcon />
          </IconButton>
          <>
            <CustomDSDialog
              open={openSentenceDialog}
              content={
                <div className={classes.dialogCard}>
                  <div id="server-modal-description">
                    <h5>Sentence Bulk Upload Mandatory Fields</h5>
                    <span>
                      A Sentence bulk upload entry will only validate if all
                      mandatory fields are included. These fields are:
                    </span>
                    <ul>
                      <li key="s1">Position</li>
                      <li key="s2">Starter</li>
                    </ul>
                  </div>
                </div>
              }
              icon={<InfoOutlined style={{ fontSize: "4rem" }} />}
              onClose={() => {
                setOpenSentenceDialog(false)
              }}
            />
            <CustomDSDialog
              open={openTriggerDialog}
              content={
                <div className={classes.dialogCard}>
                  <div id="server-modal-description">
                    <h5>Trigger Bulk Upload Mandatory Fields</h5>
                    <span>
                      A Trigger bulk upload entry will only validate if all
                      mandatory fields are included. These fields are:
                    </span>
                    <ul>
                      <li key="t1">Formula</li>
                      <li key="t2">Type</li>
                      <li key="t3">Name</li>
                    </ul>
                  </div>
                </div>
              }
              icon={<InfoOutlined style={{ fontSize: "4rem" }} />}
              onClose={() => {
                setOpenTriggerDialog(false)
              }}
            />

            <Modal
              open={showResultsModal}
              onClose={clearResultsModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div className={classes.resultsModal}>
                {headersInvalid && (
                  <div id="initial-modal-description">
                    <h4 style={{ margin: "0 0 10px 0" }}>
                      {resultMessagingString}
                    </h4>
                  </div>
                )}
                {!headersInvalid &&
                  !notCompliantTSV &&
                  !loadingSentenceOrTrigger && (
                    <div id="initial-modal-description">
                      <h2>{resultString}</h2>
                      <h3 style={{ margin: "0 0 10px 0" }}>
                        {resultMessagingString}
                      </h3>
                      {triggerIssues &&
                        triggerIssues.map((itm, i) => (
                          <li key={i}>
                            Row #{itm.index + 1} --- "{itm.type}" is "
                            {itm.detail}"
                          </li>
                        ))}
                    </div>
                  )}
                {!headersInvalid &&
                  notCompliantTSV &&
                  !loadingSentenceOrTrigger && (
                    <div id="initial-modal-description">
                      <h2>{resultString}</h2>
                      <h3 style={{ margin: "0 0 10px 0" }}>
                        {resultMessagingString}
                      </h3>
                      <ul
                        style={{
                          overflow: "auto auto",
                          maxHeight: "15vh",
                          maxWidth: "50vw"
                        }}
                      >
                        {resultObject &&
                          resultObject.map((itm, i) => (
                            <li key={i}>
                              Row #{itm.index + 1} --- Column: {itm.position}
                            </li>
                          ))}
                      </ul>
                      {triggerIssues.join(",")}
                    </div>
                  )}
                {!headersInvalid && loadingSentenceOrTrigger && (
                  <h2>{resultString}</h2>
                )}
                {!headersInvalid &&
                  !loadingSentenceOrTrigger &&
                  resultTableData.length > 0 && (
                    <>
                      <TableSimpleResults
                        columns={[
                          {
                            Header: "Index",
                            accessor: "index",
                            width: 75,
                            Cell: ({ cell }) => (
                              <>
                                {cell?.row?.original?.fromAPI ? (
                                  <div>
                                    {cell?.row?.original.hasError ? (
                                      <>
                                        <Report
                                          style={{
                                            color: "#a31545",
                                            width: "28px",
                                            height: "28px"
                                          }}
                                        />
                                        {cell?.row?.index + 1}
                                      </>
                                    ) : (
                                      <>
                                        {" "}
                                        <Check
                                          style={{
                                            color: "#66bb6a",
                                            width: "28px",
                                            height: "28px"
                                          }}
                                        />
                                        {cell?.row?.index + 1}
                                      </>
                                    )}
                                  </div>
                                ) : (
                                  <>
                                    {cell?.row?.original.hasError ? (
                                      <>
                                        <Report
                                          style={{
                                            color: "#a31545",
                                            width: "28px",
                                            height: "28px"
                                          }}
                                        />
                                        {cell?.row?.index + 1}
                                      </>
                                    ) : (
                                      <>
                                        {" "}
                                        <Check
                                          style={{
                                            color: "#66bb6a",
                                            width: "28px",
                                            height: "28px"
                                          }}
                                        />
                                        {cell?.row?.index + 1}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )
                          }
                        ].concat(columns[tsvType] || [])}
                        hideColumns={hiddenColumns}
                        data={resultTableData}
                        readOnly={true}
                      />
                    </>
                  )}
                {!headersInvalid && loadingSentenceOrTrigger && (
                  <CircularProgress size={20} />
                )}
                {!headersInvalid && !hideActionButtons && (
                  <div style={{ textAlign: "right" }}>
                    <Button
                      onClick={() => {
                        clearResultsModal("cancel")
                      }}
                      className={classes.deliverablesButton}
                      variant="contained"
                      style={{ margin: "0 10px", display: "inline-block" }}
                    >
                      Cancel
                    </Button>
                    {!malformedTSV &&
                      !allInError &&
                      resultTableData.length > 0 && (
                        <Button
                          onClick={() => {
                            if (tsvType === "sentence") {
                              const listWithDuplicates = []
                              resultTableData?.forEach(sentence => {
                                if (!sentence.hasError) {
                                  const numToAdd =
                                    Number(sentence.numberOfSentences) || 1
                                  for (let i = 0; i < numToAdd; i += 1) {
                                    listWithDuplicates.push(sentence)
                                  }
                                }
                              })
                              clearResultsModal()
                              passDataUp(listWithDuplicates)
                            } else {
                              clearResultsModal()
                              passDataUp(resultTableData)
                            }
                          }}
                          className={classes.deliverablesButton}
                          variant="contained"
                          color="primary"
                          style={{ display: "inline-block" }}
                        >
                          Add {tsvType}
                        </Button>
                      )}
                  </div>
                )}
              </div>
            </Modal>
          </>
        </div>
      )}
    </>
  )
}

UploadTSV.propTypes = {
  passDataUp: PropTypes.func,
  descriptorString: PropTypes.string,
  narrativeAndView: PropTypes.string,
  paragraph: PropTypes.object,
  narrative: PropTypes.object,
  data: PropTypes.array,
  classes: PropTypes.object
}
export default withStyles(styles)(UploadTSV)
