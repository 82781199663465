// Third-party
import { useContext, useEffect, useCallback, useState } from "react"
import makeStyles from "@mui/styles/makeStyles"
import {
  Box,
  Grid,
  Typography,
  LinearProgress,
  Modal,
  Snackbar,
  Collapse
} from "@mui/material"
import PropTypes from "prop-types"
import { useHistory, useLocation, useParams } from "react-router-dom"

// Custom
import Accordion from "components/Accordion/Accordion"
import { Cookies } from "tools/storage"
import SentencePreview from "../Sentence/SentencePreview"

import { NarrativeContext } from "contexts/narrative-context"
import SentenceTable from "components/NarrativeAdmin/Data/SentenceTable"
import ParagraphEditorAdvanced from "./ParagraphEditorAdvanced"
import ParagraphEditorToolbar from "./ParagraphEditorToolbar"
import ParagraphChangeLog from "./ParagraphChangeLog"
import ParagraphRelatedParagraphs from "./ParagraphRelatedParagraphs"
import useParagraphForm from "hooks/narratives/useParagraphForm"

const useStyles = makeStyles(theme => ({
  progressingMsg: {
    color: "#999",
    textAlign: "center",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    margin: "5px 15px 0 5px"
  },
  finishedMsg: {
    color: "#069",
    textAlign: "center",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    margin: "5px 15px 0 5px"
  },
  failedListMsg: {
    color: "#C00",
    textAlign: "center",
    cursor: "pointer",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    margin: "5px 15px 0 5px"
  },
  failedMsg: {
    color: "#C00",
    textAlign: "center",
    cursor: "pointer",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    margin: "12px 15px 0 5px"
  },
  progressBar: {
    width: "40%",
    background: "#eee",
    display: "flex",
    top: "55px",
    right: "20px",
    position: "fixed",
    zIndex: "99999",
    border: "2px solid #036",
    borderRadius: "15px",
    padding: "4px"
  },
  errorModal: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "45%",
    left: "45%",
    transform: "translate(-45%, -45%)"
  },
  selected: {
    "&.MuiListItem-root.Mui-selected": {
      backgroundColor: "#bdbdbd"
    }
  }
}))

const cookies = new Cookies()

const Paragraph = props => {
  const [editParagraph, setEditParagraph] = useState(
    !!(props.editParagraph || props.searchEditorMode)
  )

  const openParagraphs = cookies.get("open-paragraphs") || []
  const [tableView, setTableView] = useState(
    openParagraphs.includes(props.paragraph.id) || props.tableView || false
  )

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const [bulkAlertMessage, setBulkAlertMessage] = useState({})
  const [bulkAlertMessageActive, setBulkAlertMessageActive] = useState(false)

  const { searchString, narrative, setLoadingGeneral } =
    useContext(NarrativeContext)

  const form = useParagraphForm({
    isLibrary: narrative?.isLibrary,
    initialParagraph: props.paragraph,
    handleSubmitResponse
  })

  const history = useHistory()

  const sentencesSorted = useCallback(
    () =>
      props.paragraph?.sentences?.slice().sort((a, b) => {
        if (a.position < b.position) {
          return -1
        } else if (a.position > b.position) {
          return 1
        }
        if (a.id < b.id) {
          return -1
        } else if (a.id > b.id) {
          return 1
        } else {
          return 0
        }
      }),
    [props.paragraph.sentences]
  )

  const classes = useStyles()

  useEffect(() => {
    updateEditParagraphFromLocalStorage()
  }, [])

  const updateEditParagraphFromLocalStorage = () => {
    //Workaround to allow us to pass info from 2 children of NarrativeEditor to each other - NarrativeExplorer and here
    //Reason this code is needed is the NarrativeExplorer (child) can send info to the NarrativeEditor (parent) via a prop function
    //But when I change that state of a prop in the NarrativeEditor (parent) to tell this component (child) what has changed,
    //this component senses the change in prop values and updates, BUT it does not receive the current prop values
    //So as a workaround, I save that info in the NarrativeEditor in LocalStorage and then read it here and clear it out
    let cookies = new Cookies()
    let val = cookies.get("setEditFromExplorer")
    if (val && typeof val.toggleViewFromExplorer === "boolean") {
      if (val.toggleViewFromExplorer !== editParagraph) {
        setEditParagraph(val.toggleViewFromExplorer)
      }
    }

    cookies.set("setEditFromExplorer", {})
  }

  const { id } = useParams()
  const { pathname, search } = useLocation()
  let currentNarrativeId = +id
  if (!currentNarrativeId) {
    const searchParams = new URLSearchParams(search)
    currentNarrativeId = +searchParams.get("narrativeId")
  }

  const paragraph_Id = props.paragraph?.id
  const alertMessage = `Warning: Paragraph ${paragraph_Id} has Trigger IDs that need to be synced. Run Trigger ID sync now.`

  function handleSubmitResponse(paragraphId, submittedParagraph) {
    const isNewParagraph = !props.paragraph.id
    props.savedParagraph?.(paragraphId)
    !isNewParagraph && props.updateNarrativeParagraphs?.(submittedParagraph)

    setIsSnackbarOpen(false)
    setLoadingGeneral(false)

    if (
      (props.handleReload && isNewParagraph) ||
      form.libraryOrPositionChange
    ) {
      const viewKey = paragraphId
        ? `paragraphid-${paragraphId}`
        : `element-${form.formState?.contentBlock}`
      props.handleReload(viewKey)
    }
  }

  const handleCancelForm = () => {
    if (props.createMode) {
      history.push(
        `${pathname}?view=${
          narrative?.isLibrary ? "library" : "output"
        }_element-content`
      )
    } else {
      setEditParagraph(false)
    }
  }

  const toggleParagraph = () => {
    setEditParagraph(prev => !prev)
  }

  const toggleTable = () => {
    setTableView(prev => !prev)
    let cookies = new Cookies()
    let openParagraphsString = cookies.get("open-paragraphs") || "[]"
    let openParagraphs = JSON.parse(openParagraphsString)
    if (openParagraphs.includes(paragraph_Id)) {
      openParagraphs = openParagraphs.filter(value => value !== paragraph_Id)
    } else {
      openParagraphs.push(paragraph_Id)
    }
    cookies.set("open-paragraphs", JSON.stringify(openParagraphs), {
      path: "/"
    })
  }

  const processBulkMessage = msg => {
    let saving = {}
    let saved = {}
    let failed = {}
    let savedLength = 0
    let savingLength = 0
    let failedLength = 0
    let tmpBulkState = bulkAlertMessage
    let failedArray = []
    Object.keys(msg).forEach(function (key) {
      if (!isNaN(key)) {
        tmpBulkState[key] = tmpBulkState[key] ? tmpBulkState[key] : {}
        tmpBulkState[key][msg[key]] = msg.type ? msg.type : true
      }
    })
    Object.keys(tmpBulkState).forEach(function (key) {
      if (!isNaN(key)) {
        if (tmpBulkState[key].saving) {
          saving[key] = true
          savingLength += 1
        }
        if (tmpBulkState[key].saved) {
          saved[key] = true
          savedLength += 1
        }
        if (tmpBulkState[key].failed) {
          failed[key] = tmpBulkState[key].failed
          failedLength += 1
          failedArray.push(
            JSON.parse(
              `{"id" :"${key}", "error":"${tmpBulkState[key].failed}"}`
            )
          )
        }
      }
    })
    tmpBulkState.failed = failedArray
    tmpBulkState.progress = parseInt((savedLength / savingLength) * 100)

    tmpBulkState.totals = {
      saved: savedLength,
      saving: savingLength,
      failed: failedLength,
      finished: savingLength - (savedLength + failedLength) === 0
    }
    setBulkAlertMessage(tmpBulkState)
    setBulkAlertMessageActive(true)
  }

  const handleHistoryRollback = newValue => {
    form.setFormState(prev => ({ ...prev, ...newValue }))
  }

  const handleSnackClose = () => {
    setIsSnackbarOpen(false)
  }

  const renderSentenceTableContent = () => {
    const { failed, totals, progress } = bulkAlertMessage
    return (
      <>
        <SentenceTable
          narrative={narrative}
          paragraph={props.paragraph}
          handleReload={props.handleReload}
          updateNarrativeParagraphs={paragraph =>
            props.updateNarrativeParagraphs &&
            props.updateNarrativeParagraphs(paragraph)
          }
          bulkAlertMessage={val => {
            processBulkMessage(val)
          }}
          isAlertActive={() => {
            setBulkAlertMessageActive(true)
          }}
          allowUploads={!props.searchEditorMode}
        />
        {bulkAlertMessageActive && (
          <div id="myProgressParagraph" className={classes.progressBar}>
            {progress === 100 && (
              <div className={classes.finishedMsg}>Actions completed!</div>
            )}
            {progress !== 100 && totals.finished && (
              <div
                className={classes.failedListMsg}
                onClick={() => {
                  setBulkAlertMessageActive(true)
                }}
              >
                Completed with errors
              </div>
            )}
            {progress !== 100 && !totals.finished && (
              <div className={classes.progressingMsg}>
                Actions in progress...
                <br />
                {totals.saved} of {totals.saving}
                {totals.saving && <br />}
              </div>
            )}
            <Box display="flex" alignItems="center" width="100%">
              <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" value={progress || 0} />
              </Box>
              <Box minWidth={35}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ fontWeight: "bold" }}
                >
                  {progress}%
                </Typography>
              </Box>
            </Box>
            {totals.failed > 0 && !totals.finished && (
              <div className={classes.failedMsg}>
                {totals.failed} failed action
                {totals.failed > 1 ? "s" : ""}
              </div>
            )}
          </div>
        )}
        {progress !== 100 && totals?.finished && (
          <Modal
            open={totals.finished}
            onClose={() => {
              setBulkAlertMessage({})
              setBulkAlertMessageActive(false)
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className={classes.errorModal}>
              <h3 id="server-modal-title">Here are the failed requests</h3>
              <div id="server-modal-description">
                <ul>
                  {failed &&
                    failed.map((n, index) => (
                      <li key={index}>
                        ID #{n.id} --- {n.error} error
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </Modal>
        )}
        {progress === 100 && (
          <Modal
            open={!!progress}
            onClose={() => {
              setBulkAlertMessage({})
              setBulkAlertMessageActive(false)
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className={classes.errorModal}>
              <h2 id="server-modal-title">Success!</h2>
              <div id="server-modal-description">
                All {totals.saving} actions were executed correctly
              </div>
            </div>
          </Modal>
        )}
      </>
    )
  }

  const renderSentencePreviewContent = () => {
    let content = null
    if (tableView) {
      content = renderSentenceTableContent()
    } else {
      const sentences = sentencesSorted() || []
      content = (
        <Accordion
          noIcon
          overrideStyle={{ accordionSummary: { cursor: "default" } }}
          active={sentences.length > 0 ? 0 : null}
          collapses={[
            {
              title: `${name || "n/a"} block (total sentences: ${
                sentences.length
              })`,
              content: (
                <div>
                  {sentences?.map((sentence, index) => (
                    <SentencePreview
                      outlineBlock={props.hidePosition}
                      key={`${sentence.id}-${index}-${sentences.length}`}
                      sentence={sentence}
                      searchString={searchString}
                      triggers={triggers}
                    />
                  ))}
                </div>
              )
            }
          ]}
        />
      )
    }
    return content
  }

  const { triggers, buildFromOutline } = narrative
  const { contentBlock, name } = form.formState

  const allowPositionChange = !(buildFromOutline || props.hidePosition)

  const renderTypeExists =
    contentBlock === "content" ||
    contentBlock === "library" ||
    contentBlock === "bottomparagraph" ||
    contentBlock === "introparagraph"

  const showLibraryParagraphFields =
    narrative?.libraryNarrative_Ids &&
    !props.isLibraryParagraph &&
    !narrative?.isLibrary

  return (
    <div style={{ border: "1px solid #dddddd" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={isSnackbarOpen}
        key="ChangeLogSnack"
        message={alertMessage}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      />
      {!props.createMode && (
        <ParagraphEditorToolbar
          currentNarrativeId={currentNarrativeId}
          paragraph={props.paragraph}
          searchEditorMode={props.searchEditorMode}
          togglePreviewDrawer={props.togglePreviewDrawer}
          toggleTable={toggleTable}
          toggleParagraph={toggleParagraph}
          openParagraph={() => setEditParagraph(true)}
          form={form}
          ownerListForOrg={props.ownerListForOrg}
          tableView={tableView}
        />
      )}
      <Collapse in={props.createMode || editParagraph}>
        <ParagraphEditorAdvanced
          allowPositionChange={allowPositionChange}
          renderTypeExists={renderTypeExists}
          showLibraryParagraphFields={showLibraryParagraphFields}
          initialParagraph={props.paragraph}
          handleSubmitResponse={handleSubmitResponse}
          currentNarrativeId={currentNarrativeId}
          primaryTags={props.primaryTags}
          hitExitParagraph={props.hitExitParagraph}
          secondaryTags={props.secondaryTags}
          formFactorTags={props.formFactorTags}
          thirdPartySpecificTags={props.thirdPartySpecificTags}
          handleCancelForm={handleCancelForm}
          createMode={props.createMode}
          form={form}
        />
      </Collapse>
      {!props.searchEditorMode &&
        !props.createMode &&
        renderSentencePreviewContent()}
      {!props.createMode && !searchString && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ParagraphRelatedParagraphs paragraph_Id={paragraph_Id} />
          </Grid>
          <Grid item xs={12}>
            <ParagraphChangeLog
              handleRollback={handleHistoryRollback}
              paragraph_Id={paragraph_Id}
            />
          </Grid>
        </Grid>
      )}
    </div>
  )
}

Paragraph.propTypes = {
  paragraph: PropTypes.object,
  ownerListForOrg: PropTypes.array,
  editParagraph: PropTypes.bool,
  savedParagraph: PropTypes.func,
  hitExitParagraph: PropTypes.func,
  primaryTags: PropTypes.array,
  createMode: PropTypes.bool,
  handleReload: PropTypes.func,
  toggleViewFromExplorer: PropTypes.bool,
  isPreviewDrawerOpen: PropTypes.bool,
  togglePreviewDrawer: PropTypes.func,
  searchEditorMode: PropTypes.bool,
  isLibraryParagraph: PropTypes.bool
}

export default Paragraph
