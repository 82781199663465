import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

// MUI Components
import FormControl from "@mui/material/FormControl"
import FormHelperText from "@mui/material/FormHelperText"
import Grid from "@mui/material/Grid"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import TextField from "@mui/material/TextField"

import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers"

import withStyles from "@mui/styles/withStyles"

// Custom Components
import GridItem from "components/Grid/GridItem"
import Button from "components/CustomButtons/Button"
import AssociateOrganizations from "components/Affiliate/AssociateOrganizations"

// Utils
import { urlValidation } from "tools/ValidationHelpers"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useStore } from "contexts/rootContext"
import { useObserver } from "mobx-react"
import AffiliateDAO from "daos/affiliateDAO"

dayjs.extend(utc)

const styles = theme => ({
  headings: {
    display: "inline",
    fontSize: "1.5rem",
    fontWeight: 400
  },
  checkboxLabel: {
    "& .MuiFormControlLabel-label": {
      color: "#000",
      fontWeight: 400
    }
  },
  subtitle: {
    display: "inline",
    color: "#888"
  },
  requiredMsg: {
    color: "#c00",
    fontWeight: "bold",
    backgroundColor: "#f7f7f7"
  },
  formContentContainer: {
    border: "1px solid #AAA",
    _margin: "15px",
    padding: "40px 0 10px 0",
    background: "#fff",
    borderRadius: "20px",
    _minWidth: "1200px",
    overflowX: "auto"
  },
  contractSelect: {
    minWidth: 180
  }
})

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    getAffiliateContract: store.affiliateStore.getAffiliateContract,
    getAffiliateOrganizations: store.affiliateStore.getAffiliateOrganizations,
    getCTAManagerConfig: store.appConfigurationStore.getCTAManagerConfig,
    shouldDisplayCTAManagerV2:
      store.appConfigurationStore.shouldDisplayCTAManagerV2
  }))
}

function AffiliateContract(props) {
  // Variable Assignment
  const {
    classes,
    contract,
    formErrors,
    associatedOrganizations,
    organizations,
    addingContract
  } = props

  const {
    getCTAManagerConfig,
    shouldDisplayCTAManagerV2,
    getAffiliateContract,
    getAffiliateOrganizations
  } = useStoreData()
  const [myContract, setMyContract] = useState(contract)
  const [editingForm, setEditingForm] = useState(false)
  const [myFormErrors, setMyFormErrors] = useState(formErrors)
  const [chosenAffiliate] = useState(props.chosenAffiliate)
  const [associatedOrganizationsCopy, setAssociatedOrganizationsCopy] =
    useState(associatedOrganizations)

  const handleIsActiveChange = name => event => {
    let myVal =
      event.target.value === "true"
        ? true
        : event.target.value === "false"
        ? false
        : event.target.value
    const newContract = {
      ...myContract,
      [name]: myVal,
      editingContract: true
    }
    const errorObj = { ...myFormErrors }
    errorObj[name] = false
    errorObj.validationHasError = false
    setMyFormErrors(errorObj)
    setMyContract(newContract)
  }

  const handleContractChange = name => event => {
    const newInitializedFields = {
      ...myContract.initializedFields,
      [name]: false
    }
    const newContract = {
      ...myContract,
      disableSaveButton: false,
      [name]: event.target.value,
      initializedFields: newInitializedFields,
      editingContract: true
    }
    setMyContract(newContract)
  }

  const handleContractDateChange = (date, name) => {
    const newInitializedFields = {
      ...myContract.initializedFields,
      [name]: false
    }
    const newContract = {
      ...myContract,
      disableSaveButton: false,
      initializedFields: newInitializedFields,
      editingContract: true
    }
    if (!date) {
      newContract[name] = null
      setMyContract(newContract)
    } else if (!Object.is(date.$d.valueOf(), NaN)) {
      newContract[name] = date.$d.toISOString()
      setMyContract(newContract)
    }
  }

  const resetContract = () => {
    if (addingContract) {
      props.cancelledContract()
    }
    setMyContract(contract)
  }

  const formValidation = () => {
    const newFormValues = {
      ...myContract
    }
    let formErrors = {}
    let validationError = false

    if (typeof newFormValues.isActive !== "boolean") {
      formErrors.isActive = true
      validationError = true
    }

    if (!newFormValues.legalContractName) {
      formErrors.legalContractName = true
      validationError = true
    }
    if (!urlValidation(newFormValues.callToActionAffiliateContractUrl)) {
      formErrors.callToActionAffiliateContractUrl = true
      validationError = true
    }
    if (!myContract.startDate) {
      formErrors.startDate = true
      validationError = true
    }
    if (!myContract.endDate) {
      formErrors.endDate = true
      validationError = true
    }
    if (
      myContract.startDate &&
      myContract.endDate &&
      myContract.startDate > myContract.endDate
    ) {
      formErrors.endDateBad = true
      validationError = true
    }
    formErrors.validationHasError = validationError
    setMyFormErrors(formErrors)
    return validationError
  }

  const saveContract = () => {
    let validationError = formValidation()
    if (validationError) {
      setEditingForm(true)
      return
    }

    const newAffiliateContract = myContract.id
      ? {
          id: myContract.id,
          isActive: myContract.isActive,
          callToActionaffiliateId: chosenAffiliate.affiliateId,
          callToActionAffiliateContractUrl:
            myContract.callToActionAffiliateContractUrl,
          legalContractName: myContract.legalContractName,
          startDate: myContract.startDate,
          endDate: myContract.endDate
        }
      : {
          isActive: myContract.isActive,
          callToActionaffiliateId: chosenAffiliate.affiliateId,
          callToActionAffiliateContractUrl:
            myContract.callToActionAffiliateContractUrl,
          legalContractName: myContract.legalContractName,
          startDate: myContract.startDate,
          endDate: myContract.endDate
        }
    if (shouldDisplayCTAManagerV2) {
      if (!myContract.id) {
        AffiliateDAO.saveNewAffiliateContract(newAffiliateContract)
          .then(response => {
            if (typeof response === "object") {
              props.updateContractList(response)
              props.resultOfSave(
                true,
                `"${response.legalContractName}" has been Created with id="${response.id}"`,
                response.id
              )
            } else if (typeof response === "number") {
              props.resultOfSave(
                true,
                `Contract "${newAffiliateContract.legalContractName}" with ID=${response} has been Created`,
                response
              )
            } else {
              props.resultOfSave(false, response)
            }
            getAffiliateContract()
          })
          .catch(error => {
            console.error(`saveContract Error`, error)
            props.resultOfSave(false, error)
          })
      } else {
        AffiliateDAO.updateAffiliateContract(
          newAffiliateContract,
          myContract.id
        )
          .then(response => {
            if (typeof response === "object") {
              props.updateContractList(response)
              props.resultOfSave(
                true,
                `"${response.legalContractName}" has been saved with id="${response.id}"`,
                response.id
              )
            } else if (typeof response === "number") {
              props.resultOfSave(
                true,
                `Contract "${newAffiliateContract.legalContractName}" with ID=${response} has been updated`,
                response
              )
            } else {
              props.resultOfSave(false, response)
            }
            getAffiliateContract()
          })
          .catch(error => {
            console.error(`saveContract Error`, error)
            props.resultOfSave(false, error)
          })
      }
    }
  }

  const getOrgName = id => {
    let org =
      (organizations && organizations.filter(itm => itm.id === id)) || []
    if (org[0]) {
      return org[0].name
    } else {
      return id
    }
  }

  const saveNewAffiliateOrganization = async (
    organizationId,
    associationId,
    splitPercentage
  ) => {
    const affiliateOrganizationObj = {
      isArchived: false,
      organizationId: organizationId,
      costPerActionToAffiliateSplit: splitPercentage.toString(),
      callToActionAffiliateContractId: myContract.id
    }
    if (shouldDisplayCTAManagerV2) {
      if (!associationId) {
        AffiliateDAO.saveNewAffiliateOrganization(affiliateOrganizationObj)
          .then(response => {
            if (typeof response === "object") {
              props.updateAssociatedOrganization(response)
              props.resultOfSave(
                true,
                `Organization "${getOrgName(
                  organizationId
                )}" with split "${splitPercentage}" has been saved with id="${
                  response.id
                }"`
              )
            } else if (typeof response === "number") {
              props.resultOfSave(
                true,
                `Organization "${getOrgName(
                  organizationId
                )}" with split "${splitPercentage}" has been saved with id="${response}"`
              )
            } else {
              props.updateAssociatedOrganization(
                associatedOrganizationsCopy.find(
                  org => org.id === associationId
                )
              )
              props.resultOfSave(false, `Save did not succeeed`)
            }
            getAffiliateOrganizations()
          })
          .catch(err => {
            console.error(
              `Error calling AffiliateDAO.saveNewAffiliateContract`,
              err
            )
          })
      } else {
        AffiliateDAO.updateAffiliateOrganization(
          affiliateOrganizationObj,
          associationId
        )
          .then(response => {
            if (typeof response === "object") {
              props.updateAssociatedOrganization(response)
              props.resultOfSave(
                true,
                `Organization "${getOrgName(
                  organizationId
                )}" with split "${splitPercentage}" has been saved with id="${
                  response.id
                }"`
              )
            } else if (typeof response === "number") {
              props.resultOfSave(
                true,
                `Organization "${getOrgName(
                  organizationId
                )}" with split "${splitPercentage}" has been saved with id="${response}"`
              )
            } else {
              props.updateAssociatedOrganization(
                associatedOrganizationsCopy.find(
                  org => org.id === associationId
                )
              )
              props.resultOfSave(false, `Save did not succeeed`)
            }
            getAffiliateOrganizations()
          })
          .catch(err => {
            console.error(
              `Error calling AffiliateDAO.updateAffiliateOrganization`,
              err
            )
          })
      }
    }
  }

  const handleNewAffiliateOrganization = () => {
    const arr = [...associatedOrganizationsCopy]
    // If there is an empty object (i.e. a new association), return out.
    if (arr.find(obj => Object.keys(obj).length === 0)) {
      return
    }
    arr.push({})
    setAssociatedOrganizationsCopy(arr)
  }

  const {
    legalContractName,
    callToActionAffiliateContractUrl,
    isActive,
    editingContract,
    startDate,
    endDate
  } = myContract

  useEffect(() => {
    const fetchConfig = async () => {
      await getCTAManagerConfig()
    }
    fetchConfig()
  }, [shouldDisplayCTAManagerV2])

  useEffect(() => {
    setMyContract(contract)
  }, [props.contract.startDate])

  useEffect(() => {
    setAssociatedOrganizationsCopy(associatedOrganizations)
  }, [associatedOrganizations])

  useEffect(() => {
    //Do form validation whenever the form values change
    if (editingForm) {
      formValidation()
    }
  }, [myContract])

  const organizationsAndPropertiesCombined = []
  organizations.forEach(org => {
    organizationsAndPropertiesCombined.push({ id: org.id, name: org.name })
    if (
      org.properties &&
      org.properties.length &&
      org.properties[0] &&
      org.properties[0].id > 0
    ) {
      org.properties.forEach(prp => {
        organizationsAndPropertiesCombined.push({ id: prp.id, name: prp.name })
      })
    }
  })

  return (
    <>
      <>
        <div
          style={{
            paddingBottom: "0px",
            position: "relative",
            top: "12px",
            left: "20px"
          }}
        >
          <span
            style={{
              display: "inline-block",
              fontSize: "14px",
              background: "#fff",
              padding: "0 5px",
              fontWeight: "bold"
            }}
          >
            Contract Details
          </span>
        </div>
      </>
      <Grid container className={classes.formContentContainer}>
        <GridItem xs={12} sm={12}>
          <Grid container>
            <GridItem>
              <FormControl
                variant="outlined"
                required
                error={formErrors.isActive}
                className={classes.contractSelect}
                size="small"
              >
                <InputLabel id="contract-status-select-label">
                  Contract Status
                </InputLabel>
                <Select
                  label="Contract Status"
                  labelId="contract-status-select-label"
                  id="isActive"
                  variant="outlined"
                  value={typeof isActive === "boolean" ? isActive : ""}
                  onChange={handleIsActiveChange("isActive")}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Completed</MenuItem>
                </Select>
                {myFormErrors.isActive && (
                  <FormHelperText className={classes.requiredMsg}>
                    Contract Status is required!
                  </FormHelperText>
                )}
              </FormControl>
            </GridItem>
          </Grid>
          <Grid container>
            <GridItem style={{ margin: "20px 0", width: "330px" }}>
              <TextField
                type="text"
                id="legalContractName"
                label="Legal Contract Name"
                value={legalContractName || ""}
                onChange={handleContractChange("legalContractName")}
                margin="normal"
                size="small"
                variant="outlined"
                required={true}
                inputProps={{ tabIndex: "1" }}
                style={{ width: "300px", margin: "0 30px 0 0" }}
              />
              {myFormErrors.legalContractName && (
                <FormHelperText className={classes.requiredMsg}>
                  Contract Name is required!
                </FormHelperText>
              )}
            </GridItem>
            <GridItem style={{ margin: "20px 0", width: "330px" }}>
              <TextField
                type="text"
                id="callToActionAffiliateContractUrl"
                label="Contract URL"
                value={callToActionAffiliateContractUrl || ""}
                onChange={handleContractChange(
                  "callToActionAffiliateContractUrl"
                )}
                margin="normal"
                size="small"
                variant="outlined"
                required={true}
                inputProps={{ tabIndex: "2" }}
                style={{ width: "300px", margin: "0 30px 0 0" }}
              />
              {myFormErrors.callToActionAffiliateContractUrl && (
                <FormHelperText className={classes.requiredMsg}>
                  A valid Contract URL is required!
                </FormHelperText>
              )}
            </GridItem>
          </Grid>
          <Grid container>
            <GridItem style={{ margin: "10px 0 0 0", width: "330px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  autoOk
                  id="startDate"
                  variant="inline"
                  size="small"
                  inputVariant="outlined"
                  label={"Contract Term Start"}
                  format="MM/DD/YYYY"
                  value={dayjs(startDate || null)}
                  InputAdornmentProps={{ position: "end" }}
                  onChange={date => handleContractDateChange(date, "startDate")}
                  inputProps={{ tabIndex: "3" }}
                  style={{ width: "300px", margin: "0 30px 0 0" }}
                />
              </LocalizationProvider>
              {myFormErrors.startDate && (
                <FormHelperText className={classes.requiredMsg}>
                  A Start Date is required!
                </FormHelperText>
              )}
            </GridItem>
            <GridItem style={{ margin: "10px 0 0 0", width: "330px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  size="small"
                  label={"Contract Term End"}
                  format="MM/DD/YYYY"
                  id="endDate"
                  value={dayjs(endDate || null)}
                  InputAdornmentProps={{ position: "end" }}
                  onChange={date => handleContractDateChange(date, "endDate")}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  inputProps={{ tabIndex: "4" }}
                  style={{ width: "300px", margin: "0 30px 0 0" }}
                />
              </LocalizationProvider>
              {myFormErrors.endDate && (
                <FormHelperText className={classes.requiredMsg}>
                  An End Date is required!
                </FormHelperText>
              )}
              {myFormErrors.endDateBad && (
                <FormHelperText className={classes.requiredMsg}>
                  End Date must be after Start Date!
                </FormHelperText>
              )}
            </GridItem>
          </Grid>
          <Grid
            container
            justifyContent="center"
            style={{ display: "flex", margin: "20px 0 0 0" }}
            className={classes.myFlex}
          >
            <GridItem xs={12} sm={1} style={{ textAlign: "center" }}>
              {" "}
            </GridItem>
            <GridItem xs={12} sm={10} style={{ textAlign: "center" }}>
              <Button
                onClick={() => {
                  setMyFormErrors(formErrors)

                  resetContract()
                }}
                color="primary"
                style={{ padding: "12px 60px", margin: "0 10px 0 0" }}
                disabled={
                  !addingContract &&
                  !chosenAffiliate.editingAffiliate &&
                  !editingContract &&
                  !startDate &&
                  !endDate
                }
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  saveContract()
                }}
                color="primary"
                style={{ padding: "12px 60px" }}
                disabled={myFormErrors.validationHasError}
              >
                Save
              </Button>
            </GridItem>
            <GridItem xs={12} sm={1} style={{ textAlign: "center" }}>
              {" "}
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
      <Grid container justifyContent="center" style={{ marginTop: "1rem" }}>
        <GridItem xs={12} sm style={{ textAlign: "left" }}>
          <Button
            onClick={handleNewAffiliateOrganization}
            color="primary"
            disabled={!myContract?.id}
          >
            + Associate Organizations
          </Button>
        </GridItem>
      </Grid>
      {associatedOrganizationsCopy?.map(association => (
        <AssociateOrganizations
          key={`contract-${contract.id}-org-${association.organizationId}`}
          associationId={association.id}
          organizationId={association.organizationId}
          splitPercentage={association.costPerActionToAffiliateSplit}
          organizations={organizationsAndPropertiesCombined}
          associatedOrganizations={associatedOrganizationsCopy}
          handleSave={saveNewAffiliateOrganization}
          handleNewAffiliateOrganization={handleNewAffiliateOrganization}
        />
      ))}
    </>
  )
}

AffiliateContract.propTypes = {
  contract: PropTypes.object,
  formErrors: PropTypes.object,
  chosenAffiliate: PropTypes.object,
  associatedOrganizations: PropTypes.array,
  callToActionAffiliateContract: PropTypes.array,
  addingContract: PropTypes.bool,
  organizations: PropTypes.array,
  cancelledContract: PropTypes.func,
  updateContractList: PropTypes.func,
  updateAssociatedOrganization: PropTypes.func,
  resultOfSave: PropTypes.func
}

export default withStyles(styles)(AffiliateContract)
