import { List, fromJS } from "immutable"
import { action, observable, decorate } from "mobx"
import querybuilderDAO from "../daos/querybuilderDAO"

export default class QueryBuilderStore {
  rootStore
  loading
  queries

  constructor(rootStore) {
    this.rootStore = rootStore
    this.dehydrate()
  }

  /**
   * Reactions
   */
  getQueries = async () => {
    try {
      this.setLoading(true)

      const queries = fromJS(await querybuilderDAO.getQueries())
      // The end date/time can be null, so change it to be
      this.setQueries(queries.get("content", List()))
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoading(false)
    }
  }

  /**
   * Internal Actions
   */
  dehydrate() {
    this.setLoading(false)
    this.setQueries(List())
  }

  setQueries(value) {
    this.queries = value.sortBy(o => o.get("id", ""))
  }

  setLoading(value) {
    this.loading = value
  }
}

decorate(QueryBuilderStore, {
  // observables
  loading: observable,
  queries: observable,

  // actions
  setLoading: action.bound,
  setQueries: action.bound
})
