import { globalSettings } from "variables/general.jsx"
import { get, put } from "../tools/request"

export default class AppConfigDAO {
  static getAppConfig = async featureflagname => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/runtimesettings/getfeatureflag/api/${featureflagname}`
    )
    return await res.json()
  }

  static getAppMessage = async featureflagname => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/runtimesettings/getfeatureflagmessage/api/${featureflagname}`
    )
    return await res.json()
  }

  static getRenderStatus = async () => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/runtimesettings/renderingstatus`
    )
    return await res.json()
  }

  static setRenderStatus = async (domain, name, bool) => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/runtimesettings/setfeatureflag`,
      null,
      {
        domain: domain,
        name: name,
        isActive: bool,
        message: ""
      }
    )
    return await res.json()
  }
}
