import { makeStyles } from "@mui/styles"
import {
  Box,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  Switch,
  Typography
} from "@mui/material"
import PropTypes from "prop-types"

const useStyles = makeStyles(theme => ({
  elementMenu: {
    height: 400,
    maxHeight: 500,
    overflow: "auto",
    padding: theme.spacing(1, 1, 0, 1)
  }
}))

const ElementSelectionList = ({
  elementOptions,
  activeElementIds = [],
  handleToggleElement
}) => {
  const classes = useStyles()

  return (
    <Box className={classes.elementMenu}>
      <Typography variant="subtitle2">Filter Blocks</Typography>
      <List>
        {elementOptions.map(element => (
          <ListItem key={element.id}>
            <ListItemIcon>
              <FormControlLabel
                control={
                  <Switch
                    checked={activeElementIds.includes(element.id)}
                    onChange={() => handleToggleElement(element.id)}
                    color="primary"
                    size="small"
                  />
                }
                label={element.name}
              />
            </ListItemIcon>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

ElementSelectionList.propTypes = {
  elementOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeElements: PropTypes.arrayOf(
    PropTypes.oneOf([PropTypes.string, PropTypes.number])
  ),
  handleToggleElement: PropTypes.func.isRequired
}

export default ElementSelectionList
