import React from "react"
import PropTypes from "prop-types"
import PerfectScrollbar from "perfect-scrollbar"
import { NavLink } from "react-router-dom"
import cx from "classnames"
import withStyles from "@mui/styles/withStyles"
import Drawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Collapse from "@mui/material/Collapse"
import Icon from "@mui/material/Icon"
import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle"
import { observer, inject } from "mobx-react"
import OrganizationsMenu from "../Organizations/OrganizationsMenu"
import Gravatar from "react-gravatar"
import { globalSettings } from "variables/general.jsx"
import Box from "@mui/material/Box"

let ps

const useStore = component =>
  inject(({ store }) => ({
    organizationId: store.organizationStore.organizationId,
    isAccountDetailsLoaded: store.uiStore.isAccountDetailsLoaded,
    isPermissionsLoaded: store.uiStore.isPermissionsLoaded,
    account: store.accountStore.account,
    accountPermissions: store.accountStore.accountPermissions
  }))(observer(component))

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false
      })
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy()
    }
  }

  render() {
    const { className, user, headerLinks, links, orgMenu } = this.props
    return (
      <div className={className} ref="sidebarWrapper">
        {user}
        {headerLinks}
        {orgMenu}
        {links}
      </div>
    )
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openAvatar: false,
      openComponents: this.activeRoute("/components"),
      openForms: this.activeRoute("/forms"),
      openTables: this.activeRoute("/tables"),
      openMaps: this.activeRoute("/maps"),
      openPages: this.activeRoute("-page"),
      miniActive: true
    }
    this.activeRoute.bind(this)
  }

  onComp
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false
  }
  openCollapse(collapse) {
    let st = {}
    st[collapse] = !this.state[collapse]
    this.setState(st)
  }

  render() {
    const {
      classes,
      color,
      logo,
      image,
      logoText,
      routes,
      bgColor,
      rtlActive
    } = this.props
    const itemText = `${classes.itemText} ${cx({
      [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
      [classes.itemTextMiniRTL]:
        rtlActive && this.props.miniActive && this.state.miniActive,
      [classes.itemTextRTL]: rtlActive
    })}`
    const collapseItemText = `${classes.collapseItemText} ${cx({
      [classes.collapseItemTextMini]:
        this.props.miniActive && this.state.miniActive,
      [classes.collapseItemTextMiniRTL]:
        rtlActive && this.props.miniActive && this.state.miniActive,
      [classes.collapseItemTextRTL]: rtlActive
    })}`
    const userWrapperClass = `${classes.user} ${cx({
      [classes.whiteAfter]: bgColor === "white"
    })}`
    // const userWrapperClass = `${classes.user} ${cx({
    //   [classes.tealAfter]: bgColor === "dstealAccent"
    // })}`
    const caret = `${classes.caret} ${cx({
      [classes.caretRTL]: rtlActive
    })}`
    const collapseItemMini = `${classes.collapseItemMini} ${cx({
      [classes.collapseItemMiniRTL]: rtlActive
    })}`
    const photo = `${classes.photo} ${cx({
      [classes.photoRTL]: rtlActive
    })}`
    let accountName = "anonymous"
    let accountEmail = null
    if (this.props.isAccountDetailsLoaded && this.props.isPermissionsLoaded) {
      let userAccount = this.props.account
      if (userAccount) {
        accountName =
          userAccount.firstName || userAccount.username || userAccount.email
        accountEmail = userAccount.email
      }
    } else {
      return null
    }

    let user = (
      <div className={userWrapperClass}>
        <div className={photo}>
          <Gravatar
            email={accountEmail}
            className={classes.avatarImg}
            default="mp"
            size={35}
          />
        </div>
        <List className={classes.list}>
          <ListItem className={`${classes.item} ${classes.userItem}`}>
            <NavLink
              to={"#"}
              className={`${classes.itemLink} ${classes.userCollapseButton}`}
              onClick={() => this.openCollapse("openAvatar")}
            >
              <ListItemText
                primary={rtlActive ? "تانيا أندرو" : `Welcome, ${accountName}`}
                secondary={
                  <b
                    className={`${caret} ${classes.userCaret} ${
                      this.state.openAvatar ? classes.caretActive : ""
                    }`}
                  />
                }
                disableTypography={true}
                className={`${itemText} ${classes.userItemText}`}
              />
            </NavLink>
            <Collapse in={this.state.openAvatar} unmountOnExit>
              <List className={`${classes.list} ${classes.collapseList}`}>
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to="/portal/accountprofile"
                    className={`${classes.itemLink} ${classes.userCollapseLinks}`}
                  >
                    <span className={collapseItemMini}>
                      {rtlActive ? "مع" : "PR"}
                    </span>
                    <ListItemText
                      primary={rtlActive ? "ملفي" : "Profile"}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to="/portal/accountpermissions"
                    className={`${classes.itemLink} ${classes.userCollapseLinks}`}
                  >
                    <span className={collapseItemMini}>
                      {rtlActive ? "و" : "P"}
                    </span>
                    <ListItemText
                      primary={rtlActive ? "إعدادات" : "Permissions"}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
                {this.props.isPermissionsLoaded &&
                  this.props.accountPermissions.CanSeeAccountNotifications ===
                    "Yes" && (
                    <ListItem className={classes.collapseItem}>
                      <NavLink
                        to="/portal/accountnotifications"
                        className={`${classes.itemLink} ${classes.userCollapseLinks}`}
                      >
                        <span className={collapseItemMini}>
                          {rtlActive ? "و" : "N"}
                        </span>
                        <ListItemText
                          primary={rtlActive ? "إعدادات" : "Notifications"}
                          disableTypography={true}
                          className={collapseItemText}
                        />
                      </NavLink>
                    </ListItem>
                  )}
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to="/logout"
                    className={`${classes.itemLink} ${classes.userCollapseLinks}`}
                  >
                    <span className={collapseItemMini}>
                      {rtlActive ? "و" : "L"}
                    </span>
                    <ListItemText
                      primary={rtlActive ? "إعدادات" : "Log Out"}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
              </List>
            </Collapse>
          </ListItem>
        </List>
      </div>
    )

    let orgMenu = (
      <OrganizationsMenu
        showOrgMenu={this.props.organizationId !== ""}
        organizationId={this.props.organizationId}
        classes={{
          input: {
            padding: 0,
            margin: "10px 15px 0",
            backgroundColor: "white",
            width: "89%"
          }
        }}
      />
    )

    let links = (
      <List className={classes.list} aria-label="Left Nav Menu">
        {routes.map((prop, key) => {
          if (prop.redirect || prop.hideInNav) {
            return null
          }
          if (this.props.isPermissionsLoaded) {
            if (
              prop.name === "Dashboard" &&
              this.props.accountPermissions.CanSeeDashboard !== "Yes"
            ) {
              return null
            }
            if (prop.name === "QA") {
              if (this.props.accountPermissions.CanSeeQADashboard !== "Yes") {
                return null
              } else {
                //Check Views
                if (
                  this.props.accountPermissions.CanSeeReportBuilder !== "Yes"
                ) {
                  prop.views = prop.views.filter(
                    v => v.name !== "Report Builder"
                  )
                }
                if (
                  this.props.accountPermissions.CanSeeReportViewer !== "Yes"
                ) {
                  prop.views = prop.views.filter(
                    v => v.name !== "Report Viewer"
                  )
                }
                if (this.props.accountPermissions.CanSeeQAReports !== "Yes") {
                  prop.views = prop.views.filter(v => v.name !== "QA Reports")
                }
              }
            }

            if (
              prop.name === "Wiki" &&
              this.props.accountPermissions.CanSeeWiki !== "Yes"
            ) {
              return null
            }
            if (
              prop.name === "Search" &&
              this.props.accountPermissions.CanSeeSearch !== "Yes"
            ) {
              return null
            }

            if (
              prop.name === "Narrative Manager" &&
              this.props.accountPermissions.CanSeeNarrativeBuilder !== "Yes"
            ) {
              return null
            }
            if (
              prop.name === "Feed Manager" &&
              this.props.accountPermissions.CanSeeFeedManager !== "Yes"
            ) {
              return null
            }
            if (
              prop.name === "Account Manager" &&
              this.props.accountPermissions.CanSeeAccountManager !== "Yes"
            ) {
              return null
            }
            if (
              prop.name === "Knowledge Base" &&
              this.props.accountPermissions.CanSeeSiteInfo !== "Yes"
            ) {
              return null
            }
            if (
              prop.name === "File Manager" &&
              this.props.accountPermissions.CanSeeFileManager !== "Yes"
            ) {
              return null
            }
            if (
              prop.name === "Sports Manager" &&
              this.props.accountPermissions.CanSeeSportsManager !== "Yes"
            ) {
              return null
            }
            if (prop.name === "Tools") {
              if (this.props.accountPermissions.CanSeeTools !== "Yes") {
                return null
              } else {
                //Check Views
                if (
                  this.props.accountPermissions.CanSeeCacheManager !== "Yes"
                ) {
                  prop.views = prop.views.filter(
                    v => v.name !== "Cache Manager"
                  )
                }
              }
            }

            if (
              prop.name === "SiteInfo" &&
              this.props.accountPermissions.CanSeeSiteInfo !== "Yes"
            ) {
              return null
            }
            if (
              prop.name === "Sandbox Pages" &&
              this.props.accountPermissions.CanSeeSandboxPages !== "Yes"
            ) {
              return null
            }
            if (
              prop.name === "Sandbox" &&
              this.props.accountPermissions.CanSeeSandbox !== "Yes"
            ) {
              return null
            }
            if (
              prop.name === "FAQs" &&
              this.props.accountPermissions.CanSeeFAQs !== "Yes"
            ) {
              return null
            }
            if (
              prop.name === "Internal Web APIs" &&
              this.props.accountPermissions.CanSeeInternalWebAPI !== "Yes"
            ) {
              return null
            }
            if (
              prop.name === "External Web APIs" &&
              this.props.accountPermissions.CanSeeExternalWebAPI !== "Yes"
            ) {
              return null
            }
            if (
              prop.name === "Application Services" &&
              this.props.accountPermissions.CanSeeServicesList !== "Yes"
            ) {
              return null
            }
            if (
              prop.name === "Webhook Manager" &&
              this.props.accountPermissions.CanSeeWebhookManager !== "Yes"
            ) {
              return null
            }
          }
          if (prop.collapse) {
            const navLinkClasses = `${classes.itemLink} ${cx({
              [` ${classes.collapseActive}`]: this.activeRoute(prop.path)
            })}`
            const itemText = `${classes.itemText} ${cx({
              [classes.itemTextMini]:
                this.props.miniActive && this.state.miniActive,
              [classes.itemTextMiniRTL]:
                rtlActive && this.props.miniActive && this.state.miniActive,
              [classes.itemTextRTL]: rtlActive
            })}`
            const collapseItemText = `${classes.collapseItemText} ${cx({
              [classes.collapseItemTextMini]:
                this.props.miniActive && this.state.miniActive,
              [classes.collapseItemTextMiniRTL]:
                rtlActive && this.props.miniActive && this.state.miniActive,
              [classes.collapseItemTextRTL]: rtlActive
            })}`
            const itemIcon = `${classes.itemIcon} ${cx({
              [classes.itemIconRTL]: rtlActive
            })}`
            const caret = `${classes.caret} ${cx({
              [classes.caretRTL]: rtlActive
            })}`
            return (
              <ListItem key={key} className={classes.item}>
                <NavLink
                  to={"#"}
                  className={navLinkClasses}
                  onClick={() => this.openCollapse(prop.state)}
                >
                  <ListItemIcon className={itemIcon}>
                    {typeof prop.icon === "string" ? (
                      <Icon>{prop.icon}</Icon>
                    ) : (
                      <prop.icon />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={prop.name}
                    secondary={
                      <b
                        className={`${caret} ${
                          this.state[prop.state] ? classes.caretActive : ""
                        }`}
                      />
                    }
                    disableTypography={true}
                    className={itemText}
                  />
                </NavLink>
                <Collapse in={this.state[prop.state]} unmountOnExit>
                  <List className={`${classes.list} ${classes.collapseList}`}>
                    {prop.views.map((prop, key) => {
                      if (prop.redirect || prop.hideInNav) {
                        return null
                      }
                      const navLinkClasses = `${classes.collapseItemLink} ${cx({
                        [` ${classes[color]}`]: this.activeRoute(prop.path)
                      })}`
                      const collapseItemMini = `${
                        classes.collapseItemMini
                      } ${cx({
                        [classes.collapseItemMiniRTL]: rtlActive
                      })}`
                      return (
                        <ListItem key={key} className={classes.collapseItem}>
                          <NavLink to={prop.path} className={navLinkClasses}>
                            <span className={collapseItemMini}>
                              {prop.mini}
                            </span>
                            <ListItemText
                              primary={prop.name}
                              disableTypography={true}
                              className={collapseItemText}
                            />
                          </NavLink>
                        </ListItem>
                      )
                    })}
                  </List>
                </Collapse>
              </ListItem>
            )
          }
          const navLinkClasses = `${classes.itemLink} ${cx({
            [` ${classes[color]}`]: this.activeRoute(prop.path)
          })}`
          const itemText = `${classes.itemText} ${cx({
            [classes.itemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.itemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.itemTextRTL]: rtlActive
          })}`
          const itemIcon = `${classes.itemIcon} ${cx({
            [classes.itemIconRTL]: rtlActive
          })}`
          return prop.external ? (
            <ListItem key={key} className={classes.item}>
              <NavLink
                to={{ pathname: prop.path }}
                className={navLinkClasses}
                target="_blank"
              >
                <ListItemIcon className={itemIcon}>
                  {typeof prop.icon === "string" ? (
                    <Icon>{prop.icon}</Icon>
                  ) : (
                    <prop.icon />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={prop.name}
                  disableTypography={true}
                  className={itemText}
                />
              </NavLink>
            </ListItem>
          ) : (
            <ListItem key={key} className={classes.item}>
              <NavLink to={prop.path} className={navLinkClasses}>
                <ListItemIcon className={itemIcon}>
                  {typeof prop.icon === "string" ? (
                    <Icon>{prop.icon}</Icon>
                  ) : (
                    <prop.icon />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={prop.name}
                  disableTypography={true}
                  className={itemText}
                />
              </NavLink>
            </ListItem>
          )
        })}
      </List>
    )

    const logoNormal = `${classes.logoNormal} ${cx({
      [classes.logoNormalSidebarMini]:
        this.props.miniActive && this.state.miniActive,
      [classes.logoNormalSidebarMiniRTL]:
        rtlActive && this.props.miniActive && this.state.miniActive,
      [classes.logoNormalRTL]: rtlActive
    })}`
    const logoMini = `${classes.logoMini} ${cx({
      [classes.logoMiniRTL]: rtlActive
    })}`
    const logoClasses = `${classes.logo} ${cx({
      [classes.whiteAfter]: bgColor === "dstealAccent"
    })}`
    let brand = (
      <div className={logoClasses}>
        <a
          href={`${globalSettings.portalBaseUrl}/portal/dashboard/`}
          className={logoMini}
        >
          <img src={logo} alt="logo" className={classes.img} />
        </a>
        <a
          href={`${globalSettings.portalBaseUrl}/portal/dashboard/`}
          className={logoNormal}
        >
          {logoText}
        </a>
      </div>
    )
    const drawerPaper = `${classes.drawerPaper} ${cx({
      [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
      [classes.drawerPaperRTL]: rtlActive
    })}`
    const sidebarWrapper = `${classes.sidebarWrapper} ${cx({
      [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
      [classes.sidebarWrapperWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1
    })}`
    return (
      <div ref="mainPanel">
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <Drawer
            variant="temporary"
            anchor={rtlActive ? "left" : "right"}
            open={this.props.open}
            classes={{
              paper: `${drawerPaper} ${classes[`${bgColor}Background`]}`
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              links={links}
              orgMenu={orgMenu}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: `url(${image})` }}
              />
            ) : null}
          </Drawer>
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={rtlActive ? "right" : "left"}
            variant="permanent"
            open
            classes={{
              paper: `${drawerPaper} ${classes[`${bgColor}Background`]}`
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: `url(${image})` }}
              />
            ) : null}
          </Drawer>
        </Box>
      </div>
    )
  }
}

Sidebar.defaultProps = {
  bgColor: "blue"
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue", "dstealAccent", "teal"]),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose",
    "dstealAccent"
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  external: PropTypes.bool
}

export default useStore(withStyles(sidebarStyle)(Sidebar))
