import React from "react"
import withStyles from "@mui/styles/withStyles"
import PropTypes from "prop-types"

import IconButton from "@mui/material/IconButton"

import InfoIcon from "@mui/icons-material/Info"
import LinearProgress from "@mui/material/LinearProgress"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import DialogActions from "@mui/material/DialogActions"
// import DialogTitle from "@mui/material/DialogTitle"
// import DialogContent from "@mui/material/DialogContent"
// import DialogContentText from "@mui/material/DialogContentText"

import Button from "components/CustomButtons/Button.jsx"
import UploadButton from "components/CustomButtons/UploadButton.jsx"
import * as XLSX from "xlsx"
import Enums from "tools/Enums.js"
import { Cookies } from "tools/storage"
import Helpers from "tools/Helpers.js"
import NarrativeRenderScheduleDAO from "daos/narrativeRenderScheduleDAO"
import TableControlsNoToolbar from "components/NarrativeAdmin/Data/TableControlsNoToolbar"

import OrganizationDAO from "daos/organizationDAO"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import {
  renderBulkUploadInfo,
  renderOutputRefreshInfo,
  renderSampleRefreshInfo,
  columns,
  errorColumn
} from "tools/UploadRenderHelpers"

const styles = theme => ({
  resultsModal: {
    position: "absolute",
    width: "90vw",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "45%",
    left: "45%",
    transform: "translate(-45%, -45%)",
    _textAlign: "center",
    overflow: "auto",
    maxHeight: "95vh",
    "& h2": {
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      fontWeight: "500",
      fontSize: "1.42857rem",
      lineHeight: "1.6",
      letterSpacing: "0.0075em",
      textAlign: "left",
      margin: "0 0 0 35vw",
      padding: "0"
    },
    "& h6": {
      textAlign: "left",
      margin: "0 0 0 35vw"
    },
    "& ul.topList": {
      textAlign: "left",
      margin: "0 0 0 35vw",
      padding: "0"
    },
    "& p": {
      textAlign: "left",
      margin: "0 0 0 35vw",
      padding: "0"
    }
  },
  requiredMsg: {
    color: "#c00",
    fontWeight: "bold",
    backgroundColor: "#f7f7f7"
  },
  bulkUploadList: {
    "&.MuiListItem-dense": {
      paddingTop: "1px",
      paddingBottom: "0px"
    },
    "&.MuiListItem-gutters": {
      paddingLeft: "8px"
    },
    marginLeft: "2px",
    fontSize: ".9em",
    textAlign: "left"
  },
  leftJustify: {
    padding: "12px 12px 12px 2px"
  },
  errorList: {
    listStyleType: "none",
    textAlign: "left",
    margin: "0 0 0 35vw",
    padding: "0"
  }
})

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    setDialogInfoMessage: store.uiStore.setDialogInfoMessage,
    setDialogInfoOpen: store.uiStore.setDialogInfoOpen
  }))
}
function UploadRenderSchedule(props) {
  const { classes } = props
  const [resetButton, setResetButton] = React.useState(false)
  const [showResultsModal, setShowResultsModal] = React.useState(false)
  const [showAskToRenderModal, setShowAskToRenderModal] = React.useState(false)
  const [loadingRenderSchedule, setLoadingRenderSchedule] =
    React.useState(false)
  const [data, setData] = React.useState([])
  const [resultTableData, setResultTableData] = React.useState([])
  const [organizationFeedsAndNarratives, setOrganizationFeedsAndNarratives] =
    React.useState({})
  const descriptorString = "Bulk Upload Render Jobs"
  const [changedRows, setChangedRows] = React.useState([])
  const [malformedTSV, setMalformedTSV] = React.useState(false)
  const [wrongFeedOrg, setWrongFeedOrg] = React.useState(false)
  const [wrongNarrativeOrg, setWrongNarrativeOrg] = React.useState(false)
  const [sampleRefreshCase, setSampleRefreshCase] = React.useState(false)
  const [outputRefreshCase, setOutputRefreshCase] = React.useState(false)
  const [
    waitForOrgsFeedsAndDeliverableInfoToReturn,
    setWaitForOrgsFeedsAndDeliverableInfoToReturn
  ] = React.useState(false)
  const [orgsFeedsAndDeliverableData, setOrgsFeedsAndDeliverableData] =
    React.useState("")

  const [loadNarrativesAndFeeds, setLoadNarrativesAndFeeds] =
    React.useState(false)
  const [delayedOutputRefreshLoad, setDelayedOutputRefreshLoad] =
    React.useState(false)

  const [noValidRenders, setNoValidRenders] = React.useState(false)

  let cookies = new Cookies()
  const currentProperty = cookies.get("currentProperty") || ""

  const { setDialogInfoMessage, setDialogInfoOpen } = useStoreData()
  const [allErrorList, setAllErrorList] = React.useState([])

  const updateLocalCollection = (rowIndex, columnId, value) => {
    //skipResetRef.current = true // We also turn on the flag to not reset the page
    setData((old = []) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          if (row[columnId] !== value) {
            if (!changedRows.includes(rowIndex)) {
              changedRows.push(rowIndex)
              setChangedRows(changedRows)
            }
          }
          const newRow = {
            ...row,
            [columnId]: value
          }
          return newRow
        }
        return row
      })
    )
  }

  const getFutureOccurences = uploadedData => {
    let dataPlusFutureOccurences = []
    const endDateTimeValue = null
    const defaultStartDateTime = new Date().toISOString()
    const maxFutureOccurrenceCount = 1

    uploadedData.forEach(itm => {
      const getMyFutureOccurences =
        NarrativeRenderScheduleDAO.getScheduleFutureOccurences(
          itm.cronsyntax,
          !!itm.startdatetime ? itm.startdatetime : defaultStartDateTime,
          itm.useRecurrenceSettings,
          endDateTimeValue,
          maxFutureOccurrenceCount
        )
      getMyFutureOccurences.then(results => {
        if (results.content && Array.isArray(results.content)) {
          itm.futureOccurences = results.content[0]
        }
        dataPlusFutureOccurences.push(itm)
        if (dataPlusFutureOccurences.length === uploadedData.length) {
          if (outputRefreshCase) {
            if (organizationFeedsAndNarratives.narrativeOrgInfo) {
              askIfYouWantToRender(dataPlusFutureOccurences, true)
            } else {
              setData([...dataPlusFutureOccurences])
              setDelayedOutputRefreshLoad(true)
            }
          } else {
            askIfYouWantToRender(dataPlusFutureOccurences, true)
          }
        }
      })
    })
  }

  const handleFileUpload = e => {
    //In here we read a Tab Seperated Values (TSV) file and when it loads convert it to CSV
    const file = e.target.files[0] //Why not CSV to begin with? Because we have commas (,) in the data and that blows up a CSV
    const reader = new FileReader() //So the code below will detect the commas and escape those values before adding the commas back in to create the CSV
    reader.onload = evt => {
      // Parse data
      const bstr = evt.target.result
      const wb = XLSX.read(bstr, { type: "binary" })
      // Get first worksheet
      const wsname = wb.SheetNames[0]
      let ws = wb.Sheets[wsname]
      Object.keys(ws).forEach(key => {
        Object.keys(ws[key]).forEach(inkey => {
          if (inkey === "v") {
            if (typeof ws[key][inkey] === "string") {
              let tmp = ws[key][inkey]
              tmp = tmp.replace(/,/g, "__COMMASPOT__")
              ws[key][inkey] = tmp
            }
          }
        })
      })
      // Convert array of arrays, will account for any commas in the data that need to be escaped before conversion to CSV
      const dataResult = XLSX.utils.sheet_to_csv(ws, { header: 1 })
      if (sampleRefreshCase || outputRefreshCase) {
        processTSVdata(dataResult)
      } else {
        loadNarrativesAndFeedsListForBulkUpload(dataResult)
      }
    }
    reader.readAsBinaryString(file)
  }

  const addElements = (numberToAdd, list, columns) => {
    let newData = [...data]
    let newRenderSchedule = {}
    let i
    let numLoaded = 0
    let cronSyntaxExists = false
    if (!list || list.length === 0) {
      //These are default values for now
      newRenderSchedule = {
        cronTimeZoneIANA: "America/Los_Angeles",
        contentType: "query",
        useRecurrenceSettings: false
      }

      for (i = 0; i < numberToAdd; i++) {
        newData.push(newRenderSchedule)
      }
      setData([...newData])
    } else {
      let keyListConverter = {
        feed_id: "feedId",
        narrative_id: "narrativeId",
        content_id: "contentId"
      }
      let columnNames = columns.filter(itm => itm.name !== null)
      for (i = list.length - 1; i > -1; i--) {
        //have to do reverse order to have it show from first to last
        newRenderSchedule = {
          startdatetime: list[i].startdatetime,
          cronTimeZoneIANA: "America/Los_Angeles",
          contentType: "query",
          useRecurrenceSettings: !!list[i].cronSyntax?.replace(/\s/g, "")
            ? !sampleRefreshCase && !outputRefreshCase
              ? true
              : false
            : false
        }
        columnNames.forEach(itm => {
          if (list[i][itm.name] !== null && list[i][itm.name] !== undefined) {
            if (itm.name === "cronSyntax" || itm.name === "startdatetime") {
              //Remove "_" in cronSyntax and replace with spaces
              if (!!list[i][itm.name].replace(/\s/g, "")) {
                cronSyntaxExists = true
              }
              if (itm.name === "cronSyntax") {
                newRenderSchedule[itm.name] =
                  !sampleRefreshCase && !outputRefreshCase
                    ? list[i][itm.name].replace(/_/g, " ")
                    : ""
              } else {
                newRenderSchedule[itm.name] = list[i][itm.name].replace(
                  /_/g,
                  " "
                )
              }
            } else {
              if (keyListConverter[itm.name]) {
                newRenderSchedule[keyListConverter[itm.name]] =
                  list[i][itm.name]
              } else {
                newRenderSchedule[itm.name] = list[i][itm.name]
              }
            }
          }
        })
        newData.push(newRenderSchedule)
        numLoaded += 1
      }
      if (cronSyntaxExists) {
        getFutureOccurences(newData)
      } else {
        //No entries with CronSyntax
        if (outputRefreshCase) {
          if (organizationFeedsAndNarratives.narrativeOrgInfo) {
            askIfYouWantToRender(newData, true)
          } else {
            setData([...newData])
            setDelayedOutputRefreshLoad(true)
          }
        } else {
          askIfYouWantToRender(newData, true)
        }
      }
    }
    return numLoaded
  }

  const determineIfRowHasFeedInCurrentOrg = id => {
    if (sampleRefreshCase) {
      return true
    } else if (outputRefreshCase) {
      const match = organizationFeedsAndNarratives.feedSimpleList?.filter(
        fd =>
          fd.id === id &&
          fd.organizationId === Enums.Organizations.OutputRefreshActivities
      )
      return !!match?.length
    } else {
      const match = organizationFeedsAndNarratives.feedSimpleList?.filter(
        fd => fd.id === id
      )
      return !!match?.length
    }
  }

  const determineIfRowHasNarrativeInCurrentOrg = id => {
    if (outputRefreshCase) {
      return true
    } else if (sampleRefreshCase) {
      const match = organizationFeedsAndNarratives.feedSimpleList?.filter(
        fd =>
          fd.id === id &&
          fd.organizationId === Enums.Organizations.OutputRefreshActivities
      )
      return !!match?.length
    } else {
      const match = organizationFeedsAndNarratives.narrativeOrgInfo?.filter(
        itm => itm.id === id
      )
      return !!match?.length
    }
  }

  const askIfYouWantToRender = (newData, saveWithErrorIndicators) => {
    let resultArray = []
    let errorList = [...allErrorList]
    newData = Helpers.removeDuplicatesInArraysOfObjects_NumberMatches(newData, [
      "feedId",
      "narrativeId"
    ])
    if (!sampleRefreshCase) {
      newData.forEach((itm, index) => {
        //Check for Narrative ID and Feed ID in this org
        const feedError = !determineIfRowHasFeedInCurrentOrg(Number(itm.feedId))
        const narrativeError = !determineIfRowHasNarrativeInCurrentOrg(
          Number(itm.narrativeId)
        )
        if (feedError) {
          errorList.push(
            outputRefreshCase
              ? `Row ${index + 1}: Feed Id "${
                  itm.feedId
                }" is not from "Data Skrive/Output Refresh Activities", #1426.`
              : `Row ${index + 1}: Feed Id "${itm.feedId}" is not from this Org`
          )
          itm.hasError = true
          itm.hasFeedError = true
          if (!wrongFeedOrg) {
            setWrongFeedOrg(true)
          }
        }
        if (narrativeError) {
          //errors.push(`Narrative Id "${itm.narrativeId}" is not from this Org.`)
          errorList.push(
            `Row ${index + 1}: Output Id "${
              itm.narrativeId
            }" is not from this Org`
          )
          itm.hasError = true
          itm.hasNarrativeError = true
          if (!wrongNarrativeOrg) {
            setWrongNarrativeOrg(true)
          }
        }
        if (outputRefreshCase) {
          if (Number(itm.duplicateHandling) !== 2) {
            itm.hasError = true
            itm.hasWrongDuplicateHandling = true
            errorList.push(
              `Row ${index + 1}: Does not have "Duplicate Handling" = 2`
            )
          }
          const matchingFeed =
            organizationFeedsAndNarratives.feedSimpleList.filter(
              fd => fd.id === Number(itm.feedId)
            )
          const feedInfo = matchingFeed[0] || {}
          if (feedInfo.feedStatus !== Enums.FeedStatus.VALIDATEINTERNAL) {
            itm.hasError = true
            itm.hasWrongFeedStatus = true
            errorList.push(
              `Row ${index + 1}:  Feed Id "${
                itm.feedId
              }" does not have Feed Status of "VALIDATEINTERNAL" = 4, but ${
                feedInfo.feedStatus
              }`
            )
          }
        }
      })
      if (!newData.filter(itm => !itm.hasError)?.length) {
        setNoValidRenders(true)
      }
      newData.forEach(itm => {
        const body = generateBody(itm)
        resultArray.push(body)
      })
      if (saveWithErrorIndicators) {
        setData([...newData])
      }
      const newDataWithDeliverableId =
        sampleRefreshCase || outputRefreshCase
          ? [...newData]
          : getDeliverableIdFromFeedId([...newData])
      if (!newDataWithDeliverableId.filter(itm => !itm.hasError)?.length) {
        setNoValidRenders(true)
      }
      setResultTableData([...newDataWithDeliverableId])
      setShowAskToRenderModal(true)
      setAllErrorList(errorList)
    } else {
      const body = []
      newData.forEach((itm, index) => {
        if (Number(itm.duplicateHandling) !== 2) {
          itm.hasError = true
          itm.hasWrongDuplicateHandling = true
          errorList.push(
            `Row ${index + 1}: Does not have "Duplicate Handling" = 2`
          )
          body.push({
            narrativeId: itm.narrativeId,
            feedId: itm.feedId,
            hasError: true,
            hasWrongDuplicateHandling: true
          })
        } else {
          body.push({
            narrativeId: itm.narrativeId,
            feedId: itm.feedId
          })
        }
      })
      setLoadingRenderSchedule(true)
      //setAllErrorList(errorList)
      const results = OrganizationDAO.getDoOrgAndFeedHaveSameOrg(body)
      results.then(response => {
        findValidSampleRefreshEntries(response.content, newData, errorList)
        setLoadingRenderSchedule(false)
      })
    }
  }

  const getDeliverableIdFromFeedId = renderData => {
    renderData?.forEach(dta => {
      //let found = false
      //First we make sure the feedId is in this Org
      let matchFeed = organizationFeedsAndNarratives.feedSimpleList?.filter(
        fsl => Number(fsl.id) === Number(dta.feedId)
      )
      matchFeed = matchFeed && matchFeed[0]
      if (matchFeed?.id) {
        //Next we get the leagueId for the narrative/output
        let match = organizationFeedsAndNarratives.narrativeOrgInfo?.filter(
          noi => Number(noi.id) === Number(dta.narrativeId)
        )
        match = match && match[0]
        dta.leagueId = match ? match.leagueId || 0 : 0
        if (dta.leagueId) {
          //Next we get the leagueId for the narrative/output
          match = organizationFeedsAndNarratives.contractDeliverables?.filter(
            cds =>
              Number(cds.leagueId) === Number(dta.leagueId) &&
              cds.id === Number(dta.deliverableId)
          )
          match = match && match[0]
          if (
            match &&
            Number(matchFeed.feedStatus) !== Enums.FeedStatus.ACTIVE
          ) {
            dta.hasError = true
            dta.hasWrongFeedStatus = true
          } else if (match && match?.isArchived) {
            dta.hasArchivedDeliverableId = true
            dta.hasError = true
          } else if (!match) {
            dta.hasNoDeliverableId = true
            dta.hasError = true
          }
        } else {
          dta.hasNoDeliverableId = true
          dta.hasError = true
        }
      } else {
        dta.hasNoDeliverableId = true
        dta.hasError = true
      }
    })
    return renderData
  }
  const findValidSampleRefreshEntries = (resultFromAPI, myData, errList) => {
    const successfulEntries = []
    let errorList = [...errList]
    myData?.forEach((dta, index) => {
      resultFromAPI?.forEach((rst, index2) => {
        if (
          rst.feedId === Number(dta.feedId) &&
          rst.narrativeId === Number(dta.narrativeId)
        ) {
          if (Number(rst.feedStatus) !== Enums.FeedStatus.VALIDATE) {
            dta.hasError = true
            dta.hasWrongFeedStatus = true
            if (index2 === index) {
              errorList.push(
                `Row ${index + 1}: Feed "${
                  dta.feedId
                }" does not have "VALIDATE" - 2 - as its FeedStatus, but ${
                  rst.feedStatus
                }`
              )
            }
          }
          if (rst.orgMatch > -1) {
            successfulEntries.push(dta)
          } else {
            dta.hasError = true
            dta.hasOrgFeedMismatch = true
            successfulEntries.push(dta)
            if (index2 === index) {
              errorList.push(
                `Row ${
                  index + 1
                }: Has a Output and Feed in different Organizations`
              )
            }
          }
        }
      })
    })
    setAllErrorList(errorList)
    if (!successfulEntries.filter(itm => !itm.hasError)?.length) {
      setNoValidRenders(true)
    }
    const successfulEntriesWithDeliverableId =
      sampleRefreshCase || outputRefreshCase
        ? [...successfulEntries]
        : getDeliverableIdFromFeedId([...successfulEntries])
    if (
      !successfulEntriesWithDeliverableId.filter(itm => !itm.hasError)?.length
    ) {
      setNoValidRenders(true)
    }
    setResultTableData([...successfulEntriesWithDeliverableId])
    setData([...successfulEntries])
    setShowAskToRenderModal(true)
  }

  const generateBody = itm => {
    let body = {
      narrativeId: Number(itm.narrativeId),
      contentId: Number(itm.contentId),
      contentType: "query",
      contentTypeId: 10,
      isDebug: false,
      runQAReports: false,
      debugAllTriggers: false,
      feedId: Number(itm.feedId),
      duplicateHandling: Number(itm.duplicateHandling),
      isPaused: false,
      useRecurrenceSettings:
        !sampleRefreshCase && !outputRefreshCase
          ? itm.useRecurrenceSettings || false
          : false,
      cronSyntax:
        !sampleRefreshCase && !outputRefreshCase ? itm.cronSyntax || "" : "",
      cronTimeZoneIANA: itm.cronTimeZoneIANA,
      publishDelayInSeconds: Number(itm.publishdelayinseconds) || 0,
      narrativeBlock_Ids: "",
      webhookId: null
    }
    if (!!itm.startdatetime) {
      body.startDateTime = itm.startdatetime
    }
    return body
  }
  const conditionalRender = () => {
    let newData = data.filter(itm => !itm.hasError)
    let resultArray = []
    let feedData = []
    if (newData.length === 0) {
      setLoadingRenderSchedule(false)
      setNoValidRenders(true)
      return
    }
    newData.forEach(itm => {
      const body = generateBody(itm)
      const putScheduleFeedEntries =
        NarrativeRenderScheduleDAO.putScheduleFeedEntries(body)
      putScheduleFeedEntries.then(results => {
        if (results) {
          resultArray.push(results)
          body.nrsId = results
          feedData.push(body)
        } else {
          resultArray.push("failed")
        }
        if (resultArray.length === newData.length) {
          setResultTableData([...feedData])
          setData(feedData)
          setShowResultsModal(true)
          setLoadingRenderSchedule(false)
        }
      })
    })
  }

  const processTSVdata = dataString => {
    const dataStringLines = dataString.split(/\r\n|\n/) //split by CR/LF
    const headersOrig = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    ) //Take the first "line" and split out the header columns as our name keys
    const keyListConverter = {
      feed_id: "feedId",
      narrative_id: "narrativeId",
      content_id: "contentId",
      duplicatehandling: "duplicateHandling",
      cronsyntax: "cronSyntax",
      publishdelayinseconds: "publishDelayInSeconds",
      deliverable_id: "deliverableId"
    }
    const headers = []
    headersOrig.forEach(itm => {
      if (keyListConverter[itm]) {
        headers.push(keyListConverter[itm])
      } else {
        headers.push(itm)
      }
    })
    const list = []
    for (let i = 1; i < dataStringLines.length; i++) {
      dataStringLines[i] = dataStringLines[i].replace(/""/g, '"') //To remove double quotes "" to single quote "
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      )
      if (headers && row.length === headers.length) {
        const obj = {}
        for (let j = 0; j < headers.length; j++) {
          //This uses the single header values as the "name" and the specific row values as the "value"
          let columnValue = row[j] //For the properties of  "obj" which will correspond  to the name-value pairs for this row
          if (columnValue.length > 0) {
            if (columnValue[0] === '"') {
              columnValue = columnValue.substring(1, columnValue.length - 1)
            }
            if (columnValue[columnValue.length - 1] === '"') {
              columnValue = columnValue.substring(columnValue.length - 2, 1)
            }
          }
          if (headers[j]) {
            obj[headers[j]] = columnValue.replace(/__COMMASPOT__/g, ",")
          }
        }

        // do not add any blank rows
        if (Object.values(obj).filter(x => x).length > 0) {
          list.push(obj)
        }
      }
    }
    // prepare columns list from headers
    const columns = headers.map(c => ({
      name: c,
      selector: c
    }))
    if (list.length) {
      addElements(list.length, list, columns)
    }
  }

  const clearResultsModal = action => {
    setShowAskToRenderModal(false)
    setShowResultsModal(false)
    setResultTableData([])
    setData([])
    setAllErrorList([])
    setResetButton(true)
    setMalformedTSV(false)
    setWrongFeedOrg(false)
    setWrongNarrativeOrg(false)
    setOutputRefreshCase(false)
    setSampleRefreshCase(false)
    setNoValidRenders(false)
    if (!action || (action !== "cancel" && !showAskToRenderModal)) {
      props.reloadSchedules()
    }
  }

  const buttonParams = {
    color: "primary",
    style: {
      color: "#c00",
      backgroundColor: "#2ff",
      borderRadius: "5px",
      padding: "10px"
    }
  }

  const loadNarrativesAndFeedsList = isSpecial => {
    //This toggles useState values that, when changing, fire a useEffect to look at the changed valus and THEN
    //firing the call to get the Narratives and Feeds List for this organization.
    if (isSpecial) {
      if (isSpecial === "Output Refresh") {
        setOutputRefreshCase(true)
        setSampleRefreshCase(false)
        setLoadNarrativesAndFeeds(true)
      } else if (isSpecial === "Sample Refresh") {
        setSampleRefreshCase(true)
        setOutputRefreshCase(false)
        setLoadNarrativesAndFeeds(false)
      }
    } else {
      // setSampleRefreshCase(false)
      // setOutputRefreshCase(false)
      // setLoadNarrativesAndFeeds(true)
      // loadNarrativesAndFeedsList_Call()
    }
  }

  const loadNarrativesAndFeedsListForBulkUpload = dataResult => {
    setSampleRefreshCase(false)
    setOutputRefreshCase(false)
    setLoadNarrativesAndFeeds(true)
    setWaitForOrgsFeedsAndDeliverableInfoToReturn(true)
    loadNarrativesAndFeedsList_Call(dataResult)
  }

  const loadNarrativesAndFeedsList_Call = dataResult => {
    if (!organizationFeedsAndNarratives.narrativeOrgInfo) {
      const results = OrganizationDAO.getOrganizationNarrativesAndFeeds(
        props.organizationId
      )
      results.then(response => {
        setOrganizationFeedsAndNarratives(response.content)
        //processTSVdata(dataResult)
        setOrgsFeedsAndDeliverableData(dataResult)
        setWaitForOrgsFeedsAndDeliverableInfoToReturn(false)
      })
    } else {
      //processTSVdata(dataResult)
      setOrgsFeedsAndDeliverableData(dataResult)
      setWaitForOrgsFeedsAndDeliverableInfoToReturn(false)
    }
  }

  React.useEffect(() => {
    if (
      !waitForOrgsFeedsAndDeliverableInfoToReturn &&
      orgsFeedsAndDeliverableData?.length
    ) {
      processTSVdata(orgsFeedsAndDeliverableData)
    }
  }, [waitForOrgsFeedsAndDeliverableInfoToReturn, orgsFeedsAndDeliverableData])

  React.useEffect(() => {
    //For getting Narratives and Feeds List for this organization, used in Regular Bulk Render and Output Refresh
    if (loadNarrativesAndFeeds && delayedOutputRefreshLoad) {
      loadNarrativesAndFeedsList_Call()
    }
  }, [loadNarrativesAndFeeds, delayedOutputRefreshLoad])

  React.useEffect(() => {
    if (
      loadNarrativesAndFeeds &&
      delayedOutputRefreshLoad &&
      organizationFeedsAndNarratives.narrativeOrgInfo
    ) {
      setDelayedOutputRefreshLoad(false)
      askIfYouWantToRender(data, true)
    }
  }, [organizationFeedsAndNarratives])

  const createRenderTitle = outputRefreshCase
    ? "Create Output Refresh Render"
    : sampleRefreshCase
    ? "Create Sample Refresh Render"
    : "Create Render Job"

  React.useEffect(() => {
    if (resetButton) {
      setResetButton(false)
    }
  }, [resetButton])

  return (
    <>
      {!resetButton && (
        <>
          {loadingRenderSchedule && (
            <Box sx={{ width: "100%", height: "10px" }}>
              <LinearProgress />
            </Box>
          )}
          {loadNarrativesAndFeeds && delayedOutputRefreshLoad && (
            <Box sx={{ width: "100%", height: "10px" }}>
              <LinearProgress />
            </Box>
          )}
          {waitForOrgsFeedsAndDeliverableInfoToReturn && (
            <Box sx={{ width: "100%", height: "10px" }}>
              <LinearProgress />
            </Box>
          )}
          <UploadButton
            doFileUpload={e => {
              handleFileUpload(e)
            }}
            buttonParams={buttonParams}
            acceptedSuffix={".tsv"}
            descriptorString={descriptorString}
            otherClickEvent={loadNarrativesAndFeedsList}
          />
          <IconButton
            aria-label="Bulk Upload Render"
            color="primary"
            className={classes.leftJustify}
            onClick={() => {
              setDialogInfoOpen(true)
              setDialogInfoMessage(renderBulkUploadInfo(classes.bulkUploadList))
            }}
          >
            <InfoIcon />
          </IconButton>
          {currentProperty === Enums.Organizations.OutputRefreshActivities && (
            <>
              <span style={{ display: "inline-block", margin: "0 0 0 10px" }}>
                <UploadButton
                  doFileUpload={e => {
                    handleFileUpload(e)
                  }}
                  buttonParams={buttonParams}
                  acceptedSuffix={".tsv"}
                  descriptorString={"Bulk Upload Render Output Refresh"}
                  otherClickEvent={loadNarrativesAndFeedsList}
                  buttonPurpose={"Output Refresh"}
                />
                <IconButton
                  aria-label="Bulk Upload Render Output Refresh"
                  color="primary"
                  className={classes.leftJustify}
                  onClick={() => {
                    setDialogInfoOpen(true)
                    setDialogInfoMessage(
                      renderOutputRefreshInfo(classes.bulkUploadList)
                    )
                  }}
                >
                  <InfoIcon />
                </IconButton>
              </span>
            </>
          )}
          {currentProperty === Enums.Organizations.OutputRefreshActivities && (
            <>
              <span style={{ display: "inline-block", margin: "0 0 0 10px" }}>
                <UploadButton
                  doFileUpload={e => {
                    handleFileUpload(e)
                  }}
                  buttonParams={buttonParams}
                  acceptedSuffix={".tsv"}
                  descriptorString={"Bulk Upload Render Sample Refresh"}
                  otherClickEvent={loadNarrativesAndFeedsList}
                  buttonPurpose={"Sample Refresh"}
                />
                <IconButton
                  aria-label="Bulk Upload Render Sample Refresh"
                  color="primary"
                  className={classes.leftJustify}
                  onClick={() => {
                    setDialogInfoOpen(true)
                    setDialogInfoMessage(
                      renderSampleRefreshInfo(classes.bulkUploadList)
                    )
                  }}
                >
                  <InfoIcon />
                </IconButton>
              </span>
            </>
          )}
          <>
            <Modal
              open={showResultsModal}
              onClose={clearResultsModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div className={classes.resultsModal}>
                <h2>You succesfully saved the following Jobs:</h2>
                <div>
                  <TableControlsNoToolbar
                    columns={[
                      {
                        Header: "Schedule ID",
                        accessor: "nrsId",
                        width: "80",
                        Cell: ({ row }) => (
                          <div style={{ padding: "5px" }}>
                            {row.original?.nrsId}
                          </div>
                        )
                      }
                    ].concat(columns)}
                    hideColumns={[]}
                    data={resultTableData}
                    readOnly={true}
                  />
                </div>
              </div>
            </Modal>
            <Modal
              open={showAskToRenderModal}
              onClose={clearResultsModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div className={classes.resultsModal}>
                <h2>
                  {!malformedTSV
                    ? createRenderTitle
                    : "Here is the Malformed TSV in the Table"}
                </h2>
                {!noValidRenders && (
                  <p id="alert-dialog-description">
                    {!malformedTSV
                      ? "Would you like to set the Render Job for the valid entries below?"
                      : "Hopefully this helps you locate the issue"}
                  </p>
                )}
                {malformedTSV && (
                  <p id="alert-dialog-description2">
                    You can go to the following confluence page for
                    properly-formated TSV examples &gt;&gt;{" "}
                    <a
                      target="_blank"
                      href={`https://dataskrivejira.atlassian.net/wiki/spaces/PKB/pages/139362305/Render+Schedule+-+Bulk+Uploads`}
                      title="Render Schedule - Bulk Uploads"
                      rel="help noreferrer"
                    >
                      Render Schedule - Bulk Uploads
                    </a>
                  </p>
                )}
                {allErrorList.length > 0 && (
                  <>
                    <h6>Here are the Errors in the Upload:</h6>
                    <ul className={classes.errorList}>
                      {allErrorList.map((err, i) => (
                        <li key={i}>{err}</li>
                      ))}
                    </ul>
                  </>
                )}
                <TableControlsNoToolbar
                  columns={errorColumn.concat(columns)}
                  hideColumns={
                    sampleRefreshCase || outputRefreshCase
                      ? ["deliverableId"]
                      : []
                  }
                  data={resultTableData}
                  handleDataChange={updateLocalCollection}
                  skipReset={true}
                />
                <DialogActions>
                  <Button
                    onClick={() => {
                      clearResultsModal("cancel")
                    }}
                  >
                    Cancel
                  </Button>
                  {!malformedTSV && !noValidRenders && (
                    <Button
                      onClick={() => {
                        setLoadingRenderSchedule(true)
                        conditionalRender()
                        setShowAskToRenderModal(false)
                        setOutputRefreshCase(false)
                        setSampleRefreshCase(false)
                      }}
                    >
                      Create Render
                    </Button>
                  )}
                </DialogActions>
              </div>
            </Modal>
          </>
        </>
      )}
    </>
  )
}

UploadRenderSchedule.propTypes = {
  reloadSchedules: PropTypes.func,
  organizationId: PropTypes.number
}
export default withStyles(styles)(UploadRenderSchedule)
