import React, { useState, useEffect, useMemo } from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
//MUI components
import Icon from "@mui/material/Icon"
import Card from "@mui/material/Card"
import Checkbox from "@mui/material/Checkbox"
import CircularProgress from "@mui/material/CircularProgress"
import FormControlLabel from "@mui/material/FormControlLabel"
import Grid from "@mui/material/Grid"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles"

import makeStyles from "@mui/styles/makeStyles"

//Icons
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import Battery20 from "@mui/icons-material/Battery20"
import BatteryUnknown from "@mui/icons-material/BatteryUnknown"
import BlockOutlined from "@mui/icons-material/BlockOutlined"
import BookmarkBorderOutlined from "@mui/icons-material/BookmarkBorderOutlined"
import PlannedIcon from "@mui/icons-material/EmojiObjectsOutlined"
import Edit from "@mui/icons-material/Edit"
import Cancel from "@mui/icons-material/Cancel"

//Custom Components
import SaturationChip from "components/ContentSaturation/SaturationChip"
import ContentBlock from "../Types/ContentBlock"
import StatusType from "../Types/StatusType"
import RenderType from "../Types/RenderType"
import GridItem from "components/Grid/GridItem"
import CardHeader from "components/Card/CardHeader"
import { FormTheme } from "components/SharedStyles/FormStyles/FormTheme"
import ValidateTriggers from "components/NarrativeAdmin/Trigger/ValidateTriggers"

const useStyles = makeStyles({
  modalHeader: {
    backgroundColor: "#DDEAEF"
  },
  modalTitle: {
    fontSize: "large",
    fontWeight: "bold",
    marginBottom: "15px",
    marginLeft: "5px"
  },
  modalStatus: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  paragraphInfo: {
    display: "inline-flex",
    marginBottom: "8px"
  },
  paragraphDetail: {
    display: "inline-flex",
    marginBottom: "8px"
  },
  triggerHeader: {
    backgroundColor: "#DDEAEF",
    fontSize: "large",
    fontWeight: "bold",
    padding: "5px",
    borderRadius: "0px",
    marginTop: "5px",
    marginBottom: "9px",
    marginLeft: "-25px",
    marginRight: "-24px",
    paddingBottom: "9px",
    paddingLeft: "13px"
  },
  triggerFields: {
    marginLeft: "6px"
  },
  modalButton: {
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: "10px",
    paddingRight: "9px"
  },
  buttonStyle: {
    fontSize: "12px",
    padding: "14px 23px",
    minWidth: "100px",
    marginRight: "4px",
    color: "#FFF"
  },
  autocompleteModal: {
    minWidth: "235px"
  },
  autocompleteModalDark: {
    minWidth: "235px",
    backgroundColor: "#FFF"
  },
  inputFieldModalDark: {
    backgroundColor: "#FFF"
  },
  statusIcon: {
    fontSize: "1em",
    width: "20px",
    height: "25px",
    color: "#144E68",
    marginRight: "20px",
    marginBottom: "15px"
  },
  ownerField: {
    paddingTop: "9px"
  }
})
function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    narrative: store.narrativeStore.narrative,
    loadingParagraph: store.narrativeStore.loadingParagraph,
    getParagraph: store.narrativeStore.getParagraph,
    postParagraph: store.narrativeStore.postParagraph,
    reloadNarrativeBrief: store.narrativeStore.reloadNarrativeBrief,
    accounts: store.accountStore.accounts,
    getActiveAccountsByOrganization:
      store.accountStore.getActiveAccountsByOrganization,
    account: store.accountStore.account,
    narrativeParagraphTagsSummary:
      store.narrativeStore.narrativeParagraphTagsSummary,
    narrativeParagraphTags: store.narrativeStore.narrativeParagraphTags,
    triggersBrief: store.narrativeStore.triggersBrief
  }))
}

const ParagraphEditor = props => {
  const {
    loadingParagraph,
    getParagraph,
    postParagraph,
    narrative,
    accounts,
    getActiveAccountsByOrganization,
    reloadNarrativeBrief,
    narrativeParagraphTagsSummary,
    narrativeParagraphTags,
    triggersBrief
  } = useStoreData()
  const { paragraph, create } = props
  const classes = useStyles()
  const PUBLISHED = 3

  const [paragraphFormValues, setParagraphFormValues] = useState(paragraph)

  const [shouldReload, setShouldReload] = useState(false)
  const [triggerValue, setTriggerValue] = useState("")
  const [newLibraryName, setNewLibraryName] = useState(paragraphFormValues.name)
  const [manualEdit, setManualEdit] = useState(true)
  const [triggerBlurred, setTriggerBlurred] = useState(true)

  const accountsJS = accounts?.toJS() || null

  useEffect(() => {
    if (!accountsJS || accountsJS.length === 0) {
      getActiveAccountsByOrganization(narrative?.organization?.id)
    }
    if (!create && paragraph?.id) {
      getParagraph(paragraph?.id)
    }
  }, [])

  useEffect(() => {
    setParagraphFormValues(paragraph)
  }, [paragraph])

  const accountList = useMemo(
    () =>
      accounts
        ?.toJS()
        .sort((a, b) =>
          a.username.toUpperCase() > b.username.toUpperCase() ? 1 : -1
        ),
    [accounts]
  )

  const labelStatusIcon = status => {
    switch (status) {
      case 1:
        return <Battery20 />
      case 2:
        return <BatteryUnknown />
      case 4:
        return <PlannedIcon />
      case 5:
        return <BookmarkBorderOutlined />
      case 6:
        return <BlockOutlined />
      default:
        return null
    }
  }

  const notValidFormValue = () => {
    if (
      paragraphFormValues.shouldCreatePlaceholderSentences &&
      !paragraphFormValues.libraryParagraph_Id
    ) {
      return "Library Paragraph required when Creating Lib. Position Group(s)."
    } else {
      return false
    }
  }

  const handleSave = () => {
    const failMessage = notValidFormValue()
    if (failMessage) {
      alert(failMessage)
      return
    }
    const pFormValues = {
      ...paragraphFormValues,
      libraryBlockPositionsFilter:
        typeof paragraphFormValues.libraryBlockPositionsFilter === "object" &&
        paragraphFormValues.libraryBlockPositionsFilter?.length > 0
          ? paragraphFormValues.libraryBlockPositionsFilter.join()
          : typeof libraryBlockPositionsAvailable === "object" &&
            libraryBlockPositionsAvailable?.length > 0
          ? libraryBlockPositionsAvailable.join()
          : ""
    }
    postParagraph(pFormValues)
    if (shouldReload) {
      setTimeout(() => reloadNarrativeBrief(narrative.id), 1000)
    }
    props.handleClose()
  }
  const handleMultiChange = (event, newValue, reason, name) => {
    const newFormValues = {
      ...paragraphFormValues,
      [name]: newValue
    }
    setParagraphFormValues(autonameParagraph(newFormValues))
  }
  const handleAutoCompleteMultiChangeForTriggerType = (
    event,
    newValue,
    reason,
    name
  ) => {
    let newVal = ""
    newValue.forEach(itm => {
      let matchVal = null
      triggerList.forEach(tl => {
        if (`!${itm.type}` === tl) {
          matchVal = `!${itm.type}`
        } else if (itm.type === tl) {
          matchVal = itm.type
        }
      })
      if (matchVal) {
        newVal = !newVal ? matchVal : `${newVal}, ${matchVal}`
      } else {
        newVal = !newVal ? itm.type : `${newVal}, ${itm.type}`
      }
    })
    const newFormValues = {
      ...paragraphFormValues,
      [name]: newVal
    }
    setTriggerValue(newVal)
    setParagraphFormValues(autonameParagraph(newFormValues))
  }
  const handleChange = event => {
    const newFormValues = {
      ...paragraphFormValues,
      [event.target?.name || event.target?.id]: event.target.value
    }
    if (event.target?.name === "libraryBlockPositionsFilter") {
      setShouldReload(true)
    }
    setParagraphFormValues(autonameParagraph(newFormValues))
  }
  const handleLibraryChange = (event, newValue) => {
    const selectedValue = newValue ? `${newValue.id} - ${newValue.name}` : ""
    const newFormValues = {
      ...paragraphFormValues,
      name: paragraphFormValues.name ? paragraphFormValues.name : selectedValue,
      libraryParagraph_Id: newValue ? newValue.id.toString() : "",
      shouldCreatePlaceholderSentences: newValue ? true : false
    }
    setShouldReload(true)
    setParagraphFormValues(autonameParagraph(newFormValues))
    if (!paragraphFormValues.name) {
      setNewLibraryName(selectedValue)
    }
  }
  const handleOutlineBlockChange = (event, newValue) => {
    const newFormValues = {
      ...paragraphFormValues,
      narrativeOutlineBlockId: newValue && newValue.id
    }
    setShouldReload(true)
    setParagraphFormValues(autonameParagraph(newFormValues))
  }

  const handleNameChange = e => {
    const inputName = e.target.value
    setNewLibraryName(inputName)
    const newFormValues = {
      ...paragraphFormValues,
      name: inputName
    }
    setParagraphFormValues(newFormValues)
  }

  const handleCheckboxChange = event => {
    const newFormValues = {
      ...paragraphFormValues,
      [event.target.id]: event.target.checked
    }
    setParagraphFormValues(newFormValues)
  }

  const autonameParagraph = paragraphObject => {
    // Check to see if they gave us a Library Paragraph Id
    if (paragraphObject.name === "" && !!paragraphObject.libraryParagraph_Id) {
      // Find the library Paragraph in the list of library paragraphs we may have been passed
      const libraryParagraph = narrative?.libraryParagraphs?.find(
        lp => lp.id === paragraphObject.libraryParagraph_Id
      )
      // Set the name of the block if we were able to find a matching Library Paragraph
      let libraryName =
        (libraryParagraph &&
          `${libraryParagraph.id} - ${libraryParagraph.name}`) ||
        paragraphObject.libraryParagraph_Id
      libraryName = paragraphObject.libraryBlockPositionsFilter
        ? `${libraryName} ${paragraphObject.libraryBlockPositionsFilter}`
        : libraryName
      // Assign the name to the paragraph based on the results
      paragraphObject.name = libraryName
        ? `Lib. Para. ${libraryName}`
        : paragraphObject.name
    }
    // Return the Paragraph whether changed or not
    return paragraphObject
  }

  const narrativeParagraphTagsSummarySorted =
    narrativeParagraphTagsSummary || []
  const narrativeParagraphTagsSorted = narrativeParagraphTags || []

  let filteredLibraryParagraphsById = []
  narrativeParagraphTagsSorted.forEach(itm => {
    paragraphFormValues.libraryParagraphTagsSelected?.forEach((itm2, index) => {
      if (itm.tagId === itm2.tagId && itm.tagTypeId === itm2.tagTypeId) {
        if (
          !filteredLibraryParagraphsById.includes({
            id: itm.paragraphId,
            index
          })
        ) {
          filteredLibraryParagraphsById.push({ id: itm.paragraphId, index })
        }
      }
    })
  })

  let filteredLibraryParagraphs = filteredLibraryParagraphsById.length
    ? narrative?.libraryParagraphs?.toJS().filter(itm => {
        let found = []
        filteredLibraryParagraphsById.forEach(itm2 => {
          if (itm.id === itm2.id) {
            if (!found.includes(itm2.index)) {
              found.push(itm2.index)
            }
          }
        })
        return (
          found.length ===
          paragraphFormValues.libraryParagraphTagsSelected.length
        )
      })
    : narrative?.libraryParagraphs?.toJS() || []

  //If includeAllStatuses is Checked, show all filtered Paragraphs.  if not, only show ones with Status_Id = 3
  filteredLibraryParagraphs = filteredLibraryParagraphs.filter(itm => {
    if (!paragraphFormValues.includeAllStatuses) {
      return itm.status_Id === PUBLISHED
    } else {
      return true
    }
  })

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = (
    <CheckBoxIcon fontSize="small" className={classes.checkRegion} />
  )

  const triggerList =
    (paragraphFormValues?.triggerType &&
      paragraphFormValues?.triggerType.split(", ")) ||
    []

  const triggers = triggersBrief?.toJS() || []
  let triggerKeys = []
  triggers.forEach(itm => {
    if (!triggerKeys.includes(itm.type)) {
      triggerKeys.push(itm.type)
    }
  })

  const selectedLibararyParagraph = paragraphFormValues.libraryParagraph_Id
    ? narrative?.libraryParagraphs?.find(
        lp => lp.id === Number(paragraphFormValues.libraryParagraph_Id)
      )
    : []
  let libraryBlockPositionsAvailable = []

  //Fix for position=0
  selectedLibararyParagraph?.sentences?.forEach(snt => {
    if (
      Number(snt.position) > -1 &&
      !libraryBlockPositionsAvailable.includes(snt.position)
    ) {
      libraryBlockPositionsAvailable.push(snt.position)
    }
  })

  let libraryBlockPositionsFilter =
    typeof paragraphFormValues.libraryBlockPositionsFilter === "string" &&
    paragraphFormValues.libraryBlockPositionsFilter
      ? paragraphFormValues.libraryBlockPositionsFilter.split(",")
      : typeof paragraphFormValues.libraryBlockPositionsFilter === "object"
      ? paragraphFormValues.libraryBlockPositionsFilter
      : []

  return (
    <>
      {loadingParagraph && (
        <CircularProgress
          style={{
            position: "absolute",
            left: "45%",
            top: "250px",
            zIndex: "101"
          }}
        />
      )}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={FormTheme}>
          <Grid container spacing={2} className={classes.modalStatus}>
            <GridItem xs={3}>
              {/* owner */}
              <Autocomplete
                size="medium"
                name="ownerId"
                className={classes.ownerField}
                id="ownerId"
                onChange={(e, option) => {
                  const newFormValues = {
                    ...paragraphFormValues,
                    ownerId: option?.id
                  }
                  setParagraphFormValues(newFormValues)
                }}
                options={accountList}
                getOptionLabel={option => option.username}
                value={accountList?.find(
                  ob => ob.id === paragraphFormValues.ownerId
                )}
                readOnly={loadingParagraph}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="OWNER"
                    key={params.id}
                    value={params.name}
                  />
                )}
              />
            </GridItem>
            <GridItem xs={3}>
              <StatusType
                value={paragraphFormValues.status_Id || ""}
                onChange={e => {
                  const newFormvalues = {
                    ...paragraphFormValues,
                    status_Id: e
                  }
                  setParagraphFormValues(newFormvalues)
                }}
                isLibrary={narrative.isLibrary}
                paragraphType={paragraphFormValues.primaryTagId}
                readOnly={loadingParagraph}
              />
            </GridItem>
          </Grid>
          <Grid>
            <Typography className={classes.modalTitle}>
              Paragraph Information
            </Typography>
          </Grid>
          {!create && (
            <Grid container spacing={2} className={classes.paragraphInfo}>
              <GridItem>
                <TextField
                  id="id"
                  label="ID"
                  fullWidth={true}
                  value={paragraphFormValues.id}
                  readOnly
                />
              </GridItem>
              <GridItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="isArchived"
                      checked={paragraphFormValues.isArchived || false}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Archive"
                />
              </GridItem>
            </Grid>
          )}
          <Grid container spacing={2} className={classes.paragraphDetail}>
            <GridItem xs={6}>
              <Autocomplete
                className={classes.autocompleteModal}
                id="narrativeOutlineBlockId"
                fullWidth={true}
                options={narrative?.outlineBlocks}
                getOptionLabel={option =>
                  `${option.id} - ${option.contentSection}`
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    label="NARRATIVE OUTLINE BLOCK"
                    fullWidth={true}
                  />
                )}
                value={narrative?.outlineBlocks?.find(
                  ob => ob.id === paragraphFormValues.narrativeOutlineBlockId
                )}
                onChange={handleOutlineBlockChange}
                readOnly={loadingParagraph}
              />
            </GridItem>
            <GridItem xs={4}>
              <TextField
                id="name"
                name="name"
                label="NAME"
                type="text"
                fullWidth={true}
                value={newLibraryName}
                onChange={handleNameChange}
              />
            </GridItem>
          </Grid>
          <Grid container spacing={2} className={classes.paragraphDetail}>
            <GridItem xs={6}>
              <ContentBlock
                value={paragraphFormValues.contentBlock}
                onChange={e => {
                  const newFormvalues = {
                    ...paragraphFormValues,
                    contentBlock: e
                  }
                  setParagraphFormValues(newFormvalues)
                }}
                required={
                  paragraphFormValues.contentBlock === "library" ? false : true
                }
                readOnly={loadingParagraph}
              />
            </GridItem>
            {paragraphFormValues.contentBlock &&
              (paragraphFormValues.contentBlock === "content" ||
                paragraphFormValues.contentBlock === "library" ||
                paragraphFormValues.contentBlock === "bottomparagraph" ||
                paragraphFormValues.contentBlock === "introparagraph") && (
                <GridItem sm={2}>
                  <RenderType
                    margin="normal"
                    fullWidth
                    value={paragraphFormValues.renderType || ""}
                    onChange={e => {
                      const newFormvalues = {
                        ...paragraphFormValues,
                        renderType: e
                      }
                      setParagraphFormValues(newFormvalues)
                    }}
                    readOnly={loadingParagraph}
                  />
                </GridItem>
              )}
          </Grid>
          {!narrative?.isLibrary && (
            <Grid container spacing={2} className={classes.paragraphDetail}>
              <GridItem xs={8}>
                <Autocomplete
                  multiple={true}
                  className={classes.autocompleteModal}
                  fullWidth={true}
                  limitTags={4}
                  name="libraryParagraphTagsSelected"
                  id="libraryParagraphTagsSelected"
                  value={narrativeParagraphTagsSummarySorted.filter(rgn => {
                    let match = false
                    paragraphFormValues.libraryParagraphTagsSelected &&
                      paragraphFormValues.libraryParagraphTagsSelected.forEach(
                        itm => {
                          if (
                            itm.tagId === rgn.tagId &&
                            itm.tagTypeId === rgn.tagTypeId
                          ) {
                            match = true
                          }
                        }
                      )
                    return match
                  })}
                  options={narrativeParagraphTagsSummarySorted}
                  onChange={(event, newValue, reason) => {
                    handleMultiChange(
                      event,
                      newValue,
                      reason,
                      "libraryParagraphTagsSelected"
                    )
                  }}
                  getOptionLabel={option =>
                    `${option.tagTypeId} - ${option.tagName}`
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={selected}
                      />
                      {option.tagTypeId} - {option.tagName}
                    </li>
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.tagId === value.tagId : false
                  }
                  renderInput={params => (
                    <TextField {...params} label="FILTER BY" />
                  )}
                  readOnly={loadingParagraph}
                />
              </GridItem>
            </Grid>
          )}

          <Grid className={classes.paragraphDetail} container spacing={2}>
            <GridItem xs={6}>
              <Autocomplete
                className={classes.autocompleteModal}
                fullWidth={true}
                name="libraryParagraph_Id"
                id="libraryParagraph_Id"
                value={
                  paragraphFormValues.libraryParagraph_Id
                    ? narrative?.libraryParagraphs?.find(
                        lp =>
                          lp.id ===
                          Number(paragraphFormValues.libraryParagraph_Id)
                      )
                    : null
                }
                options={filteredLibraryParagraphs || []}
                onChange={handleLibraryChange}
                getOptionLabel={option => `${option.id} - ${option.name}`}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Icon className={classes.statusIcon}>
                      {labelStatusIcon(option.status_Id)}
                    </Icon>
                    {option.id} - {option.name}
                    <SaturationChip
                      saturation={option.saturation}
                      utilization={option.utilization}
                    />
                  </li>
                )}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.id === value.id : false
                }
                renderInput={params => (
                  <TextField {...params} label="LIBRARY PARAGRAPH" />
                )}
                readOnly={loadingParagraph}
              />
            </GridItem>
            <GridItem xs={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="includeAllStatuses"
                    checked={paragraphFormValues.includeAllStatuses || false}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Include All Statuses"
              />
            </GridItem>
            <GridItem xs={2}>
              <Autocomplete
                fullWidth={true}
                multiple={true}
                name="libraryBlockPositionsFilter"
                id="libraryBlockPositionsFilter"
                disableCloseOnSelect={true}
                value={libraryBlockPositionsAvailable.filter(pos => {
                  let match = false
                  libraryBlockPositionsFilter &&
                    libraryBlockPositionsFilter.forEach(itm => {
                      if (Number(itm) === Number(pos)) {
                        match = true
                      }
                    })
                  return match
                })}
                options={libraryBlockPositionsAvailable || []}
                onChange={(event, newValue, reason) => {
                  handleMultiChange(
                    event,
                    newValue,
                    reason,
                    "libraryBlockPositionsFilter"
                  )
                }}
                getOptionLabel={option => `${option}`}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option === value : false
                }
                renderInput={params => (
                  <TextField {...params} label="POSITION FILTER" />
                )}
                readOnly={loadingParagraph}
              />
            </GridItem>
            <GridItem xs={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="shouldCreatePlaceholderSentences"
                    checked={
                      paragraphFormValues.shouldCreatePlaceholderSentences ||
                      false
                    }
                    onChange={handleCheckboxChange}
                  />
                }
                label="Create Lib. Position Group?"
              />
            </GridItem>
          </Grid>
          {!narrative?.isLibrary && (
            <Card className={classes.triggerHeader} elevation={0}>
              <CardHeader className={classes.triggerTitle}>
                Trigger Details
              </CardHeader>
              <Grid container spacing={2} className={classes.triggerFields}>
                {manualEdit && (
                  <GridItem xs={8} className={classes.paragraphDetail}>
                    <TextField
                      label="TRIGGER"
                      type="text"
                      name="triggerType"
                      id="triggerType"
                      fullWidth={true}
                      value={paragraphFormValues.triggerType}
                      onChange={handleChange}
                      onBlur={e => {
                        setTriggerValue(e.target.value)
                        setTriggerBlurred(!triggerBlurred)
                      }}
                      readOnly={loadingParagraph}
                    />
                    <Tooltip
                      id="tooltip-top"
                      title="Cancel Manual Edit"
                      placement="top"
                    >
                      <IconButton
                        className={classes.iconButton}
                        aria-label="Cancel Manual Edit"
                        onClick={() => setManualEdit(false)}
                      >
                        <IconButton
                          className={classes.iconButton}
                          aria-label="Cancel Manual Edit"
                          onClick={() => setManualEdit(false)}
                          size="large"
                        >
                          <Cancel />
                        </IconButton>
                      </IconButton>
                    </Tooltip>
                  </GridItem>
                )}
                {!manualEdit && (
                  <GridItem xs={8} className={classes.paragraphDetail}>
                    <Autocomplete
                      multiple={true}
                      className={classes.ctaRegion}
                      limitTags={3}
                      fullWidth={true}
                      disableCloseOnSelect
                      options={triggers}
                      variant="outlined"
                      value={triggers.filter(keys => {
                        let match = false
                        triggerList.forEach(itm => {
                          if (
                            itm.replace(/^\s+!|^\s+|^!|\s+$/g, "") === keys.type
                          ) {
                            match = true
                          }
                        })
                        return match
                      })}
                      getOptionLabel={option => option.type}
                      isOptionEqualToValue={(option, value) =>
                        option.type === value.type
                      }
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selected}
                          />
                          {option.type}
                        </li>
                      )}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Selected Triggers"
                          required={true}
                        />
                      )}
                      onChange={(event, newValue, reason) => {
                        handleAutoCompleteMultiChangeForTriggerType(
                          event,
                          newValue,
                          reason,
                          "triggerType"
                        )
                      }}
                    />
                    <Tooltip
                      id="tooltip-top"
                      title="Manually Edit Triggers"
                      placement="top"
                    >
                      <IconButton
                        className={classes.iconButton}
                        aria-label="Manually Edit Triggers"
                        onClick={() => setManualEdit(true)}
                        size="large"
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  </GridItem>
                )}

                <GridItem xs={1} className={classes.paragraphDetail}>
                  <ValidateTriggers
                    triggerType={paragraphFormValues.triggerType}
                    triggerKeys={triggerKeys}
                    triggerValue={triggerValue}
                    triggerBlurred={triggerBlurred}
                    size={"40px"}
                    setTriggerValue={val => {
                      setTriggerValue(val)
                    }}
                  />
                </GridItem>

                <GridItem xs={3} className={classes.paragraphDetail}>
                  <TextField
                    label="TRIGGER WEIGHT"
                    type="number"
                    name="triggerWeight"
                    id="triggerWeight"
                    value={paragraphFormValues.triggerWeight}
                    onChange={handleChange}
                    readOnly={loadingParagraph}
                  />
                </GridItem>
              </Grid>
            </Card>
          )}
          <Grid className={classes.modalButton}>
            <>
              <Button
                className={classes.buttonStyle}
                variant="contained"
                size="medium"
                color="secondary"
                onClick={props.handleClose}
              >
                Cancel
              </Button>
              <Button
                className={classes.buttonStyle}
                variant="contained"
                size="medium"
                color="primary"
                type="submit"
                onClick={() => {
                  handleSave()
                }}
              >
                Save
              </Button>
            </>
          </Grid>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  )
}

export default ParagraphEditor
