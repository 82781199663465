import React from "react"
import {
  IconButton,
  Checkbox,
  FormControlLabel,
  Tooltip,
  LinearProgress
} from "@mui/material"
import PropTypes from "prop-types"
import { Input } from "reactstrap"
import SearchIcon from "@mui/icons-material/Search"
import GreyTextInput from "components/styled/GreyTextInput"
import { Clear, MoreVert, FindReplace } from "@mui/icons-material"
import withStyles from "@mui/styles/withStyles"
import BorderedLabel from "components/styled/BorderedLabel"

const styles = theme => ({
  searchIcon: {
    padding: "6px",
    backgroundColor: "#fff"
  },
  selectInput: {
    margin: 5,
    paddingLeft: "10px",
    flexGrow: 2,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%"
    }
  },
  controlRow: {
    display: "flex",
    backgroundColor: "#fff"
  },
  iconButton: {
    padding: "6px"
  }
})

function SearchBlock(props) {
  const { classes, getSearchString, loadingSearchResults } = props
  const [searchInName, setSearchInName] = React.useState(true)
  const [searchInTrigger, setSearchInTrigger] = React.useState(true)
  const [searchInTemplate, setSearchInTemplate] = React.useState(true)
  const [isSearching, setIsSearching] = React.useState(false)
  const [toggleReplaceView, setToggleReplaceView] = React.useState(false)

  const [searchType, setSearchType] = React.useState("blocks")
  const [searchString, setSearchString] = React.useState("")
  const [searchBlockIds, setSearchBlockIds] = React.useState("")
  const [replaceString, setReplaceString] = React.useState("")

  React.useEffect(() => {
    if (!getSearchString) {
      setSearchString("")
      setSearchBlockIds("")
      setReplaceString("")
    }
    if (!loadingSearchResults) {
      setIsSearching(false)
    } else {
      setIsSearching(true)
    }
  }, [getSearchString, loadingSearchResults])

  const clearFilters = () => {
    setSearchString("")
    setSearchBlockIds("")
    setReplaceString("")
  }

  return (
    <>
      <div className={classes.controlRow}>
        <Input
          className={classes.selectInput}
          type="select"
          style={{
            display: "block",
            height: "auto",
            width: "160px"
          }}
          value={searchType}
          onChange={event => {
            props.setSearchType(event.target.value)
            setSearchType(event.target.value)
          }}
        >
          <option value="blocks">Search Blocks</option>
          <option value="triggers">Search Triggers</option>
        </Input>
        <FormControlLabel
          style={{ margin: "0", color: "#999999" }}
          control={
            <Checkbox
              checked={searchInName}
              title="Name"
              onChange={event => {
                props.setSearchInName(event.target.checked)
                setSearchInName(event.target.checked)
              }}
            />
          }
          label="Name"
          title="Name"
        />
        <FormControlLabel
          style={{ margin: "0", color: "#999999" }}
          control={
            <Checkbox
              checked={searchInTrigger}
              title={searchType === "blocks" ? "Trigger" : "Key"}
              onChange={event => {
                props.setSearchInTrigger(event.target.checked)
                setSearchInTrigger(event.target.checked)
              }}
            />
          }
          label={searchType === "blocks" ? "Trigger" : "Key"}
          title={searchType === "blocks" ? "Trigger" : "Key"}
        />
        <FormControlLabel
          style={{ margin: "0", color: "#999999" }}
          control={
            <Checkbox
              checked={searchInTemplate}
              onChange={event => {
                props.setSearchInTemplate(event.target.checked)
                setSearchInTemplate(event.target.checked)
              }}
            />
          }
          label={searchType === "blocks" ? "Template" : "Formula"}
          title={searchType === "blocks" ? "Template" : "Formula"}
        />
        <GreyTextInput
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          value={searchString}
          style={{ margin: 5 }}
          onChange={event => {
            setSearchString(event.target.value)
          }}
          onBlur={event => {
            props.setSearchString(event.target.value)
          }}
        />
        <GreyTextInput
          style={{
            flexShrink: "1.2",
            margin: 5
          }}
          placeholder={
            searchType === "blocks"
              ? "Paragraph Ids e.g. 1,2,3"
              : "Trigger Ids e.g. 1,2,3"
          }
          inputProps={{ "aria-label": "filter" }}
          value={searchBlockIds}
          onChange={event => {
            if (event.target.value.replace(/\d|,/g, "")) {
              //only accept numbers and commas for input here
              return
            }
            setSearchBlockIds(event.target.value)
          }}
          onBlur={event => {
            props.setSearchBlockIds(event.target.value)
          }}
        />
        <Tooltip id="tooltip-top" title="Search" placement="top">
          <IconButton
            className={classes.iconButton}
            aria-label="Search"
            onClick={() => {
              setIsSearching(true)
              props.searchForString(searchType)
            }}
            disabled={!searchString}
            size="large"
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
        <Tooltip id="tooltip-top-clear" title="Clear Fields" placement="top">
          <IconButton
            className={classes.iconButton}
            aria-label="Clear Fields"
            onClick={() => {
              setIsSearching(false)
              clearFilters()
              props.clearFilters()
            }}
            size="large"
          >
            <Clear />
          </IconButton>
        </Tooltip>
        <Tooltip id="tooltip-replace" title="Replace Text" placement="top">
          <IconButton
            className={classes.iconButton}
            aria-label="Replace Text"
            onClick={() => {
              setToggleReplaceView(!toggleReplaceView)
            }}
            size="large"
          >
            <MoreVert />
          </IconButton>
        </Tooltip>
      </div>
      {toggleReplaceView && (
        <div className={classes.controlRow}>
          <BorderedLabel style={{ margin: "5px 10px 5px 5px" }}>
            Replacement Text
          </BorderedLabel>
          <GreyTextInput
            placeholder="Replace With…"
            inputProps={{ "aria-label": "search" }}
            value={replaceString}
            style={{ margin: 5 }}
            onChange={event => {
              setReplaceString(event.target.value)
            }}
            onBlur={event => {
              props.setReplaceStringValue(event.target.value)
            }}
          />
          <Tooltip id="tooltip-top" title="Replace All" placement="top">
            <IconButton
              className={classes.iconButton}
              aria-label="Replace All"
              onClick={() => {
                setIsSearching(true)
                props.setReplaceStrings(searchType)
              }}
              size="large"
            >
              <FindReplace />
            </IconButton>
          </Tooltip>
        </div>
      )}
      {isSearching && <LinearProgress />}
    </>
  )
}
SearchBlock.propTypes = {
  setSearchInName: PropTypes.func,
  setSearchType: PropTypes.func,
  setSearchInTrigger: PropTypes.func,
  setSearchInTemplate: PropTypes.func,
  setSearchString: PropTypes.func,
  searchForString: PropTypes.func,
  setSearchBlockIds: PropTypes.func,
  setReplaceStringValue: PropTypes.func,
  setReplaceString: PropTypes.func,
  clearFilters: PropTypes.func,
  emptySearchReturned: PropTypes.bool,
  loadingSearchResults: PropTypes.bool,
  emptySearchString: PropTypes.string
}
export default withStyles(styles)(SearchBlock)
