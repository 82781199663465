import React from "react"
import moment from "moment-timezone"

import { DEFAULT_TIMEZONE, DATE_TIME_FORMAT } from "../constants"

const FormattedDate = ({ children }) => (
  <div
    title={moment
      .tz(children, DEFAULT_TIMEZONE)
      .format(DATE_TIME_FORMAT.LONG_TIME)}
  >
    {moment.tz(children, DEFAULT_TIMEZONE).format(DATE_TIME_FORMAT.SHORT_DATE)}
  </div>
)

export default FormattedDate
