import { globalSettings } from "variables/general.jsx"
import { put } from "../tools/dennyRequest"

export default class DSChatDAO {
  // A message object has two properties, role and content
  // {
  //   role: "system|assistant|user",
  //   content: "This is where the prompt goes"
  // }
  //
  // askChatGPTPrompt takes single message object, returns single message object
  static askChatGPTPrompt = async body => {
    const res = await put(
      `${globalSettings.dennyBaseUrl}/api/chatcompletion/scriban-prompt`,
      null,
      body
    )
    return res.text()
  }
  // askChatGPT takes a list of message objects, returns a list of message objects
  static askChatGPT = async body => {
    const res = await put(
      `${globalSettings.dennyBaseUrl}/api/chatcompletion/scriban-chat`,
      null,
      body
    )
    return res.json()
  }
  static askChatGPT_V2 = async body => {
    const res = await put(
      `${globalSettings.dennyBaseUrl}/api/chatcompletion`,
      null,
      body
    )
    return res.json()
  }
}
