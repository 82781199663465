import { Box } from "@mui/material"
import { styled } from "@mui/styles"

const BorderedLabel = styled(Box)(() => ({
  display: "block",
  width: "189px",
  fontWeight: 400,
  color: "#495057",
  backgroundColor: "#fff",
  backgroundClip: "padding-box",
  border: "1px solid #ced4da",
  borderRadius: "0.25rem",
  height: "36px",
  padding: "7px 10px",
  fontSize: "16px",
  lineHeight: "1.42857"
}))

export default BorderedLabel
