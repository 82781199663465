function NarrativeOutlineMoverConfirmDialogContent(props) {
  const {
    eligibleEntitiesCount,
    targetNarrative,
    sourceNarrative,
    allSourceInfo,
    allTargetInfo
  } = props

  const styles = {
    textAlign: "left"
  }
  return (
    <div style={styles}>
      <u>
        {" "}
        The following{" "}
        {eligibleEntitiesCount - 1
          ? `${eligibleEntitiesCount} blocks `
          : `block `}
        will be copied from:{" "}
      </u>
      <br />
      Narrative #{sourceNarrative?.id} - {sourceNarrative?.name}
      {eligibleEntitiesCount > 0 && (
        <ol>
          {allSourceInfo?.map(info => (
            <li
              key={info.outlineId}
              style={{
                fontWeight: "bold"
              }}
            >
              {info.outlineName
                ? `(${info.outlineId}) Outline Block ${info.outlineName}`
                : `(${info.outlineId}) Outline Block (Has no Outline Name)`}
            </li>
          ))}
        </ol>
      )}
      {targetNarrative?.name && (
        <div>
          <u>The blocks will be copied to:</u>
          <br />
          Narrative #{targetNarrative?.id} - {targetNarrative?.name}
        </div>
      )}
      {allTargetInfo?.length > 0 && (
        <ol>
          {allTargetInfo.map(info => (
            <li
              key={info.outlineId}
              style={{
                fontWeight: "bold"
              }}
            >
              {info.outlineName
                ? `To (${info.outlineId}) Paragraph ${info.outlineName}`
                : `To (${info.outlineId}) Paragraph (Has no name)`}
            </li>
          ))}
        </ol>
      )}
    </div>
  )
}
export default NarrativeOutlineMoverConfirmDialogContent
