import React, { Component } from "react"
import InputLabel from "@mui/material/InputLabel"
import GridItem from "components/Grid/GridItem.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardIcon from "components/Card/CardIcon.jsx"
import ImageSearchIcon from "@mui/icons-material/ImageSearch"
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle"
import withStyles from "@mui/styles/withStyles"
import GridContainer from "components/Grid/GridContainer.jsx"
import Button from "components/CustomButtons/Button"
import Slider from "@mui/material/Slider"
import ImageSearchTermResults from "./ImageSearchTermResults"
import { Cookies } from "tools/storage"
import Helpers from "tools/Helpers.js"
import TimeAgo from "react-timeago"
import Check from "@mui/icons-material/Check"
import { Checkbox } from "@mui/material"
import { observer, inject } from "mobx-react"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import TextField from "@mui/material/TextField"

const useStore = component =>
  inject(({ store }) => ({
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization
  }))(observer(component))

const ENTER_KEY = 13

class ImageSearchTool extends Component {
  constructor(props) {
    super(props)
    let cookies = new Cookies()
    let imageSearchToolTerms = cookies.get("imageSearchToolTerms")
    let imageSearchToolKeywords = cookies.get("imageSearchToolKeywords")
    let isSearchGettyImagesChecked = cookies.get(
      "imageSearchToolisSearchGettyImagesChecked"
    )
    let isSearchImagnChecked = cookies.get(
      "imageSearchToolisSearchImagnChecked"
    )
    let isSearchAPMediaChecked = cookies.get(
      "imageSearchToolisSearchAPMediaChecked"
    )
    let isSearchImagoChecked = cookies.get(
      "imageSearchToolisSearchIMagoChecked"
    )
    let isDisplayCURLCommandChecked = cookies.get(
      "imageSearchToolisDisplayCURLCommandChecked"
    )

    this.state = {
      selectedImage: { width: "X", height: "Y" },
      pollyCostPerMillion: 4,
      areGettyImagesLoaded: true,
      areImagnImagesLoaded: true,
      areAPImagesLoaded: true,
      terms: imageSearchToolTerms ? imageSearchToolTerms : `Seattle Sounders`,
      keywords: imageSearchToolKeywords ? imageSearchToolKeywords : ``,
      peopleNames: ``,
      gettyImageId: 12345,
      gettyAPIUrl: ``,
      imagnAPIUrl: ``,
      apMediaAPIUrl: ``,
      images: [],
      imagesWidth: 160,
      maxImagesCount: 6,
      statisticThresholdValue: 5.4,
      gettyImages: [],
      imagnImages: [],
      apImages: [],
      seasonYears: [],
      seasonYearsLoaded: false,
      isSearchGettyImagesChecked,
      isSearchImagnChecked,
      isSearchAPMediaChecked,
      isSearchImagoChecked,
      isDisplayCURLCommandChecked,
      searchTerms: [],
      findImagesButtonClicked: false,
      doIncludeSIPAImages: false,
      doShowNumberOfPeople: false,
      gettyImagesNumberOfPeople: -1
    }
    this.onKeywordsTextAreaKeyDown = this.onKeywordsTextAreaKeyDown.bind(this)
    this.onTermsTextAreaKeyDown = this.onTermsTextAreaKeyDown.bind(this)
  }

  componentDidMount() {
    if (!this.props.isEmbedded) {
      this.props.setShowChangeCurrentOrganization(false)
    }
    this.searchImages()
  }

  imgEl = React.createRef()

  onIsGettyImagesSearchCheckedChanged = () => {
    this.setState(
      {
        isSearchGettyImagesChecked: !this.state.isSearchGettyImagesChecked
      },
      this.searchImages
    )
  }

  onIsImagnSearchCheckedChanged = () => {
    this.setState(
      {
        isSearchImagnChecked: !this.state.isSearchImagnChecked
      },
      this.searchImages
    )
  }

  onIsAPMediaSearchCheckedChanged = () => {
    this.setState(
      {
        isSearchAPMediaChecked: !this.state.isSearchAPMediaChecked
      },
      this.searchImages
    )
  }

  onIsImagoSearchCheckedChanged = () => {
    this.setState(
      {
        isSearchImagoChecked: !this.state.isSearchImagoChecked
      },
      this.searchImages
    )
  }

  onisDisplayCURLCommandCheckedChanged = () => {
    this.setState(
      {
        isDisplayCURLCommandChecked: !this.state.isDisplayCURLCommandChecked
      },
      this.searchImages
    )
  }

  onDoIncludeSIPAImagesCheckedChanged = () => {
    this.setState(
      {
        doIncludeSIPAImages: !this.state.doIncludeSIPAImages
      },
      this.searchImages
    )
  }

  searchImages = () => {
    let cookies = new Cookies()
    cookies.set("imageSearchToolTerms", this.state.terms, {
      path: "/",
      expires: Helpers.CookieExpiration.OneMonth
    })
    cookies.set("imageSearchToolKeywords", this.state.keywords, {
      path: "/",
      expires: Helpers.CookieExpiration.OneMonth
    })
    cookies.set(
      "imageSearchToolisSearchGettyImagesChecked",
      this.state.isSearchGettyImagesChecked,
      {
        path: "/",
        expires: Helpers.CookieExpiration.OneMonth
      }
    )
    cookies.set(
      "imageSearchToolisSearchImagnChecked",
      this.state.isSearchImagnChecked,
      {
        path: "/",
        expires: Helpers.CookieExpiration.OneMonth
      }
    )
    cookies.set(
      "imageSearchToolisSearchAPMediaChecked",
      this.state.isSearchAPMediaChecked,
      {
        path: "/",
        expires: Helpers.CookieExpiration.OneMonth
      }
    )
    cookies.set(
      "imageSearchToolisSearchImagoChecked",
      this.state.isSearchImagoChecked,
      {
        path: "/",
        expires: Helpers.CookieExpiration.OneMonth
      }
    )
    cookies.set(
      "imageSearchToolisDisplayCURLCommandChecked",
      this.state.isDisplayCURLCommandChecked,
      {
        path: "/",
        expires: Helpers.CookieExpiration.OneMonth
      }
    )

    // Multiple image search phrases are deliminated using a carriage return/new line
    this.setState({
      searchTerms: this.state.terms.split("\n"),
      searchKeywords: this.state.keywords.split("\n"),
      findImagesButtonClicked: !this.state.findImagesButtonClicked
    })
  }

  roundAspectRatio = (width, height) => {
    let aspectRatio = width / height
    // Epsilon handles the floating point issue of round numbers
    // such as 1.005
    return Math.round((aspectRatio + Number.EPSILON) * 100) / 100
  }

  onImagesWidthChange(width) {
    this.setState({ imagesWidth: width })
  }

  onMaxImagesCountChange(width) {
    this.setState({ maxImagesCount: width })
  }

  onKeywordsAreaChange = e => {
    this.setState({ keywords: e.target.value })
  }

  onTermsAreaChange = e => {
    this.setState({ terms: e.target.value })
  }

  onTermsTextAreaKeyDown(e) {
    if (e.ctrlKey && e.keyCode === ENTER_KEY) {
      this.setState({
        editorialSearchImages: `{}`
      })
      this.searchImages()
    }
  }

  onKeywordsTextAreaKeyDown(e) {
    if (e.ctrlKey && e.keyCode === ENTER_KEY) {
      this.setState({
        editorialSearchImages: `{}`
      })
      this.searchImages()
    }
  }

  imageItemClicked = imageItem => {
    if (imageItem) {
      this.setState({
        selectedImage: {
          externalId: imageItem.externalId,
          url: imageItem.url,
          title: imageItem.title,
          caption: imageItem.caption,
          photographer: imageItem.photographer,
          naturalWidth: imageItem.width,
          naturalHeight: imageItem.height,
          maxDownloadWidth: imageItem.maxDownloadWidth,
          maxDownloadHeight: imageItem.maxDownloadHeight,
          photoTakenDateTime: imageItem.photoTakenDateTime
        }
      })
    }
  }

  handleSelectChange = event => {
    const value = event.target.value
    const key = event.target.name
    this.setState(
      {
        [key]: value
      },
      () => this.searchImages()
    )
  }

  render() {
    const { classes } = this.props
    const { imagesWidth, maxImagesCount, selectedImage } = this.state

    return (
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <ImageSearchIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Search Getty, Imagn, AP &amp; Imago for Images
            </h4>
          </CardHeader>
          <CardBody>
            <div className="content">
              <form>
                <GridContainer>
                  <GridItem md={12}>
                    <TextField
                      label="Terms (compare searches using disinct lines)"
                      id="Terms"
                      fullWidth={true}
                      type="text"
                      multiline={true}
                      rows={5}
                      placeholder="Type search terms (multiple terms on distinct lines)"
                      value={this.state.terms}
                      onChange={this.onTermsAreaChange}
                      onKeyDown={this.onTermsTextAreaKeyDown}
                      helperText="(Ctrl-Enter) to search."
                    />
                  </GridItem>
                  {this.state.isSearchImagnChecked && (
                    <GridItem md={12}>
                      <TextField
                        label="Keywords (compare searches using disinct lines)"
                        id="Keywords"
                        fullWidth={true}
                        multiline={true}
                        rows={5}
                        placeholder="Type Imagn search keywords (multiple keywords on distinct lines)"
                        value={this.state.keywords}
                        onChange={this.onKeywordsAreaChange}
                        onKeyDown={this.onKeywordsTextAreaKeyDown}
                        helperText="(Ctrl-Enter) will perform search."
                      />
                    </GridItem>
                  )}
                </GridContainer>

                <GridContainer>
                  <GridItem md={3}>
                    <GridContainer>
                      <GridItem md={12}>
                        <Checkbox
                          checked={this.state.isSearchGettyImagesChecked}
                          onChange={this.onIsGettyImagesSearchCheckedChanged}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                        />{" "}
                        Search Getty Images?
                      </GridItem>
                      <GridItem md={12}>
                        <InputLabel>Number of People</InputLabel>

                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.gettyImagesNumberOfPeople}
                          onChange={this.handleSelectChange}
                          inputProps={{
                            name: "gettyImagesNumberOfPeople",
                            id: "gettyImagesNumberOfPeople"
                          }}
                          disabled={!this.state.isSearchGettyImagesChecked}
                        >
                          <MenuItem
                            key={-1}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={-1}
                          >
                            Not Applicable
                          </MenuItem>
                          <MenuItem
                            key={0}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={"nopeople"}
                          >
                            None
                          </MenuItem>
                          <MenuItem
                            key={2}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={"oneperson"}
                          >
                            One
                          </MenuItem>
                          <MenuItem
                            key={4}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={"twopeople"}
                          >
                            Two
                          </MenuItem>
                          <MenuItem
                            key={8}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={"groupofpeople"}
                          >
                            Group
                          </MenuItem>
                        </Select>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem md={3}>
                    <GridContainer>
                      <GridItem md={12}>
                        <Checkbox
                          checked={this.state.isSearchImagnChecked}
                          onChange={this.onIsImagnSearchCheckedChanged}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                        />{" "}
                        Search Imagn?
                      </GridItem>
                      <GridItem
                        md={12}
                        style={{
                          color: !this.state.isSearchImagnChecked
                            ? "gray"
                            : "black"
                        }}
                      >
                        <Checkbox
                          checked={this.state.doIncludeSIPAImages}
                          onChange={this.onDoIncludeSIPAImagesCheckedChanged}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                            disabled: classes.disabledCheckboxAndRadio
                          }}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          disabled={!this.state.isSearchImagnChecked}
                        />{" "}
                        Include SIPA Images?
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem md={3}>
                    <Checkbox
                      checked={this.state.isSearchAPMediaChecked}
                      onChange={this.onIsAPMediaSearchCheckedChanged}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                    />{" "}
                    Search AP Media?
                  </GridItem>
                  <GridItem md={3}>
                    <Checkbox
                      checked={this.state.isSearchImagoChecked}
                      onChange={this.onIsImagoSearchCheckedChanged}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                    />{" "}
                    Search Imago?
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem>
                    <Checkbox
                      checked={this.state.isDisplayCURLCommandChecked}
                      onChange={this.onisDisplayCURLCommandCheckedChanged}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                    />{" "}
                    Display cURL Commands?
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem sm={12}>
                    <Button onClick={this.searchImages} color="primary">
                      Find Images
                    </Button>
                  </GridItem>
                  <GridItem sm={12}>
                    <h5>Selected image:</h5>
                  </GridItem>
                  <GridItem md={12}>
                    <InputLabel style={{ color: "#AAA" }}>
                      External Id
                    </InputLabel>
                    {selectedImage.externalId}
                  </GridItem>
                  <GridItem md={9}>
                    <InputLabel style={{ color: "#AAA" }}>Url</InputLabel>
                    {selectedImage.url}
                  </GridItem>
                  <GridItem md={12}>
                    <InputLabel style={{ color: "#AAA" }}>Title</InputLabel>
                    {selectedImage.title}
                  </GridItem>
                  <GridItem md={12}>
                    <InputLabel style={{ color: "#AAA" }}>Caption</InputLabel>
                    {selectedImage.caption}
                  </GridItem>
                  <GridItem md={12}>
                    <InputLabel style={{ color: "#AAA" }}>
                      Photographer
                    </InputLabel>
                    {selectedImage.photographer}
                  </GridItem>
                  <GridItem md={12}>
                    <InputLabel style={{ color: "#AAA" }}>
                      Preview Dimensions
                    </InputLabel>
                    {selectedImage.naturalWidth && (
                      <span>
                        {selectedImage.naturalWidth} x{" "}
                        {selectedImage.naturalHeight} (natural) (aspect ratio:{" "}
                        {this.roundAspectRatio(
                          selectedImage.naturalWidth,
                          selectedImage.naturalHeight
                        )}
                        )
                      </span>
                    )}
                    {!selectedImage.naturalWidth && <span>N/A</span>}
                  </GridItem>
                  <GridItem md={12}>
                    <InputLabel style={{ color: "#AAA" }}>
                      Max Download Dimensions
                    </InputLabel>
                    {selectedImage.maxDownloadWidth && (
                      <span>
                        {selectedImage.maxDownloadWidth} x{" "}
                        {selectedImage.maxDownloadHeight} (aspect ratio:{" "}
                        {this.roundAspectRatio(
                          selectedImage.maxDownloadWidth,
                          selectedImage.maxDownloadHeight
                        )}
                        )
                      </span>
                    )}
                    {!selectedImage.maxDownloadWidth && <span>N/A</span>}
                  </GridItem>
                  <GridItem md={12}>
                    <InputLabel style={{ color: "#AAA" }}>
                      Photo Taken
                    </InputLabel>
                    {selectedImage.photoTakenDateTime && (
                      <>
                        <TimeAgo
                          date={selectedImage.photoTakenDateTime}
                          title={Helpers.prettyDateTimeinPacificTimeZone(
                            selectedImage.photoTakenDateTime
                          )}
                        />{" "}
                        (
                        {Helpers.prettyDateTimeinPacificTimeZone(
                          selectedImage.photoTakenDateTime
                        )}
                        )
                      </>
                    )}
                  </GridItem>
                  <GridContainer>
                    <GridItem sm={12}>
                      <h5>Image results:</h5>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                      Image Width ({imagesWidth}px):
                      <Slider
                        value={imagesWidth}
                        onChange={(e, value) => this.onImagesWidthChange(value)}
                        aria-labelledby="input-slider"
                        max={600}
                        min={50}
                        step={10}
                        valueLabelDisplay="auto"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                      Max Image Count ({maxImagesCount}):
                      <Slider
                        value={maxImagesCount}
                        onChange={(e, value) =>
                          this.onMaxImagesCountChange(value)
                        }
                        aria-labelledby="input-slider"
                        max={10}
                        min={1}
                        step={1}
                        valueLabelDisplay="auto"
                      />
                    </GridItem>
                  </GridContainer>
                  {this.state.searchTerms.map((item, index) => (
                    // Search Getty and pass results
                    <GridItem md={12}>
                      <ImageSearchTermResults
                        searchTerms={item}
                        searchKeywords={this.state.searchKeywords[index]}
                        doLoadGetty={this.state.isSearchGettyImagesChecked}
                        doLoadImagn={this.state.isSearchImagnChecked}
                        doLoadAPMedia={this.state.isSearchAPMediaChecked}
                        doLoadImago={this.state.isSearchImagoChecked}
                        findImagesButtonClicked={
                          this.state.findImagesButtonClicked
                        }
                        maxImagesCount={this.state.maxImagesCount}
                        displayAPIRequest={
                          this.state.isDisplayCURLCommandChecked
                        }
                        imagesWidth={this.state.imagesWidth}
                        onImageClickCallBack={this.imageItemClicked}
                        selectedImage={this.state.selectedImage}
                        doIncludeSIPAImages={this.state.doIncludeSIPAImages}
                        gettyImagesNumberOfPeople={
                          this.state.gettyImagesNumberOfPeople
                        }
                      />
                    </GridItem>
                  ))}
                </GridContainer>
              </form>
            </div>
          </CardBody>
        </Card>
      </GridItem>
    )
  }
}

export default withStyles(regularFormsStyle)(useStore(ImageSearchTool))
