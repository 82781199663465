import React, { useState, useEffect } from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"

import makeStyles from "@mui/styles/makeStyles"
import Card from "@mui/material/Card"
import AppBar from "@mui/material/AppBar"
import GridContainer from "components/Grid/GridContainer"
import Grid from "@mui/material/Grid"
import GridItem from "components/Grid/GridItem"
import TabList from "@mui/lab/TabList"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabPanel from "@mui/lab/TabPanel"
import CardContent from "@mui/material/CardContent"
import FormControlLabel from "@mui/material/FormControlLabel"
import Typography from "@mui/material/Typography"
import CheckBox from "@mui/material/Checkbox"
import Button from "components/CustomButtons/Button"
import Dialog from "@mui/material/Dialog"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"

import NarrativeBlockExplorer from "components/NarrativeAdmin/NarrativeBlockExplorer"
import TriggerDAO from "daos/triggerDAO"
import LibraryNarrativeClone from "./LibraryNarrativeClone"
import TriggerTableEditWizard from "components/NarrativeAdmin/TriggerEdit/TriggerTableEditWizard"
import TriggerIDsToContentBlocksWizard from "components/NarrativeAdmin/TriggerEdit/TriggerIDsToContentBlocksWizard"
import LibraryCopySuccessModal from "components/NarrativeAdmin/LibraryCopyTools/LibraryCopySuccessModal"
import InterstitialConfirmDialog from "components/NarrativeAdmin/LibraryCopyTools/InterstitialConfirmDialog"
import { processEntity } from "tools/LibraryCopyTools"
import useEntityHelper from "tools/LibraryCopyTools/EntityHelper"
import useNarrativeOptions from "hooks/narratives/useNarrativeOptions"
import NarrativeAutoComplete from "components/NarrativeAdmin/LibraryCopyTools/NarrativeAutocomplete"
import InfoDialog from "components/CustomDialogs/InfoDialog"

const useStyles = makeStyles({
  title: {
    paddingTop: "5px",
    paddingBottom: "5px"
  },
  copyLibary: {
    display: "flex",
    alignItems: "center"
  },
  libraryExplorerArea: {
    height: "100%",
    flex: "0 0 380px"
  },
  explorerContainer: {
    position: "relative",
    overflowY: "auto",
    height: 500
  },
  libraryCopyButton: {
    fontSize: "12px",
    padding: "7px 14px",
    minWidth: "110px"
  },
  actionArea: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "5%",
    gap: 20,
    minWidth: 220
  },
  copyButtonContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 6
  },
  includeTriggers: {
    margin: "0 4px"
  },
  includeTriggersLabel: {
    fontSize: "0.8rem"
  },
  gridContainer: {
    alignItems: "start",
    flexWrap: "nowrap"
  },
  tabPanel: {
    padding: 12
  },
  inactive: {
    textDecoration: "line-through"
  }
})

function useStoreData() {
  const store = useStore()

  return useObserver(() => ({
    loading: store.narrativeStore.loading,
    loadingSourceNarrative: store.narrativeStore.loadingSourceNarrative,
    loadingTargetNarrative: store.narrativeStore.loadingTargetNarrative,
    getSourceNarrativeBriefLibrary:
      store.narrativeStore.getSourceNarrativeBriefLibrary,
    sourceNarrative: store.narrativeStore.sourceNarrative,
    getTargetNarrativeBriefLibrary:
      store.narrativeStore.getTargetNarrativeBriefLibrary,
    targetNarrative: store.narrativeStore.targetNarrative,
    postCopySentence: store.narrativeStore.postCopySentence,
    postCopyParagraph: store.narrativeStore.postCopyParagraph,
    postCopyTrigger: store.narrativeStore.postCopyTrigger,
    copiedElements: store.narrativeStore.copiedElements,
    setCopiedElements: store.narrativeStore.setCopiedElements,
    setSourceNarrative: store.narrativeStore.setSourceNarrative,
    setTargetNarrative: store.narrativeStore.setTargetNarrative
  }))
}

function LibraryCopyTools() {
  const {
    getSourceNarrativeBriefLibrary,
    getTargetNarrativeBriefLibrary,
    sourceNarrative,
    targetNarrative,
    postCopyParagraph,
    postCopySentence,
    postCopyTrigger,
    loading,
    loadingSourceNarrative,
    loadingTargetNarrative,
    copiedElements,
    setCopiedElements,
    setSourceNarrative,
    setTargetNarrative
  } = useStoreData()

  const classes = useStyles()

  const [confirmOpen, setConfirmOpen] = useState(false)
  const [triggerCopyWizardOpen, setTriggerCopyWizardOpen] = useState(false)
  const [
    triggerIDsToContentBlocksWizardOpen,
    setTriggerIDsToContentBlocksWizardOpen
  ] = useState(false)
  const [savedTriggerResults, setSavedTriggerResults] = useState([])

  const [submittedTriggerChanges, setSubmittedTriggerChanges] = useState(null)
  const [showInactiveLibraries, setShowInactiveLibraries] = useState()
  const [showArchivedLibraries, setShowArchivedLibraries] = useState(false)
  const [tabValue, setTabValue] = useState("0")
  const [includeAssociatedTriggers, setIncludeAssociatedTriggers] =
    useState(false)
  const [numEntitiesBeingCopied, setNumEntitiesBeingCopied] = useState(0)

  // Source Info
  const [selectedSourceNarrative, setSelectedSourceNarrative] = useState(null)
  const [selectedSourceIds, setSelectedSourceIds] = useState([])

  // Target Info
  const [selectedTargetNarrative, setSelectedTargetNarrative] = useState(null)
  const [selectedTargetIds, setSelectedTargetIds] = useState([])
  const [missingTriggerIdsList, setMissingTriggerIdsList] = useState([])
  const [savingTriggers, setSavingTriggers] = useState(false)

  const sourceNarrativeId = selectedSourceNarrative?.id
  const targetNarrativeId = selectedTargetNarrative?.id

  const {
    hasEntities,
    getFilteredEntitiesByRegex,
    noTriggersInEntities,
    onlyTriggersInEntities,
    haveSomeEntityByRegex,
    onlySentencesInEntities
  } = useEntityHelper({ entities: selectedSourceIds })

  const {
    fetchNarratives,
    narrativeOptions,
    isLoading: narrativeOptionsLoading,
    nonArchivedNarratives
  } = useNarrativeOptions()

  useEffect(() => {
    fetchNarratives()
  }, [])

  // We only want paragraphs for targets
  const targetsAreValid = () =>
    selectedTargetIds?.length &&
    !selectedTargetIds.some(entity => {
      const { type } = processEntity(entity)
      return type !== "paragraphid"
    })

  async function postCopyNonSentenceBlock({ type, id }) {
    if (type === "triggerid" || type === "newstriggerid") {
      return postCopyTrigger(id, targetNarrativeId)
    } else if (type === "paragraphid") {
      return postCopyParagraph(id, targetNarrativeId)
    } else if (type === "sentenceid") {
      throw new Error("Sentence copying should use copySentencesToParagraphs()")
    }
  }

  const processBlocks = async blocks => {
    try {
      if (hasEntities() && onlySentencesInEntities() && targetsAreValid()) {
        await copySentencesToParagraphs()
      } else {
        setNumEntitiesBeingCopied(blocks.length)
        const promises = blocks.map(block => {
          const blockParts = processEntity(block)
          return postCopyNonSentenceBlock(blockParts)
        })
        await Promise.all(promises)
      }
    } catch (err) {
      console.error(
        `Error copying blocks to library ${selectedTargetNarrative?.id}`,
        err
      )
    }
  }

  const resetAfterCopy = () => {
    getTargetNarrativeBriefLibrary(targetNarrativeId)
    getSourceNarrativeBriefLibrary(sourceNarrativeId)
    setSelectedSourceIds([])
    setSelectedTargetIds([])
  }

  const onCloseSuccessModal = () => {
    setSubmittedTriggerChanges(null)
    setCopiedElements([])
    setNumEntitiesBeingCopied(0)
  }

  const copySentencesToParagraphs = async () => {
    setCopiedElements([])
    setNumEntitiesBeingCopied(
      selectedSourceIds.length * selectedTargetIds.length
    )

    const promises = []
    selectedTargetIds.forEach(target => {
      selectedSourceIds.forEach(src => {
        const { id: srcId } = processEntity(src)
        const { id: targetId } = processEntity(target)
        if (srcId && targetId) {
          promises.push(
            postCopySentence(Number(srcId), Number(targetId), targetNarrativeId)
          )
        }
      })
    })
    await Promise.all(promises)
  }

  const copyTriggersWithNoChanges = async (triggers = []) => {
    const validTriggers = triggers
    const promises = validTriggers.map(async id => {
      await postCopyTrigger(id, targetNarrativeId)
    })
    await Promise.all(promises)
  }

  const copyTriggersAndGetChangesWithNewIds = async (
    triggers = [],
    triggerChanges = []
  ) => {
    const newTriggerIdMap = {}
    const validTriggers = triggers.filter(trigger => !!trigger?.id)
    const promises = validTriggers.map(async ({ id }) => {
      const newTriggerId = await postCopyTrigger(id, targetNarrativeId)
      newTriggerIdMap[id] = newTriggerId
    })

    await Promise.all(promises)

    const triggerChangesWithNewIds = triggerChanges.map(change => ({
      ...change,
      itemId: newTriggerIdMap[change.itemId]
    }))
    return triggerChangesWithNewIds || []
  }

  const replaceInOneTrigger = async triggerChangeEntry => {
    const { itemId, changes } = triggerChangeEntry
    const promises = changes.map(({ field, oldValue, newValue }) =>
      TriggerDAO.replaceInTriggers({
        narrativeId: targetNarrativeId,
        searchString: oldValue,
        replaceString: newValue,
        inName: field === "name",
        inTrigger: field === "type",
        inTemplate: field === "formula",
        blockIds: [itemId]
      })
    )
    return await Promise.all(promises)
  }

  const handleCopyUpdatedTriggers = async triggerChanges => {
    const alertMessages = []

    const triggers = (getSourceTriggers() || []).filter(itm => {
      const trigId = itm.id
      let found = false
      if (trigId) {
        triggerChanges?.changeList?.forEach(trg => {
          if (Number(trg.itemId) === Number(trigId)) {
            found = true
          }
        })
        return found
      } else {
        return false
      }
    })

    try {
      await copyTriggersWithNoChanges(triggerChanges.noChangeList)
    } catch (err) {
      console.error(
        `Error copying triggers to library ${targetNarrativeId}`,
        err
      )
      alertMessages.push(
        "Error Encountered with copying triggers. Please contact Engineering."
      )
    }

    let triggerChangesWithNewIds = []
    try {
      setNumEntitiesBeingCopied(numEntities => numEntities + triggers.length)
      triggerChangesWithNewIds = await copyTriggersAndGetChangesWithNewIds(
        triggers,
        triggerChanges.changeList
      )
      setSubmittedTriggerChanges(triggerChangesWithNewIds)
    } catch (err) {
      console.error(
        `Error copying triggers to library ${targetNarrativeId}`,
        err
      )
      alertMessages.push(
        "Error Encountered with copying triggers. Please contact Engineering."
      )
    }

    try {
      if (triggerChangesWithNewIds.length) {
        const promises = triggerChangesWithNewIds.map(changeEntry =>
          replaceInOneTrigger(changeEntry)
        )
        await Promise.all(promises)
      }
    } catch (err) {
      console.error(
        `Error updating copied triggers with new values in library ${targetNarrativeId}`,
        err
      )
      alertMessages.push(
        "Error Encountered with replacing in triggers. Please contact Engineering."
      )
    }

    if (alertMessages.length) {
      alert(alertMessages.join("\n"))
    }

    resetAfterCopy()
  }

  function canWeCopyBlocks() {
    if (!hasEntities()) {
      return {
        able: false,
        msg: "Nothing selected to copy."
      }
    }

    if (includeAssociatedTriggers && !noTriggersInEntities()) {
      return {
        able: false,
        msg: "Please only select non-trigger blocks when selecting 'include triggers + update'"
      }
    }

    const haveSentences = haveSomeEntityByRegex(/sentenceid/)

    // If its a sentence, be sure other blocks aren't also selected
    if (haveSentences && !onlySentencesInEntities()) {
      return {
        able: false,
        msg: "You've selected a mix of sentences and other blocks which can confuse the system. Please copy top level items like Triggers and Paragraphs in separate batches from sentences."
      }
    }

    if (haveSentences && !targetsAreValid()) {
      return {
        able: false,
        msg: "Sentences can only be copied to paragraphs. Please select a target paragraph to continue"
      }
    }

    // Be sure we didnt get here without a target narrative
    if (!targetNarrativeId) {
      return {
        able: false,
        msg: "Please select the narrative you wish to export the blocks to."
      }
    }

    return { able: true }
  }

  async function copySelectedBlocks() {
    setMissingTriggerIdsList([])
    const { able, msg } = canWeCopyBlocks()
    if (!able) {
      alert(msg)
      return
    }
    if (includeAssociatedTriggers) {
      const triggers = getSourceTriggers()
      if (triggers?.length) {
        setTriggerCopyWizardOpen(true)
      } else {
        setSubmittedTriggerChanges(true)
        await processBlocks(selectedSourceIds)
        resetAfterCopy()
      }
    } else {
      await processBlocks(selectedSourceIds)
      resetAfterCopy()
    }
  }

  const getTriggersFromSourceNarrative = ids => {
    const triggerIds = ids.map(id => Number(id))
    const triggers = sourceNarrative.triggers.filter(trigger =>
      triggerIds.includes(Number(trigger.id))
    )
    return triggers
  }

  const getBlocksFromSourceNarrative = ids => {
    const idSet = new Set(ids)
    const blocks = []
    sourceNarrative.paragraphs.forEach(paragraph => {
      if (idSet.has(`${paragraph.id}`)) {
        blocks.push(paragraph)
      }
      paragraph.sentences?.forEach(sentence => {
        if (idSet.has(`${sentence.id}`)) {
          blocks.push(sentence)
        }
      })
    })

    return blocks
  }

  const checkForMissingTriggersIdsInTriggers = () => {
    const missingTriggerIds = []

    sourceNarrative?.triggers?.forEach(trg => {
      selectedSourceIds?.forEach(src => {
        let trigId = src.match(/triggerid-(\d{3,7})/)
        trigId = trigId && trigId[1]
        if (
          trigId &&
          Number(trigId) === trg.id &&
          !trg.triggerIds &&
          trg.type
        ) {
          let myList = {}
          myList.parentId = null
          myList.blockId = trg.id
          myList.triggerType = trg.triggerType
          myList.type = "Trigger"
          missingTriggerIds.push(myList)
        }
      })
    })
    return Array.from(new Set(missingTriggerIds)) // Make sure the ids are unique
  }

  const checkForMissingTriggersIdsInBlocks = (
    blocks,
    parentInfo,
    isSentence
  ) => {
    const missingTriggerIds = []

    blocks.forEach(block => {
      const myParent = parentInfo || block
      if (!block.triggerIds && block.triggerType) {
        let myList = {}
        myList.parentId = myParent.id !== block.id ? myParent.id : null
        myList.blockId = block.id
        myList.triggerType = block.triggerType
        myList.type = isSentence
          ? "Sentence"
          : block.paragraphId
          ? "Sentence"
          : "Paragraph"
        missingTriggerIds.push(myList)
      }
      if (block.sentences?.length) {
        const missingSentenceTriggerIds = checkForMissingTriggersIdsInBlocks(
          block.sentences,
          myParent,
          true
        )
        missingTriggerIds.push(...missingSentenceTriggerIds)
      }
    })
    return Array.from(new Set(missingTriggerIds)) // Make sure the ids are unique
  }

  const getAssociatedTriggersIds = blocks => {
    const associatedIds = []
    blocks.forEach(block => {
      if (block.triggerIds) {
        const triggerIds = block.triggerIds.split(",").map(id => id.trim())
        associatedIds.push(...triggerIds)
      }
      if (block.sentences?.length) {
        const sentenceTriggerIds = getAssociatedTriggersIds(block.sentences)
        associatedIds.push(...sentenceTriggerIds)
      }
    })
    return Array.from(new Set(associatedIds)) // Make sure the ids are unique
  }

  const getAssociatedTriggersFromSourceNarrative = () => {
    const entities = getFilteredEntitiesByRegex(
      haveSomeEntityByRegex(/sentenceid/) ? /sentenceid/ : /paragraphid/
    )
    const blockIds = entities.map(block => {
      const { id } = processEntity(block)
      return id
    })
    const blocks = getBlocksFromSourceNarrative(blockIds)
    const triggerIds = getAssociatedTriggersIds(blocks)
    const missingTriggerIds = checkForMissingTriggersIdsInBlocks(blocks)
    if (
      missingTriggerIds.length &&
      confirmOpen &&
      missingTriggerIdsList.length === 0
    ) {
      setMissingTriggerIdsList(missingTriggerIds)
    }
    return getTriggersFromSourceNarrative(triggerIds)
  }

  const getSelectedTriggersFromSourceNarrative = () => {
    if (!includeAssociatedTriggers && onlyTriggersInEntities()) {
      const triggerIds = selectedSourceIds.map(entity => {
        const { id } = processEntity(entity)
        return id
      })
      return getTriggersFromSourceNarrative(triggerIds)
    }
  }

  const getSourceTriggers = () => {
    if (!includeAssociatedTriggers && onlyTriggersInEntities()) {
      return getSelectedTriggersFromSourceNarrative()
    }
    return getAssociatedTriggersFromSourceNarrative()
  }

  const handleSaveTriggers = triggerChanges => {
    const onlyCopyNotModifiedTriggerSourceIds = selectedSourceIds.filter(
      itm => {
        const trigId = itm.match(/triggerid-(\d{3,7})/)
        let found = false
        if (trigId && trigId[1]) {
          triggerChanges?.changeList?.forEach(trg => {
            if (Number(trg.itemId) === Number(trigId[1])) {
              found = true
            }
          })
          return !found
        } else {
          return true
        }
      }
    )
    const copyMyBlocks = processBlocks(onlyCopyNotModifiedTriggerSourceIds)
    setSavingTriggers(true)
    copyMyBlocks.then(() => {
      handleCopyUpdatedTriggers(triggerChanges)
      setTriggerCopyWizardOpen(false)
      setSavingTriggers(false)
      setMissingTriggerIdsList([])
    })
  }

  // reset source narrative state
  useEffect(
    () => () => {
      setSourceNarrative()
      setTargetNarrative()
    },
    []
  )

  useEffect(() => {
    if (selectedSourceNarrative === null) {
      setSourceNarrative()
    }
  }, [selectedSourceNarrative])

  useEffect(() => {
    if (selectedTargetNarrative === null) {
      setTargetNarrative()
    }
  }, [selectedTargetNarrative])

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const disableClearSelection =
    !selectedSourceIds?.length && !selectedTargetIds?.length

  const disableCopyBlocks = !canWeCopyBlocks().able

  const disableCopyEditTriggers =
    !hasEntities() || !selectedTargetNarrative || !onlyTriggersInEntities()

  const successModalOpen =
    !!copiedElements?.length &&
    (!includeAssociatedTriggers || !!submittedTriggerChanges)

  return (
    <Card>
      <TabContext value={tabValue}>
        <AppBar position="static" color="default">
          <TabList
            textColor="primary"
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleTabChange}
          >
            <Tab label="Copy Library Blocks & Update Triggers" value="0" />
            <Tab label="Copy Library Narratives" value="1" />
          </TabList>
        </AppBar>
        <CardContent>
          <TabPanel className={classes.tabPanel} value={"0"} index={0}>
            <GridContainer className={classes.gridContainer}>
              <GridItem className={classes.libraryExplorerArea} xs={4}>
                <FormControlLabel
                  control={
                    <CheckBox
                      checked={showInactiveLibraries || false}
                      color="primary"
                      onChange={() => {
                        setShowInactiveLibraries(!showInactiveLibraries)
                      }}
                    />
                  }
                  label={`Show Inactive Source Libraries? ${
                    showInactiveLibraries ? "*" : ""
                  }`}
                />
                <NarrativeAutoComplete
                  narrative={selectedSourceNarrative || {}}
                  isLibrary={true}
                  options={
                    showInactiveLibraries
                      ? narrativeOptions
                      : nonArchivedNarratives
                  }
                  labelName={"Source"}
                  onChange={(e, value) => {
                    setSelectedSourceIds([])
                    getSourceNarrativeBriefLibrary(value?.id)
                    setSelectedSourceNarrative(value)
                  }}
                  disabled={narrativeOptionsLoading}
                />
                <Box className={classes.explorerContainer}>
                  {loadingSourceNarrative && (
                    <CircularProgress
                      style={{ position: "absolute", left: "45%", top: "30px" }}
                    />
                  )}
                  {sourceNarrative?.id && (
                    <NarrativeBlockExplorer
                      targetOrSource="sourceNarrative"
                      selectedBlocks={selectedSourceIds}
                      narrative={sourceNarrative}
                      setBlocks={data => {
                        setSelectedSourceIds(data)
                      }}
                      disableIndividualTriggers={includeAssociatedTriggers}
                    />
                  )}
                </Box>
              </GridItem>
              <GridItem className={classes.actionArea} xs={2}>
                {loading && (
                  <CircularProgress
                    style={{ position: "absolute", left: "49%" }}
                  />
                )}
                <Button
                  className={classes.libraryCopyButton}
                  variant="contained"
                  color="primary"
                  disabled={disableClearSelection}
                  onClick={resetAfterCopy}
                >
                  Clear Selection
                </Button>
                {sourceNarrative?.id && (
                  <>
                    <Box className={classes.copyButtonContainer}>
                      <Button
                        className={classes.libraryCopyButton}
                        variant="contained"
                        color="primary"
                        disabled={disableCopyBlocks}
                        onClick={() => setConfirmOpen(true)}
                      >
                        Copy Selected Blocks
                      </Button>
                      <Box display="flex">
                        <FormControlLabel
                          control={<CheckBox size="small" />}
                          checked={includeAssociatedTriggers}
                          className={classes.includeTriggers}
                          classes={{ label: classes.includeTriggersLabel }}
                          disabled={!noTriggersInEntities()}
                          onChange={e =>
                            setIncludeAssociatedTriggers(e.target.checked)
                          }
                          label="Include Triggers + Update?"
                        />
                        <InfoDialog
                          aria-label="copy-tools-include-triggers-and-update-helper"
                          content={
                            <>
                              Include Triggers and Update functionality
                              identifies, copies and allows user to update
                              unique triggers associated with content blocks.
                              This can not be used when selecting individual
                              Triggers.
                            </>
                          }
                        />
                      </Box>
                    </Box>
                    <Button
                      className={classes.libraryCopyButton}
                      variant="outlined"
                      color="primary"
                      disabled={disableCopyEditTriggers}
                      onClick={() => {
                        const theMissing =
                          checkForMissingTriggersIdsInTriggers()
                        setMissingTriggerIdsList(theMissing)
                        setTriggerCopyWizardOpen(true)
                      }}
                    >
                      Copy & Update Triggers
                    </Button>
                    <InterstitialConfirmDialog
                      open={confirmOpen}
                      setOpen={() => {
                        setConfirmOpen(false)
                        setMissingTriggerIdsList([])
                      }}
                      onCancel={() => {
                        setConfirmOpen(false)
                        setMissingTriggerIdsList([])
                      }}
                      onConfirm={copySelectedBlocks}
                      sourceEntities={selectedSourceIds}
                      sourceNarrative={sourceNarrative}
                      targetNarrative={targetNarrative}
                      targetEntities={selectedTargetIds}
                      selectedSources={selectedSourceIds}
                      withAssociatedTriggers={includeAssociatedTriggers}
                      missingTriggerIdsList={missingTriggerIdsList}
                    />

                    <Dialog
                      maxWidth="lg"
                      open={triggerCopyWizardOpen}
                      onClose={() => setTriggerCopyWizardOpen(false)}
                    >
                      {!savingTriggers && (
                        <TriggerTableEditWizard
                          data={getSourceTriggers()}
                          sourceEntities={selectedSourceIds}
                          sourceNarrative={sourceNarrative}
                          targetNarrative={targetNarrative}
                          targetEntities={selectedTargetIds}
                          destinationLibraryId={selectedTargetNarrative?.id}
                          selectedSources={selectedSourceIds}
                          withAssociatedTriggers={includeAssociatedTriggers}
                          onCancel={() => setTriggerCopyWizardOpen(false)}
                          onSaveTriggers={triggerChanges => {
                            handleSaveTriggers(triggerChanges)
                          }}
                          missingTriggerIdsList={missingTriggerIdsList}
                        />
                      )}
                      {savingTriggers && (
                        <CircularProgress
                          style={{
                            position: "absolute",
                            left: "45%",
                            top: "30px"
                          }}
                        />
                      )}
                    </Dialog>
                    <Dialog
                      maxWidth="lg"
                      open={triggerIDsToContentBlocksWizardOpen}
                      onClose={() => {
                        setTriggerIDsToContentBlocksWizardOpen(false)
                        onCloseSuccessModal()
                      }}
                    >
                      {triggerIDsToContentBlocksWizardOpen && (
                        <TriggerIDsToContentBlocksWizard
                          data={savedTriggerResults}
                          targetNarrative={targetNarrative}
                          triggerChanges={submittedTriggerChanges}
                          onCancel={() => {
                            setTriggerIDsToContentBlocksWizardOpen(false)
                            onCloseSuccessModal()
                          }}
                        />
                      )}
                      {savingTriggers && (
                        <CircularProgress
                          style={{
                            position: "absolute",
                            left: "45%",
                            top: "30px"
                          }}
                        />
                      )}
                    </Dialog>
                  </>
                )}
              </GridItem>
              <GridItem className={classes.libraryExplorerArea} xs={4}>
                <FormControlLabel
                  control={
                    <CheckBox
                      checked={showArchivedLibraries || false}
                      color="primary"
                      onChange={() => {
                        setShowArchivedLibraries(!showArchivedLibraries)
                      }}
                    />
                  }
                  label="Show Archived Libraries"
                />
                <NarrativeAutoComplete
                  narrative={selectedTargetNarrative || {}}
                  isLibrary={true}
                  options={
                    showInactiveLibraries
                      ? narrativeOptions
                      : nonArchivedNarratives
                  }
                  labelName={"Target"}
                  onChange={(e, value) => {
                    setSelectedTargetIds([])
                    getTargetNarrativeBriefLibrary(value?.id)
                    setSelectedTargetNarrative(value)
                  }}
                  disabled={narrativeOptionsLoading}
                />
                <Box className={classes.explorerContainer}>
                  {loadingTargetNarrative && (
                    <CircularProgress
                      style={{ position: "absolute", left: "45%", top: "30px" }}
                    />
                  )}
                  {targetNarrative?.id && (
                    <NarrativeBlockExplorer
                      targetOrSource="targetNarrative"
                      selectedBlocks={selectedTargetIds}
                      narrative={targetNarrative}
                      setBlocks={data => {
                        setSelectedTargetIds(data)
                      }}
                    />
                  )}
                </Box>
              </GridItem>
              {copiedElements && copiedElements.length > 0 && (
                <LibraryCopySuccessModal
                  open={successModalOpen}
                  copiedElements={copiedElements}
                  numEntitiesBeingCopied={numEntitiesBeingCopied}
                  triggerChanges={submittedTriggerChanges}
                  withAssociatedTriggers={includeAssociatedTriggers}
                  onCloseAction={() => {
                    onCloseSuccessModal()
                    setTriggerIDsToContentBlocksWizardOpen(false)
                  }}
                  onSyncTriggers={results => {
                    setSavedTriggerResults(results)
                    setTriggerIDsToContentBlocksWizardOpen(true)
                  }}
                  targetNarrative={targetNarrative}
                />
              )}
            </GridContainer>
          </TabPanel>
          <TabPanel value={"1"} index={1}>
            <Grid>
              <GridItem className={classes.title}>
                <Typography variant="body1">
                  Creates a new Library based on an existing Library
                </Typography>
              </GridItem>
            </Grid>
            <Grid className={classes.copyLibary}>
              <GridItem xs={6}>
                <LibraryNarrativeClone />
              </GridItem>
            </Grid>
          </TabPanel>
        </CardContent>
      </TabContext>
    </Card>
  )
}

export default LibraryCopyTools
