import { NarrativeMenuActionsContext } from "contexts/narrativeMenuActionsContext"
import useNarrativeMenuActions from "hooks/narratives/useNarrativeMenuActions"

// Ties the useNarrativeMenuActions hook and corresponding context together
// Keep the context in sync with what the hook returns!
const NarrativeMenuActionsProvider = ({ children }) => {
  const menuActions = useNarrativeMenuActions()

  return (
    <NarrativeMenuActionsContext.Provider value={menuActions}>
      {children}
    </NarrativeMenuActionsContext.Provider>
  )
}

export default NarrativeMenuActionsProvider
